import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-gh-autocomplete-multiselect',
  templateUrl: './gh-autocomplete-multiselect.component.html',
  styleUrls: ['./gh-autocomplete-multiselect.component.scss']
})
export class GhAutocompleteMultiselectComponent implements OnInit {


  @Output() changeEmitter = new EventEmitter()


  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  //* Once done with getting this established, need to be able to pass in value as alternative to coreSK
  //*   and then in html do something like the syntax [value]="item[_whatever__]"
  //*   also need to deal with this in compareFN
  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  //????????????????????????????????????????????????????????????????????????????????????????
  //* Need to add autocomplete to this component
  //*   Fuse eventually?  (this assumes that all possible data is here in one fetch... verify with Dave)
  //*       We could deal with next token and stuff, but would become much more complex...
  //???????????????????????????????????????????????????????????????????????????????????????? 

  itemsControl = new UntypedFormControl();
  label = 'Select Items'
  @Input() set _label(label) {
    this.label = label
  }


  coreGameData

  currentSelections = []

  //* IMPORTANT: 
  //*     since input passeed in as currentSelections is the result of GetGame, the attributes do not match the attributes of the result of listAllGameCoreData, which is passed in to itemsList
  //*           thus, we need to do some extra reshaping (using reshapedCurrentSelections) and treat the ngModel as the later in the following link:
  //*                 https://stackblitz.com/edit/angular6-mat-select-with-obj-default-value?file=app%2Fselect-multiple-example.html

  reshapedCurrentSelections

  @Input() set _currentSelections(currentSelections) {
    this.currentSelections = currentSelections
  }

  itemsList
  @Input() set _itemsList(itemsList) {
    this.itemsList = itemsList
  }

  //!!! need to make this madatory (similar to source of input or whatever...)
  attributeToDisplay
  @Input() set _attributeToDisplay(attributeToDisplay: string) {
    this.attributeToDisplay = attributeToDisplay
  }


  //! since the core list uses pk, if the incoming current selection are different then we need to convert it
  attributeToConvertToPK
  @Input() set _attributeToConvertToPK(attributeToConvertToPK: string) {
    this.attributeToConvertToPK = attributeToConvertToPK
  }

  isDisabled = false
  @Input() set _isDisabled(isDisabled: boolean) {
    this.isDisabled = isDisabled
  }

  @ViewChild('selectInput', { static: false }) selectInput

  blur() {
    this.selectInput.close()
  }


  form

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      selections: null
    })

    if (this.attributeToConvertToPK) {
      this.reshapedCurrentSelections = []
      for (let selection of this.currentSelections) {
        this.reshapedCurrentSelections.push({ pk: selection[this.attributeToConvertToPK] })
      }
    }
    else {
      this.reshapedCurrentSelections = this.currentSelections
    }

    this.onChanges()
  }

  onChanges(): void { // todo we will need to unsubscribe or use the NgNeat thingy
    this.form.valueChanges.subscribe(value => {
      this.changeEmitter.emit(value)
    })
  }

  compareFn(o1: any, o2: any): boolean {
    return o1.pk === o2.pk
  }

}
