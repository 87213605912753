<div *ngIf="contributionType == 0 && (games$ | async)">
    <!-- <app-autocomplete-chiplist id="game-input" [_items]="games.value" [_itemsAreObjects]="true"
        [_label]="'Games Requested'" [_allowDuplicates]="false" [_allowCustomInput]="true"
        (selectionChanged)="setGames($event)" required>
    </app-autocomplete-chiplist> -->

    <!--  TODO: replace auto complete with gh dropdown select for food and beverage below as well!!! -->

    <div class="col-12 mt-3">
        <mat-card class="mat-elevation-z6">
            <div class="row gh-card-header-row has-button">
                <div class="col-12">
                    <span class="inline-span">
                        <mat-card-title class="gh-sm-title">GUESTS' COLLECTIONS:</mat-card-title>
                    </span>
                </div>
                <div class="col-12">
                    <mat-divider></mat-divider>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mt-3">
                    <app-gh-dropdown-select [_label]="'Games Requested'" [_tier0Items]="games.value" [_isTiered]="true"
                        [_isMultiSelect]="true" [_displayThumbImg]="true" [_itemsAreObjects]="true" (selectionChangedEmitter)="setGames($event)" [_sourceOfInput]="'app-request-gamenight-contribution'">
                    </app-gh-dropdown-select>


                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="carousel-container">
                                <!-- <ngu-carousel *ngIf="gamesToContribute" class="carousel" #gamesCarousel
                                    [inputs]="carouselConfig" [dataSource]="gamesToContribute">
                                    <div *nguCarouselDef="let item;" class="item">
                                        <div class="tile">
                                            <div class="col-12 img-container">
                                                <img src="{{item.game.thumbImgPath != '' ? item.game.thumbImgPath : defaultMainImage}}"
                                                    alt="">
                                            </div>
                                            <div class="col-12 text-center mt-2">
                                                <mat-card-title class="gh-xsm-title">{{item.game.title}}
                                                </mat-card-title>
                                            </div>
                                            <div class="col-12 text-center mt-2">
                                                <mat-card-subtitle>{{getRequestorsString(item.requestors)}}
                                                </mat-card-subtitle>
                                            </div>
                                        </div>
                                    </div>

                                    <button NguCarouselNext class="rightRs">
                                        <mat-icon>
                                            keyboard_arrow_right
                                        </mat-icon>
                                    </button>
                                    <button NguCarouselPrev class="leftRs">
                                        <mat-icon>
                                            keyboard_arrow_left
                                        </mat-icon>
                                    </button>
                                    <ul class="myPoint" NguCarouselPoint>
                                        <li *ngFor="let j of gamesCarousel.pointNumbers; let jnd = index"
                                            [class.active]="j==gamesCarousel.activePoint"
                                            (click)="gamesCarousel.moveTo(jnd)">
                                        </li>
                                    </ul>
                                </ngu-carousel> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>


</div>

<div *ngIf="contributionType == 1">
    <app-autocomplete-chiplist id="food-input" [_items]="commonFoodContributions" [_itemsAreObjects]="false"
        [_label]="'Food Requested'" [_allowDuplicates]="false" [_allowCustomInput]="true" [_sourceOfInput]="'app-request-gamenight-contribution[_sourceOfInput]1'"
        (selectionChanged)="setFood($event)" required>
    </app-autocomplete-chiplist>
</div>

<div *ngIf="contributionType == 2">
    <app-autocomplete-chiplist id="beverage-input" [_items]="commonBeverageContributions" [_itemsAreObjects]="false"
        [_label]="'Beverages Requested'" [_allowDuplicates]="false" [_allowCustomInput]="true" [_sourceOfInput]="'app-request-gamenight-contribution[_sourceOfInput]2'"
        (selectionChanged)="setBeverages($event)" required>
    </app-autocomplete-chiplist>
</div>


<div class="gh-loading-container mt-5" *ngIf="contributionType == 0 && !(games$ | async)">
    <app-gh-loading-spinner></app-gh-loading-spinner>
</div>