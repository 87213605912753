<div id="dropdown-container" class="row pt-3">
    <div class="col-12">
        <app-gh-dropdown-select [_label]="'Games Of Interest'" [_tier0Items]="inputData.hostCollection" [_isTiered]="false" [_isMultiSelect]="true" [_displayThumbImg]="true" [_itemsAreObjects]="true" (selectionChangedEmitter)="setGames($event)" [_sourceOfInput]="'app-gamenight-host-collection'">
        </app-gh-dropdown-select>
    </div>
</div>

<div class="row mt-3">
    <div class="col-12">
        <div class="carousel-container">
            <!-- <ngu-carousel *ngIf="gamesOfInterest" class="carousel" #gamesCarousel [inputs]="carouselConfig" [dataSource]="gamesOfInterest">
                <div *nguCarouselDef="let item;" class="item">
                    <div class="tile">
                        <div class="col-12 img-container">
                            <img src="{{item.game.thumbImgPath != '' ? item.game.thumbImgPath : defaultMainImage}}" alt="">
                        </div>
                        <div class="col-12 text-center mt-2">
                            <mat-card-title class="gh-xsm-title">{{item.game.title}}
                            </mat-card-title>
                        </div>
                        <div class="col-12 text-center mt-2">
                            <mat-card-subtitle>{{getRequestorsString(item.requestors)}}
                            </mat-card-subtitle>
                        </div>
                    </div>
                </div>

                <button NguCarouselNext class="rightRs">
                    <mat-icon>
                        keyboard_arrow_right
                    </mat-icon>
                </button>
                <button NguCarouselPrev class="leftRs">
                    <mat-icon>
                        keyboard_arrow_left
                    </mat-icon>
                </button>
                <ul class="myPoint" NguCarouselPoint>
                    <li *ngFor="let j of gamesCarousel.pointNumbers; let jnd = index" [class.active]="j==gamesCarousel.activePoint" (click)="gamesCarousel.moveTo(jnd)">
                    </li>
                </ul>
            </ngu-carousel> -->
        </div>
    </div>
</div>