<div class="p-5 parent-container">
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: center;
        "
    >
        <strong
            ><span style="font-size: 21px; line-height: 107%; font-family: 'Segoe UI', sans-serif"
                >Game Haven Privacy Policy</span
            ></strong
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >This privacy policy (&quot;policy&quot;) will help you understand how GAME HAVEN
            (&quot;us&quot;, &quot;we&quot;, &quot;our&quot;) uses and protects the data you provide
            to us when you visit and use myGameHaven.com.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >We reserve the right to change this policy at any given time, of which you will be
            promptly updated. If you want to make sure that you are up to date with the latest
            changes, we advise you to frequently visit this page.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <strong
            ><span style="font-family: 'Segoe UI', sans-serif"
                >What User Data We Collect</span
            ></strong
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >When you visit the website, we may collect the following data:</span
        >
    </p>
    <ul style="list-style-type: disc">
        <li><span style="font-family: 'Segoe UI', sans-serif">Your IP address.</span></li>
        <li><span style="font-family: 'Segoe UI', sans-serif">Your email address.</span></li>
        <li>
            <span style="font-family: 'Segoe UI', sans-serif"
                >Other information such as gaming interests and preferences.</span
            >
        </li>
        <li>
            <span style="font-family: 'Segoe UI', sans-serif"
                >Data profile regarding your online behavior on our website (example: game ratings,
                reviews, likes).</span
            >
        </li>
    </ul>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0.5in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <strong
            ><span style="font-family: 'Segoe UI', sans-serif"
                >Why We Collect Your Data</span
            ></strong
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >We are collecting your data for several reasons:</span
        >
    </p>
    <ul style="list-style-type: disc">
        <li>
            <span style="font-family: 'Segoe UI', sans-serif"
                >To better understand your needs.</span
            >
        </li>
        <li>
            <span style="font-family: 'Segoe UI', sans-serif"
                >To improve our services and products.</span
            >
        </li>
        <li>
            <span style="font-family: 'Segoe UI', sans-serif"
                >To customize our website according to your online behavior and personal
                preferences.</span
            >
        </li>
    </ul>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0.5in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <strong
            ><span style="font-family: 'Segoe UI', sans-serif"
                >Safeguarding and Securing the Data</span
            ></strong
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >GAME HAVEN is committed to securing your data and keeping it confidential. GAME HAVEN
            (through working with AWS) has done all in its power to prevent data theft, unauthorized
            access, and disclosure by implementing the latest technologies and software, which help
            us safeguard all the information we collect online.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <strong><span style="font-family: 'Segoe UI', sans-serif">Our Cookie Policy</span></strong>
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >Once you agree to allow our website to use cookies, you also agree to use the data it
            collects regarding your online behavior (analyze web traffic, web pages you spend the
            most time on, and websites you visit).</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >The data we collect by using cookies is used to customize our website to your needs.
            After we use the data for statistical analysis, the data is completely removed from our
            systems.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >Please note that cookies don&apos;t allow us to gain control of your computer in any
            way. They are strictly used to monitor which pages you find useful and which you do not
            so that we can provide a better experience for you.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >If you want to disable cookies, you can do it by accessing the settings of your
            internet browser. You can visit&nbsp;</span
        ><a href="http://www.internetcookies.com"
            ><span style="font-family: 'Segoe UI', sans-serif">www.internetcookies.com</span></a
        ><span style="font-family: 'Segoe UI', sans-serif"
            >, which contains comprehensive information on how to do this on a wide variety of
            browsers and devices.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <strong
            ><span style="font-family: 'Segoe UI', sans-serif"
                >Links to Other Websites</span
            ></strong
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >Our website contains links that lead to other websites. If you click on these links
            GAME HAVEN is not held responsible for your data and privacy protection. Visiting those
            websites is not governed by this privacy policy agreement. Make sure to read the privacy
            policy documentation of the website you go to from our website.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <strong
            ><span style="font-family: 'Segoe UI', sans-serif"
                >Restricting the Collection of your Personal Data</span
            ></strong
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >At some point, you might wish to restrict the use and collection of your personal data.
            You can achieve this by doing the following:</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >When you are filling the forms or posting content on the website, don&rsquo;t disclose
            your personal information.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >Contact us to destroy all your personal data. A valid email is a requirement of our
            terms and conditions.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif"
            >GAME HAVEN will not lease, sell or distribute your personal information to any third
            parties, unless we have your permission. We might do so if the law forces us. Your
            personal information will be used when we need to send you promotional materials if you
            agree to this privacy policy.</span
        >
    </p>
    <p
        style="
            margin-top: 0in;
            margin-right: 0in;
            margin-bottom: 8pt;
            margin-left: 0in;
            line-height: 107%;
            font-size: 15px;
            font-family: 'Calibri', sans-serif;
            color: black;
            text-align: justify;
        "
    >
        <span style="font-family: 'Segoe UI', sans-serif">&nbsp;</span>
    </p>
</div>
