import { trigger, style, transition, animate, keyframes } from '@angular/animations'

export const AuthenticatedHeaderAnimations = {

    growIn: trigger('growInTrigger', [
        transition('void => *', [
            animate(750, keyframes([
                style({ width: '0' }),
                style({ width: '30%' })
            ]))
        ]),
        transition('* => void', [
            animate(750, keyframes([
                style({ width: '30%' }),
                style({ width: '0' })
            ]))
        ])
    ])

}