import { Injectable } from '@angular/core'
import gql from 'graphql-tag'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { Auth } from 'aws-amplify'
import { iAddToMyGroupsInput, iRemoveFromMyGroupsInput } from '../../../../common/interfaces/GroupTrackingInterfaces'
import { iCreateOrUpdateGroupInput, iDeleteGroupInput } from '../../../../common/interfaces/GroupInterfaces'
import { environment } from 'src/environments/environment'
import { BackendApiS3Service } from './backend-api-s3.service'
import { ImageMultiSizeService } from '../dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import { uploadImageFile } from './s3-file-uploader'
import { S3ContentHelperService } from '../dashboard/dashboard-shared/services/helpers/s3-content-helper.service'


@Injectable({
  providedIn: "root"
})
export class BackendAPIGroupsService {

  constructor(
    private backendApiS3Service: BackendApiS3Service,
    private imageMultiSizeService: ImageMultiSizeService,
    private s3ContentHelperService: S3ContentHelperService
  ) { }

  private appSyncClient = new AWSAppSyncClient({
    url: environment.apiUrl,
    region: 'us-east-1',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        return token
      }
    },
    disableOffline: true,
  })

  async listFollowersByGroupId(id: string, limit: number, nextToken?) {

    const response = await this.appSyncClient.query<any>({
      query: gql`
        query listFollowersByGroupId($id: ID!, $limit: Int, $nextToken: String) {
          listFollowersByGroupId(id: $id, limit: $limit, nextToken: $nextToken) {
            nextToken
            followers{
              groupTitle
              SK_byType_byZip
              updatedAt
              createdAt
              mainImageFiles
              userTitle
              username
              sk_TYPE_UID_ULID
              }
            }
          }
        `,
      variables: { id, limit, nextToken },
      fetchPolicy: 'network-only'
    })

    return response.data.listFollowersByGroupId
  }










  async addToMyGroups(input: iAddToMyGroupsInput) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation addToMyGroups($input: IGQL_AddToMyGroups!) {
            addToMyGroups (input: $input) {
              mainImageFiles
            }
          }
        `,
      variables: { input }
    })
    return response.data.addToMyGroups
  }


  async removeFromMyGroups(input: iRemoveFromMyGroupsInput) {
    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation removeFromMyGroups($input: IGQL_RemoveFromMyGroups!) {
            removeFromMyGroups (input: $input) {
              mainImageFiles
            }
          }
        `,
      variables: { input }
    })
    return response.data.removeFromMyGroups
  }

  async GetGroupMainImageFiles(id: string) {

    const response = await this.appSyncClient.query<any>({
      query: gql`
          query getGroup($id: ID!) {
            getGroup(id: $id) {
              mainImageFiles
            }
          }
        `,
      variables: { id },
      fetchPolicy: 'network-only'

    })
    return response.data.getGroup
  }

  async deleteGroup(input: iDeleteGroupInput) {
    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation deleteGroup($input: IGQL_DeleteGroupInput!) {
            deleteGroup (input: $input) {
              id
            }
          }
        `,
      variables: { input }
    })
    return response.data.deleteGroup
  }

  async CreateGroup(input: iCreateOrUpdateGroupInput, files) {

    let result = []
    let mainImageResult

    input.mainImageFiles = await this.s3ContentHelperService.prepareMainImageFiles(files)
    result.push(mainImageResult)


    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation createGroup($input: IGQL_CreateOrUpdateGroupInput!) {
            createGroup (input: $input) {
            id
            title
            createdAt
            updatedAt
            }
          }
        `,
      variables: { input }
    })
    return response.data.createGroup
  }

  async listAllGroupCoreData(type: string, limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listAllGroupCoreData($type: String, $limit: Int, $nextToken: String ) {
            listAllGroupCoreData(type: $type, limit: $limit, nextToken: $nextToken ) {
              nextToken
              coreGroups {
                corePK
                coreSK
                zipCode
                title
                pk
                mainImageFiles
                description
              } 
              
            }
          }
        `,
      variables: { type, limit, nextToken },
      fetchPolicy: 'network-only'
    })

    return response.data.listAllGroupCoreData
  }

  async listGroupsThatNeedAttention(limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listGroupsThatNeedAttention( $limit: Int, $nextToken: String ) {
            listGroupsThatNeedAttention( limit: $limit, nextToken: $nextToken ) {
              nextToken
              groups {
                createdAt
                addedByUID
                id
                title
                description
                isPublic
                dateEstablished
                havens
                games
                mainImage
                additionalImages
                zipCode
                publicAddress
                playTimes
                groupAdmin
                activityStatus
                updatedAt
                userWhoUpdated
                allowPublicToSeeGroup
                allowPublicToSeeDescription
                allowPublicToSeeMembership
                allowPublicToSeePlayTimes
                allowPublicToSeeActivity
                allowMembersToInviteGuests
                allowGuestsToInviteGuests
                allowPublicToRequestToBecomeGuest
                mainImageFiles
              } 
            }
          }
        `,
      variables: { limit, nextToken }
    })
    return response.data.listGroupsThatNeedAttention
  }

  async listAllGroups(limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listAllGroups( $limit: Int, $nextToken: String ) {
            listAllGroups( limit: $limit, nextToken: $nextToken ) {
              nextToken
              groups {
                createdAt
                addedByUID
                id
                title
                description
                isPublic
                dateEstablished
                havens
                games
                mainImage
                additionalImages
                zipCode
                publicAddress
                playTimes
                groupAdmin
                activityStatus
                updatedAt
                userWhoUpdated
                allowPublicToSeeGroup
                allowPublicToSeeDescription
                allowPublicToSeeMembership
                allowPublicToSeePlayTimes
                allowPublicToSeeActivity
                allowMembersToInviteGuests
                allowGuestsToInviteGuests
                allowPublicToRequestToBecomeGuest
                mainImageFiles
              } 
            }
          }
        `,
      variables: { limit, nextToken }
    })
    return response.data.listAllGroups
  }

  async approveNewGroup(input: iCreateOrUpdateGroupInput) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation approveNewGroup($input: IGQL_CreateOrUpdateGroupInput!) {
            approveNewGroup (input: $input) {
              id
              addedByUID
              needsAttentionPK
              needsAttentionSK
            }
          }
        `,
      variables: { input }
    })
    return response.data.approveNewGroup
  }

  async UpdateGroup(input: iCreateOrUpdateGroupInput, files?) {

    input.mainImageFiles = await this.s3ContentHelperService.prepareMainImageFiles(files)

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation updateGroup($input: IGQL_CreateOrUpdateGroupInput!) {
            updateGroup (input: $input) {
            id
            title
            createdAt
            updatedAt
            }
          }
        `,
      variables: { input }
    })
    return response.data.updateGroup
  }

















  async GetGroup(id: string) {

    const response = await this.appSyncClient.query<any>({
      query: gql`
        query getGroup($id: ID!) {
          getGroup(id: $id) {
            addedByUID
            addedByTitle
            userWhoUpdated
            title
            description
            activityStatus
            isPublic
            dateEstablished
            activityStatus
            state
            zipCode
            createdAt
            updatedAt
            mainImageFiles
            corePK
            coreSK
            groupAdmin
            pk
            otherGroupAdmin
            groupAdmin
          }
        }
      `,
      variables: { id },
      fetchPolicy: 'network-only'

    })
    return response.data.getGroup
  }

}
