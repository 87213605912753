import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-content-flag',
  templateUrl: './content-flag.component.html',
  styleUrls: ['./content-flag.component.scss']
})
export class ContentFlagComponent implements OnInit {

  isFlagged = false
  flagColor = 'gray'
  date

  constructor() { }

  ngOnInit(): void {
  }

  flag() {
    this.isFlagged = !this.isFlagged
    if (this.isFlagged) {
      this.flagColor = 'red'
      this.date = new Date()
    }
    else {
      this.flagColor = 'gray'
    }
  }

}
