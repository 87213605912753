import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import loadImage from 'blueimp-load-image';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { ImageConversionService } from '../../../services/helpers/image-conversion.service'
import { ImageCropperComponent } from '../image-cropper/image-cropper.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { HeicToJpegDirective } from '../../../directives/heic-to-jpeg.directive'
import { TimingHelperService } from '../../../services/helpers/timing-helper.service'
import heic2any from 'heic2any'
import { SnackbarService } from '../../../services/user-action-feedback/snackbar.service'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-gh-file-uploader',
    templateUrl: './gh-file-uploader.component.html',
    styleUrls: ['./gh-file-uploader.component.scss'],
})
export class GhFileUploaderComponent implements OnInit {


    //* For HEIC reference:
    //* https://soshace.com/handling-heic-images-in-angular-a-comprehensive-tutorial/

    @ViewChild(HeicToJpegDirective, { static: false }) heicToJpegDirective: HeicToJpegDirective;

    imageChanged = false

    fileName // needed to maintain file name when image is cropped

    @Input() _sourceOfInput: string

    isProfileImg = false
    @Input() set _isProfileImg(isProfileImg: boolean) {
        this.isProfileImg = isProfileImg
    }

    @Output() blurContentEmitter = new EventEmitter()
    @Output() selectedImageChanged = new EventEmitter()

    // TODO: use input to change the default image (meeple, gh icon, etc.)
    //! need distinct variables for default image and standard default image because if input is being edited and input is deleted, need to set it to some placeholder rather than the inputed image to be edited...
    standardDefaultImage = window.location.origin + '/assets/images/defaults/placeholder-event.png'
    defaultImage = this.standardDefaultImage
    selectedImage: any = this.defaultImage
    rawImage

    imageIsSelected = false


    heicPreview: File | null = null;

    @Input() set _defaultImage(defaultImage) {
        if (defaultImage) {
            this.defaultImage = defaultImage
            this.selectedImage = this.defaultImage
            this.rawImage = this.defaultImage
            this.imageIsSelected = true
        }
    }

    isEditing = false //! this means that the default image is NOT an image to be edited
    @Input() set _isEditing(isEditing: boolean) {
        this.isEditing = isEditing
    }

    // inputId is needed to make each child component unique, if parent has more than one instance, name them file-input-1, file-input-2, etc.
    inputId = 'file-input-0'
    @Input() set _inputId(inputId) {
        this.inputId = inputId
    }

    constructor(
        private dialog: MatDialog,
        private screenSizeService: ScreenSizeService,
        private timingHelperService: TimingHelperService,
        private snackbar: MatSnackBar,
        private snackbarService: SnackbarService,

        private imageConversionService: ImageConversionService,
    ) { }

    isMobileScreen = false

    fileIsHeic = false // upon default image this will always be jpg since it has been converted by lambda by then

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }







    public onFileChanged(fileInput: any) {
        if (fileInput.target.files && fileInput.target.files[0]) {
            this.imageChanged = true;
            this.fileIsHeic = fileInput.target.files[0].name.includes('.heic');

            if (!this.fileIsHeic) {
                this.handleRegularImage(fileInput);
            } else {
                this.handleHeicImage(fileInput);
            }
        }
    }

    handleRegularImage(fileInput) {
        const file = fileInput.target.files[0];
        if (file) {
            // Use loadImage directly on the file object with options for handling orientation and outputting a canvas.
            loadImage(
                file,
                (canvas) => {
                    // Convert canvas to data URL for display
                    const dataURL = canvas.toDataURL('image/jpeg');
                    this.selectedImage = dataURL;

                    // Convert canvas to blob and create a new File object for further use
                    canvas.toBlob((blob) => {
                        const newFile = new File([blob], file.name, { type: 'image/jpeg' });
                        this.rawImage = newFile;

                        if (this.selectedImage && this.rawImage != this.defaultImage) {
                            this.imageIsSelected = true;
                            this.fileName = this.rawImage.name; // Ensure this is name, not fileName

                            this.selectedImageChanged.emit({
                                selectedImage: this.selectedImage,
                                rawImage: this.rawImage,
                            });
                        }
                    }, 'image/jpeg');
                },
                { orientation: true, canvas: true }
            );
        } else {
            this.snackbarService.openErrorSnackBar('Only JPG, JPEG, and PNG are allowed');
        }
    }

    // Continue to handle HEIC images similarly but with corrections for orientation using loadImage
    async handleHeicImage(event) {
        const fileInput = event.target as HTMLInputElement;

        if (fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            this.heicPreview = file;

            this.timingHelperService.delay(1000).then(async () => {
                let convertedToJpeg = await this.convertHeicToJpeg(file);

                // Use loadImage on the JPEG blob from HEIC conversion
                loadImage(
                    convertedToJpeg,
                    (canvas) => {
                        const dataURL = canvas.toDataURL('image/jpeg');
                        this.selectedImage = dataURL;

                        canvas.toBlob((blob) => {
                            const newFile = new File([blob], file.name.replace(/\.[^.]+$/, '.jpeg'), { type: 'image/jpeg' });
                            this.rawImage = newFile;

                            if (this.selectedImage && this.rawImage != this.defaultImage) {
                                this.imageIsSelected = true;
                                this.fileName = this.rawImage.name;

                                this.selectedImageChanged.emit({
                                    selectedImage: this.selectedImage,
                                    rawImage: this.rawImage,
                                });
                            }
                        }, 'image/jpeg');
                    },
                    { orientation: true, canvas: true }
                );
            });
        }
    }

    async convertHeicToJpeg(heicFile: File) {
        try {
            const result = await heic2any({ blob: heicFile, toType: 'image/jpeg', quality: 0.8 });
            const jpegBlob = Array.isArray(result) ? result[0] : result;
            return jpegBlob;
        } catch (error) {
            console.error('Error converting HEIC to JPEG:', error);
        }
    }












    deleteSelectedImage() {
        // ! DISCUSS: OK TO HOLD OFF ON BOTH OF THESE?
        // ! Known Edge case creation of Orphan DataTransfer
        // ! KNown uncommon case of removing image without setting a new image

        // TODO: haven't yet tested what happens if image is deleted and default image is NOT the default

        this.isEditing
            ? (this.selectedImage = this.standardDefaultImage)
            : (this.selectedImage = this.defaultImage)
        this.rawImage = undefined
        this.imageIsSelected = false

        // todo if no new image is set, it doesn't clear the old one asdfuiosad8uiofjowqeidnvosdffij

        this.selectedImageChanged.emit({
            // doesn't help
            selectedImage: this.selectedImage, // doesn't help
            rawImage: this.rawImage, // doesn't help
        }) // doesn't help
    }

    openCropper() {
        this.blurContentEmitter.emit(true)
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Upload Image',
                component: ImageCropperComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                inputData: {
                    img: this.selectedImage,
                    isProfileImg: this.isProfileImg,
                },
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.blurContentEmitter.emit(false)
                if (data) {
                    let dataAsFile = this.imageConversionService.dataURLtoFile(data, this.fileName)

                    this.selectedImage = data
                    this.rawImage = dataAsFile
                    this.imageChanged = true

                    this.selectedImageChanged.emit({
                        selectedImage: this.selectedImage,
                        rawImage: this.rawImage,
                    })
                }
            })
    }






}
