<div class="parent-container">

    <div class="row radio-button-container mt-2">
        <mat-radio-group aria-label="Select an option" (change)="selectType($event)">
            <mat-radio-button value="game" [checked]="true">Game(s)</mat-radio-button>
            <mat-radio-button value="open">Open Play</mat-radio-button>
            <mat-radio-button value="break">Break / Closed</mat-radio-button>
            <mat-radio-button value="custom">Custom</mat-radio-button>
        </mat-radio-group>

    </div>

    <div class="row" *ngIf="type == 0">

        <div class="col-12 input">
            <div class="row data-group">

                <div class="col-4">
                    <mat-card-subtitle>GAME:</mat-card-subtitle>
                    <app-autocomplete-chiplist [_items]="allGames" [_itemsAreObjects]="true" [_sourceOfInput]="'app-add-event-modal1'"
                        [_label]="'Choose a game'" [_allowDuplicates]="false" [_allowCustomInput]="true"
                        [_singleInput]="true" (selectionChanged)="selectItem($event)">
                    </app-autocomplete-chiplist>
                </div>

                <div class="col-4">
                    <mat-card-subtitle>Players:</mat-card-subtitle>
                    <app-autocomplete-chiplist [_items]="userList" [_label]="'Invite Players'" [_sourceOfInput]="'app-add-event-modal2'"
                        [_allowDuplicates]="false" [_allowCustomInput]="true" [_singleInput]="true"
                        (selectionChanged)="selectItem($event)">
                    </app-autocomplete-chiplist>
                </div>

                <div class="col-4">

                    <mat-checkbox (click)="toggleAttendance()">
                        Restrict Attendance?
                    </mat-checkbox>

                    <div *ngIf="restrictAttendance">
                        <mat-card-subtitle>Open slots:</mat-card-subtitle>
                        <input matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                            placeholder="No Limit"  type="number">
                        <mat-checkbox (click)="toggleWaitlist()">
                            Allow the public to <strong>see</strong> play/meet times?
                        </mat-checkbox>

                        <div *ngIf="hasWaitlist">
                            <mat-card-subtitle>Waitlist slots:</mat-card-subtitle>
                            <input matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                                placeholder="No Limit" type="number">
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row" *ngIf="type == 1">

        <div class="col-12 input">
            <div class="row data-group">

                <div class="col-12">
                    <mat-card-subtitle>Open Play:</mat-card-subtitle>
                </div>

            </div>
        </div>
    </div>

    <div class="row" *ngIf="type == 2">

        <div class="col-12 input">
            <div class="row data-group">

                <div class="col-12">
                    <mat-card-subtitle>Break / Closed:</mat-card-subtitle>
                </div>

            </div>
        </div>
    </div>

    <div class="row" *ngIf="type == 3">

        <div class="col-12 input">
            <div class="row data-group">

                <div class="col-12">
                    <mat-card-subtitle>Custom:</mat-card-subtitle>
                </div>

            </div>
        </div>
    </div>

</div>