import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service';
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';

@UntilDestroy({ checkProperties: true })

@Injectable({
  providedIn: 'root'
})
export class GamesService {
  user

  myGames = new BehaviorSubject(null)
  myGames$ = this.myGames.asObservable()

  myWishlist = new BehaviorSubject(null)
  myWishlist$ = this.myWishlist.asObservable()

  ourDemoLibrary = new BehaviorSubject(null)
  ourDemoLibrary$ = this.ourDemoLibrary.asObservable()

  ourLendingLibrary = new BehaviorSubject(null)
  ourLendingLibrary$ = this.ourLendingLibrary.asObservable()

  ourUsuallyStocked = new BehaviorSubject(null)
  ourUsuallyStocked$ = this.ourUsuallyStocked.asObservable()

  coreGames = new BehaviorSubject(null)
  coreGames$ = this.coreGames.asObservable()

  getCoreGames() {
    return this.coreGames.value
  }

  constructor(private backendApiGameTrackingService: BackendApiGameTrackingService,
    private backendAPIUsersService: BackendAPIUsersService,
    private backendApiGamesService: BackendAPIGamesService) {

    this.init()
  }

  async init() {
    this.user = await this.initUser()
    this.initCoreGameData()
  }

  async initUser() {
    let currentUserRes
    await this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(async user => {
      if (user) {

        currentUserRes = user

        if (!user.userIsHaven) {
          this.initMyGames(currentUserRes.username)        // need collection and wishlist in here rather than in onInit so that it re-runs if the subscription updates
          this.initMyWishlist(currentUserRes.username)
        } else {
          this.initDemoLibrary(currentUserRes.username)
          this.initLendingLibrary(currentUserRes.username)
          this.initUsuallyStocked(currentUserRes.username)
        }

      }
    })

    return currentUserRes
  }








  async initCoreGameData() {
    let coreGameDataRes = await this.backendApiGamesService.listAllGameCoreData(5000, null)
    this.coreGames.next(coreGameDataRes.coreGames)
    while (coreGameDataRes.nextToken) {
      coreGameDataRes = await this.backendApiGamesService.listAllGameCoreData(5000, coreGameDataRes.nextToken)
      this.coreGames.next(coreGameDataRes.coreGames)
    }

  }










  async initMyGames(username) {
    let myGamesRes = await this.fetchMyGames(username)
    this.updateMyGames(myGamesRes)
  }

  async initMyWishlist(username) {
    let myWishlistRes = await this.fetchMyWishlist(username)
    this.updateMyWishlist(myWishlistRes)
  }

  async initDemoLibrary(username) {
    let demoLibraryRes = await this.fetchDemoLibrary(username)
    this.updateOurDemoLibrary(demoLibraryRes)
  }

  async initLendingLibrary(username) {
    let lendingLibraryRes = await this.fetchLendingLibrary(username)
    this.updateOurLendingLibrary(lendingLibraryRes)
  }

  async initUsuallyStocked(username) {
    let usuallyStockedRes = await this.fetchUsuallyStocked(username)
    this.updateOurUsuallyStocked(usuallyStockedRes)
  }



  // username is optional so that eventually we can fetch for other users if needed?
  async fetchMyGames(username?) {
    let myCollectionRes = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(username ? username : this.user.username, '#owned#', 500)
    return this.reshapeGameCollectionToWorkWithMD2(myCollectionRes.games)
  }

  async fetchMyWishlist(username?) {
    let myWishlistRes = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(username ? username : this.user.usernamee, '#wishlisted#', 500)
    return this.reshapeGameCollectionToWorkWithMD2(myWishlistRes.games)
  }


  async fetchDemoLibrary(username?) {
    let ourDemoLibraryRes = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(username ? username : this.user.usernamee, '#havenDemo#', 500)
    return this.reshapeGameCollectionToWorkWithMD2(ourDemoLibraryRes.games)
  }

  async fetchLendingLibrary(username?) {
    let ourLendingLibraryRes = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(username ? username : this.user.usernamee, '#havenLending#', 500)
    return this.reshapeGameCollectionToWorkWithMD2(ourLendingLibraryRes.games)
  }

  async fetchUsuallyStocked(username?) {
    let ourUsuallyStockedRes = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(username ? username : this.user.usernamee, '#havenUsuallyInStock#', 500)
    return this.reshapeGameCollectionToWorkWithMD2(ourUsuallyStockedRes.games)
  } ourUsuallyStockedRes










  async reshapeGameCollectionToWorkWithMD2(collection) {
    for (let game of collection) {
      game.pk = game.id
    }
    return collection
  }

  // TODO NEED TO INIT OUR DEMO LIBRARY HERE
  // TODO NEED TO INIT OUR LENDING LIBRARY HERE
  // TODO NEED TO INIT OUR USUALLY STOCKED LIBRARY HERE

  updateMyGames(myGames) {
    this.myGames.next(myGames)
  }

  updateMyWishlist(myWishlist) {
    this.myWishlist.next(myWishlist)
  }

  updateOurDemoLibrary(ourDemoLibrary) {
    this.ourDemoLibrary.next(ourDemoLibrary)
  }

  updateOurLendingLibrary(ourLendingLibrary) {
    this.ourLendingLibrary.next(ourLendingLibrary)
  }

  updateOurUsuallyStocked(ourUsuallyStocked) {
    this.ourUsuallyStocked.next(ourUsuallyStocked)
  }





  getIsInMyCollectionByPK(pk: string) {
    let result = false
    if (this.myGames.value != null) {
      if (this.myGames.value.some(g => g.pk === pk)) {
        result = true
      }
    }
    return result
  }

  getIsInMyWishlistByPK(pk: string) {
    let result = false
    if (this.myWishlist.value != null) {
      if (this.myWishlist.value.some(g => g.pk === pk)) {
        result = true
      }
    }
    return result
  }


  getIsOurDemoLibraryByPK(pk: string) {

    let result = false
    if (this.ourDemoLibrary.value != null) {
      if (this.ourDemoLibrary.value.some(g => g.pk === pk)) {
        result = true
      }
    }

    return result
  }


  getIsOurLendingLibraryByPK(pk: string) {
    let result = false
    if (this.ourLendingLibrary.value != null) {
      if (this.ourLendingLibrary.value.some(g => g.pk === pk)) {
        result = true
      }
    }
    return result
  }

  getIsOurUsuallyStockedByPK(pk: string) {
    let result = false
    if (this.ourUsuallyStocked.value != null) {
      if (this.ourUsuallyStocked.value.some(g => g.pk === pk)) {
        result = true
      }
    }
    return result
  }

  getGameMainImageFilesForSingleGame(gameId): string[] {
    let result = null
    for (let game of this.coreGames.value) {
      if (game.pk == gameId) {
        result = game.mainImageFiles
      }
    }
    return result
  }

}
