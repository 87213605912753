import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { MatSelectChange } from '@angular/material/select'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatDialog } from '@angular/material/dialog'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ProfileComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile/profile.component'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-manage-attendance',
    templateUrl: './manage-attendance.component.html',
    styleUrls: ['./manage-attendance.component.scss'],
})
export class ManageAttendanceComponent implements OnInit {
    @Input() inputData: any
    @Input() user: any
    @Input() isGHAdmin: boolean
    isMobileScreen
    // Holds convention details
    conventionDetails = new BehaviorSubject<any>(null)
    conventionDetails$ = this.conventionDetails.asObservable()

    // Holds core users (from some external service)
    coreUsers = new BehaviorSubject<any[]>([])
    coreUsers$ = this.coreUsers.asObservable()

    // Arrays to hold attendee usernames and their titles
    arrayOfAttendees: string[] = []
    arrayOfAttendeesTitles: string[] = []

    // Final fetched user data
    finalUserData: any[] = []

    // Dropdown related
    dropdownOptions = [
        { label: 'Option 1', type: 'type1' },
        { label: 'Option 2', type: 'type2' },
    ]
    selectedTabIndex = 0

    // Data table and filtering
    displayedColumns: string[] = ['title', 'userLevel', 'email', 'state', 'zip']
    dataSource = new MatTableDataSource<any>([])

    @ViewChild(MatSort, { static: true }) sort: MatSort

    constructor(
        private usersService: UsersService,
        private backendApiUserService: BackendAPIUsersService,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        // Load convention details
        this.conventionDetails.next(this.inputData.convention)
        this.arrayOfAttendees = this.conventionDetails.value.attendingUIDArray || []

        // Subscribe to coreUsers from the UsersService
        this.usersService.coreUsers$.pipe(untilDestroyed(this)).subscribe((coreUsersResult) => {
            this.coreUsers.next(coreUsersResult)

            // Map attendees to their corresponding titles
            this.arrayOfAttendeesTitles = this.arrayOfAttendees.map((attendeeUsername) => {
                const matchedUser = this.coreUsers.value.find(
                    (user) => user.username === attendeeUsername,
                )
                return matchedUser ? matchedUser.title : null
            })

            // Now fetch all user data in parallel
            this.initiateUsers()
        })
    }

    async initiateUsers() {
        if (!this.arrayOfAttendeesTitles || !this.arrayOfAttendeesTitles.length) return

        // Fetch all users in parallel by title
        const fetchPromises = this.arrayOfAttendeesTitles.map((title) => {
            // Assume getUserByTitle returns an object with: { title, userLevel, email, state, zip }
            return this.backendApiUserService.getUserByTitle(title)
        })

        try {
            this.finalUserData = await Promise.all(fetchPromises)

            // Set the data for the table
            this.dataSource.data = this.finalUserData
            this.dataSource.sort = this.sort
        } catch (error) {
            console.error('Error fetching user data:', error)
        }
    }

    // Handle dropdown changes
    onDropdownChange(event: MatSelectChange): void {
        this.selectedTabIndex = event.value
        // Implement any additional logic needed upon dropdown change
    }

    // Apply filter to the table based on the user's title
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }

    // Used for dynamic filter label
    getFilterLabel(): string {
        return 'Filter by User Title'
    }

    // Check if filter is enabled
    isFilterEnabled(): boolean {
        return true
    }
    async openUserDetails(userID) {
        let userDetails = await this.backendApiUserService.getProfileById(userID.username)

        let inputData = {
            user: userDetails,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,

            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'User Details',
                component: ProfileComponent,
                inputData: inputData,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                submitButtonText: 'Reply',
                allowParentClose: true,
            },
        })
    }
}
