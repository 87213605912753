import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { GAMES_IMAGE_GALLARY_GRID_DATA } from 'src/app/dashboard/constants/mock-data'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { iThumbnailImage } from '../../../interfaces/media/IThumbnailImage'
import { TimingHelperService } from '../../../services/helpers/timing-helper.service'
import { VideoEmbeddingHelperService } from '../../../services/helpers/video-embedding-helper.service'
import { FullSizedImageViewComponent } from '../full-sized-image-view/full-sized-image-view.component'
import { ImageGalleryGridComponent } from '../image-gallery-grid/image-gallery-grid.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-image-gallery[_sourceOfInput]',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
    // titleForFullSizedDialog = 'anything'
    titleForFullSizedDialog // TODO: set this if incoming images have a title during _thumbs input (commented out line above proves that it works if a title is passed in)

    // captionForFullSizedDialog = 'anything'
    captionForFullSizedDialog // TODO: set this if incoming images have a caption during _thumbs input (commented out line above proves that it works if a caption is passed in)

    @Input() _sourceOfInput: string

    /////////////////////////////////////////////////////////////////////////////////
    // NOTE:
    // I considered reseting to the default image n milliseconds after mouseleave...
    // but Amazon doesn't do this and I'm assuming they know what they are doing
    /////////////////////////////////////////////////////////////////////////////////

    imgGalleryGridData = GAMES_IMAGE_GALLARY_GRID_DATA

    showcaseImg = null
    showcaseIsVideo: boolean = false

    @Output() showAdditionalImagesEmitter = new EventEmitter()

    srcdoc
    videoPath

    thumbs
    @Input() set _thumbs(thumbs: iThumbnailImage[]) {
        if (thumbs) {
            this.thumbs = thumbs
            for (let img of thumbs) {
                if (img.isMainImage) {
                    this.showcaseImg = img
                }
            }
        }
    }


    @Input() _showBottomDivider = false

    constructor(
        private dialog: MatDialog,
        private screenSizeService: ScreenSizeService,

        private timingHelperService: TimingHelperService,
        private videoEmbeddingHelperService: VideoEmbeddingHelperService,
        private backendApiContentService: BackendAPIContentService,
    ) { }

    isMobileScreen = false

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        if (this.thumbs) {
            for (let thumb of this.thumbs) {
                if (thumb.videoPath) {
                    thumb.imgPath = this.videoEmbeddingHelperService.getVideoImage(thumb.videoPath)
                    this.srcdoc = this.videoEmbeddingHelperService.getSrcdoc(
                        thumb.videoPath,
                        'gh-image-gallery-box-image',
                        '50px',
                    )
                    this.videoPath = this.videoEmbeddingHelperService.getVideoPath(thumb.videoPath)
                }
            }
        }
    }

    onHoverImage(event) {
        let index = +event.srcElement.id.replace('thumb', 0)
        if (this.thumbs[index].videoPath) {
            this.showcaseIsVideo = true
        } else {
            this.showcaseIsVideo = false
            this.showcaseImg = this.thumbs[index]
        }
    }

    showAdditionalImages() {
        this.showAdditionalImagesEmitter.emit()
    }

    showFullSize() {
        // todo: deal with video? (NO: Dave says this is way down the line)
        // todo: deal with number of likes both passed in and received!!!
        //! Need to figure out how to force this generic input to adhere to iFullSizeImageDialogInput !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!



        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: this.titleForFullSizedDialog,
                component: FullSizedImageViewComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
                inputData: {
                    itemId: this.captionForFullSizedDialog,
                    image: this.showcaseImg.imgPath[3],
                    numLikes: 22,
                },
            },
            height: '70%',
            width: '90%',
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                // TODO: if like is clicked need to do stuff here
            })
    }
}
