import { Component, Input, OnInit } from '@angular/core'
// import { NguCarouselConfig } from '@ngu/carousel'
import { BehaviorSubject } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { AddGamenightContributionComponent } from './components/add-gamenight-contribution/add-gamenight-contribution.component'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { RequestGamenightContributionComponent } from './components/request-gamenight-contribution/request-gamenight-contribution.component'
import { GameNightContributionTypeEnums } from './enums/gamenight-enums'
import {
    COMMON_FOOD_CONTRIBUTIONS,
    COMMON_BEVERAGE_CONTRIBUTIONS,
} from './constants/gamenight-constants'
import { ArrayHelperService } from '../../../services/helpers/array-helper.service'
import { StringHelper } from '../../../../../../../../common/helpers/string-helper'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-gamenight[_sourceOfInput]',
    templateUrl: './gamenight.component.html',
    styleUrls: ['./gamenight.component.scss'],
})
export class GamenightComponent implements OnInit {
    @Input() _sourceOfInput: string

    commonFoodContributions = COMMON_FOOD_CONTRIBUTIONS
    commonBeverageContributions = COMMON_BEVERAGE_CONTRIBUTIONS

    infoPanelIsOpen = true
    timePlanningPanelIsOpen = false
    hostCollectionPanelIsOpen = false
    guestGamesBroughtPanelIsOpen = false
    foodPanelIsOpen = false
    beveragesPanelIsOpen = false
    forumPanelIsOpen = false

    hostCollection = [
        {
            pk: 'j828',
            title: 'Chess',
            thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F3PGCF9WSX4SS9N26P77K4WR',
        },
        {
            pk: '2zph',
            title: 'Blood Rage',
            thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F2T7MZS1RMWQ09TZVXC8PCMJ',
        },
    ]

    hostCollectionInputData

    carouselConfig = {
        // carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 4, all: 0 },
        load: 3,
        //interval: {timing: 4000, initialDelay: 1000},
        loop: true,
        touch: true,
        velocity: 0.2,
        point: {
            visible: true,
            hideOnSingleSlide: true,
        },
    }

    defaultMainImage = window.location.origin + '/assets/images/defaults/placeholder-event.png'

    user

    //! DATED< USE INPUT DATA INSTEAD!!!
    // event
    // @Input() set _event(event) {
    //   if (event) {
    //     this.event = event

    //     this.attendingList = []

    //     // this.initAttendingList()
    //     // this.initInvitedList()
    //     // this.initGames()
    //     // this.initFood()
    //     // this.initBeverages()
    //   }
    // }

    event
    @Input() set _inputData(inputData) {
        this.event = inputData

        this.initAttendingList()
        this.initInvitedList()
        this.initGames()
        this.initFood()
        this.initBeverages()
    }

    attendingList
    invitedList

    currentUserCollection = []
    guestCollections = [] //! this should NOT include the current users collection!!!!!!!!!

    games = new BehaviorSubject(null)
    games$ = this.games.asObservable()

    food = new BehaviorSubject(null)
    food$ = this.food.asObservable()

    beverages = new BehaviorSubject(null)
    beverages$ = this.beverages.asObservable()

    constructor(
        private backendAPIUsersService: BackendAPIUsersService,
        private arrayHelperService: ArrayHelperService,
        private screenSizeService: ScreenSizeService,

        private dialog: MatDialog,
    ) { }

    isMobileScreen = false

    async ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        await this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                this.user = user

                if (this.user.myCollection && this.user.myCollection.length != 0) {
                    var userCollectionGameIds = []
                    for (let game of this.user.myCollection) {
                        userCollectionGameIds.push(game.id)
                    }

                    // this.gamesDbAccessorService.getMultipleGamesByGSI1(userCollectionGameIds).then((result: any) => {
                    //   for (let game of result.games) {
                    //     this.currentUserCollection.push({
                    //       pk: game.pk,
                    //       title: game.title,
                    //       thumbImgPath: game.thumbImgPath
                    //     })
                    //   }
                    // })
                }


                this.hostCollectionInputData = {
                    user: this.user,
                    event: this.event,
                    hostCollection: this.hostCollection,
                }

                // testing, delete below
                // this.openTimeManager()
            })
    }

    async initAttendingList() {
        //! 2023 update: WTF does this following line mean???
        // this.attendingList = { rawData: [] = [], str: '' }

        let attendingList = this.event.attendingList ? this.event.attendingList : null
        if (attendingList == null) {
            return
        }

        for (let uid of attendingList) {
            await this.backendAPIUsersService.getNonCurrentUserBulkData(uid).then((result) => {
                this.attendingList.rawData.push(result)
            })
        }

        if (this.attendingList) {
            this.attendingList.str = StringHelper.createCommaSeparatedString(this.attendingList.rawData, true)

            this.guestCollections = []
            for (let user of this.attendingList.rawData) {
                if (user.pk != this.user.pk) {
                    var userCollectionIdsOnly = []
                    for (let game of user.myCollection) {
                        userCollectionIdsOnly.push(game.id)
                    }

                    // this.gamesDbAccessorService.getMultipleGamesByGSI1(userCollectionIdsOnly).then(result => {
                    //   this.guestCollections.push({
                    //     pk: user.pk,
                    //     title: user.title,
                    //     thumbImgPath: user.profileImages[0], // TODO: user bulk data should have thumbImgPath ???
                    //     tier1Items: result.games
                    //   })
                    // })
                }
            }
        }
    }

    async initInvitedList() {
        this.invitedList = { data: ([] = []), str: '' }

        for (let uid of this.event.invitedList) {
            await this.backendAPIUsersService.getNonCurrentUserBulkData(uid).then((result) => {
                this.invitedList.data.push(result)
            })
        }
        this.invitedList.str = StringHelper.createCommaSeparatedString(this.invitedList.data, true)
    }

    truncate(input: string, maxCharacters: number) {
        return StringHelper.truncateString(input, maxCharacters)
    }

    getUserNamesString(users) {
        if (users && users.length != 0) {
            return StringHelper.createCommaSeparatedString(users, true)
        }
    }

    async initGames() {
        var games = []
        let eventGames = this.event.games
        if (eventGames == null) {
            return
        }

        for (var j = 0; j < eventGames.length; j++) {
            var game
            var contributor = null
            var requestors = []

            // await this.gamesDbAccessorService.getBulkData(eventGames[j].pk).then(result => {
            //   game = result
            // })

            if (eventGames[j].contributorId != '') {
                if (this.user && eventGames[j].contributorId == this.user.pk) {
                    // don't make unessesary db call if contributor is current user
                    contributor = this.user
                } else {
                    await this.backendAPIUsersService
                        .getNonCurrentUserBulkData(eventGames[j].contributorId)
                        .then((result) => {
                            contributor = result
                        })
                }
            } else {
                // ! start refactoring here
                if (this.user && eventGames[j].requestorIds.includes(this.user.pk)) {
                    // don't make unessesary db call if contributor is current user
                    requestors.push(this.user)
                }
                for (let requestorId of eventGames[j].requestorIds) {
                    if (requestorId != this.user.pk) {
                        await this.backendAPIUsersService
                            .getNonCurrentUserBulkData(requestorId)
                            .then((result) => {
                                requestors.push(result)
                            })
                    }
                }
            }

            let gamesItem = {
                game: game,
                contributor: contributor,
                requestors: requestors,
                isCommitted: eventGames[j].isCommitted,
            }
            games.push(gamesItem)
        }
        this.games.next(games)
    }

    async initFood() {
        var food = []
        let eventFood = this.event.food
        if (eventFood == null) {
            return
        }
        for (var j = 0; j < eventFood.length; j++) {
            // var foodItemTitle = eventFood[j].title
            var contributor = null
            var requestor = null

            if (eventFood[j].contributorId != '') {
                if (this.user && eventFood[j].contributorId == this.user.pk) {
                    // don't make unessesary db call if contributor is current user
                    contributor = this.user
                } else {
                    await this.backendAPIUsersService
                        .getNonCurrentUserBulkData(eventFood[j].contributorId)
                        .then((result) => {
                            contributor = result
                        })
                }
            } else {
                if (this.user && eventFood[j].requestorId == this.user.pk) {
                    // don't make unessesary db call if contributor is current user
                    requestor = this.user
                } else {
                    await this.backendAPIUsersService
                        .getNonCurrentUserBulkData(eventFood[j].requestorId)
                        .then((result) => {
                            requestor = result
                        })
                }
            }

            let foodItem = {
                // title: foodItemTitle,
                food: eventFood[j],
                contributor: contributor,
                requestor: requestor,
                isCommitted: eventFood[j].isCommitted,
            }

            food.push(foodItem)
        }
        this.food.next(food)

        // ! this works, but is behavior subject so need to adjust either here or in parent component...????
    }

    async initBeverages() {
        var beverages = []
        let eventBeverages = this.event.beverages ? this.event.beverages : null
        if (eventBeverages == null) {
            return
        }
        for (var j = 0; j < eventBeverages.length; j++) {
            var contributor = null
            var requestor = null

            if (eventBeverages[j].contributorId != '') {
                if (this.user && eventBeverages[j].contributorId == this.user.pk) {
                    // don't make unessesary db call if contributor is current user
                    contributor = this.user
                } else {
                    await this.backendAPIUsersService
                        .getNonCurrentUserBulkData(eventBeverages[j].contributorId)
                        .then((result) => {
                            contributor = result
                        })
                }
            } else {
                if (this.user && eventBeverages[j].requestorId == this.user.pk) {
                    // don't make unessesary db call if contributor is current user
                    requestor = this.user
                } else {
                    await this.backendAPIUsersService
                        .getNonCurrentUserBulkData(eventBeverages[j].requestorId)
                        .then((result) => {
                            requestor = result
                        })
                }
            }

            let beveragesItem = {
                beverage: eventBeverages[j],
                contributor: contributor,
                requestor: requestor,
                isCommitted: eventBeverages[j].isCommitted,
            }
            beverages.push(beveragesItem)
        }
        this.beverages.next(beverages)
    }

    addMyGames(event) {
        let games = this.games.value
        for (let gameToAdd of event.tier0Items) {
            let gamesItem = {
                game: gameToAdd,
                contributor: this.user,
                requestor: null,
                isCommitted: true,
            }
            games.push(gamesItem)
        }
        this.games.next(games) // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!
    }

    requestGames(event) {
        var currentGames = this.games.value

        var requestedGames = []
        for (let item of event.tier1Items) {
            let gamesItem = {
                game: {
                    pk: item.pk,
                    title: item.title,
                    thumbImgPath: item.thumbImgPath,
                },
                contributor: null,
                requestors: item.requestors,
                isCommitted: false,
            }
            requestedGames.push(gamesItem)
        }

        var removalFlag = false
        for (let game of this.games.value) {
            if (game.requestors.includes(this.user)) {
                // user is only requestor and removed thier request
                if (requestedGames.length == 0 && game.requestors.length == 1) {
                    this.removeGame(game)
                }
                // user is NOT the only requestor and removed thier request
                else if (!requestedGames.some((g) => g.pk === game.game.pk)) {
                    removalFlag = true
                    game.requestors = this.arrayHelperService.removeFromArray(
                        game.requestors,
                        this.user,
                    )
                }
            }
        }

        for (let requestedGame of requestedGames) {
            // game is not already in list
            if (!this.games.value.some((game) => game.game.pk === requestedGame.game.pk)) {
                requestedGame.requestors = [this.user]
                currentGames.push(requestedGame)
            }

            // game is already in the list, so need to update the requestors
            else {
                for (let gameAlreadyInList of this.games.value) {
                    if (!removalFlag && requestedGame.game.pk == gameAlreadyInList.game.pk) {
                        if (!gameAlreadyInList.requestors.some((r) => r.pk === this.user.pk)) {
                            gameAlreadyInList.requestors.push(this.user)
                        }
                    }
                }
            }
        }
        // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!
    }

    removeGame(item) {
        // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!

        // could user array helper service method to do this but need to make it more generic
        let currentGameItems = this.arrayHelperService.removeFromArray(this.games.value, item)
        var indexToRemove = -1
        for (var i = 0; i < currentGameItems.length; i++) {
            if (currentGameItems[i].game.pk == item.game.pk) {
                indexToRemove = i
            }
        }

        if (indexToRemove != -1) {
            currentGameItems.splice(indexToRemove, 1)
            this.games.next(currentGameItems)
        }
    }

    fulfillRequest(item) {
        // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!
        item.isCommitted = true
        item.contributor = this.user
    }

    cancelRequestFulfillment(item) {
        // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!
        item.isCommitted = false
        item.contributor = null
    }

    addMyFood() {
        let inputData = {
            user: this.user, //? could have recent food items as suggestions? for now just letting user input items manually...
            contributionType: GameNightContributionTypeEnums.food,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Add My Food',
                component: AddGamenightContributionComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            maxWidth: '30%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                let food = this.food.value
                for (let foodToAdd of data) {
                    let foodItem = {
                        food: foodToAdd,
                        contributor: this.user,
                        requestor: null,
                        isCommitted: true,
                    }
                    food.push(foodItem)
                }
                this.food.next(food) // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!
                // TODO: open snackbar here
            }
        })
    }

    requestFood() {
        let inputData = {
            user: this.user,
            contributionType: GameNightContributionTypeEnums.food,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Request Food',
                component: RequestGamenightContributionComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            maxWidth: '30%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                let food = this.food.value
                for (let foodToAdd of data) {
                    let foodItem = {
                        food: foodToAdd,
                        contributor: null,
                        requestor: this.user,
                        isCommitted: false,
                    }
                    food.push(foodItem)
                }
                this.food.next(food) // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!

                // TODO: open snackbar here
            }
        })
    }

    removeFood(item) {
        // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!
        let currentFoodItems = this.food.value
        var indexToRemove = -1
        for (var i = 0; i < currentFoodItems.length; i++) {
            if (currentFoodItems[i].food.itemId == item.food.itemId) {
                indexToRemove = i
            }
        }

        if (indexToRemove != -1) {
            currentFoodItems.splice(indexToRemove, 1)
            this.food.next(currentFoodItems)
        }
    }

    addMyBeverages() {
        let inputData = {
            user: this.user, //? could have recent beverage items as suggestions? for now just letting user input items manually...
            contributionType: GameNightContributionTypeEnums.beverages,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Add My Beverage(s)',
                component: AddGamenightContributionComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            maxWidth: '30%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                let beverages = this.beverages.value
                for (let beverageToAdd of data) {
                    let foodItem = {
                        beverage: beverageToAdd,
                        contributor: this.user,
                        requestor: null,
                        isCommitted: true,
                    }
                    beverages.push(foodItem)
                }
                this.beverages.next(beverages) // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!
                // TODO: open snackbar here
            }
        })
    }

    requestBeverages() {
        let inputData = {
            user: this.user,
            contributionType: GameNightContributionTypeEnums.beverages,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Request Beverages',
                component: RequestGamenightContributionComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            maxWidth: '30%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                let beverages = this.beverages.value
                for (let beveragesToAdd of data) {
                    let beveragesItem = {
                        beverage: beveragesToAdd,
                        contributor: null,
                        requestor: this.user,
                        isCommitted: false,
                    }
                    beverages.push(beveragesItem)
                }
                this.beverages.next(beverages) // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!

                // TODO: open snackbar here
            }
        })
    }

    removeBeverage(item) {
        // TODO: Note that this doesnt save the changes to the DB!!!!!!!!!!!!!!!!!!!!!!
        let currentBeverageItems = this.beverages.value
        var indexToRemove = -1
        for (var i = 0; i < currentBeverageItems.length; i++) {
            if (currentBeverageItems[i].beverage.itemId == item.beverage.itemId) {
                indexToRemove = i
            }
        }

        if (indexToRemove != -1) {
            currentBeverageItems.splice(indexToRemove, 1)
            this.beverages.next(currentBeverageItems)
        }
    }
}
