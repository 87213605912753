import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { US_STATES_NAMES_ONLY } from '../../../constants/shared.constants';

@Component({
  selector: 'app-gh-state-input',
  templateUrl: './gh-state-input.component.html',
  styleUrls: ['./gh-state-input.component.scss']
})
export class GhStateInputComponent implements OnInit {

  stateOptions = US_STATES_NAMES_ONLY

  state
  @Input() set _defaultState(defaultState) {
    this.state = defaultState
  }

  @Output() stateChangedEmitter = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  setState(event) {
    this.state = event.value
    this.stateChangedEmitter.emit(this.state)
  }




  compareFunction(o1: any, o2: any) {
    return (o1 == o2)
  }

}
