import { Injectable } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Auth } from 'aws-amplify'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import gql from 'graphql-tag'
import { BehaviorSubject } from 'rxjs'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { environment } from 'src/environments/environment'
import { HavensService } from '../havens/havens.service'
import { BackendApiS3Service } from 'src/app/backend-api-services/backend-api-s3.service'
@UntilDestroy({ checkProperties: true })
@Injectable({
    providedIn: 'root',
})
export class ContentService {
    myRatings = new BehaviorSubject(null)
    myRatings$ = this.myRatings.asObservable()

    myReviews = new BehaviorSubject(null)
    myReviews$ = this.myReviews.asObservable()

    constructor(
        private backendApiGameTrackingService: BackendApiGameTrackingService,
        private backendApiUsersService: BackendAPIUsersService,
        private havensService: HavensService,
        private backendApiS3Service: BackendApiS3Service,
    ) {
        this.fetchCurrentUserAllContent()
    }

    user

    async fetchCurrentUserAllContent() {
        this.backendApiUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe(async (user) => {
                if (user) {
                    this.user = user
                    await this.fetchCurrentUserRatings()
                    await this.fetchCurrentUserReviews()
                }
            })
    }

    async fetchCurrentUserRatings() {
        let myRatingsResult = await this.backendApiGameTrackingService.listUserRatingsByID(
            this.user.username,
            500,
        )
        this.updateMyRatings(myRatingsResult.games)
    }

    coreHavens
    async fetchCurrentUserReviews() {
        let myContentResult = await this.backendApiGameTrackingService.listMyContentByType(
            this.user.username,
            'review',
            500,
        )
        let coreHavens = this.havensService.coreHavens$

        let reviews = myContentResult.content

        this.havensService.coreHavens$.pipe(untilDestroyed(this)).subscribe(async (coreHavens) => {
            if (coreHavens) {
                this.coreHavens = coreHavens
                for (let review of reviews) {
                    for (let haven of this.coreHavens) {
                        if (review.havenToReview == haven.id) {
                            review.havenTitle = haven.title
                            break
                        }
                    }
                }
            }
            this.updateMyReviews(myContentResult.content)
        })
    }

    updateMyRatings(myRatings) {
        for (let item of myRatings) {
            if (!item.rating || item.rating == null) {
                item.rating = -1
            }
        }
        this.myRatings.next(myRatings)
    }

    updateMyReviews(myReviews) {
        this.myReviews.next(myReviews)
    }

    getUserRatingsValue() {
        return this.myRatings.value
    }

    deleteS3ItemsWithImgPath(imgPath) {
        let cleanOrigional = imgPath
            .replace('https://gh-img.s3.amazonaws.com/', '')
            .replace('_S.jpg', '')

        let origionalInputS = {
            bucketName: 'gh-img',
            objectKey: cleanOrigional + '_S.jpg',
        }
        this.backendApiS3Service.deleteS3Object(origionalInputS)

        let origionalInputM = {
            bucketName: 'gh-img',
            objectKey: cleanOrigional + '_M.jpg',
        }
        this.backendApiS3Service.deleteS3Object(origionalInputM)

        let origionalInputL = {
            bucketName: 'gh-img',
            objectKey: cleanOrigional + '_L.jpg',
        }
        this.backendApiS3Service.deleteS3Object(origionalInputL)

        let origionalInputXL = {
            bucketName: 'gh-img',
            objectKey: cleanOrigional + '_XL.jpg',
        }
        this.backendApiS3Service.deleteS3Object(origionalInputXL)

        // We are no longer storing the img-o files
        // let origionalInput = {
        //     bucketName: 'gh-img-o',
        //     objectKey: cleanOrigional
        // }
        // let carl = this.backendApiS3Service.deleteS3Object(origionalInput)
    }
}
