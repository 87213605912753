<div class="mt-3 parent-container" style="display: flex; width: 100%">
    <div class="tabs-section">
        <app-vertical-tabs
            [_tabs]="tabs"
            (tabChanged)="onTabChange($event)"
            (shouldRefreshContentComponentEmitter)="refreshContentComponent()"
            [_sourceOfInput]="'app-profile-favorites'"
        ></app-vertical-tabs>
    </div>

    <div class="content-section" style="flex: 1; margin-left: 100px; width: 100%">
        <ng-container *ngIf="currentTabIndex === 0">
            <app-manage-scheduled-plays
                [inputData]="inputData"
                [user]="user"
                [isGHAdmin]="isGHAdmin"
                style="display: block; width: 100%"
            ></app-manage-scheduled-plays>
        </ng-container>

        <ng-container *ngIf="currentTabIndex === 1">
            <app-manage-game-library
                [inputData]="inputData"
                [user]="user"
                [isGHAdmin]="isGHAdmin"
                style="display: block; width: 100%"
            ></app-manage-game-library>
        </ng-container>

        <ng-container *ngIf="currentTabIndex === 2">
            <app-manage-game-interest
                [inputData]="inputData"
                [user]="user"
                [isGHAdmin]="isGHAdmin"
                style="display: block; width: 100%"
            ></app-manage-game-interest>
        </ng-container>

        <ng-container *ngIf="currentTabIndex === 3">
            <app-manage-attendance
                [inputData]="inputData"
                [user]="user"
                [isGHAdmin]="isGHAdmin"
                style="display: block; width: 100%"
            ></app-manage-attendance>
        </ng-container>
    </div>
</div>
