import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { SPECIAL_CHARACTERS } from 'src/app/shared/constants/lists-constants'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  passwordFieldIsFocused = true
  passwordLength = 0
  submitIsClicked = false

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  passwordForm: UntypedFormGroup

  constructor(private dialogRef: MatDialogRef<ResetPasswordComponent>,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { validator: this.mustMatch('password', 'confirmPassword') })


    this.passwordLength = this.passwordForm.get('password').value.length
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName]
      const matchingControl = formGroup.controls[matchingControlName]

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true })
      } else {
        matchingControl.setErrors(null)
      }
    }
  }

  getRequirementIsSatisfiedColor(type) { // TODO: create enum for type
    switch (type) {
      case 'lowercaseLetter':
        return this.checkIfPasswordContainsLowercaseCharacter() ? 'green' : 'red'
      case 'uppercaseLetter':
        return this.checkIfPasswordContainsUppercaseCharacter() ? 'green' : 'red'
      case 'number':
        return this.checkIfPasswordContainsNumber() ? 'green' : 'red'
      case 'specialCharacter':
        return this.checkIfPasswordContainsSpecialCharacter() ? 'green' : 'red'
      case 'specialCharacter':
        return this.passwordForm.get('password').value.length >= 8 ? 'green' : 'red'
    }
  }

  getRequirementIsSatisfied(type) { // TODO: create enum for type
    switch (type) {
      case 'lowercaseLetter':
        return this.checkIfPasswordContainsLowercaseCharacter()
      case 'uppercaseLetter':
        return this.checkIfPasswordContainsUppercaseCharacter()
      case 'number':
        return this.checkIfPasswordContainsNumber()
      case 'specialCharacter':
        return this.checkIfPasswordContainsSpecialCharacter()
      case 'length':
        return this.passwordForm.get('password').value.length ? this.passwordForm.get('password').value.length >= 8 : 0
    }
  }

  checkIfPasswordContainsLowercaseCharacter() {
    let currentlyEnteredPassword = this.passwordForm.get('password').value
    var containsLowercaseCharacter: boolean = false
    for (var i = 0; i < currentlyEnteredPassword.length; i++) {
      if (currentlyEnteredPassword.charAt(i) == currentlyEnteredPassword.charAt(i).toLowerCase() && !this.checkIfCharacterIsNumber(currentlyEnteredPassword.charAt(i)) && !SPECIAL_CHARACTERS.includes(currentlyEnteredPassword.charAt(i))) {
        containsLowercaseCharacter = true
      }
    }
    return containsLowercaseCharacter
  }

  checkIfPasswordContainsUppercaseCharacter() {
    let currentlyEnteredPassword = this.passwordForm.get('password').value
    var containsUppercaseCharacter: boolean = false
    for (var i = 0; i < currentlyEnteredPassword.length; i++) {
      if (currentlyEnteredPassword.charAt(i) == currentlyEnteredPassword.charAt(i).toUpperCase() && !this.checkIfCharacterIsNumber(currentlyEnteredPassword.charAt(i)) && !SPECIAL_CHARACTERS.includes(currentlyEnteredPassword.charAt(i))) {
        containsUppercaseCharacter = true
      }
    }
    return containsUppercaseCharacter
  }

  checkIfPasswordContainsNumber() {
    let currentlyEnteredPassword = this.passwordForm.get('password').value
    var containsNumber: boolean = false
    for (var i = 0; i < currentlyEnteredPassword.length; i++) {
      if (this.checkIfCharacterIsNumber(currentlyEnteredPassword.charAt(i))) {
        containsNumber = true
      }
    }
    return containsNumber
  }

  checkIfPasswordContainsSpecialCharacter() {
    let currentlyEnteredPassword = this.passwordForm.get('password').value
    var containsSpecialCharacter: boolean = false
    for (var i = 0; i < currentlyEnteredPassword.length; i++) {
      if (SPECIAL_CHARACTERS.includes(currentlyEnteredPassword.charAt(i))) {
        containsSpecialCharacter = true
      }
    }
    return containsSpecialCharacter
  }

  checkIfCharacterIsNumber(value) {
    return value >= '0' && value <= '9'
  }

  onPasswordFocus() {
    this.passwordFieldIsFocused = true
  }

  onPasswordBlur() {
    this.passwordFieldIsFocused = false
  }

  submit() {
    this.dialogRef.close(this.passwordForm)
  }

}
