import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SidebarService } from '../dashboard/dashboard-shared/services/sidebar/sidebar.service'

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private sidebarService: SidebarService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  openAd() {
    
      //! Important: router.navigate must come after sidebarService.updateSelectedItem, otherwise the route param doesn't work
      this.sidebarService.updateSelectedItem('FEEDBACK')
      this.router.navigate(['/dashboard/feedback/feedback-dashboard/2'])
  }

}
