

export interface iAddToMyGroupsInput {
  groupId: string
  title: string
  UID: string
  type: string
  userTitle: string
  userState: string
  userZipCode: number

  // mainImageFiles: string[]
}

export interface iDeleteHavensWeLoveInput {
  havenId: string
  UID: string
  type: string
  title: string
}
export interface iRemoveFromMyGroupsInput {
  groupId: string
  userId: string
  type: string
}
export interface iAddToHavensWeLoveInput {
  havenId: string
  UID: string
  type: string
  userState: string
  userZipCode: number
  title: string
  userTitle: string
  mainImageFiles: string[]
}




export interface iAddToGamesWeLoveInput {
  gameId: string
  UID: string
  type: string
  userState: string
  userZipCode: number
  title: string
  userTitle: string
  mainImageFiles: string[]
}

export interface iDeleteGamesWeLoveInput {
  gameId: string
  UID: string
  type: string
  title: string
}