import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    Input,
    ChangeDetectorRef,
} from '@angular/core'
import { GameDetailAnimations } from './animations/game-detail-animations'

import { ActivatedRoute } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { IconSnackbarComponent } from 'src/app/dashboard/dashboard-shared/components/custom-snackbars/icon-snackbar/icon-snackbar.component'
import { AddOrEditGameComponent } from 'src/app/dashboard/dashboard-shared/components/games/add-or-edit-game/add-or-edit-game.component'
import { HopperComponent } from 'src/app/dashboard/dashboard-shared/components/hopper/hopper/hopper.component'
import { ImageZoomGalleryComponent } from 'src/app/dashboard/dashboard-shared/components/media/image-zoom-gallery/image-zoom-gallery.component'
import { ConfirmActionComponent } from 'src/app/dashboard/dashboard-shared/components/modals/confirm-action/confirm-action.component'
import { HOPPER_DISPLAYED_COLUMNS_GAME_SPECIFIC } from 'src/app/dashboard/dashboard-shared/constants/hopper-constants'
import { BannerLocationEnums } from 'src/app/dashboard/dashboard-shared/enums/banner-location-enums'
import { BannerSizeEnums } from 'src/app/dashboard/dashboard-shared/enums/banner-size-enums'
import { HopperTypeEnums } from 'src/app/dashboard/dashboard-shared/enums/hopper-enums'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { MdService } from 'src/app/dashboard/dashboard-shared/services/master-detail/master-detail.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { TaPublisherDescriptionComponent } from '../../ta-components/ta-publisher-description/ta-publisher-description.component'
import { TaReviewsComponent } from '../../ta-components/ta-reviews/ta-reviews.component'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { TaMapComponent } from '../../ta-components/ta-map/ta-map.component'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { ContentService } from 'src/app/dashboard/dashboard-shared/services/content/content.service'
import {
    RadarChartOverlayTypeEnums,
    RadarChartTypeEnums,
} from 'src/app/dashboard/enums/radar-chart-enums'
import { RADAR_CHART_PROFILE_FRIEND_GAME_LABELS } from 'src/app/dashboard/constants/radar-chart-constants'
import { BannerAdService } from 'src/app/dashboard/dashboard-shared/services/ads/banner-ad.service'
import { HavenCollectionSpecificLocationModalComponent } from 'src/app/dashboard/dashboard-shared/components/havens/haven-collection-specific-location-modal/haven-collection-specific-location-modal.component'
import { GamesFindInterestNearYouComponent } from 'src/app/dashboard/dashboard-shared/components/games/games-find-interest-near-you/games-find-interest-near-you.component'
import { TaRecentGamePlaysComponent } from '../../ta-components/ta-recent-game-plays/ta-recent-game-plays.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { TaContentComponent } from '../../ta-components/ta-content/ta-content.component'
import { ImageGalleryGridComponent } from 'src/app/dashboard/dashboard-shared/components/media/image-gallery-grid/image-gallery-grid.component'
import { ImageGalleryGridService } from 'src/app/dashboard/dashboard-shared/services/media/image-gallery-grid.service'
import { OnImageActionType } from 'src/app/dashboard/dashboard-shared/enums/media-enums'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

export const DELETE_CONFIRMATION_PASSWORD = 'confirm'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-md-game-detail',
    templateUrl: './md-game-detail.component.html',
    styleUrls: ['./md-game-detail.component.scss'],
    animations: [
        GameDetailAnimations.addToMyGamesTrigger,
        GameDetailAnimations.addToWishlistTrigger,
        GameDetailAnimations.fadeInImage,
        GameDetailAnimations.fadeInSlowlyImage,
        GameDetailAnimations.slideUp,
    ],
})
export class MdGameDetailComponent implements OnInit {
    isGHAdmin = false
    userIsHaven = false

    bannerAdInputDataNonSpecific = {
        bannerSizeEnum: BannerSizeEnums.banner200x200,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        specificAds: null,
        isIFrame: false,
    }


    bannerAdInputDataSpecific1 = new BehaviorSubject({
        bannerSizeEnum: BannerSizeEnums.banner200x200,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        specificAds: [{ link: '' }],
        isIFrame: true,
    })
    bannerAdInputDataSpecific1$ = this.bannerAdInputDataSpecific1.asObservable()

    bannerAdInputDataSpecific2 = new BehaviorSubject({
        bannerSizeEnum: BannerSizeEnums.banner300x250,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        specificAds: [{ link: '' }],
        isIFrame: true,
    })
    bannerAdInputDataSpecific2$ = this.bannerAdInputDataSpecific2.asObservable()

    bannerAdInputDataSpecific3 = new BehaviorSubject({
        bannerSizeEnum: BannerSizeEnums.banner300x250,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        specificAds: [{ link: '' }],
        isIFrame: true,
    })
    bannerAdInputDataSpecific3$ = this.bannerAdInputDataSpecific3.asObservable()

    @ViewChild('section1') section1
    @ViewChild('section2') section2
    @ViewChild('section3') section3

    gamesSeekingPlayersMockData = 6 // to be replaced with Db data
    totalPlayersNeededMockData = 14 // to be replaced with Db data
    recentGamesMockData = 42 // to be replaced with Db data
    timeSpanMockData = 2 // to be replaced with Db data

    hopperColumns = HOPPER_DISPLAYED_COLUMNS_GAME_SPECIFIC
    hopperInputData = new BehaviorSubject(null)
    hopperInputData$ = this.hopperInputData.asObservable()
    hopperType = HopperTypeEnums.games

    user

    isLoading = true //TODO Implement
    opened: boolean = false
    selectedTab
    sideNavContent = 'gameComponents'

    gameIsCurrentlyCrowdfunding = false
    isInDevelopment = false
    isMobileScreen = false


    taTitles = ['Publisher Description', 'Reviews', 'Content', 'Recent Plays']

    aAdOne

    // mobile accordion items are the mobile version of desktop tabbed accordion
    selectedMobileAccordionIndex = null
    mobileAccordionItems = ['Reviews', 'Content', 'Publisher Description', 'Recent Plays']

    matchPercent

    userRating

    addToMyGamesButtonState: string = 'addToMyGamesAnimIdle'
    addToWishlistButtonState: string = 'addToWishlistAnimIdle'
    addToOurDemoLibraryButtonState: string = 'addToDemoLibraryAnimIdle'
    addToOurLendingLibraryButtonState: string = 'addToLendingLibraryAnimIdle'
    addToUsuallyStockedButtonState: string = 'addToUsuallyStockedAnimIdle'

    isInMyCollection = new BehaviorSubject(false)
    isInMyCollection$ = this.isInMyCollection.asObservable()

    isInMyWishlist = new BehaviorSubject(false)
    isInMyWishlist$ = this.isInMyWishlist.asObservable()

    isInDemoLibrary = new BehaviorSubject(false)
    isInDemoLibrary$ = this.isInDemoLibrary.asObservable()

    isInLendingLibrary = new BehaviorSubject(false)
    isInLendingLibrary$ = this.isInLendingLibrary.asObservable()

    isInUsuallyStocked = new BehaviorSubject(false)
    isInUsuallyStocked$ = this.isInUsuallyStocked.asObservable()

    selectedGame = null
    selectedGameIsUndefined = false

    taComponentsAreSet = false

    taComponent0 = { component: TaPublisherDescriptionComponent, data: { gameId: null } }
    taComponent1 = {
        component: TaReviewsComponent,
        data: { game: null, myStarRating: null, origin: null },
    }
    taComponent2 = {
        component: TaContentComponent,
        data: { gameId: null, title: null, shouldLoad: true },
    }

    taComponent3 = { component: TaRecentGamePlaysComponent, data: { gameId: null } }
    // taComponent4 = { component: TaMapComponent, data: { gameId: null } }

    // taComponent4 = { component: TaMapComponent, data: { gameId: null } }
    // taComponent3 = { component: TaRulesForumComponent, data: { gameId: null } }
    // taComponent2 = { component: TaSimilarGamesComponent, data: { gameId: null } }

    mainImage: string[] = []
    additionalImages = []
    thumbs = new BehaviorSubject(null) //? iThumbnailImage[] how to force a type on a behavior subject?
    thumbs$ = this.thumbs.asObservable()

    gameIsLended = false
    gameIsBorrowed = false

    lendedTooltip
    borrowedTooltip

    bulkDataIsSet = false
    mainImageIsSet = false
    additionalImagesAreSet = false

    myRatings = null
    selectedGameRatingItem = null

    limitDataFecthing = false
    inputData
    aAaOneClean: string
    similarGames: any
    aAdTwo: any
    aAdThree: any
    @Input() set _inputData(inputData) {

        console.log('GAME INPUT DATA', inputData)
        // todo accessing this directly by Games Tab doesn't have input.
        // ! never firing????

        this.inputData = inputData
        if (inputData.limitDataFecthing) {
            this.limitDataFecthing = inputData.limitDataFecthing
        }
    }

    @Output() navbarOpened = new EventEmitter()

    @Output() changeTab = new EventEmitter()

    constructor(
        protected activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private mdService: MdService,
        private screenSizeService: ScreenSizeService,
        private snackbar: MatSnackBar,
        private backendAPIUsersService: BackendAPIUsersService,
        private backendApiGamesService: BackendAPIGamesService,
        private backendApiContentService: BackendAPIContentService,
        private backendApiGameTrackingService: BackendApiGameTrackingService,
        private gamesService: GamesService,
        private contentService: ContentService,
        private changeDetectorRef: ChangeDetectorRef,
        private bannerAdService: BannerAdService,
        private snackbarService: SnackbarService,
        private imageGalleryGridService: ImageGalleryGridService,

    ) {
        changeDetectorRef.detach()
        setInterval(() => {
            this.changeDetectorRef.detectChanges()
        }, 500)
    }

    radarData
    radarOverlayType = RadarChartOverlayTypeEnums.myAttributes
    radarChartType = RadarChartTypeEnums.game
    myUserGameQualities

    userHasVotedForBig8 = false
    usersBig8VoteForThisGame

    async ngOnInit() {
        this.isLoading = true

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        await this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                try {
                    this.user = user
                    this.isGHAdmin = this.user.userLevel > 6
                    this.userIsHaven = this.user.userIsHaven
                } catch (error) {
                    console.log('ERROR: ', error)
                }
            })

        this.contentService.myRatings$.pipe(untilDestroyed(this)).subscribe((ratings) => {
            if (ratings) {
                this.myRatings = ratings
                this.setSelectedGameRating()
            }
        })

        this.myUserGameQualities = await this.backendAPIUsersService.getUserGameQualitiesById(
            this.user.username,
        )

        // todo: should we just fetch ALL the users big8 votes? Subscription?
        // todo: a Mapping Template already exists for this.

        this.backendApiGameTrackingService.fetchGamePreviousRatingData
        if (!this.inputData) {
            this.mdService.selectedItem$
                .pipe(untilDestroyed(this))
                .subscribe(async (selectedGame: any) => {

                    this.selectedGameIsUndefined = selectedGame == 'emptylist'

                    //! dont call this if input is true??? need to deal with a specific game coming in as input (eg. GameDetailPreviewModalComponent -> here with passed data)
                    //!     could pass data from GameDetailPreviewModalComponent into md-service, but that doesn't feel right...
                    //!       need to deal with conditional logic based on if there is input and where it came from instead...

                    if (selectedGame && selectedGame != null) {
console.log('sel game', selectedGame)
                        let gameDetails = await this.backendApiGamesService.GetGame(selectedGame.pk)
                        await this.setSelectedGame(gameDetails)

                        this.shouldInitRadarData = true

                        this.setSelectedGameRating()
                        this.setTaComponentData()

                        this.radarData = {
                            labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
                            //! return this testing below attributes: this.selectedGame.big8,
                            attributes: JSON.parse(this.selectedGame.big8), // todo simulating fetch of game Big8
                            // overlayAttributes: this.user.radarAttributes // TODO: FETCH USER DATA
                            // overlayAttributes: [8, 6, 5, 3, 4, 7, 5, 9] // TODO: FETCH USER DATA
                            numberOfVotes: this.selectedGame.big8Votes,
                            overlayAttributes: JSON.parse(this.myUserGameQualities.userGameQualities), // TODO: FETCH USER DATA
                        }
                    }
                })
        } else {
            // means that this component is opened as a modal so fetch details rather than watching md service
            this.setSelectedGame(this.inputData.game)
            this.setSelectedGameRating()
            this.setTaComponentData()
        }

        this.matchPercent = 92 // TODO: move this to mini radar chart and implement Bens math!!!
    }

    shouldInitRadarData = true
    ngAfterViewChecked() {
        if (this.shouldInitRadarData) {
            if (this.user && this.selectedGame) {
                this.shouldInitRadarData = false

                // this.radarData = {
                //   labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
                //   //! return this testing below attributes: this.selectedGame.big8,
                //   attributes: JSON.parse(this.selectedGame.big8), // todo simulating fetch of game Big8
                //   // overlayAttributes: this.user.radarAttributes // TODO: FETCH USER DATA
                //   // overlayAttributes: [8, 6, 5, 3, 4, 7, 5, 9] // TODO: FETCH USER DATA
                //   overlayAttributes: this.myUserGameQualities.userGameQualities // TODO: FETCH USER DATA
                // }
            }
        }
    }

    onGameAttributesRated(usersAttributeRating) { }

    async setSelectedGameRating() {
        // setting the rating of selectedGameRatingItem to null so that if a match is not found then it is defaulted to "not rated"
        this.selectedGameRatingItem = {
            rating: null,
        }

        if (this.myRatings != null && this.selectedGame != null) {
            for (let rating of this.myRatings) {
                if (rating.id == this.selectedGame.id) {
                    this.selectedGameRatingItem = rating
                }
            }
        }
    }

    setTaComponentData() {
        this.taComponent0.data.gameId = this.selectedGame.id

        this.taComponent1.data.game = this.selectedGame
        this.taComponent1.data.myStarRating = this.selectedGameRatingItem
        this.taComponent1.data.origin =
            this.inputData && this.inputData.origin ? this.inputData.origin : null
        this.taComponent2.data.gameId = this.selectedGame.id
        this.taComponent2.data.title = this.selectedGame.title
        this.taComponent3.data.gameId = this.selectedGame.id
        // this.taComponent4.data.gameId = this.selectedGame.id

        this.taComponentsAreSet = true
    }

    async setSelectedGame(selectedGame) {

        if (
            selectedGame &&
            (selectedGame.__typename == 'Game' ||
                selectedGame.__typename == 'GameTrackingInfo' ||
                selectedGame.__typename == 'CoreGame')
        ) {
            this.clearAllTabbedAccordionData()
            this.clearValuesForNewSelectedGame()
            this.selectedGame = selectedGame

            if (this.myUserGameQualities.userGameQualities) {
                this.radarData = {
                    labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
                    attributes: JSON.parse(this.selectedGame.big8), // todo simulating fetch of game Big8
                    numberOfVotes: this.selectedGame.big8Votes,
                    overlayAttributes: this.myUserGameQualities.userGameQualities, // TODO: FETCH USER DATA
                }
            } else {
                this.radarData = {
                    labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
                    attributes: JSON.parse(this.selectedGame.big8), // todo simulating fetch of game Big8
                    numberOfVotes: this.selectedGame.big8Votes,
                    overlayAttributes: [0, 0, 0, 0, 0, 0, 0, 0], // TODO: FETCH USER DATA
                }
            }

            let hopperInputData = {
                isGameSpecific: true,
                specificGame: this.selectedGame,
            }

            this.hopperInputData.next(hopperInputData)

            if (this.selectedGame.isCrowdfunded) {
                this.initCrowdfundingData()
            }
            if (!this.selectedGame.isCrowdfunded) {
                this.revertCrowdfundingData()
            }

            this.bulkDataIsSet = true

            // ! NOTE: currently, the child component (app-image-gallery) is directly trying to access image at index 0, so need to pass in all 4
            //!           ideally, the child component should be encapsulated and ignorant of what is passed in and should just display it
            //!           and this component should worry about what it is passing in

            this.mainImage =
                this.selectedGame.mainImageFiles && this.selectedGame.mainImageFiles.length > 0
                    ? this.selectedGame.mainImageFiles
                    : [
                        window.location.origin + '/assets/images/defaults/placeholder-event.png',
                        window.location.origin + '/assets/images/defaults/placeholder-event.png',
                        window.location.origin + '/assets/images/defaults/placeholder-event.png',
                        window.location.origin + '/assets/images/defaults/placeholder-event.png',
                    ]

            if (this.selectedGame.aad) {
                this.aAdOne = this.selectedGame.aad
            } else {
                this.aAdOne = this.bannerAdService.amazonBannerAds[getRandomInt(20)].l
            }

            //todo Eventually, this

            this.bannerAdInputDataSpecific1.next({
                bannerSizeEnum: BannerSizeEnums.banner200x200,
                multiplier: 0.75,
                bannerLocation: BannerLocationEnums.sidebar,
                hasButtons: true,
                specificAds: [{ link: this.aAdOne }],
                isIFrame: true,
            })

            function getRandomInt(max) {
                return Math.floor(Math.random() * max)
            }

            this.aAdTwo = this.selectedGame.aad
            this.bannerAdInputDataSpecific2.next({
                bannerSizeEnum: BannerSizeEnums.banner300x250,
                multiplier: 0.75,
                bannerLocation: BannerLocationEnums.sidebar,
                hasButtons: true,
                specificAds: [{ link: this.bannerAdService.amazonBannerAds[getRandomInt(20)].l }],
                isIFrame: true,
            })

            this.aAdThree = this.selectedGame.aad
            this.bannerAdInputDataSpecific3.next({
                bannerSizeEnum: BannerSizeEnums.banner300x250,
                multiplier: 0.75,
                bannerLocation: BannerLocationEnums.sidebar,
                hasButtons: true,
                specificAds: [{ link: this.bannerAdService.amazonBannerAds[getRandomInt(20)].l }],
                isIFrame: true,
            })

            this.similarGames = this.selectedGame.similarGames

            this.initLendingData(this.selectedGame)
            this.initImages()

            this.initPublishersDesignersAndArtists()

            if (this.user.userIsHaven) {
                this.isInDemoLibrary.next(
                    await this.gamesService.getIsOurDemoLibraryByPK(this.selectedGame.id),
                )
                this.isInLendingLibrary.next(
                    await this.gamesService.getIsOurLendingLibraryByPK(this.selectedGame.id),
                )
                this.isInUsuallyStocked.next(
                    await this.gamesService.getIsOurUsuallyStockedByPK(this.selectedGame.id),
                )
            } else {
                this.isInMyCollection.next(
                    await this.gamesService.getIsInMyCollectionByPK(this.selectedGame.id),
                )
                this.isInMyWishlist.next(
                    await this.gamesService.getIsInMyWishlistByPK(this.selectedGame.id),
                )
            }
            let bob = this.isInDemoLibrary.next(
                await this.gamesService.getIsOurDemoLibraryByPK(this.selectedGame.id),
            )
            this.isInLendingLibrary.next(
                await this.gamesService.getIsOurLendingLibraryByPK(this.selectedGame.id),
            )
            this.isInUsuallyStocked.next(
                await this.gamesService.getIsOurUsuallyStockedByPK(this.selectedGame.id),
            )
            this.isInMyCollection.next(
                await this.gamesService.getIsInMyCollectionByPK(this.selectedGame.id),
            )

            this.isInMyWishlist.next(
                await this.gamesService.getIsInMyWishlistByPK(this.selectedGame.id),
            )
        }
    }

    initCrowdfundingData() {
        this.gameIsCurrentlyCrowdfunding = true
    }

    revertCrowdfundingData() {
        this.gameIsCurrentlyCrowdfunding = false
    }

    async initImages() {
        // ADDITIONAL IMAGES RETURNED HERE SHOULD GO BOTH INTO THE GALLERY AND BE PASSED AS PARAMS WHEN EDITING ) also need to do the same in admin panel)

        try {
            // TODO: at this point we should only fetrch the first 4 additional images!!!
            await this.fetchAdditionalImages().then((result) => {
                this.additionalImages = result.images
            })
        } catch (err) {
            console.log('no additional images found')
        }

        this.thumbs.next([
            {
                imgPath: this.mainImage,
                isMainImage: true,
            },
            {
                imgPath: this.additionalImages[0] ? this.additionalImages[0].paths : undefined,
                isMainImage: false,
            },
            {
                imgPath: this.additionalImages[1] ? this.additionalImages[1].paths : undefined,
                isMainImage: false,
            },
            {
                imgPath: this.additionalImages[2] ? this.additionalImages[2].paths : undefined,
                isMainImage: false,
            },
            {
                imgPath: this.additionalImages[3] ? this.additionalImages[3].paths : undefined,
                isMainImage: false,
            },
        ])
    }

    async fetchAdditionalImages() {
        let fetchedImages = await this.backendApiContentService.listImagesByGameID(
            this.selectedGame.id,
            4,
        )
        return fetchedImages
    }

    initPublishersDesignersAndArtists() {
        // NOT SURE WHY THIS IS A NEEDED / PLANNED METHOD
    }

    editGame() {
        let gameDialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Update Game',
                component: AddOrEditGameComponent,
                inputData: {
                    gameToEdit: this.selectedGame,
                    isGHAdmin: this.user,
                    gameToEditMainImage: this.mainImage,
                    gameToEditAdditionalImages: this.additionalImages,
                },
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Update',
                allowParentClose: true,
            },
        })
        gameDialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((gameData) => {
                if (gameData && gameData == 'success') {
                }
            })
    }

    listOfGameTrackingItemsToBeDeleted
    async fetchAndDeleteAllGameTrackingItems(selectedGameId) {
        // todo This wont scale indefinitly. Need to be able to handle more than 500
        let carl = await this.backendApiGameTrackingService.listGameTrackingItemsByGameByType(
            selectedGameId,
            '',
            500,
        )

        this.listOfGameTrackingItemsToBeDeleted = carl











        for (const item of this.listOfGameTrackingItemsToBeDeleted.games) {
            let input = {
                pk: item.PK_GAMEID,
                sk: item.sk_TYPE_UID_ULID,
            }

            this.backendApiGameTrackingService.deleteGameTrackingItemByPkSk(input)










        }
    }



    async showAdditionalImages() {

        let images = await this.backendApiContentService.listImagesByGameID(this.selectedGame.id, 49)

        let inputData = {
            images: images.images
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Additional Images',
                component: ImageGalleryGridComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,


                // todo this will be all images
                inputData: inputData,
                hasCloseButton: true,
            },
            height: '70%',
            width: '40%',
            disableClose: true,
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
        })

        // ! NOVEL: we are subscribing to a service here
        dialogRef.afterOpened()
            .pipe(untilDestroyed(this))
            .subscribe(async () => {
                this.imageGalleryGridService.onImageAction$
                    .pipe(untilDestroyed(this))
                    .subscribe((action) => {

                        if (OnImageActionType[action.type] == 'delete') {

                            let inputData = {
                                message: 'Permanently DELETE Image:',
                                submessage: '',
                                isDeletion: true,
                                hasPassword: true
                            }


                            let dialogRefCarl = this.dialog.open(GhDialogWrapperComponent, {
                                data: {
                                    title: 'Are you sure?',
                                    component: ConfirmActionComponent,
                                    inputData: inputData,
                                    hasSubmitButton: true,
                                    hasCancelButton: true,
                                    allowParentClose: true
                                },
                                panelClass: this.isMobileScreen ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS] : DESKTOP_MODAL_PANEL_CLASS,
                                backdropClass: 'gh-dialog-backdrop',
                                disableClose: true
                            })

                            dialogRefCarl.afterClosed().pipe(untilDestroyed(this)).subscribe(async data => {

                                if (data && data.toLowerCase().trim() == DELETE_CONFIRMATION_PASSWORD) {

                                    this.backendApiContentService.deleteImageOrVideo({ id: action.imageItem.sk, type: 'image' })

                                    this.snackbarService.openErrorSnackBar('Image Deleted, you will have to refresh')

                                } else { alert('Confirmation Failure') }
                            })

                        }

                    })
            })
    }






    async deleteGame() {
        let inputData = {
            message: 'Permanently delete: ' + this.selectedGame.title + '?',
            submessage: 'All game data and references will be deleted!',
            isDeletion: true,
            hasPassword: true,
        }
        let dialogRefFoo = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Are you sure?',
                component: ConfirmActionComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRefFoo
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                if (data && data.toLowerCase().trim() == DELETE_CONFIRMATION_PASSWORD) {
                    await this.fetchAndDeleteAllGameTrackingItems(this.selectedGame.id)

                    this.backendApiGamesService
                        .deleteGame({ id: this.selectedGame.id })
                        .then((result) => {
                            // TODO: error checking here!!!
                            this.snackbarService.openSuccessSnackBar(
                                'Deleted ' + this.selectedGame.title + '!'
                            )
                        })
                    let input = {
                        replyToEmail: this.user.email,
                        // toEmail: this.user.email,
                        toEmail: 'support@mygamehaven.com',
                        subject: this.user.title + ' deleted ' + this.selectedGame.title,
                        messageText: 'could have other data here',
                    }
                    this.backendApiContentService.createSESRequest(input)

                    //! DELETE ALL GAME TRACKING ITEMS
                } else {
                    alert('Wrong Confirmation Code')
                }
            })
    }

    zoomImages() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Additional Images',
                component: ImageZoomGalleryComponent,
                inputData: { images: this.thumbs },
                hasSubmitButton: false,
                hasCancelButton: false,
            },
            height: '70%',
            width: '40%',
            disableClose: true,
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
        })
    }

    initLendingData(game) {
        this.gameIsBorrowed = false
        this.gameIsLended = false
    }

    validateMainImage(game) {
        return game.mainImage && game.mainImage != null && game.mainImage != ''
    }


    // FUTURE COMPLEX VERSION
    // openSuccessSnackBar(
    //     message: string,
    //     icon: string,
    //     iconColor: string,
    //     action?: string,
    //     type?: string,
    // ) {
    //     let snackbarRef = this.snackbar.openFromComponent(IconSnackbarComponent, {
    //         duration: 3000,
    //         horizontalPosition: 'center',
    //         verticalPosition: 'bottom',
    //         data: {
    //             message: message,
    //             action: action,
    //             icon: icon,
    //             iconColor: iconColor,
    //         },
    //         panelClass: 'ghIconSnackbar',
    //     })

    //     snackbarRef
    //         .onAction()
    //         .pipe(untilDestroyed(this))
    //         .pipe(untilDestroyed(this))
    //         .subscribe(() => {
    //             if (type == 'myGames') {
    //                 this.removeFromMyGames()
    //                 this.isInMyCollection.next(false)
    //                 this.addToMyGamesButtonState = 'addToMyGamesAnimIdle'
    //                 this.openSuccessSnackBar(
    //                     'Removed ' + this.selectedGame.title + ' from you Collection',
    //                     'delete',
    //                     '#8a2d20',
    //                 )
    //             } else if (type == 'wishlist') {
    //                 this.removeFromWishlist()
    //                 this.isInMyWishlist.next(false)
    //                 this.addToWishlistButtonState = 'addToWishlistAnimIdle'
    //                 this.openSuccessSnackBar(
    //                     'Removed ' + this.selectedGame.title + ' from Wishlist',
    //                     'delete',
    //                     '#2c3531',
    //                 )
    //             }
    //         })
    // }





    async addToDemoLibrary() {
        let input = {
            gameId: this.selectedGame.id,
            UID: this.user.defaultHaven,
            type: 'havenDemo',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
            userTitle: this.user.title,
            mainImageFiles: this.selectedGame.mainImageFiles,
            specificLocation: 'downstairsWIPsdflkjs',
        }

        this.isInDemoLibrary.next(true)

        await this.backendApiGameTrackingService.createHavenGameTrackingItem(input)
        this.snackbarService.openSuccessSnackBar(
            'Added ' + this.selectedGame.title + ' to Demo Library '
        )

        await this.gamesService.initDemoLibrary(this.user.username)
    }

    // REMOVE FROM DEMO LIBRARY

    async removeFromDemoLibrary() {
        let deleteInput = {
            gameId: this.selectedGame.id,
            UID: this.user.defaultHaven,
            type: 'havenDemo',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
        }

        let deleteFromMyCollectionRes =
            await this.backendApiGameTrackingService.deleteGameTrackingItem(deleteInput)

        this.isInDemoLibrary.next(false)

        this.snackbarService.openSuccessSnackBar(
            'Removed ' + this.selectedGame.title + ' from your Collection')

        await this.gamesService.initDemoLibrary(this.user.username)
    }

    async addToLendingLibrary() {
        let input = {
            gameId: this.selectedGame.id,
            UID: this.user.defaultHaven,
            type: 'havenLending',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
            userTitle: this.user.title,
            mainImageFiles: this.selectedGame.mainImageFiles,
            specificLocation: 'downstairsWIPsdflkjs',
        }

        this.isInLendingLibrary.next(true)

        await this.backendApiGameTrackingService.createHavenGameTrackingItem(input)

        await this.gamesService.initLendingLibrary(this.user.username)

        this.snackbarService.openSuccessSnackBar(
            'Added ' + this.selectedGame.title + ' to Lending Games '
        )
    }

    // REMOVE FROM LENDING LIBRARY

    async removeFromLendingLibrary() {
        let deleteInput = {
            gameId: this.selectedGame.id,
            UID: this.user.defaultHaven,
            type: 'havenLending',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
        }

        let deleteFromMyCollectionRes =
            await this.backendApiGameTrackingService.deleteGameTrackingItem(deleteInput)

        this.isInLendingLibrary.next(false)

        await this.gamesService.initLendingLibrary(this.user.username)

        this.snackbarService.openSuccessSnackBar(
            'Removed ' + this.selectedGame.title + ' from your Collection')
    }

    async addToNormallyStocked() {
        let input = {
            gameId: this.selectedGame.id,
            UID: this.user.defaultHaven,
            type: 'havenUsuallyInStock',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
            userTitle: this.user.title,
            mainImageFiles: this.selectedGame.mainImageFiles,
            specificLocation: 'downstairsWIPsdflkjs',
        }

        this.isInUsuallyStocked.next(true)

        await this.backendApiGameTrackingService.createHavenGameTrackingItem(input)

        await this.gamesService.initUsuallyStocked(this.user.username)

        this.snackbarService.openSuccessSnackBar(
            'Added ' + this.selectedGame.title + ' to Games usually in stock'
        )
    }

    // REMOVE FROM NORMALLY STOCKED

    async removeFromNormallyStocked() {
        let deleteInput = {
            gameId: this.selectedGame.id,
            UID: this.user.defaultHaven,
            type: 'havenUsuallyInStock',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
        }

        this.isInUsuallyStocked.next(false)

        let deleteFromMyCollectionRes =
            await this.backendApiGameTrackingService.deleteGameTrackingItem(deleteInput)

        this.snackbarService.openSuccessSnackBar(
            'Removed ' + this.selectedGame.title + ' from your Collection'
        )

        await this.gamesService.initUsuallyStocked(this.user.username)
    }

    async addToWishlist() {
        let addToMyCollectionInput = {
            gameId: this.selectedGame.id,
            UID: this.user.username,
            type: 'wishlisted',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
            userTitle: this.user.title,
            mainImageFiles: this.selectedGame.mainImageFiles,
        }
        let addToMyWishlistRes = await this.backendApiGameTrackingService.createGameTrackingItem(
            addToMyCollectionInput,
        )
        // TODO: eventually should check what is actually in these results in case it is an error. for now just checking that the result exists
        // TODO: also, I am explicitly setting the values rather than fetching from the service since it is fetching quicker than it is updating
        if (addToMyWishlistRes) {
            this.isInMyWishlist.next(true)
        }
        await this.gamesService.initMyWishlist(this.user.username)

        this.snackbarService.openSuccessSnackBar(
            'Added ' + this.selectedGame.title + ' to your Wishlist '
        )
    }


    async addToMyGames() {
        // //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // //! NEED TO CHECK IF IS LOGGED IN AS A HAVEN HERE - CONDITIONS CHANGE!!!
        // //! this will determine if specific location modal pops up!

        // //* below line should come from this.user
        // let userIsHaven = false

        // if (userIsHaven) {
        //   let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
        // panelClass: this.isMobileScreen ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS] : DESKTOP_MODAL_PANEL_CLASS,
        //     backdropClass: 'gh-dialog-backdrop',
        //     disableClose: true,
        //     height: '70vh',
        //     data: {
        //       title: this.selectedGame.title.concat(' specific location:'),
        //       component: HavenCollectionSpecificLocationModalComponent,
        //       hasSubmitButton: true,
        //       hasCancelButton: false,
        //       allowParentClose: true,
        //       inputData: {}
        //     }
        //   })

        //   dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(data => {
        //     // console.log('specific location data returned from dialog...', data)
        //     alert('need to call anbother method here that has a switch statement. depending on the type of user that is logged in need to conditionally do different things')
        //     alert('make a task:  what if a user is more than one thing? conditionals could get messy. not a huge problem but need to be thought through sooner than later!')
        //   })
        // }
        // //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        //* this stuff should be in the separate method that is mentioned above
        let addToMyCollectionInput = {
            gameId: this.selectedGame.id,
            UID: this.user.username,
            type: 'owned',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
            userTitle: this.user.title,
            mainImageFiles: this.selectedGame.mainImageFiles,
        }

        let addToMyGamesResult = await this.backendApiGameTrackingService.createGameTrackingItem(
            addToMyCollectionInput,
        )
        if (addToMyGamesResult) {
            await this.gamesService.initMyGames(this.user.username)
            this.isInMyCollection.next(true)
            this.snackbarService.openSuccessSnackBar(
                'Added ' + this.selectedGame.title + ' to your Collection '
            )
        }
    }

    // REMOVE FROM MY GAMES

    async removeFromMyGames() {
        this.isInMyCollection.next(false) // TODO: set this after backend call if successful (and do the same for add to collection and both add/remove for wishlist)

        let deleteInput = {
            gameId: this.selectedGame.id,
            UID: this.user.username,
            type: 'owned',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
        }

        let deleteFromMyCollectionRes =
            await this.backendApiGameTrackingService.deleteGameTrackingItem(deleteInput)

        //! for some reason the response from delete is null. Bypassing for now
        // if (deleteFromMyCollectionRes) {
        //   await this.gamesService.initMyGames(this.user.username)
        //   this.isInMyCollection.next(false)
        // }

        //! Bypassed
        await this.gamesService.initMyGames(this.user.username)
        this.isInMyCollection.next(false)

        this.snackbarService.openSuccessSnackBar(
            'Removed ' + this.selectedGame.title + ' from your Collection'
        )
    }

    // REMOVE FROM MY WISHLIST

    async removeFromWishlist() {
        this.isInMyWishlist.next(false)

        let deleteInput = {
            gameId: this.selectedGame.id,
            UID: this.user.username,
            type: 'wishlisted',
            userState: this.user.state,
            userZipCode: this.user.zipCode,
            title: this.selectedGame.title,
        }

        let deleteFromMyWishlistRes =
            await this.backendApiGameTrackingService.deleteGameTrackingItem(deleteInput)

        this.isInMyWishlist.next(false)

        // ! Bypassed
        await this.gamesService.initMyWishlist(this.user.username)
        this.isInMyWishlist.next(false)

        this.snackbarService.openSuccessSnackBar(
            'Removed ' + this.selectedGame.title + ' from your Wishlist'
        )
    }

    goTolentGames() {
        this.changeTab.emit(4)
    }

    goToBorrowedGames() {
        this.changeTab.emit(5)
    }

    toggleSidenav(destination) {
        switch (destination) {
            case 'gameComponents':
                this.sideNavContent = 'gameComponents'
                break

            case 'faqs':
                this.sideNavContent = 'faqs'
                break

            case 'rules':
                this.sideNavContent = 'rules'
                break

            // case "forum":
            //   this.sideNavContent = "forum"
            //   break

            case 'map':
                this.sideNavContent = 'map'
                break
        }

        this.opened = !this.opened
    }

    onTabbedAccordionClicked(tabIndex) {
        switch (tabIndex) {
            //! IMPORTANT NOTES:
            //!  1. the stringifying/parsing process doesn't maintain the content component for some reason, so need to inject it back in after...
            //!  2. could handle this is tabbed-accordion component (in order to only update the data and avoid reinstantiating the component), but leaving for now since this works fine

            case 0:
                let shallowCopy0 = JSON.stringify(this.taComponent0)
                let parsedComponent0 = JSON.parse(shallowCopy0)
                parsedComponent0.component = TaPublisherDescriptionComponent
                this.taComponent0 = parsedComponent0
                break

            case 1:
                let shallowCopy1 = JSON.stringify(this.taComponent1)
                let parsedComponent1 = JSON.parse(shallowCopy1)
                parsedComponent1.component = TaReviewsComponent
                this.taComponent1 = parsedComponent1
                break

            case 2:
                let shallowCopy2 = JSON.stringify(this.taComponent2)
                let parsedComponent2 = JSON.parse(shallowCopy2)
                parsedComponent2.component = TaContentComponent
                this.taComponent2 = parsedComponent2
                break

            case 3:
                let shallowCopy3 = JSON.stringify(this.taComponent3)
                let parsedComponent3 = JSON.parse(shallowCopy3)
                parsedComponent3.component = TaRecentGamePlaysComponent
                this.taComponent3 = parsedComponent3
                break

            case 4:
                // let shallowCopy4 = JSON.stringify(this.taComponent4)
                // let parsedComponent4 = JSON.parse(shallowCopy4)
                // parsedComponent4.component = TaMapComponent
                // this.taComponent4 = parsedComponent4
                break


            // case 3:
            //   let shallowCopy3 = JSON.stringify(this.taComponent3)
            //   let parsedComponent3 = JSON.parse(shallowCopy3)
            //   parsedComponent3.component = TaRulesForumComponent
            //   this.taComponent3 = parsedComponent3
            //   break
        }
    }

    taPanelOpenState = new BehaviorSubject(null)

    clearAllTabbedAccordionData() {
        let shallowCopy0 = JSON.stringify(this.taComponent0)
        let parsedComponent0 = JSON.parse(shallowCopy0)
        parsedComponent0.component = TaPublisherDescriptionComponent
        this.taComponent0 = parsedComponent0

        let shallowCopy1 = JSON.stringify(this.taComponent1)
        let parsedComponent1 = JSON.parse(shallowCopy1)
        parsedComponent1.component = TaReviewsComponent
        this.taComponent1 = parsedComponent1

        let shallowCopy2 = JSON.stringify(this.taComponent2)
        let parsedComponent2 = JSON.parse(shallowCopy2)
        parsedComponent2.component = TaContentComponent
        this.taComponent2 = parsedComponent2

        // let shallowCopy3 = JSON.stringify(this.taComponent3)
        // let parsedComponent3 = JSON.parse(shallowCopy3)
        // parsedComponent3.component = TaRulesForumComponent
        // this.taComponent3 = parsedComponent3

        let shallowCopy3 = JSON.stringify(this.taComponent3)
        let parsedComponent3 = JSON.parse(shallowCopy3)
        parsedComponent3.component = TaRecentGamePlaysComponent
        this.taComponent3 = parsedComponent3

        // let shallowCopy4 = JSON.stringify(this.taComponent4)
        // let parsedComponent4 = JSON.parse(shallowCopy4)
        // parsedComponent4.component = TaMapComponent
        // this.taComponent4 = parsedComponent4
    }

    clearValuesForNewSelectedGame() {
        this.bulkDataIsSet = false
        this.mainImageIsSet = false
        this.additionalImagesAreSet = false
    }

    onPanelOpened(index) {
        this.selectedMobileAccordionIndex = index
    }

    onPanelClosed(index) {
        if (index == this.selectedMobileAccordionIndex) {
            this.selectedMobileAccordionIndex = null
        }
    }

    openHopper() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            height: '70vh',
            data: {
                title: this.selectedGame.title.concat(' is being played at:'),
                component: HopperComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                inputData: {
                    isGameSpecific: true,
                    specificGame: this.selectedGame,
                    displayedColumns: HOPPER_DISPLAYED_COLUMNS_GAME_SPECIFIC,
                },
            },
        })
    }

    openFindInterestNearYou() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            height: '70vh',
            data: {
                title: 'Interested in: '.concat(this.selectedGame.title),
                component: GamesFindInterestNearYouComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                allowParentClose: true,
                state: this.user.state, //! is this supposed to be inside input data???
                inputData: {
                    isGameSpecific: true,
                    specificGame: this.selectedGame,
                    displayedColumns: HOPPER_DISPLAYED_COLUMNS_GAME_SPECIFIC,
                    user: this.user,
                },
            },
        })
    }

    async logEmittedResults(event) {
        if (event == 'resetData') {
            let gameDetails = await this.backendApiGamesService.GetGame(this.selectedGame.id)
            await this.setSelectedGame(gameDetails)

            this.shouldInitRadarData = true

            this.setSelectedGameRating()
            this.setTaComponentData()

            this.radarData = {
                labels: RADAR_CHART_PROFILE_FRIEND_GAME_LABELS,
                //! return this testing below attributes: this.selectedGame.big8,
                attributes: JSON.parse(this.selectedGame.big8), // todo simulating fetch of game Big8
                // overlayAttributes: this.user.radarAttributes // TODO: FETCH USER DATA
                // overlayAttributes: [8, 6, 5, 3, 4, 7, 5, 9] // TODO: FETCH USER DATA
                overlayAttributes: this.myUserGameQualities.userGameQualities, // TODO: FETCH USER DATA
            }
        }
    }
}
