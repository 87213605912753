import { Injectable } from '@angular/core'
import gql from 'graphql-tag'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { Auth } from 'aws-amplify'
import { environment } from 'src/environments/environment'
import { BackendApiS3Service } from './backend-api-s3.service'
import { ImageMultiSizeService } from '../dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import {
    iChangePointsInput,
    iCreateOrUpdateUserArtistItem,
    iCreateOrUpdateUserContentCreatorItem,
    iCreateOrUpdateUserDeveloperItem,
    iCreateOrUpdateUserPublisherItem,
    iDeletePDAInput,
    iUpdateSingleArrayAttributeForUserInput,
    iUpdateSingleAttributeForUserInput,
} from '../../../../common/interfaces/UserInterfaces'
import { BehaviorSubject } from 'rxjs'
// import { APIService } from '../API.service'
import { uploadImageFile } from './s3-file-uploader'
import { GlobalService } from '../shared/services/global/global.service'

@Injectable({
    providedIn: 'root',
})
export class BackendAPIUsersService {
    private currentUser = new BehaviorSubject(null)
    currentUser$ = this.currentUser.asObservable()

    constructor(
        private imageMultiSizeService: ImageMultiSizeService,
        // private oldApi: APIService,
        private globalService: GlobalService,
    ) {
        // this.subscribeToHotlist()
    }

    private appSyncClient = new AWSAppSyncClient({
        url: environment.apiUrl,
        region: 'us-east-1',
        auth: {
            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            jwtToken: async () => {
                const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                return token
            },
        },
        disableOffline: true,
    })

    async initCurrentUser() {
        try {
            const result = await this.getMyProfile()

            this.currentUser.next(result)
            return result
        } catch (error) {
            console.log('~~~ error getting current user', error)
            throw error
        }
    }

    getCurrentUser() {
        return this.currentUser.value
    }

    async updateUser(input, rawImage: File) {
        // must check for undefined as well as null
        if (rawImage != null && rawImage != undefined) {
            const extension = rawImage.name.split('.').pop()
            const s3ObjectUrl = await uploadImageFile(rawImage, extension)
            const correctedUrl = s3ObjectUrl.replace(
                'https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/',
                'https://gh-img.s3.amazonaws.com/',
            )

            input.mainImageFiles = this.imageMultiSizeService.createArrayForAllSizes(
                correctedUrl.replace('.jpg', '').replace('.jpeg', ''),
            )
        }
        //! do not add an else statement here and set it to blank profile, that overwrites the user's previous profile pic

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateUser($input: IGQL_UpdateUserInput!) {
                    updateUser(input: $input) {
                        username
                    }
                }
            `,
            variables: { input },
        })

        return response.data.updateUser
    }

    async GetUserMainImageFiles(username: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getProfileById($username: ID!) {
                    getProfileById(username: $username) {
                        mainImageFiles
                    }
                }
            `,
            variables: { username },
        })

        return response.data.getProfileById
    }

    async updateDataWithPKandSK(username, data) {
        const input = {
            pk: username,
            sk: 'predictedRatings',
            attribute: 'predictedRatings',
            data: data,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateDataWithPKandSK($input: IGQL_UpdateDataWithPKandSKInput!) {
                    updateDataWithPKandSK(input: $input) {
                        username
                    }
                }
            `,
            variables: { input },
        })
        return response.data.updateDataWithPKandSK
    }

    async updateUGQDataWithPKandSK(username, data) {
        const input = {
            pk: username,
            sk: 'userGameQualities',
            attribute: 'userGameQualities',
            data: data,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateDataWithPKandSK($input: IGQL_UpdateDataWithPKandSKInput!) {
                    updateDataWithPKandSK(input: $input) {
                        username
                    }
                }
            `,
            variables: { input },
        })
        return response.data.updateDataWithPKandSK
    }

    async getCurrentUserProfile() {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getCurrentUserProfile {
                    getMyProfile {
                        username
                        title
                        updatedAt
                        userLevel
                    }
                }
            `,
        })
        return response.data.getMyProfile
    }

    async getMyProfile() {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getMyProfile {
                    getMyProfile {
                        mainImageFiles
                        username
                        bio
                        title
                        userModelVector
                        pda
                        createdAt
                        updatedAt
                        defaultHaven
                        hotList {
                            game
                            score
                        }
                        predictedHotList {
                            big8
                            game
                            score
                        }
                        predictedRatings {
                            big8
                            game
                            score
                        }
                        data
                        userLevel
                        userId
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                        gender
                        dob
                        gsi1sk
                        lookingToPlay
                        email
                        zipCode
                        state
                        gamesILove
                        userGameQualities
                        GH_Points
                        messagingPreference
                        contentPreference
                        personalListOfNonGhUsers
                        listOfFollowers

                        gsi2pk
                        gsi2sk

                        corePK
                        coreSK

                        isNewUserFlag
                        userIsHaven
                        havensIManage
                        groupsIManage
                    }
                }
            `,
            fetchPolicy: 'network-only',
        })

        return response.data.getMyProfile
    }

    // ! ISSUE HERE, ussergameQUalites shoudl be a simple lsit but we are treating it as PredictedHotLIist?

    async getUserGameQualitiesById(username: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getUserGameQualitiesById($username: ID!) {
                    getUserGameQualitiesById(username: $username) {
                        userGameQualities
                    }
                }
            `,
            variables: { username },
            fetchPolicy: 'network-only',
        })

        return response.data.getUserGameQualitiesById
    }

    async getProfileById(username: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getProfileById($username: ID!) {
                    getProfileById(username: $username) {
                        bio
                        mainImageFiles
                        gamesILove
                        username
                        title
                        userModelVector
                        pda
                        createdAt
                        updatedAt
                        userLevel
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                        gsi1sk
                        lookingToPlay
                        email
                        havensIManage
                        zipCode
                        state
                    }
                }
            `,
            variables: { username },
        })

        return response.data.getProfileById
    }

    async getMyHotList() {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getMyHotList {
                    getMyHotList {
                        hotList {
                            game
                            score
                        }
                    }
                }
            `,
        })
        return response.data.getMyHotList
    }

    async getMyPredictedHotList() {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getMyPredictedHotList {
                    getMyPredictedHotList {
                        predictedHotList {
                            game
                            score
                            big8
                        }
                    }
                }
            `,
        })
        return response.data.getMyPredictedHotList
    }

    async getPredictedDataByUserIdByType(id: any, type: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getPredictedDataByUserIdByType($id: ID!, $type: String!) {
                    getPredictedDataByUserIdByType(id: $id, type: $type) {
                        predictedRatings
                    }
                }
            `,
            variables: { id, type },
        })

        return response.data.getPredictedDataByUserIdByType
    }

    async getHotListById(id: any) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getHotListById($id: ID!) {
                    getHotListById(id: $id) {
                        id
                    }
                }
            `,
            variables: { id },
        })
        return response.data.getHotList
    }

    notifications = []
    async subscribeToHotlist() {
        const currentUserInfo = await Auth.currentUserInfo()

        // TODO: either unsubscribe to this explicitly or use the @UntilDestroy thing
        let subscription = this.appSyncClient
            .subscribe({
                query: gql`
                    subscription onNotified($userId: ID!) {
                        onNotified(userId: $userId) {
                            ... on iNotification {
                                id
                                type
                                title
                                userId
                                createdAt
                            }

                            ... on HotListNotification {
                                hotListItemCount
                            }
                        }
                    }
                `,
                variables: {
                    userId: currentUserInfo.username,
                },
            })
            .subscribe({
                next: (resp) => {
                    this.notifications.push(resp.data.onNotified)
                },
            })
    }

    async listAllUserCoreData(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllUserCoreData($limit: Int!, $nextToken: String) {
                    listAllUserCoreData(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        coreUsers {
                            corePK
                            coreSK
                            title
                            username
                            zipCode
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listAllUserCoreData
    }

    async listUsersByLocation(locationStartsWith: string, limit: number) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listUsersByLocation($locationStartsWith: String!, $limit: Int!) {
                    listUsersByLocation(locationStartsWith: $locationStartsWith, limit: $limit) {
                        users {
                            username
                            createdAt
                            title
                            mainImageFiles
                            zipCode
                        }
                        nextToken
                    }
                }
            `,
            variables: { locationStartsWith, limit },
        })
        return response.data.listUsersByLocation
    }

    async listUsersByTitle(titleStartsWith: string, limit: number) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listUsersByTitle($titleStartsWith: String!, $limit: Int!) {
                    listUsersByTitle(titleStartsWith: $titleStartsWith, limit: $limit) {
                        nextToken
                        users {
                            title
                        }
                        title
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                    }
                }
            `,
            variables: { titleStartsWith, limit },
        })
        return response.data.listUsersByTitle
    }

    async createUserPublisherItem(input: iCreateOrUpdateUserPublisherItem) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createUserPublisherItem(
                    $input: IGQL_CreateOrUpdateUserPublisherItemInput!
                ) {
                    createUserPublisherItem(input: $input) {
                        username
                        title
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createUserPublisherItem
    }

    async createPublisherGameItem(publisher, game) {
        let input = {
            publisherTitle: publisher.title,
            publisherId: publisher.username,
            gameTitle: game.title,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createPublisherGameItem($input: IGQL_CreatePublisherGameItemInput!) {
                    createPublisherGameItem(input: $input) {
                        username
                        title
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createPublisherGameItem
    }

    async createDeveloperGameItem(developer, game) {
        let input = {
            developerTitle: developer.title,
            developerId: developer.username,
            gameTitle: game.title,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createDeveloperGameItem($input: IGQL_CreateDeveloperGameItemInput!) {
                    createDeveloperGameItem(input: $input) {
                        username
                        title
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createDeveloperGameItem
    }

    async createArtistGameItem(artist, game) {
        let input = {
            artistTitle: artist.title,
            artistId: artist.username,
            gameTitle: game.title,
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createArtistGameItem($input: IGQL_CreateArtistGameItemInput!) {
                    createArtistGameItem(input: $input) {
                        username
                        title
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createArtistGameItem
    }

    async listAllGamesByPublisherTitle(titleStartsWith: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllGamesByPublisherTitle(
                    $titleStartsWith: String
                    $limit: Int!
                    $nextToken: String
                ) {
                    listAllGamesByPublisherTitle(
                        titleStartsWith: $titleStartsWith
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        publisherGames {
                            pk
                            sk
                            createdAt
                            publisherId
                            __typename
                        }
                    }
                }
            `,
            variables: { titleStartsWith, limit, nextToken },
        })
        return response.data.listAllGamesByPublisherTitle
    }

    async listAllGamesByDeveloperTitle(titleStartsWith: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllGamesByDeveloperTitle(
                    $titleStartsWith: String
                    $limit: Int!
                    $nextToken: String
                ) {
                    listAllGamesByDeveloperTitle(
                        titleStartsWith: $titleStartsWith
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        developerGames {
                            pk
                            sk
                            createdAt
                            developerId
                            __typename
                        }
                    }
                }
            `,
            variables: { titleStartsWith, limit, nextToken },
        })
        return response.data.listAllGamesByDeveloperTitle
    }

    async listAllGamesByArtistTitle(titleStartsWith: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllGamesByArtistTitle(
                    $titleStartsWith: String
                    $limit: Int!
                    $nextToken: String
                ) {
                    listAllGamesByArtistTitle(
                        titleStartsWith: $titleStartsWith
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        artistGames {
                            pk
                            sk
                            createdAt
                            artistId
                            __typename
                        }
                    }
                }
            `,
            variables: { titleStartsWith, limit, nextToken },
        })
        return response.data.listAllGamesByArtistTitle
    }

    async listAllPublisherCoreData(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllPublisherCoreData($limit: Int!, $nextToken: String) {
                    listAllPublisherCoreData(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        corePublishers {
                            corePK
                            coreSK
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listAllPublisherCoreData
    }

    async listAllDeveloperCoreData(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllDeveloperCoreData($limit: Int!, $nextToken: String) {
                    listAllDeveloperCoreData(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        coreDevelopers {
                            corePK
                            coreSK
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listAllDeveloperCoreData
    }

    async listAllArtistCoreData(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllArtistCoreData($limit: Int!, $nextToken: String) {
                    listAllArtistCoreData(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        coreArtists {
                            corePK
                            coreSK
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listAllArtistCoreData
    }

    async listAllContentCreatorCoreData(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllContentCreatorCoreData($limit: Int!, $nextToken: String) {
                    listAllContentCreatorCoreData(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        coreContentCreators {
                            corePK
                            coreSK
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listAllContentCreatorCoreData
    }

    async getUserByTitle(title: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getUserByTitle($title: String!) {
                    getUserByTitle(title: $title) {
                        bio
                        mainImageFiles
                        gamesILove
                        username
                        title
                        userModelVector
                        pda
                        createdAt
                        updatedAt
                        userLevel
                        context
                        contextIdentity
                        contextIdentityUsername
                        contextArguments
                        gsi1sk
                        lookingToPlay
                        email
                        havensIManage
                        state
                        zipCode
                    }
                }
            `,
            variables: { title },
        })
        return response.data.getUserByTitle
    }

    async listPublishersByTitle(titleStartsWith: string, limit: number) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listPublishersByTitle($titleStartsWith: String!, $limit: Int!) {
                    listPublishersByTitle(titleStartsWith: $titleStartsWith, limit: $limit) {
                        nextToken
                        users {
                            username
                            createdAt
                            title
                        }
                        nextToken
                    }
                }
            `,
            variables: { titleStartsWith, limit },
            fetchPolicy: 'network-only',
        })
        return response.data.listPublishersByTitle
    }

    async listDesignersByTitle(titleStartsWith: string, limit: number) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listDesignersByTitle($titleStartsWith: String!, $limit: Int!) {
                    listDesignersByTitle(titleStartsWith: $titleStartsWith, limit: $limit) {
                        nextToken
                        users {
                            username
                            createdAt
                            title
                        }
                        nextToken
                    }
                }
            `,
            variables: { titleStartsWith, limit },
            fetchPolicy: 'network-only',
        })
        return response.data.listDesignersByTitle
    }

    async listArtistsByTitle(titleStartsWith: string, limit: number) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listArtistsByTitle($titleStartsWith: String!, $limit: Int!) {
                    listArtistsByTitle(titleStartsWith: $titleStartsWith, limit: $limit) {
                        nextToken
                        users {
                            username
                            createdAt
                            title
                        }
                        nextToken
                    }
                }
            `,
            variables: { titleStartsWith, limit },
            fetchPolicy: 'network-only',
        })
        return response.data.listArtistsByTitle
    }

    async listContentCreatorsByTitle(titleStartsWith: string, limit: number) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listContentCreatorsByTitle($titleStartsWith: String!, $limit: Int!) {
                    listContentCreatorsByTitle(titleStartsWith: $titleStartsWith, limit: $limit) {
                        nextToken
                        users {
                            username
                            createdAt
                            title
                        }
                        nextToken
                    }
                }
            `,
            variables: { titleStartsWith, limit },
        })
        return response.data.listContentCreatorsByTitle
    }

    async createUserDesignerItem(input: iCreateOrUpdateUserDeveloperItem) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createUserDesignerItem($input: IGQL_CreateOrUpdateUserDesignerItemInput!) {
                    createUserDesignerItem(input: $input) {
                        username
                        title
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createUserDesignerItem
    }

    async createUserArtistItem(input: iCreateOrUpdateUserArtistItem) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createUserArtistItem($input: IGQL_CreateOrUpdateUserArtistItemInput!) {
                    createUserArtistItem(input: $input) {
                        username
                        title
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createUserArtistItem
    }

    async createUserContentCreatorItem(input: iCreateOrUpdateUserContentCreatorItem) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createUserContentCreatorItem(
                    $input: IGQL_CreateOrUpdateUserContentCreatorItemInput!
                ) {
                    createUserContentCreatorItem(input: $input) {
                        username
                        title
                    }
                }
            `,
            variables: { input },
        })
        return response.data.createUserContentCreatorItem
    }

    async getUsersByGeoData(geoLocation) {
        var res

        var skString = geoLocation.country + '#'

        if (geoLocation.state != null) {
            skString += geoLocation.state + '#'
        }

        if (geoLocation.county != null) {
            skString += geoLocation.county + '#'
        }

        if (geoLocation.zipcode != null) {
            skString += geoLocation.zipcode + '#'
        }

        if (geoLocation.userId != null) {
            skString += geoLocation.userId
        }

        // await this.oldApi.ListUsers('users', { beginsWith: skString }).then((result) => {
        //   res = result
        // })
        return res.items
    }

    async getMultipleUsersByGSI1(userIds) {
        var res = []
        // for (let id of userIds) {
        //   await this.getUserByGSI1(id).then(user => {
        //     res.push(user[0])
        //   })
        // }
        // return res
    }

    async getNonCurrentUserBulkData(userId) {
        var res
        // await this.oldApi.GetUser(userId, 'BD#'.concat(userId)).then(result => {
        //   res = result
        // })
        // return JSON.parse(res.data)
    }

    async deletePDA(input: iDeletePDAInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deletePDA($input: IGQL_DeletePDAInput!) {
                    deletePDA(input: $input) {
                        title
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deletePDA
    }

    async modifySingleAttribute(input: iChangePointsInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation modifySingleAttribute($input: IGQL_ModifySingleAttributeInput!) {
                    modifySingleAttribute(input: $input) {
                        GH_Points
                    }
                }
            `,
            variables: { input },
        })
        return response.data.modifySingleAttribute
    }

    async incrementSingleAttribute(input: iChangePointsInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation incrementSingleAttribute($input: IGQL_IncrementSingleAttributeInput!) {
                    incrementSingleAttribute(input: $input) {
                        GH_Points
                    }
                }
            `,
            variables: { input },
        })
        return response.data.incrementSingleAttribute
    }

    async updateSingleAttributeForUser(input: iUpdateSingleAttributeForUserInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateSingleAttributeForUser(
                    $input: IGQL_UpdateSingleAttributeForUserInput!
                ) {
                    updateSingleAttributeForUser(input: $input) {
                        pkName
                        pkValue
                        skName
                        skValue
                        attributeName
                        attributeValue
                    }
                }
            `,
            variables: { input },
        })

        return response.data.updateSingleAttributeForUser
    }

    async updateSingleArrayAttributeForUser(input: iUpdateSingleArrayAttributeForUserInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateSingleArrayAttributeForUser(
                    $input: IGQL_UpdateSingleArrayAttributeForUserInput!
                ) {
                    updateSingleArrayAttributeForUser(input: $input) {
                        pkName
                        pkValue
                        skName
                        skValue
                        attributeName
                        attributeValue
                    }
                }
            `,
            variables: { input },
        })

        return response.data.updateSingleArrayAttributeForUser
    }
}
