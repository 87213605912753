import { NgModule } from '@angular/core'
import { Routes, RouterModule, ExtraOptions } from '@angular/router'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { AuthGuardService } from './shared/guards/auth-guard.service'
import { DeactivationGuardGuard } from './shared/guards/deactivation-guard.guard'
import { PublicGuard } from './shared/guards/public.guard'


const routes: Routes = [
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full'
  },
  // {
  //   path: 'externalSource',
  //   // canActivate: [AuthGuardService],
  //   // component: AnyRandomComponent,
  //   resolve: {
  //     url: 'externalUrlRedirectResolver'
  //   },
  //   data: {
  //     externalUrl: 'http://www.google.com'
  //   },
  //   loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)
  // }, 
  {
    path: "public",
    // canActivate: [PublicGuard],
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    // canDeactivate: [DeactivationGuardGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'temp-conventions',
    canActivate: [AuthGuardService],
    // canDeactivate: [DeactivationGuardGuard],
    loadChildren: () => import('./temp-conventions/temp-conventions.module').then(m => m.TempConventionsModule)
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
]


const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  useHash: true, //! THIS WAS THE PROBLEM:  https://stackoverflow.com/questions/57511178/angular-8-url-loads-with-routerlink-but-doesnt-show-when-directly-access-in-t
  // anchorScrolling: 'enabled',
  // ...any other options you'd like to use


  // enableTracing: true //! turn this off when not debugging
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],

  // providers: [
  //   {
  //     provide: 'externalUrlRedirectResolver',
  //     useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  //       window.location.href = (route.data as any).externalUrl
  //       console.log('here we are...', (route.data as any).externalUrl)
  //     }
  //   }
  // ]
})
export class AppRoutingModule { }
