<!-- 
<div>
    <div *ngFor="let data of trendingData.games">
        <div class="trending-data">
            <div class="trending-data__title">
                {{ data.title }}
            </div>
        </div>
    </div>
</div>

 -->

<div class="card-container">
    <mat-card *ngFor="let game of trendingData.games; let i = index" class="game-card" (click)="openGameDetails(game)">
        <mat-card-header>
            <mat-card-title>{{ game.title }}</mat-card-title>
            <mat-card-subtitle>{{ game.subtitle }}</mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image *ngIf=" game.mainImageFiles[0]" [src]="game.mainImageFiles[0]" alt="Game image"
            class="game-image">
        <!-- <mat-card-content>
        <p>
          Some more detailed information about the game could go here, if available.
        </p>
      </mat-card-content> -->

        <div class="popularity-badge" [ngStyle]="{'background-color': getBadgeColor(i + 1)}">#{{ i + 1 }}</div>
    </mat-card>
</div>


<button style="background-color: white; box-shadow: none;" mat-raised-button class="gh-button gh-button-primary m-3 p-2"
    (click)="decayTrendingGames()">Admin Only
    (decay by * 0.9)</button>