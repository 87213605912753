<div [hidden]="!dataSource || dataSource.filteredData.length == 0">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" [hidden]="isMobileScreen">

        <ng-container matColumnDef="avatar">
            <th mat-header-cell *matHeaderCellDef></th>
            <td class="p-3" mat-cell *matCellDef="let element">
                <img class="avatar-img" src="{{element.mainImageFiles && element.mainImageFiles[0] ? element.mainImageFiles[0] : defaultUserImage}}" alt="">
            </td>
        </ng-container>

        <ng-container matColumnDef="from" *ngIf="isIncoming">
            <th mat-header-cell *matHeaderCellDef> From </th>
            <td mat-cell *matCellDef="let element">
                {{element.sentFromTitle}}
            </td>
        </ng-container>

        <ng-container matColumnDef="to" *ngIf="!isIncoming">
            <th mat-header-cell *matHeaderCellDef> To </th>
            <td class="p-3" mat-cell *matCellDef="let element">
                {{element.sentToTitle}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element">
                {{element.extendedProps.date | date: 'MM/dd/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Event Title </th>
            <td class="p-3" mat-cell *matCellDef="let element">
                {{element.extendedProps.eventTitle}} </td>
        </ng-container>

        <ng-container matColumnDef="haven">
            <th mat-header-cell *matHeaderCellDef> Haven </th>
            <td class="p-3" mat-cell *matCellDef="let element">
                {{element.extendedProps.havenTitle | noHavenSoDefault}} </td>
        </ng-container>

        <ng-container matColumnDef="organizer">
            <th mat-header-cell *matHeaderCellDef> Organizer </th>
            <td class="p-3" mat-cell *matCellDef="let element">
                {{element.extendedProps.eventOrganizer}} </td>
        </ng-container>

        <ng-container matColumnDef="delete" class="ml-2">
            <th mat-header-cell *matHeaderCellDef [hidden]="!hasDeleteButtons">Decline</th>
            <td mat-cell *matCellDef="let element" [hidden]="!hasDeleteButtons">
                <button mat-button (click)="declineIncomingInviteItem(element, 'request'); $event.stopPropagation()">
                    <mat-icon class="gh-delete-icon">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="confirm" class="ml-2" *ngIf="isIncoming">
            <th mat-header-cell *matHeaderCellDef [hidden]="!hasConfirmationButtons">Confirm</th>
            <td mat-cell *matCellDef="let element" [hidden]="!hasConfirmationButtons">
                <button mat-button (click)="confirmInvite(user.username, element, 'request'); $event.stopPropagation()">
                    <mat-icon class="gh-confirm-icon">check</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="seeInviteDetails(row)"></tr>
    </table>

    <mat-accordion class="gh-accordion" [hidden]="!isMobileScreen">
        <div *ngIf="dataSource">
            <mat-expansion-panel *ngFor="let element of dataSource.filteredData; let i = index" [expanded]="false" (opened)="onPanelOpened(i)" (closed)="onPanelClosed(i)" [ngClass]="{ selected: selectedIndex == i, stripe: i % 2 == 0 }">

                <mat-expansion-panel-header>

                    <mat-panel-title>
                        <img class="avatar-img" src="{{element.mainImageFiles && element.mainImageFiles[0] ? element.mainImageFiles[0] : defaultUserImage}}" alt="">
                    </mat-panel-title>

                    <mat-panel-description *ngIf="isIncoming">
                        {{element.sentFromTitle}}
                    </mat-panel-description>

                    <mat-panel-description *ngIf="!isIncoming">
                        {{element.sentToTitle}}
                    </mat-panel-description>


                    <mat-panel-description>
                        {{element.createdAt | date: 'MM/dd/yyyy'}}
                    </mat-panel-description>

                </mat-expansion-panel-header>

                <div class="row mx-0 panel-content">

                    <div class="col-12 text-center mb-2">
                        <mat-card-title class="gh-sm-title">
                            {{element.extendedProps.eventTitle}}
                        </mat-card-title>
                    </div>

                    <div class="col-12">
                        <span class="inline-span mr-2">
                            <mat-card-title class="gh-xxsm-title">
                                Haven:
                            </mat-card-title>
                        </span>
                        <span class="inline-span">
                            <mat-card-subtitle>
                                {{element.extendedProps.havenTitle | noHavenSoDefault}}
                            </mat-card-subtitle>
                        </span>
                    </div>

                    <div class="col-12">
                        <span class="inline-span mr-2">
                            <mat-card-title class="gh-xxsm-title">
                                Organizer:
                            </mat-card-title>
                        </span>
                        <span class="inline-span">
                            <mat-card-subtitle>
                                {{element.extendedProps.eventOrganizer}}
                            </mat-card-subtitle>
                        </span>
                    </div>

                </div>

                <mat-action-row class="action-row">
                    <div class="col-4">
                        <button mat-button (click)="declineIncomingInviteItem(element, 'request')" *ngIf="hasDeleteButtons">
                            <mat-icon class="gh-delete-icon">delete</mat-icon>
                        </button>
                    </div>
                    <div class="col-4">
                        <button mat-button (click)="confirmInvite(user.username, element, 'request')" *ngIf="hasConfirmationButtons">
                            <mat-icon class="gh-confirm-icon">check</mat-icon>
                        </button>
                    </div>
                    <div class="col-4">
                        <button mat-raised-button class="gh-button gh-button-primary" (click)="seeInviteDetails(element)">
                            Details
                        </button>
                    </div>
                </mat-action-row>

            </mat-expansion-panel>
        </div>
    </mat-accordion>

</div>


<div [hidden]="dataSource && dataSource.filteredData && dataSource.filteredData.length > 0">
    <app-no-data [_message]="'No data...'"></app-no-data>
</div>