import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
// import { NguCarouselConfig } from '@ngu/carousel'
import { BehaviorSubject } from 'rxjs'
import { ulid } from 'ulid'
import { StringHelper } from '../../../../../../../../../../common/helpers/string-helper'
import { COMMON_FOOD_CONTRIBUTIONS, COMMON_BEVERAGE_CONTRIBUTIONS } from '../../constants/gamenight-constants'
import { GameNightContributionTypeEnums } from '../../enums/gamenight-enums'

@Component({
  selector: 'app-request-gamenight-contribution[_sourceOfInput]',
  templateUrl: './request-gamenight-contribution.component.html',
  styleUrls: ['./request-gamenight-contribution.component.scss']
})
export class RequestGamenightContributionComponent implements OnInit {
  
  @Input() _sourceOfInput: string

  // carouselConfig: NguCarouselConfig = {
  carouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 4, all: 0 },
    load: 3,
    //interval: {timing: 4000, initialDelay: 1000},
    loop: true,
    touch: true,
    velocity: 0.2,
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  }

  defaultMainImage = window.location.origin + '/assets/images/defaults/placeholder-event.png'

  commonFoodContributions = COMMON_FOOD_CONTRIBUTIONS
  commonBeverageContributions = COMMON_BEVERAGE_CONTRIBUTIONS

  contributionType
  eventGamesContributedOrRequested
  eventFoodContributedOrRequested
  eventBeveragesContributedOrRequested
  gamesToContribute
  foodToContribute
  beveragesToContribute

  hostGames = new BehaviorSubject(null)

  games = new BehaviorSubject(null)
  games$ = this.games.asObservable()

  isLoading = new BehaviorSubject(true)
  isLoading$ = this.isLoading.asObservable()

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData

    this.eventGamesContributedOrRequested = JSON.parse(inputData.event.games)
    this.eventFoodContributedOrRequested = JSON.parse(inputData.event.food)
    this.eventBeveragesContributedOrRequested = JSON.parse(inputData.event.beverages)

    this.contributionType = inputData.contributionType

    switch (this.contributionType) {
      case GameNightContributionTypeEnums.games:
        this.initGamesToRequest()
        break

      case GameNightContributionTypeEnums.food:
      case GameNightContributionTypeEnums.beverages:
        this.isLoading.next(false)
        break
    }
  }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  @Output() closeEmitter = new EventEmitter()

  async initGamesToRequest() {
    this.games.next(this.inputData.guestCollections)
  }

  constructor() { }

  ngOnInit(): void {
  }

  getRequestorsString(requestors) {
    return StringHelper.createCommaSeparatedString(requestors, true)
  }

  async setGames(event) {
    this.gamesToContribute = []

    for (let gameSelected of event.value) {

      var requestors = [
        {
          pk: this.inputData.user.pk,
          title: this.inputData.user.title
        }
      ]
      for (let gameContributedOrRequested of this.eventGamesContributedOrRequested) {
        if (gameSelected.pk == gameContributedOrRequested.pk) {

          if (gameContributedOrRequested.requestorId && gameContributedOrRequested.requestorId != this.inputData.user.pk) {

            // await this.usersDbService.getUserByGSI1(gameContributedOrRequested.requestorId).then(user => {
            //   requestors.push(
            //     {
            //       pk: gameContributedOrRequested.requestorId,
            //       title: user[0].title
            //     }
            //   )
            // })

          }
        }
      }

      this.gamesToContribute.push({
        game: gameSelected,
        requestors: requestors
      })

    }
  }

  setFood(event) {
    var items = []
    for (let item of event) {
      items.push({ itemId: ulid().toLowerCase(), title: item })
    }
    this.foodToContribute = items
  }

  setBeverages(event) {
    var items = []
    for (let item of event) {
      items.push({ title: item })
    }
    this.beveragesToContribute = items
  }


  //? should this below be called requests? not contribute?
  submit() {
    switch (this.contributionType) {
      case GameNightContributionTypeEnums.games:
        this.closeEmitter.emit(this.gamesToContribute)
        break

      case GameNightContributionTypeEnums.food:
        this.closeEmitter.emit(this.foodToContribute)
        break

      case GameNightContributionTypeEnums.beverages:
        this.closeEmitter.emit(this.beveragesToContribute)
        break
    }
  }

}
