import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class StringHelperService {

  constructor() { }

  //* if items are objects, this algorithm will refer to the "title" attribute
  createCommaSeparatedString(list, itemsAreObjects): string {
    var result: string = ''

    for (var i = 0; i < list.length; i++) {
      if (i == list.length - 1) {
        result = itemsAreObjects ? result.concat(list[i].title) : result.concat(list[i])
      }
      else {
        result = itemsAreObjects ? result.concat(list[i].title).concat(', ') : result.concat(list[i]).concat(', ')
      }
    }
    return result
  }
  //* if items are objects, this algorithm will refer to the "title" attribute
  createCommaSeparatedStringUsername(list, itemsAreObjects): string {
    var result: string = ''
    for (var i = 0; i < list.length; i++) {
      if (i == list.length - 1) {
        result = itemsAreObjects ? result.concat(list[i].username) : result.concat(list[i])
      }
      else {
        result = itemsAreObjects ? result.concat(list[i].username).concat(', ') : result.concat(list[i]).concat(', ')
      }
    }
    return result
  }

  getInitials(input: string) {
    var initials: any = input.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  getRandomString(length?) {
    let strLength = length ? length : 15
    // substring starting at 2 is neccesary to avoid decimals
    return Math.random().toString(36).substring(2, strLength) + Math.random().toString(36).substring(2, strLength)
  }

  truncateString(input: string, maxCharacters: number) {

    if (input) {
      return input.length > maxCharacters ? input.substring(0, maxCharacters) + '...' : input
    }
  }

  extractSrcFromIframe(input: string) {
    var tmp = document.createElement('div')
    tmp.innerHTML = input
    var src = tmp.querySelector('iframe').getAttribute('src')
    return src
  }

  castFirstLetterOfEachWordToUppercase(input) {
    return input.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
  }
}
