<mat-card-content class="parent-container">

    <div class="row mb-3">
        <div class="col-12">
            <mat-toolbar class="gh-toolbar gh-toolbar-secondary">
                <span>{{title}}</span>
            </mat-toolbar>
        </div>
    </div>

    <div class="row data-group center-content" *ngIf="rows.length == 0">
        <mat-card-title class="gh-xsm-title">Start your story!</mat-card-title>
    </div>

    <div class="data-group" *ngFor="let r of rows; let i = index">


        <div class="row">

            <div class="col-12 text-right">
                <mat-icon class="gh-icon gh-edit-icon gh-icon-clickable mr-4" matTooltip="Edit"
                    matTooltipPosition="above" (click)="addContent(i)">
                    edit
                </mat-icon>
            </div>


            <!-- ! 1 COLUMN -->
            <div class="content" *ngIf="r.layoutId == 1">
                <div class="text-container" *ngIf="r.content[0].contentType == 'text' ">
                    {{r.content[0].content}}
                </div>

                <div class="image-container" *ngIf="r.content[0].contentType == 'image' ">
                    <div class="row">
                        <img [src]="r.content[0].content.selectedImage" alt="">
                    </div>
                </div>

                <div class="video-container video-container-single-column" *ngIf="r.content[0].contentType == 'video' ">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/TZB8HRrJ0KM" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>


            <!-- ! 2 COLUMNS -->
            <div class="content col-12" *ngIf="r.layoutId == 2">
                <div class="row">
                    <div class="col-6">
                        <div class="text-container" *ngIf="r.content[0].contentType == 'text' ">
                            {{r.content[0].content}}
                        </div>

                        <div class="image-container" *ngIf="r.content[0].contentType == 'image' ">
                            <div class="row">
                                <img [src]="r.content[0].content.selectedImage" alt="">
                            </div>
                        </div>

                        <div class="video-container video-container-single-column"
                            *ngIf="r.content[0].contentType == 'video' ">
                            <div class="row">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/TZB8HRrJ0KM"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="text-container" *ngIf="r.content[1].contentType == 'text' ">
                            {{r.content[1].content}}
                        </div>

                        <div class="image-container" *ngIf="r.content[1].contentType == 'image' ">
                            <div class="row">
                                <img [src]="r.content[1].content.selectedImage" alt="">
                            </div>
                        </div>

                        <div class="video-container video-container-single-column"
                            *ngIf="r.content[1].contentType == 'video' ">
                            <div class="row">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/CoUErwb53ys"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ! 3 COLUMNS -->
            <div class="content col-12" *ngIf="r.layoutId == 3">
                <div class="row">
                    <div class="col-4">
                        <div class="text-container" *ngIf="r.content[0].contentType == 'text' ">
                            {{r.content[0].content}}
                        </div>

                        <div class="image-container" *ngIf="r.content[0].contentType == 'image' ">
                            <div class="row">
                                <img [src]="r.content[0].content.selectedImage" alt="">
                            </div>
                        </div>

                        <div class="video-container video-container-single-column"
                            *ngIf="r.content[0].contentType == 'video' ">
                            <div class="row">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/TZB8HRrJ0KM"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="text-container" *ngIf="r.content[1].contentType == 'text' ">
                            {{r.content[1].content}}
                        </div>

                        <div class="image-container" *ngIf="r.content[1].contentType == 'image' ">
                            <div class="row">
                                <img [src]="r.content[1].content.selectedImage" alt="">
                            </div>
                        </div>

                        <div class="video-container video-container-single-column"
                            *ngIf="r.content[1].contentType == 'video' ">
                            <div class="row">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/CoUErwb53ys"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="text-container" *ngIf="r.content[2].contentType == 'text' ">
                            {{r.content[2].content}}
                        </div>

                        <div class="image-container" *ngIf="r.content[2].contentType == 'image' ">
                            <div class="row">
                                <img [src]="r.content[2].content.selectedImage" alt="">
                            </div>
                        </div>

                        <div class="video-container video-container-single-column"
                            *ngIf="r.content[2].contentType == 'video' ">
                            <div class="row">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/zdU635esPpQ"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>

    <div class="row">
        <button mat-button id="add-button" (click)="addContent()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

</mat-card-content>