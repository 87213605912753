import { Injectable } from '@angular/core'
import { S3 } from 'aws-sdk'
import { BehaviorSubject } from 'rxjs'
import { BackendApiS3Service } from 'src/app/backend-api-services/backend-api-s3.service'
import { FileTypeConversionService } from './file-type-conversion.service'

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  coreLocationData = new BehaviorSubject(null)
  coreLocationData$ = this.coreLocationData.asObservable()

  constructor(
    private fileTypeConversionService: FileTypeConversionService,
    private backendApiS3SAervice: BackendApiS3Service) {
    this.fetchCoreLocationDataFromS3()
  }

  async fetchCoreLocationDataFromS3() {
    const params = {
      bucketName: 'gh-files', 
      objectKey: 'geoData.csv'
    }

    //! having s3 issues so for now adding the data directly (see below: TEMP_GEO_DATA_JSON)
    // let fetchedObject = await this.backendApiS3SAervice.fetchS3Object(params)
    // let csvToJson = await this.fileTypeConversionService.convertCSVtoJSON(fetchedObject.body)
    // this.coreLocationData.next(csvToJson)

    this.coreLocationData.next(TEMP_GEO_DATA_JSON)
    
  }
}

export const TEMP_GEO_DATA_JSON = [
  {
    "zip": 99501,
    "lat": 61.22035,
    "lng": -149.85702,
    "state_id": "AK"
  },
  {
    "zip": 99502,
    "lat": 61.1633,
    "lng": -149.98643,
    "state_id": "AK"
  },
  {
    "zip": 99503,
    "lat": 61.90805,
    "lng": -156.85772,
    "state_id": "AK"
  },
  {
    "zip": 99504,
    "lat": 61.20399,
    "lng": -149.74703,
    "state_id": "AK"
  },
  {
    "zip": 99505,
    "lat": 61.26576,
    "lng": -149.63173,
    "state_id": "AK"
  },
  {
    "zip": 99506,
    "lat": 61.26308,
    "lng": -149.80536,
    "state_id": "AK"
  },
  {
    "zip": 99507,
    "lat": 61.14974,
    "lng": -149.77599,
    "state_id": "AK"
  },
  {
    "zip": 99508,
    "lat": 61.2007,
    "lng": -149.81448,
    "state_id": "AK"
  },
  {
    "zip": 99510,
    "lat": 70.13852,
    "lng": -149.9597,
    "state_id": "AK"
  },
  {
    "zip": 99513,
    "lat": 61.21477,
    "lng": -149.88566,
    "state_id": "AK"
  },
  {
    "zip": 99515,
    "lat": 61.11738,
    "lng": -149.88888,
    "state_id": "AK"
  },
  {
    "zip": 99516,
    "lat": 61.07142,
    "lng": -149.71837,
    "state_id": "AK"
  },
  {
    "zip": 99517,
    "lat": 61.19019,
    "lng": -149.9371,
    "state_id": "AK"
  },
  {
    "zip": 99518,
    "lat": 61.15958,
    "lng": -149.88479,
    "state_id": "AK"
  },
  {
    "zip": 99519,
    "lat": 68.93806,
    "lng": -146.32364,
    "state_id": "AK"
  },
  {
    "zip": 99540,
    "lat": 60.98608,
    "lng": -149.43046,
    "state_id": "AK"
  },
  {
    "zip": 99546,
    "lat": 51.84287,
    "lng": -176.63138,
    "state_id": "AK"
  },
  {
    "zip": 99547,
    "lat": 52.22951,
    "lng": -174.20967,
    "state_id": "AK"
  },
  {
    "zip": 99548,
    "lat": 56.24475,
    "lng": -158.75781,
    "state_id": "AK"
  },
  {
    "zip": 99549,
    "lat": 56.94163,
    "lng": -158.60007,
    "state_id": "AK"
  },
  {
    "zip": 99550,
    "lat": 57.90188,
    "lng": -153.02841,
    "state_id": "AK"
  },
  {
    "zip": 99551,
    "lat": 60.90927,
    "lng": -161.42021,
    "state_id": "AK"
  },
  {
    "zip": 99552,
    "lat": 60.90988,
    "lng": -161.22672,
    "state_id": "AK"
  },
  {
    "zip": 99553,
    "lat": 54.12863,
    "lng": -165.80591,
    "state_id": "AK"
  },
  {
    "zip": 99554,
    "lat": 62.69435,
    "lng": -164.64754,
    "state_id": "AK"
  },
  {
    "zip": 99555,
    "lat": 59.26833,
    "lng": -158.62828,
    "state_id": "AK"
  },
  {
    "zip": 99556,
    "lat": 59.86781,
    "lng": -151.49527,
    "state_id": "AK"
  },
  {
    "zip": 99557,
    "lat": 61.44112,
    "lng": -156.01864,
    "state_id": "AK"
  },
  {
    "zip": 99558,
    "lat": 62.6382,
    "lng": -160.21785,
    "state_id": "AK"
  },
  {
    "zip": 99559,
    "lat": 60.65377,
    "lng": -161.90541,
    "state_id": "AK"
  },
  {
    "zip": 99561,
    "lat": 60.15137,
    "lng": -164.25198,
    "state_id": "AK"
  },
  {
    "zip": 99563,
    "lat": 61.52911,
    "lng": -165.59086,
    "state_id": "AK"
  },
  {
    "zip": 99564,
    "lat": 56.29679,
    "lng": -158.41292,
    "state_id": "AK"
  },
  {
    "zip": 99565,
    "lat": 56.30377,
    "lng": -158.52926,
    "state_id": "AK"
  },
  {
    "zip": 99566,
    "lat": 61.28097,
    "lng": -142.78996,
    "state_id": "AK"
  },
  {
    "zip": 99567,
    "lat": 61.28823,
    "lng": -148.89429,
    "state_id": "AK"
  },
  {
    "zip": 99568,
    "lat": 60.21181,
    "lng": -151.39605,
    "state_id": "AK"
  },
  {
    "zip": 99569,
    "lat": 58.81725,
    "lng": -158.52855,
    "state_id": "AK"
  },
  {
    "zip": 99571,
    "lat": 55.38499,
    "lng": -162.44859,
    "state_id": "AK"
  },
  {
    "zip": 99572,
    "lat": 60.50805,
    "lng": -149.93761,
    "state_id": "AK"
  },
  {
    "zip": 99573,
    "lat": 61.62257,
    "lng": -145.99906,
    "state_id": "AK"
  },
  {
    "zip": 99574,
    "lat": 60.63145,
    "lng": -146.0407,
    "state_id": "AK"
  },
  {
    "zip": 99575,
    "lat": 61.8439,
    "lng": -158.10821,
    "state_id": "AK"
  },
  {
    "zip": 99576,
    "lat": 59.99003,
    "lng": -158.39441,
    "state_id": "AK"
  },
  {
    "zip": 99577,
    "lat": 61.19692,
    "lng": -149.24656,
    "state_id": "AK"
  },
  {
    "zip": 99578,
    "lat": 60.21583,
    "lng": -162.03136,
    "state_id": "AK"
  },
  {
    "zip": 99579,
    "lat": 58.20676,
    "lng": -157.37317,
    "state_id": "AK"
  },
  {
    "zip": 99580,
    "lat": 59.36499,
    "lng": -157.49239,
    "state_id": "AK"
  },
  {
    "zip": 99581,
    "lat": 62.78177,
    "lng": -164.53347,
    "state_id": "AK"
  },
  {
    "zip": 99583,
    "lat": 54.84751,
    "lng": -163.40253,
    "state_id": "AK"
  },
  {
    "zip": 99585,
    "lat": 61.9287,
    "lng": -162.26794,
    "state_id": "AK"
  },
  {
    "zip": 99586,
    "lat": 62.66801,
    "lng": -144.11183,
    "state_id": "AK"
  },
  {
    "zip": 99587,
    "lat": 60.92414,
    "lng": -148.92413,
    "state_id": "AK"
  },
  {
    "zip": 99588,
    "lat": 62.3338,
    "lng": -146.72325,
    "state_id": "AK"
  },
  {
    "zip": 99589,
    "lat": 59.12749,
    "lng": -161.56874,
    "state_id": "AK"
  },
  {
    "zip": 99590,
    "lat": 62.89938,
    "lng": -160.10742,
    "state_id": "AK"
  },
  {
    "zip": 99591,
    "lat": 56.58095,
    "lng": -169.6154,
    "state_id": "AK"
  },
  {
    "zip": 99602,
    "lat": 62.18471,
    "lng": -159.84482,
    "state_id": "AK"
  },
  {
    "zip": 99603,
    "lat": 59.52457,
    "lng": -151.22871,
    "state_id": "AK"
  },
  {
    "zip": 99604,
    "lat": 61.52785,
    "lng": -166.11506,
    "state_id": "AK"
  },
  {
    "zip": 99605,
    "lat": 60.79358,
    "lng": -149.54918,
    "state_id": "AK"
  },
  {
    "zip": 99606,
    "lat": 59.79399,
    "lng": -154.64438,
    "state_id": "AK"
  },
  {
    "zip": 99607,
    "lat": 61.75722,
    "lng": -159.78569,
    "state_id": "AK"
  },
  {
    "zip": 99609,
    "lat": 60.87848,
    "lng": -162.52724,
    "state_id": "AK"
  },
  {
    "zip": 99610,
    "lat": 60.24933,
    "lng": -150.42907,
    "state_id": "AK"
  },
  {
    "zip": 99611,
    "lat": 60.71833,
    "lng": -150.98582,
    "state_id": "AK"
  },
  {
    "zip": 99612,
    "lat": 55.06329,
    "lng": -162.28781,
    "state_id": "AK"
  },
  {
    "zip": 99613,
    "lat": 58.98855,
    "lng": -155.56021,
    "state_id": "AK"
  },
  {
    "zip": 99614,
    "lat": 59.93729,
    "lng": -164.06156,
    "state_id": "AK"
  },
  {
    "zip": 99615,
    "lat": 57.58324,
    "lng": -153.40116,
    "state_id": "AK"
  },
  {
    "zip": 99620,
    "lat": 63.02924,
    "lng": -163.54621,
    "state_id": "AK"
  },
  {
    "zip": 99621,
    "lat": 60.79526,
    "lng": -161.40056,
    "state_id": "AK"
  },
  {
    "zip": 99622,
    "lat": 59.87422,
    "lng": -163.20433,
    "state_id": "AK"
  },
  {
    "zip": 99624,
    "lat": 57.50398,
    "lng": -153.907,
    "state_id": "AK"
  },
  {
    "zip": 99625,
    "lat": 59.28919,
    "lng": -156.65062,
    "state_id": "AK"
  },
  {
    "zip": 99626,
    "lat": 61.51702,
    "lng": -160.3647,
    "state_id": "AK"
  },
  {
    "zip": 99627,
    "lat": 63.53161,
    "lng": -154.62942,
    "state_id": "AK"
  },
  {
    "zip": 99628,
    "lat": 58.95135,
    "lng": -159.17597,
    "state_id": "AK"
  },
  {
    "zip": 99630,
    "lat": 60.3719,
    "lng": -166.26399,
    "state_id": "AK"
  },
  {
    "zip": 99631,
    "lat": 60.6149,
    "lng": -149.40537,
    "state_id": "AK"
  },
  {
    "zip": 99632,
    "lat": 62.11232,
    "lng": -163.66453,
    "state_id": "AK"
  },
  {
    "zip": 99633,
    "lat": 58.78297,
    "lng": -156.8984,
    "state_id": "AK"
  },
  {
    "zip": 99634,
    "lat": 60.6884,
    "lng": -161.9884,
    "state_id": "AK"
  },
  {
    "zip": 99636,
    "lat": 59.48829,
    "lng": -157.29135,
    "state_id": "AK"
  },
  {
    "zip": 99637,
    "lat": 60.54299,
    "lng": -165.14307,
    "state_id": "AK"
  },
  {
    "zip": 99638,
    "lat": 52.89995,
    "lng": -168.93738,
    "state_id": "AK"
  },
  {
    "zip": 99639,
    "lat": 60.06779,
    "lng": -151.40405,
    "state_id": "AK"
  },
  {
    "zip": 99640,
    "lat": 60.30969,
    "lng": -155.22855,
    "state_id": "AK"
  },
  {
    "zip": 99641,
    "lat": 60.88727,
    "lng": -162.46287,
    "state_id": "AK"
  },
  {
    "zip": 99643,
    "lat": 57.21747,
    "lng": -153.34422,
    "state_id": "AK"
  },
  {
    "zip": 99644,
    "lat": 57.9241,
    "lng": -152.42219,
    "state_id": "AK"
  },
  {
    "zip": 99645,
    "lat": 61.6303,
    "lng": -148.98707,
    "state_id": "AK"
  },
  {
    "zip": 99647,
    "lat": 59.86805,
    "lng": -154.07573,
    "state_id": "AK"
  },
  {
    "zip": 99648,
    "lat": 55.93061,
    "lng": -159.15742,
    "state_id": "AK"
  },
  {
    "zip": 99649,
    "lat": 57.51254,
    "lng": -157.32341,
    "state_id": "AK"
  },
  {
    "zip": 99650,
    "lat": 61.94214,
    "lng": -162.88555,
    "state_id": "AK"
  },
  {
    "zip": 99651,
    "lat": 59.00994,
    "lng": -161.71141,
    "state_id": "AK"
  },
  {
    "zip": 99652,
    "lat": 61.53464,
    "lng": -149.97233,
    "state_id": "AK"
  },
  {
    "zip": 99653,
    "lat": 60.19559,
    "lng": -154.32566,
    "state_id": "AK"
  },
  {
    "zip": 99654,
    "lat": 61.45483,
    "lng": -149.90046,
    "state_id": "AK"
  },
  {
    "zip": 99655,
    "lat": 59.74746,
    "lng": -161.88869,
    "state_id": "AK"
  },
  {
    "zip": 99656,
    "lat": 61.85716,
    "lng": -157.54468,
    "state_id": "AK"
  },
  {
    "zip": 99657,
    "lat": 61.77418,
    "lng": -161.39436,
    "state_id": "AK"
  },
  {
    "zip": 99658,
    "lat": 62.07635,
    "lng": -163.28385,
    "state_id": "AK"
  },
  {
    "zip": 99659,
    "lat": 63.4738,
    "lng": -162.12295,
    "state_id": "AK"
  },
  {
    "zip": 99660,
    "lat": 57.17997,
    "lng": -170.27905,
    "state_id": "AK"
  },
  {
    "zip": 99661,
    "lat": 55.27154,
    "lng": -160.67506,
    "state_id": "AK"
  },
  {
    "zip": 99662,
    "lat": 61.79181,
    "lng": -165.96115,
    "state_id": "AK"
  },
  {
    "zip": 99663,
    "lat": 59.39411,
    "lng": -151.62731,
    "state_id": "AK"
  },
  {
    "zip": 99664,
    "lat": 60.10787,
    "lng": -149.48485,
    "state_id": "AK"
  },
  {
    "zip": 99665,
    "lat": 62.64632,
    "lng": -159.52715,
    "state_id": "AK"
  },
  {
    "zip": 99666,
    "lat": 62.45302,
    "lng": -165.09876,
    "state_id": "AK"
  },
  {
    "zip": 99667,
    "lat": 61.82399,
    "lng": -151.78293,
    "state_id": "AK"
  },
  {
    "zip": 99668,
    "lat": 61.68735,
    "lng": -157.13723,
    "state_id": "AK"
  },
  {
    "zip": 99669,
    "lat": 60.41451,
    "lng": -151.00342,
    "state_id": "AK"
  },
  {
    "zip": 99670,
    "lat": 58.66586,
    "lng": -156.97213,
    "state_id": "AK"
  },
  {
    "zip": 99671,
    "lat": 63.48182,
    "lng": -162.25352,
    "state_id": "AK"
  },
  {
    "zip": 99672,
    "lat": 60.73292,
    "lng": -150.72816,
    "state_id": "AK"
  },
  {
    "zip": 99674,
    "lat": 61.70957,
    "lng": -148.16,
    "state_id": "AK"
  },
  {
    "zip": 99676,
    "lat": 62.45788,
    "lng": -149.7039,
    "state_id": "AK"
  },
  {
    "zip": 99677,
    "lat": 60.89214,
    "lng": -146.67253,
    "state_id": "AK"
  },
  {
    "zip": 99678,
    "lat": 59.33283,
    "lng": -160.10846,
    "state_id": "AK"
  },
  {
    "zip": 99679,
    "lat": 61.10123,
    "lng": -160.94452,
    "state_id": "AK"
  },
  {
    "zip": 99680,
    "lat": 60.38434,
    "lng": -162.68382,
    "state_id": "AK"
  },
  {
    "zip": 99681,
    "lat": 60.60681,
    "lng": -165.12855,
    "state_id": "AK"
  },
  {
    "zip": 99682,
    "lat": 60.62609,
    "lng": -152.78508,
    "state_id": "AK"
  },
  {
    "zip": 99683,
    "lat": 62.5018,
    "lng": -151.00458,
    "state_id": "AK"
  },
  {
    "zip": 99684,
    "lat": 64.08944,
    "lng": -160.3305,
    "state_id": "AK"
  },
  {
    "zip": 99685,
    "lat": 53.876,
    "lng": -166.47449,
    "state_id": "AK"
  },
  {
    "zip": 99686,
    "lat": 61.12201,
    "lng": -146.82103,
    "state_id": "AK"
  },
  {
    "zip": 99688,
    "lat": 61.90932,
    "lng": -150.03682,
    "state_id": "AK"
  },
  {
    "zip": 99689,
    "lat": 59.52058,
    "lng": -139.48834,
    "state_id": "AK"
  },
  {
    "zip": 99690,
    "lat": 60.48717,
    "lng": -164.81892,
    "state_id": "AK"
  },
  {
    "zip": 99691,
    "lat": 62.8638,
    "lng": -153.66044,
    "state_id": "AK"
  },
  {
    "zip": 99692,
    "lat": 53.88553,
    "lng": -166.53864,
    "state_id": "AK"
  },
  {
    "zip": 99693,
    "lat": 61.38199,
    "lng": -148.52108,
    "state_id": "AK"
  },
  {
    "zip": 99694,
    "lat": 61.62107,
    "lng": -149.79624,
    "state_id": "AK"
  },
  {
    "zip": 99695,
    "lat": 55.91828,
    "lng": -159.49335,
    "state_id": "AK"
  },
  {
    "zip": 99701,
    "lat": 67.16977,
    "lng": -149.56917,
    "state_id": "AK"
  },
  {
    "zip": 99702,
    "lat": 64.64824,
    "lng": -147.04901,
    "state_id": "AK"
  },
  {
    "zip": 99703,
    "lat": 64.8328,
    "lng": -147.62322,
    "state_id": "AK"
  },
  {
    "zip": 99704,
    "lat": 64.2966,
    "lng": -149.16084,
    "state_id": "AK"
  },
  {
    "zip": 99705,
    "lat": 64.76239,
    "lng": -147.31219,
    "state_id": "AK"
  },
  {
    "zip": 99706,
    "lat": 64.02799,
    "lng": -144.63394,
    "state_id": "AK"
  },
  {
    "zip": 99709,
    "lat": 64.87812,
    "lng": -148.21561,
    "state_id": "AK"
  },
  {
    "zip": 99712,
    "lat": 64.99326,
    "lng": -146.14966,
    "state_id": "AK"
  },
  {
    "zip": 99714,
    "lat": 64.4327,
    "lng": -146.67125,
    "state_id": "AK"
  },
  {
    "zip": 99720,
    "lat": 66.56354,
    "lng": -152.77606,
    "state_id": "AK"
  },
  {
    "zip": 99721,
    "lat": 68.15448,
    "lng": -151.70965,
    "state_id": "AK"
  },
  {
    "zip": 99722,
    "lat": 68.09545,
    "lng": -145.6102,
    "state_id": "AK"
  },
  {
    "zip": 99723,
    "lat": 71.27376,
    "lng": -156.74522,
    "state_id": "AK"
  },
  {
    "zip": 99724,
    "lat": 66.38311,
    "lng": -147.32171,
    "state_id": "AK"
  },
  {
    "zip": 99726,
    "lat": 67.11836,
    "lng": -152.15661,
    "state_id": "AK"
  },
  {
    "zip": 99727,
    "lat": 65.97587,
    "lng": -161.13877,
    "state_id": "AK"
  },
  {
    "zip": 99729,
    "lat": 63.18325,
    "lng": -148.50259,
    "state_id": "AK"
  },
  {
    "zip": 99730,
    "lat": 65.61511,
    "lng": -145.25129,
    "state_id": "AK"
  },
  {
    "zip": 99732,
    "lat": 64.07171,
    "lng": -142.00646,
    "state_id": "AK"
  },
  {
    "zip": 99733,
    "lat": 65.79284,
    "lng": -144.18283,
    "state_id": "AK"
  },
  {
    "zip": 99734,
    "lat": 70.36857,
    "lng": -148.99193,
    "state_id": "AK"
  },
  {
    "zip": 99736,
    "lat": 66.08489,
    "lng": -162.76104,
    "state_id": "AK"
  },
  {
    "zip": 99737,
    "lat": 63.79603,
    "lng": -145.07326,
    "state_id": "AK"
  },
  {
    "zip": 99738,
    "lat": 65.20895,
    "lng": -142.18305,
    "state_id": "AK"
  },
  {
    "zip": 99739,
    "lat": 64.96744,
    "lng": -162.54862,
    "state_id": "AK"
  },
  {
    "zip": 99740,
    "lat": 67.53438,
    "lng": -143.74514,
    "state_id": "AK"
  },
  {
    "zip": 99741,
    "lat": 64.7388,
    "lng": -156.85894,
    "state_id": "AK"
  },
  {
    "zip": 99742,
    "lat": 63.75233,
    "lng": -171.68815,
    "state_id": "AK"
  },
  {
    "zip": 99743,
    "lat": 63.7838,
    "lng": -150.12799,
    "state_id": "AK"
  },
  {
    "zip": 99744,
    "lat": 64.30627,
    "lng": -149.18016,
    "state_id": "AK"
  },
  {
    "zip": 99745,
    "lat": 66.04309,
    "lng": -154.2477,
    "state_id": "AK"
  },
  {
    "zip": 99746,
    "lat": 65.57286,
    "lng": -158.11934,
    "state_id": "AK"
  },
  {
    "zip": 99747,
    "lat": 70.11849,
    "lng": -143.66337,
    "state_id": "AK"
  },
  {
    "zip": 99748,
    "lat": 64.32647,
    "lng": -158.76751,
    "state_id": "AK"
  },
  {
    "zip": 99749,
    "lat": 66.97844,
    "lng": -160.43177,
    "state_id": "AK"
  },
  {
    "zip": 99750,
    "lat": 67.73135,
    "lng": -164.54955,
    "state_id": "AK"
  },
  {
    "zip": 99751,
    "lat": 66.91926,
    "lng": -156.87006,
    "state_id": "AK"
  },
  {
    "zip": 99752,
    "lat": 67.29134,
    "lng": -161.98514,
    "state_id": "AK"
  },
  {
    "zip": 99753,
    "lat": 64.93968,
    "lng": -161.15126,
    "state_id": "AK"
  },
  {
    "zip": 99754,
    "lat": 64.90102,
    "lng": -157.69469,
    "state_id": "AK"
  },
  {
    "zip": 99755,
    "lat": 63.69999,
    "lng": -148.62169,
    "state_id": "AK"
  },
  {
    "zip": 99756,
    "lat": 64.58631,
    "lng": -151.84672,
    "state_id": "AK"
  },
  {
    "zip": 99757,
    "lat": 63.751,
    "lng": -152.75093,
    "state_id": "AK"
  },
  {
    "zip": 99758,
    "lat": 65.15175,
    "lng": -149.37232,
    "state_id": "AK"
  },
  {
    "zip": 99759,
    "lat": 69.73126,
    "lng": -162.89793,
    "state_id": "AK"
  },
  {
    "zip": 99760,
    "lat": 64.21369,
    "lng": -148.23073,
    "state_id": "AK"
  },
  {
    "zip": 99761,
    "lat": 67.60042,
    "lng": -163.03461,
    "state_id": "AK"
  },
  {
    "zip": 99762,
    "lat": 64.8457,
    "lng": -164.46646,
    "state_id": "AK"
  },
  {
    "zip": 99763,
    "lat": 66.82841,
    "lng": -161.03646,
    "state_id": "AK"
  },
  {
    "zip": 99764,
    "lat": 63.38147,
    "lng": -141.51133,
    "state_id": "AK"
  },
  {
    "zip": 99765,
    "lat": 64.72921,
    "lng": -158.13536,
    "state_id": "AK"
  },
  {
    "zip": 99766,
    "lat": 68.65065,
    "lng": -164.95841,
    "state_id": "AK"
  },
  {
    "zip": 99767,
    "lat": 65.50221,
    "lng": -150.15117,
    "state_id": "AK"
  },
  {
    "zip": 99768,
    "lat": 65.11675,
    "lng": -154.87994,
    "state_id": "AK"
  },
  {
    "zip": 99769,
    "lat": 63.67959,
    "lng": -170.48517,
    "state_id": "AK"
  },
  {
    "zip": 99770,
    "lat": 66.76682,
    "lng": -159.47864,
    "state_id": "AK"
  },
  {
    "zip": 99771,
    "lat": 64.36007,
    "lng": -161.20314,
    "state_id": "AK"
  },
  {
    "zip": 99772,
    "lat": 65.98303,
    "lng": -165.79795,
    "state_id": "AK"
  },
  {
    "zip": 99773,
    "lat": 66.88758,
    "lng": -157.16496,
    "state_id": "AK"
  },
  {
    "zip": 99774,
    "lat": 66.01942,
    "lng": -149.0769,
    "state_id": "AK"
  },
  {
    "zip": 99775,
    "lat": 64.85849,
    "lng": -147.82472,
    "state_id": "AK"
  },
  {
    "zip": 99776,
    "lat": 63.38825,
    "lng": -143.4114,
    "state_id": "AK"
  },
  {
    "zip": 99777,
    "lat": 65.20884,
    "lng": -152.05621,
    "state_id": "AK"
  },
  {
    "zip": 99778,
    "lat": 65.15447,
    "lng": -166.29698,
    "state_id": "AK"
  },
  {
    "zip": 99780,
    "lat": 63.19468,
    "lng": -143.07797,
    "state_id": "AK"
  },
  {
    "zip": 99781,
    "lat": 67.47704,
    "lng": -146.01947,
    "state_id": "AK"
  },
  {
    "zip": 99782,
    "lat": 70.64066,
    "lng": -159.93042,
    "state_id": "AK"
  },
  {
    "zip": 99783,
    "lat": 65.66335,
    "lng": -168.02324,
    "state_id": "AK"
  },
  {
    "zip": 99784,
    "lat": 64.68209,
    "lng": -163.40587,
    "state_id": "AK"
  },
  {
    "zip": 99785,
    "lat": 65.34195,
    "lng": -166.49262,
    "state_id": "AK"
  },
  {
    "zip": 99786,
    "lat": 67.17323,
    "lng": -155.95418,
    "state_id": "AK"
  },
  {
    "zip": 99788,
    "lat": 66.64632,
    "lng": -143.7814,
    "state_id": "AK"
  },
  {
    "zip": 99789,
    "lat": 70.02976,
    "lng": -151.86118,
    "state_id": "AK"
  },
  {
    "zip": 99790,
    "lat": 65.42127,
    "lng": -148.28799,
    "state_id": "AK"
  },
  {
    "zip": 99791,
    "lat": 70.56099,
    "lng": -156.63867,
    "state_id": "AK"
  },
  {
    "zip": 99801,
    "lat": 58.39767,
    "lng": -134.05518,
    "state_id": "AK"
  },
  {
    "zip": 99820,
    "lat": 57.43987,
    "lng": -134.19673,
    "state_id": "AK"
  },
  {
    "zip": 99824,
    "lat": 58.27236,
    "lng": -134.40082,
    "state_id": "AK"
  },
  {
    "zip": 99825,
    "lat": 58.13599,
    "lng": -135.87267,
    "state_id": "AK"
  },
  {
    "zip": 99826,
    "lat": 58.75177,
    "lng": -136.56804,
    "state_id": "AK"
  },
  {
    "zip": 99827,
    "lat": 59.12421,
    "lng": -135.70688,
    "state_id": "AK"
  },
  {
    "zip": 99829,
    "lat": 58.10738,
    "lng": -135.42585,
    "state_id": "AK"
  },
  {
    "zip": 99830,
    "lat": 56.77903,
    "lng": -134.16029,
    "state_id": "AK"
  },
  {
    "zip": 99832,
    "lat": 58.01535,
    "lng": -136.17832,
    "state_id": "AK"
  },
  {
    "zip": 99833,
    "lat": 56.88523,
    "lng": -133.08513,
    "state_id": "AK"
  },
  {
    "zip": 99835,
    "lat": 56.96056,
    "lng": -135.05446,
    "state_id": "AK"
  },
  {
    "zip": 99836,
    "lat": 56.23548,
    "lng": -134.65335,
    "state_id": "AK"
  },
  {
    "zip": 99840,
    "lat": 59.56292,
    "lng": -135.33619,
    "state_id": "AK"
  },
  {
    "zip": 99841,
    "lat": 57.79433,
    "lng": -135.18486,
    "state_id": "AK"
  },
  {
    "zip": 99901,
    "lat": 55.56908,
    "lng": -131.01273,
    "state_id": "AK"
  },
  {
    "zip": 99903,
    "lat": 55.82123,
    "lng": -132.05943,
    "state_id": "AK"
  },
  {
    "zip": 99918,
    "lat": 55.94572,
    "lng": -132.74165,
    "state_id": "AK"
  },
  {
    "zip": 99919,
    "lat": 55.65107,
    "lng": -132.50367,
    "state_id": "AK"
  },
  {
    "zip": 99921,
    "lat": 55.45483,
    "lng": -132.77225,
    "state_id": "AK"
  },
  {
    "zip": 99922,
    "lat": 55.30211,
    "lng": -133.03248,
    "state_id": "AK"
  },
  {
    "zip": 99923,
    "lat": 55.97796,
    "lng": -130.03671,
    "state_id": "AK"
  },
  {
    "zip": 99925,
    "lat": 55.55796,
    "lng": -132.97482,
    "state_id": "AK"
  },
  {
    "zip": 99926,
    "lat": 55.12617,
    "lng": -131.48928,
    "state_id": "AK"
  },
  {
    "zip": 99927,
    "lat": 56.251,
    "lng": -133.37572,
    "state_id": "AK"
  },
  {
    "zip": 99929,
    "lat": 56.3695,
    "lng": -131.93648,
    "state_id": "AK"
  },
  {
    "zip": 35004,
    "lat": 33.60281,
    "lng": -86.49612,
    "state_id": "AL"
  },
  {
    "zip": 35005,
    "lat": 33.59515,
    "lng": -87.00089,
    "state_id": "AL"
  },
  {
    "zip": 35006,
    "lat": 33.42922,
    "lng": -87.19708,
    "state_id": "AL"
  },
  {
    "zip": 35007,
    "lat": 33.21591,
    "lng": -86.79717,
    "state_id": "AL"
  },
  {
    "zip": 35010,
    "lat": 32.91644,
    "lng": -85.9368,
    "state_id": "AL"
  },
  {
    "zip": 35013,
    "lat": 33.90164,
    "lng": -86.51785,
    "state_id": "AL"
  },
  {
    "zip": 35014,
    "lat": 33.35989,
    "lng": -86.26918,
    "state_id": "AL"
  },
  {
    "zip": 35016,
    "lat": 34.32333,
    "lng": -86.50142,
    "state_id": "AL"
  },
  {
    "zip": 35019,
    "lat": 34.29852,
    "lng": -86.63523,
    "state_id": "AL"
  },
  {
    "zip": 35020,
    "lat": 33.40271,
    "lng": -86.95158,
    "state_id": "AL"
  },
  {
    "zip": 35022,
    "lat": 33.32251,
    "lng": -86.96578,
    "state_id": "AL"
  },
  {
    "zip": 35023,
    "lat": 33.46814,
    "lng": -87.09265,
    "state_id": "AL"
  },
  {
    "zip": 35031,
    "lat": 34.10155,
    "lng": -86.54752,
    "state_id": "AL"
  },
  {
    "zip": 35032,
    "lat": 33.26492,
    "lng": -86.33644,
    "state_id": "AL"
  },
  {
    "zip": 35033,
    "lat": 33.94235,
    "lng": -87.02962,
    "state_id": "AL"
  },
  {
    "zip": 35034,
    "lat": 32.91192,
    "lng": -87.26984,
    "state_id": "AL"
  },
  {
    "zip": 35035,
    "lat": 33.04271,
    "lng": -86.97382,
    "state_id": "AL"
  },
  {
    "zip": 35036,
    "lat": 33.64396,
    "lng": -86.91957,
    "state_id": "AL"
  },
  {
    "zip": 35040,
    "lat": 33.10386,
    "lng": -86.73004,
    "state_id": "AL"
  },
  {
    "zip": 35042,
    "lat": 32.95455,
    "lng": -87.11481,
    "state_id": "AL"
  },
  {
    "zip": 35043,
    "lat": 33.31369,
    "lng": -86.65872,
    "state_id": "AL"
  },
  {
    "zip": 35044,
    "lat": 33.2489,
    "lng": -86.37289,
    "state_id": "AL"
  },
  {
    "zip": 35045,
    "lat": 32.80704,
    "lng": -86.67285,
    "state_id": "AL"
  },
  {
    "zip": 35046,
    "lat": 32.92285,
    "lng": -86.54504,
    "state_id": "AL"
  },
  {
    "zip": 35049,
    "lat": 33.95599,
    "lng": -86.59995,
    "state_id": "AL"
  },
  {
    "zip": 35051,
    "lat": 33.20546,
    "lng": -86.61972,
    "state_id": "AL"
  },
  {
    "zip": 35052,
    "lat": 33.59174,
    "lng": -86.40848,
    "state_id": "AL"
  },
  {
    "zip": 35053,
    "lat": 34.05329,
    "lng": -87.0679,
    "state_id": "AL"
  },
  {
    "zip": 35054,
    "lat": 33.50959,
    "lng": -86.32867,
    "state_id": "AL"
  },
  {
    "zip": 35055,
    "lat": 34.15398,
    "lng": -86.75907,
    "state_id": "AL"
  },
  {
    "zip": 35057,
    "lat": 34.13789,
    "lng": -86.9604,
    "state_id": "AL"
  },
  {
    "zip": 35058,
    "lat": 34.23134,
    "lng": -86.73788,
    "state_id": "AL"
  },
  {
    "zip": 35060,
    "lat": 33.55859,
    "lng": -86.94668,
    "state_id": "AL"
  },
  {
    "zip": 35061,
    "lat": 33.472,
    "lng": -86.95597,
    "state_id": "AL"
  },
  {
    "zip": 35062,
    "lat": 33.72907,
    "lng": -87.02039,
    "state_id": "AL"
  },
  {
    "zip": 35063,
    "lat": 33.81953,
    "lng": -87.00753,
    "state_id": "AL"
  },
  {
    "zip": 35064,
    "lat": 33.47599,
    "lng": -86.9203,
    "state_id": "AL"
  },
  {
    "zip": 35068,
    "lat": 33.60745,
    "lng": -86.83098,
    "state_id": "AL"
  },
  {
    "zip": 35070,
    "lat": 34.00977,
    "lng": -86.75419,
    "state_id": "AL"
  },
  {
    "zip": 35071,
    "lat": 33.70487,
    "lng": -86.84365,
    "state_id": "AL"
  },
  {
    "zip": 35072,
    "lat": 33.08977,
    "lng": -86.06138,
    "state_id": "AL"
  },
  {
    "zip": 35073,
    "lat": 33.65407,
    "lng": -86.98034,
    "state_id": "AL"
  },
  {
    "zip": 35074,
    "lat": 33.22242,
    "lng": -87.12425,
    "state_id": "AL"
  },
  {
    "zip": 35077,
    "lat": 34.02052,
    "lng": -86.83216,
    "state_id": "AL"
  },
  {
    "zip": 35078,
    "lat": 33.32336,
    "lng": -86.44235,
    "state_id": "AL"
  },
  {
    "zip": 35079,
    "lat": 33.94055,
    "lng": -86.75551,
    "state_id": "AL"
  },
  {
    "zip": 35080,
    "lat": 33.25974,
    "lng": -86.91282,
    "state_id": "AL"
  },
  {
    "zip": 35082,
    "lat": 33.16334,
    "lng": -86.15567,
    "state_id": "AL"
  },
  {
    "zip": 35083,
    "lat": 34.19826,
    "lng": -86.59913,
    "state_id": "AL"
  },
  {
    "zip": 35085,
    "lat": 32.97961,
    "lng": -86.73684,
    "state_id": "AL"
  },
  {
    "zip": 35087,
    "lat": 34.30638,
    "lng": -86.58434,
    "state_id": "AL"
  },
  {
    "zip": 35089,
    "lat": 32.93806,
    "lng": -86.08228,
    "state_id": "AL"
  },
  {
    "zip": 35091,
    "lat": 33.77929,
    "lng": -86.78959,
    "state_id": "AL"
  },
  {
    "zip": 35094,
    "lat": 33.52089,
    "lng": -86.55997,
    "state_id": "AL"
  },
  {
    "zip": 35096,
    "lat": 33.62053,
    "lng": -86.10863,
    "state_id": "AL"
  },
  {
    "zip": 35097,
    "lat": 33.89437,
    "lng": -86.62614,
    "state_id": "AL"
  },
  {
    "zip": 35098,
    "lat": 34.12469,
    "lng": -87.04159,
    "state_id": "AL"
  },
  {
    "zip": 35111,
    "lat": 33.28424,
    "lng": -87.09455,
    "state_id": "AL"
  },
  {
    "zip": 35112,
    "lat": 33.68968,
    "lng": -86.47078,
    "state_id": "AL"
  },
  {
    "zip": 35114,
    "lat": 33.22306,
    "lng": -86.87245,
    "state_id": "AL"
  },
  {
    "zip": 35115,
    "lat": 33.12002,
    "lng": -86.89578,
    "state_id": "AL"
  },
  {
    "zip": 35116,
    "lat": 33.73134,
    "lng": -86.76643,
    "state_id": "AL"
  },
  {
    "zip": 35117,
    "lat": 33.67295,
    "lng": -86.89677,
    "state_id": "AL"
  },
  {
    "zip": 35118,
    "lat": 33.5372,
    "lng": -87.05146,
    "state_id": "AL"
  },
  {
    "zip": 35119,
    "lat": 33.64301,
    "lng": -86.77362,
    "state_id": "AL"
  },
  {
    "zip": 35120,
    "lat": 33.66653,
    "lng": -86.42303,
    "state_id": "AL"
  },
  {
    "zip": 35121,
    "lat": 33.94125,
    "lng": -86.45527,
    "state_id": "AL"
  },
  {
    "zip": 35124,
    "lat": 33.31347,
    "lng": -86.76317,
    "state_id": "AL"
  },
  {
    "zip": 35125,
    "lat": 33.62576,
    "lng": -86.29434,
    "state_id": "AL"
  },
  {
    "zip": 35126,
    "lat": 33.73148,
    "lng": -86.65417,
    "state_id": "AL"
  },
  {
    "zip": 35127,
    "lat": 33.49167,
    "lng": -86.97639,
    "state_id": "AL"
  },
  {
    "zip": 35128,
    "lat": 33.54888,
    "lng": -86.3428,
    "state_id": "AL"
  },
  {
    "zip": 35130,
    "lat": 33.63367,
    "lng": -87.13403,
    "state_id": "AL"
  },
  {
    "zip": 35131,
    "lat": 33.73377,
    "lng": -86.1861,
    "state_id": "AL"
  },
  {
    "zip": 35133,
    "lat": 33.81871,
    "lng": -86.59957,
    "state_id": "AL"
  },
  {
    "zip": 35135,
    "lat": 33.61662,
    "lng": -86.1996,
    "state_id": "AL"
  },
  {
    "zip": 35136,
    "lat": 32.87839,
    "lng": -86.29571,
    "state_id": "AL"
  },
  {
    "zip": 35139,
    "lat": 33.71143,
    "lng": -86.97048,
    "state_id": "AL"
  },
  {
    "zip": 35143,
    "lat": 33.08733,
    "lng": -86.55295,
    "state_id": "AL"
  },
  {
    "zip": 35146,
    "lat": 33.79556,
    "lng": -86.43193,
    "state_id": "AL"
  },
  {
    "zip": 35147,
    "lat": 33.41511,
    "lng": -86.52927,
    "state_id": "AL"
  },
  {
    "zip": 35148,
    "lat": 33.75635,
    "lng": -87.04551,
    "state_id": "AL"
  },
  {
    "zip": 35149,
    "lat": 33.24802,
    "lng": -86.1969,
    "state_id": "AL"
  },
  {
    "zip": 35150,
    "lat": 33.18528,
    "lng": -86.24169,
    "state_id": "AL"
  },
  {
    "zip": 35151,
    "lat": 33.09299,
    "lng": -86.37206,
    "state_id": "AL"
  },
  {
    "zip": 35160,
    "lat": 33.37483,
    "lng": -86.08659,
    "state_id": "AL"
  },
  {
    "zip": 35171,
    "lat": 32.88214,
    "lng": -86.73965,
    "state_id": "AL"
  },
  {
    "zip": 35172,
    "lat": 33.84469,
    "lng": -86.6974,
    "state_id": "AL"
  },
  {
    "zip": 35173,
    "lat": 33.65043,
    "lng": -86.56424,
    "state_id": "AL"
  },
  {
    "zip": 35175,
    "lat": 34.43724,
    "lng": -86.51699,
    "state_id": "AL"
  },
  {
    "zip": 35176,
    "lat": 33.48534,
    "lng": -86.49823,
    "state_id": "AL"
  },
  {
    "zip": 35178,
    "lat": 33.41984,
    "lng": -86.39876,
    "state_id": "AL"
  },
  {
    "zip": 35179,
    "lat": 34.26849,
    "lng": -86.96694,
    "state_id": "AL"
  },
  {
    "zip": 35180,
    "lat": 33.82638,
    "lng": -86.83547,
    "state_id": "AL"
  },
  {
    "zip": 35183,
    "lat": 33.01591,
    "lng": -86.35076,
    "state_id": "AL"
  },
  {
    "zip": 35184,
    "lat": 33.11479,
    "lng": -87.13924,
    "state_id": "AL"
  },
  {
    "zip": 35186,
    "lat": 33.25698,
    "lng": -86.51427,
    "state_id": "AL"
  },
  {
    "zip": 35187,
    "lat": 33.08053,
    "lng": -86.88581,
    "state_id": "AL"
  },
  {
    "zip": 35188,
    "lat": 33.1821,
    "lng": -87.17022,
    "state_id": "AL"
  },
  {
    "zip": 35203,
    "lat": 33.51867,
    "lng": -86.80971,
    "state_id": "AL"
  },
  {
    "zip": 35204,
    "lat": 33.52206,
    "lng": -86.83985,
    "state_id": "AL"
  },
  {
    "zip": 35205,
    "lat": 33.49497,
    "lng": -86.80818,
    "state_id": "AL"
  },
  {
    "zip": 35206,
    "lat": 33.56925,
    "lng": -86.7134,
    "state_id": "AL"
  },
  {
    "zip": 35207,
    "lat": 33.56613,
    "lng": -86.82296,
    "state_id": "AL"
  },
  {
    "zip": 35208,
    "lat": 33.49688,
    "lng": -86.87931,
    "state_id": "AL"
  },
  {
    "zip": 35209,
    "lat": 33.46529,
    "lng": -86.81167,
    "state_id": "AL"
  },
  {
    "zip": 35210,
    "lat": 33.54516,
    "lng": -86.66552,
    "state_id": "AL"
  },
  {
    "zip": 35211,
    "lat": 33.4501,
    "lng": -86.87148,
    "state_id": "AL"
  },
  {
    "zip": 35212,
    "lat": 33.54744,
    "lng": -86.75302,
    "state_id": "AL"
  },
  {
    "zip": 35213,
    "lat": 33.50663,
    "lng": -86.74284,
    "state_id": "AL"
  },
  {
    "zip": 35214,
    "lat": 33.57688,
    "lng": -86.89186,
    "state_id": "AL"
  },
  {
    "zip": 35215,
    "lat": 33.6492,
    "lng": -86.70586,
    "state_id": "AL"
  },
  {
    "zip": 35216,
    "lat": 33.41874,
    "lng": -86.78666,
    "state_id": "AL"
  },
  {
    "zip": 35217,
    "lat": 33.60814,
    "lng": -86.76034,
    "state_id": "AL"
  },
  {
    "zip": 35218,
    "lat": 33.50777,
    "lng": -86.89384,
    "state_id": "AL"
  },
  {
    "zip": 35221,
    "lat": 33.44954,
    "lng": -86.89903,
    "state_id": "AL"
  },
  {
    "zip": 35222,
    "lat": 33.52411,
    "lng": -86.77022,
    "state_id": "AL"
  },
  {
    "zip": 35223,
    "lat": 33.4869,
    "lng": -86.7375,
    "state_id": "AL"
  },
  {
    "zip": 35224,
    "lat": 33.51937,
    "lng": -86.94447,
    "state_id": "AL"
  },
  {
    "zip": 35226,
    "lat": 33.39851,
    "lng": -86.84435,
    "state_id": "AL"
  },
  {
    "zip": 35228,
    "lat": 33.45621,
    "lng": -86.92103,
    "state_id": "AL"
  },
  {
    "zip": 35229,
    "lat": 33.46561,
    "lng": -86.78901,
    "state_id": "AL"
  },
  {
    "zip": 35233,
    "lat": 33.50879,
    "lng": -86.8019,
    "state_id": "AL"
  },
  {
    "zip": 35234,
    "lat": 33.5408,
    "lng": -86.80287,
    "state_id": "AL"
  },
  {
    "zip": 35235,
    "lat": 33.62408,
    "lng": -86.64837,
    "state_id": "AL"
  },
  {
    "zip": 35242,
    "lat": 33.42521,
    "lng": -86.67144,
    "state_id": "AL"
  },
  {
    "zip": 35243,
    "lat": 33.44278,
    "lng": -86.73994,
    "state_id": "AL"
  },
  {
    "zip": 35244,
    "lat": 33.35372,
    "lng": -86.82548,
    "state_id": "AL"
  },
  {
    "zip": 35254,
    "lat": 33.51616,
    "lng": -86.85744,
    "state_id": "AL"
  },
  {
    "zip": 35401,
    "lat": 33.16878,
    "lng": -87.61357,
    "state_id": "AL"
  },
  {
    "zip": 35404,
    "lat": 33.21668,
    "lng": -87.48608,
    "state_id": "AL"
  },
  {
    "zip": 35405,
    "lat": 33.1158,
    "lng": -87.54288,
    "state_id": "AL"
  },
  {
    "zip": 35406,
    "lat": 33.33412,
    "lng": -87.47043,
    "state_id": "AL"
  },
  {
    "zip": 35441,
    "lat": 32.85373,
    "lng": -87.73859,
    "state_id": "AL"
  },
  {
    "zip": 35442,
    "lat": 33.0737,
    "lng": -88.19358,
    "state_id": "AL"
  },
  {
    "zip": 35443,
    "lat": 32.75293,
    "lng": -88.01124,
    "state_id": "AL"
  },
  {
    "zip": 35444,
    "lat": 33.33696,
    "lng": -87.30323,
    "state_id": "AL"
  },
  {
    "zip": 35446,
    "lat": 33.21617,
    "lng": -87.74715,
    "state_id": "AL"
  },
  {
    "zip": 35447,
    "lat": 33.25667,
    "lng": -88.15451,
    "state_id": "AL"
  },
  {
    "zip": 35452,
    "lat": 33.28174,
    "lng": -87.68367,
    "state_id": "AL"
  },
  {
    "zip": 35453,
    "lat": 33.1642,
    "lng": -87.38809,
    "state_id": "AL"
  },
  {
    "zip": 35456,
    "lat": 33.05553,
    "lng": -87.42714,
    "state_id": "AL"
  },
  {
    "zip": 35457,
    "lat": 33.29618,
    "lng": -87.7737,
    "state_id": "AL"
  },
  {
    "zip": 35458,
    "lat": 33.36696,
    "lng": -87.79135,
    "state_id": "AL"
  },
  {
    "zip": 35459,
    "lat": 32.79859,
    "lng": -88.30287,
    "state_id": "AL"
  },
  {
    "zip": 35460,
    "lat": 32.70902,
    "lng": -88.15033,
    "state_id": "AL"
  },
  {
    "zip": 35461,
    "lat": 33.41546,
    "lng": -88.2142,
    "state_id": "AL"
  },
  {
    "zip": 35462,
    "lat": 32.93442,
    "lng": -87.95285,
    "state_id": "AL"
  },
  {
    "zip": 35463,
    "lat": 33.06901,
    "lng": -87.68755,
    "state_id": "AL"
  },
  {
    "zip": 35464,
    "lat": 32.80329,
    "lng": -88.15355,
    "state_id": "AL"
  },
  {
    "zip": 35466,
    "lat": 33.25825,
    "lng": -87.91413,
    "state_id": "AL"
  },
  {
    "zip": 35469,
    "lat": 32.96993,
    "lng": -87.77204,
    "state_id": "AL"
  },
  {
    "zip": 35470,
    "lat": 32.55682,
    "lng": -88.10365,
    "state_id": "AL"
  },
  {
    "zip": 35473,
    "lat": 33.2724,
    "lng": -87.58248,
    "state_id": "AL"
  },
  {
    "zip": 35474,
    "lat": 32.93785,
    "lng": -87.57215,
    "state_id": "AL"
  },
  {
    "zip": 35475,
    "lat": 33.43293,
    "lng": -87.58694,
    "state_id": "AL"
  },
  {
    "zip": 35476,
    "lat": 33.22739,
    "lng": -87.59433,
    "state_id": "AL"
  },
  {
    "zip": 35477,
    "lat": 32.95133,
    "lng": -88.24097,
    "state_id": "AL"
  },
  {
    "zip": 35480,
    "lat": 33.08965,
    "lng": -87.82006,
    "state_id": "AL"
  },
  {
    "zip": 35481,
    "lat": 33.39398,
    "lng": -88.03736,
    "state_id": "AL"
  },
  {
    "zip": 35490,
    "lat": 33.21341,
    "lng": -87.24587,
    "state_id": "AL"
  },
  {
    "zip": 35501,
    "lat": 33.81907,
    "lng": -87.29272,
    "state_id": "AL"
  },
  {
    "zip": 35503,
    "lat": 33.93756,
    "lng": -87.30035,
    "state_id": "AL"
  },
  {
    "zip": 35504,
    "lat": 33.89267,
    "lng": -87.1667,
    "state_id": "AL"
  },
  {
    "zip": 35540,
    "lat": 34.23388,
    "lng": -87.18817,
    "state_id": "AL"
  },
  {
    "zip": 35541,
    "lat": 34.08013,
    "lng": -87.18065,
    "state_id": "AL"
  },
  {
    "zip": 35542,
    "lat": 33.71589,
    "lng": -87.6789,
    "state_id": "AL"
  },
  {
    "zip": 35543,
    "lat": 34.2242,
    "lng": -87.75005,
    "state_id": "AL"
  },
  {
    "zip": 35544,
    "lat": 33.95519,
    "lng": -88.01906,
    "state_id": "AL"
  },
  {
    "zip": 35545,
    "lat": 33.65152,
    "lng": -87.93483,
    "state_id": "AL"
  },
  {
    "zip": 35546,
    "lat": 33.60722,
    "lng": -87.54901,
    "state_id": "AL"
  },
  {
    "zip": 35548,
    "lat": 34.06756,
    "lng": -87.75117,
    "state_id": "AL"
  },
  {
    "zip": 35549,
    "lat": 33.83641,
    "lng": -87.54728,
    "state_id": "AL"
  },
  {
    "zip": 35550,
    "lat": 33.74175,
    "lng": -87.16599,
    "state_id": "AL"
  },
  {
    "zip": 35552,
    "lat": 34.07228,
    "lng": -88.14216,
    "state_id": "AL"
  },
  {
    "zip": 35553,
    "lat": 34.12065,
    "lng": -87.38569,
    "state_id": "AL"
  },
  {
    "zip": 35554,
    "lat": 33.8961,
    "lng": -87.65716,
    "state_id": "AL"
  },
  {
    "zip": 35555,
    "lat": 33.66631,
    "lng": -87.81846,
    "state_id": "AL"
  },
  {
    "zip": 35559,
    "lat": 33.90556,
    "lng": -87.72817,
    "state_id": "AL"
  },
  {
    "zip": 35563,
    "lat": 33.98374,
    "lng": -87.8977,
    "state_id": "AL"
  },
  {
    "zip": 35564,
    "lat": 34.25604,
    "lng": -87.84794,
    "state_id": "AL"
  },
  {
    "zip": 35565,
    "lat": 34.23414,
    "lng": -87.55886,
    "state_id": "AL"
  },
  {
    "zip": 35570,
    "lat": 34.1646,
    "lng": -87.99501,
    "state_id": "AL"
  },
  {
    "zip": 35571,
    "lat": 34.35604,
    "lng": -87.95001,
    "state_id": "AL"
  },
  {
    "zip": 35572,
    "lat": 34.20366,
    "lng": -87.29647,
    "state_id": "AL"
  },
  {
    "zip": 35574,
    "lat": 33.56503,
    "lng": -87.95048,
    "state_id": "AL"
  },
  {
    "zip": 35575,
    "lat": 34.04293,
    "lng": -87.57845,
    "state_id": "AL"
  },
  {
    "zip": 35576,
    "lat": 33.57923,
    "lng": -88.13129,
    "state_id": "AL"
  },
  {
    "zip": 35577,
    "lat": 34.10237,
    "lng": -87.62001,
    "state_id": "AL"
  },
  {
    "zip": 35578,
    "lat": 33.98435,
    "lng": -87.46613,
    "state_id": "AL"
  },
  {
    "zip": 35579,
    "lat": 33.63661,
    "lng": -87.35619,
    "state_id": "AL"
  },
  {
    "zip": 35580,
    "lat": 33.67377,
    "lng": -87.26449,
    "state_id": "AL"
  },
  {
    "zip": 35581,
    "lat": 34.35853,
    "lng": -87.71318,
    "state_id": "AL"
  },
  {
    "zip": 35582,
    "lat": 34.48839,
    "lng": -88.09439,
    "state_id": "AL"
  },
  {
    "zip": 35584,
    "lat": 33.82199,
    "lng": -87.08699,
    "state_id": "AL"
  },
  {
    "zip": 35585,
    "lat": 34.41687,
    "lng": -87.80986,
    "state_id": "AL"
  },
  {
    "zip": 35586,
    "lat": 33.87665,
    "lng": -88.13869,
    "state_id": "AL"
  },
  {
    "zip": 35587,
    "lat": 33.80054,
    "lng": -87.45393,
    "state_id": "AL"
  },
  {
    "zip": 35592,
    "lat": 33.74629,
    "lng": -88.0844,
    "state_id": "AL"
  },
  {
    "zip": 35593,
    "lat": 34.34977,
    "lng": -88.08015,
    "state_id": "AL"
  },
  {
    "zip": 35594,
    "lat": 33.92969,
    "lng": -87.77212,
    "state_id": "AL"
  },
  {
    "zip": 35601,
    "lat": 34.60907,
    "lng": -87.01333,
    "state_id": "AL"
  },
  {
    "zip": 35603,
    "lat": 34.53751,
    "lng": -86.96674,
    "state_id": "AL"
  },
  {
    "zip": 35610,
    "lat": 34.95098,
    "lng": -87.24209,
    "state_id": "AL"
  },
  {
    "zip": 35611,
    "lat": 34.7671,
    "lng": -87.08566,
    "state_id": "AL"
  },
  {
    "zip": 35613,
    "lat": 34.82405,
    "lng": -86.87736,
    "state_id": "AL"
  },
  {
    "zip": 35614,
    "lat": 34.86542,
    "lng": -87.08385,
    "state_id": "AL"
  },
  {
    "zip": 35615,
    "lat": 34.66399,
    "lng": -86.87415,
    "state_id": "AL"
  },
  {
    "zip": 35616,
    "lat": 34.73885,
    "lng": -88.01897,
    "state_id": "AL"
  },
  {
    "zip": 35618,
    "lat": 34.64788,
    "lng": -87.30612,
    "state_id": "AL"
  },
  {
    "zip": 35619,
    "lat": 34.38732,
    "lng": -87.14546,
    "state_id": "AL"
  },
  {
    "zip": 35620,
    "lat": 34.93151,
    "lng": -86.99131,
    "state_id": "AL"
  },
  {
    "zip": 35621,
    "lat": 34.34961,
    "lng": -86.72508,
    "state_id": "AL"
  },
  {
    "zip": 35622,
    "lat": 34.34341,
    "lng": -86.89783,
    "state_id": "AL"
  },
  {
    "zip": 35630,
    "lat": 34.82364,
    "lng": -87.66058,
    "state_id": "AL"
  },
  {
    "zip": 35633,
    "lat": 34.87641,
    "lng": -87.79858,
    "state_id": "AL"
  },
  {
    "zip": 35634,
    "lat": 34.9186,
    "lng": -87.61358,
    "state_id": "AL"
  },
  {
    "zip": 35640,
    "lat": 34.44125,
    "lng": -86.94406,
    "state_id": "AL"
  },
  {
    "zip": 35643,
    "lat": 34.67015,
    "lng": -87.20546,
    "state_id": "AL"
  },
  {
    "zip": 35645,
    "lat": 34.90185,
    "lng": -87.50088,
    "state_id": "AL"
  },
  {
    "zip": 35646,
    "lat": 34.67171,
    "lng": -87.5283,
    "state_id": "AL"
  },
  {
    "zip": 35647,
    "lat": 34.96594,
    "lng": -87.12179,
    "state_id": "AL"
  },
  {
    "zip": 35648,
    "lat": 34.95875,
    "lng": -87.38491,
    "state_id": "AL"
  },
  {
    "zip": 35649,
    "lat": 34.61705,
    "lng": -86.87099,
    "state_id": "AL"
  },
  {
    "zip": 35650,
    "lat": 34.45074,
    "lng": -87.3024,
    "state_id": "AL"
  },
  {
    "zip": 35651,
    "lat": 34.46688,
    "lng": -87.48741,
    "state_id": "AL"
  },
  {
    "zip": 35652,
    "lat": 34.84536,
    "lng": -87.31128,
    "state_id": "AL"
  },
  {
    "zip": 35653,
    "lat": 34.51688,
    "lng": -87.87133,
    "state_id": "AL"
  },
  {
    "zip": 35654,
    "lat": 34.49806,
    "lng": -87.62778,
    "state_id": "AL"
  },
  {
    "zip": 35660,
    "lat": 34.75596,
    "lng": -87.70089,
    "state_id": "AL"
  },
  {
    "zip": 35661,
    "lat": 34.77345,
    "lng": -87.56505,
    "state_id": "AL"
  },
  {
    "zip": 35670,
    "lat": 34.46093,
    "lng": -86.71905,
    "state_id": "AL"
  },
  {
    "zip": 35671,
    "lat": 34.66633,
    "lng": -86.94946,
    "state_id": "AL"
  },
  {
    "zip": 35672,
    "lat": 34.64716,
    "lng": -87.40674,
    "state_id": "AL"
  },
  {
    "zip": 35673,
    "lat": 34.58094,
    "lng": -87.13628,
    "state_id": "AL"
  },
  {
    "zip": 35674,
    "lat": 34.65761,
    "lng": -87.78281,
    "state_id": "AL"
  },
  {
    "zip": 35677,
    "lat": 34.95503,
    "lng": -88.02709,
    "state_id": "AL"
  },
  {
    "zip": 35739,
    "lat": 34.969,
    "lng": -86.79579,
    "state_id": "AL"
  },
  {
    "zip": 35740,
    "lat": 34.9501,
    "lng": -85.75855,
    "state_id": "AL"
  },
  {
    "zip": 35741,
    "lat": 34.72145,
    "lng": -86.48337,
    "state_id": "AL"
  },
  {
    "zip": 35744,
    "lat": 34.61216,
    "lng": -85.90673,
    "state_id": "AL"
  },
  {
    "zip": 35745,
    "lat": 34.94093,
    "lng": -86.2001,
    "state_id": "AL"
  },
  {
    "zip": 35746,
    "lat": 34.82902,
    "lng": -85.99594,
    "state_id": "AL"
  },
  {
    "zip": 35747,
    "lat": 34.49444,
    "lng": -86.30747,
    "state_id": "AL"
  },
  {
    "zip": 35748,
    "lat": 34.72193,
    "lng": -86.38702,
    "state_id": "AL"
  },
  {
    "zip": 35749,
    "lat": 34.82433,
    "lng": -86.75334,
    "state_id": "AL"
  },
  {
    "zip": 35750,
    "lat": 34.95094,
    "lng": -86.5951,
    "state_id": "AL"
  },
  {
    "zip": 35751,
    "lat": 34.81058,
    "lng": -86.27961,
    "state_id": "AL"
  },
  {
    "zip": 35752,
    "lat": 34.74811,
    "lng": -85.94585,
    "state_id": "AL"
  },
  {
    "zip": 35754,
    "lat": 34.51953,
    "lng": -86.61026,
    "state_id": "AL"
  },
  {
    "zip": 35755,
    "lat": 34.50011,
    "lng": -86.1353,
    "state_id": "AL"
  },
  {
    "zip": 35756,
    "lat": 34.64299,
    "lng": -86.81678,
    "state_id": "AL"
  },
  {
    "zip": 35757,
    "lat": 34.7831,
    "lng": -86.74489,
    "state_id": "AL"
  },
  {
    "zip": 35758,
    "lat": 34.71349,
    "lng": -86.74668,
    "state_id": "AL"
  },
  {
    "zip": 35759,
    "lat": 34.86439,
    "lng": -86.5504,
    "state_id": "AL"
  },
  {
    "zip": 35760,
    "lat": 34.54918,
    "lng": -86.38259,
    "state_id": "AL"
  },
  {
    "zip": 35761,
    "lat": 34.91474,
    "lng": -86.41162,
    "state_id": "AL"
  },
  {
    "zip": 35763,
    "lat": 34.6215,
    "lng": -86.46442,
    "state_id": "AL"
  },
  {
    "zip": 35764,
    "lat": 34.72329,
    "lng": -86.31044,
    "state_id": "AL"
  },
  {
    "zip": 35765,
    "lat": 34.69067,
    "lng": -85.81782,
    "state_id": "AL"
  },
  {
    "zip": 35766,
    "lat": 34.87345,
    "lng": -86.27667,
    "state_id": "AL"
  },
  {
    "zip": 35768,
    "lat": 34.7711,
    "lng": -86.10539,
    "state_id": "AL"
  },
  {
    "zip": 35769,
    "lat": 34.58997,
    "lng": -86.098,
    "state_id": "AL"
  },
  {
    "zip": 35771,
    "lat": 34.53625,
    "lng": -86.00239,
    "state_id": "AL"
  },
  {
    "zip": 35772,
    "lat": 34.89076,
    "lng": -85.88756,
    "state_id": "AL"
  },
  {
    "zip": 35773,
    "lat": 34.89328,
    "lng": -86.71252,
    "state_id": "AL"
  },
  {
    "zip": 35774,
    "lat": 34.76323,
    "lng": -86.22948,
    "state_id": "AL"
  },
  {
    "zip": 35775,
    "lat": 34.54807,
    "lng": -86.69778,
    "state_id": "AL"
  },
  {
    "zip": 35776,
    "lat": 34.66144,
    "lng": -86.2383,
    "state_id": "AL"
  },
  {
    "zip": 35801,
    "lat": 34.72466,
    "lng": -86.56227,
    "state_id": "AL"
  },
  {
    "zip": 35802,
    "lat": 34.6697,
    "lng": -86.55886,
    "state_id": "AL"
  },
  {
    "zip": 35803,
    "lat": 34.56469,
    "lng": -86.52141,
    "state_id": "AL"
  },
  {
    "zip": 35805,
    "lat": 34.70858,
    "lng": -86.62126,
    "state_id": "AL"
  },
  {
    "zip": 35806,
    "lat": 34.76307,
    "lng": -86.68509,
    "state_id": "AL"
  },
  {
    "zip": 35808,
    "lat": 34.63186,
    "lng": -86.65738,
    "state_id": "AL"
  },
  {
    "zip": 35810,
    "lat": 34.801,
    "lng": -86.60761,
    "state_id": "AL"
  },
  {
    "zip": 35811,
    "lat": 34.79694,
    "lng": -86.50602,
    "state_id": "AL"
  },
  {
    "zip": 35816,
    "lat": 34.74018,
    "lng": -86.63031,
    "state_id": "AL"
  },
  {
    "zip": 35824,
    "lat": 34.64441,
    "lng": -86.75322,
    "state_id": "AL"
  },
  {
    "zip": 35896,
    "lat": 34.7543,
    "lng": -86.65457,
    "state_id": "AL"
  },
  {
    "zip": 35901,
    "lat": 34.04799,
    "lng": -85.9246,
    "state_id": "AL"
  },
  {
    "zip": 35903,
    "lat": 34.02709,
    "lng": -85.86094,
    "state_id": "AL"
  },
  {
    "zip": 35904,
    "lat": 34.0702,
    "lng": -85.99309,
    "state_id": "AL"
  },
  {
    "zip": 35905,
    "lat": 33.93405,
    "lng": -85.89942,
    "state_id": "AL"
  },
  {
    "zip": 35906,
    "lat": 33.93181,
    "lng": -86.08975,
    "state_id": "AL"
  },
  {
    "zip": 35907,
    "lat": 33.8979,
    "lng": -86.02444,
    "state_id": "AL"
  },
  {
    "zip": 35950,
    "lat": 34.25261,
    "lng": -86.262,
    "state_id": "AL"
  },
  {
    "zip": 35951,
    "lat": 34.34123,
    "lng": -86.16168,
    "state_id": "AL"
  },
  {
    "zip": 35952,
    "lat": 34.05283,
    "lng": -86.3082,
    "state_id": "AL"
  },
  {
    "zip": 35953,
    "lat": 33.81243,
    "lng": -86.22613,
    "state_id": "AL"
  },
  {
    "zip": 35954,
    "lat": 34.08839,
    "lng": -86.06009,
    "state_id": "AL"
  },
  {
    "zip": 35956,
    "lat": 34.14373,
    "lng": -86.14794,
    "state_id": "AL"
  },
  {
    "zip": 35957,
    "lat": 34.19207,
    "lng": -86.19421,
    "state_id": "AL"
  },
  {
    "zip": 35958,
    "lat": 34.91808,
    "lng": -85.64682,
    "state_id": "AL"
  },
  {
    "zip": 35959,
    "lat": 34.24059,
    "lng": -85.60697,
    "state_id": "AL"
  },
  {
    "zip": 35960,
    "lat": 34.12779,
    "lng": -85.57398,
    "state_id": "AL"
  },
  {
    "zip": 35961,
    "lat": 34.27943,
    "lng": -85.86025,
    "state_id": "AL"
  },
  {
    "zip": 35962,
    "lat": 34.30188,
    "lng": -86.03997,
    "state_id": "AL"
  },
  {
    "zip": 35963,
    "lat": 34.36165,
    "lng": -85.93023,
    "state_id": "AL"
  },
  {
    "zip": 35966,
    "lat": 34.79657,
    "lng": -85.67881,
    "state_id": "AL"
  },
  {
    "zip": 35967,
    "lat": 34.40139,
    "lng": -85.69628,
    "state_id": "AL"
  },
  {
    "zip": 35968,
    "lat": 34.48972,
    "lng": -85.76571,
    "state_id": "AL"
  },
  {
    "zip": 35971,
    "lat": 34.45552,
    "lng": -85.94512,
    "state_id": "AL"
  },
  {
    "zip": 35972,
    "lat": 33.99142,
    "lng": -86.24522,
    "state_id": "AL"
  },
  {
    "zip": 35973,
    "lat": 34.34643,
    "lng": -85.55436,
    "state_id": "AL"
  },
  {
    "zip": 35974,
    "lat": 34.36351,
    "lng": -86.01169,
    "state_id": "AL"
  },
  {
    "zip": 35975,
    "lat": 34.44014,
    "lng": -86.0663,
    "state_id": "AL"
  },
  {
    "zip": 35976,
    "lat": 34.34747,
    "lng": -86.3335,
    "state_id": "AL"
  },
  {
    "zip": 35978,
    "lat": 34.64379,
    "lng": -85.72787,
    "state_id": "AL"
  },
  {
    "zip": 35979,
    "lat": 34.83232,
    "lng": -85.60958,
    "state_id": "AL"
  },
  {
    "zip": 35980,
    "lat": 34.17624,
    "lng": -86.38525,
    "state_id": "AL"
  },
  {
    "zip": 35981,
    "lat": 34.72235,
    "lng": -85.64877,
    "state_id": "AL"
  },
  {
    "zip": 35983,
    "lat": 34.1723,
    "lng": -85.76882,
    "state_id": "AL"
  },
  {
    "zip": 35984,
    "lat": 34.52955,
    "lng": -85.56686,
    "state_id": "AL"
  },
  {
    "zip": 35986,
    "lat": 34.50328,
    "lng": -85.84036,
    "state_id": "AL"
  },
  {
    "zip": 35987,
    "lat": 33.91707,
    "lng": -86.23811,
    "state_id": "AL"
  },
  {
    "zip": 35988,
    "lat": 34.56012,
    "lng": -85.7998,
    "state_id": "AL"
  },
  {
    "zip": 35989,
    "lat": 34.60743,
    "lng": -85.63124,
    "state_id": "AL"
  },
  {
    "zip": 35990,
    "lat": 34.06773,
    "lng": -86.30454,
    "state_id": "AL"
  },
  {
    "zip": 36003,
    "lat": 32.45323,
    "lng": -86.72387,
    "state_id": "AL"
  },
  {
    "zip": 36005,
    "lat": 31.88607,
    "lng": -85.72077,
    "state_id": "AL"
  },
  {
    "zip": 36006,
    "lat": 32.64152,
    "lng": -86.72346,
    "state_id": "AL"
  },
  {
    "zip": 36009,
    "lat": 31.58356,
    "lng": -86.29694,
    "state_id": "AL"
  },
  {
    "zip": 36010,
    "lat": 31.66892,
    "lng": -85.81156,
    "state_id": "AL"
  },
  {
    "zip": 36013,
    "lat": 32.29373,
    "lng": -85.98264,
    "state_id": "AL"
  },
  {
    "zip": 36016,
    "lat": 31.85208,
    "lng": -85.42936,
    "state_id": "AL"
  },
  {
    "zip": 36017,
    "lat": 31.68174,
    "lng": -85.55506,
    "state_id": "AL"
  },
  {
    "zip": 36020,
    "lat": 32.48818,
    "lng": -86.3193,
    "state_id": "AL"
  },
  {
    "zip": 36022,
    "lat": 32.61945,
    "lng": -86.40471,
    "state_id": "AL"
  },
  {
    "zip": 36024,
    "lat": 32.66674,
    "lng": -86.03199,
    "state_id": "AL"
  },
  {
    "zip": 36025,
    "lat": 32.54153,
    "lng": -86.32561,
    "state_id": "AL"
  },
  {
    "zip": 36026,
    "lat": 32.77815,
    "lng": -86.12752,
    "state_id": "AL"
  },
  {
    "zip": 36027,
    "lat": 31.93341,
    "lng": -85.20924,
    "state_id": "AL"
  },
  {
    "zip": 36028,
    "lat": 31.49916,
    "lng": -86.38884,
    "state_id": "AL"
  },
  {
    "zip": 36029,
    "lat": 32.1779,
    "lng": -85.93363,
    "state_id": "AL"
  },
  {
    "zip": 36030,
    "lat": 31.86642,
    "lng": -86.85983,
    "state_id": "AL"
  },
  {
    "zip": 36031,
    "lat": 32.24878,
    "lng": -85.74358,
    "state_id": "AL"
  },
  {
    "zip": 36032,
    "lat": 31.9943,
    "lng": -86.56933,
    "state_id": "AL"
  },
  {
    "zip": 36033,
    "lat": 31.67124,
    "lng": -86.76784,
    "state_id": "AL"
  },
  {
    "zip": 36034,
    "lat": 31.62105,
    "lng": -86.11065,
    "state_id": "AL"
  },
  {
    "zip": 36035,
    "lat": 31.79458,
    "lng": -86.12767,
    "state_id": "AL"
  },
  {
    "zip": 36036,
    "lat": 31.9776,
    "lng": -86.15576,
    "state_id": "AL"
  },
  {
    "zip": 36037,
    "lat": 31.79895,
    "lng": -86.62528,
    "state_id": "AL"
  },
  {
    "zip": 36038,
    "lat": 31.43618,
    "lng": -86.48945,
    "state_id": "AL"
  },
  {
    "zip": 36039,
    "lat": 32.28151,
    "lng": -85.83493,
    "state_id": "AL"
  },
  {
    "zip": 36040,
    "lat": 32.16518,
    "lng": -86.68283,
    "state_id": "AL"
  },
  {
    "zip": 36041,
    "lat": 31.91462,
    "lng": -86.31825,
    "state_id": "AL"
  },
  {
    "zip": 36042,
    "lat": 31.86659,
    "lng": -86.44932,
    "state_id": "AL"
  },
  {
    "zip": 36043,
    "lat": 32.19945,
    "lng": -86.41766,
    "state_id": "AL"
  },
  {
    "zip": 36046,
    "lat": 32.00539,
    "lng": -86.33779,
    "state_id": "AL"
  },
  {
    "zip": 36047,
    "lat": 32.08133,
    "lng": -86.51661,
    "state_id": "AL"
  },
  {
    "zip": 36048,
    "lat": 31.79021,
    "lng": -85.59038,
    "state_id": "AL"
  },
  {
    "zip": 36049,
    "lat": 31.76331,
    "lng": -86.28198,
    "state_id": "AL"
  },
  {
    "zip": 36051,
    "lat": 32.68407,
    "lng": -86.49468,
    "state_id": "AL"
  },
  {
    "zip": 36052,
    "lat": 32.18685,
    "lng": -86.04647,
    "state_id": "AL"
  },
  {
    "zip": 36053,
    "lat": 32.03353,
    "lng": -85.43923,
    "state_id": "AL"
  },
  {
    "zip": 36054,
    "lat": 32.48359,
    "lng": -86.36669,
    "state_id": "AL"
  },
  {
    "zip": 36064,
    "lat": 32.30269,
    "lng": -86.07945,
    "state_id": "AL"
  },
  {
    "zip": 36066,
    "lat": 32.47996,
    "lng": -86.42407,
    "state_id": "AL"
  },
  {
    "zip": 36067,
    "lat": 32.51248,
    "lng": -86.56897,
    "state_id": "AL"
  },
  {
    "zip": 36069,
    "lat": 32.09518,
    "lng": -86.15237,
    "state_id": "AL"
  },
  {
    "zip": 36071,
    "lat": 31.70592,
    "lng": -86.39565,
    "state_id": "AL"
  },
  {
    "zip": 36075,
    "lat": 32.38458,
    "lng": -85.91114,
    "state_id": "AL"
  },
  {
    "zip": 36078,
    "lat": 32.54058,
    "lng": -85.9355,
    "state_id": "AL"
  },
  {
    "zip": 36079,
    "lat": 31.75952,
    "lng": -86.01069,
    "state_id": "AL"
  },
  {
    "zip": 36080,
    "lat": 32.70211,
    "lng": -86.28413,
    "state_id": "AL"
  },
  {
    "zip": 36081,
    "lat": 31.90489,
    "lng": -85.89686,
    "state_id": "AL"
  },
  {
    "zip": 36082,
    "lat": 31.80273,
    "lng": -85.95468,
    "state_id": "AL"
  },
  {
    "zip": 36083,
    "lat": 32.38841,
    "lng": -85.68244,
    "state_id": "AL"
  },
  {
    "zip": 36088,
    "lat": 32.41686,
    "lng": -85.71784,
    "state_id": "AL"
  },
  {
    "zip": 36089,
    "lat": 32.15112,
    "lng": -85.70046,
    "state_id": "AL"
  },
  {
    "zip": 36091,
    "lat": 32.75087,
    "lng": -86.51182,
    "state_id": "AL"
  },
  {
    "zip": 36092,
    "lat": 32.618,
    "lng": -86.19336,
    "state_id": "AL"
  },
  {
    "zip": 36093,
    "lat": 32.49754,
    "lng": -86.14038,
    "state_id": "AL"
  },
  {
    "zip": 36104,
    "lat": 32.39967,
    "lng": -86.32494,
    "state_id": "AL"
  },
  {
    "zip": 36105,
    "lat": 32.21337,
    "lng": -86.28959,
    "state_id": "AL"
  },
  {
    "zip": 36106,
    "lat": 32.3525,
    "lng": -86.25758,
    "state_id": "AL"
  },
  {
    "zip": 36107,
    "lat": 32.38378,
    "lng": -86.2793,
    "state_id": "AL"
  },
  {
    "zip": 36108,
    "lat": 32.33961,
    "lng": -86.4063,
    "state_id": "AL"
  },
  {
    "zip": 36109,
    "lat": 32.3871,
    "lng": -86.24137,
    "state_id": "AL"
  },
  {
    "zip": 36110,
    "lat": 32.44856,
    "lng": -86.2567,
    "state_id": "AL"
  },
  {
    "zip": 36111,
    "lat": 32.33758,
    "lng": -86.27162,
    "state_id": "AL"
  },
  {
    "zip": 36112,
    "lat": 32.38074,
    "lng": -86.34915,
    "state_id": "AL"
  },
  {
    "zip": 36113,
    "lat": 32.38277,
    "lng": -86.35806,
    "state_id": "AL"
  },
  {
    "zip": 36115,
    "lat": 32.40672,
    "lng": -86.24672,
    "state_id": "AL"
  },
  {
    "zip": 36116,
    "lat": 32.27263,
    "lng": -86.21539,
    "state_id": "AL"
  },
  {
    "zip": 36117,
    "lat": 32.37856,
    "lng": -86.14566,
    "state_id": "AL"
  },
  {
    "zip": 36201,
    "lat": 33.65147,
    "lng": -85.87878,
    "state_id": "AL"
  },
  {
    "zip": 36203,
    "lat": 33.58156,
    "lng": -85.83295,
    "state_id": "AL"
  },
  {
    "zip": 36205,
    "lat": 33.71723,
    "lng": -85.79495,
    "state_id": "AL"
  },
  {
    "zip": 36206,
    "lat": 33.73409,
    "lng": -85.81427,
    "state_id": "AL"
  },
  {
    "zip": 36207,
    "lat": 33.68026,
    "lng": -85.71375,
    "state_id": "AL"
  },
  {
    "zip": 36250,
    "lat": 33.76777,
    "lng": -85.89684,
    "state_id": "AL"
  },
  {
    "zip": 36251,
    "lat": 33.22982,
    "lng": -85.86642,
    "state_id": "AL"
  },
  {
    "zip": 36255,
    "lat": 33.17466,
    "lng": -85.73837,
    "state_id": "AL"
  },
  {
    "zip": 36256,
    "lat": 33.04209,
    "lng": -85.70281,
    "state_id": "AL"
  },
  {
    "zip": 36258,
    "lat": 33.47583,
    "lng": -85.71479,
    "state_id": "AL"
  },
  {
    "zip": 36260,
    "lat": 33.58363,
    "lng": -86.00159,
    "state_id": "AL"
  },
  {
    "zip": 36262,
    "lat": 33.78757,
    "lng": -85.46555,
    "state_id": "AL"
  },
  {
    "zip": 36263,
    "lat": 33.46623,
    "lng": -85.35928,
    "state_id": "AL"
  },
  {
    "zip": 36264,
    "lat": 33.59501,
    "lng": -85.53865,
    "state_id": "AL"
  },
  {
    "zip": 36265,
    "lat": 33.84779,
    "lng": -85.78524,
    "state_id": "AL"
  },
  {
    "zip": 36266,
    "lat": 33.34875,
    "lng": -85.74061,
    "state_id": "AL"
  },
  {
    "zip": 36267,
    "lat": 33.19729,
    "lng": -85.94818,
    "state_id": "AL"
  },
  {
    "zip": 36268,
    "lat": 33.49715,
    "lng": -85.9413,
    "state_id": "AL"
  },
  {
    "zip": 36269,
    "lat": 33.74339,
    "lng": -85.38289,
    "state_id": "AL"
  },
  {
    "zip": 36271,
    "lat": 33.78149,
    "lng": -86.01612,
    "state_id": "AL"
  },
  {
    "zip": 36272,
    "lat": 33.94169,
    "lng": -85.596,
    "state_id": "AL"
  },
  {
    "zip": 36273,
    "lat": 33.5403,
    "lng": -85.37853,
    "state_id": "AL"
  },
  {
    "zip": 36274,
    "lat": 33.17307,
    "lng": -85.36789,
    "state_id": "AL"
  },
  {
    "zip": 36276,
    "lat": 33.13385,
    "lng": -85.57061,
    "state_id": "AL"
  },
  {
    "zip": 36277,
    "lat": 33.75972,
    "lng": -85.81878,
    "state_id": "AL"
  },
  {
    "zip": 36278,
    "lat": 33.32618,
    "lng": -85.52697,
    "state_id": "AL"
  },
  {
    "zip": 36279,
    "lat": 33.87338,
    "lng": -85.88408,
    "state_id": "AL"
  },
  {
    "zip": 36280,
    "lat": 33.38185,
    "lng": -85.38695,
    "state_id": "AL"
  },
  {
    "zip": 36301,
    "lat": 31.14367,
    "lng": -85.40236,
    "state_id": "AL"
  },
  {
    "zip": 36303,
    "lat": 31.26679,
    "lng": -85.39718,
    "state_id": "AL"
  },
  {
    "zip": 36305,
    "lat": 31.20136,
    "lng": -85.49807,
    "state_id": "AL"
  },
  {
    "zip": 36310,
    "lat": 31.60297,
    "lng": -85.21631,
    "state_id": "AL"
  },
  {
    "zip": 36311,
    "lat": 31.60246,
    "lng": -85.68151,
    "state_id": "AL"
  },
  {
    "zip": 36312,
    "lat": 31.17088,
    "lng": -85.23739,
    "state_id": "AL"
  },
  {
    "zip": 36313,
    "lat": 31.1786,
    "lng": -85.79394,
    "state_id": "AL"
  },
  {
    "zip": 36314,
    "lat": 31.01866,
    "lng": -85.77585,
    "state_id": "AL"
  },
  {
    "zip": 36316,
    "lat": 31.1736,
    "lng": -85.86271,
    "state_id": "AL"
  },
  {
    "zip": 36317,
    "lat": 31.64459,
    "lng": -85.39108,
    "state_id": "AL"
  },
  {
    "zip": 36318,
    "lat": 31.1751,
    "lng": -85.95326,
    "state_id": "AL"
  },
  {
    "zip": 36319,
    "lat": 31.32463,
    "lng": -85.14759,
    "state_id": "AL"
  },
  {
    "zip": 36320,
    "lat": 31.05082,
    "lng": -85.31533,
    "state_id": "AL"
  },
  {
    "zip": 36321,
    "lat": 31.2118,
    "lng": -85.30184,
    "state_id": "AL"
  },
  {
    "zip": 36322,
    "lat": 31.25281,
    "lng": -85.73273,
    "state_id": "AL"
  },
  {
    "zip": 36323,
    "lat": 31.4268,
    "lng": -86.07139,
    "state_id": "AL"
  },
  {
    "zip": 36330,
    "lat": 31.30615,
    "lng": -85.8632,
    "state_id": "AL"
  },
  {
    "zip": 36340,
    "lat": 31.05086,
    "lng": -85.89142,
    "state_id": "AL"
  },
  {
    "zip": 36343,
    "lat": 31.05905,
    "lng": -85.11599,
    "state_id": "AL"
  },
  {
    "zip": 36344,
    "lat": 31.09822,
    "lng": -85.70899,
    "state_id": "AL"
  },
  {
    "zip": 36345,
    "lat": 31.37526,
    "lng": -85.30829,
    "state_id": "AL"
  },
  {
    "zip": 36346,
    "lat": 31.55883,
    "lng": -85.92674,
    "state_id": "AL"
  },
  {
    "zip": 36350,
    "lat": 31.3492,
    "lng": -85.4903,
    "state_id": "AL"
  },
  {
    "zip": 36351,
    "lat": 31.41551,
    "lng": -85.91877,
    "state_id": "AL"
  },
  {
    "zip": 36352,
    "lat": 31.25985,
    "lng": -85.61533,
    "state_id": "AL"
  },
  {
    "zip": 36353,
    "lat": 31.48285,
    "lng": -85.36606,
    "state_id": "AL"
  },
  {
    "zip": 36360,
    "lat": 31.46766,
    "lng": -85.6177,
    "state_id": "AL"
  },
  {
    "zip": 36362,
    "lat": 31.35089,
    "lng": -85.69072,
    "state_id": "AL"
  },
  {
    "zip": 36370,
    "lat": 31.14241,
    "lng": -85.15679,
    "state_id": "AL"
  },
  {
    "zip": 36371,
    "lat": 31.31334,
    "lng": -85.55205,
    "state_id": "AL"
  },
  {
    "zip": 36373,
    "lat": 31.52049,
    "lng": -85.10522,
    "state_id": "AL"
  },
  {
    "zip": 36374,
    "lat": 31.57873,
    "lng": -85.49368,
    "state_id": "AL"
  },
  {
    "zip": 36375,
    "lat": 31.07549,
    "lng": -85.53885,
    "state_id": "AL"
  },
  {
    "zip": 36376,
    "lat": 31.25624,
    "lng": -85.27019,
    "state_id": "AL"
  },
  {
    "zip": 36401,
    "lat": 31.47366,
    "lng": -86.9493,
    "state_id": "AL"
  },
  {
    "zip": 36420,
    "lat": 31.17685,
    "lng": -86.57647,
    "state_id": "AL"
  },
  {
    "zip": 36421,
    "lat": 31.32474,
    "lng": -86.50656,
    "state_id": "AL"
  },
  {
    "zip": 36425,
    "lat": 31.75818,
    "lng": -87.18469,
    "state_id": "AL"
  },
  {
    "zip": 36426,
    "lat": 31.14048,
    "lng": -87.07405,
    "state_id": "AL"
  },
  {
    "zip": 36432,
    "lat": 31.29375,
    "lng": -87.02762,
    "state_id": "AL"
  },
  {
    "zip": 36435,
    "lat": 31.88219,
    "lng": -87.4598,
    "state_id": "AL"
  },
  {
    "zip": 36436,
    "lat": 31.78536,
    "lng": -87.64953,
    "state_id": "AL"
  },
  {
    "zip": 36439,
    "lat": 31.42396,
    "lng": -87.34222,
    "state_id": "AL"
  },
  {
    "zip": 36441,
    "lat": 31.03642,
    "lng": -87.25584,
    "state_id": "AL"
  },
  {
    "zip": 36442,
    "lat": 31.07157,
    "lng": -86.36267,
    "state_id": "AL"
  },
  {
    "zip": 36444,
    "lat": 31.66427,
    "lng": -87.45328,
    "state_id": "AL"
  },
  {
    "zip": 36445,
    "lat": 31.44247,
    "lng": -87.44745,
    "state_id": "AL"
  },
  {
    "zip": 36446,
    "lat": 31.79247,
    "lng": -87.71998,
    "state_id": "AL"
  },
  {
    "zip": 36451,
    "lat": 31.69908,
    "lng": -87.80271,
    "state_id": "AL"
  },
  {
    "zip": 36453,
    "lat": 31.19254,
    "lng": -86.1514,
    "state_id": "AL"
  },
  {
    "zip": 36454,
    "lat": 31.33327,
    "lng": -87.20061,
    "state_id": "AL"
  },
  {
    "zip": 36455,
    "lat": 31.01292,
    "lng": -86.35181,
    "state_id": "AL"
  },
  {
    "zip": 36456,
    "lat": 31.56338,
    "lng": -86.81278,
    "state_id": "AL"
  },
  {
    "zip": 36460,
    "lat": 31.52096,
    "lng": -87.30743,
    "state_id": "AL"
  },
  {
    "zip": 36467,
    "lat": 31.28126,
    "lng": -86.25716,
    "state_id": "AL"
  },
  {
    "zip": 36470,
    "lat": 31.53803,
    "lng": -87.55087,
    "state_id": "AL"
  },
  {
    "zip": 36471,
    "lat": 31.62619,
    "lng": -87.24301,
    "state_id": "AL"
  },
  {
    "zip": 36473,
    "lat": 31.30577,
    "lng": -87.21362,
    "state_id": "AL"
  },
  {
    "zip": 36474,
    "lat": 31.4268,
    "lng": -86.61984,
    "state_id": "AL"
  },
  {
    "zip": 36475,
    "lat": 31.38141,
    "lng": -87.2247,
    "state_id": "AL"
  },
  {
    "zip": 36476,
    "lat": 31.36303,
    "lng": -86.55096,
    "state_id": "AL"
  },
  {
    "zip": 36477,
    "lat": 31.09522,
    "lng": -86.05576,
    "state_id": "AL"
  },
  {
    "zip": 36480,
    "lat": 31.32707,
    "lng": -87.60794,
    "state_id": "AL"
  },
  {
    "zip": 36481,
    "lat": 31.80224,
    "lng": -87.36798,
    "state_id": "AL"
  },
  {
    "zip": 36482,
    "lat": 31.6318,
    "lng": -87.64257,
    "state_id": "AL"
  },
  {
    "zip": 36483,
    "lat": 31.04302,
    "lng": -86.69175,
    "state_id": "AL"
  },
  {
    "zip": 36502,
    "lat": 31.14072,
    "lng": -87.49393,
    "state_id": "AL"
  },
  {
    "zip": 36505,
    "lat": 30.94264,
    "lng": -88.01644,
    "state_id": "AL"
  },
  {
    "zip": 36507,
    "lat": 30.86318,
    "lng": -87.73903,
    "state_id": "AL"
  },
  {
    "zip": 36509,
    "lat": 30.40204,
    "lng": -88.26155,
    "state_id": "AL"
  },
  {
    "zip": 36511,
    "lat": 30.31279,
    "lng": -87.74306,
    "state_id": "AL"
  },
  {
    "zip": 36512,
    "lat": 30.99765,
    "lng": -88.01484,
    "state_id": "AL"
  },
  {
    "zip": 36513,
    "lat": 31.1718,
    "lng": -88.00338,
    "state_id": "AL"
  },
  {
    "zip": 36518,
    "lat": 31.45453,
    "lng": -88.28395,
    "state_id": "AL"
  },
  {
    "zip": 36521,
    "lat": 30.96477,
    "lng": -88.18116,
    "state_id": "AL"
  },
  {
    "zip": 36522,
    "lat": 31.09178,
    "lng": -88.27135,
    "state_id": "AL"
  },
  {
    "zip": 36523,
    "lat": 30.37697,
    "lng": -88.16718,
    "state_id": "AL"
  },
  {
    "zip": 36524,
    "lat": 31.81425,
    "lng": -88.02109,
    "state_id": "AL"
  },
  {
    "zip": 36525,
    "lat": 30.89003,
    "lng": -88.01798,
    "state_id": "AL"
  },
  {
    "zip": 36526,
    "lat": 30.61295,
    "lng": -87.86136,
    "state_id": "AL"
  },
  {
    "zip": 36527,
    "lat": 30.69825,
    "lng": -87.87322,
    "state_id": "AL"
  },
  {
    "zip": 36528,
    "lat": 30.25255,
    "lng": -88.14385,
    "state_id": "AL"
  },
  {
    "zip": 36529,
    "lat": 31.27779,
    "lng": -88.26572,
    "state_id": "AL"
  },
  {
    "zip": 36530,
    "lat": 30.4102,
    "lng": -87.55475,
    "state_id": "AL"
  },
  {
    "zip": 36532,
    "lat": 30.48266,
    "lng": -87.86183,
    "state_id": "AL"
  },
  {
    "zip": 36535,
    "lat": 30.38546,
    "lng": -87.7262,
    "state_id": "AL"
  },
  {
    "zip": 36538,
    "lat": 31.66264,
    "lng": -88.13798,
    "state_id": "AL"
  },
  {
    "zip": 36539,
    "lat": 31.33968,
    "lng": -88.38396,
    "state_id": "AL"
  },
  {
    "zip": 36540,
    "lat": 31.44549,
    "lng": -87.66859,
    "state_id": "AL"
  },
  {
    "zip": 36541,
    "lat": 30.49335,
    "lng": -88.34053,
    "state_id": "AL"
  },
  {
    "zip": 36542,
    "lat": 30.27726,
    "lng": -87.73713,
    "state_id": "AL"
  },
  {
    "zip": 36543,
    "lat": 31.22073,
    "lng": -87.46323,
    "state_id": "AL"
  },
  {
    "zip": 36544,
    "lat": 30.47443,
    "lng": -88.23288,
    "state_id": "AL"
  },
  {
    "zip": 36545,
    "lat": 31.4894,
    "lng": -87.87434,
    "state_id": "AL"
  },
  {
    "zip": 36548,
    "lat": 31.48753,
    "lng": -87.97159,
    "state_id": "AL"
  },
  {
    "zip": 36549,
    "lat": 30.41373,
    "lng": -87.44852,
    "state_id": "AL"
  },
  {
    "zip": 36550,
    "lat": 31.23746,
    "lng": -87.72114,
    "state_id": "AL"
  },
  {
    "zip": 36551,
    "lat": 30.63762,
    "lng": -87.75286,
    "state_id": "AL"
  },
  {
    "zip": 36553,
    "lat": 31.24101,
    "lng": -88.06928,
    "state_id": "AL"
  },
  {
    "zip": 36555,
    "lat": 30.39985,
    "lng": -87.77264,
    "state_id": "AL"
  },
  {
    "zip": 36556,
    "lat": 31.20649,
    "lng": -87.97182,
    "state_id": "AL"
  },
  {
    "zip": 36558,
    "lat": 31.60774,
    "lng": -88.31852,
    "state_id": "AL"
  },
  {
    "zip": 36559,
    "lat": 30.56858,
    "lng": -87.904,
    "state_id": "AL"
  },
  {
    "zip": 36560,
    "lat": 31.09638,
    "lng": -88.04001,
    "state_id": "AL"
  },
  {
    "zip": 36561,
    "lat": 30.28692,
    "lng": -87.57033,
    "state_id": "AL"
  },
  {
    "zip": 36562,
    "lat": 31.03438,
    "lng": -87.66144,
    "state_id": "AL"
  },
  {
    "zip": 36564,
    "lat": 30.47681,
    "lng": -87.92522,
    "state_id": "AL"
  },
  {
    "zip": 36567,
    "lat": 30.61475,
    "lng": -87.55984,
    "state_id": "AL"
  },
  {
    "zip": 36568,
    "lat": 30.51279,
    "lng": -88.27257,
    "state_id": "AL"
  },
  {
    "zip": 36569,
    "lat": 31.5425,
    "lng": -88.08932,
    "state_id": "AL"
  },
  {
    "zip": 36571,
    "lat": 30.84281,
    "lng": -88.09551,
    "state_id": "AL"
  },
  {
    "zip": 36572,
    "lat": 30.85842,
    "lng": -88.06046,
    "state_id": "AL"
  },
  {
    "zip": 36574,
    "lat": 30.51519,
    "lng": -87.48076,
    "state_id": "AL"
  },
  {
    "zip": 36575,
    "lat": 30.76897,
    "lng": -88.27272,
    "state_id": "AL"
  },
  {
    "zip": 36576,
    "lat": 30.52641,
    "lng": -87.76646,
    "state_id": "AL"
  },
  {
    "zip": 36578,
    "lat": 30.74774,
    "lng": -87.77878,
    "state_id": "AL"
  },
  {
    "zip": 36579,
    "lat": 31.10661,
    "lng": -87.79842,
    "state_id": "AL"
  },
  {
    "zip": 36580,
    "lat": 30.48177,
    "lng": -87.70046,
    "state_id": "AL"
  },
  {
    "zip": 36581,
    "lat": 31.37321,
    "lng": -87.96134,
    "state_id": "AL"
  },
  {
    "zip": 36582,
    "lat": 30.51044,
    "lng": -88.17336,
    "state_id": "AL"
  },
  {
    "zip": 36583,
    "lat": 31.35528,
    "lng": -88.19769,
    "state_id": "AL"
  },
  {
    "zip": 36584,
    "lat": 31.19821,
    "lng": -88.38063,
    "state_id": "AL"
  },
  {
    "zip": 36585,
    "lat": 31.38848,
    "lng": -88.07549,
    "state_id": "AL"
  },
  {
    "zip": 36587,
    "lat": 30.84259,
    "lng": -88.35491,
    "state_id": "AL"
  },
  {
    "zip": 36590,
    "lat": 30.53181,
    "lng": -88.17441,
    "state_id": "AL"
  },
  {
    "zip": 36602,
    "lat": 30.69355,
    "lng": -88.04555,
    "state_id": "AL"
  },
  {
    "zip": 36603,
    "lat": 30.69055,
    "lng": -88.04357,
    "state_id": "AL"
  },
  {
    "zip": 36604,
    "lat": 30.6822,
    "lng": -88.06816,
    "state_id": "AL"
  },
  {
    "zip": 36605,
    "lat": 30.60791,
    "lng": -88.08977,
    "state_id": "AL"
  },
  {
    "zip": 36606,
    "lat": 30.67079,
    "lng": -88.10628,
    "state_id": "AL"
  },
  {
    "zip": 36607,
    "lat": 30.69859,
    "lng": -88.10575,
    "state_id": "AL"
  },
  {
    "zip": 36608,
    "lat": 30.68171,
    "lng": -88.29444,
    "state_id": "AL"
  },
  {
    "zip": 36609,
    "lat": 30.66091,
    "lng": -88.16318,
    "state_id": "AL"
  },
  {
    "zip": 36610,
    "lat": 30.73631,
    "lng": -88.07289,
    "state_id": "AL"
  },
  {
    "zip": 36611,
    "lat": 30.77045,
    "lng": -88.07808,
    "state_id": "AL"
  },
  {
    "zip": 36612,
    "lat": 30.75377,
    "lng": -88.11246,
    "state_id": "AL"
  },
  {
    "zip": 36613,
    "lat": 30.81178,
    "lng": -88.18098,
    "state_id": "AL"
  },
  {
    "zip": 36615,
    "lat": 30.63316,
    "lng": -88.06831,
    "state_id": "AL"
  },
  {
    "zip": 36616,
    "lat": 30.72454,
    "lng": -88.08152,
    "state_id": "AL"
  },
  {
    "zip": 36617,
    "lat": 30.71643,
    "lng": -88.09599,
    "state_id": "AL"
  },
  {
    "zip": 36618,
    "lat": 30.73594,
    "lng": -88.17345,
    "state_id": "AL"
  },
  {
    "zip": 36619,
    "lat": 30.59647,
    "lng": -88.19792,
    "state_id": "AL"
  },
  {
    "zip": 36688,
    "lat": 30.69623,
    "lng": -88.18239,
    "state_id": "AL"
  },
  {
    "zip": 36693,
    "lat": 30.6266,
    "lng": -88.14987,
    "state_id": "AL"
  },
  {
    "zip": 36695,
    "lat": 30.63011,
    "lng": -88.28067,
    "state_id": "AL"
  },
  {
    "zip": 36701,
    "lat": 32.47679,
    "lng": -87.05709,
    "state_id": "AL"
  },
  {
    "zip": 36703,
    "lat": 32.43261,
    "lng": -86.91074,
    "state_id": "AL"
  },
  {
    "zip": 36720,
    "lat": 32.15848,
    "lng": -87.34674,
    "state_id": "AL"
  },
  {
    "zip": 36722,
    "lat": 32.09152,
    "lng": -87.57346,
    "state_id": "AL"
  },
  {
    "zip": 36723,
    "lat": 32.06777,
    "lng": -87.2851,
    "state_id": "AL"
  },
  {
    "zip": 36726,
    "lat": 31.97324,
    "lng": -87.29099,
    "state_id": "AL"
  },
  {
    "zip": 36727,
    "lat": 31.92124,
    "lng": -88.00801,
    "state_id": "AL"
  },
  {
    "zip": 36728,
    "lat": 32.16788,
    "lng": -87.47938,
    "state_id": "AL"
  },
  {
    "zip": 36732,
    "lat": 32.42337,
    "lng": -87.9152,
    "state_id": "AL"
  },
  {
    "zip": 36736,
    "lat": 32.05953,
    "lng": -87.77719,
    "state_id": "AL"
  },
  {
    "zip": 36738,
    "lat": 32.42661,
    "lng": -87.6193,
    "state_id": "AL"
  },
  {
    "zip": 36740,
    "lat": 32.62732,
    "lng": -87.84017,
    "state_id": "AL"
  },
  {
    "zip": 36742,
    "lat": 32.46429,
    "lng": -87.738,
    "state_id": "AL"
  },
  {
    "zip": 36744,
    "lat": 32.69748,
    "lng": -87.59874,
    "state_id": "AL"
  },
  {
    "zip": 36748,
    "lat": 32.27557,
    "lng": -87.78482,
    "state_id": "AL"
  },
  {
    "zip": 36749,
    "lat": 32.54319,
    "lng": -86.86168,
    "state_id": "AL"
  },
  {
    "zip": 36750,
    "lat": 32.78696,
    "lng": -86.85061,
    "state_id": "AL"
  },
  {
    "zip": 36751,
    "lat": 31.80806,
    "lng": -87.54524,
    "state_id": "AL"
  },
  {
    "zip": 36752,
    "lat": 32.3001,
    "lng": -86.62153,
    "state_id": "AL"
  },
  {
    "zip": 36753,
    "lat": 31.81596,
    "lng": -87.08601,
    "state_id": "AL"
  },
  {
    "zip": 36754,
    "lat": 32.15406,
    "lng": -87.70088,
    "state_id": "AL"
  },
  {
    "zip": 36756,
    "lat": 32.69351,
    "lng": -87.28015,
    "state_id": "AL"
  },
  {
    "zip": 36758,
    "lat": 32.6503,
    "lng": -86.89917,
    "state_id": "AL"
  },
  {
    "zip": 36759,
    "lat": 32.42243,
    "lng": -87.28554,
    "state_id": "AL"
  },
  {
    "zip": 36761,
    "lat": 32.08456,
    "lng": -86.97495,
    "state_id": "AL"
  },
  {
    "zip": 36763,
    "lat": 32.27107,
    "lng": -87.96571,
    "state_id": "AL"
  },
  {
    "zip": 36765,
    "lat": 32.58282,
    "lng": -87.52276,
    "state_id": "AL"
  },
  {
    "zip": 36766,
    "lat": 31.95509,
    "lng": -87.08677,
    "state_id": "AL"
  },
  {
    "zip": 36767,
    "lat": 32.26011,
    "lng": -87.19071,
    "state_id": "AL"
  },
  {
    "zip": 36768,
    "lat": 31.90805,
    "lng": -87.02166,
    "state_id": "AL"
  },
  {
    "zip": 36769,
    "lat": 31.99843,
    "lng": -87.53861,
    "state_id": "AL"
  },
  {
    "zip": 36773,
    "lat": 32.261,
    "lng": -87.35878,
    "state_id": "AL"
  },
  {
    "zip": 36775,
    "lat": 32.22924,
    "lng": -87.01663,
    "state_id": "AL"
  },
  {
    "zip": 36776,
    "lat": 32.74498,
    "lng": -87.74252,
    "state_id": "AL"
  },
  {
    "zip": 36782,
    "lat": 32.12822,
    "lng": -87.91857,
    "state_id": "AL"
  },
  {
    "zip": 36783,
    "lat": 32.24724,
    "lng": -87.60102,
    "state_id": "AL"
  },
  {
    "zip": 36784,
    "lat": 31.93985,
    "lng": -87.80013,
    "state_id": "AL"
  },
  {
    "zip": 36785,
    "lat": 32.25267,
    "lng": -86.83672,
    "state_id": "AL"
  },
  {
    "zip": 36786,
    "lat": 32.43966,
    "lng": -87.48807,
    "state_id": "AL"
  },
  {
    "zip": 36790,
    "lat": 32.73321,
    "lng": -86.89632,
    "state_id": "AL"
  },
  {
    "zip": 36792,
    "lat": 32.92158,
    "lng": -86.90596,
    "state_id": "AL"
  },
  {
    "zip": 36793,
    "lat": 32.81731,
    "lng": -87.02616,
    "state_id": "AL"
  },
  {
    "zip": 36801,
    "lat": 32.69174,
    "lng": -85.39719,
    "state_id": "AL"
  },
  {
    "zip": 36804,
    "lat": 32.53346,
    "lng": -85.34221,
    "state_id": "AL"
  },
  {
    "zip": 36830,
    "lat": 32.53888,
    "lng": -85.49206,
    "state_id": "AL"
  },
  {
    "zip": 36832,
    "lat": 32.59621,
    "lng": -85.58195,
    "state_id": "AL"
  },
  {
    "zip": 36849,
    "lat": 32.60241,
    "lng": -85.4873,
    "state_id": "AL"
  },
  {
    "zip": 36850,
    "lat": 32.78588,
    "lng": -85.65686,
    "state_id": "AL"
  },
  {
    "zip": 36852,
    "lat": 32.75726,
    "lng": -85.29141,
    "state_id": "AL"
  },
  {
    "zip": 36853,
    "lat": 32.80723,
    "lng": -85.76792,
    "state_id": "AL"
  },
  {
    "zip": 36854,
    "lat": 32.75379,
    "lng": -85.18444,
    "state_id": "AL"
  },
  {
    "zip": 36855,
    "lat": 33.03163,
    "lng": -85.30253,
    "state_id": "AL"
  },
  {
    "zip": 36856,
    "lat": 32.27659,
    "lng": -84.99898,
    "state_id": "AL"
  },
  {
    "zip": 36858,
    "lat": 32.31552,
    "lng": -85.32069,
    "state_id": "AL"
  },
  {
    "zip": 36859,
    "lat": 32.23258,
    "lng": -85.00448,
    "state_id": "AL"
  },
  {
    "zip": 36860,
    "lat": 32.25246,
    "lng": -85.41887,
    "state_id": "AL"
  },
  {
    "zip": 36861,
    "lat": 32.88566,
    "lng": -85.83258,
    "state_id": "AL"
  },
  {
    "zip": 36862,
    "lat": 32.90442,
    "lng": -85.4418,
    "state_id": "AL"
  },
  {
    "zip": 36863,
    "lat": 32.89515,
    "lng": -85.25508,
    "state_id": "AL"
  },
  {
    "zip": 36865,
    "lat": 32.60285,
    "lng": -85.58996,
    "state_id": "AL"
  },
  {
    "zip": 36866,
    "lat": 32.55437,
    "lng": -85.71545,
    "state_id": "AL"
  },
  {
    "zip": 36867,
    "lat": 32.49662,
    "lng": -85.02348,
    "state_id": "AL"
  },
  {
    "zip": 36869,
    "lat": 32.42015,
    "lng": -85.07984,
    "state_id": "AL"
  },
  {
    "zip": 36870,
    "lat": 32.48403,
    "lng": -85.12019,
    "state_id": "AL"
  },
  {
    "zip": 36871,
    "lat": 32.18103,
    "lng": -85.15228,
    "state_id": "AL"
  },
  {
    "zip": 36874,
    "lat": 32.56557,
    "lng": -85.20573,
    "state_id": "AL"
  },
  {
    "zip": 36875,
    "lat": 32.33317,
    "lng": -85.17286,
    "state_id": "AL"
  },
  {
    "zip": 36877,
    "lat": 32.55869,
    "lng": -85.09875,
    "state_id": "AL"
  },
  {
    "zip": 36879,
    "lat": 32.72837,
    "lng": -85.55882,
    "state_id": "AL"
  },
  {
    "zip": 36901,
    "lat": 32.42547,
    "lng": -88.14741,
    "state_id": "AL"
  },
  {
    "zip": 36904,
    "lat": 32.10202,
    "lng": -88.25861,
    "state_id": "AL"
  },
  {
    "zip": 36907,
    "lat": 32.43646,
    "lng": -88.34329,
    "state_id": "AL"
  },
  {
    "zip": 36908,
    "lat": 31.90115,
    "lng": -88.31018,
    "state_id": "AL"
  },
  {
    "zip": 36910,
    "lat": 32.20461,
    "lng": -88.14693,
    "state_id": "AL"
  },
  {
    "zip": 36912,
    "lat": 32.23514,
    "lng": -88.3045,
    "state_id": "AL"
  },
  {
    "zip": 36913,
    "lat": 31.96099,
    "lng": -88.4471,
    "state_id": "AL"
  },
  {
    "zip": 36915,
    "lat": 31.95507,
    "lng": -88.35871,
    "state_id": "AL"
  },
  {
    "zip": 36916,
    "lat": 32.20996,
    "lng": -88.05649,
    "state_id": "AL"
  },
  {
    "zip": 36919,
    "lat": 31.76628,
    "lng": -88.29301,
    "state_id": "AL"
  },
  {
    "zip": 36921,
    "lat": 31.96815,
    "lng": -88.19167,
    "state_id": "AL"
  },
  {
    "zip": 36922,
    "lat": 32.30378,
    "lng": -88.14721,
    "state_id": "AL"
  },
  {
    "zip": 36925,
    "lat": 32.43259,
    "lng": -88.1782,
    "state_id": "AL"
  },
  {
    "zip": 71601,
    "lat": 34.17716,
    "lng": -91.90318,
    "state_id": "AR"
  },
  {
    "zip": 71602,
    "lat": 34.26709,
    "lng": -92.14304,
    "state_id": "AR"
  },
  {
    "zip": 71603,
    "lat": 34.12623,
    "lng": -92.08641,
    "state_id": "AR"
  },
  {
    "zip": 71630,
    "lat": 33.62346,
    "lng": -91.20873,
    "state_id": "AR"
  },
  {
    "zip": 71631,
    "lat": 33.58006,
    "lng": -92.26493,
    "state_id": "AR"
  },
  {
    "zip": 71635,
    "lat": 33.13015,
    "lng": -92.00055,
    "state_id": "AR"
  },
  {
    "zip": 71638,
    "lat": 33.51931,
    "lng": -91.48055,
    "state_id": "AR"
  },
  {
    "zip": 71639,
    "lat": 33.89822,
    "lng": -91.52662,
    "state_id": "AR"
  },
  {
    "zip": 71640,
    "lat": 33.10564,
    "lng": -91.28358,
    "state_id": "AR"
  },
  {
    "zip": 71642,
    "lat": 33.3876,
    "lng": -91.89074,
    "state_id": "AR"
  },
  {
    "zip": 71643,
    "lat": 34.01741,
    "lng": -91.57343,
    "state_id": "AR"
  },
  {
    "zip": 71644,
    "lat": 34.09406,
    "lng": -91.70391,
    "state_id": "AR"
  },
  {
    "zip": 71646,
    "lat": 33.23115,
    "lng": -91.78382,
    "state_id": "AR"
  },
  {
    "zip": 71647,
    "lat": 33.36482,
    "lng": -92.11938,
    "state_id": "AR"
  },
  {
    "zip": 71651,
    "lat": 33.31764,
    "lng": -92.2823,
    "state_id": "AR"
  },
  {
    "zip": 71652,
    "lat": 33.9307,
    "lng": -92.33571,
    "state_id": "AR"
  },
  {
    "zip": 71653,
    "lat": 33.34113,
    "lng": -91.25753,
    "state_id": "AR"
  },
  {
    "zip": 71654,
    "lat": 33.62067,
    "lng": -91.36945,
    "state_id": "AR"
  },
  {
    "zip": 71655,
    "lat": 33.63089,
    "lng": -91.7465,
    "state_id": "AR"
  },
  {
    "zip": 71658,
    "lat": 33.31536,
    "lng": -91.57218,
    "state_id": "AR"
  },
  {
    "zip": 71659,
    "lat": 34.14357,
    "lng": -91.77587,
    "state_id": "AR"
  },
  {
    "zip": 71660,
    "lat": 33.76075,
    "lng": -92.18747,
    "state_id": "AR"
  },
  {
    "zip": 71661,
    "lat": 33.14893,
    "lng": -91.54702,
    "state_id": "AR"
  },
  {
    "zip": 71662,
    "lat": 33.84204,
    "lng": -91.50226,
    "state_id": "AR"
  },
  {
    "zip": 71663,
    "lat": 33.22983,
    "lng": -91.43136,
    "state_id": "AR"
  },
  {
    "zip": 71665,
    "lat": 33.93378,
    "lng": -92.11527,
    "state_id": "AR"
  },
  {
    "zip": 71666,
    "lat": 33.76107,
    "lng": -91.2822,
    "state_id": "AR"
  },
  {
    "zip": 71667,
    "lat": 33.92544,
    "lng": -91.83073,
    "state_id": "AR"
  },
  {
    "zip": 71670,
    "lat": 33.73689,
    "lng": -91.40689,
    "state_id": "AR"
  },
  {
    "zip": 71671,
    "lat": 33.60432,
    "lng": -92.10147,
    "state_id": "AR"
  },
  {
    "zip": 71674,
    "lat": 33.84142,
    "lng": -91.19037,
    "state_id": "AR"
  },
  {
    "zip": 71675,
    "lat": 33.58849,
    "lng": -91.93911,
    "state_id": "AR"
  },
  {
    "zip": 71676,
    "lat": 33.05411,
    "lng": -91.56857,
    "state_id": "AR"
  },
  {
    "zip": 71677,
    "lat": 33.76356,
    "lng": -91.48069,
    "state_id": "AR"
  },
  {
    "zip": 71701,
    "lat": 33.58632,
    "lng": -92.81988,
    "state_id": "AR"
  },
  {
    "zip": 71711,
    "lat": 33.63752,
    "lng": -92.72032,
    "state_id": "AR"
  },
  {
    "zip": 71720,
    "lat": 33.78101,
    "lng": -92.64823,
    "state_id": "AR"
  },
  {
    "zip": 71722,
    "lat": 33.71539,
    "lng": -93.1537,
    "state_id": "AR"
  },
  {
    "zip": 71724,
    "lat": 33.32735,
    "lng": -92.53609,
    "state_id": "AR"
  },
  {
    "zip": 71725,
    "lat": 34.04513,
    "lng": -92.55299,
    "state_id": "AR"
  },
  {
    "zip": 71726,
    "lat": 33.67894,
    "lng": -92.99646,
    "state_id": "AR"
  },
  {
    "zip": 71730,
    "lat": 33.20063,
    "lng": -92.62908,
    "state_id": "AR"
  },
  {
    "zip": 71740,
    "lat": 33.08112,
    "lng": -93.16366,
    "state_id": "AR"
  },
  {
    "zip": 71742,
    "lat": 33.84884,
    "lng": -92.45282,
    "state_id": "AR"
  },
  {
    "zip": 71743,
    "lat": 33.88688,
    "lng": -93.11776,
    "state_id": "AR"
  },
  {
    "zip": 71744,
    "lat": 33.49277,
    "lng": -92.47808,
    "state_id": "AR"
  },
  {
    "zip": 71745,
    "lat": 33.50072,
    "lng": -92.39604,
    "state_id": "AR"
  },
  {
    "zip": 71747,
    "lat": 33.05951,
    "lng": -92.21518,
    "state_id": "AR"
  },
  {
    "zip": 71749,
    "lat": 33.07169,
    "lng": -92.82023,
    "state_id": "AR"
  },
  {
    "zip": 71751,
    "lat": 33.40703,
    "lng": -92.76693,
    "state_id": "AR"
  },
  {
    "zip": 71752,
    "lat": 33.40086,
    "lng": -93.19552,
    "state_id": "AR"
  },
  {
    "zip": 71753,
    "lat": 33.23018,
    "lng": -93.18403,
    "state_id": "AR"
  },
  {
    "zip": 71758,
    "lat": 33.3076,
    "lng": -92.91426,
    "state_id": "AR"
  },
  {
    "zip": 71759,
    "lat": 33.32098,
    "lng": -92.64412,
    "state_id": "AR"
  },
  {
    "zip": 71762,
    "lat": 33.33795,
    "lng": -92.77119,
    "state_id": "AR"
  },
  {
    "zip": 71763,
    "lat": 33.91824,
    "lng": -92.79802,
    "state_id": "AR"
  },
  {
    "zip": 71764,
    "lat": 33.43172,
    "lng": -93.0469,
    "state_id": "AR"
  },
  {
    "zip": 71765,
    "lat": 33.13195,
    "lng": -92.33118,
    "state_id": "AR"
  },
  {
    "zip": 71766,
    "lat": 33.72222,
    "lng": -92.50976,
    "state_id": "AR"
  },
  {
    "zip": 71770,
    "lat": 33.38938,
    "lng": -93.31801,
    "state_id": "AR"
  },
  {
    "zip": 71772,
    "lat": 33.83274,
    "lng": -93.12606,
    "state_id": "AR"
  },
  {
    "zip": 71801,
    "lat": 33.64418,
    "lng": -93.59444,
    "state_id": "AR"
  },
  {
    "zip": 71820,
    "lat": 33.79444,
    "lng": -94.26818,
    "state_id": "AR"
  },
  {
    "zip": 71822,
    "lat": 33.66116,
    "lng": -94.1564,
    "state_id": "AR"
  },
  {
    "zip": 71823,
    "lat": 33.82336,
    "lng": -94.1255,
    "state_id": "AR"
  },
  {
    "zip": 71825,
    "lat": 33.87815,
    "lng": -93.55981,
    "state_id": "AR"
  },
  {
    "zip": 71826,
    "lat": 33.09207,
    "lng": -93.72343,
    "state_id": "AR"
  },
  {
    "zip": 71827,
    "lat": 33.42497,
    "lng": -93.41143,
    "state_id": "AR"
  },
  {
    "zip": 71832,
    "lat": 34.03782,
    "lng": -94.35087,
    "state_id": "AR"
  },
  {
    "zip": 71833,
    "lat": 34.14469,
    "lng": -94.04101,
    "state_id": "AR"
  },
  {
    "zip": 71834,
    "lat": 33.11619,
    "lng": -93.95382,
    "state_id": "AR"
  },
  {
    "zip": 71835,
    "lat": 33.6647,
    "lng": -93.44504,
    "state_id": "AR"
  },
  {
    "zip": 71836,
    "lat": 33.71554,
    "lng": -94.39701,
    "state_id": "AR"
  },
  {
    "zip": 71837,
    "lat": 33.25782,
    "lng": -93.85445,
    "state_id": "AR"
  },
  {
    "zip": 71838,
    "lat": 33.65149,
    "lng": -93.80627,
    "state_id": "AR"
  },
  {
    "zip": 71839,
    "lat": 33.31316,
    "lng": -93.73563,
    "state_id": "AR"
  },
  {
    "zip": 71841,
    "lat": 34.16038,
    "lng": -94.33923,
    "state_id": "AR"
  },
  {
    "zip": 71842,
    "lat": 33.90175,
    "lng": -94.28705,
    "state_id": "AR"
  },
  {
    "zip": 71845,
    "lat": 33.32436,
    "lng": -93.62769,
    "state_id": "AR"
  },
  {
    "zip": 71846,
    "lat": 33.94123,
    "lng": -94.12412,
    "state_id": "AR"
  },
  {
    "zip": 71847,
    "lat": 33.92682,
    "lng": -93.63492,
    "state_id": "AR"
  },
  {
    "zip": 71851,
    "lat": 33.85655,
    "lng": -93.94239,
    "state_id": "AR"
  },
  {
    "zip": 71852,
    "lat": 33.99921,
    "lng": -93.85535,
    "state_id": "AR"
  },
  {
    "zip": 71853,
    "lat": 33.59098,
    "lng": -93.94653,
    "state_id": "AR"
  },
  {
    "zip": 71854,
    "lat": 33.44698,
    "lng": -93.9015,
    "state_id": "AR"
  },
  {
    "zip": 71855,
    "lat": 33.86189,
    "lng": -93.74613,
    "state_id": "AR"
  },
  {
    "zip": 71857,
    "lat": 33.80574,
    "lng": -93.34535,
    "state_id": "AR"
  },
  {
    "zip": 71858,
    "lat": 33.58905,
    "lng": -93.26618,
    "state_id": "AR"
  },
  {
    "zip": 71859,
    "lat": 33.74897,
    "lng": -93.90821,
    "state_id": "AR"
  },
  {
    "zip": 71860,
    "lat": 33.30174,
    "lng": -93.48877,
    "state_id": "AR"
  },
  {
    "zip": 71861,
    "lat": 33.10317,
    "lng": -93.49284,
    "state_id": "AR"
  },
  {
    "zip": 71862,
    "lat": 33.76964,
    "lng": -93.7342,
    "state_id": "AR"
  },
  {
    "zip": 71865,
    "lat": 33.74113,
    "lng": -94.15002,
    "state_id": "AR"
  },
  {
    "zip": 71866,
    "lat": 33.88046,
    "lng": -94.40387,
    "state_id": "AR"
  },
  {
    "zip": 71901,
    "lat": 34.52698,
    "lng": -92.97157,
    "state_id": "AR"
  },
  {
    "zip": 71909,
    "lat": 34.64093,
    "lng": -92.9994,
    "state_id": "AR"
  },
  {
    "zip": 71913,
    "lat": 34.44813,
    "lng": -93.09525,
    "state_id": "AR"
  },
  {
    "zip": 71921,
    "lat": 34.24925,
    "lng": -93.39338,
    "state_id": "AR"
  },
  {
    "zip": 71922,
    "lat": 34.02443,
    "lng": -93.41943,
    "state_id": "AR"
  },
  {
    "zip": 71923,
    "lat": 34.08201,
    "lng": -93.04608,
    "state_id": "AR"
  },
  {
    "zip": 71929,
    "lat": 34.30584,
    "lng": -93.16262,
    "state_id": "AR"
  },
  {
    "zip": 71933,
    "lat": 34.41033,
    "lng": -93.42233,
    "state_id": "AR"
  },
  {
    "zip": 71935,
    "lat": 34.39137,
    "lng": -93.76395,
    "state_id": "AR"
  },
  {
    "zip": 71937,
    "lat": 34.39982,
    "lng": -94.38939,
    "state_id": "AR"
  },
  {
    "zip": 71940,
    "lat": 34.02053,
    "lng": -93.50968,
    "state_id": "AR"
  },
  {
    "zip": 71941,
    "lat": 34.24831,
    "lng": -92.95188,
    "state_id": "AR"
  },
  {
    "zip": 71943,
    "lat": 34.33941,
    "lng": -93.59453,
    "state_id": "AR"
  },
  {
    "zip": 71944,
    "lat": 34.24348,
    "lng": -94.33411,
    "state_id": "AR"
  },
  {
    "zip": 71945,
    "lat": 34.48402,
    "lng": -94.33953,
    "state_id": "AR"
  },
  {
    "zip": 71949,
    "lat": 34.69497,
    "lng": -93.21877,
    "state_id": "AR"
  },
  {
    "zip": 71950,
    "lat": 34.24822,
    "lng": -93.71021,
    "state_id": "AR"
  },
  {
    "zip": 71952,
    "lat": 34.30388,
    "lng": -93.85355,
    "state_id": "AR"
  },
  {
    "zip": 71953,
    "lat": 34.61426,
    "lng": -94.19491,
    "state_id": "AR"
  },
  {
    "zip": 71956,
    "lat": 34.59761,
    "lng": -93.15388,
    "state_id": "AR"
  },
  {
    "zip": 71957,
    "lat": 34.56373,
    "lng": -93.58006,
    "state_id": "AR"
  },
  {
    "zip": 71958,
    "lat": 34.11258,
    "lng": -93.66607,
    "state_id": "AR"
  },
  {
    "zip": 71959,
    "lat": 34.24732,
    "lng": -93.93295,
    "state_id": "AR"
  },
  {
    "zip": 71960,
    "lat": 34.47914,
    "lng": -93.70851,
    "state_id": "AR"
  },
  {
    "zip": 71961,
    "lat": 34.59807,
    "lng": -93.84058,
    "state_id": "AR"
  },
  {
    "zip": 71962,
    "lat": 34.03345,
    "lng": -93.32632,
    "state_id": "AR"
  },
  {
    "zip": 71964,
    "lat": 34.42296,
    "lng": -93.24347,
    "state_id": "AR"
  },
  {
    "zip": 71965,
    "lat": 34.66556,
    "lng": -93.74265,
    "state_id": "AR"
  },
  {
    "zip": 71968,
    "lat": 34.52731,
    "lng": -93.29735,
    "state_id": "AR"
  },
  {
    "zip": 71969,
    "lat": 34.66611,
    "lng": -93.63224,
    "state_id": "AR"
  },
  {
    "zip": 71970,
    "lat": 34.66455,
    "lng": -93.49157,
    "state_id": "AR"
  },
  {
    "zip": 71971,
    "lat": 34.30388,
    "lng": -94.03726,
    "state_id": "AR"
  },
  {
    "zip": 71972,
    "lat": 34.3867,
    "lng": -94.27142,
    "state_id": "AR"
  },
  {
    "zip": 71973,
    "lat": 34.29961,
    "lng": -94.31719,
    "state_id": "AR"
  },
  {
    "zip": 71998,
    "lat": 34.12581,
    "lng": -93.05379,
    "state_id": "AR"
  },
  {
    "zip": 71999,
    "lat": 34.12746,
    "lng": -93.05906,
    "state_id": "AR"
  },
  {
    "zip": 72001,
    "lat": 35.06646,
    "lng": -92.87931,
    "state_id": "AR"
  },
  {
    "zip": 72002,
    "lat": 34.66298,
    "lng": -92.52896,
    "state_id": "AR"
  },
  {
    "zip": 72003,
    "lat": 34.38592,
    "lng": -91.39779,
    "state_id": "AR"
  },
  {
    "zip": 72004,
    "lat": 34.28206,
    "lng": -91.77952,
    "state_id": "AR"
  },
  {
    "zip": 72005,
    "lat": 35.54,
    "lng": -91.07153,
    "state_id": "AR"
  },
  {
    "zip": 72006,
    "lat": 35.24538,
    "lng": -91.35119,
    "state_id": "AR"
  },
  {
    "zip": 72007,
    "lat": 35.00244,
    "lng": -91.98189,
    "state_id": "AR"
  },
  {
    "zip": 72010,
    "lat": 35.32021,
    "lng": -91.53608,
    "state_id": "AR"
  },
  {
    "zip": 72011,
    "lat": 34.51179,
    "lng": -92.47154,
    "state_id": "AR"
  },
  {
    "zip": 72012,
    "lat": 35.09825,
    "lng": -91.91742,
    "state_id": "AR"
  },
  {
    "zip": 72013,
    "lat": 35.45671,
    "lng": -92.36836,
    "state_id": "AR"
  },
  {
    "zip": 72014,
    "lat": 35.43264,
    "lng": -91.11132,
    "state_id": "AR"
  },
  {
    "zip": 72015,
    "lat": 34.49333,
    "lng": -92.58959,
    "state_id": "AR"
  },
  {
    "zip": 72016,
    "lat": 34.9708,
    "lng": -92.63161,
    "state_id": "AR"
  },
  {
    "zip": 72017,
    "lat": 34.89727,
    "lng": -91.41029,
    "state_id": "AR"
  },
  {
    "zip": 72019,
    "lat": 34.63535,
    "lng": -92.68945,
    "state_id": "AR"
  },
  {
    "zip": 72020,
    "lat": 35.47232,
    "lng": -91.47508,
    "state_id": "AR"
  },
  {
    "zip": 72021,
    "lat": 34.84675,
    "lng": -91.22429,
    "state_id": "AR"
  },
  {
    "zip": 72022,
    "lat": 34.6043,
    "lng": -92.48832,
    "state_id": "AR"
  },
  {
    "zip": 72023,
    "lat": 34.95755,
    "lng": -92.05649,
    "state_id": "AR"
  },
  {
    "zip": 72024,
    "lat": 34.76847,
    "lng": -91.75098,
    "state_id": "AR"
  },
  {
    "zip": 72025,
    "lat": 35.05527,
    "lng": -92.99815,
    "state_id": "AR"
  },
  {
    "zip": 72026,
    "lat": 34.50986,
    "lng": -91.30631,
    "state_id": "AR"
  },
  {
    "zip": 72027,
    "lat": 35.37104,
    "lng": -92.57652,
    "state_id": "AR"
  },
  {
    "zip": 72029,
    "lat": 34.70687,
    "lng": -91.23381,
    "state_id": "AR"
  },
  {
    "zip": 72030,
    "lat": 35.43579,
    "lng": -92.68117,
    "state_id": "AR"
  },
  {
    "zip": 72031,
    "lat": 35.61345,
    "lng": -92.52567,
    "state_id": "AR"
  },
  {
    "zip": 72032,
    "lat": 35.07331,
    "lng": -92.36154,
    "state_id": "AR"
  },
  {
    "zip": 72034,
    "lat": 35.05041,
    "lng": -92.48685,
    "state_id": "AR"
  },
  {
    "zip": 72035,
    "lat": 35.07878,
    "lng": -92.45746,
    "state_id": "AR"
  },
  {
    "zip": 72036,
    "lat": 35.00634,
    "lng": -91.27137,
    "state_id": "AR"
  },
  {
    "zip": 72037,
    "lat": 34.53896,
    "lng": -91.8737,
    "state_id": "AR"
  },
  {
    "zip": 72038,
    "lat": 34.44629,
    "lng": -91.24382,
    "state_id": "AR"
  },
  {
    "zip": 72039,
    "lat": 35.35547,
    "lng": -92.40266,
    "state_id": "AR"
  },
  {
    "zip": 72040,
    "lat": 34.97643,
    "lng": -91.54038,
    "state_id": "AR"
  },
  {
    "zip": 72041,
    "lat": 34.72603,
    "lng": -91.47398,
    "state_id": "AR"
  },
  {
    "zip": 72042,
    "lat": 34.26769,
    "lng": -91.31609,
    "state_id": "AR"
  },
  {
    "zip": 72044,
    "lat": 35.6721,
    "lng": -92.17129,
    "state_id": "AR"
  },
  {
    "zip": 72045,
    "lat": 35.1289,
    "lng": -92.0484,
    "state_id": "AR"
  },
  {
    "zip": 72046,
    "lat": 34.55647,
    "lng": -91.95611,
    "state_id": "AR"
  },
  {
    "zip": 72047,
    "lat": 35.22381,
    "lng": -92.21065,
    "state_id": "AR"
  },
  {
    "zip": 72048,
    "lat": 34.23175,
    "lng": -91.12362,
    "state_id": "AR"
  },
  {
    "zip": 72051,
    "lat": 35.7885,
    "lng": -92.30834,
    "state_id": "AR"
  },
  {
    "zip": 72055,
    "lat": 34.10264,
    "lng": -91.36248,
    "state_id": "AR"
  },
  {
    "zip": 72057,
    "lat": 34.14421,
    "lng": -92.31452,
    "state_id": "AR"
  },
  {
    "zip": 72058,
    "lat": 35.24213,
    "lng": -92.37061,
    "state_id": "AR"
  },
  {
    "zip": 72059,
    "lat": 35.13943,
    "lng": -91.31499,
    "state_id": "AR"
  },
  {
    "zip": 72060,
    "lat": 35.08408,
    "lng": -91.60119,
    "state_id": "AR"
  },
  {
    "zip": 72061,
    "lat": 35.3219,
    "lng": -92.29905,
    "state_id": "AR"
  },
  {
    "zip": 72063,
    "lat": 35.32301,
    "lng": -92.77242,
    "state_id": "AR"
  },
  {
    "zip": 72064,
    "lat": 34.79204,
    "lng": -91.61099,
    "state_id": "AR"
  },
  {
    "zip": 72065,
    "lat": 34.52073,
    "lng": -92.28885,
    "state_id": "AR"
  },
  {
    "zip": 72067,
    "lat": 35.5646,
    "lng": -92.15825,
    "state_id": "AR"
  },
  {
    "zip": 72068,
    "lat": 35.19029,
    "lng": -91.71078,
    "state_id": "AR"
  },
  {
    "zip": 72069,
    "lat": 34.53481,
    "lng": -91.13087,
    "state_id": "AR"
  },
  {
    "zip": 72070,
    "lat": 35.00913,
    "lng": -92.70903,
    "state_id": "AR"
  },
  {
    "zip": 72072,
    "lat": 34.52489,
    "lng": -91.73979,
    "state_id": "AR"
  },
  {
    "zip": 72073,
    "lat": 34.37223,
    "lng": -91.66961,
    "state_id": "AR"
  },
  {
    "zip": 72074,
    "lat": 35.08172,
    "lng": -91.09052,
    "state_id": "AR"
  },
  {
    "zip": 72076,
    "lat": 34.91137,
    "lng": -92.1435,
    "state_id": "AR"
  },
  {
    "zip": 72079,
    "lat": 34.39059,
    "lng": -92.17612,
    "state_id": "AR"
  },
  {
    "zip": 72080,
    "lat": 35.52434,
    "lng": -92.80503,
    "state_id": "AR"
  },
  {
    "zip": 72081,
    "lat": 35.37929,
    "lng": -91.66979,
    "state_id": "AR"
  },
  {
    "zip": 72082,
    "lat": 35.23291,
    "lng": -91.67364,
    "state_id": "AR"
  },
  {
    "zip": 72083,
    "lat": 34.594,
    "lng": -92.01352,
    "state_id": "AR"
  },
  {
    "zip": 72084,
    "lat": 34.14418,
    "lng": -92.66366,
    "state_id": "AR"
  },
  {
    "zip": 72085,
    "lat": 35.36384,
    "lng": -91.82906,
    "state_id": "AR"
  },
  {
    "zip": 72086,
    "lat": 34.80894,
    "lng": -91.90941,
    "state_id": "AR"
  },
  {
    "zip": 72087,
    "lat": 34.59487,
    "lng": -92.8311,
    "state_id": "AR"
  },
  {
    "zip": 72088,
    "lat": 35.59803,
    "lng": -92.26694,
    "state_id": "AR"
  },
  {
    "zip": 72099,
    "lat": 34.89923,
    "lng": -92.14142,
    "state_id": "AR"
  },
  {
    "zip": 72101,
    "lat": 35.24003,
    "lng": -91.15672,
    "state_id": "AR"
  },
  {
    "zip": 72102,
    "lat": 35.14596,
    "lng": -91.83545,
    "state_id": "AR"
  },
  {
    "zip": 72103,
    "lat": 34.58979,
    "lng": -92.38373,
    "state_id": "AR"
  },
  {
    "zip": 72104,
    "lat": 34.3435,
    "lng": -92.82349,
    "state_id": "AR"
  },
  {
    "zip": 72106,
    "lat": 34.96663,
    "lng": -92.48207,
    "state_id": "AR"
  },
  {
    "zip": 72107,
    "lat": 35.14333,
    "lng": -92.54178,
    "state_id": "AR"
  },
  {
    "zip": 72108,
    "lat": 34.70578,
    "lng": -91.10309,
    "state_id": "AR"
  },
  {
    "zip": 72110,
    "lat": 35.15466,
    "lng": -92.77549,
    "state_id": "AR"
  },
  {
    "zip": 72111,
    "lat": 35.23583,
    "lng": -92.13191,
    "state_id": "AR"
  },
  {
    "zip": 72112,
    "lat": 35.57975,
    "lng": -91.22613,
    "state_id": "AR"
  },
  {
    "zip": 72113,
    "lat": 34.85942,
    "lng": -92.39892,
    "state_id": "AR"
  },
  {
    "zip": 72114,
    "lat": 34.76446,
    "lng": -92.25848,
    "state_id": "AR"
  },
  {
    "zip": 72116,
    "lat": 34.80048,
    "lng": -92.24492,
    "state_id": "AR"
  },
  {
    "zip": 72117,
    "lat": 34.7774,
    "lng": -92.14389,
    "state_id": "AR"
  },
  {
    "zip": 72118,
    "lat": 34.84134,
    "lng": -92.32981,
    "state_id": "AR"
  },
  {
    "zip": 72119,
    "lat": 34.83406,
    "lng": -92.29224,
    "state_id": "AR"
  },
  {
    "zip": 72120,
    "lat": 34.89896,
    "lng": -92.24109,
    "state_id": "AR"
  },
  {
    "zip": 72121,
    "lat": 35.45525,
    "lng": -91.78618,
    "state_id": "AR"
  },
  {
    "zip": 72122,
    "lat": 34.78239,
    "lng": -92.77737,
    "state_id": "AR"
  },
  {
    "zip": 72123,
    "lat": 35.25867,
    "lng": -91.23553,
    "state_id": "AR"
  },
  {
    "zip": 72125,
    "lat": 35.06787,
    "lng": -92.78675,
    "state_id": "AR"
  },
  {
    "zip": 72126,
    "lat": 34.93742,
    "lng": -92.91482,
    "state_id": "AR"
  },
  {
    "zip": 72127,
    "lat": 35.16663,
    "lng": -92.60262,
    "state_id": "AR"
  },
  {
    "zip": 72128,
    "lat": 34.31945,
    "lng": -92.6329,
    "state_id": "AR"
  },
  {
    "zip": 72129,
    "lat": 34.32775,
    "lng": -92.54194,
    "state_id": "AR"
  },
  {
    "zip": 72130,
    "lat": 35.66413,
    "lng": -92.07054,
    "state_id": "AR"
  },
  {
    "zip": 72131,
    "lat": 35.41015,
    "lng": -92.19204,
    "state_id": "AR"
  },
  {
    "zip": 72132,
    "lat": 34.44933,
    "lng": -92.20559,
    "state_id": "AR"
  },
  {
    "zip": 72134,
    "lat": 34.62872,
    "lng": -91.36864,
    "state_id": "AR"
  },
  {
    "zip": 72135,
    "lat": 34.87549,
    "lng": -92.55303,
    "state_id": "AR"
  },
  {
    "zip": 72136,
    "lat": 35.2358,
    "lng": -92.01631,
    "state_id": "AR"
  },
  {
    "zip": 72137,
    "lat": 35.33693,
    "lng": -92.03003,
    "state_id": "AR"
  },
  {
    "zip": 72139,
    "lat": 35.36114,
    "lng": -91.50761,
    "state_id": "AR"
  },
  {
    "zip": 72140,
    "lat": 34.36798,
    "lng": -91.16059,
    "state_id": "AR"
  },
  {
    "zip": 72141,
    "lat": 35.51822,
    "lng": -92.67973,
    "state_id": "AR"
  },
  {
    "zip": 72142,
    "lat": 34.69513,
    "lng": -92.05828,
    "state_id": "AR"
  },
  {
    "zip": 72143,
    "lat": 35.22889,
    "lng": -91.73564,
    "state_id": "AR"
  },
  {
    "zip": 72149,
    "lat": 35.24909,
    "lng": -91.72621,
    "state_id": "AR"
  },
  {
    "zip": 72150,
    "lat": 34.31861,
    "lng": -92.39326,
    "state_id": "AR"
  },
  {
    "zip": 72152,
    "lat": 34.35525,
    "lng": -91.97738,
    "state_id": "AR"
  },
  {
    "zip": 72153,
    "lat": 35.64537,
    "lng": -92.32604,
    "state_id": "AR"
  },
  {
    "zip": 72156,
    "lat": 35.27908,
    "lng": -92.6849,
    "state_id": "AR"
  },
  {
    "zip": 72157,
    "lat": 35.27424,
    "lng": -92.55825,
    "state_id": "AR"
  },
  {
    "zip": 72160,
    "lat": 34.42235,
    "lng": -91.53303,
    "state_id": "AR"
  },
  {
    "zip": 72165,
    "lat": 35.57544,
    "lng": -91.45793,
    "state_id": "AR"
  },
  {
    "zip": 72166,
    "lat": 34.05265,
    "lng": -91.23824,
    "state_id": "AR"
  },
  {
    "zip": 72167,
    "lat": 34.42143,
    "lng": -92.65417,
    "state_id": "AR"
  },
  {
    "zip": 72168,
    "lat": 34.43847,
    "lng": -91.97077,
    "state_id": "AR"
  },
  {
    "zip": 72169,
    "lat": 35.39651,
    "lng": -91.22657,
    "state_id": "AR"
  },
  {
    "zip": 72170,
    "lat": 34.5805,
    "lng": -91.41977,
    "state_id": "AR"
  },
  {
    "zip": 72173,
    "lat": 35.09984,
    "lng": -92.20557,
    "state_id": "AR"
  },
  {
    "zip": 72175,
    "lat": 34.34329,
    "lng": -91.76259,
    "state_id": "AR"
  },
  {
    "zip": 72176,
    "lat": 35.00257,
    "lng": -91.83211,
    "state_id": "AR"
  },
  {
    "zip": 72179,
    "lat": 35.50358,
    "lng": -91.85557,
    "state_id": "AR"
  },
  {
    "zip": 72181,
    "lat": 35.16241,
    "lng": -92.44517,
    "state_id": "AR"
  },
  {
    "zip": 72201,
    "lat": 34.74728,
    "lng": -92.27985,
    "state_id": "AR"
  },
  {
    "zip": 72202,
    "lat": 34.74687,
    "lng": -92.26485,
    "state_id": "AR"
  },
  {
    "zip": 72204,
    "lat": 34.71771,
    "lng": -92.35885,
    "state_id": "AR"
  },
  {
    "zip": 72205,
    "lat": 34.75031,
    "lng": -92.3502,
    "state_id": "AR"
  },
  {
    "zip": 72206,
    "lat": 34.62843,
    "lng": -92.2383,
    "state_id": "AR"
  },
  {
    "zip": 72207,
    "lat": 34.77359,
    "lng": -92.34565,
    "state_id": "AR"
  },
  {
    "zip": 72209,
    "lat": 34.67698,
    "lng": -92.34413,
    "state_id": "AR"
  },
  {
    "zip": 72210,
    "lat": 34.71395,
    "lng": -92.50441,
    "state_id": "AR"
  },
  {
    "zip": 72211,
    "lat": 34.75064,
    "lng": -92.4171,
    "state_id": "AR"
  },
  {
    "zip": 72212,
    "lat": 34.78597,
    "lng": -92.41522,
    "state_id": "AR"
  },
  {
    "zip": 72223,
    "lat": 34.78834,
    "lng": -92.51095,
    "state_id": "AR"
  },
  {
    "zip": 72227,
    "lat": 34.77782,
    "lng": -92.37509,
    "state_id": "AR"
  },
  {
    "zip": 72301,
    "lat": 35.14482,
    "lng": -90.18806,
    "state_id": "AR"
  },
  {
    "zip": 72311,
    "lat": 34.72386,
    "lng": -90.89147,
    "state_id": "AR"
  },
  {
    "zip": 72315,
    "lat": 35.90486,
    "lng": -89.90132,
    "state_id": "AR"
  },
  {
    "zip": 72320,
    "lat": 34.82091,
    "lng": -90.51772,
    "state_id": "AR"
  },
  {
    "zip": 72321,
    "lat": 35.81592,
    "lng": -89.94464,
    "state_id": "AR"
  },
  {
    "zip": 72322,
    "lat": 35.06487,
    "lng": -90.82211,
    "state_id": "AR"
  },
  {
    "zip": 72324,
    "lat": 35.38816,
    "lng": -90.78199,
    "state_id": "AR"
  },
  {
    "zip": 72325,
    "lat": 35.32231,
    "lng": -90.24605,
    "state_id": "AR"
  },
  {
    "zip": 72326,
    "lat": 35.11193,
    "lng": -90.90049,
    "state_id": "AR"
  },
  {
    "zip": 72327,
    "lat": 35.23154,
    "lng": -90.33312,
    "state_id": "AR"
  },
  {
    "zip": 72328,
    "lat": 34.05414,
    "lng": -91.02034,
    "state_id": "AR"
  },
  {
    "zip": 72329,
    "lat": 35.61457,
    "lng": -89.9881,
    "state_id": "AR"
  },
  {
    "zip": 72330,
    "lat": 35.5878,
    "lng": -90.21023,
    "state_id": "AR"
  },
  {
    "zip": 72331,
    "lat": 35.28356,
    "lng": -90.46449,
    "state_id": "AR"
  },
  {
    "zip": 72332,
    "lat": 35.10196,
    "lng": -90.30882,
    "state_id": "AR"
  },
  {
    "zip": 72333,
    "lat": 34.29949,
    "lng": -90.86477,
    "state_id": "AR"
  },
  {
    "zip": 72335,
    "lat": 34.99129,
    "lng": -90.75671,
    "state_id": "AR"
  },
  {
    "zip": 72338,
    "lat": 35.43018,
    "lng": -90.12754,
    "state_id": "AR"
  },
  {
    "zip": 72339,
    "lat": 35.422,
    "lng": -90.26811,
    "state_id": "AR"
  },
  {
    "zip": 72340,
    "lat": 34.94086,
    "lng": -91.02821,
    "state_id": "AR"
  },
  {
    "zip": 72341,
    "lat": 34.88701,
    "lng": -90.76103,
    "state_id": "AR"
  },
  {
    "zip": 72342,
    "lat": 34.45804,
    "lng": -90.68598,
    "state_id": "AR"
  },
  {
    "zip": 72346,
    "lat": 35.08333,
    "lng": -90.48242,
    "state_id": "AR"
  },
  {
    "zip": 72347,
    "lat": 35.38533,
    "lng": -90.99975,
    "state_id": "AR"
  },
  {
    "zip": 72348,
    "lat": 34.94144,
    "lng": -90.42332,
    "state_id": "AR"
  },
  {
    "zip": 72350,
    "lat": 35.50107,
    "lng": -90.12537,
    "state_id": "AR"
  },
  {
    "zip": 72351,
    "lat": 35.67375,
    "lng": -90.09639,
    "state_id": "AR"
  },
  {
    "zip": 72353,
    "lat": 34.30138,
    "lng": -91.00696,
    "state_id": "AR"
  },
  {
    "zip": 72354,
    "lat": 35.61566,
    "lng": -90.31656,
    "state_id": "AR"
  },
  {
    "zip": 72355,
    "lat": 34.55035,
    "lng": -90.76411,
    "state_id": "AR"
  },
  {
    "zip": 72358,
    "lat": 35.79125,
    "lng": -89.88269,
    "state_id": "AR"
  },
  {
    "zip": 72359,
    "lat": 35.02283,
    "lng": -90.72168,
    "state_id": "AR"
  },
  {
    "zip": 72360,
    "lat": 34.75473,
    "lng": -90.7715,
    "state_id": "AR"
  },
  {
    "zip": 72364,
    "lat": 35.21678,
    "lng": -90.18931,
    "state_id": "AR"
  },
  {
    "zip": 72365,
    "lat": 35.51754,
    "lng": -90.43733,
    "state_id": "AR"
  },
  {
    "zip": 72366,
    "lat": 34.51978,
    "lng": -90.9578,
    "state_id": "AR"
  },
  {
    "zip": 72367,
    "lat": 34.19163,
    "lng": -90.946,
    "state_id": "AR"
  },
  {
    "zip": 72368,
    "lat": 34.80991,
    "lng": -91.02053,
    "state_id": "AR"
  },
  {
    "zip": 72370,
    "lat": 35.68547,
    "lng": -90.10121,
    "state_id": "AR"
  },
  {
    "zip": 72372,
    "lat": 34.96969,
    "lng": -90.94658,
    "state_id": "AR"
  },
  {
    "zip": 72373,
    "lat": 35.29635,
    "lng": -90.59003,
    "state_id": "AR"
  },
  {
    "zip": 72374,
    "lat": 34.56527,
    "lng": -90.83446,
    "state_id": "AR"
  },
  {
    "zip": 72376,
    "lat": 35.08681,
    "lng": -90.30056,
    "state_id": "AR"
  },
  {
    "zip": 72377,
    "lat": 35.68757,
    "lng": -90.33853,
    "state_id": "AR"
  },
  {
    "zip": 72379,
    "lat": 34.05265,
    "lng": -91.05485,
    "state_id": "AR"
  },
  {
    "zip": 72383,
    "lat": 34.49101,
    "lng": -91.0396,
    "state_id": "AR"
  },
  {
    "zip": 72384,
    "lat": 35.37434,
    "lng": -90.2212,
    "state_id": "AR"
  },
  {
    "zip": 72386,
    "lat": 35.45221,
    "lng": -90.36347,
    "state_id": "AR"
  },
  {
    "zip": 72389,
    "lat": 34.37705,
    "lng": -90.90587,
    "state_id": "AR"
  },
  {
    "zip": 72390,
    "lat": 34.55015,
    "lng": -90.67836,
    "state_id": "AR"
  },
  {
    "zip": 72392,
    "lat": 34.95705,
    "lng": -91.09854,
    "state_id": "AR"
  },
  {
    "zip": 72394,
    "lat": 35.05612,
    "lng": -90.61746,
    "state_id": "AR"
  },
  {
    "zip": 72395,
    "lat": 35.59543,
    "lng": -90.08243,
    "state_id": "AR"
  },
  {
    "zip": 72396,
    "lat": 35.23114,
    "lng": -90.82297,
    "state_id": "AR"
  },
  {
    "zip": 72401,
    "lat": 35.87895,
    "lng": -90.66061,
    "state_id": "AR"
  },
  {
    "zip": 72404,
    "lat": 35.77595,
    "lng": -90.78171,
    "state_id": "AR"
  },
  {
    "zip": 72410,
    "lat": 35.93352,
    "lng": -91.09787,
    "state_id": "AR"
  },
  {
    "zip": 72411,
    "lat": 35.74409,
    "lng": -90.57437,
    "state_id": "AR"
  },
  {
    "zip": 72412,
    "lat": 36.12416,
    "lng": -90.69436,
    "state_id": "AR"
  },
  {
    "zip": 72413,
    "lat": 36.30006,
    "lng": -90.82185,
    "state_id": "AR"
  },
  {
    "zip": 72414,
    "lat": 35.82443,
    "lng": -90.37032,
    "state_id": "AR"
  },
  {
    "zip": 72415,
    "lat": 36.12819,
    "lng": -91.17392,
    "state_id": "AR"
  },
  {
    "zip": 72416,
    "lat": 35.96188,
    "lng": -90.79513,
    "state_id": "AR"
  },
  {
    "zip": 72417,
    "lat": 35.92104,
    "lng": -90.55005,
    "state_id": "AR"
  },
  {
    "zip": 72419,
    "lat": 35.7477,
    "lng": -90.35113,
    "state_id": "AR"
  },
  {
    "zip": 72421,
    "lat": 35.78764,
    "lng": -90.97158,
    "state_id": "AR"
  },
  {
    "zip": 72422,
    "lat": 36.41929,
    "lng": -90.54196,
    "state_id": "AR"
  },
  {
    "zip": 72424,
    "lat": 36.39054,
    "lng": -90.73633,
    "state_id": "AR"
  },
  {
    "zip": 72425,
    "lat": 36.219,
    "lng": -90.73145,
    "state_id": "AR"
  },
  {
    "zip": 72426,
    "lat": 35.86974,
    "lng": -90.04195,
    "state_id": "AR"
  },
  {
    "zip": 72427,
    "lat": 35.86702,
    "lng": -90.93567,
    "state_id": "AR"
  },
  {
    "zip": 72428,
    "lat": 35.7392,
    "lng": -90.22436,
    "state_id": "AR"
  },
  {
    "zip": 72429,
    "lat": 35.49203,
    "lng": -90.9299,
    "state_id": "AR"
  },
  {
    "zip": 72430,
    "lat": 36.33451,
    "lng": -90.17128,
    "state_id": "AR"
  },
  {
    "zip": 72431,
    "lat": 35.63341,
    "lng": -91.08199,
    "state_id": "AR"
  },
  {
    "zip": 72432,
    "lat": 35.55641,
    "lng": -90.72004,
    "state_id": "AR"
  },
  {
    "zip": 72433,
    "lat": 36.02248,
    "lng": -91.04877,
    "state_id": "AR"
  },
  {
    "zip": 72434,
    "lat": 36.21679,
    "lng": -91.16267,
    "state_id": "AR"
  },
  {
    "zip": 72435,
    "lat": 36.31872,
    "lng": -90.57623,
    "state_id": "AR"
  },
  {
    "zip": 72436,
    "lat": 36.23593,
    "lng": -90.48108,
    "state_id": "AR"
  },
  {
    "zip": 72437,
    "lat": 35.83711,
    "lng": -90.45285,
    "state_id": "AR"
  },
  {
    "zip": 72438,
    "lat": 35.94653,
    "lng": -90.22113,
    "state_id": "AR"
  },
  {
    "zip": 72440,
    "lat": 35.99509,
    "lng": -91.2515,
    "state_id": "AR"
  },
  {
    "zip": 72441,
    "lat": 36.43694,
    "lng": -90.38531,
    "state_id": "AR"
  },
  {
    "zip": 72442,
    "lat": 35.81981,
    "lng": -90.1935,
    "state_id": "AR"
  },
  {
    "zip": 72443,
    "lat": 36.18045,
    "lng": -90.41421,
    "state_id": "AR"
  },
  {
    "zip": 72444,
    "lat": 36.42779,
    "lng": -90.86061,
    "state_id": "AR"
  },
  {
    "zip": 72445,
    "lat": 35.97662,
    "lng": -91.02663,
    "state_id": "AR"
  },
  {
    "zip": 72447,
    "lat": 35.91382,
    "lng": -90.33071,
    "state_id": "AR"
  },
  {
    "zip": 72449,
    "lat": 36.17748,
    "lng": -90.81964,
    "state_id": "AR"
  },
  {
    "zip": 72450,
    "lat": 36.07935,
    "lng": -90.51876,
    "state_id": "AR"
  },
  {
    "zip": 72453,
    "lat": 36.29392,
    "lng": -90.70816,
    "state_id": "AR"
  },
  {
    "zip": 72454,
    "lat": 36.40111,
    "lng": -90.20791,
    "state_id": "AR"
  },
  {
    "zip": 72455,
    "lat": 36.31838,
    "lng": -91.03612,
    "state_id": "AR"
  },
  {
    "zip": 72456,
    "lat": 36.45724,
    "lng": -90.32127,
    "state_id": "AR"
  },
  {
    "zip": 72457,
    "lat": 36.08888,
    "lng": -91.06808,
    "state_id": "AR"
  },
  {
    "zip": 72458,
    "lat": 36.07257,
    "lng": -91.15849,
    "state_id": "AR"
  },
  {
    "zip": 72459,
    "lat": 36.28903,
    "lng": -91.2983,
    "state_id": "AR"
  },
  {
    "zip": 72460,
    "lat": 36.35181,
    "lng": -91.17075,
    "state_id": "AR"
  },
  {
    "zip": 72461,
    "lat": 36.26528,
    "lng": -90.28511,
    "state_id": "AR"
  },
  {
    "zip": 72462,
    "lat": 36.37262,
    "lng": -90.77027,
    "state_id": "AR"
  },
  {
    "zip": 72464,
    "lat": 36.45792,
    "lng": -90.14416,
    "state_id": "AR"
  },
  {
    "zip": 72466,
    "lat": 36.05568,
    "lng": -91.32904,
    "state_id": "AR"
  },
  {
    "zip": 72467,
    "lat": 35.84108,
    "lng": -90.67558,
    "state_id": "AR"
  },
  {
    "zip": 72469,
    "lat": 35.95439,
    "lng": -91.29164,
    "state_id": "AR"
  },
  {
    "zip": 72470,
    "lat": 36.46731,
    "lng": -90.70641,
    "state_id": "AR"
  },
  {
    "zip": 72471,
    "lat": 35.82507,
    "lng": -91.1178,
    "state_id": "AR"
  },
  {
    "zip": 72472,
    "lat": 35.59605,
    "lng": -90.53588,
    "state_id": "AR"
  },
  {
    "zip": 72473,
    "lat": 35.73904,
    "lng": -91.17553,
    "state_id": "AR"
  },
  {
    "zip": 72476,
    "lat": 36.05179,
    "lng": -90.91956,
    "state_id": "AR"
  },
  {
    "zip": 72478,
    "lat": 36.46333,
    "lng": -91.05581,
    "state_id": "AR"
  },
  {
    "zip": 72479,
    "lat": 35.63344,
    "lng": -90.90304,
    "state_id": "AR"
  },
  {
    "zip": 72482,
    "lat": 36.275,
    "lng": -91.36554,
    "state_id": "AR"
  },
  {
    "zip": 72501,
    "lat": 35.79155,
    "lng": -91.65346,
    "state_id": "AR"
  },
  {
    "zip": 72512,
    "lat": 36.22049,
    "lng": -91.7465,
    "state_id": "AR"
  },
  {
    "zip": 72513,
    "lat": 36.22598,
    "lng": -91.65151,
    "state_id": "AR"
  },
  {
    "zip": 72515,
    "lat": 36.27636,
    "lng": -92.05641,
    "state_id": "AR"
  },
  {
    "zip": 72517,
    "lat": 36.13231,
    "lng": -91.9732,
    "state_id": "AR"
  },
  {
    "zip": 72519,
    "lat": 36.12016,
    "lng": -92.18673,
    "state_id": "AR"
  },
  {
    "zip": 72520,
    "lat": 36.39093,
    "lng": -91.72023,
    "state_id": "AR"
  },
  {
    "zip": 72521,
    "lat": 35.9513,
    "lng": -91.54273,
    "state_id": "AR"
  },
  {
    "zip": 72522,
    "lat": 35.8109,
    "lng": -91.47044,
    "state_id": "AR"
  },
  {
    "zip": 72523,
    "lat": 35.6437,
    "lng": -91.83211,
    "state_id": "AR"
  },
  {
    "zip": 72524,
    "lat": 35.83541,
    "lng": -91.30434,
    "state_id": "AR"
  },
  {
    "zip": 72526,
    "lat": 35.88364,
    "lng": -91.77895,
    "state_id": "AR"
  },
  {
    "zip": 72527,
    "lat": 35.73989,
    "lng": -91.68503,
    "state_id": "AR"
  },
  {
    "zip": 72528,
    "lat": 36.2275,
    "lng": -92.13827,
    "state_id": "AR"
  },
  {
    "zip": 72529,
    "lat": 36.29573,
    "lng": -91.56548,
    "state_id": "AR"
  },
  {
    "zip": 72530,
    "lat": 35.61818,
    "lng": -91.95341,
    "state_id": "AR"
  },
  {
    "zip": 72531,
    "lat": 36.31939,
    "lng": -92.14884,
    "state_id": "AR"
  },
  {
    "zip": 72532,
    "lat": 36.09853,
    "lng": -91.59686,
    "state_id": "AR"
  },
  {
    "zip": 72533,
    "lat": 35.97339,
    "lng": -92.25037,
    "state_id": "AR"
  },
  {
    "zip": 72534,
    "lat": 35.60003,
    "lng": -91.72594,
    "state_id": "AR"
  },
  {
    "zip": 72536,
    "lat": 36.14713,
    "lng": -91.7672,
    "state_id": "AR"
  },
  {
    "zip": 72537,
    "lat": 36.45017,
    "lng": -92.23374,
    "state_id": "AR"
  },
  {
    "zip": 72538,
    "lat": 36.44011,
    "lng": -92.10296,
    "state_id": "AR"
  },
  {
    "zip": 72539,
    "lat": 36.33215,
    "lng": -91.71112,
    "state_id": "AR"
  },
  {
    "zip": 72540,
    "lat": 35.92724,
    "lng": -91.90051,
    "state_id": "AR"
  },
  {
    "zip": 72542,
    "lat": 36.30391,
    "lng": -91.47159,
    "state_id": "AR"
  },
  {
    "zip": 72543,
    "lat": 35.45357,
    "lng": -91.98887,
    "state_id": "AR"
  },
  {
    "zip": 72544,
    "lat": 36.40612,
    "lng": -92.19054,
    "state_id": "AR"
  },
  {
    "zip": 72546,
    "lat": 35.57892,
    "lng": -91.94104,
    "state_id": "AR"
  },
  {
    "zip": 72550,
    "lat": 35.70969,
    "lng": -91.78294,
    "state_id": "AR"
  },
  {
    "zip": 72553,
    "lat": 35.7003,
    "lng": -91.48099,
    "state_id": "AR"
  },
  {
    "zip": 72554,
    "lat": 36.44015,
    "lng": -91.5624,
    "state_id": "AR"
  },
  {
    "zip": 72555,
    "lat": 35.77484,
    "lng": -91.88225,
    "state_id": "AR"
  },
  {
    "zip": 72556,
    "lat": 36.02359,
    "lng": -91.95913,
    "state_id": "AR"
  },
  {
    "zip": 72560,
    "lat": 35.85171,
    "lng": -92.09184,
    "state_id": "AR"
  },
  {
    "zip": 72561,
    "lat": 35.95107,
    "lng": -91.80289,
    "state_id": "AR"
  },
  {
    "zip": 72562,
    "lat": 35.73264,
    "lng": -91.39823,
    "state_id": "AR"
  },
  {
    "zip": 72564,
    "lat": 35.59934,
    "lng": -91.45696,
    "state_id": "AR"
  },
  {
    "zip": 72565,
    "lat": 36.21624,
    "lng": -91.90981,
    "state_id": "AR"
  },
  {
    "zip": 72566,
    "lat": 36.21667,
    "lng": -92.07814,
    "state_id": "AR"
  },
  {
    "zip": 72567,
    "lat": 35.81461,
    "lng": -91.92601,
    "state_id": "AR"
  },
  {
    "zip": 72568,
    "lat": 35.56936,
    "lng": -91.62503,
    "state_id": "AR"
  },
  {
    "zip": 72569,
    "lat": 36.10293,
    "lng": -91.44522,
    "state_id": "AR"
  },
  {
    "zip": 72571,
    "lat": 35.6421,
    "lng": -91.53471,
    "state_id": "AR"
  },
  {
    "zip": 72572,
    "lat": 35.89914,
    "lng": -91.29099,
    "state_id": "AR"
  },
  {
    "zip": 72573,
    "lat": 36.07315,
    "lng": -91.7962,
    "state_id": "AR"
  },
  {
    "zip": 72576,
    "lat": 36.35157,
    "lng": -91.84667,
    "state_id": "AR"
  },
  {
    "zip": 72577,
    "lat": 36.02715,
    "lng": -91.71397,
    "state_id": "AR"
  },
  {
    "zip": 72578,
    "lat": 36.45315,
    "lng": -91.88636,
    "state_id": "AR"
  },
  {
    "zip": 72579,
    "lat": 35.83721,
    "lng": -91.44507,
    "state_id": "AR"
  },
  {
    "zip": 72581,
    "lat": 35.54504,
    "lng": -91.97485,
    "state_id": "AR"
  },
  {
    "zip": 72583,
    "lat": 36.40244,
    "lng": -91.9915,
    "state_id": "AR"
  },
  {
    "zip": 72584,
    "lat": 36.14103,
    "lng": -91.84371,
    "state_id": "AR"
  },
  {
    "zip": 72585,
    "lat": 36.18321,
    "lng": -92.00814,
    "state_id": "AR"
  },
  {
    "zip": 72587,
    "lat": 36.22564,
    "lng": -91.81683,
    "state_id": "AR"
  },
  {
    "zip": 72601,
    "lat": 36.23724,
    "lng": -93.09177,
    "state_id": "AR"
  },
  {
    "zip": 72611,
    "lat": 36.25035,
    "lng": -93.33028,
    "state_id": "AR"
  },
  {
    "zip": 72616,
    "lat": 36.34291,
    "lng": -93.5564,
    "state_id": "AR"
  },
  {
    "zip": 72617,
    "lat": 36.02202,
    "lng": -92.36464,
    "state_id": "AR"
  },
  {
    "zip": 72619,
    "lat": 36.37882,
    "lng": -92.58506,
    "state_id": "AR"
  },
  {
    "zip": 72623,
    "lat": 36.46327,
    "lng": -92.31662,
    "state_id": "AR"
  },
  {
    "zip": 72624,
    "lat": 36.06168,
    "lng": -93.37471,
    "state_id": "AR"
  },
  {
    "zip": 72626,
    "lat": 36.3038,
    "lng": -92.53916,
    "state_id": "AR"
  },
  {
    "zip": 72628,
    "lat": 35.8346,
    "lng": -93.28745,
    "state_id": "AR"
  },
  {
    "zip": 72629,
    "lat": 35.7393,
    "lng": -92.5584,
    "state_id": "AR"
  },
  {
    "zip": 72630,
    "lat": 36.47425,
    "lng": -92.91034,
    "state_id": "AR"
  },
  {
    "zip": 72631,
    "lat": 36.45378,
    "lng": -93.79107,
    "state_id": "AR"
  },
  {
    "zip": 72632,
    "lat": 36.37356,
    "lng": -93.74488,
    "state_id": "AR"
  },
  {
    "zip": 72633,
    "lat": 36.13546,
    "lng": -92.87198,
    "state_id": "AR"
  },
  {
    "zip": 72634,
    "lat": 36.20349,
    "lng": -92.52832,
    "state_id": "AR"
  },
  {
    "zip": 72635,
    "lat": 36.31793,
    "lng": -92.48775,
    "state_id": "AR"
  },
  {
    "zip": 72636,
    "lat": 35.99012,
    "lng": -92.71595,
    "state_id": "AR"
  },
  {
    "zip": 72638,
    "lat": 36.31994,
    "lng": -93.40742,
    "state_id": "AR"
  },
  {
    "zip": 72639,
    "lat": 36.01278,
    "lng": -92.48822,
    "state_id": "AR"
  },
  {
    "zip": 72640,
    "lat": 36.01107,
    "lng": -93.05316,
    "state_id": "AR"
  },
  {
    "zip": 72641,
    "lat": 35.98243,
    "lng": -93.23048,
    "state_id": "AR"
  },
  {
    "zip": 72642,
    "lat": 36.37091,
    "lng": -92.54407,
    "state_id": "AR"
  },
  {
    "zip": 72644,
    "lat": 36.42477,
    "lng": -92.97076,
    "state_id": "AR"
  },
  {
    "zip": 72645,
    "lat": 35.80601,
    "lng": -92.56272,
    "state_id": "AR"
  },
  {
    "zip": 72648,
    "lat": 36.08044,
    "lng": -93.15598,
    "state_id": "AR"
  },
  {
    "zip": 72650,
    "lat": 35.88004,
    "lng": -92.70619,
    "state_id": "AR"
  },
  {
    "zip": 72651,
    "lat": 36.38675,
    "lng": -92.48148,
    "state_id": "AR"
  },
  {
    "zip": 72653,
    "lat": 36.34238,
    "lng": -92.38619,
    "state_id": "AR"
  },
  {
    "zip": 72655,
    "lat": 35.9011,
    "lng": -93.02145,
    "state_id": "AR"
  },
  {
    "zip": 72658,
    "lat": 36.16637,
    "lng": -92.32049,
    "state_id": "AR"
  },
  {
    "zip": 72660,
    "lat": 36.48072,
    "lng": -93.37504,
    "state_id": "AR"
  },
  {
    "zip": 72661,
    "lat": 36.44905,
    "lng": -92.58572,
    "state_id": "AR"
  },
  {
    "zip": 72662,
    "lat": 36.43188,
    "lng": -93.18086,
    "state_id": "AR"
  },
  {
    "zip": 72663,
    "lat": 35.93775,
    "lng": -92.32779,
    "state_id": "AR"
  },
  {
    "zip": 72666,
    "lat": 35.93893,
    "lng": -93.26859,
    "state_id": "AR"
  },
  {
    "zip": 72668,
    "lat": 36.42446,
    "lng": -92.77192,
    "state_id": "AR"
  },
  {
    "zip": 72669,
    "lat": 36.06472,
    "lng": -92.87615,
    "state_id": "AR"
  },
  {
    "zip": 72670,
    "lat": 35.97284,
    "lng": -93.38538,
    "state_id": "AR"
  },
  {
    "zip": 72672,
    "lat": 36.28133,
    "lng": -92.83781,
    "state_id": "AR"
  },
  {
    "zip": 72675,
    "lat": 36.00345,
    "lng": -92.78999,
    "state_id": "AR"
  },
  {
    "zip": 72677,
    "lat": 36.2514,
    "lng": -92.68716,
    "state_id": "AR"
  },
  {
    "zip": 72679,
    "lat": 35.73421,
    "lng": -92.82794,
    "state_id": "AR"
  },
  {
    "zip": 72680,
    "lat": 35.88639,
    "lng": -92.32701,
    "state_id": "AR"
  },
  {
    "zip": 72682,
    "lat": 36.14009,
    "lng": -92.73739,
    "state_id": "AR"
  },
  {
    "zip": 72683,
    "lat": 35.93464,
    "lng": -93.10076,
    "state_id": "AR"
  },
  {
    "zip": 72685,
    "lat": 36.05571,
    "lng": -92.97529,
    "state_id": "AR"
  },
  {
    "zip": 72686,
    "lat": 35.77804,
    "lng": -92.844,
    "state_id": "AR"
  },
  {
    "zip": 72687,
    "lat": 36.26761,
    "lng": -92.71362,
    "state_id": "AR"
  },
  {
    "zip": 72701,
    "lat": 35.98468,
    "lng": -94.08076,
    "state_id": "AR"
  },
  {
    "zip": 72703,
    "lat": 36.11505,
    "lng": -94.04893,
    "state_id": "AR"
  },
  {
    "zip": 72704,
    "lat": 36.10359,
    "lng": -94.29664,
    "state_id": "AR"
  },
  {
    "zip": 72712,
    "lat": 36.3504,
    "lng": -94.25425,
    "state_id": "AR"
  },
  {
    "zip": 72714,
    "lat": 36.46757,
    "lng": -94.21662,
    "state_id": "AR"
  },
  {
    "zip": 72715,
    "lat": 36.46538,
    "lng": -94.30641,
    "state_id": "AR"
  },
  {
    "zip": 72717,
    "lat": 35.84003,
    "lng": -94.42666,
    "state_id": "AR"
  },
  {
    "zip": 72718,
    "lat": 36.26962,
    "lng": -94.22274,
    "state_id": "AR"
  },
  {
    "zip": 72719,
    "lat": 36.36759,
    "lng": -94.2958,
    "state_id": "AR"
  },
  {
    "zip": 72721,
    "lat": 35.82736,
    "lng": -93.78939,
    "state_id": "AR"
  },
  {
    "zip": 72722,
    "lat": 36.34181,
    "lng": -94.46275,
    "state_id": "AR"
  },
  {
    "zip": 72727,
    "lat": 35.92287,
    "lng": -93.92865,
    "state_id": "AR"
  },
  {
    "zip": 72729,
    "lat": 35.78229,
    "lng": -94.46383,
    "state_id": "AR"
  },
  {
    "zip": 72730,
    "lat": 36.03056,
    "lng": -94.26597,
    "state_id": "AR"
  },
  {
    "zip": 72732,
    "lat": 36.43284,
    "lng": -93.95883,
    "state_id": "AR"
  },
  {
    "zip": 72734,
    "lat": 36.27286,
    "lng": -94.45337,
    "state_id": "AR"
  },
  {
    "zip": 72736,
    "lat": 36.41216,
    "lng": -94.46406,
    "state_id": "AR"
  },
  {
    "zip": 72738,
    "lat": 36.16056,
    "lng": -93.88661,
    "state_id": "AR"
  },
  {
    "zip": 72739,
    "lat": 36.42598,
    "lng": -94.33012,
    "state_id": "AR"
  },
  {
    "zip": 72740,
    "lat": 36.10253,
    "lng": -93.68698,
    "state_id": "AR"
  },
  {
    "zip": 72742,
    "lat": 35.9715,
    "lng": -93.4978,
    "state_id": "AR"
  },
  {
    "zip": 72744,
    "lat": 35.96356,
    "lng": -94.43802,
    "state_id": "AR"
  },
  {
    "zip": 72745,
    "lat": 36.24757,
    "lng": -94.10063,
    "state_id": "AR"
  },
  {
    "zip": 72747,
    "lat": 36.36856,
    "lng": -94.58277,
    "state_id": "AR"
  },
  {
    "zip": 72749,
    "lat": 35.86739,
    "lng": -94.43688,
    "state_id": "AR"
  },
  {
    "zip": 72751,
    "lat": 36.46105,
    "lng": -94.12033,
    "state_id": "AR"
  },
  {
    "zip": 72752,
    "lat": 35.80409,
    "lng": -93.57713,
    "state_id": "AR"
  },
  {
    "zip": 72753,
    "lat": 35.93132,
    "lng": -94.32681,
    "state_id": "AR"
  },
  {
    "zip": 72756,
    "lat": 36.32118,
    "lng": -93.98341,
    "state_id": "AR"
  },
  {
    "zip": 72758,
    "lat": 36.29972,
    "lng": -94.13837,
    "state_id": "AR"
  },
  {
    "zip": 72760,
    "lat": 35.83087,
    "lng": -93.70994,
    "state_id": "AR"
  },
  {
    "zip": 72761,
    "lat": 36.17017,
    "lng": -94.45816,
    "state_id": "AR"
  },
  {
    "zip": 72762,
    "lat": 36.18777,
    "lng": -94.22923,
    "state_id": "AR"
  },
  {
    "zip": 72764,
    "lat": 36.17837,
    "lng": -94.05257,
    "state_id": "AR"
  },
  {
    "zip": 72768,
    "lat": 36.47694,
    "lng": -94.50185,
    "state_id": "AR"
  },
  {
    "zip": 72769,
    "lat": 36.03345,
    "lng": -94.49866,
    "state_id": "AR"
  },
  {
    "zip": 72773,
    "lat": 36.0165,
    "lng": -93.84895,
    "state_id": "AR"
  },
  {
    "zip": 72774,
    "lat": 35.87852,
    "lng": -94.21518,
    "state_id": "AR"
  },
  {
    "zip": 72776,
    "lat": 35.92133,
    "lng": -93.64586,
    "state_id": "AR"
  },
  {
    "zip": 72801,
    "lat": 35.2791,
    "lng": -93.14081,
    "state_id": "AR"
  },
  {
    "zip": 72802,
    "lat": 35.31282,
    "lng": -93.06679,
    "state_id": "AR"
  },
  {
    "zip": 72821,
    "lat": 35.44217,
    "lng": -93.72352,
    "state_id": "AR"
  },
  {
    "zip": 72823,
    "lat": 35.25144,
    "lng": -92.90652,
    "state_id": "AR"
  },
  {
    "zip": 72824,
    "lat": 35.13522,
    "lng": -93.41369,
    "state_id": "AR"
  },
  {
    "zip": 72826,
    "lat": 35.12286,
    "lng": -93.70927,
    "state_id": "AR"
  },
  {
    "zip": 72827,
    "lat": 34.87665,
    "lng": -93.61671,
    "state_id": "AR"
  },
  {
    "zip": 72828,
    "lat": 34.92863,
    "lng": -93.54754,
    "state_id": "AR"
  },
  {
    "zip": 72830,
    "lat": 35.51387,
    "lng": -93.51169,
    "state_id": "AR"
  },
  {
    "zip": 72832,
    "lat": 35.41275,
    "lng": -93.66679,
    "state_id": "AR"
  },
  {
    "zip": 72833,
    "lat": 35.02219,
    "lng": -93.47503,
    "state_id": "AR"
  },
  {
    "zip": 72834,
    "lat": 35.16883,
    "lng": -93.18246,
    "state_id": "AR"
  },
  {
    "zip": 72835,
    "lat": 35.2779,
    "lng": -93.3482,
    "state_id": "AR"
  },
  {
    "zip": 72837,
    "lat": 35.50538,
    "lng": -93.10988,
    "state_id": "AR"
  },
  {
    "zip": 72838,
    "lat": 34.90528,
    "lng": -93.67653,
    "state_id": "AR"
  },
  {
    "zip": 72839,
    "lat": 35.65409,
    "lng": -93.2641,
    "state_id": "AR"
  },
  {
    "zip": 72840,
    "lat": 35.46177,
    "lng": -93.61585,
    "state_id": "AR"
  },
  {
    "zip": 72841,
    "lat": 34.84201,
    "lng": -93.77213,
    "state_id": "AR"
  },
  {
    "zip": 72842,
    "lat": 35.08479,
    "lng": -93.60295,
    "state_id": "AR"
  },
  {
    "zip": 72843,
    "lat": 35.57628,
    "lng": -92.93457,
    "state_id": "AR"
  },
  {
    "zip": 72845,
    "lat": 35.36412,
    "lng": -93.37526,
    "state_id": "AR"
  },
  {
    "zip": 72846,
    "lat": 35.48454,
    "lng": -93.32588,
    "state_id": "AR"
  },
  {
    "zip": 72847,
    "lat": 35.37376,
    "lng": -93.27198,
    "state_id": "AR"
  },
  {
    "zip": 72851,
    "lat": 35.25623,
    "lng": -93.46203,
    "state_id": "AR"
  },
  {
    "zip": 72852,
    "lat": 35.68371,
    "lng": -93.5537,
    "state_id": "AR"
  },
  {
    "zip": 72853,
    "lat": 35.01934,
    "lng": -93.21305,
    "state_id": "AR"
  },
  {
    "zip": 72854,
    "lat": 35.70634,
    "lng": -93.4243,
    "state_id": "AR"
  },
  {
    "zip": 72855,
    "lat": 35.25293,
    "lng": -93.67872,
    "state_id": "AR"
  },
  {
    "zip": 72856,
    "lat": 35.77718,
    "lng": -93.07493,
    "state_id": "AR"
  },
  {
    "zip": 72857,
    "lat": 34.85777,
    "lng": -93.31513,
    "state_id": "AR"
  },
  {
    "zip": 72858,
    "lat": 35.23088,
    "lng": -93.0423,
    "state_id": "AR"
  },
  {
    "zip": 72860,
    "lat": 34.95278,
    "lng": -93.42841,
    "state_id": "AR"
  },
  {
    "zip": 72863,
    "lat": 35.36513,
    "lng": -93.50894,
    "state_id": "AR"
  },
  {
    "zip": 72865,
    "lat": 35.3097,
    "lng": -93.5663,
    "state_id": "AR"
  },
  {
    "zip": 72901,
    "lat": 35.3664,
    "lng": -94.41576,
    "state_id": "AR"
  },
  {
    "zip": 72903,
    "lat": 35.35107,
    "lng": -94.3607,
    "state_id": "AR"
  },
  {
    "zip": 72904,
    "lat": 35.41336,
    "lng": -94.3828,
    "state_id": "AR"
  },
  {
    "zip": 72908,
    "lat": 35.30649,
    "lng": -94.40909,
    "state_id": "AR"
  },
  {
    "zip": 72916,
    "lat": 35.26786,
    "lng": -94.3766,
    "state_id": "AR"
  },
  {
    "zip": 72921,
    "lat": 35.47554,
    "lng": -94.19436,
    "state_id": "AR"
  },
  {
    "zip": 72923,
    "lat": 35.31597,
    "lng": -94.3079,
    "state_id": "AR"
  },
  {
    "zip": 72926,
    "lat": 34.7328,
    "lng": -94.04704,
    "state_id": "AR"
  },
  {
    "zip": 72927,
    "lat": 35.07485,
    "lng": -93.93653,
    "state_id": "AR"
  },
  {
    "zip": 72928,
    "lat": 35.28958,
    "lng": -93.93365,
    "state_id": "AR"
  },
  {
    "zip": 72930,
    "lat": 35.43318,
    "lng": -93.9725,
    "state_id": "AR"
  },
  {
    "zip": 72932,
    "lat": 35.60416,
    "lng": -94.37726,
    "state_id": "AR"
  },
  {
    "zip": 72933,
    "lat": 35.33011,
    "lng": -94.02178,
    "state_id": "AR"
  },
  {
    "zip": 72934,
    "lat": 35.68854,
    "lng": -94.27204,
    "state_id": "AR"
  },
  {
    "zip": 72935,
    "lat": 35.49135,
    "lng": -94.1264,
    "state_id": "AR"
  },
  {
    "zip": 72936,
    "lat": 35.18957,
    "lng": -94.22563,
    "state_id": "AR"
  },
  {
    "zip": 72937,
    "lat": 35.13269,
    "lng": -94.39671,
    "state_id": "AR"
  },
  {
    "zip": 72938,
    "lat": 35.00592,
    "lng": -94.37585,
    "state_id": "AR"
  },
  {
    "zip": 72940,
    "lat": 35.11501,
    "lng": -94.26013,
    "state_id": "AR"
  },
  {
    "zip": 72941,
    "lat": 35.36537,
    "lng": -94.15397,
    "state_id": "AR"
  },
  {
    "zip": 72943,
    "lat": 35.17947,
    "lng": -93.78658,
    "state_id": "AR"
  },
  {
    "zip": 72944,
    "lat": 35.02865,
    "lng": -94.23611,
    "state_id": "AR"
  },
  {
    "zip": 72945,
    "lat": 35.08832,
    "lng": -94.34901,
    "state_id": "AR"
  },
  {
    "zip": 72946,
    "lat": 35.6663,
    "lng": -94.14176,
    "state_id": "AR"
  },
  {
    "zip": 72947,
    "lat": 35.57205,
    "lng": -94.02797,
    "state_id": "AR"
  },
  {
    "zip": 72948,
    "lat": 35.7047,
    "lng": -94.41304,
    "state_id": "AR"
  },
  {
    "zip": 72949,
    "lat": 35.57712,
    "lng": -93.80774,
    "state_id": "AR"
  },
  {
    "zip": 72950,
    "lat": 34.78363,
    "lng": -93.88751,
    "state_id": "AR"
  },
  {
    "zip": 72951,
    "lat": 35.32075,
    "lng": -93.87512,
    "state_id": "AR"
  },
  {
    "zip": 72952,
    "lat": 35.56879,
    "lng": -94.30041,
    "state_id": "AR"
  },
  {
    "zip": 72955,
    "lat": 35.61223,
    "lng": -94.44388,
    "state_id": "AR"
  },
  {
    "zip": 72956,
    "lat": 35.46801,
    "lng": -94.34945,
    "state_id": "AR"
  },
  {
    "zip": 72958,
    "lat": 34.90487,
    "lng": -94.12456,
    "state_id": "AR"
  },
  {
    "zip": 72959,
    "lat": 35.78275,
    "lng": -94.06806,
    "state_id": "AR"
  },
  {
    "zip": 96799,
    "lat": -14.21967,
    "lng": -170.3693,
    "state_id": "AS"
  },
  {
    "zip": 85003,
    "lat": 33.45076,
    "lng": -112.07836,
    "state_id": "AZ"
  },
  {
    "zip": 85004,
    "lat": 33.45155,
    "lng": -112.06986,
    "state_id": "AZ"
  },
  {
    "zip": 85006,
    "lat": 33.46513,
    "lng": -112.04788,
    "state_id": "AZ"
  },
  {
    "zip": 85007,
    "lat": 33.44713,
    "lng": -112.0912,
    "state_id": "AZ"
  },
  {
    "zip": 85008,
    "lat": 33.46331,
    "lng": -111.98676,
    "state_id": "AZ"
  },
  {
    "zip": 85009,
    "lat": 33.44281,
    "lng": -112.12823,
    "state_id": "AZ"
  },
  {
    "zip": 85012,
    "lat": 33.50731,
    "lng": -112.07035,
    "state_id": "AZ"
  },
  {
    "zip": 85013,
    "lat": 33.51007,
    "lng": -112.08287,
    "state_id": "AZ"
  },
  {
    "zip": 85014,
    "lat": 33.5085,
    "lng": -112.05674,
    "state_id": "AZ"
  },
  {
    "zip": 85015,
    "lat": 33.50918,
    "lng": -112.10179,
    "state_id": "AZ"
  },
  {
    "zip": 85016,
    "lat": 33.51359,
    "lng": -112.02967,
    "state_id": "AZ"
  },
  {
    "zip": 85017,
    "lat": 33.50909,
    "lng": -112.1237,
    "state_id": "AZ"
  },
  {
    "zip": 85018,
    "lat": 33.50537,
    "lng": -111.98579,
    "state_id": "AZ"
  },
  {
    "zip": 85019,
    "lat": 33.50918,
    "lng": -112.14299,
    "state_id": "AZ"
  },
  {
    "zip": 85020,
    "lat": 33.56733,
    "lng": -112.05461,
    "state_id": "AZ"
  },
  {
    "zip": 85021,
    "lat": 33.56004,
    "lng": -112.09398,
    "state_id": "AZ"
  },
  {
    "zip": 85022,
    "lat": 33.62716,
    "lng": -112.05177,
    "state_id": "AZ"
  },
  {
    "zip": 85023,
    "lat": 33.63155,
    "lng": -112.09466,
    "state_id": "AZ"
  },
  {
    "zip": 85024,
    "lat": 33.73548,
    "lng": -112.03347,
    "state_id": "AZ"
  },
  {
    "zip": 85027,
    "lat": 33.6819,
    "lng": -112.0996,
    "state_id": "AZ"
  },
  {
    "zip": 85028,
    "lat": 33.57555,
    "lng": -112.00911,
    "state_id": "AZ"
  },
  {
    "zip": 85029,
    "lat": 33.59504,
    "lng": -112.11027,
    "state_id": "AZ"
  },
  {
    "zip": 85031,
    "lat": 33.49492,
    "lng": -112.16833,
    "state_id": "AZ"
  },
  {
    "zip": 85032,
    "lat": 33.6263,
    "lng": -112.00381,
    "state_id": "AZ"
  },
  {
    "zip": 85033,
    "lat": 33.49438,
    "lng": -112.21164,
    "state_id": "AZ"
  },
  {
    "zip": 85034,
    "lat": 33.43489,
    "lng": -112.02005,
    "state_id": "AZ"
  },
  {
    "zip": 85035,
    "lat": 33.47144,
    "lng": -112.19466,
    "state_id": "AZ"
  },
  {
    "zip": 85037,
    "lat": 33.49056,
    "lng": -112.26468,
    "state_id": "AZ"
  },
  {
    "zip": 85040,
    "lat": 33.40613,
    "lng": -112.02657,
    "state_id": "AZ"
  },
  {
    "zip": 85041,
    "lat": 33.38586,
    "lng": -112.10884,
    "state_id": "AZ"
  },
  {
    "zip": 85042,
    "lat": 33.36882,
    "lng": -112.04246,
    "state_id": "AZ"
  },
  {
    "zip": 85043,
    "lat": 33.43242,
    "lng": -112.19646,
    "state_id": "AZ"
  },
  {
    "zip": 85044,
    "lat": 33.34239,
    "lng": -112.00177,
    "state_id": "AZ"
  },
  {
    "zip": 85045,
    "lat": 33.29917,
    "lng": -112.10853,
    "state_id": "AZ"
  },
  {
    "zip": 85048,
    "lat": 33.3126,
    "lng": -112.05578,
    "state_id": "AZ"
  },
  {
    "zip": 85050,
    "lat": 33.68633,
    "lng": -111.99631,
    "state_id": "AZ"
  },
  {
    "zip": 85051,
    "lat": 33.55908,
    "lng": -112.1332,
    "state_id": "AZ"
  },
  {
    "zip": 85053,
    "lat": 33.62994,
    "lng": -112.1316,
    "state_id": "AZ"
  },
  {
    "zip": 85054,
    "lat": 33.67311,
    "lng": -111.94612,
    "state_id": "AZ"
  },
  {
    "zip": 85083,
    "lat": 33.72456,
    "lng": -112.15854,
    "state_id": "AZ"
  },
  {
    "zip": 85085,
    "lat": 33.75295,
    "lng": -112.08931,
    "state_id": "AZ"
  },
  {
    "zip": 85086,
    "lat": 33.81546,
    "lng": -112.12019,
    "state_id": "AZ"
  },
  {
    "zip": 85087,
    "lat": 33.92352,
    "lng": -112.12793,
    "state_id": "AZ"
  },
  {
    "zip": 85118,
    "lat": 33.3534,
    "lng": -111.35045,
    "state_id": "AZ"
  },
  {
    "zip": 85119,
    "lat": 33.40972,
    "lng": -111.50197,
    "state_id": "AZ"
  },
  {
    "zip": 85120,
    "lat": 33.40301,
    "lng": -111.56787,
    "state_id": "AZ"
  },
  {
    "zip": 85121,
    "lat": 33.14345,
    "lng": -111.92129,
    "state_id": "AZ"
  },
  {
    "zip": 85122,
    "lat": 32.91719,
    "lng": -111.74422,
    "state_id": "AZ"
  },
  {
    "zip": 85123,
    "lat": 32.71578,
    "lng": -111.69557,
    "state_id": "AZ"
  },
  {
    "zip": 85128,
    "lat": 32.99049,
    "lng": -111.53802,
    "state_id": "AZ"
  },
  {
    "zip": 85131,
    "lat": 32.6739,
    "lng": -111.55013,
    "state_id": "AZ"
  },
  {
    "zip": 85132,
    "lat": 32.93339,
    "lng": -111.20446,
    "state_id": "AZ"
  },
  {
    "zip": 85135,
    "lat": 33.00418,
    "lng": -110.786,
    "state_id": "AZ"
  },
  {
    "zip": 85137,
    "lat": 33.13733,
    "lng": -111.00194,
    "state_id": "AZ"
  },
  {
    "zip": 85138,
    "lat": 33.00685,
    "lng": -111.98908,
    "state_id": "AZ"
  },
  {
    "zip": 85139,
    "lat": 32.97591,
    "lng": -112.14611,
    "state_id": "AZ"
  },
  {
    "zip": 85140,
    "lat": 33.24451,
    "lng": -111.54379,
    "state_id": "AZ"
  },
  {
    "zip": 85141,
    "lat": 32.67333,
    "lng": -111.45436,
    "state_id": "AZ"
  },
  {
    "zip": 85142,
    "lat": 33.19866,
    "lng": -111.63814,
    "state_id": "AZ"
  },
  {
    "zip": 85143,
    "lat": 33.15864,
    "lng": -111.51902,
    "state_id": "AZ"
  },
  {
    "zip": 85145,
    "lat": 32.55751,
    "lng": -111.36787,
    "state_id": "AZ"
  },
  {
    "zip": 85147,
    "lat": 33.12587,
    "lng": -111.73723,
    "state_id": "AZ"
  },
  {
    "zip": 85172,
    "lat": 32.88608,
    "lng": -111.95379,
    "state_id": "AZ"
  },
  {
    "zip": 85173,
    "lat": 33.27017,
    "lng": -111.12336,
    "state_id": "AZ"
  },
  {
    "zip": 85192,
    "lat": 32.96052,
    "lng": -110.68867,
    "state_id": "AZ"
  },
  {
    "zip": 85193,
    "lat": 32.79406,
    "lng": -111.82023,
    "state_id": "AZ"
  },
  {
    "zip": 85194,
    "lat": 32.89835,
    "lng": -111.62605,
    "state_id": "AZ"
  },
  {
    "zip": 85201,
    "lat": 33.43387,
    "lng": -111.85023,
    "state_id": "AZ"
  },
  {
    "zip": 85202,
    "lat": 33.38249,
    "lng": -111.87447,
    "state_id": "AZ"
  },
  {
    "zip": 85203,
    "lat": 33.45005,
    "lng": -111.804,
    "state_id": "AZ"
  },
  {
    "zip": 85204,
    "lat": 33.39679,
    "lng": -111.7881,
    "state_id": "AZ"
  },
  {
    "zip": 85205,
    "lat": 33.43232,
    "lng": -111.7187,
    "state_id": "AZ"
  },
  {
    "zip": 85206,
    "lat": 33.39667,
    "lng": -111.71772,
    "state_id": "AZ"
  },
  {
    "zip": 85207,
    "lat": 33.4535,
    "lng": -111.63683,
    "state_id": "AZ"
  },
  {
    "zip": 85208,
    "lat": 33.40161,
    "lng": -111.62826,
    "state_id": "AZ"
  },
  {
    "zip": 85209,
    "lat": 33.37832,
    "lng": -111.63763,
    "state_id": "AZ"
  },
  {
    "zip": 85210,
    "lat": 33.38986,
    "lng": -111.84321,
    "state_id": "AZ"
  },
  {
    "zip": 85212,
    "lat": 33.32214,
    "lng": -111.63542,
    "state_id": "AZ"
  },
  {
    "zip": 85213,
    "lat": 33.44847,
    "lng": -111.77,
    "state_id": "AZ"
  },
  {
    "zip": 85215,
    "lat": 33.51106,
    "lng": -111.57882,
    "state_id": "AZ"
  },
  {
    "zip": 85224,
    "lat": 33.32365,
    "lng": -111.87643,
    "state_id": "AZ"
  },
  {
    "zip": 85225,
    "lat": 33.3174,
    "lng": -111.83218,
    "state_id": "AZ"
  },
  {
    "zip": 85226,
    "lat": 33.259,
    "lng": -112.00763,
    "state_id": "AZ"
  },
  {
    "zip": 85233,
    "lat": 33.35211,
    "lng": -111.81134,
    "state_id": "AZ"
  },
  {
    "zip": 85234,
    "lat": 33.36455,
    "lng": -111.73943,
    "state_id": "AZ"
  },
  {
    "zip": 85248,
    "lat": 33.21797,
    "lng": -111.86962,
    "state_id": "AZ"
  },
  {
    "zip": 85249,
    "lat": 33.22551,
    "lng": -111.79707,
    "state_id": "AZ"
  },
  {
    "zip": 85250,
    "lat": 33.53583,
    "lng": -111.88853,
    "state_id": "AZ"
  },
  {
    "zip": 85251,
    "lat": 33.49393,
    "lng": -111.91954,
    "state_id": "AZ"
  },
  {
    "zip": 85253,
    "lat": 33.54501,
    "lng": -111.95854,
    "state_id": "AZ"
  },
  {
    "zip": 85254,
    "lat": 33.61504,
    "lng": -111.95198,
    "state_id": "AZ"
  },
  {
    "zip": 85255,
    "lat": 33.68316,
    "lng": -111.82109,
    "state_id": "AZ"
  },
  {
    "zip": 85256,
    "lat": 33.50196,
    "lng": -111.84066,
    "state_id": "AZ"
  },
  {
    "zip": 85257,
    "lat": 33.46417,
    "lng": -111.91592,
    "state_id": "AZ"
  },
  {
    "zip": 85258,
    "lat": 33.56466,
    "lng": -111.89566,
    "state_id": "AZ"
  },
  {
    "zip": 85259,
    "lat": 33.60112,
    "lng": -111.80949,
    "state_id": "AZ"
  },
  {
    "zip": 85260,
    "lat": 33.60959,
    "lng": -111.89159,
    "state_id": "AZ"
  },
  {
    "zip": 85262,
    "lat": 33.83185,
    "lng": -111.81058,
    "state_id": "AZ"
  },
  {
    "zip": 85263,
    "lat": 33.82693,
    "lng": -111.51454,
    "state_id": "AZ"
  },
  {
    "zip": 85264,
    "lat": 33.63083,
    "lng": -111.5326,
    "state_id": "AZ"
  },
  {
    "zip": 85266,
    "lat": 33.76695,
    "lng": -111.91825,
    "state_id": "AZ"
  },
  {
    "zip": 85268,
    "lat": 33.60699,
    "lng": -111.74005,
    "state_id": "AZ"
  },
  {
    "zip": 85281,
    "lat": 33.42815,
    "lng": -111.93115,
    "state_id": "AZ"
  },
  {
    "zip": 85282,
    "lat": 33.39414,
    "lng": -111.93184,
    "state_id": "AZ"
  },
  {
    "zip": 85283,
    "lat": 33.36461,
    "lng": -111.93212,
    "state_id": "AZ"
  },
  {
    "zip": 85284,
    "lat": 33.33717,
    "lng": -111.93424,
    "state_id": "AZ"
  },
  {
    "zip": 85286,
    "lat": 33.27106,
    "lng": -111.83122,
    "state_id": "AZ"
  },
  {
    "zip": 85295,
    "lat": 33.30544,
    "lng": -111.74085,
    "state_id": "AZ"
  },
  {
    "zip": 85296,
    "lat": 33.33539,
    "lng": -111.74059,
    "state_id": "AZ"
  },
  {
    "zip": 85297,
    "lat": 33.2778,
    "lng": -111.7335,
    "state_id": "AZ"
  },
  {
    "zip": 85298,
    "lat": 33.24188,
    "lng": -111.72573,
    "state_id": "AZ"
  },
  {
    "zip": 85301,
    "lat": 33.53177,
    "lng": -112.17799,
    "state_id": "AZ"
  },
  {
    "zip": 85302,
    "lat": 33.56796,
    "lng": -112.17795,
    "state_id": "AZ"
  },
  {
    "zip": 85303,
    "lat": 33.5327,
    "lng": -112.22112,
    "state_id": "AZ"
  },
  {
    "zip": 85304,
    "lat": 33.59646,
    "lng": -112.17698,
    "state_id": "AZ"
  },
  {
    "zip": 85305,
    "lat": 33.53079,
    "lng": -112.25636,
    "state_id": "AZ"
  },
  {
    "zip": 85306,
    "lat": 33.62394,
    "lng": -112.17639,
    "state_id": "AZ"
  },
  {
    "zip": 85307,
    "lat": 33.53756,
    "lng": -112.31365,
    "state_id": "AZ"
  },
  {
    "zip": 85308,
    "lat": 33.66051,
    "lng": -112.18462,
    "state_id": "AZ"
  },
  {
    "zip": 85309,
    "lat": 33.53551,
    "lng": -112.37668,
    "state_id": "AZ"
  },
  {
    "zip": 85310,
    "lat": 33.69578,
    "lng": -112.1719,
    "state_id": "AZ"
  },
  {
    "zip": 85320,
    "lat": 33.91846,
    "lng": -113.21363,
    "state_id": "AZ"
  },
  {
    "zip": 85321,
    "lat": 32.22945,
    "lng": -112.65431,
    "state_id": "AZ"
  },
  {
    "zip": 85322,
    "lat": 33.1741,
    "lng": -112.85102,
    "state_id": "AZ"
  },
  {
    "zip": 85323,
    "lat": 33.41939,
    "lng": -112.32606,
    "state_id": "AZ"
  },
  {
    "zip": 85324,
    "lat": 34.11916,
    "lng": -112.04426,
    "state_id": "AZ"
  },
  {
    "zip": 85325,
    "lat": 33.99884,
    "lng": -113.90161,
    "state_id": "AZ"
  },
  {
    "zip": 85326,
    "lat": 33.28904,
    "lng": -112.57796,
    "state_id": "AZ"
  },
  {
    "zip": 85328,
    "lat": 33.22631,
    "lng": -114.63446,
    "state_id": "AZ"
  },
  {
    "zip": 85331,
    "lat": 33.88506,
    "lng": -111.93494,
    "state_id": "AZ"
  },
  {
    "zip": 85332,
    "lat": 34.19082,
    "lng": -112.90464,
    "state_id": "AZ"
  },
  {
    "zip": 85333,
    "lat": 33.02343,
    "lng": -113.28105,
    "state_id": "AZ"
  },
  {
    "zip": 85334,
    "lat": 33.52216,
    "lng": -114.53383,
    "state_id": "AZ"
  },
  {
    "zip": 85335,
    "lat": 33.59191,
    "lng": -112.32664,
    "state_id": "AZ"
  },
  {
    "zip": 85336,
    "lat": 32.54533,
    "lng": -114.78951,
    "state_id": "AZ"
  },
  {
    "zip": 85337,
    "lat": 32.95961,
    "lng": -112.7033,
    "state_id": "AZ"
  },
  {
    "zip": 85338,
    "lat": 33.3735,
    "lng": -112.40794,
    "state_id": "AZ"
  },
  {
    "zip": 85339,
    "lat": 33.23775,
    "lng": -112.16077,
    "state_id": "AZ"
  },
  {
    "zip": 85340,
    "lat": 33.50984,
    "lng": -112.41317,
    "state_id": "AZ"
  },
  {
    "zip": 85341,
    "lat": 31.87377,
    "lng": -112.78476,
    "state_id": "AZ"
  },
  {
    "zip": 85342,
    "lat": 33.94479,
    "lng": -112.45305,
    "state_id": "AZ"
  },
  {
    "zip": 85343,
    "lat": 33.33909,
    "lng": -112.6891,
    "state_id": "AZ"
  },
  {
    "zip": 85344,
    "lat": 34.07552,
    "lng": -114.22197,
    "state_id": "AZ"
  },
  {
    "zip": 85345,
    "lat": 33.57196,
    "lng": -112.24617,
    "state_id": "AZ"
  },
  {
    "zip": 85346,
    "lat": 33.63625,
    "lng": -114.14619,
    "state_id": "AZ"
  },
  {
    "zip": 85347,
    "lat": 32.79743,
    "lng": -113.78962,
    "state_id": "AZ"
  },
  {
    "zip": 85348,
    "lat": 33.70873,
    "lng": -113.71442,
    "state_id": "AZ"
  },
  {
    "zip": 85349,
    "lat": 32.49738,
    "lng": -114.7524,
    "state_id": "AZ"
  },
  {
    "zip": 85350,
    "lat": 32.55352,
    "lng": -114.6974,
    "state_id": "AZ"
  },
  {
    "zip": 85351,
    "lat": 33.60645,
    "lng": -112.2821,
    "state_id": "AZ"
  },
  {
    "zip": 85352,
    "lat": 32.71939,
    "lng": -113.83951,
    "state_id": "AZ"
  },
  {
    "zip": 85353,
    "lat": 33.41824,
    "lng": -112.27288,
    "state_id": "AZ"
  },
  {
    "zip": 85354,
    "lat": 33.43316,
    "lng": -113.04897,
    "state_id": "AZ"
  },
  {
    "zip": 85355,
    "lat": 33.57623,
    "lng": -112.45385,
    "state_id": "AZ"
  },
  {
    "zip": 85356,
    "lat": 32.68901,
    "lng": -114.16228,
    "state_id": "AZ"
  },
  {
    "zip": 85357,
    "lat": 33.97768,
    "lng": -113.50324,
    "state_id": "AZ"
  },
  {
    "zip": 85360,
    "lat": 34.70927,
    "lng": -113.56826,
    "state_id": "AZ"
  },
  {
    "zip": 85361,
    "lat": 33.76398,
    "lng": -112.61468,
    "state_id": "AZ"
  },
  {
    "zip": 85362,
    "lat": 34.27995,
    "lng": -112.83475,
    "state_id": "AZ"
  },
  {
    "zip": 85363,
    "lat": 33.58446,
    "lng": -112.30505,
    "state_id": "AZ"
  },
  {
    "zip": 85364,
    "lat": 32.70312,
    "lng": -114.65882,
    "state_id": "AZ"
  },
  {
    "zip": 85365,
    "lat": 33.07894,
    "lng": -114.15047,
    "state_id": "AZ"
  },
  {
    "zip": 85367,
    "lat": 32.67173,
    "lng": -114.39553,
    "state_id": "AZ"
  },
  {
    "zip": 85371,
    "lat": 33.82863,
    "lng": -114.37582,
    "state_id": "AZ"
  },
  {
    "zip": 85373,
    "lat": 33.67381,
    "lng": -112.29959,
    "state_id": "AZ"
  },
  {
    "zip": 85374,
    "lat": 33.64402,
    "lng": -112.37672,
    "state_id": "AZ"
  },
  {
    "zip": 85375,
    "lat": 33.68563,
    "lng": -112.36604,
    "state_id": "AZ"
  },
  {
    "zip": 85377,
    "lat": 33.82433,
    "lng": -111.91526,
    "state_id": "AZ"
  },
  {
    "zip": 85379,
    "lat": 33.6021,
    "lng": -112.37355,
    "state_id": "AZ"
  },
  {
    "zip": 85381,
    "lat": 33.60956,
    "lng": -112.23168,
    "state_id": "AZ"
  },
  {
    "zip": 85382,
    "lat": 33.65509,
    "lng": -112.24747,
    "state_id": "AZ"
  },
  {
    "zip": 85383,
    "lat": 33.80236,
    "lng": -112.247,
    "state_id": "AZ"
  },
  {
    "zip": 85387,
    "lat": 33.7128,
    "lng": -112.43806,
    "state_id": "AZ"
  },
  {
    "zip": 85388,
    "lat": 33.6079,
    "lng": -112.43383,
    "state_id": "AZ"
  },
  {
    "zip": 85390,
    "lat": 33.89445,
    "lng": -112.86018,
    "state_id": "AZ"
  },
  {
    "zip": 85392,
    "lat": 33.4778,
    "lng": -112.30938,
    "state_id": "AZ"
  },
  {
    "zip": 85395,
    "lat": 33.47907,
    "lng": -112.39452,
    "state_id": "AZ"
  },
  {
    "zip": 85396,
    "lat": 33.52694,
    "lng": -112.65073,
    "state_id": "AZ"
  },
  {
    "zip": 85501,
    "lat": 33.5391,
    "lng": -110.75344,
    "state_id": "AZ"
  },
  {
    "zip": 85530,
    "lat": 33.1062,
    "lng": -110.23732,
    "state_id": "AZ"
  },
  {
    "zip": 85531,
    "lat": 32.87715,
    "lng": -109.78936,
    "state_id": "AZ"
  },
  {
    "zip": 85533,
    "lat": 33.32098,
    "lng": -109.38563,
    "state_id": "AZ"
  },
  {
    "zip": 85534,
    "lat": 32.75427,
    "lng": -109.14059,
    "state_id": "AZ"
  },
  {
    "zip": 85535,
    "lat": 32.98992,
    "lng": -109.91384,
    "state_id": "AZ"
  },
  {
    "zip": 85536,
    "lat": 33.13902,
    "lng": -110.00261,
    "state_id": "AZ"
  },
  {
    "zip": 85539,
    "lat": 33.36098,
    "lng": -110.913,
    "state_id": "AZ"
  },
  {
    "zip": 85540,
    "lat": 32.97739,
    "lng": -109.35748,
    "state_id": "AZ"
  },
  {
    "zip": 85541,
    "lat": 34.19483,
    "lng": -111.3052,
    "state_id": "AZ"
  },
  {
    "zip": 85542,
    "lat": 33.29655,
    "lng": -110.30316,
    "state_id": "AZ"
  },
  {
    "zip": 85543,
    "lat": 32.98253,
    "lng": -109.96759,
    "state_id": "AZ"
  },
  {
    "zip": 85544,
    "lat": 34.34353,
    "lng": -111.53529,
    "state_id": "AZ"
  },
  {
    "zip": 85545,
    "lat": 33.54555,
    "lng": -111.12486,
    "state_id": "AZ"
  },
  {
    "zip": 85546,
    "lat": 32.7113,
    "lng": -109.58411,
    "state_id": "AZ"
  },
  {
    "zip": 85550,
    "lat": 33.5311,
    "lng": -110.48326,
    "state_id": "AZ"
  },
  {
    "zip": 85551,
    "lat": 32.72087,
    "lng": -109.58365,
    "state_id": "AZ"
  },
  {
    "zip": 85552,
    "lat": 32.82182,
    "lng": -109.76941,
    "state_id": "AZ"
  },
  {
    "zip": 85553,
    "lat": 33.81552,
    "lng": -111.22885,
    "state_id": "AZ"
  },
  {
    "zip": 85554,
    "lat": 33.98597,
    "lng": -110.95425,
    "state_id": "AZ"
  },
  {
    "zip": 85601,
    "lat": 31.5884,
    "lng": -111.31589,
    "state_id": "AZ"
  },
  {
    "zip": 85602,
    "lat": 32.17427,
    "lng": -110.37703,
    "state_id": "AZ"
  },
  {
    "zip": 85603,
    "lat": 31.43388,
    "lng": -109.91053,
    "state_id": "AZ"
  },
  {
    "zip": 85605,
    "lat": 32.29938,
    "lng": -109.47785,
    "state_id": "AZ"
  },
  {
    "zip": 85606,
    "lat": 32.03953,
    "lng": -109.91029,
    "state_id": "AZ"
  },
  {
    "zip": 85607,
    "lat": 31.5212,
    "lng": -109.35165,
    "state_id": "AZ"
  },
  {
    "zip": 85608,
    "lat": 31.45586,
    "lng": -109.58611,
    "state_id": "AZ"
  },
  {
    "zip": 85609,
    "lat": 32.06586,
    "lng": -110.10558,
    "state_id": "AZ"
  },
  {
    "zip": 85610,
    "lat": 31.74107,
    "lng": -109.71769,
    "state_id": "AZ"
  },
  {
    "zip": 85611,
    "lat": 31.66145,
    "lng": -110.51209,
    "state_id": "AZ"
  },
  {
    "zip": 85613,
    "lat": 31.56689,
    "lng": -110.38512,
    "state_id": "AZ"
  },
  {
    "zip": 85614,
    "lat": 31.82284,
    "lng": -110.92578,
    "state_id": "AZ"
  },
  {
    "zip": 85615,
    "lat": 31.40188,
    "lng": -110.21974,
    "state_id": "AZ"
  },
  {
    "zip": 85616,
    "lat": 31.69327,
    "lng": -110.3331,
    "state_id": "AZ"
  },
  {
    "zip": 85617,
    "lat": 31.54834,
    "lng": -109.72294,
    "state_id": "AZ"
  },
  {
    "zip": 85618,
    "lat": 32.71316,
    "lng": -110.55581,
    "state_id": "AZ"
  },
  {
    "zip": 85619,
    "lat": 32.4076,
    "lng": -110.7332,
    "state_id": "AZ"
  },
  {
    "zip": 85620,
    "lat": 31.33718,
    "lng": -109.94117,
    "state_id": "AZ"
  },
  {
    "zip": 85621,
    "lat": 31.42285,
    "lng": -111.04007,
    "state_id": "AZ"
  },
  {
    "zip": 85622,
    "lat": 31.82686,
    "lng": -111.07552,
    "state_id": "AZ"
  },
  {
    "zip": 85623,
    "lat": 32.70641,
    "lng": -110.8053,
    "state_id": "AZ"
  },
  {
    "zip": 85624,
    "lat": 31.51531,
    "lng": -110.69853,
    "state_id": "AZ"
  },
  {
    "zip": 85625,
    "lat": 31.8885,
    "lng": -109.61185,
    "state_id": "AZ"
  },
  {
    "zip": 85626,
    "lat": 31.35779,
    "lng": -109.56665,
    "state_id": "AZ"
  },
  {
    "zip": 85627,
    "lat": 32.00499,
    "lng": -110.24118,
    "state_id": "AZ"
  },
  {
    "zip": 85629,
    "lat": 31.91783,
    "lng": -111.01908,
    "state_id": "AZ"
  },
  {
    "zip": 85630,
    "lat": 31.88882,
    "lng": -110.169,
    "state_id": "AZ"
  },
  {
    "zip": 85631,
    "lat": 32.59138,
    "lng": -110.55257,
    "state_id": "AZ"
  },
  {
    "zip": 85632,
    "lat": 32.0424,
    "lng": -109.17478,
    "state_id": "AZ"
  },
  {
    "zip": 85633,
    "lat": 31.62281,
    "lng": -111.49567,
    "state_id": "AZ"
  },
  {
    "zip": 85634,
    "lat": 32.03149,
    "lng": -112.00653,
    "state_id": "AZ"
  },
  {
    "zip": 85635,
    "lat": 31.57036,
    "lng": -110.17705,
    "state_id": "AZ"
  },
  {
    "zip": 85637,
    "lat": 31.72823,
    "lng": -110.68969,
    "state_id": "AZ"
  },
  {
    "zip": 85638,
    "lat": 31.71507,
    "lng": -110.0509,
    "state_id": "AZ"
  },
  {
    "zip": 85640,
    "lat": 31.5619,
    "lng": -111.04936,
    "state_id": "AZ"
  },
  {
    "zip": 85641,
    "lat": 32.04903,
    "lng": -110.6229,
    "state_id": "AZ"
  },
  {
    "zip": 85643,
    "lat": 32.40377,
    "lng": -109.96368,
    "state_id": "AZ"
  },
  {
    "zip": 85645,
    "lat": 31.70306,
    "lng": -111.15952,
    "state_id": "AZ"
  },
  {
    "zip": 85646,
    "lat": 31.60614,
    "lng": -110.99021,
    "state_id": "AZ"
  },
  {
    "zip": 85648,
    "lat": 31.48733,
    "lng": -111.04117,
    "state_id": "AZ"
  },
  {
    "zip": 85650,
    "lat": 31.50007,
    "lng": -110.20214,
    "state_id": "AZ"
  },
  {
    "zip": 85653,
    "lat": 32.38976,
    "lng": -111.377,
    "state_id": "AZ"
  },
  {
    "zip": 85654,
    "lat": 32.41526,
    "lng": -111.1544,
    "state_id": "AZ"
  },
  {
    "zip": 85658,
    "lat": 32.5492,
    "lng": -111.14839,
    "state_id": "AZ"
  },
  {
    "zip": 85701,
    "lat": 32.2169,
    "lng": -110.97095,
    "state_id": "AZ"
  },
  {
    "zip": 85704,
    "lat": 32.33788,
    "lng": -110.98553,
    "state_id": "AZ"
  },
  {
    "zip": 85705,
    "lat": 32.2713,
    "lng": -110.99214,
    "state_id": "AZ"
  },
  {
    "zip": 85706,
    "lat": 32.14763,
    "lng": -110.9334,
    "state_id": "AZ"
  },
  {
    "zip": 85707,
    "lat": 32.17921,
    "lng": -110.887,
    "state_id": "AZ"
  },
  {
    "zip": 85708,
    "lat": 32.18189,
    "lng": -110.8665,
    "state_id": "AZ"
  },
  {
    "zip": 85710,
    "lat": 32.21414,
    "lng": -110.82375,
    "state_id": "AZ"
  },
  {
    "zip": 85711,
    "lat": 32.21523,
    "lng": -110.8834,
    "state_id": "AZ"
  },
  {
    "zip": 85712,
    "lat": 32.25233,
    "lng": -110.88648,
    "state_id": "AZ"
  },
  {
    "zip": 85713,
    "lat": 32.19525,
    "lng": -111.01537,
    "state_id": "AZ"
  },
  {
    "zip": 85714,
    "lat": 32.1674,
    "lng": -110.95082,
    "state_id": "AZ"
  },
  {
    "zip": 85715,
    "lat": 32.24887,
    "lng": -110.83081,
    "state_id": "AZ"
  },
  {
    "zip": 85716,
    "lat": 32.24151,
    "lng": -110.92322,
    "state_id": "AZ"
  },
  {
    "zip": 85718,
    "lat": 32.33335,
    "lng": -110.91662,
    "state_id": "AZ"
  },
  {
    "zip": 85719,
    "lat": 32.24671,
    "lng": -110.9487,
    "state_id": "AZ"
  },
  {
    "zip": 85723,
    "lat": 32.18023,
    "lng": -110.96516,
    "state_id": "AZ"
  },
  {
    "zip": 85724,
    "lat": 32.24087,
    "lng": -110.94555,
    "state_id": "AZ"
  },
  {
    "zip": 85726,
    "lat": 32.20478,
    "lng": -110.94563,
    "state_id": "AZ"
  },
  {
    "zip": 85730,
    "lat": 32.18278,
    "lng": -110.74699,
    "state_id": "AZ"
  },
  {
    "zip": 85735,
    "lat": 32.09306,
    "lng": -111.34722,
    "state_id": "AZ"
  },
  {
    "zip": 85736,
    "lat": 31.90108,
    "lng": -111.37022,
    "state_id": "AZ"
  },
  {
    "zip": 85737,
    "lat": 32.41418,
    "lng": -110.94661,
    "state_id": "AZ"
  },
  {
    "zip": 85739,
    "lat": 32.61654,
    "lng": -110.9858,
    "state_id": "AZ"
  },
  {
    "zip": 85741,
    "lat": 32.33737,
    "lng": -111.03945,
    "state_id": "AZ"
  },
  {
    "zip": 85742,
    "lat": 32.39472,
    "lng": -111.06572,
    "state_id": "AZ"
  },
  {
    "zip": 85743,
    "lat": 32.3108,
    "lng": -111.18817,
    "state_id": "AZ"
  },
  {
    "zip": 85745,
    "lat": 32.25879,
    "lng": -111.08972,
    "state_id": "AZ"
  },
  {
    "zip": 85746,
    "lat": 32.09161,
    "lng": -111.04443,
    "state_id": "AZ"
  },
  {
    "zip": 85747,
    "lat": 32.09498,
    "lng": -110.76538,
    "state_id": "AZ"
  },
  {
    "zip": 85748,
    "lat": 32.21898,
    "lng": -110.75197,
    "state_id": "AZ"
  },
  {
    "zip": 85749,
    "lat": 32.28951,
    "lng": -110.73178,
    "state_id": "AZ"
  },
  {
    "zip": 85750,
    "lat": 32.30199,
    "lng": -110.84021,
    "state_id": "AZ"
  },
  {
    "zip": 85755,
    "lat": 32.46842,
    "lng": -110.98153,
    "state_id": "AZ"
  },
  {
    "zip": 85756,
    "lat": 32.07926,
    "lng": -110.89889,
    "state_id": "AZ"
  },
  {
    "zip": 85757,
    "lat": 32.1285,
    "lng": -111.12212,
    "state_id": "AZ"
  },
  {
    "zip": 85901,
    "lat": 34.29987,
    "lng": -109.99994,
    "state_id": "AZ"
  },
  {
    "zip": 85911,
    "lat": 34.1355,
    "lng": -110.36938,
    "state_id": "AZ"
  },
  {
    "zip": 85912,
    "lat": 34.42676,
    "lng": -109.92745,
    "state_id": "AZ"
  },
  {
    "zip": 85920,
    "lat": 33.8602,
    "lng": -109.17652,
    "state_id": "AZ"
  },
  {
    "zip": 85922,
    "lat": 33.50837,
    "lng": -109.13674,
    "state_id": "AZ"
  },
  {
    "zip": 85923,
    "lat": 34.32497,
    "lng": -110.34054,
    "state_id": "AZ"
  },
  {
    "zip": 85924,
    "lat": 34.57457,
    "lng": -109.66171,
    "state_id": "AZ"
  },
  {
    "zip": 85925,
    "lat": 34.088,
    "lng": -109.3232,
    "state_id": "AZ"
  },
  {
    "zip": 85926,
    "lat": 33.77421,
    "lng": -109.97933,
    "state_id": "AZ"
  },
  {
    "zip": 85927,
    "lat": 34.00517,
    "lng": -109.46645,
    "state_id": "AZ"
  },
  {
    "zip": 85928,
    "lat": 34.53343,
    "lng": -110.55814,
    "state_id": "AZ"
  },
  {
    "zip": 85929,
    "lat": 34.18582,
    "lng": -109.93655,
    "state_id": "AZ"
  },
  {
    "zip": 85930,
    "lat": 34.04314,
    "lng": -109.7226,
    "state_id": "AZ"
  },
  {
    "zip": 85931,
    "lat": 34.45127,
    "lng": -110.86441,
    "state_id": "AZ"
  },
  {
    "zip": 85932,
    "lat": 33.9525,
    "lng": -109.21465,
    "state_id": "AZ"
  },
  {
    "zip": 85933,
    "lat": 34.35622,
    "lng": -110.50677,
    "state_id": "AZ"
  },
  {
    "zip": 85934,
    "lat": 34.30029,
    "lng": -110.24571,
    "state_id": "AZ"
  },
  {
    "zip": 85935,
    "lat": 34.11527,
    "lng": -109.89579,
    "state_id": "AZ"
  },
  {
    "zip": 85936,
    "lat": 34.64541,
    "lng": -109.27648,
    "state_id": "AZ"
  },
  {
    "zip": 85937,
    "lat": 34.55936,
    "lng": -110.06067,
    "state_id": "AZ"
  },
  {
    "zip": 85938,
    "lat": 34.17017,
    "lng": -109.33153,
    "state_id": "AZ"
  },
  {
    "zip": 85939,
    "lat": 34.43896,
    "lng": -110.08576,
    "state_id": "AZ"
  },
  {
    "zip": 85940,
    "lat": 34.25247,
    "lng": -109.68806,
    "state_id": "AZ"
  },
  {
    "zip": 85941,
    "lat": 33.82048,
    "lng": -109.97094,
    "state_id": "AZ"
  },
  {
    "zip": 85942,
    "lat": 34.74311,
    "lng": -109.97073,
    "state_id": "AZ"
  },
  {
    "zip": 86001,
    "lat": 35.28948,
    "lng": -111.63773,
    "state_id": "AZ"
  },
  {
    "zip": 86003,
    "lat": 35.21976,
    "lng": -111.21412,
    "state_id": "AZ"
  },
  {
    "zip": 86004,
    "lat": 35.38763,
    "lng": -111.3848,
    "state_id": "AZ"
  },
  {
    "zip": 86011,
    "lat": 35.18005,
    "lng": -111.65511,
    "state_id": "AZ"
  },
  {
    "zip": 86015,
    "lat": 35.23244,
    "lng": -111.83768,
    "state_id": "AZ"
  },
  {
    "zip": 86016,
    "lat": 35.75045,
    "lng": -111.61879,
    "state_id": "AZ"
  },
  {
    "zip": 86017,
    "lat": 34.89815,
    "lng": -111.63621,
    "state_id": "AZ"
  },
  {
    "zip": 86018,
    "lat": 35.27148,
    "lng": -111.96429,
    "state_id": "AZ"
  },
  {
    "zip": 86020,
    "lat": 35.99215,
    "lng": -111.50372,
    "state_id": "AZ"
  },
  {
    "zip": 86021,
    "lat": 36.97182,
    "lng": -113.01701,
    "state_id": "AZ"
  },
  {
    "zip": 86022,
    "lat": 36.84139,
    "lng": -112.57617,
    "state_id": "AZ"
  },
  {
    "zip": 86023,
    "lat": 36.05726,
    "lng": -112.07676,
    "state_id": "AZ"
  },
  {
    "zip": 86024,
    "lat": 34.66152,
    "lng": -111.34045,
    "state_id": "AZ"
  },
  {
    "zip": 86025,
    "lat": 34.93784,
    "lng": -110.09962,
    "state_id": "AZ"
  },
  {
    "zip": 86028,
    "lat": 34.96732,
    "lng": -109.74023,
    "state_id": "AZ"
  },
  {
    "zip": 86029,
    "lat": 35.00327,
    "lng": -109.99538,
    "state_id": "AZ"
  },
  {
    "zip": 86030,
    "lat": 35.9762,
    "lng": -110.71337,
    "state_id": "AZ"
  },
  {
    "zip": 86031,
    "lat": 35.38652,
    "lng": -110.05272,
    "state_id": "AZ"
  },
  {
    "zip": 86032,
    "lat": 35.04269,
    "lng": -110.32513,
    "state_id": "AZ"
  },
  {
    "zip": 86033,
    "lat": 36.67265,
    "lng": -110.22883,
    "state_id": "AZ"
  },
  {
    "zip": 86034,
    "lat": 35.79022,
    "lng": -110.12806,
    "state_id": "AZ"
  },
  {
    "zip": 86035,
    "lat": 35.44614,
    "lng": -110.96226,
    "state_id": "AZ"
  },
  {
    "zip": 86036,
    "lat": 36.73704,
    "lng": -111.82288,
    "state_id": "AZ"
  },
  {
    "zip": 86038,
    "lat": 34.94196,
    "lng": -111.43059,
    "state_id": "AZ"
  },
  {
    "zip": 86039,
    "lat": 36.06276,
    "lng": -110.53209,
    "state_id": "AZ"
  },
  {
    "zip": 86040,
    "lat": 36.69614,
    "lng": -111.41906,
    "state_id": "AZ"
  },
  {
    "zip": 86042,
    "lat": 35.86169,
    "lng": -110.38752,
    "state_id": "AZ"
  },
  {
    "zip": 86043,
    "lat": 35.80718,
    "lng": -110.53305,
    "state_id": "AZ"
  },
  {
    "zip": 86044,
    "lat": 36.70907,
    "lng": -110.84106,
    "state_id": "AZ"
  },
  {
    "zip": 86045,
    "lat": 36.05915,
    "lng": -111.12132,
    "state_id": "AZ"
  },
  {
    "zip": 86046,
    "lat": 35.43002,
    "lng": -112.1549,
    "state_id": "AZ"
  },
  {
    "zip": 86047,
    "lat": 35.15126,
    "lng": -110.65321,
    "state_id": "AZ"
  },
  {
    "zip": 86052,
    "lat": 36.34732,
    "lng": -112.11898,
    "state_id": "AZ"
  },
  {
    "zip": 86053,
    "lat": 36.72417,
    "lng": -111.07331,
    "state_id": "AZ"
  },
  {
    "zip": 86054,
    "lat": 36.72366,
    "lng": -110.60998,
    "state_id": "AZ"
  },
  {
    "zip": 86301,
    "lat": 34.59172,
    "lng": -112.42642,
    "state_id": "AZ"
  },
  {
    "zip": 86303,
    "lat": 34.48259,
    "lng": -112.44795,
    "state_id": "AZ"
  },
  {
    "zip": 86305,
    "lat": 34.81852,
    "lng": -112.95842,
    "state_id": "AZ"
  },
  {
    "zip": 86313,
    "lat": 34.55361,
    "lng": -112.45239,
    "state_id": "AZ"
  },
  {
    "zip": 86314,
    "lat": 34.62598,
    "lng": -112.30617,
    "state_id": "AZ"
  },
  {
    "zip": 86315,
    "lat": 34.70344,
    "lng": -112.27007,
    "state_id": "AZ"
  },
  {
    "zip": 86320,
    "lat": 35.35372,
    "lng": -112.63608,
    "state_id": "AZ"
  },
  {
    "zip": 86321,
    "lat": 34.49282,
    "lng": -113.10311,
    "state_id": "AZ"
  },
  {
    "zip": 86322,
    "lat": 34.49827,
    "lng": -111.79602,
    "state_id": "AZ"
  },
  {
    "zip": 86323,
    "lat": 34.77472,
    "lng": -112.40792,
    "state_id": "AZ"
  },
  {
    "zip": 86324,
    "lat": 34.85458,
    "lng": -112.1248,
    "state_id": "AZ"
  },
  {
    "zip": 86325,
    "lat": 34.73459,
    "lng": -111.89791,
    "state_id": "AZ"
  },
  {
    "zip": 86326,
    "lat": 34.70001,
    "lng": -112.02364,
    "state_id": "AZ"
  },
  {
    "zip": 86327,
    "lat": 34.57131,
    "lng": -112.10498,
    "state_id": "AZ"
  },
  {
    "zip": 86329,
    "lat": 34.47164,
    "lng": -112.22499,
    "state_id": "AZ"
  },
  {
    "zip": 86331,
    "lat": 34.72685,
    "lng": -112.14388,
    "state_id": "AZ"
  },
  {
    "zip": 86332,
    "lat": 34.32055,
    "lng": -112.63311,
    "state_id": "AZ"
  },
  {
    "zip": 86333,
    "lat": 34.32832,
    "lng": -112.08152,
    "state_id": "AZ"
  },
  {
    "zip": 86334,
    "lat": 34.95114,
    "lng": -112.54529,
    "state_id": "AZ"
  },
  {
    "zip": 86335,
    "lat": 34.69436,
    "lng": -111.68283,
    "state_id": "AZ"
  },
  {
    "zip": 86336,
    "lat": 34.894,
    "lng": -111.87408,
    "state_id": "AZ"
  },
  {
    "zip": 86337,
    "lat": 35.35562,
    "lng": -113.0216,
    "state_id": "AZ"
  },
  {
    "zip": 86338,
    "lat": 34.58179,
    "lng": -112.7539,
    "state_id": "AZ"
  },
  {
    "zip": 86343,
    "lat": 34.19336,
    "lng": -112.29585,
    "state_id": "AZ"
  },
  {
    "zip": 86351,
    "lat": 34.77854,
    "lng": -111.78502,
    "state_id": "AZ"
  },
  {
    "zip": 86401,
    "lat": 35.13283,
    "lng": -113.70328,
    "state_id": "AZ"
  },
  {
    "zip": 86403,
    "lat": 34.48144,
    "lng": -114.34829,
    "state_id": "AZ"
  },
  {
    "zip": 86404,
    "lat": 34.61883,
    "lng": -114.30651,
    "state_id": "AZ"
  },
  {
    "zip": 86406,
    "lat": 34.42283,
    "lng": -114.12499,
    "state_id": "AZ"
  },
  {
    "zip": 86409,
    "lat": 35.46103,
    "lng": -114.02852,
    "state_id": "AZ"
  },
  {
    "zip": 86411,
    "lat": 35.42674,
    "lng": -113.64092,
    "state_id": "AZ"
  },
  {
    "zip": 86413,
    "lat": 35.20499,
    "lng": -114.33208,
    "state_id": "AZ"
  },
  {
    "zip": 86426,
    "lat": 35.00521,
    "lng": -114.56859,
    "state_id": "AZ"
  },
  {
    "zip": 86429,
    "lat": 35.16265,
    "lng": -114.48865,
    "state_id": "AZ"
  },
  {
    "zip": 86431,
    "lat": 35.41556,
    "lng": -114.21983,
    "state_id": "AZ"
  },
  {
    "zip": 86432,
    "lat": 36.46122,
    "lng": -113.58931,
    "state_id": "AZ"
  },
  {
    "zip": 86433,
    "lat": 34.91763,
    "lng": -114.34877,
    "state_id": "AZ"
  },
  {
    "zip": 86434,
    "lat": 35.69302,
    "lng": -113.61062,
    "state_id": "AZ"
  },
  {
    "zip": 86435,
    "lat": 36.05405,
    "lng": -112.90194,
    "state_id": "AZ"
  },
  {
    "zip": 86436,
    "lat": 34.75703,
    "lng": -114.38609,
    "state_id": "AZ"
  },
  {
    "zip": 86437,
    "lat": 35.37886,
    "lng": -113.6141,
    "state_id": "AZ"
  },
  {
    "zip": 86438,
    "lat": 34.57122,
    "lng": -113.84304,
    "state_id": "AZ"
  },
  {
    "zip": 86440,
    "lat": 34.88758,
    "lng": -114.53228,
    "state_id": "AZ"
  },
  {
    "zip": 86441,
    "lat": 35.56922,
    "lng": -114.37781,
    "state_id": "AZ"
  },
  {
    "zip": 86442,
    "lat": 35.08682,
    "lng": -114.56705,
    "state_id": "AZ"
  },
  {
    "zip": 86443,
    "lat": 35.98358,
    "lng": -114.45731,
    "state_id": "AZ"
  },
  {
    "zip": 86444,
    "lat": 35.90037,
    "lng": -114.04865,
    "state_id": "AZ"
  },
  {
    "zip": 86445,
    "lat": 35.77599,
    "lng": -114.50011,
    "state_id": "AZ"
  },
  {
    "zip": 86502,
    "lat": 35.222,
    "lng": -109.58173,
    "state_id": "AZ"
  },
  {
    "zip": 86503,
    "lat": 36.17065,
    "lng": -109.64211,
    "state_id": "AZ"
  },
  {
    "zip": 86504,
    "lat": 35.93147,
    "lng": -109.13646,
    "state_id": "AZ"
  },
  {
    "zip": 86505,
    "lat": 35.62446,
    "lng": -109.71432,
    "state_id": "AZ"
  },
  {
    "zip": 86506,
    "lat": 35.35243,
    "lng": -109.22936,
    "state_id": "AZ"
  },
  {
    "zip": 86507,
    "lat": 36.41058,
    "lng": -109.26345,
    "state_id": "AZ"
  },
  {
    "zip": 86508,
    "lat": 35.32971,
    "lng": -109.08954,
    "state_id": "AZ"
  },
  {
    "zip": 86510,
    "lat": 36.23729,
    "lng": -110.23069,
    "state_id": "AZ"
  },
  {
    "zip": 86511,
    "lat": 35.64746,
    "lng": -109.24552,
    "state_id": "AZ"
  },
  {
    "zip": 86512,
    "lat": 35.09844,
    "lng": -109.26938,
    "state_id": "AZ"
  },
  {
    "zip": 86514,
    "lat": 36.88505,
    "lng": -109.33032,
    "state_id": "AZ"
  },
  {
    "zip": 86515,
    "lat": 35.6828,
    "lng": -108.9485,
    "state_id": "AZ"
  },
  {
    "zip": 86520,
    "lat": 36.11052,
    "lng": -109.93602,
    "state_id": "AZ"
  },
  {
    "zip": 86535,
    "lat": 36.78066,
    "lng": -109.85989,
    "state_id": "AZ"
  },
  {
    "zip": 86538,
    "lat": 36.40507,
    "lng": -109.59933,
    "state_id": "AZ"
  },
  {
    "zip": 86540,
    "lat": 35.93448,
    "lng": -109.46249,
    "state_id": "AZ"
  },
  {
    "zip": 86544,
    "lat": 36.62586,
    "lng": -109.14637,
    "state_id": "AZ"
  },
  {
    "zip": 86545,
    "lat": 36.70591,
    "lng": -109.60744,
    "state_id": "AZ"
  },
  {
    "zip": 86547,
    "lat": 36.50412,
    "lng": -109.49668,
    "state_id": "AZ"
  },
  {
    "zip": 86556,
    "lat": 36.26902,
    "lng": -109.21012,
    "state_id": "AZ"
  },
  {
    "zip": 90001,
    "lat": 33.97398,
    "lng": -118.24955,
    "state_id": "CA"
  },
  {
    "zip": 90002,
    "lat": 33.94901,
    "lng": -118.24674,
    "state_id": "CA"
  },
  {
    "zip": 90003,
    "lat": 33.96411,
    "lng": -118.2737,
    "state_id": "CA"
  },
  {
    "zip": 90004,
    "lat": 34.07621,
    "lng": -118.31084,
    "state_id": "CA"
  },
  {
    "zip": 90005,
    "lat": 34.05912,
    "lng": -118.30654,
    "state_id": "CA"
  },
  {
    "zip": 90006,
    "lat": 34.04801,
    "lng": -118.29418,
    "state_id": "CA"
  },
  {
    "zip": 90007,
    "lat": 34.02809,
    "lng": -118.28489,
    "state_id": "CA"
  },
  {
    "zip": 90008,
    "lat": 34.00956,
    "lng": -118.34706,
    "state_id": "CA"
  },
  {
    "zip": 90010,
    "lat": 34.06211,
    "lng": -118.31577,
    "state_id": "CA"
  },
  {
    "zip": 90011,
    "lat": 34.00714,
    "lng": -118.25874,
    "state_id": "CA"
  },
  {
    "zip": 90012,
    "lat": 34.06599,
    "lng": -118.23821,
    "state_id": "CA"
  },
  {
    "zip": 90013,
    "lat": 34.04481,
    "lng": -118.24033,
    "state_id": "CA"
  },
  {
    "zip": 90014,
    "lat": 34.04308,
    "lng": -118.25169,
    "state_id": "CA"
  },
  {
    "zip": 90015,
    "lat": 34.03939,
    "lng": -118.26645,
    "state_id": "CA"
  },
  {
    "zip": 90016,
    "lat": 34.02872,
    "lng": -118.3546,
    "state_id": "CA"
  },
  {
    "zip": 90017,
    "lat": 34.05291,
    "lng": -118.2643,
    "state_id": "CA"
  },
  {
    "zip": 90018,
    "lat": 34.02887,
    "lng": -118.3172,
    "state_id": "CA"
  },
  {
    "zip": 90019,
    "lat": 34.04864,
    "lng": -118.33868,
    "state_id": "CA"
  },
  {
    "zip": 90020,
    "lat": 34.06639,
    "lng": -118.3099,
    "state_id": "CA"
  },
  {
    "zip": 90021,
    "lat": 34.02931,
    "lng": -118.23873,
    "state_id": "CA"
  },
  {
    "zip": 90022,
    "lat": 34.02453,
    "lng": -118.15609,
    "state_id": "CA"
  },
  {
    "zip": 90023,
    "lat": 34.02276,
    "lng": -118.19989,
    "state_id": "CA"
  },
  {
    "zip": 90024,
    "lat": 34.06568,
    "lng": -118.43502,
    "state_id": "CA"
  },
  {
    "zip": 90025,
    "lat": 34.04544,
    "lng": -118.44586,
    "state_id": "CA"
  },
  {
    "zip": 90026,
    "lat": 34.07928,
    "lng": -118.263,
    "state_id": "CA"
  },
  {
    "zip": 90027,
    "lat": 34.12521,
    "lng": -118.29057,
    "state_id": "CA"
  },
  {
    "zip": 90028,
    "lat": 34.09989,
    "lng": -118.32691,
    "state_id": "CA"
  },
  {
    "zip": 90029,
    "lat": 34.08984,
    "lng": -118.29471,
    "state_id": "CA"
  },
  {
    "zip": 90031,
    "lat": 34.08022,
    "lng": -118.21073,
    "state_id": "CA"
  },
  {
    "zip": 90032,
    "lat": 34.07956,
    "lng": -118.17807,
    "state_id": "CA"
  },
  {
    "zip": 90033,
    "lat": 34.05105,
    "lng": -118.21163,
    "state_id": "CA"
  },
  {
    "zip": 90034,
    "lat": 34.03068,
    "lng": -118.39953,
    "state_id": "CA"
  },
  {
    "zip": 90035,
    "lat": 34.05182,
    "lng": -118.38351,
    "state_id": "CA"
  },
  {
    "zip": 90036,
    "lat": 34.07016,
    "lng": -118.34987,
    "state_id": "CA"
  },
  {
    "zip": 90037,
    "lat": 34.00268,
    "lng": -118.28748,
    "state_id": "CA"
  },
  {
    "zip": 90038,
    "lat": 34.08876,
    "lng": -118.32652,
    "state_id": "CA"
  },
  {
    "zip": 90039,
    "lat": 34.1118,
    "lng": -118.26001,
    "state_id": "CA"
  },
  {
    "zip": 90040,
    "lat": 33.99353,
    "lng": -118.14907,
    "state_id": "CA"
  },
  {
    "zip": 90041,
    "lat": 34.13765,
    "lng": -118.20762,
    "state_id": "CA"
  },
  {
    "zip": 90042,
    "lat": 34.11462,
    "lng": -118.19202,
    "state_id": "CA"
  },
  {
    "zip": 90043,
    "lat": 33.98886,
    "lng": -118.33517,
    "state_id": "CA"
  },
  {
    "zip": 90044,
    "lat": 33.95278,
    "lng": -118.29188,
    "state_id": "CA"
  },
  {
    "zip": 90045,
    "lat": 33.95297,
    "lng": -118.40014,
    "state_id": "CA"
  },
  {
    "zip": 90046,
    "lat": 34.10746,
    "lng": -118.36525,
    "state_id": "CA"
  },
  {
    "zip": 90047,
    "lat": 33.9545,
    "lng": -118.309,
    "state_id": "CA"
  },
  {
    "zip": 90048,
    "lat": 34.07297,
    "lng": -118.37265,
    "state_id": "CA"
  },
  {
    "zip": 90049,
    "lat": 34.08718,
    "lng": -118.48925,
    "state_id": "CA"
  },
  {
    "zip": 90056,
    "lat": 33.98809,
    "lng": -118.37033,
    "state_id": "CA"
  },
  {
    "zip": 90057,
    "lat": 34.06224,
    "lng": -118.27716,
    "state_id": "CA"
  },
  {
    "zip": 90058,
    "lat": 34.00244,
    "lng": -118.21392,
    "state_id": "CA"
  },
  {
    "zip": 90059,
    "lat": 33.92621,
    "lng": -118.24964,
    "state_id": "CA"
  },
  {
    "zip": 90061,
    "lat": 33.92045,
    "lng": -118.27403,
    "state_id": "CA"
  },
  {
    "zip": 90062,
    "lat": 34.00376,
    "lng": -118.30875,
    "state_id": "CA"
  },
  {
    "zip": 90063,
    "lat": 34.0451,
    "lng": -118.18597,
    "state_id": "CA"
  },
  {
    "zip": 90064,
    "lat": 34.03702,
    "lng": -118.42492,
    "state_id": "CA"
  },
  {
    "zip": 90065,
    "lat": 34.10889,
    "lng": -118.22759,
    "state_id": "CA"
  },
  {
    "zip": 90066,
    "lat": 34.00127,
    "lng": -118.43065,
    "state_id": "CA"
  },
  {
    "zip": 90067,
    "lat": 34.0577,
    "lng": -118.41402,
    "state_id": "CA"
  },
  {
    "zip": 90068,
    "lat": 34.12837,
    "lng": -118.32828,
    "state_id": "CA"
  },
  {
    "zip": 90069,
    "lat": 34.09386,
    "lng": -118.38171,
    "state_id": "CA"
  },
  {
    "zip": 90071,
    "lat": 34.05278,
    "lng": -118.25482,
    "state_id": "CA"
  },
  {
    "zip": 90073,
    "lat": 34.05453,
    "lng": -118.45696,
    "state_id": "CA"
  },
  {
    "zip": 90077,
    "lat": 34.10546,
    "lng": -118.45615,
    "state_id": "CA"
  },
  {
    "zip": 90079,
    "lat": 34.04059,
    "lng": -118.2554,
    "state_id": "CA"
  },
  {
    "zip": 90089,
    "lat": 34.02442,
    "lng": -118.28033,
    "state_id": "CA"
  },
  {
    "zip": 90090,
    "lat": 34.07282,
    "lng": -118.24099,
    "state_id": "CA"
  },
  {
    "zip": 90094,
    "lat": 33.97539,
    "lng": -118.417,
    "state_id": "CA"
  },
  {
    "zip": 90095,
    "lat": 34.07119,
    "lng": -118.44335,
    "state_id": "CA"
  },
  {
    "zip": 90201,
    "lat": 33.97072,
    "lng": -118.17079,
    "state_id": "CA"
  },
  {
    "zip": 90210,
    "lat": 34.10103,
    "lng": -118.41475,
    "state_id": "CA"
  },
  {
    "zip": 90211,
    "lat": 34.06502,
    "lng": -118.38304,
    "state_id": "CA"
  },
  {
    "zip": 90212,
    "lat": 34.06217,
    "lng": -118.40194,
    "state_id": "CA"
  },
  {
    "zip": 90220,
    "lat": 33.88078,
    "lng": -118.23607,
    "state_id": "CA"
  },
  {
    "zip": 90221,
    "lat": 33.88614,
    "lng": -118.20595,
    "state_id": "CA"
  },
  {
    "zip": 90222,
    "lat": 33.91238,
    "lng": -118.2365,
    "state_id": "CA"
  },
  {
    "zip": 90230,
    "lat": 33.9972,
    "lng": -118.39458,
    "state_id": "CA"
  },
  {
    "zip": 90232,
    "lat": 34.01879,
    "lng": -118.39182,
    "state_id": "CA"
  },
  {
    "zip": 90240,
    "lat": 33.9568,
    "lng": -118.11869,
    "state_id": "CA"
  },
  {
    "zip": 90241,
    "lat": 33.94084,
    "lng": -118.12924,
    "state_id": "CA"
  },
  {
    "zip": 90242,
    "lat": 33.92213,
    "lng": -118.14148,
    "state_id": "CA"
  },
  {
    "zip": 90245,
    "lat": 33.91695,
    "lng": -118.40206,
    "state_id": "CA"
  },
  {
    "zip": 90247,
    "lat": 33.8914,
    "lng": -118.29737,
    "state_id": "CA"
  },
  {
    "zip": 90248,
    "lat": 33.87669,
    "lng": -118.2835,
    "state_id": "CA"
  },
  {
    "zip": 90249,
    "lat": 33.9015,
    "lng": -118.31708,
    "state_id": "CA"
  },
  {
    "zip": 90250,
    "lat": 33.91436,
    "lng": -118.34931,
    "state_id": "CA"
  },
  {
    "zip": 90254,
    "lat": 33.86546,
    "lng": -118.39665,
    "state_id": "CA"
  },
  {
    "zip": 90255,
    "lat": 33.97695,
    "lng": -118.21718,
    "state_id": "CA"
  },
  {
    "zip": 90260,
    "lat": 33.88828,
    "lng": -118.35122,
    "state_id": "CA"
  },
  {
    "zip": 90262,
    "lat": 33.92365,
    "lng": -118.20053,
    "state_id": "CA"
  },
  {
    "zip": 90263,
    "lat": 34.03892,
    "lng": -118.70792,
    "state_id": "CA"
  },
  {
    "zip": 90265,
    "lat": 34.07191,
    "lng": -118.84989,
    "state_id": "CA"
  },
  {
    "zip": 90266,
    "lat": 33.8895,
    "lng": -118.39718,
    "state_id": "CA"
  },
  {
    "zip": 90270,
    "lat": 33.98797,
    "lng": -118.18592,
    "state_id": "CA"
  },
  {
    "zip": 90272,
    "lat": 34.07991,
    "lng": -118.54219,
    "state_id": "CA"
  },
  {
    "zip": 90274,
    "lat": 33.77734,
    "lng": -118.36893,
    "state_id": "CA"
  },
  {
    "zip": 90275,
    "lat": 33.75545,
    "lng": -118.36393,
    "state_id": "CA"
  },
  {
    "zip": 90277,
    "lat": 33.83073,
    "lng": -118.3846,
    "state_id": "CA"
  },
  {
    "zip": 90278,
    "lat": 33.87325,
    "lng": -118.37037,
    "state_id": "CA"
  },
  {
    "zip": 90280,
    "lat": 33.9446,
    "lng": -118.19262,
    "state_id": "CA"
  },
  {
    "zip": 90290,
    "lat": 34.09588,
    "lng": -118.60707,
    "state_id": "CA"
  },
  {
    "zip": 90291,
    "lat": 33.99437,
    "lng": -118.46344,
    "state_id": "CA"
  },
  {
    "zip": 90292,
    "lat": 33.97831,
    "lng": -118.44761,
    "state_id": "CA"
  },
  {
    "zip": 90293,
    "lat": 33.95033,
    "lng": -118.43721,
    "state_id": "CA"
  },
  {
    "zip": 90301,
    "lat": 33.95652,
    "lng": -118.35864,
    "state_id": "CA"
  },
  {
    "zip": 90302,
    "lat": 33.97472,
    "lng": -118.35551,
    "state_id": "CA"
  },
  {
    "zip": 90303,
    "lat": 33.93809,
    "lng": -118.33232,
    "state_id": "CA"
  },
  {
    "zip": 90304,
    "lat": 33.93799,
    "lng": -118.35857,
    "state_id": "CA"
  },
  {
    "zip": 90305,
    "lat": 33.95959,
    "lng": -118.33015,
    "state_id": "CA"
  },
  {
    "zip": 90401,
    "lat": 34.01545,
    "lng": -118.49248,
    "state_id": "CA"
  },
  {
    "zip": 90402,
    "lat": 34.03561,
    "lng": -118.50364,
    "state_id": "CA"
  },
  {
    "zip": 90403,
    "lat": 34.03106,
    "lng": -118.4901,
    "state_id": "CA"
  },
  {
    "zip": 90404,
    "lat": 34.02662,
    "lng": -118.47364,
    "state_id": "CA"
  },
  {
    "zip": 90405,
    "lat": 34.01179,
    "lng": -118.46821,
    "state_id": "CA"
  },
  {
    "zip": 90501,
    "lat": 33.8334,
    "lng": -118.31426,
    "state_id": "CA"
  },
  {
    "zip": 90502,
    "lat": 33.83499,
    "lng": -118.29292,
    "state_id": "CA"
  },
  {
    "zip": 90503,
    "lat": 33.84074,
    "lng": -118.35361,
    "state_id": "CA"
  },
  {
    "zip": 90504,
    "lat": 33.86682,
    "lng": -118.33115,
    "state_id": "CA"
  },
  {
    "zip": 90505,
    "lat": 33.80887,
    "lng": -118.34802,
    "state_id": "CA"
  },
  {
    "zip": 90506,
    "lat": 33.88491,
    "lng": -118.33033,
    "state_id": "CA"
  },
  {
    "zip": 90601,
    "lat": 34.00833,
    "lng": -118.03135,
    "state_id": "CA"
  },
  {
    "zip": 90602,
    "lat": 33.972,
    "lng": -118.02227,
    "state_id": "CA"
  },
  {
    "zip": 90603,
    "lat": 33.94541,
    "lng": -117.99253,
    "state_id": "CA"
  },
  {
    "zip": 90604,
    "lat": 33.93012,
    "lng": -118.01225,
    "state_id": "CA"
  },
  {
    "zip": 90605,
    "lat": 33.94984,
    "lng": -118.02328,
    "state_id": "CA"
  },
  {
    "zip": 90606,
    "lat": 33.97766,
    "lng": -118.06579,
    "state_id": "CA"
  },
  {
    "zip": 90620,
    "lat": 33.84612,
    "lng": -118.01169,
    "state_id": "CA"
  },
  {
    "zip": 90621,
    "lat": 33.87428,
    "lng": -117.99351,
    "state_id": "CA"
  },
  {
    "zip": 90623,
    "lat": 33.85045,
    "lng": -118.04072,
    "state_id": "CA"
  },
  {
    "zip": 90630,
    "lat": 33.81814,
    "lng": -118.03816,
    "state_id": "CA"
  },
  {
    "zip": 90631,
    "lat": 33.94248,
    "lng": -117.95134,
    "state_id": "CA"
  },
  {
    "zip": 90638,
    "lat": 33.90241,
    "lng": -118.0092,
    "state_id": "CA"
  },
  {
    "zip": 90640,
    "lat": 34.01507,
    "lng": -118.11073,
    "state_id": "CA"
  },
  {
    "zip": 90650,
    "lat": 33.90685,
    "lng": -118.08263,
    "state_id": "CA"
  },
  {
    "zip": 90660,
    "lat": 33.98882,
    "lng": -118.09063,
    "state_id": "CA"
  },
  {
    "zip": 90670,
    "lat": 33.93301,
    "lng": -118.06264,
    "state_id": "CA"
  },
  {
    "zip": 90680,
    "lat": 33.80118,
    "lng": -117.99495,
    "state_id": "CA"
  },
  {
    "zip": 90701,
    "lat": 33.86763,
    "lng": -118.08062,
    "state_id": "CA"
  },
  {
    "zip": 90703,
    "lat": 33.86785,
    "lng": -118.06871,
    "state_id": "CA"
  },
  {
    "zip": 90704,
    "lat": 33.38251,
    "lng": -118.43439,
    "state_id": "CA"
  },
  {
    "zip": 90706,
    "lat": 33.88802,
    "lng": -118.12706,
    "state_id": "CA"
  },
  {
    "zip": 90710,
    "lat": 33.79809,
    "lng": -118.29907,
    "state_id": "CA"
  },
  {
    "zip": 90712,
    "lat": 33.84901,
    "lng": -118.14672,
    "state_id": "CA"
  },
  {
    "zip": 90713,
    "lat": 33.84798,
    "lng": -118.11265,
    "state_id": "CA"
  },
  {
    "zip": 90715,
    "lat": 33.84035,
    "lng": -118.07886,
    "state_id": "CA"
  },
  {
    "zip": 90716,
    "lat": 33.83049,
    "lng": -118.07302,
    "state_id": "CA"
  },
  {
    "zip": 90717,
    "lat": 33.79383,
    "lng": -118.3172,
    "state_id": "CA"
  },
  {
    "zip": 90720,
    "lat": 33.79557,
    "lng": -118.0636,
    "state_id": "CA"
  },
  {
    "zip": 90723,
    "lat": 33.89743,
    "lng": -118.16482,
    "state_id": "CA"
  },
  {
    "zip": 90731,
    "lat": 33.73334,
    "lng": -118.27433,
    "state_id": "CA"
  },
  {
    "zip": 90732,
    "lat": 33.74507,
    "lng": -118.31012,
    "state_id": "CA"
  },
  {
    "zip": 90740,
    "lat": 33.75962,
    "lng": -118.07346,
    "state_id": "CA"
  },
  {
    "zip": 90742,
    "lat": 33.71792,
    "lng": -118.07155,
    "state_id": "CA"
  },
  {
    "zip": 90743,
    "lat": 33.72709,
    "lng": -118.08284,
    "state_id": "CA"
  },
  {
    "zip": 90744,
    "lat": 33.77865,
    "lng": -118.26167,
    "state_id": "CA"
  },
  {
    "zip": 90745,
    "lat": 33.82124,
    "lng": -118.26441,
    "state_id": "CA"
  },
  {
    "zip": 90746,
    "lat": 33.85888,
    "lng": -118.25526,
    "state_id": "CA"
  },
  {
    "zip": 90747,
    "lat": 33.86298,
    "lng": -118.25131,
    "state_id": "CA"
  },
  {
    "zip": 90755,
    "lat": 33.80289,
    "lng": -118.16772,
    "state_id": "CA"
  },
  {
    "zip": 90802,
    "lat": 33.75021,
    "lng": -118.2114,
    "state_id": "CA"
  },
  {
    "zip": 90803,
    "lat": 33.76162,
    "lng": -118.12219,
    "state_id": "CA"
  },
  {
    "zip": 90804,
    "lat": 33.78185,
    "lng": -118.14863,
    "state_id": "CA"
  },
  {
    "zip": 90805,
    "lat": 33.86493,
    "lng": -118.18055,
    "state_id": "CA"
  },
  {
    "zip": 90806,
    "lat": 33.8045,
    "lng": -118.18762,
    "state_id": "CA"
  },
  {
    "zip": 90807,
    "lat": 33.82795,
    "lng": -118.1746,
    "state_id": "CA"
  },
  {
    "zip": 90808,
    "lat": 33.82406,
    "lng": -118.11239,
    "state_id": "CA"
  },
  {
    "zip": 90810,
    "lat": 33.81894,
    "lng": -118.2211,
    "state_id": "CA"
  },
  {
    "zip": 90813,
    "lat": 33.78237,
    "lng": -118.19686,
    "state_id": "CA"
  },
  {
    "zip": 90814,
    "lat": 33.77163,
    "lng": -118.14362,
    "state_id": "CA"
  },
  {
    "zip": 90815,
    "lat": 33.79581,
    "lng": -118.11626,
    "state_id": "CA"
  },
  {
    "zip": 90822,
    "lat": 33.77844,
    "lng": -118.11863,
    "state_id": "CA"
  },
  {
    "zip": 90831,
    "lat": 33.76829,
    "lng": -118.2017,
    "state_id": "CA"
  },
  {
    "zip": 91001,
    "lat": 34.19544,
    "lng": -118.13796,
    "state_id": "CA"
  },
  {
    "zip": 91006,
    "lat": 34.13603,
    "lng": -118.02679,
    "state_id": "CA"
  },
  {
    "zip": 91007,
    "lat": 34.1286,
    "lng": -118.04825,
    "state_id": "CA"
  },
  {
    "zip": 91008,
    "lat": 34.15344,
    "lng": -117.96821,
    "state_id": "CA"
  },
  {
    "zip": 91010,
    "lat": 34.14073,
    "lng": -117.9567,
    "state_id": "CA"
  },
  {
    "zip": 91011,
    "lat": 34.22161,
    "lng": -118.20516,
    "state_id": "CA"
  },
  {
    "zip": 91016,
    "lat": 34.15209,
    "lng": -118.0007,
    "state_id": "CA"
  },
  {
    "zip": 91020,
    "lat": 34.21129,
    "lng": -118.23072,
    "state_id": "CA"
  },
  {
    "zip": 91024,
    "lat": 34.16871,
    "lng": -118.05036,
    "state_id": "CA"
  },
  {
    "zip": 91030,
    "lat": 34.1102,
    "lng": -118.15735,
    "state_id": "CA"
  },
  {
    "zip": 91040,
    "lat": 34.26177,
    "lng": -118.33715,
    "state_id": "CA"
  },
  {
    "zip": 91042,
    "lat": 34.31653,
    "lng": -118.24912,
    "state_id": "CA"
  },
  {
    "zip": 91046,
    "lat": 34.21145,
    "lng": -118.24104,
    "state_id": "CA"
  },
  {
    "zip": 91101,
    "lat": 34.14657,
    "lng": -118.13942,
    "state_id": "CA"
  },
  {
    "zip": 91103,
    "lat": 34.16898,
    "lng": -118.16597,
    "state_id": "CA"
  },
  {
    "zip": 91104,
    "lat": 34.16784,
    "lng": -118.12348,
    "state_id": "CA"
  },
  {
    "zip": 91105,
    "lat": 34.13821,
    "lng": -118.16677,
    "state_id": "CA"
  },
  {
    "zip": 91106,
    "lat": 34.13867,
    "lng": -118.1282,
    "state_id": "CA"
  },
  {
    "zip": 91107,
    "lat": 34.15833,
    "lng": -118.08716,
    "state_id": "CA"
  },
  {
    "zip": 91108,
    "lat": 34.12234,
    "lng": -118.11341,
    "state_id": "CA"
  },
  {
    "zip": 91201,
    "lat": 34.17031,
    "lng": -118.28913,
    "state_id": "CA"
  },
  {
    "zip": 91202,
    "lat": 34.16785,
    "lng": -118.26846,
    "state_id": "CA"
  },
  {
    "zip": 91203,
    "lat": 34.15298,
    "lng": -118.26419,
    "state_id": "CA"
  },
  {
    "zip": 91204,
    "lat": 34.13629,
    "lng": -118.26093,
    "state_id": "CA"
  },
  {
    "zip": 91205,
    "lat": 34.13666,
    "lng": -118.24332,
    "state_id": "CA"
  },
  {
    "zip": 91206,
    "lat": 34.16068,
    "lng": -118.21352,
    "state_id": "CA"
  },
  {
    "zip": 91207,
    "lat": 34.18361,
    "lng": -118.25864,
    "state_id": "CA"
  },
  {
    "zip": 91208,
    "lat": 34.1925,
    "lng": -118.23656,
    "state_id": "CA"
  },
  {
    "zip": 91210,
    "lat": 34.14452,
    "lng": -118.25645,
    "state_id": "CA"
  },
  {
    "zip": 91214,
    "lat": 34.23671,
    "lng": -118.24925,
    "state_id": "CA"
  },
  {
    "zip": 91301,
    "lat": 34.12274,
    "lng": -118.7572,
    "state_id": "CA"
  },
  {
    "zip": 91302,
    "lat": 34.12426,
    "lng": -118.6701,
    "state_id": "CA"
  },
  {
    "zip": 91303,
    "lat": 34.19794,
    "lng": -118.60156,
    "state_id": "CA"
  },
  {
    "zip": 91304,
    "lat": 34.22465,
    "lng": -118.63249,
    "state_id": "CA"
  },
  {
    "zip": 91306,
    "lat": 34.20927,
    "lng": -118.5754,
    "state_id": "CA"
  },
  {
    "zip": 91307,
    "lat": 34.20164,
    "lng": -118.66216,
    "state_id": "CA"
  },
  {
    "zip": 91311,
    "lat": 34.28937,
    "lng": -118.60742,
    "state_id": "CA"
  },
  {
    "zip": 91316,
    "lat": 34.16039,
    "lng": -118.51669,
    "state_id": "CA"
  },
  {
    "zip": 91320,
    "lat": 34.17596,
    "lng": -118.94506,
    "state_id": "CA"
  },
  {
    "zip": 91321,
    "lat": 34.36909,
    "lng": -118.486,
    "state_id": "CA"
  },
  {
    "zip": 91324,
    "lat": 34.23901,
    "lng": -118.54958,
    "state_id": "CA"
  },
  {
    "zip": 91325,
    "lat": 34.23602,
    "lng": -118.51759,
    "state_id": "CA"
  },
  {
    "zip": 91326,
    "lat": 34.28048,
    "lng": -118.55758,
    "state_id": "CA"
  },
  {
    "zip": 91330,
    "lat": 34.24765,
    "lng": -118.52607,
    "state_id": "CA"
  },
  {
    "zip": 91331,
    "lat": 34.25562,
    "lng": -118.42076,
    "state_id": "CA"
  },
  {
    "zip": 91335,
    "lat": 34.20105,
    "lng": -118.54067,
    "state_id": "CA"
  },
  {
    "zip": 91340,
    "lat": 34.28679,
    "lng": -118.43513,
    "state_id": "CA"
  },
  {
    "zip": 91342,
    "lat": 34.31515,
    "lng": -118.38506,
    "state_id": "CA"
  },
  {
    "zip": 91343,
    "lat": 34.23827,
    "lng": -118.48067,
    "state_id": "CA"
  },
  {
    "zip": 91344,
    "lat": 34.29392,
    "lng": -118.5075,
    "state_id": "CA"
  },
  {
    "zip": 91345,
    "lat": 34.26594,
    "lng": -118.45945,
    "state_id": "CA"
  },
  {
    "zip": 91350,
    "lat": 34.43357,
    "lng": -118.50074,
    "state_id": "CA"
  },
  {
    "zip": 91351,
    "lat": 34.43321,
    "lng": -118.46295,
    "state_id": "CA"
  },
  {
    "zip": 91352,
    "lat": 34.23194,
    "lng": -118.36642,
    "state_id": "CA"
  },
  {
    "zip": 91354,
    "lat": 34.46493,
    "lng": -118.55428,
    "state_id": "CA"
  },
  {
    "zip": 91355,
    "lat": 34.42467,
    "lng": -118.58925,
    "state_id": "CA"
  },
  {
    "zip": 91356,
    "lat": 34.15508,
    "lng": -118.54751,
    "state_id": "CA"
  },
  {
    "zip": 91360,
    "lat": 34.21271,
    "lng": -118.88215,
    "state_id": "CA"
  },
  {
    "zip": 91361,
    "lat": 34.13649,
    "lng": -118.889,
    "state_id": "CA"
  },
  {
    "zip": 91362,
    "lat": 34.19336,
    "lng": -118.81868,
    "state_id": "CA"
  },
  {
    "zip": 91364,
    "lat": 34.15477,
    "lng": -118.59509,
    "state_id": "CA"
  },
  {
    "zip": 91367,
    "lat": 34.17705,
    "lng": -118.61531,
    "state_id": "CA"
  },
  {
    "zip": 91371,
    "lat": 34.18459,
    "lng": -118.57636,
    "state_id": "CA"
  },
  {
    "zip": 91377,
    "lat": 34.18496,
    "lng": -118.76687,
    "state_id": "CA"
  },
  {
    "zip": 91381,
    "lat": 34.37747,
    "lng": -118.61311,
    "state_id": "CA"
  },
  {
    "zip": 91384,
    "lat": 34.53072,
    "lng": -118.68639,
    "state_id": "CA"
  },
  {
    "zip": 91387,
    "lat": 34.39878,
    "lng": -118.37319,
    "state_id": "CA"
  },
  {
    "zip": 91390,
    "lat": 34.52287,
    "lng": -118.39215,
    "state_id": "CA"
  },
  {
    "zip": 91401,
    "lat": 34.17812,
    "lng": -118.43146,
    "state_id": "CA"
  },
  {
    "zip": 91402,
    "lat": 34.22411,
    "lng": -118.44481,
    "state_id": "CA"
  },
  {
    "zip": 91403,
    "lat": 34.14659,
    "lng": -118.46286,
    "state_id": "CA"
  },
  {
    "zip": 91405,
    "lat": 34.20109,
    "lng": -118.44816,
    "state_id": "CA"
  },
  {
    "zip": 91406,
    "lat": 34.19818,
    "lng": -118.48975,
    "state_id": "CA"
  },
  {
    "zip": 91411,
    "lat": 34.17838,
    "lng": -118.4593,
    "state_id": "CA"
  },
  {
    "zip": 91423,
    "lat": 34.14852,
    "lng": -118.43272,
    "state_id": "CA"
  },
  {
    "zip": 91436,
    "lat": 34.15087,
    "lng": -118.49229,
    "state_id": "CA"
  },
  {
    "zip": 91501,
    "lat": 34.20052,
    "lng": -118.29583,
    "state_id": "CA"
  },
  {
    "zip": 91502,
    "lat": 34.17676,
    "lng": -118.30922,
    "state_id": "CA"
  },
  {
    "zip": 91504,
    "lat": 34.20449,
    "lng": -118.327,
    "state_id": "CA"
  },
  {
    "zip": 91505,
    "lat": 34.17478,
    "lng": -118.34681,
    "state_id": "CA"
  },
  {
    "zip": 91506,
    "lat": 34.17117,
    "lng": -118.32384,
    "state_id": "CA"
  },
  {
    "zip": 91601,
    "lat": 34.16859,
    "lng": -118.37254,
    "state_id": "CA"
  },
  {
    "zip": 91602,
    "lat": 34.15106,
    "lng": -118.36634,
    "state_id": "CA"
  },
  {
    "zip": 91604,
    "lat": 34.13881,
    "lng": -118.39352,
    "state_id": "CA"
  },
  {
    "zip": 91605,
    "lat": 34.20721,
    "lng": -118.40025,
    "state_id": "CA"
  },
  {
    "zip": 91606,
    "lat": 34.18659,
    "lng": -118.38871,
    "state_id": "CA"
  },
  {
    "zip": 91607,
    "lat": 34.16619,
    "lng": -118.40011,
    "state_id": "CA"
  },
  {
    "zip": 91608,
    "lat": 34.13826,
    "lng": -118.35285,
    "state_id": "CA"
  },
  {
    "zip": 91701,
    "lat": 34.13762,
    "lng": -117.59998,
    "state_id": "CA"
  },
  {
    "zip": 91702,
    "lat": 34.26557,
    "lng": -117.86721,
    "state_id": "CA"
  },
  {
    "zip": 91706,
    "lat": 34.09641,
    "lng": -117.96816,
    "state_id": "CA"
  },
  {
    "zip": 91708,
    "lat": 33.95397,
    "lng": -117.64045,
    "state_id": "CA"
  },
  {
    "zip": 91709,
    "lat": 33.96432,
    "lng": -117.73599,
    "state_id": "CA"
  },
  {
    "zip": 91710,
    "lat": 34.00447,
    "lng": -117.68468,
    "state_id": "CA"
  },
  {
    "zip": 91711,
    "lat": 34.12853,
    "lng": -117.71561,
    "state_id": "CA"
  },
  {
    "zip": 91722,
    "lat": 34.09726,
    "lng": -117.90616,
    "state_id": "CA"
  },
  {
    "zip": 91723,
    "lat": 34.08476,
    "lng": -117.88643,
    "state_id": "CA"
  },
  {
    "zip": 91724,
    "lat": 34.08071,
    "lng": -117.85502,
    "state_id": "CA"
  },
  {
    "zip": 91730,
    "lat": 34.09955,
    "lng": -117.57847,
    "state_id": "CA"
  },
  {
    "zip": 91731,
    "lat": 34.07878,
    "lng": -118.04062,
    "state_id": "CA"
  },
  {
    "zip": 91732,
    "lat": 34.07343,
    "lng": -118.01445,
    "state_id": "CA"
  },
  {
    "zip": 91733,
    "lat": 34.04553,
    "lng": -118.05318,
    "state_id": "CA"
  },
  {
    "zip": 91737,
    "lat": 34.15259,
    "lng": -117.57772,
    "state_id": "CA"
  },
  {
    "zip": 91739,
    "lat": 34.17054,
    "lng": -117.51815,
    "state_id": "CA"
  },
  {
    "zip": 91740,
    "lat": 34.11878,
    "lng": -117.8539,
    "state_id": "CA"
  },
  {
    "zip": 91741,
    "lat": 34.15372,
    "lng": -117.84368,
    "state_id": "CA"
  },
  {
    "zip": 91744,
    "lat": 34.0289,
    "lng": -117.93731,
    "state_id": "CA"
  },
  {
    "zip": 91745,
    "lat": 33.99931,
    "lng": -117.97325,
    "state_id": "CA"
  },
  {
    "zip": 91746,
    "lat": 34.04426,
    "lng": -117.98629,
    "state_id": "CA"
  },
  {
    "zip": 91748,
    "lat": 33.97661,
    "lng": -117.8997,
    "state_id": "CA"
  },
  {
    "zip": 91750,
    "lat": 34.16086,
    "lng": -117.77278,
    "state_id": "CA"
  },
  {
    "zip": 91752,
    "lat": 33.99576,
    "lng": -117.534,
    "state_id": "CA"
  },
  {
    "zip": 91754,
    "lat": 34.05096,
    "lng": -118.1446,
    "state_id": "CA"
  },
  {
    "zip": 91755,
    "lat": 34.048,
    "lng": -118.11499,
    "state_id": "CA"
  },
  {
    "zip": 91759,
    "lat": 34.25405,
    "lng": -117.69148,
    "state_id": "CA"
  },
  {
    "zip": 91761,
    "lat": 34.03458,
    "lng": -117.59211,
    "state_id": "CA"
  },
  {
    "zip": 91762,
    "lat": 34.02905,
    "lng": -117.64478,
    "state_id": "CA"
  },
  {
    "zip": 91763,
    "lat": 34.07234,
    "lng": -117.6983,
    "state_id": "CA"
  },
  {
    "zip": 91764,
    "lat": 34.07525,
    "lng": -117.60224,
    "state_id": "CA"
  },
  {
    "zip": 91765,
    "lat": 33.98822,
    "lng": -117.81446,
    "state_id": "CA"
  },
  {
    "zip": 91766,
    "lat": 34.04179,
    "lng": -117.75691,
    "state_id": "CA"
  },
  {
    "zip": 91767,
    "lat": 34.08145,
    "lng": -117.73845,
    "state_id": "CA"
  },
  {
    "zip": 91768,
    "lat": 34.06389,
    "lng": -117.79064,
    "state_id": "CA"
  },
  {
    "zip": 91770,
    "lat": 34.06441,
    "lng": -118.08364,
    "state_id": "CA"
  },
  {
    "zip": 91773,
    "lat": 34.11004,
    "lng": -117.80981,
    "state_id": "CA"
  },
  {
    "zip": 91775,
    "lat": 34.1143,
    "lng": -118.08958,
    "state_id": "CA"
  },
  {
    "zip": 91776,
    "lat": 34.08988,
    "lng": -118.09492,
    "state_id": "CA"
  },
  {
    "zip": 91780,
    "lat": 34.10146,
    "lng": -118.05547,
    "state_id": "CA"
  },
  {
    "zip": 91784,
    "lat": 34.141,
    "lng": -117.65814,
    "state_id": "CA"
  },
  {
    "zip": 91786,
    "lat": 34.1053,
    "lng": -117.66204,
    "state_id": "CA"
  },
  {
    "zip": 91789,
    "lat": 34.01831,
    "lng": -117.85463,
    "state_id": "CA"
  },
  {
    "zip": 91790,
    "lat": 34.06735,
    "lng": -117.93771,
    "state_id": "CA"
  },
  {
    "zip": 91791,
    "lat": 34.06107,
    "lng": -117.89403,
    "state_id": "CA"
  },
  {
    "zip": 91792,
    "lat": 34.02584,
    "lng": -117.89993,
    "state_id": "CA"
  },
  {
    "zip": 91801,
    "lat": 34.09073,
    "lng": -118.12757,
    "state_id": "CA"
  },
  {
    "zip": 91803,
    "lat": 34.07494,
    "lng": -118.14621,
    "state_id": "CA"
  },
  {
    "zip": 91901,
    "lat": 32.80639,
    "lng": -116.7123,
    "state_id": "CA"
  },
  {
    "zip": 91902,
    "lat": 32.66704,
    "lng": -117.01825,
    "state_id": "CA"
  },
  {
    "zip": 91905,
    "lat": 32.67995,
    "lng": -116.31605,
    "state_id": "CA"
  },
  {
    "zip": 91906,
    "lat": 32.65946,
    "lng": -116.46057,
    "state_id": "CA"
  },
  {
    "zip": 91910,
    "lat": 32.63826,
    "lng": -117.05706,
    "state_id": "CA"
  },
  {
    "zip": 91911,
    "lat": 32.60736,
    "lng": -117.05404,
    "state_id": "CA"
  },
  {
    "zip": 91913,
    "lat": 32.62005,
    "lng": -116.98705,
    "state_id": "CA"
  },
  {
    "zip": 91914,
    "lat": 32.66544,
    "lng": -116.9524,
    "state_id": "CA"
  },
  {
    "zip": 91915,
    "lat": 32.62231,
    "lng": -116.94953,
    "state_id": "CA"
  },
  {
    "zip": 91916,
    "lat": 32.88799,
    "lng": -116.62135,
    "state_id": "CA"
  },
  {
    "zip": 91917,
    "lat": 32.60876,
    "lng": -116.71979,
    "state_id": "CA"
  },
  {
    "zip": 91931,
    "lat": 32.84637,
    "lng": -116.55771,
    "state_id": "CA"
  },
  {
    "zip": 91932,
    "lat": 32.57609,
    "lng": -117.1194,
    "state_id": "CA"
  },
  {
    "zip": 91934,
    "lat": 32.65855,
    "lng": -116.17246,
    "state_id": "CA"
  },
  {
    "zip": 91935,
    "lat": 32.70882,
    "lng": -116.79053,
    "state_id": "CA"
  },
  {
    "zip": 91941,
    "lat": 32.75989,
    "lng": -116.99341,
    "state_id": "CA"
  },
  {
    "zip": 91942,
    "lat": 32.77747,
    "lng": -117.0217,
    "state_id": "CA"
  },
  {
    "zip": 91945,
    "lat": 32.73317,
    "lng": -117.03416,
    "state_id": "CA"
  },
  {
    "zip": 91948,
    "lat": 32.86941,
    "lng": -116.45317,
    "state_id": "CA"
  },
  {
    "zip": 91950,
    "lat": 32.67002,
    "lng": -117.09419,
    "state_id": "CA"
  },
  {
    "zip": 91962,
    "lat": 32.7776,
    "lng": -116.47423,
    "state_id": "CA"
  },
  {
    "zip": 91963,
    "lat": 32.62627,
    "lng": -116.61007,
    "state_id": "CA"
  },
  {
    "zip": 91977,
    "lat": 32.72593,
    "lng": -116.99655,
    "state_id": "CA"
  },
  {
    "zip": 91978,
    "lat": 32.72416,
    "lng": -116.9424,
    "state_id": "CA"
  },
  {
    "zip": 91980,
    "lat": 32.58882,
    "lng": -116.61917,
    "state_id": "CA"
  },
  {
    "zip": 92003,
    "lat": 33.28568,
    "lng": -117.19872,
    "state_id": "CA"
  },
  {
    "zip": 92004,
    "lat": 33.13941,
    "lng": -116.10357,
    "state_id": "CA"
  },
  {
    "zip": 92007,
    "lat": 33.0219,
    "lng": -117.2735,
    "state_id": "CA"
  },
  {
    "zip": 92008,
    "lat": 33.14594,
    "lng": -117.31785,
    "state_id": "CA"
  },
  {
    "zip": 92009,
    "lat": 33.0943,
    "lng": -117.24577,
    "state_id": "CA"
  },
  {
    "zip": 92010,
    "lat": 33.15687,
    "lng": -117.28474,
    "state_id": "CA"
  },
  {
    "zip": 92011,
    "lat": 33.10721,
    "lng": -117.29429,
    "state_id": "CA"
  },
  {
    "zip": 92014,
    "lat": 32.96686,
    "lng": -117.24799,
    "state_id": "CA"
  },
  {
    "zip": 92019,
    "lat": 32.77909,
    "lng": -116.87892,
    "state_id": "CA"
  },
  {
    "zip": 92020,
    "lat": 32.79555,
    "lng": -116.96978,
    "state_id": "CA"
  },
  {
    "zip": 92021,
    "lat": 32.83548,
    "lng": -116.8708,
    "state_id": "CA"
  },
  {
    "zip": 92024,
    "lat": 33.05615,
    "lng": -117.25735,
    "state_id": "CA"
  },
  {
    "zip": 92025,
    "lat": 33.0853,
    "lng": -117.0294,
    "state_id": "CA"
  },
  {
    "zip": 92026,
    "lat": 33.21246,
    "lng": -117.11609,
    "state_id": "CA"
  },
  {
    "zip": 92027,
    "lat": 33.13446,
    "lng": -116.98428,
    "state_id": "CA"
  },
  {
    "zip": 92028,
    "lat": 33.38809,
    "lng": -117.21247,
    "state_id": "CA"
  },
  {
    "zip": 92029,
    "lat": 33.08511,
    "lng": -117.12994,
    "state_id": "CA"
  },
  {
    "zip": 92036,
    "lat": 33.02136,
    "lng": -116.49121,
    "state_id": "CA"
  },
  {
    "zip": 92037,
    "lat": 32.85505,
    "lng": -117.25012,
    "state_id": "CA"
  },
  {
    "zip": 92040,
    "lat": 32.90753,
    "lng": -116.88472,
    "state_id": "CA"
  },
  {
    "zip": 92054,
    "lat": 33.2738,
    "lng": -117.431,
    "state_id": "CA"
  },
  {
    "zip": 92055,
    "lat": 33.38774,
    "lng": -117.44483,
    "state_id": "CA"
  },
  {
    "zip": 92056,
    "lat": 33.20149,
    "lng": -117.28908,
    "state_id": "CA"
  },
  {
    "zip": 92057,
    "lat": 33.25403,
    "lng": -117.28732,
    "state_id": "CA"
  },
  {
    "zip": 92058,
    "lat": 33.27081,
    "lng": -117.33968,
    "state_id": "CA"
  },
  {
    "zip": 92059,
    "lat": 33.37649,
    "lng": -117.06618,
    "state_id": "CA"
  },
  {
    "zip": 92060,
    "lat": 33.34078,
    "lng": -116.8486,
    "state_id": "CA"
  },
  {
    "zip": 92061,
    "lat": 33.29851,
    "lng": -116.92484,
    "state_id": "CA"
  },
  {
    "zip": 92064,
    "lat": 32.98408,
    "lng": -117.01932,
    "state_id": "CA"
  },
  {
    "zip": 92065,
    "lat": 33.05146,
    "lng": -116.84562,
    "state_id": "CA"
  },
  {
    "zip": 92066,
    "lat": 33.21937,
    "lng": -116.53526,
    "state_id": "CA"
  },
  {
    "zip": 92067,
    "lat": 33.02141,
    "lng": -117.19383,
    "state_id": "CA"
  },
  {
    "zip": 92069,
    "lat": 33.17078,
    "lng": -117.15867,
    "state_id": "CA"
  },
  {
    "zip": 92070,
    "lat": 33.1594,
    "lng": -116.72662,
    "state_id": "CA"
  },
  {
    "zip": 92071,
    "lat": 32.85129,
    "lng": -116.99146,
    "state_id": "CA"
  },
  {
    "zip": 92075,
    "lat": 32.99738,
    "lng": -117.25844,
    "state_id": "CA"
  },
  {
    "zip": 92078,
    "lat": 33.11927,
    "lng": -117.18505,
    "state_id": "CA"
  },
  {
    "zip": 92081,
    "lat": 33.16431,
    "lng": -117.24028,
    "state_id": "CA"
  },
  {
    "zip": 92082,
    "lat": 33.25079,
    "lng": -117.00049,
    "state_id": "CA"
  },
  {
    "zip": 92083,
    "lat": 33.19787,
    "lng": -117.24825,
    "state_id": "CA"
  },
  {
    "zip": 92084,
    "lat": 33.22218,
    "lng": -117.20537,
    "state_id": "CA"
  },
  {
    "zip": 92086,
    "lat": 33.30957,
    "lng": -116.65268,
    "state_id": "CA"
  },
  {
    "zip": 92091,
    "lat": 33.01093,
    "lng": -117.21135,
    "state_id": "CA"
  },
  {
    "zip": 92101,
    "lat": 32.72396,
    "lng": -117.17072,
    "state_id": "CA"
  },
  {
    "zip": 92102,
    "lat": 32.71621,
    "lng": -117.11704,
    "state_id": "CA"
  },
  {
    "zip": 92103,
    "lat": 32.74742,
    "lng": -117.16688,
    "state_id": "CA"
  },
  {
    "zip": 92104,
    "lat": 32.74158,
    "lng": -117.12814,
    "state_id": "CA"
  },
  {
    "zip": 92105,
    "lat": 32.73785,
    "lng": -117.09268,
    "state_id": "CA"
  },
  {
    "zip": 92106,
    "lat": 32.71272,
    "lng": -117.23605,
    "state_id": "CA"
  },
  {
    "zip": 92107,
    "lat": 32.73981,
    "lng": -117.24372,
    "state_id": "CA"
  },
  {
    "zip": 92108,
    "lat": 32.77389,
    "lng": -117.14247,
    "state_id": "CA"
  },
  {
    "zip": 92109,
    "lat": 32.78699,
    "lng": -117.23324,
    "state_id": "CA"
  },
  {
    "zip": 92110,
    "lat": 32.76519,
    "lng": -117.1999,
    "state_id": "CA"
  },
  {
    "zip": 92111,
    "lat": 32.80648,
    "lng": -117.16887,
    "state_id": "CA"
  },
  {
    "zip": 92113,
    "lat": 32.69602,
    "lng": -117.11817,
    "state_id": "CA"
  },
  {
    "zip": 92114,
    "lat": 32.70776,
    "lng": -117.0551,
    "state_id": "CA"
  },
  {
    "zip": 92115,
    "lat": 32.76139,
    "lng": -117.07173,
    "state_id": "CA"
  },
  {
    "zip": 92116,
    "lat": 32.76496,
    "lng": -117.12281,
    "state_id": "CA"
  },
  {
    "zip": 92117,
    "lat": 32.82461,
    "lng": -117.19954,
    "state_id": "CA"
  },
  {
    "zip": 92118,
    "lat": 32.67487,
    "lng": -117.17866,
    "state_id": "CA"
  },
  {
    "zip": 92119,
    "lat": 32.8101,
    "lng": -117.03279,
    "state_id": "CA"
  },
  {
    "zip": 92120,
    "lat": 32.79468,
    "lng": -117.07114,
    "state_id": "CA"
  },
  {
    "zip": 92121,
    "lat": 32.89918,
    "lng": -117.20233,
    "state_id": "CA"
  },
  {
    "zip": 92122,
    "lat": 32.85926,
    "lng": -117.17007,
    "state_id": "CA"
  },
  {
    "zip": 92123,
    "lat": 32.80675,
    "lng": -117.13495,
    "state_id": "CA"
  },
  {
    "zip": 92124,
    "lat": 32.82654,
    "lng": -117.08602,
    "state_id": "CA"
  },
  {
    "zip": 92126,
    "lat": 32.90847,
    "lng": -117.1414,
    "state_id": "CA"
  },
  {
    "zip": 92127,
    "lat": 33.0198,
    "lng": -117.12385,
    "state_id": "CA"
  },
  {
    "zip": 92128,
    "lat": 32.99937,
    "lng": -117.07178,
    "state_id": "CA"
  },
  {
    "zip": 92129,
    "lat": 32.96486,
    "lng": -117.12604,
    "state_id": "CA"
  },
  {
    "zip": 92130,
    "lat": 32.94759,
    "lng": -117.20741,
    "state_id": "CA"
  },
  {
    "zip": 92131,
    "lat": 32.89435,
    "lng": -117.08011,
    "state_id": "CA"
  },
  {
    "zip": 92132,
    "lat": 32.71368,
    "lng": -117.17201,
    "state_id": "CA"
  },
  {
    "zip": 92134,
    "lat": 32.72425,
    "lng": -117.14671,
    "state_id": "CA"
  },
  {
    "zip": 92135,
    "lat": 32.69673,
    "lng": -117.19328,
    "state_id": "CA"
  },
  {
    "zip": 92139,
    "lat": 32.67991,
    "lng": -117.04891,
    "state_id": "CA"
  },
  {
    "zip": 92140,
    "lat": 32.7401,
    "lng": -117.19796,
    "state_id": "CA"
  },
  {
    "zip": 92145,
    "lat": 32.9306,
    "lng": -117.00801,
    "state_id": "CA"
  },
  {
    "zip": 92147,
    "lat": 32.72458,
    "lng": -117.2193,
    "state_id": "CA"
  },
  {
    "zip": 92154,
    "lat": 32.56762,
    "lng": -117.00305,
    "state_id": "CA"
  },
  {
    "zip": 92155,
    "lat": 32.67492,
    "lng": -117.16175,
    "state_id": "CA"
  },
  {
    "zip": 92173,
    "lat": 32.55403,
    "lng": -117.04239,
    "state_id": "CA"
  },
  {
    "zip": 92201,
    "lat": 33.69743,
    "lng": -116.10328,
    "state_id": "CA"
  },
  {
    "zip": 92203,
    "lat": 33.75395,
    "lng": -116.24649,
    "state_id": "CA"
  },
  {
    "zip": 92210,
    "lat": 33.70043,
    "lng": -116.34019,
    "state_id": "CA"
  },
  {
    "zip": 92211,
    "lat": 33.76218,
    "lng": -116.33102,
    "state_id": "CA"
  },
  {
    "zip": 92220,
    "lat": 33.94892,
    "lng": -116.8324,
    "state_id": "CA"
  },
  {
    "zip": 92222,
    "lat": 32.78577,
    "lng": -114.54552,
    "state_id": "CA"
  },
  {
    "zip": 92223,
    "lat": 33.94833,
    "lng": -116.98802,
    "state_id": "CA"
  },
  {
    "zip": 92225,
    "lat": 33.74646,
    "lng": -114.66793,
    "state_id": "CA"
  },
  {
    "zip": 92227,
    "lat": 32.99027,
    "lng": -115.34876,
    "state_id": "CA"
  },
  {
    "zip": 92230,
    "lat": 33.91058,
    "lng": -116.76598,
    "state_id": "CA"
  },
  {
    "zip": 92231,
    "lat": 32.68754,
    "lng": -115.54115,
    "state_id": "CA"
  },
  {
    "zip": 92233,
    "lat": 33.17378,
    "lng": -115.55954,
    "state_id": "CA"
  },
  {
    "zip": 92234,
    "lat": 33.81825,
    "lng": -116.46644,
    "state_id": "CA"
  },
  {
    "zip": 92236,
    "lat": 33.68615,
    "lng": -116.17291,
    "state_id": "CA"
  },
  {
    "zip": 92239,
    "lat": 33.76482,
    "lng": -115.47023,
    "state_id": "CA"
  },
  {
    "zip": 92240,
    "lat": 33.95314,
    "lng": -116.5219,
    "state_id": "CA"
  },
  {
    "zip": 92241,
    "lat": 33.85305,
    "lng": -116.30055,
    "state_id": "CA"
  },
  {
    "zip": 92242,
    "lat": 34.1652,
    "lng": -114.31961,
    "state_id": "CA"
  },
  {
    "zip": 92243,
    "lat": 32.77255,
    "lng": -115.59931,
    "state_id": "CA"
  },
  {
    "zip": 92249,
    "lat": 32.72559,
    "lng": -115.47265,
    "state_id": "CA"
  },
  {
    "zip": 92250,
    "lat": 32.82385,
    "lng": -115.23944,
    "state_id": "CA"
  },
  {
    "zip": 92251,
    "lat": 32.87227,
    "lng": -115.69,
    "state_id": "CA"
  },
  {
    "zip": 92252,
    "lat": 34.16837,
    "lng": -116.2884,
    "state_id": "CA"
  },
  {
    "zip": 92253,
    "lat": 33.65396,
    "lng": -116.27871,
    "state_id": "CA"
  },
  {
    "zip": 92254,
    "lat": 33.54474,
    "lng": -115.99414,
    "state_id": "CA"
  },
  {
    "zip": 92256,
    "lat": 34.07707,
    "lng": -116.6064,
    "state_id": "CA"
  },
  {
    "zip": 92257,
    "lat": 33.26444,
    "lng": -115.32343,
    "state_id": "CA"
  },
  {
    "zip": 92258,
    "lat": 33.91584,
    "lng": -116.55986,
    "state_id": "CA"
  },
  {
    "zip": 92259,
    "lat": 32.7377,
    "lng": -115.93223,
    "state_id": "CA"
  },
  {
    "zip": 92260,
    "lat": 33.70701,
    "lng": -116.40478,
    "state_id": "CA"
  },
  {
    "zip": 92262,
    "lat": 33.86055,
    "lng": -116.56152,
    "state_id": "CA"
  },
  {
    "zip": 92264,
    "lat": 33.73214,
    "lng": -116.50424,
    "state_id": "CA"
  },
  {
    "zip": 92266,
    "lat": 33.34535,
    "lng": -114.92183,
    "state_id": "CA"
  },
  {
    "zip": 92267,
    "lat": 34.27582,
    "lng": -114.19065,
    "state_id": "CA"
  },
  {
    "zip": 92268,
    "lat": 34.2221,
    "lng": -116.56421,
    "state_id": "CA"
  },
  {
    "zip": 92270,
    "lat": 33.76591,
    "lng": -116.42728,
    "state_id": "CA"
  },
  {
    "zip": 92273,
    "lat": 32.81777,
    "lng": -115.69995,
    "state_id": "CA"
  },
  {
    "zip": 92274,
    "lat": 33.46162,
    "lng": -116.08565,
    "state_id": "CA"
  },
  {
    "zip": 92275,
    "lat": 33.25184,
    "lng": -115.85659,
    "state_id": "CA"
  },
  {
    "zip": 92276,
    "lat": 33.82232,
    "lng": -116.36424,
    "state_id": "CA"
  },
  {
    "zip": 92277,
    "lat": 34.21531,
    "lng": -115.6222,
    "state_id": "CA"
  },
  {
    "zip": 92278,
    "lat": 34.22989,
    "lng": -116.05507,
    "state_id": "CA"
  },
  {
    "zip": 92280,
    "lat": 34.12557,
    "lng": -114.77794,
    "state_id": "CA"
  },
  {
    "zip": 92281,
    "lat": 33.05175,
    "lng": -115.60873,
    "state_id": "CA"
  },
  {
    "zip": 92282,
    "lat": 33.9655,
    "lng": -116.65872,
    "state_id": "CA"
  },
  {
    "zip": 92283,
    "lat": 32.93769,
    "lng": -114.7472,
    "state_id": "CA"
  },
  {
    "zip": 92284,
    "lat": 34.18604,
    "lng": -116.43234,
    "state_id": "CA"
  },
  {
    "zip": 92285,
    "lat": 34.34017,
    "lng": -116.54047,
    "state_id": "CA"
  },
  {
    "zip": 92301,
    "lat": 34.64464,
    "lng": -117.54086,
    "state_id": "CA"
  },
  {
    "zip": 92304,
    "lat": 34.54146,
    "lng": -115.64452,
    "state_id": "CA"
  },
  {
    "zip": 92305,
    "lat": 34.16326,
    "lng": -116.82868,
    "state_id": "CA"
  },
  {
    "zip": 92307,
    "lat": 34.58832,
    "lng": -117.13875,
    "state_id": "CA"
  },
  {
    "zip": 92308,
    "lat": 34.425,
    "lng": -117.15998,
    "state_id": "CA"
  },
  {
    "zip": 92309,
    "lat": 35.21647,
    "lng": -116.13003,
    "state_id": "CA"
  },
  {
    "zip": 92310,
    "lat": 35.26144,
    "lng": -116.69033,
    "state_id": "CA"
  },
  {
    "zip": 92311,
    "lat": 34.97828,
    "lng": -116.98115,
    "state_id": "CA"
  },
  {
    "zip": 92313,
    "lat": 34.031,
    "lng": -117.31288,
    "state_id": "CA"
  },
  {
    "zip": 92314,
    "lat": 34.26095,
    "lng": -116.81304,
    "state_id": "CA"
  },
  {
    "zip": 92315,
    "lat": 34.23501,
    "lng": -116.90509,
    "state_id": "CA"
  },
  {
    "zip": 92316,
    "lat": 34.05911,
    "lng": -117.39065,
    "state_id": "CA"
  },
  {
    "zip": 92320,
    "lat": 33.99042,
    "lng": -117.05205,
    "state_id": "CA"
  },
  {
    "zip": 92321,
    "lat": 34.25446,
    "lng": -117.15332,
    "state_id": "CA"
  },
  {
    "zip": 92322,
    "lat": 34.25437,
    "lng": -117.32647,
    "state_id": "CA"
  },
  {
    "zip": 92324,
    "lat": 34.03144,
    "lng": -117.28736,
    "state_id": "CA"
  },
  {
    "zip": 92325,
    "lat": 34.24332,
    "lng": -117.2811,
    "state_id": "CA"
  },
  {
    "zip": 92327,
    "lat": 34.86284,
    "lng": -116.86134,
    "state_id": "CA"
  },
  {
    "zip": 92328,
    "lat": 36.529,
    "lng": -116.87036,
    "state_id": "CA"
  },
  {
    "zip": 92332,
    "lat": 34.91269,
    "lng": -115.34169,
    "state_id": "CA"
  },
  {
    "zip": 92333,
    "lat": 34.27062,
    "lng": -116.94926,
    "state_id": "CA"
  },
  {
    "zip": 92335,
    "lat": 34.08717,
    "lng": -117.46549,
    "state_id": "CA"
  },
  {
    "zip": 92336,
    "lat": 34.14648,
    "lng": -117.46394,
    "state_id": "CA"
  },
  {
    "zip": 92337,
    "lat": 34.04981,
    "lng": -117.4706,
    "state_id": "CA"
  },
  {
    "zip": 92338,
    "lat": 34.78262,
    "lng": -116.19042,
    "state_id": "CA"
  },
  {
    "zip": 92339,
    "lat": 34.09364,
    "lng": -116.93627,
    "state_id": "CA"
  },
  {
    "zip": 92341,
    "lat": 34.23479,
    "lng": -117.06587,
    "state_id": "CA"
  },
  {
    "zip": 92342,
    "lat": 34.7584,
    "lng": -117.34944,
    "state_id": "CA"
  },
  {
    "zip": 92344,
    "lat": 34.39117,
    "lng": -117.40567,
    "state_id": "CA"
  },
  {
    "zip": 92345,
    "lat": 34.38212,
    "lng": -117.30939,
    "state_id": "CA"
  },
  {
    "zip": 92346,
    "lat": 34.12429,
    "lng": -117.18004,
    "state_id": "CA"
  },
  {
    "zip": 92347,
    "lat": 34.95443,
    "lng": -117.22236,
    "state_id": "CA"
  },
  {
    "zip": 92352,
    "lat": 34.26065,
    "lng": -117.20154,
    "state_id": "CA"
  },
  {
    "zip": 92354,
    "lat": 34.04977,
    "lng": -117.25154,
    "state_id": "CA"
  },
  {
    "zip": 92356,
    "lat": 34.5107,
    "lng": -116.89677,
    "state_id": "CA"
  },
  {
    "zip": 92358,
    "lat": 34.25923,
    "lng": -117.52095,
    "state_id": "CA"
  },
  {
    "zip": 92359,
    "lat": 34.08318,
    "lng": -117.06638,
    "state_id": "CA"
  },
  {
    "zip": 92363,
    "lat": 34.68325,
    "lng": -114.55044,
    "state_id": "CA"
  },
  {
    "zip": 92364,
    "lat": 35.34055,
    "lng": -115.42615,
    "state_id": "CA"
  },
  {
    "zip": 92365,
    "lat": 34.89977,
    "lng": -116.6491,
    "state_id": "CA"
  },
  {
    "zip": 92368,
    "lat": 34.65227,
    "lng": -117.33282,
    "state_id": "CA"
  },
  {
    "zip": 92371,
    "lat": 34.44269,
    "lng": -117.54186,
    "state_id": "CA"
  },
  {
    "zip": 92372,
    "lat": 34.44481,
    "lng": -117.62514,
    "state_id": "CA"
  },
  {
    "zip": 92373,
    "lat": 34.00495,
    "lng": -117.15067,
    "state_id": "CA"
  },
  {
    "zip": 92374,
    "lat": 34.06654,
    "lng": -117.17201,
    "state_id": "CA"
  },
  {
    "zip": 92376,
    "lat": 34.1112,
    "lng": -117.37884,
    "state_id": "CA"
  },
  {
    "zip": 92377,
    "lat": 34.15602,
    "lng": -117.40413,
    "state_id": "CA"
  },
  {
    "zip": 92378,
    "lat": 34.2261,
    "lng": -117.22605,
    "state_id": "CA"
  },
  {
    "zip": 92382,
    "lat": 34.20542,
    "lng": -117.11801,
    "state_id": "CA"
  },
  {
    "zip": 92384,
    "lat": 36.0125,
    "lng": -116.17791,
    "state_id": "CA"
  },
  {
    "zip": 92385,
    "lat": 34.21473,
    "lng": -117.19233,
    "state_id": "CA"
  },
  {
    "zip": 92386,
    "lat": 34.23721,
    "lng": -116.82765,
    "state_id": "CA"
  },
  {
    "zip": 92389,
    "lat": 35.90217,
    "lng": -116.15425,
    "state_id": "CA"
  },
  {
    "zip": 92391,
    "lat": 34.23799,
    "lng": -117.23481,
    "state_id": "CA"
  },
  {
    "zip": 92392,
    "lat": 34.48024,
    "lng": -117.4082,
    "state_id": "CA"
  },
  {
    "zip": 92394,
    "lat": 34.55629,
    "lng": -117.35281,
    "state_id": "CA"
  },
  {
    "zip": 92395,
    "lat": 34.50159,
    "lng": -117.29441,
    "state_id": "CA"
  },
  {
    "zip": 92397,
    "lat": 34.36701,
    "lng": -117.62729,
    "state_id": "CA"
  },
  {
    "zip": 92398,
    "lat": 34.91176,
    "lng": -116.8472,
    "state_id": "CA"
  },
  {
    "zip": 92399,
    "lat": 34.04282,
    "lng": -117.00516,
    "state_id": "CA"
  },
  {
    "zip": 92401,
    "lat": 34.10478,
    "lng": -117.29216,
    "state_id": "CA"
  },
  {
    "zip": 92404,
    "lat": 34.17837,
    "lng": -117.25146,
    "state_id": "CA"
  },
  {
    "zip": 92405,
    "lat": 34.1446,
    "lng": -117.30127,
    "state_id": "CA"
  },
  {
    "zip": 92407,
    "lat": 34.21658,
    "lng": -117.39082,
    "state_id": "CA"
  },
  {
    "zip": 92408,
    "lat": 34.08448,
    "lng": -117.26588,
    "state_id": "CA"
  },
  {
    "zip": 92410,
    "lat": 34.10688,
    "lng": -117.29742,
    "state_id": "CA"
  },
  {
    "zip": 92411,
    "lat": 34.12207,
    "lng": -117.32032,
    "state_id": "CA"
  },
  {
    "zip": 92501,
    "lat": 33.99542,
    "lng": -117.37361,
    "state_id": "CA"
  },
  {
    "zip": 92503,
    "lat": 33.88162,
    "lng": -117.44479,
    "state_id": "CA"
  },
  {
    "zip": 92504,
    "lat": 33.90247,
    "lng": -117.39662,
    "state_id": "CA"
  },
  {
    "zip": 92505,
    "lat": 33.93325,
    "lng": -117.49435,
    "state_id": "CA"
  },
  {
    "zip": 92506,
    "lat": 33.93434,
    "lng": -117.36737,
    "state_id": "CA"
  },
  {
    "zip": 92507,
    "lat": 33.97099,
    "lng": -117.32489,
    "state_id": "CA"
  },
  {
    "zip": 92508,
    "lat": 33.89113,
    "lng": -117.32676,
    "state_id": "CA"
  },
  {
    "zip": 92509,
    "lat": 34.00326,
    "lng": -117.44494,
    "state_id": "CA"
  },
  {
    "zip": 92518,
    "lat": 33.88869,
    "lng": -117.27768,
    "state_id": "CA"
  },
  {
    "zip": 92530,
    "lat": 33.65051,
    "lng": -117.3751,
    "state_id": "CA"
  },
  {
    "zip": 92532,
    "lat": 33.69279,
    "lng": -117.30327,
    "state_id": "CA"
  },
  {
    "zip": 92536,
    "lat": 33.49288,
    "lng": -116.83491,
    "state_id": "CA"
  },
  {
    "zip": 92539,
    "lat": 33.5135,
    "lng": -116.64385,
    "state_id": "CA"
  },
  {
    "zip": 92543,
    "lat": 33.69821,
    "lng": -116.97686,
    "state_id": "CA"
  },
  {
    "zip": 92544,
    "lat": 33.64626,
    "lng": -116.88199,
    "state_id": "CA"
  },
  {
    "zip": 92545,
    "lat": 33.72967,
    "lng": -117.03417,
    "state_id": "CA"
  },
  {
    "zip": 92548,
    "lat": 33.75866,
    "lng": -117.10946,
    "state_id": "CA"
  },
  {
    "zip": 92549,
    "lat": 33.77046,
    "lng": -116.7441,
    "state_id": "CA"
  },
  {
    "zip": 92551,
    "lat": 33.88145,
    "lng": -117.22614,
    "state_id": "CA"
  },
  {
    "zip": 92553,
    "lat": 33.92338,
    "lng": -117.24486,
    "state_id": "CA"
  },
  {
    "zip": 92555,
    "lat": 33.90109,
    "lng": -117.1184,
    "state_id": "CA"
  },
  {
    "zip": 92557,
    "lat": 33.9703,
    "lng": -117.25966,
    "state_id": "CA"
  },
  {
    "zip": 92561,
    "lat": 33.64095,
    "lng": -116.61995,
    "state_id": "CA"
  },
  {
    "zip": 92562,
    "lat": 33.55113,
    "lng": -117.34387,
    "state_id": "CA"
  },
  {
    "zip": 92563,
    "lat": 33.57996,
    "lng": -117.14579,
    "state_id": "CA"
  },
  {
    "zip": 92567,
    "lat": 33.81232,
    "lng": -117.1048,
    "state_id": "CA"
  },
  {
    "zip": 92570,
    "lat": 33.7852,
    "lng": -117.31662,
    "state_id": "CA"
  },
  {
    "zip": 92571,
    "lat": 33.82427,
    "lng": -117.20462,
    "state_id": "CA"
  },
  {
    "zip": 92582,
    "lat": 33.80584,
    "lng": -117.01947,
    "state_id": "CA"
  },
  {
    "zip": 92583,
    "lat": 33.79684,
    "lng": -116.93249,
    "state_id": "CA"
  },
  {
    "zip": 92584,
    "lat": 33.6616,
    "lng": -117.1755,
    "state_id": "CA"
  },
  {
    "zip": 92585,
    "lat": 33.74669,
    "lng": -117.17214,
    "state_id": "CA"
  },
  {
    "zip": 92586,
    "lat": 33.70901,
    "lng": -117.19889,
    "state_id": "CA"
  },
  {
    "zip": 92587,
    "lat": 33.69509,
    "lng": -117.25294,
    "state_id": "CA"
  },
  {
    "zip": 92590,
    "lat": 33.48466,
    "lng": -117.22262,
    "state_id": "CA"
  },
  {
    "zip": 92591,
    "lat": 33.53523,
    "lng": -117.10482,
    "state_id": "CA"
  },
  {
    "zip": 92592,
    "lat": 33.5111,
    "lng": -117.03089,
    "state_id": "CA"
  },
  {
    "zip": 92595,
    "lat": 33.61787,
    "lng": -117.2593,
    "state_id": "CA"
  },
  {
    "zip": 92596,
    "lat": 33.64367,
    "lng": -117.07644,
    "state_id": "CA"
  },
  {
    "zip": 92602,
    "lat": 33.74582,
    "lng": -117.74867,
    "state_id": "CA"
  },
  {
    "zip": 92603,
    "lat": 33.62447,
    "lng": -117.78875,
    "state_id": "CA"
  },
  {
    "zip": 92604,
    "lat": 33.68814,
    "lng": -117.78888,
    "state_id": "CA"
  },
  {
    "zip": 92606,
    "lat": 33.70043,
    "lng": -117.81724,
    "state_id": "CA"
  },
  {
    "zip": 92610,
    "lat": 33.6961,
    "lng": -117.67606,
    "state_id": "CA"
  },
  {
    "zip": 92612,
    "lat": 33.66077,
    "lng": -117.82642,
    "state_id": "CA"
  },
  {
    "zip": 92614,
    "lat": 33.68165,
    "lng": -117.83293,
    "state_id": "CA"
  },
  {
    "zip": 92617,
    "lat": 33.6425,
    "lng": -117.84169,
    "state_id": "CA"
  },
  {
    "zip": 92618,
    "lat": 33.66816,
    "lng": -117.73421,
    "state_id": "CA"
  },
  {
    "zip": 92620,
    "lat": 33.7126,
    "lng": -117.75069,
    "state_id": "CA"
  },
  {
    "zip": 92624,
    "lat": 33.45927,
    "lng": -117.66566,
    "state_id": "CA"
  },
  {
    "zip": 92625,
    "lat": 33.60216,
    "lng": -117.86558,
    "state_id": "CA"
  },
  {
    "zip": 92626,
    "lat": 33.67893,
    "lng": -117.90842,
    "state_id": "CA"
  },
  {
    "zip": 92627,
    "lat": 33.64788,
    "lng": -117.92044,
    "state_id": "CA"
  },
  {
    "zip": 92629,
    "lat": 33.47671,
    "lng": -117.70459,
    "state_id": "CA"
  },
  {
    "zip": 92630,
    "lat": 33.64467,
    "lng": -117.68518,
    "state_id": "CA"
  },
  {
    "zip": 92637,
    "lat": 33.61015,
    "lng": -117.73166,
    "state_id": "CA"
  },
  {
    "zip": 92646,
    "lat": 33.66538,
    "lng": -117.96762,
    "state_id": "CA"
  },
  {
    "zip": 92647,
    "lat": 33.72342,
    "lng": -118.00667,
    "state_id": "CA"
  },
  {
    "zip": 92648,
    "lat": 33.68244,
    "lng": -118.01244,
    "state_id": "CA"
  },
  {
    "zip": 92649,
    "lat": 33.72635,
    "lng": -118.05096,
    "state_id": "CA"
  },
  {
    "zip": 92651,
    "lat": 33.55926,
    "lng": -117.77647,
    "state_id": "CA"
  },
  {
    "zip": 92653,
    "lat": 33.59159,
    "lng": -117.69847,
    "state_id": "CA"
  },
  {
    "zip": 92655,
    "lat": 33.74514,
    "lng": -117.98493,
    "state_id": "CA"
  },
  {
    "zip": 92656,
    "lat": 33.57622,
    "lng": -117.73039,
    "state_id": "CA"
  },
  {
    "zip": 92657,
    "lat": 33.59561,
    "lng": -117.83209,
    "state_id": "CA"
  },
  {
    "zip": 92660,
    "lat": 33.63374,
    "lng": -117.87449,
    "state_id": "CA"
  },
  {
    "zip": 92661,
    "lat": 33.60262,
    "lng": -117.90038,
    "state_id": "CA"
  },
  {
    "zip": 92662,
    "lat": 33.60547,
    "lng": -117.89204,
    "state_id": "CA"
  },
  {
    "zip": 92663,
    "lat": 33.62365,
    "lng": -117.93046,
    "state_id": "CA"
  },
  {
    "zip": 92672,
    "lat": 33.42989,
    "lng": -117.60933,
    "state_id": "CA"
  },
  {
    "zip": 92673,
    "lat": 33.46688,
    "lng": -117.61209,
    "state_id": "CA"
  },
  {
    "zip": 92675,
    "lat": 33.50209,
    "lng": -117.60457,
    "state_id": "CA"
  },
  {
    "zip": 92676,
    "lat": 33.75037,
    "lng": -117.63463,
    "state_id": "CA"
  },
  {
    "zip": 92677,
    "lat": 33.52765,
    "lng": -117.7052,
    "state_id": "CA"
  },
  {
    "zip": 92678,
    "lat": 33.68469,
    "lng": -117.52655,
    "state_id": "CA"
  },
  {
    "zip": 92679,
    "lat": 33.63389,
    "lng": -117.58962,
    "state_id": "CA"
  },
  {
    "zip": 92683,
    "lat": 33.75252,
    "lng": -117.99406,
    "state_id": "CA"
  },
  {
    "zip": 92688,
    "lat": 33.62008,
    "lng": -117.61231,
    "state_id": "CA"
  },
  {
    "zip": 92691,
    "lat": 33.61064,
    "lng": -117.66626,
    "state_id": "CA"
  },
  {
    "zip": 92692,
    "lat": 33.60678,
    "lng": -117.64304,
    "state_id": "CA"
  },
  {
    "zip": 92694,
    "lat": 33.54724,
    "lng": -117.62373,
    "state_id": "CA"
  },
  {
    "zip": 92701,
    "lat": 33.74819,
    "lng": -117.85844,
    "state_id": "CA"
  },
  {
    "zip": 92703,
    "lat": 33.7487,
    "lng": -117.90607,
    "state_id": "CA"
  },
  {
    "zip": 92704,
    "lat": 33.72052,
    "lng": -117.90808,
    "state_id": "CA"
  },
  {
    "zip": 92705,
    "lat": 33.75566,
    "lng": -117.81639,
    "state_id": "CA"
  },
  {
    "zip": 92706,
    "lat": 33.76591,
    "lng": -117.8822,
    "state_id": "CA"
  },
  {
    "zip": 92707,
    "lat": 33.70986,
    "lng": -117.87089,
    "state_id": "CA"
  },
  {
    "zip": 92708,
    "lat": 33.71052,
    "lng": -117.9512,
    "state_id": "CA"
  },
  {
    "zip": 92780,
    "lat": 33.73402,
    "lng": -117.81916,
    "state_id": "CA"
  },
  {
    "zip": 92782,
    "lat": 33.7397,
    "lng": -117.78471,
    "state_id": "CA"
  },
  {
    "zip": 92801,
    "lat": 33.84444,
    "lng": -117.95222,
    "state_id": "CA"
  },
  {
    "zip": 92802,
    "lat": 33.80828,
    "lng": -117.92367,
    "state_id": "CA"
  },
  {
    "zip": 92804,
    "lat": 33.81826,
    "lng": -117.97506,
    "state_id": "CA"
  },
  {
    "zip": 92805,
    "lat": 33.83038,
    "lng": -117.90578,
    "state_id": "CA"
  },
  {
    "zip": 92806,
    "lat": 33.83856,
    "lng": -117.87071,
    "state_id": "CA"
  },
  {
    "zip": 92807,
    "lat": 33.84969,
    "lng": -117.78924,
    "state_id": "CA"
  },
  {
    "zip": 92808,
    "lat": 33.85647,
    "lng": -117.74013,
    "state_id": "CA"
  },
  {
    "zip": 92821,
    "lat": 33.92738,
    "lng": -117.8852,
    "state_id": "CA"
  },
  {
    "zip": 92823,
    "lat": 33.92942,
    "lng": -117.80683,
    "state_id": "CA"
  },
  {
    "zip": 92831,
    "lat": 33.87973,
    "lng": -117.89635,
    "state_id": "CA"
  },
  {
    "zip": 92832,
    "lat": 33.86859,
    "lng": -117.92897,
    "state_id": "CA"
  },
  {
    "zip": 92833,
    "lat": 33.87927,
    "lng": -117.96172,
    "state_id": "CA"
  },
  {
    "zip": 92835,
    "lat": 33.90187,
    "lng": -117.91735,
    "state_id": "CA"
  },
  {
    "zip": 92840,
    "lat": 33.78586,
    "lng": -117.93226,
    "state_id": "CA"
  },
  {
    "zip": 92841,
    "lat": 33.78689,
    "lng": -117.98195,
    "state_id": "CA"
  },
  {
    "zip": 92843,
    "lat": 33.76403,
    "lng": -117.93141,
    "state_id": "CA"
  },
  {
    "zip": 92844,
    "lat": 33.76543,
    "lng": -117.96945,
    "state_id": "CA"
  },
  {
    "zip": 92845,
    "lat": 33.78308,
    "lng": -118.02625,
    "state_id": "CA"
  },
  {
    "zip": 92860,
    "lat": 33.92467,
    "lng": -117.55166,
    "state_id": "CA"
  },
  {
    "zip": 92861,
    "lat": 33.81773,
    "lng": -117.81012,
    "state_id": "CA"
  },
  {
    "zip": 92865,
    "lat": 33.82887,
    "lng": -117.84905,
    "state_id": "CA"
  },
  {
    "zip": 92866,
    "lat": 33.78463,
    "lng": -117.84471,
    "state_id": "CA"
  },
  {
    "zip": 92867,
    "lat": 33.81575,
    "lng": -117.82213,
    "state_id": "CA"
  },
  {
    "zip": 92868,
    "lat": 33.78813,
    "lng": -117.87629,
    "state_id": "CA"
  },
  {
    "zip": 92869,
    "lat": 33.796,
    "lng": -117.78665,
    "state_id": "CA"
  },
  {
    "zip": 92870,
    "lat": 33.88079,
    "lng": -117.85518,
    "state_id": "CA"
  },
  {
    "zip": 92879,
    "lat": 33.87976,
    "lng": -117.53548,
    "state_id": "CA"
  },
  {
    "zip": 92880,
    "lat": 33.92078,
    "lng": -117.6097,
    "state_id": "CA"
  },
  {
    "zip": 92881,
    "lat": 33.82399,
    "lng": -117.51996,
    "state_id": "CA"
  },
  {
    "zip": 92882,
    "lat": 33.84194,
    "lng": -117.60424,
    "state_id": "CA"
  },
  {
    "zip": 92883,
    "lat": 33.75413,
    "lng": -117.47393,
    "state_id": "CA"
  },
  {
    "zip": 92886,
    "lat": 33.89646,
    "lng": -117.79716,
    "state_id": "CA"
  },
  {
    "zip": 92887,
    "lat": 33.88471,
    "lng": -117.73124,
    "state_id": "CA"
  },
  {
    "zip": 93001,
    "lat": 34.19219,
    "lng": -119.50794,
    "state_id": "CA"
  },
  {
    "zip": 93003,
    "lat": 34.2846,
    "lng": -119.22222,
    "state_id": "CA"
  },
  {
    "zip": 93004,
    "lat": 34.27886,
    "lng": -119.16549,
    "state_id": "CA"
  },
  {
    "zip": 93010,
    "lat": 34.22983,
    "lng": -119.07464,
    "state_id": "CA"
  },
  {
    "zip": 93012,
    "lat": 34.20328,
    "lng": -118.99795,
    "state_id": "CA"
  },
  {
    "zip": 93013,
    "lat": 34.44012,
    "lng": -119.49416,
    "state_id": "CA"
  },
  {
    "zip": 93015,
    "lat": 34.40574,
    "lng": -118.89657,
    "state_id": "CA"
  },
  {
    "zip": 93021,
    "lat": 34.30349,
    "lng": -118.8842,
    "state_id": "CA"
  },
  {
    "zip": 93022,
    "lat": 34.40734,
    "lng": -119.30566,
    "state_id": "CA"
  },
  {
    "zip": 93023,
    "lat": 34.52392,
    "lng": -119.2744,
    "state_id": "CA"
  },
  {
    "zip": 93030,
    "lat": 34.2053,
    "lng": -119.17482,
    "state_id": "CA"
  },
  {
    "zip": 93033,
    "lat": 34.15212,
    "lng": -119.12648,
    "state_id": "CA"
  },
  {
    "zip": 93035,
    "lat": 34.18358,
    "lng": -119.22179,
    "state_id": "CA"
  },
  {
    "zip": 93036,
    "lat": 34.23562,
    "lng": -119.1804,
    "state_id": "CA"
  },
  {
    "zip": 93040,
    "lat": 34.48181,
    "lng": -118.79237,
    "state_id": "CA"
  },
  {
    "zip": 93041,
    "lat": 34.13103,
    "lng": -119.14669,
    "state_id": "CA"
  },
  {
    "zip": 93042,
    "lat": 33.2479,
    "lng": -119.5067,
    "state_id": "CA"
  },
  {
    "zip": 93043,
    "lat": 34.17084,
    "lng": -119.20239,
    "state_id": "CA"
  },
  {
    "zip": 93060,
    "lat": 34.40168,
    "lng": -119.09935,
    "state_id": "CA"
  },
  {
    "zip": 93063,
    "lat": 34.3046,
    "lng": -118.68443,
    "state_id": "CA"
  },
  {
    "zip": 93064,
    "lat": 34.24755,
    "lng": -118.70095,
    "state_id": "CA"
  },
  {
    "zip": 93065,
    "lat": 34.26022,
    "lng": -118.77436,
    "state_id": "CA"
  },
  {
    "zip": 93066,
    "lat": 34.28856,
    "lng": -119.0295,
    "state_id": "CA"
  },
  {
    "zip": 93067,
    "lat": 34.42182,
    "lng": -119.59312,
    "state_id": "CA"
  },
  {
    "zip": 93101,
    "lat": 34.41898,
    "lng": -119.70923,
    "state_id": "CA"
  },
  {
    "zip": 93103,
    "lat": 34.438,
    "lng": -119.68057,
    "state_id": "CA"
  },
  {
    "zip": 93105,
    "lat": 34.53229,
    "lng": -119.79968,
    "state_id": "CA"
  },
  {
    "zip": 93108,
    "lat": 34.44816,
    "lng": -119.62043,
    "state_id": "CA"
  },
  {
    "zip": 93109,
    "lat": 34.40649,
    "lng": -119.72656,
    "state_id": "CA"
  },
  {
    "zip": 93110,
    "lat": 34.44804,
    "lng": -119.76248,
    "state_id": "CA"
  },
  {
    "zip": 93111,
    "lat": 34.44987,
    "lng": -119.80353,
    "state_id": "CA"
  },
  {
    "zip": 93117,
    "lat": 34.49127,
    "lng": -120.08221,
    "state_id": "CA"
  },
  {
    "zip": 93201,
    "lat": 35.85875,
    "lng": -119.48758,
    "state_id": "CA"
  },
  {
    "zip": 93202,
    "lat": 36.31335,
    "lng": -119.70853,
    "state_id": "CA"
  },
  {
    "zip": 93203,
    "lat": 35.1119,
    "lng": -118.83079,
    "state_id": "CA"
  },
  {
    "zip": 93204,
    "lat": 35.92646,
    "lng": -120.08639,
    "state_id": "CA"
  },
  {
    "zip": 93205,
    "lat": 35.52026,
    "lng": -118.42508,
    "state_id": "CA"
  },
  {
    "zip": 93206,
    "lat": 35.44108,
    "lng": -119.47436,
    "state_id": "CA"
  },
  {
    "zip": 93207,
    "lat": 35.88985,
    "lng": -118.63056,
    "state_id": "CA"
  },
  {
    "zip": 93208,
    "lat": 36.05663,
    "lng": -118.56848,
    "state_id": "CA"
  },
  {
    "zip": 93210,
    "lat": 36.19943,
    "lng": -120.4231,
    "state_id": "CA"
  },
  {
    "zip": 93212,
    "lat": 36.04207,
    "lng": -119.5277,
    "state_id": "CA"
  },
  {
    "zip": 93215,
    "lat": 35.77636,
    "lng": -119.1979,
    "state_id": "CA"
  },
  {
    "zip": 93218,
    "lat": 35.8609,
    "lng": -119.03612,
    "state_id": "CA"
  },
  {
    "zip": 93219,
    "lat": 35.87439,
    "lng": -119.28097,
    "state_id": "CA"
  },
  {
    "zip": 93220,
    "lat": 35.3969,
    "lng": -118.75688,
    "state_id": "CA"
  },
  {
    "zip": 93221,
    "lat": 36.29501,
    "lng": -119.02605,
    "state_id": "CA"
  },
  {
    "zip": 93222,
    "lat": 34.84483,
    "lng": -119.1997,
    "state_id": "CA"
  },
  {
    "zip": 93223,
    "lat": 36.30456,
    "lng": -119.205,
    "state_id": "CA"
  },
  {
    "zip": 93224,
    "lat": 35.22818,
    "lng": -119.56929,
    "state_id": "CA"
  },
  {
    "zip": 93225,
    "lat": 34.7747,
    "lng": -119.01985,
    "state_id": "CA"
  },
  {
    "zip": 93226,
    "lat": 35.73667,
    "lng": -118.72873,
    "state_id": "CA"
  },
  {
    "zip": 93230,
    "lat": 36.28802,
    "lng": -119.62301,
    "state_id": "CA"
  },
  {
    "zip": 93234,
    "lat": 36.19126,
    "lng": -120.09009,
    "state_id": "CA"
  },
  {
    "zip": 93235,
    "lat": 36.38984,
    "lng": -119.2199,
    "state_id": "CA"
  },
  {
    "zip": 93238,
    "lat": 35.80228,
    "lng": -118.44215,
    "state_id": "CA"
  },
  {
    "zip": 93239,
    "lat": 35.97542,
    "lng": -119.94606,
    "state_id": "CA"
  },
  {
    "zip": 93240,
    "lat": 35.61359,
    "lng": -118.44267,
    "state_id": "CA"
  },
  {
    "zip": 93241,
    "lat": 35.25578,
    "lng": -118.91285,
    "state_id": "CA"
  },
  {
    "zip": 93242,
    "lat": 36.44207,
    "lng": -119.7277,
    "state_id": "CA"
  },
  {
    "zip": 93243,
    "lat": 34.77686,
    "lng": -118.80142,
    "state_id": "CA"
  },
  {
    "zip": 93244,
    "lat": 36.46691,
    "lng": -119.01558,
    "state_id": "CA"
  },
  {
    "zip": 93245,
    "lat": 36.29517,
    "lng": -119.82871,
    "state_id": "CA"
  },
  {
    "zip": 93247,
    "lat": 36.20457,
    "lng": -119.06992,
    "state_id": "CA"
  },
  {
    "zip": 93249,
    "lat": 35.68836,
    "lng": -119.87675,
    "state_id": "CA"
  },
  {
    "zip": 93250,
    "lat": 35.66575,
    "lng": -119.19747,
    "state_id": "CA"
  },
  {
    "zip": 93251,
    "lat": 35.36171,
    "lng": -119.69355,
    "state_id": "CA"
  },
  {
    "zip": 93252,
    "lat": 34.72503,
    "lng": -119.21492,
    "state_id": "CA"
  },
  {
    "zip": 93254,
    "lat": 34.94866,
    "lng": -119.77105,
    "state_id": "CA"
  },
  {
    "zip": 93255,
    "lat": 35.6824,
    "lng": -118.09555,
    "state_id": "CA"
  },
  {
    "zip": 93256,
    "lat": 35.96189,
    "lng": -119.31555,
    "state_id": "CA"
  },
  {
    "zip": 93257,
    "lat": 35.98376,
    "lng": -118.87178,
    "state_id": "CA"
  },
  {
    "zip": 93258,
    "lat": 36.05438,
    "lng": -119.15041,
    "state_id": "CA"
  },
  {
    "zip": 93260,
    "lat": 35.81723,
    "lng": -118.66912,
    "state_id": "CA"
  },
  {
    "zip": 93261,
    "lat": 35.80852,
    "lng": -119.12814,
    "state_id": "CA"
  },
  {
    "zip": 93262,
    "lat": 36.6008,
    "lng": -118.71097,
    "state_id": "CA"
  },
  {
    "zip": 93263,
    "lat": 35.4928,
    "lng": -119.28685,
    "state_id": "CA"
  },
  {
    "zip": 93265,
    "lat": 36.19567,
    "lng": -118.6826,
    "state_id": "CA"
  },
  {
    "zip": 93266,
    "lat": 36.15726,
    "lng": -119.86374,
    "state_id": "CA"
  },
  {
    "zip": 93267,
    "lat": 36.15106,
    "lng": -119.05196,
    "state_id": "CA"
  },
  {
    "zip": 93268,
    "lat": 35.16239,
    "lng": -119.42517,
    "state_id": "CA"
  },
  {
    "zip": 93270,
    "lat": 35.95241,
    "lng": -119.07285,
    "state_id": "CA"
  },
  {
    "zip": 93271,
    "lat": 36.45921,
    "lng": -118.77808,
    "state_id": "CA"
  },
  {
    "zip": 93272,
    "lat": 36.05086,
    "lng": -119.34644,
    "state_id": "CA"
  },
  {
    "zip": 93274,
    "lat": 36.18026,
    "lng": -119.36943,
    "state_id": "CA"
  },
  {
    "zip": 93276,
    "lat": 35.29937,
    "lng": -119.35868,
    "state_id": "CA"
  },
  {
    "zip": 93277,
    "lat": 36.29923,
    "lng": -119.381,
    "state_id": "CA"
  },
  {
    "zip": 93280,
    "lat": 35.64805,
    "lng": -119.44873,
    "state_id": "CA"
  },
  {
    "zip": 93283,
    "lat": 35.56954,
    "lng": -118.27648,
    "state_id": "CA"
  },
  {
    "zip": 93285,
    "lat": 35.71608,
    "lng": -118.50242,
    "state_id": "CA"
  },
  {
    "zip": 93286,
    "lat": 36.47024,
    "lng": -119.10553,
    "state_id": "CA"
  },
  {
    "zip": 93287,
    "lat": 35.72929,
    "lng": -118.93141,
    "state_id": "CA"
  },
  {
    "zip": 93291,
    "lat": 36.38959,
    "lng": -119.36857,
    "state_id": "CA"
  },
  {
    "zip": 93292,
    "lat": 36.37715,
    "lng": -119.22402,
    "state_id": "CA"
  },
  {
    "zip": 93301,
    "lat": 35.38451,
    "lng": -119.02082,
    "state_id": "CA"
  },
  {
    "zip": 93304,
    "lat": 35.33973,
    "lng": -119.02344,
    "state_id": "CA"
  },
  {
    "zip": 93305,
    "lat": 35.38979,
    "lng": -118.98518,
    "state_id": "CA"
  },
  {
    "zip": 93306,
    "lat": 35.46364,
    "lng": -118.78979,
    "state_id": "CA"
  },
  {
    "zip": 93307,
    "lat": 35.24849,
    "lng": -118.93629,
    "state_id": "CA"
  },
  {
    "zip": 93308,
    "lat": 35.54752,
    "lng": -118.93568,
    "state_id": "CA"
  },
  {
    "zip": 93309,
    "lat": 35.3436,
    "lng": -119.06633,
    "state_id": "CA"
  },
  {
    "zip": 93311,
    "lat": 35.19414,
    "lng": -119.17444,
    "state_id": "CA"
  },
  {
    "zip": 93312,
    "lat": 35.39347,
    "lng": -119.12045,
    "state_id": "CA"
  },
  {
    "zip": 93313,
    "lat": 35.16327,
    "lng": -119.04631,
    "state_id": "CA"
  },
  {
    "zip": 93314,
    "lat": 35.38792,
    "lng": -119.23632,
    "state_id": "CA"
  },
  {
    "zip": 93401,
    "lat": 35.24175,
    "lng": -120.61711,
    "state_id": "CA"
  },
  {
    "zip": 93402,
    "lat": 35.28956,
    "lng": -120.83723,
    "state_id": "CA"
  },
  {
    "zip": 93405,
    "lat": 35.29784,
    "lng": -120.72718,
    "state_id": "CA"
  },
  {
    "zip": 93410,
    "lat": 35.30126,
    "lng": -120.66088,
    "state_id": "CA"
  },
  {
    "zip": 93420,
    "lat": 35.16613,
    "lng": -120.46507,
    "state_id": "CA"
  },
  {
    "zip": 93422,
    "lat": 35.46459,
    "lng": -120.69045,
    "state_id": "CA"
  },
  {
    "zip": 93424,
    "lat": 35.19,
    "lng": -120.73219,
    "state_id": "CA"
  },
  {
    "zip": 93426,
    "lat": 35.84641,
    "lng": -120.94132,
    "state_id": "CA"
  },
  {
    "zip": 93427,
    "lat": 34.60889,
    "lng": -120.22383,
    "state_id": "CA"
  },
  {
    "zip": 93428,
    "lat": 35.59032,
    "lng": -121.02929,
    "state_id": "CA"
  },
  {
    "zip": 93429,
    "lat": 34.86537,
    "lng": -120.53652,
    "state_id": "CA"
  },
  {
    "zip": 93430,
    "lat": 35.49234,
    "lng": -120.92555,
    "state_id": "CA"
  },
  {
    "zip": 93432,
    "lat": 35.47194,
    "lng": -120.47107,
    "state_id": "CA"
  },
  {
    "zip": 93433,
    "lat": 35.12059,
    "lng": -120.61911,
    "state_id": "CA"
  },
  {
    "zip": 93434,
    "lat": 34.9226,
    "lng": -120.60163,
    "state_id": "CA"
  },
  {
    "zip": 93436,
    "lat": 34.60558,
    "lng": -120.39708,
    "state_id": "CA"
  },
  {
    "zip": 93437,
    "lat": 34.73737,
    "lng": -120.54348,
    "state_id": "CA"
  },
  {
    "zip": 93440,
    "lat": 34.72943,
    "lng": -120.24455,
    "state_id": "CA"
  },
  {
    "zip": 93441,
    "lat": 34.74779,
    "lng": -120.03937,
    "state_id": "CA"
  },
  {
    "zip": 93442,
    "lat": 35.40216,
    "lng": -120.80287,
    "state_id": "CA"
  },
  {
    "zip": 93444,
    "lat": 35.03846,
    "lng": -120.50425,
    "state_id": "CA"
  },
  {
    "zip": 93445,
    "lat": 35.03049,
    "lng": -120.62024,
    "state_id": "CA"
  },
  {
    "zip": 93446,
    "lat": 35.66353,
    "lng": -120.73097,
    "state_id": "CA"
  },
  {
    "zip": 93449,
    "lat": 35.158,
    "lng": -120.65072,
    "state_id": "CA"
  },
  {
    "zip": 93450,
    "lat": 36.05641,
    "lng": -120.82381,
    "state_id": "CA"
  },
  {
    "zip": 93451,
    "lat": 35.90037,
    "lng": -120.59288,
    "state_id": "CA"
  },
  {
    "zip": 93452,
    "lat": 35.71049,
    "lng": -121.20413,
    "state_id": "CA"
  },
  {
    "zip": 93453,
    "lat": 35.34703,
    "lng": -120.19726,
    "state_id": "CA"
  },
  {
    "zip": 93454,
    "lat": 34.93666,
    "lng": -120.23478,
    "state_id": "CA"
  },
  {
    "zip": 93455,
    "lat": 34.82867,
    "lng": -120.42686,
    "state_id": "CA"
  },
  {
    "zip": 93458,
    "lat": 34.95709,
    "lng": -120.49063,
    "state_id": "CA"
  },
  {
    "zip": 93460,
    "lat": 34.64866,
    "lng": -120.02449,
    "state_id": "CA"
  },
  {
    "zip": 93461,
    "lat": 35.64703,
    "lng": -120.27328,
    "state_id": "CA"
  },
  {
    "zip": 93463,
    "lat": 34.62188,
    "lng": -120.14167,
    "state_id": "CA"
  },
  {
    "zip": 93465,
    "lat": 35.54014,
    "lng": -120.73303,
    "state_id": "CA"
  },
  {
    "zip": 93501,
    "lat": 35.06841,
    "lng": -118.1845,
    "state_id": "CA"
  },
  {
    "zip": 93505,
    "lat": 35.16646,
    "lng": -117.88815,
    "state_id": "CA"
  },
  {
    "zip": 93510,
    "lat": 34.46514,
    "lng": -118.21418,
    "state_id": "CA"
  },
  {
    "zip": 93512,
    "lat": 37.89263,
    "lng": -118.56464,
    "state_id": "CA"
  },
  {
    "zip": 93513,
    "lat": 37.11836,
    "lng": -118.13134,
    "state_id": "CA"
  },
  {
    "zip": 93514,
    "lat": 37.50147,
    "lng": -118.40493,
    "state_id": "CA"
  },
  {
    "zip": 93516,
    "lat": 35.00468,
    "lng": -117.63212,
    "state_id": "CA"
  },
  {
    "zip": 93517,
    "lat": 38.27358,
    "lng": -119.30296,
    "state_id": "CA"
  },
  {
    "zip": 93518,
    "lat": 35.37002,
    "lng": -118.46128,
    "state_id": "CA"
  },
  {
    "zip": 93519,
    "lat": 35.29748,
    "lng": -117.92937,
    "state_id": "CA"
  },
  {
    "zip": 93522,
    "lat": 36.29483,
    "lng": -117.59573,
    "state_id": "CA"
  },
  {
    "zip": 93523,
    "lat": 34.93098,
    "lng": -117.85026,
    "state_id": "CA"
  },
  {
    "zip": 93524,
    "lat": 34.93199,
    "lng": -117.90714,
    "state_id": "CA"
  },
  {
    "zip": 93526,
    "lat": 36.81063,
    "lng": -118.28513,
    "state_id": "CA"
  },
  {
    "zip": 93527,
    "lat": 36.04632,
    "lng": -118.17718,
    "state_id": "CA"
  },
  {
    "zip": 93528,
    "lat": 35.37158,
    "lng": -117.64335,
    "state_id": "CA"
  },
  {
    "zip": 93529,
    "lat": 37.80506,
    "lng": -119.09807,
    "state_id": "CA"
  },
  {
    "zip": 93530,
    "lat": 36.48085,
    "lng": -117.86892,
    "state_id": "CA"
  },
  {
    "zip": 93531,
    "lat": 35.21601,
    "lng": -118.58996,
    "state_id": "CA"
  },
  {
    "zip": 93532,
    "lat": 34.68472,
    "lng": -118.54415,
    "state_id": "CA"
  },
  {
    "zip": 93534,
    "lat": 34.71564,
    "lng": -118.1512,
    "state_id": "CA"
  },
  {
    "zip": 93535,
    "lat": 34.71307,
    "lng": -117.87823,
    "state_id": "CA"
  },
  {
    "zip": 93536,
    "lat": 34.74709,
    "lng": -118.36875,
    "state_id": "CA"
  },
  {
    "zip": 93541,
    "lat": 37.98192,
    "lng": -119.11567,
    "state_id": "CA"
  },
  {
    "zip": 93543,
    "lat": 34.48918,
    "lng": -117.97085,
    "state_id": "CA"
  },
  {
    "zip": 93544,
    "lat": 34.49303,
    "lng": -117.75432,
    "state_id": "CA"
  },
  {
    "zip": 93545,
    "lat": 36.55786,
    "lng": -118.05704,
    "state_id": "CA"
  },
  {
    "zip": 93546,
    "lat": 37.58419,
    "lng": -118.8523,
    "state_id": "CA"
  },
  {
    "zip": 93549,
    "lat": 36.19817,
    "lng": -117.94901,
    "state_id": "CA"
  },
  {
    "zip": 93550,
    "lat": 34.41326,
    "lng": -118.09162,
    "state_id": "CA"
  },
  {
    "zip": 93551,
    "lat": 34.60169,
    "lng": -118.23102,
    "state_id": "CA"
  },
  {
    "zip": 93552,
    "lat": 34.57149,
    "lng": -118.02316,
    "state_id": "CA"
  },
  {
    "zip": 93553,
    "lat": 34.42244,
    "lng": -117.90553,
    "state_id": "CA"
  },
  {
    "zip": 93554,
    "lat": 35.41481,
    "lng": -117.76603,
    "state_id": "CA"
  },
  {
    "zip": 93555,
    "lat": 35.6075,
    "lng": -117.68503,
    "state_id": "CA"
  },
  {
    "zip": 93558,
    "lat": 35.34799,
    "lng": -117.62145,
    "state_id": "CA"
  },
  {
    "zip": 93560,
    "lat": 34.86642,
    "lng": -118.34068,
    "state_id": "CA"
  },
  {
    "zip": 93561,
    "lat": 35.11821,
    "lng": -118.49376,
    "state_id": "CA"
  },
  {
    "zip": 93562,
    "lat": 35.74814,
    "lng": -117.38067,
    "state_id": "CA"
  },
  {
    "zip": 93563,
    "lat": 34.3966,
    "lng": -117.76036,
    "state_id": "CA"
  },
  {
    "zip": 93591,
    "lat": 34.60184,
    "lng": -117.81218,
    "state_id": "CA"
  },
  {
    "zip": 93592,
    "lat": 35.93029,
    "lng": -117.26725,
    "state_id": "CA"
  },
  {
    "zip": 93601,
    "lat": 37.39375,
    "lng": -119.74444,
    "state_id": "CA"
  },
  {
    "zip": 93602,
    "lat": 37.03501,
    "lng": -119.31428,
    "state_id": "CA"
  },
  {
    "zip": 93603,
    "lat": 36.62048,
    "lng": -118.95871,
    "state_id": "CA"
  },
  {
    "zip": 93604,
    "lat": 37.39166,
    "lng": -119.47865,
    "state_id": "CA"
  },
  {
    "zip": 93605,
    "lat": 37.20312,
    "lng": -119.24942,
    "state_id": "CA"
  },
  {
    "zip": 93606,
    "lat": 36.80315,
    "lng": -120.01847,
    "state_id": "CA"
  },
  {
    "zip": 93608,
    "lat": 36.48691,
    "lng": -120.35799,
    "state_id": "CA"
  },
  {
    "zip": 93609,
    "lat": 36.52658,
    "lng": -119.8641,
    "state_id": "CA"
  },
  {
    "zip": 93610,
    "lat": 37.09232,
    "lng": -120.28778,
    "state_id": "CA"
  },
  {
    "zip": 93611,
    "lat": 36.8253,
    "lng": -119.68017,
    "state_id": "CA"
  },
  {
    "zip": 93612,
    "lat": 36.81483,
    "lng": -119.71057,
    "state_id": "CA"
  },
  {
    "zip": 93614,
    "lat": 37.20791,
    "lng": -119.73322,
    "state_id": "CA"
  },
  {
    "zip": 93615,
    "lat": 36.50334,
    "lng": -119.28633,
    "state_id": "CA"
  },
  {
    "zip": 93616,
    "lat": 36.65535,
    "lng": -119.59388,
    "state_id": "CA"
  },
  {
    "zip": 93618,
    "lat": 36.52102,
    "lng": -119.38943,
    "state_id": "CA"
  },
  {
    "zip": 93619,
    "lat": 36.9156,
    "lng": -119.57915,
    "state_id": "CA"
  },
  {
    "zip": 93620,
    "lat": 37.02486,
    "lng": -120.62881,
    "state_id": "CA"
  },
  {
    "zip": 93621,
    "lat": 36.81464,
    "lng": -119.1721,
    "state_id": "CA"
  },
  {
    "zip": 93622,
    "lat": 36.80934,
    "lng": -120.5792,
    "state_id": "CA"
  },
  {
    "zip": 93623,
    "lat": 37.49377,
    "lng": -119.64269,
    "state_id": "CA"
  },
  {
    "zip": 93624,
    "lat": 36.37876,
    "lng": -120.11625,
    "state_id": "CA"
  },
  {
    "zip": 93625,
    "lat": 36.62455,
    "lng": -119.67187,
    "state_id": "CA"
  },
  {
    "zip": 93626,
    "lat": 37.04218,
    "lng": -119.6807,
    "state_id": "CA"
  },
  {
    "zip": 93627,
    "lat": 36.50905,
    "lng": -120.08755,
    "state_id": "CA"
  },
  {
    "zip": 93628,
    "lat": 36.79197,
    "lng": -118.92513,
    "state_id": "CA"
  },
  {
    "zip": 93630,
    "lat": 36.7212,
    "lng": -120.12961,
    "state_id": "CA"
  },
  {
    "zip": 93631,
    "lat": 36.47563,
    "lng": -119.52084,
    "state_id": "CA"
  },
  {
    "zip": 93633,
    "lat": 36.79306,
    "lng": -118.72273,
    "state_id": "CA"
  },
  {
    "zip": 93634,
    "lat": 37.2076,
    "lng": -118.94222,
    "state_id": "CA"
  },
  {
    "zip": 93635,
    "lat": 37.06906,
    "lng": -120.84552,
    "state_id": "CA"
  },
  {
    "zip": 93636,
    "lat": 36.98231,
    "lng": -119.87311,
    "state_id": "CA"
  },
  {
    "zip": 93637,
    "lat": 36.91104,
    "lng": -120.18748,
    "state_id": "CA"
  },
  {
    "zip": 93638,
    "lat": 37.04019,
    "lng": -120.03353,
    "state_id": "CA"
  },
  {
    "zip": 93640,
    "lat": 36.67424,
    "lng": -120.44196,
    "state_id": "CA"
  },
  {
    "zip": 93641,
    "lat": 36.69905,
    "lng": -119.03191,
    "state_id": "CA"
  },
  {
    "zip": 93643,
    "lat": 37.22644,
    "lng": -119.4668,
    "state_id": "CA"
  },
  {
    "zip": 93644,
    "lat": 37.37857,
    "lng": -119.62883,
    "state_id": "CA"
  },
  {
    "zip": 93645,
    "lat": 37.17103,
    "lng": -119.64134,
    "state_id": "CA"
  },
  {
    "zip": 93646,
    "lat": 36.6447,
    "lng": -119.28916,
    "state_id": "CA"
  },
  {
    "zip": 93647,
    "lat": 36.59101,
    "lng": -119.16702,
    "state_id": "CA"
  },
  {
    "zip": 93648,
    "lat": 36.62061,
    "lng": -119.52077,
    "state_id": "CA"
  },
  {
    "zip": 93650,
    "lat": 36.84071,
    "lng": -119.79965,
    "state_id": "CA"
  },
  {
    "zip": 93651,
    "lat": 36.99198,
    "lng": -119.52392,
    "state_id": "CA"
  },
  {
    "zip": 93652,
    "lat": 36.59502,
    "lng": -119.90458,
    "state_id": "CA"
  },
  {
    "zip": 93653,
    "lat": 37.24955,
    "lng": -119.94468,
    "state_id": "CA"
  },
  {
    "zip": 93654,
    "lat": 36.65715,
    "lng": -119.40291,
    "state_id": "CA"
  },
  {
    "zip": 93656,
    "lat": 36.45516,
    "lng": -119.93595,
    "state_id": "CA"
  },
  {
    "zip": 93657,
    "lat": 36.78348,
    "lng": -119.44483,
    "state_id": "CA"
  },
  {
    "zip": 93660,
    "lat": 36.58284,
    "lng": -120.176,
    "state_id": "CA"
  },
  {
    "zip": 93662,
    "lat": 36.53927,
    "lng": -119.64197,
    "state_id": "CA"
  },
  {
    "zip": 93664,
    "lat": 37.0953,
    "lng": -119.21105,
    "state_id": "CA"
  },
  {
    "zip": 93665,
    "lat": 36.96018,
    "lng": -120.64814,
    "state_id": "CA"
  },
  {
    "zip": 93666,
    "lat": 36.54713,
    "lng": -119.33539,
    "state_id": "CA"
  },
  {
    "zip": 93667,
    "lat": 36.95577,
    "lng": -119.32515,
    "state_id": "CA"
  },
  {
    "zip": 93668,
    "lat": 36.64862,
    "lng": -120.28502,
    "state_id": "CA"
  },
  {
    "zip": 93669,
    "lat": 37.28276,
    "lng": -119.54215,
    "state_id": "CA"
  },
  {
    "zip": 93673,
    "lat": 36.45261,
    "lng": -119.48234,
    "state_id": "CA"
  },
  {
    "zip": 93675,
    "lat": 36.72283,
    "lng": -119.19346,
    "state_id": "CA"
  },
  {
    "zip": 93701,
    "lat": 36.74968,
    "lng": -119.78766,
    "state_id": "CA"
  },
  {
    "zip": 93702,
    "lat": 36.73923,
    "lng": -119.75391,
    "state_id": "CA"
  },
  {
    "zip": 93703,
    "lat": 36.7689,
    "lng": -119.76245,
    "state_id": "CA"
  },
  {
    "zip": 93704,
    "lat": 36.79909,
    "lng": -119.8016,
    "state_id": "CA"
  },
  {
    "zip": 93705,
    "lat": 36.78671,
    "lng": -119.82832,
    "state_id": "CA"
  },
  {
    "zip": 93706,
    "lat": 36.65209,
    "lng": -119.90659,
    "state_id": "CA"
  },
  {
    "zip": 93710,
    "lat": 36.82234,
    "lng": -119.76022,
    "state_id": "CA"
  },
  {
    "zip": 93711,
    "lat": 36.83459,
    "lng": -119.83117,
    "state_id": "CA"
  },
  {
    "zip": 93720,
    "lat": 36.86031,
    "lng": -119.76157,
    "state_id": "CA"
  },
  {
    "zip": 93721,
    "lat": 36.73291,
    "lng": -119.78374,
    "state_id": "CA"
  },
  {
    "zip": 93722,
    "lat": 36.80092,
    "lng": -119.87723,
    "state_id": "CA"
  },
  {
    "zip": 93723,
    "lat": 36.78624,
    "lng": -119.95324,
    "state_id": "CA"
  },
  {
    "zip": 93725,
    "lat": 36.62534,
    "lng": -119.73651,
    "state_id": "CA"
  },
  {
    "zip": 93726,
    "lat": 36.79367,
    "lng": -119.76145,
    "state_id": "CA"
  },
  {
    "zip": 93727,
    "lat": 36.75155,
    "lng": -119.6806,
    "state_id": "CA"
  },
  {
    "zip": 93728,
    "lat": 36.75727,
    "lng": -119.81834,
    "state_id": "CA"
  },
  {
    "zip": 93730,
    "lat": 36.90317,
    "lng": -119.75488,
    "state_id": "CA"
  },
  {
    "zip": 93901,
    "lat": 36.63863,
    "lng": -121.62401,
    "state_id": "CA"
  },
  {
    "zip": 93905,
    "lat": 36.68321,
    "lng": -121.60701,
    "state_id": "CA"
  },
  {
    "zip": 93906,
    "lat": 36.72258,
    "lng": -121.63228,
    "state_id": "CA"
  },
  {
    "zip": 93907,
    "lat": 36.76958,
    "lng": -121.66778,
    "state_id": "CA"
  },
  {
    "zip": 93908,
    "lat": 36.63429,
    "lng": -121.60685,
    "state_id": "CA"
  },
  {
    "zip": 93920,
    "lat": 36.11042,
    "lng": -121.56063,
    "state_id": "CA"
  },
  {
    "zip": 93921,
    "lat": 36.55396,
    "lng": -121.92296,
    "state_id": "CA"
  },
  {
    "zip": 93923,
    "lat": 36.45757,
    "lng": -121.84387,
    "state_id": "CA"
  },
  {
    "zip": 93924,
    "lat": 36.39891,
    "lng": -121.64066,
    "state_id": "CA"
  },
  {
    "zip": 93925,
    "lat": 36.60064,
    "lng": -121.43123,
    "state_id": "CA"
  },
  {
    "zip": 93926,
    "lat": 36.52922,
    "lng": -121.40769,
    "state_id": "CA"
  },
  {
    "zip": 93927,
    "lat": 36.23358,
    "lng": -121.37717,
    "state_id": "CA"
  },
  {
    "zip": 93928,
    "lat": 36.03241,
    "lng": -121.23953,
    "state_id": "CA"
  },
  {
    "zip": 93930,
    "lat": 36.21475,
    "lng": -121.03097,
    "state_id": "CA"
  },
  {
    "zip": 93932,
    "lat": 35.99153,
    "lng": -121.06232,
    "state_id": "CA"
  },
  {
    "zip": 93933,
    "lat": 36.68608,
    "lng": -121.787,
    "state_id": "CA"
  },
  {
    "zip": 93940,
    "lat": 36.57944,
    "lng": -121.84332,
    "state_id": "CA"
  },
  {
    "zip": 93943,
    "lat": 36.59706,
    "lng": -121.87413,
    "state_id": "CA"
  },
  {
    "zip": 93950,
    "lat": 36.61901,
    "lng": -121.92547,
    "state_id": "CA"
  },
  {
    "zip": 93953,
    "lat": 36.58571,
    "lng": -121.94389,
    "state_id": "CA"
  },
  {
    "zip": 93954,
    "lat": 36.13313,
    "lng": -120.94207,
    "state_id": "CA"
  },
  {
    "zip": 93955,
    "lat": 36.62116,
    "lng": -121.79275,
    "state_id": "CA"
  },
  {
    "zip": 93960,
    "lat": 36.39832,
    "lng": -121.34114,
    "state_id": "CA"
  },
  {
    "zip": 93962,
    "lat": 36.62471,
    "lng": -121.64649,
    "state_id": "CA"
  },
  {
    "zip": 94002,
    "lat": 37.5135,
    "lng": -122.29905,
    "state_id": "CA"
  },
  {
    "zip": 94005,
    "lat": 37.68872,
    "lng": -122.40798,
    "state_id": "CA"
  },
  {
    "zip": 94010,
    "lat": 37.56932,
    "lng": -122.3653,
    "state_id": "CA"
  },
  {
    "zip": 94014,
    "lat": 37.6909,
    "lng": -122.44745,
    "state_id": "CA"
  },
  {
    "zip": 94015,
    "lat": 37.68122,
    "lng": -122.48052,
    "state_id": "CA"
  },
  {
    "zip": 94019,
    "lat": 37.46472,
    "lng": -122.41624,
    "state_id": "CA"
  },
  {
    "zip": 94020,
    "lat": 37.28423,
    "lng": -122.22832,
    "state_id": "CA"
  },
  {
    "zip": 94021,
    "lat": 37.27091,
    "lng": -122.28065,
    "state_id": "CA"
  },
  {
    "zip": 94022,
    "lat": 37.35752,
    "lng": -122.14444,
    "state_id": "CA"
  },
  {
    "zip": 94024,
    "lat": 37.35221,
    "lng": -122.09438,
    "state_id": "CA"
  },
  {
    "zip": 94025,
    "lat": 37.46321,
    "lng": -122.17249,
    "state_id": "CA"
  },
  {
    "zip": 94027,
    "lat": 37.45321,
    "lng": -122.20406,
    "state_id": "CA"
  },
  {
    "zip": 94028,
    "lat": 37.37857,
    "lng": -122.21545,
    "state_id": "CA"
  },
  {
    "zip": 94030,
    "lat": 37.59972,
    "lng": -122.40305,
    "state_id": "CA"
  },
  {
    "zip": 94037,
    "lat": 37.55433,
    "lng": -122.49667,
    "state_id": "CA"
  },
  {
    "zip": 94038,
    "lat": 37.52184,
    "lng": -122.50633,
    "state_id": "CA"
  },
  {
    "zip": 94040,
    "lat": 37.38058,
    "lng": -122.08535,
    "state_id": "CA"
  },
  {
    "zip": 94041,
    "lat": 37.38847,
    "lng": -122.07564,
    "state_id": "CA"
  },
  {
    "zip": 94043,
    "lat": 37.41747,
    "lng": -122.07039,
    "state_id": "CA"
  },
  {
    "zip": 94044,
    "lat": 37.60544,
    "lng": -122.48104,
    "state_id": "CA"
  },
  {
    "zip": 94060,
    "lat": 37.22338,
    "lng": -122.33714,
    "state_id": "CA"
  },
  {
    "zip": 94061,
    "lat": 37.46159,
    "lng": -122.2368,
    "state_id": "CA"
  },
  {
    "zip": 94062,
    "lat": 37.42134,
    "lng": -122.30715,
    "state_id": "CA"
  },
  {
    "zip": 94063,
    "lat": 37.49484,
    "lng": -122.20804,
    "state_id": "CA"
  },
  {
    "zip": 94065,
    "lat": 37.53542,
    "lng": -122.24673,
    "state_id": "CA"
  },
  {
    "zip": 94066,
    "lat": 37.62508,
    "lng": -122.43358,
    "state_id": "CA"
  },
  {
    "zip": 94070,
    "lat": 37.49764,
    "lng": -122.27003,
    "state_id": "CA"
  },
  {
    "zip": 94074,
    "lat": 37.32588,
    "lng": -122.34979,
    "state_id": "CA"
  },
  {
    "zip": 94080,
    "lat": 37.65554,
    "lng": -122.42212,
    "state_id": "CA"
  },
  {
    "zip": 94085,
    "lat": 37.38854,
    "lng": -122.01776,
    "state_id": "CA"
  },
  {
    "zip": 94086,
    "lat": 37.37165,
    "lng": -122.02306,
    "state_id": "CA"
  },
  {
    "zip": 94087,
    "lat": 37.35154,
    "lng": -122.03688,
    "state_id": "CA"
  },
  {
    "zip": 94089,
    "lat": 37.41154,
    "lng": -122.01404,
    "state_id": "CA"
  },
  {
    "zip": 94102,
    "lat": 37.7797,
    "lng": -122.41924,
    "state_id": "CA"
  },
  {
    "zip": 94103,
    "lat": 37.77323,
    "lng": -122.41114,
    "state_id": "CA"
  },
  {
    "zip": 94104,
    "lat": 37.79146,
    "lng": -122.40207,
    "state_id": "CA"
  },
  {
    "zip": 94105,
    "lat": 37.78978,
    "lng": -122.39387,
    "state_id": "CA"
  },
  {
    "zip": 94107,
    "lat": 37.76643,
    "lng": -122.39461,
    "state_id": "CA"
  },
  {
    "zip": 94108,
    "lat": 37.79203,
    "lng": -122.40864,
    "state_id": "CA"
  },
  {
    "zip": 94109,
    "lat": 37.7952,
    "lng": -122.4222,
    "state_id": "CA"
  },
  {
    "zip": 94110,
    "lat": 37.74994,
    "lng": -122.41539,
    "state_id": "CA"
  },
  {
    "zip": 94111,
    "lat": 37.79891,
    "lng": -122.39834,
    "state_id": "CA"
  },
  {
    "zip": 94112,
    "lat": 37.72031,
    "lng": -122.4429,
    "state_id": "CA"
  },
  {
    "zip": 94114,
    "lat": 37.75802,
    "lng": -122.43545,
    "state_id": "CA"
  },
  {
    "zip": 94115,
    "lat": 37.78597,
    "lng": -122.43719,
    "state_id": "CA"
  },
  {
    "zip": 94116,
    "lat": 37.74459,
    "lng": -122.48619,
    "state_id": "CA"
  },
  {
    "zip": 94117,
    "lat": 37.76975,
    "lng": -122.44823,
    "state_id": "CA"
  },
  {
    "zip": 94118,
    "lat": 37.7801,
    "lng": -122.46246,
    "state_id": "CA"
  },
  {
    "zip": 94121,
    "lat": 37.77651,
    "lng": -122.49449,
    "state_id": "CA"
  },
  {
    "zip": 94122,
    "lat": 37.75878,
    "lng": -122.48512,
    "state_id": "CA"
  },
  {
    "zip": 94123,
    "lat": 37.80046,
    "lng": -122.43821,
    "state_id": "CA"
  },
  {
    "zip": 94124,
    "lat": 37.73082,
    "lng": -122.38463,
    "state_id": "CA"
  },
  {
    "zip": 94127,
    "lat": 37.7357,
    "lng": -122.45943,
    "state_id": "CA"
  },
  {
    "zip": 94128,
    "lat": 37.62216,
    "lng": -122.38478,
    "state_id": "CA"
  },
  {
    "zip": 94129,
    "lat": 37.79753,
    "lng": -122.46682,
    "state_id": "CA"
  },
  {
    "zip": 94130,
    "lat": 37.82048,
    "lng": -122.36952,
    "state_id": "CA"
  },
  {
    "zip": 94131,
    "lat": 37.74543,
    "lng": -122.44291,
    "state_id": "CA"
  },
  {
    "zip": 94132,
    "lat": 37.72213,
    "lng": -122.48481,
    "state_id": "CA"
  },
  {
    "zip": 94133,
    "lat": 37.80381,
    "lng": -122.41073,
    "state_id": "CA"
  },
  {
    "zip": 94134,
    "lat": 37.71941,
    "lng": -122.41178,
    "state_id": "CA"
  },
  {
    "zip": 94158,
    "lat": 37.76976,
    "lng": -122.3876,
    "state_id": "CA"
  },
  {
    "zip": 94301,
    "lat": 37.44412,
    "lng": -122.14995,
    "state_id": "CA"
  },
  {
    "zip": 94303,
    "lat": 37.45045,
    "lng": -122.11925,
    "state_id": "CA"
  },
  {
    "zip": 94304,
    "lat": 37.38187,
    "lng": -122.17392,
    "state_id": "CA"
  },
  {
    "zip": 94305,
    "lat": 37.41721,
    "lng": -122.17097,
    "state_id": "CA"
  },
  {
    "zip": 94306,
    "lat": 37.41571,
    "lng": -122.13078,
    "state_id": "CA"
  },
  {
    "zip": 94401,
    "lat": 37.57432,
    "lng": -122.31831,
    "state_id": "CA"
  },
  {
    "zip": 94402,
    "lat": 37.54105,
    "lng": -122.33317,
    "state_id": "CA"
  },
  {
    "zip": 94403,
    "lat": 37.53847,
    "lng": -122.30452,
    "state_id": "CA"
  },
  {
    "zip": 94404,
    "lat": 37.556,
    "lng": -122.26892,
    "state_id": "CA"
  },
  {
    "zip": 94501,
    "lat": 37.77516,
    "lng": -122.2748,
    "state_id": "CA"
  },
  {
    "zip": 94502,
    "lat": 37.73679,
    "lng": -122.24061,
    "state_id": "CA"
  },
  {
    "zip": 94503,
    "lat": 38.18419,
    "lng": -122.26293,
    "state_id": "CA"
  },
  {
    "zip": 94505,
    "lat": 37.86762,
    "lng": -121.58632,
    "state_id": "CA"
  },
  {
    "zip": 94506,
    "lat": 37.8081,
    "lng": -121.90636,
    "state_id": "CA"
  },
  {
    "zip": 94507,
    "lat": 37.85042,
    "lng": -122.02108,
    "state_id": "CA"
  },
  {
    "zip": 94508,
    "lat": 38.57675,
    "lng": -122.4442,
    "state_id": "CA"
  },
  {
    "zip": 94509,
    "lat": 37.99679,
    "lng": -121.81259,
    "state_id": "CA"
  },
  {
    "zip": 94510,
    "lat": 38.09404,
    "lng": -122.13197,
    "state_id": "CA"
  },
  {
    "zip": 94511,
    "lat": 38.02912,
    "lng": -121.64067,
    "state_id": "CA"
  },
  {
    "zip": 94512,
    "lat": 38.13282,
    "lng": -121.83822,
    "state_id": "CA"
  },
  {
    "zip": 94513,
    "lat": 37.92338,
    "lng": -121.67505,
    "state_id": "CA"
  },
  {
    "zip": 94514,
    "lat": 37.82537,
    "lng": -121.62355,
    "state_id": "CA"
  },
  {
    "zip": 94515,
    "lat": 38.62483,
    "lng": -122.61865,
    "state_id": "CA"
  },
  {
    "zip": 94516,
    "lat": 37.83387,
    "lng": -122.16498,
    "state_id": "CA"
  },
  {
    "zip": 94517,
    "lat": 37.89022,
    "lng": -121.87842,
    "state_id": "CA"
  },
  {
    "zip": 94518,
    "lat": 37.95041,
    "lng": -122.02205,
    "state_id": "CA"
  },
  {
    "zip": 94519,
    "lat": 37.98847,
    "lng": -122.01294,
    "state_id": "CA"
  },
  {
    "zip": 94520,
    "lat": 37.99547,
    "lng": -122.04057,
    "state_id": "CA"
  },
  {
    "zip": 94521,
    "lat": 37.95603,
    "lng": -121.95613,
    "state_id": "CA"
  },
  {
    "zip": 94523,
    "lat": 37.95408,
    "lng": -122.07615,
    "state_id": "CA"
  },
  {
    "zip": 94525,
    "lat": 38.05278,
    "lng": -122.23117,
    "state_id": "CA"
  },
  {
    "zip": 94526,
    "lat": 37.81443,
    "lng": -121.99152,
    "state_id": "CA"
  },
  {
    "zip": 94528,
    "lat": 37.84559,
    "lng": -121.95071,
    "state_id": "CA"
  },
  {
    "zip": 94530,
    "lat": 37.92157,
    "lng": -122.29846,
    "state_id": "CA"
  },
  {
    "zip": 94531,
    "lat": 37.96574,
    "lng": -121.77582,
    "state_id": "CA"
  },
  {
    "zip": 94533,
    "lat": 38.2806,
    "lng": -122.0067,
    "state_id": "CA"
  },
  {
    "zip": 94534,
    "lat": 38.24227,
    "lng": -122.13138,
    "state_id": "CA"
  },
  {
    "zip": 94535,
    "lat": 38.28467,
    "lng": -121.93269,
    "state_id": "CA"
  },
  {
    "zip": 94536,
    "lat": 37.57132,
    "lng": -121.9854,
    "state_id": "CA"
  },
  {
    "zip": 94538,
    "lat": 37.50626,
    "lng": -121.96364,
    "state_id": "CA"
  },
  {
    "zip": 94539,
    "lat": 37.51685,
    "lng": -121.91177,
    "state_id": "CA"
  },
  {
    "zip": 94541,
    "lat": 37.67434,
    "lng": -122.08515,
    "state_id": "CA"
  },
  {
    "zip": 94542,
    "lat": 37.65984,
    "lng": -122.03717,
    "state_id": "CA"
  },
  {
    "zip": 94544,
    "lat": 37.63382,
    "lng": -122.05719,
    "state_id": "CA"
  },
  {
    "zip": 94545,
    "lat": 37.6147,
    "lng": -122.11908,
    "state_id": "CA"
  },
  {
    "zip": 94546,
    "lat": 37.71498,
    "lng": -122.08002,
    "state_id": "CA"
  },
  {
    "zip": 94547,
    "lat": 38.00655,
    "lng": -122.26146,
    "state_id": "CA"
  },
  {
    "zip": 94548,
    "lat": 37.9735,
    "lng": -121.65354,
    "state_id": "CA"
  },
  {
    "zip": 94549,
    "lat": 37.89743,
    "lng": -122.1166,
    "state_id": "CA"
  },
  {
    "zip": 94550,
    "lat": 37.52911,
    "lng": -121.60113,
    "state_id": "CA"
  },
  {
    "zip": 94551,
    "lat": 37.75262,
    "lng": -121.77003,
    "state_id": "CA"
  },
  {
    "zip": 94552,
    "lat": 37.7131,
    "lng": -122.01796,
    "state_id": "CA"
  },
  {
    "zip": 94553,
    "lat": 37.9954,
    "lng": -122.13499,
    "state_id": "CA"
  },
  {
    "zip": 94555,
    "lat": 37.55558,
    "lng": -122.08081,
    "state_id": "CA"
  },
  {
    "zip": 94556,
    "lat": 37.84064,
    "lng": -122.11492,
    "state_id": "CA"
  },
  {
    "zip": 94558,
    "lat": 38.4549,
    "lng": -122.25643,
    "state_id": "CA"
  },
  {
    "zip": 94559,
    "lat": 38.23297,
    "lng": -122.30795,
    "state_id": "CA"
  },
  {
    "zip": 94560,
    "lat": 37.52037,
    "lng": -122.03099,
    "state_id": "CA"
  },
  {
    "zip": 94561,
    "lat": 37.99363,
    "lng": -121.69256,
    "state_id": "CA"
  },
  {
    "zip": 94563,
    "lat": 37.87967,
    "lng": -122.18458,
    "state_id": "CA"
  },
  {
    "zip": 94564,
    "lat": 37.99177,
    "lng": -122.27992,
    "state_id": "CA"
  },
  {
    "zip": 94565,
    "lat": 38.01584,
    "lng": -121.90708,
    "state_id": "CA"
  },
  {
    "zip": 94566,
    "lat": 37.64912,
    "lng": -121.8591,
    "state_id": "CA"
  },
  {
    "zip": 94567,
    "lat": 38.70583,
    "lng": -122.38603,
    "state_id": "CA"
  },
  {
    "zip": 94568,
    "lat": 37.71525,
    "lng": -121.91467,
    "state_id": "CA"
  },
  {
    "zip": 94569,
    "lat": 38.03508,
    "lng": -122.19244,
    "state_id": "CA"
  },
  {
    "zip": 94571,
    "lat": 38.15822,
    "lng": -121.74043,
    "state_id": "CA"
  },
  {
    "zip": 94572,
    "lat": 38.03198,
    "lng": -122.25077,
    "state_id": "CA"
  },
  {
    "zip": 94573,
    "lat": 38.4556,
    "lng": -122.4261,
    "state_id": "CA"
  },
  {
    "zip": 94574,
    "lat": 38.53569,
    "lng": -122.39274,
    "state_id": "CA"
  },
  {
    "zip": 94575,
    "lat": 37.8419,
    "lng": -122.11042,
    "state_id": "CA"
  },
  {
    "zip": 94576,
    "lat": 38.54929,
    "lng": -122.4764,
    "state_id": "CA"
  },
  {
    "zip": 94577,
    "lat": 37.71543,
    "lng": -122.16628,
    "state_id": "CA"
  },
  {
    "zip": 94578,
    "lat": 37.70642,
    "lng": -122.1253,
    "state_id": "CA"
  },
  {
    "zip": 94579,
    "lat": 37.68643,
    "lng": -122.15748,
    "state_id": "CA"
  },
  {
    "zip": 94580,
    "lat": 37.67698,
    "lng": -122.13378,
    "state_id": "CA"
  },
  {
    "zip": 94582,
    "lat": 37.76349,
    "lng": -121.91537,
    "state_id": "CA"
  },
  {
    "zip": 94583,
    "lat": 37.75515,
    "lng": -121.97273,
    "state_id": "CA"
  },
  {
    "zip": 94585,
    "lat": 38.19379,
    "lng": -121.92595,
    "state_id": "CA"
  },
  {
    "zip": 94586,
    "lat": 37.57372,
    "lng": -121.8513,
    "state_id": "CA"
  },
  {
    "zip": 94587,
    "lat": 37.60315,
    "lng": -122.01865,
    "state_id": "CA"
  },
  {
    "zip": 94588,
    "lat": 37.73748,
    "lng": -121.88184,
    "state_id": "CA"
  },
  {
    "zip": 94589,
    "lat": 38.16459,
    "lng": -122.23584,
    "state_id": "CA"
  },
  {
    "zip": 94590,
    "lat": 38.10303,
    "lng": -122.2486,
    "state_id": "CA"
  },
  {
    "zip": 94591,
    "lat": 38.12332,
    "lng": -122.19528,
    "state_id": "CA"
  },
  {
    "zip": 94592,
    "lat": 38.09194,
    "lng": -122.27466,
    "state_id": "CA"
  },
  {
    "zip": 94595,
    "lat": 37.87197,
    "lng": -122.06933,
    "state_id": "CA"
  },
  {
    "zip": 94596,
    "lat": 37.88907,
    "lng": -122.03753,
    "state_id": "CA"
  },
  {
    "zip": 94597,
    "lat": 37.91827,
    "lng": -122.07155,
    "state_id": "CA"
  },
  {
    "zip": 94598,
    "lat": 37.9011,
    "lng": -122.00047,
    "state_id": "CA"
  },
  {
    "zip": 94599,
    "lat": 38.41119,
    "lng": -122.36068,
    "state_id": "CA"
  },
  {
    "zip": 94601,
    "lat": 37.7767,
    "lng": -122.2184,
    "state_id": "CA"
  },
  {
    "zip": 94602,
    "lat": 37.8041,
    "lng": -122.20704,
    "state_id": "CA"
  },
  {
    "zip": 94603,
    "lat": 37.73661,
    "lng": -122.17935,
    "state_id": "CA"
  },
  {
    "zip": 94605,
    "lat": 37.7604,
    "lng": -122.14834,
    "state_id": "CA"
  },
  {
    "zip": 94606,
    "lat": 37.79178,
    "lng": -122.24495,
    "state_id": "CA"
  },
  {
    "zip": 94607,
    "lat": 37.8073,
    "lng": -122.30019,
    "state_id": "CA"
  },
  {
    "zip": 94608,
    "lat": 37.83614,
    "lng": -122.28623,
    "state_id": "CA"
  },
  {
    "zip": 94609,
    "lat": 37.83434,
    "lng": -122.26433,
    "state_id": "CA"
  },
  {
    "zip": 94610,
    "lat": 37.8114,
    "lng": -122.24237,
    "state_id": "CA"
  },
  {
    "zip": 94611,
    "lat": 37.83007,
    "lng": -122.2022,
    "state_id": "CA"
  },
  {
    "zip": 94612,
    "lat": 37.80878,
    "lng": -122.26909,
    "state_id": "CA"
  },
  {
    "zip": 94613,
    "lat": 37.78093,
    "lng": -122.18285,
    "state_id": "CA"
  },
  {
    "zip": 94618,
    "lat": 37.84402,
    "lng": -122.23887,
    "state_id": "CA"
  },
  {
    "zip": 94619,
    "lat": 37.77751,
    "lng": -122.13151,
    "state_id": "CA"
  },
  {
    "zip": 94621,
    "lat": 37.7388,
    "lng": -122.20814,
    "state_id": "CA"
  },
  {
    "zip": 94702,
    "lat": 37.86576,
    "lng": -122.28629,
    "state_id": "CA"
  },
  {
    "zip": 94703,
    "lat": 37.86389,
    "lng": -122.27564,
    "state_id": "CA"
  },
  {
    "zip": 94704,
    "lat": 37.86656,
    "lng": -122.25805,
    "state_id": "CA"
  },
  {
    "zip": 94705,
    "lat": 37.86522,
    "lng": -122.23818,
    "state_id": "CA"
  },
  {
    "zip": 94706,
    "lat": 37.8897,
    "lng": -122.29551,
    "state_id": "CA"
  },
  {
    "zip": 94707,
    "lat": 37.89833,
    "lng": -122.27918,
    "state_id": "CA"
  },
  {
    "zip": 94708,
    "lat": 37.90265,
    "lng": -122.26195,
    "state_id": "CA"
  },
  {
    "zip": 94709,
    "lat": 37.87927,
    "lng": -122.26689,
    "state_id": "CA"
  },
  {
    "zip": 94710,
    "lat": 37.86734,
    "lng": -122.30312,
    "state_id": "CA"
  },
  {
    "zip": 94720,
    "lat": 37.87396,
    "lng": -122.25477,
    "state_id": "CA"
  },
  {
    "zip": 94801,
    "lat": 37.94964,
    "lng": -122.38113,
    "state_id": "CA"
  },
  {
    "zip": 94803,
    "lat": 37.95953,
    "lng": -122.28365,
    "state_id": "CA"
  },
  {
    "zip": 94804,
    "lat": 37.92101,
    "lng": -122.34169,
    "state_id": "CA"
  },
  {
    "zip": 94805,
    "lat": 37.94279,
    "lng": -122.32296,
    "state_id": "CA"
  },
  {
    "zip": 94806,
    "lat": 37.98005,
    "lng": -122.3375,
    "state_id": "CA"
  },
  {
    "zip": 94850,
    "lat": 37.90243,
    "lng": -122.31961,
    "state_id": "CA"
  },
  {
    "zip": 94901,
    "lat": 37.97999,
    "lng": -122.50281,
    "state_id": "CA"
  },
  {
    "zip": 94903,
    "lat": 38.02479,
    "lng": -122.55223,
    "state_id": "CA"
  },
  {
    "zip": 94904,
    "lat": 37.94522,
    "lng": -122.56278,
    "state_id": "CA"
  },
  {
    "zip": 94920,
    "lat": 37.88749,
    "lng": -122.46576,
    "state_id": "CA"
  },
  {
    "zip": 94922,
    "lat": 38.34186,
    "lng": -122.94952,
    "state_id": "CA"
  },
  {
    "zip": 94923,
    "lat": 38.34163,
    "lng": -123.03126,
    "state_id": "CA"
  },
  {
    "zip": 94924,
    "lat": 37.94541,
    "lng": -122.72056,
    "state_id": "CA"
  },
  {
    "zip": 94925,
    "lat": 37.92376,
    "lng": -122.5128,
    "state_id": "CA"
  },
  {
    "zip": 94928,
    "lat": 38.3464,
    "lng": -122.69419,
    "state_id": "CA"
  },
  {
    "zip": 94929,
    "lat": 38.25237,
    "lng": -122.96279,
    "state_id": "CA"
  },
  {
    "zip": 94930,
    "lat": 37.96513,
    "lng": -122.61276,
    "state_id": "CA"
  },
  {
    "zip": 94931,
    "lat": 38.32462,
    "lng": -122.71597,
    "state_id": "CA"
  },
  {
    "zip": 94933,
    "lat": 38.01252,
    "lng": -122.68743,
    "state_id": "CA"
  },
  {
    "zip": 94937,
    "lat": 38.10872,
    "lng": -122.91398,
    "state_id": "CA"
  },
  {
    "zip": 94938,
    "lat": 38.02907,
    "lng": -122.72653,
    "state_id": "CA"
  },
  {
    "zip": 94939,
    "lat": 37.93719,
    "lng": -122.53391,
    "state_id": "CA"
  },
  {
    "zip": 94940,
    "lat": 38.17042,
    "lng": -122.87275,
    "state_id": "CA"
  },
  {
    "zip": 94941,
    "lat": 37.89517,
    "lng": -122.55924,
    "state_id": "CA"
  },
  {
    "zip": 94945,
    "lat": 38.12808,
    "lng": -122.55832,
    "state_id": "CA"
  },
  {
    "zip": 94946,
    "lat": 38.07889,
    "lng": -122.69433,
    "state_id": "CA"
  },
  {
    "zip": 94947,
    "lat": 38.11324,
    "lng": -122.62972,
    "state_id": "CA"
  },
  {
    "zip": 94949,
    "lat": 38.06529,
    "lng": -122.53839,
    "state_id": "CA"
  },
  {
    "zip": 94950,
    "lat": 38.02486,
    "lng": -122.75993,
    "state_id": "CA"
  },
  {
    "zip": 94951,
    "lat": 38.32084,
    "lng": -122.64615,
    "state_id": "CA"
  },
  {
    "zip": 94952,
    "lat": 38.2346,
    "lng": -122.75895,
    "state_id": "CA"
  },
  {
    "zip": 94954,
    "lat": 38.23578,
    "lng": -122.55986,
    "state_id": "CA"
  },
  {
    "zip": 94956,
    "lat": 38.05359,
    "lng": -122.85161,
    "state_id": "CA"
  },
  {
    "zip": 94957,
    "lat": 37.96308,
    "lng": -122.56369,
    "state_id": "CA"
  },
  {
    "zip": 94960,
    "lat": 37.99584,
    "lng": -122.5778,
    "state_id": "CA"
  },
  {
    "zip": 94963,
    "lat": 38.01376,
    "lng": -122.67024,
    "state_id": "CA"
  },
  {
    "zip": 94964,
    "lat": 37.94315,
    "lng": -122.49181,
    "state_id": "CA"
  },
  {
    "zip": 94965,
    "lat": 37.84993,
    "lng": -122.52355,
    "state_id": "CA"
  },
  {
    "zip": 94970,
    "lat": 37.9145,
    "lng": -122.64684,
    "state_id": "CA"
  },
  {
    "zip": 94971,
    "lat": 38.24177,
    "lng": -122.91434,
    "state_id": "CA"
  },
  {
    "zip": 94972,
    "lat": 38.28951,
    "lng": -122.95895,
    "state_id": "CA"
  },
  {
    "zip": 94973,
    "lat": 38.01266,
    "lng": -122.63965,
    "state_id": "CA"
  },
  {
    "zip": 95002,
    "lat": 37.44201,
    "lng": -122.0052,
    "state_id": "CA"
  },
  {
    "zip": 95003,
    "lat": 37.01015,
    "lng": -121.87763,
    "state_id": "CA"
  },
  {
    "zip": 95004,
    "lat": 36.87409,
    "lng": -121.633,
    "state_id": "CA"
  },
  {
    "zip": 95005,
    "lat": 37.09905,
    "lng": -122.08716,
    "state_id": "CA"
  },
  {
    "zip": 95006,
    "lat": 37.16994,
    "lng": -122.14742,
    "state_id": "CA"
  },
  {
    "zip": 95007,
    "lat": 37.10502,
    "lng": -122.10678,
    "state_id": "CA"
  },
  {
    "zip": 95008,
    "lat": 37.27967,
    "lng": -121.95503,
    "state_id": "CA"
  },
  {
    "zip": 95010,
    "lat": 36.97722,
    "lng": -121.95476,
    "state_id": "CA"
  },
  {
    "zip": 95012,
    "lat": 36.77657,
    "lng": -121.75298,
    "state_id": "CA"
  },
  {
    "zip": 95013,
    "lat": 37.21535,
    "lng": -121.74153,
    "state_id": "CA"
  },
  {
    "zip": 95014,
    "lat": 37.30494,
    "lng": -122.08069,
    "state_id": "CA"
  },
  {
    "zip": 95017,
    "lat": 37.10583,
    "lng": -122.24176,
    "state_id": "CA"
  },
  {
    "zip": 95018,
    "lat": 37.07408,
    "lng": -122.05934,
    "state_id": "CA"
  },
  {
    "zip": 95019,
    "lat": 36.9355,
    "lng": -121.7832,
    "state_id": "CA"
  },
  {
    "zip": 95020,
    "lat": 37.01425,
    "lng": -121.54993,
    "state_id": "CA"
  },
  {
    "zip": 95023,
    "lat": 36.89993,
    "lng": -121.35126,
    "state_id": "CA"
  },
  {
    "zip": 95030,
    "lat": 37.22311,
    "lng": -121.98395,
    "state_id": "CA"
  },
  {
    "zip": 95032,
    "lat": 37.21505,
    "lng": -121.92794,
    "state_id": "CA"
  },
  {
    "zip": 95033,
    "lat": 37.16017,
    "lng": -121.98213,
    "state_id": "CA"
  },
  {
    "zip": 95035,
    "lat": 37.44421,
    "lng": -121.87432,
    "state_id": "CA"
  },
  {
    "zip": 95037,
    "lat": 37.16773,
    "lng": -121.59354,
    "state_id": "CA"
  },
  {
    "zip": 95039,
    "lat": 36.82653,
    "lng": -121.77489,
    "state_id": "CA"
  },
  {
    "zip": 95041,
    "lat": 37.05082,
    "lng": -122.0576,
    "state_id": "CA"
  },
  {
    "zip": 95043,
    "lat": 36.52513,
    "lng": -120.96137,
    "state_id": "CA"
  },
  {
    "zip": 95045,
    "lat": 36.83417,
    "lng": -121.52738,
    "state_id": "CA"
  },
  {
    "zip": 95046,
    "lat": 37.09614,
    "lng": -121.59504,
    "state_id": "CA"
  },
  {
    "zip": 95050,
    "lat": 37.35113,
    "lng": -121.95226,
    "state_id": "CA"
  },
  {
    "zip": 95051,
    "lat": 37.34861,
    "lng": -121.98434,
    "state_id": "CA"
  },
  {
    "zip": 95053,
    "lat": 37.34878,
    "lng": -121.93768,
    "state_id": "CA"
  },
  {
    "zip": 95054,
    "lat": 37.3934,
    "lng": -121.96471,
    "state_id": "CA"
  },
  {
    "zip": 95060,
    "lat": 37.03425,
    "lng": -122.12038,
    "state_id": "CA"
  },
  {
    "zip": 95062,
    "lat": 36.97258,
    "lng": -121.99009,
    "state_id": "CA"
  },
  {
    "zip": 95064,
    "lat": 36.9911,
    "lng": -122.05902,
    "state_id": "CA"
  },
  {
    "zip": 95065,
    "lat": 37.03154,
    "lng": -121.98329,
    "state_id": "CA"
  },
  {
    "zip": 95066,
    "lat": 37.06792,
    "lng": -122.01399,
    "state_id": "CA"
  },
  {
    "zip": 95070,
    "lat": 37.25638,
    "lng": -122.04963,
    "state_id": "CA"
  },
  {
    "zip": 95073,
    "lat": 37.0423,
    "lng": -121.92994,
    "state_id": "CA"
  },
  {
    "zip": 95075,
    "lat": 36.78021,
    "lng": -121.14122,
    "state_id": "CA"
  },
  {
    "zip": 95076,
    "lat": 36.93927,
    "lng": -121.74841,
    "state_id": "CA"
  },
  {
    "zip": 95110,
    "lat": 37.34662,
    "lng": -121.90993,
    "state_id": "CA"
  },
  {
    "zip": 95111,
    "lat": 37.28342,
    "lng": -121.82652,
    "state_id": "CA"
  },
  {
    "zip": 95112,
    "lat": 37.34442,
    "lng": -121.88351,
    "state_id": "CA"
  },
  {
    "zip": 95113,
    "lat": 37.33368,
    "lng": -121.89102,
    "state_id": "CA"
  },
  {
    "zip": 95116,
    "lat": 37.35042,
    "lng": -121.85257,
    "state_id": "CA"
  },
  {
    "zip": 95117,
    "lat": 37.31126,
    "lng": -121.96191,
    "state_id": "CA"
  },
  {
    "zip": 95118,
    "lat": 37.25548,
    "lng": -121.89,
    "state_id": "CA"
  },
  {
    "zip": 95119,
    "lat": 37.22893,
    "lng": -121.78599,
    "state_id": "CA"
  },
  {
    "zip": 95120,
    "lat": 37.18235,
    "lng": -121.84392,
    "state_id": "CA"
  },
  {
    "zip": 95121,
    "lat": 37.30421,
    "lng": -121.80994,
    "state_id": "CA"
  },
  {
    "zip": 95122,
    "lat": 37.3287,
    "lng": -121.83526,
    "state_id": "CA"
  },
  {
    "zip": 95123,
    "lat": 37.24484,
    "lng": -121.83302,
    "state_id": "CA"
  },
  {
    "zip": 95124,
    "lat": 37.25689,
    "lng": -121.92271,
    "state_id": "CA"
  },
  {
    "zip": 95125,
    "lat": 37.29504,
    "lng": -121.89144,
    "state_id": "CA"
  },
  {
    "zip": 95126,
    "lat": 37.32676,
    "lng": -121.91671,
    "state_id": "CA"
  },
  {
    "zip": 95127,
    "lat": 37.37108,
    "lng": -121.80071,
    "state_id": "CA"
  },
  {
    "zip": 95128,
    "lat": 37.31596,
    "lng": -121.93633,
    "state_id": "CA"
  },
  {
    "zip": 95129,
    "lat": 37.30575,
    "lng": -122.00091,
    "state_id": "CA"
  },
  {
    "zip": 95130,
    "lat": 37.2882,
    "lng": -121.98175,
    "state_id": "CA"
  },
  {
    "zip": 95131,
    "lat": 37.3871,
    "lng": -121.89744,
    "state_id": "CA"
  },
  {
    "zip": 95132,
    "lat": 37.4307,
    "lng": -121.76422,
    "state_id": "CA"
  },
  {
    "zip": 95133,
    "lat": 37.37159,
    "lng": -121.86084,
    "state_id": "CA"
  },
  {
    "zip": 95134,
    "lat": 37.42861,
    "lng": -121.94346,
    "state_id": "CA"
  },
  {
    "zip": 95135,
    "lat": 37.28985,
    "lng": -121.69571,
    "state_id": "CA"
  },
  {
    "zip": 95136,
    "lat": 37.27048,
    "lng": -121.85182,
    "state_id": "CA"
  },
  {
    "zip": 95138,
    "lat": 37.24663,
    "lng": -121.73388,
    "state_id": "CA"
  },
  {
    "zip": 95139,
    "lat": 37.22495,
    "lng": -121.76414,
    "state_id": "CA"
  },
  {
    "zip": 95140,
    "lat": 37.3897,
    "lng": -121.61968,
    "state_id": "CA"
  },
  {
    "zip": 95148,
    "lat": 37.33539,
    "lng": -121.77931,
    "state_id": "CA"
  },
  {
    "zip": 95202,
    "lat": 37.95945,
    "lng": -121.28754,
    "state_id": "CA"
  },
  {
    "zip": 95203,
    "lat": 37.95254,
    "lng": -121.33148,
    "state_id": "CA"
  },
  {
    "zip": 95204,
    "lat": 37.97388,
    "lng": -121.31987,
    "state_id": "CA"
  },
  {
    "zip": 95205,
    "lat": 37.96451,
    "lng": -121.26,
    "state_id": "CA"
  },
  {
    "zip": 95206,
    "lat": 37.9141,
    "lng": -121.42134,
    "state_id": "CA"
  },
  {
    "zip": 95207,
    "lat": 38.00246,
    "lng": -121.32502,
    "state_id": "CA"
  },
  {
    "zip": 95209,
    "lat": 38.04726,
    "lng": -121.3508,
    "state_id": "CA"
  },
  {
    "zip": 95210,
    "lat": 38.03038,
    "lng": -121.29876,
    "state_id": "CA"
  },
  {
    "zip": 95211,
    "lat": 37.97982,
    "lng": -121.31296,
    "state_id": "CA"
  },
  {
    "zip": 95212,
    "lat": 38.04827,
    "lng": -121.22818,
    "state_id": "CA"
  },
  {
    "zip": 95215,
    "lat": 37.94392,
    "lng": -121.15292,
    "state_id": "CA"
  },
  {
    "zip": 95219,
    "lat": 38.04199,
    "lng": -121.48581,
    "state_id": "CA"
  },
  {
    "zip": 95220,
    "lat": 38.19719,
    "lng": -121.23825,
    "state_id": "CA"
  },
  {
    "zip": 95222,
    "lat": 38.06467,
    "lng": -120.62048,
    "state_id": "CA"
  },
  {
    "zip": 95223,
    "lat": 38.43419,
    "lng": -119.97443,
    "state_id": "CA"
  },
  {
    "zip": 95224,
    "lat": 38.22528,
    "lng": -120.32711,
    "state_id": "CA"
  },
  {
    "zip": 95225,
    "lat": 38.19882,
    "lng": -120.88891,
    "state_id": "CA"
  },
  {
    "zip": 95226,
    "lat": 38.22864,
    "lng": -120.85805,
    "state_id": "CA"
  },
  {
    "zip": 95227,
    "lat": 38.20669,
    "lng": -121.04327,
    "state_id": "CA"
  },
  {
    "zip": 95228,
    "lat": 37.95167,
    "lng": -120.69219,
    "state_id": "CA"
  },
  {
    "zip": 95230,
    "lat": 37.9607,
    "lng": -120.882,
    "state_id": "CA"
  },
  {
    "zip": 95231,
    "lat": 37.8739,
    "lng": -121.29004,
    "state_id": "CA"
  },
  {
    "zip": 95232,
    "lat": 38.35534,
    "lng": -120.59406,
    "state_id": "CA"
  },
  {
    "zip": 95233,
    "lat": 38.1762,
    "lng": -120.37044,
    "state_id": "CA"
  },
  {
    "zip": 95234,
    "lat": 37.92544,
    "lng": -121.52776,
    "state_id": "CA"
  },
  {
    "zip": 95236,
    "lat": 38.03949,
    "lng": -121.02943,
    "state_id": "CA"
  },
  {
    "zip": 95237,
    "lat": 38.16165,
    "lng": -121.14989,
    "state_id": "CA"
  },
  {
    "zip": 95240,
    "lat": 38.11309,
    "lng": -121.14899,
    "state_id": "CA"
  },
  {
    "zip": 95242,
    "lat": 38.13459,
    "lng": -121.39362,
    "state_id": "CA"
  },
  {
    "zip": 95245,
    "lat": 38.29793,
    "lng": -120.6213,
    "state_id": "CA"
  },
  {
    "zip": 95246,
    "lat": 38.23279,
    "lng": -120.51792,
    "state_id": "CA"
  },
  {
    "zip": 95247,
    "lat": 38.13806,
    "lng": -120.4532,
    "state_id": "CA"
  },
  {
    "zip": 95248,
    "lat": 38.32399,
    "lng": -120.46138,
    "state_id": "CA"
  },
  {
    "zip": 95249,
    "lat": 38.18985,
    "lng": -120.62658,
    "state_id": "CA"
  },
  {
    "zip": 95251,
    "lat": 38.08235,
    "lng": -120.45474,
    "state_id": "CA"
  },
  {
    "zip": 95252,
    "lat": 38.14951,
    "lng": -120.84699,
    "state_id": "CA"
  },
  {
    "zip": 95254,
    "lat": 38.19516,
    "lng": -120.95514,
    "state_id": "CA"
  },
  {
    "zip": 95255,
    "lat": 38.41967,
    "lng": -120.4759,
    "state_id": "CA"
  },
  {
    "zip": 95257,
    "lat": 38.38056,
    "lng": -120.44456,
    "state_id": "CA"
  },
  {
    "zip": 95258,
    "lat": 38.16833,
    "lng": -121.31028,
    "state_id": "CA"
  },
  {
    "zip": 95301,
    "lat": 37.31796,
    "lng": -120.63372,
    "state_id": "CA"
  },
  {
    "zip": 95303,
    "lat": 37.48297,
    "lng": -120.66189,
    "state_id": "CA"
  },
  {
    "zip": 95304,
    "lat": 37.67832,
    "lng": -121.3999,
    "state_id": "CA"
  },
  {
    "zip": 95305,
    "lat": 37.80468,
    "lng": -120.27189,
    "state_id": "CA"
  },
  {
    "zip": 95306,
    "lat": 37.38408,
    "lng": -120.12909,
    "state_id": "CA"
  },
  {
    "zip": 95307,
    "lat": 37.55435,
    "lng": -120.95139,
    "state_id": "CA"
  },
  {
    "zip": 95310,
    "lat": 38.09129,
    "lng": -120.37094,
    "state_id": "CA"
  },
  {
    "zip": 95311,
    "lat": 37.7045,
    "lng": -120.08199,
    "state_id": "CA"
  },
  {
    "zip": 95312,
    "lat": 37.42103,
    "lng": -120.65308,
    "state_id": "CA"
  },
  {
    "zip": 95313,
    "lat": 37.43001,
    "lng": -121.04698,
    "state_id": "CA"
  },
  {
    "zip": 95315,
    "lat": 37.4236,
    "lng": -120.77147,
    "state_id": "CA"
  },
  {
    "zip": 95316,
    "lat": 37.55325,
    "lng": -120.71399,
    "state_id": "CA"
  },
  {
    "zip": 95317,
    "lat": 37.12725,
    "lng": -120.51506,
    "state_id": "CA"
  },
  {
    "zip": 95318,
    "lat": 37.64516,
    "lng": -119.79939,
    "state_id": "CA"
  },
  {
    "zip": 95319,
    "lat": 37.63936,
    "lng": -120.9031,
    "state_id": "CA"
  },
  {
    "zip": 95320,
    "lat": 37.82528,
    "lng": -121.01347,
    "state_id": "CA"
  },
  {
    "zip": 95321,
    "lat": 37.86092,
    "lng": -120.02585,
    "state_id": "CA"
  },
  {
    "zip": 95322,
    "lat": 37.17019,
    "lng": -121.01674,
    "state_id": "CA"
  },
  {
    "zip": 95323,
    "lat": 37.61003,
    "lng": -120.68543,
    "state_id": "CA"
  },
  {
    "zip": 95324,
    "lat": 37.39508,
    "lng": -120.89377,
    "state_id": "CA"
  },
  {
    "zip": 95325,
    "lat": 37.48497,
    "lng": -120.24151,
    "state_id": "CA"
  },
  {
    "zip": 95326,
    "lat": 37.59044,
    "lng": -120.85286,
    "state_id": "CA"
  },
  {
    "zip": 95327,
    "lat": 37.86072,
    "lng": -120.47968,
    "state_id": "CA"
  },
  {
    "zip": 95328,
    "lat": 37.55824,
    "lng": -120.90985,
    "state_id": "CA"
  },
  {
    "zip": 95329,
    "lat": 37.67563,
    "lng": -120.40341,
    "state_id": "CA"
  },
  {
    "zip": 95330,
    "lat": 37.81352,
    "lng": -121.31053,
    "state_id": "CA"
  },
  {
    "zip": 95333,
    "lat": 37.24229,
    "lng": -120.24045,
    "state_id": "CA"
  },
  {
    "zip": 95334,
    "lat": 37.34808,
    "lng": -120.74314,
    "state_id": "CA"
  },
  {
    "zip": 95335,
    "lat": 38.10945,
    "lng": -120.09668,
    "state_id": "CA"
  },
  {
    "zip": 95336,
    "lat": 37.83235,
    "lng": -121.19705,
    "state_id": "CA"
  },
  {
    "zip": 95337,
    "lat": 37.74167,
    "lng": -121.23854,
    "state_id": "CA"
  },
  {
    "zip": 95338,
    "lat": 37.50978,
    "lng": -119.98252,
    "state_id": "CA"
  },
  {
    "zip": 95340,
    "lat": 37.33905,
    "lng": -120.42428,
    "state_id": "CA"
  },
  {
    "zip": 95341,
    "lat": 37.23086,
    "lng": -120.51436,
    "state_id": "CA"
  },
  {
    "zip": 95345,
    "lat": 37.59019,
    "lng": -119.96519,
    "state_id": "CA"
  },
  {
    "zip": 95346,
    "lat": 38.06749,
    "lng": -120.17933,
    "state_id": "CA"
  },
  {
    "zip": 95348,
    "lat": 37.39583,
    "lng": -120.50013,
    "state_id": "CA"
  },
  {
    "zip": 95350,
    "lat": 37.67192,
    "lng": -121.0073,
    "state_id": "CA"
  },
  {
    "zip": 95351,
    "lat": 37.62327,
    "lng": -120.99639,
    "state_id": "CA"
  },
  {
    "zip": 95354,
    "lat": 37.6382,
    "lng": -120.96597,
    "state_id": "CA"
  },
  {
    "zip": 95355,
    "lat": 37.67283,
    "lng": -120.947,
    "state_id": "CA"
  },
  {
    "zip": 95356,
    "lat": 37.72039,
    "lng": -121.02532,
    "state_id": "CA"
  },
  {
    "zip": 95357,
    "lat": 37.66794,
    "lng": -120.88045,
    "state_id": "CA"
  },
  {
    "zip": 95358,
    "lat": 37.61577,
    "lng": -121.10177,
    "state_id": "CA"
  },
  {
    "zip": 95360,
    "lat": 37.29165,
    "lng": -121.22571,
    "state_id": "CA"
  },
  {
    "zip": 95361,
    "lat": 37.78647,
    "lng": -120.75712,
    "state_id": "CA"
  },
  {
    "zip": 95363,
    "lat": 37.46353,
    "lng": -121.21678,
    "state_id": "CA"
  },
  {
    "zip": 95364,
    "lat": 38.21142,
    "lng": -119.81338,
    "state_id": "CA"
  },
  {
    "zip": 95365,
    "lat": 37.33109,
    "lng": -120.29484,
    "state_id": "CA"
  },
  {
    "zip": 95366,
    "lat": 37.76259,
    "lng": -121.12451,
    "state_id": "CA"
  },
  {
    "zip": 95367,
    "lat": 37.72831,
    "lng": -120.94072,
    "state_id": "CA"
  },
  {
    "zip": 95368,
    "lat": 37.71618,
    "lng": -121.09002,
    "state_id": "CA"
  },
  {
    "zip": 95369,
    "lat": 37.53346,
    "lng": -120.43175,
    "state_id": "CA"
  },
  {
    "zip": 95370,
    "lat": 37.98461,
    "lng": -120.34883,
    "state_id": "CA"
  },
  {
    "zip": 95372,
    "lat": 37.99172,
    "lng": -120.26084,
    "state_id": "CA"
  },
  {
    "zip": 95374,
    "lat": 37.31615,
    "lng": -120.85288,
    "state_id": "CA"
  },
  {
    "zip": 95375,
    "lat": 38.18664,
    "lng": -120.02612,
    "state_id": "CA"
  },
  {
    "zip": 95376,
    "lat": 37.73719,
    "lng": -121.43335,
    "state_id": "CA"
  },
  {
    "zip": 95377,
    "lat": 37.65669,
    "lng": -121.49545,
    "state_id": "CA"
  },
  {
    "zip": 95379,
    "lat": 37.94693,
    "lng": -120.19399,
    "state_id": "CA"
  },
  {
    "zip": 95380,
    "lat": 37.47197,
    "lng": -120.86868,
    "state_id": "CA"
  },
  {
    "zip": 95382,
    "lat": 37.5284,
    "lng": -120.85304,
    "state_id": "CA"
  },
  {
    "zip": 95383,
    "lat": 38.10788,
    "lng": -120.23286,
    "state_id": "CA"
  },
  {
    "zip": 95385,
    "lat": 37.60949,
    "lng": -121.25116,
    "state_id": "CA"
  },
  {
    "zip": 95386,
    "lat": 37.66919,
    "lng": -120.63945,
    "state_id": "CA"
  },
  {
    "zip": 95387,
    "lat": 37.50588,
    "lng": -121.32775,
    "state_id": "CA"
  },
  {
    "zip": 95388,
    "lat": 37.41812,
    "lng": -120.59158,
    "state_id": "CA"
  },
  {
    "zip": 95389,
    "lat": 37.72677,
    "lng": -119.53536,
    "state_id": "CA"
  },
  {
    "zip": 95391,
    "lat": 37.76309,
    "lng": -121.60538,
    "state_id": "CA"
  },
  {
    "zip": 95401,
    "lat": 38.44898,
    "lng": -122.79221,
    "state_id": "CA"
  },
  {
    "zip": 95403,
    "lat": 38.5025,
    "lng": -122.75443,
    "state_id": "CA"
  },
  {
    "zip": 95404,
    "lat": 38.46565,
    "lng": -122.65198,
    "state_id": "CA"
  },
  {
    "zip": 95405,
    "lat": 38.43713,
    "lng": -122.66594,
    "state_id": "CA"
  },
  {
    "zip": 95407,
    "lat": 38.39452,
    "lng": -122.74435,
    "state_id": "CA"
  },
  {
    "zip": 95409,
    "lat": 38.46101,
    "lng": -122.59753,
    "state_id": "CA"
  },
  {
    "zip": 95410,
    "lat": 39.20801,
    "lng": -123.68669,
    "state_id": "CA"
  },
  {
    "zip": 95412,
    "lat": 38.71367,
    "lng": -123.35009,
    "state_id": "CA"
  },
  {
    "zip": 95415,
    "lat": 39.01523,
    "lng": -123.37074,
    "state_id": "CA"
  },
  {
    "zip": 95417,
    "lat": 39.68977,
    "lng": -123.61,
    "state_id": "CA"
  },
  {
    "zip": 95420,
    "lat": 39.36712,
    "lng": -123.80392,
    "state_id": "CA"
  },
  {
    "zip": 95421,
    "lat": 38.63036,
    "lng": -123.19319,
    "state_id": "CA"
  },
  {
    "zip": 95422,
    "lat": 38.97629,
    "lng": -122.62312,
    "state_id": "CA"
  },
  {
    "zip": 95423,
    "lat": 39.06486,
    "lng": -122.62739,
    "state_id": "CA"
  },
  {
    "zip": 95425,
    "lat": 38.81932,
    "lng": -123.04213,
    "state_id": "CA"
  },
  {
    "zip": 95426,
    "lat": 38.8323,
    "lng": -122.73126,
    "state_id": "CA"
  },
  {
    "zip": 95427,
    "lat": 39.24419,
    "lng": -123.56352,
    "state_id": "CA"
  },
  {
    "zip": 95428,
    "lat": 39.82723,
    "lng": -123.17885,
    "state_id": "CA"
  },
  {
    "zip": 95429,
    "lat": 39.72746,
    "lng": -123.29603,
    "state_id": "CA"
  },
  {
    "zip": 95430,
    "lat": 38.46138,
    "lng": -123.04717,
    "state_id": "CA"
  },
  {
    "zip": 95431,
    "lat": 38.34831,
    "lng": -122.51701,
    "state_id": "CA"
  },
  {
    "zip": 95432,
    "lat": 39.10516,
    "lng": -123.64,
    "state_id": "CA"
  },
  {
    "zip": 95435,
    "lat": 39.00716,
    "lng": -122.86947,
    "state_id": "CA"
  },
  {
    "zip": 95436,
    "lat": 38.491,
    "lng": -122.91416,
    "state_id": "CA"
  },
  {
    "zip": 95437,
    "lat": 39.45586,
    "lng": -123.73049,
    "state_id": "CA"
  },
  {
    "zip": 95439,
    "lat": 38.49315,
    "lng": -122.77761,
    "state_id": "CA"
  },
  {
    "zip": 95441,
    "lat": 38.73094,
    "lng": -122.90494,
    "state_id": "CA"
  },
  {
    "zip": 95442,
    "lat": 38.37674,
    "lng": -122.51286,
    "state_id": "CA"
  },
  {
    "zip": 95443,
    "lat": 39.04946,
    "lng": -122.73954,
    "state_id": "CA"
  },
  {
    "zip": 95444,
    "lat": 38.43321,
    "lng": -122.86894,
    "state_id": "CA"
  },
  {
    "zip": 95445,
    "lat": 38.83295,
    "lng": -123.49572,
    "state_id": "CA"
  },
  {
    "zip": 95446,
    "lat": 38.52662,
    "lng": -123.00418,
    "state_id": "CA"
  },
  {
    "zip": 95448,
    "lat": 38.62394,
    "lng": -122.88593,
    "state_id": "CA"
  },
  {
    "zip": 95449,
    "lat": 38.94221,
    "lng": -123.10135,
    "state_id": "CA"
  },
  {
    "zip": 95450,
    "lat": 38.49431,
    "lng": -123.16909,
    "state_id": "CA"
  },
  {
    "zip": 95451,
    "lat": 38.93284,
    "lng": -122.80414,
    "state_id": "CA"
  },
  {
    "zip": 95452,
    "lat": 38.42555,
    "lng": -122.53517,
    "state_id": "CA"
  },
  {
    "zip": 95453,
    "lat": 39.04561,
    "lng": -122.93731,
    "state_id": "CA"
  },
  {
    "zip": 95454,
    "lat": 39.80652,
    "lng": -123.50998,
    "state_id": "CA"
  },
  {
    "zip": 95456,
    "lat": 39.25969,
    "lng": -123.74759,
    "state_id": "CA"
  },
  {
    "zip": 95457,
    "lat": 38.88145,
    "lng": -122.5316,
    "state_id": "CA"
  },
  {
    "zip": 95458,
    "lat": 39.15052,
    "lng": -122.7474,
    "state_id": "CA"
  },
  {
    "zip": 95459,
    "lat": 38.97956,
    "lng": -123.58638,
    "state_id": "CA"
  },
  {
    "zip": 95460,
    "lat": 39.30879,
    "lng": -123.75604,
    "state_id": "CA"
  },
  {
    "zip": 95461,
    "lat": 38.76112,
    "lng": -122.59664,
    "state_id": "CA"
  },
  {
    "zip": 95462,
    "lat": 38.46171,
    "lng": -123.0223,
    "state_id": "CA"
  },
  {
    "zip": 95463,
    "lat": 39.18677,
    "lng": -123.55835,
    "state_id": "CA"
  },
  {
    "zip": 95464,
    "lat": 39.12829,
    "lng": -122.84919,
    "state_id": "CA"
  },
  {
    "zip": 95465,
    "lat": 38.40644,
    "lng": -123.01948,
    "state_id": "CA"
  },
  {
    "zip": 95466,
    "lat": 39.08617,
    "lng": -123.50397,
    "state_id": "CA"
  },
  {
    "zip": 95467,
    "lat": 38.80355,
    "lng": -122.54066,
    "state_id": "CA"
  },
  {
    "zip": 95468,
    "lat": 38.91223,
    "lng": -123.60458,
    "state_id": "CA"
  },
  {
    "zip": 95469,
    "lat": 39.39942,
    "lng": -123.05399,
    "state_id": "CA"
  },
  {
    "zip": 95470,
    "lat": 39.31171,
    "lng": -123.24669,
    "state_id": "CA"
  },
  {
    "zip": 95471,
    "lat": 38.52404,
    "lng": -122.96878,
    "state_id": "CA"
  },
  {
    "zip": 95472,
    "lat": 38.39817,
    "lng": -122.86574,
    "state_id": "CA"
  },
  {
    "zip": 95476,
    "lat": 38.24859,
    "lng": -122.45712,
    "state_id": "CA"
  },
  {
    "zip": 95482,
    "lat": 39.16033,
    "lng": -123.24291,
    "state_id": "CA"
  },
  {
    "zip": 95485,
    "lat": 39.2171,
    "lng": -122.92043,
    "state_id": "CA"
  },
  {
    "zip": 95486,
    "lat": 38.47411,
    "lng": -123.02405,
    "state_id": "CA"
  },
  {
    "zip": 95488,
    "lat": 39.69135,
    "lng": -123.7579,
    "state_id": "CA"
  },
  {
    "zip": 95490,
    "lat": 39.49709,
    "lng": -123.33932,
    "state_id": "CA"
  },
  {
    "zip": 95492,
    "lat": 38.53034,
    "lng": -122.81716,
    "state_id": "CA"
  },
  {
    "zip": 95493,
    "lat": 39.18538,
    "lng": -122.97207,
    "state_id": "CA"
  },
  {
    "zip": 95494,
    "lat": 38.88969,
    "lng": -123.33784,
    "state_id": "CA"
  },
  {
    "zip": 95497,
    "lat": 38.71649,
    "lng": -123.45271,
    "state_id": "CA"
  },
  {
    "zip": 95501,
    "lat": 40.79809,
    "lng": -124.15097,
    "state_id": "CA"
  },
  {
    "zip": 95503,
    "lat": 40.72426,
    "lng": -124.09613,
    "state_id": "CA"
  },
  {
    "zip": 95511,
    "lat": 40.17126,
    "lng": -123.63025,
    "state_id": "CA"
  },
  {
    "zip": 95514,
    "lat": 40.29127,
    "lng": -123.65249,
    "state_id": "CA"
  },
  {
    "zip": 95519,
    "lat": 40.95573,
    "lng": -124.04641,
    "state_id": "CA"
  },
  {
    "zip": 95521,
    "lat": 40.84857,
    "lng": -124.05913,
    "state_id": "CA"
  },
  {
    "zip": 95524,
    "lat": 40.82111,
    "lng": -124.04529,
    "state_id": "CA"
  },
  {
    "zip": 95525,
    "lat": 40.925,
    "lng": -123.82271,
    "state_id": "CA"
  },
  {
    "zip": 95526,
    "lat": 40.4567,
    "lng": -123.69082,
    "state_id": "CA"
  },
  {
    "zip": 95527,
    "lat": 40.8534,
    "lng": -123.43175,
    "state_id": "CA"
  },
  {
    "zip": 95528,
    "lat": 40.49924,
    "lng": -123.92811,
    "state_id": "CA"
  },
  {
    "zip": 95531,
    "lat": 41.79625,
    "lng": -124.00515,
    "state_id": "CA"
  },
  {
    "zip": 95536,
    "lat": 40.51692,
    "lng": -124.27436,
    "state_id": "CA"
  },
  {
    "zip": 95537,
    "lat": 40.72646,
    "lng": -124.21754,
    "state_id": "CA"
  },
  {
    "zip": 95540,
    "lat": 40.57866,
    "lng": -124.13648,
    "state_id": "CA"
  },
  {
    "zip": 95542,
    "lat": 40.08672,
    "lng": -123.78492,
    "state_id": "CA"
  },
  {
    "zip": 95543,
    "lat": 41.87127,
    "lng": -123.8623,
    "state_id": "CA"
  },
  {
    "zip": 95545,
    "lat": 40.28037,
    "lng": -124.06367,
    "state_id": "CA"
  },
  {
    "zip": 95546,
    "lat": 41.16034,
    "lng": -123.7327,
    "state_id": "CA"
  },
  {
    "zip": 95547,
    "lat": 40.55751,
    "lng": -124.08116,
    "state_id": "CA"
  },
  {
    "zip": 95548,
    "lat": 41.59277,
    "lng": -124.04488,
    "state_id": "CA"
  },
  {
    "zip": 95549,
    "lat": 40.67478,
    "lng": -123.91525,
    "state_id": "CA"
  },
  {
    "zip": 95550,
    "lat": 40.77331,
    "lng": -123.81846,
    "state_id": "CA"
  },
  {
    "zip": 95551,
    "lat": 40.66757,
    "lng": -124.21872,
    "state_id": "CA"
  },
  {
    "zip": 95552,
    "lat": 40.19843,
    "lng": -123.23113,
    "state_id": "CA"
  },
  {
    "zip": 95553,
    "lat": 40.24435,
    "lng": -123.89617,
    "state_id": "CA"
  },
  {
    "zip": 95554,
    "lat": 40.28413,
    "lng": -123.79454,
    "state_id": "CA"
  },
  {
    "zip": 95555,
    "lat": 41.3011,
    "lng": -123.99042,
    "state_id": "CA"
  },
  {
    "zip": 95556,
    "lat": 41.30078,
    "lng": -123.58461,
    "state_id": "CA"
  },
  {
    "zip": 95558,
    "lat": 40.2961,
    "lng": -124.23457,
    "state_id": "CA"
  },
  {
    "zip": 95559,
    "lat": 40.1859,
    "lng": -123.74824,
    "state_id": "CA"
  },
  {
    "zip": 95560,
    "lat": 40.16453,
    "lng": -123.83859,
    "state_id": "CA"
  },
  {
    "zip": 95562,
    "lat": 40.4624,
    "lng": -124.12516,
    "state_id": "CA"
  },
  {
    "zip": 95563,
    "lat": 40.87625,
    "lng": -123.49847,
    "state_id": "CA"
  },
  {
    "zip": 95564,
    "lat": 40.7899,
    "lng": -124.20339,
    "state_id": "CA"
  },
  {
    "zip": 95565,
    "lat": 40.45778,
    "lng": -124.01098,
    "state_id": "CA"
  },
  {
    "zip": 95567,
    "lat": 41.94978,
    "lng": -124.11187,
    "state_id": "CA"
  },
  {
    "zip": 95568,
    "lat": 41.45328,
    "lng": -123.46342,
    "state_id": "CA"
  },
  {
    "zip": 95569,
    "lat": 40.36748,
    "lng": -123.85533,
    "state_id": "CA"
  },
  {
    "zip": 95570,
    "lat": 41.12116,
    "lng": -124.11375,
    "state_id": "CA"
  },
  {
    "zip": 95571,
    "lat": 40.32248,
    "lng": -123.92055,
    "state_id": "CA"
  },
  {
    "zip": 95573,
    "lat": 40.88934,
    "lng": -123.66097,
    "state_id": "CA"
  },
  {
    "zip": 95585,
    "lat": 39.84467,
    "lng": -123.65699,
    "state_id": "CA"
  },
  {
    "zip": 95587,
    "lat": 39.96325,
    "lng": -123.77574,
    "state_id": "CA"
  },
  {
    "zip": 95589,
    "lat": 40.05662,
    "lng": -123.97052,
    "state_id": "CA"
  },
  {
    "zip": 95595,
    "lat": 40.11671,
    "lng": -123.42455,
    "state_id": "CA"
  },
  {
    "zip": 95601,
    "lat": 38.42592,
    "lng": -120.82501,
    "state_id": "CA"
  },
  {
    "zip": 95602,
    "lat": 38.99059,
    "lng": -121.11038,
    "state_id": "CA"
  },
  {
    "zip": 95603,
    "lat": 38.917,
    "lng": -121.08045,
    "state_id": "CA"
  },
  {
    "zip": 95604,
    "lat": 39.24336,
    "lng": -120.07154,
    "state_id": "CA"
  },
  {
    "zip": 95605,
    "lat": 38.59294,
    "lng": -121.5391,
    "state_id": "CA"
  },
  {
    "zip": 95606,
    "lat": 38.75605,
    "lng": -122.19543,
    "state_id": "CA"
  },
  {
    "zip": 95607,
    "lat": 38.79135,
    "lng": -122.12724,
    "state_id": "CA"
  },
  {
    "zip": 95608,
    "lat": 38.62583,
    "lng": -121.32829,
    "state_id": "CA"
  },
  {
    "zip": 95610,
    "lat": 38.69491,
    "lng": -121.27176,
    "state_id": "CA"
  },
  {
    "zip": 95612,
    "lat": 38.39227,
    "lng": -121.57571,
    "state_id": "CA"
  },
  {
    "zip": 95614,
    "lat": 38.88376,
    "lng": -120.98287,
    "state_id": "CA"
  },
  {
    "zip": 95615,
    "lat": 38.31429,
    "lng": -121.54646,
    "state_id": "CA"
  },
  {
    "zip": 95616,
    "lat": 38.55932,
    "lng": -121.7977,
    "state_id": "CA"
  },
  {
    "zip": 95618,
    "lat": 38.54067,
    "lng": -121.68305,
    "state_id": "CA"
  },
  {
    "zip": 95619,
    "lat": 38.6831,
    "lng": -120.81563,
    "state_id": "CA"
  },
  {
    "zip": 95620,
    "lat": 38.40971,
    "lng": -121.75299,
    "state_id": "CA"
  },
  {
    "zip": 95621,
    "lat": 38.69576,
    "lng": -121.30829,
    "state_id": "CA"
  },
  {
    "zip": 95623,
    "lat": 38.59673,
    "lng": -120.85855,
    "state_id": "CA"
  },
  {
    "zip": 95624,
    "lat": 38.43039,
    "lng": -121.30913,
    "state_id": "CA"
  },
  {
    "zip": 95625,
    "lat": 38.35811,
    "lng": -121.91151,
    "state_id": "CA"
  },
  {
    "zip": 95626,
    "lat": 38.73297,
    "lng": -121.46801,
    "state_id": "CA"
  },
  {
    "zip": 95627,
    "lat": 38.72619,
    "lng": -122.01421,
    "state_id": "CA"
  },
  {
    "zip": 95628,
    "lat": 38.65199,
    "lng": -121.25426,
    "state_id": "CA"
  },
  {
    "zip": 95629,
    "lat": 38.51645,
    "lng": -120.6885,
    "state_id": "CA"
  },
  {
    "zip": 95630,
    "lat": 38.66708,
    "lng": -121.14181,
    "state_id": "CA"
  },
  {
    "zip": 95631,
    "lat": 39.05606,
    "lng": -120.79093,
    "state_id": "CA"
  },
  {
    "zip": 95632,
    "lat": 38.27435,
    "lng": -121.25963,
    "state_id": "CA"
  },
  {
    "zip": 95633,
    "lat": 38.84782,
    "lng": -120.82584,
    "state_id": "CA"
  },
  {
    "zip": 95634,
    "lat": 38.93581,
    "lng": -120.77167,
    "state_id": "CA"
  },
  {
    "zip": 95635,
    "lat": 38.90496,
    "lng": -120.90861,
    "state_id": "CA"
  },
  {
    "zip": 95636,
    "lat": 38.62126,
    "lng": -120.38326,
    "state_id": "CA"
  },
  {
    "zip": 95637,
    "lat": 38.83411,
    "lng": -122.23689,
    "state_id": "CA"
  },
  {
    "zip": 95638,
    "lat": 38.33437,
    "lng": -121.1226,
    "state_id": "CA"
  },
  {
    "zip": 95639,
    "lat": 38.3903,
    "lng": -121.50015,
    "state_id": "CA"
  },
  {
    "zip": 95640,
    "lat": 38.32956,
    "lng": -120.94261,
    "state_id": "CA"
  },
  {
    "zip": 95641,
    "lat": 38.141,
    "lng": -121.59025,
    "state_id": "CA"
  },
  {
    "zip": 95642,
    "lat": 38.34262,
    "lng": -120.76101,
    "state_id": "CA"
  },
  {
    "zip": 95645,
    "lat": 38.88664,
    "lng": -121.78688,
    "state_id": "CA"
  },
  {
    "zip": 95646,
    "lat": 38.69154,
    "lng": -120.06181,
    "state_id": "CA"
  },
  {
    "zip": 95648,
    "lat": 38.9254,
    "lng": -121.31167,
    "state_id": "CA"
  },
  {
    "zip": 95650,
    "lat": 38.81283,
    "lng": -121.17077,
    "state_id": "CA"
  },
  {
    "zip": 95651,
    "lat": 38.81781,
    "lng": -120.92925,
    "state_id": "CA"
  },
  {
    "zip": 95652,
    "lat": 38.66309,
    "lng": -121.40115,
    "state_id": "CA"
  },
  {
    "zip": 95653,
    "lat": 38.69343,
    "lng": -121.97767,
    "state_id": "CA"
  },
  {
    "zip": 95655,
    "lat": 38.54957,
    "lng": -121.27931,
    "state_id": "CA"
  },
  {
    "zip": 95658,
    "lat": 38.87953,
    "lng": -121.15382,
    "state_id": "CA"
  },
  {
    "zip": 95659,
    "lat": 38.83993,
    "lng": -121.57134,
    "state_id": "CA"
  },
  {
    "zip": 95660,
    "lat": 38.67854,
    "lng": -121.37999,
    "state_id": "CA"
  },
  {
    "zip": 95661,
    "lat": 38.74132,
    "lng": -121.24908,
    "state_id": "CA"
  },
  {
    "zip": 95662,
    "lat": 38.68917,
    "lng": -121.21983,
    "state_id": "CA"
  },
  {
    "zip": 95663,
    "lat": 38.85575,
    "lng": -121.18231,
    "state_id": "CA"
  },
  {
    "zip": 95664,
    "lat": 38.79899,
    "lng": -121.05067,
    "state_id": "CA"
  },
  {
    "zip": 95665,
    "lat": 38.40208,
    "lng": -120.65144,
    "state_id": "CA"
  },
  {
    "zip": 95666,
    "lat": 38.53205,
    "lng": -120.36845,
    "state_id": "CA"
  },
  {
    "zip": 95667,
    "lat": 38.73526,
    "lng": -120.78985,
    "state_id": "CA"
  },
  {
    "zip": 95668,
    "lat": 38.82844,
    "lng": -121.49253,
    "state_id": "CA"
  },
  {
    "zip": 95669,
    "lat": 38.48221,
    "lng": -120.89838,
    "state_id": "CA"
  },
  {
    "zip": 95670,
    "lat": 38.60475,
    "lng": -121.28001,
    "state_id": "CA"
  },
  {
    "zip": 95672,
    "lat": 38.72527,
    "lng": -120.99801,
    "state_id": "CA"
  },
  {
    "zip": 95673,
    "lat": 38.68969,
    "lng": -121.46143,
    "state_id": "CA"
  },
  {
    "zip": 95674,
    "lat": 38.95417,
    "lng": -121.48186,
    "state_id": "CA"
  },
  {
    "zip": 95675,
    "lat": 38.54471,
    "lng": -120.74052,
    "state_id": "CA"
  },
  {
    "zip": 95677,
    "lat": 38.79134,
    "lng": -121.2344,
    "state_id": "CA"
  },
  {
    "zip": 95678,
    "lat": 38.76395,
    "lng": -121.28752,
    "state_id": "CA"
  },
  {
    "zip": 95679,
    "lat": 38.88594,
    "lng": -122.3272,
    "state_id": "CA"
  },
  {
    "zip": 95680,
    "lat": 38.23978,
    "lng": -121.58454,
    "state_id": "CA"
  },
  {
    "zip": 95681,
    "lat": 38.99797,
    "lng": -121.35289,
    "state_id": "CA"
  },
  {
    "zip": 95682,
    "lat": 38.61109,
    "lng": -120.96638,
    "state_id": "CA"
  },
  {
    "zip": 95683,
    "lat": 38.50764,
    "lng": -121.10153,
    "state_id": "CA"
  },
  {
    "zip": 95684,
    "lat": 38.59699,
    "lng": -120.58455,
    "state_id": "CA"
  },
  {
    "zip": 95685,
    "lat": 38.43107,
    "lng": -120.76783,
    "state_id": "CA"
  },
  {
    "zip": 95686,
    "lat": 38.19165,
    "lng": -121.48916,
    "state_id": "CA"
  },
  {
    "zip": 95687,
    "lat": 38.33691,
    "lng": -121.9204,
    "state_id": "CA"
  },
  {
    "zip": 95688,
    "lat": 38.40915,
    "lng": -122.02266,
    "state_id": "CA"
  },
  {
    "zip": 95689,
    "lat": 38.47885,
    "lng": -120.61202,
    "state_id": "CA"
  },
  {
    "zip": 95690,
    "lat": 38.2361,
    "lng": -121.5785,
    "state_id": "CA"
  },
  {
    "zip": 95691,
    "lat": 38.61708,
    "lng": -121.58234,
    "state_id": "CA"
  },
  {
    "zip": 95692,
    "lat": 39.04589,
    "lng": -121.40381,
    "state_id": "CA"
  },
  {
    "zip": 95693,
    "lat": 38.39999,
    "lng": -121.21256,
    "state_id": "CA"
  },
  {
    "zip": 95694,
    "lat": 38.55591,
    "lng": -122.00675,
    "state_id": "CA"
  },
  {
    "zip": 95695,
    "lat": 38.6871,
    "lng": -121.85704,
    "state_id": "CA"
  },
  {
    "zip": 95697,
    "lat": 38.73244,
    "lng": -121.81016,
    "state_id": "CA"
  },
  {
    "zip": 95698,
    "lat": 38.81752,
    "lng": -121.91213,
    "state_id": "CA"
  },
  {
    "zip": 95699,
    "lat": 38.4354,
    "lng": -120.85948,
    "state_id": "CA"
  },
  {
    "zip": 95701,
    "lat": 39.22737,
    "lng": -120.76634,
    "state_id": "CA"
  },
  {
    "zip": 95703,
    "lat": 38.9908,
    "lng": -120.98528,
    "state_id": "CA"
  },
  {
    "zip": 95709,
    "lat": 38.74883,
    "lng": -120.67938,
    "state_id": "CA"
  },
  {
    "zip": 95713,
    "lat": 39.08996,
    "lng": -120.91606,
    "state_id": "CA"
  },
  {
    "zip": 95714,
    "lat": 39.20125,
    "lng": -120.83932,
    "state_id": "CA"
  },
  {
    "zip": 95715,
    "lat": 39.26433,
    "lng": -120.67621,
    "state_id": "CA"
  },
  {
    "zip": 95717,
    "lat": 39.14458,
    "lng": -120.85264,
    "state_id": "CA"
  },
  {
    "zip": 95720,
    "lat": 38.76708,
    "lng": -120.2265,
    "state_id": "CA"
  },
  {
    "zip": 95721,
    "lat": 38.83448,
    "lng": -120.08184,
    "state_id": "CA"
  },
  {
    "zip": 95722,
    "lat": 39.00591,
    "lng": -121.02842,
    "state_id": "CA"
  },
  {
    "zip": 95724,
    "lat": 39.31171,
    "lng": -120.32804,
    "state_id": "CA"
  },
  {
    "zip": 95726,
    "lat": 38.80334,
    "lng": -120.51647,
    "state_id": "CA"
  },
  {
    "zip": 95728,
    "lat": 39.317,
    "lng": -120.42589,
    "state_id": "CA"
  },
  {
    "zip": 95735,
    "lat": 38.82061,
    "lng": -120.15228,
    "state_id": "CA"
  },
  {
    "zip": 95736,
    "lat": 39.03885,
    "lng": -120.97747,
    "state_id": "CA"
  },
  {
    "zip": 95742,
    "lat": 38.57508,
    "lng": -121.19836,
    "state_id": "CA"
  },
  {
    "zip": 95746,
    "lat": 38.75126,
    "lng": -121.18106,
    "state_id": "CA"
  },
  {
    "zip": 95747,
    "lat": 38.78145,
    "lng": -121.37295,
    "state_id": "CA"
  },
  {
    "zip": 95757,
    "lat": 38.34332,
    "lng": -121.43365,
    "state_id": "CA"
  },
  {
    "zip": 95758,
    "lat": 38.42788,
    "lng": -121.44343,
    "state_id": "CA"
  },
  {
    "zip": 95762,
    "lat": 38.6764,
    "lng": -121.05773,
    "state_id": "CA"
  },
  {
    "zip": 95765,
    "lat": 38.81861,
    "lng": -121.27807,
    "state_id": "CA"
  },
  {
    "zip": 95776,
    "lat": 38.6825,
    "lng": -121.70563,
    "state_id": "CA"
  },
  {
    "zip": 95811,
    "lat": 38.58747,
    "lng": -121.48516,
    "state_id": "CA"
  },
  {
    "zip": 95814,
    "lat": 38.58045,
    "lng": -121.49498,
    "state_id": "CA"
  },
  {
    "zip": 95815,
    "lat": 38.60548,
    "lng": -121.4473,
    "state_id": "CA"
  },
  {
    "zip": 95816,
    "lat": 38.57163,
    "lng": -121.46691,
    "state_id": "CA"
  },
  {
    "zip": 95817,
    "lat": 38.55059,
    "lng": -121.45643,
    "state_id": "CA"
  },
  {
    "zip": 95818,
    "lat": 38.55588,
    "lng": -121.49664,
    "state_id": "CA"
  },
  {
    "zip": 95819,
    "lat": 38.56964,
    "lng": -121.43974,
    "state_id": "CA"
  },
  {
    "zip": 95820,
    "lat": 38.53486,
    "lng": -121.44438,
    "state_id": "CA"
  },
  {
    "zip": 95821,
    "lat": 38.6259,
    "lng": -121.3845,
    "state_id": "CA"
  },
  {
    "zip": 95822,
    "lat": 38.51275,
    "lng": -121.4956,
    "state_id": "CA"
  },
  {
    "zip": 95823,
    "lat": 38.47408,
    "lng": -121.44347,
    "state_id": "CA"
  },
  {
    "zip": 95824,
    "lat": 38.51759,
    "lng": -121.44075,
    "state_id": "CA"
  },
  {
    "zip": 95825,
    "lat": 38.59036,
    "lng": -121.40641,
    "state_id": "CA"
  },
  {
    "zip": 95826,
    "lat": 38.54511,
    "lng": -121.37914,
    "state_id": "CA"
  },
  {
    "zip": 95827,
    "lat": 38.55504,
    "lng": -121.32555,
    "state_id": "CA"
  },
  {
    "zip": 95828,
    "lat": 38.48879,
    "lng": -121.39587,
    "state_id": "CA"
  },
  {
    "zip": 95829,
    "lat": 38.48924,
    "lng": -121.33249,
    "state_id": "CA"
  },
  {
    "zip": 95830,
    "lat": 38.49402,
    "lng": -121.27235,
    "state_id": "CA"
  },
  {
    "zip": 95831,
    "lat": 38.49593,
    "lng": -121.52936,
    "state_id": "CA"
  },
  {
    "zip": 95832,
    "lat": 38.44506,
    "lng": -121.49648,
    "state_id": "CA"
  },
  {
    "zip": 95833,
    "lat": 38.61564,
    "lng": -121.51585,
    "state_id": "CA"
  },
  {
    "zip": 95834,
    "lat": 38.64203,
    "lng": -121.52027,
    "state_id": "CA"
  },
  {
    "zip": 95835,
    "lat": 38.67075,
    "lng": -121.52581,
    "state_id": "CA"
  },
  {
    "zip": 95837,
    "lat": 38.70046,
    "lng": -121.5915,
    "state_id": "CA"
  },
  {
    "zip": 95838,
    "lat": 38.64679,
    "lng": -121.44495,
    "state_id": "CA"
  },
  {
    "zip": 95841,
    "lat": 38.66035,
    "lng": -121.34742,
    "state_id": "CA"
  },
  {
    "zip": 95842,
    "lat": 38.68673,
    "lng": -121.34919,
    "state_id": "CA"
  },
  {
    "zip": 95843,
    "lat": 38.71546,
    "lng": -121.36338,
    "state_id": "CA"
  },
  {
    "zip": 95864,
    "lat": 38.5853,
    "lng": -121.37627,
    "state_id": "CA"
  },
  {
    "zip": 95901,
    "lat": 39.22185,
    "lng": -121.49156,
    "state_id": "CA"
  },
  {
    "zip": 95903,
    "lat": 39.10251,
    "lng": -121.36833,
    "state_id": "CA"
  },
  {
    "zip": 95910,
    "lat": 39.47444,
    "lng": -120.82258,
    "state_id": "CA"
  },
  {
    "zip": 95912,
    "lat": 39.01635,
    "lng": -122.06195,
    "state_id": "CA"
  },
  {
    "zip": 95914,
    "lat": 39.40999,
    "lng": -121.36326,
    "state_id": "CA"
  },
  {
    "zip": 95915,
    "lat": 39.97548,
    "lng": -121.20866,
    "state_id": "CA"
  },
  {
    "zip": 95916,
    "lat": 39.66073,
    "lng": -121.36357,
    "state_id": "CA"
  },
  {
    "zip": 95917,
    "lat": 39.41906,
    "lng": -121.76252,
    "state_id": "CA"
  },
  {
    "zip": 95918,
    "lat": 39.29654,
    "lng": -121.3334,
    "state_id": "CA"
  },
  {
    "zip": 95919,
    "lat": 39.43289,
    "lng": -121.26113,
    "state_id": "CA"
  },
  {
    "zip": 95920,
    "lat": 39.46072,
    "lng": -121.93702,
    "state_id": "CA"
  },
  {
    "zip": 95922,
    "lat": 39.48489,
    "lng": -121.07192,
    "state_id": "CA"
  },
  {
    "zip": 95923,
    "lat": 40.15792,
    "lng": -121.1155,
    "state_id": "CA"
  },
  {
    "zip": 95925,
    "lat": 39.4689,
    "lng": -121.17952,
    "state_id": "CA"
  },
  {
    "zip": 95926,
    "lat": 39.7456,
    "lng": -121.84388,
    "state_id": "CA"
  },
  {
    "zip": 95928,
    "lat": 39.68377,
    "lng": -121.84454,
    "state_id": "CA"
  },
  {
    "zip": 95930,
    "lat": 39.5519,
    "lng": -121.19346,
    "state_id": "CA"
  },
  {
    "zip": 95932,
    "lat": 39.27203,
    "lng": -121.9988,
    "state_id": "CA"
  },
  {
    "zip": 95934,
    "lat": 40.07192,
    "lng": -120.92855,
    "state_id": "CA"
  },
  {
    "zip": 95935,
    "lat": 39.37755,
    "lng": -121.19117,
    "state_id": "CA"
  },
  {
    "zip": 95936,
    "lat": 39.60728,
    "lng": -120.79215,
    "state_id": "CA"
  },
  {
    "zip": 95937,
    "lat": 38.88522,
    "lng": -121.999,
    "state_id": "CA"
  },
  {
    "zip": 95938,
    "lat": 39.60303,
    "lng": -121.79879,
    "state_id": "CA"
  },
  {
    "zip": 95939,
    "lat": 39.56322,
    "lng": -122.59296,
    "state_id": "CA"
  },
  {
    "zip": 95941,
    "lat": 39.52098,
    "lng": -121.2424,
    "state_id": "CA"
  },
  {
    "zip": 95942,
    "lat": 40.01871,
    "lng": -121.56466,
    "state_id": "CA"
  },
  {
    "zip": 95943,
    "lat": 39.58572,
    "lng": -122.03669,
    "state_id": "CA"
  },
  {
    "zip": 95944,
    "lat": 39.49932,
    "lng": -120.93149,
    "state_id": "CA"
  },
  {
    "zip": 95945,
    "lat": 39.19509,
    "lng": -120.97699,
    "state_id": "CA"
  },
  {
    "zip": 95946,
    "lat": 39.21537,
    "lng": -121.20124,
    "state_id": "CA"
  },
  {
    "zip": 95947,
    "lat": 40.17456,
    "lng": -120.8197,
    "state_id": "CA"
  },
  {
    "zip": 95948,
    "lat": 39.34384,
    "lng": -121.76035,
    "state_id": "CA"
  },
  {
    "zip": 95949,
    "lat": 39.1026,
    "lng": -121.13515,
    "state_id": "CA"
  },
  {
    "zip": 95950,
    "lat": 39.04405,
    "lng": -121.92095,
    "state_id": "CA"
  },
  {
    "zip": 95951,
    "lat": 39.71694,
    "lng": -122.00226,
    "state_id": "CA"
  },
  {
    "zip": 95953,
    "lat": 39.2509,
    "lng": -121.7739,
    "state_id": "CA"
  },
  {
    "zip": 95954,
    "lat": 39.89153,
    "lng": -121.58525,
    "state_id": "CA"
  },
  {
    "zip": 95955,
    "lat": 39.29409,
    "lng": -122.20733,
    "state_id": "CA"
  },
  {
    "zip": 95956,
    "lat": 39.83806,
    "lng": -121.13636,
    "state_id": "CA"
  },
  {
    "zip": 95957,
    "lat": 39.06216,
    "lng": -121.82823,
    "state_id": "CA"
  },
  {
    "zip": 95959,
    "lat": 39.33853,
    "lng": -120.92058,
    "state_id": "CA"
  },
  {
    "zip": 95960,
    "lat": 39.38978,
    "lng": -121.04434,
    "state_id": "CA"
  },
  {
    "zip": 95961,
    "lat": 39.03344,
    "lng": -121.55758,
    "state_id": "CA"
  },
  {
    "zip": 95962,
    "lat": 39.34594,
    "lng": -121.26629,
    "state_id": "CA"
  },
  {
    "zip": 95963,
    "lat": 39.73634,
    "lng": -122.26205,
    "state_id": "CA"
  },
  {
    "zip": 95965,
    "lat": 39.5947,
    "lng": -121.59608,
    "state_id": "CA"
  },
  {
    "zip": 95966,
    "lat": 39.47123,
    "lng": -121.42821,
    "state_id": "CA"
  },
  {
    "zip": 95968,
    "lat": 39.43522,
    "lng": -121.55195,
    "state_id": "CA"
  },
  {
    "zip": 95969,
    "lat": 39.71633,
    "lng": -121.64742,
    "state_id": "CA"
  },
  {
    "zip": 95970,
    "lat": 39.40056,
    "lng": -122.06028,
    "state_id": "CA"
  },
  {
    "zip": 95971,
    "lat": 39.93721,
    "lng": -120.90026,
    "state_id": "CA"
  },
  {
    "zip": 95973,
    "lat": 39.8974,
    "lng": -121.84826,
    "state_id": "CA"
  },
  {
    "zip": 95974,
    "lat": 39.48023,
    "lng": -121.83853,
    "state_id": "CA"
  },
  {
    "zip": 95975,
    "lat": 39.22442,
    "lng": -121.15153,
    "state_id": "CA"
  },
  {
    "zip": 95977,
    "lat": 39.17693,
    "lng": -121.28798,
    "state_id": "CA"
  },
  {
    "zip": 95978,
    "lat": 39.87564,
    "lng": -121.53629,
    "state_id": "CA"
  },
  {
    "zip": 95979,
    "lat": 39.30145,
    "lng": -122.51586,
    "state_id": "CA"
  },
  {
    "zip": 95981,
    "lat": 39.63608,
    "lng": -121.03597,
    "state_id": "CA"
  },
  {
    "zip": 95982,
    "lat": 39.18314,
    "lng": -121.80465,
    "state_id": "CA"
  },
  {
    "zip": 95983,
    "lat": 40.07792,
    "lng": -120.70727,
    "state_id": "CA"
  },
  {
    "zip": 95984,
    "lat": 40.04096,
    "lng": -121.12268,
    "state_id": "CA"
  },
  {
    "zip": 95986,
    "lat": 39.34258,
    "lng": -120.77087,
    "state_id": "CA"
  },
  {
    "zip": 95987,
    "lat": 39.09609,
    "lng": -122.28249,
    "state_id": "CA"
  },
  {
    "zip": 95988,
    "lat": 39.50503,
    "lng": -122.27429,
    "state_id": "CA"
  },
  {
    "zip": 95991,
    "lat": 39.02249,
    "lng": -121.61286,
    "state_id": "CA"
  },
  {
    "zip": 95993,
    "lat": 39.08184,
    "lng": -121.68845,
    "state_id": "CA"
  },
  {
    "zip": 96001,
    "lat": 40.59648,
    "lng": -122.46004,
    "state_id": "CA"
  },
  {
    "zip": 96002,
    "lat": 40.53023,
    "lng": -122.31734,
    "state_id": "CA"
  },
  {
    "zip": 96003,
    "lat": 40.74375,
    "lng": -122.2387,
    "state_id": "CA"
  },
  {
    "zip": 96006,
    "lat": 41.17425,
    "lng": -120.85292,
    "state_id": "CA"
  },
  {
    "zip": 96007,
    "lat": 40.45867,
    "lng": -122.27916,
    "state_id": "CA"
  },
  {
    "zip": 96008,
    "lat": 40.71236,
    "lng": -122.10816,
    "state_id": "CA"
  },
  {
    "zip": 96009,
    "lat": 41.06347,
    "lng": -121.04102,
    "state_id": "CA"
  },
  {
    "zip": 96010,
    "lat": 40.91942,
    "lng": -123.24622,
    "state_id": "CA"
  },
  {
    "zip": 96011,
    "lat": 41.05006,
    "lng": -121.98342,
    "state_id": "CA"
  },
  {
    "zip": 96013,
    "lat": 41.0015,
    "lng": -121.68639,
    "state_id": "CA"
  },
  {
    "zip": 96014,
    "lat": 41.33895,
    "lng": -122.77793,
    "state_id": "CA"
  },
  {
    "zip": 96015,
    "lat": 41.50377,
    "lng": -120.91725,
    "state_id": "CA"
  },
  {
    "zip": 96016,
    "lat": 40.91155,
    "lng": -121.52318,
    "state_id": "CA"
  },
  {
    "zip": 96017,
    "lat": 41.11961,
    "lng": -122.27029,
    "state_id": "CA"
  },
  {
    "zip": 96019,
    "lat": 40.67917,
    "lng": -122.37743,
    "state_id": "CA"
  },
  {
    "zip": 96020,
    "lat": 40.367,
    "lng": -121.29632,
    "state_id": "CA"
  },
  {
    "zip": 96021,
    "lat": 39.92648,
    "lng": -122.26527,
    "state_id": "CA"
  },
  {
    "zip": 96022,
    "lat": 40.33505,
    "lng": -122.447,
    "state_id": "CA"
  },
  {
    "zip": 96023,
    "lat": 41.92683,
    "lng": -121.9071,
    "state_id": "CA"
  },
  {
    "zip": 96024,
    "lat": 40.5754,
    "lng": -122.89042,
    "state_id": "CA"
  },
  {
    "zip": 96025,
    "lat": 41.21073,
    "lng": -122.31349,
    "state_id": "CA"
  },
  {
    "zip": 96027,
    "lat": 41.3839,
    "lng": -123.06878,
    "state_id": "CA"
  },
  {
    "zip": 96028,
    "lat": 41.01058,
    "lng": -121.47205,
    "state_id": "CA"
  },
  {
    "zip": 96029,
    "lat": 39.94207,
    "lng": -122.47811,
    "state_id": "CA"
  },
  {
    "zip": 96031,
    "lat": 41.18353,
    "lng": -123.18184,
    "state_id": "CA"
  },
  {
    "zip": 96032,
    "lat": 41.60675,
    "lng": -122.96422,
    "state_id": "CA"
  },
  {
    "zip": 96033,
    "lat": 40.76366,
    "lng": -122.56918,
    "state_id": "CA"
  },
  {
    "zip": 96034,
    "lat": 41.43185,
    "lng": -122.61543,
    "state_id": "CA"
  },
  {
    "zip": 96035,
    "lat": 40.04685,
    "lng": -122.18201,
    "state_id": "CA"
  },
  {
    "zip": 96037,
    "lat": 41.54799,
    "lng": -122.93506,
    "state_id": "CA"
  },
  {
    "zip": 96038,
    "lat": 41.60892,
    "lng": -122.54643,
    "state_id": "CA"
  },
  {
    "zip": 96039,
    "lat": 41.69298,
    "lng": -123.48892,
    "state_id": "CA"
  },
  {
    "zip": 96040,
    "lat": 40.77872,
    "lng": -121.42273,
    "state_id": "CA"
  },
  {
    "zip": 96041,
    "lat": 40.52362,
    "lng": -123.19445,
    "state_id": "CA"
  },
  {
    "zip": 96044,
    "lat": 41.94084,
    "lng": -122.52007,
    "state_id": "CA"
  },
  {
    "zip": 96046,
    "lat": 40.59968,
    "lng": -123.42904,
    "state_id": "CA"
  },
  {
    "zip": 96047,
    "lat": 40.4643,
    "lng": -122.66396,
    "state_id": "CA"
  },
  {
    "zip": 96048,
    "lat": 40.82089,
    "lng": -123.0521,
    "state_id": "CA"
  },
  {
    "zip": 96049,
    "lat": 41.76839,
    "lng": -123.32588,
    "state_id": "CA"
  },
  {
    "zip": 96050,
    "lat": 41.90006,
    "lng": -122.88684,
    "state_id": "CA"
  },
  {
    "zip": 96051,
    "lat": 40.9326,
    "lng": -122.4031,
    "state_id": "CA"
  },
  {
    "zip": 96052,
    "lat": 40.7282,
    "lng": -122.81539,
    "state_id": "CA"
  },
  {
    "zip": 96054,
    "lat": 41.26648,
    "lng": -121.10043,
    "state_id": "CA"
  },
  {
    "zip": 96055,
    "lat": 40.08519,
    "lng": -122.03562,
    "state_id": "CA"
  },
  {
    "zip": 96056,
    "lat": 41.02369,
    "lng": -121.28308,
    "state_id": "CA"
  },
  {
    "zip": 96057,
    "lat": 41.26795,
    "lng": -121.93612,
    "state_id": "CA"
  },
  {
    "zip": 96058,
    "lat": 41.72783,
    "lng": -121.93254,
    "state_id": "CA"
  },
  {
    "zip": 96059,
    "lat": 40.4275,
    "lng": -121.83234,
    "state_id": "CA"
  },
  {
    "zip": 96061,
    "lat": 40.33076,
    "lng": -121.48649,
    "state_id": "CA"
  },
  {
    "zip": 96062,
    "lat": 40.57391,
    "lng": -122.07108,
    "state_id": "CA"
  },
  {
    "zip": 96063,
    "lat": 40.36022,
    "lng": -121.60481,
    "state_id": "CA"
  },
  {
    "zip": 96064,
    "lat": 41.75995,
    "lng": -122.36209,
    "state_id": "CA"
  },
  {
    "zip": 96065,
    "lat": 40.88819,
    "lng": -121.88087,
    "state_id": "CA"
  },
  {
    "zip": 96067,
    "lat": 41.30912,
    "lng": -122.36782,
    "state_id": "CA"
  },
  {
    "zip": 96068,
    "lat": 41.11529,
    "lng": -121.20869,
    "state_id": "CA"
  },
  {
    "zip": 96069,
    "lat": 40.68677,
    "lng": -122.0098,
    "state_id": "CA"
  },
  {
    "zip": 96071,
    "lat": 40.66364,
    "lng": -121.45879,
    "state_id": "CA"
  },
  {
    "zip": 96073,
    "lat": 40.59529,
    "lng": -122.20186,
    "state_id": "CA"
  },
  {
    "zip": 96074,
    "lat": 39.86746,
    "lng": -122.59372,
    "state_id": "CA"
  },
  {
    "zip": 96075,
    "lat": 40.33852,
    "lng": -121.84893,
    "state_id": "CA"
  },
  {
    "zip": 96076,
    "lat": 40.38277,
    "lng": -122.92755,
    "state_id": "CA"
  },
  {
    "zip": 96080,
    "lat": 40.16515,
    "lng": -122.38167,
    "state_id": "CA"
  },
  {
    "zip": 96084,
    "lat": 40.85035,
    "lng": -121.96886,
    "state_id": "CA"
  },
  {
    "zip": 96085,
    "lat": 41.75231,
    "lng": -123.05265,
    "state_id": "CA"
  },
  {
    "zip": 96086,
    "lat": 41.87458,
    "lng": -123.22039,
    "state_id": "CA"
  },
  {
    "zip": 96087,
    "lat": 40.62393,
    "lng": -122.61601,
    "state_id": "CA"
  },
  {
    "zip": 96088,
    "lat": 40.50989,
    "lng": -121.8795,
    "state_id": "CA"
  },
  {
    "zip": 96090,
    "lat": 40.02045,
    "lng": -122.12906,
    "state_id": "CA"
  },
  {
    "zip": 96091,
    "lat": 41.03584,
    "lng": -122.78817,
    "state_id": "CA"
  },
  {
    "zip": 96092,
    "lat": 39.96245,
    "lng": -122.01554,
    "state_id": "CA"
  },
  {
    "zip": 96093,
    "lat": 40.75873,
    "lng": -122.93795,
    "state_id": "CA"
  },
  {
    "zip": 96094,
    "lat": 41.53313,
    "lng": -122.31791,
    "state_id": "CA"
  },
  {
    "zip": 96096,
    "lat": 40.64528,
    "lng": -121.8394,
    "state_id": "CA"
  },
  {
    "zip": 96097,
    "lat": 41.75581,
    "lng": -122.67266,
    "state_id": "CA"
  },
  {
    "zip": 96101,
    "lat": 41.45587,
    "lng": -120.53619,
    "state_id": "CA"
  },
  {
    "zip": 96103,
    "lat": 39.80115,
    "lng": -120.66082,
    "state_id": "CA"
  },
  {
    "zip": 96104,
    "lat": 41.51614,
    "lng": -120.12916,
    "state_id": "CA"
  },
  {
    "zip": 96105,
    "lat": 39.83341,
    "lng": -120.17841,
    "state_id": "CA"
  },
  {
    "zip": 96106,
    "lat": 39.73671,
    "lng": -120.54374,
    "state_id": "CA"
  },
  {
    "zip": 96107,
    "lat": 38.48032,
    "lng": -119.47278,
    "state_id": "CA"
  },
  {
    "zip": 96108,
    "lat": 41.77515,
    "lng": -120.44572,
    "state_id": "CA"
  },
  {
    "zip": 96109,
    "lat": 40.0196,
    "lng": -120.09784,
    "state_id": "CA"
  },
  {
    "zip": 96110,
    "lat": 41.29329,
    "lng": -120.09354,
    "state_id": "CA"
  },
  {
    "zip": 96111,
    "lat": 39.47476,
    "lng": -120.04144,
    "state_id": "CA"
  },
  {
    "zip": 96112,
    "lat": 41.86019,
    "lng": -120.10601,
    "state_id": "CA"
  },
  {
    "zip": 96113,
    "lat": 40.14289,
    "lng": -120.15859,
    "state_id": "CA"
  },
  {
    "zip": 96114,
    "lat": 40.29473,
    "lng": -120.49696,
    "state_id": "CA"
  },
  {
    "zip": 96115,
    "lat": 41.69864,
    "lng": -120.14951,
    "state_id": "CA"
  },
  {
    "zip": 96116,
    "lat": 41.26319,
    "lng": -120.42208,
    "state_id": "CA"
  },
  {
    "zip": 96117,
    "lat": 40.54621,
    "lng": -120.23999,
    "state_id": "CA"
  },
  {
    "zip": 96118,
    "lat": 39.63884,
    "lng": -120.22928,
    "state_id": "CA"
  },
  {
    "zip": 96119,
    "lat": 41.02327,
    "lng": -120.52529,
    "state_id": "CA"
  },
  {
    "zip": 96120,
    "lat": 38.75366,
    "lng": -119.83344,
    "state_id": "CA"
  },
  {
    "zip": 96121,
    "lat": 40.16886,
    "lng": -120.37315,
    "state_id": "CA"
  },
  {
    "zip": 96122,
    "lat": 39.82647,
    "lng": -120.47237,
    "state_id": "CA"
  },
  {
    "zip": 96123,
    "lat": 40.81018,
    "lng": -120.29682,
    "state_id": "CA"
  },
  {
    "zip": 96124,
    "lat": 39.64338,
    "lng": -120.45044,
    "state_id": "CA"
  },
  {
    "zip": 96125,
    "lat": 39.5957,
    "lng": -120.62775,
    "state_id": "CA"
  },
  {
    "zip": 96126,
    "lat": 39.52992,
    "lng": -120.45679,
    "state_id": "CA"
  },
  {
    "zip": 96128,
    "lat": 40.35521,
    "lng": -120.41054,
    "state_id": "CA"
  },
  {
    "zip": 96129,
    "lat": 39.78757,
    "lng": -120.35564,
    "state_id": "CA"
  },
  {
    "zip": 96130,
    "lat": 40.54876,
    "lng": -120.68496,
    "state_id": "CA"
  },
  {
    "zip": 96132,
    "lat": 40.89059,
    "lng": -120.37772,
    "state_id": "CA"
  },
  {
    "zip": 96133,
    "lat": 38.63289,
    "lng": -119.50411,
    "state_id": "CA"
  },
  {
    "zip": 96134,
    "lat": 41.83835,
    "lng": -121.40661,
    "state_id": "CA"
  },
  {
    "zip": 96135,
    "lat": 39.80868,
    "lng": -120.20566,
    "state_id": "CA"
  },
  {
    "zip": 96136,
    "lat": 40.30836,
    "lng": -120.11896,
    "state_id": "CA"
  },
  {
    "zip": 96137,
    "lat": 40.2688,
    "lng": -121.02969,
    "state_id": "CA"
  },
  {
    "zip": 96140,
    "lat": 39.22463,
    "lng": -120.09807,
    "state_id": "CA"
  },
  {
    "zip": 96141,
    "lat": 39.07853,
    "lng": -120.17341,
    "state_id": "CA"
  },
  {
    "zip": 96142,
    "lat": 38.99948,
    "lng": -120.12581,
    "state_id": "CA"
  },
  {
    "zip": 96143,
    "lat": 39.25155,
    "lng": -120.02471,
    "state_id": "CA"
  },
  {
    "zip": 96145,
    "lat": 39.14738,
    "lng": -120.18665,
    "state_id": "CA"
  },
  {
    "zip": 96146,
    "lat": 39.19846,
    "lng": -120.23902,
    "state_id": "CA"
  },
  {
    "zip": 96148,
    "lat": 39.24685,
    "lng": -120.05806,
    "state_id": "CA"
  },
  {
    "zip": 96150,
    "lat": 38.8709,
    "lng": -120.00886,
    "state_id": "CA"
  },
  {
    "zip": 96155,
    "lat": 38.75192,
    "lng": -120.0917,
    "state_id": "CA"
  },
  {
    "zip": 96161,
    "lat": 39.30962,
    "lng": -120.20989,
    "state_id": "CA"
  },
  {
    "zip": 80002,
    "lat": 39.79503,
    "lng": -105.10586,
    "state_id": "CO"
  },
  {
    "zip": 80003,
    "lat": 39.82648,
    "lng": -105.06329,
    "state_id": "CO"
  },
  {
    "zip": 80004,
    "lat": 39.81473,
    "lng": -105.12355,
    "state_id": "CO"
  },
  {
    "zip": 80005,
    "lat": 39.85094,
    "lng": -105.13034,
    "state_id": "CO"
  },
  {
    "zip": 80007,
    "lat": 39.86605,
    "lng": -105.19937,
    "state_id": "CO"
  },
  {
    "zip": 80010,
    "lat": 39.73868,
    "lng": -104.86256,
    "state_id": "CO"
  },
  {
    "zip": 80011,
    "lat": 39.73895,
    "lng": -104.78318,
    "state_id": "CO"
  },
  {
    "zip": 80012,
    "lat": 39.69975,
    "lng": -104.83759,
    "state_id": "CO"
  },
  {
    "zip": 80013,
    "lat": 39.66138,
    "lng": -104.7657,
    "state_id": "CO"
  },
  {
    "zip": 80014,
    "lat": 39.66354,
    "lng": -104.83815,
    "state_id": "CO"
  },
  {
    "zip": 80015,
    "lat": 39.62598,
    "lng": -104.77999,
    "state_id": "CO"
  },
  {
    "zip": 80016,
    "lat": 39.59971,
    "lng": -104.70728,
    "state_id": "CO"
  },
  {
    "zip": 80017,
    "lat": 39.69762,
    "lng": -104.78588,
    "state_id": "CO"
  },
  {
    "zip": 80018,
    "lat": 39.69289,
    "lng": -104.69551,
    "state_id": "CO"
  },
  {
    "zip": 80019,
    "lat": 39.78515,
    "lng": -104.70191,
    "state_id": "CO"
  },
  {
    "zip": 80020,
    "lat": 39.93182,
    "lng": -105.07453,
    "state_id": "CO"
  },
  {
    "zip": 80021,
    "lat": 39.89096,
    "lng": -105.11448,
    "state_id": "CO"
  },
  {
    "zip": 80022,
    "lat": 39.87949,
    "lng": -104.79787,
    "state_id": "CO"
  },
  {
    "zip": 80023,
    "lat": 39.97242,
    "lng": -105.01092,
    "state_id": "CO"
  },
  {
    "zip": 80024,
    "lat": 39.84378,
    "lng": -104.91798,
    "state_id": "CO"
  },
  {
    "zip": 80025,
    "lat": 39.94509,
    "lng": -105.29066,
    "state_id": "CO"
  },
  {
    "zip": 80026,
    "lat": 40.01317,
    "lng": -105.09805,
    "state_id": "CO"
  },
  {
    "zip": 80027,
    "lat": 39.95067,
    "lng": -105.16391,
    "state_id": "CO"
  },
  {
    "zip": 80030,
    "lat": 39.83012,
    "lng": -105.0369,
    "state_id": "CO"
  },
  {
    "zip": 80031,
    "lat": 39.8758,
    "lng": -105.04066,
    "state_id": "CO"
  },
  {
    "zip": 80033,
    "lat": 39.77309,
    "lng": -105.1022,
    "state_id": "CO"
  },
  {
    "zip": 80045,
    "lat": 39.74752,
    "lng": -104.83861,
    "state_id": "CO"
  },
  {
    "zip": 80101,
    "lat": 39.39269,
    "lng": -104.02852,
    "state_id": "CO"
  },
  {
    "zip": 80102,
    "lat": 39.74095,
    "lng": -104.44391,
    "state_id": "CO"
  },
  {
    "zip": 80103,
    "lat": 39.78128,
    "lng": -104.13663,
    "state_id": "CO"
  },
  {
    "zip": 80104,
    "lat": 39.30195,
    "lng": -104.81809,
    "state_id": "CO"
  },
  {
    "zip": 80105,
    "lat": 39.66096,
    "lng": -103.96166,
    "state_id": "CO"
  },
  {
    "zip": 80106,
    "lat": 39.17265,
    "lng": -104.52902,
    "state_id": "CO"
  },
  {
    "zip": 80107,
    "lat": 39.40961,
    "lng": -104.57244,
    "state_id": "CO"
  },
  {
    "zip": 80108,
    "lat": 39.44551,
    "lng": -104.85303,
    "state_id": "CO"
  },
  {
    "zip": 80109,
    "lat": 39.36426,
    "lng": -104.90136,
    "state_id": "CO"
  },
  {
    "zip": 80110,
    "lat": 39.64629,
    "lng": -105.00921,
    "state_id": "CO"
  },
  {
    "zip": 80111,
    "lat": 39.61228,
    "lng": -104.8798,
    "state_id": "CO"
  },
  {
    "zip": 80112,
    "lat": 39.57258,
    "lng": -104.8574,
    "state_id": "CO"
  },
  {
    "zip": 80113,
    "lat": 39.64216,
    "lng": -104.96251,
    "state_id": "CO"
  },
  {
    "zip": 80116,
    "lat": 39.30721,
    "lng": -104.71756,
    "state_id": "CO"
  },
  {
    "zip": 80117,
    "lat": 39.36468,
    "lng": -104.36143,
    "state_id": "CO"
  },
  {
    "zip": 80118,
    "lat": 39.19239,
    "lng": -104.89199,
    "state_id": "CO"
  },
  {
    "zip": 80120,
    "lat": 39.5933,
    "lng": -105.00956,
    "state_id": "CO"
  },
  {
    "zip": 80121,
    "lat": 39.61115,
    "lng": -104.9532,
    "state_id": "CO"
  },
  {
    "zip": 80122,
    "lat": 39.58062,
    "lng": -104.95591,
    "state_id": "CO"
  },
  {
    "zip": 80123,
    "lat": 39.61583,
    "lng": -105.06888,
    "state_id": "CO"
  },
  {
    "zip": 80124,
    "lat": 39.53184,
    "lng": -104.89207,
    "state_id": "CO"
  },
  {
    "zip": 80125,
    "lat": 39.48598,
    "lng": -105.05018,
    "state_id": "CO"
  },
  {
    "zip": 80126,
    "lat": 39.54073,
    "lng": -104.96083,
    "state_id": "CO"
  },
  {
    "zip": 80127,
    "lat": 39.54044,
    "lng": -105.15135,
    "state_id": "CO"
  },
  {
    "zip": 80128,
    "lat": 39.5641,
    "lng": -105.07865,
    "state_id": "CO"
  },
  {
    "zip": 80129,
    "lat": 39.54463,
    "lng": -105.01081,
    "state_id": "CO"
  },
  {
    "zip": 80130,
    "lat": 39.53062,
    "lng": -104.92323,
    "state_id": "CO"
  },
  {
    "zip": 80131,
    "lat": 39.4764,
    "lng": -105.00751,
    "state_id": "CO"
  },
  {
    "zip": 80132,
    "lat": 39.09814,
    "lng": -104.8472,
    "state_id": "CO"
  },
  {
    "zip": 80133,
    "lat": 39.09972,
    "lng": -104.96952,
    "state_id": "CO"
  },
  {
    "zip": 80134,
    "lat": 39.48221,
    "lng": -104.77886,
    "state_id": "CO"
  },
  {
    "zip": 80135,
    "lat": 39.2793,
    "lng": -105.11819,
    "state_id": "CO"
  },
  {
    "zip": 80136,
    "lat": 39.79068,
    "lng": -104.29748,
    "state_id": "CO"
  },
  {
    "zip": 80137,
    "lat": 39.75788,
    "lng": -104.59287,
    "state_id": "CO"
  },
  {
    "zip": 80138,
    "lat": 39.51759,
    "lng": -104.67107,
    "state_id": "CO"
  },
  {
    "zip": 80202,
    "lat": 39.75193,
    "lng": -104.99776,
    "state_id": "CO"
  },
  {
    "zip": 80203,
    "lat": 39.73174,
    "lng": -104.98265,
    "state_id": "CO"
  },
  {
    "zip": 80204,
    "lat": 39.73489,
    "lng": -105.02035,
    "state_id": "CO"
  },
  {
    "zip": 80205,
    "lat": 39.75866,
    "lng": -104.96339,
    "state_id": "CO"
  },
  {
    "zip": 80206,
    "lat": 39.7303,
    "lng": -104.95259,
    "state_id": "CO"
  },
  {
    "zip": 80207,
    "lat": 39.76227,
    "lng": -104.91662,
    "state_id": "CO"
  },
  {
    "zip": 80209,
    "lat": 39.70599,
    "lng": -104.96594,
    "state_id": "CO"
  },
  {
    "zip": 80210,
    "lat": 39.67802,
    "lng": -104.96256,
    "state_id": "CO"
  },
  {
    "zip": 80211,
    "lat": 39.76706,
    "lng": -105.02009,
    "state_id": "CO"
  },
  {
    "zip": 80212,
    "lat": 39.77167,
    "lng": -105.04831,
    "state_id": "CO"
  },
  {
    "zip": 80214,
    "lat": 39.74214,
    "lng": -105.0712,
    "state_id": "CO"
  },
  {
    "zip": 80215,
    "lat": 39.74409,
    "lng": -105.11592,
    "state_id": "CO"
  },
  {
    "zip": 80216,
    "lat": 39.78839,
    "lng": -104.95672,
    "state_id": "CO"
  },
  {
    "zip": 80218,
    "lat": 39.73096,
    "lng": -104.97065,
    "state_id": "CO"
  },
  {
    "zip": 80219,
    "lat": 39.69538,
    "lng": -105.03438,
    "state_id": "CO"
  },
  {
    "zip": 80220,
    "lat": 39.7338,
    "lng": -104.91659,
    "state_id": "CO"
  },
  {
    "zip": 80221,
    "lat": 39.81542,
    "lng": -105.00955,
    "state_id": "CO"
  },
  {
    "zip": 80222,
    "lat": 39.67104,
    "lng": -104.92791,
    "state_id": "CO"
  },
  {
    "zip": 80223,
    "lat": 39.69617,
    "lng": -105.00186,
    "state_id": "CO"
  },
  {
    "zip": 80224,
    "lat": 39.68768,
    "lng": -104.91133,
    "state_id": "CO"
  },
  {
    "zip": 80226,
    "lat": 39.71106,
    "lng": -105.09138,
    "state_id": "CO"
  },
  {
    "zip": 80227,
    "lat": 39.66717,
    "lng": -105.08962,
    "state_id": "CO"
  },
  {
    "zip": 80228,
    "lat": 39.69002,
    "lng": -105.15677,
    "state_id": "CO"
  },
  {
    "zip": 80229,
    "lat": 39.85545,
    "lng": -104.9574,
    "state_id": "CO"
  },
  {
    "zip": 80230,
    "lat": 39.71946,
    "lng": -104.89031,
    "state_id": "CO"
  },
  {
    "zip": 80231,
    "lat": 39.67151,
    "lng": -104.88785,
    "state_id": "CO"
  },
  {
    "zip": 80232,
    "lat": 39.68853,
    "lng": -105.09048,
    "state_id": "CO"
  },
  {
    "zip": 80233,
    "lat": 39.89971,
    "lng": -104.9469,
    "state_id": "CO"
  },
  {
    "zip": 80234,
    "lat": 39.91226,
    "lng": -105.00553,
    "state_id": "CO"
  },
  {
    "zip": 80235,
    "lat": 39.6461,
    "lng": -105.08993,
    "state_id": "CO"
  },
  {
    "zip": 80236,
    "lat": 39.65165,
    "lng": -105.03962,
    "state_id": "CO"
  },
  {
    "zip": 80237,
    "lat": 39.64007,
    "lng": -104.90117,
    "state_id": "CO"
  },
  {
    "zip": 80238,
    "lat": 39.77159,
    "lng": -104.88236,
    "state_id": "CO"
  },
  {
    "zip": 80239,
    "lat": 39.7868,
    "lng": -104.83803,
    "state_id": "CO"
  },
  {
    "zip": 80241,
    "lat": 39.92873,
    "lng": -104.95508,
    "state_id": "CO"
  },
  {
    "zip": 80246,
    "lat": 39.70435,
    "lng": -104.93093,
    "state_id": "CO"
  },
  {
    "zip": 80247,
    "lat": 39.69715,
    "lng": -104.88179,
    "state_id": "CO"
  },
  {
    "zip": 80249,
    "lat": 39.84982,
    "lng": -104.69651,
    "state_id": "CO"
  },
  {
    "zip": 80260,
    "lat": 39.86679,
    "lng": -105.00605,
    "state_id": "CO"
  },
  {
    "zip": 80264,
    "lat": 39.74248,
    "lng": -104.98548,
    "state_id": "CO"
  },
  {
    "zip": 80290,
    "lat": 39.74409,
    "lng": -104.98675,
    "state_id": "CO"
  },
  {
    "zip": 80293,
    "lat": 39.74625,
    "lng": -104.98994,
    "state_id": "CO"
  },
  {
    "zip": 80294,
    "lat": 39.74945,
    "lng": -104.98927,
    "state_id": "CO"
  },
  {
    "zip": 80301,
    "lat": 40.04859,
    "lng": -105.20013,
    "state_id": "CO"
  },
  {
    "zip": 80302,
    "lat": 40.03893,
    "lng": -105.3691,
    "state_id": "CO"
  },
  {
    "zip": 80303,
    "lat": 39.9741,
    "lng": -105.21275,
    "state_id": "CO"
  },
  {
    "zip": 80304,
    "lat": 40.04547,
    "lng": -105.29048,
    "state_id": "CO"
  },
  {
    "zip": 80305,
    "lat": 39.97469,
    "lng": -105.2492,
    "state_id": "CO"
  },
  {
    "zip": 80310,
    "lat": 40.00378,
    "lng": -105.26138,
    "state_id": "CO"
  },
  {
    "zip": 80401,
    "lat": 39.71642,
    "lng": -105.2351,
    "state_id": "CO"
  },
  {
    "zip": 80403,
    "lat": 39.83088,
    "lng": -105.31503,
    "state_id": "CO"
  },
  {
    "zip": 80419,
    "lat": 39.72844,
    "lng": -105.20284,
    "state_id": "CO"
  },
  {
    "zip": 80420,
    "lat": 39.31679,
    "lng": -106.10381,
    "state_id": "CO"
  },
  {
    "zip": 80421,
    "lat": 39.47872,
    "lng": -105.52618,
    "state_id": "CO"
  },
  {
    "zip": 80422,
    "lat": 39.86909,
    "lng": -105.53237,
    "state_id": "CO"
  },
  {
    "zip": 80423,
    "lat": 39.89446,
    "lng": -106.59512,
    "state_id": "CO"
  },
  {
    "zip": 80424,
    "lat": 39.47099,
    "lng": -106.0169,
    "state_id": "CO"
  },
  {
    "zip": 80425,
    "lat": 39.34482,
    "lng": -105.22334,
    "state_id": "CO"
  },
  {
    "zip": 80426,
    "lat": 39.90844,
    "lng": -106.99145,
    "state_id": "CO"
  },
  {
    "zip": 80427,
    "lat": 39.78026,
    "lng": -105.49498,
    "state_id": "CO"
  },
  {
    "zip": 80428,
    "lat": 40.86096,
    "lng": -106.96086,
    "state_id": "CO"
  },
  {
    "zip": 80432,
    "lat": 39.22871,
    "lng": -105.81904,
    "state_id": "CO"
  },
  {
    "zip": 80433,
    "lat": 39.48949,
    "lng": -105.27378,
    "state_id": "CO"
  },
  {
    "zip": 80434,
    "lat": 40.85618,
    "lng": -106.29425,
    "state_id": "CO"
  },
  {
    "zip": 80435,
    "lat": 39.60145,
    "lng": -105.92428,
    "state_id": "CO"
  },
  {
    "zip": 80436,
    "lat": 39.78748,
    "lng": -105.64738,
    "state_id": "CO"
  },
  {
    "zip": 80438,
    "lat": 39.76029,
    "lng": -105.78335,
    "state_id": "CO"
  },
  {
    "zip": 80439,
    "lat": 39.6376,
    "lng": -105.43301,
    "state_id": "CO"
  },
  {
    "zip": 80440,
    "lat": 39.22625,
    "lng": -105.97815,
    "state_id": "CO"
  },
  {
    "zip": 80442,
    "lat": 39.9348,
    "lng": -105.88144,
    "state_id": "CO"
  },
  {
    "zip": 80443,
    "lat": 39.50537,
    "lng": -106.16294,
    "state_id": "CO"
  },
  {
    "zip": 80444,
    "lat": 39.63095,
    "lng": -105.73747,
    "state_id": "CO"
  },
  {
    "zip": 80446,
    "lat": 40.17423,
    "lng": -105.95253,
    "state_id": "CO"
  },
  {
    "zip": 80447,
    "lat": 40.27251,
    "lng": -105.79681,
    "state_id": "CO"
  },
  {
    "zip": 80448,
    "lat": 39.408,
    "lng": -105.63004,
    "state_id": "CO"
  },
  {
    "zip": 80449,
    "lat": 38.98134,
    "lng": -105.85574,
    "state_id": "CO"
  },
  {
    "zip": 80451,
    "lat": 40.09845,
    "lng": -106.07319,
    "state_id": "CO"
  },
  {
    "zip": 80452,
    "lat": 39.70716,
    "lng": -105.61389,
    "state_id": "CO"
  },
  {
    "zip": 80453,
    "lat": 39.66271,
    "lng": -105.24262,
    "state_id": "CO"
  },
  {
    "zip": 80454,
    "lat": 39.6295,
    "lng": -105.25135,
    "state_id": "CO"
  },
  {
    "zip": 80455,
    "lat": 40.11342,
    "lng": -105.39226,
    "state_id": "CO"
  },
  {
    "zip": 80456,
    "lat": 39.28408,
    "lng": -105.64459,
    "state_id": "CO"
  },
  {
    "zip": 80457,
    "lat": 39.64839,
    "lng": -105.29099,
    "state_id": "CO"
  },
  {
    "zip": 80459,
    "lat": 40.17599,
    "lng": -106.43491,
    "state_id": "CO"
  },
  {
    "zip": 80461,
    "lat": 39.23391,
    "lng": -106.31467,
    "state_id": "CO"
  },
  {
    "zip": 80463,
    "lat": 39.97325,
    "lng": -106.73027,
    "state_id": "CO"
  },
  {
    "zip": 80465,
    "lat": 39.60853,
    "lng": -105.20891,
    "state_id": "CO"
  },
  {
    "zip": 80466,
    "lat": 39.97599,
    "lng": -105.53851,
    "state_id": "CO"
  },
  {
    "zip": 80467,
    "lat": 40.25316,
    "lng": -106.86992,
    "state_id": "CO"
  },
  {
    "zip": 80468,
    "lat": 39.94766,
    "lng": -106.11892,
    "state_id": "CO"
  },
  {
    "zip": 80469,
    "lat": 40.205,
    "lng": -106.96463,
    "state_id": "CO"
  },
  {
    "zip": 80470,
    "lat": 39.36775,
    "lng": -105.34356,
    "state_id": "CO"
  },
  {
    "zip": 80471,
    "lat": 39.93527,
    "lng": -105.42114,
    "state_id": "CO"
  },
  {
    "zip": 80473,
    "lat": 40.42501,
    "lng": -106.13465,
    "state_id": "CO"
  },
  {
    "zip": 80475,
    "lat": 39.45201,
    "lng": -105.56006,
    "state_id": "CO"
  },
  {
    "zip": 80476,
    "lat": 39.68542,
    "lng": -105.81121,
    "state_id": "CO"
  },
  {
    "zip": 80477,
    "lat": 40.48635,
    "lng": -106.82938,
    "state_id": "CO"
  },
  {
    "zip": 80478,
    "lat": 40.02791,
    "lng": -105.84699,
    "state_id": "CO"
  },
  {
    "zip": 80479,
    "lat": 40.03184,
    "lng": -106.83314,
    "state_id": "CO"
  },
  {
    "zip": 80480,
    "lat": 40.67294,
    "lng": -106.35241,
    "state_id": "CO"
  },
  {
    "zip": 80481,
    "lat": 40.0896,
    "lng": -105.54587,
    "state_id": "CO"
  },
  {
    "zip": 80482,
    "lat": 39.89395,
    "lng": -105.78447,
    "state_id": "CO"
  },
  {
    "zip": 80483,
    "lat": 40.11979,
    "lng": -106.91774,
    "state_id": "CO"
  },
  {
    "zip": 80487,
    "lat": 40.548,
    "lng": -106.86711,
    "state_id": "CO"
  },
  {
    "zip": 80488,
    "lat": 40.44905,
    "lng": -106.81812,
    "state_id": "CO"
  },
  {
    "zip": 80497,
    "lat": 39.67079,
    "lng": -105.99996,
    "state_id": "CO"
  },
  {
    "zip": 80498,
    "lat": 39.77488,
    "lng": -106.22716,
    "state_id": "CO"
  },
  {
    "zip": 80501,
    "lat": 40.16475,
    "lng": -105.10293,
    "state_id": "CO"
  },
  {
    "zip": 80503,
    "lat": 40.17005,
    "lng": -105.20362,
    "state_id": "CO"
  },
  {
    "zip": 80504,
    "lat": 40.16391,
    "lng": -105.0289,
    "state_id": "CO"
  },
  {
    "zip": 80510,
    "lat": 40.20871,
    "lng": -105.57862,
    "state_id": "CO"
  },
  {
    "zip": 80511,
    "lat": 40.34002,
    "lng": -105.57227,
    "state_id": "CO"
  },
  {
    "zip": 80512,
    "lat": 40.63401,
    "lng": -105.57322,
    "state_id": "CO"
  },
  {
    "zip": 80513,
    "lat": 40.29802,
    "lng": -105.10365,
    "state_id": "CO"
  },
  {
    "zip": 80514,
    "lat": 40.06592,
    "lng": -104.95832,
    "state_id": "CO"
  },
  {
    "zip": 80515,
    "lat": 40.44443,
    "lng": -105.37528,
    "state_id": "CO"
  },
  {
    "zip": 80516,
    "lat": 40.05124,
    "lng": -105.01837,
    "state_id": "CO"
  },
  {
    "zip": 80517,
    "lat": 40.40027,
    "lng": -105.61164,
    "state_id": "CO"
  },
  {
    "zip": 80520,
    "lat": 40.11313,
    "lng": -104.93148,
    "state_id": "CO"
  },
  {
    "zip": 80521,
    "lat": 40.59325,
    "lng": -105.12777,
    "state_id": "CO"
  },
  {
    "zip": 80524,
    "lat": 40.65295,
    "lng": -105.02629,
    "state_id": "CO"
  },
  {
    "zip": 80525,
    "lat": 40.52921,
    "lng": -105.03706,
    "state_id": "CO"
  },
  {
    "zip": 80526,
    "lat": 40.52425,
    "lng": -105.14003,
    "state_id": "CO"
  },
  {
    "zip": 80528,
    "lat": 40.49615,
    "lng": -105.00023,
    "state_id": "CO"
  },
  {
    "zip": 80530,
    "lat": 40.09779,
    "lng": -104.92927,
    "state_id": "CO"
  },
  {
    "zip": 80532,
    "lat": 40.49323,
    "lng": -105.46868,
    "state_id": "CO"
  },
  {
    "zip": 80534,
    "lat": 40.332,
    "lng": -104.9354,
    "state_id": "CO"
  },
  {
    "zip": 80535,
    "lat": 40.7334,
    "lng": -105.18355,
    "state_id": "CO"
  },
  {
    "zip": 80536,
    "lat": 40.87003,
    "lng": -105.37666,
    "state_id": "CO"
  },
  {
    "zip": 80537,
    "lat": 40.37543,
    "lng": -105.1829,
    "state_id": "CO"
  },
  {
    "zip": 80538,
    "lat": 40.47885,
    "lng": -105.17397,
    "state_id": "CO"
  },
  {
    "zip": 80540,
    "lat": 40.24191,
    "lng": -105.38334,
    "state_id": "CO"
  },
  {
    "zip": 80542,
    "lat": 40.23473,
    "lng": -104.99937,
    "state_id": "CO"
  },
  {
    "zip": 80543,
    "lat": 40.34565,
    "lng": -104.85143,
    "state_id": "CO"
  },
  {
    "zip": 80544,
    "lat": 40.10345,
    "lng": -105.17108,
    "state_id": "CO"
  },
  {
    "zip": 80545,
    "lat": 40.86593,
    "lng": -105.68927,
    "state_id": "CO"
  },
  {
    "zip": 80546,
    "lat": 40.52522,
    "lng": -104.8497,
    "state_id": "CO"
  },
  {
    "zip": 80547,
    "lat": 40.52499,
    "lng": -104.96359,
    "state_id": "CO"
  },
  {
    "zip": 80549,
    "lat": 40.85312,
    "lng": -105.04552,
    "state_id": "CO"
  },
  {
    "zip": 80550,
    "lat": 40.48095,
    "lng": -104.90024,
    "state_id": "CO"
  },
  {
    "zip": 80601,
    "lat": 39.96254,
    "lng": -104.80879,
    "state_id": "CO"
  },
  {
    "zip": 80602,
    "lat": 39.96364,
    "lng": -104.90721,
    "state_id": "CO"
  },
  {
    "zip": 80603,
    "lat": 39.98259,
    "lng": -104.73715,
    "state_id": "CO"
  },
  {
    "zip": 80610,
    "lat": 40.66737,
    "lng": -104.59555,
    "state_id": "CO"
  },
  {
    "zip": 80611,
    "lat": 40.62306,
    "lng": -104.28168,
    "state_id": "CO"
  },
  {
    "zip": 80612,
    "lat": 40.8798,
    "lng": -104.86665,
    "state_id": "CO"
  },
  {
    "zip": 80615,
    "lat": 40.54556,
    "lng": -104.64418,
    "state_id": "CO"
  },
  {
    "zip": 80620,
    "lat": 40.37451,
    "lng": -104.71701,
    "state_id": "CO"
  },
  {
    "zip": 80621,
    "lat": 40.10796,
    "lng": -104.80121,
    "state_id": "CO"
  },
  {
    "zip": 80622,
    "lat": 40.53776,
    "lng": -104.4585,
    "state_id": "CO"
  },
  {
    "zip": 80623,
    "lat": 40.28536,
    "lng": -104.78252,
    "state_id": "CO"
  },
  {
    "zip": 80624,
    "lat": 40.48537,
    "lng": -104.49928,
    "state_id": "CO"
  },
  {
    "zip": 80631,
    "lat": 40.44141,
    "lng": -104.6763,
    "state_id": "CO"
  },
  {
    "zip": 80634,
    "lat": 40.40277,
    "lng": -104.7925,
    "state_id": "CO"
  },
  {
    "zip": 80640,
    "lat": 39.88544,
    "lng": -104.88215,
    "state_id": "CO"
  },
  {
    "zip": 80642,
    "lat": 40.04671,
    "lng": -104.61521,
    "state_id": "CO"
  },
  {
    "zip": 80643,
    "lat": 40.11396,
    "lng": -104.47712,
    "state_id": "CO"
  },
  {
    "zip": 80644,
    "lat": 40.37751,
    "lng": -104.43846,
    "state_id": "CO"
  },
  {
    "zip": 80645,
    "lat": 40.28354,
    "lng": -104.58073,
    "state_id": "CO"
  },
  {
    "zip": 80648,
    "lat": 40.75506,
    "lng": -104.7396,
    "state_id": "CO"
  },
  {
    "zip": 80649,
    "lat": 40.37925,
    "lng": -104.17672,
    "state_id": "CO"
  },
  {
    "zip": 80650,
    "lat": 40.64655,
    "lng": -104.7798,
    "state_id": "CO"
  },
  {
    "zip": 80651,
    "lat": 40.23685,
    "lng": -104.81557,
    "state_id": "CO"
  },
  {
    "zip": 80652,
    "lat": 40.11123,
    "lng": -104.28012,
    "state_id": "CO"
  },
  {
    "zip": 80653,
    "lat": 40.38844,
    "lng": -103.97814,
    "state_id": "CO"
  },
  {
    "zip": 80654,
    "lat": 40.13618,
    "lng": -104.07484,
    "state_id": "CO"
  },
  {
    "zip": 80701,
    "lat": 40.16995,
    "lng": -103.82645,
    "state_id": "CO"
  },
  {
    "zip": 80705,
    "lat": 40.27024,
    "lng": -103.82927,
    "state_id": "CO"
  },
  {
    "zip": 80720,
    "lat": 40.0821,
    "lng": -103.21274,
    "state_id": "CO"
  },
  {
    "zip": 80721,
    "lat": 40.68965,
    "lng": -102.15394,
    "state_id": "CO"
  },
  {
    "zip": 80722,
    "lat": 40.5082,
    "lng": -103.27497,
    "state_id": "CO"
  },
  {
    "zip": 80723,
    "lat": 40.15002,
    "lng": -103.57075,
    "state_id": "CO"
  },
  {
    "zip": 80726,
    "lat": 40.90626,
    "lng": -102.79085,
    "state_id": "CO"
  },
  {
    "zip": 80727,
    "lat": 40.06044,
    "lng": -102.5039,
    "state_id": "CO"
  },
  {
    "zip": 80728,
    "lat": 40.64497,
    "lng": -102.87388,
    "state_id": "CO"
  },
  {
    "zip": 80729,
    "lat": 40.87932,
    "lng": -104.26502,
    "state_id": "CO"
  },
  {
    "zip": 80731,
    "lat": 40.59618,
    "lng": -102.60413,
    "state_id": "CO"
  },
  {
    "zip": 80733,
    "lat": 40.3707,
    "lng": -103.45197,
    "state_id": "CO"
  },
  {
    "zip": 80734,
    "lat": 40.51933,
    "lng": -102.29363,
    "state_id": "CO"
  },
  {
    "zip": 80735,
    "lat": 39.71567,
    "lng": -102.27253,
    "state_id": "CO"
  },
  {
    "zip": 80736,
    "lat": 40.81135,
    "lng": -103.03547,
    "state_id": "CO"
  },
  {
    "zip": 80737,
    "lat": 40.87971,
    "lng": -102.18508,
    "state_id": "CO"
  },
  {
    "zip": 80740,
    "lat": 39.73604,
    "lng": -103.38471,
    "state_id": "CO"
  },
  {
    "zip": 80741,
    "lat": 40.57082,
    "lng": -103.4719,
    "state_id": "CO"
  },
  {
    "zip": 80742,
    "lat": 40.74922,
    "lng": -103.82828,
    "state_id": "CO"
  },
  {
    "zip": 80743,
    "lat": 40.20086,
    "lng": -102.95113,
    "state_id": "CO"
  },
  {
    "zip": 80744,
    "lat": 40.87797,
    "lng": -102.39011,
    "state_id": "CO"
  },
  {
    "zip": 80745,
    "lat": 40.90155,
    "lng": -103.39715,
    "state_id": "CO"
  },
  {
    "zip": 80746,
    "lat": 40.60957,
    "lng": -102.46604,
    "state_id": "CO"
  },
  {
    "zip": 80747,
    "lat": 40.94168,
    "lng": -103.09136,
    "state_id": "CO"
  },
  {
    "zip": 80749,
    "lat": 40.87489,
    "lng": -102.55734,
    "state_id": "CO"
  },
  {
    "zip": 80750,
    "lat": 40.41084,
    "lng": -103.60404,
    "state_id": "CO"
  },
  {
    "zip": 80751,
    "lat": 40.6371,
    "lng": -103.23146,
    "state_id": "CO"
  },
  {
    "zip": 80754,
    "lat": 40.72065,
    "lng": -103.66402,
    "state_id": "CO"
  },
  {
    "zip": 80755,
    "lat": 39.90933,
    "lng": -102.35404,
    "state_id": "CO"
  },
  {
    "zip": 80757,
    "lat": 39.79174,
    "lng": -103.58259,
    "state_id": "CO"
  },
  {
    "zip": 80758,
    "lat": 40.11401,
    "lng": -102.20089,
    "state_id": "CO"
  },
  {
    "zip": 80759,
    "lat": 40.13526,
    "lng": -102.68387,
    "state_id": "CO"
  },
  {
    "zip": 80801,
    "lat": 39.73261,
    "lng": -103.10732,
    "state_id": "CO"
  },
  {
    "zip": 80802,
    "lat": 38.8422,
    "lng": -102.17357,
    "state_id": "CO"
  },
  {
    "zip": 80804,
    "lat": 39.35189,
    "lng": -103.26141,
    "state_id": "CO"
  },
  {
    "zip": 80805,
    "lat": 39.31229,
    "lng": -102.44174,
    "state_id": "CO"
  },
  {
    "zip": 80807,
    "lat": 39.31882,
    "lng": -102.22605,
    "state_id": "CO"
  },
  {
    "zip": 80808,
    "lat": 38.99193,
    "lng": -104.30271,
    "state_id": "CO"
  },
  {
    "zip": 80809,
    "lat": 38.86842,
    "lng": -104.99695,
    "state_id": "CO"
  },
  {
    "zip": 80810,
    "lat": 38.82309,
    "lng": -102.45986,
    "state_id": "CO"
  },
  {
    "zip": 80812,
    "lat": 39.68252,
    "lng": -102.89282,
    "state_id": "CO"
  },
  {
    "zip": 80813,
    "lat": 38.76765,
    "lng": -105.0998,
    "state_id": "CO"
  },
  {
    "zip": 80814,
    "lat": 38.95729,
    "lng": -105.18037,
    "state_id": "CO"
  },
  {
    "zip": 80815,
    "lat": 39.39199,
    "lng": -103.0784,
    "state_id": "CO"
  },
  {
    "zip": 80816,
    "lat": 38.85459,
    "lng": -105.31204,
    "state_id": "CO"
  },
  {
    "zip": 80817,
    "lat": 38.63281,
    "lng": -104.68173,
    "state_id": "CO"
  },
  {
    "zip": 80818,
    "lat": 39.3962,
    "lng": -103.47462,
    "state_id": "CO"
  },
  {
    "zip": 80819,
    "lat": 38.96088,
    "lng": -105.01371,
    "state_id": "CO"
  },
  {
    "zip": 80820,
    "lat": 38.78833,
    "lng": -105.57777,
    "state_id": "CO"
  },
  {
    "zip": 80821,
    "lat": 38.96192,
    "lng": -103.40969,
    "state_id": "CO"
  },
  {
    "zip": 80822,
    "lat": 39.70975,
    "lng": -102.67257,
    "state_id": "CO"
  },
  {
    "zip": 80823,
    "lat": 38.65557,
    "lng": -103.41512,
    "state_id": "CO"
  },
  {
    "zip": 80824,
    "lat": 39.64665,
    "lng": -102.51712,
    "state_id": "CO"
  },
  {
    "zip": 80825,
    "lat": 38.80022,
    "lng": -102.84916,
    "state_id": "CO"
  },
  {
    "zip": 80827,
    "lat": 39.06015,
    "lng": -105.46815,
    "state_id": "CO"
  },
  {
    "zip": 80828,
    "lat": 39.34789,
    "lng": -103.72898,
    "state_id": "CO"
  },
  {
    "zip": 80829,
    "lat": 38.82807,
    "lng": -104.9358,
    "state_id": "CO"
  },
  {
    "zip": 80830,
    "lat": 39.12083,
    "lng": -103.89061,
    "state_id": "CO"
  },
  {
    "zip": 80831,
    "lat": 38.99667,
    "lng": -104.50606,
    "state_id": "CO"
  },
  {
    "zip": 80832,
    "lat": 39.01344,
    "lng": -104.00738,
    "state_id": "CO"
  },
  {
    "zip": 80833,
    "lat": 38.74026,
    "lng": -103.93414,
    "state_id": "CO"
  },
  {
    "zip": 80834,
    "lat": 39.28407,
    "lng": -102.88035,
    "state_id": "CO"
  },
  {
    "zip": 80835,
    "lat": 39.20787,
    "lng": -104.07995,
    "state_id": "CO"
  },
  {
    "zip": 80836,
    "lat": 39.29659,
    "lng": -102.58246,
    "state_id": "CO"
  },
  {
    "zip": 80840,
    "lat": 38.99467,
    "lng": -104.85708,
    "state_id": "CO"
  },
  {
    "zip": 80860,
    "lat": 38.6911,
    "lng": -105.08655,
    "state_id": "CO"
  },
  {
    "zip": 80861,
    "lat": 39.34267,
    "lng": -102.7407,
    "state_id": "CO"
  },
  {
    "zip": 80862,
    "lat": 38.88625,
    "lng": -103.06084,
    "state_id": "CO"
  },
  {
    "zip": 80863,
    "lat": 39.02833,
    "lng": -105.10805,
    "state_id": "CO"
  },
  {
    "zip": 80864,
    "lat": 38.69775,
    "lng": -104.18675,
    "state_id": "CO"
  },
  {
    "zip": 80902,
    "lat": 38.68317,
    "lng": -104.80786,
    "state_id": "CO"
  },
  {
    "zip": 80903,
    "lat": 38.83137,
    "lng": -104.81523,
    "state_id": "CO"
  },
  {
    "zip": 80904,
    "lat": 38.86059,
    "lng": -104.87195,
    "state_id": "CO"
  },
  {
    "zip": 80905,
    "lat": 38.81785,
    "lng": -104.83682,
    "state_id": "CO"
  },
  {
    "zip": 80906,
    "lat": 38.76566,
    "lng": -104.8716,
    "state_id": "CO"
  },
  {
    "zip": 80907,
    "lat": 38.87828,
    "lng": -104.82742,
    "state_id": "CO"
  },
  {
    "zip": 80908,
    "lat": 39.04771,
    "lng": -104.68949,
    "state_id": "CO"
  },
  {
    "zip": 80909,
    "lat": 38.85269,
    "lng": -104.77573,
    "state_id": "CO"
  },
  {
    "zip": 80910,
    "lat": 38.81252,
    "lng": -104.77433,
    "state_id": "CO"
  },
  {
    "zip": 80911,
    "lat": 38.75329,
    "lng": -104.72211,
    "state_id": "CO"
  },
  {
    "zip": 80913,
    "lat": 38.70279,
    "lng": -104.77236,
    "state_id": "CO"
  },
  {
    "zip": 80914,
    "lat": 38.82397,
    "lng": -104.70425,
    "state_id": "CO"
  },
  {
    "zip": 80915,
    "lat": 38.85232,
    "lng": -104.7158,
    "state_id": "CO"
  },
  {
    "zip": 80916,
    "lat": 38.8052,
    "lng": -104.71311,
    "state_id": "CO"
  },
  {
    "zip": 80917,
    "lat": 38.88606,
    "lng": -104.74543,
    "state_id": "CO"
  },
  {
    "zip": 80918,
    "lat": 38.91153,
    "lng": -104.78022,
    "state_id": "CO"
  },
  {
    "zip": 80919,
    "lat": 38.92713,
    "lng": -104.85417,
    "state_id": "CO"
  },
  {
    "zip": 80920,
    "lat": 38.95757,
    "lng": -104.77078,
    "state_id": "CO"
  },
  {
    "zip": 80921,
    "lat": 39.0056,
    "lng": -104.91017,
    "state_id": "CO"
  },
  {
    "zip": 80922,
    "lat": 38.89066,
    "lng": -104.70059,
    "state_id": "CO"
  },
  {
    "zip": 80923,
    "lat": 38.92694,
    "lng": -104.71472,
    "state_id": "CO"
  },
  {
    "zip": 80924,
    "lat": 38.96751,
    "lng": -104.7211,
    "state_id": "CO"
  },
  {
    "zip": 80925,
    "lat": 38.7507,
    "lng": -104.65042,
    "state_id": "CO"
  },
  {
    "zip": 80926,
    "lat": 38.64579,
    "lng": -104.91865,
    "state_id": "CO"
  },
  {
    "zip": 80927,
    "lat": 38.92862,
    "lng": -104.65804,
    "state_id": "CO"
  },
  {
    "zip": 80928,
    "lat": 38.64912,
    "lng": -104.452,
    "state_id": "CO"
  },
  {
    "zip": 80929,
    "lat": 38.82464,
    "lng": -104.62694,
    "state_id": "CO"
  },
  {
    "zip": 80930,
    "lat": 38.81433,
    "lng": -104.50237,
    "state_id": "CO"
  },
  {
    "zip": 80938,
    "lat": 38.90481,
    "lng": -104.66327,
    "state_id": "CO"
  },
  {
    "zip": 80939,
    "lat": 38.87791,
    "lng": -104.67732,
    "state_id": "CO"
  },
  {
    "zip": 80951,
    "lat": 38.8881,
    "lng": -104.65566,
    "state_id": "CO"
  },
  {
    "zip": 81001,
    "lat": 38.29452,
    "lng": -104.53378,
    "state_id": "CO"
  },
  {
    "zip": 81003,
    "lat": 38.27927,
    "lng": -104.63144,
    "state_id": "CO"
  },
  {
    "zip": 81004,
    "lat": 38.04123,
    "lng": -104.71645,
    "state_id": "CO"
  },
  {
    "zip": 81005,
    "lat": 38.19777,
    "lng": -104.84172,
    "state_id": "CO"
  },
  {
    "zip": 81006,
    "lat": 38.23049,
    "lng": -104.50446,
    "state_id": "CO"
  },
  {
    "zip": 81007,
    "lat": 38.3551,
    "lng": -104.77787,
    "state_id": "CO"
  },
  {
    "zip": 81008,
    "lat": 38.43687,
    "lng": -104.61754,
    "state_id": "CO"
  },
  {
    "zip": 81019,
    "lat": 37.94406,
    "lng": -104.84116,
    "state_id": "CO"
  },
  {
    "zip": 81020,
    "lat": 37.53092,
    "lng": -104.52319,
    "state_id": "CO"
  },
  {
    "zip": 81021,
    "lat": 38.37715,
    "lng": -103.37273,
    "state_id": "CO"
  },
  {
    "zip": 81022,
    "lat": 38.04424,
    "lng": -104.4323,
    "state_id": "CO"
  },
  {
    "zip": 81023,
    "lat": 38.06292,
    "lng": -104.95109,
    "state_id": "CO"
  },
  {
    "zip": 81024,
    "lat": 37.24935,
    "lng": -104.73478,
    "state_id": "CO"
  },
  {
    "zip": 81025,
    "lat": 38.35828,
    "lng": -104.26799,
    "state_id": "CO"
  },
  {
    "zip": 81027,
    "lat": 37.18946,
    "lng": -103.79991,
    "state_id": "CO"
  },
  {
    "zip": 81029,
    "lat": 37.08496,
    "lng": -102.48255,
    "state_id": "CO"
  },
  {
    "zip": 81030,
    "lat": 38.10257,
    "lng": -103.51925,
    "state_id": "CO"
  },
  {
    "zip": 81033,
    "lat": 38.19488,
    "lng": -103.86133,
    "state_id": "CO"
  },
  {
    "zip": 81036,
    "lat": 38.42386,
    "lng": -102.78361,
    "state_id": "CO"
  },
  {
    "zip": 81038,
    "lat": 38.07927,
    "lng": -103.14042,
    "state_id": "CO"
  },
  {
    "zip": 81039,
    "lat": 37.94067,
    "lng": -104.08167,
    "state_id": "CO"
  },
  {
    "zip": 81040,
    "lat": 37.78545,
    "lng": -105.2308,
    "state_id": "CO"
  },
  {
    "zip": 81041,
    "lat": 37.875,
    "lng": -102.38286,
    "state_id": "CO"
  },
  {
    "zip": 81043,
    "lat": 38.13096,
    "lng": -102.22089,
    "state_id": "CO"
  },
  {
    "zip": 81044,
    "lat": 37.90138,
    "lng": -102.93284,
    "state_id": "CO"
  },
  {
    "zip": 81045,
    "lat": 38.47641,
    "lng": -103.16212,
    "state_id": "CO"
  },
  {
    "zip": 81047,
    "lat": 38.01784,
    "lng": -102.19576,
    "state_id": "CO"
  },
  {
    "zip": 81049,
    "lat": 37.33993,
    "lng": -103.37719,
    "state_id": "CO"
  },
  {
    "zip": 81050,
    "lat": 37.94029,
    "lng": -103.50553,
    "state_id": "CO"
  },
  {
    "zip": 81052,
    "lat": 37.92617,
    "lng": -102.65974,
    "state_id": "CO"
  },
  {
    "zip": 81054,
    "lat": 37.93472,
    "lng": -103.16646,
    "state_id": "CO"
  },
  {
    "zip": 81055,
    "lat": 37.488,
    "lng": -105.07237,
    "state_id": "CO"
  },
  {
    "zip": 81057,
    "lat": 38.12827,
    "lng": -102.88066,
    "state_id": "CO"
  },
  {
    "zip": 81058,
    "lat": 38.09876,
    "lng": -103.87967,
    "state_id": "CO"
  },
  {
    "zip": 81059,
    "lat": 37.51074,
    "lng": -104.01643,
    "state_id": "CO"
  },
  {
    "zip": 81062,
    "lat": 38.29272,
    "lng": -103.94951,
    "state_id": "CO"
  },
  {
    "zip": 81063,
    "lat": 38.44559,
    "lng": -103.78268,
    "state_id": "CO"
  },
  {
    "zip": 81064,
    "lat": 37.23913,
    "lng": -102.98884,
    "state_id": "CO"
  },
  {
    "zip": 81067,
    "lat": 37.93801,
    "lng": -103.7839,
    "state_id": "CO"
  },
  {
    "zip": 81069,
    "lat": 37.89709,
    "lng": -104.89695,
    "state_id": "CO"
  },
  {
    "zip": 81071,
    "lat": 38.44836,
    "lng": -102.26642,
    "state_id": "CO"
  },
  {
    "zip": 81073,
    "lat": 37.42388,
    "lng": -102.70954,
    "state_id": "CO"
  },
  {
    "zip": 81076,
    "lat": 38.32403,
    "lng": -103.60951,
    "state_id": "CO"
  },
  {
    "zip": 81077,
    "lat": 38.01785,
    "lng": -103.63067,
    "state_id": "CO"
  },
  {
    "zip": 81081,
    "lat": 37.06734,
    "lng": -104.19479,
    "state_id": "CO"
  },
  {
    "zip": 81082,
    "lat": 37.17862,
    "lng": -104.51613,
    "state_id": "CO"
  },
  {
    "zip": 81084,
    "lat": 37.57727,
    "lng": -102.41709,
    "state_id": "CO"
  },
  {
    "zip": 81087,
    "lat": 37.36591,
    "lng": -102.40896,
    "state_id": "CO"
  },
  {
    "zip": 81089,
    "lat": 37.65595,
    "lng": -104.73158,
    "state_id": "CO"
  },
  {
    "zip": 81090,
    "lat": 37.35668,
    "lng": -102.22135,
    "state_id": "CO"
  },
  {
    "zip": 81091,
    "lat": 37.15008,
    "lng": -104.97091,
    "state_id": "CO"
  },
  {
    "zip": 81092,
    "lat": 38.19797,
    "lng": -102.74772,
    "state_id": "CO"
  },
  {
    "zip": 81101,
    "lat": 37.48372,
    "lng": -105.82998,
    "state_id": "CO"
  },
  {
    "zip": 81120,
    "lat": 37.16424,
    "lng": -106.27833,
    "state_id": "CO"
  },
  {
    "zip": 81121,
    "lat": 37.10982,
    "lng": -107.43911,
    "state_id": "CO"
  },
  {
    "zip": 81122,
    "lat": 37.33995,
    "lng": -107.47857,
    "state_id": "CO"
  },
  {
    "zip": 81123,
    "lat": 37.39859,
    "lng": -105.56977,
    "state_id": "CO"
  },
  {
    "zip": 81124,
    "lat": 37.30632,
    "lng": -106.16065,
    "state_id": "CO"
  },
  {
    "zip": 81125,
    "lat": 37.81704,
    "lng": -106.06909,
    "state_id": "CO"
  },
  {
    "zip": 81126,
    "lat": 37.15556,
    "lng": -105.33459,
    "state_id": "CO"
  },
  {
    "zip": 81128,
    "lat": 37.06812,
    "lng": -106.67242,
    "state_id": "CO"
  },
  {
    "zip": 81129,
    "lat": 37.10336,
    "lng": -106.02909,
    "state_id": "CO"
  },
  {
    "zip": 81130,
    "lat": 37.70747,
    "lng": -107.01535,
    "state_id": "CO"
  },
  {
    "zip": 81131,
    "lat": 37.87296,
    "lng": -105.63763,
    "state_id": "CO"
  },
  {
    "zip": 81132,
    "lat": 37.70747,
    "lng": -106.42885,
    "state_id": "CO"
  },
  {
    "zip": 81133,
    "lat": 37.42918,
    "lng": -105.31028,
    "state_id": "CO"
  },
  {
    "zip": 81136,
    "lat": 37.72493,
    "lng": -105.82787,
    "state_id": "CO"
  },
  {
    "zip": 81137,
    "lat": 37.05906,
    "lng": -107.61339,
    "state_id": "CO"
  },
  {
    "zip": 81138,
    "lat": 37.02646,
    "lng": -105.62276,
    "state_id": "CO"
  },
  {
    "zip": 81140,
    "lat": 37.30576,
    "lng": -106.12889,
    "state_id": "CO"
  },
  {
    "zip": 81141,
    "lat": 37.15686,
    "lng": -105.86358,
    "state_id": "CO"
  },
  {
    "zip": 81143,
    "lat": 38.04872,
    "lng": -105.8515,
    "state_id": "CO"
  },
  {
    "zip": 81144,
    "lat": 37.54984,
    "lng": -106.15119,
    "state_id": "CO"
  },
  {
    "zip": 81146,
    "lat": 37.65746,
    "lng": -105.68689,
    "state_id": "CO"
  },
  {
    "zip": 81147,
    "lat": 37.31914,
    "lng": -107.09655,
    "state_id": "CO"
  },
  {
    "zip": 81148,
    "lat": 37.17183,
    "lng": -105.98542,
    "state_id": "CO"
  },
  {
    "zip": 81149,
    "lat": 38.08398,
    "lng": -106.34014,
    "state_id": "CO"
  },
  {
    "zip": 81151,
    "lat": 37.23324,
    "lng": -105.70861,
    "state_id": "CO"
  },
  {
    "zip": 81152,
    "lat": 37.1152,
    "lng": -105.39898,
    "state_id": "CO"
  },
  {
    "zip": 81154,
    "lat": 37.59503,
    "lng": -106.64858,
    "state_id": "CO"
  },
  {
    "zip": 81155,
    "lat": 38.29743,
    "lng": -106.03192,
    "state_id": "CO"
  },
  {
    "zip": 81201,
    "lat": 38.56049,
    "lng": -106.06482,
    "state_id": "CO"
  },
  {
    "zip": 81210,
    "lat": 38.82131,
    "lng": -106.63113,
    "state_id": "CO"
  },
  {
    "zip": 81211,
    "lat": 38.90712,
    "lng": -106.23921,
    "state_id": "CO"
  },
  {
    "zip": 81212,
    "lat": 38.53864,
    "lng": -105.38622,
    "state_id": "CO"
  },
  {
    "zip": 81220,
    "lat": 38.30912,
    "lng": -107.48984,
    "state_id": "CO"
  },
  {
    "zip": 81221,
    "lat": 38.36242,
    "lng": -105.14438,
    "state_id": "CO"
  },
  {
    "zip": 81222,
    "lat": 38.35571,
    "lng": -105.81844,
    "state_id": "CO"
  },
  {
    "zip": 81223,
    "lat": 38.40535,
    "lng": -105.60648,
    "state_id": "CO"
  },
  {
    "zip": 81224,
    "lat": 38.8734,
    "lng": -106.93722,
    "state_id": "CO"
  },
  {
    "zip": 81225,
    "lat": 38.95884,
    "lng": -106.95467,
    "state_id": "CO"
  },
  {
    "zip": 81226,
    "lat": 38.33377,
    "lng": -105.12436,
    "state_id": "CO"
  },
  {
    "zip": 81227,
    "lat": 38.5493,
    "lng": -106.29004,
    "state_id": "CO"
  },
  {
    "zip": 81230,
    "lat": 38.42496,
    "lng": -106.95639,
    "state_id": "CO"
  },
  {
    "zip": 81231,
    "lat": 38.54999,
    "lng": -106.91965,
    "state_id": "CO"
  },
  {
    "zip": 81232,
    "lat": 38.28509,
    "lng": -105.61045,
    "state_id": "CO"
  },
  {
    "zip": 81233,
    "lat": 38.42522,
    "lng": -105.82738,
    "state_id": "CO"
  },
  {
    "zip": 81235,
    "lat": 38.01367,
    "lng": -107.29516,
    "state_id": "CO"
  },
  {
    "zip": 81236,
    "lat": 38.70612,
    "lng": -106.2436,
    "state_id": "CO"
  },
  {
    "zip": 81237,
    "lat": 38.60303,
    "lng": -106.61407,
    "state_id": "CO"
  },
  {
    "zip": 81239,
    "lat": 38.50884,
    "lng": -106.66357,
    "state_id": "CO"
  },
  {
    "zip": 81240,
    "lat": 38.50393,
    "lng": -105.0322,
    "state_id": "CO"
  },
  {
    "zip": 81241,
    "lat": 38.61792,
    "lng": -106.51462,
    "state_id": "CO"
  },
  {
    "zip": 81242,
    "lat": 38.50811,
    "lng": -106.07546,
    "state_id": "CO"
  },
  {
    "zip": 81243,
    "lat": 38.26344,
    "lng": -107.18964,
    "state_id": "CO"
  },
  {
    "zip": 81244,
    "lat": 38.36297,
    "lng": -105.17768,
    "state_id": "CO"
  },
  {
    "zip": 81248,
    "lat": 38.45215,
    "lng": -106.39655,
    "state_id": "CO"
  },
  {
    "zip": 81251,
    "lat": 39.10127,
    "lng": -106.44176,
    "state_id": "CO"
  },
  {
    "zip": 81252,
    "lat": 38.10886,
    "lng": -105.42017,
    "state_id": "CO"
  },
  {
    "zip": 81253,
    "lat": 38.21921,
    "lng": -105.06861,
    "state_id": "CO"
  },
  {
    "zip": 81301,
    "lat": 37.4449,
    "lng": -107.85159,
    "state_id": "CO"
  },
  {
    "zip": 81303,
    "lat": 37.11638,
    "lng": -107.89112,
    "state_id": "CO"
  },
  {
    "zip": 81320,
    "lat": 37.72182,
    "lng": -108.73343,
    "state_id": "CO"
  },
  {
    "zip": 81321,
    "lat": 37.3184,
    "lng": -108.76218,
    "state_id": "CO"
  },
  {
    "zip": 81323,
    "lat": 37.63986,
    "lng": -108.2847,
    "state_id": "CO"
  },
  {
    "zip": 81324,
    "lat": 37.6641,
    "lng": -109.0334,
    "state_id": "CO"
  },
  {
    "zip": 81325,
    "lat": 37.91688,
    "lng": -108.72802,
    "state_id": "CO"
  },
  {
    "zip": 81326,
    "lat": 37.16195,
    "lng": -108.16839,
    "state_id": "CO"
  },
  {
    "zip": 81327,
    "lat": 37.51772,
    "lng": -108.6546,
    "state_id": "CO"
  },
  {
    "zip": 81328,
    "lat": 37.36326,
    "lng": -108.2586,
    "state_id": "CO"
  },
  {
    "zip": 81330,
    "lat": 37.23128,
    "lng": -108.48246,
    "state_id": "CO"
  },
  {
    "zip": 81331,
    "lat": 37.51641,
    "lng": -108.87147,
    "state_id": "CO"
  },
  {
    "zip": 81332,
    "lat": 37.72685,
    "lng": -107.9577,
    "state_id": "CO"
  },
  {
    "zip": 81334,
    "lat": 37.11243,
    "lng": -108.54656,
    "state_id": "CO"
  },
  {
    "zip": 81335,
    "lat": 37.49643,
    "lng": -108.75704,
    "state_id": "CO"
  },
  {
    "zip": 81401,
    "lat": 38.48717,
    "lng": -107.75847,
    "state_id": "CO"
  },
  {
    "zip": 81403,
    "lat": 38.33438,
    "lng": -107.96918,
    "state_id": "CO"
  },
  {
    "zip": 81410,
    "lat": 38.80135,
    "lng": -107.96759,
    "state_id": "CO"
  },
  {
    "zip": 81411,
    "lat": 38.25076,
    "lng": -108.97993,
    "state_id": "CO"
  },
  {
    "zip": 81413,
    "lat": 38.9572,
    "lng": -107.95495,
    "state_id": "CO"
  },
  {
    "zip": 81415,
    "lat": 38.6405,
    "lng": -107.63676,
    "state_id": "CO"
  },
  {
    "zip": 81416,
    "lat": 38.75096,
    "lng": -108.1376,
    "state_id": "CO"
  },
  {
    "zip": 81418,
    "lat": 38.84869,
    "lng": -107.98719,
    "state_id": "CO"
  },
  {
    "zip": 81419,
    "lat": 38.86945,
    "lng": -107.75853,
    "state_id": "CO"
  },
  {
    "zip": 81422,
    "lat": 38.34672,
    "lng": -108.71155,
    "state_id": "CO"
  },
  {
    "zip": 81423,
    "lat": 38.0247,
    "lng": -108.36906,
    "state_id": "CO"
  },
  {
    "zip": 81424,
    "lat": 38.3344,
    "lng": -108.45797,
    "state_id": "CO"
  },
  {
    "zip": 81425,
    "lat": 38.53574,
    "lng": -108.22151,
    "state_id": "CO"
  },
  {
    "zip": 81426,
    "lat": 37.86821,
    "lng": -107.89132,
    "state_id": "CO"
  },
  {
    "zip": 81427,
    "lat": 38.02011,
    "lng": -107.63167,
    "state_id": "CO"
  },
  {
    "zip": 81428,
    "lat": 38.95808,
    "lng": -107.59525,
    "state_id": "CO"
  },
  {
    "zip": 81429,
    "lat": 38.31735,
    "lng": -108.87793,
    "state_id": "CO"
  },
  {
    "zip": 81430,
    "lat": 38.01869,
    "lng": -108.05374,
    "state_id": "CO"
  },
  {
    "zip": 81431,
    "lat": 38.10429,
    "lng": -108.58295,
    "state_id": "CO"
  },
  {
    "zip": 81432,
    "lat": 38.12814,
    "lng": -107.75691,
    "state_id": "CO"
  },
  {
    "zip": 81433,
    "lat": 37.78202,
    "lng": -107.63083,
    "state_id": "CO"
  },
  {
    "zip": 81434,
    "lat": 38.95024,
    "lng": -107.35451,
    "state_id": "CO"
  },
  {
    "zip": 81435,
    "lat": 37.9038,
    "lng": -107.86951,
    "state_id": "CO"
  },
  {
    "zip": 81501,
    "lat": 39.07211,
    "lng": -108.54759,
    "state_id": "CO"
  },
  {
    "zip": 81503,
    "lat": 39.03071,
    "lng": -108.43609,
    "state_id": "CO"
  },
  {
    "zip": 81504,
    "lat": 39.07913,
    "lng": -108.49158,
    "state_id": "CO"
  },
  {
    "zip": 81505,
    "lat": 39.18187,
    "lng": -108.57772,
    "state_id": "CO"
  },
  {
    "zip": 81506,
    "lat": 39.11586,
    "lng": -108.53246,
    "state_id": "CO"
  },
  {
    "zip": 81507,
    "lat": 39.00931,
    "lng": -108.62608,
    "state_id": "CO"
  },
  {
    "zip": 81520,
    "lat": 39.10989,
    "lng": -108.43381,
    "state_id": "CO"
  },
  {
    "zip": 81521,
    "lat": 39.14589,
    "lng": -108.81647,
    "state_id": "CO"
  },
  {
    "zip": 81522,
    "lat": 38.65091,
    "lng": -108.91455,
    "state_id": "CO"
  },
  {
    "zip": 81523,
    "lat": 38.95268,
    "lng": -108.88256,
    "state_id": "CO"
  },
  {
    "zip": 81524,
    "lat": 39.26884,
    "lng": -108.78127,
    "state_id": "CO"
  },
  {
    "zip": 81525,
    "lat": 39.28857,
    "lng": -108.95157,
    "state_id": "CO"
  },
  {
    "zip": 81526,
    "lat": 39.09613,
    "lng": -108.33698,
    "state_id": "CO"
  },
  {
    "zip": 81527,
    "lat": 38.74877,
    "lng": -108.55132,
    "state_id": "CO"
  },
  {
    "zip": 81601,
    "lat": 39.5839,
    "lng": -107.31363,
    "state_id": "CO"
  },
  {
    "zip": 81610,
    "lat": 40.3027,
    "lng": -108.76948,
    "state_id": "CO"
  },
  {
    "zip": 81611,
    "lat": 39.15162,
    "lng": -106.77138,
    "state_id": "CO"
  },
  {
    "zip": 81612,
    "lat": 39.13396,
    "lng": -106.83727,
    "state_id": "CO"
  },
  {
    "zip": 81615,
    "lat": 39.22115,
    "lng": -106.93198,
    "state_id": "CO"
  },
  {
    "zip": 81620,
    "lat": 39.5966,
    "lng": -106.51437,
    "state_id": "CO"
  },
  {
    "zip": 81621,
    "lat": 39.38608,
    "lng": -106.88558,
    "state_id": "CO"
  },
  {
    "zip": 81623,
    "lat": 39.25109,
    "lng": -107.20443,
    "state_id": "CO"
  },
  {
    "zip": 81624,
    "lat": 39.24808,
    "lng": -107.7775,
    "state_id": "CO"
  },
  {
    "zip": 81625,
    "lat": 40.73385,
    "lng": -107.68711,
    "state_id": "CO"
  },
  {
    "zip": 81630,
    "lat": 39.46257,
    "lng": -108.54199,
    "state_id": "CO"
  },
  {
    "zip": 81631,
    "lat": 39.63852,
    "lng": -106.76545,
    "state_id": "CO"
  },
  {
    "zip": 81632,
    "lat": 39.63335,
    "lng": -106.60468,
    "state_id": "CO"
  },
  {
    "zip": 81633,
    "lat": 40.33837,
    "lng": -108.42922,
    "state_id": "CO"
  },
  {
    "zip": 81635,
    "lat": 39.51987,
    "lng": -108.04806,
    "state_id": "CO"
  },
  {
    "zip": 81637,
    "lat": 39.77665,
    "lng": -107.10154,
    "state_id": "CO"
  },
  {
    "zip": 81638,
    "lat": 40.32099,
    "lng": -107.55452,
    "state_id": "CO"
  },
  {
    "zip": 81639,
    "lat": 40.50569,
    "lng": -107.25302,
    "state_id": "CO"
  },
  {
    "zip": 81640,
    "lat": 40.65844,
    "lng": -108.51905,
    "state_id": "CO"
  },
  {
    "zip": 81641,
    "lat": 40.0804,
    "lng": -107.76297,
    "state_id": "CO"
  },
  {
    "zip": 81642,
    "lat": 39.31983,
    "lng": -106.65933,
    "state_id": "CO"
  },
  {
    "zip": 81643,
    "lat": 39.07824,
    "lng": -108.15794,
    "state_id": "CO"
  },
  {
    "zip": 81645,
    "lat": 39.45072,
    "lng": -106.41702,
    "state_id": "CO"
  },
  {
    "zip": 81646,
    "lat": 39.10254,
    "lng": -107.99628,
    "state_id": "CO"
  },
  {
    "zip": 81647,
    "lat": 39.62108,
    "lng": -107.5542,
    "state_id": "CO"
  },
  {
    "zip": 81648,
    "lat": 39.97213,
    "lng": -108.73354,
    "state_id": "CO"
  },
  {
    "zip": 81649,
    "lat": 39.51832,
    "lng": -106.31039,
    "state_id": "CO"
  },
  {
    "zip": 81650,
    "lat": 39.73873,
    "lng": -108.11824,
    "state_id": "CO"
  },
  {
    "zip": 81652,
    "lat": 39.48535,
    "lng": -107.65622,
    "state_id": "CO"
  },
  {
    "zip": 81653,
    "lat": 40.96556,
    "lng": -107.2706,
    "state_id": "CO"
  },
  {
    "zip": 81654,
    "lat": 39.22581,
    "lng": -107.03027,
    "state_id": "CO"
  },
  {
    "zip": 81655,
    "lat": 39.75114,
    "lng": -106.53373,
    "state_id": "CO"
  },
  {
    "zip": 81656,
    "lat": 39.28383,
    "lng": -106.89719,
    "state_id": "CO"
  },
  {
    "zip": 81657,
    "lat": 39.6512,
    "lng": -106.32342,
    "state_id": "CO"
  },
  {
    "zip": 6001,
    "lat": 41.7907,
    "lng": -72.85385,
    "state_id": "CT"
  },
  {
    "zip": 6002,
    "lat": 41.84255,
    "lng": -72.7406,
    "state_id": "CT"
  },
  {
    "zip": 6010,
    "lat": 41.68116,
    "lng": -72.94074,
    "state_id": "CT"
  },
  {
    "zip": 6013,
    "lat": 41.75978,
    "lng": -72.95893,
    "state_id": "CT"
  },
  {
    "zip": 6016,
    "lat": 41.90417,
    "lng": -72.54448,
    "state_id": "CT"
  },
  {
    "zip": 6018,
    "lat": 42.02648,
    "lng": -73.30872,
    "state_id": "CT"
  },
  {
    "zip": 6019,
    "lat": 41.85964,
    "lng": -72.90698,
    "state_id": "CT"
  },
  {
    "zip": 6020,
    "lat": 41.83455,
    "lng": -72.92862,
    "state_id": "CT"
  },
  {
    "zip": 6021,
    "lat": 42.02184,
    "lng": -73.10434,
    "state_id": "CT"
  },
  {
    "zip": 6022,
    "lat": 41.86545,
    "lng": -72.92732,
    "state_id": "CT"
  },
  {
    "zip": 6023,
    "lat": 41.61371,
    "lng": -72.71994,
    "state_id": "CT"
  },
  {
    "zip": 6024,
    "lat": 42.01204,
    "lng": -73.27433,
    "state_id": "CT"
  },
  {
    "zip": 6026,
    "lat": 41.9427,
    "lng": -72.74235,
    "state_id": "CT"
  },
  {
    "zip": 6027,
    "lat": 42.00403,
    "lng": -72.9118,
    "state_id": "CT"
  },
  {
    "zip": 6029,
    "lat": 41.91503,
    "lng": -72.44947,
    "state_id": "CT"
  },
  {
    "zip": 6031,
    "lat": 41.95776,
    "lng": -73.31738,
    "state_id": "CT"
  },
  {
    "zip": 6032,
    "lat": 41.72527,
    "lng": -72.83124,
    "state_id": "CT"
  },
  {
    "zip": 6033,
    "lat": 41.70787,
    "lng": -72.54199,
    "state_id": "CT"
  },
  {
    "zip": 6035,
    "lat": 41.96061,
    "lng": -72.80095,
    "state_id": "CT"
  },
  {
    "zip": 6037,
    "lat": 41.6113,
    "lng": -72.77775,
    "state_id": "CT"
  },
  {
    "zip": 6039,
    "lat": 41.95003,
    "lng": -73.44477,
    "state_id": "CT"
  },
  {
    "zip": 6040,
    "lat": 41.76167,
    "lng": -72.52251,
    "state_id": "CT"
  },
  {
    "zip": 6042,
    "lat": 41.7975,
    "lng": -72.52692,
    "state_id": "CT"
  },
  {
    "zip": 6043,
    "lat": 41.76647,
    "lng": -72.43902,
    "state_id": "CT"
  },
  {
    "zip": 6051,
    "lat": 41.66514,
    "lng": -72.76974,
    "state_id": "CT"
  },
  {
    "zip": 6052,
    "lat": 41.65723,
    "lng": -72.8052,
    "state_id": "CT"
  },
  {
    "zip": 6053,
    "lat": 41.68853,
    "lng": -72.79421,
    "state_id": "CT"
  },
  {
    "zip": 6057,
    "lat": 41.84407,
    "lng": -73.00623,
    "state_id": "CT"
  },
  {
    "zip": 6058,
    "lat": 41.97184,
    "lng": -73.19542,
    "state_id": "CT"
  },
  {
    "zip": 6059,
    "lat": 41.94904,
    "lng": -72.9448,
    "state_id": "CT"
  },
  {
    "zip": 6060,
    "lat": 42.00702,
    "lng": -72.84569,
    "state_id": "CT"
  },
  {
    "zip": 6061,
    "lat": 41.87547,
    "lng": -72.96695,
    "state_id": "CT"
  },
  {
    "zip": 6062,
    "lat": 41.67341,
    "lng": -72.86077,
    "state_id": "CT"
  },
  {
    "zip": 6063,
    "lat": 41.92703,
    "lng": -72.97157,
    "state_id": "CT"
  },
  {
    "zip": 6065,
    "lat": 41.97396,
    "lng": -73.00405,
    "state_id": "CT"
  },
  {
    "zip": 6066,
    "lat": 41.8364,
    "lng": -72.46061,
    "state_id": "CT"
  },
  {
    "zip": 6067,
    "lat": 41.65723,
    "lng": -72.66318,
    "state_id": "CT"
  },
  {
    "zip": 6068,
    "lat": 42.01475,
    "lng": -73.42049,
    "state_id": "CT"
  },
  {
    "zip": 6069,
    "lat": 41.85432,
    "lng": -73.44722,
    "state_id": "CT"
  },
  {
    "zip": 6070,
    "lat": 41.8761,
    "lng": -72.81477,
    "state_id": "CT"
  },
  {
    "zip": 6071,
    "lat": 41.99465,
    "lng": -72.45213,
    "state_id": "CT"
  },
  {
    "zip": 6073,
    "lat": 41.66029,
    "lng": -72.55771,
    "state_id": "CT"
  },
  {
    "zip": 6074,
    "lat": 41.83528,
    "lng": -72.57331,
    "state_id": "CT"
  },
  {
    "zip": 6076,
    "lat": 41.98966,
    "lng": -72.26165,
    "state_id": "CT"
  },
  {
    "zip": 6078,
    "lat": 41.98912,
    "lng": -72.64995,
    "state_id": "CT"
  },
  {
    "zip": 6081,
    "lat": 41.90653,
    "lng": -72.76901,
    "state_id": "CT"
  },
  {
    "zip": 6082,
    "lat": 41.98394,
    "lng": -72.55475,
    "state_id": "CT"
  },
  {
    "zip": 6084,
    "lat": 41.87892,
    "lng": -72.36458,
    "state_id": "CT"
  },
  {
    "zip": 6085,
    "lat": 41.74637,
    "lng": -72.88809,
    "state_id": "CT"
  },
  {
    "zip": 6088,
    "lat": 41.90581,
    "lng": -72.59645,
    "state_id": "CT"
  },
  {
    "zip": 6089,
    "lat": 41.83733,
    "lng": -72.82288,
    "state_id": "CT"
  },
  {
    "zip": 6090,
    "lat": 41.94648,
    "lng": -72.86314,
    "state_id": "CT"
  },
  {
    "zip": 6091,
    "lat": 42.01274,
    "lng": -72.97466,
    "state_id": "CT"
  },
  {
    "zip": 6092,
    "lat": 41.87329,
    "lng": -72.85912,
    "state_id": "CT"
  },
  {
    "zip": 6093,
    "lat": 42.00283,
    "lng": -72.72423,
    "state_id": "CT"
  },
  {
    "zip": 6095,
    "lat": 41.8711,
    "lng": -72.67361,
    "state_id": "CT"
  },
  {
    "zip": 6096,
    "lat": 41.92752,
    "lng": -72.65703,
    "state_id": "CT"
  },
  {
    "zip": 6098,
    "lat": 41.95696,
    "lng": -73.08726,
    "state_id": "CT"
  },
  {
    "zip": 6103,
    "lat": 41.76757,
    "lng": -72.67343,
    "state_id": "CT"
  },
  {
    "zip": 6105,
    "lat": 41.77474,
    "lng": -72.70508,
    "state_id": "CT"
  },
  {
    "zip": 6106,
    "lat": 41.74872,
    "lng": -72.69494,
    "state_id": "CT"
  },
  {
    "zip": 6107,
    "lat": 41.75416,
    "lng": -72.75898,
    "state_id": "CT"
  },
  {
    "zip": 6108,
    "lat": 41.78084,
    "lng": -72.62155,
    "state_id": "CT"
  },
  {
    "zip": 6109,
    "lat": 41.7013,
    "lng": -72.67034,
    "state_id": "CT"
  },
  {
    "zip": 6110,
    "lat": 41.73268,
    "lng": -72.73369,
    "state_id": "CT"
  },
  {
    "zip": 6111,
    "lat": 41.687,
    "lng": -72.73084,
    "state_id": "CT"
  },
  {
    "zip": 6112,
    "lat": 41.79312,
    "lng": -72.69644,
    "state_id": "CT"
  },
  {
    "zip": 6114,
    "lat": 41.73935,
    "lng": -72.66955,
    "state_id": "CT"
  },
  {
    "zip": 6117,
    "lat": 41.78692,
    "lng": -72.76199,
    "state_id": "CT"
  },
  {
    "zip": 6118,
    "lat": 41.74871,
    "lng": -72.60988,
    "state_id": "CT"
  },
  {
    "zip": 6119,
    "lat": 41.76334,
    "lng": -72.72717,
    "state_id": "CT"
  },
  {
    "zip": 6120,
    "lat": 41.78938,
    "lng": -72.66535,
    "state_id": "CT"
  },
  {
    "zip": 6160,
    "lat": 41.76661,
    "lng": -72.69116,
    "state_id": "CT"
  },
  {
    "zip": 6226,
    "lat": 41.70843,
    "lng": -72.20725,
    "state_id": "CT"
  },
  {
    "zip": 6231,
    "lat": 41.62554,
    "lng": -72.37028,
    "state_id": "CT"
  },
  {
    "zip": 6232,
    "lat": 41.7331,
    "lng": -72.37607,
    "state_id": "CT"
  },
  {
    "zip": 6234,
    "lat": 41.78941,
    "lng": -71.95268,
    "state_id": "CT"
  },
  {
    "zip": 6235,
    "lat": 41.79295,
    "lng": -72.12758,
    "state_id": "CT"
  },
  {
    "zip": 6237,
    "lat": 41.69423,
    "lng": -72.30725,
    "state_id": "CT"
  },
  {
    "zip": 6238,
    "lat": 41.78306,
    "lng": -72.33955,
    "state_id": "CT"
  },
  {
    "zip": 6239,
    "lat": 41.79148,
    "lng": -71.8566,
    "state_id": "CT"
  },
  {
    "zip": 6241,
    "lat": 41.85632,
    "lng": -71.84839,
    "state_id": "CT"
  },
  {
    "zip": 6242,
    "lat": 41.89298,
    "lng": -72.09518,
    "state_id": "CT"
  },
  {
    "zip": 6243,
    "lat": 41.84523,
    "lng": -71.80244,
    "state_id": "CT"
  },
  {
    "zip": 6247,
    "lat": 41.76708,
    "lng": -72.06624,
    "state_id": "CT"
  },
  {
    "zip": 6248,
    "lat": 41.6849,
    "lng": -72.40576,
    "state_id": "CT"
  },
  {
    "zip": 6249,
    "lat": 41.63128,
    "lng": -72.2402,
    "state_id": "CT"
  },
  {
    "zip": 6250,
    "lat": 41.77331,
    "lng": -72.19741,
    "state_id": "CT"
  },
  {
    "zip": 6254,
    "lat": 41.61527,
    "lng": -72.14322,
    "state_id": "CT"
  },
  {
    "zip": 6255,
    "lat": 41.98828,
    "lng": -71.90256,
    "state_id": "CT"
  },
  {
    "zip": 6256,
    "lat": 41.73708,
    "lng": -72.15733,
    "state_id": "CT"
  },
  {
    "zip": 6259,
    "lat": 41.87006,
    "lng": -71.99073,
    "state_id": "CT"
  },
  {
    "zip": 6260,
    "lat": 41.90902,
    "lng": -71.87202,
    "state_id": "CT"
  },
  {
    "zip": 6262,
    "lat": 42.02038,
    "lng": -71.94817,
    "state_id": "CT"
  },
  {
    "zip": 6263,
    "lat": 41.84039,
    "lng": -71.90617,
    "state_id": "CT"
  },
  {
    "zip": 6264,
    "lat": 41.6942,
    "lng": -72.10044,
    "state_id": "CT"
  },
  {
    "zip": 6266,
    "lat": 41.67271,
    "lng": -72.17021,
    "state_id": "CT"
  },
  {
    "zip": 6268,
    "lat": 41.7997,
    "lng": -72.24776,
    "state_id": "CT"
  },
  {
    "zip": 6269,
    "lat": 41.80832,
    "lng": -72.27003,
    "state_id": "CT"
  },
  {
    "zip": 6277,
    "lat": 41.96883,
    "lng": -71.83975,
    "state_id": "CT"
  },
  {
    "zip": 6278,
    "lat": 41.89103,
    "lng": -72.16992,
    "state_id": "CT"
  },
  {
    "zip": 6279,
    "lat": 41.88956,
    "lng": -72.25936,
    "state_id": "CT"
  },
  {
    "zip": 6280,
    "lat": 41.69272,
    "lng": -72.14033,
    "state_id": "CT"
  },
  {
    "zip": 6281,
    "lat": 41.97245,
    "lng": -72.01529,
    "state_id": "CT"
  },
  {
    "zip": 6282,
    "lat": 41.94246,
    "lng": -72.07969,
    "state_id": "CT"
  },
  {
    "zip": 6320,
    "lat": 41.34937,
    "lng": -72.10238,
    "state_id": "CT"
  },
  {
    "zip": 6330,
    "lat": 41.64289,
    "lng": -72.07527,
    "state_id": "CT"
  },
  {
    "zip": 6331,
    "lat": 41.69792,
    "lng": -71.99975,
    "state_id": "CT"
  },
  {
    "zip": 6332,
    "lat": 41.73131,
    "lng": -71.90217,
    "state_id": "CT"
  },
  {
    "zip": 6333,
    "lat": 41.38589,
    "lng": -72.24505,
    "state_id": "CT"
  },
  {
    "zip": 6334,
    "lat": 41.54433,
    "lng": -72.1742,
    "state_id": "CT"
  },
  {
    "zip": 6335,
    "lat": 41.43116,
    "lng": -72.06161,
    "state_id": "CT"
  },
  {
    "zip": 6336,
    "lat": 41.57934,
    "lng": -72.19505,
    "state_id": "CT"
  },
  {
    "zip": 6339,
    "lat": 41.44463,
    "lng": -71.99575,
    "state_id": "CT"
  },
  {
    "zip": 6340,
    "lat": 41.3571,
    "lng": -72.04526,
    "state_id": "CT"
  },
  {
    "zip": 6350,
    "lat": 41.64836,
    "lng": -72.06838,
    "state_id": "CT"
  },
  {
    "zip": 6351,
    "lat": 41.58916,
    "lng": -71.94935,
    "state_id": "CT"
  },
  {
    "zip": 6353,
    "lat": 41.45812,
    "lng": -72.14108,
    "state_id": "CT"
  },
  {
    "zip": 6354,
    "lat": 41.70271,
    "lng": -71.85619,
    "state_id": "CT"
  },
  {
    "zip": 6355,
    "lat": 41.36786,
    "lng": -71.97419,
    "state_id": "CT"
  },
  {
    "zip": 6357,
    "lat": 41.32632,
    "lng": -72.21513,
    "state_id": "CT"
  },
  {
    "zip": 6359,
    "lat": 41.46964,
    "lng": -71.87548,
    "state_id": "CT"
  },
  {
    "zip": 6360,
    "lat": 41.54883,
    "lng": -72.08927,
    "state_id": "CT"
  },
  {
    "zip": 6365,
    "lat": 41.52232,
    "lng": -72.00107,
    "state_id": "CT"
  },
  {
    "zip": 6370,
    "lat": 41.46478,
    "lng": -72.18792,
    "state_id": "CT"
  },
  {
    "zip": 6371,
    "lat": 41.36438,
    "lng": -72.32688,
    "state_id": "CT"
  },
  {
    "zip": 6373,
    "lat": 41.6705,
    "lng": -71.79895,
    "state_id": "CT"
  },
  {
    "zip": 6374,
    "lat": 41.68042,
    "lng": -71.91421,
    "state_id": "CT"
  },
  {
    "zip": 6375,
    "lat": 41.40513,
    "lng": -72.12491,
    "state_id": "CT"
  },
  {
    "zip": 6376,
    "lat": 41.29418,
    "lng": -72.25379,
    "state_id": "CT"
  },
  {
    "zip": 6377,
    "lat": 41.72621,
    "lng": -71.81754,
    "state_id": "CT"
  },
  {
    "zip": 6378,
    "lat": 41.38057,
    "lng": -71.91397,
    "state_id": "CT"
  },
  {
    "zip": 6379,
    "lat": 41.36703,
    "lng": -71.85771,
    "state_id": "CT"
  },
  {
    "zip": 6380,
    "lat": 41.56548,
    "lng": -72.05307,
    "state_id": "CT"
  },
  {
    "zip": 6382,
    "lat": 41.46233,
    "lng": -72.1177,
    "state_id": "CT"
  },
  {
    "zip": 6384,
    "lat": 41.58138,
    "lng": -71.83183,
    "state_id": "CT"
  },
  {
    "zip": 6385,
    "lat": 41.35743,
    "lng": -72.15578,
    "state_id": "CT"
  },
  {
    "zip": 6387,
    "lat": 41.74281,
    "lng": -71.91049,
    "state_id": "CT"
  },
  {
    "zip": 6389,
    "lat": 41.5642,
    "lng": -72.12928,
    "state_id": "CT"
  },
  {
    "zip": 6401,
    "lat": 41.34424,
    "lng": -73.0689,
    "state_id": "CT"
  },
  {
    "zip": 6403,
    "lat": 41.43901,
    "lng": -73.05679,
    "state_id": "CT"
  },
  {
    "zip": 6405,
    "lat": 41.28319,
    "lng": -72.79795,
    "state_id": "CT"
  },
  {
    "zip": 6409,
    "lat": 41.35125,
    "lng": -72.4201,
    "state_id": "CT"
  },
  {
    "zip": 6410,
    "lat": 41.51172,
    "lng": -72.90425,
    "state_id": "CT"
  },
  {
    "zip": 6412,
    "lat": 41.40594,
    "lng": -72.48319,
    "state_id": "CT"
  },
  {
    "zip": 6413,
    "lat": 41.29801,
    "lng": -72.53002,
    "state_id": "CT"
  },
  {
    "zip": 6414,
    "lat": 41.56674,
    "lng": -72.55587,
    "state_id": "CT"
  },
  {
    "zip": 6415,
    "lat": 41.55747,
    "lng": -72.34738,
    "state_id": "CT"
  },
  {
    "zip": 6416,
    "lat": 41.61412,
    "lng": -72.66421,
    "state_id": "CT"
  },
  {
    "zip": 6417,
    "lat": 41.36894,
    "lng": -72.46226,
    "state_id": "CT"
  },
  {
    "zip": 6418,
    "lat": 41.32656,
    "lng": -73.08335,
    "state_id": "CT"
  },
  {
    "zip": 6419,
    "lat": 41.37925,
    "lng": -72.57848,
    "state_id": "CT"
  },
  {
    "zip": 6420,
    "lat": 41.48518,
    "lng": -72.26682,
    "state_id": "CT"
  },
  {
    "zip": 6422,
    "lat": 41.46223,
    "lng": -72.68279,
    "state_id": "CT"
  },
  {
    "zip": 6423,
    "lat": 41.4671,
    "lng": -72.38797,
    "state_id": "CT"
  },
  {
    "zip": 6424,
    "lat": 41.55889,
    "lng": -72.50564,
    "state_id": "CT"
  },
  {
    "zip": 6426,
    "lat": 41.35296,
    "lng": -72.39872,
    "state_id": "CT"
  },
  {
    "zip": 6437,
    "lat": 41.33452,
    "lng": -72.70043,
    "state_id": "CT"
  },
  {
    "zip": 6438,
    "lat": 41.45705,
    "lng": -72.50352,
    "state_id": "CT"
  },
  {
    "zip": 6441,
    "lat": 41.46505,
    "lng": -72.57741,
    "state_id": "CT"
  },
  {
    "zip": 6442,
    "lat": 41.34305,
    "lng": -72.44535,
    "state_id": "CT"
  },
  {
    "zip": 6443,
    "lat": 41.33969,
    "lng": -72.62777,
    "state_id": "CT"
  },
  {
    "zip": 6444,
    "lat": 41.56267,
    "lng": -72.93318,
    "state_id": "CT"
  },
  {
    "zip": 6447,
    "lat": 41.63306,
    "lng": -72.45472,
    "state_id": "CT"
  },
  {
    "zip": 6450,
    "lat": 41.53303,
    "lng": -72.77388,
    "state_id": "CT"
  },
  {
    "zip": 6451,
    "lat": 41.54204,
    "lng": -72.82349,
    "state_id": "CT"
  },
  {
    "zip": 6455,
    "lat": 41.51502,
    "lng": -72.71719,
    "state_id": "CT"
  },
  {
    "zip": 6456,
    "lat": 41.53992,
    "lng": -72.55024,
    "state_id": "CT"
  },
  {
    "zip": 6457,
    "lat": 41.54764,
    "lng": -72.65489,
    "state_id": "CT"
  },
  {
    "zip": 6460,
    "lat": 41.21513,
    "lng": -73.05142,
    "state_id": "CT"
  },
  {
    "zip": 6461,
    "lat": 41.23949,
    "lng": -73.07519,
    "state_id": "CT"
  },
  {
    "zip": 6467,
    "lat": 41.56787,
    "lng": -72.89985,
    "state_id": "CT"
  },
  {
    "zip": 6468,
    "lat": 41.33786,
    "lng": -73.22506,
    "state_id": "CT"
  },
  {
    "zip": 6469,
    "lat": 41.50872,
    "lng": -72.44749,
    "state_id": "CT"
  },
  {
    "zip": 6470,
    "lat": 41.39436,
    "lng": -73.31936,
    "state_id": "CT"
  },
  {
    "zip": 6471,
    "lat": 41.33057,
    "lng": -72.77473,
    "state_id": "CT"
  },
  {
    "zip": 6472,
    "lat": 41.38024,
    "lng": -72.77887,
    "state_id": "CT"
  },
  {
    "zip": 6473,
    "lat": 41.38069,
    "lng": -72.85601,
    "state_id": "CT"
  },
  {
    "zip": 6475,
    "lat": 41.30174,
    "lng": -72.38571,
    "state_id": "CT"
  },
  {
    "zip": 6477,
    "lat": 41.28272,
    "lng": -73.02718,
    "state_id": "CT"
  },
  {
    "zip": 6478,
    "lat": 41.43127,
    "lng": -73.13505,
    "state_id": "CT"
  },
  {
    "zip": 6479,
    "lat": 41.57697,
    "lng": -72.90459,
    "state_id": "CT"
  },
  {
    "zip": 6480,
    "lat": 41.59882,
    "lng": -72.58905,
    "state_id": "CT"
  },
  {
    "zip": 6481,
    "lat": 41.53358,
    "lng": -72.70034,
    "state_id": "CT"
  },
  {
    "zip": 6482,
    "lat": 41.40665,
    "lng": -73.24489,
    "state_id": "CT"
  },
  {
    "zip": 6483,
    "lat": 41.38101,
    "lng": -73.0872,
    "state_id": "CT"
  },
  {
    "zip": 6484,
    "lat": 41.30601,
    "lng": -73.13829,
    "state_id": "CT"
  },
  {
    "zip": 6488,
    "lat": 41.47454,
    "lng": -73.23291,
    "state_id": "CT"
  },
  {
    "zip": 6489,
    "lat": 41.61333,
    "lng": -72.87252,
    "state_id": "CT"
  },
  {
    "zip": 6492,
    "lat": 41.45912,
    "lng": -72.80462,
    "state_id": "CT"
  },
  {
    "zip": 6498,
    "lat": 41.30687,
    "lng": -72.46654,
    "state_id": "CT"
  },
  {
    "zip": 6510,
    "lat": 41.30648,
    "lng": -72.92601,
    "state_id": "CT"
  },
  {
    "zip": 6511,
    "lat": 41.31621,
    "lng": -72.92674,
    "state_id": "CT"
  },
  {
    "zip": 6512,
    "lat": 41.28054,
    "lng": -72.87211,
    "state_id": "CT"
  },
  {
    "zip": 6513,
    "lat": 41.31968,
    "lng": -72.86821,
    "state_id": "CT"
  },
  {
    "zip": 6514,
    "lat": 41.37348,
    "lng": -72.94116,
    "state_id": "CT"
  },
  {
    "zip": 6515,
    "lat": 41.32793,
    "lng": -72.96956,
    "state_id": "CT"
  },
  {
    "zip": 6516,
    "lat": 41.27396,
    "lng": -72.96713,
    "state_id": "CT"
  },
  {
    "zip": 6517,
    "lat": 41.34836,
    "lng": -72.90503,
    "state_id": "CT"
  },
  {
    "zip": 6518,
    "lat": 41.42763,
    "lng": -72.91349,
    "state_id": "CT"
  },
  {
    "zip": 6519,
    "lat": 41.29532,
    "lng": -72.93584,
    "state_id": "CT"
  },
  {
    "zip": 6524,
    "lat": 41.42613,
    "lng": -72.99309,
    "state_id": "CT"
  },
  {
    "zip": 6525,
    "lat": 41.35669,
    "lng": -73.01013,
    "state_id": "CT"
  },
  {
    "zip": 6604,
    "lat": 41.17748,
    "lng": -73.20091,
    "state_id": "CT"
  },
  {
    "zip": 6605,
    "lat": 41.16342,
    "lng": -73.21781,
    "state_id": "CT"
  },
  {
    "zip": 6606,
    "lat": 41.21173,
    "lng": -73.20801,
    "state_id": "CT"
  },
  {
    "zip": 6607,
    "lat": 41.17371,
    "lng": -73.16695,
    "state_id": "CT"
  },
  {
    "zip": 6608,
    "lat": 41.1868,
    "lng": -73.18098,
    "state_id": "CT"
  },
  {
    "zip": 6610,
    "lat": 41.20464,
    "lng": -73.16944,
    "state_id": "CT"
  },
  {
    "zip": 6611,
    "lat": 41.26008,
    "lng": -73.20947,
    "state_id": "CT"
  },
  {
    "zip": 6612,
    "lat": 41.26503,
    "lng": -73.30092,
    "state_id": "CT"
  },
  {
    "zip": 6614,
    "lat": 41.23017,
    "lng": -73.12788,
    "state_id": "CT"
  },
  {
    "zip": 6615,
    "lat": 41.17116,
    "lng": -73.13465,
    "state_id": "CT"
  },
  {
    "zip": 6702,
    "lat": 41.55719,
    "lng": -73.04563,
    "state_id": "CT"
  },
  {
    "zip": 6704,
    "lat": 41.5865,
    "lng": -73.03304,
    "state_id": "CT"
  },
  {
    "zip": 6705,
    "lat": 41.5488,
    "lng": -72.99312,
    "state_id": "CT"
  },
  {
    "zip": 6706,
    "lat": 41.53246,
    "lng": -73.02329,
    "state_id": "CT"
  },
  {
    "zip": 6708,
    "lat": 41.54957,
    "lng": -73.06718,
    "state_id": "CT"
  },
  {
    "zip": 6710,
    "lat": 41.56911,
    "lng": -73.04581,
    "state_id": "CT"
  },
  {
    "zip": 6712,
    "lat": 41.49925,
    "lng": -72.97604,
    "state_id": "CT"
  },
  {
    "zip": 6716,
    "lat": 41.60068,
    "lng": -72.97336,
    "state_id": "CT"
  },
  {
    "zip": 6750,
    "lat": 41.72316,
    "lng": -73.26415,
    "state_id": "CT"
  },
  {
    "zip": 6751,
    "lat": 41.63795,
    "lng": -73.21222,
    "state_id": "CT"
  },
  {
    "zip": 6752,
    "lat": 41.52084,
    "lng": -73.35965,
    "state_id": "CT"
  },
  {
    "zip": 6754,
    "lat": 41.7718,
    "lng": -73.35798,
    "state_id": "CT"
  },
  {
    "zip": 6755,
    "lat": 41.65035,
    "lng": -73.48116,
    "state_id": "CT"
  },
  {
    "zip": 6756,
    "lat": 41.84732,
    "lng": -73.23609,
    "state_id": "CT"
  },
  {
    "zip": 6757,
    "lat": 41.74118,
    "lng": -73.45864,
    "state_id": "CT"
  },
  {
    "zip": 6758,
    "lat": 41.67548,
    "lng": -73.24206,
    "state_id": "CT"
  },
  {
    "zip": 6759,
    "lat": 41.75754,
    "lng": -73.21403,
    "state_id": "CT"
  },
  {
    "zip": 6762,
    "lat": 41.52775,
    "lng": -73.12304,
    "state_id": "CT"
  },
  {
    "zip": 6763,
    "lat": 41.69214,
    "lng": -73.20196,
    "state_id": "CT"
  },
  {
    "zip": 6770,
    "lat": 41.48923,
    "lng": -73.053,
    "state_id": "CT"
  },
  {
    "zip": 6776,
    "lat": 41.60177,
    "lng": -73.41567,
    "state_id": "CT"
  },
  {
    "zip": 6777,
    "lat": 41.69288,
    "lng": -73.33977,
    "state_id": "CT"
  },
  {
    "zip": 6778,
    "lat": 41.70962,
    "lng": -73.10965,
    "state_id": "CT"
  },
  {
    "zip": 6779,
    "lat": 41.59471,
    "lng": -73.08166,
    "state_id": "CT"
  },
  {
    "zip": 6782,
    "lat": 41.65467,
    "lng": -73.04758,
    "state_id": "CT"
  },
  {
    "zip": 6783,
    "lat": 41.554,
    "lng": -73.29997,
    "state_id": "CT"
  },
  {
    "zip": 6784,
    "lat": 41.57844,
    "lng": -73.49572,
    "state_id": "CT"
  },
  {
    "zip": 6785,
    "lat": 41.6995,
    "lng": -73.44446,
    "state_id": "CT"
  },
  {
    "zip": 6786,
    "lat": 41.66924,
    "lng": -73.01504,
    "state_id": "CT"
  },
  {
    "zip": 6787,
    "lat": 41.67019,
    "lng": -73.08586,
    "state_id": "CT"
  },
  {
    "zip": 6790,
    "lat": 41.83779,
    "lng": -73.12875,
    "state_id": "CT"
  },
  {
    "zip": 6791,
    "lat": 41.75507,
    "lng": -73.05837,
    "state_id": "CT"
  },
  {
    "zip": 6793,
    "lat": 41.62953,
    "lng": -73.2884,
    "state_id": "CT"
  },
  {
    "zip": 6794,
    "lat": 41.64951,
    "lng": -73.32353,
    "state_id": "CT"
  },
  {
    "zip": 6795,
    "lat": 41.6186,
    "lng": -73.12284,
    "state_id": "CT"
  },
  {
    "zip": 6796,
    "lat": 41.87037,
    "lng": -73.33286,
    "state_id": "CT"
  },
  {
    "zip": 6798,
    "lat": 41.56149,
    "lng": -73.20705,
    "state_id": "CT"
  },
  {
    "zip": 6801,
    "lat": 41.37473,
    "lng": -73.39284,
    "state_id": "CT"
  },
  {
    "zip": 6804,
    "lat": 41.4674,
    "lng": -73.39226,
    "state_id": "CT"
  },
  {
    "zip": 6807,
    "lat": 41.0611,
    "lng": -73.59203,
    "state_id": "CT"
  },
  {
    "zip": 6810,
    "lat": 41.37629,
    "lng": -73.45924,
    "state_id": "CT"
  },
  {
    "zip": 6811,
    "lat": 41.42442,
    "lng": -73.48159,
    "state_id": "CT"
  },
  {
    "zip": 6812,
    "lat": 41.48799,
    "lng": -73.48822,
    "state_id": "CT"
  },
  {
    "zip": 6820,
    "lat": 41.07861,
    "lng": -73.48189,
    "state_id": "CT"
  },
  {
    "zip": 6824,
    "lat": 41.1757,
    "lng": -73.28114,
    "state_id": "CT"
  },
  {
    "zip": 6825,
    "lat": 41.19715,
    "lng": -73.2423,
    "state_id": "CT"
  },
  {
    "zip": 6830,
    "lat": 41.04803,
    "lng": -73.62379,
    "state_id": "CT"
  },
  {
    "zip": 6831,
    "lat": 41.08776,
    "lng": -73.66093,
    "state_id": "CT"
  },
  {
    "zip": 6840,
    "lat": 41.1592,
    "lng": -73.49917,
    "state_id": "CT"
  },
  {
    "zip": 6850,
    "lat": 41.12651,
    "lng": -73.44312,
    "state_id": "CT"
  },
  {
    "zip": 6851,
    "lat": 41.13943,
    "lng": -73.40411,
    "state_id": "CT"
  },
  {
    "zip": 6853,
    "lat": 41.06943,
    "lng": -73.43814,
    "state_id": "CT"
  },
  {
    "zip": 6854,
    "lat": 41.08954,
    "lng": -73.42869,
    "state_id": "CT"
  },
  {
    "zip": 6855,
    "lat": 41.09297,
    "lng": -73.39691,
    "state_id": "CT"
  },
  {
    "zip": 6856,
    "lat": 41.11116,
    "lng": -73.42092,
    "state_id": "CT"
  },
  {
    "zip": 6870,
    "lat": 41.02908,
    "lng": -73.56969,
    "state_id": "CT"
  },
  {
    "zip": 6877,
    "lat": 41.30644,
    "lng": -73.5022,
    "state_id": "CT"
  },
  {
    "zip": 6878,
    "lat": 41.03181,
    "lng": -73.58283,
    "state_id": "CT"
  },
  {
    "zip": 6880,
    "lat": 41.14286,
    "lng": -73.34746,
    "state_id": "CT"
  },
  {
    "zip": 6883,
    "lat": 41.2284,
    "lng": -73.37257,
    "state_id": "CT"
  },
  {
    "zip": 6890,
    "lat": 41.14639,
    "lng": -73.28944,
    "state_id": "CT"
  },
  {
    "zip": 6896,
    "lat": 41.30512,
    "lng": -73.39151,
    "state_id": "CT"
  },
  {
    "zip": 6897,
    "lat": 41.207,
    "lng": -73.44007,
    "state_id": "CT"
  },
  {
    "zip": 6901,
    "lat": 41.05359,
    "lng": -73.53817,
    "state_id": "CT"
  },
  {
    "zip": 6902,
    "lat": 41.05893,
    "lng": -73.54661,
    "state_id": "CT"
  },
  {
    "zip": 6903,
    "lat": 41.13558,
    "lng": -73.57219,
    "state_id": "CT"
  },
  {
    "zip": 6905,
    "lat": 41.08883,
    "lng": -73.54348,
    "state_id": "CT"
  },
  {
    "zip": 6906,
    "lat": 41.07101,
    "lng": -73.52256,
    "state_id": "CT"
  },
  {
    "zip": 6907,
    "lat": 41.10087,
    "lng": -73.52015,
    "state_id": "CT"
  },
  {
    "zip": 20001,
    "lat": 38.91085,
    "lng": -77.0179,
    "state_id": "DC"
  },
  {
    "zip": 20002,
    "lat": 38.90511,
    "lng": -76.98437,
    "state_id": "DC"
  },
  {
    "zip": 20003,
    "lat": 38.88125,
    "lng": -76.99056,
    "state_id": "DC"
  },
  {
    "zip": 20004,
    "lat": 38.89485,
    "lng": -77.02861,
    "state_id": "DC"
  },
  {
    "zip": 20005,
    "lat": 38.90453,
    "lng": -77.03171,
    "state_id": "DC"
  },
  {
    "zip": 20006,
    "lat": 38.89856,
    "lng": -77.04129,
    "state_id": "DC"
  },
  {
    "zip": 20007,
    "lat": 38.91418,
    "lng": -77.07866,
    "state_id": "DC"
  },
  {
    "zip": 20008,
    "lat": 38.93595,
    "lng": -77.05931,
    "state_id": "DC"
  },
  {
    "zip": 20009,
    "lat": 38.91996,
    "lng": -77.03752,
    "state_id": "DC"
  },
  {
    "zip": 20010,
    "lat": 38.93238,
    "lng": -77.02995,
    "state_id": "DC"
  },
  {
    "zip": 20011,
    "lat": 38.95241,
    "lng": -77.02278,
    "state_id": "DC"
  },
  {
    "zip": 20012,
    "lat": 38.97704,
    "lng": -77.03195,
    "state_id": "DC"
  },
  {
    "zip": 20015,
    "lat": 38.9666,
    "lng": -77.05848,
    "state_id": "DC"
  },
  {
    "zip": 20016,
    "lat": 38.93815,
    "lng": -77.09147,
    "state_id": "DC"
  },
  {
    "zip": 20017,
    "lat": 38.93845,
    "lng": -76.99318,
    "state_id": "DC"
  },
  {
    "zip": 20018,
    "lat": 38.92612,
    "lng": -76.97273,
    "state_id": "DC"
  },
  {
    "zip": 20019,
    "lat": 38.8918,
    "lng": -76.9427,
    "state_id": "DC"
  },
  {
    "zip": 20020,
    "lat": 38.86159,
    "lng": -76.9753,
    "state_id": "DC"
  },
  {
    "zip": 20024,
    "lat": 38.87826,
    "lng": -77.02758,
    "state_id": "DC"
  },
  {
    "zip": 20032,
    "lat": 38.83437,
    "lng": -77.00674,
    "state_id": "DC"
  },
  {
    "zip": 20036,
    "lat": 38.90696,
    "lng": -77.04166,
    "state_id": "DC"
  },
  {
    "zip": 20037,
    "lat": 38.89797,
    "lng": -77.05609,
    "state_id": "DC"
  },
  {
    "zip": 20045,
    "lat": 38.89673,
    "lng": -77.0308,
    "state_id": "DC"
  },
  {
    "zip": 20052,
    "lat": 38.90013,
    "lng": -77.04698,
    "state_id": "DC"
  },
  {
    "zip": 20053,
    "lat": 38.88419,
    "lng": -77.011,
    "state_id": "DC"
  },
  {
    "zip": 20057,
    "lat": 38.90917,
    "lng": -77.0756,
    "state_id": "DC"
  },
  {
    "zip": 20064,
    "lat": 38.93636,
    "lng": -76.99921,
    "state_id": "DC"
  },
  {
    "zip": 20202,
    "lat": 38.88707,
    "lng": -77.02101,
    "state_id": "DC"
  },
  {
    "zip": 20204,
    "lat": 38.88556,
    "lng": -77.01443,
    "state_id": "DC"
  },
  {
    "zip": 20228,
    "lat": 38.88631,
    "lng": -77.03016,
    "state_id": "DC"
  },
  {
    "zip": 20230,
    "lat": 38.89379,
    "lng": -77.0328,
    "state_id": "DC"
  },
  {
    "zip": 20240,
    "lat": 38.89449,
    "lng": -77.04261,
    "state_id": "DC"
  },
  {
    "zip": 20245,
    "lat": 38.89337,
    "lng": -77.0446,
    "state_id": "DC"
  },
  {
    "zip": 20260,
    "lat": 38.88368,
    "lng": -77.02504,
    "state_id": "DC"
  },
  {
    "zip": 20317,
    "lat": 38.93633,
    "lng": -77.01235,
    "state_id": "DC"
  },
  {
    "zip": 20319,
    "lat": 38.86554,
    "lng": -77.01784,
    "state_id": "DC"
  },
  {
    "zip": 20373,
    "lat": 38.8584,
    "lng": -77.00804,
    "state_id": "DC"
  },
  {
    "zip": 20390,
    "lat": 38.87901,
    "lng": -76.99375,
    "state_id": "DC"
  },
  {
    "zip": 20405,
    "lat": 38.89638,
    "lng": -77.04259,
    "state_id": "DC"
  },
  {
    "zip": 20418,
    "lat": 38.8928,
    "lng": -77.04776,
    "state_id": "DC"
  },
  {
    "zip": 20427,
    "lat": 38.90207,
    "lng": -77.04755,
    "state_id": "DC"
  },
  {
    "zip": 20506,
    "lat": 38.89703,
    "lng": -77.03866,
    "state_id": "DC"
  },
  {
    "zip": 20510,
    "lat": 38.89278,
    "lng": -77.00689,
    "state_id": "DC"
  },
  {
    "zip": 20520,
    "lat": 38.8947,
    "lng": -77.04848,
    "state_id": "DC"
  },
  {
    "zip": 20535,
    "lat": 38.89447,
    "lng": -77.02485,
    "state_id": "DC"
  },
  {
    "zip": 20540,
    "lat": 38.88793,
    "lng": -77.00471,
    "state_id": "DC"
  },
  {
    "zip": 20551,
    "lat": 38.8928,
    "lng": -77.0458,
    "state_id": "DC"
  },
  {
    "zip": 20553,
    "lat": 38.88695,
    "lng": -77.02297,
    "state_id": "DC"
  },
  {
    "zip": 20560,
    "lat": 38.88824,
    "lng": -77.02596,
    "state_id": "DC"
  },
  {
    "zip": 20565,
    "lat": 38.89062,
    "lng": -77.01939,
    "state_id": "DC"
  },
  {
    "zip": 20566,
    "lat": 38.89564,
    "lng": -77.05501,
    "state_id": "DC"
  },
  {
    "zip": 20593,
    "lat": 38.86671,
    "lng": -77.01021,
    "state_id": "DC"
  },
  {
    "zip": 19701,
    "lat": 39.58435,
    "lng": -75.69939,
    "state_id": "DE"
  },
  {
    "zip": 19702,
    "lat": 39.62225,
    "lng": -75.72748,
    "state_id": "DE"
  },
  {
    "zip": 19703,
    "lat": 39.80435,
    "lng": -75.45765,
    "state_id": "DE"
  },
  {
    "zip": 19706,
    "lat": 39.57275,
    "lng": -75.5988,
    "state_id": "DE"
  },
  {
    "zip": 19707,
    "lat": 39.78587,
    "lng": -75.68083,
    "state_id": "DE"
  },
  {
    "zip": 19709,
    "lat": 39.48886,
    "lng": -75.68227,
    "state_id": "DE"
  },
  {
    "zip": 19710,
    "lat": 39.79503,
    "lng": -75.58815,
    "state_id": "DE"
  },
  {
    "zip": 19711,
    "lat": 39.7143,
    "lng": -75.73965,
    "state_id": "DE"
  },
  {
    "zip": 19713,
    "lat": 39.67079,
    "lng": -75.71218,
    "state_id": "DE"
  },
  {
    "zip": 19716,
    "lat": 39.68964,
    "lng": -75.75851,
    "state_id": "DE"
  },
  {
    "zip": 19717,
    "lat": 39.67852,
    "lng": -75.75205,
    "state_id": "DE"
  },
  {
    "zip": 19720,
    "lat": 39.64699,
    "lng": -75.60633,
    "state_id": "DE"
  },
  {
    "zip": 19730,
    "lat": 39.45925,
    "lng": -75.65356,
    "state_id": "DE"
  },
  {
    "zip": 19731,
    "lat": 39.5189,
    "lng": -75.5833,
    "state_id": "DE"
  },
  {
    "zip": 19732,
    "lat": 39.78406,
    "lng": -75.57011,
    "state_id": "DE"
  },
  {
    "zip": 19733,
    "lat": 39.55623,
    "lng": -75.65095,
    "state_id": "DE"
  },
  {
    "zip": 19734,
    "lat": 39.38356,
    "lng": -75.64947,
    "state_id": "DE"
  },
  {
    "zip": 19735,
    "lat": 39.80544,
    "lng": -75.60231,
    "state_id": "DE"
  },
  {
    "zip": 19736,
    "lat": 39.80611,
    "lng": -75.67645,
    "state_id": "DE"
  },
  {
    "zip": 19801,
    "lat": 39.7286,
    "lng": -75.54354,
    "state_id": "DE"
  },
  {
    "zip": 19802,
    "lat": 39.75678,
    "lng": -75.52898,
    "state_id": "DE"
  },
  {
    "zip": 19803,
    "lat": 39.79996,
    "lng": -75.54202,
    "state_id": "DE"
  },
  {
    "zip": 19804,
    "lat": 39.71687,
    "lng": -75.61837,
    "state_id": "DE"
  },
  {
    "zip": 19805,
    "lat": 39.74475,
    "lng": -75.59269,
    "state_id": "DE"
  },
  {
    "zip": 19806,
    "lat": 39.76328,
    "lng": -75.56421,
    "state_id": "DE"
  },
  {
    "zip": 19807,
    "lat": 39.79762,
    "lng": -75.60979,
    "state_id": "DE"
  },
  {
    "zip": 19808,
    "lat": 39.73838,
    "lng": -75.66525,
    "state_id": "DE"
  },
  {
    "zip": 19809,
    "lat": 39.7587,
    "lng": -75.50237,
    "state_id": "DE"
  },
  {
    "zip": 19810,
    "lat": 39.81865,
    "lng": -75.50651,
    "state_id": "DE"
  },
  {
    "zip": 19901,
    "lat": 39.17172,
    "lng": -75.47693,
    "state_id": "DE"
  },
  {
    "zip": 19902,
    "lat": 39.12528,
    "lng": -75.48168,
    "state_id": "DE"
  },
  {
    "zip": 19904,
    "lat": 39.17023,
    "lng": -75.59493,
    "state_id": "DE"
  },
  {
    "zip": 19930,
    "lat": 38.55095,
    "lng": -75.06487,
    "state_id": "DE"
  },
  {
    "zip": 19931,
    "lat": 38.57399,
    "lng": -75.62294,
    "state_id": "DE"
  },
  {
    "zip": 19933,
    "lat": 38.736,
    "lng": -75.60953,
    "state_id": "DE"
  },
  {
    "zip": 19934,
    "lat": 39.08335,
    "lng": -75.62911,
    "state_id": "DE"
  },
  {
    "zip": 19936,
    "lat": 39.21909,
    "lng": -75.5845,
    "state_id": "DE"
  },
  {
    "zip": 19938,
    "lat": 39.25719,
    "lng": -75.69923,
    "state_id": "DE"
  },
  {
    "zip": 19939,
    "lat": 38.56479,
    "lng": -75.21064,
    "state_id": "DE"
  },
  {
    "zip": 19940,
    "lat": 38.47787,
    "lng": -75.56893,
    "state_id": "DE"
  },
  {
    "zip": 19941,
    "lat": 38.79557,
    "lng": -75.42556,
    "state_id": "DE"
  },
  {
    "zip": 19943,
    "lat": 39.0058,
    "lng": -75.60881,
    "state_id": "DE"
  },
  {
    "zip": 19944,
    "lat": 38.47578,
    "lng": -75.05559,
    "state_id": "DE"
  },
  {
    "zip": 19945,
    "lat": 38.50476,
    "lng": -75.22888,
    "state_id": "DE"
  },
  {
    "zip": 19946,
    "lat": 39.03423,
    "lng": -75.45416,
    "state_id": "DE"
  },
  {
    "zip": 19947,
    "lat": 38.67239,
    "lng": -75.39668,
    "state_id": "DE"
  },
  {
    "zip": 19950,
    "lat": 38.82013,
    "lng": -75.59728,
    "state_id": "DE"
  },
  {
    "zip": 19951,
    "lat": 38.69117,
    "lng": -75.25454,
    "state_id": "DE"
  },
  {
    "zip": 19952,
    "lat": 38.91587,
    "lng": -75.62609,
    "state_id": "DE"
  },
  {
    "zip": 19953,
    "lat": 39.15217,
    "lng": -75.69809,
    "state_id": "DE"
  },
  {
    "zip": 19954,
    "lat": 38.89744,
    "lng": -75.52036,
    "state_id": "DE"
  },
  {
    "zip": 19955,
    "lat": 39.22599,
    "lng": -75.66854,
    "state_id": "DE"
  },
  {
    "zip": 19956,
    "lat": 38.54787,
    "lng": -75.54079,
    "state_id": "DE"
  },
  {
    "zip": 19958,
    "lat": 38.72894,
    "lng": -75.16498,
    "state_id": "DE"
  },
  {
    "zip": 19960,
    "lat": 38.84899,
    "lng": -75.41363,
    "state_id": "DE"
  },
  {
    "zip": 19962,
    "lat": 39.07016,
    "lng": -75.49745,
    "state_id": "DE"
  },
  {
    "zip": 19963,
    "lat": 38.9361,
    "lng": -75.38158,
    "state_id": "DE"
  },
  {
    "zip": 19964,
    "lat": 39.09067,
    "lng": -75.72329,
    "state_id": "DE"
  },
  {
    "zip": 19966,
    "lat": 38.58114,
    "lng": -75.28158,
    "state_id": "DE"
  },
  {
    "zip": 19967,
    "lat": 38.53871,
    "lng": -75.12258,
    "state_id": "DE"
  },
  {
    "zip": 19968,
    "lat": 38.77759,
    "lng": -75.28649,
    "state_id": "DE"
  },
  {
    "zip": 19970,
    "lat": 38.56188,
    "lng": -75.09661,
    "state_id": "DE"
  },
  {
    "zip": 19971,
    "lat": 38.68808,
    "lng": -75.10114,
    "state_id": "DE"
  },
  {
    "zip": 19973,
    "lat": 38.63927,
    "lng": -75.61578,
    "state_id": "DE"
  },
  {
    "zip": 19975,
    "lat": 38.47014,
    "lng": -75.17488,
    "state_id": "DE"
  },
  {
    "zip": 19977,
    "lat": 39.29628,
    "lng": -75.55079,
    "state_id": "DE"
  },
  {
    "zip": 19979,
    "lat": 39.04875,
    "lng": -75.57251,
    "state_id": "DE"
  },
  {
    "zip": 32003,
    "lat": 30.09813,
    "lng": -81.71255,
    "state_id": "FL"
  },
  {
    "zip": 32008,
    "lat": 29.91651,
    "lng": -82.91313,
    "state_id": "FL"
  },
  {
    "zip": 32009,
    "lat": 30.41969,
    "lng": -81.97306,
    "state_id": "FL"
  },
  {
    "zip": 32011,
    "lat": 30.57101,
    "lng": -81.84037,
    "state_id": "FL"
  },
  {
    "zip": 32024,
    "lat": 30.08057,
    "lng": -82.72642,
    "state_id": "FL"
  },
  {
    "zip": 32025,
    "lat": 30.11075,
    "lng": -82.57601,
    "state_id": "FL"
  },
  {
    "zip": 32033,
    "lat": 29.78899,
    "lng": -81.44175,
    "state_id": "FL"
  },
  {
    "zip": 32034,
    "lat": 30.60546,
    "lng": -81.49163,
    "state_id": "FL"
  },
  {
    "zip": 32038,
    "lat": 29.93801,
    "lng": -82.70056,
    "state_id": "FL"
  },
  {
    "zip": 32040,
    "lat": 30.24347,
    "lng": -82.23885,
    "state_id": "FL"
  },
  {
    "zip": 32043,
    "lat": 29.93483,
    "lng": -81.72972,
    "state_id": "FL"
  },
  {
    "zip": 32044,
    "lat": 29.86109,
    "lng": -82.17457,
    "state_id": "FL"
  },
  {
    "zip": 32046,
    "lat": 30.70318,
    "lng": -81.91857,
    "state_id": "FL"
  },
  {
    "zip": 32052,
    "lat": 30.47855,
    "lng": -82.93597,
    "state_id": "FL"
  },
  {
    "zip": 32053,
    "lat": 30.56817,
    "lng": -83.13104,
    "state_id": "FL"
  },
  {
    "zip": 32054,
    "lat": 30.02746,
    "lng": -82.39275,
    "state_id": "FL"
  },
  {
    "zip": 32055,
    "lat": 30.27019,
    "lng": -82.62539,
    "state_id": "FL"
  },
  {
    "zip": 32058,
    "lat": 30.08343,
    "lng": -82.10835,
    "state_id": "FL"
  },
  {
    "zip": 32059,
    "lat": 30.38385,
    "lng": -83.2711,
    "state_id": "FL"
  },
  {
    "zip": 32060,
    "lat": 30.27522,
    "lng": -83.04974,
    "state_id": "FL"
  },
  {
    "zip": 32061,
    "lat": 30.09757,
    "lng": -82.51031,
    "state_id": "FL"
  },
  {
    "zip": 32062,
    "lat": 30.1334,
    "lng": -82.98041,
    "state_id": "FL"
  },
  {
    "zip": 32063,
    "lat": 30.24678,
    "lng": -82.11208,
    "state_id": "FL"
  },
  {
    "zip": 32064,
    "lat": 30.29566,
    "lng": -82.98443,
    "state_id": "FL"
  },
  {
    "zip": 32065,
    "lat": 30.14921,
    "lng": -81.80119,
    "state_id": "FL"
  },
  {
    "zip": 32066,
    "lat": 30.03677,
    "lng": -83.23398,
    "state_id": "FL"
  },
  {
    "zip": 32068,
    "lat": 30.07889,
    "lng": -81.88326,
    "state_id": "FL"
  },
  {
    "zip": 32071,
    "lat": 30.04168,
    "lng": -82.94442,
    "state_id": "FL"
  },
  {
    "zip": 32073,
    "lat": 30.17026,
    "lng": -81.73932,
    "state_id": "FL"
  },
  {
    "zip": 32079,
    "lat": 29.9784,
    "lng": -81.80593,
    "state_id": "FL"
  },
  {
    "zip": 32080,
    "lat": 29.82548,
    "lng": -81.2745,
    "state_id": "FL"
  },
  {
    "zip": 32081,
    "lat": 30.12037,
    "lng": -81.41284,
    "state_id": "FL"
  },
  {
    "zip": 32082,
    "lat": 30.1254,
    "lng": -81.36595,
    "state_id": "FL"
  },
  {
    "zip": 32083,
    "lat": 30.09216,
    "lng": -82.23423,
    "state_id": "FL"
  },
  {
    "zip": 32084,
    "lat": 29.91736,
    "lng": -81.368,
    "state_id": "FL"
  },
  {
    "zip": 32086,
    "lat": 29.75675,
    "lng": -81.30311,
    "state_id": "FL"
  },
  {
    "zip": 32087,
    "lat": 30.39035,
    "lng": -82.28513,
    "state_id": "FL"
  },
  {
    "zip": 32091,
    "lat": 29.93118,
    "lng": -82.12545,
    "state_id": "FL"
  },
  {
    "zip": 32092,
    "lat": 29.93407,
    "lng": -81.51364,
    "state_id": "FL"
  },
  {
    "zip": 32094,
    "lat": 30.196,
    "lng": -82.82962,
    "state_id": "FL"
  },
  {
    "zip": 32095,
    "lat": 30.01101,
    "lng": -81.41077,
    "state_id": "FL"
  },
  {
    "zip": 32096,
    "lat": 30.43954,
    "lng": -82.69984,
    "state_id": "FL"
  },
  {
    "zip": 32097,
    "lat": 30.66378,
    "lng": -81.62968,
    "state_id": "FL"
  },
  {
    "zip": 32102,
    "lat": 29.1468,
    "lng": -81.56166,
    "state_id": "FL"
  },
  {
    "zip": 32110,
    "lat": 29.42166,
    "lng": -81.33933,
    "state_id": "FL"
  },
  {
    "zip": 32112,
    "lat": 29.4272,
    "lng": -81.55791,
    "state_id": "FL"
  },
  {
    "zip": 32113,
    "lat": 29.39954,
    "lng": -82.08146,
    "state_id": "FL"
  },
  {
    "zip": 32114,
    "lat": 29.19393,
    "lng": -81.05145,
    "state_id": "FL"
  },
  {
    "zip": 32117,
    "lat": 29.2353,
    "lng": -81.06566,
    "state_id": "FL"
  },
  {
    "zip": 32118,
    "lat": 29.21058,
    "lng": -81.00438,
    "state_id": "FL"
  },
  {
    "zip": 32119,
    "lat": 29.16002,
    "lng": -81.02691,
    "state_id": "FL"
  },
  {
    "zip": 32124,
    "lat": 29.1654,
    "lng": -81.14927,
    "state_id": "FL"
  },
  {
    "zip": 32127,
    "lat": 29.10669,
    "lng": -80.97643,
    "state_id": "FL"
  },
  {
    "zip": 32128,
    "lat": 29.09798,
    "lng": -81.07406,
    "state_id": "FL"
  },
  {
    "zip": 32129,
    "lat": 29.13714,
    "lng": -81.02392,
    "state_id": "FL"
  },
  {
    "zip": 32130,
    "lat": 29.15571,
    "lng": -81.34467,
    "state_id": "FL"
  },
  {
    "zip": 32131,
    "lat": 29.6814,
    "lng": -81.57014,
    "state_id": "FL"
  },
  {
    "zip": 32132,
    "lat": 28.9768,
    "lng": -80.9256,
    "state_id": "FL"
  },
  {
    "zip": 32133,
    "lat": 29.02023,
    "lng": -81.90928,
    "state_id": "FL"
  },
  {
    "zip": 32134,
    "lat": 29.38283,
    "lng": -81.83703,
    "state_id": "FL"
  },
  {
    "zip": 32136,
    "lat": 29.45243,
    "lng": -81.14089,
    "state_id": "FL"
  },
  {
    "zip": 32137,
    "lat": 29.57972,
    "lng": -81.22272,
    "state_id": "FL"
  },
  {
    "zip": 32139,
    "lat": 29.37617,
    "lng": -81.61594,
    "state_id": "FL"
  },
  {
    "zip": 32140,
    "lat": 29.75924,
    "lng": -81.86791,
    "state_id": "FL"
  },
  {
    "zip": 32141,
    "lat": 28.92304,
    "lng": -80.90102,
    "state_id": "FL"
  },
  {
    "zip": 32145,
    "lat": 29.67783,
    "lng": -81.42201,
    "state_id": "FL"
  },
  {
    "zip": 32147,
    "lat": 29.60283,
    "lng": -81.78615,
    "state_id": "FL"
  },
  {
    "zip": 32148,
    "lat": 29.60799,
    "lng": -81.8841,
    "state_id": "FL"
  },
  {
    "zip": 32157,
    "lat": 29.46809,
    "lng": -81.57881,
    "state_id": "FL"
  },
  {
    "zip": 32159,
    "lat": 28.9258,
    "lng": -81.90291,
    "state_id": "FL"
  },
  {
    "zip": 32162,
    "lat": 28.91696,
    "lng": -81.99287,
    "state_id": "FL"
  },
  {
    "zip": 32164,
    "lat": 29.48409,
    "lng": -81.21789,
    "state_id": "FL"
  },
  {
    "zip": 32168,
    "lat": 28.97367,
    "lng": -81.02742,
    "state_id": "FL"
  },
  {
    "zip": 32169,
    "lat": 28.97696,
    "lng": -80.87264,
    "state_id": "FL"
  },
  {
    "zip": 32174,
    "lat": 29.28542,
    "lng": -81.16355,
    "state_id": "FL"
  },
  {
    "zip": 32176,
    "lat": 29.32552,
    "lng": -81.06034,
    "state_id": "FL"
  },
  {
    "zip": 32177,
    "lat": 29.69121,
    "lng": -81.71137,
    "state_id": "FL"
  },
  {
    "zip": 32179,
    "lat": 29.08858,
    "lng": -81.88794,
    "state_id": "FL"
  },
  {
    "zip": 32180,
    "lat": 29.2233,
    "lng": -81.44496,
    "state_id": "FL"
  },
  {
    "zip": 32181,
    "lat": 29.50399,
    "lng": -81.60013,
    "state_id": "FL"
  },
  {
    "zip": 32187,
    "lat": 29.57759,
    "lng": -81.55406,
    "state_id": "FL"
  },
  {
    "zip": 32189,
    "lat": 29.55561,
    "lng": -81.65121,
    "state_id": "FL"
  },
  {
    "zip": 32190,
    "lat": 29.34155,
    "lng": -81.48555,
    "state_id": "FL"
  },
  {
    "zip": 32193,
    "lat": 29.49054,
    "lng": -81.65295,
    "state_id": "FL"
  },
  {
    "zip": 32195,
    "lat": 28.98584,
    "lng": -81.90181,
    "state_id": "FL"
  },
  {
    "zip": 32202,
    "lat": 30.32503,
    "lng": -81.64802,
    "state_id": "FL"
  },
  {
    "zip": 32204,
    "lat": 30.31653,
    "lng": -81.68072,
    "state_id": "FL"
  },
  {
    "zip": 32205,
    "lat": 30.30287,
    "lng": -81.72197,
    "state_id": "FL"
  },
  {
    "zip": 32206,
    "lat": 30.35056,
    "lng": -81.63841,
    "state_id": "FL"
  },
  {
    "zip": 32207,
    "lat": 30.29,
    "lng": -81.63997,
    "state_id": "FL"
  },
  {
    "zip": 32208,
    "lat": 30.39343,
    "lng": -81.68254,
    "state_id": "FL"
  },
  {
    "zip": 32209,
    "lat": 30.36106,
    "lng": -81.69551,
    "state_id": "FL"
  },
  {
    "zip": 32210,
    "lat": 30.26558,
    "lng": -81.74463,
    "state_id": "FL"
  },
  {
    "zip": 32211,
    "lat": 30.33266,
    "lng": -81.58402,
    "state_id": "FL"
  },
  {
    "zip": 32212,
    "lat": 30.21908,
    "lng": -81.67892,
    "state_id": "FL"
  },
  {
    "zip": 32216,
    "lat": 30.27874,
    "lng": -81.58314,
    "state_id": "FL"
  },
  {
    "zip": 32217,
    "lat": 30.23919,
    "lng": -81.62723,
    "state_id": "FL"
  },
  {
    "zip": 32218,
    "lat": 30.48665,
    "lng": -81.66922,
    "state_id": "FL"
  },
  {
    "zip": 32219,
    "lat": 30.43045,
    "lng": -81.79383,
    "state_id": "FL"
  },
  {
    "zip": 32220,
    "lat": 30.35047,
    "lng": -81.86658,
    "state_id": "FL"
  },
  {
    "zip": 32221,
    "lat": 30.25633,
    "lng": -81.8542,
    "state_id": "FL"
  },
  {
    "zip": 32222,
    "lat": 30.21682,
    "lng": -81.82881,
    "state_id": "FL"
  },
  {
    "zip": 32223,
    "lat": 30.15489,
    "lng": -81.64815,
    "state_id": "FL"
  },
  {
    "zip": 32224,
    "lat": 30.27027,
    "lng": -81.46796,
    "state_id": "FL"
  },
  {
    "zip": 32225,
    "lat": 30.35751,
    "lng": -81.50602,
    "state_id": "FL"
  },
  {
    "zip": 32226,
    "lat": 30.46439,
    "lng": -81.50977,
    "state_id": "FL"
  },
  {
    "zip": 32227,
    "lat": 30.39001,
    "lng": -81.4072,
    "state_id": "FL"
  },
  {
    "zip": 32228,
    "lat": 30.39158,
    "lng": -81.40022,
    "state_id": "FL"
  },
  {
    "zip": 32233,
    "lat": 30.35851,
    "lng": -81.42111,
    "state_id": "FL"
  },
  {
    "zip": 32234,
    "lat": 30.2146,
    "lng": -81.98373,
    "state_id": "FL"
  },
  {
    "zip": 32244,
    "lat": 30.21791,
    "lng": -81.75349,
    "state_id": "FL"
  },
  {
    "zip": 32246,
    "lat": 30.29399,
    "lng": -81.51797,
    "state_id": "FL"
  },
  {
    "zip": 32250,
    "lat": 30.27811,
    "lng": -81.40904,
    "state_id": "FL"
  },
  {
    "zip": 32254,
    "lat": 30.34156,
    "lng": -81.7358,
    "state_id": "FL"
  },
  {
    "zip": 32256,
    "lat": 30.18783,
    "lng": -81.50041,
    "state_id": "FL"
  },
  {
    "zip": 32257,
    "lat": 30.19519,
    "lng": -81.61233,
    "state_id": "FL"
  },
  {
    "zip": 32258,
    "lat": 30.13755,
    "lng": -81.54844,
    "state_id": "FL"
  },
  {
    "zip": 32259,
    "lat": 30.07586,
    "lng": -81.58724,
    "state_id": "FL"
  },
  {
    "zip": 32266,
    "lat": 30.31645,
    "lng": -81.41177,
    "state_id": "FL"
  },
  {
    "zip": 32277,
    "lat": 30.37275,
    "lng": -81.59459,
    "state_id": "FL"
  },
  {
    "zip": 32301,
    "lat": 30.42563,
    "lng": -84.25823,
    "state_id": "FL"
  },
  {
    "zip": 32303,
    "lat": 30.51435,
    "lng": -84.34363,
    "state_id": "FL"
  },
  {
    "zip": 32304,
    "lat": 30.45363,
    "lng": -84.35159,
    "state_id": "FL"
  },
  {
    "zip": 32305,
    "lat": 30.334,
    "lng": -84.28707,
    "state_id": "FL"
  },
  {
    "zip": 32308,
    "lat": 30.47711,
    "lng": -84.22459,
    "state_id": "FL"
  },
  {
    "zip": 32309,
    "lat": 30.56622,
    "lng": -84.09823,
    "state_id": "FL"
  },
  {
    "zip": 32310,
    "lat": 30.39473,
    "lng": -84.51186,
    "state_id": "FL"
  },
  {
    "zip": 32311,
    "lat": 30.40038,
    "lng": -84.16254,
    "state_id": "FL"
  },
  {
    "zip": 32312,
    "lat": 30.60551,
    "lng": -84.22893,
    "state_id": "FL"
  },
  {
    "zip": 32317,
    "lat": 30.46508,
    "lng": -84.1128,
    "state_id": "FL"
  },
  {
    "zip": 32320,
    "lat": 29.73685,
    "lng": -85.0901,
    "state_id": "FL"
  },
  {
    "zip": 32321,
    "lat": 30.26045,
    "lng": -84.98131,
    "state_id": "FL"
  },
  {
    "zip": 32322,
    "lat": 29.90203,
    "lng": -84.63885,
    "state_id": "FL"
  },
  {
    "zip": 32323,
    "lat": 29.8933,
    "lng": -84.61289,
    "state_id": "FL"
  },
  {
    "zip": 32324,
    "lat": 30.6525,
    "lng": -84.79152,
    "state_id": "FL"
  },
  {
    "zip": 32327,
    "lat": 30.18667,
    "lng": -84.30565,
    "state_id": "FL"
  },
  {
    "zip": 32328,
    "lat": 29.83913,
    "lng": -84.93159,
    "state_id": "FL"
  },
  {
    "zip": 32330,
    "lat": 30.57985,
    "lng": -84.75048,
    "state_id": "FL"
  },
  {
    "zip": 32331,
    "lat": 30.43978,
    "lng": -83.64028,
    "state_id": "FL"
  },
  {
    "zip": 32332,
    "lat": 30.61005,
    "lng": -84.66868,
    "state_id": "FL"
  },
  {
    "zip": 32333,
    "lat": 30.60642,
    "lng": -84.41261,
    "state_id": "FL"
  },
  {
    "zip": 32334,
    "lat": 30.25717,
    "lng": -84.74533,
    "state_id": "FL"
  },
  {
    "zip": 32336,
    "lat": 30.25375,
    "lng": -83.85901,
    "state_id": "FL"
  },
  {
    "zip": 32340,
    "lat": 30.46196,
    "lng": -83.43505,
    "state_id": "FL"
  },
  {
    "zip": 32343,
    "lat": 30.48489,
    "lng": -84.47691,
    "state_id": "FL"
  },
  {
    "zip": 32344,
    "lat": 30.44055,
    "lng": -83.909,
    "state_id": "FL"
  },
  {
    "zip": 32346,
    "lat": 29.99797,
    "lng": -84.39201,
    "state_id": "FL"
  },
  {
    "zip": 32347,
    "lat": 30.16688,
    "lng": -83.61601,
    "state_id": "FL"
  },
  {
    "zip": 32348,
    "lat": 29.96859,
    "lng": -83.59698,
    "state_id": "FL"
  },
  {
    "zip": 32350,
    "lat": 30.58696,
    "lng": -83.32292,
    "state_id": "FL"
  },
  {
    "zip": 32351,
    "lat": 30.52228,
    "lng": -84.68182,
    "state_id": "FL"
  },
  {
    "zip": 32352,
    "lat": 30.65117,
    "lng": -84.58667,
    "state_id": "FL"
  },
  {
    "zip": 32355,
    "lat": 30.17164,
    "lng": -84.20722,
    "state_id": "FL"
  },
  {
    "zip": 32356,
    "lat": 29.85179,
    "lng": -83.43124,
    "state_id": "FL"
  },
  {
    "zip": 32358,
    "lat": 30.11419,
    "lng": -84.5603,
    "state_id": "FL"
  },
  {
    "zip": 32359,
    "lat": 29.67905,
    "lng": -83.38688,
    "state_id": "FL"
  },
  {
    "zip": 32361,
    "lat": 30.35769,
    "lng": -83.9886,
    "state_id": "FL"
  },
  {
    "zip": 32399,
    "lat": 30.43276,
    "lng": -84.26982,
    "state_id": "FL"
  },
  {
    "zip": 32401,
    "lat": 30.15978,
    "lng": -85.6608,
    "state_id": "FL"
  },
  {
    "zip": 32403,
    "lat": 30.05338,
    "lng": -85.5568,
    "state_id": "FL"
  },
  {
    "zip": 32404,
    "lat": 30.20096,
    "lng": -85.50611,
    "state_id": "FL"
  },
  {
    "zip": 32405,
    "lat": 30.2004,
    "lng": -85.6679,
    "state_id": "FL"
  },
  {
    "zip": 32407,
    "lat": 30.20066,
    "lng": -85.79129,
    "state_id": "FL"
  },
  {
    "zip": 32408,
    "lat": 30.13938,
    "lng": -85.7253,
    "state_id": "FL"
  },
  {
    "zip": 32409,
    "lat": 30.3484,
    "lng": -85.6598,
    "state_id": "FL"
  },
  {
    "zip": 32410,
    "lat": 29.99426,
    "lng": -85.41822,
    "state_id": "FL"
  },
  {
    "zip": 32413,
    "lat": 30.31499,
    "lng": -85.90833,
    "state_id": "FL"
  },
  {
    "zip": 32420,
    "lat": 30.63285,
    "lng": -85.38483,
    "state_id": "FL"
  },
  {
    "zip": 32421,
    "lat": 30.52989,
    "lng": -85.18213,
    "state_id": "FL"
  },
  {
    "zip": 32423,
    "lat": 30.94593,
    "lng": -85.05854,
    "state_id": "FL"
  },
  {
    "zip": 32424,
    "lat": 30.37153,
    "lng": -85.08924,
    "state_id": "FL"
  },
  {
    "zip": 32425,
    "lat": 30.85518,
    "lng": -85.72082,
    "state_id": "FL"
  },
  {
    "zip": 32426,
    "lat": 30.94714,
    "lng": -85.36944,
    "state_id": "FL"
  },
  {
    "zip": 32427,
    "lat": 30.71225,
    "lng": -85.80153,
    "state_id": "FL"
  },
  {
    "zip": 32428,
    "lat": 30.61436,
    "lng": -85.57852,
    "state_id": "FL"
  },
  {
    "zip": 32430,
    "lat": 30.41836,
    "lng": -85.23409,
    "state_id": "FL"
  },
  {
    "zip": 32431,
    "lat": 30.78695,
    "lng": -85.41357,
    "state_id": "FL"
  },
  {
    "zip": 32432,
    "lat": 30.71493,
    "lng": -85.0776,
    "state_id": "FL"
  },
  {
    "zip": 32433,
    "lat": 30.8494,
    "lng": -86.20229,
    "state_id": "FL"
  },
  {
    "zip": 32435,
    "lat": 30.66393,
    "lng": -86.1258,
    "state_id": "FL"
  },
  {
    "zip": 32437,
    "lat": 30.43364,
    "lng": -85.90551,
    "state_id": "FL"
  },
  {
    "zip": 32438,
    "lat": 30.50527,
    "lng": -85.42898,
    "state_id": "FL"
  },
  {
    "zip": 32439,
    "lat": 30.48565,
    "lng": -86.1223,
    "state_id": "FL"
  },
  {
    "zip": 32440,
    "lat": 30.92009,
    "lng": -85.52475,
    "state_id": "FL"
  },
  {
    "zip": 32442,
    "lat": 30.68786,
    "lng": -85.01146,
    "state_id": "FL"
  },
  {
    "zip": 32443,
    "lat": 30.86671,
    "lng": -85.11533,
    "state_id": "FL"
  },
  {
    "zip": 32444,
    "lat": 30.23936,
    "lng": -85.65202,
    "state_id": "FL"
  },
  {
    "zip": 32445,
    "lat": 30.96922,
    "lng": -85.19415,
    "state_id": "FL"
  },
  {
    "zip": 32446,
    "lat": 30.84513,
    "lng": -85.22908,
    "state_id": "FL"
  },
  {
    "zip": 32447,
    "lat": 30.7599,
    "lng": -85.25156,
    "state_id": "FL"
  },
  {
    "zip": 32448,
    "lat": 30.66923,
    "lng": -85.22107,
    "state_id": "FL"
  },
  {
    "zip": 32449,
    "lat": 30.28571,
    "lng": -85.24125,
    "state_id": "FL"
  },
  {
    "zip": 32455,
    "lat": 30.64248,
    "lng": -85.96498,
    "state_id": "FL"
  },
  {
    "zip": 32456,
    "lat": 29.80713,
    "lng": -85.30265,
    "state_id": "FL"
  },
  {
    "zip": 32459,
    "lat": 30.36535,
    "lng": -86.18488,
    "state_id": "FL"
  },
  {
    "zip": 32460,
    "lat": 30.76879,
    "lng": -84.94783,
    "state_id": "FL"
  },
  {
    "zip": 32461,
    "lat": 30.2897,
    "lng": -86.0276,
    "state_id": "FL"
  },
  {
    "zip": 32462,
    "lat": 30.55565,
    "lng": -85.83113,
    "state_id": "FL"
  },
  {
    "zip": 32463,
    "lat": 30.63135,
    "lng": -85.58994,
    "state_id": "FL"
  },
  {
    "zip": 32464,
    "lat": 30.89905,
    "lng": -85.95427,
    "state_id": "FL"
  },
  {
    "zip": 32465,
    "lat": 30.00212,
    "lng": -85.17799,
    "state_id": "FL"
  },
  {
    "zip": 32466,
    "lat": 30.39084,
    "lng": -85.51915,
    "state_id": "FL"
  },
  {
    "zip": 32501,
    "lat": 30.4284,
    "lng": -87.22238,
    "state_id": "FL"
  },
  {
    "zip": 32502,
    "lat": 30.4094,
    "lng": -87.22306,
    "state_id": "FL"
  },
  {
    "zip": 32503,
    "lat": 30.46022,
    "lng": -87.21398,
    "state_id": "FL"
  },
  {
    "zip": 32504,
    "lat": 30.48459,
    "lng": -87.19099,
    "state_id": "FL"
  },
  {
    "zip": 32505,
    "lat": 30.45483,
    "lng": -87.26077,
    "state_id": "FL"
  },
  {
    "zip": 32506,
    "lat": 30.39181,
    "lng": -87.36807,
    "state_id": "FL"
  },
  {
    "zip": 32507,
    "lat": 30.33922,
    "lng": -87.38214,
    "state_id": "FL"
  },
  {
    "zip": 32508,
    "lat": 30.35478,
    "lng": -87.30278,
    "state_id": "FL"
  },
  {
    "zip": 32509,
    "lat": 30.47078,
    "lng": -87.341,
    "state_id": "FL"
  },
  {
    "zip": 32511,
    "lat": 30.40606,
    "lng": -87.29175,
    "state_id": "FL"
  },
  {
    "zip": 32514,
    "lat": 30.53185,
    "lng": -87.22196,
    "state_id": "FL"
  },
  {
    "zip": 32526,
    "lat": 30.49657,
    "lng": -87.3653,
    "state_id": "FL"
  },
  {
    "zip": 32530,
    "lat": 30.59823,
    "lng": -87.03037,
    "state_id": "FL"
  },
  {
    "zip": 32531,
    "lat": 30.87685,
    "lng": -86.69101,
    "state_id": "FL"
  },
  {
    "zip": 32533,
    "lat": 30.61058,
    "lng": -87.32678,
    "state_id": "FL"
  },
  {
    "zip": 32534,
    "lat": 30.5329,
    "lng": -87.28294,
    "state_id": "FL"
  },
  {
    "zip": 32535,
    "lat": 30.96488,
    "lng": -87.34906,
    "state_id": "FL"
  },
  {
    "zip": 32536,
    "lat": 30.76427,
    "lng": -86.59175,
    "state_id": "FL"
  },
  {
    "zip": 32539,
    "lat": 30.77478,
    "lng": -86.46306,
    "state_id": "FL"
  },
  {
    "zip": 32541,
    "lat": 30.39699,
    "lng": -86.47062,
    "state_id": "FL"
  },
  {
    "zip": 32542,
    "lat": 30.55285,
    "lng": -86.57433,
    "state_id": "FL"
  },
  {
    "zip": 32544,
    "lat": 30.42795,
    "lng": -86.69928,
    "state_id": "FL"
  },
  {
    "zip": 32547,
    "lat": 30.46866,
    "lng": -86.66717,
    "state_id": "FL"
  },
  {
    "zip": 32548,
    "lat": 30.4069,
    "lng": -86.65324,
    "state_id": "FL"
  },
  {
    "zip": 32550,
    "lat": 30.38554,
    "lng": -86.34555,
    "state_id": "FL"
  },
  {
    "zip": 32561,
    "lat": 30.35157,
    "lng": -87.11104,
    "state_id": "FL"
  },
  {
    "zip": 32563,
    "lat": 30.39623,
    "lng": -87.02732,
    "state_id": "FL"
  },
  {
    "zip": 32564,
    "lat": 30.72606,
    "lng": -86.78241,
    "state_id": "FL"
  },
  {
    "zip": 32565,
    "lat": 30.89329,
    "lng": -87.13923,
    "state_id": "FL"
  },
  {
    "zip": 32566,
    "lat": 30.43717,
    "lng": -86.88927,
    "state_id": "FL"
  },
  {
    "zip": 32567,
    "lat": 30.92503,
    "lng": -86.43982,
    "state_id": "FL"
  },
  {
    "zip": 32568,
    "lat": 30.88334,
    "lng": -87.45402,
    "state_id": "FL"
  },
  {
    "zip": 32569,
    "lat": 30.40853,
    "lng": -86.7351,
    "state_id": "FL"
  },
  {
    "zip": 32570,
    "lat": 30.80756,
    "lng": -86.97173,
    "state_id": "FL"
  },
  {
    "zip": 32571,
    "lat": 30.67981,
    "lng": -87.19822,
    "state_id": "FL"
  },
  {
    "zip": 32577,
    "lat": 30.72988,
    "lng": -87.36793,
    "state_id": "FL"
  },
  {
    "zip": 32578,
    "lat": 30.51022,
    "lng": -86.45223,
    "state_id": "FL"
  },
  {
    "zip": 32579,
    "lat": 30.44928,
    "lng": -86.57499,
    "state_id": "FL"
  },
  {
    "zip": 32580,
    "lat": 30.49583,
    "lng": -86.52386,
    "state_id": "FL"
  },
  {
    "zip": 32583,
    "lat": 30.59366,
    "lng": -86.97102,
    "state_id": "FL"
  },
  {
    "zip": 32601,
    "lat": 29.64888,
    "lng": -82.32498,
    "state_id": "FL"
  },
  {
    "zip": 32603,
    "lat": 29.64994,
    "lng": -82.354,
    "state_id": "FL"
  },
  {
    "zip": 32605,
    "lat": 29.6766,
    "lng": -82.37363,
    "state_id": "FL"
  },
  {
    "zip": 32606,
    "lat": 29.68415,
    "lng": -82.44435,
    "state_id": "FL"
  },
  {
    "zip": 32607,
    "lat": 29.64597,
    "lng": -82.42072,
    "state_id": "FL"
  },
  {
    "zip": 32608,
    "lat": 29.5969,
    "lng": -82.4066,
    "state_id": "FL"
  },
  {
    "zip": 32609,
    "lat": 29.77082,
    "lng": -82.28314,
    "state_id": "FL"
  },
  {
    "zip": 32612,
    "lat": 29.64478,
    "lng": -82.35331,
    "state_id": "FL"
  },
  {
    "zip": 32615,
    "lat": 29.81652,
    "lng": -82.49209,
    "state_id": "FL"
  },
  {
    "zip": 32616,
    "lat": 29.7886,
    "lng": -82.49522,
    "state_id": "FL"
  },
  {
    "zip": 32617,
    "lat": 29.30708,
    "lng": -82.07627,
    "state_id": "FL"
  },
  {
    "zip": 32618,
    "lat": 29.54033,
    "lng": -82.51501,
    "state_id": "FL"
  },
  {
    "zip": 32619,
    "lat": 29.76169,
    "lng": -82.86262,
    "state_id": "FL"
  },
  {
    "zip": 32621,
    "lat": 29.42527,
    "lng": -82.62331,
    "state_id": "FL"
  },
  {
    "zip": 32622,
    "lat": 29.90281,
    "lng": -82.31504,
    "state_id": "FL"
  },
  {
    "zip": 32625,
    "lat": 29.22508,
    "lng": -82.99257,
    "state_id": "FL"
  },
  {
    "zip": 32626,
    "lat": 29.43104,
    "lng": -82.89024,
    "state_id": "FL"
  },
  {
    "zip": 32628,
    "lat": 29.63725,
    "lng": -83.20312,
    "state_id": "FL"
  },
  {
    "zip": 32631,
    "lat": 29.72436,
    "lng": -82.10131,
    "state_id": "FL"
  },
  {
    "zip": 32639,
    "lat": 29.22284,
    "lng": -82.71431,
    "state_id": "FL"
  },
  {
    "zip": 32640,
    "lat": 29.57579,
    "lng": -82.08938,
    "state_id": "FL"
  },
  {
    "zip": 32641,
    "lat": 29.64359,
    "lng": -82.22681,
    "state_id": "FL"
  },
  {
    "zip": 32643,
    "lat": 29.82186,
    "lng": -82.64688,
    "state_id": "FL"
  },
  {
    "zip": 32648,
    "lat": 29.50117,
    "lng": -83.26314,
    "state_id": "FL"
  },
  {
    "zip": 32653,
    "lat": 29.74328,
    "lng": -82.39538,
    "state_id": "FL"
  },
  {
    "zip": 32656,
    "lat": 29.81498,
    "lng": -81.95605,
    "state_id": "FL"
  },
  {
    "zip": 32658,
    "lat": 29.84754,
    "lng": -82.39428,
    "state_id": "FL"
  },
  {
    "zip": 32664,
    "lat": 29.44554,
    "lng": -82.22118,
    "state_id": "FL"
  },
  {
    "zip": 32666,
    "lat": 29.72554,
    "lng": -81.99465,
    "state_id": "FL"
  },
  {
    "zip": 32667,
    "lat": 29.51215,
    "lng": -82.30536,
    "state_id": "FL"
  },
  {
    "zip": 32668,
    "lat": 29.26626,
    "lng": -82.49037,
    "state_id": "FL"
  },
  {
    "zip": 32669,
    "lat": 29.63266,
    "lng": -82.60458,
    "state_id": "FL"
  },
  {
    "zip": 32680,
    "lat": 29.62865,
    "lng": -83.06991,
    "state_id": "FL"
  },
  {
    "zip": 32681,
    "lat": 29.42331,
    "lng": -82.20551,
    "state_id": "FL"
  },
  {
    "zip": 32683,
    "lat": 29.29396,
    "lng": -82.78074,
    "state_id": "FL"
  },
  {
    "zip": 32686,
    "lat": 29.36442,
    "lng": -82.26636,
    "state_id": "FL"
  },
  {
    "zip": 32692,
    "lat": 29.33641,
    "lng": -83.12812,
    "state_id": "FL"
  },
  {
    "zip": 32693,
    "lat": 29.62387,
    "lng": -82.79225,
    "state_id": "FL"
  },
  {
    "zip": 32694,
    "lat": 29.79601,
    "lng": -82.15552,
    "state_id": "FL"
  },
  {
    "zip": 32696,
    "lat": 29.39796,
    "lng": -82.46059,
    "state_id": "FL"
  },
  {
    "zip": 32697,
    "lat": 29.93146,
    "lng": -82.42909,
    "state_id": "FL"
  },
  {
    "zip": 32701,
    "lat": 28.66534,
    "lng": -81.36943,
    "state_id": "FL"
  },
  {
    "zip": 32702,
    "lat": 29.03924,
    "lng": -81.62535,
    "state_id": "FL"
  },
  {
    "zip": 32703,
    "lat": 28.67069,
    "lng": -81.55295,
    "state_id": "FL"
  },
  {
    "zip": 32707,
    "lat": 28.66267,
    "lng": -81.31427,
    "state_id": "FL"
  },
  {
    "zip": 32708,
    "lat": 28.68736,
    "lng": -81.27274,
    "state_id": "FL"
  },
  {
    "zip": 32709,
    "lat": 28.51106,
    "lng": -80.9777,
    "state_id": "FL"
  },
  {
    "zip": 32712,
    "lat": 28.74,
    "lng": -81.5061,
    "state_id": "FL"
  },
  {
    "zip": 32713,
    "lat": 28.88538,
    "lng": -81.32522,
    "state_id": "FL"
  },
  {
    "zip": 32714,
    "lat": 28.66246,
    "lng": -81.41166,
    "state_id": "FL"
  },
  {
    "zip": 32720,
    "lat": 29.00961,
    "lng": -81.36796,
    "state_id": "FL"
  },
  {
    "zip": 32724,
    "lat": 29.05646,
    "lng": -81.22875,
    "state_id": "FL"
  },
  {
    "zip": 32725,
    "lat": 28.88334,
    "lng": -81.25082,
    "state_id": "FL"
  },
  {
    "zip": 32726,
    "lat": 28.85503,
    "lng": -81.67891,
    "state_id": "FL"
  },
  {
    "zip": 32730,
    "lat": 28.65325,
    "lng": -81.34361,
    "state_id": "FL"
  },
  {
    "zip": 32732,
    "lat": 28.74551,
    "lng": -81.10597,
    "state_id": "FL"
  },
  {
    "zip": 32735,
    "lat": 28.89158,
    "lng": -81.73765,
    "state_id": "FL"
  },
  {
    "zip": 32736,
    "lat": 28.89669,
    "lng": -81.49497,
    "state_id": "FL"
  },
  {
    "zip": 32738,
    "lat": 28.90897,
    "lng": -81.18976,
    "state_id": "FL"
  },
  {
    "zip": 32744,
    "lat": 28.98623,
    "lng": -81.21924,
    "state_id": "FL"
  },
  {
    "zip": 32746,
    "lat": 28.76398,
    "lng": -81.35457,
    "state_id": "FL"
  },
  {
    "zip": 32750,
    "lat": 28.70614,
    "lng": -81.352,
    "state_id": "FL"
  },
  {
    "zip": 32751,
    "lat": 28.63118,
    "lng": -81.36412,
    "state_id": "FL"
  },
  {
    "zip": 32754,
    "lat": 28.68916,
    "lng": -80.9262,
    "state_id": "FL"
  },
  {
    "zip": 32757,
    "lat": 28.77345,
    "lng": -81.63818,
    "state_id": "FL"
  },
  {
    "zip": 32759,
    "lat": 28.83578,
    "lng": -80.90041,
    "state_id": "FL"
  },
  {
    "zip": 32763,
    "lat": 28.9406,
    "lng": -81.29815,
    "state_id": "FL"
  },
  {
    "zip": 32764,
    "lat": 28.84704,
    "lng": -81.08391,
    "state_id": "FL"
  },
  {
    "zip": 32765,
    "lat": 28.66743,
    "lng": -81.20178,
    "state_id": "FL"
  },
  {
    "zip": 32766,
    "lat": 28.6476,
    "lng": -81.08121,
    "state_id": "FL"
  },
  {
    "zip": 32767,
    "lat": 29.02,
    "lng": -81.50654,
    "state_id": "FL"
  },
  {
    "zip": 32771,
    "lat": 28.81351,
    "lng": -81.32645,
    "state_id": "FL"
  },
  {
    "zip": 32773,
    "lat": 28.75204,
    "lng": -81.24731,
    "state_id": "FL"
  },
  {
    "zip": 32776,
    "lat": 28.81758,
    "lng": -81.49633,
    "state_id": "FL"
  },
  {
    "zip": 32778,
    "lat": 28.77669,
    "lng": -81.73001,
    "state_id": "FL"
  },
  {
    "zip": 32779,
    "lat": 28.7167,
    "lng": -81.41276,
    "state_id": "FL"
  },
  {
    "zip": 32780,
    "lat": 28.53645,
    "lng": -80.79349,
    "state_id": "FL"
  },
  {
    "zip": 32784,
    "lat": 28.97601,
    "lng": -81.72198,
    "state_id": "FL"
  },
  {
    "zip": 32789,
    "lat": 28.59965,
    "lng": -81.3521,
    "state_id": "FL"
  },
  {
    "zip": 32792,
    "lat": 28.60997,
    "lng": -81.29881,
    "state_id": "FL"
  },
  {
    "zip": 32796,
    "lat": 28.62693,
    "lng": -80.84181,
    "state_id": "FL"
  },
  {
    "zip": 32798,
    "lat": 28.72721,
    "lng": -81.58558,
    "state_id": "FL"
  },
  {
    "zip": 32801,
    "lat": 28.54176,
    "lng": -81.37358,
    "state_id": "FL"
  },
  {
    "zip": 32803,
    "lat": 28.55496,
    "lng": -81.34775,
    "state_id": "FL"
  },
  {
    "zip": 32804,
    "lat": 28.57725,
    "lng": -81.39727,
    "state_id": "FL"
  },
  {
    "zip": 32805,
    "lat": 28.52947,
    "lng": -81.4059,
    "state_id": "FL"
  },
  {
    "zip": 32806,
    "lat": 28.51227,
    "lng": -81.36056,
    "state_id": "FL"
  },
  {
    "zip": 32807,
    "lat": 28.5527,
    "lng": -81.30092,
    "state_id": "FL"
  },
  {
    "zip": 32808,
    "lat": 28.5791,
    "lng": -81.44329,
    "state_id": "FL"
  },
  {
    "zip": 32809,
    "lat": 28.46208,
    "lng": -81.38588,
    "state_id": "FL"
  },
  {
    "zip": 32810,
    "lat": 28.62144,
    "lng": -81.4294,
    "state_id": "FL"
  },
  {
    "zip": 32811,
    "lat": 28.51684,
    "lng": -81.44464,
    "state_id": "FL"
  },
  {
    "zip": 32812,
    "lat": 28.48502,
    "lng": -81.32851,
    "state_id": "FL"
  },
  {
    "zip": 32814,
    "lat": 28.57015,
    "lng": -81.32648,
    "state_id": "FL"
  },
  {
    "zip": 32817,
    "lat": 28.59039,
    "lng": -81.2446,
    "state_id": "FL"
  },
  {
    "zip": 32818,
    "lat": 28.5865,
    "lng": -81.48768,
    "state_id": "FL"
  },
  {
    "zip": 32819,
    "lat": 28.4559,
    "lng": -81.47105,
    "state_id": "FL"
  },
  {
    "zip": 32820,
    "lat": 28.58498,
    "lng": -81.12137,
    "state_id": "FL"
  },
  {
    "zip": 32821,
    "lat": 28.38715,
    "lng": -81.47521,
    "state_id": "FL"
  },
  {
    "zip": 32822,
    "lat": 28.48984,
    "lng": -81.29046,
    "state_id": "FL"
  },
  {
    "zip": 32824,
    "lat": 28.38841,
    "lng": -81.34888,
    "state_id": "FL"
  },
  {
    "zip": 32825,
    "lat": 28.51539,
    "lng": -81.22804,
    "state_id": "FL"
  },
  {
    "zip": 32826,
    "lat": 28.58825,
    "lng": -81.18624,
    "state_id": "FL"
  },
  {
    "zip": 32827,
    "lat": 28.41572,
    "lng": -81.29413,
    "state_id": "FL"
  },
  {
    "zip": 32828,
    "lat": 28.52824,
    "lng": -81.16718,
    "state_id": "FL"
  },
  {
    "zip": 32829,
    "lat": 28.48021,
    "lng": -81.24605,
    "state_id": "FL"
  },
  {
    "zip": 32830,
    "lat": 28.38225,
    "lng": -81.56911,
    "state_id": "FL"
  },
  {
    "zip": 32831,
    "lat": 28.47305,
    "lng": -81.14825,
    "state_id": "FL"
  },
  {
    "zip": 32832,
    "lat": 28.39793,
    "lng": -81.18697,
    "state_id": "FL"
  },
  {
    "zip": 32833,
    "lat": 28.49469,
    "lng": -81.08006,
    "state_id": "FL"
  },
  {
    "zip": 32835,
    "lat": 28.52106,
    "lng": -81.48426,
    "state_id": "FL"
  },
  {
    "zip": 32836,
    "lat": 28.41535,
    "lng": -81.5206,
    "state_id": "FL"
  },
  {
    "zip": 32837,
    "lat": 28.37847,
    "lng": -81.42933,
    "state_id": "FL"
  },
  {
    "zip": 32839,
    "lat": 28.48834,
    "lng": -81.4071,
    "state_id": "FL"
  },
  {
    "zip": 32901,
    "lat": 28.07903,
    "lng": -80.62322,
    "state_id": "FL"
  },
  {
    "zip": 32903,
    "lat": 28.10847,
    "lng": -80.58719,
    "state_id": "FL"
  },
  {
    "zip": 32904,
    "lat": 28.06727,
    "lng": -80.67796,
    "state_id": "FL"
  },
  {
    "zip": 32905,
    "lat": 28.03126,
    "lng": -80.59947,
    "state_id": "FL"
  },
  {
    "zip": 32907,
    "lat": 28.01564,
    "lng": -80.68231,
    "state_id": "FL"
  },
  {
    "zip": 32908,
    "lat": 27.95639,
    "lng": -80.69892,
    "state_id": "FL"
  },
  {
    "zip": 32909,
    "lat": 27.92054,
    "lng": -80.64447,
    "state_id": "FL"
  },
  {
    "zip": 32920,
    "lat": 28.39497,
    "lng": -80.62083,
    "state_id": "FL"
  },
  {
    "zip": 32922,
    "lat": 28.37243,
    "lng": -80.74281,
    "state_id": "FL"
  },
  {
    "zip": 32925,
    "lat": 28.24273,
    "lng": -80.62288,
    "state_id": "FL"
  },
  {
    "zip": 32926,
    "lat": 28.39227,
    "lng": -80.81718,
    "state_id": "FL"
  },
  {
    "zip": 32927,
    "lat": 28.46083,
    "lng": -80.80763,
    "state_id": "FL"
  },
  {
    "zip": 32931,
    "lat": 28.32287,
    "lng": -80.62727,
    "state_id": "FL"
  },
  {
    "zip": 32934,
    "lat": 28.13316,
    "lng": -80.71115,
    "state_id": "FL"
  },
  {
    "zip": 32935,
    "lat": 28.14562,
    "lng": -80.64846,
    "state_id": "FL"
  },
  {
    "zip": 32937,
    "lat": 28.17832,
    "lng": -80.60427,
    "state_id": "FL"
  },
  {
    "zip": 32940,
    "lat": 28.21129,
    "lng": -80.78752,
    "state_id": "FL"
  },
  {
    "zip": 32948,
    "lat": 27.88946,
    "lng": -80.73348,
    "state_id": "FL"
  },
  {
    "zip": 32949,
    "lat": 27.92333,
    "lng": -80.55068,
    "state_id": "FL"
  },
  {
    "zip": 32950,
    "lat": 27.97598,
    "lng": -80.57879,
    "state_id": "FL"
  },
  {
    "zip": 32951,
    "lat": 27.95946,
    "lng": -80.51499,
    "state_id": "FL"
  },
  {
    "zip": 32952,
    "lat": 28.30017,
    "lng": -80.66339,
    "state_id": "FL"
  },
  {
    "zip": 32953,
    "lat": 28.44828,
    "lng": -80.70512,
    "state_id": "FL"
  },
  {
    "zip": 32955,
    "lat": 28.29642,
    "lng": -80.72525,
    "state_id": "FL"
  },
  {
    "zip": 32958,
    "lat": 27.79374,
    "lng": -80.49117,
    "state_id": "FL"
  },
  {
    "zip": 32960,
    "lat": 27.64121,
    "lng": -80.40289,
    "state_id": "FL"
  },
  {
    "zip": 32962,
    "lat": 27.58695,
    "lng": -80.38372,
    "state_id": "FL"
  },
  {
    "zip": 32963,
    "lat": 27.71958,
    "lng": -80.39621,
    "state_id": "FL"
  },
  {
    "zip": 32966,
    "lat": 27.67799,
    "lng": -80.69047,
    "state_id": "FL"
  },
  {
    "zip": 32967,
    "lat": 27.71218,
    "lng": -80.46116,
    "state_id": "FL"
  },
  {
    "zip": 32968,
    "lat": 27.58701,
    "lng": -80.46543,
    "state_id": "FL"
  },
  {
    "zip": 32970,
    "lat": 27.75138,
    "lng": -80.45311,
    "state_id": "FL"
  },
  {
    "zip": 32976,
    "lat": 27.8638,
    "lng": -80.53949,
    "state_id": "FL"
  },
  {
    "zip": 33001,
    "lat": 24.82041,
    "lng": -80.80992,
    "state_id": "FL"
  },
  {
    "zip": 33004,
    "lat": 26.0579,
    "lng": -80.13852,
    "state_id": "FL"
  },
  {
    "zip": 33009,
    "lat": 25.98566,
    "lng": -80.14786,
    "state_id": "FL"
  },
  {
    "zip": 33010,
    "lat": 25.833,
    "lng": -80.27868,
    "state_id": "FL"
  },
  {
    "zip": 33012,
    "lat": 25.86566,
    "lng": -80.3025,
    "state_id": "FL"
  },
  {
    "zip": 33013,
    "lat": 25.8623,
    "lng": -80.27029,
    "state_id": "FL"
  },
  {
    "zip": 33014,
    "lat": 25.90433,
    "lng": -80.30293,
    "state_id": "FL"
  },
  {
    "zip": 33015,
    "lat": 25.9413,
    "lng": -80.31761,
    "state_id": "FL"
  },
  {
    "zip": 33016,
    "lat": 25.8949,
    "lng": -80.33243,
    "state_id": "FL"
  },
  {
    "zip": 33018,
    "lat": 25.92593,
    "lng": -80.37946,
    "state_id": "FL"
  },
  {
    "zip": 33019,
    "lat": 26.02617,
    "lng": -80.12293,
    "state_id": "FL"
  },
  {
    "zip": 33020,
    "lat": 26.01899,
    "lng": -80.1523,
    "state_id": "FL"
  },
  {
    "zip": 33021,
    "lat": 26.02334,
    "lng": -80.18758,
    "state_id": "FL"
  },
  {
    "zip": 33023,
    "lat": 25.9894,
    "lng": -80.21534,
    "state_id": "FL"
  },
  {
    "zip": 33024,
    "lat": 26.02697,
    "lng": -80.24528,
    "state_id": "FL"
  },
  {
    "zip": 33025,
    "lat": 25.98743,
    "lng": -80.28139,
    "state_id": "FL"
  },
  {
    "zip": 33026,
    "lat": 26.02594,
    "lng": -80.29643,
    "state_id": "FL"
  },
  {
    "zip": 33027,
    "lat": 25.98245,
    "lng": -80.34361,
    "state_id": "FL"
  },
  {
    "zip": 33028,
    "lat": 26.01853,
    "lng": -80.34488,
    "state_id": "FL"
  },
  {
    "zip": 33029,
    "lat": 25.99237,
    "lng": -80.4089,
    "state_id": "FL"
  },
  {
    "zip": 33030,
    "lat": 25.48472,
    "lng": -80.51001,
    "state_id": "FL"
  },
  {
    "zip": 33031,
    "lat": 25.52754,
    "lng": -80.50111,
    "state_id": "FL"
  },
  {
    "zip": 33032,
    "lat": 25.53021,
    "lng": -80.39188,
    "state_id": "FL"
  },
  {
    "zip": 33033,
    "lat": 25.48371,
    "lng": -80.41365,
    "state_id": "FL"
  },
  {
    "zip": 33034,
    "lat": 25.28499,
    "lng": -80.62418,
    "state_id": "FL"
  },
  {
    "zip": 33035,
    "lat": 25.41746,
    "lng": -80.39864,
    "state_id": "FL"
  },
  {
    "zip": 33036,
    "lat": 24.92303,
    "lng": -80.63795,
    "state_id": "FL"
  },
  {
    "zip": 33037,
    "lat": 25.20121,
    "lng": -80.38374,
    "state_id": "FL"
  },
  {
    "zip": 33039,
    "lat": 25.5021,
    "lng": -80.39968,
    "state_id": "FL"
  },
  {
    "zip": 33040,
    "lat": 24.58547,
    "lng": -81.75451,
    "state_id": "FL"
  },
  {
    "zip": 33042,
    "lat": 24.67645,
    "lng": -81.50992,
    "state_id": "FL"
  },
  {
    "zip": 33043,
    "lat": 24.70334,
    "lng": -81.36636,
    "state_id": "FL"
  },
  {
    "zip": 33050,
    "lat": 24.72843,
    "lng": -81.03404,
    "state_id": "FL"
  },
  {
    "zip": 33051,
    "lat": 24.72317,
    "lng": -81.02153,
    "state_id": "FL"
  },
  {
    "zip": 33054,
    "lat": 25.9069,
    "lng": -80.25826,
    "state_id": "FL"
  },
  {
    "zip": 33055,
    "lat": 25.94821,
    "lng": -80.27802,
    "state_id": "FL"
  },
  {
    "zip": 33056,
    "lat": 25.94921,
    "lng": -80.24562,
    "state_id": "FL"
  },
  {
    "zip": 33060,
    "lat": 26.2348,
    "lng": -80.12054,
    "state_id": "FL"
  },
  {
    "zip": 33062,
    "lat": 26.24163,
    "lng": -80.09364,
    "state_id": "FL"
  },
  {
    "zip": 33063,
    "lat": 26.25012,
    "lng": -80.20883,
    "state_id": "FL"
  },
  {
    "zip": 33064,
    "lat": 26.27849,
    "lng": -80.11567,
    "state_id": "FL"
  },
  {
    "zip": 33065,
    "lat": 26.27284,
    "lng": -80.26039,
    "state_id": "FL"
  },
  {
    "zip": 33066,
    "lat": 26.2535,
    "lng": -80.17747,
    "state_id": "FL"
  },
  {
    "zip": 33067,
    "lat": 26.30774,
    "lng": -80.22611,
    "state_id": "FL"
  },
  {
    "zip": 33068,
    "lat": 26.21571,
    "lng": -80.21776,
    "state_id": "FL"
  },
  {
    "zip": 33069,
    "lat": 26.23566,
    "lng": -80.15791,
    "state_id": "FL"
  },
  {
    "zip": 33070,
    "lat": 25.01004,
    "lng": -80.5239,
    "state_id": "FL"
  },
  {
    "zip": 33071,
    "lat": 26.24384,
    "lng": -80.2662,
    "state_id": "FL"
  },
  {
    "zip": 33073,
    "lat": 26.2985,
    "lng": -80.1828,
    "state_id": "FL"
  },
  {
    "zip": 33076,
    "lat": 26.31682,
    "lng": -80.27528,
    "state_id": "FL"
  },
  {
    "zip": 33101,
    "lat": 25.7793,
    "lng": -80.19874,
    "state_id": "FL"
  },
  {
    "zip": 33109,
    "lat": 25.76135,
    "lng": -80.14247,
    "state_id": "FL"
  },
  {
    "zip": 33122,
    "lat": 25.79738,
    "lng": -80.29848,
    "state_id": "FL"
  },
  {
    "zip": 33125,
    "lat": 25.78384,
    "lng": -80.23755,
    "state_id": "FL"
  },
  {
    "zip": 33126,
    "lat": 25.77982,
    "lng": -80.29886,
    "state_id": "FL"
  },
  {
    "zip": 33127,
    "lat": 25.81325,
    "lng": -80.20558,
    "state_id": "FL"
  },
  {
    "zip": 33128,
    "lat": 25.77652,
    "lng": -80.20401,
    "state_id": "FL"
  },
  {
    "zip": 33129,
    "lat": 25.75283,
    "lng": -80.20028,
    "state_id": "FL"
  },
  {
    "zip": 33130,
    "lat": 25.7682,
    "lng": -80.20323,
    "state_id": "FL"
  },
  {
    "zip": 33131,
    "lat": 25.76325,
    "lng": -80.18464,
    "state_id": "FL"
  },
  {
    "zip": 33132,
    "lat": 25.77788,
    "lng": -80.17413,
    "state_id": "FL"
  },
  {
    "zip": 33133,
    "lat": 25.7298,
    "lng": -80.24319,
    "state_id": "FL"
  },
  {
    "zip": 33134,
    "lat": 25.75345,
    "lng": -80.27108,
    "state_id": "FL"
  },
  {
    "zip": 33135,
    "lat": 25.76645,
    "lng": -80.23499,
    "state_id": "FL"
  },
  {
    "zip": 33136,
    "lat": 25.78686,
    "lng": -80.20471,
    "state_id": "FL"
  },
  {
    "zip": 33137,
    "lat": 25.81529,
    "lng": -80.178,
    "state_id": "FL"
  },
  {
    "zip": 33138,
    "lat": 25.85396,
    "lng": -80.17842,
    "state_id": "FL"
  },
  {
    "zip": 33139,
    "lat": 25.78521,
    "lng": -80.14919,
    "state_id": "FL"
  },
  {
    "zip": 33140,
    "lat": 25.81599,
    "lng": -80.13902,
    "state_id": "FL"
  },
  {
    "zip": 33141,
    "lat": 25.85114,
    "lng": -80.14141,
    "state_id": "FL"
  },
  {
    "zip": 33142,
    "lat": 25.81194,
    "lng": -80.23858,
    "state_id": "FL"
  },
  {
    "zip": 33143,
    "lat": 25.70222,
    "lng": -80.29779,
    "state_id": "FL"
  },
  {
    "zip": 33144,
    "lat": 25.76321,
    "lng": -80.31232,
    "state_id": "FL"
  },
  {
    "zip": 33145,
    "lat": 25.75309,
    "lng": -80.23453,
    "state_id": "FL"
  },
  {
    "zip": 33146,
    "lat": 25.72052,
    "lng": -80.27284,
    "state_id": "FL"
  },
  {
    "zip": 33147,
    "lat": 25.85142,
    "lng": -80.23821,
    "state_id": "FL"
  },
  {
    "zip": 33149,
    "lat": 25.72479,
    "lng": -80.16118,
    "state_id": "FL"
  },
  {
    "zip": 33150,
    "lat": 25.85204,
    "lng": -80.20711,
    "state_id": "FL"
  },
  {
    "zip": 33154,
    "lat": 25.88276,
    "lng": -80.13443,
    "state_id": "FL"
  },
  {
    "zip": 33155,
    "lat": 25.73667,
    "lng": -80.31098,
    "state_id": "FL"
  },
  {
    "zip": 33156,
    "lat": 25.66828,
    "lng": -80.29732,
    "state_id": "FL"
  },
  {
    "zip": 33157,
    "lat": 25.60618,
    "lng": -80.34258,
    "state_id": "FL"
  },
  {
    "zip": 33158,
    "lat": 25.6373,
    "lng": -80.30935,
    "state_id": "FL"
  },
  {
    "zip": 33160,
    "lat": 25.93278,
    "lng": -80.13475,
    "state_id": "FL"
  },
  {
    "zip": 33161,
    "lat": 25.89376,
    "lng": -80.18252,
    "state_id": "FL"
  },
  {
    "zip": 33162,
    "lat": 25.92831,
    "lng": -80.17798,
    "state_id": "FL"
  },
  {
    "zip": 33165,
    "lat": 25.73428,
    "lng": -80.35885,
    "state_id": "FL"
  },
  {
    "zip": 33166,
    "lat": 25.82867,
    "lng": -80.31663,
    "state_id": "FL"
  },
  {
    "zip": 33167,
    "lat": 25.88524,
    "lng": -80.23694,
    "state_id": "FL"
  },
  {
    "zip": 33168,
    "lat": 25.89293,
    "lng": -80.20929,
    "state_id": "FL"
  },
  {
    "zip": 33169,
    "lat": 25.943,
    "lng": -80.21456,
    "state_id": "FL"
  },
  {
    "zip": 33170,
    "lat": 25.55766,
    "lng": -80.46141,
    "state_id": "FL"
  },
  {
    "zip": 33172,
    "lat": 25.78631,
    "lng": -80.36049,
    "state_id": "FL"
  },
  {
    "zip": 33173,
    "lat": 25.7021,
    "lng": -80.35712,
    "state_id": "FL"
  },
  {
    "zip": 33174,
    "lat": 25.76155,
    "lng": -80.36115,
    "state_id": "FL"
  },
  {
    "zip": 33175,
    "lat": 25.734,
    "lng": -80.40678,
    "state_id": "FL"
  },
  {
    "zip": 33176,
    "lat": 25.65861,
    "lng": -80.35895,
    "state_id": "FL"
  },
  {
    "zip": 33177,
    "lat": 25.59679,
    "lng": -80.40462,
    "state_id": "FL"
  },
  {
    "zip": 33178,
    "lat": 25.85807,
    "lng": -80.41949,
    "state_id": "FL"
  },
  {
    "zip": 33179,
    "lat": 25.95805,
    "lng": -80.17992,
    "state_id": "FL"
  },
  {
    "zip": 33180,
    "lat": 25.96067,
    "lng": -80.14197,
    "state_id": "FL"
  },
  {
    "zip": 33181,
    "lat": 25.89873,
    "lng": -80.1509,
    "state_id": "FL"
  },
  {
    "zip": 33182,
    "lat": 25.78369,
    "lng": -80.43398,
    "state_id": "FL"
  },
  {
    "zip": 33183,
    "lat": 25.70026,
    "lng": -80.40436,
    "state_id": "FL"
  },
  {
    "zip": 33184,
    "lat": 25.75947,
    "lng": -80.40658,
    "state_id": "FL"
  },
  {
    "zip": 33185,
    "lat": 25.72724,
    "lng": -80.44986,
    "state_id": "FL"
  },
  {
    "zip": 33186,
    "lat": 25.65548,
    "lng": -80.41048,
    "state_id": "FL"
  },
  {
    "zip": 33187,
    "lat": 25.59602,
    "lng": -80.50709,
    "state_id": "FL"
  },
  {
    "zip": 33189,
    "lat": 25.57305,
    "lng": -80.33734,
    "state_id": "FL"
  },
  {
    "zip": 33190,
    "lat": 25.55922,
    "lng": -80.34832,
    "state_id": "FL"
  },
  {
    "zip": 33193,
    "lat": 25.70175,
    "lng": -80.46679,
    "state_id": "FL"
  },
  {
    "zip": 33194,
    "lat": 25.72011,
    "lng": -80.61258,
    "state_id": "FL"
  },
  {
    "zip": 33196,
    "lat": 25.65144,
    "lng": -80.48644,
    "state_id": "FL"
  },
  {
    "zip": 33301,
    "lat": 26.1212,
    "lng": -80.1276,
    "state_id": "FL"
  },
  {
    "zip": 33304,
    "lat": 26.13863,
    "lng": -80.12161,
    "state_id": "FL"
  },
  {
    "zip": 33305,
    "lat": 26.1543,
    "lng": -80.12341,
    "state_id": "FL"
  },
  {
    "zip": 33306,
    "lat": 26.16559,
    "lng": -80.11386,
    "state_id": "FL"
  },
  {
    "zip": 33308,
    "lat": 26.18975,
    "lng": -80.10844,
    "state_id": "FL"
  },
  {
    "zip": 33309,
    "lat": 26.18799,
    "lng": -80.17327,
    "state_id": "FL"
  },
  {
    "zip": 33311,
    "lat": 26.14412,
    "lng": -80.17331,
    "state_id": "FL"
  },
  {
    "zip": 33312,
    "lat": 26.08831,
    "lng": -80.18164,
    "state_id": "FL"
  },
  {
    "zip": 33313,
    "lat": 26.15043,
    "lng": -80.22638,
    "state_id": "FL"
  },
  {
    "zip": 33314,
    "lat": 26.06765,
    "lng": -80.22307,
    "state_id": "FL"
  },
  {
    "zip": 33315,
    "lat": 26.08705,
    "lng": -80.15296,
    "state_id": "FL"
  },
  {
    "zip": 33316,
    "lat": 26.09503,
    "lng": -80.1241,
    "state_id": "FL"
  },
  {
    "zip": 33317,
    "lat": 26.11218,
    "lng": -80.22641,
    "state_id": "FL"
  },
  {
    "zip": 33319,
    "lat": 26.18274,
    "lng": -80.22571,
    "state_id": "FL"
  },
  {
    "zip": 33321,
    "lat": 26.21204,
    "lng": -80.2696,
    "state_id": "FL"
  },
  {
    "zip": 33322,
    "lat": 26.15021,
    "lng": -80.27454,
    "state_id": "FL"
  },
  {
    "zip": 33323,
    "lat": 26.15205,
    "lng": -80.31651,
    "state_id": "FL"
  },
  {
    "zip": 33324,
    "lat": 26.11236,
    "lng": -80.27416,
    "state_id": "FL"
  },
  {
    "zip": 33325,
    "lat": 26.10976,
    "lng": -80.32155,
    "state_id": "FL"
  },
  {
    "zip": 33326,
    "lat": 26.11591,
    "lng": -80.36819,
    "state_id": "FL"
  },
  {
    "zip": 33327,
    "lat": 26.11174,
    "lng": -80.4247,
    "state_id": "FL"
  },
  {
    "zip": 33328,
    "lat": 26.06714,
    "lng": -80.27223,
    "state_id": "FL"
  },
  {
    "zip": 33330,
    "lat": 26.05907,
    "lng": -80.32174,
    "state_id": "FL"
  },
  {
    "zip": 33331,
    "lat": 26.05977,
    "lng": -80.36798,
    "state_id": "FL"
  },
  {
    "zip": 33332,
    "lat": 26.03033,
    "lng": -80.44516,
    "state_id": "FL"
  },
  {
    "zip": 33334,
    "lat": 26.18312,
    "lng": -80.13442,
    "state_id": "FL"
  },
  {
    "zip": 33351,
    "lat": 26.1793,
    "lng": -80.27462,
    "state_id": "FL"
  },
  {
    "zip": 33401,
    "lat": 26.71645,
    "lng": -80.06779,
    "state_id": "FL"
  },
  {
    "zip": 33403,
    "lat": 26.80344,
    "lng": -80.0754,
    "state_id": "FL"
  },
  {
    "zip": 33404,
    "lat": 26.78246,
    "lng": -80.06623,
    "state_id": "FL"
  },
  {
    "zip": 33405,
    "lat": 26.66772,
    "lng": -80.05821,
    "state_id": "FL"
  },
  {
    "zip": 33406,
    "lat": 26.66492,
    "lng": -80.09072,
    "state_id": "FL"
  },
  {
    "zip": 33407,
    "lat": 26.75779,
    "lng": -80.09074,
    "state_id": "FL"
  },
  {
    "zip": 33408,
    "lat": 26.84116,
    "lng": -80.05673,
    "state_id": "FL"
  },
  {
    "zip": 33409,
    "lat": 26.71615,
    "lng": -80.09643,
    "state_id": "FL"
  },
  {
    "zip": 33410,
    "lat": 26.84657,
    "lng": -80.08804,
    "state_id": "FL"
  },
  {
    "zip": 33411,
    "lat": 26.71983,
    "lng": -80.19981,
    "state_id": "FL"
  },
  {
    "zip": 33412,
    "lat": 26.80684,
    "lng": -80.21262,
    "state_id": "FL"
  },
  {
    "zip": 33413,
    "lat": 26.66396,
    "lng": -80.15536,
    "state_id": "FL"
  },
  {
    "zip": 33414,
    "lat": 26.64843,
    "lng": -80.25008,
    "state_id": "FL"
  },
  {
    "zip": 33415,
    "lat": 26.66035,
    "lng": -80.12741,
    "state_id": "FL"
  },
  {
    "zip": 33417,
    "lat": 26.71997,
    "lng": -80.12486,
    "state_id": "FL"
  },
  {
    "zip": 33418,
    "lat": 26.86073,
    "lng": -80.16693,
    "state_id": "FL"
  },
  {
    "zip": 33426,
    "lat": 26.53398,
    "lng": -80.08297,
    "state_id": "FL"
  },
  {
    "zip": 33428,
    "lat": 26.34932,
    "lng": -80.21542,
    "state_id": "FL"
  },
  {
    "zip": 33430,
    "lat": 26.63955,
    "lng": -80.55041,
    "state_id": "FL"
  },
  {
    "zip": 33431,
    "lat": 26.38101,
    "lng": -80.10349,
    "state_id": "FL"
  },
  {
    "zip": 33432,
    "lat": 26.34574,
    "lng": -80.08375,
    "state_id": "FL"
  },
  {
    "zip": 33433,
    "lat": 26.34713,
    "lng": -80.15923,
    "state_id": "FL"
  },
  {
    "zip": 33434,
    "lat": 26.38156,
    "lng": -80.1687,
    "state_id": "FL"
  },
  {
    "zip": 33435,
    "lat": 26.52511,
    "lng": -80.06281,
    "state_id": "FL"
  },
  {
    "zip": 33436,
    "lat": 26.52439,
    "lng": -80.1075,
    "state_id": "FL"
  },
  {
    "zip": 33437,
    "lat": 26.51176,
    "lng": -80.14902,
    "state_id": "FL"
  },
  {
    "zip": 33438,
    "lat": 26.9318,
    "lng": -80.59593,
    "state_id": "FL"
  },
  {
    "zip": 33440,
    "lat": 26.54379,
    "lng": -81.03645,
    "state_id": "FL"
  },
  {
    "zip": 33441,
    "lat": 26.31098,
    "lng": -80.09878,
    "state_id": "FL"
  },
  {
    "zip": 33442,
    "lat": 26.31032,
    "lng": -80.14549,
    "state_id": "FL"
  },
  {
    "zip": 33444,
    "lat": 26.45817,
    "lng": -80.07997,
    "state_id": "FL"
  },
  {
    "zip": 33445,
    "lat": 26.45469,
    "lng": -80.10623,
    "state_id": "FL"
  },
  {
    "zip": 33446,
    "lat": 26.45044,
    "lng": -80.18621,
    "state_id": "FL"
  },
  {
    "zip": 33449,
    "lat": 26.58992,
    "lng": -80.23268,
    "state_id": "FL"
  },
  {
    "zip": 33455,
    "lat": 27.05565,
    "lng": -80.16475,
    "state_id": "FL"
  },
  {
    "zip": 33458,
    "lat": 26.93751,
    "lng": -80.13189,
    "state_id": "FL"
  },
  {
    "zip": 33460,
    "lat": 26.61985,
    "lng": -80.05652,
    "state_id": "FL"
  },
  {
    "zip": 33461,
    "lat": 26.62016,
    "lng": -80.09097,
    "state_id": "FL"
  },
  {
    "zip": 33462,
    "lat": 26.58054,
    "lng": -80.07352,
    "state_id": "FL"
  },
  {
    "zip": 33463,
    "lat": 26.59529,
    "lng": -80.13004,
    "state_id": "FL"
  },
  {
    "zip": 33467,
    "lat": 26.5946,
    "lng": -80.17553,
    "state_id": "FL"
  },
  {
    "zip": 33469,
    "lat": 26.98421,
    "lng": -80.10921,
    "state_id": "FL"
  },
  {
    "zip": 33470,
    "lat": 26.72708,
    "lng": -80.32037,
    "state_id": "FL"
  },
  {
    "zip": 33471,
    "lat": 26.88424,
    "lng": -81.19281,
    "state_id": "FL"
  },
  {
    "zip": 33472,
    "lat": 26.53828,
    "lng": -80.18581,
    "state_id": "FL"
  },
  {
    "zip": 33473,
    "lat": 26.50375,
    "lng": -80.19157,
    "state_id": "FL"
  },
  {
    "zip": 33476,
    "lat": 26.80467,
    "lng": -80.62356,
    "state_id": "FL"
  },
  {
    "zip": 33477,
    "lat": 26.91391,
    "lng": -80.08001,
    "state_id": "FL"
  },
  {
    "zip": 33478,
    "lat": 26.94045,
    "lng": -80.24526,
    "state_id": "FL"
  },
  {
    "zip": 33480,
    "lat": 26.69027,
    "lng": -80.04053,
    "state_id": "FL"
  },
  {
    "zip": 33483,
    "lat": 26.46118,
    "lng": -80.06378,
    "state_id": "FL"
  },
  {
    "zip": 33484,
    "lat": 26.45428,
    "lng": -80.13445,
    "state_id": "FL"
  },
  {
    "zip": 33486,
    "lat": 26.34706,
    "lng": -80.11429,
    "state_id": "FL"
  },
  {
    "zip": 33487,
    "lat": 26.41008,
    "lng": -80.09148,
    "state_id": "FL"
  },
  {
    "zip": 33493,
    "lat": 26.54005,
    "lng": -80.7293,
    "state_id": "FL"
  },
  {
    "zip": 33496,
    "lat": 26.40803,
    "lng": -80.1604,
    "state_id": "FL"
  },
  {
    "zip": 33498,
    "lat": 26.38587,
    "lng": -80.21993,
    "state_id": "FL"
  },
  {
    "zip": 33503,
    "lat": 27.76516,
    "lng": -82.27648,
    "state_id": "FL"
  },
  {
    "zip": 33510,
    "lat": 27.95592,
    "lng": -82.30014,
    "state_id": "FL"
  },
  {
    "zip": 33511,
    "lat": 27.90985,
    "lng": -82.2946,
    "state_id": "FL"
  },
  {
    "zip": 33513,
    "lat": 28.67022,
    "lng": -82.16579,
    "state_id": "FL"
  },
  {
    "zip": 33514,
    "lat": 28.67731,
    "lng": -81.99648,
    "state_id": "FL"
  },
  {
    "zip": 33521,
    "lat": 28.7983,
    "lng": -82.06841,
    "state_id": "FL"
  },
  {
    "zip": 33523,
    "lat": 28.4247,
    "lng": -82.2184,
    "state_id": "FL"
  },
  {
    "zip": 33525,
    "lat": 28.33598,
    "lng": -82.20147,
    "state_id": "FL"
  },
  {
    "zip": 33527,
    "lat": 27.97556,
    "lng": -82.21256,
    "state_id": "FL"
  },
  {
    "zip": 33534,
    "lat": 27.82342,
    "lng": -82.37676,
    "state_id": "FL"
  },
  {
    "zip": 33538,
    "lat": 28.84397,
    "lng": -82.17523,
    "state_id": "FL"
  },
  {
    "zip": 33540,
    "lat": 28.21509,
    "lng": -82.15056,
    "state_id": "FL"
  },
  {
    "zip": 33541,
    "lat": 28.23204,
    "lng": -82.2207,
    "state_id": "FL"
  },
  {
    "zip": 33542,
    "lat": 28.23546,
    "lng": -82.1771,
    "state_id": "FL"
  },
  {
    "zip": 33543,
    "lat": 28.20072,
    "lng": -82.29637,
    "state_id": "FL"
  },
  {
    "zip": 33544,
    "lat": 28.24404,
    "lng": -82.36602,
    "state_id": "FL"
  },
  {
    "zip": 33545,
    "lat": 28.26967,
    "lng": -82.29027,
    "state_id": "FL"
  },
  {
    "zip": 33547,
    "lat": 27.7763,
    "lng": -82.13381,
    "state_id": "FL"
  },
  {
    "zip": 33548,
    "lat": 28.13854,
    "lng": -82.48206,
    "state_id": "FL"
  },
  {
    "zip": 33549,
    "lat": 28.14056,
    "lng": -82.44643,
    "state_id": "FL"
  },
  {
    "zip": 33556,
    "lat": 28.14135,
    "lng": -82.59411,
    "state_id": "FL"
  },
  {
    "zip": 33558,
    "lat": 28.15879,
    "lng": -82.51562,
    "state_id": "FL"
  },
  {
    "zip": 33559,
    "lat": 28.15525,
    "lng": -82.41117,
    "state_id": "FL"
  },
  {
    "zip": 33563,
    "lat": 28.01708,
    "lng": -82.12457,
    "state_id": "FL"
  },
  {
    "zip": 33565,
    "lat": 28.09511,
    "lng": -82.15236,
    "state_id": "FL"
  },
  {
    "zip": 33566,
    "lat": 27.99275,
    "lng": -82.11639,
    "state_id": "FL"
  },
  {
    "zip": 33567,
    "lat": 27.92202,
    "lng": -82.12162,
    "state_id": "FL"
  },
  {
    "zip": 33569,
    "lat": 27.84514,
    "lng": -82.28718,
    "state_id": "FL"
  },
  {
    "zip": 33570,
    "lat": 27.68991,
    "lng": -82.45855,
    "state_id": "FL"
  },
  {
    "zip": 33572,
    "lat": 27.7629,
    "lng": -82.4105,
    "state_id": "FL"
  },
  {
    "zip": 33573,
    "lat": 27.72364,
    "lng": -82.35938,
    "state_id": "FL"
  },
  {
    "zip": 33576,
    "lat": 28.32589,
    "lng": -82.32911,
    "state_id": "FL"
  },
  {
    "zip": 33578,
    "lat": 27.86329,
    "lng": -82.34943,
    "state_id": "FL"
  },
  {
    "zip": 33579,
    "lat": 27.79654,
    "lng": -82.28746,
    "state_id": "FL"
  },
  {
    "zip": 33584,
    "lat": 28.00862,
    "lng": -82.2879,
    "state_id": "FL"
  },
  {
    "zip": 33585,
    "lat": 28.74634,
    "lng": -82.0673,
    "state_id": "FL"
  },
  {
    "zip": 33592,
    "lat": 28.10067,
    "lng": -82.28808,
    "state_id": "FL"
  },
  {
    "zip": 33594,
    "lat": 27.94073,
    "lng": -82.24195,
    "state_id": "FL"
  },
  {
    "zip": 33596,
    "lat": 27.88809,
    "lng": -82.23612,
    "state_id": "FL"
  },
  {
    "zip": 33597,
    "lat": 28.54893,
    "lng": -82.08059,
    "state_id": "FL"
  },
  {
    "zip": 33598,
    "lat": 27.68655,
    "lng": -82.28165,
    "state_id": "FL"
  },
  {
    "zip": 33602,
    "lat": 27.9536,
    "lng": -82.45686,
    "state_id": "FL"
  },
  {
    "zip": 33603,
    "lat": 27.98544,
    "lng": -82.46462,
    "state_id": "FL"
  },
  {
    "zip": 33604,
    "lat": 28.01699,
    "lng": -82.45526,
    "state_id": "FL"
  },
  {
    "zip": 33605,
    "lat": 27.95119,
    "lng": -82.42948,
    "state_id": "FL"
  },
  {
    "zip": 33606,
    "lat": 27.93218,
    "lng": -82.46488,
    "state_id": "FL"
  },
  {
    "zip": 33607,
    "lat": 27.96757,
    "lng": -82.51377,
    "state_id": "FL"
  },
  {
    "zip": 33609,
    "lat": 27.94432,
    "lng": -82.51354,
    "state_id": "FL"
  },
  {
    "zip": 33610,
    "lat": 27.99719,
    "lng": -82.38038,
    "state_id": "FL"
  },
  {
    "zip": 33611,
    "lat": 27.89059,
    "lng": -82.50685,
    "state_id": "FL"
  },
  {
    "zip": 33612,
    "lat": 28.05077,
    "lng": -82.44977,
    "state_id": "FL"
  },
  {
    "zip": 33613,
    "lat": 28.08363,
    "lng": -82.45087,
    "state_id": "FL"
  },
  {
    "zip": 33614,
    "lat": 28.00596,
    "lng": -82.50608,
    "state_id": "FL"
  },
  {
    "zip": 33615,
    "lat": 28.00536,
    "lng": -82.58135,
    "state_id": "FL"
  },
  {
    "zip": 33616,
    "lat": 27.86641,
    "lng": -82.52876,
    "state_id": "FL"
  },
  {
    "zip": 33617,
    "lat": 28.03838,
    "lng": -82.39271,
    "state_id": "FL"
  },
  {
    "zip": 33618,
    "lat": 28.07338,
    "lng": -82.49858,
    "state_id": "FL"
  },
  {
    "zip": 33619,
    "lat": 27.937,
    "lng": -82.37848,
    "state_id": "FL"
  },
  {
    "zip": 33620,
    "lat": 28.06152,
    "lng": -82.41118,
    "state_id": "FL"
  },
  {
    "zip": 33621,
    "lat": 27.84361,
    "lng": -82.50103,
    "state_id": "FL"
  },
  {
    "zip": 33624,
    "lat": 28.07901,
    "lng": -82.52678,
    "state_id": "FL"
  },
  {
    "zip": 33625,
    "lat": 28.06799,
    "lng": -82.5604,
    "state_id": "FL"
  },
  {
    "zip": 33626,
    "lat": 28.06628,
    "lng": -82.6165,
    "state_id": "FL"
  },
  {
    "zip": 33629,
    "lat": 27.92192,
    "lng": -82.51005,
    "state_id": "FL"
  },
  {
    "zip": 33634,
    "lat": 28.00884,
    "lng": -82.54618,
    "state_id": "FL"
  },
  {
    "zip": 33635,
    "lat": 28.0273,
    "lng": -82.6177,
    "state_id": "FL"
  },
  {
    "zip": 33637,
    "lat": 28.04728,
    "lng": -82.36133,
    "state_id": "FL"
  },
  {
    "zip": 33647,
    "lat": 28.13413,
    "lng": -82.35798,
    "state_id": "FL"
  },
  {
    "zip": 33701,
    "lat": 27.77041,
    "lng": -82.6357,
    "state_id": "FL"
  },
  {
    "zip": 33702,
    "lat": 27.84538,
    "lng": -82.63163,
    "state_id": "FL"
  },
  {
    "zip": 33703,
    "lat": 27.81756,
    "lng": -82.62438,
    "state_id": "FL"
  },
  {
    "zip": 33704,
    "lat": 27.79665,
    "lng": -82.63295,
    "state_id": "FL"
  },
  {
    "zip": 33705,
    "lat": 27.73633,
    "lng": -82.64469,
    "state_id": "FL"
  },
  {
    "zip": 33706,
    "lat": 27.74352,
    "lng": -82.74997,
    "state_id": "FL"
  },
  {
    "zip": 33707,
    "lat": 27.75552,
    "lng": -82.72564,
    "state_id": "FL"
  },
  {
    "zip": 33708,
    "lat": 27.81181,
    "lng": -82.79319,
    "state_id": "FL"
  },
  {
    "zip": 33709,
    "lat": 27.81714,
    "lng": -82.73058,
    "state_id": "FL"
  },
  {
    "zip": 33710,
    "lat": 27.79007,
    "lng": -82.7304,
    "state_id": "FL"
  },
  {
    "zip": 33711,
    "lat": 27.73891,
    "lng": -82.68843,
    "state_id": "FL"
  },
  {
    "zip": 33712,
    "lat": 27.73735,
    "lng": -82.66686,
    "state_id": "FL"
  },
  {
    "zip": 33713,
    "lat": 27.78879,
    "lng": -82.67756,
    "state_id": "FL"
  },
  {
    "zip": 33714,
    "lat": 27.81702,
    "lng": -82.67755,
    "state_id": "FL"
  },
  {
    "zip": 33715,
    "lat": 27.66134,
    "lng": -82.72377,
    "state_id": "FL"
  },
  {
    "zip": 33716,
    "lat": 27.87642,
    "lng": -82.65137,
    "state_id": "FL"
  },
  {
    "zip": 33744,
    "lat": 27.80853,
    "lng": -82.77452,
    "state_id": "FL"
  },
  {
    "zip": 33755,
    "lat": 27.97958,
    "lng": -82.77983,
    "state_id": "FL"
  },
  {
    "zip": 33756,
    "lat": 27.94449,
    "lng": -82.79241,
    "state_id": "FL"
  },
  {
    "zip": 33759,
    "lat": 27.98074,
    "lng": -82.71476,
    "state_id": "FL"
  },
  {
    "zip": 33760,
    "lat": 27.90777,
    "lng": -82.7145,
    "state_id": "FL"
  },
  {
    "zip": 33761,
    "lat": 28.03095,
    "lng": -82.72396,
    "state_id": "FL"
  },
  {
    "zip": 33762,
    "lat": 27.89683,
    "lng": -82.67971,
    "state_id": "FL"
  },
  {
    "zip": 33763,
    "lat": 28.00333,
    "lng": -82.74403,
    "state_id": "FL"
  },
  {
    "zip": 33764,
    "lat": 27.93381,
    "lng": -82.74062,
    "state_id": "FL"
  },
  {
    "zip": 33765,
    "lat": 27.97474,
    "lng": -82.74472,
    "state_id": "FL"
  },
  {
    "zip": 33767,
    "lat": 27.97,
    "lng": -82.82337,
    "state_id": "FL"
  },
  {
    "zip": 33770,
    "lat": 27.91469,
    "lng": -82.80358,
    "state_id": "FL"
  },
  {
    "zip": 33771,
    "lat": 27.90666,
    "lng": -82.75799,
    "state_id": "FL"
  },
  {
    "zip": 33772,
    "lat": 27.84258,
    "lng": -82.7964,
    "state_id": "FL"
  },
  {
    "zip": 33773,
    "lat": 27.88207,
    "lng": -82.75725,
    "state_id": "FL"
  },
  {
    "zip": 33774,
    "lat": 27.88268,
    "lng": -82.82763,
    "state_id": "FL"
  },
  {
    "zip": 33776,
    "lat": 27.84985,
    "lng": -82.82609,
    "state_id": "FL"
  },
  {
    "zip": 33777,
    "lat": 27.85329,
    "lng": -82.75989,
    "state_id": "FL"
  },
  {
    "zip": 33778,
    "lat": 27.88265,
    "lng": -82.79837,
    "state_id": "FL"
  },
  {
    "zip": 33781,
    "lat": 27.83865,
    "lng": -82.7152,
    "state_id": "FL"
  },
  {
    "zip": 33782,
    "lat": 27.85988,
    "lng": -82.70844,
    "state_id": "FL"
  },
  {
    "zip": 33785,
    "lat": 27.88543,
    "lng": -82.84425,
    "state_id": "FL"
  },
  {
    "zip": 33786,
    "lat": 27.92383,
    "lng": -82.8365,
    "state_id": "FL"
  },
  {
    "zip": 33801,
    "lat": 28.04082,
    "lng": -81.89624,
    "state_id": "FL"
  },
  {
    "zip": 33803,
    "lat": 28.00888,
    "lng": -81.93264,
    "state_id": "FL"
  },
  {
    "zip": 33805,
    "lat": 28.10248,
    "lng": -81.90748,
    "state_id": "FL"
  },
  {
    "zip": 33809,
    "lat": 28.2229,
    "lng": -81.97049,
    "state_id": "FL"
  },
  {
    "zip": 33810,
    "lat": 28.12701,
    "lng": -82.02758,
    "state_id": "FL"
  },
  {
    "zip": 33811,
    "lat": 27.98022,
    "lng": -82.01809,
    "state_id": "FL"
  },
  {
    "zip": 33812,
    "lat": 27.97286,
    "lng": -81.89308,
    "state_id": "FL"
  },
  {
    "zip": 33813,
    "lat": 27.9611,
    "lng": -81.93981,
    "state_id": "FL"
  },
  {
    "zip": 33815,
    "lat": 28.04069,
    "lng": -81.99645,
    "state_id": "FL"
  },
  {
    "zip": 33823,
    "lat": 28.08723,
    "lng": -81.81396,
    "state_id": "FL"
  },
  {
    "zip": 33825,
    "lat": 27.6243,
    "lng": -81.46502,
    "state_id": "FL"
  },
  {
    "zip": 33827,
    "lat": 27.81633,
    "lng": -81.51392,
    "state_id": "FL"
  },
  {
    "zip": 33830,
    "lat": 27.87486,
    "lng": -81.81743,
    "state_id": "FL"
  },
  {
    "zip": 33834,
    "lat": 27.60298,
    "lng": -81.99236,
    "state_id": "FL"
  },
  {
    "zip": 33837,
    "lat": 28.19084,
    "lng": -81.59108,
    "state_id": "FL"
  },
  {
    "zip": 33838,
    "lat": 28.02013,
    "lng": -81.61364,
    "state_id": "FL"
  },
  {
    "zip": 33839,
    "lat": 27.97563,
    "lng": -81.75068,
    "state_id": "FL"
  },
  {
    "zip": 33841,
    "lat": 27.73557,
    "lng": -81.7563,
    "state_id": "FL"
  },
  {
    "zip": 33843,
    "lat": 27.72289,
    "lng": -81.53169,
    "state_id": "FL"
  },
  {
    "zip": 33844,
    "lat": 28.08452,
    "lng": -81.58533,
    "state_id": "FL"
  },
  {
    "zip": 33847,
    "lat": 27.80458,
    "lng": -81.82327,
    "state_id": "FL"
  },
  {
    "zip": 33848,
    "lat": 28.2774,
    "lng": -81.50688,
    "state_id": "FL"
  },
  {
    "zip": 33849,
    "lat": 28.21676,
    "lng": -82.07373,
    "state_id": "FL"
  },
  {
    "zip": 33850,
    "lat": 28.12106,
    "lng": -81.73263,
    "state_id": "FL"
  },
  {
    "zip": 33851,
    "lat": 28.04444,
    "lng": -81.62354,
    "state_id": "FL"
  },
  {
    "zip": 33852,
    "lat": 27.23883,
    "lng": -81.37414,
    "state_id": "FL"
  },
  {
    "zip": 33853,
    "lat": 27.90029,
    "lng": -81.58465,
    "state_id": "FL"
  },
  {
    "zip": 33854,
    "lat": 27.85616,
    "lng": -81.40996,
    "state_id": "FL"
  },
  {
    "zip": 33855,
    "lat": 27.79538,
    "lng": -81.35278,
    "state_id": "FL"
  },
  {
    "zip": 33856,
    "lat": 27.85647,
    "lng": -81.42832,
    "state_id": "FL"
  },
  {
    "zip": 33857,
    "lat": 27.38262,
    "lng": -81.18352,
    "state_id": "FL"
  },
  {
    "zip": 33859,
    "lat": 27.87733,
    "lng": -81.62205,
    "state_id": "FL"
  },
  {
    "zip": 33860,
    "lat": 27.79175,
    "lng": -81.99412,
    "state_id": "FL"
  },
  {
    "zip": 33865,
    "lat": 27.43859,
    "lng": -81.96301,
    "state_id": "FL"
  },
  {
    "zip": 33867,
    "lat": 27.77693,
    "lng": -81.19561,
    "state_id": "FL"
  },
  {
    "zip": 33868,
    "lat": 28.25613,
    "lng": -81.82394,
    "state_id": "FL"
  },
  {
    "zip": 33870,
    "lat": 27.50424,
    "lng": -81.40208,
    "state_id": "FL"
  },
  {
    "zip": 33872,
    "lat": 27.51314,
    "lng": -81.52645,
    "state_id": "FL"
  },
  {
    "zip": 33873,
    "lat": 27.56256,
    "lng": -81.77286,
    "state_id": "FL"
  },
  {
    "zip": 33875,
    "lat": 27.40592,
    "lng": -81.50097,
    "state_id": "FL"
  },
  {
    "zip": 33876,
    "lat": 27.42871,
    "lng": -81.35191,
    "state_id": "FL"
  },
  {
    "zip": 33877,
    "lat": 27.98908,
    "lng": -81.61441,
    "state_id": "FL"
  },
  {
    "zip": 33880,
    "lat": 27.98133,
    "lng": -81.78035,
    "state_id": "FL"
  },
  {
    "zip": 33881,
    "lat": 28.05361,
    "lng": -81.70751,
    "state_id": "FL"
  },
  {
    "zip": 33884,
    "lat": 27.98101,
    "lng": -81.67356,
    "state_id": "FL"
  },
  {
    "zip": 33890,
    "lat": 27.44704,
    "lng": -81.6889,
    "state_id": "FL"
  },
  {
    "zip": 33896,
    "lat": 28.25745,
    "lng": -81.58424,
    "state_id": "FL"
  },
  {
    "zip": 33897,
    "lat": 28.27174,
    "lng": -81.69025,
    "state_id": "FL"
  },
  {
    "zip": 33898,
    "lat": 27.82721,
    "lng": -81.36219,
    "state_id": "FL"
  },
  {
    "zip": 33901,
    "lat": 26.62116,
    "lng": -81.87865,
    "state_id": "FL"
  },
  {
    "zip": 33903,
    "lat": 26.6811,
    "lng": -81.90668,
    "state_id": "FL"
  },
  {
    "zip": 33904,
    "lat": 26.57594,
    "lng": -81.94538,
    "state_id": "FL"
  },
  {
    "zip": 33905,
    "lat": 26.6693,
    "lng": -81.76046,
    "state_id": "FL"
  },
  {
    "zip": 33907,
    "lat": 26.56431,
    "lng": -81.87137,
    "state_id": "FL"
  },
  {
    "zip": 33908,
    "lat": 26.49315,
    "lng": -81.9197,
    "state_id": "FL"
  },
  {
    "zip": 33909,
    "lat": 26.69389,
    "lng": -81.94527,
    "state_id": "FL"
  },
  {
    "zip": 33912,
    "lat": 26.53286,
    "lng": -81.8252,
    "state_id": "FL"
  },
  {
    "zip": 33913,
    "lat": 26.5162,
    "lng": -81.69652,
    "state_id": "FL"
  },
  {
    "zip": 33914,
    "lat": 26.56798,
    "lng": -82.01549,
    "state_id": "FL"
  },
  {
    "zip": 33916,
    "lat": 26.63434,
    "lng": -81.83912,
    "state_id": "FL"
  },
  {
    "zip": 33917,
    "lat": 26.73578,
    "lng": -81.84351,
    "state_id": "FL"
  },
  {
    "zip": 33919,
    "lat": 26.55668,
    "lng": -81.90338,
    "state_id": "FL"
  },
  {
    "zip": 33920,
    "lat": 26.72892,
    "lng": -81.65256,
    "state_id": "FL"
  },
  {
    "zip": 33921,
    "lat": 26.77871,
    "lng": -82.25375,
    "state_id": "FL"
  },
  {
    "zip": 33922,
    "lat": 26.66432,
    "lng": -82.13808,
    "state_id": "FL"
  },
  {
    "zip": 33924,
    "lat": 26.60931,
    "lng": -82.22205,
    "state_id": "FL"
  },
  {
    "zip": 33928,
    "lat": 26.41977,
    "lng": -81.72336,
    "state_id": "FL"
  },
  {
    "zip": 33930,
    "lat": 26.56661,
    "lng": -81.41402,
    "state_id": "FL"
  },
  {
    "zip": 33931,
    "lat": 26.43551,
    "lng": -81.90623,
    "state_id": "FL"
  },
  {
    "zip": 33935,
    "lat": 26.75919,
    "lng": -81.43303,
    "state_id": "FL"
  },
  {
    "zip": 33936,
    "lat": 26.61572,
    "lng": -81.58998,
    "state_id": "FL"
  },
  {
    "zip": 33944,
    "lat": 26.98641,
    "lng": -81.28098,
    "state_id": "FL"
  },
  {
    "zip": 33945,
    "lat": 26.60226,
    "lng": -82.22088,
    "state_id": "FL"
  },
  {
    "zip": 33946,
    "lat": 26.84774,
    "lng": -82.27295,
    "state_id": "FL"
  },
  {
    "zip": 33947,
    "lat": 26.88719,
    "lng": -82.26976,
    "state_id": "FL"
  },
  {
    "zip": 33948,
    "lat": 26.98414,
    "lng": -82.15122,
    "state_id": "FL"
  },
  {
    "zip": 33950,
    "lat": 26.9022,
    "lng": -82.04419,
    "state_id": "FL"
  },
  {
    "zip": 33952,
    "lat": 26.99012,
    "lng": -82.0972,
    "state_id": "FL"
  },
  {
    "zip": 33953,
    "lat": 26.99969,
    "lng": -82.21204,
    "state_id": "FL"
  },
  {
    "zip": 33954,
    "lat": 27.02514,
    "lng": -82.12211,
    "state_id": "FL"
  },
  {
    "zip": 33955,
    "lat": 26.81037,
    "lng": -81.98116,
    "state_id": "FL"
  },
  {
    "zip": 33956,
    "lat": 26.54736,
    "lng": -82.09612,
    "state_id": "FL"
  },
  {
    "zip": 33957,
    "lat": 26.45347,
    "lng": -82.10241,
    "state_id": "FL"
  },
  {
    "zip": 33960,
    "lat": 27.03757,
    "lng": -81.42351,
    "state_id": "FL"
  },
  {
    "zip": 33965,
    "lat": 26.46368,
    "lng": -81.77214,
    "state_id": "FL"
  },
  {
    "zip": 33966,
    "lat": 26.58237,
    "lng": -81.83205,
    "state_id": "FL"
  },
  {
    "zip": 33967,
    "lat": 26.47223,
    "lng": -81.81223,
    "state_id": "FL"
  },
  {
    "zip": 33971,
    "lat": 26.63875,
    "lng": -81.69917,
    "state_id": "FL"
  },
  {
    "zip": 33972,
    "lat": 26.6492,
    "lng": -81.61667,
    "state_id": "FL"
  },
  {
    "zip": 33973,
    "lat": 26.60195,
    "lng": -81.73108,
    "state_id": "FL"
  },
  {
    "zip": 33974,
    "lat": 26.56254,
    "lng": -81.60368,
    "state_id": "FL"
  },
  {
    "zip": 33976,
    "lat": 26.59125,
    "lng": -81.68544,
    "state_id": "FL"
  },
  {
    "zip": 33980,
    "lat": 26.98058,
    "lng": -82.05209,
    "state_id": "FL"
  },
  {
    "zip": 33981,
    "lat": 26.9229,
    "lng": -82.21761,
    "state_id": "FL"
  },
  {
    "zip": 33982,
    "lat": 26.91126,
    "lng": -81.76898,
    "state_id": "FL"
  },
  {
    "zip": 33983,
    "lat": 27.0072,
    "lng": -82.01518,
    "state_id": "FL"
  },
  {
    "zip": 33990,
    "lat": 26.62789,
    "lng": -81.9433,
    "state_id": "FL"
  },
  {
    "zip": 33991,
    "lat": 26.6247,
    "lng": -82.0216,
    "state_id": "FL"
  },
  {
    "zip": 33993,
    "lat": 26.69196,
    "lng": -82.03084,
    "state_id": "FL"
  },
  {
    "zip": 34101,
    "lat": 26.04632,
    "lng": -81.76799,
    "state_id": "FL"
  },
  {
    "zip": 34102,
    "lat": 26.13806,
    "lng": -81.79659,
    "state_id": "FL"
  },
  {
    "zip": 34103,
    "lat": 26.1927,
    "lng": -81.80381,
    "state_id": "FL"
  },
  {
    "zip": 34104,
    "lat": 26.15288,
    "lng": -81.74163,
    "state_id": "FL"
  },
  {
    "zip": 34105,
    "lat": 26.18941,
    "lng": -81.76365,
    "state_id": "FL"
  },
  {
    "zip": 34108,
    "lat": 26.24499,
    "lng": -81.80691,
    "state_id": "FL"
  },
  {
    "zip": 34109,
    "lat": 26.24096,
    "lng": -81.76371,
    "state_id": "FL"
  },
  {
    "zip": 34110,
    "lat": 26.29553,
    "lng": -81.78559,
    "state_id": "FL"
  },
  {
    "zip": 34112,
    "lat": 26.11803,
    "lng": -81.74162,
    "state_id": "FL"
  },
  {
    "zip": 34113,
    "lat": 26.04867,
    "lng": -81.72777,
    "state_id": "FL"
  },
  {
    "zip": 34114,
    "lat": 25.96745,
    "lng": -81.55033,
    "state_id": "FL"
  },
  {
    "zip": 34116,
    "lat": 26.18805,
    "lng": -81.7105,
    "state_id": "FL"
  },
  {
    "zip": 34117,
    "lat": 26.1803,
    "lng": -81.59441,
    "state_id": "FL"
  },
  {
    "zip": 34119,
    "lat": 26.26653,
    "lng": -81.71461,
    "state_id": "FL"
  },
  {
    "zip": 34120,
    "lat": 26.32339,
    "lng": -81.57525,
    "state_id": "FL"
  },
  {
    "zip": 34134,
    "lat": 26.35634,
    "lng": -81.82836,
    "state_id": "FL"
  },
  {
    "zip": 34135,
    "lat": 26.35547,
    "lng": -81.75197,
    "state_id": "FL"
  },
  {
    "zip": 34137,
    "lat": 25.94097,
    "lng": -81.38067,
    "state_id": "FL"
  },
  {
    "zip": 34138,
    "lat": 25.81588,
    "lng": -81.37274,
    "state_id": "FL"
  },
  {
    "zip": 34139,
    "lat": 25.8255,
    "lng": -81.41295,
    "state_id": "FL"
  },
  {
    "zip": 34140,
    "lat": 25.9245,
    "lng": -81.65935,
    "state_id": "FL"
  },
  {
    "zip": 34141,
    "lat": 25.97229,
    "lng": -81.09363,
    "state_id": "FL"
  },
  {
    "zip": 34142,
    "lat": 26.33816,
    "lng": -81.33595,
    "state_id": "FL"
  },
  {
    "zip": 34145,
    "lat": 25.91903,
    "lng": -81.6953,
    "state_id": "FL"
  },
  {
    "zip": 34201,
    "lat": 27.4028,
    "lng": -82.4678,
    "state_id": "FL"
  },
  {
    "zip": 34202,
    "lat": 27.40672,
    "lng": -82.39011,
    "state_id": "FL"
  },
  {
    "zip": 34203,
    "lat": 27.44355,
    "lng": -82.50929,
    "state_id": "FL"
  },
  {
    "zip": 34205,
    "lat": 27.48415,
    "lng": -82.58345,
    "state_id": "FL"
  },
  {
    "zip": 34207,
    "lat": 27.43754,
    "lng": -82.58013,
    "state_id": "FL"
  },
  {
    "zip": 34208,
    "lat": 27.48711,
    "lng": -82.51322,
    "state_id": "FL"
  },
  {
    "zip": 34209,
    "lat": 27.49492,
    "lng": -82.64316,
    "state_id": "FL"
  },
  {
    "zip": 34210,
    "lat": 27.43799,
    "lng": -82.63082,
    "state_id": "FL"
  },
  {
    "zip": 34211,
    "lat": 27.45002,
    "lng": -82.37732,
    "state_id": "FL"
  },
  {
    "zip": 34212,
    "lat": 27.49886,
    "lng": -82.40782,
    "state_id": "FL"
  },
  {
    "zip": 34215,
    "lat": 27.47176,
    "lng": -82.68493,
    "state_id": "FL"
  },
  {
    "zip": 34216,
    "lat": 27.52971,
    "lng": -82.73396,
    "state_id": "FL"
  },
  {
    "zip": 34217,
    "lat": 27.48991,
    "lng": -82.70497,
    "state_id": "FL"
  },
  {
    "zip": 34219,
    "lat": 27.58667,
    "lng": -82.29926,
    "state_id": "FL"
  },
  {
    "zip": 34221,
    "lat": 27.57703,
    "lng": -82.54383,
    "state_id": "FL"
  },
  {
    "zip": 34222,
    "lat": 27.53628,
    "lng": -82.50504,
    "state_id": "FL"
  },
  {
    "zip": 34223,
    "lat": 26.98035,
    "lng": -82.35198,
    "state_id": "FL"
  },
  {
    "zip": 34224,
    "lat": 26.91996,
    "lng": -82.3047,
    "state_id": "FL"
  },
  {
    "zip": 34228,
    "lat": 27.39998,
    "lng": -82.64255,
    "state_id": "FL"
  },
  {
    "zip": 34229,
    "lat": 27.19091,
    "lng": -82.48302,
    "state_id": "FL"
  },
  {
    "zip": 34231,
    "lat": 27.26664,
    "lng": -82.51635,
    "state_id": "FL"
  },
  {
    "zip": 34232,
    "lat": 27.32603,
    "lng": -82.47235,
    "state_id": "FL"
  },
  {
    "zip": 34233,
    "lat": 27.28368,
    "lng": -82.47302,
    "state_id": "FL"
  },
  {
    "zip": 34234,
    "lat": 27.36841,
    "lng": -82.54153,
    "state_id": "FL"
  },
  {
    "zip": 34235,
    "lat": 27.36755,
    "lng": -82.4756,
    "state_id": "FL"
  },
  {
    "zip": 34236,
    "lat": 27.32626,
    "lng": -82.5572,
    "state_id": "FL"
  },
  {
    "zip": 34237,
    "lat": 27.3377,
    "lng": -82.5139,
    "state_id": "FL"
  },
  {
    "zip": 34238,
    "lat": 27.23049,
    "lng": -82.46878,
    "state_id": "FL"
  },
  {
    "zip": 34239,
    "lat": 27.31099,
    "lng": -82.52175,
    "state_id": "FL"
  },
  {
    "zip": 34240,
    "lat": 27.33903,
    "lng": -82.34735,
    "state_id": "FL"
  },
  {
    "zip": 34241,
    "lat": 27.2464,
    "lng": -82.37769,
    "state_id": "FL"
  },
  {
    "zip": 34242,
    "lat": 27.26232,
    "lng": -82.53995,
    "state_id": "FL"
  },
  {
    "zip": 34243,
    "lat": 27.40322,
    "lng": -82.52958,
    "state_id": "FL"
  },
  {
    "zip": 34251,
    "lat": 27.37608,
    "lng": -82.17041,
    "state_id": "FL"
  },
  {
    "zip": 34266,
    "lat": 27.19002,
    "lng": -81.8263,
    "state_id": "FL"
  },
  {
    "zip": 34268,
    "lat": 27.15264,
    "lng": -81.89307,
    "state_id": "FL"
  },
  {
    "zip": 34269,
    "lat": 27.09811,
    "lng": -81.99622,
    "state_id": "FL"
  },
  {
    "zip": 34275,
    "lat": 27.15228,
    "lng": -82.4201,
    "state_id": "FL"
  },
  {
    "zip": 34285,
    "lat": 27.09153,
    "lng": -82.43399,
    "state_id": "FL"
  },
  {
    "zip": 34286,
    "lat": 27.08126,
    "lng": -82.18161,
    "state_id": "FL"
  },
  {
    "zip": 34287,
    "lat": 27.0539,
    "lng": -82.24465,
    "state_id": "FL"
  },
  {
    "zip": 34288,
    "lat": 27.05388,
    "lng": -82.11165,
    "state_id": "FL"
  },
  {
    "zip": 34289,
    "lat": 27.08618,
    "lng": -82.13588,
    "state_id": "FL"
  },
  {
    "zip": 34291,
    "lat": 27.09865,
    "lng": -82.23864,
    "state_id": "FL"
  },
  {
    "zip": 34292,
    "lat": 27.10171,
    "lng": -82.34101,
    "state_id": "FL"
  },
  {
    "zip": 34293,
    "lat": 27.02802,
    "lng": -82.33552,
    "state_id": "FL"
  },
  {
    "zip": 34420,
    "lat": 29.05305,
    "lng": -82.03754,
    "state_id": "FL"
  },
  {
    "zip": 34428,
    "lat": 28.96087,
    "lng": -82.63392,
    "state_id": "FL"
  },
  {
    "zip": 34429,
    "lat": 28.85858,
    "lng": -82.65156,
    "state_id": "FL"
  },
  {
    "zip": 34431,
    "lat": 29.13923,
    "lng": -82.53281,
    "state_id": "FL"
  },
  {
    "zip": 34432,
    "lat": 29.06879,
    "lng": -82.36341,
    "state_id": "FL"
  },
  {
    "zip": 34433,
    "lat": 28.99724,
    "lng": -82.52319,
    "state_id": "FL"
  },
  {
    "zip": 34434,
    "lat": 28.99629,
    "lng": -82.43914,
    "state_id": "FL"
  },
  {
    "zip": 34436,
    "lat": 28.72351,
    "lng": -82.29699,
    "state_id": "FL"
  },
  {
    "zip": 34442,
    "lat": 28.93185,
    "lng": -82.37737,
    "state_id": "FL"
  },
  {
    "zip": 34445,
    "lat": 28.9679,
    "lng": -82.42083,
    "state_id": "FL"
  },
  {
    "zip": 34446,
    "lat": 28.74729,
    "lng": -82.52217,
    "state_id": "FL"
  },
  {
    "zip": 34448,
    "lat": 28.77006,
    "lng": -82.61286,
    "state_id": "FL"
  },
  {
    "zip": 34449,
    "lat": 29.08384,
    "lng": -82.67988,
    "state_id": "FL"
  },
  {
    "zip": 34450,
    "lat": 28.83558,
    "lng": -82.27172,
    "state_id": "FL"
  },
  {
    "zip": 34452,
    "lat": 28.77771,
    "lng": -82.36105,
    "state_id": "FL"
  },
  {
    "zip": 34453,
    "lat": 28.87853,
    "lng": -82.343,
    "state_id": "FL"
  },
  {
    "zip": 34461,
    "lat": 28.8111,
    "lng": -82.47691,
    "state_id": "FL"
  },
  {
    "zip": 34465,
    "lat": 28.9295,
    "lng": -82.48921,
    "state_id": "FL"
  },
  {
    "zip": 34470,
    "lat": 29.19891,
    "lng": -82.08742,
    "state_id": "FL"
  },
  {
    "zip": 34471,
    "lat": 29.16053,
    "lng": -82.12878,
    "state_id": "FL"
  },
  {
    "zip": 34472,
    "lat": 29.11249,
    "lng": -81.99548,
    "state_id": "FL"
  },
  {
    "zip": 34473,
    "lat": 29.00584,
    "lng": -82.18283,
    "state_id": "FL"
  },
  {
    "zip": 34474,
    "lat": 29.15642,
    "lng": -82.20944,
    "state_id": "FL"
  },
  {
    "zip": 34475,
    "lat": 29.25743,
    "lng": -82.16102,
    "state_id": "FL"
  },
  {
    "zip": 34476,
    "lat": 29.08161,
    "lng": -82.19679,
    "state_id": "FL"
  },
  {
    "zip": 34479,
    "lat": 29.25402,
    "lng": -82.10946,
    "state_id": "FL"
  },
  {
    "zip": 34480,
    "lat": 29.10567,
    "lng": -82.09793,
    "state_id": "FL"
  },
  {
    "zip": 34481,
    "lat": 29.1224,
    "lng": -82.30881,
    "state_id": "FL"
  },
  {
    "zip": 34482,
    "lat": 29.25027,
    "lng": -82.27279,
    "state_id": "FL"
  },
  {
    "zip": 34484,
    "lat": 28.9338,
    "lng": -82.08802,
    "state_id": "FL"
  },
  {
    "zip": 34488,
    "lat": 29.22164,
    "lng": -81.93927,
    "state_id": "FL"
  },
  {
    "zip": 34491,
    "lat": 28.9965,
    "lng": -82.04474,
    "state_id": "FL"
  },
  {
    "zip": 34498,
    "lat": 29.03287,
    "lng": -82.73375,
    "state_id": "FL"
  },
  {
    "zip": 34601,
    "lat": 28.59026,
    "lng": -82.36482,
    "state_id": "FL"
  },
  {
    "zip": 34602,
    "lat": 28.5031,
    "lng": -82.27965,
    "state_id": "FL"
  },
  {
    "zip": 34604,
    "lat": 28.47712,
    "lng": -82.43431,
    "state_id": "FL"
  },
  {
    "zip": 34606,
    "lat": 28.46993,
    "lng": -82.59751,
    "state_id": "FL"
  },
  {
    "zip": 34607,
    "lat": 28.49952,
    "lng": -82.63722,
    "state_id": "FL"
  },
  {
    "zip": 34608,
    "lat": 28.48272,
    "lng": -82.55319,
    "state_id": "FL"
  },
  {
    "zip": 34609,
    "lat": 28.47937,
    "lng": -82.5082,
    "state_id": "FL"
  },
  {
    "zip": 34610,
    "lat": 28.38341,
    "lng": -82.50006,
    "state_id": "FL"
  },
  {
    "zip": 34613,
    "lat": 28.57453,
    "lng": -82.56847,
    "state_id": "FL"
  },
  {
    "zip": 34614,
    "lat": 28.64775,
    "lng": -82.54601,
    "state_id": "FL"
  },
  {
    "zip": 34637,
    "lat": 28.29724,
    "lng": -82.46411,
    "state_id": "FL"
  },
  {
    "zip": 34638,
    "lat": 28.26014,
    "lng": -82.52053,
    "state_id": "FL"
  },
  {
    "zip": 34639,
    "lat": 28.24574,
    "lng": -82.42582,
    "state_id": "FL"
  },
  {
    "zip": 34652,
    "lat": 28.23662,
    "lng": -82.73668,
    "state_id": "FL"
  },
  {
    "zip": 34653,
    "lat": 28.24355,
    "lng": -82.693,
    "state_id": "FL"
  },
  {
    "zip": 34654,
    "lat": 28.29503,
    "lng": -82.6288,
    "state_id": "FL"
  },
  {
    "zip": 34655,
    "lat": 28.21708,
    "lng": -82.62584,
    "state_id": "FL"
  },
  {
    "zip": 34661,
    "lat": 28.6159,
    "lng": -82.24823,
    "state_id": "FL"
  },
  {
    "zip": 34667,
    "lat": 28.39201,
    "lng": -82.65806,
    "state_id": "FL"
  },
  {
    "zip": 34668,
    "lat": 28.30188,
    "lng": -82.70517,
    "state_id": "FL"
  },
  {
    "zip": 34669,
    "lat": 28.35826,
    "lng": -82.61478,
    "state_id": "FL"
  },
  {
    "zip": 34677,
    "lat": 28.04759,
    "lng": -82.67692,
    "state_id": "FL"
  },
  {
    "zip": 34679,
    "lat": 28.43077,
    "lng": -82.66146,
    "state_id": "FL"
  },
  {
    "zip": 34681,
    "lat": 28.08779,
    "lng": -82.77797,
    "state_id": "FL"
  },
  {
    "zip": 34683,
    "lat": 28.08632,
    "lng": -82.76003,
    "state_id": "FL"
  },
  {
    "zip": 34684,
    "lat": 28.08125,
    "lng": -82.72772,
    "state_id": "FL"
  },
  {
    "zip": 34685,
    "lat": 28.09644,
    "lng": -82.68691,
    "state_id": "FL"
  },
  {
    "zip": 34688,
    "lat": 28.1458,
    "lng": -82.6824,
    "state_id": "FL"
  },
  {
    "zip": 34689,
    "lat": 28.14915,
    "lng": -82.75806,
    "state_id": "FL"
  },
  {
    "zip": 34690,
    "lat": 28.19294,
    "lng": -82.72649,
    "state_id": "FL"
  },
  {
    "zip": 34691,
    "lat": 28.19117,
    "lng": -82.77784,
    "state_id": "FL"
  },
  {
    "zip": 34695,
    "lat": 28.00819,
    "lng": -82.69631,
    "state_id": "FL"
  },
  {
    "zip": 34698,
    "lat": 28.03399,
    "lng": -82.77961,
    "state_id": "FL"
  },
  {
    "zip": 34705,
    "lat": 28.69928,
    "lng": -81.71559,
    "state_id": "FL"
  },
  {
    "zip": 34711,
    "lat": 28.52611,
    "lng": -81.75669,
    "state_id": "FL"
  },
  {
    "zip": 34714,
    "lat": 28.41131,
    "lng": -81.78117,
    "state_id": "FL"
  },
  {
    "zip": 34715,
    "lat": 28.62586,
    "lng": -81.73055,
    "state_id": "FL"
  },
  {
    "zip": 34731,
    "lat": 28.86417,
    "lng": -81.89779,
    "state_id": "FL"
  },
  {
    "zip": 34734,
    "lat": 28.53828,
    "lng": -81.52213,
    "state_id": "FL"
  },
  {
    "zip": 34736,
    "lat": 28.55445,
    "lng": -81.89625,
    "state_id": "FL"
  },
  {
    "zip": 34737,
    "lat": 28.69295,
    "lng": -81.79654,
    "state_id": "FL"
  },
  {
    "zip": 34739,
    "lat": 27.93452,
    "lng": -81.12442,
    "state_id": "FL"
  },
  {
    "zip": 34741,
    "lat": 28.30548,
    "lng": -81.42571,
    "state_id": "FL"
  },
  {
    "zip": 34743,
    "lat": 28.3306,
    "lng": -81.35438,
    "state_id": "FL"
  },
  {
    "zip": 34744,
    "lat": 28.29642,
    "lng": -81.34485,
    "state_id": "FL"
  },
  {
    "zip": 34746,
    "lat": 28.24149,
    "lng": -81.44058,
    "state_id": "FL"
  },
  {
    "zip": 34747,
    "lat": 28.3096,
    "lng": -81.59666,
    "state_id": "FL"
  },
  {
    "zip": 34748,
    "lat": 28.76647,
    "lng": -81.88185,
    "state_id": "FL"
  },
  {
    "zip": 34753,
    "lat": 28.58976,
    "lng": -81.90079,
    "state_id": "FL"
  },
  {
    "zip": 34756,
    "lat": 28.58554,
    "lng": -81.68218,
    "state_id": "FL"
  },
  {
    "zip": 34758,
    "lat": 28.19834,
    "lng": -81.49515,
    "state_id": "FL"
  },
  {
    "zip": 34759,
    "lat": 28.09297,
    "lng": -81.43918,
    "state_id": "FL"
  },
  {
    "zip": 34760,
    "lat": 28.55459,
    "lng": -81.63236,
    "state_id": "FL"
  },
  {
    "zip": 34761,
    "lat": 28.57757,
    "lng": -81.5337,
    "state_id": "FL"
  },
  {
    "zip": 34762,
    "lat": 28.75447,
    "lng": -81.91511,
    "state_id": "FL"
  },
  {
    "zip": 34769,
    "lat": 28.24511,
    "lng": -81.29112,
    "state_id": "FL"
  },
  {
    "zip": 34771,
    "lat": 28.27645,
    "lng": -81.16274,
    "state_id": "FL"
  },
  {
    "zip": 34772,
    "lat": 28.16397,
    "lng": -81.26889,
    "state_id": "FL"
  },
  {
    "zip": 34773,
    "lat": 28.16517,
    "lng": -81.00578,
    "state_id": "FL"
  },
  {
    "zip": 34785,
    "lat": 28.83925,
    "lng": -82.04013,
    "state_id": "FL"
  },
  {
    "zip": 34786,
    "lat": 28.48213,
    "lng": -81.55434,
    "state_id": "FL"
  },
  {
    "zip": 34787,
    "lat": 28.48239,
    "lng": -81.62774,
    "state_id": "FL"
  },
  {
    "zip": 34788,
    "lat": 28.8871,
    "lng": -81.79827,
    "state_id": "FL"
  },
  {
    "zip": 34797,
    "lat": 28.73556,
    "lng": -81.81978,
    "state_id": "FL"
  },
  {
    "zip": 34945,
    "lat": 27.43798,
    "lng": -80.55733,
    "state_id": "FL"
  },
  {
    "zip": 34946,
    "lat": 27.50412,
    "lng": -80.36091,
    "state_id": "FL"
  },
  {
    "zip": 34947,
    "lat": 27.44897,
    "lng": -80.37593,
    "state_id": "FL"
  },
  {
    "zip": 34949,
    "lat": 27.46663,
    "lng": -80.30408,
    "state_id": "FL"
  },
  {
    "zip": 34950,
    "lat": 27.4435,
    "lng": -80.32967,
    "state_id": "FL"
  },
  {
    "zip": 34951,
    "lat": 27.52251,
    "lng": -80.41611,
    "state_id": "FL"
  },
  {
    "zip": 34952,
    "lat": 27.29562,
    "lng": -80.29887,
    "state_id": "FL"
  },
  {
    "zip": 34953,
    "lat": 27.249,
    "lng": -80.38129,
    "state_id": "FL"
  },
  {
    "zip": 34956,
    "lat": 27.0792,
    "lng": -80.50827,
    "state_id": "FL"
  },
  {
    "zip": 34957,
    "lat": 27.28328,
    "lng": -80.2419,
    "state_id": "FL"
  },
  {
    "zip": 34972,
    "lat": 27.50653,
    "lng": -80.92711,
    "state_id": "FL"
  },
  {
    "zip": 34974,
    "lat": 27.14475,
    "lng": -80.94548,
    "state_id": "FL"
  },
  {
    "zip": 34981,
    "lat": 27.39425,
    "lng": -80.37049,
    "state_id": "FL"
  },
  {
    "zip": 34982,
    "lat": 27.37291,
    "lng": -80.30818,
    "state_id": "FL"
  },
  {
    "zip": 34983,
    "lat": 27.32317,
    "lng": -80.35027,
    "state_id": "FL"
  },
  {
    "zip": 34984,
    "lat": 27.24784,
    "lng": -80.33458,
    "state_id": "FL"
  },
  {
    "zip": 34986,
    "lat": 27.32867,
    "lng": -80.40503,
    "state_id": "FL"
  },
  {
    "zip": 34987,
    "lat": 27.28095,
    "lng": -80.50115,
    "state_id": "FL"
  },
  {
    "zip": 34990,
    "lat": 27.13751,
    "lng": -80.34833,
    "state_id": "FL"
  },
  {
    "zip": 34994,
    "lat": 27.20078,
    "lng": -80.25695,
    "state_id": "FL"
  },
  {
    "zip": 34996,
    "lat": 27.19905,
    "lng": -80.19632,
    "state_id": "FL"
  },
  {
    "zip": 34997,
    "lat": 27.05254,
    "lng": -80.27162,
    "state_id": "FL"
  },
  {
    "zip": 30002,
    "lat": 33.77366,
    "lng": -84.26051,
    "state_id": "GA"
  },
  {
    "zip": 30004,
    "lat": 34.14329,
    "lng": -84.29948,
    "state_id": "GA"
  },
  {
    "zip": 30005,
    "lat": 34.08622,
    "lng": -84.21593,
    "state_id": "GA"
  },
  {
    "zip": 30008,
    "lat": 33.89629,
    "lng": -84.58926,
    "state_id": "GA"
  },
  {
    "zip": 30009,
    "lat": 34.07699,
    "lng": -84.30328,
    "state_id": "GA"
  },
  {
    "zip": 30011,
    "lat": 34.02235,
    "lng": -83.83769,
    "state_id": "GA"
  },
  {
    "zip": 30012,
    "lat": 33.71884,
    "lng": -83.99866,
    "state_id": "GA"
  },
  {
    "zip": 30013,
    "lat": 33.64422,
    "lng": -83.97193,
    "state_id": "GA"
  },
  {
    "zip": 30014,
    "lat": 33.57828,
    "lng": -83.82048,
    "state_id": "GA"
  },
  {
    "zip": 30016,
    "lat": 33.51712,
    "lng": -83.92942,
    "state_id": "GA"
  },
  {
    "zip": 30017,
    "lat": 33.89012,
    "lng": -83.96318,
    "state_id": "GA"
  },
  {
    "zip": 30019,
    "lat": 33.97647,
    "lng": -83.88229,
    "state_id": "GA"
  },
  {
    "zip": 30021,
    "lat": 33.80816,
    "lng": -84.23816,
    "state_id": "GA"
  },
  {
    "zip": 30022,
    "lat": 34.03027,
    "lng": -84.2472,
    "state_id": "GA"
  },
  {
    "zip": 30024,
    "lat": 34.06239,
    "lng": -84.09069,
    "state_id": "GA"
  },
  {
    "zip": 30025,
    "lat": 33.65591,
    "lng": -83.69573,
    "state_id": "GA"
  },
  {
    "zip": 30028,
    "lat": 34.28964,
    "lng": -84.17949,
    "state_id": "GA"
  },
  {
    "zip": 30030,
    "lat": 33.77126,
    "lng": -84.29204,
    "state_id": "GA"
  },
  {
    "zip": 30032,
    "lat": 33.73974,
    "lng": -84.26433,
    "state_id": "GA"
  },
  {
    "zip": 30033,
    "lat": 33.81231,
    "lng": -84.28365,
    "state_id": "GA"
  },
  {
    "zip": 30034,
    "lat": 33.69196,
    "lng": -84.24882,
    "state_id": "GA"
  },
  {
    "zip": 30035,
    "lat": 33.72556,
    "lng": -84.20523,
    "state_id": "GA"
  },
  {
    "zip": 30038,
    "lat": 33.6706,
    "lng": -84.14236,
    "state_id": "GA"
  },
  {
    "zip": 30039,
    "lat": 33.79966,
    "lng": -84.0332,
    "state_id": "GA"
  },
  {
    "zip": 30040,
    "lat": 34.21614,
    "lng": -84.1806,
    "state_id": "GA"
  },
  {
    "zip": 30041,
    "lat": 34.20039,
    "lng": -84.09418,
    "state_id": "GA"
  },
  {
    "zip": 30043,
    "lat": 34.00303,
    "lng": -84.00744,
    "state_id": "GA"
  },
  {
    "zip": 30044,
    "lat": 33.92215,
    "lng": -84.06915,
    "state_id": "GA"
  },
  {
    "zip": 30045,
    "lat": 33.93126,
    "lng": -83.92996,
    "state_id": "GA"
  },
  {
    "zip": 30046,
    "lat": 33.94959,
    "lng": -83.99417,
    "state_id": "GA"
  },
  {
    "zip": 30047,
    "lat": 33.87064,
    "lng": -84.11267,
    "state_id": "GA"
  },
  {
    "zip": 30052,
    "lat": 33.81581,
    "lng": -83.8947,
    "state_id": "GA"
  },
  {
    "zip": 30054,
    "lat": 33.67579,
    "lng": -83.87454,
    "state_id": "GA"
  },
  {
    "zip": 30055,
    "lat": 33.4897,
    "lng": -83.74234,
    "state_id": "GA"
  },
  {
    "zip": 30056,
    "lat": 33.49701,
    "lng": -83.6627,
    "state_id": "GA"
  },
  {
    "zip": 30058,
    "lat": 33.7376,
    "lng": -84.10616,
    "state_id": "GA"
  },
  {
    "zip": 30060,
    "lat": 33.92025,
    "lng": -84.54203,
    "state_id": "GA"
  },
  {
    "zip": 30062,
    "lat": 33.99971,
    "lng": -84.46987,
    "state_id": "GA"
  },
  {
    "zip": 30064,
    "lat": 33.93965,
    "lng": -84.61642,
    "state_id": "GA"
  },
  {
    "zip": 30066,
    "lat": 34.03403,
    "lng": -84.50788,
    "state_id": "GA"
  },
  {
    "zip": 30067,
    "lat": 33.93237,
    "lng": -84.46149,
    "state_id": "GA"
  },
  {
    "zip": 30068,
    "lat": 33.96888,
    "lng": -84.43288,
    "state_id": "GA"
  },
  {
    "zip": 30070,
    "lat": 33.57416,
    "lng": -83.89538,
    "state_id": "GA"
  },
  {
    "zip": 30071,
    "lat": 33.93943,
    "lng": -84.20594,
    "state_id": "GA"
  },
  {
    "zip": 30072,
    "lat": 33.79136,
    "lng": -84.20593,
    "state_id": "GA"
  },
  {
    "zip": 30075,
    "lat": 34.05248,
    "lng": -84.38741,
    "state_id": "GA"
  },
  {
    "zip": 30076,
    "lat": 34.02845,
    "lng": -84.31765,
    "state_id": "GA"
  },
  {
    "zip": 30078,
    "lat": 33.86166,
    "lng": -84.01789,
    "state_id": "GA"
  },
  {
    "zip": 30079,
    "lat": 33.79242,
    "lng": -84.25768,
    "state_id": "GA"
  },
  {
    "zip": 30080,
    "lat": 33.87137,
    "lng": -84.50105,
    "state_id": "GA"
  },
  {
    "zip": 30082,
    "lat": 33.85445,
    "lng": -84.53505,
    "state_id": "GA"
  },
  {
    "zip": 30083,
    "lat": 33.79518,
    "lng": -84.19442,
    "state_id": "GA"
  },
  {
    "zip": 30084,
    "lat": 33.85431,
    "lng": -84.21596,
    "state_id": "GA"
  },
  {
    "zip": 30087,
    "lat": 33.81002,
    "lng": -84.13133,
    "state_id": "GA"
  },
  {
    "zip": 30088,
    "lat": 33.7595,
    "lng": -84.17925,
    "state_id": "GA"
  },
  {
    "zip": 30092,
    "lat": 33.97168,
    "lng": -84.23515,
    "state_id": "GA"
  },
  {
    "zip": 30093,
    "lat": 33.90951,
    "lng": -84.17802,
    "state_id": "GA"
  },
  {
    "zip": 30094,
    "lat": 33.61125,
    "lng": -84.05635,
    "state_id": "GA"
  },
  {
    "zip": 30096,
    "lat": 33.97681,
    "lng": -84.14824,
    "state_id": "GA"
  },
  {
    "zip": 30097,
    "lat": 34.02595,
    "lng": -84.14704,
    "state_id": "GA"
  },
  {
    "zip": 30101,
    "lat": 34.04345,
    "lng": -84.71271,
    "state_id": "GA"
  },
  {
    "zip": 30102,
    "lat": 34.10611,
    "lng": -84.64344,
    "state_id": "GA"
  },
  {
    "zip": 30103,
    "lat": 34.36661,
    "lng": -84.92071,
    "state_id": "GA"
  },
  {
    "zip": 30104,
    "lat": 34.08456,
    "lng": -85.0615,
    "state_id": "GA"
  },
  {
    "zip": 30105,
    "lat": 34.47183,
    "lng": -85.16109,
    "state_id": "GA"
  },
  {
    "zip": 30106,
    "lat": 33.83605,
    "lng": -84.62705,
    "state_id": "GA"
  },
  {
    "zip": 30107,
    "lat": 34.33581,
    "lng": -84.3475,
    "state_id": "GA"
  },
  {
    "zip": 30108,
    "lat": 33.53232,
    "lng": -85.26319,
    "state_id": "GA"
  },
  {
    "zip": 30110,
    "lat": 33.742,
    "lng": -85.13332,
    "state_id": "GA"
  },
  {
    "zip": 30113,
    "lat": 33.85046,
    "lng": -85.20833,
    "state_id": "GA"
  },
  {
    "zip": 30114,
    "lat": 34.24693,
    "lng": -84.5257,
    "state_id": "GA"
  },
  {
    "zip": 30115,
    "lat": 34.20405,
    "lng": -84.40077,
    "state_id": "GA"
  },
  {
    "zip": 30116,
    "lat": 33.55092,
    "lng": -85.01359,
    "state_id": "GA"
  },
  {
    "zip": 30117,
    "lat": 33.58017,
    "lng": -85.13241,
    "state_id": "GA"
  },
  {
    "zip": 30118,
    "lat": 33.57233,
    "lng": -85.10369,
    "state_id": "GA"
  },
  {
    "zip": 30120,
    "lat": 34.16708,
    "lng": -84.85534,
    "state_id": "GA"
  },
  {
    "zip": 30121,
    "lat": 34.2084,
    "lng": -84.77663,
    "state_id": "GA"
  },
  {
    "zip": 30122,
    "lat": 33.76298,
    "lng": -84.63819,
    "state_id": "GA"
  },
  {
    "zip": 30124,
    "lat": 34.13052,
    "lng": -85.35585,
    "state_id": "GA"
  },
  {
    "zip": 30125,
    "lat": 34.00204,
    "lng": -85.27502,
    "state_id": "GA"
  },
  {
    "zip": 30126,
    "lat": 33.81592,
    "lng": -84.55206,
    "state_id": "GA"
  },
  {
    "zip": 30127,
    "lat": 33.87477,
    "lng": -84.69609,
    "state_id": "GA"
  },
  {
    "zip": 30132,
    "lat": 33.98839,
    "lng": -84.85867,
    "state_id": "GA"
  },
  {
    "zip": 30134,
    "lat": 33.77474,
    "lng": -84.77979,
    "state_id": "GA"
  },
  {
    "zip": 30135,
    "lat": 33.67236,
    "lng": -84.74554,
    "state_id": "GA"
  },
  {
    "zip": 30137,
    "lat": 34.12238,
    "lng": -84.7591,
    "state_id": "GA"
  },
  {
    "zip": 30139,
    "lat": 34.43329,
    "lng": -84.70868,
    "state_id": "GA"
  },
  {
    "zip": 30141,
    "lat": 33.86264,
    "lng": -84.77175,
    "state_id": "GA"
  },
  {
    "zip": 30143,
    "lat": 34.45879,
    "lng": -84.43321,
    "state_id": "GA"
  },
  {
    "zip": 30144,
    "lat": 34.03707,
    "lng": -84.59191,
    "state_id": "GA"
  },
  {
    "zip": 30145,
    "lat": 34.24335,
    "lng": -84.98311,
    "state_id": "GA"
  },
  {
    "zip": 30147,
    "lat": 34.14109,
    "lng": -85.21652,
    "state_id": "GA"
  },
  {
    "zip": 30148,
    "lat": 34.45758,
    "lng": -84.25847,
    "state_id": "GA"
  },
  {
    "zip": 30149,
    "lat": 34.31033,
    "lng": -85.22535,
    "state_id": "GA"
  },
  {
    "zip": 30152,
    "lat": 33.99506,
    "lng": -84.65074,
    "state_id": "GA"
  },
  {
    "zip": 30153,
    "lat": 33.9674,
    "lng": -85.06206,
    "state_id": "GA"
  },
  {
    "zip": 30157,
    "lat": 33.88586,
    "lng": -84.87326,
    "state_id": "GA"
  },
  {
    "zip": 30161,
    "lat": 34.23799,
    "lng": -85.17184,
    "state_id": "GA"
  },
  {
    "zip": 30164,
    "lat": 33.39572,
    "lng": -83.83401,
    "state_id": "GA"
  },
  {
    "zip": 30165,
    "lat": 34.30358,
    "lng": -85.27155,
    "state_id": "GA"
  },
  {
    "zip": 30168,
    "lat": 33.7837,
    "lng": -84.58873,
    "state_id": "GA"
  },
  {
    "zip": 30170,
    "lat": 33.42922,
    "lng": -85.1695,
    "state_id": "GA"
  },
  {
    "zip": 30171,
    "lat": 34.34305,
    "lng": -84.72521,
    "state_id": "GA"
  },
  {
    "zip": 30173,
    "lat": 34.13301,
    "lng": -85.14263,
    "state_id": "GA"
  },
  {
    "zip": 30175,
    "lat": 34.54322,
    "lng": -84.52902,
    "state_id": "GA"
  },
  {
    "zip": 30176,
    "lat": 33.76779,
    "lng": -85.3028,
    "state_id": "GA"
  },
  {
    "zip": 30177,
    "lat": 34.41134,
    "lng": -84.37791,
    "state_id": "GA"
  },
  {
    "zip": 30178,
    "lat": 34.09788,
    "lng": -84.96543,
    "state_id": "GA"
  },
  {
    "zip": 30179,
    "lat": 33.77918,
    "lng": -85.01565,
    "state_id": "GA"
  },
  {
    "zip": 30180,
    "lat": 33.71734,
    "lng": -84.92173,
    "state_id": "GA"
  },
  {
    "zip": 30182,
    "lat": 33.65549,
    "lng": -85.24598,
    "state_id": "GA"
  },
  {
    "zip": 30183,
    "lat": 34.33605,
    "lng": -84.60031,
    "state_id": "GA"
  },
  {
    "zip": 30184,
    "lat": 34.25787,
    "lng": -84.72398,
    "state_id": "GA"
  },
  {
    "zip": 30185,
    "lat": 33.51982,
    "lng": -84.91018,
    "state_id": "GA"
  },
  {
    "zip": 30187,
    "lat": 33.66648,
    "lng": -84.852,
    "state_id": "GA"
  },
  {
    "zip": 30188,
    "lat": 34.11881,
    "lng": -84.45551,
    "state_id": "GA"
  },
  {
    "zip": 30189,
    "lat": 34.1281,
    "lng": -84.5716,
    "state_id": "GA"
  },
  {
    "zip": 30204,
    "lat": 33.05729,
    "lng": -84.12512,
    "state_id": "GA"
  },
  {
    "zip": 30205,
    "lat": 33.26848,
    "lng": -84.46663,
    "state_id": "GA"
  },
  {
    "zip": 30206,
    "lat": 33.09433,
    "lng": -84.46049,
    "state_id": "GA"
  },
  {
    "zip": 30213,
    "lat": 33.59148,
    "lng": -84.63726,
    "state_id": "GA"
  },
  {
    "zip": 30214,
    "lat": 33.49171,
    "lng": -84.48745,
    "state_id": "GA"
  },
  {
    "zip": 30215,
    "lat": 33.38857,
    "lng": -84.45729,
    "state_id": "GA"
  },
  {
    "zip": 30216,
    "lat": 33.23701,
    "lng": -83.88471,
    "state_id": "GA"
  },
  {
    "zip": 30217,
    "lat": 33.28598,
    "lng": -85.1303,
    "state_id": "GA"
  },
  {
    "zip": 30218,
    "lat": 33.12743,
    "lng": -84.58358,
    "state_id": "GA"
  },
  {
    "zip": 30220,
    "lat": 33.22505,
    "lng": -84.82432,
    "state_id": "GA"
  },
  {
    "zip": 30222,
    "lat": 33.04301,
    "lng": -84.74461,
    "state_id": "GA"
  },
  {
    "zip": 30223,
    "lat": 33.28629,
    "lng": -84.27968,
    "state_id": "GA"
  },
  {
    "zip": 30224,
    "lat": 33.20485,
    "lng": -84.23683,
    "state_id": "GA"
  },
  {
    "zip": 30228,
    "lat": 33.40449,
    "lng": -84.30545,
    "state_id": "GA"
  },
  {
    "zip": 30230,
    "lat": 33.1642,
    "lng": -84.92214,
    "state_id": "GA"
  },
  {
    "zip": 30233,
    "lat": 33.2919,
    "lng": -83.96942,
    "state_id": "GA"
  },
  {
    "zip": 30234,
    "lat": 33.32406,
    "lng": -84.03219,
    "state_id": "GA"
  },
  {
    "zip": 30236,
    "lat": 33.52244,
    "lng": -84.32426,
    "state_id": "GA"
  },
  {
    "zip": 30238,
    "lat": 33.4943,
    "lng": -84.38168,
    "state_id": "GA"
  },
  {
    "zip": 30240,
    "lat": 33.03627,
    "lng": -85.12367,
    "state_id": "GA"
  },
  {
    "zip": 30241,
    "lat": 33.02222,
    "lng": -84.95166,
    "state_id": "GA"
  },
  {
    "zip": 30248,
    "lat": 33.35142,
    "lng": -84.10775,
    "state_id": "GA"
  },
  {
    "zip": 30250,
    "lat": 33.43662,
    "lng": -84.31423,
    "state_id": "GA"
  },
  {
    "zip": 30251,
    "lat": 33.18817,
    "lng": -84.69729,
    "state_id": "GA"
  },
  {
    "zip": 30252,
    "lat": 33.46968,
    "lng": -84.06239,
    "state_id": "GA"
  },
  {
    "zip": 30253,
    "lat": 33.44884,
    "lng": -84.18335,
    "state_id": "GA"
  },
  {
    "zip": 30256,
    "lat": 33.01415,
    "lng": -84.32024,
    "state_id": "GA"
  },
  {
    "zip": 30257,
    "lat": 33.14313,
    "lng": -84.18404,
    "state_id": "GA"
  },
  {
    "zip": 30258,
    "lat": 32.98757,
    "lng": -84.46731,
    "state_id": "GA"
  },
  {
    "zip": 30259,
    "lat": 33.26422,
    "lng": -84.74014,
    "state_id": "GA"
  },
  {
    "zip": 30260,
    "lat": 33.5849,
    "lng": -84.32918,
    "state_id": "GA"
  },
  {
    "zip": 30263,
    "lat": 33.38871,
    "lng": -84.85806,
    "state_id": "GA"
  },
  {
    "zip": 30265,
    "lat": 33.4179,
    "lng": -84.7067,
    "state_id": "GA"
  },
  {
    "zip": 30268,
    "lat": 33.54264,
    "lng": -84.72499,
    "state_id": "GA"
  },
  {
    "zip": 30269,
    "lat": 33.39449,
    "lng": -84.57082,
    "state_id": "GA"
  },
  {
    "zip": 30273,
    "lat": 33.58355,
    "lng": -84.27206,
    "state_id": "GA"
  },
  {
    "zip": 30274,
    "lat": 33.55372,
    "lng": -84.40063,
    "state_id": "GA"
  },
  {
    "zip": 30275,
    "lat": 33.43688,
    "lng": -84.87389,
    "state_id": "GA"
  },
  {
    "zip": 30276,
    "lat": 33.26735,
    "lng": -84.58164,
    "state_id": "GA"
  },
  {
    "zip": 30277,
    "lat": 33.38138,
    "lng": -84.65052,
    "state_id": "GA"
  },
  {
    "zip": 30281,
    "lat": 33.56349,
    "lng": -84.19491,
    "state_id": "GA"
  },
  {
    "zip": 30284,
    "lat": 33.34227,
    "lng": -84.28945,
    "state_id": "GA"
  },
  {
    "zip": 30285,
    "lat": 32.98852,
    "lng": -84.24909,
    "state_id": "GA"
  },
  {
    "zip": 30286,
    "lat": 32.87908,
    "lng": -84.33081,
    "state_id": "GA"
  },
  {
    "zip": 30288,
    "lat": 33.65546,
    "lng": -84.32495,
    "state_id": "GA"
  },
  {
    "zip": 30289,
    "lat": 33.3253,
    "lng": -84.63696,
    "state_id": "GA"
  },
  {
    "zip": 30290,
    "lat": 33.47743,
    "lng": -84.58795,
    "state_id": "GA"
  },
  {
    "zip": 30291,
    "lat": 33.57454,
    "lng": -84.54187,
    "state_id": "GA"
  },
  {
    "zip": 30292,
    "lat": 33.17119,
    "lng": -84.39456,
    "state_id": "GA"
  },
  {
    "zip": 30293,
    "lat": 32.97425,
    "lng": -84.60175,
    "state_id": "GA"
  },
  {
    "zip": 30294,
    "lat": 33.63633,
    "lng": -84.26377,
    "state_id": "GA"
  },
  {
    "zip": 30295,
    "lat": 33.09456,
    "lng": -84.31264,
    "state_id": "GA"
  },
  {
    "zip": 30296,
    "lat": 33.56368,
    "lng": -84.44297,
    "state_id": "GA"
  },
  {
    "zip": 30297,
    "lat": 33.61565,
    "lng": -84.37185,
    "state_id": "GA"
  },
  {
    "zip": 30303,
    "lat": 33.75332,
    "lng": -84.38986,
    "state_id": "GA"
  },
  {
    "zip": 30305,
    "lat": 33.83564,
    "lng": -84.38892,
    "state_id": "GA"
  },
  {
    "zip": 30306,
    "lat": 33.7887,
    "lng": -84.35026,
    "state_id": "GA"
  },
  {
    "zip": 30307,
    "lat": 33.7707,
    "lng": -84.33398,
    "state_id": "GA"
  },
  {
    "zip": 30308,
    "lat": 33.77121,
    "lng": -84.3781,
    "state_id": "GA"
  },
  {
    "zip": 30309,
    "lat": 33.79758,
    "lng": -84.38663,
    "state_id": "GA"
  },
  {
    "zip": 30310,
    "lat": 33.7266,
    "lng": -84.42689,
    "state_id": "GA"
  },
  {
    "zip": 30311,
    "lat": 33.72336,
    "lng": -84.47479,
    "state_id": "GA"
  },
  {
    "zip": 30312,
    "lat": 33.74482,
    "lng": -84.3751,
    "state_id": "GA"
  },
  {
    "zip": 30313,
    "lat": 33.76448,
    "lng": -84.39728,
    "state_id": "GA"
  },
  {
    "zip": 30314,
    "lat": 33.7575,
    "lng": -84.43192,
    "state_id": "GA"
  },
  {
    "zip": 30315,
    "lat": 33.70295,
    "lng": -84.38246,
    "state_id": "GA"
  },
  {
    "zip": 30316,
    "lat": 33.71362,
    "lng": -84.33252,
    "state_id": "GA"
  },
  {
    "zip": 30317,
    "lat": 33.74917,
    "lng": -84.31532,
    "state_id": "GA"
  },
  {
    "zip": 30318,
    "lat": 33.79236,
    "lng": -84.4479,
    "state_id": "GA"
  },
  {
    "zip": 30319,
    "lat": 33.87895,
    "lng": -84.33619,
    "state_id": "GA"
  },
  {
    "zip": 30322,
    "lat": 33.79484,
    "lng": -84.32589,
    "state_id": "GA"
  },
  {
    "zip": 30324,
    "lat": 33.81958,
    "lng": -84.35707,
    "state_id": "GA"
  },
  {
    "zip": 30326,
    "lat": 33.84941,
    "lng": -84.36392,
    "state_id": "GA"
  },
  {
    "zip": 30327,
    "lat": 33.86622,
    "lng": -84.42317,
    "state_id": "GA"
  },
  {
    "zip": 30328,
    "lat": 33.93215,
    "lng": -84.38577,
    "state_id": "GA"
  },
  {
    "zip": 30329,
    "lat": 33.827,
    "lng": -84.32295,
    "state_id": "GA"
  },
  {
    "zip": 30331,
    "lat": 33.70635,
    "lng": -84.54392,
    "state_id": "GA"
  },
  {
    "zip": 30332,
    "lat": 33.77823,
    "lng": -84.40437,
    "state_id": "GA"
  },
  {
    "zip": 30334,
    "lat": 33.74889,
    "lng": -84.38719,
    "state_id": "GA"
  },
  {
    "zip": 30336,
    "lat": 33.73788,
    "lng": -84.56646,
    "state_id": "GA"
  },
  {
    "zip": 30337,
    "lat": 33.64005,
    "lng": -84.45006,
    "state_id": "GA"
  },
  {
    "zip": 30338,
    "lat": 33.94374,
    "lng": -84.31748,
    "state_id": "GA"
  },
  {
    "zip": 30339,
    "lat": 33.87612,
    "lng": -84.46014,
    "state_id": "GA"
  },
  {
    "zip": 30340,
    "lat": 33.89783,
    "lng": -84.25088,
    "state_id": "GA"
  },
  {
    "zip": 30341,
    "lat": 33.88787,
    "lng": -84.29043,
    "state_id": "GA"
  },
  {
    "zip": 30342,
    "lat": 33.88204,
    "lng": -84.37464,
    "state_id": "GA"
  },
  {
    "zip": 30344,
    "lat": 33.67512,
    "lng": -84.46026,
    "state_id": "GA"
  },
  {
    "zip": 30345,
    "lat": 33.85098,
    "lng": -84.28323,
    "state_id": "GA"
  },
  {
    "zip": 30346,
    "lat": 33.92428,
    "lng": -84.33886,
    "state_id": "GA"
  },
  {
    "zip": 30349,
    "lat": 33.62356,
    "lng": -84.52591,
    "state_id": "GA"
  },
  {
    "zip": 30350,
    "lat": 33.97913,
    "lng": -84.33348,
    "state_id": "GA"
  },
  {
    "zip": 30354,
    "lat": 33.66066,
    "lng": -84.38707,
    "state_id": "GA"
  },
  {
    "zip": 30360,
    "lat": 33.93395,
    "lng": -84.27205,
    "state_id": "GA"
  },
  {
    "zip": 30363,
    "lat": 33.79099,
    "lng": -84.39918,
    "state_id": "GA"
  },
  {
    "zip": 30401,
    "lat": 32.6005,
    "lng": -82.35204,
    "state_id": "GA"
  },
  {
    "zip": 30410,
    "lat": 32.18824,
    "lng": -82.51751,
    "state_id": "GA"
  },
  {
    "zip": 30411,
    "lat": 32.12818,
    "lng": -82.79817,
    "state_id": "GA"
  },
  {
    "zip": 30412,
    "lat": 32.07823,
    "lng": -82.49158,
    "state_id": "GA"
  },
  {
    "zip": 30413,
    "lat": 32.88984,
    "lng": -82.51378,
    "state_id": "GA"
  },
  {
    "zip": 30415,
    "lat": 32.32629,
    "lng": -81.60191,
    "state_id": "GA"
  },
  {
    "zip": 30417,
    "lat": 32.17323,
    "lng": -81.9202,
    "state_id": "GA"
  },
  {
    "zip": 30420,
    "lat": 32.28838,
    "lng": -82.15678,
    "state_id": "GA"
  },
  {
    "zip": 30421,
    "lat": 32.17931,
    "lng": -82.10755,
    "state_id": "GA"
  },
  {
    "zip": 30423,
    "lat": 32.1492,
    "lng": -81.83222,
    "state_id": "GA"
  },
  {
    "zip": 30425,
    "lat": 32.64652,
    "lng": -82.02119,
    "state_id": "GA"
  },
  {
    "zip": 30426,
    "lat": 33.03567,
    "lng": -81.64694,
    "state_id": "GA"
  },
  {
    "zip": 30427,
    "lat": 31.91928,
    "lng": -81.96475,
    "state_id": "GA"
  },
  {
    "zip": 30428,
    "lat": 32.21932,
    "lng": -82.71128,
    "state_id": "GA"
  },
  {
    "zip": 30429,
    "lat": 32.16275,
    "lng": -81.93754,
    "state_id": "GA"
  },
  {
    "zip": 30434,
    "lat": 33.02522,
    "lng": -82.37729,
    "state_id": "GA"
  },
  {
    "zip": 30436,
    "lat": 32.1519,
    "lng": -82.28907,
    "state_id": "GA"
  },
  {
    "zip": 30438,
    "lat": 32.16402,
    "lng": -82.01895,
    "state_id": "GA"
  },
  {
    "zip": 30439,
    "lat": 32.40569,
    "lng": -82.07648,
    "state_id": "GA"
  },
  {
    "zip": 30441,
    "lat": 32.80991,
    "lng": -82.23574,
    "state_id": "GA"
  },
  {
    "zip": 30442,
    "lat": 32.80179,
    "lng": -81.98904,
    "state_id": "GA"
  },
  {
    "zip": 30445,
    "lat": 32.16293,
    "lng": -82.58574,
    "state_id": "GA"
  },
  {
    "zip": 30446,
    "lat": 32.56752,
    "lng": -81.46858,
    "state_id": "GA"
  },
  {
    "zip": 30448,
    "lat": 32.4876,
    "lng": -82.36424,
    "state_id": "GA"
  },
  {
    "zip": 30449,
    "lat": 32.52987,
    "lng": -81.55148,
    "state_id": "GA"
  },
  {
    "zip": 30450,
    "lat": 32.56015,
    "lng": -81.93384,
    "state_id": "GA"
  },
  {
    "zip": 30451,
    "lat": 32.39069,
    "lng": -81.95219,
    "state_id": "GA"
  },
  {
    "zip": 30452,
    "lat": 32.32234,
    "lng": -81.89065,
    "state_id": "GA"
  },
  {
    "zip": 30453,
    "lat": 32.01786,
    "lng": -82.12389,
    "state_id": "GA"
  },
  {
    "zip": 30454,
    "lat": 32.43607,
    "lng": -82.73372,
    "state_id": "GA"
  },
  {
    "zip": 30455,
    "lat": 32.68864,
    "lng": -81.80349,
    "state_id": "GA"
  },
  {
    "zip": 30456,
    "lat": 32.97821,
    "lng": -81.80604,
    "state_id": "GA"
  },
  {
    "zip": 30457,
    "lat": 32.40041,
    "lng": -82.5676,
    "state_id": "GA"
  },
  {
    "zip": 30458,
    "lat": 32.39865,
    "lng": -81.82558,
    "state_id": "GA"
  },
  {
    "zip": 30460,
    "lat": 32.41897,
    "lng": -81.78152,
    "state_id": "GA"
  },
  {
    "zip": 30461,
    "lat": 32.50903,
    "lng": -81.72051,
    "state_id": "GA"
  },
  {
    "zip": 30464,
    "lat": 32.43499,
    "lng": -82.22188,
    "state_id": "GA"
  },
  {
    "zip": 30467,
    "lat": 32.75619,
    "lng": -81.61253,
    "state_id": "GA"
  },
  {
    "zip": 30470,
    "lat": 32.33865,
    "lng": -82.52286,
    "state_id": "GA"
  },
  {
    "zip": 30471,
    "lat": 32.54914,
    "lng": -82.17839,
    "state_id": "GA"
  },
  {
    "zip": 30473,
    "lat": 32.01598,
    "lng": -82.46821,
    "state_id": "GA"
  },
  {
    "zip": 30474,
    "lat": 32.2384,
    "lng": -82.41731,
    "state_id": "GA"
  },
  {
    "zip": 30475,
    "lat": 32.22462,
    "lng": -82.3685,
    "state_id": "GA"
  },
  {
    "zip": 30477,
    "lat": 32.8613,
    "lng": -82.40011,
    "state_id": "GA"
  },
  {
    "zip": 30501,
    "lat": 34.31855,
    "lng": -83.81615,
    "state_id": "GA"
  },
  {
    "zip": 30504,
    "lat": 34.2681,
    "lng": -83.89091,
    "state_id": "GA"
  },
  {
    "zip": 30506,
    "lat": 34.35008,
    "lng": -83.89363,
    "state_id": "GA"
  },
  {
    "zip": 30507,
    "lat": 34.26055,
    "lng": -83.76698,
    "state_id": "GA"
  },
  {
    "zip": 30510,
    "lat": 34.44178,
    "lng": -83.58277,
    "state_id": "GA"
  },
  {
    "zip": 30511,
    "lat": 34.45741,
    "lng": -83.476,
    "state_id": "GA"
  },
  {
    "zip": 30512,
    "lat": 34.85602,
    "lng": -83.97095,
    "state_id": "GA"
  },
  {
    "zip": 30513,
    "lat": 34.82426,
    "lng": -84.31999,
    "state_id": "GA"
  },
  {
    "zip": 30516,
    "lat": 34.37562,
    "lng": -83.03707,
    "state_id": "GA"
  },
  {
    "zip": 30517,
    "lat": 34.13062,
    "lng": -83.79744,
    "state_id": "GA"
  },
  {
    "zip": 30518,
    "lat": 34.13191,
    "lng": -84.02636,
    "state_id": "GA"
  },
  {
    "zip": 30519,
    "lat": 34.08798,
    "lng": -83.94118,
    "state_id": "GA"
  },
  {
    "zip": 30520,
    "lat": 34.337,
    "lng": -83.08673,
    "state_id": "GA"
  },
  {
    "zip": 30521,
    "lat": 34.36236,
    "lng": -83.29363,
    "state_id": "GA"
  },
  {
    "zip": 30522,
    "lat": 34.78333,
    "lng": -84.33475,
    "state_id": "GA"
  },
  {
    "zip": 30523,
    "lat": 34.70783,
    "lng": -83.53648,
    "state_id": "GA"
  },
  {
    "zip": 30525,
    "lat": 34.9087,
    "lng": -83.37164,
    "state_id": "GA"
  },
  {
    "zip": 30527,
    "lat": 34.47947,
    "lng": -83.78624,
    "state_id": "GA"
  },
  {
    "zip": 30528,
    "lat": 34.61549,
    "lng": -83.79492,
    "state_id": "GA"
  },
  {
    "zip": 30529,
    "lat": 34.21823,
    "lng": -83.48023,
    "state_id": "GA"
  },
  {
    "zip": 30530,
    "lat": 34.22573,
    "lng": -83.38434,
    "state_id": "GA"
  },
  {
    "zip": 30531,
    "lat": 34.51014,
    "lng": -83.59159,
    "state_id": "GA"
  },
  {
    "zip": 30533,
    "lat": 34.5641,
    "lng": -84.01541,
    "state_id": "GA"
  },
  {
    "zip": 30534,
    "lat": 34.43887,
    "lng": -84.15074,
    "state_id": "GA"
  },
  {
    "zip": 30535,
    "lat": 34.58065,
    "lng": -83.57703,
    "state_id": "GA"
  },
  {
    "zip": 30536,
    "lat": 34.65505,
    "lng": -84.35535,
    "state_id": "GA"
  },
  {
    "zip": 30537,
    "lat": 34.97779,
    "lng": -83.32001,
    "state_id": "GA"
  },
  {
    "zip": 30538,
    "lat": 34.50406,
    "lng": -83.26611,
    "state_id": "GA"
  },
  {
    "zip": 30540,
    "lat": 34.72432,
    "lng": -84.54404,
    "state_id": "GA"
  },
  {
    "zip": 30541,
    "lat": 34.91288,
    "lng": -84.53909,
    "state_id": "GA"
  },
  {
    "zip": 30542,
    "lat": 34.17775,
    "lng": -83.90863,
    "state_id": "GA"
  },
  {
    "zip": 30543,
    "lat": 34.2953,
    "lng": -83.65043,
    "state_id": "GA"
  },
  {
    "zip": 30545,
    "lat": 34.74158,
    "lng": -83.76411,
    "state_id": "GA"
  },
  {
    "zip": 30546,
    "lat": 34.9088,
    "lng": -83.70729,
    "state_id": "GA"
  },
  {
    "zip": 30547,
    "lat": 34.36056,
    "lng": -83.46228,
    "state_id": "GA"
  },
  {
    "zip": 30548,
    "lat": 34.09051,
    "lng": -83.76653,
    "state_id": "GA"
  },
  {
    "zip": 30549,
    "lat": 34.10679,
    "lng": -83.57735,
    "state_id": "GA"
  },
  {
    "zip": 30552,
    "lat": 34.77102,
    "lng": -83.445,
    "state_id": "GA"
  },
  {
    "zip": 30553,
    "lat": 34.4433,
    "lng": -83.08604,
    "state_id": "GA"
  },
  {
    "zip": 30554,
    "lat": 34.3994,
    "lng": -83.65901,
    "state_id": "GA"
  },
  {
    "zip": 30555,
    "lat": 34.97393,
    "lng": -84.42919,
    "state_id": "GA"
  },
  {
    "zip": 30557,
    "lat": 34.48072,
    "lng": -83.18391,
    "state_id": "GA"
  },
  {
    "zip": 30558,
    "lat": 34.27126,
    "lng": -83.56447,
    "state_id": "GA"
  },
  {
    "zip": 30559,
    "lat": 34.95247,
    "lng": -84.26676,
    "state_id": "GA"
  },
  {
    "zip": 30560,
    "lat": 34.87976,
    "lng": -84.19489,
    "state_id": "GA"
  },
  {
    "zip": 30562,
    "lat": 34.92155,
    "lng": -83.37748,
    "state_id": "GA"
  },
  {
    "zip": 30563,
    "lat": 34.56349,
    "lng": -83.4625,
    "state_id": "GA"
  },
  {
    "zip": 30564,
    "lat": 34.47048,
    "lng": -83.88621,
    "state_id": "GA"
  },
  {
    "zip": 30565,
    "lat": 34.08688,
    "lng": -83.41115,
    "state_id": "GA"
  },
  {
    "zip": 30566,
    "lat": 34.23577,
    "lng": -83.89496,
    "state_id": "GA"
  },
  {
    "zip": 30567,
    "lat": 34.18361,
    "lng": -83.675,
    "state_id": "GA"
  },
  {
    "zip": 30568,
    "lat": 34.9551,
    "lng": -83.4155,
    "state_id": "GA"
  },
  {
    "zip": 30571,
    "lat": 34.70873,
    "lng": -83.6894,
    "state_id": "GA"
  },
  {
    "zip": 30572,
    "lat": 34.73543,
    "lng": -84.07325,
    "state_id": "GA"
  },
  {
    "zip": 30573,
    "lat": 34.74169,
    "lng": -83.41344,
    "state_id": "GA"
  },
  {
    "zip": 30575,
    "lat": 34.20384,
    "lng": -83.71601,
    "state_id": "GA"
  },
  {
    "zip": 30576,
    "lat": 34.82766,
    "lng": -83.44916,
    "state_id": "GA"
  },
  {
    "zip": 30577,
    "lat": 34.55411,
    "lng": -83.32464,
    "state_id": "GA"
  },
  {
    "zip": 30581,
    "lat": 34.79651,
    "lng": -83.41658,
    "state_id": "GA"
  },
  {
    "zip": 30582,
    "lat": 34.95105,
    "lng": -83.90314,
    "state_id": "GA"
  },
  {
    "zip": 30601,
    "lat": 34.00087,
    "lng": -83.34851,
    "state_id": "GA"
  },
  {
    "zip": 30602,
    "lat": 33.94322,
    "lng": -83.37237,
    "state_id": "GA"
  },
  {
    "zip": 30605,
    "lat": 33.90656,
    "lng": -83.32327,
    "state_id": "GA"
  },
  {
    "zip": 30606,
    "lat": 33.93961,
    "lng": -83.42878,
    "state_id": "GA"
  },
  {
    "zip": 30607,
    "lat": 34.02012,
    "lng": -83.44594,
    "state_id": "GA"
  },
  {
    "zip": 30609,
    "lat": 33.94946,
    "lng": -83.38201,
    "state_id": "GA"
  },
  {
    "zip": 30619,
    "lat": 33.85751,
    "lng": -83.24105,
    "state_id": "GA"
  },
  {
    "zip": 30620,
    "lat": 33.92906,
    "lng": -83.7573,
    "state_id": "GA"
  },
  {
    "zip": 30621,
    "lat": 33.80441,
    "lng": -83.48398,
    "state_id": "GA"
  },
  {
    "zip": 30622,
    "lat": 33.92206,
    "lng": -83.5198,
    "state_id": "GA"
  },
  {
    "zip": 30623,
    "lat": 33.73016,
    "lng": -83.53609,
    "state_id": "GA"
  },
  {
    "zip": 30624,
    "lat": 34.18721,
    "lng": -83.04685,
    "state_id": "GA"
  },
  {
    "zip": 30625,
    "lat": 33.51949,
    "lng": -83.33776,
    "state_id": "GA"
  },
  {
    "zip": 30627,
    "lat": 33.9755,
    "lng": -82.96071,
    "state_id": "GA"
  },
  {
    "zip": 30628,
    "lat": 34.02972,
    "lng": -83.21263,
    "state_id": "GA"
  },
  {
    "zip": 30629,
    "lat": 34.079,
    "lng": -83.1171,
    "state_id": "GA"
  },
  {
    "zip": 30630,
    "lat": 33.91149,
    "lng": -83.15293,
    "state_id": "GA"
  },
  {
    "zip": 30631,
    "lat": 33.5678,
    "lng": -82.87594,
    "state_id": "GA"
  },
  {
    "zip": 30633,
    "lat": 34.17834,
    "lng": -83.2471,
    "state_id": "GA"
  },
  {
    "zip": 30634,
    "lat": 34.20117,
    "lng": -82.94936,
    "state_id": "GA"
  },
  {
    "zip": 30635,
    "lat": 34.10316,
    "lng": -82.80444,
    "state_id": "GA"
  },
  {
    "zip": 30641,
    "lat": 33.77766,
    "lng": -83.56974,
    "state_id": "GA"
  },
  {
    "zip": 30642,
    "lat": 33.55439,
    "lng": -83.19344,
    "state_id": "GA"
  },
  {
    "zip": 30643,
    "lat": 34.36261,
    "lng": -82.9088,
    "state_id": "GA"
  },
  {
    "zip": 30646,
    "lat": 34.07828,
    "lng": -83.31139,
    "state_id": "GA"
  },
  {
    "zip": 30648,
    "lat": 33.88224,
    "lng": -83.04896,
    "state_id": "GA"
  },
  {
    "zip": 30650,
    "lat": 33.58404,
    "lng": -83.47196,
    "state_id": "GA"
  },
  {
    "zip": 30655,
    "lat": 33.77841,
    "lng": -83.69796,
    "state_id": "GA"
  },
  {
    "zip": 30656,
    "lat": 33.86079,
    "lng": -83.71987,
    "state_id": "GA"
  },
  {
    "zip": 30660,
    "lat": 33.78562,
    "lng": -82.95044,
    "state_id": "GA"
  },
  {
    "zip": 30662,
    "lat": 34.26569,
    "lng": -83.14789,
    "state_id": "GA"
  },
  {
    "zip": 30663,
    "lat": 33.61588,
    "lng": -83.5987,
    "state_id": "GA"
  },
  {
    "zip": 30664,
    "lat": 33.55821,
    "lng": -82.79952,
    "state_id": "GA"
  },
  {
    "zip": 30665,
    "lat": 33.53984,
    "lng": -83.05665,
    "state_id": "GA"
  },
  {
    "zip": 30666,
    "lat": 33.96001,
    "lng": -83.58398,
    "state_id": "GA"
  },
  {
    "zip": 30667,
    "lat": 33.78069,
    "lng": -83.15615,
    "state_id": "GA"
  },
  {
    "zip": 30668,
    "lat": 33.90156,
    "lng": -82.70052,
    "state_id": "GA"
  },
  {
    "zip": 30669,
    "lat": 33.674,
    "lng": -83.11498,
    "state_id": "GA"
  },
  {
    "zip": 30673,
    "lat": 33.7226,
    "lng": -82.71737,
    "state_id": "GA"
  },
  {
    "zip": 30677,
    "lat": 33.79139,
    "lng": -83.38246,
    "state_id": "GA"
  },
  {
    "zip": 30678,
    "lat": 33.45203,
    "lng": -83.0743,
    "state_id": "GA"
  },
  {
    "zip": 30680,
    "lat": 33.99623,
    "lng": -83.69949,
    "state_id": "GA"
  },
  {
    "zip": 30683,
    "lat": 33.94463,
    "lng": -83.2573,
    "state_id": "GA"
  },
  {
    "zip": 30701,
    "lat": 34.49658,
    "lng": -84.9557,
    "state_id": "GA"
  },
  {
    "zip": 30705,
    "lat": 34.75017,
    "lng": -84.74898,
    "state_id": "GA"
  },
  {
    "zip": 30707,
    "lat": 34.77638,
    "lng": -85.36037,
    "state_id": "GA"
  },
  {
    "zip": 30708,
    "lat": 34.96174,
    "lng": -84.6646,
    "state_id": "GA"
  },
  {
    "zip": 30710,
    "lat": 34.95059,
    "lng": -84.91746,
    "state_id": "GA"
  },
  {
    "zip": 30711,
    "lat": 34.92761,
    "lng": -84.73534,
    "state_id": "GA"
  },
  {
    "zip": 30720,
    "lat": 34.73465,
    "lng": -85.0029,
    "state_id": "GA"
  },
  {
    "zip": 30721,
    "lat": 34.79162,
    "lng": -84.91407,
    "state_id": "GA"
  },
  {
    "zip": 30725,
    "lat": 34.92434,
    "lng": -85.35313,
    "state_id": "GA"
  },
  {
    "zip": 30726,
    "lat": 34.97625,
    "lng": -85.13959,
    "state_id": "GA"
  },
  {
    "zip": 30728,
    "lat": 34.67871,
    "lng": -85.22469,
    "state_id": "GA"
  },
  {
    "zip": 30730,
    "lat": 34.35946,
    "lng": -85.42125,
    "state_id": "GA"
  },
  {
    "zip": 30731,
    "lat": 34.58639,
    "lng": -85.47741,
    "state_id": "GA"
  },
  {
    "zip": 30733,
    "lat": 34.41611,
    "lng": -85.04399,
    "state_id": "GA"
  },
  {
    "zip": 30734,
    "lat": 34.53315,
    "lng": -84.71441,
    "state_id": "GA"
  },
  {
    "zip": 30735,
    "lat": 34.60554,
    "lng": -84.88147,
    "state_id": "GA"
  },
  {
    "zip": 30736,
    "lat": 34.90444,
    "lng": -85.13622,
    "state_id": "GA"
  },
  {
    "zip": 30738,
    "lat": 34.79667,
    "lng": -85.47852,
    "state_id": "GA"
  },
  {
    "zip": 30739,
    "lat": 34.80455,
    "lng": -85.21562,
    "state_id": "GA"
  },
  {
    "zip": 30740,
    "lat": 34.75562,
    "lng": -85.07831,
    "state_id": "GA"
  },
  {
    "zip": 30741,
    "lat": 34.95231,
    "lng": -85.28251,
    "state_id": "GA"
  },
  {
    "zip": 30742,
    "lat": 34.95061,
    "lng": -85.24324,
    "state_id": "GA"
  },
  {
    "zip": 30746,
    "lat": 34.57709,
    "lng": -85.02728,
    "state_id": "GA"
  },
  {
    "zip": 30747,
    "lat": 34.4989,
    "lng": -85.29877,
    "state_id": "GA"
  },
  {
    "zip": 30750,
    "lat": 34.94144,
    "lng": -85.3882,
    "state_id": "GA"
  },
  {
    "zip": 30751,
    "lat": 34.98214,
    "lng": -84.73756,
    "state_id": "GA"
  },
  {
    "zip": 30752,
    "lat": 34.91035,
    "lng": -85.52908,
    "state_id": "GA"
  },
  {
    "zip": 30753,
    "lat": 34.58316,
    "lng": -85.28595,
    "state_id": "GA"
  },
  {
    "zip": 30755,
    "lat": 34.86684,
    "lng": -85.04077,
    "state_id": "GA"
  },
  {
    "zip": 30756,
    "lat": 34.89768,
    "lng": -84.97717,
    "state_id": "GA"
  },
  {
    "zip": 30757,
    "lat": 34.94196,
    "lng": -85.43513,
    "state_id": "GA"
  },
  {
    "zip": 30802,
    "lat": 33.60173,
    "lng": -82.31813,
    "state_id": "GA"
  },
  {
    "zip": 30803,
    "lat": 33.13784,
    "lng": -82.55288,
    "state_id": "GA"
  },
  {
    "zip": 30805,
    "lat": 33.2768,
    "lng": -82.18508,
    "state_id": "GA"
  },
  {
    "zip": 30807,
    "lat": 33.45671,
    "lng": -82.64821,
    "state_id": "GA"
  },
  {
    "zip": 30808,
    "lat": 33.38342,
    "lng": -82.39349,
    "state_id": "GA"
  },
  {
    "zip": 30809,
    "lat": 33.55541,
    "lng": -82.16441,
    "state_id": "GA"
  },
  {
    "zip": 30810,
    "lat": 33.23621,
    "lng": -82.58915,
    "state_id": "GA"
  },
  {
    "zip": 30812,
    "lat": 33.36723,
    "lng": -82.02857,
    "state_id": "GA"
  },
  {
    "zip": 30813,
    "lat": 33.47139,
    "lng": -82.22091,
    "state_id": "GA"
  },
  {
    "zip": 30814,
    "lat": 33.43567,
    "lng": -82.31151,
    "state_id": "GA"
  },
  {
    "zip": 30815,
    "lat": 33.28734,
    "lng": -82.08933,
    "state_id": "GA"
  },
  {
    "zip": 30816,
    "lat": 33.15362,
    "lng": -82.16093,
    "state_id": "GA"
  },
  {
    "zip": 30817,
    "lat": 33.77381,
    "lng": -82.43596,
    "state_id": "GA"
  },
  {
    "zip": 30818,
    "lat": 33.26518,
    "lng": -82.32936,
    "state_id": "GA"
  },
  {
    "zip": 30820,
    "lat": 33.22053,
    "lng": -82.71107,
    "state_id": "GA"
  },
  {
    "zip": 30821,
    "lat": 33.4837,
    "lng": -82.7352,
    "state_id": "GA"
  },
  {
    "zip": 30822,
    "lat": 32.91289,
    "lng": -81.8538,
    "state_id": "GA"
  },
  {
    "zip": 30823,
    "lat": 33.22357,
    "lng": -82.45541,
    "state_id": "GA"
  },
  {
    "zip": 30824,
    "lat": 33.51609,
    "lng": -82.51854,
    "state_id": "GA"
  },
  {
    "zip": 30828,
    "lat": 33.37218,
    "lng": -82.65529,
    "state_id": "GA"
  },
  {
    "zip": 30830,
    "lat": 33.08044,
    "lng": -81.97339,
    "state_id": "GA"
  },
  {
    "zip": 30833,
    "lat": 33.18485,
    "lng": -82.35925,
    "state_id": "GA"
  },
  {
    "zip": 30901,
    "lat": 33.43649,
    "lng": -81.95697,
    "state_id": "GA"
  },
  {
    "zip": 30903,
    "lat": 33.49014,
    "lng": -82.16265,
    "state_id": "GA"
  },
  {
    "zip": 30904,
    "lat": 33.4775,
    "lng": -82.01281,
    "state_id": "GA"
  },
  {
    "zip": 30905,
    "lat": 33.41474,
    "lng": -82.14267,
    "state_id": "GA"
  },
  {
    "zip": 30906,
    "lat": 33.34496,
    "lng": -81.97238,
    "state_id": "GA"
  },
  {
    "zip": 30907,
    "lat": 33.52294,
    "lng": -82.08527,
    "state_id": "GA"
  },
  {
    "zip": 30909,
    "lat": 33.4717,
    "lng": -82.0834,
    "state_id": "GA"
  },
  {
    "zip": 30912,
    "lat": 33.47056,
    "lng": -81.98797,
    "state_id": "GA"
  },
  {
    "zip": 31001,
    "lat": 31.97547,
    "lng": -83.34065,
    "state_id": "GA"
  },
  {
    "zip": 31002,
    "lat": 32.54863,
    "lng": -82.5829,
    "state_id": "GA"
  },
  {
    "zip": 31003,
    "lat": 32.60626,
    "lng": -83.21248,
    "state_id": "GA"
  },
  {
    "zip": 31005,
    "lat": 32.5444,
    "lng": -83.59733,
    "state_id": "GA"
  },
  {
    "zip": 31006,
    "lat": 32.58493,
    "lng": -84.25478,
    "state_id": "GA"
  },
  {
    "zip": 31007,
    "lat": 32.18894,
    "lng": -83.93315,
    "state_id": "GA"
  },
  {
    "zip": 31008,
    "lat": 32.65603,
    "lng": -83.78253,
    "state_id": "GA"
  },
  {
    "zip": 31009,
    "lat": 32.27683,
    "lng": -83.00932,
    "state_id": "GA"
  },
  {
    "zip": 31011,
    "lat": 32.12692,
    "lng": -83.06217,
    "state_id": "GA"
  },
  {
    "zip": 31012,
    "lat": 32.39465,
    "lng": -83.17562,
    "state_id": "GA"
  },
  {
    "zip": 31014,
    "lat": 32.41366,
    "lng": -83.34473,
    "state_id": "GA"
  },
  {
    "zip": 31015,
    "lat": 31.93552,
    "lng": -83.7753,
    "state_id": "GA"
  },
  {
    "zip": 31016,
    "lat": 32.84316,
    "lng": -84.11459,
    "state_id": "GA"
  },
  {
    "zip": 31017,
    "lat": 32.61857,
    "lng": -83.23188,
    "state_id": "GA"
  },
  {
    "zip": 31018,
    "lat": 32.97651,
    "lng": -82.62065,
    "state_id": "GA"
  },
  {
    "zip": 31019,
    "lat": 32.42637,
    "lng": -83.04998,
    "state_id": "GA"
  },
  {
    "zip": 31020,
    "lat": 32.69685,
    "lng": -83.50475,
    "state_id": "GA"
  },
  {
    "zip": 31021,
    "lat": 32.49306,
    "lng": -82.93909,
    "state_id": "GA"
  },
  {
    "zip": 31022,
    "lat": 32.50649,
    "lng": -83.1098,
    "state_id": "GA"
  },
  {
    "zip": 31023,
    "lat": 32.18401,
    "lng": -83.20049,
    "state_id": "GA"
  },
  {
    "zip": 31024,
    "lat": 33.32052,
    "lng": -83.37448,
    "state_id": "GA"
  },
  {
    "zip": 31025,
    "lat": 32.34007,
    "lng": -83.74578,
    "state_id": "GA"
  },
  {
    "zip": 31027,
    "lat": 32.57712,
    "lng": -82.81139,
    "state_id": "GA"
  },
  {
    "zip": 31028,
    "lat": 32.63503,
    "lng": -83.68156,
    "state_id": "GA"
  },
  {
    "zip": 31029,
    "lat": 33.03734,
    "lng": -83.92709,
    "state_id": "GA"
  },
  {
    "zip": 31030,
    "lat": 32.57032,
    "lng": -83.89041,
    "state_id": "GA"
  },
  {
    "zip": 31031,
    "lat": 32.87127,
    "lng": -83.32837,
    "state_id": "GA"
  },
  {
    "zip": 31032,
    "lat": 33.01268,
    "lng": -83.56911,
    "state_id": "GA"
  },
  {
    "zip": 31033,
    "lat": 33.08095,
    "lng": -83.44181,
    "state_id": "GA"
  },
  {
    "zip": 31035,
    "lat": 32.84606,
    "lng": -82.70309,
    "state_id": "GA"
  },
  {
    "zip": 31036,
    "lat": 32.27251,
    "lng": -83.50385,
    "state_id": "GA"
  },
  {
    "zip": 31037,
    "lat": 32.11228,
    "lng": -82.92721,
    "state_id": "GA"
  },
  {
    "zip": 31038,
    "lat": 33.14208,
    "lng": -83.64039,
    "state_id": "GA"
  },
  {
    "zip": 31039,
    "lat": 32.59551,
    "lng": -84.38528,
    "state_id": "GA"
  },
  {
    "zip": 31041,
    "lat": 32.37132,
    "lng": -84.17653,
    "state_id": "GA"
  },
  {
    "zip": 31042,
    "lat": 32.7466,
    "lng": -83.17207,
    "state_id": "GA"
  },
  {
    "zip": 31044,
    "lat": 32.63885,
    "lng": -83.39336,
    "state_id": "GA"
  },
  {
    "zip": 31045,
    "lat": 33.28247,
    "lng": -82.78307,
    "state_id": "GA"
  },
  {
    "zip": 31046,
    "lat": 33.05619,
    "lng": -83.77853,
    "state_id": "GA"
  },
  {
    "zip": 31047,
    "lat": 32.47366,
    "lng": -83.583,
    "state_id": "GA"
  },
  {
    "zip": 31049,
    "lat": 32.68759,
    "lng": -82.52366,
    "state_id": "GA"
  },
  {
    "zip": 31050,
    "lat": 32.73728,
    "lng": -83.94247,
    "state_id": "GA"
  },
  {
    "zip": 31051,
    "lat": 32.15581,
    "lng": -83.88845,
    "state_id": "GA"
  },
  {
    "zip": 31052,
    "lat": 32.77312,
    "lng": -83.84471,
    "state_id": "GA"
  },
  {
    "zip": 31054,
    "lat": 32.88424,
    "lng": -83.20281,
    "state_id": "GA"
  },
  {
    "zip": 31055,
    "lat": 31.98879,
    "lng": -82.89112,
    "state_id": "GA"
  },
  {
    "zip": 31057,
    "lat": 32.43286,
    "lng": -83.93537,
    "state_id": "GA"
  },
  {
    "zip": 31058,
    "lat": 32.49214,
    "lng": -84.41613,
    "state_id": "GA"
  },
  {
    "zip": 31060,
    "lat": 31.97572,
    "lng": -83.06083,
    "state_id": "GA"
  },
  {
    "zip": 31061,
    "lat": 33.07616,
    "lng": -83.24655,
    "state_id": "GA"
  },
  {
    "zip": 31062,
    "lat": 33.04918,
    "lng": -83.21747,
    "state_id": "GA"
  },
  {
    "zip": 31063,
    "lat": 32.28977,
    "lng": -83.96823,
    "state_id": "GA"
  },
  {
    "zip": 31064,
    "lat": 33.28906,
    "lng": -83.69711,
    "state_id": "GA"
  },
  {
    "zip": 31065,
    "lat": 32.55324,
    "lng": -83.16922,
    "state_id": "GA"
  },
  {
    "zip": 31066,
    "lat": 32.80329,
    "lng": -84.01002,
    "state_id": "GA"
  },
  {
    "zip": 31067,
    "lat": 32.86007,
    "lng": -82.93698,
    "state_id": "GA"
  },
  {
    "zip": 31068,
    "lat": 32.32624,
    "lng": -84.10771,
    "state_id": "GA"
  },
  {
    "zip": 31069,
    "lat": 32.44104,
    "lng": -83.74346,
    "state_id": "GA"
  },
  {
    "zip": 31070,
    "lat": 32.18968,
    "lng": -83.78403,
    "state_id": "GA"
  },
  {
    "zip": 31071,
    "lat": 32.12972,
    "lng": -83.52946,
    "state_id": "GA"
  },
  {
    "zip": 31072,
    "lat": 31.96604,
    "lng": -83.56352,
    "state_id": "GA"
  },
  {
    "zip": 31075,
    "lat": 32.35102,
    "lng": -82.95552,
    "state_id": "GA"
  },
  {
    "zip": 31076,
    "lat": 32.54556,
    "lng": -84.1053,
    "state_id": "GA"
  },
  {
    "zip": 31077,
    "lat": 31.93796,
    "lng": -83.18365,
    "state_id": "GA"
  },
  {
    "zip": 31078,
    "lat": 32.6995,
    "lng": -84.06613,
    "state_id": "GA"
  },
  {
    "zip": 31079,
    "lat": 31.94087,
    "lng": -83.46037,
    "state_id": "GA"
  },
  {
    "zip": 31081,
    "lat": 32.41458,
    "lng": -84.27963,
    "state_id": "GA"
  },
  {
    "zip": 31082,
    "lat": 32.99243,
    "lng": -82.8968,
    "state_id": "GA"
  },
  {
    "zip": 31083,
    "lat": 32.03945,
    "lng": -82.81023,
    "state_id": "GA"
  },
  {
    "zip": 31084,
    "lat": 31.963,
    "lng": -83.60556,
    "state_id": "GA"
  },
  {
    "zip": 31085,
    "lat": 33.42569,
    "lng": -83.62901,
    "state_id": "GA"
  },
  {
    "zip": 31087,
    "lat": 33.26371,
    "lng": -83.00242,
    "state_id": "GA"
  },
  {
    "zip": 31088,
    "lat": 32.58286,
    "lng": -83.64841,
    "state_id": "GA"
  },
  {
    "zip": 31089,
    "lat": 32.85289,
    "lng": -82.86376,
    "state_id": "GA"
  },
  {
    "zip": 31090,
    "lat": 32.82654,
    "lng": -83.06214,
    "state_id": "GA"
  },
  {
    "zip": 31091,
    "lat": 32.2409,
    "lng": -83.70131,
    "state_id": "GA"
  },
  {
    "zip": 31092,
    "lat": 32.09069,
    "lng": -83.78572,
    "state_id": "GA"
  },
  {
    "zip": 31093,
    "lat": 32.64767,
    "lng": -83.65653,
    "state_id": "GA"
  },
  {
    "zip": 31094,
    "lat": 33.11179,
    "lng": -82.79398,
    "state_id": "GA"
  },
  {
    "zip": 31096,
    "lat": 32.72233,
    "lng": -82.70517,
    "state_id": "GA"
  },
  {
    "zip": 31097,
    "lat": 32.89556,
    "lng": -84.15821,
    "state_id": "GA"
  },
  {
    "zip": 31098,
    "lat": 32.61808,
    "lng": -83.57386,
    "state_id": "GA"
  },
  {
    "zip": 31201,
    "lat": 32.80946,
    "lng": -83.61684,
    "state_id": "GA"
  },
  {
    "zip": 31204,
    "lat": 32.85012,
    "lng": -83.67584,
    "state_id": "GA"
  },
  {
    "zip": 31206,
    "lat": 32.79141,
    "lng": -83.67896,
    "state_id": "GA"
  },
  {
    "zip": 31207,
    "lat": 32.82846,
    "lng": -83.64908,
    "state_id": "GA"
  },
  {
    "zip": 31210,
    "lat": 32.90485,
    "lng": -83.73572,
    "state_id": "GA"
  },
  {
    "zip": 31211,
    "lat": 32.91046,
    "lng": -83.60606,
    "state_id": "GA"
  },
  {
    "zip": 31213,
    "lat": 32.84005,
    "lng": -83.63937,
    "state_id": "GA"
  },
  {
    "zip": 31216,
    "lat": 32.72777,
    "lng": -83.6835,
    "state_id": "GA"
  },
  {
    "zip": 31217,
    "lat": 32.84493,
    "lng": -83.50634,
    "state_id": "GA"
  },
  {
    "zip": 31220,
    "lat": 32.8778,
    "lng": -83.82199,
    "state_id": "GA"
  },
  {
    "zip": 31301,
    "lat": 31.75285,
    "lng": -81.60849,
    "state_id": "GA"
  },
  {
    "zip": 31302,
    "lat": 32.12245,
    "lng": -81.34075,
    "state_id": "GA"
  },
  {
    "zip": 31303,
    "lat": 32.49982,
    "lng": -81.30964,
    "state_id": "GA"
  },
  {
    "zip": 31304,
    "lat": 31.50906,
    "lng": -81.35236,
    "state_id": "GA"
  },
  {
    "zip": 31305,
    "lat": 31.39907,
    "lng": -81.3918,
    "state_id": "GA"
  },
  {
    "zip": 31307,
    "lat": 32.17361,
    "lng": -81.39918,
    "state_id": "GA"
  },
  {
    "zip": 31308,
    "lat": 32.17485,
    "lng": -81.48045,
    "state_id": "GA"
  },
  {
    "zip": 31309,
    "lat": 31.88144,
    "lng": -81.43441,
    "state_id": "GA"
  },
  {
    "zip": 31312,
    "lat": 32.31022,
    "lng": -81.39869,
    "state_id": "GA"
  },
  {
    "zip": 31313,
    "lat": 31.83794,
    "lng": -81.60992,
    "state_id": "GA"
  },
  {
    "zip": 31314,
    "lat": 31.87008,
    "lng": -81.63189,
    "state_id": "GA"
  },
  {
    "zip": 31315,
    "lat": 31.89382,
    "lng": -81.59015,
    "state_id": "GA"
  },
  {
    "zip": 31316,
    "lat": 31.71717,
    "lng": -81.72851,
    "state_id": "GA"
  },
  {
    "zip": 31318,
    "lat": 32.14203,
    "lng": -81.37192,
    "state_id": "GA"
  },
  {
    "zip": 31320,
    "lat": 31.76809,
    "lng": -81.36617,
    "state_id": "GA"
  },
  {
    "zip": 31321,
    "lat": 32.18121,
    "lng": -81.66567,
    "state_id": "GA"
  },
  {
    "zip": 31322,
    "lat": 32.10966,
    "lng": -81.25388,
    "state_id": "GA"
  },
  {
    "zip": 31323,
    "lat": 31.69565,
    "lng": -81.42973,
    "state_id": "GA"
  },
  {
    "zip": 31324,
    "lat": 31.85578,
    "lng": -81.26875,
    "state_id": "GA"
  },
  {
    "zip": 31326,
    "lat": 32.296,
    "lng": -81.22975,
    "state_id": "GA"
  },
  {
    "zip": 31327,
    "lat": 31.46187,
    "lng": -81.25787,
    "state_id": "GA"
  },
  {
    "zip": 31328,
    "lat": 32.00709,
    "lng": -80.8683,
    "state_id": "GA"
  },
  {
    "zip": 31329,
    "lat": 32.42312,
    "lng": -81.34866,
    "state_id": "GA"
  },
  {
    "zip": 31331,
    "lat": 31.52645,
    "lng": -81.43706,
    "state_id": "GA"
  },
  {
    "zip": 31401,
    "lat": 32.07468,
    "lng": -81.08892,
    "state_id": "GA"
  },
  {
    "zip": 31404,
    "lat": 32.05398,
    "lng": -81.04986,
    "state_id": "GA"
  },
  {
    "zip": 31405,
    "lat": 32.03949,
    "lng": -81.17255,
    "state_id": "GA"
  },
  {
    "zip": 31406,
    "lat": 31.97795,
    "lng": -81.08487,
    "state_id": "GA"
  },
  {
    "zip": 31407,
    "lat": 32.1857,
    "lng": -81.19257,
    "state_id": "GA"
  },
  {
    "zip": 31408,
    "lat": 32.11542,
    "lng": -81.18413,
    "state_id": "GA"
  },
  {
    "zip": 31409,
    "lat": 32.00938,
    "lng": -81.15692,
    "state_id": "GA"
  },
  {
    "zip": 31410,
    "lat": 32.0244,
    "lng": -80.993,
    "state_id": "GA"
  },
  {
    "zip": 31411,
    "lat": 31.94097,
    "lng": -81.03654,
    "state_id": "GA"
  },
  {
    "zip": 31415,
    "lat": 32.0786,
    "lng": -81.12582,
    "state_id": "GA"
  },
  {
    "zip": 31419,
    "lat": 31.99574,
    "lng": -81.23582,
    "state_id": "GA"
  },
  {
    "zip": 31501,
    "lat": 31.22438,
    "lng": -82.35061,
    "state_id": "GA"
  },
  {
    "zip": 31503,
    "lat": 31.19789,
    "lng": -82.41346,
    "state_id": "GA"
  },
  {
    "zip": 31510,
    "lat": 31.54582,
    "lng": -82.44053,
    "state_id": "GA"
  },
  {
    "zip": 31512,
    "lat": 31.54332,
    "lng": -83.01451,
    "state_id": "GA"
  },
  {
    "zip": 31513,
    "lat": 31.77745,
    "lng": -82.34738,
    "state_id": "GA"
  },
  {
    "zip": 31516,
    "lat": 31.30995,
    "lng": -82.25525,
    "state_id": "GA"
  },
  {
    "zip": 31518,
    "lat": 31.5147,
    "lng": -82.18282,
    "state_id": "GA"
  },
  {
    "zip": 31519,
    "lat": 31.6816,
    "lng": -82.87975,
    "state_id": "GA"
  },
  {
    "zip": 31520,
    "lat": 31.18071,
    "lng": -81.49482,
    "state_id": "GA"
  },
  {
    "zip": 31522,
    "lat": 31.23311,
    "lng": -81.3579,
    "state_id": "GA"
  },
  {
    "zip": 31523,
    "lat": 31.22042,
    "lng": -81.62655,
    "state_id": "GA"
  },
  {
    "zip": 31524,
    "lat": 31.24045,
    "lng": -81.47236,
    "state_id": "GA"
  },
  {
    "zip": 31525,
    "lat": 31.31155,
    "lng": -81.52381,
    "state_id": "GA"
  },
  {
    "zip": 31527,
    "lat": 31.07232,
    "lng": -81.44036,
    "state_id": "GA"
  },
  {
    "zip": 31532,
    "lat": 31.72255,
    "lng": -82.75263,
    "state_id": "GA"
  },
  {
    "zip": 31533,
    "lat": 31.54914,
    "lng": -82.82861,
    "state_id": "GA"
  },
  {
    "zip": 31535,
    "lat": 31.45513,
    "lng": -82.85609,
    "state_id": "GA"
  },
  {
    "zip": 31537,
    "lat": 30.89309,
    "lng": -82.00974,
    "state_id": "GA"
  },
  {
    "zip": 31539,
    "lat": 31.82649,
    "lng": -82.60805,
    "state_id": "GA"
  },
  {
    "zip": 31542,
    "lat": 31.14309,
    "lng": -82.12071,
    "state_id": "GA"
  },
  {
    "zip": 31543,
    "lat": 31.32843,
    "lng": -81.83121,
    "state_id": "GA"
  },
  {
    "zip": 31544,
    "lat": 31.83788,
    "lng": -82.95284,
    "state_id": "GA"
  },
  {
    "zip": 31545,
    "lat": 31.6523,
    "lng": -81.94185,
    "state_id": "GA"
  },
  {
    "zip": 31546,
    "lat": 31.51199,
    "lng": -81.80861,
    "state_id": "GA"
  },
  {
    "zip": 31547,
    "lat": 30.7906,
    "lng": -81.56068,
    "state_id": "GA"
  },
  {
    "zip": 31548,
    "lat": 30.79353,
    "lng": -81.698,
    "state_id": "GA"
  },
  {
    "zip": 31549,
    "lat": 31.94233,
    "lng": -82.71098,
    "state_id": "GA"
  },
  {
    "zip": 31550,
    "lat": 31.14713,
    "lng": -82.59149,
    "state_id": "GA"
  },
  {
    "zip": 31551,
    "lat": 31.49349,
    "lng": -82.2759,
    "state_id": "GA"
  },
  {
    "zip": 31552,
    "lat": 31.29476,
    "lng": -82.61267,
    "state_id": "GA"
  },
  {
    "zip": 31553,
    "lat": 31.17663,
    "lng": -81.97249,
    "state_id": "GA"
  },
  {
    "zip": 31554,
    "lat": 31.48873,
    "lng": -82.6239,
    "state_id": "GA"
  },
  {
    "zip": 31555,
    "lat": 31.70138,
    "lng": -82.07145,
    "state_id": "GA"
  },
  {
    "zip": 31556,
    "lat": 31.41385,
    "lng": -82.11163,
    "state_id": "GA"
  },
  {
    "zip": 31557,
    "lat": 31.37087,
    "lng": -82.08885,
    "state_id": "GA"
  },
  {
    "zip": 31558,
    "lat": 30.81738,
    "lng": -81.50712,
    "state_id": "GA"
  },
  {
    "zip": 31560,
    "lat": 31.49948,
    "lng": -82.02598,
    "state_id": "GA"
  },
  {
    "zip": 31561,
    "lat": 31.20231,
    "lng": -81.33709,
    "state_id": "GA"
  },
  {
    "zip": 31562,
    "lat": 30.47555,
    "lng": -82.10574,
    "state_id": "GA"
  },
  {
    "zip": 31563,
    "lat": 31.74553,
    "lng": -82.18268,
    "state_id": "GA"
  },
  {
    "zip": 31565,
    "lat": 31.06443,
    "lng": -81.64231,
    "state_id": "GA"
  },
  {
    "zip": 31566,
    "lat": 31.19036,
    "lng": -81.80772,
    "state_id": "GA"
  },
  {
    "zip": 31567,
    "lat": 31.62918,
    "lng": -82.70778,
    "state_id": "GA"
  },
  {
    "zip": 31568,
    "lat": 31.00827,
    "lng": -81.81138,
    "state_id": "GA"
  },
  {
    "zip": 31569,
    "lat": 30.90736,
    "lng": -81.70485,
    "state_id": "GA"
  },
  {
    "zip": 31601,
    "lat": 30.75159,
    "lng": -83.34207,
    "state_id": "GA"
  },
  {
    "zip": 31602,
    "lat": 30.86925,
    "lng": -83.34394,
    "state_id": "GA"
  },
  {
    "zip": 31605,
    "lat": 30.93604,
    "lng": -83.23018,
    "state_id": "GA"
  },
  {
    "zip": 31606,
    "lat": 30.79893,
    "lng": -83.18917,
    "state_id": "GA"
  },
  {
    "zip": 31620,
    "lat": 31.11841,
    "lng": -83.43765,
    "state_id": "GA"
  },
  {
    "zip": 31622,
    "lat": 31.37959,
    "lng": -83.20569,
    "state_id": "GA"
  },
  {
    "zip": 31623,
    "lat": 31.07443,
    "lng": -82.64407,
    "state_id": "GA"
  },
  {
    "zip": 31624,
    "lat": 31.29846,
    "lng": -82.72141,
    "state_id": "GA"
  },
  {
    "zip": 31625,
    "lat": 30.9931,
    "lng": -83.52844,
    "state_id": "GA"
  },
  {
    "zip": 31626,
    "lat": 30.77558,
    "lng": -83.80106,
    "state_id": "GA"
  },
  {
    "zip": 31627,
    "lat": 31.04112,
    "lng": -83.39219,
    "state_id": "GA"
  },
  {
    "zip": 31629,
    "lat": 30.82258,
    "lng": -83.68727,
    "state_id": "GA"
  },
  {
    "zip": 31630,
    "lat": 30.92379,
    "lng": -82.87935,
    "state_id": "GA"
  },
  {
    "zip": 31631,
    "lat": 30.71165,
    "lng": -82.5987,
    "state_id": "GA"
  },
  {
    "zip": 31632,
    "lat": 30.98796,
    "lng": -83.35995,
    "state_id": "GA"
  },
  {
    "zip": 31634,
    "lat": 31.04006,
    "lng": -82.76737,
    "state_id": "GA"
  },
  {
    "zip": 31635,
    "lat": 31.10395,
    "lng": -83.05415,
    "state_id": "GA"
  },
  {
    "zip": 31636,
    "lat": 30.73307,
    "lng": -83.11251,
    "state_id": "GA"
  },
  {
    "zip": 31637,
    "lat": 31.29233,
    "lng": -83.44111,
    "state_id": "GA"
  },
  {
    "zip": 31638,
    "lat": 30.91134,
    "lng": -83.51955,
    "state_id": "GA"
  },
  {
    "zip": 31639,
    "lat": 31.21657,
    "lng": -83.1914,
    "state_id": "GA"
  },
  {
    "zip": 31641,
    "lat": 30.93243,
    "lng": -83.09623,
    "state_id": "GA"
  },
  {
    "zip": 31642,
    "lat": 31.27711,
    "lng": -82.89012,
    "state_id": "GA"
  },
  {
    "zip": 31643,
    "lat": 30.76923,
    "lng": -83.55976,
    "state_id": "GA"
  },
  {
    "zip": 31645,
    "lat": 31.06185,
    "lng": -83.2171,
    "state_id": "GA"
  },
  {
    "zip": 31647,
    "lat": 31.21053,
    "lng": -83.4306,
    "state_id": "GA"
  },
  {
    "zip": 31648,
    "lat": 30.70649,
    "lng": -83.01903,
    "state_id": "GA"
  },
  {
    "zip": 31649,
    "lat": 30.96503,
    "lng": -83.00272,
    "state_id": "GA"
  },
  {
    "zip": 31650,
    "lat": 31.39583,
    "lng": -83.02578,
    "state_id": "GA"
  },
  {
    "zip": 31698,
    "lat": 30.84889,
    "lng": -83.28953,
    "state_id": "GA"
  },
  {
    "zip": 31699,
    "lat": 30.97489,
    "lng": -83.20521,
    "state_id": "GA"
  },
  {
    "zip": 31701,
    "lat": 31.55299,
    "lng": -84.15824,
    "state_id": "GA"
  },
  {
    "zip": 31705,
    "lat": 31.50973,
    "lng": -84.04437,
    "state_id": "GA"
  },
  {
    "zip": 31707,
    "lat": 31.58832,
    "lng": -84.20749,
    "state_id": "GA"
  },
  {
    "zip": 31709,
    "lat": 32.06395,
    "lng": -84.13411,
    "state_id": "GA"
  },
  {
    "zip": 31711,
    "lat": 32.19143,
    "lng": -84.12936,
    "state_id": "GA"
  },
  {
    "zip": 31712,
    "lat": 31.83562,
    "lng": -83.70924,
    "state_id": "GA"
  },
  {
    "zip": 31714,
    "lat": 31.71538,
    "lng": -83.68522,
    "state_id": "GA"
  },
  {
    "zip": 31716,
    "lat": 31.36467,
    "lng": -84.11836,
    "state_id": "GA"
  },
  {
    "zip": 31719,
    "lat": 32.08321,
    "lng": -84.29739,
    "state_id": "GA"
  },
  {
    "zip": 31720,
    "lat": 30.87986,
    "lng": -83.72295,
    "state_id": "GA"
  },
  {
    "zip": 31721,
    "lat": 31.51058,
    "lng": -84.30867,
    "state_id": "GA"
  },
  {
    "zip": 31722,
    "lat": 31.06731,
    "lng": -83.62254,
    "state_id": "GA"
  },
  {
    "zip": 31730,
    "lat": 31.20675,
    "lng": -84.28681,
    "state_id": "GA"
  },
  {
    "zip": 31733,
    "lat": 31.59177,
    "lng": -83.48964,
    "state_id": "GA"
  },
  {
    "zip": 31735,
    "lat": 31.9691,
    "lng": -83.9645,
    "state_id": "GA"
  },
  {
    "zip": 31738,
    "lat": 31.00958,
    "lng": -83.87305,
    "state_id": "GA"
  },
  {
    "zip": 31743,
    "lat": 31.90926,
    "lng": -84.00625,
    "state_id": "GA"
  },
  {
    "zip": 31744,
    "lat": 31.33828,
    "lng": -83.9042,
    "state_id": "GA"
  },
  {
    "zip": 31747,
    "lat": 31.17597,
    "lng": -83.58761,
    "state_id": "GA"
  },
  {
    "zip": 31749,
    "lat": 31.41299,
    "lng": -83.32721,
    "state_id": "GA"
  },
  {
    "zip": 31750,
    "lat": 31.73354,
    "lng": -83.21609,
    "state_id": "GA"
  },
  {
    "zip": 31756,
    "lat": 31.18046,
    "lng": -83.96247,
    "state_id": "GA"
  },
  {
    "zip": 31757,
    "lat": 30.87257,
    "lng": -83.89935,
    "state_id": "GA"
  },
  {
    "zip": 31763,
    "lat": 31.74224,
    "lng": -84.14213,
    "state_id": "GA"
  },
  {
    "zip": 31764,
    "lat": 31.97813,
    "lng": -84.08293,
    "state_id": "GA"
  },
  {
    "zip": 31765,
    "lat": 31.094,
    "lng": -84.03135,
    "state_id": "GA"
  },
  {
    "zip": 31768,
    "lat": 31.19178,
    "lng": -83.84048,
    "state_id": "GA"
  },
  {
    "zip": 31771,
    "lat": 31.24271,
    "lng": -83.64654,
    "state_id": "GA"
  },
  {
    "zip": 31772,
    "lat": 31.72508,
    "lng": -83.95683,
    "state_id": "GA"
  },
  {
    "zip": 31773,
    "lat": 30.97623,
    "lng": -84.04652,
    "state_id": "GA"
  },
  {
    "zip": 31774,
    "lat": 31.55876,
    "lng": -83.2738,
    "state_id": "GA"
  },
  {
    "zip": 31775,
    "lat": 31.32999,
    "lng": -83.59738,
    "state_id": "GA"
  },
  {
    "zip": 31778,
    "lat": 30.97494,
    "lng": -83.70907,
    "state_id": "GA"
  },
  {
    "zip": 31779,
    "lat": 31.10136,
    "lng": -84.19315,
    "state_id": "GA"
  },
  {
    "zip": 31780,
    "lat": 32.03101,
    "lng": -84.39094,
    "state_id": "GA"
  },
  {
    "zip": 31781,
    "lat": 31.55546,
    "lng": -83.79233,
    "state_id": "GA"
  },
  {
    "zip": 31783,
    "lat": 31.76223,
    "lng": -83.47123,
    "state_id": "GA"
  },
  {
    "zip": 31784,
    "lat": 31.25813,
    "lng": -84.0423,
    "state_id": "GA"
  },
  {
    "zip": 31787,
    "lat": 31.89587,
    "lng": -84.24413,
    "state_id": "GA"
  },
  {
    "zip": 31788,
    "lat": 31.10952,
    "lng": -83.67988,
    "state_id": "GA"
  },
  {
    "zip": 31789,
    "lat": 31.47993,
    "lng": -83.73614,
    "state_id": "GA"
  },
  {
    "zip": 31790,
    "lat": 31.65164,
    "lng": -83.56899,
    "state_id": "GA"
  },
  {
    "zip": 31791,
    "lat": 31.53964,
    "lng": -83.87741,
    "state_id": "GA"
  },
  {
    "zip": 31792,
    "lat": 30.7685,
    "lng": -84.04499,
    "state_id": "GA"
  },
  {
    "zip": 31793,
    "lat": 31.46191,
    "lng": -83.58728,
    "state_id": "GA"
  },
  {
    "zip": 31794,
    "lat": 31.44111,
    "lng": -83.44932,
    "state_id": "GA"
  },
  {
    "zip": 31795,
    "lat": 31.473,
    "lng": -83.67902,
    "state_id": "GA"
  },
  {
    "zip": 31796,
    "lat": 31.77857,
    "lng": -83.87306,
    "state_id": "GA"
  },
  {
    "zip": 31798,
    "lat": 31.62142,
    "lng": -83.05665,
    "state_id": "GA"
  },
  {
    "zip": 31801,
    "lat": 32.51951,
    "lng": -84.59271,
    "state_id": "GA"
  },
  {
    "zip": 31803,
    "lat": 32.30393,
    "lng": -84.51953,
    "state_id": "GA"
  },
  {
    "zip": 31804,
    "lat": 32.66248,
    "lng": -84.88516,
    "state_id": "GA"
  },
  {
    "zip": 31805,
    "lat": 32.26851,
    "lng": -84.74498,
    "state_id": "GA"
  },
  {
    "zip": 31806,
    "lat": 32.26545,
    "lng": -84.30895,
    "state_id": "GA"
  },
  {
    "zip": 31807,
    "lat": 32.63866,
    "lng": -84.80298,
    "state_id": "GA"
  },
  {
    "zip": 31808,
    "lat": 32.63243,
    "lng": -85.00782,
    "state_id": "GA"
  },
  {
    "zip": 31810,
    "lat": 32.56852,
    "lng": -84.54079,
    "state_id": "GA"
  },
  {
    "zip": 31811,
    "lat": 32.74411,
    "lng": -84.93425,
    "state_id": "GA"
  },
  {
    "zip": 31812,
    "lat": 32.64406,
    "lng": -84.41195,
    "state_id": "GA"
  },
  {
    "zip": 31814,
    "lat": 32.19694,
    "lng": -84.85512,
    "state_id": "GA"
  },
  {
    "zip": 31815,
    "lat": 32.03555,
    "lng": -84.83843,
    "state_id": "GA"
  },
  {
    "zip": 31816,
    "lat": 32.8786,
    "lng": -84.60481,
    "state_id": "GA"
  },
  {
    "zip": 31820,
    "lat": 32.57942,
    "lng": -84.82601,
    "state_id": "GA"
  },
  {
    "zip": 31821,
    "lat": 32.1176,
    "lng": -84.97115,
    "state_id": "GA"
  },
  {
    "zip": 31822,
    "lat": 32.86685,
    "lng": -84.91184,
    "state_id": "GA"
  },
  {
    "zip": 31823,
    "lat": 32.80506,
    "lng": -84.82335,
    "state_id": "GA"
  },
  {
    "zip": 31824,
    "lat": 32.05417,
    "lng": -84.52713,
    "state_id": "GA"
  },
  {
    "zip": 31825,
    "lat": 32.11822,
    "lng": -84.66914,
    "state_id": "GA"
  },
  {
    "zip": 31826,
    "lat": 32.79418,
    "lng": -84.70352,
    "state_id": "GA"
  },
  {
    "zip": 31827,
    "lat": 32.68709,
    "lng": -84.5269,
    "state_id": "GA"
  },
  {
    "zip": 31829,
    "lat": 32.56846,
    "lng": -84.73521,
    "state_id": "GA"
  },
  {
    "zip": 31830,
    "lat": 32.89651,
    "lng": -84.73184,
    "state_id": "GA"
  },
  {
    "zip": 31831,
    "lat": 32.68427,
    "lng": -84.70501,
    "state_id": "GA"
  },
  {
    "zip": 31832,
    "lat": 31.96115,
    "lng": -84.61629,
    "state_id": "GA"
  },
  {
    "zip": 31833,
    "lat": 32.8504,
    "lng": -85.08912,
    "state_id": "GA"
  },
  {
    "zip": 31836,
    "lat": 32.79703,
    "lng": -84.53996,
    "state_id": "GA"
  },
  {
    "zip": 31901,
    "lat": 32.46488,
    "lng": -84.97987,
    "state_id": "GA"
  },
  {
    "zip": 31903,
    "lat": 32.41475,
    "lng": -84.95323,
    "state_id": "GA"
  },
  {
    "zip": 31904,
    "lat": 32.55412,
    "lng": -85.00085,
    "state_id": "GA"
  },
  {
    "zip": 31905,
    "lat": 32.37976,
    "lng": -84.90728,
    "state_id": "GA"
  },
  {
    "zip": 31906,
    "lat": 32.46798,
    "lng": -84.94951,
    "state_id": "GA"
  },
  {
    "zip": 31907,
    "lat": 32.48254,
    "lng": -84.90133,
    "state_id": "GA"
  },
  {
    "zip": 31909,
    "lat": 32.54486,
    "lng": -84.92161,
    "state_id": "GA"
  },
  {
    "zip": 39813,
    "lat": 31.41755,
    "lng": -84.681,
    "state_id": "GA"
  },
  {
    "zip": 39815,
    "lat": 30.73969,
    "lng": -84.49256,
    "state_id": "GA"
  },
  {
    "zip": 39817,
    "lat": 30.93816,
    "lng": -84.59338,
    "state_id": "GA"
  },
  {
    "zip": 39819,
    "lat": 30.78522,
    "lng": -84.63844,
    "state_id": "GA"
  },
  {
    "zip": 39823,
    "lat": 31.3321,
    "lng": -84.94157,
    "state_id": "GA"
  },
  {
    "zip": 39824,
    "lat": 31.50421,
    "lng": -84.90874,
    "state_id": "GA"
  },
  {
    "zip": 39825,
    "lat": 30.92534,
    "lng": -84.7353,
    "state_id": "GA"
  },
  {
    "zip": 39826,
    "lat": 31.81995,
    "lng": -84.33814,
    "state_id": "GA"
  },
  {
    "zip": 39827,
    "lat": 30.95275,
    "lng": -84.20686,
    "state_id": "GA"
  },
  {
    "zip": 39828,
    "lat": 30.80208,
    "lng": -84.22116,
    "state_id": "GA"
  },
  {
    "zip": 39834,
    "lat": 30.89334,
    "lng": -84.42558,
    "state_id": "GA"
  },
  {
    "zip": 39836,
    "lat": 31.6658,
    "lng": -84.87329,
    "state_id": "GA"
  },
  {
    "zip": 39837,
    "lat": 31.15273,
    "lng": -84.67304,
    "state_id": "GA"
  },
  {
    "zip": 39840,
    "lat": 31.77755,
    "lng": -84.76738,
    "state_id": "GA"
  },
  {
    "zip": 39841,
    "lat": 31.28183,
    "lng": -84.68109,
    "state_id": "GA"
  },
  {
    "zip": 39842,
    "lat": 31.76133,
    "lng": -84.43681,
    "state_id": "GA"
  },
  {
    "zip": 39845,
    "lat": 30.95155,
    "lng": -84.8944,
    "state_id": "GA"
  },
  {
    "zip": 39846,
    "lat": 31.56189,
    "lng": -84.74662,
    "state_id": "GA"
  },
  {
    "zip": 39851,
    "lat": 31.61953,
    "lng": -85.00607,
    "state_id": "GA"
  },
  {
    "zip": 39854,
    "lat": 31.86403,
    "lng": -85.03994,
    "state_id": "GA"
  },
  {
    "zip": 39859,
    "lat": 30.99571,
    "lng": -84.80724,
    "state_id": "GA"
  },
  {
    "zip": 39861,
    "lat": 31.13685,
    "lng": -84.98256,
    "state_id": "GA"
  },
  {
    "zip": 39862,
    "lat": 31.45666,
    "lng": -84.51598,
    "state_id": "GA"
  },
  {
    "zip": 39866,
    "lat": 31.55126,
    "lng": -84.60234,
    "state_id": "GA"
  },
  {
    "zip": 39867,
    "lat": 31.83428,
    "lng": -84.92551,
    "state_id": "GA"
  },
  {
    "zip": 39870,
    "lat": 31.32075,
    "lng": -84.40796,
    "state_id": "GA"
  },
  {
    "zip": 39877,
    "lat": 31.91779,
    "lng": -84.50916,
    "state_id": "GA"
  },
  {
    "zip": 39885,
    "lat": 31.71757,
    "lng": -84.35108,
    "state_id": "GA"
  },
  {
    "zip": 39886,
    "lat": 31.74896,
    "lng": -84.6202,
    "state_id": "GA"
  },
  {
    "zip": 39897,
    "lat": 30.91394,
    "lng": -84.3303,
    "state_id": "GA"
  },
  {
    "zip": 96910,
    "lat": 13.45476,
    "lng": 144.75089,
    "state_id": "GU"
  },
  {
    "zip": 96913,
    "lat": 13.47783,
    "lng": 144.8146,
    "state_id": "GU"
  },
  {
    "zip": 96915,
    "lat": 13.3782,
    "lng": 144.70165,
    "state_id": "GU"
  },
  {
    "zip": 96916,
    "lat": 13.26053,
    "lng": 144.70067,
    "state_id": "GU"
  },
  {
    "zip": 96917,
    "lat": 13.29489,
    "lng": 144.73413,
    "state_id": "GU"
  },
  {
    "zip": 96921,
    "lat": 13.45902,
    "lng": 144.79397,
    "state_id": "GU"
  },
  {
    "zip": 96928,
    "lat": 13.38497,
    "lng": 144.66153,
    "state_id": "GU"
  },
  {
    "zip": 96929,
    "lat": 13.5651,
    "lng": 144.8762,
    "state_id": "GU"
  },
  {
    "zip": 96932,
    "lat": 13.47524,
    "lng": 144.74216,
    "state_id": "GU"
  },
  {
    "zip": 96701,
    "lat": 21.39682,
    "lng": -157.89771,
    "state_id": "HI"
  },
  {
    "zip": 96703,
    "lat": 22.15145,
    "lng": -159.3502,
    "state_id": "HI"
  },
  {
    "zip": 96704,
    "lat": 19.32065,
    "lng": -155.81007,
    "state_id": "HI"
  },
  {
    "zip": 96705,
    "lat": 21.89982,
    "lng": -159.56548,
    "state_id": "HI"
  },
  {
    "zip": 96706,
    "lat": 21.3403,
    "lng": -158.01708,
    "state_id": "HI"
  },
  {
    "zip": 96707,
    "lat": 21.36453,
    "lng": -158.08142,
    "state_id": "HI"
  },
  {
    "zip": 96708,
    "lat": 20.85231,
    "lng": -156.2229,
    "state_id": "HI"
  },
  {
    "zip": 96710,
    "lat": 19.85432,
    "lng": -155.21809,
    "state_id": "HI"
  },
  {
    "zip": 96712,
    "lat": 21.62927,
    "lng": -158.04855,
    "state_id": "HI"
  },
  {
    "zip": 96713,
    "lat": 20.73387,
    "lng": -156.07377,
    "state_id": "HI"
  },
  {
    "zip": 96714,
    "lat": 22.15738,
    "lng": -159.54468,
    "state_id": "HI"
  },
  {
    "zip": 96716,
    "lat": 22.0336,
    "lng": -159.58999,
    "state_id": "HI"
  },
  {
    "zip": 96717,
    "lat": 21.55604,
    "lng": -157.89624,
    "state_id": "HI"
  },
  {
    "zip": 96719,
    "lat": 20.18621,
    "lng": -155.82212,
    "state_id": "HI"
  },
  {
    "zip": 96720,
    "lat": 19.67306,
    "lng": -155.2228,
    "state_id": "HI"
  },
  {
    "zip": 96722,
    "lat": 22.20374,
    "lng": -159.46963,
    "state_id": "HI"
  },
  {
    "zip": 96725,
    "lat": 19.62925,
    "lng": -155.89767,
    "state_id": "HI"
  },
  {
    "zip": 96726,
    "lat": 19.42816,
    "lng": -155.82098,
    "state_id": "HI"
  },
  {
    "zip": 96727,
    "lat": 20.05515,
    "lng": -155.54362,
    "state_id": "HI"
  },
  {
    "zip": 96728,
    "lat": 19.85809,
    "lng": -155.12928,
    "state_id": "HI"
  },
  {
    "zip": 96729,
    "lat": 21.16773,
    "lng": -157.11424,
    "state_id": "HI"
  },
  {
    "zip": 96730,
    "lat": 21.54614,
    "lng": -157.85111,
    "state_id": "HI"
  },
  {
    "zip": 96731,
    "lat": 21.67075,
    "lng": -157.97674,
    "state_id": "HI"
  },
  {
    "zip": 96732,
    "lat": 20.88125,
    "lng": -156.4612,
    "state_id": "HI"
  },
  {
    "zip": 96734,
    "lat": 21.38884,
    "lng": -157.75325,
    "state_id": "HI"
  },
  {
    "zip": 96737,
    "lat": 19.07026,
    "lng": -155.77587,
    "state_id": "HI"
  },
  {
    "zip": 96738,
    "lat": 19.90914,
    "lng": -155.78419,
    "state_id": "HI"
  },
  {
    "zip": 96740,
    "lat": 19.7452,
    "lng": -155.90131,
    "state_id": "HI"
  },
  {
    "zip": 96741,
    "lat": 21.95578,
    "lng": -159.51629,
    "state_id": "HI"
  },
  {
    "zip": 96742,
    "lat": 21.17087,
    "lng": -156.94743,
    "state_id": "HI"
  },
  {
    "zip": 96743,
    "lat": 19.97485,
    "lng": -155.68644,
    "state_id": "HI"
  },
  {
    "zip": 96744,
    "lat": 21.44323,
    "lng": -157.83141,
    "state_id": "HI"
  },
  {
    "zip": 96746,
    "lat": 22.09267,
    "lng": -159.38128,
    "state_id": "HI"
  },
  {
    "zip": 96747,
    "lat": 21.92434,
    "lng": -159.62455,
    "state_id": "HI"
  },
  {
    "zip": 96748,
    "lat": 21.11381,
    "lng": -156.87944,
    "state_id": "HI"
  },
  {
    "zip": 96749,
    "lat": 19.60753,
    "lng": -155.00156,
    "state_id": "HI"
  },
  {
    "zip": 96750,
    "lat": 19.53819,
    "lng": -155.74884,
    "state_id": "HI"
  },
  {
    "zip": 96751,
    "lat": 22.10489,
    "lng": -159.30252,
    "state_id": "HI"
  },
  {
    "zip": 96752,
    "lat": 21.98271,
    "lng": -159.73902,
    "state_id": "HI"
  },
  {
    "zip": 96753,
    "lat": 20.72067,
    "lng": -156.43534,
    "state_id": "HI"
  },
  {
    "zip": 96754,
    "lat": 22.17561,
    "lng": -159.41264,
    "state_id": "HI"
  },
  {
    "zip": 96755,
    "lat": 20.19659,
    "lng": -155.7876,
    "state_id": "HI"
  },
  {
    "zip": 96756,
    "lat": 21.90957,
    "lng": -159.45386,
    "state_id": "HI"
  },
  {
    "zip": 96757,
    "lat": 21.15538,
    "lng": -156.99495,
    "state_id": "HI"
  },
  {
    "zip": 96759,
    "lat": 21.46741,
    "lng": -158.07241,
    "state_id": "HI"
  },
  {
    "zip": 96760,
    "lat": 19.55478,
    "lng": -155.05612,
    "state_id": "HI"
  },
  {
    "zip": 96761,
    "lat": 20.90573,
    "lng": -156.61649,
    "state_id": "HI"
  },
  {
    "zip": 96762,
    "lat": 21.62417,
    "lng": -157.94242,
    "state_id": "HI"
  },
  {
    "zip": 96763,
    "lat": 20.83512,
    "lng": -156.92717,
    "state_id": "HI"
  },
  {
    "zip": 96764,
    "lat": 19.92796,
    "lng": -155.2731,
    "state_id": "HI"
  },
  {
    "zip": 96765,
    "lat": 21.92154,
    "lng": -159.49217,
    "state_id": "HI"
  },
  {
    "zip": 96766,
    "lat": 21.9976,
    "lng": -159.41505,
    "state_id": "HI"
  },
  {
    "zip": 96768,
    "lat": 20.83202,
    "lng": -156.29768,
    "state_id": "HI"
  },
  {
    "zip": 96769,
    "lat": 21.9012,
    "lng": -160.10469,
    "state_id": "HI"
  },
  {
    "zip": 96770,
    "lat": 21.13514,
    "lng": -157.21044,
    "state_id": "HI"
  },
  {
    "zip": 96771,
    "lat": 19.52402,
    "lng": -155.13342,
    "state_id": "HI"
  },
  {
    "zip": 96772,
    "lat": 19.19037,
    "lng": -155.6299,
    "state_id": "HI"
  },
  {
    "zip": 96773,
    "lat": 19.89025,
    "lng": -155.22474,
    "state_id": "HI"
  },
  {
    "zip": 96774,
    "lat": 19.95288,
    "lng": -155.33413,
    "state_id": "HI"
  },
  {
    "zip": 96776,
    "lat": 19.98691,
    "lng": -155.39649,
    "state_id": "HI"
  },
  {
    "zip": 96777,
    "lat": 19.24992,
    "lng": -155.4646,
    "state_id": "HI"
  },
  {
    "zip": 96778,
    "lat": 19.42816,
    "lng": -155.02053,
    "state_id": "HI"
  },
  {
    "zip": 96779,
    "lat": 20.90557,
    "lng": -156.37745,
    "state_id": "HI"
  },
  {
    "zip": 96780,
    "lat": 19.96532,
    "lng": -155.21943,
    "state_id": "HI"
  },
  {
    "zip": 96781,
    "lat": 19.79317,
    "lng": -155.18285,
    "state_id": "HI"
  },
  {
    "zip": 96782,
    "lat": 21.41746,
    "lng": -157.93176,
    "state_id": "HI"
  },
  {
    "zip": 96783,
    "lat": 19.83136,
    "lng": -155.1478,
    "state_id": "HI"
  },
  {
    "zip": 96785,
    "lat": 19.45367,
    "lng": -155.40743,
    "state_id": "HI"
  },
  {
    "zip": 96786,
    "lat": 21.55748,
    "lng": -158.01149,
    "state_id": "HI"
  },
  {
    "zip": 96789,
    "lat": 21.47829,
    "lng": -157.97472,
    "state_id": "HI"
  },
  {
    "zip": 96790,
    "lat": 20.69227,
    "lng": -156.29746,
    "state_id": "HI"
  },
  {
    "zip": 96791,
    "lat": 21.55079,
    "lng": -158.15725,
    "state_id": "HI"
  },
  {
    "zip": 96792,
    "lat": 21.46307,
    "lng": -158.16416,
    "state_id": "HI"
  },
  {
    "zip": 96793,
    "lat": 20.89769,
    "lng": -156.53765,
    "state_id": "HI"
  },
  {
    "zip": 96795,
    "lat": 21.34033,
    "lng": -157.71822,
    "state_id": "HI"
  },
  {
    "zip": 96796,
    "lat": 22.09046,
    "lng": -159.68899,
    "state_id": "HI"
  },
  {
    "zip": 96797,
    "lat": 21.42424,
    "lng": -157.97898,
    "state_id": "HI"
  },
  {
    "zip": 96813,
    "lat": 21.31704,
    "lng": -157.84338,
    "state_id": "HI"
  },
  {
    "zip": 96814,
    "lat": 21.29399,
    "lng": -157.84693,
    "state_id": "HI"
  },
  {
    "zip": 96815,
    "lat": 21.27557,
    "lng": -157.82262,
    "state_id": "HI"
  },
  {
    "zip": 96816,
    "lat": 21.29278,
    "lng": -157.79071,
    "state_id": "HI"
  },
  {
    "zip": 96817,
    "lat": 21.33981,
    "lng": -157.83799,
    "state_id": "HI"
  },
  {
    "zip": 96818,
    "lat": 21.34756,
    "lng": -157.93857,
    "state_id": "HI"
  },
  {
    "zip": 96819,
    "lat": 21.34984,
    "lng": -157.8753,
    "state_id": "HI"
  },
  {
    "zip": 96821,
    "lat": 21.29954,
    "lng": -157.75006,
    "state_id": "HI"
  },
  {
    "zip": 96822,
    "lat": 21.32018,
    "lng": -157.81079,
    "state_id": "HI"
  },
  {
    "zip": 96825,
    "lat": 21.29601,
    "lng": -157.69129,
    "state_id": "HI"
  },
  {
    "zip": 96826,
    "lat": 21.29159,
    "lng": -157.82698,
    "state_id": "HI"
  },
  {
    "zip": 96850,
    "lat": 21.30376,
    "lng": -157.86266,
    "state_id": "HI"
  },
  {
    "zip": 96853,
    "lat": 21.3348,
    "lng": -157.93444,
    "state_id": "HI"
  },
  {
    "zip": 96857,
    "lat": 21.48673,
    "lng": -158.05148,
    "state_id": "HI"
  },
  {
    "zip": 96859,
    "lat": 21.36046,
    "lng": -157.89064,
    "state_id": "HI"
  },
  {
    "zip": 96860,
    "lat": 21.35382,
    "lng": -157.9394,
    "state_id": "HI"
  },
  {
    "zip": 96863,
    "lat": 21.44845,
    "lng": -157.76232,
    "state_id": "HI"
  },
  {
    "zip": 50001,
    "lat": 41.36149,
    "lng": -93.43259,
    "state_id": "IA"
  },
  {
    "zip": 50002,
    "lat": 41.51429,
    "lng": -94.64688,
    "state_id": "IA"
  },
  {
    "zip": 50003,
    "lat": 41.61058,
    "lng": -94.04272,
    "state_id": "IA"
  },
  {
    "zip": 50005,
    "lat": 42.12925,
    "lng": -93.02632,
    "state_id": "IA"
  },
  {
    "zip": 50006,
    "lat": 42.51425,
    "lng": -93.39912,
    "state_id": "IA"
  },
  {
    "zip": 50007,
    "lat": 41.80683,
    "lng": -93.602,
    "state_id": "IA"
  },
  {
    "zip": 50008,
    "lat": 40.68072,
    "lng": -93.38472,
    "state_id": "IA"
  },
  {
    "zip": 50009,
    "lat": 41.64447,
    "lng": -93.45794,
    "state_id": "IA"
  },
  {
    "zip": 50010,
    "lat": 42.0336,
    "lng": -93.58826,
    "state_id": "IA"
  },
  {
    "zip": 50011,
    "lat": 42.02406,
    "lng": -93.63648,
    "state_id": "IA"
  },
  {
    "zip": 50012,
    "lat": 42.02717,
    "lng": -93.64525,
    "state_id": "IA"
  },
  {
    "zip": 50014,
    "lat": 42.04855,
    "lng": -93.69449,
    "state_id": "IA"
  },
  {
    "zip": 50020,
    "lat": 41.43242,
    "lng": -94.74806,
    "state_id": "IA"
  },
  {
    "zip": 50021,
    "lat": 41.72119,
    "lng": -93.56816,
    "state_id": "IA"
  },
  {
    "zip": 50022,
    "lat": 41.41596,
    "lng": -95.0018,
    "state_id": "IA"
  },
  {
    "zip": 50023,
    "lat": 41.73146,
    "lng": -93.63099,
    "state_id": "IA"
  },
  {
    "zip": 50025,
    "lat": 41.75791,
    "lng": -94.92352,
    "state_id": "IA"
  },
  {
    "zip": 50026,
    "lat": 41.85852,
    "lng": -94.44962,
    "state_id": "IA"
  },
  {
    "zip": 50027,
    "lat": 41.47497,
    "lng": -92.47316,
    "state_id": "IA"
  },
  {
    "zip": 50028,
    "lat": 41.81523,
    "lng": -93.14755,
    "state_id": "IA"
  },
  {
    "zip": 50029,
    "lat": 41.82587,
    "lng": -94.55061,
    "state_id": "IA"
  },
  {
    "zip": 50032,
    "lat": 41.66644,
    "lng": -93.54247,
    "state_id": "IA"
  },
  {
    "zip": 50033,
    "lat": 41.36303,
    "lng": -93.79289,
    "state_id": "IA"
  },
  {
    "zip": 50034,
    "lat": 42.52381,
    "lng": -93.64747,
    "state_id": "IA"
  },
  {
    "zip": 50035,
    "lat": 41.72529,
    "lng": -93.45743,
    "state_id": "IA"
  },
  {
    "zip": 50036,
    "lat": 42.08722,
    "lng": -93.8566,
    "state_id": "IA"
  },
  {
    "zip": 50038,
    "lat": 41.52342,
    "lng": -93.90269,
    "state_id": "IA"
  },
  {
    "zip": 50039,
    "lat": 41.81983,
    "lng": -94.00523,
    "state_id": "IA"
  },
  {
    "zip": 50041,
    "lat": 42.63844,
    "lng": -93.24804,
    "state_id": "IA"
  },
  {
    "zip": 50042,
    "lat": 41.53343,
    "lng": -94.90151,
    "state_id": "IA"
  },
  {
    "zip": 50044,
    "lat": 41.20301,
    "lng": -92.88238,
    "state_id": "IA"
  },
  {
    "zip": 50046,
    "lat": 41.8939,
    "lng": -93.52354,
    "state_id": "IA"
  },
  {
    "zip": 50047,
    "lat": 41.46829,
    "lng": -93.47383,
    "state_id": "IA"
  },
  {
    "zip": 50048,
    "lat": 41.51337,
    "lng": -94.52011,
    "state_id": "IA"
  },
  {
    "zip": 50049,
    "lat": 41.03564,
    "lng": -93.30104,
    "state_id": "IA"
  },
  {
    "zip": 50050,
    "lat": 42.16159,
    "lng": -94.50664,
    "state_id": "IA"
  },
  {
    "zip": 50051,
    "lat": 42.13446,
    "lng": -93.14126,
    "state_id": "IA"
  },
  {
    "zip": 50052,
    "lat": 40.64211,
    "lng": -93.44223,
    "state_id": "IA"
  },
  {
    "zip": 50054,
    "lat": 41.68833,
    "lng": -93.23502,
    "state_id": "IA"
  },
  {
    "zip": 50055,
    "lat": 41.88524,
    "lng": -93.29042,
    "state_id": "IA"
  },
  {
    "zip": 50056,
    "lat": 42.01691,
    "lng": -93.29739,
    "state_id": "IA"
  },
  {
    "zip": 50057,
    "lat": 41.17828,
    "lng": -93.14679,
    "state_id": "IA"
  },
  {
    "zip": 50058,
    "lat": 41.85605,
    "lng": -94.6903,
    "state_id": "IA"
  },
  {
    "zip": 50060,
    "lat": 40.75342,
    "lng": -93.31808,
    "state_id": "IA"
  },
  {
    "zip": 50061,
    "lat": 41.48516,
    "lng": -93.78805,
    "state_id": "IA"
  },
  {
    "zip": 50062,
    "lat": 41.23459,
    "lng": -93.24393,
    "state_id": "IA"
  },
  {
    "zip": 50063,
    "lat": 41.69932,
    "lng": -93.93612,
    "state_id": "IA"
  },
  {
    "zip": 50064,
    "lat": 42.10771,
    "lng": -94.2366,
    "state_id": "IA"
  },
  {
    "zip": 50065,
    "lat": 40.60809,
    "lng": -93.78626,
    "state_id": "IA"
  },
  {
    "zip": 50066,
    "lat": 41.80485,
    "lng": -94.22581,
    "state_id": "IA"
  },
  {
    "zip": 50067,
    "lat": 40.7157,
    "lng": -93.85996,
    "state_id": "IA"
  },
  {
    "zip": 50068,
    "lat": 40.94509,
    "lng": -93.46973,
    "state_id": "IA"
  },
  {
    "zip": 50069,
    "lat": 41.5207,
    "lng": -94.03028,
    "state_id": "IA"
  },
  {
    "zip": 50070,
    "lat": 41.45566,
    "lng": -94.23822,
    "state_id": "IA"
  },
  {
    "zip": 50071,
    "lat": 42.64944,
    "lng": -93.50305,
    "state_id": "IA"
  },
  {
    "zip": 50072,
    "lat": 41.46964,
    "lng": -94.12251,
    "state_id": "IA"
  },
  {
    "zip": 50073,
    "lat": 41.7939,
    "lng": -93.52469,
    "state_id": "IA"
  },
  {
    "zip": 50074,
    "lat": 40.85679,
    "lng": -94.07581,
    "state_id": "IA"
  },
  {
    "zip": 50075,
    "lat": 42.33527,
    "lng": -93.55981,
    "state_id": "IA"
  },
  {
    "zip": 50076,
    "lat": 41.59357,
    "lng": -94.85858,
    "state_id": "IA"
  },
  {
    "zip": 50078,
    "lat": 41.93859,
    "lng": -92.86301,
    "state_id": "IA"
  },
  {
    "zip": 50101,
    "lat": 42.6673,
    "lng": -93.63472,
    "state_id": "IA"
  },
  {
    "zip": 50102,
    "lat": 42.24554,
    "lng": -93.40019,
    "state_id": "IA"
  },
  {
    "zip": 50103,
    "lat": 40.79594,
    "lng": -93.60825,
    "state_id": "IA"
  },
  {
    "zip": 50104,
    "lat": 41.48679,
    "lng": -92.38665,
    "state_id": "IA"
  },
  {
    "zip": 50105,
    "lat": 42.11027,
    "lng": -93.63735,
    "state_id": "IA"
  },
  {
    "zip": 50106,
    "lat": 41.88171,
    "lng": -92.8135,
    "state_id": "IA"
  },
  {
    "zip": 50107,
    "lat": 42.03835,
    "lng": -94.22474,
    "state_id": "IA"
  },
  {
    "zip": 50108,
    "lat": 40.83227,
    "lng": -93.95517,
    "state_id": "IA"
  },
  {
    "zip": 50109,
    "lat": 41.76602,
    "lng": -93.80392,
    "state_id": "IA"
  },
  {
    "zip": 50111,
    "lat": 41.69178,
    "lng": -93.80431,
    "state_id": "IA"
  },
  {
    "zip": 50112,
    "lat": 41.7313,
    "lng": -92.71481,
    "state_id": "IA"
  },
  {
    "zip": 50115,
    "lat": 41.68714,
    "lng": -94.55865,
    "state_id": "IA"
  },
  {
    "zip": 50116,
    "lat": 41.1797,
    "lng": -92.97601,
    "state_id": "IA"
  },
  {
    "zip": 50117,
    "lat": 41.66819,
    "lng": -94.85988,
    "state_id": "IA"
  },
  {
    "zip": 50118,
    "lat": 41.46369,
    "lng": -93.37299,
    "state_id": "IA"
  },
  {
    "zip": 50119,
    "lat": 41.31664,
    "lng": -92.9475,
    "state_id": "IA"
  },
  {
    "zip": 50120,
    "lat": 41.93371,
    "lng": -92.98288,
    "state_id": "IA"
  },
  {
    "zip": 50122,
    "lat": 42.30333,
    "lng": -93.3126,
    "state_id": "IA"
  },
  {
    "zip": 50123,
    "lat": 40.83778,
    "lng": -93.50475,
    "state_id": "IA"
  },
  {
    "zip": 50124,
    "lat": 41.88569,
    "lng": -93.60408,
    "state_id": "IA"
  },
  {
    "zip": 50125,
    "lat": 41.33363,
    "lng": -93.57962,
    "state_id": "IA"
  },
  {
    "zip": 50126,
    "lat": 42.50703,
    "lng": -93.24705,
    "state_id": "IA"
  },
  {
    "zip": 50127,
    "lat": 41.77714,
    "lng": -93.20581,
    "state_id": "IA"
  },
  {
    "zip": 50128,
    "lat": 41.85089,
    "lng": -94.29899,
    "state_id": "IA"
  },
  {
    "zip": 50129,
    "lat": 42.01447,
    "lng": -94.3768,
    "state_id": "IA"
  },
  {
    "zip": 50130,
    "lat": 42.30249,
    "lng": -93.6722,
    "state_id": "IA"
  },
  {
    "zip": 50131,
    "lat": 41.69337,
    "lng": -93.71697,
    "state_id": "IA"
  },
  {
    "zip": 50132,
    "lat": 42.39167,
    "lng": -93.69151,
    "state_id": "IA"
  },
  {
    "zip": 50133,
    "lat": 40.71536,
    "lng": -94.06862,
    "state_id": "IA"
  },
  {
    "zip": 50134,
    "lat": 41.93949,
    "lng": -93.67159,
    "state_id": "IA"
  },
  {
    "zip": 50135,
    "lat": 41.74406,
    "lng": -92.88749,
    "state_id": "IA"
  },
  {
    "zip": 50136,
    "lat": 41.47087,
    "lng": -92.29086,
    "state_id": "IA"
  },
  {
    "zip": 50138,
    "lat": 41.30073,
    "lng": -93.10425,
    "state_id": "IA"
  },
  {
    "zip": 50139,
    "lat": 41.19313,
    "lng": -93.36765,
    "state_id": "IA"
  },
  {
    "zip": 50140,
    "lat": 40.64211,
    "lng": -93.98213,
    "state_id": "IA"
  },
  {
    "zip": 50141,
    "lat": 41.86871,
    "lng": -92.95535,
    "state_id": "IA"
  },
  {
    "zip": 50142,
    "lat": 42.00662,
    "lng": -92.77563,
    "state_id": "IA"
  },
  {
    "zip": 50143,
    "lat": 41.34324,
    "lng": -92.7991,
    "state_id": "IA"
  },
  {
    "zip": 50144,
    "lat": 40.73871,
    "lng": -93.74101,
    "state_id": "IA"
  },
  {
    "zip": 50146,
    "lat": 41.68406,
    "lng": -94.24308,
    "state_id": "IA"
  },
  {
    "zip": 50147,
    "lat": 40.62714,
    "lng": -93.5491,
    "state_id": "IA"
  },
  {
    "zip": 50148,
    "lat": 42.18127,
    "lng": -92.99283,
    "state_id": "IA"
  },
  {
    "zip": 50149,
    "lat": 41.15117,
    "lng": -94.08066,
    "state_id": "IA"
  },
  {
    "zip": 50150,
    "lat": 41.12767,
    "lng": -92.96932,
    "state_id": "IA"
  },
  {
    "zip": 50151,
    "lat": 41.08384,
    "lng": -93.49842,
    "state_id": "IA"
  },
  {
    "zip": 50153,
    "lat": 41.57548,
    "lng": -92.80204,
    "state_id": "IA"
  },
  {
    "zip": 50154,
    "lat": 42.17224,
    "lng": -93.39266,
    "state_id": "IA"
  },
  {
    "zip": 50155,
    "lat": 41.20806,
    "lng": -94.20033,
    "state_id": "IA"
  },
  {
    "zip": 50156,
    "lat": 41.90298,
    "lng": -93.79847,
    "state_id": "IA"
  },
  {
    "zip": 50157,
    "lat": 41.74492,
    "lng": -92.56145,
    "state_id": "IA"
  },
  {
    "zip": 50158,
    "lat": 42.05031,
    "lng": -92.90058,
    "state_id": "IA"
  },
  {
    "zip": 50160,
    "lat": 41.37379,
    "lng": -93.73865,
    "state_id": "IA"
  },
  {
    "zip": 50161,
    "lat": 41.84826,
    "lng": -93.40232,
    "state_id": "IA"
  },
  {
    "zip": 50162,
    "lat": 41.92488,
    "lng": -93.06923,
    "state_id": "IA"
  },
  {
    "zip": 50163,
    "lat": 41.22221,
    "lng": -93.24098,
    "state_id": "IA"
  },
  {
    "zip": 50164,
    "lat": 41.50129,
    "lng": -94.41876,
    "state_id": "IA"
  },
  {
    "zip": 50165,
    "lat": 40.84367,
    "lng": -93.28372,
    "state_id": "IA"
  },
  {
    "zip": 50166,
    "lat": 41.28079,
    "lng": -93.42661,
    "state_id": "IA"
  },
  {
    "zip": 50167,
    "lat": 41.74418,
    "lng": -94.04805,
    "state_id": "IA"
  },
  {
    "zip": 50168,
    "lat": 41.78066,
    "lng": -93.27442,
    "state_id": "IA"
  },
  {
    "zip": 50169,
    "lat": 41.65845,
    "lng": -93.3526,
    "state_id": "IA"
  },
  {
    "zip": 50170,
    "lat": 41.52589,
    "lng": -93.12124,
    "state_id": "IA"
  },
  {
    "zip": 50171,
    "lat": 41.58604,
    "lng": -92.52499,
    "state_id": "IA"
  },
  {
    "zip": 50173,
    "lat": 41.97299,
    "lng": -92.71422,
    "state_id": "IA"
  },
  {
    "zip": 50174,
    "lat": 41.03768,
    "lng": -93.96153,
    "state_id": "IA"
  },
  {
    "zip": 50201,
    "lat": 42.03506,
    "lng": -93.44454,
    "state_id": "IA"
  },
  {
    "zip": 50206,
    "lat": 42.25172,
    "lng": -93.18959,
    "state_id": "IA"
  },
  {
    "zip": 50207,
    "lat": 41.4347,
    "lng": -92.63752,
    "state_id": "IA"
  },
  {
    "zip": 50208,
    "lat": 41.71025,
    "lng": -93.0383,
    "state_id": "IA"
  },
  {
    "zip": 50210,
    "lat": 41.18832,
    "lng": -93.69761,
    "state_id": "IA"
  },
  {
    "zip": 50211,
    "lat": 41.45582,
    "lng": -93.69179,
    "state_id": "IA"
  },
  {
    "zip": 50212,
    "lat": 42.0366,
    "lng": -94.06756,
    "state_id": "IA"
  },
  {
    "zip": 50213,
    "lat": 41.03205,
    "lng": -93.76611,
    "state_id": "IA"
  },
  {
    "zip": 50214,
    "lat": 41.46195,
    "lng": -93.05122,
    "state_id": "IA"
  },
  {
    "zip": 50216,
    "lat": 41.69629,
    "lng": -94.3484,
    "state_id": "IA"
  },
  {
    "zip": 50217,
    "lat": 42.17387,
    "lng": -94.24943,
    "state_id": "IA"
  },
  {
    "zip": 50218,
    "lat": 41.34828,
    "lng": -93.8802,
    "state_id": "IA"
  },
  {
    "zip": 50219,
    "lat": 41.42365,
    "lng": -92.89833,
    "state_id": "IA"
  },
  {
    "zip": 50220,
    "lat": 41.83609,
    "lng": -94.12296,
    "state_id": "IA"
  },
  {
    "zip": 50222,
    "lat": 41.22119,
    "lng": -93.9472,
    "state_id": "IA"
  },
  {
    "zip": 50223,
    "lat": 42.16892,
    "lng": -94.03007,
    "state_id": "IA"
  },
  {
    "zip": 50225,
    "lat": 41.38457,
    "lng": -93.26904,
    "state_id": "IA"
  },
  {
    "zip": 50226,
    "lat": 41.79269,
    "lng": -93.70235,
    "state_id": "IA"
  },
  {
    "zip": 50227,
    "lat": 42.59744,
    "lng": -93.42788,
    "state_id": "IA"
  },
  {
    "zip": 50228,
    "lat": 41.57869,
    "lng": -93.24221,
    "state_id": "IA"
  },
  {
    "zip": 50229,
    "lat": 41.37647,
    "lng": -93.77679,
    "state_id": "IA"
  },
  {
    "zip": 50230,
    "lat": 42.30375,
    "lng": -93.44749,
    "state_id": "IA"
  },
  {
    "zip": 50231,
    "lat": 42.23715,
    "lng": -93.60262,
    "state_id": "IA"
  },
  {
    "zip": 50232,
    "lat": 41.5565,
    "lng": -92.96697,
    "state_id": "IA"
  },
  {
    "zip": 50233,
    "lat": 41.6129,
    "lng": -94.21136,
    "state_id": "IA"
  },
  {
    "zip": 50234,
    "lat": 41.89146,
    "lng": -93.18454,
    "state_id": "IA"
  },
  {
    "zip": 50235,
    "lat": 41.92959,
    "lng": -94.21032,
    "state_id": "IA"
  },
  {
    "zip": 50236,
    "lat": 42.16802,
    "lng": -93.48318,
    "state_id": "IA"
  },
  {
    "zip": 50237,
    "lat": 41.54005,
    "lng": -93.37014,
    "state_id": "IA"
  },
  {
    "zip": 50238,
    "lat": 40.96944,
    "lng": -93.17102,
    "state_id": "IA"
  },
  {
    "zip": 50239,
    "lat": 42.14869,
    "lng": -93.19937,
    "state_id": "IA"
  },
  {
    "zip": 50240,
    "lat": 41.29506,
    "lng": -93.79469,
    "state_id": "IA"
  },
  {
    "zip": 50242,
    "lat": 41.56241,
    "lng": -92.69399,
    "state_id": "IA"
  },
  {
    "zip": 50243,
    "lat": 41.86591,
    "lng": -93.69416,
    "state_id": "IA"
  },
  {
    "zip": 50244,
    "lat": 41.85754,
    "lng": -93.65265,
    "state_id": "IA"
  },
  {
    "zip": 50246,
    "lat": 42.27537,
    "lng": -93.78216,
    "state_id": "IA"
  },
  {
    "zip": 50247,
    "lat": 42.01698,
    "lng": -93.16502,
    "state_id": "IA"
  },
  {
    "zip": 50248,
    "lat": 42.19268,
    "lng": -93.61095,
    "state_id": "IA"
  },
  {
    "zip": 50249,
    "lat": 42.27289,
    "lng": -93.90139,
    "state_id": "IA"
  },
  {
    "zip": 50250,
    "lat": 41.48281,
    "lng": -94.33906,
    "state_id": "IA"
  },
  {
    "zip": 50251,
    "lat": 41.56878,
    "lng": -92.87127,
    "state_id": "IA"
  },
  {
    "zip": 50252,
    "lat": 41.46357,
    "lng": -93.30101,
    "state_id": "IA"
  },
  {
    "zip": 50254,
    "lat": 40.98707,
    "lng": -94.07011,
    "state_id": "IA"
  },
  {
    "zip": 50255,
    "lat": 41.45579,
    "lng": -92.33224,
    "state_id": "IA"
  },
  {
    "zip": 50256,
    "lat": 41.26854,
    "lng": -92.91229,
    "state_id": "IA"
  },
  {
    "zip": 50257,
    "lat": 41.18952,
    "lng": -93.84723,
    "state_id": "IA"
  },
  {
    "zip": 50258,
    "lat": 42.22711,
    "lng": -93.07691,
    "state_id": "IA"
  },
  {
    "zip": 50261,
    "lat": 41.4699,
    "lng": -93.92069,
    "state_id": "IA"
  },
  {
    "zip": 50262,
    "lat": 40.85516,
    "lng": -93.8156,
    "state_id": "IA"
  },
  {
    "zip": 50263,
    "lat": 41.59562,
    "lng": -93.87241,
    "state_id": "IA"
  },
  {
    "zip": 50264,
    "lat": 40.90134,
    "lng": -93.70259,
    "state_id": "IA"
  },
  {
    "zip": 50265,
    "lat": 41.55553,
    "lng": -93.73736,
    "state_id": "IA"
  },
  {
    "zip": 50266,
    "lat": 41.56942,
    "lng": -93.79934,
    "state_id": "IA"
  },
  {
    "zip": 50268,
    "lat": 41.407,
    "lng": -92.36339,
    "state_id": "IA"
  },
  {
    "zip": 50271,
    "lat": 42.48278,
    "lng": -93.54144,
    "state_id": "IA"
  },
  {
    "zip": 50272,
    "lat": 41.09602,
    "lng": -93.25587,
    "state_id": "IA"
  },
  {
    "zip": 50273,
    "lat": 41.32937,
    "lng": -94.05413,
    "state_id": "IA"
  },
  {
    "zip": 50274,
    "lat": 41.38138,
    "lng": -94.85343,
    "state_id": "IA"
  },
  {
    "zip": 50275,
    "lat": 41.03747,
    "lng": -93.58957,
    "state_id": "IA"
  },
  {
    "zip": 50276,
    "lat": 41.86238,
    "lng": -93.92341,
    "state_id": "IA"
  },
  {
    "zip": 50277,
    "lat": 41.77734,
    "lng": -94.34908,
    "state_id": "IA"
  },
  {
    "zip": 50278,
    "lat": 42.15018,
    "lng": -93.29745,
    "state_id": "IA"
  },
  {
    "zip": 50309,
    "lat": 41.58487,
    "lng": -93.62063,
    "state_id": "IA"
  },
  {
    "zip": 50310,
    "lat": 41.62783,
    "lng": -93.67321,
    "state_id": "IA"
  },
  {
    "zip": 50311,
    "lat": 41.60102,
    "lng": -93.67284,
    "state_id": "IA"
  },
  {
    "zip": 50312,
    "lat": 41.58092,
    "lng": -93.67593,
    "state_id": "IA"
  },
  {
    "zip": 50313,
    "lat": 41.65465,
    "lng": -93.62155,
    "state_id": "IA"
  },
  {
    "zip": 50314,
    "lat": 41.60535,
    "lng": -93.63122,
    "state_id": "IA"
  },
  {
    "zip": 50315,
    "lat": 41.54562,
    "lng": -93.62102,
    "state_id": "IA"
  },
  {
    "zip": 50316,
    "lat": 41.60865,
    "lng": -93.59763,
    "state_id": "IA"
  },
  {
    "zip": 50317,
    "lat": 41.6163,
    "lng": -93.54711,
    "state_id": "IA"
  },
  {
    "zip": 50319,
    "lat": 41.59091,
    "lng": -93.60399,
    "state_id": "IA"
  },
  {
    "zip": 50320,
    "lat": 41.52873,
    "lng": -93.57062,
    "state_id": "IA"
  },
  {
    "zip": 50321,
    "lat": 41.53945,
    "lng": -93.67288,
    "state_id": "IA"
  },
  {
    "zip": 50322,
    "lat": 41.63362,
    "lng": -93.73618,
    "state_id": "IA"
  },
  {
    "zip": 50323,
    "lat": 41.63469,
    "lng": -93.81379,
    "state_id": "IA"
  },
  {
    "zip": 50324,
    "lat": 41.6044,
    "lng": -93.7128,
    "state_id": "IA"
  },
  {
    "zip": 50325,
    "lat": 41.61465,
    "lng": -93.79823,
    "state_id": "IA"
  },
  {
    "zip": 50327,
    "lat": 41.58155,
    "lng": -93.48471,
    "state_id": "IA"
  },
  {
    "zip": 50401,
    "lat": 43.15371,
    "lng": -93.20016,
    "state_id": "IA"
  },
  {
    "zip": 50420,
    "lat": 42.8042,
    "lng": -93.46037,
    "state_id": "IA"
  },
  {
    "zip": 50421,
    "lat": 42.84308,
    "lng": -93.62007,
    "state_id": "IA"
  },
  {
    "zip": 50423,
    "lat": 43.10928,
    "lng": -93.81267,
    "state_id": "IA"
  },
  {
    "zip": 50424,
    "lat": 43.39441,
    "lng": -93.93381,
    "state_id": "IA"
  },
  {
    "zip": 50426,
    "lat": 43.4155,
    "lng": -93.01721,
    "state_id": "IA"
  },
  {
    "zip": 50428,
    "lat": 43.13775,
    "lng": -93.38988,
    "state_id": "IA"
  },
  {
    "zip": 50430,
    "lat": 42.9816,
    "lng": -93.95399,
    "state_id": "IA"
  },
  {
    "zip": 50431,
    "lat": 42.73711,
    "lng": -93.36824,
    "state_id": "IA"
  },
  {
    "zip": 50432,
    "lat": 43.22388,
    "lng": -93.79805,
    "state_id": "IA"
  },
  {
    "zip": 50433,
    "lat": 42.92325,
    "lng": -93.0502,
    "state_id": "IA"
  },
  {
    "zip": 50434,
    "lat": 43.25906,
    "lng": -93.45355,
    "state_id": "IA"
  },
  {
    "zip": 50435,
    "lat": 43.15802,
    "lng": -92.76702,
    "state_id": "IA"
  },
  {
    "zip": 50436,
    "lat": 43.26285,
    "lng": -93.6634,
    "state_id": "IA"
  },
  {
    "zip": 50438,
    "lat": 43.10935,
    "lng": -93.61746,
    "state_id": "IA"
  },
  {
    "zip": 50439,
    "lat": 42.94567,
    "lng": -93.63521,
    "state_id": "IA"
  },
  {
    "zip": 50440,
    "lat": 43.33158,
    "lng": -93.07262,
    "state_id": "IA"
  },
  {
    "zip": 50441,
    "lat": 42.74141,
    "lng": -93.2211,
    "state_id": "IA"
  },
  {
    "zip": 50444,
    "lat": 43.29323,
    "lng": -93.38693,
    "state_id": "IA"
  },
  {
    "zip": 50446,
    "lat": 43.34257,
    "lng": -93.45257,
    "state_id": "IA"
  },
  {
    "zip": 50447,
    "lat": 42.92663,
    "lng": -93.80107,
    "state_id": "IA"
  },
  {
    "zip": 50448,
    "lat": 43.35,
    "lng": -93.24825,
    "state_id": "IA"
  },
  {
    "zip": 50449,
    "lat": 42.99902,
    "lng": -93.55878,
    "state_id": "IA"
  },
  {
    "zip": 50450,
    "lat": 43.42171,
    "lng": -93.52389,
    "state_id": "IA"
  },
  {
    "zip": 50451,
    "lat": 43.37865,
    "lng": -94.08844,
    "state_id": "IA"
  },
  {
    "zip": 50452,
    "lat": 42.78542,
    "lng": -93.37264,
    "state_id": "IA"
  },
  {
    "zip": 50453,
    "lat": 43.36604,
    "lng": -93.65241,
    "state_id": "IA"
  },
  {
    "zip": 50454,
    "lat": 43.38185,
    "lng": -92.73733,
    "state_id": "IA"
  },
  {
    "zip": 50455,
    "lat": 43.45691,
    "lng": -92.64977,
    "state_id": "IA"
  },
  {
    "zip": 50456,
    "lat": 43.28806,
    "lng": -93.21757,
    "state_id": "IA"
  },
  {
    "zip": 50457,
    "lat": 42.91633,
    "lng": -93.4936,
    "state_id": "IA"
  },
  {
    "zip": 50458,
    "lat": 43.16088,
    "lng": -93.00756,
    "state_id": "IA"
  },
  {
    "zip": 50459,
    "lat": 43.4454,
    "lng": -93.25412,
    "state_id": "IA"
  },
  {
    "zip": 50460,
    "lat": 43.2278,
    "lng": -92.68884,
    "state_id": "IA"
  },
  {
    "zip": 50461,
    "lat": 43.2994,
    "lng": -92.81903,
    "state_id": "IA"
  },
  {
    "zip": 50464,
    "lat": 43.25339,
    "lng": -93.08079,
    "state_id": "IA"
  },
  {
    "zip": 50465,
    "lat": 43.48644,
    "lng": -93.91222,
    "state_id": "IA"
  },
  {
    "zip": 50466,
    "lat": 43.38531,
    "lng": -92.54188,
    "state_id": "IA"
  },
  {
    "zip": 50467,
    "lat": 43.2117,
    "lng": -93.08654,
    "state_id": "IA"
  },
  {
    "zip": 50468,
    "lat": 43.04668,
    "lng": -92.9548,
    "state_id": "IA"
  },
  {
    "zip": 50469,
    "lat": 42.99874,
    "lng": -93.19417,
    "state_id": "IA"
  },
  {
    "zip": 50470,
    "lat": 42.74576,
    "lng": -93.56039,
    "state_id": "IA"
  },
  {
    "zip": 50471,
    "lat": 43.16657,
    "lng": -92.87642,
    "state_id": "IA"
  },
  {
    "zip": 50472,
    "lat": 43.42206,
    "lng": -92.94688,
    "state_id": "IA"
  },
  {
    "zip": 50473,
    "lat": 43.46615,
    "lng": -93.67886,
    "state_id": "IA"
  },
  {
    "zip": 50475,
    "lat": 42.87944,
    "lng": -93.21844,
    "state_id": "IA"
  },
  {
    "zip": 50476,
    "lat": 43.45235,
    "lng": -92.76398,
    "state_id": "IA"
  },
  {
    "zip": 50477,
    "lat": 42.98926,
    "lng": -93.32708,
    "state_id": "IA"
  },
  {
    "zip": 50478,
    "lat": 43.40159,
    "lng": -93.78474,
    "state_id": "IA"
  },
  {
    "zip": 50479,
    "lat": 42.93948,
    "lng": -93.40988,
    "state_id": "IA"
  },
  {
    "zip": 50480,
    "lat": 43.25039,
    "lng": -94.05021,
    "state_id": "IA"
  },
  {
    "zip": 50482,
    "lat": 43.11573,
    "lng": -93.47867,
    "state_id": "IA"
  },
  {
    "zip": 50483,
    "lat": 43.11261,
    "lng": -94.00092,
    "state_id": "IA"
  },
  {
    "zip": 50484,
    "lat": 43.23409,
    "lng": -93.91352,
    "state_id": "IA"
  },
  {
    "zip": 50501,
    "lat": 42.49286,
    "lng": -94.19259,
    "state_id": "IA"
  },
  {
    "zip": 50510,
    "lat": 42.76092,
    "lng": -94.98481,
    "state_id": "IA"
  },
  {
    "zip": 50511,
    "lat": 43.07388,
    "lng": -94.22014,
    "state_id": "IA"
  },
  {
    "zip": 50514,
    "lat": 43.41603,
    "lng": -94.47186,
    "state_id": "IA"
  },
  {
    "zip": 50515,
    "lat": 43.02086,
    "lng": -94.87102,
    "state_id": "IA"
  },
  {
    "zip": 50516,
    "lat": 42.62335,
    "lng": -94.1365,
    "state_id": "IA"
  },
  {
    "zip": 50517,
    "lat": 43.30284,
    "lng": -94.22974,
    "state_id": "IA"
  },
  {
    "zip": 50518,
    "lat": 42.51493,
    "lng": -94.38603,
    "state_id": "IA"
  },
  {
    "zip": 50519,
    "lat": 42.90172,
    "lng": -94.26289,
    "state_id": "IA"
  },
  {
    "zip": 50520,
    "lat": 42.80521,
    "lng": -94.39549,
    "state_id": "IA"
  },
  {
    "zip": 50521,
    "lat": 42.34624,
    "lng": -94.10284,
    "state_id": "IA"
  },
  {
    "zip": 50522,
    "lat": 43.1877,
    "lng": -94.18825,
    "state_id": "IA"
  },
  {
    "zip": 50523,
    "lat": 42.36804,
    "lng": -94.29575,
    "state_id": "IA"
  },
  {
    "zip": 50524,
    "lat": 42.60255,
    "lng": -94.3559,
    "state_id": "IA"
  },
  {
    "zip": 50525,
    "lat": 42.73648,
    "lng": -93.74724,
    "state_id": "IA"
  },
  {
    "zip": 50527,
    "lat": 42.965,
    "lng": -94.78542,
    "state_id": "IA"
  },
  {
    "zip": 50528,
    "lat": 43.13396,
    "lng": -94.54085,
    "state_id": "IA"
  },
  {
    "zip": 50529,
    "lat": 42.72141,
    "lng": -94.19946,
    "state_id": "IA"
  },
  {
    "zip": 50530,
    "lat": 42.26796,
    "lng": -94.04239,
    "state_id": "IA"
  },
  {
    "zip": 50531,
    "lat": 43.45779,
    "lng": -94.61111,
    "state_id": "IA"
  },
  {
    "zip": 50532,
    "lat": 42.46158,
    "lng": -94.00536,
    "state_id": "IA"
  },
  {
    "zip": 50533,
    "lat": 42.65194,
    "lng": -93.91181,
    "state_id": "IA"
  },
  {
    "zip": 50535,
    "lat": 42.45253,
    "lng": -95.16234,
    "state_id": "IA"
  },
  {
    "zip": 50536,
    "lat": 43.1174,
    "lng": -94.70157,
    "state_id": "IA"
  },
  {
    "zip": 50538,
    "lat": 42.27663,
    "lng": -94.4296,
    "state_id": "IA"
  },
  {
    "zip": 50539,
    "lat": 43.23703,
    "lng": -94.41999,
    "state_id": "IA"
  },
  {
    "zip": 50540,
    "lat": 42.59651,
    "lng": -94.842,
    "state_id": "IA"
  },
  {
    "zip": 50541,
    "lat": 42.71549,
    "lng": -94.44915,
    "state_id": "IA"
  },
  {
    "zip": 50542,
    "lat": 42.77759,
    "lng": -93.94493,
    "state_id": "IA"
  },
  {
    "zip": 50543,
    "lat": 42.27473,
    "lng": -94.30461,
    "state_id": "IA"
  },
  {
    "zip": 50544,
    "lat": 42.25071,
    "lng": -94.17053,
    "state_id": "IA"
  },
  {
    "zip": 50545,
    "lat": 42.79948,
    "lng": -94.08111,
    "state_id": "IA"
  },
  {
    "zip": 50546,
    "lat": 42.83787,
    "lng": -94.7028,
    "state_id": "IA"
  },
  {
    "zip": 50548,
    "lat": 42.71503,
    "lng": -94.22545,
    "state_id": "IA"
  },
  {
    "zip": 50551,
    "lat": 42.47954,
    "lng": -94.75105,
    "state_id": "IA"
  },
  {
    "zip": 50554,
    "lat": 42.84555,
    "lng": -94.84291,
    "state_id": "IA"
  },
  {
    "zip": 50556,
    "lat": 43.45256,
    "lng": -94.1961,
    "state_id": "IA"
  },
  {
    "zip": 50557,
    "lat": 42.35614,
    "lng": -94.04052,
    "state_id": "IA"
  },
  {
    "zip": 50558,
    "lat": 42.86105,
    "lng": -94.1652,
    "state_id": "IA"
  },
  {
    "zip": 50559,
    "lat": 43.20888,
    "lng": -94.32922,
    "state_id": "IA"
  },
  {
    "zip": 50560,
    "lat": 42.96058,
    "lng": -94.10802,
    "state_id": "IA"
  },
  {
    "zip": 50561,
    "lat": 42.42412,
    "lng": -94.84988,
    "state_id": "IA"
  },
  {
    "zip": 50562,
    "lat": 42.96153,
    "lng": -94.64178,
    "state_id": "IA"
  },
  {
    "zip": 50563,
    "lat": 42.52767,
    "lng": -94.52741,
    "state_id": "IA"
  },
  {
    "zip": 50565,
    "lat": 42.85774,
    "lng": -94.99572,
    "state_id": "IA"
  },
  {
    "zip": 50566,
    "lat": 42.43883,
    "lng": -94.32967,
    "state_id": "IA"
  },
  {
    "zip": 50567,
    "lat": 42.52311,
    "lng": -95.09631,
    "state_id": "IA"
  },
  {
    "zip": 50568,
    "lat": 42.62221,
    "lng": -94.99333,
    "state_id": "IA"
  },
  {
    "zip": 50569,
    "lat": 42.40303,
    "lng": -94.13872,
    "state_id": "IA"
  },
  {
    "zip": 50570,
    "lat": 42.89455,
    "lng": -94.36848,
    "state_id": "IA"
  },
  {
    "zip": 50571,
    "lat": 42.63095,
    "lng": -94.57859,
    "state_id": "IA"
  },
  {
    "zip": 50573,
    "lat": 42.87674,
    "lng": -94.62382,
    "state_id": "IA"
  },
  {
    "zip": 50574,
    "lat": 42.71668,
    "lng": -94.69859,
    "state_id": "IA"
  },
  {
    "zip": 50575,
    "lat": 42.56038,
    "lng": -94.70053,
    "state_id": "IA"
  },
  {
    "zip": 50576,
    "lat": 42.80326,
    "lng": -95.17528,
    "state_id": "IA"
  },
  {
    "zip": 50577,
    "lat": 42.85884,
    "lng": -93.97707,
    "state_id": "IA"
  },
  {
    "zip": 50578,
    "lat": 43.29968,
    "lng": -94.54591,
    "state_id": "IA"
  },
  {
    "zip": 50579,
    "lat": 42.39404,
    "lng": -94.63855,
    "state_id": "IA"
  },
  {
    "zip": 50581,
    "lat": 42.84801,
    "lng": -94.54307,
    "state_id": "IA"
  },
  {
    "zip": 50582,
    "lat": 42.79502,
    "lng": -94.30163,
    "state_id": "IA"
  },
  {
    "zip": 50583,
    "lat": 42.43266,
    "lng": -94.98656,
    "state_id": "IA"
  },
  {
    "zip": 50585,
    "lat": 42.91313,
    "lng": -95.13809,
    "state_id": "IA"
  },
  {
    "zip": 50586,
    "lat": 42.40411,
    "lng": -94.43174,
    "state_id": "IA"
  },
  {
    "zip": 50588,
    "lat": 42.65776,
    "lng": -95.16434,
    "state_id": "IA"
  },
  {
    "zip": 50590,
    "lat": 43.40756,
    "lng": -94.30542,
    "state_id": "IA"
  },
  {
    "zip": 50591,
    "lat": 42.67602,
    "lng": -94.0557,
    "state_id": "IA"
  },
  {
    "zip": 50593,
    "lat": 42.65716,
    "lng": -94.89883,
    "state_id": "IA"
  },
  {
    "zip": 50594,
    "lat": 42.5852,
    "lng": -94.04011,
    "state_id": "IA"
  },
  {
    "zip": 50595,
    "lat": 42.46184,
    "lng": -93.82268,
    "state_id": "IA"
  },
  {
    "zip": 50597,
    "lat": 42.97603,
    "lng": -94.45095,
    "state_id": "IA"
  },
  {
    "zip": 50598,
    "lat": 43.08188,
    "lng": -94.41708,
    "state_id": "IA"
  },
  {
    "zip": 50599,
    "lat": 42.57894,
    "lng": -93.81385,
    "state_id": "IA"
  },
  {
    "zip": 50601,
    "lat": 42.56684,
    "lng": -93.05642,
    "state_id": "IA"
  },
  {
    "zip": 50602,
    "lat": 42.74109,
    "lng": -92.80312,
    "state_id": "IA"
  },
  {
    "zip": 50603,
    "lat": 43.18771,
    "lng": -92.45952,
    "state_id": "IA"
  },
  {
    "zip": 50604,
    "lat": 42.60345,
    "lng": -92.90396,
    "state_id": "IA"
  },
  {
    "zip": 50605,
    "lat": 42.83176,
    "lng": -93.02091,
    "state_id": "IA"
  },
  {
    "zip": 50606,
    "lat": 42.74733,
    "lng": -91.6839,
    "state_id": "IA"
  },
  {
    "zip": 50607,
    "lat": 42.61067,
    "lng": -91.74982,
    "state_id": "IA"
  },
  {
    "zip": 50609,
    "lat": 42.22993,
    "lng": -92.80985,
    "state_id": "IA"
  },
  {
    "zip": 50611,
    "lat": 42.81547,
    "lng": -92.90896,
    "state_id": "IA"
  },
  {
    "zip": 50612,
    "lat": 42.28344,
    "lng": -92.38606,
    "state_id": "IA"
  },
  {
    "zip": 50613,
    "lat": 42.53309,
    "lng": -92.48895,
    "state_id": "IA"
  },
  {
    "zip": 50616,
    "lat": 43.0812,
    "lng": -92.66096,
    "state_id": "IA"
  },
  {
    "zip": 50619,
    "lat": 42.80178,
    "lng": -92.65563,
    "state_id": "IA"
  },
  {
    "zip": 50620,
    "lat": 43.15842,
    "lng": -92.59055,
    "state_id": "IA"
  },
  {
    "zip": 50621,
    "lat": 42.2511,
    "lng": -92.92101,
    "state_id": "IA"
  },
  {
    "zip": 50622,
    "lat": 42.67305,
    "lng": -92.3352,
    "state_id": "IA"
  },
  {
    "zip": 50624,
    "lat": 42.46782,
    "lng": -92.65873,
    "state_id": "IA"
  },
  {
    "zip": 50625,
    "lat": 42.74821,
    "lng": -92.98611,
    "state_id": "IA"
  },
  {
    "zip": 50626,
    "lat": 42.57816,
    "lng": -92.17095,
    "state_id": "IA"
  },
  {
    "zip": 50627,
    "lat": 42.35055,
    "lng": -93.09573,
    "state_id": "IA"
  },
  {
    "zip": 50628,
    "lat": 43.26719,
    "lng": -92.39069,
    "state_id": "IA"
  },
  {
    "zip": 50629,
    "lat": 42.6424,
    "lng": -92.08067,
    "state_id": "IA"
  },
  {
    "zip": 50630,
    "lat": 42.95627,
    "lng": -92.2127,
    "state_id": "IA"
  },
  {
    "zip": 50632,
    "lat": 42.07524,
    "lng": -92.70334,
    "state_id": "IA"
  },
  {
    "zip": 50633,
    "lat": 42.67127,
    "lng": -93.12857,
    "state_id": "IA"
  },
  {
    "zip": 50634,
    "lat": 42.41892,
    "lng": -92.21456,
    "state_id": "IA"
  },
  {
    "zip": 50635,
    "lat": 42.20208,
    "lng": -92.69932,
    "state_id": "IA"
  },
  {
    "zip": 50636,
    "lat": 42.89517,
    "lng": -92.80925,
    "state_id": "IA"
  },
  {
    "zip": 50638,
    "lat": 42.35673,
    "lng": -92.79394,
    "state_id": "IA"
  },
  {
    "zip": 50641,
    "lat": 42.59932,
    "lng": -91.91993,
    "state_id": "IA"
  },
  {
    "zip": 50642,
    "lat": 42.43977,
    "lng": -92.81271,
    "state_id": "IA"
  },
  {
    "zip": 50643,
    "lat": 42.36189,
    "lng": -92.46865,
    "state_id": "IA"
  },
  {
    "zip": 50644,
    "lat": 42.47638,
    "lng": -91.89355,
    "state_id": "IA"
  },
  {
    "zip": 50645,
    "lat": 43.0201,
    "lng": -92.45139,
    "state_id": "IA"
  },
  {
    "zip": 50647,
    "lat": 42.64885,
    "lng": -92.48336,
    "state_id": "IA"
  },
  {
    "zip": 50648,
    "lat": 42.45225,
    "lng": -92.09253,
    "state_id": "IA"
  },
  {
    "zip": 50650,
    "lat": 42.61899,
    "lng": -91.65459,
    "state_id": "IA"
  },
  {
    "zip": 50651,
    "lat": 42.31721,
    "lng": -92.19695,
    "state_id": "IA"
  },
  {
    "zip": 50652,
    "lat": 42.26429,
    "lng": -92.69376,
    "state_id": "IA"
  },
  {
    "zip": 50653,
    "lat": 42.96591,
    "lng": -92.89219,
    "state_id": "IA"
  },
  {
    "zip": 50654,
    "lat": 42.45198,
    "lng": -91.60916,
    "state_id": "IA"
  },
  {
    "zip": 50655,
    "lat": 42.77295,
    "lng": -91.90037,
    "state_id": "IA"
  },
  {
    "zip": 50658,
    "lat": 42.95699,
    "lng": -92.53563,
    "state_id": "IA"
  },
  {
    "zip": 50659,
    "lat": 43.08814,
    "lng": -92.32253,
    "state_id": "IA"
  },
  {
    "zip": 50660,
    "lat": 42.58307,
    "lng": -92.6327,
    "state_id": "IA"
  },
  {
    "zip": 50662,
    "lat": 42.6904,
    "lng": -91.93483,
    "state_id": "IA"
  },
  {
    "zip": 50664,
    "lat": 42.70125,
    "lng": -92.07619,
    "state_id": "IA"
  },
  {
    "zip": 50665,
    "lat": 42.58037,
    "lng": -92.77191,
    "state_id": "IA"
  },
  {
    "zip": 50666,
    "lat": 42.86442,
    "lng": -92.50692,
    "state_id": "IA"
  },
  {
    "zip": 50667,
    "lat": 42.47028,
    "lng": -92.2264,
    "state_id": "IA"
  },
  {
    "zip": 50668,
    "lat": 42.69069,
    "lng": -92.2265,
    "state_id": "IA"
  },
  {
    "zip": 50669,
    "lat": 42.33212,
    "lng": -92.6077,
    "state_id": "IA"
  },
  {
    "zip": 50670,
    "lat": 42.68687,
    "lng": -92.61615,
    "state_id": "IA"
  },
  {
    "zip": 50671,
    "lat": 42.65231,
    "lng": -91.80091,
    "state_id": "IA"
  },
  {
    "zip": 50672,
    "lat": 42.4252,
    "lng": -93.0632,
    "state_id": "IA"
  },
  {
    "zip": 50673,
    "lat": 42.52495,
    "lng": -92.71127,
    "state_id": "IA"
  },
  {
    "zip": 50674,
    "lat": 42.84098,
    "lng": -92.11881,
    "state_id": "IA"
  },
  {
    "zip": 50675,
    "lat": 42.20102,
    "lng": -92.4917,
    "state_id": "IA"
  },
  {
    "zip": 50676,
    "lat": 42.79629,
    "lng": -92.27961,
    "state_id": "IA"
  },
  {
    "zip": 50677,
    "lat": 42.76264,
    "lng": -92.43273,
    "state_id": "IA"
  },
  {
    "zip": 50680,
    "lat": 42.45212,
    "lng": -92.92301,
    "state_id": "IA"
  },
  {
    "zip": 50681,
    "lat": 42.7776,
    "lng": -92.00941,
    "state_id": "IA"
  },
  {
    "zip": 50682,
    "lat": 42.45328,
    "lng": -91.69678,
    "state_id": "IA"
  },
  {
    "zip": 50701,
    "lat": 42.41584,
    "lng": -92.34144,
    "state_id": "IA"
  },
  {
    "zip": 50702,
    "lat": 42.45745,
    "lng": -92.31237,
    "state_id": "IA"
  },
  {
    "zip": 50703,
    "lat": 42.54851,
    "lng": -92.28049,
    "state_id": "IA"
  },
  {
    "zip": 50707,
    "lat": 42.47933,
    "lng": -92.27892,
    "state_id": "IA"
  },
  {
    "zip": 50801,
    "lat": 41.07972,
    "lng": -94.39127,
    "state_id": "IA"
  },
  {
    "zip": 50830,
    "lat": 41.02717,
    "lng": -94.19089,
    "state_id": "IA"
  },
  {
    "zip": 50833,
    "lat": 40.6704,
    "lng": -94.70071,
    "state_id": "IA"
  },
  {
    "zip": 50835,
    "lat": 40.70813,
    "lng": -94.36888,
    "state_id": "IA"
  },
  {
    "zip": 50836,
    "lat": 40.62772,
    "lng": -94.49636,
    "state_id": "IA"
  },
  {
    "zip": 50837,
    "lat": 41.22898,
    "lng": -94.68109,
    "state_id": "IA"
  },
  {
    "zip": 50839,
    "lat": 41.04966,
    "lng": -94.82398,
    "state_id": "IA"
  },
  {
    "zip": 50840,
    "lat": 40.7913,
    "lng": -94.48525,
    "state_id": "IA"
  },
  {
    "zip": 50841,
    "lat": 41.00973,
    "lng": -94.76344,
    "state_id": "IA"
  },
  {
    "zip": 50842,
    "lat": 41.03987,
    "lng": -94.4618,
    "state_id": "IA"
  },
  {
    "zip": 50843,
    "lat": 41.23973,
    "lng": -94.88901,
    "state_id": "IA"
  },
  {
    "zip": 50845,
    "lat": 40.81871,
    "lng": -94.35236,
    "state_id": "IA"
  },
  {
    "zip": 50846,
    "lat": 41.30961,
    "lng": -94.56974,
    "state_id": "IA"
  },
  {
    "zip": 50847,
    "lat": 41.14112,
    "lng": -94.98165,
    "state_id": "IA"
  },
  {
    "zip": 50848,
    "lat": 40.78371,
    "lng": -94.76523,
    "state_id": "IA"
  },
  {
    "zip": 50849,
    "lat": 41.30326,
    "lng": -94.39942,
    "state_id": "IA"
  },
  {
    "zip": 50851,
    "lat": 40.90322,
    "lng": -94.53761,
    "state_id": "IA"
  },
  {
    "zip": 50853,
    "lat": 41.23979,
    "lng": -94.77067,
    "state_id": "IA"
  },
  {
    "zip": 50854,
    "lat": 40.69107,
    "lng": -94.21526,
    "state_id": "IA"
  },
  {
    "zip": 50857,
    "lat": 40.94349,
    "lng": -94.87875,
    "state_id": "IA"
  },
  {
    "zip": 50858,
    "lat": 41.21055,
    "lng": -94.41292,
    "state_id": "IA"
  },
  {
    "zip": 50859,
    "lat": 41.06719,
    "lng": -94.60106,
    "state_id": "IA"
  },
  {
    "zip": 50860,
    "lat": 40.62377,
    "lng": -94.34049,
    "state_id": "IA"
  },
  {
    "zip": 50861,
    "lat": 40.91157,
    "lng": -94.2565,
    "state_id": "IA"
  },
  {
    "zip": 50862,
    "lat": 40.81279,
    "lng": -94.65336,
    "state_id": "IA"
  },
  {
    "zip": 50863,
    "lat": 40.84241,
    "lng": -94.16795,
    "state_id": "IA"
  },
  {
    "zip": 50864,
    "lat": 40.97373,
    "lng": -94.98216,
    "state_id": "IA"
  },
  {
    "zip": 51001,
    "lat": 42.83311,
    "lng": -96.52235,
    "state_id": "IA"
  },
  {
    "zip": 51002,
    "lat": 42.69791,
    "lng": -95.31991,
    "state_id": "IA"
  },
  {
    "zip": 51003,
    "lat": 42.98503,
    "lng": -95.98664,
    "state_id": "IA"
  },
  {
    "zip": 51004,
    "lat": 42.3761,
    "lng": -95.9147,
    "state_id": "IA"
  },
  {
    "zip": 51005,
    "lat": 42.72154,
    "lng": -95.4249,
    "state_id": "IA"
  },
  {
    "zip": 51006,
    "lat": 42.31588,
    "lng": -95.6156,
    "state_id": "IA"
  },
  {
    "zip": 51007,
    "lat": 42.40397,
    "lng": -96.18705,
    "state_id": "IA"
  },
  {
    "zip": 51008,
    "lat": 42.81092,
    "lng": -96.26691,
    "state_id": "IA"
  },
  {
    "zip": 51009,
    "lat": 42.94431,
    "lng": -95.55152,
    "state_id": "IA"
  },
  {
    "zip": 51010,
    "lat": 42.08962,
    "lng": -95.90927,
    "state_id": "IA"
  },
  {
    "zip": 51011,
    "lat": 42.91631,
    "lng": -96.51445,
    "state_id": "IA"
  },
  {
    "zip": 51012,
    "lat": 42.74445,
    "lng": -95.56191,
    "state_id": "IA"
  },
  {
    "zip": 51014,
    "lat": 42.78564,
    "lng": -95.71073,
    "state_id": "IA"
  },
  {
    "zip": 51016,
    "lat": 42.4707,
    "lng": -95.8062,
    "state_id": "IA"
  },
  {
    "zip": 51018,
    "lat": 42.44717,
    "lng": -95.68223,
    "state_id": "IA"
  },
  {
    "zip": 51019,
    "lat": 42.27019,
    "lng": -95.72793,
    "state_id": "IA"
  },
  {
    "zip": 51020,
    "lat": 42.51064,
    "lng": -95.41163,
    "state_id": "IA"
  },
  {
    "zip": 51022,
    "lat": 42.97695,
    "lng": -95.85,
    "state_id": "IA"
  },
  {
    "zip": 51023,
    "lat": 43.01335,
    "lng": -96.45389,
    "state_id": "IA"
  },
  {
    "zip": 51024,
    "lat": 42.6184,
    "lng": -96.2666,
    "state_id": "IA"
  },
  {
    "zip": 51025,
    "lat": 42.49745,
    "lng": -95.55756,
    "state_id": "IA"
  },
  {
    "zip": 51026,
    "lat": 42.25059,
    "lng": -96.07447,
    "state_id": "IA"
  },
  {
    "zip": 51027,
    "lat": 42.96556,
    "lng": -96.32775,
    "state_id": "IA"
  },
  {
    "zip": 51028,
    "lat": 42.60001,
    "lng": -95.98665,
    "state_id": "IA"
  },
  {
    "zip": 51029,
    "lat": 42.88057,
    "lng": -95.53787,
    "state_id": "IA"
  },
  {
    "zip": 51030,
    "lat": 42.49794,
    "lng": -96.19256,
    "state_id": "IA"
  },
  {
    "zip": 51031,
    "lat": 42.80163,
    "lng": -96.17911,
    "state_id": "IA"
  },
  {
    "zip": 51033,
    "lat": 42.91705,
    "lng": -95.25031,
    "state_id": "IA"
  },
  {
    "zip": 51034,
    "lat": 42.15945,
    "lng": -95.78911,
    "state_id": "IA"
  },
  {
    "zip": 51035,
    "lat": 42.77878,
    "lng": -95.79948,
    "state_id": "IA"
  },
  {
    "zip": 51036,
    "lat": 42.96902,
    "lng": -96.18999,
    "state_id": "IA"
  },
  {
    "zip": 51037,
    "lat": 42.82664,
    "lng": -95.64314,
    "state_id": "IA"
  },
  {
    "zip": 51038,
    "lat": 42.70552,
    "lng": -96.31433,
    "state_id": "IA"
  },
  {
    "zip": 51039,
    "lat": 42.45685,
    "lng": -96.05389,
    "state_id": "IA"
  },
  {
    "zip": 51040,
    "lat": 42.0365,
    "lng": -96.08046,
    "state_id": "IA"
  },
  {
    "zip": 51041,
    "lat": 43.02612,
    "lng": -96.07792,
    "state_id": "IA"
  },
  {
    "zip": 51044,
    "lat": 42.29926,
    "lng": -95.92022,
    "state_id": "IA"
  },
  {
    "zip": 51046,
    "lat": 42.96436,
    "lng": -95.67832,
    "state_id": "IA"
  },
  {
    "zip": 51047,
    "lat": 42.93568,
    "lng": -95.36129,
    "state_id": "IA"
  },
  {
    "zip": 51048,
    "lat": 42.57519,
    "lng": -95.86175,
    "state_id": "IA"
  },
  {
    "zip": 51049,
    "lat": 42.62942,
    "lng": -95.64804,
    "state_id": "IA"
  },
  {
    "zip": 51050,
    "lat": 42.79362,
    "lng": -95.94595,
    "state_id": "IA"
  },
  {
    "zip": 51051,
    "lat": 42.20444,
    "lng": -95.97015,
    "state_id": "IA"
  },
  {
    "zip": 51052,
    "lat": 42.31471,
    "lng": -96.26502,
    "state_id": "IA"
  },
  {
    "zip": 51053,
    "lat": 42.49383,
    "lng": -95.2855,
    "state_id": "IA"
  },
  {
    "zip": 51054,
    "lat": 42.37974,
    "lng": -96.33027,
    "state_id": "IA"
  },
  {
    "zip": 51055,
    "lat": 42.21586,
    "lng": -96.25672,
    "state_id": "IA"
  },
  {
    "zip": 51056,
    "lat": 42.23547,
    "lng": -95.95351,
    "state_id": "IA"
  },
  {
    "zip": 51058,
    "lat": 42.98161,
    "lng": -95.47097,
    "state_id": "IA"
  },
  {
    "zip": 51060,
    "lat": 42.04157,
    "lng": -95.69791,
    "state_id": "IA"
  },
  {
    "zip": 51061,
    "lat": 42.57853,
    "lng": -95.71807,
    "state_id": "IA"
  },
  {
    "zip": 51062,
    "lat": 42.70701,
    "lng": -96.53667,
    "state_id": "IA"
  },
  {
    "zip": 51063,
    "lat": 42.14724,
    "lng": -96.17462,
    "state_id": "IA"
  },
  {
    "zip": 51101,
    "lat": 42.49406,
    "lng": -96.39361,
    "state_id": "IA"
  },
  {
    "zip": 51103,
    "lat": 42.51807,
    "lng": -96.4428,
    "state_id": "IA"
  },
  {
    "zip": 51104,
    "lat": 42.53542,
    "lng": -96.40413,
    "state_id": "IA"
  },
  {
    "zip": 51105,
    "lat": 42.50866,
    "lng": -96.36643,
    "state_id": "IA"
  },
  {
    "zip": 51106,
    "lat": 42.46615,
    "lng": -96.32082,
    "state_id": "IA"
  },
  {
    "zip": 51108,
    "lat": 42.5669,
    "lng": -96.35227,
    "state_id": "IA"
  },
  {
    "zip": 51109,
    "lat": 42.59486,
    "lng": -96.47856,
    "state_id": "IA"
  },
  {
    "zip": 51111,
    "lat": 42.40999,
    "lng": -96.38638,
    "state_id": "IA"
  },
  {
    "zip": 51201,
    "lat": 43.18336,
    "lng": -95.86572,
    "state_id": "IA"
  },
  {
    "zip": 51230,
    "lat": 43.36033,
    "lng": -96.32367,
    "state_id": "IA"
  },
  {
    "zip": 51231,
    "lat": 43.10312,
    "lng": -95.74219,
    "state_id": "IA"
  },
  {
    "zip": 51232,
    "lat": 43.30166,
    "lng": -95.81755,
    "state_id": "IA"
  },
  {
    "zip": 51234,
    "lat": 43.19407,
    "lng": -96.01993,
    "state_id": "IA"
  },
  {
    "zip": 51235,
    "lat": 43.29454,
    "lng": -96.21823,
    "state_id": "IA"
  },
  {
    "zip": 51237,
    "lat": 43.33672,
    "lng": -96.00067,
    "state_id": "IA"
  },
  {
    "zip": 51238,
    "lat": 43.07667,
    "lng": -95.89465,
    "state_id": "IA"
  },
  {
    "zip": 51239,
    "lat": 43.20131,
    "lng": -96.14576,
    "state_id": "IA"
  },
  {
    "zip": 51240,
    "lat": 43.30949,
    "lng": -96.45715,
    "state_id": "IA"
  },
  {
    "zip": 51241,
    "lat": 43.44966,
    "lng": -96.45993,
    "state_id": "IA"
  },
  {
    "zip": 51242,
    "lat": 43.44113,
    "lng": -96.33481,
    "state_id": "IA"
  },
  {
    "zip": 51243,
    "lat": 43.44247,
    "lng": -95.90405,
    "state_id": "IA"
  },
  {
    "zip": 51244,
    "lat": 43.24479,
    "lng": -95.93544,
    "state_id": "IA"
  },
  {
    "zip": 51245,
    "lat": 43.07473,
    "lng": -95.59999,
    "state_id": "IA"
  },
  {
    "zip": 51246,
    "lat": 43.42831,
    "lng": -96.16717,
    "state_id": "IA"
  },
  {
    "zip": 51247,
    "lat": 43.19133,
    "lng": -96.33947,
    "state_id": "IA"
  },
  {
    "zip": 51248,
    "lat": 43.20555,
    "lng": -95.65783,
    "state_id": "IA"
  },
  {
    "zip": 51249,
    "lat": 43.41257,
    "lng": -95.73207,
    "state_id": "IA"
  },
  {
    "zip": 51250,
    "lat": 43.08428,
    "lng": -96.2124,
    "state_id": "IA"
  },
  {
    "zip": 51301,
    "lat": 43.15289,
    "lng": -95.14724,
    "state_id": "IA"
  },
  {
    "zip": 51331,
    "lat": 43.36379,
    "lng": -95.12808,
    "state_id": "IA"
  },
  {
    "zip": 51333,
    "lat": 43.12798,
    "lng": -94.99965,
    "state_id": "IA"
  },
  {
    "zip": 51334,
    "lat": 43.4036,
    "lng": -94.80508,
    "state_id": "IA"
  },
  {
    "zip": 51338,
    "lat": 43.20596,
    "lng": -95.32193,
    "state_id": "IA"
  },
  {
    "zip": 51341,
    "lat": 43.01413,
    "lng": -95.03872,
    "state_id": "IA"
  },
  {
    "zip": 51342,
    "lat": 43.24395,
    "lng": -94.73795,
    "state_id": "IA"
  },
  {
    "zip": 51343,
    "lat": 43.0158,
    "lng": -95.10568,
    "state_id": "IA"
  },
  {
    "zip": 51345,
    "lat": 43.41265,
    "lng": -95.42922,
    "state_id": "IA"
  },
  {
    "zip": 51346,
    "lat": 43.18233,
    "lng": -95.46082,
    "state_id": "IA"
  },
  {
    "zip": 51347,
    "lat": 43.42329,
    "lng": -95.31427,
    "state_id": "IA"
  },
  {
    "zip": 51350,
    "lat": 43.31097,
    "lng": -95.60083,
    "state_id": "IA"
  },
  {
    "zip": 51351,
    "lat": 43.31658,
    "lng": -95.18692,
    "state_id": "IA"
  },
  {
    "zip": 51354,
    "lat": 43.41211,
    "lng": -95.53985,
    "state_id": "IA"
  },
  {
    "zip": 51355,
    "lat": 43.38871,
    "lng": -95.13672,
    "state_id": "IA"
  },
  {
    "zip": 51357,
    "lat": 43.06075,
    "lng": -95.28063,
    "state_id": "IA"
  },
  {
    "zip": 51358,
    "lat": 43.13121,
    "lng": -94.89974,
    "state_id": "IA"
  },
  {
    "zip": 51360,
    "lat": 43.4425,
    "lng": -95.08759,
    "state_id": "IA"
  },
  {
    "zip": 51363,
    "lat": 43.43359,
    "lng": -94.94672,
    "state_id": "IA"
  },
  {
    "zip": 51364,
    "lat": 43.29958,
    "lng": -94.96542,
    "state_id": "IA"
  },
  {
    "zip": 51365,
    "lat": 43.30797,
    "lng": -94.73681,
    "state_id": "IA"
  },
  {
    "zip": 51366,
    "lat": 42.95917,
    "lng": -95.00773,
    "state_id": "IA"
  },
  {
    "zip": 51401,
    "lat": 42.06615,
    "lng": -94.86957,
    "state_id": "IA"
  },
  {
    "zip": 51430,
    "lat": 42.086,
    "lng": -95.02556,
    "state_id": "IA"
  },
  {
    "zip": 51431,
    "lat": 42.34488,
    "lng": -95.35168,
    "state_id": "IA"
  },
  {
    "zip": 51433,
    "lat": 42.27619,
    "lng": -94.87114,
    "state_id": "IA"
  },
  {
    "zip": 51436,
    "lat": 42.18516,
    "lng": -95.01671,
    "state_id": "IA"
  },
  {
    "zip": 51439,
    "lat": 42.08473,
    "lng": -95.58489,
    "state_id": "IA"
  },
  {
    "zip": 51440,
    "lat": 41.91334,
    "lng": -94.81294,
    "state_id": "IA"
  },
  {
    "zip": 51441,
    "lat": 42.12097,
    "lng": -95.3167,
    "state_id": "IA"
  },
  {
    "zip": 51442,
    "lat": 42.03038,
    "lng": -95.35836,
    "state_id": "IA"
  },
  {
    "zip": 51443,
    "lat": 42.08044,
    "lng": -94.7025,
    "state_id": "IA"
  },
  {
    "zip": 51444,
    "lat": 42.00485,
    "lng": -94.97312,
    "state_id": "IA"
  },
  {
    "zip": 51445,
    "lat": 42.32447,
    "lng": -95.46452,
    "state_id": "IA"
  },
  {
    "zip": 51446,
    "lat": 41.77512,
    "lng": -95.19191,
    "state_id": "IA"
  },
  {
    "zip": 51447,
    "lat": 41.7221,
    "lng": -95.19595,
    "state_id": "IA"
  },
  {
    "zip": 51448,
    "lat": 42.20349,
    "lng": -95.30768,
    "state_id": "IA"
  },
  {
    "zip": 51449,
    "lat": 42.26814,
    "lng": -94.7345,
    "state_id": "IA"
  },
  {
    "zip": 51450,
    "lat": 42.31322,
    "lng": -95.03107,
    "state_id": "IA"
  },
  {
    "zip": 51451,
    "lat": 42.18453,
    "lng": -94.69468,
    "state_id": "IA"
  },
  {
    "zip": 51453,
    "lat": 42.26946,
    "lng": -94.54712,
    "state_id": "IA"
  },
  {
    "zip": 51454,
    "lat": 41.87381,
    "lng": -95.20712,
    "state_id": "IA"
  },
  {
    "zip": 51455,
    "lat": 41.91142,
    "lng": -95.05564,
    "state_id": "IA"
  },
  {
    "zip": 51458,
    "lat": 42.32056,
    "lng": -95.23589,
    "state_id": "IA"
  },
  {
    "zip": 51459,
    "lat": 42.04521,
    "lng": -94.63797,
    "state_id": "IA"
  },
  {
    "zip": 51461,
    "lat": 42.1698,
    "lng": -95.48096,
    "state_id": "IA"
  },
  {
    "zip": 51462,
    "lat": 42.01069,
    "lng": -94.55635,
    "state_id": "IA"
  },
  {
    "zip": 51463,
    "lat": 41.90744,
    "lng": -94.91273,
    "state_id": "IA"
  },
  {
    "zip": 51465,
    "lat": 42.09191,
    "lng": -95.20201,
    "state_id": "IA"
  },
  {
    "zip": 51466,
    "lat": 42.25635,
    "lng": -95.10379,
    "state_id": "IA"
  },
  {
    "zip": 51467,
    "lat": 42.07189,
    "lng": -95.11509,
    "state_id": "IA"
  },
  {
    "zip": 51501,
    "lat": 41.22798,
    "lng": -95.87858,
    "state_id": "IA"
  },
  {
    "zip": 51503,
    "lat": 41.23203,
    "lng": -95.77811,
    "state_id": "IA"
  },
  {
    "zip": 51510,
    "lat": 41.28843,
    "lng": -95.91714,
    "state_id": "IA"
  },
  {
    "zip": 51520,
    "lat": 41.96961,
    "lng": -95.46146,
    "state_id": "IA"
  },
  {
    "zip": 51521,
    "lat": 41.47835,
    "lng": -95.34466,
    "state_id": "IA"
  },
  {
    "zip": 51523,
    "lat": 41.91325,
    "lng": -96.06795,
    "state_id": "IA"
  },
  {
    "zip": 51525,
    "lat": 41.23381,
    "lng": -95.41644,
    "state_id": "IA"
  },
  {
    "zip": 51526,
    "lat": 41.36876,
    "lng": -95.877,
    "state_id": "IA"
  },
  {
    "zip": 51527,
    "lat": 41.82572,
    "lng": -95.34817,
    "state_id": "IA"
  },
  {
    "zip": 51528,
    "lat": 41.92755,
    "lng": -95.50198,
    "state_id": "IA"
  },
  {
    "zip": 51529,
    "lat": 41.86033,
    "lng": -95.62573,
    "state_id": "IA"
  },
  {
    "zip": 51530,
    "lat": 41.78448,
    "lng": -95.43022,
    "state_id": "IA"
  },
  {
    "zip": 51531,
    "lat": 41.58868,
    "lng": -95.08721,
    "state_id": "IA"
  },
  {
    "zip": 51532,
    "lat": 41.13518,
    "lng": -95.11248,
    "state_id": "IA"
  },
  {
    "zip": 51533,
    "lat": 41.03613,
    "lng": -95.38042,
    "state_id": "IA"
  },
  {
    "zip": 51534,
    "lat": 41.04291,
    "lng": -95.71685,
    "state_id": "IA"
  },
  {
    "zip": 51535,
    "lat": 41.22411,
    "lng": -95.13004,
    "state_id": "IA"
  },
  {
    "zip": 51536,
    "lat": 41.38616,
    "lng": -95.36077,
    "state_id": "IA"
  },
  {
    "zip": 51537,
    "lat": 41.64756,
    "lng": -95.29293,
    "state_id": "IA"
  },
  {
    "zip": 51540,
    "lat": 41.02448,
    "lng": -95.49899,
    "state_id": "IA"
  },
  {
    "zip": 51541,
    "lat": 41.13446,
    "lng": -95.41759,
    "state_id": "IA"
  },
  {
    "zip": 51542,
    "lat": 41.43792,
    "lng": -95.84137,
    "state_id": "IA"
  },
  {
    "zip": 51543,
    "lat": 41.65667,
    "lng": -95.07595,
    "state_id": "IA"
  },
  {
    "zip": 51544,
    "lat": 41.31053,
    "lng": -95.1089,
    "state_id": "IA"
  },
  {
    "zip": 51545,
    "lat": 41.84029,
    "lng": -96.02555,
    "state_id": "IA"
  },
  {
    "zip": 51546,
    "lat": 41.64245,
    "lng": -95.77515,
    "state_id": "IA"
  },
  {
    "zip": 51548,
    "lat": 41.31795,
    "lng": -95.63696,
    "state_id": "IA"
  },
  {
    "zip": 51549,
    "lat": 41.18287,
    "lng": -95.45322,
    "state_id": "IA"
  },
  {
    "zip": 51550,
    "lat": 41.69215,
    "lng": -95.87416,
    "state_id": "IA"
  },
  {
    "zip": 51551,
    "lat": 40.99208,
    "lng": -95.58941,
    "state_id": "IA"
  },
  {
    "zip": 51552,
    "lat": 41.49075,
    "lng": -95.11349,
    "state_id": "IA"
  },
  {
    "zip": 51553,
    "lat": 41.42089,
    "lng": -95.54653,
    "state_id": "IA"
  },
  {
    "zip": 51554,
    "lat": 41.14738,
    "lng": -95.68136,
    "state_id": "IA"
  },
  {
    "zip": 51555,
    "lat": 41.55841,
    "lng": -95.91921,
    "state_id": "IA"
  },
  {
    "zip": 51556,
    "lat": 41.64287,
    "lng": -96.03096,
    "state_id": "IA"
  },
  {
    "zip": 51557,
    "lat": 41.73047,
    "lng": -95.9926,
    "state_id": "IA"
  },
  {
    "zip": 51558,
    "lat": 41.9049,
    "lng": -95.86228,
    "state_id": "IA"
  },
  {
    "zip": 51559,
    "lat": 41.46344,
    "lng": -95.66368,
    "state_id": "IA"
  },
  {
    "zip": 51560,
    "lat": 41.31712,
    "lng": -95.40166,
    "state_id": "IA"
  },
  {
    "zip": 51561,
    "lat": 41.00927,
    "lng": -95.80869,
    "state_id": "IA"
  },
  {
    "zip": 51562,
    "lat": 41.7257,
    "lng": -95.50274,
    "state_id": "IA"
  },
  {
    "zip": 51563,
    "lat": 41.57494,
    "lng": -95.59363,
    "state_id": "IA"
  },
  {
    "zip": 51564,
    "lat": 41.81606,
    "lng": -95.91198,
    "state_id": "IA"
  },
  {
    "zip": 51565,
    "lat": 41.64473,
    "lng": -95.5207,
    "state_id": "IA"
  },
  {
    "zip": 51566,
    "lat": 41.0163,
    "lng": -95.2313,
    "state_id": "IA"
  },
  {
    "zip": 51570,
    "lat": 41.52347,
    "lng": -95.45765,
    "state_id": "IA"
  },
  {
    "zip": 51571,
    "lat": 41.14531,
    "lng": -95.60924,
    "state_id": "IA"
  },
  {
    "zip": 51572,
    "lat": 41.99123,
    "lng": -95.78753,
    "state_id": "IA"
  },
  {
    "zip": 51573,
    "lat": 40.97948,
    "lng": -95.09532,
    "state_id": "IA"
  },
  {
    "zip": 51575,
    "lat": 41.24193,
    "lng": -95.60305,
    "state_id": "IA"
  },
  {
    "zip": 51576,
    "lat": 41.37728,
    "lng": -95.70979,
    "state_id": "IA"
  },
  {
    "zip": 51577,
    "lat": 41.46344,
    "lng": -95.20487,
    "state_id": "IA"
  },
  {
    "zip": 51578,
    "lat": 41.71937,
    "lng": -95.39456,
    "state_id": "IA"
  },
  {
    "zip": 51579,
    "lat": 41.7467,
    "lng": -95.70341,
    "state_id": "IA"
  },
  {
    "zip": 51601,
    "lat": 40.72616,
    "lng": -95.34978,
    "state_id": "IA"
  },
  {
    "zip": 51630,
    "lat": 40.60099,
    "lng": -95.19132,
    "state_id": "IA"
  },
  {
    "zip": 51631,
    "lat": 40.60217,
    "lng": -95.00781,
    "state_id": "IA"
  },
  {
    "zip": 51632,
    "lat": 40.74694,
    "lng": -95.04956,
    "state_id": "IA"
  },
  {
    "zip": 51636,
    "lat": 40.66725,
    "lng": -95.23255,
    "state_id": "IA"
  },
  {
    "zip": 51637,
    "lat": 40.6249,
    "lng": -95.11565,
    "state_id": "IA"
  },
  {
    "zip": 51638,
    "lat": 40.84261,
    "lng": -95.27171,
    "state_id": "IA"
  },
  {
    "zip": 51639,
    "lat": 40.71982,
    "lng": -95.47485,
    "state_id": "IA"
  },
  {
    "zip": 51640,
    "lat": 40.6229,
    "lng": -95.65642,
    "state_id": "IA"
  },
  {
    "zip": 51645,
    "lat": 40.88192,
    "lng": -95.4326,
    "state_id": "IA"
  },
  {
    "zip": 51646,
    "lat": 40.74651,
    "lng": -94.87583,
    "state_id": "IA"
  },
  {
    "zip": 51647,
    "lat": 40.60885,
    "lng": -95.31974,
    "state_id": "IA"
  },
  {
    "zip": 51648,
    "lat": 40.73376,
    "lng": -95.80879,
    "state_id": "IA"
  },
  {
    "zip": 51649,
    "lat": 40.8526,
    "lng": -95.53615,
    "state_id": "IA"
  },
  {
    "zip": 51650,
    "lat": 40.66665,
    "lng": -95.55374,
    "state_id": "IA"
  },
  {
    "zip": 51652,
    "lat": 40.77031,
    "lng": -95.62757,
    "state_id": "IA"
  },
  {
    "zip": 51653,
    "lat": 40.88427,
    "lng": -95.67215,
    "state_id": "IA"
  },
  {
    "zip": 51654,
    "lat": 40.83125,
    "lng": -95.76454,
    "state_id": "IA"
  },
  {
    "zip": 51656,
    "lat": 40.73471,
    "lng": -95.1563,
    "state_id": "IA"
  },
  {
    "zip": 52001,
    "lat": 42.54498,
    "lng": -90.6938,
    "state_id": "IA"
  },
  {
    "zip": 52002,
    "lat": 42.52478,
    "lng": -90.77322,
    "state_id": "IA"
  },
  {
    "zip": 52003,
    "lat": 42.43441,
    "lng": -90.67522,
    "state_id": "IA"
  },
  {
    "zip": 52030,
    "lat": 42.15329,
    "lng": -90.59188,
    "state_id": "IA"
  },
  {
    "zip": 52031,
    "lat": 42.24884,
    "lng": -90.4886,
    "state_id": "IA"
  },
  {
    "zip": 52032,
    "lat": 42.29108,
    "lng": -90.84194,
    "state_id": "IA"
  },
  {
    "zip": 52033,
    "lat": 42.27904,
    "lng": -91.00122,
    "state_id": "IA"
  },
  {
    "zip": 52035,
    "lat": 42.66072,
    "lng": -91.19094,
    "state_id": "IA"
  },
  {
    "zip": 52037,
    "lat": 41.96713,
    "lng": -90.62862,
    "state_id": "IA"
  },
  {
    "zip": 52038,
    "lat": 42.58561,
    "lng": -91.55823,
    "state_id": "IA"
  },
  {
    "zip": 52039,
    "lat": 42.53921,
    "lng": -90.86095,
    "state_id": "IA"
  },
  {
    "zip": 52040,
    "lat": 42.50814,
    "lng": -91.13598,
    "state_id": "IA"
  },
  {
    "zip": 52041,
    "lat": 42.50616,
    "lng": -91.25694,
    "state_id": "IA"
  },
  {
    "zip": 52042,
    "lat": 42.68118,
    "lng": -91.36804,
    "state_id": "IA"
  },
  {
    "zip": 52043,
    "lat": 42.84522,
    "lng": -91.41923,
    "state_id": "IA"
  },
  {
    "zip": 52044,
    "lat": 42.76904,
    "lng": -91.32355,
    "state_id": "IA"
  },
  {
    "zip": 52045,
    "lat": 42.46088,
    "lng": -90.93757,
    "state_id": "IA"
  },
  {
    "zip": 52046,
    "lat": 42.43833,
    "lng": -91.01309,
    "state_id": "IA"
  },
  {
    "zip": 52047,
    "lat": 42.96553,
    "lng": -91.34992,
    "state_id": "IA"
  },
  {
    "zip": 52048,
    "lat": 42.74216,
    "lng": -91.25724,
    "state_id": "IA"
  },
  {
    "zip": 52049,
    "lat": 42.88544,
    "lng": -91.20424,
    "state_id": "IA"
  },
  {
    "zip": 52050,
    "lat": 42.60481,
    "lng": -91.33469,
    "state_id": "IA"
  },
  {
    "zip": 52052,
    "lat": 42.74045,
    "lng": -91.11954,
    "state_id": "IA"
  },
  {
    "zip": 52053,
    "lat": 42.61163,
    "lng": -90.96505,
    "state_id": "IA"
  },
  {
    "zip": 52054,
    "lat": 42.30542,
    "lng": -90.6271,
    "state_id": "IA"
  },
  {
    "zip": 52057,
    "lat": 42.48979,
    "lng": -91.45472,
    "state_id": "IA"
  },
  {
    "zip": 52060,
    "lat": 42.10336,
    "lng": -90.68142,
    "state_id": "IA"
  },
  {
    "zip": 52064,
    "lat": 42.08862,
    "lng": -90.31241,
    "state_id": "IA"
  },
  {
    "zip": 52065,
    "lat": 42.58298,
    "lng": -91.10622,
    "state_id": "IA"
  },
  {
    "zip": 52066,
    "lat": 42.67839,
    "lng": -90.95184,
    "state_id": "IA"
  },
  {
    "zip": 52068,
    "lat": 42.42289,
    "lng": -90.81718,
    "state_id": "IA"
  },
  {
    "zip": 52069,
    "lat": 42.05839,
    "lng": -90.43244,
    "state_id": "IA"
  },
  {
    "zip": 52070,
    "lat": 42.0816,
    "lng": -90.2185,
    "state_id": "IA"
  },
  {
    "zip": 52072,
    "lat": 42.92198,
    "lng": -91.3778,
    "state_id": "IA"
  },
  {
    "zip": 52073,
    "lat": 42.62514,
    "lng": -90.82169,
    "state_id": "IA"
  },
  {
    "zip": 52074,
    "lat": 42.10943,
    "lng": -90.47243,
    "state_id": "IA"
  },
  {
    "zip": 52076,
    "lat": 42.69982,
    "lng": -91.51016,
    "state_id": "IA"
  },
  {
    "zip": 52077,
    "lat": 42.8225,
    "lng": -91.55697,
    "state_id": "IA"
  },
  {
    "zip": 52078,
    "lat": 42.39466,
    "lng": -91.11604,
    "state_id": "IA"
  },
  {
    "zip": 52079,
    "lat": 42.28552,
    "lng": -90.71624,
    "state_id": "IA"
  },
  {
    "zip": 52101,
    "lat": 43.35368,
    "lng": -91.76474,
    "state_id": "IA"
  },
  {
    "zip": 52132,
    "lat": 43.20618,
    "lng": -91.92323,
    "state_id": "IA"
  },
  {
    "zip": 52133,
    "lat": 43.10742,
    "lng": -91.66625,
    "state_id": "IA"
  },
  {
    "zip": 52134,
    "lat": 43.46957,
    "lng": -92.41122,
    "state_id": "IA"
  },
  {
    "zip": 52135,
    "lat": 43.01941,
    "lng": -91.66822,
    "state_id": "IA"
  },
  {
    "zip": 52136,
    "lat": 43.37403,
    "lng": -92.1112,
    "state_id": "IA"
  },
  {
    "zip": 52140,
    "lat": 43.44338,
    "lng": -91.53666,
    "state_id": "IA"
  },
  {
    "zip": 52141,
    "lat": 42.93934,
    "lng": -91.63086,
    "state_id": "IA"
  },
  {
    "zip": 52142,
    "lat": 42.82875,
    "lng": -91.7916,
    "state_id": "IA"
  },
  {
    "zip": 52144,
    "lat": 43.13915,
    "lng": -91.96241,
    "state_id": "IA"
  },
  {
    "zip": 52146,
    "lat": 43.18966,
    "lng": -91.19789,
    "state_id": "IA"
  },
  {
    "zip": 52147,
    "lat": 42.95107,
    "lng": -91.96285,
    "state_id": "IA"
  },
  {
    "zip": 52151,
    "lat": 43.3439,
    "lng": -91.27788,
    "state_id": "IA"
  },
  {
    "zip": 52154,
    "lat": 43.12258,
    "lng": -92.16531,
    "state_id": "IA"
  },
  {
    "zip": 52155,
    "lat": 43.41479,
    "lng": -92.29668,
    "state_id": "IA"
  },
  {
    "zip": 52156,
    "lat": 43.05254,
    "lng": -91.46234,
    "state_id": "IA"
  },
  {
    "zip": 52157,
    "lat": 43.01876,
    "lng": -91.23439,
    "state_id": "IA"
  },
  {
    "zip": 52158,
    "lat": 43.04387,
    "lng": -91.18663,
    "state_id": "IA"
  },
  {
    "zip": 52159,
    "lat": 43.07794,
    "lng": -91.35866,
    "state_id": "IA"
  },
  {
    "zip": 52160,
    "lat": 43.46599,
    "lng": -91.36386,
    "state_id": "IA"
  },
  {
    "zip": 52161,
    "lat": 43.12957,
    "lng": -91.75713,
    "state_id": "IA"
  },
  {
    "zip": 52162,
    "lat": 43.10757,
    "lng": -91.55162,
    "state_id": "IA"
  },
  {
    "zip": 52163,
    "lat": 43.21975,
    "lng": -92.10312,
    "state_id": "IA"
  },
  {
    "zip": 52164,
    "lat": 42.8502,
    "lng": -91.89511,
    "state_id": "IA"
  },
  {
    "zip": 52165,
    "lat": 43.30679,
    "lng": -91.98342,
    "state_id": "IA"
  },
  {
    "zip": 52166,
    "lat": 43.06592,
    "lng": -91.93343,
    "state_id": "IA"
  },
  {
    "zip": 52168,
    "lat": 43.20545,
    "lng": -91.95232,
    "state_id": "IA"
  },
  {
    "zip": 52169,
    "lat": 42.85318,
    "lng": -91.66429,
    "state_id": "IA"
  },
  {
    "zip": 52170,
    "lat": 43.22394,
    "lng": -91.30523,
    "state_id": "IA"
  },
  {
    "zip": 52171,
    "lat": 43.07364,
    "lng": -92.051,
    "state_id": "IA"
  },
  {
    "zip": 52172,
    "lat": 43.27079,
    "lng": -91.48449,
    "state_id": "IA"
  },
  {
    "zip": 52175,
    "lat": 42.99396,
    "lng": -91.82561,
    "state_id": "IA"
  },
  {
    "zip": 52201,
    "lat": 41.33233,
    "lng": -91.54614,
    "state_id": "IA"
  },
  {
    "zip": 52202,
    "lat": 42.15775,
    "lng": -91.63781,
    "state_id": "IA"
  },
  {
    "zip": 52203,
    "lat": 41.81227,
    "lng": -91.88652,
    "state_id": "IA"
  },
  {
    "zip": 52205,
    "lat": 42.11853,
    "lng": -91.28537,
    "state_id": "IA"
  },
  {
    "zip": 52206,
    "lat": 41.99159,
    "lng": -91.88547,
    "state_id": "IA"
  },
  {
    "zip": 52207,
    "lat": 42.09508,
    "lng": -90.83168,
    "state_id": "IA"
  },
  {
    "zip": 52208,
    "lat": 41.88283,
    "lng": -92.25768,
    "state_id": "IA"
  },
  {
    "zip": 52209,
    "lat": 41.9067,
    "lng": -92.0994,
    "state_id": "IA"
  },
  {
    "zip": 52210,
    "lat": 42.33982,
    "lng": -92.00458,
    "state_id": "IA"
  },
  {
    "zip": 52211,
    "lat": 41.76038,
    "lng": -92.44768,
    "state_id": "IA"
  },
  {
    "zip": 52212,
    "lat": 42.10885,
    "lng": -91.08569,
    "state_id": "IA"
  },
  {
    "zip": 52213,
    "lat": 42.19785,
    "lng": -91.77249,
    "state_id": "IA"
  },
  {
    "zip": 52214,
    "lat": 42.19095,
    "lng": -91.50686,
    "state_id": "IA"
  },
  {
    "zip": 52215,
    "lat": 41.90871,
    "lng": -92.39557,
    "state_id": "IA"
  },
  {
    "zip": 52216,
    "lat": 41.88701,
    "lng": -91.04235,
    "state_id": "IA"
  },
  {
    "zip": 52217,
    "lat": 42.07994,
    "lng": -92.40795,
    "state_id": "IA"
  },
  {
    "zip": 52218,
    "lat": 42.2925,
    "lng": -91.53606,
    "state_id": "IA"
  },
  {
    "zip": 52219,
    "lat": 42.23776,
    "lng": -91.42586,
    "state_id": "IA"
  },
  {
    "zip": 52220,
    "lat": 41.72826,
    "lng": -91.99844,
    "state_id": "IA"
  },
  {
    "zip": 52221,
    "lat": 41.64197,
    "lng": -92.33228,
    "state_id": "IA"
  },
  {
    "zip": 52222,
    "lat": 41.57307,
    "lng": -92.34078,
    "state_id": "IA"
  },
  {
    "zip": 52223,
    "lat": 42.41326,
    "lng": -91.32424,
    "state_id": "IA"
  },
  {
    "zip": 52224,
    "lat": 42.16565,
    "lng": -92.2996,
    "state_id": "IA"
  },
  {
    "zip": 52225,
    "lat": 42.01081,
    "lng": -92.32687,
    "state_id": "IA"
  },
  {
    "zip": 52227,
    "lat": 41.90006,
    "lng": -91.56278,
    "state_id": "IA"
  },
  {
    "zip": 52228,
    "lat": 41.90254,
    "lng": -91.79151,
    "state_id": "IA"
  },
  {
    "zip": 52229,
    "lat": 42.14562,
    "lng": -92.16776,
    "state_id": "IA"
  },
  {
    "zip": 52231,
    "lat": 41.36249,
    "lng": -92.05636,
    "state_id": "IA"
  },
  {
    "zip": 52232,
    "lat": 41.80266,
    "lng": -92.33595,
    "state_id": "IA"
  },
  {
    "zip": 52233,
    "lat": 42.04674,
    "lng": -91.68694,
    "state_id": "IA"
  },
  {
    "zip": 52235,
    "lat": 41.57221,
    "lng": -91.53201,
    "state_id": "IA"
  },
  {
    "zip": 52236,
    "lat": 41.73279,
    "lng": -91.8761,
    "state_id": "IA"
  },
  {
    "zip": 52237,
    "lat": 42.34467,
    "lng": -91.24829,
    "state_id": "IA"
  },
  {
    "zip": 52240,
    "lat": 41.63545,
    "lng": -91.50162,
    "state_id": "IA"
  },
  {
    "zip": 52241,
    "lat": 41.69869,
    "lng": -91.59474,
    "state_id": "IA"
  },
  {
    "zip": 52242,
    "lat": 41.66264,
    "lng": -91.54787,
    "state_id": "IA"
  },
  {
    "zip": 52245,
    "lat": 41.6708,
    "lng": -91.50768,
    "state_id": "IA"
  },
  {
    "zip": 52246,
    "lat": 41.65162,
    "lng": -91.5697,
    "state_id": "IA"
  },
  {
    "zip": 52247,
    "lat": 41.51488,
    "lng": -91.71884,
    "state_id": "IA"
  },
  {
    "zip": 52248,
    "lat": 41.34691,
    "lng": -91.93583,
    "state_id": "IA"
  },
  {
    "zip": 52249,
    "lat": 42.01417,
    "lng": -92.20543,
    "state_id": "IA"
  },
  {
    "zip": 52251,
    "lat": 41.70993,
    "lng": -92.20222,
    "state_id": "IA"
  },
  {
    "zip": 52253,
    "lat": 41.8966,
    "lng": -91.35123,
    "state_id": "IA"
  },
  {
    "zip": 52254,
    "lat": 41.95214,
    "lng": -90.80116,
    "state_id": "IA"
  },
  {
    "zip": 52255,
    "lat": 41.86364,
    "lng": -90.96132,
    "state_id": "IA"
  },
  {
    "zip": 52257,
    "lat": 41.92095,
    "lng": -92.17825,
    "state_id": "IA"
  },
  {
    "zip": 52301,
    "lat": 41.78838,
    "lng": -92.08411,
    "state_id": "IA"
  },
  {
    "zip": 52302,
    "lat": 42.06554,
    "lng": -91.57061,
    "state_id": "IA"
  },
  {
    "zip": 52305,
    "lat": 42.01924,
    "lng": -91.33917,
    "state_id": "IA"
  },
  {
    "zip": 52306,
    "lat": 41.89844,
    "lng": -91.25343,
    "state_id": "IA"
  },
  {
    "zip": 52307,
    "lat": 41.79297,
    "lng": -91.89884,
    "state_id": "IA"
  },
  {
    "zip": 52308,
    "lat": 41.57231,
    "lng": -92.15852,
    "state_id": "IA"
  },
  {
    "zip": 52309,
    "lat": 42.12354,
    "lng": -90.88873,
    "state_id": "IA"
  },
  {
    "zip": 52310,
    "lat": 42.22067,
    "lng": -91.19722,
    "state_id": "IA"
  },
  {
    "zip": 52312,
    "lat": 42.00605,
    "lng": -91.24587,
    "state_id": "IA"
  },
  {
    "zip": 52313,
    "lat": 42.25906,
    "lng": -92.09498,
    "state_id": "IA"
  },
  {
    "zip": 52314,
    "lat": 41.93381,
    "lng": -91.44866,
    "state_id": "IA"
  },
  {
    "zip": 52315,
    "lat": 42.00089,
    "lng": -91.97011,
    "state_id": "IA"
  },
  {
    "zip": 52316,
    "lat": 41.54311,
    "lng": -92.08857,
    "state_id": "IA"
  },
  {
    "zip": 52317,
    "lat": 41.76242,
    "lng": -91.63101,
    "state_id": "IA"
  },
  {
    "zip": 52318,
    "lat": 41.88927,
    "lng": -91.89274,
    "state_id": "IA"
  },
  {
    "zip": 52320,
    "lat": 41.99769,
    "lng": -91.14852,
    "state_id": "IA"
  },
  {
    "zip": 52321,
    "lat": 42.14722,
    "lng": -90.98325,
    "state_id": "IA"
  },
  {
    "zip": 52322,
    "lat": 41.6911,
    "lng": -91.76015,
    "state_id": "IA"
  },
  {
    "zip": 52323,
    "lat": 41.98445,
    "lng": -90.9598,
    "state_id": "IA"
  },
  {
    "zip": 52324,
    "lat": 42.06363,
    "lng": -91.80536,
    "state_id": "IA"
  },
  {
    "zip": 52325,
    "lat": 41.57949,
    "lng": -91.92401,
    "state_id": "IA"
  },
  {
    "zip": 52326,
    "lat": 42.39026,
    "lng": -91.75735,
    "state_id": "IA"
  },
  {
    "zip": 52327,
    "lat": 41.47637,
    "lng": -91.58101,
    "state_id": "IA"
  },
  {
    "zip": 52328,
    "lat": 42.07158,
    "lng": -91.66609,
    "state_id": "IA"
  },
  {
    "zip": 52329,
    "lat": 42.35766,
    "lng": -91.85573,
    "state_id": "IA"
  },
  {
    "zip": 52330,
    "lat": 42.34949,
    "lng": -91.50048,
    "state_id": "IA"
  },
  {
    "zip": 52332,
    "lat": 42.09884,
    "lng": -91.88842,
    "state_id": "IA"
  },
  {
    "zip": 52333,
    "lat": 41.80621,
    "lng": -91.49268,
    "state_id": "IA"
  },
  {
    "zip": 52334,
    "lat": 41.7402,
    "lng": -91.94109,
    "state_id": "IA"
  },
  {
    "zip": 52335,
    "lat": 41.46229,
    "lng": -92.06377,
    "state_id": "IA"
  },
  {
    "zip": 52336,
    "lat": 42.06925,
    "lng": -91.43977,
    "state_id": "IA"
  },
  {
    "zip": 52337,
    "lat": 41.88026,
    "lng": -91.14202,
    "state_id": "IA"
  },
  {
    "zip": 52338,
    "lat": 41.82956,
    "lng": -91.70868,
    "state_id": "IA"
  },
  {
    "zip": 52339,
    "lat": 41.92296,
    "lng": -92.58862,
    "state_id": "IA"
  },
  {
    "zip": 52340,
    "lat": 41.70831,
    "lng": -91.68468,
    "state_id": "IA"
  },
  {
    "zip": 52341,
    "lat": 42.10383,
    "lng": -91.73537,
    "state_id": "IA"
  },
  {
    "zip": 52342,
    "lat": 42.05139,
    "lng": -92.55782,
    "state_id": "IA"
  },
  {
    "zip": 52345,
    "lat": 42.23413,
    "lng": -91.89197,
    "state_id": "IA"
  },
  {
    "zip": 52346,
    "lat": 42.01005,
    "lng": -92.08067,
    "state_id": "IA"
  },
  {
    "zip": 52347,
    "lat": 41.71253,
    "lng": -92.28449,
    "state_id": "IA"
  },
  {
    "zip": 52348,
    "lat": 41.99015,
    "lng": -92.38148,
    "state_id": "IA"
  },
  {
    "zip": 52349,
    "lat": 42.17237,
    "lng": -92.00562,
    "state_id": "IA"
  },
  {
    "zip": 52351,
    "lat": 41.87788,
    "lng": -91.83177,
    "state_id": "IA"
  },
  {
    "zip": 52352,
    "lat": 42.28787,
    "lng": -91.77579,
    "state_id": "IA"
  },
  {
    "zip": 52353,
    "lat": 41.29844,
    "lng": -91.71326,
    "state_id": "IA"
  },
  {
    "zip": 52354,
    "lat": 41.91593,
    "lng": -91.98774,
    "state_id": "IA"
  },
  {
    "zip": 52355,
    "lat": 41.45995,
    "lng": -92.18292,
    "state_id": "IA"
  },
  {
    "zip": 52356,
    "lat": 41.47372,
    "lng": -91.84517,
    "state_id": "IA"
  },
  {
    "zip": 52358,
    "lat": 41.69604,
    "lng": -91.31918,
    "state_id": "IA"
  },
  {
    "zip": 52359,
    "lat": 41.35852,
    "lng": -91.80745,
    "state_id": "IA"
  },
  {
    "zip": 52361,
    "lat": 41.6476,
    "lng": -92.01396,
    "state_id": "IA"
  },
  {
    "zip": 52362,
    "lat": 42.0657,
    "lng": -90.99687,
    "state_id": "IA"
  },
  {
    "zip": 52401,
    "lat": 41.97541,
    "lng": -91.65848,
    "state_id": "IA"
  },
  {
    "zip": 52402,
    "lat": 42.02277,
    "lng": -91.65978,
    "state_id": "IA"
  },
  {
    "zip": 52403,
    "lat": 41.96873,
    "lng": -91.5804,
    "state_id": "IA"
  },
  {
    "zip": 52404,
    "lat": 41.9238,
    "lng": -91.69422,
    "state_id": "IA"
  },
  {
    "zip": 52405,
    "lat": 41.98684,
    "lng": -91.74659,
    "state_id": "IA"
  },
  {
    "zip": 52411,
    "lat": 42.05151,
    "lng": -91.72714,
    "state_id": "IA"
  },
  {
    "zip": 52501,
    "lat": 41.03574,
    "lng": -92.4315,
    "state_id": "IA"
  },
  {
    "zip": 52530,
    "lat": 40.99229,
    "lng": -92.29196,
    "state_id": "IA"
  },
  {
    "zip": 52531,
    "lat": 41.02676,
    "lng": -92.81433,
    "state_id": "IA"
  },
  {
    "zip": 52533,
    "lat": 41.0176,
    "lng": -92.15249,
    "state_id": "IA"
  },
  {
    "zip": 52534,
    "lat": 41.27193,
    "lng": -92.68188,
    "state_id": "IA"
  },
  {
    "zip": 52535,
    "lat": 40.86516,
    "lng": -91.96052,
    "state_id": "IA"
  },
  {
    "zip": 52536,
    "lat": 40.95548,
    "lng": -92.61948,
    "state_id": "IA"
  },
  {
    "zip": 52537,
    "lat": 40.73627,
    "lng": -92.41215,
    "state_id": "IA"
  },
  {
    "zip": 52540,
    "lat": 41.15762,
    "lng": -91.8222,
    "state_id": "IA"
  },
  {
    "zip": 52542,
    "lat": 40.62797,
    "lng": -92.05186,
    "state_id": "IA"
  },
  {
    "zip": 52543,
    "lat": 41.20076,
    "lng": -92.51993,
    "state_id": "IA"
  },
  {
    "zip": 52544,
    "lat": 40.71758,
    "lng": -92.91073,
    "state_id": "IA"
  },
  {
    "zip": 52548,
    "lat": 41.08602,
    "lng": -92.53032,
    "state_id": "IA"
  },
  {
    "zip": 52549,
    "lat": 40.61376,
    "lng": -92.94649,
    "state_id": "IA"
  },
  {
    "zip": 52550,
    "lat": 41.31386,
    "lng": -92.35996,
    "state_id": "IA"
  },
  {
    "zip": 52551,
    "lat": 40.82485,
    "lng": -92.10191,
    "state_id": "IA"
  },
  {
    "zip": 52552,
    "lat": 40.83572,
    "lng": -92.53676,
    "state_id": "IA"
  },
  {
    "zip": 52553,
    "lat": 41.14836,
    "lng": -92.65505,
    "state_id": "IA"
  },
  {
    "zip": 52554,
    "lat": 40.92594,
    "lng": -92.22927,
    "state_id": "IA"
  },
  {
    "zip": 52555,
    "lat": 40.63966,
    "lng": -92.81523,
    "state_id": "IA"
  },
  {
    "zip": 52556,
    "lat": 41.01982,
    "lng": -91.92902,
    "state_id": "IA"
  },
  {
    "zip": 52557,
    "lat": 41.02212,
    "lng": -91.96476,
    "state_id": "IA"
  },
  {
    "zip": 52560,
    "lat": 40.85153,
    "lng": -92.24667,
    "state_id": "IA"
  },
  {
    "zip": 52561,
    "lat": 41.21987,
    "lng": -92.44621,
    "state_id": "IA"
  },
  {
    "zip": 52563,
    "lat": 41.17498,
    "lng": -92.28314,
    "state_id": "IA"
  },
  {
    "zip": 52565,
    "lat": 40.74009,
    "lng": -91.94187,
    "state_id": "IA"
  },
  {
    "zip": 52566,
    "lat": 41.14796,
    "lng": -92.50257,
    "state_id": "IA"
  },
  {
    "zip": 52567,
    "lat": 40.93035,
    "lng": -92.07781,
    "state_id": "IA"
  },
  {
    "zip": 52569,
    "lat": 40.98568,
    "lng": -93.04223,
    "state_id": "IA"
  },
  {
    "zip": 52570,
    "lat": 40.6852,
    "lng": -92.15075,
    "state_id": "IA"
  },
  {
    "zip": 52571,
    "lat": 40.87713,
    "lng": -92.83776,
    "state_id": "IA"
  },
  {
    "zip": 52572,
    "lat": 40.67726,
    "lng": -92.69193,
    "state_id": "IA"
  },
  {
    "zip": 52573,
    "lat": 40.63302,
    "lng": -91.93868,
    "state_id": "IA"
  },
  {
    "zip": 52574,
    "lat": 40.80374,
    "lng": -92.96646,
    "state_id": "IA"
  },
  {
    "zip": 52576,
    "lat": 41.21359,
    "lng": -92.11125,
    "state_id": "IA"
  },
  {
    "zip": 52577,
    "lat": 41.27193,
    "lng": -92.66047,
    "state_id": "IA"
  },
  {
    "zip": 52580,
    "lat": 41.12332,
    "lng": -92.08762,
    "state_id": "IA"
  },
  {
    "zip": 52581,
    "lat": 40.80587,
    "lng": -93.06286,
    "state_id": "IA"
  },
  {
    "zip": 52583,
    "lat": 40.80034,
    "lng": -93.15264,
    "state_id": "IA"
  },
  {
    "zip": 52584,
    "lat": 40.64523,
    "lng": -92.25362,
    "state_id": "IA"
  },
  {
    "zip": 52585,
    "lat": 41.19238,
    "lng": -91.98205,
    "state_id": "IA"
  },
  {
    "zip": 52586,
    "lat": 41.34679,
    "lng": -92.47041,
    "state_id": "IA"
  },
  {
    "zip": 52588,
    "lat": 40.86421,
    "lng": -92.17314,
    "state_id": "IA"
  },
  {
    "zip": 52590,
    "lat": 40.66439,
    "lng": -93.13237,
    "state_id": "IA"
  },
  {
    "zip": 52591,
    "lat": 41.32085,
    "lng": -92.19297,
    "state_id": "IA"
  },
  {
    "zip": 52593,
    "lat": 40.7736,
    "lng": -92.73956,
    "state_id": "IA"
  },
  {
    "zip": 52594,
    "lat": 40.8357,
    "lng": -92.64972,
    "state_id": "IA"
  },
  {
    "zip": 52595,
    "lat": 41.28862,
    "lng": -92.61497,
    "state_id": "IA"
  },
  {
    "zip": 52601,
    "lat": 40.85501,
    "lng": -91.112,
    "state_id": "IA"
  },
  {
    "zip": 52619,
    "lat": 40.52517,
    "lng": -91.56519,
    "state_id": "IA"
  },
  {
    "zip": 52620,
    "lat": 40.69791,
    "lng": -91.79695,
    "state_id": "IA"
  },
  {
    "zip": 52621,
    "lat": 41.20908,
    "lng": -91.53098,
    "state_id": "IA"
  },
  {
    "zip": 52623,
    "lat": 40.85736,
    "lng": -91.33838,
    "state_id": "IA"
  },
  {
    "zip": 52624,
    "lat": 40.73642,
    "lng": -91.33752,
    "state_id": "IA"
  },
  {
    "zip": 52625,
    "lat": 40.66545,
    "lng": -91.56941,
    "state_id": "IA"
  },
  {
    "zip": 52626,
    "lat": 40.62634,
    "lng": -91.72836,
    "state_id": "IA"
  },
  {
    "zip": 52627,
    "lat": 40.66821,
    "lng": -91.34874,
    "state_id": "IA"
  },
  {
    "zip": 52630,
    "lat": 40.80226,
    "lng": -91.70709,
    "state_id": "IA"
  },
  {
    "zip": 52632,
    "lat": 40.43915,
    "lng": -91.44079,
    "state_id": "IA"
  },
  {
    "zip": 52635,
    "lat": 40.99525,
    "lng": -91.75774,
    "state_id": "IA"
  },
  {
    "zip": 52637,
    "lat": 41.02013,
    "lng": -91.13679,
    "state_id": "IA"
  },
  {
    "zip": 52638,
    "lat": 40.79857,
    "lng": -91.24575,
    "state_id": "IA"
  },
  {
    "zip": 52639,
    "lat": 40.52993,
    "lng": -91.45635,
    "state_id": "IA"
  },
  {
    "zip": 52640,
    "lat": 41.09971,
    "lng": -91.28487,
    "state_id": "IA"
  },
  {
    "zip": 52641,
    "lat": 40.98967,
    "lng": -91.5872,
    "state_id": "IA"
  },
  {
    "zip": 52644,
    "lat": 41.04,
    "lng": -91.41496,
    "state_id": "IA"
  },
  {
    "zip": 52645,
    "lat": 40.91366,
    "lng": -91.40301,
    "state_id": "IA"
  },
  {
    "zip": 52646,
    "lat": 41.06666,
    "lng": -91.00139,
    "state_id": "IA"
  },
  {
    "zip": 52647,
    "lat": 41.13517,
    "lng": -91.54472,
    "state_id": "IA"
  },
  {
    "zip": 52649,
    "lat": 40.84075,
    "lng": -91.60359,
    "state_id": "IA"
  },
  {
    "zip": 52650,
    "lat": 40.94888,
    "lng": -91.15727,
    "state_id": "IA"
  },
  {
    "zip": 52651,
    "lat": 40.85848,
    "lng": -91.81407,
    "state_id": "IA"
  },
  {
    "zip": 52653,
    "lat": 41.16722,
    "lng": -91.15658,
    "state_id": "IA"
  },
  {
    "zip": 52654,
    "lat": 41.13751,
    "lng": -91.66823,
    "state_id": "IA"
  },
  {
    "zip": 52655,
    "lat": 40.84804,
    "lng": -91.21327,
    "state_id": "IA"
  },
  {
    "zip": 52656,
    "lat": 40.73894,
    "lng": -91.46353,
    "state_id": "IA"
  },
  {
    "zip": 52657,
    "lat": 40.76785,
    "lng": -91.51879,
    "state_id": "IA"
  },
  {
    "zip": 52658,
    "lat": 40.69989,
    "lng": -91.22547,
    "state_id": "IA"
  },
  {
    "zip": 52659,
    "lat": 41.12941,
    "lng": -91.44114,
    "state_id": "IA"
  },
  {
    "zip": 52660,
    "lat": 40.99252,
    "lng": -91.29763,
    "state_id": "IA"
  },
  {
    "zip": 52701,
    "lat": 41.98195,
    "lng": -90.24912,
    "state_id": "IA"
  },
  {
    "zip": 52720,
    "lat": 41.59255,
    "lng": -91.16601,
    "state_id": "IA"
  },
  {
    "zip": 52721,
    "lat": 41.75691,
    "lng": -90.95971,
    "state_id": "IA"
  },
  {
    "zip": 52722,
    "lat": 41.56601,
    "lng": -90.46662,
    "state_id": "IA"
  },
  {
    "zip": 52726,
    "lat": 41.50248,
    "lng": -90.78391,
    "state_id": "IA"
  },
  {
    "zip": 52727,
    "lat": 41.95831,
    "lng": -90.3334,
    "state_id": "IA"
  },
  {
    "zip": 52728,
    "lat": 41.45622,
    "lng": -90.74195,
    "state_id": "IA"
  },
  {
    "zip": 52729,
    "lat": 41.80405,
    "lng": -90.74747,
    "state_id": "IA"
  },
  {
    "zip": 52730,
    "lat": 41.77257,
    "lng": -90.3433,
    "state_id": "IA"
  },
  {
    "zip": 52731,
    "lat": 41.967,
    "lng": -90.48643,
    "state_id": "IA"
  },
  {
    "zip": 52732,
    "lat": 41.90219,
    "lng": -90.25075,
    "state_id": "IA"
  },
  {
    "zip": 52737,
    "lat": 41.25929,
    "lng": -91.37459,
    "state_id": "IA"
  },
  {
    "zip": 52738,
    "lat": 41.27158,
    "lng": -91.37902,
    "state_id": "IA"
  },
  {
    "zip": 52739,
    "lat": 41.37286,
    "lng": -91.36867,
    "state_id": "IA"
  },
  {
    "zip": 52742,
    "lat": 41.83628,
    "lng": -90.51073,
    "state_id": "IA"
  },
  {
    "zip": 52745,
    "lat": 41.7135,
    "lng": -90.76056,
    "state_id": "IA"
  },
  {
    "zip": 52746,
    "lat": 41.72089,
    "lng": -90.67108,
    "state_id": "IA"
  },
  {
    "zip": 52747,
    "lat": 41.61006,
    "lng": -90.91147,
    "state_id": "IA"
  },
  {
    "zip": 52748,
    "lat": 41.66653,
    "lng": -90.55679,
    "state_id": "IA"
  },
  {
    "zip": 52749,
    "lat": 41.3457,
    "lng": -91.13073,
    "state_id": "IA"
  },
  {
    "zip": 52750,
    "lat": 41.93211,
    "lng": -90.39107,
    "state_id": "IA"
  },
  {
    "zip": 52751,
    "lat": 41.85112,
    "lng": -90.68183,
    "state_id": "IA"
  },
  {
    "zip": 52752,
    "lat": 41.27473,
    "lng": -91.1912,
    "state_id": "IA"
  },
  {
    "zip": 52753,
    "lat": 41.62529,
    "lng": -90.39047,
    "state_id": "IA"
  },
  {
    "zip": 52754,
    "lat": 41.34304,
    "lng": -91.23802,
    "state_id": "IA"
  },
  {
    "zip": 52755,
    "lat": 41.47849,
    "lng": -91.43113,
    "state_id": "IA"
  },
  {
    "zip": 52756,
    "lat": 41.73071,
    "lng": -90.53964,
    "state_id": "IA"
  },
  {
    "zip": 52757,
    "lat": 41.80381,
    "lng": -90.37911,
    "state_id": "IA"
  },
  {
    "zip": 52758,
    "lat": 41.74583,
    "lng": -90.43931,
    "state_id": "IA"
  },
  {
    "zip": 52760,
    "lat": 41.58174,
    "lng": -91.09907,
    "state_id": "IA"
  },
  {
    "zip": 52761,
    "lat": 41.43871,
    "lng": -91.05848,
    "state_id": "IA"
  },
  {
    "zip": 52765,
    "lat": 41.72546,
    "lng": -90.8715,
    "state_id": "IA"
  },
  {
    "zip": 52766,
    "lat": 41.46616,
    "lng": -91.29615,
    "state_id": "IA"
  },
  {
    "zip": 52767,
    "lat": 41.56564,
    "lng": -90.42228,
    "state_id": "IA"
  },
  {
    "zip": 52768,
    "lat": 41.69899,
    "lng": -90.38985,
    "state_id": "IA"
  },
  {
    "zip": 52769,
    "lat": 41.61027,
    "lng": -90.84652,
    "state_id": "IA"
  },
  {
    "zip": 52772,
    "lat": 41.75037,
    "lng": -91.14065,
    "state_id": "IA"
  },
  {
    "zip": 52773,
    "lat": 41.62196,
    "lng": -90.7567,
    "state_id": "IA"
  },
  {
    "zip": 52774,
    "lat": 41.90679,
    "lng": -90.59515,
    "state_id": "IA"
  },
  {
    "zip": 52776,
    "lat": 41.57747,
    "lng": -91.27006,
    "state_id": "IA"
  },
  {
    "zip": 52777,
    "lat": 41.854,
    "lng": -90.85613,
    "state_id": "IA"
  },
  {
    "zip": 52778,
    "lat": 41.60591,
    "lng": -90.99261,
    "state_id": "IA"
  },
  {
    "zip": 52801,
    "lat": 41.52076,
    "lng": -90.57451,
    "state_id": "IA"
  },
  {
    "zip": 52802,
    "lat": 41.49749,
    "lng": -90.63498,
    "state_id": "IA"
  },
  {
    "zip": 52803,
    "lat": 41.53865,
    "lng": -90.55607,
    "state_id": "IA"
  },
  {
    "zip": 52804,
    "lat": 41.53258,
    "lng": -90.67858,
    "state_id": "IA"
  },
  {
    "zip": 52806,
    "lat": 41.58968,
    "lng": -90.6259,
    "state_id": "IA"
  },
  {
    "zip": 52807,
    "lat": 41.61062,
    "lng": -90.51687,
    "state_id": "IA"
  },
  {
    "zip": 83201,
    "lat": 42.88941,
    "lng": -112.38464,
    "state_id": "ID"
  },
  {
    "zip": 83202,
    "lat": 42.96804,
    "lng": -112.3421,
    "state_id": "ID"
  },
  {
    "zip": 83203,
    "lat": 43.04244,
    "lng": -112.49594,
    "state_id": "ID"
  },
  {
    "zip": 83204,
    "lat": 42.79655,
    "lng": -112.52767,
    "state_id": "ID"
  },
  {
    "zip": 83209,
    "lat": 42.85778,
    "lng": -112.4263,
    "state_id": "ID"
  },
  {
    "zip": 83210,
    "lat": 43.01934,
    "lng": -112.86186,
    "state_id": "ID"
  },
  {
    "zip": 83211,
    "lat": 42.7067,
    "lng": -113.01386,
    "state_id": "ID"
  },
  {
    "zip": 83212,
    "lat": 42.53357,
    "lng": -112.54199,
    "state_id": "ID"
  },
  {
    "zip": 83213,
    "lat": 43.55476,
    "lng": -113.30086,
    "state_id": "ID"
  },
  {
    "zip": 83214,
    "lat": 42.5452,
    "lng": -112.29039,
    "state_id": "ID"
  },
  {
    "zip": 83215,
    "lat": 43.443,
    "lng": -112.81224,
    "state_id": "ID"
  },
  {
    "zip": 83217,
    "lat": 42.77531,
    "lng": -111.93732,
    "state_id": "ID"
  },
  {
    "zip": 83218,
    "lat": 43.31765,
    "lng": -112.16541,
    "state_id": "ID"
  },
  {
    "zip": 83220,
    "lat": 42.36303,
    "lng": -111.3908,
    "state_id": "ID"
  },
  {
    "zip": 83221,
    "lat": 43.33607,
    "lng": -112.5451,
    "state_id": "ID"
  },
  {
    "zip": 83223,
    "lat": 42.18399,
    "lng": -111.40858,
    "state_id": "ID"
  },
  {
    "zip": 83226,
    "lat": 44.66892,
    "lng": -114.36489,
    "state_id": "ID"
  },
  {
    "zip": 83227,
    "lat": 44.24934,
    "lng": -114.50171,
    "state_id": "ID"
  },
  {
    "zip": 83228,
    "lat": 42.19562,
    "lng": -112.04029,
    "state_id": "ID"
  },
  {
    "zip": 83232,
    "lat": 42.13868,
    "lng": -111.97167,
    "state_id": "ID"
  },
  {
    "zip": 83233,
    "lat": 42.15796,
    "lng": -111.23508,
    "state_id": "ID"
  },
  {
    "zip": 83234,
    "lat": 42.41859,
    "lng": -112.09383,
    "state_id": "ID"
  },
  {
    "zip": 83235,
    "lat": 44.60826,
    "lng": -113.8033,
    "state_id": "ID"
  },
  {
    "zip": 83236,
    "lat": 43.24319,
    "lng": -111.90323,
    "state_id": "ID"
  },
  {
    "zip": 83237,
    "lat": 42.02704,
    "lng": -111.77523,
    "state_id": "ID"
  },
  {
    "zip": 83238,
    "lat": 42.33102,
    "lng": -111.1083,
    "state_id": "ID"
  },
  {
    "zip": 83239,
    "lat": 42.5063,
    "lng": -111.32836,
    "state_id": "ID"
  },
  {
    "zip": 83241,
    "lat": 42.52145,
    "lng": -111.7597,
    "state_id": "ID"
  },
  {
    "zip": 83243,
    "lat": 42.24143,
    "lng": -112.66531,
    "state_id": "ID"
  },
  {
    "zip": 83244,
    "lat": 43.95954,
    "lng": -113.11644,
    "state_id": "ID"
  },
  {
    "zip": 83245,
    "lat": 42.81814,
    "lng": -112.22036,
    "state_id": "ID"
  },
  {
    "zip": 83246,
    "lat": 42.6111,
    "lng": -112.02926,
    "state_id": "ID"
  },
  {
    "zip": 83250,
    "lat": 42.66732,
    "lng": -112.20433,
    "state_id": "ID"
  },
  {
    "zip": 83251,
    "lat": 43.93757,
    "lng": -113.89617,
    "state_id": "ID"
  },
  {
    "zip": 83252,
    "lat": 42.19026,
    "lng": -112.45832,
    "state_id": "ID"
  },
  {
    "zip": 83253,
    "lat": 44.31889,
    "lng": -113.69032,
    "state_id": "ID"
  },
  {
    "zip": 83254,
    "lat": 42.32833,
    "lng": -111.26807,
    "state_id": "ID"
  },
  {
    "zip": 83255,
    "lat": 43.69686,
    "lng": -113.57177,
    "state_id": "ID"
  },
  {
    "zip": 83261,
    "lat": 42.21611,
    "lng": -111.42069,
    "state_id": "ID"
  },
  {
    "zip": 83262,
    "lat": 43.18415,
    "lng": -112.76837,
    "state_id": "ID"
  },
  {
    "zip": 83263,
    "lat": 42.16883,
    "lng": -111.75706,
    "state_id": "ID"
  },
  {
    "zip": 83271,
    "lat": 42.45794,
    "lng": -112.84559,
    "state_id": "ID"
  },
  {
    "zip": 83272,
    "lat": 42.10496,
    "lng": -111.36387,
    "state_id": "ID"
  },
  {
    "zip": 83274,
    "lat": 43.35385,
    "lng": -112.09882,
    "state_id": "ID"
  },
  {
    "zip": 83276,
    "lat": 42.76928,
    "lng": -111.52781,
    "state_id": "ID"
  },
  {
    "zip": 83277,
    "lat": 43.07036,
    "lng": -112.67626,
    "state_id": "ID"
  },
  {
    "zip": 83278,
    "lat": 44.37034,
    "lng": -114.89595,
    "state_id": "ID"
  },
  {
    "zip": 83281,
    "lat": 42.31041,
    "lng": -111.95988,
    "state_id": "ID"
  },
  {
    "zip": 83283,
    "lat": 42.35941,
    "lng": -111.6705,
    "state_id": "ID"
  },
  {
    "zip": 83285,
    "lat": 43.09836,
    "lng": -111.28729,
    "state_id": "ID"
  },
  {
    "zip": 83286,
    "lat": 42.05319,
    "lng": -112.01483,
    "state_id": "ID"
  },
  {
    "zip": 83287,
    "lat": 42.04658,
    "lng": -111.39806,
    "state_id": "ID"
  },
  {
    "zip": 83301,
    "lat": 42.39319,
    "lng": -114.52218,
    "state_id": "ID"
  },
  {
    "zip": 83302,
    "lat": 42.12882,
    "lng": -115.00173,
    "state_id": "ID"
  },
  {
    "zip": 83311,
    "lat": 42.3896,
    "lng": -113.55677,
    "state_id": "ID"
  },
  {
    "zip": 83312,
    "lat": 41.97587,
    "lng": -113.65429,
    "state_id": "ID"
  },
  {
    "zip": 83313,
    "lat": 43.35825,
    "lng": -114.25221,
    "state_id": "ID"
  },
  {
    "zip": 83314,
    "lat": 42.97655,
    "lng": -114.93933,
    "state_id": "ID"
  },
  {
    "zip": 83316,
    "lat": 42.61389,
    "lng": -114.87212,
    "state_id": "ID"
  },
  {
    "zip": 83318,
    "lat": 42.43678,
    "lng": -113.82129,
    "state_id": "ID"
  },
  {
    "zip": 83320,
    "lat": 43.45232,
    "lng": -113.88364,
    "state_id": "ID"
  },
  {
    "zip": 83321,
    "lat": 42.46494,
    "lng": -114.91335,
    "state_id": "ID"
  },
  {
    "zip": 83322,
    "lat": 43.35579,
    "lng": -114.97494,
    "state_id": "ID"
  },
  {
    "zip": 83323,
    "lat": 42.49418,
    "lng": -113.39076,
    "state_id": "ID"
  },
  {
    "zip": 83324,
    "lat": 42.87836,
    "lng": -114.26586,
    "state_id": "ID"
  },
  {
    "zip": 83325,
    "lat": 42.58782,
    "lng": -114.24207,
    "state_id": "ID"
  },
  {
    "zip": 83327,
    "lat": 43.48907,
    "lng": -114.80402,
    "state_id": "ID"
  },
  {
    "zip": 83328,
    "lat": 42.57027,
    "lng": -114.61382,
    "state_id": "ID"
  },
  {
    "zip": 83330,
    "lat": 42.95287,
    "lng": -114.70822,
    "state_id": "ID"
  },
  {
    "zip": 83332,
    "lat": 42.80193,
    "lng": -114.9205,
    "state_id": "ID"
  },
  {
    "zip": 83333,
    "lat": 43.56677,
    "lng": -114.3155,
    "state_id": "ID"
  },
  {
    "zip": 83334,
    "lat": 42.43782,
    "lng": -114.28489,
    "state_id": "ID"
  },
  {
    "zip": 83335,
    "lat": 42.58125,
    "lng": -114.06715,
    "state_id": "ID"
  },
  {
    "zip": 83336,
    "lat": 42.56346,
    "lng": -113.81929,
    "state_id": "ID"
  },
  {
    "zip": 83337,
    "lat": 43.22691,
    "lng": -115.14703,
    "state_id": "ID"
  },
  {
    "zip": 83338,
    "lat": 42.72257,
    "lng": -114.43288,
    "state_id": "ID"
  },
  {
    "zip": 83340,
    "lat": 43.77184,
    "lng": -114.58323,
    "state_id": "ID"
  },
  {
    "zip": 83341,
    "lat": 42.41952,
    "lng": -114.36962,
    "state_id": "ID"
  },
  {
    "zip": 83342,
    "lat": 42.17776,
    "lng": -113.25127,
    "state_id": "ID"
  },
  {
    "zip": 83344,
    "lat": 42.44596,
    "lng": -114.13045,
    "state_id": "ID"
  },
  {
    "zip": 83346,
    "lat": 42.2098,
    "lng": -113.93485,
    "state_id": "ID"
  },
  {
    "zip": 83347,
    "lat": 42.73394,
    "lng": -113.88079,
    "state_id": "ID"
  },
  {
    "zip": 83348,
    "lat": 43.25697,
    "lng": -114.10781,
    "state_id": "ID"
  },
  {
    "zip": 83349,
    "lat": 43.09488,
    "lng": -114.18157,
    "state_id": "ID"
  },
  {
    "zip": 83350,
    "lat": 42.69505,
    "lng": -113.58021,
    "state_id": "ID"
  },
  {
    "zip": 83352,
    "lat": 43.07068,
    "lng": -114.41161,
    "state_id": "ID"
  },
  {
    "zip": 83353,
    "lat": 43.6856,
    "lng": -114.33225,
    "state_id": "ID"
  },
  {
    "zip": 83354,
    "lat": 43.66994,
    "lng": -114.32244,
    "state_id": "ID"
  },
  {
    "zip": 83355,
    "lat": 42.75421,
    "lng": -114.72742,
    "state_id": "ID"
  },
  {
    "zip": 83401,
    "lat": 43.55177,
    "lng": -111.89191,
    "state_id": "ID"
  },
  {
    "zip": 83402,
    "lat": 43.53154,
    "lng": -112.15453,
    "state_id": "ID"
  },
  {
    "zip": 83404,
    "lat": 43.42448,
    "lng": -112.01199,
    "state_id": "ID"
  },
  {
    "zip": 83406,
    "lat": 43.43887,
    "lng": -111.81734,
    "state_id": "ID"
  },
  {
    "zip": 83420,
    "lat": 44.07283,
    "lng": -111.35131,
    "state_id": "ID"
  },
  {
    "zip": 83421,
    "lat": 44.00502,
    "lng": -111.53798,
    "state_id": "ID"
  },
  {
    "zip": 83422,
    "lat": 43.73315,
    "lng": -111.23111,
    "state_id": "ID"
  },
  {
    "zip": 83423,
    "lat": 44.30001,
    "lng": -112.27026,
    "state_id": "ID"
  },
  {
    "zip": 83424,
    "lat": 43.91288,
    "lng": -111.16588,
    "state_id": "ID"
  },
  {
    "zip": 83425,
    "lat": 43.91648,
    "lng": -112.24277,
    "state_id": "ID"
  },
  {
    "zip": 83427,
    "lat": 43.52517,
    "lng": -111.93253,
    "state_id": "ID"
  },
  {
    "zip": 83428,
    "lat": 43.41869,
    "lng": -111.16481,
    "state_id": "ID"
  },
  {
    "zip": 83429,
    "lat": 44.46774,
    "lng": -111.40232,
    "state_id": "ID"
  },
  {
    "zip": 83431,
    "lat": 43.68885,
    "lng": -112.02963,
    "state_id": "ID"
  },
  {
    "zip": 83433,
    "lat": 44.48443,
    "lng": -111.333,
    "state_id": "ID"
  },
  {
    "zip": 83434,
    "lat": 43.75389,
    "lng": -112.00956,
    "state_id": "ID"
  },
  {
    "zip": 83435,
    "lat": 43.98786,
    "lng": -112.56826,
    "state_id": "ID"
  },
  {
    "zip": 83436,
    "lat": 43.85687,
    "lng": -111.47662,
    "state_id": "ID"
  },
  {
    "zip": 83440,
    "lat": 43.81596,
    "lng": -111.82301,
    "state_id": "ID"
  },
  {
    "zip": 83442,
    "lat": 43.67201,
    "lng": -111.89301,
    "state_id": "ID"
  },
  {
    "zip": 83443,
    "lat": 43.51974,
    "lng": -111.52662,
    "state_id": "ID"
  },
  {
    "zip": 83444,
    "lat": 43.69575,
    "lng": -112.24932,
    "state_id": "ID"
  },
  {
    "zip": 83445,
    "lat": 43.97871,
    "lng": -111.76879,
    "state_id": "ID"
  },
  {
    "zip": 83446,
    "lat": 44.47296,
    "lng": -112.19268,
    "state_id": "ID"
  },
  {
    "zip": 83448,
    "lat": 43.84663,
    "lng": -111.69692,
    "state_id": "ID"
  },
  {
    "zip": 83449,
    "lat": 43.28223,
    "lng": -111.40768,
    "state_id": "ID"
  },
  {
    "zip": 83450,
    "lat": 43.83217,
    "lng": -112.41525,
    "state_id": "ID"
  },
  {
    "zip": 83451,
    "lat": 43.86877,
    "lng": -111.63641,
    "state_id": "ID"
  },
  {
    "zip": 83452,
    "lat": 43.83666,
    "lng": -111.21961,
    "state_id": "ID"
  },
  {
    "zip": 83454,
    "lat": 43.59353,
    "lng": -111.9575,
    "state_id": "ID"
  },
  {
    "zip": 83455,
    "lat": 43.61481,
    "lng": -111.17997,
    "state_id": "ID"
  },
  {
    "zip": 83460,
    "lat": 43.81695,
    "lng": -111.78219,
    "state_id": "ID"
  },
  {
    "zip": 83462,
    "lat": 45.30433,
    "lng": -113.81072,
    "state_id": "ID"
  },
  {
    "zip": 83463,
    "lat": 45.57031,
    "lng": -113.99128,
    "state_id": "ID"
  },
  {
    "zip": 83464,
    "lat": 44.50735,
    "lng": -113.23657,
    "state_id": "ID"
  },
  {
    "zip": 83465,
    "lat": 44.77716,
    "lng": -113.70003,
    "state_id": "ID"
  },
  {
    "zip": 83466,
    "lat": 45.4242,
    "lng": -114.04185,
    "state_id": "ID"
  },
  {
    "zip": 83467,
    "lat": 45.00975,
    "lng": -113.89909,
    "state_id": "ID"
  },
  {
    "zip": 83468,
    "lat": 44.93464,
    "lng": -113.60926,
    "state_id": "ID"
  },
  {
    "zip": 83469,
    "lat": 45.36347,
    "lng": -114.4059,
    "state_id": "ID"
  },
  {
    "zip": 83501,
    "lat": 46.20692,
    "lng": -116.8963,
    "state_id": "ID"
  },
  {
    "zip": 83520,
    "lat": 46.53944,
    "lng": -116.32337,
    "state_id": "ID"
  },
  {
    "zip": 83522,
    "lat": 45.92288,
    "lng": -116.50575,
    "state_id": "ID"
  },
  {
    "zip": 83523,
    "lat": 46.24476,
    "lng": -116.45208,
    "state_id": "ID"
  },
  {
    "zip": 83524,
    "lat": 46.3688,
    "lng": -116.65715,
    "state_id": "ID"
  },
  {
    "zip": 83525,
    "lat": 45.78963,
    "lng": -115.5024,
    "state_id": "ID"
  },
  {
    "zip": 83526,
    "lat": 46.15138,
    "lng": -116.40947,
    "state_id": "ID"
  },
  {
    "zip": 83530,
    "lat": 45.92139,
    "lng": -116.08486,
    "state_id": "ID"
  },
  {
    "zip": 83533,
    "lat": 46.10805,
    "lng": -116.23189,
    "state_id": "ID"
  },
  {
    "zip": 83535,
    "lat": 46.53773,
    "lng": -116.72263,
    "state_id": "ID"
  },
  {
    "zip": 83536,
    "lat": 46.22095,
    "lng": -116.00325,
    "state_id": "ID"
  },
  {
    "zip": 83537,
    "lat": 46.63146,
    "lng": -116.54506,
    "state_id": "ID"
  },
  {
    "zip": 83539,
    "lat": 46.22378,
    "lng": -115.03002,
    "state_id": "ID"
  },
  {
    "zip": 83540,
    "lat": 46.3539,
    "lng": -116.78869,
    "state_id": "ID"
  },
  {
    "zip": 83541,
    "lat": 46.51488,
    "lng": -116.46566,
    "state_id": "ID"
  },
  {
    "zip": 83542,
    "lat": 45.56271,
    "lng": -116.36246,
    "state_id": "ID"
  },
  {
    "zip": 83543,
    "lat": 46.27486,
    "lng": -116.23675,
    "state_id": "ID"
  },
  {
    "zip": 83544,
    "lat": 46.49535,
    "lng": -116.15521,
    "state_id": "ID"
  },
  {
    "zip": 83545,
    "lat": 46.42889,
    "lng": -116.42458,
    "state_id": "ID"
  },
  {
    "zip": 83546,
    "lat": 46.56739,
    "lng": -115.81731,
    "state_id": "ID"
  },
  {
    "zip": 83547,
    "lat": 45.19088,
    "lng": -116.18702,
    "state_id": "ID"
  },
  {
    "zip": 83548,
    "lat": 46.36133,
    "lng": -116.51442,
    "state_id": "ID"
  },
  {
    "zip": 83549,
    "lat": 45.38294,
    "lng": -116.21912,
    "state_id": "ID"
  },
  {
    "zip": 83552,
    "lat": 46.00202,
    "lng": -115.92693,
    "state_id": "ID"
  },
  {
    "zip": 83553,
    "lat": 46.38353,
    "lng": -115.90473,
    "state_id": "ID"
  },
  {
    "zip": 83554,
    "lat": 45.70362,
    "lng": -116.28773,
    "state_id": "ID"
  },
  {
    "zip": 83555,
    "lat": 46.1236,
    "lng": -116.69547,
    "state_id": "ID"
  },
  {
    "zip": 83602,
    "lat": 44.07219,
    "lng": -116.11461,
    "state_id": "ID"
  },
  {
    "zip": 83604,
    "lat": 42.50828,
    "lng": -115.8056,
    "state_id": "ID"
  },
  {
    "zip": 83605,
    "lat": 43.66239,
    "lng": -116.65642,
    "state_id": "ID"
  },
  {
    "zip": 83607,
    "lat": 43.70796,
    "lng": -116.75079,
    "state_id": "ID"
  },
  {
    "zip": 83610,
    "lat": 44.67501,
    "lng": -116.7727,
    "state_id": "ID"
  },
  {
    "zip": 83611,
    "lat": 44.71396,
    "lng": -115.39592,
    "state_id": "ID"
  },
  {
    "zip": 83612,
    "lat": 44.9144,
    "lng": -116.56458,
    "state_id": "ID"
  },
  {
    "zip": 83615,
    "lat": 44.71688,
    "lng": -116.05193,
    "state_id": "ID"
  },
  {
    "zip": 83616,
    "lat": 43.77416,
    "lng": -116.39255,
    "state_id": "ID"
  },
  {
    "zip": 83617,
    "lat": 43.92995,
    "lng": -116.50566,
    "state_id": "ID"
  },
  {
    "zip": 83619,
    "lat": 43.9673,
    "lng": -116.91218,
    "state_id": "ID"
  },
  {
    "zip": 83622,
    "lat": 44.1059,
    "lng": -115.87728,
    "state_id": "ID"
  },
  {
    "zip": 83623,
    "lat": 43.08031,
    "lng": -115.39116,
    "state_id": "ID"
  },
  {
    "zip": 83624,
    "lat": 42.91336,
    "lng": -116.14386,
    "state_id": "ID"
  },
  {
    "zip": 83626,
    "lat": 43.66901,
    "lng": -116.83156,
    "state_id": "ID"
  },
  {
    "zip": 83627,
    "lat": 42.92064,
    "lng": -115.53638,
    "state_id": "ID"
  },
  {
    "zip": 83628,
    "lat": 43.5406,
    "lng": -116.9814,
    "state_id": "ID"
  },
  {
    "zip": 83629,
    "lat": 43.91939,
    "lng": -116.16258,
    "state_id": "ID"
  },
  {
    "zip": 83631,
    "lat": 43.86726,
    "lng": -115.76427,
    "state_id": "ID"
  },
  {
    "zip": 83632,
    "lat": 44.53617,
    "lng": -116.4055,
    "state_id": "ID"
  },
  {
    "zip": 83633,
    "lat": 43.01816,
    "lng": -115.21422,
    "state_id": "ID"
  },
  {
    "zip": 83634,
    "lat": 43.45202,
    "lng": -116.3279,
    "state_id": "ID"
  },
  {
    "zip": 83636,
    "lat": 43.90863,
    "lng": -116.66114,
    "state_id": "ID"
  },
  {
    "zip": 83637,
    "lat": 44.13535,
    "lng": -115.28899,
    "state_id": "ID"
  },
  {
    "zip": 83638,
    "lat": 45.09889,
    "lng": -115.93486,
    "state_id": "ID"
  },
  {
    "zip": 83639,
    "lat": 43.39703,
    "lng": -116.87117,
    "state_id": "ID"
  },
  {
    "zip": 83641,
    "lat": 43.35129,
    "lng": -116.58623,
    "state_id": "ID"
  },
  {
    "zip": 83642,
    "lat": 43.57345,
    "lng": -116.40133,
    "state_id": "ID"
  },
  {
    "zip": 83643,
    "lat": 44.63046,
    "lng": -116.44398,
    "state_id": "ID"
  },
  {
    "zip": 83644,
    "lat": 43.75012,
    "lng": -116.58225,
    "state_id": "ID"
  },
  {
    "zip": 83645,
    "lat": 44.37087,
    "lng": -116.58087,
    "state_id": "ID"
  },
  {
    "zip": 83646,
    "lat": 43.6498,
    "lng": -116.43063,
    "state_id": "ID"
  },
  {
    "zip": 83647,
    "lat": 43.43419,
    "lng": -115.50267,
    "state_id": "ID"
  },
  {
    "zip": 83648,
    "lat": 43.04963,
    "lng": -115.86557,
    "state_id": "ID"
  },
  {
    "zip": 83650,
    "lat": 42.848,
    "lng": -116.63716,
    "state_id": "ID"
  },
  {
    "zip": 83651,
    "lat": 43.58806,
    "lng": -116.62003,
    "state_id": "ID"
  },
  {
    "zip": 83654,
    "lat": 45.09725,
    "lng": -116.35667,
    "state_id": "ID"
  },
  {
    "zip": 83655,
    "lat": 43.95156,
    "lng": -116.79085,
    "state_id": "ID"
  },
  {
    "zip": 83656,
    "lat": 43.7265,
    "lng": -116.79869,
    "state_id": "ID"
  },
  {
    "zip": 83657,
    "lat": 44.2245,
    "lng": -116.30178,
    "state_id": "ID"
  },
  {
    "zip": 83660,
    "lat": 43.79911,
    "lng": -116.93136,
    "state_id": "ID"
  },
  {
    "zip": 83661,
    "lat": 44.07776,
    "lng": -116.70438,
    "state_id": "ID"
  },
  {
    "zip": 83666,
    "lat": 43.9633,
    "lng": -115.97588,
    "state_id": "ID"
  },
  {
    "zip": 83669,
    "lat": 43.71786,
    "lng": -116.49755,
    "state_id": "ID"
  },
  {
    "zip": 83670,
    "lat": 44.01727,
    "lng": -116.30688,
    "state_id": "ID"
  },
  {
    "zip": 83671,
    "lat": 45.35624,
    "lng": -115.20866,
    "state_id": "ID"
  },
  {
    "zip": 83672,
    "lat": 44.36698,
    "lng": -116.9424,
    "state_id": "ID"
  },
  {
    "zip": 83676,
    "lat": 43.65717,
    "lng": -116.91158,
    "state_id": "ID"
  },
  {
    "zip": 83677,
    "lat": 45.08023,
    "lng": -115.53308,
    "state_id": "ID"
  },
  {
    "zip": 83686,
    "lat": 43.49526,
    "lng": -116.6114,
    "state_id": "ID"
  },
  {
    "zip": 83687,
    "lat": 43.60956,
    "lng": -116.5295,
    "state_id": "ID"
  },
  {
    "zip": 83702,
    "lat": 43.67575,
    "lng": -116.16934,
    "state_id": "ID"
  },
  {
    "zip": 83703,
    "lat": 43.66443,
    "lng": -116.24044,
    "state_id": "ID"
  },
  {
    "zip": 83704,
    "lat": 43.62766,
    "lng": -116.28722,
    "state_id": "ID"
  },
  {
    "zip": 83705,
    "lat": 43.56624,
    "lng": -116.22274,
    "state_id": "ID"
  },
  {
    "zip": 83706,
    "lat": 43.59141,
    "lng": -116.19488,
    "state_id": "ID"
  },
  {
    "zip": 83709,
    "lat": 43.54974,
    "lng": -116.28929,
    "state_id": "ID"
  },
  {
    "zip": 83712,
    "lat": 43.6134,
    "lng": -116.11493,
    "state_id": "ID"
  },
  {
    "zip": 83713,
    "lat": 43.63893,
    "lng": -116.33394,
    "state_id": "ID"
  },
  {
    "zip": 83714,
    "lat": 43.73201,
    "lng": -116.27972,
    "state_id": "ID"
  },
  {
    "zip": 83716,
    "lat": 43.53453,
    "lng": -115.97112,
    "state_id": "ID"
  },
  {
    "zip": 83801,
    "lat": 47.93827,
    "lng": -116.65887,
    "state_id": "ID"
  },
  {
    "zip": 83802,
    "lat": 47.08007,
    "lng": -115.52848,
    "state_id": "ID"
  },
  {
    "zip": 83803,
    "lat": 48.03638,
    "lng": -116.44403,
    "state_id": "ID"
  },
  {
    "zip": 83804,
    "lat": 48.03617,
    "lng": -116.97368,
    "state_id": "ID"
  },
  {
    "zip": 83805,
    "lat": 48.84391,
    "lng": -116.49055,
    "state_id": "ID"
  },
  {
    "zip": 83806,
    "lat": 46.89361,
    "lng": -116.36232,
    "state_id": "ID"
  },
  {
    "zip": 83808,
    "lat": 47.32187,
    "lng": -116.0703,
    "state_id": "ID"
  },
  {
    "zip": 83809,
    "lat": 48.06362,
    "lng": -116.57348,
    "state_id": "ID"
  },
  {
    "zip": 83810,
    "lat": 47.54765,
    "lng": -116.45897,
    "state_id": "ID"
  },
  {
    "zip": 83811,
    "lat": 48.06151,
    "lng": -116.23739,
    "state_id": "ID"
  },
  {
    "zip": 83812,
    "lat": 47.01611,
    "lng": -116.22867,
    "state_id": "ID"
  },
  {
    "zip": 83813,
    "lat": 48.09743,
    "lng": -116.63516,
    "state_id": "ID"
  },
  {
    "zip": 83814,
    "lat": 47.63961,
    "lng": -116.75209,
    "state_id": "ID"
  },
  {
    "zip": 83815,
    "lat": 47.72484,
    "lng": -116.78902,
    "state_id": "ID"
  },
  {
    "zip": 83821,
    "lat": 48.58113,
    "lng": -116.85642,
    "state_id": "ID"
  },
  {
    "zip": 83822,
    "lat": 48.1666,
    "lng": -117.0021,
    "state_id": "ID"
  },
  {
    "zip": 83823,
    "lat": 46.8062,
    "lng": -116.51549,
    "state_id": "ID"
  },
  {
    "zip": 83824,
    "lat": 47.1136,
    "lng": -116.91012,
    "state_id": "ID"
  },
  {
    "zip": 83825,
    "lat": 48.24752,
    "lng": -116.60071,
    "state_id": "ID"
  },
  {
    "zip": 83826,
    "lat": 48.97617,
    "lng": -116.1889,
    "state_id": "ID"
  },
  {
    "zip": 83827,
    "lat": 46.78,
    "lng": -116.17472,
    "state_id": "ID"
  },
  {
    "zip": 83830,
    "lat": 47.07836,
    "lng": -116.36531,
    "state_id": "ID"
  },
  {
    "zip": 83832,
    "lat": 46.55355,
    "lng": -116.90686,
    "state_id": "ID"
  },
  {
    "zip": 83833,
    "lat": 47.51559,
    "lng": -116.72228,
    "state_id": "ID"
  },
  {
    "zip": 83834,
    "lat": 46.97497,
    "lng": -116.66264,
    "state_id": "ID"
  },
  {
    "zip": 83835,
    "lat": 47.79612,
    "lng": -116.65828,
    "state_id": "ID"
  },
  {
    "zip": 83836,
    "lat": 48.26372,
    "lng": -116.25139,
    "state_id": "ID"
  },
  {
    "zip": 83837,
    "lat": 47.5096,
    "lng": -116.08104,
    "state_id": "ID"
  },
  {
    "zip": 83839,
    "lat": 47.61005,
    "lng": -116.16992,
    "state_id": "ID"
  },
  {
    "zip": 83840,
    "lat": 48.31058,
    "lng": -116.51522,
    "state_id": "ID"
  },
  {
    "zip": 83841,
    "lat": 48.20154,
    "lng": -116.75116,
    "state_id": "ID"
  },
  {
    "zip": 83842,
    "lat": 47.4407,
    "lng": -116.55056,
    "state_id": "ID"
  },
  {
    "zip": 83843,
    "lat": 46.72268,
    "lng": -116.94172,
    "state_id": "ID"
  },
  {
    "zip": 83844,
    "lat": 46.72945,
    "lng": -117.01313,
    "state_id": "ID"
  },
  {
    "zip": 83845,
    "lat": 48.77845,
    "lng": -116.11779,
    "state_id": "ID"
  },
  {
    "zip": 83846,
    "lat": 47.47484,
    "lng": -115.76675,
    "state_id": "ID"
  },
  {
    "zip": 83847,
    "lat": 48.57771,
    "lng": -116.44375,
    "state_id": "ID"
  },
  {
    "zip": 83848,
    "lat": 48.7159,
    "lng": -116.91592,
    "state_id": "ID"
  },
  {
    "zip": 83849,
    "lat": 47.5166,
    "lng": -116.00053,
    "state_id": "ID"
  },
  {
    "zip": 83850,
    "lat": 47.45457,
    "lng": -116.23091,
    "state_id": "ID"
  },
  {
    "zip": 83851,
    "lat": 47.29939,
    "lng": -116.91039,
    "state_id": "ID"
  },
  {
    "zip": 83852,
    "lat": 48.30895,
    "lng": -116.54198,
    "state_id": "ID"
  },
  {
    "zip": 83854,
    "lat": 47.71876,
    "lng": -116.97291,
    "state_id": "ID"
  },
  {
    "zip": 83855,
    "lat": 46.9708,
    "lng": -116.92186,
    "state_id": "ID"
  },
  {
    "zip": 83856,
    "lat": 48.31053,
    "lng": -116.90064,
    "state_id": "ID"
  },
  {
    "zip": 83857,
    "lat": 46.89205,
    "lng": -116.79401,
    "state_id": "ID"
  },
  {
    "zip": 83858,
    "lat": 47.85308,
    "lng": -116.90777,
    "state_id": "ID"
  },
  {
    "zip": 83860,
    "lat": 48.18925,
    "lng": -116.53217,
    "state_id": "ID"
  },
  {
    "zip": 83861,
    "lat": 47.26322,
    "lng": -116.55112,
    "state_id": "ID"
  },
  {
    "zip": 83864,
    "lat": 48.46778,
    "lng": -116.52874,
    "state_id": "ID"
  },
  {
    "zip": 83866,
    "lat": 47.15511,
    "lng": -116.42466,
    "state_id": "ID"
  },
  {
    "zip": 83867,
    "lat": 47.50572,
    "lng": -115.9488,
    "state_id": "ID"
  },
  {
    "zip": 83868,
    "lat": 47.53432,
    "lng": -116.18142,
    "state_id": "ID"
  },
  {
    "zip": 83869,
    "lat": 47.97904,
    "lng": -116.89147,
    "state_id": "ID"
  },
  {
    "zip": 83870,
    "lat": 47.12587,
    "lng": -116.83168,
    "state_id": "ID"
  },
  {
    "zip": 83871,
    "lat": 46.74623,
    "lng": -116.73931,
    "state_id": "ID"
  },
  {
    "zip": 83872,
    "lat": 46.86214,
    "lng": -116.97011,
    "state_id": "ID"
  },
  {
    "zip": 83873,
    "lat": 47.61267,
    "lng": -115.88752,
    "state_id": "ID"
  },
  {
    "zip": 83874,
    "lat": 47.63915,
    "lng": -115.78438,
    "state_id": "ID"
  },
  {
    "zip": 83876,
    "lat": 47.4489,
    "lng": -116.92867,
    "state_id": "ID"
  },
  {
    "zip": 60002,
    "lat": 42.46968,
    "lng": -88.08721,
    "state_id": "IL"
  },
  {
    "zip": 60004,
    "lat": 42.11196,
    "lng": -87.97909,
    "state_id": "IL"
  },
  {
    "zip": 60005,
    "lat": 42.06394,
    "lng": -87.98567,
    "state_id": "IL"
  },
  {
    "zip": 60007,
    "lat": 42.00757,
    "lng": -87.99297,
    "state_id": "IL"
  },
  {
    "zip": 60008,
    "lat": 42.07445,
    "lng": -88.02268,
    "state_id": "IL"
  },
  {
    "zip": 60010,
    "lat": 42.1523,
    "lng": -88.1635,
    "state_id": "IL"
  },
  {
    "zip": 60012,
    "lat": 42.27211,
    "lng": -88.31265,
    "state_id": "IL"
  },
  {
    "zip": 60013,
    "lat": 42.22031,
    "lng": -88.23588,
    "state_id": "IL"
  },
  {
    "zip": 60014,
    "lat": 42.23203,
    "lng": -88.32731,
    "state_id": "IL"
  },
  {
    "zip": 60015,
    "lat": 42.17251,
    "lng": -87.87481,
    "state_id": "IL"
  },
  {
    "zip": 60016,
    "lat": 42.0497,
    "lng": -87.89166,
    "state_id": "IL"
  },
  {
    "zip": 60018,
    "lat": 41.99743,
    "lng": -87.89695,
    "state_id": "IL"
  },
  {
    "zip": 60020,
    "lat": 42.39199,
    "lng": -88.17751,
    "state_id": "IL"
  },
  {
    "zip": 60021,
    "lat": 42.19484,
    "lng": -88.21823,
    "state_id": "IL"
  },
  {
    "zip": 60022,
    "lat": 42.13608,
    "lng": -87.7676,
    "state_id": "IL"
  },
  {
    "zip": 60025,
    "lat": 42.0754,
    "lng": -87.82044,
    "state_id": "IL"
  },
  {
    "zip": 60026,
    "lat": 42.09223,
    "lng": -87.83745,
    "state_id": "IL"
  },
  {
    "zip": 60029,
    "lat": 42.05926,
    "lng": -87.77837,
    "state_id": "IL"
  },
  {
    "zip": 60030,
    "lat": 42.33666,
    "lng": -88.04459,
    "state_id": "IL"
  },
  {
    "zip": 60031,
    "lat": 42.37504,
    "lng": -87.94112,
    "state_id": "IL"
  },
  {
    "zip": 60033,
    "lat": 42.42476,
    "lng": -88.60757,
    "state_id": "IL"
  },
  {
    "zip": 60034,
    "lat": 42.45624,
    "lng": -88.41981,
    "state_id": "IL"
  },
  {
    "zip": 60035,
    "lat": 42.18367,
    "lng": -87.81056,
    "state_id": "IL"
  },
  {
    "zip": 60040,
    "lat": 42.20622,
    "lng": -87.81287,
    "state_id": "IL"
  },
  {
    "zip": 60041,
    "lat": 42.36833,
    "lng": -88.15541,
    "state_id": "IL"
  },
  {
    "zip": 60042,
    "lat": 42.27915,
    "lng": -88.19792,
    "state_id": "IL"
  },
  {
    "zip": 60043,
    "lat": 42.08886,
    "lng": -87.71459,
    "state_id": "IL"
  },
  {
    "zip": 60044,
    "lat": 42.28537,
    "lng": -87.86796,
    "state_id": "IL"
  },
  {
    "zip": 60045,
    "lat": 42.23839,
    "lng": -87.86978,
    "state_id": "IL"
  },
  {
    "zip": 60046,
    "lat": 42.41608,
    "lng": -88.06042,
    "state_id": "IL"
  },
  {
    "zip": 60047,
    "lat": 42.20226,
    "lng": -88.04546,
    "state_id": "IL"
  },
  {
    "zip": 60048,
    "lat": 42.29522,
    "lng": -87.94981,
    "state_id": "IL"
  },
  {
    "zip": 60050,
    "lat": 42.33113,
    "lng": -88.29553,
    "state_id": "IL"
  },
  {
    "zip": 60051,
    "lat": 42.35421,
    "lng": -88.22935,
    "state_id": "IL"
  },
  {
    "zip": 60053,
    "lat": 42.04234,
    "lng": -87.78897,
    "state_id": "IL"
  },
  {
    "zip": 60056,
    "lat": 42.06653,
    "lng": -87.93426,
    "state_id": "IL"
  },
  {
    "zip": 60060,
    "lat": 42.2699,
    "lng": -88.03972,
    "state_id": "IL"
  },
  {
    "zip": 60061,
    "lat": 42.23376,
    "lng": -87.96074,
    "state_id": "IL"
  },
  {
    "zip": 60062,
    "lat": 42.12656,
    "lng": -87.84438,
    "state_id": "IL"
  },
  {
    "zip": 60064,
    "lat": 42.32251,
    "lng": -87.86059,
    "state_id": "IL"
  },
  {
    "zip": 60067,
    "lat": 42.10658,
    "lng": -88.06541,
    "state_id": "IL"
  },
  {
    "zip": 60068,
    "lat": 42.0125,
    "lng": -87.84355,
    "state_id": "IL"
  },
  {
    "zip": 60069,
    "lat": 42.19762,
    "lng": -87.92607,
    "state_id": "IL"
  },
  {
    "zip": 60070,
    "lat": 42.10343,
    "lng": -87.93022,
    "state_id": "IL"
  },
  {
    "zip": 60071,
    "lat": 42.4637,
    "lng": -88.31305,
    "state_id": "IL"
  },
  {
    "zip": 60072,
    "lat": 42.40669,
    "lng": -88.30596,
    "state_id": "IL"
  },
  {
    "zip": 60073,
    "lat": 42.34816,
    "lng": -88.10977,
    "state_id": "IL"
  },
  {
    "zip": 60074,
    "lat": 42.13149,
    "lng": -88.02657,
    "state_id": "IL"
  },
  {
    "zip": 60076,
    "lat": 42.03525,
    "lng": -87.72988,
    "state_id": "IL"
  },
  {
    "zip": 60077,
    "lat": 42.03478,
    "lng": -87.75719,
    "state_id": "IL"
  },
  {
    "zip": 60081,
    "lat": 42.45075,
    "lng": -88.22623,
    "state_id": "IL"
  },
  {
    "zip": 60083,
    "lat": 42.43614,
    "lng": -87.9459,
    "state_id": "IL"
  },
  {
    "zip": 60084,
    "lat": 42.26788,
    "lng": -88.14094,
    "state_id": "IL"
  },
  {
    "zip": 60085,
    "lat": 42.35333,
    "lng": -87.86825,
    "state_id": "IL"
  },
  {
    "zip": 60087,
    "lat": 42.40344,
    "lng": -87.8538,
    "state_id": "IL"
  },
  {
    "zip": 60088,
    "lat": 42.30639,
    "lng": -87.85274,
    "state_id": "IL"
  },
  {
    "zip": 60089,
    "lat": 42.16694,
    "lng": -87.96063,
    "state_id": "IL"
  },
  {
    "zip": 60090,
    "lat": 42.12952,
    "lng": -87.92194,
    "state_id": "IL"
  },
  {
    "zip": 60091,
    "lat": 42.07696,
    "lng": -87.72801,
    "state_id": "IL"
  },
  {
    "zip": 60093,
    "lat": 42.10437,
    "lng": -87.7587,
    "state_id": "IL"
  },
  {
    "zip": 60096,
    "lat": 42.48091,
    "lng": -87.82974,
    "state_id": "IL"
  },
  {
    "zip": 60097,
    "lat": 42.39605,
    "lng": -88.36481,
    "state_id": "IL"
  },
  {
    "zip": 60098,
    "lat": 42.32518,
    "lng": -88.45788,
    "state_id": "IL"
  },
  {
    "zip": 60099,
    "lat": 42.46127,
    "lng": -87.86987,
    "state_id": "IL"
  },
  {
    "zip": 60101,
    "lat": 41.93064,
    "lng": -88.01186,
    "state_id": "IL"
  },
  {
    "zip": 60102,
    "lat": 42.16403,
    "lng": -88.30682,
    "state_id": "IL"
  },
  {
    "zip": 60103,
    "lat": 41.97941,
    "lng": -88.20633,
    "state_id": "IL"
  },
  {
    "zip": 60104,
    "lat": 41.88283,
    "lng": -87.87644,
    "state_id": "IL"
  },
  {
    "zip": 60106,
    "lat": 41.9604,
    "lng": -87.94184,
    "state_id": "IL"
  },
  {
    "zip": 60107,
    "lat": 42.02214,
    "lng": -88.17931,
    "state_id": "IL"
  },
  {
    "zip": 60108,
    "lat": 41.94927,
    "lng": -88.09168,
    "state_id": "IL"
  },
  {
    "zip": 60109,
    "lat": 42.04982,
    "lng": -88.54598,
    "state_id": "IL"
  },
  {
    "zip": 60110,
    "lat": 42.12295,
    "lng": -88.28589,
    "state_id": "IL"
  },
  {
    "zip": 60111,
    "lat": 42.00818,
    "lng": -88.8281,
    "state_id": "IL"
  },
  {
    "zip": 60112,
    "lat": 41.92431,
    "lng": -88.6908,
    "state_id": "IL"
  },
  {
    "zip": 60113,
    "lat": 41.93221,
    "lng": -88.96583,
    "state_id": "IL"
  },
  {
    "zip": 60115,
    "lat": 41.90083,
    "lng": -88.75481,
    "state_id": "IL"
  },
  {
    "zip": 60118,
    "lat": 42.1067,
    "lng": -88.30523,
    "state_id": "IL"
  },
  {
    "zip": 60119,
    "lat": 41.86048,
    "lng": -88.47668,
    "state_id": "IL"
  },
  {
    "zip": 60120,
    "lat": 42.03452,
    "lng": -88.23846,
    "state_id": "IL"
  },
  {
    "zip": 60123,
    "lat": 42.04022,
    "lng": -88.31131,
    "state_id": "IL"
  },
  {
    "zip": 60124,
    "lat": 42.02582,
    "lng": -88.39709,
    "state_id": "IL"
  },
  {
    "zip": 60126,
    "lat": 41.89648,
    "lng": -87.94206,
    "state_id": "IL"
  },
  {
    "zip": 60129,
    "lat": 42.02643,
    "lng": -88.95629,
    "state_id": "IL"
  },
  {
    "zip": 60130,
    "lat": 41.86642,
    "lng": -87.81738,
    "state_id": "IL"
  },
  {
    "zip": 60131,
    "lat": 41.93884,
    "lng": -87.88441,
    "state_id": "IL"
  },
  {
    "zip": 60133,
    "lat": 41.97761,
    "lng": -88.14301,
    "state_id": "IL"
  },
  {
    "zip": 60134,
    "lat": 41.87857,
    "lng": -88.34194,
    "state_id": "IL"
  },
  {
    "zip": 60135,
    "lat": 42.11223,
    "lng": -88.67744,
    "state_id": "IL"
  },
  {
    "zip": 60136,
    "lat": 42.10469,
    "lng": -88.37826,
    "state_id": "IL"
  },
  {
    "zip": 60137,
    "lat": 41.86518,
    "lng": -88.06188,
    "state_id": "IL"
  },
  {
    "zip": 60139,
    "lat": 41.91932,
    "lng": -88.07787,
    "state_id": "IL"
  },
  {
    "zip": 60140,
    "lat": 42.07838,
    "lng": -88.50654,
    "state_id": "IL"
  },
  {
    "zip": 60141,
    "lat": 41.85786,
    "lng": -87.838,
    "state_id": "IL"
  },
  {
    "zip": 60142,
    "lat": 42.17563,
    "lng": -88.44252,
    "state_id": "IL"
  },
  {
    "zip": 60143,
    "lat": 41.97306,
    "lng": -88.02155,
    "state_id": "IL"
  },
  {
    "zip": 60144,
    "lat": 41.83593,
    "lng": -88.52062,
    "state_id": "IL"
  },
  {
    "zip": 60145,
    "lat": 42.09365,
    "lng": -88.77655,
    "state_id": "IL"
  },
  {
    "zip": 60146,
    "lat": 42.09947,
    "lng": -88.88133,
    "state_id": "IL"
  },
  {
    "zip": 60148,
    "lat": 41.87414,
    "lng": -88.01817,
    "state_id": "IL"
  },
  {
    "zip": 60150,
    "lat": 41.92494,
    "lng": -88.88864,
    "state_id": "IL"
  },
  {
    "zip": 60151,
    "lat": 41.91612,
    "lng": -88.56663,
    "state_id": "IL"
  },
  {
    "zip": 60152,
    "lat": 42.23695,
    "lng": -88.62553,
    "state_id": "IL"
  },
  {
    "zip": 60153,
    "lat": 41.8793,
    "lng": -87.84327,
    "state_id": "IL"
  },
  {
    "zip": 60154,
    "lat": 41.84749,
    "lng": -87.89167,
    "state_id": "IL"
  },
  {
    "zip": 60155,
    "lat": 41.85781,
    "lng": -87.85636,
    "state_id": "IL"
  },
  {
    "zip": 60156,
    "lat": 42.19139,
    "lng": -88.34582,
    "state_id": "IL"
  },
  {
    "zip": 60157,
    "lat": 41.97462,
    "lng": -88.05538,
    "state_id": "IL"
  },
  {
    "zip": 60160,
    "lat": 41.90416,
    "lng": -87.86061,
    "state_id": "IL"
  },
  {
    "zip": 60162,
    "lat": 41.86758,
    "lng": -87.90222,
    "state_id": "IL"
  },
  {
    "zip": 60163,
    "lat": 41.88887,
    "lng": -87.90906,
    "state_id": "IL"
  },
  {
    "zip": 60164,
    "lat": 41.91751,
    "lng": -87.90066,
    "state_id": "IL"
  },
  {
    "zip": 60165,
    "lat": 41.90325,
    "lng": -87.88077,
    "state_id": "IL"
  },
  {
    "zip": 60169,
    "lat": 42.05039,
    "lng": -88.11667,
    "state_id": "IL"
  },
  {
    "zip": 60171,
    "lat": 41.92507,
    "lng": -87.83843,
    "state_id": "IL"
  },
  {
    "zip": 60172,
    "lat": 41.97967,
    "lng": -88.08963,
    "state_id": "IL"
  },
  {
    "zip": 60173,
    "lat": 42.05157,
    "lng": -88.05195,
    "state_id": "IL"
  },
  {
    "zip": 60174,
    "lat": 41.92721,
    "lng": -88.29934,
    "state_id": "IL"
  },
  {
    "zip": 60175,
    "lat": 41.947,
    "lng": -88.39105,
    "state_id": "IL"
  },
  {
    "zip": 60176,
    "lat": 41.95823,
    "lng": -87.86888,
    "state_id": "IL"
  },
  {
    "zip": 60177,
    "lat": 41.98991,
    "lng": -88.30996,
    "state_id": "IL"
  },
  {
    "zip": 60178,
    "lat": 42.00638,
    "lng": -88.66894,
    "state_id": "IL"
  },
  {
    "zip": 60180,
    "lat": 42.22522,
    "lng": -88.5247,
    "state_id": "IL"
  },
  {
    "zip": 60181,
    "lat": 41.87825,
    "lng": -87.97635,
    "state_id": "IL"
  },
  {
    "zip": 60184,
    "lat": 41.95248,
    "lng": -88.25373,
    "state_id": "IL"
  },
  {
    "zip": 60185,
    "lat": 41.89482,
    "lng": -88.21263,
    "state_id": "IL"
  },
  {
    "zip": 60187,
    "lat": 41.87241,
    "lng": -88.11229,
    "state_id": "IL"
  },
  {
    "zip": 60188,
    "lat": 41.91589,
    "lng": -88.1292,
    "state_id": "IL"
  },
  {
    "zip": 60189,
    "lat": 41.83975,
    "lng": -88.11786,
    "state_id": "IL"
  },
  {
    "zip": 60190,
    "lat": 41.8717,
    "lng": -88.15683,
    "state_id": "IL"
  },
  {
    "zip": 60191,
    "lat": 41.96613,
    "lng": -87.98077,
    "state_id": "IL"
  },
  {
    "zip": 60192,
    "lat": 42.0737,
    "lng": -88.18227,
    "state_id": "IL"
  },
  {
    "zip": 60193,
    "lat": 42.01176,
    "lng": -88.09626,
    "state_id": "IL"
  },
  {
    "zip": 60194,
    "lat": 42.03363,
    "lng": -88.11148,
    "state_id": "IL"
  },
  {
    "zip": 60195,
    "lat": 42.06705,
    "lng": -88.09128,
    "state_id": "IL"
  },
  {
    "zip": 60201,
    "lat": 42.05652,
    "lng": -87.6972,
    "state_id": "IL"
  },
  {
    "zip": 60202,
    "lat": 42.03045,
    "lng": -87.68954,
    "state_id": "IL"
  },
  {
    "zip": 60203,
    "lat": 42.04904,
    "lng": -87.71748,
    "state_id": "IL"
  },
  {
    "zip": 60301,
    "lat": 41.88876,
    "lng": -87.79919,
    "state_id": "IL"
  },
  {
    "zip": 60302,
    "lat": 41.89463,
    "lng": -87.78971,
    "state_id": "IL"
  },
  {
    "zip": 60304,
    "lat": 41.87243,
    "lng": -87.78948,
    "state_id": "IL"
  },
  {
    "zip": 60305,
    "lat": 41.895,
    "lng": -87.81936,
    "state_id": "IL"
  },
  {
    "zip": 60401,
    "lat": 41.34397,
    "lng": -87.61375,
    "state_id": "IL"
  },
  {
    "zip": 60402,
    "lat": 41.83469,
    "lng": -87.79139,
    "state_id": "IL"
  },
  {
    "zip": 60403,
    "lat": 41.57225,
    "lng": -88.11385,
    "state_id": "IL"
  },
  {
    "zip": 60404,
    "lat": 41.50876,
    "lng": -88.22307,
    "state_id": "IL"
  },
  {
    "zip": 60406,
    "lat": 41.65472,
    "lng": -87.68184,
    "state_id": "IL"
  },
  {
    "zip": 60407,
    "lat": 41.23056,
    "lng": -88.2693,
    "state_id": "IL"
  },
  {
    "zip": 60408,
    "lat": 41.26374,
    "lng": -88.21674,
    "state_id": "IL"
  },
  {
    "zip": 60409,
    "lat": 41.61324,
    "lng": -87.55133,
    "state_id": "IL"
  },
  {
    "zip": 60410,
    "lat": 41.42205,
    "lng": -88.21367,
    "state_id": "IL"
  },
  {
    "zip": 60411,
    "lat": 41.50869,
    "lng": -87.59056,
    "state_id": "IL"
  },
  {
    "zip": 60415,
    "lat": 41.70292,
    "lng": -87.77881,
    "state_id": "IL"
  },
  {
    "zip": 60416,
    "lat": 41.29311,
    "lng": -88.2818,
    "state_id": "IL"
  },
  {
    "zip": 60417,
    "lat": 41.42888,
    "lng": -87.5932,
    "state_id": "IL"
  },
  {
    "zip": 60419,
    "lat": 41.62773,
    "lng": -87.59942,
    "state_id": "IL"
  },
  {
    "zip": 60420,
    "lat": 41.08775,
    "lng": -88.4172,
    "state_id": "IL"
  },
  {
    "zip": 60421,
    "lat": 41.43128,
    "lng": -88.09955,
    "state_id": "IL"
  },
  {
    "zip": 60422,
    "lat": 41.53698,
    "lng": -87.68411,
    "state_id": "IL"
  },
  {
    "zip": 60423,
    "lat": 41.47957,
    "lng": -87.83793,
    "state_id": "IL"
  },
  {
    "zip": 60424,
    "lat": 41.16823,
    "lng": -88.3272,
    "state_id": "IL"
  },
  {
    "zip": 60425,
    "lat": 41.54551,
    "lng": -87.61171,
    "state_id": "IL"
  },
  {
    "zip": 60426,
    "lat": 41.61032,
    "lng": -87.65341,
    "state_id": "IL"
  },
  {
    "zip": 60428,
    "lat": 41.59979,
    "lng": -87.69055,
    "state_id": "IL"
  },
  {
    "zip": 60429,
    "lat": 41.57366,
    "lng": -87.68409,
    "state_id": "IL"
  },
  {
    "zip": 60430,
    "lat": 41.55767,
    "lng": -87.66468,
    "state_id": "IL"
  },
  {
    "zip": 60431,
    "lat": 41.53266,
    "lng": -88.20521,
    "state_id": "IL"
  },
  {
    "zip": 60432,
    "lat": 41.54032,
    "lng": -88.04157,
    "state_id": "IL"
  },
  {
    "zip": 60433,
    "lat": 41.49968,
    "lng": -88.04321,
    "state_id": "IL"
  },
  {
    "zip": 60435,
    "lat": 41.54539,
    "lng": -88.1299,
    "state_id": "IL"
  },
  {
    "zip": 60436,
    "lat": 41.49232,
    "lng": -88.12258,
    "state_id": "IL"
  },
  {
    "zip": 60437,
    "lat": 41.16307,
    "lng": -88.5535,
    "state_id": "IL"
  },
  {
    "zip": 60438,
    "lat": 41.56661,
    "lng": -87.55086,
    "state_id": "IL"
  },
  {
    "zip": 60439,
    "lat": 41.67519,
    "lng": -87.98218,
    "state_id": "IL"
  },
  {
    "zip": 60440,
    "lat": 41.70023,
    "lng": -88.07511,
    "state_id": "IL"
  },
  {
    "zip": 60441,
    "lat": 41.59297,
    "lng": -88.05077,
    "state_id": "IL"
  },
  {
    "zip": 60442,
    "lat": 41.39274,
    "lng": -87.96353,
    "state_id": "IL"
  },
  {
    "zip": 60443,
    "lat": 41.50371,
    "lng": -87.74683,
    "state_id": "IL"
  },
  {
    "zip": 60444,
    "lat": 41.24236,
    "lng": -88.40222,
    "state_id": "IL"
  },
  {
    "zip": 60445,
    "lat": 41.63502,
    "lng": -87.7362,
    "state_id": "IL"
  },
  {
    "zip": 60446,
    "lat": 41.63181,
    "lng": -88.10459,
    "state_id": "IL"
  },
  {
    "zip": 60447,
    "lat": 41.4882,
    "lng": -88.32419,
    "state_id": "IL"
  },
  {
    "zip": 60448,
    "lat": 41.53771,
    "lng": -87.89376,
    "state_id": "IL"
  },
  {
    "zip": 60449,
    "lat": 41.41569,
    "lng": -87.77244,
    "state_id": "IL"
  },
  {
    "zip": 60450,
    "lat": 41.36951,
    "lng": -88.43285,
    "state_id": "IL"
  },
  {
    "zip": 60451,
    "lat": 41.50691,
    "lng": -87.96071,
    "state_id": "IL"
  },
  {
    "zip": 60452,
    "lat": 41.60768,
    "lng": -87.75529,
    "state_id": "IL"
  },
  {
    "zip": 60453,
    "lat": 41.71427,
    "lng": -87.75282,
    "state_id": "IL"
  },
  {
    "zip": 60455,
    "lat": 41.74211,
    "lng": -87.80864,
    "state_id": "IL"
  },
  {
    "zip": 60456,
    "lat": 41.73125,
    "lng": -87.73105,
    "state_id": "IL"
  },
  {
    "zip": 60457,
    "lat": 41.72464,
    "lng": -87.82808,
    "state_id": "IL"
  },
  {
    "zip": 60458,
    "lat": 41.74892,
    "lng": -87.83452,
    "state_id": "IL"
  },
  {
    "zip": 60459,
    "lat": 41.74447,
    "lng": -87.76859,
    "state_id": "IL"
  },
  {
    "zip": 60460,
    "lat": 41.0012,
    "lng": -88.53656,
    "state_id": "IL"
  },
  {
    "zip": 60461,
    "lat": 41.51665,
    "lng": -87.69085,
    "state_id": "IL"
  },
  {
    "zip": 60462,
    "lat": 41.62487,
    "lng": -87.83242,
    "state_id": "IL"
  },
  {
    "zip": 60463,
    "lat": 41.66096,
    "lng": -87.78997,
    "state_id": "IL"
  },
  {
    "zip": 60464,
    "lat": 41.66249,
    "lng": -87.86181,
    "state_id": "IL"
  },
  {
    "zip": 60465,
    "lat": 41.69845,
    "lng": -87.82878,
    "state_id": "IL"
  },
  {
    "zip": 60466,
    "lat": 41.47904,
    "lng": -87.68284,
    "state_id": "IL"
  },
  {
    "zip": 60467,
    "lat": 41.6018,
    "lng": -87.88989,
    "state_id": "IL"
  },
  {
    "zip": 60468,
    "lat": 41.33633,
    "lng": -87.81131,
    "state_id": "IL"
  },
  {
    "zip": 60469,
    "lat": 41.62825,
    "lng": -87.68712,
    "state_id": "IL"
  },
  {
    "zip": 60470,
    "lat": 41.16897,
    "lng": -88.64107,
    "state_id": "IL"
  },
  {
    "zip": 60471,
    "lat": 41.47959,
    "lng": -87.73403,
    "state_id": "IL"
  },
  {
    "zip": 60472,
    "lat": 41.64375,
    "lng": -87.7088,
    "state_id": "IL"
  },
  {
    "zip": 60473,
    "lat": 41.59738,
    "lng": -87.59979,
    "state_id": "IL"
  },
  {
    "zip": 60474,
    "lat": 41.18561,
    "lng": -88.26622,
    "state_id": "IL"
  },
  {
    "zip": 60475,
    "lat": 41.47272,
    "lng": -87.62787,
    "state_id": "IL"
  },
  {
    "zip": 60476,
    "lat": 41.56864,
    "lng": -87.60671,
    "state_id": "IL"
  },
  {
    "zip": 60477,
    "lat": 41.57255,
    "lng": -87.78869,
    "state_id": "IL"
  },
  {
    "zip": 60478,
    "lat": 41.56381,
    "lng": -87.72468,
    "state_id": "IL"
  },
  {
    "zip": 60479,
    "lat": 41.2287,
    "lng": -88.51791,
    "state_id": "IL"
  },
  {
    "zip": 60480,
    "lat": 41.72896,
    "lng": -87.88088,
    "state_id": "IL"
  },
  {
    "zip": 60481,
    "lat": 41.28399,
    "lng": -88.12307,
    "state_id": "IL"
  },
  {
    "zip": 60482,
    "lat": 41.68735,
    "lng": -87.79106,
    "state_id": "IL"
  },
  {
    "zip": 60484,
    "lat": 41.44208,
    "lng": -87.70622,
    "state_id": "IL"
  },
  {
    "zip": 60487,
    "lat": 41.56358,
    "lng": -87.83422,
    "state_id": "IL"
  },
  {
    "zip": 60490,
    "lat": 41.67474,
    "lng": -88.1444,
    "state_id": "IL"
  },
  {
    "zip": 60491,
    "lat": 41.60277,
    "lng": -87.95989,
    "state_id": "IL"
  },
  {
    "zip": 60501,
    "lat": 41.78014,
    "lng": -87.82394,
    "state_id": "IL"
  },
  {
    "zip": 60502,
    "lat": 41.7921,
    "lng": -88.25909,
    "state_id": "IL"
  },
  {
    "zip": 60503,
    "lat": 41.71277,
    "lng": -88.25651,
    "state_id": "IL"
  },
  {
    "zip": 60504,
    "lat": 41.74635,
    "lng": -88.23848,
    "state_id": "IL"
  },
  {
    "zip": 60505,
    "lat": 41.76429,
    "lng": -88.29409,
    "state_id": "IL"
  },
  {
    "zip": 60506,
    "lat": 41.76524,
    "lng": -88.36503,
    "state_id": "IL"
  },
  {
    "zip": 60510,
    "lat": 41.84212,
    "lng": -88.30552,
    "state_id": "IL"
  },
  {
    "zip": 60511,
    "lat": 41.75795,
    "lng": -88.55615,
    "state_id": "IL"
  },
  {
    "zip": 60512,
    "lat": 41.70162,
    "lng": -88.43968,
    "state_id": "IL"
  },
  {
    "zip": 60513,
    "lat": 41.82458,
    "lng": -87.84691,
    "state_id": "IL"
  },
  {
    "zip": 60514,
    "lat": 41.79653,
    "lng": -87.95693,
    "state_id": "IL"
  },
  {
    "zip": 60515,
    "lat": 41.80959,
    "lng": -88.02289,
    "state_id": "IL"
  },
  {
    "zip": 60516,
    "lat": 41.76246,
    "lng": -88.01358,
    "state_id": "IL"
  },
  {
    "zip": 60517,
    "lat": 41.74207,
    "lng": -88.04239,
    "state_id": "IL"
  },
  {
    "zip": 60518,
    "lat": 41.59783,
    "lng": -88.91873,
    "state_id": "IL"
  },
  {
    "zip": 60519,
    "lat": 41.77789,
    "lng": -88.24233,
    "state_id": "IL"
  },
  {
    "zip": 60520,
    "lat": 41.78252,
    "lng": -88.65816,
    "state_id": "IL"
  },
  {
    "zip": 60521,
    "lat": 41.79998,
    "lng": -87.92879,
    "state_id": "IL"
  },
  {
    "zip": 60523,
    "lat": 41.83707,
    "lng": -87.95404,
    "state_id": "IL"
  },
  {
    "zip": 60525,
    "lat": 41.78424,
    "lng": -87.86893,
    "state_id": "IL"
  },
  {
    "zip": 60526,
    "lat": 41.83183,
    "lng": -87.87395,
    "state_id": "IL"
  },
  {
    "zip": 60527,
    "lat": 41.74463,
    "lng": -87.93334,
    "state_id": "IL"
  },
  {
    "zip": 60530,
    "lat": 41.79491,
    "lng": -88.96062,
    "state_id": "IL"
  },
  {
    "zip": 60531,
    "lat": 41.6157,
    "lng": -88.78691,
    "state_id": "IL"
  },
  {
    "zip": 60532,
    "lat": 41.79242,
    "lng": -88.08445,
    "state_id": "IL"
  },
  {
    "zip": 60534,
    "lat": 41.81338,
    "lng": -87.82213,
    "state_id": "IL"
  },
  {
    "zip": 60536,
    "lat": 41.59726,
    "lng": -88.54992,
    "state_id": "IL"
  },
  {
    "zip": 60537,
    "lat": 41.56208,
    "lng": -88.60243,
    "state_id": "IL"
  },
  {
    "zip": 60538,
    "lat": 41.72218,
    "lng": -88.35905,
    "state_id": "IL"
  },
  {
    "zip": 60539,
    "lat": 41.82567,
    "lng": -88.33458,
    "state_id": "IL"
  },
  {
    "zip": 60540,
    "lat": 41.76365,
    "lng": -88.14515,
    "state_id": "IL"
  },
  {
    "zip": 60541,
    "lat": 41.51664,
    "lng": -88.52541,
    "state_id": "IL"
  },
  {
    "zip": 60542,
    "lat": 41.80928,
    "lng": -88.35064,
    "state_id": "IL"
  },
  {
    "zip": 60543,
    "lat": 41.66526,
    "lng": -88.32321,
    "state_id": "IL"
  },
  {
    "zip": 60544,
    "lat": 41.61337,
    "lng": -88.21666,
    "state_id": "IL"
  },
  {
    "zip": 60545,
    "lat": 41.67749,
    "lng": -88.53667,
    "state_id": "IL"
  },
  {
    "zip": 60546,
    "lat": 41.83803,
    "lng": -87.82144,
    "state_id": "IL"
  },
  {
    "zip": 60548,
    "lat": 41.64812,
    "lng": -88.63617,
    "state_id": "IL"
  },
  {
    "zip": 60549,
    "lat": 41.49085,
    "lng": -88.74238,
    "state_id": "IL"
  },
  {
    "zip": 60550,
    "lat": 41.77881,
    "lng": -88.87592,
    "state_id": "IL"
  },
  {
    "zip": 60551,
    "lat": 41.52537,
    "lng": -88.68474,
    "state_id": "IL"
  },
  {
    "zip": 60552,
    "lat": 41.6599,
    "lng": -88.70302,
    "state_id": "IL"
  },
  {
    "zip": 60553,
    "lat": 41.82791,
    "lng": -89.02682,
    "state_id": "IL"
  },
  {
    "zip": 60554,
    "lat": 41.77601,
    "lng": -88.45653,
    "state_id": "IL"
  },
  {
    "zip": 60555,
    "lat": 41.82274,
    "lng": -88.18135,
    "state_id": "IL"
  },
  {
    "zip": 60556,
    "lat": 41.76263,
    "lng": -88.77345,
    "state_id": "IL"
  },
  {
    "zip": 60557,
    "lat": 41.44553,
    "lng": -88.76381,
    "state_id": "IL"
  },
  {
    "zip": 60558,
    "lat": 41.80612,
    "lng": -87.90159,
    "state_id": "IL"
  },
  {
    "zip": 60559,
    "lat": 41.79401,
    "lng": -87.97484,
    "state_id": "IL"
  },
  {
    "zip": 60560,
    "lat": 41.60802,
    "lng": -88.42971,
    "state_id": "IL"
  },
  {
    "zip": 60561,
    "lat": 41.74536,
    "lng": -87.98241,
    "state_id": "IL"
  },
  {
    "zip": 60563,
    "lat": 41.7997,
    "lng": -88.17025,
    "state_id": "IL"
  },
  {
    "zip": 60564,
    "lat": 41.70819,
    "lng": -88.19854,
    "state_id": "IL"
  },
  {
    "zip": 60565,
    "lat": 41.73006,
    "lng": -88.12393,
    "state_id": "IL"
  },
  {
    "zip": 60585,
    "lat": 41.65936,
    "lng": -88.22417,
    "state_id": "IL"
  },
  {
    "zip": 60586,
    "lat": 41.57046,
    "lng": -88.23342,
    "state_id": "IL"
  },
  {
    "zip": 60601,
    "lat": 41.88526,
    "lng": -87.62194,
    "state_id": "IL"
  },
  {
    "zip": 60602,
    "lat": 41.88309,
    "lng": -87.62912,
    "state_id": "IL"
  },
  {
    "zip": 60603,
    "lat": 41.88022,
    "lng": -87.62549,
    "state_id": "IL"
  },
  {
    "zip": 60604,
    "lat": 41.87814,
    "lng": -87.62837,
    "state_id": "IL"
  },
  {
    "zip": 60605,
    "lat": 41.86684,
    "lng": -87.61983,
    "state_id": "IL"
  },
  {
    "zip": 60606,
    "lat": 41.88195,
    "lng": -87.63731,
    "state_id": "IL"
  },
  {
    "zip": 60607,
    "lat": 41.87503,
    "lng": -87.65157,
    "state_id": "IL"
  },
  {
    "zip": 60608,
    "lat": 41.84878,
    "lng": -87.67131,
    "state_id": "IL"
  },
  {
    "zip": 60609,
    "lat": 41.81252,
    "lng": -87.6556,
    "state_id": "IL"
  },
  {
    "zip": 60610,
    "lat": 41.90487,
    "lng": -87.63615,
    "state_id": "IL"
  },
  {
    "zip": 60611,
    "lat": 41.89472,
    "lng": -87.61938,
    "state_id": "IL"
  },
  {
    "zip": 60612,
    "lat": 41.88033,
    "lng": -87.68765,
    "state_id": "IL"
  },
  {
    "zip": 60613,
    "lat": 41.95586,
    "lng": -87.65781,
    "state_id": "IL"
  },
  {
    "zip": 60614,
    "lat": 41.92273,
    "lng": -87.65131,
    "state_id": "IL"
  },
  {
    "zip": 60615,
    "lat": 41.80223,
    "lng": -87.60259,
    "state_id": "IL"
  },
  {
    "zip": 60616,
    "lat": 41.84525,
    "lng": -87.62725,
    "state_id": "IL"
  },
  {
    "zip": 60617,
    "lat": 41.71591,
    "lng": -87.55432,
    "state_id": "IL"
  },
  {
    "zip": 60618,
    "lat": 41.947,
    "lng": -87.70244,
    "state_id": "IL"
  },
  {
    "zip": 60619,
    "lat": 41.74365,
    "lng": -87.60557,
    "state_id": "IL"
  },
  {
    "zip": 60620,
    "lat": 41.7408,
    "lng": -87.65252,
    "state_id": "IL"
  },
  {
    "zip": 60621,
    "lat": 41.77639,
    "lng": -87.63943,
    "state_id": "IL"
  },
  {
    "zip": 60622,
    "lat": 41.90275,
    "lng": -87.6833,
    "state_id": "IL"
  },
  {
    "zip": 60623,
    "lat": 41.84808,
    "lng": -87.71778,
    "state_id": "IL"
  },
  {
    "zip": 60624,
    "lat": 41.88056,
    "lng": -87.72335,
    "state_id": "IL"
  },
  {
    "zip": 60625,
    "lat": 41.97341,
    "lng": -87.7002,
    "state_id": "IL"
  },
  {
    "zip": 60626,
    "lat": 42.00903,
    "lng": -87.66962,
    "state_id": "IL"
  },
  {
    "zip": 60628,
    "lat": 41.69176,
    "lng": -87.61796,
    "state_id": "IL"
  },
  {
    "zip": 60629,
    "lat": 41.77567,
    "lng": -87.71176,
    "state_id": "IL"
  },
  {
    "zip": 60630,
    "lat": 41.97213,
    "lng": -87.75709,
    "state_id": "IL"
  },
  {
    "zip": 60631,
    "lat": 41.99474,
    "lng": -87.81318,
    "state_id": "IL"
  },
  {
    "zip": 60632,
    "lat": 41.81133,
    "lng": -87.71335,
    "state_id": "IL"
  },
  {
    "zip": 60633,
    "lat": 41.66435,
    "lng": -87.56136,
    "state_id": "IL"
  },
  {
    "zip": 60634,
    "lat": 41.94634,
    "lng": -87.80606,
    "state_id": "IL"
  },
  {
    "zip": 60636,
    "lat": 41.77575,
    "lng": -87.66911,
    "state_id": "IL"
  },
  {
    "zip": 60637,
    "lat": 41.78143,
    "lng": -87.60318,
    "state_id": "IL"
  },
  {
    "zip": 60638,
    "lat": 41.78145,
    "lng": -87.77056,
    "state_id": "IL"
  },
  {
    "zip": 60639,
    "lat": 41.92058,
    "lng": -87.75607,
    "state_id": "IL"
  },
  {
    "zip": 60640,
    "lat": 41.97237,
    "lng": -87.66347,
    "state_id": "IL"
  },
  {
    "zip": 60641,
    "lat": 41.94663,
    "lng": -87.74669,
    "state_id": "IL"
  },
  {
    "zip": 60642,
    "lat": 41.90161,
    "lng": -87.65803,
    "state_id": "IL"
  },
  {
    "zip": 60643,
    "lat": 41.69955,
    "lng": -87.66276,
    "state_id": "IL"
  },
  {
    "zip": 60644,
    "lat": 41.8802,
    "lng": -87.75751,
    "state_id": "IL"
  },
  {
    "zip": 60645,
    "lat": 42.00853,
    "lng": -87.6948,
    "state_id": "IL"
  },
  {
    "zip": 60646,
    "lat": 41.99301,
    "lng": -87.7596,
    "state_id": "IL"
  },
  {
    "zip": 60647,
    "lat": 41.92065,
    "lng": -87.70175,
    "state_id": "IL"
  },
  {
    "zip": 60649,
    "lat": 41.76303,
    "lng": -87.57036,
    "state_id": "IL"
  },
  {
    "zip": 60651,
    "lat": 41.90208,
    "lng": -87.74089,
    "state_id": "IL"
  },
  {
    "zip": 60652,
    "lat": 41.74795,
    "lng": -87.71479,
    "state_id": "IL"
  },
  {
    "zip": 60653,
    "lat": 41.81924,
    "lng": -87.61007,
    "state_id": "IL"
  },
  {
    "zip": 60654,
    "lat": 41.89228,
    "lng": -87.63727,
    "state_id": "IL"
  },
  {
    "zip": 60655,
    "lat": 41.69476,
    "lng": -87.70379,
    "state_id": "IL"
  },
  {
    "zip": 60656,
    "lat": 41.97424,
    "lng": -87.82692,
    "state_id": "IL"
  },
  {
    "zip": 60657,
    "lat": 41.93991,
    "lng": -87.65382,
    "state_id": "IL"
  },
  {
    "zip": 60659,
    "lat": 41.99108,
    "lng": -87.70416,
    "state_id": "IL"
  },
  {
    "zip": 60660,
    "lat": 41.99111,
    "lng": -87.66605,
    "state_id": "IL"
  },
  {
    "zip": 60661,
    "lat": 41.88309,
    "lng": -87.64401,
    "state_id": "IL"
  },
  {
    "zip": 60706,
    "lat": 41.96425,
    "lng": -87.81624,
    "state_id": "IL"
  },
  {
    "zip": 60707,
    "lat": 41.9218,
    "lng": -87.80724,
    "state_id": "IL"
  },
  {
    "zip": 60712,
    "lat": 42.00545,
    "lng": -87.7333,
    "state_id": "IL"
  },
  {
    "zip": 60714,
    "lat": 42.02805,
    "lng": -87.81098,
    "state_id": "IL"
  },
  {
    "zip": 60803,
    "lat": 41.6721,
    "lng": -87.73568,
    "state_id": "IL"
  },
  {
    "zip": 60804,
    "lat": 41.83783,
    "lng": -87.76014,
    "state_id": "IL"
  },
  {
    "zip": 60805,
    "lat": 41.722,
    "lng": -87.70244,
    "state_id": "IL"
  },
  {
    "zip": 60827,
    "lat": 41.64956,
    "lng": -87.63016,
    "state_id": "IL"
  },
  {
    "zip": 60901,
    "lat": 41.11084,
    "lng": -87.89814,
    "state_id": "IL"
  },
  {
    "zip": 60910,
    "lat": 41.07877,
    "lng": -87.80859,
    "state_id": "IL"
  },
  {
    "zip": 60911,
    "lat": 40.87802,
    "lng": -87.98018,
    "state_id": "IL"
  },
  {
    "zip": 60912,
    "lat": 40.97003,
    "lng": -87.59935,
    "state_id": "IL"
  },
  {
    "zip": 60913,
    "lat": 41.14701,
    "lng": -88.06177,
    "state_id": "IL"
  },
  {
    "zip": 60914,
    "lat": 41.18671,
    "lng": -87.86195,
    "state_id": "IL"
  },
  {
    "zip": 60915,
    "lat": 41.14681,
    "lng": -87.86108,
    "state_id": "IL"
  },
  {
    "zip": 60917,
    "lat": 41.04413,
    "lng": -88.18728,
    "state_id": "IL"
  },
  {
    "zip": 60918,
    "lat": 40.5962,
    "lng": -88.03048,
    "state_id": "IL"
  },
  {
    "zip": 60919,
    "lat": 40.98745,
    "lng": -88.23835,
    "state_id": "IL"
  },
  {
    "zip": 60920,
    "lat": 41.02926,
    "lng": -88.30496,
    "state_id": "IL"
  },
  {
    "zip": 60921,
    "lat": 40.73772,
    "lng": -88.28784,
    "state_id": "IL"
  },
  {
    "zip": 60922,
    "lat": 41.00413,
    "lng": -87.91867,
    "state_id": "IL"
  },
  {
    "zip": 60924,
    "lat": 40.55778,
    "lng": -87.88532,
    "state_id": "IL"
  },
  {
    "zip": 60926,
    "lat": 40.57234,
    "lng": -87.80962,
    "state_id": "IL"
  },
  {
    "zip": 60927,
    "lat": 40.93931,
    "lng": -87.96804,
    "state_id": "IL"
  },
  {
    "zip": 60928,
    "lat": 40.74163,
    "lng": -87.84902,
    "state_id": "IL"
  },
  {
    "zip": 60929,
    "lat": 40.87497,
    "lng": -88.28915,
    "state_id": "IL"
  },
  {
    "zip": 60930,
    "lat": 40.82989,
    "lng": -88.00256,
    "state_id": "IL"
  },
  {
    "zip": 60931,
    "lat": 40.89039,
    "lng": -87.58523,
    "state_id": "IL"
  },
  {
    "zip": 60932,
    "lat": 40.46333,
    "lng": -87.80563,
    "state_id": "IL"
  },
  {
    "zip": 60933,
    "lat": 40.4647,
    "lng": -88.27192,
    "state_id": "IL"
  },
  {
    "zip": 60934,
    "lat": 40.96171,
    "lng": -88.3512,
    "state_id": "IL"
  },
  {
    "zip": 60935,
    "lat": 41.17297,
    "lng": -88.17608,
    "state_id": "IL"
  },
  {
    "zip": 60936,
    "lat": 40.46679,
    "lng": -88.35707,
    "state_id": "IL"
  },
  {
    "zip": 60938,
    "lat": 40.77329,
    "lng": -87.98865,
    "state_id": "IL"
  },
  {
    "zip": 60940,
    "lat": 41.2506,
    "lng": -87.64282,
    "state_id": "IL"
  },
  {
    "zip": 60941,
    "lat": 41.04201,
    "lng": -88.0821,
    "state_id": "IL"
  },
  {
    "zip": 60942,
    "lat": 40.46563,
    "lng": -87.66092,
    "state_id": "IL"
  },
  {
    "zip": 60945,
    "lat": 40.82829,
    "lng": -87.58373,
    "state_id": "IL"
  },
  {
    "zip": 60946,
    "lat": 40.91652,
    "lng": -88.20291,
    "state_id": "IL"
  },
  {
    "zip": 60948,
    "lat": 40.52548,
    "lng": -88.08197,
    "state_id": "IL"
  },
  {
    "zip": 60949,
    "lat": 40.38216,
    "lng": -88.10289,
    "state_id": "IL"
  },
  {
    "zip": 60950,
    "lat": 41.25119,
    "lng": -87.88445,
    "state_id": "IL"
  },
  {
    "zip": 60951,
    "lat": 40.92138,
    "lng": -87.75109,
    "state_id": "IL"
  },
  {
    "zip": 60952,
    "lat": 40.56446,
    "lng": -88.25279,
    "state_id": "IL"
  },
  {
    "zip": 60953,
    "lat": 40.62992,
    "lng": -87.68812,
    "state_id": "IL"
  },
  {
    "zip": 60954,
    "lat": 41.15338,
    "lng": -87.62902,
    "state_id": "IL"
  },
  {
    "zip": 60955,
    "lat": 40.69739,
    "lng": -87.97654,
    "state_id": "IL"
  },
  {
    "zip": 60957,
    "lat": 40.44296,
    "lng": -88.13624,
    "state_id": "IL"
  },
  {
    "zip": 60958,
    "lat": 41.06492,
    "lng": -87.59135,
    "state_id": "IL"
  },
  {
    "zip": 60959,
    "lat": 40.78971,
    "lng": -88.182,
    "state_id": "IL"
  },
  {
    "zip": 60960,
    "lat": 40.43873,
    "lng": -87.88999,
    "state_id": "IL"
  },
  {
    "zip": 60961,
    "lat": 41.10651,
    "lng": -88.23084,
    "state_id": "IL"
  },
  {
    "zip": 60962,
    "lat": 40.62283,
    "lng": -88.18445,
    "state_id": "IL"
  },
  {
    "zip": 60963,
    "lat": 40.36667,
    "lng": -87.6485,
    "state_id": "IL"
  },
  {
    "zip": 60964,
    "lat": 41.03551,
    "lng": -87.73825,
    "state_id": "IL"
  },
  {
    "zip": 60966,
    "lat": 40.75153,
    "lng": -87.58315,
    "state_id": "IL"
  },
  {
    "zip": 60968,
    "lat": 40.67241,
    "lng": -88.12066,
    "state_id": "IL"
  },
  {
    "zip": 60969,
    "lat": 41.11102,
    "lng": -88.14976,
    "state_id": "IL"
  },
  {
    "zip": 60970,
    "lat": 40.79334,
    "lng": -87.73838,
    "state_id": "IL"
  },
  {
    "zip": 60973,
    "lat": 40.54172,
    "lng": -87.6631,
    "state_id": "IL"
  },
  {
    "zip": 60974,
    "lat": 40.71914,
    "lng": -87.73422,
    "state_id": "IL"
  },
  {
    "zip": 61001,
    "lat": 42.46094,
    "lng": -90.12455,
    "state_id": "IL"
  },
  {
    "zip": 61006,
    "lat": 41.86054,
    "lng": -89.20951,
    "state_id": "IL"
  },
  {
    "zip": 61007,
    "lat": 42.19773,
    "lng": -89.58953,
    "state_id": "IL"
  },
  {
    "zip": 61008,
    "lat": 42.24514,
    "lng": -88.84072,
    "state_id": "IL"
  },
  {
    "zip": 61010,
    "lat": 42.13571,
    "lng": -89.26789,
    "state_id": "IL"
  },
  {
    "zip": 61011,
    "lat": 42.38959,
    "lng": -88.91287,
    "state_id": "IL"
  },
  {
    "zip": 61012,
    "lat": 42.40745,
    "lng": -88.75447,
    "state_id": "IL"
  },
  {
    "zip": 61013,
    "lat": 42.3745,
    "lng": -89.63708,
    "state_id": "IL"
  },
  {
    "zip": 61014,
    "lat": 41.97458,
    "lng": -89.88449,
    "state_id": "IL"
  },
  {
    "zip": 61015,
    "lat": 41.99299,
    "lng": -89.20198,
    "state_id": "IL"
  },
  {
    "zip": 61016,
    "lat": 42.19811,
    "lng": -88.9472,
    "state_id": "IL"
  },
  {
    "zip": 61018,
    "lat": 42.41528,
    "lng": -89.55528,
    "state_id": "IL"
  },
  {
    "zip": 61019,
    "lat": 42.44146,
    "lng": -89.41007,
    "state_id": "IL"
  },
  {
    "zip": 61020,
    "lat": 42.11021,
    "lng": -89.09822,
    "state_id": "IL"
  },
  {
    "zip": 61021,
    "lat": 41.8297,
    "lng": -89.47908,
    "state_id": "IL"
  },
  {
    "zip": 61024,
    "lat": 42.43649,
    "lng": -89.29054,
    "state_id": "IL"
  },
  {
    "zip": 61025,
    "lat": 42.47125,
    "lng": -90.56025,
    "state_id": "IL"
  },
  {
    "zip": 61027,
    "lat": 42.33091,
    "lng": -89.75728,
    "state_id": "IL"
  },
  {
    "zip": 61028,
    "lat": 42.29173,
    "lng": -90.16947,
    "state_id": "IL"
  },
  {
    "zip": 61030,
    "lat": 42.11513,
    "lng": -89.58891,
    "state_id": "IL"
  },
  {
    "zip": 61031,
    "lat": 41.8308,
    "lng": -89.31306,
    "state_id": "IL"
  },
  {
    "zip": 61032,
    "lat": 42.31634,
    "lng": -89.63525,
    "state_id": "IL"
  },
  {
    "zip": 61036,
    "lat": 42.41522,
    "lng": -90.39117,
    "state_id": "IL"
  },
  {
    "zip": 61037,
    "lat": 41.78938,
    "lng": -89.76032,
    "state_id": "IL"
  },
  {
    "zip": 61038,
    "lat": 42.26345,
    "lng": -88.73572,
    "state_id": "IL"
  },
  {
    "zip": 61039,
    "lat": 42.20887,
    "lng": -89.46757,
    "state_id": "IL"
  },
  {
    "zip": 61041,
    "lat": 42.27611,
    "lng": -90.30158,
    "state_id": "IL"
  },
  {
    "zip": 61042,
    "lat": 41.68764,
    "lng": -89.56374,
    "state_id": "IL"
  },
  {
    "zip": 61043,
    "lat": 42.05648,
    "lng": -89.10527,
    "state_id": "IL"
  },
  {
    "zip": 61044,
    "lat": 42.3188,
    "lng": -89.91502,
    "state_id": "IL"
  },
  {
    "zip": 61046,
    "lat": 42.10445,
    "lng": -89.81366,
    "state_id": "IL"
  },
  {
    "zip": 61047,
    "lat": 42.15313,
    "lng": -89.39589,
    "state_id": "IL"
  },
  {
    "zip": 61048,
    "lat": 42.38355,
    "lng": -89.83555,
    "state_id": "IL"
  },
  {
    "zip": 61049,
    "lat": 42.04417,
    "lng": -89.00754,
    "state_id": "IL"
  },
  {
    "zip": 61050,
    "lat": 42.44032,
    "lng": -89.73419,
    "state_id": "IL"
  },
  {
    "zip": 61051,
    "lat": 41.98272,
    "lng": -89.7549,
    "state_id": "IL"
  },
  {
    "zip": 61052,
    "lat": 42.11139,
    "lng": -88.99957,
    "state_id": "IL"
  },
  {
    "zip": 61053,
    "lat": 42.11427,
    "lng": -89.98211,
    "state_id": "IL"
  },
  {
    "zip": 61054,
    "lat": 42.05492,
    "lng": -89.44953,
    "state_id": "IL"
  },
  {
    "zip": 61057,
    "lat": 41.82926,
    "lng": -89.38207,
    "state_id": "IL"
  },
  {
    "zip": 61059,
    "lat": 42.45315,
    "lng": -89.94015,
    "state_id": "IL"
  },
  {
    "zip": 61060,
    "lat": 42.47813,
    "lng": -89.62192,
    "state_id": "IL"
  },
  {
    "zip": 61061,
    "lat": 42.00027,
    "lng": -89.33983,
    "state_id": "IL"
  },
  {
    "zip": 61062,
    "lat": 42.25297,
    "lng": -89.84139,
    "state_id": "IL"
  },
  {
    "zip": 61063,
    "lat": 42.31213,
    "lng": -89.34734,
    "state_id": "IL"
  },
  {
    "zip": 61064,
    "lat": 41.98783,
    "lng": -89.5824,
    "state_id": "IL"
  },
  {
    "zip": 61065,
    "lat": 42.40752,
    "lng": -88.82753,
    "state_id": "IL"
  },
  {
    "zip": 61067,
    "lat": 42.30446,
    "lng": -89.47747,
    "state_id": "IL"
  },
  {
    "zip": 61068,
    "lat": 41.94846,
    "lng": -89.0644,
    "state_id": "IL"
  },
  {
    "zip": 61070,
    "lat": 42.41685,
    "lng": -89.47312,
    "state_id": "IL"
  },
  {
    "zip": 61071,
    "lat": 41.72705,
    "lng": -89.70447,
    "state_id": "IL"
  },
  {
    "zip": 61072,
    "lat": 42.44688,
    "lng": -89.14108,
    "state_id": "IL"
  },
  {
    "zip": 61073,
    "lat": 42.42469,
    "lng": -88.9935,
    "state_id": "IL"
  },
  {
    "zip": 61074,
    "lat": 42.12551,
    "lng": -90.12977,
    "state_id": "IL"
  },
  {
    "zip": 61075,
    "lat": 42.46445,
    "lng": -90.25896,
    "state_id": "IL"
  },
  {
    "zip": 61077,
    "lat": 42.2382,
    "lng": -89.35787,
    "state_id": "IL"
  },
  {
    "zip": 61078,
    "lat": 42.16605,
    "lng": -89.72918,
    "state_id": "IL"
  },
  {
    "zip": 61079,
    "lat": 42.43928,
    "lng": -89.2076,
    "state_id": "IL"
  },
  {
    "zip": 61080,
    "lat": 42.47973,
    "lng": -88.98723,
    "state_id": "IL"
  },
  {
    "zip": 61081,
    "lat": 41.84388,
    "lng": -89.73537,
    "state_id": "IL"
  },
  {
    "zip": 61084,
    "lat": 42.11845,
    "lng": -89.18869,
    "state_id": "IL"
  },
  {
    "zip": 61085,
    "lat": 42.34186,
    "lng": -90.03217,
    "state_id": "IL"
  },
  {
    "zip": 61087,
    "lat": 42.4802,
    "lng": -89.99264,
    "state_id": "IL"
  },
  {
    "zip": 61088,
    "lat": 42.26887,
    "lng": -89.25567,
    "state_id": "IL"
  },
  {
    "zip": 61089,
    "lat": 42.47339,
    "lng": -89.81932,
    "state_id": "IL"
  },
  {
    "zip": 61091,
    "lat": 41.90692,
    "lng": -89.52763,
    "state_id": "IL"
  },
  {
    "zip": 61101,
    "lat": 42.33748,
    "lng": -89.14851,
    "state_id": "IL"
  },
  {
    "zip": 61102,
    "lat": 42.22955,
    "lng": -89.15731,
    "state_id": "IL"
  },
  {
    "zip": 61103,
    "lat": 42.3463,
    "lng": -89.08221,
    "state_id": "IL"
  },
  {
    "zip": 61104,
    "lat": 42.25191,
    "lng": -89.07895,
    "state_id": "IL"
  },
  {
    "zip": 61107,
    "lat": 42.28363,
    "lng": -89.00326,
    "state_id": "IL"
  },
  {
    "zip": 61108,
    "lat": 42.25483,
    "lng": -89.00393,
    "state_id": "IL"
  },
  {
    "zip": 61109,
    "lat": 42.19259,
    "lng": -89.05347,
    "state_id": "IL"
  },
  {
    "zip": 61111,
    "lat": 42.33537,
    "lng": -89.00366,
    "state_id": "IL"
  },
  {
    "zip": 61112,
    "lat": 42.24193,
    "lng": -88.97519,
    "state_id": "IL"
  },
  {
    "zip": 61114,
    "lat": 42.3084,
    "lng": -88.99101,
    "state_id": "IL"
  },
  {
    "zip": 61115,
    "lat": 42.36373,
    "lng": -89.02886,
    "state_id": "IL"
  },
  {
    "zip": 61201,
    "lat": 41.4778,
    "lng": -90.57675,
    "state_id": "IL"
  },
  {
    "zip": 61230,
    "lat": 41.73902,
    "lng": -90.21403,
    "state_id": "IL"
  },
  {
    "zip": 61231,
    "lat": 41.20972,
    "lng": -90.72473,
    "state_id": "IL"
  },
  {
    "zip": 61232,
    "lat": 41.43071,
    "lng": -90.73254,
    "state_id": "IL"
  },
  {
    "zip": 61234,
    "lat": 41.42085,
    "lng": -89.91824,
    "state_id": "IL"
  },
  {
    "zip": 61235,
    "lat": 41.39965,
    "lng": -90.02351,
    "state_id": "IL"
  },
  {
    "zip": 61236,
    "lat": 41.51379,
    "lng": -90.36072,
    "state_id": "IL"
  },
  {
    "zip": 61238,
    "lat": 41.28774,
    "lng": -90.17385,
    "state_id": "IL"
  },
  {
    "zip": 61239,
    "lat": 41.48605,
    "lng": -90.38758,
    "state_id": "IL"
  },
  {
    "zip": 61240,
    "lat": 41.42553,
    "lng": -90.42977,
    "state_id": "IL"
  },
  {
    "zip": 61241,
    "lat": 41.4885,
    "lng": -90.32095,
    "state_id": "IL"
  },
  {
    "zip": 61242,
    "lat": 41.70361,
    "lng": -90.27858,
    "state_id": "IL"
  },
  {
    "zip": 61243,
    "lat": 41.61876,
    "lng": -89.68296,
    "state_id": "IL"
  },
  {
    "zip": 61244,
    "lat": 41.52329,
    "lng": -90.39479,
    "state_id": "IL"
  },
  {
    "zip": 61250,
    "lat": 41.65891,
    "lng": -90.10302,
    "state_id": "IL"
  },
  {
    "zip": 61251,
    "lat": 41.73056,
    "lng": -90.07568,
    "state_id": "IL"
  },
  {
    "zip": 61252,
    "lat": 41.84318,
    "lng": -90.11798,
    "state_id": "IL"
  },
  {
    "zip": 61254,
    "lat": 41.46862,
    "lng": -90.15087,
    "state_id": "IL"
  },
  {
    "zip": 61256,
    "lat": 41.55914,
    "lng": -90.40154,
    "state_id": "IL"
  },
  {
    "zip": 61257,
    "lat": 41.59054,
    "lng": -90.22275,
    "state_id": "IL"
  },
  {
    "zip": 61258,
    "lat": 41.52185,
    "lng": -89.91396,
    "state_id": "IL"
  },
  {
    "zip": 61259,
    "lat": 41.38819,
    "lng": -90.93153,
    "state_id": "IL"
  },
  {
    "zip": 61260,
    "lat": 41.24624,
    "lng": -90.88299,
    "state_id": "IL"
  },
  {
    "zip": 61261,
    "lat": 41.7257,
    "lng": -89.91588,
    "state_id": "IL"
  },
  {
    "zip": 61262,
    "lat": 41.2805,
    "lng": -90.35675,
    "state_id": "IL"
  },
  {
    "zip": 61263,
    "lat": 41.25884,
    "lng": -90.60477,
    "state_id": "IL"
  },
  {
    "zip": 61264,
    "lat": 41.3968,
    "lng": -90.59173,
    "state_id": "IL"
  },
  {
    "zip": 61265,
    "lat": 41.48203,
    "lng": -90.49023,
    "state_id": "IL"
  },
  {
    "zip": 61270,
    "lat": 41.82971,
    "lng": -89.9664,
    "state_id": "IL"
  },
  {
    "zip": 61272,
    "lat": 41.24652,
    "lng": -91.00566,
    "state_id": "IL"
  },
  {
    "zip": 61273,
    "lat": 41.36288,
    "lng": -90.39892,
    "state_id": "IL"
  },
  {
    "zip": 61274,
    "lat": 41.36857,
    "lng": -90.27707,
    "state_id": "IL"
  },
  {
    "zip": 61275,
    "lat": 41.5998,
    "lng": -90.2984,
    "state_id": "IL"
  },
  {
    "zip": 61276,
    "lat": 41.30405,
    "lng": -90.5899,
    "state_id": "IL"
  },
  {
    "zip": 61277,
    "lat": 41.60673,
    "lng": -89.93165,
    "state_id": "IL"
  },
  {
    "zip": 61278,
    "lat": 41.58202,
    "lng": -90.33695,
    "state_id": "IL"
  },
  {
    "zip": 61279,
    "lat": 41.32053,
    "lng": -90.72682,
    "state_id": "IL"
  },
  {
    "zip": 61281,
    "lat": 41.29689,
    "lng": -90.51975,
    "state_id": "IL"
  },
  {
    "zip": 61282,
    "lat": 41.4956,
    "lng": -90.413,
    "state_id": "IL"
  },
  {
    "zip": 61283,
    "lat": 41.59187,
    "lng": -89.78655,
    "state_id": "IL"
  },
  {
    "zip": 61284,
    "lat": 41.39364,
    "lng": -90.74672,
    "state_id": "IL"
  },
  {
    "zip": 61285,
    "lat": 41.97814,
    "lng": -90.05951,
    "state_id": "IL"
  },
  {
    "zip": 61301,
    "lat": 41.39484,
    "lng": -89.08042,
    "state_id": "IL"
  },
  {
    "zip": 61310,
    "lat": 41.70002,
    "lng": -89.34998,
    "state_id": "IL"
  },
  {
    "zip": 61311,
    "lat": 41.03781,
    "lng": -88.86003,
    "state_id": "IL"
  },
  {
    "zip": 61312,
    "lat": 41.43577,
    "lng": -89.23295,
    "state_id": "IL"
  },
  {
    "zip": 61313,
    "lat": 41.07368,
    "lng": -88.66868,
    "state_id": "IL"
  },
  {
    "zip": 61314,
    "lat": 41.29609,
    "lng": -89.67409,
    "state_id": "IL"
  },
  {
    "zip": 61315,
    "lat": 41.28765,
    "lng": -89.36422,
    "state_id": "IL"
  },
  {
    "zip": 61316,
    "lat": 41.25904,
    "lng": -89.12502,
    "state_id": "IL"
  },
  {
    "zip": 61317,
    "lat": 41.43146,
    "lng": -89.2089,
    "state_id": "IL"
  },
  {
    "zip": 61318,
    "lat": 41.71213,
    "lng": -89.07967,
    "state_id": "IL"
  },
  {
    "zip": 61319,
    "lat": 41.01846,
    "lng": -88.74352,
    "state_id": "IL"
  },
  {
    "zip": 61320,
    "lat": 41.35459,
    "lng": -89.17042,
    "state_id": "IL"
  },
  {
    "zip": 61321,
    "lat": 40.9692,
    "lng": -88.97316,
    "state_id": "IL"
  },
  {
    "zip": 61322,
    "lat": 41.31914,
    "lng": -89.30746,
    "state_id": "IL"
  },
  {
    "zip": 61323,
    "lat": 41.43303,
    "lng": -89.39676,
    "state_id": "IL"
  },
  {
    "zip": 61324,
    "lat": 41.76991,
    "lng": -89.41389,
    "state_id": "IL"
  },
  {
    "zip": 61325,
    "lat": 41.23694,
    "lng": -88.8064,
    "state_id": "IL"
  },
  {
    "zip": 61326,
    "lat": 41.25384,
    "lng": -89.22433,
    "state_id": "IL"
  },
  {
    "zip": 61327,
    "lat": 41.22685,
    "lng": -89.30942,
    "state_id": "IL"
  },
  {
    "zip": 61328,
    "lat": 41.49955,
    "lng": -89.45929,
    "state_id": "IL"
  },
  {
    "zip": 61329,
    "lat": 41.38049,
    "lng": -89.19878,
    "state_id": "IL"
  },
  {
    "zip": 61330,
    "lat": 41.53797,
    "lng": -89.2748,
    "state_id": "IL"
  },
  {
    "zip": 61331,
    "lat": 41.74745,
    "lng": -89.2768,
    "state_id": "IL"
  },
  {
    "zip": 61332,
    "lat": 41.18873,
    "lng": -88.98881,
    "state_id": "IL"
  },
  {
    "zip": 61333,
    "lat": 40.98935,
    "lng": -88.8881,
    "state_id": "IL"
  },
  {
    "zip": 61334,
    "lat": 41.14512,
    "lng": -89.09846,
    "state_id": "IL"
  },
  {
    "zip": 61335,
    "lat": 41.16353,
    "lng": -89.22467,
    "state_id": "IL"
  },
  {
    "zip": 61336,
    "lat": 41.10705,
    "lng": -89.22006,
    "state_id": "IL"
  },
  {
    "zip": 61337,
    "lat": 41.43639,
    "lng": -89.3284,
    "state_id": "IL"
  },
  {
    "zip": 61338,
    "lat": 41.45903,
    "lng": -89.6762,
    "state_id": "IL"
  },
  {
    "zip": 61340,
    "lat": 41.2612,
    "lng": -89.2501,
    "state_id": "IL"
  },
  {
    "zip": 61341,
    "lat": 41.34073,
    "lng": -88.69249,
    "state_id": "IL"
  },
  {
    "zip": 61342,
    "lat": 41.54058,
    "lng": -89.09057,
    "state_id": "IL"
  },
  {
    "zip": 61344,
    "lat": 41.41153,
    "lng": -89.83154,
    "state_id": "IL"
  },
  {
    "zip": 61345,
    "lat": 41.28007,
    "lng": -89.78982,
    "state_id": "IL"
  },
  {
    "zip": 61346,
    "lat": 41.51115,
    "lng": -89.71819,
    "state_id": "IL"
  },
  {
    "zip": 61348,
    "lat": 41.28164,
    "lng": -89.03788,
    "state_id": "IL"
  },
  {
    "zip": 61349,
    "lat": 41.55047,
    "lng": -89.44504,
    "state_id": "IL"
  },
  {
    "zip": 61350,
    "lat": 41.37257,
    "lng": -88.86339,
    "state_id": "IL"
  },
  {
    "zip": 61353,
    "lat": 41.69654,
    "lng": -88.99507,
    "state_id": "IL"
  },
  {
    "zip": 61354,
    "lat": 41.3279,
    "lng": -89.14278,
    "state_id": "IL"
  },
  {
    "zip": 61356,
    "lat": 41.39357,
    "lng": -89.43816,
    "state_id": "IL"
  },
  {
    "zip": 61358,
    "lat": 40.97874,
    "lng": -89.04399,
    "state_id": "IL"
  },
  {
    "zip": 61359,
    "lat": 41.36211,
    "lng": -89.27125,
    "state_id": "IL"
  },
  {
    "zip": 61360,
    "lat": 41.3272,
    "lng": -88.60479,
    "state_id": "IL"
  },
  {
    "zip": 61361,
    "lat": 41.41146,
    "lng": -89.76379,
    "state_id": "IL"
  },
  {
    "zip": 61362,
    "lat": 41.35468,
    "lng": -89.22907,
    "state_id": "IL"
  },
  {
    "zip": 61363,
    "lat": 41.25423,
    "lng": -89.18246,
    "state_id": "IL"
  },
  {
    "zip": 61364,
    "lat": 41.1284,
    "lng": -88.84214,
    "state_id": "IL"
  },
  {
    "zip": 61367,
    "lat": 41.62679,
    "lng": -89.26541,
    "state_id": "IL"
  },
  {
    "zip": 61368,
    "lat": 41.26305,
    "lng": -89.52289,
    "state_id": "IL"
  },
  {
    "zip": 61369,
    "lat": 40.98409,
    "lng": -89.15506,
    "state_id": "IL"
  },
  {
    "zip": 61370,
    "lat": 41.20504,
    "lng": -89.03161,
    "state_id": "IL"
  },
  {
    "zip": 61372,
    "lat": 41.46491,
    "lng": -89.07802,
    "state_id": "IL"
  },
  {
    "zip": 61373,
    "lat": 41.39897,
    "lng": -88.9984,
    "state_id": "IL"
  },
  {
    "zip": 61374,
    "lat": 41.54773,
    "lng": -89.35871,
    "state_id": "IL"
  },
  {
    "zip": 61375,
    "lat": 41.03528,
    "lng": -89.25044,
    "state_id": "IL"
  },
  {
    "zip": 61376,
    "lat": 41.53939,
    "lng": -89.60912,
    "state_id": "IL"
  },
  {
    "zip": 61377,
    "lat": 41.06002,
    "lng": -89.03648,
    "state_id": "IL"
  },
  {
    "zip": 61378,
    "lat": 41.72443,
    "lng": -89.15703,
    "state_id": "IL"
  },
  {
    "zip": 61379,
    "lat": 41.39198,
    "lng": -89.6161,
    "state_id": "IL"
  },
  {
    "zip": 61401,
    "lat": 40.94467,
    "lng": -90.38468,
    "state_id": "IL"
  },
  {
    "zip": 61410,
    "lat": 40.79797,
    "lng": -90.39559,
    "state_id": "IL"
  },
  {
    "zip": 61411,
    "lat": 40.40016,
    "lng": -90.50509,
    "state_id": "IL"
  },
  {
    "zip": 61412,
    "lat": 41.06972,
    "lng": -90.57878,
    "state_id": "IL"
  },
  {
    "zip": 61413,
    "lat": 41.19053,
    "lng": -90.37352,
    "state_id": "IL"
  },
  {
    "zip": 61414,
    "lat": 41.12471,
    "lng": -90.15578,
    "state_id": "IL"
  },
  {
    "zip": 61415,
    "lat": 40.65708,
    "lng": -90.42918,
    "state_id": "IL"
  },
  {
    "zip": 61416,
    "lat": 40.49921,
    "lng": -90.56299,
    "state_id": "IL"
  },
  {
    "zip": 61417,
    "lat": 40.77418,
    "lng": -90.53832,
    "state_id": "IL"
  },
  {
    "zip": 61418,
    "lat": 40.85029,
    "lng": -90.86116,
    "state_id": "IL"
  },
  {
    "zip": 61419,
    "lat": 41.19979,
    "lng": -90.11754,
    "state_id": "IL"
  },
  {
    "zip": 61420,
    "lat": 40.54737,
    "lng": -90.86658,
    "state_id": "IL"
  },
  {
    "zip": 61421,
    "lat": 41.18016,
    "lng": -89.64999,
    "state_id": "IL"
  },
  {
    "zip": 61422,
    "lat": 40.55087,
    "lng": -90.53478,
    "state_id": "IL"
  },
  {
    "zip": 61423,
    "lat": 40.88629,
    "lng": -90.50119,
    "state_id": "IL"
  },
  {
    "zip": 61424,
    "lat": 41.07678,
    "lng": -89.63496,
    "state_id": "IL"
  },
  {
    "zip": 61425,
    "lat": 40.76197,
    "lng": -91.0342,
    "state_id": "IL"
  },
  {
    "zip": 61426,
    "lat": 41.11998,
    "lng": -89.70696,
    "state_id": "IL"
  },
  {
    "zip": 61427,
    "lat": 40.50196,
    "lng": -90.19418,
    "state_id": "IL"
  },
  {
    "zip": 61428,
    "lat": 40.93478,
    "lng": -90.10786,
    "state_id": "IL"
  },
  {
    "zip": 61430,
    "lat": 40.94023,
    "lng": -90.31092,
    "state_id": "IL"
  },
  {
    "zip": 61431,
    "lat": 40.60769,
    "lng": -90.27815,
    "state_id": "IL"
  },
  {
    "zip": 61432,
    "lat": 40.65259,
    "lng": -90.15863,
    "state_id": "IL"
  },
  {
    "zip": 61433,
    "lat": 40.55748,
    "lng": -90.16865,
    "state_id": "IL"
  },
  {
    "zip": 61434,
    "lat": 41.1755,
    "lng": -90.03896,
    "state_id": "IL"
  },
  {
    "zip": 61435,
    "lat": 40.99239,
    "lng": -90.56785,
    "state_id": "IL"
  },
  {
    "zip": 61436,
    "lat": 40.86223,
    "lng": -90.21683,
    "state_id": "IL"
  },
  {
    "zip": 61437,
    "lat": 40.84615,
    "lng": -90.98681,
    "state_id": "IL"
  },
  {
    "zip": 61438,
    "lat": 40.58282,
    "lng": -90.65159,
    "state_id": "IL"
  },
  {
    "zip": 61439,
    "lat": 41.02848,
    "lng": -90.357,
    "state_id": "IL"
  },
  {
    "zip": 61440,
    "lat": 40.3073,
    "lng": -90.59551,
    "state_id": "IL"
  },
  {
    "zip": 61441,
    "lat": 40.34074,
    "lng": -90.29338,
    "state_id": "IL"
  },
  {
    "zip": 61442,
    "lat": 41.10733,
    "lng": -90.92267,
    "state_id": "IL"
  },
  {
    "zip": 61443,
    "lat": 41.25995,
    "lng": -89.94172,
    "state_id": "IL"
  },
  {
    "zip": 61447,
    "lat": 40.86724,
    "lng": -90.7537,
    "state_id": "IL"
  },
  {
    "zip": 61448,
    "lat": 40.93363,
    "lng": -90.24678,
    "state_id": "IL"
  },
  {
    "zip": 61449,
    "lat": 41.08934,
    "lng": -89.9747,
    "state_id": "IL"
  },
  {
    "zip": 61450,
    "lat": 40.57883,
    "lng": -90.98165,
    "state_id": "IL"
  },
  {
    "zip": 61451,
    "lat": 40.94576,
    "lng": -89.93875,
    "state_id": "IL"
  },
  {
    "zip": 61452,
    "lat": 40.2362,
    "lng": -90.66064,
    "state_id": "IL"
  },
  {
    "zip": 61453,
    "lat": 41.01218,
    "lng": -90.76418,
    "state_id": "IL"
  },
  {
    "zip": 61454,
    "lat": 40.68017,
    "lng": -91.04246,
    "state_id": "IL"
  },
  {
    "zip": 61455,
    "lat": 40.43538,
    "lng": -90.65452,
    "state_id": "IL"
  },
  {
    "zip": 61458,
    "lat": 40.77966,
    "lng": -90.18143,
    "state_id": "IL"
  },
  {
    "zip": 61459,
    "lat": 40.50555,
    "lng": -90.41583,
    "state_id": "IL"
  },
  {
    "zip": 61460,
    "lat": 40.71663,
    "lng": -90.82915,
    "state_id": "IL"
  },
  {
    "zip": 61462,
    "lat": 40.92357,
    "lng": -90.64788,
    "state_id": "IL"
  },
  {
    "zip": 61465,
    "lat": 41.20784,
    "lng": -90.46658,
    "state_id": "IL"
  },
  {
    "zip": 61466,
    "lat": 41.11003,
    "lng": -90.47968,
    "state_id": "IL"
  },
  {
    "zip": 61467,
    "lat": 41.07376,
    "lng": -90.23977,
    "state_id": "IL"
  },
  {
    "zip": 61468,
    "lat": 41.25773,
    "lng": -90.38776,
    "state_id": "IL"
  },
  {
    "zip": 61469,
    "lat": 40.96402,
    "lng": -90.90617,
    "state_id": "IL"
  },
  {
    "zip": 61470,
    "lat": 40.60379,
    "lng": -90.50482,
    "state_id": "IL"
  },
  {
    "zip": 61471,
    "lat": 40.69753,
    "lng": -90.83501,
    "state_id": "IL"
  },
  {
    "zip": 61472,
    "lat": 41.10216,
    "lng": -90.38289,
    "state_id": "IL"
  },
  {
    "zip": 61473,
    "lat": 40.70595,
    "lng": -90.65273,
    "state_id": "IL"
  },
  {
    "zip": 61474,
    "lat": 40.73635,
    "lng": -90.3781,
    "state_id": "IL"
  },
  {
    "zip": 61475,
    "lat": 40.5979,
    "lng": -90.75343,
    "state_id": "IL"
  },
  {
    "zip": 61476,
    "lat": 41.0981,
    "lng": -90.83225,
    "state_id": "IL"
  },
  {
    "zip": 61477,
    "lat": 40.49607,
    "lng": -90.31545,
    "state_id": "IL"
  },
  {
    "zip": 61478,
    "lat": 40.75784,
    "lng": -90.76058,
    "state_id": "IL"
  },
  {
    "zip": 61479,
    "lat": 41.00477,
    "lng": -89.64478,
    "state_id": "IL"
  },
  {
    "zip": 61480,
    "lat": 40.72541,
    "lng": -90.9218,
    "state_id": "IL"
  },
  {
    "zip": 61482,
    "lat": 40.38838,
    "lng": -90.41175,
    "state_id": "IL"
  },
  {
    "zip": 61483,
    "lat": 41.08666,
    "lng": -89.87325,
    "state_id": "IL"
  },
  {
    "zip": 61484,
    "lat": 40.29528,
    "lng": -90.43646,
    "state_id": "IL"
  },
  {
    "zip": 61485,
    "lat": 41.02165,
    "lng": -90.09835,
    "state_id": "IL"
  },
  {
    "zip": 61486,
    "lat": 41.19792,
    "lng": -90.57609,
    "state_id": "IL"
  },
  {
    "zip": 61488,
    "lat": 41.03225,
    "lng": -90.3183,
    "state_id": "IL"
  },
  {
    "zip": 61489,
    "lat": 40.93399,
    "lng": -90.02847,
    "state_id": "IL"
  },
  {
    "zip": 61490,
    "lat": 41.18512,
    "lng": -90.26557,
    "state_id": "IL"
  },
  {
    "zip": 61491,
    "lat": 41.06053,
    "lng": -89.72935,
    "state_id": "IL"
  },
  {
    "zip": 61501,
    "lat": 40.22935,
    "lng": -90.31653,
    "state_id": "IL"
  },
  {
    "zip": 61516,
    "lat": 40.85808,
    "lng": -89.1352,
    "state_id": "IL"
  },
  {
    "zip": 61517,
    "lat": 40.826,
    "lng": -89.85428,
    "state_id": "IL"
  },
  {
    "zip": 61519,
    "lat": 40.46009,
    "lng": -90.08897,
    "state_id": "IL"
  },
  {
    "zip": 61520,
    "lat": 40.54208,
    "lng": -90.02132,
    "state_id": "IL"
  },
  {
    "zip": 61523,
    "lat": 40.91725,
    "lng": -89.53549,
    "state_id": "IL"
  },
  {
    "zip": 61524,
    "lat": 40.49143,
    "lng": -90.03456,
    "state_id": "IL"
  },
  {
    "zip": 61525,
    "lat": 40.84847,
    "lng": -89.669,
    "state_id": "IL"
  },
  {
    "zip": 61526,
    "lat": 40.92993,
    "lng": -89.62434,
    "state_id": "IL"
  },
  {
    "zip": 61528,
    "lat": 40.77896,
    "lng": -89.72293,
    "state_id": "IL"
  },
  {
    "zip": 61529,
    "lat": 40.78147,
    "lng": -89.94202,
    "state_id": "IL"
  },
  {
    "zip": 61530,
    "lat": 40.71406,
    "lng": -89.26042,
    "state_id": "IL"
  },
  {
    "zip": 61531,
    "lat": 40.68308,
    "lng": -90.03503,
    "state_id": "IL"
  },
  {
    "zip": 61532,
    "lat": 40.33949,
    "lng": -89.81879,
    "state_id": "IL"
  },
  {
    "zip": 61533,
    "lat": 40.58651,
    "lng": -89.83933,
    "state_id": "IL"
  },
  {
    "zip": 61534,
    "lat": 40.41448,
    "lng": -89.6661,
    "state_id": "IL"
  },
  {
    "zip": 61535,
    "lat": 40.58307,
    "lng": -89.52333,
    "state_id": "IL"
  },
  {
    "zip": 61536,
    "lat": 40.6899,
    "lng": -89.78701,
    "state_id": "IL"
  },
  {
    "zip": 61537,
    "lat": 41.11702,
    "lng": -89.46635,
    "state_id": "IL"
  },
  {
    "zip": 61539,
    "lat": 40.55515,
    "lng": -89.76712,
    "state_id": "IL"
  },
  {
    "zip": 61540,
    "lat": 41.01472,
    "lng": -89.37309,
    "state_id": "IL"
  },
  {
    "zip": 61541,
    "lat": 40.98038,
    "lng": -89.23495,
    "state_id": "IL"
  },
  {
    "zip": 61542,
    "lat": 40.38632,
    "lng": -90.13464,
    "state_id": "IL"
  },
  {
    "zip": 61543,
    "lat": 40.42079,
    "lng": -89.97069,
    "state_id": "IL"
  },
  {
    "zip": 61544,
    "lat": 40.69175,
    "lng": -90.24007,
    "state_id": "IL"
  },
  {
    "zip": 61545,
    "lat": 40.87071,
    "lng": -89.35355,
    "state_id": "IL"
  },
  {
    "zip": 61546,
    "lat": 40.45394,
    "lng": -89.79886,
    "state_id": "IL"
  },
  {
    "zip": 61547,
    "lat": 40.60634,
    "lng": -89.73755,
    "state_id": "IL"
  },
  {
    "zip": 61548,
    "lat": 40.80915,
    "lng": -89.41403,
    "state_id": "IL"
  },
  {
    "zip": 61550,
    "lat": 40.61088,
    "lng": -89.44206,
    "state_id": "IL"
  },
  {
    "zip": 61552,
    "lat": 40.81758,
    "lng": -89.5657,
    "state_id": "IL"
  },
  {
    "zip": 61553,
    "lat": 40.62594,
    "lng": -90.03228,
    "state_id": "IL"
  },
  {
    "zip": 61554,
    "lat": 40.54088,
    "lng": -89.61861,
    "state_id": "IL"
  },
  {
    "zip": 61559,
    "lat": 40.92108,
    "lng": -89.77557,
    "state_id": "IL"
  },
  {
    "zip": 61560,
    "lat": 41.1877,
    "lng": -89.40277,
    "state_id": "IL"
  },
  {
    "zip": 61561,
    "lat": 40.80295,
    "lng": -89.20088,
    "state_id": "IL"
  },
  {
    "zip": 61562,
    "lat": 40.88043,
    "lng": -89.50143,
    "state_id": "IL"
  },
  {
    "zip": 61563,
    "lat": 40.49203,
    "lng": -90.0541,
    "state_id": "IL"
  },
  {
    "zip": 61564,
    "lat": 40.49336,
    "lng": -89.65352,
    "state_id": "IL"
  },
  {
    "zip": 61565,
    "lat": 41.03231,
    "lng": -89.50995,
    "state_id": "IL"
  },
  {
    "zip": 61567,
    "lat": 40.35804,
    "lng": -89.89659,
    "state_id": "IL"
  },
  {
    "zip": 61568,
    "lat": 40.50538,
    "lng": -89.47868,
    "state_id": "IL"
  },
  {
    "zip": 61569,
    "lat": 40.68421,
    "lng": -89.89323,
    "state_id": "IL"
  },
  {
    "zip": 61570,
    "lat": 40.92039,
    "lng": -89.30774,
    "state_id": "IL"
  },
  {
    "zip": 61571,
    "lat": 40.70331,
    "lng": -89.41951,
    "state_id": "IL"
  },
  {
    "zip": 61572,
    "lat": 40.80029,
    "lng": -90.03681,
    "state_id": "IL"
  },
  {
    "zip": 61602,
    "lat": 40.67491,
    "lng": -89.60738,
    "state_id": "IL"
  },
  {
    "zip": 61603,
    "lat": 40.71332,
    "lng": -89.57724,
    "state_id": "IL"
  },
  {
    "zip": 61604,
    "lat": 40.70572,
    "lng": -89.65362,
    "state_id": "IL"
  },
  {
    "zip": 61605,
    "lat": 40.6766,
    "lng": -89.63346,
    "state_id": "IL"
  },
  {
    "zip": 61606,
    "lat": 40.69979,
    "lng": -89.61141,
    "state_id": "IL"
  },
  {
    "zip": 61607,
    "lat": 40.63007,
    "lng": -89.68512,
    "state_id": "IL"
  },
  {
    "zip": 61610,
    "lat": 40.6429,
    "lng": -89.59867,
    "state_id": "IL"
  },
  {
    "zip": 61611,
    "lat": 40.71049,
    "lng": -89.53566,
    "state_id": "IL"
  },
  {
    "zip": 61614,
    "lat": 40.75899,
    "lng": -89.60494,
    "state_id": "IL"
  },
  {
    "zip": 61615,
    "lat": 40.77427,
    "lng": -89.64588,
    "state_id": "IL"
  },
  {
    "zip": 61616,
    "lat": 40.74672,
    "lng": -89.57187,
    "state_id": "IL"
  },
  {
    "zip": 61625,
    "lat": 40.69753,
    "lng": -89.61274,
    "state_id": "IL"
  },
  {
    "zip": 61701,
    "lat": 40.47676,
    "lng": -88.99308,
    "state_id": "IL"
  },
  {
    "zip": 61704,
    "lat": 40.47053,
    "lng": -88.94332,
    "state_id": "IL"
  },
  {
    "zip": 61705,
    "lat": 40.45996,
    "lng": -89.00228,
    "state_id": "IL"
  },
  {
    "zip": 61720,
    "lat": 40.55471,
    "lng": -88.51447,
    "state_id": "IL"
  },
  {
    "zip": 61721,
    "lat": 40.3537,
    "lng": -89.32324,
    "state_id": "IL"
  },
  {
    "zip": 61722,
    "lat": 40.41906,
    "lng": -88.63213,
    "state_id": "IL"
  },
  {
    "zip": 61723,
    "lat": 40.26082,
    "lng": -89.26682,
    "state_id": "IL"
  },
  {
    "zip": 61724,
    "lat": 40.33086,
    "lng": -88.52705,
    "state_id": "IL"
  },
  {
    "zip": 61725,
    "lat": 40.60979,
    "lng": -89.11985,
    "state_id": "IL"
  },
  {
    "zip": 61726,
    "lat": 40.73661,
    "lng": -88.69717,
    "state_id": "IL"
  },
  {
    "zip": 61727,
    "lat": 40.14248,
    "lng": -88.96083,
    "state_id": "IL"
  },
  {
    "zip": 61728,
    "lat": 40.57285,
    "lng": -88.62632,
    "state_id": "IL"
  },
  {
    "zip": 61729,
    "lat": 40.63068,
    "lng": -89.22359,
    "state_id": "IL"
  },
  {
    "zip": 61730,
    "lat": 40.53249,
    "lng": -88.72938,
    "state_id": "IL"
  },
  {
    "zip": 61731,
    "lat": 40.61393,
    "lng": -88.4855,
    "state_id": "IL"
  },
  {
    "zip": 61732,
    "lat": 40.53116,
    "lng": -89.20317,
    "state_id": "IL"
  },
  {
    "zip": 61733,
    "lat": 40.6224,
    "lng": -89.33221,
    "state_id": "IL"
  },
  {
    "zip": 61734,
    "lat": 40.37344,
    "lng": -89.5265,
    "state_id": "IL"
  },
  {
    "zip": 61735,
    "lat": 40.20191,
    "lng": -88.81805,
    "state_id": "IL"
  },
  {
    "zip": 61736,
    "lat": 40.4021,
    "lng": -88.83864,
    "state_id": "IL"
  },
  {
    "zip": 61737,
    "lat": 40.45846,
    "lng": -88.73955,
    "state_id": "IL"
  },
  {
    "zip": 61738,
    "lat": 40.74604,
    "lng": -89.03428,
    "state_id": "IL"
  },
  {
    "zip": 61739,
    "lat": 40.73313,
    "lng": -88.52043,
    "state_id": "IL"
  },
  {
    "zip": 61740,
    "lat": 40.88428,
    "lng": -88.86162,
    "state_id": "IL"
  },
  {
    "zip": 61741,
    "lat": 40.75936,
    "lng": -88.40424,
    "state_id": "IL"
  },
  {
    "zip": 61742,
    "lat": 40.63295,
    "lng": -89.27267,
    "state_id": "IL"
  },
  {
    "zip": 61743,
    "lat": 40.87567,
    "lng": -88.78168,
    "state_id": "IL"
  },
  {
    "zip": 61744,
    "lat": 40.73997,
    "lng": -88.8871,
    "state_id": "IL"
  },
  {
    "zip": 61745,
    "lat": 40.32221,
    "lng": -88.97247,
    "state_id": "IL"
  },
  {
    "zip": 61747,
    "lat": 40.42174,
    "lng": -89.43072,
    "state_id": "IL"
  },
  {
    "zip": 61748,
    "lat": 40.61675,
    "lng": -88.99116,
    "state_id": "IL"
  },
  {
    "zip": 61749,
    "lat": 40.09494,
    "lng": -89.11027,
    "state_id": "IL"
  },
  {
    "zip": 61750,
    "lat": 40.12356,
    "lng": -88.85957,
    "state_id": "IL"
  },
  {
    "zip": 61751,
    "lat": 40.21455,
    "lng": -89.29926,
    "state_id": "IL"
  },
  {
    "zip": 61752,
    "lat": 40.33908,
    "lng": -88.7548,
    "state_id": "IL"
  },
  {
    "zip": 61753,
    "lat": 40.63645,
    "lng": -88.78468,
    "state_id": "IL"
  },
  {
    "zip": 61754,
    "lat": 40.33031,
    "lng": -89.16916,
    "state_id": "IL"
  },
  {
    "zip": 61755,
    "lat": 40.52794,
    "lng": -89.33596,
    "state_id": "IL"
  },
  {
    "zip": 61756,
    "lat": 40.03121,
    "lng": -88.97344,
    "state_id": "IL"
  },
  {
    "zip": 61759,
    "lat": 40.43718,
    "lng": -89.32371,
    "state_id": "IL"
  },
  {
    "zip": 61760,
    "lat": 40.88106,
    "lng": -89.02875,
    "state_id": "IL"
  },
  {
    "zip": 61761,
    "lat": 40.52958,
    "lng": -88.95856,
    "state_id": "IL"
  },
  {
    "zip": 61764,
    "lat": 40.88227,
    "lng": -88.62817,
    "state_id": "IL"
  },
  {
    "zip": 61769,
    "lat": 40.88202,
    "lng": -88.39935,
    "state_id": "IL"
  },
  {
    "zip": 61770,
    "lat": 40.44323,
    "lng": -88.52594,
    "state_id": "IL"
  },
  {
    "zip": 61771,
    "lat": 40.70928,
    "lng": -89.14117,
    "state_id": "IL"
  },
  {
    "zip": 61772,
    "lat": 40.38125,
    "lng": -89.066,
    "state_id": "IL"
  },
  {
    "zip": 61773,
    "lat": 40.57168,
    "lng": -88.38326,
    "state_id": "IL"
  },
  {
    "zip": 61774,
    "lat": 40.43069,
    "lng": -89.20402,
    "state_id": "IL"
  },
  {
    "zip": 61775,
    "lat": 40.65076,
    "lng": -88.3671,
    "state_id": "IL"
  },
  {
    "zip": 61776,
    "lat": 40.56942,
    "lng": -88.86698,
    "state_id": "IL"
  },
  {
    "zip": 61777,
    "lat": 40.24249,
    "lng": -88.94182,
    "state_id": "IL"
  },
  {
    "zip": 61778,
    "lat": 40.23288,
    "lng": -89.09115,
    "state_id": "IL"
  },
  {
    "zip": 61801,
    "lat": 40.10902,
    "lng": -88.2113,
    "state_id": "IL"
  },
  {
    "zip": 61802,
    "lat": 40.12556,
    "lng": -88.15101,
    "state_id": "IL"
  },
  {
    "zip": 61810,
    "lat": 39.91615,
    "lng": -87.91471,
    "state_id": "IL"
  },
  {
    "zip": 61811,
    "lat": 40.29272,
    "lng": -87.62283,
    "state_id": "IL"
  },
  {
    "zip": 61812,
    "lat": 40.25008,
    "lng": -87.88668,
    "state_id": "IL"
  },
  {
    "zip": 61813,
    "lat": 39.91155,
    "lng": -88.55961,
    "state_id": "IL"
  },
  {
    "zip": 61814,
    "lat": 40.2306,
    "lng": -87.57247,
    "state_id": "IL"
  },
  {
    "zip": 61815,
    "lat": 40.11073,
    "lng": -88.37656,
    "state_id": "IL"
  },
  {
    "zip": 61816,
    "lat": 39.92283,
    "lng": -88.00121,
    "state_id": "IL"
  },
  {
    "zip": 61817,
    "lat": 40.03786,
    "lng": -87.71674,
    "state_id": "IL"
  },
  {
    "zip": 61818,
    "lat": 39.88033,
    "lng": -88.71921,
    "state_id": "IL"
  },
  {
    "zip": 61820,
    "lat": 40.10887,
    "lng": -88.24348,
    "state_id": "IL"
  },
  {
    "zip": 61821,
    "lat": 40.10962,
    "lng": -88.27503,
    "state_id": "IL"
  },
  {
    "zip": 61822,
    "lat": 40.13578,
    "lng": -88.30529,
    "state_id": "IL"
  },
  {
    "zip": 61830,
    "lat": 40.02752,
    "lng": -88.7225,
    "state_id": "IL"
  },
  {
    "zip": 61831,
    "lat": 40.2251,
    "lng": -87.79208,
    "state_id": "IL"
  },
  {
    "zip": 61832,
    "lat": 40.13616,
    "lng": -87.63646,
    "state_id": "IL"
  },
  {
    "zip": 61833,
    "lat": 40.09806,
    "lng": -87.64427,
    "state_id": "IL"
  },
  {
    "zip": 61834,
    "lat": 40.1643,
    "lng": -87.63785,
    "state_id": "IL"
  },
  {
    "zip": 61839,
    "lat": 40.1377,
    "lng": -88.62912,
    "state_id": "IL"
  },
  {
    "zip": 61840,
    "lat": 40.3037,
    "lng": -88.29894,
    "state_id": "IL"
  },
  {
    "zip": 61841,
    "lat": 40.02635,
    "lng": -87.82427,
    "state_id": "IL"
  },
  {
    "zip": 61842,
    "lat": 40.23922,
    "lng": -88.66581,
    "state_id": "IL"
  },
  {
    "zip": 61843,
    "lat": 40.31533,
    "lng": -88.38396,
    "state_id": "IL"
  },
  {
    "zip": 61844,
    "lat": 40.13875,
    "lng": -87.87033,
    "state_id": "IL"
  },
  {
    "zip": 61845,
    "lat": 40.37417,
    "lng": -88.39923,
    "state_id": "IL"
  },
  {
    "zip": 61846,
    "lat": 39.97627,
    "lng": -87.61237,
    "state_id": "IL"
  },
  {
    "zip": 61847,
    "lat": 40.31312,
    "lng": -88.01542,
    "state_id": "IL"
  },
  {
    "zip": 61848,
    "lat": 40.30806,
    "lng": -87.70278,
    "state_id": "IL"
  },
  {
    "zip": 61849,
    "lat": 40.01116,
    "lng": -87.96192,
    "state_id": "IL"
  },
  {
    "zip": 61850,
    "lat": 39.9325,
    "lng": -87.73765,
    "state_id": "IL"
  },
  {
    "zip": 61851,
    "lat": 39.96081,
    "lng": -88.43474,
    "state_id": "IL"
  },
  {
    "zip": 61852,
    "lat": 39.90315,
    "lng": -88.07581,
    "state_id": "IL"
  },
  {
    "zip": 61853,
    "lat": 40.22146,
    "lng": -88.4168,
    "state_id": "IL"
  },
  {
    "zip": 61854,
    "lat": 40.20401,
    "lng": -88.52404,
    "state_id": "IL"
  },
  {
    "zip": 61855,
    "lat": 39.92213,
    "lng": -88.66087,
    "state_id": "IL"
  },
  {
    "zip": 61856,
    "lat": 40.02685,
    "lng": -88.57949,
    "state_id": "IL"
  },
  {
    "zip": 61857,
    "lat": 40.11662,
    "lng": -87.84223,
    "state_id": "IL"
  },
  {
    "zip": 61858,
    "lat": 40.13282,
    "lng": -87.76799,
    "state_id": "IL"
  },
  {
    "zip": 61859,
    "lat": 40.15412,
    "lng": -87.96122,
    "state_id": "IL"
  },
  {
    "zip": 61862,
    "lat": 40.30633,
    "lng": -87.95185,
    "state_id": "IL"
  },
  {
    "zip": 61863,
    "lat": 39.9058,
    "lng": -88.2797,
    "state_id": "IL"
  },
  {
    "zip": 61864,
    "lat": 39.97954,
    "lng": -88.14671,
    "state_id": "IL"
  },
  {
    "zip": 61865,
    "lat": 40.30451,
    "lng": -87.80255,
    "state_id": "IL"
  },
  {
    "zip": 61866,
    "lat": 40.31207,
    "lng": -88.15398,
    "state_id": "IL"
  },
  {
    "zip": 61870,
    "lat": 39.89756,
    "lng": -87.61838,
    "state_id": "IL"
  },
  {
    "zip": 61871,
    "lat": 40.19012,
    "lng": -87.96791,
    "state_id": "IL"
  },
  {
    "zip": 61872,
    "lat": 39.9387,
    "lng": -88.37719,
    "state_id": "IL"
  },
  {
    "zip": 61873,
    "lat": 40.1378,
    "lng": -88.03929,
    "state_id": "IL"
  },
  {
    "zip": 61874,
    "lat": 40.05111,
    "lng": -88.25694,
    "state_id": "IL"
  },
  {
    "zip": 61875,
    "lat": 40.1005,
    "lng": -88.41951,
    "state_id": "IL"
  },
  {
    "zip": 61876,
    "lat": 39.90508,
    "lng": -87.83412,
    "state_id": "IL"
  },
  {
    "zip": 61877,
    "lat": 39.99416,
    "lng": -88.07867,
    "state_id": "IL"
  },
  {
    "zip": 61878,
    "lat": 40.23971,
    "lng": -88.14945,
    "state_id": "IL"
  },
  {
    "zip": 61880,
    "lat": 39.97358,
    "lng": -88.24581,
    "state_id": "IL"
  },
  {
    "zip": 61882,
    "lat": 40.11346,
    "lng": -88.75994,
    "state_id": "IL"
  },
  {
    "zip": 61883,
    "lat": 40.03743,
    "lng": -87.64153,
    "state_id": "IL"
  },
  {
    "zip": 61884,
    "lat": 40.09074,
    "lng": -88.48059,
    "state_id": "IL"
  },
  {
    "zip": 61910,
    "lat": 39.67439,
    "lng": -88.29904,
    "state_id": "IL"
  },
  {
    "zip": 61911,
    "lat": 39.70325,
    "lng": -88.4698,
    "state_id": "IL"
  },
  {
    "zip": 61912,
    "lat": 39.52729,
    "lng": -88.03511,
    "state_id": "IL"
  },
  {
    "zip": 61913,
    "lat": 39.82756,
    "lng": -88.46609,
    "state_id": "IL"
  },
  {
    "zip": 61914,
    "lat": 39.63542,
    "lng": -88.76476,
    "state_id": "IL"
  },
  {
    "zip": 61917,
    "lat": 39.7142,
    "lng": -87.89767,
    "state_id": "IL"
  },
  {
    "zip": 61919,
    "lat": 39.77867,
    "lng": -88.13947,
    "state_id": "IL"
  },
  {
    "zip": 61920,
    "lat": 39.50367,
    "lng": -88.1641,
    "state_id": "IL"
  },
  {
    "zip": 61924,
    "lat": 39.79141,
    "lng": -87.65455,
    "state_id": "IL"
  },
  {
    "zip": 61925,
    "lat": 39.7235,
    "lng": -88.83629,
    "state_id": "IL"
  },
  {
    "zip": 61928,
    "lat": 39.451,
    "lng": -88.50411,
    "state_id": "IL"
  },
  {
    "zip": 61929,
    "lat": 39.82479,
    "lng": -88.62132,
    "state_id": "IL"
  },
  {
    "zip": 61930,
    "lat": 39.68088,
    "lng": -88.13049,
    "state_id": "IL"
  },
  {
    "zip": 61931,
    "lat": 39.58979,
    "lng": -88.35311,
    "state_id": "IL"
  },
  {
    "zip": 61932,
    "lat": 39.80355,
    "lng": -87.8789,
    "state_id": "IL"
  },
  {
    "zip": 61933,
    "lat": 39.54832,
    "lng": -87.91706,
    "state_id": "IL"
  },
  {
    "zip": 61936,
    "lat": 39.80135,
    "lng": -88.73026,
    "state_id": "IL"
  },
  {
    "zip": 61937,
    "lat": 39.74179,
    "lng": -88.65055,
    "state_id": "IL"
  },
  {
    "zip": 61938,
    "lat": 39.48386,
    "lng": -88.37426,
    "state_id": "IL"
  },
  {
    "zip": 61940,
    "lat": 39.80773,
    "lng": -87.81871,
    "state_id": "IL"
  },
  {
    "zip": 61941,
    "lat": 39.80403,
    "lng": -88.07291,
    "state_id": "IL"
  },
  {
    "zip": 61942,
    "lat": 39.81517,
    "lng": -88.00608,
    "state_id": "IL"
  },
  {
    "zip": 61943,
    "lat": 39.66494,
    "lng": -88.02965,
    "state_id": "IL"
  },
  {
    "zip": 61944,
    "lat": 39.601,
    "lng": -87.70126,
    "state_id": "IL"
  },
  {
    "zip": 61949,
    "lat": 39.63357,
    "lng": -87.87362,
    "state_id": "IL"
  },
  {
    "zip": 61951,
    "lat": 39.59393,
    "lng": -88.58679,
    "state_id": "IL"
  },
  {
    "zip": 61953,
    "lat": 39.79958,
    "lng": -88.29125,
    "state_id": "IL"
  },
  {
    "zip": 61955,
    "lat": 39.58195,
    "lng": -87.59103,
    "state_id": "IL"
  },
  {
    "zip": 61956,
    "lat": 39.85123,
    "lng": -88.14416,
    "state_id": "IL"
  },
  {
    "zip": 61957,
    "lat": 39.43961,
    "lng": -88.61112,
    "state_id": "IL"
  },
  {
    "zip": 62001,
    "lat": 38.87851,
    "lng": -89.74853,
    "state_id": "IL"
  },
  {
    "zip": 62002,
    "lat": 38.93973,
    "lng": -90.12764,
    "state_id": "IL"
  },
  {
    "zip": 62006,
    "lat": 39.05582,
    "lng": -90.66657,
    "state_id": "IL"
  },
  {
    "zip": 62009,
    "lat": 39.09108,
    "lng": -89.7984,
    "state_id": "IL"
  },
  {
    "zip": 62010,
    "lat": 38.92009,
    "lng": -90.04734,
    "state_id": "IL"
  },
  {
    "zip": 62011,
    "lat": 39.12647,
    "lng": -89.21593,
    "state_id": "IL"
  },
  {
    "zip": 62012,
    "lat": 39.04282,
    "lng": -90.15015,
    "state_id": "IL"
  },
  {
    "zip": 62013,
    "lat": 38.96245,
    "lng": -90.5695,
    "state_id": "IL"
  },
  {
    "zip": 62014,
    "lat": 39.04304,
    "lng": -89.95097,
    "state_id": "IL"
  },
  {
    "zip": 62015,
    "lat": 39.22173,
    "lng": -89.54337,
    "state_id": "IL"
  },
  {
    "zip": 62016,
    "lat": 39.29412,
    "lng": -90.41698,
    "state_id": "IL"
  },
  {
    "zip": 62017,
    "lat": 39.0689,
    "lng": -89.36895,
    "state_id": "IL"
  },
  {
    "zip": 62018,
    "lat": 38.9071,
    "lng": -90.08357,
    "state_id": "IL"
  },
  {
    "zip": 62019,
    "lat": 39.01943,
    "lng": -89.44927,
    "state_id": "IL"
  },
  {
    "zip": 62021,
    "lat": 38.9774,
    "lng": -89.97931,
    "state_id": "IL"
  },
  {
    "zip": 62022,
    "lat": 39.00376,
    "lng": -90.33164,
    "state_id": "IL"
  },
  {
    "zip": 62023,
    "lat": 39.11074,
    "lng": -89.78815,
    "state_id": "IL"
  },
  {
    "zip": 62024,
    "lat": 38.87133,
    "lng": -90.09568,
    "state_id": "IL"
  },
  {
    "zip": 62025,
    "lat": 38.83056,
    "lng": -89.93326,
    "state_id": "IL"
  },
  {
    "zip": 62027,
    "lat": 39.25606,
    "lng": -90.55967,
    "state_id": "IL"
  },
  {
    "zip": 62028,
    "lat": 38.95935,
    "lng": -90.35407,
    "state_id": "IL"
  },
  {
    "zip": 62030,
    "lat": 39.15463,
    "lng": -90.16445,
    "state_id": "IL"
  },
  {
    "zip": 62031,
    "lat": 39.11333,
    "lng": -90.5357,
    "state_id": "IL"
  },
  {
    "zip": 62032,
    "lat": 39.11567,
    "lng": -89.28845,
    "state_id": "IL"
  },
  {
    "zip": 62033,
    "lat": 39.13404,
    "lng": -89.84424,
    "state_id": "IL"
  },
  {
    "zip": 62034,
    "lat": 38.75651,
    "lng": -89.96987,
    "state_id": "IL"
  },
  {
    "zip": 62035,
    "lat": 38.96423,
    "lng": -90.24075,
    "state_id": "IL"
  },
  {
    "zip": 62036,
    "lat": 38.91666,
    "lng": -90.58799,
    "state_id": "IL"
  },
  {
    "zip": 62037,
    "lat": 39.00931,
    "lng": -90.46759,
    "state_id": "IL"
  },
  {
    "zip": 62040,
    "lat": 38.72613,
    "lng": -90.11067,
    "state_id": "IL"
  },
  {
    "zip": 62044,
    "lat": 39.36359,
    "lng": -90.2127,
    "state_id": "IL"
  },
  {
    "zip": 62045,
    "lat": 39.26026,
    "lng": -90.70904,
    "state_id": "IL"
  },
  {
    "zip": 62046,
    "lat": 38.89009,
    "lng": -89.84572,
    "state_id": "IL"
  },
  {
    "zip": 62047,
    "lat": 39.12107,
    "lng": -90.62535,
    "state_id": "IL"
  },
  {
    "zip": 62048,
    "lat": 38.80406,
    "lng": -90.0966,
    "state_id": "IL"
  },
  {
    "zip": 62049,
    "lat": 39.13013,
    "lng": -89.48518,
    "state_id": "IL"
  },
  {
    "zip": 62050,
    "lat": 39.44468,
    "lng": -90.55129,
    "state_id": "IL"
  },
  {
    "zip": 62051,
    "lat": 39.20174,
    "lng": -89.40192,
    "state_id": "IL"
  },
  {
    "zip": 62052,
    "lat": 39.11101,
    "lng": -90.32182,
    "state_id": "IL"
  },
  {
    "zip": 62053,
    "lat": 39.32513,
    "lng": -90.66106,
    "state_id": "IL"
  },
  {
    "zip": 62054,
    "lat": 39.20119,
    "lng": -90.34528,
    "state_id": "IL"
  },
  {
    "zip": 62056,
    "lat": 39.17871,
    "lng": -89.67103,
    "state_id": "IL"
  },
  {
    "zip": 62058,
    "lat": 38.96895,
    "lng": -89.76681,
    "state_id": "IL"
  },
  {
    "zip": 62059,
    "lat": 38.65639,
    "lng": -90.16501,
    "state_id": "IL"
  },
  {
    "zip": 62060,
    "lat": 38.6769,
    "lng": -90.14693,
    "state_id": "IL"
  },
  {
    "zip": 62061,
    "lat": 38.78975,
    "lng": -89.77952,
    "state_id": "IL"
  },
  {
    "zip": 62062,
    "lat": 38.7274,
    "lng": -89.96199,
    "state_id": "IL"
  },
  {
    "zip": 62063,
    "lat": 39.1978,
    "lng": -90.14761,
    "state_id": "IL"
  },
  {
    "zip": 62065,
    "lat": 39.23213,
    "lng": -90.62893,
    "state_id": "IL"
  },
  {
    "zip": 62067,
    "lat": 38.92985,
    "lng": -89.98516,
    "state_id": "IL"
  },
  {
    "zip": 62069,
    "lat": 39.08832,
    "lng": -89.7394,
    "state_id": "IL"
  },
  {
    "zip": 62070,
    "lat": 39.2965,
    "lng": -90.73319,
    "state_id": "IL"
  },
  {
    "zip": 62074,
    "lat": 38.95803,
    "lng": -89.68365,
    "state_id": "IL"
  },
  {
    "zip": 62075,
    "lat": 39.30115,
    "lng": -89.29534,
    "state_id": "IL"
  },
  {
    "zip": 62076,
    "lat": 39.34337,
    "lng": -89.2188,
    "state_id": "IL"
  },
  {
    "zip": 62077,
    "lat": 39.02899,
    "lng": -89.52173,
    "state_id": "IL"
  },
  {
    "zip": 62078,
    "lat": 39.47862,
    "lng": -90.48608,
    "state_id": "IL"
  },
  {
    "zip": 62079,
    "lat": 39.11219,
    "lng": -90.14204,
    "state_id": "IL"
  },
  {
    "zip": 62080,
    "lat": 39.13697,
    "lng": -89.10133,
    "state_id": "IL"
  },
  {
    "zip": 62081,
    "lat": 39.26108,
    "lng": -90.23701,
    "state_id": "IL"
  },
  {
    "zip": 62082,
    "lat": 39.49462,
    "lng": -90.32409,
    "state_id": "IL"
  },
  {
    "zip": 62083,
    "lat": 39.35447,
    "lng": -89.20144,
    "state_id": "IL"
  },
  {
    "zip": 62084,
    "lat": 38.83665,
    "lng": -90.06245,
    "state_id": "IL"
  },
  {
    "zip": 62085,
    "lat": 39.07823,
    "lng": -89.80244,
    "state_id": "IL"
  },
  {
    "zip": 62086,
    "lat": 38.9825,
    "lng": -89.58099,
    "state_id": "IL"
  },
  {
    "zip": 62087,
    "lat": 38.81683,
    "lng": -90.06613,
    "state_id": "IL"
  },
  {
    "zip": 62088,
    "lat": 39.012,
    "lng": -89.7987,
    "state_id": "IL"
  },
  {
    "zip": 62089,
    "lat": 39.1298,
    "lng": -89.4949,
    "state_id": "IL"
  },
  {
    "zip": 62090,
    "lat": 38.66983,
    "lng": -90.16932,
    "state_id": "IL"
  },
  {
    "zip": 62091,
    "lat": 39.05689,
    "lng": -89.60916,
    "state_id": "IL"
  },
  {
    "zip": 62092,
    "lat": 39.41619,
    "lng": -90.41612,
    "state_id": "IL"
  },
  {
    "zip": 62093,
    "lat": 39.06691,
    "lng": -89.85468,
    "state_id": "IL"
  },
  {
    "zip": 62094,
    "lat": 39.24473,
    "lng": -89.35264,
    "state_id": "IL"
  },
  {
    "zip": 62095,
    "lat": 38.86228,
    "lng": -90.0692,
    "state_id": "IL"
  },
  {
    "zip": 62097,
    "lat": 38.93871,
    "lng": -89.85435,
    "state_id": "IL"
  },
  {
    "zip": 62098,
    "lat": 39.38606,
    "lng": -90.30467,
    "state_id": "IL"
  },
  {
    "zip": 62201,
    "lat": 38.64271,
    "lng": -90.13864,
    "state_id": "IL"
  },
  {
    "zip": 62203,
    "lat": 38.60024,
    "lng": -90.07746,
    "state_id": "IL"
  },
  {
    "zip": 62204,
    "lat": 38.6327,
    "lng": -90.09056,
    "state_id": "IL"
  },
  {
    "zip": 62205,
    "lat": 38.60935,
    "lng": -90.12227,
    "state_id": "IL"
  },
  {
    "zip": 62206,
    "lat": 38.56794,
    "lng": -90.16665,
    "state_id": "IL"
  },
  {
    "zip": 62207,
    "lat": 38.58286,
    "lng": -90.12288,
    "state_id": "IL"
  },
  {
    "zip": 62208,
    "lat": 38.59623,
    "lng": -90.00456,
    "state_id": "IL"
  },
  {
    "zip": 62214,
    "lat": 38.37808,
    "lng": -89.61173,
    "state_id": "IL"
  },
  {
    "zip": 62215,
    "lat": 38.5094,
    "lng": -89.60732,
    "state_id": "IL"
  },
  {
    "zip": 62216,
    "lat": 38.62106,
    "lng": -89.60243,
    "state_id": "IL"
  },
  {
    "zip": 62217,
    "lat": 38.17347,
    "lng": -89.84446,
    "state_id": "IL"
  },
  {
    "zip": 62218,
    "lat": 38.51975,
    "lng": -89.47294,
    "state_id": "IL"
  },
  {
    "zip": 62219,
    "lat": 38.60586,
    "lng": -89.4318,
    "state_id": "IL"
  },
  {
    "zip": 62220,
    "lat": 38.47337,
    "lng": -89.98652,
    "state_id": "IL"
  },
  {
    "zip": 62221,
    "lat": 38.51485,
    "lng": -89.89921,
    "state_id": "IL"
  },
  {
    "zip": 62223,
    "lat": 38.53613,
    "lng": -90.05981,
    "state_id": "IL"
  },
  {
    "zip": 62225,
    "lat": 38.54559,
    "lng": -89.8554,
    "state_id": "IL"
  },
  {
    "zip": 62226,
    "lat": 38.5352,
    "lng": -90.00057,
    "state_id": "IL"
  },
  {
    "zip": 62230,
    "lat": 38.64037,
    "lng": -89.52681,
    "state_id": "IL"
  },
  {
    "zip": 62231,
    "lat": 38.64163,
    "lng": -89.33028,
    "state_id": "IL"
  },
  {
    "zip": 62232,
    "lat": 38.63221,
    "lng": -90.0027,
    "state_id": "IL"
  },
  {
    "zip": 62233,
    "lat": 37.9461,
    "lng": -89.7874,
    "state_id": "IL"
  },
  {
    "zip": 62234,
    "lat": 38.6834,
    "lng": -89.98178,
    "state_id": "IL"
  },
  {
    "zip": 62236,
    "lat": 38.43645,
    "lng": -90.21654,
    "state_id": "IL"
  },
  {
    "zip": 62237,
    "lat": 38.18905,
    "lng": -89.56909,
    "state_id": "IL"
  },
  {
    "zip": 62238,
    "lat": 38.0391,
    "lng": -89.53263,
    "state_id": "IL"
  },
  {
    "zip": 62239,
    "lat": 38.52569,
    "lng": -90.18737,
    "state_id": "IL"
  },
  {
    "zip": 62240,
    "lat": 38.52118,
    "lng": -90.20743,
    "state_id": "IL"
  },
  {
    "zip": 62241,
    "lat": 38.01352,
    "lng": -89.89064,
    "state_id": "IL"
  },
  {
    "zip": 62242,
    "lat": 38.09633,
    "lng": -89.94538,
    "state_id": "IL"
  },
  {
    "zip": 62243,
    "lat": 38.41946,
    "lng": -89.89049,
    "state_id": "IL"
  },
  {
    "zip": 62244,
    "lat": 38.18639,
    "lng": -90.19815,
    "state_id": "IL"
  },
  {
    "zip": 62245,
    "lat": 38.54881,
    "lng": -89.56793,
    "state_id": "IL"
  },
  {
    "zip": 62246,
    "lat": 38.8888,
    "lng": -89.42629,
    "state_id": "IL"
  },
  {
    "zip": 62248,
    "lat": 38.30442,
    "lng": -89.99346,
    "state_id": "IL"
  },
  {
    "zip": 62249,
    "lat": 38.75527,
    "lng": -89.66451,
    "state_id": "IL"
  },
  {
    "zip": 62250,
    "lat": 38.54083,
    "lng": -89.266,
    "state_id": "IL"
  },
  {
    "zip": 62253,
    "lat": 38.77688,
    "lng": -89.29755,
    "state_id": "IL"
  },
  {
    "zip": 62254,
    "lat": 38.60743,
    "lng": -89.82137,
    "state_id": "IL"
  },
  {
    "zip": 62255,
    "lat": 38.31168,
    "lng": -89.7819,
    "state_id": "IL"
  },
  {
    "zip": 62257,
    "lat": 38.28413,
    "lng": -89.73128,
    "state_id": "IL"
  },
  {
    "zip": 62258,
    "lat": 38.46213,
    "lng": -89.77254,
    "state_id": "IL"
  },
  {
    "zip": 62260,
    "lat": 38.45996,
    "lng": -90.10096,
    "state_id": "IL"
  },
  {
    "zip": 62261,
    "lat": 38.01309,
    "lng": -90.00282,
    "state_id": "IL"
  },
  {
    "zip": 62262,
    "lat": 38.94143,
    "lng": -89.27192,
    "state_id": "IL"
  },
  {
    "zip": 62263,
    "lat": 38.33016,
    "lng": -89.40006,
    "state_id": "IL"
  },
  {
    "zip": 62264,
    "lat": 38.31628,
    "lng": -89.9072,
    "state_id": "IL"
  },
  {
    "zip": 62265,
    "lat": 38.50064,
    "lng": -89.67063,
    "state_id": "IL"
  },
  {
    "zip": 62266,
    "lat": 38.48665,
    "lng": -89.6771,
    "state_id": "IL"
  },
  {
    "zip": 62268,
    "lat": 38.27365,
    "lng": -89.528,
    "state_id": "IL"
  },
  {
    "zip": 62269,
    "lat": 38.60338,
    "lng": -89.91604,
    "state_id": "IL"
  },
  {
    "zip": 62271,
    "lat": 38.44476,
    "lng": -89.51576,
    "state_id": "IL"
  },
  {
    "zip": 62272,
    "lat": 37.98834,
    "lng": -89.59939,
    "state_id": "IL"
  },
  {
    "zip": 62273,
    "lat": 38.78677,
    "lng": -89.58388,
    "state_id": "IL"
  },
  {
    "zip": 62274,
    "lat": 38.09177,
    "lng": -89.40828,
    "state_id": "IL"
  },
  {
    "zip": 62275,
    "lat": 38.81166,
    "lng": -89.54475,
    "state_id": "IL"
  },
  {
    "zip": 62277,
    "lat": 38.10088,
    "lng": -90.11549,
    "state_id": "IL"
  },
  {
    "zip": 62278,
    "lat": 38.20794,
    "lng": -89.99259,
    "state_id": "IL"
  },
  {
    "zip": 62279,
    "lat": 38.15121,
    "lng": -90.13541,
    "state_id": "IL"
  },
  {
    "zip": 62280,
    "lat": 37.83505,
    "lng": -89.65966,
    "state_id": "IL"
  },
  {
    "zip": 62281,
    "lat": 38.70442,
    "lng": -89.78181,
    "state_id": "IL"
  },
  {
    "zip": 62282,
    "lat": 38.36317,
    "lng": -89.71268,
    "state_id": "IL"
  },
  {
    "zip": 62284,
    "lat": 38.87655,
    "lng": -89.31304,
    "state_id": "IL"
  },
  {
    "zip": 62285,
    "lat": 38.38835,
    "lng": -90.00773,
    "state_id": "IL"
  },
  {
    "zip": 62286,
    "lat": 38.11832,
    "lng": -89.7144,
    "state_id": "IL"
  },
  {
    "zip": 62288,
    "lat": 37.98825,
    "lng": -89.68559,
    "state_id": "IL"
  },
  {
    "zip": 62289,
    "lat": 38.59694,
    "lng": -89.75153,
    "state_id": "IL"
  },
  {
    "zip": 62292,
    "lat": 38.21265,
    "lng": -89.6801,
    "state_id": "IL"
  },
  {
    "zip": 62293,
    "lat": 38.61897,
    "lng": -89.69751,
    "state_id": "IL"
  },
  {
    "zip": 62294,
    "lat": 38.70281,
    "lng": -89.87117,
    "state_id": "IL"
  },
  {
    "zip": 62295,
    "lat": 38.27929,
    "lng": -90.31336,
    "state_id": "IL"
  },
  {
    "zip": 62297,
    "lat": 38.04983,
    "lng": -89.81313,
    "state_id": "IL"
  },
  {
    "zip": 62298,
    "lat": 38.31087,
    "lng": -90.15224,
    "state_id": "IL"
  },
  {
    "zip": 62301,
    "lat": 39.93159,
    "lng": -91.3866,
    "state_id": "IL"
  },
  {
    "zip": 62305,
    "lat": 39.92982,
    "lng": -91.3459,
    "state_id": "IL"
  },
  {
    "zip": 62311,
    "lat": 40.22198,
    "lng": -90.91774,
    "state_id": "IL"
  },
  {
    "zip": 62312,
    "lat": 39.70915,
    "lng": -91.02783,
    "state_id": "IL"
  },
  {
    "zip": 62313,
    "lat": 40.31958,
    "lng": -91.22604,
    "state_id": "IL"
  },
  {
    "zip": 62314,
    "lat": 39.77064,
    "lng": -90.8903,
    "state_id": "IL"
  },
  {
    "zip": 62316,
    "lat": 40.23633,
    "lng": -91.055,
    "state_id": "IL"
  },
  {
    "zip": 62319,
    "lat": 40.15249,
    "lng": -90.73124,
    "state_id": "IL"
  },
  {
    "zip": 62320,
    "lat": 40.02073,
    "lng": -91.08045,
    "state_id": "IL"
  },
  {
    "zip": 62321,
    "lat": 40.4085,
    "lng": -91.10393,
    "state_id": "IL"
  },
  {
    "zip": 62323,
    "lat": 39.81233,
    "lng": -90.68554,
    "state_id": "IL"
  },
  {
    "zip": 62324,
    "lat": 39.99244,
    "lng": -90.95898,
    "state_id": "IL"
  },
  {
    "zip": 62325,
    "lat": 40.05125,
    "lng": -91.16603,
    "state_id": "IL"
  },
  {
    "zip": 62326,
    "lat": 40.41725,
    "lng": -90.81333,
    "state_id": "IL"
  },
  {
    "zip": 62330,
    "lat": 40.57204,
    "lng": -91.12552,
    "state_id": "IL"
  },
  {
    "zip": 62334,
    "lat": 40.3879,
    "lng": -91.24059,
    "state_id": "IL"
  },
  {
    "zip": 62336,
    "lat": 40.46814,
    "lng": -91.17146,
    "state_id": "IL"
  },
  {
    "zip": 62338,
    "lat": 39.99139,
    "lng": -91.23959,
    "state_id": "IL"
  },
  {
    "zip": 62339,
    "lat": 40.13164,
    "lng": -91.04115,
    "state_id": "IL"
  },
  {
    "zip": 62340,
    "lat": 39.72895,
    "lng": -90.72485,
    "state_id": "IL"
  },
  {
    "zip": 62341,
    "lat": 40.42574,
    "lng": -91.3028,
    "state_id": "IL"
  },
  {
    "zip": 62343,
    "lat": 39.70301,
    "lng": -91.24229,
    "state_id": "IL"
  },
  {
    "zip": 62344,
    "lat": 40.16888,
    "lng": -90.83103,
    "state_id": "IL"
  },
  {
    "zip": 62345,
    "lat": 39.70498,
    "lng": -91.12951,
    "state_id": "IL"
  },
  {
    "zip": 62346,
    "lat": 40.14813,
    "lng": -90.94751,
    "state_id": "IL"
  },
  {
    "zip": 62347,
    "lat": 39.86596,
    "lng": -91.08897,
    "state_id": "IL"
  },
  {
    "zip": 62348,
    "lat": 40.18759,
    "lng": -91.36729,
    "state_id": "IL"
  },
  {
    "zip": 62349,
    "lat": 40.1675,
    "lng": -91.2008,
    "state_id": "IL"
  },
  {
    "zip": 62351,
    "lat": 40.11574,
    "lng": -91.26763,
    "state_id": "IL"
  },
  {
    "zip": 62352,
    "lat": 39.56461,
    "lng": -90.64984,
    "state_id": "IL"
  },
  {
    "zip": 62353,
    "lat": 39.97253,
    "lng": -90.75829,
    "state_id": "IL"
  },
  {
    "zip": 62354,
    "lat": 40.52599,
    "lng": -91.33178,
    "state_id": "IL"
  },
  {
    "zip": 62355,
    "lat": 39.41022,
    "lng": -90.77649,
    "state_id": "IL"
  },
  {
    "zip": 62356,
    "lat": 39.60143,
    "lng": -91.08577,
    "state_id": "IL"
  },
  {
    "zip": 62357,
    "lat": 39.68493,
    "lng": -90.86744,
    "state_id": "IL"
  },
  {
    "zip": 62358,
    "lat": 40.58205,
    "lng": -91.25716,
    "state_id": "IL"
  },
  {
    "zip": 62359,
    "lat": 40.0327,
    "lng": -91.20473,
    "state_id": "IL"
  },
  {
    "zip": 62360,
    "lat": 39.8163,
    "lng": -91.26344,
    "state_id": "IL"
  },
  {
    "zip": 62361,
    "lat": 39.48316,
    "lng": -90.64629,
    "state_id": "IL"
  },
  {
    "zip": 62362,
    "lat": 39.78224,
    "lng": -90.7472,
    "state_id": "IL"
  },
  {
    "zip": 62363,
    "lat": 39.59361,
    "lng": -90.77424,
    "state_id": "IL"
  },
  {
    "zip": 62365,
    "lat": 39.79639,
    "lng": -91.1594,
    "state_id": "IL"
  },
  {
    "zip": 62366,
    "lat": 39.45999,
    "lng": -90.89429,
    "state_id": "IL"
  },
  {
    "zip": 62367,
    "lat": 40.30522,
    "lng": -90.87607,
    "state_id": "IL"
  },
  {
    "zip": 62370,
    "lat": 39.52086,
    "lng": -91.00195,
    "state_id": "IL"
  },
  {
    "zip": 62373,
    "lat": 40.24216,
    "lng": -91.34172,
    "state_id": "IL"
  },
  {
    "zip": 62374,
    "lat": 40.4089,
    "lng": -90.91204,
    "state_id": "IL"
  },
  {
    "zip": 62375,
    "lat": 40.00414,
    "lng": -90.87125,
    "state_id": "IL"
  },
  {
    "zip": 62376,
    "lat": 40.11507,
    "lng": -91.42049,
    "state_id": "IL"
  },
  {
    "zip": 62378,
    "lat": 39.89729,
    "lng": -90.63512,
    "state_id": "IL"
  },
  {
    "zip": 62379,
    "lat": 40.28676,
    "lng": -91.40012,
    "state_id": "IL"
  },
  {
    "zip": 62380,
    "lat": 40.24003,
    "lng": -91.19087,
    "state_id": "IL"
  },
  {
    "zip": 62401,
    "lat": 39.11952,
    "lng": -88.56418,
    "state_id": "IL"
  },
  {
    "zip": 62410,
    "lat": 38.53253,
    "lng": -87.72653,
    "state_id": "IL"
  },
  {
    "zip": 62411,
    "lat": 39.07062,
    "lng": -88.72964,
    "state_id": "IL"
  },
  {
    "zip": 62413,
    "lat": 39.13963,
    "lng": -87.83882,
    "state_id": "IL"
  },
  {
    "zip": 62414,
    "lat": 39.17067,
    "lng": -88.83047,
    "state_id": "IL"
  },
  {
    "zip": 62417,
    "lat": 38.70667,
    "lng": -87.76565,
    "state_id": "IL"
  },
  {
    "zip": 62418,
    "lat": 39.01845,
    "lng": -88.96952,
    "state_id": "IL"
  },
  {
    "zip": 62419,
    "lat": 38.62003,
    "lng": -87.98542,
    "state_id": "IL"
  },
  {
    "zip": 62420,
    "lat": 39.29041,
    "lng": -88.00199,
    "state_id": "IL"
  },
  {
    "zip": 62421,
    "lat": 38.7536,
    "lng": -87.94355,
    "state_id": "IL"
  },
  {
    "zip": 62422,
    "lat": 39.23883,
    "lng": -88.88323,
    "state_id": "IL"
  },
  {
    "zip": 62423,
    "lat": 39.46168,
    "lng": -87.57963,
    "state_id": "IL"
  },
  {
    "zip": 62424,
    "lat": 38.98978,
    "lng": -88.42215,
    "state_id": "IL"
  },
  {
    "zip": 62425,
    "lat": 38.82852,
    "lng": -88.09214,
    "state_id": "IL"
  },
  {
    "zip": 62426,
    "lat": 38.90212,
    "lng": -88.66452,
    "state_id": "IL"
  },
  {
    "zip": 62427,
    "lat": 38.8721,
    "lng": -87.65938,
    "state_id": "IL"
  },
  {
    "zip": 62428,
    "lat": 39.26364,
    "lng": -88.13124,
    "state_id": "IL"
  },
  {
    "zip": 62431,
    "lat": 39.22885,
    "lng": -88.99106,
    "state_id": "IL"
  },
  {
    "zip": 62432,
    "lat": 39.13551,
    "lng": -88.13981,
    "state_id": "IL"
  },
  {
    "zip": 62433,
    "lat": 39.11195,
    "lng": -87.70349,
    "state_id": "IL"
  },
  {
    "zip": 62434,
    "lat": 38.84323,
    "lng": -88.3251,
    "state_id": "IL"
  },
  {
    "zip": 62436,
    "lat": 39.17984,
    "lng": -88.2525,
    "state_id": "IL"
  },
  {
    "zip": 62438,
    "lat": 39.32114,
    "lng": -88.86922,
    "state_id": "IL"
  },
  {
    "zip": 62439,
    "lat": 38.74656,
    "lng": -87.63528,
    "state_id": "IL"
  },
  {
    "zip": 62440,
    "lat": 39.3955,
    "lng": -88.26791,
    "state_id": "IL"
  },
  {
    "zip": 62441,
    "lat": 39.39759,
    "lng": -87.69759,
    "state_id": "IL"
  },
  {
    "zip": 62442,
    "lat": 39.31187,
    "lng": -87.85503,
    "state_id": "IL"
  },
  {
    "zip": 62443,
    "lat": 38.9541,
    "lng": -88.62801,
    "state_id": "IL"
  },
  {
    "zip": 62444,
    "lat": 39.27655,
    "lng": -88.7393,
    "state_id": "IL"
  },
  {
    "zip": 62445,
    "lat": 39.16802,
    "lng": -88.32119,
    "state_id": "IL"
  },
  {
    "zip": 62446,
    "lat": 38.49746,
    "lng": -88.21792,
    "state_id": "IL"
  },
  {
    "zip": 62447,
    "lat": 39.31548,
    "lng": -88.44446,
    "state_id": "IL"
  },
  {
    "zip": 62448,
    "lat": 38.96893,
    "lng": -88.18257,
    "state_id": "IL"
  },
  {
    "zip": 62449,
    "lat": 39.00624,
    "lng": -87.91067,
    "state_id": "IL"
  },
  {
    "zip": 62450,
    "lat": 38.71236,
    "lng": -88.09042,
    "state_id": "IL"
  },
  {
    "zip": 62451,
    "lat": 38.99256,
    "lng": -87.60615,
    "state_id": "IL"
  },
  {
    "zip": 62452,
    "lat": 38.58621,
    "lng": -88.01726,
    "state_id": "IL"
  },
  {
    "zip": 62454,
    "lat": 38.99864,
    "lng": -87.75803,
    "state_id": "IL"
  },
  {
    "zip": 62458,
    "lat": 39.02471,
    "lng": -88.85146,
    "state_id": "IL"
  },
  {
    "zip": 62459,
    "lat": 38.93002,
    "lng": -88.02779,
    "state_id": "IL"
  },
  {
    "zip": 62460,
    "lat": 38.60842,
    "lng": -87.69831,
    "state_id": "IL"
  },
  {
    "zip": 62461,
    "lat": 39.19732,
    "lng": -88.67571,
    "state_id": "IL"
  },
  {
    "zip": 62462,
    "lat": 39.23471,
    "lng": -88.46387,
    "state_id": "IL"
  },
  {
    "zip": 62463,
    "lat": 39.27666,
    "lng": -88.60536,
    "state_id": "IL"
  },
  {
    "zip": 62465,
    "lat": 39.34939,
    "lng": -88.64047,
    "state_id": "IL"
  },
  {
    "zip": 62466,
    "lat": 38.72579,
    "lng": -87.84971,
    "state_id": "IL"
  },
  {
    "zip": 62467,
    "lat": 39.1312,
    "lng": -88.43499,
    "state_id": "IL"
  },
  {
    "zip": 62468,
    "lat": 39.27744,
    "lng": -88.27033,
    "state_id": "IL"
  },
  {
    "zip": 62469,
    "lat": 39.34861,
    "lng": -88.34055,
    "state_id": "IL"
  },
  {
    "zip": 62471,
    "lat": 38.9546,
    "lng": -89.13061,
    "state_id": "IL"
  },
  {
    "zip": 62473,
    "lat": 39.02035,
    "lng": -88.56542,
    "state_id": "IL"
  },
  {
    "zip": 62474,
    "lat": 39.43664,
    "lng": -87.99652,
    "state_id": "IL"
  },
  {
    "zip": 62475,
    "lat": 38.88697,
    "lng": -88.0515,
    "state_id": "IL"
  },
  {
    "zip": 62476,
    "lat": 38.534,
    "lng": -88.00928,
    "state_id": "IL"
  },
  {
    "zip": 62477,
    "lat": 39.23695,
    "lng": -87.66291,
    "state_id": "IL"
  },
  {
    "zip": 62478,
    "lat": 39.17838,
    "lng": -87.74268,
    "state_id": "IL"
  },
  {
    "zip": 62479,
    "lat": 39.03774,
    "lng": -88.31092,
    "state_id": "IL"
  },
  {
    "zip": 62480,
    "lat": 38.99856,
    "lng": -88.00156,
    "state_id": "IL"
  },
  {
    "zip": 62481,
    "lat": 39.12718,
    "lng": -88.022,
    "state_id": "IL"
  },
  {
    "zip": 62501,
    "lat": 39.93397,
    "lng": -88.80518,
    "state_id": "IL"
  },
  {
    "zip": 62510,
    "lat": 39.53149,
    "lng": -89.02694,
    "state_id": "IL"
  },
  {
    "zip": 62512,
    "lat": 40.14496,
    "lng": -89.20355,
    "state_id": "IL"
  },
  {
    "zip": 62513,
    "lat": 39.72201,
    "lng": -89.15082,
    "state_id": "IL"
  },
  {
    "zip": 62514,
    "lat": 39.7625,
    "lng": -89.05069,
    "state_id": "IL"
  },
  {
    "zip": 62515,
    "lat": 39.85839,
    "lng": -89.3765,
    "state_id": "IL"
  },
  {
    "zip": 62517,
    "lat": 39.59127,
    "lng": -89.43097,
    "state_id": "IL"
  },
  {
    "zip": 62518,
    "lat": 40.05417,
    "lng": -89.19142,
    "state_id": "IL"
  },
  {
    "zip": 62519,
    "lat": 39.92882,
    "lng": -89.39528,
    "state_id": "IL"
  },
  {
    "zip": 62520,
    "lat": 39.81802,
    "lng": -89.45598,
    "state_id": "IL"
  },
  {
    "zip": 62521,
    "lat": 39.81509,
    "lng": -88.92533,
    "state_id": "IL"
  },
  {
    "zip": 62522,
    "lat": 39.829,
    "lng": -89.05111,
    "state_id": "IL"
  },
  {
    "zip": 62523,
    "lat": 39.84411,
    "lng": -88.9523,
    "state_id": "IL"
  },
  {
    "zip": 62526,
    "lat": 39.90152,
    "lng": -88.9879,
    "state_id": "IL"
  },
  {
    "zip": 62530,
    "lat": 39.56174,
    "lng": -89.66136,
    "state_id": "IL"
  },
  {
    "zip": 62531,
    "lat": 39.66695,
    "lng": -89.37662,
    "state_id": "IL"
  },
  {
    "zip": 62532,
    "lat": 39.76335,
    "lng": -88.98924,
    "state_id": "IL"
  },
  {
    "zip": 62533,
    "lat": 39.44419,
    "lng": -89.62231,
    "state_id": "IL"
  },
  {
    "zip": 62534,
    "lat": 39.53445,
    "lng": -88.7986,
    "state_id": "IL"
  },
  {
    "zip": 62535,
    "lat": 39.92473,
    "lng": -88.96896,
    "state_id": "IL"
  },
  {
    "zip": 62536,
    "lat": 39.63016,
    "lng": -89.65133,
    "state_id": "IL"
  },
  {
    "zip": 62537,
    "lat": 39.85317,
    "lng": -89.09467,
    "state_id": "IL"
  },
  {
    "zip": 62538,
    "lat": 39.35641,
    "lng": -89.51993,
    "state_id": "IL"
  },
  {
    "zip": 62539,
    "lat": 39.86604,
    "lng": -89.25126,
    "state_id": "IL"
  },
  {
    "zip": 62540,
    "lat": 39.58388,
    "lng": -89.41824,
    "state_id": "IL"
  },
  {
    "zip": 62541,
    "lat": 39.96541,
    "lng": -89.35509,
    "state_id": "IL"
  },
  {
    "zip": 62543,
    "lat": 39.97146,
    "lng": -89.15219,
    "state_id": "IL"
  },
  {
    "zip": 62544,
    "lat": 39.70643,
    "lng": -88.9679,
    "state_id": "IL"
  },
  {
    "zip": 62545,
    "lat": 39.76184,
    "lng": -89.38942,
    "state_id": "IL"
  },
  {
    "zip": 62546,
    "lat": 39.43185,
    "lng": -89.43981,
    "state_id": "IL"
  },
  {
    "zip": 62547,
    "lat": 39.76896,
    "lng": -89.24887,
    "state_id": "IL"
  },
  {
    "zip": 62548,
    "lat": 39.97847,
    "lng": -89.28227,
    "state_id": "IL"
  },
  {
    "zip": 62549,
    "lat": 39.77049,
    "lng": -88.86586,
    "state_id": "IL"
  },
  {
    "zip": 62550,
    "lat": 39.61145,
    "lng": -88.99191,
    "state_id": "IL"
  },
  {
    "zip": 62551,
    "lat": 39.8532,
    "lng": -89.15766,
    "state_id": "IL"
  },
  {
    "zip": 62553,
    "lat": 39.26621,
    "lng": -89.10717,
    "state_id": "IL"
  },
  {
    "zip": 62554,
    "lat": 39.94902,
    "lng": -88.87146,
    "state_id": "IL"
  },
  {
    "zip": 62555,
    "lat": 39.4663,
    "lng": -89.22067,
    "state_id": "IL"
  },
  {
    "zip": 62556,
    "lat": 39.46992,
    "lng": -89.37329,
    "state_id": "IL"
  },
  {
    "zip": 62557,
    "lat": 39.39705,
    "lng": -89.10482,
    "state_id": "IL"
  },
  {
    "zip": 62558,
    "lat": 39.58972,
    "lng": -89.54035,
    "state_id": "IL"
  },
  {
    "zip": 62560,
    "lat": 39.30222,
    "lng": -89.60112,
    "state_id": "IL"
  },
  {
    "zip": 62561,
    "lat": 39.86626,
    "lng": -89.50866,
    "state_id": "IL"
  },
  {
    "zip": 62563,
    "lat": 39.71567,
    "lng": -89.503,
    "state_id": "IL"
  },
  {
    "zip": 62565,
    "lat": 39.4113,
    "lng": -88.80426,
    "state_id": "IL"
  },
  {
    "zip": 62567,
    "lat": 39.64523,
    "lng": -89.18987,
    "state_id": "IL"
  },
  {
    "zip": 62568,
    "lat": 39.56083,
    "lng": -89.29024,
    "state_id": "IL"
  },
  {
    "zip": 62570,
    "lat": 39.58849,
    "lng": -89.44825,
    "state_id": "IL"
  },
  {
    "zip": 62571,
    "lat": 39.38067,
    "lng": -88.96114,
    "state_id": "IL"
  },
  {
    "zip": 62572,
    "lat": 39.36095,
    "lng": -89.70156,
    "state_id": "IL"
  },
  {
    "zip": 62573,
    "lat": 39.94539,
    "lng": -89.07414,
    "state_id": "IL"
  },
  {
    "zip": 62601,
    "lat": 39.74873,
    "lng": -90.04086,
    "state_id": "IL"
  },
  {
    "zip": 62610,
    "lat": 39.55883,
    "lng": -90.43489,
    "state_id": "IL"
  },
  {
    "zip": 62611,
    "lat": 39.8856,
    "lng": -90.39946,
    "state_id": "IL"
  },
  {
    "zip": 62612,
    "lat": 39.88949,
    "lng": -90.06698,
    "state_id": "IL"
  },
  {
    "zip": 62613,
    "lat": 39.99177,
    "lng": -89.66836,
    "state_id": "IL"
  },
  {
    "zip": 62615,
    "lat": 39.58505,
    "lng": -89.75737,
    "state_id": "IL"
  },
  {
    "zip": 62617,
    "lat": 40.1525,
    "lng": -90.17885,
    "state_id": "IL"
  },
  {
    "zip": 62618,
    "lat": 39.98678,
    "lng": -90.40075,
    "state_id": "IL"
  },
  {
    "zip": 62621,
    "lat": 39.73137,
    "lng": -90.53018,
    "state_id": "IL"
  },
  {
    "zip": 62622,
    "lat": 39.97814,
    "lng": -90.35343,
    "state_id": "IL"
  },
  {
    "zip": 62624,
    "lat": 40.15442,
    "lng": -90.36199,
    "state_id": "IL"
  },
  {
    "zip": 62625,
    "lat": 39.91029,
    "lng": -89.69584,
    "state_id": "IL"
  },
  {
    "zip": 62626,
    "lat": 39.28334,
    "lng": -89.88404,
    "state_id": "IL"
  },
  {
    "zip": 62627,
    "lat": 40.05627,
    "lng": -90.12895,
    "state_id": "IL"
  },
  {
    "zip": 62628,
    "lat": 39.77945,
    "lng": -90.40324,
    "state_id": "IL"
  },
  {
    "zip": 62629,
    "lat": 39.67823,
    "lng": -89.71401,
    "state_id": "IL"
  },
  {
    "zip": 62630,
    "lat": 39.26081,
    "lng": -90.07834,
    "state_id": "IL"
  },
  {
    "zip": 62631,
    "lat": 39.82436,
    "lng": -90.36127,
    "state_id": "IL"
  },
  {
    "zip": 62633,
    "lat": 40.22644,
    "lng": -89.8768,
    "state_id": "IL"
  },
  {
    "zip": 62634,
    "lat": 40.01313,
    "lng": -89.45198,
    "state_id": "IL"
  },
  {
    "zip": 62635,
    "lat": 40.29671,
    "lng": -89.47141,
    "state_id": "IL"
  },
  {
    "zip": 62638,
    "lat": 39.60535,
    "lng": -90.0818,
    "state_id": "IL"
  },
  {
    "zip": 62639,
    "lat": 40.04263,
    "lng": -90.48803,
    "state_id": "IL"
  },
  {
    "zip": 62640,
    "lat": 39.4339,
    "lng": -89.80523,
    "state_id": "IL"
  },
  {
    "zip": 62642,
    "lat": 40.08905,
    "lng": -89.71348,
    "state_id": "IL"
  },
  {
    "zip": 62643,
    "lat": 40.24301,
    "lng": -89.45169,
    "state_id": "IL"
  },
  {
    "zip": 62644,
    "lat": 40.28959,
    "lng": -90.05434,
    "state_id": "IL"
  },
  {
    "zip": 62649,
    "lat": 39.36532,
    "lng": -90.06747,
    "state_id": "IL"
  },
  {
    "zip": 62650,
    "lat": 39.73454,
    "lng": -90.22336,
    "state_id": "IL"
  },
  {
    "zip": 62655,
    "lat": 40.16542,
    "lng": -89.99408,
    "state_id": "IL"
  },
  {
    "zip": 62656,
    "lat": 40.13988,
    "lng": -89.37163,
    "state_id": "IL"
  },
  {
    "zip": 62661,
    "lat": 39.6657,
    "lng": -89.84042,
    "state_id": "IL"
  },
  {
    "zip": 62663,
    "lat": 39.54068,
    "lng": -90.32904,
    "state_id": "IL"
  },
  {
    "zip": 62664,
    "lat": 40.21229,
    "lng": -89.72526,
    "state_id": "IL"
  },
  {
    "zip": 62665,
    "lat": 39.81714,
    "lng": -90.53395,
    "state_id": "IL"
  },
  {
    "zip": 62666,
    "lat": 40.0856,
    "lng": -89.54346,
    "state_id": "IL"
  },
  {
    "zip": 62667,
    "lat": 39.49454,
    "lng": -89.99268,
    "state_id": "IL"
  },
  {
    "zip": 62668,
    "lat": 39.57232,
    "lng": -90.23793,
    "state_id": "IL"
  },
  {
    "zip": 62670,
    "lat": 39.74136,
    "lng": -89.89389,
    "state_id": "IL"
  },
  {
    "zip": 62671,
    "lat": 40.17292,
    "lng": -89.55842,
    "state_id": "IL"
  },
  {
    "zip": 62672,
    "lat": 39.39798,
    "lng": -89.80446,
    "state_id": "IL"
  },
  {
    "zip": 62673,
    "lat": 40.09471,
    "lng": -89.97845,
    "state_id": "IL"
  },
  {
    "zip": 62674,
    "lat": 39.43469,
    "lng": -90.02994,
    "state_id": "IL"
  },
  {
    "zip": 62675,
    "lat": 40.02189,
    "lng": -89.85914,
    "state_id": "IL"
  },
  {
    "zip": 62677,
    "lat": 39.84985,
    "lng": -89.8791,
    "state_id": "IL"
  },
  {
    "zip": 62681,
    "lat": 40.13619,
    "lng": -90.55887,
    "state_id": "IL"
  },
  {
    "zip": 62682,
    "lat": 40.29219,
    "lng": -89.63596,
    "state_id": "IL"
  },
  {
    "zip": 62684,
    "lat": 39.90928,
    "lng": -89.58687,
    "state_id": "IL"
  },
  {
    "zip": 62685,
    "lat": 39.14157,
    "lng": -90.00355,
    "state_id": "IL"
  },
  {
    "zip": 62688,
    "lat": 39.94601,
    "lng": -89.92315,
    "state_id": "IL"
  },
  {
    "zip": 62689,
    "lat": 39.53367,
    "lng": -89.76185,
    "state_id": "IL"
  },
  {
    "zip": 62690,
    "lat": 39.50535,
    "lng": -89.76316,
    "state_id": "IL"
  },
  {
    "zip": 62691,
    "lat": 39.95914,
    "lng": -90.21439,
    "state_id": "IL"
  },
  {
    "zip": 62692,
    "lat": 39.5814,
    "lng": -89.9399,
    "state_id": "IL"
  },
  {
    "zip": 62693,
    "lat": 39.95827,
    "lng": -89.51728,
    "state_id": "IL"
  },
  {
    "zip": 62694,
    "lat": 39.62159,
    "lng": -90.47134,
    "state_id": "IL"
  },
  {
    "zip": 62695,
    "lat": 39.62711,
    "lng": -90.2237,
    "state_id": "IL"
  },
  {
    "zip": 62701,
    "lat": 39.80082,
    "lng": -89.64884,
    "state_id": "IL"
  },
  {
    "zip": 62702,
    "lat": 39.82399,
    "lng": -89.64168,
    "state_id": "IL"
  },
  {
    "zip": 62703,
    "lat": 39.7618,
    "lng": -89.62982,
    "state_id": "IL"
  },
  {
    "zip": 62704,
    "lat": 39.774,
    "lng": -89.68515,
    "state_id": "IL"
  },
  {
    "zip": 62707,
    "lat": 39.85438,
    "lng": -89.65437,
    "state_id": "IL"
  },
  {
    "zip": 62711,
    "lat": 39.76545,
    "lng": -89.72926,
    "state_id": "IL"
  },
  {
    "zip": 62712,
    "lat": 39.75327,
    "lng": -89.58012,
    "state_id": "IL"
  },
  {
    "zip": 62801,
    "lat": 38.51276,
    "lng": -89.14165,
    "state_id": "IL"
  },
  {
    "zip": 62803,
    "lat": 38.45287,
    "lng": -89.29656,
    "state_id": "IL"
  },
  {
    "zip": 62806,
    "lat": 38.38184,
    "lng": -88.07486,
    "state_id": "IL"
  },
  {
    "zip": 62807,
    "lat": 38.74551,
    "lng": -88.92837,
    "state_id": "IL"
  },
  {
    "zip": 62808,
    "lat": 38.30206,
    "lng": -89.18748,
    "state_id": "IL"
  },
  {
    "zip": 62809,
    "lat": 38.27909,
    "lng": -88.33984,
    "state_id": "IL"
  },
  {
    "zip": 62810,
    "lat": 38.20309,
    "lng": -88.74559,
    "state_id": "IL"
  },
  {
    "zip": 62811,
    "lat": 38.3831,
    "lng": -87.90555,
    "state_id": "IL"
  },
  {
    "zip": 62812,
    "lat": 37.99769,
    "lng": -88.91698,
    "state_id": "IL"
  },
  {
    "zip": 62814,
    "lat": 38.37832,
    "lng": -88.73183,
    "state_id": "IL"
  },
  {
    "zip": 62815,
    "lat": 38.45711,
    "lng": -87.98701,
    "state_id": "IL"
  },
  {
    "zip": 62816,
    "lat": 38.18585,
    "lng": -88.9239,
    "state_id": "IL"
  },
  {
    "zip": 62817,
    "lat": 37.95349,
    "lng": -88.47808,
    "state_id": "IL"
  },
  {
    "zip": 62818,
    "lat": 38.38328,
    "lng": -87.96536,
    "state_id": "IL"
  },
  {
    "zip": 62819,
    "lat": 37.97952,
    "lng": -89.01496,
    "state_id": "IL"
  },
  {
    "zip": 62820,
    "lat": 38.24345,
    "lng": -88.23473,
    "state_id": "IL"
  },
  {
    "zip": 62821,
    "lat": 38.07341,
    "lng": -88.13264,
    "state_id": "IL"
  },
  {
    "zip": 62822,
    "lat": 37.97774,
    "lng": -89.05655,
    "state_id": "IL"
  },
  {
    "zip": 62823,
    "lat": 38.51962,
    "lng": -88.43942,
    "state_id": "IL"
  },
  {
    "zip": 62824,
    "lat": 38.6711,
    "lng": -88.34651,
    "state_id": "IL"
  },
  {
    "zip": 62825,
    "lat": 37.99837,
    "lng": -89.06789,
    "state_id": "IL"
  },
  {
    "zip": 62827,
    "lat": 38.16915,
    "lng": -88.04316,
    "state_id": "IL"
  },
  {
    "zip": 62828,
    "lat": 38.19416,
    "lng": -88.60679,
    "state_id": "IL"
  },
  {
    "zip": 62829,
    "lat": 37.9762,
    "lng": -88.48732,
    "state_id": "IL"
  },
  {
    "zip": 62830,
    "lat": 38.44685,
    "lng": -88.96332,
    "state_id": "IL"
  },
  {
    "zip": 62831,
    "lat": 38.23068,
    "lng": -89.22128,
    "state_id": "IL"
  },
  {
    "zip": 62832,
    "lat": 38.01221,
    "lng": -89.24995,
    "state_id": "IL"
  },
  {
    "zip": 62833,
    "lat": 38.36124,
    "lng": -88.16051,
    "state_id": "IL"
  },
  {
    "zip": 62835,
    "lat": 38.10687,
    "lng": -88.32026,
    "state_id": "IL"
  },
  {
    "zip": 62836,
    "lat": 38.09034,
    "lng": -88.80085,
    "state_id": "IL"
  },
  {
    "zip": 62837,
    "lat": 38.36802,
    "lng": -88.35645,
    "state_id": "IL"
  },
  {
    "zip": 62838,
    "lat": 38.87289,
    "lng": -88.75553,
    "state_id": "IL"
  },
  {
    "zip": 62839,
    "lat": 38.6651,
    "lng": -88.49904,
    "state_id": "IL"
  },
  {
    "zip": 62841,
    "lat": 37.85938,
    "lng": -89.00024,
    "state_id": "IL"
  },
  {
    "zip": 62842,
    "lat": 38.47081,
    "lng": -88.37313,
    "state_id": "IL"
  },
  {
    "zip": 62843,
    "lat": 38.38219,
    "lng": -88.19612,
    "state_id": "IL"
  },
  {
    "zip": 62844,
    "lat": 38.25814,
    "lng": -88.04625,
    "state_id": "IL"
  },
  {
    "zip": 62846,
    "lat": 38.14642,
    "lng": -88.87024,
    "state_id": "IL"
  },
  {
    "zip": 62848,
    "lat": 38.43659,
    "lng": -89.16482,
    "state_id": "IL"
  },
  {
    "zip": 62849,
    "lat": 38.58706,
    "lng": -88.77354,
    "state_id": "IL"
  },
  {
    "zip": 62850,
    "lat": 38.49533,
    "lng": -88.59105,
    "state_id": "IL"
  },
  {
    "zip": 62851,
    "lat": 38.4207,
    "lng": -88.66192,
    "state_id": "IL"
  },
  {
    "zip": 62852,
    "lat": 38.35015,
    "lng": -87.86675,
    "state_id": "IL"
  },
  {
    "zip": 62853,
    "lat": 38.51466,
    "lng": -88.91268,
    "state_id": "IL"
  },
  {
    "zip": 62854,
    "lat": 38.76801,
    "lng": -88.80664,
    "state_id": "IL"
  },
  {
    "zip": 62856,
    "lat": 37.95748,
    "lng": -88.8362,
    "state_id": "IL"
  },
  {
    "zip": 62858,
    "lat": 38.81473,
    "lng": -88.49329,
    "state_id": "IL"
  },
  {
    "zip": 62859,
    "lat": 38.07612,
    "lng": -88.53393,
    "state_id": "IL"
  },
  {
    "zip": 62860,
    "lat": 38.04086,
    "lng": -88.72459,
    "state_id": "IL"
  },
  {
    "zip": 62861,
    "lat": 38.03196,
    "lng": -88.02855,
    "state_id": "IL"
  },
  {
    "zip": 62862,
    "lat": 38.21733,
    "lng": -88.29613,
    "state_id": "IL"
  },
  {
    "zip": 62863,
    "lat": 38.41646,
    "lng": -87.85596,
    "state_id": "IL"
  },
  {
    "zip": 62864,
    "lat": 38.33121,
    "lng": -88.90259,
    "state_id": "IL"
  },
  {
    "zip": 62865,
    "lat": 37.97199,
    "lng": -89.07878,
    "state_id": "IL"
  },
  {
    "zip": 62867,
    "lat": 37.91634,
    "lng": -88.10768,
    "state_id": "IL"
  },
  {
    "zip": 62868,
    "lat": 38.68377,
    "lng": -88.22851,
    "state_id": "IL"
  },
  {
    "zip": 62869,
    "lat": 37.96206,
    "lng": -88.28113,
    "state_id": "IL"
  },
  {
    "zip": 62870,
    "lat": 38.63495,
    "lng": -89.05107,
    "state_id": "IL"
  },
  {
    "zip": 62871,
    "lat": 37.87593,
    "lng": -88.26828,
    "state_id": "IL"
  },
  {
    "zip": 62872,
    "lat": 38.2664,
    "lng": -88.80381,
    "state_id": "IL"
  },
  {
    "zip": 62874,
    "lat": 37.91937,
    "lng": -88.9757,
    "state_id": "IL"
  },
  {
    "zip": 62875,
    "lat": 38.75915,
    "lng": -89.12131,
    "state_id": "IL"
  },
  {
    "zip": 62876,
    "lat": 38.27645,
    "lng": -89.19576,
    "state_id": "IL"
  },
  {
    "zip": 62877,
    "lat": 38.3904,
    "lng": -89.21308,
    "state_id": "IL"
  },
  {
    "zip": 62878,
    "lat": 38.57857,
    "lng": -88.50854,
    "state_id": "IL"
  },
  {
    "zip": 62879,
    "lat": 38.76484,
    "lng": -88.3607,
    "state_id": "IL"
  },
  {
    "zip": 62880,
    "lat": 38.86697,
    "lng": -88.88444,
    "state_id": "IL"
  },
  {
    "zip": 62881,
    "lat": 38.63515,
    "lng": -88.92854,
    "state_id": "IL"
  },
  {
    "zip": 62882,
    "lat": 38.63526,
    "lng": -89.12009,
    "state_id": "IL"
  },
  {
    "zip": 62883,
    "lat": 38.16328,
    "lng": -89.11505,
    "state_id": "IL"
  },
  {
    "zip": 62884,
    "lat": 38.08222,
    "lng": -89.05642,
    "state_id": "IL"
  },
  {
    "zip": 62885,
    "lat": 38.85769,
    "lng": -89.05502,
    "state_id": "IL"
  },
  {
    "zip": 62886,
    "lat": 38.37892,
    "lng": -88.53984,
    "state_id": "IL"
  },
  {
    "zip": 62887,
    "lat": 38.1874,
    "lng": -88.35986,
    "state_id": "IL"
  },
  {
    "zip": 62888,
    "lat": 38.12848,
    "lng": -89.23479,
    "state_id": "IL"
  },
  {
    "zip": 62889,
    "lat": 38.45813,
    "lng": -88.82921,
    "state_id": "IL"
  },
  {
    "zip": 62890,
    "lat": 37.90643,
    "lng": -88.73756,
    "state_id": "IL"
  },
  {
    "zip": 62891,
    "lat": 38.01516,
    "lng": -89.04304,
    "state_id": "IL"
  },
  {
    "zip": 62892,
    "lat": 38.80669,
    "lng": -89.0781,
    "state_id": "IL"
  },
  {
    "zip": 62893,
    "lat": 38.46707,
    "lng": -89.03285,
    "state_id": "IL"
  },
  {
    "zip": 62894,
    "lat": 38.20902,
    "lng": -89.03689,
    "state_id": "IL"
  },
  {
    "zip": 62895,
    "lat": 38.31014,
    "lng": -88.56344,
    "state_id": "IL"
  },
  {
    "zip": 62896,
    "lat": 37.89104,
    "lng": -88.91632,
    "state_id": "IL"
  },
  {
    "zip": 62897,
    "lat": 38.0963,
    "lng": -88.90226,
    "state_id": "IL"
  },
  {
    "zip": 62898,
    "lat": 38.35051,
    "lng": -89.06473,
    "state_id": "IL"
  },
  {
    "zip": 62899,
    "lat": 38.63192,
    "lng": -88.64568,
    "state_id": "IL"
  },
  {
    "zip": 62901,
    "lat": 37.73826,
    "lng": -89.20844,
    "state_id": "IL"
  },
  {
    "zip": 62902,
    "lat": 37.66362,
    "lng": -89.11727,
    "state_id": "IL"
  },
  {
    "zip": 62903,
    "lat": 37.67039,
    "lng": -89.27782,
    "state_id": "IL"
  },
  {
    "zip": 62905,
    "lat": 37.574,
    "lng": -89.3652,
    "state_id": "IL"
  },
  {
    "zip": 62906,
    "lat": 37.45662,
    "lng": -89.18699,
    "state_id": "IL"
  },
  {
    "zip": 62907,
    "lat": 37.86675,
    "lng": -89.49823,
    "state_id": "IL"
  },
  {
    "zip": 62908,
    "lat": 37.30848,
    "lng": -88.86578,
    "state_id": "IL"
  },
  {
    "zip": 62910,
    "lat": 37.15411,
    "lng": -88.53338,
    "state_id": "IL"
  },
  {
    "zip": 62912,
    "lat": 37.47662,
    "lng": -89.03918,
    "state_id": "IL"
  },
  {
    "zip": 62914,
    "lat": 37.06546,
    "lng": -89.22246,
    "state_id": "IL"
  },
  {
    "zip": 62915,
    "lat": 37.78334,
    "lng": -89.11983,
    "state_id": "IL"
  },
  {
    "zip": 62916,
    "lat": 37.92862,
    "lng": -89.56416,
    "state_id": "IL"
  },
  {
    "zip": 62917,
    "lat": 37.69811,
    "lng": -88.64883,
    "state_id": "IL"
  },
  {
    "zip": 62918,
    "lat": 37.79,
    "lng": -89.08769,
    "state_id": "IL"
  },
  {
    "zip": 62919,
    "lat": 37.52073,
    "lng": -88.14891,
    "state_id": "IL"
  },
  {
    "zip": 62920,
    "lat": 37.54896,
    "lng": -89.23507,
    "state_id": "IL"
  },
  {
    "zip": 62921,
    "lat": 37.80158,
    "lng": -89.08423,
    "state_id": "IL"
  },
  {
    "zip": 62922,
    "lat": 37.62105,
    "lng": -88.81596,
    "state_id": "IL"
  },
  {
    "zip": 62923,
    "lat": 37.34435,
    "lng": -89.01475,
    "state_id": "IL"
  },
  {
    "zip": 62924,
    "lat": 37.82386,
    "lng": -89.20465,
    "state_id": "IL"
  },
  {
    "zip": 62926,
    "lat": 37.36734,
    "lng": -89.1339,
    "state_id": "IL"
  },
  {
    "zip": 62927,
    "lat": 37.93521,
    "lng": -89.24162,
    "state_id": "IL"
  },
  {
    "zip": 62928,
    "lat": 37.48287,
    "lng": -88.57913,
    "state_id": "IL"
  },
  {
    "zip": 62930,
    "lat": 37.83823,
    "lng": -88.44115,
    "state_id": "IL"
  },
  {
    "zip": 62931,
    "lat": 37.52851,
    "lng": -88.28679,
    "state_id": "IL"
  },
  {
    "zip": 62932,
    "lat": 37.90344,
    "lng": -89.21404,
    "state_id": "IL"
  },
  {
    "zip": 62933,
    "lat": 37.77253,
    "lng": -89.02483,
    "state_id": "IL"
  },
  {
    "zip": 62934,
    "lat": 37.70452,
    "lng": -88.36433,
    "state_id": "IL"
  },
  {
    "zip": 62935,
    "lat": 37.8412,
    "lng": -88.63383,
    "state_id": "IL"
  },
  {
    "zip": 62938,
    "lat": 37.38021,
    "lng": -88.54268,
    "state_id": "IL"
  },
  {
    "zip": 62939,
    "lat": 37.5512,
    "lng": -88.98149,
    "state_id": "IL"
  },
  {
    "zip": 62940,
    "lat": 37.73167,
    "lng": -89.47209,
    "state_id": "IL"
  },
  {
    "zip": 62941,
    "lat": 37.24446,
    "lng": -88.98073,
    "state_id": "IL"
  },
  {
    "zip": 62942,
    "lat": 37.64347,
    "lng": -89.46919,
    "state_id": "IL"
  },
  {
    "zip": 62943,
    "lat": 37.35639,
    "lng": -88.74774,
    "state_id": "IL"
  },
  {
    "zip": 62946,
    "lat": 37.70724,
    "lng": -88.53817,
    "state_id": "IL"
  },
  {
    "zip": 62947,
    "lat": 37.5634,
    "lng": -88.44009,
    "state_id": "IL"
  },
  {
    "zip": 62948,
    "lat": 37.81689,
    "lng": -89.02951,
    "state_id": "IL"
  },
  {
    "zip": 62949,
    "lat": 37.82803,
    "lng": -89.14442,
    "state_id": "IL"
  },
  {
    "zip": 62950,
    "lat": 37.75545,
    "lng": -89.5604,
    "state_id": "IL"
  },
  {
    "zip": 62951,
    "lat": 37.82354,
    "lng": -88.92922,
    "state_id": "IL"
  },
  {
    "zip": 62952,
    "lat": 37.41823,
    "lng": -89.33724,
    "state_id": "IL"
  },
  {
    "zip": 62953,
    "lat": 37.20837,
    "lng": -88.84999,
    "state_id": "IL"
  },
  {
    "zip": 62954,
    "lat": 37.69118,
    "lng": -88.27478,
    "state_id": "IL"
  },
  {
    "zip": 62956,
    "lat": 37.27993,
    "lng": -88.94232,
    "state_id": "IL"
  },
  {
    "zip": 62957,
    "lat": 37.30789,
    "lng": -89.42661,
    "state_id": "IL"
  },
  {
    "zip": 62958,
    "lat": 37.59975,
    "lng": -89.16582,
    "state_id": "IL"
  },
  {
    "zip": 62959,
    "lat": 37.71804,
    "lng": -88.90775,
    "state_id": "IL"
  },
  {
    "zip": 62960,
    "lat": 37.22608,
    "lng": -88.71072,
    "state_id": "IL"
  },
  {
    "zip": 62961,
    "lat": 37.34028,
    "lng": -89.25519,
    "state_id": "IL"
  },
  {
    "zip": 62962,
    "lat": 37.07376,
    "lng": -89.33498,
    "state_id": "IL"
  },
  {
    "zip": 62963,
    "lat": 37.09396,
    "lng": -89.16201,
    "state_id": "IL"
  },
  {
    "zip": 62964,
    "lat": 37.12854,
    "lng": -89.21531,
    "state_id": "IL"
  },
  {
    "zip": 62965,
    "lat": 37.76708,
    "lng": -88.52022,
    "state_id": "IL"
  },
  {
    "zip": 62966,
    "lat": 37.77508,
    "lng": -89.35107,
    "state_id": "IL"
  },
  {
    "zip": 62967,
    "lat": 37.58044,
    "lng": -88.7518,
    "state_id": "IL"
  },
  {
    "zip": 62969,
    "lat": 37.15795,
    "lng": -89.34349,
    "state_id": "IL"
  },
  {
    "zip": 62970,
    "lat": 37.21476,
    "lng": -89.09964,
    "state_id": "IL"
  },
  {
    "zip": 62972,
    "lat": 37.53702,
    "lng": -88.79131,
    "state_id": "IL"
  },
  {
    "zip": 62974,
    "lat": 37.77736,
    "lng": -88.78463,
    "state_id": "IL"
  },
  {
    "zip": 62975,
    "lat": 37.63757,
    "lng": -89.37776,
    "state_id": "IL"
  },
  {
    "zip": 62976,
    "lat": 37.21898,
    "lng": -89.20552,
    "state_id": "IL"
  },
  {
    "zip": 62977,
    "lat": 37.85397,
    "lng": -88.5449,
    "state_id": "IL"
  },
  {
    "zip": 62979,
    "lat": 37.8161,
    "lng": -88.19181,
    "state_id": "IL"
  },
  {
    "zip": 62982,
    "lat": 37.42811,
    "lng": -88.35961,
    "state_id": "IL"
  },
  {
    "zip": 62983,
    "lat": 37.90497,
    "lng": -89.11721,
    "state_id": "IL"
  },
  {
    "zip": 62984,
    "lat": 37.71038,
    "lng": -88.15479,
    "state_id": "IL"
  },
  {
    "zip": 62985,
    "lat": 37.46085,
    "lng": -88.68961,
    "state_id": "IL"
  },
  {
    "zip": 62987,
    "lat": 37.6168,
    "lng": -88.6542,
    "state_id": "IL"
  },
  {
    "zip": 62988,
    "lat": 37.24392,
    "lng": -89.29752,
    "state_id": "IL"
  },
  {
    "zip": 62990,
    "lat": 37.23092,
    "lng": -89.40643,
    "state_id": "IL"
  },
  {
    "zip": 62992,
    "lat": 37.27901,
    "lng": -89.16676,
    "state_id": "IL"
  },
  {
    "zip": 62994,
    "lat": 37.90776,
    "lng": -89.34075,
    "state_id": "IL"
  },
  {
    "zip": 62995,
    "lat": 37.42602,
    "lng": -88.87524,
    "state_id": "IL"
  },
  {
    "zip": 62996,
    "lat": 37.16307,
    "lng": -89.1543,
    "state_id": "IL"
  },
  {
    "zip": 62997,
    "lat": 37.98225,
    "lng": -89.59038,
    "state_id": "IL"
  },
  {
    "zip": 62998,
    "lat": 37.51587,
    "lng": -89.4474,
    "state_id": "IL"
  },
  {
    "zip": 62999,
    "lat": 37.89171,
    "lng": -89.05259,
    "state_id": "IL"
  },
  {
    "zip": 46001,
    "lat": 40.25053,
    "lng": -85.6588,
    "state_id": "IN"
  },
  {
    "zip": 46011,
    "lat": 40.12734,
    "lng": -85.76167,
    "state_id": "IN"
  },
  {
    "zip": 46012,
    "lat": 40.14998,
    "lng": -85.62525,
    "state_id": "IN"
  },
  {
    "zip": 46013,
    "lat": 40.04912,
    "lng": -85.67827,
    "state_id": "IN"
  },
  {
    "zip": 46016,
    "lat": 40.09784,
    "lng": -85.68144,
    "state_id": "IN"
  },
  {
    "zip": 46017,
    "lat": 40.07419,
    "lng": -85.60695,
    "state_id": "IN"
  },
  {
    "zip": 46030,
    "lat": 40.17211,
    "lng": -86.00741,
    "state_id": "IN"
  },
  {
    "zip": 46031,
    "lat": 40.21028,
    "lng": -86.01901,
    "state_id": "IN"
  },
  {
    "zip": 46032,
    "lat": 39.96582,
    "lng": -86.17109,
    "state_id": "IN"
  },
  {
    "zip": 46033,
    "lat": 39.97888,
    "lng": -86.08543,
    "state_id": "IN"
  },
  {
    "zip": 46034,
    "lat": 40.12973,
    "lng": -86.03816,
    "state_id": "IN"
  },
  {
    "zip": 46035,
    "lat": 40.19001,
    "lng": -86.67809,
    "state_id": "IN"
  },
  {
    "zip": 46036,
    "lat": 40.29519,
    "lng": -85.83109,
    "state_id": "IN"
  },
  {
    "zip": 46037,
    "lat": 39.96035,
    "lng": -85.94715,
    "state_id": "IN"
  },
  {
    "zip": 46038,
    "lat": 39.96739,
    "lng": -86.0168,
    "state_id": "IN"
  },
  {
    "zip": 46039,
    "lat": 40.36948,
    "lng": -86.3123,
    "state_id": "IN"
  },
  {
    "zip": 46040,
    "lat": 39.92744,
    "lng": -85.83548,
    "state_id": "IN"
  },
  {
    "zip": 46041,
    "lat": 40.29751,
    "lng": -86.49189,
    "state_id": "IN"
  },
  {
    "zip": 46044,
    "lat": 40.20944,
    "lng": -85.79019,
    "state_id": "IN"
  },
  {
    "zip": 46045,
    "lat": 40.28877,
    "lng": -86.15019,
    "state_id": "IN"
  },
  {
    "zip": 46047,
    "lat": 40.28413,
    "lng": -85.94735,
    "state_id": "IN"
  },
  {
    "zip": 46048,
    "lat": 39.95551,
    "lng": -85.80181,
    "state_id": "IN"
  },
  {
    "zip": 46049,
    "lat": 40.29755,
    "lng": -86.22067,
    "state_id": "IN"
  },
  {
    "zip": 46050,
    "lat": 40.20445,
    "lng": -86.34887,
    "state_id": "IN"
  },
  {
    "zip": 46051,
    "lat": 40.05795,
    "lng": -85.83731,
    "state_id": "IN"
  },
  {
    "zip": 46052,
    "lat": 40.04483,
    "lng": -86.45988,
    "state_id": "IN"
  },
  {
    "zip": 46055,
    "lat": 39.88557,
    "lng": -85.90443,
    "state_id": "IN"
  },
  {
    "zip": 46056,
    "lat": 39.97523,
    "lng": -85.61202,
    "state_id": "IN"
  },
  {
    "zip": 46057,
    "lat": 40.33629,
    "lng": -86.37735,
    "state_id": "IN"
  },
  {
    "zip": 46058,
    "lat": 40.36118,
    "lng": -86.64734,
    "state_id": "IN"
  },
  {
    "zip": 46060,
    "lat": 40.06598,
    "lng": -85.93091,
    "state_id": "IN"
  },
  {
    "zip": 46062,
    "lat": 40.06176,
    "lng": -86.05544,
    "state_id": "IN"
  },
  {
    "zip": 46063,
    "lat": 40.27045,
    "lng": -85.72403,
    "state_id": "IN"
  },
  {
    "zip": 46064,
    "lat": 39.98347,
    "lng": -85.74743,
    "state_id": "IN"
  },
  {
    "zip": 46065,
    "lat": 40.42452,
    "lng": -86.61327,
    "state_id": "IN"
  },
  {
    "zip": 46068,
    "lat": 40.37695,
    "lng": -86.11583,
    "state_id": "IN"
  },
  {
    "zip": 46069,
    "lat": 40.13938,
    "lng": -86.2292,
    "state_id": "IN"
  },
  {
    "zip": 46070,
    "lat": 40.34199,
    "lng": -85.66022,
    "state_id": "IN"
  },
  {
    "zip": 46071,
    "lat": 40.12014,
    "lng": -86.60693,
    "state_id": "IN"
  },
  {
    "zip": 46072,
    "lat": 40.28611,
    "lng": -86.06745,
    "state_id": "IN"
  },
  {
    "zip": 46074,
    "lat": 40.03627,
    "lng": -86.1758,
    "state_id": "IN"
  },
  {
    "zip": 46075,
    "lat": 40.02527,
    "lng": -86.33489,
    "state_id": "IN"
  },
  {
    "zip": 46076,
    "lat": 40.36264,
    "lng": -85.93408,
    "state_id": "IN"
  },
  {
    "zip": 46077,
    "lat": 39.98434,
    "lng": -86.28387,
    "state_id": "IN"
  },
  {
    "zip": 46103,
    "lat": 39.68837,
    "lng": -86.61333,
    "state_id": "IN"
  },
  {
    "zip": 46104,
    "lat": 39.66102,
    "lng": -85.59541,
    "state_id": "IN"
  },
  {
    "zip": 46105,
    "lat": 39.76418,
    "lng": -86.81056,
    "state_id": "IN"
  },
  {
    "zip": 46106,
    "lat": 39.51304,
    "lng": -86.20537,
    "state_id": "IN"
  },
  {
    "zip": 46107,
    "lat": 39.71654,
    "lng": -86.09147,
    "state_id": "IN"
  },
  {
    "zip": 46110,
    "lat": 39.56337,
    "lng": -85.91627,
    "state_id": "IN"
  },
  {
    "zip": 46111,
    "lat": 39.53853,
    "lng": -86.36994,
    "state_id": "IN"
  },
  {
    "zip": 46112,
    "lat": 39.86506,
    "lng": -86.38258,
    "state_id": "IN"
  },
  {
    "zip": 46113,
    "lat": 39.63282,
    "lng": -86.30532,
    "state_id": "IN"
  },
  {
    "zip": 46115,
    "lat": 39.73911,
    "lng": -85.56627,
    "state_id": "IN"
  },
  {
    "zip": 46117,
    "lat": 39.81717,
    "lng": -85.61759,
    "state_id": "IN"
  },
  {
    "zip": 46118,
    "lat": 39.65377,
    "lng": -86.52221,
    "state_id": "IN"
  },
  {
    "zip": 46120,
    "lat": 39.5132,
    "lng": -86.78701,
    "state_id": "IN"
  },
  {
    "zip": 46121,
    "lat": 39.67722,
    "lng": -86.67208,
    "state_id": "IN"
  },
  {
    "zip": 46122,
    "lat": 39.76859,
    "lng": -86.54963,
    "state_id": "IN"
  },
  {
    "zip": 46123,
    "lat": 39.76291,
    "lng": -86.39961,
    "state_id": "IN"
  },
  {
    "zip": 46124,
    "lat": 39.37335,
    "lng": -85.92746,
    "state_id": "IN"
  },
  {
    "zip": 46125,
    "lat": 39.52146,
    "lng": -86.64156,
    "state_id": "IN"
  },
  {
    "zip": 46126,
    "lat": 39.62051,
    "lng": -85.88837,
    "state_id": "IN"
  },
  {
    "zip": 46127,
    "lat": 39.71195,
    "lng": -85.31219,
    "state_id": "IN"
  },
  {
    "zip": 46128,
    "lat": 39.65568,
    "lng": -86.74283,
    "state_id": "IN"
  },
  {
    "zip": 46130,
    "lat": 39.67543,
    "lng": -85.83594,
    "state_id": "IN"
  },
  {
    "zip": 46131,
    "lat": 39.47213,
    "lng": -86.04308,
    "state_id": "IN"
  },
  {
    "zip": 46133,
    "lat": 39.59162,
    "lng": -85.29965,
    "state_id": "IN"
  },
  {
    "zip": 46135,
    "lat": 39.65837,
    "lng": -86.89231,
    "state_id": "IN"
  },
  {
    "zip": 46140,
    "lat": 39.80339,
    "lng": -85.77069,
    "state_id": "IN"
  },
  {
    "zip": 46142,
    "lat": 39.62021,
    "lng": -86.17653,
    "state_id": "IN"
  },
  {
    "zip": 46143,
    "lat": 39.59509,
    "lng": -86.11657,
    "state_id": "IN"
  },
  {
    "zip": 46144,
    "lat": 39.65518,
    "lng": -85.64729,
    "state_id": "IN"
  },
  {
    "zip": 46146,
    "lat": 39.5856,
    "lng": -85.56844,
    "state_id": "IN"
  },
  {
    "zip": 46147,
    "lat": 39.97068,
    "lng": -86.62604,
    "state_id": "IN"
  },
  {
    "zip": 46148,
    "lat": 39.80882,
    "lng": -85.51287,
    "state_id": "IN"
  },
  {
    "zip": 46149,
    "lat": 39.88594,
    "lng": -86.55754,
    "state_id": "IN"
  },
  {
    "zip": 46150,
    "lat": 39.54485,
    "lng": -85.60236,
    "state_id": "IN"
  },
  {
    "zip": 46151,
    "lat": 39.44797,
    "lng": -86.43051,
    "state_id": "IN"
  },
  {
    "zip": 46155,
    "lat": 39.7435,
    "lng": -85.42986,
    "state_id": "IN"
  },
  {
    "zip": 46156,
    "lat": 39.48417,
    "lng": -85.4819,
    "state_id": "IN"
  },
  {
    "zip": 46157,
    "lat": 39.55463,
    "lng": -86.52815,
    "state_id": "IN"
  },
  {
    "zip": 46158,
    "lat": 39.58224,
    "lng": -86.37492,
    "state_id": "IN"
  },
  {
    "zip": 46160,
    "lat": 39.35229,
    "lng": -86.27077,
    "state_id": "IN"
  },
  {
    "zip": 46161,
    "lat": 39.67041,
    "lng": -85.69456,
    "state_id": "IN"
  },
  {
    "zip": 46162,
    "lat": 39.55081,
    "lng": -85.95348,
    "state_id": "IN"
  },
  {
    "zip": 46163,
    "lat": 39.72867,
    "lng": -85.90342,
    "state_id": "IN"
  },
  {
    "zip": 46164,
    "lat": 39.32195,
    "lng": -86.11501,
    "state_id": "IN"
  },
  {
    "zip": 46165,
    "lat": 39.84707,
    "lng": -86.64563,
    "state_id": "IN"
  },
  {
    "zip": 46166,
    "lat": 39.43205,
    "lng": -86.58727,
    "state_id": "IN"
  },
  {
    "zip": 46167,
    "lat": 39.87543,
    "lng": -86.4706,
    "state_id": "IN"
  },
  {
    "zip": 46168,
    "lat": 39.68923,
    "lng": -86.392,
    "state_id": "IN"
  },
  {
    "zip": 46171,
    "lat": 39.53596,
    "lng": -86.9689,
    "state_id": "IN"
  },
  {
    "zip": 46172,
    "lat": 39.8259,
    "lng": -86.81782,
    "state_id": "IN"
  },
  {
    "zip": 46173,
    "lat": 39.60273,
    "lng": -85.42902,
    "state_id": "IN"
  },
  {
    "zip": 46175,
    "lat": 39.82156,
    "lng": -86.97354,
    "state_id": "IN"
  },
  {
    "zip": 46176,
    "lat": 39.52751,
    "lng": -85.77396,
    "state_id": "IN"
  },
  {
    "zip": 46180,
    "lat": 39.59816,
    "lng": -86.61796,
    "state_id": "IN"
  },
  {
    "zip": 46181,
    "lat": 39.38059,
    "lng": -86.16262,
    "state_id": "IN"
  },
  {
    "zip": 46182,
    "lat": 39.46133,
    "lng": -85.66926,
    "state_id": "IN"
  },
  {
    "zip": 46183,
    "lat": 39.6528,
    "lng": -86.2816,
    "state_id": "IN"
  },
  {
    "zip": 46184,
    "lat": 39.5611,
    "lng": -86.0722,
    "state_id": "IN"
  },
  {
    "zip": 46186,
    "lat": 39.8938,
    "lng": -85.64452,
    "state_id": "IN"
  },
  {
    "zip": 46201,
    "lat": 39.77421,
    "lng": -86.10917,
    "state_id": "IN"
  },
  {
    "zip": 46202,
    "lat": 39.78408,
    "lng": -86.16334,
    "state_id": "IN"
  },
  {
    "zip": 46203,
    "lat": 39.73761,
    "lng": -86.0969,
    "state_id": "IN"
  },
  {
    "zip": 46204,
    "lat": 39.7715,
    "lng": -86.15699,
    "state_id": "IN"
  },
  {
    "zip": 46205,
    "lat": 39.8282,
    "lng": -86.13483,
    "state_id": "IN"
  },
  {
    "zip": 46208,
    "lat": 39.8238,
    "lng": -86.17236,
    "state_id": "IN"
  },
  {
    "zip": 46214,
    "lat": 39.79174,
    "lng": -86.28961,
    "state_id": "IN"
  },
  {
    "zip": 46216,
    "lat": 39.86626,
    "lng": -86.01148,
    "state_id": "IN"
  },
  {
    "zip": 46217,
    "lat": 39.67272,
    "lng": -86.19394,
    "state_id": "IN"
  },
  {
    "zip": 46218,
    "lat": 39.80727,
    "lng": -86.09975,
    "state_id": "IN"
  },
  {
    "zip": 46219,
    "lat": 39.78279,
    "lng": -86.04436,
    "state_id": "IN"
  },
  {
    "zip": 46220,
    "lat": 39.86773,
    "lng": -86.10818,
    "state_id": "IN"
  },
  {
    "zip": 46221,
    "lat": 39.69207,
    "lng": -86.23781,
    "state_id": "IN"
  },
  {
    "zip": 46222,
    "lat": 39.79109,
    "lng": -86.21493,
    "state_id": "IN"
  },
  {
    "zip": 46224,
    "lat": 39.79543,
    "lng": -86.2565,
    "state_id": "IN"
  },
  {
    "zip": 46225,
    "lat": 39.74048,
    "lng": -86.16312,
    "state_id": "IN"
  },
  {
    "zip": 46226,
    "lat": 39.83851,
    "lng": -86.05162,
    "state_id": "IN"
  },
  {
    "zip": 46227,
    "lat": 39.67615,
    "lng": -86.13149,
    "state_id": "IN"
  },
  {
    "zip": 46228,
    "lat": 39.84886,
    "lng": -86.20019,
    "state_id": "IN"
  },
  {
    "zip": 46229,
    "lat": 39.78856,
    "lng": -85.97795,
    "state_id": "IN"
  },
  {
    "zip": 46231,
    "lat": 39.71581,
    "lng": -86.32181,
    "state_id": "IN"
  },
  {
    "zip": 46234,
    "lat": 39.81252,
    "lng": -86.32566,
    "state_id": "IN"
  },
  {
    "zip": 46235,
    "lat": 39.83774,
    "lng": -85.97634,
    "state_id": "IN"
  },
  {
    "zip": 46236,
    "lat": 39.89167,
    "lng": -85.96847,
    "state_id": "IN"
  },
  {
    "zip": 46237,
    "lat": 39.67293,
    "lng": -86.0757,
    "state_id": "IN"
  },
  {
    "zip": 46239,
    "lat": 39.72326,
    "lng": -86.00032,
    "state_id": "IN"
  },
  {
    "zip": 46240,
    "lat": 39.90687,
    "lng": -86.12361,
    "state_id": "IN"
  },
  {
    "zip": 46241,
    "lat": 39.72567,
    "lng": -86.26606,
    "state_id": "IN"
  },
  {
    "zip": 46250,
    "lat": 39.9041,
    "lng": -86.06806,
    "state_id": "IN"
  },
  {
    "zip": 46254,
    "lat": 39.84896,
    "lng": -86.27204,
    "state_id": "IN"
  },
  {
    "zip": 46256,
    "lat": 39.90684,
    "lng": -86.01179,
    "state_id": "IN"
  },
  {
    "zip": 46259,
    "lat": 39.65177,
    "lng": -85.98712,
    "state_id": "IN"
  },
  {
    "zip": 46260,
    "lat": 39.89776,
    "lng": -86.17968,
    "state_id": "IN"
  },
  {
    "zip": 46268,
    "lat": 39.89937,
    "lng": -86.23333,
    "state_id": "IN"
  },
  {
    "zip": 46278,
    "lat": 39.89518,
    "lng": -86.29787,
    "state_id": "IN"
  },
  {
    "zip": 46280,
    "lat": 39.93713,
    "lng": -86.11896,
    "state_id": "IN"
  },
  {
    "zip": 46290,
    "lat": 39.93465,
    "lng": -86.16326,
    "state_id": "IN"
  },
  {
    "zip": 46301,
    "lat": 41.68404,
    "lng": -86.98106,
    "state_id": "IN"
  },
  {
    "zip": 46303,
    "lat": 41.37126,
    "lng": -87.47638,
    "state_id": "IN"
  },
  {
    "zip": 46304,
    "lat": 41.61095,
    "lng": -87.04594,
    "state_id": "IN"
  },
  {
    "zip": 46307,
    "lat": 41.4026,
    "lng": -87.32768,
    "state_id": "IN"
  },
  {
    "zip": 46310,
    "lat": 41.18409,
    "lng": -87.23174,
    "state_id": "IN"
  },
  {
    "zip": 46311,
    "lat": 41.46533,
    "lng": -87.50897,
    "state_id": "IN"
  },
  {
    "zip": 46312,
    "lat": 41.64701,
    "lng": -87.45443,
    "state_id": "IN"
  },
  {
    "zip": 46319,
    "lat": 41.52585,
    "lng": -87.42228,
    "state_id": "IN"
  },
  {
    "zip": 46320,
    "lat": 41.63398,
    "lng": -87.50385,
    "state_id": "IN"
  },
  {
    "zip": 46321,
    "lat": 41.54687,
    "lng": -87.50395,
    "state_id": "IN"
  },
  {
    "zip": 46322,
    "lat": 41.54827,
    "lng": -87.45875,
    "state_id": "IN"
  },
  {
    "zip": 46323,
    "lat": 41.58967,
    "lng": -87.45328,
    "state_id": "IN"
  },
  {
    "zip": 46324,
    "lat": 41.58307,
    "lng": -87.50169,
    "state_id": "IN"
  },
  {
    "zip": 46327,
    "lat": 41.63866,
    "lng": -87.50687,
    "state_id": "IN"
  },
  {
    "zip": 46340,
    "lat": 41.38418,
    "lng": -86.76905,
    "state_id": "IN"
  },
  {
    "zip": 46341,
    "lat": 41.31358,
    "lng": -87.21367,
    "state_id": "IN"
  },
  {
    "zip": 46342,
    "lat": 41.51807,
    "lng": -87.24522,
    "state_id": "IN"
  },
  {
    "zip": 46345,
    "lat": 41.52717,
    "lng": -86.69837,
    "state_id": "IN"
  },
  {
    "zip": 46346,
    "lat": 41.47224,
    "lng": -86.69244,
    "state_id": "IN"
  },
  {
    "zip": 46347,
    "lat": 41.30497,
    "lng": -87.00735,
    "state_id": "IN"
  },
  {
    "zip": 46348,
    "lat": 41.31032,
    "lng": -86.86193,
    "state_id": "IN"
  },
  {
    "zip": 46349,
    "lat": 41.10783,
    "lng": -87.42179,
    "state_id": "IN"
  },
  {
    "zip": 46350,
    "lat": 41.60738,
    "lng": -86.72156,
    "state_id": "IN"
  },
  {
    "zip": 46356,
    "lat": 41.25996,
    "lng": -87.41419,
    "state_id": "IN"
  },
  {
    "zip": 46360,
    "lat": 41.68703,
    "lng": -86.8689,
    "state_id": "IN"
  },
  {
    "zip": 46365,
    "lat": 41.60762,
    "lng": -86.54079,
    "state_id": "IN"
  },
  {
    "zip": 46366,
    "lat": 41.21533,
    "lng": -86.76793,
    "state_id": "IN"
  },
  {
    "zip": 46368,
    "lat": 41.58677,
    "lng": -87.18035,
    "state_id": "IN"
  },
  {
    "zip": 46371,
    "lat": 41.68003,
    "lng": -86.60071,
    "state_id": "IN"
  },
  {
    "zip": 46373,
    "lat": 41.44753,
    "lng": -87.46883,
    "state_id": "IN"
  },
  {
    "zip": 46374,
    "lat": 41.20593,
    "lng": -86.89612,
    "state_id": "IN"
  },
  {
    "zip": 46375,
    "lat": 41.49213,
    "lng": -87.44828,
    "state_id": "IN"
  },
  {
    "zip": 46376,
    "lat": 41.18465,
    "lng": -87.47639,
    "state_id": "IN"
  },
  {
    "zip": 46377,
    "lat": 41.1916,
    "lng": -87.3435,
    "state_id": "IN"
  },
  {
    "zip": 46379,
    "lat": 41.16789,
    "lng": -87.44002,
    "state_id": "IN"
  },
  {
    "zip": 46381,
    "lat": 41.16856,
    "lng": -87.32462,
    "state_id": "IN"
  },
  {
    "zip": 46382,
    "lat": 41.46897,
    "lng": -86.76282,
    "state_id": "IN"
  },
  {
    "zip": 46383,
    "lat": 41.45914,
    "lng": -87.00339,
    "state_id": "IN"
  },
  {
    "zip": 46385,
    "lat": 41.4619,
    "lng": -87.12439,
    "state_id": "IN"
  },
  {
    "zip": 46390,
    "lat": 41.41682,
    "lng": -86.87487,
    "state_id": "IN"
  },
  {
    "zip": 46391,
    "lat": 41.5454,
    "lng": -86.91498,
    "state_id": "IN"
  },
  {
    "zip": 46392,
    "lat": 41.18329,
    "lng": -87.04763,
    "state_id": "IN"
  },
  {
    "zip": 46393,
    "lat": 41.50823,
    "lng": -87.1776,
    "state_id": "IN"
  },
  {
    "zip": 46394,
    "lat": 41.67388,
    "lng": -87.49358,
    "state_id": "IN"
  },
  {
    "zip": 46402,
    "lat": 41.59942,
    "lng": -87.33082,
    "state_id": "IN"
  },
  {
    "zip": 46403,
    "lat": 41.6047,
    "lng": -87.26342,
    "state_id": "IN"
  },
  {
    "zip": 46404,
    "lat": 41.58406,
    "lng": -87.37468,
    "state_id": "IN"
  },
  {
    "zip": 46405,
    "lat": 41.57448,
    "lng": -87.26211,
    "state_id": "IN"
  },
  {
    "zip": 46406,
    "lat": 41.60298,
    "lng": -87.408,
    "state_id": "IN"
  },
  {
    "zip": 46407,
    "lat": 41.57849,
    "lng": -87.33024,
    "state_id": "IN"
  },
  {
    "zip": 46408,
    "lat": 41.54464,
    "lng": -87.36943,
    "state_id": "IN"
  },
  {
    "zip": 46409,
    "lat": 41.54838,
    "lng": -87.32422,
    "state_id": "IN"
  },
  {
    "zip": 46410,
    "lat": 41.4812,
    "lng": -87.33361,
    "state_id": "IN"
  },
  {
    "zip": 46501,
    "lat": 41.22608,
    "lng": -86.24792,
    "state_id": "IN"
  },
  {
    "zip": 46502,
    "lat": 41.25938,
    "lng": -85.97627,
    "state_id": "IN"
  },
  {
    "zip": 46504,
    "lat": 41.29996,
    "lng": -86.11093,
    "state_id": "IN"
  },
  {
    "zip": 46506,
    "lat": 41.46171,
    "lng": -86.16962,
    "state_id": "IN"
  },
  {
    "zip": 46507,
    "lat": 41.72057,
    "lng": -85.81751,
    "state_id": "IN"
  },
  {
    "zip": 46508,
    "lat": 41.15083,
    "lng": -85.97616,
    "state_id": "IN"
  },
  {
    "zip": 46510,
    "lat": 41.11969,
    "lng": -85.87329,
    "state_id": "IN"
  },
  {
    "zip": 46511,
    "lat": 41.22006,
    "lng": -86.42695,
    "state_id": "IN"
  },
  {
    "zip": 46514,
    "lat": 41.72343,
    "lng": -85.97468,
    "state_id": "IN"
  },
  {
    "zip": 46516,
    "lat": 41.67571,
    "lng": -85.94449,
    "state_id": "IN"
  },
  {
    "zip": 46517,
    "lat": 41.62474,
    "lng": -85.99698,
    "state_id": "IN"
  },
  {
    "zip": 46524,
    "lat": 41.29718,
    "lng": -86.02111,
    "state_id": "IN"
  },
  {
    "zip": 46526,
    "lat": 41.55314,
    "lng": -85.88346,
    "state_id": "IN"
  },
  {
    "zip": 46528,
    "lat": 41.60607,
    "lng": -85.78552,
    "state_id": "IN"
  },
  {
    "zip": 46530,
    "lat": 41.73988,
    "lng": -86.12742,
    "state_id": "IN"
  },
  {
    "zip": 46531,
    "lat": 41.35616,
    "lng": -86.51481,
    "state_id": "IN"
  },
  {
    "zip": 46532,
    "lat": 41.41826,
    "lng": -86.61872,
    "state_id": "IN"
  },
  {
    "zip": 46534,
    "lat": 41.28578,
    "lng": -86.61667,
    "state_id": "IN"
  },
  {
    "zip": 46536,
    "lat": 41.51836,
    "lng": -86.28482,
    "state_id": "IN"
  },
  {
    "zip": 46537,
    "lat": 41.45987,
    "lng": -86.31025,
    "state_id": "IN"
  },
  {
    "zip": 46538,
    "lat": 41.32682,
    "lng": -85.82156,
    "state_id": "IN"
  },
  {
    "zip": 46539,
    "lat": 41.16718,
    "lng": -86.02419,
    "state_id": "IN"
  },
  {
    "zip": 46540,
    "lat": 41.67669,
    "lng": -85.69838,
    "state_id": "IN"
  },
  {
    "zip": 46542,
    "lat": 41.39179,
    "lng": -85.87439,
    "state_id": "IN"
  },
  {
    "zip": 46543,
    "lat": 41.53519,
    "lng": -85.67212,
    "state_id": "IN"
  },
  {
    "zip": 46544,
    "lat": 41.61693,
    "lng": -86.13893,
    "state_id": "IN"
  },
  {
    "zip": 46545,
    "lat": 41.69301,
    "lng": -86.14644,
    "state_id": "IN"
  },
  {
    "zip": 46550,
    "lat": 41.44618,
    "lng": -86.00019,
    "state_id": "IN"
  },
  {
    "zip": 46552,
    "lat": 41.70627,
    "lng": -86.48468,
    "state_id": "IN"
  },
  {
    "zip": 46553,
    "lat": 41.47954,
    "lng": -85.84927,
    "state_id": "IN"
  },
  {
    "zip": 46554,
    "lat": 41.56256,
    "lng": -86.41897,
    "state_id": "IN"
  },
  {
    "zip": 46555,
    "lat": 41.32933,
    "lng": -85.69044,
    "state_id": "IN"
  },
  {
    "zip": 46556,
    "lat": 41.70662,
    "lng": -86.25128,
    "state_id": "IN"
  },
  {
    "zip": 46561,
    "lat": 41.66379,
    "lng": -86.07526,
    "state_id": "IN"
  },
  {
    "zip": 46562,
    "lat": 41.2105,
    "lng": -85.69774,
    "state_id": "IN"
  },
  {
    "zip": 46563,
    "lat": 41.35629,
    "lng": -86.32674,
    "state_id": "IN"
  },
  {
    "zip": 46565,
    "lat": 41.69266,
    "lng": -85.58044,
    "state_id": "IN"
  },
  {
    "zip": 46567,
    "lat": 41.41348,
    "lng": -85.73106,
    "state_id": "IN"
  },
  {
    "zip": 46570,
    "lat": 41.20674,
    "lng": -86.12,
    "state_id": "IN"
  },
  {
    "zip": 46571,
    "lat": 41.56849,
    "lng": -85.54557,
    "state_id": "IN"
  },
  {
    "zip": 46573,
    "lat": 41.53986,
    "lng": -86.06023,
    "state_id": "IN"
  },
  {
    "zip": 46574,
    "lat": 41.47378,
    "lng": -86.48799,
    "state_id": "IN"
  },
  {
    "zip": 46580,
    "lat": 41.20773,
    "lng": -85.87106,
    "state_id": "IN"
  },
  {
    "zip": 46582,
    "lat": 41.28414,
    "lng": -85.85464,
    "state_id": "IN"
  },
  {
    "zip": 46590,
    "lat": 41.21589,
    "lng": -85.80893,
    "state_id": "IN"
  },
  {
    "zip": 46595,
    "lat": 41.52632,
    "lng": -86.16651,
    "state_id": "IN"
  },
  {
    "zip": 46601,
    "lat": 41.67007,
    "lng": -86.25319,
    "state_id": "IN"
  },
  {
    "zip": 46613,
    "lat": 41.6548,
    "lng": -86.25874,
    "state_id": "IN"
  },
  {
    "zip": 46614,
    "lat": 41.60336,
    "lng": -86.27873,
    "state_id": "IN"
  },
  {
    "zip": 46615,
    "lat": 41.6747,
    "lng": -86.21161,
    "state_id": "IN"
  },
  {
    "zip": 46616,
    "lat": 41.69807,
    "lng": -86.26654,
    "state_id": "IN"
  },
  {
    "zip": 46617,
    "lat": 41.68444,
    "lng": -86.23493,
    "state_id": "IN"
  },
  {
    "zip": 46619,
    "lat": 41.66009,
    "lng": -86.35098,
    "state_id": "IN"
  },
  {
    "zip": 46628,
    "lat": 41.72133,
    "lng": -86.33639,
    "state_id": "IN"
  },
  {
    "zip": 46635,
    "lat": 41.71488,
    "lng": -86.20853,
    "state_id": "IN"
  },
  {
    "zip": 46637,
    "lat": 41.73375,
    "lng": -86.24157,
    "state_id": "IN"
  },
  {
    "zip": 46701,
    "lat": 41.36059,
    "lng": -85.42992,
    "state_id": "IN"
  },
  {
    "zip": 46702,
    "lat": 40.82814,
    "lng": -85.61611,
    "state_id": "IN"
  },
  {
    "zip": 46703,
    "lat": 41.65273,
    "lng": -85.00801,
    "state_id": "IN"
  },
  {
    "zip": 46704,
    "lat": 41.10407,
    "lng": -85.29276,
    "state_id": "IN"
  },
  {
    "zip": 46705,
    "lat": 41.51828,
    "lng": -85.06304,
    "state_id": "IN"
  },
  {
    "zip": 46706,
    "lat": 41.34277,
    "lng": -85.03938,
    "state_id": "IN"
  },
  {
    "zip": 46710,
    "lat": 41.35057,
    "lng": -85.25134,
    "state_id": "IN"
  },
  {
    "zip": 46711,
    "lat": 40.66372,
    "lng": -84.92555,
    "state_id": "IN"
  },
  {
    "zip": 46714,
    "lat": 40.71846,
    "lng": -85.17148,
    "state_id": "IN"
  },
  {
    "zip": 46721,
    "lat": 41.42359,
    "lng": -84.87445,
    "state_id": "IN"
  },
  {
    "zip": 46723,
    "lat": 41.23982,
    "lng": -85.32565,
    "state_id": "IN"
  },
  {
    "zip": 46725,
    "lat": 41.14913,
    "lng": -85.47486,
    "state_id": "IN"
  },
  {
    "zip": 46730,
    "lat": 41.45324,
    "lng": -85.15548,
    "state_id": "IN"
  },
  {
    "zip": 46731,
    "lat": 40.79789,
    "lng": -85.10274,
    "state_id": "IN"
  },
  {
    "zip": 46732,
    "lat": 41.37886,
    "lng": -85.62355,
    "state_id": "IN"
  },
  {
    "zip": 46733,
    "lat": 40.82852,
    "lng": -84.9401,
    "state_id": "IN"
  },
  {
    "zip": 46737,
    "lat": 41.72152,
    "lng": -84.92385,
    "state_id": "IN"
  },
  {
    "zip": 46738,
    "lat": 41.32174,
    "lng": -85.13686,
    "state_id": "IN"
  },
  {
    "zip": 46740,
    "lat": 40.60209,
    "lng": -84.96445,
    "state_id": "IN"
  },
  {
    "zip": 46741,
    "lat": 41.21129,
    "lng": -84.94988,
    "state_id": "IN"
  },
  {
    "zip": 46742,
    "lat": 41.54975,
    "lng": -84.87894,
    "state_id": "IN"
  },
  {
    "zip": 46743,
    "lat": 41.21539,
    "lng": -84.85109,
    "state_id": "IN"
  },
  {
    "zip": 46745,
    "lat": 40.95165,
    "lng": -85.00942,
    "state_id": "IN"
  },
  {
    "zip": 46746,
    "lat": 41.72278,
    "lng": -85.34525,
    "state_id": "IN"
  },
  {
    "zip": 46747,
    "lat": 41.55989,
    "lng": -85.15349,
    "state_id": "IN"
  },
  {
    "zip": 46748,
    "lat": 41.24829,
    "lng": -85.16642,
    "state_id": "IN"
  },
  {
    "zip": 46750,
    "lat": 40.87862,
    "lng": -85.49629,
    "state_id": "IN"
  },
  {
    "zip": 46755,
    "lat": 41.4502,
    "lng": -85.27404,
    "state_id": "IN"
  },
  {
    "zip": 46759,
    "lat": 40.59967,
    "lng": -85.18084,
    "state_id": "IN"
  },
  {
    "zip": 46760,
    "lat": 41.34622,
    "lng": -85.55569,
    "state_id": "IN"
  },
  {
    "zip": 46761,
    "lat": 41.63486,
    "lng": -85.36831,
    "state_id": "IN"
  },
  {
    "zip": 46763,
    "lat": 41.29513,
    "lng": -85.23546,
    "state_id": "IN"
  },
  {
    "zip": 46764,
    "lat": 41.22191,
    "lng": -85.62194,
    "state_id": "IN"
  },
  {
    "zip": 46765,
    "lat": 41.2336,
    "lng": -85.04357,
    "state_id": "IN"
  },
  {
    "zip": 46766,
    "lat": 40.71204,
    "lng": -85.28477,
    "state_id": "IN"
  },
  {
    "zip": 46767,
    "lat": 41.46847,
    "lng": -85.5796,
    "state_id": "IN"
  },
  {
    "zip": 46770,
    "lat": 40.83313,
    "lng": -85.31898,
    "state_id": "IN"
  },
  {
    "zip": 46771,
    "lat": 41.6926,
    "lng": -85.29603,
    "state_id": "IN"
  },
  {
    "zip": 46772,
    "lat": 40.72147,
    "lng": -84.91366,
    "state_id": "IN"
  },
  {
    "zip": 46773,
    "lat": 40.98214,
    "lng": -84.87561,
    "state_id": "IN"
  },
  {
    "zip": 46774,
    "lat": 41.09195,
    "lng": -84.96975,
    "state_id": "IN"
  },
  {
    "zip": 46776,
    "lat": 41.72835,
    "lng": -85.16272,
    "state_id": "IN"
  },
  {
    "zip": 46777,
    "lat": 40.87229,
    "lng": -85.15033,
    "state_id": "IN"
  },
  {
    "zip": 46779,
    "lat": 41.57441,
    "lng": -85.03508,
    "state_id": "IN"
  },
  {
    "zip": 46781,
    "lat": 40.63691,
    "lng": -85.27305,
    "state_id": "IN"
  },
  {
    "zip": 46783,
    "lat": 40.9742,
    "lng": -85.35533,
    "state_id": "IN"
  },
  {
    "zip": 46784,
    "lat": 41.49939,
    "lng": -85.38172,
    "state_id": "IN"
  },
  {
    "zip": 46785,
    "lat": 41.31872,
    "lng": -84.88526,
    "state_id": "IN"
  },
  {
    "zip": 46786,
    "lat": 41.53168,
    "lng": -85.27268,
    "state_id": "IN"
  },
  {
    "zip": 46787,
    "lat": 41.06645,
    "lng": -85.62305,
    "state_id": "IN"
  },
  {
    "zip": 46788,
    "lat": 41.27018,
    "lng": -84.91075,
    "state_id": "IN"
  },
  {
    "zip": 46791,
    "lat": 40.83697,
    "lng": -85.24019,
    "state_id": "IN"
  },
  {
    "zip": 46792,
    "lat": 40.68599,
    "lng": -85.43939,
    "state_id": "IN"
  },
  {
    "zip": 46793,
    "lat": 41.462,
    "lng": -85.01982,
    "state_id": "IN"
  },
  {
    "zip": 46794,
    "lat": 41.46635,
    "lng": -85.46059,
    "state_id": "IN"
  },
  {
    "zip": 46795,
    "lat": 41.5511,
    "lng": -85.33776,
    "state_id": "IN"
  },
  {
    "zip": 46797,
    "lat": 41.12608,
    "lng": -84.86024,
    "state_id": "IN"
  },
  {
    "zip": 46798,
    "lat": 40.94093,
    "lng": -85.22391,
    "state_id": "IN"
  },
  {
    "zip": 46799,
    "lat": 40.91219,
    "lng": -85.28337,
    "state_id": "IN"
  },
  {
    "zip": 46802,
    "lat": 41.06793,
    "lng": -85.16312,
    "state_id": "IN"
  },
  {
    "zip": 46803,
    "lat": 41.07084,
    "lng": -85.0886,
    "state_id": "IN"
  },
  {
    "zip": 46804,
    "lat": 41.05123,
    "lng": -85.24062,
    "state_id": "IN"
  },
  {
    "zip": 46805,
    "lat": 41.09933,
    "lng": -85.11755,
    "state_id": "IN"
  },
  {
    "zip": 46806,
    "lat": 41.04681,
    "lng": -85.08836,
    "state_id": "IN"
  },
  {
    "zip": 46807,
    "lat": 41.04495,
    "lng": -85.14761,
    "state_id": "IN"
  },
  {
    "zip": 46808,
    "lat": 41.09847,
    "lng": -85.17707,
    "state_id": "IN"
  },
  {
    "zip": 46809,
    "lat": 41.00051,
    "lng": -85.20627,
    "state_id": "IN"
  },
  {
    "zip": 46814,
    "lat": 41.04592,
    "lng": -85.30348,
    "state_id": "IN"
  },
  {
    "zip": 46815,
    "lat": 41.10314,
    "lng": -85.05739,
    "state_id": "IN"
  },
  {
    "zip": 46816,
    "lat": 41.00459,
    "lng": -85.03839,
    "state_id": "IN"
  },
  {
    "zip": 46818,
    "lat": 41.14786,
    "lng": -85.25018,
    "state_id": "IN"
  },
  {
    "zip": 46819,
    "lat": 40.96587,
    "lng": -85.13179,
    "state_id": "IN"
  },
  {
    "zip": 46825,
    "lat": 41.15301,
    "lng": -85.12541,
    "state_id": "IN"
  },
  {
    "zip": 46835,
    "lat": 41.15192,
    "lng": -85.04618,
    "state_id": "IN"
  },
  {
    "zip": 46845,
    "lat": 41.20487,
    "lng": -85.10807,
    "state_id": "IN"
  },
  {
    "zip": 46901,
    "lat": 40.52531,
    "lng": -86.15659,
    "state_id": "IN"
  },
  {
    "zip": 46902,
    "lat": 40.43794,
    "lng": -86.0954,
    "state_id": "IN"
  },
  {
    "zip": 46910,
    "lat": 41.04154,
    "lng": -86.0413,
    "state_id": "IN"
  },
  {
    "zip": 46911,
    "lat": 40.62983,
    "lng": -85.94546,
    "state_id": "IN"
  },
  {
    "zip": 46913,
    "lat": 40.50298,
    "lng": -86.50356,
    "state_id": "IN"
  },
  {
    "zip": 46914,
    "lat": 40.63118,
    "lng": -86.09558,
    "state_id": "IN"
  },
  {
    "zip": 46915,
    "lat": 40.47773,
    "lng": -86.38658,
    "state_id": "IN"
  },
  {
    "zip": 46917,
    "lat": 40.62283,
    "lng": -86.46926,
    "state_id": "IN"
  },
  {
    "zip": 46919,
    "lat": 40.5896,
    "lng": -85.86881,
    "state_id": "IN"
  },
  {
    "zip": 46920,
    "lat": 40.46437,
    "lng": -86.4948,
    "state_id": "IN"
  },
  {
    "zip": 46922,
    "lat": 41.13813,
    "lng": -86.41332,
    "state_id": "IN"
  },
  {
    "zip": 46923,
    "lat": 40.60635,
    "lng": -86.6459,
    "state_id": "IN"
  },
  {
    "zip": 46926,
    "lat": 40.88287,
    "lng": -86.05517,
    "state_id": "IN"
  },
  {
    "zip": 46928,
    "lat": 40.40805,
    "lng": -85.68637,
    "state_id": "IN"
  },
  {
    "zip": 46929,
    "lat": 40.54821,
    "lng": -86.46999,
    "state_id": "IN"
  },
  {
    "zip": 46930,
    "lat": 40.40894,
    "lng": -85.57232,
    "state_id": "IN"
  },
  {
    "zip": 46931,
    "lat": 40.94761,
    "lng": -86.26399,
    "state_id": "IN"
  },
  {
    "zip": 46932,
    "lat": 40.59751,
    "lng": -86.25757,
    "state_id": "IN"
  },
  {
    "zip": 46933,
    "lat": 40.47987,
    "lng": -85.58236,
    "state_id": "IN"
  },
  {
    "zip": 46936,
    "lat": 40.47942,
    "lng": -85.93093,
    "state_id": "IN"
  },
  {
    "zip": 46938,
    "lat": 40.4474,
    "lng": -85.64756,
    "state_id": "IN"
  },
  {
    "zip": 46939,
    "lat": 40.99219,
    "lng": -86.4034,
    "state_id": "IN"
  },
  {
    "zip": 46940,
    "lat": 40.68729,
    "lng": -85.69767,
    "state_id": "IN"
  },
  {
    "zip": 46941,
    "lat": 40.82079,
    "lng": -85.69678,
    "state_id": "IN"
  },
  {
    "zip": 46943,
    "lat": 40.97357,
    "lng": -85.84159,
    "state_id": "IN"
  },
  {
    "zip": 46946,
    "lat": 41.03942,
    "lng": -85.73046,
    "state_id": "IN"
  },
  {
    "zip": 46947,
    "lat": 40.76005,
    "lng": -86.37642,
    "state_id": "IN"
  },
  {
    "zip": 46950,
    "lat": 40.88296,
    "lng": -86.37179,
    "state_id": "IN"
  },
  {
    "zip": 46951,
    "lat": 40.9454,
    "lng": -86.1047,
    "state_id": "IN"
  },
  {
    "zip": 46952,
    "lat": 40.59911,
    "lng": -85.63287,
    "state_id": "IN"
  },
  {
    "zip": 46953,
    "lat": 40.51862,
    "lng": -85.64265,
    "state_id": "IN"
  },
  {
    "zip": 46957,
    "lat": 40.38832,
    "lng": -85.49586,
    "state_id": "IN"
  },
  {
    "zip": 46958,
    "lat": 40.82193,
    "lng": -86.1171,
    "state_id": "IN"
  },
  {
    "zip": 46959,
    "lat": 40.62079,
    "lng": -86.10724,
    "state_id": "IN"
  },
  {
    "zip": 46960,
    "lat": 41.15693,
    "lng": -86.52923,
    "state_id": "IN"
  },
  {
    "zip": 46961,
    "lat": 40.76652,
    "lng": -86.19165,
    "state_id": "IN"
  },
  {
    "zip": 46962,
    "lat": 40.98669,
    "lng": -85.76877,
    "state_id": "IN"
  },
  {
    "zip": 46967,
    "lat": 40.69478,
    "lng": -86.1952,
    "state_id": "IN"
  },
  {
    "zip": 46968,
    "lat": 41.17534,
    "lng": -86.54633,
    "state_id": "IN"
  },
  {
    "zip": 46970,
    "lat": 40.76013,
    "lng": -86.06308,
    "state_id": "IN"
  },
  {
    "zip": 46974,
    "lat": 40.94817,
    "lng": -85.93011,
    "state_id": "IN"
  },
  {
    "zip": 46975,
    "lat": 41.0718,
    "lng": -86.25092,
    "state_id": "IN"
  },
  {
    "zip": 46978,
    "lat": 40.8566,
    "lng": -86.51255,
    "state_id": "IN"
  },
  {
    "zip": 46979,
    "lat": 40.4293,
    "lng": -86.28006,
    "state_id": "IN"
  },
  {
    "zip": 46982,
    "lat": 41.05841,
    "lng": -85.88969,
    "state_id": "IN"
  },
  {
    "zip": 46984,
    "lat": 40.66812,
    "lng": -85.83066,
    "state_id": "IN"
  },
  {
    "zip": 46985,
    "lat": 40.95286,
    "lng": -86.57828,
    "state_id": "IN"
  },
  {
    "zip": 46986,
    "lat": 40.49795,
    "lng": -85.8193,
    "state_id": "IN"
  },
  {
    "zip": 46987,
    "lat": 40.56868,
    "lng": -85.7635,
    "state_id": "IN"
  },
  {
    "zip": 46988,
    "lat": 40.87795,
    "lng": -86.23395,
    "state_id": "IN"
  },
  {
    "zip": 46989,
    "lat": 40.45019,
    "lng": -85.47977,
    "state_id": "IN"
  },
  {
    "zip": 46990,
    "lat": 40.89727,
    "lng": -85.7281,
    "state_id": "IN"
  },
  {
    "zip": 46991,
    "lat": 40.62867,
    "lng": -85.50558,
    "state_id": "IN"
  },
  {
    "zip": 46992,
    "lat": 40.78605,
    "lng": -85.82911,
    "state_id": "IN"
  },
  {
    "zip": 46994,
    "lat": 40.66813,
    "lng": -86.25722,
    "state_id": "IN"
  },
  {
    "zip": 46996,
    "lat": 41.06264,
    "lng": -86.65938,
    "state_id": "IN"
  },
  {
    "zip": 46998,
    "lat": 40.56764,
    "lng": -86.34942,
    "state_id": "IN"
  },
  {
    "zip": 47001,
    "lat": 39.06666,
    "lng": -84.96833,
    "state_id": "IN"
  },
  {
    "zip": 47003,
    "lat": 39.55805,
    "lng": -84.84396,
    "state_id": "IN"
  },
  {
    "zip": 47006,
    "lat": 39.292,
    "lng": -85.21583,
    "state_id": "IN"
  },
  {
    "zip": 47010,
    "lat": 39.50069,
    "lng": -84.84148,
    "state_id": "IN"
  },
  {
    "zip": 47011,
    "lat": 38.87547,
    "lng": -85.07527,
    "state_id": "IN"
  },
  {
    "zip": 47012,
    "lat": 39.41776,
    "lng": -84.98153,
    "state_id": "IN"
  },
  {
    "zip": 47016,
    "lat": 39.37668,
    "lng": -84.88702,
    "state_id": "IN"
  },
  {
    "zip": 47017,
    "lat": 38.94178,
    "lng": -85.19039,
    "state_id": "IN"
  },
  {
    "zip": 47018,
    "lat": 38.98741,
    "lng": -85.0828,
    "state_id": "IN"
  },
  {
    "zip": 47020,
    "lat": 38.81667,
    "lng": -84.9331,
    "state_id": "IN"
  },
  {
    "zip": 47022,
    "lat": 39.20492,
    "lng": -84.95173,
    "state_id": "IN"
  },
  {
    "zip": 47023,
    "lat": 39.07935,
    "lng": -85.3815,
    "state_id": "IN"
  },
  {
    "zip": 47024,
    "lat": 39.47631,
    "lng": -85.20035,
    "state_id": "IN"
  },
  {
    "zip": 47025,
    "lat": 39.15647,
    "lng": -84.87428,
    "state_id": "IN"
  },
  {
    "zip": 47030,
    "lat": 39.42569,
    "lng": -85.12989,
    "state_id": "IN"
  },
  {
    "zip": 47031,
    "lat": 39.11942,
    "lng": -85.15856,
    "state_id": "IN"
  },
  {
    "zip": 47032,
    "lat": 39.0808,
    "lng": -85.04983,
    "state_id": "IN"
  },
  {
    "zip": 47034,
    "lat": 39.204,
    "lng": -85.32747,
    "state_id": "IN"
  },
  {
    "zip": 47035,
    "lat": 39.30925,
    "lng": -84.90554,
    "state_id": "IN"
  },
  {
    "zip": 47036,
    "lat": 39.38865,
    "lng": -85.24529,
    "state_id": "IN"
  },
  {
    "zip": 47037,
    "lat": 39.16443,
    "lng": -85.31848,
    "state_id": "IN"
  },
  {
    "zip": 47038,
    "lat": 38.84824,
    "lng": -84.84764,
    "state_id": "IN"
  },
  {
    "zip": 47040,
    "lat": 38.93011,
    "lng": -84.93655,
    "state_id": "IN"
  },
  {
    "zip": 47041,
    "lat": 39.2328,
    "lng": -85.08336,
    "state_id": "IN"
  },
  {
    "zip": 47042,
    "lat": 39.01119,
    "lng": -85.25929,
    "state_id": "IN"
  },
  {
    "zip": 47043,
    "lat": 38.79437,
    "lng": -85.09909,
    "state_id": "IN"
  },
  {
    "zip": 47060,
    "lat": 39.30086,
    "lng": -84.88096,
    "state_id": "IN"
  },
  {
    "zip": 47102,
    "lat": 38.75868,
    "lng": -85.79089,
    "state_id": "IN"
  },
  {
    "zip": 47104,
    "lat": 38.53994,
    "lng": -85.42094,
    "state_id": "IN"
  },
  {
    "zip": 47106,
    "lat": 38.4624,
    "lng": -85.90238,
    "state_id": "IN"
  },
  {
    "zip": 47108,
    "lat": 38.6415,
    "lng": -86.25874,
    "state_id": "IN"
  },
  {
    "zip": 47110,
    "lat": 38.09886,
    "lng": -86.18509,
    "state_id": "IN"
  },
  {
    "zip": 47111,
    "lat": 38.46471,
    "lng": -85.62776,
    "state_id": "IN"
  },
  {
    "zip": 47112,
    "lat": 38.19537,
    "lng": -86.14434,
    "state_id": "IN"
  },
  {
    "zip": 47114,
    "lat": 38.28832,
    "lng": -86.07174,
    "state_id": "IN"
  },
  {
    "zip": 47115,
    "lat": 38.34596,
    "lng": -86.21711,
    "state_id": "IN"
  },
  {
    "zip": 47116,
    "lat": 38.32497,
    "lng": -86.61755,
    "state_id": "IN"
  },
  {
    "zip": 47117,
    "lat": 38.10535,
    "lng": -85.97309,
    "state_id": "IN"
  },
  {
    "zip": 47118,
    "lat": 38.29756,
    "lng": -86.48766,
    "state_id": "IN"
  },
  {
    "zip": 47119,
    "lat": 38.36648,
    "lng": -85.88372,
    "state_id": "IN"
  },
  {
    "zip": 47120,
    "lat": 38.45656,
    "lng": -86.18847,
    "state_id": "IN"
  },
  {
    "zip": 47122,
    "lat": 38.30472,
    "lng": -85.98253,
    "state_id": "IN"
  },
  {
    "zip": 47123,
    "lat": 38.26719,
    "lng": -86.47935,
    "state_id": "IN"
  },
  {
    "zip": 47124,
    "lat": 38.36792,
    "lng": -86.00835,
    "state_id": "IN"
  },
  {
    "zip": 47125,
    "lat": 38.4607,
    "lng": -86.30858,
    "state_id": "IN"
  },
  {
    "zip": 47126,
    "lat": 38.54865,
    "lng": -85.7757,
    "state_id": "IN"
  },
  {
    "zip": 47129,
    "lat": 38.31391,
    "lng": -85.76898,
    "state_id": "IN"
  },
  {
    "zip": 47130,
    "lat": 38.33516,
    "lng": -85.69714,
    "state_id": "IN"
  },
  {
    "zip": 47135,
    "lat": 38.0411,
    "lng": -86.07437,
    "state_id": "IN"
  },
  {
    "zip": 47136,
    "lat": 38.23546,
    "lng": -85.96047,
    "state_id": "IN"
  },
  {
    "zip": 47137,
    "lat": 38.18652,
    "lng": -86.38382,
    "state_id": "IN"
  },
  {
    "zip": 47138,
    "lat": 38.68093,
    "lng": -85.59552,
    "state_id": "IN"
  },
  {
    "zip": 47140,
    "lat": 38.38358,
    "lng": -86.36725,
    "state_id": "IN"
  },
  {
    "zip": 47141,
    "lat": 38.55454,
    "lng": -85.60943,
    "state_id": "IN"
  },
  {
    "zip": 47142,
    "lat": 38.07578,
    "lng": -86.21637,
    "state_id": "IN"
  },
  {
    "zip": 47143,
    "lat": 38.46737,
    "lng": -85.76986,
    "state_id": "IN"
  },
  {
    "zip": 47145,
    "lat": 38.3306,
    "lng": -86.30261,
    "state_id": "IN"
  },
  {
    "zip": 47147,
    "lat": 38.59462,
    "lng": -85.5472,
    "state_id": "IN"
  },
  {
    "zip": 47150,
    "lat": 38.28829,
    "lng": -85.84664,
    "state_id": "IN"
  },
  {
    "zip": 47160,
    "lat": 38.16445,
    "lng": -86.05101,
    "state_id": "IN"
  },
  {
    "zip": 47161,
    "lat": 38.32305,
    "lng": -86.10363,
    "state_id": "IN"
  },
  {
    "zip": 47162,
    "lat": 38.55393,
    "lng": -85.47192,
    "state_id": "IN"
  },
  {
    "zip": 47163,
    "lat": 38.53227,
    "lng": -85.6697,
    "state_id": "IN"
  },
  {
    "zip": 47164,
    "lat": 38.40416,
    "lng": -86.09273,
    "state_id": "IN"
  },
  {
    "zip": 47165,
    "lat": 38.50454,
    "lng": -86.00561,
    "state_id": "IN"
  },
  {
    "zip": 47166,
    "lat": 38.30689,
    "lng": -86.16742,
    "state_id": "IN"
  },
  {
    "zip": 47167,
    "lat": 38.61184,
    "lng": -86.09583,
    "state_id": "IN"
  },
  {
    "zip": 47170,
    "lat": 38.6849,
    "lng": -85.84356,
    "state_id": "IN"
  },
  {
    "zip": 47172,
    "lat": 38.39885,
    "lng": -85.76864,
    "state_id": "IN"
  },
  {
    "zip": 47175,
    "lat": 38.36482,
    "lng": -86.55697,
    "state_id": "IN"
  },
  {
    "zip": 47177,
    "lat": 38.60072,
    "lng": -85.78269,
    "state_id": "IN"
  },
  {
    "zip": 47201,
    "lat": 39.15768,
    "lng": -85.9907,
    "state_id": "IN"
  },
  {
    "zip": 47203,
    "lat": 39.23159,
    "lng": -85.83295,
    "state_id": "IN"
  },
  {
    "zip": 47220,
    "lat": 38.87745,
    "lng": -86.0504,
    "state_id": "IN"
  },
  {
    "zip": 47223,
    "lat": 39.04911,
    "lng": -85.47891,
    "state_id": "IN"
  },
  {
    "zip": 47224,
    "lat": 38.88697,
    "lng": -85.22128,
    "state_id": "IN"
  },
  {
    "zip": 47226,
    "lat": 39.28268,
    "lng": -85.8691,
    "state_id": "IN"
  },
  {
    "zip": 47227,
    "lat": 38.8749,
    "lng": -85.65178,
    "state_id": "IN"
  },
  {
    "zip": 47229,
    "lat": 38.8041,
    "lng": -85.86737,
    "state_id": "IN"
  },
  {
    "zip": 47230,
    "lat": 38.795,
    "lng": -85.6298,
    "state_id": "IN"
  },
  {
    "zip": 47231,
    "lat": 38.89924,
    "lng": -85.49577,
    "state_id": "IN"
  },
  {
    "zip": 47232,
    "lat": 39.11337,
    "lng": -85.7931,
    "state_id": "IN"
  },
  {
    "zip": 47234,
    "lat": 39.37721,
    "lng": -85.76796,
    "state_id": "IN"
  },
  {
    "zip": 47235,
    "lat": 38.99605,
    "lng": -86.13628,
    "state_id": "IN"
  },
  {
    "zip": 47240,
    "lat": 39.32316,
    "lng": -85.47994,
    "state_id": "IN"
  },
  {
    "zip": 47243,
    "lat": 38.67096,
    "lng": -85.48023,
    "state_id": "IN"
  },
  {
    "zip": 47244,
    "lat": 39.21913,
    "lng": -85.70149,
    "state_id": "IN"
  },
  {
    "zip": 47246,
    "lat": 39.2985,
    "lng": -85.76731,
    "state_id": "IN"
  },
  {
    "zip": 47247,
    "lat": 39.05961,
    "lng": -85.88811,
    "state_id": "IN"
  },
  {
    "zip": 47250,
    "lat": 38.81842,
    "lng": -85.35149,
    "state_id": "IN"
  },
  {
    "zip": 47260,
    "lat": 38.8361,
    "lng": -86.19866,
    "state_id": "IN"
  },
  {
    "zip": 47263,
    "lat": 39.30947,
    "lng": -85.33021,
    "state_id": "IN"
  },
  {
    "zip": 47264,
    "lat": 38.96688,
    "lng": -86.26906,
    "state_id": "IN"
  },
  {
    "zip": 47265,
    "lat": 39.01233,
    "lng": -85.62763,
    "state_id": "IN"
  },
  {
    "zip": 47270,
    "lat": 38.84044,
    "lng": -85.7235,
    "state_id": "IN"
  },
  {
    "zip": 47272,
    "lat": 39.41234,
    "lng": -85.62046,
    "state_id": "IN"
  },
  {
    "zip": 47273,
    "lat": 39.07426,
    "lng": -85.73672,
    "state_id": "IN"
  },
  {
    "zip": 47274,
    "lat": 38.96581,
    "lng": -85.93097,
    "state_id": "IN"
  },
  {
    "zip": 47280,
    "lat": 39.29652,
    "lng": -85.95056,
    "state_id": "IN"
  },
  {
    "zip": 47281,
    "lat": 38.78587,
    "lng": -86.10386,
    "state_id": "IN"
  },
  {
    "zip": 47282,
    "lat": 38.98241,
    "lng": -85.61069,
    "state_id": "IN"
  },
  {
    "zip": 47283,
    "lat": 39.17338,
    "lng": -85.58861,
    "state_id": "IN"
  },
  {
    "zip": 47302,
    "lat": 40.12999,
    "lng": -85.37673,
    "state_id": "IN"
  },
  {
    "zip": 47303,
    "lat": 40.27154,
    "lng": -85.37801,
    "state_id": "IN"
  },
  {
    "zip": 47304,
    "lat": 40.2331,
    "lng": -85.46054,
    "state_id": "IN"
  },
  {
    "zip": 47305,
    "lat": 40.19375,
    "lng": -85.38603,
    "state_id": "IN"
  },
  {
    "zip": 47306,
    "lat": 40.20418,
    "lng": -85.4069,
    "state_id": "IN"
  },
  {
    "zip": 47320,
    "lat": 40.27874,
    "lng": -85.26668,
    "state_id": "IN"
  },
  {
    "zip": 47324,
    "lat": 39.74118,
    "lng": -84.85172,
    "state_id": "IN"
  },
  {
    "zip": 47325,
    "lat": 39.68923,
    "lng": -85.01756,
    "state_id": "IN"
  },
  {
    "zip": 47326,
    "lat": 40.54567,
    "lng": -84.96605,
    "state_id": "IN"
  },
  {
    "zip": 47327,
    "lat": 39.83125,
    "lng": -85.17382,
    "state_id": "IN"
  },
  {
    "zip": 47330,
    "lat": 39.78641,
    "lng": -85.02219,
    "state_id": "IN"
  },
  {
    "zip": 47331,
    "lat": 39.62981,
    "lng": -85.16411,
    "state_id": "IN"
  },
  {
    "zip": 47334,
    "lat": 40.11771,
    "lng": -85.52701,
    "state_id": "IN"
  },
  {
    "zip": 47335,
    "lat": 39.81238,
    "lng": -85.2049,
    "state_id": "IN"
  },
  {
    "zip": 47336,
    "lat": 40.3961,
    "lng": -85.20756,
    "state_id": "IN"
  },
  {
    "zip": 47337,
    "lat": 39.80245,
    "lng": -85.43732,
    "state_id": "IN"
  },
  {
    "zip": 47338,
    "lat": 40.3399,
    "lng": -85.3409,
    "state_id": "IN"
  },
  {
    "zip": 47339,
    "lat": 39.96271,
    "lng": -85.09496,
    "state_id": "IN"
  },
  {
    "zip": 47340,
    "lat": 40.18107,
    "lng": -85.1323,
    "state_id": "IN"
  },
  {
    "zip": 47341,
    "lat": 39.97229,
    "lng": -84.89277,
    "state_id": "IN"
  },
  {
    "zip": 47342,
    "lat": 40.32196,
    "lng": -85.51233,
    "state_id": "IN"
  },
  {
    "zip": 47344,
    "lat": 39.87833,
    "lng": -85.46549,
    "state_id": "IN"
  },
  {
    "zip": 47345,
    "lat": 39.89103,
    "lng": -85.04865,
    "state_id": "IN"
  },
  {
    "zip": 47346,
    "lat": 39.93436,
    "lng": -85.16281,
    "state_id": "IN"
  },
  {
    "zip": 47348,
    "lat": 40.45668,
    "lng": -85.34818,
    "state_id": "IN"
  },
  {
    "zip": 47351,
    "lat": 39.90547,
    "lng": -85.52219,
    "state_id": "IN"
  },
  {
    "zip": 47352,
    "lat": 39.79743,
    "lng": -85.36459,
    "state_id": "IN"
  },
  {
    "zip": 47353,
    "lat": 39.62773,
    "lng": -84.91944,
    "state_id": "IN"
  },
  {
    "zip": 47354,
    "lat": 40.04568,
    "lng": -85.19698,
    "state_id": "IN"
  },
  {
    "zip": 47355,
    "lat": 40.04527,
    "lng": -84.92712,
    "state_id": "IN"
  },
  {
    "zip": 47356,
    "lat": 40.02869,
    "lng": -85.50531,
    "state_id": "IN"
  },
  {
    "zip": 47357,
    "lat": 39.75631,
    "lng": -85.14961,
    "state_id": "IN"
  },
  {
    "zip": 47358,
    "lat": 40.05394,
    "lng": -85.11076,
    "state_id": "IN"
  },
  {
    "zip": 47359,
    "lat": 40.55043,
    "lng": -85.28362,
    "state_id": "IN"
  },
  {
    "zip": 47360,
    "lat": 40.01534,
    "lng": -85.26269,
    "state_id": "IN"
  },
  {
    "zip": 47361,
    "lat": 40.00423,
    "lng": -85.38501,
    "state_id": "IN"
  },
  {
    "zip": 47362,
    "lat": 39.93024,
    "lng": -85.36904,
    "state_id": "IN"
  },
  {
    "zip": 47367,
    "lat": 40.08265,
    "lng": -85.38721,
    "state_id": "IN"
  },
  {
    "zip": 47368,
    "lat": 40.18497,
    "lng": -85.19973,
    "state_id": "IN"
  },
  {
    "zip": 47369,
    "lat": 40.50537,
    "lng": -85.14469,
    "state_id": "IN"
  },
  {
    "zip": 47371,
    "lat": 40.41858,
    "lng": -84.96004,
    "state_id": "IN"
  },
  {
    "zip": 47373,
    "lat": 40.33676,
    "lng": -85.15582,
    "state_id": "IN"
  },
  {
    "zip": 47374,
    "lat": 39.83163,
    "lng": -84.88909,
    "state_id": "IN"
  },
  {
    "zip": 47380,
    "lat": 40.28679,
    "lng": -85.03009,
    "state_id": "IN"
  },
  {
    "zip": 47381,
    "lat": 40.38177,
    "lng": -84.86689,
    "state_id": "IN"
  },
  {
    "zip": 47382,
    "lat": 40.23637,
    "lng": -84.91748,
    "state_id": "IN"
  },
  {
    "zip": 47383,
    "lat": 40.14794,
    "lng": -85.26244,
    "state_id": "IN"
  },
  {
    "zip": 47384,
    "lat": 39.91453,
    "lng": -85.55438,
    "state_id": "IN"
  },
  {
    "zip": 47385,
    "lat": 39.83248,
    "lng": -85.4465,
    "state_id": "IN"
  },
  {
    "zip": 47386,
    "lat": 40.05272,
    "lng": -85.3833,
    "state_id": "IN"
  },
  {
    "zip": 47387,
    "lat": 39.83539,
    "lng": -85.29404,
    "state_id": "IN"
  },
  {
    "zip": 47388,
    "lat": 40.00489,
    "lng": -85.44307,
    "state_id": "IN"
  },
  {
    "zip": 47390,
    "lat": 40.20611,
    "lng": -84.84719,
    "state_id": "IN"
  },
  {
    "zip": 47393,
    "lat": 39.96395,
    "lng": -85.00188,
    "state_id": "IN"
  },
  {
    "zip": 47394,
    "lat": 40.16903,
    "lng": -84.98723,
    "state_id": "IN"
  },
  {
    "zip": 47396,
    "lat": 40.18874,
    "lng": -85.51634,
    "state_id": "IN"
  },
  {
    "zip": 47401,
    "lat": 39.08592,
    "lng": -86.45156,
    "state_id": "IN"
  },
  {
    "zip": 47403,
    "lat": 39.08488,
    "lng": -86.6113,
    "state_id": "IN"
  },
  {
    "zip": 47404,
    "lat": 39.21963,
    "lng": -86.58968,
    "state_id": "IN"
  },
  {
    "zip": 47405,
    "lat": 39.16817,
    "lng": -86.5185,
    "state_id": "IN"
  },
  {
    "zip": 47406,
    "lat": 39.17777,
    "lng": -86.51543,
    "state_id": "IN"
  },
  {
    "zip": 47408,
    "lat": 39.22993,
    "lng": -86.46447,
    "state_id": "IN"
  },
  {
    "zip": 47420,
    "lat": 38.91316,
    "lng": -86.54891,
    "state_id": "IN"
  },
  {
    "zip": 47421,
    "lat": 38.86267,
    "lng": -86.45106,
    "state_id": "IN"
  },
  {
    "zip": 47424,
    "lat": 39.02176,
    "lng": -86.87338,
    "state_id": "IN"
  },
  {
    "zip": 47427,
    "lat": 39.2393,
    "lng": -87.01498,
    "state_id": "IN"
  },
  {
    "zip": 47429,
    "lat": 39.27332,
    "lng": -86.61593,
    "state_id": "IN"
  },
  {
    "zip": 47431,
    "lat": 39.23883,
    "lng": -86.90057,
    "state_id": "IN"
  },
  {
    "zip": 47432,
    "lat": 38.48988,
    "lng": -86.64364,
    "state_id": "IN"
  },
  {
    "zip": 47433,
    "lat": 39.3559,
    "lng": -86.65253,
    "state_id": "IN"
  },
  {
    "zip": 47434,
    "lat": 39.01188,
    "lng": -86.54723,
    "state_id": "IN"
  },
  {
    "zip": 47436,
    "lat": 38.95429,
    "lng": -86.39413,
    "state_id": "IN"
  },
  {
    "zip": 47437,
    "lat": 38.71466,
    "lng": -86.67704,
    "state_id": "IN"
  },
  {
    "zip": 47438,
    "lat": 39.15935,
    "lng": -87.18853,
    "state_id": "IN"
  },
  {
    "zip": 47441,
    "lat": 39.04328,
    "lng": -87.16595,
    "state_id": "IN"
  },
  {
    "zip": 47443,
    "lat": 38.95976,
    "lng": -87.09107,
    "state_id": "IN"
  },
  {
    "zip": 47446,
    "lat": 38.73824,
    "lng": -86.51377,
    "state_id": "IN"
  },
  {
    "zip": 47448,
    "lat": 39.17381,
    "lng": -86.24241,
    "state_id": "IN"
  },
  {
    "zip": 47449,
    "lat": 38.92668,
    "lng": -87.00277,
    "state_id": "IN"
  },
  {
    "zip": 47451,
    "lat": 38.89285,
    "lng": -86.52351,
    "state_id": "IN"
  },
  {
    "zip": 47452,
    "lat": 38.64141,
    "lng": -86.42254,
    "state_id": "IN"
  },
  {
    "zip": 47453,
    "lat": 38.92528,
    "lng": -86.7742,
    "state_id": "IN"
  },
  {
    "zip": 47454,
    "lat": 38.51759,
    "lng": -86.45591,
    "state_id": "IN"
  },
  {
    "zip": 47455,
    "lat": 39.31654,
    "lng": -86.95619,
    "state_id": "IN"
  },
  {
    "zip": 47456,
    "lat": 39.45989,
    "lng": -86.70434,
    "state_id": "IN"
  },
  {
    "zip": 47457,
    "lat": 38.90909,
    "lng": -86.90472,
    "state_id": "IN"
  },
  {
    "zip": 47458,
    "lat": 39.07034,
    "lng": -86.50724,
    "state_id": "IN"
  },
  {
    "zip": 47459,
    "lat": 39.11278,
    "lng": -86.74958,
    "state_id": "IN"
  },
  {
    "zip": 47460,
    "lat": 39.28379,
    "lng": -86.79022,
    "state_id": "IN"
  },
  {
    "zip": 47462,
    "lat": 38.95668,
    "lng": -86.64716,
    "state_id": "IN"
  },
  {
    "zip": 47464,
    "lat": 39.29982,
    "lng": -86.64993,
    "state_id": "IN"
  },
  {
    "zip": 47465,
    "lat": 39.04648,
    "lng": -87.04078,
    "state_id": "IN"
  },
  {
    "zip": 47467,
    "lat": 38.7681,
    "lng": -86.34444,
    "state_id": "IN"
  },
  {
    "zip": 47468,
    "lat": 39.27287,
    "lng": -86.40299,
    "state_id": "IN"
  },
  {
    "zip": 47469,
    "lat": 38.61775,
    "lng": -86.61281,
    "state_id": "IN"
  },
  {
    "zip": 47470,
    "lat": 38.81504,
    "lng": -86.64717,
    "state_id": "IN"
  },
  {
    "zip": 47471,
    "lat": 39.1417,
    "lng": -87.00228,
    "state_id": "IN"
  },
  {
    "zip": 47501,
    "lat": 38.6624,
    "lng": -87.1724,
    "state_id": "IN"
  },
  {
    "zip": 47512,
    "lat": 38.78317,
    "lng": -87.31779,
    "state_id": "IN"
  },
  {
    "zip": 47513,
    "lat": 38.30612,
    "lng": -86.70315,
    "state_id": "IN"
  },
  {
    "zip": 47514,
    "lat": 38.15621,
    "lng": -86.58619,
    "state_id": "IN"
  },
  {
    "zip": 47515,
    "lat": 38.18399,
    "lng": -86.70629,
    "state_id": "IN"
  },
  {
    "zip": 47516,
    "lat": 38.76734,
    "lng": -87.4194,
    "state_id": "IN"
  },
  {
    "zip": 47519,
    "lat": 38.60321,
    "lng": -86.98644,
    "state_id": "IN"
  },
  {
    "zip": 47520,
    "lat": 37.98606,
    "lng": -86.59333,
    "state_id": "IN"
  },
  {
    "zip": 47521,
    "lat": 38.39662,
    "lng": -86.72827,
    "state_id": "IN"
  },
  {
    "zip": 47522,
    "lat": 38.88884,
    "lng": -86.83637,
    "state_id": "IN"
  },
  {
    "zip": 47523,
    "lat": 38.17218,
    "lng": -87.02768,
    "state_id": "IN"
  },
  {
    "zip": 47524,
    "lat": 38.4866,
    "lng": -87.61272,
    "state_id": "IN"
  },
  {
    "zip": 47525,
    "lat": 38.03115,
    "lng": -86.56374,
    "state_id": "IN"
  },
  {
    "zip": 47527,
    "lat": 38.47214,
    "lng": -86.77973,
    "state_id": "IN"
  },
  {
    "zip": 47528,
    "lat": 38.83787,
    "lng": -87.25692,
    "state_id": "IN"
  },
  {
    "zip": 47529,
    "lat": 38.86025,
    "lng": -87.07992,
    "state_id": "IN"
  },
  {
    "zip": 47531,
    "lat": 38.03898,
    "lng": -86.85225,
    "state_id": "IN"
  },
  {
    "zip": 47532,
    "lat": 38.22118,
    "lng": -86.86049,
    "state_id": "IN"
  },
  {
    "zip": 47535,
    "lat": 38.87234,
    "lng": -87.30761,
    "state_id": "IN"
  },
  {
    "zip": 47536,
    "lat": 38.11228,
    "lng": -86.83653,
    "state_id": "IN"
  },
  {
    "zip": 47537,
    "lat": 38.10582,
    "lng": -87.07233,
    "state_id": "IN"
  },
  {
    "zip": 47541,
    "lat": 38.23611,
    "lng": -87.04441,
    "state_id": "IN"
  },
  {
    "zip": 47542,
    "lat": 38.29668,
    "lng": -86.96932,
    "state_id": "IN"
  },
  {
    "zip": 47546,
    "lat": 38.4195,
    "lng": -86.9358,
    "state_id": "IN"
  },
  {
    "zip": 47550,
    "lat": 38.06463,
    "lng": -86.92632,
    "state_id": "IN"
  },
  {
    "zip": 47551,
    "lat": 38.12335,
    "lng": -86.56237,
    "state_id": "IN"
  },
  {
    "zip": 47552,
    "lat": 38.11406,
    "lng": -86.99819,
    "state_id": "IN"
  },
  {
    "zip": 47553,
    "lat": 38.65257,
    "lng": -86.91012,
    "state_id": "IN"
  },
  {
    "zip": 47557,
    "lat": 38.57478,
    "lng": -87.33777,
    "state_id": "IN"
  },
  {
    "zip": 47558,
    "lat": 38.64343,
    "lng": -87.04095,
    "state_id": "IN"
  },
  {
    "zip": 47561,
    "lat": 38.85911,
    "lng": -87.43569,
    "state_id": "IN"
  },
  {
    "zip": 47562,
    "lat": 38.83541,
    "lng": -86.98378,
    "state_id": "IN"
  },
  {
    "zip": 47564,
    "lat": 38.48247,
    "lng": -87.09414,
    "state_id": "IN"
  },
  {
    "zip": 47567,
    "lat": 38.46997,
    "lng": -87.30424,
    "state_id": "IN"
  },
  {
    "zip": 47568,
    "lat": 38.79285,
    "lng": -87.13419,
    "state_id": "IN"
  },
  {
    "zip": 47574,
    "lat": 37.95313,
    "lng": -86.55187,
    "state_id": "IN"
  },
  {
    "zip": 47575,
    "lat": 38.32153,
    "lng": -86.81057,
    "state_id": "IN"
  },
  {
    "zip": 47576,
    "lat": 38.18343,
    "lng": -86.60584,
    "state_id": "IN"
  },
  {
    "zip": 47577,
    "lat": 38.15219,
    "lng": -86.80675,
    "state_id": "IN"
  },
  {
    "zip": 47578,
    "lat": 38.90183,
    "lng": -87.19851,
    "state_id": "IN"
  },
  {
    "zip": 47579,
    "lat": 38.11405,
    "lng": -86.91852,
    "state_id": "IN"
  },
  {
    "zip": 47580,
    "lat": 38.35631,
    "lng": -86.76806,
    "state_id": "IN"
  },
  {
    "zip": 47581,
    "lat": 38.66118,
    "lng": -86.76792,
    "state_id": "IN"
  },
  {
    "zip": 47584,
    "lat": 38.25486,
    "lng": -87.25848,
    "state_id": "IN"
  },
  {
    "zip": 47585,
    "lat": 38.27092,
    "lng": -87.14807,
    "state_id": "IN"
  },
  {
    "zip": 47586,
    "lat": 38.03704,
    "lng": -86.69933,
    "state_id": "IN"
  },
  {
    "zip": 47588,
    "lat": 38.07041,
    "lng": -86.77929,
    "state_id": "IN"
  },
  {
    "zip": 47590,
    "lat": 38.35964,
    "lng": -87.10423,
    "state_id": "IN"
  },
  {
    "zip": 47591,
    "lat": 38.62838,
    "lng": -87.5041,
    "state_id": "IN"
  },
  {
    "zip": 47596,
    "lat": 38.86651,
    "lng": -87.2277,
    "state_id": "IN"
  },
  {
    "zip": 47597,
    "lat": 38.65498,
    "lng": -87.29957,
    "state_id": "IN"
  },
  {
    "zip": 47598,
    "lat": 38.38799,
    "lng": -87.21356,
    "state_id": "IN"
  },
  {
    "zip": 47601,
    "lat": 38.05209,
    "lng": -87.2553,
    "state_id": "IN"
  },
  {
    "zip": 47610,
    "lat": 38.05726,
    "lng": -87.40168,
    "state_id": "IN"
  },
  {
    "zip": 47611,
    "lat": 38.02795,
    "lng": -87.05342,
    "state_id": "IN"
  },
  {
    "zip": 47612,
    "lat": 38.19303,
    "lng": -87.69924,
    "state_id": "IN"
  },
  {
    "zip": 47613,
    "lat": 38.15751,
    "lng": -87.41729,
    "state_id": "IN"
  },
  {
    "zip": 47615,
    "lat": 37.97103,
    "lng": -86.95901,
    "state_id": "IN"
  },
  {
    "zip": 47616,
    "lat": 38.23664,
    "lng": -87.89822,
    "state_id": "IN"
  },
  {
    "zip": 47619,
    "lat": 38.1913,
    "lng": -87.29806,
    "state_id": "IN"
  },
  {
    "zip": 47620,
    "lat": 37.93261,
    "lng": -87.90612,
    "state_id": "IN"
  },
  {
    "zip": 47630,
    "lat": 37.95542,
    "lng": -87.35553,
    "state_id": "IN"
  },
  {
    "zip": 47631,
    "lat": 38.10123,
    "lng": -87.90994,
    "state_id": "IN"
  },
  {
    "zip": 47633,
    "lat": 38.17263,
    "lng": -87.78918,
    "state_id": "IN"
  },
  {
    "zip": 47634,
    "lat": 37.93768,
    "lng": -87.18335,
    "state_id": "IN"
  },
  {
    "zip": 47635,
    "lat": 37.88622,
    "lng": -87.10551,
    "state_id": "IN"
  },
  {
    "zip": 47637,
    "lat": 38.13444,
    "lng": -87.14952,
    "state_id": "IN"
  },
  {
    "zip": 47638,
    "lat": 38.07144,
    "lng": -87.77364,
    "state_id": "IN"
  },
  {
    "zip": 47639,
    "lat": 38.17983,
    "lng": -87.575,
    "state_id": "IN"
  },
  {
    "zip": 47640,
    "lat": 38.47765,
    "lng": -87.49341,
    "state_id": "IN"
  },
  {
    "zip": 47648,
    "lat": 38.24636,
    "lng": -87.55152,
    "state_id": "IN"
  },
  {
    "zip": 47649,
    "lat": 38.35216,
    "lng": -87.44734,
    "state_id": "IN"
  },
  {
    "zip": 47654,
    "lat": 38.24903,
    "lng": -87.3925,
    "state_id": "IN"
  },
  {
    "zip": 47660,
    "lat": 38.29471,
    "lng": -87.32513,
    "state_id": "IN"
  },
  {
    "zip": 47665,
    "lat": 38.27881,
    "lng": -87.72268,
    "state_id": "IN"
  },
  {
    "zip": 47666,
    "lat": 38.41898,
    "lng": -87.59994,
    "state_id": "IN"
  },
  {
    "zip": 47670,
    "lat": 38.34529,
    "lng": -87.57539,
    "state_id": "IN"
  },
  {
    "zip": 47683,
    "lat": 38.27618,
    "lng": -87.37737,
    "state_id": "IN"
  },
  {
    "zip": 47708,
    "lat": 37.97445,
    "lng": -87.57379,
    "state_id": "IN"
  },
  {
    "zip": 47710,
    "lat": 38.02559,
    "lng": -87.57598,
    "state_id": "IN"
  },
  {
    "zip": 47711,
    "lat": 38.01537,
    "lng": -87.53589,
    "state_id": "IN"
  },
  {
    "zip": 47712,
    "lat": 37.92866,
    "lng": -87.66007,
    "state_id": "IN"
  },
  {
    "zip": 47713,
    "lat": 37.954,
    "lng": -87.55959,
    "state_id": "IN"
  },
  {
    "zip": 47714,
    "lat": 37.95755,
    "lng": -87.52112,
    "state_id": "IN"
  },
  {
    "zip": 47715,
    "lat": 37.96998,
    "lng": -87.48426,
    "state_id": "IN"
  },
  {
    "zip": 47720,
    "lat": 38.06052,
    "lng": -87.6425,
    "state_id": "IN"
  },
  {
    "zip": 47725,
    "lat": 38.10708,
    "lng": -87.52564,
    "state_id": "IN"
  },
  {
    "zip": 47802,
    "lat": 39.3511,
    "lng": -87.40744,
    "state_id": "IN"
  },
  {
    "zip": 47803,
    "lat": 39.46452,
    "lng": -87.30749,
    "state_id": "IN"
  },
  {
    "zip": 47804,
    "lat": 39.49758,
    "lng": -87.38966,
    "state_id": "IN"
  },
  {
    "zip": 47805,
    "lat": 39.54708,
    "lng": -87.32663,
    "state_id": "IN"
  },
  {
    "zip": 47807,
    "lat": 39.47061,
    "lng": -87.40277,
    "state_id": "IN"
  },
  {
    "zip": 47809,
    "lat": 39.47131,
    "lng": -87.40998,
    "state_id": "IN"
  },
  {
    "zip": 47832,
    "lat": 39.8779,
    "lng": -87.25424,
    "state_id": "IN"
  },
  {
    "zip": 47833,
    "lat": 39.36,
    "lng": -86.97664,
    "state_id": "IN"
  },
  {
    "zip": 47834,
    "lat": 39.52586,
    "lng": -87.12123,
    "state_id": "IN"
  },
  {
    "zip": 47836,
    "lat": 39.64605,
    "lng": -87.17903,
    "state_id": "IN"
  },
  {
    "zip": 47837,
    "lat": 39.63084,
    "lng": -87.11328,
    "state_id": "IN"
  },
  {
    "zip": 47838,
    "lat": 38.95545,
    "lng": -87.37791,
    "state_id": "IN"
  },
  {
    "zip": 47840,
    "lat": 39.41107,
    "lng": -87.05825,
    "state_id": "IN"
  },
  {
    "zip": 47841,
    "lat": 39.275,
    "lng": -87.11502,
    "state_id": "IN"
  },
  {
    "zip": 47842,
    "lat": 39.67855,
    "lng": -87.4636,
    "state_id": "IN"
  },
  {
    "zip": 47846,
    "lat": 39.37474,
    "lng": -87.19767,
    "state_id": "IN"
  },
  {
    "zip": 47847,
    "lat": 39.82545,
    "lng": -87.48739,
    "state_id": "IN"
  },
  {
    "zip": 47848,
    "lat": 39.03865,
    "lng": -87.26313,
    "state_id": "IN"
  },
  {
    "zip": 47849,
    "lat": 39.19363,
    "lng": -87.556,
    "state_id": "IN"
  },
  {
    "zip": 47850,
    "lat": 39.24585,
    "lng": -87.43141,
    "state_id": "IN"
  },
  {
    "zip": 47853,
    "lat": 39.53822,
    "lng": -87.07283,
    "state_id": "IN"
  },
  {
    "zip": 47854,
    "lat": 39.79856,
    "lng": -87.40244,
    "state_id": "IN"
  },
  {
    "zip": 47855,
    "lat": 39.18992,
    "lng": -87.3041,
    "state_id": "IN"
  },
  {
    "zip": 47857,
    "lat": 39.52416,
    "lng": -87.09111,
    "state_id": "IN"
  },
  {
    "zip": 47858,
    "lat": 39.26386,
    "lng": -87.23117,
    "state_id": "IN"
  },
  {
    "zip": 47859,
    "lat": 39.88661,
    "lng": -87.17203,
    "state_id": "IN"
  },
  {
    "zip": 47860,
    "lat": 39.72188,
    "lng": -87.33024,
    "state_id": "IN"
  },
  {
    "zip": 47861,
    "lat": 39.06483,
    "lng": -87.56629,
    "state_id": "IN"
  },
  {
    "zip": 47862,
    "lat": 39.7705,
    "lng": -87.34552,
    "state_id": "IN"
  },
  {
    "zip": 47863,
    "lat": 39.58564,
    "lng": -87.4549,
    "state_id": "IN"
  },
  {
    "zip": 47865,
    "lat": 39.02103,
    "lng": -87.39144,
    "state_id": "IN"
  },
  {
    "zip": 47866,
    "lat": 39.28951,
    "lng": -87.32533,
    "state_id": "IN"
  },
  {
    "zip": 47868,
    "lat": 39.40964,
    "lng": -86.89941,
    "state_id": "IN"
  },
  {
    "zip": 47869,
    "lat": 39.27384,
    "lng": -87.49671,
    "state_id": "IN"
  },
  {
    "zip": 47871,
    "lat": 39.3897,
    "lng": -87.29994,
    "state_id": "IN"
  },
  {
    "zip": 47872,
    "lat": 39.75343,
    "lng": -87.16204,
    "state_id": "IN"
  },
  {
    "zip": 47874,
    "lat": 39.62794,
    "lng": -87.27458,
    "state_id": "IN"
  },
  {
    "zip": 47876,
    "lat": 39.51317,
    "lng": -87.45964,
    "state_id": "IN"
  },
  {
    "zip": 47879,
    "lat": 39.18916,
    "lng": -87.37822,
    "state_id": "IN"
  },
  {
    "zip": 47880,
    "lat": 39.59975,
    "lng": -87.41885,
    "state_id": "IN"
  },
  {
    "zip": 47881,
    "lat": 39.48594,
    "lng": -87.19237,
    "state_id": "IN"
  },
  {
    "zip": 47882,
    "lat": 39.09092,
    "lng": -87.41029,
    "state_id": "IN"
  },
  {
    "zip": 47884,
    "lat": 39.61524,
    "lng": -87.44982,
    "state_id": "IN"
  },
  {
    "zip": 47885,
    "lat": 39.50955,
    "lng": -87.47661,
    "state_id": "IN"
  },
  {
    "zip": 47901,
    "lat": 40.41785,
    "lng": -86.88992,
    "state_id": "IN"
  },
  {
    "zip": 47904,
    "lat": 40.43836,
    "lng": -86.87665,
    "state_id": "IN"
  },
  {
    "zip": 47905,
    "lat": 40.42039,
    "lng": -86.76599,
    "state_id": "IN"
  },
  {
    "zip": 47906,
    "lat": 40.48698,
    "lng": -86.98647,
    "state_id": "IN"
  },
  {
    "zip": 47907,
    "lat": 40.42435,
    "lng": -86.91536,
    "state_id": "IN"
  },
  {
    "zip": 47909,
    "lat": 40.32346,
    "lng": -86.90075,
    "state_id": "IN"
  },
  {
    "zip": 47916,
    "lat": 39.98353,
    "lng": -87.05527,
    "state_id": "IN"
  },
  {
    "zip": 47917,
    "lat": 40.46688,
    "lng": -87.47347,
    "state_id": "IN"
  },
  {
    "zip": 47918,
    "lat": 40.30479,
    "lng": -87.20376,
    "state_id": "IN"
  },
  {
    "zip": 47920,
    "lat": 40.54758,
    "lng": -86.82359,
    "state_id": "IN"
  },
  {
    "zip": 47921,
    "lat": 40.47718,
    "lng": -87.37539,
    "state_id": "IN"
  },
  {
    "zip": 47922,
    "lat": 40.87657,
    "lng": -87.35286,
    "state_id": "IN"
  },
  {
    "zip": 47923,
    "lat": 40.61137,
    "lng": -86.93134,
    "state_id": "IN"
  },
  {
    "zip": 47924,
    "lat": 40.48792,
    "lng": -86.7631,
    "state_id": "IN"
  },
  {
    "zip": 47925,
    "lat": 40.87716,
    "lng": -86.74713,
    "state_id": "IN"
  },
  {
    "zip": 47926,
    "lat": 40.77611,
    "lng": -86.59597,
    "state_id": "IN"
  },
  {
    "zip": 47928,
    "lat": 39.93048,
    "lng": -87.46735,
    "state_id": "IN"
  },
  {
    "zip": 47929,
    "lat": 40.6763,
    "lng": -86.91641,
    "state_id": "IN"
  },
  {
    "zip": 47930,
    "lat": 40.23714,
    "lng": -86.74237,
    "state_id": "IN"
  },
  {
    "zip": 47932,
    "lat": 40.12919,
    "lng": -87.40884,
    "state_id": "IN"
  },
  {
    "zip": 47933,
    "lat": 40.03865,
    "lng": -86.89671,
    "state_id": "IN"
  },
  {
    "zip": 47940,
    "lat": 40.11759,
    "lng": -86.7466,
    "state_id": "IN"
  },
  {
    "zip": 47941,
    "lat": 40.3761,
    "lng": -86.77391,
    "state_id": "IN"
  },
  {
    "zip": 47942,
    "lat": 40.68547,
    "lng": -87.44579,
    "state_id": "IN"
  },
  {
    "zip": 47943,
    "lat": 41.06206,
    "lng": -87.27352,
    "state_id": "IN"
  },
  {
    "zip": 47944,
    "lat": 40.61422,
    "lng": -87.33031,
    "state_id": "IN"
  },
  {
    "zip": 47946,
    "lat": 40.98176,
    "lng": -86.86838,
    "state_id": "IN"
  },
  {
    "zip": 47948,
    "lat": 40.77706,
    "lng": -87.28169,
    "state_id": "IN"
  },
  {
    "zip": 47949,
    "lat": 40.05931,
    "lng": -87.13284,
    "state_id": "IN"
  },
  {
    "zip": 47950,
    "lat": 40.80244,
    "lng": -86.65487,
    "state_id": "IN"
  },
  {
    "zip": 47951,
    "lat": 40.78947,
    "lng": -87.44134,
    "state_id": "IN"
  },
  {
    "zip": 47952,
    "lat": 39.96154,
    "lng": -87.29611,
    "state_id": "IN"
  },
  {
    "zip": 47954,
    "lat": 39.89955,
    "lng": -86.81523,
    "state_id": "IN"
  },
  {
    "zip": 47955,
    "lat": 40.19632,
    "lng": -86.86181,
    "state_id": "IN"
  },
  {
    "zip": 47957,
    "lat": 41.08616,
    "lng": -86.87475,
    "state_id": "IN"
  },
  {
    "zip": 47958,
    "lat": 40.16432,
    "lng": -87.14405,
    "state_id": "IN"
  },
  {
    "zip": 47959,
    "lat": 40.86485,
    "lng": -86.90152,
    "state_id": "IN"
  },
  {
    "zip": 47960,
    "lat": 40.78051,
    "lng": -86.74994,
    "state_id": "IN"
  },
  {
    "zip": 47963,
    "lat": 40.9716,
    "lng": -87.43977,
    "state_id": "IN"
  },
  {
    "zip": 47964,
    "lat": 40.95212,
    "lng": -87.29864,
    "state_id": "IN"
  },
  {
    "zip": 47965,
    "lat": 39.95431,
    "lng": -86.92036,
    "state_id": "IN"
  },
  {
    "zip": 47966,
    "lat": 39.88581,
    "lng": -87.39706,
    "state_id": "IN"
  },
  {
    "zip": 47967,
    "lat": 40.19667,
    "lng": -87.00164,
    "state_id": "IN"
  },
  {
    "zip": 47968,
    "lat": 39.96142,
    "lng": -86.7458,
    "state_id": "IN"
  },
  {
    "zip": 47969,
    "lat": 40.20572,
    "lng": -87.14875,
    "state_id": "IN"
  },
  {
    "zip": 47970,
    "lat": 40.47323,
    "lng": -87.1314,
    "state_id": "IN"
  },
  {
    "zip": 47971,
    "lat": 40.53213,
    "lng": -87.23824,
    "state_id": "IN"
  },
  {
    "zip": 47974,
    "lat": 40.04168,
    "lng": -87.47336,
    "state_id": "IN"
  },
  {
    "zip": 47975,
    "lat": 40.44912,
    "lng": -87.25331,
    "state_id": "IN"
  },
  {
    "zip": 47977,
    "lat": 40.75498,
    "lng": -87.15604,
    "state_id": "IN"
  },
  {
    "zip": 47978,
    "lat": 40.98123,
    "lng": -87.11551,
    "state_id": "IN"
  },
  {
    "zip": 47980,
    "lat": 40.75274,
    "lng": -86.90378,
    "state_id": "IN"
  },
  {
    "zip": 47981,
    "lat": 40.245,
    "lng": -86.93044,
    "state_id": "IN"
  },
  {
    "zip": 47982,
    "lat": 40.19735,
    "lng": -87.52696,
    "state_id": "IN"
  },
  {
    "zip": 47983,
    "lat": 40.27915,
    "lng": -86.76727,
    "state_id": "IN"
  },
  {
    "zip": 47987,
    "lat": 40.1233,
    "lng": -87.22674,
    "state_id": "IN"
  },
  {
    "zip": 47989,
    "lat": 39.88972,
    "lng": -87.05358,
    "state_id": "IN"
  },
  {
    "zip": 47990,
    "lat": 40.06187,
    "lng": -87.05431,
    "state_id": "IN"
  },
  {
    "zip": 47991,
    "lat": 40.28056,
    "lng": -87.44828,
    "state_id": "IN"
  },
  {
    "zip": 47992,
    "lat": 40.32231,
    "lng": -87.05291,
    "state_id": "IN"
  },
  {
    "zip": 47993,
    "lat": 40.31215,
    "lng": -87.4146,
    "state_id": "IN"
  },
  {
    "zip": 47994,
    "lat": 40.16927,
    "lng": -87.06217,
    "state_id": "IN"
  },
  {
    "zip": 47995,
    "lat": 40.76381,
    "lng": -87.03248,
    "state_id": "IN"
  },
  {
    "zip": 47997,
    "lat": 40.66769,
    "lng": -86.72359,
    "state_id": "IN"
  },
  {
    "zip": 66002,
    "lat": 39.54021,
    "lng": -95.13888,
    "state_id": "KS"
  },
  {
    "zip": 66006,
    "lat": 38.7959,
    "lng": -95.23833,
    "state_id": "KS"
  },
  {
    "zip": 66007,
    "lat": 39.15518,
    "lng": -94.94125,
    "state_id": "KS"
  },
  {
    "zip": 66008,
    "lat": 39.7122,
    "lng": -95.17243,
    "state_id": "KS"
  },
  {
    "zip": 66010,
    "lat": 38.09535,
    "lng": -94.98614,
    "state_id": "KS"
  },
  {
    "zip": 66012,
    "lat": 39.0672,
    "lng": -94.92261,
    "state_id": "KS"
  },
  {
    "zip": 66013,
    "lat": 38.7283,
    "lng": -94.68882,
    "state_id": "KS"
  },
  {
    "zip": 66014,
    "lat": 38.2222,
    "lng": -94.98349,
    "state_id": "KS"
  },
  {
    "zip": 66015,
    "lat": 38.07404,
    "lng": -95.40977,
    "state_id": "KS"
  },
  {
    "zip": 66016,
    "lat": 39.47351,
    "lng": -95.23196,
    "state_id": "KS"
  },
  {
    "zip": 66017,
    "lat": 39.70789,
    "lng": -95.27548,
    "state_id": "KS"
  },
  {
    "zip": 66018,
    "lat": 38.96627,
    "lng": -94.96781,
    "state_id": "KS"
  },
  {
    "zip": 66020,
    "lat": 39.33759,
    "lng": -95.11447,
    "state_id": "KS"
  },
  {
    "zip": 66021,
    "lat": 38.76546,
    "lng": -95.01247,
    "state_id": "KS"
  },
  {
    "zip": 66023,
    "lat": 39.51379,
    "lng": -95.39903,
    "state_id": "KS"
  },
  {
    "zip": 66024,
    "lat": 39.74758,
    "lng": -94.88278,
    "state_id": "KS"
  },
  {
    "zip": 66025,
    "lat": 38.89755,
    "lng": -95.08854,
    "state_id": "KS"
  },
  {
    "zip": 66026,
    "lat": 38.39633,
    "lng": -94.8679,
    "state_id": "KS"
  },
  {
    "zip": 66027,
    "lat": 39.36566,
    "lng": -94.9159,
    "state_id": "KS"
  },
  {
    "zip": 66030,
    "lat": 38.81502,
    "lng": -94.94123,
    "state_id": "KS"
  },
  {
    "zip": 66031,
    "lat": 38.83398,
    "lng": -94.89195,
    "state_id": "KS"
  },
  {
    "zip": 66032,
    "lat": 38.28232,
    "lng": -95.27321,
    "state_id": "KS"
  },
  {
    "zip": 66033,
    "lat": 38.36328,
    "lng": -95.12205,
    "state_id": "KS"
  },
  {
    "zip": 66035,
    "lat": 39.87742,
    "lng": -95.25345,
    "state_id": "KS"
  },
  {
    "zip": 66039,
    "lat": 38.10423,
    "lng": -95.16816,
    "state_id": "KS"
  },
  {
    "zip": 66040,
    "lat": 38.35272,
    "lng": -94.74359,
    "state_id": "KS"
  },
  {
    "zip": 66041,
    "lat": 39.59522,
    "lng": -95.30363,
    "state_id": "KS"
  },
  {
    "zip": 66042,
    "lat": 38.43466,
    "lng": -95.08283,
    "state_id": "KS"
  },
  {
    "zip": 66043,
    "lat": 39.25162,
    "lng": -94.8784,
    "state_id": "KS"
  },
  {
    "zip": 66044,
    "lat": 39.02889,
    "lng": -95.20854,
    "state_id": "KS"
  },
  {
    "zip": 66045,
    "lat": 38.95898,
    "lng": -95.24993,
    "state_id": "KS"
  },
  {
    "zip": 66046,
    "lat": 38.90777,
    "lng": -95.21352,
    "state_id": "KS"
  },
  {
    "zip": 66047,
    "lat": 38.88006,
    "lng": -95.34776,
    "state_id": "KS"
  },
  {
    "zip": 66048,
    "lat": 39.28287,
    "lng": -94.99415,
    "state_id": "KS"
  },
  {
    "zip": 66049,
    "lat": 38.9824,
    "lng": -95.34464,
    "state_id": "KS"
  },
  {
    "zip": 66050,
    "lat": 39.01539,
    "lng": -95.43917,
    "state_id": "KS"
  },
  {
    "zip": 66052,
    "lat": 39.01103,
    "lng": -95.04583,
    "state_id": "KS"
  },
  {
    "zip": 66053,
    "lat": 38.57885,
    "lng": -94.66935,
    "state_id": "KS"
  },
  {
    "zip": 66054,
    "lat": 39.20367,
    "lng": -95.19608,
    "state_id": "KS"
  },
  {
    "zip": 66056,
    "lat": 38.14989,
    "lng": -94.8433,
    "state_id": "KS"
  },
  {
    "zip": 66058,
    "lat": 39.53884,
    "lng": -95.51755,
    "state_id": "KS"
  },
  {
    "zip": 66060,
    "lat": 39.41839,
    "lng": -95.33215,
    "state_id": "KS"
  },
  {
    "zip": 66061,
    "lat": 38.89668,
    "lng": -94.87959,
    "state_id": "KS"
  },
  {
    "zip": 66062,
    "lat": 38.83455,
    "lng": -94.77798,
    "state_id": "KS"
  },
  {
    "zip": 66064,
    "lat": 38.47802,
    "lng": -94.98886,
    "state_id": "KS"
  },
  {
    "zip": 66066,
    "lat": 39.20263,
    "lng": -95.33169,
    "state_id": "KS"
  },
  {
    "zip": 66067,
    "lat": 38.62059,
    "lng": -95.27571,
    "state_id": "KS"
  },
  {
    "zip": 66070,
    "lat": 39.2054,
    "lng": -95.4507,
    "state_id": "KS"
  },
  {
    "zip": 66071,
    "lat": 38.57328,
    "lng": -94.86454,
    "state_id": "KS"
  },
  {
    "zip": 66072,
    "lat": 38.31342,
    "lng": -94.97301,
    "state_id": "KS"
  },
  {
    "zip": 66073,
    "lat": 39.09016,
    "lng": -95.3763,
    "state_id": "KS"
  },
  {
    "zip": 66075,
    "lat": 38.20085,
    "lng": -94.69193,
    "state_id": "KS"
  },
  {
    "zip": 66076,
    "lat": 38.6327,
    "lng": -95.44049,
    "state_id": "KS"
  },
  {
    "zip": 66078,
    "lat": 38.48312,
    "lng": -95.26222,
    "state_id": "KS"
  },
  {
    "zip": 66079,
    "lat": 38.54516,
    "lng": -95.11271,
    "state_id": "KS"
  },
  {
    "zip": 66080,
    "lat": 38.40442,
    "lng": -95.25955,
    "state_id": "KS"
  },
  {
    "zip": 66083,
    "lat": 38.73372,
    "lng": -94.83491,
    "state_id": "KS"
  },
  {
    "zip": 66085,
    "lat": 38.79952,
    "lng": -94.64959,
    "state_id": "KS"
  },
  {
    "zip": 66086,
    "lat": 39.11707,
    "lng": -95.07993,
    "state_id": "KS"
  },
  {
    "zip": 66087,
    "lat": 39.79815,
    "lng": -95.12419,
    "state_id": "KS"
  },
  {
    "zip": 66088,
    "lat": 39.33966,
    "lng": -95.46051,
    "state_id": "KS"
  },
  {
    "zip": 66090,
    "lat": 39.78557,
    "lng": -94.9732,
    "state_id": "KS"
  },
  {
    "zip": 66091,
    "lat": 38.17397,
    "lng": -95.32369,
    "state_id": "KS"
  },
  {
    "zip": 66092,
    "lat": 38.69741,
    "lng": -95.10247,
    "state_id": "KS"
  },
  {
    "zip": 66093,
    "lat": 38.21065,
    "lng": -95.49836,
    "state_id": "KS"
  },
  {
    "zip": 66094,
    "lat": 39.95772,
    "lng": -95.34293,
    "state_id": "KS"
  },
  {
    "zip": 66095,
    "lat": 38.44674,
    "lng": -95.43112,
    "state_id": "KS"
  },
  {
    "zip": 66097,
    "lat": 39.32884,
    "lng": -95.24444,
    "state_id": "KS"
  },
  {
    "zip": 66101,
    "lat": 39.11894,
    "lng": -94.62601,
    "state_id": "KS"
  },
  {
    "zip": 66102,
    "lat": 39.11296,
    "lng": -94.68955,
    "state_id": "KS"
  },
  {
    "zip": 66103,
    "lat": 39.06126,
    "lng": -94.62635,
    "state_id": "KS"
  },
  {
    "zip": 66104,
    "lat": 39.15041,
    "lng": -94.68864,
    "state_id": "KS"
  },
  {
    "zip": 66105,
    "lat": 39.08692,
    "lng": -94.63847,
    "state_id": "KS"
  },
  {
    "zip": 66106,
    "lat": 39.06661,
    "lng": -94.70296,
    "state_id": "KS"
  },
  {
    "zip": 66109,
    "lat": 39.16347,
    "lng": -94.82818,
    "state_id": "KS"
  },
  {
    "zip": 66111,
    "lat": 39.08383,
    "lng": -94.79515,
    "state_id": "KS"
  },
  {
    "zip": 66112,
    "lat": 39.11458,
    "lng": -94.77357,
    "state_id": "KS"
  },
  {
    "zip": 66115,
    "lat": 39.14401,
    "lng": -94.61098,
    "state_id": "KS"
  },
  {
    "zip": 66118,
    "lat": 39.10407,
    "lng": -94.61368,
    "state_id": "KS"
  },
  {
    "zip": 66202,
    "lat": 39.02391,
    "lng": -94.66913,
    "state_id": "KS"
  },
  {
    "zip": 66203,
    "lat": 39.02155,
    "lng": -94.7055,
    "state_id": "KS"
  },
  {
    "zip": 66204,
    "lat": 38.99286,
    "lng": -94.67712,
    "state_id": "KS"
  },
  {
    "zip": 66205,
    "lat": 39.03124,
    "lng": -94.6307,
    "state_id": "KS"
  },
  {
    "zip": 66206,
    "lat": 38.95794,
    "lng": -94.61937,
    "state_id": "KS"
  },
  {
    "zip": 66207,
    "lat": 38.95589,
    "lng": -94.6444,
    "state_id": "KS"
  },
  {
    "zip": 66208,
    "lat": 38.99923,
    "lng": -94.62965,
    "state_id": "KS"
  },
  {
    "zip": 66209,
    "lat": 38.89909,
    "lng": -94.63873,
    "state_id": "KS"
  },
  {
    "zip": 66210,
    "lat": 38.92369,
    "lng": -94.70493,
    "state_id": "KS"
  },
  {
    "zip": 66211,
    "lat": 38.92358,
    "lng": -94.63686,
    "state_id": "KS"
  },
  {
    "zip": 66212,
    "lat": 38.95637,
    "lng": -94.68147,
    "state_id": "KS"
  },
  {
    "zip": 66213,
    "lat": 38.89918,
    "lng": -94.70542,
    "state_id": "KS"
  },
  {
    "zip": 66214,
    "lat": 38.9638,
    "lng": -94.71418,
    "state_id": "KS"
  },
  {
    "zip": 66215,
    "lat": 38.95701,
    "lng": -94.74297,
    "state_id": "KS"
  },
  {
    "zip": 66216,
    "lat": 39.01384,
    "lng": -94.74115,
    "state_id": "KS"
  },
  {
    "zip": 66217,
    "lat": 39.00911,
    "lng": -94.78201,
    "state_id": "KS"
  },
  {
    "zip": 66218,
    "lat": 39.01651,
    "lng": -94.81779,
    "state_id": "KS"
  },
  {
    "zip": 66219,
    "lat": 38.95223,
    "lng": -94.77632,
    "state_id": "KS"
  },
  {
    "zip": 66220,
    "lat": 38.96109,
    "lng": -94.82148,
    "state_id": "KS"
  },
  {
    "zip": 66221,
    "lat": 38.86362,
    "lng": -94.71023,
    "state_id": "KS"
  },
  {
    "zip": 66223,
    "lat": 38.86282,
    "lng": -94.6682,
    "state_id": "KS"
  },
  {
    "zip": 66224,
    "lat": 38.86292,
    "lng": -94.62816,
    "state_id": "KS"
  },
  {
    "zip": 66226,
    "lat": 39.03056,
    "lng": -94.86005,
    "state_id": "KS"
  },
  {
    "zip": 66227,
    "lat": 38.97275,
    "lng": -94.8747,
    "state_id": "KS"
  },
  {
    "zip": 66401,
    "lat": 38.97012,
    "lng": -96.3105,
    "state_id": "KS"
  },
  {
    "zip": 66402,
    "lat": 38.91898,
    "lng": -95.84633,
    "state_id": "KS"
  },
  {
    "zip": 66403,
    "lat": 39.89652,
    "lng": -96.27739,
    "state_id": "KS"
  },
  {
    "zip": 66404,
    "lat": 39.87969,
    "lng": -96.17713,
    "state_id": "KS"
  },
  {
    "zip": 66406,
    "lat": 39.89355,
    "lng": -96.41974,
    "state_id": "KS"
  },
  {
    "zip": 66407,
    "lat": 39.23008,
    "lng": -96.19408,
    "state_id": "KS"
  },
  {
    "zip": 66408,
    "lat": 39.95945,
    "lng": -95.97163,
    "state_id": "KS"
  },
  {
    "zip": 66409,
    "lat": 38.92505,
    "lng": -95.55883,
    "state_id": "KS"
  },
  {
    "zip": 66411,
    "lat": 39.63972,
    "lng": -96.63624,
    "state_id": "KS"
  },
  {
    "zip": 66412,
    "lat": 39.91195,
    "lng": -96.77914,
    "state_id": "KS"
  },
  {
    "zip": 66413,
    "lat": 38.77457,
    "lng": -95.8818,
    "state_id": "KS"
  },
  {
    "zip": 66414,
    "lat": 38.82943,
    "lng": -95.68859,
    "state_id": "KS"
  },
  {
    "zip": 66415,
    "lat": 39.68728,
    "lng": -96.14561,
    "state_id": "KS"
  },
  {
    "zip": 66416,
    "lat": 39.52807,
    "lng": -95.86141,
    "state_id": "KS"
  },
  {
    "zip": 66417,
    "lat": 39.65367,
    "lng": -96.04327,
    "state_id": "KS"
  },
  {
    "zip": 66418,
    "lat": 39.28879,
    "lng": -95.93752,
    "state_id": "KS"
  },
  {
    "zip": 66419,
    "lat": 39.36379,
    "lng": -95.59928,
    "state_id": "KS"
  },
  {
    "zip": 66422,
    "lat": 39.33596,
    "lng": -96.0853,
    "state_id": "KS"
  },
  {
    "zip": 66423,
    "lat": 38.84864,
    "lng": -96.1365,
    "state_id": "KS"
  },
  {
    "zip": 66424,
    "lat": 39.6811,
    "lng": -95.39732,
    "state_id": "KS"
  },
  {
    "zip": 66425,
    "lat": 39.80452,
    "lng": -95.73059,
    "state_id": "KS"
  },
  {
    "zip": 66427,
    "lat": 39.66516,
    "lng": -96.44451,
    "state_id": "KS"
  },
  {
    "zip": 66428,
    "lat": 39.67275,
    "lng": -95.93078,
    "state_id": "KS"
  },
  {
    "zip": 66429,
    "lat": 39.09828,
    "lng": -95.53756,
    "state_id": "KS"
  },
  {
    "zip": 66431,
    "lat": 38.80471,
    "lng": -96.00433,
    "state_id": "KS"
  },
  {
    "zip": 66432,
    "lat": 39.48323,
    "lng": -96.06651,
    "state_id": "KS"
  },
  {
    "zip": 66434,
    "lat": 39.86797,
    "lng": -95.54648,
    "state_id": "KS"
  },
  {
    "zip": 66436,
    "lat": 39.46684,
    "lng": -95.69883,
    "state_id": "KS"
  },
  {
    "zip": 66438,
    "lat": 39.85274,
    "lng": -96.49527,
    "state_id": "KS"
  },
  {
    "zip": 66439,
    "lat": 39.65609,
    "lng": -95.54493,
    "state_id": "KS"
  },
  {
    "zip": 66440,
    "lat": 39.26436,
    "lng": -95.67877,
    "state_id": "KS"
  },
  {
    "zip": 66441,
    "lat": 38.9851,
    "lng": -96.80306,
    "state_id": "KS"
  },
  {
    "zip": 66442,
    "lat": 39.09328,
    "lng": -96.78981,
    "state_id": "KS"
  },
  {
    "zip": 66449,
    "lat": 39.3892,
    "lng": -96.86137,
    "state_id": "KS"
  },
  {
    "zip": 66451,
    "lat": 38.60958,
    "lng": -95.67311,
    "state_id": "KS"
  },
  {
    "zip": 66501,
    "lat": 39.05407,
    "lng": -96.23742,
    "state_id": "KS"
  },
  {
    "zip": 66502,
    "lat": 39.15122,
    "lng": -96.53124,
    "state_id": "KS"
  },
  {
    "zip": 66503,
    "lat": 39.26363,
    "lng": -96.68622,
    "state_id": "KS"
  },
  {
    "zip": 66506,
    "lat": 39.19592,
    "lng": -96.58159,
    "state_id": "KS"
  },
  {
    "zip": 66507,
    "lat": 39.04474,
    "lng": -96.03969,
    "state_id": "KS"
  },
  {
    "zip": 66508,
    "lat": 39.85319,
    "lng": -96.63239,
    "state_id": "KS"
  },
  {
    "zip": 66509,
    "lat": 39.33308,
    "lng": -95.78046,
    "state_id": "KS"
  },
  {
    "zip": 66510,
    "lat": 38.4926,
    "lng": -95.61258,
    "state_id": "KS"
  },
  {
    "zip": 66512,
    "lat": 39.1996,
    "lng": -95.55025,
    "state_id": "KS"
  },
  {
    "zip": 66514,
    "lat": 39.12468,
    "lng": -96.87841,
    "state_id": "KS"
  },
  {
    "zip": 66515,
    "lat": 39.94204,
    "lng": -95.70344,
    "state_id": "KS"
  },
  {
    "zip": 66516,
    "lat": 39.62407,
    "lng": -95.7236,
    "state_id": "KS"
  },
  {
    "zip": 66517,
    "lat": 39.11263,
    "lng": -96.70688,
    "state_id": "KS"
  },
  {
    "zip": 66518,
    "lat": 39.97091,
    "lng": -96.55284,
    "state_id": "KS"
  },
  {
    "zip": 66520,
    "lat": 39.41153,
    "lng": -96.61702,
    "state_id": "KS"
  },
  {
    "zip": 66521,
    "lat": 39.47984,
    "lng": -96.21953,
    "state_id": "KS"
  },
  {
    "zip": 66522,
    "lat": 39.8664,
    "lng": -95.94035,
    "state_id": "KS"
  },
  {
    "zip": 66523,
    "lat": 38.61194,
    "lng": -95.84033,
    "state_id": "KS"
  },
  {
    "zip": 66524,
    "lat": 38.79361,
    "lng": -95.50514,
    "state_id": "KS"
  },
  {
    "zip": 66526,
    "lat": 39.06351,
    "lng": -96.15572,
    "state_id": "KS"
  },
  {
    "zip": 66527,
    "lat": 39.74775,
    "lng": -95.67587,
    "state_id": "KS"
  },
  {
    "zip": 66528,
    "lat": 38.59798,
    "lng": -95.53602,
    "state_id": "KS"
  },
  {
    "zip": 66531,
    "lat": 39.312,
    "lng": -96.82996,
    "state_id": "KS"
  },
  {
    "zip": 66532,
    "lat": 39.8256,
    "lng": -95.37434,
    "state_id": "KS"
  },
  {
    "zip": 66533,
    "lat": 39.15935,
    "lng": -95.95173,
    "state_id": "KS"
  },
  {
    "zip": 66534,
    "lat": 39.88472,
    "lng": -95.83668,
    "state_id": "KS"
  },
  {
    "zip": 66535,
    "lat": 39.26247,
    "lng": -96.43746,
    "state_id": "KS"
  },
  {
    "zip": 66536,
    "lat": 39.22164,
    "lng": -96.07927,
    "state_id": "KS"
  },
  {
    "zip": 66537,
    "lat": 38.75305,
    "lng": -95.71917,
    "state_id": "KS"
  },
  {
    "zip": 66538,
    "lat": 39.85248,
    "lng": -96.04433,
    "state_id": "KS"
  },
  {
    "zip": 66539,
    "lat": 39.14559,
    "lng": -95.84829,
    "state_id": "KS"
  },
  {
    "zip": 66540,
    "lat": 39.4918,
    "lng": -95.97673,
    "state_id": "KS"
  },
  {
    "zip": 66541,
    "lat": 39.97644,
    "lng": -96.36579,
    "state_id": "KS"
  },
  {
    "zip": 66542,
    "lat": 39.01293,
    "lng": -95.54464,
    "state_id": "KS"
  },
  {
    "zip": 66543,
    "lat": 38.64159,
    "lng": -95.60051,
    "state_id": "KS"
  },
  {
    "zip": 66544,
    "lat": 39.6945,
    "lng": -96.27802,
    "state_id": "KS"
  },
  {
    "zip": 66546,
    "lat": 38.8954,
    "lng": -95.7239,
    "state_id": "KS"
  },
  {
    "zip": 66547,
    "lat": 39.22932,
    "lng": -96.30433,
    "state_id": "KS"
  },
  {
    "zip": 66548,
    "lat": 39.67862,
    "lng": -96.76116,
    "state_id": "KS"
  },
  {
    "zip": 66549,
    "lat": 39.43374,
    "lng": -96.42498,
    "state_id": "KS"
  },
  {
    "zip": 66550,
    "lat": 39.65663,
    "lng": -95.82022,
    "state_id": "KS"
  },
  {
    "zip": 66552,
    "lat": 39.59332,
    "lng": -95.62541,
    "state_id": "KS"
  },
  {
    "zip": 66554,
    "lat": 39.49441,
    "lng": -96.78557,
    "state_id": "KS"
  },
  {
    "zip": 66603,
    "lat": 39.05745,
    "lng": -95.67588,
    "state_id": "KS"
  },
  {
    "zip": 66604,
    "lat": 39.03885,
    "lng": -95.72663,
    "state_id": "KS"
  },
  {
    "zip": 66605,
    "lat": 39.01174,
    "lng": -95.63354,
    "state_id": "KS"
  },
  {
    "zip": 66606,
    "lat": 39.0616,
    "lng": -95.72116,
    "state_id": "KS"
  },
  {
    "zip": 66607,
    "lat": 39.04235,
    "lng": -95.63566,
    "state_id": "KS"
  },
  {
    "zip": 66608,
    "lat": 39.08038,
    "lng": -95.66445,
    "state_id": "KS"
  },
  {
    "zip": 66609,
    "lat": 38.98186,
    "lng": -95.66904,
    "state_id": "KS"
  },
  {
    "zip": 66610,
    "lat": 38.97402,
    "lng": -95.83751,
    "state_id": "KS"
  },
  {
    "zip": 66611,
    "lat": 39.01545,
    "lng": -95.6961,
    "state_id": "KS"
  },
  {
    "zip": 66612,
    "lat": 39.04051,
    "lng": -95.68039,
    "state_id": "KS"
  },
  {
    "zip": 66614,
    "lat": 39.01154,
    "lng": -95.84948,
    "state_id": "KS"
  },
  {
    "zip": 66615,
    "lat": 39.05488,
    "lng": -95.89654,
    "state_id": "KS"
  },
  {
    "zip": 66616,
    "lat": 39.06841,
    "lng": -95.62311,
    "state_id": "KS"
  },
  {
    "zip": 66617,
    "lat": 39.13905,
    "lng": -95.62529,
    "state_id": "KS"
  },
  {
    "zip": 66618,
    "lat": 39.14265,
    "lng": -95.7479,
    "state_id": "KS"
  },
  {
    "zip": 66619,
    "lat": 38.94778,
    "lng": -95.68821,
    "state_id": "KS"
  },
  {
    "zip": 66621,
    "lat": 39.03333,
    "lng": -95.70154,
    "state_id": "KS"
  },
  {
    "zip": 66622,
    "lat": 39.02593,
    "lng": -95.72227,
    "state_id": "KS"
  },
  {
    "zip": 66701,
    "lat": 37.82353,
    "lng": -94.76156,
    "state_id": "KS"
  },
  {
    "zip": 66710,
    "lat": 37.55598,
    "lng": -95.63299,
    "state_id": "KS"
  },
  {
    "zip": 66711,
    "lat": 37.6359,
    "lng": -94.6954,
    "state_id": "KS"
  },
  {
    "zip": 66712,
    "lat": 37.56379,
    "lng": -94.7178,
    "state_id": "KS"
  },
  {
    "zip": 66713,
    "lat": 37.03315,
    "lng": -94.78587,
    "state_id": "KS"
  },
  {
    "zip": 66714,
    "lat": 37.63639,
    "lng": -95.70033,
    "state_id": "KS"
  },
  {
    "zip": 66716,
    "lat": 37.94083,
    "lng": -95.03788,
    "state_id": "KS"
  },
  {
    "zip": 66717,
    "lat": 37.6956,
    "lng": -95.70322,
    "state_id": "KS"
  },
  {
    "zip": 66720,
    "lat": 37.64837,
    "lng": -95.4598,
    "state_id": "KS"
  },
  {
    "zip": 66724,
    "lat": 37.35102,
    "lng": -94.86267,
    "state_id": "KS"
  },
  {
    "zip": 66725,
    "lat": 37.16031,
    "lng": -94.89053,
    "state_id": "KS"
  },
  {
    "zip": 66728,
    "lat": 37.17128,
    "lng": -94.70414,
    "state_id": "KS"
  },
  {
    "zip": 66732,
    "lat": 37.80029,
    "lng": -95.19279,
    "state_id": "KS"
  },
  {
    "zip": 66733,
    "lat": 37.598,
    "lng": -95.25353,
    "state_id": "KS"
  },
  {
    "zip": 66734,
    "lat": 37.61833,
    "lng": -94.82984,
    "state_id": "KS"
  },
  {
    "zip": 66735,
    "lat": 37.52199,
    "lng": -94.7096,
    "state_id": "KS"
  },
  {
    "zip": 66736,
    "lat": 37.54957,
    "lng": -95.86047,
    "state_id": "KS"
  },
  {
    "zip": 66738,
    "lat": 38.0135,
    "lng": -94.71837,
    "state_id": "KS"
  },
  {
    "zip": 66739,
    "lat": 37.12022,
    "lng": -94.66557,
    "state_id": "KS"
  },
  {
    "zip": 66740,
    "lat": 37.47583,
    "lng": -95.36566,
    "state_id": "KS"
  },
  {
    "zip": 66741,
    "lat": 37.71754,
    "lng": -94.66124,
    "state_id": "KS"
  },
  {
    "zip": 66743,
    "lat": 37.50758,
    "lng": -94.88816,
    "state_id": "KS"
  },
  {
    "zip": 66746,
    "lat": 37.6683,
    "lng": -94.9595,
    "state_id": "KS"
  },
  {
    "zip": 66748,
    "lat": 37.79776,
    "lng": -95.43937,
    "state_id": "KS"
  },
  {
    "zip": 66749,
    "lat": 37.93755,
    "lng": -95.40782,
    "state_id": "KS"
  },
  {
    "zip": 66751,
    "lat": 37.93768,
    "lng": -95.2867,
    "state_id": "KS"
  },
  {
    "zip": 66753,
    "lat": 37.36095,
    "lng": -95.01195,
    "state_id": "KS"
  },
  {
    "zip": 66754,
    "lat": 38.00529,
    "lng": -94.88538,
    "state_id": "KS"
  },
  {
    "zip": 66755,
    "lat": 37.92252,
    "lng": -95.17279,
    "state_id": "KS"
  },
  {
    "zip": 66756,
    "lat": 37.5405,
    "lng": -94.6496,
    "state_id": "KS"
  },
  {
    "zip": 66757,
    "lat": 37.41691,
    "lng": -95.6964,
    "state_id": "KS"
  },
  {
    "zip": 66758,
    "lat": 38.01363,
    "lng": -95.59374,
    "state_id": "KS"
  },
  {
    "zip": 66760,
    "lat": 37.34559,
    "lng": -94.62459,
    "state_id": "KS"
  },
  {
    "zip": 66761,
    "lat": 37.91763,
    "lng": -95.56668,
    "state_id": "KS"
  },
  {
    "zip": 66762,
    "lat": 37.39511,
    "lng": -94.71046,
    "state_id": "KS"
  },
  {
    "zip": 66763,
    "lat": 37.46066,
    "lng": -94.6982,
    "state_id": "KS"
  },
  {
    "zip": 66767,
    "lat": 38.07437,
    "lng": -94.70859,
    "state_id": "KS"
  },
  {
    "zip": 66769,
    "lat": 37.85605,
    "lng": -94.89366,
    "state_id": "KS"
  },
  {
    "zip": 66770,
    "lat": 37.081,
    "lng": -94.71755,
    "state_id": "KS"
  },
  {
    "zip": 66771,
    "lat": 37.48941,
    "lng": -95.14771,
    "state_id": "KS"
  },
  {
    "zip": 66772,
    "lat": 37.75336,
    "lng": -95.21394,
    "state_id": "KS"
  },
  {
    "zip": 66773,
    "lat": 37.26685,
    "lng": -94.80891,
    "state_id": "KS"
  },
  {
    "zip": 66775,
    "lat": 37.7018,
    "lng": -95.14703,
    "state_id": "KS"
  },
  {
    "zip": 66776,
    "lat": 37.46913,
    "lng": -95.4874,
    "state_id": "KS"
  },
  {
    "zip": 66777,
    "lat": 37.79782,
    "lng": -95.97048,
    "state_id": "KS"
  },
  {
    "zip": 66778,
    "lat": 37.0005,
    "lng": -94.84085,
    "state_id": "KS"
  },
  {
    "zip": 66779,
    "lat": 37.83516,
    "lng": -94.98167,
    "state_id": "KS"
  },
  {
    "zip": 66780,
    "lat": 37.61659,
    "lng": -95.05567,
    "state_id": "KS"
  },
  {
    "zip": 66781,
    "lat": 37.29124,
    "lng": -94.72854,
    "state_id": "KS"
  },
  {
    "zip": 66782,
    "lat": 37.28501,
    "lng": -94.92629,
    "state_id": "KS"
  },
  {
    "zip": 66783,
    "lat": 37.87492,
    "lng": -95.73667,
    "state_id": "KS"
  },
  {
    "zip": 66801,
    "lat": 38.41305,
    "lng": -96.21801,
    "state_id": "KS"
  },
  {
    "zip": 66830,
    "lat": 38.59844,
    "lng": -96.08713,
    "state_id": "KS"
  },
  {
    "zip": 66833,
    "lat": 38.67618,
    "lng": -96.18451,
    "state_id": "KS"
  },
  {
    "zip": 66834,
    "lat": 38.85763,
    "lng": -96.44153,
    "state_id": "KS"
  },
  {
    "zip": 66835,
    "lat": 38.53648,
    "lng": -96.2609,
    "state_id": "KS"
  },
  {
    "zip": 66838,
    "lat": 38.55856,
    "lng": -96.79296,
    "state_id": "KS"
  },
  {
    "zip": 66839,
    "lat": 38.21416,
    "lng": -95.75078,
    "state_id": "KS"
  },
  {
    "zip": 66840,
    "lat": 38.06787,
    "lng": -96.87035,
    "state_id": "KS"
  },
  {
    "zip": 66842,
    "lat": 38.02465,
    "lng": -96.64486,
    "state_id": "KS"
  },
  {
    "zip": 66843,
    "lat": 38.22306,
    "lng": -96.73852,
    "state_id": "KS"
  },
  {
    "zip": 66845,
    "lat": 38.29566,
    "lng": -96.50629,
    "state_id": "KS"
  },
  {
    "zip": 66846,
    "lat": 38.65268,
    "lng": -96.47345,
    "state_id": "KS"
  },
  {
    "zip": 66849,
    "lat": 38.88197,
    "lng": -96.59298,
    "state_id": "KS"
  },
  {
    "zip": 66850,
    "lat": 38.40737,
    "lng": -96.72147,
    "state_id": "KS"
  },
  {
    "zip": 66851,
    "lat": 38.22847,
    "lng": -96.9203,
    "state_id": "KS"
  },
  {
    "zip": 66852,
    "lat": 38.0667,
    "lng": -95.89356,
    "state_id": "KS"
  },
  {
    "zip": 66853,
    "lat": 38.00135,
    "lng": -96.22165,
    "state_id": "KS"
  },
  {
    "zip": 66854,
    "lat": 38.25592,
    "lng": -95.97851,
    "state_id": "KS"
  },
  {
    "zip": 66856,
    "lat": 38.40244,
    "lng": -95.81675,
    "state_id": "KS"
  },
  {
    "zip": 66857,
    "lat": 38.0967,
    "lng": -95.6332,
    "state_id": "KS"
  },
  {
    "zip": 66858,
    "lat": 38.47998,
    "lng": -96.9324,
    "state_id": "KS"
  },
  {
    "zip": 66859,
    "lat": 38.5547,
    "lng": -96.9623,
    "state_id": "KS"
  },
  {
    "zip": 66860,
    "lat": 38.12662,
    "lng": -96.16078,
    "state_id": "KS"
  },
  {
    "zip": 66861,
    "lat": 38.36765,
    "lng": -97.00676,
    "state_id": "KS"
  },
  {
    "zip": 66862,
    "lat": 38.15555,
    "lng": -96.55431,
    "state_id": "KS"
  },
  {
    "zip": 66863,
    "lat": 37.83292,
    "lng": -96.0818,
    "state_id": "KS"
  },
  {
    "zip": 66864,
    "lat": 38.35644,
    "lng": -95.98371,
    "state_id": "KS"
  },
  {
    "zip": 66865,
    "lat": 38.21778,
    "lng": -96.28688,
    "state_id": "KS"
  },
  {
    "zip": 66866,
    "lat": 38.17281,
    "lng": -97.10714,
    "state_id": "KS"
  },
  {
    "zip": 66868,
    "lat": 38.54428,
    "lng": -95.98557,
    "state_id": "KS"
  },
  {
    "zip": 66869,
    "lat": 38.45772,
    "lng": -96.52745,
    "state_id": "KS"
  },
  {
    "zip": 66870,
    "lat": 37.9447,
    "lng": -96.01698,
    "state_id": "KS"
  },
  {
    "zip": 66871,
    "lat": 38.36051,
    "lng": -95.61706,
    "state_id": "KS"
  },
  {
    "zip": 66872,
    "lat": 38.79575,
    "lng": -96.76544,
    "state_id": "KS"
  },
  {
    "zip": 66873,
    "lat": 38.61561,
    "lng": -96.67447,
    "state_id": "KS"
  },
  {
    "zip": 66901,
    "lat": 39.55446,
    "lng": -97.6458,
    "state_id": "KS"
  },
  {
    "zip": 66930,
    "lat": 39.70368,
    "lng": -97.48045,
    "state_id": "KS"
  },
  {
    "zip": 66932,
    "lat": 39.77805,
    "lng": -98.92957,
    "state_id": "KS"
  },
  {
    "zip": 66933,
    "lat": 39.67382,
    "lng": -96.8666,
    "state_id": "KS"
  },
  {
    "zip": 66935,
    "lat": 39.8408,
    "lng": -97.63122,
    "state_id": "KS"
  },
  {
    "zip": 66936,
    "lat": 39.91883,
    "lng": -98.30664,
    "state_id": "KS"
  },
  {
    "zip": 66937,
    "lat": 39.58904,
    "lng": -97.27268,
    "state_id": "KS"
  },
  {
    "zip": 66938,
    "lat": 39.57489,
    "lng": -97.40989,
    "state_id": "KS"
  },
  {
    "zip": 66939,
    "lat": 39.85116,
    "lng": -97.89901,
    "state_id": "KS"
  },
  {
    "zip": 66940,
    "lat": 39.80433,
    "lng": -97.44934,
    "state_id": "KS"
  },
  {
    "zip": 66941,
    "lat": 39.83466,
    "lng": -98.44781,
    "state_id": "KS"
  },
  {
    "zip": 66942,
    "lat": 39.79901,
    "lng": -98.00725,
    "state_id": "KS"
  },
  {
    "zip": 66943,
    "lat": 39.67216,
    "lng": -96.97156,
    "state_id": "KS"
  },
  {
    "zip": 66944,
    "lat": 39.84333,
    "lng": -97.29999,
    "state_id": "KS"
  },
  {
    "zip": 66945,
    "lat": 39.88637,
    "lng": -96.8803,
    "state_id": "KS"
  },
  {
    "zip": 66946,
    "lat": 39.96671,
    "lng": -97.00688,
    "state_id": "KS"
  },
  {
    "zip": 66948,
    "lat": 39.59746,
    "lng": -97.85281,
    "state_id": "KS"
  },
  {
    "zip": 66949,
    "lat": 39.64736,
    "lng": -98.18758,
    "state_id": "KS"
  },
  {
    "zip": 66951,
    "lat": 39.85326,
    "lng": -99.02508,
    "state_id": "KS"
  },
  {
    "zip": 66952,
    "lat": 39.84109,
    "lng": -98.60013,
    "state_id": "KS"
  },
  {
    "zip": 66953,
    "lat": 39.6827,
    "lng": -97.12269,
    "state_id": "KS"
  },
  {
    "zip": 66955,
    "lat": 39.9651,
    "lng": -97.29377,
    "state_id": "KS"
  },
  {
    "zip": 66956,
    "lat": 39.79256,
    "lng": -98.22586,
    "state_id": "KS"
  },
  {
    "zip": 66958,
    "lat": 39.88911,
    "lng": -97.18145,
    "state_id": "KS"
  },
  {
    "zip": 66959,
    "lat": 39.92799,
    "lng": -97.53732,
    "state_id": "KS"
  },
  {
    "zip": 66960,
    "lat": 39.93681,
    "lng": -97.42648,
    "state_id": "KS"
  },
  {
    "zip": 66962,
    "lat": 39.5966,
    "lng": -97.12013,
    "state_id": "KS"
  },
  {
    "zip": 66963,
    "lat": 39.63765,
    "lng": -98.0043,
    "state_id": "KS"
  },
  {
    "zip": 66964,
    "lat": 39.94197,
    "lng": -97.80719,
    "state_id": "KS"
  },
  {
    "zip": 66966,
    "lat": 39.77505,
    "lng": -97.76346,
    "state_id": "KS"
  },
  {
    "zip": 66967,
    "lat": 39.82406,
    "lng": -98.7927,
    "state_id": "KS"
  },
  {
    "zip": 66968,
    "lat": 39.82742,
    "lng": -97.06588,
    "state_id": "KS"
  },
  {
    "zip": 66970,
    "lat": 39.93914,
    "lng": -98.0362,
    "state_id": "KS"
  },
  {
    "zip": 67001,
    "lat": 37.75974,
    "lng": -97.62979,
    "state_id": "KS"
  },
  {
    "zip": 67002,
    "lat": 37.69502,
    "lng": -97.10766,
    "state_id": "KS"
  },
  {
    "zip": 67003,
    "lat": 37.10384,
    "lng": -98.07254,
    "state_id": "KS"
  },
  {
    "zip": 67004,
    "lat": 37.28137,
    "lng": -97.75405,
    "state_id": "KS"
  },
  {
    "zip": 67005,
    "lat": 37.0651,
    "lng": -96.99179,
    "state_id": "KS"
  },
  {
    "zip": 67008,
    "lat": 37.47776,
    "lng": -96.78489,
    "state_id": "KS"
  },
  {
    "zip": 67009,
    "lat": 37.21929,
    "lng": -98.26673,
    "state_id": "KS"
  },
  {
    "zip": 67010,
    "lat": 37.66246,
    "lng": -96.97278,
    "state_id": "KS"
  },
  {
    "zip": 67012,
    "lat": 37.67653,
    "lng": -96.54984,
    "state_id": "KS"
  },
  {
    "zip": 67013,
    "lat": 37.37785,
    "lng": -97.27503,
    "state_id": "KS"
  },
  {
    "zip": 67016,
    "lat": 37.88676,
    "lng": -97.51478,
    "state_id": "KS"
  },
  {
    "zip": 67017,
    "lat": 37.8263,
    "lng": -97.11165,
    "state_id": "KS"
  },
  {
    "zip": 67018,
    "lat": 37.06949,
    "lng": -97.85843,
    "state_id": "KS"
  },
  {
    "zip": 67019,
    "lat": 37.30588,
    "lng": -96.77665,
    "state_id": "KS"
  },
  {
    "zip": 67020,
    "lat": 38.00553,
    "lng": -97.66875,
    "state_id": "KS"
  },
  {
    "zip": 67021,
    "lat": 37.7785,
    "lng": -98.91531,
    "state_id": "KS"
  },
  {
    "zip": 67022,
    "lat": 37.07835,
    "lng": -97.63522,
    "state_id": "KS"
  },
  {
    "zip": 67023,
    "lat": 37.3349,
    "lng": -96.61424,
    "state_id": "KS"
  },
  {
    "zip": 67024,
    "lat": 37.11328,
    "lng": -96.48699,
    "state_id": "KS"
  },
  {
    "zip": 67025,
    "lat": 37.64132,
    "lng": -97.78659,
    "state_id": "KS"
  },
  {
    "zip": 67026,
    "lat": 37.51121,
    "lng": -97.49331,
    "state_id": "KS"
  },
  {
    "zip": 67028,
    "lat": 37.45983,
    "lng": -98.93647,
    "state_id": "KS"
  },
  {
    "zip": 67029,
    "lat": 37.17086,
    "lng": -99.27483,
    "state_id": "KS"
  },
  {
    "zip": 67030,
    "lat": 37.78539,
    "lng": -97.54744,
    "state_id": "KS"
  },
  {
    "zip": 67031,
    "lat": 37.40166,
    "lng": -97.64047,
    "state_id": "KS"
  },
  {
    "zip": 67035,
    "lat": 37.64861,
    "lng": -98.39197,
    "state_id": "KS"
  },
  {
    "zip": 67036,
    "lat": 37.29757,
    "lng": -97.87666,
    "state_id": "KS"
  },
  {
    "zip": 67037,
    "lat": 37.56442,
    "lng": -97.21987,
    "state_id": "KS"
  },
  {
    "zip": 67038,
    "lat": 37.10518,
    "lng": -96.71875,
    "state_id": "KS"
  },
  {
    "zip": 67039,
    "lat": 37.52326,
    "lng": -96.97668,
    "state_id": "KS"
  },
  {
    "zip": 67041,
    "lat": 38.05399,
    "lng": -97.12999,
    "state_id": "KS"
  },
  {
    "zip": 67042,
    "lat": 37.84881,
    "lng": -96.7987,
    "state_id": "KS"
  },
  {
    "zip": 67045,
    "lat": 37.86081,
    "lng": -96.34466,
    "state_id": "KS"
  },
  {
    "zip": 67047,
    "lat": 37.62477,
    "lng": -96.04727,
    "state_id": "KS"
  },
  {
    "zip": 67049,
    "lat": 37.17744,
    "lng": -97.84637,
    "state_id": "KS"
  },
  {
    "zip": 67050,
    "lat": 37.67384,
    "lng": -97.67834,
    "state_id": "KS"
  },
  {
    "zip": 67051,
    "lat": 37.08663,
    "lng": -97.19388,
    "state_id": "KS"
  },
  {
    "zip": 67052,
    "lat": 37.6582,
    "lng": -97.57571,
    "state_id": "KS"
  },
  {
    "zip": 67053,
    "lat": 38.24685,
    "lng": -97.34623,
    "state_id": "KS"
  },
  {
    "zip": 67054,
    "lat": 37.57556,
    "lng": -99.31558,
    "state_id": "KS"
  },
  {
    "zip": 67055,
    "lat": 37.78959,
    "lng": -97.19969,
    "state_id": "KS"
  },
  {
    "zip": 67056,
    "lat": 38.022,
    "lng": -97.52539,
    "state_id": "KS"
  },
  {
    "zip": 67057,
    "lat": 37.06475,
    "lng": -98.77815,
    "state_id": "KS"
  },
  {
    "zip": 67058,
    "lat": 37.31382,
    "lng": -98.05662,
    "state_id": "KS"
  },
  {
    "zip": 67059,
    "lat": 37.63034,
    "lng": -99.09261,
    "state_id": "KS"
  },
  {
    "zip": 67060,
    "lat": 37.54617,
    "lng": -97.35955,
    "state_id": "KS"
  },
  {
    "zip": 67061,
    "lat": 37.09139,
    "lng": -98.37254,
    "state_id": "KS"
  },
  {
    "zip": 67062,
    "lat": 38.14519,
    "lng": -97.43709,
    "state_id": "KS"
  },
  {
    "zip": 67063,
    "lat": 38.34424,
    "lng": -97.22651,
    "state_id": "KS"
  },
  {
    "zip": 67065,
    "lat": 37.4685,
    "lng": -98.52693,
    "state_id": "KS"
  },
  {
    "zip": 67066,
    "lat": 37.76724,
    "lng": -98.76112,
    "state_id": "KS"
  },
  {
    "zip": 67067,
    "lat": 37.79345,
    "lng": -97.27366,
    "state_id": "KS"
  },
  {
    "zip": 67068,
    "lat": 37.61658,
    "lng": -98.10825,
    "state_id": "KS"
  },
  {
    "zip": 67070,
    "lat": 37.07357,
    "lng": -98.53023,
    "state_id": "KS"
  },
  {
    "zip": 67071,
    "lat": 37.24551,
    "lng": -98.88136,
    "state_id": "KS"
  },
  {
    "zip": 67072,
    "lat": 37.54523,
    "lng": -96.61056,
    "state_id": "KS"
  },
  {
    "zip": 67073,
    "lat": 38.38424,
    "lng": -97.32946,
    "state_id": "KS"
  },
  {
    "zip": 67074,
    "lat": 37.67221,
    "lng": -96.72095,
    "state_id": "KS"
  },
  {
    "zip": 67101,
    "lat": 37.78502,
    "lng": -97.46344,
    "state_id": "KS"
  },
  {
    "zip": 67103,
    "lat": 37.2663,
    "lng": -97.56059,
    "state_id": "KS"
  },
  {
    "zip": 67104,
    "lat": 37.27084,
    "lng": -98.65221,
    "state_id": "KS"
  },
  {
    "zip": 67105,
    "lat": 37.23336,
    "lng": -97.67581,
    "state_id": "KS"
  },
  {
    "zip": 67106,
    "lat": 37.46711,
    "lng": -97.75732,
    "state_id": "KS"
  },
  {
    "zip": 67107,
    "lat": 38.1955,
    "lng": -97.53977,
    "state_id": "KS"
  },
  {
    "zip": 67108,
    "lat": 37.81399,
    "lng": -97.68202,
    "state_id": "KS"
  },
  {
    "zip": 67109,
    "lat": 37.56373,
    "lng": -99.4826,
    "state_id": "KS"
  },
  {
    "zip": 67110,
    "lat": 37.46849,
    "lng": -97.22605,
    "state_id": "KS"
  },
  {
    "zip": 67111,
    "lat": 37.6148,
    "lng": -97.93379,
    "state_id": "KS"
  },
  {
    "zip": 67112,
    "lat": 37.46609,
    "lng": -98.41312,
    "state_id": "KS"
  },
  {
    "zip": 67114,
    "lat": 38.07349,
    "lng": -97.28875,
    "state_id": "KS"
  },
  {
    "zip": 67117,
    "lat": 38.07424,
    "lng": -97.34734,
    "state_id": "KS"
  },
  {
    "zip": 67118,
    "lat": 37.47648,
    "lng": -97.8552,
    "state_id": "KS"
  },
  {
    "zip": 67119,
    "lat": 37.23761,
    "lng": -97.18683,
    "state_id": "KS"
  },
  {
    "zip": 67120,
    "lat": 37.46413,
    "lng": -97.37749,
    "state_id": "KS"
  },
  {
    "zip": 67122,
    "lat": 37.62809,
    "lng": -96.42804,
    "state_id": "KS"
  },
  {
    "zip": 67123,
    "lat": 37.94477,
    "lng": -96.99326,
    "state_id": "KS"
  },
  {
    "zip": 67124,
    "lat": 37.63357,
    "lng": -98.77254,
    "state_id": "KS"
  },
  {
    "zip": 67127,
    "lat": 37.16743,
    "lng": -99.4884,
    "state_id": "KS"
  },
  {
    "zip": 67131,
    "lat": 37.43043,
    "lng": -96.95302,
    "state_id": "KS"
  },
  {
    "zip": 67132,
    "lat": 37.85389,
    "lng": -96.58476,
    "state_id": "KS"
  },
  {
    "zip": 67133,
    "lat": 37.57647,
    "lng": -97.10355,
    "state_id": "KS"
  },
  {
    "zip": 67134,
    "lat": 37.48606,
    "lng": -98.68293,
    "state_id": "KS"
  },
  {
    "zip": 67135,
    "lat": 37.91728,
    "lng": -97.45832,
    "state_id": "KS"
  },
  {
    "zip": 67137,
    "lat": 37.63344,
    "lng": -96.22274,
    "state_id": "KS"
  },
  {
    "zip": 67138,
    "lat": 37.27319,
    "lng": -98.42033,
    "state_id": "KS"
  },
  {
    "zip": 67140,
    "lat": 37.07934,
    "lng": -97.35645,
    "state_id": "KS"
  },
  {
    "zip": 67142,
    "lat": 37.45694,
    "lng": -98.11747,
    "state_id": "KS"
  },
  {
    "zip": 67143,
    "lat": 37.34987,
    "lng": -98.94877,
    "state_id": "KS"
  },
  {
    "zip": 67144,
    "lat": 37.81608,
    "lng": -97.00734,
    "state_id": "KS"
  },
  {
    "zip": 67146,
    "lat": 37.3894,
    "lng": -97.10311,
    "state_id": "KS"
  },
  {
    "zip": 67147,
    "lat": 37.85968,
    "lng": -97.30863,
    "state_id": "KS"
  },
  {
    "zip": 67149,
    "lat": 37.53821,
    "lng": -97.62587,
    "state_id": "KS"
  },
  {
    "zip": 67150,
    "lat": 37.01191,
    "lng": -98.21789,
    "state_id": "KS"
  },
  {
    "zip": 67151,
    "lat": 38.14187,
    "lng": -97.24906,
    "state_id": "KS"
  },
  {
    "zip": 67152,
    "lat": 37.26012,
    "lng": -97.41442,
    "state_id": "KS"
  },
  {
    "zip": 67154,
    "lat": 37.97276,
    "lng": -97.12332,
    "state_id": "KS"
  },
  {
    "zip": 67155,
    "lat": 37.27844,
    "lng": -99.13193,
    "state_id": "KS"
  },
  {
    "zip": 67156,
    "lat": 37.25321,
    "lng": -96.96383,
    "state_id": "KS"
  },
  {
    "zip": 67159,
    "lat": 37.41512,
    "lng": -98.30722,
    "state_id": "KS"
  },
  {
    "zip": 67202,
    "lat": 37.68676,
    "lng": -97.33527,
    "state_id": "KS"
  },
  {
    "zip": 67203,
    "lat": 37.70408,
    "lng": -97.36496,
    "state_id": "KS"
  },
  {
    "zip": 67204,
    "lat": 37.77246,
    "lng": -97.36352,
    "state_id": "KS"
  },
  {
    "zip": 67205,
    "lat": 37.74992,
    "lng": -97.42257,
    "state_id": "KS"
  },
  {
    "zip": 67206,
    "lat": 37.70383,
    "lng": -97.22531,
    "state_id": "KS"
  },
  {
    "zip": 67207,
    "lat": 37.66641,
    "lng": -97.22768,
    "state_id": "KS"
  },
  {
    "zip": 67208,
    "lat": 37.70447,
    "lng": -97.27924,
    "state_id": "KS"
  },
  {
    "zip": 67209,
    "lat": 37.66276,
    "lng": -97.43544,
    "state_id": "KS"
  },
  {
    "zip": 67210,
    "lat": 37.63132,
    "lng": -97.24718,
    "state_id": "KS"
  },
  {
    "zip": 67211,
    "lat": 37.66684,
    "lng": -97.31741,
    "state_id": "KS"
  },
  {
    "zip": 67212,
    "lat": 37.70198,
    "lng": -97.43641,
    "state_id": "KS"
  },
  {
    "zip": 67213,
    "lat": 37.66726,
    "lng": -97.36497,
    "state_id": "KS"
  },
  {
    "zip": 67214,
    "lat": 37.70719,
    "lng": -97.31785,
    "state_id": "KS"
  },
  {
    "zip": 67215,
    "lat": 37.61458,
    "lng": -97.42554,
    "state_id": "KS"
  },
  {
    "zip": 67216,
    "lat": 37.60861,
    "lng": -97.31388,
    "state_id": "KS"
  },
  {
    "zip": 67217,
    "lat": 37.61547,
    "lng": -97.36277,
    "state_id": "KS"
  },
  {
    "zip": 67218,
    "lat": 37.66848,
    "lng": -97.28038,
    "state_id": "KS"
  },
  {
    "zip": 67219,
    "lat": 37.77181,
    "lng": -97.31744,
    "state_id": "KS"
  },
  {
    "zip": 67220,
    "lat": 37.75153,
    "lng": -97.27911,
    "state_id": "KS"
  },
  {
    "zip": 67223,
    "lat": 37.74254,
    "lng": -97.49794,
    "state_id": "KS"
  },
  {
    "zip": 67226,
    "lat": 37.77329,
    "lng": -97.21529,
    "state_id": "KS"
  },
  {
    "zip": 67227,
    "lat": 37.62819,
    "lng": -97.49969,
    "state_id": "KS"
  },
  {
    "zip": 67228,
    "lat": 37.76025,
    "lng": -97.17189,
    "state_id": "KS"
  },
  {
    "zip": 67230,
    "lat": 37.68675,
    "lng": -97.17129,
    "state_id": "KS"
  },
  {
    "zip": 67232,
    "lat": 37.63566,
    "lng": -97.17008,
    "state_id": "KS"
  },
  {
    "zip": 67235,
    "lat": 37.68913,
    "lng": -97.49939,
    "state_id": "KS"
  },
  {
    "zip": 67260,
    "lat": 37.71943,
    "lng": -97.29327,
    "state_id": "KS"
  },
  {
    "zip": 67301,
    "lat": 37.20891,
    "lng": -95.7534,
    "state_id": "KS"
  },
  {
    "zip": 67330,
    "lat": 37.16148,
    "lng": -95.31531,
    "state_id": "KS"
  },
  {
    "zip": 67332,
    "lat": 37.04514,
    "lng": -95.23446,
    "state_id": "KS"
  },
  {
    "zip": 67333,
    "lat": 37.04672,
    "lng": -95.88109,
    "state_id": "KS"
  },
  {
    "zip": 67334,
    "lat": 37.02382,
    "lng": -96.17735,
    "state_id": "KS"
  },
  {
    "zip": 67335,
    "lat": 37.27467,
    "lng": -95.53669,
    "state_id": "KS"
  },
  {
    "zip": 67336,
    "lat": 37.05519,
    "lng": -95.07863,
    "state_id": "KS"
  },
  {
    "zip": 67337,
    "lat": 37.05527,
    "lng": -95.60147,
    "state_id": "KS"
  },
  {
    "zip": 67340,
    "lat": 37.0596,
    "lng": -95.71323,
    "state_id": "KS"
  },
  {
    "zip": 67341,
    "lat": 37.34382,
    "lng": -95.4354,
    "state_id": "KS"
  },
  {
    "zip": 67342,
    "lat": 37.05927,
    "lng": -95.35755,
    "state_id": "KS"
  },
  {
    "zip": 67344,
    "lat": 37.28806,
    "lng": -95.95266,
    "state_id": "KS"
  },
  {
    "zip": 67345,
    "lat": 37.37753,
    "lng": -96.20238,
    "state_id": "KS"
  },
  {
    "zip": 67346,
    "lat": 37.32137,
    "lng": -96.44325,
    "state_id": "KS"
  },
  {
    "zip": 67347,
    "lat": 37.12736,
    "lng": -95.95337,
    "state_id": "KS"
  },
  {
    "zip": 67349,
    "lat": 37.48858,
    "lng": -96.29883,
    "state_id": "KS"
  },
  {
    "zip": 67351,
    "lat": 37.13773,
    "lng": -95.57102,
    "state_id": "KS"
  },
  {
    "zip": 67352,
    "lat": 37.35616,
    "lng": -96.08455,
    "state_id": "KS"
  },
  {
    "zip": 67353,
    "lat": 37.31356,
    "lng": -96.31241,
    "state_id": "KS"
  },
  {
    "zip": 67354,
    "lat": 37.20917,
    "lng": -95.4095,
    "state_id": "KS"
  },
  {
    "zip": 67355,
    "lat": 37.03394,
    "lng": -96.00936,
    "state_id": "KS"
  },
  {
    "zip": 67356,
    "lat": 37.17778,
    "lng": -95.14704,
    "state_id": "KS"
  },
  {
    "zip": 67357,
    "lat": 37.34612,
    "lng": -95.26184,
    "state_id": "KS"
  },
  {
    "zip": 67360,
    "lat": 37.05094,
    "lng": -96.1051,
    "state_id": "KS"
  },
  {
    "zip": 67361,
    "lat": 37.13827,
    "lng": -96.21821,
    "state_id": "KS"
  },
  {
    "zip": 67363,
    "lat": 37.33665,
    "lng": -95.72237,
    "state_id": "KS"
  },
  {
    "zip": 67364,
    "lat": 37.05268,
    "lng": -95.81565,
    "state_id": "KS"
  },
  {
    "zip": 67401,
    "lat": 38.83401,
    "lng": -97.64584,
    "state_id": "KS"
  },
  {
    "zip": 67410,
    "lat": 38.9558,
    "lng": -97.22134,
    "state_id": "KS"
  },
  {
    "zip": 67416,
    "lat": 38.67393,
    "lng": -97.58048,
    "state_id": "KS"
  },
  {
    "zip": 67417,
    "lat": 39.43747,
    "lng": -97.5524,
    "state_id": "KS"
  },
  {
    "zip": 67418,
    "lat": 39.17113,
    "lng": -98.04251,
    "state_id": "KS"
  },
  {
    "zip": 67420,
    "lat": 39.39875,
    "lng": -98.08543,
    "state_id": "KS"
  },
  {
    "zip": 67422,
    "lat": 39.02357,
    "lng": -97.58752,
    "state_id": "KS"
  },
  {
    "zip": 67423,
    "lat": 38.95121,
    "lng": -97.98209,
    "state_id": "KS"
  },
  {
    "zip": 67425,
    "lat": 38.77538,
    "lng": -97.92783,
    "state_id": "KS"
  },
  {
    "zip": 67427,
    "lat": 38.49619,
    "lng": -98.39756,
    "state_id": "KS"
  },
  {
    "zip": 67428,
    "lat": 38.37307,
    "lng": -97.41873,
    "state_id": "KS"
  },
  {
    "zip": 67430,
    "lat": 39.52877,
    "lng": -98.43012,
    "state_id": "KS"
  },
  {
    "zip": 67431,
    "lat": 38.9559,
    "lng": -97.00941,
    "state_id": "KS"
  },
  {
    "zip": 67432,
    "lat": 39.3319,
    "lng": -97.18703,
    "state_id": "KS"
  },
  {
    "zip": 67436,
    "lat": 39.27721,
    "lng": -97.73254,
    "state_id": "KS"
  },
  {
    "zip": 67437,
    "lat": 39.5112,
    "lng": -98.55543,
    "state_id": "KS"
  },
  {
    "zip": 67438,
    "lat": 38.49746,
    "lng": -97.28224,
    "state_id": "KS"
  },
  {
    "zip": 67439,
    "lat": 38.7545,
    "lng": -98.21984,
    "state_id": "KS"
  },
  {
    "zip": 67441,
    "lat": 38.87631,
    "lng": -97.10467,
    "state_id": "KS"
  },
  {
    "zip": 67442,
    "lat": 38.65602,
    "lng": -97.77637,
    "state_id": "KS"
  },
  {
    "zip": 67443,
    "lat": 38.3807,
    "lng": -97.52213,
    "state_id": "KS"
  },
  {
    "zip": 67444,
    "lat": 38.54709,
    "lng": -98.12774,
    "state_id": "KS"
  },
  {
    "zip": 67445,
    "lat": 39.37644,
    "lng": -97.81922,
    "state_id": "KS"
  },
  {
    "zip": 67446,
    "lat": 39.47594,
    "lng": -98.30657,
    "state_id": "KS"
  },
  {
    "zip": 67447,
    "lat": 39.48345,
    "lng": -96.97114,
    "state_id": "KS"
  },
  {
    "zip": 67448,
    "lat": 38.65158,
    "lng": -97.41275,
    "state_id": "KS"
  },
  {
    "zip": 67449,
    "lat": 38.67672,
    "lng": -96.89412,
    "state_id": "KS"
  },
  {
    "zip": 67450,
    "lat": 38.61435,
    "lng": -98.44685,
    "state_id": "KS"
  },
  {
    "zip": 67451,
    "lat": 38.70252,
    "lng": -97.12736,
    "state_id": "KS"
  },
  {
    "zip": 67452,
    "lat": 39.23879,
    "lng": -98.37167,
    "state_id": "KS"
  },
  {
    "zip": 67454,
    "lat": 38.69832,
    "lng": -98.11145,
    "state_id": "KS"
  },
  {
    "zip": 67455,
    "lat": 39.0227,
    "lng": -98.16532,
    "state_id": "KS"
  },
  {
    "zip": 67456,
    "lat": 38.59603,
    "lng": -97.67428,
    "state_id": "KS"
  },
  {
    "zip": 67457,
    "lat": 38.39215,
    "lng": -97.99348,
    "state_id": "KS"
  },
  {
    "zip": 67458,
    "lat": 39.16778,
    "lng": -97.29706,
    "state_id": "KS"
  },
  {
    "zip": 67459,
    "lat": 38.60577,
    "lng": -98.3344,
    "state_id": "KS"
  },
  {
    "zip": 67460,
    "lat": 38.38361,
    "lng": -97.69807,
    "state_id": "KS"
  },
  {
    "zip": 67464,
    "lat": 38.56462,
    "lng": -97.90119,
    "state_id": "KS"
  },
  {
    "zip": 67466,
    "lat": 39.33071,
    "lng": -97.47995,
    "state_id": "KS"
  },
  {
    "zip": 67467,
    "lat": 39.14259,
    "lng": -97.69271,
    "state_id": "KS"
  },
  {
    "zip": 67468,
    "lat": 39.46077,
    "lng": -97.24666,
    "state_id": "KS"
  },
  {
    "zip": 67470,
    "lat": 38.91303,
    "lng": -97.48813,
    "state_id": "KS"
  },
  {
    "zip": 67473,
    "lat": 39.37717,
    "lng": -98.73596,
    "state_id": "KS"
  },
  {
    "zip": 67474,
    "lat": 39.57511,
    "lng": -98.71912,
    "state_id": "KS"
  },
  {
    "zip": 67475,
    "lat": 38.59105,
    "lng": -97.05189,
    "state_id": "KS"
  },
  {
    "zip": 67478,
    "lat": 39.3861,
    "lng": -97.9388,
    "state_id": "KS"
  },
  {
    "zip": 67480,
    "lat": 38.9717,
    "lng": -97.40421,
    "state_id": "KS"
  },
  {
    "zip": 67481,
    "lat": 39.01246,
    "lng": -98.3866,
    "state_id": "KS"
  },
  {
    "zip": 67482,
    "lat": 39.02685,
    "lng": -97.25974,
    "state_id": "KS"
  },
  {
    "zip": 67483,
    "lat": 38.56148,
    "lng": -97.2057,
    "state_id": "KS"
  },
  {
    "zip": 67484,
    "lat": 38.99157,
    "lng": -97.83187,
    "state_id": "KS"
  },
  {
    "zip": 67485,
    "lat": 39.29041,
    "lng": -98.50858,
    "state_id": "KS"
  },
  {
    "zip": 67487,
    "lat": 39.17803,
    "lng": -97.07164,
    "state_id": "KS"
  },
  {
    "zip": 67490,
    "lat": 38.78992,
    "lng": -98.44222,
    "state_id": "KS"
  },
  {
    "zip": 67491,
    "lat": 38.38753,
    "lng": -97.90397,
    "state_id": "KS"
  },
  {
    "zip": 67492,
    "lat": 38.81125,
    "lng": -96.96393,
    "state_id": "KS"
  },
  {
    "zip": 67501,
    "lat": 37.98307,
    "lng": -97.92973,
    "state_id": "KS"
  },
  {
    "zip": 67502,
    "lat": 38.12717,
    "lng": -97.9291,
    "state_id": "KS"
  },
  {
    "zip": 67505,
    "lat": 38.0282,
    "lng": -97.9432,
    "state_id": "KS"
  },
  {
    "zip": 67510,
    "lat": 37.99965,
    "lng": -98.20665,
    "state_id": "KS"
  },
  {
    "zip": 67511,
    "lat": 38.4325,
    "lng": -99.04725,
    "state_id": "KS"
  },
  {
    "zip": 67512,
    "lat": 38.21793,
    "lng": -98.34025,
    "state_id": "KS"
  },
  {
    "zip": 67513,
    "lat": 38.44699,
    "lng": -99.54501,
    "state_id": "KS"
  },
  {
    "zip": 67514,
    "lat": 37.86492,
    "lng": -98.20699,
    "state_id": "KS"
  },
  {
    "zip": 67515,
    "lat": 38.6247,
    "lng": -100.06846,
    "state_id": "KS"
  },
  {
    "zip": 67516,
    "lat": 38.37962,
    "lng": -99.68313,
    "state_id": "KS"
  },
  {
    "zip": 67518,
    "lat": 38.41458,
    "lng": -100.16733,
    "state_id": "KS"
  },
  {
    "zip": 67519,
    "lat": 37.93037,
    "lng": -99.09108,
    "state_id": "KS"
  },
  {
    "zip": 67520,
    "lat": 38.58583,
    "lng": -99.1829,
    "state_id": "KS"
  },
  {
    "zip": 67521,
    "lat": 38.62312,
    "lng": -99.72282,
    "state_id": "KS"
  },
  {
    "zip": 67522,
    "lat": 38.12467,
    "lng": -97.74405,
    "state_id": "KS"
  },
  {
    "zip": 67523,
    "lat": 38.1975,
    "lng": -99.5493,
    "state_id": "KS"
  },
  {
    "zip": 67524,
    "lat": 38.37532,
    "lng": -98.37878,
    "state_id": "KS"
  },
  {
    "zip": 67525,
    "lat": 38.56877,
    "lng": -98.57077,
    "state_id": "KS"
  },
  {
    "zip": 67526,
    "lat": 38.33481,
    "lng": -98.55291,
    "state_id": "KS"
  },
  {
    "zip": 67529,
    "lat": 38.07283,
    "lng": -99.25326,
    "state_id": "KS"
  },
  {
    "zip": 67530,
    "lat": 38.35287,
    "lng": -98.79363,
    "state_id": "KS"
  },
  {
    "zip": 67543,
    "lat": 37.87758,
    "lng": -97.79479,
    "state_id": "KS"
  },
  {
    "zip": 67544,
    "lat": 38.58228,
    "lng": -98.75738,
    "state_id": "KS"
  },
  {
    "zip": 67545,
    "lat": 38.16404,
    "lng": -98.61994,
    "state_id": "KS"
  },
  {
    "zip": 67546,
    "lat": 38.23255,
    "lng": -97.81946,
    "state_id": "KS"
  },
  {
    "zip": 67547,
    "lat": 37.93351,
    "lng": -99.45756,
    "state_id": "KS"
  },
  {
    "zip": 67548,
    "lat": 38.58312,
    "lng": -99.34515,
    "state_id": "KS"
  },
  {
    "zip": 67550,
    "lat": 38.18491,
    "lng": -99.12236,
    "state_id": "KS"
  },
  {
    "zip": 67552,
    "lat": 37.86625,
    "lng": -99.22853,
    "state_id": "KS"
  },
  {
    "zip": 67553,
    "lat": 38.66079,
    "lng": -99.30721,
    "state_id": "KS"
  },
  {
    "zip": 67554,
    "lat": 38.37452,
    "lng": -98.1931,
    "state_id": "KS"
  },
  {
    "zip": 67556,
    "lat": 38.6118,
    "lng": -99.52892,
    "state_id": "KS"
  },
  {
    "zip": 67557,
    "lat": 37.92781,
    "lng": -98.96403,
    "state_id": "KS"
  },
  {
    "zip": 67559,
    "lat": 38.39011,
    "lng": -99.45502,
    "state_id": "KS"
  },
  {
    "zip": 67560,
    "lat": 38.39492,
    "lng": -99.95133,
    "state_id": "KS"
  },
  {
    "zip": 67561,
    "lat": 38.10633,
    "lng": -98.0948,
    "state_id": "KS"
  },
  {
    "zip": 67563,
    "lat": 37.8441,
    "lng": -99.55638,
    "state_id": "KS"
  },
  {
    "zip": 67564,
    "lat": 38.53174,
    "lng": -98.93141,
    "state_id": "KS"
  },
  {
    "zip": 67565,
    "lat": 38.60389,
    "lng": -99.04069,
    "state_id": "KS"
  },
  {
    "zip": 67566,
    "lat": 37.93847,
    "lng": -98.09774,
    "state_id": "KS"
  },
  {
    "zip": 67567,
    "lat": 38.2727,
    "lng": -98.97772,
    "state_id": "KS"
  },
  {
    "zip": 67568,
    "lat": 38.01233,
    "lng": -98.3093,
    "state_id": "KS"
  },
  {
    "zip": 67570,
    "lat": 37.78635,
    "lng": -97.96912,
    "state_id": "KS"
  },
  {
    "zip": 67572,
    "lat": 38.68666,
    "lng": -99.9088,
    "state_id": "KS"
  },
  {
    "zip": 67573,
    "lat": 38.26846,
    "lng": -98.41889,
    "state_id": "KS"
  },
  {
    "zip": 67574,
    "lat": 38.22188,
    "lng": -99.38504,
    "state_id": "KS"
  },
  {
    "zip": 67575,
    "lat": 38.41225,
    "lng": -99.26178,
    "state_id": "KS"
  },
  {
    "zip": 67576,
    "lat": 38.03365,
    "lng": -98.79384,
    "state_id": "KS"
  },
  {
    "zip": 67578,
    "lat": 37.98588,
    "lng": -98.58098,
    "state_id": "KS"
  },
  {
    "zip": 67579,
    "lat": 38.18331,
    "lng": -98.21858,
    "state_id": "KS"
  },
  {
    "zip": 67581,
    "lat": 37.98363,
    "lng": -98.41692,
    "state_id": "KS"
  },
  {
    "zip": 67583,
    "lat": 37.80188,
    "lng": -98.47697,
    "state_id": "KS"
  },
  {
    "zip": 67584,
    "lat": 38.68113,
    "lng": -100.16369,
    "state_id": "KS"
  },
  {
    "zip": 67601,
    "lat": 38.8776,
    "lng": -99.34833,
    "state_id": "KS"
  },
  {
    "zip": 67621,
    "lat": 39.82467,
    "lng": -99.1256,
    "state_id": "KS"
  },
  {
    "zip": 67622,
    "lat": 39.8947,
    "lng": -99.72988,
    "state_id": "KS"
  },
  {
    "zip": 67623,
    "lat": 39.45273,
    "lng": -98.95817,
    "state_id": "KS"
  },
  {
    "zip": 67625,
    "lat": 39.38519,
    "lng": -99.68379,
    "state_id": "KS"
  },
  {
    "zip": 67626,
    "lat": 38.88536,
    "lng": -98.70928,
    "state_id": "KS"
  },
  {
    "zip": 67628,
    "lat": 39.622,
    "lng": -98.99409,
    "state_id": "KS"
  },
  {
    "zip": 67629,
    "lat": 39.70387,
    "lng": -100.18538,
    "state_id": "KS"
  },
  {
    "zip": 67631,
    "lat": 39.00386,
    "lng": -100.08622,
    "state_id": "KS"
  },
  {
    "zip": 67632,
    "lat": 39.3398,
    "lng": -99.58165,
    "state_id": "KS"
  },
  {
    "zip": 67634,
    "lat": 38.81919,
    "lng": -98.60734,
    "state_id": "KS"
  },
  {
    "zip": 67635,
    "lat": 39.59486,
    "lng": -100.43935,
    "state_id": "KS"
  },
  {
    "zip": 67637,
    "lat": 38.93773,
    "lng": -99.59843,
    "state_id": "KS"
  },
  {
    "zip": 67638,
    "lat": 39.63033,
    "lng": -98.85681,
    "state_id": "KS"
  },
  {
    "zip": 67639,
    "lat": 39.6372,
    "lng": -99.30931,
    "state_id": "KS"
  },
  {
    "zip": 67640,
    "lat": 38.90912,
    "lng": -99.04673,
    "state_id": "KS"
  },
  {
    "zip": 67642,
    "lat": 39.35816,
    "lng": -99.84437,
    "state_id": "KS"
  },
  {
    "zip": 67643,
    "lat": 39.67623,
    "lng": -100.30101,
    "state_id": "KS"
  },
  {
    "zip": 67644,
    "lat": 39.6333,
    "lng": -99.15781,
    "state_id": "KS"
  },
  {
    "zip": 67645,
    "lat": 39.59942,
    "lng": -99.87089,
    "state_id": "KS"
  },
  {
    "zip": 67646,
    "lat": 39.63451,
    "lng": -99.57617,
    "state_id": "KS"
  },
  {
    "zip": 67647,
    "lat": 39.93674,
    "lng": -99.53872,
    "state_id": "KS"
  },
  {
    "zip": 67648,
    "lat": 39.07901,
    "lng": -98.5683,
    "state_id": "KS"
  },
  {
    "zip": 67649,
    "lat": 39.12288,
    "lng": -98.68042,
    "state_id": "KS"
  },
  {
    "zip": 67650,
    "lat": 39.37345,
    "lng": -100.10624,
    "state_id": "KS"
  },
  {
    "zip": 67651,
    "lat": 39.20742,
    "lng": -99.05138,
    "state_id": "KS"
  },
  {
    "zip": 67653,
    "lat": 39.90702,
    "lng": -100.21274,
    "state_id": "KS"
  },
  {
    "zip": 67654,
    "lat": 39.8248,
    "lng": -99.9534,
    "state_id": "KS"
  },
  {
    "zip": 67656,
    "lat": 38.98979,
    "lng": -99.75605,
    "state_id": "KS"
  },
  {
    "zip": 67657,
    "lat": 39.21025,
    "lng": -99.62001,
    "state_id": "KS"
  },
  {
    "zip": 67658,
    "lat": 39.10589,
    "lng": -98.9312,
    "state_id": "KS"
  },
  {
    "zip": 67659,
    "lat": 39.32488,
    "lng": -100.0036,
    "state_id": "KS"
  },
  {
    "zip": 67660,
    "lat": 38.7182,
    "lng": -99.15654,
    "state_id": "KS"
  },
  {
    "zip": 67661,
    "lat": 39.82823,
    "lng": -99.32053,
    "state_id": "KS"
  },
  {
    "zip": 67663,
    "lat": 39.21143,
    "lng": -99.3492,
    "state_id": "KS"
  },
  {
    "zip": 67664,
    "lat": 39.81428,
    "lng": -99.58638,
    "state_id": "KS"
  },
  {
    "zip": 67665,
    "lat": 38.85039,
    "lng": -98.88162,
    "state_id": "KS"
  },
  {
    "zip": 67667,
    "lat": 38.71322,
    "lng": -99.33259,
    "state_id": "KS"
  },
  {
    "zip": 67669,
    "lat": 39.45329,
    "lng": -99.34648,
    "state_id": "KS"
  },
  {
    "zip": 67671,
    "lat": 38.84682,
    "lng": -99.13639,
    "state_id": "KS"
  },
  {
    "zip": 67672,
    "lat": 39.00388,
    "lng": -99.91057,
    "state_id": "KS"
  },
  {
    "zip": 67673,
    "lat": 39.15834,
    "lng": -98.81535,
    "state_id": "KS"
  },
  {
    "zip": 67674,
    "lat": 38.86385,
    "lng": -99.06877,
    "state_id": "KS"
  },
  {
    "zip": 67675,
    "lat": 39.44076,
    "lng": -99.1,
    "state_id": "KS"
  },
  {
    "zip": 67701,
    "lat": 39.39603,
    "lng": -101.05959,
    "state_id": "KS"
  },
  {
    "zip": 67730,
    "lat": 39.80575,
    "lng": -101.10589,
    "state_id": "KS"
  },
  {
    "zip": 67731,
    "lat": 39.76268,
    "lng": -101.53621,
    "state_id": "KS"
  },
  {
    "zip": 67732,
    "lat": 39.4121,
    "lng": -101.36108,
    "state_id": "KS"
  },
  {
    "zip": 67733,
    "lat": 39.31956,
    "lng": -101.51274,
    "state_id": "KS"
  },
  {
    "zip": 67734,
    "lat": 39.53258,
    "lng": -100.89443,
    "state_id": "KS"
  },
  {
    "zip": 67735,
    "lat": 39.34596,
    "lng": -101.7586,
    "state_id": "KS"
  },
  {
    "zip": 67736,
    "lat": 38.84609,
    "lng": -100.4704,
    "state_id": "KS"
  },
  {
    "zip": 67737,
    "lat": 39.12279,
    "lng": -100.47892,
    "state_id": "KS"
  },
  {
    "zip": 67738,
    "lat": 38.98985,
    "lng": -100.64764,
    "state_id": "KS"
  },
  {
    "zip": 67739,
    "lat": 39.88106,
    "lng": -100.81356,
    "state_id": "KS"
  },
  {
    "zip": 67740,
    "lat": 39.37703,
    "lng": -100.35871,
    "state_id": "KS"
  },
  {
    "zip": 67741,
    "lat": 39.39495,
    "lng": -101.99935,
    "state_id": "KS"
  },
  {
    "zip": 67743,
    "lat": 39.37301,
    "lng": -101.24712,
    "state_id": "KS"
  },
  {
    "zip": 67744,
    "lat": 39.85385,
    "lng": -100.9352,
    "state_id": "KS"
  },
  {
    "zip": 67745,
    "lat": 39.80853,
    "lng": -101.34629,
    "state_id": "KS"
  },
  {
    "zip": 67747,
    "lat": 39.04206,
    "lng": -101.06507,
    "state_id": "KS"
  },
  {
    "zip": 67748,
    "lat": 39.04078,
    "lng": -100.85279,
    "state_id": "KS"
  },
  {
    "zip": 67749,
    "lat": 39.83379,
    "lng": -100.56487,
    "state_id": "KS"
  },
  {
    "zip": 67751,
    "lat": 39.12139,
    "lng": -100.35218,
    "state_id": "KS"
  },
  {
    "zip": 67752,
    "lat": 38.98802,
    "lng": -100.24408,
    "state_id": "KS"
  },
  {
    "zip": 67753,
    "lat": 39.42672,
    "lng": -100.74615,
    "state_id": "KS"
  },
  {
    "zip": 67756,
    "lat": 39.79425,
    "lng": -101.8363,
    "state_id": "KS"
  },
  {
    "zip": 67757,
    "lat": 39.5462,
    "lng": -100.6105,
    "state_id": "KS"
  },
  {
    "zip": 67758,
    "lat": 38.8842,
    "lng": -101.76404,
    "state_id": "KS"
  },
  {
    "zip": 67761,
    "lat": 38.97082,
    "lng": -101.53108,
    "state_id": "KS"
  },
  {
    "zip": 67762,
    "lat": 38.90425,
    "lng": -101.97257,
    "state_id": "KS"
  },
  {
    "zip": 67764,
    "lat": 38.92284,
    "lng": -101.20644,
    "state_id": "KS"
  },
  {
    "zip": 67801,
    "lat": 37.72528,
    "lng": -100.05496,
    "state_id": "KS"
  },
  {
    "zip": 67831,
    "lat": 37.18657,
    "lng": -99.7922,
    "state_id": "KS"
  },
  {
    "zip": 67834,
    "lat": 37.5337,
    "lng": -99.63159,
    "state_id": "KS"
  },
  {
    "zip": 67835,
    "lat": 37.97104,
    "lng": -100.32684,
    "state_id": "KS"
  },
  {
    "zip": 67836,
    "lat": 38.02158,
    "lng": -102.00699,
    "state_id": "KS"
  },
  {
    "zip": 67837,
    "lat": 37.55289,
    "lng": -100.66235,
    "state_id": "KS"
  },
  {
    "zip": 67838,
    "lat": 38.0779,
    "lng": -101.13399,
    "state_id": "KS"
  },
  {
    "zip": 67839,
    "lat": 38.47189,
    "lng": -100.43143,
    "state_id": "KS"
  },
  {
    "zip": 67840,
    "lat": 37.08605,
    "lng": -100.0261,
    "state_id": "KS"
  },
  {
    "zip": 67841,
    "lat": 37.62236,
    "lng": -100.24408,
    "state_id": "KS"
  },
  {
    "zip": 67842,
    "lat": 37.55924,
    "lng": -99.77454,
    "state_id": "KS"
  },
  {
    "zip": 67843,
    "lat": 37.73029,
    "lng": -99.93688,
    "state_id": "KS"
  },
  {
    "zip": 67844,
    "lat": 37.38547,
    "lng": -100.19477,
    "state_id": "KS"
  },
  {
    "zip": 67846,
    "lat": 38.03484,
    "lng": -100.76704,
    "state_id": "KS"
  },
  {
    "zip": 67849,
    "lat": 38.14903,
    "lng": -99.71484,
    "state_id": "KS"
  },
  {
    "zip": 67850,
    "lat": 38.58679,
    "lng": -100.61417,
    "state_id": "KS"
  },
  {
    "zip": 67851,
    "lat": 38.06266,
    "lng": -101.02606,
    "state_id": "KS"
  },
  {
    "zip": 67853,
    "lat": 37.88868,
    "lng": -100.51504,
    "state_id": "KS"
  },
  {
    "zip": 67854,
    "lat": 38.09101,
    "lng": -100.01035,
    "state_id": "KS"
  },
  {
    "zip": 67855,
    "lat": 37.55289,
    "lng": -101.68846,
    "state_id": "KS"
  },
  {
    "zip": 67857,
    "lat": 37.98697,
    "lng": -101.55079,
    "state_id": "KS"
  },
  {
    "zip": 67859,
    "lat": 37.23914,
    "lng": -100.77919,
    "state_id": "KS"
  },
  {
    "zip": 67860,
    "lat": 38.00295,
    "lng": -101.31027,
    "state_id": "KS"
  },
  {
    "zip": 67861,
    "lat": 38.47221,
    "lng": -101.38519,
    "state_id": "KS"
  },
  {
    "zip": 67862,
    "lat": 37.55287,
    "lng": -101.93978,
    "state_id": "KS"
  },
  {
    "zip": 67863,
    "lat": 38.57311,
    "lng": -101.21651,
    "state_id": "KS"
  },
  {
    "zip": 67864,
    "lat": 37.17639,
    "lng": -100.31692,
    "state_id": "KS"
  },
  {
    "zip": 67865,
    "lat": 37.43156,
    "lng": -99.96545,
    "state_id": "KS"
  },
  {
    "zip": 67867,
    "lat": 37.5734,
    "lng": -100.45875,
    "state_id": "KS"
  },
  {
    "zip": 67868,
    "lat": 37.82114,
    "lng": -100.70879,
    "state_id": "KS"
  },
  {
    "zip": 67869,
    "lat": 37.23871,
    "lng": -100.57264,
    "state_id": "KS"
  },
  {
    "zip": 67870,
    "lat": 37.52222,
    "lng": -101.00144,
    "state_id": "KS"
  },
  {
    "zip": 67871,
    "lat": 38.51757,
    "lng": -100.90556,
    "state_id": "KS"
  },
  {
    "zip": 67876,
    "lat": 37.86154,
    "lng": -99.73664,
    "state_id": "KS"
  },
  {
    "zip": 67877,
    "lat": 37.54986,
    "lng": -100.84549,
    "state_id": "KS"
  },
  {
    "zip": 67878,
    "lat": 38.00556,
    "lng": -101.82808,
    "state_id": "KS"
  },
  {
    "zip": 67879,
    "lat": 38.48065,
    "lng": -101.80605,
    "state_id": "KS"
  },
  {
    "zip": 67880,
    "lat": 37.58511,
    "lng": -101.31458,
    "state_id": "KS"
  },
  {
    "zip": 67882,
    "lat": 37.80051,
    "lng": -99.88679,
    "state_id": "KS"
  },
  {
    "zip": 67901,
    "lat": 37.10184,
    "lng": -100.90782,
    "state_id": "KS"
  },
  {
    "zip": 67950,
    "lat": 37.04802,
    "lng": -101.86802,
    "state_id": "KS"
  },
  {
    "zip": 67951,
    "lat": 37.16223,
    "lng": -101.35248,
    "state_id": "KS"
  },
  {
    "zip": 67952,
    "lat": 37.29674,
    "lng": -101.15009,
    "state_id": "KS"
  },
  {
    "zip": 67953,
    "lat": 37.25701,
    "lng": -101.8943,
    "state_id": "KS"
  },
  {
    "zip": 67954,
    "lat": 37.20867,
    "lng": -101.64224,
    "state_id": "KS"
  },
  {
    "zip": 40003,
    "lat": 38.26916,
    "lng": -85.04705,
    "state_id": "KY"
  },
  {
    "zip": 40004,
    "lat": 37.80451,
    "lng": -85.46497,
    "state_id": "KY"
  },
  {
    "zip": 40006,
    "lat": 38.59161,
    "lng": -85.32669,
    "state_id": "KY"
  },
  {
    "zip": 40007,
    "lat": 38.46211,
    "lng": -84.99972,
    "state_id": "KY"
  },
  {
    "zip": 40008,
    "lat": 37.9163,
    "lng": -85.27637,
    "state_id": "KY"
  },
  {
    "zip": 40009,
    "lat": 37.47085,
    "lng": -85.10718,
    "state_id": "KY"
  },
  {
    "zip": 40010,
    "lat": 38.37351,
    "lng": -85.45073,
    "state_id": "KY"
  },
  {
    "zip": 40011,
    "lat": 38.52598,
    "lng": -85.16938,
    "state_id": "KY"
  },
  {
    "zip": 40012,
    "lat": 37.9072,
    "lng": -85.18462,
    "state_id": "KY"
  },
  {
    "zip": 40013,
    "lat": 37.93895,
    "lng": -85.46768,
    "state_id": "KY"
  },
  {
    "zip": 40014,
    "lat": 38.34187,
    "lng": -85.43096,
    "state_id": "KY"
  },
  {
    "zip": 40019,
    "lat": 38.37987,
    "lng": -85.1718,
    "state_id": "KY"
  },
  {
    "zip": 40020,
    "lat": 37.93504,
    "lng": -85.38753,
    "state_id": "KY"
  },
  {
    "zip": 40022,
    "lat": 38.15093,
    "lng": -85.33515,
    "state_id": "KY"
  },
  {
    "zip": 40023,
    "lat": 38.16495,
    "lng": -85.42801,
    "state_id": "KY"
  },
  {
    "zip": 40025,
    "lat": 38.29994,
    "lng": -85.64882,
    "state_id": "KY"
  },
  {
    "zip": 40026,
    "lat": 38.42808,
    "lng": -85.53232,
    "state_id": "KY"
  },
  {
    "zip": 40031,
    "lat": 38.42772,
    "lng": -85.39477,
    "state_id": "KY"
  },
  {
    "zip": 40033,
    "lat": 37.55846,
    "lng": -85.23808,
    "state_id": "KY"
  },
  {
    "zip": 40036,
    "lat": 38.44432,
    "lng": -84.97762,
    "state_id": "KY"
  },
  {
    "zip": 40037,
    "lat": 37.65468,
    "lng": -85.41157,
    "state_id": "KY"
  },
  {
    "zip": 40040,
    "lat": 37.77058,
    "lng": -85.05538,
    "state_id": "KY"
  },
  {
    "zip": 40041,
    "lat": 38.25603,
    "lng": -85.66412,
    "state_id": "KY"
  },
  {
    "zip": 40045,
    "lat": 38.68994,
    "lng": -85.3602,
    "state_id": "KY"
  },
  {
    "zip": 40046,
    "lat": 38.02262,
    "lng": -85.17422,
    "state_id": "KY"
  },
  {
    "zip": 40047,
    "lat": 38.03973,
    "lng": -85.55479,
    "state_id": "KY"
  },
  {
    "zip": 40048,
    "lat": 37.84717,
    "lng": -85.46922,
    "state_id": "KY"
  },
  {
    "zip": 40049,
    "lat": 37.66347,
    "lng": -85.39534,
    "state_id": "KY"
  },
  {
    "zip": 40050,
    "lat": 38.4459,
    "lng": -85.19007,
    "state_id": "KY"
  },
  {
    "zip": 40051,
    "lat": 37.65576,
    "lng": -85.57271,
    "state_id": "KY"
  },
  {
    "zip": 40052,
    "lat": 37.58293,
    "lng": -85.51214,
    "state_id": "KY"
  },
  {
    "zip": 40055,
    "lat": 38.49678,
    "lng": -85.32477,
    "state_id": "KY"
  },
  {
    "zip": 40056,
    "lat": 38.30628,
    "lng": -85.48823,
    "state_id": "KY"
  },
  {
    "zip": 40057,
    "lat": 38.38943,
    "lng": -85.0423,
    "state_id": "KY"
  },
  {
    "zip": 40058,
    "lat": 38.43275,
    "lng": -85.17054,
    "state_id": "KY"
  },
  {
    "zip": 40059,
    "lat": 38.35884,
    "lng": -85.59434,
    "state_id": "KY"
  },
  {
    "zip": 40060,
    "lat": 37.53801,
    "lng": -85.44181,
    "state_id": "KY"
  },
  {
    "zip": 40062,
    "lat": 37.60316,
    "lng": -85.43182,
    "state_id": "KY"
  },
  {
    "zip": 40063,
    "lat": 37.57166,
    "lng": -85.34208,
    "state_id": "KY"
  },
  {
    "zip": 40065,
    "lat": 38.21651,
    "lng": -85.20684,
    "state_id": "KY"
  },
  {
    "zip": 40067,
    "lat": 38.2218,
    "lng": -85.36684,
    "state_id": "KY"
  },
  {
    "zip": 40068,
    "lat": 38.39466,
    "lng": -85.27028,
    "state_id": "KY"
  },
  {
    "zip": 40069,
    "lat": 37.71603,
    "lng": -85.21602,
    "state_id": "KY"
  },
  {
    "zip": 40070,
    "lat": 38.47984,
    "lng": -85.2367,
    "state_id": "KY"
  },
  {
    "zip": 40071,
    "lat": 38.04375,
    "lng": -85.36912,
    "state_id": "KY"
  },
  {
    "zip": 40075,
    "lat": 38.56294,
    "lng": -85.11241,
    "state_id": "KY"
  },
  {
    "zip": 40076,
    "lat": 38.11262,
    "lng": -85.08903,
    "state_id": "KY"
  },
  {
    "zip": 40077,
    "lat": 38.49728,
    "lng": -85.44552,
    "state_id": "KY"
  },
  {
    "zip": 40078,
    "lat": 37.84271,
    "lng": -85.15168,
    "state_id": "KY"
  },
  {
    "zip": 40104,
    "lat": 38.09162,
    "lng": -86.34152,
    "state_id": "KY"
  },
  {
    "zip": 40107,
    "lat": 37.7763,
    "lng": -85.63968,
    "state_id": "KY"
  },
  {
    "zip": 40108,
    "lat": 37.97261,
    "lng": -86.16632,
    "state_id": "KY"
  },
  {
    "zip": 40109,
    "lat": 38.06055,
    "lng": -85.75898,
    "state_id": "KY"
  },
  {
    "zip": 40110,
    "lat": 37.93613,
    "lng": -85.65997,
    "state_id": "KY"
  },
  {
    "zip": 40111,
    "lat": 37.77313,
    "lng": -86.62819,
    "state_id": "KY"
  },
  {
    "zip": 40115,
    "lat": 37.73164,
    "lng": -86.22894,
    "state_id": "KY"
  },
  {
    "zip": 40117,
    "lat": 37.89774,
    "lng": -86.12642,
    "state_id": "KY"
  },
  {
    "zip": 40118,
    "lat": 38.09896,
    "lng": -85.75116,
    "state_id": "KY"
  },
  {
    "zip": 40119,
    "lat": 37.60334,
    "lng": -86.54857,
    "state_id": "KY"
  },
  {
    "zip": 40121,
    "lat": 37.92933,
    "lng": -85.94141,
    "state_id": "KY"
  },
  {
    "zip": 40140,
    "lat": 37.72467,
    "lng": -86.30095,
    "state_id": "KY"
  },
  {
    "zip": 40142,
    "lat": 37.88669,
    "lng": -86.20983,
    "state_id": "KY"
  },
  {
    "zip": 40143,
    "lat": 37.78114,
    "lng": -86.50881,
    "state_id": "KY"
  },
  {
    "zip": 40144,
    "lat": 37.74598,
    "lng": -86.37863,
    "state_id": "KY"
  },
  {
    "zip": 40145,
    "lat": 37.64424,
    "lng": -86.31131,
    "state_id": "KY"
  },
  {
    "zip": 40146,
    "lat": 37.85466,
    "lng": -86.33149,
    "state_id": "KY"
  },
  {
    "zip": 40150,
    "lat": 37.86869,
    "lng": -85.7225,
    "state_id": "KY"
  },
  {
    "zip": 40152,
    "lat": 37.61503,
    "lng": -86.44294,
    "state_id": "KY"
  },
  {
    "zip": 40155,
    "lat": 37.93704,
    "lng": -85.99176,
    "state_id": "KY"
  },
  {
    "zip": 40157,
    "lat": 38.01983,
    "lng": -86.38999,
    "state_id": "KY"
  },
  {
    "zip": 40160,
    "lat": 37.81272,
    "lng": -85.93921,
    "state_id": "KY"
  },
  {
    "zip": 40161,
    "lat": 38.00253,
    "lng": -86.39048,
    "state_id": "KY"
  },
  {
    "zip": 40162,
    "lat": 37.75457,
    "lng": -86.04907,
    "state_id": "KY"
  },
  {
    "zip": 40165,
    "lat": 37.97161,
    "lng": -85.67573,
    "state_id": "KY"
  },
  {
    "zip": 40170,
    "lat": 37.96461,
    "lng": -86.50796,
    "state_id": "KY"
  },
  {
    "zip": 40171,
    "lat": 37.97171,
    "lng": -86.45197,
    "state_id": "KY"
  },
  {
    "zip": 40175,
    "lat": 37.82297,
    "lng": -86.08571,
    "state_id": "KY"
  },
  {
    "zip": 40176,
    "lat": 37.92372,
    "lng": -86.34404,
    "state_id": "KY"
  },
  {
    "zip": 40177,
    "lat": 38.00813,
    "lng": -85.92173,
    "state_id": "KY"
  },
  {
    "zip": 40178,
    "lat": 37.65327,
    "lng": -86.40383,
    "state_id": "KY"
  },
  {
    "zip": 40202,
    "lat": 38.25718,
    "lng": -85.7535,
    "state_id": "KY"
  },
  {
    "zip": 40203,
    "lat": 38.24745,
    "lng": -85.7649,
    "state_id": "KY"
  },
  {
    "zip": 40204,
    "lat": 38.23918,
    "lng": -85.72084,
    "state_id": "KY"
  },
  {
    "zip": 40205,
    "lat": 38.22345,
    "lng": -85.68262,
    "state_id": "KY"
  },
  {
    "zip": 40206,
    "lat": 38.26036,
    "lng": -85.70484,
    "state_id": "KY"
  },
  {
    "zip": 40207,
    "lat": 38.26523,
    "lng": -85.65712,
    "state_id": "KY"
  },
  {
    "zip": 40208,
    "lat": 38.21856,
    "lng": -85.76554,
    "state_id": "KY"
  },
  {
    "zip": 40209,
    "lat": 38.18978,
    "lng": -85.74785,
    "state_id": "KY"
  },
  {
    "zip": 40210,
    "lat": 38.23152,
    "lng": -85.78619,
    "state_id": "KY"
  },
  {
    "zip": 40211,
    "lat": 38.23443,
    "lng": -85.82394,
    "state_id": "KY"
  },
  {
    "zip": 40212,
    "lat": 38.27172,
    "lng": -85.79836,
    "state_id": "KY"
  },
  {
    "zip": 40213,
    "lat": 38.17579,
    "lng": -85.71941,
    "state_id": "KY"
  },
  {
    "zip": 40214,
    "lat": 38.15187,
    "lng": -85.78246,
    "state_id": "KY"
  },
  {
    "zip": 40215,
    "lat": 38.19105,
    "lng": -85.78598,
    "state_id": "KY"
  },
  {
    "zip": 40216,
    "lat": 38.18765,
    "lng": -85.83979,
    "state_id": "KY"
  },
  {
    "zip": 40217,
    "lat": 38.21705,
    "lng": -85.73715,
    "state_id": "KY"
  },
  {
    "zip": 40218,
    "lat": 38.18904,
    "lng": -85.65404,
    "state_id": "KY"
  },
  {
    "zip": 40219,
    "lat": 38.13888,
    "lng": -85.68697,
    "state_id": "KY"
  },
  {
    "zip": 40220,
    "lat": 38.21549,
    "lng": -85.61724,
    "state_id": "KY"
  },
  {
    "zip": 40222,
    "lat": 38.27128,
    "lng": -85.62034,
    "state_id": "KY"
  },
  {
    "zip": 40223,
    "lat": 38.25996,
    "lng": -85.54185,
    "state_id": "KY"
  },
  {
    "zip": 40228,
    "lat": 38.13551,
    "lng": -85.62829,
    "state_id": "KY"
  },
  {
    "zip": 40229,
    "lat": 38.08909,
    "lng": -85.65482,
    "state_id": "KY"
  },
  {
    "zip": 40231,
    "lat": 38.19498,
    "lng": -85.69498,
    "state_id": "KY"
  },
  {
    "zip": 40241,
    "lat": 38.30164,
    "lng": -85.57585,
    "state_id": "KY"
  },
  {
    "zip": 40242,
    "lat": 38.27732,
    "lng": -85.59065,
    "state_id": "KY"
  },
  {
    "zip": 40243,
    "lat": 38.23927,
    "lng": -85.53425,
    "state_id": "KY"
  },
  {
    "zip": 40245,
    "lat": 38.26478,
    "lng": -85.45223,
    "state_id": "KY"
  },
  {
    "zip": 40258,
    "lat": 38.14688,
    "lng": -85.87761,
    "state_id": "KY"
  },
  {
    "zip": 40272,
    "lat": 38.08446,
    "lng": -85.85245,
    "state_id": "KY"
  },
  {
    "zip": 40280,
    "lat": 38.2477,
    "lng": -85.68848,
    "state_id": "KY"
  },
  {
    "zip": 40291,
    "lat": 38.1291,
    "lng": -85.57807,
    "state_id": "KY"
  },
  {
    "zip": 40299,
    "lat": 38.16266,
    "lng": -85.51677,
    "state_id": "KY"
  },
  {
    "zip": 40310,
    "lat": 37.75023,
    "lng": -84.76619,
    "state_id": "KY"
  },
  {
    "zip": 40311,
    "lat": 38.32372,
    "lng": -84.02449,
    "state_id": "KY"
  },
  {
    "zip": 40312,
    "lat": 37.85237,
    "lng": -83.93091,
    "state_id": "KY"
  },
  {
    "zip": 40313,
    "lat": 38.13047,
    "lng": -83.42092,
    "state_id": "KY"
  },
  {
    "zip": 40316,
    "lat": 37.93128,
    "lng": -83.52924,
    "state_id": "KY"
  },
  {
    "zip": 40322,
    "lat": 37.93705,
    "lng": -83.63355,
    "state_id": "KY"
  },
  {
    "zip": 40324,
    "lat": 38.24673,
    "lng": -84.55326,
    "state_id": "KY"
  },
  {
    "zip": 40328,
    "lat": 37.56398,
    "lng": -85.02585,
    "state_id": "KY"
  },
  {
    "zip": 40330,
    "lat": 37.7896,
    "lng": -84.89168,
    "state_id": "KY"
  },
  {
    "zip": 40334,
    "lat": 38.01466,
    "lng": -83.76562,
    "state_id": "KY"
  },
  {
    "zip": 40336,
    "lat": 37.68573,
    "lng": -83.98616,
    "state_id": "KY"
  },
  {
    "zip": 40337,
    "lat": 37.95348,
    "lng": -83.85639,
    "state_id": "KY"
  },
  {
    "zip": 40339,
    "lat": 37.94188,
    "lng": -84.6443,
    "state_id": "KY"
  },
  {
    "zip": 40342,
    "lat": 38.00322,
    "lng": -84.98494,
    "state_id": "KY"
  },
  {
    "zip": 40346,
    "lat": 37.98075,
    "lng": -83.74476,
    "state_id": "KY"
  },
  {
    "zip": 40347,
    "lat": 38.15843,
    "lng": -84.7156,
    "state_id": "KY"
  },
  {
    "zip": 40348,
    "lat": 38.28739,
    "lng": -84.1321,
    "state_id": "KY"
  },
  {
    "zip": 40350,
    "lat": 38.30888,
    "lng": -83.87446,
    "state_id": "KY"
  },
  {
    "zip": 40351,
    "lat": 38.20548,
    "lng": -83.4164,
    "state_id": "KY"
  },
  {
    "zip": 40353,
    "lat": 38.06564,
    "lng": -83.94764,
    "state_id": "KY"
  },
  {
    "zip": 40356,
    "lat": 37.87301,
    "lng": -84.56447,
    "state_id": "KY"
  },
  {
    "zip": 40358,
    "lat": 38.0591,
    "lng": -83.67994,
    "state_id": "KY"
  },
  {
    "zip": 40359,
    "lat": 38.48924,
    "lng": -84.81944,
    "state_id": "KY"
  },
  {
    "zip": 40360,
    "lat": 38.15446,
    "lng": -83.76739,
    "state_id": "KY"
  },
  {
    "zip": 40361,
    "lat": 38.20598,
    "lng": -84.2273,
    "state_id": "KY"
  },
  {
    "zip": 40363,
    "lat": 38.51445,
    "lng": -85.00979,
    "state_id": "KY"
  },
  {
    "zip": 40370,
    "lat": 38.39836,
    "lng": -84.52642,
    "state_id": "KY"
  },
  {
    "zip": 40371,
    "lat": 38.08161,
    "lng": -83.59876,
    "state_id": "KY"
  },
  {
    "zip": 40372,
    "lat": 37.90781,
    "lng": -84.88266,
    "state_id": "KY"
  },
  {
    "zip": 40374,
    "lat": 38.21279,
    "lng": -83.89962,
    "state_id": "KY"
  },
  {
    "zip": 40376,
    "lat": 37.78143,
    "lng": -83.69449,
    "state_id": "KY"
  },
  {
    "zip": 40379,
    "lat": 38.31339,
    "lng": -84.6872,
    "state_id": "KY"
  },
  {
    "zip": 40380,
    "lat": 37.82391,
    "lng": -83.77924,
    "state_id": "KY"
  },
  {
    "zip": 40383,
    "lat": 38.01264,
    "lng": -84.73985,
    "state_id": "KY"
  },
  {
    "zip": 40385,
    "lat": 37.73669,
    "lng": -84.13247,
    "state_id": "KY"
  },
  {
    "zip": 40387,
    "lat": 37.93536,
    "lng": -83.49267,
    "state_id": "KY"
  },
  {
    "zip": 40390,
    "lat": 37.8534,
    "lng": -84.67167,
    "state_id": "KY"
  },
  {
    "zip": 40391,
    "lat": 37.96942,
    "lng": -84.14518,
    "state_id": "KY"
  },
  {
    "zip": 40402,
    "lat": 37.29612,
    "lng": -83.97341,
    "state_id": "KY"
  },
  {
    "zip": 40403,
    "lat": 37.56947,
    "lng": -84.27119,
    "state_id": "KY"
  },
  {
    "zip": 40404,
    "lat": 37.57345,
    "lng": -84.29088,
    "state_id": "KY"
  },
  {
    "zip": 40409,
    "lat": 37.37759,
    "lng": -84.43294,
    "state_id": "KY"
  },
  {
    "zip": 40419,
    "lat": 37.45061,
    "lng": -84.49303,
    "state_id": "KY"
  },
  {
    "zip": 40422,
    "lat": 37.63786,
    "lng": -84.80074,
    "state_id": "KY"
  },
  {
    "zip": 40434,
    "lat": 37.39449,
    "lng": -83.94037,
    "state_id": "KY"
  },
  {
    "zip": 40437,
    "lat": 37.44551,
    "lng": -84.86586,
    "state_id": "KY"
  },
  {
    "zip": 40440,
    "lat": 37.57869,
    "lng": -84.82487,
    "state_id": "KY"
  },
  {
    "zip": 40442,
    "lat": 37.35272,
    "lng": -84.72469,
    "state_id": "KY"
  },
  {
    "zip": 40444,
    "lat": 37.6792,
    "lng": -84.58843,
    "state_id": "KY"
  },
  {
    "zip": 40445,
    "lat": 37.33471,
    "lng": -84.18764,
    "state_id": "KY"
  },
  {
    "zip": 40447,
    "lat": 37.45707,
    "lng": -84.02863,
    "state_id": "KY"
  },
  {
    "zip": 40448,
    "lat": 37.46165,
    "lng": -84.75371,
    "state_id": "KY"
  },
  {
    "zip": 40456,
    "lat": 37.34837,
    "lng": -84.32157,
    "state_id": "KY"
  },
  {
    "zip": 40460,
    "lat": 37.39087,
    "lng": -84.23629,
    "state_id": "KY"
  },
  {
    "zip": 40461,
    "lat": 37.59316,
    "lng": -84.40713,
    "state_id": "KY"
  },
  {
    "zip": 40464,
    "lat": 37.55826,
    "lng": -84.92345,
    "state_id": "KY"
  },
  {
    "zip": 40468,
    "lat": 37.63522,
    "lng": -84.98604,
    "state_id": "KY"
  },
  {
    "zip": 40472,
    "lat": 37.71681,
    "lng": -83.86023,
    "state_id": "KY"
  },
  {
    "zip": 40475,
    "lat": 37.76658,
    "lng": -84.3137,
    "state_id": "KY"
  },
  {
    "zip": 40481,
    "lat": 37.44884,
    "lng": -84.08787,
    "state_id": "KY"
  },
  {
    "zip": 40484,
    "lat": 37.51612,
    "lng": -84.66965,
    "state_id": "KY"
  },
  {
    "zip": 40486,
    "lat": 37.36931,
    "lng": -83.86189,
    "state_id": "KY"
  },
  {
    "zip": 40489,
    "lat": 37.3685,
    "lng": -84.64331,
    "state_id": "KY"
  },
  {
    "zip": 40502,
    "lat": 38.01387,
    "lng": -84.48207,
    "state_id": "KY"
  },
  {
    "zip": 40503,
    "lat": 38.00468,
    "lng": -84.53422,
    "state_id": "KY"
  },
  {
    "zip": 40504,
    "lat": 38.04172,
    "lng": -84.54154,
    "state_id": "KY"
  },
  {
    "zip": 40505,
    "lat": 38.05995,
    "lng": -84.45692,
    "state_id": "KY"
  },
  {
    "zip": 40506,
    "lat": 38.02821,
    "lng": -84.50319,
    "state_id": "KY"
  },
  {
    "zip": 40507,
    "lat": 38.04699,
    "lng": -84.4964,
    "state_id": "KY"
  },
  {
    "zip": 40508,
    "lat": 38.05001,
    "lng": -84.50114,
    "state_id": "KY"
  },
  {
    "zip": 40509,
    "lat": 37.99786,
    "lng": -84.37619,
    "state_id": "KY"
  },
  {
    "zip": 40510,
    "lat": 38.06892,
    "lng": -84.60142,
    "state_id": "KY"
  },
  {
    "zip": 40511,
    "lat": 38.13368,
    "lng": -84.47807,
    "state_id": "KY"
  },
  {
    "zip": 40513,
    "lat": 38.01594,
    "lng": -84.60641,
    "state_id": "KY"
  },
  {
    "zip": 40514,
    "lat": 37.98269,
    "lng": -84.56343,
    "state_id": "KY"
  },
  {
    "zip": 40515,
    "lat": 37.92033,
    "lng": -84.40976,
    "state_id": "KY"
  },
  {
    "zip": 40516,
    "lat": 38.06968,
    "lng": -84.3612,
    "state_id": "KY"
  },
  {
    "zip": 40517,
    "lat": 37.98388,
    "lng": -84.48829,
    "state_id": "KY"
  },
  {
    "zip": 40601,
    "lat": 38.23398,
    "lng": -84.88086,
    "state_id": "KY"
  },
  {
    "zip": 40604,
    "lat": 38.17516,
    "lng": -84.86479,
    "state_id": "KY"
  },
  {
    "zip": 40701,
    "lat": 36.91957,
    "lng": -84.16358,
    "state_id": "KY"
  },
  {
    "zip": 40729,
    "lat": 37.24779,
    "lng": -84.13499,
    "state_id": "KY"
  },
  {
    "zip": 40734,
    "lat": 36.92816,
    "lng": -83.96908,
    "state_id": "KY"
  },
  {
    "zip": 40737,
    "lat": 37.00524,
    "lng": -84.13321,
    "state_id": "KY"
  },
  {
    "zip": 40740,
    "lat": 37.01776,
    "lng": -84.03398,
    "state_id": "KY"
  },
  {
    "zip": 40741,
    "lat": 37.13752,
    "lng": -84.12615,
    "state_id": "KY"
  },
  {
    "zip": 40743,
    "lat": 37.07403,
    "lng": -84.11838,
    "state_id": "KY"
  },
  {
    "zip": 40744,
    "lat": 37.03732,
    "lng": -84.09364,
    "state_id": "KY"
  },
  {
    "zip": 40759,
    "lat": 36.8204,
    "lng": -84.06001,
    "state_id": "KY"
  },
  {
    "zip": 40763,
    "lat": 36.69269,
    "lng": -83.92336,
    "state_id": "KY"
  },
  {
    "zip": 40769,
    "lat": 36.71208,
    "lng": -84.17017,
    "state_id": "KY"
  },
  {
    "zip": 40771,
    "lat": 36.85863,
    "lng": -84.03479,
    "state_id": "KY"
  },
  {
    "zip": 40801,
    "lat": 36.86064,
    "lng": -83.24816,
    "state_id": "KY"
  },
  {
    "zip": 40806,
    "lat": 36.88632,
    "lng": -83.28761,
    "state_id": "KY"
  },
  {
    "zip": 40807,
    "lat": 36.96438,
    "lng": -82.94732,
    "state_id": "KY"
  },
  {
    "zip": 40808,
    "lat": 36.99344,
    "lng": -83.21055,
    "state_id": "KY"
  },
  {
    "zip": 40810,
    "lat": 36.94932,
    "lng": -83.30619,
    "state_id": "KY"
  },
  {
    "zip": 40813,
    "lat": 36.72773,
    "lng": -83.59301,
    "state_id": "KY"
  },
  {
    "zip": 40815,
    "lat": 36.78488,
    "lng": -83.21497,
    "state_id": "KY"
  },
  {
    "zip": 40816,
    "lat": 37.00448,
    "lng": -83.29736,
    "state_id": "KY"
  },
  {
    "zip": 40818,
    "lat": 36.82016,
    "lng": -83.24454,
    "state_id": "KY"
  },
  {
    "zip": 40819,
    "lat": 36.80372,
    "lng": -83.46279,
    "state_id": "KY"
  },
  {
    "zip": 40820,
    "lat": 36.75579,
    "lng": -83.1839,
    "state_id": "KY"
  },
  {
    "zip": 40823,
    "lat": 36.95985,
    "lng": -82.99713,
    "state_id": "KY"
  },
  {
    "zip": 40824,
    "lat": 36.82493,
    "lng": -83.37222,
    "state_id": "KY"
  },
  {
    "zip": 40826,
    "lat": 37.05391,
    "lng": -82.78091,
    "state_id": "KY"
  },
  {
    "zip": 40827,
    "lat": 37.04673,
    "lng": -83.47216,
    "state_id": "KY"
  },
  {
    "zip": 40828,
    "lat": 36.8727,
    "lng": -83.14268,
    "state_id": "KY"
  },
  {
    "zip": 40829,
    "lat": 36.79695,
    "lng": -83.30246,
    "state_id": "KY"
  },
  {
    "zip": 40830,
    "lat": 36.75907,
    "lng": -83.3282,
    "state_id": "KY"
  },
  {
    "zip": 40831,
    "lat": 36.75951,
    "lng": -83.34993,
    "state_id": "KY"
  },
  {
    "zip": 40840,
    "lat": 36.89882,
    "lng": -83.4429,
    "state_id": "KY"
  },
  {
    "zip": 40843,
    "lat": 36.8969,
    "lng": -82.97963,
    "state_id": "KY"
  },
  {
    "zip": 40844,
    "lat": 37.06094,
    "lng": -83.35017,
    "state_id": "KY"
  },
  {
    "zip": 40845,
    "lat": 36.79894,
    "lng": -83.51768,
    "state_id": "KY"
  },
  {
    "zip": 40847,
    "lat": 36.85056,
    "lng": -83.17483,
    "state_id": "KY"
  },
  {
    "zip": 40849,
    "lat": 36.89304,
    "lng": -83.13399,
    "state_id": "KY"
  },
  {
    "zip": 40854,
    "lat": 36.84587,
    "lng": -83.3542,
    "state_id": "KY"
  },
  {
    "zip": 40855,
    "lat": 36.94714,
    "lng": -82.89417,
    "state_id": "KY"
  },
  {
    "zip": 40856,
    "lat": 36.71799,
    "lng": -83.53171,
    "state_id": "KY"
  },
  {
    "zip": 40858,
    "lat": 36.98907,
    "lng": -83.41146,
    "state_id": "KY"
  },
  {
    "zip": 40862,
    "lat": 37.0118,
    "lng": -82.87382,
    "state_id": "KY"
  },
  {
    "zip": 40863,
    "lat": 36.73949,
    "lng": -83.44596,
    "state_id": "KY"
  },
  {
    "zip": 40865,
    "lat": 36.91731,
    "lng": -83.21411,
    "state_id": "KY"
  },
  {
    "zip": 40868,
    "lat": 37.08233,
    "lng": -83.46831,
    "state_id": "KY"
  },
  {
    "zip": 40870,
    "lat": 36.92442,
    "lng": -83.14477,
    "state_id": "KY"
  },
  {
    "zip": 40873,
    "lat": 36.8179,
    "lng": -83.40889,
    "state_id": "KY"
  },
  {
    "zip": 40874,
    "lat": 36.97322,
    "lng": -83.4748,
    "state_id": "KY"
  },
  {
    "zip": 40902,
    "lat": 36.85258,
    "lng": -83.61678,
    "state_id": "KY"
  },
  {
    "zip": 40903,
    "lat": 36.80837,
    "lng": -83.81847,
    "state_id": "KY"
  },
  {
    "zip": 40906,
    "lat": 36.88215,
    "lng": -83.88646,
    "state_id": "KY"
  },
  {
    "zip": 40913,
    "lat": 36.94427,
    "lng": -83.54395,
    "state_id": "KY"
  },
  {
    "zip": 40914,
    "lat": 37.10465,
    "lng": -83.57059,
    "state_id": "KY"
  },
  {
    "zip": 40915,
    "lat": 36.87992,
    "lng": -83.81648,
    "state_id": "KY"
  },
  {
    "zip": 40921,
    "lat": 36.7708,
    "lng": -83.90091,
    "state_id": "KY"
  },
  {
    "zip": 40923,
    "lat": 36.91945,
    "lng": -83.85097,
    "state_id": "KY"
  },
  {
    "zip": 40927,
    "lat": 36.87128,
    "lng": -83.0415,
    "state_id": "KY"
  },
  {
    "zip": 40935,
    "lat": 36.90608,
    "lng": -83.71607,
    "state_id": "KY"
  },
  {
    "zip": 40939,
    "lat": 36.81095,
    "lng": -83.71287,
    "state_id": "KY"
  },
  {
    "zip": 40940,
    "lat": 36.60704,
    "lng": -83.94829,
    "state_id": "KY"
  },
  {
    "zip": 40941,
    "lat": 37.12546,
    "lng": -83.7336,
    "state_id": "KY"
  },
  {
    "zip": 40943,
    "lat": 36.95663,
    "lng": -83.85788,
    "state_id": "KY"
  },
  {
    "zip": 40946,
    "lat": 36.98194,
    "lng": -83.79913,
    "state_id": "KY"
  },
  {
    "zip": 40949,
    "lat": 36.89048,
    "lng": -83.87163,
    "state_id": "KY"
  },
  {
    "zip": 40953,
    "lat": 36.93627,
    "lng": -83.80441,
    "state_id": "KY"
  },
  {
    "zip": 40958,
    "lat": 36.80526,
    "lng": -83.59812,
    "state_id": "KY"
  },
  {
    "zip": 40962,
    "lat": 37.14587,
    "lng": -83.74061,
    "state_id": "KY"
  },
  {
    "zip": 40964,
    "lat": 36.77285,
    "lng": -83.31337,
    "state_id": "KY"
  },
  {
    "zip": 40965,
    "lat": 36.63434,
    "lng": -83.71669,
    "state_id": "KY"
  },
  {
    "zip": 40972,
    "lat": 37.25888,
    "lng": -83.6033,
    "state_id": "KY"
  },
  {
    "zip": 40977,
    "lat": 36.70955,
    "lng": -83.75921,
    "state_id": "KY"
  },
  {
    "zip": 40979,
    "lat": 37.00757,
    "lng": -83.50651,
    "state_id": "KY"
  },
  {
    "zip": 40982,
    "lat": 36.93272,
    "lng": -83.69013,
    "state_id": "KY"
  },
  {
    "zip": 40983,
    "lat": 37.33297,
    "lng": -83.76662,
    "state_id": "KY"
  },
  {
    "zip": 40988,
    "lat": 36.849,
    "lng": -83.53251,
    "state_id": "KY"
  },
  {
    "zip": 40995,
    "lat": 36.76106,
    "lng": -83.81541,
    "state_id": "KY"
  },
  {
    "zip": 40997,
    "lat": 36.87745,
    "lng": -83.68497,
    "state_id": "KY"
  },
  {
    "zip": 41001,
    "lat": 38.9149,
    "lng": -84.40265,
    "state_id": "KY"
  },
  {
    "zip": 41002,
    "lat": 38.73062,
    "lng": -83.98266,
    "state_id": "KY"
  },
  {
    "zip": 41003,
    "lat": 38.5322,
    "lng": -84.39037,
    "state_id": "KY"
  },
  {
    "zip": 41004,
    "lat": 38.65388,
    "lng": -84.10655,
    "state_id": "KY"
  },
  {
    "zip": 41005,
    "lat": 39.00805,
    "lng": -84.76744,
    "state_id": "KY"
  },
  {
    "zip": 41006,
    "lat": 38.783,
    "lng": -84.34441,
    "state_id": "KY"
  },
  {
    "zip": 41007,
    "lat": 38.89179,
    "lng": -84.30447,
    "state_id": "KY"
  },
  {
    "zip": 41008,
    "lat": 38.66354,
    "lng": -85.16782,
    "state_id": "KY"
  },
  {
    "zip": 41010,
    "lat": 38.49773,
    "lng": -84.60525,
    "state_id": "KY"
  },
  {
    "zip": 41011,
    "lat": 39.06795,
    "lng": -84.52955,
    "state_id": "KY"
  },
  {
    "zip": 41014,
    "lat": 39.06574,
    "lng": -84.50548,
    "state_id": "KY"
  },
  {
    "zip": 41015,
    "lat": 38.97811,
    "lng": -84.4844,
    "state_id": "KY"
  },
  {
    "zip": 41016,
    "lat": 39.08709,
    "lng": -84.54915,
    "state_id": "KY"
  },
  {
    "zip": 41017,
    "lat": 39.02889,
    "lng": -84.56148,
    "state_id": "KY"
  },
  {
    "zip": 41018,
    "lat": 39.01561,
    "lng": -84.60287,
    "state_id": "KY"
  },
  {
    "zip": 41030,
    "lat": 38.7899,
    "lng": -84.5932,
    "state_id": "KY"
  },
  {
    "zip": 41031,
    "lat": 38.41612,
    "lng": -84.28683,
    "state_id": "KY"
  },
  {
    "zip": 41033,
    "lat": 38.76406,
    "lng": -84.45744,
    "state_id": "KY"
  },
  {
    "zip": 41034,
    "lat": 38.70961,
    "lng": -83.8954,
    "state_id": "KY"
  },
  {
    "zip": 41035,
    "lat": 38.70513,
    "lng": -84.65958,
    "state_id": "KY"
  },
  {
    "zip": 41039,
    "lat": 38.41149,
    "lng": -83.88076,
    "state_id": "KY"
  },
  {
    "zip": 41040,
    "lat": 38.64992,
    "lng": -84.33042,
    "state_id": "KY"
  },
  {
    "zip": 41041,
    "lat": 38.40894,
    "lng": -83.72605,
    "state_id": "KY"
  },
  {
    "zip": 41042,
    "lat": 39.00112,
    "lng": -84.6528,
    "state_id": "KY"
  },
  {
    "zip": 41043,
    "lat": 38.75874,
    "lng": -84.19762,
    "state_id": "KY"
  },
  {
    "zip": 41044,
    "lat": 38.60776,
    "lng": -83.9751,
    "state_id": "KY"
  },
  {
    "zip": 41045,
    "lat": 38.72001,
    "lng": -85.02638,
    "state_id": "KY"
  },
  {
    "zip": 41046,
    "lat": 38.71962,
    "lng": -84.80179,
    "state_id": "KY"
  },
  {
    "zip": 41048,
    "lat": 39.09489,
    "lng": -84.70691,
    "state_id": "KY"
  },
  {
    "zip": 41049,
    "lat": 38.27422,
    "lng": -83.64762,
    "state_id": "KY"
  },
  {
    "zip": 41051,
    "lat": 38.93399,
    "lng": -84.54499,
    "state_id": "KY"
  },
  {
    "zip": 41052,
    "lat": 38.67366,
    "lng": -84.76982,
    "state_id": "KY"
  },
  {
    "zip": 41055,
    "lat": 38.51828,
    "lng": -83.87086,
    "state_id": "KY"
  },
  {
    "zip": 41056,
    "lat": 38.60007,
    "lng": -83.77847,
    "state_id": "KY"
  },
  {
    "zip": 41059,
    "lat": 39.00753,
    "lng": -84.34817,
    "state_id": "KY"
  },
  {
    "zip": 41062,
    "lat": 38.70393,
    "lng": -83.92284,
    "state_id": "KY"
  },
  {
    "zip": 41063,
    "lat": 38.84793,
    "lng": -84.4977,
    "state_id": "KY"
  },
  {
    "zip": 41064,
    "lat": 38.51063,
    "lng": -84.05973,
    "state_id": "KY"
  },
  {
    "zip": 41071,
    "lat": 39.07384,
    "lng": -84.48277,
    "state_id": "KY"
  },
  {
    "zip": 41073,
    "lat": 39.10196,
    "lng": -84.47913,
    "state_id": "KY"
  },
  {
    "zip": 41074,
    "lat": 39.11281,
    "lng": -84.46412,
    "state_id": "KY"
  },
  {
    "zip": 41075,
    "lat": 39.07985,
    "lng": -84.45099,
    "state_id": "KY"
  },
  {
    "zip": 41076,
    "lat": 39.01784,
    "lng": -84.43591,
    "state_id": "KY"
  },
  {
    "zip": 41080,
    "lat": 39.05046,
    "lng": -84.83039,
    "state_id": "KY"
  },
  {
    "zip": 41083,
    "lat": 38.67037,
    "lng": -84.98308,
    "state_id": "KY"
  },
  {
    "zip": 41085,
    "lat": 39.03569,
    "lng": -84.39277,
    "state_id": "KY"
  },
  {
    "zip": 41086,
    "lat": 38.68853,
    "lng": -84.87241,
    "state_id": "KY"
  },
  {
    "zip": 41091,
    "lat": 38.91861,
    "lng": -84.74,
    "state_id": "KY"
  },
  {
    "zip": 41092,
    "lat": 38.81847,
    "lng": -84.70069,
    "state_id": "KY"
  },
  {
    "zip": 41093,
    "lat": 38.39067,
    "lng": -83.56164,
    "state_id": "KY"
  },
  {
    "zip": 41094,
    "lat": 38.88397,
    "lng": -84.62524,
    "state_id": "KY"
  },
  {
    "zip": 41095,
    "lat": 38.79218,
    "lng": -84.82367,
    "state_id": "KY"
  },
  {
    "zip": 41097,
    "lat": 38.61986,
    "lng": -84.58145,
    "state_id": "KY"
  },
  {
    "zip": 41098,
    "lat": 38.59179,
    "lng": -85.00069,
    "state_id": "KY"
  },
  {
    "zip": 41099,
    "lat": 39.03607,
    "lng": -84.46812,
    "state_id": "KY"
  },
  {
    "zip": 41101,
    "lat": 38.47222,
    "lng": -82.64601,
    "state_id": "KY"
  },
  {
    "zip": 41102,
    "lat": 38.42109,
    "lng": -82.725,
    "state_id": "KY"
  },
  {
    "zip": 41121,
    "lat": 38.44573,
    "lng": -82.83392,
    "state_id": "KY"
  },
  {
    "zip": 41124,
    "lat": 38.02977,
    "lng": -82.85102,
    "state_id": "KY"
  },
  {
    "zip": 41129,
    "lat": 38.32031,
    "lng": -82.63988,
    "state_id": "KY"
  },
  {
    "zip": 41132,
    "lat": 38.2633,
    "lng": -82.82465,
    "state_id": "KY"
  },
  {
    "zip": 41135,
    "lat": 38.35627,
    "lng": -83.28767,
    "state_id": "KY"
  },
  {
    "zip": 41139,
    "lat": 38.51539,
    "lng": -82.72849,
    "state_id": "KY"
  },
  {
    "zip": 41141,
    "lat": 38.54794,
    "lng": -83.14641,
    "state_id": "KY"
  },
  {
    "zip": 41142,
    "lat": 38.27856,
    "lng": -83.07328,
    "state_id": "KY"
  },
  {
    "zip": 41143,
    "lat": 38.33258,
    "lng": -82.97239,
    "state_id": "KY"
  },
  {
    "zip": 41144,
    "lat": 38.53687,
    "lng": -82.91904,
    "state_id": "KY"
  },
  {
    "zip": 41146,
    "lat": 38.27656,
    "lng": -82.89823,
    "state_id": "KY"
  },
  {
    "zip": 41149,
    "lat": 38.06423,
    "lng": -83.03927,
    "state_id": "KY"
  },
  {
    "zip": 41159,
    "lat": 38.01256,
    "lng": -82.96565,
    "state_id": "KY"
  },
  {
    "zip": 41164,
    "lat": 38.28972,
    "lng": -83.16254,
    "state_id": "KY"
  },
  {
    "zip": 41166,
    "lat": 38.63741,
    "lng": -83.09222,
    "state_id": "KY"
  },
  {
    "zip": 41168,
    "lat": 38.30732,
    "lng": -82.76223,
    "state_id": "KY"
  },
  {
    "zip": 41169,
    "lat": 38.52613,
    "lng": -82.71232,
    "state_id": "KY"
  },
  {
    "zip": 41171,
    "lat": 38.09415,
    "lng": -83.10474,
    "state_id": "KY"
  },
  {
    "zip": 41174,
    "lat": 38.70488,
    "lng": -83.01162,
    "state_id": "KY"
  },
  {
    "zip": 41175,
    "lat": 38.65133,
    "lng": -82.97229,
    "state_id": "KY"
  },
  {
    "zip": 41179,
    "lat": 38.52673,
    "lng": -83.38774,
    "state_id": "KY"
  },
  {
    "zip": 41180,
    "lat": 38.13517,
    "lng": -82.85386,
    "state_id": "KY"
  },
  {
    "zip": 41183,
    "lat": 38.55253,
    "lng": -82.73701,
    "state_id": "KY"
  },
  {
    "zip": 41189,
    "lat": 38.54575,
    "lng": -83.55469,
    "state_id": "KY"
  },
  {
    "zip": 41201,
    "lat": 38.06391,
    "lng": -82.72941,
    "state_id": "KY"
  },
  {
    "zip": 41203,
    "lat": 37.83807,
    "lng": -82.44517,
    "state_id": "KY"
  },
  {
    "zip": 41204,
    "lat": 37.81988,
    "lng": -82.68462,
    "state_id": "KY"
  },
  {
    "zip": 41214,
    "lat": 37.80576,
    "lng": -82.60877,
    "state_id": "KY"
  },
  {
    "zip": 41216,
    "lat": 37.72903,
    "lng": -82.81382,
    "state_id": "KY"
  },
  {
    "zip": 41219,
    "lat": 37.92816,
    "lng": -82.90765,
    "state_id": "KY"
  },
  {
    "zip": 41222,
    "lat": 37.76927,
    "lng": -82.85531,
    "state_id": "KY"
  },
  {
    "zip": 41224,
    "lat": 37.82733,
    "lng": -82.53915,
    "state_id": "KY"
  },
  {
    "zip": 41226,
    "lat": 37.98036,
    "lng": -82.95574,
    "state_id": "KY"
  },
  {
    "zip": 41230,
    "lat": 38.06401,
    "lng": -82.66635,
    "state_id": "KY"
  },
  {
    "zip": 41231,
    "lat": 37.80721,
    "lng": -82.41043,
    "state_id": "KY"
  },
  {
    "zip": 41232,
    "lat": 37.93151,
    "lng": -82.73484,
    "state_id": "KY"
  },
  {
    "zip": 41234,
    "lat": 37.7945,
    "lng": -82.74025,
    "state_id": "KY"
  },
  {
    "zip": 41238,
    "lat": 37.84853,
    "lng": -82.95474,
    "state_id": "KY"
  },
  {
    "zip": 41240,
    "lat": 37.8239,
    "lng": -82.79748,
    "state_id": "KY"
  },
  {
    "zip": 41250,
    "lat": 37.73794,
    "lng": -82.45231,
    "state_id": "KY"
  },
  {
    "zip": 41254,
    "lat": 37.88958,
    "lng": -82.72129,
    "state_id": "KY"
  },
  {
    "zip": 41255,
    "lat": 37.91239,
    "lng": -82.83308,
    "state_id": "KY"
  },
  {
    "zip": 41256,
    "lat": 37.8303,
    "lng": -82.88499,
    "state_id": "KY"
  },
  {
    "zip": 41257,
    "lat": 37.92993,
    "lng": -82.79667,
    "state_id": "KY"
  },
  {
    "zip": 41260,
    "lat": 37.82198,
    "lng": -82.75265,
    "state_id": "KY"
  },
  {
    "zip": 41262,
    "lat": 37.86548,
    "lng": -82.60328,
    "state_id": "KY"
  },
  {
    "zip": 41263,
    "lat": 37.85177,
    "lng": -82.76021,
    "state_id": "KY"
  },
  {
    "zip": 41264,
    "lat": 37.94165,
    "lng": -82.67389,
    "state_id": "KY"
  },
  {
    "zip": 41265,
    "lat": 37.7481,
    "lng": -82.70538,
    "state_id": "KY"
  },
  {
    "zip": 41267,
    "lat": 37.87493,
    "lng": -82.43857,
    "state_id": "KY"
  },
  {
    "zip": 41268,
    "lat": 37.78867,
    "lng": -82.78498,
    "state_id": "KY"
  },
  {
    "zip": 41271,
    "lat": 37.82246,
    "lng": -82.72357,
    "state_id": "KY"
  },
  {
    "zip": 41274,
    "lat": 37.85832,
    "lng": -82.81079,
    "state_id": "KY"
  },
  {
    "zip": 41301,
    "lat": 37.71939,
    "lng": -83.50059,
    "state_id": "KY"
  },
  {
    "zip": 41311,
    "lat": 37.58224,
    "lng": -83.71104,
    "state_id": "KY"
  },
  {
    "zip": 41314,
    "lat": 37.42586,
    "lng": -83.6404,
    "state_id": "KY"
  },
  {
    "zip": 41317,
    "lat": 37.44763,
    "lng": -83.18915,
    "state_id": "KY"
  },
  {
    "zip": 41332,
    "lat": 37.80018,
    "lng": -83.38982,
    "state_id": "KY"
  },
  {
    "zip": 41339,
    "lat": 37.53586,
    "lng": -83.29614,
    "state_id": "KY"
  },
  {
    "zip": 41348,
    "lat": 37.42372,
    "lng": -83.3081,
    "state_id": "KY"
  },
  {
    "zip": 41352,
    "lat": 37.82357,
    "lng": -83.33355,
    "state_id": "KY"
  },
  {
    "zip": 41360,
    "lat": 37.78906,
    "lng": -83.62758,
    "state_id": "KY"
  },
  {
    "zip": 41365,
    "lat": 37.70928,
    "lng": -83.63143,
    "state_id": "KY"
  },
  {
    "zip": 41366,
    "lat": 37.59477,
    "lng": -83.21869,
    "state_id": "KY"
  },
  {
    "zip": 41367,
    "lat": 37.41673,
    "lng": -83.22555,
    "state_id": "KY"
  },
  {
    "zip": 41385,
    "lat": 37.65279,
    "lng": -83.35039,
    "state_id": "KY"
  },
  {
    "zip": 41390,
    "lat": 37.40954,
    "lng": -83.3764,
    "state_id": "KY"
  },
  {
    "zip": 41397,
    "lat": 37.68664,
    "lng": -83.67023,
    "state_id": "KY"
  },
  {
    "zip": 41408,
    "lat": 37.7808,
    "lng": -83.27927,
    "state_id": "KY"
  },
  {
    "zip": 41421,
    "lat": 37.98975,
    "lng": -83.19786,
    "state_id": "KY"
  },
  {
    "zip": 41425,
    "lat": 37.88389,
    "lng": -83.42416,
    "state_id": "KY"
  },
  {
    "zip": 41464,
    "lat": 37.66765,
    "lng": -82.97331,
    "state_id": "KY"
  },
  {
    "zip": 41465,
    "lat": 37.72848,
    "lng": -83.08566,
    "state_id": "KY"
  },
  {
    "zip": 41472,
    "lat": 37.94287,
    "lng": -83.21962,
    "state_id": "KY"
  },
  {
    "zip": 41501,
    "lat": 37.51611,
    "lng": -82.51726,
    "state_id": "KY"
  },
  {
    "zip": 41503,
    "lat": 37.66445,
    "lng": -82.28772,
    "state_id": "KY"
  },
  {
    "zip": 41512,
    "lat": 37.25381,
    "lng": -82.4761,
    "state_id": "KY"
  },
  {
    "zip": 41513,
    "lat": 37.3493,
    "lng": -82.34252,
    "state_id": "KY"
  },
  {
    "zip": 41514,
    "lat": 37.67083,
    "lng": -82.33077,
    "state_id": "KY"
  },
  {
    "zip": 41517,
    "lat": 37.19508,
    "lng": -82.58035,
    "state_id": "KY"
  },
  {
    "zip": 41519,
    "lat": 37.58399,
    "lng": -82.32156,
    "state_id": "KY"
  },
  {
    "zip": 41522,
    "lat": 37.31087,
    "lng": -82.40908,
    "state_id": "KY"
  },
  {
    "zip": 41524,
    "lat": 37.41797,
    "lng": -82.23107,
    "state_id": "KY"
  },
  {
    "zip": 41526,
    "lat": 37.43166,
    "lng": -82.50853,
    "state_id": "KY"
  },
  {
    "zip": 41527,
    "lat": 37.63207,
    "lng": -82.28901,
    "state_id": "KY"
  },
  {
    "zip": 41528,
    "lat": 37.54901,
    "lng": -82.13991,
    "state_id": "KY"
  },
  {
    "zip": 41531,
    "lat": 37.59301,
    "lng": -82.23266,
    "state_id": "KY"
  },
  {
    "zip": 41534,
    "lat": 37.27755,
    "lng": -82.47657,
    "state_id": "KY"
  },
  {
    "zip": 41535,
    "lat": 37.59634,
    "lng": -82.27968,
    "state_id": "KY"
  },
  {
    "zip": 41537,
    "lat": 37.22894,
    "lng": -82.61109,
    "state_id": "KY"
  },
  {
    "zip": 41538,
    "lat": 37.31164,
    "lng": -82.59555,
    "state_id": "KY"
  },
  {
    "zip": 41539,
    "lat": 37.50682,
    "lng": -82.32713,
    "state_id": "KY"
  },
  {
    "zip": 41540,
    "lat": 37.39094,
    "lng": -82.33492,
    "state_id": "KY"
  },
  {
    "zip": 41543,
    "lat": 37.5479,
    "lng": -82.28552,
    "state_id": "KY"
  },
  {
    "zip": 41544,
    "lat": 37.59399,
    "lng": -82.17406,
    "state_id": "KY"
  },
  {
    "zip": 41547,
    "lat": 37.53609,
    "lng": -82.09064,
    "state_id": "KY"
  },
  {
    "zip": 41548,
    "lat": 37.38145,
    "lng": -82.27265,
    "state_id": "KY"
  },
  {
    "zip": 41553,
    "lat": 37.47712,
    "lng": -82.16361,
    "state_id": "KY"
  },
  {
    "zip": 41554,
    "lat": 37.4486,
    "lng": -82.29924,
    "state_id": "KY"
  },
  {
    "zip": 41555,
    "lat": 37.53834,
    "lng": -82.25834,
    "state_id": "KY"
  },
  {
    "zip": 41557,
    "lat": 37.48553,
    "lng": -82.41805,
    "state_id": "KY"
  },
  {
    "zip": 41558,
    "lat": 37.53859,
    "lng": -82.20922,
    "state_id": "KY"
  },
  {
    "zip": 41559,
    "lat": 37.37443,
    "lng": -82.38292,
    "state_id": "KY"
  },
  {
    "zip": 41560,
    "lat": 37.39551,
    "lng": -82.57831,
    "state_id": "KY"
  },
  {
    "zip": 41562,
    "lat": 37.39869,
    "lng": -82.46364,
    "state_id": "KY"
  },
  {
    "zip": 41563,
    "lat": 37.23132,
    "lng": -82.53228,
    "state_id": "KY"
  },
  {
    "zip": 41564,
    "lat": 37.60768,
    "lng": -82.36479,
    "state_id": "KY"
  },
  {
    "zip": 41566,
    "lat": 37.40347,
    "lng": -82.2015,
    "state_id": "KY"
  },
  {
    "zip": 41567,
    "lat": 37.57503,
    "lng": -82.2833,
    "state_id": "KY"
  },
  {
    "zip": 41568,
    "lat": 37.50222,
    "lng": -82.06563,
    "state_id": "KY"
  },
  {
    "zip": 41571,
    "lat": 37.62755,
    "lng": -82.43557,
    "state_id": "KY"
  },
  {
    "zip": 41572,
    "lat": 37.31351,
    "lng": -82.64357,
    "state_id": "KY"
  },
  {
    "zip": 41601,
    "lat": 37.60744,
    "lng": -82.72251,
    "state_id": "KY"
  },
  {
    "zip": 41602,
    "lat": 37.73752,
    "lng": -82.74988,
    "state_id": "KY"
  },
  {
    "zip": 41603,
    "lat": 37.5588,
    "lng": -82.68829,
    "state_id": "KY"
  },
  {
    "zip": 41604,
    "lat": 37.38891,
    "lng": -82.66567,
    "state_id": "KY"
  },
  {
    "zip": 41605,
    "lat": 37.55546,
    "lng": -82.62666,
    "state_id": "KY"
  },
  {
    "zip": 41606,
    "lat": 37.34862,
    "lng": -82.73557,
    "state_id": "KY"
  },
  {
    "zip": 41607,
    "lat": 37.62526,
    "lng": -82.84181,
    "state_id": "KY"
  },
  {
    "zip": 41612,
    "lat": 37.35431,
    "lng": -82.72306,
    "state_id": "KY"
  },
  {
    "zip": 41615,
    "lat": 37.54387,
    "lng": -82.68177,
    "state_id": "KY"
  },
  {
    "zip": 41616,
    "lat": 37.58107,
    "lng": -82.8767,
    "state_id": "KY"
  },
  {
    "zip": 41619,
    "lat": 37.48058,
    "lng": -82.7416,
    "state_id": "KY"
  },
  {
    "zip": 41621,
    "lat": 37.62508,
    "lng": -82.72777,
    "state_id": "KY"
  },
  {
    "zip": 41622,
    "lat": 37.52229,
    "lng": -82.81697,
    "state_id": "KY"
  },
  {
    "zip": 41630,
    "lat": 37.4538,
    "lng": -82.86469,
    "state_id": "KY"
  },
  {
    "zip": 41631,
    "lat": 37.47297,
    "lng": -82.65525,
    "state_id": "KY"
  },
  {
    "zip": 41632,
    "lat": 37.54642,
    "lng": -82.94021,
    "state_id": "KY"
  },
  {
    "zip": 41635,
    "lat": 37.47706,
    "lng": -82.62675,
    "state_id": "KY"
  },
  {
    "zip": 41636,
    "lat": 37.39937,
    "lng": -82.72916,
    "state_id": "KY"
  },
  {
    "zip": 41640,
    "lat": 37.47755,
    "lng": -82.94522,
    "state_id": "KY"
  },
  {
    "zip": 41642,
    "lat": 37.59562,
    "lng": -82.64622,
    "state_id": "KY"
  },
  {
    "zip": 41643,
    "lat": 37.46001,
    "lng": -82.8326,
    "state_id": "KY"
  },
  {
    "zip": 41645,
    "lat": 37.52401,
    "lng": -82.79352,
    "state_id": "KY"
  },
  {
    "zip": 41647,
    "lat": 37.43553,
    "lng": -82.72034,
    "state_id": "KY"
  },
  {
    "zip": 41649,
    "lat": 37.57078,
    "lng": -82.78197,
    "state_id": "KY"
  },
  {
    "zip": 41650,
    "lat": 37.35451,
    "lng": -82.68332,
    "state_id": "KY"
  },
  {
    "zip": 41653,
    "lat": 37.66104,
    "lng": -82.76356,
    "state_id": "KY"
  },
  {
    "zip": 41655,
    "lat": 37.50502,
    "lng": -82.72489,
    "state_id": "KY"
  },
  {
    "zip": 41659,
    "lat": 37.57414,
    "lng": -82.62706,
    "state_id": "KY"
  },
  {
    "zip": 41660,
    "lat": 37.41261,
    "lng": -82.63714,
    "state_id": "KY"
  },
  {
    "zip": 41663,
    "lat": 37.56558,
    "lng": -82.65783,
    "state_id": "KY"
  },
  {
    "zip": 41666,
    "lat": 37.43933,
    "lng": -82.80732,
    "state_id": "KY"
  },
  {
    "zip": 41667,
    "lat": 37.32021,
    "lng": -82.69148,
    "state_id": "KY"
  },
  {
    "zip": 41669,
    "lat": 37.33167,
    "lng": -82.71552,
    "state_id": "KY"
  },
  {
    "zip": 41701,
    "lat": 37.29829,
    "lng": -83.19128,
    "state_id": "KY"
  },
  {
    "zip": 41712,
    "lat": 37.36672,
    "lng": -83.13816,
    "state_id": "KY"
  },
  {
    "zip": 41713,
    "lat": 37.21944,
    "lng": -83.27628,
    "state_id": "KY"
  },
  {
    "zip": 41714,
    "lat": 37.15778,
    "lng": -83.51806,
    "state_id": "KY"
  },
  {
    "zip": 41719,
    "lat": 37.29835,
    "lng": -83.25431,
    "state_id": "KY"
  },
  {
    "zip": 41721,
    "lat": 37.30927,
    "lng": -83.49427,
    "state_id": "KY"
  },
  {
    "zip": 41722,
    "lat": 37.3718,
    "lng": -83.11757,
    "state_id": "KY"
  },
  {
    "zip": 41723,
    "lat": 37.25347,
    "lng": -83.31956,
    "state_id": "KY"
  },
  {
    "zip": 41725,
    "lat": 37.33916,
    "lng": -83.0351,
    "state_id": "KY"
  },
  {
    "zip": 41727,
    "lat": 37.35386,
    "lng": -83.30575,
    "state_id": "KY"
  },
  {
    "zip": 41729,
    "lat": 37.2656,
    "lng": -83.21982,
    "state_id": "KY"
  },
  {
    "zip": 41731,
    "lat": 37.10919,
    "lng": -83.06907,
    "state_id": "KY"
  },
  {
    "zip": 41735,
    "lat": 37.01709,
    "lng": -83.10356,
    "state_id": "KY"
  },
  {
    "zip": 41739,
    "lat": 37.32532,
    "lng": -83.12149,
    "state_id": "KY"
  },
  {
    "zip": 41740,
    "lat": 37.35716,
    "lng": -83.05779,
    "state_id": "KY"
  },
  {
    "zip": 41745,
    "lat": 37.3353,
    "lng": -83.43471,
    "state_id": "KY"
  },
  {
    "zip": 41746,
    "lat": 37.21047,
    "lng": -83.09802,
    "state_id": "KY"
  },
  {
    "zip": 41749,
    "lat": 37.19766,
    "lng": -83.41011,
    "state_id": "KY"
  },
  {
    "zip": 41751,
    "lat": 37.2078,
    "lng": -83.13696,
    "state_id": "KY"
  },
  {
    "zip": 41754,
    "lat": 37.30031,
    "lng": -83.32191,
    "state_id": "KY"
  },
  {
    "zip": 41759,
    "lat": 37.2218,
    "lng": -83.02391,
    "state_id": "KY"
  },
  {
    "zip": 41760,
    "lat": 37.2054,
    "lng": -83.07866,
    "state_id": "KY"
  },
  {
    "zip": 41762,
    "lat": 37.25348,
    "lng": -83.48379,
    "state_id": "KY"
  },
  {
    "zip": 41763,
    "lat": 37.05686,
    "lng": -83.12943,
    "state_id": "KY"
  },
  {
    "zip": 41764,
    "lat": 37.12686,
    "lng": -83.25533,
    "state_id": "KY"
  },
  {
    "zip": 41766,
    "lat": 37.1799,
    "lng": -83.4657,
    "state_id": "KY"
  },
  {
    "zip": 41772,
    "lat": 37.42968,
    "lng": -83.04841,
    "state_id": "KY"
  },
  {
    "zip": 41773,
    "lat": 37.22864,
    "lng": -83.02845,
    "state_id": "KY"
  },
  {
    "zip": 41774,
    "lat": 37.15853,
    "lng": -83.14047,
    "state_id": "KY"
  },
  {
    "zip": 41775,
    "lat": 37.09369,
    "lng": -83.34195,
    "state_id": "KY"
  },
  {
    "zip": 41776,
    "lat": 37.16706,
    "lng": -83.29127,
    "state_id": "KY"
  },
  {
    "zip": 41777,
    "lat": 37.06254,
    "lng": -83.23274,
    "state_id": "KY"
  },
  {
    "zip": 41804,
    "lat": 37.15251,
    "lng": -82.99652,
    "state_id": "KY"
  },
  {
    "zip": 41810,
    "lat": 37.18516,
    "lng": -82.67389,
    "state_id": "KY"
  },
  {
    "zip": 41812,
    "lat": 37.24724,
    "lng": -82.74443,
    "state_id": "KY"
  },
  {
    "zip": 41815,
    "lat": 37.16152,
    "lng": -82.7974,
    "state_id": "KY"
  },
  {
    "zip": 41817,
    "lat": 37.35765,
    "lng": -82.92418,
    "state_id": "KY"
  },
  {
    "zip": 41819,
    "lat": 36.98922,
    "lng": -83.07941,
    "state_id": "KY"
  },
  {
    "zip": 41821,
    "lat": 37.0745,
    "lng": -83.01161,
    "state_id": "KY"
  },
  {
    "zip": 41822,
    "lat": 37.32615,
    "lng": -82.97337,
    "state_id": "KY"
  },
  {
    "zip": 41824,
    "lat": 37.20145,
    "lng": -82.87711,
    "state_id": "KY"
  },
  {
    "zip": 41825,
    "lat": 37.23018,
    "lng": -82.70802,
    "state_id": "KY"
  },
  {
    "zip": 41826,
    "lat": 37.16991,
    "lng": -82.9258,
    "state_id": "KY"
  },
  {
    "zip": 41828,
    "lat": 37.29524,
    "lng": -82.78645,
    "state_id": "KY"
  },
  {
    "zip": 41831,
    "lat": 37.3794,
    "lng": -82.95222,
    "state_id": "KY"
  },
  {
    "zip": 41832,
    "lat": 37.14879,
    "lng": -82.95853,
    "state_id": "KY"
  },
  {
    "zip": 41833,
    "lat": 37.03469,
    "lng": -82.98374,
    "state_id": "KY"
  },
  {
    "zip": 41834,
    "lat": 37.26133,
    "lng": -82.95686,
    "state_id": "KY"
  },
  {
    "zip": 41835,
    "lat": 37.21209,
    "lng": -82.66952,
    "state_id": "KY"
  },
  {
    "zip": 41836,
    "lat": 37.25954,
    "lng": -82.90993,
    "state_id": "KY"
  },
  {
    "zip": 41837,
    "lat": 37.12705,
    "lng": -82.74588,
    "state_id": "KY"
  },
  {
    "zip": 41838,
    "lat": 37.19805,
    "lng": -82.74767,
    "state_id": "KY"
  },
  {
    "zip": 41839,
    "lat": 37.4014,
    "lng": -82.87664,
    "state_id": "KY"
  },
  {
    "zip": 41840,
    "lat": 37.19718,
    "lng": -82.71545,
    "state_id": "KY"
  },
  {
    "zip": 41843,
    "lat": 37.27235,
    "lng": -82.86478,
    "state_id": "KY"
  },
  {
    "zip": 41844,
    "lat": 37.32677,
    "lng": -82.86426,
    "state_id": "KY"
  },
  {
    "zip": 41845,
    "lat": 37.13276,
    "lng": -82.91898,
    "state_id": "KY"
  },
  {
    "zip": 41847,
    "lat": 37.21149,
    "lng": -82.95737,
    "state_id": "KY"
  },
  {
    "zip": 41848,
    "lat": 37.09526,
    "lng": -82.94841,
    "state_id": "KY"
  },
  {
    "zip": 41849,
    "lat": 37.17462,
    "lng": -82.73404,
    "state_id": "KY"
  },
  {
    "zip": 41855,
    "lat": 37.19069,
    "lng": -82.78512,
    "state_id": "KY"
  },
  {
    "zip": 41858,
    "lat": 37.13537,
    "lng": -82.85272,
    "state_id": "KY"
  },
  {
    "zip": 41859,
    "lat": 37.39793,
    "lng": -82.77683,
    "state_id": "KY"
  },
  {
    "zip": 41861,
    "lat": 37.40578,
    "lng": -82.82191,
    "state_id": "KY"
  },
  {
    "zip": 41862,
    "lat": 37.35362,
    "lng": -82.79398,
    "state_id": "KY"
  },
  {
    "zip": 42001,
    "lat": 37.03762,
    "lng": -88.71089,
    "state_id": "KY"
  },
  {
    "zip": 42003,
    "lat": 37.01039,
    "lng": -88.58562,
    "state_id": "KY"
  },
  {
    "zip": 42020,
    "lat": 36.69232,
    "lng": -88.29296,
    "state_id": "KY"
  },
  {
    "zip": 42021,
    "lat": 36.80481,
    "lng": -89.02655,
    "state_id": "KY"
  },
  {
    "zip": 42022,
    "lat": 37.1459,
    "lng": -88.94554,
    "state_id": "KY"
  },
  {
    "zip": 42023,
    "lat": 36.87161,
    "lng": -88.9913,
    "state_id": "KY"
  },
  {
    "zip": 42024,
    "lat": 37.07568,
    "lng": -89.05165,
    "state_id": "KY"
  },
  {
    "zip": 42025,
    "lat": 36.85638,
    "lng": -88.3345,
    "state_id": "KY"
  },
  {
    "zip": 42027,
    "lat": 36.91631,
    "lng": -88.62851,
    "state_id": "KY"
  },
  {
    "zip": 42028,
    "lat": 37.23925,
    "lng": -88.3379,
    "state_id": "KY"
  },
  {
    "zip": 42029,
    "lat": 37.00775,
    "lng": -88.39222,
    "state_id": "KY"
  },
  {
    "zip": 42031,
    "lat": 36.68755,
    "lng": -88.99122,
    "state_id": "KY"
  },
  {
    "zip": 42032,
    "lat": 36.77221,
    "lng": -89.10806,
    "state_id": "KY"
  },
  {
    "zip": 42035,
    "lat": 36.90497,
    "lng": -88.84197,
    "state_id": "KY"
  },
  {
    "zip": 42036,
    "lat": 36.72199,
    "lng": -88.22525,
    "state_id": "KY"
  },
  {
    "zip": 42037,
    "lat": 37.17273,
    "lng": -88.18779,
    "state_id": "KY"
  },
  {
    "zip": 42038,
    "lat": 37.04143,
    "lng": -88.02225,
    "state_id": "KY"
  },
  {
    "zip": 42039,
    "lat": 36.78263,
    "lng": -88.83142,
    "state_id": "KY"
  },
  {
    "zip": 42040,
    "lat": 36.62056,
    "lng": -88.50269,
    "state_id": "KY"
  },
  {
    "zip": 42041,
    "lat": 36.54755,
    "lng": -88.87483,
    "state_id": "KY"
  },
  {
    "zip": 42044,
    "lat": 36.95872,
    "lng": -88.26478,
    "state_id": "KY"
  },
  {
    "zip": 42045,
    "lat": 37.06498,
    "lng": -88.26195,
    "state_id": "KY"
  },
  {
    "zip": 42047,
    "lat": 37.29583,
    "lng": -88.39236,
    "state_id": "KY"
  },
  {
    "zip": 42048,
    "lat": 36.76673,
    "lng": -88.228,
    "state_id": "KY"
  },
  {
    "zip": 42049,
    "lat": 36.5178,
    "lng": -88.3264,
    "state_id": "KY"
  },
  {
    "zip": 42050,
    "lat": 36.55193,
    "lng": -89.22078,
    "state_id": "KY"
  },
  {
    "zip": 42051,
    "lat": 36.8553,
    "lng": -88.64408,
    "state_id": "KY"
  },
  {
    "zip": 42053,
    "lat": 37.11293,
    "lng": -88.88106,
    "state_id": "KY"
  },
  {
    "zip": 42054,
    "lat": 36.71528,
    "lng": -88.43389,
    "state_id": "KY"
  },
  {
    "zip": 42055,
    "lat": 37.06848,
    "lng": -88.16781,
    "state_id": "KY"
  },
  {
    "zip": 42056,
    "lat": 37.1115,
    "lng": -88.98611,
    "state_id": "KY"
  },
  {
    "zip": 42058,
    "lat": 37.05917,
    "lng": -88.47485,
    "state_id": "KY"
  },
  {
    "zip": 42060,
    "lat": 36.9644,
    "lng": -88.82774,
    "state_id": "KY"
  },
  {
    "zip": 42061,
    "lat": 36.88267,
    "lng": -88.76968,
    "state_id": "KY"
  },
  {
    "zip": 42064,
    "lat": 37.35404,
    "lng": -88.0939,
    "state_id": "KY"
  },
  {
    "zip": 42066,
    "lat": 36.73284,
    "lng": -88.63943,
    "state_id": "KY"
  },
  {
    "zip": 42069,
    "lat": 36.92218,
    "lng": -88.75655,
    "state_id": "KY"
  },
  {
    "zip": 42071,
    "lat": 36.62034,
    "lng": -88.2839,
    "state_id": "KY"
  },
  {
    "zip": 42076,
    "lat": 36.55803,
    "lng": -88.09582,
    "state_id": "KY"
  },
  {
    "zip": 42078,
    "lat": 37.29174,
    "lng": -88.27515,
    "state_id": "KY"
  },
  {
    "zip": 42079,
    "lat": 36.56266,
    "lng": -88.57879,
    "state_id": "KY"
  },
  {
    "zip": 42081,
    "lat": 37.23217,
    "lng": -88.40806,
    "state_id": "KY"
  },
  {
    "zip": 42082,
    "lat": 36.9179,
    "lng": -88.49964,
    "state_id": "KY"
  },
  {
    "zip": 42083,
    "lat": 37.16145,
    "lng": -88.27925,
    "state_id": "KY"
  },
  {
    "zip": 42085,
    "lat": 36.5704,
    "lng": -88.80015,
    "state_id": "KY"
  },
  {
    "zip": 42086,
    "lat": 37.08913,
    "lng": -88.76903,
    "state_id": "KY"
  },
  {
    "zip": 42087,
    "lat": 36.97406,
    "lng": -89.00622,
    "state_id": "KY"
  },
  {
    "zip": 42088,
    "lat": 36.61604,
    "lng": -88.74671,
    "state_id": "KY"
  },
  {
    "zip": 42101,
    "lat": 37.06376,
    "lng": -86.47618,
    "state_id": "KY"
  },
  {
    "zip": 42102,
    "lat": 37.00366,
    "lng": -86.41825,
    "state_id": "KY"
  },
  {
    "zip": 42103,
    "lat": 36.94903,
    "lng": -86.33253,
    "state_id": "KY"
  },
  {
    "zip": 42104,
    "lat": 36.87837,
    "lng": -86.45125,
    "state_id": "KY"
  },
  {
    "zip": 42120,
    "lat": 36.6711,
    "lng": -86.2697,
    "state_id": "KY"
  },
  {
    "zip": 42122,
    "lat": 36.85954,
    "lng": -86.35821,
    "state_id": "KY"
  },
  {
    "zip": 42123,
    "lat": 36.82162,
    "lng": -85.98925,
    "state_id": "KY"
  },
  {
    "zip": 42124,
    "lat": 36.87052,
    "lng": -85.64393,
    "state_id": "KY"
  },
  {
    "zip": 42127,
    "lat": 37.12131,
    "lng": -85.92893,
    "state_id": "KY"
  },
  {
    "zip": 42129,
    "lat": 36.99117,
    "lng": -85.58874,
    "state_id": "KY"
  },
  {
    "zip": 42130,
    "lat": 36.92408,
    "lng": -85.77894,
    "state_id": "KY"
  },
  {
    "zip": 42133,
    "lat": 36.72482,
    "lng": -85.95753,
    "state_id": "KY"
  },
  {
    "zip": 42134,
    "lat": 36.72444,
    "lng": -86.56599,
    "state_id": "KY"
  },
  {
    "zip": 42140,
    "lat": 36.65651,
    "lng": -85.83405,
    "state_id": "KY"
  },
  {
    "zip": 42141,
    "lat": 36.94755,
    "lng": -85.91659,
    "state_id": "KY"
  },
  {
    "zip": 42151,
    "lat": 36.64816,
    "lng": -85.55848,
    "state_id": "KY"
  },
  {
    "zip": 42153,
    "lat": 36.66775,
    "lng": -86.06114,
    "state_id": "KY"
  },
  {
    "zip": 42154,
    "lat": 37.05422,
    "lng": -85.72543,
    "state_id": "KY"
  },
  {
    "zip": 42156,
    "lat": 36.85164,
    "lng": -86.05231,
    "state_id": "KY"
  },
  {
    "zip": 42157,
    "lat": 36.79088,
    "lng": -85.81515,
    "state_id": "KY"
  },
  {
    "zip": 42159,
    "lat": 36.99292,
    "lng": -86.24531,
    "state_id": "KY"
  },
  {
    "zip": 42160,
    "lat": 37.07785,
    "lng": -86.07503,
    "state_id": "KY"
  },
  {
    "zip": 42163,
    "lat": 37.0791,
    "lng": -86.13414,
    "state_id": "KY"
  },
  {
    "zip": 42164,
    "lat": 36.77559,
    "lng": -86.18471,
    "state_id": "KY"
  },
  {
    "zip": 42166,
    "lat": 36.87844,
    "lng": -85.70937,
    "state_id": "KY"
  },
  {
    "zip": 42167,
    "lat": 36.72411,
    "lng": -85.67362,
    "state_id": "KY"
  },
  {
    "zip": 42170,
    "lat": 36.84425,
    "lng": -86.57625,
    "state_id": "KY"
  },
  {
    "zip": 42171,
    "lat": 37.03473,
    "lng": -86.16596,
    "state_id": "KY"
  },
  {
    "zip": 42202,
    "lat": 36.68737,
    "lng": -86.86041,
    "state_id": "KY"
  },
  {
    "zip": 42204,
    "lat": 36.6958,
    "lng": -87.07358,
    "state_id": "KY"
  },
  {
    "zip": 42206,
    "lat": 36.8856,
    "lng": -86.72146,
    "state_id": "KY"
  },
  {
    "zip": 42207,
    "lat": 37.3034,
    "lng": -86.27858,
    "state_id": "KY"
  },
  {
    "zip": 42210,
    "lat": 37.20975,
    "lng": -86.2491,
    "state_id": "KY"
  },
  {
    "zip": 42211,
    "lat": 36.82146,
    "lng": -87.83899,
    "state_id": "KY"
  },
  {
    "zip": 42214,
    "lat": 37.13985,
    "lng": -85.6915,
    "state_id": "KY"
  },
  {
    "zip": 42215,
    "lat": 36.99698,
    "lng": -87.66707,
    "state_id": "KY"
  },
  {
    "zip": 42217,
    "lat": 37.04837,
    "lng": -87.46144,
    "state_id": "KY"
  },
  {
    "zip": 42220,
    "lat": 36.89552,
    "lng": -87.17904,
    "state_id": "KY"
  },
  {
    "zip": 42223,
    "lat": 36.62825,
    "lng": -87.46282,
    "state_id": "KY"
  },
  {
    "zip": 42232,
    "lat": 36.87531,
    "lng": -87.65855,
    "state_id": "KY"
  },
  {
    "zip": 42234,
    "lat": 36.70645,
    "lng": -87.17664,
    "state_id": "KY"
  },
  {
    "zip": 42236,
    "lat": 36.71982,
    "lng": -87.61942,
    "state_id": "KY"
  },
  {
    "zip": 42240,
    "lat": 36.87878,
    "lng": -87.46244,
    "state_id": "KY"
  },
  {
    "zip": 42254,
    "lat": 36.66083,
    "lng": -87.63741,
    "state_id": "KY"
  },
  {
    "zip": 42256,
    "lat": 37.01479,
    "lng": -86.94594,
    "state_id": "KY"
  },
  {
    "zip": 42259,
    "lat": 37.24387,
    "lng": -86.15143,
    "state_id": "KY"
  },
  {
    "zip": 42261,
    "lat": 37.21978,
    "lng": -86.66901,
    "state_id": "KY"
  },
  {
    "zip": 42262,
    "lat": 36.68155,
    "lng": -87.44869,
    "state_id": "KY"
  },
  {
    "zip": 42265,
    "lat": 36.73581,
    "lng": -86.99345,
    "state_id": "KY"
  },
  {
    "zip": 42266,
    "lat": 36.76621,
    "lng": -87.36027,
    "state_id": "KY"
  },
  {
    "zip": 42273,
    "lat": 37.21755,
    "lng": -86.85332,
    "state_id": "KY"
  },
  {
    "zip": 42274,
    "lat": 36.94686,
    "lng": -86.60235,
    "state_id": "KY"
  },
  {
    "zip": 42275,
    "lat": 37.22055,
    "lng": -86.44738,
    "state_id": "KY"
  },
  {
    "zip": 42276,
    "lat": 36.8571,
    "lng": -86.88077,
    "state_id": "KY"
  },
  {
    "zip": 42280,
    "lat": 36.95376,
    "lng": -87.09913,
    "state_id": "KY"
  },
  {
    "zip": 42285,
    "lat": 37.26405,
    "lng": -86.29777,
    "state_id": "KY"
  },
  {
    "zip": 42286,
    "lat": 36.72632,
    "lng": -87.27264,
    "state_id": "KY"
  },
  {
    "zip": 42301,
    "lat": 37.74038,
    "lng": -87.24615,
    "state_id": "KY"
  },
  {
    "zip": 42303,
    "lat": 37.76154,
    "lng": -87.04899,
    "state_id": "KY"
  },
  {
    "zip": 42320,
    "lat": 37.35244,
    "lng": -86.8709,
    "state_id": "KY"
  },
  {
    "zip": 42321,
    "lat": 37.17156,
    "lng": -87.05596,
    "state_id": "KY"
  },
  {
    "zip": 42322,
    "lat": 37.61369,
    "lng": -87.39532,
    "state_id": "KY"
  },
  {
    "zip": 42323,
    "lat": 37.17369,
    "lng": -87.03489,
    "state_id": "KY"
  },
  {
    "zip": 42324,
    "lat": 37.13534,
    "lng": -87.00093,
    "state_id": "KY"
  },
  {
    "zip": 42325,
    "lat": 37.32733,
    "lng": -87.27036,
    "state_id": "KY"
  },
  {
    "zip": 42326,
    "lat": 37.22439,
    "lng": -87.01035,
    "state_id": "KY"
  },
  {
    "zip": 42327,
    "lat": 37.58745,
    "lng": -87.30164,
    "state_id": "KY"
  },
  {
    "zip": 42328,
    "lat": 37.41858,
    "lng": -87.04225,
    "state_id": "KY"
  },
  {
    "zip": 42330,
    "lat": 37.32067,
    "lng": -87.12003,
    "state_id": "KY"
  },
  {
    "zip": 42332,
    "lat": 37.24996,
    "lng": -87.08786,
    "state_id": "KY"
  },
  {
    "zip": 42333,
    "lat": 37.36404,
    "lng": -86.76398,
    "state_id": "KY"
  },
  {
    "zip": 42337,
    "lat": 37.2272,
    "lng": -87.01079,
    "state_id": "KY"
  },
  {
    "zip": 42338,
    "lat": 37.54589,
    "lng": -86.79122,
    "state_id": "KY"
  },
  {
    "zip": 42339,
    "lat": 37.10953,
    "lng": -86.95003,
    "state_id": "KY"
  },
  {
    "zip": 42343,
    "lat": 37.63277,
    "lng": -86.6975,
    "state_id": "KY"
  },
  {
    "zip": 42344,
    "lat": 37.25686,
    "lng": -87.32011,
    "state_id": "KY"
  },
  {
    "zip": 42345,
    "lat": 37.14413,
    "lng": -87.18463,
    "state_id": "KY"
  },
  {
    "zip": 42347,
    "lat": 37.52455,
    "lng": -86.88429,
    "state_id": "KY"
  },
  {
    "zip": 42348,
    "lat": 37.83486,
    "lng": -86.76308,
    "state_id": "KY"
  },
  {
    "zip": 42349,
    "lat": 37.42765,
    "lng": -86.67895,
    "state_id": "KY"
  },
  {
    "zip": 42350,
    "lat": 37.44958,
    "lng": -87.1889,
    "state_id": "KY"
  },
  {
    "zip": 42351,
    "lat": 37.90602,
    "lng": -86.87955,
    "state_id": "KY"
  },
  {
    "zip": 42352,
    "lat": 37.51496,
    "lng": -87.08144,
    "state_id": "KY"
  },
  {
    "zip": 42354,
    "lat": 37.37782,
    "lng": -86.9297,
    "state_id": "KY"
  },
  {
    "zip": 42355,
    "lat": 37.85787,
    "lng": -86.98447,
    "state_id": "KY"
  },
  {
    "zip": 42356,
    "lat": 37.69335,
    "lng": -87.32407,
    "state_id": "KY"
  },
  {
    "zip": 42361,
    "lat": 37.52072,
    "lng": -86.6844,
    "state_id": "KY"
  },
  {
    "zip": 42366,
    "lat": 37.72267,
    "lng": -86.94541,
    "state_id": "KY"
  },
  {
    "zip": 42367,
    "lat": 37.2393,
    "lng": -87.16002,
    "state_id": "KY"
  },
  {
    "zip": 42368,
    "lat": 37.70262,
    "lng": -86.75501,
    "state_id": "KY"
  },
  {
    "zip": 42369,
    "lat": 37.3554,
    "lng": -87.00653,
    "state_id": "KY"
  },
  {
    "zip": 42370,
    "lat": 37.44912,
    "lng": -86.73906,
    "state_id": "KY"
  },
  {
    "zip": 42371,
    "lat": 37.49876,
    "lng": -87.28935,
    "state_id": "KY"
  },
  {
    "zip": 42372,
    "lat": 37.40537,
    "lng": -87.27471,
    "state_id": "KY"
  },
  {
    "zip": 42374,
    "lat": 37.33431,
    "lng": -87.15235,
    "state_id": "KY"
  },
  {
    "zip": 42376,
    "lat": 37.61685,
    "lng": -87.08757,
    "state_id": "KY"
  },
  {
    "zip": 42378,
    "lat": 37.65607,
    "lng": -86.86895,
    "state_id": "KY"
  },
  {
    "zip": 42404,
    "lat": 37.47709,
    "lng": -87.83306,
    "state_id": "KY"
  },
  {
    "zip": 42406,
    "lat": 37.76318,
    "lng": -87.73173,
    "state_id": "KY"
  },
  {
    "zip": 42408,
    "lat": 37.18064,
    "lng": -87.6943,
    "state_id": "KY"
  },
  {
    "zip": 42409,
    "lat": 37.54304,
    "lng": -87.70071,
    "state_id": "KY"
  },
  {
    "zip": 42410,
    "lat": 37.27717,
    "lng": -87.51257,
    "state_id": "KY"
  },
  {
    "zip": 42411,
    "lat": 37.20308,
    "lng": -88.01808,
    "state_id": "KY"
  },
  {
    "zip": 42413,
    "lat": 37.44364,
    "lng": -87.47093,
    "state_id": "KY"
  },
  {
    "zip": 42420,
    "lat": 37.80343,
    "lng": -87.5168,
    "state_id": "KY"
  },
  {
    "zip": 42431,
    "lat": 37.32921,
    "lng": -87.4783,
    "state_id": "KY"
  },
  {
    "zip": 42436,
    "lat": 37.41444,
    "lng": -87.57033,
    "state_id": "KY"
  },
  {
    "zip": 42437,
    "lat": 37.66395,
    "lng": -87.93655,
    "state_id": "KY"
  },
  {
    "zip": 42440,
    "lat": 37.24155,
    "lng": -87.4649,
    "state_id": "KY"
  },
  {
    "zip": 42441,
    "lat": 37.35487,
    "lng": -87.66825,
    "state_id": "KY"
  },
  {
    "zip": 42442,
    "lat": 37.16366,
    "lng": -87.49561,
    "state_id": "KY"
  },
  {
    "zip": 42445,
    "lat": 37.13023,
    "lng": -87.8547,
    "state_id": "KY"
  },
  {
    "zip": 42450,
    "lat": 37.39614,
    "lng": -87.76331,
    "state_id": "KY"
  },
  {
    "zip": 42451,
    "lat": 37.88635,
    "lng": -87.39155,
    "state_id": "KY"
  },
  {
    "zip": 42452,
    "lat": 37.68825,
    "lng": -87.53224,
    "state_id": "KY"
  },
  {
    "zip": 42453,
    "lat": 37.15801,
    "lng": -87.60336,
    "state_id": "KY"
  },
  {
    "zip": 42455,
    "lat": 37.5871,
    "lng": -87.54166,
    "state_id": "KY"
  },
  {
    "zip": 42456,
    "lat": 37.51336,
    "lng": -87.49436,
    "state_id": "KY"
  },
  {
    "zip": 42458,
    "lat": 37.84121,
    "lng": -87.4236,
    "state_id": "KY"
  },
  {
    "zip": 42459,
    "lat": 37.55856,
    "lng": -88.01533,
    "state_id": "KY"
  },
  {
    "zip": 42461,
    "lat": 37.79347,
    "lng": -87.91064,
    "state_id": "KY"
  },
  {
    "zip": 42462,
    "lat": 37.7409,
    "lng": -87.8007,
    "state_id": "KY"
  },
  {
    "zip": 42463,
    "lat": 37.47526,
    "lng": -87.8716,
    "state_id": "KY"
  },
  {
    "zip": 42464,
    "lat": 37.18152,
    "lng": -87.36706,
    "state_id": "KY"
  },
  {
    "zip": 42501,
    "lat": 37.06174,
    "lng": -84.44978,
    "state_id": "KY"
  },
  {
    "zip": 42503,
    "lat": 37.15641,
    "lng": -84.52524,
    "state_id": "KY"
  },
  {
    "zip": 42516,
    "lat": 37.22715,
    "lng": -84.7786,
    "state_id": "KY"
  },
  {
    "zip": 42518,
    "lat": 36.9346,
    "lng": -84.63301,
    "state_id": "KY"
  },
  {
    "zip": 42519,
    "lat": 36.93932,
    "lng": -84.53574,
    "state_id": "KY"
  },
  {
    "zip": 42528,
    "lat": 37.18413,
    "lng": -85.01403,
    "state_id": "KY"
  },
  {
    "zip": 42533,
    "lat": 37.06762,
    "lng": -84.5929,
    "state_id": "KY"
  },
  {
    "zip": 42539,
    "lat": 37.30381,
    "lng": -84.95386,
    "state_id": "KY"
  },
  {
    "zip": 42541,
    "lat": 37.3715,
    "lng": -84.79765,
    "state_id": "KY"
  },
  {
    "zip": 42544,
    "lat": 37.03401,
    "lng": -84.8166,
    "state_id": "KY"
  },
  {
    "zip": 42553,
    "lat": 37.17121,
    "lng": -84.697,
    "state_id": "KY"
  },
  {
    "zip": 42565,
    "lat": 37.15503,
    "lng": -84.89352,
    "state_id": "KY"
  },
  {
    "zip": 42566,
    "lat": 37.28532,
    "lng": -84.7797,
    "state_id": "KY"
  },
  {
    "zip": 42567,
    "lat": 37.26417,
    "lng": -84.60653,
    "state_id": "KY"
  },
  {
    "zip": 42602,
    "lat": 36.73159,
    "lng": -85.13322,
    "state_id": "KY"
  },
  {
    "zip": 42603,
    "lat": 36.78238,
    "lng": -85.02751,
    "state_id": "KY"
  },
  {
    "zip": 42629,
    "lat": 36.92484,
    "lng": -85.1168,
    "state_id": "KY"
  },
  {
    "zip": 42631,
    "lat": 36.73965,
    "lng": -84.48032,
    "state_id": "KY"
  },
  {
    "zip": 42633,
    "lat": 36.79286,
    "lng": -84.83042,
    "state_id": "KY"
  },
  {
    "zip": 42634,
    "lat": 36.8792,
    "lng": -84.41931,
    "state_id": "KY"
  },
  {
    "zip": 42635,
    "lat": 36.68069,
    "lng": -84.39841,
    "state_id": "KY"
  },
  {
    "zip": 42638,
    "lat": 36.67476,
    "lng": -84.47022,
    "state_id": "KY"
  },
  {
    "zip": 42642,
    "lat": 37.0551,
    "lng": -85.03748,
    "state_id": "KY"
  },
  {
    "zip": 42647,
    "lat": 36.69206,
    "lng": -84.61067,
    "state_id": "KY"
  },
  {
    "zip": 42649,
    "lat": 36.62464,
    "lng": -84.42251,
    "state_id": "KY"
  },
  {
    "zip": 42653,
    "lat": 36.77789,
    "lng": -84.4574,
    "state_id": "KY"
  },
  {
    "zip": 42701,
    "lat": 37.70498,
    "lng": -85.8358,
    "state_id": "KY"
  },
  {
    "zip": 42712,
    "lat": 37.56315,
    "lng": -86.15974,
    "state_id": "KY"
  },
  {
    "zip": 42713,
    "lat": 37.38324,
    "lng": -85.88309,
    "state_id": "KY"
  },
  {
    "zip": 42715,
    "lat": 36.95294,
    "lng": -85.39256,
    "state_id": "KY"
  },
  {
    "zip": 42716,
    "lat": 37.47647,
    "lng": -85.60847,
    "state_id": "KY"
  },
  {
    "zip": 42717,
    "lat": 36.78188,
    "lng": -85.37894,
    "state_id": "KY"
  },
  {
    "zip": 42718,
    "lat": 37.38734,
    "lng": -85.3736,
    "state_id": "KY"
  },
  {
    "zip": 42721,
    "lat": 37.42146,
    "lng": -86.49685,
    "state_id": "KY"
  },
  {
    "zip": 42722,
    "lat": 37.27541,
    "lng": -85.71688,
    "state_id": "KY"
  },
  {
    "zip": 42724,
    "lat": 37.67304,
    "lng": -86.06463,
    "state_id": "KY"
  },
  {
    "zip": 42726,
    "lat": 37.42496,
    "lng": -86.14914,
    "state_id": "KY"
  },
  {
    "zip": 42728,
    "lat": 37.11094,
    "lng": -85.28855,
    "state_id": "KY"
  },
  {
    "zip": 42729,
    "lat": 37.29759,
    "lng": -86.09407,
    "state_id": "KY"
  },
  {
    "zip": 42732,
    "lat": 37.60606,
    "lng": -86.11386,
    "state_id": "KY"
  },
  {
    "zip": 42733,
    "lat": 37.34337,
    "lng": -85.17314,
    "state_id": "KY"
  },
  {
    "zip": 42740,
    "lat": 37.59209,
    "lng": -85.94053,
    "state_id": "KY"
  },
  {
    "zip": 42741,
    "lat": 36.99531,
    "lng": -85.24056,
    "state_id": "KY"
  },
  {
    "zip": 42743,
    "lat": 37.22893,
    "lng": -85.53082,
    "state_id": "KY"
  },
  {
    "zip": 42746,
    "lat": 37.21189,
    "lng": -85.7336,
    "state_id": "KY"
  },
  {
    "zip": 42748,
    "lat": 37.57101,
    "lng": -85.70853,
    "state_id": "KY"
  },
  {
    "zip": 42749,
    "lat": 37.18954,
    "lng": -85.88021,
    "state_id": "KY"
  },
  {
    "zip": 42753,
    "lat": 37.22356,
    "lng": -85.18513,
    "state_id": "KY"
  },
  {
    "zip": 42754,
    "lat": 37.47598,
    "lng": -86.31461,
    "state_id": "KY"
  },
  {
    "zip": 42757,
    "lat": 37.40058,
    "lng": -85.72075,
    "state_id": "KY"
  },
  {
    "zip": 42758,
    "lat": 37.37623,
    "lng": -85.1991,
    "state_id": "KY"
  },
  {
    "zip": 42762,
    "lat": 37.45259,
    "lng": -86.39606,
    "state_id": "KY"
  },
  {
    "zip": 42764,
    "lat": 37.426,
    "lng": -85.61914,
    "state_id": "KY"
  },
  {
    "zip": 42765,
    "lat": 37.314,
    "lng": -85.92166,
    "state_id": "KY"
  },
  {
    "zip": 42776,
    "lat": 37.51361,
    "lng": -85.91646,
    "state_id": "KY"
  },
  {
    "zip": 42782,
    "lat": 37.34402,
    "lng": -85.63159,
    "state_id": "KY"
  },
  {
    "zip": 42784,
    "lat": 37.45122,
    "lng": -85.92067,
    "state_id": "KY"
  },
  {
    "zip": 42788,
    "lat": 37.52882,
    "lng": -86.03469,
    "state_id": "KY"
  },
  {
    "zip": 70001,
    "lat": 29.98373,
    "lng": -90.16724,
    "state_id": "LA"
  },
  {
    "zip": 70002,
    "lat": 30.01051,
    "lng": -90.16221,
    "state_id": "LA"
  },
  {
    "zip": 70003,
    "lat": 29.99855,
    "lng": -90.21402,
    "state_id": "LA"
  },
  {
    "zip": 70005,
    "lat": 29.99969,
    "lng": -90.1339,
    "state_id": "LA"
  },
  {
    "zip": 70006,
    "lat": 30.01408,
    "lng": -90.1918,
    "state_id": "LA"
  },
  {
    "zip": 70030,
    "lat": 29.81729,
    "lng": -90.43232,
    "state_id": "LA"
  },
  {
    "zip": 70031,
    "lat": 29.94194,
    "lng": -90.29591,
    "state_id": "LA"
  },
  {
    "zip": 70032,
    "lat": 29.95808,
    "lng": -89.99784,
    "state_id": "LA"
  },
  {
    "zip": 70036,
    "lat": 29.70904,
    "lng": -90.12118,
    "state_id": "LA"
  },
  {
    "zip": 70037,
    "lat": 29.7482,
    "lng": -90.00821,
    "state_id": "LA"
  },
  {
    "zip": 70038,
    "lat": 29.32522,
    "lng": -89.39859,
    "state_id": "LA"
  },
  {
    "zip": 70039,
    "lat": 29.88144,
    "lng": -90.38905,
    "state_id": "LA"
  },
  {
    "zip": 70040,
    "lat": 29.74732,
    "lng": -89.94379,
    "state_id": "LA"
  },
  {
    "zip": 70041,
    "lat": 29.37292,
    "lng": -89.53473,
    "state_id": "LA"
  },
  {
    "zip": 70043,
    "lat": 29.94804,
    "lng": -89.96274,
    "state_id": "LA"
  },
  {
    "zip": 70047,
    "lat": 29.96954,
    "lng": -90.36589,
    "state_id": "LA"
  },
  {
    "zip": 70049,
    "lat": 30.02889,
    "lng": -90.57025,
    "state_id": "LA"
  },
  {
    "zip": 70050,
    "lat": 29.38165,
    "lng": -89.59203,
    "state_id": "LA"
  },
  {
    "zip": 70051,
    "lat": 30.07286,
    "lng": -90.63104,
    "state_id": "LA"
  },
  {
    "zip": 70052,
    "lat": 30.08239,
    "lng": -90.6994,
    "state_id": "LA"
  },
  {
    "zip": 70053,
    "lat": 29.9145,
    "lng": -90.05338,
    "state_id": "LA"
  },
  {
    "zip": 70056,
    "lat": 29.88861,
    "lng": -90.0303,
    "state_id": "LA"
  },
  {
    "zip": 70057,
    "lat": 29.96601,
    "lng": -90.46279,
    "state_id": "LA"
  },
  {
    "zip": 70058,
    "lat": 29.87048,
    "lng": -90.06706,
    "state_id": "LA"
  },
  {
    "zip": 70062,
    "lat": 29.98927,
    "lng": -90.25576,
    "state_id": "LA"
  },
  {
    "zip": 70065,
    "lat": 30.02764,
    "lng": -90.25403,
    "state_id": "LA"
  },
  {
    "zip": 70067,
    "lat": 29.71427,
    "lng": -90.10525,
    "state_id": "LA"
  },
  {
    "zip": 70068,
    "lat": 30.13454,
    "lng": -90.43473,
    "state_id": "LA"
  },
  {
    "zip": 70070,
    "lat": 29.83446,
    "lng": -90.30509,
    "state_id": "LA"
  },
  {
    "zip": 70071,
    "lat": 30.05473,
    "lng": -90.70644,
    "state_id": "LA"
  },
  {
    "zip": 70072,
    "lat": 29.83601,
    "lng": -90.11042,
    "state_id": "LA"
  },
  {
    "zip": 70075,
    "lat": 29.93294,
    "lng": -89.9213,
    "state_id": "LA"
  },
  {
    "zip": 70076,
    "lat": 30.06504,
    "lng": -90.64642,
    "state_id": "LA"
  },
  {
    "zip": 70079,
    "lat": 30.004,
    "lng": -90.41029,
    "state_id": "LA"
  },
  {
    "zip": 70080,
    "lat": 29.88514,
    "lng": -90.43404,
    "state_id": "LA"
  },
  {
    "zip": 70082,
    "lat": 29.5582,
    "lng": -89.74973,
    "state_id": "LA"
  },
  {
    "zip": 70083,
    "lat": 29.50875,
    "lng": -89.85111,
    "state_id": "LA"
  },
  {
    "zip": 70084,
    "lat": 30.07521,
    "lng": -90.5679,
    "state_id": "LA"
  },
  {
    "zip": 70085,
    "lat": 29.83621,
    "lng": -89.73963,
    "state_id": "LA"
  },
  {
    "zip": 70086,
    "lat": 30.03206,
    "lng": -90.87544,
    "state_id": "LA"
  },
  {
    "zip": 70087,
    "lat": 29.97743,
    "lng": -90.31484,
    "state_id": "LA"
  },
  {
    "zip": 70090,
    "lat": 29.96846,
    "lng": -90.70461,
    "state_id": "LA"
  },
  {
    "zip": 70091,
    "lat": 29.21349,
    "lng": -89.28346,
    "state_id": "LA"
  },
  {
    "zip": 70092,
    "lat": 29.89997,
    "lng": -89.89274,
    "state_id": "LA"
  },
  {
    "zip": 70094,
    "lat": 29.91347,
    "lng": -90.20805,
    "state_id": "LA"
  },
  {
    "zip": 70112,
    "lat": 29.95705,
    "lng": -90.07686,
    "state_id": "LA"
  },
  {
    "zip": 70113,
    "lat": 29.94295,
    "lng": -90.08308,
    "state_id": "LA"
  },
  {
    "zip": 70114,
    "lat": 29.93794,
    "lng": -90.03267,
    "state_id": "LA"
  },
  {
    "zip": 70115,
    "lat": 29.92391,
    "lng": -90.10201,
    "state_id": "LA"
  },
  {
    "zip": 70116,
    "lat": 29.96725,
    "lng": -90.0644,
    "state_id": "LA"
  },
  {
    "zip": 70117,
    "lat": 29.96845,
    "lng": -90.03005,
    "state_id": "LA"
  },
  {
    "zip": 70118,
    "lat": 29.94519,
    "lng": -90.12557,
    "state_id": "LA"
  },
  {
    "zip": 70119,
    "lat": 29.97572,
    "lng": -90.08698,
    "state_id": "LA"
  },
  {
    "zip": 70121,
    "lat": 29.96184,
    "lng": -90.16244,
    "state_id": "LA"
  },
  {
    "zip": 70122,
    "lat": 30.01317,
    "lng": -90.06285,
    "state_id": "LA"
  },
  {
    "zip": 70123,
    "lat": 29.9509,
    "lng": -90.20507,
    "state_id": "LA"
  },
  {
    "zip": 70124,
    "lat": 30.00748,
    "lng": -90.10373,
    "state_id": "LA"
  },
  {
    "zip": 70125,
    "lat": 29.9521,
    "lng": -90.10335,
    "state_id": "LA"
  },
  {
    "zip": 70126,
    "lat": 30.01849,
    "lng": -90.02115,
    "state_id": "LA"
  },
  {
    "zip": 70127,
    "lat": 30.02506,
    "lng": -89.97673,
    "state_id": "LA"
  },
  {
    "zip": 70128,
    "lat": 30.05108,
    "lng": -89.95511,
    "state_id": "LA"
  },
  {
    "zip": 70129,
    "lat": 30.08249,
    "lng": -89.81558,
    "state_id": "LA"
  },
  {
    "zip": 70130,
    "lat": 29.93676,
    "lng": -90.06934,
    "state_id": "LA"
  },
  {
    "zip": 70131,
    "lat": 29.90818,
    "lng": -89.96119,
    "state_id": "LA"
  },
  {
    "zip": 70139,
    "lat": 29.95023,
    "lng": -90.071,
    "state_id": "LA"
  },
  {
    "zip": 70163,
    "lat": 29.95006,
    "lng": -90.07544,
    "state_id": "LA"
  },
  {
    "zip": 70301,
    "lat": 29.81411,
    "lng": -90.74082,
    "state_id": "LA"
  },
  {
    "zip": 70339,
    "lat": 29.92366,
    "lng": -91.18158,
    "state_id": "LA"
  },
  {
    "zip": 70340,
    "lat": 29.66974,
    "lng": -91.1043,
    "state_id": "LA"
  },
  {
    "zip": 70341,
    "lat": 30.03056,
    "lng": -91.0679,
    "state_id": "LA"
  },
  {
    "zip": 70342,
    "lat": 29.69486,
    "lng": -91.23766,
    "state_id": "LA"
  },
  {
    "zip": 70343,
    "lat": 29.54813,
    "lng": -90.55717,
    "state_id": "LA"
  },
  {
    "zip": 70344,
    "lat": 29.31205,
    "lng": -90.64965,
    "state_id": "LA"
  },
  {
    "zip": 70345,
    "lat": 29.55121,
    "lng": -90.26282,
    "state_id": "LA"
  },
  {
    "zip": 70346,
    "lat": 30.12207,
    "lng": -91.02757,
    "state_id": "LA"
  },
  {
    "zip": 70352,
    "lat": 29.68513,
    "lng": -90.94384,
    "state_id": "LA"
  },
  {
    "zip": 70353,
    "lat": 29.37072,
    "lng": -90.70531,
    "state_id": "LA"
  },
  {
    "zip": 70354,
    "lat": 29.43738,
    "lng": -90.28482,
    "state_id": "LA"
  },
  {
    "zip": 70355,
    "lat": 29.69904,
    "lng": -90.42925,
    "state_id": "LA"
  },
  {
    "zip": 70356,
    "lat": 29.64175,
    "lng": -90.9606,
    "state_id": "LA"
  },
  {
    "zip": 70357,
    "lat": 29.28651,
    "lng": -90.18315,
    "state_id": "LA"
  },
  {
    "zip": 70358,
    "lat": 29.21577,
    "lng": -90.02775,
    "state_id": "LA"
  },
  {
    "zip": 70359,
    "lat": 29.69401,
    "lng": -90.77654,
    "state_id": "LA"
  },
  {
    "zip": 70360,
    "lat": 29.5882,
    "lng": -90.81023,
    "state_id": "LA"
  },
  {
    "zip": 70363,
    "lat": 29.51679,
    "lng": -90.70662,
    "state_id": "LA"
  },
  {
    "zip": 70364,
    "lat": 29.63437,
    "lng": -90.68765,
    "state_id": "LA"
  },
  {
    "zip": 70372,
    "lat": 29.78302,
    "lng": -90.98896,
    "state_id": "LA"
  },
  {
    "zip": 70373,
    "lat": 29.59705,
    "lng": -90.34243,
    "state_id": "LA"
  },
  {
    "zip": 70374,
    "lat": 29.60197,
    "lng": -90.49873,
    "state_id": "LA"
  },
  {
    "zip": 70375,
    "lat": 29.69274,
    "lng": -90.53684,
    "state_id": "LA"
  },
  {
    "zip": 70377,
    "lat": 29.38943,
    "lng": -90.51238,
    "state_id": "LA"
  },
  {
    "zip": 70380,
    "lat": 29.72179,
    "lng": -91.11651,
    "state_id": "LA"
  },
  {
    "zip": 70390,
    "lat": 29.8964,
    "lng": -91.02726,
    "state_id": "LA"
  },
  {
    "zip": 70391,
    "lat": 29.99096,
    "lng": -91.06069,
    "state_id": "LA"
  },
  {
    "zip": 70392,
    "lat": 29.73002,
    "lng": -91.3167,
    "state_id": "LA"
  },
  {
    "zip": 70393,
    "lat": 29.99136,
    "lng": -91.00732,
    "state_id": "LA"
  },
  {
    "zip": 70394,
    "lat": 29.69449,
    "lng": -90.61115,
    "state_id": "LA"
  },
  {
    "zip": 70395,
    "lat": 29.69301,
    "lng": -90.85117,
    "state_id": "LA"
  },
  {
    "zip": 70397,
    "lat": 29.40939,
    "lng": -90.7859,
    "state_id": "LA"
  },
  {
    "zip": 70401,
    "lat": 30.52828,
    "lng": -90.45675,
    "state_id": "LA"
  },
  {
    "zip": 70402,
    "lat": 30.51619,
    "lng": -90.4702,
    "state_id": "LA"
  },
  {
    "zip": 70403,
    "lat": 30.48002,
    "lng": -90.48386,
    "state_id": "LA"
  },
  {
    "zip": 70420,
    "lat": 30.4872,
    "lng": -89.96749,
    "state_id": "LA"
  },
  {
    "zip": 70422,
    "lat": 30.73532,
    "lng": -90.48247,
    "state_id": "LA"
  },
  {
    "zip": 70426,
    "lat": 30.93355,
    "lng": -89.85845,
    "state_id": "LA"
  },
  {
    "zip": 70427,
    "lat": 30.74609,
    "lng": -89.91074,
    "state_id": "LA"
  },
  {
    "zip": 70431,
    "lat": 30.61318,
    "lng": -89.97384,
    "state_id": "LA"
  },
  {
    "zip": 70433,
    "lat": 30.46329,
    "lng": -90.1384,
    "state_id": "LA"
  },
  {
    "zip": 70435,
    "lat": 30.55782,
    "lng": -90.10716,
    "state_id": "LA"
  },
  {
    "zip": 70436,
    "lat": 30.80301,
    "lng": -90.52175,
    "state_id": "LA"
  },
  {
    "zip": 70437,
    "lat": 30.62026,
    "lng": -90.20867,
    "state_id": "LA"
  },
  {
    "zip": 70438,
    "lat": 30.84676,
    "lng": -90.10812,
    "state_id": "LA"
  },
  {
    "zip": 70441,
    "lat": 30.85865,
    "lng": -90.75279,
    "state_id": "LA"
  },
  {
    "zip": 70442,
    "lat": 30.68956,
    "lng": -90.32822,
    "state_id": "LA"
  },
  {
    "zip": 70443,
    "lat": 30.62961,
    "lng": -90.53603,
    "state_id": "LA"
  },
  {
    "zip": 70444,
    "lat": 30.90655,
    "lng": -90.48962,
    "state_id": "LA"
  },
  {
    "zip": 70445,
    "lat": 30.3677,
    "lng": -89.9128,
    "state_id": "LA"
  },
  {
    "zip": 70446,
    "lat": 30.62888,
    "lng": -90.34845,
    "state_id": "LA"
  },
  {
    "zip": 70447,
    "lat": 30.42117,
    "lng": -90.2015,
    "state_id": "LA"
  },
  {
    "zip": 70448,
    "lat": 30.3628,
    "lng": -90.03942,
    "state_id": "LA"
  },
  {
    "zip": 70449,
    "lat": 30.25594,
    "lng": -90.66905,
    "state_id": "LA"
  },
  {
    "zip": 70450,
    "lat": 30.93705,
    "lng": -90.26969,
    "state_id": "LA"
  },
  {
    "zip": 70451,
    "lat": 30.5484,
    "lng": -90.48091,
    "state_id": "LA"
  },
  {
    "zip": 70452,
    "lat": 30.43024,
    "lng": -89.78728,
    "state_id": "LA"
  },
  {
    "zip": 70453,
    "lat": 30.6939,
    "lng": -90.76648,
    "state_id": "LA"
  },
  {
    "zip": 70454,
    "lat": 30.40094,
    "lng": -90.37267,
    "state_id": "LA"
  },
  {
    "zip": 70455,
    "lat": 30.51962,
    "lng": -90.32459,
    "state_id": "LA"
  },
  {
    "zip": 70456,
    "lat": 30.78983,
    "lng": -90.50598,
    "state_id": "LA"
  },
  {
    "zip": 70458,
    "lat": 30.25865,
    "lng": -89.79441,
    "state_id": "LA"
  },
  {
    "zip": 70460,
    "lat": 30.29569,
    "lng": -89.83778,
    "state_id": "LA"
  },
  {
    "zip": 70461,
    "lat": 30.23554,
    "lng": -89.71583,
    "state_id": "LA"
  },
  {
    "zip": 70462,
    "lat": 30.36609,
    "lng": -90.5838,
    "state_id": "LA"
  },
  {
    "zip": 70463,
    "lat": 30.65861,
    "lng": -89.90387,
    "state_id": "LA"
  },
  {
    "zip": 70464,
    "lat": 30.53955,
    "lng": -89.91005,
    "state_id": "LA"
  },
  {
    "zip": 70465,
    "lat": 30.85157,
    "lng": -90.51982,
    "state_id": "LA"
  },
  {
    "zip": 70466,
    "lat": 30.56807,
    "lng": -90.50141,
    "state_id": "LA"
  },
  {
    "zip": 70471,
    "lat": 30.4072,
    "lng": -90.06234,
    "state_id": "LA"
  },
  {
    "zip": 70501,
    "lat": 30.23973,
    "lng": -91.99045,
    "state_id": "LA"
  },
  {
    "zip": 70503,
    "lat": 30.17281,
    "lng": -92.06059,
    "state_id": "LA"
  },
  {
    "zip": 70506,
    "lat": 30.19548,
    "lng": -92.08133,
    "state_id": "LA"
  },
  {
    "zip": 70507,
    "lat": 30.28173,
    "lng": -92.03049,
    "state_id": "LA"
  },
  {
    "zip": 70508,
    "lat": 30.15553,
    "lng": -92.02861,
    "state_id": "LA"
  },
  {
    "zip": 70510,
    "lat": 29.89809,
    "lng": -92.19968,
    "state_id": "LA"
  },
  {
    "zip": 70512,
    "lat": 30.41407,
    "lng": -91.92303,
    "state_id": "LA"
  },
  {
    "zip": 70513,
    "lat": 29.90354,
    "lng": -91.90288,
    "state_id": "LA"
  },
  {
    "zip": 70514,
    "lat": 29.84593,
    "lng": -91.55177,
    "state_id": "LA"
  },
  {
    "zip": 70515,
    "lat": 30.45212,
    "lng": -92.56949,
    "state_id": "LA"
  },
  {
    "zip": 70516,
    "lat": 30.36575,
    "lng": -92.30371,
    "state_id": "LA"
  },
  {
    "zip": 70517,
    "lat": 30.29484,
    "lng": -91.82954,
    "state_id": "LA"
  },
  {
    "zip": 70518,
    "lat": 30.1334,
    "lng": -91.92882,
    "state_id": "LA"
  },
  {
    "zip": 70519,
    "lat": 30.08019,
    "lng": -91.89741,
    "state_id": "LA"
  },
  {
    "zip": 70520,
    "lat": 30.332,
    "lng": -92.03576,
    "state_id": "LA"
  },
  {
    "zip": 70523,
    "lat": 29.88904,
    "lng": -91.5011,
    "state_id": "LA"
  },
  {
    "zip": 70524,
    "lat": 30.55692,
    "lng": -92.31134,
    "state_id": "LA"
  },
  {
    "zip": 70525,
    "lat": 30.41227,
    "lng": -92.2212,
    "state_id": "LA"
  },
  {
    "zip": 70526,
    "lat": 30.2102,
    "lng": -92.37984,
    "state_id": "LA"
  },
  {
    "zip": 70528,
    "lat": 29.93946,
    "lng": -91.985,
    "state_id": "LA"
  },
  {
    "zip": 70529,
    "lat": 30.19693,
    "lng": -92.16228,
    "state_id": "LA"
  },
  {
    "zip": 70531,
    "lat": 30.23273,
    "lng": -92.50828,
    "state_id": "LA"
  },
  {
    "zip": 70532,
    "lat": 30.46656,
    "lng": -92.69758,
    "state_id": "LA"
  },
  {
    "zip": 70533,
    "lat": 29.8972,
    "lng": -92.03969,
    "state_id": "LA"
  },
  {
    "zip": 70534,
    "lat": 30.19491,
    "lng": -92.44206,
    "state_id": "LA"
  },
  {
    "zip": 70535,
    "lat": 30.47822,
    "lng": -92.4264,
    "state_id": "LA"
  },
  {
    "zip": 70537,
    "lat": 30.25736,
    "lng": -92.56671,
    "state_id": "LA"
  },
  {
    "zip": 70538,
    "lat": 29.76614,
    "lng": -91.58223,
    "state_id": "LA"
  },
  {
    "zip": 70541,
    "lat": 30.4256,
    "lng": -92.04582,
    "state_id": "LA"
  },
  {
    "zip": 70542,
    "lat": 30.00522,
    "lng": -92.58841,
    "state_id": "LA"
  },
  {
    "zip": 70543,
    "lat": 30.33473,
    "lng": -92.50079,
    "state_id": "LA"
  },
  {
    "zip": 70544,
    "lat": 29.90438,
    "lng": -91.66229,
    "state_id": "LA"
  },
  {
    "zip": 70546,
    "lat": 30.2652,
    "lng": -92.66776,
    "state_id": "LA"
  },
  {
    "zip": 70548,
    "lat": 29.80528,
    "lng": -92.40555,
    "state_id": "LA"
  },
  {
    "zip": 70549,
    "lat": 30.07158,
    "lng": -92.80294,
    "state_id": "LA"
  },
  {
    "zip": 70550,
    "lat": 30.51867,
    "lng": -92.18768,
    "state_id": "LA"
  },
  {
    "zip": 70552,
    "lat": 30.0668,
    "lng": -91.69062,
    "state_id": "LA"
  },
  {
    "zip": 70554,
    "lat": 30.62249,
    "lng": -92.4793,
    "state_id": "LA"
  },
  {
    "zip": 70555,
    "lat": 30.08119,
    "lng": -92.14556,
    "state_id": "LA"
  },
  {
    "zip": 70556,
    "lat": 30.1939,
    "lng": -92.55982,
    "state_id": "LA"
  },
  {
    "zip": 70558,
    "lat": 30.10044,
    "lng": -92.07575,
    "state_id": "LA"
  },
  {
    "zip": 70559,
    "lat": 30.12481,
    "lng": -92.50458,
    "state_id": "LA"
  },
  {
    "zip": 70560,
    "lat": 29.92402,
    "lng": -91.87079,
    "state_id": "LA"
  },
  {
    "zip": 70563,
    "lat": 30.02466,
    "lng": -91.72841,
    "state_id": "LA"
  },
  {
    "zip": 70570,
    "lat": 30.53274,
    "lng": -92.10482,
    "state_id": "LA"
  },
  {
    "zip": 70575,
    "lat": 29.94894,
    "lng": -92.15808,
    "state_id": "LA"
  },
  {
    "zip": 70576,
    "lat": 30.78097,
    "lng": -92.41826,
    "state_id": "LA"
  },
  {
    "zip": 70577,
    "lat": 30.55119,
    "lng": -91.93386,
    "state_id": "LA"
  },
  {
    "zip": 70578,
    "lat": 30.22932,
    "lng": -92.26141,
    "state_id": "LA"
  },
  {
    "zip": 70580,
    "lat": 30.67524,
    "lng": -92.42832,
    "state_id": "LA"
  },
  {
    "zip": 70581,
    "lat": 30.25173,
    "lng": -92.73797,
    "state_id": "LA"
  },
  {
    "zip": 70582,
    "lat": 30.15874,
    "lng": -91.78739,
    "state_id": "LA"
  },
  {
    "zip": 70583,
    "lat": 30.25964,
    "lng": -92.12203,
    "state_id": "LA"
  },
  {
    "zip": 70584,
    "lat": 30.38841,
    "lng": -92.10134,
    "state_id": "LA"
  },
  {
    "zip": 70585,
    "lat": 30.87834,
    "lng": -92.40767,
    "state_id": "LA"
  },
  {
    "zip": 70586,
    "lat": 30.74143,
    "lng": -92.33756,
    "state_id": "LA"
  },
  {
    "zip": 70589,
    "lat": 30.68384,
    "lng": -92.02521,
    "state_id": "LA"
  },
  {
    "zip": 70591,
    "lat": 30.25535,
    "lng": -92.83885,
    "state_id": "LA"
  },
  {
    "zip": 70592,
    "lat": 30.08065,
    "lng": -92.01235,
    "state_id": "LA"
  },
  {
    "zip": 70601,
    "lat": 30.22622,
    "lng": -93.21575,
    "state_id": "LA"
  },
  {
    "zip": 70605,
    "lat": 30.13066,
    "lng": -93.27365,
    "state_id": "LA"
  },
  {
    "zip": 70607,
    "lat": 30.04236,
    "lng": -93.20414,
    "state_id": "LA"
  },
  {
    "zip": 70611,
    "lat": 30.34597,
    "lng": -93.20159,
    "state_id": "LA"
  },
  {
    "zip": 70615,
    "lat": 30.25365,
    "lng": -93.12276,
    "state_id": "LA"
  },
  {
    "zip": 70630,
    "lat": 30.02719,
    "lng": -93.00631,
    "state_id": "LA"
  },
  {
    "zip": 70631,
    "lat": 29.86632,
    "lng": -93.55142,
    "state_id": "LA"
  },
  {
    "zip": 70632,
    "lat": 29.84012,
    "lng": -93.02113,
    "state_id": "LA"
  },
  {
    "zip": 70633,
    "lat": 30.42202,
    "lng": -93.39729,
    "state_id": "LA"
  },
  {
    "zip": 70634,
    "lat": 30.79388,
    "lng": -93.23973,
    "state_id": "LA"
  },
  {
    "zip": 70637,
    "lat": 30.70431,
    "lng": -92.96708,
    "state_id": "LA"
  },
  {
    "zip": 70638,
    "lat": 30.8541,
    "lng": -92.79731,
    "state_id": "LA"
  },
  {
    "zip": 70639,
    "lat": 30.98475,
    "lng": -93.49102,
    "state_id": "LA"
  },
  {
    "zip": 70640,
    "lat": 30.37078,
    "lng": -92.90717,
    "state_id": "LA"
  },
  {
    "zip": 70643,
    "lat": 29.7921,
    "lng": -92.78968,
    "state_id": "LA"
  },
  {
    "zip": 70644,
    "lat": 30.7916,
    "lng": -92.94369,
    "state_id": "LA"
  },
  {
    "zip": 70645,
    "lat": 29.96154,
    "lng": -93.40869,
    "state_id": "LA"
  },
  {
    "zip": 70646,
    "lat": 30.09869,
    "lng": -92.92143,
    "state_id": "LA"
  },
  {
    "zip": 70647,
    "lat": 30.25705,
    "lng": -93.01872,
    "state_id": "LA"
  },
  {
    "zip": 70648,
    "lat": 30.50196,
    "lng": -92.8787,
    "state_id": "LA"
  },
  {
    "zip": 70650,
    "lat": 30.23744,
    "lng": -92.9232,
    "state_id": "LA"
  },
  {
    "zip": 70651,
    "lat": 30.54399,
    "lng": -92.95089,
    "state_id": "LA"
  },
  {
    "zip": 70652,
    "lat": 30.60771,
    "lng": -93.26731,
    "state_id": "LA"
  },
  {
    "zip": 70653,
    "lat": 30.66353,
    "lng": -93.56221,
    "state_id": "LA"
  },
  {
    "zip": 70654,
    "lat": 30.65505,
    "lng": -92.88407,
    "state_id": "LA"
  },
  {
    "zip": 70655,
    "lat": 30.66444,
    "lng": -92.72386,
    "state_id": "LA"
  },
  {
    "zip": 70656,
    "lat": 30.96017,
    "lng": -92.96962,
    "state_id": "LA"
  },
  {
    "zip": 70657,
    "lat": 30.50316,
    "lng": -93.15042,
    "state_id": "LA"
  },
  {
    "zip": 70658,
    "lat": 30.52867,
    "lng": -93.0476,
    "state_id": "LA"
  },
  {
    "zip": 70659,
    "lat": 30.92143,
    "lng": -93.28381,
    "state_id": "LA"
  },
  {
    "zip": 70660,
    "lat": 30.55763,
    "lng": -93.45028,
    "state_id": "LA"
  },
  {
    "zip": 70661,
    "lat": 30.3599,
    "lng": -93.6503,
    "state_id": "LA"
  },
  {
    "zip": 70662,
    "lat": 30.79888,
    "lng": -92.99766,
    "state_id": "LA"
  },
  {
    "zip": 70663,
    "lat": 30.28964,
    "lng": -93.37951,
    "state_id": "LA"
  },
  {
    "zip": 70665,
    "lat": 30.12584,
    "lng": -93.44997,
    "state_id": "LA"
  },
  {
    "zip": 70668,
    "lat": 30.18695,
    "lng": -93.59897,
    "state_id": "LA"
  },
  {
    "zip": 70669,
    "lat": 30.2434,
    "lng": -93.27906,
    "state_id": "LA"
  },
  {
    "zip": 70706,
    "lat": 30.61374,
    "lng": -90.9015,
    "state_id": "LA"
  },
  {
    "zip": 70710,
    "lat": 30.35311,
    "lng": -91.25939,
    "state_id": "LA"
  },
  {
    "zip": 70711,
    "lat": 30.53164,
    "lng": -90.59957,
    "state_id": "LA"
  },
  {
    "zip": 70712,
    "lat": 30.96944,
    "lng": -91.59642,
    "state_id": "LA"
  },
  {
    "zip": 70714,
    "lat": 30.58646,
    "lng": -91.1277,
    "state_id": "LA"
  },
  {
    "zip": 70715,
    "lat": 30.75799,
    "lng": -91.67792,
    "state_id": "LA"
  },
  {
    "zip": 70719,
    "lat": 30.3769,
    "lng": -91.28986,
    "state_id": "LA"
  },
  {
    "zip": 70721,
    "lat": 30.2182,
    "lng": -91.08694,
    "state_id": "LA"
  },
  {
    "zip": 70722,
    "lat": 30.8425,
    "lng": -90.93158,
    "state_id": "LA"
  },
  {
    "zip": 70723,
    "lat": 30.05947,
    "lng": -90.83847,
    "state_id": "LA"
  },
  {
    "zip": 70725,
    "lat": 30.13057,
    "lng": -90.9581,
    "state_id": "LA"
  },
  {
    "zip": 70726,
    "lat": 30.42802,
    "lng": -90.89899,
    "state_id": "LA"
  },
  {
    "zip": 70729,
    "lat": 30.58591,
    "lng": -91.35059,
    "state_id": "LA"
  },
  {
    "zip": 70730,
    "lat": 30.81633,
    "lng": -91.10122,
    "state_id": "LA"
  },
  {
    "zip": 70732,
    "lat": 30.61795,
    "lng": -91.60425,
    "state_id": "LA"
  },
  {
    "zip": 70733,
    "lat": 30.3043,
    "lng": -90.79607,
    "state_id": "LA"
  },
  {
    "zip": 70734,
    "lat": 30.2029,
    "lng": -91.00205,
    "state_id": "LA"
  },
  {
    "zip": 70736,
    "lat": 30.6323,
    "lng": -91.3383,
    "state_id": "LA"
  },
  {
    "zip": 70737,
    "lat": 30.22512,
    "lng": -90.92222,
    "state_id": "LA"
  },
  {
    "zip": 70739,
    "lat": 30.59843,
    "lng": -90.97049,
    "state_id": "LA"
  },
  {
    "zip": 70740,
    "lat": 30.33983,
    "lng": -91.43534,
    "state_id": "LA"
  },
  {
    "zip": 70743,
    "lat": 30.02741,
    "lng": -90.77436,
    "state_id": "LA"
  },
  {
    "zip": 70744,
    "lat": 30.55826,
    "lng": -90.67611,
    "state_id": "LA"
  },
  {
    "zip": 70747,
    "lat": 30.8746,
    "lng": -91.68121,
    "state_id": "LA"
  },
  {
    "zip": 70748,
    "lat": 30.81405,
    "lng": -91.20544,
    "state_id": "LA"
  },
  {
    "zip": 70749,
    "lat": 30.63868,
    "lng": -91.40254,
    "state_id": "LA"
  },
  {
    "zip": 70750,
    "lat": 30.50029,
    "lng": -91.7661,
    "state_id": "LA"
  },
  {
    "zip": 70752,
    "lat": 30.5822,
    "lng": -91.40757,
    "state_id": "LA"
  },
  {
    "zip": 70753,
    "lat": 30.94499,
    "lng": -91.72702,
    "state_id": "LA"
  },
  {
    "zip": 70754,
    "lat": 30.40395,
    "lng": -90.74289,
    "state_id": "LA"
  },
  {
    "zip": 70755,
    "lat": 30.59336,
    "lng": -91.53003,
    "state_id": "LA"
  },
  {
    "zip": 70756,
    "lat": 30.54682,
    "lng": -91.61414,
    "state_id": "LA"
  },
  {
    "zip": 70757,
    "lat": 30.48852,
    "lng": -91.51873,
    "state_id": "LA"
  },
  {
    "zip": 70759,
    "lat": 30.69753,
    "lng": -91.57759,
    "state_id": "LA"
  },
  {
    "zip": 70760,
    "lat": 30.70662,
    "lng": -91.45553,
    "state_id": "LA"
  },
  {
    "zip": 70761,
    "lat": 30.97613,
    "lng": -91.02382,
    "state_id": "LA"
  },
  {
    "zip": 70762,
    "lat": 30.57672,
    "lng": -91.46861,
    "state_id": "LA"
  },
  {
    "zip": 70763,
    "lat": 30.04385,
    "lng": -90.7416,
    "state_id": "LA"
  },
  {
    "zip": 70764,
    "lat": 30.22614,
    "lng": -91.2883,
    "state_id": "LA"
  },
  {
    "zip": 70767,
    "lat": 30.4741,
    "lng": -91.32295,
    "state_id": "LA"
  },
  {
    "zip": 70769,
    "lat": 30.30735,
    "lng": -90.94036,
    "state_id": "LA"
  },
  {
    "zip": 70770,
    "lat": 30.65023,
    "lng": -90.99732,
    "state_id": "LA"
  },
  {
    "zip": 70772,
    "lat": 30.43694,
    "lng": -91.46872,
    "state_id": "LA"
  },
  {
    "zip": 70773,
    "lat": 30.60876,
    "lng": -91.36302,
    "state_id": "LA"
  },
  {
    "zip": 70774,
    "lat": 30.20762,
    "lng": -90.77383,
    "state_id": "LA"
  },
  {
    "zip": 70775,
    "lat": 30.86017,
    "lng": -91.36861,
    "state_id": "LA"
  },
  {
    "zip": 70776,
    "lat": 30.26356,
    "lng": -91.08986,
    "state_id": "LA"
  },
  {
    "zip": 70777,
    "lat": 30.73828,
    "lng": -91.07011,
    "state_id": "LA"
  },
  {
    "zip": 70778,
    "lat": 30.15328,
    "lng": -90.86062,
    "state_id": "LA"
  },
  {
    "zip": 70780,
    "lat": 30.29539,
    "lng": -91.1698,
    "state_id": "LA"
  },
  {
    "zip": 70782,
    "lat": 30.94374,
    "lng": -91.51858,
    "state_id": "LA"
  },
  {
    "zip": 70783,
    "lat": 30.68347,
    "lng": -91.40435,
    "state_id": "LA"
  },
  {
    "zip": 70785,
    "lat": 30.55892,
    "lng": -90.81818,
    "state_id": "LA"
  },
  {
    "zip": 70787,
    "lat": 30.96309,
    "lng": -91.44319,
    "state_id": "LA"
  },
  {
    "zip": 70788,
    "lat": 30.13632,
    "lng": -91.17351,
    "state_id": "LA"
  },
  {
    "zip": 70789,
    "lat": 30.93177,
    "lng": -91.08262,
    "state_id": "LA"
  },
  {
    "zip": 70791,
    "lat": 30.65217,
    "lng": -91.15564,
    "state_id": "LA"
  },
  {
    "zip": 70801,
    "lat": 30.44965,
    "lng": -91.18597,
    "state_id": "LA"
  },
  {
    "zip": 70802,
    "lat": 30.44383,
    "lng": -91.17753,
    "state_id": "LA"
  },
  {
    "zip": 70803,
    "lat": 30.41429,
    "lng": -91.17756,
    "state_id": "LA"
  },
  {
    "zip": 70805,
    "lat": 30.48853,
    "lng": -91.15936,
    "state_id": "LA"
  },
  {
    "zip": 70806,
    "lat": 30.44881,
    "lng": -91.12471,
    "state_id": "LA"
  },
  {
    "zip": 70807,
    "lat": 30.543,
    "lng": -91.21105,
    "state_id": "LA"
  },
  {
    "zip": 70808,
    "lat": 30.40564,
    "lng": -91.14254,
    "state_id": "LA"
  },
  {
    "zip": 70809,
    "lat": 30.39381,
    "lng": -91.07114,
    "state_id": "LA"
  },
  {
    "zip": 70810,
    "lat": 30.3454,
    "lng": -91.08307,
    "state_id": "LA"
  },
  {
    "zip": 70811,
    "lat": 30.53195,
    "lng": -91.11677,
    "state_id": "LA"
  },
  {
    "zip": 70812,
    "lat": 30.50073,
    "lng": -91.11044,
    "state_id": "LA"
  },
  {
    "zip": 70814,
    "lat": 30.48518,
    "lng": -91.068,
    "state_id": "LA"
  },
  {
    "zip": 70815,
    "lat": 30.45546,
    "lng": -91.06629,
    "state_id": "LA"
  },
  {
    "zip": 70816,
    "lat": 30.42897,
    "lng": -91.0214,
    "state_id": "LA"
  },
  {
    "zip": 70817,
    "lat": 30.37601,
    "lng": -90.9809,
    "state_id": "LA"
  },
  {
    "zip": 70818,
    "lat": 30.5425,
    "lng": -91.04985,
    "state_id": "LA"
  },
  {
    "zip": 70819,
    "lat": 30.47395,
    "lng": -91.01095,
    "state_id": "LA"
  },
  {
    "zip": 70820,
    "lat": 30.36901,
    "lng": -91.18539,
    "state_id": "LA"
  },
  {
    "zip": 70836,
    "lat": 30.39079,
    "lng": -91.09071,
    "state_id": "LA"
  },
  {
    "zip": 71001,
    "lat": 32.59461,
    "lng": -92.89717,
    "state_id": "LA"
  },
  {
    "zip": 71003,
    "lat": 32.62862,
    "lng": -93.02968,
    "state_id": "LA"
  },
  {
    "zip": 71004,
    "lat": 32.74841,
    "lng": -93.86309,
    "state_id": "LA"
  },
  {
    "zip": 71006,
    "lat": 32.72761,
    "lng": -93.63354,
    "state_id": "LA"
  },
  {
    "zip": 71007,
    "lat": 32.36916,
    "lng": -94.00662,
    "state_id": "LA"
  },
  {
    "zip": 71008,
    "lat": 32.34041,
    "lng": -92.96684,
    "state_id": "LA"
  },
  {
    "zip": 71016,
    "lat": 32.23252,
    "lng": -93.10826,
    "state_id": "LA"
  },
  {
    "zip": 71018,
    "lat": 32.82253,
    "lng": -93.42626,
    "state_id": "LA"
  },
  {
    "zip": 71019,
    "lat": 32.0481,
    "lng": -93.31013,
    "state_id": "LA"
  },
  {
    "zip": 71021,
    "lat": 32.96714,
    "lng": -93.44614,
    "state_id": "LA"
  },
  {
    "zip": 71023,
    "lat": 32.47804,
    "lng": -93.40681,
    "state_id": "LA"
  },
  {
    "zip": 71024,
    "lat": 32.48751,
    "lng": -93.2046,
    "state_id": "LA"
  },
  {
    "zip": 71027,
    "lat": 32.24518,
    "lng": -93.67306,
    "state_id": "LA"
  },
  {
    "zip": 71028,
    "lat": 32.48954,
    "lng": -93.07607,
    "state_id": "LA"
  },
  {
    "zip": 71029,
    "lat": 32.83985,
    "lng": -93.83538,
    "state_id": "LA"
  },
  {
    "zip": 71030,
    "lat": 32.19298,
    "lng": -93.79082,
    "state_id": "LA"
  },
  {
    "zip": 71031,
    "lat": 32.00822,
    "lng": -92.90586,
    "state_id": "LA"
  },
  {
    "zip": 71032,
    "lat": 32.09478,
    "lng": -93.79297,
    "state_id": "LA"
  },
  {
    "zip": 71033,
    "lat": 32.43693,
    "lng": -94.00276,
    "state_id": "LA"
  },
  {
    "zip": 71034,
    "lat": 32.18368,
    "lng": -93.30692,
    "state_id": "LA"
  },
  {
    "zip": 71037,
    "lat": 32.56962,
    "lng": -93.52321,
    "state_id": "LA"
  },
  {
    "zip": 71038,
    "lat": 32.94411,
    "lng": -93.06443,
    "state_id": "LA"
  },
  {
    "zip": 71039,
    "lat": 32.4269,
    "lng": -93.29391,
    "state_id": "LA"
  },
  {
    "zip": 71040,
    "lat": 32.7826,
    "lng": -93.01974,
    "state_id": "LA"
  },
  {
    "zip": 71043,
    "lat": 32.88588,
    "lng": -93.88477,
    "state_id": "LA"
  },
  {
    "zip": 71044,
    "lat": 32.968,
    "lng": -93.88232,
    "state_id": "LA"
  },
  {
    "zip": 71045,
    "lat": 32.35247,
    "lng": -93.1826,
    "state_id": "LA"
  },
  {
    "zip": 71046,
    "lat": 32.16403,
    "lng": -93.95024,
    "state_id": "LA"
  },
  {
    "zip": 71047,
    "lat": 32.29784,
    "lng": -93.91816,
    "state_id": "LA"
  },
  {
    "zip": 71048,
    "lat": 32.83853,
    "lng": -92.8456,
    "state_id": "LA"
  },
  {
    "zip": 71049,
    "lat": 32.01289,
    "lng": -93.96557,
    "state_id": "LA"
  },
  {
    "zip": 71051,
    "lat": 32.34358,
    "lng": -93.49114,
    "state_id": "LA"
  },
  {
    "zip": 71052,
    "lat": 32.00943,
    "lng": -93.66509,
    "state_id": "LA"
  },
  {
    "zip": 71055,
    "lat": 32.6725,
    "lng": -93.29812,
    "state_id": "LA"
  },
  {
    "zip": 71060,
    "lat": 32.64682,
    "lng": -93.98422,
    "state_id": "LA"
  },
  {
    "zip": 71061,
    "lat": 32.76705,
    "lng": -93.96639,
    "state_id": "LA"
  },
  {
    "zip": 71063,
    "lat": 31.90905,
    "lng": -93.51129,
    "state_id": "LA"
  },
  {
    "zip": 71064,
    "lat": 32.91077,
    "lng": -93.68579,
    "state_id": "LA"
  },
  {
    "zip": 71065,
    "lat": 31.80766,
    "lng": -93.50564,
    "state_id": "LA"
  },
  {
    "zip": 71067,
    "lat": 32.5968,
    "lng": -93.49348,
    "state_id": "LA"
  },
  {
    "zip": 71068,
    "lat": 32.28367,
    "lng": -93.32183,
    "state_id": "LA"
  },
  {
    "zip": 71069,
    "lat": 32.9662,
    "lng": -93.98748,
    "state_id": "LA"
  },
  {
    "zip": 71070,
    "lat": 32.10241,
    "lng": -93.01329,
    "state_id": "LA"
  },
  {
    "zip": 71071,
    "lat": 32.92383,
    "lng": -93.45566,
    "state_id": "LA"
  },
  {
    "zip": 71072,
    "lat": 32.93729,
    "lng": -93.31261,
    "state_id": "LA"
  },
  {
    "zip": 71073,
    "lat": 32.52345,
    "lng": -93.30696,
    "state_id": "LA"
  },
  {
    "zip": 71075,
    "lat": 32.99336,
    "lng": -93.49922,
    "state_id": "LA"
  },
  {
    "zip": 71078,
    "lat": 32.27196,
    "lng": -93.79337,
    "state_id": "LA"
  },
  {
    "zip": 71079,
    "lat": 32.9391,
    "lng": -92.81477,
    "state_id": "LA"
  },
  {
    "zip": 71082,
    "lat": 32.8365,
    "lng": -93.9802,
    "state_id": "LA"
  },
  {
    "zip": 71101,
    "lat": 32.50609,
    "lng": -93.74683,
    "state_id": "LA"
  },
  {
    "zip": 71103,
    "lat": 32.49194,
    "lng": -93.7721,
    "state_id": "LA"
  },
  {
    "zip": 71104,
    "lat": 32.48494,
    "lng": -93.73252,
    "state_id": "LA"
  },
  {
    "zip": 71105,
    "lat": 32.45673,
    "lng": -93.70965,
    "state_id": "LA"
  },
  {
    "zip": 71106,
    "lat": 32.38572,
    "lng": -93.73634,
    "state_id": "LA"
  },
  {
    "zip": 71107,
    "lat": 32.59665,
    "lng": -93.85878,
    "state_id": "LA"
  },
  {
    "zip": 71108,
    "lat": 32.4426,
    "lng": -93.78848,
    "state_id": "LA"
  },
  {
    "zip": 71109,
    "lat": 32.46874,
    "lng": -93.81377,
    "state_id": "LA"
  },
  {
    "zip": 71110,
    "lat": 32.50008,
    "lng": -93.59976,
    "state_id": "LA"
  },
  {
    "zip": 71111,
    "lat": 32.58156,
    "lng": -93.70305,
    "state_id": "LA"
  },
  {
    "zip": 71112,
    "lat": 32.44249,
    "lng": -93.63612,
    "state_id": "LA"
  },
  {
    "zip": 71115,
    "lat": 32.26845,
    "lng": -93.56896,
    "state_id": "LA"
  },
  {
    "zip": 71118,
    "lat": 32.39266,
    "lng": -93.80531,
    "state_id": "LA"
  },
  {
    "zip": 71119,
    "lat": 32.48449,
    "lng": -93.92987,
    "state_id": "LA"
  },
  {
    "zip": 71129,
    "lat": 32.38799,
    "lng": -93.92385,
    "state_id": "LA"
  },
  {
    "zip": 71201,
    "lat": 32.53298,
    "lng": -92.10528,
    "state_id": "LA"
  },
  {
    "zip": 71202,
    "lat": 32.3959,
    "lng": -92.05625,
    "state_id": "LA"
  },
  {
    "zip": 71203,
    "lat": 32.5828,
    "lng": -92.01769,
    "state_id": "LA"
  },
  {
    "zip": 71209,
    "lat": 32.52915,
    "lng": -92.07003,
    "state_id": "LA"
  },
  {
    "zip": 71219,
    "lat": 32.323,
    "lng": -91.6843,
    "state_id": "LA"
  },
  {
    "zip": 71220,
    "lat": 32.86485,
    "lng": -91.9114,
    "state_id": "LA"
  },
  {
    "zip": 71222,
    "lat": 32.82676,
    "lng": -92.65791,
    "state_id": "LA"
  },
  {
    "zip": 71223,
    "lat": 32.89241,
    "lng": -91.66024,
    "state_id": "LA"
  },
  {
    "zip": 71225,
    "lat": 32.5104,
    "lng": -92.34419,
    "state_id": "LA"
  },
  {
    "zip": 71226,
    "lat": 32.25266,
    "lng": -92.44554,
    "state_id": "LA"
  },
  {
    "zip": 71227,
    "lat": 32.53465,
    "lng": -92.48035,
    "state_id": "LA"
  },
  {
    "zip": 71229,
    "lat": 32.63897,
    "lng": -91.89321,
    "state_id": "LA"
  },
  {
    "zip": 71232,
    "lat": 32.3952,
    "lng": -91.47354,
    "state_id": "LA"
  },
  {
    "zip": 71233,
    "lat": 32.32515,
    "lng": -90.93206,
    "state_id": "LA"
  },
  {
    "zip": 71234,
    "lat": 32.64415,
    "lng": -92.34166,
    "state_id": "LA"
  },
  {
    "zip": 71235,
    "lat": 32.69053,
    "lng": -92.67428,
    "state_id": "LA"
  },
  {
    "zip": 71237,
    "lat": 32.59249,
    "lng": -91.49189,
    "state_id": "LA"
  },
  {
    "zip": 71238,
    "lat": 32.36776,
    "lng": -92.37881,
    "state_id": "LA"
  },
  {
    "zip": 71241,
    "lat": 32.77182,
    "lng": -92.36289,
    "state_id": "LA"
  },
  {
    "zip": 71243,
    "lat": 31.95726,
    "lng": -91.81105,
    "state_id": "LA"
  },
  {
    "zip": 71245,
    "lat": 32.5297,
    "lng": -92.72192,
    "state_id": "LA"
  },
  {
    "zip": 71247,
    "lat": 32.27241,
    "lng": -92.72184,
    "state_id": "LA"
  },
  {
    "zip": 71250,
    "lat": 32.95894,
    "lng": -91.5762,
    "state_id": "LA"
  },
  {
    "zip": 71251,
    "lat": 32.21619,
    "lng": -92.67173,
    "state_id": "LA"
  },
  {
    "zip": 71253,
    "lat": 32.99119,
    "lng": -91.30936,
    "state_id": "LA"
  },
  {
    "zip": 71254,
    "lat": 32.79696,
    "lng": -91.25569,
    "state_id": "LA"
  },
  {
    "zip": 71256,
    "lat": 32.95098,
    "lng": -92.72279,
    "state_id": "LA"
  },
  {
    "zip": 71259,
    "lat": 32.27255,
    "lng": -91.84626,
    "state_id": "LA"
  },
  {
    "zip": 71260,
    "lat": 32.90786,
    "lng": -92.23879,
    "state_id": "LA"
  },
  {
    "zip": 71261,
    "lat": 32.77214,
    "lng": -91.69884,
    "state_id": "LA"
  },
  {
    "zip": 71263,
    "lat": 32.8689,
    "lng": -91.42567,
    "state_id": "LA"
  },
  {
    "zip": 71264,
    "lat": 32.60214,
    "lng": -91.77469,
    "state_id": "LA"
  },
  {
    "zip": 71266,
    "lat": 32.69816,
    "lng": -91.49624,
    "state_id": "LA"
  },
  {
    "zip": 71268,
    "lat": 32.34702,
    "lng": -92.74102,
    "state_id": "LA"
  },
  {
    "zip": 71269,
    "lat": 32.44574,
    "lng": -91.7869,
    "state_id": "LA"
  },
  {
    "zip": 71270,
    "lat": 32.49458,
    "lng": -92.63932,
    "state_id": "LA"
  },
  {
    "zip": 71272,
    "lat": 32.5269,
    "lng": -92.64931,
    "state_id": "LA"
  },
  {
    "zip": 71275,
    "lat": 32.5134,
    "lng": -92.82085,
    "state_id": "LA"
  },
  {
    "zip": 71276,
    "lat": 32.57571,
    "lng": -91.14931,
    "state_id": "LA"
  },
  {
    "zip": 71277,
    "lat": 32.94939,
    "lng": -92.55717,
    "state_id": "LA"
  },
  {
    "zip": 71279,
    "lat": 32.48731,
    "lng": -91.85714,
    "state_id": "LA"
  },
  {
    "zip": 71280,
    "lat": 32.72605,
    "lng": -92.09706,
    "state_id": "LA"
  },
  {
    "zip": 71282,
    "lat": 32.36504,
    "lng": -91.1811,
    "state_id": "LA"
  },
  {
    "zip": 71286,
    "lat": 32.66443,
    "lng": -91.19645,
    "state_id": "LA"
  },
  {
    "zip": 71291,
    "lat": 32.57001,
    "lng": -92.182,
    "state_id": "LA"
  },
  {
    "zip": 71292,
    "lat": 32.40098,
    "lng": -92.2143,
    "state_id": "LA"
  },
  {
    "zip": 71295,
    "lat": 32.14743,
    "lng": -91.70683,
    "state_id": "LA"
  },
  {
    "zip": 71301,
    "lat": 31.27424,
    "lng": -92.46876,
    "state_id": "LA"
  },
  {
    "zip": 71302,
    "lat": 31.20429,
    "lng": -92.3775,
    "state_id": "LA"
  },
  {
    "zip": 71303,
    "lat": 31.27807,
    "lng": -92.54371,
    "state_id": "LA"
  },
  {
    "zip": 71316,
    "lat": 31.26253,
    "lng": -91.75986,
    "state_id": "LA"
  },
  {
    "zip": 71322,
    "lat": 30.86405,
    "lng": -92.14654,
    "state_id": "LA"
  },
  {
    "zip": 71323,
    "lat": 31.26501,
    "lng": -92.20835,
    "state_id": "LA"
  },
  {
    "zip": 71325,
    "lat": 31.00135,
    "lng": -92.32192,
    "state_id": "LA"
  },
  {
    "zip": 71326,
    "lat": 31.77049,
    "lng": -91.60947,
    "state_id": "LA"
  },
  {
    "zip": 71327,
    "lat": 30.98262,
    "lng": -92.03351,
    "state_id": "LA"
  },
  {
    "zip": 71328,
    "lat": 31.36086,
    "lng": -92.17548,
    "state_id": "LA"
  },
  {
    "zip": 71331,
    "lat": 31.26464,
    "lng": -92.0817,
    "state_id": "LA"
  },
  {
    "zip": 71333,
    "lat": 30.906,
    "lng": -92.07384,
    "state_id": "LA"
  },
  {
    "zip": 71334,
    "lat": 31.65639,
    "lng": -91.56305,
    "state_id": "LA"
  },
  {
    "zip": 71336,
    "lat": 32.02112,
    "lng": -91.59335,
    "state_id": "LA"
  },
  {
    "zip": 71339,
    "lat": 31.01384,
    "lng": -91.93234,
    "state_id": "LA"
  },
  {
    "zip": 71340,
    "lat": 31.76918,
    "lng": -91.81069,
    "state_id": "LA"
  },
  {
    "zip": 71341,
    "lat": 31.06164,
    "lng": -92.16497,
    "state_id": "LA"
  },
  {
    "zip": 71342,
    "lat": 31.60484,
    "lng": -92.13805,
    "state_id": "LA"
  },
  {
    "zip": 71343,
    "lat": 31.51699,
    "lng": -91.90443,
    "state_id": "LA"
  },
  {
    "zip": 71345,
    "lat": 30.72926,
    "lng": -91.97539,
    "state_id": "LA"
  },
  {
    "zip": 71346,
    "lat": 31.11308,
    "lng": -92.37618,
    "state_id": "LA"
  },
  {
    "zip": 71350,
    "lat": 31.06814,
    "lng": -92.06899,
    "state_id": "LA"
  },
  {
    "zip": 71351,
    "lat": 31.17738,
    "lng": -91.96283,
    "state_id": "LA"
  },
  {
    "zip": 71353,
    "lat": 30.75036,
    "lng": -91.81854,
    "state_id": "LA"
  },
  {
    "zip": 71354,
    "lat": 31.40667,
    "lng": -91.76946,
    "state_id": "LA"
  },
  {
    "zip": 71355,
    "lat": 31.07853,
    "lng": -91.85006,
    "state_id": "LA"
  },
  {
    "zip": 71356,
    "lat": 30.82064,
    "lng": -92.04833,
    "state_id": "LA"
  },
  {
    "zip": 71357,
    "lat": 32.11106,
    "lng": -91.29853,
    "state_id": "LA"
  },
  {
    "zip": 71358,
    "lat": 30.69306,
    "lng": -91.86611,
    "state_id": "LA"
  },
  {
    "zip": 71360,
    "lat": 31.32037,
    "lng": -92.3529,
    "state_id": "LA"
  },
  {
    "zip": 71362,
    "lat": 30.89643,
    "lng": -91.96621,
    "state_id": "LA"
  },
  {
    "zip": 71366,
    "lat": 31.9396,
    "lng": -91.33846,
    "state_id": "LA"
  },
  {
    "zip": 71367,
    "lat": 30.88321,
    "lng": -92.29376,
    "state_id": "LA"
  },
  {
    "zip": 71368,
    "lat": 31.86464,
    "lng": -91.68802,
    "state_id": "LA"
  },
  {
    "zip": 71369,
    "lat": 30.92272,
    "lng": -91.86756,
    "state_id": "LA"
  },
  {
    "zip": 71371,
    "lat": 31.67912,
    "lng": -92.25657,
    "state_id": "LA"
  },
  {
    "zip": 71373,
    "lat": 31.30969,
    "lng": -91.60794,
    "state_id": "LA"
  },
  {
    "zip": 71375,
    "lat": 31.84917,
    "lng": -91.47557,
    "state_id": "LA"
  },
  {
    "zip": 71377,
    "lat": 31.61093,
    "lng": -91.78648,
    "state_id": "LA"
  },
  {
    "zip": 71378,
    "lat": 31.95539,
    "lng": -91.70933,
    "state_id": "LA"
  },
  {
    "zip": 71401,
    "lat": 31.79748,
    "lng": -91.94596,
    "state_id": "LA"
  },
  {
    "zip": 71403,
    "lat": 31.21481,
    "lng": -93.43269,
    "state_id": "LA"
  },
  {
    "zip": 71404,
    "lat": 31.74824,
    "lng": -92.74907,
    "state_id": "LA"
  },
  {
    "zip": 71405,
    "lat": 31.40946,
    "lng": -92.40096,
    "state_id": "LA"
  },
  {
    "zip": 71406,
    "lat": 31.73313,
    "lng": -93.50458,
    "state_id": "LA"
  },
  {
    "zip": 71407,
    "lat": 31.51593,
    "lng": -92.48601,
    "state_id": "LA"
  },
  {
    "zip": 71409,
    "lat": 31.31964,
    "lng": -92.70738,
    "state_id": "LA"
  },
  {
    "zip": 71410,
    "lat": 31.9648,
    "lng": -92.77153,
    "state_id": "LA"
  },
  {
    "zip": 71411,
    "lat": 31.9049,
    "lng": -93.08994,
    "state_id": "LA"
  },
  {
    "zip": 71414,
    "lat": 31.82637,
    "lng": -93.02461,
    "state_id": "LA"
  },
  {
    "zip": 71416,
    "lat": 31.53792,
    "lng": -92.9122,
    "state_id": "LA"
  },
  {
    "zip": 71417,
    "lat": 31.51533,
    "lng": -92.65407,
    "state_id": "LA"
  },
  {
    "zip": 71418,
    "lat": 32.143,
    "lng": -92.05545,
    "state_id": "LA"
  },
  {
    "zip": 71419,
    "lat": 31.80197,
    "lng": -93.7094,
    "state_id": "LA"
  },
  {
    "zip": 71422,
    "lat": 32.07824,
    "lng": -92.66397,
    "state_id": "LA"
  },
  {
    "zip": 71423,
    "lat": 31.60806,
    "lng": -92.56735,
    "state_id": "LA"
  },
  {
    "zip": 71424,
    "lat": 31.18522,
    "lng": -92.67216,
    "state_id": "LA"
  },
  {
    "zip": 71425,
    "lat": 31.88619,
    "lng": -91.89889,
    "state_id": "LA"
  },
  {
    "zip": 71426,
    "lat": 31.4989,
    "lng": -93.45992,
    "state_id": "LA"
  },
  {
    "zip": 71427,
    "lat": 31.39595,
    "lng": -92.89252,
    "state_id": "LA"
  },
  {
    "zip": 71429,
    "lat": 31.40627,
    "lng": -93.42716,
    "state_id": "LA"
  },
  {
    "zip": 71430,
    "lat": 31.04033,
    "lng": -92.50058,
    "state_id": "LA"
  },
  {
    "zip": 71432,
    "lat": 31.7512,
    "lng": -92.47125,
    "state_id": "LA"
  },
  {
    "zip": 71433,
    "lat": 31.00167,
    "lng": -92.64666,
    "state_id": "LA"
  },
  {
    "zip": 71435,
    "lat": 32.03092,
    "lng": -92.16365,
    "state_id": "LA"
  },
  {
    "zip": 71438,
    "lat": 31.11376,
    "lng": -92.82478,
    "state_id": "LA"
  },
  {
    "zip": 71439,
    "lat": 31.33692,
    "lng": -93.39633,
    "state_id": "LA"
  },
  {
    "zip": 71441,
    "lat": 31.944,
    "lng": -92.14073,
    "state_id": "LA"
  },
  {
    "zip": 71446,
    "lat": 31.16724,
    "lng": -93.18769,
    "state_id": "LA"
  },
  {
    "zip": 71447,
    "lat": 31.43689,
    "lng": -92.81209,
    "state_id": "LA"
  },
  {
    "zip": 71449,
    "lat": 31.54282,
    "lng": -93.51993,
    "state_id": "LA"
  },
  {
    "zip": 71450,
    "lat": 31.77816,
    "lng": -93.41056,
    "state_id": "LA"
  },
  {
    "zip": 71452,
    "lat": 31.57872,
    "lng": -92.9418,
    "state_id": "LA"
  },
  {
    "zip": 71454,
    "lat": 31.69265,
    "lng": -92.86119,
    "state_id": "LA"
  },
  {
    "zip": 71455,
    "lat": 31.40504,
    "lng": -92.9794,
    "state_id": "LA"
  },
  {
    "zip": 71456,
    "lat": 31.63656,
    "lng": -92.9767,
    "state_id": "LA"
  },
  {
    "zip": 71457,
    "lat": 31.74636,
    "lng": -93.09298,
    "state_id": "LA"
  },
  {
    "zip": 71459,
    "lat": 31.07666,
    "lng": -93.21793,
    "state_id": "LA"
  },
  {
    "zip": 71461,
    "lat": 31.11839,
    "lng": -93.29026,
    "state_id": "LA"
  },
  {
    "zip": 71462,
    "lat": 31.66308,
    "lng": -93.71395,
    "state_id": "LA"
  },
  {
    "zip": 71463,
    "lat": 30.80779,
    "lng": -92.61253,
    "state_id": "LA"
  },
  {
    "zip": 71465,
    "lat": 31.86776,
    "lng": -92.1882,
    "state_id": "LA"
  },
  {
    "zip": 71466,
    "lat": 31.21518,
    "lng": -92.74864,
    "state_id": "LA"
  },
  {
    "zip": 71467,
    "lat": 31.5579,
    "lng": -92.39543,
    "state_id": "LA"
  },
  {
    "zip": 71468,
    "lat": 31.48116,
    "lng": -93.14764,
    "state_id": "LA"
  },
  {
    "zip": 71469,
    "lat": 31.69029,
    "lng": -93.26634,
    "state_id": "LA"
  },
  {
    "zip": 71472,
    "lat": 31.19834,
    "lng": -92.79372,
    "state_id": "LA"
  },
  {
    "zip": 71473,
    "lat": 32.06641,
    "lng": -92.43378,
    "state_id": "LA"
  },
  {
    "zip": 71474,
    "lat": 31.27408,
    "lng": -93.01389,
    "state_id": "LA"
  },
  {
    "zip": 71479,
    "lat": 31.85466,
    "lng": -92.36356,
    "state_id": "LA"
  },
  {
    "zip": 71480,
    "lat": 31.86298,
    "lng": -92.29177,
    "state_id": "LA"
  },
  {
    "zip": 71483,
    "lat": 31.89381,
    "lng": -92.66129,
    "state_id": "LA"
  },
  {
    "zip": 71485,
    "lat": 31.15498,
    "lng": -92.54526,
    "state_id": "LA"
  },
  {
    "zip": 71486,
    "lat": 31.64932,
    "lng": -93.61818,
    "state_id": "LA"
  },
  {
    "zip": 1001,
    "lat": 42.06259,
    "lng": -72.62589,
    "state_id": "MA"
  },
  {
    "zip": 1002,
    "lat": 42.37492,
    "lng": -72.4621,
    "state_id": "MA"
  },
  {
    "zip": 1003,
    "lat": 42.39192,
    "lng": -72.52479,
    "state_id": "MA"
  },
  {
    "zip": 1005,
    "lat": 42.42017,
    "lng": -72.10615,
    "state_id": "MA"
  },
  {
    "zip": 1007,
    "lat": 42.27875,
    "lng": -72.40036,
    "state_id": "MA"
  },
  {
    "zip": 1008,
    "lat": 42.18428,
    "lng": -72.95203,
    "state_id": "MA"
  },
  {
    "zip": 1009,
    "lat": 42.20972,
    "lng": -72.33971,
    "state_id": "MA"
  },
  {
    "zip": 1010,
    "lat": 42.12863,
    "lng": -72.20624,
    "state_id": "MA"
  },
  {
    "zip": 1011,
    "lat": 42.29652,
    "lng": -72.95968,
    "state_id": "MA"
  },
  {
    "zip": 1012,
    "lat": 42.38699,
    "lng": -72.84634,
    "state_id": "MA"
  },
  {
    "zip": 1013,
    "lat": 42.1595,
    "lng": -72.60825,
    "state_id": "MA"
  },
  {
    "zip": 1020,
    "lat": 42.17637,
    "lng": -72.56538,
    "state_id": "MA"
  },
  {
    "zip": 1022,
    "lat": 42.19731,
    "lng": -72.54267,
    "state_id": "MA"
  },
  {
    "zip": 1026,
    "lat": 42.4633,
    "lng": -72.92024,
    "state_id": "MA"
  },
  {
    "zip": 1027,
    "lat": 42.29508,
    "lng": -72.74309,
    "state_id": "MA"
  },
  {
    "zip": 1028,
    "lat": 42.05968,
    "lng": -72.49908,
    "state_id": "MA"
  },
  {
    "zip": 1029,
    "lat": 42.19632,
    "lng": -73.04836,
    "state_id": "MA"
  },
  {
    "zip": 1030,
    "lat": 42.06886,
    "lng": -72.67998,
    "state_id": "MA"
  },
  {
    "zip": 1031,
    "lat": 42.32934,
    "lng": -72.19822,
    "state_id": "MA"
  },
  {
    "zip": 1032,
    "lat": 42.45031,
    "lng": -72.81299,
    "state_id": "MA"
  },
  {
    "zip": 1033,
    "lat": 42.26081,
    "lng": -72.50363,
    "state_id": "MA"
  },
  {
    "zip": 1034,
    "lat": 42.08485,
    "lng": -72.95765,
    "state_id": "MA"
  },
  {
    "zip": 1035,
    "lat": 42.35564,
    "lng": -72.56921,
    "state_id": "MA"
  },
  {
    "zip": 1036,
    "lat": 42.06369,
    "lng": -72.4159,
    "state_id": "MA"
  },
  {
    "zip": 1037,
    "lat": 42.37011,
    "lng": -72.19771,
    "state_id": "MA"
  },
  {
    "zip": 1038,
    "lat": 42.38548,
    "lng": -72.60701,
    "state_id": "MA"
  },
  {
    "zip": 1039,
    "lat": 42.40629,
    "lng": -72.69363,
    "state_id": "MA"
  },
  {
    "zip": 1040,
    "lat": 42.21256,
    "lng": -72.64107,
    "state_id": "MA"
  },
  {
    "zip": 1050,
    "lat": 42.28784,
    "lng": -72.86986,
    "state_id": "MA"
  },
  {
    "zip": 1053,
    "lat": 42.35347,
    "lng": -72.71162,
    "state_id": "MA"
  },
  {
    "zip": 1054,
    "lat": 42.47363,
    "lng": -72.48746,
    "state_id": "MA"
  },
  {
    "zip": 1056,
    "lat": 42.19211,
    "lng": -72.45804,
    "state_id": "MA"
  },
  {
    "zip": 1057,
    "lat": 42.09164,
    "lng": -72.32432,
    "state_id": "MA"
  },
  {
    "zip": 1060,
    "lat": 42.32229,
    "lng": -72.63128,
    "state_id": "MA"
  },
  {
    "zip": 1062,
    "lat": 42.32188,
    "lng": -72.69278,
    "state_id": "MA"
  },
  {
    "zip": 1063,
    "lat": 42.31901,
    "lng": -72.63905,
    "state_id": "MA"
  },
  {
    "zip": 1066,
    "lat": 42.40763,
    "lng": -72.6548,
    "state_id": "MA"
  },
  {
    "zip": 1068,
    "lat": 42.35002,
    "lng": -72.04606,
    "state_id": "MA"
  },
  {
    "zip": 1069,
    "lat": 42.18807,
    "lng": -72.30555,
    "state_id": "MA"
  },
  {
    "zip": 1070,
    "lat": 42.51853,
    "lng": -72.92391,
    "state_id": "MA"
  },
  {
    "zip": 1071,
    "lat": 42.16035,
    "lng": -72.86,
    "state_id": "MA"
  },
  {
    "zip": 1072,
    "lat": 42.45737,
    "lng": -72.41841,
    "state_id": "MA"
  },
  {
    "zip": 1073,
    "lat": 42.23083,
    "lng": -72.73857,
    "state_id": "MA"
  },
  {
    "zip": 1074,
    "lat": 42.38672,
    "lng": -72.09404,
    "state_id": "MA"
  },
  {
    "zip": 1075,
    "lat": 42.25674,
    "lng": -72.57923,
    "state_id": "MA"
  },
  {
    "zip": 1077,
    "lat": 42.05437,
    "lng": -72.77851,
    "state_id": "MA"
  },
  {
    "zip": 1079,
    "lat": 42.19437,
    "lng": -72.33014,
    "state_id": "MA"
  },
  {
    "zip": 1080,
    "lat": 42.18206,
    "lng": -72.36564,
    "state_id": "MA"
  },
  {
    "zip": 1081,
    "lat": 42.06234,
    "lng": -72.23095,
    "state_id": "MA"
  },
  {
    "zip": 1082,
    "lat": 42.2948,
    "lng": -72.27776,
    "state_id": "MA"
  },
  {
    "zip": 1083,
    "lat": 42.20382,
    "lng": -72.19495,
    "state_id": "MA"
  },
  {
    "zip": 1084,
    "lat": 42.39038,
    "lng": -72.88089,
    "state_id": "MA"
  },
  {
    "zip": 1085,
    "lat": 42.15371,
    "lng": -72.76958,
    "state_id": "MA"
  },
  {
    "zip": 1086,
    "lat": 42.13044,
    "lng": -72.79402,
    "state_id": "MA"
  },
  {
    "zip": 1088,
    "lat": 42.39012,
    "lng": -72.6478,
    "state_id": "MA"
  },
  {
    "zip": 1089,
    "lat": 42.12534,
    "lng": -72.65027,
    "state_id": "MA"
  },
  {
    "zip": 1092,
    "lat": 42.19972,
    "lng": -72.23727,
    "state_id": "MA"
  },
  {
    "zip": 1093,
    "lat": 42.43438,
    "lng": -72.65305,
    "state_id": "MA"
  },
  {
    "zip": 1094,
    "lat": 42.35945,
    "lng": -72.13847,
    "state_id": "MA"
  },
  {
    "zip": 1095,
    "lat": 42.12764,
    "lng": -72.42993,
    "state_id": "MA"
  },
  {
    "zip": 1096,
    "lat": 42.40418,
    "lng": -72.76819,
    "state_id": "MA"
  },
  {
    "zip": 1097,
    "lat": 42.17603,
    "lng": -72.8313,
    "state_id": "MA"
  },
  {
    "zip": 1098,
    "lat": 42.3946,
    "lng": -72.94242,
    "state_id": "MA"
  },
  {
    "zip": 1103,
    "lat": 42.104,
    "lng": -72.5921,
    "state_id": "MA"
  },
  {
    "zip": 1104,
    "lat": 42.13404,
    "lng": -72.56679,
    "state_id": "MA"
  },
  {
    "zip": 1105,
    "lat": 42.09917,
    "lng": -72.57899,
    "state_id": "MA"
  },
  {
    "zip": 1106,
    "lat": 42.04791,
    "lng": -72.57176,
    "state_id": "MA"
  },
  {
    "zip": 1107,
    "lat": 42.12129,
    "lng": -72.6055,
    "state_id": "MA"
  },
  {
    "zip": 1108,
    "lat": 42.08055,
    "lng": -72.55983,
    "state_id": "MA"
  },
  {
    "zip": 1109,
    "lat": 42.11837,
    "lng": -72.54875,
    "state_id": "MA"
  },
  {
    "zip": 1118,
    "lat": 42.09476,
    "lng": -72.52533,
    "state_id": "MA"
  },
  {
    "zip": 1119,
    "lat": 42.12455,
    "lng": -72.51122,
    "state_id": "MA"
  },
  {
    "zip": 1128,
    "lat": 42.09488,
    "lng": -72.48814,
    "state_id": "MA"
  },
  {
    "zip": 1129,
    "lat": 42.121,
    "lng": -72.48773,
    "state_id": "MA"
  },
  {
    "zip": 1151,
    "lat": 42.15295,
    "lng": -72.50881,
    "state_id": "MA"
  },
  {
    "zip": 1199,
    "lat": 42.12065,
    "lng": -72.6045,
    "state_id": "MA"
  },
  {
    "zip": 1201,
    "lat": 42.44993,
    "lng": -73.27644,
    "state_id": "MA"
  },
  {
    "zip": 1220,
    "lat": 42.62372,
    "lng": -73.11718,
    "state_id": "MA"
  },
  {
    "zip": 1222,
    "lat": 42.05899,
    "lng": -73.32081,
    "state_id": "MA"
  },
  {
    "zip": 1223,
    "lat": 42.31176,
    "lng": -73.0994,
    "state_id": "MA"
  },
  {
    "zip": 1224,
    "lat": 42.50661,
    "lng": -73.19746,
    "state_id": "MA"
  },
  {
    "zip": 1225,
    "lat": 42.56267,
    "lng": -73.15484,
    "state_id": "MA"
  },
  {
    "zip": 1226,
    "lat": 42.48168,
    "lng": -73.13992,
    "state_id": "MA"
  },
  {
    "zip": 1229,
    "lat": 42.27796,
    "lng": -73.33212,
    "state_id": "MA"
  },
  {
    "zip": 1230,
    "lat": 42.18475,
    "lng": -73.3437,
    "state_id": "MA"
  },
  {
    "zip": 1235,
    "lat": 42.42975,
    "lng": -73.07241,
    "state_id": "MA"
  },
  {
    "zip": 1236,
    "lat": 42.26658,
    "lng": -73.3767,
    "state_id": "MA"
  },
  {
    "zip": 1237,
    "lat": 42.54971,
    "lng": -73.26265,
    "state_id": "MA"
  },
  {
    "zip": 1238,
    "lat": 42.29522,
    "lng": -73.2288,
    "state_id": "MA"
  },
  {
    "zip": 1240,
    "lat": 42.37208,
    "lng": -73.27645,
    "state_id": "MA"
  },
  {
    "zip": 1242,
    "lat": 42.33328,
    "lng": -73.24921,
    "state_id": "MA"
  },
  {
    "zip": 1243,
    "lat": 42.35146,
    "lng": -73.02323,
    "state_id": "MA"
  },
  {
    "zip": 1244,
    "lat": 42.11608,
    "lng": -73.2627,
    "state_id": "MA"
  },
  {
    "zip": 1245,
    "lat": 42.18815,
    "lng": -73.2189,
    "state_id": "MA"
  },
  {
    "zip": 1247,
    "lat": 42.69547,
    "lng": -73.07997,
    "state_id": "MA"
  },
  {
    "zip": 1253,
    "lat": 42.21187,
    "lng": -73.11616,
    "state_id": "MA"
  },
  {
    "zip": 1254,
    "lat": 42.37982,
    "lng": -73.36545,
    "state_id": "MA"
  },
  {
    "zip": 1255,
    "lat": 42.10834,
    "lng": -73.1229,
    "state_id": "MA"
  },
  {
    "zip": 1256,
    "lat": 42.59628,
    "lng": -73.01957,
    "state_id": "MA"
  },
  {
    "zip": 1257,
    "lat": 42.09701,
    "lng": -73.36886,
    "state_id": "MA"
  },
  {
    "zip": 1258,
    "lat": 42.10257,
    "lng": -73.46176,
    "state_id": "MA"
  },
  {
    "zip": 1259,
    "lat": 42.07553,
    "lng": -73.23564,
    "state_id": "MA"
  },
  {
    "zip": 1260,
    "lat": 42.29587,
    "lng": -73.34517,
    "state_id": "MA"
  },
  {
    "zip": 1262,
    "lat": 42.29354,
    "lng": -73.3177,
    "state_id": "MA"
  },
  {
    "zip": 1264,
    "lat": 42.23411,
    "lng": -73.19903,
    "state_id": "MA"
  },
  {
    "zip": 1266,
    "lat": 42.308,
    "lng": -73.38408,
    "state_id": "MA"
  },
  {
    "zip": 1267,
    "lat": 42.67197,
    "lng": -73.23904,
    "state_id": "MA"
  },
  {
    "zip": 1270,
    "lat": 42.51492,
    "lng": -73.04125,
    "state_id": "MA"
  },
  {
    "zip": 1301,
    "lat": 42.62406,
    "lng": -72.60401,
    "state_id": "MA"
  },
  {
    "zip": 1330,
    "lat": 42.51377,
    "lng": -72.81395,
    "state_id": "MA"
  },
  {
    "zip": 1331,
    "lat": 42.56728,
    "lng": -72.18294,
    "state_id": "MA"
  },
  {
    "zip": 1337,
    "lat": 42.69503,
    "lng": -72.5771,
    "state_id": "MA"
  },
  {
    "zip": 1338,
    "lat": 42.57358,
    "lng": -72.82134,
    "state_id": "MA"
  },
  {
    "zip": 1339,
    "lat": 42.60801,
    "lng": -72.89006,
    "state_id": "MA"
  },
  {
    "zip": 1340,
    "lat": 42.6832,
    "lng": -72.71797,
    "state_id": "MA"
  },
  {
    "zip": 1341,
    "lat": 42.50447,
    "lng": -72.70514,
    "state_id": "MA"
  },
  {
    "zip": 1342,
    "lat": 42.54451,
    "lng": -72.60591,
    "state_id": "MA"
  },
  {
    "zip": 1343,
    "lat": 42.65444,
    "lng": -72.98109,
    "state_id": "MA"
  },
  {
    "zip": 1344,
    "lat": 42.60777,
    "lng": -72.42883,
    "state_id": "MA"
  },
  {
    "zip": 1346,
    "lat": 42.68892,
    "lng": -72.817,
    "state_id": "MA"
  },
  {
    "zip": 1347,
    "lat": 42.56137,
    "lng": -72.51694,
    "state_id": "MA"
  },
  {
    "zip": 1349,
    "lat": 42.57271,
    "lng": -72.48332,
    "state_id": "MA"
  },
  {
    "zip": 1350,
    "lat": 42.72265,
    "lng": -72.98245,
    "state_id": "MA"
  },
  {
    "zip": 1351,
    "lat": 42.54281,
    "lng": -72.51905,
    "state_id": "MA"
  },
  {
    "zip": 1354,
    "lat": 42.63366,
    "lng": -72.51108,
    "state_id": "MA"
  },
  {
    "zip": 1355,
    "lat": 42.45289,
    "lng": -72.33044,
    "state_id": "MA"
  },
  {
    "zip": 1360,
    "lat": 42.67575,
    "lng": -72.44594,
    "state_id": "MA"
  },
  {
    "zip": 1364,
    "lat": 42.61292,
    "lng": -72.29242,
    "state_id": "MA"
  },
  {
    "zip": 1366,
    "lat": 42.48054,
    "lng": -72.18772,
    "state_id": "MA"
  },
  {
    "zip": 1367,
    "lat": 42.6958,
    "lng": -72.91185,
    "state_id": "MA"
  },
  {
    "zip": 1368,
    "lat": 42.68082,
    "lng": -72.18602,
    "state_id": "MA"
  },
  {
    "zip": 1370,
    "lat": 42.59655,
    "lng": -72.72864,
    "state_id": "MA"
  },
  {
    "zip": 1373,
    "lat": 42.47292,
    "lng": -72.61561,
    "state_id": "MA"
  },
  {
    "zip": 1375,
    "lat": 42.4651,
    "lng": -72.55461,
    "state_id": "MA"
  },
  {
    "zip": 1376,
    "lat": 42.59532,
    "lng": -72.55577,
    "state_id": "MA"
  },
  {
    "zip": 1378,
    "lat": 42.6748,
    "lng": -72.34581,
    "state_id": "MA"
  },
  {
    "zip": 1379,
    "lat": 42.55286,
    "lng": -72.40253,
    "state_id": "MA"
  },
  {
    "zip": 1420,
    "lat": 42.58578,
    "lng": -71.81662,
    "state_id": "MA"
  },
  {
    "zip": 1430,
    "lat": 42.66019,
    "lng": -71.92369,
    "state_id": "MA"
  },
  {
    "zip": 1431,
    "lat": 42.67652,
    "lng": -71.82322,
    "state_id": "MA"
  },
  {
    "zip": 1432,
    "lat": 42.56469,
    "lng": -71.57135,
    "state_id": "MA"
  },
  {
    "zip": 1434,
    "lat": 42.53858,
    "lng": -71.6114,
    "state_id": "MA"
  },
  {
    "zip": 1436,
    "lat": 42.60546,
    "lng": -72.08846,
    "state_id": "MA"
  },
  {
    "zip": 1438,
    "lat": 42.5618,
    "lng": -72.0317,
    "state_id": "MA"
  },
  {
    "zip": 1440,
    "lat": 42.58737,
    "lng": -71.98649,
    "state_id": "MA"
  },
  {
    "zip": 1450,
    "lat": 42.61368,
    "lng": -71.56141,
    "state_id": "MA"
  },
  {
    "zip": 1451,
    "lat": 42.50124,
    "lng": -71.57378,
    "state_id": "MA"
  },
  {
    "zip": 1452,
    "lat": 42.48379,
    "lng": -72.00514,
    "state_id": "MA"
  },
  {
    "zip": 1453,
    "lat": 42.51864,
    "lng": -71.76138,
    "state_id": "MA"
  },
  {
    "zip": 1460,
    "lat": 42.53501,
    "lng": -71.4891,
    "state_id": "MA"
  },
  {
    "zip": 1462,
    "lat": 42.58968,
    "lng": -71.7199,
    "state_id": "MA"
  },
  {
    "zip": 1463,
    "lat": 42.67127,
    "lng": -71.60426,
    "state_id": "MA"
  },
  {
    "zip": 1464,
    "lat": 42.5733,
    "lng": -71.64836,
    "state_id": "MA"
  },
  {
    "zip": 1467,
    "lat": 42.48772,
    "lng": -71.61729,
    "state_id": "MA"
  },
  {
    "zip": 1468,
    "lat": 42.55207,
    "lng": -72.06894,
    "state_id": "MA"
  },
  {
    "zip": 1469,
    "lat": 42.66673,
    "lng": -71.69396,
    "state_id": "MA"
  },
  {
    "zip": 1473,
    "lat": 42.55122,
    "lng": -71.90266,
    "state_id": "MA"
  },
  {
    "zip": 1474,
    "lat": 42.66784,
    "lng": -71.75221,
    "state_id": "MA"
  },
  {
    "zip": 1475,
    "lat": 42.66667,
    "lng": -72.04899,
    "state_id": "MA"
  },
  {
    "zip": 1501,
    "lat": 42.19721,
    "lng": -71.84534,
    "state_id": "MA"
  },
  {
    "zip": 1503,
    "lat": 42.38378,
    "lng": -71.63551,
    "state_id": "MA"
  },
  {
    "zip": 1504,
    "lat": 42.0399,
    "lng": -71.53124,
    "state_id": "MA"
  },
  {
    "zip": 1505,
    "lat": 42.35633,
    "lng": -71.72123,
    "state_id": "MA"
  },
  {
    "zip": 1506,
    "lat": 42.19365,
    "lng": -72.10442,
    "state_id": "MA"
  },
  {
    "zip": 1507,
    "lat": 42.13509,
    "lng": -71.96803,
    "state_id": "MA"
  },
  {
    "zip": 1510,
    "lat": 42.4119,
    "lng": -71.68882,
    "state_id": "MA"
  },
  {
    "zip": 1515,
    "lat": 42.20328,
    "lng": -72.0467,
    "state_id": "MA"
  },
  {
    "zip": 1516,
    "lat": 42.05284,
    "lng": -71.75092,
    "state_id": "MA"
  },
  {
    "zip": 1518,
    "lat": 42.12349,
    "lng": -72.11861,
    "state_id": "MA"
  },
  {
    "zip": 1519,
    "lat": 42.20102,
    "lng": -71.68008,
    "state_id": "MA"
  },
  {
    "zip": 1520,
    "lat": 42.33536,
    "lng": -71.85284,
    "state_id": "MA"
  },
  {
    "zip": 1521,
    "lat": 42.06012,
    "lng": -72.16592,
    "state_id": "MA"
  },
  {
    "zip": 1522,
    "lat": 42.38137,
    "lng": -71.86913,
    "state_id": "MA"
  },
  {
    "zip": 1523,
    "lat": 42.47971,
    "lng": -71.67844,
    "state_id": "MA"
  },
  {
    "zip": 1524,
    "lat": 42.24921,
    "lng": -71.91982,
    "state_id": "MA"
  },
  {
    "zip": 1525,
    "lat": 42.10683,
    "lng": -71.63236,
    "state_id": "MA"
  },
  {
    "zip": 1527,
    "lat": 42.19218,
    "lng": -71.77416,
    "state_id": "MA"
  },
  {
    "zip": 1529,
    "lat": 42.03905,
    "lng": -71.57766,
    "state_id": "MA"
  },
  {
    "zip": 1531,
    "lat": 42.31929,
    "lng": -72.12796,
    "state_id": "MA"
  },
  {
    "zip": 1532,
    "lat": 42.32315,
    "lng": -71.64621,
    "state_id": "MA"
  },
  {
    "zip": 1534,
    "lat": 42.14044,
    "lng": -71.64291,
    "state_id": "MA"
  },
  {
    "zip": 1535,
    "lat": 42.2695,
    "lng": -72.07795,
    "state_id": "MA"
  },
  {
    "zip": 1536,
    "lat": 42.23236,
    "lng": -71.69101,
    "state_id": "MA"
  },
  {
    "zip": 1537,
    "lat": 42.16158,
    "lng": -71.89149,
    "state_id": "MA"
  },
  {
    "zip": 1540,
    "lat": 42.11634,
    "lng": -71.85733,
    "state_id": "MA"
  },
  {
    "zip": 1541,
    "lat": 42.45649,
    "lng": -71.88128,
    "state_id": "MA"
  },
  {
    "zip": 1542,
    "lat": 42.20185,
    "lng": -71.91079,
    "state_id": "MA"
  },
  {
    "zip": 1543,
    "lat": 42.38481,
    "lng": -71.96726,
    "state_id": "MA"
  },
  {
    "zip": 1545,
    "lat": 42.28423,
    "lng": -71.71554,
    "state_id": "MA"
  },
  {
    "zip": 1550,
    "lat": 42.06039,
    "lng": -72.03377,
    "state_id": "MA"
  },
  {
    "zip": 1560,
    "lat": 42.17496,
    "lng": -71.67763,
    "state_id": "MA"
  },
  {
    "zip": 1561,
    "lat": 42.44394,
    "lng": -71.68551,
    "state_id": "MA"
  },
  {
    "zip": 1562,
    "lat": 42.24715,
    "lng": -71.99189,
    "state_id": "MA"
  },
  {
    "zip": 1564,
    "lat": 42.44124,
    "lng": -71.77297,
    "state_id": "MA"
  },
  {
    "zip": 1566,
    "lat": 42.1024,
    "lng": -72.08116,
    "state_id": "MA"
  },
  {
    "zip": 1568,
    "lat": 42.17702,
    "lng": -71.60462,
    "state_id": "MA"
  },
  {
    "zip": 1569,
    "lat": 42.05872,
    "lng": -71.63709,
    "state_id": "MA"
  },
  {
    "zip": 1570,
    "lat": 42.05206,
    "lng": -71.8485,
    "state_id": "MA"
  },
  {
    "zip": 1571,
    "lat": 42.05501,
    "lng": -71.93519,
    "state_id": "MA"
  },
  {
    "zip": 1581,
    "lat": 42.26816,
    "lng": -71.61402,
    "state_id": "MA"
  },
  {
    "zip": 1583,
    "lat": 42.37025,
    "lng": -71.78499,
    "state_id": "MA"
  },
  {
    "zip": 1585,
    "lat": 42.24153,
    "lng": -72.16193,
    "state_id": "MA"
  },
  {
    "zip": 1588,
    "lat": 42.11998,
    "lng": -71.66956,
    "state_id": "MA"
  },
  {
    "zip": 1590,
    "lat": 42.13395,
    "lng": -71.75087,
    "state_id": "MA"
  },
  {
    "zip": 1602,
    "lat": 42.27225,
    "lng": -71.84952,
    "state_id": "MA"
  },
  {
    "zip": 1603,
    "lat": 42.24407,
    "lng": -71.84445,
    "state_id": "MA"
  },
  {
    "zip": 1604,
    "lat": 42.25151,
    "lng": -71.76772,
    "state_id": "MA"
  },
  {
    "zip": 1605,
    "lat": 42.28947,
    "lng": -71.78751,
    "state_id": "MA"
  },
  {
    "zip": 1606,
    "lat": 42.31444,
    "lng": -71.79556,
    "state_id": "MA"
  },
  {
    "zip": 1607,
    "lat": 42.22747,
    "lng": -71.78872,
    "state_id": "MA"
  },
  {
    "zip": 1608,
    "lat": 42.26187,
    "lng": -71.80159,
    "state_id": "MA"
  },
  {
    "zip": 1609,
    "lat": 42.28645,
    "lng": -71.82895,
    "state_id": "MA"
  },
  {
    "zip": 1610,
    "lat": 42.24674,
    "lng": -71.80967,
    "state_id": "MA"
  },
  {
    "zip": 1611,
    "lat": 42.2383,
    "lng": -71.87725,
    "state_id": "MA"
  },
  {
    "zip": 1612,
    "lat": 42.31035,
    "lng": -71.93077,
    "state_id": "MA"
  },
  {
    "zip": 1701,
    "lat": 42.32174,
    "lng": -71.43817,
    "state_id": "MA"
  },
  {
    "zip": 1702,
    "lat": 42.28217,
    "lng": -71.43387,
    "state_id": "MA"
  },
  {
    "zip": 1718,
    "lat": 42.51978,
    "lng": -71.42928,
    "state_id": "MA"
  },
  {
    "zip": 1719,
    "lat": 42.48836,
    "lng": -71.51782,
    "state_id": "MA"
  },
  {
    "zip": 1720,
    "lat": 42.48416,
    "lng": -71.43805,
    "state_id": "MA"
  },
  {
    "zip": 1721,
    "lat": 42.25729,
    "lng": -71.46866,
    "state_id": "MA"
  },
  {
    "zip": 1730,
    "lat": 42.49697,
    "lng": -71.27833,
    "state_id": "MA"
  },
  {
    "zip": 1731,
    "lat": 42.45675,
    "lng": -71.27952,
    "state_id": "MA"
  },
  {
    "zip": 1740,
    "lat": 42.43616,
    "lng": -71.60728,
    "state_id": "MA"
  },
  {
    "zip": 1741,
    "lat": 42.53001,
    "lng": -71.35128,
    "state_id": "MA"
  },
  {
    "zip": 1742,
    "lat": 42.46225,
    "lng": -71.36424,
    "state_id": "MA"
  },
  {
    "zip": 1745,
    "lat": 42.29183,
    "lng": -71.50108,
    "state_id": "MA"
  },
  {
    "zip": 1746,
    "lat": 42.19777,
    "lng": -71.44497,
    "state_id": "MA"
  },
  {
    "zip": 1747,
    "lat": 42.12481,
    "lng": -71.5351,
    "state_id": "MA"
  },
  {
    "zip": 1748,
    "lat": 42.2255,
    "lng": -71.53779,
    "state_id": "MA"
  },
  {
    "zip": 1749,
    "lat": 42.38868,
    "lng": -71.54647,
    "state_id": "MA"
  },
  {
    "zip": 1752,
    "lat": 42.3494,
    "lng": -71.54681,
    "state_id": "MA"
  },
  {
    "zip": 1754,
    "lat": 42.4264,
    "lng": -71.45612,
    "state_id": "MA"
  },
  {
    "zip": 1756,
    "lat": 42.09303,
    "lng": -71.5515,
    "state_id": "MA"
  },
  {
    "zip": 1757,
    "lat": 42.15678,
    "lng": -71.51911,
    "state_id": "MA"
  },
  {
    "zip": 1760,
    "lat": 42.28435,
    "lng": -71.34981,
    "state_id": "MA"
  },
  {
    "zip": 1770,
    "lat": 42.23417,
    "lng": -71.37529,
    "state_id": "MA"
  },
  {
    "zip": 1772,
    "lat": 42.30144,
    "lng": -71.53041,
    "state_id": "MA"
  },
  {
    "zip": 1773,
    "lat": 42.4252,
    "lng": -71.31039,
    "state_id": "MA"
  },
  {
    "zip": 1775,
    "lat": 42.42983,
    "lng": -71.51148,
    "state_id": "MA"
  },
  {
    "zip": 1776,
    "lat": 42.38473,
    "lng": -71.4234,
    "state_id": "MA"
  },
  {
    "zip": 1778,
    "lat": 42.35829,
    "lng": -71.35918,
    "state_id": "MA"
  },
  {
    "zip": 1801,
    "lat": 42.487,
    "lng": -71.15426,
    "state_id": "MA"
  },
  {
    "zip": 1803,
    "lat": 42.50216,
    "lng": -71.20257,
    "state_id": "MA"
  },
  {
    "zip": 1810,
    "lat": 42.64655,
    "lng": -71.16505,
    "state_id": "MA"
  },
  {
    "zip": 1821,
    "lat": 42.55016,
    "lng": -71.25217,
    "state_id": "MA"
  },
  {
    "zip": 1824,
    "lat": 42.59111,
    "lng": -71.35563,
    "state_id": "MA"
  },
  {
    "zip": 1826,
    "lat": 42.68314,
    "lng": -71.30061,
    "state_id": "MA"
  },
  {
    "zip": 1827,
    "lat": 42.67642,
    "lng": -71.5005,
    "state_id": "MA"
  },
  {
    "zip": 1830,
    "lat": 42.79591,
    "lng": -71.05311,
    "state_id": "MA"
  },
  {
    "zip": 1832,
    "lat": 42.79103,
    "lng": -71.13128,
    "state_id": "MA"
  },
  {
    "zip": 1833,
    "lat": 42.72401,
    "lng": -70.9821,
    "state_id": "MA"
  },
  {
    "zip": 1834,
    "lat": 42.75199,
    "lng": -71.01486,
    "state_id": "MA"
  },
  {
    "zip": 1835,
    "lat": 42.75293,
    "lng": -71.08594,
    "state_id": "MA"
  },
  {
    "zip": 1840,
    "lat": 42.70688,
    "lng": -71.1601,
    "state_id": "MA"
  },
  {
    "zip": 1841,
    "lat": 42.71083,
    "lng": -71.1651,
    "state_id": "MA"
  },
  {
    "zip": 1843,
    "lat": 42.68984,
    "lng": -71.16097,
    "state_id": "MA"
  },
  {
    "zip": 1844,
    "lat": 42.73401,
    "lng": -71.18889,
    "state_id": "MA"
  },
  {
    "zip": 1845,
    "lat": 42.67135,
    "lng": -71.08653,
    "state_id": "MA"
  },
  {
    "zip": 1850,
    "lat": 42.65569,
    "lng": -71.30292,
    "state_id": "MA"
  },
  {
    "zip": 1851,
    "lat": 42.62812,
    "lng": -71.33565,
    "state_id": "MA"
  },
  {
    "zip": 1852,
    "lat": 42.63232,
    "lng": -71.29534,
    "state_id": "MA"
  },
  {
    "zip": 1854,
    "lat": 42.64906,
    "lng": -71.348,
    "state_id": "MA"
  },
  {
    "zip": 1860,
    "lat": 42.83857,
    "lng": -71.01192,
    "state_id": "MA"
  },
  {
    "zip": 1862,
    "lat": 42.57488,
    "lng": -71.29343,
    "state_id": "MA"
  },
  {
    "zip": 1863,
    "lat": 42.63239,
    "lng": -71.39055,
    "state_id": "MA"
  },
  {
    "zip": 1864,
    "lat": 42.58157,
    "lng": -71.08757,
    "state_id": "MA"
  },
  {
    "zip": 1867,
    "lat": 42.53507,
    "lng": -71.10555,
    "state_id": "MA"
  },
  {
    "zip": 1876,
    "lat": 42.61224,
    "lng": -71.22746,
    "state_id": "MA"
  },
  {
    "zip": 1879,
    "lat": 42.66638,
    "lng": -71.42869,
    "state_id": "MA"
  },
  {
    "zip": 1880,
    "lat": 42.50222,
    "lng": -71.06702,
    "state_id": "MA"
  },
  {
    "zip": 1886,
    "lat": 42.58642,
    "lng": -71.44008,
    "state_id": "MA"
  },
  {
    "zip": 1887,
    "lat": 42.56099,
    "lng": -71.16545,
    "state_id": "MA"
  },
  {
    "zip": 1890,
    "lat": 42.4518,
    "lng": -71.1463,
    "state_id": "MA"
  },
  {
    "zip": 1901,
    "lat": 42.46104,
    "lng": -70.94657,
    "state_id": "MA"
  },
  {
    "zip": 1902,
    "lat": 42.47086,
    "lng": -70.93991,
    "state_id": "MA"
  },
  {
    "zip": 1904,
    "lat": 42.49153,
    "lng": -70.97273,
    "state_id": "MA"
  },
  {
    "zip": 1905,
    "lat": 42.46614,
    "lng": -70.97589,
    "state_id": "MA"
  },
  {
    "zip": 1906,
    "lat": 42.46799,
    "lng": -71.01441,
    "state_id": "MA"
  },
  {
    "zip": 1907,
    "lat": 42.47583,
    "lng": -70.90673,
    "state_id": "MA"
  },
  {
    "zip": 1908,
    "lat": 42.42978,
    "lng": -70.92729,
    "state_id": "MA"
  },
  {
    "zip": 1913,
    "lat": 42.85296,
    "lng": -70.94458,
    "state_id": "MA"
  },
  {
    "zip": 1915,
    "lat": 42.5681,
    "lng": -70.86273,
    "state_id": "MA"
  },
  {
    "zip": 1921,
    "lat": 42.68152,
    "lng": -71.01897,
    "state_id": "MA"
  },
  {
    "zip": 1922,
    "lat": 42.75872,
    "lng": -70.91448,
    "state_id": "MA"
  },
  {
    "zip": 1923,
    "lat": 42.57399,
    "lng": -70.94926,
    "state_id": "MA"
  },
  {
    "zip": 1929,
    "lat": 42.63474,
    "lng": -70.77689,
    "state_id": "MA"
  },
  {
    "zip": 1930,
    "lat": 42.62589,
    "lng": -70.68968,
    "state_id": "MA"
  },
  {
    "zip": 1937,
    "lat": 42.58573,
    "lng": -70.98403,
    "state_id": "MA"
  },
  {
    "zip": 1938,
    "lat": 42.68366,
    "lng": -70.84325,
    "state_id": "MA"
  },
  {
    "zip": 1940,
    "lat": 42.53457,
    "lng": -71.03755,
    "state_id": "MA"
  },
  {
    "zip": 1944,
    "lat": 42.58154,
    "lng": -70.76825,
    "state_id": "MA"
  },
  {
    "zip": 1945,
    "lat": 42.49911,
    "lng": -70.86368,
    "state_id": "MA"
  },
  {
    "zip": 1949,
    "lat": 42.60432,
    "lng": -71.01643,
    "state_id": "MA"
  },
  {
    "zip": 1950,
    "lat": 42.81238,
    "lng": -70.88787,
    "state_id": "MA"
  },
  {
    "zip": 1951,
    "lat": 42.77804,
    "lng": -70.84979,
    "state_id": "MA"
  },
  {
    "zip": 1952,
    "lat": 42.84654,
    "lng": -70.86162,
    "state_id": "MA"
  },
  {
    "zip": 1960,
    "lat": 42.53372,
    "lng": -70.97223,
    "state_id": "MA"
  },
  {
    "zip": 1966,
    "lat": 42.65435,
    "lng": -70.6222,
    "state_id": "MA"
  },
  {
    "zip": 1969,
    "lat": 42.72121,
    "lng": -70.89294,
    "state_id": "MA"
  },
  {
    "zip": 1970,
    "lat": 42.5126,
    "lng": -70.90376,
    "state_id": "MA"
  },
  {
    "zip": 1982,
    "lat": 42.62676,
    "lng": -70.85795,
    "state_id": "MA"
  },
  {
    "zip": 1983,
    "lat": 42.63728,
    "lng": -70.94256,
    "state_id": "MA"
  },
  {
    "zip": 1984,
    "lat": 42.60082,
    "lng": -70.88249,
    "state_id": "MA"
  },
  {
    "zip": 1985,
    "lat": 42.79391,
    "lng": -70.96908,
    "state_id": "MA"
  },
  {
    "zip": 2019,
    "lat": 42.07774,
    "lng": -71.47409,
    "state_id": "MA"
  },
  {
    "zip": 2021,
    "lat": 42.17515,
    "lng": -71.12655,
    "state_id": "MA"
  },
  {
    "zip": 2025,
    "lat": 42.23604,
    "lng": -70.81903,
    "state_id": "MA"
  },
  {
    "zip": 2026,
    "lat": 42.2467,
    "lng": -71.17775,
    "state_id": "MA"
  },
  {
    "zip": 2030,
    "lat": 42.23663,
    "lng": -71.28418,
    "state_id": "MA"
  },
  {
    "zip": 2032,
    "lat": 42.15491,
    "lng": -71.21548,
    "state_id": "MA"
  },
  {
    "zip": 2035,
    "lat": 42.06266,
    "lng": -71.24613,
    "state_id": "MA"
  },
  {
    "zip": 2038,
    "lat": 42.08622,
    "lng": -71.41126,
    "state_id": "MA"
  },
  {
    "zip": 2043,
    "lat": 42.21678,
    "lng": -70.88499,
    "state_id": "MA"
  },
  {
    "zip": 2045,
    "lat": 42.28436,
    "lng": -70.88269,
    "state_id": "MA"
  },
  {
    "zip": 2047,
    "lat": 42.13346,
    "lng": -70.68674,
    "state_id": "MA"
  },
  {
    "zip": 2048,
    "lat": 42.01626,
    "lng": -71.21868,
    "state_id": "MA"
  },
  {
    "zip": 2050,
    "lat": 42.11488,
    "lng": -70.71475,
    "state_id": "MA"
  },
  {
    "zip": 2052,
    "lat": 42.18481,
    "lng": -71.305,
    "state_id": "MA"
  },
  {
    "zip": 2053,
    "lat": 42.15352,
    "lng": -71.42907,
    "state_id": "MA"
  },
  {
    "zip": 2054,
    "lat": 42.16932,
    "lng": -71.36256,
    "state_id": "MA"
  },
  {
    "zip": 2056,
    "lat": 42.11637,
    "lng": -71.32963,
    "state_id": "MA"
  },
  {
    "zip": 2061,
    "lat": 42.16073,
    "lng": -70.81774,
    "state_id": "MA"
  },
  {
    "zip": 2062,
    "lat": 42.18605,
    "lng": -71.19492,
    "state_id": "MA"
  },
  {
    "zip": 2066,
    "lat": 42.20146,
    "lng": -70.76181,
    "state_id": "MA"
  },
  {
    "zip": 2067,
    "lat": 42.10811,
    "lng": -71.18143,
    "state_id": "MA"
  },
  {
    "zip": 2071,
    "lat": 42.10279,
    "lng": -71.2721,
    "state_id": "MA"
  },
  {
    "zip": 2072,
    "lat": 42.11923,
    "lng": -71.10189,
    "state_id": "MA"
  },
  {
    "zip": 2081,
    "lat": 42.14652,
    "lng": -71.25883,
    "state_id": "MA"
  },
  {
    "zip": 2090,
    "lat": 42.22026,
    "lng": -71.21068,
    "state_id": "MA"
  },
  {
    "zip": 2093,
    "lat": 42.0513,
    "lng": -71.35522,
    "state_id": "MA"
  },
  {
    "zip": 2108,
    "lat": 42.35767,
    "lng": -71.06505,
    "state_id": "MA"
  },
  {
    "zip": 2109,
    "lat": 42.36459,
    "lng": -71.05298,
    "state_id": "MA"
  },
  {
    "zip": 2110,
    "lat": 42.35826,
    "lng": -71.05175,
    "state_id": "MA"
  },
  {
    "zip": 2111,
    "lat": 42.35007,
    "lng": -71.05911,
    "state_id": "MA"
  },
  {
    "zip": 2113,
    "lat": 42.36542,
    "lng": -71.0554,
    "state_id": "MA"
  },
  {
    "zip": 2114,
    "lat": 42.36316,
    "lng": -71.06732,
    "state_id": "MA"
  },
  {
    "zip": 2115,
    "lat": 42.34109,
    "lng": -71.09456,
    "state_id": "MA"
  },
  {
    "zip": 2116,
    "lat": 42.35062,
    "lng": -71.07564,
    "state_id": "MA"
  },
  {
    "zip": 2118,
    "lat": 42.3382,
    "lng": -71.07074,
    "state_id": "MA"
  },
  {
    "zip": 2119,
    "lat": 42.3241,
    "lng": -71.08483,
    "state_id": "MA"
  },
  {
    "zip": 2120,
    "lat": 42.3321,
    "lng": -71.09637,
    "state_id": "MA"
  },
  {
    "zip": 2121,
    "lat": 42.30589,
    "lng": -71.08583,
    "state_id": "MA"
  },
  {
    "zip": 2122,
    "lat": 42.30007,
    "lng": -71.03352,
    "state_id": "MA"
  },
  {
    "zip": 2124,
    "lat": 42.28572,
    "lng": -71.07105,
    "state_id": "MA"
  },
  {
    "zip": 2125,
    "lat": 42.31545,
    "lng": -71.05594,
    "state_id": "MA"
  },
  {
    "zip": 2126,
    "lat": 42.2743,
    "lng": -71.09388,
    "state_id": "MA"
  },
  {
    "zip": 2127,
    "lat": 42.33479,
    "lng": -71.03902,
    "state_id": "MA"
  },
  {
    "zip": 2128,
    "lat": 42.37266,
    "lng": -71.01656,
    "state_id": "MA"
  },
  {
    "zip": 2129,
    "lat": 42.37975,
    "lng": -71.06169,
    "state_id": "MA"
  },
  {
    "zip": 2130,
    "lat": 42.30986,
    "lng": -71.11486,
    "state_id": "MA"
  },
  {
    "zip": 2131,
    "lat": 42.2847,
    "lng": -71.12652,
    "state_id": "MA"
  },
  {
    "zip": 2132,
    "lat": 42.28025,
    "lng": -71.16145,
    "state_id": "MA"
  },
  {
    "zip": 2134,
    "lat": 42.35788,
    "lng": -71.12942,
    "state_id": "MA"
  },
  {
    "zip": 2135,
    "lat": 42.34981,
    "lng": -71.15386,
    "state_id": "MA"
  },
  {
    "zip": 2136,
    "lat": 42.25535,
    "lng": -71.12937,
    "state_id": "MA"
  },
  {
    "zip": 2138,
    "lat": 42.38004,
    "lng": -71.13464,
    "state_id": "MA"
  },
  {
    "zip": 2139,
    "lat": 42.36253,
    "lng": -71.10302,
    "state_id": "MA"
  },
  {
    "zip": 2140,
    "lat": 42.39329,
    "lng": -71.13329,
    "state_id": "MA"
  },
  {
    "zip": 2141,
    "lat": 42.36997,
    "lng": -71.08268,
    "state_id": "MA"
  },
  {
    "zip": 2142,
    "lat": 42.36195,
    "lng": -71.08196,
    "state_id": "MA"
  },
  {
    "zip": 2143,
    "lat": 42.38145,
    "lng": -71.09684,
    "state_id": "MA"
  },
  {
    "zip": 2144,
    "lat": 42.40065,
    "lng": -71.12186,
    "state_id": "MA"
  },
  {
    "zip": 2145,
    "lat": 42.39203,
    "lng": -71.08951,
    "state_id": "MA"
  },
  {
    "zip": 2148,
    "lat": 42.43048,
    "lng": -71.0576,
    "state_id": "MA"
  },
  {
    "zip": 2149,
    "lat": 42.40642,
    "lng": -71.05447,
    "state_id": "MA"
  },
  {
    "zip": 2150,
    "lat": 42.39594,
    "lng": -71.03244,
    "state_id": "MA"
  },
  {
    "zip": 2151,
    "lat": 42.41841,
    "lng": -71.00347,
    "state_id": "MA"
  },
  {
    "zip": 2152,
    "lat": 42.37279,
    "lng": -70.97789,
    "state_id": "MA"
  },
  {
    "zip": 2155,
    "lat": 42.42324,
    "lng": -71.10885,
    "state_id": "MA"
  },
  {
    "zip": 2163,
    "lat": 42.36636,
    "lng": -71.12076,
    "state_id": "MA"
  },
  {
    "zip": 2169,
    "lat": 42.24759,
    "lng": -71.00322,
    "state_id": "MA"
  },
  {
    "zip": 2170,
    "lat": 42.26649,
    "lng": -71.01615,
    "state_id": "MA"
  },
  {
    "zip": 2171,
    "lat": 42.28822,
    "lng": -71.02459,
    "state_id": "MA"
  },
  {
    "zip": 2176,
    "lat": 42.45561,
    "lng": -71.05901,
    "state_id": "MA"
  },
  {
    "zip": 2180,
    "lat": 42.47407,
    "lng": -71.09723,
    "state_id": "MA"
  },
  {
    "zip": 2184,
    "lat": 42.20384,
    "lng": -71.00221,
    "state_id": "MA"
  },
  {
    "zip": 2186,
    "lat": 42.24123,
    "lng": -71.08437,
    "state_id": "MA"
  },
  {
    "zip": 2188,
    "lat": 42.20808,
    "lng": -70.95748,
    "state_id": "MA"
  },
  {
    "zip": 2189,
    "lat": 42.20876,
    "lng": -70.9316,
    "state_id": "MA"
  },
  {
    "zip": 2190,
    "lat": 42.16646,
    "lng": -70.95271,
    "state_id": "MA"
  },
  {
    "zip": 2191,
    "lat": 42.24364,
    "lng": -70.94148,
    "state_id": "MA"
  },
  {
    "zip": 2199,
    "lat": 42.34747,
    "lng": -71.08202,
    "state_id": "MA"
  },
  {
    "zip": 2203,
    "lat": 42.3605,
    "lng": -71.05896,
    "state_id": "MA"
  },
  {
    "zip": 2210,
    "lat": 42.34781,
    "lng": -71.03905,
    "state_id": "MA"
  },
  {
    "zip": 2215,
    "lat": 42.34778,
    "lng": -71.10287,
    "state_id": "MA"
  },
  {
    "zip": 2301,
    "lat": 42.07791,
    "lng": -71.04225,
    "state_id": "MA"
  },
  {
    "zip": 2302,
    "lat": 42.08784,
    "lng": -70.99871,
    "state_id": "MA"
  },
  {
    "zip": 2322,
    "lat": 42.12666,
    "lng": -71.04924,
    "state_id": "MA"
  },
  {
    "zip": 2324,
    "lat": 41.97282,
    "lng": -70.97489,
    "state_id": "MA"
  },
  {
    "zip": 2330,
    "lat": 41.87389,
    "lng": -70.75635,
    "state_id": "MA"
  },
  {
    "zip": 2332,
    "lat": 42.0467,
    "lng": -70.71496,
    "state_id": "MA"
  },
  {
    "zip": 2333,
    "lat": 42.03515,
    "lng": -70.94241,
    "state_id": "MA"
  },
  {
    "zip": 2338,
    "lat": 41.99141,
    "lng": -70.86326,
    "state_id": "MA"
  },
  {
    "zip": 2339,
    "lat": 42.12239,
    "lng": -70.85666,
    "state_id": "MA"
  },
  {
    "zip": 2341,
    "lat": 42.05579,
    "lng": -70.87232,
    "state_id": "MA"
  },
  {
    "zip": 2343,
    "lat": 42.14729,
    "lng": -71.00482,
    "state_id": "MA"
  },
  {
    "zip": 2346,
    "lat": 41.88036,
    "lng": -70.87432,
    "state_id": "MA"
  },
  {
    "zip": 2347,
    "lat": 41.83127,
    "lng": -70.95914,
    "state_id": "MA"
  },
  {
    "zip": 2351,
    "lat": 42.11724,
    "lng": -70.95962,
    "state_id": "MA"
  },
  {
    "zip": 2356,
    "lat": 42.05507,
    "lng": -71.12059,
    "state_id": "MA"
  },
  {
    "zip": 2357,
    "lat": 42.05774,
    "lng": -71.08074,
    "state_id": "MA"
  },
  {
    "zip": 2359,
    "lat": 42.06547,
    "lng": -70.80143,
    "state_id": "MA"
  },
  {
    "zip": 2360,
    "lat": 41.87859,
    "lng": -70.63066,
    "state_id": "MA"
  },
  {
    "zip": 2364,
    "lat": 41.98617,
    "lng": -70.74823,
    "state_id": "MA"
  },
  {
    "zip": 2366,
    "lat": 41.85079,
    "lng": -70.65722,
    "state_id": "MA"
  },
  {
    "zip": 2367,
    "lat": 41.96345,
    "lng": -70.80973,
    "state_id": "MA"
  },
  {
    "zip": 2368,
    "lat": 42.17697,
    "lng": -71.05341,
    "state_id": "MA"
  },
  {
    "zip": 2370,
    "lat": 42.12987,
    "lng": -70.91096,
    "state_id": "MA"
  },
  {
    "zip": 2375,
    "lat": 42.01746,
    "lng": -71.10147,
    "state_id": "MA"
  },
  {
    "zip": 2379,
    "lat": 42.02139,
    "lng": -71.02669,
    "state_id": "MA"
  },
  {
    "zip": 2382,
    "lat": 42.07999,
    "lng": -70.93991,
    "state_id": "MA"
  },
  {
    "zip": 2420,
    "lat": 42.45633,
    "lng": -71.21668,
    "state_id": "MA"
  },
  {
    "zip": 2421,
    "lat": 42.43853,
    "lng": -71.23999,
    "state_id": "MA"
  },
  {
    "zip": 2445,
    "lat": 42.32599,
    "lng": -71.13396,
    "state_id": "MA"
  },
  {
    "zip": 2446,
    "lat": 42.34356,
    "lng": -71.12167,
    "state_id": "MA"
  },
  {
    "zip": 2451,
    "lat": 42.39821,
    "lng": -71.25711,
    "state_id": "MA"
  },
  {
    "zip": 2452,
    "lat": 42.39357,
    "lng": -71.21961,
    "state_id": "MA"
  },
  {
    "zip": 2453,
    "lat": 42.36913,
    "lng": -71.24024,
    "state_id": "MA"
  },
  {
    "zip": 2457,
    "lat": 42.29836,
    "lng": -71.26833,
    "state_id": "MA"
  },
  {
    "zip": 2458,
    "lat": 42.3528,
    "lng": -71.18748,
    "state_id": "MA"
  },
  {
    "zip": 2459,
    "lat": 42.31493,
    "lng": -71.19174,
    "state_id": "MA"
  },
  {
    "zip": 2460,
    "lat": 42.35196,
    "lng": -71.2083,
    "state_id": "MA"
  },
  {
    "zip": 2461,
    "lat": 42.31681,
    "lng": -71.20849,
    "state_id": "MA"
  },
  {
    "zip": 2462,
    "lat": 42.32991,
    "lng": -71.25616,
    "state_id": "MA"
  },
  {
    "zip": 2464,
    "lat": 42.31292,
    "lng": -71.21947,
    "state_id": "MA"
  },
  {
    "zip": 2465,
    "lat": 42.34914,
    "lng": -71.22665,
    "state_id": "MA"
  },
  {
    "zip": 2466,
    "lat": 42.34412,
    "lng": -71.24806,
    "state_id": "MA"
  },
  {
    "zip": 2467,
    "lat": 42.3164,
    "lng": -71.16117,
    "state_id": "MA"
  },
  {
    "zip": 2468,
    "lat": 42.3273,
    "lng": -71.23077,
    "state_id": "MA"
  },
  {
    "zip": 2472,
    "lat": 42.37,
    "lng": -71.17725,
    "state_id": "MA"
  },
  {
    "zip": 2474,
    "lat": 42.42026,
    "lng": -71.15651,
    "state_id": "MA"
  },
  {
    "zip": 2476,
    "lat": 42.41624,
    "lng": -71.17518,
    "state_id": "MA"
  },
  {
    "zip": 2478,
    "lat": 42.39603,
    "lng": -71.17952,
    "state_id": "MA"
  },
  {
    "zip": 2481,
    "lat": 42.31293,
    "lng": -71.27431,
    "state_id": "MA"
  },
  {
    "zip": 2482,
    "lat": 42.29384,
    "lng": -71.29987,
    "state_id": "MA"
  },
  {
    "zip": 2492,
    "lat": 42.27612,
    "lng": -71.24374,
    "state_id": "MA"
  },
  {
    "zip": 2493,
    "lat": 42.35894,
    "lng": -71.3001,
    "state_id": "MA"
  },
  {
    "zip": 2494,
    "lat": 42.29966,
    "lng": -71.23201,
    "state_id": "MA"
  },
  {
    "zip": 2532,
    "lat": 41.75162,
    "lng": -70.59728,
    "state_id": "MA"
  },
  {
    "zip": 2534,
    "lat": 41.66709,
    "lng": -70.61991,
    "state_id": "MA"
  },
  {
    "zip": 2535,
    "lat": 41.34896,
    "lng": -70.74629,
    "state_id": "MA"
  },
  {
    "zip": 2536,
    "lat": 41.59694,
    "lng": -70.56721,
    "state_id": "MA"
  },
  {
    "zip": 2537,
    "lat": 41.72948,
    "lng": -70.44014,
    "state_id": "MA"
  },
  {
    "zip": 2538,
    "lat": 41.77699,
    "lng": -70.64919,
    "state_id": "MA"
  },
  {
    "zip": 2539,
    "lat": 41.37993,
    "lng": -70.53227,
    "state_id": "MA"
  },
  {
    "zip": 2540,
    "lat": 41.57197,
    "lng": -70.62793,
    "state_id": "MA"
  },
  {
    "zip": 2542,
    "lat": 41.70259,
    "lng": -70.54687,
    "state_id": "MA"
  },
  {
    "zip": 2543,
    "lat": 41.49345,
    "lng": -70.73109,
    "state_id": "MA"
  },
  {
    "zip": 2553,
    "lat": 41.71336,
    "lng": -70.61883,
    "state_id": "MA"
  },
  {
    "zip": 2554,
    "lat": 41.28538,
    "lng": -70.07982,
    "state_id": "MA"
  },
  {
    "zip": 2556,
    "lat": 41.63949,
    "lng": -70.62584,
    "state_id": "MA"
  },
  {
    "zip": 2557,
    "lat": 41.4366,
    "lng": -70.58,
    "state_id": "MA"
  },
  {
    "zip": 2558,
    "lat": 41.74478,
    "lng": -70.65348,
    "state_id": "MA"
  },
  {
    "zip": 2559,
    "lat": 41.69166,
    "lng": -70.61524,
    "state_id": "MA"
  },
  {
    "zip": 2561,
    "lat": 41.76887,
    "lng": -70.53319,
    "state_id": "MA"
  },
  {
    "zip": 2562,
    "lat": 41.78718,
    "lng": -70.52691,
    "state_id": "MA"
  },
  {
    "zip": 2563,
    "lat": 41.71963,
    "lng": -70.478,
    "state_id": "MA"
  },
  {
    "zip": 2564,
    "lat": 41.26534,
    "lng": -69.98431,
    "state_id": "MA"
  },
  {
    "zip": 2568,
    "lat": 41.45264,
    "lng": -70.61758,
    "state_id": "MA"
  },
  {
    "zip": 2571,
    "lat": 41.76358,
    "lng": -70.69796,
    "state_id": "MA"
  },
  {
    "zip": 2575,
    "lat": 41.39993,
    "lng": -70.65454,
    "state_id": "MA"
  },
  {
    "zip": 2576,
    "lat": 41.77807,
    "lng": -70.7637,
    "state_id": "MA"
  },
  {
    "zip": 2584,
    "lat": 41.25883,
    "lng": -70.00735,
    "state_id": "MA"
  },
  {
    "zip": 2601,
    "lat": 41.6603,
    "lng": -70.29682,
    "state_id": "MA"
  },
  {
    "zip": 2630,
    "lat": 41.70459,
    "lng": -70.30335,
    "state_id": "MA"
  },
  {
    "zip": 2631,
    "lat": 41.74684,
    "lng": -70.06942,
    "state_id": "MA"
  },
  {
    "zip": 2632,
    "lat": 41.66056,
    "lng": -70.34749,
    "state_id": "MA"
  },
  {
    "zip": 2633,
    "lat": 41.68893,
    "lng": -69.97225,
    "state_id": "MA"
  },
  {
    "zip": 2635,
    "lat": 41.62372,
    "lng": -70.44051,
    "state_id": "MA"
  },
  {
    "zip": 2637,
    "lat": 41.70571,
    "lng": -70.27243,
    "state_id": "MA"
  },
  {
    "zip": 2638,
    "lat": 41.73226,
    "lng": -70.19361,
    "state_id": "MA"
  },
  {
    "zip": 2639,
    "lat": 41.66634,
    "lng": -70.1353,
    "state_id": "MA"
  },
  {
    "zip": 2641,
    "lat": 41.75059,
    "lng": -70.15223,
    "state_id": "MA"
  },
  {
    "zip": 2642,
    "lat": 41.83957,
    "lng": -69.97697,
    "state_id": "MA"
  },
  {
    "zip": 2643,
    "lat": 41.76568,
    "lng": -69.93648,
    "state_id": "MA"
  },
  {
    "zip": 2644,
    "lat": 41.68422,
    "lng": -70.51024,
    "state_id": "MA"
  },
  {
    "zip": 2645,
    "lat": 41.70422,
    "lng": -70.06082,
    "state_id": "MA"
  },
  {
    "zip": 2646,
    "lat": 41.67079,
    "lng": -70.07195,
    "state_id": "MA"
  },
  {
    "zip": 2647,
    "lat": 41.63184,
    "lng": -70.30805,
    "state_id": "MA"
  },
  {
    "zip": 2648,
    "lat": 41.67071,
    "lng": -70.41635,
    "state_id": "MA"
  },
  {
    "zip": 2649,
    "lat": 41.61764,
    "lng": -70.49053,
    "state_id": "MA"
  },
  {
    "zip": 2650,
    "lat": 41.70341,
    "lng": -69.96591,
    "state_id": "MA"
  },
  {
    "zip": 2651,
    "lat": 41.87507,
    "lng": -70.0034,
    "state_id": "MA"
  },
  {
    "zip": 2652,
    "lat": 42.04411,
    "lng": -70.09475,
    "state_id": "MA"
  },
  {
    "zip": 2653,
    "lat": 41.76877,
    "lng": -69.9739,
    "state_id": "MA"
  },
  {
    "zip": 2655,
    "lat": 41.62996,
    "lng": -70.39258,
    "state_id": "MA"
  },
  {
    "zip": 2657,
    "lat": 42.06139,
    "lng": -70.19814,
    "state_id": "MA"
  },
  {
    "zip": 2659,
    "lat": 41.68304,
    "lng": -70.0223,
    "state_id": "MA"
  },
  {
    "zip": 2660,
    "lat": 41.70548,
    "lng": -70.15434,
    "state_id": "MA"
  },
  {
    "zip": 2663,
    "lat": 41.90674,
    "lng": -69.99749,
    "state_id": "MA"
  },
  {
    "zip": 2664,
    "lat": 41.67465,
    "lng": -70.19464,
    "state_id": "MA"
  },
  {
    "zip": 2666,
    "lat": 41.98943,
    "lng": -70.04569,
    "state_id": "MA"
  },
  {
    "zip": 2667,
    "lat": 41.9279,
    "lng": -70.01813,
    "state_id": "MA"
  },
  {
    "zip": 2668,
    "lat": 41.71181,
    "lng": -70.35676,
    "state_id": "MA"
  },
  {
    "zip": 2669,
    "lat": 41.67105,
    "lng": -69.9928,
    "state_id": "MA"
  },
  {
    "zip": 2670,
    "lat": 41.65987,
    "lng": -70.1716,
    "state_id": "MA"
  },
  {
    "zip": 2671,
    "lat": 41.6721,
    "lng": -70.11095,
    "state_id": "MA"
  },
  {
    "zip": 2672,
    "lat": 41.63551,
    "lng": -70.31791,
    "state_id": "MA"
  },
  {
    "zip": 2673,
    "lat": 41.65731,
    "lng": -70.24666,
    "state_id": "MA"
  },
  {
    "zip": 2675,
    "lat": 41.70486,
    "lng": -70.23063,
    "state_id": "MA"
  },
  {
    "zip": 2702,
    "lat": 41.78453,
    "lng": -71.06116,
    "state_id": "MA"
  },
  {
    "zip": 2703,
    "lat": 41.93105,
    "lng": -71.29505,
    "state_id": "MA"
  },
  {
    "zip": 2713,
    "lat": 41.43437,
    "lng": -70.87573,
    "state_id": "MA"
  },
  {
    "zip": 2715,
    "lat": 41.81747,
    "lng": -71.15057,
    "state_id": "MA"
  },
  {
    "zip": 2717,
    "lat": 41.75874,
    "lng": -70.97166,
    "state_id": "MA"
  },
  {
    "zip": 2718,
    "lat": 41.86891,
    "lng": -71.01162,
    "state_id": "MA"
  },
  {
    "zip": 2719,
    "lat": 41.63945,
    "lng": -70.87321,
    "state_id": "MA"
  },
  {
    "zip": 2720,
    "lat": 41.73286,
    "lng": -71.11808,
    "state_id": "MA"
  },
  {
    "zip": 2721,
    "lat": 41.67459,
    "lng": -71.14768,
    "state_id": "MA"
  },
  {
    "zip": 2723,
    "lat": 41.69271,
    "lng": -71.12968,
    "state_id": "MA"
  },
  {
    "zip": 2724,
    "lat": 41.684,
    "lng": -71.17942,
    "state_id": "MA"
  },
  {
    "zip": 2725,
    "lat": 41.71252,
    "lng": -71.18426,
    "state_id": "MA"
  },
  {
    "zip": 2726,
    "lat": 41.76075,
    "lng": -71.14438,
    "state_id": "MA"
  },
  {
    "zip": 2738,
    "lat": 41.70912,
    "lng": -70.76355,
    "state_id": "MA"
  },
  {
    "zip": 2739,
    "lat": 41.66869,
    "lng": -70.81706,
    "state_id": "MA"
  },
  {
    "zip": 2740,
    "lat": 41.63787,
    "lng": -70.93814,
    "state_id": "MA"
  },
  {
    "zip": 2743,
    "lat": 41.71385,
    "lng": -70.90123,
    "state_id": "MA"
  },
  {
    "zip": 2744,
    "lat": 41.60799,
    "lng": -70.91269,
    "state_id": "MA"
  },
  {
    "zip": 2745,
    "lat": 41.69887,
    "lng": -70.94819,
    "state_id": "MA"
  },
  {
    "zip": 2746,
    "lat": 41.66144,
    "lng": -70.94169,
    "state_id": "MA"
  },
  {
    "zip": 2747,
    "lat": 41.66783,
    "lng": -71.01627,
    "state_id": "MA"
  },
  {
    "zip": 2748,
    "lat": 41.5605,
    "lng": -70.97897,
    "state_id": "MA"
  },
  {
    "zip": 2760,
    "lat": 41.97006,
    "lng": -71.33585,
    "state_id": "MA"
  },
  {
    "zip": 2762,
    "lat": 42.01409,
    "lng": -71.33642,
    "state_id": "MA"
  },
  {
    "zip": 2763,
    "lat": 41.96669,
    "lng": -71.30749,
    "state_id": "MA"
  },
  {
    "zip": 2764,
    "lat": 41.85663,
    "lng": -71.16052,
    "state_id": "MA"
  },
  {
    "zip": 2766,
    "lat": 41.96402,
    "lng": -71.18418,
    "state_id": "MA"
  },
  {
    "zip": 2767,
    "lat": 41.93707,
    "lng": -71.05109,
    "state_id": "MA"
  },
  {
    "zip": 2769,
    "lat": 41.84394,
    "lng": -71.24559,
    "state_id": "MA"
  },
  {
    "zip": 2770,
    "lat": 41.75849,
    "lng": -70.84084,
    "state_id": "MA"
  },
  {
    "zip": 2771,
    "lat": 41.83786,
    "lng": -71.31735,
    "state_id": "MA"
  },
  {
    "zip": 2777,
    "lat": 41.75703,
    "lng": -71.21203,
    "state_id": "MA"
  },
  {
    "zip": 2779,
    "lat": 41.83491,
    "lng": -71.07543,
    "state_id": "MA"
  },
  {
    "zip": 2780,
    "lat": 41.90755,
    "lng": -71.11956,
    "state_id": "MA"
  },
  {
    "zip": 2790,
    "lat": 41.59989,
    "lng": -71.08324,
    "state_id": "MA"
  },
  {
    "zip": 2791,
    "lat": 41.528,
    "lng": -71.07806,
    "state_id": "MA"
  },
  {
    "zip": 20601,
    "lat": 38.6137,
    "lng": -76.85088,
    "state_id": "MD"
  },
  {
    "zip": 20602,
    "lat": 38.58396,
    "lng": -76.89426,
    "state_id": "MD"
  },
  {
    "zip": 20603,
    "lat": 38.62939,
    "lng": -76.97697,
    "state_id": "MD"
  },
  {
    "zip": 20606,
    "lat": 38.25952,
    "lng": -76.73699,
    "state_id": "MD"
  },
  {
    "zip": 20607,
    "lat": 38.67227,
    "lng": -77.02018,
    "state_id": "MD"
  },
  {
    "zip": 20608,
    "lat": 38.58625,
    "lng": -76.70692,
    "state_id": "MD"
  },
  {
    "zip": 20609,
    "lat": 38.27423,
    "lng": -76.75504,
    "state_id": "MD"
  },
  {
    "zip": 20611,
    "lat": 38.46079,
    "lng": -76.98456,
    "state_id": "MD"
  },
  {
    "zip": 20612,
    "lat": 38.50707,
    "lng": -76.67788,
    "state_id": "MD"
  },
  {
    "zip": 20613,
    "lat": 38.67247,
    "lng": -76.8088,
    "state_id": "MD"
  },
  {
    "zip": 20615,
    "lat": 38.42013,
    "lng": -76.54786,
    "state_id": "MD"
  },
  {
    "zip": 20616,
    "lat": 38.65412,
    "lng": -77.08845,
    "state_id": "MD"
  },
  {
    "zip": 20617,
    "lat": 38.54127,
    "lng": -76.85245,
    "state_id": "MD"
  },
  {
    "zip": 20618,
    "lat": 38.28161,
    "lng": -76.79027,
    "state_id": "MD"
  },
  {
    "zip": 20619,
    "lat": 38.29102,
    "lng": -76.52683,
    "state_id": "MD"
  },
  {
    "zip": 20620,
    "lat": 38.23157,
    "lng": -76.52327,
    "state_id": "MD"
  },
  {
    "zip": 20621,
    "lat": 38.33131,
    "lng": -76.79647,
    "state_id": "MD"
  },
  {
    "zip": 20622,
    "lat": 38.45397,
    "lng": -76.84481,
    "state_id": "MD"
  },
  {
    "zip": 20623,
    "lat": 38.74247,
    "lng": -76.83723,
    "state_id": "MD"
  },
  {
    "zip": 20624,
    "lat": 38.33643,
    "lng": -76.73785,
    "state_id": "MD"
  },
  {
    "zip": 20625,
    "lat": 38.26234,
    "lng": -76.84842,
    "state_id": "MD"
  },
  {
    "zip": 20626,
    "lat": 38.23109,
    "lng": -76.76088,
    "state_id": "MD"
  },
  {
    "zip": 20628,
    "lat": 38.14941,
    "lng": -76.35096,
    "state_id": "MD"
  },
  {
    "zip": 20629,
    "lat": 38.3365,
    "lng": -76.45232,
    "state_id": "MD"
  },
  {
    "zip": 20630,
    "lat": 38.16257,
    "lng": -76.47243,
    "state_id": "MD"
  },
  {
    "zip": 20632,
    "lat": 38.43207,
    "lng": -76.95195,
    "state_id": "MD"
  },
  {
    "zip": 20634,
    "lat": 38.24376,
    "lng": -76.4952,
    "state_id": "MD"
  },
  {
    "zip": 20636,
    "lat": 38.35215,
    "lng": -76.5703,
    "state_id": "MD"
  },
  {
    "zip": 20637,
    "lat": 38.52625,
    "lng": -76.77615,
    "state_id": "MD"
  },
  {
    "zip": 20639,
    "lat": 38.60752,
    "lng": -76.60715,
    "state_id": "MD"
  },
  {
    "zip": 20640,
    "lat": 38.55696,
    "lng": -77.16199,
    "state_id": "MD"
  },
  {
    "zip": 20645,
    "lat": 38.29668,
    "lng": -76.90644,
    "state_id": "MD"
  },
  {
    "zip": 20646,
    "lat": 38.5226,
    "lng": -77.00017,
    "state_id": "MD"
  },
  {
    "zip": 20650,
    "lat": 38.27242,
    "lng": -76.63437,
    "state_id": "MD"
  },
  {
    "zip": 20653,
    "lat": 38.23019,
    "lng": -76.43372,
    "state_id": "MD"
  },
  {
    "zip": 20657,
    "lat": 38.38362,
    "lng": -76.44544,
    "state_id": "MD"
  },
  {
    "zip": 20658,
    "lat": 38.56349,
    "lng": -77.16134,
    "state_id": "MD"
  },
  {
    "zip": 20659,
    "lat": 38.42062,
    "lng": -76.73142,
    "state_id": "MD"
  },
  {
    "zip": 20660,
    "lat": 38.37019,
    "lng": -76.7067,
    "state_id": "MD"
  },
  {
    "zip": 20662,
    "lat": 38.43515,
    "lng": -77.20182,
    "state_id": "MD"
  },
  {
    "zip": 20664,
    "lat": 38.35251,
    "lng": -76.92152,
    "state_id": "MD"
  },
  {
    "zip": 20667,
    "lat": 38.21768,
    "lng": -76.44286,
    "state_id": "MD"
  },
  {
    "zip": 20670,
    "lat": 38.28223,
    "lng": -76.42039,
    "state_id": "MD"
  },
  {
    "zip": 20674,
    "lat": 38.13424,
    "lng": -76.50078,
    "state_id": "MD"
  },
  {
    "zip": 20675,
    "lat": 38.58095,
    "lng": -77.0224,
    "state_id": "MD"
  },
  {
    "zip": 20676,
    "lat": 38.48962,
    "lng": -76.54611,
    "state_id": "MD"
  },
  {
    "zip": 20677,
    "lat": 38.49995,
    "lng": -77.041,
    "state_id": "MD"
  },
  {
    "zip": 20678,
    "lat": 38.52135,
    "lng": -76.60325,
    "state_id": "MD"
  },
  {
    "zip": 20680,
    "lat": 38.11373,
    "lng": -76.37247,
    "state_id": "MD"
  },
  {
    "zip": 20684,
    "lat": 38.14256,
    "lng": -76.40772,
    "state_id": "MD"
  },
  {
    "zip": 20685,
    "lat": 38.43387,
    "lng": -76.53005,
    "state_id": "MD"
  },
  {
    "zip": 20686,
    "lat": 38.17905,
    "lng": -76.43066,
    "state_id": "MD"
  },
  {
    "zip": 20687,
    "lat": 38.07686,
    "lng": -76.34786,
    "state_id": "MD"
  },
  {
    "zip": 20688,
    "lat": 38.33554,
    "lng": -76.46837,
    "state_id": "MD"
  },
  {
    "zip": 20689,
    "lat": 38.66285,
    "lng": -76.58232,
    "state_id": "MD"
  },
  {
    "zip": 20690,
    "lat": 38.16183,
    "lng": -76.52846,
    "state_id": "MD"
  },
  {
    "zip": 20692,
    "lat": 38.18457,
    "lng": -76.50071,
    "state_id": "MD"
  },
  {
    "zip": 20693,
    "lat": 38.45927,
    "lng": -77.08935,
    "state_id": "MD"
  },
  {
    "zip": 20695,
    "lat": 38.59134,
    "lng": -76.97331,
    "state_id": "MD"
  },
  {
    "zip": 20701,
    "lat": 39.12627,
    "lng": -76.78423,
    "state_id": "MD"
  },
  {
    "zip": 20705,
    "lat": 39.04801,
    "lng": -76.90127,
    "state_id": "MD"
  },
  {
    "zip": 20706,
    "lat": 38.96561,
    "lng": -76.85094,
    "state_id": "MD"
  },
  {
    "zip": 20707,
    "lat": 39.09805,
    "lng": -76.88178,
    "state_id": "MD"
  },
  {
    "zip": 20708,
    "lat": 39.04654,
    "lng": -76.82659,
    "state_id": "MD"
  },
  {
    "zip": 20710,
    "lat": 38.94206,
    "lng": -76.92617,
    "state_id": "MD"
  },
  {
    "zip": 20711,
    "lat": 38.80509,
    "lng": -76.65199,
    "state_id": "MD"
  },
  {
    "zip": 20712,
    "lat": 38.94318,
    "lng": -76.96522,
    "state_id": "MD"
  },
  {
    "zip": 20714,
    "lat": 38.72222,
    "lng": -76.54613,
    "state_id": "MD"
  },
  {
    "zip": 20715,
    "lat": 38.98827,
    "lng": -76.7401,
    "state_id": "MD"
  },
  {
    "zip": 20716,
    "lat": 38.92723,
    "lng": -76.71504,
    "state_id": "MD"
  },
  {
    "zip": 20720,
    "lat": 38.98227,
    "lng": -76.7827,
    "state_id": "MD"
  },
  {
    "zip": 20721,
    "lat": 38.92108,
    "lng": -76.78878,
    "state_id": "MD"
  },
  {
    "zip": 20722,
    "lat": 38.93512,
    "lng": -76.94943,
    "state_id": "MD"
  },
  {
    "zip": 20723,
    "lat": 39.13694,
    "lng": -76.86662,
    "state_id": "MD"
  },
  {
    "zip": 20724,
    "lat": 39.1011,
    "lng": -76.80393,
    "state_id": "MD"
  },
  {
    "zip": 20732,
    "lat": 38.65846,
    "lng": -76.54154,
    "state_id": "MD"
  },
  {
    "zip": 20733,
    "lat": 38.80516,
    "lng": -76.53302,
    "state_id": "MD"
  },
  {
    "zip": 20735,
    "lat": 38.75018,
    "lng": -76.90582,
    "state_id": "MD"
  },
  {
    "zip": 20736,
    "lat": 38.69041,
    "lng": -76.62805,
    "state_id": "MD"
  },
  {
    "zip": 20737,
    "lat": 38.96508,
    "lng": -76.9136,
    "state_id": "MD"
  },
  {
    "zip": 20740,
    "lat": 39.00274,
    "lng": -76.93095,
    "state_id": "MD"
  },
  {
    "zip": 20742,
    "lat": 38.98958,
    "lng": -76.9456,
    "state_id": "MD"
  },
  {
    "zip": 20743,
    "lat": 38.8839,
    "lng": -76.89333,
    "state_id": "MD"
  },
  {
    "zip": 20744,
    "lat": 38.75402,
    "lng": -76.98404,
    "state_id": "MD"
  },
  {
    "zip": 20745,
    "lat": 38.80649,
    "lng": -76.99426,
    "state_id": "MD"
  },
  {
    "zip": 20746,
    "lat": 38.83678,
    "lng": -76.91808,
    "state_id": "MD"
  },
  {
    "zip": 20747,
    "lat": 38.85441,
    "lng": -76.88383,
    "state_id": "MD"
  },
  {
    "zip": 20748,
    "lat": 38.81687,
    "lng": -76.94058,
    "state_id": "MD"
  },
  {
    "zip": 20751,
    "lat": 38.79605,
    "lng": -76.56104,
    "state_id": "MD"
  },
  {
    "zip": 20754,
    "lat": 38.73661,
    "lng": -76.65282,
    "state_id": "MD"
  },
  {
    "zip": 20755,
    "lat": 39.10578,
    "lng": -76.74679,
    "state_id": "MD"
  },
  {
    "zip": 20758,
    "lat": 38.73334,
    "lng": -76.59306,
    "state_id": "MD"
  },
  {
    "zip": 20759,
    "lat": 39.1548,
    "lng": -76.92854,
    "state_id": "MD"
  },
  {
    "zip": 20762,
    "lat": 38.80731,
    "lng": -76.87482,
    "state_id": "MD"
  },
  {
    "zip": 20763,
    "lat": 39.13501,
    "lng": -76.81667,
    "state_id": "MD"
  },
  {
    "zip": 20764,
    "lat": 38.83375,
    "lng": -76.51148,
    "state_id": "MD"
  },
  {
    "zip": 20765,
    "lat": 38.84459,
    "lng": -76.54561,
    "state_id": "MD"
  },
  {
    "zip": 20769,
    "lat": 38.99351,
    "lng": -76.81993,
    "state_id": "MD"
  },
  {
    "zip": 20770,
    "lat": 39.00431,
    "lng": -76.87939,
    "state_id": "MD"
  },
  {
    "zip": 20772,
    "lat": 38.77981,
    "lng": -76.76623,
    "state_id": "MD"
  },
  {
    "zip": 20774,
    "lat": 38.87525,
    "lng": -76.77386,
    "state_id": "MD"
  },
  {
    "zip": 20776,
    "lat": 38.87091,
    "lng": -76.60779,
    "state_id": "MD"
  },
  {
    "zip": 20777,
    "lat": 39.17486,
    "lng": -76.9679,
    "state_id": "MD"
  },
  {
    "zip": 20778,
    "lat": 38.83719,
    "lng": -76.56515,
    "state_id": "MD"
  },
  {
    "zip": 20779,
    "lat": 38.76573,
    "lng": -76.57683,
    "state_id": "MD"
  },
  {
    "zip": 20781,
    "lat": 38.9432,
    "lng": -76.93649,
    "state_id": "MD"
  },
  {
    "zip": 20782,
    "lat": 38.96478,
    "lng": -76.96493,
    "state_id": "MD"
  },
  {
    "zip": 20783,
    "lat": 38.99912,
    "lng": -76.9688,
    "state_id": "MD"
  },
  {
    "zip": 20784,
    "lat": 38.951,
    "lng": -76.89044,
    "state_id": "MD"
  },
  {
    "zip": 20785,
    "lat": 38.91808,
    "lng": -76.88202,
    "state_id": "MD"
  },
  {
    "zip": 20794,
    "lat": 39.15014,
    "lng": -76.78898,
    "state_id": "MD"
  },
  {
    "zip": 20812,
    "lat": 38.96671,
    "lng": -77.14339,
    "state_id": "MD"
  },
  {
    "zip": 20814,
    "lat": 39.00492,
    "lng": -77.10198,
    "state_id": "MD"
  },
  {
    "zip": 20815,
    "lat": 38.98364,
    "lng": -77.07861,
    "state_id": "MD"
  },
  {
    "zip": 20816,
    "lat": 38.95591,
    "lng": -77.11854,
    "state_id": "MD"
  },
  {
    "zip": 20817,
    "lat": 38.99815,
    "lng": -77.14857,
    "state_id": "MD"
  },
  {
    "zip": 20818,
    "lat": 38.97397,
    "lng": -77.16241,
    "state_id": "MD"
  },
  {
    "zip": 20832,
    "lat": 39.15116,
    "lng": -77.07256,
    "state_id": "MD"
  },
  {
    "zip": 20833,
    "lat": 39.20521,
    "lng": -77.05527,
    "state_id": "MD"
  },
  {
    "zip": 20837,
    "lat": 39.1126,
    "lng": -77.40682,
    "state_id": "MD"
  },
  {
    "zip": 20838,
    "lat": 39.22622,
    "lng": -77.37235,
    "state_id": "MD"
  },
  {
    "zip": 20839,
    "lat": 39.18354,
    "lng": -77.42255,
    "state_id": "MD"
  },
  {
    "zip": 20841,
    "lat": 39.19019,
    "lng": -77.32336,
    "state_id": "MD"
  },
  {
    "zip": 20842,
    "lat": 39.21077,
    "lng": -77.42556,
    "state_id": "MD"
  },
  {
    "zip": 20850,
    "lat": 39.09129,
    "lng": -77.18085,
    "state_id": "MD"
  },
  {
    "zip": 20851,
    "lat": 39.0784,
    "lng": -77.12182,
    "state_id": "MD"
  },
  {
    "zip": 20852,
    "lat": 39.0515,
    "lng": -77.12458,
    "state_id": "MD"
  },
  {
    "zip": 20853,
    "lat": 39.10231,
    "lng": -77.09515,
    "state_id": "MD"
  },
  {
    "zip": 20854,
    "lat": 39.03376,
    "lng": -77.22046,
    "state_id": "MD"
  },
  {
    "zip": 20855,
    "lat": 39.13711,
    "lng": -77.13239,
    "state_id": "MD"
  },
  {
    "zip": 20860,
    "lat": 39.14839,
    "lng": -77.02845,
    "state_id": "MD"
  },
  {
    "zip": 20861,
    "lat": 39.15242,
    "lng": -76.99757,
    "state_id": "MD"
  },
  {
    "zip": 20862,
    "lat": 39.18361,
    "lng": -77.01946,
    "state_id": "MD"
  },
  {
    "zip": 20866,
    "lat": 39.10945,
    "lng": -76.93354,
    "state_id": "MD"
  },
  {
    "zip": 20868,
    "lat": 39.12586,
    "lng": -76.96844,
    "state_id": "MD"
  },
  {
    "zip": 20871,
    "lat": 39.26092,
    "lng": -77.28324,
    "state_id": "MD"
  },
  {
    "zip": 20872,
    "lat": 39.29148,
    "lng": -77.21769,
    "state_id": "MD"
  },
  {
    "zip": 20874,
    "lat": 39.12971,
    "lng": -77.2975,
    "state_id": "MD"
  },
  {
    "zip": 20876,
    "lat": 39.20789,
    "lng": -77.23298,
    "state_id": "MD"
  },
  {
    "zip": 20877,
    "lat": 39.14088,
    "lng": -77.18824,
    "state_id": "MD"
  },
  {
    "zip": 20878,
    "lat": 39.11253,
    "lng": -77.25142,
    "state_id": "MD"
  },
  {
    "zip": 20879,
    "lat": 39.16918,
    "lng": -77.17602,
    "state_id": "MD"
  },
  {
    "zip": 20880,
    "lat": 39.13969,
    "lng": -77.17346,
    "state_id": "MD"
  },
  {
    "zip": 20882,
    "lat": 39.23308,
    "lng": -77.14521,
    "state_id": "MD"
  },
  {
    "zip": 20886,
    "lat": 39.1798,
    "lng": -77.19344,
    "state_id": "MD"
  },
  {
    "zip": 20895,
    "lat": 39.02674,
    "lng": -77.07756,
    "state_id": "MD"
  },
  {
    "zip": 20896,
    "lat": 39.03621,
    "lng": -77.09344,
    "state_id": "MD"
  },
  {
    "zip": 20899,
    "lat": 39.14384,
    "lng": -77.2162,
    "state_id": "MD"
  },
  {
    "zip": 20901,
    "lat": 39.02145,
    "lng": -77.00907,
    "state_id": "MD"
  },
  {
    "zip": 20902,
    "lat": 39.04371,
    "lng": -77.0422,
    "state_id": "MD"
  },
  {
    "zip": 20903,
    "lat": 39.0206,
    "lng": -76.98121,
    "state_id": "MD"
  },
  {
    "zip": 20904,
    "lat": 39.06662,
    "lng": -76.97973,
    "state_id": "MD"
  },
  {
    "zip": 20905,
    "lat": 39.11195,
    "lng": -76.99291,
    "state_id": "MD"
  },
  {
    "zip": 20906,
    "lat": 39.0867,
    "lng": -77.05664,
    "state_id": "MD"
  },
  {
    "zip": 20910,
    "lat": 39.0022,
    "lng": -77.03637,
    "state_id": "MD"
  },
  {
    "zip": 20912,
    "lat": 38.9822,
    "lng": -77.00124,
    "state_id": "MD"
  },
  {
    "zip": 21001,
    "lat": 39.51073,
    "lng": -76.2006,
    "state_id": "MD"
  },
  {
    "zip": 21005,
    "lat": 39.47415,
    "lng": -76.11891,
    "state_id": "MD"
  },
  {
    "zip": 21009,
    "lat": 39.47051,
    "lng": -76.29409,
    "state_id": "MD"
  },
  {
    "zip": 21010,
    "lat": 39.3898,
    "lng": -76.29092,
    "state_id": "MD"
  },
  {
    "zip": 21012,
    "lat": 39.04528,
    "lng": -76.49706,
    "state_id": "MD"
  },
  {
    "zip": 21013,
    "lat": 39.50906,
    "lng": -76.48647,
    "state_id": "MD"
  },
  {
    "zip": 21014,
    "lat": 39.53663,
    "lng": -76.35169,
    "state_id": "MD"
  },
  {
    "zip": 21015,
    "lat": 39.54681,
    "lng": -76.29604,
    "state_id": "MD"
  },
  {
    "zip": 21017,
    "lat": 39.47622,
    "lng": -76.23691,
    "state_id": "MD"
  },
  {
    "zip": 21028,
    "lat": 39.56797,
    "lng": -76.24245,
    "state_id": "MD"
  },
  {
    "zip": 21029,
    "lat": 39.20559,
    "lng": -76.95299,
    "state_id": "MD"
  },
  {
    "zip": 21030,
    "lat": 39.4919,
    "lng": -76.66767,
    "state_id": "MD"
  },
  {
    "zip": 21031,
    "lat": 39.48719,
    "lng": -76.65794,
    "state_id": "MD"
  },
  {
    "zip": 21032,
    "lat": 39.03376,
    "lng": -76.60411,
    "state_id": "MD"
  },
  {
    "zip": 21034,
    "lat": 39.65065,
    "lng": -76.22116,
    "state_id": "MD"
  },
  {
    "zip": 21035,
    "lat": 38.93689,
    "lng": -76.63735,
    "state_id": "MD"
  },
  {
    "zip": 21036,
    "lat": 39.23372,
    "lng": -77.00258,
    "state_id": "MD"
  },
  {
    "zip": 21037,
    "lat": 38.91546,
    "lng": -76.54423,
    "state_id": "MD"
  },
  {
    "zip": 21040,
    "lat": 39.43315,
    "lng": -76.29515,
    "state_id": "MD"
  },
  {
    "zip": 21042,
    "lat": 39.2703,
    "lng": -76.89282,
    "state_id": "MD"
  },
  {
    "zip": 21043,
    "lat": 39.25753,
    "lng": -76.7979,
    "state_id": "MD"
  },
  {
    "zip": 21044,
    "lat": 39.21033,
    "lng": -76.88381,
    "state_id": "MD"
  },
  {
    "zip": 21045,
    "lat": 39.20617,
    "lng": -76.82747,
    "state_id": "MD"
  },
  {
    "zip": 21046,
    "lat": 39.17356,
    "lng": -76.84188,
    "state_id": "MD"
  },
  {
    "zip": 21047,
    "lat": 39.52804,
    "lng": -76.43925,
    "state_id": "MD"
  },
  {
    "zip": 21048,
    "lat": 39.49525,
    "lng": -76.91035,
    "state_id": "MD"
  },
  {
    "zip": 21050,
    "lat": 39.5857,
    "lng": -76.39227,
    "state_id": "MD"
  },
  {
    "zip": 21051,
    "lat": 39.47209,
    "lng": -76.45401,
    "state_id": "MD"
  },
  {
    "zip": 21052,
    "lat": 39.20675,
    "lng": -76.44568,
    "state_id": "MD"
  },
  {
    "zip": 21053,
    "lat": 39.69257,
    "lng": -76.71561,
    "state_id": "MD"
  },
  {
    "zip": 21054,
    "lat": 39.02176,
    "lng": -76.67326,
    "state_id": "MD"
  },
  {
    "zip": 21056,
    "lat": 39.07602,
    "lng": -76.44033,
    "state_id": "MD"
  },
  {
    "zip": 21057,
    "lat": 39.45048,
    "lng": -76.5065,
    "state_id": "MD"
  },
  {
    "zip": 21060,
    "lat": 39.16798,
    "lng": -76.58374,
    "state_id": "MD"
  },
  {
    "zip": 21061,
    "lat": 39.16177,
    "lng": -76.62973,
    "state_id": "MD"
  },
  {
    "zip": 21071,
    "lat": 39.48165,
    "lng": -76.81091,
    "state_id": "MD"
  },
  {
    "zip": 21074,
    "lat": 39.61738,
    "lng": -76.8436,
    "state_id": "MD"
  },
  {
    "zip": 21075,
    "lat": 39.2035,
    "lng": -76.75183,
    "state_id": "MD"
  },
  {
    "zip": 21076,
    "lat": 39.16683,
    "lng": -76.72159,
    "state_id": "MD"
  },
  {
    "zip": 21077,
    "lat": 39.15605,
    "lng": -76.69762,
    "state_id": "MD"
  },
  {
    "zip": 21078,
    "lat": 39.56949,
    "lng": -76.14799,
    "state_id": "MD"
  },
  {
    "zip": 21082,
    "lat": 39.47922,
    "lng": -76.47715,
    "state_id": "MD"
  },
  {
    "zip": 21084,
    "lat": 39.61638,
    "lng": -76.46409,
    "state_id": "MD"
  },
  {
    "zip": 21085,
    "lat": 39.44788,
    "lng": -76.35469,
    "state_id": "MD"
  },
  {
    "zip": 21087,
    "lat": 39.44771,
    "lng": -76.4157,
    "state_id": "MD"
  },
  {
    "zip": 21090,
    "lat": 39.20923,
    "lng": -76.66808,
    "state_id": "MD"
  },
  {
    "zip": 21093,
    "lat": 39.43979,
    "lng": -76.64087,
    "state_id": "MD"
  },
  {
    "zip": 21102,
    "lat": 39.68683,
    "lng": -76.84669,
    "state_id": "MD"
  },
  {
    "zip": 21104,
    "lat": 39.34962,
    "lng": -76.90652,
    "state_id": "MD"
  },
  {
    "zip": 21105,
    "lat": 39.71395,
    "lng": -76.65127,
    "state_id": "MD"
  },
  {
    "zip": 21108,
    "lat": 39.08957,
    "lng": -76.62208,
    "state_id": "MD"
  },
  {
    "zip": 21111,
    "lat": 39.57727,
    "lng": -76.5818,
    "state_id": "MD"
  },
  {
    "zip": 21113,
    "lat": 39.0536,
    "lng": -76.71657,
    "state_id": "MD"
  },
  {
    "zip": 21114,
    "lat": 39.00961,
    "lng": -76.68423,
    "state_id": "MD"
  },
  {
    "zip": 21117,
    "lat": 39.4271,
    "lng": -76.77713,
    "state_id": "MD"
  },
  {
    "zip": 21120,
    "lat": 39.64496,
    "lng": -76.6789,
    "state_id": "MD"
  },
  {
    "zip": 21122,
    "lat": 39.11839,
    "lng": -76.50162,
    "state_id": "MD"
  },
  {
    "zip": 21128,
    "lat": 39.40774,
    "lng": -76.4462,
    "state_id": "MD"
  },
  {
    "zip": 21130,
    "lat": 39.47741,
    "lng": -76.19413,
    "state_id": "MD"
  },
  {
    "zip": 21131,
    "lat": 39.50467,
    "lng": -76.57373,
    "state_id": "MD"
  },
  {
    "zip": 21132,
    "lat": 39.69604,
    "lng": -76.42926,
    "state_id": "MD"
  },
  {
    "zip": 21133,
    "lat": 39.37477,
    "lng": -76.81245,
    "state_id": "MD"
  },
  {
    "zip": 21136,
    "lat": 39.48903,
    "lng": -76.80848,
    "state_id": "MD"
  },
  {
    "zip": 21140,
    "lat": 38.95025,
    "lng": -76.58489,
    "state_id": "MD"
  },
  {
    "zip": 21144,
    "lat": 39.12091,
    "lng": -76.67733,
    "state_id": "MD"
  },
  {
    "zip": 21146,
    "lat": 39.07758,
    "lng": -76.55665,
    "state_id": "MD"
  },
  {
    "zip": 21152,
    "lat": 39.54827,
    "lng": -76.68189,
    "state_id": "MD"
  },
  {
    "zip": 21153,
    "lat": 39.41272,
    "lng": -76.70802,
    "state_id": "MD"
  },
  {
    "zip": 21154,
    "lat": 39.65224,
    "lng": -76.35617,
    "state_id": "MD"
  },
  {
    "zip": 21155,
    "lat": 39.57111,
    "lng": -76.80589,
    "state_id": "MD"
  },
  {
    "zip": 21156,
    "lat": 39.43726,
    "lng": -76.39653,
    "state_id": "MD"
  },
  {
    "zip": 21157,
    "lat": 39.54936,
    "lng": -76.9808,
    "state_id": "MD"
  },
  {
    "zip": 21158,
    "lat": 39.64671,
    "lng": -77.03251,
    "state_id": "MD"
  },
  {
    "zip": 21160,
    "lat": 39.70667,
    "lng": -76.30982,
    "state_id": "MD"
  },
  {
    "zip": 21161,
    "lat": 39.66042,
    "lng": -76.5654,
    "state_id": "MD"
  },
  {
    "zip": 21162,
    "lat": 39.39001,
    "lng": -76.40381,
    "state_id": "MD"
  },
  {
    "zip": 21163,
    "lat": 39.33972,
    "lng": -76.85618,
    "state_id": "MD"
  },
  {
    "zip": 21201,
    "lat": 39.29479,
    "lng": -76.6222,
    "state_id": "MD"
  },
  {
    "zip": 21202,
    "lat": 39.29646,
    "lng": -76.60745,
    "state_id": "MD"
  },
  {
    "zip": 21204,
    "lat": 39.40257,
    "lng": -76.63262,
    "state_id": "MD"
  },
  {
    "zip": 21205,
    "lat": 39.3023,
    "lng": -76.56447,
    "state_id": "MD"
  },
  {
    "zip": 21206,
    "lat": 39.33929,
    "lng": -76.53675,
    "state_id": "MD"
  },
  {
    "zip": 21207,
    "lat": 39.32424,
    "lng": -76.72038,
    "state_id": "MD"
  },
  {
    "zip": 21208,
    "lat": 39.38482,
    "lng": -76.72439,
    "state_id": "MD"
  },
  {
    "zip": 21209,
    "lat": 39.3739,
    "lng": -76.6696,
    "state_id": "MD"
  },
  {
    "zip": 21210,
    "lat": 39.3582,
    "lng": -76.63439,
    "state_id": "MD"
  },
  {
    "zip": 21211,
    "lat": 39.32995,
    "lng": -76.63937,
    "state_id": "MD"
  },
  {
    "zip": 21212,
    "lat": 39.36827,
    "lng": -76.61508,
    "state_id": "MD"
  },
  {
    "zip": 21213,
    "lat": 39.31517,
    "lng": -76.5772,
    "state_id": "MD"
  },
  {
    "zip": 21214,
    "lat": 39.35177,
    "lng": -76.56446,
    "state_id": "MD"
  },
  {
    "zip": 21215,
    "lat": 39.34555,
    "lng": -76.68369,
    "state_id": "MD"
  },
  {
    "zip": 21216,
    "lat": 39.31104,
    "lng": -76.67206,
    "state_id": "MD"
  },
  {
    "zip": 21217,
    "lat": 39.30814,
    "lng": -76.63857,
    "state_id": "MD"
  },
  {
    "zip": 21218,
    "lat": 39.32997,
    "lng": -76.60268,
    "state_id": "MD"
  },
  {
    "zip": 21219,
    "lat": 39.22998,
    "lng": -76.45036,
    "state_id": "MD"
  },
  {
    "zip": 21220,
    "lat": 39.34515,
    "lng": -76.39765,
    "state_id": "MD"
  },
  {
    "zip": 21221,
    "lat": 39.2942,
    "lng": -76.43967,
    "state_id": "MD"
  },
  {
    "zip": 21222,
    "lat": 39.26558,
    "lng": -76.49337,
    "state_id": "MD"
  },
  {
    "zip": 21223,
    "lat": 39.28427,
    "lng": -76.65323,
    "state_id": "MD"
  },
  {
    "zip": 21224,
    "lat": 39.28184,
    "lng": -76.54131,
    "state_id": "MD"
  },
  {
    "zip": 21225,
    "lat": 39.22592,
    "lng": -76.61527,
    "state_id": "MD"
  },
  {
    "zip": 21226,
    "lat": 39.20714,
    "lng": -76.56282,
    "state_id": "MD"
  },
  {
    "zip": 21227,
    "lat": 39.24046,
    "lng": -76.67938,
    "state_id": "MD"
  },
  {
    "zip": 21228,
    "lat": 39.27264,
    "lng": -76.7474,
    "state_id": "MD"
  },
  {
    "zip": 21229,
    "lat": 39.28514,
    "lng": -76.69078,
    "state_id": "MD"
  },
  {
    "zip": 21230,
    "lat": 39.2663,
    "lng": -76.62243,
    "state_id": "MD"
  },
  {
    "zip": 21231,
    "lat": 39.2871,
    "lng": -76.59238,
    "state_id": "MD"
  },
  {
    "zip": 21234,
    "lat": 39.39346,
    "lng": -76.53387,
    "state_id": "MD"
  },
  {
    "zip": 21236,
    "lat": 39.3884,
    "lng": -76.48606,
    "state_id": "MD"
  },
  {
    "zip": 21237,
    "lat": 39.33918,
    "lng": -76.4952,
    "state_id": "MD"
  },
  {
    "zip": 21239,
    "lat": 39.36652,
    "lng": -76.5873,
    "state_id": "MD"
  },
  {
    "zip": 21240,
    "lat": 39.17533,
    "lng": -76.67323,
    "state_id": "MD"
  },
  {
    "zip": 21244,
    "lat": 39.33303,
    "lng": -76.78034,
    "state_id": "MD"
  },
  {
    "zip": 21250,
    "lat": 39.25557,
    "lng": -76.71117,
    "state_id": "MD"
  },
  {
    "zip": 21251,
    "lat": 39.34725,
    "lng": -76.58072,
    "state_id": "MD"
  },
  {
    "zip": 21252,
    "lat": 39.39388,
    "lng": -76.6078,
    "state_id": "MD"
  },
  {
    "zip": 21286,
    "lat": 39.41507,
    "lng": -76.57441,
    "state_id": "MD"
  },
  {
    "zip": 21401,
    "lat": 38.9898,
    "lng": -76.55009,
    "state_id": "MD"
  },
  {
    "zip": 21402,
    "lat": 38.98821,
    "lng": -76.47251,
    "state_id": "MD"
  },
  {
    "zip": 21403,
    "lat": 38.94404,
    "lng": -76.49254,
    "state_id": "MD"
  },
  {
    "zip": 21405,
    "lat": 39.0305,
    "lng": -76.55147,
    "state_id": "MD"
  },
  {
    "zip": 21409,
    "lat": 39.01965,
    "lng": -76.44746,
    "state_id": "MD"
  },
  {
    "zip": 21502,
    "lat": 39.64405,
    "lng": -78.75494,
    "state_id": "MD"
  },
  {
    "zip": 21520,
    "lat": 39.62474,
    "lng": -79.3015,
    "state_id": "MD"
  },
  {
    "zip": 21521,
    "lat": 39.54602,
    "lng": -79.04045,
    "state_id": "MD"
  },
  {
    "zip": 21522,
    "lat": 39.61027,
    "lng": -79.22778,
    "state_id": "MD"
  },
  {
    "zip": 21523,
    "lat": 39.4799,
    "lng": -79.0792,
    "state_id": "MD"
  },
  {
    "zip": 21524,
    "lat": 39.70761,
    "lng": -78.80141,
    "state_id": "MD"
  },
  {
    "zip": 21529,
    "lat": 39.70616,
    "lng": -78.76852,
    "state_id": "MD"
  },
  {
    "zip": 21530,
    "lat": 39.68927,
    "lng": -78.53663,
    "state_id": "MD"
  },
  {
    "zip": 21531,
    "lat": 39.64832,
    "lng": -79.42139,
    "state_id": "MD"
  },
  {
    "zip": 21532,
    "lat": 39.656,
    "lng": -78.95941,
    "state_id": "MD"
  },
  {
    "zip": 21536,
    "lat": 39.65644,
    "lng": -79.16853,
    "state_id": "MD"
  },
  {
    "zip": 21538,
    "lat": 39.40132,
    "lng": -79.2211,
    "state_id": "MD"
  },
  {
    "zip": 21539,
    "lat": 39.59921,
    "lng": -79.01341,
    "state_id": "MD"
  },
  {
    "zip": 21540,
    "lat": 39.47664,
    "lng": -79.0594,
    "state_id": "MD"
  },
  {
    "zip": 21541,
    "lat": 39.55454,
    "lng": -79.36819,
    "state_id": "MD"
  },
  {
    "zip": 21542,
    "lat": 39.59605,
    "lng": -78.94987,
    "state_id": "MD"
  },
  {
    "zip": 21543,
    "lat": 39.64929,
    "lng": -78.95969,
    "state_id": "MD"
  },
  {
    "zip": 21545,
    "lat": 39.70093,
    "lng": -78.86373,
    "state_id": "MD"
  },
  {
    "zip": 21550,
    "lat": 39.38906,
    "lng": -79.39092,
    "state_id": "MD"
  },
  {
    "zip": 21555,
    "lat": 39.58935,
    "lng": -78.55658,
    "state_id": "MD"
  },
  {
    "zip": 21557,
    "lat": 39.5194,
    "lng": -78.92287,
    "state_id": "MD"
  },
  {
    "zip": 21561,
    "lat": 39.49464,
    "lng": -79.20056,
    "state_id": "MD"
  },
  {
    "zip": 21562,
    "lat": 39.50344,
    "lng": -79.05051,
    "state_id": "MD"
  },
  {
    "zip": 21601,
    "lat": 38.79377,
    "lng": -76.08178,
    "state_id": "MD"
  },
  {
    "zip": 21607,
    "lat": 39.13272,
    "lng": -75.85418,
    "state_id": "MD"
  },
  {
    "zip": 21610,
    "lat": 39.36486,
    "lng": -76.07909,
    "state_id": "MD"
  },
  {
    "zip": 21612,
    "lat": 38.75264,
    "lng": -76.27533,
    "state_id": "MD"
  },
  {
    "zip": 21613,
    "lat": 38.49315,
    "lng": -76.07341,
    "state_id": "MD"
  },
  {
    "zip": 21617,
    "lat": 39.04974,
    "lng": -76.03974,
    "state_id": "MD"
  },
  {
    "zip": 21619,
    "lat": 38.9528,
    "lng": -76.27937,
    "state_id": "MD"
  },
  {
    "zip": 21620,
    "lat": 39.2092,
    "lng": -76.09187,
    "state_id": "MD"
  },
  {
    "zip": 21622,
    "lat": 38.40785,
    "lng": -76.17532,
    "state_id": "MD"
  },
  {
    "zip": 21623,
    "lat": 39.12272,
    "lng": -75.96238,
    "state_id": "MD"
  },
  {
    "zip": 21624,
    "lat": 38.84261,
    "lng": -76.267,
    "state_id": "MD"
  },
  {
    "zip": 21625,
    "lat": 38.8734,
    "lng": -75.99634,
    "state_id": "MD"
  },
  {
    "zip": 21626,
    "lat": 38.33747,
    "lng": -76.09292,
    "state_id": "MD"
  },
  {
    "zip": 21627,
    "lat": 38.23271,
    "lng": -76.04837,
    "state_id": "MD"
  },
  {
    "zip": 21628,
    "lat": 39.23665,
    "lng": -75.91881,
    "state_id": "MD"
  },
  {
    "zip": 21629,
    "lat": 38.86273,
    "lng": -75.82792,
    "state_id": "MD"
  },
  {
    "zip": 21631,
    "lat": 38.58627,
    "lng": -75.94549,
    "state_id": "MD"
  },
  {
    "zip": 21632,
    "lat": 38.7275,
    "lng": -75.77367,
    "state_id": "MD"
  },
  {
    "zip": 21634,
    "lat": 38.28382,
    "lng": -76.18745,
    "state_id": "MD"
  },
  {
    "zip": 21635,
    "lat": 39.33861,
    "lng": -75.84055,
    "state_id": "MD"
  },
  {
    "zip": 21636,
    "lat": 39.02408,
    "lng": -75.80476,
    "state_id": "MD"
  },
  {
    "zip": 21638,
    "lat": 38.94479,
    "lng": -76.2027,
    "state_id": "MD"
  },
  {
    "zip": 21639,
    "lat": 38.96569,
    "lng": -75.79355,
    "state_id": "MD"
  },
  {
    "zip": 21640,
    "lat": 39.06952,
    "lng": -75.81872,
    "state_id": "MD"
  },
  {
    "zip": 21641,
    "lat": 38.91776,
    "lng": -75.9422,
    "state_id": "MD"
  },
  {
    "zip": 21643,
    "lat": 38.64781,
    "lng": -75.86998,
    "state_id": "MD"
  },
  {
    "zip": 21644,
    "lat": 39.11243,
    "lng": -75.87355,
    "state_id": "MD"
  },
  {
    "zip": 21645,
    "lat": 39.3226,
    "lng": -75.96461,
    "state_id": "MD"
  },
  {
    "zip": 21647,
    "lat": 38.81548,
    "lng": -76.28035,
    "state_id": "MD"
  },
  {
    "zip": 21648,
    "lat": 38.4836,
    "lng": -76.23009,
    "state_id": "MD"
  },
  {
    "zip": 21649,
    "lat": 39.1345,
    "lng": -75.77017,
    "state_id": "MD"
  },
  {
    "zip": 21650,
    "lat": 39.31535,
    "lng": -75.80964,
    "state_id": "MD"
  },
  {
    "zip": 21651,
    "lat": 39.25903,
    "lng": -75.8534,
    "state_id": "MD"
  },
  {
    "zip": 21652,
    "lat": 38.72083,
    "lng": -76.28287,
    "state_id": "MD"
  },
  {
    "zip": 21653,
    "lat": 38.75105,
    "lng": -76.17852,
    "state_id": "MD"
  },
  {
    "zip": 21654,
    "lat": 38.69003,
    "lng": -76.1258,
    "state_id": "MD"
  },
  {
    "zip": 21655,
    "lat": 38.74525,
    "lng": -75.92081,
    "state_id": "MD"
  },
  {
    "zip": 21657,
    "lat": 38.95766,
    "lng": -75.98489,
    "state_id": "MD"
  },
  {
    "zip": 21658,
    "lat": 38.94338,
    "lng": -76.1373,
    "state_id": "MD"
  },
  {
    "zip": 21659,
    "lat": 38.58326,
    "lng": -75.78187,
    "state_id": "MD"
  },
  {
    "zip": 21660,
    "lat": 38.95595,
    "lng": -75.89037,
    "state_id": "MD"
  },
  {
    "zip": 21661,
    "lat": 39.12803,
    "lng": -76.22014,
    "state_id": "MD"
  },
  {
    "zip": 21662,
    "lat": 38.71249,
    "lng": -76.20473,
    "state_id": "MD"
  },
  {
    "zip": 21663,
    "lat": 38.78478,
    "lng": -76.2312,
    "state_id": "MD"
  },
  {
    "zip": 21664,
    "lat": 38.60704,
    "lng": -75.94526,
    "state_id": "MD"
  },
  {
    "zip": 21665,
    "lat": 38.7515,
    "lng": -76.32291,
    "state_id": "MD"
  },
  {
    "zip": 21666,
    "lat": 38.93959,
    "lng": -76.33268,
    "state_id": "MD"
  },
  {
    "zip": 21667,
    "lat": 39.34472,
    "lng": -76.05404,
    "state_id": "MD"
  },
  {
    "zip": 21668,
    "lat": 39.19032,
    "lng": -75.85267,
    "state_id": "MD"
  },
  {
    "zip": 21669,
    "lat": 38.45996,
    "lng": -76.28926,
    "state_id": "MD"
  },
  {
    "zip": 21671,
    "lat": 38.69644,
    "lng": -76.33563,
    "state_id": "MD"
  },
  {
    "zip": 21672,
    "lat": 38.27684,
    "lng": -76.06081,
    "state_id": "MD"
  },
  {
    "zip": 21673,
    "lat": 38.65194,
    "lng": -76.04074,
    "state_id": "MD"
  },
  {
    "zip": 21675,
    "lat": 38.28791,
    "lng": -76.08691,
    "state_id": "MD"
  },
  {
    "zip": 21676,
    "lat": 38.78063,
    "lng": -76.32204,
    "state_id": "MD"
  },
  {
    "zip": 21677,
    "lat": 38.48674,
    "lng": -76.18396,
    "state_id": "MD"
  },
  {
    "zip": 21678,
    "lat": 39.30899,
    "lng": -76.09726,
    "state_id": "MD"
  },
  {
    "zip": 21679,
    "lat": 38.91758,
    "lng": -76.08466,
    "state_id": "MD"
  },
  {
    "zip": 21701,
    "lat": 39.44605,
    "lng": -77.33495,
    "state_id": "MD"
  },
  {
    "zip": 21702,
    "lat": 39.47869,
    "lng": -77.4566,
    "state_id": "MD"
  },
  {
    "zip": 21703,
    "lat": 39.36778,
    "lng": -77.46958,
    "state_id": "MD"
  },
  {
    "zip": 21704,
    "lat": 39.35404,
    "lng": -77.37499,
    "state_id": "MD"
  },
  {
    "zip": 21705,
    "lat": 39.40889,
    "lng": -77.41004,
    "state_id": "MD"
  },
  {
    "zip": 21710,
    "lat": 39.29815,
    "lng": -77.44959,
    "state_id": "MD"
  },
  {
    "zip": 21711,
    "lat": 39.66692,
    "lng": -78.01628,
    "state_id": "MD"
  },
  {
    "zip": 21713,
    "lat": 39.52485,
    "lng": -77.671,
    "state_id": "MD"
  },
  {
    "zip": 21714,
    "lat": 39.41915,
    "lng": -77.50467,
    "state_id": "MD"
  },
  {
    "zip": 21716,
    "lat": 39.31459,
    "lng": -77.6202,
    "state_id": "MD"
  },
  {
    "zip": 21717,
    "lat": 39.33701,
    "lng": -77.43642,
    "state_id": "MD"
  },
  {
    "zip": 21718,
    "lat": 39.39199,
    "lng": -77.62728,
    "state_id": "MD"
  },
  {
    "zip": 21719,
    "lat": 39.70738,
    "lng": -77.49664,
    "state_id": "MD"
  },
  {
    "zip": 21722,
    "lat": 39.66595,
    "lng": -77.91719,
    "state_id": "MD"
  },
  {
    "zip": 21723,
    "lat": 39.32541,
    "lng": -77.01046,
    "state_id": "MD"
  },
  {
    "zip": 21727,
    "lat": 39.68904,
    "lng": -77.32726,
    "state_id": "MD"
  },
  {
    "zip": 21733,
    "lat": 39.54932,
    "lng": -77.75848,
    "state_id": "MD"
  },
  {
    "zip": 21734,
    "lat": 39.60769,
    "lng": -77.70802,
    "state_id": "MD"
  },
  {
    "zip": 21737,
    "lat": 39.25463,
    "lng": -77.01982,
    "state_id": "MD"
  },
  {
    "zip": 21738,
    "lat": 39.28093,
    "lng": -77.02587,
    "state_id": "MD"
  },
  {
    "zip": 21740,
    "lat": 39.63401,
    "lng": -77.74244,
    "state_id": "MD"
  },
  {
    "zip": 21742,
    "lat": 39.66977,
    "lng": -77.65638,
    "state_id": "MD"
  },
  {
    "zip": 21746,
    "lat": 39.56503,
    "lng": -77.71134,
    "state_id": "MD"
  },
  {
    "zip": 21750,
    "lat": 39.68671,
    "lng": -78.21632,
    "state_id": "MD"
  },
  {
    "zip": 21754,
    "lat": 39.33486,
    "lng": -77.31223,
    "state_id": "MD"
  },
  {
    "zip": 21755,
    "lat": 39.36054,
    "lng": -77.56701,
    "state_id": "MD"
  },
  {
    "zip": 21756,
    "lat": 39.45217,
    "lng": -77.70062,
    "state_id": "MD"
  },
  {
    "zip": 21757,
    "lat": 39.59986,
    "lng": -77.25679,
    "state_id": "MD"
  },
  {
    "zip": 21758,
    "lat": 39.34814,
    "lng": -77.66498,
    "state_id": "MD"
  },
  {
    "zip": 21762,
    "lat": 39.48088,
    "lng": -77.24711,
    "state_id": "MD"
  },
  {
    "zip": 21766,
    "lat": 39.66145,
    "lng": -78.39274,
    "state_id": "MD"
  },
  {
    "zip": 21767,
    "lat": 39.69722,
    "lng": -77.74729,
    "state_id": "MD"
  },
  {
    "zip": 21769,
    "lat": 39.44498,
    "lng": -77.56552,
    "state_id": "MD"
  },
  {
    "zip": 21770,
    "lat": 39.35175,
    "lng": -77.25681,
    "state_id": "MD"
  },
  {
    "zip": 21771,
    "lat": 39.3935,
    "lng": -77.15689,
    "state_id": "MD"
  },
  {
    "zip": 21773,
    "lat": 39.53908,
    "lng": -77.55176,
    "state_id": "MD"
  },
  {
    "zip": 21774,
    "lat": 39.40962,
    "lng": -77.27592,
    "state_id": "MD"
  },
  {
    "zip": 21776,
    "lat": 39.51621,
    "lng": -77.10408,
    "state_id": "MD"
  },
  {
    "zip": 21777,
    "lat": 39.2682,
    "lng": -77.52073,
    "state_id": "MD"
  },
  {
    "zip": 21778,
    "lat": 39.61601,
    "lng": -77.33581,
    "state_id": "MD"
  },
  {
    "zip": 21779,
    "lat": 39.42689,
    "lng": -77.65162,
    "state_id": "MD"
  },
  {
    "zip": 21780,
    "lat": 39.67672,
    "lng": -77.4652,
    "state_id": "MD"
  },
  {
    "zip": 21781,
    "lat": 39.57109,
    "lng": -77.75967,
    "state_id": "MD"
  },
  {
    "zip": 21782,
    "lat": 39.45711,
    "lng": -77.7615,
    "state_id": "MD"
  },
  {
    "zip": 21783,
    "lat": 39.65159,
    "lng": -77.55503,
    "state_id": "MD"
  },
  {
    "zip": 21784,
    "lat": 39.40106,
    "lng": -76.97365,
    "state_id": "MD"
  },
  {
    "zip": 21787,
    "lat": 39.67332,
    "lng": -77.1698,
    "state_id": "MD"
  },
  {
    "zip": 21788,
    "lat": 39.59335,
    "lng": -77.41901,
    "state_id": "MD"
  },
  {
    "zip": 21790,
    "lat": 39.2618,
    "lng": -77.49001,
    "state_id": "MD"
  },
  {
    "zip": 21791,
    "lat": 39.53988,
    "lng": -77.18726,
    "state_id": "MD"
  },
  {
    "zip": 21793,
    "lat": 39.49104,
    "lng": -77.34474,
    "state_id": "MD"
  },
  {
    "zip": 21794,
    "lat": 39.29664,
    "lng": -76.9727,
    "state_id": "MD"
  },
  {
    "zip": 21795,
    "lat": 39.58216,
    "lng": -77.82589,
    "state_id": "MD"
  },
  {
    "zip": 21797,
    "lat": 39.3336,
    "lng": -77.06992,
    "state_id": "MD"
  },
  {
    "zip": 21798,
    "lat": 39.53657,
    "lng": -77.30129,
    "state_id": "MD"
  },
  {
    "zip": 21801,
    "lat": 38.38055,
    "lng": -75.64148,
    "state_id": "MD"
  },
  {
    "zip": 21802,
    "lat": 38.3447,
    "lng": -75.58257,
    "state_id": "MD"
  },
  {
    "zip": 21804,
    "lat": 38.31275,
    "lng": -75.53322,
    "state_id": "MD"
  },
  {
    "zip": 21810,
    "lat": 38.29821,
    "lng": -75.70992,
    "state_id": "MD"
  },
  {
    "zip": 21811,
    "lat": 38.32022,
    "lng": -75.21805,
    "state_id": "MD"
  },
  {
    "zip": 21813,
    "lat": 38.42616,
    "lng": -75.16416,
    "state_id": "MD"
  },
  {
    "zip": 21814,
    "lat": 38.29207,
    "lng": -75.88614,
    "state_id": "MD"
  },
  {
    "zip": 21817,
    "lat": 37.98231,
    "lng": -75.83495,
    "state_id": "MD"
  },
  {
    "zip": 21821,
    "lat": 38.1666,
    "lng": -75.92042,
    "state_id": "MD"
  },
  {
    "zip": 21822,
    "lat": 38.27519,
    "lng": -75.6376,
    "state_id": "MD"
  },
  {
    "zip": 21824,
    "lat": 37.98348,
    "lng": -76.03473,
    "state_id": "MD"
  },
  {
    "zip": 21826,
    "lat": 38.31705,
    "lng": -75.62696,
    "state_id": "MD"
  },
  {
    "zip": 21829,
    "lat": 38.09553,
    "lng": -75.37947,
    "state_id": "MD"
  },
  {
    "zip": 21830,
    "lat": 38.40037,
    "lng": -75.73244,
    "state_id": "MD"
  },
  {
    "zip": 21835,
    "lat": 38.53308,
    "lng": -75.94577,
    "state_id": "MD"
  },
  {
    "zip": 21837,
    "lat": 38.45901,
    "lng": -75.76683,
    "state_id": "MD"
  },
  {
    "zip": 21838,
    "lat": 38.01378,
    "lng": -75.73083,
    "state_id": "MD"
  },
  {
    "zip": 21840,
    "lat": 38.25807,
    "lng": -75.89272,
    "state_id": "MD"
  },
  {
    "zip": 21841,
    "lat": 38.24732,
    "lng": -75.29533,
    "state_id": "MD"
  },
  {
    "zip": 21842,
    "lat": 38.37243,
    "lng": -75.09279,
    "state_id": "MD"
  },
  {
    "zip": 21849,
    "lat": 38.37573,
    "lng": -75.46149,
    "state_id": "MD"
  },
  {
    "zip": 21850,
    "lat": 38.36055,
    "lng": -75.39935,
    "state_id": "MD"
  },
  {
    "zip": 21851,
    "lat": 38.0793,
    "lng": -75.54305,
    "state_id": "MD"
  },
  {
    "zip": 21853,
    "lat": 38.20319,
    "lng": -75.71508,
    "state_id": "MD"
  },
  {
    "zip": 21856,
    "lat": 38.33516,
    "lng": -75.79155,
    "state_id": "MD"
  },
  {
    "zip": 21861,
    "lat": 38.53529,
    "lng": -75.72826,
    "state_id": "MD"
  },
  {
    "zip": 21862,
    "lat": 38.39758,
    "lng": -75.22379,
    "state_id": "MD"
  },
  {
    "zip": 21863,
    "lat": 38.19242,
    "lng": -75.39846,
    "state_id": "MD"
  },
  {
    "zip": 21864,
    "lat": 38.04199,
    "lng": -75.40638,
    "state_id": "MD"
  },
  {
    "zip": 21865,
    "lat": 38.28504,
    "lng": -75.84124,
    "state_id": "MD"
  },
  {
    "zip": 21866,
    "lat": 37.96745,
    "lng": -76.02142,
    "state_id": "MD"
  },
  {
    "zip": 21867,
    "lat": 38.10959,
    "lng": -75.79362,
    "state_id": "MD"
  },
  {
    "zip": 21869,
    "lat": 38.44243,
    "lng": -75.89174,
    "state_id": "MD"
  },
  {
    "zip": 21871,
    "lat": 38.10383,
    "lng": -75.73073,
    "state_id": "MD"
  },
  {
    "zip": 21872,
    "lat": 38.41936,
    "lng": -75.29454,
    "state_id": "MD"
  },
  {
    "zip": 21874,
    "lat": 38.39166,
    "lng": -75.35243,
    "state_id": "MD"
  },
  {
    "zip": 21875,
    "lat": 38.44082,
    "lng": -75.54922,
    "state_id": "MD"
  },
  {
    "zip": 21890,
    "lat": 38.15732,
    "lng": -75.70404,
    "state_id": "MD"
  },
  {
    "zip": 21901,
    "lat": 39.58572,
    "lng": -75.95843,
    "state_id": "MD"
  },
  {
    "zip": 21902,
    "lat": 39.55195,
    "lng": -76.06319,
    "state_id": "MD"
  },
  {
    "zip": 21903,
    "lat": 39.57296,
    "lng": -76.03994,
    "state_id": "MD"
  },
  {
    "zip": 21904,
    "lat": 39.62315,
    "lng": -76.07802,
    "state_id": "MD"
  },
  {
    "zip": 21911,
    "lat": 39.68931,
    "lng": -76.03823,
    "state_id": "MD"
  },
  {
    "zip": 21912,
    "lat": 39.42065,
    "lng": -75.81637,
    "state_id": "MD"
  },
  {
    "zip": 21913,
    "lat": 39.40454,
    "lng": -75.86757,
    "state_id": "MD"
  },
  {
    "zip": 21914,
    "lat": 39.57303,
    "lng": -75.98187,
    "state_id": "MD"
  },
  {
    "zip": 21915,
    "lat": 39.49906,
    "lng": -75.84411,
    "state_id": "MD"
  },
  {
    "zip": 21916,
    "lat": 39.64119,
    "lng": -75.86182,
    "state_id": "MD"
  },
  {
    "zip": 21917,
    "lat": 39.6714,
    "lng": -76.09828,
    "state_id": "MD"
  },
  {
    "zip": 21918,
    "lat": 39.68362,
    "lng": -76.16681,
    "state_id": "MD"
  },
  {
    "zip": 21919,
    "lat": 39.42031,
    "lng": -75.93263,
    "state_id": "MD"
  },
  {
    "zip": 21920,
    "lat": 39.65746,
    "lng": -75.82839,
    "state_id": "MD"
  },
  {
    "zip": 21921,
    "lat": 39.62629,
    "lng": -75.85882,
    "state_id": "MD"
  },
  {
    "zip": 21930,
    "lat": 39.37212,
    "lng": -75.88996,
    "state_id": "MD"
  },
  {
    "zip": 3901,
    "lat": 43.30056,
    "lng": -70.84329,
    "state_id": "ME"
  },
  {
    "zip": 3902,
    "lat": 43.22146,
    "lng": -70.63766,
    "state_id": "ME"
  },
  {
    "zip": 3903,
    "lat": 43.14881,
    "lng": -70.78597,
    "state_id": "ME"
  },
  {
    "zip": 3904,
    "lat": 43.11055,
    "lng": -70.73108,
    "state_id": "ME"
  },
  {
    "zip": 3905,
    "lat": 43.08684,
    "lng": -70.68718,
    "state_id": "ME"
  },
  {
    "zip": 3906,
    "lat": 43.346,
    "lng": -70.77759,
    "state_id": "ME"
  },
  {
    "zip": 3907,
    "lat": 43.25464,
    "lng": -70.61096,
    "state_id": "ME"
  },
  {
    "zip": 3908,
    "lat": 43.23876,
    "lng": -70.74781,
    "state_id": "ME"
  },
  {
    "zip": 3909,
    "lat": 43.16755,
    "lng": -70.68247,
    "state_id": "ME"
  },
  {
    "zip": 3910,
    "lat": 43.17286,
    "lng": -70.61671,
    "state_id": "ME"
  },
  {
    "zip": 3911,
    "lat": 43.13488,
    "lng": -70.63485,
    "state_id": "ME"
  },
  {
    "zip": 4001,
    "lat": 43.52727,
    "lng": -70.92098,
    "state_id": "ME"
  },
  {
    "zip": 4002,
    "lat": 43.49588,
    "lng": -70.67943,
    "state_id": "ME"
  },
  {
    "zip": 4003,
    "lat": 43.73158,
    "lng": -69.98824,
    "state_id": "ME"
  },
  {
    "zip": 4005,
    "lat": 43.49603,
    "lng": -70.49547,
    "state_id": "ME"
  },
  {
    "zip": 4006,
    "lat": 43.44439,
    "lng": -70.34472,
    "state_id": "ME"
  },
  {
    "zip": 4008,
    "lat": 44.03716,
    "lng": -69.86207,
    "state_id": "ME"
  },
  {
    "zip": 4009,
    "lat": 44.0483,
    "lng": -70.73382,
    "state_id": "ME"
  },
  {
    "zip": 4010,
    "lat": 43.93305,
    "lng": -70.91671,
    "state_id": "ME"
  },
  {
    "zip": 4011,
    "lat": 43.9007,
    "lng": -69.97606,
    "state_id": "ME"
  },
  {
    "zip": 4015,
    "lat": 43.97437,
    "lng": -70.51792,
    "state_id": "ME"
  },
  {
    "zip": 4017,
    "lat": 43.73003,
    "lng": -70.11457,
    "state_id": "ME"
  },
  {
    "zip": 4019,
    "lat": 43.69149,
    "lng": -70.0999,
    "state_id": "ME"
  },
  {
    "zip": 4020,
    "lat": 43.76815,
    "lng": -70.81022,
    "state_id": "ME"
  },
  {
    "zip": 4021,
    "lat": 43.79747,
    "lng": -70.26495,
    "state_id": "ME"
  },
  {
    "zip": 4022,
    "lat": 43.98049,
    "lng": -70.80722,
    "state_id": "ME"
  },
  {
    "zip": 4024,
    "lat": 43.83304,
    "lng": -70.67771,
    "state_id": "ME"
  },
  {
    "zip": 4027,
    "lat": 43.40332,
    "lng": -70.91154,
    "state_id": "ME"
  },
  {
    "zip": 4029,
    "lat": 43.90164,
    "lng": -70.68772,
    "state_id": "ME"
  },
  {
    "zip": 4030,
    "lat": 43.5895,
    "lng": -70.69236,
    "state_id": "ME"
  },
  {
    "zip": 4032,
    "lat": 43.85562,
    "lng": -70.1008,
    "state_id": "ME"
  },
  {
    "zip": 4037,
    "lat": 44.09281,
    "lng": -70.94984,
    "state_id": "ME"
  },
  {
    "zip": 4038,
    "lat": 43.70338,
    "lng": -70.4581,
    "state_id": "ME"
  },
  {
    "zip": 4039,
    "lat": 43.88747,
    "lng": -70.3422,
    "state_id": "ME"
  },
  {
    "zip": 4040,
    "lat": 44.11094,
    "lng": -70.7185,
    "state_id": "ME"
  },
  {
    "zip": 4041,
    "lat": 43.86397,
    "lng": -70.82561,
    "state_id": "ME"
  },
  {
    "zip": 4042,
    "lat": 43.62952,
    "lng": -70.62453,
    "state_id": "ME"
  },
  {
    "zip": 4043,
    "lat": 43.3976,
    "lng": -70.57101,
    "state_id": "ME"
  },
  {
    "zip": 4046,
    "lat": 43.42031,
    "lng": -70.492,
    "state_id": "ME"
  },
  {
    "zip": 4047,
    "lat": 43.7362,
    "lng": -70.91076,
    "state_id": "ME"
  },
  {
    "zip": 4048,
    "lat": 43.68732,
    "lng": -70.78284,
    "state_id": "ME"
  },
  {
    "zip": 4049,
    "lat": 43.73768,
    "lng": -70.70296,
    "state_id": "ME"
  },
  {
    "zip": 4050,
    "lat": 43.69205,
    "lng": -70.15696,
    "state_id": "ME"
  },
  {
    "zip": 4051,
    "lat": 44.18874,
    "lng": -70.88469,
    "state_id": "ME"
  },
  {
    "zip": 4055,
    "lat": 43.9702,
    "lng": -70.61545,
    "state_id": "ME"
  },
  {
    "zip": 4056,
    "lat": 43.65403,
    "lng": -70.86296,
    "state_id": "ME"
  },
  {
    "zip": 4057,
    "lat": 44.10162,
    "lng": -70.69888,
    "state_id": "ME"
  },
  {
    "zip": 4061,
    "lat": 43.63377,
    "lng": -70.74209,
    "state_id": "ME"
  },
  {
    "zip": 4062,
    "lat": 43.79746,
    "lng": -70.40447,
    "state_id": "ME"
  },
  {
    "zip": 4063,
    "lat": 43.50109,
    "lng": -70.3961,
    "state_id": "ME"
  },
  {
    "zip": 4064,
    "lat": 43.52613,
    "lng": -70.38985,
    "state_id": "ME"
  },
  {
    "zip": 4066,
    "lat": 43.77278,
    "lng": -69.96827,
    "state_id": "ME"
  },
  {
    "zip": 4068,
    "lat": 43.84003,
    "lng": -70.94067,
    "state_id": "ME"
  },
  {
    "zip": 4069,
    "lat": 43.89918,
    "lng": -70.18192,
    "state_id": "ME"
  },
  {
    "zip": 4071,
    "lat": 43.92839,
    "lng": -70.4508,
    "state_id": "ME"
  },
  {
    "zip": 4072,
    "lat": 43.53899,
    "lng": -70.46233,
    "state_id": "ME"
  },
  {
    "zip": 4073,
    "lat": 43.41338,
    "lng": -70.74238,
    "state_id": "ME"
  },
  {
    "zip": 4074,
    "lat": 43.59108,
    "lng": -70.36821,
    "state_id": "ME"
  },
  {
    "zip": 4076,
    "lat": 43.55246,
    "lng": -70.83794,
    "state_id": "ME"
  },
  {
    "zip": 4079,
    "lat": 43.80344,
    "lng": -69.94972,
    "state_id": "ME"
  },
  {
    "zip": 4083,
    "lat": 43.46727,
    "lng": -70.81383,
    "state_id": "ME"
  },
  {
    "zip": 4084,
    "lat": 43.76606,
    "lng": -70.566,
    "state_id": "ME"
  },
  {
    "zip": 4085,
    "lat": 43.77448,
    "lng": -70.62667,
    "state_id": "ME"
  },
  {
    "zip": 4086,
    "lat": 43.96149,
    "lng": -69.95876,
    "state_id": "ME"
  },
  {
    "zip": 4087,
    "lat": 43.56551,
    "lng": -70.75251,
    "state_id": "ME"
  },
  {
    "zip": 4088,
    "lat": 44.19301,
    "lng": -70.71635,
    "state_id": "ME"
  },
  {
    "zip": 4090,
    "lat": 43.3267,
    "lng": -70.63359,
    "state_id": "ME"
  },
  {
    "zip": 4091,
    "lat": 43.83211,
    "lng": -70.75141,
    "state_id": "ME"
  },
  {
    "zip": 4092,
    "lat": 43.69532,
    "lng": -70.35388,
    "state_id": "ME"
  },
  {
    "zip": 4093,
    "lat": 43.64281,
    "lng": -70.5377,
    "state_id": "ME"
  },
  {
    "zip": 4095,
    "lat": 43.63562,
    "lng": -70.91523,
    "state_id": "ME"
  },
  {
    "zip": 4096,
    "lat": 43.79784,
    "lng": -70.17185,
    "state_id": "ME"
  },
  {
    "zip": 4097,
    "lat": 43.85122,
    "lng": -70.23636,
    "state_id": "ME"
  },
  {
    "zip": 4101,
    "lat": 43.66239,
    "lng": -70.25838,
    "state_id": "ME"
  },
  {
    "zip": 4102,
    "lat": 43.65872,
    "lng": -70.30614,
    "state_id": "ME"
  },
  {
    "zip": 4103,
    "lat": 43.69379,
    "lng": -70.29088,
    "state_id": "ME"
  },
  {
    "zip": 4105,
    "lat": 43.74754,
    "lng": -70.28249,
    "state_id": "ME"
  },
  {
    "zip": 4106,
    "lat": 43.63068,
    "lng": -70.28913,
    "state_id": "ME"
  },
  {
    "zip": 4107,
    "lat": 43.58873,
    "lng": -70.23768,
    "state_id": "ME"
  },
  {
    "zip": 4108,
    "lat": 43.66057,
    "lng": -70.18901,
    "state_id": "ME"
  },
  {
    "zip": 4109,
    "lat": 43.66732,
    "lng": -70.19984,
    "state_id": "ME"
  },
  {
    "zip": 4110,
    "lat": 43.75825,
    "lng": -70.19871,
    "state_id": "ME"
  },
  {
    "zip": 4210,
    "lat": 44.0849,
    "lng": -70.24922,
    "state_id": "ME"
  },
  {
    "zip": 4216,
    "lat": 44.70684,
    "lng": -70.82149,
    "state_id": "ME"
  },
  {
    "zip": 4217,
    "lat": 44.36776,
    "lng": -70.83319,
    "state_id": "ME"
  },
  {
    "zip": 4219,
    "lat": 44.40924,
    "lng": -70.59286,
    "state_id": "ME"
  },
  {
    "zip": 4220,
    "lat": 44.33654,
    "lng": -70.35329,
    "state_id": "ME"
  },
  {
    "zip": 4221,
    "lat": 44.46028,
    "lng": -70.29981,
    "state_id": "ME"
  },
  {
    "zip": 4222,
    "lat": 43.96439,
    "lng": -70.12955,
    "state_id": "ME"
  },
  {
    "zip": 4224,
    "lat": 44.58017,
    "lng": -70.40111,
    "state_id": "ME"
  },
  {
    "zip": 4226,
    "lat": 44.60844,
    "lng": -70.69937,
    "state_id": "ME"
  },
  {
    "zip": 4227,
    "lat": 44.55721,
    "lng": -70.29763,
    "state_id": "ME"
  },
  {
    "zip": 4228,
    "lat": 44.43256,
    "lng": -70.11501,
    "state_id": "ME"
  },
  {
    "zip": 4231,
    "lat": 44.26422,
    "lng": -70.88725,
    "state_id": "ME"
  },
  {
    "zip": 4234,
    "lat": 44.62385,
    "lng": -70.19191,
    "state_id": "ME"
  },
  {
    "zip": 4236,
    "lat": 44.19041,
    "lng": -70.14461,
    "state_id": "ME"
  },
  {
    "zip": 4237,
    "lat": 44.49665,
    "lng": -70.72564,
    "state_id": "ME"
  },
  {
    "zip": 4238,
    "lat": 44.21068,
    "lng": -70.38176,
    "state_id": "ME"
  },
  {
    "zip": 4239,
    "lat": 44.53058,
    "lng": -70.20968,
    "state_id": "ME"
  },
  {
    "zip": 4240,
    "lat": 44.09148,
    "lng": -70.16807,
    "state_id": "ME"
  },
  {
    "zip": 4250,
    "lat": 44.02599,
    "lng": -70.11656,
    "state_id": "ME"
  },
  {
    "zip": 4252,
    "lat": 44.02706,
    "lng": -70.0596,
    "state_id": "ME"
  },
  {
    "zip": 4253,
    "lat": 44.4079,
    "lng": -70.21503,
    "state_id": "ME"
  },
  {
    "zip": 4254,
    "lat": 44.43248,
    "lng": -70.14274,
    "state_id": "ME"
  },
  {
    "zip": 4255,
    "lat": 44.33622,
    "lng": -70.67047,
    "state_id": "ME"
  },
  {
    "zip": 4256,
    "lat": 44.10237,
    "lng": -70.40898,
    "state_id": "ME"
  },
  {
    "zip": 4257,
    "lat": 44.57347,
    "lng": -70.51358,
    "state_id": "ME"
  },
  {
    "zip": 4258,
    "lat": 44.14833,
    "lng": -70.33787,
    "state_id": "ME"
  },
  {
    "zip": 4259,
    "lat": 44.22836,
    "lng": -70.00856,
    "state_id": "ME"
  },
  {
    "zip": 4260,
    "lat": 43.96394,
    "lng": -70.2959,
    "state_id": "ME"
  },
  {
    "zip": 4261,
    "lat": 44.56461,
    "lng": -70.92003,
    "state_id": "ME"
  },
  {
    "zip": 4263,
    "lat": 44.29481,
    "lng": -70.13062,
    "state_id": "ME"
  },
  {
    "zip": 4265,
    "lat": 44.28444,
    "lng": -70.04639,
    "state_id": "ME"
  },
  {
    "zip": 4267,
    "lat": 44.2104,
    "lng": -70.7902,
    "state_id": "ME"
  },
  {
    "zip": 4268,
    "lat": 44.22918,
    "lng": -70.60656,
    "state_id": "ME"
  },
  {
    "zip": 4270,
    "lat": 44.1124,
    "lng": -70.51561,
    "state_id": "ME"
  },
  {
    "zip": 4271,
    "lat": 44.26366,
    "lng": -70.49813,
    "state_id": "ME"
  },
  {
    "zip": 4274,
    "lat": 44.0461,
    "lng": -70.38943,
    "state_id": "ME"
  },
  {
    "zip": 4275,
    "lat": 44.68968,
    "lng": -70.63731,
    "state_id": "ME"
  },
  {
    "zip": 4276,
    "lat": 44.53469,
    "lng": -70.61541,
    "state_id": "ME"
  },
  {
    "zip": 4280,
    "lat": 44.12152,
    "lng": -70.06153,
    "state_id": "ME"
  },
  {
    "zip": 4281,
    "lat": 44.24443,
    "lng": -70.48828,
    "state_id": "ME"
  },
  {
    "zip": 4282,
    "lat": 44.26707,
    "lng": -70.24284,
    "state_id": "ME"
  },
  {
    "zip": 4284,
    "lat": 44.35129,
    "lng": -70.06611,
    "state_id": "ME"
  },
  {
    "zip": 4285,
    "lat": 44.70329,
    "lng": -70.44621,
    "state_id": "ME"
  },
  {
    "zip": 4286,
    "lat": 44.40146,
    "lng": -70.86511,
    "state_id": "ME"
  },
  {
    "zip": 4287,
    "lat": 44.05725,
    "lng": -69.96819,
    "state_id": "ME"
  },
  {
    "zip": 4289,
    "lat": 44.32108,
    "lng": -70.53379,
    "state_id": "ME"
  },
  {
    "zip": 4290,
    "lat": 44.47354,
    "lng": -70.45058,
    "state_id": "ME"
  },
  {
    "zip": 4292,
    "lat": 44.37404,
    "lng": -70.44688,
    "state_id": "ME"
  },
  {
    "zip": 4294,
    "lat": 44.62897,
    "lng": -70.26955,
    "state_id": "ME"
  },
  {
    "zip": 4330,
    "lat": 44.36394,
    "lng": -69.73739,
    "state_id": "ME"
  },
  {
    "zip": 4342,
    "lat": 44.07942,
    "lng": -69.73938,
    "state_id": "ME"
  },
  {
    "zip": 4343,
    "lat": 44.32884,
    "lng": -69.89163,
    "state_id": "ME"
  },
  {
    "zip": 4344,
    "lat": 44.25881,
    "lng": -69.82131,
    "state_id": "ME"
  },
  {
    "zip": 4345,
    "lat": 44.19822,
    "lng": -69.78479,
    "state_id": "ME"
  },
  {
    "zip": 4346,
    "lat": 44.23469,
    "lng": -69.75061,
    "state_id": "ME"
  },
  {
    "zip": 4347,
    "lat": 44.29046,
    "lng": -69.81412,
    "state_id": "ME"
  },
  {
    "zip": 4348,
    "lat": 44.21522,
    "lng": -69.49919,
    "state_id": "ME"
  },
  {
    "zip": 4349,
    "lat": 44.4373,
    "lng": -70.06853,
    "state_id": "ME"
  },
  {
    "zip": 4350,
    "lat": 44.16247,
    "lng": -69.93987,
    "state_id": "ME"
  },
  {
    "zip": 4351,
    "lat": 44.33132,
    "lng": -69.86104,
    "state_id": "ME"
  },
  {
    "zip": 4352,
    "lat": 44.4656,
    "lng": -69.96094,
    "state_id": "ME"
  },
  {
    "zip": 4353,
    "lat": 44.20527,
    "lng": -69.61249,
    "state_id": "ME"
  },
  {
    "zip": 4354,
    "lat": 44.39603,
    "lng": -69.42707,
    "state_id": "ME"
  },
  {
    "zip": 4355,
    "lat": 44.38211,
    "lng": -69.94907,
    "state_id": "ME"
  },
  {
    "zip": 4357,
    "lat": 44.12371,
    "lng": -69.82882,
    "state_id": "ME"
  },
  {
    "zip": 4358,
    "lat": 44.41818,
    "lng": -69.53288,
    "state_id": "ME"
  },
  {
    "zip": 4359,
    "lat": 44.17747,
    "lng": -69.76061,
    "state_id": "ME"
  },
  {
    "zip": 4360,
    "lat": 44.54757,
    "lng": -70.00297,
    "state_id": "ME"
  },
  {
    "zip": 4363,
    "lat": 44.31109,
    "lng": -69.57805,
    "state_id": "ME"
  },
  {
    "zip": 4364,
    "lat": 44.31155,
    "lng": -69.96236,
    "state_id": "ME"
  },
  {
    "zip": 4401,
    "lat": 44.84923,
    "lng": -68.84479,
    "state_id": "ME"
  },
  {
    "zip": 4406,
    "lat": 45.23454,
    "lng": -69.56989,
    "state_id": "ME"
  },
  {
    "zip": 4408,
    "lat": 44.99135,
    "lng": -68.23072,
    "state_id": "ME"
  },
  {
    "zip": 4410,
    "lat": 45.08821,
    "lng": -68.90696,
    "state_id": "ME"
  },
  {
    "zip": 4411,
    "lat": 44.88125,
    "lng": -68.57612,
    "state_id": "ME"
  },
  {
    "zip": 4412,
    "lat": 44.78351,
    "lng": -68.73513,
    "state_id": "ME"
  },
  {
    "zip": 4413,
    "lat": 45.55075,
    "lng": -67.7436,
    "state_id": "ME"
  },
  {
    "zip": 4414,
    "lat": 45.44464,
    "lng": -69.10659,
    "state_id": "ME"
  },
  {
    "zip": 4415,
    "lat": 45.38263,
    "lng": -69.06191,
    "state_id": "ME"
  },
  {
    "zip": 4416,
    "lat": 44.62724,
    "lng": -68.75286,
    "state_id": "ME"
  },
  {
    "zip": 4417,
    "lat": 45.23486,
    "lng": -68.37908,
    "state_id": "ME"
  },
  {
    "zip": 4418,
    "lat": 45.08638,
    "lng": -68.4722,
    "state_id": "ME"
  },
  {
    "zip": 4419,
    "lat": 44.79688,
    "lng": -69.0314,
    "state_id": "ME"
  },
  {
    "zip": 4421,
    "lat": 44.4111,
    "lng": -68.79524,
    "state_id": "ME"
  },
  {
    "zip": 4422,
    "lat": 45.07101,
    "lng": -69.03409,
    "state_id": "ME"
  },
  {
    "zip": 4424,
    "lat": 45.67054,
    "lng": -67.86814,
    "state_id": "ME"
  },
  {
    "zip": 4426,
    "lat": 45.21958,
    "lng": -69.18354,
    "state_id": "ME"
  },
  {
    "zip": 4427,
    "lat": 44.9806,
    "lng": -69.01076,
    "state_id": "ME"
  },
  {
    "zip": 4428,
    "lat": 44.80645,
    "lng": -68.55592,
    "state_id": "ME"
  },
  {
    "zip": 4429,
    "lat": 44.72076,
    "lng": -68.61643,
    "state_id": "ME"
  },
  {
    "zip": 4430,
    "lat": 45.63921,
    "lng": -68.58517,
    "state_id": "ME"
  },
  {
    "zip": 4431,
    "lat": 44.56207,
    "lng": -68.67037,
    "state_id": "ME"
  },
  {
    "zip": 4434,
    "lat": 44.78536,
    "lng": -69.13372,
    "state_id": "ME"
  },
  {
    "zip": 4435,
    "lat": 44.96435,
    "lng": -69.13539,
    "state_id": "ME"
  },
  {
    "zip": 4438,
    "lat": 44.59856,
    "lng": -68.91904,
    "state_id": "ME"
  },
  {
    "zip": 4441,
    "lat": 45.89602,
    "lng": -69.47534,
    "state_id": "ME"
  },
  {
    "zip": 4442,
    "lat": 45.47946,
    "lng": -69.69393,
    "state_id": "ME"
  },
  {
    "zip": 4443,
    "lat": 45.22433,
    "lng": -69.39898,
    "state_id": "ME"
  },
  {
    "zip": 4444,
    "lat": 44.72594,
    "lng": -68.94584,
    "state_id": "ME"
  },
  {
    "zip": 4448,
    "lat": 45.31869,
    "lng": -68.70995,
    "state_id": "ME"
  },
  {
    "zip": 4449,
    "lat": 44.99775,
    "lng": -68.88397,
    "state_id": "ME"
  },
  {
    "zip": 4450,
    "lat": 44.91605,
    "lng": -68.92801,
    "state_id": "ME"
  },
  {
    "zip": 4451,
    "lat": 45.61088,
    "lng": -68.21044,
    "state_id": "ME"
  },
  {
    "zip": 4453,
    "lat": 45.1719,
    "lng": -68.78509,
    "state_id": "ME"
  },
  {
    "zip": 4454,
    "lat": 45.55588,
    "lng": -67.56379,
    "state_id": "ME"
  },
  {
    "zip": 4455,
    "lat": 45.32332,
    "lng": -68.27501,
    "state_id": "ME"
  },
  {
    "zip": 4456,
    "lat": 44.88647,
    "lng": -68.99773,
    "state_id": "ME"
  },
  {
    "zip": 4457,
    "lat": 45.41631,
    "lng": -68.47826,
    "state_id": "ME"
  },
  {
    "zip": 4459,
    "lat": 45.5941,
    "lng": -68.3406,
    "state_id": "ME"
  },
  {
    "zip": 4460,
    "lat": 45.6938,
    "lng": -68.5459,
    "state_id": "ME"
  },
  {
    "zip": 4461,
    "lat": 44.96416,
    "lng": -68.56865,
    "state_id": "ME"
  },
  {
    "zip": 4462,
    "lat": 45.93691,
    "lng": -68.98088,
    "state_id": "ME"
  },
  {
    "zip": 4463,
    "lat": 45.27526,
    "lng": -68.90608,
    "state_id": "ME"
  },
  {
    "zip": 4464,
    "lat": 45.41868,
    "lng": -69.39805,
    "state_id": "ME"
  },
  {
    "zip": 4468,
    "lat": 45.02316,
    "lng": -68.72756,
    "state_id": "ME"
  },
  {
    "zip": 4469,
    "lat": 44.90092,
    "lng": -68.66829,
    "state_id": "ME"
  },
  {
    "zip": 4471,
    "lat": 45.89108,
    "lng": -67.84714,
    "state_id": "ME"
  },
  {
    "zip": 4472,
    "lat": 44.57351,
    "lng": -68.6773,
    "state_id": "ME"
  },
  {
    "zip": 4473,
    "lat": 44.88648,
    "lng": -68.71719,
    "state_id": "ME"
  },
  {
    "zip": 4474,
    "lat": 44.71498,
    "lng": -68.77898,
    "state_id": "ME"
  },
  {
    "zip": 4475,
    "lat": 45.18152,
    "lng": -68.58717,
    "state_id": "ME"
  },
  {
    "zip": 4476,
    "lat": 44.47478,
    "lng": -68.7053,
    "state_id": "ME"
  },
  {
    "zip": 4478,
    "lat": 45.86407,
    "lng": -69.87681,
    "state_id": "ME"
  },
  {
    "zip": 4479,
    "lat": 45.12757,
    "lng": -69.30704,
    "state_id": "ME"
  },
  {
    "zip": 4481,
    "lat": 45.24655,
    "lng": -69.10216,
    "state_id": "ME"
  },
  {
    "zip": 4485,
    "lat": 45.36098,
    "lng": -69.62022,
    "state_id": "ME"
  },
  {
    "zip": 4487,
    "lat": 45.39039,
    "lng": -68.10218,
    "state_id": "ME"
  },
  {
    "zip": 4488,
    "lat": 44.87586,
    "lng": -69.11143,
    "state_id": "ME"
  },
  {
    "zip": 4489,
    "lat": 44.91017,
    "lng": -68.69005,
    "state_id": "ME"
  },
  {
    "zip": 4490,
    "lat": 45.43358,
    "lng": -67.85571,
    "state_id": "ME"
  },
  {
    "zip": 4491,
    "lat": 45.57068,
    "lng": -67.4697,
    "state_id": "ME"
  },
  {
    "zip": 4492,
    "lat": 45.3491,
    "lng": -67.67788,
    "state_id": "ME"
  },
  {
    "zip": 4493,
    "lat": 45.23786,
    "lng": -68.5338,
    "state_id": "ME"
  },
  {
    "zip": 4495,
    "lat": 45.45459,
    "lng": -68.32986,
    "state_id": "ME"
  },
  {
    "zip": 4496,
    "lat": 44.6584,
    "lng": -68.9119,
    "state_id": "ME"
  },
  {
    "zip": 4497,
    "lat": 45.76367,
    "lng": -68.05134,
    "state_id": "ME"
  },
  {
    "zip": 4530,
    "lat": 43.89158,
    "lng": -69.8306,
    "state_id": "ME"
  },
  {
    "zip": 4535,
    "lat": 44.08776,
    "lng": -69.63437,
    "state_id": "ME"
  },
  {
    "zip": 4537,
    "lat": 43.89673,
    "lng": -69.62343,
    "state_id": "ME"
  },
  {
    "zip": 4538,
    "lat": 43.85622,
    "lng": -69.62968,
    "state_id": "ME"
  },
  {
    "zip": 4539,
    "lat": 43.96424,
    "lng": -69.50668,
    "state_id": "ME"
  },
  {
    "zip": 4541,
    "lat": 43.89571,
    "lng": -69.47667,
    "state_id": "ME"
  },
  {
    "zip": 4543,
    "lat": 44.03446,
    "lng": -69.49212,
    "state_id": "ME"
  },
  {
    "zip": 4544,
    "lat": 43.84552,
    "lng": -69.58687,
    "state_id": "ME"
  },
  {
    "zip": 4547,
    "lat": 43.99388,
    "lng": -69.32779,
    "state_id": "ME"
  },
  {
    "zip": 4548,
    "lat": 43.81499,
    "lng": -69.74804,
    "state_id": "ME"
  },
  {
    "zip": 4551,
    "lat": 44.00648,
    "lng": -69.42701,
    "state_id": "ME"
  },
  {
    "zip": 4553,
    "lat": 44.04786,
    "lng": -69.57146,
    "state_id": "ME"
  },
  {
    "zip": 4554,
    "lat": 43.86575,
    "lng": -69.50557,
    "state_id": "ME"
  },
  {
    "zip": 4555,
    "lat": 44.10599,
    "lng": -69.47687,
    "state_id": "ME"
  },
  {
    "zip": 4556,
    "lat": 43.96868,
    "lng": -69.6231,
    "state_id": "ME"
  },
  {
    "zip": 4558,
    "lat": 43.89873,
    "lng": -69.51577,
    "state_id": "ME"
  },
  {
    "zip": 4562,
    "lat": 43.78757,
    "lng": -69.82764,
    "state_id": "ME"
  },
  {
    "zip": 4563,
    "lat": 44.0151,
    "lng": -69.25655,
    "state_id": "ME"
  },
  {
    "zip": 4564,
    "lat": 43.93503,
    "lng": -69.46469,
    "state_id": "ME"
  },
  {
    "zip": 4568,
    "lat": 43.88591,
    "lng": -69.5599,
    "state_id": "ME"
  },
  {
    "zip": 4570,
    "lat": 43.80849,
    "lng": -69.6307,
    "state_id": "ME"
  },
  {
    "zip": 4571,
    "lat": 43.89733,
    "lng": -69.67365,
    "state_id": "ME"
  },
  {
    "zip": 4572,
    "lat": 44.10984,
    "lng": -69.36956,
    "state_id": "ME"
  },
  {
    "zip": 4573,
    "lat": 43.95136,
    "lng": -69.55374,
    "state_id": "ME"
  },
  {
    "zip": 4574,
    "lat": 44.27335,
    "lng": -69.39127,
    "state_id": "ME"
  },
  {
    "zip": 4575,
    "lat": 43.85273,
    "lng": -69.662,
    "state_id": "ME"
  },
  {
    "zip": 4576,
    "lat": 43.82228,
    "lng": -69.66695,
    "state_id": "ME"
  },
  {
    "zip": 4578,
    "lat": 43.98135,
    "lng": -69.6926,
    "state_id": "ME"
  },
  {
    "zip": 4579,
    "lat": 43.96217,
    "lng": -69.7687,
    "state_id": "ME"
  },
  {
    "zip": 4605,
    "lat": 44.67483,
    "lng": -68.41713,
    "state_id": "ME"
  },
  {
    "zip": 4606,
    "lat": 44.57718,
    "lng": -67.70304,
    "state_id": "ME"
  },
  {
    "zip": 4607,
    "lat": 44.48263,
    "lng": -68.05651,
    "state_id": "ME"
  },
  {
    "zip": 4609,
    "lat": 44.38521,
    "lng": -68.27112,
    "state_id": "ME"
  },
  {
    "zip": 4611,
    "lat": 44.48771,
    "lng": -67.59633,
    "state_id": "ME"
  },
  {
    "zip": 4612,
    "lat": 44.25998,
    "lng": -68.37314,
    "state_id": "ME"
  },
  {
    "zip": 4613,
    "lat": 44.37799,
    "lng": -68.03415,
    "state_id": "ME"
  },
  {
    "zip": 4614,
    "lat": 44.41784,
    "lng": -68.58452,
    "state_id": "ME"
  },
  {
    "zip": 4616,
    "lat": 44.28346,
    "lng": -68.57069,
    "state_id": "ME"
  },
  {
    "zip": 4617,
    "lat": 44.36621,
    "lng": -68.73678,
    "state_id": "ME"
  },
  {
    "zip": 4619,
    "lat": 45.134,
    "lng": -67.224,
    "state_id": "ME"
  },
  {
    "zip": 4622,
    "lat": 44.757,
    "lng": -67.98458,
    "state_id": "ME"
  },
  {
    "zip": 4623,
    "lat": 44.72804,
    "lng": -67.72818,
    "state_id": "ME"
  },
  {
    "zip": 4624,
    "lat": 44.42283,
    "lng": -67.98706,
    "state_id": "ME"
  },
  {
    "zip": 4625,
    "lat": 44.2513,
    "lng": -68.26048,
    "state_id": "ME"
  },
  {
    "zip": 4626,
    "lat": 44.68494,
    "lng": -67.22169,
    "state_id": "ME"
  },
  {
    "zip": 4627,
    "lat": 44.22294,
    "lng": -68.65275,
    "state_id": "ME"
  },
  {
    "zip": 4628,
    "lat": 44.87152,
    "lng": -67.26987,
    "state_id": "ME"
  },
  {
    "zip": 4629,
    "lat": 44.42822,
    "lng": -68.5176,
    "state_id": "ME"
  },
  {
    "zip": 4630,
    "lat": 44.8373,
    "lng": -67.4583,
    "state_id": "ME"
  },
  {
    "zip": 4631,
    "lat": 44.9131,
    "lng": -67.01792,
    "state_id": "ME"
  },
  {
    "zip": 4634,
    "lat": 44.64894,
    "lng": -68.22041,
    "state_id": "ME"
  },
  {
    "zip": 4635,
    "lat": 44.14299,
    "lng": -68.34483,
    "state_id": "ME"
  },
  {
    "zip": 4637,
    "lat": 45.19615,
    "lng": -67.8076,
    "state_id": "ME"
  },
  {
    "zip": 4640,
    "lat": 44.53848,
    "lng": -68.30374,
    "state_id": "ME"
  },
  {
    "zip": 4642,
    "lat": 44.33383,
    "lng": -68.80434,
    "state_id": "ME"
  },
  {
    "zip": 4643,
    "lat": 44.57913,
    "lng": -67.80571,
    "state_id": "ME"
  },
  {
    "zip": 4644,
    "lat": 44.41384,
    "lng": -68.25207,
    "state_id": "ME"
  },
  {
    "zip": 4645,
    "lat": 44.05408,
    "lng": -68.6282,
    "state_id": "ME"
  },
  {
    "zip": 4646,
    "lat": 44.25555,
    "lng": -68.22168,
    "state_id": "ME"
  },
  {
    "zip": 4648,
    "lat": 44.66395,
    "lng": -67.59028,
    "state_id": "ME"
  },
  {
    "zip": 4649,
    "lat": 44.56605,
    "lng": -67.5817,
    "state_id": "ME"
  },
  {
    "zip": 4650,
    "lat": 44.2849,
    "lng": -68.70898,
    "state_id": "ME"
  },
  {
    "zip": 4652,
    "lat": 44.82242,
    "lng": -67.07614,
    "state_id": "ME"
  },
  {
    "zip": 4653,
    "lat": 44.2313,
    "lng": -68.34036,
    "state_id": "ME"
  },
  {
    "zip": 4654,
    "lat": 44.77104,
    "lng": -67.55341,
    "state_id": "ME"
  },
  {
    "zip": 4655,
    "lat": 44.66467,
    "lng": -67.39318,
    "state_id": "ME"
  },
  {
    "zip": 4657,
    "lat": 44.97015,
    "lng": -67.42295,
    "state_id": "ME"
  },
  {
    "zip": 4658,
    "lat": 44.53986,
    "lng": -67.87307,
    "state_id": "ME"
  },
  {
    "zip": 4660,
    "lat": 44.34056,
    "lng": -68.33312,
    "state_id": "ME"
  },
  {
    "zip": 4662,
    "lat": 44.30858,
    "lng": -68.29427,
    "state_id": "ME"
  },
  {
    "zip": 4664,
    "lat": 44.54693,
    "lng": -68.12714,
    "state_id": "ME"
  },
  {
    "zip": 4666,
    "lat": 44.98173,
    "lng": -67.22515,
    "state_id": "ME"
  },
  {
    "zip": 4667,
    "lat": 44.97853,
    "lng": -67.10412,
    "state_id": "ME"
  },
  {
    "zip": 4668,
    "lat": 45.20968,
    "lng": -67.59013,
    "state_id": "ME"
  },
  {
    "zip": 4669,
    "lat": 44.41669,
    "lng": -68.0175,
    "state_id": "ME"
  },
  {
    "zip": 4671,
    "lat": 45.0662,
    "lng": -67.16247,
    "state_id": "ME"
  },
  {
    "zip": 4673,
    "lat": 44.30964,
    "lng": -68.67454,
    "state_id": "ME"
  },
  {
    "zip": 4674,
    "lat": 44.29472,
    "lng": -68.4107,
    "state_id": "ME"
  },
  {
    "zip": 4675,
    "lat": 44.30453,
    "lng": -68.25274,
    "state_id": "ME"
  },
  {
    "zip": 4676,
    "lat": 44.34662,
    "lng": -68.64166,
    "state_id": "ME"
  },
  {
    "zip": 4677,
    "lat": 44.49016,
    "lng": -68.17945,
    "state_id": "ME"
  },
  {
    "zip": 4679,
    "lat": 44.27941,
    "lng": -68.3326,
    "state_id": "ME"
  },
  {
    "zip": 4680,
    "lat": 44.5064,
    "lng": -67.94467,
    "state_id": "ME"
  },
  {
    "zip": 4681,
    "lat": 44.16655,
    "lng": -68.65646,
    "state_id": "ME"
  },
  {
    "zip": 4683,
    "lat": 44.22116,
    "lng": -68.75914,
    "state_id": "ME"
  },
  {
    "zip": 4684,
    "lat": 44.49337,
    "lng": -68.52794,
    "state_id": "ME"
  },
  {
    "zip": 4685,
    "lat": 44.1562,
    "lng": -68.44848,
    "state_id": "ME"
  },
  {
    "zip": 4686,
    "lat": 44.98542,
    "lng": -67.69916,
    "state_id": "ME"
  },
  {
    "zip": 4691,
    "lat": 44.76272,
    "lng": -67.251,
    "state_id": "ME"
  },
  {
    "zip": 4693,
    "lat": 44.38479,
    "lng": -68.07828,
    "state_id": "ME"
  },
  {
    "zip": 4694,
    "lat": 45.10333,
    "lng": -67.47223,
    "state_id": "ME"
  },
  {
    "zip": 4730,
    "lat": 46.13233,
    "lng": -67.91389,
    "state_id": "ME"
  },
  {
    "zip": 4732,
    "lat": 46.50721,
    "lng": -68.76427,
    "state_id": "ME"
  },
  {
    "zip": 4733,
    "lat": 45.75697,
    "lng": -68.40146,
    "state_id": "ME"
  },
  {
    "zip": 4734,
    "lat": 46.47856,
    "lng": -67.92231,
    "state_id": "ME"
  },
  {
    "zip": 4735,
    "lat": 46.41805,
    "lng": -67.86466,
    "state_id": "ME"
  },
  {
    "zip": 4736,
    "lat": 46.91531,
    "lng": -68.02117,
    "state_id": "ME"
  },
  {
    "zip": 4739,
    "lat": 47.01016,
    "lng": -68.69084,
    "state_id": "ME"
  },
  {
    "zip": 4740,
    "lat": 46.64322,
    "lng": -67.85487,
    "state_id": "ME"
  },
  {
    "zip": 4741,
    "lat": 47.44775,
    "lng": -69.1963,
    "state_id": "ME"
  },
  {
    "zip": 4742,
    "lat": 46.77766,
    "lng": -67.85527,
    "state_id": "ME"
  },
  {
    "zip": 4743,
    "lat": 47.19506,
    "lng": -68.61742,
    "state_id": "ME"
  },
  {
    "zip": 4745,
    "lat": 47.28276,
    "lng": -68.39168,
    "state_id": "ME"
  },
  {
    "zip": 4746,
    "lat": 47.24885,
    "lng": -68.12472,
    "state_id": "ME"
  },
  {
    "zip": 4747,
    "lat": 46.00423,
    "lng": -68.23809,
    "state_id": "ME"
  },
  {
    "zip": 4750,
    "lat": 46.95826,
    "lng": -67.85713,
    "state_id": "ME"
  },
  {
    "zip": 4756,
    "lat": 47.32218,
    "lng": -68.30324,
    "state_id": "ME"
  },
  {
    "zip": 4757,
    "lat": 46.66268,
    "lng": -68.17241,
    "state_id": "ME"
  },
  {
    "zip": 4758,
    "lat": 46.55427,
    "lng": -67.84731,
    "state_id": "ME"
  },
  {
    "zip": 4760,
    "lat": 46.34676,
    "lng": -67.93643,
    "state_id": "ME"
  },
  {
    "zip": 4761,
    "lat": 46.11835,
    "lng": -67.97138,
    "state_id": "ME"
  },
  {
    "zip": 4762,
    "lat": 46.97077,
    "lng": -68.13464,
    "state_id": "ME"
  },
  {
    "zip": 4763,
    "lat": 46.08061,
    "lng": -68.10359,
    "state_id": "ME"
  },
  {
    "zip": 4764,
    "lat": 46.41864,
    "lng": -68.56825,
    "state_id": "ME"
  },
  {
    "zip": 4765,
    "lat": 46.15346,
    "lng": -68.62626,
    "state_id": "ME"
  },
  {
    "zip": 4766,
    "lat": 46.88162,
    "lng": -68.23912,
    "state_id": "ME"
  },
  {
    "zip": 4768,
    "lat": 46.82618,
    "lng": -68.86887,
    "state_id": "ME"
  },
  {
    "zip": 4769,
    "lat": 46.68676,
    "lng": -67.98738,
    "state_id": "ME"
  },
  {
    "zip": 4772,
    "lat": 47.22724,
    "lng": -68.31309,
    "state_id": "ME"
  },
  {
    "zip": 4773,
    "lat": 47.27622,
    "lng": -68.2234,
    "state_id": "ME"
  },
  {
    "zip": 4774,
    "lat": 47.07986,
    "lng": -69.12385,
    "state_id": "ME"
  },
  {
    "zip": 4776,
    "lat": 45.85109,
    "lng": -68.32344,
    "state_id": "ME"
  },
  {
    "zip": 4777,
    "lat": 45.84773,
    "lng": -68.50737,
    "state_id": "ME"
  },
  {
    "zip": 4779,
    "lat": 47.12208,
    "lng": -68.32294,
    "state_id": "ME"
  },
  {
    "zip": 4780,
    "lat": 46.23761,
    "lng": -68.25263,
    "state_id": "ME"
  },
  {
    "zip": 4781,
    "lat": 47.14457,
    "lng": -68.60948,
    "state_id": "ME"
  },
  {
    "zip": 4783,
    "lat": 47.04254,
    "lng": -68.17584,
    "state_id": "ME"
  },
  {
    "zip": 4785,
    "lat": 47.13003,
    "lng": -67.98687,
    "state_id": "ME"
  },
  {
    "zip": 4786,
    "lat": 46.79208,
    "lng": -68.17877,
    "state_id": "ME"
  },
  {
    "zip": 4787,
    "lat": 46.55169,
    "lng": -67.97684,
    "state_id": "ME"
  },
  {
    "zip": 4841,
    "lat": 44.12737,
    "lng": -69.13685,
    "state_id": "ME"
  },
  {
    "zip": 4843,
    "lat": 44.2293,
    "lng": -69.08923,
    "state_id": "ME"
  },
  {
    "zip": 4847,
    "lat": 44.25104,
    "lng": -69.18961,
    "state_id": "ME"
  },
  {
    "zip": 4848,
    "lat": 44.31083,
    "lng": -68.90896,
    "state_id": "ME"
  },
  {
    "zip": 4849,
    "lat": 44.31793,
    "lng": -69.04753,
    "state_id": "ME"
  },
  {
    "zip": 4851,
    "lat": 43.85498,
    "lng": -68.87351,
    "state_id": "ME"
  },
  {
    "zip": 4852,
    "lat": 43.76561,
    "lng": -69.31235,
    "state_id": "ME"
  },
  {
    "zip": 4853,
    "lat": 44.15172,
    "lng": -68.86311,
    "state_id": "ME"
  },
  {
    "zip": 4854,
    "lat": 44.06816,
    "lng": -69.08706,
    "state_id": "ME"
  },
  {
    "zip": 4855,
    "lat": 43.93459,
    "lng": -69.25745,
    "state_id": "ME"
  },
  {
    "zip": 4856,
    "lat": 44.17799,
    "lng": -69.12006,
    "state_id": "ME"
  },
  {
    "zip": 4858,
    "lat": 44.04647,
    "lng": -69.14522,
    "state_id": "ME"
  },
  {
    "zip": 4859,
    "lat": 43.98894,
    "lng": -69.13754,
    "state_id": "ME"
  },
  {
    "zip": 4860,
    "lat": 43.96925,
    "lng": -69.22844,
    "state_id": "ME"
  },
  {
    "zip": 4861,
    "lat": 44.093,
    "lng": -69.17485,
    "state_id": "ME"
  },
  {
    "zip": 4862,
    "lat": 44.26191,
    "lng": -69.27714,
    "state_id": "ME"
  },
  {
    "zip": 4863,
    "lat": 44.07898,
    "lng": -68.84384,
    "state_id": "ME"
  },
  {
    "zip": 4864,
    "lat": 44.12946,
    "lng": -69.24481,
    "state_id": "ME"
  },
  {
    "zip": 4901,
    "lat": 44.55442,
    "lng": -69.57048,
    "state_id": "ME"
  },
  {
    "zip": 4910,
    "lat": 44.517,
    "lng": -69.44122,
    "state_id": "ME"
  },
  {
    "zip": 4911,
    "lat": 44.77795,
    "lng": -69.9556,
    "state_id": "ME"
  },
  {
    "zip": 4912,
    "lat": 44.98414,
    "lng": -69.6839,
    "state_id": "ME"
  },
  {
    "zip": 4915,
    "lat": 44.4664,
    "lng": -69.04163,
    "state_id": "ME"
  },
  {
    "zip": 4917,
    "lat": 44.48822,
    "lng": -69.84011,
    "state_id": "ME"
  },
  {
    "zip": 4918,
    "lat": 44.5173,
    "lng": -69.86687,
    "state_id": "ME"
  },
  {
    "zip": 4920,
    "lat": 45.14013,
    "lng": -69.85024,
    "state_id": "ME"
  },
  {
    "zip": 4921,
    "lat": 44.57091,
    "lng": -69.13348,
    "state_id": "ME"
  },
  {
    "zip": 4922,
    "lat": 44.68739,
    "lng": -69.38255,
    "state_id": "ME"
  },
  {
    "zip": 4923,
    "lat": 45.03206,
    "lng": -69.44671,
    "state_id": "ME"
  },
  {
    "zip": 4924,
    "lat": 44.7792,
    "lng": -69.54966,
    "state_id": "ME"
  },
  {
    "zip": 4925,
    "lat": 45.21793,
    "lng": -69.90477,
    "state_id": "ME"
  },
  {
    "zip": 4926,
    "lat": 44.48175,
    "lng": -69.51648,
    "state_id": "ME"
  },
  {
    "zip": 4927,
    "lat": 44.67015,
    "lng": -69.5359,
    "state_id": "ME"
  },
  {
    "zip": 4928,
    "lat": 44.94966,
    "lng": -69.25694,
    "state_id": "ME"
  },
  {
    "zip": 4929,
    "lat": 44.76209,
    "lng": -69.31062,
    "state_id": "ME"
  },
  {
    "zip": 4930,
    "lat": 45.03112,
    "lng": -69.31483,
    "state_id": "ME"
  },
  {
    "zip": 4932,
    "lat": 44.6957,
    "lng": -69.13335,
    "state_id": "ME"
  },
  {
    "zip": 4933,
    "lat": 44.81758,
    "lng": -69.22918,
    "state_id": "ME"
  },
  {
    "zip": 4936,
    "lat": 45.33572,
    "lng": -70.62836,
    "state_id": "ME"
  },
  {
    "zip": 4937,
    "lat": 44.63567,
    "lng": -69.67618,
    "state_id": "ME"
  },
  {
    "zip": 4938,
    "lat": 44.65873,
    "lng": -70.10338,
    "state_id": "ME"
  },
  {
    "zip": 4939,
    "lat": 45.05436,
    "lng": -69.15859,
    "state_id": "ME"
  },
  {
    "zip": 4940,
    "lat": 44.62494,
    "lng": -70.08322,
    "state_id": "ME"
  },
  {
    "zip": 4941,
    "lat": 44.46539,
    "lng": -69.30168,
    "state_id": "ME"
  },
  {
    "zip": 4942,
    "lat": 45.06371,
    "lng": -69.58551,
    "state_id": "ME"
  },
  {
    "zip": 4943,
    "lat": 44.87993,
    "lng": -69.52049,
    "state_id": "ME"
  },
  {
    "zip": 4944,
    "lat": 44.68734,
    "lng": -69.64364,
    "state_id": "ME"
  },
  {
    "zip": 4945,
    "lat": 45.76295,
    "lng": -70.2339,
    "state_id": "ME"
  },
  {
    "zip": 4947,
    "lat": 45.03266,
    "lng": -70.23876,
    "state_id": "ME"
  },
  {
    "zip": 4949,
    "lat": 44.36712,
    "lng": -69.34029,
    "state_id": "ME"
  },
  {
    "zip": 4950,
    "lat": 44.8325,
    "lng": -69.80553,
    "state_id": "ME"
  },
  {
    "zip": 4951,
    "lat": 44.60849,
    "lng": -69.04758,
    "state_id": "ME"
  },
  {
    "zip": 4952,
    "lat": 44.40936,
    "lng": -69.1428,
    "state_id": "ME"
  },
  {
    "zip": 4953,
    "lat": 44.86019,
    "lng": -69.23297,
    "state_id": "ME"
  },
  {
    "zip": 4955,
    "lat": 44.63926,
    "lng": -70.00701,
    "state_id": "ME"
  },
  {
    "zip": 4956,
    "lat": 44.80839,
    "lng": -70.11433,
    "state_id": "ME"
  },
  {
    "zip": 4957,
    "lat": 44.70147,
    "lng": -69.84608,
    "state_id": "ME"
  },
  {
    "zip": 4958,
    "lat": 44.90752,
    "lng": -69.93066,
    "state_id": "ME"
  },
  {
    "zip": 4961,
    "lat": 45.13299,
    "lng": -70.13469,
    "state_id": "ME"
  },
  {
    "zip": 4962,
    "lat": 44.48921,
    "lng": -69.62609,
    "state_id": "ME"
  },
  {
    "zip": 4963,
    "lat": 44.56628,
    "lng": -69.81475,
    "state_id": "ME"
  },
  {
    "zip": 4964,
    "lat": 44.87582,
    "lng": -70.78325,
    "state_id": "ME"
  },
  {
    "zip": 4965,
    "lat": 44.84561,
    "lng": -69.36269,
    "state_id": "ME"
  },
  {
    "zip": 4966,
    "lat": 44.82818,
    "lng": -70.43,
    "state_id": "ME"
  },
  {
    "zip": 4967,
    "lat": 44.77143,
    "lng": -69.43801,
    "state_id": "ME"
  },
  {
    "zip": 4969,
    "lat": 44.77016,
    "lng": -69.22461,
    "state_id": "ME"
  },
  {
    "zip": 4970,
    "lat": 45.00446,
    "lng": -70.63805,
    "state_id": "ME"
  },
  {
    "zip": 4971,
    "lat": 44.93186,
    "lng": -69.39225,
    "state_id": "ME"
  },
  {
    "zip": 4973,
    "lat": 44.36225,
    "lng": -69.19305,
    "state_id": "ME"
  },
  {
    "zip": 4974,
    "lat": 44.48927,
    "lng": -68.93004,
    "state_id": "ME"
  },
  {
    "zip": 4975,
    "lat": 44.62534,
    "lng": -69.58839,
    "state_id": "ME"
  },
  {
    "zip": 4976,
    "lat": 44.79873,
    "lng": -69.66951,
    "state_id": "ME"
  },
  {
    "zip": 4978,
    "lat": 44.62916,
    "lng": -69.8013,
    "state_id": "ME"
  },
  {
    "zip": 4979,
    "lat": 44.94304,
    "lng": -69.79267,
    "state_id": "ME"
  },
  {
    "zip": 4981,
    "lat": 44.53224,
    "lng": -68.85946,
    "state_id": "ME"
  },
  {
    "zip": 4982,
    "lat": 45.1116,
    "lng": -70.423,
    "state_id": "ME"
  },
  {
    "zip": 4983,
    "lat": 44.9228,
    "lng": -70.30926,
    "state_id": "ME"
  },
  {
    "zip": 4984,
    "lat": 44.70129,
    "lng": -70.26216,
    "state_id": "ME"
  },
  {
    "zip": 4985,
    "lat": 45.37082,
    "lng": -69.94295,
    "state_id": "ME"
  },
  {
    "zip": 4986,
    "lat": 44.55294,
    "lng": -69.22252,
    "state_id": "ME"
  },
  {
    "zip": 4987,
    "lat": 44.67915,
    "lng": -69.25361,
    "state_id": "ME"
  },
  {
    "zip": 4988,
    "lat": 44.59588,
    "lng": -69.35972,
    "state_id": "ME"
  },
  {
    "zip": 4989,
    "lat": 44.42988,
    "lng": -69.64786,
    "state_id": "ME"
  },
  {
    "zip": 4992,
    "lat": 44.66302,
    "lng": -70.16006,
    "state_id": "ME"
  },
  {
    "zip": 48001,
    "lat": 42.63776,
    "lng": -82.5796,
    "state_id": "MI"
  },
  {
    "zip": 48002,
    "lat": 42.93908,
    "lng": -82.926,
    "state_id": "MI"
  },
  {
    "zip": 48003,
    "lat": 42.93259,
    "lng": -83.04065,
    "state_id": "MI"
  },
  {
    "zip": 48005,
    "lat": 42.85024,
    "lng": -82.9231,
    "state_id": "MI"
  },
  {
    "zip": 48006,
    "lat": 43.07539,
    "lng": -82.69647,
    "state_id": "MI"
  },
  {
    "zip": 48009,
    "lat": 42.54421,
    "lng": -83.21766,
    "state_id": "MI"
  },
  {
    "zip": 48014,
    "lat": 43.02537,
    "lng": -82.93086,
    "state_id": "MI"
  },
  {
    "zip": 48015,
    "lat": 42.48042,
    "lng": -83.0272,
    "state_id": "MI"
  },
  {
    "zip": 48017,
    "lat": 42.53669,
    "lng": -83.15038,
    "state_id": "MI"
  },
  {
    "zip": 48021,
    "lat": 42.46561,
    "lng": -82.94606,
    "state_id": "MI"
  },
  {
    "zip": 48022,
    "lat": 43.02828,
    "lng": -82.81122,
    "state_id": "MI"
  },
  {
    "zip": 48023,
    "lat": 42.70096,
    "lng": -82.65923,
    "state_id": "MI"
  },
  {
    "zip": 48025,
    "lat": 42.52032,
    "lng": -83.26737,
    "state_id": "MI"
  },
  {
    "zip": 48026,
    "lat": 42.5388,
    "lng": -82.94964,
    "state_id": "MI"
  },
  {
    "zip": 48027,
    "lat": 42.94467,
    "lng": -82.68212,
    "state_id": "MI"
  },
  {
    "zip": 48028,
    "lat": 42.5891,
    "lng": -82.61944,
    "state_id": "MI"
  },
  {
    "zip": 48030,
    "lat": 42.46192,
    "lng": -83.09766,
    "state_id": "MI"
  },
  {
    "zip": 48032,
    "lat": 43.13334,
    "lng": -82.59489,
    "state_id": "MI"
  },
  {
    "zip": 48033,
    "lat": 42.46456,
    "lng": -83.28869,
    "state_id": "MI"
  },
  {
    "zip": 48034,
    "lat": 42.49693,
    "lng": -83.29111,
    "state_id": "MI"
  },
  {
    "zip": 48035,
    "lat": 42.55613,
    "lng": -82.90942,
    "state_id": "MI"
  },
  {
    "zip": 48036,
    "lat": 42.59213,
    "lng": -82.89995,
    "state_id": "MI"
  },
  {
    "zip": 48038,
    "lat": 42.60546,
    "lng": -82.94158,
    "state_id": "MI"
  },
  {
    "zip": 48039,
    "lat": 42.69665,
    "lng": -82.55054,
    "state_id": "MI"
  },
  {
    "zip": 48040,
    "lat": 42.90986,
    "lng": -82.48023,
    "state_id": "MI"
  },
  {
    "zip": 48041,
    "lat": 42.93873,
    "lng": -82.80268,
    "state_id": "MI"
  },
  {
    "zip": 48042,
    "lat": 42.68677,
    "lng": -82.90861,
    "state_id": "MI"
  },
  {
    "zip": 48043,
    "lat": 42.59781,
    "lng": -82.88221,
    "state_id": "MI"
  },
  {
    "zip": 48044,
    "lat": 42.64991,
    "lng": -82.92956,
    "state_id": "MI"
  },
  {
    "zip": 48045,
    "lat": 42.59168,
    "lng": -82.83023,
    "state_id": "MI"
  },
  {
    "zip": 48047,
    "lat": 42.6777,
    "lng": -82.77761,
    "state_id": "MI"
  },
  {
    "zip": 48048,
    "lat": 42.74246,
    "lng": -82.79774,
    "state_id": "MI"
  },
  {
    "zip": 48049,
    "lat": 43.03594,
    "lng": -82.57496,
    "state_id": "MI"
  },
  {
    "zip": 48050,
    "lat": 42.78482,
    "lng": -82.80142,
    "state_id": "MI"
  },
  {
    "zip": 48051,
    "lat": 42.69378,
    "lng": -82.82068,
    "state_id": "MI"
  },
  {
    "zip": 48054,
    "lat": 42.77074,
    "lng": -82.54496,
    "state_id": "MI"
  },
  {
    "zip": 48059,
    "lat": 43.08281,
    "lng": -82.49942,
    "state_id": "MI"
  },
  {
    "zip": 48060,
    "lat": 42.97864,
    "lng": -82.46271,
    "state_id": "MI"
  },
  {
    "zip": 48062,
    "lat": 42.849,
    "lng": -82.79989,
    "state_id": "MI"
  },
  {
    "zip": 48063,
    "lat": 42.85587,
    "lng": -82.67679,
    "state_id": "MI"
  },
  {
    "zip": 48064,
    "lat": 42.76719,
    "lng": -82.67231,
    "state_id": "MI"
  },
  {
    "zip": 48065,
    "lat": 42.84552,
    "lng": -83.0407,
    "state_id": "MI"
  },
  {
    "zip": 48066,
    "lat": 42.50734,
    "lng": -82.93697,
    "state_id": "MI"
  },
  {
    "zip": 48067,
    "lat": 42.49046,
    "lng": -83.13795,
    "state_id": "MI"
  },
  {
    "zip": 48069,
    "lat": 42.4714,
    "lng": -83.14426,
    "state_id": "MI"
  },
  {
    "zip": 48070,
    "lat": 42.48163,
    "lng": -83.16844,
    "state_id": "MI"
  },
  {
    "zip": 48071,
    "lat": 42.5073,
    "lng": -83.10339,
    "state_id": "MI"
  },
  {
    "zip": 48072,
    "lat": 42.49788,
    "lng": -83.18588,
    "state_id": "MI"
  },
  {
    "zip": 48073,
    "lat": 42.5192,
    "lng": -83.16436,
    "state_id": "MI"
  },
  {
    "zip": 48074,
    "lat": 42.94827,
    "lng": -82.56202,
    "state_id": "MI"
  },
  {
    "zip": 48075,
    "lat": 42.46206,
    "lng": -83.23042,
    "state_id": "MI"
  },
  {
    "zip": 48076,
    "lat": 42.4975,
    "lng": -83.23088,
    "state_id": "MI"
  },
  {
    "zip": 48079,
    "lat": 42.85781,
    "lng": -82.54738,
    "state_id": "MI"
  },
  {
    "zip": 48080,
    "lat": 42.46416,
    "lng": -82.89956,
    "state_id": "MI"
  },
  {
    "zip": 48081,
    "lat": 42.49505,
    "lng": -82.89862,
    "state_id": "MI"
  },
  {
    "zip": 48082,
    "lat": 42.52703,
    "lng": -82.88713,
    "state_id": "MI"
  },
  {
    "zip": 48083,
    "lat": 42.55706,
    "lng": -83.11685,
    "state_id": "MI"
  },
  {
    "zip": 48084,
    "lat": 42.56064,
    "lng": -83.17572,
    "state_id": "MI"
  },
  {
    "zip": 48085,
    "lat": 42.60063,
    "lng": -83.11982,
    "state_id": "MI"
  },
  {
    "zip": 48088,
    "lat": 42.51565,
    "lng": -82.98297,
    "state_id": "MI"
  },
  {
    "zip": 48089,
    "lat": 42.47019,
    "lng": -82.99544,
    "state_id": "MI"
  },
  {
    "zip": 48091,
    "lat": 42.46818,
    "lng": -83.05799,
    "state_id": "MI"
  },
  {
    "zip": 48092,
    "lat": 42.51398,
    "lng": -83.05937,
    "state_id": "MI"
  },
  {
    "zip": 48093,
    "lat": 42.51416,
    "lng": -83.01481,
    "state_id": "MI"
  },
  {
    "zip": 48094,
    "lat": 42.73641,
    "lng": -83.03709,
    "state_id": "MI"
  },
  {
    "zip": 48095,
    "lat": 42.7798,
    "lng": -83.03864,
    "state_id": "MI"
  },
  {
    "zip": 48096,
    "lat": 42.76136,
    "lng": -82.92003,
    "state_id": "MI"
  },
  {
    "zip": 48097,
    "lat": 43.126,
    "lng": -82.8295,
    "state_id": "MI"
  },
  {
    "zip": 48098,
    "lat": 42.59907,
    "lng": -83.17888,
    "state_id": "MI"
  },
  {
    "zip": 48101,
    "lat": 42.25987,
    "lng": -83.21061,
    "state_id": "MI"
  },
  {
    "zip": 48103,
    "lat": 42.25991,
    "lng": -83.84325,
    "state_id": "MI"
  },
  {
    "zip": 48104,
    "lat": 42.26425,
    "lng": -83.71703,
    "state_id": "MI"
  },
  {
    "zip": 48105,
    "lat": 42.33168,
    "lng": -83.70705,
    "state_id": "MI"
  },
  {
    "zip": 48108,
    "lat": 42.22217,
    "lng": -83.73181,
    "state_id": "MI"
  },
  {
    "zip": 48109,
    "lat": 42.28357,
    "lng": -83.72398,
    "state_id": "MI"
  },
  {
    "zip": 48111,
    "lat": 42.17903,
    "lng": -83.48676,
    "state_id": "MI"
  },
  {
    "zip": 48114,
    "lat": 42.57002,
    "lng": -83.74835,
    "state_id": "MI"
  },
  {
    "zip": 48116,
    "lat": 42.50513,
    "lng": -83.78199,
    "state_id": "MI"
  },
  {
    "zip": 48117,
    "lat": 42.04999,
    "lng": -83.40922,
    "state_id": "MI"
  },
  {
    "zip": 48118,
    "lat": 42.31257,
    "lng": -84.03874,
    "state_id": "MI"
  },
  {
    "zip": 48120,
    "lat": 42.30697,
    "lng": -83.17394,
    "state_id": "MI"
  },
  {
    "zip": 48122,
    "lat": 42.28024,
    "lng": -83.17807,
    "state_id": "MI"
  },
  {
    "zip": 48124,
    "lat": 42.29832,
    "lng": -83.24756,
    "state_id": "MI"
  },
  {
    "zip": 48125,
    "lat": 42.27791,
    "lng": -83.26539,
    "state_id": "MI"
  },
  {
    "zip": 48126,
    "lat": 42.32556,
    "lng": -83.18336,
    "state_id": "MI"
  },
  {
    "zip": 48127,
    "lat": 42.33659,
    "lng": -83.28293,
    "state_id": "MI"
  },
  {
    "zip": 48128,
    "lat": 42.32043,
    "lng": -83.25926,
    "state_id": "MI"
  },
  {
    "zip": 48130,
    "lat": 42.35419,
    "lng": -83.90324,
    "state_id": "MI"
  },
  {
    "zip": 48131,
    "lat": 41.96572,
    "lng": -83.67235,
    "state_id": "MI"
  },
  {
    "zip": 48133,
    "lat": 41.7812,
    "lng": -83.48594,
    "state_id": "MI"
  },
  {
    "zip": 48134,
    "lat": 42.10714,
    "lng": -83.29339,
    "state_id": "MI"
  },
  {
    "zip": 48135,
    "lat": 42.3244,
    "lng": -83.34119,
    "state_id": "MI"
  },
  {
    "zip": 48137,
    "lat": 42.46219,
    "lng": -84.0736,
    "state_id": "MI"
  },
  {
    "zip": 48138,
    "lat": 42.13309,
    "lng": -83.15577,
    "state_id": "MI"
  },
  {
    "zip": 48139,
    "lat": 42.45237,
    "lng": -83.80585,
    "state_id": "MI"
  },
  {
    "zip": 48140,
    "lat": 41.88134,
    "lng": -83.58142,
    "state_id": "MI"
  },
  {
    "zip": 48141,
    "lat": 42.29349,
    "lng": -83.31483,
    "state_id": "MI"
  },
  {
    "zip": 48143,
    "lat": 42.45982,
    "lng": -83.85196,
    "state_id": "MI"
  },
  {
    "zip": 48144,
    "lat": 41.75327,
    "lng": -83.62835,
    "state_id": "MI"
  },
  {
    "zip": 48145,
    "lat": 41.85061,
    "lng": -83.46329,
    "state_id": "MI"
  },
  {
    "zip": 48146,
    "lat": 42.2433,
    "lng": -83.18113,
    "state_id": "MI"
  },
  {
    "zip": 48150,
    "lat": 42.36843,
    "lng": -83.3726,
    "state_id": "MI"
  },
  {
    "zip": 48152,
    "lat": 42.42583,
    "lng": -83.37459,
    "state_id": "MI"
  },
  {
    "zip": 48154,
    "lat": 42.3971,
    "lng": -83.37291,
    "state_id": "MI"
  },
  {
    "zip": 48157,
    "lat": 41.81451,
    "lng": -83.43854,
    "state_id": "MI"
  },
  {
    "zip": 48158,
    "lat": 42.15601,
    "lng": -84.03098,
    "state_id": "MI"
  },
  {
    "zip": 48159,
    "lat": 42.02416,
    "lng": -83.55357,
    "state_id": "MI"
  },
  {
    "zip": 48160,
    "lat": 42.08085,
    "lng": -83.67886,
    "state_id": "MI"
  },
  {
    "zip": 48161,
    "lat": 41.90805,
    "lng": -83.47199,
    "state_id": "MI"
  },
  {
    "zip": 48162,
    "lat": 41.96032,
    "lng": -83.4356,
    "state_id": "MI"
  },
  {
    "zip": 48164,
    "lat": 42.12697,
    "lng": -83.3909,
    "state_id": "MI"
  },
  {
    "zip": 48165,
    "lat": 42.50135,
    "lng": -83.6176,
    "state_id": "MI"
  },
  {
    "zip": 48166,
    "lat": 41.9863,
    "lng": -83.29408,
    "state_id": "MI"
  },
  {
    "zip": 48167,
    "lat": 42.43528,
    "lng": -83.52917,
    "state_id": "MI"
  },
  {
    "zip": 48168,
    "lat": 42.4063,
    "lng": -83.54044,
    "state_id": "MI"
  },
  {
    "zip": 48169,
    "lat": 42.46279,
    "lng": -83.949,
    "state_id": "MI"
  },
  {
    "zip": 48170,
    "lat": 42.36761,
    "lng": -83.53159,
    "state_id": "MI"
  },
  {
    "zip": 48173,
    "lat": 42.07512,
    "lng": -83.21467,
    "state_id": "MI"
  },
  {
    "zip": 48174,
    "lat": 42.20857,
    "lng": -83.35402,
    "state_id": "MI"
  },
  {
    "zip": 48176,
    "lat": 42.15132,
    "lng": -83.81531,
    "state_id": "MI"
  },
  {
    "zip": 48177,
    "lat": 41.80396,
    "lng": -83.58091,
    "state_id": "MI"
  },
  {
    "zip": 48178,
    "lat": 42.44466,
    "lng": -83.6635,
    "state_id": "MI"
  },
  {
    "zip": 48179,
    "lat": 42.03561,
    "lng": -83.2534,
    "state_id": "MI"
  },
  {
    "zip": 48180,
    "lat": 42.226,
    "lng": -83.26882,
    "state_id": "MI"
  },
  {
    "zip": 48182,
    "lat": 41.79189,
    "lng": -83.57932,
    "state_id": "MI"
  },
  {
    "zip": 48183,
    "lat": 42.13585,
    "lng": -83.21932,
    "state_id": "MI"
  },
  {
    "zip": 48184,
    "lat": 42.27421,
    "lng": -83.39407,
    "state_id": "MI"
  },
  {
    "zip": 48185,
    "lat": 42.33574,
    "lng": -83.38462,
    "state_id": "MI"
  },
  {
    "zip": 48186,
    "lat": 42.29478,
    "lng": -83.37453,
    "state_id": "MI"
  },
  {
    "zip": 48187,
    "lat": 42.32916,
    "lng": -83.48782,
    "state_id": "MI"
  },
  {
    "zip": 48188,
    "lat": 42.28563,
    "lng": -83.48588,
    "state_id": "MI"
  },
  {
    "zip": 48189,
    "lat": 42.41212,
    "lng": -83.78537,
    "state_id": "MI"
  },
  {
    "zip": 48190,
    "lat": 42.13155,
    "lng": -83.59377,
    "state_id": "MI"
  },
  {
    "zip": 48191,
    "lat": 42.11987,
    "lng": -83.564,
    "state_id": "MI"
  },
  {
    "zip": 48192,
    "lat": 42.20834,
    "lng": -83.16162,
    "state_id": "MI"
  },
  {
    "zip": 48193,
    "lat": 42.17378,
    "lng": -83.20944,
    "state_id": "MI"
  },
  {
    "zip": 48195,
    "lat": 42.20472,
    "lng": -83.20589,
    "state_id": "MI"
  },
  {
    "zip": 48197,
    "lat": 42.19714,
    "lng": -83.62885,
    "state_id": "MI"
  },
  {
    "zip": 48198,
    "lat": 42.27549,
    "lng": -83.58835,
    "state_id": "MI"
  },
  {
    "zip": 48201,
    "lat": 42.34704,
    "lng": -83.06014,
    "state_id": "MI"
  },
  {
    "zip": 48202,
    "lat": 42.37483,
    "lng": -83.07771,
    "state_id": "MI"
  },
  {
    "zip": 48203,
    "lat": 42.42166,
    "lng": -83.10232,
    "state_id": "MI"
  },
  {
    "zip": 48204,
    "lat": 42.36576,
    "lng": -83.14294,
    "state_id": "MI"
  },
  {
    "zip": 48205,
    "lat": 42.43315,
    "lng": -82.9811,
    "state_id": "MI"
  },
  {
    "zip": 48206,
    "lat": 42.3751,
    "lng": -83.10785,
    "state_id": "MI"
  },
  {
    "zip": 48207,
    "lat": 42.3493,
    "lng": -83.0148,
    "state_id": "MI"
  },
  {
    "zip": 48208,
    "lat": 42.34852,
    "lng": -83.09192,
    "state_id": "MI"
  },
  {
    "zip": 48209,
    "lat": 42.30535,
    "lng": -83.11626,
    "state_id": "MI"
  },
  {
    "zip": 48210,
    "lat": 42.33622,
    "lng": -83.12832,
    "state_id": "MI"
  },
  {
    "zip": 48211,
    "lat": 42.38134,
    "lng": -83.04578,
    "state_id": "MI"
  },
  {
    "zip": 48212,
    "lat": 42.40942,
    "lng": -83.05601,
    "state_id": "MI"
  },
  {
    "zip": 48213,
    "lat": 42.39794,
    "lng": -82.9954,
    "state_id": "MI"
  },
  {
    "zip": 48214,
    "lat": 42.36508,
    "lng": -82.98744,
    "state_id": "MI"
  },
  {
    "zip": 48215,
    "lat": 42.37512,
    "lng": -82.95445,
    "state_id": "MI"
  },
  {
    "zip": 48216,
    "lat": 42.32655,
    "lng": -83.07873,
    "state_id": "MI"
  },
  {
    "zip": 48217,
    "lat": 42.27806,
    "lng": -83.15399,
    "state_id": "MI"
  },
  {
    "zip": 48218,
    "lat": 42.27317,
    "lng": -83.12728,
    "state_id": "MI"
  },
  {
    "zip": 48219,
    "lat": 42.42575,
    "lng": -83.25286,
    "state_id": "MI"
  },
  {
    "zip": 48220,
    "lat": 42.45807,
    "lng": -83.13521,
    "state_id": "MI"
  },
  {
    "zip": 48221,
    "lat": 42.42703,
    "lng": -83.14869,
    "state_id": "MI"
  },
  {
    "zip": 48223,
    "lat": 42.39343,
    "lng": -83.24641,
    "state_id": "MI"
  },
  {
    "zip": 48224,
    "lat": 42.41067,
    "lng": -82.94128,
    "state_id": "MI"
  },
  {
    "zip": 48225,
    "lat": 42.43897,
    "lng": -82.92948,
    "state_id": "MI"
  },
  {
    "zip": 48226,
    "lat": 42.33168,
    "lng": -83.05016,
    "state_id": "MI"
  },
  {
    "zip": 48227,
    "lat": 42.38725,
    "lng": -83.19264,
    "state_id": "MI"
  },
  {
    "zip": 48228,
    "lat": 42.35545,
    "lng": -83.21705,
    "state_id": "MI"
  },
  {
    "zip": 48229,
    "lat": 42.25087,
    "lng": -83.14284,
    "state_id": "MI"
  },
  {
    "zip": 48230,
    "lat": 42.38324,
    "lng": -82.92332,
    "state_id": "MI"
  },
  {
    "zip": 48233,
    "lat": 42.3237,
    "lng": -83.06166,
    "state_id": "MI"
  },
  {
    "zip": 48234,
    "lat": 42.43127,
    "lng": -83.03958,
    "state_id": "MI"
  },
  {
    "zip": 48235,
    "lat": 42.42704,
    "lng": -83.1948,
    "state_id": "MI"
  },
  {
    "zip": 48236,
    "lat": 42.42502,
    "lng": -82.89655,
    "state_id": "MI"
  },
  {
    "zip": 48237,
    "lat": 42.46494,
    "lng": -83.18237,
    "state_id": "MI"
  },
  {
    "zip": 48238,
    "lat": 42.39627,
    "lng": -83.14133,
    "state_id": "MI"
  },
  {
    "zip": 48239,
    "lat": 42.37587,
    "lng": -83.28466,
    "state_id": "MI"
  },
  {
    "zip": 48240,
    "lat": 42.42445,
    "lng": -83.30128,
    "state_id": "MI"
  },
  {
    "zip": 48242,
    "lat": 42.212,
    "lng": -83.35249,
    "state_id": "MI"
  },
  {
    "zip": 48243,
    "lat": 42.32995,
    "lng": -83.03954,
    "state_id": "MI"
  },
  {
    "zip": 48301,
    "lat": 42.54444,
    "lng": -83.28284,
    "state_id": "MI"
  },
  {
    "zip": 48302,
    "lat": 42.58569,
    "lng": -83.29526,
    "state_id": "MI"
  },
  {
    "zip": 48304,
    "lat": 42.58765,
    "lng": -83.23463,
    "state_id": "MI"
  },
  {
    "zip": 48306,
    "lat": 42.72323,
    "lng": -83.14914,
    "state_id": "MI"
  },
  {
    "zip": 48307,
    "lat": 42.65958,
    "lng": -83.12322,
    "state_id": "MI"
  },
  {
    "zip": 48309,
    "lat": 42.65864,
    "lng": -83.18367,
    "state_id": "MI"
  },
  {
    "zip": 48310,
    "lat": 42.56436,
    "lng": -83.06782,
    "state_id": "MI"
  },
  {
    "zip": 48312,
    "lat": 42.55846,
    "lng": -83.00941,
    "state_id": "MI"
  },
  {
    "zip": 48313,
    "lat": 42.59956,
    "lng": -83.00153,
    "state_id": "MI"
  },
  {
    "zip": 48314,
    "lat": 42.60989,
    "lng": -83.05431,
    "state_id": "MI"
  },
  {
    "zip": 48315,
    "lat": 42.6729,
    "lng": -82.99631,
    "state_id": "MI"
  },
  {
    "zip": 48316,
    "lat": 42.69064,
    "lng": -83.0568,
    "state_id": "MI"
  },
  {
    "zip": 48317,
    "lat": 42.64676,
    "lng": -83.05315,
    "state_id": "MI"
  },
  {
    "zip": 48320,
    "lat": 42.61261,
    "lng": -83.33801,
    "state_id": "MI"
  },
  {
    "zip": 48322,
    "lat": 42.54215,
    "lng": -83.38137,
    "state_id": "MI"
  },
  {
    "zip": 48323,
    "lat": 42.57032,
    "lng": -83.37617,
    "state_id": "MI"
  },
  {
    "zip": 48324,
    "lat": 42.59614,
    "lng": -83.39263,
    "state_id": "MI"
  },
  {
    "zip": 48326,
    "lat": 42.67577,
    "lng": -83.25332,
    "state_id": "MI"
  },
  {
    "zip": 48327,
    "lat": 42.64421,
    "lng": -83.41415,
    "state_id": "MI"
  },
  {
    "zip": 48328,
    "lat": 42.64382,
    "lng": -83.35626,
    "state_id": "MI"
  },
  {
    "zip": 48329,
    "lat": 42.68839,
    "lng": -83.38888,
    "state_id": "MI"
  },
  {
    "zip": 48331,
    "lat": 42.50399,
    "lng": -83.40853,
    "state_id": "MI"
  },
  {
    "zip": 48334,
    "lat": 42.50667,
    "lng": -83.34946,
    "state_id": "MI"
  },
  {
    "zip": 48335,
    "lat": 42.46285,
    "lng": -83.40199,
    "state_id": "MI"
  },
  {
    "zip": 48336,
    "lat": 42.4631,
    "lng": -83.34727,
    "state_id": "MI"
  },
  {
    "zip": 48340,
    "lat": 42.67083,
    "lng": -83.29099,
    "state_id": "MI"
  },
  {
    "zip": 48341,
    "lat": 42.62781,
    "lng": -83.29636,
    "state_id": "MI"
  },
  {
    "zip": 48342,
    "lat": 42.64337,
    "lng": -83.27435,
    "state_id": "MI"
  },
  {
    "zip": 48346,
    "lat": 42.72155,
    "lng": -83.42304,
    "state_id": "MI"
  },
  {
    "zip": 48348,
    "lat": 42.76696,
    "lng": -83.40128,
    "state_id": "MI"
  },
  {
    "zip": 48350,
    "lat": 42.74084,
    "lng": -83.53218,
    "state_id": "MI"
  },
  {
    "zip": 48353,
    "lat": 42.64765,
    "lng": -83.71682,
    "state_id": "MI"
  },
  {
    "zip": 48356,
    "lat": 42.65507,
    "lng": -83.58926,
    "state_id": "MI"
  },
  {
    "zip": 48357,
    "lat": 42.65606,
    "lng": -83.64388,
    "state_id": "MI"
  },
  {
    "zip": 48359,
    "lat": 42.72202,
    "lng": -83.27878,
    "state_id": "MI"
  },
  {
    "zip": 48360,
    "lat": 42.74934,
    "lng": -83.269,
    "state_id": "MI"
  },
  {
    "zip": 48362,
    "lat": 42.78226,
    "lng": -83.26969,
    "state_id": "MI"
  },
  {
    "zip": 48363,
    "lat": 42.77285,
    "lng": -83.1618,
    "state_id": "MI"
  },
  {
    "zip": 48367,
    "lat": 42.84283,
    "lng": -83.14145,
    "state_id": "MI"
  },
  {
    "zip": 48370,
    "lat": 42.84124,
    "lng": -83.20084,
    "state_id": "MI"
  },
  {
    "zip": 48371,
    "lat": 42.84121,
    "lng": -83.29086,
    "state_id": "MI"
  },
  {
    "zip": 48374,
    "lat": 42.47081,
    "lng": -83.52386,
    "state_id": "MI"
  },
  {
    "zip": 48375,
    "lat": 42.46482,
    "lng": -83.46335,
    "state_id": "MI"
  },
  {
    "zip": 48377,
    "lat": 42.50648,
    "lng": -83.47275,
    "state_id": "MI"
  },
  {
    "zip": 48380,
    "lat": 42.58073,
    "lng": -83.66216,
    "state_id": "MI"
  },
  {
    "zip": 48381,
    "lat": 42.56168,
    "lng": -83.5942,
    "state_id": "MI"
  },
  {
    "zip": 48382,
    "lat": 42.59073,
    "lng": -83.50585,
    "state_id": "MI"
  },
  {
    "zip": 48383,
    "lat": 42.65789,
    "lng": -83.53215,
    "state_id": "MI"
  },
  {
    "zip": 48386,
    "lat": 42.65786,
    "lng": -83.47527,
    "state_id": "MI"
  },
  {
    "zip": 48390,
    "lat": 42.55142,
    "lng": -83.47637,
    "state_id": "MI"
  },
  {
    "zip": 48393,
    "lat": 42.52113,
    "lng": -83.54507,
    "state_id": "MI"
  },
  {
    "zip": 48397,
    "lat": 42.49501,
    "lng": -83.04105,
    "state_id": "MI"
  },
  {
    "zip": 48401,
    "lat": 43.3527,
    "lng": -82.66725,
    "state_id": "MI"
  },
  {
    "zip": 48411,
    "lat": 42.93608,
    "lng": -83.53083,
    "state_id": "MI"
  },
  {
    "zip": 48412,
    "lat": 43.06287,
    "lng": -83.17079,
    "state_id": "MI"
  },
  {
    "zip": 48413,
    "lat": 43.80193,
    "lng": -82.99474,
    "state_id": "MI"
  },
  {
    "zip": 48414,
    "lat": 42.86178,
    "lng": -84.07879,
    "state_id": "MI"
  },
  {
    "zip": 48415,
    "lat": 43.27114,
    "lng": -83.80094,
    "state_id": "MI"
  },
  {
    "zip": 48416,
    "lat": 43.21671,
    "lng": -82.98451,
    "state_id": "MI"
  },
  {
    "zip": 48417,
    "lat": 43.26049,
    "lng": -83.93595,
    "state_id": "MI"
  },
  {
    "zip": 48418,
    "lat": 42.80426,
    "lng": -83.97019,
    "state_id": "MI"
  },
  {
    "zip": 48419,
    "lat": 43.42927,
    "lng": -82.65461,
    "state_id": "MI"
  },
  {
    "zip": 48420,
    "lat": 43.17959,
    "lng": -83.71417,
    "state_id": "MI"
  },
  {
    "zip": 48421,
    "lat": 43.1537,
    "lng": -83.39208,
    "state_id": "MI"
  },
  {
    "zip": 48422,
    "lat": 43.26263,
    "lng": -82.65059,
    "state_id": "MI"
  },
  {
    "zip": 48423,
    "lat": 43.0398,
    "lng": -83.51346,
    "state_id": "MI"
  },
  {
    "zip": 48426,
    "lat": 43.51006,
    "lng": -83.05897,
    "state_id": "MI"
  },
  {
    "zip": 48427,
    "lat": 43.53798,
    "lng": -82.72406,
    "state_id": "MI"
  },
  {
    "zip": 48428,
    "lat": 42.93844,
    "lng": -83.14816,
    "state_id": "MI"
  },
  {
    "zip": 48429,
    "lat": 42.9068,
    "lng": -84.00369,
    "state_id": "MI"
  },
  {
    "zip": 48430,
    "lat": 42.75837,
    "lng": -83.74277,
    "state_id": "MI"
  },
  {
    "zip": 48432,
    "lat": 43.89911,
    "lng": -82.97668,
    "state_id": "MI"
  },
  {
    "zip": 48433,
    "lat": 43.07575,
    "lng": -83.86625,
    "state_id": "MI"
  },
  {
    "zip": 48434,
    "lat": 43.66187,
    "lng": -82.61168,
    "state_id": "MI"
  },
  {
    "zip": 48435,
    "lat": 43.24828,
    "lng": -83.35811,
    "state_id": "MI"
  },
  {
    "zip": 48436,
    "lat": 42.86708,
    "lng": -83.87327,
    "state_id": "MI"
  },
  {
    "zip": 48437,
    "lat": 43.11231,
    "lng": -83.61542,
    "state_id": "MI"
  },
  {
    "zip": 48438,
    "lat": 42.91236,
    "lng": -83.47688,
    "state_id": "MI"
  },
  {
    "zip": 48439,
    "lat": 42.91681,
    "lng": -83.62911,
    "state_id": "MI"
  },
  {
    "zip": 48440,
    "lat": 42.95066,
    "lng": -83.41234,
    "state_id": "MI"
  },
  {
    "zip": 48441,
    "lat": 43.8062,
    "lng": -82.71738,
    "state_id": "MI"
  },
  {
    "zip": 48442,
    "lat": 42.79164,
    "lng": -83.60347,
    "state_id": "MI"
  },
  {
    "zip": 48444,
    "lat": 43.06499,
    "lng": -83.05511,
    "state_id": "MI"
  },
  {
    "zip": 48445,
    "lat": 43.95158,
    "lng": -82.98696,
    "state_id": "MI"
  },
  {
    "zip": 48446,
    "lat": 43.05355,
    "lng": -83.33761,
    "state_id": "MI"
  },
  {
    "zip": 48449,
    "lat": 42.9949,
    "lng": -83.94622,
    "state_id": "MI"
  },
  {
    "zip": 48450,
    "lat": 43.25872,
    "lng": -82.54203,
    "state_id": "MI"
  },
  {
    "zip": 48451,
    "lat": 42.80266,
    "lng": -83.81948,
    "state_id": "MI"
  },
  {
    "zip": 48453,
    "lat": 43.34412,
    "lng": -83.03193,
    "state_id": "MI"
  },
  {
    "zip": 48454,
    "lat": 43.19918,
    "lng": -82.81951,
    "state_id": "MI"
  },
  {
    "zip": 48455,
    "lat": 42.93886,
    "lng": -83.2892,
    "state_id": "MI"
  },
  {
    "zip": 48456,
    "lat": 43.67026,
    "lng": -82.75953,
    "state_id": "MI"
  },
  {
    "zip": 48457,
    "lat": 43.18943,
    "lng": -83.89564,
    "state_id": "MI"
  },
  {
    "zip": 48458,
    "lat": 43.12225,
    "lng": -83.68221,
    "state_id": "MI"
  },
  {
    "zip": 48460,
    "lat": 43.1282,
    "lng": -83.98722,
    "state_id": "MI"
  },
  {
    "zip": 48461,
    "lat": 43.204,
    "lng": -83.20452,
    "state_id": "MI"
  },
  {
    "zip": 48462,
    "lat": 42.8489,
    "lng": -83.42665,
    "state_id": "MI"
  },
  {
    "zip": 48463,
    "lat": 43.16587,
    "lng": -83.52301,
    "state_id": "MI"
  },
  {
    "zip": 48464,
    "lat": 43.21906,
    "lng": -83.42104,
    "state_id": "MI"
  },
  {
    "zip": 48465,
    "lat": 43.6146,
    "lng": -82.70806,
    "state_id": "MI"
  },
  {
    "zip": 48466,
    "lat": 43.27695,
    "lng": -82.81672,
    "state_id": "MI"
  },
  {
    "zip": 48467,
    "lat": 44.00834,
    "lng": -82.98494,
    "state_id": "MI"
  },
  {
    "zip": 48468,
    "lat": 43.94225,
    "lng": -82.78773,
    "state_id": "MI"
  },
  {
    "zip": 48469,
    "lat": 43.43925,
    "lng": -82.5583,
    "state_id": "MI"
  },
  {
    "zip": 48470,
    "lat": 43.73254,
    "lng": -82.75783,
    "state_id": "MI"
  },
  {
    "zip": 48471,
    "lat": 43.41814,
    "lng": -82.85228,
    "state_id": "MI"
  },
  {
    "zip": 48472,
    "lat": 43.51347,
    "lng": -82.95538,
    "state_id": "MI"
  },
  {
    "zip": 48473,
    "lat": 42.93991,
    "lng": -83.82618,
    "state_id": "MI"
  },
  {
    "zip": 48475,
    "lat": 43.67062,
    "lng": -82.93945,
    "state_id": "MI"
  },
  {
    "zip": 48476,
    "lat": 42.9385,
    "lng": -84.02992,
    "state_id": "MI"
  },
  {
    "zip": 48502,
    "lat": 43.01359,
    "lng": -83.68855,
    "state_id": "MI"
  },
  {
    "zip": 48503,
    "lat": 43.01179,
    "lng": -83.6885,
    "state_id": "MI"
  },
  {
    "zip": 48504,
    "lat": 43.05734,
    "lng": -83.74982,
    "state_id": "MI"
  },
  {
    "zip": 48505,
    "lat": 43.07042,
    "lng": -83.68744,
    "state_id": "MI"
  },
  {
    "zip": 48506,
    "lat": 43.06784,
    "lng": -83.62161,
    "state_id": "MI"
  },
  {
    "zip": 48507,
    "lat": 42.96539,
    "lng": -83.71547,
    "state_id": "MI"
  },
  {
    "zip": 48509,
    "lat": 43.02616,
    "lng": -83.60528,
    "state_id": "MI"
  },
  {
    "zip": 48519,
    "lat": 42.98752,
    "lng": -83.60693,
    "state_id": "MI"
  },
  {
    "zip": 48529,
    "lat": 42.97245,
    "lng": -83.6618,
    "state_id": "MI"
  },
  {
    "zip": 48532,
    "lat": 43.01239,
    "lng": -83.79085,
    "state_id": "MI"
  },
  {
    "zip": 48551,
    "lat": 42.98103,
    "lng": -83.71691,
    "state_id": "MI"
  },
  {
    "zip": 48553,
    "lat": 42.97781,
    "lng": -83.72352,
    "state_id": "MI"
  },
  {
    "zip": 48554,
    "lat": 42.97584,
    "lng": -83.79096,
    "state_id": "MI"
  },
  {
    "zip": 48601,
    "lat": 43.40416,
    "lng": -83.89375,
    "state_id": "MI"
  },
  {
    "zip": 48602,
    "lat": 43.41969,
    "lng": -83.97466,
    "state_id": "MI"
  },
  {
    "zip": 48603,
    "lat": 43.46313,
    "lng": -84.02967,
    "state_id": "MI"
  },
  {
    "zip": 48604,
    "lat": 43.49444,
    "lng": -83.96255,
    "state_id": "MI"
  },
  {
    "zip": 48607,
    "lat": 43.43177,
    "lng": -83.9338,
    "state_id": "MI"
  },
  {
    "zip": 48609,
    "lat": 43.38736,
    "lng": -84.08797,
    "state_id": "MI"
  },
  {
    "zip": 48610,
    "lat": 44.12834,
    "lng": -84.17435,
    "state_id": "MI"
  },
  {
    "zip": 48611,
    "lat": 43.63183,
    "lng": -84.09311,
    "state_id": "MI"
  },
  {
    "zip": 48612,
    "lat": 43.88113,
    "lng": -84.43196,
    "state_id": "MI"
  },
  {
    "zip": 48613,
    "lat": 43.9532,
    "lng": -84.16778,
    "state_id": "MI"
  },
  {
    "zip": 48614,
    "lat": 43.25053,
    "lng": -84.3025,
    "state_id": "MI"
  },
  {
    "zip": 48615,
    "lat": 43.42948,
    "lng": -84.47145,
    "state_id": "MI"
  },
  {
    "zip": 48616,
    "lat": 43.19288,
    "lng": -84.11164,
    "state_id": "MI"
  },
  {
    "zip": 48617,
    "lat": 43.84684,
    "lng": -84.71585,
    "state_id": "MI"
  },
  {
    "zip": 48618,
    "lat": 43.73725,
    "lng": -84.56022,
    "state_id": "MI"
  },
  {
    "zip": 48619,
    "lat": 44.82455,
    "lng": -84.03134,
    "state_id": "MI"
  },
  {
    "zip": 48621,
    "lat": 44.72406,
    "lng": -83.99442,
    "state_id": "MI"
  },
  {
    "zip": 48622,
    "lat": 43.84005,
    "lng": -84.88039,
    "state_id": "MI"
  },
  {
    "zip": 48623,
    "lat": 43.51988,
    "lng": -84.13557,
    "state_id": "MI"
  },
  {
    "zip": 48624,
    "lat": 44.06207,
    "lng": -84.46659,
    "state_id": "MI"
  },
  {
    "zip": 48625,
    "lat": 44.05402,
    "lng": -84.83132,
    "state_id": "MI"
  },
  {
    "zip": 48626,
    "lat": 43.42214,
    "lng": -84.22535,
    "state_id": "MI"
  },
  {
    "zip": 48627,
    "lat": 44.46165,
    "lng": -84.74959,
    "state_id": "MI"
  },
  {
    "zip": 48628,
    "lat": 43.7835,
    "lng": -84.32883,
    "state_id": "MI"
  },
  {
    "zip": 48629,
    "lat": 44.31164,
    "lng": -84.77662,
    "state_id": "MI"
  },
  {
    "zip": 48630,
    "lat": 44.32478,
    "lng": -84.77246,
    "state_id": "MI"
  },
  {
    "zip": 48631,
    "lat": 43.68516,
    "lng": -84.001,
    "state_id": "MI"
  },
  {
    "zip": 48632,
    "lat": 43.85751,
    "lng": -85.02182,
    "state_id": "MI"
  },
  {
    "zip": 48633,
    "lat": 43.93715,
    "lng": -84.93121,
    "state_id": "MI"
  },
  {
    "zip": 48634,
    "lat": 43.75358,
    "lng": -84.06081,
    "state_id": "MI"
  },
  {
    "zip": 48635,
    "lat": 44.39543,
    "lng": -84.00128,
    "state_id": "MI"
  },
  {
    "zip": 48636,
    "lat": 44.60008,
    "lng": -84.29318,
    "state_id": "MI"
  },
  {
    "zip": 48637,
    "lat": 43.41327,
    "lng": -84.33678,
    "state_id": "MI"
  },
  {
    "zip": 48638,
    "lat": 43.41704,
    "lng": -84.01958,
    "state_id": "MI"
  },
  {
    "zip": 48640,
    "lat": 43.58564,
    "lng": -84.33573,
    "state_id": "MI"
  },
  {
    "zip": 48642,
    "lat": 43.70615,
    "lng": -84.22942,
    "state_id": "MI"
  },
  {
    "zip": 48647,
    "lat": 44.65892,
    "lng": -84.10486,
    "state_id": "MI"
  },
  {
    "zip": 48649,
    "lat": 43.15577,
    "lng": -84.21534,
    "state_id": "MI"
  },
  {
    "zip": 48650,
    "lat": 43.8503,
    "lng": -84.01348,
    "state_id": "MI"
  },
  {
    "zip": 48651,
    "lat": 44.25569,
    "lng": -84.6156,
    "state_id": "MI"
  },
  {
    "zip": 48652,
    "lat": 43.85571,
    "lng": -84.20511,
    "state_id": "MI"
  },
  {
    "zip": 48653,
    "lat": 44.48783,
    "lng": -84.60048,
    "state_id": "MI"
  },
  {
    "zip": 48654,
    "lat": 44.45598,
    "lng": -84.13163,
    "state_id": "MI"
  },
  {
    "zip": 48655,
    "lat": 43.29223,
    "lng": -84.15912,
    "state_id": "MI"
  },
  {
    "zip": 48656,
    "lat": 44.34819,
    "lng": -84.44879,
    "state_id": "MI"
  },
  {
    "zip": 48657,
    "lat": 43.7109,
    "lng": -84.42066,
    "state_id": "MI"
  },
  {
    "zip": 48658,
    "lat": 43.98067,
    "lng": -83.93686,
    "state_id": "MI"
  },
  {
    "zip": 48659,
    "lat": 44.06686,
    "lng": -84.05049,
    "state_id": "MI"
  },
  {
    "zip": 48661,
    "lat": 44.3289,
    "lng": -84.24079,
    "state_id": "MI"
  },
  {
    "zip": 48662,
    "lat": 43.41115,
    "lng": -84.41098,
    "state_id": "MI"
  },
  {
    "zip": 48667,
    "lat": 43.60217,
    "lng": -84.23409,
    "state_id": "MI"
  },
  {
    "zip": 48701,
    "lat": 43.58606,
    "lng": -83.54525,
    "state_id": "MI"
  },
  {
    "zip": 48703,
    "lat": 44.0631,
    "lng": -83.67161,
    "state_id": "MI"
  },
  {
    "zip": 48705,
    "lat": 44.70086,
    "lng": -83.65359,
    "state_id": "MI"
  },
  {
    "zip": 48706,
    "lat": 43.59715,
    "lng": -83.95464,
    "state_id": "MI"
  },
  {
    "zip": 48708,
    "lat": 43.5619,
    "lng": -83.84505,
    "state_id": "MI"
  },
  {
    "zip": 48710,
    "lat": 43.55649,
    "lng": -83.98781,
    "state_id": "MI"
  },
  {
    "zip": 48720,
    "lat": 43.83237,
    "lng": -83.35022,
    "state_id": "MI"
  },
  {
    "zip": 48721,
    "lat": 44.80516,
    "lng": -83.34552,
    "state_id": "MI"
  },
  {
    "zip": 48722,
    "lat": 43.34833,
    "lng": -83.8418,
    "state_id": "MI"
  },
  {
    "zip": 48723,
    "lat": 43.4856,
    "lng": -83.387,
    "state_id": "MI"
  },
  {
    "zip": 48724,
    "lat": 43.46004,
    "lng": -83.92283,
    "state_id": "MI"
  },
  {
    "zip": 48725,
    "lat": 43.93738,
    "lng": -83.2071,
    "state_id": "MI"
  },
  {
    "zip": 48726,
    "lat": 43.60769,
    "lng": -83.16911,
    "state_id": "MI"
  },
  {
    "zip": 48727,
    "lat": 43.30975,
    "lng": -83.17566,
    "state_id": "MI"
  },
  {
    "zip": 48728,
    "lat": 44.73606,
    "lng": -83.82747,
    "state_id": "MI"
  },
  {
    "zip": 48729,
    "lat": 43.49495,
    "lng": -83.17857,
    "state_id": "MI"
  },
  {
    "zip": 48730,
    "lat": 44.34876,
    "lng": -83.47091,
    "state_id": "MI"
  },
  {
    "zip": 48731,
    "lat": 43.84084,
    "lng": -83.1587,
    "state_id": "MI"
  },
  {
    "zip": 48732,
    "lat": 43.60666,
    "lng": -83.78266,
    "state_id": "MI"
  },
  {
    "zip": 48733,
    "lat": 43.53286,
    "lng": -83.5907,
    "state_id": "MI"
  },
  {
    "zip": 48734,
    "lat": 43.35437,
    "lng": -83.74436,
    "state_id": "MI"
  },
  {
    "zip": 48735,
    "lat": 43.66824,
    "lng": -83.27345,
    "state_id": "MI"
  },
  {
    "zip": 48737,
    "lat": 44.55041,
    "lng": -83.69879,
    "state_id": "MI"
  },
  {
    "zip": 48738,
    "lat": 44.55187,
    "lng": -83.33286,
    "state_id": "MI"
  },
  {
    "zip": 48739,
    "lat": 44.37289,
    "lng": -83.84006,
    "state_id": "MI"
  },
  {
    "zip": 48740,
    "lat": 44.66021,
    "lng": -83.37952,
    "state_id": "MI"
  },
  {
    "zip": 48741,
    "lat": 43.40514,
    "lng": -83.18348,
    "state_id": "MI"
  },
  {
    "zip": 48742,
    "lat": 44.72833,
    "lng": -83.4436,
    "state_id": "MI"
  },
  {
    "zip": 48743,
    "lat": 44.43963,
    "lng": -83.86964,
    "state_id": "MI"
  },
  {
    "zip": 48744,
    "lat": 43.3477,
    "lng": -83.36059,
    "state_id": "MI"
  },
  {
    "zip": 48745,
    "lat": 44.56994,
    "lng": -83.47269,
    "state_id": "MI"
  },
  {
    "zip": 48746,
    "lat": 43.265,
    "lng": -83.54281,
    "state_id": "MI"
  },
  {
    "zip": 48747,
    "lat": 43.52176,
    "lng": -83.76125,
    "state_id": "MI"
  },
  {
    "zip": 48748,
    "lat": 44.30406,
    "lng": -83.66737,
    "state_id": "MI"
  },
  {
    "zip": 48749,
    "lat": 44.0546,
    "lng": -83.88724,
    "state_id": "MI"
  },
  {
    "zip": 48750,
    "lat": 44.44325,
    "lng": -83.45426,
    "state_id": "MI"
  },
  {
    "zip": 48754,
    "lat": 43.73368,
    "lng": -83.23591,
    "state_id": "MI"
  },
  {
    "zip": 48755,
    "lat": 43.83422,
    "lng": -83.27021,
    "state_id": "MI"
  },
  {
    "zip": 48756,
    "lat": 44.22939,
    "lng": -83.97408,
    "state_id": "MI"
  },
  {
    "zip": 48757,
    "lat": 43.46941,
    "lng": -83.67944,
    "state_id": "MI"
  },
  {
    "zip": 48759,
    "lat": 43.72871,
    "lng": -83.39381,
    "state_id": "MI"
  },
  {
    "zip": 48760,
    "lat": 43.32116,
    "lng": -83.25754,
    "state_id": "MI"
  },
  {
    "zip": 48761,
    "lat": 44.51192,
    "lng": -83.8924,
    "state_id": "MI"
  },
  {
    "zip": 48762,
    "lat": 44.81744,
    "lng": -83.4888,
    "state_id": "MI"
  },
  {
    "zip": 48763,
    "lat": 44.25253,
    "lng": -83.61271,
    "state_id": "MI"
  },
  {
    "zip": 48765,
    "lat": 44.15241,
    "lng": -83.7179,
    "state_id": "MI"
  },
  {
    "zip": 48766,
    "lat": 44.11105,
    "lng": -83.84696,
    "state_id": "MI"
  },
  {
    "zip": 48767,
    "lat": 43.64224,
    "lng": -83.45716,
    "state_id": "MI"
  },
  {
    "zip": 48768,
    "lat": 43.37136,
    "lng": -83.57789,
    "state_id": "MI"
  },
  {
    "zip": 48770,
    "lat": 44.24876,
    "lng": -83.82086,
    "state_id": "MI"
  },
  {
    "zip": 48801,
    "lat": 43.3777,
    "lng": -84.67974,
    "state_id": "MI"
  },
  {
    "zip": 48806,
    "lat": 43.18499,
    "lng": -84.49754,
    "state_id": "MI"
  },
  {
    "zip": 48807,
    "lat": 43.15305,
    "lng": -84.40478,
    "state_id": "MI"
  },
  {
    "zip": 48808,
    "lat": 42.82554,
    "lng": -84.44828,
    "state_id": "MI"
  },
  {
    "zip": 48809,
    "lat": 43.06537,
    "lng": -85.25823,
    "state_id": "MI"
  },
  {
    "zip": 48811,
    "lat": 43.1811,
    "lng": -84.855,
    "state_id": "MI"
  },
  {
    "zip": 48813,
    "lat": 42.58015,
    "lng": -84.8454,
    "state_id": "MI"
  },
  {
    "zip": 48815,
    "lat": 42.84126,
    "lng": -85.25708,
    "state_id": "MI"
  },
  {
    "zip": 48816,
    "lat": 42.76398,
    "lng": -83.95447,
    "state_id": "MI"
  },
  {
    "zip": 48817,
    "lat": 43.01789,
    "lng": -84.03859,
    "state_id": "MI"
  },
  {
    "zip": 48818,
    "lat": 43.27546,
    "lng": -84.89218,
    "state_id": "MI"
  },
  {
    "zip": 48819,
    "lat": 42.54638,
    "lng": -84.28531,
    "state_id": "MI"
  },
  {
    "zip": 48820,
    "lat": 42.86302,
    "lng": -84.5929,
    "state_id": "MI"
  },
  {
    "zip": 48821,
    "lat": 42.63938,
    "lng": -84.64707,
    "state_id": "MI"
  },
  {
    "zip": 48822,
    "lat": 42.83332,
    "lng": -84.76068,
    "state_id": "MI"
  },
  {
    "zip": 48823,
    "lat": 42.76191,
    "lng": -84.45389,
    "state_id": "MI"
  },
  {
    "zip": 48825,
    "lat": 42.72701,
    "lng": -84.48087,
    "state_id": "MI"
  },
  {
    "zip": 48827,
    "lat": 42.50912,
    "lng": -84.65979,
    "state_id": "MI"
  },
  {
    "zip": 48829,
    "lat": 43.40119,
    "lng": -85.0202,
    "state_id": "MI"
  },
  {
    "zip": 48831,
    "lat": 43.10289,
    "lng": -84.37169,
    "state_id": "MI"
  },
  {
    "zip": 48832,
    "lat": 43.41464,
    "lng": -84.78014,
    "state_id": "MI"
  },
  {
    "zip": 48834,
    "lat": 43.13224,
    "lng": -85.02952,
    "state_id": "MI"
  },
  {
    "zip": 48835,
    "lat": 43.02831,
    "lng": -84.74686,
    "state_id": "MI"
  },
  {
    "zip": 48836,
    "lat": 42.67078,
    "lng": -84.07467,
    "state_id": "MI"
  },
  {
    "zip": 48837,
    "lat": 42.74646,
    "lng": -84.7684,
    "state_id": "MI"
  },
  {
    "zip": 48838,
    "lat": 43.18392,
    "lng": -85.25749,
    "state_id": "MI"
  },
  {
    "zip": 48840,
    "lat": 42.76902,
    "lng": -84.37066,
    "state_id": "MI"
  },
  {
    "zip": 48841,
    "lat": 43.11434,
    "lng": -84.237,
    "state_id": "MI"
  },
  {
    "zip": 48842,
    "lat": 42.63384,
    "lng": -84.53868,
    "state_id": "MI"
  },
  {
    "zip": 48843,
    "lat": 42.56904,
    "lng": -83.92628,
    "state_id": "MI"
  },
  {
    "zip": 48845,
    "lat": 43.10442,
    "lng": -84.8479,
    "state_id": "MI"
  },
  {
    "zip": 48846,
    "lat": 42.98705,
    "lng": -85.05756,
    "state_id": "MI"
  },
  {
    "zip": 48847,
    "lat": 43.27111,
    "lng": -84.57475,
    "state_id": "MI"
  },
  {
    "zip": 48848,
    "lat": 42.88721,
    "lng": -84.35562,
    "state_id": "MI"
  },
  {
    "zip": 48849,
    "lat": 42.80093,
    "lng": -85.13199,
    "state_id": "MI"
  },
  {
    "zip": 48850,
    "lat": 43.43784,
    "lng": -85.25416,
    "state_id": "MI"
  },
  {
    "zip": 48851,
    "lat": 42.95567,
    "lng": -84.93978,
    "state_id": "MI"
  },
  {
    "zip": 48852,
    "lat": 43.35167,
    "lng": -85.04608,
    "state_id": "MI"
  },
  {
    "zip": 48853,
    "lat": 43.10129,
    "lng": -84.69016,
    "state_id": "MI"
  },
  {
    "zip": 48854,
    "lat": 42.58201,
    "lng": -84.45163,
    "state_id": "MI"
  },
  {
    "zip": 48855,
    "lat": 42.68702,
    "lng": -83.91404,
    "state_id": "MI"
  },
  {
    "zip": 48856,
    "lat": 43.2,
    "lng": -84.73662,
    "state_id": "MI"
  },
  {
    "zip": 48857,
    "lat": 42.84251,
    "lng": -84.1553,
    "state_id": "MI"
  },
  {
    "zip": 48858,
    "lat": 43.61519,
    "lng": -84.79615,
    "state_id": "MI"
  },
  {
    "zip": 48860,
    "lat": 43.05232,
    "lng": -84.91093,
    "state_id": "MI"
  },
  {
    "zip": 48861,
    "lat": 42.7306,
    "lng": -84.92028,
    "state_id": "MI"
  },
  {
    "zip": 48864,
    "lat": 42.70133,
    "lng": -84.40675,
    "state_id": "MI"
  },
  {
    "zip": 48865,
    "lat": 43.087,
    "lng": -85.10561,
    "state_id": "MI"
  },
  {
    "zip": 48866,
    "lat": 42.99488,
    "lng": -84.37438,
    "state_id": "MI"
  },
  {
    "zip": 48867,
    "lat": 43.00318,
    "lng": -84.1886,
    "state_id": "MI"
  },
  {
    "zip": 48870,
    "lat": 43.11037,
    "lng": -84.98474,
    "state_id": "MI"
  },
  {
    "zip": 48871,
    "lat": 43.15437,
    "lng": -84.67549,
    "state_id": "MI"
  },
  {
    "zip": 48872,
    "lat": 42.80075,
    "lng": -84.21794,
    "state_id": "MI"
  },
  {
    "zip": 48873,
    "lat": 42.99628,
    "lng": -84.84343,
    "state_id": "MI"
  },
  {
    "zip": 48874,
    "lat": 43.18645,
    "lng": -84.59493,
    "state_id": "MI"
  },
  {
    "zip": 48875,
    "lat": 42.85933,
    "lng": -84.92965,
    "state_id": "MI"
  },
  {
    "zip": 48876,
    "lat": 42.64556,
    "lng": -84.7357,
    "state_id": "MI"
  },
  {
    "zip": 48877,
    "lat": 43.40172,
    "lng": -84.84284,
    "state_id": "MI"
  },
  {
    "zip": 48878,
    "lat": 43.71229,
    "lng": -84.77694,
    "state_id": "MI"
  },
  {
    "zip": 48879,
    "lat": 43.0043,
    "lng": -84.57907,
    "state_id": "MI"
  },
  {
    "zip": 48880,
    "lat": 43.4576,
    "lng": -84.56832,
    "state_id": "MI"
  },
  {
    "zip": 48881,
    "lat": 42.93281,
    "lng": -85.20153,
    "state_id": "MI"
  },
  {
    "zip": 48883,
    "lat": 43.54186,
    "lng": -84.66863,
    "state_id": "MI"
  },
  {
    "zip": 48884,
    "lat": 43.2083,
    "lng": -85.04322,
    "state_id": "MI"
  },
  {
    "zip": 48885,
    "lat": 43.24983,
    "lng": -85.14445,
    "state_id": "MI"
  },
  {
    "zip": 48886,
    "lat": 43.41909,
    "lng": -85.16183,
    "state_id": "MI"
  },
  {
    "zip": 48888,
    "lat": 43.31038,
    "lng": -85.09559,
    "state_id": "MI"
  },
  {
    "zip": 48889,
    "lat": 43.29162,
    "lng": -84.7976,
    "state_id": "MI"
  },
  {
    "zip": 48890,
    "lat": 42.76963,
    "lng": -84.96872,
    "state_id": "MI"
  },
  {
    "zip": 48891,
    "lat": 43.39589,
    "lng": -84.91364,
    "state_id": "MI"
  },
  {
    "zip": 48892,
    "lat": 42.63649,
    "lng": -84.1745,
    "state_id": "MI"
  },
  {
    "zip": 48893,
    "lat": 43.683,
    "lng": -84.97788,
    "state_id": "MI"
  },
  {
    "zip": 48894,
    "lat": 42.92452,
    "lng": -84.78564,
    "state_id": "MI"
  },
  {
    "zip": 48895,
    "lat": 42.68872,
    "lng": -84.2796,
    "state_id": "MI"
  },
  {
    "zip": 48896,
    "lat": 43.52128,
    "lng": -84.90102,
    "state_id": "MI"
  },
  {
    "zip": 48897,
    "lat": 42.7068,
    "lng": -85.13214,
    "state_id": "MI"
  },
  {
    "zip": 48906,
    "lat": 42.78453,
    "lng": -84.58759,
    "state_id": "MI"
  },
  {
    "zip": 48910,
    "lat": 42.69853,
    "lng": -84.52299,
    "state_id": "MI"
  },
  {
    "zip": 48911,
    "lat": 42.67443,
    "lng": -84.57087,
    "state_id": "MI"
  },
  {
    "zip": 48912,
    "lat": 42.7402,
    "lng": -84.52354,
    "state_id": "MI"
  },
  {
    "zip": 48915,
    "lat": 42.7382,
    "lng": -84.57099,
    "state_id": "MI"
  },
  {
    "zip": 48917,
    "lat": 42.72379,
    "lng": -84.63995,
    "state_id": "MI"
  },
  {
    "zip": 48933,
    "lat": 42.7325,
    "lng": -84.55428,
    "state_id": "MI"
  },
  {
    "zip": 49001,
    "lat": 42.26472,
    "lng": -85.56245,
    "state_id": "MI"
  },
  {
    "zip": 49002,
    "lat": 42.19737,
    "lng": -85.56095,
    "state_id": "MI"
  },
  {
    "zip": 49004,
    "lat": 42.35183,
    "lng": -85.56203,
    "state_id": "MI"
  },
  {
    "zip": 49006,
    "lat": 42.29536,
    "lng": -85.63017,
    "state_id": "MI"
  },
  {
    "zip": 49007,
    "lat": 42.30237,
    "lng": -85.58823,
    "state_id": "MI"
  },
  {
    "zip": 49008,
    "lat": 42.26284,
    "lng": -85.61512,
    "state_id": "MI"
  },
  {
    "zip": 49009,
    "lat": 42.30764,
    "lng": -85.69405,
    "state_id": "MI"
  },
  {
    "zip": 49010,
    "lat": 42.53463,
    "lng": -85.87431,
    "state_id": "MI"
  },
  {
    "zip": 49011,
    "lat": 42.10523,
    "lng": -85.22291,
    "state_id": "MI"
  },
  {
    "zip": 49012,
    "lat": 42.36122,
    "lng": -85.34642,
    "state_id": "MI"
  },
  {
    "zip": 49013,
    "lat": 42.30567,
    "lng": -86.10846,
    "state_id": "MI"
  },
  {
    "zip": 49014,
    "lat": 42.31108,
    "lng": -85.10611,
    "state_id": "MI"
  },
  {
    "zip": 49015,
    "lat": 42.27125,
    "lng": -85.2261,
    "state_id": "MI"
  },
  {
    "zip": 49017,
    "lat": 42.39988,
    "lng": -85.21207,
    "state_id": "MI"
  },
  {
    "zip": 49021,
    "lat": 42.45915,
    "lng": -85.06393,
    "state_id": "MI"
  },
  {
    "zip": 49022,
    "lat": 42.11367,
    "lng": -86.36565,
    "state_id": "MI"
  },
  {
    "zip": 49024,
    "lat": 42.20269,
    "lng": -85.61906,
    "state_id": "MI"
  },
  {
    "zip": 49026,
    "lat": 42.3772,
    "lng": -85.96541,
    "state_id": "MI"
  },
  {
    "zip": 49027,
    "lat": 42.34805,
    "lng": -86.06872,
    "state_id": "MI"
  },
  {
    "zip": 49028,
    "lat": 41.84824,
    "lng": -85.18658,
    "state_id": "MI"
  },
  {
    "zip": 49029,
    "lat": 42.14344,
    "lng": -85.09245,
    "state_id": "MI"
  },
  {
    "zip": 49030,
    "lat": 41.85654,
    "lng": -85.33179,
    "state_id": "MI"
  },
  {
    "zip": 49031,
    "lat": 41.89839,
    "lng": -85.9873,
    "state_id": "MI"
  },
  {
    "zip": 49032,
    "lat": 41.91821,
    "lng": -85.5095,
    "state_id": "MI"
  },
  {
    "zip": 49033,
    "lat": 42.2262,
    "lng": -85.09109,
    "state_id": "MI"
  },
  {
    "zip": 49034,
    "lat": 42.23117,
    "lng": -85.33688,
    "state_id": "MI"
  },
  {
    "zip": 49036,
    "lat": 41.90283,
    "lng": -85.02321,
    "state_id": "MI"
  },
  {
    "zip": 49037,
    "lat": 42.33243,
    "lng": -85.24827,
    "state_id": "MI"
  },
  {
    "zip": 49038,
    "lat": 42.20296,
    "lng": -86.3258,
    "state_id": "MI"
  },
  {
    "zip": 49040,
    "lat": 41.96419,
    "lng": -85.32892,
    "state_id": "MI"
  },
  {
    "zip": 49042,
    "lat": 41.85734,
    "lng": -85.66665,
    "state_id": "MI"
  },
  {
    "zip": 49043,
    "lat": 42.28577,
    "lng": -86.26737,
    "state_id": "MI"
  },
  {
    "zip": 49045,
    "lat": 42.10531,
    "lng": -86.00067,
    "state_id": "MI"
  },
  {
    "zip": 49046,
    "lat": 42.51237,
    "lng": -85.40322,
    "state_id": "MI"
  },
  {
    "zip": 49047,
    "lat": 42.00405,
    "lng": -86.12197,
    "state_id": "MI"
  },
  {
    "zip": 49048,
    "lat": 42.27284,
    "lng": -85.49849,
    "state_id": "MI"
  },
  {
    "zip": 49050,
    "lat": 42.5059,
    "lng": -85.23697,
    "state_id": "MI"
  },
  {
    "zip": 49051,
    "lat": 42.1835,
    "lng": -85.24042,
    "state_id": "MI"
  },
  {
    "zip": 49052,
    "lat": 42.11428,
    "lng": -85.32073,
    "state_id": "MI"
  },
  {
    "zip": 49053,
    "lat": 42.28896,
    "lng": -85.4163,
    "state_id": "MI"
  },
  {
    "zip": 49055,
    "lat": 42.37012,
    "lng": -85.85335,
    "state_id": "MI"
  },
  {
    "zip": 49056,
    "lat": 42.4022,
    "lng": -86.06054,
    "state_id": "MI"
  },
  {
    "zip": 49057,
    "lat": 42.18365,
    "lng": -86.16402,
    "state_id": "MI"
  },
  {
    "zip": 49058,
    "lat": 42.6319,
    "lng": -85.29211,
    "state_id": "MI"
  },
  {
    "zip": 49060,
    "lat": 42.42654,
    "lng": -85.39533,
    "state_id": "MI"
  },
  {
    "zip": 49061,
    "lat": 41.87903,
    "lng": -85.82276,
    "state_id": "MI"
  },
  {
    "zip": 49064,
    "lat": 42.22108,
    "lng": -86.04888,
    "state_id": "MI"
  },
  {
    "zip": 49065,
    "lat": 42.13619,
    "lng": -85.84016,
    "state_id": "MI"
  },
  {
    "zip": 49066,
    "lat": 42.03445,
    "lng": -85.34999,
    "state_id": "MI"
  },
  {
    "zip": 49067,
    "lat": 42.02408,
    "lng": -85.80197,
    "state_id": "MI"
  },
  {
    "zip": 49068,
    "lat": 42.27682,
    "lng": -84.94084,
    "state_id": "MI"
  },
  {
    "zip": 49070,
    "lat": 42.54045,
    "lng": -85.63117,
    "state_id": "MI"
  },
  {
    "zip": 49071,
    "lat": 42.22744,
    "lng": -85.78169,
    "state_id": "MI"
  },
  {
    "zip": 49072,
    "lat": 42.01201,
    "lng": -85.46057,
    "state_id": "MI"
  },
  {
    "zip": 49073,
    "lat": 42.57738,
    "lng": -85.11775,
    "state_id": "MI"
  },
  {
    "zip": 49074,
    "lat": 42.31736,
    "lng": -85.5387,
    "state_id": "MI"
  },
  {
    "zip": 49075,
    "lat": 41.91758,
    "lng": -85.44912,
    "state_id": "MI"
  },
  {
    "zip": 49076,
    "lat": 42.43181,
    "lng": -84.8876,
    "state_id": "MI"
  },
  {
    "zip": 49078,
    "lat": 42.46507,
    "lng": -85.72807,
    "state_id": "MI"
  },
  {
    "zip": 49079,
    "lat": 42.24056,
    "lng": -85.90806,
    "state_id": "MI"
  },
  {
    "zip": 49080,
    "lat": 42.461,
    "lng": -85.57506,
    "state_id": "MI"
  },
  {
    "zip": 49082,
    "lat": 41.94441,
    "lng": -84.87575,
    "state_id": "MI"
  },
  {
    "zip": 49083,
    "lat": 42.3775,
    "lng": -85.46056,
    "state_id": "MI"
  },
  {
    "zip": 49084,
    "lat": 42.18255,
    "lng": -86.38207,
    "state_id": "MI"
  },
  {
    "zip": 49085,
    "lat": 42.05013,
    "lng": -86.46028,
    "state_id": "MI"
  },
  {
    "zip": 49087,
    "lat": 42.12188,
    "lng": -85.68818,
    "state_id": "MI"
  },
  {
    "zip": 49088,
    "lat": 42.18514,
    "lng": -85.4289,
    "state_id": "MI"
  },
  {
    "zip": 49089,
    "lat": 42.00466,
    "lng": -85.23596,
    "state_id": "MI"
  },
  {
    "zip": 49090,
    "lat": 42.40865,
    "lng": -86.21614,
    "state_id": "MI"
  },
  {
    "zip": 49091,
    "lat": 41.82443,
    "lng": -85.44609,
    "state_id": "MI"
  },
  {
    "zip": 49092,
    "lat": 42.10005,
    "lng": -84.97579,
    "state_id": "MI"
  },
  {
    "zip": 49093,
    "lat": 41.97256,
    "lng": -85.64978,
    "state_id": "MI"
  },
  {
    "zip": 49094,
    "lat": 42.04734,
    "lng": -85.11419,
    "state_id": "MI"
  },
  {
    "zip": 49095,
    "lat": 41.90703,
    "lng": -85.88036,
    "state_id": "MI"
  },
  {
    "zip": 49096,
    "lat": 42.64167,
    "lng": -85.01576,
    "state_id": "MI"
  },
  {
    "zip": 49097,
    "lat": 42.11459,
    "lng": -85.48818,
    "state_id": "MI"
  },
  {
    "zip": 49098,
    "lat": 42.16878,
    "lng": -86.24276,
    "state_id": "MI"
  },
  {
    "zip": 49099,
    "lat": 41.79176,
    "lng": -85.6779,
    "state_id": "MI"
  },
  {
    "zip": 49101,
    "lat": 41.94291,
    "lng": -86.4829,
    "state_id": "MI"
  },
  {
    "zip": 49102,
    "lat": 41.9523,
    "lng": -86.26113,
    "state_id": "MI"
  },
  {
    "zip": 49103,
    "lat": 41.94691,
    "lng": -86.37641,
    "state_id": "MI"
  },
  {
    "zip": 49104,
    "lat": 41.96373,
    "lng": -86.35895,
    "state_id": "MI"
  },
  {
    "zip": 49106,
    "lat": 41.93763,
    "lng": -86.55148,
    "state_id": "MI"
  },
  {
    "zip": 49107,
    "lat": 41.84578,
    "lng": -86.41376,
    "state_id": "MI"
  },
  {
    "zip": 49111,
    "lat": 42.02477,
    "lng": -86.28776,
    "state_id": "MI"
  },
  {
    "zip": 49112,
    "lat": 41.79758,
    "lng": -86.03353,
    "state_id": "MI"
  },
  {
    "zip": 49113,
    "lat": 41.80237,
    "lng": -86.50087,
    "state_id": "MI"
  },
  {
    "zip": 49115,
    "lat": 41.87229,
    "lng": -86.62929,
    "state_id": "MI"
  },
  {
    "zip": 49116,
    "lat": 41.84904,
    "lng": -86.66164,
    "state_id": "MI"
  },
  {
    "zip": 49117,
    "lat": 41.7806,
    "lng": -86.72678,
    "state_id": "MI"
  },
  {
    "zip": 49119,
    "lat": 41.87436,
    "lng": -86.54904,
    "state_id": "MI"
  },
  {
    "zip": 49120,
    "lat": 41.83224,
    "lng": -86.23082,
    "state_id": "MI"
  },
  {
    "zip": 49125,
    "lat": 41.88708,
    "lng": -86.57745,
    "state_id": "MI"
  },
  {
    "zip": 49126,
    "lat": 42.02581,
    "lng": -86.36878,
    "state_id": "MI"
  },
  {
    "zip": 49127,
    "lat": 42.00635,
    "lng": -86.51219,
    "state_id": "MI"
  },
  {
    "zip": 49128,
    "lat": 41.81651,
    "lng": -86.60333,
    "state_id": "MI"
  },
  {
    "zip": 49129,
    "lat": 41.81695,
    "lng": -86.68803,
    "state_id": "MI"
  },
  {
    "zip": 49130,
    "lat": 41.78195,
    "lng": -85.85943,
    "state_id": "MI"
  },
  {
    "zip": 49201,
    "lat": 42.24977,
    "lng": -84.38151,
    "state_id": "MI"
  },
  {
    "zip": 49202,
    "lat": 42.26859,
    "lng": -84.41036,
    "state_id": "MI"
  },
  {
    "zip": 49203,
    "lat": 42.22151,
    "lng": -84.4011,
    "state_id": "MI"
  },
  {
    "zip": 49220,
    "lat": 41.99461,
    "lng": -84.34243,
    "state_id": "MI"
  },
  {
    "zip": 49221,
    "lat": 41.90301,
    "lng": -84.06363,
    "state_id": "MI"
  },
  {
    "zip": 49224,
    "lat": 42.28109,
    "lng": -84.75185,
    "state_id": "MI"
  },
  {
    "zip": 49227,
    "lat": 41.95194,
    "lng": -84.77118,
    "state_id": "MI"
  },
  {
    "zip": 49228,
    "lat": 41.80438,
    "lng": -83.88349,
    "state_id": "MI"
  },
  {
    "zip": 49229,
    "lat": 41.98679,
    "lng": -83.82832,
    "state_id": "MI"
  },
  {
    "zip": 49230,
    "lat": 42.09465,
    "lng": -84.22071,
    "state_id": "MI"
  },
  {
    "zip": 49232,
    "lat": 41.74047,
    "lng": -84.66858,
    "state_id": "MI"
  },
  {
    "zip": 49233,
    "lat": 42.06223,
    "lng": -84.36286,
    "state_id": "MI"
  },
  {
    "zip": 49234,
    "lat": 42.11855,
    "lng": -84.36865,
    "state_id": "MI"
  },
  {
    "zip": 49235,
    "lat": 41.86356,
    "lng": -84.21469,
    "state_id": "MI"
  },
  {
    "zip": 49236,
    "lat": 42.07839,
    "lng": -83.95098,
    "state_id": "MI"
  },
  {
    "zip": 49237,
    "lat": 42.17573,
    "lng": -84.65157,
    "state_id": "MI"
  },
  {
    "zip": 49238,
    "lat": 41.90553,
    "lng": -83.78599,
    "state_id": "MI"
  },
  {
    "zip": 49240,
    "lat": 42.27984,
    "lng": -84.17871,
    "state_id": "MI"
  },
  {
    "zip": 49241,
    "lat": 42.10325,
    "lng": -84.60227,
    "state_id": "MI"
  },
  {
    "zip": 49242,
    "lat": 41.88334,
    "lng": -84.6164,
    "state_id": "MI"
  },
  {
    "zip": 49245,
    "lat": 42.14004,
    "lng": -84.81759,
    "state_id": "MI"
  },
  {
    "zip": 49246,
    "lat": 42.12627,
    "lng": -84.50881,
    "state_id": "MI"
  },
  {
    "zip": 49247,
    "lat": 41.84785,
    "lng": -84.34247,
    "state_id": "MI"
  },
  {
    "zip": 49248,
    "lat": 41.75645,
    "lng": -84.01123,
    "state_id": "MI"
  },
  {
    "zip": 49249,
    "lat": 42.02285,
    "lng": -84.45833,
    "state_id": "MI"
  },
  {
    "zip": 49250,
    "lat": 42.01912,
    "lng": -84.62273,
    "state_id": "MI"
  },
  {
    "zip": 49251,
    "lat": 42.46595,
    "lng": -84.41006,
    "state_id": "MI"
  },
  {
    "zip": 49252,
    "lat": 42.0369,
    "lng": -84.76722,
    "state_id": "MI"
  },
  {
    "zip": 49253,
    "lat": 41.96685,
    "lng": -84.271,
    "state_id": "MI"
  },
  {
    "zip": 49254,
    "lat": 42.22712,
    "lng": -84.31609,
    "state_id": "MI"
  },
  {
    "zip": 49255,
    "lat": 41.77276,
    "lng": -84.85057,
    "state_id": "MI"
  },
  {
    "zip": 49256,
    "lat": 41.74998,
    "lng": -84.21859,
    "state_id": "MI"
  },
  {
    "zip": 49259,
    "lat": 42.3789,
    "lng": -84.25303,
    "state_id": "MI"
  },
  {
    "zip": 49261,
    "lat": 42.16098,
    "lng": -84.23845,
    "state_id": "MI"
  },
  {
    "zip": 49262,
    "lat": 41.96146,
    "lng": -84.46737,
    "state_id": "MI"
  },
  {
    "zip": 49263,
    "lat": 42.15943,
    "lng": -84.18477,
    "state_id": "MI"
  },
  {
    "zip": 49264,
    "lat": 42.44358,
    "lng": -84.5514,
    "state_id": "MI"
  },
  {
    "zip": 49265,
    "lat": 42.0073,
    "lng": -84.17264,
    "state_id": "MI"
  },
  {
    "zip": 49266,
    "lat": 41.84223,
    "lng": -84.54784,
    "state_id": "MI"
  },
  {
    "zip": 49267,
    "lat": 41.76089,
    "lng": -83.72251,
    "state_id": "MI"
  },
  {
    "zip": 49268,
    "lat": 41.8729,
    "lng": -83.92632,
    "state_id": "MI"
  },
  {
    "zip": 49269,
    "lat": 42.29295,
    "lng": -84.58793,
    "state_id": "MI"
  },
  {
    "zip": 49270,
    "lat": 41.86973,
    "lng": -83.68326,
    "state_id": "MI"
  },
  {
    "zip": 49271,
    "lat": 41.83693,
    "lng": -84.45552,
    "state_id": "MI"
  },
  {
    "zip": 49272,
    "lat": 42.39405,
    "lng": -84.34663,
    "state_id": "MI"
  },
  {
    "zip": 49274,
    "lat": 41.83849,
    "lng": -84.76278,
    "state_id": "MI"
  },
  {
    "zip": 49276,
    "lat": 41.79744,
    "lng": -83.78462,
    "state_id": "MI"
  },
  {
    "zip": 49277,
    "lat": 42.38734,
    "lng": -84.46528,
    "state_id": "MI"
  },
  {
    "zip": 49279,
    "lat": 41.78085,
    "lng": -84.10395,
    "state_id": "MI"
  },
  {
    "zip": 49282,
    "lat": 42.03884,
    "lng": -84.40599,
    "state_id": "MI"
  },
  {
    "zip": 49283,
    "lat": 42.20696,
    "lng": -84.55069,
    "state_id": "MI"
  },
  {
    "zip": 49284,
    "lat": 42.38674,
    "lng": -84.69318,
    "state_id": "MI"
  },
  {
    "zip": 49285,
    "lat": 42.4706,
    "lng": -84.21008,
    "state_id": "MI"
  },
  {
    "zip": 49286,
    "lat": 42.0136,
    "lng": -83.936,
    "state_id": "MI"
  },
  {
    "zip": 49287,
    "lat": 42.02108,
    "lng": -84.08139,
    "state_id": "MI"
  },
  {
    "zip": 49288,
    "lat": 41.73466,
    "lng": -84.42999,
    "state_id": "MI"
  },
  {
    "zip": 49289,
    "lat": 41.76813,
    "lng": -84.1078,
    "state_id": "MI"
  },
  {
    "zip": 49301,
    "lat": 42.97647,
    "lng": -85.47661,
    "state_id": "MI"
  },
  {
    "zip": 49302,
    "lat": 42.82286,
    "lng": -85.3986,
    "state_id": "MI"
  },
  {
    "zip": 49303,
    "lat": 43.27729,
    "lng": -85.85483,
    "state_id": "MI"
  },
  {
    "zip": 49304,
    "lat": 43.91391,
    "lng": -85.87,
    "state_id": "MI"
  },
  {
    "zip": 49305,
    "lat": 43.75075,
    "lng": -85.1497,
    "state_id": "MI"
  },
  {
    "zip": 49306,
    "lat": 43.07325,
    "lng": -85.56775,
    "state_id": "MI"
  },
  {
    "zip": 49307,
    "lat": 43.69892,
    "lng": -85.48912,
    "state_id": "MI"
  },
  {
    "zip": 49309,
    "lat": 43.7595,
    "lng": -85.88093,
    "state_id": "MI"
  },
  {
    "zip": 49310,
    "lat": 43.51564,
    "lng": -85.05652,
    "state_id": "MI"
  },
  {
    "zip": 49312,
    "lat": 43.68821,
    "lng": -85.80547,
    "state_id": "MI"
  },
  {
    "zip": 49315,
    "lat": 42.80013,
    "lng": -85.73622,
    "state_id": "MI"
  },
  {
    "zip": 49316,
    "lat": 42.79679,
    "lng": -85.55403,
    "state_id": "MI"
  },
  {
    "zip": 49318,
    "lat": 43.22347,
    "lng": -85.82912,
    "state_id": "MI"
  },
  {
    "zip": 49319,
    "lat": 43.22712,
    "lng": -85.52638,
    "state_id": "MI"
  },
  {
    "zip": 49320,
    "lat": 43.75358,
    "lng": -85.27547,
    "state_id": "MI"
  },
  {
    "zip": 49321,
    "lat": 43.07427,
    "lng": -85.68089,
    "state_id": "MI"
  },
  {
    "zip": 49322,
    "lat": 43.35674,
    "lng": -85.35115,
    "state_id": "MI"
  },
  {
    "zip": 49323,
    "lat": 42.72534,
    "lng": -85.78723,
    "state_id": "MI"
  },
  {
    "zip": 49325,
    "lat": 42.76052,
    "lng": -85.29815,
    "state_id": "MI"
  },
  {
    "zip": 49326,
    "lat": 43.24982,
    "lng": -85.3206,
    "state_id": "MI"
  },
  {
    "zip": 49327,
    "lat": 43.33539,
    "lng": -85.84617,
    "state_id": "MI"
  },
  {
    "zip": 49328,
    "lat": 42.63167,
    "lng": -85.7592,
    "state_id": "MI"
  },
  {
    "zip": 49329,
    "lat": 43.40631,
    "lng": -85.48347,
    "state_id": "MI"
  },
  {
    "zip": 49330,
    "lat": 43.24504,
    "lng": -85.73739,
    "state_id": "MI"
  },
  {
    "zip": 49331,
    "lat": 42.95145,
    "lng": -85.35216,
    "state_id": "MI"
  },
  {
    "zip": 49332,
    "lat": 43.62439,
    "lng": -85.24589,
    "state_id": "MI"
  },
  {
    "zip": 49333,
    "lat": 42.69563,
    "lng": -85.46103,
    "state_id": "MI"
  },
  {
    "zip": 49335,
    "lat": 42.73858,
    "lng": -85.66841,
    "state_id": "MI"
  },
  {
    "zip": 49336,
    "lat": 43.50869,
    "lng": -85.43177,
    "state_id": "MI"
  },
  {
    "zip": 49337,
    "lat": 43.43535,
    "lng": -85.7136,
    "state_id": "MI"
  },
  {
    "zip": 49338,
    "lat": 43.75952,
    "lng": -85.60856,
    "state_id": "MI"
  },
  {
    "zip": 49339,
    "lat": 43.33242,
    "lng": -85.49033,
    "state_id": "MI"
  },
  {
    "zip": 49340,
    "lat": 43.62151,
    "lng": -85.11395,
    "state_id": "MI"
  },
  {
    "zip": 49341,
    "lat": 43.1243,
    "lng": -85.49188,
    "state_id": "MI"
  },
  {
    "zip": 49342,
    "lat": 43.69241,
    "lng": -85.32222,
    "state_id": "MI"
  },
  {
    "zip": 49343,
    "lat": 43.29642,
    "lng": -85.53553,
    "state_id": "MI"
  },
  {
    "zip": 49344,
    "lat": 42.59299,
    "lng": -85.59134,
    "state_id": "MI"
  },
  {
    "zip": 49345,
    "lat": 43.15398,
    "lng": -85.70374,
    "state_id": "MI"
  },
  {
    "zip": 49346,
    "lat": 43.58572,
    "lng": -85.42186,
    "state_id": "MI"
  },
  {
    "zip": 49347,
    "lat": 43.31473,
    "lng": -85.34851,
    "state_id": "MI"
  },
  {
    "zip": 49348,
    "lat": 42.68571,
    "lng": -85.61467,
    "state_id": "MI"
  },
  {
    "zip": 49349,
    "lat": 43.58204,
    "lng": -85.7429,
    "state_id": "MI"
  },
  {
    "zip": 49401,
    "lat": 42.97619,
    "lng": -85.94147,
    "state_id": "MI"
  },
  {
    "zip": 49402,
    "lat": 43.93322,
    "lng": -86.03456,
    "state_id": "MI"
  },
  {
    "zip": 49403,
    "lat": 43.13495,
    "lng": -85.8519,
    "state_id": "MI"
  },
  {
    "zip": 49404,
    "lat": 43.06584,
    "lng": -85.95067,
    "state_id": "MI"
  },
  {
    "zip": 49405,
    "lat": 43.91432,
    "lng": -86.19148,
    "state_id": "MI"
  },
  {
    "zip": 49406,
    "lat": 42.64221,
    "lng": -86.20249,
    "state_id": "MI"
  },
  {
    "zip": 49408,
    "lat": 42.57039,
    "lng": -86.11456,
    "state_id": "MI"
  },
  {
    "zip": 49410,
    "lat": 44.03194,
    "lng": -86.12977,
    "state_id": "MI"
  },
  {
    "zip": 49411,
    "lat": 44.1066,
    "lng": -86.25056,
    "state_id": "MI"
  },
  {
    "zip": 49412,
    "lat": 43.4686,
    "lng": -85.94815,
    "state_id": "MI"
  },
  {
    "zip": 49415,
    "lat": 43.15111,
    "lng": -86.11799,
    "state_id": "MI"
  },
  {
    "zip": 49417,
    "lat": 43.01589,
    "lng": -86.14361,
    "state_id": "MI"
  },
  {
    "zip": 49418,
    "lat": 42.87799,
    "lng": -85.77136,
    "state_id": "MI"
  },
  {
    "zip": 49419,
    "lat": 42.6789,
    "lng": -85.981,
    "state_id": "MI"
  },
  {
    "zip": 49420,
    "lat": 43.71823,
    "lng": -86.28308,
    "state_id": "MI"
  },
  {
    "zip": 49421,
    "lat": 43.60245,
    "lng": -86.06114,
    "state_id": "MI"
  },
  {
    "zip": 49423,
    "lat": 42.74045,
    "lng": -86.08942,
    "state_id": "MI"
  },
  {
    "zip": 49424,
    "lat": 42.84476,
    "lng": -86.12522,
    "state_id": "MI"
  },
  {
    "zip": 49425,
    "lat": 43.43849,
    "lng": -86.09886,
    "state_id": "MI"
  },
  {
    "zip": 49426,
    "lat": 42.86322,
    "lng": -85.88836,
    "state_id": "MI"
  },
  {
    "zip": 49428,
    "lat": 42.92001,
    "lng": -85.83769,
    "state_id": "MI"
  },
  {
    "zip": 49431,
    "lat": 43.98218,
    "lng": -86.40018,
    "state_id": "MI"
  },
  {
    "zip": 49434,
    "lat": 42.76916,
    "lng": -86.20766,
    "state_id": "MI"
  },
  {
    "zip": 49435,
    "lat": 43.02697,
    "lng": -85.84073,
    "state_id": "MI"
  },
  {
    "zip": 49436,
    "lat": 43.67846,
    "lng": -86.46312,
    "state_id": "MI"
  },
  {
    "zip": 49437,
    "lat": 43.45945,
    "lng": -86.38286,
    "state_id": "MI"
  },
  {
    "zip": 49440,
    "lat": 43.23855,
    "lng": -86.25385,
    "state_id": "MI"
  },
  {
    "zip": 49441,
    "lat": 43.18555,
    "lng": -86.27255,
    "state_id": "MI"
  },
  {
    "zip": 49442,
    "lat": 43.24203,
    "lng": -86.12904,
    "state_id": "MI"
  },
  {
    "zip": 49444,
    "lat": 43.17906,
    "lng": -86.19887,
    "state_id": "MI"
  },
  {
    "zip": 49445,
    "lat": 43.29648,
    "lng": -86.27539,
    "state_id": "MI"
  },
  {
    "zip": 49446,
    "lat": 43.55469,
    "lng": -86.38688,
    "state_id": "MI"
  },
  {
    "zip": 49448,
    "lat": 43.09071,
    "lng": -86.07328,
    "state_id": "MI"
  },
  {
    "zip": 49449,
    "lat": 43.79871,
    "lng": -86.3746,
    "state_id": "MI"
  },
  {
    "zip": 49450,
    "lat": 42.48437,
    "lng": -86.08277,
    "state_id": "MI"
  },
  {
    "zip": 49451,
    "lat": 43.21197,
    "lng": -85.96945,
    "state_id": "MI"
  },
  {
    "zip": 49452,
    "lat": 43.51202,
    "lng": -86.25363,
    "state_id": "MI"
  },
  {
    "zip": 49453,
    "lat": 42.66315,
    "lng": -86.17241,
    "state_id": "MI"
  },
  {
    "zip": 49454,
    "lat": 43.95136,
    "lng": -86.28808,
    "state_id": "MI"
  },
  {
    "zip": 49455,
    "lat": 43.60957,
    "lng": -86.3663,
    "state_id": "MI"
  },
  {
    "zip": 49456,
    "lat": 43.08947,
    "lng": -86.19426,
    "state_id": "MI"
  },
  {
    "zip": 49457,
    "lat": 43.35365,
    "lng": -86.14828,
    "state_id": "MI"
  },
  {
    "zip": 49458,
    "lat": 43.92618,
    "lng": -86.09675,
    "state_id": "MI"
  },
  {
    "zip": 49459,
    "lat": 43.74097,
    "lng": -86.11271,
    "state_id": "MI"
  },
  {
    "zip": 49460,
    "lat": 42.93866,
    "lng": -86.13498,
    "state_id": "MI"
  },
  {
    "zip": 49461,
    "lat": 43.3826,
    "lng": -86.32258,
    "state_id": "MI"
  },
  {
    "zip": 49464,
    "lat": 42.84713,
    "lng": -85.98617,
    "state_id": "MI"
  },
  {
    "zip": 49503,
    "lat": 42.96252,
    "lng": -85.65928,
    "state_id": "MI"
  },
  {
    "zip": 49504,
    "lat": 42.98041,
    "lng": -85.70941,
    "state_id": "MI"
  },
  {
    "zip": 49505,
    "lat": 42.99835,
    "lng": -85.63693,
    "state_id": "MI"
  },
  {
    "zip": 49506,
    "lat": 42.9451,
    "lng": -85.614,
    "state_id": "MI"
  },
  {
    "zip": 49507,
    "lat": 42.93068,
    "lng": -85.65513,
    "state_id": "MI"
  },
  {
    "zip": 49508,
    "lat": 42.87352,
    "lng": -85.62526,
    "state_id": "MI"
  },
  {
    "zip": 49509,
    "lat": 42.89755,
    "lng": -85.69297,
    "state_id": "MI"
  },
  {
    "zip": 49512,
    "lat": 42.8814,
    "lng": -85.54673,
    "state_id": "MI"
  },
  {
    "zip": 49519,
    "lat": 42.89792,
    "lng": -85.71863,
    "state_id": "MI"
  },
  {
    "zip": 49525,
    "lat": 43.02115,
    "lng": -85.5917,
    "state_id": "MI"
  },
  {
    "zip": 49534,
    "lat": 42.97092,
    "lng": -85.78825,
    "state_id": "MI"
  },
  {
    "zip": 49544,
    "lat": 43.04596,
    "lng": -85.74191,
    "state_id": "MI"
  },
  {
    "zip": 49546,
    "lat": 42.92759,
    "lng": -85.53745,
    "state_id": "MI"
  },
  {
    "zip": 49548,
    "lat": 42.86966,
    "lng": -85.66276,
    "state_id": "MI"
  },
  {
    "zip": 49601,
    "lat": 44.24978,
    "lng": -85.50741,
    "state_id": "MI"
  },
  {
    "zip": 49611,
    "lat": 44.97368,
    "lng": -84.97443,
    "state_id": "MI"
  },
  {
    "zip": 49612,
    "lat": 44.87135,
    "lng": -85.24242,
    "state_id": "MI"
  },
  {
    "zip": 49613,
    "lat": 44.50271,
    "lng": -86.20548,
    "state_id": "MI"
  },
  {
    "zip": 49614,
    "lat": 44.43874,
    "lng": -86.11643,
    "state_id": "MI"
  },
  {
    "zip": 49615,
    "lat": 44.97086,
    "lng": -85.2064,
    "state_id": "MI"
  },
  {
    "zip": 49616,
    "lat": 44.57439,
    "lng": -86.08099,
    "state_id": "MI"
  },
  {
    "zip": 49617,
    "lat": 44.64409,
    "lng": -86.03259,
    "state_id": "MI"
  },
  {
    "zip": 49618,
    "lat": 44.29285,
    "lng": -85.60237,
    "state_id": "MI"
  },
  {
    "zip": 49619,
    "lat": 44.30263,
    "lng": -85.98189,
    "state_id": "MI"
  },
  {
    "zip": 49620,
    "lat": 44.52136,
    "lng": -85.69383,
    "state_id": "MI"
  },
  {
    "zip": 49621,
    "lat": 44.8634,
    "lng": -85.77556,
    "state_id": "MI"
  },
  {
    "zip": 49622,
    "lat": 45.07736,
    "lng": -85.26139,
    "state_id": "MI"
  },
  {
    "zip": 49623,
    "lat": 43.9048,
    "lng": -85.6835,
    "state_id": "MI"
  },
  {
    "zip": 49625,
    "lat": 44.4323,
    "lng": -85.87975,
    "state_id": "MI"
  },
  {
    "zip": 49626,
    "lat": 44.23777,
    "lng": -86.29323,
    "state_id": "MI"
  },
  {
    "zip": 49627,
    "lat": 45.09603,
    "lng": -85.34991,
    "state_id": "MI"
  },
  {
    "zip": 49628,
    "lat": 44.62085,
    "lng": -86.23105,
    "state_id": "MI"
  },
  {
    "zip": 49629,
    "lat": 44.91081,
    "lng": -85.39302,
    "state_id": "MI"
  },
  {
    "zip": 49630,
    "lat": 44.81378,
    "lng": -85.99273,
    "state_id": "MI"
  },
  {
    "zip": 49631,
    "lat": 43.91413,
    "lng": -85.26182,
    "state_id": "MI"
  },
  {
    "zip": 49632,
    "lat": 44.23892,
    "lng": -84.96935,
    "state_id": "MI"
  },
  {
    "zip": 49633,
    "lat": 44.55957,
    "lng": -85.20286,
    "state_id": "MI"
  },
  {
    "zip": 49634,
    "lat": 44.21485,
    "lng": -86.29031,
    "state_id": "MI"
  },
  {
    "zip": 49635,
    "lat": 44.61996,
    "lng": -86.18479,
    "state_id": "MI"
  },
  {
    "zip": 49636,
    "lat": 44.87721,
    "lng": -85.99598,
    "state_id": "MI"
  },
  {
    "zip": 49637,
    "lat": 44.6264,
    "lng": -85.7059,
    "state_id": "MI"
  },
  {
    "zip": 49638,
    "lat": 44.29045,
    "lng": -85.75115,
    "state_id": "MI"
  },
  {
    "zip": 49639,
    "lat": 43.84591,
    "lng": -85.39842,
    "state_id": "MI"
  },
  {
    "zip": 49640,
    "lat": 44.716,
    "lng": -86.03671,
    "state_id": "MI"
  },
  {
    "zip": 49642,
    "lat": 43.86676,
    "lng": -85.76056,
    "state_id": "MI"
  },
  {
    "zip": 49643,
    "lat": 44.63959,
    "lng": -85.83375,
    "state_id": "MI"
  },
  {
    "zip": 49644,
    "lat": 44.10623,
    "lng": -85.92203,
    "state_id": "MI"
  },
  {
    "zip": 49645,
    "lat": 44.36961,
    "lng": -86.02592,
    "state_id": "MI"
  },
  {
    "zip": 49646,
    "lat": 44.72786,
    "lng": -85.05866,
    "state_id": "MI"
  },
  {
    "zip": 49648,
    "lat": 44.98464,
    "lng": -85.34315,
    "state_id": "MI"
  },
  {
    "zip": 49649,
    "lat": 44.56775,
    "lng": -85.53483,
    "state_id": "MI"
  },
  {
    "zip": 49650,
    "lat": 44.73314,
    "lng": -85.87878,
    "state_id": "MI"
  },
  {
    "zip": 49651,
    "lat": 44.39399,
    "lng": -85.11292,
    "state_id": "MI"
  },
  {
    "zip": 49653,
    "lat": 44.97548,
    "lng": -85.73205,
    "state_id": "MI"
  },
  {
    "zip": 49654,
    "lat": 45.07933,
    "lng": -85.98983,
    "state_id": "MI"
  },
  {
    "zip": 49655,
    "lat": 44.02602,
    "lng": -85.44344,
    "state_id": "MI"
  },
  {
    "zip": 49656,
    "lat": 44.06554,
    "lng": -85.69763,
    "state_id": "MI"
  },
  {
    "zip": 49657,
    "lat": 44.21434,
    "lng": -85.17354,
    "state_id": "MI"
  },
  {
    "zip": 49659,
    "lat": 44.89941,
    "lng": -85.02408,
    "state_id": "MI"
  },
  {
    "zip": 49660,
    "lat": 44.22586,
    "lng": -86.20635,
    "state_id": "MI"
  },
  {
    "zip": 49663,
    "lat": 44.42993,
    "lng": -85.38848,
    "state_id": "MI"
  },
  {
    "zip": 49664,
    "lat": 44.87029,
    "lng": -85.89005,
    "state_id": "MI"
  },
  {
    "zip": 49665,
    "lat": 44.09683,
    "lng": -85.12696,
    "state_id": "MI"
  },
  {
    "zip": 49666,
    "lat": 44.62204,
    "lng": -85.55408,
    "state_id": "MI"
  },
  {
    "zip": 49667,
    "lat": 44.36653,
    "lng": -84.91323,
    "state_id": "MI"
  },
  {
    "zip": 49668,
    "lat": 44.40582,
    "lng": -85.7044,
    "state_id": "MI"
  },
  {
    "zip": 49670,
    "lat": 45.16042,
    "lng": -85.65145,
    "state_id": "MI"
  },
  {
    "zip": 49674,
    "lat": 45.05225,
    "lng": -85.59331,
    "state_id": "MI"
  },
  {
    "zip": 49675,
    "lat": 44.37615,
    "lng": -86.21656,
    "state_id": "MI"
  },
  {
    "zip": 49676,
    "lat": 44.83306,
    "lng": -85.2892,
    "state_id": "MI"
  },
  {
    "zip": 49677,
    "lat": 43.89683,
    "lng": -85.54827,
    "state_id": "MI"
  },
  {
    "zip": 49679,
    "lat": 43.87765,
    "lng": -85.14647,
    "state_id": "MI"
  },
  {
    "zip": 49680,
    "lat": 44.64121,
    "lng": -85.25244,
    "state_id": "MI"
  },
  {
    "zip": 49682,
    "lat": 44.97877,
    "lng": -85.65001,
    "state_id": "MI"
  },
  {
    "zip": 49683,
    "lat": 44.53935,
    "lng": -85.9306,
    "state_id": "MI"
  },
  {
    "zip": 49684,
    "lat": 44.75802,
    "lng": -85.70845,
    "state_id": "MI"
  },
  {
    "zip": 49686,
    "lat": 44.73572,
    "lng": -85.50303,
    "state_id": "MI"
  },
  {
    "zip": 49688,
    "lat": 44.12052,
    "lng": -85.44678,
    "state_id": "MI"
  },
  {
    "zip": 49689,
    "lat": 44.21424,
    "lng": -85.90753,
    "state_id": "MI"
  },
  {
    "zip": 49690,
    "lat": 44.78224,
    "lng": -85.39565,
    "state_id": "MI"
  },
  {
    "zip": 49701,
    "lat": 45.75566,
    "lng": -84.72915,
    "state_id": "MI"
  },
  {
    "zip": 49705,
    "lat": 45.35578,
    "lng": -84.46131,
    "state_id": "MI"
  },
  {
    "zip": 49706,
    "lat": 45.4403,
    "lng": -84.78045,
    "state_id": "MI"
  },
  {
    "zip": 49707,
    "lat": 45.0916,
    "lng": -83.49648,
    "state_id": "MI"
  },
  {
    "zip": 49709,
    "lat": 45.01522,
    "lng": -84.15842,
    "state_id": "MI"
  },
  {
    "zip": 49710,
    "lat": 46.28343,
    "lng": -84.18837,
    "state_id": "MI"
  },
  {
    "zip": 49712,
    "lat": 45.21009,
    "lng": -85.00996,
    "state_id": "MI"
  },
  {
    "zip": 49713,
    "lat": 45.21434,
    "lng": -84.85253,
    "state_id": "MI"
  },
  {
    "zip": 49715,
    "lat": 46.3875,
    "lng": -84.70636,
    "state_id": "MI"
  },
  {
    "zip": 49716,
    "lat": 45.51117,
    "lng": -84.74935,
    "state_id": "MI"
  },
  {
    "zip": 49717,
    "lat": 45.439,
    "lng": -84.69817,
    "state_id": "MI"
  },
  {
    "zip": 49718,
    "lat": 45.7204,
    "lng": -84.8317,
    "state_id": "MI"
  },
  {
    "zip": 49719,
    "lat": 46.0093,
    "lng": -84.32334,
    "state_id": "MI"
  },
  {
    "zip": 49720,
    "lat": 45.27545,
    "lng": -85.23947,
    "state_id": "MI"
  },
  {
    "zip": 49721,
    "lat": 45.57899,
    "lng": -84.45895,
    "state_id": "MI"
  },
  {
    "zip": 49722,
    "lat": 45.42349,
    "lng": -84.86324,
    "state_id": "MI"
  },
  {
    "zip": 49724,
    "lat": 46.32697,
    "lng": -84.38785,
    "state_id": "MI"
  },
  {
    "zip": 49725,
    "lat": 45.99858,
    "lng": -84.0289,
    "state_id": "MI"
  },
  {
    "zip": 49726,
    "lat": 46.0016,
    "lng": -83.66243,
    "state_id": "MI"
  },
  {
    "zip": 49727,
    "lat": 45.13063,
    "lng": -85.10348,
    "state_id": "MI"
  },
  {
    "zip": 49728,
    "lat": 46.39445,
    "lng": -85.01704,
    "state_id": "MI"
  },
  {
    "zip": 49729,
    "lat": 45.16162,
    "lng": -85.28369,
    "state_id": "MI"
  },
  {
    "zip": 49730,
    "lat": 45.046,
    "lng": -84.87388,
    "state_id": "MI"
  },
  {
    "zip": 49733,
    "lat": 44.80858,
    "lng": -84.70122,
    "state_id": "MI"
  },
  {
    "zip": 49735,
    "lat": 44.99526,
    "lng": -84.67103,
    "state_id": "MI"
  },
  {
    "zip": 49736,
    "lat": 46.07216,
    "lng": -84.14056,
    "state_id": "MI"
  },
  {
    "zip": 49738,
    "lat": 44.69373,
    "lng": -84.60635,
    "state_id": "MI"
  },
  {
    "zip": 49740,
    "lat": 45.52448,
    "lng": -85.00071,
    "state_id": "MI"
  },
  {
    "zip": 49743,
    "lat": 45.27349,
    "lng": -83.89646,
    "state_id": "MI"
  },
  {
    "zip": 49744,
    "lat": 45.00018,
    "lng": -83.66623,
    "state_id": "MI"
  },
  {
    "zip": 49745,
    "lat": 46.05191,
    "lng": -84.53123,
    "state_id": "MI"
  },
  {
    "zip": 49746,
    "lat": 45.06388,
    "lng": -83.95317,
    "state_id": "MI"
  },
  {
    "zip": 49747,
    "lat": 44.85112,
    "lng": -83.64207,
    "state_id": "MI"
  },
  {
    "zip": 49748,
    "lat": 46.37799,
    "lng": -85.19108,
    "state_id": "MI"
  },
  {
    "zip": 49749,
    "lat": 45.41967,
    "lng": -84.57446,
    "state_id": "MI"
  },
  {
    "zip": 49751,
    "lat": 44.99838,
    "lng": -84.41512,
    "state_id": "MI"
  },
  {
    "zip": 49752,
    "lat": 46.28287,
    "lng": -84.46843,
    "state_id": "MI"
  },
  {
    "zip": 49753,
    "lat": 45.01889,
    "lng": -83.77221,
    "state_id": "MI"
  },
  {
    "zip": 49755,
    "lat": 45.63581,
    "lng": -84.80027,
    "state_id": "MI"
  },
  {
    "zip": 49756,
    "lat": 44.82343,
    "lng": -84.28073,
    "state_id": "MI"
  },
  {
    "zip": 49757,
    "lat": 45.85933,
    "lng": -84.62533,
    "state_id": "MI"
  },
  {
    "zip": 49759,
    "lat": 45.41794,
    "lng": -84.09842,
    "state_id": "MI"
  },
  {
    "zip": 49760,
    "lat": 46.05368,
    "lng": -84.89498,
    "state_id": "MI"
  },
  {
    "zip": 49762,
    "lat": 46.13871,
    "lng": -85.26754,
    "state_id": "MI"
  },
  {
    "zip": 49764,
    "lat": 45.42431,
    "lng": -84.82766,
    "state_id": "MI"
  },
  {
    "zip": 49765,
    "lat": 45.32844,
    "lng": -84.24545,
    "state_id": "MI"
  },
  {
    "zip": 49766,
    "lat": 44.90578,
    "lng": -83.44709,
    "state_id": "MI"
  },
  {
    "zip": 49768,
    "lat": 46.64427,
    "lng": -85.13305,
    "state_id": "MI"
  },
  {
    "zip": 49769,
    "lat": 45.56748,
    "lng": -84.85911,
    "state_id": "MI"
  },
  {
    "zip": 49770,
    "lat": 45.34405,
    "lng": -84.88842,
    "state_id": "MI"
  },
  {
    "zip": 49774,
    "lat": 46.15693,
    "lng": -84.34679,
    "state_id": "MI"
  },
  {
    "zip": 49775,
    "lat": 45.76941,
    "lng": -84.46333,
    "state_id": "MI"
  },
  {
    "zip": 49776,
    "lat": 45.24542,
    "lng": -83.69068,
    "state_id": "MI"
  },
  {
    "zip": 49777,
    "lat": 45.2924,
    "lng": -83.51533,
    "state_id": "MI"
  },
  {
    "zip": 49779,
    "lat": 45.40153,
    "lng": -83.876,
    "state_id": "MI"
  },
  {
    "zip": 49780,
    "lat": 46.2121,
    "lng": -84.72555,
    "state_id": "MI"
  },
  {
    "zip": 49781,
    "lat": 45.95424,
    "lng": -84.78404,
    "state_id": "MI"
  },
  {
    "zip": 49782,
    "lat": 45.68297,
    "lng": -85.54832,
    "state_id": "MI"
  },
  {
    "zip": 49783,
    "lat": 46.41474,
    "lng": -84.28349,
    "state_id": "MI"
  },
  {
    "zip": 49788,
    "lat": 46.264,
    "lng": -84.46748,
    "state_id": "MI"
  },
  {
    "zip": 49791,
    "lat": 45.48686,
    "lng": -84.59583,
    "state_id": "MI"
  },
  {
    "zip": 49793,
    "lat": 46.2092,
    "lng": -85.06095,
    "state_id": "MI"
  },
  {
    "zip": 49795,
    "lat": 45.15877,
    "lng": -84.57657,
    "state_id": "MI"
  },
  {
    "zip": 49796,
    "lat": 45.26286,
    "lng": -84.94535,
    "state_id": "MI"
  },
  {
    "zip": 49799,
    "lat": 45.26109,
    "lng": -84.58194,
    "state_id": "MI"
  },
  {
    "zip": 49801,
    "lat": 45.96733,
    "lng": -87.98216,
    "state_id": "MI"
  },
  {
    "zip": 49802,
    "lat": 45.80058,
    "lng": -88.07727,
    "state_id": "MI"
  },
  {
    "zip": 49805,
    "lat": 47.35563,
    "lng": -88.36961,
    "state_id": "MI"
  },
  {
    "zip": 49806,
    "lat": 46.44251,
    "lng": -86.89667,
    "state_id": "MI"
  },
  {
    "zip": 49807,
    "lat": 45.78476,
    "lng": -87.37235,
    "state_id": "MI"
  },
  {
    "zip": 49808,
    "lat": 46.8126,
    "lng": -87.88242,
    "state_id": "MI"
  },
  {
    "zip": 49812,
    "lat": 45.59335,
    "lng": -87.49817,
    "state_id": "MI"
  },
  {
    "zip": 49814,
    "lat": 46.56817,
    "lng": -87.91206,
    "state_id": "MI"
  },
  {
    "zip": 49815,
    "lat": 46.18673,
    "lng": -87.96492,
    "state_id": "MI"
  },
  {
    "zip": 49816,
    "lat": 46.28458,
    "lng": -86.89419,
    "state_id": "MI"
  },
  {
    "zip": 49817,
    "lat": 45.96723,
    "lng": -86.4632,
    "state_id": "MI"
  },
  {
    "zip": 49818,
    "lat": 45.97522,
    "lng": -87.3461,
    "state_id": "MI"
  },
  {
    "zip": 49819,
    "lat": 46.09393,
    "lng": -87.47656,
    "state_id": "MI"
  },
  {
    "zip": 49820,
    "lat": 46.20385,
    "lng": -85.69056,
    "state_id": "MI"
  },
  {
    "zip": 49821,
    "lat": 45.53014,
    "lng": -87.63265,
    "state_id": "MI"
  },
  {
    "zip": 49822,
    "lat": 46.44231,
    "lng": -87.03984,
    "state_id": "MI"
  },
  {
    "zip": 49825,
    "lat": 46.34505,
    "lng": -86.98526,
    "state_id": "MI"
  },
  {
    "zip": 49826,
    "lat": 46.34071,
    "lng": -87.03928,
    "state_id": "MI"
  },
  {
    "zip": 49827,
    "lat": 46.16915,
    "lng": -85.55772,
    "state_id": "MI"
  },
  {
    "zip": 49829,
    "lat": 45.76353,
    "lng": -87.15961,
    "state_id": "MI"
  },
  {
    "zip": 49831,
    "lat": 46.06707,
    "lng": -87.72935,
    "state_id": "MI"
  },
  {
    "zip": 49833,
    "lat": 46.29185,
    "lng": -87.32754,
    "state_id": "MI"
  },
  {
    "zip": 49834,
    "lat": 45.94378,
    "lng": -87.75838,
    "state_id": "MI"
  },
  {
    "zip": 49835,
    "lat": 45.73335,
    "lng": -86.58289,
    "state_id": "MI"
  },
  {
    "zip": 49836,
    "lat": 46.19793,
    "lng": -85.88414,
    "state_id": "MI"
  },
  {
    "zip": 49837,
    "lat": 45.87446,
    "lng": -87.09707,
    "state_id": "MI"
  },
  {
    "zip": 49838,
    "lat": 46.06199,
    "lng": -85.74746,
    "state_id": "MI"
  },
  {
    "zip": 49839,
    "lat": 46.60785,
    "lng": -86.09856,
    "state_id": "MI"
  },
  {
    "zip": 49840,
    "lat": 46.08795,
    "lng": -86.01775,
    "state_id": "MI"
  },
  {
    "zip": 49841,
    "lat": 46.27424,
    "lng": -87.47057,
    "state_id": "MI"
  },
  {
    "zip": 49847,
    "lat": 45.7054,
    "lng": -87.63187,
    "state_id": "MI"
  },
  {
    "zip": 49848,
    "lat": 45.37829,
    "lng": -87.63642,
    "state_id": "MI"
  },
  {
    "zip": 49849,
    "lat": 46.43127,
    "lng": -87.75311,
    "state_id": "MI"
  },
  {
    "zip": 49852,
    "lat": 45.78392,
    "lng": -87.81806,
    "state_id": "MI"
  },
  {
    "zip": 49853,
    "lat": 46.35941,
    "lng": -85.74505,
    "state_id": "MI"
  },
  {
    "zip": 49854,
    "lat": 46.12581,
    "lng": -86.33788,
    "state_id": "MI"
  },
  {
    "zip": 49855,
    "lat": 46.57442,
    "lng": -87.46659,
    "state_id": "MI"
  },
  {
    "zip": 49858,
    "lat": 45.21945,
    "lng": -87.58409,
    "state_id": "MI"
  },
  {
    "zip": 49861,
    "lat": 46.56862,
    "lng": -88.16938,
    "state_id": "MI"
  },
  {
    "zip": 49862,
    "lat": 46.37771,
    "lng": -86.70846,
    "state_id": "MI"
  },
  {
    "zip": 49863,
    "lat": 45.61329,
    "lng": -87.55149,
    "state_id": "MI"
  },
  {
    "zip": 49864,
    "lat": 45.87145,
    "lng": -86.67261,
    "state_id": "MI"
  },
  {
    "zip": 49866,
    "lat": 46.52135,
    "lng": -87.57875,
    "state_id": "MI"
  },
  {
    "zip": 49868,
    "lat": 46.49368,
    "lng": -85.50573,
    "state_id": "MI"
  },
  {
    "zip": 49870,
    "lat": 45.86816,
    "lng": -87.90541,
    "state_id": "MI"
  },
  {
    "zip": 49871,
    "lat": 46.42415,
    "lng": -87.53865,
    "state_id": "MI"
  },
  {
    "zip": 49872,
    "lat": 46.02103,
    "lng": -87.08436,
    "state_id": "MI"
  },
  {
    "zip": 49873,
    "lat": 45.87947,
    "lng": -87.53794,
    "state_id": "MI"
  },
  {
    "zip": 49874,
    "lat": 45.70195,
    "lng": -87.50304,
    "state_id": "MI"
  },
  {
    "zip": 49876,
    "lat": 45.80265,
    "lng": -87.98215,
    "state_id": "MI"
  },
  {
    "zip": 49877,
    "lat": 46.14909,
    "lng": -87.7089,
    "state_id": "MI"
  },
  {
    "zip": 49878,
    "lat": 45.95738,
    "lng": -86.85514,
    "state_id": "MI"
  },
  {
    "zip": 49879,
    "lat": 46.36208,
    "lng": -88.0317,
    "state_id": "MI"
  },
  {
    "zip": 49880,
    "lat": 46.11278,
    "lng": -87.22182,
    "state_id": "MI"
  },
  {
    "zip": 49881,
    "lat": 46.07835,
    "lng": -88.03349,
    "state_id": "MI"
  },
  {
    "zip": 49883,
    "lat": 46.43518,
    "lng": -86.01333,
    "state_id": "MI"
  },
  {
    "zip": 49884,
    "lat": 46.42305,
    "lng": -86.33948,
    "state_id": "MI"
  },
  {
    "zip": 49885,
    "lat": 46.33662,
    "lng": -87.20744,
    "state_id": "MI"
  },
  {
    "zip": 49886,
    "lat": 45.73911,
    "lng": -87.52696,
    "state_id": "MI"
  },
  {
    "zip": 49887,
    "lat": 45.43422,
    "lng": -87.56487,
    "state_id": "MI"
  },
  {
    "zip": 49891,
    "lat": 46.22516,
    "lng": -87.01703,
    "state_id": "MI"
  },
  {
    "zip": 49892,
    "lat": 45.78927,
    "lng": -87.78646,
    "state_id": "MI"
  },
  {
    "zip": 49893,
    "lat": 45.29686,
    "lng": -87.59794,
    "state_id": "MI"
  },
  {
    "zip": 49894,
    "lat": 45.78192,
    "lng": -87.07439,
    "state_id": "MI"
  },
  {
    "zip": 49895,
    "lat": 46.16752,
    "lng": -86.66583,
    "state_id": "MI"
  },
  {
    "zip": 49896,
    "lat": 45.67439,
    "lng": -87.38991,
    "state_id": "MI"
  },
  {
    "zip": 49901,
    "lat": 47.30785,
    "lng": -88.39524,
    "state_id": "MI"
  },
  {
    "zip": 49902,
    "lat": 46.04303,
    "lng": -88.37986,
    "state_id": "MI"
  },
  {
    "zip": 49903,
    "lat": 46.32813,
    "lng": -88.42991,
    "state_id": "MI"
  },
  {
    "zip": 49905,
    "lat": 47.12377,
    "lng": -88.71648,
    "state_id": "MI"
  },
  {
    "zip": 49908,
    "lat": 46.76547,
    "lng": -88.5679,
    "state_id": "MI"
  },
  {
    "zip": 49910,
    "lat": 46.59851,
    "lng": -89.61275,
    "state_id": "MI"
  },
  {
    "zip": 49911,
    "lat": 46.48999,
    "lng": -90.04988,
    "state_id": "MI"
  },
  {
    "zip": 49912,
    "lat": 46.49436,
    "lng": -89.2028,
    "state_id": "MI"
  },
  {
    "zip": 49913,
    "lat": 47.24374,
    "lng": -88.45654,
    "state_id": "MI"
  },
  {
    "zip": 49915,
    "lat": 46.06511,
    "lng": -88.62197,
    "state_id": "MI"
  },
  {
    "zip": 49916,
    "lat": 46.98011,
    "lng": -88.59177,
    "state_id": "MI"
  },
  {
    "zip": 49917,
    "lat": 47.28139,
    "lng": -88.35909,
    "state_id": "MI"
  },
  {
    "zip": 49918,
    "lat": 47.43409,
    "lng": -87.83571,
    "state_id": "MI"
  },
  {
    "zip": 49919,
    "lat": 46.51861,
    "lng": -88.45585,
    "state_id": "MI"
  },
  {
    "zip": 49920,
    "lat": 46.15512,
    "lng": -88.29236,
    "state_id": "MI"
  },
  {
    "zip": 49921,
    "lat": 47.09251,
    "lng": -88.58227,
    "state_id": "MI"
  },
  {
    "zip": 49922,
    "lat": 47.10391,
    "lng": -88.48497,
    "state_id": "MI"
  },
  {
    "zip": 49925,
    "lat": 46.5448,
    "lng": -89.37248,
    "state_id": "MI"
  },
  {
    "zip": 49927,
    "lat": 46.04518,
    "lng": -88.56439,
    "state_id": "MI"
  },
  {
    "zip": 49929,
    "lat": 46.79434,
    "lng": -89.10725,
    "state_id": "MI"
  },
  {
    "zip": 49930,
    "lat": 47.16247,
    "lng": -88.53997,
    "state_id": "MI"
  },
  {
    "zip": 49931,
    "lat": 47.1305,
    "lng": -88.60379,
    "state_id": "MI"
  },
  {
    "zip": 49934,
    "lat": 47.17083,
    "lng": -88.43781,
    "state_id": "MI"
  },
  {
    "zip": 49935,
    "lat": 46.19924,
    "lng": -88.74481,
    "state_id": "MI"
  },
  {
    "zip": 49938,
    "lat": 46.53458,
    "lng": -90.11674,
    "state_id": "MI"
  },
  {
    "zip": 49942,
    "lat": 47.27452,
    "lng": -88.4071,
    "state_id": "MI"
  },
  {
    "zip": 49945,
    "lat": 47.18093,
    "lng": -88.29842,
    "state_id": "MI"
  },
  {
    "zip": 49946,
    "lat": 46.70629,
    "lng": -88.3165,
    "state_id": "MI"
  },
  {
    "zip": 49947,
    "lat": 46.38709,
    "lng": -89.59717,
    "state_id": "MI"
  },
  {
    "zip": 49948,
    "lat": 46.72151,
    "lng": -89.01733,
    "state_id": "MI"
  },
  {
    "zip": 49950,
    "lat": 47.38063,
    "lng": -88.11995,
    "state_id": "MI"
  },
  {
    "zip": 49952,
    "lat": 46.69708,
    "lng": -88.78883,
    "state_id": "MI"
  },
  {
    "zip": 49953,
    "lat": 46.80404,
    "lng": -89.37181,
    "state_id": "MI"
  },
  {
    "zip": 49955,
    "lat": 47.00441,
    "lng": -88.70782,
    "state_id": "MI"
  },
  {
    "zip": 49958,
    "lat": 46.82582,
    "lng": -88.67424,
    "state_id": "MI"
  },
  {
    "zip": 49959,
    "lat": 46.47064,
    "lng": -89.99838,
    "state_id": "MI"
  },
  {
    "zip": 49960,
    "lat": 46.71123,
    "lng": -89.25647,
    "state_id": "MI"
  },
  {
    "zip": 49961,
    "lat": 46.52257,
    "lng": -88.72205,
    "state_id": "MI"
  },
  {
    "zip": 49962,
    "lat": 46.83131,
    "lng": -88.15186,
    "state_id": "MI"
  },
  {
    "zip": 49963,
    "lat": 47.05329,
    "lng": -88.66482,
    "state_id": "MI"
  },
  {
    "zip": 49965,
    "lat": 46.95053,
    "lng": -88.85511,
    "state_id": "MI"
  },
  {
    "zip": 49967,
    "lat": 46.49554,
    "lng": -88.93683,
    "state_id": "MI"
  },
  {
    "zip": 49968,
    "lat": 46.49227,
    "lng": -89.893,
    "state_id": "MI"
  },
  {
    "zip": 49969,
    "lat": 46.2463,
    "lng": -89.22106,
    "state_id": "MI"
  },
  {
    "zip": 49970,
    "lat": 46.5412,
    "lng": -88.60494,
    "state_id": "MI"
  },
  {
    "zip": 49971,
    "lat": 46.67711,
    "lng": -89.70132,
    "state_id": "MI"
  },
  {
    "zip": 55001,
    "lat": 44.9017,
    "lng": -92.81888,
    "state_id": "MN"
  },
  {
    "zip": 55003,
    "lat": 45.01181,
    "lng": -92.77887,
    "state_id": "MN"
  },
  {
    "zip": 55005,
    "lat": 45.39234,
    "lng": -93.21887,
    "state_id": "MN"
  },
  {
    "zip": 55006,
    "lat": 45.73819,
    "lng": -93.20864,
    "state_id": "MN"
  },
  {
    "zip": 55007,
    "lat": 45.97515,
    "lng": -93.12285,
    "state_id": "MN"
  },
  {
    "zip": 55008,
    "lat": 45.57271,
    "lng": -93.27004,
    "state_id": "MN"
  },
  {
    "zip": 55009,
    "lat": 44.48303,
    "lng": -92.86484,
    "state_id": "MN"
  },
  {
    "zip": 55011,
    "lat": 45.34072,
    "lng": -93.25328,
    "state_id": "MN"
  },
  {
    "zip": 55012,
    "lat": 45.4379,
    "lng": -92.79168,
    "state_id": "MN"
  },
  {
    "zip": 55013,
    "lat": 45.35437,
    "lng": -92.89819,
    "state_id": "MN"
  },
  {
    "zip": 55014,
    "lat": 45.16421,
    "lng": -93.12315,
    "state_id": "MN"
  },
  {
    "zip": 55016,
    "lat": 44.8177,
    "lng": -92.93462,
    "state_id": "MN"
  },
  {
    "zip": 55017,
    "lat": 45.68019,
    "lng": -93.43366,
    "state_id": "MN"
  },
  {
    "zip": 55018,
    "lat": 44.42218,
    "lng": -92.99565,
    "state_id": "MN"
  },
  {
    "zip": 55019,
    "lat": 44.41445,
    "lng": -93.248,
    "state_id": "MN"
  },
  {
    "zip": 55020,
    "lat": 44.57624,
    "lng": -93.36947,
    "state_id": "MN"
  },
  {
    "zip": 55021,
    "lat": 44.29978,
    "lng": -93.27718,
    "state_id": "MN"
  },
  {
    "zip": 55024,
    "lat": 44.62994,
    "lng": -93.13428,
    "state_id": "MN"
  },
  {
    "zip": 55025,
    "lat": 45.26256,
    "lng": -93.0227,
    "state_id": "MN"
  },
  {
    "zip": 55026,
    "lat": 44.52642,
    "lng": -92.34858,
    "state_id": "MN"
  },
  {
    "zip": 55027,
    "lat": 44.40949,
    "lng": -92.62085,
    "state_id": "MN"
  },
  {
    "zip": 55029,
    "lat": 45.63861,
    "lng": -93.1986,
    "state_id": "MN"
  },
  {
    "zip": 55030,
    "lat": 45.8395,
    "lng": -93.11393,
    "state_id": "MN"
  },
  {
    "zip": 55031,
    "lat": 44.60805,
    "lng": -92.96228,
    "state_id": "MN"
  },
  {
    "zip": 55032,
    "lat": 45.58823,
    "lng": -92.99162,
    "state_id": "MN"
  },
  {
    "zip": 55033,
    "lat": 44.713,
    "lng": -92.86373,
    "state_id": "MN"
  },
  {
    "zip": 55036,
    "lat": 45.87114,
    "lng": -93.11959,
    "state_id": "MN"
  },
  {
    "zip": 55037,
    "lat": 46.01299,
    "lng": -92.77561,
    "state_id": "MN"
  },
  {
    "zip": 55038,
    "lat": 45.16869,
    "lng": -92.97938,
    "state_id": "MN"
  },
  {
    "zip": 55040,
    "lat": 45.4685,
    "lng": -93.27972,
    "state_id": "MN"
  },
  {
    "zip": 55041,
    "lat": 44.39402,
    "lng": -92.32022,
    "state_id": "MN"
  },
  {
    "zip": 55042,
    "lat": 44.99196,
    "lng": -92.89876,
    "state_id": "MN"
  },
  {
    "zip": 55043,
    "lat": 44.94089,
    "lng": -92.76967,
    "state_id": "MN"
  },
  {
    "zip": 55044,
    "lat": 44.64094,
    "lng": -93.2798,
    "state_id": "MN"
  },
  {
    "zip": 55045,
    "lat": 45.39473,
    "lng": -92.83217,
    "state_id": "MN"
  },
  {
    "zip": 55046,
    "lat": 44.44863,
    "lng": -93.41897,
    "state_id": "MN"
  },
  {
    "zip": 55047,
    "lat": 45.20246,
    "lng": -92.82401,
    "state_id": "MN"
  },
  {
    "zip": 55049,
    "lat": 44.17659,
    "lng": -93.22797,
    "state_id": "MN"
  },
  {
    "zip": 55051,
    "lat": 45.92772,
    "lng": -93.30432,
    "state_id": "MN"
  },
  {
    "zip": 55052,
    "lat": 44.23223,
    "lng": -93.43952,
    "state_id": "MN"
  },
  {
    "zip": 55053,
    "lat": 44.34655,
    "lng": -93.06401,
    "state_id": "MN"
  },
  {
    "zip": 55054,
    "lat": 44.57008,
    "lng": -93.35029,
    "state_id": "MN"
  },
  {
    "zip": 55055,
    "lat": 44.87382,
    "lng": -92.99777,
    "state_id": "MN"
  },
  {
    "zip": 55056,
    "lat": 45.50874,
    "lng": -92.95253,
    "state_id": "MN"
  },
  {
    "zip": 55057,
    "lat": 44.4713,
    "lng": -93.17617,
    "state_id": "MN"
  },
  {
    "zip": 55060,
    "lat": 44.05901,
    "lng": -93.22289,
    "state_id": "MN"
  },
  {
    "zip": 55063,
    "lat": 45.83631,
    "lng": -92.90419,
    "state_id": "MN"
  },
  {
    "zip": 55065,
    "lat": 44.54689,
    "lng": -93.01637,
    "state_id": "MN"
  },
  {
    "zip": 55066,
    "lat": 44.51829,
    "lng": -92.54508,
    "state_id": "MN"
  },
  {
    "zip": 55068,
    "lat": 44.73781,
    "lng": -93.06868,
    "state_id": "MN"
  },
  {
    "zip": 55069,
    "lat": 45.69343,
    "lng": -92.96549,
    "state_id": "MN"
  },
  {
    "zip": 55070,
    "lat": 45.41252,
    "lng": -93.38804,
    "state_id": "MN"
  },
  {
    "zip": 55071,
    "lat": 44.82146,
    "lng": -92.99653,
    "state_id": "MN"
  },
  {
    "zip": 55072,
    "lat": 46.14102,
    "lng": -92.61841,
    "state_id": "MN"
  },
  {
    "zip": 55073,
    "lat": 45.27293,
    "lng": -92.82494,
    "state_id": "MN"
  },
  {
    "zip": 55074,
    "lat": 45.38445,
    "lng": -92.73341,
    "state_id": "MN"
  },
  {
    "zip": 55075,
    "lat": 44.88747,
    "lng": -93.04093,
    "state_id": "MN"
  },
  {
    "zip": 55076,
    "lat": 44.83633,
    "lng": -93.03401,
    "state_id": "MN"
  },
  {
    "zip": 55077,
    "lat": 44.81828,
    "lng": -93.07364,
    "state_id": "MN"
  },
  {
    "zip": 55079,
    "lat": 45.41321,
    "lng": -93.03309,
    "state_id": "MN"
  },
  {
    "zip": 55080,
    "lat": 45.66146,
    "lng": -93.21462,
    "state_id": "MN"
  },
  {
    "zip": 55082,
    "lat": 45.07279,
    "lng": -92.84039,
    "state_id": "MN"
  },
  {
    "zip": 55084,
    "lat": 45.45951,
    "lng": -92.70629,
    "state_id": "MN"
  },
  {
    "zip": 55085,
    "lat": 44.67266,
    "lng": -92.96293,
    "state_id": "MN"
  },
  {
    "zip": 55087,
    "lat": 44.24851,
    "lng": -93.39495,
    "state_id": "MN"
  },
  {
    "zip": 55088,
    "lat": 44.53088,
    "lng": -93.38698,
    "state_id": "MN"
  },
  {
    "zip": 55089,
    "lat": 44.5814,
    "lng": -92.71593,
    "state_id": "MN"
  },
  {
    "zip": 55090,
    "lat": 45.05349,
    "lng": -92.95738,
    "state_id": "MN"
  },
  {
    "zip": 55092,
    "lat": 45.33657,
    "lng": -93.07864,
    "state_id": "MN"
  },
  {
    "zip": 55101,
    "lat": 44.9513,
    "lng": -93.09023,
    "state_id": "MN"
  },
  {
    "zip": 55102,
    "lat": 44.93189,
    "lng": -93.12161,
    "state_id": "MN"
  },
  {
    "zip": 55103,
    "lat": 44.96423,
    "lng": -93.12446,
    "state_id": "MN"
  },
  {
    "zip": 55104,
    "lat": 44.95447,
    "lng": -93.16221,
    "state_id": "MN"
  },
  {
    "zip": 55105,
    "lat": 44.9346,
    "lng": -93.16817,
    "state_id": "MN"
  },
  {
    "zip": 55106,
    "lat": 44.96353,
    "lng": -93.04827,
    "state_id": "MN"
  },
  {
    "zip": 55107,
    "lat": 44.93108,
    "lng": -93.07958,
    "state_id": "MN"
  },
  {
    "zip": 55108,
    "lat": 44.98095,
    "lng": -93.17541,
    "state_id": "MN"
  },
  {
    "zip": 55109,
    "lat": 45.01462,
    "lng": -93.02611,
    "state_id": "MN"
  },
  {
    "zip": 55110,
    "lat": 45.09024,
    "lng": -93.00627,
    "state_id": "MN"
  },
  {
    "zip": 55111,
    "lat": 44.87899,
    "lng": -93.19854,
    "state_id": "MN"
  },
  {
    "zip": 55112,
    "lat": 45.07658,
    "lng": -93.18893,
    "state_id": "MN"
  },
  {
    "zip": 55113,
    "lat": 45.01301,
    "lng": -93.15555,
    "state_id": "MN"
  },
  {
    "zip": 55114,
    "lat": 44.96609,
    "lng": -93.19433,
    "state_id": "MN"
  },
  {
    "zip": 55115,
    "lat": 45.06774,
    "lng": -92.95329,
    "state_id": "MN"
  },
  {
    "zip": 55116,
    "lat": 44.90973,
    "lng": -93.17024,
    "state_id": "MN"
  },
  {
    "zip": 55117,
    "lat": 44.99936,
    "lng": -93.09687,
    "state_id": "MN"
  },
  {
    "zip": 55118,
    "lat": 44.89648,
    "lng": -93.10344,
    "state_id": "MN"
  },
  {
    "zip": 55119,
    "lat": 44.93794,
    "lng": -93.00936,
    "state_id": "MN"
  },
  {
    "zip": 55120,
    "lat": 44.87251,
    "lng": -93.14907,
    "state_id": "MN"
  },
  {
    "zip": 55121,
    "lat": 44.84706,
    "lng": -93.15429,
    "state_id": "MN"
  },
  {
    "zip": 55122,
    "lat": 44.80469,
    "lng": -93.1977,
    "state_id": "MN"
  },
  {
    "zip": 55123,
    "lat": 44.80465,
    "lng": -93.13754,
    "state_id": "MN"
  },
  {
    "zip": 55124,
    "lat": 44.74557,
    "lng": -93.20073,
    "state_id": "MN"
  },
  {
    "zip": 55125,
    "lat": 44.91985,
    "lng": -92.94394,
    "state_id": "MN"
  },
  {
    "zip": 55126,
    "lat": 45.08477,
    "lng": -93.13255,
    "state_id": "MN"
  },
  {
    "zip": 55127,
    "lat": 45.08411,
    "lng": -93.08265,
    "state_id": "MN"
  },
  {
    "zip": 55128,
    "lat": 44.98793,
    "lng": -92.96393,
    "state_id": "MN"
  },
  {
    "zip": 55129,
    "lat": 44.89405,
    "lng": -92.90679,
    "state_id": "MN"
  },
  {
    "zip": 55130,
    "lat": 44.97298,
    "lng": -93.08268,
    "state_id": "MN"
  },
  {
    "zip": 55150,
    "lat": 44.8869,
    "lng": -93.1642,
    "state_id": "MN"
  },
  {
    "zip": 55155,
    "lat": 44.95601,
    "lng": -93.08265,
    "state_id": "MN"
  },
  {
    "zip": 55301,
    "lat": 45.24784,
    "lng": -93.65869,
    "state_id": "MN"
  },
  {
    "zip": 55302,
    "lat": 45.24268,
    "lng": -94.11894,
    "state_id": "MN"
  },
  {
    "zip": 55303,
    "lat": 45.28861,
    "lng": -93.42307,
    "state_id": "MN"
  },
  {
    "zip": 55304,
    "lat": 45.25574,
    "lng": -93.2655,
    "state_id": "MN"
  },
  {
    "zip": 55305,
    "lat": 44.95479,
    "lng": -93.43235,
    "state_id": "MN"
  },
  {
    "zip": 55306,
    "lat": 44.73069,
    "lng": -93.292,
    "state_id": "MN"
  },
  {
    "zip": 55307,
    "lat": 44.60335,
    "lng": -94.10241,
    "state_id": "MN"
  },
  {
    "zip": 55308,
    "lat": 45.43026,
    "lng": -93.83604,
    "state_id": "MN"
  },
  {
    "zip": 55309,
    "lat": 45.37999,
    "lng": -93.73908,
    "state_id": "MN"
  },
  {
    "zip": 55310,
    "lat": 44.75918,
    "lng": -94.88069,
    "state_id": "MN"
  },
  {
    "zip": 55311,
    "lat": 45.1049,
    "lng": -93.49482,
    "state_id": "MN"
  },
  {
    "zip": 55312,
    "lat": 44.70468,
    "lng": -94.34221,
    "state_id": "MN"
  },
  {
    "zip": 55313,
    "lat": 45.17379,
    "lng": -93.85143,
    "state_id": "MN"
  },
  {
    "zip": 55314,
    "lat": 44.76936,
    "lng": -94.59938,
    "state_id": "MN"
  },
  {
    "zip": 55315,
    "lat": 44.71842,
    "lng": -93.68698,
    "state_id": "MN"
  },
  {
    "zip": 55316,
    "lat": 45.17026,
    "lng": -93.39045,
    "state_id": "MN"
  },
  {
    "zip": 55317,
    "lat": 44.85809,
    "lng": -93.55192,
    "state_id": "MN"
  },
  {
    "zip": 55318,
    "lat": 44.81107,
    "lng": -93.63664,
    "state_id": "MN"
  },
  {
    "zip": 55319,
    "lat": 45.47349,
    "lng": -93.94425,
    "state_id": "MN"
  },
  {
    "zip": 55320,
    "lat": 45.39591,
    "lng": -94.08707,
    "state_id": "MN"
  },
  {
    "zip": 55321,
    "lat": 45.08414,
    "lng": -94.19274,
    "state_id": "MN"
  },
  {
    "zip": 55322,
    "lat": 44.77013,
    "lng": -93.78573,
    "state_id": "MN"
  },
  {
    "zip": 55324,
    "lat": 45.05595,
    "lng": -94.42354,
    "state_id": "MN"
  },
  {
    "zip": 55325,
    "lat": 45.0837,
    "lng": -94.31909,
    "state_id": "MN"
  },
  {
    "zip": 55327,
    "lat": 45.19864,
    "lng": -93.47573,
    "state_id": "MN"
  },
  {
    "zip": 55328,
    "lat": 45.03575,
    "lng": -93.81318,
    "state_id": "MN"
  },
  {
    "zip": 55329,
    "lat": 45.3092,
    "lng": -94.57192,
    "state_id": "MN"
  },
  {
    "zip": 55330,
    "lat": 45.33432,
    "lng": -93.5679,
    "state_id": "MN"
  },
  {
    "zip": 55331,
    "lat": 44.89951,
    "lng": -93.60394,
    "state_id": "MN"
  },
  {
    "zip": 55332,
    "lat": 44.52043,
    "lng": -94.70944,
    "state_id": "MN"
  },
  {
    "zip": 55333,
    "lat": 44.56018,
    "lng": -94.86708,
    "state_id": "MN"
  },
  {
    "zip": 55334,
    "lat": 44.54169,
    "lng": -94.20761,
    "state_id": "MN"
  },
  {
    "zip": 55335,
    "lat": 44.52995,
    "lng": -94.5472,
    "state_id": "MN"
  },
  {
    "zip": 55336,
    "lat": 44.77083,
    "lng": -94.18114,
    "state_id": "MN"
  },
  {
    "zip": 55337,
    "lat": 44.77668,
    "lng": -93.27503,
    "state_id": "MN"
  },
  {
    "zip": 55338,
    "lat": 44.66948,
    "lng": -93.99815,
    "state_id": "MN"
  },
  {
    "zip": 55339,
    "lat": 44.72709,
    "lng": -93.95634,
    "state_id": "MN"
  },
  {
    "zip": 55340,
    "lat": 45.0762,
    "lng": -93.57576,
    "state_id": "MN"
  },
  {
    "zip": 55341,
    "lat": 45.15922,
    "lng": -93.65914,
    "state_id": "MN"
  },
  {
    "zip": 55342,
    "lat": 44.74281,
    "lng": -94.73171,
    "state_id": "MN"
  },
  {
    "zip": 55343,
    "lat": 44.91441,
    "lng": -93.41625,
    "state_id": "MN"
  },
  {
    "zip": 55344,
    "lat": 44.86428,
    "lng": -93.43082,
    "state_id": "MN"
  },
  {
    "zip": 55345,
    "lat": 44.91527,
    "lng": -93.48375,
    "state_id": "MN"
  },
  {
    "zip": 55346,
    "lat": 44.87844,
    "lng": -93.48322,
    "state_id": "MN"
  },
  {
    "zip": 55347,
    "lat": 44.82932,
    "lng": -93.46632,
    "state_id": "MN"
  },
  {
    "zip": 55349,
    "lat": 45.05955,
    "lng": -94.07407,
    "state_id": "MN"
  },
  {
    "zip": 55350,
    "lat": 44.89535,
    "lng": -94.3939,
    "state_id": "MN"
  },
  {
    "zip": 55352,
    "lat": 44.65501,
    "lng": -93.58766,
    "state_id": "MN"
  },
  {
    "zip": 55353,
    "lat": 45.32194,
    "lng": -94.31535,
    "state_id": "MN"
  },
  {
    "zip": 55354,
    "lat": 44.88304,
    "lng": -94.06871,
    "state_id": "MN"
  },
  {
    "zip": 55355,
    "lat": 45.09862,
    "lng": -94.53992,
    "state_id": "MN"
  },
  {
    "zip": 55356,
    "lat": 44.99301,
    "lng": -93.58801,
    "state_id": "MN"
  },
  {
    "zip": 55357,
    "lat": 45.09912,
    "lng": -93.65971,
    "state_id": "MN"
  },
  {
    "zip": 55358,
    "lat": 45.25161,
    "lng": -93.99595,
    "state_id": "MN"
  },
  {
    "zip": 55359,
    "lat": 45.00502,
    "lng": -93.70126,
    "state_id": "MN"
  },
  {
    "zip": 55360,
    "lat": 44.90864,
    "lng": -93.91963,
    "state_id": "MN"
  },
  {
    "zip": 55362,
    "lat": 45.30049,
    "lng": -93.8316,
    "state_id": "MN"
  },
  {
    "zip": 55363,
    "lat": 45.04414,
    "lng": -93.91389,
    "state_id": "MN"
  },
  {
    "zip": 55364,
    "lat": 44.93742,
    "lng": -93.67275,
    "state_id": "MN"
  },
  {
    "zip": 55366,
    "lat": 44.67262,
    "lng": -94.23203,
    "state_id": "MN"
  },
  {
    "zip": 55367,
    "lat": 44.89503,
    "lng": -93.97722,
    "state_id": "MN"
  },
  {
    "zip": 55368,
    "lat": 44.74172,
    "lng": -93.89419,
    "state_id": "MN"
  },
  {
    "zip": 55369,
    "lat": 45.12644,
    "lng": -93.44629,
    "state_id": "MN"
  },
  {
    "zip": 55370,
    "lat": 44.77738,
    "lng": -94.03899,
    "state_id": "MN"
  },
  {
    "zip": 55371,
    "lat": 45.5801,
    "lng": -93.59086,
    "state_id": "MN"
  },
  {
    "zip": 55372,
    "lat": 44.67752,
    "lng": -93.41126,
    "state_id": "MN"
  },
  {
    "zip": 55373,
    "lat": 45.08818,
    "lng": -93.72376,
    "state_id": "MN"
  },
  {
    "zip": 55374,
    "lat": 45.17646,
    "lng": -93.57956,
    "state_id": "MN"
  },
  {
    "zip": 55375,
    "lat": 44.89699,
    "lng": -93.74287,
    "state_id": "MN"
  },
  {
    "zip": 55376,
    "lat": 45.21057,
    "lng": -93.68726,
    "state_id": "MN"
  },
  {
    "zip": 55378,
    "lat": 44.75741,
    "lng": -93.36308,
    "state_id": "MN"
  },
  {
    "zip": 55379,
    "lat": 44.75421,
    "lng": -93.51618,
    "state_id": "MN"
  },
  {
    "zip": 55381,
    "lat": 44.92671,
    "lng": -94.18751,
    "state_id": "MN"
  },
  {
    "zip": 55382,
    "lat": 45.30632,
    "lng": -94.20458,
    "state_id": "MN"
  },
  {
    "zip": 55384,
    "lat": 44.9368,
    "lng": -93.63183,
    "state_id": "MN"
  },
  {
    "zip": 55385,
    "lat": 44.73835,
    "lng": -94.49127,
    "state_id": "MN"
  },
  {
    "zip": 55386,
    "lat": 44.86264,
    "lng": -93.66794,
    "state_id": "MN"
  },
  {
    "zip": 55387,
    "lat": 44.85669,
    "lng": -93.77569,
    "state_id": "MN"
  },
  {
    "zip": 55388,
    "lat": 44.94945,
    "lng": -93.84551,
    "state_id": "MN"
  },
  {
    "zip": 55389,
    "lat": 45.29687,
    "lng": -94.43982,
    "state_id": "MN"
  },
  {
    "zip": 55390,
    "lat": 45.0653,
    "lng": -93.97954,
    "state_id": "MN"
  },
  {
    "zip": 55391,
    "lat": 44.95945,
    "lng": -93.54199,
    "state_id": "MN"
  },
  {
    "zip": 55395,
    "lat": 44.9534,
    "lng": -94.06546,
    "state_id": "MN"
  },
  {
    "zip": 55396,
    "lat": 44.54548,
    "lng": -94.36698,
    "state_id": "MN"
  },
  {
    "zip": 55397,
    "lat": 44.81564,
    "lng": -93.93409,
    "state_id": "MN"
  },
  {
    "zip": 55398,
    "lat": 45.46401,
    "lng": -93.59843,
    "state_id": "MN"
  },
  {
    "zip": 55401,
    "lat": 44.98479,
    "lng": -93.26946,
    "state_id": "MN"
  },
  {
    "zip": 55402,
    "lat": 44.97605,
    "lng": -93.2716,
    "state_id": "MN"
  },
  {
    "zip": 55403,
    "lat": 44.96998,
    "lng": -93.2857,
    "state_id": "MN"
  },
  {
    "zip": 55404,
    "lat": 44.96197,
    "lng": -93.26085,
    "state_id": "MN"
  },
  {
    "zip": 55405,
    "lat": 44.97026,
    "lng": -93.30476,
    "state_id": "MN"
  },
  {
    "zip": 55406,
    "lat": 44.93957,
    "lng": -93.22115,
    "state_id": "MN"
  },
  {
    "zip": 55407,
    "lat": 44.93503,
    "lng": -93.2529,
    "state_id": "MN"
  },
  {
    "zip": 55408,
    "lat": 44.94005,
    "lng": -93.29197,
    "state_id": "MN"
  },
  {
    "zip": 55409,
    "lat": 44.9303,
    "lng": -93.28142,
    "state_id": "MN"
  },
  {
    "zip": 55410,
    "lat": 44.91187,
    "lng": -93.31958,
    "state_id": "MN"
  },
  {
    "zip": 55411,
    "lat": 44.99927,
    "lng": -93.29835,
    "state_id": "MN"
  },
  {
    "zip": 55412,
    "lat": 45.02701,
    "lng": -93.29983,
    "state_id": "MN"
  },
  {
    "zip": 55413,
    "lat": 44.99895,
    "lng": -93.24185,
    "state_id": "MN"
  },
  {
    "zip": 55414,
    "lat": 44.97936,
    "lng": -93.22646,
    "state_id": "MN"
  },
  {
    "zip": 55415,
    "lat": 44.97478,
    "lng": -93.25765,
    "state_id": "MN"
  },
  {
    "zip": 55416,
    "lat": 44.94927,
    "lng": -93.33884,
    "state_id": "MN"
  },
  {
    "zip": 55417,
    "lat": 44.90409,
    "lng": -93.23078,
    "state_id": "MN"
  },
  {
    "zip": 55418,
    "lat": 45.02122,
    "lng": -93.24284,
    "state_id": "MN"
  },
  {
    "zip": 55419,
    "lat": 44.90593,
    "lng": -93.28735,
    "state_id": "MN"
  },
  {
    "zip": 55420,
    "lat": 44.83593,
    "lng": -93.27774,
    "state_id": "MN"
  },
  {
    "zip": 55421,
    "lat": 45.0509,
    "lng": -93.25313,
    "state_id": "MN"
  },
  {
    "zip": 55422,
    "lat": 45.00908,
    "lng": -93.33952,
    "state_id": "MN"
  },
  {
    "zip": 55423,
    "lat": 44.87624,
    "lng": -93.28215,
    "state_id": "MN"
  },
  {
    "zip": 55424,
    "lat": 44.90525,
    "lng": -93.33976,
    "state_id": "MN"
  },
  {
    "zip": 55425,
    "lat": 44.84222,
    "lng": -93.23632,
    "state_id": "MN"
  },
  {
    "zip": 55426,
    "lat": 44.95532,
    "lng": -93.38088,
    "state_id": "MN"
  },
  {
    "zip": 55427,
    "lat": 45.00628,
    "lng": -93.38022,
    "state_id": "MN"
  },
  {
    "zip": 55428,
    "lat": 45.06322,
    "lng": -93.3812,
    "state_id": "MN"
  },
  {
    "zip": 55429,
    "lat": 45.06454,
    "lng": -93.34137,
    "state_id": "MN"
  },
  {
    "zip": 55430,
    "lat": 45.06397,
    "lng": -93.30129,
    "state_id": "MN"
  },
  {
    "zip": 55431,
    "lat": 44.82605,
    "lng": -93.31243,
    "state_id": "MN"
  },
  {
    "zip": 55432,
    "lat": 45.09695,
    "lng": -93.25467,
    "state_id": "MN"
  },
  {
    "zip": 55433,
    "lat": 45.16134,
    "lng": -93.31642,
    "state_id": "MN"
  },
  {
    "zip": 55434,
    "lat": 45.16809,
    "lng": -93.2505,
    "state_id": "MN"
  },
  {
    "zip": 55435,
    "lat": 44.87352,
    "lng": -93.33471,
    "state_id": "MN"
  },
  {
    "zip": 55436,
    "lat": 44.9034,
    "lng": -93.37405,
    "state_id": "MN"
  },
  {
    "zip": 55437,
    "lat": 44.82463,
    "lng": -93.34525,
    "state_id": "MN"
  },
  {
    "zip": 55438,
    "lat": 44.82494,
    "lng": -93.37819,
    "state_id": "MN"
  },
  {
    "zip": 55439,
    "lat": 44.87513,
    "lng": -93.37569,
    "state_id": "MN"
  },
  {
    "zip": 55441,
    "lat": 45.00459,
    "lng": -93.42831,
    "state_id": "MN"
  },
  {
    "zip": 55442,
    "lat": 45.04855,
    "lng": -93.42689,
    "state_id": "MN"
  },
  {
    "zip": 55443,
    "lat": 45.11958,
    "lng": -93.33713,
    "state_id": "MN"
  },
  {
    "zip": 55444,
    "lat": 45.10596,
    "lng": -93.30214,
    "state_id": "MN"
  },
  {
    "zip": 55445,
    "lat": 45.12327,
    "lng": -93.37971,
    "state_id": "MN"
  },
  {
    "zip": 55446,
    "lat": 45.04404,
    "lng": -93.48754,
    "state_id": "MN"
  },
  {
    "zip": 55447,
    "lat": 45.0011,
    "lng": -93.4892,
    "state_id": "MN"
  },
  {
    "zip": 55448,
    "lat": 45.19075,
    "lng": -93.30214,
    "state_id": "MN"
  },
  {
    "zip": 55449,
    "lat": 45.17278,
    "lng": -93.19461,
    "state_id": "MN"
  },
  {
    "zip": 55450,
    "lat": 44.87908,
    "lng": -93.22705,
    "state_id": "MN"
  },
  {
    "zip": 55454,
    "lat": 44.96966,
    "lng": -93.2422,
    "state_id": "MN"
  },
  {
    "zip": 55455,
    "lat": 44.97358,
    "lng": -93.23316,
    "state_id": "MN"
  },
  {
    "zip": 55601,
    "lat": 47.23577,
    "lng": -91.36285,
    "state_id": "MN"
  },
  {
    "zip": 55602,
    "lat": 47.34149,
    "lng": -91.87954,
    "state_id": "MN"
  },
  {
    "zip": 55603,
    "lat": 47.55843,
    "lng": -91.26394,
    "state_id": "MN"
  },
  {
    "zip": 55604,
    "lat": 47.96004,
    "lng": -90.56468,
    "state_id": "MN"
  },
  {
    "zip": 55605,
    "lat": 47.96314,
    "lng": -89.75309,
    "state_id": "MN"
  },
  {
    "zip": 55606,
    "lat": 47.93521,
    "lng": -89.99773,
    "state_id": "MN"
  },
  {
    "zip": 55607,
    "lat": 47.62179,
    "lng": -91.51817,
    "state_id": "MN"
  },
  {
    "zip": 55609,
    "lat": 46.97015,
    "lng": -91.78115,
    "state_id": "MN"
  },
  {
    "zip": 55612,
    "lat": 47.77269,
    "lng": -90.70127,
    "state_id": "MN"
  },
  {
    "zip": 55613,
    "lat": 47.60721,
    "lng": -90.95745,
    "state_id": "MN"
  },
  {
    "zip": 55614,
    "lat": 47.36675,
    "lng": -91.39457,
    "state_id": "MN"
  },
  {
    "zip": 55615,
    "lat": 47.73504,
    "lng": -90.83271,
    "state_id": "MN"
  },
  {
    "zip": 55616,
    "lat": 47.19075,
    "lng": -91.66289,
    "state_id": "MN"
  },
  {
    "zip": 55702,
    "lat": 46.95298,
    "lng": -92.67355,
    "state_id": "MN"
  },
  {
    "zip": 55703,
    "lat": 47.74202,
    "lng": -92.78159,
    "state_id": "MN"
  },
  {
    "zip": 55704,
    "lat": 46.20394,
    "lng": -92.76826,
    "state_id": "MN"
  },
  {
    "zip": 55705,
    "lat": 47.44915,
    "lng": -92.2033,
    "state_id": "MN"
  },
  {
    "zip": 55706,
    "lat": 47.72478,
    "lng": -91.96488,
    "state_id": "MN"
  },
  {
    "zip": 55707,
    "lat": 46.54618,
    "lng": -92.64828,
    "state_id": "MN"
  },
  {
    "zip": 55708,
    "lat": 47.57147,
    "lng": -92.33939,
    "state_id": "MN"
  },
  {
    "zip": 55709,
    "lat": 47.42657,
    "lng": -93.37103,
    "state_id": "MN"
  },
  {
    "zip": 55710,
    "lat": 47.62542,
    "lng": -92.71544,
    "state_id": "MN"
  },
  {
    "zip": 55711,
    "lat": 46.84457,
    "lng": -92.6989,
    "state_id": "MN"
  },
  {
    "zip": 55712,
    "lat": 46.29373,
    "lng": -92.49977,
    "state_id": "MN"
  },
  {
    "zip": 55713,
    "lat": 47.49064,
    "lng": -92.76718,
    "state_id": "MN"
  },
  {
    "zip": 55716,
    "lat": 47.31915,
    "lng": -93.26726,
    "state_id": "MN"
  },
  {
    "zip": 55717,
    "lat": 47.05818,
    "lng": -92.39241,
    "state_id": "MN"
  },
  {
    "zip": 55718,
    "lat": 46.62315,
    "lng": -92.48532,
    "state_id": "MN"
  },
  {
    "zip": 55719,
    "lat": 47.55556,
    "lng": -92.87256,
    "state_id": "MN"
  },
  {
    "zip": 55720,
    "lat": 46.7546,
    "lng": -92.54077,
    "state_id": "MN"
  },
  {
    "zip": 55721,
    "lat": 47.21981,
    "lng": -93.68697,
    "state_id": "MN"
  },
  {
    "zip": 55722,
    "lat": 47.27937,
    "lng": -93.43921,
    "state_id": "MN"
  },
  {
    "zip": 55723,
    "lat": 47.82291,
    "lng": -92.9351,
    "state_id": "MN"
  },
  {
    "zip": 55724,
    "lat": 47.17903,
    "lng": -92.36188,
    "state_id": "MN"
  },
  {
    "zip": 55725,
    "lat": 48.25997,
    "lng": -92.30212,
    "state_id": "MN"
  },
  {
    "zip": 55726,
    "lat": 46.67413,
    "lng": -92.83121,
    "state_id": "MN"
  },
  {
    "zip": 55731,
    "lat": 47.97722,
    "lng": -91.65229,
    "state_id": "MN"
  },
  {
    "zip": 55732,
    "lat": 47.67754,
    "lng": -92.21375,
    "state_id": "MN"
  },
  {
    "zip": 55733,
    "lat": 46.70914,
    "lng": -92.36292,
    "state_id": "MN"
  },
  {
    "zip": 55734,
    "lat": 47.3576,
    "lng": -92.47242,
    "state_id": "MN"
  },
  {
    "zip": 55735,
    "lat": 46.21689,
    "lng": -93.02405,
    "state_id": "MN"
  },
  {
    "zip": 55736,
    "lat": 46.96363,
    "lng": -92.93793,
    "state_id": "MN"
  },
  {
    "zip": 55738,
    "lat": 47.27432,
    "lng": -92.67239,
    "state_id": "MN"
  },
  {
    "zip": 55741,
    "lat": 47.4633,
    "lng": -92.39795,
    "state_id": "MN"
  },
  {
    "zip": 55742,
    "lat": 47.19558,
    "lng": -93.1365,
    "state_id": "MN"
  },
  {
    "zip": 55744,
    "lat": 47.23487,
    "lng": -93.51155,
    "state_id": "MN"
  },
  {
    "zip": 55746,
    "lat": 47.36127,
    "lng": -92.95286,
    "state_id": "MN"
  },
  {
    "zip": 55748,
    "lat": 47.00801,
    "lng": -93.62389,
    "state_id": "MN"
  },
  {
    "zip": 55749,
    "lat": 46.44528,
    "lng": -92.42728,
    "state_id": "MN"
  },
  {
    "zip": 55750,
    "lat": 47.52203,
    "lng": -91.96576,
    "state_id": "MN"
  },
  {
    "zip": 55751,
    "lat": 47.39855,
    "lng": -92.66106,
    "state_id": "MN"
  },
  {
    "zip": 55752,
    "lat": 46.95319,
    "lng": -93.2774,
    "state_id": "MN"
  },
  {
    "zip": 55753,
    "lat": 47.39547,
    "lng": -93.08159,
    "state_id": "MN"
  },
  {
    "zip": 55756,
    "lat": 46.36669,
    "lng": -92.59251,
    "state_id": "MN"
  },
  {
    "zip": 55757,
    "lat": 46.51485,
    "lng": -92.93706,
    "state_id": "MN"
  },
  {
    "zip": 55758,
    "lat": 47.50955,
    "lng": -92.72588,
    "state_id": "MN"
  },
  {
    "zip": 55760,
    "lat": 46.64196,
    "lng": -93.24358,
    "state_id": "MN"
  },
  {
    "zip": 55763,
    "lat": 47.32622,
    "lng": -92.14929,
    "state_id": "MN"
  },
  {
    "zip": 55764,
    "lat": 47.32773,
    "lng": -93.31055,
    "state_id": "MN"
  },
  {
    "zip": 55765,
    "lat": 47.10843,
    "lng": -92.76083,
    "state_id": "MN"
  },
  {
    "zip": 55766,
    "lat": 47.2581,
    "lng": -92.42755,
    "state_id": "MN"
  },
  {
    "zip": 55767,
    "lat": 46.45069,
    "lng": -92.75917,
    "state_id": "MN"
  },
  {
    "zip": 55768,
    "lat": 47.48668,
    "lng": -92.68649,
    "state_id": "MN"
  },
  {
    "zip": 55769,
    "lat": 47.485,
    "lng": -93.18871,
    "state_id": "MN"
  },
  {
    "zip": 55771,
    "lat": 48.14393,
    "lng": -92.85607,
    "state_id": "MN"
  },
  {
    "zip": 55772,
    "lat": 48.09474,
    "lng": -93.15553,
    "state_id": "MN"
  },
  {
    "zip": 55775,
    "lat": 47.31315,
    "lng": -93.16784,
    "state_id": "MN"
  },
  {
    "zip": 55779,
    "lat": 46.91574,
    "lng": -92.44455,
    "state_id": "MN"
  },
  {
    "zip": 55780,
    "lat": 46.68309,
    "lng": -92.69275,
    "state_id": "MN"
  },
  {
    "zip": 55781,
    "lat": 47.66706,
    "lng": -93.04234,
    "state_id": "MN"
  },
  {
    "zip": 55782,
    "lat": 47.8231,
    "lng": -92.2049,
    "state_id": "MN"
  },
  {
    "zip": 55783,
    "lat": 46.3964,
    "lng": -92.88291,
    "state_id": "MN"
  },
  {
    "zip": 55784,
    "lat": 47.05249,
    "lng": -93.18733,
    "state_id": "MN"
  },
  {
    "zip": 55785,
    "lat": 46.92319,
    "lng": -93.74633,
    "state_id": "MN"
  },
  {
    "zip": 55786,
    "lat": 47.31638,
    "lng": -93.39046,
    "state_id": "MN"
  },
  {
    "zip": 55787,
    "lat": 46.67989,
    "lng": -93.11205,
    "state_id": "MN"
  },
  {
    "zip": 55790,
    "lat": 47.93483,
    "lng": -92.28442,
    "state_id": "MN"
  },
  {
    "zip": 55792,
    "lat": 47.60053,
    "lng": -92.48723,
    "state_id": "MN"
  },
  {
    "zip": 55793,
    "lat": 47.1159,
    "lng": -93.28405,
    "state_id": "MN"
  },
  {
    "zip": 55795,
    "lat": 46.3139,
    "lng": -92.86291,
    "state_id": "MN"
  },
  {
    "zip": 55797,
    "lat": 46.56514,
    "lng": -92.36871,
    "state_id": "MN"
  },
  {
    "zip": 55798,
    "lat": 46.71831,
    "lng": -92.98054,
    "state_id": "MN"
  },
  {
    "zip": 55802,
    "lat": 46.75279,
    "lng": -92.08194,
    "state_id": "MN"
  },
  {
    "zip": 55803,
    "lat": 47.04751,
    "lng": -92.05538,
    "state_id": "MN"
  },
  {
    "zip": 55804,
    "lat": 46.93687,
    "lng": -91.93249,
    "state_id": "MN"
  },
  {
    "zip": 55805,
    "lat": 46.80082,
    "lng": -92.09558,
    "state_id": "MN"
  },
  {
    "zip": 55806,
    "lat": 46.76815,
    "lng": -92.12837,
    "state_id": "MN"
  },
  {
    "zip": 55807,
    "lat": 46.73519,
    "lng": -92.16843,
    "state_id": "MN"
  },
  {
    "zip": 55808,
    "lat": 46.6767,
    "lng": -92.23808,
    "state_id": "MN"
  },
  {
    "zip": 55810,
    "lat": 46.75959,
    "lng": -92.27299,
    "state_id": "MN"
  },
  {
    "zip": 55811,
    "lat": 46.84223,
    "lng": -92.22244,
    "state_id": "MN"
  },
  {
    "zip": 55812,
    "lat": 46.81162,
    "lng": -92.07396,
    "state_id": "MN"
  },
  {
    "zip": 55814,
    "lat": 46.83413,
    "lng": -92.19769,
    "state_id": "MN"
  },
  {
    "zip": 55901,
    "lat": 44.06977,
    "lng": -92.50688,
    "state_id": "MN"
  },
  {
    "zip": 55902,
    "lat": 43.96721,
    "lng": -92.51596,
    "state_id": "MN"
  },
  {
    "zip": 55904,
    "lat": 43.95996,
    "lng": -92.40063,
    "state_id": "MN"
  },
  {
    "zip": 55905,
    "lat": 44.05595,
    "lng": -92.52583,
    "state_id": "MN"
  },
  {
    "zip": 55906,
    "lat": 44.10731,
    "lng": -92.40531,
    "state_id": "MN"
  },
  {
    "zip": 55909,
    "lat": 43.56201,
    "lng": -92.73378,
    "state_id": "MN"
  },
  {
    "zip": 55910,
    "lat": 44.13295,
    "lng": -91.97332,
    "state_id": "MN"
  },
  {
    "zip": 55912,
    "lat": 43.68155,
    "lng": -92.99022,
    "state_id": "MN"
  },
  {
    "zip": 55917,
    "lat": 43.87833,
    "lng": -93.071,
    "state_id": "MN"
  },
  {
    "zip": 55918,
    "lat": 43.74081,
    "lng": -92.84647,
    "state_id": "MN"
  },
  {
    "zip": 55919,
    "lat": 43.61871,
    "lng": -91.29394,
    "state_id": "MN"
  },
  {
    "zip": 55920,
    "lat": 44.01384,
    "lng": -92.62204,
    "state_id": "MN"
  },
  {
    "zip": 55921,
    "lat": 43.62303,
    "lng": -91.4626,
    "state_id": "MN"
  },
  {
    "zip": 55922,
    "lat": 43.5589,
    "lng": -91.90098,
    "state_id": "MN"
  },
  {
    "zip": 55923,
    "lat": 43.85103,
    "lng": -92.18612,
    "state_id": "MN"
  },
  {
    "zip": 55924,
    "lat": 44.03024,
    "lng": -93.01691,
    "state_id": "MN"
  },
  {
    "zip": 55925,
    "lat": 43.90903,
    "lng": -91.4623,
    "state_id": "MN"
  },
  {
    "zip": 55926,
    "lat": 43.74327,
    "lng": -92.70689,
    "state_id": "MN"
  },
  {
    "zip": 55927,
    "lat": 44.03432,
    "lng": -92.87104,
    "state_id": "MN"
  },
  {
    "zip": 55929,
    "lat": 43.97676,
    "lng": -92.13966,
    "state_id": "MN"
  },
  {
    "zip": 55931,
    "lat": 43.51402,
    "lng": -91.39604,
    "state_id": "MN"
  },
  {
    "zip": 55932,
    "lat": 44.1391,
    "lng": -92.3019,
    "state_id": "MN"
  },
  {
    "zip": 55933,
    "lat": 43.64605,
    "lng": -92.6806,
    "state_id": "MN"
  },
  {
    "zip": 55934,
    "lat": 44.00402,
    "lng": -92.26424,
    "state_id": "MN"
  },
  {
    "zip": 55935,
    "lat": 43.74892,
    "lng": -92.12597,
    "state_id": "MN"
  },
  {
    "zip": 55936,
    "lat": 43.71374,
    "lng": -92.5837,
    "state_id": "MN"
  },
  {
    "zip": 55939,
    "lat": 43.54334,
    "lng": -92.09167,
    "state_id": "MN"
  },
  {
    "zip": 55940,
    "lat": 43.89491,
    "lng": -92.80423,
    "state_id": "MN"
  },
  {
    "zip": 55941,
    "lat": 43.72564,
    "lng": -91.34828,
    "state_id": "MN"
  },
  {
    "zip": 55943,
    "lat": 43.79079,
    "lng": -91.58398,
    "state_id": "MN"
  },
  {
    "zip": 55944,
    "lat": 43.98769,
    "lng": -92.73786,
    "state_id": "MN"
  },
  {
    "zip": 55945,
    "lat": 44.27089,
    "lng": -92.06606,
    "state_id": "MN"
  },
  {
    "zip": 55946,
    "lat": 44.26963,
    "lng": -92.96187,
    "state_id": "MN"
  },
  {
    "zip": 55947,
    "lat": 43.8247,
    "lng": -91.35784,
    "state_id": "MN"
  },
  {
    "zip": 55949,
    "lat": 43.71262,
    "lng": -91.9379,
    "state_id": "MN"
  },
  {
    "zip": 55950,
    "lat": 43.7471,
    "lng": -92.96451,
    "state_id": "MN"
  },
  {
    "zip": 55951,
    "lat": 43.53642,
    "lng": -92.48128,
    "state_id": "MN"
  },
  {
    "zip": 55952,
    "lat": 43.94335,
    "lng": -91.84356,
    "state_id": "MN"
  },
  {
    "zip": 55953,
    "lat": 43.52651,
    "lng": -92.96659,
    "state_id": "MN"
  },
  {
    "zip": 55954,
    "lat": 43.56465,
    "lng": -91.78085,
    "state_id": "MN"
  },
  {
    "zip": 55955,
    "lat": 44.08924,
    "lng": -92.7398,
    "state_id": "MN"
  },
  {
    "zip": 55956,
    "lat": 44.28344,
    "lng": -92.52956,
    "state_id": "MN"
  },
  {
    "zip": 55957,
    "lat": 44.25102,
    "lng": -92.28267,
    "state_id": "MN"
  },
  {
    "zip": 55959,
    "lat": 44.11011,
    "lng": -91.78445,
    "state_id": "MN"
  },
  {
    "zip": 55960,
    "lat": 44.14076,
    "lng": -92.53738,
    "state_id": "MN"
  },
  {
    "zip": 55961,
    "lat": 43.60842,
    "lng": -92.47377,
    "state_id": "MN"
  },
  {
    "zip": 55962,
    "lat": 43.76172,
    "lng": -91.84183,
    "state_id": "MN"
  },
  {
    "zip": 55963,
    "lat": 44.1898,
    "lng": -92.66251,
    "state_id": "MN"
  },
  {
    "zip": 55964,
    "lat": 44.16445,
    "lng": -92.15669,
    "state_id": "MN"
  },
  {
    "zip": 55965,
    "lat": 43.62841,
    "lng": -92.12664,
    "state_id": "MN"
  },
  {
    "zip": 55967,
    "lat": 43.79517,
    "lng": -92.51341,
    "state_id": "MN"
  },
  {
    "zip": 55968,
    "lat": 44.39778,
    "lng": -92.08944,
    "state_id": "MN"
  },
  {
    "zip": 55969,
    "lat": 44.08247,
    "lng": -91.87731,
    "state_id": "MN"
  },
  {
    "zip": 55970,
    "lat": 43.5951,
    "lng": -92.83191,
    "state_id": "MN"
  },
  {
    "zip": 55971,
    "lat": 43.81586,
    "lng": -91.75538,
    "state_id": "MN"
  },
  {
    "zip": 55972,
    "lat": 43.99619,
    "lng": -92.04897,
    "state_id": "MN"
  },
  {
    "zip": 55973,
    "lat": 43.80626,
    "lng": -92.77284,
    "state_id": "MN"
  },
  {
    "zip": 55974,
    "lat": 43.58027,
    "lng": -91.64952,
    "state_id": "MN"
  },
  {
    "zip": 55975,
    "lat": 43.67542,
    "lng": -92.37189,
    "state_id": "MN"
  },
  {
    "zip": 55976,
    "lat": 43.86431,
    "lng": -92.4874,
    "state_id": "MN"
  },
  {
    "zip": 55977,
    "lat": 43.55577,
    "lng": -92.64807,
    "state_id": "MN"
  },
  {
    "zip": 55979,
    "lat": 43.92042,
    "lng": -91.95094,
    "state_id": "MN"
  },
  {
    "zip": 55981,
    "lat": 44.35555,
    "lng": -92.06703,
    "state_id": "MN"
  },
  {
    "zip": 55982,
    "lat": 43.81574,
    "lng": -92.88474,
    "state_id": "MN"
  },
  {
    "zip": 55983,
    "lat": 44.2861,
    "lng": -92.80949,
    "state_id": "MN"
  },
  {
    "zip": 55985,
    "lat": 44.15827,
    "lng": -92.90358,
    "state_id": "MN"
  },
  {
    "zip": 55987,
    "lat": 43.98519,
    "lng": -91.63469,
    "state_id": "MN"
  },
  {
    "zip": 55990,
    "lat": 43.7322,
    "lng": -92.26178,
    "state_id": "MN"
  },
  {
    "zip": 55991,
    "lat": 44.25815,
    "lng": -92.4193,
    "state_id": "MN"
  },
  {
    "zip": 55992,
    "lat": 44.30605,
    "lng": -92.68211,
    "state_id": "MN"
  },
  {
    "zip": 56001,
    "lat": 44.13203,
    "lng": -93.97704,
    "state_id": "MN"
  },
  {
    "zip": 56003,
    "lat": 44.21274,
    "lng": -94.0886,
    "state_id": "MN"
  },
  {
    "zip": 56007,
    "lat": 43.66036,
    "lng": -93.32527,
    "state_id": "MN"
  },
  {
    "zip": 56009,
    "lat": 43.66368,
    "lng": -93.55584,
    "state_id": "MN"
  },
  {
    "zip": 56010,
    "lat": 43.89118,
    "lng": -94.17806,
    "state_id": "MN"
  },
  {
    "zip": 56011,
    "lat": 44.60236,
    "lng": -93.77421,
    "state_id": "MN"
  },
  {
    "zip": 56013,
    "lat": 43.62265,
    "lng": -94.1056,
    "state_id": "MN"
  },
  {
    "zip": 56014,
    "lat": 43.58261,
    "lng": -93.82579,
    "state_id": "MN"
  },
  {
    "zip": 56016,
    "lat": 43.76364,
    "lng": -93.34549,
    "state_id": "MN"
  },
  {
    "zip": 56017,
    "lat": 44.30705,
    "lng": -93.82016,
    "state_id": "MN"
  },
  {
    "zip": 56019,
    "lat": 44.11271,
    "lng": -94.89317,
    "state_id": "MN"
  },
  {
    "zip": 56020,
    "lat": 43.60817,
    "lng": -93.53817,
    "state_id": "MN"
  },
  {
    "zip": 56021,
    "lat": 44.28391,
    "lng": -94.31379,
    "state_id": "MN"
  },
  {
    "zip": 56022,
    "lat": 44.05354,
    "lng": -94.83936,
    "state_id": "MN"
  },
  {
    "zip": 56023,
    "lat": 43.77551,
    "lng": -94.01335,
    "state_id": "MN"
  },
  {
    "zip": 56024,
    "lat": 44.15134,
    "lng": -93.84783,
    "state_id": "MN"
  },
  {
    "zip": 56025,
    "lat": 43.75162,
    "lng": -93.91077,
    "state_id": "MN"
  },
  {
    "zip": 56026,
    "lat": 43.87551,
    "lng": -93.29274,
    "state_id": "MN"
  },
  {
    "zip": 56027,
    "lat": 43.51151,
    "lng": -94.11966,
    "state_id": "MN"
  },
  {
    "zip": 56028,
    "lat": 44.22507,
    "lng": -93.70937,
    "state_id": "MN"
  },
  {
    "zip": 56029,
    "lat": 43.52648,
    "lng": -93.5242,
    "state_id": "MN"
  },
  {
    "zip": 56031,
    "lat": 43.62216,
    "lng": -94.46744,
    "state_id": "MN"
  },
  {
    "zip": 56032,
    "lat": 43.76271,
    "lng": -93.55952,
    "state_id": "MN"
  },
  {
    "zip": 56033,
    "lat": 43.56008,
    "lng": -93.93073,
    "state_id": "MN"
  },
  {
    "zip": 56034,
    "lat": 44.03065,
    "lng": -94.18331,
    "state_id": "MN"
  },
  {
    "zip": 56035,
    "lat": 43.8215,
    "lng": -93.26853,
    "state_id": "MN"
  },
  {
    "zip": 56036,
    "lat": 43.55141,
    "lng": -93.2205,
    "state_id": "MN"
  },
  {
    "zip": 56037,
    "lat": 44.01864,
    "lng": -94.04886,
    "state_id": "MN"
  },
  {
    "zip": 56039,
    "lat": 43.66113,
    "lng": -94.32587,
    "state_id": "MN"
  },
  {
    "zip": 56041,
    "lat": 44.15269,
    "lng": -94.52895,
    "state_id": "MN"
  },
  {
    "zip": 56042,
    "lat": 43.80419,
    "lng": -93.48359,
    "state_id": "MN"
  },
  {
    "zip": 56043,
    "lat": 43.64831,
    "lng": -93.22324,
    "state_id": "MN"
  },
  {
    "zip": 56044,
    "lat": 44.56177,
    "lng": -93.93485,
    "state_id": "MN"
  },
  {
    "zip": 56045,
    "lat": 43.75943,
    "lng": -93.21294,
    "state_id": "MN"
  },
  {
    "zip": 56046,
    "lat": 43.9645,
    "lng": -93.27154,
    "state_id": "MN"
  },
  {
    "zip": 56047,
    "lat": 43.73228,
    "lng": -94.23364,
    "state_id": "MN"
  },
  {
    "zip": 56048,
    "lat": 44.09289,
    "lng": -93.71891,
    "state_id": "MN"
  },
  {
    "zip": 56050,
    "lat": 44.26488,
    "lng": -93.95316,
    "state_id": "MN"
  },
  {
    "zip": 56051,
    "lat": 43.53744,
    "lng": -93.71056,
    "state_id": "MN"
  },
  {
    "zip": 56052,
    "lat": 44.32011,
    "lng": -93.55363,
    "state_id": "MN"
  },
  {
    "zip": 56054,
    "lat": 44.43521,
    "lng": -94.3738,
    "state_id": "MN"
  },
  {
    "zip": 56055,
    "lat": 44.12821,
    "lng": -94.24573,
    "state_id": "MN"
  },
  {
    "zip": 56056,
    "lat": 44.07277,
    "lng": -94.57928,
    "state_id": "MN"
  },
  {
    "zip": 56057,
    "lat": 44.39454,
    "lng": -93.71549,
    "state_id": "MN"
  },
  {
    "zip": 56058,
    "lat": 44.44502,
    "lng": -93.91536,
    "state_id": "MN"
  },
  {
    "zip": 56060,
    "lat": 43.93273,
    "lng": -94.44547,
    "state_id": "MN"
  },
  {
    "zip": 56062,
    "lat": 44.04739,
    "lng": -94.41483,
    "state_id": "MN"
  },
  {
    "zip": 56063,
    "lat": 44.21914,
    "lng": -93.82015,
    "state_id": "MN"
  },
  {
    "zip": 56065,
    "lat": 43.93683,
    "lng": -93.91869,
    "state_id": "MN"
  },
  {
    "zip": 56068,
    "lat": 43.8645,
    "lng": -93.80599,
    "state_id": "MN"
  },
  {
    "zip": 56069,
    "lat": 44.41823,
    "lng": -93.55372,
    "state_id": "MN"
  },
  {
    "zip": 56071,
    "lat": 44.53702,
    "lng": -93.58185,
    "state_id": "MN"
  },
  {
    "zip": 56072,
    "lat": 43.89744,
    "lng": -93.51478,
    "state_id": "MN"
  },
  {
    "zip": 56073,
    "lat": 44.30581,
    "lng": -94.46737,
    "state_id": "MN"
  },
  {
    "zip": 56074,
    "lat": 44.32305,
    "lng": -94.20066,
    "state_id": "MN"
  },
  {
    "zip": 56075,
    "lat": 43.73492,
    "lng": -94.43667,
    "state_id": "MN"
  },
  {
    "zip": 56078,
    "lat": 44.00597,
    "lng": -93.76562,
    "state_id": "MN"
  },
  {
    "zip": 56080,
    "lat": 44.08034,
    "lng": -93.85446,
    "state_id": "MN"
  },
  {
    "zip": 56081,
    "lat": 43.99128,
    "lng": -94.62912,
    "state_id": "MN"
  },
  {
    "zip": 56082,
    "lat": 44.35226,
    "lng": -94.03246,
    "state_id": "MN"
  },
  {
    "zip": 56083,
    "lat": 44.20556,
    "lng": -95.13522,
    "state_id": "MN"
  },
  {
    "zip": 56085,
    "lat": 44.29333,
    "lng": -94.73619,
    "state_id": "MN"
  },
  {
    "zip": 56087,
    "lat": 44.24009,
    "lng": -94.97457,
    "state_id": "MN"
  },
  {
    "zip": 56088,
    "lat": 43.82613,
    "lng": -94.44562,
    "state_id": "MN"
  },
  {
    "zip": 56089,
    "lat": 43.56103,
    "lng": -93.43083,
    "state_id": "MN"
  },
  {
    "zip": 56090,
    "lat": 43.95747,
    "lng": -94.2398,
    "state_id": "MN"
  },
  {
    "zip": 56091,
    "lat": 43.92733,
    "lng": -93.68572,
    "state_id": "MN"
  },
  {
    "zip": 56093,
    "lat": 44.06461,
    "lng": -93.51471,
    "state_id": "MN"
  },
  {
    "zip": 56096,
    "lat": 44.24278,
    "lng": -93.59757,
    "state_id": "MN"
  },
  {
    "zip": 56097,
    "lat": 43.72404,
    "lng": -93.71629,
    "state_id": "MN"
  },
  {
    "zip": 56098,
    "lat": 43.77421,
    "lng": -94.18564,
    "state_id": "MN"
  },
  {
    "zip": 56101,
    "lat": 43.88314,
    "lng": -95.13734,
    "state_id": "MN"
  },
  {
    "zip": 56110,
    "lat": 43.62068,
    "lng": -95.94208,
    "state_id": "MN"
  },
  {
    "zip": 56111,
    "lat": 43.6391,
    "lng": -94.88195,
    "state_id": "MN"
  },
  {
    "zip": 56113,
    "lat": 44.39936,
    "lng": -96.15369,
    "state_id": "MN"
  },
  {
    "zip": 56114,
    "lat": 43.97713,
    "lng": -95.59835,
    "state_id": "MN"
  },
  {
    "zip": 56115,
    "lat": 44.21649,
    "lng": -95.89824,
    "state_id": "MN"
  },
  {
    "zip": 56116,
    "lat": 43.61497,
    "lng": -96.38155,
    "state_id": "MN"
  },
  {
    "zip": 56117,
    "lat": 43.52855,
    "lng": -95.68531,
    "state_id": "MN"
  },
  {
    "zip": 56118,
    "lat": 43.93821,
    "lng": -95.03209,
    "state_id": "MN"
  },
  {
    "zip": 56119,
    "lat": 43.71168,
    "lng": -95.46887,
    "state_id": "MN"
  },
  {
    "zip": 56120,
    "lat": 43.96231,
    "lng": -94.7922,
    "state_id": "MN"
  },
  {
    "zip": 56121,
    "lat": 43.53771,
    "lng": -94.6113,
    "state_id": "MN"
  },
  {
    "zip": 56122,
    "lat": 43.89664,
    "lng": -95.95325,
    "state_id": "MN"
  },
  {
    "zip": 56123,
    "lat": 44.08554,
    "lng": -95.59614,
    "state_id": "MN"
  },
  {
    "zip": 56125,
    "lat": 44.05821,
    "lng": -95.55287,
    "state_id": "MN"
  },
  {
    "zip": 56127,
    "lat": 43.54764,
    "lng": -94.77441,
    "state_id": "MN"
  },
  {
    "zip": 56128,
    "lat": 43.88151,
    "lng": -96.1156,
    "state_id": "MN"
  },
  {
    "zip": 56129,
    "lat": 43.5282,
    "lng": -96.04059,
    "state_id": "MN"
  },
  {
    "zip": 56131,
    "lat": 43.86002,
    "lng": -95.57781,
    "state_id": "MN"
  },
  {
    "zip": 56132,
    "lat": 44.20559,
    "lng": -95.75858,
    "state_id": "MN"
  },
  {
    "zip": 56134,
    "lat": 43.7957,
    "lng": -96.23845,
    "state_id": "MN"
  },
  {
    "zip": 56136,
    "lat": 44.50245,
    "lng": -96.3969,
    "state_id": "MN"
  },
  {
    "zip": 56137,
    "lat": 43.83488,
    "lng": -95.32681,
    "state_id": "MN"
  },
  {
    "zip": 56138,
    "lat": 43.5338,
    "lng": -96.38767,
    "state_id": "MN"
  },
  {
    "zip": 56139,
    "lat": 44.10295,
    "lng": -96.17727,
    "state_id": "MN"
  },
  {
    "zip": 56140,
    "lat": 43.90898,
    "lng": -96.36802,
    "state_id": "MN"
  },
  {
    "zip": 56141,
    "lat": 43.87881,
    "lng": -95.79022,
    "state_id": "MN"
  },
  {
    "zip": 56142,
    "lat": 44.47157,
    "lng": -96.23256,
    "state_id": "MN"
  },
  {
    "zip": 56143,
    "lat": 43.632,
    "lng": -95.00409,
    "state_id": "MN"
  },
  {
    "zip": 56144,
    "lat": 43.85183,
    "lng": -96.39177,
    "state_id": "MN"
  },
  {
    "zip": 56145,
    "lat": 44.05473,
    "lng": -95.17571,
    "state_id": "MN"
  },
  {
    "zip": 56146,
    "lat": 43.58006,
    "lng": -96.10343,
    "state_id": "MN"
  },
  {
    "zip": 56147,
    "lat": 43.75937,
    "lng": -96.0616,
    "state_id": "MN"
  },
  {
    "zip": 56149,
    "lat": 44.30096,
    "lng": -96.30744,
    "state_id": "MN"
  },
  {
    "zip": 56150,
    "lat": 43.63559,
    "lng": -95.21108,
    "state_id": "MN"
  },
  {
    "zip": 56151,
    "lat": 44.0324,
    "lng": -95.92804,
    "state_id": "MN"
  },
  {
    "zip": 56152,
    "lat": 44.23054,
    "lng": -95.27345,
    "state_id": "MN"
  },
  {
    "zip": 56153,
    "lat": 43.8331,
    "lng": -96.01097,
    "state_id": "MN"
  },
  {
    "zip": 56155,
    "lat": 43.7685,
    "lng": -95.94072,
    "state_id": "MN"
  },
  {
    "zip": 56156,
    "lat": 43.66279,
    "lng": -96.22599,
    "state_id": "MN"
  },
  {
    "zip": 56157,
    "lat": 44.39638,
    "lng": -95.94815,
    "state_id": "MN"
  },
  {
    "zip": 56158,
    "lat": 43.63905,
    "lng": -96.07365,
    "state_id": "MN"
  },
  {
    "zip": 56159,
    "lat": 43.94162,
    "lng": -94.93219,
    "state_id": "MN"
  },
  {
    "zip": 56160,
    "lat": 43.83478,
    "lng": -94.80794,
    "state_id": "MN"
  },
  {
    "zip": 56161,
    "lat": 43.70866,
    "lng": -95.32354,
    "state_id": "MN"
  },
  {
    "zip": 56162,
    "lat": 43.84171,
    "lng": -94.66875,
    "state_id": "MN"
  },
  {
    "zip": 56164,
    "lat": 44.05663,
    "lng": -96.33918,
    "state_id": "MN"
  },
  {
    "zip": 56165,
    "lat": 43.72466,
    "lng": -95.71049,
    "state_id": "MN"
  },
  {
    "zip": 56166,
    "lat": 44.19999,
    "lng": -95.37236,
    "state_id": "MN"
  },
  {
    "zip": 56167,
    "lat": 43.55154,
    "lng": -95.40261,
    "state_id": "MN"
  },
  {
    "zip": 56168,
    "lat": 43.61749,
    "lng": -95.80619,
    "state_id": "MN"
  },
  {
    "zip": 56169,
    "lat": 44.33383,
    "lng": -96.00494,
    "state_id": "MN"
  },
  {
    "zip": 56170,
    "lat": 44.16825,
    "lng": -96.08704,
    "state_id": "MN"
  },
  {
    "zip": 56171,
    "lat": 43.66765,
    "lng": -94.7652,
    "state_id": "MN"
  },
  {
    "zip": 56172,
    "lat": 44.00895,
    "lng": -95.7708,
    "state_id": "MN"
  },
  {
    "zip": 56173,
    "lat": 43.52478,
    "lng": -96.24592,
    "state_id": "MN"
  },
  {
    "zip": 56174,
    "lat": 44.01598,
    "lng": -95.30553,
    "state_id": "MN"
  },
  {
    "zip": 56175,
    "lat": 44.25519,
    "lng": -95.63527,
    "state_id": "MN"
  },
  {
    "zip": 56176,
    "lat": 43.78443,
    "lng": -94.724,
    "state_id": "MN"
  },
  {
    "zip": 56178,
    "lat": 44.28485,
    "lng": -96.1312,
    "state_id": "MN"
  },
  {
    "zip": 56180,
    "lat": 44.24594,
    "lng": -95.46528,
    "state_id": "MN"
  },
  {
    "zip": 56181,
    "lat": 43.67983,
    "lng": -94.61734,
    "state_id": "MN"
  },
  {
    "zip": 56183,
    "lat": 44.0294,
    "lng": -95.42781,
    "state_id": "MN"
  },
  {
    "zip": 56185,
    "lat": 43.79203,
    "lng": -95.82543,
    "state_id": "MN"
  },
  {
    "zip": 56186,
    "lat": 44.03033,
    "lng": -96.08164,
    "state_id": "MN"
  },
  {
    "zip": 56187,
    "lat": 43.62385,
    "lng": -95.58286,
    "state_id": "MN"
  },
  {
    "zip": 56201,
    "lat": 45.10152,
    "lng": -95.0419,
    "state_id": "MN"
  },
  {
    "zip": 56207,
    "lat": 45.49685,
    "lng": -96.06945,
    "state_id": "MN"
  },
  {
    "zip": 56208,
    "lat": 45.2425,
    "lng": -96.0043,
    "state_id": "MN"
  },
  {
    "zip": 56209,
    "lat": 45.12422,
    "lng": -94.80081,
    "state_id": "MN"
  },
  {
    "zip": 56210,
    "lat": 45.55988,
    "lng": -96.56527,
    "state_id": "MN"
  },
  {
    "zip": 56211,
    "lat": 45.57994,
    "lng": -96.69185,
    "state_id": "MN"
  },
  {
    "zip": 56212,
    "lat": 45.13696,
    "lng": -96.33196,
    "state_id": "MN"
  },
  {
    "zip": 56214,
    "lat": 44.60675,
    "lng": -95.32622,
    "state_id": "MN"
  },
  {
    "zip": 56215,
    "lat": 45.31146,
    "lng": -95.57235,
    "state_id": "MN"
  },
  {
    "zip": 56216,
    "lat": 44.93658,
    "lng": -95.05655,
    "state_id": "MN"
  },
  {
    "zip": 56218,
    "lat": 44.82443,
    "lng": -95.9456,
    "state_id": "MN"
  },
  {
    "zip": 56219,
    "lat": 45.59978,
    "lng": -96.81994,
    "state_id": "MN"
  },
  {
    "zip": 56220,
    "lat": 44.73183,
    "lng": -96.29416,
    "state_id": "MN"
  },
  {
    "zip": 56221,
    "lat": 45.55512,
    "lng": -96.19635,
    "state_id": "MN"
  },
  {
    "zip": 56222,
    "lat": 44.98414,
    "lng": -95.36161,
    "state_id": "MN"
  },
  {
    "zip": 56223,
    "lat": 44.76201,
    "lng": -95.81929,
    "state_id": "MN"
  },
  {
    "zip": 56224,
    "lat": 44.37437,
    "lng": -95.05108,
    "state_id": "MN"
  },
  {
    "zip": 56225,
    "lat": 45.45313,
    "lng": -96.4369,
    "state_id": "MN"
  },
  {
    "zip": 56226,
    "lat": 45.41622,
    "lng": -95.67238,
    "state_id": "MN"
  },
  {
    "zip": 56227,
    "lat": 45.29278,
    "lng": -96.16591,
    "state_id": "MN"
  },
  {
    "zip": 56228,
    "lat": 44.93688,
    "lng": -94.67707,
    "state_id": "MN"
  },
  {
    "zip": 56229,
    "lat": 44.59331,
    "lng": -95.71817,
    "state_id": "MN"
  },
  {
    "zip": 56230,
    "lat": 44.79299,
    "lng": -95.0924,
    "state_id": "MN"
  },
  {
    "zip": 56231,
    "lat": 45.28237,
    "lng": -95.7614,
    "state_id": "MN"
  },
  {
    "zip": 56232,
    "lat": 44.93054,
    "lng": -96.03834,
    "state_id": "MN"
  },
  {
    "zip": 56235,
    "lat": 45.70425,
    "lng": -96.01918,
    "state_id": "MN"
  },
  {
    "zip": 56236,
    "lat": 45.65973,
    "lng": -96.41057,
    "state_id": "MN"
  },
  {
    "zip": 56237,
    "lat": 44.61646,
    "lng": -95.4325,
    "state_id": "MN"
  },
  {
    "zip": 56239,
    "lat": 44.50421,
    "lng": -95.91039,
    "state_id": "MN"
  },
  {
    "zip": 56240,
    "lat": 45.56404,
    "lng": -96.4754,
    "state_id": "MN"
  },
  {
    "zip": 56241,
    "lat": 44.79694,
    "lng": -95.57915,
    "state_id": "MN"
  },
  {
    "zip": 56243,
    "lat": 45.16765,
    "lng": -94.68214,
    "state_id": "MN"
  },
  {
    "zip": 56244,
    "lat": 45.48197,
    "lng": -95.78307,
    "state_id": "MN"
  },
  {
    "zip": 56245,
    "lat": 44.68438,
    "lng": -95.68112,
    "state_id": "MN"
  },
  {
    "zip": 56248,
    "lat": 45.79568,
    "lng": -96.1369,
    "state_id": "MN"
  },
  {
    "zip": 56249,
    "lat": 45.31552,
    "lng": -95.89866,
    "state_id": "MN"
  },
  {
    "zip": 56251,
    "lat": 45.14248,
    "lng": -94.91824,
    "state_id": "MN"
  },
  {
    "zip": 56252,
    "lat": 45.18108,
    "lng": -95.30209,
    "state_id": "MN"
  },
  {
    "zip": 56253,
    "lat": 44.96362,
    "lng": -94.87981,
    "state_id": "MN"
  },
  {
    "zip": 56255,
    "lat": 44.39598,
    "lng": -95.41863,
    "state_id": "MN"
  },
  {
    "zip": 56256,
    "lat": 45.02506,
    "lng": -96.19793,
    "state_id": "MN"
  },
  {
    "zip": 56257,
    "lat": 44.97103,
    "lng": -96.39811,
    "state_id": "MN"
  },
  {
    "zip": 56258,
    "lat": 44.44389,
    "lng": -95.76046,
    "state_id": "MN"
  },
  {
    "zip": 56260,
    "lat": 44.96579,
    "lng": -95.48291,
    "state_id": "MN"
  },
  {
    "zip": 56262,
    "lat": 45.11816,
    "lng": -95.86472,
    "state_id": "MN"
  },
  {
    "zip": 56263,
    "lat": 44.41485,
    "lng": -95.54378,
    "state_id": "MN"
  },
  {
    "zip": 56264,
    "lat": 44.57051,
    "lng": -95.9794,
    "state_id": "MN"
  },
  {
    "zip": 56265,
    "lat": 45.00486,
    "lng": -95.69626,
    "state_id": "MN"
  },
  {
    "zip": 56266,
    "lat": 44.42396,
    "lng": -94.91732,
    "state_id": "MN"
  },
  {
    "zip": 56267,
    "lat": 45.58453,
    "lng": -95.93008,
    "state_id": "MN"
  },
  {
    "zip": 56270,
    "lat": 44.56746,
    "lng": -94.9914,
    "state_id": "MN"
  },
  {
    "zip": 56271,
    "lat": 45.2447,
    "lng": -95.41168,
    "state_id": "MN"
  },
  {
    "zip": 56273,
    "lat": 45.32132,
    "lng": -94.96514,
    "state_id": "MN"
  },
  {
    "zip": 56274,
    "lat": 45.90222,
    "lng": -96.24572,
    "state_id": "MN"
  },
  {
    "zip": 56276,
    "lat": 45.24253,
    "lng": -96.31464,
    "state_id": "MN"
  },
  {
    "zip": 56277,
    "lat": 44.76534,
    "lng": -94.99499,
    "state_id": "MN"
  },
  {
    "zip": 56278,
    "lat": 45.34709,
    "lng": -96.37797,
    "state_id": "MN"
  },
  {
    "zip": 56279,
    "lat": 45.21742,
    "lng": -95.17399,
    "state_id": "MN"
  },
  {
    "zip": 56280,
    "lat": 44.63289,
    "lng": -96.16847,
    "state_id": "MN"
  },
  {
    "zip": 56281,
    "lat": 44.95326,
    "lng": -95.16929,
    "state_id": "MN"
  },
  {
    "zip": 56282,
    "lat": 45.03884,
    "lng": -95.22591,
    "state_id": "MN"
  },
  {
    "zip": 56283,
    "lat": 44.53276,
    "lng": -95.14209,
    "state_id": "MN"
  },
  {
    "zip": 56284,
    "lat": 44.78469,
    "lng": -95.20234,
    "state_id": "MN"
  },
  {
    "zip": 56285,
    "lat": 44.78885,
    "lng": -95.34902,
    "state_id": "MN"
  },
  {
    "zip": 56287,
    "lat": 44.47544,
    "lng": -95.32623,
    "state_id": "MN"
  },
  {
    "zip": 56288,
    "lat": 45.2418,
    "lng": -94.95244,
    "state_id": "MN"
  },
  {
    "zip": 56289,
    "lat": 45.33175,
    "lng": -95.22657,
    "state_id": "MN"
  },
  {
    "zip": 56291,
    "lat": 44.62353,
    "lng": -96.06835,
    "state_id": "MN"
  },
  {
    "zip": 56292,
    "lat": 44.49817,
    "lng": -95.45715,
    "state_id": "MN"
  },
  {
    "zip": 56293,
    "lat": 44.4169,
    "lng": -95.26531,
    "state_id": "MN"
  },
  {
    "zip": 56294,
    "lat": 44.31485,
    "lng": -95.21306,
    "state_id": "MN"
  },
  {
    "zip": 56295,
    "lat": 45.04119,
    "lng": -95.83406,
    "state_id": "MN"
  },
  {
    "zip": 56296,
    "lat": 45.82866,
    "lng": -96.46746,
    "state_id": "MN"
  },
  {
    "zip": 56297,
    "lat": 44.63679,
    "lng": -95.54302,
    "state_id": "MN"
  },
  {
    "zip": 56301,
    "lat": 45.48813,
    "lng": -94.24229,
    "state_id": "MN"
  },
  {
    "zip": 56303,
    "lat": 45.57441,
    "lng": -94.21155,
    "state_id": "MN"
  },
  {
    "zip": 56304,
    "lat": 45.52536,
    "lng": -94.05284,
    "state_id": "MN"
  },
  {
    "zip": 56307,
    "lat": 45.62799,
    "lng": -94.58872,
    "state_id": "MN"
  },
  {
    "zip": 56308,
    "lat": 45.87903,
    "lng": -95.38672,
    "state_id": "MN"
  },
  {
    "zip": 56309,
    "lat": 46.08118,
    "lng": -95.80641,
    "state_id": "MN"
  },
  {
    "zip": 56310,
    "lat": 45.63407,
    "lng": -94.44332,
    "state_id": "MN"
  },
  {
    "zip": 56311,
    "lat": 45.89728,
    "lng": -95.89764,
    "state_id": "MN"
  },
  {
    "zip": 56312,
    "lat": 45.4635,
    "lng": -94.95258,
    "state_id": "MN"
  },
  {
    "zip": 56313,
    "lat": 45.78397,
    "lng": -93.55308,
    "state_id": "MN"
  },
  {
    "zip": 56314,
    "lat": 45.81167,
    "lng": -94.43311,
    "state_id": "MN"
  },
  {
    "zip": 56315,
    "lat": 45.98475,
    "lng": -95.58899,
    "state_id": "MN"
  },
  {
    "zip": 56316,
    "lat": 45.49549,
    "lng": -95.15268,
    "state_id": "MN"
  },
  {
    "zip": 56318,
    "lat": 45.85441,
    "lng": -94.67228,
    "state_id": "MN"
  },
  {
    "zip": 56319,
    "lat": 46.00199,
    "lng": -95.22751,
    "state_id": "MN"
  },
  {
    "zip": 56320,
    "lat": 45.4648,
    "lng": -94.41873,
    "state_id": "MN"
  },
  {
    "zip": 56321,
    "lat": 45.58047,
    "lng": -94.39312,
    "state_id": "MN"
  },
  {
    "zip": 56323,
    "lat": 45.6322,
    "lng": -95.72517,
    "state_id": "MN"
  },
  {
    "zip": 56324,
    "lat": 46.16608,
    "lng": -95.90514,
    "state_id": "MN"
  },
  {
    "zip": 56325,
    "lat": 45.56305,
    "lng": -94.94629,
    "state_id": "MN"
  },
  {
    "zip": 56326,
    "lat": 46.02287,
    "lng": -95.68728,
    "state_id": "MN"
  },
  {
    "zip": 56327,
    "lat": 45.75739,
    "lng": -95.61273,
    "state_id": "MN"
  },
  {
    "zip": 56328,
    "lat": 45.9547,
    "lng": -94.51757,
    "state_id": "MN"
  },
  {
    "zip": 56329,
    "lat": 45.70875,
    "lng": -93.90575,
    "state_id": "MN"
  },
  {
    "zip": 56330,
    "lat": 45.7497,
    "lng": -93.74923,
    "state_id": "MN"
  },
  {
    "zip": 56331,
    "lat": 45.67731,
    "lng": -94.67654,
    "state_id": "MN"
  },
  {
    "zip": 56332,
    "lat": 45.96507,
    "lng": -95.5058,
    "state_id": "MN"
  },
  {
    "zip": 56334,
    "lat": 45.58335,
    "lng": -95.34548,
    "state_id": "MN"
  },
  {
    "zip": 56335,
    "lat": 45.60045,
    "lng": -94.86175,
    "state_id": "MN"
  },
  {
    "zip": 56336,
    "lat": 45.82325,
    "lng": -94.76155,
    "state_id": "MN"
  },
  {
    "zip": 56338,
    "lat": 46.00872,
    "lng": -93.87842,
    "state_id": "MN"
  },
  {
    "zip": 56339,
    "lat": 45.82768,
    "lng": -95.8169,
    "state_id": "MN"
  },
  {
    "zip": 56340,
    "lat": 45.75601,
    "lng": -94.44614,
    "state_id": "MN"
  },
  {
    "zip": 56342,
    "lat": 46.18132,
    "lng": -93.40806,
    "state_id": "MN"
  },
  {
    "zip": 56343,
    "lat": 45.7887,
    "lng": -95.69968,
    "state_id": "MN"
  },
  {
    "zip": 56345,
    "lat": 45.98881,
    "lng": -94.37486,
    "state_id": "MN"
  },
  {
    "zip": 56347,
    "lat": 45.96752,
    "lng": -94.87195,
    "state_id": "MN"
  },
  {
    "zip": 56349,
    "lat": 45.72518,
    "lng": -95.51186,
    "state_id": "MN"
  },
  {
    "zip": 56350,
    "lat": 46.31575,
    "lng": -93.23493,
    "state_id": "MN"
  },
  {
    "zip": 56352,
    "lat": 45.65043,
    "lng": -94.80221,
    "state_id": "MN"
  },
  {
    "zip": 56353,
    "lat": 45.79738,
    "lng": -93.62818,
    "state_id": "MN"
  },
  {
    "zip": 56354,
    "lat": 46.06295,
    "lng": -95.26466,
    "state_id": "MN"
  },
  {
    "zip": 56355,
    "lat": 45.93999,
    "lng": -95.23556,
    "state_id": "MN"
  },
  {
    "zip": 56356,
    "lat": 45.629,
    "lng": -94.75336,
    "state_id": "MN"
  },
  {
    "zip": 56357,
    "lat": 45.69729,
    "lng": -93.80078,
    "state_id": "MN"
  },
  {
    "zip": 56358,
    "lat": 45.84355,
    "lng": -93.45215,
    "state_id": "MN"
  },
  {
    "zip": 56359,
    "lat": 46.08377,
    "lng": -93.66374,
    "state_id": "MN"
  },
  {
    "zip": 56360,
    "lat": 45.87375,
    "lng": -95.12725,
    "state_id": "MN"
  },
  {
    "zip": 56361,
    "lat": 46.16024,
    "lng": -95.33724,
    "state_id": "MN"
  },
  {
    "zip": 56362,
    "lat": 45.40328,
    "lng": -94.7142,
    "state_id": "MN"
  },
  {
    "zip": 56363,
    "lat": 45.69668,
    "lng": -93.64982,
    "state_id": "MN"
  },
  {
    "zip": 56364,
    "lat": 46.01121,
    "lng": -94.0656,
    "state_id": "MN"
  },
  {
    "zip": 56367,
    "lat": 45.76141,
    "lng": -94.17008,
    "state_id": "MN"
  },
  {
    "zip": 56368,
    "lat": 45.4521,
    "lng": -94.53893,
    "state_id": "MN"
  },
  {
    "zip": 56369,
    "lat": 45.46705,
    "lng": -94.34535,
    "state_id": "MN"
  },
  {
    "zip": 56371,
    "lat": 45.43316,
    "lng": -94.63661,
    "state_id": "MN"
  },
  {
    "zip": 56373,
    "lat": 45.85573,
    "lng": -94.23654,
    "state_id": "MN"
  },
  {
    "zip": 56374,
    "lat": 45.60711,
    "lng": -94.33586,
    "state_id": "MN"
  },
  {
    "zip": 56375,
    "lat": 45.70091,
    "lng": -94.27427,
    "state_id": "MN"
  },
  {
    "zip": 56376,
    "lat": 45.50562,
    "lng": -94.67959,
    "state_id": "MN"
  },
  {
    "zip": 56377,
    "lat": 45.6414,
    "lng": -94.22927,
    "state_id": "MN"
  },
  {
    "zip": 56378,
    "lat": 45.72274,
    "lng": -94.98834,
    "state_id": "MN"
  },
  {
    "zip": 56379,
    "lat": 45.63993,
    "lng": -94.08445,
    "state_id": "MN"
  },
  {
    "zip": 56381,
    "lat": 45.55252,
    "lng": -95.54989,
    "state_id": "MN"
  },
  {
    "zip": 56382,
    "lat": 45.91891,
    "lng": -94.61092,
    "state_id": "MN"
  },
  {
    "zip": 56384,
    "lat": 45.80948,
    "lng": -94.56718,
    "state_id": "MN"
  },
  {
    "zip": 56385,
    "lat": 45.70512,
    "lng": -95.22287,
    "state_id": "MN"
  },
  {
    "zip": 56386,
    "lat": 46.11173,
    "lng": -93.52019,
    "state_id": "MN"
  },
  {
    "zip": 56387,
    "lat": 45.53289,
    "lng": -94.23969,
    "state_id": "MN"
  },
  {
    "zip": 56389,
    "lat": 45.79887,
    "lng": -95.08922,
    "state_id": "MN"
  },
  {
    "zip": 56401,
    "lat": 46.32011,
    "lng": -94.11503,
    "state_id": "MN"
  },
  {
    "zip": 56425,
    "lat": 46.34286,
    "lng": -94.28219,
    "state_id": "MN"
  },
  {
    "zip": 56431,
    "lat": 46.48197,
    "lng": -93.6427,
    "state_id": "MN"
  },
  {
    "zip": 56433,
    "lat": 46.97333,
    "lng": -94.69687,
    "state_id": "MN"
  },
  {
    "zip": 56434,
    "lat": 46.33853,
    "lng": -94.93461,
    "state_id": "MN"
  },
  {
    "zip": 56435,
    "lat": 46.80838,
    "lng": -94.53793,
    "state_id": "MN"
  },
  {
    "zip": 56436,
    "lat": 47.16187,
    "lng": -94.6888,
    "state_id": "MN"
  },
  {
    "zip": 56437,
    "lat": 46.24891,
    "lng": -95.05896,
    "state_id": "MN"
  },
  {
    "zip": 56438,
    "lat": 46.13804,
    "lng": -94.80893,
    "state_id": "MN"
  },
  {
    "zip": 56440,
    "lat": 46.14557,
    "lng": -94.95755,
    "state_id": "MN"
  },
  {
    "zip": 56441,
    "lat": 46.5702,
    "lng": -93.98312,
    "state_id": "MN"
  },
  {
    "zip": 56442,
    "lat": 46.67502,
    "lng": -94.10179,
    "state_id": "MN"
  },
  {
    "zip": 56443,
    "lat": 46.17895,
    "lng": -94.61822,
    "state_id": "MN"
  },
  {
    "zip": 56444,
    "lat": 46.41916,
    "lng": -93.8748,
    "state_id": "MN"
  },
  {
    "zip": 56446,
    "lat": 46.12551,
    "lng": -95.08925,
    "state_id": "MN"
  },
  {
    "zip": 56447,
    "lat": 46.73516,
    "lng": -93.91683,
    "state_id": "MN"
  },
  {
    "zip": 56448,
    "lat": 46.76812,
    "lng": -94.09042,
    "state_id": "MN"
  },
  {
    "zip": 56449,
    "lat": 46.1758,
    "lng": -94.27166,
    "state_id": "MN"
  },
  {
    "zip": 56450,
    "lat": 46.23147,
    "lng": -93.83415,
    "state_id": "MN"
  },
  {
    "zip": 56452,
    "lat": 46.94724,
    "lng": -94.46365,
    "state_id": "MN"
  },
  {
    "zip": 56453,
    "lat": 46.31417,
    "lng": -95.16055,
    "state_id": "MN"
  },
  {
    "zip": 56455,
    "lat": 46.44873,
    "lng": -94.00749,
    "state_id": "MN"
  },
  {
    "zip": 56456,
    "lat": 46.63778,
    "lng": -94.3373,
    "state_id": "MN"
  },
  {
    "zip": 56458,
    "lat": 47.19912,
    "lng": -94.98969,
    "state_id": "MN"
  },
  {
    "zip": 56461,
    "lat": 47.23613,
    "lng": -94.85164,
    "state_id": "MN"
  },
  {
    "zip": 56464,
    "lat": 46.7633,
    "lng": -95.10477,
    "state_id": "MN"
  },
  {
    "zip": 56465,
    "lat": 46.53526,
    "lng": -94.11216,
    "state_id": "MN"
  },
  {
    "zip": 56466,
    "lat": 46.40923,
    "lng": -94.63597,
    "state_id": "MN"
  },
  {
    "zip": 56467,
    "lat": 46.97944,
    "lng": -94.84325,
    "state_id": "MN"
  },
  {
    "zip": 56468,
    "lat": 46.49294,
    "lng": -94.29767,
    "state_id": "MN"
  },
  {
    "zip": 56469,
    "lat": 46.77909,
    "lng": -93.54598,
    "state_id": "MN"
  },
  {
    "zip": 56470,
    "lat": 47.02292,
    "lng": -95.09814,
    "state_id": "MN"
  },
  {
    "zip": 56472,
    "lat": 46.57402,
    "lng": -94.35565,
    "state_id": "MN"
  },
  {
    "zip": 56473,
    "lat": 46.36069,
    "lng": -94.48958,
    "state_id": "MN"
  },
  {
    "zip": 56474,
    "lat": 46.71985,
    "lng": -94.40297,
    "state_id": "MN"
  },
  {
    "zip": 56475,
    "lat": 46.08319,
    "lng": -94.54153,
    "state_id": "MN"
  },
  {
    "zip": 56477,
    "lat": 46.64357,
    "lng": -95.00667,
    "state_id": "MN"
  },
  {
    "zip": 56479,
    "lat": 46.44004,
    "lng": -94.79023,
    "state_id": "MN"
  },
  {
    "zip": 56481,
    "lat": 46.47357,
    "lng": -94.93251,
    "state_id": "MN"
  },
  {
    "zip": 56482,
    "lat": 46.46532,
    "lng": -95.14279,
    "state_id": "MN"
  },
  {
    "zip": 56484,
    "lat": 47.0991,
    "lng": -94.4677,
    "state_id": "MN"
  },
  {
    "zip": 56501,
    "lat": 46.84061,
    "lng": -95.80915,
    "state_id": "MN"
  },
  {
    "zip": 56510,
    "lat": 47.36034,
    "lng": -96.5254,
    "state_id": "MN"
  },
  {
    "zip": 56511,
    "lat": 46.86146,
    "lng": -96.00127,
    "state_id": "MN"
  },
  {
    "zip": 56514,
    "lat": 46.6481,
    "lng": -96.43137,
    "state_id": "MN"
  },
  {
    "zip": 56515,
    "lat": 46.28824,
    "lng": -95.70826,
    "state_id": "MN"
  },
  {
    "zip": 56516,
    "lat": 47.44188,
    "lng": -95.97604,
    "state_id": "MN"
  },
  {
    "zip": 56517,
    "lat": 47.56033,
    "lng": -96.54963,
    "state_id": "MN"
  },
  {
    "zip": 56518,
    "lat": 46.48906,
    "lng": -95.24512,
    "state_id": "MN"
  },
  {
    "zip": 56519,
    "lat": 47.16305,
    "lng": -96.48887,
    "state_id": "MN"
  },
  {
    "zip": 56520,
    "lat": 46.28543,
    "lng": -96.50478,
    "state_id": "MN"
  },
  {
    "zip": 56521,
    "lat": 46.99834,
    "lng": -95.89688,
    "state_id": "MN"
  },
  {
    "zip": 56522,
    "lat": 46.13638,
    "lng": -96.38819,
    "state_id": "MN"
  },
  {
    "zip": 56523,
    "lat": 47.65026,
    "lng": -96.81344,
    "state_id": "MN"
  },
  {
    "zip": 56524,
    "lat": 46.21378,
    "lng": -95.61021,
    "state_id": "MN"
  },
  {
    "zip": 56525,
    "lat": 46.66635,
    "lng": -96.75003,
    "state_id": "MN"
  },
  {
    "zip": 56527,
    "lat": 46.37872,
    "lng": -95.31722,
    "state_id": "MN"
  },
  {
    "zip": 56528,
    "lat": 46.52869,
    "lng": -95.81167,
    "state_id": "MN"
  },
  {
    "zip": 56529,
    "lat": 46.87655,
    "lng": -96.6895,
    "state_id": "MN"
  },
  {
    "zip": 56531,
    "lat": 45.98789,
    "lng": -95.98588,
    "state_id": "MN"
  },
  {
    "zip": 56533,
    "lat": 46.40948,
    "lng": -96.1567,
    "state_id": "MN"
  },
  {
    "zip": 56534,
    "lat": 46.46113,
    "lng": -96.01096,
    "state_id": "MN"
  },
  {
    "zip": 56535,
    "lat": 47.67935,
    "lng": -96.03913,
    "state_id": "MN"
  },
  {
    "zip": 56536,
    "lat": 47.04718,
    "lng": -96.52556,
    "state_id": "MN"
  },
  {
    "zip": 56537,
    "lat": 46.27602,
    "lng": -96.0904,
    "state_id": "MN"
  },
  {
    "zip": 56540,
    "lat": 47.55101,
    "lng": -96.27991,
    "state_id": "MN"
  },
  {
    "zip": 56541,
    "lat": 47.1588,
    "lng": -96.13092,
    "state_id": "MN"
  },
  {
    "zip": 56542,
    "lat": 47.55566,
    "lng": -95.73003,
    "state_id": "MN"
  },
  {
    "zip": 56543,
    "lat": 46.28703,
    "lng": -96.33833,
    "state_id": "MN"
  },
  {
    "zip": 56544,
    "lat": 46.75762,
    "lng": -95.59377,
    "state_id": "MN"
  },
  {
    "zip": 56545,
    "lat": 47.38576,
    "lng": -96.23907,
    "state_id": "MN"
  },
  {
    "zip": 56546,
    "lat": 47.10368,
    "lng": -96.72757,
    "state_id": "MN"
  },
  {
    "zip": 56547,
    "lat": 46.8896,
    "lng": -96.54951,
    "state_id": "MN"
  },
  {
    "zip": 56548,
    "lat": 47.37051,
    "lng": -96.7535,
    "state_id": "MN"
  },
  {
    "zip": 56549,
    "lat": 46.84847,
    "lng": -96.31024,
    "state_id": "MN"
  },
  {
    "zip": 56550,
    "lat": 47.27135,
    "lng": -96.73674,
    "state_id": "MN"
  },
  {
    "zip": 56551,
    "lat": 46.31819,
    "lng": -95.44552,
    "state_id": "MN"
  },
  {
    "zip": 56552,
    "lat": 46.98809,
    "lng": -96.21221,
    "state_id": "MN"
  },
  {
    "zip": 56553,
    "lat": 46.42885,
    "lng": -96.61825,
    "state_id": "MN"
  },
  {
    "zip": 56554,
    "lat": 46.88346,
    "lng": -96.11475,
    "state_id": "MN"
  },
  {
    "zip": 56556,
    "lat": 47.66337,
    "lng": -95.88328,
    "state_id": "MN"
  },
  {
    "zip": 56557,
    "lat": 47.31672,
    "lng": -95.80367,
    "state_id": "MN"
  },
  {
    "zip": 56560,
    "lat": 46.84226,
    "lng": -96.73561,
    "state_id": "MN"
  },
  {
    "zip": 56565,
    "lat": 46.04962,
    "lng": -96.29764,
    "state_id": "MN"
  },
  {
    "zip": 56566,
    "lat": 47.24014,
    "lng": -95.61125,
    "state_id": "MN"
  },
  {
    "zip": 56567,
    "lat": 46.54688,
    "lng": -95.36728,
    "state_id": "MN"
  },
  {
    "zip": 56568,
    "lat": 47.53378,
    "lng": -96.76867,
    "state_id": "MN"
  },
  {
    "zip": 56569,
    "lat": 47.08056,
    "lng": -95.85769,
    "state_id": "MN"
  },
  {
    "zip": 56570,
    "lat": 46.90877,
    "lng": -95.35587,
    "state_id": "MN"
  },
  {
    "zip": 56571,
    "lat": 46.43786,
    "lng": -95.54191,
    "state_id": "MN"
  },
  {
    "zip": 56572,
    "lat": 46.60306,
    "lng": -96.08207,
    "state_id": "MN"
  },
  {
    "zip": 56573,
    "lat": 46.61556,
    "lng": -95.55123,
    "state_id": "MN"
  },
  {
    "zip": 56574,
    "lat": 47.19517,
    "lng": -96.71298,
    "state_id": "MN"
  },
  {
    "zip": 56575,
    "lat": 47.04218,
    "lng": -95.43878,
    "state_id": "MN"
  },
  {
    "zip": 56576,
    "lat": 46.46682,
    "lng": -95.6974,
    "state_id": "MN"
  },
  {
    "zip": 56577,
    "lat": 46.97357,
    "lng": -95.80329,
    "state_id": "MN"
  },
  {
    "zip": 56578,
    "lat": 46.94039,
    "lng": -95.65265,
    "state_id": "MN"
  },
  {
    "zip": 56579,
    "lat": 46.46993,
    "lng": -96.34343,
    "state_id": "MN"
  },
  {
    "zip": 56580,
    "lat": 46.73588,
    "lng": -96.60707,
    "state_id": "MN"
  },
  {
    "zip": 56581,
    "lat": 47.4608,
    "lng": -96.76911,
    "state_id": "MN"
  },
  {
    "zip": 56583,
    "lat": 46.01056,
    "lng": -96.41106,
    "state_id": "MN"
  },
  {
    "zip": 56584,
    "lat": 47.22952,
    "lng": -96.22904,
    "state_id": "MN"
  },
  {
    "zip": 56585,
    "lat": 47.09213,
    "lng": -96.24571,
    "state_id": "MN"
  },
  {
    "zip": 56586,
    "lat": 46.3227,
    "lng": -95.84146,
    "state_id": "MN"
  },
  {
    "zip": 56587,
    "lat": 46.64126,
    "lng": -95.86143,
    "state_id": "MN"
  },
  {
    "zip": 56588,
    "lat": 46.21949,
    "lng": -95.50605,
    "state_id": "MN"
  },
  {
    "zip": 56589,
    "lat": 47.1786,
    "lng": -95.72986,
    "state_id": "MN"
  },
  {
    "zip": 56590,
    "lat": 46.03682,
    "lng": -96.1618,
    "state_id": "MN"
  },
  {
    "zip": 56591,
    "lat": 47.08873,
    "lng": -95.84464,
    "state_id": "MN"
  },
  {
    "zip": 56592,
    "lat": 47.53638,
    "lng": -96.00757,
    "state_id": "MN"
  },
  {
    "zip": 56593,
    "lat": 46.8038,
    "lng": -95.35401,
    "state_id": "MN"
  },
  {
    "zip": 56594,
    "lat": 46.54828,
    "lng": -96.64299,
    "state_id": "MN"
  },
  {
    "zip": 56601,
    "lat": 47.51242,
    "lng": -94.85697,
    "state_id": "MN"
  },
  {
    "zip": 56621,
    "lat": 47.41863,
    "lng": -95.4075,
    "state_id": "MN"
  },
  {
    "zip": 56623,
    "lat": 48.60057,
    "lng": -94.56999,
    "state_id": "MN"
  },
  {
    "zip": 56626,
    "lat": 47.33949,
    "lng": -94.24502,
    "state_id": "MN"
  },
  {
    "zip": 56627,
    "lat": 48.23393,
    "lng": -94.05488,
    "state_id": "MN"
  },
  {
    "zip": 56628,
    "lat": 47.68668,
    "lng": -93.59346,
    "state_id": "MN"
  },
  {
    "zip": 56629,
    "lat": 48.54414,
    "lng": -94.11781,
    "state_id": "MN"
  },
  {
    "zip": 56630,
    "lat": 47.75703,
    "lng": -94.49914,
    "state_id": "MN"
  },
  {
    "zip": 56633,
    "lat": 47.31969,
    "lng": -94.50844,
    "state_id": "MN"
  },
  {
    "zip": 56634,
    "lat": 47.6827,
    "lng": -95.40297,
    "state_id": "MN"
  },
  {
    "zip": 56636,
    "lat": 47.40863,
    "lng": -93.90401,
    "state_id": "MN"
  },
  {
    "zip": 56637,
    "lat": 47.61075,
    "lng": -93.83605,
    "state_id": "MN"
  },
  {
    "zip": 56639,
    "lat": 47.9062,
    "lng": -93.52201,
    "state_id": "MN"
  },
  {
    "zip": 56641,
    "lat": 47.20637,
    "lng": -94.24352,
    "state_id": "MN"
  },
  {
    "zip": 56644,
    "lat": 47.79095,
    "lng": -95.51085,
    "state_id": "MN"
  },
  {
    "zip": 56646,
    "lat": 47.77564,
    "lng": -95.6372,
    "state_id": "MN"
  },
  {
    "zip": 56647,
    "lat": 47.69009,
    "lng": -94.61994,
    "state_id": "MN"
  },
  {
    "zip": 56649,
    "lat": 48.53442,
    "lng": -93.13185,
    "state_id": "MN"
  },
  {
    "zip": 56650,
    "lat": 47.99759,
    "lng": -94.56322,
    "state_id": "MN"
  },
  {
    "zip": 56651,
    "lat": 47.43874,
    "lng": -95.62106,
    "state_id": "MN"
  },
  {
    "zip": 56652,
    "lat": 47.73334,
    "lng": -95.24819,
    "state_id": "MN"
  },
  {
    "zip": 56653,
    "lat": 48.2607,
    "lng": -93.49868,
    "state_id": "MN"
  },
  {
    "zip": 56654,
    "lat": 48.47215,
    "lng": -93.94023,
    "state_id": "MN"
  },
  {
    "zip": 56655,
    "lat": 47.00359,
    "lng": -94.22748,
    "state_id": "MN"
  },
  {
    "zip": 56657,
    "lat": 47.57472,
    "lng": -93.61664,
    "state_id": "MN"
  },
  {
    "zip": 56658,
    "lat": 48.09036,
    "lng": -93.82919,
    "state_id": "MN"
  },
  {
    "zip": 56659,
    "lat": 47.64691,
    "lng": -94.01643,
    "state_id": "MN"
  },
  {
    "zip": 56660,
    "lat": 47.98996,
    "lng": -94.1743,
    "state_id": "MN"
  },
  {
    "zip": 56661,
    "lat": 47.85834,
    "lng": -94.12806,
    "state_id": "MN"
  },
  {
    "zip": 56662,
    "lat": 46.84415,
    "lng": -93.89664,
    "state_id": "MN"
  },
  {
    "zip": 56663,
    "lat": 47.51767,
    "lng": -94.46764,
    "state_id": "MN"
  },
  {
    "zip": 56666,
    "lat": 48.03179,
    "lng": -94.8337,
    "state_id": "MN"
  },
  {
    "zip": 56667,
    "lat": 47.73659,
    "lng": -94.94017,
    "state_id": "MN"
  },
  {
    "zip": 56668,
    "lat": 48.61279,
    "lng": -93.34597,
    "state_id": "MN"
  },
  {
    "zip": 56669,
    "lat": 48.40816,
    "lng": -93.19277,
    "state_id": "MN"
  },
  {
    "zip": 56670,
    "lat": 47.84881,
    "lng": -94.86603,
    "state_id": "MN"
  },
  {
    "zip": 56671,
    "lat": 47.91059,
    "lng": -95.31776,
    "state_id": "MN"
  },
  {
    "zip": 56672,
    "lat": 47.07447,
    "lng": -94.01538,
    "state_id": "MN"
  },
  {
    "zip": 56673,
    "lat": 48.82786,
    "lng": -95.1119,
    "state_id": "MN"
  },
  {
    "zip": 56676,
    "lat": 47.53466,
    "lng": -95.19276,
    "state_id": "MN"
  },
  {
    "zip": 56678,
    "lat": 47.42229,
    "lng": -95.11983,
    "state_id": "MN"
  },
  {
    "zip": 56680,
    "lat": 47.64803,
    "lng": -93.94111,
    "state_id": "MN"
  },
  {
    "zip": 56681,
    "lat": 47.60997,
    "lng": -94.25265,
    "state_id": "MN"
  },
  {
    "zip": 56683,
    "lat": 47.71829,
    "lng": -94.75213,
    "state_id": "MN"
  },
  {
    "zip": 56684,
    "lat": 47.86001,
    "lng": -95.6962,
    "state_id": "MN"
  },
  {
    "zip": 56685,
    "lat": 48.23841,
    "lng": -94.52099,
    "state_id": "MN"
  },
  {
    "zip": 56686,
    "lat": 48.78527,
    "lng": -94.91374,
    "state_id": "MN"
  },
  {
    "zip": 56687,
    "lat": 47.49933,
    "lng": -94.99641,
    "state_id": "MN"
  },
  {
    "zip": 56688,
    "lat": 47.7674,
    "lng": -93.9131,
    "state_id": "MN"
  },
  {
    "zip": 56701,
    "lat": 48.12209,
    "lng": -96.19247,
    "state_id": "MN"
  },
  {
    "zip": 56710,
    "lat": 48.21548,
    "lng": -97.00188,
    "state_id": "MN"
  },
  {
    "zip": 56711,
    "lat": 49.32761,
    "lng": -95.00168,
    "state_id": "MN"
  },
  {
    "zip": 56713,
    "lat": 48.34841,
    "lng": -96.8135,
    "state_id": "MN"
  },
  {
    "zip": 56714,
    "lat": 48.78556,
    "lng": -96.00593,
    "state_id": "MN"
  },
  {
    "zip": 56715,
    "lat": 47.80889,
    "lng": -95.94138,
    "state_id": "MN"
  },
  {
    "zip": 56716,
    "lat": 47.7454,
    "lng": -96.55255,
    "state_id": "MN"
  },
  {
    "zip": 56720,
    "lat": 48.57202,
    "lng": -96.89294,
    "state_id": "MN"
  },
  {
    "zip": 56721,
    "lat": 47.97356,
    "lng": -96.94394,
    "state_id": "MN"
  },
  {
    "zip": 56722,
    "lat": 47.9839,
    "lng": -96.64515,
    "state_id": "MN"
  },
  {
    "zip": 56723,
    "lat": 47.80943,
    "lng": -96.80638,
    "state_id": "MN"
  },
  {
    "zip": 56724,
    "lat": 48.44587,
    "lng": -95.73593,
    "state_id": "MN"
  },
  {
    "zip": 56725,
    "lat": 48.15052,
    "lng": -95.80073,
    "state_id": "MN"
  },
  {
    "zip": 56726,
    "lat": 48.74709,
    "lng": -96.23095,
    "state_id": "MN"
  },
  {
    "zip": 56727,
    "lat": 48.29343,
    "lng": -95.40182,
    "state_id": "MN"
  },
  {
    "zip": 56728,
    "lat": 48.79182,
    "lng": -96.99731,
    "state_id": "MN"
  },
  {
    "zip": 56729,
    "lat": 48.6639,
    "lng": -96.55754,
    "state_id": "MN"
  },
  {
    "zip": 56731,
    "lat": 48.90923,
    "lng": -97.06743,
    "state_id": "MN"
  },
  {
    "zip": 56732,
    "lat": 48.57627,
    "lng": -96.48706,
    "state_id": "MN"
  },
  {
    "zip": 56733,
    "lat": 48.63232,
    "lng": -96.89649,
    "state_id": "MN"
  },
  {
    "zip": 56734,
    "lat": 48.76782,
    "lng": -96.59521,
    "state_id": "MN"
  },
  {
    "zip": 56735,
    "lat": 48.91531,
    "lng": -96.63664,
    "state_id": "MN"
  },
  {
    "zip": 56736,
    "lat": 47.71411,
    "lng": -96.19226,
    "state_id": "MN"
  },
  {
    "zip": 56737,
    "lat": 48.43414,
    "lng": -96.03504,
    "state_id": "MN"
  },
  {
    "zip": 56738,
    "lat": 48.33514,
    "lng": -96.33509,
    "state_id": "MN"
  },
  {
    "zip": 56741,
    "lat": 49.28434,
    "lng": -94.85818,
    "state_id": "MN"
  },
  {
    "zip": 56742,
    "lat": 47.92821,
    "lng": -95.80976,
    "state_id": "MN"
  },
  {
    "zip": 56744,
    "lat": 48.24552,
    "lng": -97.11368,
    "state_id": "MN"
  },
  {
    "zip": 56748,
    "lat": 47.92198,
    "lng": -96.01021,
    "state_id": "MN"
  },
  {
    "zip": 56750,
    "lat": 47.89265,
    "lng": -96.29926,
    "state_id": "MN"
  },
  {
    "zip": 56751,
    "lat": 48.81137,
    "lng": -95.73291,
    "state_id": "MN"
  },
  {
    "zip": 56754,
    "lat": 47.99354,
    "lng": -96.1922,
    "state_id": "MN"
  },
  {
    "zip": 56755,
    "lat": 48.97109,
    "lng": -97.10472,
    "state_id": "MN"
  },
  {
    "zip": 56756,
    "lat": 48.89219,
    "lng": -95.54779,
    "state_id": "MN"
  },
  {
    "zip": 56757,
    "lat": 48.47462,
    "lng": -96.89199,
    "state_id": "MN"
  },
  {
    "zip": 56758,
    "lat": 48.46661,
    "lng": -96.51583,
    "state_id": "MN"
  },
  {
    "zip": 56759,
    "lat": 48.54587,
    "lng": -96.08797,
    "state_id": "MN"
  },
  {
    "zip": 56760,
    "lat": 48.24358,
    "lng": -96.45326,
    "state_id": "MN"
  },
  {
    "zip": 56761,
    "lat": 48.60273,
    "lng": -95.69637,
    "state_id": "MN"
  },
  {
    "zip": 56762,
    "lat": 48.16876,
    "lng": -96.73961,
    "state_id": "MN"
  },
  {
    "zip": 56763,
    "lat": 48.80886,
    "lng": -95.35636,
    "state_id": "MN"
  },
  {
    "zip": 63005,
    "lat": 38.64426,
    "lng": -90.64722,
    "state_id": "MO"
  },
  {
    "zip": 63010,
    "lat": 38.42948,
    "lng": -90.39324,
    "state_id": "MO"
  },
  {
    "zip": 63011,
    "lat": 38.6037,
    "lng": -90.55916,
    "state_id": "MO"
  },
  {
    "zip": 63012,
    "lat": 38.33317,
    "lng": -90.44748,
    "state_id": "MO"
  },
  {
    "zip": 63013,
    "lat": 38.41546,
    "lng": -91.15207,
    "state_id": "MO"
  },
  {
    "zip": 63014,
    "lat": 38.64701,
    "lng": -91.31851,
    "state_id": "MO"
  },
  {
    "zip": 63015,
    "lat": 38.40367,
    "lng": -90.75352,
    "state_id": "MO"
  },
  {
    "zip": 63016,
    "lat": 38.35304,
    "lng": -90.63997,
    "state_id": "MO"
  },
  {
    "zip": 63017,
    "lat": 38.65105,
    "lng": -90.53642,
    "state_id": "MO"
  },
  {
    "zip": 63019,
    "lat": 38.22875,
    "lng": -90.37673,
    "state_id": "MO"
  },
  {
    "zip": 63020,
    "lat": 38.10677,
    "lng": -90.56195,
    "state_id": "MO"
  },
  {
    "zip": 63021,
    "lat": 38.5688,
    "lng": -90.54644,
    "state_id": "MO"
  },
  {
    "zip": 63023,
    "lat": 38.26928,
    "lng": -90.71317,
    "state_id": "MO"
  },
  {
    "zip": 63025,
    "lat": 38.49049,
    "lng": -90.61937,
    "state_id": "MO"
  },
  {
    "zip": 63026,
    "lat": 38.50274,
    "lng": -90.4603,
    "state_id": "MO"
  },
  {
    "zip": 63028,
    "lat": 38.14639,
    "lng": -90.39567,
    "state_id": "MO"
  },
  {
    "zip": 63030,
    "lat": 38.12778,
    "lng": -90.74521,
    "state_id": "MO"
  },
  {
    "zip": 63031,
    "lat": 38.81194,
    "lng": -90.353,
    "state_id": "MO"
  },
  {
    "zip": 63033,
    "lat": 38.7956,
    "lng": -90.27717,
    "state_id": "MO"
  },
  {
    "zip": 63034,
    "lat": 38.84808,
    "lng": -90.28876,
    "state_id": "MO"
  },
  {
    "zip": 63036,
    "lat": 37.98041,
    "lng": -90.37608,
    "state_id": "MO"
  },
  {
    "zip": 63037,
    "lat": 38.42751,
    "lng": -91.3159,
    "state_id": "MO"
  },
  {
    "zip": 63038,
    "lat": 38.58139,
    "lng": -90.67217,
    "state_id": "MO"
  },
  {
    "zip": 63039,
    "lat": 38.49476,
    "lng": -90.83865,
    "state_id": "MO"
  },
  {
    "zip": 63040,
    "lat": 38.57299,
    "lng": -90.63496,
    "state_id": "MO"
  },
  {
    "zip": 63041,
    "lat": 38.25101,
    "lng": -90.79012,
    "state_id": "MO"
  },
  {
    "zip": 63042,
    "lat": 38.78579,
    "lng": -90.38627,
    "state_id": "MO"
  },
  {
    "zip": 63043,
    "lat": 38.72929,
    "lng": -90.46207,
    "state_id": "MO"
  },
  {
    "zip": 63044,
    "lat": 38.76984,
    "lng": -90.42897,
    "state_id": "MO"
  },
  {
    "zip": 63045,
    "lat": 38.7689,
    "lng": -90.46626,
    "state_id": "MO"
  },
  {
    "zip": 63047,
    "lat": 38.19566,
    "lng": -90.48722,
    "state_id": "MO"
  },
  {
    "zip": 63048,
    "lat": 38.25883,
    "lng": -90.39333,
    "state_id": "MO"
  },
  {
    "zip": 63049,
    "lat": 38.47939,
    "lng": -90.53099,
    "state_id": "MO"
  },
  {
    "zip": 63050,
    "lat": 38.25975,
    "lng": -90.57782,
    "state_id": "MO"
  },
  {
    "zip": 63051,
    "lat": 38.39957,
    "lng": -90.58004,
    "state_id": "MO"
  },
  {
    "zip": 63052,
    "lat": 38.39075,
    "lng": -90.43558,
    "state_id": "MO"
  },
  {
    "zip": 63053,
    "lat": 38.3666,
    "lng": -90.36394,
    "state_id": "MO"
  },
  {
    "zip": 63055,
    "lat": 38.53293,
    "lng": -90.83396,
    "state_id": "MO"
  },
  {
    "zip": 63056,
    "lat": 38.38981,
    "lng": -91.21711,
    "state_id": "MO"
  },
  {
    "zip": 63057,
    "lat": 38.34327,
    "lng": -90.40565,
    "state_id": "MO"
  },
  {
    "zip": 63060,
    "lat": 38.25889,
    "lng": -90.86722,
    "state_id": "MO"
  },
  {
    "zip": 63061,
    "lat": 38.26989,
    "lng": -90.81533,
    "state_id": "MO"
  },
  {
    "zip": 63068,
    "lat": 38.55697,
    "lng": -91.24177,
    "state_id": "MO"
  },
  {
    "zip": 63069,
    "lat": 38.48979,
    "lng": -90.7304,
    "state_id": "MO"
  },
  {
    "zip": 63070,
    "lat": 38.28374,
    "lng": -90.42141,
    "state_id": "MO"
  },
  {
    "zip": 63071,
    "lat": 38.15171,
    "lng": -90.82487,
    "state_id": "MO"
  },
  {
    "zip": 63072,
    "lat": 38.36885,
    "lng": -90.81344,
    "state_id": "MO"
  },
  {
    "zip": 63073,
    "lat": 38.59357,
    "lng": -90.76811,
    "state_id": "MO"
  },
  {
    "zip": 63074,
    "lat": 38.7265,
    "lng": -90.3887,
    "state_id": "MO"
  },
  {
    "zip": 63077,
    "lat": 38.32711,
    "lng": -90.98631,
    "state_id": "MO"
  },
  {
    "zip": 63079,
    "lat": 38.26058,
    "lng": -91.09978,
    "state_id": "MO"
  },
  {
    "zip": 63080,
    "lat": 38.20374,
    "lng": -91.08459,
    "state_id": "MO"
  },
  {
    "zip": 63084,
    "lat": 38.41787,
    "lng": -91.01283,
    "state_id": "MO"
  },
  {
    "zip": 63087,
    "lat": 38.0131,
    "lng": -90.45368,
    "state_id": "MO"
  },
  {
    "zip": 63088,
    "lat": 38.54766,
    "lng": -90.50325,
    "state_id": "MO"
  },
  {
    "zip": 63089,
    "lat": 38.45952,
    "lng": -90.88385,
    "state_id": "MO"
  },
  {
    "zip": 63090,
    "lat": 38.53098,
    "lng": -91.04846,
    "state_id": "MO"
  },
  {
    "zip": 63091,
    "lat": 38.36803,
    "lng": -91.39035,
    "state_id": "MO"
  },
  {
    "zip": 63101,
    "lat": 38.63153,
    "lng": -90.19255,
    "state_id": "MO"
  },
  {
    "zip": 63102,
    "lat": 38.63524,
    "lng": -90.18643,
    "state_id": "MO"
  },
  {
    "zip": 63103,
    "lat": 38.62975,
    "lng": -90.21682,
    "state_id": "MO"
  },
  {
    "zip": 63104,
    "lat": 38.61073,
    "lng": -90.21263,
    "state_id": "MO"
  },
  {
    "zip": 63105,
    "lat": 38.64432,
    "lng": -90.32833,
    "state_id": "MO"
  },
  {
    "zip": 63106,
    "lat": 38.64445,
    "lng": -90.20839,
    "state_id": "MO"
  },
  {
    "zip": 63107,
    "lat": 38.66386,
    "lng": -90.21208,
    "state_id": "MO"
  },
  {
    "zip": 63108,
    "lat": 38.64481,
    "lng": -90.25354,
    "state_id": "MO"
  },
  {
    "zip": 63109,
    "lat": 38.58455,
    "lng": -90.2958,
    "state_id": "MO"
  },
  {
    "zip": 63110,
    "lat": 38.62583,
    "lng": -90.26695,
    "state_id": "MO"
  },
  {
    "zip": 63111,
    "lat": 38.55822,
    "lng": -90.25004,
    "state_id": "MO"
  },
  {
    "zip": 63112,
    "lat": 38.65892,
    "lng": -90.28272,
    "state_id": "MO"
  },
  {
    "zip": 63113,
    "lat": 38.65798,
    "lng": -90.24757,
    "state_id": "MO"
  },
  {
    "zip": 63114,
    "lat": 38.70221,
    "lng": -90.36262,
    "state_id": "MO"
  },
  {
    "zip": 63115,
    "lat": 38.68224,
    "lng": -90.23993,
    "state_id": "MO"
  },
  {
    "zip": 63116,
    "lat": 38.58038,
    "lng": -90.26455,
    "state_id": "MO"
  },
  {
    "zip": 63117,
    "lat": 38.63085,
    "lng": -90.33107,
    "state_id": "MO"
  },
  {
    "zip": 63118,
    "lat": 38.59232,
    "lng": -90.22565,
    "state_id": "MO"
  },
  {
    "zip": 63119,
    "lat": 38.58847,
    "lng": -90.35134,
    "state_id": "MO"
  },
  {
    "zip": 63120,
    "lat": 38.69056,
    "lng": -90.26212,
    "state_id": "MO"
  },
  {
    "zip": 63121,
    "lat": 38.70721,
    "lng": -90.30136,
    "state_id": "MO"
  },
  {
    "zip": 63122,
    "lat": 38.57921,
    "lng": -90.42014,
    "state_id": "MO"
  },
  {
    "zip": 63123,
    "lat": 38.54892,
    "lng": -90.32756,
    "state_id": "MO"
  },
  {
    "zip": 63124,
    "lat": 38.63804,
    "lng": -90.38036,
    "state_id": "MO"
  },
  {
    "zip": 63125,
    "lat": 38.51852,
    "lng": -90.29285,
    "state_id": "MO"
  },
  {
    "zip": 63126,
    "lat": 38.54966,
    "lng": -90.37863,
    "state_id": "MO"
  },
  {
    "zip": 63127,
    "lat": 38.53325,
    "lng": -90.41347,
    "state_id": "MO"
  },
  {
    "zip": 63128,
    "lat": 38.49296,
    "lng": -90.38213,
    "state_id": "MO"
  },
  {
    "zip": 63129,
    "lat": 38.45578,
    "lng": -90.32196,
    "state_id": "MO"
  },
  {
    "zip": 63130,
    "lat": 38.665,
    "lng": -90.32528,
    "state_id": "MO"
  },
  {
    "zip": 63131,
    "lat": 38.61747,
    "lng": -90.4442,
    "state_id": "MO"
  },
  {
    "zip": 63132,
    "lat": 38.676,
    "lng": -90.37796,
    "state_id": "MO"
  },
  {
    "zip": 63133,
    "lat": 38.68092,
    "lng": -90.30597,
    "state_id": "MO"
  },
  {
    "zip": 63134,
    "lat": 38.7434,
    "lng": -90.34563,
    "state_id": "MO"
  },
  {
    "zip": 63135,
    "lat": 38.75007,
    "lng": -90.299,
    "state_id": "MO"
  },
  {
    "zip": 63136,
    "lat": 38.74332,
    "lng": -90.25979,
    "state_id": "MO"
  },
  {
    "zip": 63137,
    "lat": 38.75052,
    "lng": -90.21123,
    "state_id": "MO"
  },
  {
    "zip": 63138,
    "lat": 38.79965,
    "lng": -90.1823,
    "state_id": "MO"
  },
  {
    "zip": 63139,
    "lat": 38.61043,
    "lng": -90.29174,
    "state_id": "MO"
  },
  {
    "zip": 63140,
    "lat": 38.7385,
    "lng": -90.32312,
    "state_id": "MO"
  },
  {
    "zip": 63141,
    "lat": 38.65831,
    "lng": -90.45818,
    "state_id": "MO"
  },
  {
    "zip": 63143,
    "lat": 38.61125,
    "lng": -90.32018,
    "state_id": "MO"
  },
  {
    "zip": 63144,
    "lat": 38.61908,
    "lng": -90.34765,
    "state_id": "MO"
  },
  {
    "zip": 63146,
    "lat": 38.69842,
    "lng": -90.47332,
    "state_id": "MO"
  },
  {
    "zip": 63147,
    "lat": 38.69547,
    "lng": -90.21591,
    "state_id": "MO"
  },
  {
    "zip": 63155,
    "lat": 38.62788,
    "lng": -90.20545,
    "state_id": "MO"
  },
  {
    "zip": 63301,
    "lat": 38.86333,
    "lng": -90.46736,
    "state_id": "MO"
  },
  {
    "zip": 63303,
    "lat": 38.74008,
    "lng": -90.54291,
    "state_id": "MO"
  },
  {
    "zip": 63304,
    "lat": 38.70613,
    "lng": -90.66519,
    "state_id": "MO"
  },
  {
    "zip": 63330,
    "lat": 39.2707,
    "lng": -90.80655,
    "state_id": "MO"
  },
  {
    "zip": 63332,
    "lat": 38.59699,
    "lng": -90.89033,
    "state_id": "MO"
  },
  {
    "zip": 63333,
    "lat": 39.03672,
    "lng": -91.31596,
    "state_id": "MO"
  },
  {
    "zip": 63334,
    "lat": 39.30166,
    "lng": -91.18781,
    "state_id": "MO"
  },
  {
    "zip": 63336,
    "lat": 39.3362,
    "lng": -90.94666,
    "state_id": "MO"
  },
  {
    "zip": 63339,
    "lat": 39.3442,
    "lng": -91.37329,
    "state_id": "MO"
  },
  {
    "zip": 63341,
    "lat": 38.67431,
    "lng": -90.81188,
    "state_id": "MO"
  },
  {
    "zip": 63343,
    "lat": 39.15854,
    "lng": -90.82799,
    "state_id": "MO"
  },
  {
    "zip": 63344,
    "lat": 39.23691,
    "lng": -91.00429,
    "state_id": "MO"
  },
  {
    "zip": 63345,
    "lat": 39.27807,
    "lng": -91.57134,
    "state_id": "MO"
  },
  {
    "zip": 63347,
    "lat": 39.07093,
    "lng": -90.76701,
    "state_id": "MO"
  },
  {
    "zip": 63348,
    "lat": 38.79301,
    "lng": -90.9427,
    "state_id": "MO"
  },
  {
    "zip": 63349,
    "lat": 38.97755,
    "lng": -91.15308,
    "state_id": "MO"
  },
  {
    "zip": 63350,
    "lat": 38.89938,
    "lng": -91.36097,
    "state_id": "MO"
  },
  {
    "zip": 63351,
    "lat": 38.86243,
    "lng": -91.31372,
    "state_id": "MO"
  },
  {
    "zip": 63352,
    "lat": 39.25132,
    "lng": -91.65402,
    "state_id": "MO"
  },
  {
    "zip": 63353,
    "lat": 39.44689,
    "lng": -91.12198,
    "state_id": "MO"
  },
  {
    "zip": 63357,
    "lat": 38.65857,
    "lng": -91.09595,
    "state_id": "MO"
  },
  {
    "zip": 63359,
    "lat": 39.15548,
    "lng": -91.34908,
    "state_id": "MO"
  },
  {
    "zip": 63361,
    "lat": 38.96251,
    "lng": -91.55447,
    "state_id": "MO"
  },
  {
    "zip": 63362,
    "lat": 38.93213,
    "lng": -90.88017,
    "state_id": "MO"
  },
  {
    "zip": 63363,
    "lat": 38.88612,
    "lng": -91.45602,
    "state_id": "MO"
  },
  {
    "zip": 63366,
    "lat": 38.85944,
    "lng": -90.72026,
    "state_id": "MO"
  },
  {
    "zip": 63367,
    "lat": 38.77781,
    "lng": -90.79558,
    "state_id": "MO"
  },
  {
    "zip": 63368,
    "lat": 38.75126,
    "lng": -90.72957,
    "state_id": "MO"
  },
  {
    "zip": 63369,
    "lat": 38.93958,
    "lng": -90.76506,
    "state_id": "MO"
  },
  {
    "zip": 63370,
    "lat": 39.09021,
    "lng": -91.24057,
    "state_id": "MO"
  },
  {
    "zip": 63373,
    "lat": 38.92999,
    "lng": -90.39297,
    "state_id": "MO"
  },
  {
    "zip": 63376,
    "lat": 38.79989,
    "lng": -90.61738,
    "state_id": "MO"
  },
  {
    "zip": 63377,
    "lat": 39.11037,
    "lng": -91.1037,
    "state_id": "MO"
  },
  {
    "zip": 63379,
    "lat": 38.99816,
    "lng": -90.9888,
    "state_id": "MO"
  },
  {
    "zip": 63381,
    "lat": 38.98402,
    "lng": -91.25447,
    "state_id": "MO"
  },
  {
    "zip": 63382,
    "lat": 39.30535,
    "lng": -91.49609,
    "state_id": "MO"
  },
  {
    "zip": 63383,
    "lat": 38.80634,
    "lng": -91.19086,
    "state_id": "MO"
  },
  {
    "zip": 63384,
    "lat": 39.09236,
    "lng": -91.56,
    "state_id": "MO"
  },
  {
    "zip": 63385,
    "lat": 38.79705,
    "lng": -90.85754,
    "state_id": "MO"
  },
  {
    "zip": 63386,
    "lat": 38.86962,
    "lng": -90.21517,
    "state_id": "MO"
  },
  {
    "zip": 63387,
    "lat": 39.18393,
    "lng": -91.01656,
    "state_id": "MO"
  },
  {
    "zip": 63388,
    "lat": 38.88769,
    "lng": -91.74286,
    "state_id": "MO"
  },
  {
    "zip": 63389,
    "lat": 39.0052,
    "lng": -90.77884,
    "state_id": "MO"
  },
  {
    "zip": 63390,
    "lat": 38.80673,
    "lng": -91.04079,
    "state_id": "MO"
  },
  {
    "zip": 63401,
    "lat": 39.69234,
    "lng": -91.45656,
    "state_id": "MO"
  },
  {
    "zip": 63430,
    "lat": 40.38961,
    "lng": -91.5404,
    "state_id": "MO"
  },
  {
    "zip": 63431,
    "lat": 39.74409,
    "lng": -92.32712,
    "state_id": "MO"
  },
  {
    "zip": 63432,
    "lat": 40.50059,
    "lng": -91.99888,
    "state_id": "MO"
  },
  {
    "zip": 63433,
    "lat": 39.53296,
    "lng": -91.13169,
    "state_id": "MO"
  },
  {
    "zip": 63434,
    "lat": 39.90679,
    "lng": -91.95009,
    "state_id": "MO"
  },
  {
    "zip": 63435,
    "lat": 40.20545,
    "lng": -91.58375,
    "state_id": "MO"
  },
  {
    "zip": 63436,
    "lat": 39.50658,
    "lng": -91.54663,
    "state_id": "MO"
  },
  {
    "zip": 63437,
    "lat": 39.7318,
    "lng": -92.2372,
    "state_id": "MO"
  },
  {
    "zip": 63438,
    "lat": 39.96113,
    "lng": -91.68647,
    "state_id": "MO"
  },
  {
    "zip": 63439,
    "lat": 39.80197,
    "lng": -91.86059,
    "state_id": "MO"
  },
  {
    "zip": 63440,
    "lat": 39.97682,
    "lng": -91.75183,
    "state_id": "MO"
  },
  {
    "zip": 63441,
    "lat": 39.47846,
    "lng": -91.30956,
    "state_id": "MO"
  },
  {
    "zip": 63443,
    "lat": 39.6686,
    "lng": -91.86797,
    "state_id": "MO"
  },
  {
    "zip": 63445,
    "lat": 40.39883,
    "lng": -91.72564,
    "state_id": "MO"
  },
  {
    "zip": 63446,
    "lat": 40.13623,
    "lng": -92.00384,
    "state_id": "MO"
  },
  {
    "zip": 63447,
    "lat": 40.09495,
    "lng": -91.90277,
    "state_id": "MO"
  },
  {
    "zip": 63448,
    "lat": 40.02694,
    "lng": -91.55354,
    "state_id": "MO"
  },
  {
    "zip": 63450,
    "lat": 39.67125,
    "lng": -92.14849,
    "state_id": "MO"
  },
  {
    "zip": 63451,
    "lat": 39.90604,
    "lng": -92.20448,
    "state_id": "MO"
  },
  {
    "zip": 63452,
    "lat": 40.09119,
    "lng": -91.78605,
    "state_id": "MO"
  },
  {
    "zip": 63453,
    "lat": 40.50431,
    "lng": -91.87111,
    "state_id": "MO"
  },
  {
    "zip": 63454,
    "lat": 39.92671,
    "lng": -91.64758,
    "state_id": "MO"
  },
  {
    "zip": 63456,
    "lat": 39.65585,
    "lng": -91.72648,
    "state_id": "MO"
  },
  {
    "zip": 63457,
    "lat": 40.16591,
    "lng": -91.70263,
    "state_id": "MO"
  },
  {
    "zip": 63458,
    "lat": 39.99237,
    "lng": -92.02156,
    "state_id": "MO"
  },
  {
    "zip": 63459,
    "lat": 39.58146,
    "lng": -91.37768,
    "state_id": "MO"
  },
  {
    "zip": 63460,
    "lat": 39.99864,
    "lng": -92.21186,
    "state_id": "MO"
  },
  {
    "zip": 63461,
    "lat": 39.79798,
    "lng": -91.55773,
    "state_id": "MO"
  },
  {
    "zip": 63462,
    "lat": 39.42545,
    "lng": -91.69914,
    "state_id": "MO"
  },
  {
    "zip": 63463,
    "lat": 39.84514,
    "lng": -91.76016,
    "state_id": "MO"
  },
  {
    "zip": 63465,
    "lat": 40.51716,
    "lng": -91.67529,
    "state_id": "MO"
  },
  {
    "zip": 63467,
    "lat": 39.64859,
    "lng": -91.27085,
    "state_id": "MO"
  },
  {
    "zip": 63468,
    "lat": 39.68063,
    "lng": -92.01349,
    "state_id": "MO"
  },
  {
    "zip": 63469,
    "lat": 39.83348,
    "lng": -92.04297,
    "state_id": "MO"
  },
  {
    "zip": 63471,
    "lat": 39.92478,
    "lng": -91.50729,
    "state_id": "MO"
  },
  {
    "zip": 63472,
    "lat": 40.40016,
    "lng": -91.58422,
    "state_id": "MO"
  },
  {
    "zip": 63473,
    "lat": 40.24913,
    "lng": -91.78521,
    "state_id": "MO"
  },
  {
    "zip": 63474,
    "lat": 40.35129,
    "lng": -91.89949,
    "state_id": "MO"
  },
  {
    "zip": 63501,
    "lat": 40.16727,
    "lng": -92.59033,
    "state_id": "MO"
  },
  {
    "zip": 63530,
    "lat": 39.90545,
    "lng": -92.45446,
    "state_id": "MO"
  },
  {
    "zip": 63531,
    "lat": 40.29939,
    "lng": -92.25034,
    "state_id": "MO"
  },
  {
    "zip": 63532,
    "lat": 39.76086,
    "lng": -92.57343,
    "state_id": "MO"
  },
  {
    "zip": 63533,
    "lat": 40.20497,
    "lng": -92.39463,
    "state_id": "MO"
  },
  {
    "zip": 63534,
    "lat": 39.74492,
    "lng": -92.64731,
    "state_id": "MO"
  },
  {
    "zip": 63535,
    "lat": 40.56892,
    "lng": -92.65856,
    "state_id": "MO"
  },
  {
    "zip": 63536,
    "lat": 40.49056,
    "lng": -92.35914,
    "state_id": "MO"
  },
  {
    "zip": 63537,
    "lat": 40.14854,
    "lng": -92.16016,
    "state_id": "MO"
  },
  {
    "zip": 63538,
    "lat": 39.9544,
    "lng": -92.67004,
    "state_id": "MO"
  },
  {
    "zip": 63539,
    "lat": 39.91479,
    "lng": -92.75527,
    "state_id": "MO"
  },
  {
    "zip": 63540,
    "lat": 40.10398,
    "lng": -92.40659,
    "state_id": "MO"
  },
  {
    "zip": 63541,
    "lat": 40.50356,
    "lng": -92.62973,
    "state_id": "MO"
  },
  {
    "zip": 63543,
    "lat": 40.35696,
    "lng": -92.00855,
    "state_id": "MO"
  },
  {
    "zip": 63544,
    "lat": 40.25124,
    "lng": -92.84261,
    "state_id": "MO"
  },
  {
    "zip": 63545,
    "lat": 40.26899,
    "lng": -92.96453,
    "state_id": "MO"
  },
  {
    "zip": 63546,
    "lat": 40.33071,
    "lng": -92.51474,
    "state_id": "MO"
  },
  {
    "zip": 63547,
    "lat": 40.13751,
    "lng": -92.29973,
    "state_id": "MO"
  },
  {
    "zip": 63548,
    "lat": 40.53009,
    "lng": -92.48521,
    "state_id": "MO"
  },
  {
    "zip": 63549,
    "lat": 40.01822,
    "lng": -92.48597,
    "state_id": "MO"
  },
  {
    "zip": 63551,
    "lat": 40.50061,
    "lng": -92.73558,
    "state_id": "MO"
  },
  {
    "zip": 63552,
    "lat": 39.75978,
    "lng": -92.44743,
    "state_id": "MO"
  },
  {
    "zip": 63555,
    "lat": 40.48106,
    "lng": -92.19579,
    "state_id": "MO"
  },
  {
    "zip": 63556,
    "lat": 40.18512,
    "lng": -93.125,
    "state_id": "MO"
  },
  {
    "zip": 63557,
    "lat": 39.97621,
    "lng": -92.87157,
    "state_id": "MO"
  },
  {
    "zip": 63558,
    "lat": 39.7425,
    "lng": -92.75304,
    "state_id": "MO"
  },
  {
    "zip": 63559,
    "lat": 40.22228,
    "lng": -92.745,
    "state_id": "MO"
  },
  {
    "zip": 63560,
    "lat": 40.35816,
    "lng": -93.11879,
    "state_id": "MO"
  },
  {
    "zip": 63561,
    "lat": 40.41937,
    "lng": -92.54226,
    "state_id": "MO"
  },
  {
    "zip": 63563,
    "lat": 40.30413,
    "lng": -92.07061,
    "state_id": "MO"
  },
  {
    "zip": 63565,
    "lat": 40.48406,
    "lng": -92.94506,
    "state_id": "MO"
  },
  {
    "zip": 63566,
    "lat": 40.0503,
    "lng": -92.9253,
    "state_id": "MO"
  },
  {
    "zip": 63567,
    "lat": 40.41882,
    "lng": -92.71979,
    "state_id": "MO"
  },
  {
    "zip": 63601,
    "lat": 37.82421,
    "lng": -90.54705,
    "state_id": "MO"
  },
  {
    "zip": 63620,
    "lat": 37.39458,
    "lng": -90.65368,
    "state_id": "MO"
  },
  {
    "zip": 63621,
    "lat": 37.48715,
    "lng": -90.60616,
    "state_id": "MO"
  },
  {
    "zip": 63622,
    "lat": 37.7887,
    "lng": -90.89655,
    "state_id": "MO"
  },
  {
    "zip": 63623,
    "lat": 37.67519,
    "lng": -90.88897,
    "state_id": "MO"
  },
  {
    "zip": 63624,
    "lat": 37.75239,
    "lng": -90.64097,
    "state_id": "MO"
  },
  {
    "zip": 63625,
    "lat": 37.57042,
    "lng": -90.97794,
    "state_id": "MO"
  },
  {
    "zip": 63626,
    "lat": 38.06894,
    "lng": -90.68797,
    "state_id": "MO"
  },
  {
    "zip": 63627,
    "lat": 38.04471,
    "lng": -90.25724,
    "state_id": "MO"
  },
  {
    "zip": 63628,
    "lat": 37.94102,
    "lng": -90.52333,
    "state_id": "MO"
  },
  {
    "zip": 63629,
    "lat": 37.41515,
    "lng": -91.23542,
    "state_id": "MO"
  },
  {
    "zip": 63630,
    "lat": 38.01825,
    "lng": -90.72722,
    "state_id": "MO"
  },
  {
    "zip": 63631,
    "lat": 37.7466,
    "lng": -90.78287,
    "state_id": "MO"
  },
  {
    "zip": 63633,
    "lat": 37.42851,
    "lng": -91.01848,
    "state_id": "MO"
  },
  {
    "zip": 63636,
    "lat": 37.29551,
    "lng": -90.57778,
    "state_id": "MO"
  },
  {
    "zip": 63637,
    "lat": 37.71967,
    "lng": -90.51407,
    "state_id": "MO"
  },
  {
    "zip": 63638,
    "lat": 37.21666,
    "lng": -91.00725,
    "state_id": "MO"
  },
  {
    "zip": 63640,
    "lat": 37.75098,
    "lng": -90.36963,
    "state_id": "MO"
  },
  {
    "zip": 63645,
    "lat": 37.53669,
    "lng": -90.33513,
    "state_id": "MO"
  },
  {
    "zip": 63648,
    "lat": 37.82143,
    "lng": -90.6949,
    "state_id": "MO"
  },
  {
    "zip": 63650,
    "lat": 37.61629,
    "lng": -90.63141,
    "state_id": "MO"
  },
  {
    "zip": 63653,
    "lat": 37.86025,
    "lng": -90.59161,
    "state_id": "MO"
  },
  {
    "zip": 63654,
    "lat": 37.45814,
    "lng": -90.8546,
    "state_id": "MO"
  },
  {
    "zip": 63655,
    "lat": 37.39352,
    "lng": -90.20766,
    "state_id": "MO"
  },
  {
    "zip": 63656,
    "lat": 37.59292,
    "lng": -90.79904,
    "state_id": "MO"
  },
  {
    "zip": 63660,
    "lat": 37.91345,
    "lng": -90.69782,
    "state_id": "MO"
  },
  {
    "zip": 63662,
    "lat": 37.53116,
    "lng": -90.05128,
    "state_id": "MO"
  },
  {
    "zip": 63663,
    "lat": 37.62499,
    "lng": -90.64608,
    "state_id": "MO"
  },
  {
    "zip": 63664,
    "lat": 37.91251,
    "lng": -90.91974,
    "state_id": "MO"
  },
  {
    "zip": 63665,
    "lat": 37.30804,
    "lng": -90.84559,
    "state_id": "MO"
  },
  {
    "zip": 63666,
    "lat": 37.39807,
    "lng": -91.05979,
    "state_id": "MO"
  },
  {
    "zip": 63670,
    "lat": 37.8958,
    "lng": -90.16833,
    "state_id": "MO"
  },
  {
    "zip": 63673,
    "lat": 37.84217,
    "lng": -89.99082,
    "state_id": "MO"
  },
  {
    "zip": 63674,
    "lat": 38.03131,
    "lng": -90.65785,
    "state_id": "MO"
  },
  {
    "zip": 63675,
    "lat": 37.30476,
    "lng": -90.68977,
    "state_id": "MO"
  },
  {
    "zip": 63701,
    "lat": 37.33785,
    "lng": -89.57681,
    "state_id": "MO"
  },
  {
    "zip": 63703,
    "lat": 37.27381,
    "lng": -89.53995,
    "state_id": "MO"
  },
  {
    "zip": 63730,
    "lat": 37.09789,
    "lng": -89.91708,
    "state_id": "MO"
  },
  {
    "zip": 63732,
    "lat": 37.59161,
    "lng": -89.58041,
    "state_id": "MO"
  },
  {
    "zip": 63735,
    "lat": 36.98628,
    "lng": -89.7798,
    "state_id": "MO"
  },
  {
    "zip": 63736,
    "lat": 37.0849,
    "lng": -89.50748,
    "state_id": "MO"
  },
  {
    "zip": 63738,
    "lat": 37.0893,
    "lng": -89.95738,
    "state_id": "MO"
  },
  {
    "zip": 63739,
    "lat": 37.36029,
    "lng": -89.80045,
    "state_id": "MO"
  },
  {
    "zip": 63740,
    "lat": 37.17594,
    "lng": -89.68507,
    "state_id": "MO"
  },
  {
    "zip": 63742,
    "lat": 37.15866,
    "lng": -89.44621,
    "state_id": "MO"
  },
  {
    "zip": 63743,
    "lat": 37.51737,
    "lng": -89.82544,
    "state_id": "MO"
  },
  {
    "zip": 63744,
    "lat": 37.19582,
    "lng": -89.7382,
    "state_id": "MO"
  },
  {
    "zip": 63745,
    "lat": 37.24193,
    "lng": -89.69407,
    "state_id": "MO"
  },
  {
    "zip": 63746,
    "lat": 37.7078,
    "lng": -89.69482,
    "state_id": "MO"
  },
  {
    "zip": 63747,
    "lat": 37.5597,
    "lng": -89.80889,
    "state_id": "MO"
  },
  {
    "zip": 63748,
    "lat": 37.67265,
    "lng": -89.62223,
    "state_id": "MO"
  },
  {
    "zip": 63750,
    "lat": 37.14849,
    "lng": -90.18596,
    "state_id": "MO"
  },
  {
    "zip": 63751,
    "lat": 37.28122,
    "lng": -90.13552,
    "state_id": "MO"
  },
  {
    "zip": 63755,
    "lat": 37.44246,
    "lng": -89.63964,
    "state_id": "MO"
  },
  {
    "zip": 63758,
    "lat": 37.18646,
    "lng": -89.55678,
    "state_id": "MO"
  },
  {
    "zip": 63760,
    "lat": 37.24007,
    "lng": -89.90157,
    "state_id": "MO"
  },
  {
    "zip": 63763,
    "lat": 37.05533,
    "lng": -90.17657,
    "state_id": "MO"
  },
  {
    "zip": 63764,
    "lat": 37.31917,
    "lng": -89.99003,
    "state_id": "MO"
  },
  {
    "zip": 63766,
    "lat": 37.4345,
    "lng": -89.84406,
    "state_id": "MO"
  },
  {
    "zip": 63767,
    "lat": 37.04621,
    "lng": -89.61122,
    "state_id": "MO"
  },
  {
    "zip": 63769,
    "lat": 37.52118,
    "lng": -89.73896,
    "state_id": "MO"
  },
  {
    "zip": 63770,
    "lat": 37.5975,
    "lng": -89.70307,
    "state_id": "MO"
  },
  {
    "zip": 63771,
    "lat": 37.07344,
    "lng": -89.7063,
    "state_id": "MO"
  },
  {
    "zip": 63774,
    "lat": 37.09603,
    "lng": -89.75993,
    "state_id": "MO"
  },
  {
    "zip": 63775,
    "lat": 37.71395,
    "lng": -89.88138,
    "state_id": "MO"
  },
  {
    "zip": 63780,
    "lat": 37.1894,
    "lng": -89.51421,
    "state_id": "MO"
  },
  {
    "zip": 63781,
    "lat": 37.53858,
    "lng": -89.92908,
    "state_id": "MO"
  },
  {
    "zip": 63782,
    "lat": 37.11361,
    "lng": -90.02781,
    "state_id": "MO"
  },
  {
    "zip": 63783,
    "lat": 37.60725,
    "lng": -89.67986,
    "state_id": "MO"
  },
  {
    "zip": 63784,
    "lat": 36.98454,
    "lng": -89.69155,
    "state_id": "MO"
  },
  {
    "zip": 63785,
    "lat": 37.26415,
    "lng": -89.81515,
    "state_id": "MO"
  },
  {
    "zip": 63787,
    "lat": 37.12578,
    "lng": -90.12329,
    "state_id": "MO"
  },
  {
    "zip": 63801,
    "lat": 36.89805,
    "lng": -89.6053,
    "state_id": "MO"
  },
  {
    "zip": 63820,
    "lat": 36.82421,
    "lng": -89.32559,
    "state_id": "MO"
  },
  {
    "zip": 63821,
    "lat": 36.04869,
    "lng": -90.23924,
    "state_id": "MO"
  },
  {
    "zip": 63822,
    "lat": 36.66432,
    "lng": -90.00176,
    "state_id": "MO"
  },
  {
    "zip": 63823,
    "lat": 36.89568,
    "lng": -89.45633,
    "state_id": "MO"
  },
  {
    "zip": 63824,
    "lat": 37.00501,
    "lng": -89.52662,
    "state_id": "MO"
  },
  {
    "zip": 63825,
    "lat": 36.9271,
    "lng": -89.94558,
    "state_id": "MO"
  },
  {
    "zip": 63826,
    "lat": 36.17481,
    "lng": -89.85056,
    "state_id": "MO"
  },
  {
    "zip": 63827,
    "lat": 36.26012,
    "lng": -89.88749,
    "state_id": "MO"
  },
  {
    "zip": 63828,
    "lat": 36.75037,
    "lng": -89.69183,
    "state_id": "MO"
  },
  {
    "zip": 63829,
    "lat": 36.03861,
    "lng": -90.31333,
    "state_id": "MO"
  },
  {
    "zip": 63830,
    "lat": 36.13007,
    "lng": -89.69068,
    "state_id": "MO"
  },
  {
    "zip": 63833,
    "lat": 36.6455,
    "lng": -89.72895,
    "state_id": "MO"
  },
  {
    "zip": 63834,
    "lat": 36.92081,
    "lng": -89.27888,
    "state_id": "MO"
  },
  {
    "zip": 63837,
    "lat": 36.45875,
    "lng": -89.99543,
    "state_id": "MO"
  },
  {
    "zip": 63839,
    "lat": 36.04477,
    "lng": -89.81363,
    "state_id": "MO"
  },
  {
    "zip": 63841,
    "lat": 36.77337,
    "lng": -89.98702,
    "state_id": "MO"
  },
  {
    "zip": 63845,
    "lat": 36.70987,
    "lng": -89.32336,
    "state_id": "MO"
  },
  {
    "zip": 63846,
    "lat": 36.82187,
    "lng": -89.80301,
    "state_id": "MO"
  },
  {
    "zip": 63847,
    "lat": 36.43996,
    "lng": -90.03892,
    "state_id": "MO"
  },
  {
    "zip": 63848,
    "lat": 36.44766,
    "lng": -89.89886,
    "state_id": "MO"
  },
  {
    "zip": 63849,
    "lat": 36.13288,
    "lng": -89.98292,
    "state_id": "MO"
  },
  {
    "zip": 63851,
    "lat": 36.25887,
    "lng": -89.71725,
    "state_id": "MO"
  },
  {
    "zip": 63852,
    "lat": 36.37352,
    "lng": -90.01536,
    "state_id": "MO"
  },
  {
    "zip": 63853,
    "lat": 36.06479,
    "lng": -89.87034,
    "state_id": "MO"
  },
  {
    "zip": 63855,
    "lat": 36.05434,
    "lng": -90.07053,
    "state_id": "MO"
  },
  {
    "zip": 63857,
    "lat": 36.22457,
    "lng": -90.04691,
    "state_id": "MO"
  },
  {
    "zip": 63860,
    "lat": 36.65333,
    "lng": -89.58819,
    "state_id": "MO"
  },
  {
    "zip": 63862,
    "lat": 36.57859,
    "lng": -89.68325,
    "state_id": "MO"
  },
  {
    "zip": 63863,
    "lat": 36.56464,
    "lng": -89.9795,
    "state_id": "MO"
  },
  {
    "zip": 63866,
    "lat": 36.53281,
    "lng": -89.60421,
    "state_id": "MO"
  },
  {
    "zip": 63867,
    "lat": 36.73736,
    "lng": -89.54805,
    "state_id": "MO"
  },
  {
    "zip": 63868,
    "lat": 36.84916,
    "lng": -89.69337,
    "state_id": "MO"
  },
  {
    "zip": 63869,
    "lat": 36.59589,
    "lng": -89.48586,
    "state_id": "MO"
  },
  {
    "zip": 63870,
    "lat": 36.62198,
    "lng": -89.84782,
    "state_id": "MO"
  },
  {
    "zip": 63873,
    "lat": 36.42374,
    "lng": -89.67057,
    "state_id": "MO"
  },
  {
    "zip": 63874,
    "lat": 36.54712,
    "lng": -89.81846,
    "state_id": "MO"
  },
  {
    "zip": 63876,
    "lat": 36.13182,
    "lng": -90.16855,
    "state_id": "MO"
  },
  {
    "zip": 63877,
    "lat": 36.07563,
    "lng": -89.85708,
    "state_id": "MO"
  },
  {
    "zip": 63878,
    "lat": 36.50301,
    "lng": -89.82234,
    "state_id": "MO"
  },
  {
    "zip": 63879,
    "lat": 36.35477,
    "lng": -89.81704,
    "state_id": "MO"
  },
  {
    "zip": 63880,
    "lat": 36.32723,
    "lng": -90.0251,
    "state_id": "MO"
  },
  {
    "zip": 63882,
    "lat": 36.91476,
    "lng": -89.22185,
    "state_id": "MO"
  },
  {
    "zip": 63901,
    "lat": 36.76636,
    "lng": -90.46266,
    "state_id": "MO"
  },
  {
    "zip": 63902,
    "lat": 36.76761,
    "lng": -90.4271,
    "state_id": "MO"
  },
  {
    "zip": 63932,
    "lat": 36.68993,
    "lng": -90.25075,
    "state_id": "MO"
  },
  {
    "zip": 63933,
    "lat": 36.52847,
    "lng": -90.11044,
    "state_id": "MO"
  },
  {
    "zip": 63934,
    "lat": 37.19795,
    "lng": -90.38413,
    "state_id": "MO"
  },
  {
    "zip": 63935,
    "lat": 36.67173,
    "lng": -90.91571,
    "state_id": "MO"
  },
  {
    "zip": 63936,
    "lat": 36.80759,
    "lng": -90.13448,
    "state_id": "MO"
  },
  {
    "zip": 63937,
    "lat": 36.94006,
    "lng": -90.76896,
    "state_id": "MO"
  },
  {
    "zip": 63939,
    "lat": 36.66339,
    "lng": -90.6821,
    "state_id": "MO"
  },
  {
    "zip": 63940,
    "lat": 36.77101,
    "lng": -90.2276,
    "state_id": "MO"
  },
  {
    "zip": 63941,
    "lat": 36.8901,
    "lng": -91.17029,
    "state_id": "MO"
  },
  {
    "zip": 63942,
    "lat": 36.55101,
    "lng": -91.07906,
    "state_id": "MO"
  },
  {
    "zip": 63943,
    "lat": 36.82855,
    "lng": -90.79023,
    "state_id": "MO"
  },
  {
    "zip": 63944,
    "lat": 37.10636,
    "lng": -90.3659,
    "state_id": "MO"
  },
  {
    "zip": 63945,
    "lat": 36.63478,
    "lng": -90.54576,
    "state_id": "MO"
  },
  {
    "zip": 63951,
    "lat": 37.12085,
    "lng": -90.26341,
    "state_id": "MO"
  },
  {
    "zip": 63952,
    "lat": 37.00926,
    "lng": -90.65117,
    "state_id": "MO"
  },
  {
    "zip": 63953,
    "lat": 36.58187,
    "lng": -90.63153,
    "state_id": "MO"
  },
  {
    "zip": 63954,
    "lat": 36.55756,
    "lng": -90.47373,
    "state_id": "MO"
  },
  {
    "zip": 63955,
    "lat": 36.60357,
    "lng": -90.68089,
    "state_id": "MO"
  },
  {
    "zip": 63956,
    "lat": 37.2116,
    "lng": -90.51818,
    "state_id": "MO"
  },
  {
    "zip": 63957,
    "lat": 37.1451,
    "lng": -90.68288,
    "state_id": "MO"
  },
  {
    "zip": 63960,
    "lat": 36.96109,
    "lng": -90.12297,
    "state_id": "MO"
  },
  {
    "zip": 63961,
    "lat": 36.57073,
    "lng": -90.27104,
    "state_id": "MO"
  },
  {
    "zip": 63962,
    "lat": 36.84408,
    "lng": -90.28148,
    "state_id": "MO"
  },
  {
    "zip": 63964,
    "lat": 37.25404,
    "lng": -90.39999,
    "state_id": "MO"
  },
  {
    "zip": 63965,
    "lat": 36.9678,
    "lng": -91.01243,
    "state_id": "MO"
  },
  {
    "zip": 63966,
    "lat": 36.97806,
    "lng": -90.26246,
    "state_id": "MO"
  },
  {
    "zip": 63967,
    "lat": 36.94607,
    "lng": -90.47916,
    "state_id": "MO"
  },
  {
    "zip": 64001,
    "lat": 39.10533,
    "lng": -93.54211,
    "state_id": "MO"
  },
  {
    "zip": 64011,
    "lat": 38.96596,
    "lng": -94.0688,
    "state_id": "MO"
  },
  {
    "zip": 64012,
    "lat": 38.78686,
    "lng": -94.54993,
    "state_id": "MO"
  },
  {
    "zip": 64014,
    "lat": 39.0071,
    "lng": -94.25504,
    "state_id": "MO"
  },
  {
    "zip": 64015,
    "lat": 39.01411,
    "lng": -94.30635,
    "state_id": "MO"
  },
  {
    "zip": 64016,
    "lat": 39.11773,
    "lng": -94.21478,
    "state_id": "MO"
  },
  {
    "zip": 64017,
    "lat": 39.19551,
    "lng": -94.02403,
    "state_id": "MO"
  },
  {
    "zip": 64018,
    "lat": 39.45249,
    "lng": -94.72685,
    "state_id": "MO"
  },
  {
    "zip": 64019,
    "lat": 38.77581,
    "lng": -93.86392,
    "state_id": "MO"
  },
  {
    "zip": 64020,
    "lat": 38.96606,
    "lng": -93.59126,
    "state_id": "MO"
  },
  {
    "zip": 64021,
    "lat": 39.10661,
    "lng": -93.62755,
    "state_id": "MO"
  },
  {
    "zip": 64022,
    "lat": 39.19508,
    "lng": -93.67277,
    "state_id": "MO"
  },
  {
    "zip": 64024,
    "lat": 39.32369,
    "lng": -94.22796,
    "state_id": "MO"
  },
  {
    "zip": 64029,
    "lat": 39.00581,
    "lng": -94.21514,
    "state_id": "MO"
  },
  {
    "zip": 64030,
    "lat": 38.88014,
    "lng": -94.52271,
    "state_id": "MO"
  },
  {
    "zip": 64034,
    "lat": 38.85237,
    "lng": -94.29942,
    "state_id": "MO"
  },
  {
    "zip": 64035,
    "lat": 39.2949,
    "lng": -93.80948,
    "state_id": "MO"
  },
  {
    "zip": 64036,
    "lat": 39.19827,
    "lng": -93.9354,
    "state_id": "MO"
  },
  {
    "zip": 64037,
    "lat": 39.05745,
    "lng": -93.73279,
    "state_id": "MO"
  },
  {
    "zip": 64040,
    "lat": 38.72149,
    "lng": -93.98638,
    "state_id": "MO"
  },
  {
    "zip": 64048,
    "lat": 39.44387,
    "lng": -94.36712,
    "state_id": "MO"
  },
  {
    "zip": 64050,
    "lat": 39.11515,
    "lng": -94.40757,
    "state_id": "MO"
  },
  {
    "zip": 64052,
    "lat": 39.0734,
    "lng": -94.45018,
    "state_id": "MO"
  },
  {
    "zip": 64053,
    "lat": 39.10994,
    "lng": -94.46475,
    "state_id": "MO"
  },
  {
    "zip": 64054,
    "lat": 39.10999,
    "lng": -94.4401,
    "state_id": "MO"
  },
  {
    "zip": 64055,
    "lat": 39.05069,
    "lng": -94.39858,
    "state_id": "MO"
  },
  {
    "zip": 64056,
    "lat": 39.1105,
    "lng": -94.31636,
    "state_id": "MO"
  },
  {
    "zip": 64057,
    "lat": 39.06923,
    "lng": -94.32405,
    "state_id": "MO"
  },
  {
    "zip": 64058,
    "lat": 39.17951,
    "lng": -94.30669,
    "state_id": "MO"
  },
  {
    "zip": 64060,
    "lat": 39.36858,
    "lng": -94.36458,
    "state_id": "MO"
  },
  {
    "zip": 64061,
    "lat": 38.78085,
    "lng": -94.08873,
    "state_id": "MO"
  },
  {
    "zip": 64062,
    "lat": 39.45339,
    "lng": -94.16986,
    "state_id": "MO"
  },
  {
    "zip": 64063,
    "lat": 38.912,
    "lng": -94.35166,
    "state_id": "MO"
  },
  {
    "zip": 64064,
    "lat": 38.97546,
    "lng": -94.3466,
    "state_id": "MO"
  },
  {
    "zip": 64065,
    "lat": 38.9529,
    "lng": -94.40575,
    "state_id": "MO"
  },
  {
    "zip": 64066,
    "lat": 39.1382,
    "lng": -94.12649,
    "state_id": "MO"
  },
  {
    "zip": 64067,
    "lat": 39.15592,
    "lng": -93.83557,
    "state_id": "MO"
  },
  {
    "zip": 64068,
    "lat": 39.25952,
    "lng": -94.38881,
    "state_id": "MO"
  },
  {
    "zip": 64070,
    "lat": 38.88836,
    "lng": -94.14858,
    "state_id": "MO"
  },
  {
    "zip": 64071,
    "lat": 39.02947,
    "lng": -93.83358,
    "state_id": "MO"
  },
  {
    "zip": 64072,
    "lat": 39.23946,
    "lng": -94.29343,
    "state_id": "MO"
  },
  {
    "zip": 64074,
    "lat": 39.08675,
    "lng": -94.0743,
    "state_id": "MO"
  },
  {
    "zip": 64075,
    "lat": 39.00029,
    "lng": -94.14525,
    "state_id": "MO"
  },
  {
    "zip": 64076,
    "lat": 38.98052,
    "lng": -93.94495,
    "state_id": "MO"
  },
  {
    "zip": 64077,
    "lat": 39.22356,
    "lng": -94.13028,
    "state_id": "MO"
  },
  {
    "zip": 64078,
    "lat": 38.70311,
    "lng": -94.45628,
    "state_id": "MO"
  },
  {
    "zip": 64079,
    "lat": 39.35777,
    "lng": -94.79601,
    "state_id": "MO"
  },
  {
    "zip": 64080,
    "lat": 38.76701,
    "lng": -94.25449,
    "state_id": "MO"
  },
  {
    "zip": 64081,
    "lat": 38.90705,
    "lng": -94.40411,
    "state_id": "MO"
  },
  {
    "zip": 64082,
    "lat": 38.86531,
    "lng": -94.40773,
    "state_id": "MO"
  },
  {
    "zip": 64083,
    "lat": 38.79971,
    "lng": -94.44728,
    "state_id": "MO"
  },
  {
    "zip": 64084,
    "lat": 39.38345,
    "lng": -94.06459,
    "state_id": "MO"
  },
  {
    "zip": 64085,
    "lat": 39.33449,
    "lng": -93.94571,
    "state_id": "MO"
  },
  {
    "zip": 64086,
    "lat": 38.90788,
    "lng": -94.28751,
    "state_id": "MO"
  },
  {
    "zip": 64088,
    "lat": 39.15841,
    "lng": -94.18443,
    "state_id": "MO"
  },
  {
    "zip": 64089,
    "lat": 39.39226,
    "lng": -94.56261,
    "state_id": "MO"
  },
  {
    "zip": 64090,
    "lat": 38.75934,
    "lng": -94.16359,
    "state_id": "MO"
  },
  {
    "zip": 64092,
    "lat": 39.21992,
    "lng": -94.80207,
    "state_id": "MO"
  },
  {
    "zip": 64093,
    "lat": 38.79479,
    "lng": -93.73174,
    "state_id": "MO"
  },
  {
    "zip": 64096,
    "lat": 39.20208,
    "lng": -93.5624,
    "state_id": "MO"
  },
  {
    "zip": 64097,
    "lat": 39.10855,
    "lng": -93.98641,
    "state_id": "MO"
  },
  {
    "zip": 64098,
    "lat": 39.45614,
    "lng": -94.89399,
    "state_id": "MO"
  },
  {
    "zip": 64101,
    "lat": 39.10362,
    "lng": -94.6006,
    "state_id": "MO"
  },
  {
    "zip": 64102,
    "lat": 39.09396,
    "lng": -94.60411,
    "state_id": "MO"
  },
  {
    "zip": 64105,
    "lat": 39.10477,
    "lng": -94.59104,
    "state_id": "MO"
  },
  {
    "zip": 64106,
    "lat": 39.10515,
    "lng": -94.57207,
    "state_id": "MO"
  },
  {
    "zip": 64108,
    "lat": 39.08422,
    "lng": -94.58485,
    "state_id": "MO"
  },
  {
    "zip": 64109,
    "lat": 39.06647,
    "lng": -94.56678,
    "state_id": "MO"
  },
  {
    "zip": 64110,
    "lat": 39.03442,
    "lng": -94.57283,
    "state_id": "MO"
  },
  {
    "zip": 64111,
    "lat": 39.05752,
    "lng": -94.5939,
    "state_id": "MO"
  },
  {
    "zip": 64112,
    "lat": 39.03598,
    "lng": -94.59529,
    "state_id": "MO"
  },
  {
    "zip": 64113,
    "lat": 39.01415,
    "lng": -94.5958,
    "state_id": "MO"
  },
  {
    "zip": 64114,
    "lat": 38.95797,
    "lng": -94.59722,
    "state_id": "MO"
  },
  {
    "zip": 64116,
    "lat": 39.14879,
    "lng": -94.57455,
    "state_id": "MO"
  },
  {
    "zip": 64117,
    "lat": 39.16523,
    "lng": -94.5229,
    "state_id": "MO"
  },
  {
    "zip": 64118,
    "lat": 39.213,
    "lng": -94.57474,
    "state_id": "MO"
  },
  {
    "zip": 64119,
    "lat": 39.20938,
    "lng": -94.51934,
    "state_id": "MO"
  },
  {
    "zip": 64120,
    "lat": 39.13092,
    "lng": -94.51526,
    "state_id": "MO"
  },
  {
    "zip": 64123,
    "lat": 39.11435,
    "lng": -94.52323,
    "state_id": "MO"
  },
  {
    "zip": 64124,
    "lat": 39.10721,
    "lng": -94.53905,
    "state_id": "MO"
  },
  {
    "zip": 64125,
    "lat": 39.10579,
    "lng": -94.49381,
    "state_id": "MO"
  },
  {
    "zip": 64126,
    "lat": 39.09042,
    "lng": -94.49617,
    "state_id": "MO"
  },
  {
    "zip": 64127,
    "lat": 39.08914,
    "lng": -94.53856,
    "state_id": "MO"
  },
  {
    "zip": 64128,
    "lat": 39.06557,
    "lng": -94.53462,
    "state_id": "MO"
  },
  {
    "zip": 64129,
    "lat": 39.04931,
    "lng": -94.4961,
    "state_id": "MO"
  },
  {
    "zip": 64130,
    "lat": 39.03372,
    "lng": -94.54077,
    "state_id": "MO"
  },
  {
    "zip": 64131,
    "lat": 38.96168,
    "lng": -94.57563,
    "state_id": "MO"
  },
  {
    "zip": 64132,
    "lat": 38.98676,
    "lng": -94.54164,
    "state_id": "MO"
  },
  {
    "zip": 64133,
    "lat": 39.01386,
    "lng": -94.45648,
    "state_id": "MO"
  },
  {
    "zip": 64134,
    "lat": 38.92948,
    "lng": -94.48674,
    "state_id": "MO"
  },
  {
    "zip": 64136,
    "lat": 39.01145,
    "lng": -94.40066,
    "state_id": "MO"
  },
  {
    "zip": 64137,
    "lat": 38.93525,
    "lng": -94.54269,
    "state_id": "MO"
  },
  {
    "zip": 64138,
    "lat": 38.9688,
    "lng": -94.47028,
    "state_id": "MO"
  },
  {
    "zip": 64139,
    "lat": 38.96676,
    "lng": -94.40884,
    "state_id": "MO"
  },
  {
    "zip": 64145,
    "lat": 38.8755,
    "lng": -94.59312,
    "state_id": "MO"
  },
  {
    "zip": 64146,
    "lat": 38.88773,
    "lng": -94.57334,
    "state_id": "MO"
  },
  {
    "zip": 64147,
    "lat": 38.85281,
    "lng": -94.54626,
    "state_id": "MO"
  },
  {
    "zip": 64149,
    "lat": 38.86258,
    "lng": -94.46538,
    "state_id": "MO"
  },
  {
    "zip": 64150,
    "lat": 39.17045,
    "lng": -94.63029,
    "state_id": "MO"
  },
  {
    "zip": 64151,
    "lat": 39.21489,
    "lng": -94.6311,
    "state_id": "MO"
  },
  {
    "zip": 64152,
    "lat": 39.21288,
    "lng": -94.72042,
    "state_id": "MO"
  },
  {
    "zip": 64153,
    "lat": 39.2805,
    "lng": -94.73228,
    "state_id": "MO"
  },
  {
    "zip": 64154,
    "lat": 39.27837,
    "lng": -94.63087,
    "state_id": "MO"
  },
  {
    "zip": 64155,
    "lat": 39.27699,
    "lng": -94.57682,
    "state_id": "MO"
  },
  {
    "zip": 64156,
    "lat": 39.27942,
    "lng": -94.52417,
    "state_id": "MO"
  },
  {
    "zip": 64157,
    "lat": 39.27616,
    "lng": -94.47186,
    "state_id": "MO"
  },
  {
    "zip": 64158,
    "lat": 39.23531,
    "lng": -94.48314,
    "state_id": "MO"
  },
  {
    "zip": 64161,
    "lat": 39.1619,
    "lng": -94.44958,
    "state_id": "MO"
  },
  {
    "zip": 64163,
    "lat": 39.33641,
    "lng": -94.69091,
    "state_id": "MO"
  },
  {
    "zip": 64164,
    "lat": 39.33093,
    "lng": -94.63239,
    "state_id": "MO"
  },
  {
    "zip": 64165,
    "lat": 39.32142,
    "lng": -94.57623,
    "state_id": "MO"
  },
  {
    "zip": 64166,
    "lat": 39.32207,
    "lng": -94.52303,
    "state_id": "MO"
  },
  {
    "zip": 64167,
    "lat": 39.3175,
    "lng": -94.483,
    "state_id": "MO"
  },
  {
    "zip": 64401,
    "lat": 39.63329,
    "lng": -94.71731,
    "state_id": "MO"
  },
  {
    "zip": 64402,
    "lat": 40.25757,
    "lng": -94.32789,
    "state_id": "MO"
  },
  {
    "zip": 64420,
    "lat": 40.48533,
    "lng": -94.28869,
    "state_id": "MO"
  },
  {
    "zip": 64421,
    "lat": 39.91297,
    "lng": -94.93341,
    "state_id": "MO"
  },
  {
    "zip": 64422,
    "lat": 39.8891,
    "lng": -94.49364,
    "state_id": "MO"
  },
  {
    "zip": 64423,
    "lat": 40.19007,
    "lng": -94.85584,
    "state_id": "MO"
  },
  {
    "zip": 64424,
    "lat": 40.26243,
    "lng": -94.03596,
    "state_id": "MO"
  },
  {
    "zip": 64426,
    "lat": 40.51885,
    "lng": -93.86528,
    "state_id": "MO"
  },
  {
    "zip": 64427,
    "lat": 40.11029,
    "lng": -94.83614,
    "state_id": "MO"
  },
  {
    "zip": 64428,
    "lat": 40.43684,
    "lng": -95.09476,
    "state_id": "MO"
  },
  {
    "zip": 64429,
    "lat": 39.7347,
    "lng": -94.22783,
    "state_id": "MO"
  },
  {
    "zip": 64430,
    "lat": 39.83721,
    "lng": -94.56632,
    "state_id": "MO"
  },
  {
    "zip": 64431,
    "lat": 40.52885,
    "lng": -94.99592,
    "state_id": "MO"
  },
  {
    "zip": 64432,
    "lat": 40.26367,
    "lng": -94.66858,
    "state_id": "MO"
  },
  {
    "zip": 64433,
    "lat": 40.24107,
    "lng": -94.68053,
    "state_id": "MO"
  },
  {
    "zip": 64434,
    "lat": 40.26189,
    "lng": -94.73352,
    "state_id": "MO"
  },
  {
    "zip": 64436,
    "lat": 39.85402,
    "lng": -94.69018,
    "state_id": "MO"
  },
  {
    "zip": 64437,
    "lat": 40.12858,
    "lng": -95.34692,
    "state_id": "MO"
  },
  {
    "zip": 64438,
    "lat": 40.15981,
    "lng": -94.41063,
    "state_id": "MO"
  },
  {
    "zip": 64439,
    "lat": 39.52363,
    "lng": -94.7543,
    "state_id": "MO"
  },
  {
    "zip": 64440,
    "lat": 39.57804,
    "lng": -94.91891,
    "state_id": "MO"
  },
  {
    "zip": 64441,
    "lat": 40.3982,
    "lng": -94.26605,
    "state_id": "MO"
  },
  {
    "zip": 64442,
    "lat": 40.49621,
    "lng": -94.00942,
    "state_id": "MO"
  },
  {
    "zip": 64443,
    "lat": 39.74677,
    "lng": -94.65231,
    "state_id": "MO"
  },
  {
    "zip": 64444,
    "lat": 39.48464,
    "lng": -94.63943,
    "state_id": "MO"
  },
  {
    "zip": 64445,
    "lat": 40.53533,
    "lng": -95.13881,
    "state_id": "MO"
  },
  {
    "zip": 64446,
    "lat": 40.3201,
    "lng": -95.42108,
    "state_id": "MO"
  },
  {
    "zip": 64448,
    "lat": 39.59986,
    "lng": -94.81899,
    "state_id": "MO"
  },
  {
    "zip": 64449,
    "lat": 40.04259,
    "lng": -94.97763,
    "state_id": "MO"
  },
  {
    "zip": 64451,
    "lat": 39.99561,
    "lng": -95.19634,
    "state_id": "MO"
  },
  {
    "zip": 64453,
    "lat": 40.34502,
    "lng": -94.48017,
    "state_id": "MO"
  },
  {
    "zip": 64454,
    "lat": 39.60274,
    "lng": -94.59642,
    "state_id": "MO"
  },
  {
    "zip": 64455,
    "lat": 40.18491,
    "lng": -95.00978,
    "state_id": "MO"
  },
  {
    "zip": 64456,
    "lat": 40.48962,
    "lng": -94.38704,
    "state_id": "MO"
  },
  {
    "zip": 64457,
    "lat": 40.16095,
    "lng": -94.68555,
    "state_id": "MO"
  },
  {
    "zip": 64458,
    "lat": 40.52077,
    "lng": -94.14305,
    "state_id": "MO"
  },
  {
    "zip": 64459,
    "lat": 39.92948,
    "lng": -94.64646,
    "state_id": "MO"
  },
  {
    "zip": 64461,
    "lat": 40.52234,
    "lng": -94.813,
    "state_id": "MO"
  },
  {
    "zip": 64463,
    "lat": 40.06282,
    "lng": -94.48256,
    "state_id": "MO"
  },
  {
    "zip": 64465,
    "lat": 39.54904,
    "lng": -94.29344,
    "state_id": "MO"
  },
  {
    "zip": 64466,
    "lat": 40.16542,
    "lng": -95.1073,
    "state_id": "MO"
  },
  {
    "zip": 64467,
    "lat": 40.40144,
    "lng": -94.16093,
    "state_id": "MO"
  },
  {
    "zip": 64468,
    "lat": 40.34392,
    "lng": -94.88079,
    "state_id": "MO"
  },
  {
    "zip": 64469,
    "lat": 39.92672,
    "lng": -94.36668,
    "state_id": "MO"
  },
  {
    "zip": 64470,
    "lat": 40.16899,
    "lng": -95.22393,
    "state_id": "MO"
  },
  {
    "zip": 64471,
    "lat": 40.25602,
    "lng": -94.19423,
    "state_id": "MO"
  },
  {
    "zip": 64473,
    "lat": 39.9743,
    "lng": -95.08302,
    "state_id": "MO"
  },
  {
    "zip": 64474,
    "lat": 39.75575,
    "lng": -94.38273,
    "state_id": "MO"
  },
  {
    "zip": 64475,
    "lat": 40.43887,
    "lng": -94.62615,
    "state_id": "MO"
  },
  {
    "zip": 64476,
    "lat": 40.45082,
    "lng": -94.83755,
    "state_id": "MO"
  },
  {
    "zip": 64477,
    "lat": 39.57363,
    "lng": -94.45624,
    "state_id": "MO"
  },
  {
    "zip": 64479,
    "lat": 40.35026,
    "lng": -94.66346,
    "state_id": "MO"
  },
  {
    "zip": 64480,
    "lat": 40.05947,
    "lng": -94.70838,
    "state_id": "MO"
  },
  {
    "zip": 64481,
    "lat": 40.35607,
    "lng": -93.8899,
    "state_id": "MO"
  },
  {
    "zip": 64482,
    "lat": 40.46298,
    "lng": -95.54198,
    "state_id": "MO"
  },
  {
    "zip": 64483,
    "lat": 40.04456,
    "lng": -94.83982,
    "state_id": "MO"
  },
  {
    "zip": 64484,
    "lat": 39.55668,
    "lng": -95.02036,
    "state_id": "MO"
  },
  {
    "zip": 64485,
    "lat": 39.95171,
    "lng": -94.81387,
    "state_id": "MO"
  },
  {
    "zip": 64486,
    "lat": 40.52908,
    "lng": -94.61867,
    "state_id": "MO"
  },
  {
    "zip": 64487,
    "lat": 40.30865,
    "lng": -95.10265,
    "state_id": "MO"
  },
  {
    "zip": 64489,
    "lat": 40.23307,
    "lng": -94.55553,
    "state_id": "MO"
  },
  {
    "zip": 64490,
    "lat": 39.73535,
    "lng": -94.51603,
    "state_id": "MO"
  },
  {
    "zip": 64491,
    "lat": 40.44831,
    "lng": -95.34493,
    "state_id": "MO"
  },
  {
    "zip": 64492,
    "lat": 39.47985,
    "lng": -94.53458,
    "state_id": "MO"
  },
  {
    "zip": 64493,
    "lat": 39.63503,
    "lng": -94.31814,
    "state_id": "MO"
  },
  {
    "zip": 64494,
    "lat": 39.99165,
    "lng": -94.61181,
    "state_id": "MO"
  },
  {
    "zip": 64496,
    "lat": 40.4807,
    "lng": -95.63994,
    "state_id": "MO"
  },
  {
    "zip": 64497,
    "lat": 39.92558,
    "lng": -94.21998,
    "state_id": "MO"
  },
  {
    "zip": 64498,
    "lat": 40.53957,
    "lng": -95.32196,
    "state_id": "MO"
  },
  {
    "zip": 64499,
    "lat": 40.39381,
    "lng": -94.43969,
    "state_id": "MO"
  },
  {
    "zip": 64501,
    "lat": 39.76563,
    "lng": -94.84462,
    "state_id": "MO"
  },
  {
    "zip": 64503,
    "lat": 39.74901,
    "lng": -94.84367,
    "state_id": "MO"
  },
  {
    "zip": 64504,
    "lat": 39.68622,
    "lng": -94.91344,
    "state_id": "MO"
  },
  {
    "zip": 64505,
    "lat": 39.8443,
    "lng": -94.834,
    "state_id": "MO"
  },
  {
    "zip": 64506,
    "lat": 39.78973,
    "lng": -94.80172,
    "state_id": "MO"
  },
  {
    "zip": 64507,
    "lat": 39.72863,
    "lng": -94.75993,
    "state_id": "MO"
  },
  {
    "zip": 64601,
    "lat": 39.80692,
    "lng": -93.57647,
    "state_id": "MO"
  },
  {
    "zip": 64620,
    "lat": 39.896,
    "lng": -94.08971,
    "state_id": "MO"
  },
  {
    "zip": 64622,
    "lat": 39.49516,
    "lng": -93.54637,
    "state_id": "MO"
  },
  {
    "zip": 64623,
    "lat": 39.47035,
    "lng": -93.33376,
    "state_id": "MO"
  },
  {
    "zip": 64624,
    "lat": 39.56841,
    "lng": -93.77766,
    "state_id": "MO"
  },
  {
    "zip": 64625,
    "lat": 39.73949,
    "lng": -93.80706,
    "state_id": "MO"
  },
  {
    "zip": 64628,
    "lat": 39.79719,
    "lng": -93.04697,
    "state_id": "MO"
  },
  {
    "zip": 64630,
    "lat": 40.03879,
    "lng": -93.15518,
    "state_id": "MO"
  },
  {
    "zip": 64631,
    "lat": 39.81973,
    "lng": -92.8743,
    "state_id": "MO"
  },
  {
    "zip": 64632,
    "lat": 40.47075,
    "lng": -93.76667,
    "state_id": "MO"
  },
  {
    "zip": 64633,
    "lat": 39.35829,
    "lng": -93.4837,
    "state_id": "MO"
  },
  {
    "zip": 64635,
    "lat": 39.93785,
    "lng": -93.44438,
    "state_id": "MO"
  },
  {
    "zip": 64636,
    "lat": 40.11023,
    "lng": -93.98127,
    "state_id": "MO"
  },
  {
    "zip": 64637,
    "lat": 39.5617,
    "lng": -93.91593,
    "state_id": "MO"
  },
  {
    "zip": 64638,
    "lat": 39.61963,
    "lng": -93.61336,
    "state_id": "MO"
  },
  {
    "zip": 64639,
    "lat": 39.39201,
    "lng": -93.22301,
    "state_id": "MO"
  },
  {
    "zip": 64640,
    "lat": 39.89844,
    "lng": -93.94498,
    "state_id": "MO"
  },
  {
    "zip": 64641,
    "lat": 40.17743,
    "lng": -93.39901,
    "state_id": "MO"
  },
  {
    "zip": 64642,
    "lat": 40.17184,
    "lng": -93.83148,
    "state_id": "MO"
  },
  {
    "zip": 64643,
    "lat": 39.61359,
    "lng": -93.37004,
    "state_id": "MO"
  },
  {
    "zip": 64644,
    "lat": 39.73591,
    "lng": -93.97284,
    "state_id": "MO"
  },
  {
    "zip": 64645,
    "lat": 40.29742,
    "lng": -93.32736,
    "state_id": "MO"
  },
  {
    "zip": 64646,
    "lat": 40.09789,
    "lng": -93.31383,
    "state_id": "MO"
  },
  {
    "zip": 64647,
    "lat": 40.026,
    "lng": -93.97593,
    "state_id": "MO"
  },
  {
    "zip": 64648,
    "lat": 39.99021,
    "lng": -93.80216,
    "state_id": "MO"
  },
  {
    "zip": 64649,
    "lat": 39.77493,
    "lng": -94.08762,
    "state_id": "MO"
  },
  {
    "zip": 64650,
    "lat": 39.63486,
    "lng": -94.05983,
    "state_id": "MO"
  },
  {
    "zip": 64651,
    "lat": 39.76933,
    "lng": -93.18582,
    "state_id": "MO"
  },
  {
    "zip": 64652,
    "lat": 40.02449,
    "lng": -93.42406,
    "state_id": "MO"
  },
  {
    "zip": 64653,
    "lat": 39.9003,
    "lng": -93.21048,
    "state_id": "MO"
  },
  {
    "zip": 64654,
    "lat": 39.83446,
    "lng": -93.77259,
    "state_id": "MO"
  },
  {
    "zip": 64655,
    "lat": 40.45067,
    "lng": -93.24657,
    "state_id": "MO"
  },
  {
    "zip": 64656,
    "lat": 39.67743,
    "lng": -93.69268,
    "state_id": "MO"
  },
  {
    "zip": 64657,
    "lat": 40.12602,
    "lng": -94.21992,
    "state_id": "MO"
  },
  {
    "zip": 64658,
    "lat": 39.66687,
    "lng": -92.92362,
    "state_id": "MO"
  },
  {
    "zip": 64659,
    "lat": 39.78453,
    "lng": -93.29795,
    "state_id": "MO"
  },
  {
    "zip": 64660,
    "lat": 39.5709,
    "lng": -93.09382,
    "state_id": "MO"
  },
  {
    "zip": 64661,
    "lat": 40.53062,
    "lng": -93.51266,
    "state_id": "MO"
  },
  {
    "zip": 64664,
    "lat": 39.74432,
    "lng": -93.66499,
    "state_id": "MO"
  },
  {
    "zip": 64667,
    "lat": 40.38502,
    "lng": -93.33481,
    "state_id": "MO"
  },
  {
    "zip": 64668,
    "lat": 39.35102,
    "lng": -93.70209,
    "state_id": "MO"
  },
  {
    "zip": 64670,
    "lat": 40.04813,
    "lng": -94.14156,
    "state_id": "MO"
  },
  {
    "zip": 64671,
    "lat": 39.53647,
    "lng": -94.05858,
    "state_id": "MO"
  },
  {
    "zip": 64672,
    "lat": 40.54034,
    "lng": -93.24142,
    "state_id": "MO"
  },
  {
    "zip": 64673,
    "lat": 40.37745,
    "lng": -93.58664,
    "state_id": "MO"
  },
  {
    "zip": 64674,
    "lat": 39.96709,
    "lng": -93.13951,
    "state_id": "MO"
  },
  {
    "zip": 64676,
    "lat": 39.64499,
    "lng": -93.05431,
    "state_id": "MO"
  },
  {
    "zip": 64679,
    "lat": 40.23547,
    "lng": -93.58484,
    "state_id": "MO"
  },
  {
    "zip": 64681,
    "lat": 39.64778,
    "lng": -93.22159,
    "state_id": "MO"
  },
  {
    "zip": 64682,
    "lat": 39.55053,
    "lng": -93.49355,
    "state_id": "MO"
  },
  {
    "zip": 64683,
    "lat": 40.07736,
    "lng": -93.60167,
    "state_id": "MO"
  },
  {
    "zip": 64686,
    "lat": 39.7503,
    "lng": -93.62435,
    "state_id": "MO"
  },
  {
    "zip": 64688,
    "lat": 39.81567,
    "lng": -93.38263,
    "state_id": "MO"
  },
  {
    "zip": 64689,
    "lat": 39.87366,
    "lng": -94.14815,
    "state_id": "MO"
  },
  {
    "zip": 64701,
    "lat": 38.61854,
    "lng": -94.33351,
    "state_id": "MO"
  },
  {
    "zip": 64720,
    "lat": 38.40491,
    "lng": -94.34314,
    "state_id": "MO"
  },
  {
    "zip": 64722,
    "lat": 38.27813,
    "lng": -94.55032,
    "state_id": "MO"
  },
  {
    "zip": 64723,
    "lat": 38.36334,
    "lng": -94.55862,
    "state_id": "MO"
  },
  {
    "zip": 64724,
    "lat": 38.15135,
    "lng": -94.0112,
    "state_id": "MO"
  },
  {
    "zip": 64725,
    "lat": 38.49732,
    "lng": -94.36973,
    "state_id": "MO"
  },
  {
    "zip": 64726,
    "lat": 38.53186,
    "lng": -93.92214,
    "state_id": "MO"
  },
  {
    "zip": 64728,
    "lat": 37.70263,
    "lng": -94.52365,
    "state_id": "MO"
  },
  {
    "zip": 64730,
    "lat": 38.25758,
    "lng": -94.29645,
    "state_id": "MO"
  },
  {
    "zip": 64733,
    "lat": 38.58476,
    "lng": -93.82866,
    "state_id": "MO"
  },
  {
    "zip": 64734,
    "lat": 38.65224,
    "lng": -94.57284,
    "state_id": "MO"
  },
  {
    "zip": 64735,
    "lat": 38.36745,
    "lng": -93.74306,
    "state_id": "MO"
  },
  {
    "zip": 64738,
    "lat": 37.90138,
    "lng": -93.65931,
    "state_id": "MO"
  },
  {
    "zip": 64739,
    "lat": 38.51138,
    "lng": -94.0946,
    "state_id": "MO"
  },
  {
    "zip": 64740,
    "lat": 38.22554,
    "lng": -93.71441,
    "state_id": "MO"
  },
  {
    "zip": 64741,
    "lat": 37.81971,
    "lng": -94.56087,
    "state_id": "MO"
  },
  {
    "zip": 64742,
    "lat": 38.49889,
    "lng": -94.54467,
    "state_id": "MO"
  },
  {
    "zip": 64743,
    "lat": 38.66841,
    "lng": -94.23241,
    "state_id": "MO"
  },
  {
    "zip": 64744,
    "lat": 37.85012,
    "lng": -93.97884,
    "state_id": "MO"
  },
  {
    "zip": 64745,
    "lat": 38.16687,
    "lng": -94.49647,
    "state_id": "MO"
  },
  {
    "zip": 64746,
    "lat": 38.60941,
    "lng": -94.49337,
    "state_id": "MO"
  },
  {
    "zip": 64747,
    "lat": 38.57308,
    "lng": -94.17686,
    "state_id": "MO"
  },
  {
    "zip": 64748,
    "lat": 37.35698,
    "lng": -94.09565,
    "state_id": "MO"
  },
  {
    "zip": 64750,
    "lat": 37.93546,
    "lng": -94.11501,
    "state_id": "MO"
  },
  {
    "zip": 64752,
    "lat": 38.06547,
    "lng": -94.56768,
    "state_id": "MO"
  },
  {
    "zip": 64755,
    "lat": 37.33718,
    "lng": -94.3094,
    "state_id": "MO"
  },
  {
    "zip": 64756,
    "lat": 37.61824,
    "lng": -94.01481,
    "state_id": "MO"
  },
  {
    "zip": 64759,
    "lat": 37.52501,
    "lng": -94.26299,
    "state_id": "MO"
  },
  {
    "zip": 64761,
    "lat": 38.59201,
    "lng": -93.68867,
    "state_id": "MO"
  },
  {
    "zip": 64762,
    "lat": 37.54772,
    "lng": -94.50037,
    "state_id": "MO"
  },
  {
    "zip": 64763,
    "lat": 38.14468,
    "lng": -93.70169,
    "state_id": "MO"
  },
  {
    "zip": 64765,
    "lat": 37.99622,
    "lng": -94.44377,
    "state_id": "MO"
  },
  {
    "zip": 64767,
    "lat": 37.75003,
    "lng": -94.20064,
    "state_id": "MO"
  },
  {
    "zip": 64769,
    "lat": 37.4823,
    "lng": -94.56886,
    "state_id": "MO"
  },
  {
    "zip": 64770,
    "lat": 38.27222,
    "lng": -93.98536,
    "state_id": "MO"
  },
  {
    "zip": 64771,
    "lat": 37.76056,
    "lng": -94.45925,
    "state_id": "MO"
  },
  {
    "zip": 64772,
    "lat": 37.82945,
    "lng": -94.33172,
    "state_id": "MO"
  },
  {
    "zip": 64776,
    "lat": 38.04348,
    "lng": -93.66572,
    "state_id": "MO"
  },
  {
    "zip": 64778,
    "lat": 37.92405,
    "lng": -94.48263,
    "state_id": "MO"
  },
  {
    "zip": 64779,
    "lat": 38.09377,
    "lng": -94.40442,
    "state_id": "MO"
  },
  {
    "zip": 64780,
    "lat": 38.06167,
    "lng": -94.0458,
    "state_id": "MO"
  },
  {
    "zip": 64781,
    "lat": 37.98077,
    "lng": -93.81427,
    "state_id": "MO"
  },
  {
    "zip": 64783,
    "lat": 38.00467,
    "lng": -94.11222,
    "state_id": "MO"
  },
  {
    "zip": 64784,
    "lat": 37.66688,
    "lng": -94.23413,
    "state_id": "MO"
  },
  {
    "zip": 64788,
    "lat": 38.42971,
    "lng": -94.02696,
    "state_id": "MO"
  },
  {
    "zip": 64790,
    "lat": 37.93016,
    "lng": -94.22666,
    "state_id": "MO"
  },
  {
    "zip": 64801,
    "lat": 37.11012,
    "lng": -94.49639,
    "state_id": "MO"
  },
  {
    "zip": 64804,
    "lat": 37.02091,
    "lng": -94.50993,
    "state_id": "MO"
  },
  {
    "zip": 64830,
    "lat": 37.23137,
    "lng": -94.41035,
    "state_id": "MO"
  },
  {
    "zip": 64831,
    "lat": 36.65661,
    "lng": -94.4565,
    "state_id": "MO"
  },
  {
    "zip": 64832,
    "lat": 37.33984,
    "lng": -94.57194,
    "state_id": "MO"
  },
  {
    "zip": 64833,
    "lat": 37.1938,
    "lng": -94.12982,
    "state_id": "MO"
  },
  {
    "zip": 64834,
    "lat": 37.18771,
    "lng": -94.56871,
    "state_id": "MO"
  },
  {
    "zip": 64835,
    "lat": 37.14841,
    "lng": -94.43741,
    "state_id": "MO"
  },
  {
    "zip": 64836,
    "lat": 37.18743,
    "lng": -94.28787,
    "state_id": "MO"
  },
  {
    "zip": 64840,
    "lat": 37.01818,
    "lng": -94.33319,
    "state_id": "MO"
  },
  {
    "zip": 64841,
    "lat": 37.07829,
    "lng": -94.41671,
    "state_id": "MO"
  },
  {
    "zip": 64842,
    "lat": 36.79104,
    "lng": -94.10446,
    "state_id": "MO"
  },
  {
    "zip": 64843,
    "lat": 36.73389,
    "lng": -94.44391,
    "state_id": "MO"
  },
  {
    "zip": 64844,
    "lat": 36.91535,
    "lng": -94.23901,
    "state_id": "MO"
  },
  {
    "zip": 64847,
    "lat": 36.59401,
    "lng": -94.44101,
    "state_id": "MO"
  },
  {
    "zip": 64848,
    "lat": 37.17523,
    "lng": -93.99143,
    "state_id": "MO"
  },
  {
    "zip": 64849,
    "lat": 37.25491,
    "lng": -94.44487,
    "state_id": "MO"
  },
  {
    "zip": 64850,
    "lat": 36.86088,
    "lng": -94.39835,
    "state_id": "MO"
  },
  {
    "zip": 64854,
    "lat": 36.54356,
    "lng": -94.45029,
    "state_id": "MO"
  },
  {
    "zip": 64855,
    "lat": 37.29597,
    "lng": -94.48582,
    "state_id": "MO"
  },
  {
    "zip": 64856,
    "lat": 36.56461,
    "lng": -94.26665,
    "state_id": "MO"
  },
  {
    "zip": 64857,
    "lat": 37.24587,
    "lng": -94.43862,
    "state_id": "MO"
  },
  {
    "zip": 64858,
    "lat": 36.90012,
    "lng": -94.53001,
    "state_id": "MO"
  },
  {
    "zip": 64859,
    "lat": 37.15956,
    "lng": -94.14708,
    "state_id": "MO"
  },
  {
    "zip": 64861,
    "lat": 36.69772,
    "lng": -94.1485,
    "state_id": "MO"
  },
  {
    "zip": 64862,
    "lat": 37.09739,
    "lng": -94.11431,
    "state_id": "MO"
  },
  {
    "zip": 64863,
    "lat": 36.55556,
    "lng": -94.58445,
    "state_id": "MO"
  },
  {
    "zip": 64865,
    "lat": 36.84081,
    "lng": -94.57817,
    "state_id": "MO"
  },
  {
    "zip": 64866,
    "lat": 36.86888,
    "lng": -94.1422,
    "state_id": "MO"
  },
  {
    "zip": 64867,
    "lat": 36.72965,
    "lng": -94.23452,
    "state_id": "MO"
  },
  {
    "zip": 64870,
    "lat": 37.18523,
    "lng": -94.48113,
    "state_id": "MO"
  },
  {
    "zip": 64873,
    "lat": 37.01766,
    "lng": -94.05146,
    "state_id": "MO"
  },
  {
    "zip": 64874,
    "lat": 36.75832,
    "lng": -94.05496,
    "state_id": "MO"
  },
  {
    "zip": 65001,
    "lat": 38.29067,
    "lng": -92.01086,
    "state_id": "MO"
  },
  {
    "zip": 65010,
    "lat": 38.79164,
    "lng": -92.24033,
    "state_id": "MO"
  },
  {
    "zip": 65011,
    "lat": 38.39176,
    "lng": -92.71199,
    "state_id": "MO"
  },
  {
    "zip": 65013,
    "lat": 38.27532,
    "lng": -91.74974,
    "state_id": "MO"
  },
  {
    "zip": 65014,
    "lat": 38.31338,
    "lng": -91.62565,
    "state_id": "MO"
  },
  {
    "zip": 65016,
    "lat": 38.56229,
    "lng": -91.92075,
    "state_id": "MO"
  },
  {
    "zip": 65017,
    "lat": 38.09075,
    "lng": -92.49776,
    "state_id": "MO"
  },
  {
    "zip": 65018,
    "lat": 38.62052,
    "lng": -92.5632,
    "state_id": "MO"
  },
  {
    "zip": 65020,
    "lat": 38.00441,
    "lng": -92.77976,
    "state_id": "MO"
  },
  {
    "zip": 65023,
    "lat": 38.6525,
    "lng": -92.39909,
    "state_id": "MO"
  },
  {
    "zip": 65024,
    "lat": 38.62273,
    "lng": -91.7557,
    "state_id": "MO"
  },
  {
    "zip": 65025,
    "lat": 38.67035,
    "lng": -92.6875,
    "state_id": "MO"
  },
  {
    "zip": 65026,
    "lat": 38.32084,
    "lng": -92.57113,
    "state_id": "MO"
  },
  {
    "zip": 65032,
    "lat": 38.3424,
    "lng": -92.38235,
    "state_id": "MO"
  },
  {
    "zip": 65034,
    "lat": 38.55832,
    "lng": -92.81969,
    "state_id": "MO"
  },
  {
    "zip": 65035,
    "lat": 38.36227,
    "lng": -91.93258,
    "state_id": "MO"
  },
  {
    "zip": 65037,
    "lat": 38.23784,
    "lng": -92.85696,
    "state_id": "MO"
  },
  {
    "zip": 65039,
    "lat": 38.71067,
    "lng": -92.28722,
    "state_id": "MO"
  },
  {
    "zip": 65040,
    "lat": 38.35991,
    "lng": -92.31102,
    "state_id": "MO"
  },
  {
    "zip": 65041,
    "lat": 38.64269,
    "lng": -91.4766,
    "state_id": "MO"
  },
  {
    "zip": 65043,
    "lat": 38.62483,
    "lng": -92.10538,
    "state_id": "MO"
  },
  {
    "zip": 65046,
    "lat": 38.77836,
    "lng": -92.48099,
    "state_id": "MO"
  },
  {
    "zip": 65047,
    "lat": 38.16157,
    "lng": -92.57225,
    "state_id": "MO"
  },
  {
    "zip": 65048,
    "lat": 38.3615,
    "lng": -92.04676,
    "state_id": "MO"
  },
  {
    "zip": 65049,
    "lat": 38.20032,
    "lng": -92.66842,
    "state_id": "MO"
  },
  {
    "zip": 65050,
    "lat": 38.54271,
    "lng": -92.6855,
    "state_id": "MO"
  },
  {
    "zip": 65051,
    "lat": 38.46843,
    "lng": -91.79341,
    "state_id": "MO"
  },
  {
    "zip": 65052,
    "lat": 38.04943,
    "lng": -92.65326,
    "state_id": "MO"
  },
  {
    "zip": 65053,
    "lat": 38.54132,
    "lng": -92.37149,
    "state_id": "MO"
  },
  {
    "zip": 65054,
    "lat": 38.48628,
    "lng": -91.93837,
    "state_id": "MO"
  },
  {
    "zip": 65058,
    "lat": 38.25329,
    "lng": -92.13825,
    "state_id": "MO"
  },
  {
    "zip": 65059,
    "lat": 38.69865,
    "lng": -91.87038,
    "state_id": "MO"
  },
  {
    "zip": 65061,
    "lat": 38.60041,
    "lng": -91.63667,
    "state_id": "MO"
  },
  {
    "zip": 65062,
    "lat": 38.49554,
    "lng": -91.65139,
    "state_id": "MO"
  },
  {
    "zip": 65063,
    "lat": 38.72442,
    "lng": -92.08671,
    "state_id": "MO"
  },
  {
    "zip": 65064,
    "lat": 38.40778,
    "lng": -92.47683,
    "state_id": "MO"
  },
  {
    "zip": 65065,
    "lat": 38.13304,
    "lng": -92.66885,
    "state_id": "MO"
  },
  {
    "zip": 65066,
    "lat": 38.34963,
    "lng": -91.48834,
    "state_id": "MO"
  },
  {
    "zip": 65067,
    "lat": 38.76451,
    "lng": -91.71318,
    "state_id": "MO"
  },
  {
    "zip": 65068,
    "lat": 38.82174,
    "lng": -92.61111,
    "state_id": "MO"
  },
  {
    "zip": 65069,
    "lat": 38.75374,
    "lng": -91.59182,
    "state_id": "MO"
  },
  {
    "zip": 65072,
    "lat": 38.26782,
    "lng": -92.72308,
    "state_id": "MO"
  },
  {
    "zip": 65074,
    "lat": 38.47933,
    "lng": -92.47518,
    "state_id": "MO"
  },
  {
    "zip": 65075,
    "lat": 38.24999,
    "lng": -92.26608,
    "state_id": "MO"
  },
  {
    "zip": 65076,
    "lat": 38.37131,
    "lng": -92.19443,
    "state_id": "MO"
  },
  {
    "zip": 65077,
    "lat": 38.76606,
    "lng": -91.80319,
    "state_id": "MO"
  },
  {
    "zip": 65078,
    "lat": 38.39063,
    "lng": -93.00787,
    "state_id": "MO"
  },
  {
    "zip": 65079,
    "lat": 38.15232,
    "lng": -92.74824,
    "state_id": "MO"
  },
  {
    "zip": 65080,
    "lat": 38.63071,
    "lng": -91.97905,
    "state_id": "MO"
  },
  {
    "zip": 65081,
    "lat": 38.64416,
    "lng": -92.78274,
    "state_id": "MO"
  },
  {
    "zip": 65082,
    "lat": 38.22461,
    "lng": -92.42647,
    "state_id": "MO"
  },
  {
    "zip": 65083,
    "lat": 38.14191,
    "lng": -92.43972,
    "state_id": "MO"
  },
  {
    "zip": 65084,
    "lat": 38.43573,
    "lng": -92.8534,
    "state_id": "MO"
  },
  {
    "zip": 65085,
    "lat": 38.40179,
    "lng": -92.07865,
    "state_id": "MO"
  },
  {
    "zip": 65101,
    "lat": 38.49167,
    "lng": -92.14999,
    "state_id": "MO"
  },
  {
    "zip": 65109,
    "lat": 38.57328,
    "lng": -92.27874,
    "state_id": "MO"
  },
  {
    "zip": 65201,
    "lat": 38.8982,
    "lng": -92.24359,
    "state_id": "MO"
  },
  {
    "zip": 65202,
    "lat": 39.02051,
    "lng": -92.29823,
    "state_id": "MO"
  },
  {
    "zip": 65203,
    "lat": 38.88518,
    "lng": -92.397,
    "state_id": "MO"
  },
  {
    "zip": 65215,
    "lat": 38.95317,
    "lng": -92.32085,
    "state_id": "MO"
  },
  {
    "zip": 65230,
    "lat": 39.28717,
    "lng": -92.69501,
    "state_id": "MO"
  },
  {
    "zip": 65231,
    "lat": 39.01631,
    "lng": -91.89797,
    "state_id": "MO"
  },
  {
    "zip": 65232,
    "lat": 39.16329,
    "lng": -91.75384,
    "state_id": "MO"
  },
  {
    "zip": 65233,
    "lat": 38.91429,
    "lng": -92.72912,
    "state_id": "MO"
  },
  {
    "zip": 65236,
    "lat": 39.44205,
    "lng": -93.07933,
    "state_id": "MO"
  },
  {
    "zip": 65237,
    "lat": 38.7796,
    "lng": -92.78736,
    "state_id": "MO"
  },
  {
    "zip": 65239,
    "lat": 39.53368,
    "lng": -92.43032,
    "state_id": "MO"
  },
  {
    "zip": 65240,
    "lat": 39.22243,
    "lng": -92.12569,
    "state_id": "MO"
  },
  {
    "zip": 65243,
    "lat": 39.27542,
    "lng": -92.35509,
    "state_id": "MO"
  },
  {
    "zip": 65244,
    "lat": 39.48158,
    "lng": -92.66167,
    "state_id": "MO"
  },
  {
    "zip": 65246,
    "lat": 39.37866,
    "lng": -92.99577,
    "state_id": "MO"
  },
  {
    "zip": 65247,
    "lat": 39.63625,
    "lng": -92.47236,
    "state_id": "MO"
  },
  {
    "zip": 65248,
    "lat": 39.14088,
    "lng": -92.65599,
    "state_id": "MO"
  },
  {
    "zip": 65250,
    "lat": 39.03392,
    "lng": -92.83648,
    "state_id": "MO"
  },
  {
    "zip": 65251,
    "lat": 38.8471,
    "lng": -91.97838,
    "state_id": "MO"
  },
  {
    "zip": 65254,
    "lat": 39.2144,
    "lng": -92.85141,
    "state_id": "MO"
  },
  {
    "zip": 65255,
    "lat": 39.09612,
    "lng": -92.22951,
    "state_id": "MO"
  },
  {
    "zip": 65256,
    "lat": 39.13062,
    "lng": -92.4594,
    "state_id": "MO"
  },
  {
    "zip": 65257,
    "lat": 39.28665,
    "lng": -92.53204,
    "state_id": "MO"
  },
  {
    "zip": 65258,
    "lat": 39.52302,
    "lng": -92.1334,
    "state_id": "MO"
  },
  {
    "zip": 65259,
    "lat": 39.47595,
    "lng": -92.56932,
    "state_id": "MO"
  },
  {
    "zip": 65260,
    "lat": 39.58659,
    "lng": -92.39629,
    "state_id": "MO"
  },
  {
    "zip": 65261,
    "lat": 39.47343,
    "lng": -92.92956,
    "state_id": "MO"
  },
  {
    "zip": 65262,
    "lat": 38.95338,
    "lng": -91.94408,
    "state_id": "MO"
  },
  {
    "zip": 65263,
    "lat": 39.45684,
    "lng": -92.22348,
    "state_id": "MO"
  },
  {
    "zip": 65264,
    "lat": 39.08764,
    "lng": -91.69254,
    "state_id": "MO"
  },
  {
    "zip": 65265,
    "lat": 39.20145,
    "lng": -91.88456,
    "state_id": "MO"
  },
  {
    "zip": 65270,
    "lat": 39.41515,
    "lng": -92.4028,
    "state_id": "MO"
  },
  {
    "zip": 65274,
    "lat": 39.01676,
    "lng": -92.68017,
    "state_id": "MO"
  },
  {
    "zip": 65275,
    "lat": 39.46276,
    "lng": -91.99251,
    "state_id": "MO"
  },
  {
    "zip": 65276,
    "lat": 38.84759,
    "lng": -92.95355,
    "state_id": "MO"
  },
  {
    "zip": 65278,
    "lat": 39.34161,
    "lng": -92.4107,
    "state_id": "MO"
  },
  {
    "zip": 65279,
    "lat": 39.00961,
    "lng": -92.5187,
    "state_id": "MO"
  },
  {
    "zip": 65280,
    "lat": 39.23904,
    "lng": -91.73768,
    "state_id": "MO"
  },
  {
    "zip": 65281,
    "lat": 39.46796,
    "lng": -92.79502,
    "state_id": "MO"
  },
  {
    "zip": 65282,
    "lat": 39.38661,
    "lng": -91.83066,
    "state_id": "MO"
  },
  {
    "zip": 65283,
    "lat": 39.50838,
    "lng": -91.83494,
    "state_id": "MO"
  },
  {
    "zip": 65284,
    "lat": 39.20063,
    "lng": -92.2969,
    "state_id": "MO"
  },
  {
    "zip": 65285,
    "lat": 39.19964,
    "lng": -92.01746,
    "state_id": "MO"
  },
  {
    "zip": 65286,
    "lat": 39.50926,
    "lng": -93.21896,
    "state_id": "MO"
  },
  {
    "zip": 65287,
    "lat": 38.88948,
    "lng": -92.52653,
    "state_id": "MO"
  },
  {
    "zip": 65301,
    "lat": 38.69843,
    "lng": -93.22615,
    "state_id": "MO"
  },
  {
    "zip": 65305,
    "lat": 38.72939,
    "lng": -93.55446,
    "state_id": "MO"
  },
  {
    "zip": 65320,
    "lat": 39.06997,
    "lng": -92.94723,
    "state_id": "MO"
  },
  {
    "zip": 65321,
    "lat": 39.08691,
    "lng": -93.45976,
    "state_id": "MO"
  },
  {
    "zip": 65322,
    "lat": 38.9786,
    "lng": -92.95008,
    "state_id": "MO"
  },
  {
    "zip": 65323,
    "lat": 38.44724,
    "lng": -93.61594,
    "state_id": "MO"
  },
  {
    "zip": 65324,
    "lat": 38.11557,
    "lng": -93.00095,
    "state_id": "MO"
  },
  {
    "zip": 65325,
    "lat": 38.43541,
    "lng": -93.18041,
    "state_id": "MO"
  },
  {
    "zip": 65326,
    "lat": 38.16736,
    "lng": -93.14362,
    "state_id": "MO"
  },
  {
    "zip": 65327,
    "lat": 38.97373,
    "lng": -93.49528,
    "state_id": "MO"
  },
  {
    "zip": 65329,
    "lat": 38.61586,
    "lng": -92.95996,
    "state_id": "MO"
  },
  {
    "zip": 65330,
    "lat": 39.21845,
    "lng": -92.94793,
    "state_id": "MO"
  },
  {
    "zip": 65332,
    "lat": 38.62181,
    "lng": -93.41383,
    "state_id": "MO"
  },
  {
    "zip": 65333,
    "lat": 38.90712,
    "lng": -93.3047,
    "state_id": "MO"
  },
  {
    "zip": 65334,
    "lat": 38.8469,
    "lng": -93.22748,
    "state_id": "MO"
  },
  {
    "zip": 65335,
    "lat": 38.50529,
    "lng": -93.34264,
    "state_id": "MO"
  },
  {
    "zip": 65336,
    "lat": 38.78331,
    "lng": -93.56732,
    "state_id": "MO"
  },
  {
    "zip": 65337,
    "lat": 38.79111,
    "lng": -93.43048,
    "state_id": "MO"
  },
  {
    "zip": 65338,
    "lat": 38.36078,
    "lng": -93.28153,
    "state_id": "MO"
  },
  {
    "zip": 65339,
    "lat": 39.19658,
    "lng": -93.38152,
    "state_id": "MO"
  },
  {
    "zip": 65340,
    "lat": 39.09557,
    "lng": -93.17882,
    "state_id": "MO"
  },
  {
    "zip": 65344,
    "lat": 39.31184,
    "lng": -93.19745,
    "state_id": "MO"
  },
  {
    "zip": 65345,
    "lat": 38.5404,
    "lng": -93.13023,
    "state_id": "MO"
  },
  {
    "zip": 65347,
    "lat": 38.98765,
    "lng": -93.04191,
    "state_id": "MO"
  },
  {
    "zip": 65348,
    "lat": 38.71295,
    "lng": -92.98034,
    "state_id": "MO"
  },
  {
    "zip": 65349,
    "lat": 39.23206,
    "lng": -93.04875,
    "state_id": "MO"
  },
  {
    "zip": 65350,
    "lat": 38.64768,
    "lng": -93.09531,
    "state_id": "MO"
  },
  {
    "zip": 65351,
    "lat": 38.9868,
    "lng": -93.38845,
    "state_id": "MO"
  },
  {
    "zip": 65354,
    "lat": 38.64544,
    "lng": -92.88504,
    "state_id": "MO"
  },
  {
    "zip": 65355,
    "lat": 38.22005,
    "lng": -93.36821,
    "state_id": "MO"
  },
  {
    "zip": 65360,
    "lat": 38.51961,
    "lng": -93.51416,
    "state_id": "MO"
  },
  {
    "zip": 65401,
    "lat": 37.90464,
    "lng": -91.76534,
    "state_id": "MO"
  },
  {
    "zip": 65436,
    "lat": 37.62362,
    "lng": -91.97145,
    "state_id": "MO"
  },
  {
    "zip": 65438,
    "lat": 36.92524,
    "lng": -91.50253,
    "state_id": "MO"
  },
  {
    "zip": 65439,
    "lat": 37.65011,
    "lng": -91.10159,
    "state_id": "MO"
  },
  {
    "zip": 65440,
    "lat": 37.61185,
    "lng": -91.17846,
    "state_id": "MO"
  },
  {
    "zip": 65441,
    "lat": 38.08959,
    "lng": -91.1562,
    "state_id": "MO"
  },
  {
    "zip": 65443,
    "lat": 38.12956,
    "lng": -92.09897,
    "state_id": "MO"
  },
  {
    "zip": 65444,
    "lat": 37.38089,
    "lng": -92.05625,
    "state_id": "MO"
  },
  {
    "zip": 65446,
    "lat": 37.78477,
    "lng": -91.26923,
    "state_id": "MO"
  },
  {
    "zip": 65449,
    "lat": 37.84803,
    "lng": -91.48679,
    "state_id": "MO"
  },
  {
    "zip": 65452,
    "lat": 37.96296,
    "lng": -92.26775,
    "state_id": "MO"
  },
  {
    "zip": 65453,
    "lat": 38.09615,
    "lng": -91.42882,
    "state_id": "MO"
  },
  {
    "zip": 65456,
    "lat": 37.78121,
    "lng": -91.1981,
    "state_id": "MO"
  },
  {
    "zip": 65457,
    "lat": 37.81156,
    "lng": -92.05429,
    "state_id": "MO"
  },
  {
    "zip": 65459,
    "lat": 38.03001,
    "lng": -92.08259,
    "state_id": "MO"
  },
  {
    "zip": 65461,
    "lat": 37.67435,
    "lng": -92.03665,
    "state_id": "MO"
  },
  {
    "zip": 65462,
    "lat": 37.68637,
    "lng": -91.85894,
    "state_id": "MO"
  },
  {
    "zip": 65463,
    "lat": 37.84624,
    "lng": -92.77837,
    "state_id": "MO"
  },
  {
    "zip": 65464,
    "lat": 37.18395,
    "lng": -91.89198,
    "state_id": "MO"
  },
  {
    "zip": 65466,
    "lat": 37.20588,
    "lng": -91.37201,
    "state_id": "MO"
  },
  {
    "zip": 65468,
    "lat": 37.24989,
    "lng": -91.79022,
    "state_id": "MO"
  },
  {
    "zip": 65470,
    "lat": 37.54423,
    "lng": -92.40018,
    "state_id": "MO"
  },
  {
    "zip": 65473,
    "lat": 37.70694,
    "lng": -92.15446,
    "state_id": "MO"
  },
  {
    "zip": 65479,
    "lat": 37.33026,
    "lng": -91.62935,
    "state_id": "MO"
  },
  {
    "zip": 65483,
    "lat": 37.30185,
    "lng": -91.95091,
    "state_id": "MO"
  },
  {
    "zip": 65484,
    "lat": 37.3538,
    "lng": -92.21525,
    "state_id": "MO"
  },
  {
    "zip": 65486,
    "lat": 38.10081,
    "lng": -92.31281,
    "state_id": "MO"
  },
  {
    "zip": 65501,
    "lat": 37.45368,
    "lng": -91.56486,
    "state_id": "MO"
  },
  {
    "zip": 65529,
    "lat": 37.92393,
    "lng": -91.98636,
    "state_id": "MO"
  },
  {
    "zip": 65534,
    "lat": 37.69528,
    "lng": -92.28079,
    "state_id": "MO"
  },
  {
    "zip": 65535,
    "lat": 38.07109,
    "lng": -91.27778,
    "state_id": "MO"
  },
  {
    "zip": 65536,
    "lat": 37.68624,
    "lng": -92.64574,
    "state_id": "MO"
  },
  {
    "zip": 65541,
    "lat": 37.64613,
    "lng": -91.76623,
    "state_id": "MO"
  },
  {
    "zip": 65542,
    "lat": 37.50412,
    "lng": -91.88146,
    "state_id": "MO"
  },
  {
    "zip": 65543,
    "lat": 37.47127,
    "lng": -92.30507,
    "state_id": "MO"
  },
  {
    "zip": 65548,
    "lat": 37.01443,
    "lng": -91.71492,
    "state_id": "MO"
  },
  {
    "zip": 65550,
    "lat": 37.83231,
    "lng": -91.94937,
    "state_id": "MO"
  },
  {
    "zip": 65552,
    "lat": 37.53328,
    "lng": -92.16579,
    "state_id": "MO"
  },
  {
    "zip": 65555,
    "lat": 37.36208,
    "lng": -91.76672,
    "state_id": "MO"
  },
  {
    "zip": 65556,
    "lat": 37.83648,
    "lng": -92.40885,
    "state_id": "MO"
  },
  {
    "zip": 65557,
    "lat": 37.50621,
    "lng": -92.10368,
    "state_id": "MO"
  },
  {
    "zip": 65559,
    "lat": 38.02628,
    "lng": -91.61263,
    "state_id": "MO"
  },
  {
    "zip": 65560,
    "lat": 37.59664,
    "lng": -91.49928,
    "state_id": "MO"
  },
  {
    "zip": 65564,
    "lat": 37.23564,
    "lng": -91.96831,
    "state_id": "MO"
  },
  {
    "zip": 65565,
    "lat": 37.90612,
    "lng": -91.24857,
    "state_id": "MO"
  },
  {
    "zip": 65566,
    "lat": 37.72225,
    "lng": -91.12823,
    "state_id": "MO"
  },
  {
    "zip": 65567,
    "lat": 37.87255,
    "lng": -92.53839,
    "state_id": "MO"
  },
  {
    "zip": 65570,
    "lat": 37.45537,
    "lng": -92.10184,
    "state_id": "MO"
  },
  {
    "zip": 65571,
    "lat": 37.1863,
    "lng": -91.63356,
    "state_id": "MO"
  },
  {
    "zip": 65580,
    "lat": 38.11479,
    "lng": -91.79612,
    "state_id": "MO"
  },
  {
    "zip": 65582,
    "lat": 38.19208,
    "lng": -91.92331,
    "state_id": "MO"
  },
  {
    "zip": 65583,
    "lat": 37.82155,
    "lng": -92.25356,
    "state_id": "MO"
  },
  {
    "zip": 65584,
    "lat": 37.82835,
    "lng": -92.13097,
    "state_id": "MO"
  },
  {
    "zip": 65586,
    "lat": 37.84953,
    "lng": -91.43165,
    "state_id": "MO"
  },
  {
    "zip": 65588,
    "lat": 36.98208,
    "lng": -91.28434,
    "state_id": "MO"
  },
  {
    "zip": 65589,
    "lat": 37.24545,
    "lng": -91.83825,
    "state_id": "MO"
  },
  {
    "zip": 65590,
    "lat": 37.61611,
    "lng": -92.94628,
    "state_id": "MO"
  },
  {
    "zip": 65591,
    "lat": 37.98115,
    "lng": -92.59402,
    "state_id": "MO"
  },
  {
    "zip": 65601,
    "lat": 37.53489,
    "lng": -93.59085,
    "state_id": "MO"
  },
  {
    "zip": 65603,
    "lat": 37.55123,
    "lng": -93.86185,
    "state_id": "MO"
  },
  {
    "zip": 65604,
    "lat": 37.2741,
    "lng": -93.60132,
    "state_id": "MO"
  },
  {
    "zip": 65605,
    "lat": 36.88355,
    "lng": -93.69187,
    "state_id": "MO"
  },
  {
    "zip": 65606,
    "lat": 36.6994,
    "lng": -91.3623,
    "state_id": "MO"
  },
  {
    "zip": 65608,
    "lat": 36.89508,
    "lng": -92.68156,
    "state_id": "MO"
  },
  {
    "zip": 65609,
    "lat": 36.52738,
    "lng": -92.15774,
    "state_id": "MO"
  },
  {
    "zip": 65610,
    "lat": 37.03629,
    "lng": -93.52992,
    "state_id": "MO"
  },
  {
    "zip": 65611,
    "lat": 36.54174,
    "lng": -93.36464,
    "state_id": "MO"
  },
  {
    "zip": 65612,
    "lat": 37.2179,
    "lng": -93.54747,
    "state_id": "MO"
  },
  {
    "zip": 65613,
    "lat": 37.63521,
    "lng": -93.39413,
    "state_id": "MO"
  },
  {
    "zip": 65614,
    "lat": 36.73949,
    "lng": -92.91607,
    "state_id": "MO"
  },
  {
    "zip": 65616,
    "lat": 36.66901,
    "lng": -93.2481,
    "state_id": "MO"
  },
  {
    "zip": 65617,
    "lat": 37.43571,
    "lng": -93.34404,
    "state_id": "MO"
  },
  {
    "zip": 65618,
    "lat": 36.74241,
    "lng": -92.37585,
    "state_id": "MO"
  },
  {
    "zip": 65619,
    "lat": 37.12118,
    "lng": -93.38943,
    "state_id": "MO"
  },
  {
    "zip": 65620,
    "lat": 37.01509,
    "lng": -92.95297,
    "state_id": "MO"
  },
  {
    "zip": 65622,
    "lat": 37.63216,
    "lng": -93.10314,
    "state_id": "MO"
  },
  {
    "zip": 65623,
    "lat": 36.74882,
    "lng": -93.90639,
    "state_id": "MO"
  },
  {
    "zip": 65624,
    "lat": 36.73109,
    "lng": -93.56742,
    "state_id": "MO"
  },
  {
    "zip": 65625,
    "lat": 36.67462,
    "lng": -93.8147,
    "state_id": "MO"
  },
  {
    "zip": 65626,
    "lat": 36.59885,
    "lng": -92.12593,
    "state_id": "MO"
  },
  {
    "zip": 65627,
    "lat": 36.56664,
    "lng": -93.00048,
    "state_id": "MO"
  },
  {
    "zip": 65629,
    "lat": 36.8942,
    "lng": -93.00871,
    "state_id": "MO"
  },
  {
    "zip": 65630,
    "lat": 36.83208,
    "lng": -93.21659,
    "state_id": "MO"
  },
  {
    "zip": 65631,
    "lat": 37.00778,
    "lng": -93.42314,
    "state_id": "MO"
  },
  {
    "zip": 65632,
    "lat": 37.50031,
    "lng": -92.81325,
    "state_id": "MO"
  },
  {
    "zip": 65633,
    "lat": 36.89742,
    "lng": -93.53974,
    "state_id": "MO"
  },
  {
    "zip": 65634,
    "lat": 38.02347,
    "lng": -93.19243,
    "state_id": "MO"
  },
  {
    "zip": 65635,
    "lat": 37.5375,
    "lng": -93.72091,
    "state_id": "MO"
  },
  {
    "zip": 65637,
    "lat": 36.76393,
    "lng": -92.20357,
    "state_id": "MO"
  },
  {
    "zip": 65638,
    "lat": 36.84129,
    "lng": -92.32558,
    "state_id": "MO"
  },
  {
    "zip": 65640,
    "lat": 37.69915,
    "lng": -93.57475,
    "state_id": "MO"
  },
  {
    "zip": 65641,
    "lat": 36.54344,
    "lng": -93.76438,
    "state_id": "MO"
  },
  {
    "zip": 65644,
    "lat": 37.50795,
    "lng": -93.03609,
    "state_id": "MO"
  },
  {
    "zip": 65646,
    "lat": 37.33678,
    "lng": -93.70202,
    "state_id": "MO"
  },
  {
    "zip": 65647,
    "lat": 36.69017,
    "lng": -94.00698,
    "state_id": "MO"
  },
  {
    "zip": 65648,
    "lat": 37.40114,
    "lng": -93.15627,
    "state_id": "MO"
  },
  {
    "zip": 65649,
    "lat": 37.61828,
    "lng": -93.61837,
    "state_id": "MO"
  },
  {
    "zip": 65650,
    "lat": 37.8193,
    "lng": -93.4477,
    "state_id": "MO"
  },
  {
    "zip": 65652,
    "lat": 37.14472,
    "lng": -92.94616,
    "state_id": "MO"
  },
  {
    "zip": 65653,
    "lat": 36.77912,
    "lng": -93.11433,
    "state_id": "MO"
  },
  {
    "zip": 65654,
    "lat": 37.02291,
    "lng": -93.90585,
    "state_id": "MO"
  },
  {
    "zip": 65655,
    "lat": 36.59796,
    "lng": -92.42655,
    "state_id": "MO"
  },
  {
    "zip": 65656,
    "lat": 36.79505,
    "lng": -93.46672,
    "state_id": "MO"
  },
  {
    "zip": 65657,
    "lat": 36.83316,
    "lng": -93.01467,
    "state_id": "MO"
  },
  {
    "zip": 65658,
    "lat": 36.54113,
    "lng": -93.64116,
    "state_id": "MO"
  },
  {
    "zip": 65660,
    "lat": 37.33126,
    "lng": -92.27244,
    "state_id": "MO"
  },
  {
    "zip": 65661,
    "lat": 37.46179,
    "lng": -93.82631,
    "state_id": "MO"
  },
  {
    "zip": 65662,
    "lat": 37.44022,
    "lng": -92.57738,
    "state_id": "MO"
  },
  {
    "zip": 65663,
    "lat": 37.62119,
    "lng": -93.24482,
    "state_id": "MO"
  },
  {
    "zip": 65664,
    "lat": 37.19733,
    "lng": -93.61725,
    "state_id": "MO"
  },
  {
    "zip": 65667,
    "lat": 37.30802,
    "lng": -92.51504,
    "state_id": "MO"
  },
  {
    "zip": 65668,
    "lat": 37.93281,
    "lng": -93.28777,
    "state_id": "MO"
  },
  {
    "zip": 65669,
    "lat": 36.91222,
    "lng": -93.30266,
    "state_id": "MO"
  },
  {
    "zip": 65672,
    "lat": 36.56635,
    "lng": -93.21579,
    "state_id": "MO"
  },
  {
    "zip": 65674,
    "lat": 37.786,
    "lng": -93.60684,
    "state_id": "MO"
  },
  {
    "zip": 65676,
    "lat": 36.57617,
    "lng": -92.60996,
    "state_id": "MO"
  },
  {
    "zip": 65679,
    "lat": 36.57465,
    "lng": -93.10428,
    "state_id": "MO"
  },
  {
    "zip": 65680,
    "lat": 36.65751,
    "lng": -93.01482,
    "state_id": "MO"
  },
  {
    "zip": 65681,
    "lat": 36.55438,
    "lng": -93.46377,
    "state_id": "MO"
  },
  {
    "zip": 65682,
    "lat": 37.41611,
    "lng": -93.96984,
    "state_id": "MO"
  },
  {
    "zip": 65685,
    "lat": 37.75419,
    "lng": -93.1566,
    "state_id": "MO"
  },
  {
    "zip": 65686,
    "lat": 36.62533,
    "lng": -93.43875,
    "state_id": "MO"
  },
  {
    "zip": 65689,
    "lat": 37.14072,
    "lng": -92.09633,
    "state_id": "MO"
  },
  {
    "zip": 65690,
    "lat": 36.58526,
    "lng": -91.27236,
    "state_id": "MO"
  },
  {
    "zip": 65692,
    "lat": 36.62758,
    "lng": -91.63978,
    "state_id": "MO"
  },
  {
    "zip": 65702,
    "lat": 37.06736,
    "lng": -92.48971,
    "state_id": "MO"
  },
  {
    "zip": 65704,
    "lat": 37.12329,
    "lng": -92.58455,
    "state_id": "MO"
  },
  {
    "zip": 65705,
    "lat": 37.0212,
    "lng": -93.60889,
    "state_id": "MO"
  },
  {
    "zip": 65706,
    "lat": 37.32361,
    "lng": -92.89561,
    "state_id": "MO"
  },
  {
    "zip": 65707,
    "lat": 37.22822,
    "lng": -93.845,
    "state_id": "MO"
  },
  {
    "zip": 65708,
    "lat": 36.9048,
    "lng": -93.91017,
    "state_id": "MO"
  },
  {
    "zip": 65710,
    "lat": 37.48693,
    "lng": -93.42288,
    "state_id": "MO"
  },
  {
    "zip": 65711,
    "lat": 37.17731,
    "lng": -92.27674,
    "state_id": "MO"
  },
  {
    "zip": 65712,
    "lat": 37.10792,
    "lng": -93.80433,
    "state_id": "MO"
  },
  {
    "zip": 65713,
    "lat": 37.40341,
    "lng": -92.74622,
    "state_id": "MO"
  },
  {
    "zip": 65714,
    "lat": 37.03861,
    "lng": -93.31839,
    "state_id": "MO"
  },
  {
    "zip": 65715,
    "lat": 36.73285,
    "lng": -92.57293,
    "state_id": "MO"
  },
  {
    "zip": 65717,
    "lat": 37.05958,
    "lng": -92.41495,
    "state_id": "MO"
  },
  {
    "zip": 65720,
    "lat": 36.94037,
    "lng": -92.95276,
    "state_id": "MO"
  },
  {
    "zip": 65721,
    "lat": 36.9801,
    "lng": -93.21018,
    "state_id": "MO"
  },
  {
    "zip": 65722,
    "lat": 37.58165,
    "lng": -92.80848,
    "state_id": "MO"
  },
  {
    "zip": 65723,
    "lat": 36.96002,
    "lng": -94.04273,
    "state_id": "MO"
  },
  {
    "zip": 65724,
    "lat": 37.84323,
    "lng": -93.31301,
    "state_id": "MO"
  },
  {
    "zip": 65725,
    "lat": 37.43298,
    "lng": -93.27658,
    "state_id": "MO"
  },
  {
    "zip": 65727,
    "lat": 37.7559,
    "lng": -93.29762,
    "state_id": "MO"
  },
  {
    "zip": 65728,
    "lat": 36.87139,
    "lng": -93.34556,
    "state_id": "MO"
  },
  {
    "zip": 65729,
    "lat": 36.51716,
    "lng": -92.5894,
    "state_id": "MO"
  },
  {
    "zip": 65730,
    "lat": 36.62367,
    "lng": -94.17715,
    "state_id": "MO"
  },
  {
    "zip": 65731,
    "lat": 36.65455,
    "lng": -93.12221,
    "state_id": "MO"
  },
  {
    "zip": 65732,
    "lat": 37.94131,
    "lng": -93.16162,
    "state_id": "MO"
  },
  {
    "zip": 65733,
    "lat": 36.52859,
    "lng": -92.84643,
    "state_id": "MO"
  },
  {
    "zip": 65734,
    "lat": 36.79587,
    "lng": -93.94761,
    "state_id": "MO"
  },
  {
    "zip": 65735,
    "lat": 38.04198,
    "lng": -93.47193,
    "state_id": "MO"
  },
  {
    "zip": 65737,
    "lat": 36.71277,
    "lng": -93.36017,
    "state_id": "MO"
  },
  {
    "zip": 65738,
    "lat": 37.13521,
    "lng": -93.4991,
    "state_id": "MO"
  },
  {
    "zip": 65739,
    "lat": 36.51927,
    "lng": -93.26876,
    "state_id": "MO"
  },
  {
    "zip": 65740,
    "lat": 36.71322,
    "lng": -93.16393,
    "state_id": "MO"
  },
  {
    "zip": 65742,
    "lat": 37.12553,
    "lng": -93.07886,
    "state_id": "MO"
  },
  {
    "zip": 65744,
    "lat": 36.6228,
    "lng": -92.89758,
    "state_id": "MO"
  },
  {
    "zip": 65745,
    "lat": 36.5308,
    "lng": -93.96784,
    "state_id": "MO"
  },
  {
    "zip": 65746,
    "lat": 37.14712,
    "lng": -92.77841,
    "state_id": "MO"
  },
  {
    "zip": 65747,
    "lat": 36.60226,
    "lng": -93.57982,
    "state_id": "MO"
  },
  {
    "zip": 65752,
    "lat": 37.32576,
    "lng": -93.83229,
    "state_id": "MO"
  },
  {
    "zip": 65753,
    "lat": 36.99876,
    "lng": -93.05469,
    "state_id": "MO"
  },
  {
    "zip": 65754,
    "lat": 36.84889,
    "lng": -93.2896,
    "state_id": "MO"
  },
  {
    "zip": 65755,
    "lat": 36.80505,
    "lng": -92.62696,
    "state_id": "MO"
  },
  {
    "zip": 65756,
    "lat": 37.10289,
    "lng": -93.97091,
    "state_id": "MO"
  },
  {
    "zip": 65757,
    "lat": 37.28281,
    "lng": -93.09744,
    "state_id": "MO"
  },
  {
    "zip": 65759,
    "lat": 36.7552,
    "lng": -93.03171,
    "state_id": "MO"
  },
  {
    "zip": 65760,
    "lat": 36.62976,
    "lng": -92.28099,
    "state_id": "MO"
  },
  {
    "zip": 65761,
    "lat": 36.57545,
    "lng": -92.72845,
    "state_id": "MO"
  },
  {
    "zip": 65762,
    "lat": 36.68752,
    "lng": -92.65337,
    "state_id": "MO"
  },
  {
    "zip": 65764,
    "lat": 37.82304,
    "lng": -92.95438,
    "state_id": "MO"
  },
  {
    "zip": 65766,
    "lat": 36.54037,
    "lng": -92.25955,
    "state_id": "MO"
  },
  {
    "zip": 65767,
    "lat": 37.84382,
    "lng": -93.1545,
    "state_id": "MO"
  },
  {
    "zip": 65768,
    "lat": 36.91958,
    "lng": -92.2644,
    "state_id": "MO"
  },
  {
    "zip": 65769,
    "lat": 36.91749,
    "lng": -93.79532,
    "state_id": "MO"
  },
  {
    "zip": 65770,
    "lat": 37.42338,
    "lng": -93.54707,
    "state_id": "MO"
  },
  {
    "zip": 65771,
    "lat": 36.7725,
    "lng": -93.20639,
    "state_id": "MO"
  },
  {
    "zip": 65772,
    "lat": 36.59499,
    "lng": -94.03764,
    "state_id": "MO"
  },
  {
    "zip": 65773,
    "lat": 36.75729,
    "lng": -92.50935,
    "state_id": "MO"
  },
  {
    "zip": 65774,
    "lat": 37.92914,
    "lng": -93.49222,
    "state_id": "MO"
  },
  {
    "zip": 65775,
    "lat": 36.70128,
    "lng": -91.85865,
    "state_id": "MO"
  },
  {
    "zip": 65777,
    "lat": 36.52287,
    "lng": -91.99016,
    "state_id": "MO"
  },
  {
    "zip": 65778,
    "lat": 36.52914,
    "lng": -91.27615,
    "state_id": "MO"
  },
  {
    "zip": 65779,
    "lat": 37.97556,
    "lng": -93.38123,
    "state_id": "MO"
  },
  {
    "zip": 65781,
    "lat": 37.34947,
    "lng": -93.41582,
    "state_id": "MO"
  },
  {
    "zip": 65783,
    "lat": 37.71731,
    "lng": -92.93339,
    "state_id": "MO"
  },
  {
    "zip": 65784,
    "lat": 36.67987,
    "lng": -92.31395,
    "state_id": "MO"
  },
  {
    "zip": 65785,
    "lat": 37.70614,
    "lng": -93.82119,
    "state_id": "MO"
  },
  {
    "zip": 65786,
    "lat": 37.96582,
    "lng": -92.96644,
    "state_id": "MO"
  },
  {
    "zip": 65787,
    "lat": 38.06892,
    "lng": -92.88945,
    "state_id": "MO"
  },
  {
    "zip": 65788,
    "lat": 36.80909,
    "lng": -91.69377,
    "state_id": "MO"
  },
  {
    "zip": 65789,
    "lat": 36.86371,
    "lng": -91.88103,
    "state_id": "MO"
  },
  {
    "zip": 65790,
    "lat": 36.70633,
    "lng": -92.11409,
    "state_id": "MO"
  },
  {
    "zip": 65791,
    "lat": 36.56171,
    "lng": -91.50304,
    "state_id": "MO"
  },
  {
    "zip": 65793,
    "lat": 36.99249,
    "lng": -91.9721,
    "state_id": "MO"
  },
  {
    "zip": 65802,
    "lat": 37.20909,
    "lng": -93.35405,
    "state_id": "MO"
  },
  {
    "zip": 65803,
    "lat": 37.28397,
    "lng": -93.28907,
    "state_id": "MO"
  },
  {
    "zip": 65804,
    "lat": 37.14925,
    "lng": -93.25268,
    "state_id": "MO"
  },
  {
    "zip": 65806,
    "lat": 37.20562,
    "lng": -93.29937,
    "state_id": "MO"
  },
  {
    "zip": 65807,
    "lat": 37.16563,
    "lng": -93.32557,
    "state_id": "MO"
  },
  {
    "zip": 65809,
    "lat": 37.1687,
    "lng": -93.19335,
    "state_id": "MO"
  },
  {
    "zip": 65810,
    "lat": 37.11738,
    "lng": -93.31722,
    "state_id": "MO"
  },
  {
    "zip": 96950,
    "lat": 15.18887,
    "lng": 145.75356,
    "state_id": "MP"
  },
  {
    "zip": 96951,
    "lat": 14.15733,
    "lng": 145.2145,
    "state_id": "MP"
  },
  {
    "zip": 96952,
    "lat": 15.01312,
    "lng": 145.63149,
    "state_id": "MP"
  },
  {
    "zip": 38601,
    "lat": 34.48202,
    "lng": -89.43964,
    "state_id": "MS"
  },
  {
    "zip": 38603,
    "lat": 34.83463,
    "lng": -89.14271,
    "state_id": "MS"
  },
  {
    "zip": 38606,
    "lat": 34.30467,
    "lng": -89.95592,
    "state_id": "MS"
  },
  {
    "zip": 38610,
    "lat": 34.65293,
    "lng": -89.02614,
    "state_id": "MS"
  },
  {
    "zip": 38611,
    "lat": 34.8532,
    "lng": -89.67162,
    "state_id": "MS"
  },
  {
    "zip": 38614,
    "lat": 34.15577,
    "lng": -90.60406,
    "state_id": "MS"
  },
  {
    "zip": 38617,
    "lat": 34.34946,
    "lng": -90.52248,
    "state_id": "MS"
  },
  {
    "zip": 38618,
    "lat": 34.70124,
    "lng": -89.93036,
    "state_id": "MS"
  },
  {
    "zip": 38619,
    "lat": 34.51446,
    "lng": -89.84234,
    "state_id": "MS"
  },
  {
    "zip": 38620,
    "lat": 34.22738,
    "lng": -89.90596,
    "state_id": "MS"
  },
  {
    "zip": 38621,
    "lat": 34.4491,
    "lng": -90.17014,
    "state_id": "MS"
  },
  {
    "zip": 38622,
    "lat": 34.1719,
    "lng": -90.1385,
    "state_id": "MS"
  },
  {
    "zip": 38623,
    "lat": 34.36162,
    "lng": -90.28052,
    "state_id": "MS"
  },
  {
    "zip": 38625,
    "lat": 34.60922,
    "lng": -88.82567,
    "state_id": "MS"
  },
  {
    "zip": 38626,
    "lat": 34.51367,
    "lng": -90.41775,
    "state_id": "MS"
  },
  {
    "zip": 38627,
    "lat": 34.42428,
    "lng": -89.2237,
    "state_id": "MS"
  },
  {
    "zip": 38629,
    "lat": 34.87502,
    "lng": -89.00097,
    "state_id": "MS"
  },
  {
    "zip": 38630,
    "lat": 34.27881,
    "lng": -90.68558,
    "state_id": "MS"
  },
  {
    "zip": 38631,
    "lat": 34.36387,
    "lng": -90.61971,
    "state_id": "MS"
  },
  {
    "zip": 38632,
    "lat": 34.80034,
    "lng": -90.00774,
    "state_id": "MS"
  },
  {
    "zip": 38633,
    "lat": 34.64142,
    "lng": -89.19457,
    "state_id": "MS"
  },
  {
    "zip": 38635,
    "lat": 34.76809,
    "lng": -89.48171,
    "state_id": "MS"
  },
  {
    "zip": 38637,
    "lat": 34.95148,
    "lng": -90.05015,
    "state_id": "MS"
  },
  {
    "zip": 38639,
    "lat": 34.30311,
    "lng": -90.43195,
    "state_id": "MS"
  },
  {
    "zip": 38641,
    "lat": 34.91466,
    "lng": -90.1894,
    "state_id": "MS"
  },
  {
    "zip": 38642,
    "lat": 34.9173,
    "lng": -89.32985,
    "state_id": "MS"
  },
  {
    "zip": 38643,
    "lat": 34.15156,
    "lng": -90.26631,
    "state_id": "MS"
  },
  {
    "zip": 38644,
    "lat": 34.46085,
    "lng": -90.49357,
    "state_id": "MS"
  },
  {
    "zip": 38645,
    "lat": 34.24561,
    "lng": -90.47864,
    "state_id": "MS"
  },
  {
    "zip": 38646,
    "lat": 34.28105,
    "lng": -90.29644,
    "state_id": "MS"
  },
  {
    "zip": 38647,
    "lat": 34.96079,
    "lng": -89.22683,
    "state_id": "MS"
  },
  {
    "zip": 38650,
    "lat": 34.52584,
    "lng": -89.14143,
    "state_id": "MS"
  },
  {
    "zip": 38651,
    "lat": 34.89151,
    "lng": -90.00409,
    "state_id": "MS"
  },
  {
    "zip": 38652,
    "lat": 34.48346,
    "lng": -88.99688,
    "state_id": "MS"
  },
  {
    "zip": 38654,
    "lat": 34.93518,
    "lng": -89.82291,
    "state_id": "MS"
  },
  {
    "zip": 38655,
    "lat": 34.33305,
    "lng": -89.47577,
    "state_id": "MS"
  },
  {
    "zip": 38658,
    "lat": 34.19156,
    "lng": -89.90859,
    "state_id": "MS"
  },
  {
    "zip": 38659,
    "lat": 34.62211,
    "lng": -89.31938,
    "state_id": "MS"
  },
  {
    "zip": 38661,
    "lat": 34.89624,
    "lng": -89.55984,
    "state_id": "MS"
  },
  {
    "zip": 38663,
    "lat": 34.73986,
    "lng": -88.88963,
    "state_id": "MS"
  },
  {
    "zip": 38664,
    "lat": 34.80958,
    "lng": -90.32723,
    "state_id": "MS"
  },
  {
    "zip": 38665,
    "lat": 34.591,
    "lng": -90.16196,
    "state_id": "MS"
  },
  {
    "zip": 38666,
    "lat": 34.42655,
    "lng": -89.91679,
    "state_id": "MS"
  },
  {
    "zip": 38668,
    "lat": 34.60128,
    "lng": -89.91624,
    "state_id": "MS"
  },
  {
    "zip": 38670,
    "lat": 34.41562,
    "lng": -90.29148,
    "state_id": "MS"
  },
  {
    "zip": 38671,
    "lat": 34.96564,
    "lng": -89.9997,
    "state_id": "MS"
  },
  {
    "zip": 38672,
    "lat": 34.93787,
    "lng": -89.94861,
    "state_id": "MS"
  },
  {
    "zip": 38673,
    "lat": 34.29989,
    "lng": -89.61558,
    "state_id": "MS"
  },
  {
    "zip": 38674,
    "lat": 34.87435,
    "lng": -88.89617,
    "state_id": "MS"
  },
  {
    "zip": 38676,
    "lat": 34.66771,
    "lng": -90.38523,
    "state_id": "MS"
  },
  {
    "zip": 38677,
    "lat": 34.36544,
    "lng": -89.53717,
    "state_id": "MS"
  },
  {
    "zip": 38680,
    "lat": 34.95273,
    "lng": -90.14411,
    "state_id": "MS"
  },
  {
    "zip": 38683,
    "lat": 34.93753,
    "lng": -88.87019,
    "state_id": "MS"
  },
  {
    "zip": 38685,
    "lat": 34.59957,
    "lng": -89.4879,
    "state_id": "MS"
  },
  {
    "zip": 38701,
    "lat": 33.30989,
    "lng": -91.04385,
    "state_id": "MS"
  },
  {
    "zip": 38702,
    "lat": 33.5361,
    "lng": -91.1795,
    "state_id": "MS"
  },
  {
    "zip": 38703,
    "lat": 33.48071,
    "lng": -91.05899,
    "state_id": "MS"
  },
  {
    "zip": 38704,
    "lat": 33.00716,
    "lng": -91.02129,
    "state_id": "MS"
  },
  {
    "zip": 38720,
    "lat": 34.12393,
    "lng": -90.78524,
    "state_id": "MS"
  },
  {
    "zip": 38721,
    "lat": 32.98404,
    "lng": -90.7504,
    "state_id": "MS"
  },
  {
    "zip": 38722,
    "lat": 33.25903,
    "lng": -90.8861,
    "state_id": "MS"
  },
  {
    "zip": 38723,
    "lat": 33.22965,
    "lng": -91.03837,
    "state_id": "MS"
  },
  {
    "zip": 38725,
    "lat": 33.65082,
    "lng": -91.02697,
    "state_id": "MS"
  },
  {
    "zip": 38726,
    "lat": 33.74813,
    "lng": -90.98345,
    "state_id": "MS"
  },
  {
    "zip": 38730,
    "lat": 33.68906,
    "lng": -90.7838,
    "state_id": "MS"
  },
  {
    "zip": 38731,
    "lat": 33.07507,
    "lng": -91.04832,
    "state_id": "MS"
  },
  {
    "zip": 38732,
    "lat": 33.75623,
    "lng": -90.7275,
    "state_id": "MS"
  },
  {
    "zip": 38736,
    "lat": 33.64538,
    "lng": -90.53814,
    "state_id": "MS"
  },
  {
    "zip": 38737,
    "lat": 33.86996,
    "lng": -90.5124,
    "state_id": "MS"
  },
  {
    "zip": 38738,
    "lat": 33.93902,
    "lng": -90.53115,
    "state_id": "MS"
  },
  {
    "zip": 38740,
    "lat": 34.05558,
    "lng": -90.79886,
    "state_id": "MS"
  },
  {
    "zip": 38744,
    "lat": 33.01576,
    "lng": -91.0353,
    "state_id": "MS"
  },
  {
    "zip": 38745,
    "lat": 32.97747,
    "lng": -90.9829,
    "state_id": "MS"
  },
  {
    "zip": 38746,
    "lat": 33.9651,
    "lng": -90.90917,
    "state_id": "MS"
  },
  {
    "zip": 38748,
    "lat": 33.14908,
    "lng": -90.90406,
    "state_id": "MS"
  },
  {
    "zip": 38749,
    "lat": 33.44734,
    "lng": -90.75238,
    "state_id": "MS"
  },
  {
    "zip": 38751,
    "lat": 33.4489,
    "lng": -90.6796,
    "state_id": "MS"
  },
  {
    "zip": 38753,
    "lat": 33.34751,
    "lng": -90.57878,
    "state_id": "MS"
  },
  {
    "zip": 38754,
    "lat": 33.24853,
    "lng": -90.62238,
    "state_id": "MS"
  },
  {
    "zip": 38756,
    "lat": 33.39148,
    "lng": -90.85619,
    "state_id": "MS"
  },
  {
    "zip": 38759,
    "lat": 33.82315,
    "lng": -90.73133,
    "state_id": "MS"
  },
  {
    "zip": 38760,
    "lat": 33.45804,
    "lng": -90.98927,
    "state_id": "MS"
  },
  {
    "zip": 38761,
    "lat": 33.43204,
    "lng": -90.48489,
    "state_id": "MS"
  },
  {
    "zip": 38762,
    "lat": 33.88792,
    "lng": -90.71629,
    "state_id": "MS"
  },
  {
    "zip": 38764,
    "lat": 33.79006,
    "lng": -90.86296,
    "state_id": "MS"
  },
  {
    "zip": 38765,
    "lat": 33.06911,
    "lng": -90.87459,
    "state_id": "MS"
  },
  {
    "zip": 38767,
    "lat": 34.14049,
    "lng": -90.78472,
    "state_id": "MS"
  },
  {
    "zip": 38768,
    "lat": 33.96973,
    "lng": -90.48724,
    "state_id": "MS"
  },
  {
    "zip": 38769,
    "lat": 33.8651,
    "lng": -90.98235,
    "state_id": "MS"
  },
  {
    "zip": 38771,
    "lat": 33.73799,
    "lng": -90.52927,
    "state_id": "MS"
  },
  {
    "zip": 38772,
    "lat": 33.56048,
    "lng": -91.05149,
    "state_id": "MS"
  },
  {
    "zip": 38773,
    "lat": 33.60626,
    "lng": -90.77861,
    "state_id": "MS"
  },
  {
    "zip": 38774,
    "lat": 33.96311,
    "lng": -90.76274,
    "state_id": "MS"
  },
  {
    "zip": 38778,
    "lat": 33.54826,
    "lng": -90.52479,
    "state_id": "MS"
  },
  {
    "zip": 38781,
    "lat": 33.90855,
    "lng": -90.75661,
    "state_id": "MS"
  },
  {
    "zip": 38801,
    "lat": 34.22131,
    "lng": -88.77303,
    "state_id": "MS"
  },
  {
    "zip": 38804,
    "lat": 34.2823,
    "lng": -88.67147,
    "state_id": "MS"
  },
  {
    "zip": 38821,
    "lat": 33.97596,
    "lng": -88.43758,
    "state_id": "MS"
  },
  {
    "zip": 38824,
    "lat": 34.52038,
    "lng": -88.64307,
    "state_id": "MS"
  },
  {
    "zip": 38826,
    "lat": 34.30572,
    "lng": -88.84606,
    "state_id": "MS"
  },
  {
    "zip": 38827,
    "lat": 34.53265,
    "lng": -88.20043,
    "state_id": "MS"
  },
  {
    "zip": 38828,
    "lat": 34.42964,
    "lng": -88.864,
    "state_id": "MS"
  },
  {
    "zip": 38829,
    "lat": 34.65198,
    "lng": -88.53077,
    "state_id": "MS"
  },
  {
    "zip": 38833,
    "lat": 34.83787,
    "lng": -88.33923,
    "state_id": "MS"
  },
  {
    "zip": 38834,
    "lat": 34.91545,
    "lng": -88.5635,
    "state_id": "MS"
  },
  {
    "zip": 38838,
    "lat": 34.54757,
    "lng": -88.26042,
    "state_id": "MS"
  },
  {
    "zip": 38841,
    "lat": 34.35127,
    "lng": -89.01667,
    "state_id": "MS"
  },
  {
    "zip": 38843,
    "lat": 34.26496,
    "lng": -88.37564,
    "state_id": "MS"
  },
  {
    "zip": 38844,
    "lat": 33.83859,
    "lng": -88.25533,
    "state_id": "MS"
  },
  {
    "zip": 38846,
    "lat": 34.85078,
    "lng": -88.39396,
    "state_id": "MS"
  },
  {
    "zip": 38847,
    "lat": 34.4109,
    "lng": -88.2422,
    "state_id": "MS"
  },
  {
    "zip": 38848,
    "lat": 33.94752,
    "lng": -88.27568,
    "state_id": "MS"
  },
  {
    "zip": 38849,
    "lat": 34.45623,
    "lng": -88.69749,
    "state_id": "MS"
  },
  {
    "zip": 38850,
    "lat": 34.04896,
    "lng": -89.05309,
    "state_id": "MS"
  },
  {
    "zip": 38851,
    "lat": 33.90862,
    "lng": -88.96909,
    "state_id": "MS"
  },
  {
    "zip": 38852,
    "lat": 34.84197,
    "lng": -88.21464,
    "state_id": "MS"
  },
  {
    "zip": 38855,
    "lat": 34.3267,
    "lng": -88.48542,
    "state_id": "MS"
  },
  {
    "zip": 38856,
    "lat": 34.46863,
    "lng": -88.44672,
    "state_id": "MS"
  },
  {
    "zip": 38857,
    "lat": 34.28028,
    "lng": -88.57748,
    "state_id": "MS"
  },
  {
    "zip": 38858,
    "lat": 34.0997,
    "lng": -88.54372,
    "state_id": "MS"
  },
  {
    "zip": 38859,
    "lat": 34.52645,
    "lng": -88.37561,
    "state_id": "MS"
  },
  {
    "zip": 38860,
    "lat": 33.96936,
    "lng": -88.76376,
    "state_id": "MS"
  },
  {
    "zip": 38862,
    "lat": 34.16941,
    "lng": -88.62123,
    "state_id": "MS"
  },
  {
    "zip": 38863,
    "lat": 34.21931,
    "lng": -89.01938,
    "state_id": "MS"
  },
  {
    "zip": 38864,
    "lat": 34.13398,
    "lng": -89.19913,
    "state_id": "MS"
  },
  {
    "zip": 38865,
    "lat": 34.78437,
    "lng": -88.59098,
    "state_id": "MS"
  },
  {
    "zip": 38866,
    "lat": 34.36793,
    "lng": -88.68058,
    "state_id": "MS"
  },
  {
    "zip": 38868,
    "lat": 34.11927,
    "lng": -88.75739,
    "state_id": "MS"
  },
  {
    "zip": 38869,
    "lat": 34.35744,
    "lng": -88.83516,
    "state_id": "MS"
  },
  {
    "zip": 38870,
    "lat": 34.06747,
    "lng": -88.33644,
    "state_id": "MS"
  },
  {
    "zip": 38871,
    "lat": 34.33475,
    "lng": -89.2033,
    "state_id": "MS"
  },
  {
    "zip": 38873,
    "lat": 34.65801,
    "lng": -88.24941,
    "state_id": "MS"
  },
  {
    "zip": 38876,
    "lat": 34.21786,
    "lng": -88.23038,
    "state_id": "MS"
  },
  {
    "zip": 38878,
    "lat": 33.88925,
    "lng": -89.18913,
    "state_id": "MS"
  },
  {
    "zip": 38879,
    "lat": 34.18699,
    "lng": -88.7169,
    "state_id": "MS"
  },
  {
    "zip": 38901,
    "lat": 33.76161,
    "lng": -89.80425,
    "state_id": "MS"
  },
  {
    "zip": 38913,
    "lat": 34.13417,
    "lng": -89.37883,
    "state_id": "MS"
  },
  {
    "zip": 38914,
    "lat": 33.86819,
    "lng": -89.45757,
    "state_id": "MS"
  },
  {
    "zip": 38915,
    "lat": 34.02785,
    "lng": -89.38955,
    "state_id": "MS"
  },
  {
    "zip": 38916,
    "lat": 33.81438,
    "lng": -89.35214,
    "state_id": "MS"
  },
  {
    "zip": 38917,
    "lat": 33.5516,
    "lng": -89.96044,
    "state_id": "MS"
  },
  {
    "zip": 38920,
    "lat": 33.87636,
    "lng": -90.06536,
    "state_id": "MS"
  },
  {
    "zip": 38921,
    "lat": 33.9814,
    "lng": -90.14854,
    "state_id": "MS"
  },
  {
    "zip": 38922,
    "lat": 33.9312,
    "lng": -89.64492,
    "state_id": "MS"
  },
  {
    "zip": 38923,
    "lat": 33.38102,
    "lng": -89.98723,
    "state_id": "MS"
  },
  {
    "zip": 38924,
    "lat": 33.29292,
    "lng": -90.22469,
    "state_id": "MS"
  },
  {
    "zip": 38925,
    "lat": 33.62764,
    "lng": -89.63211,
    "state_id": "MS"
  },
  {
    "zip": 38927,
    "lat": 34.13076,
    "lng": -90.025,
    "state_id": "MS"
  },
  {
    "zip": 38928,
    "lat": 33.85738,
    "lng": -90.28805,
    "state_id": "MS"
  },
  {
    "zip": 38929,
    "lat": 33.74025,
    "lng": -89.53118,
    "state_id": "MS"
  },
  {
    "zip": 38930,
    "lat": 33.54541,
    "lng": -90.14512,
    "state_id": "MS"
  },
  {
    "zip": 38940,
    "lat": 33.7551,
    "lng": -90.03334,
    "state_id": "MS"
  },
  {
    "zip": 38941,
    "lat": 33.48085,
    "lng": -90.35694,
    "state_id": "MS"
  },
  {
    "zip": 38943,
    "lat": 33.53888,
    "lng": -89.84366,
    "state_id": "MS"
  },
  {
    "zip": 38944,
    "lat": 33.75886,
    "lng": -90.34392,
    "state_id": "MS"
  },
  {
    "zip": 38945,
    "lat": 33.60004,
    "lng": -90.17071,
    "state_id": "MS"
  },
  {
    "zip": 38946,
    "lat": 33.36414,
    "lng": -90.37915,
    "state_id": "MS"
  },
  {
    "zip": 38947,
    "lat": 33.53242,
    "lng": -89.90006,
    "state_id": "MS"
  },
  {
    "zip": 38948,
    "lat": 34.07252,
    "lng": -89.88861,
    "state_id": "MS"
  },
  {
    "zip": 38949,
    "lat": 34.19267,
    "lng": -89.42174,
    "state_id": "MS"
  },
  {
    "zip": 38950,
    "lat": 33.74864,
    "lng": -90.2119,
    "state_id": "MS"
  },
  {
    "zip": 38951,
    "lat": 33.97296,
    "lng": -89.28954,
    "state_id": "MS"
  },
  {
    "zip": 38952,
    "lat": 33.62117,
    "lng": -90.34601,
    "state_id": "MS"
  },
  {
    "zip": 38953,
    "lat": 33.92643,
    "lng": -89.92772,
    "state_id": "MS"
  },
  {
    "zip": 38954,
    "lat": 33.4153,
    "lng": -90.21174,
    "state_id": "MS"
  },
  {
    "zip": 38957,
    "lat": 33.9917,
    "lng": -90.35041,
    "state_id": "MS"
  },
  {
    "zip": 38958,
    "lat": 33.30538,
    "lng": -90.42558,
    "state_id": "MS"
  },
  {
    "zip": 38961,
    "lat": 33.96246,
    "lng": -89.88489,
    "state_id": "MS"
  },
  {
    "zip": 38962,
    "lat": 33.87494,
    "lng": -90.18428,
    "state_id": "MS"
  },
  {
    "zip": 38963,
    "lat": 34.02207,
    "lng": -90.38975,
    "state_id": "MS"
  },
  {
    "zip": 38964,
    "lat": 34.09097,
    "lng": -90.38961,
    "state_id": "MS"
  },
  {
    "zip": 38965,
    "lat": 34.13567,
    "lng": -89.60006,
    "state_id": "MS"
  },
  {
    "zip": 38966,
    "lat": 33.91688,
    "lng": -90.35832,
    "state_id": "MS"
  },
  {
    "zip": 38967,
    "lat": 33.45924,
    "lng": -89.73377,
    "state_id": "MS"
  },
  {
    "zip": 39038,
    "lat": 33.18468,
    "lng": -90.48436,
    "state_id": "MS"
  },
  {
    "zip": 39039,
    "lat": 32.81969,
    "lng": -90.22506,
    "state_id": "MS"
  },
  {
    "zip": 39040,
    "lat": 32.63746,
    "lng": -90.41989,
    "state_id": "MS"
  },
  {
    "zip": 39041,
    "lat": 32.41376,
    "lng": -90.47335,
    "state_id": "MS"
  },
  {
    "zip": 39042,
    "lat": 32.21203,
    "lng": -89.88862,
    "state_id": "MS"
  },
  {
    "zip": 39044,
    "lat": 32.02921,
    "lng": -89.97299,
    "state_id": "MS"
  },
  {
    "zip": 39045,
    "lat": 32.7899,
    "lng": -89.81777,
    "state_id": "MS"
  },
  {
    "zip": 39046,
    "lat": 32.63195,
    "lng": -89.98542,
    "state_id": "MS"
  },
  {
    "zip": 39047,
    "lat": 32.42629,
    "lng": -89.94677,
    "state_id": "MS"
  },
  {
    "zip": 39051,
    "lat": 32.78825,
    "lng": -89.50044,
    "state_id": "MS"
  },
  {
    "zip": 39054,
    "lat": 32.79855,
    "lng": -90.92941,
    "state_id": "MS"
  },
  {
    "zip": 39056,
    "lat": 32.37098,
    "lng": -90.34932,
    "state_id": "MS"
  },
  {
    "zip": 39057,
    "lat": 32.4926,
    "lng": -89.28509,
    "state_id": "MS"
  },
  {
    "zip": 39059,
    "lat": 31.98234,
    "lng": -90.35549,
    "state_id": "MS"
  },
  {
    "zip": 39061,
    "lat": 33.08812,
    "lng": -90.81733,
    "state_id": "MS"
  },
  {
    "zip": 39062,
    "lat": 31.98509,
    "lng": -89.89836,
    "state_id": "MS"
  },
  {
    "zip": 39063,
    "lat": 33.1182,
    "lng": -89.89907,
    "state_id": "MS"
  },
  {
    "zip": 39066,
    "lat": 32.30198,
    "lng": -90.60565,
    "state_id": "MS"
  },
  {
    "zip": 39067,
    "lat": 33.11031,
    "lng": -89.4467,
    "state_id": "MS"
  },
  {
    "zip": 39069,
    "lat": 31.68626,
    "lng": -91.04597,
    "state_id": "MS"
  },
  {
    "zip": 39071,
    "lat": 32.55328,
    "lng": -90.31777,
    "state_id": "MS"
  },
  {
    "zip": 39073,
    "lat": 32.10176,
    "lng": -90.13333,
    "state_id": "MS"
  },
  {
    "zip": 39074,
    "lat": 32.39097,
    "lng": -89.47839,
    "state_id": "MS"
  },
  {
    "zip": 39078,
    "lat": 31.87707,
    "lng": -90.18895,
    "state_id": "MS"
  },
  {
    "zip": 39079,
    "lat": 32.93484,
    "lng": -89.89869,
    "state_id": "MS"
  },
  {
    "zip": 39082,
    "lat": 31.94582,
    "lng": -90.12174,
    "state_id": "MS"
  },
  {
    "zip": 39083,
    "lat": 31.83791,
    "lng": -90.47006,
    "state_id": "MS"
  },
  {
    "zip": 39086,
    "lat": 31.95832,
    "lng": -90.76335,
    "state_id": "MS"
  },
  {
    "zip": 39088,
    "lat": 32.74331,
    "lng": -90.73824,
    "state_id": "MS"
  },
  {
    "zip": 39090,
    "lat": 33.00422,
    "lng": -89.55406,
    "state_id": "MS"
  },
  {
    "zip": 39092,
    "lat": 32.31614,
    "lng": -89.37365,
    "state_id": "MS"
  },
  {
    "zip": 39094,
    "lat": 32.57821,
    "lng": -89.67588,
    "state_id": "MS"
  },
  {
    "zip": 39095,
    "lat": 33.12456,
    "lng": -90.08242,
    "state_id": "MS"
  },
  {
    "zip": 39096,
    "lat": 31.82548,
    "lng": -91.11572,
    "state_id": "MS"
  },
  {
    "zip": 39097,
    "lat": 32.99034,
    "lng": -90.59906,
    "state_id": "MS"
  },
  {
    "zip": 39108,
    "lat": 33.15864,
    "lng": -89.33319,
    "state_id": "MS"
  },
  {
    "zip": 39110,
    "lat": 32.50599,
    "lng": -90.14022,
    "state_id": "MS"
  },
  {
    "zip": 39111,
    "lat": 31.89316,
    "lng": -89.71877,
    "state_id": "MS"
  },
  {
    "zip": 39113,
    "lat": 32.91036,
    "lng": -91.02088,
    "state_id": "MS"
  },
  {
    "zip": 39114,
    "lat": 31.95185,
    "lng": -89.82891,
    "state_id": "MS"
  },
  {
    "zip": 39115,
    "lat": 33.04489,
    "lng": -90.56432,
    "state_id": "MS"
  },
  {
    "zip": 39116,
    "lat": 31.88968,
    "lng": -89.54988,
    "state_id": "MS"
  },
  {
    "zip": 39117,
    "lat": 32.30567,
    "lng": -89.65928,
    "state_id": "MS"
  },
  {
    "zip": 39119,
    "lat": 31.75288,
    "lng": -89.67334,
    "state_id": "MS"
  },
  {
    "zip": 39120,
    "lat": 31.51025,
    "lng": -91.35405,
    "state_id": "MS"
  },
  {
    "zip": 39140,
    "lat": 31.75173,
    "lng": -90.00273,
    "state_id": "MS"
  },
  {
    "zip": 39144,
    "lat": 31.83048,
    "lng": -90.80474,
    "state_id": "MS"
  },
  {
    "zip": 39145,
    "lat": 32.34293,
    "lng": -89.79806,
    "state_id": "MS"
  },
  {
    "zip": 39146,
    "lat": 32.87092,
    "lng": -89.99534,
    "state_id": "MS"
  },
  {
    "zip": 39149,
    "lat": 31.83346,
    "lng": -90.00904,
    "state_id": "MS"
  },
  {
    "zip": 39150,
    "lat": 31.9967,
    "lng": -90.98386,
    "state_id": "MS"
  },
  {
    "zip": 39152,
    "lat": 32.20928,
    "lng": -89.57703,
    "state_id": "MS"
  },
  {
    "zip": 39153,
    "lat": 32.05162,
    "lng": -89.49863,
    "state_id": "MS"
  },
  {
    "zip": 39154,
    "lat": 32.22581,
    "lng": -90.44898,
    "state_id": "MS"
  },
  {
    "zip": 39156,
    "lat": 32.54032,
    "lng": -90.77608,
    "state_id": "MS"
  },
  {
    "zip": 39157,
    "lat": 32.42667,
    "lng": -90.17147,
    "state_id": "MS"
  },
  {
    "zip": 39159,
    "lat": 32.82769,
    "lng": -90.94764,
    "state_id": "MS"
  },
  {
    "zip": 39160,
    "lat": 32.99301,
    "lng": -89.75747,
    "state_id": "MS"
  },
  {
    "zip": 39162,
    "lat": 32.62762,
    "lng": -90.63537,
    "state_id": "MS"
  },
  {
    "zip": 39166,
    "lat": 33.04034,
    "lng": -90.48061,
    "state_id": "MS"
  },
  {
    "zip": 39167,
    "lat": 32.09405,
    "lng": -90.0411,
    "state_id": "MS"
  },
  {
    "zip": 39168,
    "lat": 31.83297,
    "lng": -89.409,
    "state_id": "MS"
  },
  {
    "zip": 39169,
    "lat": 33.13655,
    "lng": -90.29019,
    "state_id": "MS"
  },
  {
    "zip": 39170,
    "lat": 32.11369,
    "lng": -90.33719,
    "state_id": "MS"
  },
  {
    "zip": 39174,
    "lat": 32.40152,
    "lng": -90.15949,
    "state_id": "MS"
  },
  {
    "zip": 39175,
    "lat": 32.09202,
    "lng": -90.62825,
    "state_id": "MS"
  },
  {
    "zip": 39176,
    "lat": 33.30197,
    "lng": -89.76658,
    "state_id": "MS"
  },
  {
    "zip": 39177,
    "lat": 32.62286,
    "lng": -90.80031,
    "state_id": "MS"
  },
  {
    "zip": 39179,
    "lat": 32.80401,
    "lng": -90.10161,
    "state_id": "MS"
  },
  {
    "zip": 39180,
    "lat": 32.23088,
    "lng": -90.85589,
    "state_id": "MS"
  },
  {
    "zip": 39183,
    "lat": 32.45228,
    "lng": -90.80658,
    "state_id": "MS"
  },
  {
    "zip": 39189,
    "lat": 32.605,
    "lng": -89.3989,
    "state_id": "MS"
  },
  {
    "zip": 39191,
    "lat": 31.71343,
    "lng": -90.41497,
    "state_id": "MS"
  },
  {
    "zip": 39192,
    "lat": 33.18903,
    "lng": -89.76033,
    "state_id": "MS"
  },
  {
    "zip": 39193,
    "lat": 32.23445,
    "lng": -90.07935,
    "state_id": "MS"
  },
  {
    "zip": 39194,
    "lat": 32.83609,
    "lng": -90.48743,
    "state_id": "MS"
  },
  {
    "zip": 39201,
    "lat": 32.28931,
    "lng": -90.18396,
    "state_id": "MS"
  },
  {
    "zip": 39202,
    "lat": 32.31029,
    "lng": -90.17096,
    "state_id": "MS"
  },
  {
    "zip": 39203,
    "lat": 32.30881,
    "lng": -90.20038,
    "state_id": "MS"
  },
  {
    "zip": 39204,
    "lat": 32.26813,
    "lng": -90.2269,
    "state_id": "MS"
  },
  {
    "zip": 39206,
    "lat": 32.37241,
    "lng": -90.17247,
    "state_id": "MS"
  },
  {
    "zip": 39208,
    "lat": 32.26039,
    "lng": -90.08962,
    "state_id": "MS"
  },
  {
    "zip": 39209,
    "lat": 32.39339,
    "lng": -90.30136,
    "state_id": "MS"
  },
  {
    "zip": 39211,
    "lat": 32.37175,
    "lng": -90.12222,
    "state_id": "MS"
  },
  {
    "zip": 39212,
    "lat": 32.2421,
    "lng": -90.2731,
    "state_id": "MS"
  },
  {
    "zip": 39213,
    "lat": 32.39247,
    "lng": -90.23371,
    "state_id": "MS"
  },
  {
    "zip": 39216,
    "lat": 32.33374,
    "lng": -90.16035,
    "state_id": "MS"
  },
  {
    "zip": 39217,
    "lat": 32.29911,
    "lng": -90.21045,
    "state_id": "MS"
  },
  {
    "zip": 39218,
    "lat": 32.22866,
    "lng": -90.15903,
    "state_id": "MS"
  },
  {
    "zip": 39232,
    "lat": 32.32976,
    "lng": -90.09163,
    "state_id": "MS"
  },
  {
    "zip": 39269,
    "lat": 32.30114,
    "lng": -90.1889,
    "state_id": "MS"
  },
  {
    "zip": 39272,
    "lat": 32.18881,
    "lng": -90.25946,
    "state_id": "MS"
  },
  {
    "zip": 39301,
    "lat": 32.26855,
    "lng": -88.58391,
    "state_id": "MS"
  },
  {
    "zip": 39305,
    "lat": 32.46657,
    "lng": -88.72002,
    "state_id": "MS"
  },
  {
    "zip": 39307,
    "lat": 32.33768,
    "lng": -88.79618,
    "state_id": "MS"
  },
  {
    "zip": 39309,
    "lat": 32.55039,
    "lng": -88.61318,
    "state_id": "MS"
  },
  {
    "zip": 39320,
    "lat": 32.56871,
    "lng": -88.75401,
    "state_id": "MS"
  },
  {
    "zip": 39322,
    "lat": 31.58415,
    "lng": -88.53434,
    "state_id": "MS"
  },
  {
    "zip": 39323,
    "lat": 32.35105,
    "lng": -88.92633,
    "state_id": "MS"
  },
  {
    "zip": 39325,
    "lat": 32.5643,
    "lng": -88.87498,
    "state_id": "MS"
  },
  {
    "zip": 39326,
    "lat": 32.59914,
    "lng": -88.67903,
    "state_id": "MS"
  },
  {
    "zip": 39327,
    "lat": 32.44578,
    "lng": -89.11948,
    "state_id": "MS"
  },
  {
    "zip": 39328,
    "lat": 32.74432,
    "lng": -88.70075,
    "state_id": "MS"
  },
  {
    "zip": 39330,
    "lat": 32.18563,
    "lng": -88.85772,
    "state_id": "MS"
  },
  {
    "zip": 39332,
    "lat": 32.30005,
    "lng": -89.00412,
    "state_id": "MS"
  },
  {
    "zip": 39335,
    "lat": 32.5354,
    "lng": -88.49037,
    "state_id": "MS"
  },
  {
    "zip": 39336,
    "lat": 32.28886,
    "lng": -89.26542,
    "state_id": "MS"
  },
  {
    "zip": 39337,
    "lat": 32.51415,
    "lng": -88.98056,
    "state_id": "MS"
  },
  {
    "zip": 39338,
    "lat": 32.11472,
    "lng": -89.22514,
    "state_id": "MS"
  },
  {
    "zip": 39339,
    "lat": 33.10146,
    "lng": -89.00835,
    "state_id": "MS"
  },
  {
    "zip": 39341,
    "lat": 33.10052,
    "lng": -88.56203,
    "state_id": "MS"
  },
  {
    "zip": 39342,
    "lat": 32.42445,
    "lng": -88.64837,
    "state_id": "MS"
  },
  {
    "zip": 39345,
    "lat": 32.29456,
    "lng": -89.12583,
    "state_id": "MS"
  },
  {
    "zip": 39346,
    "lat": 32.96205,
    "lng": -89.08969,
    "state_id": "MS"
  },
  {
    "zip": 39347,
    "lat": 32.05785,
    "lng": -88.93097,
    "state_id": "MS"
  },
  {
    "zip": 39348,
    "lat": 32.01058,
    "lng": -89.03125,
    "state_id": "MS"
  },
  {
    "zip": 39350,
    "lat": 32.79578,
    "lng": -89.12848,
    "state_id": "MS"
  },
  {
    "zip": 39352,
    "lat": 32.6844,
    "lng": -88.47989,
    "state_id": "MS"
  },
  {
    "zip": 39354,
    "lat": 32.88007,
    "lng": -88.83184,
    "state_id": "MS"
  },
  {
    "zip": 39355,
    "lat": 32.05253,
    "lng": -88.62348,
    "state_id": "MS"
  },
  {
    "zip": 39356,
    "lat": 32.13642,
    "lng": -89.02493,
    "state_id": "MS"
  },
  {
    "zip": 39358,
    "lat": 32.84397,
    "lng": -88.46413,
    "state_id": "MS"
  },
  {
    "zip": 39359,
    "lat": 32.56781,
    "lng": -89.33359,
    "state_id": "MS"
  },
  {
    "zip": 39360,
    "lat": 31.85895,
    "lng": -88.74884,
    "state_id": "MS"
  },
  {
    "zip": 39361,
    "lat": 32.98149,
    "lng": -88.61159,
    "state_id": "MS"
  },
  {
    "zip": 39362,
    "lat": 31.39196,
    "lng": -88.54069,
    "state_id": "MS"
  },
  {
    "zip": 39363,
    "lat": 32.12033,
    "lng": -88.77845,
    "state_id": "MS"
  },
  {
    "zip": 39364,
    "lat": 32.40922,
    "lng": -88.49598,
    "state_id": "MS"
  },
  {
    "zip": 39365,
    "lat": 32.60123,
    "lng": -89.12602,
    "state_id": "MS"
  },
  {
    "zip": 39366,
    "lat": 31.94157,
    "lng": -88.91662,
    "state_id": "MS"
  },
  {
    "zip": 39367,
    "lat": 31.67902,
    "lng": -88.66749,
    "state_id": "MS"
  },
  {
    "zip": 39401,
    "lat": 31.23265,
    "lng": -89.26717,
    "state_id": "MS"
  },
  {
    "zip": 39402,
    "lat": 31.3337,
    "lng": -89.41259,
    "state_id": "MS"
  },
  {
    "zip": 39406,
    "lat": 31.32844,
    "lng": -89.33449,
    "state_id": "MS"
  },
  {
    "zip": 39421,
    "lat": 31.48476,
    "lng": -89.712,
    "state_id": "MS"
  },
  {
    "zip": 39422,
    "lat": 31.96057,
    "lng": -89.25949,
    "state_id": "MS"
  },
  {
    "zip": 39423,
    "lat": 31.17101,
    "lng": -88.91489,
    "state_id": "MS"
  },
  {
    "zip": 39425,
    "lat": 31.05912,
    "lng": -89.07959,
    "state_id": "MS"
  },
  {
    "zip": 39426,
    "lat": 30.65371,
    "lng": -89.66106,
    "state_id": "MS"
  },
  {
    "zip": 39427,
    "lat": 31.48135,
    "lng": -89.82791,
    "state_id": "MS"
  },
  {
    "zip": 39428,
    "lat": 31.65047,
    "lng": -89.57337,
    "state_id": "MS"
  },
  {
    "zip": 39429,
    "lat": 31.24372,
    "lng": -89.76187,
    "state_id": "MS"
  },
  {
    "zip": 39437,
    "lat": 31.57844,
    "lng": -89.22003,
    "state_id": "MS"
  },
  {
    "zip": 39439,
    "lat": 31.85486,
    "lng": -89.01243,
    "state_id": "MS"
  },
  {
    "zip": 39440,
    "lat": 31.693,
    "lng": -89.14714,
    "state_id": "MS"
  },
  {
    "zip": 39443,
    "lat": 31.71378,
    "lng": -89.07595,
    "state_id": "MS"
  },
  {
    "zip": 39451,
    "lat": 31.17153,
    "lng": -88.6192,
    "state_id": "MS"
  },
  {
    "zip": 39452,
    "lat": 30.87871,
    "lng": -88.5996,
    "state_id": "MS"
  },
  {
    "zip": 39455,
    "lat": 31.01053,
    "lng": -89.46549,
    "state_id": "MS"
  },
  {
    "zip": 39456,
    "lat": 31.04537,
    "lng": -88.82994,
    "state_id": "MS"
  },
  {
    "zip": 39459,
    "lat": 31.4908,
    "lng": -89.30087,
    "state_id": "MS"
  },
  {
    "zip": 39461,
    "lat": 31.1828,
    "lng": -88.73818,
    "state_id": "MS"
  },
  {
    "zip": 39462,
    "lat": 31.22291,
    "lng": -89.06016,
    "state_id": "MS"
  },
  {
    "zip": 39464,
    "lat": 31.49249,
    "lng": -89.06775,
    "state_id": "MS"
  },
  {
    "zip": 39465,
    "lat": 31.34449,
    "lng": -89.18036,
    "state_id": "MS"
  },
  {
    "zip": 39466,
    "lat": 30.54011,
    "lng": -89.63859,
    "state_id": "MS"
  },
  {
    "zip": 39470,
    "lat": 30.81361,
    "lng": -89.57353,
    "state_id": "MS"
  },
  {
    "zip": 39474,
    "lat": 31.61225,
    "lng": -89.85545,
    "state_id": "MS"
  },
  {
    "zip": 39475,
    "lat": 31.15478,
    "lng": -89.41704,
    "state_id": "MS"
  },
  {
    "zip": 39476,
    "lat": 31.38345,
    "lng": -88.87558,
    "state_id": "MS"
  },
  {
    "zip": 39477,
    "lat": 31.7926,
    "lng": -89.03479,
    "state_id": "MS"
  },
  {
    "zip": 39478,
    "lat": 31.05446,
    "lng": -89.85759,
    "state_id": "MS"
  },
  {
    "zip": 39479,
    "lat": 31.53602,
    "lng": -89.46075,
    "state_id": "MS"
  },
  {
    "zip": 39480,
    "lat": 31.71061,
    "lng": -89.31219,
    "state_id": "MS"
  },
  {
    "zip": 39481,
    "lat": 31.86715,
    "lng": -89.2422,
    "state_id": "MS"
  },
  {
    "zip": 39482,
    "lat": 31.37264,
    "lng": -89.57858,
    "state_id": "MS"
  },
  {
    "zip": 39483,
    "lat": 31.23585,
    "lng": -89.92948,
    "state_id": "MS"
  },
  {
    "zip": 39501,
    "lat": 30.38317,
    "lng": -89.10346,
    "state_id": "MS"
  },
  {
    "zip": 39503,
    "lat": 30.47761,
    "lng": -89.1536,
    "state_id": "MS"
  },
  {
    "zip": 39507,
    "lat": 30.39914,
    "lng": -89.03582,
    "state_id": "MS"
  },
  {
    "zip": 39520,
    "lat": 30.26641,
    "lng": -89.47043,
    "state_id": "MS"
  },
  {
    "zip": 39525,
    "lat": 30.38173,
    "lng": -89.37028,
    "state_id": "MS"
  },
  {
    "zip": 39530,
    "lat": 30.32883,
    "lng": -88.95779,
    "state_id": "MS"
  },
  {
    "zip": 39531,
    "lat": 30.40422,
    "lng": -88.96775,
    "state_id": "MS"
  },
  {
    "zip": 39532,
    "lat": 30.49403,
    "lng": -88.96513,
    "state_id": "MS"
  },
  {
    "zip": 39534,
    "lat": 30.40672,
    "lng": -88.92114,
    "state_id": "MS"
  },
  {
    "zip": 39540,
    "lat": 30.44175,
    "lng": -88.89903,
    "state_id": "MS"
  },
  {
    "zip": 39553,
    "lat": 30.41322,
    "lng": -88.64751,
    "state_id": "MS"
  },
  {
    "zip": 39556,
    "lat": 30.44861,
    "lng": -89.43753,
    "state_id": "MS"
  },
  {
    "zip": 39560,
    "lat": 30.3754,
    "lng": -89.17436,
    "state_id": "MS"
  },
  {
    "zip": 39561,
    "lat": 30.70499,
    "lng": -89.15505,
    "state_id": "MS"
  },
  {
    "zip": 39562,
    "lat": 30.54705,
    "lng": -88.49275,
    "state_id": "MS"
  },
  {
    "zip": 39563,
    "lat": 30.42495,
    "lng": -88.52327,
    "state_id": "MS"
  },
  {
    "zip": 39564,
    "lat": 30.40499,
    "lng": -88.75452,
    "state_id": "MS"
  },
  {
    "zip": 39565,
    "lat": 30.58047,
    "lng": -88.7431,
    "state_id": "MS"
  },
  {
    "zip": 39567,
    "lat": 30.29492,
    "lng": -88.58611,
    "state_id": "MS"
  },
  {
    "zip": 39571,
    "lat": 30.41702,
    "lng": -89.28186,
    "state_id": "MS"
  },
  {
    "zip": 39572,
    "lat": 30.2507,
    "lng": -89.60124,
    "state_id": "MS"
  },
  {
    "zip": 39573,
    "lat": 30.72713,
    "lng": -89.09229,
    "state_id": "MS"
  },
  {
    "zip": 39574,
    "lat": 30.60749,
    "lng": -89.09007,
    "state_id": "MS"
  },
  {
    "zip": 39576,
    "lat": 30.28877,
    "lng": -89.38377,
    "state_id": "MS"
  },
  {
    "zip": 39577,
    "lat": 30.9061,
    "lng": -89.07637,
    "state_id": "MS"
  },
  {
    "zip": 39581,
    "lat": 30.3575,
    "lng": -88.4905,
    "state_id": "MS"
  },
  {
    "zip": 39601,
    "lat": 31.56536,
    "lng": -90.46973,
    "state_id": "MS"
  },
  {
    "zip": 39629,
    "lat": 31.43776,
    "lng": -90.45855,
    "state_id": "MS"
  },
  {
    "zip": 39630,
    "lat": 31.45932,
    "lng": -90.84563,
    "state_id": "MS"
  },
  {
    "zip": 39631,
    "lat": 31.07762,
    "lng": -91.09725,
    "state_id": "MS"
  },
  {
    "zip": 39633,
    "lat": 31.291,
    "lng": -91.18493,
    "state_id": "MS"
  },
  {
    "zip": 39635,
    "lat": 31.19144,
    "lng": -90.46345,
    "state_id": "MS"
  },
  {
    "zip": 39638,
    "lat": 31.22602,
    "lng": -90.9935,
    "state_id": "MS"
  },
  {
    "zip": 39641,
    "lat": 31.3567,
    "lng": -90.16983,
    "state_id": "MS"
  },
  {
    "zip": 39643,
    "lat": 31.244,
    "lng": -90.0194,
    "state_id": "MS"
  },
  {
    "zip": 39645,
    "lat": 31.14749,
    "lng": -90.79778,
    "state_id": "MS"
  },
  {
    "zip": 39647,
    "lat": 31.51575,
    "lng": -90.72011,
    "state_id": "MS"
  },
  {
    "zip": 39648,
    "lat": 31.18729,
    "lng": -90.38232,
    "state_id": "MS"
  },
  {
    "zip": 39652,
    "lat": 31.11253,
    "lng": -90.47403,
    "state_id": "MS"
  },
  {
    "zip": 39653,
    "lat": 31.46183,
    "lng": -90.86619,
    "state_id": "MS"
  },
  {
    "zip": 39654,
    "lat": 31.54357,
    "lng": -90.13543,
    "state_id": "MS"
  },
  {
    "zip": 39656,
    "lat": 31.44283,
    "lng": -89.93761,
    "state_id": "MS"
  },
  {
    "zip": 39657,
    "lat": 31.02567,
    "lng": -90.50366,
    "state_id": "MS"
  },
  {
    "zip": 39661,
    "lat": 31.48579,
    "lng": -91.0812,
    "state_id": "MS"
  },
  {
    "zip": 39662,
    "lat": 31.39081,
    "lng": -90.2943,
    "state_id": "MS"
  },
  {
    "zip": 39663,
    "lat": 31.58714,
    "lng": -90.02541,
    "state_id": "MS"
  },
  {
    "zip": 39664,
    "lat": 31.33096,
    "lng": -90.67976,
    "state_id": "MS"
  },
  {
    "zip": 39665,
    "lat": 31.63787,
    "lng": -90.21402,
    "state_id": "MS"
  },
  {
    "zip": 39666,
    "lat": 31.29094,
    "lng": -90.48192,
    "state_id": "MS"
  },
  {
    "zip": 39667,
    "lat": 31.12978,
    "lng": -90.12535,
    "state_id": "MS"
  },
  {
    "zip": 39668,
    "lat": 31.69112,
    "lng": -90.79058,
    "state_id": "MS"
  },
  {
    "zip": 39669,
    "lat": 31.14245,
    "lng": -91.3969,
    "state_id": "MS"
  },
  {
    "zip": 39701,
    "lat": 33.41082,
    "lng": -88.50472,
    "state_id": "MS"
  },
  {
    "zip": 39702,
    "lat": 33.43974,
    "lng": -88.34479,
    "state_id": "MS"
  },
  {
    "zip": 39705,
    "lat": 33.58606,
    "lng": -88.43574,
    "state_id": "MS"
  },
  {
    "zip": 39730,
    "lat": 33.8418,
    "lng": -88.56421,
    "state_id": "MS"
  },
  {
    "zip": 39735,
    "lat": 33.34737,
    "lng": -89.18525,
    "state_id": "MS"
  },
  {
    "zip": 39736,
    "lat": 33.43195,
    "lng": -88.65228,
    "state_id": "MS"
  },
  {
    "zip": 39737,
    "lat": 33.67003,
    "lng": -89.32741,
    "state_id": "MS"
  },
  {
    "zip": 39739,
    "lat": 33.23963,
    "lng": -88.57382,
    "state_id": "MS"
  },
  {
    "zip": 39740,
    "lat": 33.72185,
    "lng": -88.31218,
    "state_id": "MS"
  },
  {
    "zip": 39741,
    "lat": 33.65137,
    "lng": -88.86238,
    "state_id": "MS"
  },
  {
    "zip": 39743,
    "lat": 33.32189,
    "lng": -88.61306,
    "state_id": "MS"
  },
  {
    "zip": 39744,
    "lat": 33.58209,
    "lng": -89.30713,
    "state_id": "MS"
  },
  {
    "zip": 39745,
    "lat": 33.29845,
    "lng": -89.45057,
    "state_id": "MS"
  },
  {
    "zip": 39746,
    "lat": 33.74841,
    "lng": -88.41418,
    "state_id": "MS"
  },
  {
    "zip": 39747,
    "lat": 33.40855,
    "lng": -89.55494,
    "state_id": "MS"
  },
  {
    "zip": 39750,
    "lat": 33.56857,
    "lng": -89.0605,
    "state_id": "MS"
  },
  {
    "zip": 39751,
    "lat": 33.70516,
    "lng": -89.10514,
    "state_id": "MS"
  },
  {
    "zip": 39752,
    "lat": 33.51957,
    "lng": -89.15446,
    "state_id": "MS"
  },
  {
    "zip": 39755,
    "lat": 33.61192,
    "lng": -88.95385,
    "state_id": "MS"
  },
  {
    "zip": 39756,
    "lat": 33.78648,
    "lng": -88.74123,
    "state_id": "MS"
  },
  {
    "zip": 39759,
    "lat": 33.43662,
    "lng": -88.82643,
    "state_id": "MS"
  },
  {
    "zip": 39760,
    "lat": 33.46413,
    "lng": -88.70508,
    "state_id": "MS"
  },
  {
    "zip": 39762,
    "lat": 33.45273,
    "lng": -88.79521,
    "state_id": "MS"
  },
  {
    "zip": 39766,
    "lat": 33.58953,
    "lng": -88.31312,
    "state_id": "MS"
  },
  {
    "zip": 39767,
    "lat": 33.47662,
    "lng": -89.44679,
    "state_id": "MS"
  },
  {
    "zip": 39769,
    "lat": 33.3149,
    "lng": -89.00978,
    "state_id": "MS"
  },
  {
    "zip": 39771,
    "lat": 33.60697,
    "lng": -89.27517,
    "state_id": "MS"
  },
  {
    "zip": 39772,
    "lat": 33.30449,
    "lng": -89.29571,
    "state_id": "MS"
  },
  {
    "zip": 39773,
    "lat": 33.64257,
    "lng": -88.68528,
    "state_id": "MS"
  },
  {
    "zip": 39776,
    "lat": 33.78226,
    "lng": -89.04258,
    "state_id": "MS"
  },
  {
    "zip": 59001,
    "lat": 45.52642,
    "lng": -109.5245,
    "state_id": "MT"
  },
  {
    "zip": 59002,
    "lat": 45.98428,
    "lng": -108.67398,
    "state_id": "MT"
  },
  {
    "zip": 59003,
    "lat": 45.46675,
    "lng": -106.19541,
    "state_id": "MT"
  },
  {
    "zip": 59006,
    "lat": 45.90861,
    "lng": -108.08042,
    "state_id": "MT"
  },
  {
    "zip": 59007,
    "lat": 45.13859,
    "lng": -109.14139,
    "state_id": "MT"
  },
  {
    "zip": 59008,
    "lat": 45.07284,
    "lng": -109.05888,
    "state_id": "MT"
  },
  {
    "zip": 59010,
    "lat": 45.99697,
    "lng": -107.30997,
    "state_id": "MT"
  },
  {
    "zip": 59011,
    "lat": 45.9299,
    "lng": -109.97895,
    "state_id": "MT"
  },
  {
    "zip": 59012,
    "lat": 45.27317,
    "lng": -106.49272,
    "state_id": "MT"
  },
  {
    "zip": 59013,
    "lat": 45.45604,
    "lng": -109.08717,
    "state_id": "MT"
  },
  {
    "zip": 59014,
    "lat": 45.19433,
    "lng": -108.76985,
    "state_id": "MT"
  },
  {
    "zip": 59015,
    "lat": 46.07518,
    "lng": -108.83149,
    "state_id": "MT"
  },
  {
    "zip": 59016,
    "lat": 45.41077,
    "lng": -106.96221,
    "state_id": "MT"
  },
  {
    "zip": 59018,
    "lat": 45.86107,
    "lng": -110.56342,
    "state_id": "MT"
  },
  {
    "zip": 59019,
    "lat": 45.65095,
    "lng": -109.2457,
    "state_id": "MT"
  },
  {
    "zip": 59020,
    "lat": 45.09558,
    "lng": -110.02575,
    "state_id": "MT"
  },
  {
    "zip": 59022,
    "lat": 45.54103,
    "lng": -107.28431,
    "state_id": "MT"
  },
  {
    "zip": 59024,
    "lat": 46.20104,
    "lng": -107.71123,
    "state_id": "MT"
  },
  {
    "zip": 59025,
    "lat": 45.09485,
    "lng": -106.63038,
    "state_id": "MT"
  },
  {
    "zip": 59026,
    "lat": 45.47218,
    "lng": -108.80055,
    "state_id": "MT"
  },
  {
    "zip": 59027,
    "lat": 45.24021,
    "lng": -110.9063,
    "state_id": "MT"
  },
  {
    "zip": 59028,
    "lat": 45.34492,
    "lng": -109.68506,
    "state_id": "MT"
  },
  {
    "zip": 59029,
    "lat": 45.40732,
    "lng": -108.80085,
    "state_id": "MT"
  },
  {
    "zip": 59030,
    "lat": 45.08481,
    "lng": -110.57248,
    "state_id": "MT"
  },
  {
    "zip": 59031,
    "lat": 45.50144,
    "lng": -107.42008,
    "state_id": "MT"
  },
  {
    "zip": 59032,
    "lat": 47.0227,
    "lng": -108.74436,
    "state_id": "MT"
  },
  {
    "zip": 59033,
    "lat": 45.72862,
    "lng": -109.70192,
    "state_id": "MT"
  },
  {
    "zip": 59034,
    "lat": 45.69788,
    "lng": -107.68068,
    "state_id": "MT"
  },
  {
    "zip": 59035,
    "lat": 45.29237,
    "lng": -107.96723,
    "state_id": "MT"
  },
  {
    "zip": 59036,
    "lat": 46.48592,
    "lng": -109.93495,
    "state_id": "MT"
  },
  {
    "zip": 59037,
    "lat": 45.80627,
    "lng": -108.21282,
    "state_id": "MT"
  },
  {
    "zip": 59038,
    "lat": 46.19039,
    "lng": -107.22703,
    "state_id": "MT"
  },
  {
    "zip": 59039,
    "lat": 46.69466,
    "lng": -107.29021,
    "state_id": "MT"
  },
  {
    "zip": 59041,
    "lat": 45.49743,
    "lng": -108.97689,
    "state_id": "MT"
  },
  {
    "zip": 59043,
    "lat": 45.52619,
    "lng": -106.6504,
    "state_id": "MT"
  },
  {
    "zip": 59044,
    "lat": 45.63607,
    "lng": -108.74525,
    "state_id": "MT"
  },
  {
    "zip": 59046,
    "lat": 46.4014,
    "lng": -109.00853,
    "state_id": "MT"
  },
  {
    "zip": 59047,
    "lat": 45.54805,
    "lng": -110.569,
    "state_id": "MT"
  },
  {
    "zip": 59050,
    "lat": 45.24092,
    "lng": -107.5271,
    "state_id": "MT"
  },
  {
    "zip": 59052,
    "lat": 45.43122,
    "lng": -110.18328,
    "state_id": "MT"
  },
  {
    "zip": 59053,
    "lat": 46.44501,
    "lng": -110.4549,
    "state_id": "MT"
  },
  {
    "zip": 59054,
    "lat": 46.64222,
    "lng": -107.73319,
    "state_id": "MT"
  },
  {
    "zip": 59055,
    "lat": 46.10414,
    "lng": -109.78086,
    "state_id": "MT"
  },
  {
    "zip": 59057,
    "lat": 45.85203,
    "lng": -108.94186,
    "state_id": "MT"
  },
  {
    "zip": 59058,
    "lat": 47.08099,
    "lng": -107.78958,
    "state_id": "MT"
  },
  {
    "zip": 59059,
    "lat": 46.40735,
    "lng": -108.07478,
    "state_id": "MT"
  },
  {
    "zip": 59061,
    "lat": 45.34997,
    "lng": -109.87707,
    "state_id": "MT"
  },
  {
    "zip": 59062,
    "lat": 45.0903,
    "lng": -106.14997,
    "state_id": "MT"
  },
  {
    "zip": 59063,
    "lat": 45.66524,
    "lng": -108.96381,
    "state_id": "MT"
  },
  {
    "zip": 59064,
    "lat": 45.88238,
    "lng": -107.90781,
    "state_id": "MT"
  },
  {
    "zip": 59065,
    "lat": 45.27875,
    "lng": -110.73092,
    "state_id": "MT"
  },
  {
    "zip": 59066,
    "lat": 45.33741,
    "lng": -108.49493,
    "state_id": "MT"
  },
  {
    "zip": 59067,
    "lat": 45.98262,
    "lng": -109.2388,
    "state_id": "MT"
  },
  {
    "zip": 59068,
    "lat": 45.15835,
    "lng": -109.44983,
    "state_id": "MT"
  },
  {
    "zip": 59069,
    "lat": 45.71888,
    "lng": -109.60081,
    "state_id": "MT"
  },
  {
    "zip": 59070,
    "lat": 45.36906,
    "lng": -109.18417,
    "state_id": "MT"
  },
  {
    "zip": 59071,
    "lat": 45.26807,
    "lng": -109.56486,
    "state_id": "MT"
  },
  {
    "zip": 59072,
    "lat": 46.4691,
    "lng": -108.53766,
    "state_id": "MT"
  },
  {
    "zip": 59074,
    "lat": 46.36786,
    "lng": -109.31631,
    "state_id": "MT"
  },
  {
    "zip": 59075,
    "lat": 45.39855,
    "lng": -107.88926,
    "state_id": "MT"
  },
  {
    "zip": 59076,
    "lat": 46.27876,
    "lng": -107.07395,
    "state_id": "MT"
  },
  {
    "zip": 59077,
    "lat": 47.1839,
    "lng": -107.58782,
    "state_id": "MT"
  },
  {
    "zip": 59078,
    "lat": 46.34172,
    "lng": -109.53547,
    "state_id": "MT"
  },
  {
    "zip": 59079,
    "lat": 46.06377,
    "lng": -108.35367,
    "state_id": "MT"
  },
  {
    "zip": 59081,
    "lat": 45.00851,
    "lng": -109.97286,
    "state_id": "MT"
  },
  {
    "zip": 59082,
    "lat": 45.74188,
    "lng": -110.2249,
    "state_id": "MT"
  },
  {
    "zip": 59085,
    "lat": 46.36321,
    "lng": -110.10736,
    "state_id": "MT"
  },
  {
    "zip": 59086,
    "lat": 46.06705,
    "lng": -110.56173,
    "state_id": "MT"
  },
  {
    "zip": 59087,
    "lat": 47.13236,
    "lng": -108.21161,
    "state_id": "MT"
  },
  {
    "zip": 59088,
    "lat": 46.1305,
    "lng": -108.04196,
    "state_id": "MT"
  },
  {
    "zip": 59089,
    "lat": 45.0772,
    "lng": -107.35526,
    "state_id": "MT"
  },
  {
    "zip": 59101,
    "lat": 45.61405,
    "lng": -108.38675,
    "state_id": "MT"
  },
  {
    "zip": 59102,
    "lat": 45.77569,
    "lng": -108.58016,
    "state_id": "MT"
  },
  {
    "zip": 59105,
    "lat": 45.88269,
    "lng": -108.50585,
    "state_id": "MT"
  },
  {
    "zip": 59106,
    "lat": 45.80792,
    "lng": -108.6834,
    "state_id": "MT"
  },
  {
    "zip": 59201,
    "lat": 48.1729,
    "lng": -105.66875,
    "state_id": "MT"
  },
  {
    "zip": 59211,
    "lat": 48.69453,
    "lng": -104.35835,
    "state_id": "MT"
  },
  {
    "zip": 59212,
    "lat": 48.18722,
    "lng": -104.19086,
    "state_id": "MT"
  },
  {
    "zip": 59213,
    "lat": 48.18464,
    "lng": -104.87858,
    "state_id": "MT"
  },
  {
    "zip": 59214,
    "lat": 47.30032,
    "lng": -106.01361,
    "state_id": "MT"
  },
  {
    "zip": 59215,
    "lat": 47.50394,
    "lng": -105.74629,
    "state_id": "MT"
  },
  {
    "zip": 59217,
    "lat": 47.5793,
    "lng": -104.24949,
    "state_id": "MT"
  },
  {
    "zip": 59218,
    "lat": 48.10261,
    "lng": -104.55206,
    "state_id": "MT"
  },
  {
    "zip": 59219,
    "lat": 48.53856,
    "lng": -104.17756,
    "state_id": "MT"
  },
  {
    "zip": 59221,
    "lat": 47.92807,
    "lng": -104.19224,
    "state_id": "MT"
  },
  {
    "zip": 59222,
    "lat": 48.7042,
    "lng": -105.14527,
    "state_id": "MT"
  },
  {
    "zip": 59223,
    "lat": 47.89166,
    "lng": -106.2098,
    "state_id": "MT"
  },
  {
    "zip": 59225,
    "lat": 48.28847,
    "lng": -105.97934,
    "state_id": "MT"
  },
  {
    "zip": 59226,
    "lat": 48.34852,
    "lng": -104.58038,
    "state_id": "MT"
  },
  {
    "zip": 59230,
    "lat": 48.09859,
    "lng": -106.83538,
    "state_id": "MT"
  },
  {
    "zip": 59231,
    "lat": 48.39802,
    "lng": -106.54804,
    "state_id": "MT"
  },
  {
    "zip": 59240,
    "lat": 48.84216,
    "lng": -106.23569,
    "state_id": "MT"
  },
  {
    "zip": 59241,
    "lat": 48.46975,
    "lng": -107.05858,
    "state_id": "MT"
  },
  {
    "zip": 59242,
    "lat": 48.43505,
    "lng": -104.4389,
    "state_id": "MT"
  },
  {
    "zip": 59243,
    "lat": 47.75213,
    "lng": -104.67241,
    "state_id": "MT"
  },
  {
    "zip": 59244,
    "lat": 48.60423,
    "lng": -106.31973,
    "state_id": "MT"
  },
  {
    "zip": 59247,
    "lat": 48.51172,
    "lng": -104.53503,
    "state_id": "MT"
  },
  {
    "zip": 59248,
    "lat": 48.26666,
    "lng": -106.26984,
    "state_id": "MT"
  },
  {
    "zip": 59250,
    "lat": 48.85642,
    "lng": -106.57354,
    "state_id": "MT"
  },
  {
    "zip": 59252,
    "lat": 48.92181,
    "lng": -104.80926,
    "state_id": "MT"
  },
  {
    "zip": 59253,
    "lat": 48.71822,
    "lng": -105.8763,
    "state_id": "MT"
  },
  {
    "zip": 59254,
    "lat": 48.74875,
    "lng": -104.6076,
    "state_id": "MT"
  },
  {
    "zip": 59255,
    "lat": 48.2446,
    "lng": -105.16804,
    "state_id": "MT"
  },
  {
    "zip": 59256,
    "lat": 48.91653,
    "lng": -104.55461,
    "state_id": "MT"
  },
  {
    "zip": 59257,
    "lat": 48.75446,
    "lng": -104.94202,
    "state_id": "MT"
  },
  {
    "zip": 59258,
    "lat": 48.57825,
    "lng": -104.69461,
    "state_id": "MT"
  },
  {
    "zip": 59259,
    "lat": 47.68491,
    "lng": -105.08661,
    "state_id": "MT"
  },
  {
    "zip": 59260,
    "lat": 48.8247,
    "lng": -106.07217,
    "state_id": "MT"
  },
  {
    "zip": 59261,
    "lat": 48.5825,
    "lng": -107.38539,
    "state_id": "MT"
  },
  {
    "zip": 59262,
    "lat": 47.48778,
    "lng": -104.50347,
    "state_id": "MT"
  },
  {
    "zip": 59263,
    "lat": 48.78344,
    "lng": -105.52668,
    "state_id": "MT"
  },
  {
    "zip": 59270,
    "lat": 47.57573,
    "lng": -104.08014,
    "state_id": "MT"
  },
  {
    "zip": 59274,
    "lat": 47.82087,
    "lng": -105.47882,
    "state_id": "MT"
  },
  {
    "zip": 59275,
    "lat": 48.87778,
    "lng": -104.20482,
    "state_id": "MT"
  },
  {
    "zip": 59276,
    "lat": 48.93196,
    "lng": -105.18495,
    "state_id": "MT"
  },
  {
    "zip": 59301,
    "lat": 46.29014,
    "lng": -105.7613,
    "state_id": "MT"
  },
  {
    "zip": 59311,
    "lat": 45.18077,
    "lng": -104.43071,
    "state_id": "MT"
  },
  {
    "zip": 59312,
    "lat": 47.01122,
    "lng": -106.20525,
    "state_id": "MT"
  },
  {
    "zip": 59313,
    "lat": 46.30559,
    "lng": -104.24971,
    "state_id": "MT"
  },
  {
    "zip": 59314,
    "lat": 45.07361,
    "lng": -105.36909,
    "state_id": "MT"
  },
  {
    "zip": 59315,
    "lat": 47.44087,
    "lng": -104.86239,
    "state_id": "MT"
  },
  {
    "zip": 59317,
    "lat": 45.36386,
    "lng": -105.49341,
    "state_id": "MT"
  },
  {
    "zip": 59318,
    "lat": 47.49141,
    "lng": -107.48345,
    "state_id": "MT"
  },
  {
    "zip": 59322,
    "lat": 47.01452,
    "lng": -106.67992,
    "state_id": "MT"
  },
  {
    "zip": 59323,
    "lat": 45.98499,
    "lng": -106.67019,
    "state_id": "MT"
  },
  {
    "zip": 59324,
    "lat": 45.78588,
    "lng": -104.5465,
    "state_id": "MT"
  },
  {
    "zip": 59326,
    "lat": 46.76265,
    "lng": -104.84331,
    "state_id": "MT"
  },
  {
    "zip": 59327,
    "lat": 46.2524,
    "lng": -106.70624,
    "state_id": "MT"
  },
  {
    "zip": 59330,
    "lat": 47.09687,
    "lng": -104.75104,
    "state_id": "MT"
  },
  {
    "zip": 59332,
    "lat": 45.36711,
    "lng": -104.76841,
    "state_id": "MT"
  },
  {
    "zip": 59333,
    "lat": 46.28008,
    "lng": -106.21822,
    "state_id": "MT"
  },
  {
    "zip": 59336,
    "lat": 46.33786,
    "lng": -105.01486,
    "state_id": "MT"
  },
  {
    "zip": 59337,
    "lat": 47.3852,
    "lng": -106.80168,
    "state_id": "MT"
  },
  {
    "zip": 59338,
    "lat": 46.63606,
    "lng": -105.65565,
    "state_id": "MT"
  },
  {
    "zip": 59339,
    "lat": 47.26372,
    "lng": -105.18592,
    "state_id": "MT"
  },
  {
    "zip": 59343,
    "lat": 45.65675,
    "lng": -105.43796,
    "state_id": "MT"
  },
  {
    "zip": 59344,
    "lat": 46.41199,
    "lng": -104.59529,
    "state_id": "MT"
  },
  {
    "zip": 59347,
    "lat": 46.43522,
    "lng": -106.35207,
    "state_id": "MT"
  },
  {
    "zip": 59349,
    "lat": 46.88446,
    "lng": -105.5113,
    "state_id": "MT"
  },
  {
    "zip": 59351,
    "lat": 45.75297,
    "lng": -105.76015,
    "state_id": "MT"
  },
  {
    "zip": 59353,
    "lat": 46.96525,
    "lng": -104.24901,
    "state_id": "MT"
  },
  {
    "zip": 59354,
    "lat": 46.20586,
    "lng": -104.39787,
    "state_id": "MT"
  },
  {
    "zip": 59401,
    "lat": 47.51103,
    "lng": -111.27552,
    "state_id": "MT"
  },
  {
    "zip": 59404,
    "lat": 47.63059,
    "lng": -111.3471,
    "state_id": "MT"
  },
  {
    "zip": 59405,
    "lat": 47.30522,
    "lng": -111.28924,
    "state_id": "MT"
  },
  {
    "zip": 59410,
    "lat": 47.48417,
    "lng": -112.65292,
    "state_id": "MT"
  },
  {
    "zip": 59411,
    "lat": 48.87269,
    "lng": -113.40785,
    "state_id": "MT"
  },
  {
    "zip": 59412,
    "lat": 47.32714,
    "lng": -110.89218,
    "state_id": "MT"
  },
  {
    "zip": 59414,
    "lat": 47.52734,
    "lng": -111.26797,
    "state_id": "MT"
  },
  {
    "zip": 59416,
    "lat": 48.04197,
    "lng": -111.49611,
    "state_id": "MT"
  },
  {
    "zip": 59417,
    "lat": 48.67267,
    "lng": -113.191,
    "state_id": "MT"
  },
  {
    "zip": 59418,
    "lat": 46.79849,
    "lng": -109.91236,
    "state_id": "MT"
  },
  {
    "zip": 59419,
    "lat": 47.9863,
    "lng": -112.40858,
    "state_id": "MT"
  },
  {
    "zip": 59420,
    "lat": 47.88107,
    "lng": -111.03349,
    "state_id": "MT"
  },
  {
    "zip": 59421,
    "lat": 47.19433,
    "lng": -111.7364,
    "state_id": "MT"
  },
  {
    "zip": 59422,
    "lat": 47.81702,
    "lng": -112.47353,
    "state_id": "MT"
  },
  {
    "zip": 59424,
    "lat": 47.34665,
    "lng": -110.09078,
    "state_id": "MT"
  },
  {
    "zip": 59425,
    "lat": 48.1826,
    "lng": -111.8938,
    "state_id": "MT"
  },
  {
    "zip": 59427,
    "lat": 48.7707,
    "lng": -112.54045,
    "state_id": "MT"
  },
  {
    "zip": 59430,
    "lat": 47.43476,
    "lng": -109.84057,
    "state_id": "MT"
  },
  {
    "zip": 59432,
    "lat": 48.16843,
    "lng": -112.40394,
    "state_id": "MT"
  },
  {
    "zip": 59433,
    "lat": 47.89582,
    "lng": -111.70633,
    "state_id": "MT"
  },
  {
    "zip": 59434,
    "lat": 48.48135,
    "lng": -113.35533,
    "state_id": "MT"
  },
  {
    "zip": 59436,
    "lat": 47.61494,
    "lng": -112.07166,
    "state_id": "MT"
  },
  {
    "zip": 59440,
    "lat": 47.72565,
    "lng": -111.15714,
    "state_id": "MT"
  },
  {
    "zip": 59441,
    "lat": 46.85917,
    "lng": -108.98017,
    "state_id": "MT"
  },
  {
    "zip": 59442,
    "lat": 47.92931,
    "lng": -110.61174,
    "state_id": "MT"
  },
  {
    "zip": 59443,
    "lat": 47.56256,
    "lng": -111.82913,
    "state_id": "MT"
  },
  {
    "zip": 59444,
    "lat": 48.72526,
    "lng": -111.36528,
    "state_id": "MT"
  },
  {
    "zip": 59446,
    "lat": 47.61922,
    "lng": -110.19282,
    "state_id": "MT"
  },
  {
    "zip": 59447,
    "lat": 47.26791,
    "lng": -110.47452,
    "state_id": "MT"
  },
  {
    "zip": 59448,
    "lat": 48.25859,
    "lng": -112.81432,
    "state_id": "MT"
  },
  {
    "zip": 59450,
    "lat": 47.59739,
    "lng": -110.73125,
    "state_id": "MT"
  },
  {
    "zip": 59451,
    "lat": 47.38991,
    "lng": -109.38616,
    "state_id": "MT"
  },
  {
    "zip": 59452,
    "lat": 46.86223,
    "lng": -110.10977,
    "state_id": "MT"
  },
  {
    "zip": 59453,
    "lat": 46.64953,
    "lng": -109.63272,
    "state_id": "MT"
  },
  {
    "zip": 59454,
    "lat": 48.71695,
    "lng": -112.02463,
    "state_id": "MT"
  },
  {
    "zip": 59456,
    "lat": 48.26448,
    "lng": -111.38867,
    "state_id": "MT"
  },
  {
    "zip": 59457,
    "lat": 47.09217,
    "lng": -109.36047,
    "state_id": "MT"
  },
  {
    "zip": 59460,
    "lat": 48.08759,
    "lng": -110.50842,
    "state_id": "MT"
  },
  {
    "zip": 59461,
    "lat": 48.48476,
    "lng": -111.20669,
    "state_id": "MT"
  },
  {
    "zip": 59462,
    "lat": 47.11797,
    "lng": -109.88733,
    "state_id": "MT"
  },
  {
    "zip": 59463,
    "lat": 47.03563,
    "lng": -110.83089,
    "state_id": "MT"
  },
  {
    "zip": 59464,
    "lat": 46.9045,
    "lng": -109.65198,
    "state_id": "MT"
  },
  {
    "zip": 59465,
    "lat": 46.89031,
    "lng": -110.72027,
    "state_id": "MT"
  },
  {
    "zip": 59466,
    "lat": 48.75838,
    "lng": -111.6667,
    "state_id": "MT"
  },
  {
    "zip": 59467,
    "lat": 48.07972,
    "lng": -112.58013,
    "state_id": "MT"
  },
  {
    "zip": 59468,
    "lat": 47.71024,
    "lng": -111.6216,
    "state_id": "MT"
  },
  {
    "zip": 59469,
    "lat": 47.20156,
    "lng": -110.70173,
    "state_id": "MT"
  },
  {
    "zip": 59471,
    "lat": 47.42131,
    "lng": -108.75945,
    "state_id": "MT"
  },
  {
    "zip": 59472,
    "lat": 47.3985,
    "lng": -111.16353,
    "state_id": "MT"
  },
  {
    "zip": 59474,
    "lat": 48.51891,
    "lng": -111.73779,
    "state_id": "MT"
  },
  {
    "zip": 59477,
    "lat": 47.45509,
    "lng": -111.91955,
    "state_id": "MT"
  },
  {
    "zip": 59479,
    "lat": 47.01982,
    "lng": -110.3311,
    "state_id": "MT"
  },
  {
    "zip": 59480,
    "lat": 47.19327,
    "lng": -111.17675,
    "state_id": "MT"
  },
  {
    "zip": 59482,
    "lat": 48.87288,
    "lng": -111.90786,
    "state_id": "MT"
  },
  {
    "zip": 59483,
    "lat": 47.45534,
    "lng": -111.69927,
    "state_id": "MT"
  },
  {
    "zip": 59484,
    "lat": 48.96737,
    "lng": -111.65972,
    "state_id": "MT"
  },
  {
    "zip": 59485,
    "lat": 47.40656,
    "lng": -111.54358,
    "state_id": "MT"
  },
  {
    "zip": 59486,
    "lat": 48.31576,
    "lng": -112.38402,
    "state_id": "MT"
  },
  {
    "zip": 59487,
    "lat": 47.58346,
    "lng": -111.6436,
    "state_id": "MT"
  },
  {
    "zip": 59489,
    "lat": 47.64038,
    "lng": -109.23598,
    "state_id": "MT"
  },
  {
    "zip": 59501,
    "lat": 48.68878,
    "lng": -109.79899,
    "state_id": "MT"
  },
  {
    "zip": 59520,
    "lat": 48.0365,
    "lng": -109.94551,
    "state_id": "MT"
  },
  {
    "zip": 59521,
    "lat": 48.30096,
    "lng": -109.94099,
    "state_id": "MT"
  },
  {
    "zip": 59522,
    "lat": 48.54167,
    "lng": -111.00323,
    "state_id": "MT"
  },
  {
    "zip": 59523,
    "lat": 48.54585,
    "lng": -109.22978,
    "state_id": "MT"
  },
  {
    "zip": 59524,
    "lat": 48.23564,
    "lng": -108.31918,
    "state_id": "MT"
  },
  {
    "zip": 59525,
    "lat": 48.69459,
    "lng": -110.31205,
    "state_id": "MT"
  },
  {
    "zip": 59526,
    "lat": 48.43613,
    "lng": -108.71494,
    "state_id": "MT"
  },
  {
    "zip": 59527,
    "lat": 48.04656,
    "lng": -108.74784,
    "state_id": "MT"
  },
  {
    "zip": 59528,
    "lat": 48.56857,
    "lng": -110.40091,
    "state_id": "MT"
  },
  {
    "zip": 59529,
    "lat": 48.82648,
    "lng": -108.63418,
    "state_id": "MT"
  },
  {
    "zip": 59530,
    "lat": 48.62509,
    "lng": -110.69139,
    "state_id": "MT"
  },
  {
    "zip": 59531,
    "lat": 48.78978,
    "lng": -110.80521,
    "state_id": "MT"
  },
  {
    "zip": 59532,
    "lat": 48.5771,
    "lng": -110.09665,
    "state_id": "MT"
  },
  {
    "zip": 59535,
    "lat": 47.96372,
    "lng": -109.26738,
    "state_id": "MT"
  },
  {
    "zip": 59537,
    "lat": 48.82806,
    "lng": -108.04618,
    "state_id": "MT"
  },
  {
    "zip": 59538,
    "lat": 48.07292,
    "lng": -107.8404,
    "state_id": "MT"
  },
  {
    "zip": 59540,
    "lat": 48.59871,
    "lng": -110.55555,
    "state_id": "MT"
  },
  {
    "zip": 59542,
    "lat": 48.78746,
    "lng": -108.37609,
    "state_id": "MT"
  },
  {
    "zip": 59544,
    "lat": 48.84308,
    "lng": -107.55539,
    "state_id": "MT"
  },
  {
    "zip": 59545,
    "lat": 48.93242,
    "lng": -111.16864,
    "state_id": "MT"
  },
  {
    "zip": 59546,
    "lat": 47.76973,
    "lng": -108.59198,
    "state_id": "MT"
  },
  {
    "zip": 59547,
    "lat": 48.65706,
    "lng": -109.01579,
    "state_id": "MT"
  },
  {
    "zip": 59601,
    "lat": 46.53983,
    "lng": -112.17642,
    "state_id": "MT"
  },
  {
    "zip": 59602,
    "lat": 46.74009,
    "lng": -111.91254,
    "state_id": "MT"
  },
  {
    "zip": 59631,
    "lat": 46.3121,
    "lng": -112.36463,
    "state_id": "MT"
  },
  {
    "zip": 59632,
    "lat": 46.17687,
    "lng": -112.0216,
    "state_id": "MT"
  },
  {
    "zip": 59633,
    "lat": 46.81903,
    "lng": -112.35917,
    "state_id": "MT"
  },
  {
    "zip": 59634,
    "lat": 46.46896,
    "lng": -111.97465,
    "state_id": "MT"
  },
  {
    "zip": 59635,
    "lat": 46.56251,
    "lng": -111.82796,
    "state_id": "MT"
  },
  {
    "zip": 59636,
    "lat": 46.61972,
    "lng": -112.10993,
    "state_id": "MT"
  },
  {
    "zip": 59638,
    "lat": 46.36037,
    "lng": -111.96815,
    "state_id": "MT"
  },
  {
    "zip": 59639,
    "lat": 47.09251,
    "lng": -112.69228,
    "state_id": "MT"
  },
  {
    "zip": 59640,
    "lat": 46.73543,
    "lng": -112.30527,
    "state_id": "MT"
  },
  {
    "zip": 59642,
    "lat": 46.28236,
    "lng": -110.72436,
    "state_id": "MT"
  },
  {
    "zip": 59643,
    "lat": 46.17096,
    "lng": -111.58999,
    "state_id": "MT"
  },
  {
    "zip": 59644,
    "lat": 46.42113,
    "lng": -111.43103,
    "state_id": "MT"
  },
  {
    "zip": 59645,
    "lat": 46.68044,
    "lng": -111.04716,
    "state_id": "MT"
  },
  {
    "zip": 59647,
    "lat": 46.48174,
    "lng": -111.65808,
    "state_id": "MT"
  },
  {
    "zip": 59648,
    "lat": 47.08191,
    "lng": -112.1179,
    "state_id": "MT"
  },
  {
    "zip": 59701,
    "lat": 46.03422,
    "lng": -112.46687,
    "state_id": "MT"
  },
  {
    "zip": 59703,
    "lat": 46.04559,
    "lng": -112.53752,
    "state_id": "MT"
  },
  {
    "zip": 59710,
    "lat": 45.06984,
    "lng": -112.06068,
    "state_id": "MT"
  },
  {
    "zip": 59711,
    "lat": 46.10629,
    "lng": -113.0659,
    "state_id": "MT"
  },
  {
    "zip": 59713,
    "lat": 46.67999,
    "lng": -112.59066,
    "state_id": "MT"
  },
  {
    "zip": 59714,
    "lat": 45.97984,
    "lng": -111.13976,
    "state_id": "MT"
  },
  {
    "zip": 59715,
    "lat": 45.82857,
    "lng": -110.90996,
    "state_id": "MT"
  },
  {
    "zip": 59716,
    "lat": 45.10267,
    "lng": -111.48685,
    "state_id": "MT"
  },
  {
    "zip": 59718,
    "lat": 45.57816,
    "lng": -111.12639,
    "state_id": "MT"
  },
  {
    "zip": 59720,
    "lat": 44.87821,
    "lng": -111.64397,
    "state_id": "MT"
  },
  {
    "zip": 59721,
    "lat": 45.88027,
    "lng": -111.83654,
    "state_id": "MT"
  },
  {
    "zip": 59722,
    "lat": 46.36252,
    "lng": -112.76562,
    "state_id": "MT"
  },
  {
    "zip": 59724,
    "lat": 44.70971,
    "lng": -112.7468,
    "state_id": "MT"
  },
  {
    "zip": 59725,
    "lat": 45.08977,
    "lng": -112.83786,
    "state_id": "MT"
  },
  {
    "zip": 59727,
    "lat": 45.80308,
    "lng": -112.80862,
    "state_id": "MT"
  },
  {
    "zip": 59728,
    "lat": 46.51578,
    "lng": -112.41988,
    "state_id": "MT"
  },
  {
    "zip": 59729,
    "lat": 45.29334,
    "lng": -111.65892,
    "state_id": "MT"
  },
  {
    "zip": 59730,
    "lat": 45.22766,
    "lng": -111.20626,
    "state_id": "MT"
  },
  {
    "zip": 59731,
    "lat": 46.57902,
    "lng": -112.77361,
    "state_id": "MT"
  },
  {
    "zip": 59732,
    "lat": 45.48812,
    "lng": -112.7331,
    "state_id": "MT"
  },
  {
    "zip": 59733,
    "lat": 46.58869,
    "lng": -112.88581,
    "state_id": "MT"
  },
  {
    "zip": 59735,
    "lat": 45.65028,
    "lng": -111.86389,
    "state_id": "MT"
  },
  {
    "zip": 59736,
    "lat": 45.33381,
    "lng": -113.39401,
    "state_id": "MT"
  },
  {
    "zip": 59739,
    "lat": 44.63692,
    "lng": -112.17097,
    "state_id": "MT"
  },
  {
    "zip": 59740,
    "lat": 45.47646,
    "lng": -111.82133,
    "state_id": "MT"
  },
  {
    "zip": 59741,
    "lat": 45.77232,
    "lng": -111.36076,
    "state_id": "MT"
  },
  {
    "zip": 59743,
    "lat": 45.66872,
    "lng": -112.67788,
    "state_id": "MT"
  },
  {
    "zip": 59745,
    "lat": 45.58955,
    "lng": -111.61387,
    "state_id": "MT"
  },
  {
    "zip": 59746,
    "lat": 45.36589,
    "lng": -113.05783,
    "state_id": "MT"
  },
  {
    "zip": 59747,
    "lat": 45.71301,
    "lng": -111.94574,
    "state_id": "MT"
  },
  {
    "zip": 59748,
    "lat": 46.04668,
    "lng": -112.69922,
    "state_id": "MT"
  },
  {
    "zip": 59749,
    "lat": 45.44578,
    "lng": -112.12315,
    "state_id": "MT"
  },
  {
    "zip": 59750,
    "lat": 45.95131,
    "lng": -112.7184,
    "state_id": "MT"
  },
  {
    "zip": 59751,
    "lat": 45.63289,
    "lng": -112.47829,
    "state_id": "MT"
  },
  {
    "zip": 59752,
    "lat": 45.94918,
    "lng": -111.50639,
    "state_id": "MT"
  },
  {
    "zip": 59754,
    "lat": 45.46327,
    "lng": -112.33843,
    "state_id": "MT"
  },
  {
    "zip": 59755,
    "lat": 45.32848,
    "lng": -111.93792,
    "state_id": "MT"
  },
  {
    "zip": 59756,
    "lat": 46.17993,
    "lng": -112.82788,
    "state_id": "MT"
  },
  {
    "zip": 59758,
    "lat": 44.7432,
    "lng": -111.18791,
    "state_id": "MT"
  },
  {
    "zip": 59759,
    "lat": 45.84574,
    "lng": -112.19624,
    "state_id": "MT"
  },
  {
    "zip": 59760,
    "lat": 45.76446,
    "lng": -111.6359,
    "state_id": "MT"
  },
  {
    "zip": 59761,
    "lat": 45.63591,
    "lng": -113.55342,
    "state_id": "MT"
  },
  {
    "zip": 59762,
    "lat": 45.78639,
    "lng": -113.15688,
    "state_id": "MT"
  },
  {
    "zip": 59801,
    "lat": 46.85627,
    "lng": -114.01491,
    "state_id": "MT"
  },
  {
    "zip": 59802,
    "lat": 46.90361,
    "lng": -113.91981,
    "state_id": "MT"
  },
  {
    "zip": 59803,
    "lat": 46.79395,
    "lng": -113.96285,
    "state_id": "MT"
  },
  {
    "zip": 59804,
    "lat": 46.90102,
    "lng": -114.25103,
    "state_id": "MT"
  },
  {
    "zip": 59808,
    "lat": 46.98298,
    "lng": -114.09606,
    "state_id": "MT"
  },
  {
    "zip": 59820,
    "lat": 46.89599,
    "lng": -114.63709,
    "state_id": "MT"
  },
  {
    "zip": 59821,
    "lat": 47.17258,
    "lng": -114.06047,
    "state_id": "MT"
  },
  {
    "zip": 59823,
    "lat": 46.93538,
    "lng": -113.49452,
    "state_id": "MT"
  },
  {
    "zip": 59824,
    "lat": 47.42325,
    "lng": -114.20355,
    "state_id": "MT"
  },
  {
    "zip": 59825,
    "lat": 46.62697,
    "lng": -113.68306,
    "state_id": "MT"
  },
  {
    "zip": 59826,
    "lat": 47.43796,
    "lng": -113.69484,
    "state_id": "MT"
  },
  {
    "zip": 59827,
    "lat": 45.89486,
    "lng": -114.04548,
    "state_id": "MT"
  },
  {
    "zip": 59828,
    "lat": 46.33331,
    "lng": -113.96528,
    "state_id": "MT"
  },
  {
    "zip": 59829,
    "lat": 45.78631,
    "lng": -114.28224,
    "state_id": "MT"
  },
  {
    "zip": 59830,
    "lat": 47.42448,
    "lng": -115.34366,
    "state_id": "MT"
  },
  {
    "zip": 59831,
    "lat": 47.27752,
    "lng": -114.35275,
    "state_id": "MT"
  },
  {
    "zip": 59832,
    "lat": 46.68119,
    "lng": -113.23879,
    "state_id": "MT"
  },
  {
    "zip": 59833,
    "lat": 46.65156,
    "lng": -114.06221,
    "state_id": "MT"
  },
  {
    "zip": 59834,
    "lat": 47.07465,
    "lng": -114.23107,
    "state_id": "MT"
  },
  {
    "zip": 59837,
    "lat": 46.54511,
    "lng": -113.27498,
    "state_id": "MT"
  },
  {
    "zip": 59840,
    "lat": 46.18544,
    "lng": -114.18925,
    "state_id": "MT"
  },
  {
    "zip": 59841,
    "lat": 46.33391,
    "lng": -114.22324,
    "state_id": "MT"
  },
  {
    "zip": 59842,
    "lat": 47.35383,
    "lng": -115.41785,
    "state_id": "MT"
  },
  {
    "zip": 59843,
    "lat": 46.8341,
    "lng": -112.96136,
    "state_id": "MT"
  },
  {
    "zip": 59844,
    "lat": 48.05714,
    "lng": -115.96858,
    "state_id": "MT"
  },
  {
    "zip": 59845,
    "lat": 47.6584,
    "lng": -114.54417,
    "state_id": "MT"
  },
  {
    "zip": 59846,
    "lat": 47.14829,
    "lng": -114.51102,
    "state_id": "MT"
  },
  {
    "zip": 59847,
    "lat": 46.70575,
    "lng": -114.43727,
    "state_id": "MT"
  },
  {
    "zip": 59848,
    "lat": 47.70146,
    "lng": -114.6913,
    "state_id": "MT"
  },
  {
    "zip": 59851,
    "lat": 46.87084,
    "lng": -113.87795,
    "state_id": "MT"
  },
  {
    "zip": 59853,
    "lat": 48.05713,
    "lng": -115.76969,
    "state_id": "MT"
  },
  {
    "zip": 59854,
    "lat": 47.03541,
    "lng": -113.08407,
    "state_id": "MT"
  },
  {
    "zip": 59855,
    "lat": 47.60547,
    "lng": -114.11852,
    "state_id": "MT"
  },
  {
    "zip": 59856,
    "lat": 47.33704,
    "lng": -114.79019,
    "state_id": "MT"
  },
  {
    "zip": 59858,
    "lat": 46.28846,
    "lng": -113.44897,
    "state_id": "MT"
  },
  {
    "zip": 59859,
    "lat": 47.53049,
    "lng": -114.82135,
    "state_id": "MT"
  },
  {
    "zip": 59860,
    "lat": 47.71027,
    "lng": -114.16687,
    "state_id": "MT"
  },
  {
    "zip": 59863,
    "lat": 47.30957,
    "lng": -114.17891,
    "state_id": "MT"
  },
  {
    "zip": 59864,
    "lat": 47.52899,
    "lng": -114.11019,
    "state_id": "MT"
  },
  {
    "zip": 59865,
    "lat": 47.32883,
    "lng": -114.01011,
    "state_id": "MT"
  },
  {
    "zip": 59866,
    "lat": 47.32669,
    "lng": -115.15643,
    "state_id": "MT"
  },
  {
    "zip": 59867,
    "lat": 47.42241,
    "lng": -115.59571,
    "state_id": "MT"
  },
  {
    "zip": 59868,
    "lat": 47.21409,
    "lng": -113.50499,
    "state_id": "MT"
  },
  {
    "zip": 59870,
    "lat": 46.51569,
    "lng": -114.06162,
    "state_id": "MT"
  },
  {
    "zip": 59871,
    "lat": 45.86574,
    "lng": -113.83209,
    "state_id": "MT"
  },
  {
    "zip": 59872,
    "lat": 47.11284,
    "lng": -114.90011,
    "state_id": "MT"
  },
  {
    "zip": 59873,
    "lat": 47.70258,
    "lng": -115.282,
    "state_id": "MT"
  },
  {
    "zip": 59874,
    "lat": 47.80341,
    "lng": -115.62681,
    "state_id": "MT"
  },
  {
    "zip": 59875,
    "lat": 46.40794,
    "lng": -114.26067,
    "state_id": "MT"
  },
  {
    "zip": 59901,
    "lat": 48.22816,
    "lng": -114.38225,
    "state_id": "MT"
  },
  {
    "zip": 59910,
    "lat": 47.79522,
    "lng": -114.30204,
    "state_id": "MT"
  },
  {
    "zip": 59911,
    "lat": 47.8815,
    "lng": -113.86899,
    "state_id": "MT"
  },
  {
    "zip": 59912,
    "lat": 48.40467,
    "lng": -114.16435,
    "state_id": "MT"
  },
  {
    "zip": 59913,
    "lat": 48.41716,
    "lng": -113.94615,
    "state_id": "MT"
  },
  {
    "zip": 59914,
    "lat": 47.85966,
    "lng": -114.28323,
    "state_id": "MT"
  },
  {
    "zip": 59915,
    "lat": 47.84314,
    "lng": -114.40228,
    "state_id": "MT"
  },
  {
    "zip": 59916,
    "lat": 48.28106,
    "lng": -113.52864,
    "state_id": "MT"
  },
  {
    "zip": 59917,
    "lat": 48.90264,
    "lng": -114.92853,
    "state_id": "MT"
  },
  {
    "zip": 59918,
    "lat": 48.7718,
    "lng": -114.85673,
    "state_id": "MT"
  },
  {
    "zip": 59919,
    "lat": 47.98892,
    "lng": -113.32507,
    "state_id": "MT"
  },
  {
    "zip": 59920,
    "lat": 48.06676,
    "lng": -114.53269,
    "state_id": "MT"
  },
  {
    "zip": 59922,
    "lat": 47.9969,
    "lng": -114.18714,
    "state_id": "MT"
  },
  {
    "zip": 59923,
    "lat": 48.26411,
    "lng": -115.32285,
    "state_id": "MT"
  },
  {
    "zip": 59925,
    "lat": 48.04831,
    "lng": -114.83043,
    "state_id": "MT"
  },
  {
    "zip": 59926,
    "lat": 48.36303,
    "lng": -114.00651,
    "state_id": "MT"
  },
  {
    "zip": 59927,
    "lat": 48.5721,
    "lng": -114.68407,
    "state_id": "MT"
  },
  {
    "zip": 59928,
    "lat": 48.78561,
    "lng": -114.42467,
    "state_id": "MT"
  },
  {
    "zip": 59929,
    "lat": 47.92383,
    "lng": -114.36427,
    "state_id": "MT"
  },
  {
    "zip": 59930,
    "lat": 48.84902,
    "lng": -115.14309,
    "state_id": "MT"
  },
  {
    "zip": 59931,
    "lat": 47.91195,
    "lng": -114.17268,
    "state_id": "MT"
  },
  {
    "zip": 59932,
    "lat": 48.04657,
    "lng": -114.25412,
    "state_id": "MT"
  },
  {
    "zip": 59933,
    "lat": 48.68096,
    "lng": -114.77149,
    "state_id": "MT"
  },
  {
    "zip": 59934,
    "lat": 48.59837,
    "lng": -114.8965,
    "state_id": "MT"
  },
  {
    "zip": 59935,
    "lat": 48.64381,
    "lng": -115.86032,
    "state_id": "MT"
  },
  {
    "zip": 59936,
    "lat": 48.60876,
    "lng": -113.85536,
    "state_id": "MT"
  },
  {
    "zip": 59937,
    "lat": 48.41177,
    "lng": -114.53715,
    "state_id": "MT"
  },
  {
    "zip": 27006,
    "lat": 35.94454,
    "lng": -80.43766,
    "state_id": "NC"
  },
  {
    "zip": 27007,
    "lat": 36.38732,
    "lng": -80.59249,
    "state_id": "NC"
  },
  {
    "zip": 27009,
    "lat": 36.23306,
    "lng": -80.07612,
    "state_id": "NC"
  },
  {
    "zip": 27011,
    "lat": 36.2156,
    "lng": -80.69667,
    "state_id": "NC"
  },
  {
    "zip": 27012,
    "lat": 36.0041,
    "lng": -80.37582,
    "state_id": "NC"
  },
  {
    "zip": 27013,
    "lat": 35.75594,
    "lng": -80.70351,
    "state_id": "NC"
  },
  {
    "zip": 27014,
    "lat": 35.81193,
    "lng": -80.55519,
    "state_id": "NC"
  },
  {
    "zip": 27016,
    "lat": 36.45168,
    "lng": -80.21452,
    "state_id": "NC"
  },
  {
    "zip": 27017,
    "lat": 36.38153,
    "lng": -80.76031,
    "state_id": "NC"
  },
  {
    "zip": 27018,
    "lat": 36.19792,
    "lng": -80.52076,
    "state_id": "NC"
  },
  {
    "zip": 27019,
    "lat": 36.28392,
    "lng": -80.23478,
    "state_id": "NC"
  },
  {
    "zip": 27020,
    "lat": 36.10084,
    "lng": -80.80798,
    "state_id": "NC"
  },
  {
    "zip": 27021,
    "lat": 36.31838,
    "lng": -80.33868,
    "state_id": "NC"
  },
  {
    "zip": 27022,
    "lat": 36.51447,
    "lng": -80.21465,
    "state_id": "NC"
  },
  {
    "zip": 27023,
    "lat": 36.09096,
    "lng": -80.45048,
    "state_id": "NC"
  },
  {
    "zip": 27024,
    "lat": 36.52379,
    "lng": -80.84169,
    "state_id": "NC"
  },
  {
    "zip": 27025,
    "lat": 36.37788,
    "lng": -79.97286,
    "state_id": "NC"
  },
  {
    "zip": 27027,
    "lat": 36.44539,
    "lng": -79.98931,
    "state_id": "NC"
  },
  {
    "zip": 27028,
    "lat": 35.92347,
    "lng": -80.57515,
    "state_id": "NC"
  },
  {
    "zip": 27030,
    "lat": 36.48892,
    "lng": -80.62906,
    "state_id": "NC"
  },
  {
    "zip": 27040,
    "lat": 36.16893,
    "lng": -80.38971,
    "state_id": "NC"
  },
  {
    "zip": 27041,
    "lat": 36.41987,
    "lng": -80.48238,
    "state_id": "NC"
  },
  {
    "zip": 27042,
    "lat": 36.34116,
    "lng": -80.05603,
    "state_id": "NC"
  },
  {
    "zip": 27043,
    "lat": 36.33748,
    "lng": -80.45027,
    "state_id": "NC"
  },
  {
    "zip": 27045,
    "lat": 36.23488,
    "lng": -80.29577,
    "state_id": "NC"
  },
  {
    "zip": 27046,
    "lat": 36.49321,
    "lng": -80.09055,
    "state_id": "NC"
  },
  {
    "zip": 27047,
    "lat": 36.31155,
    "lng": -80.57765,
    "state_id": "NC"
  },
  {
    "zip": 27048,
    "lat": 36.47573,
    "lng": -79.91057,
    "state_id": "NC"
  },
  {
    "zip": 27050,
    "lat": 36.23598,
    "lng": -80.40128,
    "state_id": "NC"
  },
  {
    "zip": 27051,
    "lat": 36.18762,
    "lng": -80.16052,
    "state_id": "NC"
  },
  {
    "zip": 27052,
    "lat": 36.32615,
    "lng": -80.15436,
    "state_id": "NC"
  },
  {
    "zip": 27053,
    "lat": 36.47279,
    "lng": -80.34823,
    "state_id": "NC"
  },
  {
    "zip": 27054,
    "lat": 35.79245,
    "lng": -80.5984,
    "state_id": "NC"
  },
  {
    "zip": 27055,
    "lat": 36.11345,
    "lng": -80.6337,
    "state_id": "NC"
  },
  {
    "zip": 27101,
    "lat": 36.11173,
    "lng": -80.19857,
    "state_id": "NC"
  },
  {
    "zip": 27103,
    "lat": 36.05798,
    "lng": -80.32147,
    "state_id": "NC"
  },
  {
    "zip": 27104,
    "lat": 36.09482,
    "lng": -80.3239,
    "state_id": "NC"
  },
  {
    "zip": 27105,
    "lat": 36.16283,
    "lng": -80.23476,
    "state_id": "NC"
  },
  {
    "zip": 27106,
    "lat": 36.14292,
    "lng": -80.32263,
    "state_id": "NC"
  },
  {
    "zip": 27107,
    "lat": 36.01471,
    "lng": -80.1783,
    "state_id": "NC"
  },
  {
    "zip": 27109,
    "lat": 36.13391,
    "lng": -80.27798,
    "state_id": "NC"
  },
  {
    "zip": 27110,
    "lat": 36.08926,
    "lng": -80.22497,
    "state_id": "NC"
  },
  {
    "zip": 27127,
    "lat": 36.01992,
    "lng": -80.27894,
    "state_id": "NC"
  },
  {
    "zip": 27201,
    "lat": 36.03424,
    "lng": -79.48485,
    "state_id": "NC"
  },
  {
    "zip": 27203,
    "lat": 35.73221,
    "lng": -79.7893,
    "state_id": "NC"
  },
  {
    "zip": 27205,
    "lat": 35.63956,
    "lng": -79.85091,
    "state_id": "NC"
  },
  {
    "zip": 27207,
    "lat": 35.60548,
    "lng": -79.39299,
    "state_id": "NC"
  },
  {
    "zip": 27208,
    "lat": 35.56282,
    "lng": -79.54294,
    "state_id": "NC"
  },
  {
    "zip": 27209,
    "lat": 35.34314,
    "lng": -79.7508,
    "state_id": "NC"
  },
  {
    "zip": 27212,
    "lat": 36.46058,
    "lng": -79.28064,
    "state_id": "NC"
  },
  {
    "zip": 27214,
    "lat": 36.21252,
    "lng": -79.67859,
    "state_id": "NC"
  },
  {
    "zip": 27215,
    "lat": 36.03178,
    "lng": -79.48892,
    "state_id": "NC"
  },
  {
    "zip": 27217,
    "lat": 36.19274,
    "lng": -79.38335,
    "state_id": "NC"
  },
  {
    "zip": 27229,
    "lat": 35.25024,
    "lng": -79.79754,
    "state_id": "NC"
  },
  {
    "zip": 27231,
    "lat": 36.20296,
    "lng": -79.16981,
    "state_id": "NC"
  },
  {
    "zip": 27233,
    "lat": 35.88702,
    "lng": -79.69957,
    "state_id": "NC"
  },
  {
    "zip": 27235,
    "lat": 36.0953,
    "lng": -80.00887,
    "state_id": "NC"
  },
  {
    "zip": 27239,
    "lat": 35.60758,
    "lng": -80.08959,
    "state_id": "NC"
  },
  {
    "zip": 27242,
    "lat": 35.33052,
    "lng": -79.63977,
    "state_id": "NC"
  },
  {
    "zip": 27243,
    "lat": 36.078,
    "lng": -79.19495,
    "state_id": "NC"
  },
  {
    "zip": 27244,
    "lat": 36.21011,
    "lng": -79.48778,
    "state_id": "NC"
  },
  {
    "zip": 27248,
    "lat": 35.78777,
    "lng": -79.70968,
    "state_id": "NC"
  },
  {
    "zip": 27249,
    "lat": 36.1574,
    "lng": -79.5819,
    "state_id": "NC"
  },
  {
    "zip": 27252,
    "lat": 35.56417,
    "lng": -79.34949,
    "state_id": "NC"
  },
  {
    "zip": 27253,
    "lat": 35.96166,
    "lng": -79.34546,
    "state_id": "NC"
  },
  {
    "zip": 27258,
    "lat": 36.03624,
    "lng": -79.32597,
    "state_id": "NC"
  },
  {
    "zip": 27260,
    "lat": 35.95396,
    "lng": -79.98833,
    "state_id": "NC"
  },
  {
    "zip": 27262,
    "lat": 35.96231,
    "lng": -80.03839,
    "state_id": "NC"
  },
  {
    "zip": 27263,
    "lat": 35.90928,
    "lng": -79.93796,
    "state_id": "NC"
  },
  {
    "zip": 27265,
    "lat": 36.00956,
    "lng": -80.03052,
    "state_id": "NC"
  },
  {
    "zip": 27278,
    "lat": 36.08601,
    "lng": -79.08617,
    "state_id": "NC"
  },
  {
    "zip": 27281,
    "lat": 35.18609,
    "lng": -79.62675,
    "state_id": "NC"
  },
  {
    "zip": 27282,
    "lat": 35.9957,
    "lng": -79.92824,
    "state_id": "NC"
  },
  {
    "zip": 27283,
    "lat": 35.95195,
    "lng": -79.63724,
    "state_id": "NC"
  },
  {
    "zip": 27284,
    "lat": 36.11847,
    "lng": -80.07738,
    "state_id": "NC"
  },
  {
    "zip": 27288,
    "lat": 36.4991,
    "lng": -79.74662,
    "state_id": "NC"
  },
  {
    "zip": 27291,
    "lat": 36.40204,
    "lng": -79.16604,
    "state_id": "NC"
  },
  {
    "zip": 27292,
    "lat": 35.73451,
    "lng": -80.20924,
    "state_id": "NC"
  },
  {
    "zip": 27295,
    "lat": 35.86839,
    "lng": -80.31509,
    "state_id": "NC"
  },
  {
    "zip": 27298,
    "lat": 35.88583,
    "lng": -79.56712,
    "state_id": "NC"
  },
  {
    "zip": 27299,
    "lat": 35.75219,
    "lng": -80.38593,
    "state_id": "NC"
  },
  {
    "zip": 27301,
    "lat": 36.11377,
    "lng": -79.66684,
    "state_id": "NC"
  },
  {
    "zip": 27302,
    "lat": 36.14042,
    "lng": -79.26434,
    "state_id": "NC"
  },
  {
    "zip": 27305,
    "lat": 36.5195,
    "lng": -79.22876,
    "state_id": "NC"
  },
  {
    "zip": 27306,
    "lat": 35.21538,
    "lng": -79.97525,
    "state_id": "NC"
  },
  {
    "zip": 27310,
    "lat": 36.17268,
    "lng": -79.99314,
    "state_id": "NC"
  },
  {
    "zip": 27311,
    "lat": 36.48314,
    "lng": -79.47459,
    "state_id": "NC"
  },
  {
    "zip": 27312,
    "lat": 35.75676,
    "lng": -79.19671,
    "state_id": "NC"
  },
  {
    "zip": 27313,
    "lat": 35.929,
    "lng": -79.75182,
    "state_id": "NC"
  },
  {
    "zip": 27314,
    "lat": 36.29169,
    "lng": -79.18699,
    "state_id": "NC"
  },
  {
    "zip": 27315,
    "lat": 36.5052,
    "lng": -79.39276,
    "state_id": "NC"
  },
  {
    "zip": 27316,
    "lat": 35.68986,
    "lng": -79.62225,
    "state_id": "NC"
  },
  {
    "zip": 27317,
    "lat": 35.83746,
    "lng": -79.80283,
    "state_id": "NC"
  },
  {
    "zip": 27320,
    "lat": 36.34247,
    "lng": -79.65758,
    "state_id": "NC"
  },
  {
    "zip": 27325,
    "lat": 35.46321,
    "lng": -79.57477,
    "state_id": "NC"
  },
  {
    "zip": 27326,
    "lat": 36.45343,
    "lng": -79.55636,
    "state_id": "NC"
  },
  {
    "zip": 27330,
    "lat": 35.51635,
    "lng": -79.18718,
    "state_id": "NC"
  },
  {
    "zip": 27332,
    "lat": 35.37931,
    "lng": -79.13603,
    "state_id": "NC"
  },
  {
    "zip": 27340,
    "lat": 35.94596,
    "lng": -79.31719,
    "state_id": "NC"
  },
  {
    "zip": 27341,
    "lat": 35.52466,
    "lng": -79.70201,
    "state_id": "NC"
  },
  {
    "zip": 27343,
    "lat": 36.50309,
    "lng": -79.09172,
    "state_id": "NC"
  },
  {
    "zip": 27344,
    "lat": 35.72929,
    "lng": -79.43188,
    "state_id": "NC"
  },
  {
    "zip": 27349,
    "lat": 35.89843,
    "lng": -79.41629,
    "state_id": "NC"
  },
  {
    "zip": 27350,
    "lat": 35.80575,
    "lng": -79.88925,
    "state_id": "NC"
  },
  {
    "zip": 27355,
    "lat": 35.78963,
    "lng": -79.56858,
    "state_id": "NC"
  },
  {
    "zip": 27356,
    "lat": 35.43919,
    "lng": -79.79799,
    "state_id": "NC"
  },
  {
    "zip": 27357,
    "lat": 36.26818,
    "lng": -79.97697,
    "state_id": "NC"
  },
  {
    "zip": 27358,
    "lat": 36.22808,
    "lng": -79.87975,
    "state_id": "NC"
  },
  {
    "zip": 27360,
    "lat": 35.85771,
    "lng": -80.09978,
    "state_id": "NC"
  },
  {
    "zip": 27370,
    "lat": 35.81079,
    "lng": -79.99171,
    "state_id": "NC"
  },
  {
    "zip": 27371,
    "lat": 35.42018,
    "lng": -79.95884,
    "state_id": "NC"
  },
  {
    "zip": 27376,
    "lat": 35.25454,
    "lng": -79.53539,
    "state_id": "NC"
  },
  {
    "zip": 27377,
    "lat": 36.03823,
    "lng": -79.60259,
    "state_id": "NC"
  },
  {
    "zip": 27379,
    "lat": 36.3771,
    "lng": -79.34624,
    "state_id": "NC"
  },
  {
    "zip": 27401,
    "lat": 36.07011,
    "lng": -79.76582,
    "state_id": "NC"
  },
  {
    "zip": 27403,
    "lat": 36.0643,
    "lng": -79.82474,
    "state_id": "NC"
  },
  {
    "zip": 27405,
    "lat": 36.11603,
    "lng": -79.7354,
    "state_id": "NC"
  },
  {
    "zip": 27406,
    "lat": 35.99912,
    "lng": -79.76565,
    "state_id": "NC"
  },
  {
    "zip": 27407,
    "lat": 36.0098,
    "lng": -79.87648,
    "state_id": "NC"
  },
  {
    "zip": 27408,
    "lat": 36.1031,
    "lng": -79.81418,
    "state_id": "NC"
  },
  {
    "zip": 27409,
    "lat": 36.09381,
    "lng": -79.94467,
    "state_id": "NC"
  },
  {
    "zip": 27410,
    "lat": 36.11785,
    "lng": -79.89434,
    "state_id": "NC"
  },
  {
    "zip": 27455,
    "lat": 36.18377,
    "lng": -79.80763,
    "state_id": "NC"
  },
  {
    "zip": 27501,
    "lat": 35.48576,
    "lng": -78.68494,
    "state_id": "NC"
  },
  {
    "zip": 27502,
    "lat": 35.7201,
    "lng": -78.91507,
    "state_id": "NC"
  },
  {
    "zip": 27503,
    "lat": 36.15926,
    "lng": -78.8778,
    "state_id": "NC"
  },
  {
    "zip": 27504,
    "lat": 35.39897,
    "lng": -78.51901,
    "state_id": "NC"
  },
  {
    "zip": 27505,
    "lat": 35.4207,
    "lng": -79.002,
    "state_id": "NC"
  },
  {
    "zip": 27507,
    "lat": 36.51271,
    "lng": -78.56306,
    "state_id": "NC"
  },
  {
    "zip": 27508,
    "lat": 35.95771,
    "lng": -78.24979,
    "state_id": "NC"
  },
  {
    "zip": 27509,
    "lat": 36.12849,
    "lng": -78.7758,
    "state_id": "NC"
  },
  {
    "zip": 27510,
    "lat": 35.91585,
    "lng": -79.08193,
    "state_id": "NC"
  },
  {
    "zip": 27511,
    "lat": 35.76325,
    "lng": -78.78426,
    "state_id": "NC"
  },
  {
    "zip": 27513,
    "lat": 35.80222,
    "lng": -78.80271,
    "state_id": "NC"
  },
  {
    "zip": 27514,
    "lat": 35.96717,
    "lng": -79.04442,
    "state_id": "NC"
  },
  {
    "zip": 27516,
    "lat": 35.91251,
    "lng": -79.15137,
    "state_id": "NC"
  },
  {
    "zip": 27517,
    "lat": 35.85024,
    "lng": -79.02484,
    "state_id": "NC"
  },
  {
    "zip": 27518,
    "lat": 35.72981,
    "lng": -78.77353,
    "state_id": "NC"
  },
  {
    "zip": 27519,
    "lat": 35.80708,
    "lng": -78.88695,
    "state_id": "NC"
  },
  {
    "zip": 27520,
    "lat": 35.61427,
    "lng": -78.47187,
    "state_id": "NC"
  },
  {
    "zip": 27521,
    "lat": 35.41365,
    "lng": -78.65924,
    "state_id": "NC"
  },
  {
    "zip": 27522,
    "lat": 36.10623,
    "lng": -78.67393,
    "state_id": "NC"
  },
  {
    "zip": 27523,
    "lat": 35.77227,
    "lng": -78.96053,
    "state_id": "NC"
  },
  {
    "zip": 27524,
    "lat": 35.39541,
    "lng": -78.38191,
    "state_id": "NC"
  },
  {
    "zip": 27525,
    "lat": 36.11841,
    "lng": -78.46803,
    "state_id": "NC"
  },
  {
    "zip": 27526,
    "lat": 35.54137,
    "lng": -78.82801,
    "state_id": "NC"
  },
  {
    "zip": 27527,
    "lat": 35.65778,
    "lng": -78.38384,
    "state_id": "NC"
  },
  {
    "zip": 27529,
    "lat": 35.65233,
    "lng": -78.58172,
    "state_id": "NC"
  },
  {
    "zip": 27530,
    "lat": 35.36899,
    "lng": -78.07487,
    "state_id": "NC"
  },
  {
    "zip": 27531,
    "lat": 35.34299,
    "lng": -77.96444,
    "state_id": "NC"
  },
  {
    "zip": 27534,
    "lat": 35.37132,
    "lng": -77.90759,
    "state_id": "NC"
  },
  {
    "zip": 27536,
    "lat": 36.32669,
    "lng": -78.40883,
    "state_id": "NC"
  },
  {
    "zip": 27537,
    "lat": 36.3601,
    "lng": -78.39039,
    "state_id": "NC"
  },
  {
    "zip": 27539,
    "lat": 35.67672,
    "lng": -78.81397,
    "state_id": "NC"
  },
  {
    "zip": 27540,
    "lat": 35.61215,
    "lng": -78.86968,
    "state_id": "NC"
  },
  {
    "zip": 27541,
    "lat": 36.26325,
    "lng": -79.08637,
    "state_id": "NC"
  },
  {
    "zip": 27542,
    "lat": 35.61826,
    "lng": -78.14073,
    "state_id": "NC"
  },
  {
    "zip": 27544,
    "lat": 36.20052,
    "lng": -78.44077,
    "state_id": "NC"
  },
  {
    "zip": 27545,
    "lat": 35.78352,
    "lng": -78.4778,
    "state_id": "NC"
  },
  {
    "zip": 27546,
    "lat": 35.38386,
    "lng": -78.86807,
    "state_id": "NC"
  },
  {
    "zip": 27549,
    "lat": 36.10367,
    "lng": -78.23827,
    "state_id": "NC"
  },
  {
    "zip": 27551,
    "lat": 36.43195,
    "lng": -78.05164,
    "state_id": "NC"
  },
  {
    "zip": 27553,
    "lat": 36.48362,
    "lng": -78.307,
    "state_id": "NC"
  },
  {
    "zip": 27555,
    "lat": 35.56246,
    "lng": -78.20423,
    "state_id": "NC"
  },
  {
    "zip": 27556,
    "lat": 36.40839,
    "lng": -78.31478,
    "state_id": "NC"
  },
  {
    "zip": 27557,
    "lat": 35.77609,
    "lng": -78.19922,
    "state_id": "NC"
  },
  {
    "zip": 27559,
    "lat": 35.63173,
    "lng": -79.10088,
    "state_id": "NC"
  },
  {
    "zip": 27560,
    "lat": 35.85922,
    "lng": -78.8271,
    "state_id": "NC"
  },
  {
    "zip": 27562,
    "lat": 35.64129,
    "lng": -78.98812,
    "state_id": "NC"
  },
  {
    "zip": 27563,
    "lat": 36.42886,
    "lng": -78.23717,
    "state_id": "NC"
  },
  {
    "zip": 27565,
    "lat": 36.36263,
    "lng": -78.65005,
    "state_id": "NC"
  },
  {
    "zip": 27568,
    "lat": 35.5089,
    "lng": -78.24388,
    "state_id": "NC"
  },
  {
    "zip": 27569,
    "lat": 35.42912,
    "lng": -78.17608,
    "state_id": "NC"
  },
  {
    "zip": 27571,
    "lat": 35.92048,
    "lng": -78.45722,
    "state_id": "NC"
  },
  {
    "zip": 27572,
    "lat": 36.2491,
    "lng": -78.88452,
    "state_id": "NC"
  },
  {
    "zip": 27573,
    "lat": 36.39672,
    "lng": -78.97607,
    "state_id": "NC"
  },
  {
    "zip": 27574,
    "lat": 36.43041,
    "lng": -78.94505,
    "state_id": "NC"
  },
  {
    "zip": 27576,
    "lat": 35.58282,
    "lng": -78.2576,
    "state_id": "NC"
  },
  {
    "zip": 27577,
    "lat": 35.49187,
    "lng": -78.34385,
    "state_id": "NC"
  },
  {
    "zip": 27581,
    "lat": 36.2013,
    "lng": -78.73072,
    "state_id": "NC"
  },
  {
    "zip": 27582,
    "lat": 36.46187,
    "lng": -78.5743,
    "state_id": "NC"
  },
  {
    "zip": 27583,
    "lat": 36.29509,
    "lng": -78.93614,
    "state_id": "NC"
  },
  {
    "zip": 27587,
    "lat": 35.98129,
    "lng": -78.55288,
    "state_id": "NC"
  },
  {
    "zip": 27589,
    "lat": 36.33759,
    "lng": -78.1291,
    "state_id": "NC"
  },
  {
    "zip": 27591,
    "lat": 35.78485,
    "lng": -78.38605,
    "state_id": "NC"
  },
  {
    "zip": 27592,
    "lat": 35.55773,
    "lng": -78.67346,
    "state_id": "NC"
  },
  {
    "zip": 27596,
    "lat": 36.01015,
    "lng": -78.44623,
    "state_id": "NC"
  },
  {
    "zip": 27597,
    "lat": 35.84098,
    "lng": -78.30021,
    "state_id": "NC"
  },
  {
    "zip": 27601,
    "lat": 35.77367,
    "lng": -78.63442,
    "state_id": "NC"
  },
  {
    "zip": 27603,
    "lat": 35.66355,
    "lng": -78.66172,
    "state_id": "NC"
  },
  {
    "zip": 27604,
    "lat": 35.81863,
    "lng": -78.55881,
    "state_id": "NC"
  },
  {
    "zip": 27605,
    "lat": 35.79067,
    "lng": -78.65459,
    "state_id": "NC"
  },
  {
    "zip": 27606,
    "lat": 35.74046,
    "lng": -78.71762,
    "state_id": "NC"
  },
  {
    "zip": 27607,
    "lat": 35.81327,
    "lng": -78.72239,
    "state_id": "NC"
  },
  {
    "zip": 27608,
    "lat": 35.80903,
    "lng": -78.64731,
    "state_id": "NC"
  },
  {
    "zip": 27609,
    "lat": 35.84328,
    "lng": -78.63332,
    "state_id": "NC"
  },
  {
    "zip": 27610,
    "lat": 35.74598,
    "lng": -78.54647,
    "state_id": "NC"
  },
  {
    "zip": 27612,
    "lat": 35.85334,
    "lng": -78.71023,
    "state_id": "NC"
  },
  {
    "zip": 27613,
    "lat": 35.92531,
    "lng": -78.71143,
    "state_id": "NC"
  },
  {
    "zip": 27614,
    "lat": 35.94725,
    "lng": -78.61915,
    "state_id": "NC"
  },
  {
    "zip": 27615,
    "lat": 35.89798,
    "lng": -78.63612,
    "state_id": "NC"
  },
  {
    "zip": 27616,
    "lat": 35.86728,
    "lng": -78.53812,
    "state_id": "NC"
  },
  {
    "zip": 27617,
    "lat": 35.90726,
    "lng": -78.7717,
    "state_id": "NC"
  },
  {
    "zip": 27701,
    "lat": 35.99886,
    "lng": -78.89864,
    "state_id": "NC"
  },
  {
    "zip": 27703,
    "lat": 35.96078,
    "lng": -78.80681,
    "state_id": "NC"
  },
  {
    "zip": 27704,
    "lat": 36.04174,
    "lng": -78.83035,
    "state_id": "NC"
  },
  {
    "zip": 27705,
    "lat": 36.02698,
    "lng": -78.98065,
    "state_id": "NC"
  },
  {
    "zip": 27707,
    "lat": 35.95474,
    "lng": -78.95486,
    "state_id": "NC"
  },
  {
    "zip": 27709,
    "lat": 35.92576,
    "lng": -78.83553,
    "state_id": "NC"
  },
  {
    "zip": 27712,
    "lat": 36.09689,
    "lng": -78.90212,
    "state_id": "NC"
  },
  {
    "zip": 27713,
    "lat": 35.89497,
    "lng": -78.92368,
    "state_id": "NC"
  },
  {
    "zip": 27801,
    "lat": 35.9155,
    "lng": -77.7361,
    "state_id": "NC"
  },
  {
    "zip": 27803,
    "lat": 35.89988,
    "lng": -77.85593,
    "state_id": "NC"
  },
  {
    "zip": 27804,
    "lat": 35.99039,
    "lng": -77.84674,
    "state_id": "NC"
  },
  {
    "zip": 27805,
    "lat": 36.20783,
    "lng": -77.08434,
    "state_id": "NC"
  },
  {
    "zip": 27806,
    "lat": 35.29727,
    "lng": -76.76526,
    "state_id": "NC"
  },
  {
    "zip": 27807,
    "lat": 35.80665,
    "lng": -78.09352,
    "state_id": "NC"
  },
  {
    "zip": 27808,
    "lat": 35.47122,
    "lng": -76.76314,
    "state_id": "NC"
  },
  {
    "zip": 27809,
    "lat": 36.02027,
    "lng": -77.74282,
    "state_id": "NC"
  },
  {
    "zip": 27810,
    "lat": 35.55184,
    "lng": -76.53706,
    "state_id": "NC"
  },
  {
    "zip": 27812,
    "lat": 35.81566,
    "lng": -77.36924,
    "state_id": "NC"
  },
  {
    "zip": 27813,
    "lat": 35.63089,
    "lng": -77.93603,
    "state_id": "NC"
  },
  {
    "zip": 27814,
    "lat": 35.38299,
    "lng": -76.92429,
    "state_id": "NC"
  },
  {
    "zip": 27816,
    "lat": 36.10682,
    "lng": -78.07056,
    "state_id": "NC"
  },
  {
    "zip": 27817,
    "lat": 35.44393,
    "lng": -77.0764,
    "state_id": "NC"
  },
  {
    "zip": 27818,
    "lat": 36.50275,
    "lng": -77.01395,
    "state_id": "NC"
  },
  {
    "zip": 27819,
    "lat": 35.81601,
    "lng": -77.45488,
    "state_id": "NC"
  },
  {
    "zip": 27820,
    "lat": 36.41563,
    "lng": -77.24179,
    "state_id": "NC"
  },
  {
    "zip": 27821,
    "lat": 35.32373,
    "lng": -76.87461,
    "state_id": "NC"
  },
  {
    "zip": 27822,
    "lat": 35.80519,
    "lng": -77.84034,
    "state_id": "NC"
  },
  {
    "zip": 27823,
    "lat": 36.20577,
    "lng": -77.72004,
    "state_id": "NC"
  },
  {
    "zip": 27824,
    "lat": 35.52145,
    "lng": -76.03258,
    "state_id": "NC"
  },
  {
    "zip": 27825,
    "lat": 35.83281,
    "lng": -77.17446,
    "state_id": "NC"
  },
  {
    "zip": 27826,
    "lat": 35.58173,
    "lng": -76.21189,
    "state_id": "NC"
  },
  {
    "zip": 27827,
    "lat": 35.69661,
    "lng": -77.51323,
    "state_id": "NC"
  },
  {
    "zip": 27828,
    "lat": 35.5861,
    "lng": -77.58796,
    "state_id": "NC"
  },
  {
    "zip": 27829,
    "lat": 35.69021,
    "lng": -77.62076,
    "state_id": "NC"
  },
  {
    "zip": 27830,
    "lat": 35.55528,
    "lng": -77.95795,
    "state_id": "NC"
  },
  {
    "zip": 27831,
    "lat": 36.45917,
    "lng": -77.57071,
    "state_id": "NC"
  },
  {
    "zip": 27832,
    "lat": 36.51201,
    "lng": -77.72584,
    "state_id": "NC"
  },
  {
    "zip": 27834,
    "lat": 35.65856,
    "lng": -77.38395,
    "state_id": "NC"
  },
  {
    "zip": 27837,
    "lat": 35.5169,
    "lng": -77.20951,
    "state_id": "NC"
  },
  {
    "zip": 27839,
    "lat": 36.28837,
    "lng": -77.5522,
    "state_id": "NC"
  },
  {
    "zip": 27840,
    "lat": 35.95925,
    "lng": -77.22221,
    "state_id": "NC"
  },
  {
    "zip": 27841,
    "lat": 35.90856,
    "lng": -77.27624,
    "state_id": "NC"
  },
  {
    "zip": 27842,
    "lat": 36.52483,
    "lng": -77.85857,
    "state_id": "NC"
  },
  {
    "zip": 27843,
    "lat": 36.0005,
    "lng": -77.39833,
    "state_id": "NC"
  },
  {
    "zip": 27844,
    "lat": 36.25231,
    "lng": -77.9487,
    "state_id": "NC"
  },
  {
    "zip": 27845,
    "lat": 36.37388,
    "lng": -77.43208,
    "state_id": "NC"
  },
  {
    "zip": 27846,
    "lat": 35.76368,
    "lng": -76.88982,
    "state_id": "NC"
  },
  {
    "zip": 27847,
    "lat": 36.1846,
    "lng": -77.18907,
    "state_id": "NC"
  },
  {
    "zip": 27849,
    "lat": 36.07214,
    "lng": -77.20663,
    "state_id": "NC"
  },
  {
    "zip": 27850,
    "lat": 36.40104,
    "lng": -77.88397,
    "state_id": "NC"
  },
  {
    "zip": 27851,
    "lat": 35.64124,
    "lng": -78.0284,
    "state_id": "NC"
  },
  {
    "zip": 27852,
    "lat": 35.75262,
    "lng": -77.63588,
    "state_id": "NC"
  },
  {
    "zip": 27853,
    "lat": 36.51478,
    "lng": -77.30433,
    "state_id": "NC"
  },
  {
    "zip": 27855,
    "lat": 36.42201,
    "lng": -77.08377,
    "state_id": "NC"
  },
  {
    "zip": 27856,
    "lat": 36.01379,
    "lng": -77.98267,
    "state_id": "NC"
  },
  {
    "zip": 27857,
    "lat": 35.97356,
    "lng": -77.28627,
    "state_id": "NC"
  },
  {
    "zip": 27858,
    "lat": 35.53207,
    "lng": -77.29789,
    "state_id": "NC"
  },
  {
    "zip": 27860,
    "lat": 35.65075,
    "lng": -76.68044,
    "state_id": "NC"
  },
  {
    "zip": 27861,
    "lat": 35.81488,
    "lng": -77.32014,
    "state_id": "NC"
  },
  {
    "zip": 27862,
    "lat": 36.49468,
    "lng": -77.17962,
    "state_id": "NC"
  },
  {
    "zip": 27863,
    "lat": 35.48762,
    "lng": -77.96791,
    "state_id": "NC"
  },
  {
    "zip": 27864,
    "lat": 35.80833,
    "lng": -77.64947,
    "state_id": "NC"
  },
  {
    "zip": 27865,
    "lat": 35.59315,
    "lng": -76.81676,
    "state_id": "NC"
  },
  {
    "zip": 27866,
    "lat": 36.51842,
    "lng": -77.51514,
    "state_id": "NC"
  },
  {
    "zip": 27869,
    "lat": 36.26606,
    "lng": -77.31438,
    "state_id": "NC"
  },
  {
    "zip": 27870,
    "lat": 36.43001,
    "lng": -77.72097,
    "state_id": "NC"
  },
  {
    "zip": 27871,
    "lat": 35.8111,
    "lng": -77.26213,
    "state_id": "NC"
  },
  {
    "zip": 27872,
    "lat": 36.19493,
    "lng": -77.2603,
    "state_id": "NC"
  },
  {
    "zip": 27873,
    "lat": 35.65358,
    "lng": -77.77524,
    "state_id": "NC"
  },
  {
    "zip": 27874,
    "lat": 36.12984,
    "lng": -77.40916,
    "state_id": "NC"
  },
  {
    "zip": 27875,
    "lat": 35.45785,
    "lng": -76.48668,
    "state_id": "NC"
  },
  {
    "zip": 27876,
    "lat": 36.47751,
    "lng": -77.39594,
    "state_id": "NC"
  },
  {
    "zip": 27878,
    "lat": 35.86681,
    "lng": -77.83493,
    "state_id": "NC"
  },
  {
    "zip": 27879,
    "lat": 35.57453,
    "lng": -77.27946,
    "state_id": "NC"
  },
  {
    "zip": 27880,
    "lat": 35.73497,
    "lng": -78.08706,
    "state_id": "NC"
  },
  {
    "zip": 27881,
    "lat": 35.97843,
    "lng": -77.43659,
    "state_id": "NC"
  },
  {
    "zip": 27882,
    "lat": 35.94071,
    "lng": -78.11629,
    "state_id": "NC"
  },
  {
    "zip": 27883,
    "lat": 35.59767,
    "lng": -77.79709,
    "state_id": "NC"
  },
  {
    "zip": 27884,
    "lat": 35.7046,
    "lng": -77.27004,
    "state_id": "NC"
  },
  {
    "zip": 27885,
    "lat": 35.41418,
    "lng": -76.26283,
    "state_id": "NC"
  },
  {
    "zip": 27886,
    "lat": 35.90299,
    "lng": -77.52247,
    "state_id": "NC"
  },
  {
    "zip": 27888,
    "lat": 35.58513,
    "lng": -77.70497,
    "state_id": "NC"
  },
  {
    "zip": 27889,
    "lat": 35.59112,
    "lng": -77.01419,
    "state_id": "NC"
  },
  {
    "zip": 27890,
    "lat": 36.41525,
    "lng": -77.59078,
    "state_id": "NC"
  },
  {
    "zip": 27891,
    "lat": 36.09929,
    "lng": -77.74091,
    "state_id": "NC"
  },
  {
    "zip": 27892,
    "lat": 35.81743,
    "lng": -77.06452,
    "state_id": "NC"
  },
  {
    "zip": 27893,
    "lat": 35.69204,
    "lng": -77.90257,
    "state_id": "NC"
  },
  {
    "zip": 27896,
    "lat": 35.79141,
    "lng": -77.97651,
    "state_id": "NC"
  },
  {
    "zip": 27897,
    "lat": 36.32502,
    "lng": -77.2107,
    "state_id": "NC"
  },
  {
    "zip": 27909,
    "lat": 36.29548,
    "lng": -76.28394,
    "state_id": "NC"
  },
  {
    "zip": 27910,
    "lat": 36.28948,
    "lng": -76.9931,
    "state_id": "NC"
  },
  {
    "zip": 27915,
    "lat": 35.40321,
    "lng": -75.4953,
    "state_id": "NC"
  },
  {
    "zip": 27916,
    "lat": 36.32212,
    "lng": -75.91509,
    "state_id": "NC"
  },
  {
    "zip": 27917,
    "lat": 36.36121,
    "lng": -75.99114,
    "state_id": "NC"
  },
  {
    "zip": 27919,
    "lat": 36.31187,
    "lng": -76.50347,
    "state_id": "NC"
  },
  {
    "zip": 27920,
    "lat": 35.25902,
    "lng": -75.53971,
    "state_id": "NC"
  },
  {
    "zip": 27921,
    "lat": 36.35833,
    "lng": -76.1691,
    "state_id": "NC"
  },
  {
    "zip": 27922,
    "lat": 36.32022,
    "lng": -76.84832,
    "state_id": "NC"
  },
  {
    "zip": 27923,
    "lat": 36.37888,
    "lng": -75.93668,
    "state_id": "NC"
  },
  {
    "zip": 27924,
    "lat": 36.17506,
    "lng": -76.83992,
    "state_id": "NC"
  },
  {
    "zip": 27925,
    "lat": 35.84819,
    "lng": -76.19669,
    "state_id": "NC"
  },
  {
    "zip": 27926,
    "lat": 36.51685,
    "lng": -76.6078,
    "state_id": "NC"
  },
  {
    "zip": 27927,
    "lat": 36.4131,
    "lng": -75.8497,
    "state_id": "NC"
  },
  {
    "zip": 27928,
    "lat": 35.82883,
    "lng": -76.43415,
    "state_id": "NC"
  },
  {
    "zip": 27929,
    "lat": 36.42767,
    "lng": -76.00129,
    "state_id": "NC"
  },
  {
    "zip": 27932,
    "lat": 36.1007,
    "lng": -76.60012,
    "state_id": "NC"
  },
  {
    "zip": 27935,
    "lat": 36.42984,
    "lng": -76.85863,
    "state_id": "NC"
  },
  {
    "zip": 27936,
    "lat": 35.24286,
    "lng": -75.58313,
    "state_id": "NC"
  },
  {
    "zip": 27937,
    "lat": 36.50216,
    "lng": -76.77868,
    "state_id": "NC"
  },
  {
    "zip": 27938,
    "lat": 36.3834,
    "lng": -76.71786,
    "state_id": "NC"
  },
  {
    "zip": 27939,
    "lat": 36.23639,
    "lng": -75.86756,
    "state_id": "NC"
  },
  {
    "zip": 27941,
    "lat": 36.10241,
    "lng": -75.8169,
    "state_id": "NC"
  },
  {
    "zip": 27942,
    "lat": 36.28368,
    "lng": -76.76262,
    "state_id": "NC"
  },
  {
    "zip": 27943,
    "lat": 35.21097,
    "lng": -75.7008,
    "state_id": "NC"
  },
  {
    "zip": 27944,
    "lat": 36.17466,
    "lng": -76.42018,
    "state_id": "NC"
  },
  {
    "zip": 27946,
    "lat": 36.35849,
    "lng": -76.60035,
    "state_id": "NC"
  },
  {
    "zip": 27947,
    "lat": 36.20521,
    "lng": -75.87756,
    "state_id": "NC"
  },
  {
    "zip": 27948,
    "lat": 36.01761,
    "lng": -75.68664,
    "state_id": "NC"
  },
  {
    "zip": 27949,
    "lat": 36.10813,
    "lng": -75.73111,
    "state_id": "NC"
  },
  {
    "zip": 27950,
    "lat": 36.52599,
    "lng": -76.00081,
    "state_id": "NC"
  },
  {
    "zip": 27953,
    "lat": 35.78282,
    "lng": -75.86446,
    "state_id": "NC"
  },
  {
    "zip": 27954,
    "lat": 35.91056,
    "lng": -75.68205,
    "state_id": "NC"
  },
  {
    "zip": 27956,
    "lat": 36.40253,
    "lng": -76.01406,
    "state_id": "NC"
  },
  {
    "zip": 27957,
    "lat": 36.06449,
    "lng": -76.77187,
    "state_id": "NC"
  },
  {
    "zip": 27958,
    "lat": 36.48334,
    "lng": -76.14524,
    "state_id": "NC"
  },
  {
    "zip": 27959,
    "lat": 35.88591,
    "lng": -75.59657,
    "state_id": "NC"
  },
  {
    "zip": 27960,
    "lat": 35.12624,
    "lng": -75.92083,
    "state_id": "NC"
  },
  {
    "zip": 27962,
    "lat": 35.79077,
    "lng": -76.73861,
    "state_id": "NC"
  },
  {
    "zip": 27964,
    "lat": 36.08598,
    "lng": -75.80222,
    "state_id": "NC"
  },
  {
    "zip": 27965,
    "lat": 36.28318,
    "lng": -75.92746,
    "state_id": "NC"
  },
  {
    "zip": 27966,
    "lat": 36.13401,
    "lng": -75.83924,
    "state_id": "NC"
  },
  {
    "zip": 27967,
    "lat": 36.2295,
    "lng": -76.9049,
    "state_id": "NC"
  },
  {
    "zip": 27968,
    "lat": 35.68291,
    "lng": -75.49116,
    "state_id": "NC"
  },
  {
    "zip": 27970,
    "lat": 35.87517,
    "lng": -76.58169,
    "state_id": "NC"
  },
  {
    "zip": 27972,
    "lat": 35.5482,
    "lng": -75.47106,
    "state_id": "NC"
  },
  {
    "zip": 27973,
    "lat": 36.35993,
    "lng": -76.06615,
    "state_id": "NC"
  },
  {
    "zip": 27974,
    "lat": 36.23919,
    "lng": -76.00642,
    "state_id": "NC"
  },
  {
    "zip": 27976,
    "lat": 36.48796,
    "lng": -76.35104,
    "state_id": "NC"
  },
  {
    "zip": 27978,
    "lat": 35.73482,
    "lng": -75.74841,
    "state_id": "NC"
  },
  {
    "zip": 27979,
    "lat": 36.44137,
    "lng": -76.55915,
    "state_id": "NC"
  },
  {
    "zip": 27980,
    "lat": 36.24376,
    "lng": -76.61884,
    "state_id": "NC"
  },
  {
    "zip": 27981,
    "lat": 35.85408,
    "lng": -75.6415,
    "state_id": "NC"
  },
  {
    "zip": 27982,
    "lat": 35.56512,
    "lng": -75.46628,
    "state_id": "NC"
  },
  {
    "zip": 27983,
    "lat": 35.98396,
    "lng": -76.93933,
    "state_id": "NC"
  },
  {
    "zip": 27985,
    "lat": 36.21286,
    "lng": -76.45829,
    "state_id": "NC"
  },
  {
    "zip": 27986,
    "lat": 36.38789,
    "lng": -76.92569,
    "state_id": "NC"
  },
  {
    "zip": 28001,
    "lat": 35.33623,
    "lng": -80.20477,
    "state_id": "NC"
  },
  {
    "zip": 28006,
    "lat": 35.4069,
    "lng": -81.09465,
    "state_id": "NC"
  },
  {
    "zip": 28007,
    "lat": 35.10451,
    "lng": -80.10989,
    "state_id": "NC"
  },
  {
    "zip": 28009,
    "lat": 35.40852,
    "lng": -80.10754,
    "state_id": "NC"
  },
  {
    "zip": 28012,
    "lat": 35.21042,
    "lng": -81.04074,
    "state_id": "NC"
  },
  {
    "zip": 28016,
    "lat": 35.31749,
    "lng": -81.28818,
    "state_id": "NC"
  },
  {
    "zip": 28017,
    "lat": 35.24709,
    "lng": -81.67063,
    "state_id": "NC"
  },
  {
    "zip": 28018,
    "lat": 35.48378,
    "lng": -81.79521,
    "state_id": "NC"
  },
  {
    "zip": 28019,
    "lat": 35.28039,
    "lng": -81.79053,
    "state_id": "NC"
  },
  {
    "zip": 28020,
    "lat": 35.52498,
    "lng": -81.64131,
    "state_id": "NC"
  },
  {
    "zip": 28021,
    "lat": 35.39855,
    "lng": -81.4098,
    "state_id": "NC"
  },
  {
    "zip": 28023,
    "lat": 35.56951,
    "lng": -80.60223,
    "state_id": "NC"
  },
  {
    "zip": 28025,
    "lat": 35.38042,
    "lng": -80.5279,
    "state_id": "NC"
  },
  {
    "zip": 28027,
    "lat": 35.40847,
    "lng": -80.67521,
    "state_id": "NC"
  },
  {
    "zip": 28031,
    "lat": 35.47155,
    "lng": -80.89809,
    "state_id": "NC"
  },
  {
    "zip": 28032,
    "lat": 35.23492,
    "lng": -81.08024,
    "state_id": "NC"
  },
  {
    "zip": 28033,
    "lat": 35.41871,
    "lng": -81.3211,
    "state_id": "NC"
  },
  {
    "zip": 28034,
    "lat": 35.35325,
    "lng": -81.18086,
    "state_id": "NC"
  },
  {
    "zip": 28036,
    "lat": 35.48573,
    "lng": -80.79425,
    "state_id": "NC"
  },
  {
    "zip": 28037,
    "lat": 35.50705,
    "lng": -81.01755,
    "state_id": "NC"
  },
  {
    "zip": 28039,
    "lat": 35.68043,
    "lng": -80.43551,
    "state_id": "NC"
  },
  {
    "zip": 28040,
    "lat": 35.39149,
    "lng": -81.74721,
    "state_id": "NC"
  },
  {
    "zip": 28043,
    "lat": 35.31198,
    "lng": -81.86931,
    "state_id": "NC"
  },
  {
    "zip": 28052,
    "lat": 35.22379,
    "lng": -81.23375,
    "state_id": "NC"
  },
  {
    "zip": 28054,
    "lat": 35.26276,
    "lng": -81.15012,
    "state_id": "NC"
  },
  {
    "zip": 28056,
    "lat": 35.21718,
    "lng": -81.12519,
    "state_id": "NC"
  },
  {
    "zip": 28071,
    "lat": 35.51557,
    "lng": -80.32699,
    "state_id": "NC"
  },
  {
    "zip": 28072,
    "lat": 35.61397,
    "lng": -80.44503,
    "state_id": "NC"
  },
  {
    "zip": 28073,
    "lat": 35.19718,
    "lng": -81.48098,
    "state_id": "NC"
  },
  {
    "zip": 28075,
    "lat": 35.30272,
    "lng": -80.63982,
    "state_id": "NC"
  },
  {
    "zip": 28076,
    "lat": 35.25931,
    "lng": -81.7888,
    "state_id": "NC"
  },
  {
    "zip": 28077,
    "lat": 35.40416,
    "lng": -81.20284,
    "state_id": "NC"
  },
  {
    "zip": 28078,
    "lat": 35.40491,
    "lng": -80.86263,
    "state_id": "NC"
  },
  {
    "zip": 28079,
    "lat": 35.11227,
    "lng": -80.60317,
    "state_id": "NC"
  },
  {
    "zip": 28080,
    "lat": 35.45254,
    "lng": -81.10976,
    "state_id": "NC"
  },
  {
    "zip": 28081,
    "lat": 35.50229,
    "lng": -80.66996,
    "state_id": "NC"
  },
  {
    "zip": 28083,
    "lat": 35.49082,
    "lng": -80.58059,
    "state_id": "NC"
  },
  {
    "zip": 28086,
    "lat": 35.243,
    "lng": -81.37108,
    "state_id": "NC"
  },
  {
    "zip": 28088,
    "lat": 35.54425,
    "lng": -80.61468,
    "state_id": "NC"
  },
  {
    "zip": 28089,
    "lat": 35.31818,
    "lng": -81.65975,
    "state_id": "NC"
  },
  {
    "zip": 28090,
    "lat": 35.45947,
    "lng": -81.56041,
    "state_id": "NC"
  },
  {
    "zip": 28091,
    "lat": 34.98477,
    "lng": -79.93154,
    "state_id": "NC"
  },
  {
    "zip": 28092,
    "lat": 35.48577,
    "lng": -81.25452,
    "state_id": "NC"
  },
  {
    "zip": 28097,
    "lat": 35.2967,
    "lng": -80.39302,
    "state_id": "NC"
  },
  {
    "zip": 28098,
    "lat": 35.27099,
    "lng": -81.09844,
    "state_id": "NC"
  },
  {
    "zip": 28101,
    "lat": 35.25782,
    "lng": -81.0789,
    "state_id": "NC"
  },
  {
    "zip": 28102,
    "lat": 34.81446,
    "lng": -79.97457,
    "state_id": "NC"
  },
  {
    "zip": 28103,
    "lat": 35.00287,
    "lng": -80.35279,
    "state_id": "NC"
  },
  {
    "zip": 28104,
    "lat": 35.06048,
    "lng": -80.69576,
    "state_id": "NC"
  },
  {
    "zip": 28105,
    "lat": 35.11659,
    "lng": -80.71313,
    "state_id": "NC"
  },
  {
    "zip": 28107,
    "lat": 35.25124,
    "lng": -80.52009,
    "state_id": "NC"
  },
  {
    "zip": 28108,
    "lat": 34.93426,
    "lng": -80.67945,
    "state_id": "NC"
  },
  {
    "zip": 28109,
    "lat": 35.49217,
    "lng": -80.28652,
    "state_id": "NC"
  },
  {
    "zip": 28110,
    "lat": 35.0675,
    "lng": -80.52703,
    "state_id": "NC"
  },
  {
    "zip": 28112,
    "lat": 34.89302,
    "lng": -80.54085,
    "state_id": "NC"
  },
  {
    "zip": 28114,
    "lat": 35.22947,
    "lng": -81.74926,
    "state_id": "NC"
  },
  {
    "zip": 28115,
    "lat": 35.57956,
    "lng": -80.77328,
    "state_id": "NC"
  },
  {
    "zip": 28117,
    "lat": 35.57216,
    "lng": -80.89562,
    "state_id": "NC"
  },
  {
    "zip": 28119,
    "lat": 34.8509,
    "lng": -80.01586,
    "state_id": "NC"
  },
  {
    "zip": 28120,
    "lat": 35.33151,
    "lng": -81.02617,
    "state_id": "NC"
  },
  {
    "zip": 28124,
    "lat": 35.40211,
    "lng": -80.40378,
    "state_id": "NC"
  },
  {
    "zip": 28125,
    "lat": 35.65947,
    "lng": -80.70306,
    "state_id": "NC"
  },
  {
    "zip": 28127,
    "lat": 35.45666,
    "lng": -80.17346,
    "state_id": "NC"
  },
  {
    "zip": 28128,
    "lat": 35.2146,
    "lng": -80.15849,
    "state_id": "NC"
  },
  {
    "zip": 28129,
    "lat": 35.2316,
    "lng": -80.32713,
    "state_id": "NC"
  },
  {
    "zip": 28133,
    "lat": 34.93454,
    "lng": -80.26135,
    "state_id": "NC"
  },
  {
    "zip": 28134,
    "lat": 35.08616,
    "lng": -80.8918,
    "state_id": "NC"
  },
  {
    "zip": 28135,
    "lat": 35.04001,
    "lng": -80.21081,
    "state_id": "NC"
  },
  {
    "zip": 28137,
    "lat": 35.50359,
    "lng": -80.2547,
    "state_id": "NC"
  },
  {
    "zip": 28138,
    "lat": 35.52095,
    "lng": -80.44185,
    "state_id": "NC"
  },
  {
    "zip": 28139,
    "lat": 35.3487,
    "lng": -81.99232,
    "state_id": "NC"
  },
  {
    "zip": 28144,
    "lat": 35.70622,
    "lng": -80.46414,
    "state_id": "NC"
  },
  {
    "zip": 28146,
    "lat": 35.62089,
    "lng": -80.39305,
    "state_id": "NC"
  },
  {
    "zip": 28147,
    "lat": 35.67987,
    "lng": -80.56392,
    "state_id": "NC"
  },
  {
    "zip": 28150,
    "lat": 35.34829,
    "lng": -81.57502,
    "state_id": "NC"
  },
  {
    "zip": 28152,
    "lat": 35.24301,
    "lng": -81.59517,
    "state_id": "NC"
  },
  {
    "zip": 28159,
    "lat": 35.69465,
    "lng": -80.43198,
    "state_id": "NC"
  },
  {
    "zip": 28160,
    "lat": 35.36102,
    "lng": -81.92233,
    "state_id": "NC"
  },
  {
    "zip": 28163,
    "lat": 35.20743,
    "lng": -80.42853,
    "state_id": "NC"
  },
  {
    "zip": 28164,
    "lat": 35.39171,
    "lng": -81.03812,
    "state_id": "NC"
  },
  {
    "zip": 28166,
    "lat": 35.68145,
    "lng": -80.87567,
    "state_id": "NC"
  },
  {
    "zip": 28167,
    "lat": 35.50618,
    "lng": -81.96192,
    "state_id": "NC"
  },
  {
    "zip": 28168,
    "lat": 35.55476,
    "lng": -81.42363,
    "state_id": "NC"
  },
  {
    "zip": 28169,
    "lat": 35.35844,
    "lng": -81.42931,
    "state_id": "NC"
  },
  {
    "zip": 28170,
    "lat": 34.99577,
    "lng": -80.09668,
    "state_id": "NC"
  },
  {
    "zip": 28173,
    "lat": 34.91685,
    "lng": -80.73013,
    "state_id": "NC"
  },
  {
    "zip": 28174,
    "lat": 34.96987,
    "lng": -80.43858,
    "state_id": "NC"
  },
  {
    "zip": 28202,
    "lat": 35.22778,
    "lng": -80.84458,
    "state_id": "NC"
  },
  {
    "zip": 28203,
    "lat": 35.20815,
    "lng": -80.85911,
    "state_id": "NC"
  },
  {
    "zip": 28204,
    "lat": 35.21463,
    "lng": -80.82702,
    "state_id": "NC"
  },
  {
    "zip": 28205,
    "lat": 35.21973,
    "lng": -80.78791,
    "state_id": "NC"
  },
  {
    "zip": 28206,
    "lat": 35.25679,
    "lng": -80.82116,
    "state_id": "NC"
  },
  {
    "zip": 28207,
    "lat": 35.19512,
    "lng": -80.82622,
    "state_id": "NC"
  },
  {
    "zip": 28208,
    "lat": 35.23057,
    "lng": -80.90992,
    "state_id": "NC"
  },
  {
    "zip": 28209,
    "lat": 35.17854,
    "lng": -80.85386,
    "state_id": "NC"
  },
  {
    "zip": 28210,
    "lat": 35.129,
    "lng": -80.85552,
    "state_id": "NC"
  },
  {
    "zip": 28211,
    "lat": 35.16807,
    "lng": -80.79616,
    "state_id": "NC"
  },
  {
    "zip": 28212,
    "lat": 35.18943,
    "lng": -80.74511,
    "state_id": "NC"
  },
  {
    "zip": 28213,
    "lat": 35.28493,
    "lng": -80.73326,
    "state_id": "NC"
  },
  {
    "zip": 28214,
    "lat": 35.27488,
    "lng": -80.96851,
    "state_id": "NC"
  },
  {
    "zip": 28215,
    "lat": 35.24684,
    "lng": -80.6935,
    "state_id": "NC"
  },
  {
    "zip": 28216,
    "lat": 35.31122,
    "lng": -80.8875,
    "state_id": "NC"
  },
  {
    "zip": 28217,
    "lat": 35.17145,
    "lng": -80.90839,
    "state_id": "NC"
  },
  {
    "zip": 28226,
    "lat": 35.1067,
    "lng": -80.81984,
    "state_id": "NC"
  },
  {
    "zip": 28227,
    "lat": 35.18614,
    "lng": -80.65136,
    "state_id": "NC"
  },
  {
    "zip": 28244,
    "lat": 35.2246,
    "lng": -80.8431,
    "state_id": "NC"
  },
  {
    "zip": 28262,
    "lat": 35.32513,
    "lng": -80.74224,
    "state_id": "NC"
  },
  {
    "zip": 28269,
    "lat": 35.33788,
    "lng": -80.80251,
    "state_id": "NC"
  },
  {
    "zip": 28270,
    "lat": 35.10982,
    "lng": -80.76033,
    "state_id": "NC"
  },
  {
    "zip": 28273,
    "lat": 35.12675,
    "lng": -80.94667,
    "state_id": "NC"
  },
  {
    "zip": 28277,
    "lat": 35.05238,
    "lng": -80.81752,
    "state_id": "NC"
  },
  {
    "zip": 28278,
    "lat": 35.12961,
    "lng": -81.00788,
    "state_id": "NC"
  },
  {
    "zip": 28280,
    "lat": 35.22633,
    "lng": -80.84309,
    "state_id": "NC"
  },
  {
    "zip": 28282,
    "lat": 35.22481,
    "lng": -80.84528,
    "state_id": "NC"
  },
  {
    "zip": 28301,
    "lat": 35.07431,
    "lng": -78.88358,
    "state_id": "NC"
  },
  {
    "zip": 28303,
    "lat": 35.08602,
    "lng": -78.9618,
    "state_id": "NC"
  },
  {
    "zip": 28304,
    "lat": 35.02599,
    "lng": -78.98983,
    "state_id": "NC"
  },
  {
    "zip": 28305,
    "lat": 35.05266,
    "lng": -78.90674,
    "state_id": "NC"
  },
  {
    "zip": 28306,
    "lat": 34.95875,
    "lng": -78.89551,
    "state_id": "NC"
  },
  {
    "zip": 28307,
    "lat": 35.13737,
    "lng": -78.98501,
    "state_id": "NC"
  },
  {
    "zip": 28308,
    "lat": 35.17102,
    "lng": -79.01498,
    "state_id": "NC"
  },
  {
    "zip": 28310,
    "lat": 35.14157,
    "lng": -79.02177,
    "state_id": "NC"
  },
  {
    "zip": 28311,
    "lat": 35.17184,
    "lng": -78.88169,
    "state_id": "NC"
  },
  {
    "zip": 28312,
    "lat": 34.95501,
    "lng": -78.74072,
    "state_id": "NC"
  },
  {
    "zip": 28314,
    "lat": 35.05815,
    "lng": -79.02259,
    "state_id": "NC"
  },
  {
    "zip": 28315,
    "lat": 35.1165,
    "lng": -79.43682,
    "state_id": "NC"
  },
  {
    "zip": 28318,
    "lat": 35.01788,
    "lng": -78.61414,
    "state_id": "NC"
  },
  {
    "zip": 28320,
    "lat": 34.5518,
    "lng": -78.77337,
    "state_id": "NC"
  },
  {
    "zip": 28323,
    "lat": 35.31194,
    "lng": -78.84057,
    "state_id": "NC"
  },
  {
    "zip": 28325,
    "lat": 35.15392,
    "lng": -78.1042,
    "state_id": "NC"
  },
  {
    "zip": 28326,
    "lat": 35.28032,
    "lng": -79.1685,
    "state_id": "NC"
  },
  {
    "zip": 28327,
    "lat": 35.35637,
    "lng": -79.42307,
    "state_id": "NC"
  },
  {
    "zip": 28328,
    "lat": 35.00345,
    "lng": -78.33829,
    "state_id": "NC"
  },
  {
    "zip": 28330,
    "lat": 34.90959,
    "lng": -79.82238,
    "state_id": "NC"
  },
  {
    "zip": 28332,
    "lat": 34.65911,
    "lng": -78.73795,
    "state_id": "NC"
  },
  {
    "zip": 28333,
    "lat": 35.28272,
    "lng": -78.01278,
    "state_id": "NC"
  },
  {
    "zip": 28334,
    "lat": 35.26849,
    "lng": -78.56989,
    "state_id": "NC"
  },
  {
    "zip": 28337,
    "lat": 34.66141,
    "lng": -78.57076,
    "state_id": "NC"
  },
  {
    "zip": 28338,
    "lat": 35.10278,
    "lng": -79.75911,
    "state_id": "NC"
  },
  {
    "zip": 28339,
    "lat": 35.31414,
    "lng": -78.72176,
    "state_id": "NC"
  },
  {
    "zip": 28340,
    "lat": 34.47199,
    "lng": -79.14074,
    "state_id": "NC"
  },
  {
    "zip": 28341,
    "lat": 35.11891,
    "lng": -78.17831,
    "state_id": "NC"
  },
  {
    "zip": 28342,
    "lat": 35.19194,
    "lng": -78.64973,
    "state_id": "NC"
  },
  {
    "zip": 28343,
    "lat": 34.7635,
    "lng": -79.58189,
    "state_id": "NC"
  },
  {
    "zip": 28344,
    "lat": 35.16341,
    "lng": -78.62734,
    "state_id": "NC"
  },
  {
    "zip": 28345,
    "lat": 34.87411,
    "lng": -79.66866,
    "state_id": "NC"
  },
  {
    "zip": 28347,
    "lat": 35.05501,
    "lng": -79.59385,
    "state_id": "NC"
  },
  {
    "zip": 28348,
    "lat": 34.92635,
    "lng": -78.91152,
    "state_id": "NC"
  },
  {
    "zip": 28349,
    "lat": 34.97677,
    "lng": -77.9171,
    "state_id": "NC"
  },
  {
    "zip": 28350,
    "lat": 35.24354,
    "lng": -79.31306,
    "state_id": "NC"
  },
  {
    "zip": 28351,
    "lat": 34.84025,
    "lng": -79.55836,
    "state_id": "NC"
  },
  {
    "zip": 28352,
    "lat": 34.76732,
    "lng": -79.45084,
    "state_id": "NC"
  },
  {
    "zip": 28356,
    "lat": 35.23088,
    "lng": -78.7909,
    "state_id": "NC"
  },
  {
    "zip": 28357,
    "lat": 34.90575,
    "lng": -79.08703,
    "state_id": "NC"
  },
  {
    "zip": 28358,
    "lat": 34.60567,
    "lng": -78.94113,
    "state_id": "NC"
  },
  {
    "zip": 28360,
    "lat": 34.67464,
    "lng": -79.07344,
    "state_id": "NC"
  },
  {
    "zip": 28363,
    "lat": 34.95472,
    "lng": -79.54204,
    "state_id": "NC"
  },
  {
    "zip": 28364,
    "lat": 34.71235,
    "lng": -79.32696,
    "state_id": "NC"
  },
  {
    "zip": 28365,
    "lat": 35.17701,
    "lng": -78.0566,
    "state_id": "NC"
  },
  {
    "zip": 28366,
    "lat": 35.23019,
    "lng": -78.35944,
    "state_id": "NC"
  },
  {
    "zip": 28367,
    "lat": 35.1727,
    "lng": -79.72528,
    "state_id": "NC"
  },
  {
    "zip": 28369,
    "lat": 34.42096,
    "lng": -79.03027,
    "state_id": "NC"
  },
  {
    "zip": 28371,
    "lat": 34.90636,
    "lng": -78.98946,
    "state_id": "NC"
  },
  {
    "zip": 28372,
    "lat": 34.69689,
    "lng": -79.1776,
    "state_id": "NC"
  },
  {
    "zip": 28373,
    "lat": 35.09074,
    "lng": -79.48519,
    "state_id": "NC"
  },
  {
    "zip": 28374,
    "lat": 35.19759,
    "lng": -79.46247,
    "state_id": "NC"
  },
  {
    "zip": 28375,
    "lat": 34.47572,
    "lng": -79.03855,
    "state_id": "NC"
  },
  {
    "zip": 28376,
    "lat": 34.9919,
    "lng": -79.24262,
    "state_id": "NC"
  },
  {
    "zip": 28377,
    "lat": 34.83438,
    "lng": -79.21329,
    "state_id": "NC"
  },
  {
    "zip": 28379,
    "lat": 34.93102,
    "lng": -79.78009,
    "state_id": "NC"
  },
  {
    "zip": 28382,
    "lat": 34.96393,
    "lng": -78.51332,
    "state_id": "NC"
  },
  {
    "zip": 28383,
    "lat": 34.5755,
    "lng": -79.26576,
    "state_id": "NC"
  },
  {
    "zip": 28384,
    "lat": 34.79838,
    "lng": -78.95735,
    "state_id": "NC"
  },
  {
    "zip": 28385,
    "lat": 35.04525,
    "lng": -78.49749,
    "state_id": "NC"
  },
  {
    "zip": 28386,
    "lat": 34.85423,
    "lng": -79.12742,
    "state_id": "NC"
  },
  {
    "zip": 28387,
    "lat": 35.17912,
    "lng": -79.37607,
    "state_id": "NC"
  },
  {
    "zip": 28390,
    "lat": 35.21757,
    "lng": -78.95465,
    "state_id": "NC"
  },
  {
    "zip": 28391,
    "lat": 35.02121,
    "lng": -78.70175,
    "state_id": "NC"
  },
  {
    "zip": 28392,
    "lat": 34.73801,
    "lng": -78.80148,
    "state_id": "NC"
  },
  {
    "zip": 28393,
    "lat": 34.97788,
    "lng": -78.18778,
    "state_id": "NC"
  },
  {
    "zip": 28394,
    "lat": 35.21684,
    "lng": -79.23466,
    "state_id": "NC"
  },
  {
    "zip": 28395,
    "lat": 35.13842,
    "lng": -78.73982,
    "state_id": "NC"
  },
  {
    "zip": 28396,
    "lat": 34.92929,
    "lng": -79.40378,
    "state_id": "NC"
  },
  {
    "zip": 28398,
    "lat": 34.99525,
    "lng": -78.06875,
    "state_id": "NC"
  },
  {
    "zip": 28399,
    "lat": 34.76006,
    "lng": -78.70514,
    "state_id": "NC"
  },
  {
    "zip": 28401,
    "lat": 34.27257,
    "lng": -77.96332,
    "state_id": "NC"
  },
  {
    "zip": 28403,
    "lat": 34.22189,
    "lng": -77.87985,
    "state_id": "NC"
  },
  {
    "zip": 28405,
    "lat": 34.26515,
    "lng": -77.86701,
    "state_id": "NC"
  },
  {
    "zip": 28409,
    "lat": 34.15484,
    "lng": -77.86235,
    "state_id": "NC"
  },
  {
    "zip": 28411,
    "lat": 34.30228,
    "lng": -77.7917,
    "state_id": "NC"
  },
  {
    "zip": 28412,
    "lat": 34.13314,
    "lng": -77.9194,
    "state_id": "NC"
  },
  {
    "zip": 28420,
    "lat": 34.07423,
    "lng": -78.48055,
    "state_id": "NC"
  },
  {
    "zip": 28421,
    "lat": 34.52082,
    "lng": -78.17817,
    "state_id": "NC"
  },
  {
    "zip": 28422,
    "lat": 34.0196,
    "lng": -78.17297,
    "state_id": "NC"
  },
  {
    "zip": 28423,
    "lat": 34.31178,
    "lng": -78.39381,
    "state_id": "NC"
  },
  {
    "zip": 28424,
    "lat": 34.29029,
    "lng": -78.69973,
    "state_id": "NC"
  },
  {
    "zip": 28425,
    "lat": 34.56277,
    "lng": -77.89637,
    "state_id": "NC"
  },
  {
    "zip": 28428,
    "lat": 34.03832,
    "lng": -77.90681,
    "state_id": "NC"
  },
  {
    "zip": 28429,
    "lat": 34.34202,
    "lng": -77.90118,
    "state_id": "NC"
  },
  {
    "zip": 28430,
    "lat": 34.29242,
    "lng": -78.93519,
    "state_id": "NC"
  },
  {
    "zip": 28431,
    "lat": 34.31373,
    "lng": -78.83935,
    "state_id": "NC"
  },
  {
    "zip": 28432,
    "lat": 34.17623,
    "lng": -78.76396,
    "state_id": "NC"
  },
  {
    "zip": 28433,
    "lat": 34.4911,
    "lng": -78.62406,
    "state_id": "NC"
  },
  {
    "zip": 28434,
    "lat": 34.46645,
    "lng": -78.46909,
    "state_id": "NC"
  },
  {
    "zip": 28435,
    "lat": 34.4076,
    "lng": -78.10562,
    "state_id": "NC"
  },
  {
    "zip": 28436,
    "lat": 34.27617,
    "lng": -78.26633,
    "state_id": "NC"
  },
  {
    "zip": 28438,
    "lat": 34.41155,
    "lng": -78.92459,
    "state_id": "NC"
  },
  {
    "zip": 28439,
    "lat": 34.29479,
    "lng": -79.00975,
    "state_id": "NC"
  },
  {
    "zip": 28441,
    "lat": 34.78969,
    "lng": -78.4309,
    "state_id": "NC"
  },
  {
    "zip": 28442,
    "lat": 34.28908,
    "lng": -78.60226,
    "state_id": "NC"
  },
  {
    "zip": 28443,
    "lat": 34.43991,
    "lng": -77.67196,
    "state_id": "NC"
  },
  {
    "zip": 28444,
    "lat": 34.69175,
    "lng": -78.31961,
    "state_id": "NC"
  },
  {
    "zip": 28445,
    "lat": 34.51045,
    "lng": -77.54669,
    "state_id": "NC"
  },
  {
    "zip": 28447,
    "lat": 34.60051,
    "lng": -78.25933,
    "state_id": "NC"
  },
  {
    "zip": 28448,
    "lat": 34.49223,
    "lng": -78.32202,
    "state_id": "NC"
  },
  {
    "zip": 28449,
    "lat": 33.98155,
    "lng": -77.9275,
    "state_id": "NC"
  },
  {
    "zip": 28450,
    "lat": 34.32535,
    "lng": -78.51763,
    "state_id": "NC"
  },
  {
    "zip": 28451,
    "lat": 34.23768,
    "lng": -78.09331,
    "state_id": "NC"
  },
  {
    "zip": 28452,
    "lat": 33.99718,
    "lng": -78.55299,
    "state_id": "NC"
  },
  {
    "zip": 28453,
    "lat": 34.88191,
    "lng": -78.07909,
    "state_id": "NC"
  },
  {
    "zip": 28454,
    "lat": 34.6783,
    "lng": -77.6509,
    "state_id": "NC"
  },
  {
    "zip": 28455,
    "lat": 34.11281,
    "lng": -78.63202,
    "state_id": "NC"
  },
  {
    "zip": 28456,
    "lat": 34.37595,
    "lng": -78.2803,
    "state_id": "NC"
  },
  {
    "zip": 28457,
    "lat": 34.44759,
    "lng": -77.88907,
    "state_id": "NC"
  },
  {
    "zip": 28458,
    "lat": 34.81827,
    "lng": -78.08992,
    "state_id": "NC"
  },
  {
    "zip": 28460,
    "lat": 34.53297,
    "lng": -77.424,
    "state_id": "NC"
  },
  {
    "zip": 28461,
    "lat": 33.96625,
    "lng": -78.03635,
    "state_id": "NC"
  },
  {
    "zip": 28462,
    "lat": 34.02318,
    "lng": -78.2885,
    "state_id": "NC"
  },
  {
    "zip": 28463,
    "lat": 34.10815,
    "lng": -78.78748,
    "state_id": "NC"
  },
  {
    "zip": 28464,
    "lat": 34.77792,
    "lng": -78.01925,
    "state_id": "NC"
  },
  {
    "zip": 28465,
    "lat": 33.91255,
    "lng": -78.1033,
    "state_id": "NC"
  },
  {
    "zip": 28466,
    "lat": 34.73526,
    "lng": -77.90114,
    "state_id": "NC"
  },
  {
    "zip": 28467,
    "lat": 33.91762,
    "lng": -78.58185,
    "state_id": "NC"
  },
  {
    "zip": 28468,
    "lat": 33.90232,
    "lng": -78.52122,
    "state_id": "NC"
  },
  {
    "zip": 28469,
    "lat": 33.92705,
    "lng": -78.46877,
    "state_id": "NC"
  },
  {
    "zip": 28470,
    "lat": 33.96367,
    "lng": -78.40642,
    "state_id": "NC"
  },
  {
    "zip": 28472,
    "lat": 34.30705,
    "lng": -78.68917,
    "state_id": "NC"
  },
  {
    "zip": 28478,
    "lat": 34.64302,
    "lng": -78.03846,
    "state_id": "NC"
  },
  {
    "zip": 28479,
    "lat": 34.09652,
    "lng": -78.01755,
    "state_id": "NC"
  },
  {
    "zip": 28480,
    "lat": 34.22393,
    "lng": -77.79325,
    "state_id": "NC"
  },
  {
    "zip": 28501,
    "lat": 35.24439,
    "lng": -77.5219,
    "state_id": "NC"
  },
  {
    "zip": 28504,
    "lat": 35.22496,
    "lng": -77.63538,
    "state_id": "NC"
  },
  {
    "zip": 28508,
    "lat": 35.10033,
    "lng": -77.82326,
    "state_id": "NC"
  },
  {
    "zip": 28510,
    "lat": 35.00388,
    "lng": -76.80688,
    "state_id": "NC"
  },
  {
    "zip": 28511,
    "lat": 34.8996,
    "lng": -76.35195,
    "state_id": "NC"
  },
  {
    "zip": 28512,
    "lat": 34.69926,
    "lng": -76.76783,
    "state_id": "NC"
  },
  {
    "zip": 28513,
    "lat": 35.4415,
    "lng": -77.38893,
    "state_id": "NC"
  },
  {
    "zip": 28515,
    "lat": 35.18605,
    "lng": -76.70616,
    "state_id": "NC"
  },
  {
    "zip": 28516,
    "lat": 34.88857,
    "lng": -76.55466,
    "state_id": "NC"
  },
  {
    "zip": 28518,
    "lat": 34.89644,
    "lng": -77.75658,
    "state_id": "NC"
  },
  {
    "zip": 28519,
    "lat": 35.12374,
    "lng": -77.02169,
    "state_id": "NC"
  },
  {
    "zip": 28520,
    "lat": 34.97178,
    "lng": -76.32332,
    "state_id": "NC"
  },
  {
    "zip": 28521,
    "lat": 34.8245,
    "lng": -77.7376,
    "state_id": "NC"
  },
  {
    "zip": 28523,
    "lat": 35.20867,
    "lng": -77.29174,
    "state_id": "NC"
  },
  {
    "zip": 28524,
    "lat": 34.7996,
    "lng": -76.47021,
    "state_id": "NC"
  },
  {
    "zip": 28525,
    "lat": 35.12455,
    "lng": -77.69267,
    "state_id": "NC"
  },
  {
    "zip": 28526,
    "lat": 35.25087,
    "lng": -77.39745,
    "state_id": "NC"
  },
  {
    "zip": 28527,
    "lat": 35.26609,
    "lng": -77.01715,
    "state_id": "NC"
  },
  {
    "zip": 28528,
    "lat": 34.73775,
    "lng": -76.53945,
    "state_id": "NC"
  },
  {
    "zip": 28529,
    "lat": 35.08159,
    "lng": -76.86145,
    "state_id": "NC"
  },
  {
    "zip": 28530,
    "lat": 35.3727,
    "lng": -77.41187,
    "state_id": "NC"
  },
  {
    "zip": 28531,
    "lat": 34.70015,
    "lng": -76.56116,
    "state_id": "NC"
  },
  {
    "zip": 28532,
    "lat": 34.88813,
    "lng": -76.90891,
    "state_id": "NC"
  },
  {
    "zip": 28533,
    "lat": 34.90284,
    "lng": -76.90147,
    "state_id": "NC"
  },
  {
    "zip": 28537,
    "lat": 35.24548,
    "lng": -76.54945,
    "state_id": "NC"
  },
  {
    "zip": 28538,
    "lat": 35.41919,
    "lng": -77.56436,
    "state_id": "NC"
  },
  {
    "zip": 28539,
    "lat": 34.71336,
    "lng": -77.21704,
    "state_id": "NC"
  },
  {
    "zip": 28540,
    "lat": 34.75633,
    "lng": -77.50563,
    "state_id": "NC"
  },
  {
    "zip": 28543,
    "lat": 34.73361,
    "lng": -77.3788,
    "state_id": "NC"
  },
  {
    "zip": 28544,
    "lat": 34.72129,
    "lng": -77.30735,
    "state_id": "NC"
  },
  {
    "zip": 28546,
    "lat": 34.79662,
    "lng": -77.36104,
    "state_id": "NC"
  },
  {
    "zip": 28547,
    "lat": 34.6713,
    "lng": -77.37371,
    "state_id": "NC"
  },
  {
    "zip": 28551,
    "lat": 35.32188,
    "lng": -77.78151,
    "state_id": "NC"
  },
  {
    "zip": 28552,
    "lat": 35.30146,
    "lng": -76.55464,
    "state_id": "NC"
  },
  {
    "zip": 28553,
    "lat": 34.73223,
    "lng": -76.51345,
    "state_id": "NC"
  },
  {
    "zip": 28554,
    "lat": 35.47849,
    "lng": -77.58598,
    "state_id": "NC"
  },
  {
    "zip": 28555,
    "lat": 34.87471,
    "lng": -77.22871,
    "state_id": "NC"
  },
  {
    "zip": 28556,
    "lat": 35.12436,
    "lng": -76.6651,
    "state_id": "NC"
  },
  {
    "zip": 28557,
    "lat": 34.74059,
    "lng": -76.74584,
    "state_id": "NC"
  },
  {
    "zip": 28560,
    "lat": 35.13426,
    "lng": -76.98571,
    "state_id": "NC"
  },
  {
    "zip": 28562,
    "lat": 35.08049,
    "lng": -77.12678,
    "state_id": "NC"
  },
  {
    "zip": 28570,
    "lat": 34.78153,
    "lng": -76.85393,
    "state_id": "NC"
  },
  {
    "zip": 28571,
    "lat": 35.05996,
    "lng": -76.71423,
    "state_id": "NC"
  },
  {
    "zip": 28572,
    "lat": 35.02305,
    "lng": -77.73025,
    "state_id": "NC"
  },
  {
    "zip": 28573,
    "lat": 34.9965,
    "lng": -77.19904,
    "state_id": "NC"
  },
  {
    "zip": 28574,
    "lat": 34.87858,
    "lng": -77.59493,
    "state_id": "NC"
  },
  {
    "zip": 28575,
    "lat": 34.68734,
    "lng": -76.89137,
    "state_id": "NC"
  },
  {
    "zip": 28577,
    "lat": 34.8762,
    "lng": -76.38518,
    "state_id": "NC"
  },
  {
    "zip": 28578,
    "lat": 35.20056,
    "lng": -77.86341,
    "state_id": "NC"
  },
  {
    "zip": 28579,
    "lat": 34.76251,
    "lng": -76.51679,
    "state_id": "NC"
  },
  {
    "zip": 28580,
    "lat": 35.45271,
    "lng": -77.68335,
    "state_id": "NC"
  },
  {
    "zip": 28581,
    "lat": 34.85052,
    "lng": -76.43611,
    "state_id": "NC"
  },
  {
    "zip": 28582,
    "lat": 34.75864,
    "lng": -77.15031,
    "state_id": "NC"
  },
  {
    "zip": 28583,
    "lat": 35.13707,
    "lng": -76.74056,
    "state_id": "NC"
  },
  {
    "zip": 28584,
    "lat": 34.74606,
    "lng": -77.06903,
    "state_id": "NC"
  },
  {
    "zip": 28585,
    "lat": 35.07395,
    "lng": -77.41455,
    "state_id": "NC"
  },
  {
    "zip": 28586,
    "lat": 35.31639,
    "lng": -77.16349,
    "state_id": "NC"
  },
  {
    "zip": 28587,
    "lat": 35.19355,
    "lng": -76.6693,
    "state_id": "NC"
  },
  {
    "zip": 28589,
    "lat": 34.81049,
    "lng": -76.50511,
    "state_id": "NC"
  },
  {
    "zip": 28590,
    "lat": 35.52049,
    "lng": -77.41628,
    "state_id": "NC"
  },
  {
    "zip": 28594,
    "lat": 34.66485,
    "lng": -77.02923,
    "state_id": "NC"
  },
  {
    "zip": 28601,
    "lat": 35.77253,
    "lng": -81.32634,
    "state_id": "NC"
  },
  {
    "zip": 28602,
    "lat": 35.67609,
    "lng": -81.38615,
    "state_id": "NC"
  },
  {
    "zip": 28604,
    "lat": 36.1772,
    "lng": -81.8479,
    "state_id": "NC"
  },
  {
    "zip": 28605,
    "lat": 36.13548,
    "lng": -81.6996,
    "state_id": "NC"
  },
  {
    "zip": 28606,
    "lat": 36.05343,
    "lng": -81.31883,
    "state_id": "NC"
  },
  {
    "zip": 28607,
    "lat": 36.21795,
    "lng": -81.65523,
    "state_id": "NC"
  },
  {
    "zip": 28609,
    "lat": 35.67414,
    "lng": -81.05336,
    "state_id": "NC"
  },
  {
    "zip": 28610,
    "lat": 35.7323,
    "lng": -81.13683,
    "state_id": "NC"
  },
  {
    "zip": 28611,
    "lat": 36.01102,
    "lng": -81.73527,
    "state_id": "NC"
  },
  {
    "zip": 28612,
    "lat": 35.66086,
    "lng": -81.54008,
    "state_id": "NC"
  },
  {
    "zip": 28613,
    "lat": 35.74082,
    "lng": -81.20915,
    "state_id": "NC"
  },
  {
    "zip": 28615,
    "lat": 36.46267,
    "lng": -81.65856,
    "state_id": "NC"
  },
  {
    "zip": 28616,
    "lat": 36.02313,
    "lng": -81.92013,
    "state_id": "NC"
  },
  {
    "zip": 28617,
    "lat": 36.48213,
    "lng": -81.38023,
    "state_id": "NC"
  },
  {
    "zip": 28618,
    "lat": 36.20672,
    "lng": -81.5172,
    "state_id": "NC"
  },
  {
    "zip": 28619,
    "lat": 35.75855,
    "lng": -81.60243,
    "state_id": "NC"
  },
  {
    "zip": 28621,
    "lat": 36.30808,
    "lng": -80.83736,
    "state_id": "NC"
  },
  {
    "zip": 28622,
    "lat": 36.19264,
    "lng": -81.94743,
    "state_id": "NC"
  },
  {
    "zip": 28623,
    "lat": 36.52964,
    "lng": -80.97076,
    "state_id": "NC"
  },
  {
    "zip": 28624,
    "lat": 36.12128,
    "lng": -81.42019,
    "state_id": "NC"
  },
  {
    "zip": 28625,
    "lat": 35.86507,
    "lng": -80.88572,
    "state_id": "NC"
  },
  {
    "zip": 28626,
    "lat": 36.2954,
    "lng": -81.50144,
    "state_id": "NC"
  },
  {
    "zip": 28627,
    "lat": 36.45785,
    "lng": -81.00585,
    "state_id": "NC"
  },
  {
    "zip": 28628,
    "lat": 35.72629,
    "lng": -81.78507,
    "state_id": "NC"
  },
  {
    "zip": 28629,
    "lat": 36.34017,
    "lng": -81.36516,
    "state_id": "NC"
  },
  {
    "zip": 28630,
    "lat": 35.82489,
    "lng": -81.42344,
    "state_id": "NC"
  },
  {
    "zip": 28631,
    "lat": 36.5515,
    "lng": -81.41082,
    "state_id": "NC"
  },
  {
    "zip": 28634,
    "lat": 35.97155,
    "lng": -80.75388,
    "state_id": "NC"
  },
  {
    "zip": 28635,
    "lat": 36.30546,
    "lng": -81.12605,
    "state_id": "NC"
  },
  {
    "zip": 28636,
    "lat": 35.94324,
    "lng": -81.06397,
    "state_id": "NC"
  },
  {
    "zip": 28637,
    "lat": 35.71798,
    "lng": -81.41929,
    "state_id": "NC"
  },
  {
    "zip": 28638,
    "lat": 35.8446,
    "lng": -81.47663,
    "state_id": "NC"
  },
  {
    "zip": 28640,
    "lat": 36.40345,
    "lng": -81.40751,
    "state_id": "NC"
  },
  {
    "zip": 28642,
    "lat": 36.2194,
    "lng": -80.81964,
    "state_id": "NC"
  },
  {
    "zip": 28643,
    "lat": 36.5296,
    "lng": -81.5344,
    "state_id": "NC"
  },
  {
    "zip": 28644,
    "lat": 36.4313,
    "lng": -81.26683,
    "state_id": "NC"
  },
  {
    "zip": 28645,
    "lat": 35.97735,
    "lng": -81.54417,
    "state_id": "NC"
  },
  {
    "zip": 28646,
    "lat": 36.07917,
    "lng": -81.8392,
    "state_id": "NC"
  },
  {
    "zip": 28647,
    "lat": 35.938,
    "lng": -81.973,
    "state_id": "NC"
  },
  {
    "zip": 28649,
    "lat": 36.33683,
    "lng": -81.20101,
    "state_id": "NC"
  },
  {
    "zip": 28650,
    "lat": 35.57214,
    "lng": -81.15196,
    "state_id": "NC"
  },
  {
    "zip": 28651,
    "lat": 36.27221,
    "lng": -81.2849,
    "state_id": "NC"
  },
  {
    "zip": 28652,
    "lat": 36.10008,
    "lng": -81.98729,
    "state_id": "NC"
  },
  {
    "zip": 28654,
    "lat": 36.06185,
    "lng": -81.15149,
    "state_id": "NC"
  },
  {
    "zip": 28655,
    "lat": 35.75713,
    "lng": -81.74413,
    "state_id": "NC"
  },
  {
    "zip": 28657,
    "lat": 36.03015,
    "lng": -81.94099,
    "state_id": "NC"
  },
  {
    "zip": 28658,
    "lat": 35.63586,
    "lng": -81.24087,
    "state_id": "NC"
  },
  {
    "zip": 28659,
    "lat": 36.16602,
    "lng": -81.07622,
    "state_id": "NC"
  },
  {
    "zip": 28660,
    "lat": 35.96842,
    "lng": -80.85709,
    "state_id": "NC"
  },
  {
    "zip": 28662,
    "lat": 36.0215,
    "lng": -81.89909,
    "state_id": "NC"
  },
  {
    "zip": 28663,
    "lat": 36.53905,
    "lng": -81.30477,
    "state_id": "NC"
  },
  {
    "zip": 28665,
    "lat": 36.20971,
    "lng": -81.37519,
    "state_id": "NC"
  },
  {
    "zip": 28666,
    "lat": 35.72506,
    "lng": -81.47052,
    "state_id": "NC"
  },
  {
    "zip": 28667,
    "lat": 35.77216,
    "lng": -81.42794,
    "state_id": "NC"
  },
  {
    "zip": 28668,
    "lat": 36.40737,
    "lng": -80.99129,
    "state_id": "NC"
  },
  {
    "zip": 28669,
    "lat": 36.22862,
    "lng": -80.99496,
    "state_id": "NC"
  },
  {
    "zip": 28670,
    "lat": 36.19367,
    "lng": -80.92346,
    "state_id": "NC"
  },
  {
    "zip": 28671,
    "lat": 35.75273,
    "lng": -81.52787,
    "state_id": "NC"
  },
  {
    "zip": 28672,
    "lat": 36.4833,
    "lng": -81.32758,
    "state_id": "NC"
  },
  {
    "zip": 28673,
    "lat": 35.6076,
    "lng": -80.99923,
    "state_id": "NC"
  },
  {
    "zip": 28675,
    "lat": 36.50348,
    "lng": -81.13528,
    "state_id": "NC"
  },
  {
    "zip": 28676,
    "lat": 36.32907,
    "lng": -80.85746,
    "state_id": "NC"
  },
  {
    "zip": 28677,
    "lat": 35.73728,
    "lng": -80.92343,
    "state_id": "NC"
  },
  {
    "zip": 28678,
    "lat": 35.83465,
    "lng": -81.06028,
    "state_id": "NC"
  },
  {
    "zip": 28679,
    "lat": 36.25934,
    "lng": -81.84268,
    "state_id": "NC"
  },
  {
    "zip": 28681,
    "lat": 35.92094,
    "lng": -81.2221,
    "state_id": "NC"
  },
  {
    "zip": 28682,
    "lat": 35.58088,
    "lng": -80.96422,
    "state_id": "NC"
  },
  {
    "zip": 28683,
    "lat": 36.3892,
    "lng": -80.91181,
    "state_id": "NC"
  },
  {
    "zip": 28684,
    "lat": 36.3384,
    "lng": -81.6146,
    "state_id": "NC"
  },
  {
    "zip": 28685,
    "lat": 36.36079,
    "lng": -81.05984,
    "state_id": "NC"
  },
  {
    "zip": 28689,
    "lat": 36.04317,
    "lng": -80.93531,
    "state_id": "NC"
  },
  {
    "zip": 28690,
    "lat": 35.73296,
    "lng": -81.57401,
    "state_id": "NC"
  },
  {
    "zip": 28692,
    "lat": 36.28003,
    "lng": -81.79529,
    "state_id": "NC"
  },
  {
    "zip": 28693,
    "lat": 36.46954,
    "lng": -81.56252,
    "state_id": "NC"
  },
  {
    "zip": 28694,
    "lat": 36.36256,
    "lng": -81.47354,
    "state_id": "NC"
  },
  {
    "zip": 28697,
    "lat": 36.13612,
    "lng": -81.1651,
    "state_id": "NC"
  },
  {
    "zip": 28698,
    "lat": 36.33938,
    "lng": -81.74011,
    "state_id": "NC"
  },
  {
    "zip": 28701,
    "lat": 35.70239,
    "lng": -82.64105,
    "state_id": "NC"
  },
  {
    "zip": 28702,
    "lat": 35.39966,
    "lng": -83.62051,
    "state_id": "NC"
  },
  {
    "zip": 28704,
    "lat": 35.46135,
    "lng": -82.57753,
    "state_id": "NC"
  },
  {
    "zip": 28705,
    "lat": 36.04365,
    "lng": -82.15888,
    "state_id": "NC"
  },
  {
    "zip": 28707,
    "lat": 35.39743,
    "lng": -83.06586,
    "state_id": "NC"
  },
  {
    "zip": 28708,
    "lat": 35.2714,
    "lng": -82.86267,
    "state_id": "NC"
  },
  {
    "zip": 28709,
    "lat": 35.76386,
    "lng": -82.40612,
    "state_id": "NC"
  },
  {
    "zip": 28711,
    "lat": 35.59867,
    "lng": -82.2902,
    "state_id": "NC"
  },
  {
    "zip": 28712,
    "lat": 35.16965,
    "lng": -82.77103,
    "state_id": "NC"
  },
  {
    "zip": 28713,
    "lat": 35.36935,
    "lng": -83.50254,
    "state_id": "NC"
  },
  {
    "zip": 28714,
    "lat": 35.89376,
    "lng": -82.31145,
    "state_id": "NC"
  },
  {
    "zip": 28715,
    "lat": 35.51246,
    "lng": -82.71225,
    "state_id": "NC"
  },
  {
    "zip": 28716,
    "lat": 35.43589,
    "lng": -82.86109,
    "state_id": "NC"
  },
  {
    "zip": 28717,
    "lat": 35.08257,
    "lng": -83.09114,
    "state_id": "NC"
  },
  {
    "zip": 28718,
    "lat": 35.16548,
    "lng": -82.6287,
    "state_id": "NC"
  },
  {
    "zip": 28719,
    "lat": 35.51785,
    "lng": -83.33418,
    "state_id": "NC"
  },
  {
    "zip": 28720,
    "lat": 35.45103,
    "lng": -82.25356,
    "state_id": "NC"
  },
  {
    "zip": 28721,
    "lat": 35.64801,
    "lng": -82.9511,
    "state_id": "NC"
  },
  {
    "zip": 28722,
    "lat": 35.23413,
    "lng": -82.1247,
    "state_id": "NC"
  },
  {
    "zip": 28723,
    "lat": 35.2806,
    "lng": -83.12186,
    "state_id": "NC"
  },
  {
    "zip": 28725,
    "lat": 35.3727,
    "lng": -83.2603,
    "state_id": "NC"
  },
  {
    "zip": 28726,
    "lat": 35.28118,
    "lng": -82.4182,
    "state_id": "NC"
  },
  {
    "zip": 28729,
    "lat": 35.32007,
    "lng": -82.60234,
    "state_id": "NC"
  },
  {
    "zip": 28730,
    "lat": 35.52465,
    "lng": -82.37036,
    "state_id": "NC"
  },
  {
    "zip": 28731,
    "lat": 35.28894,
    "lng": -82.39353,
    "state_id": "NC"
  },
  {
    "zip": 28732,
    "lat": 35.44586,
    "lng": -82.46666,
    "state_id": "NC"
  },
  {
    "zip": 28733,
    "lat": 35.43677,
    "lng": -83.80762,
    "state_id": "NC"
  },
  {
    "zip": 28734,
    "lat": 35.1828,
    "lng": -83.42108,
    "state_id": "NC"
  },
  {
    "zip": 28735,
    "lat": 35.47574,
    "lng": -82.35043,
    "state_id": "NC"
  },
  {
    "zip": 28736,
    "lat": 35.18605,
    "lng": -83.08139,
    "state_id": "NC"
  },
  {
    "zip": 28739,
    "lat": 35.2636,
    "lng": -82.5464,
    "state_id": "NC"
  },
  {
    "zip": 28740,
    "lat": 36.05004,
    "lng": -82.2842,
    "state_id": "NC"
  },
  {
    "zip": 28741,
    "lat": 35.06147,
    "lng": -83.21069,
    "state_id": "NC"
  },
  {
    "zip": 28742,
    "lat": 35.37582,
    "lng": -82.62471,
    "state_id": "NC"
  },
  {
    "zip": 28743,
    "lat": 35.80819,
    "lng": -82.90049,
    "state_id": "NC"
  },
  {
    "zip": 28745,
    "lat": 35.5259,
    "lng": -82.9704,
    "state_id": "NC"
  },
  {
    "zip": 28746,
    "lat": 35.45947,
    "lng": -82.16648,
    "state_id": "NC"
  },
  {
    "zip": 28747,
    "lat": 35.15718,
    "lng": -82.92327,
    "state_id": "NC"
  },
  {
    "zip": 28748,
    "lat": 35.64957,
    "lng": -82.75774,
    "state_id": "NC"
  },
  {
    "zip": 28749,
    "lat": 35.84058,
    "lng": -82.09877,
    "state_id": "NC"
  },
  {
    "zip": 28751,
    "lat": 35.505,
    "lng": -83.1207,
    "state_id": "NC"
  },
  {
    "zip": 28752,
    "lat": 35.71188,
    "lng": -82.03696,
    "state_id": "NC"
  },
  {
    "zip": 28753,
    "lat": 35.86765,
    "lng": -82.70241,
    "state_id": "NC"
  },
  {
    "zip": 28754,
    "lat": 35.8756,
    "lng": -82.51568,
    "state_id": "NC"
  },
  {
    "zip": 28756,
    "lat": 35.34733,
    "lng": -82.18106,
    "state_id": "NC"
  },
  {
    "zip": 28757,
    "lat": 35.64973,
    "lng": -82.30857,
    "state_id": "NC"
  },
  {
    "zip": 28759,
    "lat": 35.3818,
    "lng": -82.58513,
    "state_id": "NC"
  },
  {
    "zip": 28761,
    "lat": 35.68636,
    "lng": -81.907,
    "state_id": "NC"
  },
  {
    "zip": 28762,
    "lat": 35.63368,
    "lng": -82.19075,
    "state_id": "NC"
  },
  {
    "zip": 28763,
    "lat": 35.03818,
    "lng": -83.43227,
    "state_id": "NC"
  },
  {
    "zip": 28766,
    "lat": 35.25102,
    "lng": -82.61987,
    "state_id": "NC"
  },
  {
    "zip": 28768,
    "lat": 35.30434,
    "lng": -82.70935,
    "state_id": "NC"
  },
  {
    "zip": 28771,
    "lat": 35.34574,
    "lng": -83.8463,
    "state_id": "NC"
  },
  {
    "zip": 28772,
    "lat": 35.11698,
    "lng": -82.82724,
    "state_id": "NC"
  },
  {
    "zip": 28773,
    "lat": 35.25648,
    "lng": -82.31353,
    "state_id": "NC"
  },
  {
    "zip": 28774,
    "lat": 35.09142,
    "lng": -82.99513,
    "state_id": "NC"
  },
  {
    "zip": 28775,
    "lat": 35.0198,
    "lng": -83.32836,
    "state_id": "NC"
  },
  {
    "zip": 28777,
    "lat": 35.90169,
    "lng": -82.06863,
    "state_id": "NC"
  },
  {
    "zip": 28778,
    "lat": 35.61719,
    "lng": -82.40701,
    "state_id": "NC"
  },
  {
    "zip": 28779,
    "lat": 35.35521,
    "lng": -83.21134,
    "state_id": "NC"
  },
  {
    "zip": 28781,
    "lat": 35.21522,
    "lng": -83.64605,
    "state_id": "NC"
  },
  {
    "zip": 28782,
    "lat": 35.23587,
    "lng": -82.15041,
    "state_id": "NC"
  },
  {
    "zip": 28783,
    "lat": 35.25277,
    "lng": -83.02057,
    "state_id": "NC"
  },
  {
    "zip": 28785,
    "lat": 35.63716,
    "lng": -83.09195,
    "state_id": "NC"
  },
  {
    "zip": 28786,
    "lat": 35.46316,
    "lng": -82.99227,
    "state_id": "NC"
  },
  {
    "zip": 28787,
    "lat": 35.72596,
    "lng": -82.52324,
    "state_id": "NC"
  },
  {
    "zip": 28789,
    "lat": 35.40656,
    "lng": -83.31736,
    "state_id": "NC"
  },
  {
    "zip": 28790,
    "lat": 35.20139,
    "lng": -82.48748,
    "state_id": "NC"
  },
  {
    "zip": 28791,
    "lat": 35.35616,
    "lng": -82.50843,
    "state_id": "NC"
  },
  {
    "zip": 28792,
    "lat": 35.38983,
    "lng": -82.36891,
    "state_id": "NC"
  },
  {
    "zip": 28801,
    "lat": 35.59416,
    "lng": -82.55784,
    "state_id": "NC"
  },
  {
    "zip": 28803,
    "lat": 35.52962,
    "lng": -82.52001,
    "state_id": "NC"
  },
  {
    "zip": 28804,
    "lat": 35.64772,
    "lng": -82.56459,
    "state_id": "NC"
  },
  {
    "zip": 28805,
    "lat": 35.61362,
    "lng": -82.48211,
    "state_id": "NC"
  },
  {
    "zip": 28806,
    "lat": 35.57236,
    "lng": -82.6143,
    "state_id": "NC"
  },
  {
    "zip": 28901,
    "lat": 35.19808,
    "lng": -83.81034,
    "state_id": "NC"
  },
  {
    "zip": 28902,
    "lat": 35.02609,
    "lng": -83.95661,
    "state_id": "NC"
  },
  {
    "zip": 28904,
    "lat": 35.06241,
    "lng": -83.72757,
    "state_id": "NC"
  },
  {
    "zip": 28905,
    "lat": 35.16395,
    "lng": -83.94009,
    "state_id": "NC"
  },
  {
    "zip": 28906,
    "lat": 35.11873,
    "lng": -84.13326,
    "state_id": "NC"
  },
  {
    "zip": 28909,
    "lat": 35.00171,
    "lng": -83.90468,
    "state_id": "NC"
  },
  {
    "zip": 58001,
    "lat": 46.44503,
    "lng": -96.72069,
    "state_id": "ND"
  },
  {
    "zip": 58002,
    "lat": 46.97633,
    "lng": -97.40689,
    "state_id": "ND"
  },
  {
    "zip": 58004,
    "lat": 47.02568,
    "lng": -97.26258,
    "state_id": "ND"
  },
  {
    "zip": 58005,
    "lat": 47.05288,
    "lng": -96.95089,
    "state_id": "ND"
  },
  {
    "zip": 58006,
    "lat": 47.10153,
    "lng": -97.20979,
    "state_id": "ND"
  },
  {
    "zip": 58007,
    "lat": 47.02438,
    "lng": -97.46867,
    "state_id": "ND"
  },
  {
    "zip": 58008,
    "lat": 46.30931,
    "lng": -97.00055,
    "state_id": "ND"
  },
  {
    "zip": 58009,
    "lat": 47.32765,
    "lng": -97.24662,
    "state_id": "ND"
  },
  {
    "zip": 58011,
    "lat": 46.91871,
    "lng": -97.54214,
    "state_id": "ND"
  },
  {
    "zip": 58012,
    "lat": 46.93887,
    "lng": -97.16924,
    "state_id": "ND"
  },
  {
    "zip": 58013,
    "lat": 46.06134,
    "lng": -97.36279,
    "state_id": "ND"
  },
  {
    "zip": 58015,
    "lat": 46.59527,
    "lng": -96.81651,
    "state_id": "ND"
  },
  {
    "zip": 58016,
    "lat": 47.36709,
    "lng": -97.46169,
    "state_id": "ND"
  },
  {
    "zip": 58017,
    "lat": 46.0411,
    "lng": -97.84257,
    "state_id": "ND"
  },
  {
    "zip": 58018,
    "lat": 46.436,
    "lng": -96.89646,
    "state_id": "ND"
  },
  {
    "zip": 58021,
    "lat": 46.73761,
    "lng": -97.08518,
    "state_id": "ND"
  },
  {
    "zip": 58027,
    "lat": 46.63342,
    "lng": -97.61152,
    "state_id": "ND"
  },
  {
    "zip": 58029,
    "lat": 47.11674,
    "lng": -97.40006,
    "state_id": "ND"
  },
  {
    "zip": 58030,
    "lat": 46.03005,
    "lng": -96.67008,
    "state_id": "ND"
  },
  {
    "zip": 58031,
    "lat": 46.76302,
    "lng": -97.68077,
    "state_id": "ND"
  },
  {
    "zip": 58032,
    "lat": 46.07633,
    "lng": -97.64174,
    "state_id": "ND"
  },
  {
    "zip": 58033,
    "lat": 46.43678,
    "lng": -97.92796,
    "state_id": "ND"
  },
  {
    "zip": 58035,
    "lat": 47.25455,
    "lng": -97.41995,
    "state_id": "ND"
  },
  {
    "zip": 58036,
    "lat": 47.15557,
    "lng": -96.94807,
    "state_id": "ND"
  },
  {
    "zip": 58038,
    "lat": 47.24508,
    "lng": -96.99583,
    "state_id": "ND"
  },
  {
    "zip": 58040,
    "lat": 46.21453,
    "lng": -97.76732,
    "state_id": "ND"
  },
  {
    "zip": 58041,
    "lat": 46.04969,
    "lng": -96.9145,
    "state_id": "ND"
  },
  {
    "zip": 58042,
    "lat": 46.97095,
    "lng": -96.95486,
    "state_id": "ND"
  },
  {
    "zip": 58043,
    "lat": 45.97677,
    "lng": -97.541,
    "state_id": "ND"
  },
  {
    "zip": 58045,
    "lat": 47.38296,
    "lng": -97.02403,
    "state_id": "ND"
  },
  {
    "zip": 58046,
    "lat": 47.32652,
    "lng": -97.72644,
    "state_id": "ND"
  },
  {
    "zip": 58047,
    "lat": 46.71743,
    "lng": -96.88025,
    "state_id": "ND"
  },
  {
    "zip": 58048,
    "lat": 47.19831,
    "lng": -97.22007,
    "state_id": "ND"
  },
  {
    "zip": 58049,
    "lat": 46.65532,
    "lng": -97.99855,
    "state_id": "ND"
  },
  {
    "zip": 58051,
    "lat": 46.6414,
    "lng": -97.03054,
    "state_id": "ND"
  },
  {
    "zip": 58052,
    "lat": 46.63502,
    "lng": -97.27877,
    "state_id": "ND"
  },
  {
    "zip": 58053,
    "lat": 46.06393,
    "lng": -97.17997,
    "state_id": "ND"
  },
  {
    "zip": 58054,
    "lat": 46.42531,
    "lng": -97.66952,
    "state_id": "ND"
  },
  {
    "zip": 58056,
    "lat": 47.23187,
    "lng": -97.93131,
    "state_id": "ND"
  },
  {
    "zip": 58057,
    "lat": 46.42444,
    "lng": -97.25723,
    "state_id": "ND"
  },
  {
    "zip": 58058,
    "lat": 46.18256,
    "lng": -96.96339,
    "state_id": "ND"
  },
  {
    "zip": 58059,
    "lat": 46.84414,
    "lng": -97.11661,
    "state_id": "ND"
  },
  {
    "zip": 58060,
    "lat": 46.26494,
    "lng": -97.43421,
    "state_id": "ND"
  },
  {
    "zip": 58061,
    "lat": 46.27908,
    "lng": -96.89756,
    "state_id": "ND"
  },
  {
    "zip": 58062,
    "lat": 46.63602,
    "lng": -97.8284,
    "state_id": "ND"
  },
  {
    "zip": 58063,
    "lat": 46.96547,
    "lng": -97.80407,
    "state_id": "ND"
  },
  {
    "zip": 58064,
    "lat": 47.15065,
    "lng": -97.61585,
    "state_id": "ND"
  },
  {
    "zip": 58065,
    "lat": 47.18618,
    "lng": -97.76043,
    "state_id": "ND"
  },
  {
    "zip": 58067,
    "lat": 46.06474,
    "lng": -97.48782,
    "state_id": "ND"
  },
  {
    "zip": 58068,
    "lat": 46.54299,
    "lng": -97.40464,
    "state_id": "ND"
  },
  {
    "zip": 58069,
    "lat": 46.25185,
    "lng": -97.83256,
    "state_id": "ND"
  },
  {
    "zip": 58071,
    "lat": 46.95518,
    "lng": -97.67832,
    "state_id": "ND"
  },
  {
    "zip": 58072,
    "lat": 46.92569,
    "lng": -98.00688,
    "state_id": "ND"
  },
  {
    "zip": 58075,
    "lat": 46.27439,
    "lng": -96.72615,
    "state_id": "ND"
  },
  {
    "zip": 58076,
    "lat": 46.27285,
    "lng": -96.60753,
    "state_id": "ND"
  },
  {
    "zip": 58077,
    "lat": 46.52482,
    "lng": -96.97509,
    "state_id": "ND"
  },
  {
    "zip": 58078,
    "lat": 46.86303,
    "lng": -96.93126,
    "state_id": "ND"
  },
  {
    "zip": 58079,
    "lat": 46.86671,
    "lng": -97.34533,
    "state_id": "ND"
  },
  {
    "zip": 58081,
    "lat": 46.31072,
    "lng": -97.14137,
    "state_id": "ND"
  },
  {
    "zip": 58102,
    "lat": 46.9209,
    "lng": -96.83183,
    "state_id": "ND"
  },
  {
    "zip": 58103,
    "lat": 46.85617,
    "lng": -96.82207,
    "state_id": "ND"
  },
  {
    "zip": 58104,
    "lat": 46.79325,
    "lng": -96.83971,
    "state_id": "ND"
  },
  {
    "zip": 58105,
    "lat": 46.89543,
    "lng": -96.80774,
    "state_id": "ND"
  },
  {
    "zip": 58201,
    "lat": 47.87121,
    "lng": -97.14106,
    "state_id": "ND"
  },
  {
    "zip": 58202,
    "lat": 47.92151,
    "lng": -97.07356,
    "state_id": "ND"
  },
  {
    "zip": 58203,
    "lat": 47.97217,
    "lng": -97.15496,
    "state_id": "ND"
  },
  {
    "zip": 58204,
    "lat": 47.95497,
    "lng": -97.38681,
    "state_id": "ND"
  },
  {
    "zip": 58205,
    "lat": 47.95147,
    "lng": -97.37898,
    "state_id": "ND"
  },
  {
    "zip": 58210,
    "lat": 48.40897,
    "lng": -98.10711,
    "state_id": "ND"
  },
  {
    "zip": 58212,
    "lat": 47.70512,
    "lng": -97.99942,
    "state_id": "ND"
  },
  {
    "zip": 58214,
    "lat": 47.92461,
    "lng": -97.49967,
    "state_id": "ND"
  },
  {
    "zip": 58216,
    "lat": 48.88188,
    "lng": -97.43451,
    "state_id": "ND"
  },
  {
    "zip": 58218,
    "lat": 47.59477,
    "lng": -97.04589,
    "state_id": "ND"
  },
  {
    "zip": 58219,
    "lat": 47.47763,
    "lng": -96.87908,
    "state_id": "ND"
  },
  {
    "zip": 58220,
    "lat": 48.79354,
    "lng": -97.71425,
    "state_id": "ND"
  },
  {
    "zip": 58222,
    "lat": 48.61521,
    "lng": -97.68156,
    "state_id": "ND"
  },
  {
    "zip": 58223,
    "lat": 47.50869,
    "lng": -97.03444,
    "state_id": "ND"
  },
  {
    "zip": 58224,
    "lat": 48.17233,
    "lng": -97.94363,
    "state_id": "ND"
  },
  {
    "zip": 58225,
    "lat": 48.61036,
    "lng": -97.21566,
    "state_id": "ND"
  },
  {
    "zip": 58227,
    "lat": 48.54507,
    "lng": -97.90323,
    "state_id": "ND"
  },
  {
    "zip": 58228,
    "lat": 47.87866,
    "lng": -97.35236,
    "state_id": "ND"
  },
  {
    "zip": 58229,
    "lat": 48.49867,
    "lng": -98.24331,
    "state_id": "ND"
  },
  {
    "zip": 58230,
    "lat": 47.48413,
    "lng": -97.83358,
    "state_id": "ND"
  },
  {
    "zip": 58231,
    "lat": 48.20015,
    "lng": -97.82625,
    "state_id": "ND"
  },
  {
    "zip": 58233,
    "lat": 48.22616,
    "lng": -97.52578,
    "state_id": "ND"
  },
  {
    "zip": 58235,
    "lat": 48.09901,
    "lng": -97.47478,
    "state_id": "ND"
  },
  {
    "zip": 58237,
    "lat": 48.41406,
    "lng": -97.40542,
    "state_id": "ND"
  },
  {
    "zip": 58238,
    "lat": 48.78088,
    "lng": -97.39878,
    "state_id": "ND"
  },
  {
    "zip": 58239,
    "lat": 48.94042,
    "lng": -98.70665,
    "state_id": "ND"
  },
  {
    "zip": 58240,
    "lat": 47.63421,
    "lng": -97.48282,
    "state_id": "ND"
  },
  {
    "zip": 58241,
    "lat": 48.69843,
    "lng": -97.67743,
    "state_id": "ND"
  },
  {
    "zip": 58243,
    "lat": 48.51272,
    "lng": -97.68092,
    "state_id": "ND"
  },
  {
    "zip": 58244,
    "lat": 48.14307,
    "lng": -97.61472,
    "state_id": "ND"
  },
  {
    "zip": 58249,
    "lat": 48.82348,
    "lng": -98.32857,
    "state_id": "ND"
  },
  {
    "zip": 58250,
    "lat": 48.27871,
    "lng": -98.0078,
    "state_id": "ND"
  },
  {
    "zip": 58251,
    "lat": 47.94938,
    "lng": -97.69121,
    "state_id": "ND"
  },
  {
    "zip": 58254,
    "lat": 47.77265,
    "lng": -98.14239,
    "state_id": "ND"
  },
  {
    "zip": 58256,
    "lat": 48.0905,
    "lng": -97.20738,
    "state_id": "ND"
  },
  {
    "zip": 58257,
    "lat": 47.49739,
    "lng": -97.28184,
    "state_id": "ND"
  },
  {
    "zip": 58258,
    "lat": 48.01992,
    "lng": -97.34087,
    "state_id": "ND"
  },
  {
    "zip": 58259,
    "lat": 48.0237,
    "lng": -98.1244,
    "state_id": "ND"
  },
  {
    "zip": 58260,
    "lat": 48.63512,
    "lng": -98.03472,
    "state_id": "ND"
  },
  {
    "zip": 58261,
    "lat": 48.23978,
    "lng": -97.31189,
    "state_id": "ND"
  },
  {
    "zip": 58262,
    "lat": 48.68223,
    "lng": -97.87632,
    "state_id": "ND"
  },
  {
    "zip": 58265,
    "lat": 48.95104,
    "lng": -97.60765,
    "state_id": "ND"
  },
  {
    "zip": 58266,
    "lat": 48.00321,
    "lng": -97.8486,
    "state_id": "ND"
  },
  {
    "zip": 58267,
    "lat": 47.7539,
    "lng": -97.63507,
    "state_id": "ND"
  },
  {
    "zip": 58269,
    "lat": 48.6932,
    "lng": -98.16746,
    "state_id": "ND"
  },
  {
    "zip": 58270,
    "lat": 48.39438,
    "lng": -97.7837,
    "state_id": "ND"
  },
  {
    "zip": 58271,
    "lat": 48.91108,
    "lng": -97.29409,
    "state_id": "ND"
  },
  {
    "zip": 58272,
    "lat": 48.01703,
    "lng": -97.98127,
    "state_id": "ND"
  },
  {
    "zip": 58273,
    "lat": 48.29608,
    "lng": -97.66853,
    "state_id": "ND"
  },
  {
    "zip": 58274,
    "lat": 47.49304,
    "lng": -97.48753,
    "state_id": "ND"
  },
  {
    "zip": 58275,
    "lat": 47.68513,
    "lng": -97.11637,
    "state_id": "ND"
  },
  {
    "zip": 58276,
    "lat": 48.63081,
    "lng": -97.45133,
    "state_id": "ND"
  },
  {
    "zip": 58277,
    "lat": 47.60892,
    "lng": -97.83077,
    "state_id": "ND"
  },
  {
    "zip": 58278,
    "lat": 47.77313,
    "lng": -97.12866,
    "state_id": "ND"
  },
  {
    "zip": 58281,
    "lat": 48.85821,
    "lng": -98.61018,
    "state_id": "ND"
  },
  {
    "zip": 58282,
    "lat": 48.90459,
    "lng": -97.94874,
    "state_id": "ND"
  },
  {
    "zip": 58301,
    "lat": 48.13148,
    "lng": -98.87381,
    "state_id": "ND"
  },
  {
    "zip": 58311,
    "lat": 48.65243,
    "lng": -98.57949,
    "state_id": "ND"
  },
  {
    "zip": 58316,
    "lat": 48.82808,
    "lng": -99.77376,
    "state_id": "ND"
  },
  {
    "zip": 58317,
    "lat": 48.61861,
    "lng": -99.38178,
    "state_id": "ND"
  },
  {
    "zip": 58318,
    "lat": 48.85463,
    "lng": -100.41887,
    "state_id": "ND"
  },
  {
    "zip": 58321,
    "lat": 48.20004,
    "lng": -98.33809,
    "state_id": "ND"
  },
  {
    "zip": 58323,
    "lat": 48.85099,
    "lng": -98.88076,
    "state_id": "ND"
  },
  {
    "zip": 58324,
    "lat": 48.48025,
    "lng": -99.22159,
    "state_id": "ND"
  },
  {
    "zip": 58325,
    "lat": 48.28448,
    "lng": -99.15467,
    "state_id": "ND"
  },
  {
    "zip": 58327,
    "lat": 48.09378,
    "lng": -98.58805,
    "state_id": "ND"
  },
  {
    "zip": 58329,
    "lat": 48.87323,
    "lng": -100.08087,
    "state_id": "ND"
  },
  {
    "zip": 58330,
    "lat": 48.4467,
    "lng": -98.46952,
    "state_id": "ND"
  },
  {
    "zip": 58331,
    "lat": 48.63737,
    "lng": -99.09431,
    "state_id": "ND"
  },
  {
    "zip": 58332,
    "lat": 48.04184,
    "lng": -99.76173,
    "state_id": "ND"
  },
  {
    "zip": 58335,
    "lat": 47.98109,
    "lng": -99.02636,
    "state_id": "ND"
  },
  {
    "zip": 58338,
    "lat": 48.5344,
    "lng": -98.62028,
    "state_id": "ND"
  },
  {
    "zip": 58339,
    "lat": 48.89582,
    "lng": -99.44561,
    "state_id": "ND"
  },
  {
    "zip": 58341,
    "lat": 47.77527,
    "lng": -99.85003,
    "state_id": "ND"
  },
  {
    "zip": 58343,
    "lat": 48.29739,
    "lng": -99.72223,
    "state_id": "ND"
  },
  {
    "zip": 58344,
    "lat": 48.02425,
    "lng": -98.34367,
    "state_id": "ND"
  },
  {
    "zip": 58345,
    "lat": 48.30539,
    "lng": -98.36947,
    "state_id": "ND"
  },
  {
    "zip": 58346,
    "lat": 48.28751,
    "lng": -99.42857,
    "state_id": "ND"
  },
  {
    "zip": 58348,
    "lat": 47.96681,
    "lng": -99.52234,
    "state_id": "ND"
  },
  {
    "zip": 58351,
    "lat": 48.09867,
    "lng": -99.27912,
    "state_id": "ND"
  },
  {
    "zip": 58352,
    "lat": 48.70751,
    "lng": -98.86932,
    "state_id": "ND"
  },
  {
    "zip": 58353,
    "lat": 48.63289,
    "lng": -99.59572,
    "state_id": "ND"
  },
  {
    "zip": 58355,
    "lat": 48.59091,
    "lng": -98.36628,
    "state_id": "ND"
  },
  {
    "zip": 58356,
    "lat": 47.6733,
    "lng": -99.07125,
    "state_id": "ND"
  },
  {
    "zip": 58357,
    "lat": 47.94117,
    "lng": -99.23268,
    "state_id": "ND"
  },
  {
    "zip": 58361,
    "lat": 47.75733,
    "lng": -98.34619,
    "state_id": "ND"
  },
  {
    "zip": 58362,
    "lat": 48.21439,
    "lng": -99.08929,
    "state_id": "ND"
  },
  {
    "zip": 58363,
    "lat": 48.75801,
    "lng": -99.45141,
    "state_id": "ND"
  },
  {
    "zip": 58365,
    "lat": 48.85826,
    "lng": -99.2521,
    "state_id": "ND"
  },
  {
    "zip": 58366,
    "lat": 48.6587,
    "lng": -99.8712,
    "state_id": "ND"
  },
  {
    "zip": 58367,
    "lat": 48.86927,
    "lng": -99.58666,
    "state_id": "ND"
  },
  {
    "zip": 58368,
    "lat": 48.25641,
    "lng": -100.04448,
    "state_id": "ND"
  },
  {
    "zip": 58369,
    "lat": 48.94814,
    "lng": -99.79911,
    "state_id": "ND"
  },
  {
    "zip": 58370,
    "lat": 47.98314,
    "lng": -98.84401,
    "state_id": "ND"
  },
  {
    "zip": 58372,
    "lat": 48.94534,
    "lng": -98.99627,
    "state_id": "ND"
  },
  {
    "zip": 58374,
    "lat": 47.83247,
    "lng": -99.09495,
    "state_id": "ND"
  },
  {
    "zip": 58377,
    "lat": 48.45843,
    "lng": -98.86283,
    "state_id": "ND"
  },
  {
    "zip": 58379,
    "lat": 47.90599,
    "lng": -98.84504,
    "state_id": "ND"
  },
  {
    "zip": 58380,
    "lat": 47.83163,
    "lng": -98.51521,
    "state_id": "ND"
  },
  {
    "zip": 58381,
    "lat": 47.83267,
    "lng": -98.74062,
    "state_id": "ND"
  },
  {
    "zip": 58382,
    "lat": 48.31164,
    "lng": -98.78499,
    "state_id": "ND"
  },
  {
    "zip": 58384,
    "lat": 48.60286,
    "lng": -100.25675,
    "state_id": "ND"
  },
  {
    "zip": 58385,
    "lat": 48.47849,
    "lng": -99.66357,
    "state_id": "ND"
  },
  {
    "zip": 58386,
    "lat": 48.30899,
    "lng": -99.59652,
    "state_id": "ND"
  },
  {
    "zip": 58401,
    "lat": 46.8825,
    "lng": -98.76392,
    "state_id": "ND"
  },
  {
    "zip": 58402,
    "lat": 46.88035,
    "lng": -98.68714,
    "state_id": "ND"
  },
  {
    "zip": 58405,
    "lat": 46.91548,
    "lng": -98.69955,
    "state_id": "ND"
  },
  {
    "zip": 58413,
    "lat": 46.0347,
    "lng": -99.30717,
    "state_id": "ND"
  },
  {
    "zip": 58415,
    "lat": 46.37119,
    "lng": -98.49156,
    "state_id": "ND"
  },
  {
    "zip": 58416,
    "lat": 47.54152,
    "lng": -98.35019,
    "state_id": "ND"
  },
  {
    "zip": 58418,
    "lat": 47.42096,
    "lng": -99.64577,
    "state_id": "ND"
  },
  {
    "zip": 58420,
    "lat": 47.06133,
    "lng": -98.86783,
    "state_id": "ND"
  },
  {
    "zip": 58421,
    "lat": 47.44199,
    "lng": -99.06885,
    "state_id": "ND"
  },
  {
    "zip": 58422,
    "lat": 47.59801,
    "lng": -99.40331,
    "state_id": "ND"
  },
  {
    "zip": 58423,
    "lat": 47.41106,
    "lng": -99.82589,
    "state_id": "ND"
  },
  {
    "zip": 58424,
    "lat": 46.88852,
    "lng": -99.11094,
    "state_id": "ND"
  },
  {
    "zip": 58425,
    "lat": 47.45614,
    "lng": -98.1243,
    "state_id": "ND"
  },
  {
    "zip": 58426,
    "lat": 47.21919,
    "lng": -98.58679,
    "state_id": "ND"
  },
  {
    "zip": 58428,
    "lat": 46.84846,
    "lng": -99.7515,
    "state_id": "ND"
  },
  {
    "zip": 58429,
    "lat": 47.18485,
    "lng": -98.16373,
    "state_id": "ND"
  },
  {
    "zip": 58430,
    "lat": 47.51464,
    "lng": -100.2612,
    "state_id": "ND"
  },
  {
    "zip": 58431,
    "lat": 46.50768,
    "lng": -98.48494,
    "state_id": "ND"
  },
  {
    "zip": 58433,
    "lat": 46.37033,
    "lng": -98.71301,
    "state_id": "ND"
  },
  {
    "zip": 58436,
    "lat": 46.08765,
    "lng": -98.57873,
    "state_id": "ND"
  },
  {
    "zip": 58438,
    "lat": 47.66349,
    "lng": -99.59638,
    "state_id": "ND"
  },
  {
    "zip": 58439,
    "lat": 45.9824,
    "lng": -98.83447,
    "state_id": "ND"
  },
  {
    "zip": 58440,
    "lat": 46.26931,
    "lng": -99.16721,
    "state_id": "ND"
  },
  {
    "zip": 58441,
    "lat": 46.17639,
    "lng": -98.37127,
    "state_id": "ND"
  },
  {
    "zip": 58442,
    "lat": 46.56469,
    "lng": -99.17914,
    "state_id": "ND"
  },
  {
    "zip": 58443,
    "lat": 47.45539,
    "lng": -98.67201,
    "state_id": "ND"
  },
  {
    "zip": 58444,
    "lat": 47.49942,
    "lng": -100.12971,
    "state_id": "ND"
  },
  {
    "zip": 58445,
    "lat": 47.56401,
    "lng": -98.80248,
    "state_id": "ND"
  },
  {
    "zip": 58448,
    "lat": 47.31125,
    "lng": -98.22024,
    "state_id": "ND"
  },
  {
    "zip": 58451,
    "lat": 47.45155,
    "lng": -99.95301,
    "state_id": "ND"
  },
  {
    "zip": 58452,
    "lat": 47.54776,
    "lng": -98.23123,
    "state_id": "ND"
  },
  {
    "zip": 58454,
    "lat": 46.59652,
    "lng": -98.93542,
    "state_id": "ND"
  },
  {
    "zip": 58455,
    "lat": 47.30172,
    "lng": -98.74302,
    "state_id": "ND"
  },
  {
    "zip": 58456,
    "lat": 46.26552,
    "lng": -98.946,
    "state_id": "ND"
  },
  {
    "zip": 58458,
    "lat": 46.36956,
    "lng": -98.29869,
    "state_id": "ND"
  },
  {
    "zip": 58460,
    "lat": 46.30802,
    "lng": -99.33677,
    "state_id": "ND"
  },
  {
    "zip": 58461,
    "lat": 46.65292,
    "lng": -98.19499,
    "state_id": "ND"
  },
  {
    "zip": 58463,
    "lat": 47.49072,
    "lng": -100.47234,
    "state_id": "ND"
  },
  {
    "zip": 58464,
    "lat": 47.60518,
    "lng": -98.58105,
    "state_id": "ND"
  },
  {
    "zip": 58466,
    "lat": 46.61786,
    "lng": -98.36748,
    "state_id": "ND"
  },
  {
    "zip": 58467,
    "lat": 46.88114,
    "lng": -99.33368,
    "state_id": "ND"
  },
  {
    "zip": 58472,
    "lat": 46.64266,
    "lng": -98.66533,
    "state_id": "ND"
  },
  {
    "zip": 58474,
    "lat": 46.09559,
    "lng": -98.13353,
    "state_id": "ND"
  },
  {
    "zip": 58475,
    "lat": 47.16547,
    "lng": -99.53946,
    "state_id": "ND"
  },
  {
    "zip": 58476,
    "lat": 47.19769,
    "lng": -99.01677,
    "state_id": "ND"
  },
  {
    "zip": 58477,
    "lat": 47.21577,
    "lng": -100.50904,
    "state_id": "ND"
  },
  {
    "zip": 58478,
    "lat": 47.17486,
    "lng": -99.74566,
    "state_id": "ND"
  },
  {
    "zip": 58479,
    "lat": 47.0953,
    "lng": -98.18466,
    "state_id": "ND"
  },
  {
    "zip": 58480,
    "lat": 46.95327,
    "lng": -98.26049,
    "state_id": "ND"
  },
  {
    "zip": 58481,
    "lat": 46.93133,
    "lng": -98.42113,
    "state_id": "ND"
  },
  {
    "zip": 58482,
    "lat": 46.85515,
    "lng": -99.89895,
    "state_id": "ND"
  },
  {
    "zip": 58483,
    "lat": 46.65252,
    "lng": -99.40808,
    "state_id": "ND"
  },
  {
    "zip": 58484,
    "lat": 47.37511,
    "lng": -98.47812,
    "state_id": "ND"
  },
  {
    "zip": 58486,
    "lat": 47.39046,
    "lng": -99.39125,
    "state_id": "ND"
  },
  {
    "zip": 58487,
    "lat": 46.85757,
    "lng": -99.59723,
    "state_id": "ND"
  },
  {
    "zip": 58488,
    "lat": 47.17816,
    "lng": -99.99624,
    "state_id": "ND"
  },
  {
    "zip": 58490,
    "lat": 46.37966,
    "lng": -98.05824,
    "state_id": "ND"
  },
  {
    "zip": 58492,
    "lat": 47.15447,
    "lng": -98.43797,
    "state_id": "ND"
  },
  {
    "zip": 58494,
    "lat": 47.15652,
    "lng": -100.28022,
    "state_id": "ND"
  },
  {
    "zip": 58495,
    "lat": 46.27701,
    "lng": -99.60082,
    "state_id": "ND"
  },
  {
    "zip": 58496,
    "lat": 47.14742,
    "lng": -99.3291,
    "state_id": "ND"
  },
  {
    "zip": 58497,
    "lat": 46.76718,
    "lng": -98.54587,
    "state_id": "ND"
  },
  {
    "zip": 58501,
    "lat": 46.81868,
    "lng": -100.70201,
    "state_id": "ND"
  },
  {
    "zip": 58503,
    "lat": 46.90432,
    "lng": -100.75055,
    "state_id": "ND"
  },
  {
    "zip": 58504,
    "lat": 46.72307,
    "lng": -100.6779,
    "state_id": "ND"
  },
  {
    "zip": 58505,
    "lat": 46.8202,
    "lng": -100.78111,
    "state_id": "ND"
  },
  {
    "zip": 58520,
    "lat": 46.68174,
    "lng": -101.54687,
    "state_id": "ND"
  },
  {
    "zip": 58521,
    "lat": 47.02467,
    "lng": -100.69204,
    "state_id": "ND"
  },
  {
    "zip": 58523,
    "lat": 47.25857,
    "lng": -101.77838,
    "state_id": "ND"
  },
  {
    "zip": 58524,
    "lat": 46.59187,
    "lng": -100.0808,
    "state_id": "ND"
  },
  {
    "zip": 58528,
    "lat": 46.33616,
    "lng": -100.63638,
    "state_id": "ND"
  },
  {
    "zip": 58529,
    "lat": 46.29985,
    "lng": -101.55514,
    "state_id": "ND"
  },
  {
    "zip": 58530,
    "lat": 47.12919,
    "lng": -101.15012,
    "state_id": "ND"
  },
  {
    "zip": 58531,
    "lat": 47.57372,
    "lng": -101.19518,
    "state_id": "ND"
  },
  {
    "zip": 58532,
    "lat": 46.85214,
    "lng": -100.10998,
    "state_id": "ND"
  },
  {
    "zip": 58533,
    "lat": 46.40191,
    "lng": -101.79675,
    "state_id": "ND"
  },
  {
    "zip": 58535,
    "lat": 46.49992,
    "lng": -101.24527,
    "state_id": "ND"
  },
  {
    "zip": 58538,
    "lat": 46.11235,
    "lng": -100.6928,
    "state_id": "ND"
  },
  {
    "zip": 58540,
    "lat": 47.62988,
    "lng": -101.71675,
    "state_id": "ND"
  },
  {
    "zip": 58541,
    "lat": 47.36516,
    "lng": -102.06548,
    "state_id": "ND"
  },
  {
    "zip": 58542,
    "lat": 46.05021,
    "lng": -100.01432,
    "state_id": "ND"
  },
  {
    "zip": 58544,
    "lat": 46.50851,
    "lng": -100.36917,
    "state_id": "ND"
  },
  {
    "zip": 58545,
    "lat": 47.35584,
    "lng": -101.58061,
    "state_id": "ND"
  },
  {
    "zip": 58549,
    "lat": 46.47464,
    "lng": -99.93629,
    "state_id": "ND"
  },
  {
    "zip": 58552,
    "lat": 46.23896,
    "lng": -100.30105,
    "state_id": "ND"
  },
  {
    "zip": 58554,
    "lat": 46.73801,
    "lng": -100.94022,
    "state_id": "ND"
  },
  {
    "zip": 58558,
    "lat": 46.82637,
    "lng": -100.51066,
    "state_id": "ND"
  },
  {
    "zip": 58559,
    "lat": 47.48816,
    "lng": -100.72767,
    "state_id": "ND"
  },
  {
    "zip": 58560,
    "lat": 46.67951,
    "lng": -100.25937,
    "state_id": "ND"
  },
  {
    "zip": 58561,
    "lat": 46.48704,
    "lng": -99.68333,
    "state_id": "ND"
  },
  {
    "zip": 58562,
    "lat": 46.37488,
    "lng": -102.00451,
    "state_id": "ND"
  },
  {
    "zip": 58563,
    "lat": 46.90329,
    "lng": -101.43495,
    "state_id": "ND"
  },
  {
    "zip": 58564,
    "lat": 46.26614,
    "lng": -101.35223,
    "state_id": "ND"
  },
  {
    "zip": 58565,
    "lat": 47.48677,
    "lng": -101.38317,
    "state_id": "ND"
  },
  {
    "zip": 58566,
    "lat": 46.58322,
    "lng": -100.92538,
    "state_id": "ND"
  },
  {
    "zip": 58568,
    "lat": 46.08787,
    "lng": -101.04722,
    "state_id": "ND"
  },
  {
    "zip": 58569,
    "lat": 46.26226,
    "lng": -101.19051,
    "state_id": "ND"
  },
  {
    "zip": 58570,
    "lat": 46.38367,
    "lng": -100.88831,
    "state_id": "ND"
  },
  {
    "zip": 58571,
    "lat": 47.27584,
    "lng": -101.40094,
    "state_id": "ND"
  },
  {
    "zip": 58572,
    "lat": 46.8564,
    "lng": -100.33648,
    "state_id": "ND"
  },
  {
    "zip": 58573,
    "lat": 46.10009,
    "lng": -100.20329,
    "state_id": "ND"
  },
  {
    "zip": 58575,
    "lat": 47.56336,
    "lng": -100.90178,
    "state_id": "ND"
  },
  {
    "zip": 58576,
    "lat": 47.43867,
    "lng": -101.2165,
    "state_id": "ND"
  },
  {
    "zip": 58577,
    "lat": 47.32196,
    "lng": -101.05551,
    "state_id": "ND"
  },
  {
    "zip": 58579,
    "lat": 47.18206,
    "lng": -100.74,
    "state_id": "ND"
  },
  {
    "zip": 58580,
    "lat": 47.25974,
    "lng": -101.93177,
    "state_id": "ND"
  },
  {
    "zip": 58581,
    "lat": 46.03994,
    "lng": -99.78126,
    "state_id": "ND"
  },
  {
    "zip": 58601,
    "lat": 46.91588,
    "lng": -102.83434,
    "state_id": "ND"
  },
  {
    "zip": 58620,
    "lat": 46.50431,
    "lng": -103.33089,
    "state_id": "ND"
  },
  {
    "zip": 58621,
    "lat": 47.08318,
    "lng": -103.92808,
    "state_id": "ND"
  },
  {
    "zip": 58622,
    "lat": 46.89613,
    "lng": -103.27062,
    "state_id": "ND"
  },
  {
    "zip": 58623,
    "lat": 46.18901,
    "lng": -103.42567,
    "state_id": "ND"
  },
  {
    "zip": 58625,
    "lat": 47.26784,
    "lng": -102.19435,
    "state_id": "ND"
  },
  {
    "zip": 58626,
    "lat": 47.38146,
    "lng": -102.62034,
    "state_id": "ND"
  },
  {
    "zip": 58627,
    "lat": 47.23294,
    "lng": -103.30559,
    "state_id": "ND"
  },
  {
    "zip": 58630,
    "lat": 46.94463,
    "lng": -102.5792,
    "state_id": "ND"
  },
  {
    "zip": 58631,
    "lat": 46.79977,
    "lng": -101.81892,
    "state_id": "ND"
  },
  {
    "zip": 58632,
    "lat": 46.6689,
    "lng": -103.95637,
    "state_id": "ND"
  },
  {
    "zip": 58634,
    "lat": 47.4342,
    "lng": -103.34243,
    "state_id": "ND"
  },
  {
    "zip": 58636,
    "lat": 47.38629,
    "lng": -102.36036,
    "state_id": "ND"
  },
  {
    "zip": 58638,
    "lat": 46.94037,
    "lng": -102.04409,
    "state_id": "ND"
  },
  {
    "zip": 58639,
    "lat": 46.09177,
    "lng": -102.61406,
    "state_id": "ND"
  },
  {
    "zip": 58640,
    "lat": 47.45234,
    "lng": -102.90254,
    "state_id": "ND"
  },
  {
    "zip": 58641,
    "lat": 46.66845,
    "lng": -102.49863,
    "state_id": "ND"
  },
  {
    "zip": 58642,
    "lat": 47.20868,
    "lng": -102.84273,
    "state_id": "ND"
  },
  {
    "zip": 58643,
    "lat": 46.32971,
    "lng": -103.94448,
    "state_id": "ND"
  },
  {
    "zip": 58645,
    "lat": 46.93876,
    "lng": -103.56314,
    "state_id": "ND"
  },
  {
    "zip": 58646,
    "lat": 46.39388,
    "lng": -102.28253,
    "state_id": "ND"
  },
  {
    "zip": 58647,
    "lat": 46.52223,
    "lng": -102.93452,
    "state_id": "ND"
  },
  {
    "zip": 58649,
    "lat": 46.12098,
    "lng": -102.9136,
    "state_id": "ND"
  },
  {
    "zip": 58650,
    "lat": 46.43682,
    "lng": -102.58754,
    "state_id": "ND"
  },
  {
    "zip": 58651,
    "lat": 46.25558,
    "lng": -103.73525,
    "state_id": "ND"
  },
  {
    "zip": 58652,
    "lat": 46.86877,
    "lng": -102.26784,
    "state_id": "ND"
  },
  {
    "zip": 58653,
    "lat": 46.09162,
    "lng": -103.13542,
    "state_id": "ND"
  },
  {
    "zip": 58654,
    "lat": 46.88083,
    "lng": -103.77792,
    "state_id": "ND"
  },
  {
    "zip": 58655,
    "lat": 46.77518,
    "lng": -103.03399,
    "state_id": "ND"
  },
  {
    "zip": 58656,
    "lat": 46.94402,
    "lng": -102.4583,
    "state_id": "ND"
  },
  {
    "zip": 58701,
    "lat": 48.14259,
    "lng": -101.32131,
    "state_id": "ND"
  },
  {
    "zip": 58702,
    "lat": 48.23214,
    "lng": -101.29379,
    "state_id": "ND"
  },
  {
    "zip": 58703,
    "lat": 48.33057,
    "lng": -101.31556,
    "state_id": "ND"
  },
  {
    "zip": 58704,
    "lat": 48.42515,
    "lng": -101.32098,
    "state_id": "ND"
  },
  {
    "zip": 58705,
    "lat": 48.42237,
    "lng": -101.33389,
    "state_id": "ND"
  },
  {
    "zip": 58707,
    "lat": 48.24621,
    "lng": -101.30075,
    "state_id": "ND"
  },
  {
    "zip": 58710,
    "lat": 47.87229,
    "lng": -100.22952,
    "state_id": "ND"
  },
  {
    "zip": 58711,
    "lat": 48.93075,
    "lng": -101.28461,
    "state_id": "ND"
  },
  {
    "zip": 58712,
    "lat": 47.97636,
    "lng": -100.53095,
    "state_id": "ND"
  },
  {
    "zip": 58713,
    "lat": 48.53502,
    "lng": -100.59538,
    "state_id": "ND"
  },
  {
    "zip": 58716,
    "lat": 47.81791,
    "lng": -101.07158,
    "state_id": "ND"
  },
  {
    "zip": 58718,
    "lat": 48.3124,
    "lng": -101.8106,
    "state_id": "ND"
  },
  {
    "zip": 58721,
    "lat": 48.80587,
    "lng": -102.28087,
    "state_id": "ND"
  },
  {
    "zip": 58722,
    "lat": 48.23734,
    "lng": -101.49498,
    "state_id": "ND"
  },
  {
    "zip": 58723,
    "lat": 47.75863,
    "lng": -100.60481,
    "state_id": "ND"
  },
  {
    "zip": 58725,
    "lat": 48.49193,
    "lng": -101.68351,
    "state_id": "ND"
  },
  {
    "zip": 58727,
    "lat": 48.84349,
    "lng": -102.798,
    "state_id": "ND"
  },
  {
    "zip": 58730,
    "lat": 48.86469,
    "lng": -103.31427,
    "state_id": "ND"
  },
  {
    "zip": 58731,
    "lat": 48.43968,
    "lng": -100.97982,
    "state_id": "ND"
  },
  {
    "zip": 58733,
    "lat": 48.17475,
    "lng": -101.587,
    "state_id": "ND"
  },
  {
    "zip": 58734,
    "lat": 48.51538,
    "lng": -101.94471,
    "state_id": "ND"
  },
  {
    "zip": 58735,
    "lat": 47.87377,
    "lng": -101.48232,
    "state_id": "ND"
  },
  {
    "zip": 58736,
    "lat": 47.93381,
    "lng": -100.3819,
    "state_id": "ND"
  },
  {
    "zip": 58737,
    "lat": 48.92147,
    "lng": -102.39152,
    "state_id": "ND"
  },
  {
    "zip": 58740,
    "lat": 48.47179,
    "lng": -101.22971,
    "state_id": "ND"
  },
  {
    "zip": 58741,
    "lat": 48.2775,
    "lng": -100.82448,
    "state_id": "ND"
  },
  {
    "zip": 58744,
    "lat": 48.11917,
    "lng": -100.6232,
    "state_id": "ND"
  },
  {
    "zip": 58746,
    "lat": 48.71825,
    "lng": -102.08515,
    "state_id": "ND"
  },
  {
    "zip": 58748,
    "lat": 48.67955,
    "lng": -100.65168,
    "state_id": "ND"
  },
  {
    "zip": 58750,
    "lat": 48.61617,
    "lng": -101.39826,
    "state_id": "ND"
  },
  {
    "zip": 58752,
    "lat": 48.83339,
    "lng": -102.58468,
    "state_id": "ND"
  },
  {
    "zip": 58755,
    "lat": 48.62398,
    "lng": -102.94595,
    "state_id": "ND"
  },
  {
    "zip": 58756,
    "lat": 47.9681,
    "lng": -101.81016,
    "state_id": "ND"
  },
  {
    "zip": 58757,
    "lat": 47.69113,
    "lng": -102.55689,
    "state_id": "ND"
  },
  {
    "zip": 58758,
    "lat": 47.77614,
    "lng": -100.10825,
    "state_id": "ND"
  },
  {
    "zip": 58759,
    "lat": 47.85576,
    "lng": -101.24677,
    "state_id": "ND"
  },
  {
    "zip": 58760,
    "lat": 48.70279,
    "lng": -101.14773,
    "state_id": "ND"
  },
  {
    "zip": 58761,
    "lat": 48.78319,
    "lng": -101.53294,
    "state_id": "ND"
  },
  {
    "zip": 58762,
    "lat": 48.67302,
    "lng": -100.94159,
    "state_id": "ND"
  },
  {
    "zip": 58763,
    "lat": 47.9929,
    "lng": -102.57574,
    "state_id": "ND"
  },
  {
    "zip": 58765,
    "lat": 48.87741,
    "lng": -103.0399,
    "state_id": "ND"
  },
  {
    "zip": 58768,
    "lat": 48.23219,
    "lng": -101.01063,
    "state_id": "ND"
  },
  {
    "zip": 58769,
    "lat": 48.32321,
    "lng": -102.22117,
    "state_id": "ND"
  },
  {
    "zip": 58770,
    "lat": 47.8844,
    "lng": -102.15792,
    "state_id": "ND"
  },
  {
    "zip": 58771,
    "lat": 48.08424,
    "lng": -101.97553,
    "state_id": "ND"
  },
  {
    "zip": 58772,
    "lat": 48.96554,
    "lng": -102.62408,
    "state_id": "ND"
  },
  {
    "zip": 58773,
    "lat": 48.58083,
    "lng": -102.65891,
    "state_id": "ND"
  },
  {
    "zip": 58775,
    "lat": 47.70823,
    "lng": -101.87082,
    "state_id": "ND"
  },
  {
    "zip": 58776,
    "lat": 48.2428,
    "lng": -102.61391,
    "state_id": "ND"
  },
  {
    "zip": 58778,
    "lat": 47.7784,
    "lng": -100.92716,
    "state_id": "ND"
  },
  {
    "zip": 58779,
    "lat": 47.86324,
    "lng": -101.76722,
    "state_id": "ND"
  },
  {
    "zip": 58781,
    "lat": 48.0211,
    "lng": -101.12696,
    "state_id": "ND"
  },
  {
    "zip": 58782,
    "lat": 48.93922,
    "lng": -101.70106,
    "state_id": "ND"
  },
  {
    "zip": 58783,
    "lat": 48.89223,
    "lng": -100.75674,
    "state_id": "ND"
  },
  {
    "zip": 58784,
    "lat": 48.36434,
    "lng": -102.42438,
    "state_id": "ND"
  },
  {
    "zip": 58785,
    "lat": 48.30898,
    "lng": -101.09062,
    "state_id": "ND"
  },
  {
    "zip": 58787,
    "lat": 48.75721,
    "lng": -101.80563,
    "state_id": "ND"
  },
  {
    "zip": 58788,
    "lat": 48.3423,
    "lng": -100.48054,
    "state_id": "ND"
  },
  {
    "zip": 58789,
    "lat": 48.559,
    "lng": -100.8161,
    "state_id": "ND"
  },
  {
    "zip": 58790,
    "lat": 48.03414,
    "lng": -100.92968,
    "state_id": "ND"
  },
  {
    "zip": 58792,
    "lat": 47.97114,
    "lng": -100.74328,
    "state_id": "ND"
  },
  {
    "zip": 58793,
    "lat": 48.86881,
    "lng": -101.0539,
    "state_id": "ND"
  },
  {
    "zip": 58794,
    "lat": 48.26294,
    "lng": -102.77081,
    "state_id": "ND"
  },
  {
    "zip": 58795,
    "lat": 48.64331,
    "lng": -103.15884,
    "state_id": "ND"
  },
  {
    "zip": 58801,
    "lat": 48.21311,
    "lng": -103.75325,
    "state_id": "ND"
  },
  {
    "zip": 58830,
    "lat": 48.60606,
    "lng": -103.46567,
    "state_id": "ND"
  },
  {
    "zip": 58831,
    "lat": 47.79474,
    "lng": -103.65455,
    "state_id": "ND"
  },
  {
    "zip": 58833,
    "lat": 48.89109,
    "lng": -103.52301,
    "state_id": "ND"
  },
  {
    "zip": 58835,
    "lat": 47.77465,
    "lng": -103.44089,
    "state_id": "ND"
  },
  {
    "zip": 58838,
    "lat": 47.77632,
    "lng": -103.90172,
    "state_id": "ND"
  },
  {
    "zip": 58843,
    "lat": 48.23382,
    "lng": -103.37928,
    "state_id": "ND"
  },
  {
    "zip": 58844,
    "lat": 48.88128,
    "lng": -103.74142,
    "state_id": "ND"
  },
  {
    "zip": 58845,
    "lat": 48.64854,
    "lng": -103.92544,
    "state_id": "ND"
  },
  {
    "zip": 58847,
    "lat": 47.90633,
    "lng": -102.89825,
    "state_id": "ND"
  },
  {
    "zip": 58849,
    "lat": 48.31884,
    "lng": -103.1997,
    "state_id": "ND"
  },
  {
    "zip": 58852,
    "lat": 48.35672,
    "lng": -102.95361,
    "state_id": "ND"
  },
  {
    "zip": 58853,
    "lat": 48.07536,
    "lng": -103.83679,
    "state_id": "ND"
  },
  {
    "zip": 58854,
    "lat": 47.81095,
    "lng": -103.14547,
    "state_id": "ND"
  },
  {
    "zip": 58856,
    "lat": 48.58635,
    "lng": -103.71719,
    "state_id": "ND"
  },
  {
    "zip": 68001,
    "lat": 41.33482,
    "lng": -96.95891,
    "state_id": "NE"
  },
  {
    "zip": 68002,
    "lat": 41.49691,
    "lng": -96.33704,
    "state_id": "NE"
  },
  {
    "zip": 68003,
    "lat": 41.07768,
    "lng": -96.38545,
    "state_id": "NE"
  },
  {
    "zip": 68004,
    "lat": 42.01242,
    "lng": -96.6321,
    "state_id": "NE"
  },
  {
    "zip": 68005,
    "lat": 41.14193,
    "lng": -95.89228,
    "state_id": "NE"
  },
  {
    "zip": 68007,
    "lat": 41.3698,
    "lng": -96.1914,
    "state_id": "NE"
  },
  {
    "zip": 68008,
    "lat": 41.54785,
    "lng": -96.16351,
    "state_id": "NE"
  },
  {
    "zip": 68010,
    "lat": 41.25584,
    "lng": -96.13385,
    "state_id": "NE"
  },
  {
    "zip": 68014,
    "lat": 41.28226,
    "lng": -96.96178,
    "state_id": "NE"
  },
  {
    "zip": 68015,
    "lat": 41.38558,
    "lng": -96.64342,
    "state_id": "NE"
  },
  {
    "zip": 68016,
    "lat": 41.04333,
    "lng": -96.10424,
    "state_id": "NE"
  },
  {
    "zip": 68017,
    "lat": 41.05951,
    "lng": -96.65487,
    "state_id": "NE"
  },
  {
    "zip": 68018,
    "lat": 41.30538,
    "lng": -96.61232,
    "state_id": "NE"
  },
  {
    "zip": 68019,
    "lat": 41.78424,
    "lng": -96.37371,
    "state_id": "NE"
  },
  {
    "zip": 68020,
    "lat": 41.97579,
    "lng": -96.25927,
    "state_id": "NE"
  },
  {
    "zip": 68022,
    "lat": 41.27845,
    "lng": -96.24594,
    "state_id": "NE"
  },
  {
    "zip": 68023,
    "lat": 41.44916,
    "lng": -96.0203,
    "state_id": "NE"
  },
  {
    "zip": 68025,
    "lat": 41.43794,
    "lng": -96.48933,
    "state_id": "NE"
  },
  {
    "zip": 68028,
    "lat": 41.10634,
    "lng": -96.26083,
    "state_id": "NE"
  },
  {
    "zip": 68029,
    "lat": 41.6609,
    "lng": -96.28274,
    "state_id": "NE"
  },
  {
    "zip": 68030,
    "lat": 42.32178,
    "lng": -96.45023,
    "state_id": "NE"
  },
  {
    "zip": 68031,
    "lat": 41.64279,
    "lng": -96.53047,
    "state_id": "NE"
  },
  {
    "zip": 68033,
    "lat": 41.1307,
    "lng": -96.51674,
    "state_id": "NE"
  },
  {
    "zip": 68034,
    "lat": 41.4584,
    "lng": -96.21917,
    "state_id": "NE"
  },
  {
    "zip": 68036,
    "lat": 41.39078,
    "lng": -96.95177,
    "state_id": "NE"
  },
  {
    "zip": 68037,
    "lat": 40.98582,
    "lng": -96.12729,
    "state_id": "NE"
  },
  {
    "zip": 68038,
    "lat": 41.95407,
    "lng": -96.44589,
    "state_id": "NE"
  },
  {
    "zip": 68039,
    "lat": 42.11408,
    "lng": -96.34461,
    "state_id": "NE"
  },
  {
    "zip": 68040,
    "lat": 41.28446,
    "lng": -96.73674,
    "state_id": "NE"
  },
  {
    "zip": 68041,
    "lat": 41.24826,
    "lng": -96.49825,
    "state_id": "NE"
  },
  {
    "zip": 68042,
    "lat": 41.0967,
    "lng": -96.43835,
    "state_id": "NE"
  },
  {
    "zip": 68044,
    "lat": 41.54914,
    "lng": -96.44413,
    "state_id": "NE"
  },
  {
    "zip": 68045,
    "lat": 41.8256,
    "lng": -96.49652,
    "state_id": "NE"
  },
  {
    "zip": 68046,
    "lat": 41.12488,
    "lng": -96.05945,
    "state_id": "NE"
  },
  {
    "zip": 68047,
    "lat": 42.10665,
    "lng": -96.74049,
    "state_id": "NE"
  },
  {
    "zip": 68048,
    "lat": 40.98141,
    "lng": -95.9344,
    "state_id": "NE"
  },
  {
    "zip": 68050,
    "lat": 41.30711,
    "lng": -96.84113,
    "state_id": "NE"
  },
  {
    "zip": 68055,
    "lat": 42.05893,
    "lng": -96.47216,
    "state_id": "NE"
  },
  {
    "zip": 68057,
    "lat": 41.6596,
    "lng": -96.7192,
    "state_id": "NE"
  },
  {
    "zip": 68058,
    "lat": 41.00875,
    "lng": -96.24832,
    "state_id": "NE"
  },
  {
    "zip": 68059,
    "lat": 41.06666,
    "lng": -96.15786,
    "state_id": "NE"
  },
  {
    "zip": 68061,
    "lat": 41.79941,
    "lng": -96.21152,
    "state_id": "NE"
  },
  {
    "zip": 68062,
    "lat": 42.19429,
    "lng": -96.66909,
    "state_id": "NE"
  },
  {
    "zip": 68063,
    "lat": 41.73059,
    "lng": -96.49563,
    "state_id": "NE"
  },
  {
    "zip": 68064,
    "lat": 41.35106,
    "lng": -96.34991,
    "state_id": "NE"
  },
  {
    "zip": 68065,
    "lat": 41.07317,
    "lng": -96.83987,
    "state_id": "NE"
  },
  {
    "zip": 68066,
    "lat": 41.18598,
    "lng": -96.6243,
    "state_id": "NE"
  },
  {
    "zip": 68067,
    "lat": 42.15289,
    "lng": -96.48393,
    "state_id": "NE"
  },
  {
    "zip": 68068,
    "lat": 41.39797,
    "lng": -96.20855,
    "state_id": "NE"
  },
  {
    "zip": 68069,
    "lat": 41.24286,
    "lng": -96.31621,
    "state_id": "NE"
  },
  {
    "zip": 68070,
    "lat": 41.19219,
    "lng": -96.80242,
    "state_id": "NE"
  },
  {
    "zip": 68071,
    "lat": 42.23568,
    "lng": -96.47117,
    "state_id": "NE"
  },
  {
    "zip": 68072,
    "lat": 41.60917,
    "lng": -96.50483,
    "state_id": "NE"
  },
  {
    "zip": 68073,
    "lat": 41.25066,
    "lng": -96.40779,
    "state_id": "NE"
  },
  {
    "zip": 68102,
    "lat": 41.26204,
    "lng": -95.93306,
    "state_id": "NE"
  },
  {
    "zip": 68104,
    "lat": 41.29498,
    "lng": -96.00128,
    "state_id": "NE"
  },
  {
    "zip": 68105,
    "lat": 41.2405,
    "lng": -95.96416,
    "state_id": "NE"
  },
  {
    "zip": 68106,
    "lat": 41.23893,
    "lng": -96.00218,
    "state_id": "NE"
  },
  {
    "zip": 68107,
    "lat": 41.20841,
    "lng": -95.95167,
    "state_id": "NE"
  },
  {
    "zip": 68108,
    "lat": 41.23741,
    "lng": -95.93055,
    "state_id": "NE"
  },
  {
    "zip": 68110,
    "lat": 41.29845,
    "lng": -95.91168,
    "state_id": "NE"
  },
  {
    "zip": 68111,
    "lat": 41.29558,
    "lng": -95.96399,
    "state_id": "NE"
  },
  {
    "zip": 68112,
    "lat": 41.37395,
    "lng": -95.9572,
    "state_id": "NE"
  },
  {
    "zip": 68113,
    "lat": 41.11759,
    "lng": -95.91126,
    "state_id": "NE"
  },
  {
    "zip": 68114,
    "lat": 41.26324,
    "lng": -96.05206,
    "state_id": "NE"
  },
  {
    "zip": 68116,
    "lat": 41.29935,
    "lng": -96.16782,
    "state_id": "NE"
  },
  {
    "zip": 68117,
    "lat": 41.20752,
    "lng": -96.00042,
    "state_id": "NE"
  },
  {
    "zip": 68118,
    "lat": 41.26318,
    "lng": -96.17743,
    "state_id": "NE"
  },
  {
    "zip": 68122,
    "lat": 41.36821,
    "lng": -96.05112,
    "state_id": "NE"
  },
  {
    "zip": 68123,
    "lat": 41.10147,
    "lng": -95.94331,
    "state_id": "NE"
  },
  {
    "zip": 68124,
    "lat": 41.23528,
    "lng": -96.05163,
    "state_id": "NE"
  },
  {
    "zip": 68127,
    "lat": 41.20544,
    "lng": -96.05066,
    "state_id": "NE"
  },
  {
    "zip": 68128,
    "lat": 41.18135,
    "lng": -96.06582,
    "state_id": "NE"
  },
  {
    "zip": 68130,
    "lat": 41.23422,
    "lng": -96.19586,
    "state_id": "NE"
  },
  {
    "zip": 68131,
    "lat": 41.26452,
    "lng": -95.96445,
    "state_id": "NE"
  },
  {
    "zip": 68132,
    "lat": 41.26466,
    "lng": -96.00236,
    "state_id": "NE"
  },
  {
    "zip": 68133,
    "lat": 41.11357,
    "lng": -95.99941,
    "state_id": "NE"
  },
  {
    "zip": 68134,
    "lat": 41.29854,
    "lng": -96.05065,
    "state_id": "NE"
  },
  {
    "zip": 68135,
    "lat": 41.20569,
    "lng": -96.19552,
    "state_id": "NE"
  },
  {
    "zip": 68136,
    "lat": 41.16889,
    "lng": -96.18671,
    "state_id": "NE"
  },
  {
    "zip": 68137,
    "lat": 41.20586,
    "lng": -96.11886,
    "state_id": "NE"
  },
  {
    "zip": 68138,
    "lat": 41.15606,
    "lng": -96.13645,
    "state_id": "NE"
  },
  {
    "zip": 68142,
    "lat": 41.36831,
    "lng": -96.10463,
    "state_id": "NE"
  },
  {
    "zip": 68144,
    "lat": 41.23486,
    "lng": -96.11919,
    "state_id": "NE"
  },
  {
    "zip": 68147,
    "lat": 41.1761,
    "lng": -95.95671,
    "state_id": "NE"
  },
  {
    "zip": 68152,
    "lat": 41.36536,
    "lng": -95.99789,
    "state_id": "NE"
  },
  {
    "zip": 68154,
    "lat": 41.26475,
    "lng": -96.11565,
    "state_id": "NE"
  },
  {
    "zip": 68157,
    "lat": 41.17845,
    "lng": -95.99693,
    "state_id": "NE"
  },
  {
    "zip": 68164,
    "lat": 41.29905,
    "lng": -96.10955,
    "state_id": "NE"
  },
  {
    "zip": 68178,
    "lat": 41.26569,
    "lng": -95.94756,
    "state_id": "NE"
  },
  {
    "zip": 68301,
    "lat": 40.46516,
    "lng": -96.52387,
    "state_id": "NE"
  },
  {
    "zip": 68303,
    "lat": 40.26603,
    "lng": -97.422,
    "state_id": "NE"
  },
  {
    "zip": 68304,
    "lat": 40.87932,
    "lng": -96.39239,
    "state_id": "NE"
  },
  {
    "zip": 68305,
    "lat": 40.36566,
    "lng": -95.85854,
    "state_id": "NE"
  },
  {
    "zip": 68307,
    "lat": 40.78983,
    "lng": -96.1336,
    "state_id": "NE"
  },
  {
    "zip": 68309,
    "lat": 40.06006,
    "lng": -96.56862,
    "state_id": "NE"
  },
  {
    "zip": 68310,
    "lat": 40.25097,
    "lng": -96.74955,
    "state_id": "NE"
  },
  {
    "zip": 68313,
    "lat": 40.78197,
    "lng": -97.27235,
    "state_id": "NE"
  },
  {
    "zip": 68314,
    "lat": 41.01276,
    "lng": -97.02761,
    "state_id": "NE"
  },
  {
    "zip": 68315,
    "lat": 40.24944,
    "lng": -97.54116,
    "state_id": "NE"
  },
  {
    "zip": 68316,
    "lat": 41.01776,
    "lng": -97.61813,
    "state_id": "NE"
  },
  {
    "zip": 68317,
    "lat": 40.67176,
    "lng": -96.50733,
    "state_id": "NE"
  },
  {
    "zip": 68318,
    "lat": 40.15391,
    "lng": -96.6401,
    "state_id": "NE"
  },
  {
    "zip": 68319,
    "lat": 40.93082,
    "lng": -97.76149,
    "state_id": "NE"
  },
  {
    "zip": 68320,
    "lat": 40.48626,
    "lng": -95.95518,
    "state_id": "NE"
  },
  {
    "zip": 68321,
    "lat": 40.3924,
    "lng": -95.69082,
    "state_id": "NE"
  },
  {
    "zip": 68322,
    "lat": 40.33882,
    "lng": -97.55624,
    "state_id": "NE"
  },
  {
    "zip": 68323,
    "lat": 40.14019,
    "lng": -96.38206,
    "state_id": "NE"
  },
  {
    "zip": 68324,
    "lat": 40.56002,
    "lng": -96.29215,
    "state_id": "NE"
  },
  {
    "zip": 68325,
    "lat": 40.01683,
    "lng": -97.75788,
    "state_id": "NE"
  },
  {
    "zip": 68326,
    "lat": 40.28607,
    "lng": -97.68284,
    "state_id": "NE"
  },
  {
    "zip": 68327,
    "lat": 40.0282,
    "lng": -97.61814,
    "state_id": "NE"
  },
  {
    "zip": 68328,
    "lat": 40.47743,
    "lng": -96.83764,
    "state_id": "NE"
  },
  {
    "zip": 68329,
    "lat": 40.50243,
    "lng": -96.16245,
    "state_id": "NE"
  },
  {
    "zip": 68331,
    "lat": 40.472,
    "lng": -96.71059,
    "state_id": "NE"
  },
  {
    "zip": 68332,
    "lat": 40.31682,
    "lng": -96.40564,
    "state_id": "NE"
  },
  {
    "zip": 68333,
    "lat": 40.6233,
    "lng": -96.94775,
    "state_id": "NE"
  },
  {
    "zip": 68335,
    "lat": 40.27698,
    "lng": -97.80302,
    "state_id": "NE"
  },
  {
    "zip": 68336,
    "lat": 40.96396,
    "lng": -96.68805,
    "state_id": "NE"
  },
  {
    "zip": 68337,
    "lat": 40.09667,
    "lng": -95.83811,
    "state_id": "NE"
  },
  {
    "zip": 68338,
    "lat": 40.31047,
    "lng": -97.27153,
    "state_id": "NE"
  },
  {
    "zip": 68339,
    "lat": 40.7337,
    "lng": -96.8646,
    "state_id": "NE"
  },
  {
    "zip": 68340,
    "lat": 40.13255,
    "lng": -97.74719,
    "state_id": "NE"
  },
  {
    "zip": 68341,
    "lat": 40.38379,
    "lng": -96.91376,
    "state_id": "NE"
  },
  {
    "zip": 68342,
    "lat": 40.10069,
    "lng": -96.94431,
    "state_id": "NE"
  },
  {
    "zip": 68343,
    "lat": 40.61869,
    "lng": -97.13424,
    "state_id": "NE"
  },
  {
    "zip": 68344,
    "lat": 40.56985,
    "lng": -96.40066,
    "state_id": "NE"
  },
  {
    "zip": 68345,
    "lat": 40.03993,
    "lng": -96.028,
    "state_id": "NE"
  },
  {
    "zip": 68346,
    "lat": 40.66383,
    "lng": -96.02894,
    "state_id": "NE"
  },
  {
    "zip": 68347,
    "lat": 40.80389,
    "lng": -96.42643,
    "state_id": "NE"
  },
  {
    "zip": 68348,
    "lat": 40.30611,
    "lng": -96.13923,
    "state_id": "NE"
  },
  {
    "zip": 68349,
    "lat": 40.82928,
    "lng": -96.29738,
    "state_id": "NE"
  },
  {
    "zip": 68350,
    "lat": 40.04461,
    "lng": -97.0763,
    "state_id": "NE"
  },
  {
    "zip": 68351,
    "lat": 40.65712,
    "lng": -97.42865,
    "state_id": "NE"
  },
  {
    "zip": 68352,
    "lat": 40.1458,
    "lng": -97.22032,
    "state_id": "NE"
  },
  {
    "zip": 68354,
    "lat": 40.63781,
    "lng": -97.57833,
    "state_id": "NE"
  },
  {
    "zip": 68355,
    "lat": 40.11509,
    "lng": -95.56406,
    "state_id": "NE"
  },
  {
    "zip": 68357,
    "lat": 40.31658,
    "lng": -96.55112,
    "state_id": "NE"
  },
  {
    "zip": 68358,
    "lat": 40.53148,
    "lng": -96.60805,
    "state_id": "NE"
  },
  {
    "zip": 68359,
    "lat": 40.62958,
    "lng": -97.28532,
    "state_id": "NE"
  },
  {
    "zip": 68360,
    "lat": 40.94549,
    "lng": -96.95509,
    "state_id": "NE"
  },
  {
    "zip": 68361,
    "lat": 40.50993,
    "lng": -97.60023,
    "state_id": "NE"
  },
  {
    "zip": 68362,
    "lat": 40.15667,
    "lng": -97.44173,
    "state_id": "NE"
  },
  {
    "zip": 68364,
    "lat": 40.83266,
    "lng": -97.2189,
    "state_id": "NE"
  },
  {
    "zip": 68365,
    "lat": 40.60962,
    "lng": -97.72883,
    "state_id": "NE"
  },
  {
    "zip": 68366,
    "lat": 40.97385,
    "lng": -96.43815,
    "state_id": "NE"
  },
  {
    "zip": 68367,
    "lat": 41.03581,
    "lng": -97.39728,
    "state_id": "NE"
  },
  {
    "zip": 68368,
    "lat": 40.55868,
    "lng": -96.82094,
    "state_id": "NE"
  },
  {
    "zip": 68370,
    "lat": 40.13915,
    "lng": -97.55057,
    "state_id": "NE"
  },
  {
    "zip": 68371,
    "lat": 40.77226,
    "lng": -97.78919,
    "state_id": "NE"
  },
  {
    "zip": 68372,
    "lat": 40.60813,
    "lng": -96.60353,
    "state_id": "NE"
  },
  {
    "zip": 68375,
    "lat": 40.03551,
    "lng": -97.45673,
    "state_id": "NE"
  },
  {
    "zip": 68376,
    "lat": 40.17438,
    "lng": -95.945,
    "state_id": "NE"
  },
  {
    "zip": 68377,
    "lat": 40.21421,
    "lng": -97.01842,
    "state_id": "NE"
  },
  {
    "zip": 68378,
    "lat": 40.38339,
    "lng": -96.02534,
    "state_id": "NE"
  },
  {
    "zip": 68379,
    "lat": 40.51983,
    "lng": -95.86744,
    "state_id": "NE"
  },
  {
    "zip": 68380,
    "lat": 40.24083,
    "lng": -96.39323,
    "state_id": "NE"
  },
  {
    "zip": 68381,
    "lat": 40.08011,
    "lng": -96.48041,
    "state_id": "NE"
  },
  {
    "zip": 68382,
    "lat": 40.5973,
    "lng": -96.02408,
    "state_id": "NE"
  },
  {
    "zip": 68401,
    "lat": 40.73785,
    "lng": -97.59167,
    "state_id": "NE"
  },
  {
    "zip": 68402,
    "lat": 40.90778,
    "lng": -96.85713,
    "state_id": "NE"
  },
  {
    "zip": 68403,
    "lat": 40.92071,
    "lng": -96.16801,
    "state_id": "NE"
  },
  {
    "zip": 68404,
    "lat": 40.61631,
    "lng": -96.75073,
    "state_id": "NE"
  },
  {
    "zip": 68405,
    "lat": 40.76579,
    "lng": -97.07264,
    "state_id": "NE"
  },
  {
    "zip": 68406,
    "lat": 40.49989,
    "lng": -97.38536,
    "state_id": "NE"
  },
  {
    "zip": 68407,
    "lat": 40.91922,
    "lng": -96.26654,
    "state_id": "NE"
  },
  {
    "zip": 68409,
    "lat": 40.90812,
    "lng": -95.95152,
    "state_id": "NE"
  },
  {
    "zip": 68410,
    "lat": 40.6375,
    "lng": -95.88476,
    "state_id": "NE"
  },
  {
    "zip": 68413,
    "lat": 40.83779,
    "lng": -96.01229,
    "state_id": "NE"
  },
  {
    "zip": 68414,
    "lat": 40.30462,
    "lng": -95.67043,
    "state_id": "NE"
  },
  {
    "zip": 68415,
    "lat": 40.06372,
    "lng": -96.8205,
    "state_id": "NE"
  },
  {
    "zip": 68416,
    "lat": 40.41036,
    "lng": -97.45682,
    "state_id": "NE"
  },
  {
    "zip": 68417,
    "lat": 40.73642,
    "lng": -96.08861,
    "state_id": "NE"
  },
  {
    "zip": 68418,
    "lat": 40.69487,
    "lng": -96.38857,
    "state_id": "NE"
  },
  {
    "zip": 68419,
    "lat": 40.5998,
    "lng": -96.51117,
    "state_id": "NE"
  },
  {
    "zip": 68420,
    "lat": 40.09409,
    "lng": -96.20202,
    "state_id": "NE"
  },
  {
    "zip": 68421,
    "lat": 40.49007,
    "lng": -95.74118,
    "state_id": "NE"
  },
  {
    "zip": 68422,
    "lat": 40.39019,
    "lng": -96.69598,
    "state_id": "NE"
  },
  {
    "zip": 68423,
    "lat": 40.80269,
    "lng": -96.95214,
    "state_id": "NE"
  },
  {
    "zip": 68424,
    "lat": 40.29705,
    "lng": -97.01681,
    "state_id": "NE"
  },
  {
    "zip": 68428,
    "lat": 40.96968,
    "lng": -96.79448,
    "state_id": "NE"
  },
  {
    "zip": 68430,
    "lat": 40.66862,
    "lng": -96.66342,
    "state_id": "NE"
  },
  {
    "zip": 68431,
    "lat": 40.06947,
    "lng": -95.43349,
    "state_id": "NE"
  },
  {
    "zip": 68433,
    "lat": 40.05169,
    "lng": -95.73584,
    "state_id": "NE"
  },
  {
    "zip": 68434,
    "lat": 40.90874,
    "lng": -97.12035,
    "state_id": "NE"
  },
  {
    "zip": 68436,
    "lat": 40.42383,
    "lng": -97.73942,
    "state_id": "NE"
  },
  {
    "zip": 68437,
    "lat": 40.23166,
    "lng": -95.64577,
    "state_id": "NE"
  },
  {
    "zip": 68438,
    "lat": 40.62657,
    "lng": -96.74448,
    "state_id": "NE"
  },
  {
    "zip": 68439,
    "lat": 41.00107,
    "lng": -97.20883,
    "state_id": "NE"
  },
  {
    "zip": 68440,
    "lat": 40.05687,
    "lng": -97.00982,
    "state_id": "NE"
  },
  {
    "zip": 68441,
    "lat": 40.22526,
    "lng": -96.23816,
    "state_id": "NE"
  },
  {
    "zip": 68442,
    "lat": 40.23865,
    "lng": -95.77321,
    "state_id": "NE"
  },
  {
    "zip": 68443,
    "lat": 40.45213,
    "lng": -96.36807,
    "state_id": "NE"
  },
  {
    "zip": 68444,
    "lat": 40.41138,
    "lng": -97.57706,
    "state_id": "NE"
  },
  {
    "zip": 68445,
    "lat": 40.38926,
    "lng": -97.07915,
    "state_id": "NE"
  },
  {
    "zip": 68446,
    "lat": 40.65328,
    "lng": -96.17511,
    "state_id": "NE"
  },
  {
    "zip": 68447,
    "lat": 40.20495,
    "lng": -96.07629,
    "state_id": "NE"
  },
  {
    "zip": 68448,
    "lat": 40.55163,
    "lng": -96.02557,
    "state_id": "NE"
  },
  {
    "zip": 68450,
    "lat": 40.36099,
    "lng": -96.22739,
    "state_id": "NE"
  },
  {
    "zip": 68452,
    "lat": 40.39446,
    "lng": -97.85229,
    "state_id": "NE"
  },
  {
    "zip": 68453,
    "lat": 40.41597,
    "lng": -97.3278,
    "state_id": "NE"
  },
  {
    "zip": 68454,
    "lat": 40.69187,
    "lng": -96.28849,
    "state_id": "NE"
  },
  {
    "zip": 68455,
    "lat": 40.82569,
    "lng": -95.90087,
    "state_id": "NE"
  },
  {
    "zip": 68456,
    "lat": 40.91123,
    "lng": -97.31435,
    "state_id": "NE"
  },
  {
    "zip": 68457,
    "lat": 40.15328,
    "lng": -95.70736,
    "state_id": "NE"
  },
  {
    "zip": 68458,
    "lat": 40.23004,
    "lng": -96.50321,
    "state_id": "NE"
  },
  {
    "zip": 68460,
    "lat": 40.89459,
    "lng": -97.43027,
    "state_id": "NE"
  },
  {
    "zip": 68461,
    "lat": 40.76645,
    "lng": -96.51516,
    "state_id": "NE"
  },
  {
    "zip": 68462,
    "lat": 40.94907,
    "lng": -96.52639,
    "state_id": "NE"
  },
  {
    "zip": 68463,
    "lat": 40.87532,
    "lng": -96.13419,
    "state_id": "NE"
  },
  {
    "zip": 68464,
    "lat": 40.4286,
    "lng": -97.19869,
    "state_id": "NE"
  },
  {
    "zip": 68465,
    "lat": 40.49083,
    "lng": -97.02078,
    "state_id": "NE"
  },
  {
    "zip": 68466,
    "lat": 40.07023,
    "lng": -96.64125,
    "state_id": "NE"
  },
  {
    "zip": 68467,
    "lat": 40.8664,
    "lng": -97.59544,
    "state_id": "NE"
  },
  {
    "zip": 68502,
    "lat": 40.78539,
    "lng": -96.69847,
    "state_id": "NE"
  },
  {
    "zip": 68503,
    "lat": 40.82458,
    "lng": -96.67403,
    "state_id": "NE"
  },
  {
    "zip": 68504,
    "lat": 40.8543,
    "lng": -96.66057,
    "state_id": "NE"
  },
  {
    "zip": 68505,
    "lat": 40.82486,
    "lng": -96.61928,
    "state_id": "NE"
  },
  {
    "zip": 68506,
    "lat": 40.78388,
    "lng": -96.63864,
    "state_id": "NE"
  },
  {
    "zip": 68507,
    "lat": 40.85892,
    "lng": -96.61486,
    "state_id": "NE"
  },
  {
    "zip": 68508,
    "lat": 40.8183,
    "lng": -96.70587,
    "state_id": "NE"
  },
  {
    "zip": 68510,
    "lat": 40.80652,
    "lng": -96.63597,
    "state_id": "NE"
  },
  {
    "zip": 68512,
    "lat": 40.73876,
    "lng": -96.70755,
    "state_id": "NE"
  },
  {
    "zip": 68514,
    "lat": 40.92554,
    "lng": -96.65156,
    "state_id": "NE"
  },
  {
    "zip": 68516,
    "lat": 40.73488,
    "lng": -96.64258,
    "state_id": "NE"
  },
  {
    "zip": 68517,
    "lat": 40.93398,
    "lng": -96.60554,
    "state_id": "NE"
  },
  {
    "zip": 68520,
    "lat": 40.79968,
    "lng": -96.55148,
    "state_id": "NE"
  },
  {
    "zip": 68521,
    "lat": 40.86613,
    "lng": -96.70947,
    "state_id": "NE"
  },
  {
    "zip": 68522,
    "lat": 40.7887,
    "lng": -96.76209,
    "state_id": "NE"
  },
  {
    "zip": 68523,
    "lat": 40.73596,
    "lng": -96.7584,
    "state_id": "NE"
  },
  {
    "zip": 68524,
    "lat": 40.87113,
    "lng": -96.80537,
    "state_id": "NE"
  },
  {
    "zip": 68526,
    "lat": 40.73235,
    "lng": -96.58367,
    "state_id": "NE"
  },
  {
    "zip": 68527,
    "lat": 40.84711,
    "lng": -96.52423,
    "state_id": "NE"
  },
  {
    "zip": 68528,
    "lat": 40.82024,
    "lng": -96.81943,
    "state_id": "NE"
  },
  {
    "zip": 68531,
    "lat": 40.90141,
    "lng": -96.72324,
    "state_id": "NE"
  },
  {
    "zip": 68532,
    "lat": 40.79201,
    "lng": -96.84265,
    "state_id": "NE"
  },
  {
    "zip": 68601,
    "lat": 41.45254,
    "lng": -97.40235,
    "state_id": "NE"
  },
  {
    "zip": 68620,
    "lat": 41.73403,
    "lng": -98.06991,
    "state_id": "NE"
  },
  {
    "zip": 68621,
    "lat": 41.48745,
    "lng": -96.6364,
    "state_id": "NE"
  },
  {
    "zip": 68622,
    "lat": 41.94182,
    "lng": -98.58052,
    "state_id": "NE"
  },
  {
    "zip": 68623,
    "lat": 41.44401,
    "lng": -98.15026,
    "state_id": "NE"
  },
  {
    "zip": 68624,
    "lat": 41.35174,
    "lng": -97.23428,
    "state_id": "NE"
  },
  {
    "zip": 68626,
    "lat": 41.16707,
    "lng": -96.99433,
    "state_id": "NE"
  },
  {
    "zip": 68627,
    "lat": 41.5544,
    "lng": -98.16238,
    "state_id": "NE"
  },
  {
    "zip": 68628,
    "lat": 41.22073,
    "lng": -97.83968,
    "state_id": "NE"
  },
  {
    "zip": 68629,
    "lat": 41.71607,
    "lng": -97.12874,
    "state_id": "NE"
  },
  {
    "zip": 68631,
    "lat": 41.68978,
    "lng": -97.34907,
    "state_id": "NE"
  },
  {
    "zip": 68632,
    "lat": 41.24724,
    "lng": -97.13113,
    "state_id": "NE"
  },
  {
    "zip": 68633,
    "lat": 41.70515,
    "lng": -96.89691,
    "state_id": "NE"
  },
  {
    "zip": 68634,
    "lat": 41.38989,
    "lng": -97.49306,
    "state_id": "NE"
  },
  {
    "zip": 68635,
    "lat": 41.08247,
    "lng": -97.01491,
    "state_id": "NE"
  },
  {
    "zip": 68636,
    "lat": 42.00875,
    "lng": -98.15375,
    "state_id": "NE"
  },
  {
    "zip": 68637,
    "lat": 41.80528,
    "lng": -98.65848,
    "state_id": "NE"
  },
  {
    "zip": 68638,
    "lat": 41.37421,
    "lng": -97.9868,
    "state_id": "NE"
  },
  {
    "zip": 68640,
    "lat": 41.46285,
    "lng": -97.77395,
    "state_id": "NE"
  },
  {
    "zip": 68641,
    "lat": 41.73515,
    "lng": -97.00818,
    "state_id": "NE"
  },
  {
    "zip": 68642,
    "lat": 41.69028,
    "lng": -97.51506,
    "state_id": "NE"
  },
  {
    "zip": 68643,
    "lat": 41.70459,
    "lng": -97.25843,
    "state_id": "NE"
  },
  {
    "zip": 68644,
    "lat": 41.71033,
    "lng": -97.6752,
    "state_id": "NE"
  },
  {
    "zip": 68647,
    "lat": 41.52325,
    "lng": -97.62151,
    "state_id": "NE"
  },
  {
    "zip": 68648,
    "lat": 41.40459,
    "lng": -96.79161,
    "state_id": "NE"
  },
  {
    "zip": 68649,
    "lat": 41.50913,
    "lng": -96.78774,
    "state_id": "NE"
  },
  {
    "zip": 68651,
    "lat": 41.2262,
    "lng": -97.57973,
    "state_id": "NE"
  },
  {
    "zip": 68652,
    "lat": 41.86969,
    "lng": -98.05189,
    "state_id": "NE"
  },
  {
    "zip": 68653,
    "lat": 41.55523,
    "lng": -97.48529,
    "state_id": "NE"
  },
  {
    "zip": 68654,
    "lat": 41.06673,
    "lng": -97.78414,
    "state_id": "NE"
  },
  {
    "zip": 68655,
    "lat": 41.65525,
    "lng": -98.25059,
    "state_id": "NE"
  },
  {
    "zip": 68658,
    "lat": 41.19791,
    "lng": -97.29082,
    "state_id": "NE"
  },
  {
    "zip": 68659,
    "lat": 41.54493,
    "lng": -96.94331,
    "state_id": "NE"
  },
  {
    "zip": 68660,
    "lat": 41.57549,
    "lng": -97.86973,
    "state_id": "NE"
  },
  {
    "zip": 68661,
    "lat": 41.49315,
    "lng": -97.08361,
    "state_id": "NE"
  },
  {
    "zip": 68662,
    "lat": 41.21054,
    "lng": -97.41406,
    "state_id": "NE"
  },
  {
    "zip": 68663,
    "lat": 41.31669,
    "lng": -97.69469,
    "state_id": "NE"
  },
  {
    "zip": 68664,
    "lat": 41.70403,
    "lng": -96.78645,
    "state_id": "NE"
  },
  {
    "zip": 68665,
    "lat": 41.76102,
    "lng": -98.40606,
    "state_id": "NE"
  },
  {
    "zip": 68666,
    "lat": 41.11091,
    "lng": -97.61556,
    "state_id": "NE"
  },
  {
    "zip": 68667,
    "lat": 41.11012,
    "lng": -97.30806,
    "state_id": "NE"
  },
  {
    "zip": 68669,
    "lat": 41.0825,
    "lng": -97.23467,
    "state_id": "NE"
  },
  {
    "zip": 68701,
    "lat": 42.02136,
    "lng": -97.44362,
    "state_id": "NE"
  },
  {
    "zip": 68710,
    "lat": 42.43882,
    "lng": -96.85468,
    "state_id": "NE"
  },
  {
    "zip": 68711,
    "lat": 42.19266,
    "lng": -99.03874,
    "state_id": "NE"
  },
  {
    "zip": 68713,
    "lat": 42.53801,
    "lng": -98.96907,
    "state_id": "NE"
  },
  {
    "zip": 68714,
    "lat": 42.40228,
    "lng": -99.4864,
    "state_id": "NE"
  },
  {
    "zip": 68715,
    "lat": 41.96447,
    "lng": -97.60782,
    "state_id": "NE"
  },
  {
    "zip": 68716,
    "lat": 41.93314,
    "lng": -96.82297,
    "state_id": "NE"
  },
  {
    "zip": 68717,
    "lat": 42.39463,
    "lng": -97.21934,
    "state_id": "NE"
  },
  {
    "zip": 68718,
    "lat": 42.65594,
    "lng": -97.69676,
    "state_id": "NE"
  },
  {
    "zip": 68719,
    "lat": 42.9484,
    "lng": -98.57211,
    "state_id": "NE"
  },
  {
    "zip": 68720,
    "lat": 42.34562,
    "lng": -98.01261,
    "state_id": "NE"
  },
  {
    "zip": 68722,
    "lat": 42.9202,
    "lng": -98.87406,
    "state_id": "NE"
  },
  {
    "zip": 68723,
    "lat": 42.286,
    "lng": -97.21192,
    "state_id": "NE"
  },
  {
    "zip": 68724,
    "lat": 42.638,
    "lng": -97.894,
    "state_id": "NE"
  },
  {
    "zip": 68725,
    "lat": 42.20223,
    "lng": -98.7771,
    "state_id": "NE"
  },
  {
    "zip": 68726,
    "lat": 42.18195,
    "lng": -98.2055,
    "state_id": "NE"
  },
  {
    "zip": 68727,
    "lat": 42.513,
    "lng": -97.20348,
    "state_id": "NE"
  },
  {
    "zip": 68728,
    "lat": 42.37634,
    "lng": -96.95269,
    "state_id": "NE"
  },
  {
    "zip": 68729,
    "lat": 42.47079,
    "lng": -97.90434,
    "state_id": "NE"
  },
  {
    "zip": 68730,
    "lat": 42.77006,
    "lng": -97.54015,
    "state_id": "NE"
  },
  {
    "zip": 68731,
    "lat": 42.4098,
    "lng": -96.48106,
    "state_id": "NE"
  },
  {
    "zip": 68732,
    "lat": 42.49187,
    "lng": -96.97943,
    "state_id": "NE"
  },
  {
    "zip": 68733,
    "lat": 42.28484,
    "lng": -96.72476,
    "state_id": "NE"
  },
  {
    "zip": 68734,
    "lat": 42.46905,
    "lng": -98.79604,
    "state_id": "NE"
  },
  {
    "zip": 68735,
    "lat": 42.14366,
    "lng": -98.47554,
    "state_id": "NE"
  },
  {
    "zip": 68736,
    "lat": 42.73543,
    "lng": -97.36778,
    "state_id": "NE"
  },
  {
    "zip": 68739,
    "lat": 42.62568,
    "lng": -97.26945,
    "state_id": "NE"
  },
  {
    "zip": 68740,
    "lat": 42.15193,
    "lng": -97.30034,
    "state_id": "NE"
  },
  {
    "zip": 68741,
    "lat": 42.34861,
    "lng": -96.59047,
    "state_id": "NE"
  },
  {
    "zip": 68742,
    "lat": 42.35139,
    "lng": -98.51674,
    "state_id": "NE"
  },
  {
    "zip": 68743,
    "lat": 42.46556,
    "lng": -96.62619,
    "state_id": "NE"
  },
  {
    "zip": 68745,
    "lat": 42.44767,
    "lng": -97.08792,
    "state_id": "NE"
  },
  {
    "zip": 68746,
    "lat": 42.79222,
    "lng": -98.43848,
    "state_id": "NE"
  },
  {
    "zip": 68747,
    "lat": 42.39443,
    "lng": -97.49721,
    "state_id": "NE"
  },
  {
    "zip": 68748,
    "lat": 41.84007,
    "lng": -97.46471,
    "state_id": "NE"
  },
  {
    "zip": 68749,
    "lat": 42.45307,
    "lng": -97.47532,
    "state_id": "NE"
  },
  {
    "zip": 68751,
    "lat": 42.6906,
    "lng": -96.98083,
    "state_id": "NE"
  },
  {
    "zip": 68752,
    "lat": 42.02716,
    "lng": -97.72844,
    "state_id": "NE"
  },
  {
    "zip": 68753,
    "lat": 42.88469,
    "lng": -99.44943,
    "state_id": "NE"
  },
  {
    "zip": 68755,
    "lat": 42.92332,
    "lng": -99.12905,
    "state_id": "NE"
  },
  {
    "zip": 68756,
    "lat": 42.19063,
    "lng": -97.98003,
    "state_id": "NE"
  },
  {
    "zip": 68757,
    "lat": 42.65025,
    "lng": -96.92136,
    "state_id": "NE"
  },
  {
    "zip": 68758,
    "lat": 41.78651,
    "lng": -97.78843,
    "state_id": "NE"
  },
  {
    "zip": 68759,
    "lat": 42.70517,
    "lng": -99.31385,
    "state_id": "NE"
  },
  {
    "zip": 68760,
    "lat": 42.76551,
    "lng": -98.03095,
    "state_id": "NE"
  },
  {
    "zip": 68761,
    "lat": 42.05446,
    "lng": -97.96181,
    "state_id": "NE"
  },
  {
    "zip": 68763,
    "lat": 42.50871,
    "lng": -98.60943,
    "state_id": "NE"
  },
  {
    "zip": 68764,
    "lat": 42.39997,
    "lng": -98.23106,
    "state_id": "NE"
  },
  {
    "zip": 68765,
    "lat": 42.34268,
    "lng": -97.62229,
    "state_id": "NE"
  },
  {
    "zip": 68766,
    "lat": 42.43498,
    "lng": -98.40574,
    "state_id": "NE"
  },
  {
    "zip": 68767,
    "lat": 42.1996,
    "lng": -97.55184,
    "state_id": "NE"
  },
  {
    "zip": 68768,
    "lat": 41.99445,
    "lng": -97.06757,
    "state_id": "NE"
  },
  {
    "zip": 68769,
    "lat": 42.32227,
    "lng": -97.81212,
    "state_id": "NE"
  },
  {
    "zip": 68770,
    "lat": 42.56422,
    "lng": -96.76512,
    "state_id": "NE"
  },
  {
    "zip": 68771,
    "lat": 42.38321,
    "lng": -97.35756,
    "state_id": "NE"
  },
  {
    "zip": 68773,
    "lat": 42.36476,
    "lng": -98.1235,
    "state_id": "NE"
  },
  {
    "zip": 68774,
    "lat": 42.81535,
    "lng": -97.28009,
    "state_id": "NE"
  },
  {
    "zip": 68776,
    "lat": 42.46058,
    "lng": -96.44131,
    "state_id": "NE"
  },
  {
    "zip": 68777,
    "lat": 42.86576,
    "lng": -98.69311,
    "state_id": "NE"
  },
  {
    "zip": 68778,
    "lat": 42.86757,
    "lng": -99.79082,
    "state_id": "NE"
  },
  {
    "zip": 68779,
    "lat": 41.95641,
    "lng": -97.20831,
    "state_id": "NE"
  },
  {
    "zip": 68780,
    "lat": 42.6428,
    "lng": -99.13364,
    "state_id": "NE"
  },
  {
    "zip": 68781,
    "lat": 42.02383,
    "lng": -97.86242,
    "state_id": "NE"
  },
  {
    "zip": 68783,
    "lat": 42.62072,
    "lng": -98.15211,
    "state_id": "NE"
  },
  {
    "zip": 68784,
    "lat": 42.25777,
    "lng": -96.8929,
    "state_id": "NE"
  },
  {
    "zip": 68785,
    "lat": 42.42647,
    "lng": -96.72412,
    "state_id": "NE"
  },
  {
    "zip": 68786,
    "lat": 42.50576,
    "lng": -97.55664,
    "state_id": "NE"
  },
  {
    "zip": 68787,
    "lat": 42.21441,
    "lng": -97.03474,
    "state_id": "NE"
  },
  {
    "zip": 68788,
    "lat": 41.83971,
    "lng": -96.72148,
    "state_id": "NE"
  },
  {
    "zip": 68789,
    "lat": 42.54725,
    "lng": -97.97265,
    "state_id": "NE"
  },
  {
    "zip": 68790,
    "lat": 42.16242,
    "lng": -97.17545,
    "state_id": "NE"
  },
  {
    "zip": 68791,
    "lat": 41.99204,
    "lng": -96.93641,
    "state_id": "NE"
  },
  {
    "zip": 68792,
    "lat": 42.73821,
    "lng": -97.12155,
    "state_id": "NE"
  },
  {
    "zip": 68801,
    "lat": 40.94785,
    "lng": -98.29167,
    "state_id": "NE"
  },
  {
    "zip": 68803,
    "lat": 40.95492,
    "lng": -98.41522,
    "state_id": "NE"
  },
  {
    "zip": 68810,
    "lat": 40.85843,
    "lng": -98.46696,
    "state_id": "NE"
  },
  {
    "zip": 68812,
    "lat": 40.90115,
    "lng": -99.27942,
    "state_id": "NE"
  },
  {
    "zip": 68813,
    "lat": 41.68513,
    "lng": -99.91471,
    "state_id": "NE"
  },
  {
    "zip": 68814,
    "lat": 41.31652,
    "lng": -99.41292,
    "state_id": "NE"
  },
  {
    "zip": 68815,
    "lat": 41.41932,
    "lng": -99.14385,
    "state_id": "NE"
  },
  {
    "zip": 68816,
    "lat": 41.17739,
    "lng": -98.13296,
    "state_id": "NE"
  },
  {
    "zip": 68817,
    "lat": 41.27394,
    "lng": -98.79087,
    "state_id": "NE"
  },
  {
    "zip": 68818,
    "lat": 40.81762,
    "lng": -98.00833,
    "state_id": "NE"
  },
  {
    "zip": 68820,
    "lat": 41.10858,
    "lng": -98.70223,
    "state_id": "NE"
  },
  {
    "zip": 68821,
    "lat": 42.00951,
    "lng": -99.85778,
    "state_id": "NE"
  },
  {
    "zip": 68822,
    "lat": 41.38295,
    "lng": -99.63171,
    "state_id": "NE"
  },
  {
    "zip": 68823,
    "lat": 41.92648,
    "lng": -99.10901,
    "state_id": "NE"
  },
  {
    "zip": 68824,
    "lat": 40.99226,
    "lng": -98.60166,
    "state_id": "NE"
  },
  {
    "zip": 68825,
    "lat": 41.27175,
    "lng": -99.96458,
    "state_id": "NE"
  },
  {
    "zip": 68826,
    "lat": 41.16361,
    "lng": -98.01551,
    "state_id": "NE"
  },
  {
    "zip": 68827,
    "lat": 41.04497,
    "lng": -98.1854,
    "state_id": "NE"
  },
  {
    "zip": 68828,
    "lat": 41.54885,
    "lng": -99.2354,
    "state_id": "NE"
  },
  {
    "zip": 68831,
    "lat": 41.11096,
    "lng": -98.55389,
    "state_id": "NE"
  },
  {
    "zip": 68832,
    "lat": 40.76667,
    "lng": -98.36573,
    "state_id": "NE"
  },
  {
    "zip": 68833,
    "lat": 41.82838,
    "lng": -100.15389,
    "state_id": "NE"
  },
  {
    "zip": 68834,
    "lat": 41.03164,
    "lng": -99.65885,
    "state_id": "NE"
  },
  {
    "zip": 68835,
    "lat": 41.33176,
    "lng": -98.6199,
    "state_id": "NE"
  },
  {
    "zip": 68836,
    "lat": 40.70649,
    "lng": -99.3743,
    "state_id": "NE"
  },
  {
    "zip": 68837,
    "lat": 41.65982,
    "lng": -99.07658,
    "state_id": "NE"
  },
  {
    "zip": 68838,
    "lat": 41.2152,
    "lng": -98.66548,
    "state_id": "NE"
  },
  {
    "zip": 68840,
    "lat": 40.75845,
    "lng": -98.88117,
    "state_id": "NE"
  },
  {
    "zip": 68841,
    "lat": 40.75998,
    "lng": -98.17078,
    "state_id": "NE"
  },
  {
    "zip": 68842,
    "lat": 41.56757,
    "lng": -98.50057,
    "state_id": "NE"
  },
  {
    "zip": 68843,
    "lat": 40.90455,
    "lng": -97.8853,
    "state_id": "NE"
  },
  {
    "zip": 68844,
    "lat": 41.05991,
    "lng": -99.05857,
    "state_id": "NE"
  },
  {
    "zip": 68845,
    "lat": 40.72731,
    "lng": -99.16299,
    "state_id": "NE"
  },
  {
    "zip": 68846,
    "lat": 41.06653,
    "lng": -97.88675,
    "state_id": "NE"
  },
  {
    "zip": 68847,
    "lat": 40.75891,
    "lng": -99.02572,
    "state_id": "NE"
  },
  {
    "zip": 68849,
    "lat": 40.70107,
    "lng": -99.104,
    "state_id": "NE"
  },
  {
    "zip": 68850,
    "lat": 40.82956,
    "lng": -99.73645,
    "state_id": "NE"
  },
  {
    "zip": 68852,
    "lat": 41.16459,
    "lng": -99.12629,
    "state_id": "NE"
  },
  {
    "zip": 68853,
    "lat": 41.29253,
    "lng": -98.98826,
    "state_id": "NE"
  },
  {
    "zip": 68854,
    "lat": 41.00843,
    "lng": -98.00044,
    "state_id": "NE"
  },
  {
    "zip": 68855,
    "lat": 41.1678,
    "lng": -99.32387,
    "state_id": "NE"
  },
  {
    "zip": 68856,
    "lat": 41.49586,
    "lng": -99.82745,
    "state_id": "NE"
  },
  {
    "zip": 68858,
    "lat": 40.95947,
    "lng": -99.37702,
    "state_id": "NE"
  },
  {
    "zip": 68859,
    "lat": 41.47117,
    "lng": -98.82392,
    "state_id": "NE"
  },
  {
    "zip": 68860,
    "lat": 41.1331,
    "lng": -99.79139,
    "state_id": "NE"
  },
  {
    "zip": 68861,
    "lat": 40.70241,
    "lng": -99.25883,
    "state_id": "NE"
  },
  {
    "zip": 68862,
    "lat": 41.60044,
    "lng": -98.93664,
    "state_id": "NE"
  },
  {
    "zip": 68863,
    "lat": 40.76972,
    "lng": -99.52494,
    "state_id": "NE"
  },
  {
    "zip": 68864,
    "lat": 41.26306,
    "lng": -98.23505,
    "state_id": "NE"
  },
  {
    "zip": 68865,
    "lat": 40.8965,
    "lng": -98.18586,
    "state_id": "NE"
  },
  {
    "zip": 68866,
    "lat": 40.97538,
    "lng": -99.12858,
    "state_id": "NE"
  },
  {
    "zip": 68869,
    "lat": 40.99689,
    "lng": -98.88117,
    "state_id": "NE"
  },
  {
    "zip": 68870,
    "lat": 40.85249,
    "lng": -99.16106,
    "state_id": "NE"
  },
  {
    "zip": 68871,
    "lat": 41.14059,
    "lng": -98.85118,
    "state_id": "NE"
  },
  {
    "zip": 68872,
    "lat": 41.09485,
    "lng": -98.33396,
    "state_id": "NE"
  },
  {
    "zip": 68873,
    "lat": 41.24501,
    "lng": -98.45046,
    "state_id": "NE"
  },
  {
    "zip": 68874,
    "lat": 41.63752,
    "lng": -99.41287,
    "state_id": "NE"
  },
  {
    "zip": 68875,
    "lat": 41.56285,
    "lng": -98.66741,
    "state_id": "NE"
  },
  {
    "zip": 68876,
    "lat": 40.81729,
    "lng": -98.74995,
    "state_id": "NE"
  },
  {
    "zip": 68878,
    "lat": 40.97611,
    "lng": -99.51705,
    "state_id": "NE"
  },
  {
    "zip": 68879,
    "lat": 41.90208,
    "lng": -99.54467,
    "state_id": "NE"
  },
  {
    "zip": 68881,
    "lat": 41.43843,
    "lng": -99.35188,
    "state_id": "NE"
  },
  {
    "zip": 68882,
    "lat": 41.42785,
    "lng": -98.40025,
    "state_id": "NE"
  },
  {
    "zip": 68883,
    "lat": 40.81264,
    "lng": -98.59113,
    "state_id": "NE"
  },
  {
    "zip": 68901,
    "lat": 40.59293,
    "lng": -98.37284,
    "state_id": "NE"
  },
  {
    "zip": 68920,
    "lat": 40.15684,
    "lng": -99.33721,
    "state_id": "NE"
  },
  {
    "zip": 68922,
    "lat": 40.33395,
    "lng": -99.89664,
    "state_id": "NE"
  },
  {
    "zip": 68923,
    "lat": 40.35909,
    "lng": -99.46833,
    "state_id": "NE"
  },
  {
    "zip": 68924,
    "lat": 40.49565,
    "lng": -99.11905,
    "state_id": "NE"
  },
  {
    "zip": 68925,
    "lat": 40.4254,
    "lng": -98.43785,
    "state_id": "NE"
  },
  {
    "zip": 68926,
    "lat": 40.0948,
    "lng": -99.84767,
    "state_id": "NE"
  },
  {
    "zip": 68927,
    "lat": 40.50199,
    "lng": -99.62896,
    "state_id": "NE"
  },
  {
    "zip": 68928,
    "lat": 40.27938,
    "lng": -98.61362,
    "state_id": "NE"
  },
  {
    "zip": 68929,
    "lat": 40.14998,
    "lng": -99.05112,
    "state_id": "NE"
  },
  {
    "zip": 68930,
    "lat": 40.28849,
    "lng": -98.41415,
    "state_id": "NE"
  },
  {
    "zip": 68932,
    "lat": 40.30849,
    "lng": -98.72519,
    "state_id": "NE"
  },
  {
    "zip": 68933,
    "lat": 40.51674,
    "lng": -98.02741,
    "state_id": "NE"
  },
  {
    "zip": 68934,
    "lat": 40.33931,
    "lng": -98.13834,
    "state_id": "NE"
  },
  {
    "zip": 68935,
    "lat": 40.3779,
    "lng": -97.9574,
    "state_id": "NE"
  },
  {
    "zip": 68936,
    "lat": 40.33137,
    "lng": -99.77505,
    "state_id": "NE"
  },
  {
    "zip": 68937,
    "lat": 40.59087,
    "lng": -99.88136,
    "state_id": "NE"
  },
  {
    "zip": 68938,
    "lat": 40.40951,
    "lng": -98.11835,
    "state_id": "NE"
  },
  {
    "zip": 68939,
    "lat": 40.10132,
    "lng": -98.96887,
    "state_id": "NE"
  },
  {
    "zip": 68940,
    "lat": 40.55134,
    "lng": -99.24279,
    "state_id": "NE"
  },
  {
    "zip": 68941,
    "lat": 40.45725,
    "lng": -98.27812,
    "state_id": "NE"
  },
  {
    "zip": 68942,
    "lat": 40.09089,
    "lng": -98.30035,
    "state_id": "NE"
  },
  {
    "zip": 68943,
    "lat": 40.04888,
    "lng": -97.89868,
    "state_id": "NE"
  },
  {
    "zip": 68944,
    "lat": 40.64372,
    "lng": -98.09094,
    "state_id": "NE"
  },
  {
    "zip": 68945,
    "lat": 40.56825,
    "lng": -98.77782,
    "state_id": "NE"
  },
  {
    "zip": 68946,
    "lat": 40.11166,
    "lng": -99.98577,
    "state_id": "NE"
  },
  {
    "zip": 68947,
    "lat": 40.2801,
    "lng": -99.04765,
    "state_id": "NE"
  },
  {
    "zip": 68948,
    "lat": 40.32004,
    "lng": -100.02192,
    "state_id": "NE"
  },
  {
    "zip": 68949,
    "lat": 40.42911,
    "lng": -99.38993,
    "state_id": "NE"
  },
  {
    "zip": 68950,
    "lat": 40.46178,
    "lng": -98.66943,
    "state_id": "NE"
  },
  {
    "zip": 68952,
    "lat": 40.07521,
    "lng": -98.66779,
    "state_id": "NE"
  },
  {
    "zip": 68954,
    "lat": 40.62267,
    "lng": -98.23737,
    "state_id": "NE"
  },
  {
    "zip": 68955,
    "lat": 40.59454,
    "lng": -98.53927,
    "state_id": "NE"
  },
  {
    "zip": 68956,
    "lat": 40.63459,
    "lng": -98.69256,
    "state_id": "NE"
  },
  {
    "zip": 68957,
    "lat": 40.2665,
    "lng": -98.2372,
    "state_id": "NE"
  },
  {
    "zip": 68958,
    "lat": 40.52237,
    "lng": -99.50139,
    "state_id": "NE"
  },
  {
    "zip": 68959,
    "lat": 40.48589,
    "lng": -98.91426,
    "state_id": "NE"
  },
  {
    "zip": 68960,
    "lat": 40.10088,
    "lng": -99.13731,
    "state_id": "NE"
  },
  {
    "zip": 68961,
    "lat": 40.20518,
    "lng": -98.03851,
    "state_id": "NE"
  },
  {
    "zip": 68964,
    "lat": 40.23679,
    "lng": -97.91267,
    "state_id": "NE"
  },
  {
    "zip": 68966,
    "lat": 40.14974,
    "lng": -99.46891,
    "state_id": "NE"
  },
  {
    "zip": 68967,
    "lat": 40.25369,
    "lng": -99.62433,
    "state_id": "NE"
  },
  {
    "zip": 68969,
    "lat": 40.30383,
    "lng": -99.28345,
    "state_id": "NE"
  },
  {
    "zip": 68970,
    "lat": 40.10883,
    "lng": -98.51522,
    "state_id": "NE"
  },
  {
    "zip": 68971,
    "lat": 40.11797,
    "lng": -99.24414,
    "state_id": "NE"
  },
  {
    "zip": 68972,
    "lat": 40.10787,
    "lng": -98.80645,
    "state_id": "NE"
  },
  {
    "zip": 68973,
    "lat": 40.45228,
    "lng": -98.56397,
    "state_id": "NE"
  },
  {
    "zip": 68974,
    "lat": 40.1324,
    "lng": -97.87189,
    "state_id": "NE"
  },
  {
    "zip": 68975,
    "lat": 40.62041,
    "lng": -97.95379,
    "state_id": "NE"
  },
  {
    "zip": 68976,
    "lat": 40.58319,
    "lng": -99.72852,
    "state_id": "NE"
  },
  {
    "zip": 68977,
    "lat": 40.0699,
    "lng": -99.63076,
    "state_id": "NE"
  },
  {
    "zip": 68978,
    "lat": 40.06335,
    "lng": -98.09144,
    "state_id": "NE"
  },
  {
    "zip": 68979,
    "lat": 40.58518,
    "lng": -97.85126,
    "state_id": "NE"
  },
  {
    "zip": 68980,
    "lat": 40.68125,
    "lng": -98.25261,
    "state_id": "NE"
  },
  {
    "zip": 68981,
    "lat": 40.281,
    "lng": -98.89665,
    "state_id": "NE"
  },
  {
    "zip": 68982,
    "lat": 40.33233,
    "lng": -99.18016,
    "state_id": "NE"
  },
  {
    "zip": 69001,
    "lat": 40.24977,
    "lng": -100.64166,
    "state_id": "NE"
  },
  {
    "zip": 69020,
    "lat": 40.22083,
    "lng": -100.30358,
    "state_id": "NE"
  },
  {
    "zip": 69021,
    "lat": 40.13758,
    "lng": -101.54253,
    "state_id": "NE"
  },
  {
    "zip": 69022,
    "lat": 40.35959,
    "lng": -100.18582,
    "state_id": "NE"
  },
  {
    "zip": 69023,
    "lat": 40.48119,
    "lng": -101.90418,
    "state_id": "NE"
  },
  {
    "zip": 69024,
    "lat": 40.23235,
    "lng": -100.85151,
    "state_id": "NE"
  },
  {
    "zip": 69025,
    "lat": 40.62265,
    "lng": -100.51307,
    "state_id": "NE"
  },
  {
    "zip": 69026,
    "lat": 40.04677,
    "lng": -100.42967,
    "state_id": "NE"
  },
  {
    "zip": 69027,
    "lat": 40.42736,
    "lng": -101.53668,
    "state_id": "NE"
  },
  {
    "zip": 69028,
    "lat": 40.63536,
    "lng": -100.05634,
    "state_id": "NE"
  },
  {
    "zip": 69029,
    "lat": 40.70961,
    "lng": -100.25091,
    "state_id": "NE"
  },
  {
    "zip": 69030,
    "lat": 40.20562,
    "lng": -101.92311,
    "state_id": "NE"
  },
  {
    "zip": 69032,
    "lat": 40.55803,
    "lng": -101.02052,
    "state_id": "NE"
  },
  {
    "zip": 69033,
    "lat": 40.56053,
    "lng": -101.6785,
    "state_id": "NE"
  },
  {
    "zip": 69034,
    "lat": 40.2486,
    "lng": -100.43074,
    "state_id": "NE"
  },
  {
    "zip": 69036,
    "lat": 40.04866,
    "lng": -100.27437,
    "state_id": "NE"
  },
  {
    "zip": 69037,
    "lat": 40.19799,
    "lng": -101.41163,
    "state_id": "NE"
  },
  {
    "zip": 69038,
    "lat": 40.60865,
    "lng": -100.73986,
    "state_id": "NE"
  },
  {
    "zip": 69039,
    "lat": 40.69218,
    "lng": -100.38838,
    "state_id": "NE"
  },
  {
    "zip": 69040,
    "lat": 40.35016,
    "lng": -101.12728,
    "state_id": "NE"
  },
  {
    "zip": 69041,
    "lat": 40.17088,
    "lng": -101.73987,
    "state_id": "NE"
  },
  {
    "zip": 69042,
    "lat": 40.4767,
    "lng": -100.40524,
    "state_id": "NE"
  },
  {
    "zip": 69043,
    "lat": 40.1355,
    "lng": -101.2325,
    "state_id": "NE"
  },
  {
    "zip": 69044,
    "lat": 40.12824,
    "lng": -101.02252,
    "state_id": "NE"
  },
  {
    "zip": 69045,
    "lat": 40.47285,
    "lng": -101.37369,
    "state_id": "NE"
  },
  {
    "zip": 69046,
    "lat": 40.07638,
    "lng": -100.08654,
    "state_id": "NE"
  },
  {
    "zip": 69101,
    "lat": 41.14266,
    "lng": -100.80079,
    "state_id": "NE"
  },
  {
    "zip": 69120,
    "lat": 41.39539,
    "lng": -100.22699,
    "state_id": "NE"
  },
  {
    "zip": 69121,
    "lat": 41.5665,
    "lng": -101.69469,
    "state_id": "NE"
  },
  {
    "zip": 69122,
    "lat": 41.13007,
    "lng": -102.10258,
    "state_id": "NE"
  },
  {
    "zip": 69123,
    "lat": 41.01217,
    "lng": -100.37252,
    "state_id": "NE"
  },
  {
    "zip": 69125,
    "lat": 41.61648,
    "lng": -102.83343,
    "state_id": "NE"
  },
  {
    "zip": 69127,
    "lat": 41.12368,
    "lng": -101.91067,
    "state_id": "NE"
  },
  {
    "zip": 69128,
    "lat": 41.22199,
    "lng": -103.92135,
    "state_id": "NE"
  },
  {
    "zip": 69129,
    "lat": 41.11253,
    "lng": -102.41746,
    "state_id": "NE"
  },
  {
    "zip": 69130,
    "lat": 40.88948,
    "lng": -99.95734,
    "state_id": "NE"
  },
  {
    "zip": 69131,
    "lat": 41.40732,
    "lng": -102.97355,
    "state_id": "NE"
  },
  {
    "zip": 69132,
    "lat": 40.85684,
    "lng": -100.98323,
    "state_id": "NE"
  },
  {
    "zip": 69133,
    "lat": 41.18871,
    "lng": -103.46028,
    "state_id": "NE"
  },
  {
    "zip": 69134,
    "lat": 40.83787,
    "lng": -101.36428,
    "state_id": "NE"
  },
  {
    "zip": 69135,
    "lat": 42.24019,
    "lng": -100.25949,
    "state_id": "NE"
  },
  {
    "zip": 69138,
    "lat": 40.99858,
    "lng": -100.17417,
    "state_id": "NE"
  },
  {
    "zip": 69140,
    "lat": 40.85087,
    "lng": -101.78039,
    "state_id": "NE"
  },
  {
    "zip": 69141,
    "lat": 41.30645,
    "lng": -102.95494,
    "state_id": "NE"
  },
  {
    "zip": 69142,
    "lat": 41.91574,
    "lng": -100.3172,
    "state_id": "NE"
  },
  {
    "zip": 69143,
    "lat": 41.21012,
    "lng": -101.0392,
    "state_id": "NE"
  },
  {
    "zip": 69144,
    "lat": 41.29013,
    "lng": -101.58233,
    "state_id": "NE"
  },
  {
    "zip": 69145,
    "lat": 41.2465,
    "lng": -103.67052,
    "state_id": "NE"
  },
  {
    "zip": 69146,
    "lat": 41.34411,
    "lng": -101.8176,
    "state_id": "NE"
  },
  {
    "zip": 69147,
    "lat": 41.50657,
    "lng": -102.10098,
    "state_id": "NE"
  },
  {
    "zip": 69148,
    "lat": 41.58567,
    "lng": -102.57943,
    "state_id": "NE"
  },
  {
    "zip": 69149,
    "lat": 41.21545,
    "lng": -102.67597,
    "state_id": "NE"
  },
  {
    "zip": 69150,
    "lat": 40.84769,
    "lng": -101.54353,
    "state_id": "NE"
  },
  {
    "zip": 69151,
    "lat": 41.0982,
    "lng": -100.50409,
    "state_id": "NE"
  },
  {
    "zip": 69152,
    "lat": 42.10926,
    "lng": -101.13245,
    "state_id": "NE"
  },
  {
    "zip": 69153,
    "lat": 41.09967,
    "lng": -101.67646,
    "state_id": "NE"
  },
  {
    "zip": 69154,
    "lat": 41.43717,
    "lng": -102.34768,
    "state_id": "NE"
  },
  {
    "zip": 69155,
    "lat": 41.19466,
    "lng": -101.39565,
    "state_id": "NE"
  },
  {
    "zip": 69156,
    "lat": 41.31093,
    "lng": -103.33204,
    "state_id": "NE"
  },
  {
    "zip": 69157,
    "lat": 42.06169,
    "lng": -100.16159,
    "state_id": "NE"
  },
  {
    "zip": 69161,
    "lat": 42.13836,
    "lng": -100.7693,
    "state_id": "NE"
  },
  {
    "zip": 69162,
    "lat": 41.12789,
    "lng": -103.02521,
    "state_id": "NE"
  },
  {
    "zip": 69163,
    "lat": 41.56183,
    "lng": -100.57078,
    "state_id": "NE"
  },
  {
    "zip": 69165,
    "lat": 41.3071,
    "lng": -101.22877,
    "state_id": "NE"
  },
  {
    "zip": 69166,
    "lat": 42.07125,
    "lng": -100.56855,
    "state_id": "NE"
  },
  {
    "zip": 69167,
    "lat": 41.59477,
    "lng": -101.00348,
    "state_id": "NE"
  },
  {
    "zip": 69168,
    "lat": 40.79934,
    "lng": -101.98656,
    "state_id": "NE"
  },
  {
    "zip": 69169,
    "lat": 40.81753,
    "lng": -101.1691,
    "state_id": "NE"
  },
  {
    "zip": 69170,
    "lat": 40.79808,
    "lng": -100.7415,
    "state_id": "NE"
  },
  {
    "zip": 69171,
    "lat": 40.89006,
    "lng": -100.07072,
    "state_id": "NE"
  },
  {
    "zip": 69201,
    "lat": 42.71527,
    "lng": -100.61122,
    "state_id": "NE"
  },
  {
    "zip": 69210,
    "lat": 42.37775,
    "lng": -99.90853,
    "state_id": "NE"
  },
  {
    "zip": 69211,
    "lat": 42.82499,
    "lng": -101.29132,
    "state_id": "NE"
  },
  {
    "zip": 69212,
    "lat": 42.93681,
    "lng": -100.79259,
    "state_id": "NE"
  },
  {
    "zip": 69214,
    "lat": 42.60814,
    "lng": -100.08656,
    "state_id": "NE"
  },
  {
    "zip": 69216,
    "lat": 42.9872,
    "lng": -100.98732,
    "state_id": "NE"
  },
  {
    "zip": 69217,
    "lat": 42.38756,
    "lng": -99.71404,
    "state_id": "NE"
  },
  {
    "zip": 69218,
    "lat": 42.78883,
    "lng": -101.66748,
    "state_id": "NE"
  },
  {
    "zip": 69219,
    "lat": 42.70609,
    "lng": -101.10082,
    "state_id": "NE"
  },
  {
    "zip": 69220,
    "lat": 42.90092,
    "lng": -100.18995,
    "state_id": "NE"
  },
  {
    "zip": 69221,
    "lat": 42.59286,
    "lng": -100.3109,
    "state_id": "NE"
  },
  {
    "zip": 69301,
    "lat": 42.12051,
    "lng": -102.88314,
    "state_id": "NE"
  },
  {
    "zip": 69331,
    "lat": 41.87278,
    "lng": -102.98652,
    "state_id": "NE"
  },
  {
    "zip": 69333,
    "lat": 42.05948,
    "lng": -101.97264,
    "state_id": "NE"
  },
  {
    "zip": 69334,
    "lat": 41.82583,
    "lng": -103.28838,
    "state_id": "NE"
  },
  {
    "zip": 69335,
    "lat": 42.08291,
    "lng": -102.11388,
    "state_id": "NE"
  },
  {
    "zip": 69336,
    "lat": 41.60962,
    "lng": -103.14127,
    "state_id": "NE"
  },
  {
    "zip": 69337,
    "lat": 42.82654,
    "lng": -103.01091,
    "state_id": "NE"
  },
  {
    "zip": 69339,
    "lat": 42.59536,
    "lng": -103.40799,
    "state_id": "NE"
  },
  {
    "zip": 69340,
    "lat": 42.21349,
    "lng": -102.20804,
    "state_id": "NE"
  },
  {
    "zip": 69341,
    "lat": 41.73123,
    "lng": -103.67304,
    "state_id": "NE"
  },
  {
    "zip": 69343,
    "lat": 42.69427,
    "lng": -102.01462,
    "state_id": "NE"
  },
  {
    "zip": 69345,
    "lat": 41.5752,
    "lng": -103.74548,
    "state_id": "NE"
  },
  {
    "zip": 69346,
    "lat": 42.6874,
    "lng": -103.81099,
    "state_id": "NE"
  },
  {
    "zip": 69347,
    "lat": 42.60771,
    "lng": -102.67976,
    "state_id": "NE"
  },
  {
    "zip": 69348,
    "lat": 42.28163,
    "lng": -103.31798,
    "state_id": "NE"
  },
  {
    "zip": 69350,
    "lat": 41.97744,
    "lng": -101.73279,
    "state_id": "NE"
  },
  {
    "zip": 69351,
    "lat": 42.03702,
    "lng": -102.41872,
    "state_id": "NE"
  },
  {
    "zip": 69352,
    "lat": 41.80064,
    "lng": -103.97065,
    "state_id": "NE"
  },
  {
    "zip": 69353,
    "lat": 41.74715,
    "lng": -103.41677,
    "state_id": "NE"
  },
  {
    "zip": 69354,
    "lat": 42.43668,
    "lng": -103.40774,
    "state_id": "NE"
  },
  {
    "zip": 69355,
    "lat": 41.78298,
    "lng": -103.50793,
    "state_id": "NE"
  },
  {
    "zip": 69356,
    "lat": 41.89476,
    "lng": -103.45129,
    "state_id": "NE"
  },
  {
    "zip": 69357,
    "lat": 42.07536,
    "lng": -103.81427,
    "state_id": "NE"
  },
  {
    "zip": 69358,
    "lat": 42.06784,
    "lng": -103.96709,
    "state_id": "NE"
  },
  {
    "zip": 69360,
    "lat": 42.69945,
    "lng": -102.47171,
    "state_id": "NE"
  },
  {
    "zip": 69361,
    "lat": 41.9246,
    "lng": -103.62167,
    "state_id": "NE"
  },
  {
    "zip": 69365,
    "lat": 42.9686,
    "lng": -102.51041,
    "state_id": "NE"
  },
  {
    "zip": 69366,
    "lat": 42.15757,
    "lng": -101.55442,
    "state_id": "NE"
  },
  {
    "zip": 69367,
    "lat": 42.81616,
    "lng": -103.32848,
    "state_id": "NE"
  },
  {
    "zip": 3031,
    "lat": 42.87036,
    "lng": -71.6079,
    "state_id": "NH"
  },
  {
    "zip": 3032,
    "lat": 42.9906,
    "lng": -71.34379,
    "state_id": "NH"
  },
  {
    "zip": 3033,
    "lat": 42.74641,
    "lng": -71.67057,
    "state_id": "NH"
  },
  {
    "zip": 3034,
    "lat": 43.06357,
    "lng": -71.31141,
    "state_id": "NH"
  },
  {
    "zip": 3036,
    "lat": 42.96717,
    "lng": -71.25088,
    "state_id": "NH"
  },
  {
    "zip": 3037,
    "lat": 43.13975,
    "lng": -71.25262,
    "state_id": "NH"
  },
  {
    "zip": 3038,
    "lat": 42.88877,
    "lng": -71.27988,
    "state_id": "NH"
  },
  {
    "zip": 3042,
    "lat": 43.05005,
    "lng": -71.07478,
    "state_id": "NH"
  },
  {
    "zip": 3043,
    "lat": 42.99406,
    "lng": -71.81349,
    "state_id": "NH"
  },
  {
    "zip": 3044,
    "lat": 42.98868,
    "lng": -71.12527,
    "state_id": "NH"
  },
  {
    "zip": 3045,
    "lat": 43.01995,
    "lng": -71.57006,
    "state_id": "NH"
  },
  {
    "zip": 3046,
    "lat": 43.10184,
    "lng": -71.60561,
    "state_id": "NH"
  },
  {
    "zip": 3047,
    "lat": 42.94007,
    "lng": -71.87021,
    "state_id": "NH"
  },
  {
    "zip": 3048,
    "lat": 42.74824,
    "lng": -71.76183,
    "state_id": "NH"
  },
  {
    "zip": 3049,
    "lat": 42.74942,
    "lng": -71.58344,
    "state_id": "NH"
  },
  {
    "zip": 3051,
    "lat": 42.76392,
    "lng": -71.40709,
    "state_id": "NH"
  },
  {
    "zip": 3052,
    "lat": 42.84285,
    "lng": -71.455,
    "state_id": "NH"
  },
  {
    "zip": 3053,
    "lat": 42.8786,
    "lng": -71.38633,
    "state_id": "NH"
  },
  {
    "zip": 3054,
    "lat": 42.85469,
    "lng": -71.51884,
    "state_id": "NH"
  },
  {
    "zip": 3055,
    "lat": 42.81745,
    "lng": -71.6734,
    "state_id": "NH"
  },
  {
    "zip": 3057,
    "lat": 42.90244,
    "lng": -71.68336,
    "state_id": "NH"
  },
  {
    "zip": 3060,
    "lat": 42.74127,
    "lng": -71.45927,
    "state_id": "NH"
  },
  {
    "zip": 3062,
    "lat": 42.72218,
    "lng": -71.49566,
    "state_id": "NH"
  },
  {
    "zip": 3063,
    "lat": 42.77951,
    "lng": -71.51939,
    "state_id": "NH"
  },
  {
    "zip": 3064,
    "lat": 42.77879,
    "lng": -71.47462,
    "state_id": "NH"
  },
  {
    "zip": 3070,
    "lat": 42.97734,
    "lng": -71.68637,
    "state_id": "NH"
  },
  {
    "zip": 3071,
    "lat": 42.74893,
    "lng": -71.87471,
    "state_id": "NH"
  },
  {
    "zip": 3076,
    "lat": 42.7335,
    "lng": -71.32391,
    "state_id": "NH"
  },
  {
    "zip": 3077,
    "lat": 43.03293,
    "lng": -71.19804,
    "state_id": "NH"
  },
  {
    "zip": 3079,
    "lat": 42.79023,
    "lng": -71.22023,
    "state_id": "NH"
  },
  {
    "zip": 3082,
    "lat": 42.90024,
    "lng": -71.77423,
    "state_id": "NH"
  },
  {
    "zip": 3084,
    "lat": 42.82853,
    "lng": -71.85496,
    "state_id": "NH"
  },
  {
    "zip": 3086,
    "lat": 42.82935,
    "lng": -71.77106,
    "state_id": "NH"
  },
  {
    "zip": 3087,
    "lat": 42.80759,
    "lng": -71.29945,
    "state_id": "NH"
  },
  {
    "zip": 3101,
    "lat": 42.98892,
    "lng": -71.46588,
    "state_id": "NH"
  },
  {
    "zip": 3102,
    "lat": 43.00798,
    "lng": -71.49129,
    "state_id": "NH"
  },
  {
    "zip": 3103,
    "lat": 42.95352,
    "lng": -71.44429,
    "state_id": "NH"
  },
  {
    "zip": 3104,
    "lat": 43.00934,
    "lng": -71.44145,
    "state_id": "NH"
  },
  {
    "zip": 3106,
    "lat": 43.07112,
    "lng": -71.43637,
    "state_id": "NH"
  },
  {
    "zip": 3109,
    "lat": 42.96625,
    "lng": -71.40168,
    "state_id": "NH"
  },
  {
    "zip": 3110,
    "lat": 42.94066,
    "lng": -71.53028,
    "state_id": "NH"
  },
  {
    "zip": 3215,
    "lat": 43.9453,
    "lng": -71.45282,
    "state_id": "NH"
  },
  {
    "zip": 3216,
    "lat": 43.44563,
    "lng": -71.79571,
    "state_id": "NH"
  },
  {
    "zip": 3217,
    "lat": 43.7153,
    "lng": -71.63091,
    "state_id": "NH"
  },
  {
    "zip": 3218,
    "lat": 43.33463,
    "lng": -71.28921,
    "state_id": "NH"
  },
  {
    "zip": 3220,
    "lat": 43.46768,
    "lng": -71.47519,
    "state_id": "NH"
  },
  {
    "zip": 3221,
    "lat": 43.25179,
    "lng": -71.96171,
    "state_id": "NH"
  },
  {
    "zip": 3222,
    "lat": 43.6275,
    "lng": -71.78286,
    "state_id": "NH"
  },
  {
    "zip": 3223,
    "lat": 43.84968,
    "lng": -71.68155,
    "state_id": "NH"
  },
  {
    "zip": 3224,
    "lat": 43.34986,
    "lng": -71.55093,
    "state_id": "NH"
  },
  {
    "zip": 3225,
    "lat": 43.35839,
    "lng": -71.23805,
    "state_id": "NH"
  },
  {
    "zip": 3226,
    "lat": 43.70403,
    "lng": -71.51126,
    "state_id": "NH"
  },
  {
    "zip": 3227,
    "lat": 43.82774,
    "lng": -71.4678,
    "state_id": "NH"
  },
  {
    "zip": 3229,
    "lat": 43.19787,
    "lng": -71.69678,
    "state_id": "NH"
  },
  {
    "zip": 3230,
    "lat": 43.52599,
    "lng": -71.87149,
    "state_id": "NH"
  },
  {
    "zip": 3231,
    "lat": 43.46656,
    "lng": -71.75669,
    "state_id": "NH"
  },
  {
    "zip": 3233,
    "lat": 43.4252,
    "lng": -71.93316,
    "state_id": "NH"
  },
  {
    "zip": 3234,
    "lat": 43.21491,
    "lng": -71.33907,
    "state_id": "NH"
  },
  {
    "zip": 3235,
    "lat": 43.44838,
    "lng": -71.67183,
    "state_id": "NH"
  },
  {
    "zip": 3237,
    "lat": 43.4296,
    "lng": -71.38212,
    "state_id": "NH"
  },
  {
    "zip": 3238,
    "lat": 43.98288,
    "lng": -71.89868,
    "state_id": "NH"
  },
  {
    "zip": 3240,
    "lat": 43.57599,
    "lng": -71.97223,
    "state_id": "NH"
  },
  {
    "zip": 3241,
    "lat": 43.7217,
    "lng": -71.81986,
    "state_id": "NH"
  },
  {
    "zip": 3242,
    "lat": 43.1736,
    "lng": -71.82244,
    "state_id": "NH"
  },
  {
    "zip": 3243,
    "lat": 43.52493,
    "lng": -71.76467,
    "state_id": "NH"
  },
  {
    "zip": 3244,
    "lat": 43.11797,
    "lng": -71.91877,
    "state_id": "NH"
  },
  {
    "zip": 3245,
    "lat": 43.75003,
    "lng": -71.58789,
    "state_id": "NH"
  },
  {
    "zip": 3246,
    "lat": 43.57267,
    "lng": -71.47744,
    "state_id": "NH"
  },
  {
    "zip": 3249,
    "lat": 43.55791,
    "lng": -71.38502,
    "state_id": "NH"
  },
  {
    "zip": 3251,
    "lat": 44.08544,
    "lng": -71.57272,
    "state_id": "NH"
  },
  {
    "zip": 3253,
    "lat": 43.63072,
    "lng": -71.49963,
    "state_id": "NH"
  },
  {
    "zip": 3254,
    "lat": 43.72491,
    "lng": -71.37776,
    "state_id": "NH"
  },
  {
    "zip": 3255,
    "lat": 43.31479,
    "lng": -72.02625,
    "state_id": "NH"
  },
  {
    "zip": 3256,
    "lat": 43.62434,
    "lng": -71.63353,
    "state_id": "NH"
  },
  {
    "zip": 3257,
    "lat": 43.41691,
    "lng": -71.9906,
    "state_id": "NH"
  },
  {
    "zip": 3258,
    "lat": 43.25754,
    "lng": -71.39917,
    "state_id": "NH"
  },
  {
    "zip": 3259,
    "lat": 43.87212,
    "lng": -71.39649,
    "state_id": "NH"
  },
  {
    "zip": 3260,
    "lat": 43.35365,
    "lng": -71.92174,
    "state_id": "NH"
  },
  {
    "zip": 3261,
    "lat": 43.21341,
    "lng": -71.20966,
    "state_id": "NH"
  },
  {
    "zip": 3262,
    "lat": 43.99914,
    "lng": -71.73805,
    "state_id": "NH"
  },
  {
    "zip": 3263,
    "lat": 43.29468,
    "lng": -71.311,
    "state_id": "NH"
  },
  {
    "zip": 3264,
    "lat": 43.72976,
    "lng": -71.71127,
    "state_id": "NH"
  },
  {
    "zip": 3266,
    "lat": 43.79034,
    "lng": -71.89609,
    "state_id": "NH"
  },
  {
    "zip": 3268,
    "lat": 43.38648,
    "lng": -71.75656,
    "state_id": "NH"
  },
  {
    "zip": 3269,
    "lat": 43.52789,
    "lng": -71.60144,
    "state_id": "NH"
  },
  {
    "zip": 3273,
    "lat": 43.29903,
    "lng": -71.93208,
    "state_id": "NH"
  },
  {
    "zip": 3275,
    "lat": 43.16019,
    "lng": -71.41794,
    "state_id": "NH"
  },
  {
    "zip": 3276,
    "lat": 43.42788,
    "lng": -71.57503,
    "state_id": "NH"
  },
  {
    "zip": 3278,
    "lat": 43.29331,
    "lng": -71.82605,
    "state_id": "NH"
  },
  {
    "zip": 3279,
    "lat": 43.94318,
    "lng": -71.88139,
    "state_id": "NH"
  },
  {
    "zip": 3280,
    "lat": 43.18298,
    "lng": -72.08925,
    "state_id": "NH"
  },
  {
    "zip": 3281,
    "lat": 43.08126,
    "lng": -71.72208,
    "state_id": "NH"
  },
  {
    "zip": 3282,
    "lat": 43.86015,
    "lng": -71.92935,
    "state_id": "NH"
  },
  {
    "zip": 3284,
    "lat": 43.49281,
    "lng": -72.03075,
    "state_id": "NH"
  },
  {
    "zip": 3285,
    "lat": 43.92411,
    "lng": -71.64361,
    "state_id": "NH"
  },
  {
    "zip": 3287,
    "lat": 43.44516,
    "lng": -71.91669,
    "state_id": "NH"
  },
  {
    "zip": 3290,
    "lat": 43.12457,
    "lng": -71.12081,
    "state_id": "NH"
  },
  {
    "zip": 3291,
    "lat": 43.17918,
    "lng": -71.1427,
    "state_id": "NH"
  },
  {
    "zip": 3293,
    "lat": 43.97009,
    "lng": -71.67827,
    "state_id": "NH"
  },
  {
    "zip": 3301,
    "lat": 43.22434,
    "lng": -71.54337,
    "state_id": "NH"
  },
  {
    "zip": 3303,
    "lat": 43.29814,
    "lng": -71.67194,
    "state_id": "NH"
  },
  {
    "zip": 3304,
    "lat": 43.13079,
    "lng": -71.53069,
    "state_id": "NH"
  },
  {
    "zip": 3307,
    "lat": 43.32538,
    "lng": -71.44364,
    "state_id": "NH"
  },
  {
    "zip": 3431,
    "lat": 42.96265,
    "lng": -72.29556,
    "state_id": "NH"
  },
  {
    "zip": 3440,
    "lat": 43.05408,
    "lng": -71.98229,
    "state_id": "NH"
  },
  {
    "zip": 3441,
    "lat": 42.77998,
    "lng": -72.43849,
    "state_id": "NH"
  },
  {
    "zip": 3442,
    "lat": 43.01242,
    "lng": -71.90818,
    "state_id": "NH"
  },
  {
    "zip": 3443,
    "lat": 42.87709,
    "lng": -72.46158,
    "state_id": "NH"
  },
  {
    "zip": 3444,
    "lat": 42.89433,
    "lng": -72.07224,
    "state_id": "NH"
  },
  {
    "zip": 3445,
    "lat": 43.01434,
    "lng": -72.213,
    "state_id": "NH"
  },
  {
    "zip": 3446,
    "lat": 42.86048,
    "lng": -72.29758,
    "state_id": "NH"
  },
  {
    "zip": 3447,
    "lat": 42.75901,
    "lng": -72.14848,
    "state_id": "NH"
  },
  {
    "zip": 3448,
    "lat": 43.03964,
    "lng": -72.27194,
    "state_id": "NH"
  },
  {
    "zip": 3449,
    "lat": 42.97584,
    "lng": -71.99579,
    "state_id": "NH"
  },
  {
    "zip": 3450,
    "lat": 42.94158,
    "lng": -72.09127,
    "state_id": "NH"
  },
  {
    "zip": 3451,
    "lat": 42.80751,
    "lng": -72.49863,
    "state_id": "NH"
  },
  {
    "zip": 3452,
    "lat": 42.82928,
    "lng": -72.05965,
    "state_id": "NH"
  },
  {
    "zip": 3455,
    "lat": 42.89841,
    "lng": -72.18349,
    "state_id": "NH"
  },
  {
    "zip": 3456,
    "lat": 43.13096,
    "lng": -72.21499,
    "state_id": "NH"
  },
  {
    "zip": 3457,
    "lat": 42.99585,
    "lng": -72.12252,
    "state_id": "NH"
  },
  {
    "zip": 3458,
    "lat": 42.86906,
    "lng": -71.93782,
    "state_id": "NH"
  },
  {
    "zip": 3461,
    "lat": 42.75237,
    "lng": -72.01072,
    "state_id": "NH"
  },
  {
    "zip": 3462,
    "lat": 42.8955,
    "lng": -72.40479,
    "state_id": "NH"
  },
  {
    "zip": 3464,
    "lat": 43.07926,
    "lng": -72.1142,
    "state_id": "NH"
  },
  {
    "zip": 3465,
    "lat": 42.82723,
    "lng": -72.19011,
    "state_id": "NH"
  },
  {
    "zip": 3466,
    "lat": 42.89558,
    "lng": -72.50974,
    "state_id": "NH"
  },
  {
    "zip": 3467,
    "lat": 42.96935,
    "lng": -72.43221,
    "state_id": "NH"
  },
  {
    "zip": 3470,
    "lat": 42.77883,
    "lng": -72.34417,
    "state_id": "NH"
  },
  {
    "zip": 3561,
    "lat": 44.33165,
    "lng": -71.81169,
    "state_id": "NH"
  },
  {
    "zip": 3570,
    "lat": 44.49905,
    "lng": -71.15716,
    "state_id": "NH"
  },
  {
    "zip": 3574,
    "lat": 44.25344,
    "lng": -71.59886,
    "state_id": "NH"
  },
  {
    "zip": 3575,
    "lat": 44.29607,
    "lng": -71.42372,
    "state_id": "NH"
  },
  {
    "zip": 3576,
    "lat": 44.91614,
    "lng": -71.38478,
    "state_id": "NH"
  },
  {
    "zip": 3579,
    "lat": 44.96868,
    "lng": -71.03296,
    "state_id": "NH"
  },
  {
    "zip": 3580,
    "lat": 44.16777,
    "lng": -71.70147,
    "state_id": "NH"
  },
  {
    "zip": 3581,
    "lat": 44.33931,
    "lng": -71.13252,
    "state_id": "NH"
  },
  {
    "zip": 3582,
    "lat": 44.59552,
    "lng": -71.43867,
    "state_id": "NH"
  },
  {
    "zip": 3583,
    "lat": 44.39677,
    "lng": -71.46472,
    "state_id": "NH"
  },
  {
    "zip": 3584,
    "lat": 44.48489,
    "lng": -71.54566,
    "state_id": "NH"
  },
  {
    "zip": 3585,
    "lat": 44.22063,
    "lng": -71.89455,
    "state_id": "NH"
  },
  {
    "zip": 3586,
    "lat": 44.22036,
    "lng": -71.79641,
    "state_id": "NH"
  },
  {
    "zip": 3588,
    "lat": 44.61213,
    "lng": -71.20771,
    "state_id": "NH"
  },
  {
    "zip": 3590,
    "lat": 44.74767,
    "lng": -71.51821,
    "state_id": "NH"
  },
  {
    "zip": 3592,
    "lat": 45.12296,
    "lng": -71.26298,
    "state_id": "NH"
  },
  {
    "zip": 3593,
    "lat": 44.31658,
    "lng": -71.30374,
    "state_id": "NH"
  },
  {
    "zip": 3595,
    "lat": 44.30622,
    "lng": -71.5268,
    "state_id": "NH"
  },
  {
    "zip": 3597,
    "lat": 44.73595,
    "lng": -71.39278,
    "state_id": "NH"
  },
  {
    "zip": 3598,
    "lat": 44.37976,
    "lng": -71.63225,
    "state_id": "NH"
  },
  {
    "zip": 3601,
    "lat": 43.2338,
    "lng": -72.29576,
    "state_id": "NH"
  },
  {
    "zip": 3602,
    "lat": 43.13384,
    "lng": -72.33036,
    "state_id": "NH"
  },
  {
    "zip": 3603,
    "lat": 43.25149,
    "lng": -72.38134,
    "state_id": "NH"
  },
  {
    "zip": 3604,
    "lat": 43.128,
    "lng": -72.37984,
    "state_id": "NH"
  },
  {
    "zip": 3605,
    "lat": 43.22739,
    "lng": -72.1814,
    "state_id": "NH"
  },
  {
    "zip": 3607,
    "lat": 43.19552,
    "lng": -72.26883,
    "state_id": "NH"
  },
  {
    "zip": 3608,
    "lat": 43.07067,
    "lng": -72.40643,
    "state_id": "NH"
  },
  {
    "zip": 3609,
    "lat": 43.1446,
    "lng": -72.44526,
    "state_id": "NH"
  },
  {
    "zip": 3740,
    "lat": 44.18086,
    "lng": -71.98272,
    "state_id": "NH"
  },
  {
    "zip": 3741,
    "lat": 43.67188,
    "lng": -72.01584,
    "state_id": "NH"
  },
  {
    "zip": 3743,
    "lat": 43.35826,
    "lng": -72.3253,
    "state_id": "NH"
  },
  {
    "zip": 3745,
    "lat": 43.47011,
    "lng": -72.33239,
    "state_id": "NH"
  },
  {
    "zip": 3746,
    "lat": 43.49432,
    "lng": -72.2574,
    "state_id": "NH"
  },
  {
    "zip": 3748,
    "lat": 43.59369,
    "lng": -72.1139,
    "state_id": "NH"
  },
  {
    "zip": 3750,
    "lat": 43.7076,
    "lng": -72.20263,
    "state_id": "NH"
  },
  {
    "zip": 3751,
    "lat": 43.44896,
    "lng": -72.08876,
    "state_id": "NH"
  },
  {
    "zip": 3752,
    "lat": 43.28921,
    "lng": -72.11523,
    "state_id": "NH"
  },
  {
    "zip": 3753,
    "lat": 43.51701,
    "lng": -72.14972,
    "state_id": "NH"
  },
  {
    "zip": 3754,
    "lat": 43.37746,
    "lng": -72.13839,
    "state_id": "NH"
  },
  {
    "zip": 3755,
    "lat": 43.71777,
    "lng": -72.18929,
    "state_id": "NH"
  },
  {
    "zip": 3765,
    "lat": 44.0343,
    "lng": -72.05286,
    "state_id": "NH"
  },
  {
    "zip": 3766,
    "lat": 43.63467,
    "lng": -72.23724,
    "state_id": "NH"
  },
  {
    "zip": 3768,
    "lat": 43.80527,
    "lng": -72.11572,
    "state_id": "NH"
  },
  {
    "zip": 3770,
    "lat": 43.53406,
    "lng": -72.26647,
    "state_id": "NH"
  },
  {
    "zip": 3771,
    "lat": 44.28502,
    "lng": -72.00934,
    "state_id": "NH"
  },
  {
    "zip": 3773,
    "lat": 43.38523,
    "lng": -72.19935,
    "state_id": "NH"
  },
  {
    "zip": 3774,
    "lat": 44.08904,
    "lng": -71.99508,
    "state_id": "NH"
  },
  {
    "zip": 3777,
    "lat": 43.89512,
    "lng": -72.06962,
    "state_id": "NH"
  },
  {
    "zip": 3779,
    "lat": 43.97237,
    "lng": -72.02873,
    "state_id": "NH"
  },
  {
    "zip": 3780,
    "lat": 44.03228,
    "lng": -71.97743,
    "state_id": "NH"
  },
  {
    "zip": 3781,
    "lat": 43.55276,
    "lng": -72.28405,
    "state_id": "NH"
  },
  {
    "zip": 3782,
    "lat": 43.39093,
    "lng": -72.09279,
    "state_id": "NH"
  },
  {
    "zip": 3784,
    "lat": 43.6368,
    "lng": -72.30594,
    "state_id": "NH"
  },
  {
    "zip": 3785,
    "lat": 44.06393,
    "lng": -71.89424,
    "state_id": "NH"
  },
  {
    "zip": 3801,
    "lat": 43.07292,
    "lng": -70.8052,
    "state_id": "NH"
  },
  {
    "zip": 3809,
    "lat": 43.45369,
    "lng": -71.21237,
    "state_id": "NH"
  },
  {
    "zip": 3810,
    "lat": 43.52705,
    "lng": -71.28103,
    "state_id": "NH"
  },
  {
    "zip": 3811,
    "lat": 42.83696,
    "lng": -71.16039,
    "state_id": "NH"
  },
  {
    "zip": 3812,
    "lat": 44.0806,
    "lng": -71.29205,
    "state_id": "NH"
  },
  {
    "zip": 3813,
    "lat": 44.09738,
    "lng": -71.04513,
    "state_id": "NH"
  },
  {
    "zip": 3814,
    "lat": 43.76919,
    "lng": -71.18478,
    "state_id": "NH"
  },
  {
    "zip": 3816,
    "lat": 43.68469,
    "lng": -71.25517,
    "state_id": "NH"
  },
  {
    "zip": 3817,
    "lat": 43.88741,
    "lng": -71.23211,
    "state_id": "NH"
  },
  {
    "zip": 3818,
    "lat": 43.97359,
    "lng": -71.25003,
    "state_id": "NH"
  },
  {
    "zip": 3819,
    "lat": 42.92842,
    "lng": -71.12117,
    "state_id": "NH"
  },
  {
    "zip": 3820,
    "lat": 43.18879,
    "lng": -70.88678,
    "state_id": "NH"
  },
  {
    "zip": 3823,
    "lat": 43.17288,
    "lng": -70.94094,
    "state_id": "NH"
  },
  {
    "zip": 3824,
    "lat": 43.11744,
    "lng": -70.91946,
    "state_id": "NH"
  },
  {
    "zip": 3825,
    "lat": 43.21357,
    "lng": -71.04284,
    "state_id": "NH"
  },
  {
    "zip": 3826,
    "lat": 42.88621,
    "lng": -71.13378,
    "state_id": "NH"
  },
  {
    "zip": 3827,
    "lat": 42.90685,
    "lng": -70.98774,
    "state_id": "NH"
  },
  {
    "zip": 3830,
    "lat": 43.63391,
    "lng": -71.00259,
    "state_id": "NH"
  },
  {
    "zip": 3832,
    "lat": 43.90307,
    "lng": -71.04786,
    "state_id": "NH"
  },
  {
    "zip": 3833,
    "lat": 42.97477,
    "lng": -70.98917,
    "state_id": "NH"
  },
  {
    "zip": 3835,
    "lat": 43.36275,
    "lng": -71.07607,
    "state_id": "NH"
  },
  {
    "zip": 3836,
    "lat": 43.82621,
    "lng": -71.07147,
    "state_id": "NH"
  },
  {
    "zip": 3837,
    "lat": 43.42165,
    "lng": -71.32909,
    "state_id": "NH"
  },
  {
    "zip": 3838,
    "lat": 44.11231,
    "lng": -71.24091,
    "state_id": "NH"
  },
  {
    "zip": 3839,
    "lat": 43.26199,
    "lng": -70.98373,
    "state_id": "NH"
  },
  {
    "zip": 3840,
    "lat": 43.0385,
    "lng": -70.8481,
    "state_id": "NH"
  },
  {
    "zip": 3841,
    "lat": 42.88314,
    "lng": -71.1809,
    "state_id": "NH"
  },
  {
    "zip": 3842,
    "lat": 42.93963,
    "lng": -70.8371,
    "state_id": "NH"
  },
  {
    "zip": 3844,
    "lat": 42.92705,
    "lng": -70.88586,
    "state_id": "NH"
  },
  {
    "zip": 3845,
    "lat": 44.10676,
    "lng": -71.12499,
    "state_id": "NH"
  },
  {
    "zip": 3846,
    "lat": 44.1853,
    "lng": -71.19065,
    "state_id": "NH"
  },
  {
    "zip": 3847,
    "lat": 44.07005,
    "lng": -71.11317,
    "state_id": "NH"
  },
  {
    "zip": 3848,
    "lat": 42.9156,
    "lng": -71.0665,
    "state_id": "NH"
  },
  {
    "zip": 3849,
    "lat": 43.90044,
    "lng": -71.13378,
    "state_id": "NH"
  },
  {
    "zip": 3850,
    "lat": 43.69273,
    "lng": -71.302,
    "state_id": "NH"
  },
  {
    "zip": 3851,
    "lat": 43.4361,
    "lng": -71.01649,
    "state_id": "NH"
  },
  {
    "zip": 3852,
    "lat": 43.4982,
    "lng": -70.97547,
    "state_id": "NH"
  },
  {
    "zip": 3853,
    "lat": 43.63952,
    "lng": -71.27902,
    "state_id": "NH"
  },
  {
    "zip": 3854,
    "lat": 43.06559,
    "lng": -70.72005,
    "state_id": "NH"
  },
  {
    "zip": 3855,
    "lat": 43.46363,
    "lng": -71.14194,
    "state_id": "NH"
  },
  {
    "zip": 3856,
    "lat": 43.03923,
    "lng": -70.96629,
    "state_id": "NH"
  },
  {
    "zip": 3857,
    "lat": 43.06913,
    "lng": -70.95182,
    "state_id": "NH"
  },
  {
    "zip": 3858,
    "lat": 42.8684,
    "lng": -71.04356,
    "state_id": "NH"
  },
  {
    "zip": 3860,
    "lat": 44.04206,
    "lng": -71.1199,
    "state_id": "NH"
  },
  {
    "zip": 3861,
    "lat": 43.11969,
    "lng": -71.00682,
    "state_id": "NH"
  },
  {
    "zip": 3862,
    "lat": 42.97904,
    "lng": -70.83024,
    "state_id": "NH"
  },
  {
    "zip": 3864,
    "lat": 43.69143,
    "lng": -71.10883,
    "state_id": "NH"
  },
  {
    "zip": 3865,
    "lat": 42.84028,
    "lng": -71.09606,
    "state_id": "NH"
  },
  {
    "zip": 3867,
    "lat": 43.30327,
    "lng": -70.98691,
    "state_id": "NH"
  },
  {
    "zip": 3868,
    "lat": 43.32027,
    "lng": -70.94164,
    "state_id": "NH"
  },
  {
    "zip": 3869,
    "lat": 43.22066,
    "lng": -70.84199,
    "state_id": "NH"
  },
  {
    "zip": 3870,
    "lat": 43.0152,
    "lng": -70.76077,
    "state_id": "NH"
  },
  {
    "zip": 3871,
    "lat": 42.98222,
    "lng": -70.77484,
    "state_id": "NH"
  },
  {
    "zip": 3872,
    "lat": 43.5697,
    "lng": -71.045,
    "state_id": "NH"
  },
  {
    "zip": 3873,
    "lat": 42.93399,
    "lng": -71.18209,
    "state_id": "NH"
  },
  {
    "zip": 3874,
    "lat": 42.88708,
    "lng": -70.86067,
    "state_id": "NH"
  },
  {
    "zip": 3875,
    "lat": 43.89547,
    "lng": -71.18603,
    "state_id": "NH"
  },
  {
    "zip": 3878,
    "lat": 43.25288,
    "lng": -70.88522,
    "state_id": "NH"
  },
  {
    "zip": 3882,
    "lat": 43.74565,
    "lng": -71.0422,
    "state_id": "NH"
  },
  {
    "zip": 3883,
    "lat": 43.80765,
    "lng": -71.30773,
    "state_id": "NH"
  },
  {
    "zip": 3884,
    "lat": 43.2797,
    "lng": -71.14834,
    "state_id": "NH"
  },
  {
    "zip": 3885,
    "lat": 43.01569,
    "lng": -70.90062,
    "state_id": "NH"
  },
  {
    "zip": 3886,
    "lat": 43.86243,
    "lng": -71.28686,
    "state_id": "NH"
  },
  {
    "zip": 3887,
    "lat": 43.48597,
    "lng": -71.06265,
    "state_id": "NH"
  },
  {
    "zip": 3890,
    "lat": 43.80809,
    "lng": -71.19664,
    "state_id": "NH"
  },
  {
    "zip": 3894,
    "lat": 43.61213,
    "lng": -71.17163,
    "state_id": "NH"
  },
  {
    "zip": 7001,
    "lat": 40.58338,
    "lng": -74.27004,
    "state_id": "NJ"
  },
  {
    "zip": 7002,
    "lat": 40.66464,
    "lng": -74.10867,
    "state_id": "NJ"
  },
  {
    "zip": 7003,
    "lat": 40.80999,
    "lng": -74.18679,
    "state_id": "NJ"
  },
  {
    "zip": 7004,
    "lat": 40.88278,
    "lng": -74.30415,
    "state_id": "NJ"
  },
  {
    "zip": 7005,
    "lat": 40.92947,
    "lng": -74.421,
    "state_id": "NJ"
  },
  {
    "zip": 7006,
    "lat": 40.85215,
    "lng": -74.28167,
    "state_id": "NJ"
  },
  {
    "zip": 7008,
    "lat": 40.58474,
    "lng": -74.22843,
    "state_id": "NJ"
  },
  {
    "zip": 7009,
    "lat": 40.85651,
    "lng": -74.22874,
    "state_id": "NJ"
  },
  {
    "zip": 7010,
    "lat": 40.82211,
    "lng": -73.98794,
    "state_id": "NJ"
  },
  {
    "zip": 7011,
    "lat": 40.87848,
    "lng": -74.14478,
    "state_id": "NJ"
  },
  {
    "zip": 7012,
    "lat": 40.84745,
    "lng": -74.15983,
    "state_id": "NJ"
  },
  {
    "zip": 7013,
    "lat": 40.8691,
    "lng": -74.17277,
    "state_id": "NJ"
  },
  {
    "zip": 7014,
    "lat": 40.83149,
    "lng": -74.1355,
    "state_id": "NJ"
  },
  {
    "zip": 7016,
    "lat": 40.65643,
    "lng": -74.30357,
    "state_id": "NJ"
  },
  {
    "zip": 7017,
    "lat": 40.77217,
    "lng": -74.20708,
    "state_id": "NJ"
  },
  {
    "zip": 7018,
    "lat": 40.75588,
    "lng": -74.21778,
    "state_id": "NJ"
  },
  {
    "zip": 7020,
    "lat": 40.82366,
    "lng": -73.97397,
    "state_id": "NJ"
  },
  {
    "zip": 7021,
    "lat": 40.82686,
    "lng": -74.27973,
    "state_id": "NJ"
  },
  {
    "zip": 7022,
    "lat": 40.81818,
    "lng": -74.00221,
    "state_id": "NJ"
  },
  {
    "zip": 7023,
    "lat": 40.64173,
    "lng": -74.38568,
    "state_id": "NJ"
  },
  {
    "zip": 7024,
    "lat": 40.8508,
    "lng": -73.97123,
    "state_id": "NJ"
  },
  {
    "zip": 7026,
    "lat": 40.87859,
    "lng": -74.10813,
    "state_id": "NJ"
  },
  {
    "zip": 7027,
    "lat": 40.65138,
    "lng": -74.32312,
    "state_id": "NJ"
  },
  {
    "zip": 7028,
    "lat": 40.80407,
    "lng": -74.20433,
    "state_id": "NJ"
  },
  {
    "zip": 7029,
    "lat": 40.7437,
    "lng": -74.15396,
    "state_id": "NJ"
  },
  {
    "zip": 7030,
    "lat": 40.74522,
    "lng": -74.03217,
    "state_id": "NJ"
  },
  {
    "zip": 7031,
    "lat": 40.78748,
    "lng": -74.12719,
    "state_id": "NJ"
  },
  {
    "zip": 7032,
    "lat": 40.75373,
    "lng": -74.12032,
    "state_id": "NJ"
  },
  {
    "zip": 7033,
    "lat": 40.67811,
    "lng": -74.28901,
    "state_id": "NJ"
  },
  {
    "zip": 7034,
    "lat": 40.87968,
    "lng": -74.37989,
    "state_id": "NJ"
  },
  {
    "zip": 7035,
    "lat": 40.92535,
    "lng": -74.30489,
    "state_id": "NJ"
  },
  {
    "zip": 7036,
    "lat": 40.62524,
    "lng": -74.23892,
    "state_id": "NJ"
  },
  {
    "zip": 7039,
    "lat": 40.7855,
    "lng": -74.32908,
    "state_id": "NJ"
  },
  {
    "zip": 7040,
    "lat": 40.73061,
    "lng": -74.26963,
    "state_id": "NJ"
  },
  {
    "zip": 7041,
    "lat": 40.72308,
    "lng": -74.30068,
    "state_id": "NJ"
  },
  {
    "zip": 7042,
    "lat": 40.81369,
    "lng": -74.21768,
    "state_id": "NJ"
  },
  {
    "zip": 7043,
    "lat": 40.84431,
    "lng": -74.20045,
    "state_id": "NJ"
  },
  {
    "zip": 7044,
    "lat": 40.83223,
    "lng": -74.24291,
    "state_id": "NJ"
  },
  {
    "zip": 7045,
    "lat": 40.91072,
    "lng": -74.36767,
    "state_id": "NJ"
  },
  {
    "zip": 7046,
    "lat": 40.89064,
    "lng": -74.44053,
    "state_id": "NJ"
  },
  {
    "zip": 7047,
    "lat": 40.79358,
    "lng": -74.02613,
    "state_id": "NJ"
  },
  {
    "zip": 7050,
    "lat": 40.76817,
    "lng": -74.23485,
    "state_id": "NJ"
  },
  {
    "zip": 7052,
    "lat": 40.7893,
    "lng": -74.26283,
    "state_id": "NJ"
  },
  {
    "zip": 7054,
    "lat": 40.85565,
    "lng": -74.40225,
    "state_id": "NJ"
  },
  {
    "zip": 7055,
    "lat": 40.8575,
    "lng": -74.12821,
    "state_id": "NJ"
  },
  {
    "zip": 7057,
    "lat": 40.85349,
    "lng": -74.10674,
    "state_id": "NJ"
  },
  {
    "zip": 7058,
    "lat": 40.86728,
    "lng": -74.34275,
    "state_id": "NJ"
  },
  {
    "zip": 7059,
    "lat": 40.63237,
    "lng": -74.51459,
    "state_id": "NJ"
  },
  {
    "zip": 7060,
    "lat": 40.61635,
    "lng": -74.42166,
    "state_id": "NJ"
  },
  {
    "zip": 7062,
    "lat": 40.63162,
    "lng": -74.40333,
    "state_id": "NJ"
  },
  {
    "zip": 7063,
    "lat": 40.60514,
    "lng": -74.4461,
    "state_id": "NJ"
  },
  {
    "zip": 7064,
    "lat": 40.56812,
    "lng": -74.24811,
    "state_id": "NJ"
  },
  {
    "zip": 7065,
    "lat": 40.60771,
    "lng": -74.2807,
    "state_id": "NJ"
  },
  {
    "zip": 7066,
    "lat": 40.62022,
    "lng": -74.31357,
    "state_id": "NJ"
  },
  {
    "zip": 7067,
    "lat": 40.59263,
    "lng": -74.31483,
    "state_id": "NJ"
  },
  {
    "zip": 7068,
    "lat": 40.82073,
    "lng": -74.30866,
    "state_id": "NJ"
  },
  {
    "zip": 7069,
    "lat": 40.64247,
    "lng": -74.43952,
    "state_id": "NJ"
  },
  {
    "zip": 7070,
    "lat": 40.82017,
    "lng": -74.10566,
    "state_id": "NJ"
  },
  {
    "zip": 7071,
    "lat": 40.79635,
    "lng": -74.10991,
    "state_id": "NJ"
  },
  {
    "zip": 7072,
    "lat": 40.82467,
    "lng": -74.06105,
    "state_id": "NJ"
  },
  {
    "zip": 7073,
    "lat": 40.81803,
    "lng": -74.08538,
    "state_id": "NJ"
  },
  {
    "zip": 7074,
    "lat": 40.8399,
    "lng": -74.05672,
    "state_id": "NJ"
  },
  {
    "zip": 7075,
    "lat": 40.85076,
    "lng": -74.08776,
    "state_id": "NJ"
  },
  {
    "zip": 7076,
    "lat": 40.63304,
    "lng": -74.37392,
    "state_id": "NJ"
  },
  {
    "zip": 7077,
    "lat": 40.55409,
    "lng": -74.25501,
    "state_id": "NJ"
  },
  {
    "zip": 7078,
    "lat": 40.74207,
    "lng": -74.33378,
    "state_id": "NJ"
  },
  {
    "zip": 7079,
    "lat": 40.74912,
    "lng": -74.26016,
    "state_id": "NJ"
  },
  {
    "zip": 7080,
    "lat": 40.57481,
    "lng": -74.41531,
    "state_id": "NJ"
  },
  {
    "zip": 7081,
    "lat": 40.69948,
    "lng": -74.32543,
    "state_id": "NJ"
  },
  {
    "zip": 7082,
    "lat": 40.92641,
    "lng": -74.34534,
    "state_id": "NJ"
  },
  {
    "zip": 7083,
    "lat": 40.69419,
    "lng": -74.26895,
    "state_id": "NJ"
  },
  {
    "zip": 7086,
    "lat": 40.76808,
    "lng": -74.02087,
    "state_id": "NJ"
  },
  {
    "zip": 7087,
    "lat": 40.76736,
    "lng": -74.03227,
    "state_id": "NJ"
  },
  {
    "zip": 7088,
    "lat": 40.71787,
    "lng": -74.2849,
    "state_id": "NJ"
  },
  {
    "zip": 7090,
    "lat": 40.65154,
    "lng": -74.34327,
    "state_id": "NJ"
  },
  {
    "zip": 7092,
    "lat": 40.68103,
    "lng": -74.36024,
    "state_id": "NJ"
  },
  {
    "zip": 7093,
    "lat": 40.78809,
    "lng": -74.01145,
    "state_id": "NJ"
  },
  {
    "zip": 7094,
    "lat": 40.78098,
    "lng": -74.0661,
    "state_id": "NJ"
  },
  {
    "zip": 7095,
    "lat": 40.55369,
    "lng": -74.28641,
    "state_id": "NJ"
  },
  {
    "zip": 7102,
    "lat": 40.73591,
    "lng": -74.17355,
    "state_id": "NJ"
  },
  {
    "zip": 7103,
    "lat": 40.73869,
    "lng": -74.19554,
    "state_id": "NJ"
  },
  {
    "zip": 7104,
    "lat": 40.76734,
    "lng": -74.16831,
    "state_id": "NJ"
  },
  {
    "zip": 7105,
    "lat": 40.72288,
    "lng": -74.13883,
    "state_id": "NJ"
  },
  {
    "zip": 7106,
    "lat": 40.7418,
    "lng": -74.2304,
    "state_id": "NJ"
  },
  {
    "zip": 7107,
    "lat": 40.76182,
    "lng": -74.18655,
    "state_id": "NJ"
  },
  {
    "zip": 7108,
    "lat": 40.72312,
    "lng": -74.20016,
    "state_id": "NJ"
  },
  {
    "zip": 7109,
    "lat": 40.79496,
    "lng": -74.16165,
    "state_id": "NJ"
  },
  {
    "zip": 7110,
    "lat": 40.81924,
    "lng": -74.15708,
    "state_id": "NJ"
  },
  {
    "zip": 7111,
    "lat": 40.7243,
    "lng": -74.23168,
    "state_id": "NJ"
  },
  {
    "zip": 7112,
    "lat": 40.70946,
    "lng": -74.20955,
    "state_id": "NJ"
  },
  {
    "zip": 7114,
    "lat": 40.69995,
    "lng": -74.16405,
    "state_id": "NJ"
  },
  {
    "zip": 7201,
    "lat": 40.672,
    "lng": -74.17661,
    "state_id": "NJ"
  },
  {
    "zip": 7202,
    "lat": 40.65255,
    "lng": -74.21616,
    "state_id": "NJ"
  },
  {
    "zip": 7203,
    "lat": 40.65269,
    "lng": -74.25994,
    "state_id": "NJ"
  },
  {
    "zip": 7204,
    "lat": 40.66531,
    "lng": -74.2665,
    "state_id": "NJ"
  },
  {
    "zip": 7205,
    "lat": 40.69616,
    "lng": -74.22859,
    "state_id": "NJ"
  },
  {
    "zip": 7206,
    "lat": 40.65183,
    "lng": -74.18418,
    "state_id": "NJ"
  },
  {
    "zip": 7208,
    "lat": 40.67419,
    "lng": -74.2251,
    "state_id": "NJ"
  },
  {
    "zip": 7302,
    "lat": 40.71978,
    "lng": -74.04681,
    "state_id": "NJ"
  },
  {
    "zip": 7304,
    "lat": 40.71648,
    "lng": -74.07355,
    "state_id": "NJ"
  },
  {
    "zip": 7305,
    "lat": 40.69847,
    "lng": -74.08054,
    "state_id": "NJ"
  },
  {
    "zip": 7306,
    "lat": 40.73458,
    "lng": -74.07178,
    "state_id": "NJ"
  },
  {
    "zip": 7307,
    "lat": 40.75176,
    "lng": -74.05631,
    "state_id": "NJ"
  },
  {
    "zip": 7310,
    "lat": 40.73063,
    "lng": -74.03787,
    "state_id": "NJ"
  },
  {
    "zip": 7311,
    "lat": 40.71909,
    "lng": -74.03293,
    "state_id": "NJ"
  },
  {
    "zip": 7401,
    "lat": 41.03319,
    "lng": -74.13328,
    "state_id": "NJ"
  },
  {
    "zip": 7403,
    "lat": 41.02126,
    "lng": -74.33188,
    "state_id": "NJ"
  },
  {
    "zip": 7405,
    "lat": 40.98646,
    "lng": -74.38308,
    "state_id": "NJ"
  },
  {
    "zip": 7407,
    "lat": 40.90494,
    "lng": -74.12005,
    "state_id": "NJ"
  },
  {
    "zip": 7410,
    "lat": 40.93588,
    "lng": -74.1177,
    "state_id": "NJ"
  },
  {
    "zip": 7416,
    "lat": 41.11589,
    "lng": -74.59775,
    "state_id": "NJ"
  },
  {
    "zip": 7417,
    "lat": 41.00857,
    "lng": -74.20831,
    "state_id": "NJ"
  },
  {
    "zip": 7418,
    "lat": 41.2386,
    "lng": -74.48738,
    "state_id": "NJ"
  },
  {
    "zip": 7419,
    "lat": 41.15321,
    "lng": -74.56813,
    "state_id": "NJ"
  },
  {
    "zip": 7420,
    "lat": 41.03255,
    "lng": -74.30119,
    "state_id": "NJ"
  },
  {
    "zip": 7421,
    "lat": 41.16582,
    "lng": -74.36312,
    "state_id": "NJ"
  },
  {
    "zip": 7422,
    "lat": 41.1816,
    "lng": -74.44914,
    "state_id": "NJ"
  },
  {
    "zip": 7423,
    "lat": 41.0001,
    "lng": -74.09724,
    "state_id": "NJ"
  },
  {
    "zip": 7424,
    "lat": 40.88374,
    "lng": -74.20587,
    "state_id": "NJ"
  },
  {
    "zip": 7430,
    "lat": 41.08162,
    "lng": -74.18566,
    "state_id": "NJ"
  },
  {
    "zip": 7432,
    "lat": 40.99515,
    "lng": -74.14108,
    "state_id": "NJ"
  },
  {
    "zip": 7435,
    "lat": 41.03603,
    "lng": -74.44998,
    "state_id": "NJ"
  },
  {
    "zip": 7436,
    "lat": 41.03134,
    "lng": -74.24078,
    "state_id": "NJ"
  },
  {
    "zip": 7438,
    "lat": 41.03023,
    "lng": -74.51968,
    "state_id": "NJ"
  },
  {
    "zip": 7439,
    "lat": 41.07611,
    "lng": -74.59771,
    "state_id": "NJ"
  },
  {
    "zip": 7440,
    "lat": 40.94671,
    "lng": -74.29413,
    "state_id": "NJ"
  },
  {
    "zip": 7442,
    "lat": 41.00247,
    "lng": -74.28578,
    "state_id": "NJ"
  },
  {
    "zip": 7444,
    "lat": 40.96783,
    "lng": -74.30729,
    "state_id": "NJ"
  },
  {
    "zip": 7446,
    "lat": 41.05944,
    "lng": -74.14545,
    "state_id": "NJ"
  },
  {
    "zip": 7450,
    "lat": 40.9822,
    "lng": -74.11266,
    "state_id": "NJ"
  },
  {
    "zip": 7452,
    "lat": 40.9601,
    "lng": -74.1249,
    "state_id": "NJ"
  },
  {
    "zip": 7456,
    "lat": 41.10644,
    "lng": -74.27492,
    "state_id": "NJ"
  },
  {
    "zip": 7457,
    "lat": 40.9921,
    "lng": -74.31247,
    "state_id": "NJ"
  },
  {
    "zip": 7458,
    "lat": 41.04586,
    "lng": -74.09709,
    "state_id": "NJ"
  },
  {
    "zip": 7460,
    "lat": 41.11219,
    "lng": -74.495,
    "state_id": "NJ"
  },
  {
    "zip": 7461,
    "lat": 41.241,
    "lng": -74.60648,
    "state_id": "NJ"
  },
  {
    "zip": 7462,
    "lat": 41.19889,
    "lng": -74.48862,
    "state_id": "NJ"
  },
  {
    "zip": 7463,
    "lat": 41.01334,
    "lng": -74.12571,
    "state_id": "NJ"
  },
  {
    "zip": 7465,
    "lat": 41.051,
    "lng": -74.2977,
    "state_id": "NJ"
  },
  {
    "zip": 7470,
    "lat": 40.94803,
    "lng": -74.24525,
    "state_id": "NJ"
  },
  {
    "zip": 7480,
    "lat": 41.08748,
    "lng": -74.37737,
    "state_id": "NJ"
  },
  {
    "zip": 7481,
    "lat": 40.99898,
    "lng": -74.16757,
    "state_id": "NJ"
  },
  {
    "zip": 7495,
    "lat": 41.10444,
    "lng": -74.16321,
    "state_id": "NJ"
  },
  {
    "zip": 7501,
    "lat": 40.91273,
    "lng": -74.17121,
    "state_id": "NJ"
  },
  {
    "zip": 7502,
    "lat": 40.91863,
    "lng": -74.1942,
    "state_id": "NJ"
  },
  {
    "zip": 7503,
    "lat": 40.89774,
    "lng": -74.15342,
    "state_id": "NJ"
  },
  {
    "zip": 7504,
    "lat": 40.91221,
    "lng": -74.1413,
    "state_id": "NJ"
  },
  {
    "zip": 7505,
    "lat": 40.91623,
    "lng": -74.17187,
    "state_id": "NJ"
  },
  {
    "zip": 7506,
    "lat": 40.958,
    "lng": -74.15811,
    "state_id": "NJ"
  },
  {
    "zip": 7508,
    "lat": 40.95433,
    "lng": -74.18441,
    "state_id": "NJ"
  },
  {
    "zip": 7512,
    "lat": 40.9039,
    "lng": -74.22123,
    "state_id": "NJ"
  },
  {
    "zip": 7513,
    "lat": 40.90574,
    "lng": -74.14861,
    "state_id": "NJ"
  },
  {
    "zip": 7514,
    "lat": 40.92622,
    "lng": -74.14452,
    "state_id": "NJ"
  },
  {
    "zip": 7522,
    "lat": 40.92502,
    "lng": -74.17853,
    "state_id": "NJ"
  },
  {
    "zip": 7524,
    "lat": 40.93215,
    "lng": -74.15682,
    "state_id": "NJ"
  },
  {
    "zip": 7601,
    "lat": 40.889,
    "lng": -74.0461,
    "state_id": "NJ"
  },
  {
    "zip": 7603,
    "lat": 40.87508,
    "lng": -74.02932,
    "state_id": "NJ"
  },
  {
    "zip": 7604,
    "lat": 40.86195,
    "lng": -74.07409,
    "state_id": "NJ"
  },
  {
    "zip": 7605,
    "lat": 40.86382,
    "lng": -73.98978,
    "state_id": "NJ"
  },
  {
    "zip": 7606,
    "lat": 40.85725,
    "lng": -74.04668,
    "state_id": "NJ"
  },
  {
    "zip": 7607,
    "lat": 40.9026,
    "lng": -74.06326,
    "state_id": "NJ"
  },
  {
    "zip": 7608,
    "lat": 40.85368,
    "lng": -74.06129,
    "state_id": "NJ"
  },
  {
    "zip": 7620,
    "lat": 40.96156,
    "lng": -73.91996,
    "state_id": "NJ"
  },
  {
    "zip": 7621,
    "lat": 40.9238,
    "lng": -73.99859,
    "state_id": "NJ"
  },
  {
    "zip": 7624,
    "lat": 40.97331,
    "lng": -73.96053,
    "state_id": "NJ"
  },
  {
    "zip": 7626,
    "lat": 40.94052,
    "lng": -73.95956,
    "state_id": "NJ"
  },
  {
    "zip": 7627,
    "lat": 40.95505,
    "lng": -73.95674,
    "state_id": "NJ"
  },
  {
    "zip": 7628,
    "lat": 40.94514,
    "lng": -73.99232,
    "state_id": "NJ"
  },
  {
    "zip": 7630,
    "lat": 40.97487,
    "lng": -74.02397,
    "state_id": "NJ"
  },
  {
    "zip": 7631,
    "lat": 40.89178,
    "lng": -73.97355,
    "state_id": "NJ"
  },
  {
    "zip": 7632,
    "lat": 40.8822,
    "lng": -73.94661,
    "state_id": "NJ"
  },
  {
    "zip": 7640,
    "lat": 40.98988,
    "lng": -73.98024,
    "state_id": "NJ"
  },
  {
    "zip": 7641,
    "lat": 40.96221,
    "lng": -73.99753,
    "state_id": "NJ"
  },
  {
    "zip": 7642,
    "lat": 41.00735,
    "lng": -74.04383,
    "state_id": "NJ"
  },
  {
    "zip": 7643,
    "lat": 40.84634,
    "lng": -74.03879,
    "state_id": "NJ"
  },
  {
    "zip": 7644,
    "lat": 40.87848,
    "lng": -74.08138,
    "state_id": "NJ"
  },
  {
    "zip": 7645,
    "lat": 41.05292,
    "lng": -74.04988,
    "state_id": "NJ"
  },
  {
    "zip": 7646,
    "lat": 40.93369,
    "lng": -74.01957,
    "state_id": "NJ"
  },
  {
    "zip": 7647,
    "lat": 41.00669,
    "lng": -73.94259,
    "state_id": "NJ"
  },
  {
    "zip": 7648,
    "lat": 40.9933,
    "lng": -73.95089,
    "state_id": "NJ"
  },
  {
    "zip": 7649,
    "lat": 40.95625,
    "lng": -74.03147,
    "state_id": "NJ"
  },
  {
    "zip": 7650,
    "lat": 40.84714,
    "lng": -73.9968,
    "state_id": "NJ"
  },
  {
    "zip": 7652,
    "lat": 40.94554,
    "lng": -74.07117,
    "state_id": "NJ"
  },
  {
    "zip": 7656,
    "lat": 41.03533,
    "lng": -74.04243,
    "state_id": "NJ"
  },
  {
    "zip": 7657,
    "lat": 40.83134,
    "lng": -74.01475,
    "state_id": "NJ"
  },
  {
    "zip": 7660,
    "lat": 40.85445,
    "lng": -74.02017,
    "state_id": "NJ"
  },
  {
    "zip": 7661,
    "lat": 40.92695,
    "lng": -74.03872,
    "state_id": "NJ"
  },
  {
    "zip": 7662,
    "lat": 40.90715,
    "lng": -74.07936,
    "state_id": "NJ"
  },
  {
    "zip": 7663,
    "lat": 40.90321,
    "lng": -74.09559,
    "state_id": "NJ"
  },
  {
    "zip": 7666,
    "lat": 40.88988,
    "lng": -74.01063,
    "state_id": "NJ"
  },
  {
    "zip": 7670,
    "lat": 40.91745,
    "lng": -73.95359,
    "state_id": "NJ"
  },
  {
    "zip": 7675,
    "lat": 41.00911,
    "lng": -74.00429,
    "state_id": "NJ"
  },
  {
    "zip": 7676,
    "lat": 40.98845,
    "lng": -74.06359,
    "state_id": "NJ"
  },
  {
    "zip": 7677,
    "lat": 41.02518,
    "lng": -74.06025,
    "state_id": "NJ"
  },
  {
    "zip": 7701,
    "lat": 40.36144,
    "lng": -74.07745,
    "state_id": "NJ"
  },
  {
    "zip": 7702,
    "lat": 40.32487,
    "lng": -74.05996,
    "state_id": "NJ"
  },
  {
    "zip": 7703,
    "lat": 40.3145,
    "lng": -74.0423,
    "state_id": "NJ"
  },
  {
    "zip": 7704,
    "lat": 40.36182,
    "lng": -74.03927,
    "state_id": "NJ"
  },
  {
    "zip": 7711,
    "lat": 40.2394,
    "lng": -74.00872,
    "state_id": "NJ"
  },
  {
    "zip": 7712,
    "lat": 40.24812,
    "lng": -74.05482,
    "state_id": "NJ"
  },
  {
    "zip": 7716,
    "lat": 40.40136,
    "lng": -74.03087,
    "state_id": "NJ"
  },
  {
    "zip": 7717,
    "lat": 40.19125,
    "lng": -74.01659,
    "state_id": "NJ"
  },
  {
    "zip": 7718,
    "lat": 40.42076,
    "lng": -74.08494,
    "state_id": "NJ"
  },
  {
    "zip": 7719,
    "lat": 40.1687,
    "lng": -74.07274,
    "state_id": "NJ"
  },
  {
    "zip": 7720,
    "lat": 40.20177,
    "lng": -74.01276,
    "state_id": "NJ"
  },
  {
    "zip": 7721,
    "lat": 40.43579,
    "lng": -74.2355,
    "state_id": "NJ"
  },
  {
    "zip": 7722,
    "lat": 40.28487,
    "lng": -74.16954,
    "state_id": "NJ"
  },
  {
    "zip": 7723,
    "lat": 40.25032,
    "lng": -74.00037,
    "state_id": "NJ"
  },
  {
    "zip": 7724,
    "lat": 40.29844,
    "lng": -74.07486,
    "state_id": "NJ"
  },
  {
    "zip": 7726,
    "lat": 40.28248,
    "lng": -74.34246,
    "state_id": "NJ"
  },
  {
    "zip": 7727,
    "lat": 40.19831,
    "lng": -74.16001,
    "state_id": "NJ"
  },
  {
    "zip": 7728,
    "lat": 40.22544,
    "lng": -74.2854,
    "state_id": "NJ"
  },
  {
    "zip": 7730,
    "lat": 40.42483,
    "lng": -74.17571,
    "state_id": "NJ"
  },
  {
    "zip": 7731,
    "lat": 40.14979,
    "lng": -74.20007,
    "state_id": "NJ"
  },
  {
    "zip": 7732,
    "lat": 40.43092,
    "lng": -73.99198,
    "state_id": "NJ"
  },
  {
    "zip": 7733,
    "lat": 40.3767,
    "lng": -74.17258,
    "state_id": "NJ"
  },
  {
    "zip": 7734,
    "lat": 40.44294,
    "lng": -74.13513,
    "state_id": "NJ"
  },
  {
    "zip": 7735,
    "lat": 40.44121,
    "lng": -74.19961,
    "state_id": "NJ"
  },
  {
    "zip": 7737,
    "lat": 40.41011,
    "lng": -74.06483,
    "state_id": "NJ"
  },
  {
    "zip": 7738,
    "lat": 40.33775,
    "lng": -74.12755,
    "state_id": "NJ"
  },
  {
    "zip": 7739,
    "lat": 40.33574,
    "lng": -74.03457,
    "state_id": "NJ"
  },
  {
    "zip": 7740,
    "lat": 40.29586,
    "lng": -73.99184,
    "state_id": "NJ"
  },
  {
    "zip": 7746,
    "lat": 40.31809,
    "lng": -74.24988,
    "state_id": "NJ"
  },
  {
    "zip": 7747,
    "lat": 40.4132,
    "lng": -74.25178,
    "state_id": "NJ"
  },
  {
    "zip": 7748,
    "lat": 40.39433,
    "lng": -74.11566,
    "state_id": "NJ"
  },
  {
    "zip": 7750,
    "lat": 40.33643,
    "lng": -73.98632,
    "state_id": "NJ"
  },
  {
    "zip": 7751,
    "lat": 40.3626,
    "lng": -74.2591,
    "state_id": "NJ"
  },
  {
    "zip": 7753,
    "lat": 40.21494,
    "lng": -74.07856,
    "state_id": "NJ"
  },
  {
    "zip": 7755,
    "lat": 40.26369,
    "lng": -74.02284,
    "state_id": "NJ"
  },
  {
    "zip": 7756,
    "lat": 40.21186,
    "lng": -74.00829,
    "state_id": "NJ"
  },
  {
    "zip": 7757,
    "lat": 40.31621,
    "lng": -74.01608,
    "state_id": "NJ"
  },
  {
    "zip": 7758,
    "lat": 40.431,
    "lng": -74.10359,
    "state_id": "NJ"
  },
  {
    "zip": 7760,
    "lat": 40.36892,
    "lng": -74.00422,
    "state_id": "NJ"
  },
  {
    "zip": 7762,
    "lat": 40.15297,
    "lng": -74.03581,
    "state_id": "NJ"
  },
  {
    "zip": 7764,
    "lat": 40.28832,
    "lng": -74.0185,
    "state_id": "NJ"
  },
  {
    "zip": 7801,
    "lat": 40.91881,
    "lng": -74.5542,
    "state_id": "NJ"
  },
  {
    "zip": 7803,
    "lat": 40.87768,
    "lng": -74.60045,
    "state_id": "NJ"
  },
  {
    "zip": 7820,
    "lat": 40.92708,
    "lng": -74.81142,
    "state_id": "NJ"
  },
  {
    "zip": 7821,
    "lat": 40.96469,
    "lng": -74.74995,
    "state_id": "NJ"
  },
  {
    "zip": 7822,
    "lat": 41.14014,
    "lng": -74.70546,
    "state_id": "NJ"
  },
  {
    "zip": 7823,
    "lat": 40.82796,
    "lng": -75.0365,
    "state_id": "NJ"
  },
  {
    "zip": 7825,
    "lat": 40.9775,
    "lng": -74.96,
    "state_id": "NJ"
  },
  {
    "zip": 7826,
    "lat": 41.19266,
    "lng": -74.76869,
    "state_id": "NJ"
  },
  {
    "zip": 7827,
    "lat": 41.29226,
    "lng": -74.73934,
    "state_id": "NJ"
  },
  {
    "zip": 7828,
    "lat": 40.88499,
    "lng": -74.75145,
    "state_id": "NJ"
  },
  {
    "zip": 7830,
    "lat": 40.72104,
    "lng": -74.80747,
    "state_id": "NJ"
  },
  {
    "zip": 7832,
    "lat": 40.96014,
    "lng": -75.05705,
    "state_id": "NJ"
  },
  {
    "zip": 7833,
    "lat": 40.89189,
    "lng": -75.06774,
    "state_id": "NJ"
  },
  {
    "zip": 7834,
    "lat": 40.88845,
    "lng": -74.48651,
    "state_id": "NJ"
  },
  {
    "zip": 7836,
    "lat": 40.84525,
    "lng": -74.70319,
    "state_id": "NJ"
  },
  {
    "zip": 7838,
    "lat": 40.88921,
    "lng": -74.91439,
    "state_id": "NJ"
  },
  {
    "zip": 7840,
    "lat": 40.85408,
    "lng": -74.83573,
    "state_id": "NJ"
  },
  {
    "zip": 7842,
    "lat": 40.9411,
    "lng": -74.51033,
    "state_id": "NJ"
  },
  {
    "zip": 7843,
    "lat": 40.93959,
    "lng": -74.65981,
    "state_id": "NJ"
  },
  {
    "zip": 7846,
    "lat": 40.9661,
    "lng": -74.87721,
    "state_id": "NJ"
  },
  {
    "zip": 7847,
    "lat": 40.8846,
    "lng": -74.62581,
    "state_id": "NJ"
  },
  {
    "zip": 7848,
    "lat": 41.10338,
    "lng": -74.68405,
    "state_id": "NJ"
  },
  {
    "zip": 7849,
    "lat": 40.96623,
    "lng": -74.60998,
    "state_id": "NJ"
  },
  {
    "zip": 7850,
    "lat": 40.90607,
    "lng": -74.66402,
    "state_id": "NJ"
  },
  {
    "zip": 7851,
    "lat": 41.22981,
    "lng": -74.84673,
    "state_id": "NJ"
  },
  {
    "zip": 7852,
    "lat": 40.88224,
    "lng": -74.66265,
    "state_id": "NJ"
  },
  {
    "zip": 7853,
    "lat": 40.78499,
    "lng": -74.78545,
    "state_id": "NJ"
  },
  {
    "zip": 7856,
    "lat": 40.91868,
    "lng": -74.63344,
    "state_id": "NJ"
  },
  {
    "zip": 7857,
    "lat": 40.89713,
    "lng": -74.6999,
    "state_id": "NJ"
  },
  {
    "zip": 7860,
    "lat": 41.06025,
    "lng": -74.81341,
    "state_id": "NJ"
  },
  {
    "zip": 7863,
    "lat": 40.8129,
    "lng": -74.96037,
    "state_id": "NJ"
  },
  {
    "zip": 7865,
    "lat": 40.78469,
    "lng": -74.89875,
    "state_id": "NJ"
  },
  {
    "zip": 7866,
    "lat": 40.95384,
    "lng": -74.49016,
    "state_id": "NJ"
  },
  {
    "zip": 7869,
    "lat": 40.84492,
    "lng": -74.58101,
    "state_id": "NJ"
  },
  {
    "zip": 7870,
    "lat": 40.80394,
    "lng": -74.81965,
    "state_id": "NJ"
  },
  {
    "zip": 7871,
    "lat": 41.04708,
    "lng": -74.62793,
    "state_id": "NJ"
  },
  {
    "zip": 7874,
    "lat": 40.92158,
    "lng": -74.73628,
    "state_id": "NJ"
  },
  {
    "zip": 7876,
    "lat": 40.85332,
    "lng": -74.65575,
    "state_id": "NJ"
  },
  {
    "zip": 7878,
    "lat": 40.87177,
    "lng": -74.47627,
    "state_id": "NJ"
  },
  {
    "zip": 7880,
    "lat": 40.87302,
    "lng": -74.88968,
    "state_id": "NJ"
  },
  {
    "zip": 7881,
    "lat": 41.125,
    "lng": -74.91088,
    "state_id": "NJ"
  },
  {
    "zip": 7882,
    "lat": 40.75173,
    "lng": -75.01123,
    "state_id": "NJ"
  },
  {
    "zip": 7885,
    "lat": 40.93517,
    "lng": -74.57845,
    "state_id": "NJ"
  },
  {
    "zip": 7901,
    "lat": 40.71468,
    "lng": -74.36592,
    "state_id": "NJ"
  },
  {
    "zip": 7920,
    "lat": 40.6784,
    "lng": -74.56296,
    "state_id": "NJ"
  },
  {
    "zip": 7921,
    "lat": 40.65697,
    "lng": -74.6775,
    "state_id": "NJ"
  },
  {
    "zip": 7922,
    "lat": 40.67518,
    "lng": -74.42817,
    "state_id": "NJ"
  },
  {
    "zip": 7924,
    "lat": 40.72704,
    "lng": -74.58964,
    "state_id": "NJ"
  },
  {
    "zip": 7926,
    "lat": 40.80129,
    "lng": -74.57029,
    "state_id": "NJ"
  },
  {
    "zip": 7927,
    "lat": 40.82131,
    "lng": -74.45334,
    "state_id": "NJ"
  },
  {
    "zip": 7928,
    "lat": 40.7262,
    "lng": -74.41252,
    "state_id": "NJ"
  },
  {
    "zip": 7930,
    "lat": 40.77989,
    "lng": -74.68675,
    "state_id": "NJ"
  },
  {
    "zip": 7931,
    "lat": 40.70944,
    "lng": -74.65261,
    "state_id": "NJ"
  },
  {
    "zip": 7932,
    "lat": 40.77709,
    "lng": -74.39289,
    "state_id": "NJ"
  },
  {
    "zip": 7933,
    "lat": 40.69196,
    "lng": -74.46494,
    "state_id": "NJ"
  },
  {
    "zip": 7934,
    "lat": 40.72066,
    "lng": -74.67808,
    "state_id": "NJ"
  },
  {
    "zip": 7935,
    "lat": 40.73656,
    "lng": -74.44403,
    "state_id": "NJ"
  },
  {
    "zip": 7936,
    "lat": 40.81925,
    "lng": -74.36374,
    "state_id": "NJ"
  },
  {
    "zip": 7939,
    "lat": 40.66736,
    "lng": -74.55378,
    "state_id": "NJ"
  },
  {
    "zip": 7940,
    "lat": 40.75981,
    "lng": -74.41794,
    "state_id": "NJ"
  },
  {
    "zip": 7945,
    "lat": 40.78135,
    "lng": -74.59797,
    "state_id": "NJ"
  },
  {
    "zip": 7946,
    "lat": 40.67751,
    "lng": -74.51119,
    "state_id": "NJ"
  },
  {
    "zip": 7950,
    "lat": 40.8445,
    "lng": -74.48239,
    "state_id": "NJ"
  },
  {
    "zip": 7960,
    "lat": 40.78339,
    "lng": -74.49958,
    "state_id": "NJ"
  },
  {
    "zip": 7961,
    "lat": 40.78062,
    "lng": -74.4327,
    "state_id": "NJ"
  },
  {
    "zip": 7970,
    "lat": 40.80802,
    "lng": -74.57277,
    "state_id": "NJ"
  },
  {
    "zip": 7974,
    "lat": 40.6973,
    "lng": -74.4031,
    "state_id": "NJ"
  },
  {
    "zip": 7976,
    "lat": 40.72292,
    "lng": -74.48638,
    "state_id": "NJ"
  },
  {
    "zip": 7977,
    "lat": 40.70628,
    "lng": -74.66373,
    "state_id": "NJ"
  },
  {
    "zip": 7979,
    "lat": 40.70261,
    "lng": -74.72762,
    "state_id": "NJ"
  },
  {
    "zip": 7980,
    "lat": 40.67865,
    "lng": -74.49385,
    "state_id": "NJ"
  },
  {
    "zip": 7981,
    "lat": 40.82207,
    "lng": -74.41904,
    "state_id": "NJ"
  },
  {
    "zip": 8001,
    "lat": 39.55073,
    "lng": -75.34977,
    "state_id": "NJ"
  },
  {
    "zip": 8002,
    "lat": 39.93133,
    "lng": -75.02539,
    "state_id": "NJ"
  },
  {
    "zip": 8003,
    "lat": 39.88323,
    "lng": -74.97228,
    "state_id": "NJ"
  },
  {
    "zip": 8004,
    "lat": 39.76465,
    "lng": -74.87002,
    "state_id": "NJ"
  },
  {
    "zip": 8005,
    "lat": 39.76366,
    "lng": -74.3153,
    "state_id": "NJ"
  },
  {
    "zip": 8006,
    "lat": 39.75428,
    "lng": -74.10813,
    "state_id": "NJ"
  },
  {
    "zip": 8007,
    "lat": 39.86572,
    "lng": -75.05378,
    "state_id": "NJ"
  },
  {
    "zip": 8008,
    "lat": 39.62403,
    "lng": -74.22123,
    "state_id": "NJ"
  },
  {
    "zip": 8009,
    "lat": 39.76519,
    "lng": -74.93262,
    "state_id": "NJ"
  },
  {
    "zip": 8010,
    "lat": 40.05333,
    "lng": -74.91419,
    "state_id": "NJ"
  },
  {
    "zip": 8011,
    "lat": 39.97272,
    "lng": -74.71292,
    "state_id": "NJ"
  },
  {
    "zip": 8012,
    "lat": 39.78429,
    "lng": -75.05528,
    "state_id": "NJ"
  },
  {
    "zip": 8014,
    "lat": 39.81795,
    "lng": -75.35809,
    "state_id": "NJ"
  },
  {
    "zip": 8015,
    "lat": 39.93294,
    "lng": -74.53757,
    "state_id": "NJ"
  },
  {
    "zip": 8016,
    "lat": 40.06937,
    "lng": -74.82981,
    "state_id": "NJ"
  },
  {
    "zip": 8019,
    "lat": 39.77674,
    "lng": -74.53088,
    "state_id": "NJ"
  },
  {
    "zip": 8020,
    "lat": 39.7974,
    "lng": -75.22411,
    "state_id": "NJ"
  },
  {
    "zip": 8021,
    "lat": 39.8067,
    "lng": -75.00058,
    "state_id": "NJ"
  },
  {
    "zip": 8022,
    "lat": 40.0661,
    "lng": -74.70865,
    "state_id": "NJ"
  },
  {
    "zip": 8023,
    "lat": 39.68741,
    "lng": -75.49759,
    "state_id": "NJ"
  },
  {
    "zip": 8026,
    "lat": 39.83322,
    "lng": -74.96571,
    "state_id": "NJ"
  },
  {
    "zip": 8027,
    "lat": 39.82999,
    "lng": -75.28737,
    "state_id": "NJ"
  },
  {
    "zip": 8028,
    "lat": 39.69436,
    "lng": -75.12054,
    "state_id": "NJ"
  },
  {
    "zip": 8029,
    "lat": 39.84063,
    "lng": -75.06781,
    "state_id": "NJ"
  },
  {
    "zip": 8030,
    "lat": 39.88971,
    "lng": -75.11772,
    "state_id": "NJ"
  },
  {
    "zip": 8031,
    "lat": 39.86668,
    "lng": -75.0945,
    "state_id": "NJ"
  },
  {
    "zip": 8033,
    "lat": 39.89319,
    "lng": -75.03702,
    "state_id": "NJ"
  },
  {
    "zip": 8034,
    "lat": 39.90608,
    "lng": -74.99927,
    "state_id": "NJ"
  },
  {
    "zip": 8035,
    "lat": 39.87913,
    "lng": -75.06442,
    "state_id": "NJ"
  },
  {
    "zip": 8036,
    "lat": 39.97625,
    "lng": -74.83732,
    "state_id": "NJ"
  },
  {
    "zip": 8037,
    "lat": 39.638,
    "lng": -74.77275,
    "state_id": "NJ"
  },
  {
    "zip": 8038,
    "lat": 39.47383,
    "lng": -75.4886,
    "state_id": "NJ"
  },
  {
    "zip": 8039,
    "lat": 39.68798,
    "lng": -75.27215,
    "state_id": "NJ"
  },
  {
    "zip": 8041,
    "lat": 40.03787,
    "lng": -74.68382,
    "state_id": "NJ"
  },
  {
    "zip": 8042,
    "lat": 40.0151,
    "lng": -74.66234,
    "state_id": "NJ"
  },
  {
    "zip": 8043,
    "lat": 39.84509,
    "lng": -74.95492,
    "state_id": "NJ"
  },
  {
    "zip": 8045,
    "lat": 39.86722,
    "lng": -75.02974,
    "state_id": "NJ"
  },
  {
    "zip": 8046,
    "lat": 40.02784,
    "lng": -74.88606,
    "state_id": "NJ"
  },
  {
    "zip": 8048,
    "lat": 39.95732,
    "lng": -74.80401,
    "state_id": "NJ"
  },
  {
    "zip": 8049,
    "lat": 39.85438,
    "lng": -75.03856,
    "state_id": "NJ"
  },
  {
    "zip": 8050,
    "lat": 39.70642,
    "lng": -74.24834,
    "state_id": "NJ"
  },
  {
    "zip": 8051,
    "lat": 39.7854,
    "lng": -75.17891,
    "state_id": "NJ"
  },
  {
    "zip": 8052,
    "lat": 39.95204,
    "lng": -74.99508,
    "state_id": "NJ"
  },
  {
    "zip": 8053,
    "lat": 39.86049,
    "lng": -74.89467,
    "state_id": "NJ"
  },
  {
    "zip": 8054,
    "lat": 39.94835,
    "lng": -74.90476,
    "state_id": "NJ"
  },
  {
    "zip": 8055,
    "lat": 39.86375,
    "lng": -74.82251,
    "state_id": "NJ"
  },
  {
    "zip": 8056,
    "lat": 39.7829,
    "lng": -75.25155,
    "state_id": "NJ"
  },
  {
    "zip": 8057,
    "lat": 39.97939,
    "lng": -74.94112,
    "state_id": "NJ"
  },
  {
    "zip": 8059,
    "lat": 39.88441,
    "lng": -75.09301,
    "state_id": "NJ"
  },
  {
    "zip": 8060,
    "lat": 40.00858,
    "lng": -74.78952,
    "state_id": "NJ"
  },
  {
    "zip": 8061,
    "lat": 39.80457,
    "lng": -75.20707,
    "state_id": "NJ"
  },
  {
    "zip": 8062,
    "lat": 39.71519,
    "lng": -75.22256,
    "state_id": "NJ"
  },
  {
    "zip": 8063,
    "lat": 39.86757,
    "lng": -75.18512,
    "state_id": "NJ"
  },
  {
    "zip": 8064,
    "lat": 39.95758,
    "lng": -74.63366,
    "state_id": "NJ"
  },
  {
    "zip": 8065,
    "lat": 40.00235,
    "lng": -75.03587,
    "state_id": "NJ"
  },
  {
    "zip": 8066,
    "lat": 39.8345,
    "lng": -75.2249,
    "state_id": "NJ"
  },
  {
    "zip": 8067,
    "lat": 39.73824,
    "lng": -75.41262,
    "state_id": "NJ"
  },
  {
    "zip": 8068,
    "lat": 39.95939,
    "lng": -74.66295,
    "state_id": "NJ"
  },
  {
    "zip": 8069,
    "lat": 39.69949,
    "lng": -75.44961,
    "state_id": "NJ"
  },
  {
    "zip": 8070,
    "lat": 39.6244,
    "lng": -75.50916,
    "state_id": "NJ"
  },
  {
    "zip": 8071,
    "lat": 39.73228,
    "lng": -75.13387,
    "state_id": "NJ"
  },
  {
    "zip": 8072,
    "lat": 39.54424,
    "lng": -75.41666,
    "state_id": "NJ"
  },
  {
    "zip": 8073,
    "lat": 40.00958,
    "lng": -74.86688,
    "state_id": "NJ"
  },
  {
    "zip": 8074,
    "lat": 39.71571,
    "lng": -75.16404,
    "state_id": "NJ"
  },
  {
    "zip": 8075,
    "lat": 40.02928,
    "lng": -74.94989,
    "state_id": "NJ"
  },
  {
    "zip": 8077,
    "lat": 40.00192,
    "lng": -74.99523,
    "state_id": "NJ"
  },
  {
    "zip": 8078,
    "lat": 39.8521,
    "lng": -75.07449,
    "state_id": "NJ"
  },
  {
    "zip": 8079,
    "lat": 39.54335,
    "lng": -75.4302,
    "state_id": "NJ"
  },
  {
    "zip": 8080,
    "lat": 39.75582,
    "lng": -75.11888,
    "state_id": "NJ"
  },
  {
    "zip": 8081,
    "lat": 39.73665,
    "lng": -74.97547,
    "state_id": "NJ"
  },
  {
    "zip": 8083,
    "lat": 39.84125,
    "lng": -75.02862,
    "state_id": "NJ"
  },
  {
    "zip": 8084,
    "lat": 39.82897,
    "lng": -75.01556,
    "state_id": "NJ"
  },
  {
    "zip": 8085,
    "lat": 39.75939,
    "lng": -75.33192,
    "state_id": "NJ"
  },
  {
    "zip": 8086,
    "lat": 39.84573,
    "lng": -75.19422,
    "state_id": "NJ"
  },
  {
    "zip": 8087,
    "lat": 39.59283,
    "lng": -74.37545,
    "state_id": "NJ"
  },
  {
    "zip": 8088,
    "lat": 39.84924,
    "lng": -74.6927,
    "state_id": "NJ"
  },
  {
    "zip": 8089,
    "lat": 39.71826,
    "lng": -74.82432,
    "state_id": "NJ"
  },
  {
    "zip": 8090,
    "lat": 39.79751,
    "lng": -75.15071,
    "state_id": "NJ"
  },
  {
    "zip": 8091,
    "lat": 39.80536,
    "lng": -74.92546,
    "state_id": "NJ"
  },
  {
    "zip": 8092,
    "lat": 39.65483,
    "lng": -74.28431,
    "state_id": "NJ"
  },
  {
    "zip": 8093,
    "lat": 39.86416,
    "lng": -75.1358,
    "state_id": "NJ"
  },
  {
    "zip": 8094,
    "lat": 39.65352,
    "lng": -74.9672,
    "state_id": "NJ"
  },
  {
    "zip": 8095,
    "lat": 39.64847,
    "lng": -74.86033,
    "state_id": "NJ"
  },
  {
    "zip": 8096,
    "lat": 39.8266,
    "lng": -75.12674,
    "state_id": "NJ"
  },
  {
    "zip": 8097,
    "lat": 39.81527,
    "lng": -75.15087,
    "state_id": "NJ"
  },
  {
    "zip": 8098,
    "lat": 39.63888,
    "lng": -75.3294,
    "state_id": "NJ"
  },
  {
    "zip": 8102,
    "lat": 39.95236,
    "lng": -75.12032,
    "state_id": "NJ"
  },
  {
    "zip": 8103,
    "lat": 39.93577,
    "lng": -75.11351,
    "state_id": "NJ"
  },
  {
    "zip": 8104,
    "lat": 39.91636,
    "lng": -75.11241,
    "state_id": "NJ"
  },
  {
    "zip": 8105,
    "lat": 39.95152,
    "lng": -75.09121,
    "state_id": "NJ"
  },
  {
    "zip": 8106,
    "lat": 39.89124,
    "lng": -75.07404,
    "state_id": "NJ"
  },
  {
    "zip": 8107,
    "lat": 39.90763,
    "lng": -75.08336,
    "state_id": "NJ"
  },
  {
    "zip": 8108,
    "lat": 39.91431,
    "lng": -75.0618,
    "state_id": "NJ"
  },
  {
    "zip": 8109,
    "lat": 39.95039,
    "lng": -75.05043,
    "state_id": "NJ"
  },
  {
    "zip": 8110,
    "lat": 39.97228,
    "lng": -75.0606,
    "state_id": "NJ"
  },
  {
    "zip": 8201,
    "lat": 39.42237,
    "lng": -74.49507,
    "state_id": "NJ"
  },
  {
    "zip": 8202,
    "lat": 39.10018,
    "lng": -74.73873,
    "state_id": "NJ"
  },
  {
    "zip": 8203,
    "lat": 39.41277,
    "lng": -74.38012,
    "state_id": "NJ"
  },
  {
    "zip": 8204,
    "lat": 38.9732,
    "lng": -74.92006,
    "state_id": "NJ"
  },
  {
    "zip": 8205,
    "lat": 39.47458,
    "lng": -74.45742,
    "state_id": "NJ"
  },
  {
    "zip": 8210,
    "lat": 39.1172,
    "lng": -74.82352,
    "state_id": "NJ"
  },
  {
    "zip": 8212,
    "lat": 38.93571,
    "lng": -74.95422,
    "state_id": "NJ"
  },
  {
    "zip": 8215,
    "lat": 39.58253,
    "lng": -74.58151,
    "state_id": "NJ"
  },
  {
    "zip": 8217,
    "lat": 39.57536,
    "lng": -74.71852,
    "state_id": "NJ"
  },
  {
    "zip": 8221,
    "lat": 39.34346,
    "lng": -74.57068,
    "state_id": "NJ"
  },
  {
    "zip": 8223,
    "lat": 39.27017,
    "lng": -74.65709,
    "state_id": "NJ"
  },
  {
    "zip": 8224,
    "lat": 39.59857,
    "lng": -74.45979,
    "state_id": "NJ"
  },
  {
    "zip": 8225,
    "lat": 39.36396,
    "lng": -74.54079,
    "state_id": "NJ"
  },
  {
    "zip": 8226,
    "lat": 39.26859,
    "lng": -74.60158,
    "state_id": "NJ"
  },
  {
    "zip": 8230,
    "lat": 39.21268,
    "lng": -74.71739,
    "state_id": "NJ"
  },
  {
    "zip": 8232,
    "lat": 39.38769,
    "lng": -74.51489,
    "state_id": "NJ"
  },
  {
    "zip": 8234,
    "lat": 39.38657,
    "lng": -74.62376,
    "state_id": "NJ"
  },
  {
    "zip": 8240,
    "lat": 39.48729,
    "lng": -74.53176,
    "state_id": "NJ"
  },
  {
    "zip": 8241,
    "lat": 39.53287,
    "lng": -74.48504,
    "state_id": "NJ"
  },
  {
    "zip": 8242,
    "lat": 39.01806,
    "lng": -74.88884,
    "state_id": "NJ"
  },
  {
    "zip": 8243,
    "lat": 39.15187,
    "lng": -74.698,
    "state_id": "NJ"
  },
  {
    "zip": 8244,
    "lat": 39.31821,
    "lng": -74.58798,
    "state_id": "NJ"
  },
  {
    "zip": 8246,
    "lat": 39.18094,
    "lng": -74.76575,
    "state_id": "NJ"
  },
  {
    "zip": 8247,
    "lat": 39.05103,
    "lng": -74.78329,
    "state_id": "NJ"
  },
  {
    "zip": 8248,
    "lat": 39.19555,
    "lng": -74.66073,
    "state_id": "NJ"
  },
  {
    "zip": 8251,
    "lat": 39.02704,
    "lng": -74.92736,
    "state_id": "NJ"
  },
  {
    "zip": 8260,
    "lat": 38.9966,
    "lng": -74.8391,
    "state_id": "NJ"
  },
  {
    "zip": 8270,
    "lat": 39.2719,
    "lng": -74.79635,
    "state_id": "NJ"
  },
  {
    "zip": 8302,
    "lat": 39.4397,
    "lng": -75.26078,
    "state_id": "NJ"
  },
  {
    "zip": 8310,
    "lat": 39.52712,
    "lng": -74.89543,
    "state_id": "NJ"
  },
  {
    "zip": 8311,
    "lat": 39.32767,
    "lng": -75.20982,
    "state_id": "NJ"
  },
  {
    "zip": 8312,
    "lat": 39.66131,
    "lng": -75.0802,
    "state_id": "NJ"
  },
  {
    "zip": 8314,
    "lat": 39.21814,
    "lng": -74.94235,
    "state_id": "NJ"
  },
  {
    "zip": 8316,
    "lat": 39.27321,
    "lng": -74.96125,
    "state_id": "NJ"
  },
  {
    "zip": 8317,
    "lat": 39.40409,
    "lng": -74.82828,
    "state_id": "NJ"
  },
  {
    "zip": 8318,
    "lat": 39.5563,
    "lng": -75.17636,
    "state_id": "NJ"
  },
  {
    "zip": 8319,
    "lat": 39.36864,
    "lng": -74.81691,
    "state_id": "NJ"
  },
  {
    "zip": 8320,
    "lat": 39.3851,
    "lng": -75.16059,
    "state_id": "NJ"
  },
  {
    "zip": 8321,
    "lat": 39.22087,
    "lng": -75.1418,
    "state_id": "NJ"
  },
  {
    "zip": 8322,
    "lat": 39.61418,
    "lng": -75.03521,
    "state_id": "NJ"
  },
  {
    "zip": 8323,
    "lat": 39.39557,
    "lng": -75.36605,
    "state_id": "NJ"
  },
  {
    "zip": 8324,
    "lat": 39.21679,
    "lng": -74.99636,
    "state_id": "NJ"
  },
  {
    "zip": 8326,
    "lat": 39.53767,
    "lng": -74.92829,
    "state_id": "NJ"
  },
  {
    "zip": 8327,
    "lat": 39.24962,
    "lng": -74.98212,
    "state_id": "NJ"
  },
  {
    "zip": 8328,
    "lat": 39.5784,
    "lng": -75.05917,
    "state_id": "NJ"
  },
  {
    "zip": 8329,
    "lat": 39.27733,
    "lng": -75.00191,
    "state_id": "NJ"
  },
  {
    "zip": 8330,
    "lat": 39.4761,
    "lng": -74.74148,
    "state_id": "NJ"
  },
  {
    "zip": 8332,
    "lat": 39.37701,
    "lng": -75.02428,
    "state_id": "NJ"
  },
  {
    "zip": 8340,
    "lat": 39.43609,
    "lng": -74.87302,
    "state_id": "NJ"
  },
  {
    "zip": 8341,
    "lat": 39.52548,
    "lng": -74.95257,
    "state_id": "NJ"
  },
  {
    "zip": 8343,
    "lat": 39.64133,
    "lng": -75.17053,
    "state_id": "NJ"
  },
  {
    "zip": 8344,
    "lat": 39.5675,
    "lng": -74.98473,
    "state_id": "NJ"
  },
  {
    "zip": 8345,
    "lat": 39.28108,
    "lng": -75.16505,
    "state_id": "NJ"
  },
  {
    "zip": 8346,
    "lat": 39.56303,
    "lng": -74.85608,
    "state_id": "NJ"
  },
  {
    "zip": 8348,
    "lat": 39.30747,
    "lng": -74.97523,
    "state_id": "NJ"
  },
  {
    "zip": 8349,
    "lat": 39.27408,
    "lng": -75.07138,
    "state_id": "NJ"
  },
  {
    "zip": 8350,
    "lat": 39.49174,
    "lng": -74.87832,
    "state_id": "NJ"
  },
  {
    "zip": 8352,
    "lat": 39.47573,
    "lng": -75.12798,
    "state_id": "NJ"
  },
  {
    "zip": 8353,
    "lat": 39.45954,
    "lng": -75.29695,
    "state_id": "NJ"
  },
  {
    "zip": 8360,
    "lat": 39.49427,
    "lng": -75.00641,
    "state_id": "NJ"
  },
  {
    "zip": 8361,
    "lat": 39.44891,
    "lng": -74.95957,
    "state_id": "NJ"
  },
  {
    "zip": 8401,
    "lat": 39.37961,
    "lng": -74.45278,
    "state_id": "NJ"
  },
  {
    "zip": 8402,
    "lat": 39.3307,
    "lng": -74.50705,
    "state_id": "NJ"
  },
  {
    "zip": 8403,
    "lat": 39.31914,
    "lng": -74.53541,
    "state_id": "NJ"
  },
  {
    "zip": 8406,
    "lat": 39.34567,
    "lng": -74.48602,
    "state_id": "NJ"
  },
  {
    "zip": 8501,
    "lat": 40.15563,
    "lng": -74.55438,
    "state_id": "NJ"
  },
  {
    "zip": 8502,
    "lat": 40.44827,
    "lng": -74.65564,
    "state_id": "NJ"
  },
  {
    "zip": 8505,
    "lat": 40.10462,
    "lng": -74.73938,
    "state_id": "NJ"
  },
  {
    "zip": 8510,
    "lat": 40.19049,
    "lng": -74.4214,
    "state_id": "NJ"
  },
  {
    "zip": 8511,
    "lat": 40.043,
    "lng": -74.55391,
    "state_id": "NJ"
  },
  {
    "zip": 8512,
    "lat": 40.32022,
    "lng": -74.52661,
    "state_id": "NJ"
  },
  {
    "zip": 8514,
    "lat": 40.13303,
    "lng": -74.4964,
    "state_id": "NJ"
  },
  {
    "zip": 8515,
    "lat": 40.11626,
    "lng": -74.64586,
    "state_id": "NJ"
  },
  {
    "zip": 8518,
    "lat": 40.11522,
    "lng": -74.80263,
    "state_id": "NJ"
  },
  {
    "zip": 8520,
    "lat": 40.25527,
    "lng": -74.53163,
    "state_id": "NJ"
  },
  {
    "zip": 8525,
    "lat": 40.39621,
    "lng": -74.78327,
    "state_id": "NJ"
  },
  {
    "zip": 8527,
    "lat": 40.10475,
    "lng": -74.34993,
    "state_id": "NJ"
  },
  {
    "zip": 8528,
    "lat": 40.38659,
    "lng": -74.61873,
    "state_id": "NJ"
  },
  {
    "zip": 8530,
    "lat": 40.37134,
    "lng": -74.89675,
    "state_id": "NJ"
  },
  {
    "zip": 8533,
    "lat": 40.07112,
    "lng": -74.49366,
    "state_id": "NJ"
  },
  {
    "zip": 8534,
    "lat": 40.33008,
    "lng": -74.79294,
    "state_id": "NJ"
  },
  {
    "zip": 8535,
    "lat": 40.23562,
    "lng": -74.43932,
    "state_id": "NJ"
  },
  {
    "zip": 8536,
    "lat": 40.33428,
    "lng": -74.58235,
    "state_id": "NJ"
  },
  {
    "zip": 8540,
    "lat": 40.3633,
    "lng": -74.65567,
    "state_id": "NJ"
  },
  {
    "zip": 8542,
    "lat": 40.35292,
    "lng": -74.66041,
    "state_id": "NJ"
  },
  {
    "zip": 8550,
    "lat": 40.28106,
    "lng": -74.61756,
    "state_id": "NJ"
  },
  {
    "zip": 8551,
    "lat": 40.44173,
    "lng": -74.84011,
    "state_id": "NJ"
  },
  {
    "zip": 8553,
    "lat": 40.40015,
    "lng": -74.64054,
    "state_id": "NJ"
  },
  {
    "zip": 8554,
    "lat": 40.11647,
    "lng": -74.778,
    "state_id": "NJ"
  },
  {
    "zip": 8555,
    "lat": 40.21995,
    "lng": -74.4718,
    "state_id": "NJ"
  },
  {
    "zip": 8558,
    "lat": 40.41354,
    "lng": -74.70392,
    "state_id": "NJ"
  },
  {
    "zip": 8559,
    "lat": 40.4375,
    "lng": -74.97093,
    "state_id": "NJ"
  },
  {
    "zip": 8560,
    "lat": 40.31342,
    "lng": -74.85903,
    "state_id": "NJ"
  },
  {
    "zip": 8561,
    "lat": 40.24943,
    "lng": -74.58082,
    "state_id": "NJ"
  },
  {
    "zip": 8562,
    "lat": 40.06655,
    "lng": -74.59483,
    "state_id": "NJ"
  },
  {
    "zip": 8608,
    "lat": 40.21896,
    "lng": -74.76785,
    "state_id": "NJ"
  },
  {
    "zip": 8609,
    "lat": 40.22595,
    "lng": -74.74081,
    "state_id": "NJ"
  },
  {
    "zip": 8610,
    "lat": 40.19247,
    "lng": -74.71582,
    "state_id": "NJ"
  },
  {
    "zip": 8611,
    "lat": 40.19322,
    "lng": -74.74471,
    "state_id": "NJ"
  },
  {
    "zip": 8618,
    "lat": 40.24823,
    "lng": -74.78773,
    "state_id": "NJ"
  },
  {
    "zip": 8619,
    "lat": 40.24121,
    "lng": -74.69605,
    "state_id": "NJ"
  },
  {
    "zip": 8620,
    "lat": 40.16697,
    "lng": -74.6488,
    "state_id": "NJ"
  },
  {
    "zip": 8628,
    "lat": 40.26627,
    "lng": -74.82334,
    "state_id": "NJ"
  },
  {
    "zip": 8629,
    "lat": 40.22064,
    "lng": -74.73122,
    "state_id": "NJ"
  },
  {
    "zip": 8638,
    "lat": 40.25675,
    "lng": -74.763,
    "state_id": "NJ"
  },
  {
    "zip": 8640,
    "lat": 40.00351,
    "lng": -74.58932,
    "state_id": "NJ"
  },
  {
    "zip": 8641,
    "lat": 40.02703,
    "lng": -74.58803,
    "state_id": "NJ"
  },
  {
    "zip": 8648,
    "lat": 40.28492,
    "lng": -74.72187,
    "state_id": "NJ"
  },
  {
    "zip": 8690,
    "lat": 40.22503,
    "lng": -74.66034,
    "state_id": "NJ"
  },
  {
    "zip": 8691,
    "lat": 40.20976,
    "lng": -74.59379,
    "state_id": "NJ"
  },
  {
    "zip": 8701,
    "lat": 40.07645,
    "lng": -74.20323,
    "state_id": "NJ"
  },
  {
    "zip": 8720,
    "lat": 40.13872,
    "lng": -74.10104,
    "state_id": "NJ"
  },
  {
    "zip": 8721,
    "lat": 39.90417,
    "lng": -74.15523,
    "state_id": "NJ"
  },
  {
    "zip": 8722,
    "lat": 39.92854,
    "lng": -74.20228,
    "state_id": "NJ"
  },
  {
    "zip": 8723,
    "lat": 40.03885,
    "lng": -74.11089,
    "state_id": "NJ"
  },
  {
    "zip": 8724,
    "lat": 40.08988,
    "lng": -74.11458,
    "state_id": "NJ"
  },
  {
    "zip": 8730,
    "lat": 40.10484,
    "lng": -74.06368,
    "state_id": "NJ"
  },
  {
    "zip": 8731,
    "lat": 39.86218,
    "lng": -74.26968,
    "state_id": "NJ"
  },
  {
    "zip": 8732,
    "lat": 39.94175,
    "lng": -74.14494,
    "state_id": "NJ"
  },
  {
    "zip": 8733,
    "lat": 40.02631,
    "lng": -74.32541,
    "state_id": "NJ"
  },
  {
    "zip": 8734,
    "lat": 39.86403,
    "lng": -74.17052,
    "state_id": "NJ"
  },
  {
    "zip": 8735,
    "lat": 39.98229,
    "lng": -74.07259,
    "state_id": "NJ"
  },
  {
    "zip": 8736,
    "lat": 40.12001,
    "lng": -74.06847,
    "state_id": "NJ"
  },
  {
    "zip": 8738,
    "lat": 40.02112,
    "lng": -74.06223,
    "state_id": "NJ"
  },
  {
    "zip": 8740,
    "lat": 39.9282,
    "lng": -74.13378,
    "state_id": "NJ"
  },
  {
    "zip": 8741,
    "lat": 39.93698,
    "lng": -74.16811,
    "state_id": "NJ"
  },
  {
    "zip": 8742,
    "lat": 40.0808,
    "lng": -74.062,
    "state_id": "NJ"
  },
  {
    "zip": 8750,
    "lat": 40.13394,
    "lng": -74.04432,
    "state_id": "NJ"
  },
  {
    "zip": 8751,
    "lat": 39.94634,
    "lng": -74.0836,
    "state_id": "NJ"
  },
  {
    "zip": 8752,
    "lat": 39.8474,
    "lng": -74.09667,
    "state_id": "NJ"
  },
  {
    "zip": 8753,
    "lat": 39.97937,
    "lng": -74.15558,
    "state_id": "NJ"
  },
  {
    "zip": 8755,
    "lat": 40.00967,
    "lng": -74.22456,
    "state_id": "NJ"
  },
  {
    "zip": 8757,
    "lat": 39.94573,
    "lng": -74.25617,
    "state_id": "NJ"
  },
  {
    "zip": 8758,
    "lat": 39.78928,
    "lng": -74.23008,
    "state_id": "NJ"
  },
  {
    "zip": 8759,
    "lat": 39.97836,
    "lng": -74.35623,
    "state_id": "NJ"
  },
  {
    "zip": 8801,
    "lat": 40.6239,
    "lng": -74.88658,
    "state_id": "NJ"
  },
  {
    "zip": 8802,
    "lat": 40.67647,
    "lng": -75.02428,
    "state_id": "NJ"
  },
  {
    "zip": 8804,
    "lat": 40.64554,
    "lng": -75.09525,
    "state_id": "NJ"
  },
  {
    "zip": 8805,
    "lat": 40.57164,
    "lng": -74.53689,
    "state_id": "NJ"
  },
  {
    "zip": 8807,
    "lat": 40.59277,
    "lng": -74.62025,
    "state_id": "NJ"
  },
  {
    "zip": 8808,
    "lat": 40.73214,
    "lng": -75.04926,
    "state_id": "NJ"
  },
  {
    "zip": 8809,
    "lat": 40.65125,
    "lng": -74.92233,
    "state_id": "NJ"
  },
  {
    "zip": 8810,
    "lat": 40.37271,
    "lng": -74.49384,
    "state_id": "NJ"
  },
  {
    "zip": 8812,
    "lat": 40.59977,
    "lng": -74.4807,
    "state_id": "NJ"
  },
  {
    "zip": 8816,
    "lat": 40.42851,
    "lng": -74.41619,
    "state_id": "NJ"
  },
  {
    "zip": 8817,
    "lat": 40.5155,
    "lng": -74.39446,
    "state_id": "NJ"
  },
  {
    "zip": 8820,
    "lat": 40.57722,
    "lng": -74.36588,
    "state_id": "NJ"
  },
  {
    "zip": 8821,
    "lat": 40.52001,
    "lng": -74.68565,
    "state_id": "NJ"
  },
  {
    "zip": 8822,
    "lat": 40.52109,
    "lng": -74.86364,
    "state_id": "NJ"
  },
  {
    "zip": 8823,
    "lat": 40.44153,
    "lng": -74.56506,
    "state_id": "NJ"
  },
  {
    "zip": 8824,
    "lat": 40.42253,
    "lng": -74.55168,
    "state_id": "NJ"
  },
  {
    "zip": 8825,
    "lat": 40.51299,
    "lng": -75.02495,
    "state_id": "NJ"
  },
  {
    "zip": 8826,
    "lat": 40.71955,
    "lng": -74.90505,
    "state_id": "NJ"
  },
  {
    "zip": 8827,
    "lat": 40.66479,
    "lng": -74.97391,
    "state_id": "NJ"
  },
  {
    "zip": 8828,
    "lat": 40.37766,
    "lng": -74.42385,
    "state_id": "NJ"
  },
  {
    "zip": 8829,
    "lat": 40.66801,
    "lng": -74.89421,
    "state_id": "NJ"
  },
  {
    "zip": 8830,
    "lat": 40.56968,
    "lng": -74.31702,
    "state_id": "NJ"
  },
  {
    "zip": 8831,
    "lat": 40.32664,
    "lng": -74.42968,
    "state_id": "NJ"
  },
  {
    "zip": 8832,
    "lat": 40.51723,
    "lng": -74.3077,
    "state_id": "NJ"
  },
  {
    "zip": 8833,
    "lat": 40.64504,
    "lng": -74.82048,
    "state_id": "NJ"
  },
  {
    "zip": 8835,
    "lat": 40.542,
    "lng": -74.58923,
    "state_id": "NJ"
  },
  {
    "zip": 8836,
    "lat": 40.59608,
    "lng": -74.55466,
    "state_id": "NJ"
  },
  {
    "zip": 8837,
    "lat": 40.51363,
    "lng": -74.34465,
    "state_id": "NJ"
  },
  {
    "zip": 8840,
    "lat": 40.54366,
    "lng": -74.35843,
    "state_id": "NJ"
  },
  {
    "zip": 8844,
    "lat": 40.49847,
    "lng": -74.6729,
    "state_id": "NJ"
  },
  {
    "zip": 8846,
    "lat": 40.57442,
    "lng": -74.50104,
    "state_id": "NJ"
  },
  {
    "zip": 8848,
    "lat": 40.59456,
    "lng": -75.09574,
    "state_id": "NJ"
  },
  {
    "zip": 8850,
    "lat": 40.44725,
    "lng": -74.44012,
    "state_id": "NJ"
  },
  {
    "zip": 8852,
    "lat": 40.38788,
    "lng": -74.54822,
    "state_id": "NJ"
  },
  {
    "zip": 8853,
    "lat": 40.52936,
    "lng": -74.74007,
    "state_id": "NJ"
  },
  {
    "zip": 8854,
    "lat": 40.54674,
    "lng": -74.46361,
    "state_id": "NJ"
  },
  {
    "zip": 8857,
    "lat": 40.39161,
    "lng": -74.32984,
    "state_id": "NJ"
  },
  {
    "zip": 8858,
    "lat": 40.67934,
    "lng": -74.73706,
    "state_id": "NJ"
  },
  {
    "zip": 8859,
    "lat": 40.45951,
    "lng": -74.30424,
    "state_id": "NJ"
  },
  {
    "zip": 8861,
    "lat": 40.52097,
    "lng": -74.27408,
    "state_id": "NJ"
  },
  {
    "zip": 8863,
    "lat": 40.52715,
    "lng": -74.31559,
    "state_id": "NJ"
  },
  {
    "zip": 8865,
    "lat": 40.70775,
    "lng": -75.1508,
    "state_id": "NJ"
  },
  {
    "zip": 8867,
    "lat": 40.57358,
    "lng": -74.96578,
    "state_id": "NJ"
  },
  {
    "zip": 8869,
    "lat": 40.57302,
    "lng": -74.64315,
    "state_id": "NJ"
  },
  {
    "zip": 8872,
    "lat": 40.46429,
    "lng": -74.33722,
    "state_id": "NJ"
  },
  {
    "zip": 8873,
    "lat": 40.49836,
    "lng": -74.53286,
    "state_id": "NJ"
  },
  {
    "zip": 8876,
    "lat": 40.58799,
    "lng": -74.68739,
    "state_id": "NJ"
  },
  {
    "zip": 8879,
    "lat": 40.46555,
    "lng": -74.27731,
    "state_id": "NJ"
  },
  {
    "zip": 8880,
    "lat": 40.55353,
    "lng": -74.52772,
    "state_id": "NJ"
  },
  {
    "zip": 8882,
    "lat": 40.4456,
    "lng": -74.37841,
    "state_id": "NJ"
  },
  {
    "zip": 8884,
    "lat": 40.39511,
    "lng": -74.39148,
    "state_id": "NJ"
  },
  {
    "zip": 8886,
    "lat": 40.69315,
    "lng": -75.10526,
    "state_id": "NJ"
  },
  {
    "zip": 8887,
    "lat": 40.52363,
    "lng": -74.79445,
    "state_id": "NJ"
  },
  {
    "zip": 8889,
    "lat": 40.60759,
    "lng": -74.75959,
    "state_id": "NJ"
  },
  {
    "zip": 8890,
    "lat": 40.53934,
    "lng": -74.57394,
    "state_id": "NJ"
  },
  {
    "zip": 8901,
    "lat": 40.48408,
    "lng": -74.44269,
    "state_id": "NJ"
  },
  {
    "zip": 8902,
    "lat": 40.44071,
    "lng": -74.4863,
    "state_id": "NJ"
  },
  {
    "zip": 8904,
    "lat": 40.50056,
    "lng": -74.42829,
    "state_id": "NJ"
  },
  {
    "zip": 87001,
    "lat": 35.38884,
    "lng": -106.3516,
    "state_id": "NM"
  },
  {
    "zip": 87002,
    "lat": 34.60272,
    "lng": -106.6599,
    "state_id": "NM"
  },
  {
    "zip": 87004,
    "lat": 35.35824,
    "lng": -106.58333,
    "state_id": "NM"
  },
  {
    "zip": 87005,
    "lat": 35.26424,
    "lng": -107.97682,
    "state_id": "NM"
  },
  {
    "zip": 87006,
    "lat": 34.49195,
    "lng": -106.84311,
    "state_id": "NM"
  },
  {
    "zip": 87007,
    "lat": 35.03773,
    "lng": -107.50292,
    "state_id": "NM"
  },
  {
    "zip": 87008,
    "lat": 35.14572,
    "lng": -106.3918,
    "state_id": "NM"
  },
  {
    "zip": 87010,
    "lat": 35.39865,
    "lng": -106.12888,
    "state_id": "NM"
  },
  {
    "zip": 87011,
    "lat": 34.06453,
    "lng": -105.94955,
    "state_id": "NM"
  },
  {
    "zip": 87012,
    "lat": 36.1018,
    "lng": -106.66923,
    "state_id": "NM"
  },
  {
    "zip": 87013,
    "lat": 35.90924,
    "lng": -107.39226,
    "state_id": "NM"
  },
  {
    "zip": 87014,
    "lat": 35.21926,
    "lng": -107.38687,
    "state_id": "NM"
  },
  {
    "zip": 87015,
    "lat": 35.07419,
    "lng": -106.18002,
    "state_id": "NM"
  },
  {
    "zip": 87016,
    "lat": 34.77803,
    "lng": -105.92203,
    "state_id": "NM"
  },
  {
    "zip": 87017,
    "lat": 36.32022,
    "lng": -106.76865,
    "state_id": "NM"
  },
  {
    "zip": 87018,
    "lat": 36.13061,
    "lng": -107.5115,
    "state_id": "NM"
  },
  {
    "zip": 87020,
    "lat": 34.93005,
    "lng": -107.93988,
    "state_id": "NM"
  },
  {
    "zip": 87021,
    "lat": 35.19137,
    "lng": -107.88389,
    "state_id": "NM"
  },
  {
    "zip": 87022,
    "lat": 34.89774,
    "lng": -106.70539,
    "state_id": "NM"
  },
  {
    "zip": 87023,
    "lat": 34.59821,
    "lng": -106.76059,
    "state_id": "NM"
  },
  {
    "zip": 87024,
    "lat": 35.68399,
    "lng": -106.73,
    "state_id": "NM"
  },
  {
    "zip": 87025,
    "lat": 35.83959,
    "lng": -106.63483,
    "state_id": "NM"
  },
  {
    "zip": 87026,
    "lat": 34.96242,
    "lng": -107.23255,
    "state_id": "NM"
  },
  {
    "zip": 87027,
    "lat": 36.12632,
    "lng": -106.94777,
    "state_id": "NM"
  },
  {
    "zip": 87028,
    "lat": 34.3621,
    "lng": -106.77283,
    "state_id": "NM"
  },
  {
    "zip": 87029,
    "lat": 36.39636,
    "lng": -106.99763,
    "state_id": "NM"
  },
  {
    "zip": 87031,
    "lat": 34.73181,
    "lng": -106.88782,
    "state_id": "NM"
  },
  {
    "zip": 87032,
    "lat": 34.85126,
    "lng": -106.01138,
    "state_id": "NM"
  },
  {
    "zip": 87034,
    "lat": 34.78174,
    "lng": -107.5614,
    "state_id": "NM"
  },
  {
    "zip": 87035,
    "lat": 34.95997,
    "lng": -105.98054,
    "state_id": "NM"
  },
  {
    "zip": 87036,
    "lat": 34.37816,
    "lng": -106.25927,
    "state_id": "NM"
  },
  {
    "zip": 87037,
    "lat": 36.15984,
    "lng": -107.76644,
    "state_id": "NM"
  },
  {
    "zip": 87038,
    "lat": 35.08945,
    "lng": -107.4352,
    "state_id": "NM"
  },
  {
    "zip": 87040,
    "lat": 35.12356,
    "lng": -107.39163,
    "state_id": "NM"
  },
  {
    "zip": 87041,
    "lat": 35.68785,
    "lng": -106.32989,
    "state_id": "NM"
  },
  {
    "zip": 87042,
    "lat": 34.82798,
    "lng": -106.68653,
    "state_id": "NM"
  },
  {
    "zip": 87043,
    "lat": 35.28806,
    "lng": -106.46606,
    "state_id": "NM"
  },
  {
    "zip": 87044,
    "lat": 35.66552,
    "lng": -106.64425,
    "state_id": "NM"
  },
  {
    "zip": 87045,
    "lat": 35.39241,
    "lng": -108.02154,
    "state_id": "NM"
  },
  {
    "zip": 87046,
    "lat": 36.24694,
    "lng": -106.91609,
    "state_id": "NM"
  },
  {
    "zip": 87047,
    "lat": 35.24327,
    "lng": -106.28953,
    "state_id": "NM"
  },
  {
    "zip": 87048,
    "lat": 35.23602,
    "lng": -106.62004,
    "state_id": "NM"
  },
  {
    "zip": 87049,
    "lat": 35.12405,
    "lng": -107.59336,
    "state_id": "NM"
  },
  {
    "zip": 87051,
    "lat": 35.04865,
    "lng": -107.83409,
    "state_id": "NM"
  },
  {
    "zip": 87052,
    "lat": 35.50479,
    "lng": -106.30689,
    "state_id": "NM"
  },
  {
    "zip": 87053,
    "lat": 35.65124,
    "lng": -107.03361,
    "state_id": "NM"
  },
  {
    "zip": 87056,
    "lat": 35.18882,
    "lng": -105.88649,
    "state_id": "NM"
  },
  {
    "zip": 87059,
    "lat": 34.99401,
    "lng": -106.29716,
    "state_id": "NM"
  },
  {
    "zip": 87061,
    "lat": 34.73119,
    "lng": -106.37961,
    "state_id": "NM"
  },
  {
    "zip": 87062,
    "lat": 34.43995,
    "lng": -106.65882,
    "state_id": "NM"
  },
  {
    "zip": 87063,
    "lat": 34.5011,
    "lng": -105.85749,
    "state_id": "NM"
  },
  {
    "zip": 87064,
    "lat": 36.1862,
    "lng": -106.57686,
    "state_id": "NM"
  },
  {
    "zip": 87068,
    "lat": 34.86111,
    "lng": -106.57306,
    "state_id": "NM"
  },
  {
    "zip": 87070,
    "lat": 35.0219,
    "lng": -105.77764,
    "state_id": "NM"
  },
  {
    "zip": 87072,
    "lat": 35.61197,
    "lng": -106.38808,
    "state_id": "NM"
  },
  {
    "zip": 87083,
    "lat": 35.64844,
    "lng": -106.34271,
    "state_id": "NM"
  },
  {
    "zip": 87102,
    "lat": 35.08098,
    "lng": -106.64664,
    "state_id": "NM"
  },
  {
    "zip": 87104,
    "lat": 35.10338,
    "lng": -106.67537,
    "state_id": "NM"
  },
  {
    "zip": 87105,
    "lat": 34.96917,
    "lng": -106.63094,
    "state_id": "NM"
  },
  {
    "zip": 87106,
    "lat": 35.0582,
    "lng": -106.6216,
    "state_id": "NM"
  },
  {
    "zip": 87107,
    "lat": 35.1374,
    "lng": -106.64303,
    "state_id": "NM"
  },
  {
    "zip": 87108,
    "lat": 35.07254,
    "lng": -106.5784,
    "state_id": "NM"
  },
  {
    "zip": 87109,
    "lat": 35.15297,
    "lng": -106.57541,
    "state_id": "NM"
  },
  {
    "zip": 87110,
    "lat": 35.10811,
    "lng": -106.5781,
    "state_id": "NM"
  },
  {
    "zip": 87111,
    "lat": 35.14382,
    "lng": -106.4862,
    "state_id": "NM"
  },
  {
    "zip": 87112,
    "lat": 35.10108,
    "lng": -106.51629,
    "state_id": "NM"
  },
  {
    "zip": 87113,
    "lat": 35.18356,
    "lng": -106.59231,
    "state_id": "NM"
  },
  {
    "zip": 87114,
    "lat": 35.19711,
    "lng": -106.68401,
    "state_id": "NM"
  },
  {
    "zip": 87116,
    "lat": 35.052,
    "lng": -106.57174,
    "state_id": "NM"
  },
  {
    "zip": 87117,
    "lat": 35.02011,
    "lng": -106.55005,
    "state_id": "NM"
  },
  {
    "zip": 87120,
    "lat": 35.13508,
    "lng": -106.73708,
    "state_id": "NM"
  },
  {
    "zip": 87121,
    "lat": 35.05852,
    "lng": -106.87791,
    "state_id": "NM"
  },
  {
    "zip": 87122,
    "lat": 35.19069,
    "lng": -106.49886,
    "state_id": "NM"
  },
  {
    "zip": 87123,
    "lat": 35.07105,
    "lng": -106.46794,
    "state_id": "NM"
  },
  {
    "zip": 87124,
    "lat": 35.26775,
    "lng": -106.78288,
    "state_id": "NM"
  },
  {
    "zip": 87144,
    "lat": 35.32392,
    "lng": -106.70973,
    "state_id": "NM"
  },
  {
    "zip": 87301,
    "lat": 35.47483,
    "lng": -108.83461,
    "state_id": "NM"
  },
  {
    "zip": 87305,
    "lat": 35.3839,
    "lng": -108.80091,
    "state_id": "NM"
  },
  {
    "zip": 87310,
    "lat": 35.79415,
    "lng": -108.53908,
    "state_id": "NM"
  },
  {
    "zip": 87311,
    "lat": 35.6101,
    "lng": -108.51293,
    "state_id": "NM"
  },
  {
    "zip": 87312,
    "lat": 35.42263,
    "lng": -108.32303,
    "state_id": "NM"
  },
  {
    "zip": 87313,
    "lat": 35.8141,
    "lng": -108.07867,
    "state_id": "NM"
  },
  {
    "zip": 87315,
    "lat": 34.74291,
    "lng": -108.68277,
    "state_id": "NM"
  },
  {
    "zip": 87316,
    "lat": 35.4396,
    "lng": -108.45318,
    "state_id": "NM"
  },
  {
    "zip": 87317,
    "lat": 35.58757,
    "lng": -108.76101,
    "state_id": "NM"
  },
  {
    "zip": 87319,
    "lat": 35.47231,
    "lng": -108.9505,
    "state_id": "NM"
  },
  {
    "zip": 87320,
    "lat": 35.80791,
    "lng": -108.87611,
    "state_id": "NM"
  },
  {
    "zip": 87321,
    "lat": 35.00127,
    "lng": -108.37074,
    "state_id": "NM"
  },
  {
    "zip": 87322,
    "lat": 35.52603,
    "lng": -108.65311,
    "state_id": "NM"
  },
  {
    "zip": 87323,
    "lat": 35.455,
    "lng": -108.1991,
    "state_id": "NM"
  },
  {
    "zip": 87325,
    "lat": 36.00208,
    "lng": -108.62286,
    "state_id": "NM"
  },
  {
    "zip": 87326,
    "lat": 35.26584,
    "lng": -108.83133,
    "state_id": "NM"
  },
  {
    "zip": 87327,
    "lat": 34.98022,
    "lng": -108.78819,
    "state_id": "NM"
  },
  {
    "zip": 87328,
    "lat": 35.97473,
    "lng": -108.98609,
    "state_id": "NM"
  },
  {
    "zip": 87347,
    "lat": 35.42727,
    "lng": -108.44644,
    "state_id": "NM"
  },
  {
    "zip": 87357,
    "lat": 34.94704,
    "lng": -108.3587,
    "state_id": "NM"
  },
  {
    "zip": 87364,
    "lat": 36.14192,
    "lng": -108.70648,
    "state_id": "NM"
  },
  {
    "zip": 87375,
    "lat": 35.67201,
    "lng": -108.75616,
    "state_id": "NM"
  },
  {
    "zip": 87401,
    "lat": 36.74121,
    "lng": -108.17992,
    "state_id": "NM"
  },
  {
    "zip": 87402,
    "lat": 36.78065,
    "lng": -108.14402,
    "state_id": "NM"
  },
  {
    "zip": 87410,
    "lat": 36.86691,
    "lng": -107.87985,
    "state_id": "NM"
  },
  {
    "zip": 87412,
    "lat": 36.69881,
    "lng": -107.47851,
    "state_id": "NM"
  },
  {
    "zip": 87413,
    "lat": 36.41515,
    "lng": -107.97672,
    "state_id": "NM"
  },
  {
    "zip": 87415,
    "lat": 36.81831,
    "lng": -108.09743,
    "state_id": "NM"
  },
  {
    "zip": 87416,
    "lat": 36.69316,
    "lng": -108.4212,
    "state_id": "NM"
  },
  {
    "zip": 87417,
    "lat": 36.74406,
    "lng": -108.345,
    "state_id": "NM"
  },
  {
    "zip": 87418,
    "lat": 36.95199,
    "lng": -108.15521,
    "state_id": "NM"
  },
  {
    "zip": 87419,
    "lat": 36.88504,
    "lng": -107.50236,
    "state_id": "NM"
  },
  {
    "zip": 87420,
    "lat": 36.69066,
    "lng": -108.84928,
    "state_id": "NM"
  },
  {
    "zip": 87421,
    "lat": 36.76368,
    "lng": -108.51858,
    "state_id": "NM"
  },
  {
    "zip": 87455,
    "lat": 36.3244,
    "lng": -108.64179,
    "state_id": "NM"
  },
  {
    "zip": 87461,
    "lat": 36.33533,
    "lng": -108.92874,
    "state_id": "NM"
  },
  {
    "zip": 87499,
    "lat": 36.34902,
    "lng": -108.21084,
    "state_id": "NM"
  },
  {
    "zip": 87501,
    "lat": 35.74235,
    "lng": -105.84143,
    "state_id": "NM"
  },
  {
    "zip": 87505,
    "lat": 35.62193,
    "lng": -105.86873,
    "state_id": "NM"
  },
  {
    "zip": 87506,
    "lat": 35.81952,
    "lng": -105.98861,
    "state_id": "NM"
  },
  {
    "zip": 87507,
    "lat": 35.59345,
    "lng": -106.10777,
    "state_id": "NM"
  },
  {
    "zip": 87508,
    "lat": 35.52744,
    "lng": -105.96873,
    "state_id": "NM"
  },
  {
    "zip": 87510,
    "lat": 36.29328,
    "lng": -106.41423,
    "state_id": "NM"
  },
  {
    "zip": 87511,
    "lat": 36.10226,
    "lng": -105.92584,
    "state_id": "NM"
  },
  {
    "zip": 87512,
    "lat": 36.90902,
    "lng": -105.37427,
    "state_id": "NM"
  },
  {
    "zip": 87513,
    "lat": 36.57957,
    "lng": -105.68733,
    "state_id": "NM"
  },
  {
    "zip": 87514,
    "lat": 36.54126,
    "lng": -105.52521,
    "state_id": "NM"
  },
  {
    "zip": 87515,
    "lat": 36.50231,
    "lng": -106.37718,
    "state_id": "NM"
  },
  {
    "zip": 87516,
    "lat": 36.16236,
    "lng": -106.46572,
    "state_id": "NM"
  },
  {
    "zip": 87517,
    "lat": 36.3949,
    "lng": -105.78756,
    "state_id": "NM"
  },
  {
    "zip": 87518,
    "lat": 36.50733,
    "lng": -106.56265,
    "state_id": "NM"
  },
  {
    "zip": 87519,
    "lat": 36.76989,
    "lng": -105.62796,
    "state_id": "NM"
  },
  {
    "zip": 87520,
    "lat": 36.88271,
    "lng": -106.62705,
    "state_id": "NM"
  },
  {
    "zip": 87521,
    "lat": 36.07629,
    "lng": -105.69802,
    "state_id": "NM"
  },
  {
    "zip": 87522,
    "lat": 35.93801,
    "lng": -105.83647,
    "state_id": "NM"
  },
  {
    "zip": 87523,
    "lat": 36.01364,
    "lng": -105.84268,
    "state_id": "NM"
  },
  {
    "zip": 87524,
    "lat": 36.93242,
    "lng": -105.67231,
    "state_id": "NM"
  },
  {
    "zip": 87525,
    "lat": 36.58686,
    "lng": -105.46136,
    "state_id": "NM"
  },
  {
    "zip": 87527,
    "lat": 36.16447,
    "lng": -105.8627,
    "state_id": "NM"
  },
  {
    "zip": 87528,
    "lat": 36.66881,
    "lng": -106.98213,
    "state_id": "NM"
  },
  {
    "zip": 87529,
    "lat": 36.47006,
    "lng": -105.62274,
    "state_id": "NM"
  },
  {
    "zip": 87530,
    "lat": 36.34928,
    "lng": -106.19133,
    "state_id": "NM"
  },
  {
    "zip": 87531,
    "lat": 36.24753,
    "lng": -105.8509,
    "state_id": "NM"
  },
  {
    "zip": 87532,
    "lat": 35.99328,
    "lng": -106.08987,
    "state_id": "NM"
  },
  {
    "zip": 87533,
    "lat": 36.02388,
    "lng": -106.06493,
    "state_id": "NM"
  },
  {
    "zip": 87535,
    "lat": 35.52874,
    "lng": -105.74774,
    "state_id": "NM"
  },
  {
    "zip": 87537,
    "lat": 36.04961,
    "lng": -106.18535,
    "state_id": "NM"
  },
  {
    "zip": 87538,
    "lat": 35.49455,
    "lng": -105.57126,
    "state_id": "NM"
  },
  {
    "zip": 87539,
    "lat": 36.38171,
    "lng": -106.03924,
    "state_id": "NM"
  },
  {
    "zip": 87540,
    "lat": 35.44515,
    "lng": -105.90366,
    "state_id": "NM"
  },
  {
    "zip": 87543,
    "lat": 36.107,
    "lng": -105.6612,
    "state_id": "NM"
  },
  {
    "zip": 87544,
    "lat": 35.84233,
    "lng": -106.29079,
    "state_id": "NM"
  },
  {
    "zip": 87548,
    "lat": 36.18298,
    "lng": -106.15596,
    "state_id": "NM"
  },
  {
    "zip": 87549,
    "lat": 36.2414,
    "lng": -106.01215,
    "state_id": "NM"
  },
  {
    "zip": 87551,
    "lat": 36.69507,
    "lng": -106.66806,
    "state_id": "NM"
  },
  {
    "zip": 87552,
    "lat": 35.64618,
    "lng": -105.60163,
    "state_id": "NM"
  },
  {
    "zip": 87553,
    "lat": 36.20595,
    "lng": -105.70695,
    "state_id": "NM"
  },
  {
    "zip": 87554,
    "lat": 36.48752,
    "lng": -106.02537,
    "state_id": "NM"
  },
  {
    "zip": 87556,
    "lat": 36.76309,
    "lng": -105.53864,
    "state_id": "NM"
  },
  {
    "zip": 87557,
    "lat": 36.28667,
    "lng": -105.6835,
    "state_id": "NM"
  },
  {
    "zip": 87558,
    "lat": 36.68695,
    "lng": -105.39021,
    "state_id": "NM"
  },
  {
    "zip": 87560,
    "lat": 35.22435,
    "lng": -105.50236,
    "state_id": "NM"
  },
  {
    "zip": 87562,
    "lat": 35.41692,
    "lng": -105.68447,
    "state_id": "NM"
  },
  {
    "zip": 87564,
    "lat": 36.60798,
    "lng": -105.62786,
    "state_id": "NM"
  },
  {
    "zip": 87565,
    "lat": 35.45598,
    "lng": -105.49284,
    "state_id": "NM"
  },
  {
    "zip": 87566,
    "lat": 36.07121,
    "lng": -106.07011,
    "state_id": "NM"
  },
  {
    "zip": 87567,
    "lat": 35.9939,
    "lng": -106.02023,
    "state_id": "NM"
  },
  {
    "zip": 87569,
    "lat": 35.3464,
    "lng": -105.30068,
    "state_id": "NM"
  },
  {
    "zip": 87571,
    "lat": 36.39918,
    "lng": -105.53627,
    "state_id": "NM"
  },
  {
    "zip": 87573,
    "lat": 35.77291,
    "lng": -105.67126,
    "state_id": "NM"
  },
  {
    "zip": 87574,
    "lat": 35.81671,
    "lng": -105.88857,
    "state_id": "NM"
  },
  {
    "zip": 87575,
    "lat": 36.74607,
    "lng": -106.38491,
    "state_id": "NM"
  },
  {
    "zip": 87577,
    "lat": 36.67246,
    "lng": -105.98125,
    "state_id": "NM"
  },
  {
    "zip": 87578,
    "lat": 36.03903,
    "lng": -105.7653,
    "state_id": "NM"
  },
  {
    "zip": 87579,
    "lat": 36.12477,
    "lng": -105.55659,
    "state_id": "NM"
  },
  {
    "zip": 87580,
    "lat": 36.57008,
    "lng": -105.56577,
    "state_id": "NM"
  },
  {
    "zip": 87581,
    "lat": 36.52862,
    "lng": -106.14479,
    "state_id": "NM"
  },
  {
    "zip": 87582,
    "lat": 36.15503,
    "lng": -106.01298,
    "state_id": "NM"
  },
  {
    "zip": 87583,
    "lat": 35.2313,
    "lng": -105.33623,
    "state_id": "NM"
  },
  {
    "zip": 87701,
    "lat": 35.53869,
    "lng": -104.92699,
    "state_id": "NM"
  },
  {
    "zip": 87710,
    "lat": 36.3847,
    "lng": -105.24389,
    "state_id": "NM"
  },
  {
    "zip": 87711,
    "lat": 35.22451,
    "lng": -105.10452,
    "state_id": "NM"
  },
  {
    "zip": 87712,
    "lat": 35.86843,
    "lng": -105.17042,
    "state_id": "NM"
  },
  {
    "zip": 87713,
    "lat": 36.16713,
    "lng": -105.36485,
    "state_id": "NM"
  },
  {
    "zip": 87714,
    "lat": 36.51452,
    "lng": -105.03183,
    "state_id": "NM"
  },
  {
    "zip": 87715,
    "lat": 36.01816,
    "lng": -105.42581,
    "state_id": "NM"
  },
  {
    "zip": 87718,
    "lat": 36.59004,
    "lng": -105.29664,
    "state_id": "NM"
  },
  {
    "zip": 87722,
    "lat": 36.15243,
    "lng": -105.24648,
    "state_id": "NM"
  },
  {
    "zip": 87723,
    "lat": 36.05875,
    "lng": -105.37558,
    "state_id": "NM"
  },
  {
    "zip": 87724,
    "lat": 35.17355,
    "lng": -105.07814,
    "state_id": "NM"
  },
  {
    "zip": 87728,
    "lat": 36.59629,
    "lng": -104.61989,
    "state_id": "NM"
  },
  {
    "zip": 87729,
    "lat": 36.33255,
    "lng": -104.8006,
    "state_id": "NM"
  },
  {
    "zip": 87730,
    "lat": 36.14842,
    "lng": -104.21107,
    "state_id": "NM"
  },
  {
    "zip": 87731,
    "lat": 35.73196,
    "lng": -105.41386,
    "state_id": "NM"
  },
  {
    "zip": 87732,
    "lat": 35.9634,
    "lng": -105.3675,
    "state_id": "NM"
  },
  {
    "zip": 87733,
    "lat": 35.80997,
    "lng": -103.82487,
    "state_id": "NM"
  },
  {
    "zip": 87734,
    "lat": 36.16938,
    "lng": -105.07791,
    "state_id": "NM"
  },
  {
    "zip": 87735,
    "lat": 36.07344,
    "lng": -105.1471,
    "state_id": "NM"
  },
  {
    "zip": 87736,
    "lat": 35.98112,
    "lng": -105.19545,
    "state_id": "NM"
  },
  {
    "zip": 87740,
    "lat": 36.75538,
    "lng": -104.41045,
    "state_id": "NM"
  },
  {
    "zip": 87742,
    "lat": 35.8884,
    "lng": -105.51851,
    "state_id": "NM"
  },
  {
    "zip": 87743,
    "lat": 35.98141,
    "lng": -104.08691,
    "state_id": "NM"
  },
  {
    "zip": 87745,
    "lat": 35.78161,
    "lng": -105.24435,
    "state_id": "NM"
  },
  {
    "zip": 87746,
    "lat": 35.64111,
    "lng": -104.23613,
    "state_id": "NM"
  },
  {
    "zip": 87747,
    "lat": 36.36658,
    "lng": -104.46312,
    "state_id": "NM"
  },
  {
    "zip": 87749,
    "lat": 36.54831,
    "lng": -105.08379,
    "state_id": "NM"
  },
  {
    "zip": 87750,
    "lat": 35.82539,
    "lng": -104.92706,
    "state_id": "NM"
  },
  {
    "zip": 87752,
    "lat": 36.01907,
    "lng": -104.64816,
    "state_id": "NM"
  },
  {
    "zip": 87753,
    "lat": 35.85806,
    "lng": -104.96389,
    "state_id": "NM"
  },
  {
    "zip": 87801,
    "lat": 34.13037,
    "lng": -106.84601,
    "state_id": "NM"
  },
  {
    "zip": 87820,
    "lat": 33.89521,
    "lng": -108.54735,
    "state_id": "NM"
  },
  {
    "zip": 87821,
    "lat": 33.97948,
    "lng": -108.05949,
    "state_id": "NM"
  },
  {
    "zip": 87823,
    "lat": 34.19268,
    "lng": -106.9488,
    "state_id": "NM"
  },
  {
    "zip": 87824,
    "lat": 33.82744,
    "lng": -108.97822,
    "state_id": "NM"
  },
  {
    "zip": 87825,
    "lat": 33.8756,
    "lng": -107.63263,
    "state_id": "NM"
  },
  {
    "zip": 87827,
    "lat": 34.40185,
    "lng": -108.10286,
    "state_id": "NM"
  },
  {
    "zip": 87828,
    "lat": 34.20595,
    "lng": -106.91672,
    "state_id": "NM"
  },
  {
    "zip": 87829,
    "lat": 34.32592,
    "lng": -108.65876,
    "state_id": "NM"
  },
  {
    "zip": 87830,
    "lat": 33.7196,
    "lng": -108.73952,
    "state_id": "NM"
  },
  {
    "zip": 87831,
    "lat": 34.26503,
    "lng": -106.93317,
    "state_id": "NM"
  },
  {
    "zip": 87832,
    "lat": 33.8189,
    "lng": -106.8284,
    "state_id": "NM"
  },
  {
    "zip": 87901,
    "lat": 33.19995,
    "lng": -107.14359,
    "state_id": "NM"
  },
  {
    "zip": 87930,
    "lat": 32.83387,
    "lng": -107.43342,
    "state_id": "NM"
  },
  {
    "zip": 87931,
    "lat": 32.96411,
    "lng": -107.41022,
    "state_id": "NM"
  },
  {
    "zip": 87933,
    "lat": 32.79024,
    "lng": -107.27793,
    "state_id": "NM"
  },
  {
    "zip": 87935,
    "lat": 33.25398,
    "lng": -107.22754,
    "state_id": "NM"
  },
  {
    "zip": 87936,
    "lat": 32.7532,
    "lng": -107.26365,
    "state_id": "NM"
  },
  {
    "zip": 87937,
    "lat": 32.5998,
    "lng": -107.19131,
    "state_id": "NM"
  },
  {
    "zip": 87939,
    "lat": 33.41286,
    "lng": -107.48874,
    "state_id": "NM"
  },
  {
    "zip": 87940,
    "lat": 32.61067,
    "lng": -106.99736,
    "state_id": "NM"
  },
  {
    "zip": 87941,
    "lat": 32.71095,
    "lng": -107.22014,
    "state_id": "NM"
  },
  {
    "zip": 87942,
    "lat": 33.09827,
    "lng": -107.43514,
    "state_id": "NM"
  },
  {
    "zip": 87943,
    "lat": 33.39161,
    "lng": -107.73215,
    "state_id": "NM"
  },
  {
    "zip": 88001,
    "lat": 32.2901,
    "lng": -106.75394,
    "state_id": "NM"
  },
  {
    "zip": 88002,
    "lat": 32.46284,
    "lng": -106.48007,
    "state_id": "NM"
  },
  {
    "zip": 88003,
    "lat": 32.28103,
    "lng": -106.74589,
    "state_id": "NM"
  },
  {
    "zip": 88004,
    "lat": 32.2231,
    "lng": -106.98744,
    "state_id": "NM"
  },
  {
    "zip": 88005,
    "lat": 32.2581,
    "lng": -106.82185,
    "state_id": "NM"
  },
  {
    "zip": 88007,
    "lat": 32.31084,
    "lng": -107.02653,
    "state_id": "NM"
  },
  {
    "zip": 88008,
    "lat": 31.83928,
    "lng": -106.68173,
    "state_id": "NM"
  },
  {
    "zip": 88009,
    "lat": 32.01011,
    "lng": -108.59661,
    "state_id": "NM"
  },
  {
    "zip": 88011,
    "lat": 32.31585,
    "lng": -106.6437,
    "state_id": "NM"
  },
  {
    "zip": 88012,
    "lat": 32.58346,
    "lng": -106.77146,
    "state_id": "NM"
  },
  {
    "zip": 88020,
    "lat": 31.77074,
    "lng": -108.82822,
    "state_id": "NM"
  },
  {
    "zip": 88021,
    "lat": 31.95488,
    "lng": -107.01802,
    "state_id": "NM"
  },
  {
    "zip": 88022,
    "lat": 32.79981,
    "lng": -108.17998,
    "state_id": "NM"
  },
  {
    "zip": 88023,
    "lat": 32.75127,
    "lng": -108.1324,
    "state_id": "NM"
  },
  {
    "zip": 88024,
    "lat": 32.0706,
    "lng": -106.63951,
    "state_id": "NM"
  },
  {
    "zip": 88025,
    "lat": 33.04515,
    "lng": -108.75149,
    "state_id": "NM"
  },
  {
    "zip": 88026,
    "lat": 32.7596,
    "lng": -108.15742,
    "state_id": "NM"
  },
  {
    "zip": 88027,
    "lat": 32.04414,
    "lng": -106.78959,
    "state_id": "NM"
  },
  {
    "zip": 88028,
    "lat": 32.93289,
    "lng": -108.63592,
    "state_id": "NM"
  },
  {
    "zip": 88029,
    "lat": 31.8459,
    "lng": -107.69127,
    "state_id": "NM"
  },
  {
    "zip": 88030,
    "lat": 32.18773,
    "lng": -107.72945,
    "state_id": "NM"
  },
  {
    "zip": 88032,
    "lat": 32.39614,
    "lng": -106.81614,
    "state_id": "NM"
  },
  {
    "zip": 88033,
    "lat": 32.31953,
    "lng": -107.02885,
    "state_id": "NM"
  },
  {
    "zip": 88034,
    "lat": 32.60966,
    "lng": -107.93929,
    "state_id": "NM"
  },
  {
    "zip": 88038,
    "lat": 33.07212,
    "lng": -108.42651,
    "state_id": "NM"
  },
  {
    "zip": 88039,
    "lat": 33.34884,
    "lng": -108.72204,
    "state_id": "NM"
  },
  {
    "zip": 88040,
    "lat": 31.61683,
    "lng": -108.43763,
    "state_id": "NM"
  },
  {
    "zip": 88041,
    "lat": 32.7907,
    "lng": -107.91398,
    "state_id": "NM"
  },
  {
    "zip": 88042,
    "lat": 32.80696,
    "lng": -107.58906,
    "state_id": "NM"
  },
  {
    "zip": 88043,
    "lat": 32.62091,
    "lng": -108.11154,
    "state_id": "NM"
  },
  {
    "zip": 88044,
    "lat": 32.13825,
    "lng": -106.79987,
    "state_id": "NM"
  },
  {
    "zip": 88045,
    "lat": 32.3796,
    "lng": -108.77269,
    "state_id": "NM"
  },
  {
    "zip": 88046,
    "lat": 32.25,
    "lng": -106.8023,
    "state_id": "NM"
  },
  {
    "zip": 88047,
    "lat": 32.21003,
    "lng": -106.71473,
    "state_id": "NM"
  },
  {
    "zip": 88048,
    "lat": 32.16352,
    "lng": -106.65918,
    "state_id": "NM"
  },
  {
    "zip": 88049,
    "lat": 33.05048,
    "lng": -107.96276,
    "state_id": "NM"
  },
  {
    "zip": 88051,
    "lat": 33.01613,
    "lng": -108.94876,
    "state_id": "NM"
  },
  {
    "zip": 88052,
    "lat": 32.42536,
    "lng": -106.59941,
    "state_id": "NM"
  },
  {
    "zip": 88053,
    "lat": 32.9025,
    "lng": -108.20595,
    "state_id": "NM"
  },
  {
    "zip": 88055,
    "lat": 32.73817,
    "lng": -108.73398,
    "state_id": "NM"
  },
  {
    "zip": 88056,
    "lat": 31.84421,
    "lng": -108.98254,
    "state_id": "NM"
  },
  {
    "zip": 88061,
    "lat": 32.64983,
    "lng": -108.33017,
    "state_id": "NM"
  },
  {
    "zip": 88063,
    "lat": 31.81825,
    "lng": -106.5994,
    "state_id": "NM"
  },
  {
    "zip": 88065,
    "lat": 32.685,
    "lng": -108.32017,
    "state_id": "NM"
  },
  {
    "zip": 88072,
    "lat": 32.14052,
    "lng": -106.61286,
    "state_id": "NM"
  },
  {
    "zip": 88081,
    "lat": 32.22412,
    "lng": -106.26298,
    "state_id": "NM"
  },
  {
    "zip": 88101,
    "lat": 34.4987,
    "lng": -103.2838,
    "state_id": "NM"
  },
  {
    "zip": 88103,
    "lat": 34.38412,
    "lng": -103.31544,
    "state_id": "NM"
  },
  {
    "zip": 88112,
    "lat": 34.86451,
    "lng": -103.17105,
    "state_id": "NM"
  },
  {
    "zip": 88113,
    "lat": 33.77807,
    "lng": -103.08654,
    "state_id": "NM"
  },
  {
    "zip": 88114,
    "lat": 33.51844,
    "lng": -103.28904,
    "state_id": "NM"
  },
  {
    "zip": 88115,
    "lat": 33.92314,
    "lng": -103.34251,
    "state_id": "NM"
  },
  {
    "zip": 88116,
    "lat": 33.91829,
    "lng": -103.87993,
    "state_id": "NM"
  },
  {
    "zip": 88118,
    "lat": 34.21924,
    "lng": -103.67162,
    "state_id": "NM"
  },
  {
    "zip": 88119,
    "lat": 34.32303,
    "lng": -104.40797,
    "state_id": "NM"
  },
  {
    "zip": 88120,
    "lat": 34.87865,
    "lng": -103.4542,
    "state_id": "NM"
  },
  {
    "zip": 88121,
    "lat": 34.71464,
    "lng": -103.98069,
    "state_id": "NM"
  },
  {
    "zip": 88124,
    "lat": 34.54625,
    "lng": -103.65358,
    "state_id": "NM"
  },
  {
    "zip": 88125,
    "lat": 33.68518,
    "lng": -103.28026,
    "state_id": "NM"
  },
  {
    "zip": 88126,
    "lat": 33.83096,
    "lng": -103.37685,
    "state_id": "NM"
  },
  {
    "zip": 88130,
    "lat": 34.13556,
    "lng": -103.31049,
    "state_id": "NM"
  },
  {
    "zip": 88132,
    "lat": 33.92431,
    "lng": -103.19459,
    "state_id": "NM"
  },
  {
    "zip": 88134,
    "lat": 34.32702,
    "lng": -104.01581,
    "state_id": "NM"
  },
  {
    "zip": 88135,
    "lat": 34.5867,
    "lng": -103.09334,
    "state_id": "NM"
  },
  {
    "zip": 88136,
    "lat": 34.45503,
    "lng": -104.74524,
    "state_id": "NM"
  },
  {
    "zip": 88201,
    "lat": 33.63974,
    "lng": -104.37492,
    "state_id": "NM"
  },
  {
    "zip": 88203,
    "lat": 33.18609,
    "lng": -104.34426,
    "state_id": "NM"
  },
  {
    "zip": 88210,
    "lat": 32.77172,
    "lng": -104.21957,
    "state_id": "NM"
  },
  {
    "zip": 88220,
    "lat": 32.29635,
    "lng": -104.41326,
    "state_id": "NM"
  },
  {
    "zip": 88230,
    "lat": 33.21777,
    "lng": -104.39873,
    "state_id": "NM"
  },
  {
    "zip": 88231,
    "lat": 32.44904,
    "lng": -103.25497,
    "state_id": "NM"
  },
  {
    "zip": 88232,
    "lat": 33.07947,
    "lng": -104.45942,
    "state_id": "NM"
  },
  {
    "zip": 88240,
    "lat": 32.70781,
    "lng": -103.47152,
    "state_id": "NM"
  },
  {
    "zip": 88242,
    "lat": 32.81047,
    "lng": -103.15864,
    "state_id": "NM"
  },
  {
    "zip": 88250,
    "lat": 32.72828,
    "lng": -104.91836,
    "state_id": "NM"
  },
  {
    "zip": 88252,
    "lat": 32.1693,
    "lng": -103.38987,
    "state_id": "NM"
  },
  {
    "zip": 88253,
    "lat": 32.98609,
    "lng": -104.45485,
    "state_id": "NM"
  },
  {
    "zip": 88254,
    "lat": 32.60872,
    "lng": -104.4489,
    "state_id": "NM"
  },
  {
    "zip": 88255,
    "lat": 32.81422,
    "lng": -103.99645,
    "state_id": "NM"
  },
  {
    "zip": 88256,
    "lat": 32.19587,
    "lng": -103.89273,
    "state_id": "NM"
  },
  {
    "zip": 88260,
    "lat": 32.99151,
    "lng": -103.39911,
    "state_id": "NM"
  },
  {
    "zip": 88262,
    "lat": 33.13287,
    "lng": -103.42588,
    "state_id": "NM"
  },
  {
    "zip": 88263,
    "lat": 32.09407,
    "lng": -104.07823,
    "state_id": "NM"
  },
  {
    "zip": 88264,
    "lat": 32.89768,
    "lng": -103.70224,
    "state_id": "NM"
  },
  {
    "zip": 88265,
    "lat": 32.62045,
    "lng": -103.3029,
    "state_id": "NM"
  },
  {
    "zip": 88267,
    "lat": 33.33191,
    "lng": -103.42182,
    "state_id": "NM"
  },
  {
    "zip": 88268,
    "lat": 32.17619,
    "lng": -104.37953,
    "state_id": "NM"
  },
  {
    "zip": 88301,
    "lat": 33.79263,
    "lng": -105.78565,
    "state_id": "NM"
  },
  {
    "zip": 88310,
    "lat": 32.70076,
    "lng": -106.00576,
    "state_id": "NM"
  },
  {
    "zip": 88311,
    "lat": 32.80751,
    "lng": -105.98864,
    "state_id": "NM"
  },
  {
    "zip": 88312,
    "lat": 33.43147,
    "lng": -105.67741,
    "state_id": "NM"
  },
  {
    "zip": 88314,
    "lat": 33.10073,
    "lng": -105.86726,
    "state_id": "NM"
  },
  {
    "zip": 88316,
    "lat": 33.79595,
    "lng": -105.31892,
    "state_id": "NM"
  },
  {
    "zip": 88317,
    "lat": 32.9157,
    "lng": -105.63923,
    "state_id": "NM"
  },
  {
    "zip": 88318,
    "lat": 34.20837,
    "lng": -105.3855,
    "state_id": "NM"
  },
  {
    "zip": 88321,
    "lat": 34.76222,
    "lng": -105.44878,
    "state_id": "NM"
  },
  {
    "zip": 88323,
    "lat": 33.49479,
    "lng": -105.52745,
    "state_id": "NM"
  },
  {
    "zip": 88324,
    "lat": 33.38988,
    "lng": -105.43913,
    "state_id": "NM"
  },
  {
    "zip": 88325,
    "lat": 32.85121,
    "lng": -105.8626,
    "state_id": "NM"
  },
  {
    "zip": 88330,
    "lat": 32.83659,
    "lng": -106.07738,
    "state_id": "NM"
  },
  {
    "zip": 88336,
    "lat": 33.3182,
    "lng": -105.29484,
    "state_id": "NM"
  },
  {
    "zip": 88337,
    "lat": 32.99129,
    "lng": -105.89153,
    "state_id": "NM"
  },
  {
    "zip": 88338,
    "lat": 33.4869,
    "lng": -105.38177,
    "state_id": "NM"
  },
  {
    "zip": 88339,
    "lat": 32.93508,
    "lng": -105.25424,
    "state_id": "NM"
  },
  {
    "zip": 88340,
    "lat": 33.17829,
    "lng": -105.6119,
    "state_id": "NM"
  },
  {
    "zip": 88341,
    "lat": 33.49517,
    "lng": -105.78799,
    "state_id": "NM"
  },
  {
    "zip": 88342,
    "lat": 32.38792,
    "lng": -106.10153,
    "state_id": "NM"
  },
  {
    "zip": 88343,
    "lat": 33.2258,
    "lng": -105.07714,
    "state_id": "NM"
  },
  {
    "zip": 88344,
    "lat": 32.5699,
    "lng": -105.31133,
    "state_id": "NM"
  },
  {
    "zip": 88345,
    "lat": 33.34798,
    "lng": -105.68001,
    "state_id": "NM"
  },
  {
    "zip": 88346,
    "lat": 33.35376,
    "lng": -105.54007,
    "state_id": "NM"
  },
  {
    "zip": 88347,
    "lat": 32.76768,
    "lng": -105.65711,
    "state_id": "NM"
  },
  {
    "zip": 88348,
    "lat": 33.39619,
    "lng": -105.35842,
    "state_id": "NM"
  },
  {
    "zip": 88349,
    "lat": 32.76434,
    "lng": -105.78603,
    "state_id": "NM"
  },
  {
    "zip": 88350,
    "lat": 32.64305,
    "lng": -105.67741,
    "state_id": "NM"
  },
  {
    "zip": 88351,
    "lat": 33.54952,
    "lng": -105.08763,
    "state_id": "NM"
  },
  {
    "zip": 88352,
    "lat": 33.03756,
    "lng": -106.10175,
    "state_id": "NM"
  },
  {
    "zip": 88353,
    "lat": 34.57144,
    "lng": -105.11609,
    "state_id": "NM"
  },
  {
    "zip": 88354,
    "lat": 32.74362,
    "lng": -105.51458,
    "state_id": "NM"
  },
  {
    "zip": 88355,
    "lat": 33.36303,
    "lng": -105.66826,
    "state_id": "NM"
  },
  {
    "zip": 88401,
    "lat": 35.11258,
    "lng": -103.83173,
    "state_id": "NM"
  },
  {
    "zip": 88410,
    "lat": 35.87396,
    "lng": -103.29185,
    "state_id": "NM"
  },
  {
    "zip": 88411,
    "lat": 35.13678,
    "lng": -103.16049,
    "state_id": "NM"
  },
  {
    "zip": 88414,
    "lat": 36.77852,
    "lng": -103.99843,
    "state_id": "NM"
  },
  {
    "zip": 88415,
    "lat": 36.31373,
    "lng": -103.32582,
    "state_id": "NM"
  },
  {
    "zip": 88416,
    "lat": 35.31347,
    "lng": -104.20726,
    "state_id": "NM"
  },
  {
    "zip": 88417,
    "lat": 35.05147,
    "lng": -104.39122,
    "state_id": "NM"
  },
  {
    "zip": 88418,
    "lat": 36.72511,
    "lng": -103.68341,
    "state_id": "NM"
  },
  {
    "zip": 88419,
    "lat": 36.88276,
    "lng": -103.53903,
    "state_id": "NM"
  },
  {
    "zip": 88421,
    "lat": 35.29834,
    "lng": -104.48127,
    "state_id": "NM"
  },
  {
    "zip": 88422,
    "lat": 36.34694,
    "lng": -103.99939,
    "state_id": "NM"
  },
  {
    "zip": 88424,
    "lat": 36.40931,
    "lng": -103.661,
    "state_id": "NM"
  },
  {
    "zip": 88426,
    "lat": 35.53808,
    "lng": -103.51084,
    "state_id": "NM"
  },
  {
    "zip": 88427,
    "lat": 34.77048,
    "lng": -103.77441,
    "state_id": "NM"
  },
  {
    "zip": 88430,
    "lat": 35.59028,
    "lng": -103.18118,
    "state_id": "NM"
  },
  {
    "zip": 88431,
    "lat": 35.17599,
    "lng": -104.27799,
    "state_id": "NM"
  },
  {
    "zip": 88434,
    "lat": 35.18597,
    "lng": -103.30237,
    "state_id": "NM"
  },
  {
    "zip": 88435,
    "lat": 34.91298,
    "lng": -104.69767,
    "state_id": "NM"
  },
  {
    "zip": 88436,
    "lat": 36.13154,
    "lng": -103.12165,
    "state_id": "NM"
  },
  {
    "zip": 89001,
    "lat": 37.27386,
    "lng": -115.39051,
    "state_id": "NV"
  },
  {
    "zip": 89002,
    "lat": 35.99857,
    "lng": -114.96171,
    "state_id": "NV"
  },
  {
    "zip": 89003,
    "lat": 36.95743,
    "lng": -116.7262,
    "state_id": "NV"
  },
  {
    "zip": 89004,
    "lat": 36.08529,
    "lng": -115.47079,
    "state_id": "NV"
  },
  {
    "zip": 89005,
    "lat": 35.98675,
    "lng": -114.82466,
    "state_id": "NV"
  },
  {
    "zip": 89007,
    "lat": 36.63481,
    "lng": -114.18809,
    "state_id": "NV"
  },
  {
    "zip": 89008,
    "lat": 37.27637,
    "lng": -114.54633,
    "state_id": "NV"
  },
  {
    "zip": 89010,
    "lat": 37.77764,
    "lng": -118.08391,
    "state_id": "NV"
  },
  {
    "zip": 89011,
    "lat": 36.0828,
    "lng": -114.96848,
    "state_id": "NV"
  },
  {
    "zip": 89012,
    "lat": 36.01194,
    "lng": -115.04397,
    "state_id": "NV"
  },
  {
    "zip": 89013,
    "lat": 37.3979,
    "lng": -117.403,
    "state_id": "NV"
  },
  {
    "zip": 89014,
    "lat": 36.06172,
    "lng": -115.05811,
    "state_id": "NV"
  },
  {
    "zip": 89015,
    "lat": 36.03921,
    "lng": -114.92817,
    "state_id": "NV"
  },
  {
    "zip": 89017,
    "lat": 37.73096,
    "lng": -115.24786,
    "state_id": "NV"
  },
  {
    "zip": 89018,
    "lat": 36.54161,
    "lng": -115.66863,
    "state_id": "NV"
  },
  {
    "zip": 89019,
    "lat": 35.76621,
    "lng": -115.73453,
    "state_id": "NV"
  },
  {
    "zip": 89020,
    "lat": 36.53671,
    "lng": -116.4348,
    "state_id": "NV"
  },
  {
    "zip": 89021,
    "lat": 36.63094,
    "lng": -114.46701,
    "state_id": "NV"
  },
  {
    "zip": 89022,
    "lat": 38.541,
    "lng": -117.02063,
    "state_id": "NV"
  },
  {
    "zip": 89025,
    "lat": 36.61511,
    "lng": -114.9107,
    "state_id": "NV"
  },
  {
    "zip": 89026,
    "lat": 35.75522,
    "lng": -115.28964,
    "state_id": "NV"
  },
  {
    "zip": 89027,
    "lat": 36.81122,
    "lng": -114.12363,
    "state_id": "NV"
  },
  {
    "zip": 89029,
    "lat": 35.12559,
    "lng": -114.68628,
    "state_id": "NV"
  },
  {
    "zip": 89030,
    "lat": 36.2115,
    "lng": -115.12414,
    "state_id": "NV"
  },
  {
    "zip": 89031,
    "lat": 36.25893,
    "lng": -115.17175,
    "state_id": "NV"
  },
  {
    "zip": 89032,
    "lat": 36.22321,
    "lng": -115.17272,
    "state_id": "NV"
  },
  {
    "zip": 89039,
    "lat": 35.28794,
    "lng": -114.8799,
    "state_id": "NV"
  },
  {
    "zip": 89040,
    "lat": 36.35808,
    "lng": -114.5386,
    "state_id": "NV"
  },
  {
    "zip": 89042,
    "lat": 37.74396,
    "lng": -114.32223,
    "state_id": "NV"
  },
  {
    "zip": 89043,
    "lat": 38.25741,
    "lng": -114.47219,
    "state_id": "NV"
  },
  {
    "zip": 89044,
    "lat": 35.90247,
    "lng": -115.17885,
    "state_id": "NV"
  },
  {
    "zip": 89045,
    "lat": 38.87842,
    "lng": -116.95211,
    "state_id": "NV"
  },
  {
    "zip": 89046,
    "lat": 35.51337,
    "lng": -114.88657,
    "state_id": "NV"
  },
  {
    "zip": 89047,
    "lat": 37.7659,
    "lng": -117.64791,
    "state_id": "NV"
  },
  {
    "zip": 89048,
    "lat": 36.16602,
    "lng": -116.00381,
    "state_id": "NV"
  },
  {
    "zip": 89049,
    "lat": 38.36445,
    "lng": -116.26157,
    "state_id": "NV"
  },
  {
    "zip": 89052,
    "lat": 35.9551,
    "lng": -115.05673,
    "state_id": "NV"
  },
  {
    "zip": 89054,
    "lat": 35.92722,
    "lng": -115.20828,
    "state_id": "NV"
  },
  {
    "zip": 89060,
    "lat": 36.39425,
    "lng": -116.06352,
    "state_id": "NV"
  },
  {
    "zip": 89061,
    "lat": 36.08762,
    "lng": -115.89658,
    "state_id": "NV"
  },
  {
    "zip": 89074,
    "lat": 36.03659,
    "lng": -115.08087,
    "state_id": "NV"
  },
  {
    "zip": 89081,
    "lat": 36.25833,
    "lng": -115.10677,
    "state_id": "NV"
  },
  {
    "zip": 89084,
    "lat": 36.2969,
    "lng": -115.17408,
    "state_id": "NV"
  },
  {
    "zip": 89085,
    "lat": 36.30958,
    "lng": -115.19819,
    "state_id": "NV"
  },
  {
    "zip": 89086,
    "lat": 36.29227,
    "lng": -115.10844,
    "state_id": "NV"
  },
  {
    "zip": 89101,
    "lat": 36.17251,
    "lng": -115.12228,
    "state_id": "NV"
  },
  {
    "zip": 89102,
    "lat": 36.14535,
    "lng": -115.18684,
    "state_id": "NV"
  },
  {
    "zip": 89103,
    "lat": 36.11179,
    "lng": -115.21172,
    "state_id": "NV"
  },
  {
    "zip": 89104,
    "lat": 36.15136,
    "lng": -115.10856,
    "state_id": "NV"
  },
  {
    "zip": 89106,
    "lat": 36.1817,
    "lng": -115.16325,
    "state_id": "NV"
  },
  {
    "zip": 89107,
    "lat": 36.17066,
    "lng": -115.21028,
    "state_id": "NV"
  },
  {
    "zip": 89108,
    "lat": 36.20531,
    "lng": -115.22367,
    "state_id": "NV"
  },
  {
    "zip": 89109,
    "lat": 36.12535,
    "lng": -115.16372,
    "state_id": "NV"
  },
  {
    "zip": 89110,
    "lat": 36.17138,
    "lng": -115.04771,
    "state_id": "NV"
  },
  {
    "zip": 89113,
    "lat": 36.06123,
    "lng": -115.2635,
    "state_id": "NV"
  },
  {
    "zip": 89115,
    "lat": 36.25367,
    "lng": -115.041,
    "state_id": "NV"
  },
  {
    "zip": 89117,
    "lat": 36.14257,
    "lng": -115.28003,
    "state_id": "NV"
  },
  {
    "zip": 89118,
    "lat": 36.07725,
    "lng": -115.21396,
    "state_id": "NV"
  },
  {
    "zip": 89119,
    "lat": 36.08476,
    "lng": -115.14614,
    "state_id": "NV"
  },
  {
    "zip": 89120,
    "lat": 36.08132,
    "lng": -115.09546,
    "state_id": "NV"
  },
  {
    "zip": 89121,
    "lat": 36.12152,
    "lng": -115.09127,
    "state_id": "NV"
  },
  {
    "zip": 89122,
    "lat": 36.10639,
    "lng": -115.04028,
    "state_id": "NV"
  },
  {
    "zip": 89123,
    "lat": 36.03525,
    "lng": -115.14874,
    "state_id": "NV"
  },
  {
    "zip": 89124,
    "lat": 36.42582,
    "lng": -115.48089,
    "state_id": "NV"
  },
  {
    "zip": 89128,
    "lat": 36.19682,
    "lng": -115.26436,
    "state_id": "NV"
  },
  {
    "zip": 89129,
    "lat": 36.23331,
    "lng": -115.29016,
    "state_id": "NV"
  },
  {
    "zip": 89130,
    "lat": 36.25405,
    "lng": -115.22708,
    "state_id": "NV"
  },
  {
    "zip": 89131,
    "lat": 36.30619,
    "lng": -115.24268,
    "state_id": "NV"
  },
  {
    "zip": 89134,
    "lat": 36.20274,
    "lng": -115.30777,
    "state_id": "NV"
  },
  {
    "zip": 89135,
    "lat": 36.10099,
    "lng": -115.37588,
    "state_id": "NV"
  },
  {
    "zip": 89138,
    "lat": 36.16665,
    "lng": -115.36122,
    "state_id": "NV"
  },
  {
    "zip": 89139,
    "lat": 36.03449,
    "lng": -115.21164,
    "state_id": "NV"
  },
  {
    "zip": 89141,
    "lat": 35.98838,
    "lng": -115.20704,
    "state_id": "NV"
  },
  {
    "zip": 89142,
    "lat": 36.1479,
    "lng": -115.03637,
    "state_id": "NV"
  },
  {
    "zip": 89143,
    "lat": 36.32231,
    "lng": -115.29318,
    "state_id": "NV"
  },
  {
    "zip": 89144,
    "lat": 36.1789,
    "lng": -115.32074,
    "state_id": "NV"
  },
  {
    "zip": 89145,
    "lat": 36.1677,
    "lng": -115.27787,
    "state_id": "NV"
  },
  {
    "zip": 89146,
    "lat": 36.14325,
    "lng": -115.22693,
    "state_id": "NV"
  },
  {
    "zip": 89147,
    "lat": 36.1128,
    "lng": -115.28016,
    "state_id": "NV"
  },
  {
    "zip": 89148,
    "lat": 36.0588,
    "lng": -115.31039,
    "state_id": "NV"
  },
  {
    "zip": 89149,
    "lat": 36.27223,
    "lng": -115.29254,
    "state_id": "NV"
  },
  {
    "zip": 89156,
    "lat": 36.16345,
    "lng": -114.98822,
    "state_id": "NV"
  },
  {
    "zip": 89161,
    "lat": 36.00035,
    "lng": -115.36387,
    "state_id": "NV"
  },
  {
    "zip": 89166,
    "lat": 36.38065,
    "lng": -115.50689,
    "state_id": "NV"
  },
  {
    "zip": 89169,
    "lat": 36.12415,
    "lng": -115.14128,
    "state_id": "NV"
  },
  {
    "zip": 89178,
    "lat": 35.99768,
    "lng": -115.28607,
    "state_id": "NV"
  },
  {
    "zip": 89179,
    "lat": 35.8946,
    "lng": -115.33187,
    "state_id": "NV"
  },
  {
    "zip": 89183,
    "lat": 35.99588,
    "lng": -115.15762,
    "state_id": "NV"
  },
  {
    "zip": 89191,
    "lat": 36.23961,
    "lng": -115.02563,
    "state_id": "NV"
  },
  {
    "zip": 89301,
    "lat": 39.54452,
    "lng": -114.77305,
    "state_id": "NV"
  },
  {
    "zip": 89310,
    "lat": 39.41354,
    "lng": -117.16463,
    "state_id": "NV"
  },
  {
    "zip": 89311,
    "lat": 38.98711,
    "lng": -114.19431,
    "state_id": "NV"
  },
  {
    "zip": 89314,
    "lat": 38.85481,
    "lng": -115.76709,
    "state_id": "NV"
  },
  {
    "zip": 89316,
    "lat": 39.63087,
    "lng": -116.17716,
    "state_id": "NV"
  },
  {
    "zip": 89317,
    "lat": 38.65835,
    "lng": -115.09913,
    "state_id": "NV"
  },
  {
    "zip": 89318,
    "lat": 39.43856,
    "lng": -114.80356,
    "state_id": "NV"
  },
  {
    "zip": 89319,
    "lat": 39.29631,
    "lng": -114.97601,
    "state_id": "NV"
  },
  {
    "zip": 89402,
    "lat": 39.22549,
    "lng": -120.00295,
    "state_id": "NV"
  },
  {
    "zip": 89403,
    "lat": 39.19832,
    "lng": -119.47024,
    "state_id": "NV"
  },
  {
    "zip": 89404,
    "lat": 41.88683,
    "lng": -118.70584,
    "state_id": "NV"
  },
  {
    "zip": 89405,
    "lat": 40.48051,
    "lng": -119.42448,
    "state_id": "NV"
  },
  {
    "zip": 89406,
    "lat": 39.52221,
    "lng": -118.31663,
    "state_id": "NV"
  },
  {
    "zip": 89408,
    "lat": 39.5641,
    "lng": -119.17158,
    "state_id": "NV"
  },
  {
    "zip": 89409,
    "lat": 38.78468,
    "lng": -118.10324,
    "state_id": "NV"
  },
  {
    "zip": 89410,
    "lat": 38.87028,
    "lng": -119.61147,
    "state_id": "NV"
  },
  {
    "zip": 89411,
    "lat": 39.01902,
    "lng": -119.85021,
    "state_id": "NV"
  },
  {
    "zip": 89412,
    "lat": 41.10776,
    "lng": -119.69948,
    "state_id": "NV"
  },
  {
    "zip": 89413,
    "lat": 39.07389,
    "lng": -119.92096,
    "state_id": "NV"
  },
  {
    "zip": 89414,
    "lat": 41.04623,
    "lng": -117.17819,
    "state_id": "NV"
  },
  {
    "zip": 89415,
    "lat": 38.48546,
    "lng": -118.6415,
    "state_id": "NV"
  },
  {
    "zip": 89418,
    "lat": 40.54841,
    "lng": -118.03436,
    "state_id": "NV"
  },
  {
    "zip": 89419,
    "lat": 40.2717,
    "lng": -118.179,
    "state_id": "NV"
  },
  {
    "zip": 89420,
    "lat": 38.54458,
    "lng": -118.22671,
    "state_id": "NV"
  },
  {
    "zip": 89421,
    "lat": 42.05134,
    "lng": -117.90083,
    "state_id": "NV"
  },
  {
    "zip": 89422,
    "lat": 38.17367,
    "lng": -118.41484,
    "state_id": "NV"
  },
  {
    "zip": 89423,
    "lat": 39.01761,
    "lng": -119.74877,
    "state_id": "NV"
  },
  {
    "zip": 89424,
    "lat": 39.851,
    "lng": -119.32368,
    "state_id": "NV"
  },
  {
    "zip": 89425,
    "lat": 41.66538,
    "lng": -117.90827,
    "state_id": "NV"
  },
  {
    "zip": 89426,
    "lat": 41.48814,
    "lng": -117.43099,
    "state_id": "NV"
  },
  {
    "zip": 89427,
    "lat": 38.92055,
    "lng": -118.70037,
    "state_id": "NV"
  },
  {
    "zip": 89428,
    "lat": 39.26102,
    "lng": -119.63457,
    "state_id": "NV"
  },
  {
    "zip": 89429,
    "lat": 39.36397,
    "lng": -119.30531,
    "state_id": "NV"
  },
  {
    "zip": 89430,
    "lat": 38.7751,
    "lng": -119.30508,
    "state_id": "NV"
  },
  {
    "zip": 89431,
    "lat": 39.54046,
    "lng": -119.7495,
    "state_id": "NV"
  },
  {
    "zip": 89433,
    "lat": 39.60898,
    "lng": -119.77665,
    "state_id": "NV"
  },
  {
    "zip": 89434,
    "lat": 39.53209,
    "lng": -119.64042,
    "state_id": "NV"
  },
  {
    "zip": 89436,
    "lat": 39.60434,
    "lng": -119.68778,
    "state_id": "NV"
  },
  {
    "zip": 89438,
    "lat": 40.77331,
    "lng": -117.1078,
    "state_id": "NV"
  },
  {
    "zip": 89439,
    "lat": 39.52955,
    "lng": -120.00394,
    "state_id": "NV"
  },
  {
    "zip": 89440,
    "lat": 39.29648,
    "lng": -119.65873,
    "state_id": "NV"
  },
  {
    "zip": 89441,
    "lat": 39.67635,
    "lng": -119.6758,
    "state_id": "NV"
  },
  {
    "zip": 89442,
    "lat": 39.65978,
    "lng": -119.42121,
    "state_id": "NV"
  },
  {
    "zip": 89444,
    "lat": 38.70709,
    "lng": -119.37194,
    "state_id": "NV"
  },
  {
    "zip": 89445,
    "lat": 41.16908,
    "lng": -118.27422,
    "state_id": "NV"
  },
  {
    "zip": 89446,
    "lat": 41.21713,
    "lng": -117.87034,
    "state_id": "NV"
  },
  {
    "zip": 89447,
    "lat": 38.8122,
    "lng": -119.13121,
    "state_id": "NV"
  },
  {
    "zip": 89448,
    "lat": 39.00784,
    "lng": -119.92412,
    "state_id": "NV"
  },
  {
    "zip": 89449,
    "lat": 38.96431,
    "lng": -119.90684,
    "state_id": "NV"
  },
  {
    "zip": 89450,
    "lat": 39.24043,
    "lng": -119.93903,
    "state_id": "NV"
  },
  {
    "zip": 89451,
    "lat": 39.24152,
    "lng": -119.93386,
    "state_id": "NV"
  },
  {
    "zip": 89460,
    "lat": 38.90108,
    "lng": -119.79057,
    "state_id": "NV"
  },
  {
    "zip": 89501,
    "lat": 39.52566,
    "lng": -119.81303,
    "state_id": "NV"
  },
  {
    "zip": 89502,
    "lat": 39.49206,
    "lng": -119.74507,
    "state_id": "NV"
  },
  {
    "zip": 89503,
    "lat": 39.54076,
    "lng": -119.83983,
    "state_id": "NV"
  },
  {
    "zip": 89506,
    "lat": 39.70138,
    "lng": -119.82276,
    "state_id": "NV"
  },
  {
    "zip": 89508,
    "lat": 39.79286,
    "lng": -119.91954,
    "state_id": "NV"
  },
  {
    "zip": 89509,
    "lat": 39.49663,
    "lng": -119.82726,
    "state_id": "NV"
  },
  {
    "zip": 89510,
    "lat": 40.05586,
    "lng": -119.66305,
    "state_id": "NV"
  },
  {
    "zip": 89511,
    "lat": 39.3953,
    "lng": -119.89494,
    "state_id": "NV"
  },
  {
    "zip": 89512,
    "lat": 39.55284,
    "lng": -119.80072,
    "state_id": "NV"
  },
  {
    "zip": 89519,
    "lat": 39.48135,
    "lng": -119.85904,
    "state_id": "NV"
  },
  {
    "zip": 89521,
    "lat": 39.38099,
    "lng": -119.68598,
    "state_id": "NV"
  },
  {
    "zip": 89523,
    "lat": 39.524,
    "lng": -119.91376,
    "state_id": "NV"
  },
  {
    "zip": 89701,
    "lat": 39.13966,
    "lng": -119.71837,
    "state_id": "NV"
  },
  {
    "zip": 89702,
    "lat": 39.15921,
    "lng": -119.73599,
    "state_id": "NV"
  },
  {
    "zip": 89703,
    "lat": 39.1639,
    "lng": -119.81277,
    "state_id": "NV"
  },
  {
    "zip": 89704,
    "lat": 39.26682,
    "lng": -119.8174,
    "state_id": "NV"
  },
  {
    "zip": 89705,
    "lat": 39.11057,
    "lng": -119.86226,
    "state_id": "NV"
  },
  {
    "zip": 89706,
    "lat": 39.21975,
    "lng": -119.70611,
    "state_id": "NV"
  },
  {
    "zip": 89801,
    "lat": 41.24905,
    "lng": -115.7589,
    "state_id": "NV"
  },
  {
    "zip": 89815,
    "lat": 40.6171,
    "lng": -115.59563,
    "state_id": "NV"
  },
  {
    "zip": 89820,
    "lat": 40.42657,
    "lng": -116.97366,
    "state_id": "NV"
  },
  {
    "zip": 89821,
    "lat": 40.49241,
    "lng": -116.46224,
    "state_id": "NV"
  },
  {
    "zip": 89822,
    "lat": 40.53131,
    "lng": -116.19417,
    "state_id": "NV"
  },
  {
    "zip": 89823,
    "lat": 41.00476,
    "lng": -115.27447,
    "state_id": "NV"
  },
  {
    "zip": 89825,
    "lat": 41.88816,
    "lng": -114.72328,
    "state_id": "NV"
  },
  {
    "zip": 89826,
    "lat": 41.8873,
    "lng": -115.38128,
    "state_id": "NV"
  },
  {
    "zip": 89828,
    "lat": 40.75053,
    "lng": -115.36309,
    "state_id": "NV"
  },
  {
    "zip": 89830,
    "lat": 41.27533,
    "lng": -114.28821,
    "state_id": "NV"
  },
  {
    "zip": 89831,
    "lat": 41.73253,
    "lng": -116.11224,
    "state_id": "NV"
  },
  {
    "zip": 89832,
    "lat": 41.98653,
    "lng": -116.16574,
    "state_id": "NV"
  },
  {
    "zip": 89833,
    "lat": 40.47107,
    "lng": -115.31693,
    "state_id": "NV"
  },
  {
    "zip": 89834,
    "lat": 41.37383,
    "lng": -116.2641,
    "state_id": "NV"
  },
  {
    "zip": 89835,
    "lat": 41.1147,
    "lng": -114.85451,
    "state_id": "NV"
  },
  {
    "zip": 89883,
    "lat": 40.72545,
    "lng": -114.24179,
    "state_id": "NV"
  },
  {
    "zip": 6390,
    "lat": 41.27024,
    "lng": -71.98783,
    "state_id": "NY"
  },
  {
    "zip": 10001,
    "lat": 40.75065,
    "lng": -73.99718,
    "state_id": "NY"
  },
  {
    "zip": 10002,
    "lat": 40.7157,
    "lng": -73.98638,
    "state_id": "NY"
  },
  {
    "zip": 10003,
    "lat": 40.7318,
    "lng": -73.98911,
    "state_id": "NY"
  },
  {
    "zip": 10004,
    "lat": 40.69465,
    "lng": -74.02106,
    "state_id": "NY"
  },
  {
    "zip": 10005,
    "lat": 40.70614,
    "lng": -74.00891,
    "state_id": "NY"
  },
  {
    "zip": 10006,
    "lat": 40.70968,
    "lng": -74.01284,
    "state_id": "NY"
  },
  {
    "zip": 10007,
    "lat": 40.71395,
    "lng": -74.00764,
    "state_id": "NY"
  },
  {
    "zip": 10009,
    "lat": 40.72633,
    "lng": -73.97861,
    "state_id": "NY"
  },
  {
    "zip": 10010,
    "lat": 40.73911,
    "lng": -73.98265,
    "state_id": "NY"
  },
  {
    "zip": 10011,
    "lat": 40.74187,
    "lng": -74.00052,
    "state_id": "NY"
  },
  {
    "zip": 10012,
    "lat": 40.72584,
    "lng": -73.998,
    "state_id": "NY"
  },
  {
    "zip": 10013,
    "lat": 40.72022,
    "lng": -74.00463,
    "state_id": "NY"
  },
  {
    "zip": 10014,
    "lat": 40.73374,
    "lng": -74.00687,
    "state_id": "NY"
  },
  {
    "zip": 10016,
    "lat": 40.74515,
    "lng": -73.9783,
    "state_id": "NY"
  },
  {
    "zip": 10017,
    "lat": 40.75221,
    "lng": -73.97251,
    "state_id": "NY"
  },
  {
    "zip": 10018,
    "lat": 40.75533,
    "lng": -73.99311,
    "state_id": "NY"
  },
  {
    "zip": 10019,
    "lat": 40.7657,
    "lng": -73.98706,
    "state_id": "NY"
  },
  {
    "zip": 10020,
    "lat": 40.75906,
    "lng": -73.98026,
    "state_id": "NY"
  },
  {
    "zip": 10021,
    "lat": 40.76939,
    "lng": -73.95871,
    "state_id": "NY"
  },
  {
    "zip": 10022,
    "lat": 40.75859,
    "lng": -73.96793,
    "state_id": "NY"
  },
  {
    "zip": 10023,
    "lat": 40.77601,
    "lng": -73.98269,
    "state_id": "NY"
  },
  {
    "zip": 10024,
    "lat": 40.79227,
    "lng": -73.97482,
    "state_id": "NY"
  },
  {
    "zip": 10025,
    "lat": 40.79857,
    "lng": -73.96659,
    "state_id": "NY"
  },
  {
    "zip": 10026,
    "lat": 40.80245,
    "lng": -73.9526,
    "state_id": "NY"
  },
  {
    "zip": 10027,
    "lat": 40.81184,
    "lng": -73.9534,
    "state_id": "NY"
  },
  {
    "zip": 10028,
    "lat": 40.7763,
    "lng": -73.95372,
    "state_id": "NY"
  },
  {
    "zip": 10029,
    "lat": 40.79173,
    "lng": -73.94396,
    "state_id": "NY"
  },
  {
    "zip": 10030,
    "lat": 40.81823,
    "lng": -73.94288,
    "state_id": "NY"
  },
  {
    "zip": 10031,
    "lat": 40.82518,
    "lng": -73.95013,
    "state_id": "NY"
  },
  {
    "zip": 10032,
    "lat": 40.83884,
    "lng": -73.94279,
    "state_id": "NY"
  },
  {
    "zip": 10033,
    "lat": 40.8506,
    "lng": -73.93391,
    "state_id": "NY"
  },
  {
    "zip": 10034,
    "lat": 40.86715,
    "lng": -73.9241,
    "state_id": "NY"
  },
  {
    "zip": 10035,
    "lat": 40.79551,
    "lng": -73.92967,
    "state_id": "NY"
  },
  {
    "zip": 10036,
    "lat": 40.75927,
    "lng": -73.98999,
    "state_id": "NY"
  },
  {
    "zip": 10037,
    "lat": 40.81287,
    "lng": -73.93739,
    "state_id": "NY"
  },
  {
    "zip": 10038,
    "lat": 40.70916,
    "lng": -74.00279,
    "state_id": "NY"
  },
  {
    "zip": 10039,
    "lat": 40.83076,
    "lng": -73.93619,
    "state_id": "NY"
  },
  {
    "zip": 10040,
    "lat": 40.85821,
    "lng": -73.93053,
    "state_id": "NY"
  },
  {
    "zip": 10044,
    "lat": 40.76166,
    "lng": -73.95017,
    "state_id": "NY"
  },
  {
    "zip": 10065,
    "lat": 40.76474,
    "lng": -73.96314,
    "state_id": "NY"
  },
  {
    "zip": 10069,
    "lat": 40.77555,
    "lng": -73.99035,
    "state_id": "NY"
  },
  {
    "zip": 10075,
    "lat": 40.77335,
    "lng": -73.95622,
    "state_id": "NY"
  },
  {
    "zip": 10103,
    "lat": 40.76078,
    "lng": -73.97767,
    "state_id": "NY"
  },
  {
    "zip": 10110,
    "lat": 40.7545,
    "lng": -73.98226,
    "state_id": "NY"
  },
  {
    "zip": 10111,
    "lat": 40.75892,
    "lng": -73.97746,
    "state_id": "NY"
  },
  {
    "zip": 10112,
    "lat": 40.75917,
    "lng": -73.97967,
    "state_id": "NY"
  },
  {
    "zip": 10115,
    "lat": 40.81085,
    "lng": -73.96374,
    "state_id": "NY"
  },
  {
    "zip": 10119,
    "lat": 40.75031,
    "lng": -73.99298,
    "state_id": "NY"
  },
  {
    "zip": 10128,
    "lat": 40.78138,
    "lng": -73.95016,
    "state_id": "NY"
  },
  {
    "zip": 10152,
    "lat": 40.7584,
    "lng": -73.97203,
    "state_id": "NY"
  },
  {
    "zip": 10153,
    "lat": 40.76362,
    "lng": -73.97244,
    "state_id": "NY"
  },
  {
    "zip": 10154,
    "lat": 40.75778,
    "lng": -73.97249,
    "state_id": "NY"
  },
  {
    "zip": 10162,
    "lat": 40.76931,
    "lng": -73.94993,
    "state_id": "NY"
  },
  {
    "zip": 10165,
    "lat": 40.75213,
    "lng": -73.97872,
    "state_id": "NY"
  },
  {
    "zip": 10167,
    "lat": 40.75479,
    "lng": -73.97495,
    "state_id": "NY"
  },
  {
    "zip": 10168,
    "lat": 40.75145,
    "lng": -73.9771,
    "state_id": "NY"
  },
  {
    "zip": 10169,
    "lat": 40.75439,
    "lng": -73.9761,
    "state_id": "NY"
  },
  {
    "zip": 10170,
    "lat": 40.75263,
    "lng": -73.97588,
    "state_id": "NY"
  },
  {
    "zip": 10171,
    "lat": 40.7559,
    "lng": -73.97386,
    "state_id": "NY"
  },
  {
    "zip": 10172,
    "lat": 40.75527,
    "lng": -73.97431,
    "state_id": "NY"
  },
  {
    "zip": 10173,
    "lat": 40.75413,
    "lng": -73.97936,
    "state_id": "NY"
  },
  {
    "zip": 10174,
    "lat": 40.75144,
    "lng": -73.975,
    "state_id": "NY"
  },
  {
    "zip": 10177,
    "lat": 40.75498,
    "lng": -73.97589,
    "state_id": "NY"
  },
  {
    "zip": 10199,
    "lat": 40.75138,
    "lng": -73.99715,
    "state_id": "NY"
  },
  {
    "zip": 10271,
    "lat": 40.70824,
    "lng": -74.01054,
    "state_id": "NY"
  },
  {
    "zip": 10278,
    "lat": 40.71514,
    "lng": -74.00377,
    "state_id": "NY"
  },
  {
    "zip": 10279,
    "lat": 40.71263,
    "lng": -74.00867,
    "state_id": "NY"
  },
  {
    "zip": 10280,
    "lat": 40.71025,
    "lng": -74.01672,
    "state_id": "NY"
  },
  {
    "zip": 10282,
    "lat": 40.71683,
    "lng": -74.01494,
    "state_id": "NY"
  },
  {
    "zip": 10301,
    "lat": 40.62846,
    "lng": -74.0962,
    "state_id": "NY"
  },
  {
    "zip": 10302,
    "lat": 40.63043,
    "lng": -74.13772,
    "state_id": "NY"
  },
  {
    "zip": 10303,
    "lat": 40.63239,
    "lng": -74.16905,
    "state_id": "NY"
  },
  {
    "zip": 10304,
    "lat": 40.60607,
    "lng": -74.09418,
    "state_id": "NY"
  },
  {
    "zip": 10305,
    "lat": 40.59538,
    "lng": -74.07543,
    "state_id": "NY"
  },
  {
    "zip": 10306,
    "lat": 40.57152,
    "lng": -74.12535,
    "state_id": "NY"
  },
  {
    "zip": 10307,
    "lat": 40.50794,
    "lng": -74.23965,
    "state_id": "NY"
  },
  {
    "zip": 10308,
    "lat": 40.55152,
    "lng": -74.15038,
    "state_id": "NY"
  },
  {
    "zip": 10309,
    "lat": 40.53132,
    "lng": -74.22056,
    "state_id": "NY"
  },
  {
    "zip": 10310,
    "lat": 40.63236,
    "lng": -74.11616,
    "state_id": "NY"
  },
  {
    "zip": 10311,
    "lat": 40.60524,
    "lng": -74.17956,
    "state_id": "NY"
  },
  {
    "zip": 10312,
    "lat": 40.54534,
    "lng": -74.18153,
    "state_id": "NY"
  },
  {
    "zip": 10314,
    "lat": 40.59913,
    "lng": -74.16572,
    "state_id": "NY"
  },
  {
    "zip": 10451,
    "lat": 40.82077,
    "lng": -73.92387,
    "state_id": "NY"
  },
  {
    "zip": 10452,
    "lat": 40.83739,
    "lng": -73.92344,
    "state_id": "NY"
  },
  {
    "zip": 10453,
    "lat": 40.85225,
    "lng": -73.91358,
    "state_id": "NY"
  },
  {
    "zip": 10454,
    "lat": 40.80549,
    "lng": -73.91661,
    "state_id": "NY"
  },
  {
    "zip": 10455,
    "lat": 40.81478,
    "lng": -73.90862,
    "state_id": "NY"
  },
  {
    "zip": 10456,
    "lat": 40.83003,
    "lng": -73.90815,
    "state_id": "NY"
  },
  {
    "zip": 10457,
    "lat": 40.84713,
    "lng": -73.89871,
    "state_id": "NY"
  },
  {
    "zip": 10458,
    "lat": 40.86244,
    "lng": -73.88826,
    "state_id": "NY"
  },
  {
    "zip": 10459,
    "lat": 40.82585,
    "lng": -73.89289,
    "state_id": "NY"
  },
  {
    "zip": 10460,
    "lat": 40.84172,
    "lng": -73.87956,
    "state_id": "NY"
  },
  {
    "zip": 10461,
    "lat": 40.8474,
    "lng": -73.84066,
    "state_id": "NY"
  },
  {
    "zip": 10462,
    "lat": 40.84269,
    "lng": -73.8586,
    "state_id": "NY"
  },
  {
    "zip": 10463,
    "lat": 40.88077,
    "lng": -73.90662,
    "state_id": "NY"
  },
  {
    "zip": 10464,
    "lat": 40.86793,
    "lng": -73.80051,
    "state_id": "NY"
  },
  {
    "zip": 10465,
    "lat": 40.82391,
    "lng": -73.82298,
    "state_id": "NY"
  },
  {
    "zip": 10466,
    "lat": 40.89098,
    "lng": -73.84597,
    "state_id": "NY"
  },
  {
    "zip": 10467,
    "lat": 40.88081,
    "lng": -73.87392,
    "state_id": "NY"
  },
  {
    "zip": 10468,
    "lat": 40.86804,
    "lng": -73.90006,
    "state_id": "NY"
  },
  {
    "zip": 10469,
    "lat": 40.86862,
    "lng": -73.84813,
    "state_id": "NY"
  },
  {
    "zip": 10470,
    "lat": 40.89594,
    "lng": -73.86812,
    "state_id": "NY"
  },
  {
    "zip": 10471,
    "lat": 40.89947,
    "lng": -73.9018,
    "state_id": "NY"
  },
  {
    "zip": 10472,
    "lat": 40.8295,
    "lng": -73.8694,
    "state_id": "NY"
  },
  {
    "zip": 10473,
    "lat": 40.81819,
    "lng": -73.85878,
    "state_id": "NY"
  },
  {
    "zip": 10474,
    "lat": 40.81066,
    "lng": -73.8844,
    "state_id": "NY"
  },
  {
    "zip": 10475,
    "lat": 40.86953,
    "lng": -73.82532,
    "state_id": "NY"
  },
  {
    "zip": 10501,
    "lat": 41.29448,
    "lng": -73.76075,
    "state_id": "NY"
  },
  {
    "zip": 10502,
    "lat": 41.01145,
    "lng": -73.84149,
    "state_id": "NY"
  },
  {
    "zip": 10503,
    "lat": 41.02651,
    "lng": -73.8752,
    "state_id": "NY"
  },
  {
    "zip": 10504,
    "lat": 41.13121,
    "lng": -73.70587,
    "state_id": "NY"
  },
  {
    "zip": 10505,
    "lat": 41.3421,
    "lng": -73.74565,
    "state_id": "NY"
  },
  {
    "zip": 10506,
    "lat": 41.18883,
    "lng": -73.63393,
    "state_id": "NY"
  },
  {
    "zip": 10507,
    "lat": 41.2284,
    "lng": -73.68628,
    "state_id": "NY"
  },
  {
    "zip": 10509,
    "lat": 41.41174,
    "lng": -73.59437,
    "state_id": "NY"
  },
  {
    "zip": 10510,
    "lat": 41.14094,
    "lng": -73.83447,
    "state_id": "NY"
  },
  {
    "zip": 10511,
    "lat": 41.26299,
    "lng": -73.94366,
    "state_id": "NY"
  },
  {
    "zip": 10512,
    "lat": 41.44925,
    "lng": -73.71213,
    "state_id": "NY"
  },
  {
    "zip": 10514,
    "lat": 41.17308,
    "lng": -73.77091,
    "state_id": "NY"
  },
  {
    "zip": 10516,
    "lat": 41.45334,
    "lng": -73.90241,
    "state_id": "NY"
  },
  {
    "zip": 10517,
    "lat": 41.29822,
    "lng": -73.86194,
    "state_id": "NY"
  },
  {
    "zip": 10518,
    "lat": 41.26778,
    "lng": -73.60032,
    "state_id": "NY"
  },
  {
    "zip": 10519,
    "lat": 41.35021,
    "lng": -73.6557,
    "state_id": "NY"
  },
  {
    "zip": 10520,
    "lat": 41.22059,
    "lng": -73.87156,
    "state_id": "NY"
  },
  {
    "zip": 10522,
    "lat": 41.01226,
    "lng": -73.86479,
    "state_id": "NY"
  },
  {
    "zip": 10523,
    "lat": 41.06,
    "lng": -73.81892,
    "state_id": "NY"
  },
  {
    "zip": 10524,
    "lat": 41.37063,
    "lng": -73.92197,
    "state_id": "NY"
  },
  {
    "zip": 10526,
    "lat": 41.29108,
    "lng": -73.66782,
    "state_id": "NY"
  },
  {
    "zip": 10527,
    "lat": 41.31915,
    "lng": -73.76447,
    "state_id": "NY"
  },
  {
    "zip": 10528,
    "lat": 40.97834,
    "lng": -73.72293,
    "state_id": "NY"
  },
  {
    "zip": 10530,
    "lat": 41.02341,
    "lng": -73.8077,
    "state_id": "NY"
  },
  {
    "zip": 10532,
    "lat": 41.09912,
    "lng": -73.80003,
    "state_id": "NY"
  },
  {
    "zip": 10533,
    "lat": 41.03836,
    "lng": -73.85569,
    "state_id": "NY"
  },
  {
    "zip": 10535,
    "lat": 41.33516,
    "lng": -73.79933,
    "state_id": "NY"
  },
  {
    "zip": 10536,
    "lat": 41.26982,
    "lng": -73.68726,
    "state_id": "NY"
  },
  {
    "zip": 10537,
    "lat": 41.33785,
    "lng": -73.88514,
    "state_id": "NY"
  },
  {
    "zip": 10538,
    "lat": 40.938,
    "lng": -73.75667,
    "state_id": "NY"
  },
  {
    "zip": 10541,
    "lat": 41.37973,
    "lng": -73.75123,
    "state_id": "NY"
  },
  {
    "zip": 10543,
    "lat": 40.95199,
    "lng": -73.73552,
    "state_id": "NY"
  },
  {
    "zip": 10545,
    "lat": 41.17861,
    "lng": -73.83549,
    "state_id": "NY"
  },
  {
    "zip": 10546,
    "lat": 41.19552,
    "lng": -73.79992,
    "state_id": "NY"
  },
  {
    "zip": 10547,
    "lat": 41.31169,
    "lng": -73.84622,
    "state_id": "NY"
  },
  {
    "zip": 10548,
    "lat": 41.2472,
    "lng": -73.93496,
    "state_id": "NY"
  },
  {
    "zip": 10549,
    "lat": 41.20069,
    "lng": -73.72098,
    "state_id": "NY"
  },
  {
    "zip": 10550,
    "lat": 40.90625,
    "lng": -73.83505,
    "state_id": "NY"
  },
  {
    "zip": 10552,
    "lat": 40.9238,
    "lng": -73.82509,
    "state_id": "NY"
  },
  {
    "zip": 10553,
    "lat": 40.90854,
    "lng": -73.82173,
    "state_id": "NY"
  },
  {
    "zip": 10560,
    "lat": 41.33202,
    "lng": -73.60221,
    "state_id": "NY"
  },
  {
    "zip": 10562,
    "lat": 41.19242,
    "lng": -73.83096,
    "state_id": "NY"
  },
  {
    "zip": 10566,
    "lat": 41.28919,
    "lng": -73.91845,
    "state_id": "NY"
  },
  {
    "zip": 10567,
    "lat": 41.28896,
    "lng": -73.89799,
    "state_id": "NY"
  },
  {
    "zip": 10570,
    "lat": 41.13006,
    "lng": -73.7872,
    "state_id": "NY"
  },
  {
    "zip": 10573,
    "lat": 41.0168,
    "lng": -73.67728,
    "state_id": "NY"
  },
  {
    "zip": 10576,
    "lat": 41.21336,
    "lng": -73.57373,
    "state_id": "NY"
  },
  {
    "zip": 10577,
    "lat": 41.03786,
    "lng": -73.71367,
    "state_id": "NY"
  },
  {
    "zip": 10578,
    "lat": 41.31739,
    "lng": -73.67446,
    "state_id": "NY"
  },
  {
    "zip": 10579,
    "lat": 41.39377,
    "lng": -73.83614,
    "state_id": "NY"
  },
  {
    "zip": 10580,
    "lat": 40.97889,
    "lng": -73.69306,
    "state_id": "NY"
  },
  {
    "zip": 10583,
    "lat": 40.98924,
    "lng": -73.7931,
    "state_id": "NY"
  },
  {
    "zip": 10588,
    "lat": 41.33533,
    "lng": -73.82183,
    "state_id": "NY"
  },
  {
    "zip": 10589,
    "lat": 41.32994,
    "lng": -73.69523,
    "state_id": "NY"
  },
  {
    "zip": 10590,
    "lat": 41.25393,
    "lng": -73.53733,
    "state_id": "NY"
  },
  {
    "zip": 10591,
    "lat": 41.08586,
    "lng": -73.84417,
    "state_id": "NY"
  },
  {
    "zip": 10594,
    "lat": 41.11637,
    "lng": -73.77267,
    "state_id": "NY"
  },
  {
    "zip": 10595,
    "lat": 41.08646,
    "lng": -73.78201,
    "state_id": "NY"
  },
  {
    "zip": 10596,
    "lat": 41.25608,
    "lng": -73.95911,
    "state_id": "NY"
  },
  {
    "zip": 10597,
    "lat": 41.29406,
    "lng": -73.59682,
    "state_id": "NY"
  },
  {
    "zip": 10598,
    "lat": 41.2819,
    "lng": -73.79328,
    "state_id": "NY"
  },
  {
    "zip": 10601,
    "lat": 41.03295,
    "lng": -73.76504,
    "state_id": "NY"
  },
  {
    "zip": 10603,
    "lat": 41.05456,
    "lng": -73.77939,
    "state_id": "NY"
  },
  {
    "zip": 10604,
    "lat": 41.05928,
    "lng": -73.73944,
    "state_id": "NY"
  },
  {
    "zip": 10605,
    "lat": 41.00919,
    "lng": -73.74605,
    "state_id": "NY"
  },
  {
    "zip": 10606,
    "lat": 41.02045,
    "lng": -73.77572,
    "state_id": "NY"
  },
  {
    "zip": 10607,
    "lat": 41.0395,
    "lng": -73.81117,
    "state_id": "NY"
  },
  {
    "zip": 10701,
    "lat": 40.94544,
    "lng": -73.88034,
    "state_id": "NY"
  },
  {
    "zip": 10703,
    "lat": 40.95974,
    "lng": -73.88031,
    "state_id": "NY"
  },
  {
    "zip": 10704,
    "lat": 40.91886,
    "lng": -73.86176,
    "state_id": "NY"
  },
  {
    "zip": 10705,
    "lat": 40.91925,
    "lng": -73.88976,
    "state_id": "NY"
  },
  {
    "zip": 10706,
    "lat": 40.98976,
    "lng": -73.86752,
    "state_id": "NY"
  },
  {
    "zip": 10707,
    "lat": 40.96054,
    "lng": -73.82274,
    "state_id": "NY"
  },
  {
    "zip": 10708,
    "lat": 40.93816,
    "lng": -73.83075,
    "state_id": "NY"
  },
  {
    "zip": 10709,
    "lat": 40.95459,
    "lng": -73.80935,
    "state_id": "NY"
  },
  {
    "zip": 10710,
    "lat": 40.96732,
    "lng": -73.84713,
    "state_id": "NY"
  },
  {
    "zip": 10801,
    "lat": 40.91757,
    "lng": -73.78437,
    "state_id": "NY"
  },
  {
    "zip": 10803,
    "lat": 40.9003,
    "lng": -73.80662,
    "state_id": "NY"
  },
  {
    "zip": 10804,
    "lat": 40.94912,
    "lng": -73.78636,
    "state_id": "NY"
  },
  {
    "zip": 10805,
    "lat": 40.89645,
    "lng": -73.7804,
    "state_id": "NY"
  },
  {
    "zip": 10901,
    "lat": 41.13876,
    "lng": -74.11615,
    "state_id": "NY"
  },
  {
    "zip": 10910,
    "lat": 41.27707,
    "lng": -74.13522,
    "state_id": "NY"
  },
  {
    "zip": 10911,
    "lat": 41.31682,
    "lng": -74.0031,
    "state_id": "NY"
  },
  {
    "zip": 10913,
    "lat": 41.06885,
    "lng": -73.95552,
    "state_id": "NY"
  },
  {
    "zip": 10914,
    "lat": 41.41597,
    "lng": -74.19953,
    "state_id": "NY"
  },
  {
    "zip": 10915,
    "lat": 41.54231,
    "lng": -74.35823,
    "state_id": "NY"
  },
  {
    "zip": 10916,
    "lat": 41.44214,
    "lng": -74.2487,
    "state_id": "NY"
  },
  {
    "zip": 10917,
    "lat": 41.32443,
    "lng": -74.11697,
    "state_id": "NY"
  },
  {
    "zip": 10918,
    "lat": 41.3477,
    "lng": -74.25871,
    "state_id": "NY"
  },
  {
    "zip": 10919,
    "lat": 41.52855,
    "lng": -74.38317,
    "state_id": "NY"
  },
  {
    "zip": 10920,
    "lat": 41.15291,
    "lng": -73.94057,
    "state_id": "NY"
  },
  {
    "zip": 10921,
    "lat": 41.32976,
    "lng": -74.36388,
    "state_id": "NY"
  },
  {
    "zip": 10922,
    "lat": 41.3336,
    "lng": -73.99473,
    "state_id": "NY"
  },
  {
    "zip": 10923,
    "lat": 41.20235,
    "lng": -73.9998,
    "state_id": "NY"
  },
  {
    "zip": 10924,
    "lat": 41.37946,
    "lng": -74.34667,
    "state_id": "NY"
  },
  {
    "zip": 10925,
    "lat": 41.21009,
    "lng": -74.3033,
    "state_id": "NY"
  },
  {
    "zip": 10926,
    "lat": 41.30162,
    "lng": -74.12443,
    "state_id": "NY"
  },
  {
    "zip": 10927,
    "lat": 41.19257,
    "lng": -73.96708,
    "state_id": "NY"
  },
  {
    "zip": 10928,
    "lat": 41.35344,
    "lng": -73.99766,
    "state_id": "NY"
  },
  {
    "zip": 10930,
    "lat": 41.36485,
    "lng": -74.11535,
    "state_id": "NY"
  },
  {
    "zip": 10931,
    "lat": 41.14745,
    "lng": -74.16455,
    "state_id": "NY"
  },
  {
    "zip": 10932,
    "lat": 41.48207,
    "lng": -74.46457,
    "state_id": "NY"
  },
  {
    "zip": 10933,
    "lat": 41.36525,
    "lng": -74.5142,
    "state_id": "NY"
  },
  {
    "zip": 10940,
    "lat": 41.45119,
    "lng": -74.47016,
    "state_id": "NY"
  },
  {
    "zip": 10941,
    "lat": 41.48857,
    "lng": -74.34496,
    "state_id": "NY"
  },
  {
    "zip": 10950,
    "lat": 41.31837,
    "lng": -74.19886,
    "state_id": "NY"
  },
  {
    "zip": 10952,
    "lat": 41.11363,
    "lng": -74.07943,
    "state_id": "NY"
  },
  {
    "zip": 10953,
    "lat": 41.40504,
    "lng": -74.07745,
    "state_id": "NY"
  },
  {
    "zip": 10954,
    "lat": 41.09997,
    "lng": -74.01276,
    "state_id": "NY"
  },
  {
    "zip": 10956,
    "lat": 41.1569,
    "lng": -73.99436,
    "state_id": "NY"
  },
  {
    "zip": 10958,
    "lat": 41.37337,
    "lng": -74.43044,
    "state_id": "NY"
  },
  {
    "zip": 10960,
    "lat": 41.09198,
    "lng": -73.92584,
    "state_id": "NY"
  },
  {
    "zip": 10962,
    "lat": 41.04906,
    "lng": -73.95913,
    "state_id": "NY"
  },
  {
    "zip": 10963,
    "lat": 41.46332,
    "lng": -74.54425,
    "state_id": "NY"
  },
  {
    "zip": 10964,
    "lat": 41.01624,
    "lng": -73.91438,
    "state_id": "NY"
  },
  {
    "zip": 10965,
    "lat": 41.06179,
    "lng": -74.01279,
    "state_id": "NY"
  },
  {
    "zip": 10968,
    "lat": 41.03936,
    "lng": -73.91777,
    "state_id": "NY"
  },
  {
    "zip": 10969,
    "lat": 41.29255,
    "lng": -74.48882,
    "state_id": "NY"
  },
  {
    "zip": 10970,
    "lat": 41.18793,
    "lng": -74.07769,
    "state_id": "NY"
  },
  {
    "zip": 10973,
    "lat": 41.38494,
    "lng": -74.47609,
    "state_id": "NY"
  },
  {
    "zip": 10974,
    "lat": 41.16798,
    "lng": -74.17825,
    "state_id": "NY"
  },
  {
    "zip": 10975,
    "lat": 41.25801,
    "lng": -74.17403,
    "state_id": "NY"
  },
  {
    "zip": 10976,
    "lat": 41.02857,
    "lng": -73.92629,
    "state_id": "NY"
  },
  {
    "zip": 10977,
    "lat": 41.11768,
    "lng": -74.0481,
    "state_id": "NY"
  },
  {
    "zip": 10979,
    "lat": 41.18272,
    "lng": -74.31501,
    "state_id": "NY"
  },
  {
    "zip": 10980,
    "lat": 41.23609,
    "lng": -74.04651,
    "state_id": "NY"
  },
  {
    "zip": 10983,
    "lat": 41.02736,
    "lng": -73.94833,
    "state_id": "NY"
  },
  {
    "zip": 10984,
    "lat": 41.20855,
    "lng": -74.01752,
    "state_id": "NY"
  },
  {
    "zip": 10985,
    "lat": 41.58358,
    "lng": -74.3732,
    "state_id": "NY"
  },
  {
    "zip": 10986,
    "lat": 41.28157,
    "lng": -73.99502,
    "state_id": "NY"
  },
  {
    "zip": 10987,
    "lat": 41.20392,
    "lng": -74.23211,
    "state_id": "NY"
  },
  {
    "zip": 10988,
    "lat": 41.30156,
    "lng": -74.5428,
    "state_id": "NY"
  },
  {
    "zip": 10989,
    "lat": 41.12464,
    "lng": -73.93617,
    "state_id": "NY"
  },
  {
    "zip": 10990,
    "lat": 41.26442,
    "lng": -74.366,
    "state_id": "NY"
  },
  {
    "zip": 10992,
    "lat": 41.42549,
    "lng": -74.16594,
    "state_id": "NY"
  },
  {
    "zip": 10993,
    "lat": 41.21013,
    "lng": -73.97552,
    "state_id": "NY"
  },
  {
    "zip": 10994,
    "lat": 41.09751,
    "lng": -73.97262,
    "state_id": "NY"
  },
  {
    "zip": 10996,
    "lat": 41.38975,
    "lng": -73.97266,
    "state_id": "NY"
  },
  {
    "zip": 10998,
    "lat": 41.33514,
    "lng": -74.53885,
    "state_id": "NY"
  },
  {
    "zip": 11001,
    "lat": 40.72353,
    "lng": -73.70457,
    "state_id": "NY"
  },
  {
    "zip": 11003,
    "lat": 40.70134,
    "lng": -73.70792,
    "state_id": "NY"
  },
  {
    "zip": 11004,
    "lat": 40.74628,
    "lng": -73.71148,
    "state_id": "NY"
  },
  {
    "zip": 11005,
    "lat": 40.75663,
    "lng": -73.71423,
    "state_id": "NY"
  },
  {
    "zip": 11010,
    "lat": 40.7006,
    "lng": -73.675,
    "state_id": "NY"
  },
  {
    "zip": 11020,
    "lat": 40.77106,
    "lng": -73.71278,
    "state_id": "NY"
  },
  {
    "zip": 11021,
    "lat": 40.78645,
    "lng": -73.72899,
    "state_id": "NY"
  },
  {
    "zip": 11023,
    "lat": 40.7991,
    "lng": -73.73351,
    "state_id": "NY"
  },
  {
    "zip": 11024,
    "lat": 40.81632,
    "lng": -73.74172,
    "state_id": "NY"
  },
  {
    "zip": 11030,
    "lat": 40.79338,
    "lng": -73.68887,
    "state_id": "NY"
  },
  {
    "zip": 11040,
    "lat": 40.7456,
    "lng": -73.67978,
    "state_id": "NY"
  },
  {
    "zip": 11042,
    "lat": 40.75848,
    "lng": -73.6973,
    "state_id": "NY"
  },
  {
    "zip": 11050,
    "lat": 40.83849,
    "lng": -73.69071,
    "state_id": "NY"
  },
  {
    "zip": 11096,
    "lat": 40.62118,
    "lng": -73.75303,
    "state_id": "NY"
  },
  {
    "zip": 11101,
    "lat": 40.74671,
    "lng": -73.93893,
    "state_id": "NY"
  },
  {
    "zip": 11102,
    "lat": 40.77238,
    "lng": -73.92611,
    "state_id": "NY"
  },
  {
    "zip": 11103,
    "lat": 40.76266,
    "lng": -73.91349,
    "state_id": "NY"
  },
  {
    "zip": 11104,
    "lat": 40.7446,
    "lng": -73.92027,
    "state_id": "NY"
  },
  {
    "zip": 11105,
    "lat": 40.77887,
    "lng": -73.90622,
    "state_id": "NY"
  },
  {
    "zip": 11106,
    "lat": 40.76172,
    "lng": -73.93183,
    "state_id": "NY"
  },
  {
    "zip": 11109,
    "lat": 40.74589,
    "lng": -73.95751,
    "state_id": "NY"
  },
  {
    "zip": 11201,
    "lat": 40.69463,
    "lng": -73.98972,
    "state_id": "NY"
  },
  {
    "zip": 11203,
    "lat": 40.64955,
    "lng": -73.93444,
    "state_id": "NY"
  },
  {
    "zip": 11204,
    "lat": 40.61871,
    "lng": -73.98492,
    "state_id": "NY"
  },
  {
    "zip": 11205,
    "lat": 40.69468,
    "lng": -73.96613,
    "state_id": "NY"
  },
  {
    "zip": 11206,
    "lat": 40.70187,
    "lng": -73.94235,
    "state_id": "NY"
  },
  {
    "zip": 11207,
    "lat": 40.67073,
    "lng": -73.89429,
    "state_id": "NY"
  },
  {
    "zip": 11208,
    "lat": 40.66855,
    "lng": -73.87103,
    "state_id": "NY"
  },
  {
    "zip": 11209,
    "lat": 40.62194,
    "lng": -74.03009,
    "state_id": "NY"
  },
  {
    "zip": 11210,
    "lat": 40.62821,
    "lng": -73.94627,
    "state_id": "NY"
  },
  {
    "zip": 11211,
    "lat": 40.71244,
    "lng": -73.95299,
    "state_id": "NY"
  },
  {
    "zip": 11212,
    "lat": 40.66293,
    "lng": -73.91303,
    "state_id": "NY"
  },
  {
    "zip": 11213,
    "lat": 40.67111,
    "lng": -73.9363,
    "state_id": "NY"
  },
  {
    "zip": 11214,
    "lat": 40.59866,
    "lng": -73.99636,
    "state_id": "NY"
  },
  {
    "zip": 11215,
    "lat": 40.6626,
    "lng": -73.98678,
    "state_id": "NY"
  },
  {
    "zip": 11216,
    "lat": 40.68075,
    "lng": -73.94937,
    "state_id": "NY"
  },
  {
    "zip": 11217,
    "lat": 40.68259,
    "lng": -73.97928,
    "state_id": "NY"
  },
  {
    "zip": 11218,
    "lat": 40.64346,
    "lng": -73.97611,
    "state_id": "NY"
  },
  {
    "zip": 11219,
    "lat": 40.63267,
    "lng": -73.99669,
    "state_id": "NY"
  },
  {
    "zip": 11220,
    "lat": 40.64115,
    "lng": -74.01651,
    "state_id": "NY"
  },
  {
    "zip": 11221,
    "lat": 40.69136,
    "lng": -73.92787,
    "state_id": "NY"
  },
  {
    "zip": 11222,
    "lat": 40.72858,
    "lng": -73.94774,
    "state_id": "NY"
  },
  {
    "zip": 11223,
    "lat": 40.59715,
    "lng": -73.97359,
    "state_id": "NY"
  },
  {
    "zip": 11224,
    "lat": 40.57665,
    "lng": -73.98873,
    "state_id": "NY"
  },
  {
    "zip": 11225,
    "lat": 40.66304,
    "lng": -73.95431,
    "state_id": "NY"
  },
  {
    "zip": 11226,
    "lat": 40.64639,
    "lng": -73.9568,
    "state_id": "NY"
  },
  {
    "zip": 11228,
    "lat": 40.61679,
    "lng": -74.013,
    "state_id": "NY"
  },
  {
    "zip": 11229,
    "lat": 40.60083,
    "lng": -73.94401,
    "state_id": "NY"
  },
  {
    "zip": 11230,
    "lat": 40.62214,
    "lng": -73.96506,
    "state_id": "NY"
  },
  {
    "zip": 11231,
    "lat": 40.67767,
    "lng": -74.00465,
    "state_id": "NY"
  },
  {
    "zip": 11232,
    "lat": 40.65632,
    "lng": -74.00563,
    "state_id": "NY"
  },
  {
    "zip": 11233,
    "lat": 40.6783,
    "lng": -73.91995,
    "state_id": "NY"
  },
  {
    "zip": 11234,
    "lat": 40.60602,
    "lng": -73.91071,
    "state_id": "NY"
  },
  {
    "zip": 11235,
    "lat": 40.58402,
    "lng": -73.9488,
    "state_id": "NY"
  },
  {
    "zip": 11236,
    "lat": 40.63958,
    "lng": -73.90107,
    "state_id": "NY"
  },
  {
    "zip": 11237,
    "lat": 40.70429,
    "lng": -73.92107,
    "state_id": "NY"
  },
  {
    "zip": 11238,
    "lat": 40.67913,
    "lng": -73.96384,
    "state_id": "NY"
  },
  {
    "zip": 11239,
    "lat": 40.64844,
    "lng": -73.87969,
    "state_id": "NY"
  },
  {
    "zip": 11351,
    "lat": 40.78076,
    "lng": -73.82543,
    "state_id": "NY"
  },
  {
    "zip": 11354,
    "lat": 40.76863,
    "lng": -73.82739,
    "state_id": "NY"
  },
  {
    "zip": 11355,
    "lat": 40.75148,
    "lng": -73.82092,
    "state_id": "NY"
  },
  {
    "zip": 11356,
    "lat": 40.78497,
    "lng": -73.84165,
    "state_id": "NY"
  },
  {
    "zip": 11357,
    "lat": 40.78636,
    "lng": -73.81097,
    "state_id": "NY"
  },
  {
    "zip": 11358,
    "lat": 40.76044,
    "lng": -73.79632,
    "state_id": "NY"
  },
  {
    "zip": 11359,
    "lat": 40.79142,
    "lng": -73.77665,
    "state_id": "NY"
  },
  {
    "zip": 11360,
    "lat": 40.78039,
    "lng": -73.78148,
    "state_id": "NY"
  },
  {
    "zip": 11361,
    "lat": 40.76383,
    "lng": -73.77237,
    "state_id": "NY"
  },
  {
    "zip": 11362,
    "lat": 40.7565,
    "lng": -73.73686,
    "state_id": "NY"
  },
  {
    "zip": 11363,
    "lat": 40.77268,
    "lng": -73.74671,
    "state_id": "NY"
  },
  {
    "zip": 11364,
    "lat": 40.74532,
    "lng": -73.76068,
    "state_id": "NY"
  },
  {
    "zip": 11365,
    "lat": 40.73976,
    "lng": -73.79486,
    "state_id": "NY"
  },
  {
    "zip": 11366,
    "lat": 40.72817,
    "lng": -73.78491,
    "state_id": "NY"
  },
  {
    "zip": 11367,
    "lat": 40.73041,
    "lng": -73.82623,
    "state_id": "NY"
  },
  {
    "zip": 11368,
    "lat": 40.74959,
    "lng": -73.8526,
    "state_id": "NY"
  },
  {
    "zip": 11369,
    "lat": 40.76339,
    "lng": -73.87238,
    "state_id": "NY"
  },
  {
    "zip": 11370,
    "lat": 40.77568,
    "lng": -73.88738,
    "state_id": "NY"
  },
  {
    "zip": 11371,
    "lat": 40.77445,
    "lng": -73.87332,
    "state_id": "NY"
  },
  {
    "zip": 11372,
    "lat": 40.75166,
    "lng": -73.88361,
    "state_id": "NY"
  },
  {
    "zip": 11373,
    "lat": 40.73886,
    "lng": -73.87858,
    "state_id": "NY"
  },
  {
    "zip": 11374,
    "lat": 40.72652,
    "lng": -73.8615,
    "state_id": "NY"
  },
  {
    "zip": 11375,
    "lat": 40.72092,
    "lng": -73.84611,
    "state_id": "NY"
  },
  {
    "zip": 11377,
    "lat": 40.74482,
    "lng": -73.90522,
    "state_id": "NY"
  },
  {
    "zip": 11378,
    "lat": 40.72467,
    "lng": -73.90954,
    "state_id": "NY"
  },
  {
    "zip": 11379,
    "lat": 40.71676,
    "lng": -73.87953,
    "state_id": "NY"
  },
  {
    "zip": 11385,
    "lat": 40.70102,
    "lng": -73.89013,
    "state_id": "NY"
  },
  {
    "zip": 11411,
    "lat": 40.694,
    "lng": -73.73619,
    "state_id": "NY"
  },
  {
    "zip": 11412,
    "lat": 40.69811,
    "lng": -73.75896,
    "state_id": "NY"
  },
  {
    "zip": 11413,
    "lat": 40.66992,
    "lng": -73.7509,
    "state_id": "NY"
  },
  {
    "zip": 11414,
    "lat": 40.65809,
    "lng": -73.84495,
    "state_id": "NY"
  },
  {
    "zip": 11415,
    "lat": 40.70795,
    "lng": -73.82816,
    "state_id": "NY"
  },
  {
    "zip": 11416,
    "lat": 40.68458,
    "lng": -73.8496,
    "state_id": "NY"
  },
  {
    "zip": 11417,
    "lat": 40.67637,
    "lng": -73.84441,
    "state_id": "NY"
  },
  {
    "zip": 11418,
    "lat": 40.70019,
    "lng": -73.83611,
    "state_id": "NY"
  },
  {
    "zip": 11419,
    "lat": 40.68863,
    "lng": -73.82297,
    "state_id": "NY"
  },
  {
    "zip": 11420,
    "lat": 40.67358,
    "lng": -73.81787,
    "state_id": "NY"
  },
  {
    "zip": 11421,
    "lat": 40.69401,
    "lng": -73.85869,
    "state_id": "NY"
  },
  {
    "zip": 11422,
    "lat": 40.66041,
    "lng": -73.73626,
    "state_id": "NY"
  },
  {
    "zip": 11423,
    "lat": 40.71561,
    "lng": -73.76843,
    "state_id": "NY"
  },
  {
    "zip": 11424,
    "lat": 40.71424,
    "lng": -73.82714,
    "state_id": "NY"
  },
  {
    "zip": 11425,
    "lat": 40.60774,
    "lng": -74.02391,
    "state_id": "NY"
  },
  {
    "zip": 11426,
    "lat": 40.73646,
    "lng": -73.72237,
    "state_id": "NY"
  },
  {
    "zip": 11427,
    "lat": 40.73097,
    "lng": -73.74553,
    "state_id": "NY"
  },
  {
    "zip": 11428,
    "lat": 40.72098,
    "lng": -73.74227,
    "state_id": "NY"
  },
  {
    "zip": 11429,
    "lat": 40.70978,
    "lng": -73.7387,
    "state_id": "NY"
  },
  {
    "zip": 11430,
    "lat": 40.6474,
    "lng": -73.78627,
    "state_id": "NY"
  },
  {
    "zip": 11432,
    "lat": 40.71529,
    "lng": -73.79306,
    "state_id": "NY"
  },
  {
    "zip": 11433,
    "lat": 40.69813,
    "lng": -73.78684,
    "state_id": "NY"
  },
  {
    "zip": 11434,
    "lat": 40.67677,
    "lng": -73.77626,
    "state_id": "NY"
  },
  {
    "zip": 11435,
    "lat": 40.70138,
    "lng": -73.80968,
    "state_id": "NY"
  },
  {
    "zip": 11436,
    "lat": 40.67577,
    "lng": -73.79672,
    "state_id": "NY"
  },
  {
    "zip": 11451,
    "lat": 40.7012,
    "lng": -73.79596,
    "state_id": "NY"
  },
  {
    "zip": 11501,
    "lat": 40.74633,
    "lng": -73.63892,
    "state_id": "NY"
  },
  {
    "zip": 11507,
    "lat": 40.77091,
    "lng": -73.65235,
    "state_id": "NY"
  },
  {
    "zip": 11509,
    "lat": 40.58877,
    "lng": -73.72852,
    "state_id": "NY"
  },
  {
    "zip": 11510,
    "lat": 40.65021,
    "lng": -73.60804,
    "state_id": "NY"
  },
  {
    "zip": 11514,
    "lat": 40.74989,
    "lng": -73.61247,
    "state_id": "NY"
  },
  {
    "zip": 11516,
    "lat": 40.62578,
    "lng": -73.72669,
    "state_id": "NY"
  },
  {
    "zip": 11518,
    "lat": 40.63759,
    "lng": -73.66678,
    "state_id": "NY"
  },
  {
    "zip": 11520,
    "lat": 40.65003,
    "lng": -73.58291,
    "state_id": "NY"
  },
  {
    "zip": 11530,
    "lat": 40.72737,
    "lng": -73.63725,
    "state_id": "NY"
  },
  {
    "zip": 11542,
    "lat": 40.87093,
    "lng": -73.62885,
    "state_id": "NY"
  },
  {
    "zip": 11545,
    "lat": 40.82705,
    "lng": -73.58903,
    "state_id": "NY"
  },
  {
    "zip": 11547,
    "lat": 40.83073,
    "lng": -73.64435,
    "state_id": "NY"
  },
  {
    "zip": 11548,
    "lat": 40.81464,
    "lng": -73.60681,
    "state_id": "NY"
  },
  {
    "zip": 11549,
    "lat": 40.7173,
    "lng": -73.6028,
    "state_id": "NY"
  },
  {
    "zip": 11550,
    "lat": 40.70171,
    "lng": -73.62007,
    "state_id": "NY"
  },
  {
    "zip": 11552,
    "lat": 40.69233,
    "lng": -73.65129,
    "state_id": "NY"
  },
  {
    "zip": 11553,
    "lat": 40.70674,
    "lng": -73.59206,
    "state_id": "NY"
  },
  {
    "zip": 11554,
    "lat": 40.71961,
    "lng": -73.56021,
    "state_id": "NY"
  },
  {
    "zip": 11556,
    "lat": 40.71968,
    "lng": -73.58375,
    "state_id": "NY"
  },
  {
    "zip": 11557,
    "lat": 40.6374,
    "lng": -73.69197,
    "state_id": "NY"
  },
  {
    "zip": 11558,
    "lat": 40.60481,
    "lng": -73.64927,
    "state_id": "NY"
  },
  {
    "zip": 11559,
    "lat": 40.60648,
    "lng": -73.71731,
    "state_id": "NY"
  },
  {
    "zip": 11560,
    "lat": 40.88062,
    "lng": -73.59021,
    "state_id": "NY"
  },
  {
    "zip": 11561,
    "lat": 40.58937,
    "lng": -73.64791,
    "state_id": "NY"
  },
  {
    "zip": 11563,
    "lat": 40.65726,
    "lng": -73.67368,
    "state_id": "NY"
  },
  {
    "zip": 11565,
    "lat": 40.67504,
    "lng": -73.67167,
    "state_id": "NY"
  },
  {
    "zip": 11566,
    "lat": 40.66312,
    "lng": -73.55412,
    "state_id": "NY"
  },
  {
    "zip": 11568,
    "lat": 40.78694,
    "lng": -73.5965,
    "state_id": "NY"
  },
  {
    "zip": 11569,
    "lat": 40.58975,
    "lng": -73.58228,
    "state_id": "NY"
  },
  {
    "zip": 11570,
    "lat": 40.66573,
    "lng": -73.63851,
    "state_id": "NY"
  },
  {
    "zip": 11572,
    "lat": 40.63232,
    "lng": -73.63686,
    "state_id": "NY"
  },
  {
    "zip": 11575,
    "lat": 40.68046,
    "lng": -73.58527,
    "state_id": "NY"
  },
  {
    "zip": 11576,
    "lat": 40.79841,
    "lng": -73.64767,
    "state_id": "NY"
  },
  {
    "zip": 11577,
    "lat": 40.78326,
    "lng": -73.63883,
    "state_id": "NY"
  },
  {
    "zip": 11579,
    "lat": 40.84404,
    "lng": -73.64415,
    "state_id": "NY"
  },
  {
    "zip": 11580,
    "lat": 40.67496,
    "lng": -73.70357,
    "state_id": "NY"
  },
  {
    "zip": 11581,
    "lat": 40.65154,
    "lng": -73.71648,
    "state_id": "NY"
  },
  {
    "zip": 11590,
    "lat": 40.75541,
    "lng": -73.57461,
    "state_id": "NY"
  },
  {
    "zip": 11596,
    "lat": 40.75971,
    "lng": -73.64236,
    "state_id": "NY"
  },
  {
    "zip": 11598,
    "lat": 40.63093,
    "lng": -73.71164,
    "state_id": "NY"
  },
  {
    "zip": 11691,
    "lat": 40.60138,
    "lng": -73.76103,
    "state_id": "NY"
  },
  {
    "zip": 11692,
    "lat": 40.59369,
    "lng": -73.79231,
    "state_id": "NY"
  },
  {
    "zip": 11693,
    "lat": 40.5965,
    "lng": -73.81676,
    "state_id": "NY"
  },
  {
    "zip": 11694,
    "lat": 40.57775,
    "lng": -73.84386,
    "state_id": "NY"
  },
  {
    "zip": 11697,
    "lat": 40.55951,
    "lng": -73.9066,
    "state_id": "NY"
  },
  {
    "zip": 11701,
    "lat": 40.68199,
    "lng": -73.41258,
    "state_id": "NY"
  },
  {
    "zip": 11702,
    "lat": 40.656,
    "lng": -73.31677,
    "state_id": "NY"
  },
  {
    "zip": 11703,
    "lat": 40.73294,
    "lng": -73.32562,
    "state_id": "NY"
  },
  {
    "zip": 11704,
    "lat": 40.71694,
    "lng": -73.35898,
    "state_id": "NY"
  },
  {
    "zip": 11705,
    "lat": 40.74517,
    "lng": -73.05508,
    "state_id": "NY"
  },
  {
    "zip": 11706,
    "lat": 40.7217,
    "lng": -73.2508,
    "state_id": "NY"
  },
  {
    "zip": 11709,
    "lat": 40.90688,
    "lng": -73.5597,
    "state_id": "NY"
  },
  {
    "zip": 11710,
    "lat": 40.67215,
    "lng": -73.53288,
    "state_id": "NY"
  },
  {
    "zip": 11713,
    "lat": 40.77499,
    "lng": -72.94317,
    "state_id": "NY"
  },
  {
    "zip": 11714,
    "lat": 40.74254,
    "lng": -73.48606,
    "state_id": "NY"
  },
  {
    "zip": 11715,
    "lat": 40.75003,
    "lng": -73.03516,
    "state_id": "NY"
  },
  {
    "zip": 11716,
    "lat": 40.77126,
    "lng": -73.12695,
    "state_id": "NY"
  },
  {
    "zip": 11717,
    "lat": 40.78378,
    "lng": -73.25225,
    "state_id": "NY"
  },
  {
    "zip": 11718,
    "lat": 40.71886,
    "lng": -73.26388,
    "state_id": "NY"
  },
  {
    "zip": 11719,
    "lat": 40.78075,
    "lng": -72.91071,
    "state_id": "NY"
  },
  {
    "zip": 11720,
    "lat": 40.87035,
    "lng": -73.08212,
    "state_id": "NY"
  },
  {
    "zip": 11721,
    "lat": 40.89359,
    "lng": -73.36994,
    "state_id": "NY"
  },
  {
    "zip": 11722,
    "lat": 40.78306,
    "lng": -73.19491,
    "state_id": "NY"
  },
  {
    "zip": 11724,
    "lat": 40.86532,
    "lng": -73.45289,
    "state_id": "NY"
  },
  {
    "zip": 11725,
    "lat": 40.84045,
    "lng": -73.2808,
    "state_id": "NY"
  },
  {
    "zip": 11726,
    "lat": 40.67885,
    "lng": -73.39564,
    "state_id": "NY"
  },
  {
    "zip": 11727,
    "lat": 40.88206,
    "lng": -73.00402,
    "state_id": "NY"
  },
  {
    "zip": 11729,
    "lat": 40.76279,
    "lng": -73.32177,
    "state_id": "NY"
  },
  {
    "zip": 11730,
    "lat": 40.71749,
    "lng": -73.17397,
    "state_id": "NY"
  },
  {
    "zip": 11731,
    "lat": 40.86267,
    "lng": -73.31691,
    "state_id": "NY"
  },
  {
    "zip": 11732,
    "lat": 40.84467,
    "lng": -73.53674,
    "state_id": "NY"
  },
  {
    "zip": 11733,
    "lat": 40.93757,
    "lng": -73.10591,
    "state_id": "NY"
  },
  {
    "zip": 11735,
    "lat": 40.73159,
    "lng": -73.4327,
    "state_id": "NY"
  },
  {
    "zip": 11738,
    "lat": 40.83816,
    "lng": -73.03822,
    "state_id": "NY"
  },
  {
    "zip": 11739,
    "lat": 40.72955,
    "lng": -73.16056,
    "state_id": "NY"
  },
  {
    "zip": 11740,
    "lat": 40.86529,
    "lng": -73.36132,
    "state_id": "NY"
  },
  {
    "zip": 11741,
    "lat": 40.79489,
    "lng": -73.07032,
    "state_id": "NY"
  },
  {
    "zip": 11742,
    "lat": 40.81006,
    "lng": -73.04143,
    "state_id": "NY"
  },
  {
    "zip": 11743,
    "lat": 40.88124,
    "lng": -73.42679,
    "state_id": "NY"
  },
  {
    "zip": 11746,
    "lat": 40.81438,
    "lng": -73.36095,
    "state_id": "NY"
  },
  {
    "zip": 11747,
    "lat": 40.78412,
    "lng": -73.40975,
    "state_id": "NY"
  },
  {
    "zip": 11749,
    "lat": 40.80676,
    "lng": -73.17087,
    "state_id": "NY"
  },
  {
    "zip": 11751,
    "lat": 40.72998,
    "lng": -73.21426,
    "state_id": "NY"
  },
  {
    "zip": 11752,
    "lat": 40.75671,
    "lng": -73.17481,
    "state_id": "NY"
  },
  {
    "zip": 11753,
    "lat": 40.78997,
    "lng": -73.54008,
    "state_id": "NY"
  },
  {
    "zip": 11754,
    "lat": 40.88768,
    "lng": -73.24739,
    "state_id": "NY"
  },
  {
    "zip": 11755,
    "lat": 40.85821,
    "lng": -73.11724,
    "state_id": "NY"
  },
  {
    "zip": 11756,
    "lat": 40.72543,
    "lng": -73.51654,
    "state_id": "NY"
  },
  {
    "zip": 11757,
    "lat": 40.68875,
    "lng": -73.37348,
    "state_id": "NY"
  },
  {
    "zip": 11758,
    "lat": 40.66827,
    "lng": -73.45849,
    "state_id": "NY"
  },
  {
    "zip": 11762,
    "lat": 40.68203,
    "lng": -73.44669,
    "state_id": "NY"
  },
  {
    "zip": 11763,
    "lat": 40.82582,
    "lng": -72.9833,
    "state_id": "NY"
  },
  {
    "zip": 11764,
    "lat": 40.93594,
    "lng": -72.97548,
    "state_id": "NY"
  },
  {
    "zip": 11765,
    "lat": 40.88328,
    "lng": -73.55795,
    "state_id": "NY"
  },
  {
    "zip": 11766,
    "lat": 40.93582,
    "lng": -73.0165,
    "state_id": "NY"
  },
  {
    "zip": 11767,
    "lat": 40.84386,
    "lng": -73.14476,
    "state_id": "NY"
  },
  {
    "zip": 11768,
    "lat": 40.91062,
    "lng": -73.32621,
    "state_id": "NY"
  },
  {
    "zip": 11769,
    "lat": 40.73619,
    "lng": -73.1309,
    "state_id": "NY"
  },
  {
    "zip": 11770,
    "lat": 40.64579,
    "lng": -73.15658,
    "state_id": "NY"
  },
  {
    "zip": 11771,
    "lat": 40.86682,
    "lng": -73.52593,
    "state_id": "NY"
  },
  {
    "zip": 11772,
    "lat": 40.76096,
    "lng": -72.9871,
    "state_id": "NY"
  },
  {
    "zip": 11776,
    "lat": 40.91361,
    "lng": -73.04636,
    "state_id": "NY"
  },
  {
    "zip": 11777,
    "lat": 40.95169,
    "lng": -73.06468,
    "state_id": "NY"
  },
  {
    "zip": 11778,
    "lat": 40.9433,
    "lng": -72.93,
    "state_id": "NY"
  },
  {
    "zip": 11779,
    "lat": 40.81277,
    "lng": -73.11536,
    "state_id": "NY"
  },
  {
    "zip": 11780,
    "lat": 40.89219,
    "lng": -73.16952,
    "state_id": "NY"
  },
  {
    "zip": 11782,
    "lat": 40.73748,
    "lng": -73.08116,
    "state_id": "NY"
  },
  {
    "zip": 11783,
    "lat": 40.67767,
    "lng": -73.49062,
    "state_id": "NY"
  },
  {
    "zip": 11784,
    "lat": 40.86891,
    "lng": -73.04118,
    "state_id": "NY"
  },
  {
    "zip": 11786,
    "lat": 40.94462,
    "lng": -72.88515,
    "state_id": "NY"
  },
  {
    "zip": 11787,
    "lat": 40.85339,
    "lng": -73.21033,
    "state_id": "NY"
  },
  {
    "zip": 11788,
    "lat": 40.81808,
    "lng": -73.21328,
    "state_id": "NY"
  },
  {
    "zip": 11789,
    "lat": 40.95756,
    "lng": -72.97214,
    "state_id": "NY"
  },
  {
    "zip": 11790,
    "lat": 40.90689,
    "lng": -73.12767,
    "state_id": "NY"
  },
  {
    "zip": 11791,
    "lat": 40.8284,
    "lng": -73.50435,
    "state_id": "NY"
  },
  {
    "zip": 11792,
    "lat": 40.94818,
    "lng": -72.8305,
    "state_id": "NY"
  },
  {
    "zip": 11793,
    "lat": 40.65069,
    "lng": -73.51421,
    "state_id": "NY"
  },
  {
    "zip": 11794,
    "lat": 40.91362,
    "lng": -73.12514,
    "state_id": "NY"
  },
  {
    "zip": 11795,
    "lat": 40.70788,
    "lng": -73.29826,
    "state_id": "NY"
  },
  {
    "zip": 11796,
    "lat": 40.73135,
    "lng": -73.0997,
    "state_id": "NY"
  },
  {
    "zip": 11797,
    "lat": 40.81727,
    "lng": -73.47004,
    "state_id": "NY"
  },
  {
    "zip": 11798,
    "lat": 40.75225,
    "lng": -73.37596,
    "state_id": "NY"
  },
  {
    "zip": 11801,
    "lat": 40.76239,
    "lng": -73.52394,
    "state_id": "NY"
  },
  {
    "zip": 11803,
    "lat": 40.7818,
    "lng": -73.4735,
    "state_id": "NY"
  },
  {
    "zip": 11804,
    "lat": 40.75874,
    "lng": -73.45697,
    "state_id": "NY"
  },
  {
    "zip": 11901,
    "lat": 40.92254,
    "lng": -72.64657,
    "state_id": "NY"
  },
  {
    "zip": 11930,
    "lat": 40.98935,
    "lng": -72.10144,
    "state_id": "NY"
  },
  {
    "zip": 11931,
    "lat": 40.93495,
    "lng": -72.61361,
    "state_id": "NY"
  },
  {
    "zip": 11932,
    "lat": 40.93905,
    "lng": -72.30785,
    "state_id": "NY"
  },
  {
    "zip": 11933,
    "lat": 40.92378,
    "lng": -72.75494,
    "state_id": "NY"
  },
  {
    "zip": 11934,
    "lat": 40.80008,
    "lng": -72.79363,
    "state_id": "NY"
  },
  {
    "zip": 11935,
    "lat": 41.01796,
    "lng": -72.48929,
    "state_id": "NY"
  },
  {
    "zip": 11937,
    "lat": 41.00703,
    "lng": -72.1953,
    "state_id": "NY"
  },
  {
    "zip": 11939,
    "lat": 41.12788,
    "lng": -72.34146,
    "state_id": "NY"
  },
  {
    "zip": 11940,
    "lat": 40.8104,
    "lng": -72.75711,
    "state_id": "NY"
  },
  {
    "zip": 11941,
    "lat": 40.82973,
    "lng": -72.72839,
    "state_id": "NY"
  },
  {
    "zip": 11942,
    "lat": 40.85315,
    "lng": -72.5849,
    "state_id": "NY"
  },
  {
    "zip": 11944,
    "lat": 41.09988,
    "lng": -72.37089,
    "state_id": "NY"
  },
  {
    "zip": 11946,
    "lat": 40.87787,
    "lng": -72.52651,
    "state_id": "NY"
  },
  {
    "zip": 11947,
    "lat": 40.95518,
    "lng": -72.57717,
    "state_id": "NY"
  },
  {
    "zip": 11948,
    "lat": 40.96594,
    "lng": -72.55795,
    "state_id": "NY"
  },
  {
    "zip": 11949,
    "lat": 40.86546,
    "lng": -72.80134,
    "state_id": "NY"
  },
  {
    "zip": 11950,
    "lat": 40.80776,
    "lng": -72.84737,
    "state_id": "NY"
  },
  {
    "zip": 11951,
    "lat": 40.7634,
    "lng": -72.83056,
    "state_id": "NY"
  },
  {
    "zip": 11952,
    "lat": 40.99698,
    "lng": -72.5437,
    "state_id": "NY"
  },
  {
    "zip": 11953,
    "lat": 40.88593,
    "lng": -72.95064,
    "state_id": "NY"
  },
  {
    "zip": 11954,
    "lat": 41.04652,
    "lng": -71.94662,
    "state_id": "NY"
  },
  {
    "zip": 11955,
    "lat": 40.80886,
    "lng": -72.81986,
    "state_id": "NY"
  },
  {
    "zip": 11956,
    "lat": 40.98159,
    "lng": -72.46894,
    "state_id": "NY"
  },
  {
    "zip": 11957,
    "lat": 41.14927,
    "lng": -72.26173,
    "state_id": "NY"
  },
  {
    "zip": 11958,
    "lat": 41.03618,
    "lng": -72.46153,
    "state_id": "NY"
  },
  {
    "zip": 11959,
    "lat": 40.82255,
    "lng": -72.60118,
    "state_id": "NY"
  },
  {
    "zip": 11960,
    "lat": 40.80854,
    "lng": -72.70635,
    "state_id": "NY"
  },
  {
    "zip": 11961,
    "lat": 40.90392,
    "lng": -72.88749,
    "state_id": "NY"
  },
  {
    "zip": 11962,
    "lat": 40.93565,
    "lng": -72.27269,
    "state_id": "NY"
  },
  {
    "zip": 11963,
    "lat": 40.99428,
    "lng": -72.3208,
    "state_id": "NY"
  },
  {
    "zip": 11964,
    "lat": 41.0544,
    "lng": -72.32076,
    "state_id": "NY"
  },
  {
    "zip": 11965,
    "lat": 41.07777,
    "lng": -72.3527,
    "state_id": "NY"
  },
  {
    "zip": 11967,
    "lat": 40.79514,
    "lng": -72.8749,
    "state_id": "NY"
  },
  {
    "zip": 11968,
    "lat": 40.907,
    "lng": -72.41279,
    "state_id": "NY"
  },
  {
    "zip": 11970,
    "lat": 40.93914,
    "lng": -72.57747,
    "state_id": "NY"
  },
  {
    "zip": 11971,
    "lat": 41.05946,
    "lng": -72.4264,
    "state_id": "NY"
  },
  {
    "zip": 11972,
    "lat": 40.83354,
    "lng": -72.70683,
    "state_id": "NY"
  },
  {
    "zip": 11973,
    "lat": 40.86786,
    "lng": -72.88213,
    "state_id": "NY"
  },
  {
    "zip": 11975,
    "lat": 40.94316,
    "lng": -72.24438,
    "state_id": "NY"
  },
  {
    "zip": 11976,
    "lat": 40.92765,
    "lng": -72.34791,
    "state_id": "NY"
  },
  {
    "zip": 11977,
    "lat": 40.82759,
    "lng": -72.67913,
    "state_id": "NY"
  },
  {
    "zip": 11978,
    "lat": 40.82936,
    "lng": -72.64734,
    "state_id": "NY"
  },
  {
    "zip": 11980,
    "lat": 40.83146,
    "lng": -72.92233,
    "state_id": "NY"
  },
  {
    "zip": 12007,
    "lat": 42.45616,
    "lng": -73.92765,
    "state_id": "NY"
  },
  {
    "zip": 12008,
    "lat": 42.85301,
    "lng": -73.90799,
    "state_id": "NY"
  },
  {
    "zip": 12009,
    "lat": 42.69824,
    "lng": -74.03178,
    "state_id": "NY"
  },
  {
    "zip": 12010,
    "lat": 42.93797,
    "lng": -74.17386,
    "state_id": "NY"
  },
  {
    "zip": 12015,
    "lat": 42.28262,
    "lng": -73.8272,
    "state_id": "NY"
  },
  {
    "zip": 12017,
    "lat": 42.32118,
    "lng": -73.46216,
    "state_id": "NY"
  },
  {
    "zip": 12018,
    "lat": 42.62952,
    "lng": -73.52591,
    "state_id": "NY"
  },
  {
    "zip": 12019,
    "lat": 42.93075,
    "lng": -73.88635,
    "state_id": "NY"
  },
  {
    "zip": 12020,
    "lat": 43.00248,
    "lng": -73.868,
    "state_id": "NY"
  },
  {
    "zip": 12022,
    "lat": 42.67078,
    "lng": -73.34569,
    "state_id": "NY"
  },
  {
    "zip": 12023,
    "lat": 42.60998,
    "lng": -74.16738,
    "state_id": "NY"
  },
  {
    "zip": 12024,
    "lat": 42.47678,
    "lng": -73.53331,
    "state_id": "NY"
  },
  {
    "zip": 12025,
    "lat": 43.0833,
    "lng": -74.136,
    "state_id": "NY"
  },
  {
    "zip": 12027,
    "lat": 42.92243,
    "lng": -73.90716,
    "state_id": "NY"
  },
  {
    "zip": 12028,
    "lat": 42.93994,
    "lng": -73.4432,
    "state_id": "NY"
  },
  {
    "zip": 12029,
    "lat": 42.40668,
    "lng": -73.42658,
    "state_id": "NY"
  },
  {
    "zip": 12031,
    "lat": 42.76477,
    "lng": -74.4569,
    "state_id": "NY"
  },
  {
    "zip": 12032,
    "lat": 43.23363,
    "lng": -74.55081,
    "state_id": "NY"
  },
  {
    "zip": 12033,
    "lat": 42.53754,
    "lng": -73.70714,
    "state_id": "NY"
  },
  {
    "zip": 12035,
    "lat": 42.73177,
    "lng": -74.35151,
    "state_id": "NY"
  },
  {
    "zip": 12036,
    "lat": 42.54077,
    "lng": -74.6774,
    "state_id": "NY"
  },
  {
    "zip": 12037,
    "lat": 42.34517,
    "lng": -73.56853,
    "state_id": "NY"
  },
  {
    "zip": 12040,
    "lat": 42.63738,
    "lng": -73.35077,
    "state_id": "NY"
  },
  {
    "zip": 12041,
    "lat": 42.58328,
    "lng": -73.96423,
    "state_id": "NY"
  },
  {
    "zip": 12042,
    "lat": 42.4021,
    "lng": -73.9202,
    "state_id": "NY"
  },
  {
    "zip": 12043,
    "lat": 42.70454,
    "lng": -74.52273,
    "state_id": "NY"
  },
  {
    "zip": 12045,
    "lat": 42.47693,
    "lng": -73.7952,
    "state_id": "NY"
  },
  {
    "zip": 12046,
    "lat": 42.49182,
    "lng": -73.91917,
    "state_id": "NY"
  },
  {
    "zip": 12047,
    "lat": 42.784,
    "lng": -73.72653,
    "state_id": "NY"
  },
  {
    "zip": 12051,
    "lat": 42.34411,
    "lng": -73.84516,
    "state_id": "NY"
  },
  {
    "zip": 12052,
    "lat": 42.75397,
    "lng": -73.47861,
    "state_id": "NY"
  },
  {
    "zip": 12053,
    "lat": 42.75365,
    "lng": -74.1923,
    "state_id": "NY"
  },
  {
    "zip": 12054,
    "lat": 42.60874,
    "lng": -73.86582,
    "state_id": "NY"
  },
  {
    "zip": 12056,
    "lat": 42.7666,
    "lng": -74.09025,
    "state_id": "NY"
  },
  {
    "zip": 12057,
    "lat": 42.96978,
    "lng": -73.34463,
    "state_id": "NY"
  },
  {
    "zip": 12058,
    "lat": 42.34966,
    "lng": -73.92449,
    "state_id": "NY"
  },
  {
    "zip": 12059,
    "lat": 42.61213,
    "lng": -74.05894,
    "state_id": "NY"
  },
  {
    "zip": 12060,
    "lat": 42.41679,
    "lng": -73.49419,
    "state_id": "NY"
  },
  {
    "zip": 12061,
    "lat": 42.59819,
    "lng": -73.6557,
    "state_id": "NY"
  },
  {
    "zip": 12062,
    "lat": 42.531,
    "lng": -73.50807,
    "state_id": "NY"
  },
  {
    "zip": 12063,
    "lat": 42.56505,
    "lng": -73.63758,
    "state_id": "NY"
  },
  {
    "zip": 12064,
    "lat": 42.61434,
    "lng": -74.65729,
    "state_id": "NY"
  },
  {
    "zip": 12065,
    "lat": 42.85316,
    "lng": -73.78649,
    "state_id": "NY"
  },
  {
    "zip": 12066,
    "lat": 42.78057,
    "lng": -74.31049,
    "state_id": "NY"
  },
  {
    "zip": 12067,
    "lat": 42.55615,
    "lng": -73.92633,
    "state_id": "NY"
  },
  {
    "zip": 12068,
    "lat": 42.95546,
    "lng": -74.40903,
    "state_id": "NY"
  },
  {
    "zip": 12069,
    "lat": 42.94514,
    "lng": -74.27897,
    "state_id": "NY"
  },
  {
    "zip": 12070,
    "lat": 42.98469,
    "lng": -74.25387,
    "state_id": "NY"
  },
  {
    "zip": 12071,
    "lat": 42.55267,
    "lng": -74.42264,
    "state_id": "NY"
  },
  {
    "zip": 12072,
    "lat": 42.88466,
    "lng": -74.36231,
    "state_id": "NY"
  },
  {
    "zip": 12074,
    "lat": 43.05038,
    "lng": -74.0445,
    "state_id": "NY"
  },
  {
    "zip": 12075,
    "lat": 42.29983,
    "lng": -73.63382,
    "state_id": "NY"
  },
  {
    "zip": 12076,
    "lat": 42.41003,
    "lng": -74.38858,
    "state_id": "NY"
  },
  {
    "zip": 12077,
    "lat": 42.59434,
    "lng": -73.78405,
    "state_id": "NY"
  },
  {
    "zip": 12078,
    "lat": 43.1238,
    "lng": -74.36368,
    "state_id": "NY"
  },
  {
    "zip": 12083,
    "lat": 42.4303,
    "lng": -74.02664,
    "state_id": "NY"
  },
  {
    "zip": 12084,
    "lat": 42.70421,
    "lng": -73.90096,
    "state_id": "NY"
  },
  {
    "zip": 12085,
    "lat": 42.70336,
    "lng": -73.96335,
    "state_id": "NY"
  },
  {
    "zip": 12086,
    "lat": 42.99719,
    "lng": -74.10672,
    "state_id": "NY"
  },
  {
    "zip": 12087,
    "lat": 42.43247,
    "lng": -73.89778,
    "state_id": "NY"
  },
  {
    "zip": 12089,
    "lat": 42.86787,
    "lng": -73.31205,
    "state_id": "NY"
  },
  {
    "zip": 12090,
    "lat": 42.88022,
    "lng": -73.35532,
    "state_id": "NY"
  },
  {
    "zip": 12092,
    "lat": 42.70126,
    "lng": -74.37659,
    "state_id": "NY"
  },
  {
    "zip": 12093,
    "lat": 42.4968,
    "lng": -74.6268,
    "state_id": "NY"
  },
  {
    "zip": 12094,
    "lat": 42.89332,
    "lng": -73.48837,
    "state_id": "NY"
  },
  {
    "zip": 12095,
    "lat": 43.02702,
    "lng": -74.40419,
    "state_id": "NY"
  },
  {
    "zip": 12106,
    "lat": 42.38895,
    "lng": -73.71132,
    "state_id": "NY"
  },
  {
    "zip": 12108,
    "lat": 43.57407,
    "lng": -74.44934,
    "state_id": "NY"
  },
  {
    "zip": 12110,
    "lat": 42.75316,
    "lng": -73.7767,
    "state_id": "NY"
  },
  {
    "zip": 12115,
    "lat": 42.47281,
    "lng": -73.58074,
    "state_id": "NY"
  },
  {
    "zip": 12116,
    "lat": 42.53723,
    "lng": -74.91524,
    "state_id": "NY"
  },
  {
    "zip": 12117,
    "lat": 43.16875,
    "lng": -74.2547,
    "state_id": "NY"
  },
  {
    "zip": 12118,
    "lat": 42.91684,
    "lng": -73.72138,
    "state_id": "NY"
  },
  {
    "zip": 12120,
    "lat": 42.45141,
    "lng": -74.14424,
    "state_id": "NY"
  },
  {
    "zip": 12121,
    "lat": 42.84592,
    "lng": -73.60204,
    "state_id": "NY"
  },
  {
    "zip": 12122,
    "lat": 42.54808,
    "lng": -74.31659,
    "state_id": "NY"
  },
  {
    "zip": 12123,
    "lat": 42.52279,
    "lng": -73.60792,
    "state_id": "NY"
  },
  {
    "zip": 12124,
    "lat": 42.44906,
    "lng": -73.79272,
    "state_id": "NY"
  },
  {
    "zip": 12125,
    "lat": 42.47602,
    "lng": -73.40481,
    "state_id": "NY"
  },
  {
    "zip": 12130,
    "lat": 42.44206,
    "lng": -73.6595,
    "state_id": "NY"
  },
  {
    "zip": 12131,
    "lat": 42.45964,
    "lng": -74.46805,
    "state_id": "NY"
  },
  {
    "zip": 12132,
    "lat": 42.47052,
    "lng": -73.63004,
    "state_id": "NY"
  },
  {
    "zip": 12134,
    "lat": 43.2662,
    "lng": -74.22876,
    "state_id": "NY"
  },
  {
    "zip": 12136,
    "lat": 42.43618,
    "lng": -73.55836,
    "state_id": "NY"
  },
  {
    "zip": 12137,
    "lat": 42.85609,
    "lng": -74.12773,
    "state_id": "NY"
  },
  {
    "zip": 12138,
    "lat": 42.74721,
    "lng": -73.37207,
    "state_id": "NY"
  },
  {
    "zip": 12139,
    "lat": 43.53729,
    "lng": -74.56482,
    "state_id": "NY"
  },
  {
    "zip": 12140,
    "lat": 42.69179,
    "lng": -73.56269,
    "state_id": "NY"
  },
  {
    "zip": 12143,
    "lat": 42.48579,
    "lng": -73.85204,
    "state_id": "NY"
  },
  {
    "zip": 12144,
    "lat": 42.62974,
    "lng": -73.71811,
    "state_id": "NY"
  },
  {
    "zip": 12147,
    "lat": 42.51463,
    "lng": -74.15546,
    "state_id": "NY"
  },
  {
    "zip": 12148,
    "lat": 42.83454,
    "lng": -73.84743,
    "state_id": "NY"
  },
  {
    "zip": 12149,
    "lat": 42.61829,
    "lng": -74.56493,
    "state_id": "NY"
  },
  {
    "zip": 12150,
    "lat": 42.87857,
    "lng": -74.05383,
    "state_id": "NY"
  },
  {
    "zip": 12151,
    "lat": 42.92512,
    "lng": -73.78874,
    "state_id": "NY"
  },
  {
    "zip": 12153,
    "lat": 42.64044,
    "lng": -73.46871,
    "state_id": "NY"
  },
  {
    "zip": 12154,
    "lat": 42.9377,
    "lng": -73.60063,
    "state_id": "NY"
  },
  {
    "zip": 12155,
    "lat": 42.59561,
    "lng": -74.82455,
    "state_id": "NY"
  },
  {
    "zip": 12156,
    "lat": 42.47719,
    "lng": -73.74355,
    "state_id": "NY"
  },
  {
    "zip": 12157,
    "lat": 42.66588,
    "lng": -74.29885,
    "state_id": "NY"
  },
  {
    "zip": 12158,
    "lat": 42.54063,
    "lng": -73.82091,
    "state_id": "NY"
  },
  {
    "zip": 12159,
    "lat": 42.6424,
    "lng": -73.88604,
    "state_id": "NY"
  },
  {
    "zip": 12160,
    "lat": 42.75887,
    "lng": -74.3672,
    "state_id": "NY"
  },
  {
    "zip": 12161,
    "lat": 42.53606,
    "lng": -73.85486,
    "state_id": "NY"
  },
  {
    "zip": 12164,
    "lat": 43.55385,
    "lng": -74.35818,
    "state_id": "NY"
  },
  {
    "zip": 12165,
    "lat": 42.31057,
    "lng": -73.50767,
    "state_id": "NY"
  },
  {
    "zip": 12166,
    "lat": 42.8387,
    "lng": -74.45279,
    "state_id": "NY"
  },
  {
    "zip": 12167,
    "lat": 42.42076,
    "lng": -74.58407,
    "state_id": "NY"
  },
  {
    "zip": 12168,
    "lat": 42.56099,
    "lng": -73.38082,
    "state_id": "NY"
  },
  {
    "zip": 12169,
    "lat": 42.58644,
    "lng": -73.44276,
    "state_id": "NY"
  },
  {
    "zip": 12170,
    "lat": 42.99684,
    "lng": -73.66309,
    "state_id": "NY"
  },
  {
    "zip": 12172,
    "lat": 42.28913,
    "lng": -73.73955,
    "state_id": "NY"
  },
  {
    "zip": 12173,
    "lat": 42.38753,
    "lng": -73.76027,
    "state_id": "NY"
  },
  {
    "zip": 12174,
    "lat": 42.35336,
    "lng": -73.72854,
    "state_id": "NY"
  },
  {
    "zip": 12175,
    "lat": 42.5515,
    "lng": -74.55282,
    "state_id": "NY"
  },
  {
    "zip": 12176,
    "lat": 42.38014,
    "lng": -73.96713,
    "state_id": "NY"
  },
  {
    "zip": 12177,
    "lat": 42.951,
    "lng": -74.28714,
    "state_id": "NY"
  },
  {
    "zip": 12180,
    "lat": 42.75062,
    "lng": -73.60223,
    "state_id": "NY"
  },
  {
    "zip": 12182,
    "lat": 42.80045,
    "lng": -73.63286,
    "state_id": "NY"
  },
  {
    "zip": 12183,
    "lat": 42.74743,
    "lng": -73.69256,
    "state_id": "NY"
  },
  {
    "zip": 12184,
    "lat": 42.42049,
    "lng": -73.65421,
    "state_id": "NY"
  },
  {
    "zip": 12185,
    "lat": 42.90329,
    "lng": -73.53123,
    "state_id": "NY"
  },
  {
    "zip": 12186,
    "lat": 42.62751,
    "lng": -73.97066,
    "state_id": "NY"
  },
  {
    "zip": 12187,
    "lat": 42.62196,
    "lng": -74.46226,
    "state_id": "NY"
  },
  {
    "zip": 12188,
    "lat": 42.81988,
    "lng": -73.69582,
    "state_id": "NY"
  },
  {
    "zip": 12189,
    "lat": 42.73626,
    "lng": -73.71765,
    "state_id": "NY"
  },
  {
    "zip": 12190,
    "lat": 43.47353,
    "lng": -74.27331,
    "state_id": "NY"
  },
  {
    "zip": 12192,
    "lat": 42.40291,
    "lng": -73.82828,
    "state_id": "NY"
  },
  {
    "zip": 12193,
    "lat": 42.5157,
    "lng": -74.04497,
    "state_id": "NY"
  },
  {
    "zip": 12194,
    "lat": 42.52443,
    "lng": -74.45965,
    "state_id": "NY"
  },
  {
    "zip": 12195,
    "lat": 42.48664,
    "lng": -73.47099,
    "state_id": "NY"
  },
  {
    "zip": 12196,
    "lat": 42.63157,
    "lng": -73.61698,
    "state_id": "NY"
  },
  {
    "zip": 12197,
    "lat": 42.61136,
    "lng": -74.72774,
    "state_id": "NY"
  },
  {
    "zip": 12198,
    "lat": 42.67673,
    "lng": -73.63404,
    "state_id": "NY"
  },
  {
    "zip": 12202,
    "lat": 42.63378,
    "lng": -73.76322,
    "state_id": "NY"
  },
  {
    "zip": 12203,
    "lat": 42.68108,
    "lng": -73.84822,
    "state_id": "NY"
  },
  {
    "zip": 12204,
    "lat": 42.68802,
    "lng": -73.72852,
    "state_id": "NY"
  },
  {
    "zip": 12205,
    "lat": 42.71896,
    "lng": -73.82934,
    "state_id": "NY"
  },
  {
    "zip": 12206,
    "lat": 42.67445,
    "lng": -73.78253,
    "state_id": "NY"
  },
  {
    "zip": 12207,
    "lat": 42.65728,
    "lng": -73.74608,
    "state_id": "NY"
  },
  {
    "zip": 12208,
    "lat": 42.65331,
    "lng": -73.8102,
    "state_id": "NY"
  },
  {
    "zip": 12209,
    "lat": 42.63807,
    "lng": -73.7899,
    "state_id": "NY"
  },
  {
    "zip": 12210,
    "lat": 42.65996,
    "lng": -73.75818,
    "state_id": "NY"
  },
  {
    "zip": 12211,
    "lat": 42.70582,
    "lng": -73.76407,
    "state_id": "NY"
  },
  {
    "zip": 12222,
    "lat": 42.68539,
    "lng": -73.82541,
    "state_id": "NY"
  },
  {
    "zip": 12302,
    "lat": 42.88182,
    "lng": -73.98204,
    "state_id": "NY"
  },
  {
    "zip": 12303,
    "lat": 42.74956,
    "lng": -73.9246,
    "state_id": "NY"
  },
  {
    "zip": 12304,
    "lat": 42.7741,
    "lng": -73.8973,
    "state_id": "NY"
  },
  {
    "zip": 12305,
    "lat": 42.81215,
    "lng": -73.95043,
    "state_id": "NY"
  },
  {
    "zip": 12306,
    "lat": 42.80481,
    "lng": -74.02857,
    "state_id": "NY"
  },
  {
    "zip": 12307,
    "lat": 42.80503,
    "lng": -73.93364,
    "state_id": "NY"
  },
  {
    "zip": 12308,
    "lat": 42.82157,
    "lng": -73.91999,
    "state_id": "NY"
  },
  {
    "zip": 12309,
    "lat": 42.79915,
    "lng": -73.86951,
    "state_id": "NY"
  },
  {
    "zip": 12401,
    "lat": 41.93558,
    "lng": -74.05278,
    "state_id": "NY"
  },
  {
    "zip": 12404,
    "lat": 41.81464,
    "lng": -74.23593,
    "state_id": "NY"
  },
  {
    "zip": 12405,
    "lat": 42.32139,
    "lng": -74.08988,
    "state_id": "NY"
  },
  {
    "zip": 12406,
    "lat": 42.10202,
    "lng": -74.55241,
    "state_id": "NY"
  },
  {
    "zip": 12407,
    "lat": 42.31761,
    "lng": -74.35824,
    "state_id": "NY"
  },
  {
    "zip": 12409,
    "lat": 42.04364,
    "lng": -74.16684,
    "state_id": "NY"
  },
  {
    "zip": 12410,
    "lat": 42.0699,
    "lng": -74.44976,
    "state_id": "NY"
  },
  {
    "zip": 12411,
    "lat": 41.87608,
    "lng": -74.04552,
    "state_id": "NY"
  },
  {
    "zip": 12412,
    "lat": 42.01273,
    "lng": -74.27957,
    "state_id": "NY"
  },
  {
    "zip": 12413,
    "lat": 42.31255,
    "lng": -74.02123,
    "state_id": "NY"
  },
  {
    "zip": 12414,
    "lat": 42.22399,
    "lng": -73.92037,
    "state_id": "NY"
  },
  {
    "zip": 12416,
    "lat": 42.10067,
    "lng": -74.28871,
    "state_id": "NY"
  },
  {
    "zip": 12417,
    "lat": 41.90682,
    "lng": -73.98998,
    "state_id": "NY"
  },
  {
    "zip": 12418,
    "lat": 42.35877,
    "lng": -74.16173,
    "state_id": "NY"
  },
  {
    "zip": 12419,
    "lat": 41.85962,
    "lng": -74.10349,
    "state_id": "NY"
  },
  {
    "zip": 12420,
    "lat": 41.67113,
    "lng": -74.3793,
    "state_id": "NY"
  },
  {
    "zip": 12421,
    "lat": 42.2486,
    "lng": -74.54307,
    "state_id": "NY"
  },
  {
    "zip": 12422,
    "lat": 42.3926,
    "lng": -74.20334,
    "state_id": "NY"
  },
  {
    "zip": 12423,
    "lat": 42.37921,
    "lng": -74.10974,
    "state_id": "NY"
  },
  {
    "zip": 12424,
    "lat": 42.24686,
    "lng": -74.13529,
    "state_id": "NY"
  },
  {
    "zip": 12427,
    "lat": 42.14078,
    "lng": -74.13729,
    "state_id": "NY"
  },
  {
    "zip": 12428,
    "lat": 41.73903,
    "lng": -74.44489,
    "state_id": "NY"
  },
  {
    "zip": 12429,
    "lat": 41.8291,
    "lng": -73.97495,
    "state_id": "NY"
  },
  {
    "zip": 12430,
    "lat": 42.20346,
    "lng": -74.49959,
    "state_id": "NY"
  },
  {
    "zip": 12431,
    "lat": 42.36032,
    "lng": -74.02069,
    "state_id": "NY"
  },
  {
    "zip": 12432,
    "lat": 42.04431,
    "lng": -73.94446,
    "state_id": "NY"
  },
  {
    "zip": 12433,
    "lat": 42.00337,
    "lng": -74.15849,
    "state_id": "NY"
  },
  {
    "zip": 12434,
    "lat": 42.36051,
    "lng": -74.50076,
    "state_id": "NY"
  },
  {
    "zip": 12435,
    "lat": 41.7288,
    "lng": -74.5187,
    "state_id": "NY"
  },
  {
    "zip": 12436,
    "lat": 42.19555,
    "lng": -74.07411,
    "state_id": "NY"
  },
  {
    "zip": 12438,
    "lat": 42.20791,
    "lng": -74.60127,
    "state_id": "NY"
  },
  {
    "zip": 12439,
    "lat": 42.29608,
    "lng": -74.18386,
    "state_id": "NY"
  },
  {
    "zip": 12440,
    "lat": 41.77871,
    "lng": -74.17726,
    "state_id": "NY"
  },
  {
    "zip": 12441,
    "lat": 42.1353,
    "lng": -74.50865,
    "state_id": "NY"
  },
  {
    "zip": 12442,
    "lat": 42.21526,
    "lng": -74.23674,
    "state_id": "NY"
  },
  {
    "zip": 12443,
    "lat": 41.93397,
    "lng": -74.08537,
    "state_id": "NY"
  },
  {
    "zip": 12444,
    "lat": 42.26319,
    "lng": -74.29672,
    "state_id": "NY"
  },
  {
    "zip": 12446,
    "lat": 41.80597,
    "lng": -74.32554,
    "state_id": "NY"
  },
  {
    "zip": 12448,
    "lat": 42.07925,
    "lng": -74.1829,
    "state_id": "NY"
  },
  {
    "zip": 12449,
    "lat": 41.99355,
    "lng": -73.9931,
    "state_id": "NY"
  },
  {
    "zip": 12450,
    "lat": 42.13328,
    "lng": -74.24421,
    "state_id": "NY"
  },
  {
    "zip": 12451,
    "lat": 42.30526,
    "lng": -73.95016,
    "state_id": "NY"
  },
  {
    "zip": 12452,
    "lat": 42.25765,
    "lng": -74.36122,
    "state_id": "NY"
  },
  {
    "zip": 12453,
    "lat": 42.09341,
    "lng": -73.93634,
    "state_id": "NY"
  },
  {
    "zip": 12454,
    "lat": 42.28669,
    "lng": -74.15098,
    "state_id": "NY"
  },
  {
    "zip": 12455,
    "lat": 42.13971,
    "lng": -74.65986,
    "state_id": "NY"
  },
  {
    "zip": 12456,
    "lat": 42.03317,
    "lng": -73.99706,
    "state_id": "NY"
  },
  {
    "zip": 12457,
    "lat": 42.04406,
    "lng": -74.2525,
    "state_id": "NY"
  },
  {
    "zip": 12458,
    "lat": 41.81571,
    "lng": -74.41994,
    "state_id": "NY"
  },
  {
    "zip": 12459,
    "lat": 42.23647,
    "lng": -74.68027,
    "state_id": "NY"
  },
  {
    "zip": 12460,
    "lat": 42.41081,
    "lng": -74.15606,
    "state_id": "NY"
  },
  {
    "zip": 12461,
    "lat": 41.90889,
    "lng": -74.26928,
    "state_id": "NY"
  },
  {
    "zip": 12463,
    "lat": 42.19997,
    "lng": -74.01727,
    "state_id": "NY"
  },
  {
    "zip": 12464,
    "lat": 42.03513,
    "lng": -74.35126,
    "state_id": "NY"
  },
  {
    "zip": 12465,
    "lat": 42.1531,
    "lng": -74.46732,
    "state_id": "NY"
  },
  {
    "zip": 12466,
    "lat": 41.90425,
    "lng": -73.97908,
    "state_id": "NY"
  },
  {
    "zip": 12468,
    "lat": 42.29895,
    "lng": -74.42302,
    "state_id": "NY"
  },
  {
    "zip": 12469,
    "lat": 42.44715,
    "lng": -74.23917,
    "state_id": "NY"
  },
  {
    "zip": 12470,
    "lat": 42.29589,
    "lng": -74.07723,
    "state_id": "NY"
  },
  {
    "zip": 12471,
    "lat": 41.84147,
    "lng": -74.04122,
    "state_id": "NY"
  },
  {
    "zip": 12472,
    "lat": 41.84608,
    "lng": -74.07873,
    "state_id": "NY"
  },
  {
    "zip": 12473,
    "lat": 42.25818,
    "lng": -74.04101,
    "state_id": "NY"
  },
  {
    "zip": 12474,
    "lat": 42.29729,
    "lng": -74.57932,
    "state_id": "NY"
  },
  {
    "zip": 12475,
    "lat": 42.0152,
    "lng": -74.01543,
    "state_id": "NY"
  },
  {
    "zip": 12477,
    "lat": 42.08592,
    "lng": -73.9998,
    "state_id": "NY"
  },
  {
    "zip": 12480,
    "lat": 42.13631,
    "lng": -74.37742,
    "state_id": "NY"
  },
  {
    "zip": 12481,
    "lat": 41.98299,
    "lng": -74.22481,
    "state_id": "NY"
  },
  {
    "zip": 12482,
    "lat": 42.26813,
    "lng": -73.95546,
    "state_id": "NY"
  },
  {
    "zip": 12483,
    "lat": 41.67552,
    "lng": -74.42136,
    "state_id": "NY"
  },
  {
    "zip": 12484,
    "lat": 41.87104,
    "lng": -74.17495,
    "state_id": "NY"
  },
  {
    "zip": 12485,
    "lat": 42.2032,
    "lng": -74.14374,
    "state_id": "NY"
  },
  {
    "zip": 12486,
    "lat": 41.83381,
    "lng": -74.06256,
    "state_id": "NY"
  },
  {
    "zip": 12487,
    "lat": 41.85934,
    "lng": -73.99538,
    "state_id": "NY"
  },
  {
    "zip": 12489,
    "lat": 41.75069,
    "lng": -74.35549,
    "state_id": "NY"
  },
  {
    "zip": 12490,
    "lat": 42.12466,
    "lng": -73.92605,
    "state_id": "NY"
  },
  {
    "zip": 12491,
    "lat": 41.96747,
    "lng": -74.13696,
    "state_id": "NY"
  },
  {
    "zip": 12492,
    "lat": 42.18491,
    "lng": -74.33575,
    "state_id": "NY"
  },
  {
    "zip": 12493,
    "lat": 41.79614,
    "lng": -73.97687,
    "state_id": "NY"
  },
  {
    "zip": 12494,
    "lat": 41.9566,
    "lng": -74.29289,
    "state_id": "NY"
  },
  {
    "zip": 12495,
    "lat": 42.08489,
    "lng": -74.24067,
    "state_id": "NY"
  },
  {
    "zip": 12496,
    "lat": 42.32647,
    "lng": -74.27376,
    "state_id": "NY"
  },
  {
    "zip": 12498,
    "lat": 42.04851,
    "lng": -74.10639,
    "state_id": "NY"
  },
  {
    "zip": 12501,
    "lat": 41.85964,
    "lng": -73.55857,
    "state_id": "NY"
  },
  {
    "zip": 12502,
    "lat": 42.08515,
    "lng": -73.65507,
    "state_id": "NY"
  },
  {
    "zip": 12503,
    "lat": 42.0385,
    "lng": -73.58041,
    "state_id": "NY"
  },
  {
    "zip": 12504,
    "lat": 42.02963,
    "lng": -73.91605,
    "state_id": "NY"
  },
  {
    "zip": 12507,
    "lat": 42.00374,
    "lng": -73.92069,
    "state_id": "NY"
  },
  {
    "zip": 12508,
    "lat": 41.49675,
    "lng": -73.95384,
    "state_id": "NY"
  },
  {
    "zip": 12512,
    "lat": 41.55181,
    "lng": -73.96613,
    "state_id": "NY"
  },
  {
    "zip": 12513,
    "lat": 42.21761,
    "lng": -73.72778,
    "state_id": "NY"
  },
  {
    "zip": 12514,
    "lat": 41.87279,
    "lng": -73.76292,
    "state_id": "NY"
  },
  {
    "zip": 12515,
    "lat": 41.68301,
    "lng": -74.06266,
    "state_id": "NY"
  },
  {
    "zip": 12516,
    "lat": 42.11175,
    "lng": -73.56537,
    "state_id": "NY"
  },
  {
    "zip": 12517,
    "lat": 42.11828,
    "lng": -73.512,
    "state_id": "NY"
  },
  {
    "zip": 12518,
    "lat": 41.41613,
    "lng": -74.03946,
    "state_id": "NY"
  },
  {
    "zip": 12520,
    "lat": 41.43298,
    "lng": -74.00598,
    "state_id": "NY"
  },
  {
    "zip": 12521,
    "lat": 42.1701,
    "lng": -73.65029,
    "state_id": "NY"
  },
  {
    "zip": 12522,
    "lat": 41.72043,
    "lng": -73.59681,
    "state_id": "NY"
  },
  {
    "zip": 12523,
    "lat": 42.08684,
    "lng": -73.75327,
    "state_id": "NY"
  },
  {
    "zip": 12524,
    "lat": 41.52964,
    "lng": -73.89257,
    "state_id": "NY"
  },
  {
    "zip": 12525,
    "lat": 41.68638,
    "lng": -74.18568,
    "state_id": "NY"
  },
  {
    "zip": 12526,
    "lat": 42.12185,
    "lng": -73.85885,
    "state_id": "NY"
  },
  {
    "zip": 12527,
    "lat": 41.51944,
    "lng": -73.93565,
    "state_id": "NY"
  },
  {
    "zip": 12528,
    "lat": 41.71884,
    "lng": -74.00447,
    "state_id": "NY"
  },
  {
    "zip": 12529,
    "lat": 42.21555,
    "lng": -73.53709,
    "state_id": "NY"
  },
  {
    "zip": 12530,
    "lat": 42.20743,
    "lng": -73.68788,
    "state_id": "NY"
  },
  {
    "zip": 12531,
    "lat": 41.5369,
    "lng": -73.66963,
    "state_id": "NY"
  },
  {
    "zip": 12533,
    "lat": 41.56031,
    "lng": -73.79397,
    "state_id": "NY"
  },
  {
    "zip": 12534,
    "lat": 42.22273,
    "lng": -73.75158,
    "state_id": "NY"
  },
  {
    "zip": 12538,
    "lat": 41.79248,
    "lng": -73.89308,
    "state_id": "NY"
  },
  {
    "zip": 12540,
    "lat": 41.6708,
    "lng": -73.7251,
    "state_id": "NY"
  },
  {
    "zip": 12542,
    "lat": 41.60744,
    "lng": -73.99732,
    "state_id": "NY"
  },
  {
    "zip": 12543,
    "lat": 41.49327,
    "lng": -74.17578,
    "state_id": "NY"
  },
  {
    "zip": 12545,
    "lat": 41.79056,
    "lng": -73.67398,
    "state_id": "NY"
  },
  {
    "zip": 12546,
    "lat": 41.96789,
    "lng": -73.5345,
    "state_id": "NY"
  },
  {
    "zip": 12547,
    "lat": 41.65596,
    "lng": -73.98512,
    "state_id": "NY"
  },
  {
    "zip": 12548,
    "lat": 41.65987,
    "lng": -74.10747,
    "state_id": "NY"
  },
  {
    "zip": 12549,
    "lat": 41.53004,
    "lng": -74.25872,
    "state_id": "NY"
  },
  {
    "zip": 12550,
    "lat": 41.5372,
    "lng": -74.05256,
    "state_id": "NY"
  },
  {
    "zip": 12553,
    "lat": 41.45711,
    "lng": -74.07564,
    "state_id": "NY"
  },
  {
    "zip": 12561,
    "lat": 41.75498,
    "lng": -74.09752,
    "state_id": "NY"
  },
  {
    "zip": 12563,
    "lat": 41.49457,
    "lng": -73.58745,
    "state_id": "NY"
  },
  {
    "zip": 12564,
    "lat": 41.57751,
    "lng": -73.58674,
    "state_id": "NY"
  },
  {
    "zip": 12565,
    "lat": 42.24852,
    "lng": -73.6463,
    "state_id": "NY"
  },
  {
    "zip": 12566,
    "lat": 41.62101,
    "lng": -74.33126,
    "state_id": "NY"
  },
  {
    "zip": 12567,
    "lat": 41.98247,
    "lng": -73.64953,
    "state_id": "NY"
  },
  {
    "zip": 12569,
    "lat": 41.73432,
    "lng": -73.79584,
    "state_id": "NY"
  },
  {
    "zip": 12570,
    "lat": 41.61935,
    "lng": -73.67232,
    "state_id": "NY"
  },
  {
    "zip": 12571,
    "lat": 42.0057,
    "lng": -73.80906,
    "state_id": "NY"
  },
  {
    "zip": 12572,
    "lat": 41.92526,
    "lng": -73.87204,
    "state_id": "NY"
  },
  {
    "zip": 12574,
    "lat": 41.91773,
    "lng": -73.94563,
    "state_id": "NY"
  },
  {
    "zip": 12575,
    "lat": 41.47844,
    "lng": -74.15408,
    "state_id": "NY"
  },
  {
    "zip": 12577,
    "lat": 41.42586,
    "lng": -74.12296,
    "state_id": "NY"
  },
  {
    "zip": 12578,
    "lat": 41.80843,
    "lng": -73.79486,
    "state_id": "NY"
  },
  {
    "zip": 12580,
    "lat": 41.85424,
    "lng": -73.87222,
    "state_id": "NY"
  },
  {
    "zip": 12581,
    "lat": 41.90699,
    "lng": -73.69347,
    "state_id": "NY"
  },
  {
    "zip": 12582,
    "lat": 41.54448,
    "lng": -73.73509,
    "state_id": "NY"
  },
  {
    "zip": 12583,
    "lat": 42.06131,
    "lng": -73.86652,
    "state_id": "NY"
  },
  {
    "zip": 12585,
    "lat": 41.72485,
    "lng": -73.7009,
    "state_id": "NY"
  },
  {
    "zip": 12586,
    "lat": 41.56504,
    "lng": -74.17331,
    "state_id": "NY"
  },
  {
    "zip": 12589,
    "lat": 41.62667,
    "lng": -74.15905,
    "state_id": "NY"
  },
  {
    "zip": 12590,
    "lat": 41.59496,
    "lng": -73.88774,
    "state_id": "NY"
  },
  {
    "zip": 12592,
    "lat": 41.79141,
    "lng": -73.55412,
    "state_id": "NY"
  },
  {
    "zip": 12594,
    "lat": 41.6684,
    "lng": -73.55016,
    "state_id": "NY"
  },
  {
    "zip": 12601,
    "lat": 41.70358,
    "lng": -73.91165,
    "state_id": "NY"
  },
  {
    "zip": 12603,
    "lat": 41.67822,
    "lng": -73.86547,
    "state_id": "NY"
  },
  {
    "zip": 12604,
    "lat": 41.68846,
    "lng": -73.89206,
    "state_id": "NY"
  },
  {
    "zip": 12701,
    "lat": 41.64914,
    "lng": -74.70027,
    "state_id": "NY"
  },
  {
    "zip": 12719,
    "lat": 41.48855,
    "lng": -74.91187,
    "state_id": "NY"
  },
  {
    "zip": 12720,
    "lat": 41.65846,
    "lng": -74.90424,
    "state_id": "NY"
  },
  {
    "zip": 12721,
    "lat": 41.56748,
    "lng": -74.42892,
    "state_id": "NY"
  },
  {
    "zip": 12722,
    "lat": 41.59064,
    "lng": -74.37424,
    "state_id": "NY"
  },
  {
    "zip": 12723,
    "lat": 41.77568,
    "lng": -75.02832,
    "state_id": "NY"
  },
  {
    "zip": 12724,
    "lat": 41.85412,
    "lng": -74.9339,
    "state_id": "NY"
  },
  {
    "zip": 12725,
    "lat": 41.96001,
    "lng": -74.54538,
    "state_id": "NY"
  },
  {
    "zip": 12726,
    "lat": 41.69411,
    "lng": -74.97726,
    "state_id": "NY"
  },
  {
    "zip": 12729,
    "lat": 41.47624,
    "lng": -74.6244,
    "state_id": "NY"
  },
  {
    "zip": 12732,
    "lat": 41.5627,
    "lng": -74.87396,
    "state_id": "NY"
  },
  {
    "zip": 12733,
    "lat": 41.73198,
    "lng": -74.60978,
    "state_id": "NY"
  },
  {
    "zip": 12734,
    "lat": 41.73304,
    "lng": -74.75093,
    "state_id": "NY"
  },
  {
    "zip": 12736,
    "lat": 41.85536,
    "lng": -75.03209,
    "state_id": "NY"
  },
  {
    "zip": 12737,
    "lat": 41.50471,
    "lng": -74.80052,
    "state_id": "NY"
  },
  {
    "zip": 12738,
    "lat": 41.65611,
    "lng": -74.57099,
    "state_id": "NY"
  },
  {
    "zip": 12740,
    "lat": 41.89428,
    "lng": -74.46984,
    "state_id": "NY"
  },
  {
    "zip": 12741,
    "lat": 41.84156,
    "lng": -75.08108,
    "state_id": "NY"
  },
  {
    "zip": 12742,
    "lat": 41.71931,
    "lng": -74.7219,
    "state_id": "NY"
  },
  {
    "zip": 12743,
    "lat": 41.54276,
    "lng": -74.8477,
    "state_id": "NY"
  },
  {
    "zip": 12745,
    "lat": 41.78338,
    "lng": -75.02464,
    "state_id": "NY"
  },
  {
    "zip": 12746,
    "lat": 41.44137,
    "lng": -74.66427,
    "state_id": "NY"
  },
  {
    "zip": 12747,
    "lat": 41.76737,
    "lng": -74.66463,
    "state_id": "NY"
  },
  {
    "zip": 12748,
    "lat": 41.77543,
    "lng": -74.92349,
    "state_id": "NY"
  },
  {
    "zip": 12749,
    "lat": 41.69466,
    "lng": -74.83671,
    "state_id": "NY"
  },
  {
    "zip": 12750,
    "lat": 41.73212,
    "lng": -74.95709,
    "state_id": "NY"
  },
  {
    "zip": 12751,
    "lat": 41.69363,
    "lng": -74.66427,
    "state_id": "NY"
  },
  {
    "zip": 12752,
    "lat": 41.68243,
    "lng": -74.98832,
    "state_id": "NY"
  },
  {
    "zip": 12754,
    "lat": 41.79843,
    "lng": -74.73685,
    "state_id": "NY"
  },
  {
    "zip": 12758,
    "lat": 41.94576,
    "lng": -74.74555,
    "state_id": "NY"
  },
  {
    "zip": 12759,
    "lat": 41.77915,
    "lng": -74.65336,
    "state_id": "NY"
  },
  {
    "zip": 12760,
    "lat": 41.90018,
    "lng": -75.10893,
    "state_id": "NY"
  },
  {
    "zip": 12762,
    "lat": 41.66892,
    "lng": -74.79152,
    "state_id": "NY"
  },
  {
    "zip": 12763,
    "lat": 41.67987,
    "lng": -74.52305,
    "state_id": "NY"
  },
  {
    "zip": 12764,
    "lat": 41.59528,
    "lng": -74.98458,
    "state_id": "NY"
  },
  {
    "zip": 12765,
    "lat": 41.85542,
    "lng": -74.61939,
    "state_id": "NY"
  },
  {
    "zip": 12766,
    "lat": 41.81153,
    "lng": -74.98028,
    "state_id": "NY"
  },
  {
    "zip": 12767,
    "lat": 41.8414,
    "lng": -75.00261,
    "state_id": "NY"
  },
  {
    "zip": 12768,
    "lat": 41.87132,
    "lng": -74.71587,
    "state_id": "NY"
  },
  {
    "zip": 12769,
    "lat": 41.65793,
    "lng": -74.46733,
    "state_id": "NY"
  },
  {
    "zip": 12770,
    "lat": 41.44786,
    "lng": -74.85422,
    "state_id": "NY"
  },
  {
    "zip": 12771,
    "lat": 41.3744,
    "lng": -74.62464,
    "state_id": "NY"
  },
  {
    "zip": 12775,
    "lat": 41.61565,
    "lng": -74.59273,
    "state_id": "NY"
  },
  {
    "zip": 12776,
    "lat": 41.96089,
    "lng": -74.93463,
    "state_id": "NY"
  },
  {
    "zip": 12777,
    "lat": 41.54832,
    "lng": -74.69615,
    "state_id": "NY"
  },
  {
    "zip": 12778,
    "lat": 41.64225,
    "lng": -74.80929,
    "state_id": "NY"
  },
  {
    "zip": 12779,
    "lat": 41.70275,
    "lng": -74.63167,
    "state_id": "NY"
  },
  {
    "zip": 12780,
    "lat": 41.44432,
    "lng": -74.72128,
    "state_id": "NY"
  },
  {
    "zip": 12781,
    "lat": 41.6197,
    "lng": -74.46633,
    "state_id": "NY"
  },
  {
    "zip": 12783,
    "lat": 41.74096,
    "lng": -74.83629,
    "state_id": "NY"
  },
  {
    "zip": 12784,
    "lat": 41.66983,
    "lng": -74.62598,
    "state_id": "NY"
  },
  {
    "zip": 12785,
    "lat": 41.52529,
    "lng": -74.56984,
    "state_id": "NY"
  },
  {
    "zip": 12786,
    "lat": 41.6405,
    "lng": -74.85503,
    "state_id": "NY"
  },
  {
    "zip": 12787,
    "lat": 41.79796,
    "lng": -74.8353,
    "state_id": "NY"
  },
  {
    "zip": 12788,
    "lat": 41.7894,
    "lng": -74.58833,
    "state_id": "NY"
  },
  {
    "zip": 12789,
    "lat": 41.70682,
    "lng": -74.57042,
    "state_id": "NY"
  },
  {
    "zip": 12790,
    "lat": 41.59141,
    "lng": -74.51581,
    "state_id": "NY"
  },
  {
    "zip": 12791,
    "lat": 41.81225,
    "lng": -74.89007,
    "state_id": "NY"
  },
  {
    "zip": 12792,
    "lat": 41.51836,
    "lng": -74.94767,
    "state_id": "NY"
  },
  {
    "zip": 12801,
    "lat": 43.31142,
    "lng": -73.64489,
    "state_id": "NY"
  },
  {
    "zip": 12803,
    "lat": 43.28938,
    "lng": -73.62995,
    "state_id": "NY"
  },
  {
    "zip": 12804,
    "lat": 43.33891,
    "lng": -73.68408,
    "state_id": "NY"
  },
  {
    "zip": 12808,
    "lat": 43.75116,
    "lng": -73.72811,
    "state_id": "NY"
  },
  {
    "zip": 12809,
    "lat": 43.2383,
    "lng": -73.46344,
    "state_id": "NY"
  },
  {
    "zip": 12810,
    "lat": 43.49698,
    "lng": -74.01049,
    "state_id": "NY"
  },
  {
    "zip": 12811,
    "lat": 43.60546,
    "lng": -74.02832,
    "state_id": "NY"
  },
  {
    "zip": 12812,
    "lat": 43.8721,
    "lng": -74.41197,
    "state_id": "NY"
  },
  {
    "zip": 12814,
    "lat": 43.61048,
    "lng": -73.64715,
    "state_id": "NY"
  },
  {
    "zip": 12815,
    "lat": 43.69032,
    "lng": -73.69708,
    "state_id": "NY"
  },
  {
    "zip": 12816,
    "lat": 43.04807,
    "lng": -73.37477,
    "state_id": "NY"
  },
  {
    "zip": 12817,
    "lat": 43.63355,
    "lng": -73.82515,
    "state_id": "NY"
  },
  {
    "zip": 12819,
    "lat": 43.59842,
    "lng": -73.47914,
    "state_id": "NY"
  },
  {
    "zip": 12821,
    "lat": 43.4587,
    "lng": -73.41288,
    "state_id": "NY"
  },
  {
    "zip": 12822,
    "lat": 43.2421,
    "lng": -73.88798,
    "state_id": "NY"
  },
  {
    "zip": 12823,
    "lat": 43.18178,
    "lng": -73.40762,
    "state_id": "NY"
  },
  {
    "zip": 12824,
    "lat": 43.51637,
    "lng": -73.70976,
    "state_id": "NY"
  },
  {
    "zip": 12827,
    "lat": 43.44843,
    "lng": -73.51224,
    "state_id": "NY"
  },
  {
    "zip": 12828,
    "lat": 43.25509,
    "lng": -73.56407,
    "state_id": "NY"
  },
  {
    "zip": 12831,
    "lat": 43.19523,
    "lng": -73.68822,
    "state_id": "NY"
  },
  {
    "zip": 12832,
    "lat": 43.37072,
    "lng": -73.31797,
    "state_id": "NY"
  },
  {
    "zip": 12833,
    "lat": 43.14011,
    "lng": -73.83972,
    "state_id": "NY"
  },
  {
    "zip": 12834,
    "lat": 43.0947,
    "lng": -73.50304,
    "state_id": "NY"
  },
  {
    "zip": 12835,
    "lat": 43.33208,
    "lng": -74.0037,
    "state_id": "NY"
  },
  {
    "zip": 12836,
    "lat": 43.74966,
    "lng": -73.56841,
    "state_id": "NY"
  },
  {
    "zip": 12837,
    "lat": 43.50881,
    "lng": -73.267,
    "state_id": "NY"
  },
  {
    "zip": 12838,
    "lat": 43.34237,
    "lng": -73.40811,
    "state_id": "NY"
  },
  {
    "zip": 12839,
    "lat": 43.3472,
    "lng": -73.55346,
    "state_id": "NY"
  },
  {
    "zip": 12841,
    "lat": 43.63582,
    "lng": -73.50158,
    "state_id": "NY"
  },
  {
    "zip": 12842,
    "lat": 43.75918,
    "lng": -74.29218,
    "state_id": "NY"
  },
  {
    "zip": 12843,
    "lat": 43.57505,
    "lng": -74.00429,
    "state_id": "NY"
  },
  {
    "zip": 12844,
    "lat": 43.48947,
    "lng": -73.62118,
    "state_id": "NY"
  },
  {
    "zip": 12845,
    "lat": 43.42409,
    "lng": -73.7133,
    "state_id": "NY"
  },
  {
    "zip": 12846,
    "lat": 43.34418,
    "lng": -73.80804,
    "state_id": "NY"
  },
  {
    "zip": 12847,
    "lat": 43.99912,
    "lng": -74.58741,
    "state_id": "NY"
  },
  {
    "zip": 12849,
    "lat": 43.44758,
    "lng": -73.29857,
    "state_id": "NY"
  },
  {
    "zip": 12850,
    "lat": 43.10704,
    "lng": -73.98352,
    "state_id": "NY"
  },
  {
    "zip": 12851,
    "lat": 43.85581,
    "lng": -74.04157,
    "state_id": "NY"
  },
  {
    "zip": 12852,
    "lat": 43.94558,
    "lng": -74.16086,
    "state_id": "NY"
  },
  {
    "zip": 12853,
    "lat": 43.68126,
    "lng": -74.0086,
    "state_id": "NY"
  },
  {
    "zip": 12855,
    "lat": 44.00199,
    "lng": -73.79646,
    "state_id": "NY"
  },
  {
    "zip": 12856,
    "lat": 43.65071,
    "lng": -74.14883,
    "state_id": "NY"
  },
  {
    "zip": 12857,
    "lat": 43.82268,
    "lng": -73.91002,
    "state_id": "NY"
  },
  {
    "zip": 12858,
    "lat": 43.90278,
    "lng": -73.6729,
    "state_id": "NY"
  },
  {
    "zip": 12859,
    "lat": 43.16697,
    "lng": -73.90979,
    "state_id": "NY"
  },
  {
    "zip": 12860,
    "lat": 43.72556,
    "lng": -73.80778,
    "state_id": "NY"
  },
  {
    "zip": 12861,
    "lat": 43.74971,
    "lng": -73.41912,
    "state_id": "NY"
  },
  {
    "zip": 12862,
    "lat": 43.67954,
    "lng": -73.91148,
    "state_id": "NY"
  },
  {
    "zip": 12863,
    "lat": 43.06037,
    "lng": -73.93194,
    "state_id": "NY"
  },
  {
    "zip": 12864,
    "lat": 43.72753,
    "lng": -74.30522,
    "state_id": "NY"
  },
  {
    "zip": 12865,
    "lat": 43.2142,
    "lng": -73.34102,
    "state_id": "NY"
  },
  {
    "zip": 12866,
    "lat": 43.07079,
    "lng": -73.74082,
    "state_id": "NY"
  },
  {
    "zip": 12870,
    "lat": 43.84235,
    "lng": -73.75409,
    "state_id": "NY"
  },
  {
    "zip": 12871,
    "lat": 43.08916,
    "lng": -73.61351,
    "state_id": "NY"
  },
  {
    "zip": 12872,
    "lat": 43.87716,
    "lng": -73.72994,
    "state_id": "NY"
  },
  {
    "zip": 12873,
    "lat": 43.11853,
    "lng": -73.3068,
    "state_id": "NY"
  },
  {
    "zip": 12874,
    "lat": 43.6905,
    "lng": -73.54806,
    "state_id": "NY"
  },
  {
    "zip": 12878,
    "lat": 43.42908,
    "lng": -74.0268,
    "state_id": "NY"
  },
  {
    "zip": 12883,
    "lat": 43.85227,
    "lng": -73.50591,
    "state_id": "NY"
  },
  {
    "zip": 12884,
    "lat": 43.08837,
    "lng": -73.59124,
    "state_id": "NY"
  },
  {
    "zip": 12885,
    "lat": 43.52946,
    "lng": -73.8168,
    "state_id": "NY"
  },
  {
    "zip": 12886,
    "lat": 43.66527,
    "lng": -73.92998,
    "state_id": "NY"
  },
  {
    "zip": 12887,
    "lat": 43.53952,
    "lng": -73.37118,
    "state_id": "NY"
  },
  {
    "zip": 12901,
    "lat": 44.70382,
    "lng": -73.47382,
    "state_id": "NY"
  },
  {
    "zip": 12903,
    "lat": 44.68041,
    "lng": -73.44692,
    "state_id": "NY"
  },
  {
    "zip": 12910,
    "lat": 44.85607,
    "lng": -73.64077,
    "state_id": "NY"
  },
  {
    "zip": 12911,
    "lat": 44.51911,
    "lng": -73.46529,
    "state_id": "NY"
  },
  {
    "zip": 12912,
    "lat": 44.46217,
    "lng": -73.74699,
    "state_id": "NY"
  },
  {
    "zip": 12913,
    "lat": 44.42207,
    "lng": -74.02075,
    "state_id": "NY"
  },
  {
    "zip": 12914,
    "lat": 44.92719,
    "lng": -74.59971,
    "state_id": "NY"
  },
  {
    "zip": 12916,
    "lat": 44.83521,
    "lng": -74.51591,
    "state_id": "NY"
  },
  {
    "zip": 12917,
    "lat": 44.92766,
    "lng": -74.17891,
    "state_id": "NY"
  },
  {
    "zip": 12918,
    "lat": 44.69038,
    "lng": -73.6835,
    "state_id": "NY"
  },
  {
    "zip": 12919,
    "lat": 44.9679,
    "lng": -73.44937,
    "state_id": "NY"
  },
  {
    "zip": 12920,
    "lat": 44.87672,
    "lng": -74.0621,
    "state_id": "NY"
  },
  {
    "zip": 12921,
    "lat": 44.89118,
    "lng": -73.43791,
    "state_id": "NY"
  },
  {
    "zip": 12922,
    "lat": 44.28808,
    "lng": -74.71793,
    "state_id": "NY"
  },
  {
    "zip": 12923,
    "lat": 44.95458,
    "lng": -73.93893,
    "state_id": "NY"
  },
  {
    "zip": 12924,
    "lat": 44.47775,
    "lng": -73.58429,
    "state_id": "NY"
  },
  {
    "zip": 12926,
    "lat": 44.95325,
    "lng": -74.32481,
    "state_id": "NY"
  },
  {
    "zip": 12927,
    "lat": 44.19929,
    "lng": -74.82126,
    "state_id": "NY"
  },
  {
    "zip": 12928,
    "lat": 43.95157,
    "lng": -73.52996,
    "state_id": "NY"
  },
  {
    "zip": 12929,
    "lat": 44.72003,
    "lng": -73.71929,
    "state_id": "NY"
  },
  {
    "zip": 12930,
    "lat": 44.72338,
    "lng": -74.54128,
    "state_id": "NY"
  },
  {
    "zip": 12932,
    "lat": 44.21219,
    "lng": -73.61264,
    "state_id": "NY"
  },
  {
    "zip": 12933,
    "lat": 44.89118,
    "lng": -73.84512,
    "state_id": "NY"
  },
  {
    "zip": 12934,
    "lat": 44.86615,
    "lng": -73.88407,
    "state_id": "NY"
  },
  {
    "zip": 12935,
    "lat": 44.83475,
    "lng": -73.78753,
    "state_id": "NY"
  },
  {
    "zip": 12936,
    "lat": 44.27795,
    "lng": -73.39874,
    "state_id": "NY"
  },
  {
    "zip": 12937,
    "lat": 44.96094,
    "lng": -74.48086,
    "state_id": "NY"
  },
  {
    "zip": 12939,
    "lat": 44.42776,
    "lng": -74.16691,
    "state_id": "NY"
  },
  {
    "zip": 12941,
    "lat": 44.35505,
    "lng": -73.71661,
    "state_id": "NY"
  },
  {
    "zip": 12942,
    "lat": 44.26021,
    "lng": -73.79998,
    "state_id": "NY"
  },
  {
    "zip": 12943,
    "lat": 44.13919,
    "lng": -73.8183,
    "state_id": "NY"
  },
  {
    "zip": 12944,
    "lat": 44.45067,
    "lng": -73.51227,
    "state_id": "NY"
  },
  {
    "zip": 12945,
    "lat": 44.33748,
    "lng": -74.23815,
    "state_id": "NY"
  },
  {
    "zip": 12946,
    "lat": 44.22892,
    "lng": -73.98407,
    "state_id": "NY"
  },
  {
    "zip": 12950,
    "lat": 44.32008,
    "lng": -73.58511,
    "state_id": "NY"
  },
  {
    "zip": 12952,
    "lat": 44.70834,
    "lng": -73.90565,
    "state_id": "NY"
  },
  {
    "zip": 12953,
    "lat": 44.74094,
    "lng": -74.26329,
    "state_id": "NY"
  },
  {
    "zip": 12955,
    "lat": 44.79079,
    "lng": -73.95709,
    "state_id": "NY"
  },
  {
    "zip": 12956,
    "lat": 44.09325,
    "lng": -73.49542,
    "state_id": "NY"
  },
  {
    "zip": 12957,
    "lat": 44.84835,
    "lng": -74.57457,
    "state_id": "NY"
  },
  {
    "zip": 12958,
    "lat": 44.95943,
    "lng": -73.57857,
    "state_id": "NY"
  },
  {
    "zip": 12959,
    "lat": 44.96035,
    "lng": -73.70387,
    "state_id": "NY"
  },
  {
    "zip": 12960,
    "lat": 44.02891,
    "lng": -73.55458,
    "state_id": "NY"
  },
  {
    "zip": 12961,
    "lat": 44.05714,
    "lng": -73.54953,
    "state_id": "NY"
  },
  {
    "zip": 12962,
    "lat": 44.70159,
    "lng": -73.60537,
    "state_id": "NY"
  },
  {
    "zip": 12964,
    "lat": 44.14257,
    "lng": -73.62574,
    "state_id": "NY"
  },
  {
    "zip": 12965,
    "lat": 44.70304,
    "lng": -74.68052,
    "state_id": "NY"
  },
  {
    "zip": 12966,
    "lat": 44.79441,
    "lng": -74.4189,
    "state_id": "NY"
  },
  {
    "zip": 12967,
    "lat": 44.77356,
    "lng": -74.65949,
    "state_id": "NY"
  },
  {
    "zip": 12969,
    "lat": 44.70303,
    "lng": -74.08563,
    "state_id": "NY"
  },
  {
    "zip": 12970,
    "lat": 44.48775,
    "lng": -74.31608,
    "state_id": "NY"
  },
  {
    "zip": 12972,
    "lat": 44.55812,
    "lng": -73.57351,
    "state_id": "NY"
  },
  {
    "zip": 12973,
    "lat": 44.2987,
    "lng": -74.59401,
    "state_id": "NY"
  },
  {
    "zip": 12974,
    "lat": 44.0529,
    "lng": -73.47041,
    "state_id": "NY"
  },
  {
    "zip": 12975,
    "lat": 44.5291,
    "lng": -73.43052,
    "state_id": "NY"
  },
  {
    "zip": 12976,
    "lat": 44.50619,
    "lng": -74.21604,
    "state_id": "NY"
  },
  {
    "zip": 12977,
    "lat": 44.28018,
    "lng": -74.07955,
    "state_id": "NY"
  },
  {
    "zip": 12978,
    "lat": 44.61634,
    "lng": -73.80874,
    "state_id": "NY"
  },
  {
    "zip": 12979,
    "lat": 44.99163,
    "lng": -73.37333,
    "state_id": "NY"
  },
  {
    "zip": 12980,
    "lat": 44.56117,
    "lng": -74.49364,
    "state_id": "NY"
  },
  {
    "zip": 12981,
    "lat": 44.62649,
    "lng": -73.84303,
    "state_id": "NY"
  },
  {
    "zip": 12983,
    "lat": 44.32137,
    "lng": -74.20288,
    "state_id": "NY"
  },
  {
    "zip": 12985,
    "lat": 44.56301,
    "lng": -73.74966,
    "state_id": "NY"
  },
  {
    "zip": 12986,
    "lat": 44.23209,
    "lng": -74.49055,
    "state_id": "NY"
  },
  {
    "zip": 12987,
    "lat": 44.32195,
    "lng": -73.76709,
    "state_id": "NY"
  },
  {
    "zip": 12989,
    "lat": 44.53739,
    "lng": -74.063,
    "state_id": "NY"
  },
  {
    "zip": 12992,
    "lat": 44.8158,
    "lng": -73.51816,
    "state_id": "NY"
  },
  {
    "zip": 12993,
    "lat": 44.19893,
    "lng": -73.47219,
    "state_id": "NY"
  },
  {
    "zip": 12996,
    "lat": 44.36021,
    "lng": -73.43946,
    "state_id": "NY"
  },
  {
    "zip": 12997,
    "lat": 44.37545,
    "lng": -73.84318,
    "state_id": "NY"
  },
  {
    "zip": 12998,
    "lat": 44.08463,
    "lng": -73.579,
    "state_id": "NY"
  },
  {
    "zip": 13020,
    "lat": 42.81647,
    "lng": -76.07455,
    "state_id": "NY"
  },
  {
    "zip": 13021,
    "lat": 42.92379,
    "lng": -76.55886,
    "state_id": "NY"
  },
  {
    "zip": 13024,
    "lat": 42.93454,
    "lng": -76.57423,
    "state_id": "NY"
  },
  {
    "zip": 13026,
    "lat": 42.74967,
    "lng": -76.66094,
    "state_id": "NY"
  },
  {
    "zip": 13027,
    "lat": 43.16746,
    "lng": -76.36419,
    "state_id": "NY"
  },
  {
    "zip": 13028,
    "lat": 43.2969,
    "lng": -75.93471,
    "state_id": "NY"
  },
  {
    "zip": 13029,
    "lat": 43.22348,
    "lng": -76.14999,
    "state_id": "NY"
  },
  {
    "zip": 13030,
    "lat": 43.15504,
    "lng": -75.96071,
    "state_id": "NY"
  },
  {
    "zip": 13031,
    "lat": 43.04563,
    "lng": -76.30833,
    "state_id": "NY"
  },
  {
    "zip": 13032,
    "lat": 43.07809,
    "lng": -75.76528,
    "state_id": "NY"
  },
  {
    "zip": 13033,
    "lat": 43.18619,
    "lng": -76.57006,
    "state_id": "NY"
  },
  {
    "zip": 13034,
    "lat": 42.91191,
    "lng": -76.69379,
    "state_id": "NY"
  },
  {
    "zip": 13035,
    "lat": 42.9408,
    "lng": -75.82567,
    "state_id": "NY"
  },
  {
    "zip": 13036,
    "lat": 43.32375,
    "lng": -76.17336,
    "state_id": "NY"
  },
  {
    "zip": 13037,
    "lat": 43.06035,
    "lng": -75.86576,
    "state_id": "NY"
  },
  {
    "zip": 13039,
    "lat": 43.16855,
    "lng": -76.0691,
    "state_id": "NY"
  },
  {
    "zip": 13040,
    "lat": 42.56139,
    "lng": -75.93225,
    "state_id": "NY"
  },
  {
    "zip": 13041,
    "lat": 43.19065,
    "lng": -76.19758,
    "state_id": "NY"
  },
  {
    "zip": 13042,
    "lat": 43.26755,
    "lng": -75.85561,
    "state_id": "NY"
  },
  {
    "zip": 13044,
    "lat": 43.29438,
    "lng": -75.99873,
    "state_id": "NY"
  },
  {
    "zip": 13045,
    "lat": 42.58146,
    "lng": -76.19211,
    "state_id": "NY"
  },
  {
    "zip": 13051,
    "lat": 42.8768,
    "lng": -75.90869,
    "state_id": "NY"
  },
  {
    "zip": 13052,
    "lat": 42.72245,
    "lng": -75.86346,
    "state_id": "NY"
  },
  {
    "zip": 13053,
    "lat": 42.47462,
    "lng": -76.26598,
    "state_id": "NY"
  },
  {
    "zip": 13054,
    "lat": 43.17083,
    "lng": -75.67213,
    "state_id": "NY"
  },
  {
    "zip": 13057,
    "lat": 43.09534,
    "lng": -76.04015,
    "state_id": "NY"
  },
  {
    "zip": 13060,
    "lat": 43.02427,
    "lng": -76.41883,
    "state_id": "NY"
  },
  {
    "zip": 13061,
    "lat": 42.86189,
    "lng": -75.75686,
    "state_id": "NY"
  },
  {
    "zip": 13062,
    "lat": 42.4851,
    "lng": -76.38411,
    "state_id": "NY"
  },
  {
    "zip": 13063,
    "lat": 42.84626,
    "lng": -75.97705,
    "state_id": "NY"
  },
  {
    "zip": 13064,
    "lat": 43.32924,
    "lng": -76.70983,
    "state_id": "NY"
  },
  {
    "zip": 13066,
    "lat": 43.03271,
    "lng": -76.00321,
    "state_id": "NY"
  },
  {
    "zip": 13068,
    "lat": 42.49979,
    "lng": -76.3587,
    "state_id": "NY"
  },
  {
    "zip": 13069,
    "lat": 43.33216,
    "lng": -76.37919,
    "state_id": "NY"
  },
  {
    "zip": 13071,
    "lat": 42.67797,
    "lng": -76.54112,
    "state_id": "NY"
  },
  {
    "zip": 13072,
    "lat": 42.76325,
    "lng": -75.76409,
    "state_id": "NY"
  },
  {
    "zip": 13073,
    "lat": 42.58517,
    "lng": -76.39787,
    "state_id": "NY"
  },
  {
    "zip": 13074,
    "lat": 43.30983,
    "lng": -76.54781,
    "state_id": "NY"
  },
  {
    "zip": 13076,
    "lat": 43.35587,
    "lng": -76.1526,
    "state_id": "NY"
  },
  {
    "zip": 13077,
    "lat": 42.71392,
    "lng": -76.19595,
    "state_id": "NY"
  },
  {
    "zip": 13078,
    "lat": 42.96083,
    "lng": -76.06157,
    "state_id": "NY"
  },
  {
    "zip": 13080,
    "lat": 43.08434,
    "lng": -76.47897,
    "state_id": "NY"
  },
  {
    "zip": 13081,
    "lat": 42.67359,
    "lng": -76.62122,
    "state_id": "NY"
  },
  {
    "zip": 13082,
    "lat": 43.10052,
    "lng": -75.95324,
    "state_id": "NY"
  },
  {
    "zip": 13083,
    "lat": 43.6484,
    "lng": -75.98126,
    "state_id": "NY"
  },
  {
    "zip": 13084,
    "lat": 42.88899,
    "lng": -76.11305,
    "state_id": "NY"
  },
  {
    "zip": 13087,
    "lat": 42.7064,
    "lng": -76.15587,
    "state_id": "NY"
  },
  {
    "zip": 13088,
    "lat": 43.10998,
    "lng": -76.18713,
    "state_id": "NY"
  },
  {
    "zip": 13090,
    "lat": 43.15168,
    "lng": -76.21356,
    "state_id": "NY"
  },
  {
    "zip": 13092,
    "lat": 42.65168,
    "lng": -76.4234,
    "state_id": "NY"
  },
  {
    "zip": 13101,
    "lat": 42.59851,
    "lng": -76.06728,
    "state_id": "NY"
  },
  {
    "zip": 13102,
    "lat": 42.55271,
    "lng": -76.29248,
    "state_id": "NY"
  },
  {
    "zip": 13103,
    "lat": 43.32708,
    "lng": -76.10831,
    "state_id": "NY"
  },
  {
    "zip": 13104,
    "lat": 42.96313,
    "lng": -75.9557,
    "state_id": "NY"
  },
  {
    "zip": 13108,
    "lat": 42.97329,
    "lng": -76.33094,
    "state_id": "NY"
  },
  {
    "zip": 13110,
    "lat": 42.89315,
    "lng": -76.28534,
    "state_id": "NY"
  },
  {
    "zip": 13111,
    "lat": 43.25868,
    "lng": -76.62045,
    "state_id": "NY"
  },
  {
    "zip": 13112,
    "lat": 43.10146,
    "lng": -76.41846,
    "state_id": "NY"
  },
  {
    "zip": 13113,
    "lat": 43.16266,
    "lng": -76.53856,
    "state_id": "NY"
  },
  {
    "zip": 13114,
    "lat": 43.46386,
    "lng": -76.24422,
    "state_id": "NY"
  },
  {
    "zip": 13115,
    "lat": 43.39866,
    "lng": -76.47876,
    "state_id": "NY"
  },
  {
    "zip": 13116,
    "lat": 43.07482,
    "lng": -76.00787,
    "state_id": "NY"
  },
  {
    "zip": 13117,
    "lat": 43.00931,
    "lng": -76.70749,
    "state_id": "NY"
  },
  {
    "zip": 13118,
    "lat": 42.75086,
    "lng": -76.39059,
    "state_id": "NY"
  },
  {
    "zip": 13120,
    "lat": 42.93959,
    "lng": -76.1742,
    "state_id": "NY"
  },
  {
    "zip": 13122,
    "lat": 42.84187,
    "lng": -75.85947,
    "state_id": "NY"
  },
  {
    "zip": 13123,
    "lat": 43.23355,
    "lng": -75.76908,
    "state_id": "NY"
  },
  {
    "zip": 13124,
    "lat": 42.64605,
    "lng": -75.81796,
    "state_id": "NY"
  },
  {
    "zip": 13126,
    "lat": 43.43486,
    "lng": -76.46232,
    "state_id": "NY"
  },
  {
    "zip": 13131,
    "lat": 43.42122,
    "lng": -76.09224,
    "state_id": "NY"
  },
  {
    "zip": 13132,
    "lat": 43.26679,
    "lng": -76.2447,
    "state_id": "NY"
  },
  {
    "zip": 13134,
    "lat": 42.96738,
    "lng": -75.6847,
    "state_id": "NY"
  },
  {
    "zip": 13135,
    "lat": 43.25488,
    "lng": -76.31913,
    "state_id": "NY"
  },
  {
    "zip": 13136,
    "lat": 42.61469,
    "lng": -75.84903,
    "state_id": "NY"
  },
  {
    "zip": 13138,
    "lat": 42.89915,
    "lng": -76.01454,
    "state_id": "NY"
  },
  {
    "zip": 13140,
    "lat": 43.05983,
    "lng": -76.65899,
    "state_id": "NY"
  },
  {
    "zip": 13141,
    "lat": 42.7632,
    "lng": -76.18349,
    "state_id": "NY"
  },
  {
    "zip": 13142,
    "lat": 43.55147,
    "lng": -76.1307,
    "state_id": "NY"
  },
  {
    "zip": 13143,
    "lat": 43.23326,
    "lng": -76.71529,
    "state_id": "NY"
  },
  {
    "zip": 13144,
    "lat": 43.56996,
    "lng": -75.97433,
    "state_id": "NY"
  },
  {
    "zip": 13145,
    "lat": 43.65044,
    "lng": -76.1212,
    "state_id": "NY"
  },
  {
    "zip": 13146,
    "lat": 43.0901,
    "lng": -76.75786,
    "state_id": "NY"
  },
  {
    "zip": 13147,
    "lat": 42.77501,
    "lng": -76.5644,
    "state_id": "NY"
  },
  {
    "zip": 13148,
    "lat": 42.91464,
    "lng": -76.7847,
    "state_id": "NY"
  },
  {
    "zip": 13152,
    "lat": 42.8926,
    "lng": -76.38469,
    "state_id": "NY"
  },
  {
    "zip": 13153,
    "lat": 42.99654,
    "lng": -76.45361,
    "state_id": "NY"
  },
  {
    "zip": 13155,
    "lat": 42.65491,
    "lng": -75.78004,
    "state_id": "NY"
  },
  {
    "zip": 13156,
    "lat": 43.33302,
    "lng": -76.66058,
    "state_id": "NY"
  },
  {
    "zip": 13157,
    "lat": 43.20488,
    "lng": -75.72357,
    "state_id": "NY"
  },
  {
    "zip": 13158,
    "lat": 42.71911,
    "lng": -75.96671,
    "state_id": "NY"
  },
  {
    "zip": 13159,
    "lat": 42.80198,
    "lng": -76.11349,
    "state_id": "NY"
  },
  {
    "zip": 13160,
    "lat": 42.82792,
    "lng": -76.65173,
    "state_id": "NY"
  },
  {
    "zip": 13162,
    "lat": 43.18407,
    "lng": -75.72001,
    "state_id": "NY"
  },
  {
    "zip": 13163,
    "lat": 43.0807,
    "lng": -75.71107,
    "state_id": "NY"
  },
  {
    "zip": 13164,
    "lat": 43.09407,
    "lng": -76.3161,
    "state_id": "NY"
  },
  {
    "zip": 13165,
    "lat": 42.91188,
    "lng": -76.87612,
    "state_id": "NY"
  },
  {
    "zip": 13166,
    "lat": 43.07093,
    "lng": -76.55733,
    "state_id": "NY"
  },
  {
    "zip": 13167,
    "lat": 43.31407,
    "lng": -76.06023,
    "state_id": "NY"
  },
  {
    "zip": 13202,
    "lat": 43.04381,
    "lng": -76.15068,
    "state_id": "NY"
  },
  {
    "zip": 13203,
    "lat": 43.06114,
    "lng": -76.13495,
    "state_id": "NY"
  },
  {
    "zip": 13204,
    "lat": 43.05077,
    "lng": -76.17707,
    "state_id": "NY"
  },
  {
    "zip": 13205,
    "lat": 43.00524,
    "lng": -76.14204,
    "state_id": "NY"
  },
  {
    "zip": 13206,
    "lat": 43.07349,
    "lng": -76.1058,
    "state_id": "NY"
  },
  {
    "zip": 13207,
    "lat": 43.01383,
    "lng": -76.16415,
    "state_id": "NY"
  },
  {
    "zip": 13208,
    "lat": 43.07874,
    "lng": -76.14526,
    "state_id": "NY"
  },
  {
    "zip": 13209,
    "lat": 43.08508,
    "lng": -76.2486,
    "state_id": "NY"
  },
  {
    "zip": 13210,
    "lat": 43.03014,
    "lng": -76.12607,
    "state_id": "NY"
  },
  {
    "zip": 13211,
    "lat": 43.10359,
    "lng": -76.11948,
    "state_id": "NY"
  },
  {
    "zip": 13212,
    "lat": 43.12819,
    "lng": -76.13128,
    "state_id": "NY"
  },
  {
    "zip": 13214,
    "lat": 43.0382,
    "lng": -76.0743,
    "state_id": "NY"
  },
  {
    "zip": 13215,
    "lat": 42.97997,
    "lng": -76.23038,
    "state_id": "NY"
  },
  {
    "zip": 13219,
    "lat": 43.03979,
    "lng": -76.22278,
    "state_id": "NY"
  },
  {
    "zip": 13224,
    "lat": 43.03715,
    "lng": -76.1029,
    "state_id": "NY"
  },
  {
    "zip": 13290,
    "lat": 43.07079,
    "lng": -76.17364,
    "state_id": "NY"
  },
  {
    "zip": 13301,
    "lat": 43.41729,
    "lng": -75.22038,
    "state_id": "NY"
  },
  {
    "zip": 13302,
    "lat": 43.49522,
    "lng": -75.96966,
    "state_id": "NY"
  },
  {
    "zip": 13303,
    "lat": 43.36598,
    "lng": -75.46494,
    "state_id": "NY"
  },
  {
    "zip": 13304,
    "lat": 43.24242,
    "lng": -75.16453,
    "state_id": "NY"
  },
  {
    "zip": 13305,
    "lat": 43.89223,
    "lng": -75.42124,
    "state_id": "NY"
  },
  {
    "zip": 13308,
    "lat": 43.24881,
    "lng": -75.66616,
    "state_id": "NY"
  },
  {
    "zip": 13309,
    "lat": 43.47366,
    "lng": -75.3481,
    "state_id": "NY"
  },
  {
    "zip": 13310,
    "lat": 42.89222,
    "lng": -75.57275,
    "state_id": "NY"
  },
  {
    "zip": 13312,
    "lat": 43.7074,
    "lng": -75.20768,
    "state_id": "NY"
  },
  {
    "zip": 13313,
    "lat": 42.88019,
    "lng": -75.27283,
    "state_id": "NY"
  },
  {
    "zip": 13314,
    "lat": 42.81673,
    "lng": -75.31946,
    "state_id": "NY"
  },
  {
    "zip": 13315,
    "lat": 42.74414,
    "lng": -75.14515,
    "state_id": "NY"
  },
  {
    "zip": 13316,
    "lat": 43.4095,
    "lng": -75.74262,
    "state_id": "NY"
  },
  {
    "zip": 13317,
    "lat": 42.85503,
    "lng": -74.58309,
    "state_id": "NY"
  },
  {
    "zip": 13318,
    "lat": 42.91908,
    "lng": -75.25773,
    "state_id": "NY"
  },
  {
    "zip": 13319,
    "lat": 43.0264,
    "lng": -75.26694,
    "state_id": "NY"
  },
  {
    "zip": 13320,
    "lat": 42.77725,
    "lng": -74.73844,
    "state_id": "NY"
  },
  {
    "zip": 13321,
    "lat": 43.0879,
    "lng": -75.37398,
    "state_id": "NY"
  },
  {
    "zip": 13322,
    "lat": 42.96517,
    "lng": -75.20226,
    "state_id": "NY"
  },
  {
    "zip": 13323,
    "lat": 43.04174,
    "lng": -75.37911,
    "state_id": "NY"
  },
  {
    "zip": 13324,
    "lat": 43.32621,
    "lng": -74.95408,
    "state_id": "NY"
  },
  {
    "zip": 13325,
    "lat": 43.57631,
    "lng": -75.52688,
    "state_id": "NY"
  },
  {
    "zip": 13326,
    "lat": 42.7146,
    "lng": -74.90441,
    "state_id": "NY"
  },
  {
    "zip": 13327,
    "lat": 43.95983,
    "lng": -75.27646,
    "state_id": "NY"
  },
  {
    "zip": 13328,
    "lat": 42.98507,
    "lng": -75.429,
    "state_id": "NY"
  },
  {
    "zip": 13329,
    "lat": 43.11123,
    "lng": -74.69824,
    "state_id": "NY"
  },
  {
    "zip": 13331,
    "lat": 43.84298,
    "lng": -74.90042,
    "state_id": "NY"
  },
  {
    "zip": 13332,
    "lat": 42.75678,
    "lng": -75.57549,
    "state_id": "NY"
  },
  {
    "zip": 13333,
    "lat": 42.83368,
    "lng": -74.82328,
    "state_id": "NY"
  },
  {
    "zip": 13334,
    "lat": 42.82873,
    "lng": -75.65804,
    "state_id": "NY"
  },
  {
    "zip": 13335,
    "lat": 42.70267,
    "lng": -75.25046,
    "state_id": "NY"
  },
  {
    "zip": 13337,
    "lat": 42.75391,
    "lng": -74.9812,
    "state_id": "NY"
  },
  {
    "zip": 13338,
    "lat": 43.50141,
    "lng": -74.97935,
    "state_id": "NY"
  },
  {
    "zip": 13339,
    "lat": 42.93996,
    "lng": -74.66664,
    "state_id": "NY"
  },
  {
    "zip": 13340,
    "lat": 43.05083,
    "lng": -75.11372,
    "state_id": "NY"
  },
  {
    "zip": 13341,
    "lat": 43.0361,
    "lng": -75.39698,
    "state_id": "NY"
  },
  {
    "zip": 13342,
    "lat": 42.6546,
    "lng": -75.19102,
    "state_id": "NY"
  },
  {
    "zip": 13343,
    "lat": 43.75056,
    "lng": -75.31074,
    "state_id": "NY"
  },
  {
    "zip": 13345,
    "lat": 43.68855,
    "lng": -75.33017,
    "state_id": "NY"
  },
  {
    "zip": 13346,
    "lat": 42.81801,
    "lng": -75.54386,
    "state_id": "NY"
  },
  {
    "zip": 13348,
    "lat": 42.70859,
    "lng": -75.06768,
    "state_id": "NY"
  },
  {
    "zip": 13350,
    "lat": 43.06185,
    "lng": -74.98873,
    "state_id": "NY"
  },
  {
    "zip": 13352,
    "lat": 43.31274,
    "lng": -75.11641,
    "state_id": "NY"
  },
  {
    "zip": 13353,
    "lat": 43.46576,
    "lng": -74.72544,
    "state_id": "NY"
  },
  {
    "zip": 13354,
    "lat": 43.2631,
    "lng": -75.26965,
    "state_id": "NY"
  },
  {
    "zip": 13355,
    "lat": 42.81267,
    "lng": -75.42864,
    "state_id": "NY"
  },
  {
    "zip": 13357,
    "lat": 42.96705,
    "lng": -75.08337,
    "state_id": "NY"
  },
  {
    "zip": 13360,
    "lat": 43.72756,
    "lng": -74.73795,
    "state_id": "NY"
  },
  {
    "zip": 13361,
    "lat": 42.90373,
    "lng": -74.86827,
    "state_id": "NY"
  },
  {
    "zip": 13362,
    "lat": 42.98218,
    "lng": -75.52199,
    "state_id": "NY"
  },
  {
    "zip": 13363,
    "lat": 43.3273,
    "lng": -75.51383,
    "state_id": "NY"
  },
  {
    "zip": 13364,
    "lat": 42.80502,
    "lng": -75.26104,
    "state_id": "NY"
  },
  {
    "zip": 13365,
    "lat": 43.1214,
    "lng": -74.8449,
    "state_id": "NY"
  },
  {
    "zip": 13367,
    "lat": 43.85827,
    "lng": -75.30587,
    "state_id": "NY"
  },
  {
    "zip": 13368,
    "lat": 43.63877,
    "lng": -75.27511,
    "state_id": "NY"
  },
  {
    "zip": 13402,
    "lat": 42.90116,
    "lng": -75.50495,
    "state_id": "NY"
  },
  {
    "zip": 13403,
    "lat": 43.1692,
    "lng": -75.2708,
    "state_id": "NY"
  },
  {
    "zip": 13404,
    "lat": 43.73635,
    "lng": -75.47172,
    "state_id": "NY"
  },
  {
    "zip": 13406,
    "lat": 43.13529,
    "lng": -74.91789,
    "state_id": "NY"
  },
  {
    "zip": 13407,
    "lat": 42.96895,
    "lng": -74.94448,
    "state_id": "NY"
  },
  {
    "zip": 13408,
    "lat": 42.91981,
    "lng": -75.66916,
    "state_id": "NY"
  },
  {
    "zip": 13409,
    "lat": 42.97648,
    "lng": -75.5951,
    "state_id": "NY"
  },
  {
    "zip": 13410,
    "lat": 42.93103,
    "lng": -74.61255,
    "state_id": "NY"
  },
  {
    "zip": 13411,
    "lat": 42.63306,
    "lng": -75.31162,
    "state_id": "NY"
  },
  {
    "zip": 13413,
    "lat": 43.05898,
    "lng": -75.27709,
    "state_id": "NY"
  },
  {
    "zip": 13415,
    "lat": 42.59653,
    "lng": -75.19847,
    "state_id": "NY"
  },
  {
    "zip": 13416,
    "lat": 43.18705,
    "lng": -74.95615,
    "state_id": "NY"
  },
  {
    "zip": 13417,
    "lat": 43.10096,
    "lng": -75.2938,
    "state_id": "NY"
  },
  {
    "zip": 13418,
    "lat": 42.84506,
    "lng": -75.38218,
    "state_id": "NY"
  },
  {
    "zip": 13420,
    "lat": 43.70795,
    "lng": -74.99236,
    "state_id": "NY"
  },
  {
    "zip": 13421,
    "lat": 43.06521,
    "lng": -75.64464,
    "state_id": "NY"
  },
  {
    "zip": 13424,
    "lat": 43.15238,
    "lng": -75.36526,
    "state_id": "NY"
  },
  {
    "zip": 13425,
    "lat": 42.9635,
    "lng": -75.48701,
    "state_id": "NY"
  },
  {
    "zip": 13428,
    "lat": 42.92177,
    "lng": -74.54059,
    "state_id": "NY"
  },
  {
    "zip": 13431,
    "lat": 43.21291,
    "lng": -75.07749,
    "state_id": "NY"
  },
  {
    "zip": 13433,
    "lat": 43.58639,
    "lng": -75.26501,
    "state_id": "NY"
  },
  {
    "zip": 13435,
    "lat": 43.30516,
    "lng": -75.15067,
    "state_id": "NY"
  },
  {
    "zip": 13436,
    "lat": 43.81103,
    "lng": -74.66038,
    "state_id": "NY"
  },
  {
    "zip": 13437,
    "lat": 43.59719,
    "lng": -75.80364,
    "state_id": "NY"
  },
  {
    "zip": 13438,
    "lat": 43.34881,
    "lng": -75.16444,
    "state_id": "NY"
  },
  {
    "zip": 13439,
    "lat": 42.85611,
    "lng": -74.99561,
    "state_id": "NY"
  },
  {
    "zip": 13440,
    "lat": 43.21598,
    "lng": -75.46131,
    "state_id": "NY"
  },
  {
    "zip": 13441,
    "lat": 43.22639,
    "lng": -75.40825,
    "state_id": "NY"
  },
  {
    "zip": 13450,
    "lat": 42.70193,
    "lng": -74.8103,
    "state_id": "NY"
  },
  {
    "zip": 13452,
    "lat": 43.03926,
    "lng": -74.6285,
    "state_id": "NY"
  },
  {
    "zip": 13454,
    "lat": 43.20988,
    "lng": -74.75733,
    "state_id": "NY"
  },
  {
    "zip": 13456,
    "lat": 42.99999,
    "lng": -75.25549,
    "state_id": "NY"
  },
  {
    "zip": 13459,
    "lat": 42.7765,
    "lng": -74.5882,
    "state_id": "NY"
  },
  {
    "zip": 13460,
    "lat": 42.68706,
    "lng": -75.45419,
    "state_id": "NY"
  },
  {
    "zip": 13461,
    "lat": 43.07041,
    "lng": -75.59912,
    "state_id": "NY"
  },
  {
    "zip": 13464,
    "lat": 42.68586,
    "lng": -75.61754,
    "state_id": "NY"
  },
  {
    "zip": 13468,
    "lat": 42.84523,
    "lng": -74.85576,
    "state_id": "NY"
  },
  {
    "zip": 13469,
    "lat": 43.21526,
    "lng": -75.29956,
    "state_id": "NY"
  },
  {
    "zip": 13470,
    "lat": 43.19982,
    "lng": -74.64135,
    "state_id": "NY"
  },
  {
    "zip": 13471,
    "lat": 43.42057,
    "lng": -75.6175,
    "state_id": "NY"
  },
  {
    "zip": 13472,
    "lat": 43.69405,
    "lng": -75.0594,
    "state_id": "NY"
  },
  {
    "zip": 13473,
    "lat": 43.64444,
    "lng": -75.46384,
    "state_id": "NY"
  },
  {
    "zip": 13475,
    "lat": 42.89381,
    "lng": -74.83025,
    "state_id": "NY"
  },
  {
    "zip": 13476,
    "lat": 43.08838,
    "lng": -75.50959,
    "state_id": "NY"
  },
  {
    "zip": 13477,
    "lat": 43.03291,
    "lng": -75.51291,
    "state_id": "NY"
  },
  {
    "zip": 13478,
    "lat": 43.14924,
    "lng": -75.58207,
    "state_id": "NY"
  },
  {
    "zip": 13480,
    "lat": 42.91577,
    "lng": -75.3675,
    "state_id": "NY"
  },
  {
    "zip": 13483,
    "lat": 43.39757,
    "lng": -75.82556,
    "state_id": "NY"
  },
  {
    "zip": 13484,
    "lat": 42.86633,
    "lng": -75.65921,
    "state_id": "NY"
  },
  {
    "zip": 13485,
    "lat": 42.78675,
    "lng": -75.31488,
    "state_id": "NY"
  },
  {
    "zip": 13486,
    "lat": 43.34664,
    "lng": -75.34962,
    "state_id": "NY"
  },
  {
    "zip": 13488,
    "lat": 42.691,
    "lng": -74.74894,
    "state_id": "NY"
  },
  {
    "zip": 13489,
    "lat": 43.46716,
    "lng": -75.54189,
    "state_id": "NY"
  },
  {
    "zip": 13490,
    "lat": 43.11009,
    "lng": -75.42646,
    "state_id": "NY"
  },
  {
    "zip": 13491,
    "lat": 42.86272,
    "lng": -75.17634,
    "state_id": "NY"
  },
  {
    "zip": 13492,
    "lat": 43.11988,
    "lng": -75.32875,
    "state_id": "NY"
  },
  {
    "zip": 13493,
    "lat": 43.43417,
    "lng": -75.89572,
    "state_id": "NY"
  },
  {
    "zip": 13494,
    "lat": 43.53267,
    "lng": -75.14125,
    "state_id": "NY"
  },
  {
    "zip": 13495,
    "lat": 43.11085,
    "lng": -75.27752,
    "state_id": "NY"
  },
  {
    "zip": 13501,
    "lat": 43.08125,
    "lng": -75.22635,
    "state_id": "NY"
  },
  {
    "zip": 13502,
    "lat": 43.14099,
    "lng": -75.16112,
    "state_id": "NY"
  },
  {
    "zip": 13601,
    "lat": 43.96838,
    "lng": -75.90673,
    "state_id": "NY"
  },
  {
    "zip": 13602,
    "lat": 44.05127,
    "lng": -75.76504,
    "state_id": "NY"
  },
  {
    "zip": 13603,
    "lat": 44.03703,
    "lng": -75.79779,
    "state_id": "NY"
  },
  {
    "zip": 13605,
    "lat": 43.80618,
    "lng": -76.04894,
    "state_id": "NY"
  },
  {
    "zip": 13606,
    "lat": 43.87206,
    "lng": -76.01523,
    "state_id": "NY"
  },
  {
    "zip": 13607,
    "lat": 44.31524,
    "lng": -75.919,
    "state_id": "NY"
  },
  {
    "zip": 13608,
    "lat": 44.2403,
    "lng": -75.62522,
    "state_id": "NY"
  },
  {
    "zip": 13612,
    "lat": 43.98821,
    "lng": -75.7716,
    "state_id": "NY"
  },
  {
    "zip": 13613,
    "lat": 44.86385,
    "lng": -74.74076,
    "state_id": "NY"
  },
  {
    "zip": 13614,
    "lat": 44.53089,
    "lng": -75.69114,
    "state_id": "NY"
  },
  {
    "zip": 13615,
    "lat": 44.04018,
    "lng": -75.98509,
    "state_id": "NY"
  },
  {
    "zip": 13616,
    "lat": 44.02608,
    "lng": -75.85861,
    "state_id": "NY"
  },
  {
    "zip": 13617,
    "lat": 44.5792,
    "lng": -75.14931,
    "state_id": "NY"
  },
  {
    "zip": 13618,
    "lat": 44.11925,
    "lng": -76.27578,
    "state_id": "NY"
  },
  {
    "zip": 13619,
    "lat": 43.97907,
    "lng": -75.60121,
    "state_id": "NY"
  },
  {
    "zip": 13620,
    "lat": 43.91039,
    "lng": -75.45017,
    "state_id": "NY"
  },
  {
    "zip": 13621,
    "lat": 44.83972,
    "lng": -75.07205,
    "state_id": "NY"
  },
  {
    "zip": 13622,
    "lat": 44.09671,
    "lng": -76.11278,
    "state_id": "NY"
  },
  {
    "zip": 13623,
    "lat": 44.44976,
    "lng": -75.75247,
    "state_id": "NY"
  },
  {
    "zip": 13624,
    "lat": 44.21199,
    "lng": -76.09426,
    "state_id": "NY"
  },
  {
    "zip": 13625,
    "lat": 44.53799,
    "lng": -74.92593,
    "state_id": "NY"
  },
  {
    "zip": 13626,
    "lat": 43.84126,
    "lng": -75.72062,
    "state_id": "NY"
  },
  {
    "zip": 13628,
    "lat": 44.0316,
    "lng": -75.68246,
    "state_id": "NY"
  },
  {
    "zip": 13630,
    "lat": 44.49742,
    "lng": -75.31529,
    "state_id": "NY"
  },
  {
    "zip": 13633,
    "lat": 44.48686,
    "lng": -75.48393,
    "state_id": "NY"
  },
  {
    "zip": 13634,
    "lat": 44.00948,
    "lng": -76.07736,
    "state_id": "NY"
  },
  {
    "zip": 13635,
    "lat": 44.3039,
    "lng": -75.2504,
    "state_id": "NY"
  },
  {
    "zip": 13636,
    "lat": 43.74343,
    "lng": -76.11664,
    "state_id": "NY"
  },
  {
    "zip": 13637,
    "lat": 44.09632,
    "lng": -75.83001,
    "state_id": "NY"
  },
  {
    "zip": 13638,
    "lat": 44.02032,
    "lng": -75.74888,
    "state_id": "NY"
  },
  {
    "zip": 13639,
    "lat": 44.2541,
    "lng": -75.13798,
    "state_id": "NY"
  },
  {
    "zip": 13640,
    "lat": 44.32224,
    "lng": -75.99117,
    "state_id": "NY"
  },
  {
    "zip": 13641,
    "lat": 44.27524,
    "lng": -76.00474,
    "state_id": "NY"
  },
  {
    "zip": 13642,
    "lat": 44.33609,
    "lng": -75.45708,
    "state_id": "NY"
  },
  {
    "zip": 13643,
    "lat": 44.03181,
    "lng": -75.71709,
    "state_id": "NY"
  },
  {
    "zip": 13646,
    "lat": 44.43933,
    "lng": -75.67844,
    "state_id": "NY"
  },
  {
    "zip": 13647,
    "lat": 44.60584,
    "lng": -74.98059,
    "state_id": "NY"
  },
  {
    "zip": 13648,
    "lat": 44.14914,
    "lng": -75.31807,
    "state_id": "NY"
  },
  {
    "zip": 13650,
    "lat": 43.80752,
    "lng": -76.206,
    "state_id": "NY"
  },
  {
    "zip": 13651,
    "lat": 43.868,
    "lng": -76.17874,
    "state_id": "NY"
  },
  {
    "zip": 13652,
    "lat": 44.44226,
    "lng": -75.20324,
    "state_id": "NY"
  },
  {
    "zip": 13654,
    "lat": 44.56872,
    "lng": -75.45567,
    "state_id": "NY"
  },
  {
    "zip": 13655,
    "lat": 44.97809,
    "lng": -74.6513,
    "state_id": "NY"
  },
  {
    "zip": 13656,
    "lat": 44.19138,
    "lng": -75.94969,
    "state_id": "NY"
  },
  {
    "zip": 13658,
    "lat": 44.73794,
    "lng": -75.27739,
    "state_id": "NY"
  },
  {
    "zip": 13659,
    "lat": 43.73818,
    "lng": -75.87133,
    "state_id": "NY"
  },
  {
    "zip": 13660,
    "lat": 44.77123,
    "lng": -75.15848,
    "state_id": "NY"
  },
  {
    "zip": 13661,
    "lat": 43.70988,
    "lng": -76.10606,
    "state_id": "NY"
  },
  {
    "zip": 13662,
    "lat": 44.9322,
    "lng": -74.8844,
    "state_id": "NY"
  },
  {
    "zip": 13664,
    "lat": 44.58426,
    "lng": -75.64532,
    "state_id": "NY"
  },
  {
    "zip": 13665,
    "lat": 44.0477,
    "lng": -75.44368,
    "state_id": "NY"
  },
  {
    "zip": 13666,
    "lat": 44.21772,
    "lng": -74.94226,
    "state_id": "NY"
  },
  {
    "zip": 13667,
    "lat": 44.83726,
    "lng": -74.96392,
    "state_id": "NY"
  },
  {
    "zip": 13668,
    "lat": 44.75496,
    "lng": -74.98605,
    "state_id": "NY"
  },
  {
    "zip": 13669,
    "lat": 44.6441,
    "lng": -75.49517,
    "state_id": "NY"
  },
  {
    "zip": 13670,
    "lat": 44.16999,
    "lng": -75.11349,
    "state_id": "NY"
  },
  {
    "zip": 13672,
    "lat": 44.50261,
    "lng": -74.68515,
    "state_id": "NY"
  },
  {
    "zip": 13673,
    "lat": 44.16665,
    "lng": -75.71884,
    "state_id": "NY"
  },
  {
    "zip": 13674,
    "lat": 43.73823,
    "lng": -76.05526,
    "state_id": "NY"
  },
  {
    "zip": 13675,
    "lat": 44.27907,
    "lng": -75.84834,
    "state_id": "NY"
  },
  {
    "zip": 13676,
    "lat": 44.653,
    "lng": -74.92142,
    "state_id": "NY"
  },
  {
    "zip": 13677,
    "lat": 44.51126,
    "lng": -75.17881,
    "state_id": "NY"
  },
  {
    "zip": 13678,
    "lat": 44.82086,
    "lng": -74.98385,
    "state_id": "NY"
  },
  {
    "zip": 13679,
    "lat": 44.32437,
    "lng": -75.76427,
    "state_id": "NY"
  },
  {
    "zip": 13680,
    "lat": 44.59421,
    "lng": -75.32386,
    "state_id": "NY"
  },
  {
    "zip": 13681,
    "lat": 44.42851,
    "lng": -75.37088,
    "state_id": "NY"
  },
  {
    "zip": 13682,
    "lat": 43.84193,
    "lng": -75.8901,
    "state_id": "NY"
  },
  {
    "zip": 13684,
    "lat": 44.36187,
    "lng": -75.04066,
    "state_id": "NY"
  },
  {
    "zip": 13685,
    "lat": 43.92448,
    "lng": -76.17347,
    "state_id": "NY"
  },
  {
    "zip": 13687,
    "lat": 44.42317,
    "lng": -74.82047,
    "state_id": "NY"
  },
  {
    "zip": 13690,
    "lat": 44.11593,
    "lng": -75.01035,
    "state_id": "NY"
  },
  {
    "zip": 13691,
    "lat": 44.22541,
    "lng": -75.7832,
    "state_id": "NY"
  },
  {
    "zip": 13692,
    "lat": 44.28887,
    "lng": -76.02632,
    "state_id": "NY"
  },
  {
    "zip": 13693,
    "lat": 44.02833,
    "lng": -76.25075,
    "state_id": "NY"
  },
  {
    "zip": 13694,
    "lat": 44.85099,
    "lng": -75.16108,
    "state_id": "NY"
  },
  {
    "zip": 13695,
    "lat": 44.10979,
    "lng": -74.92161,
    "state_id": "NY"
  },
  {
    "zip": 13696,
    "lat": 44.69472,
    "lng": -74.88802,
    "state_id": "NY"
  },
  {
    "zip": 13697,
    "lat": 44.74892,
    "lng": -74.8095,
    "state_id": "NY"
  },
  {
    "zip": 13730,
    "lat": 42.22934,
    "lng": -75.53325,
    "state_id": "NY"
  },
  {
    "zip": 13731,
    "lat": 42.12927,
    "lng": -74.78925,
    "state_id": "NY"
  },
  {
    "zip": 13732,
    "lat": 42.04413,
    "lng": -76.168,
    "state_id": "NY"
  },
  {
    "zip": 13733,
    "lat": 42.30321,
    "lng": -75.48204,
    "state_id": "NY"
  },
  {
    "zip": 13734,
    "lat": 42.07439,
    "lng": -76.40764,
    "state_id": "NY"
  },
  {
    "zip": 13736,
    "lat": 42.31851,
    "lng": -76.19833,
    "state_id": "NY"
  },
  {
    "zip": 13739,
    "lat": 42.36614,
    "lng": -74.79281,
    "state_id": "NY"
  },
  {
    "zip": 13740,
    "lat": 42.27136,
    "lng": -74.75077,
    "state_id": "NY"
  },
  {
    "zip": 13743,
    "lat": 42.21616,
    "lng": -76.33854,
    "state_id": "NY"
  },
  {
    "zip": 13744,
    "lat": 42.23865,
    "lng": -75.90912,
    "state_id": "NY"
  },
  {
    "zip": 13746,
    "lat": 42.27242,
    "lng": -75.83524,
    "state_id": "NY"
  },
  {
    "zip": 13748,
    "lat": 42.0345,
    "lng": -75.81702,
    "state_id": "NY"
  },
  {
    "zip": 13750,
    "lat": 42.47127,
    "lng": -74.84609,
    "state_id": "NY"
  },
  {
    "zip": 13751,
    "lat": 42.4508,
    "lng": -74.90131,
    "state_id": "NY"
  },
  {
    "zip": 13752,
    "lat": 42.18312,
    "lng": -74.90136,
    "state_id": "NY"
  },
  {
    "zip": 13753,
    "lat": 42.30733,
    "lng": -74.92678,
    "state_id": "NY"
  },
  {
    "zip": 13754,
    "lat": 42.09109,
    "lng": -75.4358,
    "state_id": "NY"
  },
  {
    "zip": 13755,
    "lat": 42.06285,
    "lng": -74.99797,
    "state_id": "NY"
  },
  {
    "zip": 13756,
    "lat": 42.00622,
    "lng": -75.10093,
    "state_id": "NY"
  },
  {
    "zip": 13757,
    "lat": 42.40895,
    "lng": -74.90374,
    "state_id": "NY"
  },
  {
    "zip": 13760,
    "lat": 42.1349,
    "lng": -76.08467,
    "state_id": "NY"
  },
  {
    "zip": 13774,
    "lat": 41.9655,
    "lng": -75.15925,
    "state_id": "NY"
  },
  {
    "zip": 13775,
    "lat": 42.3309,
    "lng": -75.1471,
    "state_id": "NY"
  },
  {
    "zip": 13776,
    "lat": 42.47058,
    "lng": -75.33294,
    "state_id": "NY"
  },
  {
    "zip": 13777,
    "lat": 42.25815,
    "lng": -75.9896,
    "state_id": "NY"
  },
  {
    "zip": 13778,
    "lat": 42.34332,
    "lng": -75.75481,
    "state_id": "NY"
  },
  {
    "zip": 13780,
    "lat": 42.42488,
    "lng": -75.47926,
    "state_id": "NY"
  },
  {
    "zip": 13782,
    "lat": 42.16647,
    "lng": -74.98201,
    "state_id": "NY"
  },
  {
    "zip": 13783,
    "lat": 41.9914,
    "lng": -75.26477,
    "state_id": "NY"
  },
  {
    "zip": 13784,
    "lat": 42.42693,
    "lng": -76.22137,
    "state_id": "NY"
  },
  {
    "zip": 13786,
    "lat": 42.4414,
    "lng": -74.69464,
    "state_id": "NY"
  },
  {
    "zip": 13787,
    "lat": 42.20504,
    "lng": -75.66757,
    "state_id": "NY"
  },
  {
    "zip": 13788,
    "lat": 42.35102,
    "lng": -74.65813,
    "state_id": "NY"
  },
  {
    "zip": 13790,
    "lat": 42.17019,
    "lng": -75.99803,
    "state_id": "NY"
  },
  {
    "zip": 13794,
    "lat": 42.39312,
    "lng": -76.0137,
    "state_id": "NY"
  },
  {
    "zip": 13795,
    "lat": 42.0607,
    "lng": -75.77999,
    "state_id": "NY"
  },
  {
    "zip": 13796,
    "lat": 42.55069,
    "lng": -75.13978,
    "state_id": "NY"
  },
  {
    "zip": 13797,
    "lat": 42.33042,
    "lng": -76.04827,
    "state_id": "NY"
  },
  {
    "zip": 13801,
    "lat": 42.50961,
    "lng": -75.77965,
    "state_id": "NY"
  },
  {
    "zip": 13802,
    "lat": 42.2494,
    "lng": -76.04051,
    "state_id": "NY"
  },
  {
    "zip": 13803,
    "lat": 42.45654,
    "lng": -76.06327,
    "state_id": "NY"
  },
  {
    "zip": 13804,
    "lat": 42.21024,
    "lng": -75.3739,
    "state_id": "NY"
  },
  {
    "zip": 13806,
    "lat": 42.37348,
    "lng": -74.96509,
    "state_id": "NY"
  },
  {
    "zip": 13807,
    "lat": 42.60875,
    "lng": -74.98652,
    "state_id": "NY"
  },
  {
    "zip": 13808,
    "lat": 42.5279,
    "lng": -75.25647,
    "state_id": "NY"
  },
  {
    "zip": 13809,
    "lat": 42.40381,
    "lng": -75.39554,
    "state_id": "NY"
  },
  {
    "zip": 13810,
    "lat": 42.61172,
    "lng": -75.1116,
    "state_id": "NY"
  },
  {
    "zip": 13811,
    "lat": 42.23178,
    "lng": -76.17458,
    "state_id": "NY"
  },
  {
    "zip": 13812,
    "lat": 42.03013,
    "lng": -76.3539,
    "state_id": "NY"
  },
  {
    "zip": 13813,
    "lat": 42.16688,
    "lng": -75.54751,
    "state_id": "NY"
  },
  {
    "zip": 13815,
    "lat": 42.54689,
    "lng": -75.53118,
    "state_id": "NY"
  },
  {
    "zip": 13820,
    "lat": 42.47468,
    "lng": -75.04058,
    "state_id": "NY"
  },
  {
    "zip": 13825,
    "lat": 42.43937,
    "lng": -75.2022,
    "state_id": "NY"
  },
  {
    "zip": 13826,
    "lat": 42.1026,
    "lng": -75.63986,
    "state_id": "NY"
  },
  {
    "zip": 13827,
    "lat": 42.11192,
    "lng": -76.2546,
    "state_id": "NY"
  },
  {
    "zip": 13830,
    "lat": 42.44002,
    "lng": -75.62797,
    "state_id": "NY"
  },
  {
    "zip": 13832,
    "lat": 42.65776,
    "lng": -75.67579,
    "state_id": "NY"
  },
  {
    "zip": 13833,
    "lat": 42.19942,
    "lng": -75.7672,
    "state_id": "NY"
  },
  {
    "zip": 13834,
    "lat": 42.53386,
    "lng": -74.96276,
    "state_id": "NY"
  },
  {
    "zip": 13835,
    "lat": 42.38956,
    "lng": -76.17119,
    "state_id": "NY"
  },
  {
    "zip": 13838,
    "lat": 42.28573,
    "lng": -75.39269,
    "state_id": "NY"
  },
  {
    "zip": 13839,
    "lat": 42.26141,
    "lng": -75.25798,
    "state_id": "NY"
  },
  {
    "zip": 13841,
    "lat": 42.41375,
    "lng": -75.83693,
    "state_id": "NY"
  },
  {
    "zip": 13842,
    "lat": 42.37369,
    "lng": -74.72398,
    "state_id": "NY"
  },
  {
    "zip": 13843,
    "lat": 42.52442,
    "lng": -75.37338,
    "state_id": "NY"
  },
  {
    "zip": 13844,
    "lat": 42.61008,
    "lng": -75.67041,
    "state_id": "NY"
  },
  {
    "zip": 13845,
    "lat": 42.05471,
    "lng": -76.35374,
    "state_id": "NY"
  },
  {
    "zip": 13846,
    "lat": 42.36281,
    "lng": -75.05218,
    "state_id": "NY"
  },
  {
    "zip": 13847,
    "lat": 42.18592,
    "lng": -75.29064,
    "state_id": "NY"
  },
  {
    "zip": 13849,
    "lat": 42.34381,
    "lng": -75.31152,
    "state_id": "NY"
  },
  {
    "zip": 13850,
    "lat": 42.04876,
    "lng": -76.02661,
    "state_id": "NY"
  },
  {
    "zip": 13856,
    "lat": 42.16588,
    "lng": -75.16721,
    "state_id": "NY"
  },
  {
    "zip": 13859,
    "lat": 42.37092,
    "lng": -75.24854,
    "state_id": "NY"
  },
  {
    "zip": 13860,
    "lat": 42.4514,
    "lng": -74.93801,
    "state_id": "NY"
  },
  {
    "zip": 13861,
    "lat": 42.5053,
    "lng": -75.1491,
    "state_id": "NY"
  },
  {
    "zip": 13862,
    "lat": 42.33638,
    "lng": -75.93851,
    "state_id": "NY"
  },
  {
    "zip": 13863,
    "lat": 42.44848,
    "lng": -75.90866,
    "state_id": "NY"
  },
  {
    "zip": 13864,
    "lat": 42.28926,
    "lng": -76.3927,
    "state_id": "NY"
  },
  {
    "zip": 13865,
    "lat": 42.06276,
    "lng": -75.63308,
    "state_id": "NY"
  },
  {
    "zip": 13901,
    "lat": 42.1824,
    "lng": -75.87988,
    "state_id": "NY"
  },
  {
    "zip": 13902,
    "lat": 42.08888,
    "lng": -75.96892,
    "state_id": "NY"
  },
  {
    "zip": 13903,
    "lat": 42.04737,
    "lng": -75.89543,
    "state_id": "NY"
  },
  {
    "zip": 13904,
    "lat": 42.13079,
    "lng": -75.81597,
    "state_id": "NY"
  },
  {
    "zip": 13905,
    "lat": 42.18011,
    "lng": -75.94471,
    "state_id": "NY"
  },
  {
    "zip": 14001,
    "lat": 43.03296,
    "lng": -78.51205,
    "state_id": "NY"
  },
  {
    "zip": 14004,
    "lat": 42.89467,
    "lng": -78.50971,
    "state_id": "NY"
  },
  {
    "zip": 14005,
    "lat": 42.91694,
    "lng": -78.2503,
    "state_id": "NY"
  },
  {
    "zip": 14006,
    "lat": 42.63368,
    "lng": -79.02321,
    "state_id": "NY"
  },
  {
    "zip": 14008,
    "lat": 43.31174,
    "lng": -78.62669,
    "state_id": "NY"
  },
  {
    "zip": 14009,
    "lat": 42.57462,
    "lng": -78.39319,
    "state_id": "NY"
  },
  {
    "zip": 14011,
    "lat": 42.83226,
    "lng": -78.29695,
    "state_id": "NY"
  },
  {
    "zip": 14012,
    "lat": 43.33439,
    "lng": -78.52548,
    "state_id": "NY"
  },
  {
    "zip": 14013,
    "lat": 43.08141,
    "lng": -78.39763,
    "state_id": "NY"
  },
  {
    "zip": 14020,
    "lat": 42.99624,
    "lng": -78.21274,
    "state_id": "NY"
  },
  {
    "zip": 14024,
    "lat": 42.58014,
    "lng": -78.2492,
    "state_id": "NY"
  },
  {
    "zip": 14025,
    "lat": 42.6222,
    "lng": -78.72705,
    "state_id": "NY"
  },
  {
    "zip": 14026,
    "lat": 42.94239,
    "lng": -78.68798,
    "state_id": "NY"
  },
  {
    "zip": 14028,
    "lat": 43.3161,
    "lng": -78.71781,
    "state_id": "NY"
  },
  {
    "zip": 14030,
    "lat": 42.56626,
    "lng": -78.50097,
    "state_id": "NY"
  },
  {
    "zip": 14031,
    "lat": 42.98721,
    "lng": -78.61075,
    "state_id": "NY"
  },
  {
    "zip": 14032,
    "lat": 43.04672,
    "lng": -78.63074,
    "state_id": "NY"
  },
  {
    "zip": 14033,
    "lat": 42.66062,
    "lng": -78.68688,
    "state_id": "NY"
  },
  {
    "zip": 14034,
    "lat": 42.49818,
    "lng": -78.86094,
    "state_id": "NY"
  },
  {
    "zip": 14035,
    "lat": 42.49073,
    "lng": -78.84831,
    "state_id": "NY"
  },
  {
    "zip": 14036,
    "lat": 42.97503,
    "lng": -78.38734,
    "state_id": "NY"
  },
  {
    "zip": 14037,
    "lat": 42.80578,
    "lng": -78.45513,
    "state_id": "NY"
  },
  {
    "zip": 14039,
    "lat": 42.83638,
    "lng": -78.16926,
    "state_id": "NY"
  },
  {
    "zip": 14040,
    "lat": 42.88925,
    "lng": -78.38116,
    "state_id": "NY"
  },
  {
    "zip": 14041,
    "lat": 42.40358,
    "lng": -78.97358,
    "state_id": "NY"
  },
  {
    "zip": 14042,
    "lat": 42.469,
    "lng": -78.48654,
    "state_id": "NY"
  },
  {
    "zip": 14043,
    "lat": 42.90191,
    "lng": -78.70339,
    "state_id": "NY"
  },
  {
    "zip": 14047,
    "lat": 42.68585,
    "lng": -78.99348,
    "state_id": "NY"
  },
  {
    "zip": 14048,
    "lat": 42.48052,
    "lng": -79.31161,
    "state_id": "NY"
  },
  {
    "zip": 14051,
    "lat": 43.04221,
    "lng": -78.69906,
    "state_id": "NY"
  },
  {
    "zip": 14052,
    "lat": 42.77018,
    "lng": -78.58105,
    "state_id": "NY"
  },
  {
    "zip": 14054,
    "lat": 42.91517,
    "lng": -78.1275,
    "state_id": "NY"
  },
  {
    "zip": 14055,
    "lat": 42.55467,
    "lng": -78.6014,
    "state_id": "NY"
  },
  {
    "zip": 14057,
    "lat": 42.64582,
    "lng": -78.87732,
    "state_id": "NY"
  },
  {
    "zip": 14058,
    "lat": 43.10243,
    "lng": -78.16272,
    "state_id": "NY"
  },
  {
    "zip": 14059,
    "lat": 42.83363,
    "lng": -78.636,
    "state_id": "NY"
  },
  {
    "zip": 14060,
    "lat": 42.44658,
    "lng": -78.30793,
    "state_id": "NY"
  },
  {
    "zip": 14061,
    "lat": 42.59387,
    "lng": -79.07934,
    "state_id": "NY"
  },
  {
    "zip": 14062,
    "lat": 42.4409,
    "lng": -79.15074,
    "state_id": "NY"
  },
  {
    "zip": 14063,
    "lat": 42.414,
    "lng": -79.32601,
    "state_id": "NY"
  },
  {
    "zip": 14065,
    "lat": 42.48717,
    "lng": -78.31345,
    "state_id": "NY"
  },
  {
    "zip": 14066,
    "lat": 42.62421,
    "lng": -78.18037,
    "state_id": "NY"
  },
  {
    "zip": 14067,
    "lat": 43.21573,
    "lng": -78.56662,
    "state_id": "NY"
  },
  {
    "zip": 14068,
    "lat": 43.02746,
    "lng": -78.75649,
    "state_id": "NY"
  },
  {
    "zip": 14069,
    "lat": 42.60762,
    "lng": -78.63381,
    "state_id": "NY"
  },
  {
    "zip": 14070,
    "lat": 42.4361,
    "lng": -78.92583,
    "state_id": "NY"
  },
  {
    "zip": 14072,
    "lat": 43.01819,
    "lng": -78.96356,
    "state_id": "NY"
  },
  {
    "zip": 14075,
    "lat": 42.71394,
    "lng": -78.83238,
    "state_id": "NY"
  },
  {
    "zip": 14080,
    "lat": 42.64406,
    "lng": -78.54661,
    "state_id": "NY"
  },
  {
    "zip": 14081,
    "lat": 42.56181,
    "lng": -79.06362,
    "state_id": "NY"
  },
  {
    "zip": 14082,
    "lat": 42.65656,
    "lng": -78.38237,
    "state_id": "NY"
  },
  {
    "zip": 14085,
    "lat": 42.71412,
    "lng": -78.92916,
    "state_id": "NY"
  },
  {
    "zip": 14086,
    "lat": 42.90809,
    "lng": -78.62904,
    "state_id": "NY"
  },
  {
    "zip": 14091,
    "lat": 42.54238,
    "lng": -78.88369,
    "state_id": "NY"
  },
  {
    "zip": 14092,
    "lat": 43.17357,
    "lng": -78.99361,
    "state_id": "NY"
  },
  {
    "zip": 14094,
    "lat": 43.16018,
    "lng": -78.7009,
    "state_id": "NY"
  },
  {
    "zip": 14098,
    "lat": 43.33438,
    "lng": -78.3806,
    "state_id": "NY"
  },
  {
    "zip": 14101,
    "lat": 42.38953,
    "lng": -78.54357,
    "state_id": "NY"
  },
  {
    "zip": 14102,
    "lat": 42.83931,
    "lng": -78.55687,
    "state_id": "NY"
  },
  {
    "zip": 14103,
    "lat": 43.21227,
    "lng": -78.37666,
    "state_id": "NY"
  },
  {
    "zip": 14105,
    "lat": 43.19782,
    "lng": -78.48395,
    "state_id": "NY"
  },
  {
    "zip": 14108,
    "lat": 43.26333,
    "lng": -78.72701,
    "state_id": "NY"
  },
  {
    "zip": 14109,
    "lat": 43.13777,
    "lng": -79.03521,
    "state_id": "NY"
  },
  {
    "zip": 14111,
    "lat": 42.58254,
    "lng": -78.9008,
    "state_id": "NY"
  },
  {
    "zip": 14112,
    "lat": 42.6981,
    "lng": -78.9395,
    "state_id": "NY"
  },
  {
    "zip": 14113,
    "lat": 42.67163,
    "lng": -78.33968,
    "state_id": "NY"
  },
  {
    "zip": 14120,
    "lat": 43.07363,
    "lng": -78.83645,
    "state_id": "NY"
  },
  {
    "zip": 14125,
    "lat": 43.0871,
    "lng": -78.2784,
    "state_id": "NY"
  },
  {
    "zip": 14126,
    "lat": 43.33565,
    "lng": -78.72558,
    "state_id": "NY"
  },
  {
    "zip": 14127,
    "lat": 42.75176,
    "lng": -78.74377,
    "state_id": "NY"
  },
  {
    "zip": 14129,
    "lat": 42.48009,
    "lng": -79.01038,
    "state_id": "NY"
  },
  {
    "zip": 14130,
    "lat": 42.55599,
    "lng": -78.14771,
    "state_id": "NY"
  },
  {
    "zip": 14131,
    "lat": 43.23624,
    "lng": -78.89904,
    "state_id": "NY"
  },
  {
    "zip": 14132,
    "lat": 43.14932,
    "lng": -78.8777,
    "state_id": "NY"
  },
  {
    "zip": 14134,
    "lat": 42.52748,
    "lng": -78.52419,
    "state_id": "NY"
  },
  {
    "zip": 14135,
    "lat": 42.48767,
    "lng": -79.2409,
    "state_id": "NY"
  },
  {
    "zip": 14136,
    "lat": 42.52306,
    "lng": -79.17042,
    "state_id": "NY"
  },
  {
    "zip": 14138,
    "lat": 42.37026,
    "lng": -79.05056,
    "state_id": "NY"
  },
  {
    "zip": 14139,
    "lat": 42.71657,
    "lng": -78.53477,
    "state_id": "NY"
  },
  {
    "zip": 14141,
    "lat": 42.52208,
    "lng": -78.70939,
    "state_id": "NY"
  },
  {
    "zip": 14143,
    "lat": 42.97556,
    "lng": -78.07337,
    "state_id": "NY"
  },
  {
    "zip": 14145,
    "lat": 42.73004,
    "lng": -78.42813,
    "state_id": "NY"
  },
  {
    "zip": 14150,
    "lat": 42.99782,
    "lng": -78.87829,
    "state_id": "NY"
  },
  {
    "zip": 14167,
    "lat": 42.74412,
    "lng": -78.32487,
    "state_id": "NY"
  },
  {
    "zip": 14168,
    "lat": 42.52038,
    "lng": -78.99068,
    "state_id": "NY"
  },
  {
    "zip": 14169,
    "lat": 42.76612,
    "lng": -78.52369,
    "state_id": "NY"
  },
  {
    "zip": 14170,
    "lat": 42.70227,
    "lng": -78.67089,
    "state_id": "NY"
  },
  {
    "zip": 14171,
    "lat": 42.42279,
    "lng": -78.64277,
    "state_id": "NY"
  },
  {
    "zip": 14172,
    "lat": 43.28315,
    "lng": -78.81813,
    "state_id": "NY"
  },
  {
    "zip": 14173,
    "lat": 42.52601,
    "lng": -78.47312,
    "state_id": "NY"
  },
  {
    "zip": 14174,
    "lat": 43.24097,
    "lng": -79.00178,
    "state_id": "NY"
  },
  {
    "zip": 14201,
    "lat": 42.89667,
    "lng": -78.88697,
    "state_id": "NY"
  },
  {
    "zip": 14202,
    "lat": 42.88793,
    "lng": -78.88309,
    "state_id": "NY"
  },
  {
    "zip": 14203,
    "lat": 42.86381,
    "lng": -78.8658,
    "state_id": "NY"
  },
  {
    "zip": 14204,
    "lat": 42.88221,
    "lng": -78.86135,
    "state_id": "NY"
  },
  {
    "zip": 14206,
    "lat": 42.88088,
    "lng": -78.8114,
    "state_id": "NY"
  },
  {
    "zip": 14207,
    "lat": 42.95173,
    "lng": -78.89779,
    "state_id": "NY"
  },
  {
    "zip": 14208,
    "lat": 42.91609,
    "lng": -78.85301,
    "state_id": "NY"
  },
  {
    "zip": 14209,
    "lat": 42.91402,
    "lng": -78.86605,
    "state_id": "NY"
  },
  {
    "zip": 14210,
    "lat": 42.86351,
    "lng": -78.82739,
    "state_id": "NY"
  },
  {
    "zip": 14211,
    "lat": 42.90779,
    "lng": -78.81906,
    "state_id": "NY"
  },
  {
    "zip": 14212,
    "lat": 42.89422,
    "lng": -78.82018,
    "state_id": "NY"
  },
  {
    "zip": 14213,
    "lat": 42.91804,
    "lng": -78.89281,
    "state_id": "NY"
  },
  {
    "zip": 14214,
    "lat": 42.93952,
    "lng": -78.84083,
    "state_id": "NY"
  },
  {
    "zip": 14215,
    "lat": 42.93535,
    "lng": -78.81069,
    "state_id": "NY"
  },
  {
    "zip": 14216,
    "lat": 42.94848,
    "lng": -78.8617,
    "state_id": "NY"
  },
  {
    "zip": 14217,
    "lat": 42.97183,
    "lng": -78.87686,
    "state_id": "NY"
  },
  {
    "zip": 14218,
    "lat": 42.81966,
    "lng": -78.82865,
    "state_id": "NY"
  },
  {
    "zip": 14219,
    "lat": 42.78893,
    "lng": -78.82691,
    "state_id": "NY"
  },
  {
    "zip": 14220,
    "lat": 42.84565,
    "lng": -78.82321,
    "state_id": "NY"
  },
  {
    "zip": 14221,
    "lat": 42.98002,
    "lng": -78.72285,
    "state_id": "NY"
  },
  {
    "zip": 14222,
    "lat": 42.91977,
    "lng": -78.87686,
    "state_id": "NY"
  },
  {
    "zip": 14223,
    "lat": 42.97346,
    "lng": -78.84621,
    "state_id": "NY"
  },
  {
    "zip": 14224,
    "lat": 42.83781,
    "lng": -78.74799,
    "state_id": "NY"
  },
  {
    "zip": 14225,
    "lat": 42.92895,
    "lng": -78.75165,
    "state_id": "NY"
  },
  {
    "zip": 14226,
    "lat": 42.97098,
    "lng": -78.79873,
    "state_id": "NY"
  },
  {
    "zip": 14227,
    "lat": 42.88425,
    "lng": -78.74615,
    "state_id": "NY"
  },
  {
    "zip": 14228,
    "lat": 43.04091,
    "lng": -78.78122,
    "state_id": "NY"
  },
  {
    "zip": 14261,
    "lat": 43.00198,
    "lng": -78.78534,
    "state_id": "NY"
  },
  {
    "zip": 14301,
    "lat": 43.09583,
    "lng": -79.04052,
    "state_id": "NY"
  },
  {
    "zip": 14302,
    "lat": 43.0939,
    "lng": -79.04919,
    "state_id": "NY"
  },
  {
    "zip": 14303,
    "lat": 43.08501,
    "lng": -79.03812,
    "state_id": "NY"
  },
  {
    "zip": 14304,
    "lat": 43.09942,
    "lng": -78.95199,
    "state_id": "NY"
  },
  {
    "zip": 14305,
    "lat": 43.11961,
    "lng": -79.02227,
    "state_id": "NY"
  },
  {
    "zip": 14411,
    "lat": 43.23425,
    "lng": -78.21193,
    "state_id": "NY"
  },
  {
    "zip": 14414,
    "lat": 42.89349,
    "lng": -77.73514,
    "state_id": "NY"
  },
  {
    "zip": 14415,
    "lat": 42.75548,
    "lng": -77.01708,
    "state_id": "NY"
  },
  {
    "zip": 14416,
    "lat": 43.07902,
    "lng": -77.98172,
    "state_id": "NY"
  },
  {
    "zip": 14418,
    "lat": 42.60783,
    "lng": -77.2151,
    "state_id": "NY"
  },
  {
    "zip": 14420,
    "lat": 43.212,
    "lng": -77.93455,
    "state_id": "NY"
  },
  {
    "zip": 14422,
    "lat": 43.08233,
    "lng": -78.0657,
    "state_id": "NY"
  },
  {
    "zip": 14423,
    "lat": 42.94419,
    "lng": -77.82759,
    "state_id": "NY"
  },
  {
    "zip": 14424,
    "lat": 42.84815,
    "lng": -77.30818,
    "state_id": "NY"
  },
  {
    "zip": 14425,
    "lat": 42.99155,
    "lng": -77.33801,
    "state_id": "NY"
  },
  {
    "zip": 14427,
    "lat": 42.62049,
    "lng": -78.05368,
    "state_id": "NY"
  },
  {
    "zip": 14428,
    "lat": 43.07741,
    "lng": -77.85949,
    "state_id": "NY"
  },
  {
    "zip": 14432,
    "lat": 42.95739,
    "lng": -77.1435,
    "state_id": "NY"
  },
  {
    "zip": 14433,
    "lat": 43.08198,
    "lng": -76.8786,
    "state_id": "NY"
  },
  {
    "zip": 14435,
    "lat": 42.71313,
    "lng": -77.66234,
    "state_id": "NY"
  },
  {
    "zip": 14437,
    "lat": 42.57523,
    "lng": -77.73829,
    "state_id": "NY"
  },
  {
    "zip": 14441,
    "lat": 42.68523,
    "lng": -76.95727,
    "state_id": "NY"
  },
  {
    "zip": 14445,
    "lat": 43.11262,
    "lng": -77.48992,
    "state_id": "NY"
  },
  {
    "zip": 14450,
    "lat": 43.09166,
    "lng": -77.41702,
    "state_id": "NY"
  },
  {
    "zip": 14454,
    "lat": 42.7969,
    "lng": -77.77384,
    "state_id": "NY"
  },
  {
    "zip": 14456,
    "lat": 42.8459,
    "lng": -77.00121,
    "state_id": "NY"
  },
  {
    "zip": 14462,
    "lat": 42.68655,
    "lng": -77.75113,
    "state_id": "NY"
  },
  {
    "zip": 14464,
    "lat": 43.32706,
    "lng": -77.93617,
    "state_id": "NY"
  },
  {
    "zip": 14466,
    "lat": 42.77652,
    "lng": -77.5811,
    "state_id": "NY"
  },
  {
    "zip": 14467,
    "lat": 43.04168,
    "lng": -77.61017,
    "state_id": "NY"
  },
  {
    "zip": 14468,
    "lat": 43.29112,
    "lng": -77.80539,
    "state_id": "NY"
  },
  {
    "zip": 14469,
    "lat": 42.87755,
    "lng": -77.46599,
    "state_id": "NY"
  },
  {
    "zip": 14470,
    "lat": 43.21053,
    "lng": -78.05341,
    "state_id": "NY"
  },
  {
    "zip": 14471,
    "lat": 42.75386,
    "lng": -77.49222,
    "state_id": "NY"
  },
  {
    "zip": 14472,
    "lat": 42.96729,
    "lng": -77.57913,
    "state_id": "NY"
  },
  {
    "zip": 14475,
    "lat": 42.9378,
    "lng": -77.4984,
    "state_id": "NY"
  },
  {
    "zip": 14476,
    "lat": 43.32922,
    "lng": -78.04473,
    "state_id": "NY"
  },
  {
    "zip": 14477,
    "lat": 43.33361,
    "lng": -78.13592,
    "state_id": "NY"
  },
  {
    "zip": 14478,
    "lat": 42.5779,
    "lng": -77.12632,
    "state_id": "NY"
  },
  {
    "zip": 14479,
    "lat": 43.23624,
    "lng": -78.31386,
    "state_id": "NY"
  },
  {
    "zip": 14480,
    "lat": 42.83948,
    "lng": -77.70683,
    "state_id": "NY"
  },
  {
    "zip": 14481,
    "lat": 42.76189,
    "lng": -77.92144,
    "state_id": "NY"
  },
  {
    "zip": 14482,
    "lat": 42.97701,
    "lng": -77.97232,
    "state_id": "NY"
  },
  {
    "zip": 14485,
    "lat": 42.87991,
    "lng": -77.60055,
    "state_id": "NY"
  },
  {
    "zip": 14486,
    "lat": 42.8943,
    "lng": -77.92158,
    "state_id": "NY"
  },
  {
    "zip": 14487,
    "lat": 42.81176,
    "lng": -77.63595,
    "state_id": "NY"
  },
  {
    "zip": 14489,
    "lat": 43.08496,
    "lng": -76.99002,
    "state_id": "NY"
  },
  {
    "zip": 14502,
    "lat": 43.09728,
    "lng": -77.33547,
    "state_id": "NY"
  },
  {
    "zip": 14504,
    "lat": 42.96905,
    "lng": -77.23154,
    "state_id": "NY"
  },
  {
    "zip": 14505,
    "lat": 43.16056,
    "lng": -77.17323,
    "state_id": "NY"
  },
  {
    "zip": 14506,
    "lat": 43.00176,
    "lng": -77.50701,
    "state_id": "NY"
  },
  {
    "zip": 14507,
    "lat": 42.69781,
    "lng": -77.27025,
    "state_id": "NY"
  },
  {
    "zip": 14510,
    "lat": 42.69013,
    "lng": -77.87462,
    "state_id": "NY"
  },
  {
    "zip": 14511,
    "lat": 42.99763,
    "lng": -77.87806,
    "state_id": "NY"
  },
  {
    "zip": 14512,
    "lat": 42.64558,
    "lng": -77.39159,
    "state_id": "NY"
  },
  {
    "zip": 14513,
    "lat": 43.07279,
    "lng": -77.09532,
    "state_id": "NY"
  },
  {
    "zip": 14514,
    "lat": 43.09985,
    "lng": -77.79969,
    "state_id": "NY"
  },
  {
    "zip": 14516,
    "lat": 43.20068,
    "lng": -76.91599,
    "state_id": "NY"
  },
  {
    "zip": 14517,
    "lat": 42.59241,
    "lng": -77.89582,
    "state_id": "NY"
  },
  {
    "zip": 14519,
    "lat": 43.2326,
    "lng": -77.31398,
    "state_id": "NY"
  },
  {
    "zip": 14521,
    "lat": 42.67538,
    "lng": -76.80747,
    "state_id": "NY"
  },
  {
    "zip": 14522,
    "lat": 43.06112,
    "lng": -77.22122,
    "state_id": "NY"
  },
  {
    "zip": 14525,
    "lat": 42.87328,
    "lng": -78.01243,
    "state_id": "NY"
  },
  {
    "zip": 14526,
    "lat": 43.15111,
    "lng": -77.44759,
    "state_id": "NY"
  },
  {
    "zip": 14527,
    "lat": 42.66712,
    "lng": -77.0653,
    "state_id": "NY"
  },
  {
    "zip": 14529,
    "lat": 42.53997,
    "lng": -77.63825,
    "state_id": "NY"
  },
  {
    "zip": 14530,
    "lat": 42.73343,
    "lng": -78.00479,
    "state_id": "NY"
  },
  {
    "zip": 14532,
    "lat": 42.96449,
    "lng": -77.0258,
    "state_id": "NY"
  },
  {
    "zip": 14533,
    "lat": 42.84444,
    "lng": -77.88555,
    "state_id": "NY"
  },
  {
    "zip": 14534,
    "lat": 43.05585,
    "lng": -77.52019,
    "state_id": "NY"
  },
  {
    "zip": 14536,
    "lat": 42.54192,
    "lng": -78.0833,
    "state_id": "NY"
  },
  {
    "zip": 14537,
    "lat": 43.03584,
    "lng": -77.16341,
    "state_id": "NY"
  },
  {
    "zip": 14539,
    "lat": 42.83458,
    "lng": -77.87489,
    "state_id": "NY"
  },
  {
    "zip": 14541,
    "lat": 42.75657,
    "lng": -76.84444,
    "state_id": "NY"
  },
  {
    "zip": 14542,
    "lat": 43.14733,
    "lng": -76.86014,
    "state_id": "NY"
  },
  {
    "zip": 14543,
    "lat": 42.98756,
    "lng": -77.6774,
    "state_id": "NY"
  },
  {
    "zip": 14544,
    "lat": 42.75658,
    "lng": -77.24347,
    "state_id": "NY"
  },
  {
    "zip": 14545,
    "lat": 42.66491,
    "lng": -77.70101,
    "state_id": "NY"
  },
  {
    "zip": 14546,
    "lat": 43.031,
    "lng": -77.77345,
    "state_id": "NY"
  },
  {
    "zip": 14548,
    "lat": 42.97605,
    "lng": -77.24386,
    "state_id": "NY"
  },
  {
    "zip": 14549,
    "lat": 42.70004,
    "lng": -78.01723,
    "state_id": "NY"
  },
  {
    "zip": 14550,
    "lat": 42.67783,
    "lng": -78.09104,
    "state_id": "NY"
  },
  {
    "zip": 14551,
    "lat": 43.21915,
    "lng": -77.04497,
    "state_id": "NY"
  },
  {
    "zip": 14555,
    "lat": 43.25922,
    "lng": -76.97814,
    "state_id": "NY"
  },
  {
    "zip": 14559,
    "lat": 43.18805,
    "lng": -77.81798,
    "state_id": "NY"
  },
  {
    "zip": 14560,
    "lat": 42.68378,
    "lng": -77.57082,
    "state_id": "NY"
  },
  {
    "zip": 14561,
    "lat": 42.82046,
    "lng": -77.13175,
    "state_id": "NY"
  },
  {
    "zip": 14564,
    "lat": 42.98295,
    "lng": -77.42408,
    "state_id": "NY"
  },
  {
    "zip": 14568,
    "lat": 43.14723,
    "lng": -77.28382,
    "state_id": "NY"
  },
  {
    "zip": 14569,
    "lat": 42.73931,
    "lng": -78.17138,
    "state_id": "NY"
  },
  {
    "zip": 14571,
    "lat": 43.34256,
    "lng": -78.25309,
    "state_id": "NY"
  },
  {
    "zip": 14572,
    "lat": 42.56536,
    "lng": -77.571,
    "state_id": "NY"
  },
  {
    "zip": 14580,
    "lat": 43.2193,
    "lng": -77.44551,
    "state_id": "NY"
  },
  {
    "zip": 14585,
    "lat": 42.90629,
    "lng": -77.55337,
    "state_id": "NY"
  },
  {
    "zip": 14586,
    "lat": 43.0418,
    "lng": -77.68688,
    "state_id": "NY"
  },
  {
    "zip": 14588,
    "lat": 42.68099,
    "lng": -76.87062,
    "state_id": "NY"
  },
  {
    "zip": 14589,
    "lat": 43.24008,
    "lng": -77.17253,
    "state_id": "NY"
  },
  {
    "zip": 14590,
    "lat": 43.24228,
    "lng": -76.82725,
    "state_id": "NY"
  },
  {
    "zip": 14591,
    "lat": 42.83196,
    "lng": -78.09835,
    "state_id": "NY"
  },
  {
    "zip": 14592,
    "lat": 42.87217,
    "lng": -77.89574,
    "state_id": "NY"
  },
  {
    "zip": 14604,
    "lat": 43.15678,
    "lng": -77.60479,
    "state_id": "NY"
  },
  {
    "zip": 14605,
    "lat": 43.1683,
    "lng": -77.60203,
    "state_id": "NY"
  },
  {
    "zip": 14606,
    "lat": 43.17128,
    "lng": -77.69857,
    "state_id": "NY"
  },
  {
    "zip": 14607,
    "lat": 43.15081,
    "lng": -77.58644,
    "state_id": "NY"
  },
  {
    "zip": 14608,
    "lat": 43.1536,
    "lng": -77.6238,
    "state_id": "NY"
  },
  {
    "zip": 14609,
    "lat": 43.17753,
    "lng": -77.55277,
    "state_id": "NY"
  },
  {
    "zip": 14610,
    "lat": 43.14258,
    "lng": -77.54548,
    "state_id": "NY"
  },
  {
    "zip": 14611,
    "lat": 43.14749,
    "lng": -77.64714,
    "state_id": "NY"
  },
  {
    "zip": 14612,
    "lat": 43.26574,
    "lng": -77.67613,
    "state_id": "NY"
  },
  {
    "zip": 14613,
    "lat": 43.18222,
    "lng": -77.6404,
    "state_id": "NY"
  },
  {
    "zip": 14614,
    "lat": 43.15754,
    "lng": -77.61503,
    "state_id": "NY"
  },
  {
    "zip": 14615,
    "lat": 43.20342,
    "lng": -77.65596,
    "state_id": "NY"
  },
  {
    "zip": 14616,
    "lat": 43.23464,
    "lng": -77.65755,
    "state_id": "NY"
  },
  {
    "zip": 14617,
    "lat": 43.22509,
    "lng": -77.59363,
    "state_id": "NY"
  },
  {
    "zip": 14618,
    "lat": 43.113,
    "lng": -77.55536,
    "state_id": "NY"
  },
  {
    "zip": 14619,
    "lat": 43.13615,
    "lng": -77.64897,
    "state_id": "NY"
  },
  {
    "zip": 14620,
    "lat": 43.12891,
    "lng": -77.60517,
    "state_id": "NY"
  },
  {
    "zip": 14621,
    "lat": 43.18971,
    "lng": -77.60374,
    "state_id": "NY"
  },
  {
    "zip": 14622,
    "lat": 43.21386,
    "lng": -77.55399,
    "state_id": "NY"
  },
  {
    "zip": 14623,
    "lat": 43.08846,
    "lng": -77.64239,
    "state_id": "NY"
  },
  {
    "zip": 14624,
    "lat": 43.12739,
    "lng": -77.7323,
    "state_id": "NY"
  },
  {
    "zip": 14625,
    "lat": 43.14899,
    "lng": -77.50534,
    "state_id": "NY"
  },
  {
    "zip": 14626,
    "lat": 43.2143,
    "lng": -77.71383,
    "state_id": "NY"
  },
  {
    "zip": 14627,
    "lat": 43.12861,
    "lng": -77.62917,
    "state_id": "NY"
  },
  {
    "zip": 14701,
    "lat": 42.0788,
    "lng": -79.25735,
    "state_id": "NY"
  },
  {
    "zip": 14706,
    "lat": 42.11331,
    "lng": -78.52578,
    "state_id": "NY"
  },
  {
    "zip": 14707,
    "lat": 42.07738,
    "lng": -78.0605,
    "state_id": "NY"
  },
  {
    "zip": 14708,
    "lat": 42.01639,
    "lng": -78.06417,
    "state_id": "NY"
  },
  {
    "zip": 14709,
    "lat": 42.35235,
    "lng": -77.98556,
    "state_id": "NY"
  },
  {
    "zip": 14710,
    "lat": 42.09033,
    "lng": -79.42211,
    "state_id": "NY"
  },
  {
    "zip": 14711,
    "lat": 42.32604,
    "lng": -78.12146,
    "state_id": "NY"
  },
  {
    "zip": 14712,
    "lat": 42.18102,
    "lng": -79.36726,
    "state_id": "NY"
  },
  {
    "zip": 14714,
    "lat": 42.28999,
    "lng": -78.23324,
    "state_id": "NY"
  },
  {
    "zip": 14715,
    "lat": 42.0676,
    "lng": -78.14919,
    "state_id": "NY"
  },
  {
    "zip": 14716,
    "lat": 42.3788,
    "lng": -79.43513,
    "state_id": "NY"
  },
  {
    "zip": 14717,
    "lat": 42.364,
    "lng": -78.18399,
    "state_id": "NY"
  },
  {
    "zip": 14718,
    "lat": 42.34481,
    "lng": -79.28727,
    "state_id": "NY"
  },
  {
    "zip": 14719,
    "lat": 42.33848,
    "lng": -78.87923,
    "state_id": "NY"
  },
  {
    "zip": 14720,
    "lat": 42.10857,
    "lng": -79.28113,
    "state_id": "NY"
  },
  {
    "zip": 14721,
    "lat": 42.01386,
    "lng": -78.26648,
    "state_id": "NY"
  },
  {
    "zip": 14722,
    "lat": 42.20939,
    "lng": -79.46821,
    "state_id": "NY"
  },
  {
    "zip": 14723,
    "lat": 42.3104,
    "lng": -79.13985,
    "state_id": "NY"
  },
  {
    "zip": 14724,
    "lat": 42.05281,
    "lng": -79.6687,
    "state_id": "NY"
  },
  {
    "zip": 14726,
    "lat": 42.25158,
    "lng": -79.02122,
    "state_id": "NY"
  },
  {
    "zip": 14727,
    "lat": 42.20785,
    "lng": -78.29184,
    "state_id": "NY"
  },
  {
    "zip": 14728,
    "lat": 42.26302,
    "lng": -79.41968,
    "state_id": "NY"
  },
  {
    "zip": 14729,
    "lat": 42.4035,
    "lng": -78.7434,
    "state_id": "NY"
  },
  {
    "zip": 14731,
    "lat": 42.3005,
    "lng": -78.65037,
    "state_id": "NY"
  },
  {
    "zip": 14732,
    "lat": 42.2319,
    "lng": -79.11636,
    "state_id": "NY"
  },
  {
    "zip": 14733,
    "lat": 42.14916,
    "lng": -79.17462,
    "state_id": "NY"
  },
  {
    "zip": 14735,
    "lat": 42.45147,
    "lng": -78.09768,
    "state_id": "NY"
  },
  {
    "zip": 14736,
    "lat": 42.14089,
    "lng": -79.74611,
    "state_id": "NY"
  },
  {
    "zip": 14737,
    "lat": 42.33512,
    "lng": -78.43048,
    "state_id": "NY"
  },
  {
    "zip": 14738,
    "lat": 42.04548,
    "lng": -79.06035,
    "state_id": "NY"
  },
  {
    "zip": 14739,
    "lat": 42.19164,
    "lng": -78.14761,
    "state_id": "NY"
  },
  {
    "zip": 14740,
    "lat": 42.21957,
    "lng": -79.18406,
    "state_id": "NY"
  },
  {
    "zip": 14741,
    "lat": 42.21772,
    "lng": -78.59825,
    "state_id": "NY"
  },
  {
    "zip": 14742,
    "lat": 42.12015,
    "lng": -79.30688,
    "state_id": "NY"
  },
  {
    "zip": 14743,
    "lat": 42.20296,
    "lng": -78.40516,
    "state_id": "NY"
  },
  {
    "zip": 14744,
    "lat": 42.42232,
    "lng": -78.21068,
    "state_id": "NY"
  },
  {
    "zip": 14747,
    "lat": 42.15401,
    "lng": -79.09133,
    "state_id": "NY"
  },
  {
    "zip": 14748,
    "lat": 42.13712,
    "lng": -78.63735,
    "state_id": "NY"
  },
  {
    "zip": 14750,
    "lat": 42.07978,
    "lng": -79.32928,
    "state_id": "NY"
  },
  {
    "zip": 14752,
    "lat": 42.35173,
    "lng": -79.31982,
    "state_id": "NY"
  },
  {
    "zip": 14753,
    "lat": 42.05049,
    "lng": -78.64552,
    "state_id": "NY"
  },
  {
    "zip": 14754,
    "lat": 42.02571,
    "lng": -78.2059,
    "state_id": "NY"
  },
  {
    "zip": 14755,
    "lat": 42.23644,
    "lng": -78.81303,
    "state_id": "NY"
  },
  {
    "zip": 14756,
    "lat": 42.19788,
    "lng": -79.42123,
    "state_id": "NY"
  },
  {
    "zip": 14757,
    "lat": 42.23673,
    "lng": -79.50416,
    "state_id": "NY"
  },
  {
    "zip": 14760,
    "lat": 42.07338,
    "lng": -78.41388,
    "state_id": "NY"
  },
  {
    "zip": 14767,
    "lat": 42.05172,
    "lng": -79.51133,
    "state_id": "NY"
  },
  {
    "zip": 14769,
    "lat": 42.36811,
    "lng": -79.47649,
    "state_id": "NY"
  },
  {
    "zip": 14770,
    "lat": 42.04244,
    "lng": -78.30415,
    "state_id": "NY"
  },
  {
    "zip": 14772,
    "lat": 42.155,
    "lng": -78.95337,
    "state_id": "NY"
  },
  {
    "zip": 14774,
    "lat": 42.08839,
    "lng": -78.14914,
    "state_id": "NY"
  },
  {
    "zip": 14775,
    "lat": 42.2322,
    "lng": -79.69519,
    "state_id": "NY"
  },
  {
    "zip": 14777,
    "lat": 42.39179,
    "lng": -78.2615,
    "state_id": "NY"
  },
  {
    "zip": 14778,
    "lat": 42.07758,
    "lng": -78.48324,
    "state_id": "NY"
  },
  {
    "zip": 14779,
    "lat": 42.10645,
    "lng": -78.77776,
    "state_id": "NY"
  },
  {
    "zip": 14781,
    "lat": 42.16117,
    "lng": -79.60186,
    "state_id": "NY"
  },
  {
    "zip": 14782,
    "lat": 42.26661,
    "lng": -79.26045,
    "state_id": "NY"
  },
  {
    "zip": 14783,
    "lat": 42.07798,
    "lng": -78.88412,
    "state_id": "NY"
  },
  {
    "zip": 14784,
    "lat": 42.3153,
    "lng": -79.38065,
    "state_id": "NY"
  },
  {
    "zip": 14787,
    "lat": 42.31124,
    "lng": -79.57054,
    "state_id": "NY"
  },
  {
    "zip": 14788,
    "lat": 42.06205,
    "lng": -78.38018,
    "state_id": "NY"
  },
  {
    "zip": 14801,
    "lat": 42.09688,
    "lng": -77.28862,
    "state_id": "NY"
  },
  {
    "zip": 14802,
    "lat": 42.25326,
    "lng": -77.78926,
    "state_id": "NY"
  },
  {
    "zip": 14803,
    "lat": 42.2543,
    "lng": -77.78687,
    "state_id": "NY"
  },
  {
    "zip": 14804,
    "lat": 42.31717,
    "lng": -77.84886,
    "state_id": "NY"
  },
  {
    "zip": 14805,
    "lat": 42.35884,
    "lng": -76.72417,
    "state_id": "NY"
  },
  {
    "zip": 14806,
    "lat": 42.15996,
    "lng": -77.77932,
    "state_id": "NY"
  },
  {
    "zip": 14807,
    "lat": 42.41922,
    "lng": -77.71193,
    "state_id": "NY"
  },
  {
    "zip": 14808,
    "lat": 42.55966,
    "lng": -77.46691,
    "state_id": "NY"
  },
  {
    "zip": 14809,
    "lat": 42.41969,
    "lng": -77.44608,
    "state_id": "NY"
  },
  {
    "zip": 14810,
    "lat": 42.3487,
    "lng": -77.34272,
    "state_id": "NY"
  },
  {
    "zip": 14812,
    "lat": 42.29326,
    "lng": -76.99553,
    "state_id": "NY"
  },
  {
    "zip": 14813,
    "lat": 42.24794,
    "lng": -77.99107,
    "state_id": "NY"
  },
  {
    "zip": 14814,
    "lat": 42.15003,
    "lng": -76.95274,
    "state_id": "NY"
  },
  {
    "zip": 14815,
    "lat": 42.37458,
    "lng": -77.09279,
    "state_id": "NY"
  },
  {
    "zip": 14816,
    "lat": 42.19533,
    "lng": -76.73129,
    "state_id": "NY"
  },
  {
    "zip": 14817,
    "lat": 42.36488,
    "lng": -76.34272,
    "state_id": "NY"
  },
  {
    "zip": 14818,
    "lat": 42.44809,
    "lng": -76.81885,
    "state_id": "NY"
  },
  {
    "zip": 14819,
    "lat": 42.22047,
    "lng": -77.43861,
    "state_id": "NY"
  },
  {
    "zip": 14820,
    "lat": 42.19226,
    "lng": -77.35933,
    "state_id": "NY"
  },
  {
    "zip": 14821,
    "lat": 42.23413,
    "lng": -77.21433,
    "state_id": "NY"
  },
  {
    "zip": 14822,
    "lat": 42.43085,
    "lng": -77.84964,
    "state_id": "NY"
  },
  {
    "zip": 14823,
    "lat": 42.24857,
    "lng": -77.55377,
    "state_id": "NY"
  },
  {
    "zip": 14824,
    "lat": 42.26709,
    "lng": -76.70087,
    "state_id": "NY"
  },
  {
    "zip": 14825,
    "lat": 42.05508,
    "lng": -76.62115,
    "state_id": "NY"
  },
  {
    "zip": 14826,
    "lat": 42.49153,
    "lng": -77.49693,
    "state_id": "NY"
  },
  {
    "zip": 14827,
    "lat": 42.18065,
    "lng": -77.14283,
    "state_id": "NY"
  },
  {
    "zip": 14830,
    "lat": 42.12985,
    "lng": -77.03315,
    "state_id": "NY"
  },
  {
    "zip": 14836,
    "lat": 42.5163,
    "lng": -77.9178,
    "state_id": "NY"
  },
  {
    "zip": 14837,
    "lat": 42.49726,
    "lng": -77.01723,
    "state_id": "NY"
  },
  {
    "zip": 14838,
    "lat": 42.18734,
    "lng": -76.66605,
    "state_id": "NY"
  },
  {
    "zip": 14839,
    "lat": 42.13945,
    "lng": -77.64475,
    "state_id": "NY"
  },
  {
    "zip": 14840,
    "lat": 42.44214,
    "lng": -77.18832,
    "state_id": "NY"
  },
  {
    "zip": 14841,
    "lat": 42.52534,
    "lng": -76.84091,
    "state_id": "NY"
  },
  {
    "zip": 14842,
    "lat": 42.59462,
    "lng": -76.96503,
    "state_id": "NY"
  },
  {
    "zip": 14843,
    "lat": 42.31834,
    "lng": -77.64549,
    "state_id": "NY"
  },
  {
    "zip": 14845,
    "lat": 42.20497,
    "lng": -76.83565,
    "state_id": "NY"
  },
  {
    "zip": 14846,
    "lat": 42.53471,
    "lng": -78.00099,
    "state_id": "NY"
  },
  {
    "zip": 14847,
    "lat": 42.60396,
    "lng": -76.73981,
    "state_id": "NY"
  },
  {
    "zip": 14850,
    "lat": 42.43101,
    "lng": -76.49959,
    "state_id": "NY"
  },
  {
    "zip": 14853,
    "lat": 42.44773,
    "lng": -76.47959,
    "state_id": "NY"
  },
  {
    "zip": 14854,
    "lat": 42.50574,
    "lng": -76.61477,
    "state_id": "NY"
  },
  {
    "zip": 14855,
    "lat": 42.14206,
    "lng": -77.50282,
    "state_id": "NY"
  },
  {
    "zip": 14856,
    "lat": 42.3767,
    "lng": -77.36651,
    "state_id": "NY"
  },
  {
    "zip": 14858,
    "lat": 42.02999,
    "lng": -77.13565,
    "state_id": "NY"
  },
  {
    "zip": 14859,
    "lat": 42.11991,
    "lng": -76.53526,
    "state_id": "NY"
  },
  {
    "zip": 14860,
    "lat": 42.59358,
    "lng": -76.83621,
    "state_id": "NY"
  },
  {
    "zip": 14861,
    "lat": 42.08993,
    "lng": -76.68477,
    "state_id": "NY"
  },
  {
    "zip": 14864,
    "lat": 42.27828,
    "lng": -76.84167,
    "state_id": "NY"
  },
  {
    "zip": 14865,
    "lat": 42.34857,
    "lng": -76.83399,
    "state_id": "NY"
  },
  {
    "zip": 14867,
    "lat": 42.34405,
    "lng": -76.61415,
    "state_id": "NY"
  },
  {
    "zip": 14869,
    "lat": 42.36507,
    "lng": -76.77186,
    "state_id": "NY"
  },
  {
    "zip": 14870,
    "lat": 42.16768,
    "lng": -77.13458,
    "state_id": "NY"
  },
  {
    "zip": 14871,
    "lat": 42.04026,
    "lng": -76.91143,
    "state_id": "NY"
  },
  {
    "zip": 14872,
    "lat": 42.24239,
    "lng": -76.8678,
    "state_id": "NY"
  },
  {
    "zip": 14873,
    "lat": 42.52238,
    "lng": -77.29841,
    "state_id": "NY"
  },
  {
    "zip": 14874,
    "lat": 42.53016,
    "lng": -77.17551,
    "state_id": "NY"
  },
  {
    "zip": 14877,
    "lat": 42.05664,
    "lng": -77.6859,
    "state_id": "NY"
  },
  {
    "zip": 14878,
    "lat": 42.45028,
    "lng": -76.94341,
    "state_id": "NY"
  },
  {
    "zip": 14879,
    "lat": 42.31621,
    "lng": -77.19003,
    "state_id": "NY"
  },
  {
    "zip": 14880,
    "lat": 42.17744,
    "lng": -77.97066,
    "state_id": "NY"
  },
  {
    "zip": 14881,
    "lat": 42.40056,
    "lng": -76.35671,
    "state_id": "NY"
  },
  {
    "zip": 14882,
    "lat": 42.58016,
    "lng": -76.55496,
    "state_id": "NY"
  },
  {
    "zip": 14883,
    "lat": 42.23929,
    "lng": -76.4812,
    "state_id": "NY"
  },
  {
    "zip": 14884,
    "lat": 42.48015,
    "lng": -77.8885,
    "state_id": "NY"
  },
  {
    "zip": 14885,
    "lat": 42.04656,
    "lng": -77.57035,
    "state_id": "NY"
  },
  {
    "zip": 14886,
    "lat": 42.50471,
    "lng": -76.68977,
    "state_id": "NY"
  },
  {
    "zip": 14889,
    "lat": 42.21521,
    "lng": -76.58135,
    "state_id": "NY"
  },
  {
    "zip": 14891,
    "lat": 42.37724,
    "lng": -76.94575,
    "state_id": "NY"
  },
  {
    "zip": 14892,
    "lat": 42.04832,
    "lng": -76.52704,
    "state_id": "NY"
  },
  {
    "zip": 14893,
    "lat": 42.46781,
    "lng": -77.10772,
    "state_id": "NY"
  },
  {
    "zip": 14894,
    "lat": 42.02236,
    "lng": -76.769,
    "state_id": "NY"
  },
  {
    "zip": 14895,
    "lat": 42.07697,
    "lng": -77.93709,
    "state_id": "NY"
  },
  {
    "zip": 14897,
    "lat": 42.02921,
    "lng": -77.79237,
    "state_id": "NY"
  },
  {
    "zip": 14898,
    "lat": 42.05701,
    "lng": -77.43902,
    "state_id": "NY"
  },
  {
    "zip": 14901,
    "lat": 42.08198,
    "lng": -76.74428,
    "state_id": "NY"
  },
  {
    "zip": 14903,
    "lat": 42.12526,
    "lng": -76.87563,
    "state_id": "NY"
  },
  {
    "zip": 14904,
    "lat": 42.06982,
    "lng": -76.79987,
    "state_id": "NY"
  },
  {
    "zip": 14905,
    "lat": 42.0883,
    "lng": -76.84392,
    "state_id": "NY"
  },
  {
    "zip": 43001,
    "lat": 40.08865,
    "lng": -82.61366,
    "state_id": "OH"
  },
  {
    "zip": 43002,
    "lat": 40.06333,
    "lng": -83.1739,
    "state_id": "OH"
  },
  {
    "zip": 43003,
    "lat": 40.41034,
    "lng": -82.97538,
    "state_id": "OH"
  },
  {
    "zip": 43004,
    "lat": 40.01581,
    "lng": -82.80012,
    "state_id": "OH"
  },
  {
    "zip": 43005,
    "lat": 40.28618,
    "lng": -82.27706,
    "state_id": "OH"
  },
  {
    "zip": 43006,
    "lat": 40.45874,
    "lng": -82.14575,
    "state_id": "OH"
  },
  {
    "zip": 43008,
    "lat": 39.93381,
    "lng": -82.48137,
    "state_id": "OH"
  },
  {
    "zip": 43009,
    "lat": 40.17253,
    "lng": -83.64152,
    "state_id": "OH"
  },
  {
    "zip": 43010,
    "lat": 39.99965,
    "lng": -83.62186,
    "state_id": "OH"
  },
  {
    "zip": 43011,
    "lat": 40.31046,
    "lng": -82.68616,
    "state_id": "OH"
  },
  {
    "zip": 43013,
    "lat": 40.2322,
    "lng": -82.68766,
    "state_id": "OH"
  },
  {
    "zip": 43014,
    "lat": 40.47131,
    "lng": -82.2564,
    "state_id": "OH"
  },
  {
    "zip": 43015,
    "lat": 40.2968,
    "lng": -83.06065,
    "state_id": "OH"
  },
  {
    "zip": 43016,
    "lat": 40.09861,
    "lng": -83.15376,
    "state_id": "OH"
  },
  {
    "zip": 43017,
    "lat": 40.11682,
    "lng": -83.1301,
    "state_id": "OH"
  },
  {
    "zip": 43019,
    "lat": 40.49344,
    "lng": -82.57643,
    "state_id": "OH"
  },
  {
    "zip": 43021,
    "lat": 40.20095,
    "lng": -82.88428,
    "state_id": "OH"
  },
  {
    "zip": 43022,
    "lat": 40.33833,
    "lng": -82.33968,
    "state_id": "OH"
  },
  {
    "zip": 43023,
    "lat": 40.07526,
    "lng": -82.53181,
    "state_id": "OH"
  },
  {
    "zip": 43025,
    "lat": 39.96634,
    "lng": -82.51676,
    "state_id": "OH"
  },
  {
    "zip": 43026,
    "lat": 40.02276,
    "lng": -83.1855,
    "state_id": "OH"
  },
  {
    "zip": 43028,
    "lat": 40.40033,
    "lng": -82.29352,
    "state_id": "OH"
  },
  {
    "zip": 43029,
    "lat": 40.09509,
    "lng": -83.45856,
    "state_id": "OH"
  },
  {
    "zip": 43030,
    "lat": 39.9615,
    "lng": -82.41584,
    "state_id": "OH"
  },
  {
    "zip": 43031,
    "lat": 40.16102,
    "lng": -82.66624,
    "state_id": "OH"
  },
  {
    "zip": 43032,
    "lat": 40.32822,
    "lng": -82.95917,
    "state_id": "OH"
  },
  {
    "zip": 43033,
    "lat": 39.96093,
    "lng": -82.60006,
    "state_id": "OH"
  },
  {
    "zip": 43035,
    "lat": 40.18911,
    "lng": -82.99512,
    "state_id": "OH"
  },
  {
    "zip": 43036,
    "lat": 40.35232,
    "lng": -83.26215,
    "state_id": "OH"
  },
  {
    "zip": 43037,
    "lat": 40.27801,
    "lng": -82.3258,
    "state_id": "OH"
  },
  {
    "zip": 43040,
    "lat": 40.26095,
    "lng": -83.35954,
    "state_id": "OH"
  },
  {
    "zip": 43044,
    "lat": 40.05024,
    "lng": -83.56425,
    "state_id": "OH"
  },
  {
    "zip": 43045,
    "lat": 40.16987,
    "lng": -83.4486,
    "state_id": "OH"
  },
  {
    "zip": 43046,
    "lat": 39.89864,
    "lng": -82.54118,
    "state_id": "OH"
  },
  {
    "zip": 43050,
    "lat": 40.37231,
    "lng": -82.49559,
    "state_id": "OH"
  },
  {
    "zip": 43054,
    "lat": 40.08085,
    "lng": -82.80342,
    "state_id": "OH"
  },
  {
    "zip": 43055,
    "lat": 40.11783,
    "lng": -82.37652,
    "state_id": "OH"
  },
  {
    "zip": 43056,
    "lat": 40.01136,
    "lng": -82.33505,
    "state_id": "OH"
  },
  {
    "zip": 43060,
    "lat": 40.21996,
    "lng": -83.57248,
    "state_id": "OH"
  },
  {
    "zip": 43061,
    "lat": 40.28661,
    "lng": -83.20324,
    "state_id": "OH"
  },
  {
    "zip": 43062,
    "lat": 40.00752,
    "lng": -82.68279,
    "state_id": "OH"
  },
  {
    "zip": 43064,
    "lat": 40.09804,
    "lng": -83.28777,
    "state_id": "OH"
  },
  {
    "zip": 43065,
    "lat": 40.17762,
    "lng": -83.09366,
    "state_id": "OH"
  },
  {
    "zip": 43066,
    "lat": 40.3977,
    "lng": -83.16451,
    "state_id": "OH"
  },
  {
    "zip": 43067,
    "lat": 40.34577,
    "lng": -83.47083,
    "state_id": "OH"
  },
  {
    "zip": 43068,
    "lat": 39.95635,
    "lng": -82.78506,
    "state_id": "OH"
  },
  {
    "zip": 43070,
    "lat": 40.21577,
    "lng": -83.95792,
    "state_id": "OH"
  },
  {
    "zip": 43071,
    "lat": 40.18424,
    "lng": -82.35821,
    "state_id": "OH"
  },
  {
    "zip": 43072,
    "lat": 40.11638,
    "lng": -83.95628,
    "state_id": "OH"
  },
  {
    "zip": 43074,
    "lat": 40.27834,
    "lng": -82.84958,
    "state_id": "OH"
  },
  {
    "zip": 43076,
    "lat": 39.89645,
    "lng": -82.39754,
    "state_id": "OH"
  },
  {
    "zip": 43077,
    "lat": 40.13664,
    "lng": -83.34074,
    "state_id": "OH"
  },
  {
    "zip": 43078,
    "lat": 40.11282,
    "lng": -83.7764,
    "state_id": "OH"
  },
  {
    "zip": 43080,
    "lat": 40.24273,
    "lng": -82.42906,
    "state_id": "OH"
  },
  {
    "zip": 43081,
    "lat": 40.11064,
    "lng": -82.89134,
    "state_id": "OH"
  },
  {
    "zip": 43082,
    "lat": 40.15243,
    "lng": -82.88199,
    "state_id": "OH"
  },
  {
    "zip": 43084,
    "lat": 40.15683,
    "lng": -83.54083,
    "state_id": "OH"
  },
  {
    "zip": 43085,
    "lat": 40.09986,
    "lng": -83.01569,
    "state_id": "OH"
  },
  {
    "zip": 43101,
    "lat": 39.46464,
    "lng": -82.74563,
    "state_id": "OH"
  },
  {
    "zip": 43102,
    "lat": 39.63957,
    "lng": -82.763,
    "state_id": "OH"
  },
  {
    "zip": 43103,
    "lat": 39.7257,
    "lng": -82.93543,
    "state_id": "OH"
  },
  {
    "zip": 43105,
    "lat": 39.86357,
    "lng": -82.61664,
    "state_id": "OH"
  },
  {
    "zip": 43106,
    "lat": 39.64231,
    "lng": -83.42115,
    "state_id": "OH"
  },
  {
    "zip": 43107,
    "lat": 39.69084,
    "lng": -82.40959,
    "state_id": "OH"
  },
  {
    "zip": 43109,
    "lat": 39.91257,
    "lng": -82.8337,
    "state_id": "OH"
  },
  {
    "zip": 43110,
    "lat": 39.82635,
    "lng": -82.79947,
    "state_id": "OH"
  },
  {
    "zip": 43111,
    "lat": 39.50207,
    "lng": -82.24238,
    "state_id": "OH"
  },
  {
    "zip": 43112,
    "lat": 39.79671,
    "lng": -82.70358,
    "state_id": "OH"
  },
  {
    "zip": 43113,
    "lat": 39.59091,
    "lng": -82.96316,
    "state_id": "OH"
  },
  {
    "zip": 43115,
    "lat": 39.49147,
    "lng": -83.16271,
    "state_id": "OH"
  },
  {
    "zip": 43116,
    "lat": 39.77156,
    "lng": -83.06145,
    "state_id": "OH"
  },
  {
    "zip": 43117,
    "lat": 39.76879,
    "lng": -83.20612,
    "state_id": "OH"
  },
  {
    "zip": 43119,
    "lat": 39.93574,
    "lng": -83.20074,
    "state_id": "OH"
  },
  {
    "zip": 43123,
    "lat": 39.86855,
    "lng": -83.11985,
    "state_id": "OH"
  },
  {
    "zip": 43125,
    "lat": 39.83664,
    "lng": -82.88449,
    "state_id": "OH"
  },
  {
    "zip": 43126,
    "lat": 39.81391,
    "lng": -83.16648,
    "state_id": "OH"
  },
  {
    "zip": 43127,
    "lat": 39.48052,
    "lng": -82.32422,
    "state_id": "OH"
  },
  {
    "zip": 43128,
    "lat": 39.66406,
    "lng": -83.57754,
    "state_id": "OH"
  },
  {
    "zip": 43130,
    "lat": 39.69883,
    "lng": -82.6105,
    "state_id": "OH"
  },
  {
    "zip": 43135,
    "lat": 39.46644,
    "lng": -82.69757,
    "state_id": "OH"
  },
  {
    "zip": 43136,
    "lat": 39.80136,
    "lng": -82.81252,
    "state_id": "OH"
  },
  {
    "zip": 43137,
    "lat": 39.80819,
    "lng": -82.98549,
    "state_id": "OH"
  },
  {
    "zip": 43138,
    "lat": 39.52202,
    "lng": -82.41098,
    "state_id": "OH"
  },
  {
    "zip": 43140,
    "lat": 39.88299,
    "lng": -83.42997,
    "state_id": "OH"
  },
  {
    "zip": 43142,
    "lat": 39.5934,
    "lng": -83.58742,
    "state_id": "OH"
  },
  {
    "zip": 43143,
    "lat": 39.71673,
    "lng": -83.29396,
    "state_id": "OH"
  },
  {
    "zip": 43144,
    "lat": 39.52851,
    "lng": -82.19212,
    "state_id": "OH"
  },
  {
    "zip": 43145,
    "lat": 39.56536,
    "lng": -83.25846,
    "state_id": "OH"
  },
  {
    "zip": 43146,
    "lat": 39.77435,
    "lng": -83.14167,
    "state_id": "OH"
  },
  {
    "zip": 43147,
    "lat": 39.89927,
    "lng": -82.74211,
    "state_id": "OH"
  },
  {
    "zip": 43148,
    "lat": 39.81669,
    "lng": -82.4995,
    "state_id": "OH"
  },
  {
    "zip": 43149,
    "lat": 39.53834,
    "lng": -82.57212,
    "state_id": "OH"
  },
  {
    "zip": 43150,
    "lat": 39.77327,
    "lng": -82.40933,
    "state_id": "OH"
  },
  {
    "zip": 43151,
    "lat": 39.73291,
    "lng": -83.47637,
    "state_id": "OH"
  },
  {
    "zip": 43152,
    "lat": 39.39444,
    "lng": -82.61485,
    "state_id": "OH"
  },
  {
    "zip": 43153,
    "lat": 39.73971,
    "lng": -83.57006,
    "state_id": "OH"
  },
  {
    "zip": 43154,
    "lat": 39.60079,
    "lng": -82.82877,
    "state_id": "OH"
  },
  {
    "zip": 43155,
    "lat": 39.62612,
    "lng": -82.5249,
    "state_id": "OH"
  },
  {
    "zip": 43156,
    "lat": 39.55382,
    "lng": -82.77628,
    "state_id": "OH"
  },
  {
    "zip": 43157,
    "lat": 39.84266,
    "lng": -82.54613,
    "state_id": "OH"
  },
  {
    "zip": 43158,
    "lat": 39.44918,
    "lng": -82.35762,
    "state_id": "OH"
  },
  {
    "zip": 43160,
    "lat": 39.52651,
    "lng": -83.44268,
    "state_id": "OH"
  },
  {
    "zip": 43162,
    "lat": 39.94338,
    "lng": -83.30453,
    "state_id": "OH"
  },
  {
    "zip": 43164,
    "lat": 39.58555,
    "lng": -83.11846,
    "state_id": "OH"
  },
  {
    "zip": 43201,
    "lat": 39.99131,
    "lng": -83.00039,
    "state_id": "OH"
  },
  {
    "zip": 43202,
    "lat": 40.02144,
    "lng": -83.01416,
    "state_id": "OH"
  },
  {
    "zip": 43203,
    "lat": 39.9731,
    "lng": -82.96898,
    "state_id": "OH"
  },
  {
    "zip": 43204,
    "lat": 39.96136,
    "lng": -83.0816,
    "state_id": "OH"
  },
  {
    "zip": 43205,
    "lat": 39.95766,
    "lng": -82.96219,
    "state_id": "OH"
  },
  {
    "zip": 43206,
    "lat": 39.94222,
    "lng": -82.97372,
    "state_id": "OH"
  },
  {
    "zip": 43207,
    "lat": 39.89583,
    "lng": -82.9629,
    "state_id": "OH"
  },
  {
    "zip": 43209,
    "lat": 39.9539,
    "lng": -82.93011,
    "state_id": "OH"
  },
  {
    "zip": 43210,
    "lat": 40.00521,
    "lng": -83.02265,
    "state_id": "OH"
  },
  {
    "zip": 43211,
    "lat": 40.01175,
    "lng": -82.97234,
    "state_id": "OH"
  },
  {
    "zip": 43212,
    "lat": 39.98705,
    "lng": -83.04167,
    "state_id": "OH"
  },
  {
    "zip": 43213,
    "lat": 39.96894,
    "lng": -82.8675,
    "state_id": "OH"
  },
  {
    "zip": 43214,
    "lat": 40.05214,
    "lng": -83.01973,
    "state_id": "OH"
  },
  {
    "zip": 43215,
    "lat": 39.96635,
    "lng": -83.01282,
    "state_id": "OH"
  },
  {
    "zip": 43217,
    "lat": 39.81677,
    "lng": -82.93188,
    "state_id": "OH"
  },
  {
    "zip": 43219,
    "lat": 40.0065,
    "lng": -82.92123,
    "state_id": "OH"
  },
  {
    "zip": 43220,
    "lat": 40.04789,
    "lng": -83.07127,
    "state_id": "OH"
  },
  {
    "zip": 43221,
    "lat": 40.02259,
    "lng": -83.07756,
    "state_id": "OH"
  },
  {
    "zip": 43222,
    "lat": 39.9612,
    "lng": -83.03526,
    "state_id": "OH"
  },
  {
    "zip": 43223,
    "lat": 39.92831,
    "lng": -83.03511,
    "state_id": "OH"
  },
  {
    "zip": 43224,
    "lat": 40.04284,
    "lng": -82.96625,
    "state_id": "OH"
  },
  {
    "zip": 43227,
    "lat": 39.94485,
    "lng": -82.88937,
    "state_id": "OH"
  },
  {
    "zip": 43228,
    "lat": 39.96479,
    "lng": -83.126,
    "state_id": "OH"
  },
  {
    "zip": 43229,
    "lat": 40.08586,
    "lng": -82.97601,
    "state_id": "OH"
  },
  {
    "zip": 43230,
    "lat": 40.03563,
    "lng": -82.86985,
    "state_id": "OH"
  },
  {
    "zip": 43231,
    "lat": 40.07927,
    "lng": -82.93894,
    "state_id": "OH"
  },
  {
    "zip": 43232,
    "lat": 39.92216,
    "lng": -82.87022,
    "state_id": "OH"
  },
  {
    "zip": 43235,
    "lat": 40.10264,
    "lng": -83.0524,
    "state_id": "OH"
  },
  {
    "zip": 43240,
    "lat": 40.14545,
    "lng": -82.98172,
    "state_id": "OH"
  },
  {
    "zip": 43302,
    "lat": 40.5997,
    "lng": -83.1296,
    "state_id": "OH"
  },
  {
    "zip": 43310,
    "lat": 40.53492,
    "lng": -83.78126,
    "state_id": "OH"
  },
  {
    "zip": 43311,
    "lat": 40.36589,
    "lng": -83.75618,
    "state_id": "OH"
  },
  {
    "zip": 43314,
    "lat": 40.64914,
    "lng": -82.95943,
    "state_id": "OH"
  },
  {
    "zip": 43315,
    "lat": 40.48322,
    "lng": -82.87768,
    "state_id": "OH"
  },
  {
    "zip": 43316,
    "lat": 40.95416,
    "lng": -83.3808,
    "state_id": "OH"
  },
  {
    "zip": 43317,
    "lat": 40.47724,
    "lng": -82.68181,
    "state_id": "OH"
  },
  {
    "zip": 43318,
    "lat": 40.30922,
    "lng": -83.91275,
    "state_id": "OH"
  },
  {
    "zip": 43319,
    "lat": 40.30649,
    "lng": -83.57216,
    "state_id": "OH"
  },
  {
    "zip": 43320,
    "lat": 40.58855,
    "lng": -82.89804,
    "state_id": "OH"
  },
  {
    "zip": 43321,
    "lat": 40.45733,
    "lng": -82.8298,
    "state_id": "OH"
  },
  {
    "zip": 43322,
    "lat": 40.53226,
    "lng": -83.20788,
    "state_id": "OH"
  },
  {
    "zip": 43323,
    "lat": 40.73156,
    "lng": -83.25004,
    "state_id": "OH"
  },
  {
    "zip": 43324,
    "lat": 40.44635,
    "lng": -83.8106,
    "state_id": "OH"
  },
  {
    "zip": 43326,
    "lat": 40.63931,
    "lng": -83.61507,
    "state_id": "OH"
  },
  {
    "zip": 43330,
    "lat": 40.81377,
    "lng": -83.41937,
    "state_id": "OH"
  },
  {
    "zip": 43331,
    "lat": 40.51767,
    "lng": -83.91905,
    "state_id": "OH"
  },
  {
    "zip": 43332,
    "lat": 40.58671,
    "lng": -83.37789,
    "state_id": "OH"
  },
  {
    "zip": 43333,
    "lat": 40.43993,
    "lng": -83.93201,
    "state_id": "OH"
  },
  {
    "zip": 43334,
    "lat": 40.40049,
    "lng": -82.80419,
    "state_id": "OH"
  },
  {
    "zip": 43336,
    "lat": 40.29111,
    "lng": -83.58245,
    "state_id": "OH"
  },
  {
    "zip": 43337,
    "lat": 40.682,
    "lng": -83.24152,
    "state_id": "OH"
  },
  {
    "zip": 43338,
    "lat": 40.56049,
    "lng": -82.75947,
    "state_id": "OH"
  },
  {
    "zip": 43340,
    "lat": 40.53922,
    "lng": -83.47897,
    "state_id": "OH"
  },
  {
    "zip": 43341,
    "lat": 40.5964,
    "lng": -83.3153,
    "state_id": "OH"
  },
  {
    "zip": 43342,
    "lat": 40.4756,
    "lng": -83.1836,
    "state_id": "OH"
  },
  {
    "zip": 43343,
    "lat": 40.30908,
    "lng": -83.97749,
    "state_id": "OH"
  },
  {
    "zip": 43344,
    "lat": 40.4331,
    "lng": -83.33969,
    "state_id": "OH"
  },
  {
    "zip": 43345,
    "lat": 40.51123,
    "lng": -83.58376,
    "state_id": "OH"
  },
  {
    "zip": 43347,
    "lat": 40.47782,
    "lng": -83.65955,
    "state_id": "OH"
  },
  {
    "zip": 43348,
    "lat": 40.46752,
    "lng": -83.8869,
    "state_id": "OH"
  },
  {
    "zip": 43351,
    "lat": 40.82495,
    "lng": -83.30373,
    "state_id": "OH"
  },
  {
    "zip": 43356,
    "lat": 40.45816,
    "lng": -83.04718,
    "state_id": "OH"
  },
  {
    "zip": 43357,
    "lat": 40.25335,
    "lng": -83.74664,
    "state_id": "OH"
  },
  {
    "zip": 43358,
    "lat": 40.41959,
    "lng": -83.53257,
    "state_id": "OH"
  },
  {
    "zip": 43359,
    "lat": 40.87347,
    "lng": -83.45487,
    "state_id": "OH"
  },
  {
    "zip": 43360,
    "lat": 40.3311,
    "lng": -83.63126,
    "state_id": "OH"
  },
  {
    "zip": 43402,
    "lat": 41.40996,
    "lng": -83.6546,
    "state_id": "OH"
  },
  {
    "zip": 43403,
    "lat": 41.377,
    "lng": -83.63711,
    "state_id": "OH"
  },
  {
    "zip": 43406,
    "lat": 41.33618,
    "lng": -83.4363,
    "state_id": "OH"
  },
  {
    "zip": 43407,
    "lat": 41.27787,
    "lng": -83.2494,
    "state_id": "OH"
  },
  {
    "zip": 43408,
    "lat": 41.57185,
    "lng": -83.36346,
    "state_id": "OH"
  },
  {
    "zip": 43410,
    "lat": 41.307,
    "lng": -82.95954,
    "state_id": "OH"
  },
  {
    "zip": 43412,
    "lat": 41.627,
    "lng": -83.32311,
    "state_id": "OH"
  },
  {
    "zip": 43413,
    "lat": 41.2415,
    "lng": -83.65251,
    "state_id": "OH"
  },
  {
    "zip": 43416,
    "lat": 41.48353,
    "lng": -83.26937,
    "state_id": "OH"
  },
  {
    "zip": 43420,
    "lat": 41.35774,
    "lng": -83.11204,
    "state_id": "OH"
  },
  {
    "zip": 43430,
    "lat": 41.52466,
    "lng": -83.3701,
    "state_id": "OH"
  },
  {
    "zip": 43431,
    "lat": 41.3899,
    "lng": -83.33777,
    "state_id": "OH"
  },
  {
    "zip": 43432,
    "lat": 41.56308,
    "lng": -83.25214,
    "state_id": "OH"
  },
  {
    "zip": 43433,
    "lat": 41.50474,
    "lng": -82.88031,
    "state_id": "OH"
  },
  {
    "zip": 43434,
    "lat": 41.69299,
    "lng": -83.44471,
    "state_id": "OH"
  },
  {
    "zip": 43435,
    "lat": 41.32533,
    "lng": -83.31279,
    "state_id": "OH"
  },
  {
    "zip": 43437,
    "lat": 41.2537,
    "lng": -83.60238,
    "state_id": "OH"
  },
  {
    "zip": 43438,
    "lat": 41.60341,
    "lng": -82.70291,
    "state_id": "OH"
  },
  {
    "zip": 43439,
    "lat": 41.51768,
    "lng": -83.04128,
    "state_id": "OH"
  },
  {
    "zip": 43440,
    "lat": 41.52626,
    "lng": -82.78079,
    "state_id": "OH"
  },
  {
    "zip": 43442,
    "lat": 41.42695,
    "lng": -83.21537,
    "state_id": "OH"
  },
  {
    "zip": 43443,
    "lat": 41.46172,
    "lng": -83.4674,
    "state_id": "OH"
  },
  {
    "zip": 43445,
    "lat": 41.58485,
    "lng": -83.29854,
    "state_id": "OH"
  },
  {
    "zip": 43446,
    "lat": 41.68475,
    "lng": -82.80853,
    "state_id": "OH"
  },
  {
    "zip": 43447,
    "lat": 41.56604,
    "lng": -83.43547,
    "state_id": "OH"
  },
  {
    "zip": 43449,
    "lat": 41.53624,
    "lng": -83.14349,
    "state_id": "OH"
  },
  {
    "zip": 43450,
    "lat": 41.4007,
    "lng": -83.49214,
    "state_id": "OH"
  },
  {
    "zip": 43451,
    "lat": 41.31278,
    "lng": -83.6151,
    "state_id": "OH"
  },
  {
    "zip": 43452,
    "lat": 41.5151,
    "lng": -82.96687,
    "state_id": "OH"
  },
  {
    "zip": 43456,
    "lat": 41.66821,
    "lng": -82.8232,
    "state_id": "OH"
  },
  {
    "zip": 43457,
    "lat": 41.26732,
    "lng": -83.42749,
    "state_id": "OH"
  },
  {
    "zip": 43458,
    "lat": 41.53105,
    "lng": -83.21258,
    "state_id": "OH"
  },
  {
    "zip": 43460,
    "lat": 41.60286,
    "lng": -83.563,
    "state_id": "OH"
  },
  {
    "zip": 43462,
    "lat": 41.28356,
    "lng": -83.71868,
    "state_id": "OH"
  },
  {
    "zip": 43463,
    "lat": 41.50866,
    "lng": -83.50804,
    "state_id": "OH"
  },
  {
    "zip": 43464,
    "lat": 41.40348,
    "lng": -82.92993,
    "state_id": "OH"
  },
  {
    "zip": 43465,
    "lat": 41.57004,
    "lng": -83.50379,
    "state_id": "OH"
  },
  {
    "zip": 43466,
    "lat": 41.29535,
    "lng": -83.51482,
    "state_id": "OH"
  },
  {
    "zip": 43467,
    "lat": 41.24321,
    "lng": -83.48382,
    "state_id": "OH"
  },
  {
    "zip": 43468,
    "lat": 41.59788,
    "lng": -83.34147,
    "state_id": "OH"
  },
  {
    "zip": 43469,
    "lat": 41.45946,
    "lng": -83.36322,
    "state_id": "OH"
  },
  {
    "zip": 43501,
    "lat": 41.6725,
    "lng": -84.45726,
    "state_id": "OH"
  },
  {
    "zip": 43502,
    "lat": 41.53029,
    "lng": -84.29884,
    "state_id": "OH"
  },
  {
    "zip": 43504,
    "lat": 41.69585,
    "lng": -83.83676,
    "state_id": "OH"
  },
  {
    "zip": 43505,
    "lat": 41.52403,
    "lng": -84.73118,
    "state_id": "OH"
  },
  {
    "zip": 43506,
    "lat": 41.46629,
    "lng": -84.55621,
    "state_id": "OH"
  },
  {
    "zip": 43511,
    "lat": 41.26339,
    "lng": -83.82366,
    "state_id": "OH"
  },
  {
    "zip": 43512,
    "lat": 41.30271,
    "lng": -84.35806,
    "state_id": "OH"
  },
  {
    "zip": 43515,
    "lat": 41.58534,
    "lng": -84.00775,
    "state_id": "OH"
  },
  {
    "zip": 43516,
    "lat": 41.2179,
    "lng": -83.91492,
    "state_id": "OH"
  },
  {
    "zip": 43517,
    "lat": 41.44021,
    "lng": -84.72687,
    "state_id": "OH"
  },
  {
    "zip": 43518,
    "lat": 41.58918,
    "lng": -84.75397,
    "state_id": "OH"
  },
  {
    "zip": 43519,
    "lat": 41.42092,
    "lng": -84.40855,
    "state_id": "OH"
  },
  {
    "zip": 43521,
    "lat": 41.66297,
    "lng": -84.29867,
    "state_id": "OH"
  },
  {
    "zip": 43522,
    "lat": 41.41914,
    "lng": -83.84484,
    "state_id": "OH"
  },
  {
    "zip": 43523,
    "lat": 41.33707,
    "lng": -83.98922,
    "state_id": "OH"
  },
  {
    "zip": 43524,
    "lat": 41.22848,
    "lng": -84.03956,
    "state_id": "OH"
  },
  {
    "zip": 43525,
    "lat": 41.46657,
    "lng": -83.70444,
    "state_id": "OH"
  },
  {
    "zip": 43526,
    "lat": 41.31447,
    "lng": -84.72798,
    "state_id": "OH"
  },
  {
    "zip": 43527,
    "lat": 41.25049,
    "lng": -84.16007,
    "state_id": "OH"
  },
  {
    "zip": 43528,
    "lat": 41.62859,
    "lng": -83.74896,
    "state_id": "OH"
  },
  {
    "zip": 43529,
    "lat": 41.18761,
    "lng": -83.78162,
    "state_id": "OH"
  },
  {
    "zip": 43531,
    "lat": 41.63509,
    "lng": -84.49572,
    "state_id": "OH"
  },
  {
    "zip": 43532,
    "lat": 41.45519,
    "lng": -83.97431,
    "state_id": "OH"
  },
  {
    "zip": 43533,
    "lat": 41.69143,
    "lng": -84.08253,
    "state_id": "OH"
  },
  {
    "zip": 43534,
    "lat": 41.35557,
    "lng": -83.93006,
    "state_id": "OH"
  },
  {
    "zip": 43535,
    "lat": 41.30731,
    "lng": -84.02206,
    "state_id": "OH"
  },
  {
    "zip": 43536,
    "lat": 41.30977,
    "lng": -84.63288,
    "state_id": "OH"
  },
  {
    "zip": 43537,
    "lat": 41.57468,
    "lng": -83.68551,
    "state_id": "OH"
  },
  {
    "zip": 43540,
    "lat": 41.69563,
    "lng": -83.94129,
    "state_id": "OH"
  },
  {
    "zip": 43541,
    "lat": 41.30076,
    "lng": -83.82951,
    "state_id": "OH"
  },
  {
    "zip": 43542,
    "lat": 41.56675,
    "lng": -83.76508,
    "state_id": "OH"
  },
  {
    "zip": 43543,
    "lat": 41.60565,
    "lng": -84.63371,
    "state_id": "OH"
  },
  {
    "zip": 43545,
    "lat": 41.3912,
    "lng": -84.1275,
    "state_id": "OH"
  },
  {
    "zip": 43547,
    "lat": 41.49214,
    "lng": -83.87631,
    "state_id": "OH"
  },
  {
    "zip": 43548,
    "lat": 41.18534,
    "lng": -84.17511,
    "state_id": "OH"
  },
  {
    "zip": 43549,
    "lat": 41.37449,
    "lng": -84.51732,
    "state_id": "OH"
  },
  {
    "zip": 43551,
    "lat": 41.52153,
    "lng": -83.57126,
    "state_id": "OH"
  },
  {
    "zip": 43553,
    "lat": 41.53122,
    "lng": -84.2288,
    "state_id": "OH"
  },
  {
    "zip": 43554,
    "lat": 41.66029,
    "lng": -84.55805,
    "state_id": "OH"
  },
  {
    "zip": 43555,
    "lat": 41.43418,
    "lng": -84.25433,
    "state_id": "OH"
  },
  {
    "zip": 43556,
    "lat": 41.30608,
    "lng": -84.56989,
    "state_id": "OH"
  },
  {
    "zip": 43557,
    "lat": 41.48405,
    "lng": -84.39612,
    "state_id": "OH"
  },
  {
    "zip": 43558,
    "lat": 41.59281,
    "lng": -83.87835,
    "state_id": "OH"
  },
  {
    "zip": 43560,
    "lat": 41.70106,
    "lng": -83.73808,
    "state_id": "OH"
  },
  {
    "zip": 43565,
    "lat": 41.41999,
    "lng": -83.74015,
    "state_id": "OH"
  },
  {
    "zip": 43566,
    "lat": 41.49591,
    "lng": -83.75864,
    "state_id": "OH"
  },
  {
    "zip": 43567,
    "lat": 41.57568,
    "lng": -84.15406,
    "state_id": "OH"
  },
  {
    "zip": 43569,
    "lat": 41.35144,
    "lng": -83.78829,
    "state_id": "OH"
  },
  {
    "zip": 43570,
    "lat": 41.58705,
    "lng": -84.44005,
    "state_id": "OH"
  },
  {
    "zip": 43571,
    "lat": 41.51583,
    "lng": -83.81977,
    "state_id": "OH"
  },
  {
    "zip": 43604,
    "lat": 41.65203,
    "lng": -83.53993,
    "state_id": "OH"
  },
  {
    "zip": 43605,
    "lat": 41.64825,
    "lng": -83.50669,
    "state_id": "OH"
  },
  {
    "zip": 43606,
    "lat": 41.67177,
    "lng": -83.61084,
    "state_id": "OH"
  },
  {
    "zip": 43607,
    "lat": 41.64754,
    "lng": -83.60624,
    "state_id": "OH"
  },
  {
    "zip": 43608,
    "lat": 41.67987,
    "lng": -83.52854,
    "state_id": "OH"
  },
  {
    "zip": 43609,
    "lat": 41.62709,
    "lng": -83.58047,
    "state_id": "OH"
  },
  {
    "zip": 43610,
    "lat": 41.67802,
    "lng": -83.56138,
    "state_id": "OH"
  },
  {
    "zip": 43611,
    "lat": 41.70313,
    "lng": -83.49138,
    "state_id": "OH"
  },
  {
    "zip": 43612,
    "lat": 41.70948,
    "lng": -83.54864,
    "state_id": "OH"
  },
  {
    "zip": 43613,
    "lat": 41.70629,
    "lng": -83.60372,
    "state_id": "OH"
  },
  {
    "zip": 43614,
    "lat": 41.60318,
    "lng": -83.62887,
    "state_id": "OH"
  },
  {
    "zip": 43615,
    "lat": 41.65078,
    "lng": -83.67317,
    "state_id": "OH"
  },
  {
    "zip": 43616,
    "lat": 41.65847,
    "lng": -83.40906,
    "state_id": "OH"
  },
  {
    "zip": 43617,
    "lat": 41.66465,
    "lng": -83.72823,
    "state_id": "OH"
  },
  {
    "zip": 43619,
    "lat": 41.60449,
    "lng": -83.47218,
    "state_id": "OH"
  },
  {
    "zip": 43620,
    "lat": 41.66519,
    "lng": -83.55439,
    "state_id": "OH"
  },
  {
    "zip": 43623,
    "lat": 41.7041,
    "lng": -83.65147,
    "state_id": "OH"
  },
  {
    "zip": 43701,
    "lat": 39.96008,
    "lng": -81.99398,
    "state_id": "OH"
  },
  {
    "zip": 43711,
    "lat": 39.84213,
    "lng": -81.57748,
    "state_id": "OH"
  },
  {
    "zip": 43713,
    "lat": 39.98808,
    "lng": -81.17407,
    "state_id": "OH"
  },
  {
    "zip": 43716,
    "lat": 39.83676,
    "lng": -81.00996,
    "state_id": "OH"
  },
  {
    "zip": 43717,
    "lat": 39.78971,
    "lng": -81.55569,
    "state_id": "OH"
  },
  {
    "zip": 43718,
    "lat": 40.00942,
    "lng": -81.0009,
    "state_id": "OH"
  },
  {
    "zip": 43719,
    "lat": 39.99883,
    "lng": -81.07941,
    "state_id": "OH"
  },
  {
    "zip": 43720,
    "lat": 39.79451,
    "lng": -81.87296,
    "state_id": "OH"
  },
  {
    "zip": 43721,
    "lat": 39.94887,
    "lng": -82.2599,
    "state_id": "OH"
  },
  {
    "zip": 43722,
    "lat": 39.9192,
    "lng": -81.51756,
    "state_id": "OH"
  },
  {
    "zip": 43723,
    "lat": 39.96057,
    "lng": -81.54009,
    "state_id": "OH"
  },
  {
    "zip": 43724,
    "lat": 39.73278,
    "lng": -81.51487,
    "state_id": "OH"
  },
  {
    "zip": 43725,
    "lat": 40.04495,
    "lng": -81.58946,
    "state_id": "OH"
  },
  {
    "zip": 43727,
    "lat": 39.86818,
    "lng": -81.79394,
    "state_id": "OH"
  },
  {
    "zip": 43728,
    "lat": 39.49212,
    "lng": -81.89469,
    "state_id": "OH"
  },
  {
    "zip": 43730,
    "lat": 39.62433,
    "lng": -82.09725,
    "state_id": "OH"
  },
  {
    "zip": 43731,
    "lat": 39.72716,
    "lng": -82.06483,
    "state_id": "OH"
  },
  {
    "zip": 43732,
    "lat": 39.8457,
    "lng": -81.65279,
    "state_id": "OH"
  },
  {
    "zip": 43733,
    "lat": 39.92734,
    "lng": -81.53821,
    "state_id": "OH"
  },
  {
    "zip": 43734,
    "lat": 39.88311,
    "lng": -81.9011,
    "state_id": "OH"
  },
  {
    "zip": 43735,
    "lat": 39.86326,
    "lng": -82.11874,
    "state_id": "OH"
  },
  {
    "zip": 43736,
    "lat": 40.05897,
    "lng": -81.23642,
    "state_id": "OH"
  },
  {
    "zip": 43738,
    "lat": 39.85532,
    "lng": -82.14106,
    "state_id": "OH"
  },
  {
    "zip": 43739,
    "lat": 39.90779,
    "lng": -82.28878,
    "state_id": "OH"
  },
  {
    "zip": 43740,
    "lat": 39.95221,
    "lng": -82.21278,
    "state_id": "OH"
  },
  {
    "zip": 43746,
    "lat": 39.97171,
    "lng": -82.17436,
    "state_id": "OH"
  },
  {
    "zip": 43747,
    "lat": 39.86251,
    "lng": -81.12164,
    "state_id": "OH"
  },
  {
    "zip": 43748,
    "lat": 39.70025,
    "lng": -82.30934,
    "state_id": "OH"
  },
  {
    "zip": 43749,
    "lat": 40.16529,
    "lng": -81.56944,
    "state_id": "OH"
  },
  {
    "zip": 43750,
    "lat": 39.9936,
    "lng": -81.50948,
    "state_id": "OH"
  },
  {
    "zip": 43754,
    "lat": 39.75808,
    "lng": -81.23765,
    "state_id": "OH"
  },
  {
    "zip": 43755,
    "lat": 40.05431,
    "lng": -81.43484,
    "state_id": "OH"
  },
  {
    "zip": 43756,
    "lat": 39.69077,
    "lng": -81.79502,
    "state_id": "OH"
  },
  {
    "zip": 43758,
    "lat": 39.62287,
    "lng": -81.94431,
    "state_id": "OH"
  },
  {
    "zip": 43759,
    "lat": 40.06479,
    "lng": -81.07431,
    "state_id": "OH"
  },
  {
    "zip": 43760,
    "lat": 39.88935,
    "lng": -82.19922,
    "state_id": "OH"
  },
  {
    "zip": 43761,
    "lat": 39.66793,
    "lng": -82.14685,
    "state_id": "OH"
  },
  {
    "zip": 43762,
    "lat": 40.02767,
    "lng": -81.74066,
    "state_id": "OH"
  },
  {
    "zip": 43764,
    "lat": 39.71067,
    "lng": -82.19147,
    "state_id": "OH"
  },
  {
    "zip": 43766,
    "lat": 39.59848,
    "lng": -82.25152,
    "state_id": "OH"
  },
  {
    "zip": 43767,
    "lat": 40.01235,
    "lng": -81.8045,
    "state_id": "OH"
  },
  {
    "zip": 43768,
    "lat": 40.03777,
    "lng": -81.44485,
    "state_id": "OH"
  },
  {
    "zip": 43771,
    "lat": 39.83602,
    "lng": -81.9438,
    "state_id": "OH"
  },
  {
    "zip": 43772,
    "lat": 39.8817,
    "lng": -81.52433,
    "state_id": "OH"
  },
  {
    "zip": 43773,
    "lat": 39.98833,
    "lng": -81.29618,
    "state_id": "OH"
  },
  {
    "zip": 43777,
    "lat": 39.81816,
    "lng": -82.08729,
    "state_id": "OH"
  },
  {
    "zip": 43778,
    "lat": 40.00716,
    "lng": -81.37625,
    "state_id": "OH"
  },
  {
    "zip": 43779,
    "lat": 39.8162,
    "lng": -81.42435,
    "state_id": "OH"
  },
  {
    "zip": 43780,
    "lat": 39.91754,
    "lng": -81.43856,
    "state_id": "OH"
  },
  {
    "zip": 43782,
    "lat": 39.61842,
    "lng": -82.21731,
    "state_id": "OH"
  },
  {
    "zip": 43783,
    "lat": 39.80421,
    "lng": -82.28408,
    "state_id": "OH"
  },
  {
    "zip": 43786,
    "lat": 39.71045,
    "lng": -81.27699,
    "state_id": "OH"
  },
  {
    "zip": 43787,
    "lat": 39.53138,
    "lng": -81.80618,
    "state_id": "OH"
  },
  {
    "zip": 43788,
    "lat": 39.80227,
    "lng": -81.33324,
    "state_id": "OH"
  },
  {
    "zip": 43793,
    "lat": 39.74329,
    "lng": -81.09981,
    "state_id": "OH"
  },
  {
    "zip": 43802,
    "lat": 40.09582,
    "lng": -81.8599,
    "state_id": "OH"
  },
  {
    "zip": 43804,
    "lat": 40.45385,
    "lng": -81.73741,
    "state_id": "OH"
  },
  {
    "zip": 43805,
    "lat": 40.39337,
    "lng": -81.97258,
    "state_id": "OH"
  },
  {
    "zip": 43811,
    "lat": 40.18324,
    "lng": -81.92621,
    "state_id": "OH"
  },
  {
    "zip": 43812,
    "lat": 40.27166,
    "lng": -81.88214,
    "state_id": "OH"
  },
  {
    "zip": 43821,
    "lat": 40.13673,
    "lng": -82.00667,
    "state_id": "OH"
  },
  {
    "zip": 43822,
    "lat": 40.17741,
    "lng": -82.1725,
    "state_id": "OH"
  },
  {
    "zip": 43824,
    "lat": 40.36278,
    "lng": -81.76149,
    "state_id": "OH"
  },
  {
    "zip": 43830,
    "lat": 40.06171,
    "lng": -82.14477,
    "state_id": "OH"
  },
  {
    "zip": 43832,
    "lat": 40.27482,
    "lng": -81.58244,
    "state_id": "OH"
  },
  {
    "zip": 43836,
    "lat": 40.20885,
    "lng": -81.72004,
    "state_id": "OH"
  },
  {
    "zip": 43837,
    "lat": 40.30194,
    "lng": -81.48069,
    "state_id": "OH"
  },
  {
    "zip": 43840,
    "lat": 40.4072,
    "lng": -81.60399,
    "state_id": "OH"
  },
  {
    "zip": 43842,
    "lat": 40.13683,
    "lng": -82.01275,
    "state_id": "OH"
  },
  {
    "zip": 43843,
    "lat": 40.33665,
    "lng": -82.17392,
    "state_id": "OH"
  },
  {
    "zip": 43844,
    "lat": 40.33368,
    "lng": -82.05252,
    "state_id": "OH"
  },
  {
    "zip": 43845,
    "lat": 40.25695,
    "lng": -81.73121,
    "state_id": "OH"
  },
  {
    "zip": 43901,
    "lat": 40.22385,
    "lng": -80.86213,
    "state_id": "OH"
  },
  {
    "zip": 43902,
    "lat": 39.89145,
    "lng": -80.96236,
    "state_id": "OH"
  },
  {
    "zip": 43903,
    "lat": 40.4703,
    "lng": -80.95207,
    "state_id": "OH"
  },
  {
    "zip": 43905,
    "lat": 40.10349,
    "lng": -80.83808,
    "state_id": "OH"
  },
  {
    "zip": 43906,
    "lat": 40.01217,
    "lng": -80.80356,
    "state_id": "OH"
  },
  {
    "zip": 43907,
    "lat": 40.25609,
    "lng": -81.02123,
    "state_id": "OH"
  },
  {
    "zip": 43908,
    "lat": 40.50881,
    "lng": -80.8744,
    "state_id": "OH"
  },
  {
    "zip": 43910,
    "lat": 40.37232,
    "lng": -80.823,
    "state_id": "OH"
  },
  {
    "zip": 43912,
    "lat": 40.08805,
    "lng": -80.79447,
    "state_id": "OH"
  },
  {
    "zip": 43913,
    "lat": 40.27101,
    "lng": -80.63084,
    "state_id": "OH"
  },
  {
    "zip": 43914,
    "lat": 39.77526,
    "lng": -80.95282,
    "state_id": "OH"
  },
  {
    "zip": 43915,
    "lat": 39.75982,
    "lng": -80.89765,
    "state_id": "OH"
  },
  {
    "zip": 43917,
    "lat": 40.22509,
    "lng": -80.80313,
    "state_id": "OH"
  },
  {
    "zip": 43920,
    "lat": 40.67851,
    "lng": -80.5832,
    "state_id": "OH"
  },
  {
    "zip": 43925,
    "lat": 40.44783,
    "lng": -80.86232,
    "state_id": "OH"
  },
  {
    "zip": 43926,
    "lat": 40.50882,
    "lng": -80.62215,
    "state_id": "OH"
  },
  {
    "zip": 43927,
    "lat": 40.11881,
    "lng": -80.93644,
    "state_id": "OH"
  },
  {
    "zip": 43928,
    "lat": 40.01032,
    "lng": -80.9075,
    "state_id": "OH"
  },
  {
    "zip": 43930,
    "lat": 40.56755,
    "lng": -80.76591,
    "state_id": "OH"
  },
  {
    "zip": 43931,
    "lat": 39.67324,
    "lng": -80.87221,
    "state_id": "OH"
  },
  {
    "zip": 43932,
    "lat": 40.51682,
    "lng": -80.76198,
    "state_id": "OH"
  },
  {
    "zip": 43933,
    "lat": 39.93718,
    "lng": -80.89439,
    "state_id": "OH"
  },
  {
    "zip": 43934,
    "lat": 40.07815,
    "lng": -80.78999,
    "state_id": "OH"
  },
  {
    "zip": 43935,
    "lat": 40.12589,
    "lng": -80.75137,
    "state_id": "OH"
  },
  {
    "zip": 43938,
    "lat": 40.30374,
    "lng": -80.67252,
    "state_id": "OH"
  },
  {
    "zip": 43939,
    "lat": 40.16719,
    "lng": -80.79996,
    "state_id": "OH"
  },
  {
    "zip": 43940,
    "lat": 40.02877,
    "lng": -80.8234,
    "state_id": "OH"
  },
  {
    "zip": 43942,
    "lat": 39.86364,
    "lng": -80.84882,
    "state_id": "OH"
  },
  {
    "zip": 43943,
    "lat": 40.22297,
    "lng": -80.72456,
    "state_id": "OH"
  },
  {
    "zip": 43944,
    "lat": 40.43074,
    "lng": -80.77107,
    "state_id": "OH"
  },
  {
    "zip": 43945,
    "lat": 40.6324,
    "lng": -80.84359,
    "state_id": "OH"
  },
  {
    "zip": 43946,
    "lat": 39.65071,
    "lng": -80.96512,
    "state_id": "OH"
  },
  {
    "zip": 43947,
    "lat": 39.9561,
    "lng": -80.78563,
    "state_id": "OH"
  },
  {
    "zip": 43948,
    "lat": 40.27003,
    "lng": -80.77825,
    "state_id": "OH"
  },
  {
    "zip": 43950,
    "lat": 40.09619,
    "lng": -80.92587,
    "state_id": "OH"
  },
  {
    "zip": 43951,
    "lat": 40.11082,
    "lng": -81.01164,
    "state_id": "OH"
  },
  {
    "zip": 43952,
    "lat": 40.39806,
    "lng": -80.66163,
    "state_id": "OH"
  },
  {
    "zip": 43953,
    "lat": 40.35806,
    "lng": -80.7078,
    "state_id": "OH"
  },
  {
    "zip": 43961,
    "lat": 40.52531,
    "lng": -80.62971,
    "state_id": "OH"
  },
  {
    "zip": 43962,
    "lat": 40.67208,
    "lng": -80.87989,
    "state_id": "OH"
  },
  {
    "zip": 43963,
    "lat": 40.17169,
    "lng": -80.69681,
    "state_id": "OH"
  },
  {
    "zip": 43964,
    "lat": 40.48617,
    "lng": -80.67064,
    "state_id": "OH"
  },
  {
    "zip": 43967,
    "lat": 40.02646,
    "lng": -80.94009,
    "state_id": "OH"
  },
  {
    "zip": 43968,
    "lat": 40.63219,
    "lng": -80.68551,
    "state_id": "OH"
  },
  {
    "zip": 43970,
    "lat": 40.46323,
    "lng": -80.8891,
    "state_id": "OH"
  },
  {
    "zip": 43971,
    "lat": 40.15815,
    "lng": -80.71288,
    "state_id": "OH"
  },
  {
    "zip": 43972,
    "lat": 40.10163,
    "lng": -80.97496,
    "state_id": "OH"
  },
  {
    "zip": 43973,
    "lat": 40.19412,
    "lng": -81.27181,
    "state_id": "OH"
  },
  {
    "zip": 43974,
    "lat": 40.18218,
    "lng": -80.88725,
    "state_id": "OH"
  },
  {
    "zip": 43976,
    "lat": 40.36522,
    "lng": -80.90873,
    "state_id": "OH"
  },
  {
    "zip": 43977,
    "lat": 40.1398,
    "lng": -81.10357,
    "state_id": "OH"
  },
  {
    "zip": 43983,
    "lat": 40.14151,
    "lng": -81.20541,
    "state_id": "OH"
  },
  {
    "zip": 43985,
    "lat": 40.16587,
    "lng": -81.13445,
    "state_id": "OH"
  },
  {
    "zip": 43986,
    "lat": 40.38015,
    "lng": -80.99374,
    "state_id": "OH"
  },
  {
    "zip": 43988,
    "lat": 40.40284,
    "lng": -81.10267,
    "state_id": "OH"
  },
  {
    "zip": 44001,
    "lat": 41.36493,
    "lng": -82.26176,
    "state_id": "OH"
  },
  {
    "zip": 44003,
    "lat": 41.62142,
    "lng": -80.58599,
    "state_id": "OH"
  },
  {
    "zip": 44004,
    "lat": 41.85338,
    "lng": -80.78859,
    "state_id": "OH"
  },
  {
    "zip": 44010,
    "lat": 41.76247,
    "lng": -80.85209,
    "state_id": "OH"
  },
  {
    "zip": 44011,
    "lat": 41.44845,
    "lng": -82.01872,
    "state_id": "OH"
  },
  {
    "zip": 44012,
    "lat": 41.49453,
    "lng": -82.01602,
    "state_id": "OH"
  },
  {
    "zip": 44017,
    "lat": 41.36865,
    "lng": -81.86317,
    "state_id": "OH"
  },
  {
    "zip": 44021,
    "lat": 41.44167,
    "lng": -81.14743,
    "state_id": "OH"
  },
  {
    "zip": 44022,
    "lat": 41.44809,
    "lng": -81.40462,
    "state_id": "OH"
  },
  {
    "zip": 44023,
    "lat": 41.38639,
    "lng": -81.29065,
    "state_id": "OH"
  },
  {
    "zip": 44024,
    "lat": 41.57663,
    "lng": -81.19262,
    "state_id": "OH"
  },
  {
    "zip": 44026,
    "lat": 41.53247,
    "lng": -81.33179,
    "state_id": "OH"
  },
  {
    "zip": 44028,
    "lat": 41.31019,
    "lng": -81.939,
    "state_id": "OH"
  },
  {
    "zip": 44030,
    "lat": 41.89683,
    "lng": -80.58027,
    "state_id": "OH"
  },
  {
    "zip": 44032,
    "lat": 41.66599,
    "lng": -80.6699,
    "state_id": "OH"
  },
  {
    "zip": 44035,
    "lat": 41.36515,
    "lng": -82.12938,
    "state_id": "OH"
  },
  {
    "zip": 44039,
    "lat": 41.3847,
    "lng": -82.0196,
    "state_id": "OH"
  },
  {
    "zip": 44040,
    "lat": 41.53204,
    "lng": -81.41101,
    "state_id": "OH"
  },
  {
    "zip": 44041,
    "lat": 41.77579,
    "lng": -80.95137,
    "state_id": "OH"
  },
  {
    "zip": 44044,
    "lat": 41.26672,
    "lng": -82.04027,
    "state_id": "OH"
  },
  {
    "zip": 44045,
    "lat": 41.74619,
    "lng": -81.2836,
    "state_id": "OH"
  },
  {
    "zip": 44046,
    "lat": 41.54269,
    "lng": -81.06935,
    "state_id": "OH"
  },
  {
    "zip": 44047,
    "lat": 41.71894,
    "lng": -80.73887,
    "state_id": "OH"
  },
  {
    "zip": 44048,
    "lat": 41.84626,
    "lng": -80.64558,
    "state_id": "OH"
  },
  {
    "zip": 44049,
    "lat": 41.26628,
    "lng": -82.3053,
    "state_id": "OH"
  },
  {
    "zip": 44050,
    "lat": 41.24845,
    "lng": -82.13008,
    "state_id": "OH"
  },
  {
    "zip": 44052,
    "lat": 41.45531,
    "lng": -82.16251,
    "state_id": "OH"
  },
  {
    "zip": 44053,
    "lat": 41.42515,
    "lng": -82.22405,
    "state_id": "OH"
  },
  {
    "zip": 44054,
    "lat": 41.46438,
    "lng": -82.09318,
    "state_id": "OH"
  },
  {
    "zip": 44055,
    "lat": 41.43383,
    "lng": -82.13369,
    "state_id": "OH"
  },
  {
    "zip": 44056,
    "lat": 41.31492,
    "lng": -81.49832,
    "state_id": "OH"
  },
  {
    "zip": 44057,
    "lat": 41.76786,
    "lng": -81.0583,
    "state_id": "OH"
  },
  {
    "zip": 44060,
    "lat": 41.678,
    "lng": -81.3285,
    "state_id": "OH"
  },
  {
    "zip": 44062,
    "lat": 41.45764,
    "lng": -81.02602,
    "state_id": "OH"
  },
  {
    "zip": 44064,
    "lat": 41.60517,
    "lng": -81.04787,
    "state_id": "OH"
  },
  {
    "zip": 44065,
    "lat": 41.46337,
    "lng": -81.24302,
    "state_id": "OH"
  },
  {
    "zip": 44067,
    "lat": 41.31477,
    "lng": -81.54766,
    "state_id": "OH"
  },
  {
    "zip": 44070,
    "lat": 41.41504,
    "lng": -81.91891,
    "state_id": "OH"
  },
  {
    "zip": 44072,
    "lat": 41.47175,
    "lng": -81.32795,
    "state_id": "OH"
  },
  {
    "zip": 44074,
    "lat": 41.28741,
    "lng": -82.2336,
    "state_id": "OH"
  },
  {
    "zip": 44076,
    "lat": 41.52931,
    "lng": -80.82075,
    "state_id": "OH"
  },
  {
    "zip": 44077,
    "lat": 41.69943,
    "lng": -81.21074,
    "state_id": "OH"
  },
  {
    "zip": 44080,
    "lat": 41.36979,
    "lng": -81.05052,
    "state_id": "OH"
  },
  {
    "zip": 44081,
    "lat": 41.7678,
    "lng": -81.14561,
    "state_id": "OH"
  },
  {
    "zip": 44082,
    "lat": 41.76233,
    "lng": -80.56598,
    "state_id": "OH"
  },
  {
    "zip": 44084,
    "lat": 41.67263,
    "lng": -80.89491,
    "state_id": "OH"
  },
  {
    "zip": 44085,
    "lat": 41.60526,
    "lng": -80.8709,
    "state_id": "OH"
  },
  {
    "zip": 44086,
    "lat": 41.67511,
    "lng": -81.05788,
    "state_id": "OH"
  },
  {
    "zip": 44087,
    "lat": 41.31796,
    "lng": -81.44017,
    "state_id": "OH"
  },
  {
    "zip": 44089,
    "lat": 41.38904,
    "lng": -82.36655,
    "state_id": "OH"
  },
  {
    "zip": 44090,
    "lat": 41.16738,
    "lng": -82.2296,
    "state_id": "OH"
  },
  {
    "zip": 44092,
    "lat": 41.598,
    "lng": -81.46842,
    "state_id": "OH"
  },
  {
    "zip": 44093,
    "lat": 41.53273,
    "lng": -80.61212,
    "state_id": "OH"
  },
  {
    "zip": 44094,
    "lat": 41.60995,
    "lng": -81.3799,
    "state_id": "OH"
  },
  {
    "zip": 44095,
    "lat": 41.65238,
    "lng": -81.44214,
    "state_id": "OH"
  },
  {
    "zip": 44099,
    "lat": 41.55006,
    "lng": -80.97471,
    "state_id": "OH"
  },
  {
    "zip": 44101,
    "lat": 41.48928,
    "lng": -81.66732,
    "state_id": "OH"
  },
  {
    "zip": 44102,
    "lat": 41.47524,
    "lng": -81.73766,
    "state_id": "OH"
  },
  {
    "zip": 44103,
    "lat": 41.5172,
    "lng": -81.64237,
    "state_id": "OH"
  },
  {
    "zip": 44104,
    "lat": 41.48222,
    "lng": -81.62674,
    "state_id": "OH"
  },
  {
    "zip": 44105,
    "lat": 41.45064,
    "lng": -81.63024,
    "state_id": "OH"
  },
  {
    "zip": 44106,
    "lat": 41.50628,
    "lng": -81.60683,
    "state_id": "OH"
  },
  {
    "zip": 44107,
    "lat": 41.48243,
    "lng": -81.80106,
    "state_id": "OH"
  },
  {
    "zip": 44108,
    "lat": 41.54,
    "lng": -81.60842,
    "state_id": "OH"
  },
  {
    "zip": 44109,
    "lat": 41.44473,
    "lng": -81.69672,
    "state_id": "OH"
  },
  {
    "zip": 44110,
    "lat": 41.56416,
    "lng": -81.57098,
    "state_id": "OH"
  },
  {
    "zip": 44111,
    "lat": 41.45828,
    "lng": -81.78853,
    "state_id": "OH"
  },
  {
    "zip": 44112,
    "lat": 41.53569,
    "lng": -81.57368,
    "state_id": "OH"
  },
  {
    "zip": 44113,
    "lat": 41.48424,
    "lng": -81.69762,
    "state_id": "OH"
  },
  {
    "zip": 44114,
    "lat": 41.51449,
    "lng": -81.67561,
    "state_id": "OH"
  },
  {
    "zip": 44115,
    "lat": 41.49215,
    "lng": -81.67108,
    "state_id": "OH"
  },
  {
    "zip": 44116,
    "lat": 41.47017,
    "lng": -81.85251,
    "state_id": "OH"
  },
  {
    "zip": 44117,
    "lat": 41.57149,
    "lng": -81.52679,
    "state_id": "OH"
  },
  {
    "zip": 44118,
    "lat": 41.50186,
    "lng": -81.5563,
    "state_id": "OH"
  },
  {
    "zip": 44119,
    "lat": 41.58736,
    "lng": -81.54704,
    "state_id": "OH"
  },
  {
    "zip": 44120,
    "lat": 41.47357,
    "lng": -81.57931,
    "state_id": "OH"
  },
  {
    "zip": 44121,
    "lat": 41.52647,
    "lng": -81.53214,
    "state_id": "OH"
  },
  {
    "zip": 44122,
    "lat": 41.47013,
    "lng": -81.51178,
    "state_id": "OH"
  },
  {
    "zip": 44123,
    "lat": 41.60382,
    "lng": -81.52422,
    "state_id": "OH"
  },
  {
    "zip": 44124,
    "lat": 41.50053,
    "lng": -81.46678,
    "state_id": "OH"
  },
  {
    "zip": 44125,
    "lat": 41.40695,
    "lng": -81.6151,
    "state_id": "OH"
  },
  {
    "zip": 44126,
    "lat": 41.44175,
    "lng": -81.85303,
    "state_id": "OH"
  },
  {
    "zip": 44127,
    "lat": 41.47157,
    "lng": -81.64914,
    "state_id": "OH"
  },
  {
    "zip": 44128,
    "lat": 41.43761,
    "lng": -81.54027,
    "state_id": "OH"
  },
  {
    "zip": 44129,
    "lat": 41.38971,
    "lng": -81.73513,
    "state_id": "OH"
  },
  {
    "zip": 44130,
    "lat": 41.3778,
    "lng": -81.78683,
    "state_id": "OH"
  },
  {
    "zip": 44131,
    "lat": 41.38285,
    "lng": -81.65242,
    "state_id": "OH"
  },
  {
    "zip": 44132,
    "lat": 41.60503,
    "lng": -81.49946,
    "state_id": "OH"
  },
  {
    "zip": 44133,
    "lat": 41.31414,
    "lng": -81.74522,
    "state_id": "OH"
  },
  {
    "zip": 44134,
    "lat": 41.38533,
    "lng": -81.70451,
    "state_id": "OH"
  },
  {
    "zip": 44135,
    "lat": 41.4257,
    "lng": -81.81966,
    "state_id": "OH"
  },
  {
    "zip": 44136,
    "lat": 41.31184,
    "lng": -81.81098,
    "state_id": "OH"
  },
  {
    "zip": 44137,
    "lat": 41.40929,
    "lng": -81.56248,
    "state_id": "OH"
  },
  {
    "zip": 44138,
    "lat": 41.37364,
    "lng": -81.9231,
    "state_id": "OH"
  },
  {
    "zip": 44139,
    "lat": 41.38326,
    "lng": -81.4444,
    "state_id": "OH"
  },
  {
    "zip": 44140,
    "lat": 41.48509,
    "lng": -81.93143,
    "state_id": "OH"
  },
  {
    "zip": 44141,
    "lat": 41.30084,
    "lng": -81.61424,
    "state_id": "OH"
  },
  {
    "zip": 44142,
    "lat": 41.40227,
    "lng": -81.82519,
    "state_id": "OH"
  },
  {
    "zip": 44143,
    "lat": 41.5535,
    "lng": -81.47509,
    "state_id": "OH"
  },
  {
    "zip": 44144,
    "lat": 41.43764,
    "lng": -81.74274,
    "state_id": "OH"
  },
  {
    "zip": 44145,
    "lat": 41.45244,
    "lng": -81.92945,
    "state_id": "OH"
  },
  {
    "zip": 44146,
    "lat": 41.38161,
    "lng": -81.53018,
    "state_id": "OH"
  },
  {
    "zip": 44147,
    "lat": 41.31914,
    "lng": -81.67871,
    "state_id": "OH"
  },
  {
    "zip": 44149,
    "lat": 41.31341,
    "lng": -81.85617,
    "state_id": "OH"
  },
  {
    "zip": 44201,
    "lat": 41.02682,
    "lng": -81.17928,
    "state_id": "OH"
  },
  {
    "zip": 44202,
    "lat": 41.31499,
    "lng": -81.3399,
    "state_id": "OH"
  },
  {
    "zip": 44203,
    "lat": 41.02056,
    "lng": -81.62891,
    "state_id": "OH"
  },
  {
    "zip": 44212,
    "lat": 41.24342,
    "lng": -81.82951,
    "state_id": "OH"
  },
  {
    "zip": 44214,
    "lat": 40.95263,
    "lng": -81.99851,
    "state_id": "OH"
  },
  {
    "zip": 44215,
    "lat": 41.07027,
    "lng": -81.89779,
    "state_id": "OH"
  },
  {
    "zip": 44216,
    "lat": 40.93914,
    "lng": -81.58506,
    "state_id": "OH"
  },
  {
    "zip": 44217,
    "lat": 40.95518,
    "lng": -81.91161,
    "state_id": "OH"
  },
  {
    "zip": 44221,
    "lat": 41.13962,
    "lng": -81.47656,
    "state_id": "OH"
  },
  {
    "zip": 44223,
    "lat": 41.17121,
    "lng": -81.53122,
    "state_id": "OH"
  },
  {
    "zip": 44224,
    "lat": 41.17668,
    "lng": -81.43881,
    "state_id": "OH"
  },
  {
    "zip": 44230,
    "lat": 40.9633,
    "lng": -81.68996,
    "state_id": "OH"
  },
  {
    "zip": 44231,
    "lat": 41.30653,
    "lng": -81.07238,
    "state_id": "OH"
  },
  {
    "zip": 44233,
    "lat": 41.23941,
    "lng": -81.73512,
    "state_id": "OH"
  },
  {
    "zip": 44234,
    "lat": 41.33498,
    "lng": -81.15758,
    "state_id": "OH"
  },
  {
    "zip": 44235,
    "lat": 41.03146,
    "lng": -82.12449,
    "state_id": "OH"
  },
  {
    "zip": 44236,
    "lat": 41.24769,
    "lng": -81.45094,
    "state_id": "OH"
  },
  {
    "zip": 44240,
    "lat": 41.13302,
    "lng": -81.34148,
    "state_id": "OH"
  },
  {
    "zip": 44241,
    "lat": 41.23941,
    "lng": -81.3472,
    "state_id": "OH"
  },
  {
    "zip": 44243,
    "lat": 41.14798,
    "lng": -81.34119,
    "state_id": "OH"
  },
  {
    "zip": 44250,
    "lat": 41.02112,
    "lng": -81.43737,
    "state_id": "OH"
  },
  {
    "zip": 44251,
    "lat": 41.02914,
    "lng": -81.92738,
    "state_id": "OH"
  },
  {
    "zip": 44253,
    "lat": 41.1657,
    "lng": -82.03323,
    "state_id": "OH"
  },
  {
    "zip": 44254,
    "lat": 41.04413,
    "lng": -82.01481,
    "state_id": "OH"
  },
  {
    "zip": 44255,
    "lat": 41.28651,
    "lng": -81.22973,
    "state_id": "OH"
  },
  {
    "zip": 44256,
    "lat": 41.14011,
    "lng": -81.85904,
    "state_id": "OH"
  },
  {
    "zip": 44260,
    "lat": 41.03678,
    "lng": -81.34084,
    "state_id": "OH"
  },
  {
    "zip": 44262,
    "lat": 41.14048,
    "lng": -81.43726,
    "state_id": "OH"
  },
  {
    "zip": 44264,
    "lat": 41.22823,
    "lng": -81.54253,
    "state_id": "OH"
  },
  {
    "zip": 44265,
    "lat": 41.03572,
    "lng": -81.2531,
    "state_id": "OH"
  },
  {
    "zip": 44266,
    "lat": 41.16674,
    "lng": -81.20258,
    "state_id": "OH"
  },
  {
    "zip": 44270,
    "lat": 40.96055,
    "lng": -81.77806,
    "state_id": "OH"
  },
  {
    "zip": 44272,
    "lat": 41.08801,
    "lng": -81.18335,
    "state_id": "OH"
  },
  {
    "zip": 44273,
    "lat": 41.02751,
    "lng": -81.87623,
    "state_id": "OH"
  },
  {
    "zip": 44274,
    "lat": 41.09834,
    "lng": -81.73264,
    "state_id": "OH"
  },
  {
    "zip": 44275,
    "lat": 41.10151,
    "lng": -82.09938,
    "state_id": "OH"
  },
  {
    "zip": 44276,
    "lat": 40.93616,
    "lng": -81.83617,
    "state_id": "OH"
  },
  {
    "zip": 44278,
    "lat": 41.10158,
    "lng": -81.42166,
    "state_id": "OH"
  },
  {
    "zip": 44280,
    "lat": 41.23896,
    "lng": -81.92381,
    "state_id": "OH"
  },
  {
    "zip": 44281,
    "lat": 41.05858,
    "lng": -81.73834,
    "state_id": "OH"
  },
  {
    "zip": 44285,
    "lat": 41.15976,
    "lng": -81.07151,
    "state_id": "OH"
  },
  {
    "zip": 44286,
    "lat": 41.23687,
    "lng": -81.64062,
    "state_id": "OH"
  },
  {
    "zip": 44287,
    "lat": 40.93909,
    "lng": -82.10109,
    "state_id": "OH"
  },
  {
    "zip": 44288,
    "lat": 41.23861,
    "lng": -81.08434,
    "state_id": "OH"
  },
  {
    "zip": 44301,
    "lat": 41.04388,
    "lng": -81.52455,
    "state_id": "OH"
  },
  {
    "zip": 44302,
    "lat": 41.08955,
    "lng": -81.53883,
    "state_id": "OH"
  },
  {
    "zip": 44303,
    "lat": 41.10467,
    "lng": -81.53685,
    "state_id": "OH"
  },
  {
    "zip": 44304,
    "lat": 41.08306,
    "lng": -81.5078,
    "state_id": "OH"
  },
  {
    "zip": 44305,
    "lat": 41.07587,
    "lng": -81.4614,
    "state_id": "OH"
  },
  {
    "zip": 44306,
    "lat": 41.03937,
    "lng": -81.4832,
    "state_id": "OH"
  },
  {
    "zip": 44307,
    "lat": 41.07044,
    "lng": -81.54631,
    "state_id": "OH"
  },
  {
    "zip": 44308,
    "lat": 41.08164,
    "lng": -81.51709,
    "state_id": "OH"
  },
  {
    "zip": 44310,
    "lat": 41.1066,
    "lng": -81.4971,
    "state_id": "OH"
  },
  {
    "zip": 44311,
    "lat": 41.06427,
    "lng": -81.52086,
    "state_id": "OH"
  },
  {
    "zip": 44312,
    "lat": 41.01539,
    "lng": -81.44085,
    "state_id": "OH"
  },
  {
    "zip": 44313,
    "lat": 41.13281,
    "lng": -81.56452,
    "state_id": "OH"
  },
  {
    "zip": 44314,
    "lat": 41.0406,
    "lng": -81.55858,
    "state_id": "OH"
  },
  {
    "zip": 44319,
    "lat": 40.98304,
    "lng": -81.53167,
    "state_id": "OH"
  },
  {
    "zip": 44320,
    "lat": 41.07835,
    "lng": -81.58332,
    "state_id": "OH"
  },
  {
    "zip": 44321,
    "lat": 41.09891,
    "lng": -81.65131,
    "state_id": "OH"
  },
  {
    "zip": 44333,
    "lat": 41.16193,
    "lng": -81.63287,
    "state_id": "OH"
  },
  {
    "zip": 44401,
    "lat": 41.02259,
    "lng": -80.94521,
    "state_id": "OH"
  },
  {
    "zip": 44402,
    "lat": 41.38663,
    "lng": -80.85424,
    "state_id": "OH"
  },
  {
    "zip": 44403,
    "lat": 41.24042,
    "lng": -80.58148,
    "state_id": "OH"
  },
  {
    "zip": 44404,
    "lat": 41.32651,
    "lng": -80.54998,
    "state_id": "OH"
  },
  {
    "zip": 44405,
    "lat": 41.07769,
    "lng": -80.59186,
    "state_id": "OH"
  },
  {
    "zip": 44406,
    "lat": 41.01105,
    "lng": -80.77222,
    "state_id": "OH"
  },
  {
    "zip": 44408,
    "lat": 40.88615,
    "lng": -80.68651,
    "state_id": "OH"
  },
  {
    "zip": 44410,
    "lat": 41.34928,
    "lng": -80.72414,
    "state_id": "OH"
  },
  {
    "zip": 44411,
    "lat": 41.035,
    "lng": -81.04871,
    "state_id": "OH"
  },
  {
    "zip": 44412,
    "lat": 41.09396,
    "lng": -81.02478,
    "state_id": "OH"
  },
  {
    "zip": 44413,
    "lat": 40.84785,
    "lng": -80.55409,
    "state_id": "OH"
  },
  {
    "zip": 44417,
    "lat": 41.43634,
    "lng": -80.66387,
    "state_id": "OH"
  },
  {
    "zip": 44418,
    "lat": 41.3074,
    "lng": -80.60347,
    "state_id": "OH"
  },
  {
    "zip": 44420,
    "lat": 41.17326,
    "lng": -80.68007,
    "state_id": "OH"
  },
  {
    "zip": 44423,
    "lat": 40.75272,
    "lng": -80.9008,
    "state_id": "OH"
  },
  {
    "zip": 44425,
    "lat": 41.17053,
    "lng": -80.57383,
    "state_id": "OH"
  },
  {
    "zip": 44427,
    "lat": 40.71629,
    "lng": -80.95635,
    "state_id": "OH"
  },
  {
    "zip": 44428,
    "lat": 41.4361,
    "lng": -80.57854,
    "state_id": "OH"
  },
  {
    "zip": 44429,
    "lat": 41.09715,
    "lng": -80.98165,
    "state_id": "OH"
  },
  {
    "zip": 44430,
    "lat": 41.24069,
    "lng": -80.90784,
    "state_id": "OH"
  },
  {
    "zip": 44431,
    "lat": 40.8483,
    "lng": -80.75416,
    "state_id": "OH"
  },
  {
    "zip": 44432,
    "lat": 40.75011,
    "lng": -80.75814,
    "state_id": "OH"
  },
  {
    "zip": 44436,
    "lat": 41.05947,
    "lng": -80.54332,
    "state_id": "OH"
  },
  {
    "zip": 44437,
    "lat": 41.15582,
    "lng": -80.73122,
    "state_id": "OH"
  },
  {
    "zip": 44438,
    "lat": 41.24234,
    "lng": -80.53365,
    "state_id": "OH"
  },
  {
    "zip": 44439,
    "lat": 41.4558,
    "lng": -80.95638,
    "state_id": "OH"
  },
  {
    "zip": 44440,
    "lat": 41.14835,
    "lng": -80.78377,
    "state_id": "OH"
  },
  {
    "zip": 44441,
    "lat": 40.75795,
    "lng": -80.55297,
    "state_id": "OH"
  },
  {
    "zip": 44442,
    "lat": 40.96613,
    "lng": -80.54925,
    "state_id": "OH"
  },
  {
    "zip": 44443,
    "lat": 40.9244,
    "lng": -80.59836,
    "state_id": "OH"
  },
  {
    "zip": 44444,
    "lat": 41.17342,
    "lng": -80.97525,
    "state_id": "OH"
  },
  {
    "zip": 44445,
    "lat": 40.84865,
    "lng": -80.61804,
    "state_id": "OH"
  },
  {
    "zip": 44446,
    "lat": 41.18996,
    "lng": -80.7496,
    "state_id": "OH"
  },
  {
    "zip": 44449,
    "lat": 40.98034,
    "lng": -81.03356,
    "state_id": "OH"
  },
  {
    "zip": 44450,
    "lat": 41.45519,
    "lng": -80.82992,
    "state_id": "OH"
  },
  {
    "zip": 44451,
    "lat": 41.0812,
    "lng": -80.86852,
    "state_id": "OH"
  },
  {
    "zip": 44452,
    "lat": 40.9477,
    "lng": -80.66169,
    "state_id": "OH"
  },
  {
    "zip": 44454,
    "lat": 40.92002,
    "lng": -80.54298,
    "state_id": "OH"
  },
  {
    "zip": 44455,
    "lat": 40.77658,
    "lng": -80.6101,
    "state_id": "OH"
  },
  {
    "zip": 44460,
    "lat": 40.89921,
    "lng": -80.87055,
    "state_id": "OH"
  },
  {
    "zip": 44470,
    "lat": 41.29776,
    "lng": -80.96182,
    "state_id": "OH"
  },
  {
    "zip": 44471,
    "lat": 41.04848,
    "lng": -80.58968,
    "state_id": "OH"
  },
  {
    "zip": 44473,
    "lat": 41.24616,
    "lng": -80.66249,
    "state_id": "OH"
  },
  {
    "zip": 44481,
    "lat": 41.22718,
    "lng": -80.86405,
    "state_id": "OH"
  },
  {
    "zip": 44483,
    "lat": 41.26542,
    "lng": -80.81576,
    "state_id": "OH"
  },
  {
    "zip": 44484,
    "lat": 41.23521,
    "lng": -80.74905,
    "state_id": "OH"
  },
  {
    "zip": 44485,
    "lat": 41.24042,
    "lng": -80.84612,
    "state_id": "OH"
  },
  {
    "zip": 44490,
    "lat": 40.8999,
    "lng": -80.76806,
    "state_id": "OH"
  },
  {
    "zip": 44491,
    "lat": 41.37502,
    "lng": -80.96429,
    "state_id": "OH"
  },
  {
    "zip": 44493,
    "lat": 40.82502,
    "lng": -80.88864,
    "state_id": "OH"
  },
  {
    "zip": 44502,
    "lat": 41.08438,
    "lng": -80.64246,
    "state_id": "OH"
  },
  {
    "zip": 44503,
    "lat": 41.09917,
    "lng": -80.64895,
    "state_id": "OH"
  },
  {
    "zip": 44504,
    "lat": 41.12356,
    "lng": -80.65365,
    "state_id": "OH"
  },
  {
    "zip": 44505,
    "lat": 41.12798,
    "lng": -80.6196,
    "state_id": "OH"
  },
  {
    "zip": 44506,
    "lat": 41.09355,
    "lng": -80.62606,
    "state_id": "OH"
  },
  {
    "zip": 44507,
    "lat": 41.07441,
    "lng": -80.65525,
    "state_id": "OH"
  },
  {
    "zip": 44509,
    "lat": 41.10805,
    "lng": -80.69644,
    "state_id": "OH"
  },
  {
    "zip": 44510,
    "lat": 41.12273,
    "lng": -80.67413,
    "state_id": "OH"
  },
  {
    "zip": 44511,
    "lat": 41.06953,
    "lng": -80.69565,
    "state_id": "OH"
  },
  {
    "zip": 44512,
    "lat": 41.02498,
    "lng": -80.66778,
    "state_id": "OH"
  },
  {
    "zip": 44514,
    "lat": 40.99893,
    "lng": -80.60809,
    "state_id": "OH"
  },
  {
    "zip": 44515,
    "lat": 41.10123,
    "lng": -80.76375,
    "state_id": "OH"
  },
  {
    "zip": 44601,
    "lat": 40.91936,
    "lng": -81.13023,
    "state_id": "OH"
  },
  {
    "zip": 44606,
    "lat": 40.73165,
    "lng": -81.79763,
    "state_id": "OH"
  },
  {
    "zip": 44607,
    "lat": 40.68949,
    "lng": -81.02581,
    "state_id": "OH"
  },
  {
    "zip": 44608,
    "lat": 40.65394,
    "lng": -81.59493,
    "state_id": "OH"
  },
  {
    "zip": 44609,
    "lat": 40.91729,
    "lng": -80.98799,
    "state_id": "OH"
  },
  {
    "zip": 44610,
    "lat": 40.55952,
    "lng": -81.79983,
    "state_id": "OH"
  },
  {
    "zip": 44611,
    "lat": 40.60452,
    "lng": -82.08586,
    "state_id": "OH"
  },
  {
    "zip": 44612,
    "lat": 40.63595,
    "lng": -81.4596,
    "state_id": "OH"
  },
  {
    "zip": 44613,
    "lat": 40.71367,
    "lng": -81.60024,
    "state_id": "OH"
  },
  {
    "zip": 44614,
    "lat": 40.88707,
    "lng": -81.58627,
    "state_id": "OH"
  },
  {
    "zip": 44615,
    "lat": 40.56509,
    "lng": -81.07428,
    "state_id": "OH"
  },
  {
    "zip": 44618,
    "lat": 40.77268,
    "lng": -81.68897,
    "state_id": "OH"
  },
  {
    "zip": 44620,
    "lat": 40.58266,
    "lng": -81.21376,
    "state_id": "OH"
  },
  {
    "zip": 44621,
    "lat": 40.42753,
    "lng": -81.29587,
    "state_id": "OH"
  },
  {
    "zip": 44622,
    "lat": 40.54871,
    "lng": -81.47896,
    "state_id": "OH"
  },
  {
    "zip": 44624,
    "lat": 40.61132,
    "lng": -81.66097,
    "state_id": "OH"
  },
  {
    "zip": 44625,
    "lat": 40.76108,
    "lng": -81.00001,
    "state_id": "OH"
  },
  {
    "zip": 44626,
    "lat": 40.68963,
    "lng": -81.38377,
    "state_id": "OH"
  },
  {
    "zip": 44627,
    "lat": 40.66603,
    "lng": -81.83249,
    "state_id": "OH"
  },
  {
    "zip": 44628,
    "lat": 40.53714,
    "lng": -82.16453,
    "state_id": "OH"
  },
  {
    "zip": 44629,
    "lat": 40.35645,
    "lng": -81.44614,
    "state_id": "OH"
  },
  {
    "zip": 44632,
    "lat": 40.96379,
    "lng": -81.30667,
    "state_id": "OH"
  },
  {
    "zip": 44633,
    "lat": 40.63139,
    "lng": -81.93415,
    "state_id": "OH"
  },
  {
    "zip": 44634,
    "lat": 40.83417,
    "lng": -81.04793,
    "state_id": "OH"
  },
  {
    "zip": 44637,
    "lat": 40.48315,
    "lng": -82.03372,
    "state_id": "OH"
  },
  {
    "zip": 44638,
    "lat": 40.64822,
    "lng": -82.13607,
    "state_id": "OH"
  },
  {
    "zip": 44640,
    "lat": 40.98445,
    "lng": -81.14861,
    "state_id": "OH"
  },
  {
    "zip": 44641,
    "lat": 40.86582,
    "lng": -81.24172,
    "state_id": "OH"
  },
  {
    "zip": 44643,
    "lat": 40.63965,
    "lng": -81.30086,
    "state_id": "OH"
  },
  {
    "zip": 44644,
    "lat": 40.68776,
    "lng": -81.17665,
    "state_id": "OH"
  },
  {
    "zip": 44645,
    "lat": 40.90888,
    "lng": -81.71655,
    "state_id": "OH"
  },
  {
    "zip": 44646,
    "lat": 40.81249,
    "lng": -81.49841,
    "state_id": "OH"
  },
  {
    "zip": 44647,
    "lat": 40.79758,
    "lng": -81.56503,
    "state_id": "OH"
  },
  {
    "zip": 44651,
    "lat": 40.62549,
    "lng": -80.95387,
    "state_id": "OH"
  },
  {
    "zip": 44652,
    "lat": 40.90044,
    "lng": -81.32618,
    "state_id": "OH"
  },
  {
    "zip": 44653,
    "lat": 40.43606,
    "lng": -81.37023,
    "state_id": "OH"
  },
  {
    "zip": 44654,
    "lat": 40.53909,
    "lng": -81.87079,
    "state_id": "OH"
  },
  {
    "zip": 44656,
    "lat": 40.569,
    "lng": -81.33676,
    "state_id": "OH"
  },
  {
    "zip": 44657,
    "lat": 40.74115,
    "lng": -81.0931,
    "state_id": "OH"
  },
  {
    "zip": 44659,
    "lat": 40.69464,
    "lng": -81.70207,
    "state_id": "OH"
  },
  {
    "zip": 44661,
    "lat": 40.59529,
    "lng": -82.11284,
    "state_id": "OH"
  },
  {
    "zip": 44662,
    "lat": 40.71086,
    "lng": -81.55621,
    "state_id": "OH"
  },
  {
    "zip": 44663,
    "lat": 40.46047,
    "lng": -81.45206,
    "state_id": "OH"
  },
  {
    "zip": 44666,
    "lat": 40.84409,
    "lng": -81.63286,
    "state_id": "OH"
  },
  {
    "zip": 44667,
    "lat": 40.83346,
    "lng": -81.76595,
    "state_id": "OH"
  },
  {
    "zip": 44669,
    "lat": 40.79669,
    "lng": -81.15786,
    "state_id": "OH"
  },
  {
    "zip": 44670,
    "lat": 40.7627,
    "lng": -81.18862,
    "state_id": "OH"
  },
  {
    "zip": 44671,
    "lat": 40.64595,
    "lng": -81.36565,
    "state_id": "OH"
  },
  {
    "zip": 44672,
    "lat": 40.92059,
    "lng": -81.02542,
    "state_id": "OH"
  },
  {
    "zip": 44675,
    "lat": 40.50033,
    "lng": -81.25007,
    "state_id": "OH"
  },
  {
    "zip": 44676,
    "lat": 40.6885,
    "lng": -82.0354,
    "state_id": "OH"
  },
  {
    "zip": 44677,
    "lat": 40.87411,
    "lng": -81.86115,
    "state_id": "OH"
  },
  {
    "zip": 44678,
    "lat": 40.56661,
    "lng": -81.34988,
    "state_id": "OH"
  },
  {
    "zip": 44680,
    "lat": 40.60258,
    "lng": -81.54245,
    "state_id": "OH"
  },
  {
    "zip": 44681,
    "lat": 40.5021,
    "lng": -81.66119,
    "state_id": "OH"
  },
  {
    "zip": 44682,
    "lat": 40.39729,
    "lng": -81.40533,
    "state_id": "OH"
  },
  {
    "zip": 44683,
    "lat": 40.35284,
    "lng": -81.32318,
    "state_id": "OH"
  },
  {
    "zip": 44685,
    "lat": 40.95992,
    "lng": -81.42424,
    "state_id": "OH"
  },
  {
    "zip": 44687,
    "lat": 40.53789,
    "lng": -81.72007,
    "state_id": "OH"
  },
  {
    "zip": 44688,
    "lat": 40.68462,
    "lng": -81.25771,
    "state_id": "OH"
  },
  {
    "zip": 44689,
    "lat": 40.64989,
    "lng": -81.65687,
    "state_id": "OH"
  },
  {
    "zip": 44690,
    "lat": 40.61422,
    "lng": -81.69379,
    "state_id": "OH"
  },
  {
    "zip": 44691,
    "lat": 40.8057,
    "lng": -81.97955,
    "state_id": "OH"
  },
  {
    "zip": 44693,
    "lat": 40.3019,
    "lng": -81.18622,
    "state_id": "OH"
  },
  {
    "zip": 44695,
    "lat": 40.43674,
    "lng": -81.17831,
    "state_id": "OH"
  },
  {
    "zip": 44697,
    "lat": 40.61156,
    "lng": -81.4236,
    "state_id": "OH"
  },
  {
    "zip": 44699,
    "lat": 40.27808,
    "lng": -81.28491,
    "state_id": "OH"
  },
  {
    "zip": 44702,
    "lat": 40.79988,
    "lng": -81.37543,
    "state_id": "OH"
  },
  {
    "zip": 44703,
    "lat": 40.81052,
    "lng": -81.38138,
    "state_id": "OH"
  },
  {
    "zip": 44704,
    "lat": 40.8003,
    "lng": -81.34034,
    "state_id": "OH"
  },
  {
    "zip": 44705,
    "lat": 40.83384,
    "lng": -81.33223,
    "state_id": "OH"
  },
  {
    "zip": 44706,
    "lat": 40.75352,
    "lng": -81.41866,
    "state_id": "OH"
  },
  {
    "zip": 44707,
    "lat": 40.76111,
    "lng": -81.34988,
    "state_id": "OH"
  },
  {
    "zip": 44708,
    "lat": 40.81775,
    "lng": -81.43562,
    "state_id": "OH"
  },
  {
    "zip": 44709,
    "lat": 40.84237,
    "lng": -81.38622,
    "state_id": "OH"
  },
  {
    "zip": 44710,
    "lat": 40.7897,
    "lng": -81.42607,
    "state_id": "OH"
  },
  {
    "zip": 44714,
    "lat": 40.83611,
    "lng": -81.35907,
    "state_id": "OH"
  },
  {
    "zip": 44718,
    "lat": 40.85116,
    "lng": -81.44747,
    "state_id": "OH"
  },
  {
    "zip": 44720,
    "lat": 40.90138,
    "lng": -81.43165,
    "state_id": "OH"
  },
  {
    "zip": 44721,
    "lat": 40.89412,
    "lng": -81.32837,
    "state_id": "OH"
  },
  {
    "zip": 44730,
    "lat": 40.76573,
    "lng": -81.25905,
    "state_id": "OH"
  },
  {
    "zip": 44802,
    "lat": 41.04566,
    "lng": -83.41945,
    "state_id": "OH"
  },
  {
    "zip": 44804,
    "lat": 41.10561,
    "lng": -83.53412,
    "state_id": "OH"
  },
  {
    "zip": 44805,
    "lat": 40.87291,
    "lng": -82.31688,
    "state_id": "OH"
  },
  {
    "zip": 44807,
    "lat": 41.05988,
    "lng": -82.87733,
    "state_id": "OH"
  },
  {
    "zip": 44809,
    "lat": 41.13348,
    "lng": -83.2888,
    "state_id": "OH"
  },
  {
    "zip": 44811,
    "lat": 41.24463,
    "lng": -82.84455,
    "state_id": "OH"
  },
  {
    "zip": 44813,
    "lat": 40.59884,
    "lng": -82.53168,
    "state_id": "OH"
  },
  {
    "zip": 44814,
    "lat": 41.32187,
    "lng": -82.4726,
    "state_id": "OH"
  },
  {
    "zip": 44815,
    "lat": 41.24664,
    "lng": -83.23942,
    "state_id": "OH"
  },
  {
    "zip": 44816,
    "lat": 41.33023,
    "lng": -82.35253,
    "state_id": "OH"
  },
  {
    "zip": 44817,
    "lat": 41.19985,
    "lng": -83.54542,
    "state_id": "OH"
  },
  {
    "zip": 44818,
    "lat": 41.00036,
    "lng": -82.99593,
    "state_id": "OH"
  },
  {
    "zip": 44820,
    "lat": 40.81746,
    "lng": -82.97252,
    "state_id": "OH"
  },
  {
    "zip": 44822,
    "lat": 40.55722,
    "lng": -82.39861,
    "state_id": "OH"
  },
  {
    "zip": 44824,
    "lat": 41.3806,
    "lng": -82.80504,
    "state_id": "OH"
  },
  {
    "zip": 44825,
    "lat": 40.95424,
    "lng": -82.94314,
    "state_id": "OH"
  },
  {
    "zip": 44826,
    "lat": 41.2408,
    "lng": -82.48009,
    "state_id": "OH"
  },
  {
    "zip": 44827,
    "lat": 40.81937,
    "lng": -82.75771,
    "state_id": "OH"
  },
  {
    "zip": 44828,
    "lat": 41.23186,
    "lng": -82.86093,
    "state_id": "OH"
  },
  {
    "zip": 44830,
    "lat": 41.16705,
    "lng": -83.39991,
    "state_id": "OH"
  },
  {
    "zip": 44833,
    "lat": 40.71064,
    "lng": -82.79417,
    "state_id": "OH"
  },
  {
    "zip": 44836,
    "lat": 41.23807,
    "lng": -83.05467,
    "state_id": "OH"
  },
  {
    "zip": 44837,
    "lat": 41.01641,
    "lng": -82.48219,
    "state_id": "OH"
  },
  {
    "zip": 44838,
    "lat": 40.77228,
    "lng": -82.25979,
    "state_id": "OH"
  },
  {
    "zip": 44839,
    "lat": 41.37348,
    "lng": -82.56096,
    "state_id": "OH"
  },
  {
    "zip": 44840,
    "lat": 40.78965,
    "lng": -82.17898,
    "state_id": "OH"
  },
  {
    "zip": 44841,
    "lat": 41.25124,
    "lng": -83.30611,
    "state_id": "OH"
  },
  {
    "zip": 44842,
    "lat": 40.65147,
    "lng": -82.22496,
    "state_id": "OH"
  },
  {
    "zip": 44843,
    "lat": 40.68904,
    "lng": -82.41074,
    "state_id": "OH"
  },
  {
    "zip": 44844,
    "lat": 40.9922,
    "lng": -83.26132,
    "state_id": "OH"
  },
  {
    "zip": 44846,
    "lat": 41.3206,
    "lng": -82.61033,
    "state_id": "OH"
  },
  {
    "zip": 44847,
    "lat": 41.22885,
    "lng": -82.7055,
    "state_id": "OH"
  },
  {
    "zip": 44849,
    "lat": 40.80925,
    "lng": -83.13046,
    "state_id": "OH"
  },
  {
    "zip": 44850,
    "lat": 41.03549,
    "lng": -82.68406,
    "state_id": "OH"
  },
  {
    "zip": 44851,
    "lat": 41.10396,
    "lng": -82.40599,
    "state_id": "OH"
  },
  {
    "zip": 44853,
    "lat": 41.0553,
    "lng": -83.30302,
    "state_id": "OH"
  },
  {
    "zip": 44854,
    "lat": 40.94343,
    "lng": -82.8551,
    "state_id": "OH"
  },
  {
    "zip": 44855,
    "lat": 41.10395,
    "lng": -82.58953,
    "state_id": "OH"
  },
  {
    "zip": 44856,
    "lat": 40.794,
    "lng": -82.85666,
    "state_id": "OH"
  },
  {
    "zip": 44857,
    "lat": 41.2185,
    "lng": -82.58239,
    "state_id": "OH"
  },
  {
    "zip": 44859,
    "lat": 41.02044,
    "lng": -82.3265,
    "state_id": "OH"
  },
  {
    "zip": 44861,
    "lat": 41.23951,
    "lng": -83.14798,
    "state_id": "OH"
  },
  {
    "zip": 44864,
    "lat": 40.66494,
    "lng": -82.32131,
    "state_id": "OH"
  },
  {
    "zip": 44865,
    "lat": 40.99234,
    "lng": -82.67912,
    "state_id": "OH"
  },
  {
    "zip": 44866,
    "lat": 40.92062,
    "lng": -82.19347,
    "state_id": "OH"
  },
  {
    "zip": 44867,
    "lat": 41.14708,
    "lng": -82.98823,
    "state_id": "OH"
  },
  {
    "zip": 44870,
    "lat": 41.4052,
    "lng": -82.72495,
    "state_id": "OH"
  },
  {
    "zip": 44874,
    "lat": 40.96814,
    "lng": -82.36642,
    "state_id": "OH"
  },
  {
    "zip": 44875,
    "lat": 40.88812,
    "lng": -82.65272,
    "state_id": "OH"
  },
  {
    "zip": 44878,
    "lat": 40.94623,
    "lng": -82.51719,
    "state_id": "OH"
  },
  {
    "zip": 44880,
    "lat": 41.03411,
    "lng": -82.22035,
    "state_id": "OH"
  },
  {
    "zip": 44881,
    "lat": 40.87002,
    "lng": -82.87601,
    "state_id": "OH"
  },
  {
    "zip": 44882,
    "lat": 40.9476,
    "lng": -83.13513,
    "state_id": "OH"
  },
  {
    "zip": 44883,
    "lat": 41.12373,
    "lng": -83.17331,
    "state_id": "OH"
  },
  {
    "zip": 44887,
    "lat": 40.92159,
    "lng": -82.78573,
    "state_id": "OH"
  },
  {
    "zip": 44889,
    "lat": 41.24556,
    "lng": -82.38327,
    "state_id": "OH"
  },
  {
    "zip": 44890,
    "lat": 41.08111,
    "lng": -82.71563,
    "state_id": "OH"
  },
  {
    "zip": 44901,
    "lat": 40.79166,
    "lng": -82.50941,
    "state_id": "OH"
  },
  {
    "zip": 44902,
    "lat": 40.75817,
    "lng": -82.51056,
    "state_id": "OH"
  },
  {
    "zip": 44903,
    "lat": 40.77603,
    "lng": -82.52734,
    "state_id": "OH"
  },
  {
    "zip": 44904,
    "lat": 40.66547,
    "lng": -82.61294,
    "state_id": "OH"
  },
  {
    "zip": 44905,
    "lat": 40.77747,
    "lng": -82.46827,
    "state_id": "OH"
  },
  {
    "zip": 44906,
    "lat": 40.76575,
    "lng": -82.57694,
    "state_id": "OH"
  },
  {
    "zip": 44907,
    "lat": 40.72722,
    "lng": -82.52138,
    "state_id": "OH"
  },
  {
    "zip": 45001,
    "lat": 39.13918,
    "lng": -84.71233,
    "state_id": "OH"
  },
  {
    "zip": 45002,
    "lat": 39.19836,
    "lng": -84.73405,
    "state_id": "OH"
  },
  {
    "zip": 45003,
    "lat": 39.58696,
    "lng": -84.78787,
    "state_id": "OH"
  },
  {
    "zip": 45005,
    "lat": 39.53559,
    "lng": -84.30296,
    "state_id": "OH"
  },
  {
    "zip": 45011,
    "lat": 39.42962,
    "lng": -84.50248,
    "state_id": "OH"
  },
  {
    "zip": 45013,
    "lat": 39.40771,
    "lng": -84.64867,
    "state_id": "OH"
  },
  {
    "zip": 45014,
    "lat": 39.32804,
    "lng": -84.55132,
    "state_id": "OH"
  },
  {
    "zip": 45015,
    "lat": 39.36202,
    "lng": -84.53959,
    "state_id": "OH"
  },
  {
    "zip": 45030,
    "lat": 39.25707,
    "lng": -84.76083,
    "state_id": "OH"
  },
  {
    "zip": 45032,
    "lat": 39.50239,
    "lng": -84.01038,
    "state_id": "OH"
  },
  {
    "zip": 45033,
    "lat": 39.1768,
    "lng": -84.76306,
    "state_id": "OH"
  },
  {
    "zip": 45034,
    "lat": 39.35889,
    "lng": -84.24646,
    "state_id": "OH"
  },
  {
    "zip": 45036,
    "lat": 39.44443,
    "lng": -84.2202,
    "state_id": "OH"
  },
  {
    "zip": 45039,
    "lat": 39.32785,
    "lng": -84.23948,
    "state_id": "OH"
  },
  {
    "zip": 45040,
    "lat": 39.352,
    "lng": -84.31256,
    "state_id": "OH"
  },
  {
    "zip": 45041,
    "lat": 39.21114,
    "lng": -84.703,
    "state_id": "OH"
  },
  {
    "zip": 45042,
    "lat": 39.54879,
    "lng": -84.43796,
    "state_id": "OH"
  },
  {
    "zip": 45044,
    "lat": 39.45004,
    "lng": -84.38227,
    "state_id": "OH"
  },
  {
    "zip": 45050,
    "lat": 39.44535,
    "lng": -84.35918,
    "state_id": "OH"
  },
  {
    "zip": 45051,
    "lat": 39.09796,
    "lng": -84.64662,
    "state_id": "OH"
  },
  {
    "zip": 45052,
    "lat": 39.1448,
    "lng": -84.77923,
    "state_id": "OH"
  },
  {
    "zip": 45053,
    "lat": 39.35251,
    "lng": -84.78054,
    "state_id": "OH"
  },
  {
    "zip": 45054,
    "lat": 39.44055,
    "lng": -84.07497,
    "state_id": "OH"
  },
  {
    "zip": 45056,
    "lat": 39.49069,
    "lng": -84.74361,
    "state_id": "OH"
  },
  {
    "zip": 45062,
    "lat": 39.47972,
    "lng": -84.55304,
    "state_id": "OH"
  },
  {
    "zip": 45064,
    "lat": 39.56619,
    "lng": -84.60077,
    "state_id": "OH"
  },
  {
    "zip": 45065,
    "lat": 39.37086,
    "lng": -84.21025,
    "state_id": "OH"
  },
  {
    "zip": 45066,
    "lat": 39.54906,
    "lng": -84.22313,
    "state_id": "OH"
  },
  {
    "zip": 45067,
    "lat": 39.48775,
    "lng": -84.47871,
    "state_id": "OH"
  },
  {
    "zip": 45068,
    "lat": 39.52618,
    "lng": -84.06357,
    "state_id": "OH"
  },
  {
    "zip": 45069,
    "lat": 39.34239,
    "lng": -84.41141,
    "state_id": "OH"
  },
  {
    "zip": 45070,
    "lat": 39.5897,
    "lng": -84.55807,
    "state_id": "OH"
  },
  {
    "zip": 45101,
    "lat": 38.69055,
    "lng": -83.74129,
    "state_id": "OH"
  },
  {
    "zip": 45102,
    "lat": 39.01712,
    "lng": -84.20427,
    "state_id": "OH"
  },
  {
    "zip": 45103,
    "lat": 39.09753,
    "lng": -84.13767,
    "state_id": "OH"
  },
  {
    "zip": 45106,
    "lat": 38.94492,
    "lng": -84.07145,
    "state_id": "OH"
  },
  {
    "zip": 45107,
    "lat": 39.29264,
    "lng": -83.96611,
    "state_id": "OH"
  },
  {
    "zip": 45111,
    "lat": 39.19634,
    "lng": -84.29021,
    "state_id": "OH"
  },
  {
    "zip": 45112,
    "lat": 38.7934,
    "lng": -84.14021,
    "state_id": "OH"
  },
  {
    "zip": 45113,
    "lat": 39.39718,
    "lng": -83.98502,
    "state_id": "OH"
  },
  {
    "zip": 45115,
    "lat": 38.82411,
    "lng": -83.69909,
    "state_id": "OH"
  },
  {
    "zip": 45118,
    "lat": 39.17028,
    "lng": -83.93325,
    "state_id": "OH"
  },
  {
    "zip": 45120,
    "lat": 38.82223,
    "lng": -84.0896,
    "state_id": "OH"
  },
  {
    "zip": 45121,
    "lat": 38.87002,
    "lng": -83.90363,
    "state_id": "OH"
  },
  {
    "zip": 45122,
    "lat": 39.22034,
    "lng": -84.11621,
    "state_id": "OH"
  },
  {
    "zip": 45123,
    "lat": 39.35023,
    "lng": -83.38582,
    "state_id": "OH"
  },
  {
    "zip": 45130,
    "lat": 38.90948,
    "lng": -83.99864,
    "state_id": "OH"
  },
  {
    "zip": 45131,
    "lat": 38.79011,
    "lng": -83.9666,
    "state_id": "OH"
  },
  {
    "zip": 45132,
    "lat": 39.34391,
    "lng": -83.60031,
    "state_id": "OH"
  },
  {
    "zip": 45133,
    "lat": 39.15726,
    "lng": -83.57603,
    "state_id": "OH"
  },
  {
    "zip": 45135,
    "lat": 39.34607,
    "lng": -83.54426,
    "state_id": "OH"
  },
  {
    "zip": 45140,
    "lat": 39.25758,
    "lng": -84.24167,
    "state_id": "OH"
  },
  {
    "zip": 45142,
    "lat": 39.20569,
    "lng": -83.8126,
    "state_id": "OH"
  },
  {
    "zip": 45144,
    "lat": 38.7056,
    "lng": -83.61289,
    "state_id": "OH"
  },
  {
    "zip": 45146,
    "lat": 39.31512,
    "lng": -83.79828,
    "state_id": "OH"
  },
  {
    "zip": 45147,
    "lat": 39.20667,
    "lng": -84.28889,
    "state_id": "OH"
  },
  {
    "zip": 45148,
    "lat": 39.28715,
    "lng": -83.88347,
    "state_id": "OH"
  },
  {
    "zip": 45150,
    "lat": 39.1657,
    "lng": -84.233,
    "state_id": "OH"
  },
  {
    "zip": 45152,
    "lat": 39.35058,
    "lng": -84.12168,
    "state_id": "OH"
  },
  {
    "zip": 45153,
    "lat": 38.86869,
    "lng": -84.18496,
    "state_id": "OH"
  },
  {
    "zip": 45154,
    "lat": 39.05949,
    "lng": -83.91926,
    "state_id": "OH"
  },
  {
    "zip": 45155,
    "lat": 39.03989,
    "lng": -83.75158,
    "state_id": "OH"
  },
  {
    "zip": 45156,
    "lat": 38.81472,
    "lng": -84.2154,
    "state_id": "OH"
  },
  {
    "zip": 45157,
    "lat": 38.95651,
    "lng": -84.22984,
    "state_id": "OH"
  },
  {
    "zip": 45158,
    "lat": 39.18114,
    "lng": -84.08991,
    "state_id": "OH"
  },
  {
    "zip": 45159,
    "lat": 39.33069,
    "lng": -83.68921,
    "state_id": "OH"
  },
  {
    "zip": 45160,
    "lat": 39.123,
    "lng": -84.13673,
    "state_id": "OH"
  },
  {
    "zip": 45162,
    "lat": 39.26783,
    "lng": -84.07828,
    "state_id": "OH"
  },
  {
    "zip": 45164,
    "lat": 39.55406,
    "lng": -83.78264,
    "state_id": "OH"
  },
  {
    "zip": 45166,
    "lat": 39.4766,
    "lng": -83.68776,
    "state_id": "OH"
  },
  {
    "zip": 45167,
    "lat": 38.77283,
    "lng": -83.80059,
    "state_id": "OH"
  },
  {
    "zip": 45168,
    "lat": 38.84488,
    "lng": -83.75573,
    "state_id": "OH"
  },
  {
    "zip": 45169,
    "lat": 39.50061,
    "lng": -83.64981,
    "state_id": "OH"
  },
  {
    "zip": 45171,
    "lat": 39.01455,
    "lng": -83.79884,
    "state_id": "OH"
  },
  {
    "zip": 45172,
    "lat": 39.07447,
    "lng": -83.38688,
    "state_id": "OH"
  },
  {
    "zip": 45174,
    "lat": 39.15887,
    "lng": -84.31054,
    "state_id": "OH"
  },
  {
    "zip": 45176,
    "lat": 39.08341,
    "lng": -84.02315,
    "state_id": "OH"
  },
  {
    "zip": 45177,
    "lat": 39.46013,
    "lng": -83.83716,
    "state_id": "OH"
  },
  {
    "zip": 45202,
    "lat": 39.10893,
    "lng": -84.50253,
    "state_id": "OH"
  },
  {
    "zip": 45203,
    "lat": 39.10428,
    "lng": -84.53355,
    "state_id": "OH"
  },
  {
    "zip": 45204,
    "lat": 39.09636,
    "lng": -84.57161,
    "state_id": "OH"
  },
  {
    "zip": 45205,
    "lat": 39.11007,
    "lng": -84.57523,
    "state_id": "OH"
  },
  {
    "zip": 45206,
    "lat": 39.12738,
    "lng": -84.48436,
    "state_id": "OH"
  },
  {
    "zip": 45207,
    "lat": 39.14203,
    "lng": -84.47135,
    "state_id": "OH"
  },
  {
    "zip": 45208,
    "lat": 39.13475,
    "lng": -84.43453,
    "state_id": "OH"
  },
  {
    "zip": 45209,
    "lat": 39.15304,
    "lng": -84.42651,
    "state_id": "OH"
  },
  {
    "zip": 45211,
    "lat": 39.15629,
    "lng": -84.59823,
    "state_id": "OH"
  },
  {
    "zip": 45212,
    "lat": 39.16413,
    "lng": -84.4523,
    "state_id": "OH"
  },
  {
    "zip": 45213,
    "lat": 39.18076,
    "lng": -84.42008,
    "state_id": "OH"
  },
  {
    "zip": 45214,
    "lat": 39.1226,
    "lng": -84.54458,
    "state_id": "OH"
  },
  {
    "zip": 45215,
    "lat": 39.2353,
    "lng": -84.46211,
    "state_id": "OH"
  },
  {
    "zip": 45216,
    "lat": 39.2009,
    "lng": -84.48165,
    "state_id": "OH"
  },
  {
    "zip": 45217,
    "lat": 39.16767,
    "lng": -84.49728,
    "state_id": "OH"
  },
  {
    "zip": 45218,
    "lat": 39.26642,
    "lng": -84.52227,
    "state_id": "OH"
  },
  {
    "zip": 45219,
    "lat": 39.12733,
    "lng": -84.513,
    "state_id": "OH"
  },
  {
    "zip": 45220,
    "lat": 39.14854,
    "lng": -84.52071,
    "state_id": "OH"
  },
  {
    "zip": 45223,
    "lat": 39.17123,
    "lng": -84.54898,
    "state_id": "OH"
  },
  {
    "zip": 45224,
    "lat": 39.20099,
    "lng": -84.53151,
    "state_id": "OH"
  },
  {
    "zip": 45225,
    "lat": 39.14307,
    "lng": -84.55211,
    "state_id": "OH"
  },
  {
    "zip": 45226,
    "lat": 39.11129,
    "lng": -84.42002,
    "state_id": "OH"
  },
  {
    "zip": 45227,
    "lat": 39.15384,
    "lng": -84.38531,
    "state_id": "OH"
  },
  {
    "zip": 45229,
    "lat": 39.15266,
    "lng": -84.48688,
    "state_id": "OH"
  },
  {
    "zip": 45230,
    "lat": 39.07434,
    "lng": -84.39088,
    "state_id": "OH"
  },
  {
    "zip": 45231,
    "lat": 39.24541,
    "lng": -84.53788,
    "state_id": "OH"
  },
  {
    "zip": 45232,
    "lat": 39.18239,
    "lng": -84.51104,
    "state_id": "OH"
  },
  {
    "zip": 45233,
    "lat": 39.11841,
    "lng": -84.66579,
    "state_id": "OH"
  },
  {
    "zip": 45236,
    "lat": 39.20981,
    "lng": -84.3973,
    "state_id": "OH"
  },
  {
    "zip": 45237,
    "lat": 39.19306,
    "lng": -84.45233,
    "state_id": "OH"
  },
  {
    "zip": 45238,
    "lat": 39.10914,
    "lng": -84.61122,
    "state_id": "OH"
  },
  {
    "zip": 45239,
    "lat": 39.20446,
    "lng": -84.57993,
    "state_id": "OH"
  },
  {
    "zip": 45240,
    "lat": 39.28513,
    "lng": -84.52883,
    "state_id": "OH"
  },
  {
    "zip": 45241,
    "lat": 39.27708,
    "lng": -84.40193,
    "state_id": "OH"
  },
  {
    "zip": 45242,
    "lat": 39.24362,
    "lng": -84.35637,
    "state_id": "OH"
  },
  {
    "zip": 45243,
    "lat": 39.18379,
    "lng": -84.34177,
    "state_id": "OH"
  },
  {
    "zip": 45244,
    "lat": 39.11987,
    "lng": -84.32747,
    "state_id": "OH"
  },
  {
    "zip": 45245,
    "lat": 39.06272,
    "lng": -84.27533,
    "state_id": "OH"
  },
  {
    "zip": 45246,
    "lat": 39.28963,
    "lng": -84.46692,
    "state_id": "OH"
  },
  {
    "zip": 45247,
    "lat": 39.22036,
    "lng": -84.6516,
    "state_id": "OH"
  },
  {
    "zip": 45248,
    "lat": 39.16433,
    "lng": -84.66255,
    "state_id": "OH"
  },
  {
    "zip": 45249,
    "lat": 39.27545,
    "lng": -84.32799,
    "state_id": "OH"
  },
  {
    "zip": 45251,
    "lat": 39.26809,
    "lng": -84.59383,
    "state_id": "OH"
  },
  {
    "zip": 45252,
    "lat": 39.27347,
    "lng": -84.63214,
    "state_id": "OH"
  },
  {
    "zip": 45255,
    "lat": 39.05885,
    "lng": -84.32711,
    "state_id": "OH"
  },
  {
    "zip": 45301,
    "lat": 39.71146,
    "lng": -84.02204,
    "state_id": "OH"
  },
  {
    "zip": 45302,
    "lat": 40.40228,
    "lng": -84.20383,
    "state_id": "OH"
  },
  {
    "zip": 45303,
    "lat": 40.21593,
    "lng": -84.65781,
    "state_id": "OH"
  },
  {
    "zip": 45304,
    "lat": 39.9888,
    "lng": -84.53121,
    "state_id": "OH"
  },
  {
    "zip": 45305,
    "lat": 39.63474,
    "lng": -84.07969,
    "state_id": "OH"
  },
  {
    "zip": 45306,
    "lat": 40.45819,
    "lng": -84.18727,
    "state_id": "OH"
  },
  {
    "zip": 45307,
    "lat": 39.58066,
    "lng": -83.72337,
    "state_id": "OH"
  },
  {
    "zip": 45308,
    "lat": 40.12549,
    "lng": -84.4574,
    "state_id": "OH"
  },
  {
    "zip": 45309,
    "lat": 39.84263,
    "lng": -84.42149,
    "state_id": "OH"
  },
  {
    "zip": 45310,
    "lat": 40.35307,
    "lng": -84.64248,
    "state_id": "OH"
  },
  {
    "zip": 45311,
    "lat": 39.62693,
    "lng": -84.6671,
    "state_id": "OH"
  },
  {
    "zip": 45312,
    "lat": 40.06026,
    "lng": -84.08027,
    "state_id": "OH"
  },
  {
    "zip": 45314,
    "lat": 39.74698,
    "lng": -83.77573,
    "state_id": "OH"
  },
  {
    "zip": 45315,
    "lat": 39.85699,
    "lng": -84.33909,
    "state_id": "OH"
  },
  {
    "zip": 45316,
    "lat": 39.79715,
    "lng": -83.82576,
    "state_id": "OH"
  },
  {
    "zip": 45317,
    "lat": 40.17487,
    "lng": -84.01914,
    "state_id": "OH"
  },
  {
    "zip": 45318,
    "lat": 40.12926,
    "lng": -84.35344,
    "state_id": "OH"
  },
  {
    "zip": 45319,
    "lat": 39.91613,
    "lng": -83.94321,
    "state_id": "OH"
  },
  {
    "zip": 45320,
    "lat": 39.74915,
    "lng": -84.68804,
    "state_id": "OH"
  },
  {
    "zip": 45321,
    "lat": 39.8735,
    "lng": -84.67821,
    "state_id": "OH"
  },
  {
    "zip": 45322,
    "lat": 39.89787,
    "lng": -84.32868,
    "state_id": "OH"
  },
  {
    "zip": 45323,
    "lat": 39.84989,
    "lng": -83.92759,
    "state_id": "OH"
  },
  {
    "zip": 45324,
    "lat": 39.82283,
    "lng": -83.99406,
    "state_id": "OH"
  },
  {
    "zip": 45325,
    "lat": 39.69126,
    "lng": -84.42457,
    "state_id": "OH"
  },
  {
    "zip": 45326,
    "lat": 40.14271,
    "lng": -84.09287,
    "state_id": "OH"
  },
  {
    "zip": 45327,
    "lat": 39.63251,
    "lng": -84.39985,
    "state_id": "OH"
  },
  {
    "zip": 45328,
    "lat": 40.116,
    "lng": -84.49651,
    "state_id": "OH"
  },
  {
    "zip": 45330,
    "lat": 39.64831,
    "lng": -84.52812,
    "state_id": "OH"
  },
  {
    "zip": 45331,
    "lat": 40.09787,
    "lng": -84.64631,
    "state_id": "OH"
  },
  {
    "zip": 45332,
    "lat": 39.99702,
    "lng": -84.78665,
    "state_id": "OH"
  },
  {
    "zip": 45333,
    "lat": 40.2476,
    "lng": -84.33283,
    "state_id": "OH"
  },
  {
    "zip": 45334,
    "lat": 40.44538,
    "lng": -84.04431,
    "state_id": "OH"
  },
  {
    "zip": 45335,
    "lat": 39.63032,
    "lng": -83.73381,
    "state_id": "OH"
  },
  {
    "zip": 45336,
    "lat": 40.44015,
    "lng": -84.26137,
    "state_id": "OH"
  },
  {
    "zip": 45337,
    "lat": 39.97988,
    "lng": -84.42057,
    "state_id": "OH"
  },
  {
    "zip": 45338,
    "lat": 39.84999,
    "lng": -84.54359,
    "state_id": "OH"
  },
  {
    "zip": 45339,
    "lat": 40.00916,
    "lng": -84.3425,
    "state_id": "OH"
  },
  {
    "zip": 45340,
    "lat": 40.37192,
    "lng": -84.04744,
    "state_id": "OH"
  },
  {
    "zip": 45341,
    "lat": 39.87673,
    "lng": -84.02331,
    "state_id": "OH"
  },
  {
    "zip": 45342,
    "lat": 39.62983,
    "lng": -84.27635,
    "state_id": "OH"
  },
  {
    "zip": 45344,
    "lat": 39.95646,
    "lng": -84.00956,
    "state_id": "OH"
  },
  {
    "zip": 45345,
    "lat": 39.73786,
    "lng": -84.40749,
    "state_id": "OH"
  },
  {
    "zip": 45346,
    "lat": 39.98073,
    "lng": -84.7113,
    "state_id": "OH"
  },
  {
    "zip": 45347,
    "lat": 39.88231,
    "lng": -84.76263,
    "state_id": "OH"
  },
  {
    "zip": 45348,
    "lat": 40.32806,
    "lng": -84.65193,
    "state_id": "OH"
  },
  {
    "zip": 45349,
    "lat": 39.98914,
    "lng": -83.94135,
    "state_id": "OH"
  },
  {
    "zip": 45350,
    "lat": 40.32442,
    "lng": -84.57367,
    "state_id": "OH"
  },
  {
    "zip": 45351,
    "lat": 40.33941,
    "lng": -84.4952,
    "state_id": "OH"
  },
  {
    "zip": 45352,
    "lat": 40.05016,
    "lng": -84.74424,
    "state_id": "OH"
  },
  {
    "zip": 45353,
    "lat": 40.29252,
    "lng": -84.04274,
    "state_id": "OH"
  },
  {
    "zip": 45354,
    "lat": 39.91054,
    "lng": -84.39968,
    "state_id": "OH"
  },
  {
    "zip": 45356,
    "lat": 40.16088,
    "lng": -84.23154,
    "state_id": "OH"
  },
  {
    "zip": 45358,
    "lat": 39.98659,
    "lng": -84.48777,
    "state_id": "OH"
  },
  {
    "zip": 45359,
    "lat": 40.05141,
    "lng": -84.35256,
    "state_id": "OH"
  },
  {
    "zip": 45360,
    "lat": 40.33063,
    "lng": -84.09166,
    "state_id": "OH"
  },
  {
    "zip": 45361,
    "lat": 39.96374,
    "lng": -84.41484,
    "state_id": "OH"
  },
  {
    "zip": 45362,
    "lat": 40.28677,
    "lng": -84.6357,
    "state_id": "OH"
  },
  {
    "zip": 45363,
    "lat": 40.24047,
    "lng": -84.39892,
    "state_id": "OH"
  },
  {
    "zip": 45365,
    "lat": 40.28424,
    "lng": -84.15953,
    "state_id": "OH"
  },
  {
    "zip": 45368,
    "lat": 39.84329,
    "lng": -83.65488,
    "state_id": "OH"
  },
  {
    "zip": 45369,
    "lat": 39.95302,
    "lng": -83.6043,
    "state_id": "OH"
  },
  {
    "zip": 45370,
    "lat": 39.60729,
    "lng": -84.02839,
    "state_id": "OH"
  },
  {
    "zip": 45371,
    "lat": 39.94036,
    "lng": -84.16255,
    "state_id": "OH"
  },
  {
    "zip": 45372,
    "lat": 40.01472,
    "lng": -83.83862,
    "state_id": "OH"
  },
  {
    "zip": 45373,
    "lat": 40.03218,
    "lng": -84.19478,
    "state_id": "OH"
  },
  {
    "zip": 45377,
    "lat": 39.89645,
    "lng": -84.22334,
    "state_id": "OH"
  },
  {
    "zip": 45378,
    "lat": 39.90288,
    "lng": -84.48874,
    "state_id": "OH"
  },
  {
    "zip": 45380,
    "lat": 40.24335,
    "lng": -84.51429,
    "state_id": "OH"
  },
  {
    "zip": 45381,
    "lat": 39.73152,
    "lng": -84.52454,
    "state_id": "OH"
  },
  {
    "zip": 45382,
    "lat": 39.90036,
    "lng": -84.61347,
    "state_id": "OH"
  },
  {
    "zip": 45383,
    "lat": 39.95621,
    "lng": -84.33772,
    "state_id": "OH"
  },
  {
    "zip": 45384,
    "lat": 39.71532,
    "lng": -83.88486,
    "state_id": "OH"
  },
  {
    "zip": 45385,
    "lat": 39.6698,
    "lng": -83.91316,
    "state_id": "OH"
  },
  {
    "zip": 45387,
    "lat": 39.79806,
    "lng": -83.88693,
    "state_id": "OH"
  },
  {
    "zip": 45388,
    "lat": 40.32643,
    "lng": -84.4819,
    "state_id": "OH"
  },
  {
    "zip": 45389,
    "lat": 40.05723,
    "lng": -84.02755,
    "state_id": "OH"
  },
  {
    "zip": 45390,
    "lat": 40.21875,
    "lng": -84.76887,
    "state_id": "OH"
  },
  {
    "zip": 45402,
    "lat": 39.75954,
    "lng": -84.21002,
    "state_id": "OH"
  },
  {
    "zip": 45403,
    "lat": 39.76874,
    "lng": -84.14776,
    "state_id": "OH"
  },
  {
    "zip": 45404,
    "lat": 39.79057,
    "lng": -84.16168,
    "state_id": "OH"
  },
  {
    "zip": 45405,
    "lat": 39.79241,
    "lng": -84.21588,
    "state_id": "OH"
  },
  {
    "zip": 45406,
    "lat": 39.78436,
    "lng": -84.24168,
    "state_id": "OH"
  },
  {
    "zip": 45409,
    "lat": 39.72428,
    "lng": -84.18758,
    "state_id": "OH"
  },
  {
    "zip": 45410,
    "lat": 39.74802,
    "lng": -84.15809,
    "state_id": "OH"
  },
  {
    "zip": 45414,
    "lat": 39.84254,
    "lng": -84.21243,
    "state_id": "OH"
  },
  {
    "zip": 45415,
    "lat": 39.83457,
    "lng": -84.25698,
    "state_id": "OH"
  },
  {
    "zip": 45416,
    "lat": 39.80717,
    "lng": -84.25801,
    "state_id": "OH"
  },
  {
    "zip": 45417,
    "lat": 39.73592,
    "lng": -84.28564,
    "state_id": "OH"
  },
  {
    "zip": 45419,
    "lat": 39.71342,
    "lng": -84.16718,
    "state_id": "OH"
  },
  {
    "zip": 45420,
    "lat": 39.71954,
    "lng": -84.13056,
    "state_id": "OH"
  },
  {
    "zip": 45424,
    "lat": 39.84675,
    "lng": -84.11121,
    "state_id": "OH"
  },
  {
    "zip": 45426,
    "lat": 39.80178,
    "lng": -84.31473,
    "state_id": "OH"
  },
  {
    "zip": 45428,
    "lat": 39.74293,
    "lng": -84.26068,
    "state_id": "OH"
  },
  {
    "zip": 45429,
    "lat": 39.68478,
    "lng": -84.16093,
    "state_id": "OH"
  },
  {
    "zip": 45430,
    "lat": 39.71287,
    "lng": -84.08443,
    "state_id": "OH"
  },
  {
    "zip": 45431,
    "lat": 39.76453,
    "lng": -84.07957,
    "state_id": "OH"
  },
  {
    "zip": 45432,
    "lat": 39.73905,
    "lng": -84.08565,
    "state_id": "OH"
  },
  {
    "zip": 45433,
    "lat": 39.81256,
    "lng": -84.05835,
    "state_id": "OH"
  },
  {
    "zip": 45434,
    "lat": 39.72158,
    "lng": -84.02895,
    "state_id": "OH"
  },
  {
    "zip": 45439,
    "lat": 39.70098,
    "lng": -84.21871,
    "state_id": "OH"
  },
  {
    "zip": 45440,
    "lat": 39.674,
    "lng": -84.09859,
    "state_id": "OH"
  },
  {
    "zip": 45449,
    "lat": 39.66467,
    "lng": -84.24134,
    "state_id": "OH"
  },
  {
    "zip": 45458,
    "lat": 39.60072,
    "lng": -84.15813,
    "state_id": "OH"
  },
  {
    "zip": 45459,
    "lat": 39.6469,
    "lng": -84.16955,
    "state_id": "OH"
  },
  {
    "zip": 45502,
    "lat": 39.92831,
    "lng": -83.81837,
    "state_id": "OH"
  },
  {
    "zip": 45503,
    "lat": 39.96015,
    "lng": -83.77234,
    "state_id": "OH"
  },
  {
    "zip": 45504,
    "lat": 39.94925,
    "lng": -83.86602,
    "state_id": "OH"
  },
  {
    "zip": 45505,
    "lat": 39.90692,
    "lng": -83.7629,
    "state_id": "OH"
  },
  {
    "zip": 45506,
    "lat": 39.90054,
    "lng": -83.85358,
    "state_id": "OH"
  },
  {
    "zip": 45601,
    "lat": 39.31024,
    "lng": -82.95564,
    "state_id": "OH"
  },
  {
    "zip": 45612,
    "lat": 39.21586,
    "lng": -83.27254,
    "state_id": "OH"
  },
  {
    "zip": 45613,
    "lat": 39.03318,
    "lng": -82.85138,
    "state_id": "OH"
  },
  {
    "zip": 45614,
    "lat": 38.92975,
    "lng": -82.27709,
    "state_id": "OH"
  },
  {
    "zip": 45616,
    "lat": 38.75333,
    "lng": -83.3076,
    "state_id": "OH"
  },
  {
    "zip": 45617,
    "lat": 39.2797,
    "lng": -83.15589,
    "state_id": "OH"
  },
  {
    "zip": 45618,
    "lat": 38.88556,
    "lng": -83.61638,
    "state_id": "OH"
  },
  {
    "zip": 45619,
    "lat": 38.47362,
    "lng": -82.45037,
    "state_id": "OH"
  },
  {
    "zip": 45620,
    "lat": 38.95777,
    "lng": -82.13982,
    "state_id": "OH"
  },
  {
    "zip": 45621,
    "lat": 39.11071,
    "lng": -82.60761,
    "state_id": "OH"
  },
  {
    "zip": 45622,
    "lat": 39.37557,
    "lng": -82.49937,
    "state_id": "OH"
  },
  {
    "zip": 45623,
    "lat": 38.6415,
    "lng": -82.26784,
    "state_id": "OH"
  },
  {
    "zip": 45624,
    "lat": 39.17024,
    "lng": -83.3469,
    "state_id": "OH"
  },
  {
    "zip": 45628,
    "lat": 39.39099,
    "lng": -83.19805,
    "state_id": "OH"
  },
  {
    "zip": 45629,
    "lat": 38.64971,
    "lng": -82.81286,
    "state_id": "OH"
  },
  {
    "zip": 45630,
    "lat": 38.69916,
    "lng": -83.09141,
    "state_id": "OH"
  },
  {
    "zip": 45631,
    "lat": 38.81774,
    "lng": -82.25553,
    "state_id": "OH"
  },
  {
    "zip": 45634,
    "lat": 39.17982,
    "lng": -82.48627,
    "state_id": "OH"
  },
  {
    "zip": 45636,
    "lat": 38.58725,
    "lng": -82.82521,
    "state_id": "OH"
  },
  {
    "zip": 45638,
    "lat": 38.56184,
    "lng": -82.67799,
    "state_id": "OH"
  },
  {
    "zip": 45640,
    "lat": 39.02198,
    "lng": -82.65363,
    "state_id": "OH"
  },
  {
    "zip": 45642,
    "lat": 39.04632,
    "lng": -83.05292,
    "state_id": "OH"
  },
  {
    "zip": 45644,
    "lat": 39.4532,
    "lng": -82.84153,
    "state_id": "OH"
  },
  {
    "zip": 45645,
    "lat": 38.56988,
    "lng": -82.53159,
    "state_id": "OH"
  },
  {
    "zip": 45646,
    "lat": 39.07677,
    "lng": -83.3229,
    "state_id": "OH"
  },
  {
    "zip": 45647,
    "lat": 39.29437,
    "lng": -82.73775,
    "state_id": "OH"
  },
  {
    "zip": 45648,
    "lat": 38.92058,
    "lng": -83.0151,
    "state_id": "OH"
  },
  {
    "zip": 45650,
    "lat": 38.74441,
    "lng": -83.42086,
    "state_id": "OH"
  },
  {
    "zip": 45651,
    "lat": 39.2787,
    "lng": -82.48881,
    "state_id": "OH"
  },
  {
    "zip": 45652,
    "lat": 38.83144,
    "lng": -83.08477,
    "state_id": "OH"
  },
  {
    "zip": 45653,
    "lat": 38.89155,
    "lng": -82.8231,
    "state_id": "OH"
  },
  {
    "zip": 45654,
    "lat": 39.3666,
    "lng": -82.3821,
    "state_id": "OH"
  },
  {
    "zip": 45656,
    "lat": 38.87587,
    "lng": -82.59256,
    "state_id": "OH"
  },
  {
    "zip": 45657,
    "lat": 38.85279,
    "lng": -83.2142,
    "state_id": "OH"
  },
  {
    "zip": 45658,
    "lat": 38.7621,
    "lng": -82.41127,
    "state_id": "OH"
  },
  {
    "zip": 45659,
    "lat": 38.67088,
    "lng": -82.62351,
    "state_id": "OH"
  },
  {
    "zip": 45660,
    "lat": 38.98646,
    "lng": -83.3612,
    "state_id": "OH"
  },
  {
    "zip": 45661,
    "lat": 39.04219,
    "lng": -83.10695,
    "state_id": "OH"
  },
  {
    "zip": 45662,
    "lat": 38.78775,
    "lng": -82.92046,
    "state_id": "OH"
  },
  {
    "zip": 45663,
    "lat": 38.74781,
    "lng": -83.10379,
    "state_id": "OH"
  },
  {
    "zip": 45669,
    "lat": 38.49429,
    "lng": -82.35534,
    "state_id": "OH"
  },
  {
    "zip": 45671,
    "lat": 38.96821,
    "lng": -83.24146,
    "state_id": "OH"
  },
  {
    "zip": 45672,
    "lat": 39.20161,
    "lng": -82.68342,
    "state_id": "OH"
  },
  {
    "zip": 45673,
    "lat": 39.20394,
    "lng": -82.81214,
    "state_id": "OH"
  },
  {
    "zip": 45674,
    "lat": 38.88163,
    "lng": -82.37329,
    "state_id": "OH"
  },
  {
    "zip": 45678,
    "lat": 38.60103,
    "lng": -82.3772,
    "state_id": "OH"
  },
  {
    "zip": 45679,
    "lat": 38.97536,
    "lng": -83.56372,
    "state_id": "OH"
  },
  {
    "zip": 45680,
    "lat": 38.45479,
    "lng": -82.54513,
    "state_id": "OH"
  },
  {
    "zip": 45681,
    "lat": 39.30598,
    "lng": -83.25186,
    "state_id": "OH"
  },
  {
    "zip": 45682,
    "lat": 38.80766,
    "lng": -82.6944,
    "state_id": "OH"
  },
  {
    "zip": 45684,
    "lat": 38.67318,
    "lng": -83.27352,
    "state_id": "OH"
  },
  {
    "zip": 45685,
    "lat": 38.93037,
    "lng": -82.44968,
    "state_id": "OH"
  },
  {
    "zip": 45686,
    "lat": 39.01095,
    "lng": -82.37191,
    "state_id": "OH"
  },
  {
    "zip": 45688,
    "lat": 38.72999,
    "lng": -82.53036,
    "state_id": "OH"
  },
  {
    "zip": 45690,
    "lat": 39.13139,
    "lng": -83.01686,
    "state_id": "OH"
  },
  {
    "zip": 45692,
    "lat": 39.11457,
    "lng": -82.54062,
    "state_id": "OH"
  },
  {
    "zip": 45693,
    "lat": 38.81009,
    "lng": -83.53667,
    "state_id": "OH"
  },
  {
    "zip": 45694,
    "lat": 38.74733,
    "lng": -82.78221,
    "state_id": "OH"
  },
  {
    "zip": 45695,
    "lat": 39.15942,
    "lng": -82.36917,
    "state_id": "OH"
  },
  {
    "zip": 45696,
    "lat": 38.60592,
    "lng": -82.45037,
    "state_id": "OH"
  },
  {
    "zip": 45697,
    "lat": 38.9339,
    "lng": -83.66522,
    "state_id": "OH"
  },
  {
    "zip": 45698,
    "lat": 39.28581,
    "lng": -82.39259,
    "state_id": "OH"
  },
  {
    "zip": 45701,
    "lat": 39.31358,
    "lng": -82.08214,
    "state_id": "OH"
  },
  {
    "zip": 45710,
    "lat": 39.19803,
    "lng": -82.22951,
    "state_id": "OH"
  },
  {
    "zip": 45711,
    "lat": 39.42452,
    "lng": -81.92993,
    "state_id": "OH"
  },
  {
    "zip": 45714,
    "lat": 39.31735,
    "lng": -81.61891,
    "state_id": "OH"
  },
  {
    "zip": 45715,
    "lat": 39.60457,
    "lng": -81.62985,
    "state_id": "OH"
  },
  {
    "zip": 45716,
    "lat": 39.46286,
    "lng": -82.18176,
    "state_id": "OH"
  },
  {
    "zip": 45719,
    "lat": 39.40007,
    "lng": -82.12608,
    "state_id": "OH"
  },
  {
    "zip": 45721,
    "lat": 39.56548,
    "lng": -81.58441,
    "state_id": "OH"
  },
  {
    "zip": 45723,
    "lat": 39.22297,
    "lng": -81.82739,
    "state_id": "OH"
  },
  {
    "zip": 45724,
    "lat": 39.38111,
    "lng": -81.79195,
    "state_id": "OH"
  },
  {
    "zip": 45727,
    "lat": 39.64399,
    "lng": -81.48482,
    "state_id": "OH"
  },
  {
    "zip": 45729,
    "lat": 39.41361,
    "lng": -81.60783,
    "state_id": "OH"
  },
  {
    "zip": 45732,
    "lat": 39.51519,
    "lng": -82.0673,
    "state_id": "OH"
  },
  {
    "zip": 45734,
    "lat": 39.63875,
    "lng": -81.18571,
    "state_id": "OH"
  },
  {
    "zip": 45735,
    "lat": 39.25475,
    "lng": -81.92457,
    "state_id": "OH"
  },
  {
    "zip": 45740,
    "lat": 39.47702,
    "lng": -82.07966,
    "state_id": "OH"
  },
  {
    "zip": 45741,
    "lat": 39.06483,
    "lng": -82.2523,
    "state_id": "OH"
  },
  {
    "zip": 45742,
    "lat": 39.2855,
    "lng": -81.72908,
    "state_id": "OH"
  },
  {
    "zip": 45743,
    "lat": 39.07618,
    "lng": -81.84989,
    "state_id": "OH"
  },
  {
    "zip": 45744,
    "lat": 39.53769,
    "lng": -81.50407,
    "state_id": "OH"
  },
  {
    "zip": 45745,
    "lat": 39.61622,
    "lng": -81.32979,
    "state_id": "OH"
  },
  {
    "zip": 45746,
    "lat": 39.60991,
    "lng": -81.45918,
    "state_id": "OH"
  },
  {
    "zip": 45750,
    "lat": 39.43136,
    "lng": -81.42219,
    "state_id": "OH"
  },
  {
    "zip": 45760,
    "lat": 39.01612,
    "lng": -82.12329,
    "state_id": "OH"
  },
  {
    "zip": 45761,
    "lat": 39.42651,
    "lng": -82.09102,
    "state_id": "OH"
  },
  {
    "zip": 45764,
    "lat": 39.44972,
    "lng": -82.24418,
    "state_id": "OH"
  },
  {
    "zip": 45766,
    "lat": 39.32282,
    "lng": -82.25938,
    "state_id": "OH"
  },
  {
    "zip": 45767,
    "lat": 39.54029,
    "lng": -81.13416,
    "state_id": "OH"
  },
  {
    "zip": 45768,
    "lat": 39.41031,
    "lng": -81.27091,
    "state_id": "OH"
  },
  {
    "zip": 45769,
    "lat": 39.10645,
    "lng": -82.04824,
    "state_id": "OH"
  },
  {
    "zip": 45770,
    "lat": 38.98347,
    "lng": -81.80049,
    "state_id": "OH"
  },
  {
    "zip": 45771,
    "lat": 38.98139,
    "lng": -81.89773,
    "state_id": "OH"
  },
  {
    "zip": 45772,
    "lat": 39.14242,
    "lng": -81.8252,
    "state_id": "OH"
  },
  {
    "zip": 45773,
    "lat": 39.4585,
    "lng": -81.26731,
    "state_id": "OH"
  },
  {
    "zip": 45775,
    "lat": 39.08289,
    "lng": -82.15426,
    "state_id": "OH"
  },
  {
    "zip": 45776,
    "lat": 39.18708,
    "lng": -82.02045,
    "state_id": "OH"
  },
  {
    "zip": 45778,
    "lat": 39.34711,
    "lng": -81.89314,
    "state_id": "OH"
  },
  {
    "zip": 45779,
    "lat": 38.99844,
    "lng": -81.9731,
    "state_id": "OH"
  },
  {
    "zip": 45780,
    "lat": 39.36636,
    "lng": -82.13108,
    "state_id": "OH"
  },
  {
    "zip": 45782,
    "lat": 39.48597,
    "lng": -82.07952,
    "state_id": "OH"
  },
  {
    "zip": 45784,
    "lat": 39.38717,
    "lng": -81.68722,
    "state_id": "OH"
  },
  {
    "zip": 45786,
    "lat": 39.50244,
    "lng": -81.65368,
    "state_id": "OH"
  },
  {
    "zip": 45787,
    "lat": 39.46919,
    "lng": -81.63163,
    "state_id": "OH"
  },
  {
    "zip": 45788,
    "lat": 39.51363,
    "lng": -81.36686,
    "state_id": "OH"
  },
  {
    "zip": 45789,
    "lat": 39.55155,
    "lng": -81.25683,
    "state_id": "OH"
  },
  {
    "zip": 45801,
    "lat": 40.77558,
    "lng": -84.03697,
    "state_id": "OH"
  },
  {
    "zip": 45804,
    "lat": 40.71467,
    "lng": -84.06633,
    "state_id": "OH"
  },
  {
    "zip": 45805,
    "lat": 40.72874,
    "lng": -84.16469,
    "state_id": "OH"
  },
  {
    "zip": 45806,
    "lat": 40.6752,
    "lng": -84.12437,
    "state_id": "OH"
  },
  {
    "zip": 45807,
    "lat": 40.80637,
    "lng": -84.17081,
    "state_id": "OH"
  },
  {
    "zip": 45808,
    "lat": 40.83163,
    "lng": -83.97232,
    "state_id": "OH"
  },
  {
    "zip": 45809,
    "lat": 40.84563,
    "lng": -84.18742,
    "state_id": "OH"
  },
  {
    "zip": 45810,
    "lat": 40.77679,
    "lng": -83.81205,
    "state_id": "OH"
  },
  {
    "zip": 45812,
    "lat": 40.68519,
    "lng": -83.80944,
    "state_id": "OH"
  },
  {
    "zip": 45813,
    "lat": 41.18986,
    "lng": -84.73112,
    "state_id": "OH"
  },
  {
    "zip": 45814,
    "lat": 40.9019,
    "lng": -83.61406,
    "state_id": "OH"
  },
  {
    "zip": 45816,
    "lat": 41.00446,
    "lng": -83.79401,
    "state_id": "OH"
  },
  {
    "zip": 45817,
    "lat": 40.87725,
    "lng": -83.88356,
    "state_id": "OH"
  },
  {
    "zip": 45819,
    "lat": 40.62408,
    "lng": -84.26038,
    "state_id": "OH"
  },
  {
    "zip": 45820,
    "lat": 40.8349,
    "lng": -84.08394,
    "state_id": "OH"
  },
  {
    "zip": 45821,
    "lat": 41.22627,
    "lng": -84.56425,
    "state_id": "OH"
  },
  {
    "zip": 45822,
    "lat": 40.55265,
    "lng": -84.59801,
    "state_id": "OH"
  },
  {
    "zip": 45826,
    "lat": 40.43575,
    "lng": -84.49344,
    "state_id": "OH"
  },
  {
    "zip": 45827,
    "lat": 41.0032,
    "lng": -84.30088,
    "state_id": "OH"
  },
  {
    "zip": 45828,
    "lat": 40.48671,
    "lng": -84.67532,
    "state_id": "OH"
  },
  {
    "zip": 45830,
    "lat": 40.90566,
    "lng": -84.09236,
    "state_id": "OH"
  },
  {
    "zip": 45831,
    "lat": 41.11002,
    "lng": -84.24957,
    "state_id": "OH"
  },
  {
    "zip": 45832,
    "lat": 40.92657,
    "lng": -84.73285,
    "state_id": "OH"
  },
  {
    "zip": 45833,
    "lat": 40.83401,
    "lng": -84.34917,
    "state_id": "OH"
  },
  {
    "zip": 45835,
    "lat": 40.75983,
    "lng": -83.70005,
    "state_id": "OH"
  },
  {
    "zip": 45836,
    "lat": 40.79266,
    "lng": -83.63982,
    "state_id": "OH"
  },
  {
    "zip": 45838,
    "lat": 40.74204,
    "lng": -84.47764,
    "state_id": "OH"
  },
  {
    "zip": 45840,
    "lat": 41.02537,
    "lng": -83.6521,
    "state_id": "OH"
  },
  {
    "zip": 45841,
    "lat": 40.87455,
    "lng": -83.73719,
    "state_id": "OH"
  },
  {
    "zip": 45843,
    "lat": 40.78156,
    "lng": -83.53505,
    "state_id": "OH"
  },
  {
    "zip": 45844,
    "lat": 40.91747,
    "lng": -84.27738,
    "state_id": "OH"
  },
  {
    "zip": 45845,
    "lat": 40.33031,
    "lng": -84.38174,
    "state_id": "OH"
  },
  {
    "zip": 45846,
    "lat": 40.40882,
    "lng": -84.74634,
    "state_id": "OH"
  },
  {
    "zip": 45849,
    "lat": 41.01385,
    "lng": -84.45338,
    "state_id": "OH"
  },
  {
    "zip": 45850,
    "lat": 40.70409,
    "lng": -83.91821,
    "state_id": "OH"
  },
  {
    "zip": 45851,
    "lat": 41.03234,
    "lng": -84.60004,
    "state_id": "OH"
  },
  {
    "zip": 45853,
    "lat": 40.98624,
    "lng": -84.19854,
    "state_id": "OH"
  },
  {
    "zip": 45854,
    "lat": 40.7578,
    "lng": -83.95175,
    "state_id": "OH"
  },
  {
    "zip": 45855,
    "lat": 41.08791,
    "lng": -84.5841,
    "state_id": "OH"
  },
  {
    "zip": 45856,
    "lat": 41.11526,
    "lng": -83.99906,
    "state_id": "OH"
  },
  {
    "zip": 45858,
    "lat": 41.10771,
    "lng": -83.80444,
    "state_id": "OH"
  },
  {
    "zip": 45859,
    "lat": 40.68372,
    "lng": -83.77862,
    "state_id": "OH"
  },
  {
    "zip": 45860,
    "lat": 40.394,
    "lng": -84.51341,
    "state_id": "OH"
  },
  {
    "zip": 45861,
    "lat": 41.08281,
    "lng": -84.42657,
    "state_id": "OH"
  },
  {
    "zip": 45862,
    "lat": 40.67074,
    "lng": -84.51455,
    "state_id": "OH"
  },
  {
    "zip": 45863,
    "lat": 40.90781,
    "lng": -84.44728,
    "state_id": "OH"
  },
  {
    "zip": 45864,
    "lat": 41.09936,
    "lng": -84.14086,
    "state_id": "OH"
  },
  {
    "zip": 45865,
    "lat": 40.39152,
    "lng": -84.36881,
    "state_id": "OH"
  },
  {
    "zip": 45866,
    "lat": 40.4914,
    "lng": -84.5516,
    "state_id": "OH"
  },
  {
    "zip": 45867,
    "lat": 40.89834,
    "lng": -83.53695,
    "state_id": "OH"
  },
  {
    "zip": 45868,
    "lat": 40.96628,
    "lng": -83.84966,
    "state_id": "OH"
  },
  {
    "zip": 45869,
    "lat": 40.4566,
    "lng": -84.39712,
    "state_id": "OH"
  },
  {
    "zip": 45870,
    "lat": 40.55626,
    "lng": -83.95144,
    "state_id": "OH"
  },
  {
    "zip": 45871,
    "lat": 40.49378,
    "lng": -84.29966,
    "state_id": "OH"
  },
  {
    "zip": 45872,
    "lat": 41.1887,
    "lng": -83.68573,
    "state_id": "OH"
  },
  {
    "zip": 45873,
    "lat": 41.11955,
    "lng": -84.39311,
    "state_id": "OH"
  },
  {
    "zip": 45874,
    "lat": 40.78698,
    "lng": -84.67286,
    "state_id": "OH"
  },
  {
    "zip": 45875,
    "lat": 41.02206,
    "lng": -84.06171,
    "state_id": "OH"
  },
  {
    "zip": 45876,
    "lat": 40.92728,
    "lng": -84.34131,
    "state_id": "OH"
  },
  {
    "zip": 45877,
    "lat": 40.9558,
    "lng": -83.93683,
    "state_id": "OH"
  },
  {
    "zip": 45879,
    "lat": 41.12849,
    "lng": -84.56158,
    "state_id": "OH"
  },
  {
    "zip": 45880,
    "lat": 41.0677,
    "lng": -84.7361,
    "state_id": "OH"
  },
  {
    "zip": 45881,
    "lat": 40.94952,
    "lng": -83.7756,
    "state_id": "OH"
  },
  {
    "zip": 45882,
    "lat": 40.67736,
    "lng": -84.67559,
    "state_id": "OH"
  },
  {
    "zip": 45883,
    "lat": 40.40709,
    "lng": -84.62852,
    "state_id": "OH"
  },
  {
    "zip": 45884,
    "lat": 40.55423,
    "lng": -84.08301,
    "state_id": "OH"
  },
  {
    "zip": 45885,
    "lat": 40.57147,
    "lng": -84.38858,
    "state_id": "OH"
  },
  {
    "zip": 45886,
    "lat": 40.99192,
    "lng": -84.61727,
    "state_id": "OH"
  },
  {
    "zip": 45887,
    "lat": 40.71001,
    "lng": -84.36403,
    "state_id": "OH"
  },
  {
    "zip": 45888,
    "lat": 40.60222,
    "lng": -84.08677,
    "state_id": "OH"
  },
  {
    "zip": 45889,
    "lat": 41.14664,
    "lng": -83.63997,
    "state_id": "OH"
  },
  {
    "zip": 45890,
    "lat": 40.97568,
    "lng": -83.48986,
    "state_id": "OH"
  },
  {
    "zip": 45891,
    "lat": 40.875,
    "lng": -84.57436,
    "state_id": "OH"
  },
  {
    "zip": 45894,
    "lat": 40.76519,
    "lng": -84.46509,
    "state_id": "OH"
  },
  {
    "zip": 45895,
    "lat": 40.57016,
    "lng": -84.1518,
    "state_id": "OH"
  },
  {
    "zip": 45896,
    "lat": 40.60422,
    "lng": -83.93137,
    "state_id": "OH"
  },
  {
    "zip": 45897,
    "lat": 40.8317,
    "lng": -83.65748,
    "state_id": "OH"
  },
  {
    "zip": 45898,
    "lat": 40.743,
    "lng": -84.75692,
    "state_id": "OH"
  },
  {
    "zip": 45899,
    "lat": 40.80055,
    "lng": -84.77461,
    "state_id": "OH"
  },
  {
    "zip": 73002,
    "lat": 34.94937,
    "lng": -97.731,
    "state_id": "OK"
  },
  {
    "zip": 73003,
    "lat": 35.66893,
    "lng": -97.49716,
    "state_id": "OK"
  },
  {
    "zip": 73004,
    "lat": 35.148,
    "lng": -97.84626,
    "state_id": "OK"
  },
  {
    "zip": 73005,
    "lat": 35.05783,
    "lng": -98.2382,
    "state_id": "OK"
  },
  {
    "zip": 73006,
    "lat": 34.90892,
    "lng": -98.40267,
    "state_id": "OK"
  },
  {
    "zip": 73007,
    "lat": 35.68863,
    "lng": -97.31856,
    "state_id": "OK"
  },
  {
    "zip": 73008,
    "lat": 35.51408,
    "lng": -97.64615,
    "state_id": "OK"
  },
  {
    "zip": 73009,
    "lat": 35.2861,
    "lng": -98.36362,
    "state_id": "OK"
  },
  {
    "zip": 73010,
    "lat": 35.10622,
    "lng": -97.67844,
    "state_id": "OK"
  },
  {
    "zip": 73011,
    "lat": 34.84444,
    "lng": -97.74985,
    "state_id": "OK"
  },
  {
    "zip": 73012,
    "lat": 35.66753,
    "lng": -97.59363,
    "state_id": "OK"
  },
  {
    "zip": 73013,
    "lat": 35.61946,
    "lng": -97.4812,
    "state_id": "OK"
  },
  {
    "zip": 73014,
    "lat": 35.58109,
    "lng": -98.14626,
    "state_id": "OK"
  },
  {
    "zip": 73015,
    "lat": 35.08056,
    "lng": -98.59537,
    "state_id": "OK"
  },
  {
    "zip": 73016,
    "lat": 35.79895,
    "lng": -97.6871,
    "state_id": "OK"
  },
  {
    "zip": 73017,
    "lat": 34.92621,
    "lng": -98.0938,
    "state_id": "OK"
  },
  {
    "zip": 73018,
    "lat": 35.05122,
    "lng": -97.94094,
    "state_id": "OK"
  },
  {
    "zip": 73019,
    "lat": 35.20606,
    "lng": -97.44358,
    "state_id": "OK"
  },
  {
    "zip": 73020,
    "lat": 35.45871,
    "lng": -97.26217,
    "state_id": "OK"
  },
  {
    "zip": 73021,
    "lat": 35.34249,
    "lng": -98.6622,
    "state_id": "OK"
  },
  {
    "zip": 73024,
    "lat": 35.35864,
    "lng": -98.7981,
    "state_id": "OK"
  },
  {
    "zip": 73025,
    "lat": 35.73235,
    "lng": -97.57274,
    "state_id": "OK"
  },
  {
    "zip": 73026,
    "lat": 35.23431,
    "lng": -97.27915,
    "state_id": "OK"
  },
  {
    "zip": 73027,
    "lat": 35.97276,
    "lng": -97.24019,
    "state_id": "OK"
  },
  {
    "zip": 73028,
    "lat": 35.97493,
    "lng": -97.62718,
    "state_id": "OK"
  },
  {
    "zip": 73029,
    "lat": 34.90561,
    "lng": -98.20637,
    "state_id": "OK"
  },
  {
    "zip": 73030,
    "lat": 34.47363,
    "lng": -97.17406,
    "state_id": "OK"
  },
  {
    "zip": 73032,
    "lat": 34.39865,
    "lng": -97.04676,
    "state_id": "OK"
  },
  {
    "zip": 73033,
    "lat": 35.31442,
    "lng": -98.54661,
    "state_id": "OK"
  },
  {
    "zip": 73034,
    "lat": 35.70187,
    "lng": -97.42552,
    "state_id": "OK"
  },
  {
    "zip": 73036,
    "lat": 35.50998,
    "lng": -97.95958,
    "state_id": "OK"
  },
  {
    "zip": 73038,
    "lat": 35.12205,
    "lng": -98.44596,
    "state_id": "OK"
  },
  {
    "zip": 73040,
    "lat": 35.64159,
    "lng": -98.37557,
    "state_id": "OK"
  },
  {
    "zip": 73041,
    "lat": 35.04283,
    "lng": -98.87662,
    "state_id": "OK"
  },
  {
    "zip": 73042,
    "lat": 35.22809,
    "lng": -98.25066,
    "state_id": "OK"
  },
  {
    "zip": 73043,
    "lat": 35.73424,
    "lng": -98.39719,
    "state_id": "OK"
  },
  {
    "zip": 73044,
    "lat": 35.86321,
    "lng": -97.42483,
    "state_id": "OK"
  },
  {
    "zip": 73045,
    "lat": 35.51214,
    "lng": -97.14369,
    "state_id": "OK"
  },
  {
    "zip": 73047,
    "lat": 35.45869,
    "lng": -98.31647,
    "state_id": "OK"
  },
  {
    "zip": 73048,
    "lat": 35.4877,
    "lng": -98.55686,
    "state_id": "OK"
  },
  {
    "zip": 73049,
    "lat": 35.58643,
    "lng": -97.30171,
    "state_id": "OK"
  },
  {
    "zip": 73050,
    "lat": 35.93872,
    "lng": -97.26385,
    "state_id": "OK"
  },
  {
    "zip": 73051,
    "lat": 35.03322,
    "lng": -97.24589,
    "state_id": "OK"
  },
  {
    "zip": 73052,
    "lat": 34.83152,
    "lng": -97.59585,
    "state_id": "OK"
  },
  {
    "zip": 73053,
    "lat": 35.36202,
    "lng": -98.41424,
    "state_id": "OK"
  },
  {
    "zip": 73054,
    "lat": 35.67307,
    "lng": -97.19165,
    "state_id": "OK"
  },
  {
    "zip": 73055,
    "lat": 34.6457,
    "lng": -97.91351,
    "state_id": "OK"
  },
  {
    "zip": 73056,
    "lat": 36.17071,
    "lng": -97.6241,
    "state_id": "OK"
  },
  {
    "zip": 73057,
    "lat": 34.83306,
    "lng": -97.43162,
    "state_id": "OK"
  },
  {
    "zip": 73058,
    "lat": 35.82065,
    "lng": -97.21824,
    "state_id": "OK"
  },
  {
    "zip": 73059,
    "lat": 35.31891,
    "lng": -98.04738,
    "state_id": "OK"
  },
  {
    "zip": 73061,
    "lat": 36.3488,
    "lng": -97.00648,
    "state_id": "OK"
  },
  {
    "zip": 73062,
    "lat": 35.04526,
    "lng": -98.74388,
    "state_id": "OK"
  },
  {
    "zip": 73063,
    "lat": 36.05348,
    "lng": -97.42386,
    "state_id": "OK"
  },
  {
    "zip": 73064,
    "lat": 35.37519,
    "lng": -97.74554,
    "state_id": "OK"
  },
  {
    "zip": 73065,
    "lat": 35.25211,
    "lng": -97.61209,
    "state_id": "OK"
  },
  {
    "zip": 73066,
    "lat": 35.50023,
    "lng": -97.32752,
    "state_id": "OK"
  },
  {
    "zip": 73067,
    "lat": 34.90313,
    "lng": -97.90822,
    "state_id": "OK"
  },
  {
    "zip": 73068,
    "lat": 35.13905,
    "lng": -97.28281,
    "state_id": "OK"
  },
  {
    "zip": 73069,
    "lat": 35.24963,
    "lng": -97.46292,
    "state_id": "OK"
  },
  {
    "zip": 73071,
    "lat": 35.23899,
    "lng": -97.41142,
    "state_id": "OK"
  },
  {
    "zip": 73072,
    "lat": 35.20826,
    "lng": -97.50317,
    "state_id": "OK"
  },
  {
    "zip": 73073,
    "lat": 36.15589,
    "lng": -97.40778,
    "state_id": "OK"
  },
  {
    "zip": 73074,
    "lat": 34.83027,
    "lng": -97.27979,
    "state_id": "OK"
  },
  {
    "zip": 73075,
    "lat": 34.75158,
    "lng": -97.23352,
    "state_id": "OK"
  },
  {
    "zip": 73077,
    "lat": 36.30486,
    "lng": -97.26678,
    "state_id": "OK"
  },
  {
    "zip": 73078,
    "lat": 35.67033,
    "lng": -97.75351,
    "state_id": "OK"
  },
  {
    "zip": 73079,
    "lat": 35.20623,
    "lng": -98.01554,
    "state_id": "OK"
  },
  {
    "zip": 73080,
    "lat": 35.00654,
    "lng": -97.46238,
    "state_id": "OK"
  },
  {
    "zip": 73082,
    "lat": 34.77591,
    "lng": -97.90323,
    "state_id": "OK"
  },
  {
    "zip": 73084,
    "lat": 35.52587,
    "lng": -97.34434,
    "state_id": "OK"
  },
  {
    "zip": 73086,
    "lat": 34.4838,
    "lng": -96.9662,
    "state_id": "OK"
  },
  {
    "zip": 73089,
    "lat": 35.26443,
    "lng": -97.79434,
    "state_id": "OK"
  },
  {
    "zip": 73090,
    "lat": 35.39706,
    "lng": -97.94647,
    "state_id": "OK"
  },
  {
    "zip": 73092,
    "lat": 35.12694,
    "lng": -98.095,
    "state_id": "OK"
  },
  {
    "zip": 73093,
    "lat": 35.10349,
    "lng": -97.49228,
    "state_id": "OK"
  },
  {
    "zip": 73095,
    "lat": 34.90714,
    "lng": -97.29911,
    "state_id": "OK"
  },
  {
    "zip": 73096,
    "lat": 35.51537,
    "lng": -98.73748,
    "state_id": "OK"
  },
  {
    "zip": 73097,
    "lat": 35.40048,
    "lng": -97.64483,
    "state_id": "OK"
  },
  {
    "zip": 73098,
    "lat": 34.6286,
    "lng": -97.16098,
    "state_id": "OK"
  },
  {
    "zip": 73099,
    "lat": 35.52828,
    "lng": -97.76237,
    "state_id": "OK"
  },
  {
    "zip": 73102,
    "lat": 35.47077,
    "lng": -97.51894,
    "state_id": "OK"
  },
  {
    "zip": 73103,
    "lat": 35.48909,
    "lng": -97.51892,
    "state_id": "OK"
  },
  {
    "zip": 73104,
    "lat": 35.47528,
    "lng": -97.50387,
    "state_id": "OK"
  },
  {
    "zip": 73105,
    "lat": 35.51899,
    "lng": -97.50306,
    "state_id": "OK"
  },
  {
    "zip": 73106,
    "lat": 35.48196,
    "lng": -97.53683,
    "state_id": "OK"
  },
  {
    "zip": 73107,
    "lat": 35.4816,
    "lng": -97.57561,
    "state_id": "OK"
  },
  {
    "zip": 73108,
    "lat": 35.45001,
    "lng": -97.56768,
    "state_id": "OK"
  },
  {
    "zip": 73109,
    "lat": 35.43313,
    "lng": -97.52463,
    "state_id": "OK"
  },
  {
    "zip": 73110,
    "lat": 35.46116,
    "lng": -97.39765,
    "state_id": "OK"
  },
  {
    "zip": 73111,
    "lat": 35.51881,
    "lng": -97.47717,
    "state_id": "OK"
  },
  {
    "zip": 73112,
    "lat": 35.51843,
    "lng": -97.57487,
    "state_id": "OK"
  },
  {
    "zip": 73114,
    "lat": 35.5789,
    "lng": -97.5177,
    "state_id": "OK"
  },
  {
    "zip": 73115,
    "lat": 35.44234,
    "lng": -97.44152,
    "state_id": "OK"
  },
  {
    "zip": 73116,
    "lat": 35.54636,
    "lng": -97.56423,
    "state_id": "OK"
  },
  {
    "zip": 73117,
    "lat": 35.47465,
    "lng": -97.45973,
    "state_id": "OK"
  },
  {
    "zip": 73118,
    "lat": 35.51832,
    "lng": -97.5295,
    "state_id": "OK"
  },
  {
    "zip": 73119,
    "lat": 35.422,
    "lng": -97.56615,
    "state_id": "OK"
  },
  {
    "zip": 73120,
    "lat": 35.58047,
    "lng": -97.57223,
    "state_id": "OK"
  },
  {
    "zip": 73121,
    "lat": 35.52108,
    "lng": -97.4409,
    "state_id": "OK"
  },
  {
    "zip": 73122,
    "lat": 35.51989,
    "lng": -97.61431,
    "state_id": "OK"
  },
  {
    "zip": 73127,
    "lat": 35.47757,
    "lng": -97.65392,
    "state_id": "OK"
  },
  {
    "zip": 73128,
    "lat": 35.45035,
    "lng": -97.6434,
    "state_id": "OK"
  },
  {
    "zip": 73129,
    "lat": 35.43148,
    "lng": -97.48435,
    "state_id": "OK"
  },
  {
    "zip": 73130,
    "lat": 35.45862,
    "lng": -97.34556,
    "state_id": "OK"
  },
  {
    "zip": 73131,
    "lat": 35.57672,
    "lng": -97.46433,
    "state_id": "OK"
  },
  {
    "zip": 73132,
    "lat": 35.5509,
    "lng": -97.63997,
    "state_id": "OK"
  },
  {
    "zip": 73134,
    "lat": 35.61423,
    "lng": -97.56827,
    "state_id": "OK"
  },
  {
    "zip": 73135,
    "lat": 35.39481,
    "lng": -97.42882,
    "state_id": "OK"
  },
  {
    "zip": 73139,
    "lat": 35.38477,
    "lng": -97.52559,
    "state_id": "OK"
  },
  {
    "zip": 73141,
    "lat": 35.51688,
    "lng": -97.39771,
    "state_id": "OK"
  },
  {
    "zip": 73142,
    "lat": 35.61379,
    "lng": -97.64242,
    "state_id": "OK"
  },
  {
    "zip": 73145,
    "lat": 35.41912,
    "lng": -97.3941,
    "state_id": "OK"
  },
  {
    "zip": 73149,
    "lat": 35.38941,
    "lng": -97.48934,
    "state_id": "OK"
  },
  {
    "zip": 73150,
    "lat": 35.40093,
    "lng": -97.33277,
    "state_id": "OK"
  },
  {
    "zip": 73151,
    "lat": 35.56754,
    "lng": -97.41114,
    "state_id": "OK"
  },
  {
    "zip": 73159,
    "lat": 35.39008,
    "lng": -97.57679,
    "state_id": "OK"
  },
  {
    "zip": 73160,
    "lat": 35.33208,
    "lng": -97.47543,
    "state_id": "OK"
  },
  {
    "zip": 73162,
    "lat": 35.58115,
    "lng": -97.64143,
    "state_id": "OK"
  },
  {
    "zip": 73165,
    "lat": 35.34382,
    "lng": -97.35726,
    "state_id": "OK"
  },
  {
    "zip": 73169,
    "lat": 35.38128,
    "lng": -97.64253,
    "state_id": "OK"
  },
  {
    "zip": 73170,
    "lat": 35.32738,
    "lng": -97.55059,
    "state_id": "OK"
  },
  {
    "zip": 73173,
    "lat": 35.34524,
    "lng": -97.62226,
    "state_id": "OK"
  },
  {
    "zip": 73179,
    "lat": 35.42445,
    "lng": -97.64447,
    "state_id": "OK"
  },
  {
    "zip": 73401,
    "lat": 34.20308,
    "lng": -97.11484,
    "state_id": "OK"
  },
  {
    "zip": 73425,
    "lat": 34.45347,
    "lng": -97.5551,
    "state_id": "OK"
  },
  {
    "zip": 73430,
    "lat": 33.90519,
    "lng": -97.36686,
    "state_id": "OK"
  },
  {
    "zip": 73432,
    "lat": 34.25701,
    "lng": -96.41945,
    "state_id": "OK"
  },
  {
    "zip": 73433,
    "lat": 34.62413,
    "lng": -97.40671,
    "state_id": "OK"
  },
  {
    "zip": 73434,
    "lat": 34.62385,
    "lng": -97.58555,
    "state_id": "OK"
  },
  {
    "zip": 73437,
    "lat": 34.36189,
    "lng": -97.4156,
    "state_id": "OK"
  },
  {
    "zip": 73438,
    "lat": 34.28646,
    "lng": -97.49945,
    "state_id": "OK"
  },
  {
    "zip": 73439,
    "lat": 33.94552,
    "lng": -96.75692,
    "state_id": "OK"
  },
  {
    "zip": 73440,
    "lat": 33.98071,
    "lng": -96.89989,
    "state_id": "OK"
  },
  {
    "zip": 73441,
    "lat": 33.90457,
    "lng": -97.43061,
    "state_id": "OK"
  },
  {
    "zip": 73442,
    "lat": 34.3265,
    "lng": -97.6718,
    "state_id": "OK"
  },
  {
    "zip": 73443,
    "lat": 34.16434,
    "lng": -97.29621,
    "state_id": "OK"
  },
  {
    "zip": 73444,
    "lat": 34.45673,
    "lng": -97.35853,
    "state_id": "OK"
  },
  {
    "zip": 73446,
    "lat": 34.08958,
    "lng": -96.77804,
    "state_id": "OK"
  },
  {
    "zip": 73447,
    "lat": 34.24608,
    "lng": -96.85805,
    "state_id": "OK"
  },
  {
    "zip": 73448,
    "lat": 33.94683,
    "lng": -97.10753,
    "state_id": "OK"
  },
  {
    "zip": 73449,
    "lat": 34.00002,
    "lng": -96.53768,
    "state_id": "OK"
  },
  {
    "zip": 73450,
    "lat": 34.26244,
    "lng": -96.53518,
    "state_id": "OK"
  },
  {
    "zip": 73453,
    "lat": 34.03428,
    "lng": -97.26457,
    "state_id": "OK"
  },
  {
    "zip": 73455,
    "lat": 34.23162,
    "lng": -96.76246,
    "state_id": "OK"
  },
  {
    "zip": 73456,
    "lat": 34.13071,
    "lng": -97.6346,
    "state_id": "OK"
  },
  {
    "zip": 73458,
    "lat": 34.35204,
    "lng": -97.25328,
    "state_id": "OK"
  },
  {
    "zip": 73459,
    "lat": 33.77417,
    "lng": -97.1331,
    "state_id": "OK"
  },
  {
    "zip": 73460,
    "lat": 34.28709,
    "lng": -96.66144,
    "state_id": "OK"
  },
  {
    "zip": 73461,
    "lat": 34.35956,
    "lng": -96.4436,
    "state_id": "OK"
  },
  {
    "zip": 73463,
    "lat": 34.11237,
    "lng": -97.43373,
    "state_id": "OK"
  },
  {
    "zip": 73481,
    "lat": 34.43636,
    "lng": -97.5218,
    "state_id": "OK"
  },
  {
    "zip": 73487,
    "lat": 34.47883,
    "lng": -97.44581,
    "state_id": "OK"
  },
  {
    "zip": 73491,
    "lat": 34.47659,
    "lng": -97.65173,
    "state_id": "OK"
  },
  {
    "zip": 73501,
    "lat": 34.5607,
    "lng": -98.28812,
    "state_id": "OK"
  },
  {
    "zip": 73503,
    "lat": 34.67862,
    "lng": -98.49712,
    "state_id": "OK"
  },
  {
    "zip": 73505,
    "lat": 34.57887,
    "lng": -98.47856,
    "state_id": "OK"
  },
  {
    "zip": 73507,
    "lat": 34.7608,
    "lng": -98.54675,
    "state_id": "OK"
  },
  {
    "zip": 73520,
    "lat": 34.25284,
    "lng": -97.98123,
    "state_id": "OK"
  },
  {
    "zip": 73521,
    "lat": 34.64009,
    "lng": -99.31562,
    "state_id": "OK"
  },
  {
    "zip": 73526,
    "lat": 34.79408,
    "lng": -99.26918,
    "state_id": "OK"
  },
  {
    "zip": 73527,
    "lat": 34.57106,
    "lng": -98.62201,
    "state_id": "OK"
  },
  {
    "zip": 73528,
    "lat": 34.40433,
    "lng": -98.63496,
    "state_id": "OK"
  },
  {
    "zip": 73529,
    "lat": 34.35642,
    "lng": -97.94623,
    "state_id": "OK"
  },
  {
    "zip": 73530,
    "lat": 34.24724,
    "lng": -99.02039,
    "state_id": "OK"
  },
  {
    "zip": 73531,
    "lat": 34.19635,
    "lng": -98.53354,
    "state_id": "OK"
  },
  {
    "zip": 73532,
    "lat": 34.64261,
    "lng": -99.58948,
    "state_id": "OK"
  },
  {
    "zip": 73533,
    "lat": 34.49772,
    "lng": -97.85431,
    "state_id": "OK"
  },
  {
    "zip": 73537,
    "lat": 34.47923,
    "lng": -99.66092,
    "state_id": "OK"
  },
  {
    "zip": 73538,
    "lat": 34.73298,
    "lng": -98.29789,
    "state_id": "OK"
  },
  {
    "zip": 73539,
    "lat": 34.45759,
    "lng": -99.27991,
    "state_id": "OK"
  },
  {
    "zip": 73540,
    "lat": 34.47006,
    "lng": -98.56043,
    "state_id": "OK"
  },
  {
    "zip": 73541,
    "lat": 34.78957,
    "lng": -98.1791,
    "state_id": "OK"
  },
  {
    "zip": 73542,
    "lat": 34.40372,
    "lng": -98.94177,
    "state_id": "OK"
  },
  {
    "zip": 73543,
    "lat": 34.46585,
    "lng": -98.37797,
    "state_id": "OK"
  },
  {
    "zip": 73544,
    "lat": 34.69497,
    "lng": -99.76502,
    "state_id": "OK"
  },
  {
    "zip": 73546,
    "lat": 34.22025,
    "lng": -98.72435,
    "state_id": "OK"
  },
  {
    "zip": 73547,
    "lat": 34.9871,
    "lng": -99.40795,
    "state_id": "OK"
  },
  {
    "zip": 73548,
    "lat": 34.20843,
    "lng": -98.14657,
    "state_id": "OK"
  },
  {
    "zip": 73549,
    "lat": 34.6509,
    "lng": -99.15542,
    "state_id": "OK"
  },
  {
    "zip": 73550,
    "lat": 34.69155,
    "lng": -99.90224,
    "state_id": "OK"
  },
  {
    "zip": 73551,
    "lat": 34.35173,
    "lng": -98.872,
    "state_id": "OK"
  },
  {
    "zip": 73552,
    "lat": 34.63299,
    "lng": -98.7567,
    "state_id": "OK"
  },
  {
    "zip": 73553,
    "lat": 34.3262,
    "lng": -98.72943,
    "state_id": "OK"
  },
  {
    "zip": 73554,
    "lat": 34.89057,
    "lng": -99.61999,
    "state_id": "OK"
  },
  {
    "zip": 73555,
    "lat": 34.50171,
    "lng": -98.963,
    "state_id": "OK"
  },
  {
    "zip": 73556,
    "lat": 34.73015,
    "lng": -99.38406,
    "state_id": "OK"
  },
  {
    "zip": 73557,
    "lat": 34.72967,
    "lng": -98.48944,
    "state_id": "OK"
  },
  {
    "zip": 73559,
    "lat": 34.72772,
    "lng": -98.96376,
    "state_id": "OK"
  },
  {
    "zip": 73560,
    "lat": 34.53737,
    "lng": -99.45034,
    "state_id": "OK"
  },
  {
    "zip": 73562,
    "lat": 34.18345,
    "lng": -98.41301,
    "state_id": "OK"
  },
  {
    "zip": 73564,
    "lat": 34.84191,
    "lng": -98.98818,
    "state_id": "OK"
  },
  {
    "zip": 73565,
    "lat": 34.02495,
    "lng": -97.87875,
    "state_id": "OK"
  },
  {
    "zip": 73566,
    "lat": 34.62438,
    "lng": -98.94466,
    "state_id": "OK"
  },
  {
    "zip": 73567,
    "lat": 34.74323,
    "lng": -98.15518,
    "state_id": "OK"
  },
  {
    "zip": 73568,
    "lat": 34.24894,
    "lng": -98.21204,
    "state_id": "OK"
  },
  {
    "zip": 73569,
    "lat": 33.93793,
    "lng": -97.80531,
    "state_id": "OK"
  },
  {
    "zip": 73570,
    "lat": 34.51005,
    "lng": -99.10765,
    "state_id": "OK"
  },
  {
    "zip": 73571,
    "lat": 34.91824,
    "lng": -99.89567,
    "state_id": "OK"
  },
  {
    "zip": 73572,
    "lat": 34.36052,
    "lng": -98.34471,
    "state_id": "OK"
  },
  {
    "zip": 73573,
    "lat": 34.16881,
    "lng": -97.94315,
    "state_id": "OK"
  },
  {
    "zip": 73601,
    "lat": 35.48716,
    "lng": -98.96587,
    "state_id": "OK"
  },
  {
    "zip": 73620,
    "lat": 35.61363,
    "lng": -99.01516,
    "state_id": "OK"
  },
  {
    "zip": 73622,
    "lat": 35.38945,
    "lng": -98.9958,
    "state_id": "OK"
  },
  {
    "zip": 73624,
    "lat": 35.34103,
    "lng": -99.17919,
    "state_id": "OK"
  },
  {
    "zip": 73625,
    "lat": 35.67701,
    "lng": -99.19056,
    "state_id": "OK"
  },
  {
    "zip": 73626,
    "lat": 35.42136,
    "lng": -99.27905,
    "state_id": "OK"
  },
  {
    "zip": 73627,
    "lat": 35.20292,
    "lng": -99.45349,
    "state_id": "OK"
  },
  {
    "zip": 73628,
    "lat": 35.62471,
    "lng": -99.68448,
    "state_id": "OK"
  },
  {
    "zip": 73632,
    "lat": 35.27186,
    "lng": -98.9274,
    "state_id": "OK"
  },
  {
    "zip": 73638,
    "lat": 35.8404,
    "lng": -99.78449,
    "state_id": "OK"
  },
  {
    "zip": 73639,
    "lat": 35.73186,
    "lng": -98.9561,
    "state_id": "OK"
  },
  {
    "zip": 73641,
    "lat": 35.27332,
    "lng": -99.17215,
    "state_id": "OK"
  },
  {
    "zip": 73642,
    "lat": 35.82109,
    "lng": -99.91097,
    "state_id": "OK"
  },
  {
    "zip": 73644,
    "lat": 35.4185,
    "lng": -99.43895,
    "state_id": "OK"
  },
  {
    "zip": 73645,
    "lat": 35.20229,
    "lng": -99.8963,
    "state_id": "OK"
  },
  {
    "zip": 73646,
    "lat": 35.82975,
    "lng": -98.61412,
    "state_id": "OK"
  },
  {
    "zip": 73647,
    "lat": 35.42781,
    "lng": -99.15909,
    "state_id": "OK"
  },
  {
    "zip": 73650,
    "lat": 35.64184,
    "lng": -99.40731,
    "state_id": "OK"
  },
  {
    "zip": 73651,
    "lat": 35.01465,
    "lng": -99.07321,
    "state_id": "OK"
  },
  {
    "zip": 73654,
    "lat": 35.8594,
    "lng": -99.37032,
    "state_id": "OK"
  },
  {
    "zip": 73655,
    "lat": 34.98007,
    "lng": -99.25965,
    "state_id": "OK"
  },
  {
    "zip": 73658,
    "lat": 35.94778,
    "lng": -98.73347,
    "state_id": "OK"
  },
  {
    "zip": 73659,
    "lat": 35.86952,
    "lng": -98.92294,
    "state_id": "OK"
  },
  {
    "zip": 73660,
    "lat": 35.62475,
    "lng": -99.91541,
    "state_id": "OK"
  },
  {
    "zip": 73661,
    "lat": 35.16077,
    "lng": -99.01617,
    "state_id": "OK"
  },
  {
    "zip": 73662,
    "lat": 35.29862,
    "lng": -99.67524,
    "state_id": "OK"
  },
  {
    "zip": 73663,
    "lat": 36.10753,
    "lng": -98.90277,
    "state_id": "OK"
  },
  {
    "zip": 73664,
    "lat": 35.1859,
    "lng": -99.24866,
    "state_id": "OK"
  },
  {
    "zip": 73666,
    "lat": 35.47427,
    "lng": -99.91498,
    "state_id": "OK"
  },
  {
    "zip": 73667,
    "lat": 36.00235,
    "lng": -99.02411,
    "state_id": "OK"
  },
  {
    "zip": 73669,
    "lat": 35.7289,
    "lng": -98.74036,
    "state_id": "OK"
  },
  {
    "zip": 73673,
    "lat": 35.05295,
    "lng": -99.56732,
    "state_id": "OK"
  },
  {
    "zip": 73701,
    "lat": 36.42772,
    "lng": -97.78618,
    "state_id": "OK"
  },
  {
    "zip": 73703,
    "lat": 36.42763,
    "lng": -97.96221,
    "state_id": "OK"
  },
  {
    "zip": 73705,
    "lat": 36.3383,
    "lng": -97.90257,
    "state_id": "OK"
  },
  {
    "zip": 73716,
    "lat": 36.49979,
    "lng": -98.51721,
    "state_id": "OK"
  },
  {
    "zip": 73717,
    "lat": 36.84324,
    "lng": -98.77287,
    "state_id": "OK"
  },
  {
    "zip": 73718,
    "lat": 36.22232,
    "lng": -98.17033,
    "state_id": "OK"
  },
  {
    "zip": 73719,
    "lat": 36.95684,
    "lng": -98.28845,
    "state_id": "OK"
  },
  {
    "zip": 73720,
    "lat": 36.20078,
    "lng": -97.87315,
    "state_id": "OK"
  },
  {
    "zip": 73722,
    "lat": 36.89878,
    "lng": -98.33854,
    "state_id": "OK"
  },
  {
    "zip": 73724,
    "lat": 36.04878,
    "lng": -98.66747,
    "state_id": "OK"
  },
  {
    "zip": 73726,
    "lat": 36.58014,
    "lng": -98.49724,
    "state_id": "OK"
  },
  {
    "zip": 73727,
    "lat": 36.50569,
    "lng": -98.03714,
    "state_id": "OK"
  },
  {
    "zip": 73728,
    "lat": 36.74218,
    "lng": -98.37801,
    "state_id": "OK"
  },
  {
    "zip": 73729,
    "lat": 36.41926,
    "lng": -98.47207,
    "state_id": "OK"
  },
  {
    "zip": 73730,
    "lat": 36.3194,
    "lng": -97.5443,
    "state_id": "OK"
  },
  {
    "zip": 73731,
    "lat": 36.64335,
    "lng": -98.59791,
    "state_id": "OK"
  },
  {
    "zip": 73733,
    "lat": 36.2536,
    "lng": -97.69072,
    "state_id": "OK"
  },
  {
    "zip": 73734,
    "lat": 35.98577,
    "lng": -97.86015,
    "state_id": "OK"
  },
  {
    "zip": 73735,
    "lat": 36.28065,
    "lng": -98.05836,
    "state_id": "OK"
  },
  {
    "zip": 73736,
    "lat": 36.3449,
    "lng": -97.68847,
    "state_id": "OK"
  },
  {
    "zip": 73737,
    "lat": 36.29653,
    "lng": -98.62319,
    "state_id": "OK"
  },
  {
    "zip": 73738,
    "lat": 36.45196,
    "lng": -97.55176,
    "state_id": "OK"
  },
  {
    "zip": 73739,
    "lat": 36.51628,
    "lng": -98.14037,
    "state_id": "OK"
  },
  {
    "zip": 73741,
    "lat": 36.55962,
    "lng": -98.26018,
    "state_id": "OK"
  },
  {
    "zip": 73742,
    "lat": 36.09844,
    "lng": -97.9331,
    "state_id": "OK"
  },
  {
    "zip": 73743,
    "lat": 36.57436,
    "lng": -98.01411,
    "state_id": "OK"
  },
  {
    "zip": 73744,
    "lat": 35.94349,
    "lng": -98.30381,
    "state_id": "OK"
  },
  {
    "zip": 73746,
    "lat": 36.68504,
    "lng": -98.65759,
    "state_id": "OK"
  },
  {
    "zip": 73747,
    "lat": 36.22762,
    "lng": -98.33229,
    "state_id": "OK"
  },
  {
    "zip": 73749,
    "lat": 36.71274,
    "lng": -98.15966,
    "state_id": "OK"
  },
  {
    "zip": 73750,
    "lat": 35.85545,
    "lng": -97.95231,
    "state_id": "OK"
  },
  {
    "zip": 73753,
    "lat": 36.55168,
    "lng": -97.85715,
    "state_id": "OK"
  },
  {
    "zip": 73754,
    "lat": 36.39685,
    "lng": -98.09097,
    "state_id": "OK"
  },
  {
    "zip": 73755,
    "lat": 36.13483,
    "lng": -98.57157,
    "state_id": "OK"
  },
  {
    "zip": 73756,
    "lat": 35.98171,
    "lng": -98.1303,
    "state_id": "OK"
  },
  {
    "zip": 73757,
    "lat": 36.28737,
    "lng": -97.45643,
    "state_id": "OK"
  },
  {
    "zip": 73758,
    "lat": 36.94521,
    "lng": -98.07979,
    "state_id": "OK"
  },
  {
    "zip": 73759,
    "lat": 36.85891,
    "lng": -97.71491,
    "state_id": "OK"
  },
  {
    "zip": 73760,
    "lat": 36.3736,
    "lng": -98.16086,
    "state_id": "OK"
  },
  {
    "zip": 73761,
    "lat": 36.68393,
    "lng": -98.03851,
    "state_id": "OK"
  },
  {
    "zip": 73762,
    "lat": 35.71322,
    "lng": -97.968,
    "state_id": "OK"
  },
  {
    "zip": 73763,
    "lat": 36.09967,
    "lng": -98.32462,
    "state_id": "OK"
  },
  {
    "zip": 73764,
    "lat": 35.85041,
    "lng": -98.20502,
    "state_id": "OK"
  },
  {
    "zip": 73766,
    "lat": 36.65189,
    "lng": -97.82807,
    "state_id": "OK"
  },
  {
    "zip": 73768,
    "lat": 36.357,
    "lng": -98.29586,
    "state_id": "OK"
  },
  {
    "zip": 73771,
    "lat": 36.87045,
    "lng": -97.96623,
    "state_id": "OK"
  },
  {
    "zip": 73772,
    "lat": 35.8602,
    "lng": -98.4268,
    "state_id": "OK"
  },
  {
    "zip": 73773,
    "lat": 36.2605,
    "lng": -97.92453,
    "state_id": "OK"
  },
  {
    "zip": 73801,
    "lat": 36.47878,
    "lng": -99.43646,
    "state_id": "OK"
  },
  {
    "zip": 73832,
    "lat": 36.03793,
    "lng": -99.67436,
    "state_id": "OK"
  },
  {
    "zip": 73834,
    "lat": 36.85231,
    "lng": -99.55374,
    "state_id": "OK"
  },
  {
    "zip": 73835,
    "lat": 35.97591,
    "lng": -99.21109,
    "state_id": "OK"
  },
  {
    "zip": 73838,
    "lat": 36.24593,
    "lng": -98.89635,
    "state_id": "OK"
  },
  {
    "zip": 73840,
    "lat": 36.35367,
    "lng": -99.61841,
    "state_id": "OK"
  },
  {
    "zip": 73841,
    "lat": 36.55883,
    "lng": -99.62421,
    "state_id": "OK"
  },
  {
    "zip": 73842,
    "lat": 36.81202,
    "lng": -99.19633,
    "state_id": "OK"
  },
  {
    "zip": 73843,
    "lat": 36.40146,
    "lng": -99.8153,
    "state_id": "OK"
  },
  {
    "zip": 73844,
    "lat": 36.88695,
    "lng": -100.14301,
    "state_id": "OK"
  },
  {
    "zip": 73848,
    "lat": 36.654,
    "lng": -100.00725,
    "state_id": "OK"
  },
  {
    "zip": 73851,
    "lat": 36.59145,
    "lng": -99.80648,
    "state_id": "OK"
  },
  {
    "zip": 73852,
    "lat": 36.48158,
    "lng": -99.11655,
    "state_id": "OK"
  },
  {
    "zip": 73853,
    "lat": 36.23954,
    "lng": -99.14683,
    "state_id": "OK"
  },
  {
    "zip": 73855,
    "lat": 36.91681,
    "lng": -99.86541,
    "state_id": "OK"
  },
  {
    "zip": 73857,
    "lat": 36.27055,
    "lng": -99.32995,
    "state_id": "OK"
  },
  {
    "zip": 73858,
    "lat": 36.18299,
    "lng": -99.92277,
    "state_id": "OK"
  },
  {
    "zip": 73859,
    "lat": 36.12364,
    "lng": -99.29827,
    "state_id": "OK"
  },
  {
    "zip": 73860,
    "lat": 36.53018,
    "lng": -98.8338,
    "state_id": "OK"
  },
  {
    "zip": 73901,
    "lat": 36.73544,
    "lng": -101.02069,
    "state_id": "OK"
  },
  {
    "zip": 73931,
    "lat": 36.60438,
    "lng": -100.79271,
    "state_id": "OK"
  },
  {
    "zip": 73932,
    "lat": 36.71612,
    "lng": -100.50249,
    "state_id": "OK"
  },
  {
    "zip": 73933,
    "lat": 36.74596,
    "lng": -102.57362,
    "state_id": "OK"
  },
  {
    "zip": 73937,
    "lat": 36.5542,
    "lng": -102.79119,
    "state_id": "OK"
  },
  {
    "zip": 73938,
    "lat": 36.93497,
    "lng": -100.58305,
    "state_id": "OK"
  },
  {
    "zip": 73939,
    "lat": 36.77793,
    "lng": -101.79129,
    "state_id": "OK"
  },
  {
    "zip": 73942,
    "lat": 36.74521,
    "lng": -101.51134,
    "state_id": "OK"
  },
  {
    "zip": 73944,
    "lat": 36.59047,
    "lng": -101.10788,
    "state_id": "OK"
  },
  {
    "zip": 73945,
    "lat": 36.83331,
    "lng": -101.23303,
    "state_id": "OK"
  },
  {
    "zip": 73946,
    "lat": 36.93133,
    "lng": -102.81268,
    "state_id": "OK"
  },
  {
    "zip": 73947,
    "lat": 36.81289,
    "lng": -102.15782,
    "state_id": "OK"
  },
  {
    "zip": 73949,
    "lat": 36.57949,
    "lng": -101.92065,
    "state_id": "OK"
  },
  {
    "zip": 73950,
    "lat": 36.85721,
    "lng": -100.88374,
    "state_id": "OK"
  },
  {
    "zip": 73951,
    "lat": 36.94471,
    "lng": -101.0575,
    "state_id": "OK"
  },
  {
    "zip": 74001,
    "lat": 36.49726,
    "lng": -96.0798,
    "state_id": "OK"
  },
  {
    "zip": 74002,
    "lat": 36.55613,
    "lng": -96.14402,
    "state_id": "OK"
  },
  {
    "zip": 74003,
    "lat": 36.72742,
    "lng": -96.06581,
    "state_id": "OK"
  },
  {
    "zip": 74006,
    "lat": 36.71433,
    "lng": -95.89225,
    "state_id": "OK"
  },
  {
    "zip": 74008,
    "lat": 35.92456,
    "lng": -95.87253,
    "state_id": "OK"
  },
  {
    "zip": 74010,
    "lat": 35.81446,
    "lng": -96.36493,
    "state_id": "OK"
  },
  {
    "zip": 74011,
    "lat": 35.98162,
    "lng": -95.80942,
    "state_id": "OK"
  },
  {
    "zip": 74012,
    "lat": 36.05359,
    "lng": -95.80494,
    "state_id": "OK"
  },
  {
    "zip": 74014,
    "lat": 36.05998,
    "lng": -95.67515,
    "state_id": "OK"
  },
  {
    "zip": 74015,
    "lat": 36.18229,
    "lng": -95.70744,
    "state_id": "OK"
  },
  {
    "zip": 74016,
    "lat": 36.54513,
    "lng": -95.44976,
    "state_id": "OK"
  },
  {
    "zip": 74017,
    "lat": 36.39313,
    "lng": -95.58349,
    "state_id": "OK"
  },
  {
    "zip": 74019,
    "lat": 36.28056,
    "lng": -95.60386,
    "state_id": "OK"
  },
  {
    "zip": 74020,
    "lat": 36.24511,
    "lng": -96.41911,
    "state_id": "OK"
  },
  {
    "zip": 74021,
    "lat": 36.38241,
    "lng": -95.84795,
    "state_id": "OK"
  },
  {
    "zip": 74022,
    "lat": 36.93189,
    "lng": -95.9794,
    "state_id": "OK"
  },
  {
    "zip": 74023,
    "lat": 35.95803,
    "lng": -96.7497,
    "state_id": "OK"
  },
  {
    "zip": 74026,
    "lat": 35.71287,
    "lng": -96.76233,
    "state_id": "OK"
  },
  {
    "zip": 74027,
    "lat": 36.79882,
    "lng": -95.58707,
    "state_id": "OK"
  },
  {
    "zip": 74028,
    "lat": 35.78653,
    "lng": -96.52369,
    "state_id": "OK"
  },
  {
    "zip": 74029,
    "lat": 36.82681,
    "lng": -95.88752,
    "state_id": "OK"
  },
  {
    "zip": 74030,
    "lat": 35.98764,
    "lng": -96.56447,
    "state_id": "OK"
  },
  {
    "zip": 74032,
    "lat": 36.20922,
    "lng": -96.90433,
    "state_id": "OK"
  },
  {
    "zip": 74033,
    "lat": 35.94587,
    "lng": -96.00757,
    "state_id": "OK"
  },
  {
    "zip": 74034,
    "lat": 36.22631,
    "lng": -96.57904,
    "state_id": "OK"
  },
  {
    "zip": 74035,
    "lat": 36.42348,
    "lng": -96.38873,
    "state_id": "OK"
  },
  {
    "zip": 74036,
    "lat": 36.15902,
    "lng": -95.50724,
    "state_id": "OK"
  },
  {
    "zip": 74037,
    "lat": 36.00187,
    "lng": -95.9783,
    "state_id": "OK"
  },
  {
    "zip": 74038,
    "lat": 36.15819,
    "lng": -96.5636,
    "state_id": "OK"
  },
  {
    "zip": 74039,
    "lat": 35.89624,
    "lng": -96.22923,
    "state_id": "OK"
  },
  {
    "zip": 74041,
    "lat": 35.9417,
    "lng": -96.05455,
    "state_id": "OK"
  },
  {
    "zip": 74042,
    "lat": 36.86882,
    "lng": -95.57556,
    "state_id": "OK"
  },
  {
    "zip": 74044,
    "lat": 36.09464,
    "lng": -96.38256,
    "state_id": "OK"
  },
  {
    "zip": 74045,
    "lat": 36.2234,
    "lng": -96.70733,
    "state_id": "OK"
  },
  {
    "zip": 74046,
    "lat": 35.74991,
    "lng": -96.56317,
    "state_id": "OK"
  },
  {
    "zip": 74047,
    "lat": 35.85505,
    "lng": -96.01407,
    "state_id": "OK"
  },
  {
    "zip": 74048,
    "lat": 36.69188,
    "lng": -95.65212,
    "state_id": "OK"
  },
  {
    "zip": 74050,
    "lat": 36.07282,
    "lng": -96.06463,
    "state_id": "OK"
  },
  {
    "zip": 74051,
    "lat": 36.60993,
    "lng": -95.96602,
    "state_id": "OK"
  },
  {
    "zip": 74052,
    "lat": 36.08466,
    "lng": -96.57993,
    "state_id": "OK"
  },
  {
    "zip": 74053,
    "lat": 36.43261,
    "lng": -95.73266,
    "state_id": "OK"
  },
  {
    "zip": 74054,
    "lat": 36.2753,
    "lng": -96.3475,
    "state_id": "OK"
  },
  {
    "zip": 74055,
    "lat": 36.27843,
    "lng": -95.81894,
    "state_id": "OK"
  },
  {
    "zip": 74056,
    "lat": 36.80028,
    "lng": -96.33635,
    "state_id": "OK"
  },
  {
    "zip": 74058,
    "lat": 36.3568,
    "lng": -96.75986,
    "state_id": "OK"
  },
  {
    "zip": 74059,
    "lat": 35.95898,
    "lng": -97.05221,
    "state_id": "OK"
  },
  {
    "zip": 74060,
    "lat": 36.26746,
    "lng": -96.24784,
    "state_id": "OK"
  },
  {
    "zip": 74061,
    "lat": 36.53892,
    "lng": -95.90055,
    "state_id": "OK"
  },
  {
    "zip": 74062,
    "lat": 36.04052,
    "lng": -96.8932,
    "state_id": "OK"
  },
  {
    "zip": 74063,
    "lat": 36.15043,
    "lng": -96.19614,
    "state_id": "OK"
  },
  {
    "zip": 74066,
    "lat": 35.99282,
    "lng": -96.16465,
    "state_id": "OK"
  },
  {
    "zip": 74068,
    "lat": 35.90831,
    "lng": -96.57505,
    "state_id": "OK"
  },
  {
    "zip": 74070,
    "lat": 36.38838,
    "lng": -96.07447,
    "state_id": "OK"
  },
  {
    "zip": 74071,
    "lat": 35.78212,
    "lng": -96.28047,
    "state_id": "OK"
  },
  {
    "zip": 74072,
    "lat": 36.94696,
    "lng": -95.53756,
    "state_id": "OK"
  },
  {
    "zip": 74073,
    "lat": 36.30147,
    "lng": -96.01704,
    "state_id": "OK"
  },
  {
    "zip": 74074,
    "lat": 36.08105,
    "lng": -97.07624,
    "state_id": "OK"
  },
  {
    "zip": 74075,
    "lat": 36.17192,
    "lng": -97.07907,
    "state_id": "OK"
  },
  {
    "zip": 74078,
    "lat": 36.12408,
    "lng": -97.0705,
    "state_id": "OK"
  },
  {
    "zip": 74079,
    "lat": 35.74673,
    "lng": -96.65588,
    "state_id": "OK"
  },
  {
    "zip": 74080,
    "lat": 36.52745,
    "lng": -95.74124,
    "state_id": "OK"
  },
  {
    "zip": 74081,
    "lat": 36.17588,
    "lng": -96.45561,
    "state_id": "OK"
  },
  {
    "zip": 74082,
    "lat": 36.45047,
    "lng": -95.88155,
    "state_id": "OK"
  },
  {
    "zip": 74083,
    "lat": 36.91545,
    "lng": -95.7694,
    "state_id": "OK"
  },
  {
    "zip": 74084,
    "lat": 36.53814,
    "lng": -96.34617,
    "state_id": "OK"
  },
  {
    "zip": 74085,
    "lat": 36.11039,
    "lng": -96.71513,
    "state_id": "OK"
  },
  {
    "zip": 74103,
    "lat": 36.15561,
    "lng": -95.99453,
    "state_id": "OK"
  },
  {
    "zip": 74104,
    "lat": 36.1465,
    "lng": -95.95398,
    "state_id": "OK"
  },
  {
    "zip": 74105,
    "lat": 36.09747,
    "lng": -95.96404,
    "state_id": "OK"
  },
  {
    "zip": 74106,
    "lat": 36.19194,
    "lng": -95.98423,
    "state_id": "OK"
  },
  {
    "zip": 74107,
    "lat": 36.1069,
    "lng": -96.04148,
    "state_id": "OK"
  },
  {
    "zip": 74108,
    "lat": 36.1476,
    "lng": -95.79401,
    "state_id": "OK"
  },
  {
    "zip": 74110,
    "lat": 36.18865,
    "lng": -95.95389,
    "state_id": "OK"
  },
  {
    "zip": 74112,
    "lat": 36.14715,
    "lng": -95.9043,
    "state_id": "OK"
  },
  {
    "zip": 74114,
    "lat": 36.127,
    "lng": -95.94659,
    "state_id": "OK"
  },
  {
    "zip": 74115,
    "lat": 36.1924,
    "lng": -95.90944,
    "state_id": "OK"
  },
  {
    "zip": 74116,
    "lat": 36.18804,
    "lng": -95.83619,
    "state_id": "OK"
  },
  {
    "zip": 74117,
    "lat": 36.2416,
    "lng": -95.89623,
    "state_id": "OK"
  },
  {
    "zip": 74119,
    "lat": 36.14204,
    "lng": -95.98919,
    "state_id": "OK"
  },
  {
    "zip": 74120,
    "lat": 36.15041,
    "lng": -95.97682,
    "state_id": "OK"
  },
  {
    "zip": 74126,
    "lat": 36.24068,
    "lng": -96.02965,
    "state_id": "OK"
  },
  {
    "zip": 74127,
    "lat": 36.17895,
    "lng": -96.04534,
    "state_id": "OK"
  },
  {
    "zip": 74128,
    "lat": 36.14745,
    "lng": -95.85144,
    "state_id": "OK"
  },
  {
    "zip": 74129,
    "lat": 36.12678,
    "lng": -95.8697,
    "state_id": "OK"
  },
  {
    "zip": 74130,
    "lat": 36.24151,
    "lng": -95.95512,
    "state_id": "OK"
  },
  {
    "zip": 74131,
    "lat": 36.05242,
    "lng": -96.07093,
    "state_id": "OK"
  },
  {
    "zip": 74132,
    "lat": 36.04863,
    "lng": -96.01322,
    "state_id": "OK"
  },
  {
    "zip": 74133,
    "lat": 36.04146,
    "lng": -95.87941,
    "state_id": "OK"
  },
  {
    "zip": 74134,
    "lat": 36.11164,
    "lng": -95.80388,
    "state_id": "OK"
  },
  {
    "zip": 74135,
    "lat": 36.09727,
    "lng": -95.92329,
    "state_id": "OK"
  },
  {
    "zip": 74136,
    "lat": 36.06141,
    "lng": -95.94236,
    "state_id": "OK"
  },
  {
    "zip": 74137,
    "lat": 36.02055,
    "lng": -95.92937,
    "state_id": "OK"
  },
  {
    "zip": 74145,
    "lat": 36.09733,
    "lng": -95.88735,
    "state_id": "OK"
  },
  {
    "zip": 74146,
    "lat": 36.09785,
    "lng": -95.85322,
    "state_id": "OK"
  },
  {
    "zip": 74301,
    "lat": 36.67736,
    "lng": -95.22875,
    "state_id": "OK"
  },
  {
    "zip": 74330,
    "lat": 36.43708,
    "lng": -95.27256,
    "state_id": "OK"
  },
  {
    "zip": 74331,
    "lat": 36.63939,
    "lng": -94.9423,
    "state_id": "OK"
  },
  {
    "zip": 74332,
    "lat": 36.52077,
    "lng": -95.23335,
    "state_id": "OK"
  },
  {
    "zip": 74333,
    "lat": 36.79144,
    "lng": -95.08618,
    "state_id": "OK"
  },
  {
    "zip": 74337,
    "lat": 36.13395,
    "lng": -95.34139,
    "state_id": "OK"
  },
  {
    "zip": 74338,
    "lat": 36.26274,
    "lng": -94.72952,
    "state_id": "OK"
  },
  {
    "zip": 74339,
    "lat": 36.93398,
    "lng": -94.87974,
    "state_id": "OK"
  },
  {
    "zip": 74340,
    "lat": 36.47584,
    "lng": -95.02402,
    "state_id": "OK"
  },
  {
    "zip": 74342,
    "lat": 36.421,
    "lng": -94.93773,
    "state_id": "OK"
  },
  {
    "zip": 74343,
    "lat": 36.74205,
    "lng": -94.82579,
    "state_id": "OK"
  },
  {
    "zip": 74344,
    "lat": 36.5973,
    "lng": -94.73848,
    "state_id": "OK"
  },
  {
    "zip": 74346,
    "lat": 36.43793,
    "lng": -94.75038,
    "state_id": "OK"
  },
  {
    "zip": 74347,
    "lat": 36.17717,
    "lng": -94.78535,
    "state_id": "OK"
  },
  {
    "zip": 74349,
    "lat": 36.51767,
    "lng": -95.03399,
    "state_id": "OK"
  },
  {
    "zip": 74350,
    "lat": 36.46625,
    "lng": -95.05121,
    "state_id": "OK"
  },
  {
    "zip": 74352,
    "lat": 36.14175,
    "lng": -95.18108,
    "state_id": "OK"
  },
  {
    "zip": 74354,
    "lat": 36.88592,
    "lng": -94.88271,
    "state_id": "OK"
  },
  {
    "zip": 74358,
    "lat": 36.91785,
    "lng": -94.8806,
    "state_id": "OK"
  },
  {
    "zip": 74359,
    "lat": 36.17705,
    "lng": -94.82626,
    "state_id": "OK"
  },
  {
    "zip": 74360,
    "lat": 36.97154,
    "lng": -94.83286,
    "state_id": "OK"
  },
  {
    "zip": 74361,
    "lat": 36.30298,
    "lng": -95.30664,
    "state_id": "OK"
  },
  {
    "zip": 74363,
    "lat": 36.95315,
    "lng": -94.71438,
    "state_id": "OK"
  },
  {
    "zip": 74364,
    "lat": 36.20215,
    "lng": -94.9573,
    "state_id": "OK"
  },
  {
    "zip": 74365,
    "lat": 36.29621,
    "lng": -95.06754,
    "state_id": "OK"
  },
  {
    "zip": 74366,
    "lat": 36.39032,
    "lng": -95.04647,
    "state_id": "OK"
  },
  {
    "zip": 74367,
    "lat": 36.41971,
    "lng": -95.11206,
    "state_id": "OK"
  },
  {
    "zip": 74368,
    "lat": 36.20754,
    "lng": -94.84861,
    "state_id": "OK"
  },
  {
    "zip": 74369,
    "lat": 36.91922,
    "lng": -95.19809,
    "state_id": "OK"
  },
  {
    "zip": 74370,
    "lat": 36.76577,
    "lng": -94.6798,
    "state_id": "OK"
  },
  {
    "zip": 74401,
    "lat": 35.72181,
    "lng": -95.46533,
    "state_id": "OK"
  },
  {
    "zip": 74403,
    "lat": 35.67168,
    "lng": -95.30678,
    "state_id": "OK"
  },
  {
    "zip": 74421,
    "lat": 35.76006,
    "lng": -96.04371,
    "state_id": "OK"
  },
  {
    "zip": 74422,
    "lat": 35.63654,
    "lng": -95.70864,
    "state_id": "OK"
  },
  {
    "zip": 74423,
    "lat": 35.67306,
    "lng": -95.1917,
    "state_id": "OK"
  },
  {
    "zip": 74425,
    "lat": 35.17235,
    "lng": -95.64189,
    "state_id": "OK"
  },
  {
    "zip": 74426,
    "lat": 35.44994,
    "lng": -95.52887,
    "state_id": "OK"
  },
  {
    "zip": 74427,
    "lat": 35.68398,
    "lng": -94.90704,
    "state_id": "OK"
  },
  {
    "zip": 74428,
    "lat": 35.56784,
    "lng": -95.63752,
    "state_id": "OK"
  },
  {
    "zip": 74429,
    "lat": 35.95451,
    "lng": -95.62806,
    "state_id": "OK"
  },
  {
    "zip": 74430,
    "lat": 35.12451,
    "lng": -95.66073,
    "state_id": "OK"
  },
  {
    "zip": 74431,
    "lat": 35.46684,
    "lng": -95.94553,
    "state_id": "OK"
  },
  {
    "zip": 74432,
    "lat": 35.28443,
    "lng": -95.65657,
    "state_id": "OK"
  },
  {
    "zip": 74434,
    "lat": 35.8085,
    "lng": -95.21268,
    "state_id": "OK"
  },
  {
    "zip": 74435,
    "lat": 35.57733,
    "lng": -95.09355,
    "state_id": "OK"
  },
  {
    "zip": 74436,
    "lat": 35.79664,
    "lng": -95.69675,
    "state_id": "OK"
  },
  {
    "zip": 74437,
    "lat": 35.45456,
    "lng": -95.92798,
    "state_id": "OK"
  },
  {
    "zip": 74438,
    "lat": 35.5376,
    "lng": -95.75539,
    "state_id": "OK"
  },
  {
    "zip": 74441,
    "lat": 35.97558,
    "lng": -95.15073,
    "state_id": "OK"
  },
  {
    "zip": 74442,
    "lat": 35.13141,
    "lng": -95.81575,
    "state_id": "OK"
  },
  {
    "zip": 74445,
    "lat": 35.62809,
    "lng": -95.82588,
    "state_id": "OK"
  },
  {
    "zip": 74446,
    "lat": 35.84854,
    "lng": -95.31625,
    "state_id": "OK"
  },
  {
    "zip": 74447,
    "lat": 35.65124,
    "lng": -96.00316,
    "state_id": "OK"
  },
  {
    "zip": 74450,
    "lat": 35.59646,
    "lng": -95.48126,
    "state_id": "OK"
  },
  {
    "zip": 74451,
    "lat": 35.74314,
    "lng": -94.97553,
    "state_id": "OK"
  },
  {
    "zip": 74452,
    "lat": 36.12017,
    "lng": -95.06805,
    "state_id": "OK"
  },
  {
    "zip": 74454,
    "lat": 35.85546,
    "lng": -95.51522,
    "state_id": "OK"
  },
  {
    "zip": 74455,
    "lat": 35.3619,
    "lng": -95.26371,
    "state_id": "OK"
  },
  {
    "zip": 74456,
    "lat": 35.71124,
    "lng": -95.99701,
    "state_id": "OK"
  },
  {
    "zip": 74457,
    "lat": 36.03116,
    "lng": -94.81926,
    "state_id": "OK"
  },
  {
    "zip": 74458,
    "lat": 35.88357,
    "lng": -95.59183,
    "state_id": "OK"
  },
  {
    "zip": 74459,
    "lat": 35.53422,
    "lng": -95.49223,
    "state_id": "OK"
  },
  {
    "zip": 74460,
    "lat": 35.50796,
    "lng": -95.95652,
    "state_id": "OK"
  },
  {
    "zip": 74462,
    "lat": 35.2832,
    "lng": -95.15524,
    "state_id": "OK"
  },
  {
    "zip": 74463,
    "lat": 35.75977,
    "lng": -95.55047,
    "state_id": "OK"
  },
  {
    "zip": 74464,
    "lat": 35.92728,
    "lng": -94.98503,
    "state_id": "OK"
  },
  {
    "zip": 74467,
    "lat": 35.96628,
    "lng": -95.39265,
    "state_id": "OK"
  },
  {
    "zip": 74468,
    "lat": 35.61216,
    "lng": -95.56808,
    "state_id": "OK"
  },
  {
    "zip": 74469,
    "lat": 35.49515,
    "lng": -95.30809,
    "state_id": "OK"
  },
  {
    "zip": 74470,
    "lat": 35.48063,
    "lng": -95.17267,
    "state_id": "OK"
  },
  {
    "zip": 74471,
    "lat": 35.83005,
    "lng": -94.86476,
    "state_id": "OK"
  },
  {
    "zip": 74472,
    "lat": 35.24792,
    "lng": -95.26683,
    "state_id": "OK"
  },
  {
    "zip": 74477,
    "lat": 36.00418,
    "lng": -95.26655,
    "state_id": "OK"
  },
  {
    "zip": 74501,
    "lat": 34.9824,
    "lng": -95.7955,
    "state_id": "OK"
  },
  {
    "zip": 74521,
    "lat": 34.65767,
    "lng": -95.09368,
    "state_id": "OK"
  },
  {
    "zip": 74522,
    "lat": 34.9035,
    "lng": -95.69272,
    "state_id": "OK"
  },
  {
    "zip": 74523,
    "lat": 34.22758,
    "lng": -95.61038,
    "state_id": "OK"
  },
  {
    "zip": 74525,
    "lat": 34.33507,
    "lng": -96.06885,
    "state_id": "OK"
  },
  {
    "zip": 74528,
    "lat": 34.72501,
    "lng": -95.72559,
    "state_id": "OK"
  },
  {
    "zip": 74530,
    "lat": 34.42588,
    "lng": -96.5012,
    "state_id": "OK"
  },
  {
    "zip": 74531,
    "lat": 34.88726,
    "lng": -96.2522,
    "state_id": "OK"
  },
  {
    "zip": 74533,
    "lat": 34.20709,
    "lng": -96.18856,
    "state_id": "OK"
  },
  {
    "zip": 74534,
    "lat": 34.61213,
    "lng": -96.36845,
    "state_id": "OK"
  },
  {
    "zip": 74535,
    "lat": 34.4829,
    "lng": -96.43966,
    "state_id": "OK"
  },
  {
    "zip": 74536,
    "lat": 34.56172,
    "lng": -95.4519,
    "state_id": "OK"
  },
  {
    "zip": 74538,
    "lat": 34.57981,
    "lng": -96.25755,
    "state_id": "OK"
  },
  {
    "zip": 74540,
    "lat": 34.55688,
    "lng": -95.70263,
    "state_id": "OK"
  },
  {
    "zip": 74543,
    "lat": 34.36424,
    "lng": -95.43181,
    "state_id": "OK"
  },
  {
    "zip": 74546,
    "lat": 34.85844,
    "lng": -95.57873,
    "state_id": "OK"
  },
  {
    "zip": 74547,
    "lat": 34.81483,
    "lng": -95.59308,
    "state_id": "OK"
  },
  {
    "zip": 74549,
    "lat": 34.55839,
    "lng": -94.93264,
    "state_id": "OK"
  },
  {
    "zip": 74552,
    "lat": 35.1212,
    "lng": -95.24293,
    "state_id": "OK"
  },
  {
    "zip": 74553,
    "lat": 34.71206,
    "lng": -95.91385,
    "state_id": "OK"
  },
  {
    "zip": 74554,
    "lat": 34.9233,
    "lng": -95.71067,
    "state_id": "OK"
  },
  {
    "zip": 74555,
    "lat": 34.25966,
    "lng": -95.95486,
    "state_id": "OK"
  },
  {
    "zip": 74556,
    "lat": 34.46697,
    "lng": -96.18204,
    "state_id": "OK"
  },
  {
    "zip": 74557,
    "lat": 34.40176,
    "lng": -95.69245,
    "state_id": "OK"
  },
  {
    "zip": 74558,
    "lat": 34.4938,
    "lng": -95.15183,
    "state_id": "OK"
  },
  {
    "zip": 74560,
    "lat": 34.64691,
    "lng": -95.74546,
    "state_id": "OK"
  },
  {
    "zip": 74561,
    "lat": 35.12566,
    "lng": -95.42926,
    "state_id": "OK"
  },
  {
    "zip": 74562,
    "lat": 34.2946,
    "lng": -95.28514,
    "state_id": "OK"
  },
  {
    "zip": 74563,
    "lat": 34.97376,
    "lng": -95.06672,
    "state_id": "OK"
  },
  {
    "zip": 74565,
    "lat": 34.80339,
    "lng": -95.84538,
    "state_id": "OK"
  },
  {
    "zip": 74567,
    "lat": 34.42481,
    "lng": -95.39557,
    "state_id": "OK"
  },
  {
    "zip": 74569,
    "lat": 34.46562,
    "lng": -95.90905,
    "state_id": "OK"
  },
  {
    "zip": 74570,
    "lat": 34.85272,
    "lng": -96.10642,
    "state_id": "OK"
  },
  {
    "zip": 74571,
    "lat": 34.71847,
    "lng": -94.98841,
    "state_id": "OK"
  },
  {
    "zip": 74572,
    "lat": 34.64068,
    "lng": -96.42489,
    "state_id": "OK"
  },
  {
    "zip": 74574,
    "lat": 34.68415,
    "lng": -95.28332,
    "state_id": "OK"
  },
  {
    "zip": 74576,
    "lat": 34.6602,
    "lng": -96.03757,
    "state_id": "OK"
  },
  {
    "zip": 74577,
    "lat": 34.6825,
    "lng": -94.84376,
    "state_id": "OK"
  },
  {
    "zip": 74578,
    "lat": 34.90557,
    "lng": -95.34057,
    "state_id": "OK"
  },
  {
    "zip": 74601,
    "lat": 36.70063,
    "lng": -97.14621,
    "state_id": "OK"
  },
  {
    "zip": 74604,
    "lat": 36.69829,
    "lng": -96.98172,
    "state_id": "OK"
  },
  {
    "zip": 74630,
    "lat": 36.51156,
    "lng": -97.4265,
    "state_id": "OK"
  },
  {
    "zip": 74631,
    "lat": 36.79401,
    "lng": -97.30867,
    "state_id": "OK"
  },
  {
    "zip": 74632,
    "lat": 36.93705,
    "lng": -97.33692,
    "state_id": "OK"
  },
  {
    "zip": 74633,
    "lat": 36.70322,
    "lng": -96.79223,
    "state_id": "OK"
  },
  {
    "zip": 74636,
    "lat": 36.81782,
    "lng": -97.52228,
    "state_id": "OK"
  },
  {
    "zip": 74637,
    "lat": 36.57167,
    "lng": -96.6747,
    "state_id": "OK"
  },
  {
    "zip": 74640,
    "lat": 36.55122,
    "lng": -97.65395,
    "state_id": "OK"
  },
  {
    "zip": 74641,
    "lat": 36.78921,
    "lng": -96.87904,
    "state_id": "OK"
  },
  {
    "zip": 74643,
    "lat": 36.67085,
    "lng": -97.55561,
    "state_id": "OK"
  },
  {
    "zip": 74644,
    "lat": 36.55599,
    "lng": -97.10985,
    "state_id": "OK"
  },
  {
    "zip": 74646,
    "lat": 36.82618,
    "lng": -97.44102,
    "state_id": "OK"
  },
  {
    "zip": 74647,
    "lat": 36.91233,
    "lng": -96.98529,
    "state_id": "OK"
  },
  {
    "zip": 74650,
    "lat": 36.47746,
    "lng": -96.74748,
    "state_id": "OK"
  },
  {
    "zip": 74651,
    "lat": 36.46925,
    "lng": -97.17474,
    "state_id": "OK"
  },
  {
    "zip": 74652,
    "lat": 36.85873,
    "lng": -96.65036,
    "state_id": "OK"
  },
  {
    "zip": 74653,
    "lat": 36.65114,
    "lng": -97.3517,
    "state_id": "OK"
  },
  {
    "zip": 74701,
    "lat": 34.00732,
    "lng": -96.37812,
    "state_id": "OK"
  },
  {
    "zip": 74720,
    "lat": 33.8259,
    "lng": -96.3753,
    "state_id": "OK"
  },
  {
    "zip": 74722,
    "lat": 34.38784,
    "lng": -94.91665,
    "state_id": "OK"
  },
  {
    "zip": 74723,
    "lat": 33.99066,
    "lng": -96.01516,
    "state_id": "OK"
  },
  {
    "zip": 74724,
    "lat": 34.34711,
    "lng": -94.85598,
    "state_id": "OK"
  },
  {
    "zip": 74726,
    "lat": 33.95266,
    "lng": -96.1783,
    "state_id": "OK"
  },
  {
    "zip": 74727,
    "lat": 34.01587,
    "lng": -95.85238,
    "state_id": "OK"
  },
  {
    "zip": 74728,
    "lat": 34.15341,
    "lng": -94.8017,
    "state_id": "OK"
  },
  {
    "zip": 74729,
    "lat": 34.14713,
    "lng": -96.25429,
    "state_id": "OK"
  },
  {
    "zip": 74730,
    "lat": 33.90485,
    "lng": -96.43335,
    "state_id": "OK"
  },
  {
    "zip": 74731,
    "lat": 33.87802,
    "lng": -96.56038,
    "state_id": "OK"
  },
  {
    "zip": 74733,
    "lat": 33.83108,
    "lng": -96.48261,
    "state_id": "OK"
  },
  {
    "zip": 74734,
    "lat": 34.10221,
    "lng": -94.54619,
    "state_id": "OK"
  },
  {
    "zip": 74735,
    "lat": 34.08558,
    "lng": -95.24951,
    "state_id": "OK"
  },
  {
    "zip": 74736,
    "lat": 33.92521,
    "lng": -94.98604,
    "state_id": "OK"
  },
  {
    "zip": 74738,
    "lat": 33.90368,
    "lng": -95.44285,
    "state_id": "OK"
  },
  {
    "zip": 74740,
    "lat": 33.7905,
    "lng": -94.59975,
    "state_id": "OK"
  },
  {
    "zip": 74741,
    "lat": 33.7792,
    "lng": -96.29667,
    "state_id": "OK"
  },
  {
    "zip": 74743,
    "lat": 34.00827,
    "lng": -95.49416,
    "state_id": "OK"
  },
  {
    "zip": 74745,
    "lat": 33.86516,
    "lng": -94.81143,
    "state_id": "OK"
  },
  {
    "zip": 74747,
    "lat": 33.77368,
    "lng": -96.35609,
    "state_id": "OK"
  },
  {
    "zip": 74748,
    "lat": 34.15879,
    "lng": -96.44041,
    "state_id": "OK"
  },
  {
    "zip": 74750,
    "lat": 33.96848,
    "lng": -95.02061,
    "state_id": "OK"
  },
  {
    "zip": 74753,
    "lat": 33.90794,
    "lng": -96.53658,
    "state_id": "OK"
  },
  {
    "zip": 74754,
    "lat": 34.20372,
    "lng": -95.12595,
    "state_id": "OK"
  },
  {
    "zip": 74755,
    "lat": 34.12945,
    "lng": -95.11729,
    "state_id": "OK"
  },
  {
    "zip": 74756,
    "lat": 34.07072,
    "lng": -95.36582,
    "state_id": "OK"
  },
  {
    "zip": 74759,
    "lat": 34.04818,
    "lng": -95.70961,
    "state_id": "OK"
  },
  {
    "zip": 74760,
    "lat": 34.15787,
    "lng": -95.36326,
    "state_id": "OK"
  },
  {
    "zip": 74761,
    "lat": 34.00962,
    "lng": -95.20167,
    "state_id": "OK"
  },
  {
    "zip": 74764,
    "lat": 34.04509,
    "lng": -95.09157,
    "state_id": "OK"
  },
  {
    "zip": 74766,
    "lat": 34.1501,
    "lng": -94.97465,
    "state_id": "OK"
  },
  {
    "zip": 74801,
    "lat": 35.31628,
    "lng": -96.9687,
    "state_id": "OK"
  },
  {
    "zip": 74804,
    "lat": 35.39285,
    "lng": -96.92029,
    "state_id": "OK"
  },
  {
    "zip": 74820,
    "lat": 34.78901,
    "lng": -96.71078,
    "state_id": "OK"
  },
  {
    "zip": 74824,
    "lat": 35.88647,
    "lng": -96.87417,
    "state_id": "OK"
  },
  {
    "zip": 74825,
    "lat": 34.8079,
    "lng": -96.408,
    "state_id": "OK"
  },
  {
    "zip": 74826,
    "lat": 35.01929,
    "lng": -96.90124,
    "state_id": "OK"
  },
  {
    "zip": 74827,
    "lat": 34.94201,
    "lng": -96.34218,
    "state_id": "OK"
  },
  {
    "zip": 74829,
    "lat": 35.51735,
    "lng": -96.47046,
    "state_id": "OK"
  },
  {
    "zip": 74830,
    "lat": 35.1439,
    "lng": -96.66498,
    "state_id": "OK"
  },
  {
    "zip": 74831,
    "lat": 34.89861,
    "lng": -97.06026,
    "state_id": "OK"
  },
  {
    "zip": 74832,
    "lat": 35.82626,
    "lng": -97.01138,
    "state_id": "OK"
  },
  {
    "zip": 74833,
    "lat": 35.57666,
    "lng": -96.41107,
    "state_id": "OK"
  },
  {
    "zip": 74834,
    "lat": 35.70136,
    "lng": -96.88249,
    "state_id": "OK"
  },
  {
    "zip": 74836,
    "lat": 34.45383,
    "lng": -96.67933,
    "state_id": "OK"
  },
  {
    "zip": 74837,
    "lat": 35.35878,
    "lng": -96.45122,
    "state_id": "OK"
  },
  {
    "zip": 74839,
    "lat": 35.23981,
    "lng": -96.03006,
    "state_id": "OK"
  },
  {
    "zip": 74840,
    "lat": 35.30844,
    "lng": -96.7699,
    "state_id": "OK"
  },
  {
    "zip": 74842,
    "lat": 34.62514,
    "lng": -96.61247,
    "state_id": "OK"
  },
  {
    "zip": 74843,
    "lat": 34.65642,
    "lng": -96.72252,
    "state_id": "OK"
  },
  {
    "zip": 74844,
    "lat": 34.8691,
    "lng": -96.57785,
    "state_id": "OK"
  },
  {
    "zip": 74845,
    "lat": 35.25754,
    "lng": -95.8872,
    "state_id": "OK"
  },
  {
    "zip": 74848,
    "lat": 35.08207,
    "lng": -96.36396,
    "state_id": "OK"
  },
  {
    "zip": 74849,
    "lat": 34.98447,
    "lng": -96.73768,
    "state_id": "OK"
  },
  {
    "zip": 74850,
    "lat": 35.11314,
    "lng": -96.09218,
    "state_id": "OK"
  },
  {
    "zip": 74851,
    "lat": 35.42615,
    "lng": -97.07904,
    "state_id": "OK"
  },
  {
    "zip": 74852,
    "lat": 35.12579,
    "lng": -97.00984,
    "state_id": "OK"
  },
  {
    "zip": 74854,
    "lat": 35.10615,
    "lng": -96.74676,
    "state_id": "OK"
  },
  {
    "zip": 74855,
    "lat": 35.51676,
    "lng": -96.90011,
    "state_id": "OK"
  },
  {
    "zip": 74856,
    "lat": 34.41254,
    "lng": -96.81974,
    "state_id": "OK"
  },
  {
    "zip": 74857,
    "lat": 35.34012,
    "lng": -97.19639,
    "state_id": "OK"
  },
  {
    "zip": 74859,
    "lat": 35.46304,
    "lng": -96.31757,
    "state_id": "OK"
  },
  {
    "zip": 74860,
    "lat": 35.52876,
    "lng": -96.56776,
    "state_id": "OK"
  },
  {
    "zip": 74864,
    "lat": 35.48955,
    "lng": -96.70257,
    "state_id": "OK"
  },
  {
    "zip": 74865,
    "lat": 34.60484,
    "lng": -96.79568,
    "state_id": "OK"
  },
  {
    "zip": 74867,
    "lat": 34.96441,
    "lng": -96.55666,
    "state_id": "OK"
  },
  {
    "zip": 74868,
    "lat": 35.2672,
    "lng": -96.64619,
    "state_id": "OK"
  },
  {
    "zip": 74869,
    "lat": 35.60199,
    "lng": -96.75467,
    "state_id": "OK"
  },
  {
    "zip": 74871,
    "lat": 34.60391,
    "lng": -96.54581,
    "state_id": "OK"
  },
  {
    "zip": 74872,
    "lat": 34.7809,
    "lng": -96.98194,
    "state_id": "OK"
  },
  {
    "zip": 74873,
    "lat": 35.21879,
    "lng": -96.97342,
    "state_id": "OK"
  },
  {
    "zip": 74875,
    "lat": 35.87711,
    "lng": -96.97383,
    "state_id": "OK"
  },
  {
    "zip": 74878,
    "lat": 35.00907,
    "lng": -97.07006,
    "state_id": "OK"
  },
  {
    "zip": 74880,
    "lat": 35.37784,
    "lng": -96.11953,
    "state_id": "OK"
  },
  {
    "zip": 74881,
    "lat": 35.7185,
    "lng": -97.08275,
    "state_id": "OK"
  },
  {
    "zip": 74883,
    "lat": 35.22787,
    "lng": -96.22614,
    "state_id": "OK"
  },
  {
    "zip": 74884,
    "lat": 35.19212,
    "lng": -96.51603,
    "state_id": "OK"
  },
  {
    "zip": 74901,
    "lat": 35.34831,
    "lng": -94.45818,
    "state_id": "OK"
  },
  {
    "zip": 74902,
    "lat": 35.24463,
    "lng": -94.48039,
    "state_id": "OK"
  },
  {
    "zip": 74930,
    "lat": 35.18553,
    "lng": -94.77969,
    "state_id": "OK"
  },
  {
    "zip": 74931,
    "lat": 35.69476,
    "lng": -94.7575,
    "state_id": "OK"
  },
  {
    "zip": 74932,
    "lat": 35.14647,
    "lng": -94.53069,
    "state_id": "OK"
  },
  {
    "zip": 74935,
    "lat": 34.94842,
    "lng": -94.89681,
    "state_id": "OK"
  },
  {
    "zip": 74936,
    "lat": 35.378,
    "lng": -94.71702,
    "state_id": "OK"
  },
  {
    "zip": 74937,
    "lat": 34.82535,
    "lng": -94.57625,
    "state_id": "OK"
  },
  {
    "zip": 74939,
    "lat": 34.70454,
    "lng": -94.61531,
    "state_id": "OK"
  },
  {
    "zip": 74940,
    "lat": 34.95419,
    "lng": -94.62583,
    "state_id": "OK"
  },
  {
    "zip": 74941,
    "lat": 35.28547,
    "lng": -94.87577,
    "state_id": "OK"
  },
  {
    "zip": 74942,
    "lat": 34.89905,
    "lng": -94.97703,
    "state_id": "OK"
  },
  {
    "zip": 74943,
    "lat": 35.08201,
    "lng": -95.07576,
    "state_id": "OK"
  },
  {
    "zip": 74944,
    "lat": 35.13027,
    "lng": -94.99735,
    "state_id": "OK"
  },
  {
    "zip": 74945,
    "lat": 35.59721,
    "lng": -94.80933,
    "state_id": "OK"
  },
  {
    "zip": 74946,
    "lat": 35.3917,
    "lng": -94.45244,
    "state_id": "OK"
  },
  {
    "zip": 74948,
    "lat": 35.46558,
    "lng": -94.56103,
    "state_id": "OK"
  },
  {
    "zip": 74949,
    "lat": 34.66108,
    "lng": -94.76836,
    "state_id": "OK"
  },
  {
    "zip": 74951,
    "lat": 35.17041,
    "lng": -94.65564,
    "state_id": "OK"
  },
  {
    "zip": 74953,
    "lat": 35.0447,
    "lng": -94.60707,
    "state_id": "OK"
  },
  {
    "zip": 74954,
    "lat": 35.42669,
    "lng": -94.49701,
    "state_id": "OK"
  },
  {
    "zip": 74955,
    "lat": 35.49389,
    "lng": -94.7566,
    "state_id": "OK"
  },
  {
    "zip": 74956,
    "lat": 35.10959,
    "lng": -94.76291,
    "state_id": "OK"
  },
  {
    "zip": 74957,
    "lat": 34.52613,
    "lng": -94.65681,
    "state_id": "OK"
  },
  {
    "zip": 74959,
    "lat": 35.26246,
    "lng": -94.6184,
    "state_id": "OK"
  },
  {
    "zip": 74960,
    "lat": 35.81041,
    "lng": -94.64973,
    "state_id": "OK"
  },
  {
    "zip": 74962,
    "lat": 35.53333,
    "lng": -94.95251,
    "state_id": "OK"
  },
  {
    "zip": 74963,
    "lat": 34.39814,
    "lng": -94.57588,
    "state_id": "OK"
  },
  {
    "zip": 74964,
    "lat": 36.11706,
    "lng": -94.66437,
    "state_id": "OK"
  },
  {
    "zip": 74965,
    "lat": 35.99871,
    "lng": -94.62549,
    "state_id": "OK"
  },
  {
    "zip": 74966,
    "lat": 34.91121,
    "lng": -94.86892,
    "state_id": "OK"
  },
  {
    "zip": 97001,
    "lat": 44.92441,
    "lng": -120.65,
    "state_id": "OR"
  },
  {
    "zip": 97002,
    "lat": 45.23708,
    "lng": -122.79898,
    "state_id": "OR"
  },
  {
    "zip": 97004,
    "lat": 45.25113,
    "lng": -122.46006,
    "state_id": "OR"
  },
  {
    "zip": 97005,
    "lat": 45.49095,
    "lng": -122.8035,
    "state_id": "OR"
  },
  {
    "zip": 97006,
    "lat": 45.51704,
    "lng": -122.85988,
    "state_id": "OR"
  },
  {
    "zip": 97007,
    "lat": 45.45435,
    "lng": -122.87964,
    "state_id": "OR"
  },
  {
    "zip": 97008,
    "lat": 45.46022,
    "lng": -122.80417,
    "state_id": "OR"
  },
  {
    "zip": 97009,
    "lat": 45.42427,
    "lng": -122.33785,
    "state_id": "OR"
  },
  {
    "zip": 97011,
    "lat": 45.38724,
    "lng": -122.0265,
    "state_id": "OR"
  },
  {
    "zip": 97013,
    "lat": 45.21812,
    "lng": -122.67168,
    "state_id": "OR"
  },
  {
    "zip": 97014,
    "lat": 45.58407,
    "lng": -122.01834,
    "state_id": "OR"
  },
  {
    "zip": 97015,
    "lat": 45.41478,
    "lng": -122.53835,
    "state_id": "OR"
  },
  {
    "zip": 97016,
    "lat": 46.05878,
    "lng": -123.2688,
    "state_id": "OR"
  },
  {
    "zip": 97017,
    "lat": 45.17177,
    "lng": -122.38501,
    "state_id": "OR"
  },
  {
    "zip": 97018,
    "lat": 45.89497,
    "lng": -122.81081,
    "state_id": "OR"
  },
  {
    "zip": 97019,
    "lat": 45.51019,
    "lng": -122.21108,
    "state_id": "OR"
  },
  {
    "zip": 97020,
    "lat": 45.22204,
    "lng": -122.83756,
    "state_id": "OR"
  },
  {
    "zip": 97021,
    "lat": 45.3871,
    "lng": -121.14216,
    "state_id": "OR"
  },
  {
    "zip": 97022,
    "lat": 45.34598,
    "lng": -122.33038,
    "state_id": "OR"
  },
  {
    "zip": 97023,
    "lat": 45.23533,
    "lng": -122.2261,
    "state_id": "OR"
  },
  {
    "zip": 97024,
    "lat": 45.54724,
    "lng": -122.44146,
    "state_id": "OR"
  },
  {
    "zip": 97026,
    "lat": 45.10668,
    "lng": -122.95108,
    "state_id": "OR"
  },
  {
    "zip": 97027,
    "lat": 45.38692,
    "lng": -122.59285,
    "state_id": "OR"
  },
  {
    "zip": 97028,
    "lat": 45.28232,
    "lng": -121.75272,
    "state_id": "OR"
  },
  {
    "zip": 97029,
    "lat": 45.28737,
    "lng": -120.80847,
    "state_id": "OR"
  },
  {
    "zip": 97030,
    "lat": 45.50897,
    "lng": -122.43189,
    "state_id": "OR"
  },
  {
    "zip": 97031,
    "lat": 45.6247,
    "lng": -121.54547,
    "state_id": "OR"
  },
  {
    "zip": 97032,
    "lat": 45.17924,
    "lng": -122.7853,
    "state_id": "OR"
  },
  {
    "zip": 97033,
    "lat": 45.23361,
    "lng": -120.5876,
    "state_id": "OR"
  },
  {
    "zip": 97034,
    "lat": 45.40926,
    "lng": -122.68384,
    "state_id": "OR"
  },
  {
    "zip": 97035,
    "lat": 45.41321,
    "lng": -122.72514,
    "state_id": "OR"
  },
  {
    "zip": 97037,
    "lat": 45.06757,
    "lng": -121.02609,
    "state_id": "OR"
  },
  {
    "zip": 97038,
    "lat": 45.08756,
    "lng": -122.55767,
    "state_id": "OR"
  },
  {
    "zip": 97039,
    "lat": 45.45601,
    "lng": -120.66447,
    "state_id": "OR"
  },
  {
    "zip": 97040,
    "lat": 45.62479,
    "lng": -121.38804,
    "state_id": "OR"
  },
  {
    "zip": 97041,
    "lat": 45.4397,
    "lng": -121.61989,
    "state_id": "OR"
  },
  {
    "zip": 97042,
    "lat": 45.20577,
    "lng": -122.53452,
    "state_id": "OR"
  },
  {
    "zip": 97045,
    "lat": 45.32843,
    "lng": -122.53074,
    "state_id": "OR"
  },
  {
    "zip": 97048,
    "lat": 46.04457,
    "lng": -122.98087,
    "state_id": "OR"
  },
  {
    "zip": 97049,
    "lat": 45.35859,
    "lng": -121.86511,
    "state_id": "OR"
  },
  {
    "zip": 97050,
    "lat": 45.68281,
    "lng": -120.77134,
    "state_id": "OR"
  },
  {
    "zip": 97051,
    "lat": 45.8847,
    "lng": -122.91213,
    "state_id": "OR"
  },
  {
    "zip": 97053,
    "lat": 45.82711,
    "lng": -122.88583,
    "state_id": "OR"
  },
  {
    "zip": 97054,
    "lat": 45.94813,
    "lng": -122.93373,
    "state_id": "OR"
  },
  {
    "zip": 97055,
    "lat": 45.38866,
    "lng": -122.15507,
    "state_id": "OR"
  },
  {
    "zip": 97056,
    "lat": 45.78126,
    "lng": -122.9547,
    "state_id": "OR"
  },
  {
    "zip": 97057,
    "lat": 45.00276,
    "lng": -120.7499,
    "state_id": "OR"
  },
  {
    "zip": 97058,
    "lat": 45.53878,
    "lng": -121.15334,
    "state_id": "OR"
  },
  {
    "zip": 97060,
    "lat": 45.53423,
    "lng": -122.37027,
    "state_id": "OR"
  },
  {
    "zip": 97062,
    "lat": 45.36875,
    "lng": -122.76196,
    "state_id": "OR"
  },
  {
    "zip": 97063,
    "lat": 45.22297,
    "lng": -121.29255,
    "state_id": "OR"
  },
  {
    "zip": 97064,
    "lat": 45.87265,
    "lng": -123.22833,
    "state_id": "OR"
  },
  {
    "zip": 97065,
    "lat": 45.59894,
    "lng": -120.65763,
    "state_id": "OR"
  },
  {
    "zip": 97067,
    "lat": 45.30635,
    "lng": -122.01959,
    "state_id": "OR"
  },
  {
    "zip": 97068,
    "lat": 45.35303,
    "lng": -122.66929,
    "state_id": "OR"
  },
  {
    "zip": 97070,
    "lat": 45.30759,
    "lng": -122.77154,
    "state_id": "OR"
  },
  {
    "zip": 97071,
    "lat": 45.13592,
    "lng": -122.82654,
    "state_id": "OR"
  },
  {
    "zip": 97080,
    "lat": 45.47847,
    "lng": -122.38954,
    "state_id": "OR"
  },
  {
    "zip": 97086,
    "lat": 45.44519,
    "lng": -122.52821,
    "state_id": "OR"
  },
  {
    "zip": 97089,
    "lat": 45.42549,
    "lng": -122.44332,
    "state_id": "OR"
  },
  {
    "zip": 97101,
    "lat": 45.09043,
    "lng": -123.21732,
    "state_id": "OR"
  },
  {
    "zip": 97102,
    "lat": 45.79203,
    "lng": -123.95464,
    "state_id": "OR"
  },
  {
    "zip": 97103,
    "lat": 46.13219,
    "lng": -123.70323,
    "state_id": "OR"
  },
  {
    "zip": 97106,
    "lat": 45.65471,
    "lng": -123.13347,
    "state_id": "OR"
  },
  {
    "zip": 97107,
    "lat": 45.55435,
    "lng": -123.88077,
    "state_id": "OR"
  },
  {
    "zip": 97108,
    "lat": 45.26893,
    "lng": -123.70879,
    "state_id": "OR"
  },
  {
    "zip": 97109,
    "lat": 45.73784,
    "lng": -123.18112,
    "state_id": "OR"
  },
  {
    "zip": 97110,
    "lat": 45.90103,
    "lng": -123.9554,
    "state_id": "OR"
  },
  {
    "zip": 97111,
    "lat": 45.2916,
    "lng": -123.1957,
    "state_id": "OR"
  },
  {
    "zip": 97112,
    "lat": 45.26032,
    "lng": -123.86917,
    "state_id": "OR"
  },
  {
    "zip": 97113,
    "lat": 45.50548,
    "lng": -123.04618,
    "state_id": "OR"
  },
  {
    "zip": 97114,
    "lat": 45.1824,
    "lng": -123.07179,
    "state_id": "OR"
  },
  {
    "zip": 97115,
    "lat": 45.27229,
    "lng": -123.02701,
    "state_id": "OR"
  },
  {
    "zip": 97116,
    "lat": 45.58259,
    "lng": -123.17353,
    "state_id": "OR"
  },
  {
    "zip": 97117,
    "lat": 45.61289,
    "lng": -123.28747,
    "state_id": "OR"
  },
  {
    "zip": 97118,
    "lat": 45.56101,
    "lng": -123.91122,
    "state_id": "OR"
  },
  {
    "zip": 97119,
    "lat": 45.46866,
    "lng": -123.19967,
    "state_id": "OR"
  },
  {
    "zip": 97121,
    "lat": 46.18212,
    "lng": -123.95678,
    "state_id": "OR"
  },
  {
    "zip": 97122,
    "lat": 45.1763,
    "lng": -123.81958,
    "state_id": "OR"
  },
  {
    "zip": 97123,
    "lat": 45.44163,
    "lng": -122.98413,
    "state_id": "OR"
  },
  {
    "zip": 97124,
    "lat": 45.56783,
    "lng": -122.94644,
    "state_id": "OR"
  },
  {
    "zip": 97125,
    "lat": 45.66742,
    "lng": -123.21216,
    "state_id": "OR"
  },
  {
    "zip": 97127,
    "lat": 45.2461,
    "lng": -123.11145,
    "state_id": "OR"
  },
  {
    "zip": 97128,
    "lat": 45.19704,
    "lng": -123.25457,
    "state_id": "OR"
  },
  {
    "zip": 97130,
    "lat": 45.69632,
    "lng": -123.92251,
    "state_id": "OR"
  },
  {
    "zip": 97131,
    "lat": 45.74026,
    "lng": -123.82081,
    "state_id": "OR"
  },
  {
    "zip": 97132,
    "lat": 45.32229,
    "lng": -122.9871,
    "state_id": "OR"
  },
  {
    "zip": 97133,
    "lat": 45.68195,
    "lng": -123.01959,
    "state_id": "OR"
  },
  {
    "zip": 97134,
    "lat": 45.45734,
    "lng": -123.96575,
    "state_id": "OR"
  },
  {
    "zip": 97135,
    "lat": 45.19514,
    "lng": -123.95611,
    "state_id": "OR"
  },
  {
    "zip": 97136,
    "lat": 45.62439,
    "lng": -123.91609,
    "state_id": "OR"
  },
  {
    "zip": 97137,
    "lat": 45.21609,
    "lng": -122.95879,
    "state_id": "OR"
  },
  {
    "zip": 97138,
    "lat": 45.90188,
    "lng": -123.67147,
    "state_id": "OR"
  },
  {
    "zip": 97140,
    "lat": 45.35504,
    "lng": -122.86426,
    "state_id": "OR"
  },
  {
    "zip": 97141,
    "lat": 45.47146,
    "lng": -123.7552,
    "state_id": "OR"
  },
  {
    "zip": 97144,
    "lat": 45.74048,
    "lng": -123.30958,
    "state_id": "OR"
  },
  {
    "zip": 97145,
    "lat": 45.85103,
    "lng": -123.95067,
    "state_id": "OR"
  },
  {
    "zip": 97146,
    "lat": 46.13148,
    "lng": -123.91986,
    "state_id": "OR"
  },
  {
    "zip": 97147,
    "lat": 45.68879,
    "lng": -123.88215,
    "state_id": "OR"
  },
  {
    "zip": 97148,
    "lat": 45.35589,
    "lng": -123.24658,
    "state_id": "OR"
  },
  {
    "zip": 97149,
    "lat": 45.10521,
    "lng": -123.94853,
    "state_id": "OR"
  },
  {
    "zip": 97201,
    "lat": 45.50777,
    "lng": -122.68975,
    "state_id": "OR"
  },
  {
    "zip": 97202,
    "lat": 45.48292,
    "lng": -122.64407,
    "state_id": "OR"
  },
  {
    "zip": 97203,
    "lat": 45.60644,
    "lng": -122.75365,
    "state_id": "OR"
  },
  {
    "zip": 97204,
    "lat": 45.51832,
    "lng": -122.6738,
    "state_id": "OR"
  },
  {
    "zip": 97205,
    "lat": 45.519,
    "lng": -122.70202,
    "state_id": "OR"
  },
  {
    "zip": 97206,
    "lat": 45.48188,
    "lng": -122.5986,
    "state_id": "OR"
  },
  {
    "zip": 97208,
    "lat": 45.52866,
    "lng": -122.67898,
    "state_id": "OR"
  },
  {
    "zip": 97209,
    "lat": 45.53095,
    "lng": -122.68439,
    "state_id": "OR"
  },
  {
    "zip": 97210,
    "lat": 45.54692,
    "lng": -122.73,
    "state_id": "OR"
  },
  {
    "zip": 97211,
    "lat": 45.58133,
    "lng": -122.63421,
    "state_id": "OR"
  },
  {
    "zip": 97212,
    "lat": 45.54426,
    "lng": -122.64367,
    "state_id": "OR"
  },
  {
    "zip": 97213,
    "lat": 45.5378,
    "lng": -122.59963,
    "state_id": "OR"
  },
  {
    "zip": 97214,
    "lat": 45.5143,
    "lng": -122.64309,
    "state_id": "OR"
  },
  {
    "zip": 97215,
    "lat": 45.51468,
    "lng": -122.59959,
    "state_id": "OR"
  },
  {
    "zip": 97216,
    "lat": 45.51388,
    "lng": -122.55837,
    "state_id": "OR"
  },
  {
    "zip": 97217,
    "lat": 45.59995,
    "lng": -122.70386,
    "state_id": "OR"
  },
  {
    "zip": 97218,
    "lat": 45.57677,
    "lng": -122.60074,
    "state_id": "OR"
  },
  {
    "zip": 97219,
    "lat": 45.45395,
    "lng": -122.6998,
    "state_id": "OR"
  },
  {
    "zip": 97220,
    "lat": 45.54971,
    "lng": -122.55917,
    "state_id": "OR"
  },
  {
    "zip": 97221,
    "lat": 45.49797,
    "lng": -122.7278,
    "state_id": "OR"
  },
  {
    "zip": 97222,
    "lat": 45.44129,
    "lng": -122.61709,
    "state_id": "OR"
  },
  {
    "zip": 97223,
    "lat": 45.4403,
    "lng": -122.7795,
    "state_id": "OR"
  },
  {
    "zip": 97224,
    "lat": 45.40551,
    "lng": -122.7951,
    "state_id": "OR"
  },
  {
    "zip": 97225,
    "lat": 45.50218,
    "lng": -122.77008,
    "state_id": "OR"
  },
  {
    "zip": 97227,
    "lat": 45.54429,
    "lng": -122.67855,
    "state_id": "OR"
  },
  {
    "zip": 97229,
    "lat": 45.5506,
    "lng": -122.80998,
    "state_id": "OR"
  },
  {
    "zip": 97230,
    "lat": 45.55813,
    "lng": -122.50746,
    "state_id": "OR"
  },
  {
    "zip": 97231,
    "lat": 45.67949,
    "lng": -122.82746,
    "state_id": "OR"
  },
  {
    "zip": 97232,
    "lat": 45.52919,
    "lng": -122.64377,
    "state_id": "OR"
  },
  {
    "zip": 97233,
    "lat": 45.514,
    "lng": -122.49948,
    "state_id": "OR"
  },
  {
    "zip": 97236,
    "lat": 45.4847,
    "lng": -122.51053,
    "state_id": "OR"
  },
  {
    "zip": 97239,
    "lat": 45.48842,
    "lng": -122.69061,
    "state_id": "OR"
  },
  {
    "zip": 97266,
    "lat": 45.48275,
    "lng": -122.55838,
    "state_id": "OR"
  },
  {
    "zip": 97267,
    "lat": 45.40772,
    "lng": -122.61488,
    "state_id": "OR"
  },
  {
    "zip": 97301,
    "lat": 44.94903,
    "lng": -123.00399,
    "state_id": "OR"
  },
  {
    "zip": 97302,
    "lat": 44.90187,
    "lng": -123.06785,
    "state_id": "OR"
  },
  {
    "zip": 97303,
    "lat": 45.03045,
    "lng": -123.02438,
    "state_id": "OR"
  },
  {
    "zip": 97304,
    "lat": 45.00899,
    "lng": -123.1081,
    "state_id": "OR"
  },
  {
    "zip": 97305,
    "lat": 45.01433,
    "lng": -122.92872,
    "state_id": "OR"
  },
  {
    "zip": 97306,
    "lat": 44.84465,
    "lng": -123.07971,
    "state_id": "OR"
  },
  {
    "zip": 97317,
    "lat": 44.90261,
    "lng": -122.90736,
    "state_id": "OR"
  },
  {
    "zip": 97321,
    "lat": 44.65193,
    "lng": -123.13328,
    "state_id": "OR"
  },
  {
    "zip": 97322,
    "lat": 44.62606,
    "lng": -123.01891,
    "state_id": "OR"
  },
  {
    "zip": 97324,
    "lat": 44.35569,
    "lng": -123.62686,
    "state_id": "OR"
  },
  {
    "zip": 97325,
    "lat": 44.83135,
    "lng": -122.85173,
    "state_id": "OR"
  },
  {
    "zip": 97326,
    "lat": 44.61593,
    "lng": -123.60118,
    "state_id": "OR"
  },
  {
    "zip": 97327,
    "lat": 44.37503,
    "lng": -122.9476,
    "state_id": "OR"
  },
  {
    "zip": 97329,
    "lat": 44.41125,
    "lng": -122.38453,
    "state_id": "OR"
  },
  {
    "zip": 97330,
    "lat": 44.63646,
    "lng": -123.27657,
    "state_id": "OR"
  },
  {
    "zip": 97331,
    "lat": 44.56463,
    "lng": -123.28037,
    "state_id": "OR"
  },
  {
    "zip": 97333,
    "lat": 44.47368,
    "lng": -123.30732,
    "state_id": "OR"
  },
  {
    "zip": 97338,
    "lat": 44.92701,
    "lng": -123.3463,
    "state_id": "OR"
  },
  {
    "zip": 97341,
    "lat": 44.80892,
    "lng": -124.05122,
    "state_id": "OR"
  },
  {
    "zip": 97342,
    "lat": 44.73974,
    "lng": -121.88891,
    "state_id": "OR"
  },
  {
    "zip": 97343,
    "lat": 44.55856,
    "lng": -123.7434,
    "state_id": "OR"
  },
  {
    "zip": 97344,
    "lat": 44.86914,
    "lng": -123.46455,
    "state_id": "OR"
  },
  {
    "zip": 97345,
    "lat": 44.41464,
    "lng": -122.57084,
    "state_id": "OR"
  },
  {
    "zip": 97346,
    "lat": 44.76901,
    "lng": -122.35788,
    "state_id": "OR"
  },
  {
    "zip": 97347,
    "lat": 45.07708,
    "lng": -123.65654,
    "state_id": "OR"
  },
  {
    "zip": 97348,
    "lat": 44.38452,
    "lng": -123.12296,
    "state_id": "OR"
  },
  {
    "zip": 97350,
    "lat": 44.64076,
    "lng": -121.91593,
    "state_id": "OR"
  },
  {
    "zip": 97351,
    "lat": 44.83831,
    "lng": -123.17489,
    "state_id": "OR"
  },
  {
    "zip": 97352,
    "lat": 44.75148,
    "lng": -123.03139,
    "state_id": "OR"
  },
  {
    "zip": 97355,
    "lat": 44.53097,
    "lng": -122.82268,
    "state_id": "OR"
  },
  {
    "zip": 97357,
    "lat": 44.73947,
    "lng": -123.80475,
    "state_id": "OR"
  },
  {
    "zip": 97358,
    "lat": 44.80596,
    "lng": -122.41273,
    "state_id": "OR"
  },
  {
    "zip": 97360,
    "lat": 44.76528,
    "lng": -122.47458,
    "state_id": "OR"
  },
  {
    "zip": 97361,
    "lat": 44.7692,
    "lng": -123.33919,
    "state_id": "OR"
  },
  {
    "zip": 97362,
    "lat": 45.06916,
    "lng": -122.77021,
    "state_id": "OR"
  },
  {
    "zip": 97364,
    "lat": 44.99901,
    "lng": -123.9868,
    "state_id": "OR"
  },
  {
    "zip": 97365,
    "lat": 44.66872,
    "lng": -124.01448,
    "state_id": "OR"
  },
  {
    "zip": 97366,
    "lat": 44.57712,
    "lng": -124.05101,
    "state_id": "OR"
  },
  {
    "zip": 97367,
    "lat": 44.91557,
    "lng": -123.97531,
    "state_id": "OR"
  },
  {
    "zip": 97368,
    "lat": 45.00593,
    "lng": -123.90625,
    "state_id": "OR"
  },
  {
    "zip": 97369,
    "lat": 44.76165,
    "lng": -124.05169,
    "state_id": "OR"
  },
  {
    "zip": 97370,
    "lat": 44.56894,
    "lng": -123.46029,
    "state_id": "OR"
  },
  {
    "zip": 97371,
    "lat": 44.98779,
    "lng": -123.20653,
    "state_id": "OR"
  },
  {
    "zip": 97373,
    "lat": 45.05708,
    "lng": -122.77175,
    "state_id": "OR"
  },
  {
    "zip": 97374,
    "lat": 44.6927,
    "lng": -122.78396,
    "state_id": "OR"
  },
  {
    "zip": 97375,
    "lat": 44.97735,
    "lng": -122.59641,
    "state_id": "OR"
  },
  {
    "zip": 97376,
    "lat": 44.5085,
    "lng": -123.98034,
    "state_id": "OR"
  },
  {
    "zip": 97377,
    "lat": 44.46393,
    "lng": -123.10471,
    "state_id": "OR"
  },
  {
    "zip": 97378,
    "lat": 45.07352,
    "lng": -123.42961,
    "state_id": "OR"
  },
  {
    "zip": 97380,
    "lat": 44.77822,
    "lng": -123.93679,
    "state_id": "OR"
  },
  {
    "zip": 97381,
    "lat": 44.97268,
    "lng": -122.74572,
    "state_id": "OR"
  },
  {
    "zip": 97383,
    "lat": 44.79361,
    "lng": -122.72171,
    "state_id": "OR"
  },
  {
    "zip": 97384,
    "lat": 44.791,
    "lng": -122.61888,
    "state_id": "OR"
  },
  {
    "zip": 97385,
    "lat": 44.86655,
    "lng": -122.72835,
    "state_id": "OR"
  },
  {
    "zip": 97386,
    "lat": 44.35982,
    "lng": -122.75171,
    "state_id": "OR"
  },
  {
    "zip": 97388,
    "lat": 44.89207,
    "lng": -124.02187,
    "state_id": "OR"
  },
  {
    "zip": 97389,
    "lat": 44.53352,
    "lng": -123.08975,
    "state_id": "OR"
  },
  {
    "zip": 97390,
    "lat": 44.31498,
    "lng": -123.85884,
    "state_id": "OR"
  },
  {
    "zip": 97391,
    "lat": 44.62573,
    "lng": -123.89577,
    "state_id": "OR"
  },
  {
    "zip": 97392,
    "lat": 44.78939,
    "lng": -122.94136,
    "state_id": "OR"
  },
  {
    "zip": 97394,
    "lat": 44.42605,
    "lng": -123.9772,
    "state_id": "OR"
  },
  {
    "zip": 97396,
    "lat": 45.11938,
    "lng": -123.52159,
    "state_id": "OR"
  },
  {
    "zip": 97401,
    "lat": 44.06825,
    "lng": -123.08181,
    "state_id": "OR"
  },
  {
    "zip": 97402,
    "lat": 44.05156,
    "lng": -123.22999,
    "state_id": "OR"
  },
  {
    "zip": 97403,
    "lat": 44.03582,
    "lng": -123.05299,
    "state_id": "OR"
  },
  {
    "zip": 97404,
    "lat": 44.10486,
    "lng": -123.13298,
    "state_id": "OR"
  },
  {
    "zip": 97405,
    "lat": 43.93975,
    "lng": -123.19156,
    "state_id": "OR"
  },
  {
    "zip": 97406,
    "lat": 42.62136,
    "lng": -124.014,
    "state_id": "OR"
  },
  {
    "zip": 97408,
    "lat": 44.14466,
    "lng": -123.05446,
    "state_id": "OR"
  },
  {
    "zip": 97410,
    "lat": 42.79896,
    "lng": -123.14789,
    "state_id": "OR"
  },
  {
    "zip": 97411,
    "lat": 43.07421,
    "lng": -124.35919,
    "state_id": "OR"
  },
  {
    "zip": 97412,
    "lat": 44.19597,
    "lng": -123.56091,
    "state_id": "OR"
  },
  {
    "zip": 97413,
    "lat": 44.1006,
    "lng": -122.02805,
    "state_id": "OR"
  },
  {
    "zip": 97414,
    "lat": 42.96512,
    "lng": -124.17391,
    "state_id": "OR"
  },
  {
    "zip": 97415,
    "lat": 42.13701,
    "lng": -124.23586,
    "state_id": "OR"
  },
  {
    "zip": 97416,
    "lat": 43.03589,
    "lng": -123.6854,
    "state_id": "OR"
  },
  {
    "zip": 97417,
    "lat": 42.95311,
    "lng": -123.23005,
    "state_id": "OR"
  },
  {
    "zip": 97419,
    "lat": 44.17576,
    "lng": -123.40945,
    "state_id": "OR"
  },
  {
    "zip": 97420,
    "lat": 43.35487,
    "lng": -124.14625,
    "state_id": "OR"
  },
  {
    "zip": 97423,
    "lat": 43.19484,
    "lng": -124.17293,
    "state_id": "OR"
  },
  {
    "zip": 97424,
    "lat": 43.74448,
    "lng": -123.05447,
    "state_id": "OR"
  },
  {
    "zip": 97426,
    "lat": 43.89992,
    "lng": -123.0367,
    "state_id": "OR"
  },
  {
    "zip": 97429,
    "lat": 42.96834,
    "lng": -123.06351,
    "state_id": "OR"
  },
  {
    "zip": 97430,
    "lat": 44.17494,
    "lng": -123.70523,
    "state_id": "OR"
  },
  {
    "zip": 97431,
    "lat": 43.87446,
    "lng": -122.81868,
    "state_id": "OR"
  },
  {
    "zip": 97434,
    "lat": 43.66775,
    "lng": -122.79333,
    "state_id": "OR"
  },
  {
    "zip": 97435,
    "lat": 43.70037,
    "lng": -123.33489,
    "state_id": "OR"
  },
  {
    "zip": 97436,
    "lat": 43.64043,
    "lng": -123.59204,
    "state_id": "OR"
  },
  {
    "zip": 97437,
    "lat": 44.09688,
    "lng": -123.38908,
    "state_id": "OR"
  },
  {
    "zip": 97438,
    "lat": 43.95088,
    "lng": -122.68838,
    "state_id": "OR"
  },
  {
    "zip": 97439,
    "lat": 44.06609,
    "lng": -124.03756,
    "state_id": "OR"
  },
  {
    "zip": 97441,
    "lat": 43.75104,
    "lng": -124.15651,
    "state_id": "OR"
  },
  {
    "zip": 97442,
    "lat": 42.77045,
    "lng": -123.40467,
    "state_id": "OR"
  },
  {
    "zip": 97443,
    "lat": 43.24704,
    "lng": -123.01073,
    "state_id": "OR"
  },
  {
    "zip": 97444,
    "lat": 42.47962,
    "lng": -124.34441,
    "state_id": "OR"
  },
  {
    "zip": 97446,
    "lat": 44.26785,
    "lng": -123.0575,
    "state_id": "OR"
  },
  {
    "zip": 97447,
    "lat": 43.27536,
    "lng": -122.51546,
    "state_id": "OR"
  },
  {
    "zip": 97448,
    "lat": 44.21054,
    "lng": -123.2815,
    "state_id": "OR"
  },
  {
    "zip": 97449,
    "lat": 43.57609,
    "lng": -124.08389,
    "state_id": "OR"
  },
  {
    "zip": 97450,
    "lat": 42.90428,
    "lng": -124.39742,
    "state_id": "OR"
  },
  {
    "zip": 97451,
    "lat": 43.8152,
    "lng": -123.26791,
    "state_id": "OR"
  },
  {
    "zip": 97452,
    "lat": 43.8805,
    "lng": -122.72121,
    "state_id": "OR"
  },
  {
    "zip": 97453,
    "lat": 43.99817,
    "lng": -123.85948,
    "state_id": "OR"
  },
  {
    "zip": 97454,
    "lat": 44.22157,
    "lng": -122.82168,
    "state_id": "OR"
  },
  {
    "zip": 97455,
    "lat": 43.9646,
    "lng": -122.91734,
    "state_id": "OR"
  },
  {
    "zip": 97456,
    "lat": 44.34026,
    "lng": -123.36052,
    "state_id": "OR"
  },
  {
    "zip": 97457,
    "lat": 43.05435,
    "lng": -123.22019,
    "state_id": "OR"
  },
  {
    "zip": 97458,
    "lat": 43.05967,
    "lng": -124.01846,
    "state_id": "OR"
  },
  {
    "zip": 97459,
    "lat": 43.48361,
    "lng": -124.17284,
    "state_id": "OR"
  },
  {
    "zip": 97461,
    "lat": 44.09769,
    "lng": -123.48948,
    "state_id": "OR"
  },
  {
    "zip": 97462,
    "lat": 43.48633,
    "lng": -123.38294,
    "state_id": "OR"
  },
  {
    "zip": 97463,
    "lat": 43.74772,
    "lng": -122.40384,
    "state_id": "OR"
  },
  {
    "zip": 97465,
    "lat": 42.75418,
    "lng": -124.38225,
    "state_id": "OR"
  },
  {
    "zip": 97466,
    "lat": 42.86681,
    "lng": -124.07255,
    "state_id": "OR"
  },
  {
    "zip": 97467,
    "lat": 43.71022,
    "lng": -123.95643,
    "state_id": "OR"
  },
  {
    "zip": 97469,
    "lat": 42.92196,
    "lng": -123.42468,
    "state_id": "OR"
  },
  {
    "zip": 97470,
    "lat": 43.22975,
    "lng": -123.23434,
    "state_id": "OR"
  },
  {
    "zip": 97471,
    "lat": 43.22016,
    "lng": -123.47589,
    "state_id": "OR"
  },
  {
    "zip": 97473,
    "lat": 43.67797,
    "lng": -123.87375,
    "state_id": "OR"
  },
  {
    "zip": 97476,
    "lat": 42.83552,
    "lng": -124.41224,
    "state_id": "OR"
  },
  {
    "zip": 97477,
    "lat": 44.05875,
    "lng": -123.01122,
    "state_id": "OR"
  },
  {
    "zip": 97478,
    "lat": 44.09093,
    "lng": -122.85142,
    "state_id": "OR"
  },
  {
    "zip": 97479,
    "lat": 43.40238,
    "lng": -123.1998,
    "state_id": "OR"
  },
  {
    "zip": 97480,
    "lat": 44.13581,
    "lng": -123.83571,
    "state_id": "OR"
  },
  {
    "zip": 97481,
    "lat": 43.11386,
    "lng": -123.55215,
    "state_id": "OR"
  },
  {
    "zip": 97484,
    "lat": 42.94002,
    "lng": -122.8502,
    "state_id": "OR"
  },
  {
    "zip": 97486,
    "lat": 43.38201,
    "lng": -123.52858,
    "state_id": "OR"
  },
  {
    "zip": 97487,
    "lat": 43.98617,
    "lng": -123.39091,
    "state_id": "OR"
  },
  {
    "zip": 97488,
    "lat": 44.12753,
    "lng": -122.44518,
    "state_id": "OR"
  },
  {
    "zip": 97489,
    "lat": 44.13709,
    "lng": -122.64589,
    "state_id": "OR"
  },
  {
    "zip": 97490,
    "lat": 44.00425,
    "lng": -123.61199,
    "state_id": "OR"
  },
  {
    "zip": 97492,
    "lat": 43.72143,
    "lng": -122.49536,
    "state_id": "OR"
  },
  {
    "zip": 97493,
    "lat": 43.89788,
    "lng": -124.01746,
    "state_id": "OR"
  },
  {
    "zip": 97494,
    "lat": 43.3304,
    "lng": -123.3281,
    "state_id": "OR"
  },
  {
    "zip": 97495,
    "lat": 43.28778,
    "lng": -123.3054,
    "state_id": "OR"
  },
  {
    "zip": 97496,
    "lat": 43.05776,
    "lng": -123.49006,
    "state_id": "OR"
  },
  {
    "zip": 97497,
    "lat": 42.66677,
    "lng": -123.39219,
    "state_id": "OR"
  },
  {
    "zip": 97498,
    "lat": 44.28485,
    "lng": -124.02009,
    "state_id": "OR"
  },
  {
    "zip": 97499,
    "lat": 43.59277,
    "lng": -123.25553,
    "state_id": "OR"
  },
  {
    "zip": 97501,
    "lat": 42.28178,
    "lng": -122.90541,
    "state_id": "OR"
  },
  {
    "zip": 97502,
    "lat": 42.41724,
    "lng": -122.95621,
    "state_id": "OR"
  },
  {
    "zip": 97503,
    "lat": 42.59042,
    "lng": -122.92747,
    "state_id": "OR"
  },
  {
    "zip": 97504,
    "lat": 42.32693,
    "lng": -122.79868,
    "state_id": "OR"
  },
  {
    "zip": 97520,
    "lat": 42.15773,
    "lng": -122.5513,
    "state_id": "OR"
  },
  {
    "zip": 97522,
    "lat": 42.58129,
    "lng": -122.52832,
    "state_id": "OR"
  },
  {
    "zip": 97523,
    "lat": 42.12095,
    "lng": -123.57109,
    "state_id": "OR"
  },
  {
    "zip": 97524,
    "lat": 42.45097,
    "lng": -122.67703,
    "state_id": "OR"
  },
  {
    "zip": 97525,
    "lat": 42.44371,
    "lng": -123.07484,
    "state_id": "OR"
  },
  {
    "zip": 97526,
    "lat": 42.51404,
    "lng": -123.34022,
    "state_id": "OR"
  },
  {
    "zip": 97527,
    "lat": 42.38226,
    "lng": -123.40806,
    "state_id": "OR"
  },
  {
    "zip": 97530,
    "lat": 42.15192,
    "lng": -123.05757,
    "state_id": "OR"
  },
  {
    "zip": 97531,
    "lat": 42.20427,
    "lng": -123.64943,
    "state_id": "OR"
  },
  {
    "zip": 97532,
    "lat": 42.57181,
    "lng": -123.52213,
    "state_id": "OR"
  },
  {
    "zip": 97534,
    "lat": 42.08425,
    "lng": -123.75982,
    "state_id": "OR"
  },
  {
    "zip": 97535,
    "lat": 42.26775,
    "lng": -122.81147,
    "state_id": "OR"
  },
  {
    "zip": 97536,
    "lat": 42.78738,
    "lng": -122.50562,
    "state_id": "OR"
  },
  {
    "zip": 97537,
    "lat": 42.54655,
    "lng": -123.14123,
    "state_id": "OR"
  },
  {
    "zip": 97538,
    "lat": 42.2737,
    "lng": -123.58169,
    "state_id": "OR"
  },
  {
    "zip": 97539,
    "lat": 42.60007,
    "lng": -122.79042,
    "state_id": "OR"
  },
  {
    "zip": 97540,
    "lat": 42.19974,
    "lng": -122.80854,
    "state_id": "OR"
  },
  {
    "zip": 97541,
    "lat": 42.73849,
    "lng": -122.75469,
    "state_id": "OR"
  },
  {
    "zip": 97543,
    "lat": 42.37506,
    "lng": -123.56212,
    "state_id": "OR"
  },
  {
    "zip": 97544,
    "lat": 42.13221,
    "lng": -123.31332,
    "state_id": "OR"
  },
  {
    "zip": 97601,
    "lat": 42.44883,
    "lng": -122.07473,
    "state_id": "OR"
  },
  {
    "zip": 97603,
    "lat": 42.14085,
    "lng": -121.68079,
    "state_id": "OR"
  },
  {
    "zip": 97604,
    "lat": 42.94659,
    "lng": -122.17721,
    "state_id": "OR"
  },
  {
    "zip": 97620,
    "lat": 42.20628,
    "lng": -119.78389,
    "state_id": "OR"
  },
  {
    "zip": 97621,
    "lat": 42.47655,
    "lng": -121.28228,
    "state_id": "OR"
  },
  {
    "zip": 97622,
    "lat": 42.41915,
    "lng": -121.03403,
    "state_id": "OR"
  },
  {
    "zip": 97623,
    "lat": 42.18157,
    "lng": -121.31966,
    "state_id": "OR"
  },
  {
    "zip": 97624,
    "lat": 42.74175,
    "lng": -121.70551,
    "state_id": "OR"
  },
  {
    "zip": 97625,
    "lat": 42.31485,
    "lng": -121.58099,
    "state_id": "OR"
  },
  {
    "zip": 97626,
    "lat": 42.66858,
    "lng": -122.02784,
    "state_id": "OR"
  },
  {
    "zip": 97627,
    "lat": 42.12189,
    "lng": -122.01889,
    "state_id": "OR"
  },
  {
    "zip": 97630,
    "lat": 42.31317,
    "lng": -120.36637,
    "state_id": "OR"
  },
  {
    "zip": 97632,
    "lat": 42.03211,
    "lng": -121.4233,
    "state_id": "OR"
  },
  {
    "zip": 97633,
    "lat": 42.03085,
    "lng": -121.56334,
    "state_id": "OR"
  },
  {
    "zip": 97634,
    "lat": 42.12827,
    "lng": -121.81491,
    "state_id": "OR"
  },
  {
    "zip": 97635,
    "lat": 41.93715,
    "lng": -120.29869,
    "state_id": "OR"
  },
  {
    "zip": 97636,
    "lat": 42.61088,
    "lng": -120.52183,
    "state_id": "OR"
  },
  {
    "zip": 97637,
    "lat": 42.52584,
    "lng": -119.91401,
    "state_id": "OR"
  },
  {
    "zip": 97638,
    "lat": 43.13952,
    "lng": -120.99851,
    "state_id": "OR"
  },
  {
    "zip": 97639,
    "lat": 42.45146,
    "lng": -121.43653,
    "state_id": "OR"
  },
  {
    "zip": 97640,
    "lat": 42.89029,
    "lng": -120.67172,
    "state_id": "OR"
  },
  {
    "zip": 97641,
    "lat": 43.26455,
    "lng": -120.51876,
    "state_id": "OR"
  },
  {
    "zip": 97701,
    "lat": 44.01483,
    "lng": -121.33105,
    "state_id": "OR"
  },
  {
    "zip": 97702,
    "lat": 43.99863,
    "lng": -121.24356,
    "state_id": "OR"
  },
  {
    "zip": 97707,
    "lat": 43.8258,
    "lng": -121.49526,
    "state_id": "OR"
  },
  {
    "zip": 97710,
    "lat": 42.18621,
    "lng": -118.47568,
    "state_id": "OR"
  },
  {
    "zip": 97711,
    "lat": 44.72285,
    "lng": -120.63173,
    "state_id": "OR"
  },
  {
    "zip": 97712,
    "lat": 43.78281,
    "lng": -120.45307,
    "state_id": "OR"
  },
  {
    "zip": 97720,
    "lat": 43.56855,
    "lng": -118.84711,
    "state_id": "OR"
  },
  {
    "zip": 97721,
    "lat": 42.9041,
    "lng": -118.58289,
    "state_id": "OR"
  },
  {
    "zip": 97722,
    "lat": 42.95081,
    "lng": -118.69496,
    "state_id": "OR"
  },
  {
    "zip": 97730,
    "lat": 44.50237,
    "lng": -121.64715,
    "state_id": "OR"
  },
  {
    "zip": 97731,
    "lat": 43.14598,
    "lng": -121.79748,
    "state_id": "OR"
  },
  {
    "zip": 97732,
    "lat": 43.39673,
    "lng": -118.45323,
    "state_id": "OR"
  },
  {
    "zip": 97733,
    "lat": 43.37924,
    "lng": -121.95668,
    "state_id": "OR"
  },
  {
    "zip": 97734,
    "lat": 44.54038,
    "lng": -121.33486,
    "state_id": "OR"
  },
  {
    "zip": 97735,
    "lat": 43.41066,
    "lng": -120.92595,
    "state_id": "OR"
  },
  {
    "zip": 97736,
    "lat": 42.71476,
    "lng": -119.0058,
    "state_id": "OR"
  },
  {
    "zip": 97737,
    "lat": 43.50266,
    "lng": -121.73126,
    "state_id": "OR"
  },
  {
    "zip": 97738,
    "lat": 43.52256,
    "lng": -119.15178,
    "state_id": "OR"
  },
  {
    "zip": 97739,
    "lat": 43.69959,
    "lng": -121.46219,
    "state_id": "OR"
  },
  {
    "zip": 97741,
    "lat": 44.6518,
    "lng": -121.05269,
    "state_id": "OR"
  },
  {
    "zip": 97750,
    "lat": 44.63858,
    "lng": -120.1165,
    "state_id": "OR"
  },
  {
    "zip": 97751,
    "lat": 44.13755,
    "lng": -119.87398,
    "state_id": "OR"
  },
  {
    "zip": 97752,
    "lat": 44.13734,
    "lng": -120.29783,
    "state_id": "OR"
  },
  {
    "zip": 97753,
    "lat": 44.23519,
    "lng": -121.01555,
    "state_id": "OR"
  },
  {
    "zip": 97754,
    "lat": 44.15223,
    "lng": -120.57082,
    "state_id": "OR"
  },
  {
    "zip": 97756,
    "lat": 44.27851,
    "lng": -121.21385,
    "state_id": "OR"
  },
  {
    "zip": 97758,
    "lat": 43.38843,
    "lng": -119.88021,
    "state_id": "OR"
  },
  {
    "zip": 97759,
    "lat": 44.3772,
    "lng": -121.78388,
    "state_id": "OR"
  },
  {
    "zip": 97760,
    "lat": 44.39092,
    "lng": -121.23781,
    "state_id": "OR"
  },
  {
    "zip": 97761,
    "lat": 44.84165,
    "lng": -121.29841,
    "state_id": "OR"
  },
  {
    "zip": 97801,
    "lat": 45.66821,
    "lng": -118.81573,
    "state_id": "OR"
  },
  {
    "zip": 97810,
    "lat": 45.71397,
    "lng": -118.45808,
    "state_id": "OR"
  },
  {
    "zip": 97812,
    "lat": 45.55943,
    "lng": -120.21621,
    "state_id": "OR"
  },
  {
    "zip": 97813,
    "lat": 45.85042,
    "lng": -118.52813,
    "state_id": "OR"
  },
  {
    "zip": 97814,
    "lat": 44.82656,
    "lng": -117.76251,
    "state_id": "OR"
  },
  {
    "zip": 97817,
    "lat": 44.71373,
    "lng": -118.64708,
    "state_id": "OR"
  },
  {
    "zip": 97818,
    "lat": 45.77477,
    "lng": -119.87004,
    "state_id": "OR"
  },
  {
    "zip": 97819,
    "lat": 44.48542,
    "lng": -117.76037,
    "state_id": "OR"
  },
  {
    "zip": 97820,
    "lat": 44.16878,
    "lng": -119.19978,
    "state_id": "OR"
  },
  {
    "zip": 97823,
    "lat": 45.24281,
    "lng": -120.20562,
    "state_id": "OR"
  },
  {
    "zip": 97824,
    "lat": 45.33995,
    "lng": -117.75625,
    "state_id": "OR"
  },
  {
    "zip": 97825,
    "lat": 44.38529,
    "lng": -119.5166,
    "state_id": "OR"
  },
  {
    "zip": 97826,
    "lat": 45.65448,
    "lng": -119.22665,
    "state_id": "OR"
  },
  {
    "zip": 97827,
    "lat": 45.58794,
    "lng": -117.84525,
    "state_id": "OR"
  },
  {
    "zip": 97828,
    "lat": 45.7068,
    "lng": -117.2286,
    "state_id": "OR"
  },
  {
    "zip": 97830,
    "lat": 44.9502,
    "lng": -120.18931,
    "state_id": "OR"
  },
  {
    "zip": 97833,
    "lat": 44.92896,
    "lng": -118.01488,
    "state_id": "OR"
  },
  {
    "zip": 97834,
    "lat": 44.96864,
    "lng": -117.18919,
    "state_id": "OR"
  },
  {
    "zip": 97835,
    "lat": 45.91,
    "lng": -118.78663,
    "state_id": "OR"
  },
  {
    "zip": 97836,
    "lat": 45.2939,
    "lng": -119.50152,
    "state_id": "OR"
  },
  {
    "zip": 97837,
    "lat": 44.51811,
    "lng": -118.03637,
    "state_id": "OR"
  },
  {
    "zip": 97838,
    "lat": 45.85295,
    "lng": -119.28695,
    "state_id": "OR"
  },
  {
    "zip": 97839,
    "lat": 45.59138,
    "lng": -119.59274,
    "state_id": "OR"
  },
  {
    "zip": 97840,
    "lat": 44.90644,
    "lng": -116.9271,
    "state_id": "OR"
  },
  {
    "zip": 97841,
    "lat": 45.46512,
    "lng": -117.94494,
    "state_id": "OR"
  },
  {
    "zip": 97842,
    "lat": 45.46752,
    "lng": -116.74865,
    "state_id": "OR"
  },
  {
    "zip": 97843,
    "lat": 45.50471,
    "lng": -119.89785,
    "state_id": "OR"
  },
  {
    "zip": 97844,
    "lat": 45.87421,
    "lng": -119.55845,
    "state_id": "OR"
  },
  {
    "zip": 97845,
    "lat": 44.40179,
    "lng": -118.90315,
    "state_id": "OR"
  },
  {
    "zip": 97846,
    "lat": 45.46587,
    "lng": -117.02962,
    "state_id": "OR"
  },
  {
    "zip": 97848,
    "lat": 44.67557,
    "lng": -119.56605,
    "state_id": "OR"
  },
  {
    "zip": 97850,
    "lat": 45.30159,
    "lng": -118.11669,
    "state_id": "OR"
  },
  {
    "zip": 97856,
    "lat": 44.80663,
    "lng": -119.12171,
    "state_id": "OR"
  },
  {
    "zip": 97857,
    "lat": 45.37812,
    "lng": -117.50433,
    "state_id": "OR"
  },
  {
    "zip": 97859,
    "lat": 45.52907,
    "lng": -118.42136,
    "state_id": "OR"
  },
  {
    "zip": 97862,
    "lat": 45.92342,
    "lng": -118.31008,
    "state_id": "OR"
  },
  {
    "zip": 97864,
    "lat": 44.84588,
    "lng": -119.44529,
    "state_id": "OR"
  },
  {
    "zip": 97865,
    "lat": 44.4472,
    "lng": -119.19104,
    "state_id": "OR"
  },
  {
    "zip": 97867,
    "lat": 45.06907,
    "lng": -117.9955,
    "state_id": "OR"
  },
  {
    "zip": 97868,
    "lat": 45.41177,
    "lng": -118.87134,
    "state_id": "OR"
  },
  {
    "zip": 97869,
    "lat": 44.40198,
    "lng": -118.62051,
    "state_id": "OR"
  },
  {
    "zip": 97870,
    "lat": 44.78891,
    "lng": -117.18786,
    "state_id": "OR"
  },
  {
    "zip": 97873,
    "lat": 44.13206,
    "lng": -119.00288,
    "state_id": "OR"
  },
  {
    "zip": 97874,
    "lat": 44.77754,
    "lng": -119.86744,
    "state_id": "OR"
  },
  {
    "zip": 97875,
    "lat": 45.81938,
    "lng": -119.13834,
    "state_id": "OR"
  },
  {
    "zip": 97876,
    "lat": 45.5165,
    "lng": -118.03552,
    "state_id": "OR"
  },
  {
    "zip": 97877,
    "lat": 44.79261,
    "lng": -118.33936,
    "state_id": "OR"
  },
  {
    "zip": 97880,
    "lat": 45.08362,
    "lng": -118.89401,
    "state_id": "OR"
  },
  {
    "zip": 97882,
    "lat": 45.89984,
    "lng": -119.35522,
    "state_id": "OR"
  },
  {
    "zip": 97883,
    "lat": 45.15518,
    "lng": -117.61966,
    "state_id": "OR"
  },
  {
    "zip": 97884,
    "lat": 44.45644,
    "lng": -118.21822,
    "state_id": "OR"
  },
  {
    "zip": 97885,
    "lat": 45.65585,
    "lng": -117.51177,
    "state_id": "OR"
  },
  {
    "zip": 97886,
    "lat": 45.7987,
    "lng": -118.23481,
    "state_id": "OR"
  },
  {
    "zip": 97901,
    "lat": 43.62894,
    "lng": -117.09006,
    "state_id": "OR"
  },
  {
    "zip": 97903,
    "lat": 44.24869,
    "lng": -117.62058,
    "state_id": "OR"
  },
  {
    "zip": 97904,
    "lat": 43.88224,
    "lng": -118.51065,
    "state_id": "OR"
  },
  {
    "zip": 97905,
    "lat": 44.57379,
    "lng": -117.45952,
    "state_id": "OR"
  },
  {
    "zip": 97906,
    "lat": 43.52609,
    "lng": -117.77349,
    "state_id": "OR"
  },
  {
    "zip": 97907,
    "lat": 44.41016,
    "lng": -117.34305,
    "state_id": "OR"
  },
  {
    "zip": 97908,
    "lat": 44.30925,
    "lng": -117.93944,
    "state_id": "OR"
  },
  {
    "zip": 97909,
    "lat": 44.24647,
    "lng": -117.41897,
    "state_id": "OR"
  },
  {
    "zip": 97910,
    "lat": 42.73826,
    "lng": -117.50535,
    "state_id": "OR"
  },
  {
    "zip": 97911,
    "lat": 43.75425,
    "lng": -118.05702,
    "state_id": "OR"
  },
  {
    "zip": 97913,
    "lat": 43.61127,
    "lng": -117.32015,
    "state_id": "OR"
  },
  {
    "zip": 97914,
    "lat": 44.11322,
    "lng": -117.08407,
    "state_id": "OR"
  },
  {
    "zip": 97918,
    "lat": 44.04162,
    "lng": -117.36115,
    "state_id": "OR"
  },
  {
    "zip": 97920,
    "lat": 44.07265,
    "lng": -117.84644,
    "state_id": "OR"
  },
  {
    "zip": 15001,
    "lat": 40.59209,
    "lng": -80.31976,
    "state_id": "PA"
  },
  {
    "zip": 15003,
    "lat": 40.6011,
    "lng": -80.21386,
    "state_id": "PA"
  },
  {
    "zip": 15004,
    "lat": 40.34521,
    "lng": -80.38118,
    "state_id": "PA"
  },
  {
    "zip": 15005,
    "lat": 40.64462,
    "lng": -80.17588,
    "state_id": "PA"
  },
  {
    "zip": 15006,
    "lat": 40.63357,
    "lng": -79.87681,
    "state_id": "PA"
  },
  {
    "zip": 15007,
    "lat": 40.65239,
    "lng": -79.93179,
    "state_id": "PA"
  },
  {
    "zip": 15009,
    "lat": 40.69797,
    "lng": -80.36543,
    "state_id": "PA"
  },
  {
    "zip": 15010,
    "lat": 40.76872,
    "lng": -80.3591,
    "state_id": "PA"
  },
  {
    "zip": 15012,
    "lat": 40.15883,
    "lng": -79.81218,
    "state_id": "PA"
  },
  {
    "zip": 15014,
    "lat": 40.60798,
    "lng": -79.741,
    "state_id": "PA"
  },
  {
    "zip": 15015,
    "lat": 40.63718,
    "lng": -80.08111,
    "state_id": "PA"
  },
  {
    "zip": 15017,
    "lat": 40.34033,
    "lng": -80.1281,
    "state_id": "PA"
  },
  {
    "zip": 15018,
    "lat": 40.2732,
    "lng": -79.79382,
    "state_id": "PA"
  },
  {
    "zip": 15019,
    "lat": 40.40657,
    "lng": -80.32926,
    "state_id": "PA"
  },
  {
    "zip": 15020,
    "lat": 40.23094,
    "lng": -79.94552,
    "state_id": "PA"
  },
  {
    "zip": 15021,
    "lat": 40.39429,
    "lng": -80.43512,
    "state_id": "PA"
  },
  {
    "zip": 15022,
    "lat": 40.13237,
    "lng": -79.93578,
    "state_id": "PA"
  },
  {
    "zip": 15024,
    "lat": 40.57854,
    "lng": -79.84369,
    "state_id": "PA"
  },
  {
    "zip": 15025,
    "lat": 40.29911,
    "lng": -79.92191,
    "state_id": "PA"
  },
  {
    "zip": 15026,
    "lat": 40.51077,
    "lng": -80.36,
    "state_id": "PA"
  },
  {
    "zip": 15027,
    "lat": 40.66675,
    "lng": -80.23944,
    "state_id": "PA"
  },
  {
    "zip": 15028,
    "lat": 40.30172,
    "lng": -79.79885,
    "state_id": "PA"
  },
  {
    "zip": 15030,
    "lat": 40.58728,
    "lng": -79.78068,
    "state_id": "PA"
  },
  {
    "zip": 15031,
    "lat": 40.34821,
    "lng": -80.16126,
    "state_id": "PA"
  },
  {
    "zip": 15033,
    "lat": 40.17928,
    "lng": -79.86462,
    "state_id": "PA"
  },
  {
    "zip": 15034,
    "lat": 40.35083,
    "lng": -79.89066,
    "state_id": "PA"
  },
  {
    "zip": 15035,
    "lat": 40.38491,
    "lng": -79.80711,
    "state_id": "PA"
  },
  {
    "zip": 15037,
    "lat": 40.25735,
    "lng": -79.8508,
    "state_id": "PA"
  },
  {
    "zip": 15038,
    "lat": 40.25077,
    "lng": -79.9262,
    "state_id": "PA"
  },
  {
    "zip": 15042,
    "lat": 40.68786,
    "lng": -80.20534,
    "state_id": "PA"
  },
  {
    "zip": 15043,
    "lat": 40.55468,
    "lng": -80.48909,
    "state_id": "PA"
  },
  {
    "zip": 15044,
    "lat": 40.63904,
    "lng": -79.94999,
    "state_id": "PA"
  },
  {
    "zip": 15045,
    "lat": 40.32615,
    "lng": -79.88616,
    "state_id": "PA"
  },
  {
    "zip": 15046,
    "lat": 40.55622,
    "lng": -80.22858,
    "state_id": "PA"
  },
  {
    "zip": 15047,
    "lat": 40.31584,
    "lng": -79.79998,
    "state_id": "PA"
  },
  {
    "zip": 15049,
    "lat": 40.55611,
    "lng": -79.80562,
    "state_id": "PA"
  },
  {
    "zip": 15050,
    "lat": 40.5622,
    "lng": -80.4388,
    "state_id": "PA"
  },
  {
    "zip": 15051,
    "lat": 40.56245,
    "lng": -79.86712,
    "state_id": "PA"
  },
  {
    "zip": 15052,
    "lat": 40.66992,
    "lng": -80.43962,
    "state_id": "PA"
  },
  {
    "zip": 15053,
    "lat": 40.38101,
    "lng": -80.35983,
    "state_id": "PA"
  },
  {
    "zip": 15054,
    "lat": 40.36236,
    "lng": -80.40768,
    "state_id": "PA"
  },
  {
    "zip": 15055,
    "lat": 40.30637,
    "lng": -80.12263,
    "state_id": "PA"
  },
  {
    "zip": 15056,
    "lat": 40.56483,
    "lng": -80.21384,
    "state_id": "PA"
  },
  {
    "zip": 15057,
    "lat": 40.35592,
    "lng": -80.24824,
    "state_id": "PA"
  },
  {
    "zip": 15059,
    "lat": 40.67929,
    "lng": -80.49084,
    "state_id": "PA"
  },
  {
    "zip": 15060,
    "lat": 40.36691,
    "lng": -80.29208,
    "state_id": "PA"
  },
  {
    "zip": 15061,
    "lat": 40.65875,
    "lng": -80.31819,
    "state_id": "PA"
  },
  {
    "zip": 15062,
    "lat": 40.15033,
    "lng": -79.88044,
    "state_id": "PA"
  },
  {
    "zip": 15063,
    "lat": 40.19504,
    "lng": -79.92427,
    "state_id": "PA"
  },
  {
    "zip": 15064,
    "lat": 40.35638,
    "lng": -80.14995,
    "state_id": "PA"
  },
  {
    "zip": 15065,
    "lat": 40.64214,
    "lng": -79.72728,
    "state_id": "PA"
  },
  {
    "zip": 15066,
    "lat": 40.75195,
    "lng": -80.25304,
    "state_id": "PA"
  },
  {
    "zip": 15067,
    "lat": 40.20875,
    "lng": -79.96208,
    "state_id": "PA"
  },
  {
    "zip": 15068,
    "lat": 40.56164,
    "lng": -79.71289,
    "state_id": "PA"
  },
  {
    "zip": 15071,
    "lat": 40.41207,
    "lng": -80.18779,
    "state_id": "PA"
  },
  {
    "zip": 15072,
    "lat": 40.13936,
    "lng": -79.85617,
    "state_id": "PA"
  },
  {
    "zip": 15074,
    "lat": 40.72785,
    "lng": -80.21831,
    "state_id": "PA"
  },
  {
    "zip": 15075,
    "lat": 40.58606,
    "lng": -79.82683,
    "state_id": "PA"
  },
  {
    "zip": 15076,
    "lat": 40.60736,
    "lng": -79.83469,
    "state_id": "PA"
  },
  {
    "zip": 15077,
    "lat": 40.62422,
    "lng": -80.42206,
    "state_id": "PA"
  },
  {
    "zip": 15078,
    "lat": 40.35585,
    "lng": -80.38491,
    "state_id": "PA"
  },
  {
    "zip": 15081,
    "lat": 40.57457,
    "lng": -80.23629,
    "state_id": "PA"
  },
  {
    "zip": 15082,
    "lat": 40.37776,
    "lng": -80.21299,
    "state_id": "PA"
  },
  {
    "zip": 15083,
    "lat": 40.25428,
    "lng": -79.78741,
    "state_id": "PA"
  },
  {
    "zip": 15084,
    "lat": 40.63007,
    "lng": -79.80281,
    "state_id": "PA"
  },
  {
    "zip": 15085,
    "lat": 40.38573,
    "lng": -79.72227,
    "state_id": "PA"
  },
  {
    "zip": 15086,
    "lat": 40.66546,
    "lng": -80.0951,
    "state_id": "PA"
  },
  {
    "zip": 15087,
    "lat": 40.18957,
    "lng": -79.85302,
    "state_id": "PA"
  },
  {
    "zip": 15088,
    "lat": 40.27228,
    "lng": -79.89584,
    "state_id": "PA"
  },
  {
    "zip": 15089,
    "lat": 40.22039,
    "lng": -79.74058,
    "state_id": "PA"
  },
  {
    "zip": 15090,
    "lat": 40.62549,
    "lng": -80.06889,
    "state_id": "PA"
  },
  {
    "zip": 15101,
    "lat": 40.58048,
    "lng": -79.95446,
    "state_id": "PA"
  },
  {
    "zip": 15102,
    "lat": 40.32133,
    "lng": -80.03658,
    "state_id": "PA"
  },
  {
    "zip": 15104,
    "lat": 40.4038,
    "lng": -79.86263,
    "state_id": "PA"
  },
  {
    "zip": 15106,
    "lat": 40.41015,
    "lng": -80.11423,
    "state_id": "PA"
  },
  {
    "zip": 15108,
    "lat": 40.49997,
    "lng": -80.19959,
    "state_id": "PA"
  },
  {
    "zip": 15110,
    "lat": 40.37319,
    "lng": -79.85012,
    "state_id": "PA"
  },
  {
    "zip": 15112,
    "lat": 40.40456,
    "lng": -79.83773,
    "state_id": "PA"
  },
  {
    "zip": 15116,
    "lat": 40.53908,
    "lng": -79.95047,
    "state_id": "PA"
  },
  {
    "zip": 15120,
    "lat": 40.39613,
    "lng": -79.90678,
    "state_id": "PA"
  },
  {
    "zip": 15122,
    "lat": 40.36027,
    "lng": -79.90516,
    "state_id": "PA"
  },
  {
    "zip": 15126,
    "lat": 40.46246,
    "lng": -80.28445,
    "state_id": "PA"
  },
  {
    "zip": 15129,
    "lat": 40.29323,
    "lng": -79.99607,
    "state_id": "PA"
  },
  {
    "zip": 15131,
    "lat": 40.33602,
    "lng": -79.8002,
    "state_id": "PA"
  },
  {
    "zip": 15132,
    "lat": 40.33984,
    "lng": -79.84315,
    "state_id": "PA"
  },
  {
    "zip": 15133,
    "lat": 40.32718,
    "lng": -79.8633,
    "state_id": "PA"
  },
  {
    "zip": 15135,
    "lat": 40.3016,
    "lng": -79.81539,
    "state_id": "PA"
  },
  {
    "zip": 15136,
    "lat": 40.46789,
    "lng": -80.10438,
    "state_id": "PA"
  },
  {
    "zip": 15137,
    "lat": 40.37796,
    "lng": -79.80895,
    "state_id": "PA"
  },
  {
    "zip": 15139,
    "lat": 40.52201,
    "lng": -79.83474,
    "state_id": "PA"
  },
  {
    "zip": 15140,
    "lat": 40.40779,
    "lng": -79.77644,
    "state_id": "PA"
  },
  {
    "zip": 15142,
    "lat": 40.38542,
    "lng": -80.12202,
    "state_id": "PA"
  },
  {
    "zip": 15143,
    "lat": 40.57322,
    "lng": -80.14654,
    "state_id": "PA"
  },
  {
    "zip": 15144,
    "lat": 40.54934,
    "lng": -79.78284,
    "state_id": "PA"
  },
  {
    "zip": 15145,
    "lat": 40.41527,
    "lng": -79.82411,
    "state_id": "PA"
  },
  {
    "zip": 15146,
    "lat": 40.42626,
    "lng": -79.76041,
    "state_id": "PA"
  },
  {
    "zip": 15147,
    "lat": 40.49717,
    "lng": -79.82729,
    "state_id": "PA"
  },
  {
    "zip": 15148,
    "lat": 40.39347,
    "lng": -79.79524,
    "state_id": "PA"
  },
  {
    "zip": 15201,
    "lat": 40.47511,
    "lng": -79.95285,
    "state_id": "PA"
  },
  {
    "zip": 15202,
    "lat": 40.5053,
    "lng": -80.0685,
    "state_id": "PA"
  },
  {
    "zip": 15203,
    "lat": 40.42632,
    "lng": -79.97638,
    "state_id": "PA"
  },
  {
    "zip": 15204,
    "lat": 40.45597,
    "lng": -80.06083,
    "state_id": "PA"
  },
  {
    "zip": 15205,
    "lat": 40.43808,
    "lng": -80.09918,
    "state_id": "PA"
  },
  {
    "zip": 15206,
    "lat": 40.47224,
    "lng": -79.91325,
    "state_id": "PA"
  },
  {
    "zip": 15207,
    "lat": 40.40031,
    "lng": -79.93369,
    "state_id": "PA"
  },
  {
    "zip": 15208,
    "lat": 40.45316,
    "lng": -79.89943,
    "state_id": "PA"
  },
  {
    "zip": 15209,
    "lat": 40.49987,
    "lng": -79.97394,
    "state_id": "PA"
  },
  {
    "zip": 15210,
    "lat": 40.40713,
    "lng": -79.98382,
    "state_id": "PA"
  },
  {
    "zip": 15211,
    "lat": 40.43042,
    "lng": -80.01564,
    "state_id": "PA"
  },
  {
    "zip": 15212,
    "lat": 40.47076,
    "lng": -80.00757,
    "state_id": "PA"
  },
  {
    "zip": 15213,
    "lat": 40.44398,
    "lng": -79.95523,
    "state_id": "PA"
  },
  {
    "zip": 15214,
    "lat": 40.48647,
    "lng": -80.01393,
    "state_id": "PA"
  },
  {
    "zip": 15215,
    "lat": 40.5048,
    "lng": -79.91381,
    "state_id": "PA"
  },
  {
    "zip": 15216,
    "lat": 40.40263,
    "lng": -80.03483,
    "state_id": "PA"
  },
  {
    "zip": 15217,
    "lat": 40.43073,
    "lng": -79.92056,
    "state_id": "PA"
  },
  {
    "zip": 15218,
    "lat": 40.42381,
    "lng": -79.89003,
    "state_id": "PA"
  },
  {
    "zip": 15219,
    "lat": 40.44227,
    "lng": -79.98321,
    "state_id": "PA"
  },
  {
    "zip": 15220,
    "lat": 40.41964,
    "lng": -80.04904,
    "state_id": "PA"
  },
  {
    "zip": 15221,
    "lat": 40.43459,
    "lng": -79.86545,
    "state_id": "PA"
  },
  {
    "zip": 15222,
    "lat": 40.44763,
    "lng": -79.99336,
    "state_id": "PA"
  },
  {
    "zip": 15223,
    "lat": 40.50507,
    "lng": -79.95289,
    "state_id": "PA"
  },
  {
    "zip": 15224,
    "lat": 40.46412,
    "lng": -79.94489,
    "state_id": "PA"
  },
  {
    "zip": 15225,
    "lat": 40.50637,
    "lng": -80.11158,
    "state_id": "PA"
  },
  {
    "zip": 15226,
    "lat": 40.39512,
    "lng": -80.01398,
    "state_id": "PA"
  },
  {
    "zip": 15227,
    "lat": 40.37575,
    "lng": -79.97057,
    "state_id": "PA"
  },
  {
    "zip": 15228,
    "lat": 40.37077,
    "lng": -80.04415,
    "state_id": "PA"
  },
  {
    "zip": 15229,
    "lat": 40.52036,
    "lng": -80.03704,
    "state_id": "PA"
  },
  {
    "zip": 15232,
    "lat": 40.45245,
    "lng": -79.93182,
    "state_id": "PA"
  },
  {
    "zip": 15233,
    "lat": 40.46092,
    "lng": -80.03489,
    "state_id": "PA"
  },
  {
    "zip": 15234,
    "lat": 40.36813,
    "lng": -80.01782,
    "state_id": "PA"
  },
  {
    "zip": 15235,
    "lat": 40.45981,
    "lng": -79.82244,
    "state_id": "PA"
  },
  {
    "zip": 15236,
    "lat": 40.34744,
    "lng": -79.97544,
    "state_id": "PA"
  },
  {
    "zip": 15237,
    "lat": 40.54883,
    "lng": -80.04746,
    "state_id": "PA"
  },
  {
    "zip": 15238,
    "lat": 40.53464,
    "lng": -79.88051,
    "state_id": "PA"
  },
  {
    "zip": 15239,
    "lat": 40.48368,
    "lng": -79.73808,
    "state_id": "PA"
  },
  {
    "zip": 15241,
    "lat": 40.33305,
    "lng": -80.0824,
    "state_id": "PA"
  },
  {
    "zip": 15243,
    "lat": 40.3748,
    "lng": -80.07313,
    "state_id": "PA"
  },
  {
    "zip": 15260,
    "lat": 40.44321,
    "lng": -79.95313,
    "state_id": "PA"
  },
  {
    "zip": 15290,
    "lat": 40.45734,
    "lng": -80.0193,
    "state_id": "PA"
  },
  {
    "zip": 15301,
    "lat": 40.16204,
    "lng": -80.25282,
    "state_id": "PA"
  },
  {
    "zip": 15310,
    "lat": 39.79273,
    "lng": -80.48224,
    "state_id": "PA"
  },
  {
    "zip": 15311,
    "lat": 40.04591,
    "lng": -80.18393,
    "state_id": "PA"
  },
  {
    "zip": 15312,
    "lat": 40.25414,
    "lng": -80.43834,
    "state_id": "PA"
  },
  {
    "zip": 15313,
    "lat": 40.06793,
    "lng": -80.02375,
    "state_id": "PA"
  },
  {
    "zip": 15314,
    "lat": 40.14567,
    "lng": -80.01814,
    "state_id": "PA"
  },
  {
    "zip": 15315,
    "lat": 39.75816,
    "lng": -79.9828,
    "state_id": "PA"
  },
  {
    "zip": 15316,
    "lat": 39.72461,
    "lng": -80.25368,
    "state_id": "PA"
  },
  {
    "zip": 15317,
    "lat": 40.2706,
    "lng": -80.16677,
    "state_id": "PA"
  },
  {
    "zip": 15320,
    "lat": 39.8769,
    "lng": -79.99369,
    "state_id": "PA"
  },
  {
    "zip": 15321,
    "lat": 40.32333,
    "lng": -80.18813,
    "state_id": "PA"
  },
  {
    "zip": 15322,
    "lat": 39.97653,
    "lng": -80.05014,
    "state_id": "PA"
  },
  {
    "zip": 15323,
    "lat": 40.10716,
    "lng": -80.4068,
    "state_id": "PA"
  },
  {
    "zip": 15324,
    "lat": 40.10076,
    "lng": -80.06581,
    "state_id": "PA"
  },
  {
    "zip": 15325,
    "lat": 39.94937,
    "lng": -79.96338,
    "state_id": "PA"
  },
  {
    "zip": 15327,
    "lat": 39.74946,
    "lng": -79.96438,
    "state_id": "PA"
  },
  {
    "zip": 15329,
    "lat": 40.03057,
    "lng": -80.2755,
    "state_id": "PA"
  },
  {
    "zip": 15330,
    "lat": 40.18026,
    "lng": -80.09452,
    "state_id": "PA"
  },
  {
    "zip": 15331,
    "lat": 40.1074,
    "lng": -80.0216,
    "state_id": "PA"
  },
  {
    "zip": 15332,
    "lat": 40.24152,
    "lng": -79.99288,
    "state_id": "PA"
  },
  {
    "zip": 15333,
    "lat": 40.027,
    "lng": -80.01504,
    "state_id": "PA"
  },
  {
    "zip": 15334,
    "lat": 39.80832,
    "lng": -79.96793,
    "state_id": "PA"
  },
  {
    "zip": 15337,
    "lat": 39.95294,
    "lng": -80.37594,
    "state_id": "PA"
  },
  {
    "zip": 15338,
    "lat": 39.80988,
    "lng": -79.96739,
    "state_id": "PA"
  },
  {
    "zip": 15340,
    "lat": 40.28324,
    "lng": -80.31749,
    "state_id": "PA"
  },
  {
    "zip": 15341,
    "lat": 39.82528,
    "lng": -80.34412,
    "state_id": "PA"
  },
  {
    "zip": 15342,
    "lat": 40.24419,
    "lng": -80.22122,
    "state_id": "PA"
  },
  {
    "zip": 15344,
    "lat": 39.92034,
    "lng": -80.05852,
    "state_id": "PA"
  },
  {
    "zip": 15345,
    "lat": 40.02263,
    "lng": -80.10503,
    "state_id": "PA"
  },
  {
    "zip": 15346,
    "lat": 39.93545,
    "lng": -80.0753,
    "state_id": "PA"
  },
  {
    "zip": 15347,
    "lat": 40.21742,
    "lng": -80.22683,
    "state_id": "PA"
  },
  {
    "zip": 15348,
    "lat": 39.98831,
    "lng": -79.99615,
    "state_id": "PA"
  },
  {
    "zip": 15349,
    "lat": 39.75811,
    "lng": -80.084,
    "state_id": "PA"
  },
  {
    "zip": 15350,
    "lat": 40.29292,
    "lng": -80.20101,
    "state_id": "PA"
  },
  {
    "zip": 15351,
    "lat": 39.88099,
    "lng": -79.92802,
    "state_id": "PA"
  },
  {
    "zip": 15352,
    "lat": 39.75342,
    "lng": -80.39525,
    "state_id": "PA"
  },
  {
    "zip": 15353,
    "lat": 39.96211,
    "lng": -80.31086,
    "state_id": "PA"
  },
  {
    "zip": 15357,
    "lat": 39.94155,
    "lng": -79.98582,
    "state_id": "PA"
  },
  {
    "zip": 15358,
    "lat": 40.05539,
    "lng": -80.00301,
    "state_id": "PA"
  },
  {
    "zip": 15359,
    "lat": 39.87648,
    "lng": -80.27581,
    "state_id": "PA"
  },
  {
    "zip": 15360,
    "lat": 40.08492,
    "lng": -80.08241,
    "state_id": "PA"
  },
  {
    "zip": 15361,
    "lat": 40.32906,
    "lng": -80.25821,
    "state_id": "PA"
  },
  {
    "zip": 15362,
    "lat": 39.76238,
    "lng": -80.21676,
    "state_id": "PA"
  },
  {
    "zip": 15363,
    "lat": 40.25171,
    "lng": -80.19798,
    "state_id": "PA"
  },
  {
    "zip": 15364,
    "lat": 39.94149,
    "lng": -80.29058,
    "state_id": "PA"
  },
  {
    "zip": 15366,
    "lat": 40.15954,
    "lng": -79.97305,
    "state_id": "PA"
  },
  {
    "zip": 15367,
    "lat": 40.26528,
    "lng": -80.05557,
    "state_id": "PA"
  },
  {
    "zip": 15368,
    "lat": 40.01441,
    "lng": -79.98904,
    "state_id": "PA"
  },
  {
    "zip": 15370,
    "lat": 39.87577,
    "lng": -80.17563,
    "state_id": "PA"
  },
  {
    "zip": 15376,
    "lat": 40.10462,
    "lng": -80.48692,
    "state_id": "PA"
  },
  {
    "zip": 15377,
    "lat": 39.9814,
    "lng": -80.45161,
    "state_id": "PA"
  },
  {
    "zip": 15378,
    "lat": 40.2783,
    "lng": -80.2751,
    "state_id": "PA"
  },
  {
    "zip": 15379,
    "lat": 40.2432,
    "lng": -80.42509,
    "state_id": "PA"
  },
  {
    "zip": 15380,
    "lat": 39.87172,
    "lng": -80.4664,
    "state_id": "PA"
  },
  {
    "zip": 15401,
    "lat": 39.90108,
    "lng": -79.74724,
    "state_id": "PA"
  },
  {
    "zip": 15410,
    "lat": 39.91461,
    "lng": -79.90521,
    "state_id": "PA"
  },
  {
    "zip": 15411,
    "lat": 39.74752,
    "lng": -79.34539,
    "state_id": "PA"
  },
  {
    "zip": 15412,
    "lat": 40.09232,
    "lng": -79.85647,
    "state_id": "PA"
  },
  {
    "zip": 15413,
    "lat": 39.98529,
    "lng": -79.87119,
    "state_id": "PA"
  },
  {
    "zip": 15417,
    "lat": 40.01356,
    "lng": -79.91906,
    "state_id": "PA"
  },
  {
    "zip": 15419,
    "lat": 40.05555,
    "lng": -79.89447,
    "state_id": "PA"
  },
  {
    "zip": 15420,
    "lat": 39.96011,
    "lng": -79.86527,
    "state_id": "PA"
  },
  {
    "zip": 15421,
    "lat": 39.84582,
    "lng": -79.59717,
    "state_id": "PA"
  },
  {
    "zip": 15422,
    "lat": 39.98117,
    "lng": -79.81165,
    "state_id": "PA"
  },
  {
    "zip": 15423,
    "lat": 40.08765,
    "lng": -79.92707,
    "state_id": "PA"
  },
  {
    "zip": 15424,
    "lat": 39.81917,
    "lng": -79.36026,
    "state_id": "PA"
  },
  {
    "zip": 15425,
    "lat": 40.02652,
    "lng": -79.55659,
    "state_id": "PA"
  },
  {
    "zip": 15427,
    "lat": 40.06993,
    "lng": -79.97485,
    "state_id": "PA"
  },
  {
    "zip": 15428,
    "lat": 40.08098,
    "lng": -79.68177,
    "state_id": "PA"
  },
  {
    "zip": 15429,
    "lat": 40.00152,
    "lng": -79.94002,
    "state_id": "PA"
  },
  {
    "zip": 15430,
    "lat": 40.04023,
    "lng": -79.65538,
    "state_id": "PA"
  },
  {
    "zip": 15431,
    "lat": 39.95032,
    "lng": -79.59689,
    "state_id": "PA"
  },
  {
    "zip": 15432,
    "lat": 40.11512,
    "lng": -79.86173,
    "state_id": "PA"
  },
  {
    "zip": 15433,
    "lat": 39.97029,
    "lng": -79.95817,
    "state_id": "PA"
  },
  {
    "zip": 15434,
    "lat": 40.08062,
    "lng": -79.88112,
    "state_id": "PA"
  },
  {
    "zip": 15435,
    "lat": 39.94364,
    "lng": -79.84992,
    "state_id": "PA"
  },
  {
    "zip": 15436,
    "lat": 39.81782,
    "lng": -79.72481,
    "state_id": "PA"
  },
  {
    "zip": 15437,
    "lat": 39.79065,
    "lng": -79.60852,
    "state_id": "PA"
  },
  {
    "zip": 15438,
    "lat": 40.07556,
    "lng": -79.84248,
    "state_id": "PA"
  },
  {
    "zip": 15440,
    "lat": 39.73533,
    "lng": -79.61665,
    "state_id": "PA"
  },
  {
    "zip": 15442,
    "lat": 40.01734,
    "lng": -79.84075,
    "state_id": "PA"
  },
  {
    "zip": 15443,
    "lat": 39.91961,
    "lng": -79.88078,
    "state_id": "PA"
  },
  {
    "zip": 15444,
    "lat": 40.01201,
    "lng": -79.90875,
    "state_id": "PA"
  },
  {
    "zip": 15445,
    "lat": 39.8728,
    "lng": -79.65691,
    "state_id": "PA"
  },
  {
    "zip": 15446,
    "lat": 40.03339,
    "lng": -79.39916,
    "state_id": "PA"
  },
  {
    "zip": 15447,
    "lat": 39.94384,
    "lng": -79.93692,
    "state_id": "PA"
  },
  {
    "zip": 15448,
    "lat": 40.13568,
    "lng": -79.73395,
    "state_id": "PA"
  },
  {
    "zip": 15449,
    "lat": 39.96428,
    "lng": -79.78337,
    "state_id": "PA"
  },
  {
    "zip": 15450,
    "lat": 40.00363,
    "lng": -79.9742,
    "state_id": "PA"
  },
  {
    "zip": 15451,
    "lat": 39.73866,
    "lng": -79.83804,
    "state_id": "PA"
  },
  {
    "zip": 15454,
    "lat": 39.86311,
    "lng": -79.87189,
    "state_id": "PA"
  },
  {
    "zip": 15455,
    "lat": 40.0033,
    "lng": -79.64231,
    "state_id": "PA"
  },
  {
    "zip": 15456,
    "lat": 39.92547,
    "lng": -79.65418,
    "state_id": "PA"
  },
  {
    "zip": 15458,
    "lat": 39.89012,
    "lng": -79.8512,
    "state_id": "PA"
  },
  {
    "zip": 15459,
    "lat": 39.76654,
    "lng": -79.46612,
    "state_id": "PA"
  },
  {
    "zip": 15460,
    "lat": 39.81192,
    "lng": -79.91221,
    "state_id": "PA"
  },
  {
    "zip": 15461,
    "lat": 39.84236,
    "lng": -79.90028,
    "state_id": "PA"
  },
  {
    "zip": 15462,
    "lat": 40.05684,
    "lng": -79.39018,
    "state_id": "PA"
  },
  {
    "zip": 15463,
    "lat": 39.96554,
    "lng": -79.89986,
    "state_id": "PA"
  },
  {
    "zip": 15464,
    "lat": 39.92447,
    "lng": -79.43666,
    "state_id": "PA"
  },
  {
    "zip": 15466,
    "lat": 40.07537,
    "lng": -79.89176,
    "state_id": "PA"
  },
  {
    "zip": 15467,
    "lat": 39.78532,
    "lng": -79.91784,
    "state_id": "PA"
  },
  {
    "zip": 15468,
    "lat": 39.95606,
    "lng": -79.83006,
    "state_id": "PA"
  },
  {
    "zip": 15469,
    "lat": 40.01394,
    "lng": -79.4236,
    "state_id": "PA"
  },
  {
    "zip": 15470,
    "lat": 39.86865,
    "lng": -79.53348,
    "state_id": "PA"
  },
  {
    "zip": 15472,
    "lat": 39.91932,
    "lng": -79.71678,
    "state_id": "PA"
  },
  {
    "zip": 15473,
    "lat": 40.06986,
    "lng": -79.76979,
    "state_id": "PA"
  },
  {
    "zip": 15474,
    "lat": 39.74968,
    "lng": -79.90068,
    "state_id": "PA"
  },
  {
    "zip": 15475,
    "lat": 39.95164,
    "lng": -79.8775,
    "state_id": "PA"
  },
  {
    "zip": 15476,
    "lat": 39.86975,
    "lng": -79.9206,
    "state_id": "PA"
  },
  {
    "zip": 15477,
    "lat": 40.07819,
    "lng": -79.86373,
    "state_id": "PA"
  },
  {
    "zip": 15478,
    "lat": 39.78778,
    "lng": -79.80315,
    "state_id": "PA"
  },
  {
    "zip": 15479,
    "lat": 40.15302,
    "lng": -79.71437,
    "state_id": "PA"
  },
  {
    "zip": 15480,
    "lat": 39.9867,
    "lng": -79.77209,
    "state_id": "PA"
  },
  {
    "zip": 15482,
    "lat": 40.0608,
    "lng": -79.76575,
    "state_id": "PA"
  },
  {
    "zip": 15483,
    "lat": 40.08335,
    "lng": -79.85071,
    "state_id": "PA"
  },
  {
    "zip": 15484,
    "lat": 39.89269,
    "lng": -79.7837,
    "state_id": "PA"
  },
  {
    "zip": 15486,
    "lat": 40.02532,
    "lng": -79.70476,
    "state_id": "PA"
  },
  {
    "zip": 15489,
    "lat": 39.96644,
    "lng": -79.696,
    "state_id": "PA"
  },
  {
    "zip": 15490,
    "lat": 40.07238,
    "lng": -79.4544,
    "state_id": "PA"
  },
  {
    "zip": 15492,
    "lat": 40.11942,
    "lng": -79.76242,
    "state_id": "PA"
  },
  {
    "zip": 15501,
    "lat": 40.03596,
    "lng": -79.12871,
    "state_id": "PA"
  },
  {
    "zip": 15502,
    "lat": 40.0358,
    "lng": -79.24605,
    "state_id": "PA"
  },
  {
    "zip": 15510,
    "lat": 39.96695,
    "lng": -79.04005,
    "state_id": "PA"
  },
  {
    "zip": 15520,
    "lat": 40.10635,
    "lng": -79.06055,
    "state_id": "PA"
  },
  {
    "zip": 15521,
    "lat": 40.19912,
    "lng": -78.635,
    "state_id": "PA"
  },
  {
    "zip": 15522,
    "lat": 39.94935,
    "lng": -78.55186,
    "state_id": "PA"
  },
  {
    "zip": 15530,
    "lat": 39.94797,
    "lng": -78.90938,
    "state_id": "PA"
  },
  {
    "zip": 15531,
    "lat": 40.18448,
    "lng": -79.07961,
    "state_id": "PA"
  },
  {
    "zip": 15532,
    "lat": 39.76206,
    "lng": -79.06082,
    "state_id": "PA"
  },
  {
    "zip": 15533,
    "lat": 39.98276,
    "lng": -78.24053,
    "state_id": "PA"
  },
  {
    "zip": 15534,
    "lat": 39.89839,
    "lng": -78.69695,
    "state_id": "PA"
  },
  {
    "zip": 15535,
    "lat": 39.82445,
    "lng": -78.45201,
    "state_id": "PA"
  },
  {
    "zip": 15536,
    "lat": 39.95168,
    "lng": -78.20812,
    "state_id": "PA"
  },
  {
    "zip": 15537,
    "lat": 40.00171,
    "lng": -78.36501,
    "state_id": "PA"
  },
  {
    "zip": 15538,
    "lat": 39.86797,
    "lng": -78.83622,
    "state_id": "PA"
  },
  {
    "zip": 15539,
    "lat": 40.12842,
    "lng": -78.59148,
    "state_id": "PA"
  },
  {
    "zip": 15540,
    "lat": 39.79442,
    "lng": -79.23894,
    "state_id": "PA"
  },
  {
    "zip": 15541,
    "lat": 40.04808,
    "lng": -78.97719,
    "state_id": "PA"
  },
  {
    "zip": 15542,
    "lat": 39.87569,
    "lng": -79.07152,
    "state_id": "PA"
  },
  {
    "zip": 15544,
    "lat": 40.13565,
    "lng": -79.09342,
    "state_id": "PA"
  },
  {
    "zip": 15545,
    "lat": 39.78412,
    "lng": -78.77385,
    "state_id": "PA"
  },
  {
    "zip": 15546,
    "lat": 40.13667,
    "lng": -79.04803,
    "state_id": "PA"
  },
  {
    "zip": 15547,
    "lat": 40.16212,
    "lng": -79.07168,
    "state_id": "PA"
  },
  {
    "zip": 15550,
    "lat": 39.98679,
    "lng": -78.64393,
    "state_id": "PA"
  },
  {
    "zip": 15551,
    "lat": 39.88566,
    "lng": -79.27776,
    "state_id": "PA"
  },
  {
    "zip": 15552,
    "lat": 39.79393,
    "lng": -78.99184,
    "state_id": "PA"
  },
  {
    "zip": 15554,
    "lat": 40.12543,
    "lng": -78.61847,
    "state_id": "PA"
  },
  {
    "zip": 15555,
    "lat": 40.09241,
    "lng": -79.08224,
    "state_id": "PA"
  },
  {
    "zip": 15557,
    "lat": 39.93032,
    "lng": -79.21492,
    "state_id": "PA"
  },
  {
    "zip": 15558,
    "lat": 39.75016,
    "lng": -79.11157,
    "state_id": "PA"
  },
  {
    "zip": 15559,
    "lat": 40.05541,
    "lng": -78.68145,
    "state_id": "PA"
  },
  {
    "zip": 15560,
    "lat": 40.01631,
    "lng": -78.90689,
    "state_id": "PA"
  },
  {
    "zip": 15561,
    "lat": 40.09556,
    "lng": -79.08772,
    "state_id": "PA"
  },
  {
    "zip": 15562,
    "lat": 39.73904,
    "lng": -79.13644,
    "state_id": "PA"
  },
  {
    "zip": 15563,
    "lat": 40.09781,
    "lng": -78.93805,
    "state_id": "PA"
  },
  {
    "zip": 15564,
    "lat": 39.73,
    "lng": -78.83777,
    "state_id": "PA"
  },
  {
    "zip": 15601,
    "lat": 40.31519,
    "lng": -79.53552,
    "state_id": "PA"
  },
  {
    "zip": 15610,
    "lat": 40.1416,
    "lng": -79.41489,
    "state_id": "PA"
  },
  {
    "zip": 15611,
    "lat": 40.30652,
    "lng": -79.65264,
    "state_id": "PA"
  },
  {
    "zip": 15612,
    "lat": 40.13737,
    "lng": -79.59981,
    "state_id": "PA"
  },
  {
    "zip": 15613,
    "lat": 40.5538,
    "lng": -79.56425,
    "state_id": "PA"
  },
  {
    "zip": 15615,
    "lat": 40.36523,
    "lng": -79.73419,
    "state_id": "PA"
  },
  {
    "zip": 15616,
    "lat": 40.22471,
    "lng": -79.5584,
    "state_id": "PA"
  },
  {
    "zip": 15617,
    "lat": 40.26907,
    "lng": -79.65778,
    "state_id": "PA"
  },
  {
    "zip": 15618,
    "lat": 40.56219,
    "lng": -79.44515,
    "state_id": "PA"
  },
  {
    "zip": 15620,
    "lat": 40.32358,
    "lng": -79.33864,
    "state_id": "PA"
  },
  {
    "zip": 15621,
    "lat": 40.21383,
    "lng": -79.48387,
    "state_id": "PA"
  },
  {
    "zip": 15622,
    "lat": 40.04173,
    "lng": -79.32285,
    "state_id": "PA"
  },
  {
    "zip": 15623,
    "lat": 40.36727,
    "lng": -79.62031,
    "state_id": "PA"
  },
  {
    "zip": 15624,
    "lat": 40.36491,
    "lng": -79.47022,
    "state_id": "PA"
  },
  {
    "zip": 15625,
    "lat": 40.26951,
    "lng": -79.67976,
    "state_id": "PA"
  },
  {
    "zip": 15626,
    "lat": 40.40706,
    "lng": -79.57613,
    "state_id": "PA"
  },
  {
    "zip": 15627,
    "lat": 40.35126,
    "lng": -79.30599,
    "state_id": "PA"
  },
  {
    "zip": 15628,
    "lat": 40.10054,
    "lng": -79.37343,
    "state_id": "PA"
  },
  {
    "zip": 15629,
    "lat": 40.59727,
    "lng": -79.56313,
    "state_id": "PA"
  },
  {
    "zip": 15631,
    "lat": 40.08746,
    "lng": -79.58534,
    "state_id": "PA"
  },
  {
    "zip": 15632,
    "lat": 40.43898,
    "lng": -79.60686,
    "state_id": "PA"
  },
  {
    "zip": 15633,
    "lat": 40.35732,
    "lng": -79.52395,
    "state_id": "PA"
  },
  {
    "zip": 15634,
    "lat": 40.32369,
    "lng": -79.60463,
    "state_id": "PA"
  },
  {
    "zip": 15635,
    "lat": 40.349,
    "lng": -79.499,
    "state_id": "PA"
  },
  {
    "zip": 15636,
    "lat": 40.36538,
    "lng": -79.65704,
    "state_id": "PA"
  },
  {
    "zip": 15637,
    "lat": 40.26497,
    "lng": -79.71185,
    "state_id": "PA"
  },
  {
    "zip": 15638,
    "lat": 40.26436,
    "lng": -79.39891,
    "state_id": "PA"
  },
  {
    "zip": 15639,
    "lat": 40.20816,
    "lng": -79.59383,
    "state_id": "PA"
  },
  {
    "zip": 15640,
    "lat": 40.22468,
    "lng": -79.72906,
    "state_id": "PA"
  },
  {
    "zip": 15641,
    "lat": 40.63162,
    "lng": -79.58918,
    "state_id": "PA"
  },
  {
    "zip": 15642,
    "lat": 40.31918,
    "lng": -79.72046,
    "state_id": "PA"
  },
  {
    "zip": 15644,
    "lat": 40.3474,
    "lng": -79.61125,
    "state_id": "PA"
  },
  {
    "zip": 15646,
    "lat": 40.08989,
    "lng": -79.3356,
    "state_id": "PA"
  },
  {
    "zip": 15647,
    "lat": 40.34543,
    "lng": -79.7238,
    "state_id": "PA"
  },
  {
    "zip": 15650,
    "lat": 40.27576,
    "lng": -79.3932,
    "state_id": "PA"
  },
  {
    "zip": 15655,
    "lat": 40.20148,
    "lng": -79.17506,
    "state_id": "PA"
  },
  {
    "zip": 15656,
    "lat": 40.64637,
    "lng": -79.62207,
    "state_id": "PA"
  },
  {
    "zip": 15658,
    "lat": 40.25042,
    "lng": -79.22323,
    "state_id": "PA"
  },
  {
    "zip": 15660,
    "lat": 40.24468,
    "lng": -79.77438,
    "state_id": "PA"
  },
  {
    "zip": 15661,
    "lat": 40.32215,
    "lng": -79.36,
    "state_id": "PA"
  },
  {
    "zip": 15662,
    "lat": 40.33438,
    "lng": -79.47848,
    "state_id": "PA"
  },
  {
    "zip": 15663,
    "lat": 40.25179,
    "lng": -79.67922,
    "state_id": "PA"
  },
  {
    "zip": 15665,
    "lat": 40.33592,
    "lng": -79.66083,
    "state_id": "PA"
  },
  {
    "zip": 15666,
    "lat": 40.15618,
    "lng": -79.51442,
    "state_id": "PA"
  },
  {
    "zip": 15668,
    "lat": 40.46203,
    "lng": -79.67125,
    "state_id": "PA"
  },
  {
    "zip": 15670,
    "lat": 40.40695,
    "lng": -79.43008,
    "state_id": "PA"
  },
  {
    "zip": 15671,
    "lat": 40.35624,
    "lng": -79.32177,
    "state_id": "PA"
  },
  {
    "zip": 15672,
    "lat": 40.24366,
    "lng": -79.63109,
    "state_id": "PA"
  },
  {
    "zip": 15673,
    "lat": 40.5938,
    "lng": -79.5563,
    "state_id": "PA"
  },
  {
    "zip": 15675,
    "lat": 40.33531,
    "lng": -79.63708,
    "state_id": "PA"
  },
  {
    "zip": 15676,
    "lat": 40.24088,
    "lng": -79.4657,
    "state_id": "PA"
  },
  {
    "zip": 15677,
    "lat": 40.14314,
    "lng": -79.23733,
    "state_id": "PA"
  },
  {
    "zip": 15678,
    "lat": 40.28723,
    "lng": -79.72629,
    "state_id": "PA"
  },
  {
    "zip": 15679,
    "lat": 40.17515,
    "lng": -79.65085,
    "state_id": "PA"
  },
  {
    "zip": 15680,
    "lat": 40.52077,
    "lng": -79.49927,
    "state_id": "PA"
  },
  {
    "zip": 15681,
    "lat": 40.50095,
    "lng": -79.43852,
    "state_id": "PA"
  },
  {
    "zip": 15683,
    "lat": 40.10767,
    "lng": -79.60721,
    "state_id": "PA"
  },
  {
    "zip": 15684,
    "lat": 40.46071,
    "lng": -79.52062,
    "state_id": "PA"
  },
  {
    "zip": 15686,
    "lat": 40.61948,
    "lng": -79.43271,
    "state_id": "PA"
  },
  {
    "zip": 15687,
    "lat": 40.13246,
    "lng": -79.32095,
    "state_id": "PA"
  },
  {
    "zip": 15688,
    "lat": 40.16864,
    "lng": -79.58474,
    "state_id": "PA"
  },
  {
    "zip": 15689,
    "lat": 40.22141,
    "lng": -79.49141,
    "state_id": "PA"
  },
  {
    "zip": 15690,
    "lat": 40.64173,
    "lng": -79.53991,
    "state_id": "PA"
  },
  {
    "zip": 15691,
    "lat": 40.29537,
    "lng": -79.68615,
    "state_id": "PA"
  },
  {
    "zip": 15692,
    "lat": 40.33172,
    "lng": -79.67884,
    "state_id": "PA"
  },
  {
    "zip": 15693,
    "lat": 40.25312,
    "lng": -79.40769,
    "state_id": "PA"
  },
  {
    "zip": 15695,
    "lat": 40.19731,
    "lng": -79.69334,
    "state_id": "PA"
  },
  {
    "zip": 15696,
    "lat": 40.2801,
    "lng": -79.36615,
    "state_id": "PA"
  },
  {
    "zip": 15697,
    "lat": 40.24073,
    "lng": -79.57962,
    "state_id": "PA"
  },
  {
    "zip": 15698,
    "lat": 40.21638,
    "lng": -79.68947,
    "state_id": "PA"
  },
  {
    "zip": 15701,
    "lat": 40.62828,
    "lng": -79.14966,
    "state_id": "PA"
  },
  {
    "zip": 15710,
    "lat": 40.64016,
    "lng": -78.87253,
    "state_id": "PA"
  },
  {
    "zip": 15711,
    "lat": 41.00813,
    "lng": -78.96225,
    "state_id": "PA"
  },
  {
    "zip": 15712,
    "lat": 40.79044,
    "lng": -78.84789,
    "state_id": "PA"
  },
  {
    "zip": 15713,
    "lat": 40.56977,
    "lng": -79.26171,
    "state_id": "PA"
  },
  {
    "zip": 15714,
    "lat": 40.64535,
    "lng": -78.82308,
    "state_id": "PA"
  },
  {
    "zip": 15715,
    "lat": 40.97016,
    "lng": -78.87619,
    "state_id": "PA"
  },
  {
    "zip": 15716,
    "lat": 40.46688,
    "lng": -79.18799,
    "state_id": "PA"
  },
  {
    "zip": 15717,
    "lat": 40.45265,
    "lng": -79.24304,
    "state_id": "PA"
  },
  {
    "zip": 15721,
    "lat": 40.81462,
    "lng": -78.79247,
    "state_id": "PA"
  },
  {
    "zip": 15722,
    "lat": 40.5949,
    "lng": -78.72062,
    "state_id": "PA"
  },
  {
    "zip": 15723,
    "lat": 40.7002,
    "lng": -79.15918,
    "state_id": "PA"
  },
  {
    "zip": 15724,
    "lat": 40.73909,
    "lng": -78.81775,
    "state_id": "PA"
  },
  {
    "zip": 15725,
    "lat": 40.51993,
    "lng": -79.3414,
    "state_id": "PA"
  },
  {
    "zip": 15727,
    "lat": 40.55803,
    "lng": -79.30976,
    "state_id": "PA"
  },
  {
    "zip": 15728,
    "lat": 40.67553,
    "lng": -78.96699,
    "state_id": "PA"
  },
  {
    "zip": 15729,
    "lat": 40.7046,
    "lng": -78.9203,
    "state_id": "PA"
  },
  {
    "zip": 15730,
    "lat": 41.05477,
    "lng": -79.0935,
    "state_id": "PA"
  },
  {
    "zip": 15731,
    "lat": 40.50364,
    "lng": -79.17634,
    "state_id": "PA"
  },
  {
    "zip": 15732,
    "lat": 40.72711,
    "lng": -79.21358,
    "state_id": "PA"
  },
  {
    "zip": 15733,
    "lat": 40.98691,
    "lng": -78.96071,
    "state_id": "PA"
  },
  {
    "zip": 15734,
    "lat": 40.72485,
    "lng": -79.00127,
    "state_id": "PA"
  },
  {
    "zip": 15736,
    "lat": 40.70125,
    "lng": -79.36647,
    "state_id": "PA"
  },
  {
    "zip": 15737,
    "lat": 40.60517,
    "lng": -78.75778,
    "state_id": "PA"
  },
  {
    "zip": 15738,
    "lat": 40.69153,
    "lng": -78.78518,
    "state_id": "PA"
  },
  {
    "zip": 15739,
    "lat": 40.67611,
    "lng": -79.16795,
    "state_id": "PA"
  },
  {
    "zip": 15741,
    "lat": 40.80657,
    "lng": -78.88963,
    "state_id": "PA"
  },
  {
    "zip": 15742,
    "lat": 40.81439,
    "lng": -78.863,
    "state_id": "PA"
  },
  {
    "zip": 15744,
    "lat": 40.92332,
    "lng": -79.08315,
    "state_id": "PA"
  },
  {
    "zip": 15745,
    "lat": 40.62316,
    "lng": -78.91511,
    "state_id": "PA"
  },
  {
    "zip": 15746,
    "lat": 40.75876,
    "lng": -78.88588,
    "state_id": "PA"
  },
  {
    "zip": 15747,
    "lat": 40.76788,
    "lng": -79.14812,
    "state_id": "PA"
  },
  {
    "zip": 15748,
    "lat": 40.52449,
    "lng": -79.08432,
    "state_id": "PA"
  },
  {
    "zip": 15750,
    "lat": 40.48473,
    "lng": -79.18082,
    "state_id": "PA"
  },
  {
    "zip": 15752,
    "lat": 40.5399,
    "lng": -79.28301,
    "state_id": "PA"
  },
  {
    "zip": 15753,
    "lat": 40.78761,
    "lng": -78.65143,
    "state_id": "PA"
  },
  {
    "zip": 15754,
    "lat": 40.55664,
    "lng": -79.15153,
    "state_id": "PA"
  },
  {
    "zip": 15756,
    "lat": 40.56853,
    "lng": -79.29763,
    "state_id": "PA"
  },
  {
    "zip": 15757,
    "lat": 40.88746,
    "lng": -78.73536,
    "state_id": "PA"
  },
  {
    "zip": 15759,
    "lat": 40.77111,
    "lng": -79.03035,
    "state_id": "PA"
  },
  {
    "zip": 15760,
    "lat": 40.64833,
    "lng": -78.80016,
    "state_id": "PA"
  },
  {
    "zip": 15761,
    "lat": 40.63227,
    "lng": -78.88974,
    "state_id": "PA"
  },
  {
    "zip": 15762,
    "lat": 40.59721,
    "lng": -78.82989,
    "state_id": "PA"
  },
  {
    "zip": 15764,
    "lat": 40.99257,
    "lng": -79.02905,
    "state_id": "PA"
  },
  {
    "zip": 15765,
    "lat": 40.59762,
    "lng": -78.9961,
    "state_id": "PA"
  },
  {
    "zip": 15767,
    "lat": 40.96007,
    "lng": -78.96756,
    "state_id": "PA"
  },
  {
    "zip": 15770,
    "lat": 40.99583,
    "lng": -79.15645,
    "state_id": "PA"
  },
  {
    "zip": 15771,
    "lat": 40.83085,
    "lng": -78.9892,
    "state_id": "PA"
  },
  {
    "zip": 15772,
    "lat": 40.87486,
    "lng": -78.89658,
    "state_id": "PA"
  },
  {
    "zip": 15773,
    "lat": 40.62676,
    "lng": -78.73546,
    "state_id": "PA"
  },
  {
    "zip": 15774,
    "lat": 40.65694,
    "lng": -79.32667,
    "state_id": "PA"
  },
  {
    "zip": 15775,
    "lat": 40.62725,
    "lng": -78.78183,
    "state_id": "PA"
  },
  {
    "zip": 15776,
    "lat": 41.01333,
    "lng": -79.11395,
    "state_id": "PA"
  },
  {
    "zip": 15777,
    "lat": 40.69385,
    "lng": -78.96539,
    "state_id": "PA"
  },
  {
    "zip": 15778,
    "lat": 40.96755,
    "lng": -79.19735,
    "state_id": "PA"
  },
  {
    "zip": 15779,
    "lat": 40.39925,
    "lng": -79.2177,
    "state_id": "PA"
  },
  {
    "zip": 15780,
    "lat": 40.91609,
    "lng": -79.05678,
    "state_id": "PA"
  },
  {
    "zip": 15781,
    "lat": 40.96379,
    "lng": -78.98679,
    "state_id": "PA"
  },
  {
    "zip": 15783,
    "lat": 40.60677,
    "lng": -79.34801,
    "state_id": "PA"
  },
  {
    "zip": 15784,
    "lat": 41.02502,
    "lng": -79.14045,
    "state_id": "PA"
  },
  {
    "zip": 15801,
    "lat": 41.125,
    "lng": -78.72553,
    "state_id": "PA"
  },
  {
    "zip": 15821,
    "lat": 41.35537,
    "lng": -78.36769,
    "state_id": "PA"
  },
  {
    "zip": 15823,
    "lat": 41.25841,
    "lng": -78.70952,
    "state_id": "PA"
  },
  {
    "zip": 15824,
    "lat": 41.24985,
    "lng": -78.84256,
    "state_id": "PA"
  },
  {
    "zip": 15825,
    "lat": 41.1753,
    "lng": -79.04035,
    "state_id": "PA"
  },
  {
    "zip": 15827,
    "lat": 41.31921,
    "lng": -78.52169,
    "state_id": "PA"
  },
  {
    "zip": 15828,
    "lat": 41.35264,
    "lng": -79.13648,
    "state_id": "PA"
  },
  {
    "zip": 15829,
    "lat": 41.1762,
    "lng": -79.19759,
    "state_id": "PA"
  },
  {
    "zip": 15832,
    "lat": 41.35637,
    "lng": -78.19169,
    "state_id": "PA"
  },
  {
    "zip": 15834,
    "lat": 41.51217,
    "lng": -78.27772,
    "state_id": "PA"
  },
  {
    "zip": 15840,
    "lat": 41.17204,
    "lng": -78.82612,
    "state_id": "PA"
  },
  {
    "zip": 15841,
    "lat": 41.26294,
    "lng": -78.52986,
    "state_id": "PA"
  },
  {
    "zip": 15845,
    "lat": 41.50048,
    "lng": -78.69572,
    "state_id": "PA"
  },
  {
    "zip": 15846,
    "lat": 41.34038,
    "lng": -78.56787,
    "state_id": "PA"
  },
  {
    "zip": 15847,
    "lat": 41.08936,
    "lng": -79.03245,
    "state_id": "PA"
  },
  {
    "zip": 15848,
    "lat": 41.03163,
    "lng": -78.71906,
    "state_id": "PA"
  },
  {
    "zip": 15849,
    "lat": 41.17933,
    "lng": -78.55935,
    "state_id": "PA"
  },
  {
    "zip": 15851,
    "lat": 41.09965,
    "lng": -78.90269,
    "state_id": "PA"
  },
  {
    "zip": 15853,
    "lat": 41.39403,
    "lng": -78.79405,
    "state_id": "PA"
  },
  {
    "zip": 15856,
    "lat": 41.09282,
    "lng": -78.62455,
    "state_id": "PA"
  },
  {
    "zip": 15857,
    "lat": 41.45703,
    "lng": -78.53431,
    "state_id": "PA"
  },
  {
    "zip": 15860,
    "lat": 41.34781,
    "lng": -79.04804,
    "state_id": "PA"
  },
  {
    "zip": 15861,
    "lat": 41.31854,
    "lng": -78.07816,
    "state_id": "PA"
  },
  {
    "zip": 15863,
    "lat": 41.01435,
    "lng": -78.83977,
    "state_id": "PA"
  },
  {
    "zip": 15864,
    "lat": 41.10234,
    "lng": -79.19817,
    "state_id": "PA"
  },
  {
    "zip": 15865,
    "lat": 41.04525,
    "lng": -78.82744,
    "state_id": "PA"
  },
  {
    "zip": 15866,
    "lat": 41.02537,
    "lng": -78.79112,
    "state_id": "PA"
  },
  {
    "zip": 15868,
    "lat": 41.28138,
    "lng": -78.38364,
    "state_id": "PA"
  },
  {
    "zip": 15870,
    "lat": 41.58334,
    "lng": -78.57446,
    "state_id": "PA"
  },
  {
    "zip": 15901,
    "lat": 40.32839,
    "lng": -78.9142,
    "state_id": "PA"
  },
  {
    "zip": 15902,
    "lat": 40.31992,
    "lng": -78.88034,
    "state_id": "PA"
  },
  {
    "zip": 15904,
    "lat": 40.30345,
    "lng": -78.84491,
    "state_id": "PA"
  },
  {
    "zip": 15905,
    "lat": 40.28713,
    "lng": -78.97841,
    "state_id": "PA"
  },
  {
    "zip": 15906,
    "lat": 40.3727,
    "lng": -78.94602,
    "state_id": "PA"
  },
  {
    "zip": 15909,
    "lat": 40.40974,
    "lng": -78.87126,
    "state_id": "PA"
  },
  {
    "zip": 15920,
    "lat": 40.46603,
    "lng": -79.05146,
    "state_id": "PA"
  },
  {
    "zip": 15921,
    "lat": 40.31733,
    "lng": -78.70089,
    "state_id": "PA"
  },
  {
    "zip": 15922,
    "lat": 40.51762,
    "lng": -78.87663,
    "state_id": "PA"
  },
  {
    "zip": 15923,
    "lat": 40.3556,
    "lng": -79.16409,
    "state_id": "PA"
  },
  {
    "zip": 15924,
    "lat": 40.10664,
    "lng": -78.77536,
    "state_id": "PA"
  },
  {
    "zip": 15925,
    "lat": 40.40856,
    "lng": -78.64065,
    "state_id": "PA"
  },
  {
    "zip": 15926,
    "lat": 40.05189,
    "lng": -78.82426,
    "state_id": "PA"
  },
  {
    "zip": 15927,
    "lat": 40.53999,
    "lng": -78.77867,
    "state_id": "PA"
  },
  {
    "zip": 15928,
    "lat": 40.24024,
    "lng": -78.91725,
    "state_id": "PA"
  },
  {
    "zip": 15929,
    "lat": 40.46763,
    "lng": -79.00552,
    "state_id": "PA"
  },
  {
    "zip": 15930,
    "lat": 40.29488,
    "lng": -78.71872,
    "state_id": "PA"
  },
  {
    "zip": 15931,
    "lat": 40.51598,
    "lng": -78.75705,
    "state_id": "PA"
  },
  {
    "zip": 15934,
    "lat": 40.27903,
    "lng": -78.80403,
    "state_id": "PA"
  },
  {
    "zip": 15935,
    "lat": 40.20378,
    "lng": -78.9663,
    "state_id": "PA"
  },
  {
    "zip": 15936,
    "lat": 40.16337,
    "lng": -78.89446,
    "state_id": "PA"
  },
  {
    "zip": 15937,
    "lat": 40.20529,
    "lng": -78.98465,
    "state_id": "PA"
  },
  {
    "zip": 15938,
    "lat": 40.42017,
    "lng": -78.61174,
    "state_id": "PA"
  },
  {
    "zip": 15940,
    "lat": 40.52169,
    "lng": -78.62885,
    "state_id": "PA"
  },
  {
    "zip": 15942,
    "lat": 40.40348,
    "lng": -78.81228,
    "state_id": "PA"
  },
  {
    "zip": 15943,
    "lat": 40.47467,
    "lng": -78.83967,
    "state_id": "PA"
  },
  {
    "zip": 15944,
    "lat": 40.36353,
    "lng": -79.07969,
    "state_id": "PA"
  },
  {
    "zip": 15945,
    "lat": 40.35788,
    "lng": -78.86693,
    "state_id": "PA"
  },
  {
    "zip": 15946,
    "lat": 40.37234,
    "lng": -78.63277,
    "state_id": "PA"
  },
  {
    "zip": 15948,
    "lat": 40.48995,
    "lng": -78.76472,
    "state_id": "PA"
  },
  {
    "zip": 15949,
    "lat": 40.3956,
    "lng": -79.12425,
    "state_id": "PA"
  },
  {
    "zip": 15951,
    "lat": 40.33187,
    "lng": -78.77107,
    "state_id": "PA"
  },
  {
    "zip": 15952,
    "lat": 40.3035,
    "lng": -78.77614,
    "state_id": "PA"
  },
  {
    "zip": 15953,
    "lat": 40.20918,
    "lng": -78.8882,
    "state_id": "PA"
  },
  {
    "zip": 15954,
    "lat": 40.42184,
    "lng": -78.99974,
    "state_id": "PA"
  },
  {
    "zip": 15955,
    "lat": 40.32239,
    "lng": -78.69726,
    "state_id": "PA"
  },
  {
    "zip": 15956,
    "lat": 40.34731,
    "lng": -78.78559,
    "state_id": "PA"
  },
  {
    "zip": 15957,
    "lat": 40.55657,
    "lng": -78.89737,
    "state_id": "PA"
  },
  {
    "zip": 15958,
    "lat": 40.38931,
    "lng": -78.73205,
    "state_id": "PA"
  },
  {
    "zip": 15960,
    "lat": 40.49842,
    "lng": -78.88906,
    "state_id": "PA"
  },
  {
    "zip": 15961,
    "lat": 40.46679,
    "lng": -78.93769,
    "state_id": "PA"
  },
  {
    "zip": 15962,
    "lat": 40.38814,
    "lng": -78.71507,
    "state_id": "PA"
  },
  {
    "zip": 15963,
    "lat": 40.22052,
    "lng": -78.763,
    "state_id": "PA"
  },
  {
    "zip": 16001,
    "lat": 40.9095,
    "lng": -79.9438,
    "state_id": "PA"
  },
  {
    "zip": 16002,
    "lat": 40.81578,
    "lng": -79.85737,
    "state_id": "PA"
  },
  {
    "zip": 16020,
    "lat": 41.11533,
    "lng": -79.89856,
    "state_id": "PA"
  },
  {
    "zip": 16022,
    "lat": 41.05401,
    "lng": -79.73382,
    "state_id": "PA"
  },
  {
    "zip": 16023,
    "lat": 40.78551,
    "lng": -79.75032,
    "state_id": "PA"
  },
  {
    "zip": 16024,
    "lat": 40.74266,
    "lng": -80.03854,
    "state_id": "PA"
  },
  {
    "zip": 16025,
    "lat": 40.94467,
    "lng": -79.75638,
    "state_id": "PA"
  },
  {
    "zip": 16027,
    "lat": 40.82019,
    "lng": -80.01925,
    "state_id": "PA"
  },
  {
    "zip": 16028,
    "lat": 40.95792,
    "lng": -79.63702,
    "state_id": "PA"
  },
  {
    "zip": 16029,
    "lat": 40.87929,
    "lng": -79.84656,
    "state_id": "PA"
  },
  {
    "zip": 16030,
    "lat": 41.13637,
    "lng": -79.79704,
    "state_id": "PA"
  },
  {
    "zip": 16033,
    "lat": 40.79163,
    "lng": -80.04531,
    "state_id": "PA"
  },
  {
    "zip": 16034,
    "lat": 40.86139,
    "lng": -79.72973,
    "state_id": "PA"
  },
  {
    "zip": 16035,
    "lat": 41.10672,
    "lng": -80.00996,
    "state_id": "PA"
  },
  {
    "zip": 16036,
    "lat": 41.13541,
    "lng": -79.66917,
    "state_id": "PA"
  },
  {
    "zip": 16037,
    "lat": 40.85315,
    "lng": -80.12822,
    "state_id": "PA"
  },
  {
    "zip": 16038,
    "lat": 41.16001,
    "lng": -79.95132,
    "state_id": "PA"
  },
  {
    "zip": 16040,
    "lat": 41.09255,
    "lng": -79.84301,
    "state_id": "PA"
  },
  {
    "zip": 16041,
    "lat": 41.00531,
    "lng": -79.71495,
    "state_id": "PA"
  },
  {
    "zip": 16045,
    "lat": 40.85285,
    "lng": -79.91668,
    "state_id": "PA"
  },
  {
    "zip": 16046,
    "lat": 40.70069,
    "lng": -80.03006,
    "state_id": "PA"
  },
  {
    "zip": 16048,
    "lat": 41.05318,
    "lng": -79.81842,
    "state_id": "PA"
  },
  {
    "zip": 16049,
    "lat": 41.09663,
    "lng": -79.67259,
    "state_id": "PA"
  },
  {
    "zip": 16050,
    "lat": 41.04332,
    "lng": -79.77173,
    "state_id": "PA"
  },
  {
    "zip": 16051,
    "lat": 40.94671,
    "lng": -80.13852,
    "state_id": "PA"
  },
  {
    "zip": 16052,
    "lat": 40.90468,
    "lng": -80.0614,
    "state_id": "PA"
  },
  {
    "zip": 16053,
    "lat": 40.81188,
    "lng": -79.98316,
    "state_id": "PA"
  },
  {
    "zip": 16054,
    "lat": 41.15704,
    "lng": -79.65744,
    "state_id": "PA"
  },
  {
    "zip": 16055,
    "lat": 40.7157,
    "lng": -79.74802,
    "state_id": "PA"
  },
  {
    "zip": 16056,
    "lat": 40.723,
    "lng": -79.84105,
    "state_id": "PA"
  },
  {
    "zip": 16057,
    "lat": 41.03455,
    "lng": -80.05676,
    "state_id": "PA"
  },
  {
    "zip": 16059,
    "lat": 40.70401,
    "lng": -79.9285,
    "state_id": "PA"
  },
  {
    "zip": 16061,
    "lat": 41.00659,
    "lng": -79.88699,
    "state_id": "PA"
  },
  {
    "zip": 16063,
    "lat": 40.76488,
    "lng": -80.1274,
    "state_id": "PA"
  },
  {
    "zip": 16066,
    "lat": 40.71109,
    "lng": -80.10588,
    "state_id": "PA"
  },
  {
    "zip": 16101,
    "lat": 40.98532,
    "lng": -80.28716,
    "state_id": "PA"
  },
  {
    "zip": 16102,
    "lat": 40.96067,
    "lng": -80.42074,
    "state_id": "PA"
  },
  {
    "zip": 16105,
    "lat": 41.05351,
    "lng": -80.33815,
    "state_id": "PA"
  },
  {
    "zip": 16110,
    "lat": 41.50609,
    "lng": -80.3849,
    "state_id": "PA"
  },
  {
    "zip": 16111,
    "lat": 41.52038,
    "lng": -80.2826,
    "state_id": "PA"
  },
  {
    "zip": 16112,
    "lat": 40.96819,
    "lng": -80.50177,
    "state_id": "PA"
  },
  {
    "zip": 16113,
    "lat": 41.28153,
    "lng": -80.42506,
    "state_id": "PA"
  },
  {
    "zip": 16114,
    "lat": 41.40413,
    "lng": -80.18545,
    "state_id": "PA"
  },
  {
    "zip": 16115,
    "lat": 40.79345,
    "lng": -80.46336,
    "state_id": "PA"
  },
  {
    "zip": 16116,
    "lat": 41.03631,
    "lng": -80.45212,
    "state_id": "PA"
  },
  {
    "zip": 16117,
    "lat": 40.87294,
    "lng": -80.25591,
    "state_id": "PA"
  },
  {
    "zip": 16120,
    "lat": 40.89169,
    "lng": -80.47295,
    "state_id": "PA"
  },
  {
    "zip": 16121,
    "lat": 41.21121,
    "lng": -80.49725,
    "state_id": "PA"
  },
  {
    "zip": 16123,
    "lat": 40.81959,
    "lng": -80.19818,
    "state_id": "PA"
  },
  {
    "zip": 16124,
    "lat": 41.33181,
    "lng": -80.2659,
    "state_id": "PA"
  },
  {
    "zip": 16125,
    "lat": 41.40597,
    "lng": -80.37338,
    "state_id": "PA"
  },
  {
    "zip": 16127,
    "lat": 41.17286,
    "lng": -80.07283,
    "state_id": "PA"
  },
  {
    "zip": 16130,
    "lat": 41.44324,
    "lng": -80.21527,
    "state_id": "PA"
  },
  {
    "zip": 16131,
    "lat": 41.55038,
    "lng": -80.37756,
    "state_id": "PA"
  },
  {
    "zip": 16132,
    "lat": 41.00591,
    "lng": -80.50568,
    "state_id": "PA"
  },
  {
    "zip": 16133,
    "lat": 41.27175,
    "lng": -80.11534,
    "state_id": "PA"
  },
  {
    "zip": 16134,
    "lat": 41.50588,
    "lng": -80.46407,
    "state_id": "PA"
  },
  {
    "zip": 16136,
    "lat": 40.83423,
    "lng": -80.3237,
    "state_id": "PA"
  },
  {
    "zip": 16137,
    "lat": 41.23013,
    "lng": -80.23846,
    "state_id": "PA"
  },
  {
    "zip": 16140,
    "lat": 41.0953,
    "lng": -80.5141,
    "state_id": "PA"
  },
  {
    "zip": 16141,
    "lat": 40.87041,
    "lng": -80.39624,
    "state_id": "PA"
  },
  {
    "zip": 16142,
    "lat": 41.14055,
    "lng": -80.33906,
    "state_id": "PA"
  },
  {
    "zip": 16143,
    "lat": 41.10551,
    "lng": -80.45301,
    "state_id": "PA"
  },
  {
    "zip": 16145,
    "lat": 41.38525,
    "lng": -80.07182,
    "state_id": "PA"
  },
  {
    "zip": 16146,
    "lat": 41.23412,
    "lng": -80.49958,
    "state_id": "PA"
  },
  {
    "zip": 16148,
    "lat": 41.23117,
    "lng": -80.43193,
    "state_id": "PA"
  },
  {
    "zip": 16150,
    "lat": 41.28553,
    "lng": -80.44388,
    "state_id": "PA"
  },
  {
    "zip": 16151,
    "lat": 41.4437,
    "lng": -80.207,
    "state_id": "PA"
  },
  {
    "zip": 16153,
    "lat": 41.31813,
    "lng": -80.08106,
    "state_id": "PA"
  },
  {
    "zip": 16154,
    "lat": 41.32448,
    "lng": -80.42086,
    "state_id": "PA"
  },
  {
    "zip": 16155,
    "lat": 41.0677,
    "lng": -80.51078,
    "state_id": "PA"
  },
  {
    "zip": 16156,
    "lat": 41.0956,
    "lng": -80.21801,
    "state_id": "PA"
  },
  {
    "zip": 16157,
    "lat": 40.88881,
    "lng": -80.33558,
    "state_id": "PA"
  },
  {
    "zip": 16159,
    "lat": 41.15855,
    "lng": -80.46838,
    "state_id": "PA"
  },
  {
    "zip": 16160,
    "lat": 40.92782,
    "lng": -80.36355,
    "state_id": "PA"
  },
  {
    "zip": 16161,
    "lat": 41.19701,
    "lng": -80.49587,
    "state_id": "PA"
  },
  {
    "zip": 16201,
    "lat": 40.80913,
    "lng": -79.47288,
    "state_id": "PA"
  },
  {
    "zip": 16210,
    "lat": 40.89804,
    "lng": -79.51387,
    "state_id": "PA"
  },
  {
    "zip": 16211,
    "lat": 40.7945,
    "lng": -79.20253,
    "state_id": "PA"
  },
  {
    "zip": 16212,
    "lat": 40.75312,
    "lng": -79.58463,
    "state_id": "PA"
  },
  {
    "zip": 16213,
    "lat": 41.12569,
    "lng": -79.55779,
    "state_id": "PA"
  },
  {
    "zip": 16214,
    "lat": 41.20315,
    "lng": -79.35754,
    "state_id": "PA"
  },
  {
    "zip": 16217,
    "lat": 41.33655,
    "lng": -79.18477,
    "state_id": "PA"
  },
  {
    "zip": 16218,
    "lat": 40.92679,
    "lng": -79.592,
    "state_id": "PA"
  },
  {
    "zip": 16222,
    "lat": 40.87377,
    "lng": -79.26088,
    "state_id": "PA"
  },
  {
    "zip": 16223,
    "lat": 40.97594,
    "lng": -79.3643,
    "state_id": "PA"
  },
  {
    "zip": 16224,
    "lat": 41.0623,
    "lng": -79.29393,
    "state_id": "PA"
  },
  {
    "zip": 16226,
    "lat": 40.71097,
    "lng": -79.48683,
    "state_id": "PA"
  },
  {
    "zip": 16228,
    "lat": 40.76079,
    "lng": -79.53575,
    "state_id": "PA"
  },
  {
    "zip": 16229,
    "lat": 40.71796,
    "lng": -79.64488,
    "state_id": "PA"
  },
  {
    "zip": 16230,
    "lat": 41.02099,
    "lng": -79.2782,
    "state_id": "PA"
  },
  {
    "zip": 16232,
    "lat": 41.22117,
    "lng": -79.54863,
    "state_id": "PA"
  },
  {
    "zip": 16233,
    "lat": 41.36617,
    "lng": -79.272,
    "state_id": "PA"
  },
  {
    "zip": 16235,
    "lat": 41.31759,
    "lng": -79.35437,
    "state_id": "PA"
  },
  {
    "zip": 16236,
    "lat": 40.78487,
    "lng": -79.52323,
    "state_id": "PA"
  },
  {
    "zip": 16238,
    "lat": 40.78776,
    "lng": -79.52068,
    "state_id": "PA"
  },
  {
    "zip": 16239,
    "lat": 41.49187,
    "lng": -79.13575,
    "state_id": "PA"
  },
  {
    "zip": 16240,
    "lat": 41.0335,
    "lng": -79.22467,
    "state_id": "PA"
  },
  {
    "zip": 16242,
    "lat": 41.00749,
    "lng": -79.36039,
    "state_id": "PA"
  },
  {
    "zip": 16244,
    "lat": 40.79217,
    "lng": -79.27665,
    "state_id": "PA"
  },
  {
    "zip": 16245,
    "lat": 41.00681,
    "lng": -79.29843,
    "state_id": "PA"
  },
  {
    "zip": 16246,
    "lat": 40.79169,
    "lng": -79.18222,
    "state_id": "PA"
  },
  {
    "zip": 16248,
    "lat": 41.03487,
    "lng": -79.50536,
    "state_id": "PA"
  },
  {
    "zip": 16249,
    "lat": 40.7665,
    "lng": -79.31512,
    "state_id": "PA"
  },
  {
    "zip": 16250,
    "lat": 40.76826,
    "lng": -79.23986,
    "state_id": "PA"
  },
  {
    "zip": 16253,
    "lat": 40.95148,
    "lng": -79.34286,
    "state_id": "PA"
  },
  {
    "zip": 16254,
    "lat": 41.26841,
    "lng": -79.45741,
    "state_id": "PA"
  },
  {
    "zip": 16255,
    "lat": 41.12753,
    "lng": -79.46442,
    "state_id": "PA"
  },
  {
    "zip": 16256,
    "lat": 40.86666,
    "lng": -79.13758,
    "state_id": "PA"
  },
  {
    "zip": 16258,
    "lat": 41.23343,
    "lng": -79.27797,
    "state_id": "PA"
  },
  {
    "zip": 16259,
    "lat": 40.92681,
    "lng": -79.45501,
    "state_id": "PA"
  },
  {
    "zip": 16260,
    "lat": 41.39565,
    "lng": -79.22659,
    "state_id": "PA"
  },
  {
    "zip": 16262,
    "lat": 40.83904,
    "lng": -79.65217,
    "state_id": "PA"
  },
  {
    "zip": 16263,
    "lat": 40.7974,
    "lng": -79.33506,
    "state_id": "PA"
  },
  {
    "zip": 16301,
    "lat": 41.45555,
    "lng": -79.6353,
    "state_id": "PA"
  },
  {
    "zip": 16311,
    "lat": 41.45675,
    "lng": -80.03446,
    "state_id": "PA"
  },
  {
    "zip": 16312,
    "lat": 41.93214,
    "lng": -79.30324,
    "state_id": "PA"
  },
  {
    "zip": 16313,
    "lat": 41.7342,
    "lng": -79.12155,
    "state_id": "PA"
  },
  {
    "zip": 16314,
    "lat": 41.51705,
    "lng": -80.06621,
    "state_id": "PA"
  },
  {
    "zip": 16316,
    "lat": 41.60181,
    "lng": -80.30344,
    "state_id": "PA"
  },
  {
    "zip": 16317,
    "lat": 41.53254,
    "lng": -79.85367,
    "state_id": "PA"
  },
  {
    "zip": 16319,
    "lat": 41.31974,
    "lng": -79.63169,
    "state_id": "PA"
  },
  {
    "zip": 16321,
    "lat": 41.57103,
    "lng": -79.3996,
    "state_id": "PA"
  },
  {
    "zip": 16322,
    "lat": 41.59896,
    "lng": -79.3737,
    "state_id": "PA"
  },
  {
    "zip": 16323,
    "lat": 41.41271,
    "lng": -79.82922,
    "state_id": "PA"
  },
  {
    "zip": 16326,
    "lat": 41.3673,
    "lng": -79.43182,
    "state_id": "PA"
  },
  {
    "zip": 16327,
    "lat": 41.63245,
    "lng": -79.96066,
    "state_id": "PA"
  },
  {
    "zip": 16328,
    "lat": 41.65297,
    "lng": -79.72511,
    "state_id": "PA"
  },
  {
    "zip": 16329,
    "lat": 41.80994,
    "lng": -79.26449,
    "state_id": "PA"
  },
  {
    "zip": 16331,
    "lat": 41.29258,
    "lng": -79.56554,
    "state_id": "PA"
  },
  {
    "zip": 16332,
    "lat": 41.35695,
    "lng": -79.368,
    "state_id": "PA"
  },
  {
    "zip": 16333,
    "lat": 41.70345,
    "lng": -78.92856,
    "state_id": "PA"
  },
  {
    "zip": 16334,
    "lat": 41.29108,
    "lng": -79.45521,
    "state_id": "PA"
  },
  {
    "zip": 16335,
    "lat": 41.63092,
    "lng": -80.15633,
    "state_id": "PA"
  },
  {
    "zip": 16340,
    "lat": 41.8024,
    "lng": -79.41765,
    "state_id": "PA"
  },
  {
    "zip": 16341,
    "lat": 41.56607,
    "lng": -79.54934,
    "state_id": "PA"
  },
  {
    "zip": 16342,
    "lat": 41.32265,
    "lng": -79.93081,
    "state_id": "PA"
  },
  {
    "zip": 16343,
    "lat": 41.42206,
    "lng": -79.75234,
    "state_id": "PA"
  },
  {
    "zip": 16344,
    "lat": 41.47041,
    "lng": -79.68456,
    "state_id": "PA"
  },
  {
    "zip": 16345,
    "lat": 41.95196,
    "lng": -79.07196,
    "state_id": "PA"
  },
  {
    "zip": 16346,
    "lat": 41.3785,
    "lng": -79.67198,
    "state_id": "PA"
  },
  {
    "zip": 16347,
    "lat": 41.6471,
    "lng": -79.06077,
    "state_id": "PA"
  },
  {
    "zip": 16350,
    "lat": 41.96557,
    "lng": -79.3343,
    "state_id": "PA"
  },
  {
    "zip": 16351,
    "lat": 41.67827,
    "lng": -79.35991,
    "state_id": "PA"
  },
  {
    "zip": 16352,
    "lat": 41.76719,
    "lng": -79.03847,
    "state_id": "PA"
  },
  {
    "zip": 16353,
    "lat": 41.50109,
    "lng": -79.377,
    "state_id": "PA"
  },
  {
    "zip": 16354,
    "lat": 41.6137,
    "lng": -79.70641,
    "state_id": "PA"
  },
  {
    "zip": 16360,
    "lat": 41.68422,
    "lng": -79.88966,
    "state_id": "PA"
  },
  {
    "zip": 16361,
    "lat": 41.38596,
    "lng": -79.34279,
    "state_id": "PA"
  },
  {
    "zip": 16362,
    "lat": 41.45033,
    "lng": -79.96236,
    "state_id": "PA"
  },
  {
    "zip": 16364,
    "lat": 41.36403,
    "lng": -79.51593,
    "state_id": "PA"
  },
  {
    "zip": 16365,
    "lat": 41.84174,
    "lng": -79.15831,
    "state_id": "PA"
  },
  {
    "zip": 16370,
    "lat": 41.57139,
    "lng": -79.44023,
    "state_id": "PA"
  },
  {
    "zip": 16371,
    "lat": 41.86682,
    "lng": -79.31889,
    "state_id": "PA"
  },
  {
    "zip": 16372,
    "lat": 41.19996,
    "lng": -79.87306,
    "state_id": "PA"
  },
  {
    "zip": 16373,
    "lat": 41.20719,
    "lng": -79.6994,
    "state_id": "PA"
  },
  {
    "zip": 16374,
    "lat": 41.26215,
    "lng": -79.81382,
    "state_id": "PA"
  },
  {
    "zip": 16401,
    "lat": 41.8811,
    "lng": -80.40485,
    "state_id": "PA"
  },
  {
    "zip": 16402,
    "lat": 41.95791,
    "lng": -79.46564,
    "state_id": "PA"
  },
  {
    "zip": 16403,
    "lat": 41.79277,
    "lng": -80.02015,
    "state_id": "PA"
  },
  {
    "zip": 16404,
    "lat": 41.72561,
    "lng": -79.78907,
    "state_id": "PA"
  },
  {
    "zip": 16405,
    "lat": 41.94691,
    "lng": -79.53472,
    "state_id": "PA"
  },
  {
    "zip": 16406,
    "lat": 41.74949,
    "lng": -80.36272,
    "state_id": "PA"
  },
  {
    "zip": 16407,
    "lat": 41.91978,
    "lng": -79.66328,
    "state_id": "PA"
  },
  {
    "zip": 16410,
    "lat": 41.92107,
    "lng": -80.30603,
    "state_id": "PA"
  },
  {
    "zip": 16411,
    "lat": 41.98131,
    "lng": -80.4463,
    "state_id": "PA"
  },
  {
    "zip": 16412,
    "lat": 41.88323,
    "lng": -80.16634,
    "state_id": "PA"
  },
  {
    "zip": 16415,
    "lat": 42.0309,
    "lng": -80.23041,
    "state_id": "PA"
  },
  {
    "zip": 16416,
    "lat": 41.82575,
    "lng": -79.4727,
    "state_id": "PA"
  },
  {
    "zip": 16417,
    "lat": 41.97087,
    "lng": -80.31327,
    "state_id": "PA"
  },
  {
    "zip": 16420,
    "lat": 41.69633,
    "lng": -79.54726,
    "state_id": "PA"
  },
  {
    "zip": 16421,
    "lat": 42.17118,
    "lng": -79.9328,
    "state_id": "PA"
  },
  {
    "zip": 16422,
    "lat": 41.66559,
    "lng": -80.31447,
    "state_id": "PA"
  },
  {
    "zip": 16423,
    "lat": 42.01841,
    "lng": -80.34342,
    "state_id": "PA"
  },
  {
    "zip": 16424,
    "lat": 41.65917,
    "lng": -80.44202,
    "state_id": "PA"
  },
  {
    "zip": 16426,
    "lat": 41.97966,
    "lng": -80.14223,
    "state_id": "PA"
  },
  {
    "zip": 16427,
    "lat": 41.87732,
    "lng": -79.96793,
    "state_id": "PA"
  },
  {
    "zip": 16428,
    "lat": 42.17062,
    "lng": -79.82852,
    "state_id": "PA"
  },
  {
    "zip": 16433,
    "lat": 41.73522,
    "lng": -80.14297,
    "state_id": "PA"
  },
  {
    "zip": 16434,
    "lat": 41.79378,
    "lng": -79.66503,
    "state_id": "PA"
  },
  {
    "zip": 16435,
    "lat": 41.81823,
    "lng": -80.38408,
    "state_id": "PA"
  },
  {
    "zip": 16436,
    "lat": 41.85371,
    "lng": -79.50646,
    "state_id": "PA"
  },
  {
    "zip": 16438,
    "lat": 41.89229,
    "lng": -79.84418,
    "state_id": "PA"
  },
  {
    "zip": 16440,
    "lat": 41.78768,
    "lng": -80.12758,
    "state_id": "PA"
  },
  {
    "zip": 16441,
    "lat": 41.95586,
    "lng": -79.98764,
    "state_id": "PA"
  },
  {
    "zip": 16442,
    "lat": 42.04031,
    "lng": -79.82596,
    "state_id": "PA"
  },
  {
    "zip": 16443,
    "lat": 41.93882,
    "lng": -80.48026,
    "state_id": "PA"
  },
  {
    "zip": 16444,
    "lat": 41.87069,
    "lng": -80.12175,
    "state_id": "PA"
  },
  {
    "zip": 16501,
    "lat": 42.12129,
    "lng": -80.08922,
    "state_id": "PA"
  },
  {
    "zip": 16502,
    "lat": 42.11066,
    "lng": -80.10143,
    "state_id": "PA"
  },
  {
    "zip": 16503,
    "lat": 42.12696,
    "lng": -80.06119,
    "state_id": "PA"
  },
  {
    "zip": 16504,
    "lat": 42.10904,
    "lng": -80.04903,
    "state_id": "PA"
  },
  {
    "zip": 16505,
    "lat": 42.11426,
    "lng": -80.14822,
    "state_id": "PA"
  },
  {
    "zip": 16506,
    "lat": 42.06376,
    "lng": -80.15268,
    "state_id": "PA"
  },
  {
    "zip": 16507,
    "lat": 42.13615,
    "lng": -80.08658,
    "state_id": "PA"
  },
  {
    "zip": 16508,
    "lat": 42.09657,
    "lng": -80.09348,
    "state_id": "PA"
  },
  {
    "zip": 16509,
    "lat": 42.05876,
    "lng": -80.04254,
    "state_id": "PA"
  },
  {
    "zip": 16510,
    "lat": 42.11289,
    "lng": -79.95458,
    "state_id": "PA"
  },
  {
    "zip": 16511,
    "lat": 42.16584,
    "lng": -79.99514,
    "state_id": "PA"
  },
  {
    "zip": 16546,
    "lat": 42.10496,
    "lng": -80.05346,
    "state_id": "PA"
  },
  {
    "zip": 16563,
    "lat": 42.11924,
    "lng": -79.98653,
    "state_id": "PA"
  },
  {
    "zip": 16601,
    "lat": 40.55566,
    "lng": -78.37018,
    "state_id": "PA"
  },
  {
    "zip": 16602,
    "lat": 40.5124,
    "lng": -78.37222,
    "state_id": "PA"
  },
  {
    "zip": 16611,
    "lat": 40.5674,
    "lng": -78.10768,
    "state_id": "PA"
  },
  {
    "zip": 16613,
    "lat": 40.54804,
    "lng": -78.53759,
    "state_id": "PA"
  },
  {
    "zip": 16616,
    "lat": 40.76829,
    "lng": -78.44556,
    "state_id": "PA"
  },
  {
    "zip": 16617,
    "lat": 40.60364,
    "lng": -78.33053,
    "state_id": "PA"
  },
  {
    "zip": 16619,
    "lat": 40.66207,
    "lng": -78.44157,
    "state_id": "PA"
  },
  {
    "zip": 16620,
    "lat": 40.8395,
    "lng": -78.35112,
    "state_id": "PA"
  },
  {
    "zip": 16621,
    "lat": 40.23229,
    "lng": -78.12518,
    "state_id": "PA"
  },
  {
    "zip": 16622,
    "lat": 40.32864,
    "lng": -78.06643,
    "state_id": "PA"
  },
  {
    "zip": 16623,
    "lat": 40.27155,
    "lng": -78.05223,
    "state_id": "PA"
  },
  {
    "zip": 16624,
    "lat": 40.5704,
    "lng": -78.60044,
    "state_id": "PA"
  },
  {
    "zip": 16625,
    "lat": 40.28208,
    "lng": -78.50833,
    "state_id": "PA"
  },
  {
    "zip": 16627,
    "lat": 40.75578,
    "lng": -78.49757,
    "state_id": "PA"
  },
  {
    "zip": 16630,
    "lat": 40.45615,
    "lng": -78.57642,
    "state_id": "PA"
  },
  {
    "zip": 16631,
    "lat": 40.27276,
    "lng": -78.34561,
    "state_id": "PA"
  },
  {
    "zip": 16633,
    "lat": 40.15742,
    "lng": -78.23491,
    "state_id": "PA"
  },
  {
    "zip": 16634,
    "lat": 40.21093,
    "lng": -78.17274,
    "state_id": "PA"
  },
  {
    "zip": 16635,
    "lat": 40.41513,
    "lng": -78.4893,
    "state_id": "PA"
  },
  {
    "zip": 16636,
    "lat": 40.60247,
    "lng": -78.50332,
    "state_id": "PA"
  },
  {
    "zip": 16637,
    "lat": 40.34111,
    "lng": -78.46718,
    "state_id": "PA"
  },
  {
    "zip": 16638,
    "lat": 40.3366,
    "lng": -78.20362,
    "state_id": "PA"
  },
  {
    "zip": 16639,
    "lat": 40.69468,
    "lng": -78.454,
    "state_id": "PA"
  },
  {
    "zip": 16640,
    "lat": 40.7069,
    "lng": -78.57689,
    "state_id": "PA"
  },
  {
    "zip": 16641,
    "lat": 40.501,
    "lng": -78.54076,
    "state_id": "PA"
  },
  {
    "zip": 16645,
    "lat": 40.7989,
    "lng": -78.49901,
    "state_id": "PA"
  },
  {
    "zip": 16646,
    "lat": 40.6807,
    "lng": -78.71439,
    "state_id": "PA"
  },
  {
    "zip": 16647,
    "lat": 40.39906,
    "lng": -78.10079,
    "state_id": "PA"
  },
  {
    "zip": 16648,
    "lat": 40.43804,
    "lng": -78.33669,
    "state_id": "PA"
  },
  {
    "zip": 16650,
    "lat": 40.11793,
    "lng": -78.28902,
    "state_id": "PA"
  },
  {
    "zip": 16651,
    "lat": 40.84911,
    "lng": -78.38025,
    "state_id": "PA"
  },
  {
    "zip": 16652,
    "lat": 40.51478,
    "lng": -77.95686,
    "state_id": "PA"
  },
  {
    "zip": 16655,
    "lat": 40.24865,
    "lng": -78.55158,
    "state_id": "PA"
  },
  {
    "zip": 16656,
    "lat": 40.80683,
    "lng": -78.56415,
    "state_id": "PA"
  },
  {
    "zip": 16657,
    "lat": 40.32177,
    "lng": -78.17793,
    "state_id": "PA"
  },
  {
    "zip": 16659,
    "lat": 40.16155,
    "lng": -78.38969,
    "state_id": "PA"
  },
  {
    "zip": 16661,
    "lat": 40.83603,
    "lng": -78.45653,
    "state_id": "PA"
  },
  {
    "zip": 16662,
    "lat": 40.31357,
    "lng": -78.29615,
    "state_id": "PA"
  },
  {
    "zip": 16664,
    "lat": 40.17966,
    "lng": -78.4243,
    "state_id": "PA"
  },
  {
    "zip": 16665,
    "lat": 40.38872,
    "lng": -78.43429,
    "state_id": "PA"
  },
  {
    "zip": 16666,
    "lat": 40.87687,
    "lng": -78.32334,
    "state_id": "PA"
  },
  {
    "zip": 16667,
    "lat": 40.17991,
    "lng": -78.53107,
    "state_id": "PA"
  },
  {
    "zip": 16668,
    "lat": 40.652,
    "lng": -78.62041,
    "state_id": "PA"
  },
  {
    "zip": 16669,
    "lat": 40.65652,
    "lng": -77.90714,
    "state_id": "PA"
  },
  {
    "zip": 16670,
    "lat": 40.26267,
    "lng": -78.50805,
    "state_id": "PA"
  },
  {
    "zip": 16671,
    "lat": 40.79412,
    "lng": -78.40205,
    "state_id": "PA"
  },
  {
    "zip": 16672,
    "lat": 40.17222,
    "lng": -78.24212,
    "state_id": "PA"
  },
  {
    "zip": 16673,
    "lat": 40.31244,
    "lng": -78.39148,
    "state_id": "PA"
  },
  {
    "zip": 16674,
    "lat": 40.18941,
    "lng": -78.08824,
    "state_id": "PA"
  },
  {
    "zip": 16677,
    "lat": 40.81181,
    "lng": -78.24366,
    "state_id": "PA"
  },
  {
    "zip": 16678,
    "lat": 40.22378,
    "lng": -78.23818,
    "state_id": "PA"
  },
  {
    "zip": 16679,
    "lat": 40.15712,
    "lng": -78.19729,
    "state_id": "PA"
  },
  {
    "zip": 16680,
    "lat": 40.75065,
    "lng": -78.40127,
    "state_id": "PA"
  },
  {
    "zip": 16682,
    "lat": 40.26608,
    "lng": -78.45782,
    "state_id": "PA"
  },
  {
    "zip": 16683,
    "lat": 40.64783,
    "lng": -78.08316,
    "state_id": "PA"
  },
  {
    "zip": 16685,
    "lat": 40.28753,
    "lng": -78.08262,
    "state_id": "PA"
  },
  {
    "zip": 16686,
    "lat": 40.66829,
    "lng": -78.25087,
    "state_id": "PA"
  },
  {
    "zip": 16689,
    "lat": 40.10467,
    "lng": -78.09632,
    "state_id": "PA"
  },
  {
    "zip": 16691,
    "lat": 40.07749,
    "lng": -78.14592,
    "state_id": "PA"
  },
  {
    "zip": 16692,
    "lat": 40.75601,
    "lng": -78.72003,
    "state_id": "PA"
  },
  {
    "zip": 16693,
    "lat": 40.4727,
    "lng": -78.21444,
    "state_id": "PA"
  },
  {
    "zip": 16694,
    "lat": 40.15625,
    "lng": -78.14856,
    "state_id": "PA"
  },
  {
    "zip": 16695,
    "lat": 40.20859,
    "lng": -78.36079,
    "state_id": "PA"
  },
  {
    "zip": 16699,
    "lat": 40.44736,
    "lng": -78.56054,
    "state_id": "PA"
  },
  {
    "zip": 16701,
    "lat": 41.9179,
    "lng": -78.76293,
    "state_id": "PA"
  },
  {
    "zip": 16720,
    "lat": 41.5841,
    "lng": -78.03707,
    "state_id": "PA"
  },
  {
    "zip": 16724,
    "lat": 41.73335,
    "lng": -78.37117,
    "state_id": "PA"
  },
  {
    "zip": 16725,
    "lat": 41.90645,
    "lng": -78.65296,
    "state_id": "PA"
  },
  {
    "zip": 16726,
    "lat": 41.80767,
    "lng": -78.57296,
    "state_id": "PA"
  },
  {
    "zip": 16727,
    "lat": 41.981,
    "lng": -78.53002,
    "state_id": "PA"
  },
  {
    "zip": 16728,
    "lat": 41.56577,
    "lng": -78.92422,
    "state_id": "PA"
  },
  {
    "zip": 16729,
    "lat": 41.96243,
    "lng": -78.47598,
    "state_id": "PA"
  },
  {
    "zip": 16730,
    "lat": 41.81688,
    "lng": -78.42266,
    "state_id": "PA"
  },
  {
    "zip": 16731,
    "lat": 41.94555,
    "lng": -78.37465,
    "state_id": "PA"
  },
  {
    "zip": 16732,
    "lat": 41.85724,
    "lng": -78.61727,
    "state_id": "PA"
  },
  {
    "zip": 16733,
    "lat": 41.70324,
    "lng": -78.59526,
    "state_id": "PA"
  },
  {
    "zip": 16734,
    "lat": 41.61853,
    "lng": -78.84053,
    "state_id": "PA"
  },
  {
    "zip": 16735,
    "lat": 41.64109,
    "lng": -78.81382,
    "state_id": "PA"
  },
  {
    "zip": 16738,
    "lat": 41.80802,
    "lng": -78.71912,
    "state_id": "PA"
  },
  {
    "zip": 16740,
    "lat": 41.70402,
    "lng": -78.57091,
    "state_id": "PA"
  },
  {
    "zip": 16743,
    "lat": 41.78909,
    "lng": -78.26196,
    "state_id": "PA"
  },
  {
    "zip": 16744,
    "lat": 41.86705,
    "lng": -78.56709,
    "state_id": "PA"
  },
  {
    "zip": 16745,
    "lat": 41.92213,
    "lng": -78.48345,
    "state_id": "PA"
  },
  {
    "zip": 16746,
    "lat": 41.79396,
    "lng": -78.13637,
    "state_id": "PA"
  },
  {
    "zip": 16748,
    "lat": 41.94449,
    "lng": -78.15296,
    "state_id": "PA"
  },
  {
    "zip": 16749,
    "lat": 41.76542,
    "lng": -78.4365,
    "state_id": "PA"
  },
  {
    "zip": 16750,
    "lat": 41.88542,
    "lng": -78.29434,
    "state_id": "PA"
  },
  {
    "zip": 16801,
    "lat": 40.77888,
    "lng": -77.84134,
    "state_id": "PA"
  },
  {
    "zip": 16802,
    "lat": 40.80269,
    "lng": -77.86063,
    "state_id": "PA"
  },
  {
    "zip": 16803,
    "lat": 40.80168,
    "lng": -77.89958,
    "state_id": "PA"
  },
  {
    "zip": 16820,
    "lat": 40.89564,
    "lng": -77.39238,
    "state_id": "PA"
  },
  {
    "zip": 16821,
    "lat": 40.96282,
    "lng": -78.20181,
    "state_id": "PA"
  },
  {
    "zip": 16822,
    "lat": 41.15516,
    "lng": -77.70409,
    "state_id": "PA"
  },
  {
    "zip": 16823,
    "lat": 40.93769,
    "lng": -77.77303,
    "state_id": "PA"
  },
  {
    "zip": 16825,
    "lat": 40.98624,
    "lng": -78.31193,
    "state_id": "PA"
  },
  {
    "zip": 16826,
    "lat": 41.0642,
    "lng": -77.59501,
    "state_id": "PA"
  },
  {
    "zip": 16827,
    "lat": 40.76094,
    "lng": -77.76561,
    "state_id": "PA"
  },
  {
    "zip": 16828,
    "lat": 40.81299,
    "lng": -77.68795,
    "state_id": "PA"
  },
  {
    "zip": 16829,
    "lat": 41.08391,
    "lng": -77.89326,
    "state_id": "PA"
  },
  {
    "zip": 16830,
    "lat": 41.08445,
    "lng": -78.43609,
    "state_id": "PA"
  },
  {
    "zip": 16832,
    "lat": 40.84161,
    "lng": -77.46914,
    "state_id": "PA"
  },
  {
    "zip": 16833,
    "lat": 40.94064,
    "lng": -78.57434,
    "state_id": "PA"
  },
  {
    "zip": 16834,
    "lat": 41.03914,
    "lng": -78.10393,
    "state_id": "PA"
  },
  {
    "zip": 16835,
    "lat": 40.90753,
    "lng": -77.8773,
    "state_id": "PA"
  },
  {
    "zip": 16836,
    "lat": 41.14532,
    "lng": -78.2706,
    "state_id": "PA"
  },
  {
    "zip": 16837,
    "lat": 40.94576,
    "lng": -78.47494,
    "state_id": "PA"
  },
  {
    "zip": 16838,
    "lat": 40.99369,
    "lng": -78.64028,
    "state_id": "PA"
  },
  {
    "zip": 16839,
    "lat": 41.00357,
    "lng": -78.11043,
    "state_id": "PA"
  },
  {
    "zip": 16840,
    "lat": 40.92303,
    "lng": -78.20378,
    "state_id": "PA"
  },
  {
    "zip": 16841,
    "lat": 41.03834,
    "lng": -77.69914,
    "state_id": "PA"
  },
  {
    "zip": 16843,
    "lat": 41.00216,
    "lng": -78.46608,
    "state_id": "PA"
  },
  {
    "zip": 16844,
    "lat": 40.90908,
    "lng": -77.93204,
    "state_id": "PA"
  },
  {
    "zip": 16845,
    "lat": 41.1506,
    "lng": -78.03224,
    "state_id": "PA"
  },
  {
    "zip": 16847,
    "lat": 40.99921,
    "lng": -78.16469,
    "state_id": "PA"
  },
  {
    "zip": 16848,
    "lat": 41.0114,
    "lng": -77.5363,
    "state_id": "PA"
  },
  {
    "zip": 16849,
    "lat": 40.96044,
    "lng": -78.11433,
    "state_id": "PA"
  },
  {
    "zip": 16851,
    "lat": 40.82189,
    "lng": -77.78713,
    "state_id": "PA"
  },
  {
    "zip": 16852,
    "lat": 40.93704,
    "lng": -77.51867,
    "state_id": "PA"
  },
  {
    "zip": 16853,
    "lat": 40.93909,
    "lng": -77.78707,
    "state_id": "PA"
  },
  {
    "zip": 16854,
    "lat": 40.89799,
    "lng": -77.47309,
    "state_id": "PA"
  },
  {
    "zip": 16855,
    "lat": 40.99735,
    "lng": -78.37705,
    "state_id": "PA"
  },
  {
    "zip": 16858,
    "lat": 41.00053,
    "lng": -78.20035,
    "state_id": "PA"
  },
  {
    "zip": 16859,
    "lat": 41.04074,
    "lng": -78.02324,
    "state_id": "PA"
  },
  {
    "zip": 16860,
    "lat": 40.94675,
    "lng": -78.17069,
    "state_id": "PA"
  },
  {
    "zip": 16861,
    "lat": 40.86515,
    "lng": -78.52366,
    "state_id": "PA"
  },
  {
    "zip": 16863,
    "lat": 40.91038,
    "lng": -78.47498,
    "state_id": "PA"
  },
  {
    "zip": 16865,
    "lat": 40.71019,
    "lng": -77.99599,
    "state_id": "PA"
  },
  {
    "zip": 16866,
    "lat": 40.86697,
    "lng": -78.17477,
    "state_id": "PA"
  },
  {
    "zip": 16868,
    "lat": 40.73196,
    "lng": -77.88061,
    "state_id": "PA"
  },
  {
    "zip": 16870,
    "lat": 40.80007,
    "lng": -78.06028,
    "state_id": "PA"
  },
  {
    "zip": 16871,
    "lat": 41.18599,
    "lng": -78.02609,
    "state_id": "PA"
  },
  {
    "zip": 16872,
    "lat": 40.9674,
    "lng": -77.35842,
    "state_id": "PA"
  },
  {
    "zip": 16874,
    "lat": 40.99793,
    "lng": -77.95747,
    "state_id": "PA"
  },
  {
    "zip": 16875,
    "lat": 40.8473,
    "lng": -77.5718,
    "state_id": "PA"
  },
  {
    "zip": 16876,
    "lat": 40.96634,
    "lng": -78.2909,
    "state_id": "PA"
  },
  {
    "zip": 16877,
    "lat": 40.73859,
    "lng": -78.06109,
    "state_id": "PA"
  },
  {
    "zip": 16878,
    "lat": 40.94825,
    "lng": -78.32864,
    "state_id": "PA"
  },
  {
    "zip": 16879,
    "lat": 40.96977,
    "lng": -78.15064,
    "state_id": "PA"
  },
  {
    "zip": 16881,
    "lat": 41.0262,
    "lng": -78.31399,
    "state_id": "PA"
  },
  {
    "zip": 16882,
    "lat": 40.91244,
    "lng": -77.32347,
    "state_id": "PA"
  },
  {
    "zip": 16901,
    "lat": 41.73083,
    "lng": -77.32717,
    "state_id": "PA"
  },
  {
    "zip": 16911,
    "lat": 41.65672,
    "lng": -77.12533,
    "state_id": "PA"
  },
  {
    "zip": 16912,
    "lat": 41.67764,
    "lng": -77.04331,
    "state_id": "PA"
  },
  {
    "zip": 16914,
    "lat": 41.86377,
    "lng": -76.78241,
    "state_id": "PA"
  },
  {
    "zip": 16915,
    "lat": 41.78312,
    "lng": -77.96438,
    "state_id": "PA"
  },
  {
    "zip": 16917,
    "lat": 41.72843,
    "lng": -77.05971,
    "state_id": "PA"
  },
  {
    "zip": 16920,
    "lat": 41.98208,
    "lng": -77.28831,
    "state_id": "PA"
  },
  {
    "zip": 16921,
    "lat": 41.71164,
    "lng": -77.55895,
    "state_id": "PA"
  },
  {
    "zip": 16922,
    "lat": 41.66899,
    "lng": -77.69782,
    "state_id": "PA"
  },
  {
    "zip": 16923,
    "lat": 41.94018,
    "lng": -77.87242,
    "state_id": "PA"
  },
  {
    "zip": 16925,
    "lat": 41.9516,
    "lng": -76.79174,
    "state_id": "PA"
  },
  {
    "zip": 16926,
    "lat": 41.72064,
    "lng": -76.69837,
    "state_id": "PA"
  },
  {
    "zip": 16927,
    "lat": 41.95872,
    "lng": -77.65875,
    "state_id": "PA"
  },
  {
    "zip": 16928,
    "lat": 41.9534,
    "lng": -77.43454,
    "state_id": "PA"
  },
  {
    "zip": 16929,
    "lat": 41.97086,
    "lng": -77.1486,
    "state_id": "PA"
  },
  {
    "zip": 16930,
    "lat": 41.5739,
    "lng": -77.14507,
    "state_id": "PA"
  },
  {
    "zip": 16932,
    "lat": 41.77608,
    "lng": -76.94236,
    "state_id": "PA"
  },
  {
    "zip": 16933,
    "lat": 41.81906,
    "lng": -77.06663,
    "state_id": "PA"
  },
  {
    "zip": 16935,
    "lat": 41.86636,
    "lng": -77.3128,
    "state_id": "PA"
  },
  {
    "zip": 16936,
    "lat": 41.94603,
    "lng": -76.96035,
    "state_id": "PA"
  },
  {
    "zip": 16937,
    "lat": 41.96562,
    "lng": -77.71116,
    "state_id": "PA"
  },
  {
    "zip": 16938,
    "lat": 41.57618,
    "lng": -77.35357,
    "state_id": "PA"
  },
  {
    "zip": 16939,
    "lat": 41.67105,
    "lng": -77.02225,
    "state_id": "PA"
  },
  {
    "zip": 16940,
    "lat": 41.98537,
    "lng": -77.23822,
    "state_id": "PA"
  },
  {
    "zip": 16941,
    "lat": 41.98838,
    "lng": -77.75798,
    "state_id": "PA"
  },
  {
    "zip": 16942,
    "lat": 41.96248,
    "lng": -77.35216,
    "state_id": "PA"
  },
  {
    "zip": 16943,
    "lat": 41.84084,
    "lng": -77.61545,
    "state_id": "PA"
  },
  {
    "zip": 16946,
    "lat": 41.90894,
    "lng": -77.13781,
    "state_id": "PA"
  },
  {
    "zip": 16947,
    "lat": 41.76854,
    "lng": -76.79508,
    "state_id": "PA"
  },
  {
    "zip": 16948,
    "lat": 41.84897,
    "lng": -77.75802,
    "state_id": "PA"
  },
  {
    "zip": 16950,
    "lat": 41.90101,
    "lng": -77.53337,
    "state_id": "PA"
  },
  {
    "zip": 17002,
    "lat": 40.50755,
    "lng": -77.83922,
    "state_id": "PA"
  },
  {
    "zip": 17003,
    "lat": 40.36394,
    "lng": -76.5649,
    "state_id": "PA"
  },
  {
    "zip": 17004,
    "lat": 40.60064,
    "lng": -77.73048,
    "state_id": "PA"
  },
  {
    "zip": 17005,
    "lat": 40.60285,
    "lng": -76.80968,
    "state_id": "PA"
  },
  {
    "zip": 17006,
    "lat": 40.28559,
    "lng": -77.54373,
    "state_id": "PA"
  },
  {
    "zip": 17007,
    "lat": 40.13083,
    "lng": -77.1182,
    "state_id": "PA"
  },
  {
    "zip": 17009,
    "lat": 40.63605,
    "lng": -77.5658,
    "state_id": "PA"
  },
  {
    "zip": 17010,
    "lat": 40.27768,
    "lng": -76.58177,
    "state_id": "PA"
  },
  {
    "zip": 17011,
    "lat": 40.23514,
    "lng": -76.92916,
    "state_id": "PA"
  },
  {
    "zip": 17013,
    "lat": 40.24171,
    "lng": -77.19827,
    "state_id": "PA"
  },
  {
    "zip": 17015,
    "lat": 40.17719,
    "lng": -77.23116,
    "state_id": "PA"
  },
  {
    "zip": 17016,
    "lat": 40.27754,
    "lng": -76.40238,
    "state_id": "PA"
  },
  {
    "zip": 17017,
    "lat": 40.64332,
    "lng": -76.88259,
    "state_id": "PA"
  },
  {
    "zip": 17018,
    "lat": 40.40248,
    "lng": -76.8812,
    "state_id": "PA"
  },
  {
    "zip": 17019,
    "lat": 40.09011,
    "lng": -77.02253,
    "state_id": "PA"
  },
  {
    "zip": 17020,
    "lat": 40.41182,
    "lng": -77.04341,
    "state_id": "PA"
  },
  {
    "zip": 17021,
    "lat": 40.33957,
    "lng": -77.65933,
    "state_id": "PA"
  },
  {
    "zip": 17022,
    "lat": 40.16632,
    "lng": -76.60867,
    "state_id": "PA"
  },
  {
    "zip": 17023,
    "lat": 40.58425,
    "lng": -76.81627,
    "state_id": "PA"
  },
  {
    "zip": 17024,
    "lat": 40.4096,
    "lng": -77.31211,
    "state_id": "PA"
  },
  {
    "zip": 17025,
    "lat": 40.29511,
    "lng": -76.97594,
    "state_id": "PA"
  },
  {
    "zip": 17026,
    "lat": 40.46203,
    "lng": -76.42852,
    "state_id": "PA"
  },
  {
    "zip": 17027,
    "lat": 40.15617,
    "lng": -76.99573,
    "state_id": "PA"
  },
  {
    "zip": 17028,
    "lat": 40.39419,
    "lng": -76.65823,
    "state_id": "PA"
  },
  {
    "zip": 17029,
    "lat": 40.55451,
    "lng": -77.62196,
    "state_id": "PA"
  },
  {
    "zip": 17030,
    "lat": 40.60926,
    "lng": -76.72563,
    "state_id": "PA"
  },
  {
    "zip": 17032,
    "lat": 40.49316,
    "lng": -76.82065,
    "state_id": "PA"
  },
  {
    "zip": 17033,
    "lat": 40.27319,
    "lng": -76.63536,
    "state_id": "PA"
  },
  {
    "zip": 17034,
    "lat": 40.20871,
    "lng": -76.78531,
    "state_id": "PA"
  },
  {
    "zip": 17035,
    "lat": 40.41635,
    "lng": -77.57482,
    "state_id": "PA"
  },
  {
    "zip": 17036,
    "lat": 40.28545,
    "lng": -76.69875,
    "state_id": "PA"
  },
  {
    "zip": 17037,
    "lat": 40.43391,
    "lng": -77.42031,
    "state_id": "PA"
  },
  {
    "zip": 17038,
    "lat": 40.46929,
    "lng": -76.5461,
    "state_id": "PA"
  },
  {
    "zip": 17039,
    "lat": 40.29071,
    "lng": -76.241,
    "state_id": "PA"
  },
  {
    "zip": 17040,
    "lat": 40.31691,
    "lng": -77.3161,
    "state_id": "PA"
  },
  {
    "zip": 17041,
    "lat": 40.21665,
    "lng": -76.53951,
    "state_id": "PA"
  },
  {
    "zip": 17042,
    "lat": 40.29452,
    "lng": -76.42499,
    "state_id": "PA"
  },
  {
    "zip": 17043,
    "lat": 40.24684,
    "lng": -76.90009,
    "state_id": "PA"
  },
  {
    "zip": 17044,
    "lat": 40.57841,
    "lng": -77.59429,
    "state_id": "PA"
  },
  {
    "zip": 17045,
    "lat": 40.58876,
    "lng": -77.00341,
    "state_id": "PA"
  },
  {
    "zip": 17046,
    "lat": 40.38268,
    "lng": -76.43004,
    "state_id": "PA"
  },
  {
    "zip": 17047,
    "lat": 40.36378,
    "lng": -77.41819,
    "state_id": "PA"
  },
  {
    "zip": 17048,
    "lat": 40.60783,
    "lng": -76.72609,
    "state_id": "PA"
  },
  {
    "zip": 17049,
    "lat": 40.65303,
    "lng": -77.25612,
    "state_id": "PA"
  },
  {
    "zip": 17050,
    "lat": 40.24856,
    "lng": -77.02628,
    "state_id": "PA"
  },
  {
    "zip": 17051,
    "lat": 40.45876,
    "lng": -77.77506,
    "state_id": "PA"
  },
  {
    "zip": 17052,
    "lat": 40.3169,
    "lng": -77.98105,
    "state_id": "PA"
  },
  {
    "zip": 17053,
    "lat": 40.32557,
    "lng": -77.03153,
    "state_id": "PA"
  },
  {
    "zip": 17055,
    "lat": 40.17895,
    "lng": -77.00361,
    "state_id": "PA"
  },
  {
    "zip": 17056,
    "lat": 40.53757,
    "lng": -77.35426,
    "state_id": "PA"
  },
  {
    "zip": 17057,
    "lat": 40.19378,
    "lng": -76.72521,
    "state_id": "PA"
  },
  {
    "zip": 17058,
    "lat": 40.4962,
    "lng": -77.55429,
    "state_id": "PA"
  },
  {
    "zip": 17059,
    "lat": 40.59169,
    "lng": -77.38457,
    "state_id": "PA"
  },
  {
    "zip": 17060,
    "lat": 40.46787,
    "lng": -77.89671,
    "state_id": "PA"
  },
  {
    "zip": 17061,
    "lat": 40.56663,
    "lng": -76.91225,
    "state_id": "PA"
  },
  {
    "zip": 17062,
    "lat": 40.55205,
    "lng": -77.16722,
    "state_id": "PA"
  },
  {
    "zip": 17063,
    "lat": 40.76197,
    "lng": -77.47581,
    "state_id": "PA"
  },
  {
    "zip": 17064,
    "lat": 40.24237,
    "lng": -76.47607,
    "state_id": "PA"
  },
  {
    "zip": 17065,
    "lat": 40.11146,
    "lng": -77.19018,
    "state_id": "PA"
  },
  {
    "zip": 17066,
    "lat": 40.35601,
    "lng": -77.86192,
    "state_id": "PA"
  },
  {
    "zip": 17067,
    "lat": 40.38704,
    "lng": -76.31538,
    "state_id": "PA"
  },
  {
    "zip": 17068,
    "lat": 40.40981,
    "lng": -77.17702,
    "state_id": "PA"
  },
  {
    "zip": 17069,
    "lat": 40.45397,
    "lng": -76.97003,
    "state_id": "PA"
  },
  {
    "zip": 17070,
    "lat": 40.20299,
    "lng": -76.86587,
    "state_id": "PA"
  },
  {
    "zip": 17071,
    "lat": 40.29922,
    "lng": -77.60076,
    "state_id": "PA"
  },
  {
    "zip": 17072,
    "lat": 40.23372,
    "lng": -77.08174,
    "state_id": "PA"
  },
  {
    "zip": 17073,
    "lat": 40.30318,
    "lng": -76.25604,
    "state_id": "PA"
  },
  {
    "zip": 17074,
    "lat": 40.4804,
    "lng": -77.14455,
    "state_id": "PA"
  },
  {
    "zip": 17075,
    "lat": 40.39453,
    "lng": -77.83312,
    "state_id": "PA"
  },
  {
    "zip": 17076,
    "lat": 40.6162,
    "lng": -77.31165,
    "state_id": "PA"
  },
  {
    "zip": 17077,
    "lat": 40.40301,
    "lng": -76.53593,
    "state_id": "PA"
  },
  {
    "zip": 17078,
    "lat": 40.28594,
    "lng": -76.58355,
    "state_id": "PA"
  },
  {
    "zip": 17080,
    "lat": 40.64067,
    "lng": -76.80307,
    "state_id": "PA"
  },
  {
    "zip": 17081,
    "lat": 40.20101,
    "lng": -77.28333,
    "state_id": "PA"
  },
  {
    "zip": 17082,
    "lat": 40.4984,
    "lng": -77.42765,
    "state_id": "PA"
  },
  {
    "zip": 17083,
    "lat": 40.27759,
    "lng": -76.43759,
    "state_id": "PA"
  },
  {
    "zip": 17084,
    "lat": 40.68509,
    "lng": -77.62789,
    "state_id": "PA"
  },
  {
    "zip": 17086,
    "lat": 40.69061,
    "lng": -77.12442,
    "state_id": "PA"
  },
  {
    "zip": 17087,
    "lat": 40.40523,
    "lng": -76.26228,
    "state_id": "PA"
  },
  {
    "zip": 17088,
    "lat": 40.30069,
    "lng": -76.29418,
    "state_id": "PA"
  },
  {
    "zip": 17090,
    "lat": 40.32928,
    "lng": -77.18197,
    "state_id": "PA"
  },
  {
    "zip": 17093,
    "lat": 40.30772,
    "lng": -76.93128,
    "state_id": "PA"
  },
  {
    "zip": 17094,
    "lat": 40.58321,
    "lng": -77.21767,
    "state_id": "PA"
  },
  {
    "zip": 17097,
    "lat": 40.58009,
    "lng": -76.67748,
    "state_id": "PA"
  },
  {
    "zip": 17098,
    "lat": 40.58895,
    "lng": -76.63236,
    "state_id": "PA"
  },
  {
    "zip": 17099,
    "lat": 40.64519,
    "lng": -77.57574,
    "state_id": "PA"
  },
  {
    "zip": 17101,
    "lat": 40.25916,
    "lng": -76.88769,
    "state_id": "PA"
  },
  {
    "zip": 17102,
    "lat": 40.27153,
    "lng": -76.89673,
    "state_id": "PA"
  },
  {
    "zip": 17103,
    "lat": 40.27589,
    "lng": -76.86635,
    "state_id": "PA"
  },
  {
    "zip": 17104,
    "lat": 40.25481,
    "lng": -76.86264,
    "state_id": "PA"
  },
  {
    "zip": 17109,
    "lat": 40.28993,
    "lng": -76.82433,
    "state_id": "PA"
  },
  {
    "zip": 17110,
    "lat": 40.31559,
    "lng": -76.88557,
    "state_id": "PA"
  },
  {
    "zip": 17111,
    "lat": 40.26895,
    "lng": -76.78491,
    "state_id": "PA"
  },
  {
    "zip": 17112,
    "lat": 40.37311,
    "lng": -76.77649,
    "state_id": "PA"
  },
  {
    "zip": 17113,
    "lat": 40.22555,
    "lng": -76.82749,
    "state_id": "PA"
  },
  {
    "zip": 17120,
    "lat": 40.26517,
    "lng": -76.88281,
    "state_id": "PA"
  },
  {
    "zip": 17201,
    "lat": 39.9611,
    "lng": -77.65615,
    "state_id": "PA"
  },
  {
    "zip": 17202,
    "lat": 39.92137,
    "lng": -77.67753,
    "state_id": "PA"
  },
  {
    "zip": 17210,
    "lat": 40.19639,
    "lng": -77.66123,
    "state_id": "PA"
  },
  {
    "zip": 17211,
    "lat": 39.75258,
    "lng": -78.40712,
    "state_id": "PA"
  },
  {
    "zip": 17212,
    "lat": 39.8127,
    "lng": -78.06452,
    "state_id": "PA"
  },
  {
    "zip": 17213,
    "lat": 40.25362,
    "lng": -77.77059,
    "state_id": "PA"
  },
  {
    "zip": 17214,
    "lat": 39.74793,
    "lng": -77.47117,
    "state_id": "PA"
  },
  {
    "zip": 17215,
    "lat": 40.07784,
    "lng": -77.89396,
    "state_id": "PA"
  },
  {
    "zip": 17217,
    "lat": 40.23864,
    "lng": -77.72154,
    "state_id": "PA"
  },
  {
    "zip": 17219,
    "lat": 40.23127,
    "lng": -77.68563,
    "state_id": "PA"
  },
  {
    "zip": 17220,
    "lat": 40.18486,
    "lng": -77.74114,
    "state_id": "PA"
  },
  {
    "zip": 17221,
    "lat": 40.06664,
    "lng": -77.81668,
    "state_id": "PA"
  },
  {
    "zip": 17222,
    "lat": 39.89527,
    "lng": -77.49231,
    "state_id": "PA"
  },
  {
    "zip": 17223,
    "lat": 40.07801,
    "lng": -77.95317,
    "state_id": "PA"
  },
  {
    "zip": 17224,
    "lat": 39.96797,
    "lng": -77.89747,
    "state_id": "PA"
  },
  {
    "zip": 17225,
    "lat": 39.7864,
    "lng": -77.75933,
    "state_id": "PA"
  },
  {
    "zip": 17228,
    "lat": 39.98715,
    "lng": -78.09328,
    "state_id": "PA"
  },
  {
    "zip": 17229,
    "lat": 40.0783,
    "lng": -78.01128,
    "state_id": "PA"
  },
  {
    "zip": 17233,
    "lat": 39.95524,
    "lng": -78.00169,
    "state_id": "PA"
  },
  {
    "zip": 17235,
    "lat": 39.85718,
    "lng": -77.69796,
    "state_id": "PA"
  },
  {
    "zip": 17236,
    "lat": 39.79806,
    "lng": -77.94519,
    "state_id": "PA"
  },
  {
    "zip": 17237,
    "lat": 39.83858,
    "lng": -77.54183,
    "state_id": "PA"
  },
  {
    "zip": 17238,
    "lat": 39.85819,
    "lng": -78.13094,
    "state_id": "PA"
  },
  {
    "zip": 17239,
    "lat": 40.13174,
    "lng": -77.83678,
    "state_id": "PA"
  },
  {
    "zip": 17240,
    "lat": 40.15424,
    "lng": -77.58309,
    "state_id": "PA"
  },
  {
    "zip": 17241,
    "lat": 40.1778,
    "lng": -77.40336,
    "state_id": "PA"
  },
  {
    "zip": 17243,
    "lat": 40.27763,
    "lng": -77.81968,
    "state_id": "PA"
  },
  {
    "zip": 17244,
    "lat": 40.08489,
    "lng": -77.66265,
    "state_id": "PA"
  },
  {
    "zip": 17246,
    "lat": 40.05441,
    "lng": -77.66083,
    "state_id": "PA"
  },
  {
    "zip": 17247,
    "lat": 39.79867,
    "lng": -77.57942,
    "state_id": "PA"
  },
  {
    "zip": 17249,
    "lat": 40.24143,
    "lng": -77.89949,
    "state_id": "PA"
  },
  {
    "zip": 17250,
    "lat": 39.73702,
    "lng": -77.52425,
    "state_id": "PA"
  },
  {
    "zip": 17251,
    "lat": 40.13098,
    "lng": -77.68093,
    "state_id": "PA"
  },
  {
    "zip": 17252,
    "lat": 39.91342,
    "lng": -77.81846,
    "state_id": "PA"
  },
  {
    "zip": 17253,
    "lat": 40.21316,
    "lng": -78.00685,
    "state_id": "PA"
  },
  {
    "zip": 17254,
    "lat": 39.9703,
    "lng": -77.59034,
    "state_id": "PA"
  },
  {
    "zip": 17255,
    "lat": 40.16129,
    "lng": -77.86247,
    "state_id": "PA"
  },
  {
    "zip": 17256,
    "lat": 39.78325,
    "lng": -77.67758,
    "state_id": "PA"
  },
  {
    "zip": 17257,
    "lat": 40.04912,
    "lng": -77.49141,
    "state_id": "PA"
  },
  {
    "zip": 17260,
    "lat": 40.29452,
    "lng": -77.89587,
    "state_id": "PA"
  },
  {
    "zip": 17261,
    "lat": 39.86096,
    "lng": -77.50816,
    "state_id": "PA"
  },
  {
    "zip": 17262,
    "lat": 40.14603,
    "lng": -77.73624,
    "state_id": "PA"
  },
  {
    "zip": 17263,
    "lat": 39.72732,
    "lng": -77.71763,
    "state_id": "PA"
  },
  {
    "zip": 17264,
    "lat": 40.17993,
    "lng": -77.99369,
    "state_id": "PA"
  },
  {
    "zip": 17265,
    "lat": 40.02817,
    "lng": -77.78664,
    "state_id": "PA"
  },
  {
    "zip": 17266,
    "lat": 40.08751,
    "lng": -77.4119,
    "state_id": "PA"
  },
  {
    "zip": 17267,
    "lat": 39.80255,
    "lng": -78.24066,
    "state_id": "PA"
  },
  {
    "zip": 17268,
    "lat": 39.77564,
    "lng": -77.57384,
    "state_id": "PA"
  },
  {
    "zip": 17271,
    "lat": 40.0977,
    "lng": -77.80314,
    "state_id": "PA"
  },
  {
    "zip": 17272,
    "lat": 39.76946,
    "lng": -77.62188,
    "state_id": "PA"
  },
  {
    "zip": 17301,
    "lat": 39.89062,
    "lng": -76.9803,
    "state_id": "PA"
  },
  {
    "zip": 17302,
    "lat": 39.81564,
    "lng": -76.40924,
    "state_id": "PA"
  },
  {
    "zip": 17304,
    "lat": 39.97676,
    "lng": -77.23064,
    "state_id": "PA"
  },
  {
    "zip": 17306,
    "lat": 39.98089,
    "lng": -77.24963,
    "state_id": "PA"
  },
  {
    "zip": 17307,
    "lat": 39.95083,
    "lng": -77.32222,
    "state_id": "PA"
  },
  {
    "zip": 17309,
    "lat": 39.87129,
    "lng": -76.45019,
    "state_id": "PA"
  },
  {
    "zip": 17311,
    "lat": 39.81637,
    "lng": -76.84245,
    "state_id": "PA"
  },
  {
    "zip": 17313,
    "lat": 39.88617,
    "lng": -76.65658,
    "state_id": "PA"
  },
  {
    "zip": 17314,
    "lat": 39.75474,
    "lng": -76.32953,
    "state_id": "PA"
  },
  {
    "zip": 17315,
    "lat": 40.02324,
    "lng": -76.86497,
    "state_id": "PA"
  },
  {
    "zip": 17316,
    "lat": 39.9669,
    "lng": -77.00425,
    "state_id": "PA"
  },
  {
    "zip": 17317,
    "lat": 39.97079,
    "lng": -76.52294,
    "state_id": "PA"
  },
  {
    "zip": 17318,
    "lat": 40.02213,
    "lng": -76.72299,
    "state_id": "PA"
  },
  {
    "zip": 17319,
    "lat": 40.16042,
    "lng": -76.7941,
    "state_id": "PA"
  },
  {
    "zip": 17320,
    "lat": 39.76858,
    "lng": -77.38764,
    "state_id": "PA"
  },
  {
    "zip": 17321,
    "lat": 39.75475,
    "lng": -76.44716,
    "state_id": "PA"
  },
  {
    "zip": 17322,
    "lat": 39.85592,
    "lng": -76.53272,
    "state_id": "PA"
  },
  {
    "zip": 17324,
    "lat": 40.03482,
    "lng": -77.22834,
    "state_id": "PA"
  },
  {
    "zip": 17325,
    "lat": 39.82788,
    "lng": -77.22677,
    "state_id": "PA"
  },
  {
    "zip": 17327,
    "lat": 39.77959,
    "lng": -76.75311,
    "state_id": "PA"
  },
  {
    "zip": 17329,
    "lat": 39.76184,
    "lng": -76.85112,
    "state_id": "PA"
  },
  {
    "zip": 17331,
    "lat": 39.79014,
    "lng": -76.97832,
    "state_id": "PA"
  },
  {
    "zip": 17339,
    "lat": 40.13511,
    "lng": -76.88376,
    "state_id": "PA"
  },
  {
    "zip": 17340,
    "lat": 39.75558,
    "lng": -77.11257,
    "state_id": "PA"
  },
  {
    "zip": 17343,
    "lat": 39.87026,
    "lng": -77.33375,
    "state_id": "PA"
  },
  {
    "zip": 17344,
    "lat": 39.8057,
    "lng": -77.01915,
    "state_id": "PA"
  },
  {
    "zip": 17345,
    "lat": 40.07752,
    "lng": -76.7354,
    "state_id": "PA"
  },
  {
    "zip": 17347,
    "lat": 40.05525,
    "lng": -76.68892,
    "state_id": "PA"
  },
  {
    "zip": 17349,
    "lat": 39.75429,
    "lng": -76.68019,
    "state_id": "PA"
  },
  {
    "zip": 17350,
    "lat": 39.88904,
    "lng": -77.07993,
    "state_id": "PA"
  },
  {
    "zip": 17352,
    "lat": 39.76123,
    "lng": -76.49988,
    "state_id": "PA"
  },
  {
    "zip": 17353,
    "lat": 39.88898,
    "lng": -77.38512,
    "state_id": "PA"
  },
  {
    "zip": 17355,
    "lat": 39.75982,
    "lng": -76.69605,
    "state_id": "PA"
  },
  {
    "zip": 17356,
    "lat": 39.8966,
    "lng": -76.58225,
    "state_id": "PA"
  },
  {
    "zip": 17360,
    "lat": 39.85197,
    "lng": -76.7548,
    "state_id": "PA"
  },
  {
    "zip": 17361,
    "lat": 39.76552,
    "lng": -76.67702,
    "state_id": "PA"
  },
  {
    "zip": 17362,
    "lat": 39.85196,
    "lng": -76.86854,
    "state_id": "PA"
  },
  {
    "zip": 17363,
    "lat": 39.76864,
    "lng": -76.5864,
    "state_id": "PA"
  },
  {
    "zip": 17364,
    "lat": 39.92939,
    "lng": -76.89936,
    "state_id": "PA"
  },
  {
    "zip": 17365,
    "lat": 40.05559,
    "lng": -76.9379,
    "state_id": "PA"
  },
  {
    "zip": 17366,
    "lat": 39.93234,
    "lng": -76.55891,
    "state_id": "PA"
  },
  {
    "zip": 17368,
    "lat": 39.9839,
    "lng": -76.51873,
    "state_id": "PA"
  },
  {
    "zip": 17370,
    "lat": 40.11847,
    "lng": -76.77619,
    "state_id": "PA"
  },
  {
    "zip": 17371,
    "lat": 39.9026,
    "lng": -76.78798,
    "state_id": "PA"
  },
  {
    "zip": 17372,
    "lat": 40.00129,
    "lng": -77.10586,
    "state_id": "PA"
  },
  {
    "zip": 17401,
    "lat": 39.95905,
    "lng": -76.73364,
    "state_id": "PA"
  },
  {
    "zip": 17402,
    "lat": 39.95893,
    "lng": -76.65922,
    "state_id": "PA"
  },
  {
    "zip": 17403,
    "lat": 39.92298,
    "lng": -76.71338,
    "state_id": "PA"
  },
  {
    "zip": 17404,
    "lat": 40.00233,
    "lng": -76.77122,
    "state_id": "PA"
  },
  {
    "zip": 17406,
    "lat": 40.01093,
    "lng": -76.63837,
    "state_id": "PA"
  },
  {
    "zip": 17407,
    "lat": 39.88321,
    "lng": -76.71192,
    "state_id": "PA"
  },
  {
    "zip": 17408,
    "lat": 39.9337,
    "lng": -76.80297,
    "state_id": "PA"
  },
  {
    "zip": 17501,
    "lat": 40.15723,
    "lng": -76.20403,
    "state_id": "PA"
  },
  {
    "zip": 17502,
    "lat": 40.10002,
    "lng": -76.66633,
    "state_id": "PA"
  },
  {
    "zip": 17505,
    "lat": 40.06035,
    "lng": -76.19127,
    "state_id": "PA"
  },
  {
    "zip": 17507,
    "lat": 40.19787,
    "lng": -76.01631,
    "state_id": "PA"
  },
  {
    "zip": 17508,
    "lat": 40.12384,
    "lng": -76.21761,
    "state_id": "PA"
  },
  {
    "zip": 17509,
    "lat": 39.90967,
    "lng": -76.03006,
    "state_id": "PA"
  },
  {
    "zip": 17512,
    "lat": 40.03939,
    "lng": -76.48599,
    "state_id": "PA"
  },
  {
    "zip": 17516,
    "lat": 39.94011,
    "lng": -76.37551,
    "state_id": "PA"
  },
  {
    "zip": 17517,
    "lat": 40.24451,
    "lng": -76.13248,
    "state_id": "PA"
  },
  {
    "zip": 17518,
    "lat": 39.8055,
    "lng": -76.25671,
    "state_id": "PA"
  },
  {
    "zip": 17519,
    "lat": 40.14156,
    "lng": -76.02062,
    "state_id": "PA"
  },
  {
    "zip": 17520,
    "lat": 40.09794,
    "lng": -76.349,
    "state_id": "PA"
  },
  {
    "zip": 17522,
    "lat": 40.1722,
    "lng": -76.17074,
    "state_id": "PA"
  },
  {
    "zip": 17527,
    "lat": 40.01353,
    "lng": -75.99485,
    "state_id": "PA"
  },
  {
    "zip": 17529,
    "lat": 40.03979,
    "lng": -76.10019,
    "state_id": "PA"
  },
  {
    "zip": 17532,
    "lat": 39.85354,
    "lng": -76.29623,
    "state_id": "PA"
  },
  {
    "zip": 17535,
    "lat": 40.00647,
    "lng": -76.04056,
    "state_id": "PA"
  },
  {
    "zip": 17536,
    "lat": 39.84442,
    "lng": -76.07301,
    "state_id": "PA"
  },
  {
    "zip": 17538,
    "lat": 40.08919,
    "lng": -76.41567,
    "state_id": "PA"
  },
  {
    "zip": 17540,
    "lat": 40.09654,
    "lng": -76.18709,
    "state_id": "PA"
  },
  {
    "zip": 17543,
    "lat": 40.18459,
    "lng": -76.30153,
    "state_id": "PA"
  },
  {
    "zip": 17545,
    "lat": 40.17339,
    "lng": -76.43144,
    "state_id": "PA"
  },
  {
    "zip": 17547,
    "lat": 40.07047,
    "lng": -76.58553,
    "state_id": "PA"
  },
  {
    "zip": 17550,
    "lat": 40.07597,
    "lng": -76.58321,
    "state_id": "PA"
  },
  {
    "zip": 17551,
    "lat": 39.97851,
    "lng": -76.37368,
    "state_id": "PA"
  },
  {
    "zip": 17552,
    "lat": 40.10825,
    "lng": -76.51038,
    "state_id": "PA"
  },
  {
    "zip": 17554,
    "lat": 40.03958,
    "lng": -76.42604,
    "state_id": "PA"
  },
  {
    "zip": 17555,
    "lat": 40.12135,
    "lng": -75.96421,
    "state_id": "PA"
  },
  {
    "zip": 17557,
    "lat": 40.10116,
    "lng": -76.07287,
    "state_id": "PA"
  },
  {
    "zip": 17560,
    "lat": 39.91085,
    "lng": -76.22738,
    "state_id": "PA"
  },
  {
    "zip": 17562,
    "lat": 39.97598,
    "lng": -76.09117,
    "state_id": "PA"
  },
  {
    "zip": 17563,
    "lat": 39.76423,
    "lng": -76.18659,
    "state_id": "PA"
  },
  {
    "zip": 17565,
    "lat": 39.89864,
    "lng": -76.33183,
    "state_id": "PA"
  },
  {
    "zip": 17566,
    "lat": 39.86968,
    "lng": -76.14818,
    "state_id": "PA"
  },
  {
    "zip": 17569,
    "lat": 40.2689,
    "lng": -76.09563,
    "state_id": "PA"
  },
  {
    "zip": 17570,
    "lat": 40.12975,
    "lng": -76.56846,
    "state_id": "PA"
  },
  {
    "zip": 17572,
    "lat": 40.0086,
    "lng": -76.15302,
    "state_id": "PA"
  },
  {
    "zip": 17576,
    "lat": 40.03754,
    "lng": -76.19688,
    "state_id": "PA"
  },
  {
    "zip": 17578,
    "lat": 40.22177,
    "lng": -76.16309,
    "state_id": "PA"
  },
  {
    "zip": 17579,
    "lat": 39.95754,
    "lng": -76.17992,
    "state_id": "PA"
  },
  {
    "zip": 17581,
    "lat": 40.15852,
    "lng": -76.04965,
    "state_id": "PA"
  },
  {
    "zip": 17582,
    "lat": 39.97442,
    "lng": -76.44656,
    "state_id": "PA"
  },
  {
    "zip": 17584,
    "lat": 39.95884,
    "lng": -76.26504,
    "state_id": "PA"
  },
  {
    "zip": 17601,
    "lat": 40.07357,
    "lng": -76.31453,
    "state_id": "PA"
  },
  {
    "zip": 17602,
    "lat": 40.01501,
    "lng": -76.24597,
    "state_id": "PA"
  },
  {
    "zip": 17603,
    "lat": 40.01301,
    "lng": -76.35232,
    "state_id": "PA"
  },
  {
    "zip": 17606,
    "lat": 40.11172,
    "lng": -76.30402,
    "state_id": "PA"
  },
  {
    "zip": 17701,
    "lat": 41.33749,
    "lng": -76.90232,
    "state_id": "PA"
  },
  {
    "zip": 17702,
    "lat": 41.18342,
    "lng": -77.07838,
    "state_id": "PA"
  },
  {
    "zip": 17721,
    "lat": 41.18361,
    "lng": -77.31844,
    "state_id": "PA"
  },
  {
    "zip": 17723,
    "lat": 41.432,
    "lng": -77.45613,
    "state_id": "PA"
  },
  {
    "zip": 17724,
    "lat": 41.64177,
    "lng": -76.80387,
    "state_id": "PA"
  },
  {
    "zip": 17727,
    "lat": 41.52994,
    "lng": -77.49167,
    "state_id": "PA"
  },
  {
    "zip": 17728,
    "lat": 41.32762,
    "lng": -77.08533,
    "state_id": "PA"
  },
  {
    "zip": 17729,
    "lat": 41.49557,
    "lng": -77.74755,
    "state_id": "PA"
  },
  {
    "zip": 17730,
    "lat": 41.11044,
    "lng": -76.8785,
    "state_id": "PA"
  },
  {
    "zip": 17731,
    "lat": 41.42874,
    "lng": -76.56675,
    "state_id": "PA"
  },
  {
    "zip": 17737,
    "lat": 41.29888,
    "lng": -76.68821,
    "state_id": "PA"
  },
  {
    "zip": 17739,
    "lat": 41.39347,
    "lng": -77.40858,
    "state_id": "PA"
  },
  {
    "zip": 17740,
    "lat": 41.24706,
    "lng": -77.27062,
    "state_id": "PA"
  },
  {
    "zip": 17742,
    "lat": 41.23589,
    "lng": -76.60501,
    "state_id": "PA"
  },
  {
    "zip": 17744,
    "lat": 41.24453,
    "lng": -77.15989,
    "state_id": "PA"
  },
  {
    "zip": 17745,
    "lat": 41.2708,
    "lng": -77.47638,
    "state_id": "PA"
  },
  {
    "zip": 17747,
    "lat": 41.03855,
    "lng": -77.32321,
    "state_id": "PA"
  },
  {
    "zip": 17748,
    "lat": 41.15045,
    "lng": -77.35306,
    "state_id": "PA"
  },
  {
    "zip": 17749,
    "lat": 41.07222,
    "lng": -76.81891,
    "state_id": "PA"
  },
  {
    "zip": 17750,
    "lat": 41.05521,
    "lng": -77.47728,
    "state_id": "PA"
  },
  {
    "zip": 17751,
    "lat": 41.1368,
    "lng": -77.53826,
    "state_id": "PA"
  },
  {
    "zip": 17752,
    "lat": 41.17894,
    "lng": -76.92958,
    "state_id": "PA"
  },
  {
    "zip": 17754,
    "lat": 41.31115,
    "lng": -76.885,
    "state_id": "PA"
  },
  {
    "zip": 17756,
    "lat": 41.20791,
    "lng": -76.73741,
    "state_id": "PA"
  },
  {
    "zip": 17758,
    "lat": 41.36478,
    "lng": -76.52849,
    "state_id": "PA"
  },
  {
    "zip": 17760,
    "lat": 41.40825,
    "lng": -77.66414,
    "state_id": "PA"
  },
  {
    "zip": 17762,
    "lat": 41.28314,
    "lng": -76.70707,
    "state_id": "PA"
  },
  {
    "zip": 17763,
    "lat": 41.51534,
    "lng": -76.97576,
    "state_id": "PA"
  },
  {
    "zip": 17764,
    "lat": 41.33441,
    "lng": -77.81989,
    "state_id": "PA"
  },
  {
    "zip": 17765,
    "lat": 41.57369,
    "lng": -76.9709,
    "state_id": "PA"
  },
  {
    "zip": 17768,
    "lat": 41.54763,
    "lng": -76.74961,
    "state_id": "PA"
  },
  {
    "zip": 17771,
    "lat": 41.43171,
    "lng": -77.03932,
    "state_id": "PA"
  },
  {
    "zip": 17772,
    "lat": 41.11836,
    "lng": -76.71678,
    "state_id": "PA"
  },
  {
    "zip": 17774,
    "lat": 41.27923,
    "lng": -76.52915,
    "state_id": "PA"
  },
  {
    "zip": 17776,
    "lat": 41.40567,
    "lng": -77.31273,
    "state_id": "PA"
  },
  {
    "zip": 17777,
    "lat": 41.1031,
    "lng": -76.8255,
    "state_id": "PA"
  },
  {
    "zip": 17778,
    "lat": 41.28639,
    "lng": -77.96688,
    "state_id": "PA"
  },
  {
    "zip": 17779,
    "lat": 41.20455,
    "lng": -77.38003,
    "state_id": "PA"
  },
  {
    "zip": 17801,
    "lat": 40.83453,
    "lng": -76.7574,
    "state_id": "PA"
  },
  {
    "zip": 17810,
    "lat": 41.11204,
    "lng": -77.0315,
    "state_id": "PA"
  },
  {
    "zip": 17812,
    "lat": 40.74069,
    "lng": -77.22815,
    "state_id": "PA"
  },
  {
    "zip": 17813,
    "lat": 40.7829,
    "lng": -77.17549,
    "state_id": "PA"
  },
  {
    "zip": 17814,
    "lat": 41.27606,
    "lng": -76.36933,
    "state_id": "PA"
  },
  {
    "zip": 17815,
    "lat": 41.01702,
    "lng": -76.42381,
    "state_id": "PA"
  },
  {
    "zip": 17820,
    "lat": 40.89893,
    "lng": -76.40947,
    "state_id": "PA"
  },
  {
    "zip": 17821,
    "lat": 40.98871,
    "lng": -76.63927,
    "state_id": "PA"
  },
  {
    "zip": 17822,
    "lat": 40.96811,
    "lng": -76.60515,
    "state_id": "PA"
  },
  {
    "zip": 17823,
    "lat": 40.72172,
    "lng": -76.72414,
    "state_id": "PA"
  },
  {
    "zip": 17824,
    "lat": 40.85381,
    "lng": -76.50035,
    "state_id": "PA"
  },
  {
    "zip": 17827,
    "lat": 40.75453,
    "lng": -76.962,
    "state_id": "PA"
  },
  {
    "zip": 17829,
    "lat": 40.90055,
    "lng": -77.15582,
    "state_id": "PA"
  },
  {
    "zip": 17830,
    "lat": 40.68817,
    "lng": -76.79457,
    "state_id": "PA"
  },
  {
    "zip": 17832,
    "lat": 40.80594,
    "lng": -76.45906,
    "state_id": "PA"
  },
  {
    "zip": 17834,
    "lat": 40.7793,
    "lng": -76.4682,
    "state_id": "PA"
  },
  {
    "zip": 17835,
    "lat": 40.88165,
    "lng": -77.20329,
    "state_id": "PA"
  },
  {
    "zip": 17836,
    "lat": 40.71336,
    "lng": -76.6016,
    "state_id": "PA"
  },
  {
    "zip": 17837,
    "lat": 40.9811,
    "lng": -76.94856,
    "state_id": "PA"
  },
  {
    "zip": 17840,
    "lat": 40.77208,
    "lng": -76.43312,
    "state_id": "PA"
  },
  {
    "zip": 17841,
    "lat": 40.72492,
    "lng": -77.35292,
    "state_id": "PA"
  },
  {
    "zip": 17842,
    "lat": 40.80403,
    "lng": -77.04166,
    "state_id": "PA"
  },
  {
    "zip": 17844,
    "lat": 40.96546,
    "lng": -77.0863,
    "state_id": "PA"
  },
  {
    "zip": 17845,
    "lat": 40.8853,
    "lng": -77.21116,
    "state_id": "PA"
  },
  {
    "zip": 17846,
    "lat": 41.14531,
    "lng": -76.51724,
    "state_id": "PA"
  },
  {
    "zip": 17847,
    "lat": 41.0057,
    "lng": -76.80591,
    "state_id": "PA"
  },
  {
    "zip": 17850,
    "lat": 40.9658,
    "lng": -76.8575,
    "state_id": "PA"
  },
  {
    "zip": 17851,
    "lat": 40.80428,
    "lng": -76.43246,
    "state_id": "PA"
  },
  {
    "zip": 17853,
    "lat": 40.69423,
    "lng": -77.00953,
    "state_id": "PA"
  },
  {
    "zip": 17855,
    "lat": 40.88731,
    "lng": -76.97223,
    "state_id": "PA"
  },
  {
    "zip": 17856,
    "lat": 41.06099,
    "lng": -76.94567,
    "state_id": "PA"
  },
  {
    "zip": 17857,
    "lat": 40.92793,
    "lng": -76.76739,
    "state_id": "PA"
  },
  {
    "zip": 17859,
    "lat": 41.11781,
    "lng": -76.39304,
    "state_id": "PA"
  },
  {
    "zip": 17860,
    "lat": 40.82666,
    "lng": -76.63614,
    "state_id": "PA"
  },
  {
    "zip": 17861,
    "lat": 40.77231,
    "lng": -77.08325,
    "state_id": "PA"
  },
  {
    "zip": 17862,
    "lat": 40.86052,
    "lng": -77.05692,
    "state_id": "PA"
  },
  {
    "zip": 17864,
    "lat": 40.70372,
    "lng": -76.90494,
    "state_id": "PA"
  },
  {
    "zip": 17865,
    "lat": 40.99015,
    "lng": -76.78678,
    "state_id": "PA"
  },
  {
    "zip": 17866,
    "lat": 40.78848,
    "lng": -76.54899,
    "state_id": "PA"
  },
  {
    "zip": 17867,
    "lat": 40.71407,
    "lng": -76.68838,
    "state_id": "PA"
  },
  {
    "zip": 17868,
    "lat": 40.95787,
    "lng": -76.634,
    "state_id": "PA"
  },
  {
    "zip": 17870,
    "lat": 40.81313,
    "lng": -76.88654,
    "state_id": "PA"
  },
  {
    "zip": 17872,
    "lat": 40.76654,
    "lng": -76.61207,
    "state_id": "PA"
  },
  {
    "zip": 17876,
    "lat": 40.85466,
    "lng": -76.82307,
    "state_id": "PA"
  },
  {
    "zip": 17878,
    "lat": 41.17213,
    "lng": -76.32236,
    "state_id": "PA"
  },
  {
    "zip": 17880,
    "lat": 40.89224,
    "lng": -77.12174,
    "state_id": "PA"
  },
  {
    "zip": 17881,
    "lat": 40.78285,
    "lng": -76.67013,
    "state_id": "PA"
  },
  {
    "zip": 17884,
    "lat": 41.0556,
    "lng": -76.66865,
    "state_id": "PA"
  },
  {
    "zip": 17885,
    "lat": 40.85601,
    "lng": -77.31861,
    "state_id": "PA"
  },
  {
    "zip": 17886,
    "lat": 41.01667,
    "lng": -76.87519,
    "state_id": "PA"
  },
  {
    "zip": 17887,
    "lat": 41.11521,
    "lng": -76.91786,
    "state_id": "PA"
  },
  {
    "zip": 17888,
    "lat": 40.81034,
    "lng": -76.3771,
    "state_id": "PA"
  },
  {
    "zip": 17889,
    "lat": 40.88262,
    "lng": -76.91803,
    "state_id": "PA"
  },
  {
    "zip": 17901,
    "lat": 40.69364,
    "lng": -76.25521,
    "state_id": "PA"
  },
  {
    "zip": 17920,
    "lat": 40.81614,
    "lng": -76.32812,
    "state_id": "PA"
  },
  {
    "zip": 17921,
    "lat": 40.7503,
    "lng": -76.36289,
    "state_id": "PA"
  },
  {
    "zip": 17922,
    "lat": 40.59121,
    "lng": -76.11425,
    "state_id": "PA"
  },
  {
    "zip": 17923,
    "lat": 40.64109,
    "lng": -76.31849,
    "state_id": "PA"
  },
  {
    "zip": 17925,
    "lat": 40.75608,
    "lng": -76.0702,
    "state_id": "PA"
  },
  {
    "zip": 17929,
    "lat": 40.63065,
    "lng": -76.1942,
    "state_id": "PA"
  },
  {
    "zip": 17930,
    "lat": 40.70867,
    "lng": -76.1153,
    "state_id": "PA"
  },
  {
    "zip": 17931,
    "lat": 40.78374,
    "lng": -76.21507,
    "state_id": "PA"
  },
  {
    "zip": 17933,
    "lat": 40.6043,
    "lng": -76.24311,
    "state_id": "PA"
  },
  {
    "zip": 17934,
    "lat": 40.79771,
    "lng": -76.21661,
    "state_id": "PA"
  },
  {
    "zip": 17935,
    "lat": 40.79602,
    "lng": -76.2832,
    "state_id": "PA"
  },
  {
    "zip": 17936,
    "lat": 40.75013,
    "lng": -76.33989,
    "state_id": "PA"
  },
  {
    "zip": 17938,
    "lat": 40.65139,
    "lng": -76.51595,
    "state_id": "PA"
  },
  {
    "zip": 17941,
    "lat": 40.68402,
    "lng": -76.61416,
    "state_id": "PA"
  },
  {
    "zip": 17943,
    "lat": 40.75658,
    "lng": -76.38796,
    "state_id": "PA"
  },
  {
    "zip": 17944,
    "lat": 40.67245,
    "lng": -76.27917,
    "state_id": "PA"
  },
  {
    "zip": 17945,
    "lat": 40.78458,
    "lng": -76.37512,
    "state_id": "PA"
  },
  {
    "zip": 17946,
    "lat": 40.81204,
    "lng": -76.24909,
    "state_id": "PA"
  },
  {
    "zip": 17948,
    "lat": 40.84075,
    "lng": -76.11385,
    "state_id": "PA"
  },
  {
    "zip": 17949,
    "lat": 40.79397,
    "lng": -76.24226,
    "state_id": "PA"
  },
  {
    "zip": 17951,
    "lat": 40.67845,
    "lng": -76.24483,
    "state_id": "PA"
  },
  {
    "zip": 17952,
    "lat": 40.75544,
    "lng": -76.05854,
    "state_id": "PA"
  },
  {
    "zip": 17953,
    "lat": 40.75185,
    "lng": -76.12392,
    "state_id": "PA"
  },
  {
    "zip": 17954,
    "lat": 40.69042,
    "lng": -76.25973,
    "state_id": "PA"
  },
  {
    "zip": 17957,
    "lat": 40.5918,
    "lng": -76.51829,
    "state_id": "PA"
  },
  {
    "zip": 17959,
    "lat": 40.73328,
    "lng": -76.14463,
    "state_id": "PA"
  },
  {
    "zip": 17960,
    "lat": 40.69973,
    "lng": -75.94584,
    "state_id": "PA"
  },
  {
    "zip": 17961,
    "lat": 40.64791,
    "lng": -76.06158,
    "state_id": "PA"
  },
  {
    "zip": 17963,
    "lat": 40.56124,
    "lng": -76.38315,
    "state_id": "PA"
  },
  {
    "zip": 17964,
    "lat": 40.71419,
    "lng": -76.49698,
    "state_id": "PA"
  },
  {
    "zip": 17965,
    "lat": 40.69859,
    "lng": -76.16533,
    "state_id": "PA"
  },
  {
    "zip": 17967,
    "lat": 40.85556,
    "lng": -76.22076,
    "state_id": "PA"
  },
  {
    "zip": 17968,
    "lat": 40.63868,
    "lng": -76.61229,
    "state_id": "PA"
  },
  {
    "zip": 17970,
    "lat": 40.72018,
    "lng": -76.19185,
    "state_id": "PA"
  },
  {
    "zip": 17972,
    "lat": 40.58962,
    "lng": -76.20538,
    "state_id": "PA"
  },
  {
    "zip": 17974,
    "lat": 40.69572,
    "lng": -76.23628,
    "state_id": "PA"
  },
  {
    "zip": 17976,
    "lat": 40.82081,
    "lng": -76.20974,
    "state_id": "PA"
  },
  {
    "zip": 17978,
    "lat": 40.62546,
    "lng": -76.61487,
    "state_id": "PA"
  },
  {
    "zip": 17979,
    "lat": 40.56151,
    "lng": -76.19974,
    "state_id": "PA"
  },
  {
    "zip": 17980,
    "lat": 40.5406,
    "lng": -76.60773,
    "state_id": "PA"
  },
  {
    "zip": 17981,
    "lat": 40.63435,
    "lng": -76.39047,
    "state_id": "PA"
  },
  {
    "zip": 17982,
    "lat": 40.78586,
    "lng": -76.02126,
    "state_id": "PA"
  },
  {
    "zip": 17983,
    "lat": 40.6426,
    "lng": -76.54716,
    "state_id": "PA"
  },
  {
    "zip": 17985,
    "lat": 40.91353,
    "lng": -76.21827,
    "state_id": "PA"
  },
  {
    "zip": 18011,
    "lat": 40.47124,
    "lng": -75.64643,
    "state_id": "PA"
  },
  {
    "zip": 18013,
    "lat": 40.85301,
    "lng": -75.17174,
    "state_id": "PA"
  },
  {
    "zip": 18014,
    "lat": 40.76509,
    "lng": -75.40936,
    "state_id": "PA"
  },
  {
    "zip": 18015,
    "lat": 40.59008,
    "lng": -75.37082,
    "state_id": "PA"
  },
  {
    "zip": 18016,
    "lat": 40.63234,
    "lng": -75.3931,
    "state_id": "PA"
  },
  {
    "zip": 18017,
    "lat": 40.65971,
    "lng": -75.38684,
    "state_id": "PA"
  },
  {
    "zip": 18018,
    "lat": 40.62772,
    "lng": -75.39528,
    "state_id": "PA"
  },
  {
    "zip": 18020,
    "lat": 40.67227,
    "lng": -75.32689,
    "state_id": "PA"
  },
  {
    "zip": 18030,
    "lat": 40.80223,
    "lng": -75.66404,
    "state_id": "PA"
  },
  {
    "zip": 18031,
    "lat": 40.55399,
    "lng": -75.65287,
    "state_id": "PA"
  },
  {
    "zip": 18032,
    "lat": 40.65696,
    "lng": -75.4678,
    "state_id": "PA"
  },
  {
    "zip": 18034,
    "lat": 40.54349,
    "lng": -75.41632,
    "state_id": "PA"
  },
  {
    "zip": 18035,
    "lat": 40.74673,
    "lng": -75.53873,
    "state_id": "PA"
  },
  {
    "zip": 18036,
    "lat": 40.50901,
    "lng": -75.38593,
    "state_id": "PA"
  },
  {
    "zip": 18037,
    "lat": 40.68217,
    "lng": -75.545,
    "state_id": "PA"
  },
  {
    "zip": 18038,
    "lat": 40.79594,
    "lng": -75.48226,
    "state_id": "PA"
  },
  {
    "zip": 18040,
    "lat": 40.74492,
    "lng": -75.22169,
    "state_id": "PA"
  },
  {
    "zip": 18041,
    "lat": 40.41948,
    "lng": -75.51485,
    "state_id": "PA"
  },
  {
    "zip": 18042,
    "lat": 40.65153,
    "lng": -75.22403,
    "state_id": "PA"
  },
  {
    "zip": 18045,
    "lat": 40.69399,
    "lng": -75.27423,
    "state_id": "PA"
  },
  {
    "zip": 18046,
    "lat": 40.54805,
    "lng": -75.5603,
    "state_id": "PA"
  },
  {
    "zip": 18049,
    "lat": 40.51741,
    "lng": -75.5008,
    "state_id": "PA"
  },
  {
    "zip": 18051,
    "lat": 40.59337,
    "lng": -75.66623,
    "state_id": "PA"
  },
  {
    "zip": 18052,
    "lat": 40.65684,
    "lng": -75.50458,
    "state_id": "PA"
  },
  {
    "zip": 18053,
    "lat": 40.71797,
    "lng": -75.70698,
    "state_id": "PA"
  },
  {
    "zip": 18054,
    "lat": 40.35423,
    "lng": -75.43661,
    "state_id": "PA"
  },
  {
    "zip": 18055,
    "lat": 40.5892,
    "lng": -75.29957,
    "state_id": "PA"
  },
  {
    "zip": 18056,
    "lat": 40.4491,
    "lng": -75.54912,
    "state_id": "PA"
  },
  {
    "zip": 18058,
    "lat": 40.89122,
    "lng": -75.49311,
    "state_id": "PA"
  },
  {
    "zip": 18059,
    "lat": 40.72339,
    "lng": -75.53629,
    "state_id": "PA"
  },
  {
    "zip": 18062,
    "lat": 40.50251,
    "lng": -75.58301,
    "state_id": "PA"
  },
  {
    "zip": 18063,
    "lat": 40.7824,
    "lng": -75.17201,
    "state_id": "PA"
  },
  {
    "zip": 18064,
    "lat": 40.75742,
    "lng": -75.31792,
    "state_id": "PA"
  },
  {
    "zip": 18066,
    "lat": 40.66491,
    "lng": -75.74498,
    "state_id": "PA"
  },
  {
    "zip": 18067,
    "lat": 40.71692,
    "lng": -75.47803,
    "state_id": "PA"
  },
  {
    "zip": 18068,
    "lat": 40.48482,
    "lng": -75.52003,
    "state_id": "PA"
  },
  {
    "zip": 18069,
    "lat": 40.62593,
    "lng": -75.61736,
    "state_id": "PA"
  },
  {
    "zip": 18070,
    "lat": 40.43217,
    "lng": -75.53529,
    "state_id": "PA"
  },
  {
    "zip": 18071,
    "lat": 40.82964,
    "lng": -75.58125,
    "state_id": "PA"
  },
  {
    "zip": 18072,
    "lat": 40.84556,
    "lng": -75.25759,
    "state_id": "PA"
  },
  {
    "zip": 18073,
    "lat": 40.38414,
    "lng": -75.47555,
    "state_id": "PA"
  },
  {
    "zip": 18074,
    "lat": 40.31952,
    "lng": -75.51609,
    "state_id": "PA"
  },
  {
    "zip": 18076,
    "lat": 40.37475,
    "lng": -75.48076,
    "state_id": "PA"
  },
  {
    "zip": 18077,
    "lat": 40.56687,
    "lng": -75.2435,
    "state_id": "PA"
  },
  {
    "zip": 18078,
    "lat": 40.67323,
    "lng": -75.61893,
    "state_id": "PA"
  },
  {
    "zip": 18079,
    "lat": 40.74411,
    "lng": -75.65793,
    "state_id": "PA"
  },
  {
    "zip": 18080,
    "lat": 40.7358,
    "lng": -75.63322,
    "state_id": "PA"
  },
  {
    "zip": 18081,
    "lat": 40.56225,
    "lng": -75.28391,
    "state_id": "PA"
  },
  {
    "zip": 18083,
    "lat": 40.75457,
    "lng": -75.26709,
    "state_id": "PA"
  },
  {
    "zip": 18085,
    "lat": 40.74131,
    "lng": -75.25519,
    "state_id": "PA"
  },
  {
    "zip": 18086,
    "lat": 40.73713,
    "lng": -75.54829,
    "state_id": "PA"
  },
  {
    "zip": 18087,
    "lat": 40.55421,
    "lng": -75.59481,
    "state_id": "PA"
  },
  {
    "zip": 18088,
    "lat": 40.76596,
    "lng": -75.55657,
    "state_id": "PA"
  },
  {
    "zip": 18091,
    "lat": 40.82612,
    "lng": -75.32005,
    "state_id": "PA"
  },
  {
    "zip": 18092,
    "lat": 40.47045,
    "lng": -75.51533,
    "state_id": "PA"
  },
  {
    "zip": 18101,
    "lat": 40.6027,
    "lng": -75.46925,
    "state_id": "PA"
  },
  {
    "zip": 18102,
    "lat": 40.6083,
    "lng": -75.4763,
    "state_id": "PA"
  },
  {
    "zip": 18103,
    "lat": 40.57044,
    "lng": -75.48232,
    "state_id": "PA"
  },
  {
    "zip": 18104,
    "lat": 40.61129,
    "lng": -75.54707,
    "state_id": "PA"
  },
  {
    "zip": 18105,
    "lat": 40.60141,
    "lng": -75.49394,
    "state_id": "PA"
  },
  {
    "zip": 18106,
    "lat": 40.57568,
    "lng": -75.59616,
    "state_id": "PA"
  },
  {
    "zip": 18109,
    "lat": 40.6366,
    "lng": -75.44047,
    "state_id": "PA"
  },
  {
    "zip": 18195,
    "lat": 40.58427,
    "lng": -75.62479,
    "state_id": "PA"
  },
  {
    "zip": 18201,
    "lat": 40.94888,
    "lng": -75.95625,
    "state_id": "PA"
  },
  {
    "zip": 18202,
    "lat": 40.95148,
    "lng": -76.04683,
    "state_id": "PA"
  },
  {
    "zip": 18210,
    "lat": 41.00118,
    "lng": -75.57188,
    "state_id": "PA"
  },
  {
    "zip": 18211,
    "lat": 40.74377,
    "lng": -75.82539,
    "state_id": "PA"
  },
  {
    "zip": 18212,
    "lat": 40.77523,
    "lng": -75.70957,
    "state_id": "PA"
  },
  {
    "zip": 18214,
    "lat": 40.7988,
    "lng": -76.0815,
    "state_id": "PA"
  },
  {
    "zip": 18216,
    "lat": 40.9415,
    "lng": -75.89283,
    "state_id": "PA"
  },
  {
    "zip": 18218,
    "lat": 40.81973,
    "lng": -75.9161,
    "state_id": "PA"
  },
  {
    "zip": 18219,
    "lat": 40.99086,
    "lng": -76.0575,
    "state_id": "PA"
  },
  {
    "zip": 18220,
    "lat": 40.84049,
    "lng": -76.06034,
    "state_id": "PA"
  },
  {
    "zip": 18221,
    "lat": 41.00179,
    "lng": -75.91731,
    "state_id": "PA"
  },
  {
    "zip": 18222,
    "lat": 41.03504,
    "lng": -76.0005,
    "state_id": "PA"
  },
  {
    "zip": 18223,
    "lat": 40.98235,
    "lng": -75.9502,
    "state_id": "PA"
  },
  {
    "zip": 18224,
    "lat": 41.02248,
    "lng": -75.87949,
    "state_id": "PA"
  },
  {
    "zip": 18225,
    "lat": 40.98132,
    "lng": -75.97121,
    "state_id": "PA"
  },
  {
    "zip": 18229,
    "lat": 40.93059,
    "lng": -75.67677,
    "state_id": "PA"
  },
  {
    "zip": 18230,
    "lat": 40.92114,
    "lng": -75.93445,
    "state_id": "PA"
  },
  {
    "zip": 18231,
    "lat": 40.90427,
    "lng": -76.00791,
    "state_id": "PA"
  },
  {
    "zip": 18232,
    "lat": 40.83307,
    "lng": -75.88478,
    "state_id": "PA"
  },
  {
    "zip": 18234,
    "lat": 40.99254,
    "lng": -75.96435,
    "state_id": "PA"
  },
  {
    "zip": 18235,
    "lat": 40.82991,
    "lng": -75.69736,
    "state_id": "PA"
  },
  {
    "zip": 18237,
    "lat": 40.8835,
    "lng": -75.99638,
    "state_id": "PA"
  },
  {
    "zip": 18239,
    "lat": 40.9875,
    "lng": -75.98248,
    "state_id": "PA"
  },
  {
    "zip": 18240,
    "lat": 40.86055,
    "lng": -75.87024,
    "state_id": "PA"
  },
  {
    "zip": 18241,
    "lat": 40.94539,
    "lng": -76.14607,
    "state_id": "PA"
  },
  {
    "zip": 18242,
    "lat": 40.91137,
    "lng": -76.12435,
    "state_id": "PA"
  },
  {
    "zip": 18244,
    "lat": 40.8987,
    "lng": -75.73183,
    "state_id": "PA"
  },
  {
    "zip": 18245,
    "lat": 40.85318,
    "lng": -76.0335,
    "state_id": "PA"
  },
  {
    "zip": 18246,
    "lat": 40.95722,
    "lng": -76.19524,
    "state_id": "PA"
  },
  {
    "zip": 18248,
    "lat": 40.89323,
    "lng": -76.10742,
    "state_id": "PA"
  },
  {
    "zip": 18249,
    "lat": 40.98721,
    "lng": -76.1236,
    "state_id": "PA"
  },
  {
    "zip": 18250,
    "lat": 40.82483,
    "lng": -75.84637,
    "state_id": "PA"
  },
  {
    "zip": 18251,
    "lat": 41.00788,
    "lng": -76.07726,
    "state_id": "PA"
  },
  {
    "zip": 18252,
    "lat": 40.77236,
    "lng": -75.97612,
    "state_id": "PA"
  },
  {
    "zip": 18254,
    "lat": 40.91594,
    "lng": -75.96564,
    "state_id": "PA"
  },
  {
    "zip": 18255,
    "lat": 40.9328,
    "lng": -75.8314,
    "state_id": "PA"
  },
  {
    "zip": 18256,
    "lat": 40.9409,
    "lng": -76.14677,
    "state_id": "PA"
  },
  {
    "zip": 18301,
    "lat": 41.03197,
    "lng": -75.17993,
    "state_id": "PA"
  },
  {
    "zip": 18302,
    "lat": 41.09367,
    "lng": -75.11881,
    "state_id": "PA"
  },
  {
    "zip": 18321,
    "lat": 41.01685,
    "lng": -75.28975,
    "state_id": "PA"
  },
  {
    "zip": 18322,
    "lat": 40.92284,
    "lng": -75.39879,
    "state_id": "PA"
  },
  {
    "zip": 18323,
    "lat": 41.19658,
    "lng": -75.27346,
    "state_id": "PA"
  },
  {
    "zip": 18324,
    "lat": 41.15197,
    "lng": -75.00303,
    "state_id": "PA"
  },
  {
    "zip": 18325,
    "lat": 41.21399,
    "lng": -75.22,
    "state_id": "PA"
  },
  {
    "zip": 18326,
    "lat": 41.15412,
    "lng": -75.27256,
    "state_id": "PA"
  },
  {
    "zip": 18327,
    "lat": 40.97462,
    "lng": -75.13872,
    "state_id": "PA"
  },
  {
    "zip": 18328,
    "lat": 41.22452,
    "lng": -75.00356,
    "state_id": "PA"
  },
  {
    "zip": 18330,
    "lat": 40.97068,
    "lng": -75.44909,
    "state_id": "PA"
  },
  {
    "zip": 18331,
    "lat": 40.91247,
    "lng": -75.44503,
    "state_id": "PA"
  },
  {
    "zip": 18332,
    "lat": 41.08952,
    "lng": -75.262,
    "state_id": "PA"
  },
  {
    "zip": 18333,
    "lat": 40.90235,
    "lng": -75.4917,
    "state_id": "PA"
  },
  {
    "zip": 18334,
    "lat": 41.06061,
    "lng": -75.44551,
    "state_id": "PA"
  },
  {
    "zip": 18335,
    "lat": 41.0656,
    "lng": -75.10538,
    "state_id": "PA"
  },
  {
    "zip": 18336,
    "lat": 41.37605,
    "lng": -74.74178,
    "state_id": "PA"
  },
  {
    "zip": 18337,
    "lat": 41.3271,
    "lng": -74.87894,
    "state_id": "PA"
  },
  {
    "zip": 18340,
    "lat": 41.41988,
    "lng": -74.76756,
    "state_id": "PA"
  },
  {
    "zip": 18342,
    "lat": 41.16691,
    "lng": -75.27267,
    "state_id": "PA"
  },
  {
    "zip": 18343,
    "lat": 40.89562,
    "lng": -75.10849,
    "state_id": "PA"
  },
  {
    "zip": 18344,
    "lat": 41.11849,
    "lng": -75.34097,
    "state_id": "PA"
  },
  {
    "zip": 18346,
    "lat": 41.13535,
    "lng": -75.40792,
    "state_id": "PA"
  },
  {
    "zip": 18347,
    "lat": 41.13379,
    "lng": -75.55732,
    "state_id": "PA"
  },
  {
    "zip": 18349,
    "lat": 41.10199,
    "lng": -75.36813,
    "state_id": "PA"
  },
  {
    "zip": 18350,
    "lat": 41.12567,
    "lng": -75.46659,
    "state_id": "PA"
  },
  {
    "zip": 18351,
    "lat": 40.92277,
    "lng": -75.09941,
    "state_id": "PA"
  },
  {
    "zip": 18352,
    "lat": 41.00786,
    "lng": -75.3547,
    "state_id": "PA"
  },
  {
    "zip": 18353,
    "lat": 40.89651,
    "lng": -75.367,
    "state_id": "PA"
  },
  {
    "zip": 18354,
    "lat": 40.92571,
    "lng": -75.32502,
    "state_id": "PA"
  },
  {
    "zip": 18355,
    "lat": 41.07598,
    "lng": -75.35392,
    "state_id": "PA"
  },
  {
    "zip": 18356,
    "lat": 41.01115,
    "lng": -75.11796,
    "state_id": "PA"
  },
  {
    "zip": 18357,
    "lat": 41.22767,
    "lng": -75.21847,
    "state_id": "PA"
  },
  {
    "zip": 18360,
    "lat": 40.97392,
    "lng": -75.28367,
    "state_id": "PA"
  },
  {
    "zip": 18370,
    "lat": 41.09181,
    "lng": -75.34138,
    "state_id": "PA"
  },
  {
    "zip": 18371,
    "lat": 41.15868,
    "lng": -75.0417,
    "state_id": "PA"
  },
  {
    "zip": 18372,
    "lat": 41.04724,
    "lng": -75.32455,
    "state_id": "PA"
  },
  {
    "zip": 18403,
    "lat": 41.50042,
    "lng": -75.52959,
    "state_id": "PA"
  },
  {
    "zip": 18405,
    "lat": 41.593,
    "lng": -75.10266,
    "state_id": "PA"
  },
  {
    "zip": 18407,
    "lat": 41.59954,
    "lng": -75.52782,
    "state_id": "PA"
  },
  {
    "zip": 18411,
    "lat": 41.45427,
    "lng": -75.74522,
    "state_id": "PA"
  },
  {
    "zip": 18413,
    "lat": 41.64816,
    "lng": -75.58949,
    "state_id": "PA"
  },
  {
    "zip": 18414,
    "lat": 41.54011,
    "lng": -75.7403,
    "state_id": "PA"
  },
  {
    "zip": 18415,
    "lat": 41.74419,
    "lng": -75.11477,
    "state_id": "PA"
  },
  {
    "zip": 18417,
    "lat": 41.80469,
    "lng": -75.19853,
    "state_id": "PA"
  },
  {
    "zip": 18419,
    "lat": 41.58581,
    "lng": -75.79094,
    "state_id": "PA"
  },
  {
    "zip": 18420,
    "lat": 41.60201,
    "lng": -75.71012,
    "state_id": "PA"
  },
  {
    "zip": 18421,
    "lat": 41.6645,
    "lng": -75.46973,
    "state_id": "PA"
  },
  {
    "zip": 18424,
    "lat": 41.22515,
    "lng": -75.51595,
    "state_id": "PA"
  },
  {
    "zip": 18425,
    "lat": 41.42577,
    "lng": -75.02051,
    "state_id": "PA"
  },
  {
    "zip": 18426,
    "lat": 41.31382,
    "lng": -75.23551,
    "state_id": "PA"
  },
  {
    "zip": 18427,
    "lat": 41.40467,
    "lng": -75.4069,
    "state_id": "PA"
  },
  {
    "zip": 18428,
    "lat": 41.41607,
    "lng": -75.12311,
    "state_id": "PA"
  },
  {
    "zip": 18430,
    "lat": 41.74811,
    "lng": -75.47403,
    "state_id": "PA"
  },
  {
    "zip": 18431,
    "lat": 41.62299,
    "lng": -75.25748,
    "state_id": "PA"
  },
  {
    "zip": 18433,
    "lat": 41.57448,
    "lng": -75.5882,
    "state_id": "PA"
  },
  {
    "zip": 18434,
    "lat": 41.46147,
    "lng": -75.54831,
    "state_id": "PA"
  },
  {
    "zip": 18435,
    "lat": 41.48906,
    "lng": -75.00234,
    "state_id": "PA"
  },
  {
    "zip": 18436,
    "lat": 41.43676,
    "lng": -75.39302,
    "state_id": "PA"
  },
  {
    "zip": 18437,
    "lat": 41.8678,
    "lng": -75.30636,
    "state_id": "PA"
  },
  {
    "zip": 18438,
    "lat": 41.43596,
    "lng": -75.25095,
    "state_id": "PA"
  },
  {
    "zip": 18439,
    "lat": 41.82684,
    "lng": -75.34922,
    "state_id": "PA"
  },
  {
    "zip": 18441,
    "lat": 41.65949,
    "lng": -75.62839,
    "state_id": "PA"
  },
  {
    "zip": 18443,
    "lat": 41.66231,
    "lng": -75.10116,
    "state_id": "PA"
  },
  {
    "zip": 18444,
    "lat": 41.33107,
    "lng": -75.55095,
    "state_id": "PA"
  },
  {
    "zip": 18445,
    "lat": 41.2966,
    "lng": -75.36063,
    "state_id": "PA"
  },
  {
    "zip": 18446,
    "lat": 41.645,
    "lng": -75.75704,
    "state_id": "PA"
  },
  {
    "zip": 18447,
    "lat": 41.50831,
    "lng": -75.61664,
    "state_id": "PA"
  },
  {
    "zip": 18451,
    "lat": 41.40818,
    "lng": -75.21646,
    "state_id": "PA"
  },
  {
    "zip": 18452,
    "lat": 41.48361,
    "lng": -75.59016,
    "state_id": "PA"
  },
  {
    "zip": 18453,
    "lat": 41.75457,
    "lng": -75.36203,
    "state_id": "PA"
  },
  {
    "zip": 18454,
    "lat": 41.829,
    "lng": -75.42667,
    "state_id": "PA"
  },
  {
    "zip": 18455,
    "lat": 41.88678,
    "lng": -75.35605,
    "state_id": "PA"
  },
  {
    "zip": 18456,
    "lat": 41.6262,
    "lng": -75.34428,
    "state_id": "PA"
  },
  {
    "zip": 18457,
    "lat": 41.47197,
    "lng": -75.04858,
    "state_id": "PA"
  },
  {
    "zip": 18458,
    "lat": 41.40926,
    "lng": -74.91634,
    "state_id": "PA"
  },
  {
    "zip": 18459,
    "lat": 41.50495,
    "lng": -75.42905,
    "state_id": "PA"
  },
  {
    "zip": 18460,
    "lat": 41.25393,
    "lng": -75.32354,
    "state_id": "PA"
  },
  {
    "zip": 18461,
    "lat": 41.92781,
    "lng": -75.3282,
    "state_id": "PA"
  },
  {
    "zip": 18462,
    "lat": 41.90749,
    "lng": -75.4223,
    "state_id": "PA"
  },
  {
    "zip": 18463,
    "lat": 41.34925,
    "lng": -75.40332,
    "state_id": "PA"
  },
  {
    "zip": 18464,
    "lat": 41.40327,
    "lng": -75.17869,
    "state_id": "PA"
  },
  {
    "zip": 18465,
    "lat": 41.82946,
    "lng": -75.51184,
    "state_id": "PA"
  },
  {
    "zip": 18466,
    "lat": 41.19551,
    "lng": -75.38564,
    "state_id": "PA"
  },
  {
    "zip": 18469,
    "lat": 41.70113,
    "lng": -75.14217,
    "state_id": "PA"
  },
  {
    "zip": 18470,
    "lat": 41.72793,
    "lng": -75.53824,
    "state_id": "PA"
  },
  {
    "zip": 18471,
    "lat": 41.52832,
    "lng": -75.69221,
    "state_id": "PA"
  },
  {
    "zip": 18472,
    "lat": 41.58381,
    "lng": -75.39848,
    "state_id": "PA"
  },
  {
    "zip": 18473,
    "lat": 41.52436,
    "lng": -75.21087,
    "state_id": "PA"
  },
  {
    "zip": 18503,
    "lat": 41.41128,
    "lng": -75.66769,
    "state_id": "PA"
  },
  {
    "zip": 18504,
    "lat": 41.42553,
    "lng": -75.69991,
    "state_id": "PA"
  },
  {
    "zip": 18505,
    "lat": 41.3878,
    "lng": -75.65313,
    "state_id": "PA"
  },
  {
    "zip": 18507,
    "lat": 41.35534,
    "lng": -75.68053,
    "state_id": "PA"
  },
  {
    "zip": 18508,
    "lat": 41.45493,
    "lng": -75.65793,
    "state_id": "PA"
  },
  {
    "zip": 18509,
    "lat": 41.43081,
    "lng": -75.6428,
    "state_id": "PA"
  },
  {
    "zip": 18510,
    "lat": 41.4073,
    "lng": -75.6368,
    "state_id": "PA"
  },
  {
    "zip": 18512,
    "lat": 41.42739,
    "lng": -75.59457,
    "state_id": "PA"
  },
  {
    "zip": 18517,
    "lat": 41.39461,
    "lng": -75.71365,
    "state_id": "PA"
  },
  {
    "zip": 18518,
    "lat": 41.37501,
    "lng": -75.73959,
    "state_id": "PA"
  },
  {
    "zip": 18519,
    "lat": 41.46192,
    "lng": -75.63073,
    "state_id": "PA"
  },
  {
    "zip": 18602,
    "lat": 41.18765,
    "lng": -75.75521,
    "state_id": "PA"
  },
  {
    "zip": 18603,
    "lat": 41.08296,
    "lng": -76.24887,
    "state_id": "PA"
  },
  {
    "zip": 18610,
    "lat": 41.06305,
    "lng": -75.54612,
    "state_id": "PA"
  },
  {
    "zip": 18612,
    "lat": 41.34938,
    "lng": -75.98332,
    "state_id": "PA"
  },
  {
    "zip": 18614,
    "lat": 41.48972,
    "lng": -76.37811,
    "state_id": "PA"
  },
  {
    "zip": 18615,
    "lat": 41.46186,
    "lng": -75.85726,
    "state_id": "PA"
  },
  {
    "zip": 18616,
    "lat": 41.50966,
    "lng": -76.62372,
    "state_id": "PA"
  },
  {
    "zip": 18617,
    "lat": 41.17062,
    "lng": -76.07668,
    "state_id": "PA"
  },
  {
    "zip": 18618,
    "lat": 41.37478,
    "lng": -76.03487,
    "state_id": "PA"
  },
  {
    "zip": 18619,
    "lat": 41.44803,
    "lng": -76.71127,
    "state_id": "PA"
  },
  {
    "zip": 18621,
    "lat": 41.25328,
    "lng": -76.09282,
    "state_id": "PA"
  },
  {
    "zip": 18622,
    "lat": 41.20378,
    "lng": -76.27304,
    "state_id": "PA"
  },
  {
    "zip": 18623,
    "lat": 41.67031,
    "lng": -76.14769,
    "state_id": "PA"
  },
  {
    "zip": 18624,
    "lat": 41.05777,
    "lng": -75.62088,
    "state_id": "PA"
  },
  {
    "zip": 18625,
    "lat": 41.50959,
    "lng": -75.85078,
    "state_id": "PA"
  },
  {
    "zip": 18626,
    "lat": 41.4044,
    "lng": -76.5132,
    "state_id": "PA"
  },
  {
    "zip": 18628,
    "lat": 41.43793,
    "lng": -76.31442,
    "state_id": "PA"
  },
  {
    "zip": 18629,
    "lat": 41.51147,
    "lng": -76.13828,
    "state_id": "PA"
  },
  {
    "zip": 18630,
    "lat": 41.6674,
    "lng": -76.02944,
    "state_id": "PA"
  },
  {
    "zip": 18631,
    "lat": 41.02958,
    "lng": -76.30335,
    "state_id": "PA"
  },
  {
    "zip": 18632,
    "lat": 41.46691,
    "lng": -76.38343,
    "state_id": "PA"
  },
  {
    "zip": 18634,
    "lat": 41.18918,
    "lng": -76.02153,
    "state_id": "PA"
  },
  {
    "zip": 18635,
    "lat": 41.03053,
    "lng": -76.20174,
    "state_id": "PA"
  },
  {
    "zip": 18636,
    "lat": 41.4026,
    "lng": -76.09751,
    "state_id": "PA"
  },
  {
    "zip": 18640,
    "lat": 41.29714,
    "lng": -75.74029,
    "state_id": "PA"
  },
  {
    "zip": 18641,
    "lat": 41.33603,
    "lng": -75.73045,
    "state_id": "PA"
  },
  {
    "zip": 18642,
    "lat": 41.35387,
    "lng": -75.77672,
    "state_id": "PA"
  },
  {
    "zip": 18643,
    "lat": 41.36655,
    "lng": -75.83624,
    "state_id": "PA"
  },
  {
    "zip": 18644,
    "lat": 41.33134,
    "lng": -75.87861,
    "state_id": "PA"
  },
  {
    "zip": 18651,
    "lat": 41.24756,
    "lng": -75.96185,
    "state_id": "PA"
  },
  {
    "zip": 18653,
    "lat": 41.39328,
    "lng": -75.82357,
    "state_id": "PA"
  },
  {
    "zip": 18655,
    "lat": 41.18175,
    "lng": -76.19614,
    "state_id": "PA"
  },
  {
    "zip": 18656,
    "lat": 41.32264,
    "lng": -76.20947,
    "state_id": "PA"
  },
  {
    "zip": 18657,
    "lat": 41.52521,
    "lng": -75.95419,
    "state_id": "PA"
  },
  {
    "zip": 18660,
    "lat": 41.10388,
    "lng": -76.06491,
    "state_id": "PA"
  },
  {
    "zip": 18661,
    "lat": 41.08606,
    "lng": -75.75357,
    "state_id": "PA"
  },
  {
    "zip": 18701,
    "lat": 41.24368,
    "lng": -75.88508,
    "state_id": "PA"
  },
  {
    "zip": 18702,
    "lat": 41.22084,
    "lng": -75.77365,
    "state_id": "PA"
  },
  {
    "zip": 18704,
    "lat": 41.27661,
    "lng": -75.89463,
    "state_id": "PA"
  },
  {
    "zip": 18705,
    "lat": 41.27183,
    "lng": -75.84331,
    "state_id": "PA"
  },
  {
    "zip": 18706,
    "lat": 41.20436,
    "lng": -75.91138,
    "state_id": "PA"
  },
  {
    "zip": 18707,
    "lat": 41.13369,
    "lng": -75.92644,
    "state_id": "PA"
  },
  {
    "zip": 18708,
    "lat": 41.29414,
    "lng": -75.96534,
    "state_id": "PA"
  },
  {
    "zip": 18709,
    "lat": 41.28549,
    "lng": -75.89639,
    "state_id": "PA"
  },
  {
    "zip": 18801,
    "lat": 41.82978,
    "lng": -75.93836,
    "state_id": "PA"
  },
  {
    "zip": 18810,
    "lat": 41.93569,
    "lng": -76.50452,
    "state_id": "PA"
  },
  {
    "zip": 18812,
    "lat": 41.96666,
    "lng": -75.95747,
    "state_id": "PA"
  },
  {
    "zip": 18814,
    "lat": 41.78173,
    "lng": -76.60551,
    "state_id": "PA"
  },
  {
    "zip": 18816,
    "lat": 41.74982,
    "lng": -75.91554,
    "state_id": "PA"
  },
  {
    "zip": 18817,
    "lat": 41.87005,
    "lng": -76.6263,
    "state_id": "PA"
  },
  {
    "zip": 18818,
    "lat": 41.91031,
    "lng": -76.04687,
    "state_id": "PA"
  },
  {
    "zip": 18821,
    "lat": 41.98652,
    "lng": -75.73635,
    "state_id": "PA"
  },
  {
    "zip": 18822,
    "lat": 41.94977,
    "lng": -75.80145,
    "state_id": "PA"
  },
  {
    "zip": 18823,
    "lat": 41.77608,
    "lng": -75.69351,
    "state_id": "PA"
  },
  {
    "zip": 18824,
    "lat": 41.7041,
    "lng": -75.78357,
    "state_id": "PA"
  },
  {
    "zip": 18825,
    "lat": 41.80766,
    "lng": -75.60101,
    "state_id": "PA"
  },
  {
    "zip": 18826,
    "lat": 41.75105,
    "lng": -75.74653,
    "state_id": "PA"
  },
  {
    "zip": 18828,
    "lat": 41.79647,
    "lng": -76.10448,
    "state_id": "PA"
  },
  {
    "zip": 18829,
    "lat": 41.8345,
    "lng": -76.16968,
    "state_id": "PA"
  },
  {
    "zip": 18830,
    "lat": 41.95142,
    "lng": -76.10742,
    "state_id": "PA"
  },
  {
    "zip": 18831,
    "lat": 41.89121,
    "lng": -76.60085,
    "state_id": "PA"
  },
  {
    "zip": 18832,
    "lat": 41.67702,
    "lng": -76.55657,
    "state_id": "PA"
  },
  {
    "zip": 18833,
    "lat": 41.59759,
    "lng": -76.479,
    "state_id": "PA"
  },
  {
    "zip": 18834,
    "lat": 41.8435,
    "lng": -75.71432,
    "state_id": "PA"
  },
  {
    "zip": 18837,
    "lat": 41.92085,
    "lng": -76.29649,
    "state_id": "PA"
  },
  {
    "zip": 18840,
    "lat": 41.97183,
    "lng": -76.53575,
    "state_id": "PA"
  },
  {
    "zip": 18842,
    "lat": 41.75811,
    "lng": -75.62108,
    "state_id": "PA"
  },
  {
    "zip": 18843,
    "lat": 41.79442,
    "lng": -75.89978,
    "state_id": "PA"
  },
  {
    "zip": 18844,
    "lat": 41.69516,
    "lng": -75.9143,
    "state_id": "PA"
  },
  {
    "zip": 18845,
    "lat": 41.7881,
    "lng": -76.18461,
    "state_id": "PA"
  },
  {
    "zip": 18846,
    "lat": 41.60238,
    "lng": -76.241,
    "state_id": "PA"
  },
  {
    "zip": 18847,
    "lat": 41.92957,
    "lng": -75.56896,
    "state_id": "PA"
  },
  {
    "zip": 18848,
    "lat": 41.75212,
    "lng": -76.45419,
    "state_id": "PA"
  },
  {
    "zip": 18850,
    "lat": 41.85292,
    "lng": -76.49997,
    "state_id": "PA"
  },
  {
    "zip": 18851,
    "lat": 41.93406,
    "lng": -76.17605,
    "state_id": "PA"
  },
  {
    "zip": 18853,
    "lat": 41.72067,
    "lng": -76.26901,
    "state_id": "PA"
  },
  {
    "zip": 18854,
    "lat": 41.79465,
    "lng": -76.35532,
    "state_id": "PA"
  },
  {
    "zip": 18901,
    "lat": 40.30543,
    "lng": -75.14884,
    "state_id": "PA"
  },
  {
    "zip": 18902,
    "lat": 40.35307,
    "lng": -75.09676,
    "state_id": "PA"
  },
  {
    "zip": 18912,
    "lat": 40.32373,
    "lng": -75.05437,
    "state_id": "PA"
  },
  {
    "zip": 18913,
    "lat": 40.38173,
    "lng": -75.05577,
    "state_id": "PA"
  },
  {
    "zip": 18914,
    "lat": 40.289,
    "lng": -75.20985,
    "state_id": "PA"
  },
  {
    "zip": 18915,
    "lat": 40.27218,
    "lng": -75.25677,
    "state_id": "PA"
  },
  {
    "zip": 18917,
    "lat": 40.37303,
    "lng": -75.20409,
    "state_id": "PA"
  },
  {
    "zip": 18920,
    "lat": 40.49613,
    "lng": -75.08428,
    "state_id": "PA"
  },
  {
    "zip": 18923,
    "lat": 40.35772,
    "lng": -75.17108,
    "state_id": "PA"
  },
  {
    "zip": 18925,
    "lat": 40.28623,
    "lng": -75.05641,
    "state_id": "PA"
  },
  {
    "zip": 18929,
    "lat": 40.25521,
    "lng": -75.07984,
    "state_id": "PA"
  },
  {
    "zip": 18930,
    "lat": 40.52155,
    "lng": -75.21355,
    "state_id": "PA"
  },
  {
    "zip": 18932,
    "lat": 40.29426,
    "lng": -75.2558,
    "state_id": "PA"
  },
  {
    "zip": 18935,
    "lat": 40.43725,
    "lng": -75.40108,
    "state_id": "PA"
  },
  {
    "zip": 18936,
    "lat": 40.22398,
    "lng": -75.23102,
    "state_id": "PA"
  },
  {
    "zip": 18938,
    "lat": 40.35238,
    "lng": -74.9975,
    "state_id": "PA"
  },
  {
    "zip": 18940,
    "lat": 40.2632,
    "lng": -74.94751,
    "state_id": "PA"
  },
  {
    "zip": 18942,
    "lat": 40.47019,
    "lng": -75.15929,
    "state_id": "PA"
  },
  {
    "zip": 18944,
    "lat": 40.39137,
    "lng": -75.23465,
    "state_id": "PA"
  },
  {
    "zip": 18947,
    "lat": 40.4244,
    "lng": -75.11448,
    "state_id": "PA"
  },
  {
    "zip": 18950,
    "lat": 40.44282,
    "lng": -75.07147,
    "state_id": "PA"
  },
  {
    "zip": 18951,
    "lat": 40.4524,
    "lng": -75.34461,
    "state_id": "PA"
  },
  {
    "zip": 18954,
    "lat": 40.22264,
    "lng": -74.99932,
    "state_id": "PA"
  },
  {
    "zip": 18955,
    "lat": 40.47756,
    "lng": -75.3152,
    "state_id": "PA"
  },
  {
    "zip": 18960,
    "lat": 40.36405,
    "lng": -75.32503,
    "state_id": "PA"
  },
  {
    "zip": 18962,
    "lat": 40.346,
    "lng": -75.27068,
    "state_id": "PA"
  },
  {
    "zip": 18964,
    "lat": 40.29739,
    "lng": -75.33329,
    "state_id": "PA"
  },
  {
    "zip": 18966,
    "lat": 40.18764,
    "lng": -75.00629,
    "state_id": "PA"
  },
  {
    "zip": 18969,
    "lat": 40.32631,
    "lng": -75.37272,
    "state_id": "PA"
  },
  {
    "zip": 18970,
    "lat": 40.41201,
    "lng": -75.38118,
    "state_id": "PA"
  },
  {
    "zip": 18972,
    "lat": 40.53343,
    "lng": -75.1196,
    "state_id": "PA"
  },
  {
    "zip": 18974,
    "lat": 40.21597,
    "lng": -75.07296,
    "state_id": "PA"
  },
  {
    "zip": 18976,
    "lat": 40.24792,
    "lng": -75.1443,
    "state_id": "PA"
  },
  {
    "zip": 18977,
    "lat": 40.28725,
    "lng": -74.88067,
    "state_id": "PA"
  },
  {
    "zip": 18980,
    "lat": 40.26793,
    "lng": -75.01415,
    "state_id": "PA"
  },
  {
    "zip": 19001,
    "lat": 40.12594,
    "lng": -75.12543,
    "state_id": "PA"
  },
  {
    "zip": 19002,
    "lat": 40.18382,
    "lng": -75.20993,
    "state_id": "PA"
  },
  {
    "zip": 19003,
    "lat": 40.00154,
    "lng": -75.29898,
    "state_id": "PA"
  },
  {
    "zip": 19004,
    "lat": 40.01373,
    "lng": -75.23007,
    "state_id": "PA"
  },
  {
    "zip": 19006,
    "lat": 40.13296,
    "lng": -75.06155,
    "state_id": "PA"
  },
  {
    "zip": 19007,
    "lat": 40.11389,
    "lng": -74.85855,
    "state_id": "PA"
  },
  {
    "zip": 19008,
    "lat": 39.97385,
    "lng": -75.36075,
    "state_id": "PA"
  },
  {
    "zip": 19009,
    "lat": 40.13778,
    "lng": -75.06425,
    "state_id": "PA"
  },
  {
    "zip": 19010,
    "lat": 40.02359,
    "lng": -75.32978,
    "state_id": "PA"
  },
  {
    "zip": 19012,
    "lat": 40.05972,
    "lng": -75.10587,
    "state_id": "PA"
  },
  {
    "zip": 19013,
    "lat": 39.84791,
    "lng": -75.37611,
    "state_id": "PA"
  },
  {
    "zip": 19014,
    "lat": 39.86362,
    "lng": -75.43358,
    "state_id": "PA"
  },
  {
    "zip": 19015,
    "lat": 39.86921,
    "lng": -75.39286,
    "state_id": "PA"
  },
  {
    "zip": 19017,
    "lat": 39.8909,
    "lng": -75.4644,
    "state_id": "PA"
  },
  {
    "zip": 19018,
    "lat": 39.92304,
    "lng": -75.29861,
    "state_id": "PA"
  },
  {
    "zip": 19020,
    "lat": 40.10451,
    "lng": -74.93857,
    "state_id": "PA"
  },
  {
    "zip": 19021,
    "lat": 40.08904,
    "lng": -74.89153,
    "state_id": "PA"
  },
  {
    "zip": 19022,
    "lat": 39.86143,
    "lng": -75.33704,
    "state_id": "PA"
  },
  {
    "zip": 19023,
    "lat": 39.91716,
    "lng": -75.26745,
    "state_id": "PA"
  },
  {
    "zip": 19025,
    "lat": 40.14644,
    "lng": -75.16198,
    "state_id": "PA"
  },
  {
    "zip": 19026,
    "lat": 39.9503,
    "lng": -75.30406,
    "state_id": "PA"
  },
  {
    "zip": 19027,
    "lat": 40.07349,
    "lng": -75.1242,
    "state_id": "PA"
  },
  {
    "zip": 19029,
    "lat": 39.86955,
    "lng": -75.29113,
    "state_id": "PA"
  },
  {
    "zip": 19030,
    "lat": 40.18068,
    "lng": -74.83717,
    "state_id": "PA"
  },
  {
    "zip": 19031,
    "lat": 40.1092,
    "lng": -75.21701,
    "state_id": "PA"
  },
  {
    "zip": 19032,
    "lat": 39.89122,
    "lng": -75.2795,
    "state_id": "PA"
  },
  {
    "zip": 19033,
    "lat": 39.89093,
    "lng": -75.32837,
    "state_id": "PA"
  },
  {
    "zip": 19034,
    "lat": 40.13427,
    "lng": -75.20482,
    "state_id": "PA"
  },
  {
    "zip": 19035,
    "lat": 40.0492,
    "lng": -75.28147,
    "state_id": "PA"
  },
  {
    "zip": 19036,
    "lat": 39.90359,
    "lng": -75.29342,
    "state_id": "PA"
  },
  {
    "zip": 19038,
    "lat": 40.10169,
    "lng": -75.17242,
    "state_id": "PA"
  },
  {
    "zip": 19040,
    "lat": 40.17697,
    "lng": -75.10567,
    "state_id": "PA"
  },
  {
    "zip": 19041,
    "lat": 40.00718,
    "lng": -75.31653,
    "state_id": "PA"
  },
  {
    "zip": 19043,
    "lat": 39.90032,
    "lng": -75.30889,
    "state_id": "PA"
  },
  {
    "zip": 19044,
    "lat": 40.18607,
    "lng": -75.15287,
    "state_id": "PA"
  },
  {
    "zip": 19046,
    "lat": 40.10141,
    "lng": -75.10557,
    "state_id": "PA"
  },
  {
    "zip": 19047,
    "lat": 40.18131,
    "lng": -74.9104,
    "state_id": "PA"
  },
  {
    "zip": 19050,
    "lat": 39.93752,
    "lng": -75.26369,
    "state_id": "PA"
  },
  {
    "zip": 19053,
    "lat": 40.15467,
    "lng": -74.97955,
    "state_id": "PA"
  },
  {
    "zip": 19054,
    "lat": 40.17083,
    "lng": -74.82041,
    "state_id": "PA"
  },
  {
    "zip": 19055,
    "lat": 40.14967,
    "lng": -74.83881,
    "state_id": "PA"
  },
  {
    "zip": 19056,
    "lat": 40.14969,
    "lng": -74.8858,
    "state_id": "PA"
  },
  {
    "zip": 19057,
    "lat": 40.14008,
    "lng": -74.85645,
    "state_id": "PA"
  },
  {
    "zip": 19060,
    "lat": 39.84973,
    "lng": -75.49395,
    "state_id": "PA"
  },
  {
    "zip": 19061,
    "lat": 39.82937,
    "lng": -75.43547,
    "state_id": "PA"
  },
  {
    "zip": 19063,
    "lat": 39.92181,
    "lng": -75.4141,
    "state_id": "PA"
  },
  {
    "zip": 19064,
    "lat": 39.9333,
    "lng": -75.34114,
    "state_id": "PA"
  },
  {
    "zip": 19066,
    "lat": 40.00262,
    "lng": -75.24908,
    "state_id": "PA"
  },
  {
    "zip": 19067,
    "lat": 40.20632,
    "lng": -74.81597,
    "state_id": "PA"
  },
  {
    "zip": 19070,
    "lat": 39.90624,
    "lng": -75.3247,
    "state_id": "PA"
  },
  {
    "zip": 19072,
    "lat": 40.02433,
    "lng": -75.25771,
    "state_id": "PA"
  },
  {
    "zip": 19073,
    "lat": 39.98103,
    "lng": -75.43487,
    "state_id": "PA"
  },
  {
    "zip": 19074,
    "lat": 39.88644,
    "lng": -75.29637,
    "state_id": "PA"
  },
  {
    "zip": 19075,
    "lat": 40.11404,
    "lng": -75.18635,
    "state_id": "PA"
  },
  {
    "zip": 19076,
    "lat": 39.88589,
    "lng": -75.30755,
    "state_id": "PA"
  },
  {
    "zip": 19078,
    "lat": 39.87477,
    "lng": -75.32185,
    "state_id": "PA"
  },
  {
    "zip": 19079,
    "lat": 39.89888,
    "lng": -75.26663,
    "state_id": "PA"
  },
  {
    "zip": 19081,
    "lat": 39.89805,
    "lng": -75.34715,
    "state_id": "PA"
  },
  {
    "zip": 19082,
    "lat": 39.96041,
    "lng": -75.27045,
    "state_id": "PA"
  },
  {
    "zip": 19083,
    "lat": 39.97701,
    "lng": -75.3121,
    "state_id": "PA"
  },
  {
    "zip": 19085,
    "lat": 40.03738,
    "lng": -75.34956,
    "state_id": "PA"
  },
  {
    "zip": 19086,
    "lat": 39.8906,
    "lng": -75.37007,
    "state_id": "PA"
  },
  {
    "zip": 19087,
    "lat": 40.06116,
    "lng": -75.39987,
    "state_id": "PA"
  },
  {
    "zip": 19090,
    "lat": 40.15675,
    "lng": -75.12687,
    "state_id": "PA"
  },
  {
    "zip": 19094,
    "lat": 39.87482,
    "lng": -75.34659,
    "state_id": "PA"
  },
  {
    "zip": 19095,
    "lat": 40.08597,
    "lng": -75.15119,
    "state_id": "PA"
  },
  {
    "zip": 19096,
    "lat": 39.99786,
    "lng": -75.27419,
    "state_id": "PA"
  },
  {
    "zip": 19102,
    "lat": 39.95287,
    "lng": -75.16549,
    "state_id": "PA"
  },
  {
    "zip": 19103,
    "lat": 39.9528,
    "lng": -75.17397,
    "state_id": "PA"
  },
  {
    "zip": 19104,
    "lat": 39.95917,
    "lng": -75.19842,
    "state_id": "PA"
  },
  {
    "zip": 19106,
    "lat": 39.94865,
    "lng": -75.14296,
    "state_id": "PA"
  },
  {
    "zip": 19107,
    "lat": 39.95182,
    "lng": -75.15861,
    "state_id": "PA"
  },
  {
    "zip": 19109,
    "lat": 39.94966,
    "lng": -75.16365,
    "state_id": "PA"
  },
  {
    "zip": 19111,
    "lat": 40.06025,
    "lng": -75.08041,
    "state_id": "PA"
  },
  {
    "zip": 19112,
    "lat": 39.8899,
    "lng": -75.16977,
    "state_id": "PA"
  },
  {
    "zip": 19113,
    "lat": 39.86742,
    "lng": -75.25335,
    "state_id": "PA"
  },
  {
    "zip": 19114,
    "lat": 40.06736,
    "lng": -75.00358,
    "state_id": "PA"
  },
  {
    "zip": 19115,
    "lat": 40.09108,
    "lng": -75.04576,
    "state_id": "PA"
  },
  {
    "zip": 19116,
    "lat": 40.11679,
    "lng": -75.01276,
    "state_id": "PA"
  },
  {
    "zip": 19118,
    "lat": 40.0727,
    "lng": -75.21247,
    "state_id": "PA"
  },
  {
    "zip": 19119,
    "lat": 40.05382,
    "lng": -75.19094,
    "state_id": "PA"
  },
  {
    "zip": 19120,
    "lat": 40.03365,
    "lng": -75.11998,
    "state_id": "PA"
  },
  {
    "zip": 19121,
    "lat": 39.98216,
    "lng": -75.17866,
    "state_id": "PA"
  },
  {
    "zip": 19122,
    "lat": 39.97778,
    "lng": -75.14582,
    "state_id": "PA"
  },
  {
    "zip": 19123,
    "lat": 39.9642,
    "lng": -75.14586,
    "state_id": "PA"
  },
  {
    "zip": 19124,
    "lat": 40.01711,
    "lng": -75.09304,
    "state_id": "PA"
  },
  {
    "zip": 19125,
    "lat": 39.97617,
    "lng": -75.1248,
    "state_id": "PA"
  },
  {
    "zip": 19126,
    "lat": 40.05538,
    "lng": -75.13752,
    "state_id": "PA"
  },
  {
    "zip": 19127,
    "lat": 40.02828,
    "lng": -75.22732,
    "state_id": "PA"
  },
  {
    "zip": 19128,
    "lat": 40.04881,
    "lng": -75.22899,
    "state_id": "PA"
  },
  {
    "zip": 19129,
    "lat": 40.01358,
    "lng": -75.18461,
    "state_id": "PA"
  },
  {
    "zip": 19130,
    "lat": 39.96776,
    "lng": -75.17544,
    "state_id": "PA"
  },
  {
    "zip": 19131,
    "lat": 39.98856,
    "lng": -75.21792,
    "state_id": "PA"
  },
  {
    "zip": 19132,
    "lat": 39.99628,
    "lng": -75.17077,
    "state_id": "PA"
  },
  {
    "zip": 19133,
    "lat": 39.99259,
    "lng": -75.14138,
    "state_id": "PA"
  },
  {
    "zip": 19134,
    "lat": 39.98981,
    "lng": -75.10843,
    "state_id": "PA"
  },
  {
    "zip": 19135,
    "lat": 40.02188,
    "lng": -75.04929,
    "state_id": "PA"
  },
  {
    "zip": 19136,
    "lat": 40.03972,
    "lng": -75.01894,
    "state_id": "PA"
  },
  {
    "zip": 19137,
    "lat": 39.9946,
    "lng": -75.074,
    "state_id": "PA"
  },
  {
    "zip": 19138,
    "lat": 40.05611,
    "lng": -75.15901,
    "state_id": "PA"
  },
  {
    "zip": 19139,
    "lat": 39.96144,
    "lng": -75.22981,
    "state_id": "PA"
  },
  {
    "zip": 19140,
    "lat": 40.01241,
    "lng": -75.14508,
    "state_id": "PA"
  },
  {
    "zip": 19141,
    "lat": 40.03757,
    "lng": -75.14578,
    "state_id": "PA"
  },
  {
    "zip": 19142,
    "lat": 39.92272,
    "lng": -75.23414,
    "state_id": "PA"
  },
  {
    "zip": 19143,
    "lat": 39.94289,
    "lng": -75.22636,
    "state_id": "PA"
  },
  {
    "zip": 19144,
    "lat": 40.03311,
    "lng": -75.17495,
    "state_id": "PA"
  },
  {
    "zip": 19145,
    "lat": 39.9149,
    "lng": -75.1921,
    "state_id": "PA"
  },
  {
    "zip": 19146,
    "lat": 39.9394,
    "lng": -75.18353,
    "state_id": "PA"
  },
  {
    "zip": 19147,
    "lat": 39.93585,
    "lng": -75.15224,
    "state_id": "PA"
  },
  {
    "zip": 19148,
    "lat": 39.91162,
    "lng": -75.15143,
    "state_id": "PA"
  },
  {
    "zip": 19149,
    "lat": 40.03772,
    "lng": -75.06572,
    "state_id": "PA"
  },
  {
    "zip": 19150,
    "lat": 40.07255,
    "lng": -75.17183,
    "state_id": "PA"
  },
  {
    "zip": 19151,
    "lat": 39.97561,
    "lng": -75.25622,
    "state_id": "PA"
  },
  {
    "zip": 19152,
    "lat": 40.0607,
    "lng": -75.04673,
    "state_id": "PA"
  },
  {
    "zip": 19153,
    "lat": 39.89333,
    "lng": -75.22832,
    "state_id": "PA"
  },
  {
    "zip": 19154,
    "lat": 40.09506,
    "lng": -74.98161,
    "state_id": "PA"
  },
  {
    "zip": 19301,
    "lat": 40.04115,
    "lng": -75.48032,
    "state_id": "PA"
  },
  {
    "zip": 19310,
    "lat": 39.93546,
    "lng": -75.96963,
    "state_id": "PA"
  },
  {
    "zip": 19311,
    "lat": 39.82265,
    "lng": -75.76893,
    "state_id": "PA"
  },
  {
    "zip": 19312,
    "lat": 40.03239,
    "lng": -75.45165,
    "state_id": "PA"
  },
  {
    "zip": 19316,
    "lat": 40.05543,
    "lng": -75.83641,
    "state_id": "PA"
  },
  {
    "zip": 19317,
    "lat": 39.85766,
    "lng": -75.6017,
    "state_id": "PA"
  },
  {
    "zip": 19319,
    "lat": 39.92825,
    "lng": -75.52054,
    "state_id": "PA"
  },
  {
    "zip": 19320,
    "lat": 39.96904,
    "lng": -75.83098,
    "state_id": "PA"
  },
  {
    "zip": 19330,
    "lat": 39.87242,
    "lng": -75.9165,
    "state_id": "PA"
  },
  {
    "zip": 19333,
    "lat": 40.04109,
    "lng": -75.42325,
    "state_id": "PA"
  },
  {
    "zip": 19335,
    "lat": 40.02282,
    "lng": -75.72018,
    "state_id": "PA"
  },
  {
    "zip": 19341,
    "lat": 40.04104,
    "lng": -75.63669,
    "state_id": "PA"
  },
  {
    "zip": 19342,
    "lat": 39.90579,
    "lng": -75.49672,
    "state_id": "PA"
  },
  {
    "zip": 19343,
    "lat": 40.1014,
    "lng": -75.75458,
    "state_id": "PA"
  },
  {
    "zip": 19344,
    "lat": 40.08403,
    "lng": -75.88149,
    "state_id": "PA"
  },
  {
    "zip": 19345,
    "lat": 40.0294,
    "lng": -75.56539,
    "state_id": "PA"
  },
  {
    "zip": 19348,
    "lat": 39.86726,
    "lng": -75.71376,
    "state_id": "PA"
  },
  {
    "zip": 19350,
    "lat": 39.76322,
    "lng": -75.7947,
    "state_id": "PA"
  },
  {
    "zip": 19352,
    "lat": 39.77689,
    "lng": -75.8893,
    "state_id": "PA"
  },
  {
    "zip": 19355,
    "lat": 40.04684,
    "lng": -75.53098,
    "state_id": "PA"
  },
  {
    "zip": 19358,
    "lat": 39.9617,
    "lng": -75.80273,
    "state_id": "PA"
  },
  {
    "zip": 19362,
    "lat": 39.7525,
    "lng": -76.06797,
    "state_id": "PA"
  },
  {
    "zip": 19363,
    "lat": 39.78697,
    "lng": -75.97447,
    "state_id": "PA"
  },
  {
    "zip": 19365,
    "lat": 39.96409,
    "lng": -75.92512,
    "state_id": "PA"
  },
  {
    "zip": 19367,
    "lat": 39.96298,
    "lng": -75.88401,
    "state_id": "PA"
  },
  {
    "zip": 19372,
    "lat": 39.99844,
    "lng": -75.75887,
    "state_id": "PA"
  },
  {
    "zip": 19373,
    "lat": 39.90249,
    "lng": -75.53387,
    "state_id": "PA"
  },
  {
    "zip": 19374,
    "lat": 39.83343,
    "lng": -75.75329,
    "state_id": "PA"
  },
  {
    "zip": 19375,
    "lat": 39.90044,
    "lng": -75.74032,
    "state_id": "PA"
  },
  {
    "zip": 19380,
    "lat": 39.98485,
    "lng": -75.60717,
    "state_id": "PA"
  },
  {
    "zip": 19382,
    "lat": 39.92809,
    "lng": -75.6123,
    "state_id": "PA"
  },
  {
    "zip": 19383,
    "lat": 39.95163,
    "lng": -75.60163,
    "state_id": "PA"
  },
  {
    "zip": 19390,
    "lat": 39.8329,
    "lng": -75.84009,
    "state_id": "PA"
  },
  {
    "zip": 19401,
    "lat": 40.13038,
    "lng": -75.33136,
    "state_id": "PA"
  },
  {
    "zip": 19403,
    "lat": 40.14964,
    "lng": -75.37966,
    "state_id": "PA"
  },
  {
    "zip": 19405,
    "lat": 40.10382,
    "lng": -75.34071,
    "state_id": "PA"
  },
  {
    "zip": 19406,
    "lat": 40.09442,
    "lng": -75.38244,
    "state_id": "PA"
  },
  {
    "zip": 19422,
    "lat": 40.15734,
    "lng": -75.27722,
    "state_id": "PA"
  },
  {
    "zip": 19425,
    "lat": 40.10221,
    "lng": -75.64641,
    "state_id": "PA"
  },
  {
    "zip": 19426,
    "lat": 40.19137,
    "lng": -75.43729,
    "state_id": "PA"
  },
  {
    "zip": 19428,
    "lat": 40.08044,
    "lng": -75.30085,
    "state_id": "PA"
  },
  {
    "zip": 19435,
    "lat": 40.32745,
    "lng": -75.56918,
    "state_id": "PA"
  },
  {
    "zip": 19436,
    "lat": 40.20152,
    "lng": -75.247,
    "state_id": "PA"
  },
  {
    "zip": 19437,
    "lat": 40.18241,
    "lng": -75.2586,
    "state_id": "PA"
  },
  {
    "zip": 19438,
    "lat": 40.26979,
    "lng": -75.39513,
    "state_id": "PA"
  },
  {
    "zip": 19440,
    "lat": 40.28646,
    "lng": -75.2895,
    "state_id": "PA"
  },
  {
    "zip": 19442,
    "lat": 40.12953,
    "lng": -75.58314,
    "state_id": "PA"
  },
  {
    "zip": 19444,
    "lat": 40.08782,
    "lng": -75.25319,
    "state_id": "PA"
  },
  {
    "zip": 19446,
    "lat": 40.23199,
    "lng": -75.30376,
    "state_id": "PA"
  },
  {
    "zip": 19453,
    "lat": 40.13916,
    "lng": -75.50156,
    "state_id": "PA"
  },
  {
    "zip": 19454,
    "lat": 40.22346,
    "lng": -75.24321,
    "state_id": "PA"
  },
  {
    "zip": 19456,
    "lat": 40.13331,
    "lng": -75.46104,
    "state_id": "PA"
  },
  {
    "zip": 19457,
    "lat": 40.20466,
    "lng": -75.58813,
    "state_id": "PA"
  },
  {
    "zip": 19460,
    "lat": 40.12704,
    "lng": -75.53008,
    "state_id": "PA"
  },
  {
    "zip": 19462,
    "lat": 40.11552,
    "lng": -75.28382,
    "state_id": "PA"
  },
  {
    "zip": 19464,
    "lat": 40.25886,
    "lng": -75.61574,
    "state_id": "PA"
  },
  {
    "zip": 19465,
    "lat": 40.19855,
    "lng": -75.67292,
    "state_id": "PA"
  },
  {
    "zip": 19468,
    "lat": 40.20755,
    "lng": -75.53285,
    "state_id": "PA"
  },
  {
    "zip": 19472,
    "lat": 40.33695,
    "lng": -75.57379,
    "state_id": "PA"
  },
  {
    "zip": 19473,
    "lat": 40.25899,
    "lng": -75.47484,
    "state_id": "PA"
  },
  {
    "zip": 19474,
    "lat": 40.22353,
    "lng": -75.40403,
    "state_id": "PA"
  },
  {
    "zip": 19475,
    "lat": 40.17046,
    "lng": -75.60067,
    "state_id": "PA"
  },
  {
    "zip": 19477,
    "lat": 40.1836,
    "lng": -75.23319,
    "state_id": "PA"
  },
  {
    "zip": 19492,
    "lat": 40.28555,
    "lng": -75.49204,
    "state_id": "PA"
  },
  {
    "zip": 19501,
    "lat": 40.24277,
    "lng": -76.05842,
    "state_id": "PA"
  },
  {
    "zip": 19503,
    "lat": 40.40504,
    "lng": -75.57682,
    "state_id": "PA"
  },
  {
    "zip": 19504,
    "lat": 40.40704,
    "lng": -75.58838,
    "state_id": "PA"
  },
  {
    "zip": 19505,
    "lat": 40.38151,
    "lng": -75.62132,
    "state_id": "PA"
  },
  {
    "zip": 19506,
    "lat": 40.45959,
    "lng": -76.12575,
    "state_id": "PA"
  },
  {
    "zip": 19507,
    "lat": 40.49175,
    "lng": -76.28584,
    "state_id": "PA"
  },
  {
    "zip": 19508,
    "lat": 40.26378,
    "lng": -75.8416,
    "state_id": "PA"
  },
  {
    "zip": 19510,
    "lat": 40.44782,
    "lng": -75.88259,
    "state_id": "PA"
  },
  {
    "zip": 19511,
    "lat": 40.48469,
    "lng": -75.74379,
    "state_id": "PA"
  },
  {
    "zip": 19512,
    "lat": 40.34849,
    "lng": -75.67943,
    "state_id": "PA"
  },
  {
    "zip": 19518,
    "lat": 40.26888,
    "lng": -75.75305,
    "state_id": "PA"
  },
  {
    "zip": 19519,
    "lat": 40.32031,
    "lng": -75.73344,
    "state_id": "PA"
  },
  {
    "zip": 19520,
    "lat": 40.16253,
    "lng": -75.79711,
    "state_id": "PA"
  },
  {
    "zip": 19522,
    "lat": 40.44729,
    "lng": -75.81851,
    "state_id": "PA"
  },
  {
    "zip": 19523,
    "lat": 40.20269,
    "lng": -75.84566,
    "state_id": "PA"
  },
  {
    "zip": 19525,
    "lat": 40.3076,
    "lng": -75.58676,
    "state_id": "PA"
  },
  {
    "zip": 19526,
    "lat": 40.54661,
    "lng": -75.99626,
    "state_id": "PA"
  },
  {
    "zip": 19529,
    "lat": 40.63128,
    "lng": -75.8646,
    "state_id": "PA"
  },
  {
    "zip": 19530,
    "lat": 40.53822,
    "lng": -75.77793,
    "state_id": "PA"
  },
  {
    "zip": 19533,
    "lat": 40.42404,
    "lng": -75.98742,
    "state_id": "PA"
  },
  {
    "zip": 19534,
    "lat": 40.57334,
    "lng": -75.87452,
    "state_id": "PA"
  },
  {
    "zip": 19535,
    "lat": 40.33994,
    "lng": -75.80406,
    "state_id": "PA"
  },
  {
    "zip": 19536,
    "lat": 40.48043,
    "lng": -75.75882,
    "state_id": "PA"
  },
  {
    "zip": 19538,
    "lat": 40.54701,
    "lng": -75.70038,
    "state_id": "PA"
  },
  {
    "zip": 19539,
    "lat": 40.48988,
    "lng": -75.68948,
    "state_id": "PA"
  },
  {
    "zip": 19540,
    "lat": 40.23692,
    "lng": -75.96612,
    "state_id": "PA"
  },
  {
    "zip": 19541,
    "lat": 40.47989,
    "lng": -76.02544,
    "state_id": "PA"
  },
  {
    "zip": 19543,
    "lat": 40.17118,
    "lng": -75.89241,
    "state_id": "PA"
  },
  {
    "zip": 19544,
    "lat": 40.41714,
    "lng": -76.29612,
    "state_id": "PA"
  },
  {
    "zip": 19545,
    "lat": 40.34153,
    "lng": -75.62407,
    "state_id": "PA"
  },
  {
    "zip": 19547,
    "lat": 40.38112,
    "lng": -75.76542,
    "state_id": "PA"
  },
  {
    "zip": 19549,
    "lat": 40.58455,
    "lng": -76.02102,
    "state_id": "PA"
  },
  {
    "zip": 19550,
    "lat": 40.45574,
    "lng": -76.2507,
    "state_id": "PA"
  },
  {
    "zip": 19551,
    "lat": 40.35953,
    "lng": -76.14055,
    "state_id": "PA"
  },
  {
    "zip": 19554,
    "lat": 40.51284,
    "lng": -76.10386,
    "state_id": "PA"
  },
  {
    "zip": 19555,
    "lat": 40.49392,
    "lng": -75.95131,
    "state_id": "PA"
  },
  {
    "zip": 19559,
    "lat": 40.49444,
    "lng": -76.18254,
    "state_id": "PA"
  },
  {
    "zip": 19560,
    "lat": 40.40346,
    "lng": -75.89416,
    "state_id": "PA"
  },
  {
    "zip": 19562,
    "lat": 40.50497,
    "lng": -75.70237,
    "state_id": "PA"
  },
  {
    "zip": 19564,
    "lat": 40.52158,
    "lng": -75.87444,
    "state_id": "PA"
  },
  {
    "zip": 19565,
    "lat": 40.34286,
    "lng": -76.08641,
    "state_id": "PA"
  },
  {
    "zip": 19567,
    "lat": 40.39342,
    "lng": -76.21164,
    "state_id": "PA"
  },
  {
    "zip": 19601,
    "lat": 40.35261,
    "lng": -75.94103,
    "state_id": "PA"
  },
  {
    "zip": 19602,
    "lat": 40.32862,
    "lng": -75.91455,
    "state_id": "PA"
  },
  {
    "zip": 19604,
    "lat": 40.35756,
    "lng": -75.91062,
    "state_id": "PA"
  },
  {
    "zip": 19605,
    "lat": 40.39827,
    "lng": -75.94362,
    "state_id": "PA"
  },
  {
    "zip": 19606,
    "lat": 40.33712,
    "lng": -75.85738,
    "state_id": "PA"
  },
  {
    "zip": 19607,
    "lat": 40.29164,
    "lng": -75.94441,
    "state_id": "PA"
  },
  {
    "zip": 19608,
    "lat": 40.31133,
    "lng": -76.03456,
    "state_id": "PA"
  },
  {
    "zip": 19609,
    "lat": 40.32801,
    "lng": -75.9971,
    "state_id": "PA"
  },
  {
    "zip": 19610,
    "lat": 40.34005,
    "lng": -75.97604,
    "state_id": "PA"
  },
  {
    "zip": 19611,
    "lat": 40.32476,
    "lng": -75.94098,
    "state_id": "PA"
  },
  {
    "zip": 2802,
    "lat": 41.95195,
    "lng": -71.45534,
    "state_id": "RI"
  },
  {
    "zip": 2804,
    "lat": 41.43188,
    "lng": -71.77236,
    "state_id": "RI"
  },
  {
    "zip": 2806,
    "lat": 41.74438,
    "lng": -71.31461,
    "state_id": "RI"
  },
  {
    "zip": 2807,
    "lat": 41.17762,
    "lng": -71.57854,
    "state_id": "RI"
  },
  {
    "zip": 2808,
    "lat": 41.40855,
    "lng": -71.74867,
    "state_id": "RI"
  },
  {
    "zip": 2809,
    "lat": 41.68271,
    "lng": -71.2694,
    "state_id": "RI"
  },
  {
    "zip": 2812,
    "lat": 41.47768,
    "lng": -71.65437,
    "state_id": "RI"
  },
  {
    "zip": 2813,
    "lat": 41.39727,
    "lng": -71.67017,
    "state_id": "RI"
  },
  {
    "zip": 2814,
    "lat": 41.89875,
    "lng": -71.69752,
    "state_id": "RI"
  },
  {
    "zip": 2815,
    "lat": 41.77305,
    "lng": -71.65833,
    "state_id": "RI"
  },
  {
    "zip": 2816,
    "lat": 41.69086,
    "lng": -71.61977,
    "state_id": "RI"
  },
  {
    "zip": 2817,
    "lat": 41.62906,
    "lng": -71.6675,
    "state_id": "RI"
  },
  {
    "zip": 2818,
    "lat": 41.64311,
    "lng": -71.49293,
    "state_id": "RI"
  },
  {
    "zip": 2822,
    "lat": 41.56724,
    "lng": -71.63243,
    "state_id": "RI"
  },
  {
    "zip": 2825,
    "lat": 41.78117,
    "lng": -71.72612,
    "state_id": "RI"
  },
  {
    "zip": 2826,
    "lat": 41.97399,
    "lng": -71.63706,
    "state_id": "RI"
  },
  {
    "zip": 2827,
    "lat": 41.69785,
    "lng": -71.73955,
    "state_id": "RI"
  },
  {
    "zip": 2828,
    "lat": 41.87799,
    "lng": -71.55938,
    "state_id": "RI"
  },
  {
    "zip": 2830,
    "lat": 41.98212,
    "lng": -71.64988,
    "state_id": "RI"
  },
  {
    "zip": 2831,
    "lat": 41.75264,
    "lng": -71.58911,
    "state_id": "RI"
  },
  {
    "zip": 2832,
    "lat": 41.50835,
    "lng": -71.72597,
    "state_id": "RI"
  },
  {
    "zip": 2833,
    "lat": 41.48737,
    "lng": -71.77825,
    "state_id": "RI"
  },
  {
    "zip": 2835,
    "lat": 41.51497,
    "lng": -71.3771,
    "state_id": "RI"
  },
  {
    "zip": 2836,
    "lat": 41.45232,
    "lng": -71.62048,
    "state_id": "RI"
  },
  {
    "zip": 2837,
    "lat": 41.51523,
    "lng": -71.16455,
    "state_id": "RI"
  },
  {
    "zip": 2838,
    "lat": 41.96513,
    "lng": -71.47617,
    "state_id": "RI"
  },
  {
    "zip": 2839,
    "lat": 41.94292,
    "lng": -71.64314,
    "state_id": "RI"
  },
  {
    "zip": 2840,
    "lat": 41.47775,
    "lng": -71.32036,
    "state_id": "RI"
  },
  {
    "zip": 2841,
    "lat": 41.51334,
    "lng": -71.32381,
    "state_id": "RI"
  },
  {
    "zip": 2842,
    "lat": 41.51735,
    "lng": -71.2771,
    "state_id": "RI"
  },
  {
    "zip": 2852,
    "lat": 41.58765,
    "lng": -71.46086,
    "state_id": "RI"
  },
  {
    "zip": 2857,
    "lat": 41.82934,
    "lng": -71.63203,
    "state_id": "RI"
  },
  {
    "zip": 2858,
    "lat": 41.96346,
    "lng": -71.64831,
    "state_id": "RI"
  },
  {
    "zip": 2859,
    "lat": 41.96667,
    "lng": -71.74516,
    "state_id": "RI"
  },
  {
    "zip": 2860,
    "lat": 41.87002,
    "lng": -71.3883,
    "state_id": "RI"
  },
  {
    "zip": 2861,
    "lat": 41.87853,
    "lng": -71.35227,
    "state_id": "RI"
  },
  {
    "zip": 2863,
    "lat": 41.89005,
    "lng": -71.39344,
    "state_id": "RI"
  },
  {
    "zip": 2864,
    "lat": 41.97039,
    "lng": -71.41978,
    "state_id": "RI"
  },
  {
    "zip": 2865,
    "lat": 41.91265,
    "lng": -71.44847,
    "state_id": "RI"
  },
  {
    "zip": 2871,
    "lat": 41.58391,
    "lng": -71.25635,
    "state_id": "RI"
  },
  {
    "zip": 2872,
    "lat": 41.61718,
    "lng": -71.32886,
    "state_id": "RI"
  },
  {
    "zip": 2873,
    "lat": 41.53107,
    "lng": -71.77576,
    "state_id": "RI"
  },
  {
    "zip": 2874,
    "lat": 41.51543,
    "lng": -71.46878,
    "state_id": "RI"
  },
  {
    "zip": 2875,
    "lat": 41.45557,
    "lng": -71.63813,
    "state_id": "RI"
  },
  {
    "zip": 2876,
    "lat": 41.99625,
    "lng": -71.58169,
    "state_id": "RI"
  },
  {
    "zip": 2878,
    "lat": 41.60908,
    "lng": -71.1741,
    "state_id": "RI"
  },
  {
    "zip": 2879,
    "lat": 41.4267,
    "lng": -71.53625,
    "state_id": "RI"
  },
  {
    "zip": 2881,
    "lat": 41.47893,
    "lng": -71.52341,
    "state_id": "RI"
  },
  {
    "zip": 2882,
    "lat": 41.42484,
    "lng": -71.46856,
    "state_id": "RI"
  },
  {
    "zip": 2885,
    "lat": 41.72826,
    "lng": -71.26282,
    "state_id": "RI"
  },
  {
    "zip": 2886,
    "lat": 41.70572,
    "lng": -71.45857,
    "state_id": "RI"
  },
  {
    "zip": 2888,
    "lat": 41.74861,
    "lng": -71.41036,
    "state_id": "RI"
  },
  {
    "zip": 2889,
    "lat": 41.7057,
    "lng": -71.39206,
    "state_id": "RI"
  },
  {
    "zip": 2891,
    "lat": 41.36026,
    "lng": -71.79357,
    "state_id": "RI"
  },
  {
    "zip": 2892,
    "lat": 41.49598,
    "lng": -71.59689,
    "state_id": "RI"
  },
  {
    "zip": 2893,
    "lat": 41.69855,
    "lng": -71.51538,
    "state_id": "RI"
  },
  {
    "zip": 2894,
    "lat": 41.44726,
    "lng": -71.70056,
    "state_id": "RI"
  },
  {
    "zip": 2895,
    "lat": 42.00101,
    "lng": -71.49934,
    "state_id": "RI"
  },
  {
    "zip": 2896,
    "lat": 41.97241,
    "lng": -71.5511,
    "state_id": "RI"
  },
  {
    "zip": 2898,
    "lat": 41.51526,
    "lng": -71.6757,
    "state_id": "RI"
  },
  {
    "zip": 2903,
    "lat": 41.81805,
    "lng": -71.40901,
    "state_id": "RI"
  },
  {
    "zip": 2904,
    "lat": 41.8604,
    "lng": -71.43538,
    "state_id": "RI"
  },
  {
    "zip": 2905,
    "lat": 41.78722,
    "lng": -71.39886,
    "state_id": "RI"
  },
  {
    "zip": 2906,
    "lat": 41.83917,
    "lng": -71.38994,
    "state_id": "RI"
  },
  {
    "zip": 2907,
    "lat": 41.79828,
    "lng": -71.4255,
    "state_id": "RI"
  },
  {
    "zip": 2908,
    "lat": 41.83912,
    "lng": -71.43687,
    "state_id": "RI"
  },
  {
    "zip": 2909,
    "lat": 41.82025,
    "lng": -71.45146,
    "state_id": "RI"
  },
  {
    "zip": 2910,
    "lat": 41.77564,
    "lng": -71.43574,
    "state_id": "RI"
  },
  {
    "zip": 2911,
    "lat": 41.85475,
    "lng": -71.47355,
    "state_id": "RI"
  },
  {
    "zip": 2912,
    "lat": 41.82561,
    "lng": -71.40224,
    "state_id": "RI"
  },
  {
    "zip": 2914,
    "lat": 41.81371,
    "lng": -71.36504,
    "state_id": "RI"
  },
  {
    "zip": 2915,
    "lat": 41.77759,
    "lng": -71.35015,
    "state_id": "RI"
  },
  {
    "zip": 2916,
    "lat": 41.84456,
    "lng": -71.35203,
    "state_id": "RI"
  },
  {
    "zip": 2917,
    "lat": 41.90647,
    "lng": -71.52459,
    "state_id": "RI"
  },
  {
    "zip": 2919,
    "lat": 41.82736,
    "lng": -71.52001,
    "state_id": "RI"
  },
  {
    "zip": 2920,
    "lat": 41.76743,
    "lng": -71.46599,
    "state_id": "RI"
  },
  {
    "zip": 2921,
    "lat": 41.76211,
    "lng": -71.51725,
    "state_id": "RI"
  },
  {
    "zip": 29001,
    "lat": 33.7835,
    "lng": -80.17252,
    "state_id": "SC"
  },
  {
    "zip": 29003,
    "lat": 33.24056,
    "lng": -81.01545,
    "state_id": "SC"
  },
  {
    "zip": 29006,
    "lat": 33.87029,
    "lng": -81.55054,
    "state_id": "SC"
  },
  {
    "zip": 29009,
    "lat": 34.43983,
    "lng": -80.37879,
    "state_id": "SC"
  },
  {
    "zip": 29010,
    "lat": 34.21604,
    "lng": -80.27023,
    "state_id": "SC"
  },
  {
    "zip": 29014,
    "lat": 34.54461,
    "lng": -81.13436,
    "state_id": "SC"
  },
  {
    "zip": 29015,
    "lat": 34.43967,
    "lng": -81.33699,
    "state_id": "SC"
  },
  {
    "zip": 29016,
    "lat": 34.20244,
    "lng": -80.99575,
    "state_id": "SC"
  },
  {
    "zip": 29018,
    "lat": 33.34524,
    "lng": -80.64534,
    "state_id": "SC"
  },
  {
    "zip": 29020,
    "lat": 34.31226,
    "lng": -80.59702,
    "state_id": "SC"
  },
  {
    "zip": 29030,
    "lat": 33.58113,
    "lng": -80.65351,
    "state_id": "SC"
  },
  {
    "zip": 29031,
    "lat": 34.60413,
    "lng": -81.43241,
    "state_id": "SC"
  },
  {
    "zip": 29032,
    "lat": 34.34083,
    "lng": -80.45555,
    "state_id": "SC"
  },
  {
    "zip": 29033,
    "lat": 33.95661,
    "lng": -81.0578,
    "state_id": "SC"
  },
  {
    "zip": 29036,
    "lat": 34.1329,
    "lng": -81.33686,
    "state_id": "SC"
  },
  {
    "zip": 29037,
    "lat": 34.18558,
    "lng": -81.86437,
    "state_id": "SC"
  },
  {
    "zip": 29038,
    "lat": 33.37278,
    "lng": -80.98135,
    "state_id": "SC"
  },
  {
    "zip": 29039,
    "lat": 33.41513,
    "lng": -80.9076,
    "state_id": "SC"
  },
  {
    "zip": 29040,
    "lat": 34.0593,
    "lng": -80.43427,
    "state_id": "SC"
  },
  {
    "zip": 29042,
    "lat": 33.30999,
    "lng": -81.15072,
    "state_id": "SC"
  },
  {
    "zip": 29044,
    "lat": 33.93088,
    "lng": -80.69349,
    "state_id": "SC"
  },
  {
    "zip": 29045,
    "lat": 34.18243,
    "lng": -80.8099,
    "state_id": "SC"
  },
  {
    "zip": 29046,
    "lat": 34.10827,
    "lng": -80.15832,
    "state_id": "SC"
  },
  {
    "zip": 29047,
    "lat": 33.53362,
    "lng": -80.58157,
    "state_id": "SC"
  },
  {
    "zip": 29048,
    "lat": 33.38888,
    "lng": -80.29595,
    "state_id": "SC"
  },
  {
    "zip": 29051,
    "lat": 33.86445,
    "lng": -80.12761,
    "state_id": "SC"
  },
  {
    "zip": 29052,
    "lat": 33.81144,
    "lng": -80.73851,
    "state_id": "SC"
  },
  {
    "zip": 29053,
    "lat": 33.82734,
    "lng": -81.08767,
    "state_id": "SC"
  },
  {
    "zip": 29054,
    "lat": 33.94214,
    "lng": -81.38044,
    "state_id": "SC"
  },
  {
    "zip": 29055,
    "lat": 34.576,
    "lng": -80.91757,
    "state_id": "SC"
  },
  {
    "zip": 29056,
    "lat": 33.60516,
    "lng": -79.99465,
    "state_id": "SC"
  },
  {
    "zip": 29058,
    "lat": 34.56391,
    "lng": -80.73586,
    "state_id": "SC"
  },
  {
    "zip": 29059,
    "lat": 33.32915,
    "lng": -80.4176,
    "state_id": "SC"
  },
  {
    "zip": 29061,
    "lat": 33.89818,
    "lng": -80.85035,
    "state_id": "SC"
  },
  {
    "zip": 29062,
    "lat": 33.99474,
    "lng": -80.60011,
    "state_id": "SC"
  },
  {
    "zip": 29063,
    "lat": 34.14018,
    "lng": -81.20556,
    "state_id": "SC"
  },
  {
    "zip": 29065,
    "lat": 34.28519,
    "lng": -81.29356,
    "state_id": "SC"
  },
  {
    "zip": 29067,
    "lat": 34.54885,
    "lng": -80.5322,
    "state_id": "SC"
  },
  {
    "zip": 29069,
    "lat": 34.1862,
    "lng": -80.09627,
    "state_id": "SC"
  },
  {
    "zip": 29070,
    "lat": 33.91763,
    "lng": -81.45613,
    "state_id": "SC"
  },
  {
    "zip": 29072,
    "lat": 34.00046,
    "lng": -81.27544,
    "state_id": "SC"
  },
  {
    "zip": 29073,
    "lat": 33.8937,
    "lng": -81.23818,
    "state_id": "SC"
  },
  {
    "zip": 29074,
    "lat": 34.44965,
    "lng": -80.81551,
    "state_id": "SC"
  },
  {
    "zip": 29075,
    "lat": 34.20037,
    "lng": -81.3739,
    "state_id": "SC"
  },
  {
    "zip": 29078,
    "lat": 34.18347,
    "lng": -80.6991,
    "state_id": "SC"
  },
  {
    "zip": 29079,
    "lat": 34.2864,
    "lng": -80.1137,
    "state_id": "SC"
  },
  {
    "zip": 29080,
    "lat": 34.01121,
    "lng": -80.08539,
    "state_id": "SC"
  },
  {
    "zip": 29081,
    "lat": 33.0921,
    "lng": -81.04244,
    "state_id": "SC"
  },
  {
    "zip": 29082,
    "lat": 33.03773,
    "lng": -80.94174,
    "state_id": "SC"
  },
  {
    "zip": 29101,
    "lat": 34.46022,
    "lng": -80.24829,
    "state_id": "SC"
  },
  {
    "zip": 29102,
    "lat": 33.64506,
    "lng": -80.19125,
    "state_id": "SC"
  },
  {
    "zip": 29104,
    "lat": 33.99854,
    "lng": -80.21791,
    "state_id": "SC"
  },
  {
    "zip": 29105,
    "lat": 33.80843,
    "lng": -81.59564,
    "state_id": "SC"
  },
  {
    "zip": 29107,
    "lat": 33.52866,
    "lng": -81.1239,
    "state_id": "SC"
  },
  {
    "zip": 29108,
    "lat": 34.29876,
    "lng": -81.62475,
    "state_id": "SC"
  },
  {
    "zip": 29111,
    "lat": 33.78531,
    "lng": -80.01233,
    "state_id": "SC"
  },
  {
    "zip": 29112,
    "lat": 33.63031,
    "lng": -81.08273,
    "state_id": "SC"
  },
  {
    "zip": 29113,
    "lat": 33.43622,
    "lng": -81.13227,
    "state_id": "SC"
  },
  {
    "zip": 29114,
    "lat": 33.9537,
    "lng": -79.93237,
    "state_id": "SC"
  },
  {
    "zip": 29115,
    "lat": 33.4777,
    "lng": -80.84883,
    "state_id": "SC"
  },
  {
    "zip": 29117,
    "lat": 33.49816,
    "lng": -80.84893,
    "state_id": "SC"
  },
  {
    "zip": 29118,
    "lat": 33.57049,
    "lng": -80.8921,
    "state_id": "SC"
  },
  {
    "zip": 29122,
    "lat": 34.24012,
    "lng": -81.32534,
    "state_id": "SC"
  },
  {
    "zip": 29123,
    "lat": 33.76637,
    "lng": -81.25947,
    "state_id": "SC"
  },
  {
    "zip": 29125,
    "lat": 33.69829,
    "lng": -80.46723,
    "state_id": "SC"
  },
  {
    "zip": 29126,
    "lat": 34.32269,
    "lng": -81.4204,
    "state_id": "SC"
  },
  {
    "zip": 29127,
    "lat": 34.16621,
    "lng": -81.51232,
    "state_id": "SC"
  },
  {
    "zip": 29128,
    "lat": 34.09771,
    "lng": -80.53037,
    "state_id": "SC"
  },
  {
    "zip": 29129,
    "lat": 33.78485,
    "lng": -81.67263,
    "state_id": "SC"
  },
  {
    "zip": 29130,
    "lat": 34.32541,
    "lng": -80.89267,
    "state_id": "SC"
  },
  {
    "zip": 29133,
    "lat": 33.35637,
    "lng": -80.81378,
    "state_id": "SC"
  },
  {
    "zip": 29135,
    "lat": 33.68917,
    "lng": -80.78727,
    "state_id": "SC"
  },
  {
    "zip": 29137,
    "lat": 33.60057,
    "lng": -81.32452,
    "state_id": "SC"
  },
  {
    "zip": 29138,
    "lat": 34.04454,
    "lng": -81.77281,
    "state_id": "SC"
  },
  {
    "zip": 29142,
    "lat": 33.46347,
    "lng": -80.52511,
    "state_id": "SC"
  },
  {
    "zip": 29145,
    "lat": 34.22068,
    "lng": -81.77415,
    "state_id": "SC"
  },
  {
    "zip": 29146,
    "lat": 33.50863,
    "lng": -81.29827,
    "state_id": "SC"
  },
  {
    "zip": 29147,
    "lat": 34.0919,
    "lng": -80.9663,
    "state_id": "SC"
  },
  {
    "zip": 29148,
    "lat": 33.53657,
    "lng": -80.34218,
    "state_id": "SC"
  },
  {
    "zip": 29150,
    "lat": 33.8755,
    "lng": -80.35448,
    "state_id": "SC"
  },
  {
    "zip": 29152,
    "lat": 33.97495,
    "lng": -80.47178,
    "state_id": "SC"
  },
  {
    "zip": 29153,
    "lat": 33.96077,
    "lng": -80.30418,
    "state_id": "SC"
  },
  {
    "zip": 29154,
    "lat": 33.87969,
    "lng": -80.4401,
    "state_id": "SC"
  },
  {
    "zip": 29160,
    "lat": 33.74396,
    "lng": -81.04175,
    "state_id": "SC"
  },
  {
    "zip": 29161,
    "lat": 34.10623,
    "lng": -79.94265,
    "state_id": "SC"
  },
  {
    "zip": 29162,
    "lat": 33.88668,
    "lng": -80.01094,
    "state_id": "SC"
  },
  {
    "zip": 29163,
    "lat": 33.42392,
    "lng": -80.4395,
    "state_id": "SC"
  },
  {
    "zip": 29164,
    "lat": 33.65532,
    "lng": -81.42213,
    "state_id": "SC"
  },
  {
    "zip": 29166,
    "lat": 33.90175,
    "lng": -81.70816,
    "state_id": "SC"
  },
  {
    "zip": 29168,
    "lat": 33.84861,
    "lng": -80.55182,
    "state_id": "SC"
  },
  {
    "zip": 29169,
    "lat": 33.9965,
    "lng": -81.09719,
    "state_id": "SC"
  },
  {
    "zip": 29170,
    "lat": 33.93814,
    "lng": -81.14546,
    "state_id": "SC"
  },
  {
    "zip": 29172,
    "lat": 33.90748,
    "lng": -81.0721,
    "state_id": "SC"
  },
  {
    "zip": 29175,
    "lat": 34.44385,
    "lng": -80.59833,
    "state_id": "SC"
  },
  {
    "zip": 29178,
    "lat": 34.49038,
    "lng": -81.6022,
    "state_id": "SC"
  },
  {
    "zip": 29180,
    "lat": 34.36739,
    "lng": -81.09596,
    "state_id": "SC"
  },
  {
    "zip": 29201,
    "lat": 33.98345,
    "lng": -81.02814,
    "state_id": "SC"
  },
  {
    "zip": 29202,
    "lat": 33.99356,
    "lng": -81.031,
    "state_id": "SC"
  },
  {
    "zip": 29203,
    "lat": 34.1034,
    "lng": -81.04355,
    "state_id": "SC"
  },
  {
    "zip": 29204,
    "lat": 34.02924,
    "lng": -81.00065,
    "state_id": "SC"
  },
  {
    "zip": 29205,
    "lat": 33.99009,
    "lng": -80.99729,
    "state_id": "SC"
  },
  {
    "zip": 29206,
    "lat": 34.02828,
    "lng": -80.95838,
    "state_id": "SC"
  },
  {
    "zip": 29207,
    "lat": 34.04138,
    "lng": -80.84042,
    "state_id": "SC"
  },
  {
    "zip": 29208,
    "lat": 33.99824,
    "lng": -81.02793,
    "state_id": "SC"
  },
  {
    "zip": 29209,
    "lat": 33.92407,
    "lng": -80.95328,
    "state_id": "SC"
  },
  {
    "zip": 29210,
    "lat": 34.04612,
    "lng": -81.10661,
    "state_id": "SC"
  },
  {
    "zip": 29212,
    "lat": 34.07791,
    "lng": -81.19934,
    "state_id": "SC"
  },
  {
    "zip": 29223,
    "lat": 34.09243,
    "lng": -80.92143,
    "state_id": "SC"
  },
  {
    "zip": 29225,
    "lat": 33.99619,
    "lng": -81.02504,
    "state_id": "SC"
  },
  {
    "zip": 29229,
    "lat": 34.13881,
    "lng": -80.8892,
    "state_id": "SC"
  },
  {
    "zip": 29301,
    "lat": 34.93486,
    "lng": -82.01161,
    "state_id": "SC"
  },
  {
    "zip": 29302,
    "lat": 34.89502,
    "lng": -81.84322,
    "state_id": "SC"
  },
  {
    "zip": 29303,
    "lat": 34.99339,
    "lng": -81.96734,
    "state_id": "SC"
  },
  {
    "zip": 29306,
    "lat": 34.89363,
    "lng": -81.92285,
    "state_id": "SC"
  },
  {
    "zip": 29307,
    "lat": 34.98388,
    "lng": -81.83124,
    "state_id": "SC"
  },
  {
    "zip": 29316,
    "lat": 35.04116,
    "lng": -81.97461,
    "state_id": "SC"
  },
  {
    "zip": 29320,
    "lat": 34.95865,
    "lng": -81.99355,
    "state_id": "SC"
  },
  {
    "zip": 29321,
    "lat": 34.71768,
    "lng": -81.74227,
    "state_id": "SC"
  },
  {
    "zip": 29322,
    "lat": 35.12353,
    "lng": -82.13043,
    "state_id": "SC"
  },
  {
    "zip": 29323,
    "lat": 35.12929,
    "lng": -81.90853,
    "state_id": "SC"
  },
  {
    "zip": 29324,
    "lat": 34.9838,
    "lng": -81.82664,
    "state_id": "SC"
  },
  {
    "zip": 29325,
    "lat": 34.47227,
    "lng": -81.84268,
    "state_id": "SC"
  },
  {
    "zip": 29329,
    "lat": 34.99371,
    "lng": -81.83749,
    "state_id": "SC"
  },
  {
    "zip": 29330,
    "lat": 35.05434,
    "lng": -81.8049,
    "state_id": "SC"
  },
  {
    "zip": 29331,
    "lat": 34.65049,
    "lng": -81.84842,
    "state_id": "SC"
  },
  {
    "zip": 29332,
    "lat": 34.2828,
    "lng": -81.97031,
    "state_id": "SC"
  },
  {
    "zip": 29333,
    "lat": 34.97116,
    "lng": -81.91021,
    "state_id": "SC"
  },
  {
    "zip": 29334,
    "lat": 34.90538,
    "lng": -82.12596,
    "state_id": "SC"
  },
  {
    "zip": 29335,
    "lat": 34.65756,
    "lng": -81.9067,
    "state_id": "SC"
  },
  {
    "zip": 29338,
    "lat": 35.13552,
    "lng": -82.00154,
    "state_id": "SC"
  },
  {
    "zip": 29340,
    "lat": 34.98191,
    "lng": -81.59387,
    "state_id": "SC"
  },
  {
    "zip": 29341,
    "lat": 35.11761,
    "lng": -81.71316,
    "state_id": "SC"
  },
  {
    "zip": 29346,
    "lat": 34.94515,
    "lng": -81.83643,
    "state_id": "SC"
  },
  {
    "zip": 29349,
    "lat": 35.07022,
    "lng": -82.06702,
    "state_id": "SC"
  },
  {
    "zip": 29351,
    "lat": 34.42171,
    "lng": -81.8097,
    "state_id": "SC"
  },
  {
    "zip": 29353,
    "lat": 34.82895,
    "lng": -81.65842,
    "state_id": "SC"
  },
  {
    "zip": 29355,
    "lat": 34.36269,
    "lng": -81.7638,
    "state_id": "SC"
  },
  {
    "zip": 29356,
    "lat": 35.14389,
    "lng": -82.2637,
    "state_id": "SC"
  },
  {
    "zip": 29360,
    "lat": 34.49665,
    "lng": -82.04671,
    "state_id": "SC"
  },
  {
    "zip": 29364,
    "lat": 34.76885,
    "lng": -81.4681,
    "state_id": "SC"
  },
  {
    "zip": 29365,
    "lat": 34.98309,
    "lng": -82.16856,
    "state_id": "SC"
  },
  {
    "zip": 29368,
    "lat": 35.08175,
    "lng": -81.86314,
    "state_id": "SC"
  },
  {
    "zip": 29369,
    "lat": 34.86455,
    "lng": -82.02147,
    "state_id": "SC"
  },
  {
    "zip": 29370,
    "lat": 34.37085,
    "lng": -81.96724,
    "state_id": "SC"
  },
  {
    "zip": 29372,
    "lat": 34.90581,
    "lng": -81.70871,
    "state_id": "SC"
  },
  {
    "zip": 29373,
    "lat": 34.92236,
    "lng": -81.74637,
    "state_id": "SC"
  },
  {
    "zip": 29374,
    "lat": 34.78551,
    "lng": -81.84919,
    "state_id": "SC"
  },
  {
    "zip": 29375,
    "lat": 34.86114,
    "lng": -82.11379,
    "state_id": "SC"
  },
  {
    "zip": 29376,
    "lat": 34.81528,
    "lng": -81.94932,
    "state_id": "SC"
  },
  {
    "zip": 29377,
    "lat": 34.92932,
    "lng": -82.09673,
    "state_id": "SC"
  },
  {
    "zip": 29378,
    "lat": 34.96697,
    "lng": -81.96874,
    "state_id": "SC"
  },
  {
    "zip": 29379,
    "lat": 34.68075,
    "lng": -81.60943,
    "state_id": "SC"
  },
  {
    "zip": 29384,
    "lat": 34.34491,
    "lng": -82.09153,
    "state_id": "SC"
  },
  {
    "zip": 29385,
    "lat": 34.97338,
    "lng": -82.09968,
    "state_id": "SC"
  },
  {
    "zip": 29388,
    "lat": 34.764,
    "lng": -82.04387,
    "state_id": "SC"
  },
  {
    "zip": 29401,
    "lat": 32.77869,
    "lng": -79.93494,
    "state_id": "SC"
  },
  {
    "zip": 29403,
    "lat": 32.80462,
    "lng": -79.94377,
    "state_id": "SC"
  },
  {
    "zip": 29404,
    "lat": 32.89864,
    "lng": -80.05028,
    "state_id": "SC"
  },
  {
    "zip": 29405,
    "lat": 32.85397,
    "lng": -79.98183,
    "state_id": "SC"
  },
  {
    "zip": 29406,
    "lat": 32.93544,
    "lng": -80.03262,
    "state_id": "SC"
  },
  {
    "zip": 29407,
    "lat": 32.79915,
    "lng": -79.99867,
    "state_id": "SC"
  },
  {
    "zip": 29409,
    "lat": 32.79637,
    "lng": -79.96069,
    "state_id": "SC"
  },
  {
    "zip": 29410,
    "lat": 32.93025,
    "lng": -80.00272,
    "state_id": "SC"
  },
  {
    "zip": 29412,
    "lat": 32.70947,
    "lng": -79.9512,
    "state_id": "SC"
  },
  {
    "zip": 29414,
    "lat": 32.83801,
    "lng": -80.08832,
    "state_id": "SC"
  },
  {
    "zip": 29418,
    "lat": 32.88918,
    "lng": -80.06476,
    "state_id": "SC"
  },
  {
    "zip": 29420,
    "lat": 32.93366,
    "lng": -80.10254,
    "state_id": "SC"
  },
  {
    "zip": 29423,
    "lat": 32.9788,
    "lng": -80.0713,
    "state_id": "SC"
  },
  {
    "zip": 29424,
    "lat": 32.78364,
    "lng": -79.93737,
    "state_id": "SC"
  },
  {
    "zip": 29426,
    "lat": 32.79914,
    "lng": -80.37269,
    "state_id": "SC"
  },
  {
    "zip": 29429,
    "lat": 32.97346,
    "lng": -79.66947,
    "state_id": "SC"
  },
  {
    "zip": 29431,
    "lat": 33.27822,
    "lng": -79.88069,
    "state_id": "SC"
  },
  {
    "zip": 29432,
    "lat": 33.23112,
    "lng": -80.80785,
    "state_id": "SC"
  },
  {
    "zip": 29434,
    "lat": 33.14133,
    "lng": -79.8469,
    "state_id": "SC"
  },
  {
    "zip": 29435,
    "lat": 32.96697,
    "lng": -80.47273,
    "state_id": "SC"
  },
  {
    "zip": 29436,
    "lat": 33.31862,
    "lng": -80.18464,
    "state_id": "SC"
  },
  {
    "zip": 29437,
    "lat": 33.14178,
    "lng": -80.42264,
    "state_id": "SC"
  },
  {
    "zip": 29438,
    "lat": 32.5722,
    "lng": -80.31893,
    "state_id": "SC"
  },
  {
    "zip": 29439,
    "lat": 32.66632,
    "lng": -79.92909,
    "state_id": "SC"
  },
  {
    "zip": 29440,
    "lat": 33.37712,
    "lng": -79.329,
    "state_id": "SC"
  },
  {
    "zip": 29445,
    "lat": 32.99782,
    "lng": -79.99,
    "state_id": "SC"
  },
  {
    "zip": 29446,
    "lat": 32.62839,
    "lng": -80.5454,
    "state_id": "SC"
  },
  {
    "zip": 29448,
    "lat": 33.24786,
    "lng": -80.44803,
    "state_id": "SC"
  },
  {
    "zip": 29449,
    "lat": 32.70259,
    "lng": -80.2865,
    "state_id": "SC"
  },
  {
    "zip": 29450,
    "lat": 33.06496,
    "lng": -79.78053,
    "state_id": "SC"
  },
  {
    "zip": 29451,
    "lat": 32.82154,
    "lng": -79.75349,
    "state_id": "SC"
  },
  {
    "zip": 29452,
    "lat": 32.6804,
    "lng": -80.46305,
    "state_id": "SC"
  },
  {
    "zip": 29453,
    "lat": 33.22213,
    "lng": -79.62823,
    "state_id": "SC"
  },
  {
    "zip": 29455,
    "lat": 32.69435,
    "lng": -80.09021,
    "state_id": "SC"
  },
  {
    "zip": 29456,
    "lat": 32.98657,
    "lng": -80.11402,
    "state_id": "SC"
  },
  {
    "zip": 29458,
    "lat": 33.09891,
    "lng": -79.45896,
    "state_id": "SC"
  },
  {
    "zip": 29461,
    "lat": 33.1587,
    "lng": -80.00009,
    "state_id": "SC"
  },
  {
    "zip": 29464,
    "lat": 32.82038,
    "lng": -79.85636,
    "state_id": "SC"
  },
  {
    "zip": 29466,
    "lat": 32.87848,
    "lng": -79.78989,
    "state_id": "SC"
  },
  {
    "zip": 29468,
    "lat": 33.42264,
    "lng": -80.06469,
    "state_id": "SC"
  },
  {
    "zip": 29469,
    "lat": 33.24879,
    "lng": -80.08945,
    "state_id": "SC"
  },
  {
    "zip": 29470,
    "lat": 32.82219,
    "lng": -80.2511,
    "state_id": "SC"
  },
  {
    "zip": 29471,
    "lat": 33.18537,
    "lng": -80.66859,
    "state_id": "SC"
  },
  {
    "zip": 29472,
    "lat": 33.05707,
    "lng": -80.31845,
    "state_id": "SC"
  },
  {
    "zip": 29474,
    "lat": 32.89678,
    "lng": -80.51733,
    "state_id": "SC"
  },
  {
    "zip": 29475,
    "lat": 32.96021,
    "lng": -80.81786,
    "state_id": "SC"
  },
  {
    "zip": 29477,
    "lat": 33.16744,
    "lng": -80.57062,
    "state_id": "SC"
  },
  {
    "zip": 29479,
    "lat": 33.3799,
    "lng": -79.88696,
    "state_id": "SC"
  },
  {
    "zip": 29481,
    "lat": 33.1135,
    "lng": -80.80305,
    "state_id": "SC"
  },
  {
    "zip": 29482,
    "lat": 32.76856,
    "lng": -79.83545,
    "state_id": "SC"
  },
  {
    "zip": 29483,
    "lat": 33.05135,
    "lng": -80.18747,
    "state_id": "SC"
  },
  {
    "zip": 29485,
    "lat": 32.9526,
    "lng": -80.19348,
    "state_id": "SC"
  },
  {
    "zip": 29487,
    "lat": 32.6569,
    "lng": -80.18477,
    "state_id": "SC"
  },
  {
    "zip": 29488,
    "lat": 32.90658,
    "lng": -80.67209,
    "state_id": "SC"
  },
  {
    "zip": 29492,
    "lat": 32.91379,
    "lng": -79.8824,
    "state_id": "SC"
  },
  {
    "zip": 29493,
    "lat": 33.03413,
    "lng": -80.84286,
    "state_id": "SC"
  },
  {
    "zip": 29501,
    "lat": 34.20619,
    "lng": -79.82544,
    "state_id": "SC"
  },
  {
    "zip": 29505,
    "lat": 34.12309,
    "lng": -79.6892,
    "state_id": "SC"
  },
  {
    "zip": 29506,
    "lat": 34.21532,
    "lng": -79.64682,
    "state_id": "SC"
  },
  {
    "zip": 29510,
    "lat": 33.45796,
    "lng": -79.60342,
    "state_id": "SC"
  },
  {
    "zip": 29511,
    "lat": 33.97729,
    "lng": -79.11497,
    "state_id": "SC"
  },
  {
    "zip": 29512,
    "lat": 34.62635,
    "lng": -79.70984,
    "state_id": "SC"
  },
  {
    "zip": 29516,
    "lat": 34.43761,
    "lng": -79.63013,
    "state_id": "SC"
  },
  {
    "zip": 29518,
    "lat": 33.78862,
    "lng": -79.84798,
    "state_id": "SC"
  },
  {
    "zip": 29519,
    "lat": 34.01865,
    "lng": -79.3598,
    "state_id": "SC"
  },
  {
    "zip": 29520,
    "lat": 34.67955,
    "lng": -79.9272,
    "state_id": "SC"
  },
  {
    "zip": 29525,
    "lat": 34.5573,
    "lng": -79.54112,
    "state_id": "SC"
  },
  {
    "zip": 29526,
    "lat": 33.86374,
    "lng": -78.96948,
    "state_id": "SC"
  },
  {
    "zip": 29527,
    "lat": 33.78271,
    "lng": -79.14732,
    "state_id": "SC"
  },
  {
    "zip": 29530,
    "lat": 33.99134,
    "lng": -79.74202,
    "state_id": "SC"
  },
  {
    "zip": 29532,
    "lat": 34.27887,
    "lng": -79.87248,
    "state_id": "SC"
  },
  {
    "zip": 29536,
    "lat": 34.41899,
    "lng": -79.37304,
    "state_id": "SC"
  },
  {
    "zip": 29540,
    "lat": 34.38512,
    "lng": -79.84007,
    "state_id": "SC"
  },
  {
    "zip": 29541,
    "lat": 34.06523,
    "lng": -79.74326,
    "state_id": "SC"
  },
  {
    "zip": 29543,
    "lat": 34.28761,
    "lng": -79.26593,
    "state_id": "SC"
  },
  {
    "zip": 29544,
    "lat": 34.00019,
    "lng": -79.21547,
    "state_id": "SC"
  },
  {
    "zip": 29545,
    "lat": 34.16239,
    "lng": -78.96558,
    "state_id": "SC"
  },
  {
    "zip": 29546,
    "lat": 33.89264,
    "lng": -79.35226,
    "state_id": "SC"
  },
  {
    "zip": 29547,
    "lat": 34.48799,
    "lng": -79.33339,
    "state_id": "SC"
  },
  {
    "zip": 29550,
    "lat": 34.39887,
    "lng": -80.07734,
    "state_id": "SC"
  },
  {
    "zip": 29554,
    "lat": 33.69803,
    "lng": -79.38,
    "state_id": "SC"
  },
  {
    "zip": 29555,
    "lat": 33.84036,
    "lng": -79.46572,
    "state_id": "SC"
  },
  {
    "zip": 29556,
    "lat": 33.66677,
    "lng": -79.75943,
    "state_id": "SC"
  },
  {
    "zip": 29560,
    "lat": 33.8343,
    "lng": -79.74772,
    "state_id": "SC"
  },
  {
    "zip": 29563,
    "lat": 34.35346,
    "lng": -79.20429,
    "state_id": "SC"
  },
  {
    "zip": 29564,
    "lat": 33.46143,
    "lng": -79.84307,
    "state_id": "SC"
  },
  {
    "zip": 29565,
    "lat": 34.35767,
    "lng": -79.50228,
    "state_id": "SC"
  },
  {
    "zip": 29566,
    "lat": 33.87234,
    "lng": -78.66322,
    "state_id": "SC"
  },
  {
    "zip": 29567,
    "lat": 34.56126,
    "lng": -79.43354,
    "state_id": "SC"
  },
  {
    "zip": 29568,
    "lat": 33.90772,
    "lng": -78.75206,
    "state_id": "SC"
  },
  {
    "zip": 29569,
    "lat": 34.0329,
    "lng": -78.90523,
    "state_id": "SC"
  },
  {
    "zip": 29570,
    "lat": 34.67421,
    "lng": -79.56063,
    "state_id": "SC"
  },
  {
    "zip": 29571,
    "lat": 34.13662,
    "lng": -79.42189,
    "state_id": "SC"
  },
  {
    "zip": 29572,
    "lat": 33.76918,
    "lng": -78.78914,
    "state_id": "SC"
  },
  {
    "zip": 29574,
    "lat": 34.17245,
    "lng": -79.26506,
    "state_id": "SC"
  },
  {
    "zip": 29575,
    "lat": 33.62852,
    "lng": -78.97347,
    "state_id": "SC"
  },
  {
    "zip": 29576,
    "lat": 33.56262,
    "lng": -79.05808,
    "state_id": "SC"
  },
  {
    "zip": 29577,
    "lat": 33.69869,
    "lng": -78.90303,
    "state_id": "SC"
  },
  {
    "zip": 29579,
    "lat": 33.75477,
    "lng": -78.91731,
    "state_id": "SC"
  },
  {
    "zip": 29580,
    "lat": 33.66251,
    "lng": -79.55892,
    "state_id": "SC"
  },
  {
    "zip": 29581,
    "lat": 34.19851,
    "lng": -79.11228,
    "state_id": "SC"
  },
  {
    "zip": 29582,
    "lat": 33.83412,
    "lng": -78.66109,
    "state_id": "SC"
  },
  {
    "zip": 29583,
    "lat": 33.97235,
    "lng": -79.57206,
    "state_id": "SC"
  },
  {
    "zip": 29584,
    "lat": 34.58153,
    "lng": -80.06092,
    "state_id": "SC"
  },
  {
    "zip": 29585,
    "lat": 33.52246,
    "lng": -79.13408,
    "state_id": "SC"
  },
  {
    "zip": 29588,
    "lat": 33.67162,
    "lng": -79.02449,
    "state_id": "SC"
  },
  {
    "zip": 29590,
    "lat": 33.56547,
    "lng": -79.84908,
    "state_id": "SC"
  },
  {
    "zip": 29591,
    "lat": 33.93841,
    "lng": -79.76241,
    "state_id": "SC"
  },
  {
    "zip": 29592,
    "lat": 34.2636,
    "lng": -79.47877,
    "state_id": "SC"
  },
  {
    "zip": 29593,
    "lat": 34.49494,
    "lng": -79.85739,
    "state_id": "SC"
  },
  {
    "zip": 29594,
    "lat": 34.64017,
    "lng": -79.57731,
    "state_id": "SC"
  },
  {
    "zip": 29596,
    "lat": 34.74341,
    "lng": -79.83794,
    "state_id": "SC"
  },
  {
    "zip": 29601,
    "lat": 34.84777,
    "lng": -82.4027,
    "state_id": "SC"
  },
  {
    "zip": 29605,
    "lat": 34.77531,
    "lng": -82.38015,
    "state_id": "SC"
  },
  {
    "zip": 29607,
    "lat": 34.81152,
    "lng": -82.3313,
    "state_id": "SC"
  },
  {
    "zip": 29609,
    "lat": 34.91237,
    "lng": -82.38902,
    "state_id": "SC"
  },
  {
    "zip": 29611,
    "lat": 34.82924,
    "lng": -82.45924,
    "state_id": "SC"
  },
  {
    "zip": 29613,
    "lat": 34.92588,
    "lng": -82.4386,
    "state_id": "SC"
  },
  {
    "zip": 29614,
    "lat": 34.87376,
    "lng": -82.36306,
    "state_id": "SC"
  },
  {
    "zip": 29615,
    "lat": 34.85581,
    "lng": -82.29514,
    "state_id": "SC"
  },
  {
    "zip": 29617,
    "lat": 34.91199,
    "lng": -82.46658,
    "state_id": "SC"
  },
  {
    "zip": 29620,
    "lat": 34.17875,
    "lng": -82.42872,
    "state_id": "SC"
  },
  {
    "zip": 29621,
    "lat": 34.50703,
    "lng": -82.60538,
    "state_id": "SC"
  },
  {
    "zip": 29624,
    "lat": 34.43733,
    "lng": -82.62515,
    "state_id": "SC"
  },
  {
    "zip": 29625,
    "lat": 34.55697,
    "lng": -82.76366,
    "state_id": "SC"
  },
  {
    "zip": 29626,
    "lat": 34.45956,
    "lng": -82.76133,
    "state_id": "SC"
  },
  {
    "zip": 29627,
    "lat": 34.51778,
    "lng": -82.47664,
    "state_id": "SC"
  },
  {
    "zip": 29628,
    "lat": 34.09083,
    "lng": -82.56469,
    "state_id": "SC"
  },
  {
    "zip": 29630,
    "lat": 34.74024,
    "lng": -82.79867,
    "state_id": "SC"
  },
  {
    "zip": 29631,
    "lat": 34.6813,
    "lng": -82.81727,
    "state_id": "SC"
  },
  {
    "zip": 29634,
    "lat": 34.67529,
    "lng": -82.8394,
    "state_id": "SC"
  },
  {
    "zip": 29635,
    "lat": 35.07992,
    "lng": -82.62815,
    "state_id": "SC"
  },
  {
    "zip": 29638,
    "lat": 34.35948,
    "lng": -82.33659,
    "state_id": "SC"
  },
  {
    "zip": 29639,
    "lat": 34.30798,
    "lng": -82.42482,
    "state_id": "SC"
  },
  {
    "zip": 29640,
    "lat": 34.88831,
    "lng": -82.57772,
    "state_id": "SC"
  },
  {
    "zip": 29642,
    "lat": 34.77558,
    "lng": -82.56473,
    "state_id": "SC"
  },
  {
    "zip": 29643,
    "lat": 34.51182,
    "lng": -83.00057,
    "state_id": "SC"
  },
  {
    "zip": 29644,
    "lat": 34.67169,
    "lng": -82.19331,
    "state_id": "SC"
  },
  {
    "zip": 29645,
    "lat": 34.59349,
    "lng": -82.12295,
    "state_id": "SC"
  },
  {
    "zip": 29646,
    "lat": 34.13472,
    "lng": -82.15202,
    "state_id": "SC"
  },
  {
    "zip": 29649,
    "lat": 34.24272,
    "lng": -82.14246,
    "state_id": "SC"
  },
  {
    "zip": 29650,
    "lat": 34.89755,
    "lng": -82.25845,
    "state_id": "SC"
  },
  {
    "zip": 29651,
    "lat": 34.94719,
    "lng": -82.22596,
    "state_id": "SC"
  },
  {
    "zip": 29653,
    "lat": 34.30028,
    "lng": -82.23113,
    "state_id": "SC"
  },
  {
    "zip": 29654,
    "lat": 34.46547,
    "lng": -82.36263,
    "state_id": "SC"
  },
  {
    "zip": 29655,
    "lat": 34.26492,
    "lng": -82.64086,
    "state_id": "SC"
  },
  {
    "zip": 29657,
    "lat": 34.7661,
    "lng": -82.68894,
    "state_id": "SC"
  },
  {
    "zip": 29658,
    "lat": 34.76476,
    "lng": -83.28647,
    "state_id": "SC"
  },
  {
    "zip": 29659,
    "lat": 34.20973,
    "lng": -82.64705,
    "state_id": "SC"
  },
  {
    "zip": 29661,
    "lat": 35.06213,
    "lng": -82.53509,
    "state_id": "SC"
  },
  {
    "zip": 29662,
    "lat": 34.77777,
    "lng": -82.30524,
    "state_id": "SC"
  },
  {
    "zip": 29664,
    "lat": 34.86596,
    "lng": -83.1582,
    "state_id": "SC"
  },
  {
    "zip": 29665,
    "lat": 34.72475,
    "lng": -82.91408,
    "state_id": "SC"
  },
  {
    "zip": 29666,
    "lat": 34.12232,
    "lng": -81.99164,
    "state_id": "SC"
  },
  {
    "zip": 29667,
    "lat": 34.76477,
    "lng": -82.7567,
    "state_id": "SC"
  },
  {
    "zip": 29669,
    "lat": 34.64149,
    "lng": -82.41659,
    "state_id": "SC"
  },
  {
    "zip": 29670,
    "lat": 34.64341,
    "lng": -82.72332,
    "state_id": "SC"
  },
  {
    "zip": 29671,
    "lat": 34.93511,
    "lng": -82.72151,
    "state_id": "SC"
  },
  {
    "zip": 29672,
    "lat": 34.74824,
    "lng": -82.93963,
    "state_id": "SC"
  },
  {
    "zip": 29673,
    "lat": 34.7193,
    "lng": -82.45722,
    "state_id": "SC"
  },
  {
    "zip": 29676,
    "lat": 34.93322,
    "lng": -82.96065,
    "state_id": "SC"
  },
  {
    "zip": 29678,
    "lat": 34.63301,
    "lng": -82.94107,
    "state_id": "SC"
  },
  {
    "zip": 29680,
    "lat": 34.68859,
    "lng": -82.29155,
    "state_id": "SC"
  },
  {
    "zip": 29681,
    "lat": 34.76916,
    "lng": -82.22475,
    "state_id": "SC"
  },
  {
    "zip": 29682,
    "lat": 34.8345,
    "lng": -82.84553,
    "state_id": "SC"
  },
  {
    "zip": 29683,
    "lat": 35.03003,
    "lng": -82.4942,
    "state_id": "SC"
  },
  {
    "zip": 29684,
    "lat": 34.3803,
    "lng": -82.72663,
    "state_id": "SC"
  },
  {
    "zip": 29685,
    "lat": 34.98721,
    "lng": -82.84714,
    "state_id": "SC"
  },
  {
    "zip": 29686,
    "lat": 34.93031,
    "lng": -83.04657,
    "state_id": "SC"
  },
  {
    "zip": 29687,
    "lat": 34.98422,
    "lng": -82.32764,
    "state_id": "SC"
  },
  {
    "zip": 29689,
    "lat": 34.52743,
    "lng": -82.87393,
    "state_id": "SC"
  },
  {
    "zip": 29690,
    "lat": 35.0595,
    "lng": -82.42151,
    "state_id": "SC"
  },
  {
    "zip": 29691,
    "lat": 34.78364,
    "lng": -83.0861,
    "state_id": "SC"
  },
  {
    "zip": 29692,
    "lat": 34.4196,
    "lng": -82.22193,
    "state_id": "SC"
  },
  {
    "zip": 29693,
    "lat": 34.65257,
    "lng": -83.15153,
    "state_id": "SC"
  },
  {
    "zip": 29696,
    "lat": 34.77602,
    "lng": -83.00584,
    "state_id": "SC"
  },
  {
    "zip": 29697,
    "lat": 34.62807,
    "lng": -82.54808,
    "state_id": "SC"
  },
  {
    "zip": 29702,
    "lat": 35.11336,
    "lng": -81.47683,
    "state_id": "SC"
  },
  {
    "zip": 29704,
    "lat": 34.82092,
    "lng": -80.91536,
    "state_id": "SC"
  },
  {
    "zip": 29706,
    "lat": 34.71675,
    "lng": -81.23292,
    "state_id": "SC"
  },
  {
    "zip": 29707,
    "lat": 34.97723,
    "lng": -80.85827,
    "state_id": "SC"
  },
  {
    "zip": 29708,
    "lat": 35.04658,
    "lng": -80.98587,
    "state_id": "SC"
  },
  {
    "zip": 29709,
    "lat": 34.72034,
    "lng": -80.0966,
    "state_id": "SC"
  },
  {
    "zip": 29710,
    "lat": 35.10647,
    "lng": -81.22036,
    "state_id": "SC"
  },
  {
    "zip": 29712,
    "lat": 34.79498,
    "lng": -80.98561,
    "state_id": "SC"
  },
  {
    "zip": 29714,
    "lat": 34.71103,
    "lng": -80.91533,
    "state_id": "SC"
  },
  {
    "zip": 29715,
    "lat": 35.01074,
    "lng": -80.92653,
    "state_id": "SC"
  },
  {
    "zip": 29717,
    "lat": 34.95296,
    "lng": -81.44316,
    "state_id": "SC"
  },
  {
    "zip": 29718,
    "lat": 34.61982,
    "lng": -80.33128,
    "state_id": "SC"
  },
  {
    "zip": 29720,
    "lat": 34.74098,
    "lng": -80.73584,
    "state_id": "SC"
  },
  {
    "zip": 29724,
    "lat": 34.77398,
    "lng": -81.0111,
    "state_id": "SC"
  },
  {
    "zip": 29726,
    "lat": 34.85776,
    "lng": -81.23181,
    "state_id": "SC"
  },
  {
    "zip": 29727,
    "lat": 34.71431,
    "lng": -80.26826,
    "state_id": "SC"
  },
  {
    "zip": 29728,
    "lat": 34.76824,
    "lng": -80.40245,
    "state_id": "SC"
  },
  {
    "zip": 29729,
    "lat": 34.67671,
    "lng": -81.00586,
    "state_id": "SC"
  },
  {
    "zip": 29730,
    "lat": 34.88671,
    "lng": -81.02081,
    "state_id": "SC"
  },
  {
    "zip": 29732,
    "lat": 34.96968,
    "lng": -81.07935,
    "state_id": "SC"
  },
  {
    "zip": 29733,
    "lat": 34.93971,
    "lng": -81.03192,
    "state_id": "SC"
  },
  {
    "zip": 29741,
    "lat": 34.72501,
    "lng": -80.20106,
    "state_id": "SC"
  },
  {
    "zip": 29742,
    "lat": 34.86973,
    "lng": -81.38167,
    "state_id": "SC"
  },
  {
    "zip": 29743,
    "lat": 35.02554,
    "lng": -81.39877,
    "state_id": "SC"
  },
  {
    "zip": 29745,
    "lat": 34.99484,
    "lng": -81.216,
    "state_id": "SC"
  },
  {
    "zip": 29801,
    "lat": 33.58945,
    "lng": -81.69697,
    "state_id": "SC"
  },
  {
    "zip": 29803,
    "lat": 33.42181,
    "lng": -81.68,
    "state_id": "SC"
  },
  {
    "zip": 29805,
    "lat": 33.6474,
    "lng": -81.60525,
    "state_id": "SC"
  },
  {
    "zip": 29809,
    "lat": 33.41822,
    "lng": -81.69171,
    "state_id": "SC"
  },
  {
    "zip": 29810,
    "lat": 32.99533,
    "lng": -81.37633,
    "state_id": "SC"
  },
  {
    "zip": 29812,
    "lat": 33.22683,
    "lng": -81.44135,
    "state_id": "SC"
  },
  {
    "zip": 29816,
    "lat": 33.5005,
    "lng": -81.87341,
    "state_id": "SC"
  },
  {
    "zip": 29817,
    "lat": 33.37057,
    "lng": -81.27283,
    "state_id": "SC"
  },
  {
    "zip": 29819,
    "lat": 34.04502,
    "lng": -82.22691,
    "state_id": "SC"
  },
  {
    "zip": 29821,
    "lat": 33.64381,
    "lng": -82.11184,
    "state_id": "SC"
  },
  {
    "zip": 29824,
    "lat": 33.81939,
    "lng": -81.99726,
    "state_id": "SC"
  },
  {
    "zip": 29826,
    "lat": 33.37214,
    "lng": -81.36382,
    "state_id": "SC"
  },
  {
    "zip": 29827,
    "lat": 32.96459,
    "lng": -81.2533,
    "state_id": "SC"
  },
  {
    "zip": 29828,
    "lat": 33.52282,
    "lng": -81.82839,
    "state_id": "SC"
  },
  {
    "zip": 29829,
    "lat": 33.57393,
    "lng": -81.85362,
    "state_id": "SC"
  },
  {
    "zip": 29831,
    "lat": 33.3112,
    "lng": -81.80288,
    "state_id": "SC"
  },
  {
    "zip": 29832,
    "lat": 33.83962,
    "lng": -81.80817,
    "state_id": "SC"
  },
  {
    "zip": 29834,
    "lat": 33.5119,
    "lng": -81.85827,
    "state_id": "SC"
  },
  {
    "zip": 29835,
    "lat": 33.91894,
    "lng": -82.2903,
    "state_id": "SC"
  },
  {
    "zip": 29836,
    "lat": 33.11739,
    "lng": -81.56876,
    "state_id": "SC"
  },
  {
    "zip": 29838,
    "lat": 33.73749,
    "lng": -82.16331,
    "state_id": "SC"
  },
  {
    "zip": 29840,
    "lat": 34.00645,
    "lng": -82.53238,
    "state_id": "SC"
  },
  {
    "zip": 29841,
    "lat": 33.52505,
    "lng": -81.93946,
    "state_id": "SC"
  },
  {
    "zip": 29842,
    "lat": 33.44613,
    "lng": -81.8637,
    "state_id": "SC"
  },
  {
    "zip": 29843,
    "lat": 33.18289,
    "lng": -81.18034,
    "state_id": "SC"
  },
  {
    "zip": 29844,
    "lat": 33.76826,
    "lng": -82.23213,
    "state_id": "SC"
  },
  {
    "zip": 29845,
    "lat": 33.82617,
    "lng": -82.20494,
    "state_id": "SC"
  },
  {
    "zip": 29847,
    "lat": 33.70004,
    "lng": -81.85588,
    "state_id": "SC"
  },
  {
    "zip": 29848,
    "lat": 34.00151,
    "lng": -82.21348,
    "state_id": "SC"
  },
  {
    "zip": 29849,
    "lat": 33.09685,
    "lng": -81.23312,
    "state_id": "SC"
  },
  {
    "zip": 29850,
    "lat": 33.61271,
    "lng": -81.81272,
    "state_id": "SC"
  },
  {
    "zip": 29851,
    "lat": 33.51185,
    "lng": -81.81159,
    "state_id": "SC"
  },
  {
    "zip": 29853,
    "lat": 33.40297,
    "lng": -81.42338,
    "state_id": "SC"
  },
  {
    "zip": 29856,
    "lat": 33.48331,
    "lng": -81.51321,
    "state_id": "SC"
  },
  {
    "zip": 29860,
    "lat": 33.60279,
    "lng": -81.97479,
    "state_id": "SC"
  },
  {
    "zip": 29899,
    "lat": 33.928,
    "lng": -82.25068,
    "state_id": "SC"
  },
  {
    "zip": 29902,
    "lat": 32.37717,
    "lng": -80.69522,
    "state_id": "SC"
  },
  {
    "zip": 29904,
    "lat": 32.45734,
    "lng": -80.71791,
    "state_id": "SC"
  },
  {
    "zip": 29905,
    "lat": 32.35145,
    "lng": -80.68492,
    "state_id": "SC"
  },
  {
    "zip": 29906,
    "lat": 32.44678,
    "lng": -80.75031,
    "state_id": "SC"
  },
  {
    "zip": 29907,
    "lat": 32.45331,
    "lng": -80.6087,
    "state_id": "SC"
  },
  {
    "zip": 29909,
    "lat": 32.341,
    "lng": -80.85373,
    "state_id": "SC"
  },
  {
    "zip": 29910,
    "lat": 32.22078,
    "lng": -80.88483,
    "state_id": "SC"
  },
  {
    "zip": 29911,
    "lat": 32.9376,
    "lng": -81.14181,
    "state_id": "SC"
  },
  {
    "zip": 29912,
    "lat": 32.58262,
    "lng": -80.93074,
    "state_id": "SC"
  },
  {
    "zip": 29915,
    "lat": 32.11465,
    "lng": -80.86898,
    "state_id": "SC"
  },
  {
    "zip": 29916,
    "lat": 32.72295,
    "lng": -80.96338,
    "state_id": "SC"
  },
  {
    "zip": 29918,
    "lat": 32.74555,
    "lng": -81.24206,
    "state_id": "SC"
  },
  {
    "zip": 29920,
    "lat": 32.36456,
    "lng": -80.54964,
    "state_id": "SC"
  },
  {
    "zip": 29921,
    "lat": 32.6826,
    "lng": -81.17286,
    "state_id": "SC"
  },
  {
    "zip": 29922,
    "lat": 32.62218,
    "lng": -81.28344,
    "state_id": "SC"
  },
  {
    "zip": 29923,
    "lat": 32.85956,
    "lng": -81.24639,
    "state_id": "SC"
  },
  {
    "zip": 29924,
    "lat": 32.87669,
    "lng": -81.11328,
    "state_id": "SC"
  },
  {
    "zip": 29926,
    "lat": 32.22801,
    "lng": -80.75234,
    "state_id": "SC"
  },
  {
    "zip": 29927,
    "lat": 32.23044,
    "lng": -81.04402,
    "state_id": "SC"
  },
  {
    "zip": 29928,
    "lat": 32.1615,
    "lng": -80.76347,
    "state_id": "SC"
  },
  {
    "zip": 29929,
    "lat": 32.93567,
    "lng": -80.94263,
    "state_id": "SC"
  },
  {
    "zip": 29932,
    "lat": 32.81839,
    "lng": -81.34757,
    "state_id": "SC"
  },
  {
    "zip": 29934,
    "lat": 32.59311,
    "lng": -81.10784,
    "state_id": "SC"
  },
  {
    "zip": 29935,
    "lat": 32.38324,
    "lng": -80.69508,
    "state_id": "SC"
  },
  {
    "zip": 29936,
    "lat": 32.47805,
    "lng": -80.97238,
    "state_id": "SC"
  },
  {
    "zip": 29939,
    "lat": 32.6725,
    "lng": -81.24403,
    "state_id": "SC"
  },
  {
    "zip": 29940,
    "lat": 32.55321,
    "lng": -80.70667,
    "state_id": "SC"
  },
  {
    "zip": 29941,
    "lat": 32.54373,
    "lng": -80.81181,
    "state_id": "SC"
  },
  {
    "zip": 29943,
    "lat": 32.46815,
    "lng": -81.16689,
    "state_id": "SC"
  },
  {
    "zip": 29944,
    "lat": 32.79221,
    "lng": -81.03282,
    "state_id": "SC"
  },
  {
    "zip": 29945,
    "lat": 32.67305,
    "lng": -80.78432,
    "state_id": "SC"
  },
  {
    "zip": 57001,
    "lat": 42.98447,
    "lng": -96.63972,
    "state_id": "SD"
  },
  {
    "zip": 57002,
    "lat": 44.2965,
    "lng": -96.67695,
    "state_id": "SD"
  },
  {
    "zip": 57003,
    "lat": 43.73545,
    "lng": -96.75995,
    "state_id": "SD"
  },
  {
    "zip": 57004,
    "lat": 43.07769,
    "lng": -96.79149,
    "state_id": "SD"
  },
  {
    "zip": 57005,
    "lat": 43.59263,
    "lng": -96.58601,
    "state_id": "SD"
  },
  {
    "zip": 57006,
    "lat": 44.31526,
    "lng": -96.79634,
    "state_id": "SD"
  },
  {
    "zip": 57010,
    "lat": 42.8183,
    "lng": -96.82082,
    "state_id": "SD"
  },
  {
    "zip": 57012,
    "lat": 43.59566,
    "lng": -97.27312,
    "state_id": "SD"
  },
  {
    "zip": 57013,
    "lat": 43.28311,
    "lng": -96.62,
    "state_id": "SD"
  },
  {
    "zip": 57014,
    "lat": 43.11301,
    "lng": -96.95273,
    "state_id": "SD"
  },
  {
    "zip": 57015,
    "lat": 43.41842,
    "lng": -96.98006,
    "state_id": "SD"
  },
  {
    "zip": 57016,
    "lat": 43.893,
    "lng": -96.9618,
    "state_id": "SD"
  },
  {
    "zip": 57017,
    "lat": 44.01208,
    "lng": -96.82924,
    "state_id": "SD"
  },
  {
    "zip": 57018,
    "lat": 43.79804,
    "lng": -96.98275,
    "state_id": "SD"
  },
  {
    "zip": 57020,
    "lat": 43.67853,
    "lng": -96.83054,
    "state_id": "SD"
  },
  {
    "zip": 57021,
    "lat": 43.27028,
    "lng": -96.97796,
    "state_id": "SD"
  },
  {
    "zip": 57022,
    "lat": 43.84539,
    "lng": -96.72324,
    "state_id": "SD"
  },
  {
    "zip": 57024,
    "lat": 43.9885,
    "lng": -96.67923,
    "state_id": "SD"
  },
  {
    "zip": 57025,
    "lat": 42.72733,
    "lng": -96.69199,
    "state_id": "SD"
  },
  {
    "zip": 57026,
    "lat": 44.25924,
    "lng": -96.51241,
    "state_id": "SD"
  },
  {
    "zip": 57027,
    "lat": 43.18667,
    "lng": -96.49742,
    "state_id": "SD"
  },
  {
    "zip": 57028,
    "lat": 44.0604,
    "lng": -96.60564,
    "state_id": "SD"
  },
  {
    "zip": 57029,
    "lat": 43.33754,
    "lng": -97.48028,
    "state_id": "SD"
  },
  {
    "zip": 57030,
    "lat": 43.73698,
    "lng": -96.52246,
    "state_id": "SD"
  },
  {
    "zip": 57031,
    "lat": 42.87453,
    "lng": -97.18347,
    "state_id": "SD"
  },
  {
    "zip": 57032,
    "lat": 43.42188,
    "lng": -96.6869,
    "state_id": "SD"
  },
  {
    "zip": 57033,
    "lat": 43.61913,
    "lng": -96.95759,
    "state_id": "SD"
  },
  {
    "zip": 57034,
    "lat": 43.1219,
    "lng": -96.55137,
    "state_id": "SD"
  },
  {
    "zip": 57035,
    "lat": 43.63214,
    "lng": -97.07453,
    "state_id": "SD"
  },
  {
    "zip": 57036,
    "lat": 43.27297,
    "lng": -97.14117,
    "state_id": "SD"
  },
  {
    "zip": 57037,
    "lat": 43.10295,
    "lng": -97.2557,
    "state_id": "SD"
  },
  {
    "zip": 57038,
    "lat": 42.58243,
    "lng": -96.58099,
    "state_id": "SD"
  },
  {
    "zip": 57039,
    "lat": 43.33696,
    "lng": -96.86951,
    "state_id": "SD"
  },
  {
    "zip": 57040,
    "lat": 43.0637,
    "lng": -97.58616,
    "state_id": "SD"
  },
  {
    "zip": 57041,
    "lat": 43.72978,
    "lng": -96.86761,
    "state_id": "SD"
  },
  {
    "zip": 57042,
    "lat": 43.97971,
    "lng": -97.15072,
    "state_id": "SD"
  },
  {
    "zip": 57043,
    "lat": 43.41623,
    "lng": -97.31155,
    "state_id": "SD"
  },
  {
    "zip": 57045,
    "lat": 43.20784,
    "lng": -97.53005,
    "state_id": "SD"
  },
  {
    "zip": 57046,
    "lat": 42.95912,
    "lng": -97.30789,
    "state_id": "SD"
  },
  {
    "zip": 57047,
    "lat": 43.52064,
    "lng": -97.21195,
    "state_id": "SD"
  },
  {
    "zip": 57048,
    "lat": 43.74273,
    "lng": -97.19195,
    "state_id": "SD"
  },
  {
    "zip": 57049,
    "lat": 42.52476,
    "lng": -96.50721,
    "state_id": "SD"
  },
  {
    "zip": 57050,
    "lat": 44.15727,
    "lng": -97.01469,
    "state_id": "SD"
  },
  {
    "zip": 57051,
    "lat": 44.22942,
    "lng": -97.33512,
    "state_id": "SD"
  },
  {
    "zip": 57052,
    "lat": 43.29367,
    "lng": -97.71244,
    "state_id": "SD"
  },
  {
    "zip": 57053,
    "lat": 43.41009,
    "lng": -97.14026,
    "state_id": "SD"
  },
  {
    "zip": 57054,
    "lat": 44.12934,
    "lng": -97.25468,
    "state_id": "SD"
  },
  {
    "zip": 57055,
    "lat": 43.66731,
    "lng": -96.74057,
    "state_id": "SD"
  },
  {
    "zip": 57057,
    "lat": 44.1017,
    "lng": -96.95957,
    "state_id": "SD"
  },
  {
    "zip": 57058,
    "lat": 43.73939,
    "lng": -97.40163,
    "state_id": "SD"
  },
  {
    "zip": 57059,
    "lat": 43.12564,
    "lng": -97.75782,
    "state_id": "SD"
  },
  {
    "zip": 57061,
    "lat": 44.24236,
    "lng": -97.044,
    "state_id": "SD"
  },
  {
    "zip": 57062,
    "lat": 42.85036,
    "lng": -97.95455,
    "state_id": "SD"
  },
  {
    "zip": 57063,
    "lat": 42.92373,
    "lng": -97.68508,
    "state_id": "SD"
  },
  {
    "zip": 57064,
    "lat": 43.45592,
    "lng": -96.87384,
    "state_id": "SD"
  },
  {
    "zip": 57065,
    "lat": 43.90454,
    "lng": -96.61559,
    "state_id": "SD"
  },
  {
    "zip": 57066,
    "lat": 42.9904,
    "lng": -97.86923,
    "state_id": "SD"
  },
  {
    "zip": 57067,
    "lat": 43.04058,
    "lng": -97.47611,
    "state_id": "SD"
  },
  {
    "zip": 57068,
    "lat": 43.57541,
    "lng": -96.49221,
    "state_id": "SD"
  },
  {
    "zip": 57069,
    "lat": 42.83957,
    "lng": -96.97314,
    "state_id": "SD"
  },
  {
    "zip": 57070,
    "lat": 43.18289,
    "lng": -97.17439,
    "state_id": "SD"
  },
  {
    "zip": 57071,
    "lat": 44.27352,
    "lng": -96.96064,
    "state_id": "SD"
  },
  {
    "zip": 57072,
    "lat": 42.98223,
    "lng": -97.19354,
    "state_id": "SD"
  },
  {
    "zip": 57073,
    "lat": 43.00393,
    "lng": -97.05586,
    "state_id": "SD"
  },
  {
    "zip": 57075,
    "lat": 44.00185,
    "lng": -96.96352,
    "state_id": "SD"
  },
  {
    "zip": 57076,
    "lat": 43.99297,
    "lng": -97.35973,
    "state_id": "SD"
  },
  {
    "zip": 57077,
    "lat": 43.32013,
    "lng": -96.75782,
    "state_id": "SD"
  },
  {
    "zip": 57078,
    "lat": 42.91596,
    "lng": -97.44362,
    "state_id": "SD"
  },
  {
    "zip": 57103,
    "lat": 43.54647,
    "lng": -96.68894,
    "state_id": "SD"
  },
  {
    "zip": 57104,
    "lat": 43.60469,
    "lng": -96.70745,
    "state_id": "SD"
  },
  {
    "zip": 57105,
    "lat": 43.52065,
    "lng": -96.73534,
    "state_id": "SD"
  },
  {
    "zip": 57106,
    "lat": 43.51054,
    "lng": -96.83901,
    "state_id": "SD"
  },
  {
    "zip": 57107,
    "lat": 43.59489,
    "lng": -96.82121,
    "state_id": "SD"
  },
  {
    "zip": 57108,
    "lat": 43.47752,
    "lng": -96.70406,
    "state_id": "SD"
  },
  {
    "zip": 57110,
    "lat": 43.54322,
    "lng": -96.63772,
    "state_id": "SD"
  },
  {
    "zip": 57117,
    "lat": 43.53124,
    "lng": -96.75497,
    "state_id": "SD"
  },
  {
    "zip": 57197,
    "lat": 43.52457,
    "lng": -96.73861,
    "state_id": "SD"
  },
  {
    "zip": 57201,
    "lat": 44.93786,
    "lng": -97.09464,
    "state_id": "SD"
  },
  {
    "zip": 57212,
    "lat": 44.37942,
    "lng": -97.1433,
    "state_id": "SD"
  },
  {
    "zip": 57213,
    "lat": 44.54863,
    "lng": -96.52265,
    "state_id": "SD"
  },
  {
    "zip": 57214,
    "lat": 44.50184,
    "lng": -97.20477,
    "state_id": "SD"
  },
  {
    "zip": 57216,
    "lat": 45.30015,
    "lng": -96.52616,
    "state_id": "SD"
  },
  {
    "zip": 57217,
    "lat": 45.07844,
    "lng": -97.66123,
    "state_id": "SD"
  },
  {
    "zip": 57218,
    "lat": 44.65767,
    "lng": -96.59183,
    "state_id": "SD"
  },
  {
    "zip": 57219,
    "lat": 45.28801,
    "lng": -97.80865,
    "state_id": "SD"
  },
  {
    "zip": 57220,
    "lat": 44.46796,
    "lng": -96.92042,
    "state_id": "SD"
  },
  {
    "zip": 57221,
    "lat": 44.59541,
    "lng": -97.45309,
    "state_id": "SD"
  },
  {
    "zip": 57223,
    "lat": 44.71561,
    "lng": -97.00842,
    "state_id": "SD"
  },
  {
    "zip": 57224,
    "lat": 45.86237,
    "lng": -97.13702,
    "state_id": "SD"
  },
  {
    "zip": 57225,
    "lat": 44.88507,
    "lng": -97.72526,
    "state_id": "SD"
  },
  {
    "zip": 57226,
    "lat": 44.7876,
    "lng": -96.71862,
    "state_id": "SD"
  },
  {
    "zip": 57227,
    "lat": 45.36221,
    "lng": -96.71801,
    "state_id": "SD"
  },
  {
    "zip": 57231,
    "lat": 44.36451,
    "lng": -97.59028,
    "state_id": "SD"
  },
  {
    "zip": 57232,
    "lat": 45.61756,
    "lng": -97.38428,
    "state_id": "SD"
  },
  {
    "zip": 57233,
    "lat": 44.51193,
    "lng": -97.39821,
    "state_id": "SD"
  },
  {
    "zip": 57234,
    "lat": 44.60292,
    "lng": -96.89277,
    "state_id": "SD"
  },
  {
    "zip": 57235,
    "lat": 45.07397,
    "lng": -97.30875,
    "state_id": "SD"
  },
  {
    "zip": 57236,
    "lat": 44.98413,
    "lng": -97.58139,
    "state_id": "SD"
  },
  {
    "zip": 57237,
    "lat": 44.79479,
    "lng": -96.51693,
    "state_id": "SD"
  },
  {
    "zip": 57238,
    "lat": 44.87543,
    "lng": -96.86082,
    "state_id": "SD"
  },
  {
    "zip": 57239,
    "lat": 45.50968,
    "lng": -97.30925,
    "state_id": "SD"
  },
  {
    "zip": 57241,
    "lat": 44.69891,
    "lng": -97.21352,
    "state_id": "SD"
  },
  {
    "zip": 57242,
    "lat": 44.77385,
    "lng": -97.3629,
    "state_id": "SD"
  },
  {
    "zip": 57243,
    "lat": 44.91279,
    "lng": -97.41939,
    "state_id": "SD"
  },
  {
    "zip": 57245,
    "lat": 44.89759,
    "lng": -96.91817,
    "state_id": "SD"
  },
  {
    "zip": 57246,
    "lat": 45.05837,
    "lng": -96.66704,
    "state_id": "SD"
  },
  {
    "zip": 57247,
    "lat": 45.72002,
    "lng": -97.43095,
    "state_id": "SD"
  },
  {
    "zip": 57248,
    "lat": 44.57978,
    "lng": -97.18943,
    "state_id": "SD"
  },
  {
    "zip": 57249,
    "lat": 44.37332,
    "lng": -97.36306,
    "state_id": "SD"
  },
  {
    "zip": 57251,
    "lat": 45.27889,
    "lng": -96.93516,
    "state_id": "SD"
  },
  {
    "zip": 57252,
    "lat": 45.20386,
    "lng": -96.6117,
    "state_id": "SD"
  },
  {
    "zip": 57255,
    "lat": 45.85807,
    "lng": -96.9413,
    "state_id": "SD"
  },
  {
    "zip": 57256,
    "lat": 45.2657,
    "lng": -97.19248,
    "state_id": "SD"
  },
  {
    "zip": 57257,
    "lat": 45.49709,
    "lng": -97.02624,
    "state_id": "SD"
  },
  {
    "zip": 57258,
    "lat": 44.88631,
    "lng": -97.91985,
    "state_id": "SD"
  },
  {
    "zip": 57259,
    "lat": 44.99961,
    "lng": -96.56585,
    "state_id": "SD"
  },
  {
    "zip": 57260,
    "lat": 45.83605,
    "lng": -96.71839,
    "state_id": "SD"
  },
  {
    "zip": 57261,
    "lat": 45.54059,
    "lng": -97.49161,
    "state_id": "SD"
  },
  {
    "zip": 57262,
    "lat": 45.67323,
    "lng": -97.07148,
    "state_id": "SD"
  },
  {
    "zip": 57263,
    "lat": 45.13744,
    "lng": -97.00214,
    "state_id": "SD"
  },
  {
    "zip": 57264,
    "lat": 45.11135,
    "lng": -96.80465,
    "state_id": "SD"
  },
  {
    "zip": 57265,
    "lat": 44.99019,
    "lng": -96.78992,
    "state_id": "SD"
  },
  {
    "zip": 57266,
    "lat": 45.27903,
    "lng": -97.09701,
    "state_id": "SD"
  },
  {
    "zip": 57268,
    "lat": 44.56975,
    "lng": -96.69075,
    "state_id": "SD"
  },
  {
    "zip": 57269,
    "lat": 45.2209,
    "lng": -96.8145,
    "state_id": "SD"
  },
  {
    "zip": 57270,
    "lat": 45.84287,
    "lng": -97.34063,
    "state_id": "SD"
  },
  {
    "zip": 57271,
    "lat": 44.73617,
    "lng": -97.53066,
    "state_id": "SD"
  },
  {
    "zip": 57272,
    "lat": 45.10327,
    "lng": -97.46793,
    "state_id": "SD"
  },
  {
    "zip": 57273,
    "lat": 45.33582,
    "lng": -97.27353,
    "state_id": "SD"
  },
  {
    "zip": 57274,
    "lat": 45.30335,
    "lng": -97.56291,
    "state_id": "SD"
  },
  {
    "zip": 57276,
    "lat": 44.43013,
    "lng": -96.6135,
    "state_id": "SD"
  },
  {
    "zip": 57278,
    "lat": 44.63908,
    "lng": -97.69546,
    "state_id": "SD"
  },
  {
    "zip": 57279,
    "lat": 45.41983,
    "lng": -96.87483,
    "state_id": "SD"
  },
  {
    "zip": 57301,
    "lat": 43.71376,
    "lng": -98.04724,
    "state_id": "SD"
  },
  {
    "zip": 57311,
    "lat": 43.65292,
    "lng": -97.76171,
    "state_id": "SD"
  },
  {
    "zip": 57312,
    "lat": 44.20046,
    "lng": -98.37634,
    "state_id": "SD"
  },
  {
    "zip": 57313,
    "lat": 43.31032,
    "lng": -98.3658,
    "state_id": "SD"
  },
  {
    "zip": 57314,
    "lat": 44.05029,
    "lng": -97.98514,
    "state_id": "SD"
  },
  {
    "zip": 57315,
    "lat": 42.99326,
    "lng": -98.04664,
    "state_id": "SD"
  },
  {
    "zip": 57317,
    "lat": 43.12625,
    "lng": -98.98066,
    "state_id": "SD"
  },
  {
    "zip": 57319,
    "lat": 43.53605,
    "lng": -97.48283,
    "state_id": "SD"
  },
  {
    "zip": 57321,
    "lat": 43.87233,
    "lng": -97.55062,
    "state_id": "SD"
  },
  {
    "zip": 57322,
    "lat": 44.63068,
    "lng": -97.97403,
    "state_id": "SD"
  },
  {
    "zip": 57323,
    "lat": 44.14585,
    "lng": -97.71383,
    "state_id": "SD"
  },
  {
    "zip": 57324,
    "lat": 44.29847,
    "lng": -98.05399,
    "state_id": "SD"
  },
  {
    "zip": 57325,
    "lat": 43.7561,
    "lng": -99.29633,
    "state_id": "SD"
  },
  {
    "zip": 57328,
    "lat": 43.43242,
    "lng": -98.4403,
    "state_id": "SD"
  },
  {
    "zip": 57329,
    "lat": 42.97949,
    "lng": -98.15395,
    "state_id": "SD"
  },
  {
    "zip": 57330,
    "lat": 43.25187,
    "lng": -98.16564,
    "state_id": "SD"
  },
  {
    "zip": 57331,
    "lat": 43.47318,
    "lng": -98.03723,
    "state_id": "SD"
  },
  {
    "zip": 57332,
    "lat": 43.54804,
    "lng": -97.64734,
    "state_id": "SD"
  },
  {
    "zip": 57334,
    "lat": 43.54636,
    "lng": -97.99428,
    "state_id": "SD"
  },
  {
    "zip": 57335,
    "lat": 43.06138,
    "lng": -98.77377,
    "state_id": "SD"
  },
  {
    "zip": 57337,
    "lat": 44.00976,
    "lng": -97.79213,
    "state_id": "SD"
  },
  {
    "zip": 57339,
    "lat": 44.11596,
    "lng": -99.41258,
    "state_id": "SD"
  },
  {
    "zip": 57340,
    "lat": 43.79557,
    "lng": -97.84511,
    "state_id": "SD"
  },
  {
    "zip": 57341,
    "lat": 44.08985,
    "lng": -99.08809,
    "state_id": "SD"
  },
  {
    "zip": 57342,
    "lat": 43.2466,
    "lng": -98.70177,
    "state_id": "SD"
  },
  {
    "zip": 57344,
    "lat": 43.45074,
    "lng": -98.63395,
    "state_id": "SD"
  },
  {
    "zip": 57345,
    "lat": 44.57382,
    "lng": -99.45348,
    "state_id": "SD"
  },
  {
    "zip": 57346,
    "lat": 44.23508,
    "lng": -99.5524,
    "state_id": "SD"
  },
  {
    "zip": 57348,
    "lat": 44.62177,
    "lng": -98.36444,
    "state_id": "SD"
  },
  {
    "zip": 57349,
    "lat": 44.03028,
    "lng": -97.57578,
    "state_id": "SD"
  },
  {
    "zip": 57350,
    "lat": 44.40227,
    "lng": -98.20051,
    "state_id": "SD"
  },
  {
    "zip": 57353,
    "lat": 44.35872,
    "lng": -97.84415,
    "state_id": "SD"
  },
  {
    "zip": 57355,
    "lat": 43.77867,
    "lng": -98.9494,
    "state_id": "SD"
  },
  {
    "zip": 57356,
    "lat": 43.15884,
    "lng": -98.52724,
    "state_id": "SD"
  },
  {
    "zip": 57358,
    "lat": 44.0753,
    "lng": -98.42189,
    "state_id": "SD"
  },
  {
    "zip": 57359,
    "lat": 43.89804,
    "lng": -98.17521,
    "state_id": "SD"
  },
  {
    "zip": 57361,
    "lat": 42.99698,
    "lng": -98.42921,
    "state_id": "SD"
  },
  {
    "zip": 57362,
    "lat": 44.4916,
    "lng": -99.07088,
    "state_id": "SD"
  },
  {
    "zip": 57363,
    "lat": 43.67863,
    "lng": -98.25553,
    "state_id": "SD"
  },
  {
    "zip": 57364,
    "lat": 43.42922,
    "lng": -98.60689,
    "state_id": "SD"
  },
  {
    "zip": 57365,
    "lat": 43.81175,
    "lng": -99.41645,
    "state_id": "SD"
  },
  {
    "zip": 57366,
    "lat": 43.38348,
    "lng": -97.94396,
    "state_id": "SD"
  },
  {
    "zip": 57367,
    "lat": 43.06397,
    "lng": -98.52206,
    "state_id": "SD"
  },
  {
    "zip": 57368,
    "lat": 43.75128,
    "lng": -98.47749,
    "state_id": "SD"
  },
  {
    "zip": 57369,
    "lat": 43.42504,
    "lng": -98.9479,
    "state_id": "SD"
  },
  {
    "zip": 57370,
    "lat": 43.80154,
    "lng": -99.16909,
    "state_id": "SD"
  },
  {
    "zip": 57371,
    "lat": 44.42302,
    "lng": -99.23537,
    "state_id": "SD"
  },
  {
    "zip": 57373,
    "lat": 44.53858,
    "lng": -98.86412,
    "state_id": "SD"
  },
  {
    "zip": 57374,
    "lat": 43.75613,
    "lng": -97.60163,
    "state_id": "SD"
  },
  {
    "zip": 57375,
    "lat": 43.56202,
    "lng": -98.47987,
    "state_id": "SD"
  },
  {
    "zip": 57376,
    "lat": 43.21571,
    "lng": -97.94065,
    "state_id": "SD"
  },
  {
    "zip": 57379,
    "lat": 44.25834,
    "lng": -98.55119,
    "state_id": "SD"
  },
  {
    "zip": 57380,
    "lat": 43.0305,
    "lng": -98.3076,
    "state_id": "SD"
  },
  {
    "zip": 57381,
    "lat": 44.39086,
    "lng": -98.73306,
    "state_id": "SD"
  },
  {
    "zip": 57382,
    "lat": 44.06162,
    "lng": -98.66741,
    "state_id": "SD"
  },
  {
    "zip": 57383,
    "lat": 43.71959,
    "lng": -98.71463,
    "state_id": "SD"
  },
  {
    "zip": 57384,
    "lat": 44.41466,
    "lng": -98.49125,
    "state_id": "SD"
  },
  {
    "zip": 57385,
    "lat": 44.0508,
    "lng": -98.28843,
    "state_id": "SD"
  },
  {
    "zip": 57386,
    "lat": 44.51317,
    "lng": -98.00368,
    "state_id": "SD"
  },
  {
    "zip": 57401,
    "lat": 45.47648,
    "lng": -98.52753,
    "state_id": "SD"
  },
  {
    "zip": 57420,
    "lat": 45.28685,
    "lng": -100.14134,
    "state_id": "SD"
  },
  {
    "zip": 57421,
    "lat": 45.75366,
    "lng": -97.9163,
    "state_id": "SD"
  },
  {
    "zip": 57422,
    "lat": 45.42189,
    "lng": -97.91166,
    "state_id": "SD"
  },
  {
    "zip": 57424,
    "lat": 45.01655,
    "lng": -98.53829,
    "state_id": "SD"
  },
  {
    "zip": 57426,
    "lat": 45.73795,
    "lng": -98.49555,
    "state_id": "SD"
  },
  {
    "zip": 57427,
    "lat": 45.48373,
    "lng": -98.31718,
    "state_id": "SD"
  },
  {
    "zip": 57428,
    "lat": 45.45234,
    "lng": -99.67794,
    "state_id": "SD"
  },
  {
    "zip": 57429,
    "lat": 45.17477,
    "lng": -98.2973,
    "state_id": "SD"
  },
  {
    "zip": 57430,
    "lat": 45.81545,
    "lng": -97.7108,
    "state_id": "SD"
  },
  {
    "zip": 57432,
    "lat": 45.68238,
    "lng": -98.03365,
    "state_id": "SD"
  },
  {
    "zip": 57433,
    "lat": 45.65115,
    "lng": -98.31581,
    "state_id": "SD"
  },
  {
    "zip": 57434,
    "lat": 45.16922,
    "lng": -98.07465,
    "state_id": "SD"
  },
  {
    "zip": 57435,
    "lat": 45.17654,
    "lng": -98.91268,
    "state_id": "SD"
  },
  {
    "zip": 57436,
    "lat": 44.85674,
    "lng": -98.08626,
    "state_id": "SD"
  },
  {
    "zip": 57437,
    "lat": 45.79144,
    "lng": -99.59805,
    "state_id": "SD"
  },
  {
    "zip": 57438,
    "lat": 45.06372,
    "lng": -99.16239,
    "state_id": "SD"
  },
  {
    "zip": 57439,
    "lat": 45.33168,
    "lng": -98.06511,
    "state_id": "SD"
  },
  {
    "zip": 57440,
    "lat": 44.84998,
    "lng": -98.26189,
    "state_id": "SD"
  },
  {
    "zip": 57441,
    "lat": 45.82891,
    "lng": -98.52457,
    "state_id": "SD"
  },
  {
    "zip": 57442,
    "lat": 45.05451,
    "lng": -100.14463,
    "state_id": "SD"
  },
  {
    "zip": 57445,
    "lat": 45.46107,
    "lng": -98.11901,
    "state_id": "SD"
  },
  {
    "zip": 57446,
    "lat": 45.86836,
    "lng": -98.14616,
    "state_id": "SD"
  },
  {
    "zip": 57448,
    "lat": 45.61283,
    "lng": -99.40674,
    "state_id": "SD"
  },
  {
    "zip": 57449,
    "lat": 45.73821,
    "lng": -98.18289,
    "state_id": "SD"
  },
  {
    "zip": 57450,
    "lat": 45.22527,
    "lng": -99.83955,
    "state_id": "SD"
  },
  {
    "zip": 57451,
    "lat": 45.43469,
    "lng": -98.94763,
    "state_id": "SD"
  },
  {
    "zip": 57452,
    "lat": 45.532,
    "lng": -99.86624,
    "state_id": "SD"
  },
  {
    "zip": 57454,
    "lat": 45.61118,
    "lng": -97.77843,
    "state_id": "SD"
  },
  {
    "zip": 57455,
    "lat": 45.00296,
    "lng": -99.73449,
    "state_id": "SD"
  },
  {
    "zip": 57456,
    "lat": 45.73712,
    "lng": -98.98196,
    "state_id": "SD"
  },
  {
    "zip": 57457,
    "lat": 45.88672,
    "lng": -99.16009,
    "state_id": "SD"
  },
  {
    "zip": 57460,
    "lat": 45.27419,
    "lng": -98.6741,
    "state_id": "SD"
  },
  {
    "zip": 57461,
    "lat": 45.15692,
    "lng": -98.43202,
    "state_id": "SD"
  },
  {
    "zip": 57465,
    "lat": 45.14218,
    "lng": -98.68102,
    "state_id": "SD"
  },
  {
    "zip": 57466,
    "lat": 45.21161,
    "lng": -99.46409,
    "state_id": "SD"
  },
  {
    "zip": 57467,
    "lat": 44.83336,
    "lng": -99.1415,
    "state_id": "SD"
  },
  {
    "zip": 57468,
    "lat": 45.50012,
    "lng": -97.7555,
    "state_id": "SD"
  },
  {
    "zip": 57469,
    "lat": 44.86139,
    "lng": -98.57443,
    "state_id": "SD"
  },
  {
    "zip": 57470,
    "lat": 44.87671,
    "lng": -98.86688,
    "state_id": "SD"
  },
  {
    "zip": 57471,
    "lat": 45.39945,
    "lng": -99.3257,
    "state_id": "SD"
  },
  {
    "zip": 57472,
    "lat": 45.44768,
    "lng": -100.07291,
    "state_id": "SD"
  },
  {
    "zip": 57473,
    "lat": 44.99611,
    "lng": -99.50199,
    "state_id": "SD"
  },
  {
    "zip": 57474,
    "lat": 45.29864,
    "lng": -98.26884,
    "state_id": "SD"
  },
  {
    "zip": 57475,
    "lat": 45.22191,
    "lng": -99.63213,
    "state_id": "SD"
  },
  {
    "zip": 57476,
    "lat": 44.71425,
    "lng": -98.61703,
    "state_id": "SD"
  },
  {
    "zip": 57477,
    "lat": 45.04038,
    "lng": -98.16155,
    "state_id": "SD"
  },
  {
    "zip": 57479,
    "lat": 45.30296,
    "lng": -98.44322,
    "state_id": "SD"
  },
  {
    "zip": 57481,
    "lat": 45.65042,
    "lng": -98.66769,
    "state_id": "SD"
  },
  {
    "zip": 57501,
    "lat": 44.52935,
    "lng": -100.30251,
    "state_id": "SD"
  },
  {
    "zip": 57520,
    "lat": 44.8448,
    "lng": -100.16428,
    "state_id": "SD"
  },
  {
    "zip": 57521,
    "lat": 43.80982,
    "lng": -101.207,
    "state_id": "SD"
  },
  {
    "zip": 57522,
    "lat": 44.47735,
    "lng": -99.99679,
    "state_id": "SD"
  },
  {
    "zip": 57523,
    "lat": 43.23661,
    "lng": -99.23922,
    "state_id": "SD"
  },
  {
    "zip": 57528,
    "lat": 43.19158,
    "lng": -99.76538,
    "state_id": "SD"
  },
  {
    "zip": 57529,
    "lat": 43.23148,
    "lng": -99.56769,
    "state_id": "SD"
  },
  {
    "zip": 57531,
    "lat": 43.9707,
    "lng": -100.49108,
    "state_id": "SD"
  },
  {
    "zip": 57532,
    "lat": 44.38995,
    "lng": -100.61774,
    "state_id": "SD"
  },
  {
    "zip": 57533,
    "lat": 43.37414,
    "lng": -99.43177,
    "state_id": "SD"
  },
  {
    "zip": 57534,
    "lat": 43.58107,
    "lng": -99.68744,
    "state_id": "SD"
  },
  {
    "zip": 57536,
    "lat": 44.3991,
    "lng": -99.76162,
    "state_id": "SD"
  },
  {
    "zip": 57537,
    "lat": 44.56549,
    "lng": -101.06026,
    "state_id": "SD"
  },
  {
    "zip": 57538,
    "lat": 43.12035,
    "lng": -99.14445,
    "state_id": "SD"
  },
  {
    "zip": 57540,
    "lat": 44.51898,
    "lng": -99.6073,
    "state_id": "SD"
  },
  {
    "zip": 57541,
    "lat": 43.6032,
    "lng": -99.89584,
    "state_id": "SD"
  },
  {
    "zip": 57543,
    "lat": 43.75287,
    "lng": -101.51687,
    "state_id": "SD"
  },
  {
    "zip": 57544,
    "lat": 43.9455,
    "lng": -99.88774,
    "state_id": "SD"
  },
  {
    "zip": 57547,
    "lat": 43.47547,
    "lng": -101.42936,
    "state_id": "SD"
  },
  {
    "zip": 57548,
    "lat": 44.08834,
    "lng": -99.6589,
    "state_id": "SD"
  },
  {
    "zip": 57551,
    "lat": 43.17891,
    "lng": -101.6976,
    "state_id": "SD"
  },
  {
    "zip": 57552,
    "lat": 44.27155,
    "lng": -101.19563,
    "state_id": "SD"
  },
  {
    "zip": 57553,
    "lat": 44.48113,
    "lng": -101.64204,
    "state_id": "SD"
  },
  {
    "zip": 57555,
    "lat": 43.24342,
    "lng": -100.6321,
    "state_id": "SD"
  },
  {
    "zip": 57559,
    "lat": 43.935,
    "lng": -100.72531,
    "state_id": "SD"
  },
  {
    "zip": 57560,
    "lat": 43.43957,
    "lng": -101.19703,
    "state_id": "SD"
  },
  {
    "zip": 57562,
    "lat": 43.89385,
    "lng": -100.95269,
    "state_id": "SD"
  },
  {
    "zip": 57563,
    "lat": 43.36321,
    "lng": -100.38085,
    "state_id": "SD"
  },
  {
    "zip": 57564,
    "lat": 44.7122,
    "lng": -99.99244,
    "state_id": "SD"
  },
  {
    "zip": 57566,
    "lat": 43.32465,
    "lng": -101.093,
    "state_id": "SD"
  },
  {
    "zip": 57567,
    "lat": 44.11878,
    "lng": -101.73082,
    "state_id": "SD"
  },
  {
    "zip": 57568,
    "lat": 43.88753,
    "lng": -100.08533,
    "state_id": "SD"
  },
  {
    "zip": 57569,
    "lat": 43.85788,
    "lng": -99.57537,
    "state_id": "SD"
  },
  {
    "zip": 57570,
    "lat": 43.23712,
    "lng": -100.87597,
    "state_id": "SD"
  },
  {
    "zip": 57571,
    "lat": 43.08528,
    "lng": -99.09257,
    "state_id": "SD"
  },
  {
    "zip": 57572,
    "lat": 43.14943,
    "lng": -101.03101,
    "state_id": "SD"
  },
  {
    "zip": 57574,
    "lat": 43.10506,
    "lng": -101.35617,
    "state_id": "SD"
  },
  {
    "zip": 57576,
    "lat": 43.92019,
    "lng": -100.30092,
    "state_id": "SD"
  },
  {
    "zip": 57577,
    "lat": 43.5417,
    "lng": -101.67957,
    "state_id": "SD"
  },
  {
    "zip": 57579,
    "lat": 43.59156,
    "lng": -100.79476,
    "state_id": "SD"
  },
  {
    "zip": 57580,
    "lat": 43.278,
    "lng": -100.05857,
    "state_id": "SD"
  },
  {
    "zip": 57584,
    "lat": 43.53318,
    "lng": -100.071,
    "state_id": "SD"
  },
  {
    "zip": 57585,
    "lat": 43.53994,
    "lng": -100.40351,
    "state_id": "SD"
  },
  {
    "zip": 57601,
    "lat": 45.44464,
    "lng": -100.50963,
    "state_id": "SD"
  },
  {
    "zip": 57620,
    "lat": 45.38241,
    "lng": -102.54875,
    "state_id": "SD"
  },
  {
    "zip": 57621,
    "lat": 45.7492,
    "lng": -101.07488,
    "state_id": "SD"
  },
  {
    "zip": 57622,
    "lat": 44.66411,
    "lng": -101.54094,
    "state_id": "SD"
  },
  {
    "zip": 57623,
    "lat": 45.02371,
    "lng": -101.68134,
    "state_id": "SD"
  },
  {
    "zip": 57625,
    "lat": 44.95167,
    "lng": -100.99522,
    "state_id": "SD"
  },
  {
    "zip": 57626,
    "lat": 44.99261,
    "lng": -102.20973,
    "state_id": "SD"
  },
  {
    "zip": 57630,
    "lat": 45.43917,
    "lng": -100.90554,
    "state_id": "SD"
  },
  {
    "zip": 57631,
    "lat": 45.53361,
    "lng": -100.28243,
    "state_id": "SD"
  },
  {
    "zip": 57632,
    "lat": 45.83662,
    "lng": -100.03958,
    "state_id": "SD"
  },
  {
    "zip": 57633,
    "lat": 45.46016,
    "lng": -101.45463,
    "state_id": "SD"
  },
  {
    "zip": 57634,
    "lat": 45.75269,
    "lng": -101.88428,
    "state_id": "SD"
  },
  {
    "zip": 57636,
    "lat": 45.03497,
    "lng": -101.46131,
    "state_id": "SD"
  },
  {
    "zip": 57638,
    "lat": 45.8862,
    "lng": -102.21443,
    "state_id": "SD"
  },
  {
    "zip": 57639,
    "lat": 45.70362,
    "lng": -100.7718,
    "state_id": "SD"
  },
  {
    "zip": 57640,
    "lat": 45.7802,
    "lng": -102.72035,
    "state_id": "SD"
  },
  {
    "zip": 57641,
    "lat": 45.87398,
    "lng": -101.32176,
    "state_id": "SD"
  },
  {
    "zip": 57642,
    "lat": 45.81092,
    "lng": -100.74303,
    "state_id": "SD"
  },
  {
    "zip": 57644,
    "lat": 45.44865,
    "lng": -102.05859,
    "state_id": "SD"
  },
  {
    "zip": 57645,
    "lat": 45.98433,
    "lng": -101.74271,
    "state_id": "SD"
  },
  {
    "zip": 57646,
    "lat": 45.69014,
    "lng": -100.08379,
    "state_id": "SD"
  },
  {
    "zip": 57648,
    "lat": 45.9106,
    "lng": -100.36469,
    "state_id": "SD"
  },
  {
    "zip": 57649,
    "lat": 45.51717,
    "lng": -102.86106,
    "state_id": "SD"
  },
  {
    "zip": 57650,
    "lat": 45.8385,
    "lng": -103.02244,
    "state_id": "SD"
  },
  {
    "zip": 57651,
    "lat": 45.5094,
    "lng": -103.1002,
    "state_id": "SD"
  },
  {
    "zip": 57652,
    "lat": 45.20262,
    "lng": -100.60482,
    "state_id": "SD"
  },
  {
    "zip": 57656,
    "lat": 45.38806,
    "lng": -101.07766,
    "state_id": "SD"
  },
  {
    "zip": 57657,
    "lat": 45.50669,
    "lng": -100.81216,
    "state_id": "SD"
  },
  {
    "zip": 57658,
    "lat": 45.67891,
    "lng": -100.49452,
    "state_id": "SD"
  },
  {
    "zip": 57660,
    "lat": 45.80335,
    "lng": -101.56816,
    "state_id": "SD"
  },
  {
    "zip": 57661,
    "lat": 45.25675,
    "lng": -100.96018,
    "state_id": "SD"
  },
  {
    "zip": 57701,
    "lat": 44.14153,
    "lng": -103.20508,
    "state_id": "SD"
  },
  {
    "zip": 57702,
    "lat": 44.03379,
    "lng": -103.39048,
    "state_id": "SD"
  },
  {
    "zip": 57703,
    "lat": 44.00284,
    "lng": -103.04377,
    "state_id": "SD"
  },
  {
    "zip": 57706,
    "lat": 44.15719,
    "lng": -103.09356,
    "state_id": "SD"
  },
  {
    "zip": 57714,
    "lat": 43.30254,
    "lng": -101.93317,
    "state_id": "SD"
  },
  {
    "zip": 57716,
    "lat": 43.1085,
    "lng": -102.20056,
    "state_id": "SD"
  },
  {
    "zip": 57717,
    "lat": 44.9518,
    "lng": -103.88836,
    "state_id": "SD"
  },
  {
    "zip": 57718,
    "lat": 44.17891,
    "lng": -103.3648,
    "state_id": "SD"
  },
  {
    "zip": 57719,
    "lat": 44.23334,
    "lng": -102.98161,
    "state_id": "SD"
  },
  {
    "zip": 57720,
    "lat": 45.56634,
    "lng": -103.61302,
    "state_id": "SD"
  },
  {
    "zip": 57722,
    "lat": 43.46359,
    "lng": -102.93334,
    "state_id": "SD"
  },
  {
    "zip": 57724,
    "lat": 45.64027,
    "lng": -104.02776,
    "state_id": "SD"
  },
  {
    "zip": 57725,
    "lat": 43.94008,
    "lng": -102.80947,
    "state_id": "SD"
  },
  {
    "zip": 57730,
    "lat": 43.71951,
    "lng": -103.6317,
    "state_id": "SD"
  },
  {
    "zip": 57732,
    "lat": 44.24611,
    "lng": -103.65397,
    "state_id": "SD"
  },
  {
    "zip": 57735,
    "lat": 43.30066,
    "lng": -103.85448,
    "state_id": "SD"
  },
  {
    "zip": 57738,
    "lat": 43.65073,
    "lng": -103.12421,
    "state_id": "SD"
  },
  {
    "zip": 57741,
    "lat": 44.40493,
    "lng": -103.46671,
    "state_id": "SD"
  },
  {
    "zip": 57744,
    "lat": 43.77402,
    "lng": -103.03646,
    "state_id": "SD"
  },
  {
    "zip": 57745,
    "lat": 44.02159,
    "lng": -103.77986,
    "state_id": "SD"
  },
  {
    "zip": 57747,
    "lat": 43.38497,
    "lng": -103.52898,
    "state_id": "SD"
  },
  {
    "zip": 57748,
    "lat": 44.58384,
    "lng": -102.02291,
    "state_id": "SD"
  },
  {
    "zip": 57750,
    "lat": 43.59156,
    "lng": -101.95411,
    "state_id": "SD"
  },
  {
    "zip": 57751,
    "lat": 43.85544,
    "lng": -103.38659,
    "state_id": "SD"
  },
  {
    "zip": 57752,
    "lat": 43.46467,
    "lng": -102.19338,
    "state_id": "SD"
  },
  {
    "zip": 57754,
    "lat": 44.263,
    "lng": -103.87125,
    "state_id": "SD"
  },
  {
    "zip": 57755,
    "lat": 45.83664,
    "lng": -103.34919,
    "state_id": "SD"
  },
  {
    "zip": 57756,
    "lat": 43.23568,
    "lng": -102.49781,
    "state_id": "SD"
  },
  {
    "zip": 57758,
    "lat": 44.97064,
    "lng": -102.69678,
    "state_id": "SD"
  },
  {
    "zip": 57759,
    "lat": 44.22399,
    "lng": -103.55987,
    "state_id": "SD"
  },
  {
    "zip": 57760,
    "lat": 44.97606,
    "lng": -103.2548,
    "state_id": "SD"
  },
  {
    "zip": 57761,
    "lat": 44.22941,
    "lng": -102.73762,
    "state_id": "SD"
  },
  {
    "zip": 57762,
    "lat": 44.75323,
    "lng": -103.61064,
    "state_id": "SD"
  },
  {
    "zip": 57763,
    "lat": 43.11474,
    "lng": -103.25651,
    "state_id": "SD"
  },
  {
    "zip": 57764,
    "lat": 43.18657,
    "lng": -102.83334,
    "state_id": "SD"
  },
  {
    "zip": 57766,
    "lat": 43.39823,
    "lng": -103.20234,
    "state_id": "SD"
  },
  {
    "zip": 57767,
    "lat": 44.13481,
    "lng": -102.57973,
    "state_id": "SD"
  },
  {
    "zip": 57769,
    "lat": 44.24855,
    "lng": -103.30207,
    "state_id": "SD"
  },
  {
    "zip": 57770,
    "lat": 43.07025,
    "lng": -102.58986,
    "state_id": "SD"
  },
  {
    "zip": 57772,
    "lat": 43.50735,
    "lng": -102.52564,
    "state_id": "SD"
  },
  {
    "zip": 57773,
    "lat": 43.59211,
    "lng": -103.56817,
    "state_id": "SD"
  },
  {
    "zip": 57775,
    "lat": 44.10347,
    "lng": -102.01598,
    "state_id": "SD"
  },
  {
    "zip": 57776,
    "lat": 45.26374,
    "lng": -103.49577,
    "state_id": "SD"
  },
  {
    "zip": 57779,
    "lat": 44.56278,
    "lng": -103.75782,
    "state_id": "SD"
  },
  {
    "zip": 57780,
    "lat": 43.78208,
    "lng": -102.5036,
    "state_id": "SD"
  },
  {
    "zip": 57782,
    "lat": 43.30243,
    "lng": -103.11207,
    "state_id": "SD"
  },
  {
    "zip": 57783,
    "lat": 44.45605,
    "lng": -103.92832,
    "state_id": "SD"
  },
  {
    "zip": 57785,
    "lat": 44.42539,
    "lng": -103.22842,
    "state_id": "SD"
  },
  {
    "zip": 57787,
    "lat": 44.68205,
    "lng": -102.65549,
    "state_id": "SD"
  },
  {
    "zip": 57788,
    "lat": 44.6297,
    "lng": -103.19406,
    "state_id": "SD"
  },
  {
    "zip": 57790,
    "lat": 44.08293,
    "lng": -102.21554,
    "state_id": "SD"
  },
  {
    "zip": 57791,
    "lat": 44.22501,
    "lng": -102.43111,
    "state_id": "SD"
  },
  {
    "zip": 57792,
    "lat": 44.53919,
    "lng": -102.41636,
    "state_id": "SD"
  },
  {
    "zip": 57793,
    "lat": 44.52048,
    "lng": -103.58514,
    "state_id": "SD"
  },
  {
    "zip": 57794,
    "lat": 43.12403,
    "lng": -102.39483,
    "state_id": "SD"
  },
  {
    "zip": 57799,
    "lat": 44.49871,
    "lng": -103.87245,
    "state_id": "SD"
  },
  {
    "zip": 37010,
    "lat": 36.56763,
    "lng": -87.1088,
    "state_id": "TN"
  },
  {
    "zip": 37012,
    "lat": 36.07386,
    "lng": -86.00151,
    "state_id": "TN"
  },
  {
    "zip": 37013,
    "lat": 36.04709,
    "lng": -86.63244,
    "state_id": "TN"
  },
  {
    "zip": 37014,
    "lat": 35.86957,
    "lng": -86.65754,
    "state_id": "TN"
  },
  {
    "zip": 37015,
    "lat": 36.28416,
    "lng": -87.07721,
    "state_id": "TN"
  },
  {
    "zip": 37016,
    "lat": 35.95746,
    "lng": -86.10025,
    "state_id": "TN"
  },
  {
    "zip": 37018,
    "lat": 35.63864,
    "lng": -86.19707,
    "state_id": "TN"
  },
  {
    "zip": 37019,
    "lat": 35.39228,
    "lng": -86.70979,
    "state_id": "TN"
  },
  {
    "zip": 37020,
    "lat": 35.62659,
    "lng": -86.40264,
    "state_id": "TN"
  },
  {
    "zip": 37022,
    "lat": 36.50014,
    "lng": -86.31075,
    "state_id": "TN"
  },
  {
    "zip": 37023,
    "lat": 36.58337,
    "lng": -87.78431,
    "state_id": "TN"
  },
  {
    "zip": 37025,
    "lat": 35.9487,
    "lng": -87.30774,
    "state_id": "TN"
  },
  {
    "zip": 37026,
    "lat": 35.69649,
    "lng": -86.10563,
    "state_id": "TN"
  },
  {
    "zip": 37027,
    "lat": 35.99939,
    "lng": -86.78396,
    "state_id": "TN"
  },
  {
    "zip": 37028,
    "lat": 36.62864,
    "lng": -87.85948,
    "state_id": "TN"
  },
  {
    "zip": 37029,
    "lat": 36.04186,
    "lng": -87.26452,
    "state_id": "TN"
  },
  {
    "zip": 37030,
    "lat": 36.27439,
    "lng": -85.94758,
    "state_id": "TN"
  },
  {
    "zip": 37031,
    "lat": 36.37137,
    "lng": -86.29964,
    "state_id": "TN"
  },
  {
    "zip": 37032,
    "lat": 36.52424,
    "lng": -87.01714,
    "state_id": "TN"
  },
  {
    "zip": 37033,
    "lat": 35.7343,
    "lng": -87.55114,
    "state_id": "TN"
  },
  {
    "zip": 37034,
    "lat": 35.64058,
    "lng": -86.69168,
    "state_id": "TN"
  },
  {
    "zip": 37035,
    "lat": 36.38335,
    "lng": -87.12718,
    "state_id": "TN"
  },
  {
    "zip": 37036,
    "lat": 36.23988,
    "lng": -87.27192,
    "state_id": "TN"
  },
  {
    "zip": 37037,
    "lat": 35.70277,
    "lng": -86.3515,
    "state_id": "TN"
  },
  {
    "zip": 37040,
    "lat": 36.52333,
    "lng": -87.33336,
    "state_id": "TN"
  },
  {
    "zip": 37042,
    "lat": 36.56905,
    "lng": -87.41699,
    "state_id": "TN"
  },
  {
    "zip": 37043,
    "lat": 36.49577,
    "lng": -87.23242,
    "state_id": "TN"
  },
  {
    "zip": 37046,
    "lat": 35.77988,
    "lng": -86.71733,
    "state_id": "TN"
  },
  {
    "zip": 37047,
    "lat": 35.31732,
    "lng": -86.8358,
    "state_id": "TN"
  },
  {
    "zip": 37048,
    "lat": 36.49131,
    "lng": -86.5959,
    "state_id": "TN"
  },
  {
    "zip": 37049,
    "lat": 36.5601,
    "lng": -86.67715,
    "state_id": "TN"
  },
  {
    "zip": 37050,
    "lat": 36.37286,
    "lng": -87.63061,
    "state_id": "TN"
  },
  {
    "zip": 37051,
    "lat": 36.30428,
    "lng": -87.41023,
    "state_id": "TN"
  },
  {
    "zip": 37052,
    "lat": 36.3735,
    "lng": -87.3988,
    "state_id": "TN"
  },
  {
    "zip": 37055,
    "lat": 36.07849,
    "lng": -87.44267,
    "state_id": "TN"
  },
  {
    "zip": 37057,
    "lat": 36.40324,
    "lng": -86.02839,
    "state_id": "TN"
  },
  {
    "zip": 37058,
    "lat": 36.47884,
    "lng": -87.87961,
    "state_id": "TN"
  },
  {
    "zip": 37059,
    "lat": 35.98748,
    "lng": -85.90928,
    "state_id": "TN"
  },
  {
    "zip": 37060,
    "lat": 35.74546,
    "lng": -86.63775,
    "state_id": "TN"
  },
  {
    "zip": 37061,
    "lat": 36.28313,
    "lng": -87.65522,
    "state_id": "TN"
  },
  {
    "zip": 37062,
    "lat": 35.98321,
    "lng": -87.13346,
    "state_id": "TN"
  },
  {
    "zip": 37064,
    "lat": 35.88756,
    "lng": -86.95881,
    "state_id": "TN"
  },
  {
    "zip": 37066,
    "lat": 36.39871,
    "lng": -86.45526,
    "state_id": "TN"
  },
  {
    "zip": 37067,
    "lat": 35.91374,
    "lng": -86.77838,
    "state_id": "TN"
  },
  {
    "zip": 37069,
    "lat": 35.98848,
    "lng": -86.90478,
    "state_id": "TN"
  },
  {
    "zip": 37072,
    "lat": 36.35751,
    "lng": -86.74424,
    "state_id": "TN"
  },
  {
    "zip": 37073,
    "lat": 36.4279,
    "lng": -86.81408,
    "state_id": "TN"
  },
  {
    "zip": 37074,
    "lat": 36.41897,
    "lng": -86.14428,
    "state_id": "TN"
  },
  {
    "zip": 37075,
    "lat": 36.33995,
    "lng": -86.60658,
    "state_id": "TN"
  },
  {
    "zip": 37076,
    "lat": 36.14947,
    "lng": -86.58188,
    "state_id": "TN"
  },
  {
    "zip": 37078,
    "lat": 35.92275,
    "lng": -87.76786,
    "state_id": "TN"
  },
  {
    "zip": 37079,
    "lat": 36.48171,
    "lng": -87.65934,
    "state_id": "TN"
  },
  {
    "zip": 37080,
    "lat": 36.32824,
    "lng": -86.91676,
    "state_id": "TN"
  },
  {
    "zip": 37082,
    "lat": 36.09666,
    "lng": -87.11342,
    "state_id": "TN"
  },
  {
    "zip": 37083,
    "lat": 36.54396,
    "lng": -86.00239,
    "state_id": "TN"
  },
  {
    "zip": 37085,
    "lat": 35.95483,
    "lng": -86.2805,
    "state_id": "TN"
  },
  {
    "zip": 37086,
    "lat": 36.0212,
    "lng": -86.55922,
    "state_id": "TN"
  },
  {
    "zip": 37087,
    "lat": 36.27275,
    "lng": -86.27211,
    "state_id": "TN"
  },
  {
    "zip": 37090,
    "lat": 36.12506,
    "lng": -86.29667,
    "state_id": "TN"
  },
  {
    "zip": 37091,
    "lat": 35.48621,
    "lng": -86.76278,
    "state_id": "TN"
  },
  {
    "zip": 37095,
    "lat": 35.98204,
    "lng": -85.96215,
    "state_id": "TN"
  },
  {
    "zip": 37096,
    "lat": 35.60376,
    "lng": -87.86553,
    "state_id": "TN"
  },
  {
    "zip": 37097,
    "lat": 35.76961,
    "lng": -87.82657,
    "state_id": "TN"
  },
  {
    "zip": 37098,
    "lat": 35.86809,
    "lng": -87.31517,
    "state_id": "TN"
  },
  {
    "zip": 37101,
    "lat": 36.06548,
    "lng": -87.635,
    "state_id": "TN"
  },
  {
    "zip": 37110,
    "lat": 35.64419,
    "lng": -85.74463,
    "state_id": "TN"
  },
  {
    "zip": 37115,
    "lat": 36.2545,
    "lng": -86.69608,
    "state_id": "TN"
  },
  {
    "zip": 37118,
    "lat": 35.93024,
    "lng": -86.19021,
    "state_id": "TN"
  },
  {
    "zip": 37122,
    "lat": 36.1775,
    "lng": -86.4889,
    "state_id": "TN"
  },
  {
    "zip": 37127,
    "lat": 35.78119,
    "lng": -86.32774,
    "state_id": "TN"
  },
  {
    "zip": 37128,
    "lat": 35.79915,
    "lng": -86.47277,
    "state_id": "TN"
  },
  {
    "zip": 37129,
    "lat": 35.92713,
    "lng": -86.44048,
    "state_id": "TN"
  },
  {
    "zip": 37130,
    "lat": 35.87897,
    "lng": -86.32911,
    "state_id": "TN"
  },
  {
    "zip": 37132,
    "lat": 35.84933,
    "lng": -86.36431,
    "state_id": "TN"
  },
  {
    "zip": 37134,
    "lat": 36.00132,
    "lng": -87.95077,
    "state_id": "TN"
  },
  {
    "zip": 37135,
    "lat": 35.93634,
    "lng": -86.66601,
    "state_id": "TN"
  },
  {
    "zip": 37137,
    "lat": 35.87602,
    "lng": -87.50113,
    "state_id": "TN"
  },
  {
    "zip": 37138,
    "lat": 36.24669,
    "lng": -86.61941,
    "state_id": "TN"
  },
  {
    "zip": 37140,
    "lat": 35.85917,
    "lng": -87.6525,
    "state_id": "TN"
  },
  {
    "zip": 37141,
    "lat": 36.61501,
    "lng": -86.70198,
    "state_id": "TN"
  },
  {
    "zip": 37142,
    "lat": 36.41114,
    "lng": -87.50134,
    "state_id": "TN"
  },
  {
    "zip": 37143,
    "lat": 36.12781,
    "lng": -87.03094,
    "state_id": "TN"
  },
  {
    "zip": 37144,
    "lat": 35.30522,
    "lng": -86.66033,
    "state_id": "TN"
  },
  {
    "zip": 37145,
    "lat": 36.37944,
    "lng": -85.91221,
    "state_id": "TN"
  },
  {
    "zip": 37146,
    "lat": 36.40163,
    "lng": -87.03332,
    "state_id": "TN"
  },
  {
    "zip": 37148,
    "lat": 36.57393,
    "lng": -86.50187,
    "state_id": "TN"
  },
  {
    "zip": 37149,
    "lat": 35.80403,
    "lng": -86.19282,
    "state_id": "TN"
  },
  {
    "zip": 37150,
    "lat": 36.54102,
    "lng": -85.8135,
    "state_id": "TN"
  },
  {
    "zip": 37151,
    "lat": 36.31758,
    "lng": -86.0399,
    "state_id": "TN"
  },
  {
    "zip": 37153,
    "lat": 35.76385,
    "lng": -86.55397,
    "state_id": "TN"
  },
  {
    "zip": 37160,
    "lat": 35.46396,
    "lng": -86.48382,
    "state_id": "TN"
  },
  {
    "zip": 37165,
    "lat": 36.29213,
    "lng": -87.47016,
    "state_id": "TN"
  },
  {
    "zip": 37166,
    "lat": 35.92461,
    "lng": -85.78929,
    "state_id": "TN"
  },
  {
    "zip": 37167,
    "lat": 35.95857,
    "lng": -86.53269,
    "state_id": "TN"
  },
  {
    "zip": 37171,
    "lat": 36.36655,
    "lng": -87.29988,
    "state_id": "TN"
  },
  {
    "zip": 37172,
    "lat": 36.53551,
    "lng": -86.861,
    "state_id": "TN"
  },
  {
    "zip": 37174,
    "lat": 35.72177,
    "lng": -86.89988,
    "state_id": "TN"
  },
  {
    "zip": 37175,
    "lat": 36.32711,
    "lng": -87.89722,
    "state_id": "TN"
  },
  {
    "zip": 37178,
    "lat": 36.32318,
    "lng": -87.78422,
    "state_id": "TN"
  },
  {
    "zip": 37179,
    "lat": 35.81157,
    "lng": -86.92773,
    "state_id": "TN"
  },
  {
    "zip": 37180,
    "lat": 35.61158,
    "lng": -86.58122,
    "state_id": "TN"
  },
  {
    "zip": 37181,
    "lat": 36.24481,
    "lng": -87.48181,
    "state_id": "TN"
  },
  {
    "zip": 37183,
    "lat": 35.50287,
    "lng": -86.3089,
    "state_id": "TN"
  },
  {
    "zip": 37184,
    "lat": 36.08245,
    "lng": -86.14409,
    "state_id": "TN"
  },
  {
    "zip": 37185,
    "lat": 36.0697,
    "lng": -87.83801,
    "state_id": "TN"
  },
  {
    "zip": 37186,
    "lat": 36.58703,
    "lng": -86.23011,
    "state_id": "TN"
  },
  {
    "zip": 37187,
    "lat": 36.14706,
    "lng": -87.19956,
    "state_id": "TN"
  },
  {
    "zip": 37188,
    "lat": 36.48024,
    "lng": -86.68133,
    "state_id": "TN"
  },
  {
    "zip": 37189,
    "lat": 36.28034,
    "lng": -86.82828,
    "state_id": "TN"
  },
  {
    "zip": 37190,
    "lat": 35.82913,
    "lng": -86.03992,
    "state_id": "TN"
  },
  {
    "zip": 37191,
    "lat": 36.49963,
    "lng": -87.53127,
    "state_id": "TN"
  },
  {
    "zip": 37201,
    "lat": 36.16618,
    "lng": -86.77756,
    "state_id": "TN"
  },
  {
    "zip": 37203,
    "lat": 36.14934,
    "lng": -86.79033,
    "state_id": "TN"
  },
  {
    "zip": 37204,
    "lat": 36.10674,
    "lng": -86.77425,
    "state_id": "TN"
  },
  {
    "zip": 37205,
    "lat": 36.11121,
    "lng": -86.87256,
    "state_id": "TN"
  },
  {
    "zip": 37206,
    "lat": 36.18096,
    "lng": -86.73105,
    "state_id": "TN"
  },
  {
    "zip": 37207,
    "lat": 36.23282,
    "lng": -86.77655,
    "state_id": "TN"
  },
  {
    "zip": 37208,
    "lat": 36.17769,
    "lng": -86.80786,
    "state_id": "TN"
  },
  {
    "zip": 37209,
    "lat": 36.15547,
    "lng": -86.92156,
    "state_id": "TN"
  },
  {
    "zip": 37210,
    "lat": 36.14432,
    "lng": -86.73367,
    "state_id": "TN"
  },
  {
    "zip": 37211,
    "lat": 36.06717,
    "lng": -86.7237,
    "state_id": "TN"
  },
  {
    "zip": 37212,
    "lat": 36.13386,
    "lng": -86.80117,
    "state_id": "TN"
  },
  {
    "zip": 37213,
    "lat": 36.16628,
    "lng": -86.76694,
    "state_id": "TN"
  },
  {
    "zip": 37214,
    "lat": 36.16308,
    "lng": -86.6651,
    "state_id": "TN"
  },
  {
    "zip": 37215,
    "lat": 36.08192,
    "lng": -86.83469,
    "state_id": "TN"
  },
  {
    "zip": 37216,
    "lat": 36.21609,
    "lng": -86.72645,
    "state_id": "TN"
  },
  {
    "zip": 37217,
    "lat": 36.10517,
    "lng": -86.65907,
    "state_id": "TN"
  },
  {
    "zip": 37218,
    "lat": 36.20696,
    "lng": -86.89257,
    "state_id": "TN"
  },
  {
    "zip": 37219,
    "lat": 36.16697,
    "lng": -86.78319,
    "state_id": "TN"
  },
  {
    "zip": 37220,
    "lat": 36.06728,
    "lng": -86.78088,
    "state_id": "TN"
  },
  {
    "zip": 37221,
    "lat": 36.05561,
    "lng": -86.9734,
    "state_id": "TN"
  },
  {
    "zip": 37228,
    "lat": 36.19498,
    "lng": -86.80403,
    "state_id": "TN"
  },
  {
    "zip": 37240,
    "lat": 36.14489,
    "lng": -86.80548,
    "state_id": "TN"
  },
  {
    "zip": 37243,
    "lat": 36.16503,
    "lng": -86.782,
    "state_id": "TN"
  },
  {
    "zip": 37246,
    "lat": 36.15986,
    "lng": -86.79143,
    "state_id": "TN"
  },
  {
    "zip": 37301,
    "lat": 35.41971,
    "lng": -85.79526,
    "state_id": "TN"
  },
  {
    "zip": 37302,
    "lat": 35.01323,
    "lng": -85.01622,
    "state_id": "TN"
  },
  {
    "zip": 37303,
    "lat": 35.4407,
    "lng": -84.64319,
    "state_id": "TN"
  },
  {
    "zip": 37305,
    "lat": 35.46518,
    "lng": -85.69423,
    "state_id": "TN"
  },
  {
    "zip": 37306,
    "lat": 35.09863,
    "lng": -86.18923,
    "state_id": "TN"
  },
  {
    "zip": 37307,
    "lat": 35.16905,
    "lng": -84.6258,
    "state_id": "TN"
  },
  {
    "zip": 37308,
    "lat": 35.3483,
    "lng": -84.9962,
    "state_id": "TN"
  },
  {
    "zip": 37309,
    "lat": 35.30336,
    "lng": -84.73759,
    "state_id": "TN"
  },
  {
    "zip": 37310,
    "lat": 35.26387,
    "lng": -84.78005,
    "state_id": "TN"
  },
  {
    "zip": 37311,
    "lat": 35.11286,
    "lng": -84.92865,
    "state_id": "TN"
  },
  {
    "zip": 37312,
    "lat": 35.22985,
    "lng": -84.87066,
    "state_id": "TN"
  },
  {
    "zip": 37313,
    "lat": 35.36125,
    "lng": -85.71265,
    "state_id": "TN"
  },
  {
    "zip": 37315,
    "lat": 35.04812,
    "lng": -85.0517,
    "state_id": "TN"
  },
  {
    "zip": 37317,
    "lat": 35.03146,
    "lng": -84.44343,
    "state_id": "TN"
  },
  {
    "zip": 37318,
    "lat": 35.17787,
    "lng": -85.9926,
    "state_id": "TN"
  },
  {
    "zip": 37321,
    "lat": 35.50222,
    "lng": -85.00767,
    "state_id": "TN"
  },
  {
    "zip": 37322,
    "lat": 35.49567,
    "lng": -84.81601,
    "state_id": "TN"
  },
  {
    "zip": 37323,
    "lat": 35.09721,
    "lng": -84.82058,
    "state_id": "TN"
  },
  {
    "zip": 37324,
    "lat": 35.25512,
    "lng": -85.99133,
    "state_id": "TN"
  },
  {
    "zip": 37325,
    "lat": 35.24957,
    "lng": -84.59176,
    "state_id": "TN"
  },
  {
    "zip": 37326,
    "lat": 35.04003,
    "lng": -84.38463,
    "state_id": "TN"
  },
  {
    "zip": 37327,
    "lat": 35.42277,
    "lng": -85.40585,
    "state_id": "TN"
  },
  {
    "zip": 37328,
    "lat": 35.02721,
    "lng": -86.36666,
    "state_id": "TN"
  },
  {
    "zip": 37329,
    "lat": 35.39437,
    "lng": -84.46102,
    "state_id": "TN"
  },
  {
    "zip": 37330,
    "lat": 35.28183,
    "lng": -86.12986,
    "state_id": "TN"
  },
  {
    "zip": 37331,
    "lat": 35.31936,
    "lng": -84.53023,
    "state_id": "TN"
  },
  {
    "zip": 37332,
    "lat": 35.59543,
    "lng": -84.97315,
    "state_id": "TN"
  },
  {
    "zip": 37333,
    "lat": 35.15379,
    "lng": -84.33545,
    "state_id": "TN"
  },
  {
    "zip": 37334,
    "lat": 35.16682,
    "lng": -86.5811,
    "state_id": "TN"
  },
  {
    "zip": 37335,
    "lat": 35.06482,
    "lng": -86.40464,
    "state_id": "TN"
  },
  {
    "zip": 37336,
    "lat": 35.30247,
    "lng": -84.93327,
    "state_id": "TN"
  },
  {
    "zip": 37337,
    "lat": 35.78338,
    "lng": -84.87054,
    "state_id": "TN"
  },
  {
    "zip": 37338,
    "lat": 35.42077,
    "lng": -85.21405,
    "state_id": "TN"
  },
  {
    "zip": 37339,
    "lat": 35.37992,
    "lng": -85.63424,
    "state_id": "TN"
  },
  {
    "zip": 37340,
    "lat": 35.0317,
    "lng": -85.52315,
    "state_id": "TN"
  },
  {
    "zip": 37341,
    "lat": 35.21551,
    "lng": -85.08404,
    "state_id": "TN"
  },
  {
    "zip": 37342,
    "lat": 35.38825,
    "lng": -85.94763,
    "state_id": "TN"
  },
  {
    "zip": 37343,
    "lat": 35.16884,
    "lng": -85.20915,
    "state_id": "TN"
  },
  {
    "zip": 37345,
    "lat": 35.03681,
    "lng": -86.24032,
    "state_id": "TN"
  },
  {
    "zip": 37347,
    "lat": 35.06113,
    "lng": -85.61895,
    "state_id": "TN"
  },
  {
    "zip": 37348,
    "lat": 35.12594,
    "lng": -86.4373,
    "state_id": "TN"
  },
  {
    "zip": 37350,
    "lat": 34.99383,
    "lng": -85.35021,
    "state_id": "TN"
  },
  {
    "zip": 37351,
    "lat": 35.10096,
    "lng": -85.2615,
    "state_id": "TN"
  },
  {
    "zip": 37352,
    "lat": 35.28091,
    "lng": -86.35526,
    "state_id": "TN"
  },
  {
    "zip": 37353,
    "lat": 35.11867,
    "lng": -84.98667,
    "state_id": "TN"
  },
  {
    "zip": 37354,
    "lat": 35.50011,
    "lng": -84.34843,
    "state_id": "TN"
  },
  {
    "zip": 37355,
    "lat": 35.50099,
    "lng": -86.08476,
    "state_id": "TN"
  },
  {
    "zip": 37356,
    "lat": 35.22901,
    "lng": -85.82423,
    "state_id": "TN"
  },
  {
    "zip": 37357,
    "lat": 35.58607,
    "lng": -85.91716,
    "state_id": "TN"
  },
  {
    "zip": 37359,
    "lat": 35.2042,
    "lng": -86.41289,
    "state_id": "TN"
  },
  {
    "zip": 37360,
    "lat": 35.44056,
    "lng": -86.25495,
    "state_id": "TN"
  },
  {
    "zip": 37361,
    "lat": 35.09882,
    "lng": -84.68632,
    "state_id": "TN"
  },
  {
    "zip": 37362,
    "lat": 35.03518,
    "lng": -84.68734,
    "state_id": "TN"
  },
  {
    "zip": 37363,
    "lat": 35.11406,
    "lng": -85.06431,
    "state_id": "TN"
  },
  {
    "zip": 37365,
    "lat": 35.38231,
    "lng": -85.56122,
    "state_id": "TN"
  },
  {
    "zip": 37366,
    "lat": 35.32119,
    "lng": -85.83808,
    "state_id": "TN"
  },
  {
    "zip": 37367,
    "lat": 35.63171,
    "lng": -85.2266,
    "state_id": "TN"
  },
  {
    "zip": 37369,
    "lat": 35.19207,
    "lng": -84.47983,
    "state_id": "TN"
  },
  {
    "zip": 37370,
    "lat": 35.36,
    "lng": -84.70294,
    "state_id": "TN"
  },
  {
    "zip": 37373,
    "lat": 35.398,
    "lng": -85.10278,
    "state_id": "TN"
  },
  {
    "zip": 37374,
    "lat": 35.18911,
    "lng": -85.6565,
    "state_id": "TN"
  },
  {
    "zip": 37375,
    "lat": 35.15429,
    "lng": -85.89178,
    "state_id": "TN"
  },
  {
    "zip": 37376,
    "lat": 35.04786,
    "lng": -85.9559,
    "state_id": "TN"
  },
  {
    "zip": 37377,
    "lat": 35.20885,
    "lng": -85.33527,
    "state_id": "TN"
  },
  {
    "zip": 37379,
    "lat": 35.29449,
    "lng": -85.17352,
    "state_id": "TN"
  },
  {
    "zip": 37380,
    "lat": 35.07588,
    "lng": -85.75437,
    "state_id": "TN"
  },
  {
    "zip": 37381,
    "lat": 35.68138,
    "lng": -84.87675,
    "state_id": "TN"
  },
  {
    "zip": 37385,
    "lat": 35.3312,
    "lng": -84.24295,
    "state_id": "TN"
  },
  {
    "zip": 37387,
    "lat": 35.27334,
    "lng": -85.74694,
    "state_id": "TN"
  },
  {
    "zip": 37388,
    "lat": 35.34685,
    "lng": -86.21998,
    "state_id": "TN"
  },
  {
    "zip": 37391,
    "lat": 35.0894,
    "lng": -84.35294,
    "state_id": "TN"
  },
  {
    "zip": 37394,
    "lat": 35.53954,
    "lng": -85.86004,
    "state_id": "TN"
  },
  {
    "zip": 37396,
    "lat": 35.00591,
    "lng": -85.49979,
    "state_id": "TN"
  },
  {
    "zip": 37397,
    "lat": 35.21755,
    "lng": -85.51563,
    "state_id": "TN"
  },
  {
    "zip": 37398,
    "lat": 35.18375,
    "lng": -86.13986,
    "state_id": "TN"
  },
  {
    "zip": 37402,
    "lat": 35.04357,
    "lng": -85.3184,
    "state_id": "TN"
  },
  {
    "zip": 37403,
    "lat": 35.04774,
    "lng": -85.29452,
    "state_id": "TN"
  },
  {
    "zip": 37404,
    "lat": 35.02794,
    "lng": -85.27369,
    "state_id": "TN"
  },
  {
    "zip": 37405,
    "lat": 35.12144,
    "lng": -85.40589,
    "state_id": "TN"
  },
  {
    "zip": 37406,
    "lat": 35.07224,
    "lng": -85.24555,
    "state_id": "TN"
  },
  {
    "zip": 37407,
    "lat": 35.00206,
    "lng": -85.28961,
    "state_id": "TN"
  },
  {
    "zip": 37408,
    "lat": 35.02868,
    "lng": -85.30849,
    "state_id": "TN"
  },
  {
    "zip": 37409,
    "lat": 35.00197,
    "lng": -85.34466,
    "state_id": "TN"
  },
  {
    "zip": 37410,
    "lat": 35.00209,
    "lng": -85.31377,
    "state_id": "TN"
  },
  {
    "zip": 37411,
    "lat": 35.02876,
    "lng": -85.22618,
    "state_id": "TN"
  },
  {
    "zip": 37412,
    "lat": 34.99752,
    "lng": -85.22748,
    "state_id": "TN"
  },
  {
    "zip": 37415,
    "lat": 35.12733,
    "lng": -85.28017,
    "state_id": "TN"
  },
  {
    "zip": 37416,
    "lat": 35.10444,
    "lng": -85.17662,
    "state_id": "TN"
  },
  {
    "zip": 37419,
    "lat": 35.03832,
    "lng": -85.40818,
    "state_id": "TN"
  },
  {
    "zip": 37421,
    "lat": 35.0289,
    "lng": -85.15095,
    "state_id": "TN"
  },
  {
    "zip": 37601,
    "lat": 36.33029,
    "lng": -82.32145,
    "state_id": "TN"
  },
  {
    "zip": 37604,
    "lat": 36.2996,
    "lng": -82.3832,
    "state_id": "TN"
  },
  {
    "zip": 37614,
    "lat": 36.30143,
    "lng": -82.37123,
    "state_id": "TN"
  },
  {
    "zip": 37615,
    "lat": 36.39958,
    "lng": -82.45219,
    "state_id": "TN"
  },
  {
    "zip": 37616,
    "lat": 36.22652,
    "lng": -82.74757,
    "state_id": "TN"
  },
  {
    "zip": 37617,
    "lat": 36.53067,
    "lng": -82.37208,
    "state_id": "TN"
  },
  {
    "zip": 37618,
    "lat": 36.46567,
    "lng": -82.20725,
    "state_id": "TN"
  },
  {
    "zip": 37620,
    "lat": 36.55139,
    "lng": -82.09777,
    "state_id": "TN"
  },
  {
    "zip": 37640,
    "lat": 36.32921,
    "lng": -81.97852,
    "state_id": "TN"
  },
  {
    "zip": 37641,
    "lat": 36.19901,
    "lng": -82.66422,
    "state_id": "TN"
  },
  {
    "zip": 37642,
    "lat": 36.52903,
    "lng": -82.72991,
    "state_id": "TN"
  },
  {
    "zip": 37643,
    "lat": 36.37734,
    "lng": -82.13641,
    "state_id": "TN"
  },
  {
    "zip": 37645,
    "lat": 36.5621,
    "lng": -82.66232,
    "state_id": "TN"
  },
  {
    "zip": 37650,
    "lat": 36.10446,
    "lng": -82.45622,
    "state_id": "TN"
  },
  {
    "zip": 37656,
    "lat": 36.39878,
    "lng": -82.62985,
    "state_id": "TN"
  },
  {
    "zip": 37657,
    "lat": 36.01758,
    "lng": -82.56019,
    "state_id": "TN"
  },
  {
    "zip": 37658,
    "lat": 36.25846,
    "lng": -82.14069,
    "state_id": "TN"
  },
  {
    "zip": 37659,
    "lat": 36.28012,
    "lng": -82.4939,
    "state_id": "TN"
  },
  {
    "zip": 37660,
    "lat": 36.52905,
    "lng": -82.57456,
    "state_id": "TN"
  },
  {
    "zip": 37663,
    "lat": 36.46572,
    "lng": -82.48631,
    "state_id": "TN"
  },
  {
    "zip": 37664,
    "lat": 36.51329,
    "lng": -82.50576,
    "state_id": "TN"
  },
  {
    "zip": 37665,
    "lat": 36.57882,
    "lng": -82.5705,
    "state_id": "TN"
  },
  {
    "zip": 37680,
    "lat": 36.57928,
    "lng": -81.72545,
    "state_id": "TN"
  },
  {
    "zip": 37681,
    "lat": 36.25402,
    "lng": -82.62497,
    "state_id": "TN"
  },
  {
    "zip": 37682,
    "lat": 36.30106,
    "lng": -82.29077,
    "state_id": "TN"
  },
  {
    "zip": 37683,
    "lat": 36.45475,
    "lng": -81.82203,
    "state_id": "TN"
  },
  {
    "zip": 37686,
    "lat": 36.44203,
    "lng": -82.34493,
    "state_id": "TN"
  },
  {
    "zip": 37687,
    "lat": 36.18003,
    "lng": -82.09912,
    "state_id": "TN"
  },
  {
    "zip": 37688,
    "lat": 36.54547,
    "lng": -81.88283,
    "state_id": "TN"
  },
  {
    "zip": 37690,
    "lat": 36.24926,
    "lng": -82.55645,
    "state_id": "TN"
  },
  {
    "zip": 37691,
    "lat": 36.37168,
    "lng": -81.75667,
    "state_id": "TN"
  },
  {
    "zip": 37692,
    "lat": 36.18977,
    "lng": -82.30084,
    "state_id": "TN"
  },
  {
    "zip": 37694,
    "lat": 36.38621,
    "lng": -82.27585,
    "state_id": "TN"
  },
  {
    "zip": 37701,
    "lat": 35.79414,
    "lng": -83.9846,
    "state_id": "TN"
  },
  {
    "zip": 37705,
    "lat": 36.22893,
    "lng": -84.01005,
    "state_id": "TN"
  },
  {
    "zip": 37708,
    "lat": 36.32525,
    "lng": -83.33404,
    "state_id": "TN"
  },
  {
    "zip": 37709,
    "lat": 36.16497,
    "lng": -83.6648,
    "state_id": "TN"
  },
  {
    "zip": 37710,
    "lat": 36.15863,
    "lng": -84.31611,
    "state_id": "TN"
  },
  {
    "zip": 37711,
    "lat": 36.28318,
    "lng": -83.03736,
    "state_id": "TN"
  },
  {
    "zip": 37713,
    "lat": 36.09017,
    "lng": -83.13206,
    "state_id": "TN"
  },
  {
    "zip": 37714,
    "lat": 36.26643,
    "lng": -84.26994,
    "state_id": "TN"
  },
  {
    "zip": 37715,
    "lat": 36.55424,
    "lng": -83.96049,
    "state_id": "TN"
  },
  {
    "zip": 37716,
    "lat": 36.09954,
    "lng": -84.16803,
    "state_id": "TN"
  },
  {
    "zip": 37719,
    "lat": 36.02246,
    "lng": -84.42936,
    "state_id": "TN"
  },
  {
    "zip": 37721,
    "lat": 36.13176,
    "lng": -83.80706,
    "state_id": "TN"
  },
  {
    "zip": 37722,
    "lat": 35.80702,
    "lng": -83.23447,
    "state_id": "TN"
  },
  {
    "zip": 37723,
    "lat": 35.95367,
    "lng": -84.82058,
    "state_id": "TN"
  },
  {
    "zip": 37724,
    "lat": 36.54689,
    "lng": -83.70098,
    "state_id": "TN"
  },
  {
    "zip": 37725,
    "lat": 35.99875,
    "lng": -83.39826,
    "state_id": "TN"
  },
  {
    "zip": 37726,
    "lat": 36.20754,
    "lng": -84.8344,
    "state_id": "TN"
  },
  {
    "zip": 37727,
    "lat": 35.88807,
    "lng": -83.00727,
    "state_id": "TN"
  },
  {
    "zip": 37729,
    "lat": 36.51669,
    "lng": -84.0345,
    "state_id": "TN"
  },
  {
    "zip": 37730,
    "lat": 36.54748,
    "lng": -83.97553,
    "state_id": "TN"
  },
  {
    "zip": 37731,
    "lat": 36.54327,
    "lng": -83.00662,
    "state_id": "TN"
  },
  {
    "zip": 37732,
    "lat": 36.32708,
    "lng": -84.6064,
    "state_id": "TN"
  },
  {
    "zip": 37733,
    "lat": 36.35405,
    "lng": -84.71124,
    "state_id": "TN"
  },
  {
    "zip": 37737,
    "lat": 35.7577,
    "lng": -84.11981,
    "state_id": "TN"
  },
  {
    "zip": 37738,
    "lat": 35.6702,
    "lng": -83.47481,
    "state_id": "TN"
  },
  {
    "zip": 37742,
    "lat": 35.6754,
    "lng": -84.18028,
    "state_id": "TN"
  },
  {
    "zip": 37743,
    "lat": 36.0655,
    "lng": -82.85674,
    "state_id": "TN"
  },
  {
    "zip": 37745,
    "lat": 36.27443,
    "lng": -82.82388,
    "state_id": "TN"
  },
  {
    "zip": 37748,
    "lat": 35.94879,
    "lng": -84.5134,
    "state_id": "TN"
  },
  {
    "zip": 37752,
    "lat": 36.56934,
    "lng": -83.53979,
    "state_id": "TN"
  },
  {
    "zip": 37753,
    "lat": 35.82308,
    "lng": -83.10035,
    "state_id": "TN"
  },
  {
    "zip": 37754,
    "lat": 36.13112,
    "lng": -84.03295,
    "state_id": "TN"
  },
  {
    "zip": 37755,
    "lat": 36.41295,
    "lng": -84.52727,
    "state_id": "TN"
  },
  {
    "zip": 37756,
    "lat": 36.3046,
    "lng": -84.41983,
    "state_id": "TN"
  },
  {
    "zip": 37757,
    "lat": 36.30165,
    "lng": -84.14314,
    "state_id": "TN"
  },
  {
    "zip": 37760,
    "lat": 36.11059,
    "lng": -83.46447,
    "state_id": "TN"
  },
  {
    "zip": 37762,
    "lat": 36.5771,
    "lng": -84.12599,
    "state_id": "TN"
  },
  {
    "zip": 37763,
    "lat": 35.82252,
    "lng": -84.49248,
    "state_id": "TN"
  },
  {
    "zip": 37764,
    "lat": 35.97092,
    "lng": -83.61716,
    "state_id": "TN"
  },
  {
    "zip": 37765,
    "lat": 36.5719,
    "lng": -83.0649,
    "state_id": "TN"
  },
  {
    "zip": 37766,
    "lat": 36.39801,
    "lng": -84.08637,
    "state_id": "TN"
  },
  {
    "zip": 37769,
    "lat": 36.21841,
    "lng": -84.15332,
    "state_id": "TN"
  },
  {
    "zip": 37770,
    "lat": 36.15335,
    "lng": -84.66307,
    "state_id": "TN"
  },
  {
    "zip": 37771,
    "lat": 35.83931,
    "lng": -84.31655,
    "state_id": "TN"
  },
  {
    "zip": 37772,
    "lat": 35.78825,
    "lng": -84.21878,
    "state_id": "TN"
  },
  {
    "zip": 37774,
    "lat": 35.7304,
    "lng": -84.35411,
    "state_id": "TN"
  },
  {
    "zip": 37777,
    "lat": 35.82711,
    "lng": -84.05208,
    "state_id": "TN"
  },
  {
    "zip": 37779,
    "lat": 36.21514,
    "lng": -83.75606,
    "state_id": "TN"
  },
  {
    "zip": 37801,
    "lat": 35.66815,
    "lng": -84.08768,
    "state_id": "TN"
  },
  {
    "zip": 37803,
    "lat": 35.66053,
    "lng": -83.98471,
    "state_id": "TN"
  },
  {
    "zip": 37804,
    "lat": 35.79207,
    "lng": -83.89312,
    "state_id": "TN"
  },
  {
    "zip": 37806,
    "lat": 36.08385,
    "lng": -83.72845,
    "state_id": "TN"
  },
  {
    "zip": 37807,
    "lat": 36.26003,
    "lng": -83.82806,
    "state_id": "TN"
  },
  {
    "zip": 37809,
    "lat": 36.15417,
    "lng": -83.03483,
    "state_id": "TN"
  },
  {
    "zip": 37810,
    "lat": 36.18021,
    "lng": -83.10307,
    "state_id": "TN"
  },
  {
    "zip": 37811,
    "lat": 36.35524,
    "lng": -83.21486,
    "state_id": "TN"
  },
  {
    "zip": 37813,
    "lat": 36.176,
    "lng": -83.26506,
    "state_id": "TN"
  },
  {
    "zip": 37814,
    "lat": 36.23563,
    "lng": -83.33153,
    "state_id": "TN"
  },
  {
    "zip": 37818,
    "lat": 36.18917,
    "lng": -82.96871,
    "state_id": "TN"
  },
  {
    "zip": 37819,
    "lat": 36.55423,
    "lng": -84.21192,
    "state_id": "TN"
  },
  {
    "zip": 37820,
    "lat": 36.08527,
    "lng": -83.56636,
    "state_id": "TN"
  },
  {
    "zip": 37821,
    "lat": 35.97622,
    "lng": -83.19483,
    "state_id": "TN"
  },
  {
    "zip": 37825,
    "lat": 36.41243,
    "lng": -83.69154,
    "state_id": "TN"
  },
  {
    "zip": 37826,
    "lat": 35.55828,
    "lng": -84.58966,
    "state_id": "TN"
  },
  {
    "zip": 37828,
    "lat": 36.19797,
    "lng": -84.06905,
    "state_id": "TN"
  },
  {
    "zip": 37829,
    "lat": 36.00388,
    "lng": -84.63241,
    "state_id": "TN"
  },
  {
    "zip": 37830,
    "lat": 35.96501,
    "lng": -84.2912,
    "state_id": "TN"
  },
  {
    "zip": 37840,
    "lat": 36.05933,
    "lng": -84.37547,
    "state_id": "TN"
  },
  {
    "zip": 37841,
    "lat": 36.51837,
    "lng": -84.57832,
    "state_id": "TN"
  },
  {
    "zip": 37843,
    "lat": 36.00787,
    "lng": -83.05135,
    "state_id": "TN"
  },
  {
    "zip": 37845,
    "lat": 36.09337,
    "lng": -84.43956,
    "state_id": "TN"
  },
  {
    "zip": 37846,
    "lat": 35.68052,
    "lng": -84.48497,
    "state_id": "TN"
  },
  {
    "zip": 37847,
    "lat": 36.4342,
    "lng": -84.27891,
    "state_id": "TN"
  },
  {
    "zip": 37848,
    "lat": 36.23572,
    "lng": -83.6801,
    "state_id": "TN"
  },
  {
    "zip": 37849,
    "lat": 36.0585,
    "lng": -84.04314,
    "state_id": "TN"
  },
  {
    "zip": 37851,
    "lat": 36.58232,
    "lng": -83.90613,
    "state_id": "TN"
  },
  {
    "zip": 37852,
    "lat": 36.32643,
    "lng": -84.58394,
    "state_id": "TN"
  },
  {
    "zip": 37853,
    "lat": 35.83629,
    "lng": -83.91275,
    "state_id": "TN"
  },
  {
    "zip": 37854,
    "lat": 35.87866,
    "lng": -84.71717,
    "state_id": "TN"
  },
  {
    "zip": 37857,
    "lat": 36.42008,
    "lng": -82.94223,
    "state_id": "TN"
  },
  {
    "zip": 37860,
    "lat": 36.25305,
    "lng": -83.18254,
    "state_id": "TN"
  },
  {
    "zip": 37861,
    "lat": 36.24647,
    "lng": -83.51235,
    "state_id": "TN"
  },
  {
    "zip": 37862,
    "lat": 35.77803,
    "lng": -83.6125,
    "state_id": "TN"
  },
  {
    "zip": 37863,
    "lat": 35.78596,
    "lng": -83.56217,
    "state_id": "TN"
  },
  {
    "zip": 37865,
    "lat": 35.84936,
    "lng": -83.74095,
    "state_id": "TN"
  },
  {
    "zip": 37866,
    "lat": 36.34644,
    "lng": -83.85615,
    "state_id": "TN"
  },
  {
    "zip": 37869,
    "lat": 36.51846,
    "lng": -83.24129,
    "state_id": "TN"
  },
  {
    "zip": 37870,
    "lat": 36.46339,
    "lng": -83.83521,
    "state_id": "TN"
  },
  {
    "zip": 37871,
    "lat": 36.04932,
    "lng": -83.67885,
    "state_id": "TN"
  },
  {
    "zip": 37872,
    "lat": 36.26951,
    "lng": -84.64952,
    "state_id": "TN"
  },
  {
    "zip": 37873,
    "lat": 36.52582,
    "lng": -82.84799,
    "state_id": "TN"
  },
  {
    "zip": 37874,
    "lat": 35.5981,
    "lng": -84.45768,
    "state_id": "TN"
  },
  {
    "zip": 37876,
    "lat": 35.86584,
    "lng": -83.47938,
    "state_id": "TN"
  },
  {
    "zip": 37877,
    "lat": 36.15098,
    "lng": -83.40305,
    "state_id": "TN"
  },
  {
    "zip": 37878,
    "lat": 35.62122,
    "lng": -83.90319,
    "state_id": "TN"
  },
  {
    "zip": 37879,
    "lat": 36.47714,
    "lng": -83.49523,
    "state_id": "TN"
  },
  {
    "zip": 37880,
    "lat": 35.69986,
    "lng": -84.66839,
    "state_id": "TN"
  },
  {
    "zip": 37881,
    "lat": 36.40096,
    "lng": -83.34146,
    "state_id": "TN"
  },
  {
    "zip": 37882,
    "lat": 35.60488,
    "lng": -83.80989,
    "state_id": "TN"
  },
  {
    "zip": 37885,
    "lat": 35.48817,
    "lng": -84.1212,
    "state_id": "TN"
  },
  {
    "zip": 37886,
    "lat": 35.74557,
    "lng": -83.80472,
    "state_id": "TN"
  },
  {
    "zip": 37887,
    "lat": 36.09667,
    "lng": -84.58472,
    "state_id": "TN"
  },
  {
    "zip": 37888,
    "lat": 36.31023,
    "lng": -83.61133,
    "state_id": "TN"
  },
  {
    "zip": 37890,
    "lat": 36.08151,
    "lng": -83.29334,
    "state_id": "TN"
  },
  {
    "zip": 37891,
    "lat": 36.29001,
    "lng": -83.13771,
    "state_id": "TN"
  },
  {
    "zip": 37892,
    "lat": 36.55319,
    "lng": -84.3717,
    "state_id": "TN"
  },
  {
    "zip": 37902,
    "lat": 35.96378,
    "lng": -83.92022,
    "state_id": "TN"
  },
  {
    "zip": 37909,
    "lat": 35.94757,
    "lng": -84.0214,
    "state_id": "TN"
  },
  {
    "zip": 37912,
    "lat": 36.00751,
    "lng": -83.98483,
    "state_id": "TN"
  },
  {
    "zip": 37914,
    "lat": 35.9796,
    "lng": -83.79919,
    "state_id": "TN"
  },
  {
    "zip": 37915,
    "lat": 35.97051,
    "lng": -83.89998,
    "state_id": "TN"
  },
  {
    "zip": 37916,
    "lat": 35.95306,
    "lng": -83.93338,
    "state_id": "TN"
  },
  {
    "zip": 37917,
    "lat": 36.00119,
    "lng": -83.91371,
    "state_id": "TN"
  },
  {
    "zip": 37918,
    "lat": 36.06085,
    "lng": -83.90938,
    "state_id": "TN"
  },
  {
    "zip": 37919,
    "lat": 35.9143,
    "lng": -84.0011,
    "state_id": "TN"
  },
  {
    "zip": 37920,
    "lat": 35.91071,
    "lng": -83.8593,
    "state_id": "TN"
  },
  {
    "zip": 37921,
    "lat": 35.97926,
    "lng": -84.00285,
    "state_id": "TN"
  },
  {
    "zip": 37922,
    "lat": 35.86154,
    "lng": -84.10034,
    "state_id": "TN"
  },
  {
    "zip": 37923,
    "lat": 35.92608,
    "lng": -84.08022,
    "state_id": "TN"
  },
  {
    "zip": 37924,
    "lat": 36.03578,
    "lng": -83.80247,
    "state_id": "TN"
  },
  {
    "zip": 37931,
    "lat": 35.976,
    "lng": -84.12517,
    "state_id": "TN"
  },
  {
    "zip": 37932,
    "lat": 35.92252,
    "lng": -84.19592,
    "state_id": "TN"
  },
  {
    "zip": 37934,
    "lat": 35.87289,
    "lng": -84.17913,
    "state_id": "TN"
  },
  {
    "zip": 37938,
    "lat": 36.12392,
    "lng": -83.9386,
    "state_id": "TN"
  },
  {
    "zip": 38001,
    "lat": 35.81094,
    "lng": -89.15658,
    "state_id": "TN"
  },
  {
    "zip": 38002,
    "lat": 35.28695,
    "lng": -89.7084,
    "state_id": "TN"
  },
  {
    "zip": 38004,
    "lat": 35.41885,
    "lng": -89.76739,
    "state_id": "TN"
  },
  {
    "zip": 38006,
    "lat": 35.6949,
    "lng": -89.09725,
    "state_id": "TN"
  },
  {
    "zip": 38007,
    "lat": 36.15948,
    "lng": -89.4271,
    "state_id": "TN"
  },
  {
    "zip": 38008,
    "lat": 35.24682,
    "lng": -88.99499,
    "state_id": "TN"
  },
  {
    "zip": 38011,
    "lat": 35.46697,
    "lng": -89.70724,
    "state_id": "TN"
  },
  {
    "zip": 38012,
    "lat": 35.60824,
    "lng": -89.27013,
    "state_id": "TN"
  },
  {
    "zip": 38015,
    "lat": 35.55867,
    "lng": -89.82317,
    "state_id": "TN"
  },
  {
    "zip": 38016,
    "lat": 35.17981,
    "lng": -89.75885,
    "state_id": "TN"
  },
  {
    "zip": 38017,
    "lat": 35.0675,
    "lng": -89.65065,
    "state_id": "TN"
  },
  {
    "zip": 38018,
    "lat": 35.13812,
    "lng": -89.76591,
    "state_id": "TN"
  },
  {
    "zip": 38019,
    "lat": 35.56347,
    "lng": -89.63019,
    "state_id": "TN"
  },
  {
    "zip": 38021,
    "lat": 35.87583,
    "lng": -89.16472,
    "state_id": "TN"
  },
  {
    "zip": 38023,
    "lat": 35.47979,
    "lng": -89.94491,
    "state_id": "TN"
  },
  {
    "zip": 38024,
    "lat": 36.03641,
    "lng": -89.38076,
    "state_id": "TN"
  },
  {
    "zip": 38028,
    "lat": 35.18309,
    "lng": -89.63487,
    "state_id": "TN"
  },
  {
    "zip": 38029,
    "lat": 35.23874,
    "lng": -89.82189,
    "state_id": "TN"
  },
  {
    "zip": 38030,
    "lat": 36.01192,
    "lng": -89.5913,
    "state_id": "TN"
  },
  {
    "zip": 38034,
    "lat": 35.90976,
    "lng": -89.23443,
    "state_id": "TN"
  },
  {
    "zip": 38036,
    "lat": 35.3202,
    "lng": -89.62583,
    "state_id": "TN"
  },
  {
    "zip": 38037,
    "lat": 35.79604,
    "lng": -89.39374,
    "state_id": "TN"
  },
  {
    "zip": 38039,
    "lat": 35.05811,
    "lng": -89.18215,
    "state_id": "TN"
  },
  {
    "zip": 38040,
    "lat": 35.88516,
    "lng": -89.43398,
    "state_id": "TN"
  },
  {
    "zip": 38041,
    "lat": 35.64371,
    "lng": -89.70457,
    "state_id": "TN"
  },
  {
    "zip": 38042,
    "lat": 35.1474,
    "lng": -89.13854,
    "state_id": "TN"
  },
  {
    "zip": 38044,
    "lat": 35.20535,
    "lng": -88.80319,
    "state_id": "TN"
  },
  {
    "zip": 38046,
    "lat": 35.06985,
    "lng": -89.24792,
    "state_id": "TN"
  },
  {
    "zip": 38047,
    "lat": 36.09055,
    "lng": -89.50346,
    "state_id": "TN"
  },
  {
    "zip": 38049,
    "lat": 35.39033,
    "lng": -89.54863,
    "state_id": "TN"
  },
  {
    "zip": 38050,
    "lat": 35.82956,
    "lng": -89.23304,
    "state_id": "TN"
  },
  {
    "zip": 38052,
    "lat": 35.08626,
    "lng": -88.91097,
    "state_id": "TN"
  },
  {
    "zip": 38053,
    "lat": 35.34422,
    "lng": -89.95337,
    "state_id": "TN"
  },
  {
    "zip": 38054,
    "lat": 35.33636,
    "lng": -89.87356,
    "state_id": "TN"
  },
  {
    "zip": 38057,
    "lat": 35.05337,
    "lng": -89.36722,
    "state_id": "TN"
  },
  {
    "zip": 38058,
    "lat": 35.45892,
    "lng": -89.81969,
    "state_id": "TN"
  },
  {
    "zip": 38059,
    "lat": 36.1111,
    "lng": -89.24521,
    "state_id": "TN"
  },
  {
    "zip": 38060,
    "lat": 35.2091,
    "lng": -89.50332,
    "state_id": "TN"
  },
  {
    "zip": 38061,
    "lat": 35.06498,
    "lng": -88.76755,
    "state_id": "TN"
  },
  {
    "zip": 38063,
    "lat": 35.75577,
    "lng": -89.63995,
    "state_id": "TN"
  },
  {
    "zip": 38066,
    "lat": 35.0748,
    "lng": -89.5323,
    "state_id": "TN"
  },
  {
    "zip": 38067,
    "lat": 35.09286,
    "lng": -89.04265,
    "state_id": "TN"
  },
  {
    "zip": 38068,
    "lat": 35.26682,
    "lng": -89.33586,
    "state_id": "TN"
  },
  {
    "zip": 38069,
    "lat": 35.46388,
    "lng": -89.36435,
    "state_id": "TN"
  },
  {
    "zip": 38070,
    "lat": 35.94354,
    "lng": -89.23696,
    "state_id": "TN"
  },
  {
    "zip": 38075,
    "lat": 35.36844,
    "lng": -89.15179,
    "state_id": "TN"
  },
  {
    "zip": 38076,
    "lat": 35.13552,
    "lng": -89.43809,
    "state_id": "TN"
  },
  {
    "zip": 38077,
    "lat": 36.32806,
    "lng": -89.47442,
    "state_id": "TN"
  },
  {
    "zip": 38079,
    "lat": 36.4302,
    "lng": -89.46291,
    "state_id": "TN"
  },
  {
    "zip": 38080,
    "lat": 36.20974,
    "lng": -89.52713,
    "state_id": "TN"
  },
  {
    "zip": 38103,
    "lat": 35.15313,
    "lng": -90.05543,
    "state_id": "TN"
  },
  {
    "zip": 38104,
    "lat": 35.1326,
    "lng": -90.00372,
    "state_id": "TN"
  },
  {
    "zip": 38105,
    "lat": 35.15123,
    "lng": -90.03515,
    "state_id": "TN"
  },
  {
    "zip": 38106,
    "lat": 35.10146,
    "lng": -90.09693,
    "state_id": "TN"
  },
  {
    "zip": 38107,
    "lat": 35.17017,
    "lng": -90.02373,
    "state_id": "TN"
  },
  {
    "zip": 38108,
    "lat": 35.17607,
    "lng": -89.96971,
    "state_id": "TN"
  },
  {
    "zip": 38109,
    "lat": 35.03394,
    "lng": -90.14947,
    "state_id": "TN"
  },
  {
    "zip": 38111,
    "lat": 35.11014,
    "lng": -89.9441,
    "state_id": "TN"
  },
  {
    "zip": 38112,
    "lat": 35.14785,
    "lng": -89.97668,
    "state_id": "TN"
  },
  {
    "zip": 38114,
    "lat": 35.09649,
    "lng": -89.98585,
    "state_id": "TN"
  },
  {
    "zip": 38115,
    "lat": 35.05496,
    "lng": -89.86356,
    "state_id": "TN"
  },
  {
    "zip": 38116,
    "lat": 35.03331,
    "lng": -90.01092,
    "state_id": "TN"
  },
  {
    "zip": 38117,
    "lat": 35.11524,
    "lng": -89.90529,
    "state_id": "TN"
  },
  {
    "zip": 38118,
    "lat": 35.03584,
    "lng": -89.93152,
    "state_id": "TN"
  },
  {
    "zip": 38119,
    "lat": 35.07975,
    "lng": -89.84584,
    "state_id": "TN"
  },
  {
    "zip": 38120,
    "lat": 35.12352,
    "lng": -89.85219,
    "state_id": "TN"
  },
  {
    "zip": 38122,
    "lat": 35.15893,
    "lng": -89.92128,
    "state_id": "TN"
  },
  {
    "zip": 38125,
    "lat": 35.02586,
    "lng": -89.78865,
    "state_id": "TN"
  },
  {
    "zip": 38126,
    "lat": 35.12695,
    "lng": -90.04363,
    "state_id": "TN"
  },
  {
    "zip": 38127,
    "lat": 35.23765,
    "lng": -90.01756,
    "state_id": "TN"
  },
  {
    "zip": 38128,
    "lat": 35.22611,
    "lng": -89.92618,
    "state_id": "TN"
  },
  {
    "zip": 38131,
    "lat": 35.06631,
    "lng": -89.99205,
    "state_id": "TN"
  },
  {
    "zip": 38132,
    "lat": 35.07131,
    "lng": -90.00136,
    "state_id": "TN"
  },
  {
    "zip": 38133,
    "lat": 35.21293,
    "lng": -89.79427,
    "state_id": "TN"
  },
  {
    "zip": 38134,
    "lat": 35.17696,
    "lng": -89.85992,
    "state_id": "TN"
  },
  {
    "zip": 38135,
    "lat": 35.2382,
    "lng": -89.84934,
    "state_id": "TN"
  },
  {
    "zip": 38138,
    "lat": 35.08482,
    "lng": -89.79929,
    "state_id": "TN"
  },
  {
    "zip": 38139,
    "lat": 35.08549,
    "lng": -89.75537,
    "state_id": "TN"
  },
  {
    "zip": 38141,
    "lat": 35.01598,
    "lng": -89.85474,
    "state_id": "TN"
  },
  {
    "zip": 38152,
    "lat": 35.12278,
    "lng": -89.93396,
    "state_id": "TN"
  },
  {
    "zip": 38201,
    "lat": 36.12283,
    "lng": -88.52912,
    "state_id": "TN"
  },
  {
    "zip": 38220,
    "lat": 35.98795,
    "lng": -88.67061,
    "state_id": "TN"
  },
  {
    "zip": 38221,
    "lat": 36.24504,
    "lng": -88.03583,
    "state_id": "TN"
  },
  {
    "zip": 38222,
    "lat": 36.44511,
    "lng": -88.16463,
    "state_id": "TN"
  },
  {
    "zip": 38224,
    "lat": 36.39138,
    "lng": -88.48608,
    "state_id": "TN"
  },
  {
    "zip": 38225,
    "lat": 36.32087,
    "lng": -88.67904,
    "state_id": "TN"
  },
  {
    "zip": 38226,
    "lat": 36.48134,
    "lng": -88.6824,
    "state_id": "TN"
  },
  {
    "zip": 38229,
    "lat": 36.21998,
    "lng": -88.60838,
    "state_id": "TN"
  },
  {
    "zip": 38230,
    "lat": 36.15611,
    "lng": -88.77124,
    "state_id": "TN"
  },
  {
    "zip": 38231,
    "lat": 36.21178,
    "lng": -88.4252,
    "state_id": "TN"
  },
  {
    "zip": 38232,
    "lat": 36.34943,
    "lng": -89.32268,
    "state_id": "TN"
  },
  {
    "zip": 38233,
    "lat": 36.20283,
    "lng": -89.04853,
    "state_id": "TN"
  },
  {
    "zip": 38235,
    "lat": 35.98929,
    "lng": -88.56403,
    "state_id": "TN"
  },
  {
    "zip": 38236,
    "lat": 36.17195,
    "lng": -88.27246,
    "state_id": "TN"
  },
  {
    "zip": 38237,
    "lat": 36.3595,
    "lng": -88.83433,
    "state_id": "TN"
  },
  {
    "zip": 38240,
    "lat": 36.2513,
    "lng": -89.31926,
    "state_id": "TN"
  },
  {
    "zip": 38241,
    "lat": 36.42572,
    "lng": -88.58489,
    "state_id": "TN"
  },
  {
    "zip": 38242,
    "lat": 36.30373,
    "lng": -88.32802,
    "state_id": "TN"
  },
  {
    "zip": 38251,
    "lat": 36.44525,
    "lng": -88.35593,
    "state_id": "TN"
  },
  {
    "zip": 38253,
    "lat": 36.29684,
    "lng": -89.05191,
    "state_id": "TN"
  },
  {
    "zip": 38254,
    "lat": 36.37494,
    "lng": -89.34734,
    "state_id": "TN"
  },
  {
    "zip": 38255,
    "lat": 36.23861,
    "lng": -88.86379,
    "state_id": "TN"
  },
  {
    "zip": 38256,
    "lat": 36.3064,
    "lng": -88.13039,
    "state_id": "TN"
  },
  {
    "zip": 38257,
    "lat": 36.46034,
    "lng": -88.8741,
    "state_id": "TN"
  },
  {
    "zip": 38258,
    "lat": 36.02598,
    "lng": -88.61744,
    "state_id": "TN"
  },
  {
    "zip": 38259,
    "lat": 36.20016,
    "lng": -89.18377,
    "state_id": "TN"
  },
  {
    "zip": 38260,
    "lat": 36.36545,
    "lng": -89.21066,
    "state_id": "TN"
  },
  {
    "zip": 38261,
    "lat": 36.43234,
    "lng": -89.11643,
    "state_id": "TN"
  },
  {
    "zip": 38301,
    "lat": 35.586,
    "lng": -88.85281,
    "state_id": "TN"
  },
  {
    "zip": 38305,
    "lat": 35.70199,
    "lng": -88.76793,
    "state_id": "TN"
  },
  {
    "zip": 38310,
    "lat": 35.25849,
    "lng": -88.39883,
    "state_id": "TN"
  },
  {
    "zip": 38311,
    "lat": 35.43642,
    "lng": -88.10553,
    "state_id": "TN"
  },
  {
    "zip": 38313,
    "lat": 35.60026,
    "lng": -88.62504,
    "state_id": "TN"
  },
  {
    "zip": 38315,
    "lat": 35.26727,
    "lng": -88.62638,
    "state_id": "TN"
  },
  {
    "zip": 38316,
    "lat": 36.06851,
    "lng": -88.8149,
    "state_id": "TN"
  },
  {
    "zip": 38317,
    "lat": 36.05457,
    "lng": -88.27454,
    "state_id": "TN"
  },
  {
    "zip": 38318,
    "lat": 35.95571,
    "lng": -88.26285,
    "state_id": "TN"
  },
  {
    "zip": 38320,
    "lat": 36.05142,
    "lng": -88.11364,
    "state_id": "TN"
  },
  {
    "zip": 38321,
    "lat": 35.83715,
    "lng": -88.52987,
    "state_id": "TN"
  },
  {
    "zip": 38326,
    "lat": 35.05788,
    "lng": -88.28787,
    "state_id": "TN"
  },
  {
    "zip": 38327,
    "lat": 35.22119,
    "lng": -88.29917,
    "state_id": "TN"
  },
  {
    "zip": 38328,
    "lat": 35.67783,
    "lng": -88.21651,
    "state_id": "TN"
  },
  {
    "zip": 38329,
    "lat": 35.54408,
    "lng": -88.11059,
    "state_id": "TN"
  },
  {
    "zip": 38330,
    "lat": 36.06702,
    "lng": -89.03658,
    "state_id": "TN"
  },
  {
    "zip": 38332,
    "lat": 35.41911,
    "lng": -88.42645,
    "state_id": "TN"
  },
  {
    "zip": 38333,
    "lat": 36.12376,
    "lng": -87.96956,
    "state_id": "TN"
  },
  {
    "zip": 38334,
    "lat": 35.35445,
    "lng": -88.58751,
    "state_id": "TN"
  },
  {
    "zip": 38337,
    "lat": 35.78532,
    "lng": -89.01404,
    "state_id": "TN"
  },
  {
    "zip": 38339,
    "lat": 35.0524,
    "lng": -88.51505,
    "state_id": "TN"
  },
  {
    "zip": 38340,
    "lat": 35.39985,
    "lng": -88.68049,
    "state_id": "TN"
  },
  {
    "zip": 38341,
    "lat": 35.87981,
    "lng": -88.09095,
    "state_id": "TN"
  },
  {
    "zip": 38342,
    "lat": 36.10499,
    "lng": -88.28376,
    "state_id": "TN"
  },
  {
    "zip": 38343,
    "lat": 35.82686,
    "lng": -88.92626,
    "state_id": "TN"
  },
  {
    "zip": 38344,
    "lat": 35.97828,
    "lng": -88.42846,
    "state_id": "TN"
  },
  {
    "zip": 38345,
    "lat": 35.59363,
    "lng": -88.51257,
    "state_id": "TN"
  },
  {
    "zip": 38347,
    "lat": 35.47776,
    "lng": -88.49862,
    "state_id": "TN"
  },
  {
    "zip": 38348,
    "lat": 35.8685,
    "lng": -88.65383,
    "state_id": "TN"
  },
  {
    "zip": 38351,
    "lat": 35.6654,
    "lng": -88.40307,
    "state_id": "TN"
  },
  {
    "zip": 38352,
    "lat": 35.53163,
    "lng": -88.53216,
    "state_id": "TN"
  },
  {
    "zip": 38355,
    "lat": 35.78694,
    "lng": -88.76755,
    "state_id": "TN"
  },
  {
    "zip": 38356,
    "lat": 35.43449,
    "lng": -88.89534,
    "state_id": "TN"
  },
  {
    "zip": 38357,
    "lat": 35.04583,
    "lng": -88.41546,
    "state_id": "TN"
  },
  {
    "zip": 38358,
    "lat": 35.91668,
    "lng": -88.75932,
    "state_id": "TN"
  },
  {
    "zip": 38359,
    "lat": 35.36983,
    "lng": -88.35135,
    "state_id": "TN"
  },
  {
    "zip": 38361,
    "lat": 35.3176,
    "lng": -88.28499,
    "state_id": "TN"
  },
  {
    "zip": 38362,
    "lat": 35.72906,
    "lng": -88.78291,
    "state_id": "TN"
  },
  {
    "zip": 38363,
    "lat": 35.6853,
    "lng": -88.11312,
    "state_id": "TN"
  },
  {
    "zip": 38365,
    "lat": 35.05363,
    "lng": -88.23585,
    "state_id": "TN"
  },
  {
    "zip": 38366,
    "lat": 35.47611,
    "lng": -88.74604,
    "state_id": "TN"
  },
  {
    "zip": 38367,
    "lat": 35.05297,
    "lng": -88.6279,
    "state_id": "TN"
  },
  {
    "zip": 38368,
    "lat": 35.50485,
    "lng": -88.35342,
    "state_id": "TN"
  },
  {
    "zip": 38369,
    "lat": 36.13228,
    "lng": -88.96121,
    "state_id": "TN"
  },
  {
    "zip": 38370,
    "lat": 35.38412,
    "lng": -88.24391,
    "state_id": "TN"
  },
  {
    "zip": 38371,
    "lat": 35.42296,
    "lng": -88.29819,
    "state_id": "TN"
  },
  {
    "zip": 38372,
    "lat": 35.1695,
    "lng": -88.14364,
    "state_id": "TN"
  },
  {
    "zip": 38374,
    "lat": 35.51577,
    "lng": -88.23483,
    "state_id": "TN"
  },
  {
    "zip": 38375,
    "lat": 35.15768,
    "lng": -88.5893,
    "state_id": "TN"
  },
  {
    "zip": 38376,
    "lat": 35.10335,
    "lng": -88.37188,
    "state_id": "TN"
  },
  {
    "zip": 38379,
    "lat": 35.16424,
    "lng": -88.43375,
    "state_id": "TN"
  },
  {
    "zip": 38380,
    "lat": 35.79601,
    "lng": -88.03258,
    "state_id": "TN"
  },
  {
    "zip": 38381,
    "lat": 35.35712,
    "lng": -88.96621,
    "state_id": "TN"
  },
  {
    "zip": 38382,
    "lat": 35.9672,
    "lng": -88.99287,
    "state_id": "TN"
  },
  {
    "zip": 38387,
    "lat": 35.86743,
    "lng": -88.27749,
    "state_id": "TN"
  },
  {
    "zip": 38388,
    "lat": 35.7813,
    "lng": -88.32129,
    "state_id": "TN"
  },
  {
    "zip": 38390,
    "lat": 35.84051,
    "lng": -88.37231,
    "state_id": "TN"
  },
  {
    "zip": 38391,
    "lat": 35.55104,
    "lng": -88.99403,
    "state_id": "TN"
  },
  {
    "zip": 38392,
    "lat": 35.46457,
    "lng": -89.03333,
    "state_id": "TN"
  },
  {
    "zip": 38401,
    "lat": 35.62902,
    "lng": -87.02062,
    "state_id": "TN"
  },
  {
    "zip": 38425,
    "lat": 35.40586,
    "lng": -87.94814,
    "state_id": "TN"
  },
  {
    "zip": 38449,
    "lat": 35.0482,
    "lng": -86.82589,
    "state_id": "TN"
  },
  {
    "zip": 38450,
    "lat": 35.18437,
    "lng": -87.79174,
    "state_id": "TN"
  },
  {
    "zip": 38451,
    "lat": 35.46631,
    "lng": -86.99181,
    "state_id": "TN"
  },
  {
    "zip": 38452,
    "lat": 35.08042,
    "lng": -87.79628,
    "state_id": "TN"
  },
  {
    "zip": 38453,
    "lat": 35.12138,
    "lng": -86.81208,
    "state_id": "TN"
  },
  {
    "zip": 38454,
    "lat": 35.72318,
    "lng": -87.35211,
    "state_id": "TN"
  },
  {
    "zip": 38455,
    "lat": 35.04888,
    "lng": -86.88709,
    "state_id": "TN"
  },
  {
    "zip": 38456,
    "lat": 35.33546,
    "lng": -87.26638,
    "state_id": "TN"
  },
  {
    "zip": 38457,
    "lat": 35.02936,
    "lng": -87.28446,
    "state_id": "TN"
  },
  {
    "zip": 38459,
    "lat": 35.1943,
    "lng": -86.80544,
    "state_id": "TN"
  },
  {
    "zip": 38460,
    "lat": 35.08864,
    "lng": -87.15322,
    "state_id": "TN"
  },
  {
    "zip": 38461,
    "lat": 35.593,
    "lng": -87.34196,
    "state_id": "TN"
  },
  {
    "zip": 38462,
    "lat": 35.5311,
    "lng": -87.55161,
    "state_id": "TN"
  },
  {
    "zip": 38463,
    "lat": 35.06826,
    "lng": -87.64451,
    "state_id": "TN"
  },
  {
    "zip": 38464,
    "lat": 35.28669,
    "lng": -87.42957,
    "state_id": "TN"
  },
  {
    "zip": 38468,
    "lat": 35.131,
    "lng": -87.29381,
    "state_id": "TN"
  },
  {
    "zip": 38469,
    "lat": 35.0718,
    "lng": -87.42725,
    "state_id": "TN"
  },
  {
    "zip": 38471,
    "lat": 35.07429,
    "lng": -87.92131,
    "state_id": "TN"
  },
  {
    "zip": 38472,
    "lat": 35.37758,
    "lng": -87.0386,
    "state_id": "TN"
  },
  {
    "zip": 38473,
    "lat": 35.0277,
    "lng": -87.17113,
    "state_id": "TN"
  },
  {
    "zip": 38474,
    "lat": 35.51684,
    "lng": -87.22653,
    "state_id": "TN"
  },
  {
    "zip": 38475,
    "lat": 35.25348,
    "lng": -88.01611,
    "state_id": "TN"
  },
  {
    "zip": 38476,
    "lat": 35.84119,
    "lng": -87.21374,
    "state_id": "TN"
  },
  {
    "zip": 38477,
    "lat": 35.05244,
    "lng": -87.00394,
    "state_id": "TN"
  },
  {
    "zip": 38478,
    "lat": 35.21489,
    "lng": -87.01128,
    "state_id": "TN"
  },
  {
    "zip": 38481,
    "lat": 35.03138,
    "lng": -87.49239,
    "state_id": "TN"
  },
  {
    "zip": 38482,
    "lat": 35.7689,
    "lng": -87.14526,
    "state_id": "TN"
  },
  {
    "zip": 38483,
    "lat": 35.43768,
    "lng": -87.33956,
    "state_id": "TN"
  },
  {
    "zip": 38485,
    "lat": 35.33676,
    "lng": -87.77362,
    "state_id": "TN"
  },
  {
    "zip": 38486,
    "lat": 35.17056,
    "lng": -87.5451,
    "state_id": "TN"
  },
  {
    "zip": 38487,
    "lat": 35.72194,
    "lng": -87.23475,
    "state_id": "TN"
  },
  {
    "zip": 38488,
    "lat": 35.04059,
    "lng": -86.68473,
    "state_id": "TN"
  },
  {
    "zip": 38501,
    "lat": 36.2282,
    "lng": -85.53847,
    "state_id": "TN"
  },
  {
    "zip": 38504,
    "lat": 36.39558,
    "lng": -84.72439,
    "state_id": "TN"
  },
  {
    "zip": 38505,
    "lat": 36.17512,
    "lng": -85.50507,
    "state_id": "TN"
  },
  {
    "zip": 38506,
    "lat": 36.1819,
    "lng": -85.44078,
    "state_id": "TN"
  },
  {
    "zip": 38541,
    "lat": 36.52956,
    "lng": -85.33738,
    "state_id": "TN"
  },
  {
    "zip": 38542,
    "lat": 36.31716,
    "lng": -85.20224,
    "state_id": "TN"
  },
  {
    "zip": 38543,
    "lat": 36.37242,
    "lng": -85.16624,
    "state_id": "TN"
  },
  {
    "zip": 38544,
    "lat": 36.11987,
    "lng": -85.66489,
    "state_id": "TN"
  },
  {
    "zip": 38545,
    "lat": 36.23209,
    "lng": -85.66285,
    "state_id": "TN"
  },
  {
    "zip": 38547,
    "lat": 36.15169,
    "lng": -86.0084,
    "state_id": "TN"
  },
  {
    "zip": 38548,
    "lat": 36.17047,
    "lng": -85.79151,
    "state_id": "TN"
  },
  {
    "zip": 38549,
    "lat": 36.56081,
    "lng": -85.1516,
    "state_id": "TN"
  },
  {
    "zip": 38551,
    "lat": 36.55797,
    "lng": -85.48547,
    "state_id": "TN"
  },
  {
    "zip": 38552,
    "lat": 36.22039,
    "lng": -85.81002,
    "state_id": "TN"
  },
  {
    "zip": 38553,
    "lat": 36.20186,
    "lng": -85.00634,
    "state_id": "TN"
  },
  {
    "zip": 38554,
    "lat": 36.2495,
    "lng": -85.16166,
    "state_id": "TN"
  },
  {
    "zip": 38555,
    "lat": 35.88935,
    "lng": -84.98069,
    "state_id": "TN"
  },
  {
    "zip": 38556,
    "lat": 36.40939,
    "lng": -84.92373,
    "state_id": "TN"
  },
  {
    "zip": 38558,
    "lat": 36.00774,
    "lng": -84.87192,
    "state_id": "TN"
  },
  {
    "zip": 38559,
    "lat": 35.80753,
    "lng": -85.50623,
    "state_id": "TN"
  },
  {
    "zip": 38560,
    "lat": 36.23317,
    "lng": -85.87015,
    "state_id": "TN"
  },
  {
    "zip": 38562,
    "lat": 36.35439,
    "lng": -85.65774,
    "state_id": "TN"
  },
  {
    "zip": 38563,
    "lat": 36.20009,
    "lng": -86.02205,
    "state_id": "TN"
  },
  {
    "zip": 38564,
    "lat": 36.27566,
    "lng": -85.7481,
    "state_id": "TN"
  },
  {
    "zip": 38565,
    "lat": 36.26275,
    "lng": -85.00949,
    "state_id": "TN"
  },
  {
    "zip": 38567,
    "lat": 36.13572,
    "lng": -85.9163,
    "state_id": "TN"
  },
  {
    "zip": 38568,
    "lat": 36.43131,
    "lng": -85.46822,
    "state_id": "TN"
  },
  {
    "zip": 38569,
    "lat": 36.09835,
    "lng": -85.85544,
    "state_id": "TN"
  },
  {
    "zip": 38570,
    "lat": 36.37413,
    "lng": -85.32084,
    "state_id": "TN"
  },
  {
    "zip": 38571,
    "lat": 36.05276,
    "lng": -85.0138,
    "state_id": "TN"
  },
  {
    "zip": 38572,
    "lat": 35.84872,
    "lng": -85.13077,
    "state_id": "TN"
  },
  {
    "zip": 38573,
    "lat": 36.48912,
    "lng": -85.21848,
    "state_id": "TN"
  },
  {
    "zip": 38574,
    "lat": 36.13736,
    "lng": -85.23317,
    "state_id": "TN"
  },
  {
    "zip": 38575,
    "lat": 36.55959,
    "lng": -85.63682,
    "state_id": "TN"
  },
  {
    "zip": 38577,
    "lat": 36.56493,
    "lng": -84.96504,
    "state_id": "TN"
  },
  {
    "zip": 38578,
    "lat": 35.98123,
    "lng": -85.19828,
    "state_id": "TN"
  },
  {
    "zip": 38579,
    "lat": 35.83032,
    "lng": -85.55188,
    "state_id": "TN"
  },
  {
    "zip": 38580,
    "lat": 36.27813,
    "lng": -85.29194,
    "state_id": "TN"
  },
  {
    "zip": 38581,
    "lat": 35.7388,
    "lng": -85.61893,
    "state_id": "TN"
  },
  {
    "zip": 38582,
    "lat": 36.09389,
    "lng": -85.75424,
    "state_id": "TN"
  },
  {
    "zip": 38583,
    "lat": 35.94324,
    "lng": -85.43927,
    "state_id": "TN"
  },
  {
    "zip": 38585,
    "lat": 35.69355,
    "lng": -85.42668,
    "state_id": "TN"
  },
  {
    "zip": 38587,
    "lat": 35.86946,
    "lng": -85.61686,
    "state_id": "TN"
  },
  {
    "zip": 38588,
    "lat": 36.45833,
    "lng": -85.72126,
    "state_id": "TN"
  },
  {
    "zip": 38589,
    "lat": 36.2856,
    "lng": -85.07714,
    "state_id": "TN"
  },
  {
    "zip": 75001,
    "lat": 32.96,
    "lng": -96.83849,
    "state_id": "TX"
  },
  {
    "zip": 75002,
    "lat": 33.08965,
    "lng": -96.60752,
    "state_id": "TX"
  },
  {
    "zip": 75006,
    "lat": 32.96188,
    "lng": -96.89701,
    "state_id": "TX"
  },
  {
    "zip": 75007,
    "lat": 33.00462,
    "lng": -96.89715,
    "state_id": "TX"
  },
  {
    "zip": 75009,
    "lat": 33.34028,
    "lng": -96.75033,
    "state_id": "TX"
  },
  {
    "zip": 75010,
    "lat": 33.03425,
    "lng": -96.89673,
    "state_id": "TX"
  },
  {
    "zip": 75013,
    "lat": 33.11467,
    "lng": -96.69411,
    "state_id": "TX"
  },
  {
    "zip": 75019,
    "lat": 32.96329,
    "lng": -96.98553,
    "state_id": "TX"
  },
  {
    "zip": 75020,
    "lat": 33.77165,
    "lng": -96.60414,
    "state_id": "TX"
  },
  {
    "zip": 75021,
    "lat": 33.72616,
    "lng": -96.47325,
    "state_id": "TX"
  },
  {
    "zip": 75022,
    "lat": 33.02772,
    "lng": -97.12024,
    "state_id": "TX"
  },
  {
    "zip": 75023,
    "lat": 33.05706,
    "lng": -96.73242,
    "state_id": "TX"
  },
  {
    "zip": 75024,
    "lat": 33.07735,
    "lng": -96.80704,
    "state_id": "TX"
  },
  {
    "zip": 75025,
    "lat": 33.09095,
    "lng": -96.74128,
    "state_id": "TX"
  },
  {
    "zip": 75028,
    "lat": 33.0328,
    "lng": -97.06086,
    "state_id": "TX"
  },
  {
    "zip": 75032,
    "lat": 32.85505,
    "lng": -96.42772,
    "state_id": "TX"
  },
  {
    "zip": 75034,
    "lat": 33.15195,
    "lng": -96.86036,
    "state_id": "TX"
  },
  {
    "zip": 75035,
    "lat": 33.15534,
    "lng": -96.77271,
    "state_id": "TX"
  },
  {
    "zip": 75038,
    "lat": 32.87457,
    "lng": -96.99759,
    "state_id": "TX"
  },
  {
    "zip": 75039,
    "lat": 32.88753,
    "lng": -96.94224,
    "state_id": "TX"
  },
  {
    "zip": 75040,
    "lat": 32.92766,
    "lng": -96.62008,
    "state_id": "TX"
  },
  {
    "zip": 75041,
    "lat": 32.88091,
    "lng": -96.65147,
    "state_id": "TX"
  },
  {
    "zip": 75042,
    "lat": 32.9139,
    "lng": -96.67493,
    "state_id": "TX"
  },
  {
    "zip": 75043,
    "lat": 32.85707,
    "lng": -96.57941,
    "state_id": "TX"
  },
  {
    "zip": 75044,
    "lat": 32.96264,
    "lng": -96.65323,
    "state_id": "TX"
  },
  {
    "zip": 75048,
    "lat": 32.972,
    "lng": -96.58085,
    "state_id": "TX"
  },
  {
    "zip": 75050,
    "lat": 32.77409,
    "lng": -97.00532,
    "state_id": "TX"
  },
  {
    "zip": 75051,
    "lat": 32.72758,
    "lng": -96.99437,
    "state_id": "TX"
  },
  {
    "zip": 75052,
    "lat": 32.6659,
    "lng": -97.02649,
    "state_id": "TX"
  },
  {
    "zip": 75054,
    "lat": 32.59059,
    "lng": -97.04051,
    "state_id": "TX"
  },
  {
    "zip": 75056,
    "lat": 33.07393,
    "lng": -96.91484,
    "state_id": "TX"
  },
  {
    "zip": 75057,
    "lat": 33.04952,
    "lng": -96.98383,
    "state_id": "TX"
  },
  {
    "zip": 75058,
    "lat": 33.45012,
    "lng": -96.74225,
    "state_id": "TX"
  },
  {
    "zip": 75060,
    "lat": 32.796,
    "lng": -96.95446,
    "state_id": "TX"
  },
  {
    "zip": 75061,
    "lat": 32.82605,
    "lng": -96.9655,
    "state_id": "TX"
  },
  {
    "zip": 75062,
    "lat": 32.84702,
    "lng": -96.95686,
    "state_id": "TX"
  },
  {
    "zip": 75063,
    "lat": 32.92046,
    "lng": -96.98599,
    "state_id": "TX"
  },
  {
    "zip": 75065,
    "lat": 33.11093,
    "lng": -97.0119,
    "state_id": "TX"
  },
  {
    "zip": 75067,
    "lat": 33.01371,
    "lng": -97.00025,
    "state_id": "TX"
  },
  {
    "zip": 75068,
    "lat": 33.17647,
    "lng": -96.95053,
    "state_id": "TX"
  },
  {
    "zip": 75069,
    "lat": 33.16545,
    "lng": -96.59468,
    "state_id": "TX"
  },
  {
    "zip": 75070,
    "lat": 33.17311,
    "lng": -96.69773,
    "state_id": "TX"
  },
  {
    "zip": 75071,
    "lat": 33.24663,
    "lng": -96.62898,
    "state_id": "TX"
  },
  {
    "zip": 75074,
    "lat": 33.03188,
    "lng": -96.67428,
    "state_id": "TX"
  },
  {
    "zip": 75075,
    "lat": 33.02137,
    "lng": -96.74103,
    "state_id": "TX"
  },
  {
    "zip": 75076,
    "lat": 33.79472,
    "lng": -96.72168,
    "state_id": "TX"
  },
  {
    "zip": 75077,
    "lat": 33.07886,
    "lng": -97.06273,
    "state_id": "TX"
  },
  {
    "zip": 75078,
    "lat": 33.24672,
    "lng": -96.80856,
    "state_id": "TX"
  },
  {
    "zip": 75080,
    "lat": 32.97394,
    "lng": -96.74202,
    "state_id": "TX"
  },
  {
    "zip": 75081,
    "lat": 32.94882,
    "lng": -96.7102,
    "state_id": "TX"
  },
  {
    "zip": 75082,
    "lat": 32.99157,
    "lng": -96.66234,
    "state_id": "TX"
  },
  {
    "zip": 75087,
    "lat": 32.94172,
    "lng": -96.44993,
    "state_id": "TX"
  },
  {
    "zip": 75088,
    "lat": 32.89229,
    "lng": -96.54917,
    "state_id": "TX"
  },
  {
    "zip": 75089,
    "lat": 32.92975,
    "lng": -96.54975,
    "state_id": "TX"
  },
  {
    "zip": 75090,
    "lat": 33.60452,
    "lng": -96.54562,
    "state_id": "TX"
  },
  {
    "zip": 75092,
    "lat": 33.64891,
    "lng": -96.70005,
    "state_id": "TX"
  },
  {
    "zip": 75093,
    "lat": 33.0355,
    "lng": -96.81001,
    "state_id": "TX"
  },
  {
    "zip": 75094,
    "lat": 33.01909,
    "lng": -96.61509,
    "state_id": "TX"
  },
  {
    "zip": 75098,
    "lat": 33.0119,
    "lng": -96.53479,
    "state_id": "TX"
  },
  {
    "zip": 75101,
    "lat": 32.27003,
    "lng": -96.70239,
    "state_id": "TX"
  },
  {
    "zip": 75102,
    "lat": 32.06629,
    "lng": -96.64223,
    "state_id": "TX"
  },
  {
    "zip": 75103,
    "lat": 32.51192,
    "lng": -95.88235,
    "state_id": "TX"
  },
  {
    "zip": 75104,
    "lat": 32.58042,
    "lng": -96.96613,
    "state_id": "TX"
  },
  {
    "zip": 75105,
    "lat": 32.26654,
    "lng": -96.37522,
    "state_id": "TX"
  },
  {
    "zip": 75109,
    "lat": 32.04523,
    "lng": -96.35405,
    "state_id": "TX"
  },
  {
    "zip": 75110,
    "lat": 32.08693,
    "lng": -96.52879,
    "state_id": "TX"
  },
  {
    "zip": 75114,
    "lat": 32.61016,
    "lng": -96.44553,
    "state_id": "TX"
  },
  {
    "zip": 75115,
    "lat": 32.59888,
    "lng": -96.86374,
    "state_id": "TX"
  },
  {
    "zip": 75116,
    "lat": 32.66017,
    "lng": -96.91393,
    "state_id": "TX"
  },
  {
    "zip": 75117,
    "lat": 32.71183,
    "lng": -95.85077,
    "state_id": "TX"
  },
  {
    "zip": 75119,
    "lat": 32.32976,
    "lng": -96.59334,
    "state_id": "TX"
  },
  {
    "zip": 75124,
    "lat": 32.32673,
    "lng": -95.9724,
    "state_id": "TX"
  },
  {
    "zip": 75125,
    "lat": 32.53137,
    "lng": -96.62477,
    "state_id": "TX"
  },
  {
    "zip": 75126,
    "lat": 32.74205,
    "lng": -96.45185,
    "state_id": "TX"
  },
  {
    "zip": 75127,
    "lat": 32.69116,
    "lng": -95.79336,
    "state_id": "TX"
  },
  {
    "zip": 75132,
    "lat": 32.94537,
    "lng": -96.37375,
    "state_id": "TX"
  },
  {
    "zip": 75134,
    "lat": 32.6201,
    "lng": -96.783,
    "state_id": "TX"
  },
  {
    "zip": 75135,
    "lat": 33.06521,
    "lng": -96.22289,
    "state_id": "TX"
  },
  {
    "zip": 75137,
    "lat": 32.634,
    "lng": -96.91176,
    "state_id": "TX"
  },
  {
    "zip": 75140,
    "lat": 32.64691,
    "lng": -95.69145,
    "state_id": "TX"
  },
  {
    "zip": 75141,
    "lat": 32.63998,
    "lng": -96.69464,
    "state_id": "TX"
  },
  {
    "zip": 75142,
    "lat": 32.57357,
    "lng": -96.24717,
    "state_id": "TX"
  },
  {
    "zip": 75143,
    "lat": 32.36358,
    "lng": -96.24817,
    "state_id": "TX"
  },
  {
    "zip": 75144,
    "lat": 32.11313,
    "lng": -96.20885,
    "state_id": "TX"
  },
  {
    "zip": 75146,
    "lat": 32.57462,
    "lng": -96.75068,
    "state_id": "TX"
  },
  {
    "zip": 75147,
    "lat": 32.43298,
    "lng": -96.0774,
    "state_id": "TX"
  },
  {
    "zip": 75148,
    "lat": 32.12461,
    "lng": -96.01216,
    "state_id": "TX"
  },
  {
    "zip": 75149,
    "lat": 32.77003,
    "lng": -96.61487,
    "state_id": "TX"
  },
  {
    "zip": 75150,
    "lat": 32.81577,
    "lng": -96.63039,
    "state_id": "TX"
  },
  {
    "zip": 75152,
    "lat": 32.43647,
    "lng": -96.68038,
    "state_id": "TX"
  },
  {
    "zip": 75153,
    "lat": 32.15027,
    "lng": -96.32856,
    "state_id": "TX"
  },
  {
    "zip": 75154,
    "lat": 32.52281,
    "lng": -96.81015,
    "state_id": "TX"
  },
  {
    "zip": 75155,
    "lat": 32.22573,
    "lng": -96.47318,
    "state_id": "TX"
  },
  {
    "zip": 75156,
    "lat": 32.28688,
    "lng": -96.09979,
    "state_id": "TX"
  },
  {
    "zip": 75157,
    "lat": 32.46227,
    "lng": -96.44684,
    "state_id": "TX"
  },
  {
    "zip": 75158,
    "lat": 32.46222,
    "lng": -96.39662,
    "state_id": "TX"
  },
  {
    "zip": 75159,
    "lat": 32.60748,
    "lng": -96.54087,
    "state_id": "TX"
  },
  {
    "zip": 75160,
    "lat": 32.76169,
    "lng": -96.30017,
    "state_id": "TX"
  },
  {
    "zip": 75161,
    "lat": 32.73742,
    "lng": -96.17115,
    "state_id": "TX"
  },
  {
    "zip": 75163,
    "lat": 32.16759,
    "lng": -96.11031,
    "state_id": "TX"
  },
  {
    "zip": 75164,
    "lat": 33.06593,
    "lng": -96.30793,
    "state_id": "TX"
  },
  {
    "zip": 75165,
    "lat": 32.36912,
    "lng": -96.79744,
    "state_id": "TX"
  },
  {
    "zip": 75166,
    "lat": 33.01234,
    "lng": -96.45026,
    "state_id": "TX"
  },
  {
    "zip": 75167,
    "lat": 32.35888,
    "lng": -96.91515,
    "state_id": "TX"
  },
  {
    "zip": 75169,
    "lat": 32.70454,
    "lng": -95.99657,
    "state_id": "TX"
  },
  {
    "zip": 75172,
    "lat": 32.60482,
    "lng": -96.67515,
    "state_id": "TX"
  },
  {
    "zip": 75173,
    "lat": 33.05923,
    "lng": -96.39106,
    "state_id": "TX"
  },
  {
    "zip": 75180,
    "lat": 32.71893,
    "lng": -96.61839,
    "state_id": "TX"
  },
  {
    "zip": 75181,
    "lat": 32.72697,
    "lng": -96.55519,
    "state_id": "TX"
  },
  {
    "zip": 75182,
    "lat": 32.80014,
    "lng": -96.55405,
    "state_id": "TX"
  },
  {
    "zip": 75189,
    "lat": 32.95183,
    "lng": -96.31192,
    "state_id": "TX"
  },
  {
    "zip": 75201,
    "lat": 32.78773,
    "lng": -96.79936,
    "state_id": "TX"
  },
  {
    "zip": 75202,
    "lat": 32.77924,
    "lng": -96.80472,
    "state_id": "TX"
  },
  {
    "zip": 75203,
    "lat": 32.74627,
    "lng": -96.803,
    "state_id": "TX"
  },
  {
    "zip": 75204,
    "lat": 32.80204,
    "lng": -96.78881,
    "state_id": "TX"
  },
  {
    "zip": 75205,
    "lat": 32.83657,
    "lng": -96.79619,
    "state_id": "TX"
  },
  {
    "zip": 75206,
    "lat": 32.83155,
    "lng": -96.77027,
    "state_id": "TX"
  },
  {
    "zip": 75207,
    "lat": 32.78676,
    "lng": -96.82139,
    "state_id": "TX"
  },
  {
    "zip": 75208,
    "lat": 32.75376,
    "lng": -96.83984,
    "state_id": "TX"
  },
  {
    "zip": 75209,
    "lat": 32.84885,
    "lng": -96.82587,
    "state_id": "TX"
  },
  {
    "zip": 75210,
    "lat": 32.77135,
    "lng": -96.74596,
    "state_id": "TX"
  },
  {
    "zip": 75211,
    "lat": 32.73493,
    "lng": -96.90297,
    "state_id": "TX"
  },
  {
    "zip": 75212,
    "lat": 32.78142,
    "lng": -96.87913,
    "state_id": "TX"
  },
  {
    "zip": 75214,
    "lat": 32.82865,
    "lng": -96.74543,
    "state_id": "TX"
  },
  {
    "zip": 75215,
    "lat": 32.75076,
    "lng": -96.7581,
    "state_id": "TX"
  },
  {
    "zip": 75216,
    "lat": 32.71165,
    "lng": -96.78371,
    "state_id": "TX"
  },
  {
    "zip": 75217,
    "lat": 32.71217,
    "lng": -96.68118,
    "state_id": "TX"
  },
  {
    "zip": 75218,
    "lat": 32.84154,
    "lng": -96.70237,
    "state_id": "TX"
  },
  {
    "zip": 75219,
    "lat": 32.81179,
    "lng": -96.81292,
    "state_id": "TX"
  },
  {
    "zip": 75220,
    "lat": 32.86664,
    "lng": -96.87615,
    "state_id": "TX"
  },
  {
    "zip": 75223,
    "lat": 32.79223,
    "lng": -96.74399,
    "state_id": "TX"
  },
  {
    "zip": 75224,
    "lat": 32.711,
    "lng": -96.83994,
    "state_id": "TX"
  },
  {
    "zip": 75225,
    "lat": 32.86521,
    "lng": -96.79098,
    "state_id": "TX"
  },
  {
    "zip": 75226,
    "lat": 32.7828,
    "lng": -96.77632,
    "state_id": "TX"
  },
  {
    "zip": 75227,
    "lat": 32.77,
    "lng": -96.68728,
    "state_id": "TX"
  },
  {
    "zip": 75228,
    "lat": 32.82443,
    "lng": -96.67996,
    "state_id": "TX"
  },
  {
    "zip": 75229,
    "lat": 32.89374,
    "lng": -96.86441,
    "state_id": "TX"
  },
  {
    "zip": 75230,
    "lat": 32.90229,
    "lng": -96.79207,
    "state_id": "TX"
  },
  {
    "zip": 75231,
    "lat": 32.87764,
    "lng": -96.74976,
    "state_id": "TX"
  },
  {
    "zip": 75232,
    "lat": 32.66095,
    "lng": -96.84018,
    "state_id": "TX"
  },
  {
    "zip": 75233,
    "lat": 32.70435,
    "lng": -96.87298,
    "state_id": "TX"
  },
  {
    "zip": 75234,
    "lat": 32.92366,
    "lng": -96.89042,
    "state_id": "TX"
  },
  {
    "zip": 75235,
    "lat": 32.8325,
    "lng": -96.84849,
    "state_id": "TX"
  },
  {
    "zip": 75236,
    "lat": 32.68557,
    "lng": -96.93576,
    "state_id": "TX"
  },
  {
    "zip": 75237,
    "lat": 32.66564,
    "lng": -96.8731,
    "state_id": "TX"
  },
  {
    "zip": 75238,
    "lat": 32.87853,
    "lng": -96.70782,
    "state_id": "TX"
  },
  {
    "zip": 75240,
    "lat": 32.93174,
    "lng": -96.78854,
    "state_id": "TX"
  },
  {
    "zip": 75241,
    "lat": 32.66571,
    "lng": -96.75904,
    "state_id": "TX"
  },
  {
    "zip": 75243,
    "lat": 32.91211,
    "lng": -96.73563,
    "state_id": "TX"
  },
  {
    "zip": 75244,
    "lat": 32.92542,
    "lng": -96.83663,
    "state_id": "TX"
  },
  {
    "zip": 75246,
    "lat": 32.79292,
    "lng": -96.7733,
    "state_id": "TX"
  },
  {
    "zip": 75247,
    "lat": 32.81358,
    "lng": -96.87748,
    "state_id": "TX"
  },
  {
    "zip": 75248,
    "lat": 32.96955,
    "lng": -96.79528,
    "state_id": "TX"
  },
  {
    "zip": 75249,
    "lat": 32.64442,
    "lng": -96.9588,
    "state_id": "TX"
  },
  {
    "zip": 75251,
    "lat": 32.91901,
    "lng": -96.77193,
    "state_id": "TX"
  },
  {
    "zip": 75252,
    "lat": 32.99723,
    "lng": -96.79081,
    "state_id": "TX"
  },
  {
    "zip": 75253,
    "lat": 32.67499,
    "lng": -96.61286,
    "state_id": "TX"
  },
  {
    "zip": 75254,
    "lat": 32.94562,
    "lng": -96.80133,
    "state_id": "TX"
  },
  {
    "zip": 75270,
    "lat": 32.78119,
    "lng": -96.80223,
    "state_id": "TX"
  },
  {
    "zip": 75287,
    "lat": 33.00032,
    "lng": -96.84114,
    "state_id": "TX"
  },
  {
    "zip": 75390,
    "lat": 32.81419,
    "lng": -96.8408,
    "state_id": "TX"
  },
  {
    "zip": 75401,
    "lat": 33.18426,
    "lng": -96.11952,
    "state_id": "TX"
  },
  {
    "zip": 75402,
    "lat": 33.06879,
    "lng": -96.08534,
    "state_id": "TX"
  },
  {
    "zip": 75407,
    "lat": 33.15089,
    "lng": -96.48796,
    "state_id": "TX"
  },
  {
    "zip": 75409,
    "lat": 33.35196,
    "lng": -96.52734,
    "state_id": "TX"
  },
  {
    "zip": 75410,
    "lat": 32.79101,
    "lng": -95.63456,
    "state_id": "TX"
  },
  {
    "zip": 75411,
    "lat": 33.86196,
    "lng": -95.64855,
    "state_id": "TX"
  },
  {
    "zip": 75412,
    "lat": 33.79984,
    "lng": -95.15232,
    "state_id": "TX"
  },
  {
    "zip": 75413,
    "lat": 33.4414,
    "lng": -96.17912,
    "state_id": "TX"
  },
  {
    "zip": 75414,
    "lat": 33.62333,
    "lng": -96.43179,
    "state_id": "TX"
  },
  {
    "zip": 75415,
    "lat": 33.45985,
    "lng": -95.75133,
    "state_id": "TX"
  },
  {
    "zip": 75416,
    "lat": 33.67825,
    "lng": -95.35442,
    "state_id": "TX"
  },
  {
    "zip": 75417,
    "lat": 33.43887,
    "lng": -95.13134,
    "state_id": "TX"
  },
  {
    "zip": 75418,
    "lat": 33.57269,
    "lng": -96.17766,
    "state_id": "TX"
  },
  {
    "zip": 75420,
    "lat": 33.053,
    "lng": -95.72612,
    "state_id": "TX"
  },
  {
    "zip": 75421,
    "lat": 33.64665,
    "lng": -95.7172,
    "state_id": "TX"
  },
  {
    "zip": 75422,
    "lat": 33.13937,
    "lng": -95.93198,
    "state_id": "TX"
  },
  {
    "zip": 75423,
    "lat": 33.28614,
    "lng": -96.19999,
    "state_id": "TX"
  },
  {
    "zip": 75424,
    "lat": 33.30659,
    "lng": -96.38586,
    "state_id": "TX"
  },
  {
    "zip": 75426,
    "lat": 33.65539,
    "lng": -95.00488,
    "state_id": "TX"
  },
  {
    "zip": 75428,
    "lat": 33.26847,
    "lng": -95.91606,
    "state_id": "TX"
  },
  {
    "zip": 75431,
    "lat": 33.01166,
    "lng": -95.46369,
    "state_id": "TX"
  },
  {
    "zip": 75432,
    "lat": 33.39605,
    "lng": -95.67442,
    "state_id": "TX"
  },
  {
    "zip": 75433,
    "lat": 33.10767,
    "lng": -95.80424,
    "state_id": "TX"
  },
  {
    "zip": 75435,
    "lat": 33.48223,
    "lng": -95.31354,
    "state_id": "TX"
  },
  {
    "zip": 75436,
    "lat": 33.71282,
    "lng": -95.25156,
    "state_id": "TX"
  },
  {
    "zip": 75437,
    "lat": 33.25841,
    "lng": -95.46899,
    "state_id": "TX"
  },
  {
    "zip": 75438,
    "lat": 33.60829,
    "lng": -96.06541,
    "state_id": "TX"
  },
  {
    "zip": 75439,
    "lat": 33.55199,
    "lng": -96.27716,
    "state_id": "TX"
  },
  {
    "zip": 75440,
    "lat": 32.87828,
    "lng": -95.75557,
    "state_id": "TX"
  },
  {
    "zip": 75441,
    "lat": 33.4322,
    "lng": -95.65349,
    "state_id": "TX"
  },
  {
    "zip": 75442,
    "lat": 33.17365,
    "lng": -96.35309,
    "state_id": "TX"
  },
  {
    "zip": 75446,
    "lat": 33.61208,
    "lng": -95.89414,
    "state_id": "TX"
  },
  {
    "zip": 75447,
    "lat": 33.76513,
    "lng": -96.1177,
    "state_id": "TX"
  },
  {
    "zip": 75448,
    "lat": 33.31493,
    "lng": -95.79329,
    "state_id": "TX"
  },
  {
    "zip": 75449,
    "lat": 33.42067,
    "lng": -95.94604,
    "state_id": "TX"
  },
  {
    "zip": 75450,
    "lat": 33.4051,
    "lng": -95.47848,
    "state_id": "TX"
  },
  {
    "zip": 75451,
    "lat": 32.96634,
    "lng": -95.1212,
    "state_id": "TX"
  },
  {
    "zip": 75452,
    "lat": 33.39324,
    "lng": -96.24331,
    "state_id": "TX"
  },
  {
    "zip": 75453,
    "lat": 32.99671,
    "lng": -95.93273,
    "state_id": "TX"
  },
  {
    "zip": 75454,
    "lat": 33.28335,
    "lng": -96.56323,
    "state_id": "TX"
  },
  {
    "zip": 75455,
    "lat": 33.21697,
    "lng": -94.97819,
    "state_id": "TX"
  },
  {
    "zip": 75457,
    "lat": 33.1728,
    "lng": -95.21222,
    "state_id": "TX"
  },
  {
    "zip": 75459,
    "lat": 33.53109,
    "lng": -96.67755,
    "state_id": "TX"
  },
  {
    "zip": 75460,
    "lat": 33.65814,
    "lng": -95.6042,
    "state_id": "TX"
  },
  {
    "zip": 75462,
    "lat": 33.62386,
    "lng": -95.48378,
    "state_id": "TX"
  },
  {
    "zip": 75468,
    "lat": 33.51315,
    "lng": -95.40653,
    "state_id": "TX"
  },
  {
    "zip": 75469,
    "lat": 33.42161,
    "lng": -95.8146,
    "state_id": "TX"
  },
  {
    "zip": 75470,
    "lat": 33.58231,
    "lng": -95.80822,
    "state_id": "TX"
  },
  {
    "zip": 75471,
    "lat": 33.03945,
    "lng": -95.38927,
    "state_id": "TX"
  },
  {
    "zip": 75472,
    "lat": 32.88593,
    "lng": -95.87797,
    "state_id": "TX"
  },
  {
    "zip": 75473,
    "lat": 33.81433,
    "lng": -95.48985,
    "state_id": "TX"
  },
  {
    "zip": 75474,
    "lat": 32.91953,
    "lng": -96.10899,
    "state_id": "TX"
  },
  {
    "zip": 75475,
    "lat": 33.47484,
    "lng": -96.23822,
    "state_id": "TX"
  },
  {
    "zip": 75476,
    "lat": 33.70586,
    "lng": -96.24354,
    "state_id": "TX"
  },
  {
    "zip": 75477,
    "lat": 33.53286,
    "lng": -95.73471,
    "state_id": "TX"
  },
  {
    "zip": 75478,
    "lat": 33.17445,
    "lng": -95.35751,
    "state_id": "TX"
  },
  {
    "zip": 75479,
    "lat": 33.62159,
    "lng": -96.33922,
    "state_id": "TX"
  },
  {
    "zip": 75480,
    "lat": 33.02472,
    "lng": -95.19411,
    "state_id": "TX"
  },
  {
    "zip": 75481,
    "lat": 33.31522,
    "lng": -95.37374,
    "state_id": "TX"
  },
  {
    "zip": 75482,
    "lat": 33.16816,
    "lng": -95.60681,
    "state_id": "TX"
  },
  {
    "zip": 75486,
    "lat": 33.7583,
    "lng": -95.7486,
    "state_id": "TX"
  },
  {
    "zip": 75487,
    "lat": 33.33605,
    "lng": -95.16159,
    "state_id": "TX"
  },
  {
    "zip": 75488,
    "lat": 33.77723,
    "lng": -95.98748,
    "state_id": "TX"
  },
  {
    "zip": 75489,
    "lat": 33.51926,
    "lng": -96.48429,
    "state_id": "TX"
  },
  {
    "zip": 75490,
    "lat": 33.43427,
    "lng": -96.31339,
    "state_id": "TX"
  },
  {
    "zip": 75491,
    "lat": 33.49636,
    "lng": -96.39753,
    "state_id": "TX"
  },
  {
    "zip": 75492,
    "lat": 33.58768,
    "lng": -95.99551,
    "state_id": "TX"
  },
  {
    "zip": 75493,
    "lat": 33.13883,
    "lng": -95.10854,
    "state_id": "TX"
  },
  {
    "zip": 75494,
    "lat": 32.90937,
    "lng": -95.26234,
    "state_id": "TX"
  },
  {
    "zip": 75495,
    "lat": 33.43311,
    "lng": -96.55161,
    "state_id": "TX"
  },
  {
    "zip": 75496,
    "lat": 33.35984,
    "lng": -96.06298,
    "state_id": "TX"
  },
  {
    "zip": 75497,
    "lat": 32.922,
    "lng": -95.572,
    "state_id": "TX"
  },
  {
    "zip": 75501,
    "lat": 33.37366,
    "lng": -94.13142,
    "state_id": "TX"
  },
  {
    "zip": 75503,
    "lat": 33.51345,
    "lng": -94.13057,
    "state_id": "TX"
  },
  {
    "zip": 75550,
    "lat": 33.49406,
    "lng": -94.88241,
    "state_id": "TX"
  },
  {
    "zip": 75551,
    "lat": 33.11556,
    "lng": -94.20197,
    "state_id": "TX"
  },
  {
    "zip": 75554,
    "lat": 33.5549,
    "lng": -94.7795,
    "state_id": "TX"
  },
  {
    "zip": 75555,
    "lat": 32.94688,
    "lng": -94.13833,
    "state_id": "TX"
  },
  {
    "zip": 75556,
    "lat": 33.16112,
    "lng": -94.06127,
    "state_id": "TX"
  },
  {
    "zip": 75558,
    "lat": 33.2419,
    "lng": -94.85805,
    "state_id": "TX"
  },
  {
    "zip": 75559,
    "lat": 33.50992,
    "lng": -94.62748,
    "state_id": "TX"
  },
  {
    "zip": 75560,
    "lat": 33.18696,
    "lng": -94.36878,
    "state_id": "TX"
  },
  {
    "zip": 75561,
    "lat": 33.51333,
    "lng": -94.27781,
    "state_id": "TX"
  },
  {
    "zip": 75562,
    "lat": 32.92543,
    "lng": -94.24848,
    "state_id": "TX"
  },
  {
    "zip": 75563,
    "lat": 33.00608,
    "lng": -94.38964,
    "state_id": "TX"
  },
  {
    "zip": 75565,
    "lat": 32.93731,
    "lng": -94.0722,
    "state_id": "TX"
  },
  {
    "zip": 75566,
    "lat": 33.14818,
    "lng": -94.49175,
    "state_id": "TX"
  },
  {
    "zip": 75567,
    "lat": 33.31403,
    "lng": -94.31877,
    "state_id": "TX"
  },
  {
    "zip": 75568,
    "lat": 33.20102,
    "lng": -94.59619,
    "state_id": "TX"
  },
  {
    "zip": 75569,
    "lat": 33.44145,
    "lng": -94.12641,
    "state_id": "TX"
  },
  {
    "zip": 75570,
    "lat": 33.46195,
    "lng": -94.43753,
    "state_id": "TX"
  },
  {
    "zip": 75571,
    "lat": 33.20616,
    "lng": -94.764,
    "state_id": "TX"
  },
  {
    "zip": 75572,
    "lat": 33.23154,
    "lng": -94.13216,
    "state_id": "TX"
  },
  {
    "zip": 75573,
    "lat": 33.3423,
    "lng": -94.23728,
    "state_id": "TX"
  },
  {
    "zip": 75574,
    "lat": 33.33096,
    "lng": -94.55347,
    "state_id": "TX"
  },
  {
    "zip": 75601,
    "lat": 32.50856,
    "lng": -94.72383,
    "state_id": "TX"
  },
  {
    "zip": 75602,
    "lat": 32.459,
    "lng": -94.65992,
    "state_id": "TX"
  },
  {
    "zip": 75603,
    "lat": 32.39854,
    "lng": -94.70911,
    "state_id": "TX"
  },
  {
    "zip": 75604,
    "lat": 32.50965,
    "lng": -94.82774,
    "state_id": "TX"
  },
  {
    "zip": 75605,
    "lat": 32.58257,
    "lng": -94.72064,
    "state_id": "TX"
  },
  {
    "zip": 75630,
    "lat": 32.87431,
    "lng": -94.55271,
    "state_id": "TX"
  },
  {
    "zip": 75631,
    "lat": 32.24926,
    "lng": -94.45456,
    "state_id": "TX"
  },
  {
    "zip": 75633,
    "lat": 32.12895,
    "lng": -94.27705,
    "state_id": "TX"
  },
  {
    "zip": 75638,
    "lat": 33.02095,
    "lng": -94.73186,
    "state_id": "TX"
  },
  {
    "zip": 75639,
    "lat": 32.29033,
    "lng": -94.16944,
    "state_id": "TX"
  },
  {
    "zip": 75640,
    "lat": 32.71557,
    "lng": -94.68413,
    "state_id": "TX"
  },
  {
    "zip": 75641,
    "lat": 32.37844,
    "lng": -94.57574,
    "state_id": "TX"
  },
  {
    "zip": 75642,
    "lat": 32.37152,
    "lng": -94.17658,
    "state_id": "TX"
  },
  {
    "zip": 75643,
    "lat": 32.01618,
    "lng": -94.36084,
    "state_id": "TX"
  },
  {
    "zip": 75644,
    "lat": 32.7951,
    "lng": -94.99553,
    "state_id": "TX"
  },
  {
    "zip": 75645,
    "lat": 32.68749,
    "lng": -94.87405,
    "state_id": "TX"
  },
  {
    "zip": 75647,
    "lat": 32.5189,
    "lng": -94.95133,
    "state_id": "TX"
  },
  {
    "zip": 75650,
    "lat": 32.51394,
    "lng": -94.55473,
    "state_id": "TX"
  },
  {
    "zip": 75651,
    "lat": 32.67157,
    "lng": -94.53603,
    "state_id": "TX"
  },
  {
    "zip": 75652,
    "lat": 32.22989,
    "lng": -94.73272,
    "state_id": "TX"
  },
  {
    "zip": 75654,
    "lat": 32.08099,
    "lng": -94.82647,
    "state_id": "TX"
  },
  {
    "zip": 75656,
    "lat": 33.00552,
    "lng": -94.59071,
    "state_id": "TX"
  },
  {
    "zip": 75657,
    "lat": 32.7922,
    "lng": -94.30601,
    "state_id": "TX"
  },
  {
    "zip": 75661,
    "lat": 32.63754,
    "lng": -94.16153,
    "state_id": "TX"
  },
  {
    "zip": 75662,
    "lat": 32.38397,
    "lng": -94.87014,
    "state_id": "TX"
  },
  {
    "zip": 75667,
    "lat": 31.98544,
    "lng": -94.84005,
    "state_id": "TX"
  },
  {
    "zip": 75668,
    "lat": 32.90453,
    "lng": -94.68362,
    "state_id": "TX"
  },
  {
    "zip": 75669,
    "lat": 32.03376,
    "lng": -94.56395,
    "state_id": "TX"
  },
  {
    "zip": 75670,
    "lat": 32.57299,
    "lng": -94.42297,
    "state_id": "TX"
  },
  {
    "zip": 75672,
    "lat": 32.46883,
    "lng": -94.29489,
    "state_id": "TX"
  },
  {
    "zip": 75681,
    "lat": 31.92844,
    "lng": -94.69197,
    "state_id": "TX"
  },
  {
    "zip": 75682,
    "lat": 32.25033,
    "lng": -94.9366,
    "state_id": "TX"
  },
  {
    "zip": 75683,
    "lat": 32.82301,
    "lng": -94.73701,
    "state_id": "TX"
  },
  {
    "zip": 75684,
    "lat": 32.27335,
    "lng": -94.93895,
    "state_id": "TX"
  },
  {
    "zip": 75686,
    "lat": 32.96953,
    "lng": -94.95018,
    "state_id": "TX"
  },
  {
    "zip": 75691,
    "lat": 32.31327,
    "lng": -94.55066,
    "state_id": "TX"
  },
  {
    "zip": 75692,
    "lat": 32.48038,
    "lng": -94.11629,
    "state_id": "TX"
  },
  {
    "zip": 75693,
    "lat": 32.53385,
    "lng": -94.85985,
    "state_id": "TX"
  },
  {
    "zip": 75701,
    "lat": 32.32331,
    "lng": -95.3009,
    "state_id": "TX"
  },
  {
    "zip": 75702,
    "lat": 32.36287,
    "lng": -95.31531,
    "state_id": "TX"
  },
  {
    "zip": 75703,
    "lat": 32.23855,
    "lng": -95.32063,
    "state_id": "TX"
  },
  {
    "zip": 75704,
    "lat": 32.40753,
    "lng": -95.44131,
    "state_id": "TX"
  },
  {
    "zip": 75705,
    "lat": 32.36603,
    "lng": -95.10274,
    "state_id": "TX"
  },
  {
    "zip": 75706,
    "lat": 32.466,
    "lng": -95.3076,
    "state_id": "TX"
  },
  {
    "zip": 75707,
    "lat": 32.30359,
    "lng": -95.17687,
    "state_id": "TX"
  },
  {
    "zip": 75708,
    "lat": 32.41635,
    "lng": -95.20959,
    "state_id": "TX"
  },
  {
    "zip": 75709,
    "lat": 32.31072,
    "lng": -95.39289,
    "state_id": "TX"
  },
  {
    "zip": 75750,
    "lat": 32.27443,
    "lng": -95.07493,
    "state_id": "TX"
  },
  {
    "zip": 75751,
    "lat": 32.09643,
    "lng": -95.90829,
    "state_id": "TX"
  },
  {
    "zip": 75752,
    "lat": 32.24454,
    "lng": -95.80858,
    "state_id": "TX"
  },
  {
    "zip": 75754,
    "lat": 32.42579,
    "lng": -95.66519,
    "state_id": "TX"
  },
  {
    "zip": 75755,
    "lat": 32.63315,
    "lng": -95.08596,
    "state_id": "TX"
  },
  {
    "zip": 75756,
    "lat": 32.30402,
    "lng": -95.60003,
    "state_id": "TX"
  },
  {
    "zip": 75757,
    "lat": 32.11461,
    "lng": -95.349,
    "state_id": "TX"
  },
  {
    "zip": 75758,
    "lat": 32.27541,
    "lng": -95.50733,
    "state_id": "TX"
  },
  {
    "zip": 75759,
    "lat": 32.03594,
    "lng": -95.41876,
    "state_id": "TX"
  },
  {
    "zip": 75760,
    "lat": 31.8041,
    "lng": -94.8556,
    "state_id": "TX"
  },
  {
    "zip": 75762,
    "lat": 32.21681,
    "lng": -95.41378,
    "state_id": "TX"
  },
  {
    "zip": 75763,
    "lat": 32.04187,
    "lng": -95.52214,
    "state_id": "TX"
  },
  {
    "zip": 75764,
    "lat": 31.89544,
    "lng": -95.14372,
    "state_id": "TX"
  },
  {
    "zip": 75765,
    "lat": 32.64159,
    "lng": -95.23516,
    "state_id": "TX"
  },
  {
    "zip": 75766,
    "lat": 31.9462,
    "lng": -95.25702,
    "state_id": "TX"
  },
  {
    "zip": 75770,
    "lat": 32.13855,
    "lng": -95.64876,
    "state_id": "TX"
  },
  {
    "zip": 75771,
    "lat": 32.5361,
    "lng": -95.43456,
    "state_id": "TX"
  },
  {
    "zip": 75773,
    "lat": 32.67876,
    "lng": -95.44591,
    "state_id": "TX"
  },
  {
    "zip": 75778,
    "lat": 32.3023,
    "lng": -95.71185,
    "state_id": "TX"
  },
  {
    "zip": 75779,
    "lat": 31.86529,
    "lng": -95.48595,
    "state_id": "TX"
  },
  {
    "zip": 75780,
    "lat": 31.99099,
    "lng": -95.09183,
    "state_id": "TX"
  },
  {
    "zip": 75783,
    "lat": 32.82157,
    "lng": -95.42365,
    "state_id": "TX"
  },
  {
    "zip": 75784,
    "lat": 31.8737,
    "lng": -94.97332,
    "state_id": "TX"
  },
  {
    "zip": 75785,
    "lat": 31.77994,
    "lng": -95.17381,
    "state_id": "TX"
  },
  {
    "zip": 75788,
    "lat": 31.82598,
    "lng": -94.92588,
    "state_id": "TX"
  },
  {
    "zip": 75789,
    "lat": 32.10909,
    "lng": -95.09693,
    "state_id": "TX"
  },
  {
    "zip": 75790,
    "lat": 32.53231,
    "lng": -95.6426,
    "state_id": "TX"
  },
  {
    "zip": 75791,
    "lat": 32.22164,
    "lng": -95.22053,
    "state_id": "TX"
  },
  {
    "zip": 75792,
    "lat": 32.47668,
    "lng": -95.10197,
    "state_id": "TX"
  },
  {
    "zip": 75801,
    "lat": 31.73457,
    "lng": -95.53316,
    "state_id": "TX"
  },
  {
    "zip": 75803,
    "lat": 31.87441,
    "lng": -95.66916,
    "state_id": "TX"
  },
  {
    "zip": 75831,
    "lat": 31.46234,
    "lng": -96.01473,
    "state_id": "TX"
  },
  {
    "zip": 75832,
    "lat": 31.91904,
    "lng": -95.9946,
    "state_id": "TX"
  },
  {
    "zip": 75833,
    "lat": 31.26718,
    "lng": -95.839,
    "state_id": "TX"
  },
  {
    "zip": 75835,
    "lat": 31.29348,
    "lng": -95.49196,
    "state_id": "TX"
  },
  {
    "zip": 75838,
    "lat": 31.47745,
    "lng": -96.22824,
    "state_id": "TX"
  },
  {
    "zip": 75839,
    "lat": 31.60599,
    "lng": -95.5779,
    "state_id": "TX"
  },
  {
    "zip": 75840,
    "lat": 31.78236,
    "lng": -96.08899,
    "state_id": "TX"
  },
  {
    "zip": 75844,
    "lat": 31.51376,
    "lng": -95.43842,
    "state_id": "TX"
  },
  {
    "zip": 75845,
    "lat": 31.10075,
    "lng": -95.08954,
    "state_id": "TX"
  },
  {
    "zip": 75846,
    "lat": 31.33865,
    "lng": -96.16544,
    "state_id": "TX"
  },
  {
    "zip": 75847,
    "lat": 31.35383,
    "lng": -95.14609,
    "state_id": "TX"
  },
  {
    "zip": 75848,
    "lat": 31.76525,
    "lng": -96.32993,
    "state_id": "TX"
  },
  {
    "zip": 75849,
    "lat": 31.41743,
    "lng": -95.48164,
    "state_id": "TX"
  },
  {
    "zip": 75850,
    "lat": 31.13741,
    "lng": -95.9168,
    "state_id": "TX"
  },
  {
    "zip": 75851,
    "lat": 31.09323,
    "lng": -95.49712,
    "state_id": "TX"
  },
  {
    "zip": 75852,
    "lat": 30.96685,
    "lng": -95.71166,
    "state_id": "TX"
  },
  {
    "zip": 75853,
    "lat": 31.93692,
    "lng": -95.81323,
    "state_id": "TX"
  },
  {
    "zip": 75855,
    "lat": 31.56221,
    "lng": -95.85025,
    "state_id": "TX"
  },
  {
    "zip": 75856,
    "lat": 31.18891,
    "lng": -95.25576,
    "state_id": "TX"
  },
  {
    "zip": 75858,
    "lat": 31.39074,
    "lng": -95.12689,
    "state_id": "TX"
  },
  {
    "zip": 75859,
    "lat": 31.88161,
    "lng": -96.24023,
    "state_id": "TX"
  },
  {
    "zip": 75860,
    "lat": 31.62091,
    "lng": -96.24864,
    "state_id": "TX"
  },
  {
    "zip": 75861,
    "lat": 31.85323,
    "lng": -95.8924,
    "state_id": "TX"
  },
  {
    "zip": 75862,
    "lat": 30.95984,
    "lng": -95.32384,
    "state_id": "TX"
  },
  {
    "zip": 75901,
    "lat": 31.30134,
    "lng": -94.65552,
    "state_id": "TX"
  },
  {
    "zip": 75904,
    "lat": 31.3397,
    "lng": -94.82173,
    "state_id": "TX"
  },
  {
    "zip": 75925,
    "lat": 31.61114,
    "lng": -95.06597,
    "state_id": "TX"
  },
  {
    "zip": 75926,
    "lat": 31.25151,
    "lng": -94.96273,
    "state_id": "TX"
  },
  {
    "zip": 75928,
    "lat": 30.67148,
    "lng": -93.71173,
    "state_id": "TX"
  },
  {
    "zip": 75929,
    "lat": 31.24549,
    "lng": -94.19464,
    "state_id": "TX"
  },
  {
    "zip": 75930,
    "lat": 31.32203,
    "lng": -94.03261,
    "state_id": "TX"
  },
  {
    "zip": 75931,
    "lat": 31.1108,
    "lng": -94.00384,
    "state_id": "TX"
  },
  {
    "zip": 75932,
    "lat": 31.05408,
    "lng": -93.60988,
    "state_id": "TX"
  },
  {
    "zip": 75933,
    "lat": 30.55809,
    "lng": -93.81161,
    "state_id": "TX"
  },
  {
    "zip": 75934,
    "lat": 30.89591,
    "lng": -94.75068,
    "state_id": "TX"
  },
  {
    "zip": 75935,
    "lat": 31.73269,
    "lng": -94.20156,
    "state_id": "TX"
  },
  {
    "zip": 75936,
    "lat": 30.95213,
    "lng": -94.57961,
    "state_id": "TX"
  },
  {
    "zip": 75937,
    "lat": 31.45872,
    "lng": -94.3765,
    "state_id": "TX"
  },
  {
    "zip": 75938,
    "lat": 30.93262,
    "lng": -94.35342,
    "state_id": "TX"
  },
  {
    "zip": 75939,
    "lat": 31.01824,
    "lng": -94.79403,
    "state_id": "TX"
  },
  {
    "zip": 75941,
    "lat": 31.17992,
    "lng": -94.76368,
    "state_id": "TX"
  },
  {
    "zip": 75942,
    "lat": 30.81634,
    "lng": -94.43146,
    "state_id": "TX"
  },
  {
    "zip": 75943,
    "lat": 31.65356,
    "lng": -94.88784,
    "state_id": "TX"
  },
  {
    "zip": 75944,
    "lat": 31.33734,
    "lng": -94.38859,
    "state_id": "TX"
  },
  {
    "zip": 75946,
    "lat": 31.81362,
    "lng": -94.52541,
    "state_id": "TX"
  },
  {
    "zip": 75948,
    "lat": 31.30812,
    "lng": -93.77894,
    "state_id": "TX"
  },
  {
    "zip": 75949,
    "lat": 31.23143,
    "lng": -94.51943,
    "state_id": "TX"
  },
  {
    "zip": 75951,
    "lat": 30.94151,
    "lng": -94.05384,
    "state_id": "TX"
  },
  {
    "zip": 75954,
    "lat": 31.92347,
    "lng": -94.05436,
    "state_id": "TX"
  },
  {
    "zip": 75956,
    "lat": 30.67771,
    "lng": -93.97821,
    "state_id": "TX"
  },
  {
    "zip": 75959,
    "lat": 31.51451,
    "lng": -93.8493,
    "state_id": "TX"
  },
  {
    "zip": 75960,
    "lat": 30.91444,
    "lng": -94.86708,
    "state_id": "TX"
  },
  {
    "zip": 75961,
    "lat": 31.56113,
    "lng": -94.5,
    "state_id": "TX"
  },
  {
    "zip": 75962,
    "lat": 31.61986,
    "lng": -94.64708,
    "state_id": "TX"
  },
  {
    "zip": 75964,
    "lat": 31.62169,
    "lng": -94.74495,
    "state_id": "TX"
  },
  {
    "zip": 75965,
    "lat": 31.72385,
    "lng": -94.62609,
    "state_id": "TX"
  },
  {
    "zip": 75966,
    "lat": 30.8352,
    "lng": -93.72843,
    "state_id": "TX"
  },
  {
    "zip": 75968,
    "lat": 31.25261,
    "lng": -93.95041,
    "state_id": "TX"
  },
  {
    "zip": 75969,
    "lat": 31.44688,
    "lng": -94.89891,
    "state_id": "TX"
  },
  {
    "zip": 75972,
    "lat": 31.49498,
    "lng": -94.12708,
    "state_id": "TX"
  },
  {
    "zip": 75973,
    "lat": 31.71654,
    "lng": -93.93295,
    "state_id": "TX"
  },
  {
    "zip": 75974,
    "lat": 31.95671,
    "lng": -94.2387,
    "state_id": "TX"
  },
  {
    "zip": 75975,
    "lat": 31.89738,
    "lng": -94.41302,
    "state_id": "TX"
  },
  {
    "zip": 75976,
    "lat": 31.5206,
    "lng": -94.92745,
    "state_id": "TX"
  },
  {
    "zip": 75977,
    "lat": 31.06121,
    "lng": -93.78207,
    "state_id": "TX"
  },
  {
    "zip": 75978,
    "lat": 31.50905,
    "lng": -94.5272,
    "state_id": "TX"
  },
  {
    "zip": 75979,
    "lat": 30.77631,
    "lng": -94.40151,
    "state_id": "TX"
  },
  {
    "zip": 75980,
    "lat": 31.12666,
    "lng": -94.35255,
    "state_id": "TX"
  },
  {
    "zip": 76001,
    "lat": 32.6304,
    "lng": -97.15275,
    "state_id": "TX"
  },
  {
    "zip": 76002,
    "lat": 32.62262,
    "lng": -97.09224,
    "state_id": "TX"
  },
  {
    "zip": 76006,
    "lat": 32.78493,
    "lng": -97.10018,
    "state_id": "TX"
  },
  {
    "zip": 76008,
    "lat": 32.69291,
    "lng": -97.62376,
    "state_id": "TX"
  },
  {
    "zip": 76009,
    "lat": 32.4158,
    "lng": -97.20169,
    "state_id": "TX"
  },
  {
    "zip": 76010,
    "lat": 32.72283,
    "lng": -97.08035,
    "state_id": "TX"
  },
  {
    "zip": 76011,
    "lat": 32.75425,
    "lng": -97.08253,
    "state_id": "TX"
  },
  {
    "zip": 76012,
    "lat": 32.75461,
    "lng": -97.13902,
    "state_id": "TX"
  },
  {
    "zip": 76013,
    "lat": 32.72033,
    "lng": -97.15647,
    "state_id": "TX"
  },
  {
    "zip": 76014,
    "lat": 32.69202,
    "lng": -97.08794,
    "state_id": "TX"
  },
  {
    "zip": 76015,
    "lat": 32.69221,
    "lng": -97.13341,
    "state_id": "TX"
  },
  {
    "zip": 76016,
    "lat": 32.68906,
    "lng": -97.18871,
    "state_id": "TX"
  },
  {
    "zip": 76017,
    "lat": 32.66204,
    "lng": -97.16261,
    "state_id": "TX"
  },
  {
    "zip": 76018,
    "lat": 32.66077,
    "lng": -97.09,
    "state_id": "TX"
  },
  {
    "zip": 76020,
    "lat": 32.90006,
    "lng": -97.56542,
    "state_id": "TX"
  },
  {
    "zip": 76021,
    "lat": 32.85231,
    "lng": -97.13119,
    "state_id": "TX"
  },
  {
    "zip": 76022,
    "lat": 32.83064,
    "lng": -97.14523,
    "state_id": "TX"
  },
  {
    "zip": 76023,
    "lat": 33.04851,
    "lng": -97.60577,
    "state_id": "TX"
  },
  {
    "zip": 76028,
    "lat": 32.52604,
    "lng": -97.30318,
    "state_id": "TX"
  },
  {
    "zip": 76031,
    "lat": 32.34848,
    "lng": -97.33114,
    "state_id": "TX"
  },
  {
    "zip": 76033,
    "lat": 32.28511,
    "lng": -97.51137,
    "state_id": "TX"
  },
  {
    "zip": 76034,
    "lat": 32.8912,
    "lng": -97.14876,
    "state_id": "TX"
  },
  {
    "zip": 76035,
    "lat": 32.56288,
    "lng": -97.63361,
    "state_id": "TX"
  },
  {
    "zip": 76036,
    "lat": 32.57702,
    "lng": -97.41284,
    "state_id": "TX"
  },
  {
    "zip": 76039,
    "lat": 32.85936,
    "lng": -97.08383,
    "state_id": "TX"
  },
  {
    "zip": 76040,
    "lat": 32.8147,
    "lng": -97.0968,
    "state_id": "TX"
  },
  {
    "zip": 76041,
    "lat": 32.24005,
    "lng": -96.84634,
    "state_id": "TX"
  },
  {
    "zip": 76043,
    "lat": 32.20528,
    "lng": -97.79709,
    "state_id": "TX"
  },
  {
    "zip": 76044,
    "lat": 32.4276,
    "lng": -97.54354,
    "state_id": "TX"
  },
  {
    "zip": 76048,
    "lat": 32.42318,
    "lng": -97.80877,
    "state_id": "TX"
  },
  {
    "zip": 76049,
    "lat": 32.46394,
    "lng": -97.70805,
    "state_id": "TX"
  },
  {
    "zip": 76050,
    "lat": 32.27537,
    "lng": -97.16601,
    "state_id": "TX"
  },
  {
    "zip": 76051,
    "lat": 32.92499,
    "lng": -97.07177,
    "state_id": "TX"
  },
  {
    "zip": 76052,
    "lat": 32.98624,
    "lng": -97.37655,
    "state_id": "TX"
  },
  {
    "zip": 76053,
    "lat": 32.8171,
    "lng": -97.17953,
    "state_id": "TX"
  },
  {
    "zip": 76054,
    "lat": 32.85918,
    "lng": -97.17781,
    "state_id": "TX"
  },
  {
    "zip": 76055,
    "lat": 32.16217,
    "lng": -97.15511,
    "state_id": "TX"
  },
  {
    "zip": 76058,
    "lat": 32.46693,
    "lng": -97.42394,
    "state_id": "TX"
  },
  {
    "zip": 76059,
    "lat": 32.39252,
    "lng": -97.32687,
    "state_id": "TX"
  },
  {
    "zip": 76060,
    "lat": 32.64076,
    "lng": -97.21585,
    "state_id": "TX"
  },
  {
    "zip": 76061,
    "lat": 32.50396,
    "lng": -97.18842,
    "state_id": "TX"
  },
  {
    "zip": 76063,
    "lat": 32.56979,
    "lng": -97.14275,
    "state_id": "TX"
  },
  {
    "zip": 76064,
    "lat": 32.30136,
    "lng": -97.03549,
    "state_id": "TX"
  },
  {
    "zip": 76065,
    "lat": 32.4705,
    "lng": -96.989,
    "state_id": "TX"
  },
  {
    "zip": 76066,
    "lat": 32.70727,
    "lng": -97.99502,
    "state_id": "TX"
  },
  {
    "zip": 76067,
    "lat": 32.80005,
    "lng": -98.12756,
    "state_id": "TX"
  },
  {
    "zip": 76070,
    "lat": 32.26241,
    "lng": -97.65424,
    "state_id": "TX"
  },
  {
    "zip": 76071,
    "lat": 33.00546,
    "lng": -97.47935,
    "state_id": "TX"
  },
  {
    "zip": 76073,
    "lat": 33.10349,
    "lng": -97.72734,
    "state_id": "TX"
  },
  {
    "zip": 76077,
    "lat": 32.28213,
    "lng": -97.70548,
    "state_id": "TX"
  },
  {
    "zip": 76078,
    "lat": 33.08919,
    "lng": -97.46659,
    "state_id": "TX"
  },
  {
    "zip": 76082,
    "lat": 32.97051,
    "lng": -97.71844,
    "state_id": "TX"
  },
  {
    "zip": 76084,
    "lat": 32.43218,
    "lng": -97.08946,
    "state_id": "TX"
  },
  {
    "zip": 76085,
    "lat": 32.85029,
    "lng": -97.70077,
    "state_id": "TX"
  },
  {
    "zip": 76086,
    "lat": 32.75479,
    "lng": -97.78995,
    "state_id": "TX"
  },
  {
    "zip": 76087,
    "lat": 32.67075,
    "lng": -97.78313,
    "state_id": "TX"
  },
  {
    "zip": 76088,
    "lat": 32.84576,
    "lng": -97.90143,
    "state_id": "TX"
  },
  {
    "zip": 76092,
    "lat": 32.95998,
    "lng": -97.1486,
    "state_id": "TX"
  },
  {
    "zip": 76093,
    "lat": 32.21723,
    "lng": -97.3949,
    "state_id": "TX"
  },
  {
    "zip": 76102,
    "lat": 32.75923,
    "lng": -97.32978,
    "state_id": "TX"
  },
  {
    "zip": 76103,
    "lat": 32.75205,
    "lng": -97.26547,
    "state_id": "TX"
  },
  {
    "zip": 76104,
    "lat": 32.7287,
    "lng": -97.31777,
    "state_id": "TX"
  },
  {
    "zip": 76105,
    "lat": 32.72405,
    "lng": -97.26937,
    "state_id": "TX"
  },
  {
    "zip": 76106,
    "lat": 32.81451,
    "lng": -97.35244,
    "state_id": "TX"
  },
  {
    "zip": 76107,
    "lat": 32.74171,
    "lng": -97.38196,
    "state_id": "TX"
  },
  {
    "zip": 76108,
    "lat": 32.77595,
    "lng": -97.52195,
    "state_id": "TX"
  },
  {
    "zip": 76109,
    "lat": 32.701,
    "lng": -97.38393,
    "state_id": "TX"
  },
  {
    "zip": 76110,
    "lat": 32.70699,
    "lng": -97.33891,
    "state_id": "TX"
  },
  {
    "zip": 76111,
    "lat": 32.77808,
    "lng": -97.29988,
    "state_id": "TX"
  },
  {
    "zip": 76112,
    "lat": 32.74812,
    "lng": -97.21845,
    "state_id": "TX"
  },
  {
    "zip": 76114,
    "lat": 32.77444,
    "lng": -97.40272,
    "state_id": "TX"
  },
  {
    "zip": 76115,
    "lat": 32.67879,
    "lng": -97.33076,
    "state_id": "TX"
  },
  {
    "zip": 76116,
    "lat": 32.72173,
    "lng": -97.44636,
    "state_id": "TX"
  },
  {
    "zip": 76117,
    "lat": 32.80369,
    "lng": -97.26756,
    "state_id": "TX"
  },
  {
    "zip": 76118,
    "lat": 32.79966,
    "lng": -97.20773,
    "state_id": "TX"
  },
  {
    "zip": 76119,
    "lat": 32.69064,
    "lng": -97.26215,
    "state_id": "TX"
  },
  {
    "zip": 76120,
    "lat": 32.77223,
    "lng": -97.1782,
    "state_id": "TX"
  },
  {
    "zip": 76123,
    "lat": 32.61898,
    "lng": -97.39318,
    "state_id": "TX"
  },
  {
    "zip": 76126,
    "lat": 32.63382,
    "lng": -97.51239,
    "state_id": "TX"
  },
  {
    "zip": 76127,
    "lat": 32.77701,
    "lng": -97.43019,
    "state_id": "TX"
  },
  {
    "zip": 76129,
    "lat": 32.7087,
    "lng": -97.36342,
    "state_id": "TX"
  },
  {
    "zip": 76131,
    "lat": 32.88334,
    "lng": -97.34691,
    "state_id": "TX"
  },
  {
    "zip": 76132,
    "lat": 32.66759,
    "lng": -97.41797,
    "state_id": "TX"
  },
  {
    "zip": 76133,
    "lat": 32.65393,
    "lng": -97.37723,
    "state_id": "TX"
  },
  {
    "zip": 76134,
    "lat": 32.64275,
    "lng": -97.33353,
    "state_id": "TX"
  },
  {
    "zip": 76135,
    "lat": 32.83435,
    "lng": -97.46485,
    "state_id": "TX"
  },
  {
    "zip": 76137,
    "lat": 32.8588,
    "lng": -97.29129,
    "state_id": "TX"
  },
  {
    "zip": 76140,
    "lat": 32.6206,
    "lng": -97.2701,
    "state_id": "TX"
  },
  {
    "zip": 76148,
    "lat": 32.86811,
    "lng": -97.25197,
    "state_id": "TX"
  },
  {
    "zip": 76155,
    "lat": 32.82398,
    "lng": -97.04866,
    "state_id": "TX"
  },
  {
    "zip": 76164,
    "lat": 32.78114,
    "lng": -97.35461,
    "state_id": "TX"
  },
  {
    "zip": 76177,
    "lat": 32.97522,
    "lng": -97.3112,
    "state_id": "TX"
  },
  {
    "zip": 76179,
    "lat": 32.9144,
    "lng": -97.43777,
    "state_id": "TX"
  },
  {
    "zip": 76180,
    "lat": 32.83996,
    "lng": -97.22499,
    "state_id": "TX"
  },
  {
    "zip": 76182,
    "lat": 32.88278,
    "lng": -97.20984,
    "state_id": "TX"
  },
  {
    "zip": 76201,
    "lat": 33.22112,
    "lng": -97.14723,
    "state_id": "TX"
  },
  {
    "zip": 76205,
    "lat": 33.19028,
    "lng": -97.12812,
    "state_id": "TX"
  },
  {
    "zip": 76207,
    "lat": 33.22856,
    "lng": -97.18133,
    "state_id": "TX"
  },
  {
    "zip": 76208,
    "lat": 33.20884,
    "lng": -97.05737,
    "state_id": "TX"
  },
  {
    "zip": 76209,
    "lat": 33.2316,
    "lng": -97.1099,
    "state_id": "TX"
  },
  {
    "zip": 76210,
    "lat": 33.15036,
    "lng": -97.096,
    "state_id": "TX"
  },
  {
    "zip": 76225,
    "lat": 33.36444,
    "lng": -97.67314,
    "state_id": "TX"
  },
  {
    "zip": 76226,
    "lat": 33.11035,
    "lng": -97.16946,
    "state_id": "TX"
  },
  {
    "zip": 76227,
    "lat": 33.27393,
    "lng": -96.99051,
    "state_id": "TX"
  },
  {
    "zip": 76228,
    "lat": 33.62099,
    "lng": -98.06028,
    "state_id": "TX"
  },
  {
    "zip": 76230,
    "lat": 33.51993,
    "lng": -97.95469,
    "state_id": "TX"
  },
  {
    "zip": 76233,
    "lat": 33.53625,
    "lng": -96.90829,
    "state_id": "TX"
  },
  {
    "zip": 76234,
    "lat": 33.272,
    "lng": -97.51417,
    "state_id": "TX"
  },
  {
    "zip": 76238,
    "lat": 33.48818,
    "lng": -97.35069,
    "state_id": "TX"
  },
  {
    "zip": 76239,
    "lat": 33.51985,
    "lng": -97.54515,
    "state_id": "TX"
  },
  {
    "zip": 76240,
    "lat": 33.65628,
    "lng": -97.17454,
    "state_id": "TX"
  },
  {
    "zip": 76244,
    "lat": 32.93098,
    "lng": -97.28435,
    "state_id": "TX"
  },
  {
    "zip": 76245,
    "lat": 33.828,
    "lng": -96.84915,
    "state_id": "TX"
  },
  {
    "zip": 76247,
    "lat": 33.09841,
    "lng": -97.32495,
    "state_id": "TX"
  },
  {
    "zip": 76248,
    "lat": 32.92575,
    "lng": -97.22899,
    "state_id": "TX"
  },
  {
    "zip": 76249,
    "lat": 33.28282,
    "lng": -97.30413,
    "state_id": "TX"
  },
  {
    "zip": 76250,
    "lat": 33.63013,
    "lng": -97.23578,
    "state_id": "TX"
  },
  {
    "zip": 76251,
    "lat": 33.62076,
    "lng": -97.68976,
    "state_id": "TX"
  },
  {
    "zip": 76252,
    "lat": 33.68568,
    "lng": -97.38545,
    "state_id": "TX"
  },
  {
    "zip": 76253,
    "lat": 33.61587,
    "lng": -97.31078,
    "state_id": "TX"
  },
  {
    "zip": 76255,
    "lat": 33.82,
    "lng": -97.72915,
    "state_id": "TX"
  },
  {
    "zip": 76258,
    "lat": 33.37191,
    "lng": -96.91964,
    "state_id": "TX"
  },
  {
    "zip": 76259,
    "lat": 33.20016,
    "lng": -97.30705,
    "state_id": "TX"
  },
  {
    "zip": 76261,
    "lat": 33.80066,
    "lng": -97.96327,
    "state_id": "TX"
  },
  {
    "zip": 76262,
    "lat": 33.00948,
    "lng": -97.22581,
    "state_id": "TX"
  },
  {
    "zip": 76263,
    "lat": 33.46748,
    "lng": -97.45338,
    "state_id": "TX"
  },
  {
    "zip": 76264,
    "lat": 33.73974,
    "lng": -96.83528,
    "state_id": "TX"
  },
  {
    "zip": 76265,
    "lat": 33.74828,
    "lng": -97.54502,
    "state_id": "TX"
  },
  {
    "zip": 76266,
    "lat": 33.36725,
    "lng": -97.21414,
    "state_id": "TX"
  },
  {
    "zip": 76268,
    "lat": 33.62759,
    "lng": -96.76741,
    "state_id": "TX"
  },
  {
    "zip": 76270,
    "lat": 33.45613,
    "lng": -97.75202,
    "state_id": "TX"
  },
  {
    "zip": 76271,
    "lat": 33.46699,
    "lng": -96.90774,
    "state_id": "TX"
  },
  {
    "zip": 76272,
    "lat": 33.46625,
    "lng": -97.12889,
    "state_id": "TX"
  },
  {
    "zip": 76273,
    "lat": 33.74557,
    "lng": -96.93355,
    "state_id": "TX"
  },
  {
    "zip": 76301,
    "lat": 33.90559,
    "lng": -98.47978,
    "state_id": "TX"
  },
  {
    "zip": 76302,
    "lat": 33.86456,
    "lng": -98.49109,
    "state_id": "TX"
  },
  {
    "zip": 76305,
    "lat": 33.99942,
    "lng": -98.3939,
    "state_id": "TX"
  },
  {
    "zip": 76306,
    "lat": 33.94504,
    "lng": -98.52395,
    "state_id": "TX"
  },
  {
    "zip": 76308,
    "lat": 33.85238,
    "lng": -98.54057,
    "state_id": "TX"
  },
  {
    "zip": 76309,
    "lat": 33.89524,
    "lng": -98.54432,
    "state_id": "TX"
  },
  {
    "zip": 76310,
    "lat": 33.79856,
    "lng": -98.5109,
    "state_id": "TX"
  },
  {
    "zip": 76311,
    "lat": 33.96889,
    "lng": -98.50882,
    "state_id": "TX"
  },
  {
    "zip": 76351,
    "lat": 33.56892,
    "lng": -98.67455,
    "state_id": "TX"
  },
  {
    "zip": 76354,
    "lat": 34.09738,
    "lng": -98.62144,
    "state_id": "TX"
  },
  {
    "zip": 76357,
    "lat": 34.08174,
    "lng": -98.18468,
    "state_id": "TX"
  },
  {
    "zip": 76360,
    "lat": 33.96894,
    "lng": -98.96631,
    "state_id": "TX"
  },
  {
    "zip": 76363,
    "lat": 33.48306,
    "lng": -99.54199,
    "state_id": "TX"
  },
  {
    "zip": 76364,
    "lat": 34.11368,
    "lng": -99.01676,
    "state_id": "TX"
  },
  {
    "zip": 76365,
    "lat": 33.75814,
    "lng": -98.20162,
    "state_id": "TX"
  },
  {
    "zip": 76366,
    "lat": 33.727,
    "lng": -98.78209,
    "state_id": "TX"
  },
  {
    "zip": 76367,
    "lat": 33.97433,
    "lng": -98.72275,
    "state_id": "TX"
  },
  {
    "zip": 76370,
    "lat": 33.53167,
    "lng": -98.88816,
    "state_id": "TX"
  },
  {
    "zip": 76371,
    "lat": 33.48661,
    "lng": -99.64034,
    "state_id": "TX"
  },
  {
    "zip": 76372,
    "lat": 33.25292,
    "lng": -98.91903,
    "state_id": "TX"
  },
  {
    "zip": 76373,
    "lat": 34.13069,
    "lng": -99.11397,
    "state_id": "TX"
  },
  {
    "zip": 76374,
    "lat": 33.35976,
    "lng": -98.73819,
    "state_id": "TX"
  },
  {
    "zip": 76377,
    "lat": 34.01483,
    "lng": -98.23963,
    "state_id": "TX"
  },
  {
    "zip": 76379,
    "lat": 33.67016,
    "lng": -98.47541,
    "state_id": "TX"
  },
  {
    "zip": 76380,
    "lat": 33.61862,
    "lng": -99.24239,
    "state_id": "TX"
  },
  {
    "zip": 76384,
    "lat": 34.16773,
    "lng": -99.32555,
    "state_id": "TX"
  },
  {
    "zip": 76388,
    "lat": 33.32169,
    "lng": -99.63091,
    "state_id": "TX"
  },
  {
    "zip": 76389,
    "lat": 33.51578,
    "lng": -98.4582,
    "state_id": "TX"
  },
  {
    "zip": 76401,
    "lat": 32.28258,
    "lng": -98.19881,
    "state_id": "TX"
  },
  {
    "zip": 76402,
    "lat": 32.21637,
    "lng": -98.21726,
    "state_id": "TX"
  },
  {
    "zip": 76424,
    "lat": 32.80509,
    "lng": -98.89862,
    "state_id": "TX"
  },
  {
    "zip": 76426,
    "lat": 33.16683,
    "lng": -97.84268,
    "state_id": "TX"
  },
  {
    "zip": 76427,
    "lat": 33.15546,
    "lng": -98.37755,
    "state_id": "TX"
  },
  {
    "zip": 76429,
    "lat": 32.741,
    "lng": -98.6742,
    "state_id": "TX"
  },
  {
    "zip": 76430,
    "lat": 32.785,
    "lng": -99.2474,
    "state_id": "TX"
  },
  {
    "zip": 76431,
    "lat": 33.31747,
    "lng": -97.85662,
    "state_id": "TX"
  },
  {
    "zip": 76432,
    "lat": 31.84215,
    "lng": -98.79696,
    "state_id": "TX"
  },
  {
    "zip": 76433,
    "lat": 32.31517,
    "lng": -98.01968,
    "state_id": "TX"
  },
  {
    "zip": 76435,
    "lat": 32.25177,
    "lng": -98.85661,
    "state_id": "TX"
  },
  {
    "zip": 76436,
    "lat": 31.86715,
    "lng": -98.22143,
    "state_id": "TX"
  },
  {
    "zip": 76437,
    "lat": 32.39591,
    "lng": -99.02367,
    "state_id": "TX"
  },
  {
    "zip": 76442,
    "lat": 31.89183,
    "lng": -98.6149,
    "state_id": "TX"
  },
  {
    "zip": 76443,
    "lat": 32.13341,
    "lng": -99.21059,
    "state_id": "TX"
  },
  {
    "zip": 76444,
    "lat": 32.11472,
    "lng": -98.56573,
    "state_id": "TX"
  },
  {
    "zip": 76445,
    "lat": 32.29949,
    "lng": -98.55077,
    "state_id": "TX"
  },
  {
    "zip": 76446,
    "lat": 32.0752,
    "lng": -98.34943,
    "state_id": "TX"
  },
  {
    "zip": 76448,
    "lat": 32.40015,
    "lng": -98.80082,
    "state_id": "TX"
  },
  {
    "zip": 76449,
    "lat": 32.91565,
    "lng": -98.32279,
    "state_id": "TX"
  },
  {
    "zip": 76450,
    "lat": 33.06259,
    "lng": -98.62573,
    "state_id": "TX"
  },
  {
    "zip": 76452,
    "lat": 31.75506,
    "lng": -98.3541,
    "state_id": "TX"
  },
  {
    "zip": 76453,
    "lat": 32.5856,
    "lng": -98.34162,
    "state_id": "TX"
  },
  {
    "zip": 76454,
    "lat": 32.2081,
    "lng": -98.72898,
    "state_id": "TX"
  },
  {
    "zip": 76455,
    "lat": 31.80574,
    "lng": -98.38354,
    "state_id": "TX"
  },
  {
    "zip": 76457,
    "lat": 31.95198,
    "lng": -98.02939,
    "state_id": "TX"
  },
  {
    "zip": 76458,
    "lat": 33.21797,
    "lng": -98.18771,
    "state_id": "TX"
  },
  {
    "zip": 76459,
    "lat": 33.27406,
    "lng": -98.38756,
    "state_id": "TX"
  },
  {
    "zip": 76460,
    "lat": 33.30965,
    "lng": -98.49838,
    "state_id": "TX"
  },
  {
    "zip": 76462,
    "lat": 32.52348,
    "lng": -98.0275,
    "state_id": "TX"
  },
  {
    "zip": 76463,
    "lat": 32.45664,
    "lng": -98.41487,
    "state_id": "TX"
  },
  {
    "zip": 76464,
    "lat": 32.5559,
    "lng": -99.16497,
    "state_id": "TX"
  },
  {
    "zip": 76466,
    "lat": 32.43334,
    "lng": -98.73744,
    "state_id": "TX"
  },
  {
    "zip": 76469,
    "lat": 32.34528,
    "lng": -99.19387,
    "state_id": "TX"
  },
  {
    "zip": 76470,
    "lat": 32.50228,
    "lng": -98.66152,
    "state_id": "TX"
  },
  {
    "zip": 76471,
    "lat": 32.11605,
    "lng": -98.98478,
    "state_id": "TX"
  },
  {
    "zip": 76472,
    "lat": 32.60119,
    "lng": -98.17057,
    "state_id": "TX"
  },
  {
    "zip": 76474,
    "lat": 31.97301,
    "lng": -98.79293,
    "state_id": "TX"
  },
  {
    "zip": 76475,
    "lat": 32.65903,
    "lng": -98.49888,
    "state_id": "TX"
  },
  {
    "zip": 76476,
    "lat": 32.36651,
    "lng": -97.91946,
    "state_id": "TX"
  },
  {
    "zip": 76481,
    "lat": 32.9956,
    "lng": -98.74309,
    "state_id": "TX"
  },
  {
    "zip": 76483,
    "lat": 33.18878,
    "lng": -99.27224,
    "state_id": "TX"
  },
  {
    "zip": 76484,
    "lat": 32.75833,
    "lng": -98.28241,
    "state_id": "TX"
  },
  {
    "zip": 76486,
    "lat": 33.02291,
    "lng": -98.0622,
    "state_id": "TX"
  },
  {
    "zip": 76487,
    "lat": 32.97949,
    "lng": -97.88221,
    "state_id": "TX"
  },
  {
    "zip": 76490,
    "lat": 32.94544,
    "lng": -98.01889,
    "state_id": "TX"
  },
  {
    "zip": 76491,
    "lat": 33.03487,
    "lng": -99.05052,
    "state_id": "TX"
  },
  {
    "zip": 76501,
    "lat": 31.07505,
    "lng": -97.25435,
    "state_id": "TX"
  },
  {
    "zip": 76502,
    "lat": 31.09624,
    "lng": -97.41039,
    "state_id": "TX"
  },
  {
    "zip": 76504,
    "lat": 31.13805,
    "lng": -97.3726,
    "state_id": "TX"
  },
  {
    "zip": 76508,
    "lat": 31.07758,
    "lng": -97.36425,
    "state_id": "TX"
  },
  {
    "zip": 76511,
    "lat": 30.80163,
    "lng": -97.42498,
    "state_id": "TX"
  },
  {
    "zip": 76513,
    "lat": 31.05135,
    "lng": -97.49967,
    "state_id": "TX"
  },
  {
    "zip": 76518,
    "lat": 30.84974,
    "lng": -97.15418,
    "state_id": "TX"
  },
  {
    "zip": 76519,
    "lat": 30.99427,
    "lng": -97.04726,
    "state_id": "TX"
  },
  {
    "zip": 76520,
    "lat": 30.87611,
    "lng": -96.90748,
    "state_id": "TX"
  },
  {
    "zip": 76522,
    "lat": 31.21604,
    "lng": -97.97338,
    "state_id": "TX"
  },
  {
    "zip": 76523,
    "lat": 30.78956,
    "lng": -97.29127,
    "state_id": "TX"
  },
  {
    "zip": 76524,
    "lat": 31.26693,
    "lng": -97.20313,
    "state_id": "TX"
  },
  {
    "zip": 76525,
    "lat": 31.48558,
    "lng": -98.14597,
    "state_id": "TX"
  },
  {
    "zip": 76527,
    "lat": 30.84416,
    "lng": -97.80601,
    "state_id": "TX"
  },
  {
    "zip": 76528,
    "lat": 31.42046,
    "lng": -97.76152,
    "state_id": "TX"
  },
  {
    "zip": 76530,
    "lat": 30.71583,
    "lng": -97.42174,
    "state_id": "TX"
  },
  {
    "zip": 76531,
    "lat": 31.6858,
    "lng": -98.18812,
    "state_id": "TX"
  },
  {
    "zip": 76534,
    "lat": 30.88263,
    "lng": -97.37359,
    "state_id": "TX"
  },
  {
    "zip": 76537,
    "lat": 30.81749,
    "lng": -97.60543,
    "state_id": "TX"
  },
  {
    "zip": 76538,
    "lat": 31.64149,
    "lng": -97.89017,
    "state_id": "TX"
  },
  {
    "zip": 76539,
    "lat": 31.08867,
    "lng": -98.00991,
    "state_id": "TX"
  },
  {
    "zip": 76541,
    "lat": 31.11414,
    "lng": -97.72885,
    "state_id": "TX"
  },
  {
    "zip": 76542,
    "lat": 31.01061,
    "lng": -97.74608,
    "state_id": "TX"
  },
  {
    "zip": 76543,
    "lat": 31.13897,
    "lng": -97.64323,
    "state_id": "TX"
  },
  {
    "zip": 76544,
    "lat": 31.14312,
    "lng": -97.76165,
    "state_id": "TX"
  },
  {
    "zip": 76548,
    "lat": 31.05081,
    "lng": -97.64587,
    "state_id": "TX"
  },
  {
    "zip": 76549,
    "lat": 31.01316,
    "lng": -97.82996,
    "state_id": "TX"
  },
  {
    "zip": 76550,
    "lat": 31.08755,
    "lng": -98.22873,
    "state_id": "TX"
  },
  {
    "zip": 76554,
    "lat": 30.97574,
    "lng": -97.36779,
    "state_id": "TX"
  },
  {
    "zip": 76556,
    "lat": 30.70295,
    "lng": -96.82589,
    "state_id": "TX"
  },
  {
    "zip": 76557,
    "lat": 31.29554,
    "lng": -97.39495,
    "state_id": "TX"
  },
  {
    "zip": 76559,
    "lat": 31.07639,
    "lng": -97.6049,
    "state_id": "TX"
  },
  {
    "zip": 76561,
    "lat": 31.41292,
    "lng": -97.53715,
    "state_id": "TX"
  },
  {
    "zip": 76565,
    "lat": 31.6437,
    "lng": -98.35105,
    "state_id": "TX"
  },
  {
    "zip": 76566,
    "lat": 31.51052,
    "lng": -97.99614,
    "state_id": "TX"
  },
  {
    "zip": 76567,
    "lat": 30.65,
    "lng": -97.00757,
    "state_id": "TX"
  },
  {
    "zip": 76569,
    "lat": 30.94373,
    "lng": -97.22878,
    "state_id": "TX"
  },
  {
    "zip": 76570,
    "lat": 31.08166,
    "lng": -96.93867,
    "state_id": "TX"
  },
  {
    "zip": 76571,
    "lat": 30.9338,
    "lng": -97.58908,
    "state_id": "TX"
  },
  {
    "zip": 76573,
    "lat": 30.81862,
    "lng": -97.50231,
    "state_id": "TX"
  },
  {
    "zip": 76574,
    "lat": 30.58423,
    "lng": -97.38741,
    "state_id": "TX"
  },
  {
    "zip": 76577,
    "lat": 30.62971,
    "lng": -97.16969,
    "state_id": "TX"
  },
  {
    "zip": 76578,
    "lat": 30.53615,
    "lng": -97.23789,
    "state_id": "TX"
  },
  {
    "zip": 76579,
    "lat": 31.19707,
    "lng": -97.26374,
    "state_id": "TX"
  },
  {
    "zip": 76596,
    "lat": 31.47755,
    "lng": -97.7296,
    "state_id": "TX"
  },
  {
    "zip": 76597,
    "lat": 31.48635,
    "lng": -97.70694,
    "state_id": "TX"
  },
  {
    "zip": 76598,
    "lat": 31.47532,
    "lng": -97.73549,
    "state_id": "TX"
  },
  {
    "zip": 76599,
    "lat": 31.4679,
    "lng": -97.73518,
    "state_id": "TX"
  },
  {
    "zip": 76621,
    "lat": 31.883,
    "lng": -97.08594,
    "state_id": "TX"
  },
  {
    "zip": 76622,
    "lat": 31.82204,
    "lng": -97.24398,
    "state_id": "TX"
  },
  {
    "zip": 76623,
    "lat": 32.21038,
    "lng": -96.75659,
    "state_id": "TX"
  },
  {
    "zip": 76624,
    "lat": 31.6566,
    "lng": -96.9596,
    "state_id": "TX"
  },
  {
    "zip": 76626,
    "lat": 32.10623,
    "lng": -96.71536,
    "state_id": "TX"
  },
  {
    "zip": 76627,
    "lat": 32.10741,
    "lng": -97.39448,
    "state_id": "TX"
  },
  {
    "zip": 76628,
    "lat": 32.03143,
    "lng": -96.95221,
    "state_id": "TX"
  },
  {
    "zip": 76629,
    "lat": 31.15494,
    "lng": -96.6554,
    "state_id": "TX"
  },
  {
    "zip": 76630,
    "lat": 31.3405,
    "lng": -97.22091,
    "state_id": "TX"
  },
  {
    "zip": 76631,
    "lat": 31.97453,
    "lng": -96.96749,
    "state_id": "TX"
  },
  {
    "zip": 76632,
    "lat": 31.29871,
    "lng": -97.06006,
    "state_id": "TX"
  },
  {
    "zip": 76633,
    "lat": 31.6781,
    "lng": -97.31732,
    "state_id": "TX"
  },
  {
    "zip": 76634,
    "lat": 31.80782,
    "lng": -97.56146,
    "state_id": "TX"
  },
  {
    "zip": 76635,
    "lat": 31.7284,
    "lng": -96.66386,
    "state_id": "TX"
  },
  {
    "zip": 76636,
    "lat": 32.17157,
    "lng": -97.28593,
    "state_id": "TX"
  },
  {
    "zip": 76637,
    "lat": 31.76614,
    "lng": -97.81681,
    "state_id": "TX"
  },
  {
    "zip": 76638,
    "lat": 31.5498,
    "lng": -97.44461,
    "state_id": "TX"
  },
  {
    "zip": 76639,
    "lat": 31.87245,
    "lng": -96.65574,
    "state_id": "TX"
  },
  {
    "zip": 76640,
    "lat": 31.68989,
    "lng": -97.07076,
    "state_id": "TX"
  },
  {
    "zip": 76641,
    "lat": 32.0345,
    "lng": -96.79489,
    "state_id": "TX"
  },
  {
    "zip": 76642,
    "lat": 31.50128,
    "lng": -96.51757,
    "state_id": "TX"
  },
  {
    "zip": 76643,
    "lat": 31.45383,
    "lng": -97.19364,
    "state_id": "TX"
  },
  {
    "zip": 76645,
    "lat": 32.02173,
    "lng": -97.1336,
    "state_id": "TX"
  },
  {
    "zip": 76648,
    "lat": 31.8472,
    "lng": -96.80272,
    "state_id": "TX"
  },
  {
    "zip": 76649,
    "lat": 32.00079,
    "lng": -97.87752,
    "state_id": "TX"
  },
  {
    "zip": 76650,
    "lat": 31.98357,
    "lng": -96.8604,
    "state_id": "TX"
  },
  {
    "zip": 76651,
    "lat": 32.1807,
    "lng": -96.86697,
    "state_id": "TX"
  },
  {
    "zip": 76652,
    "lat": 32.11575,
    "lng": -97.57043,
    "state_id": "TX"
  },
  {
    "zip": 76653,
    "lat": 31.29812,
    "lng": -96.60817,
    "state_id": "TX"
  },
  {
    "zip": 76654,
    "lat": 31.73054,
    "lng": -97.01427,
    "state_id": "TX"
  },
  {
    "zip": 76655,
    "lat": 31.39609,
    "lng": -97.17738,
    "state_id": "TX"
  },
  {
    "zip": 76656,
    "lat": 31.18465,
    "lng": -97.0586,
    "state_id": "TX"
  },
  {
    "zip": 76657,
    "lat": 31.4458,
    "lng": -97.39449,
    "state_id": "TX"
  },
  {
    "zip": 76660,
    "lat": 31.9243,
    "lng": -96.90239,
    "state_id": "TX"
  },
  {
    "zip": 76661,
    "lat": 31.31864,
    "lng": -96.84932,
    "state_id": "TX"
  },
  {
    "zip": 76664,
    "lat": 31.55793,
    "lng": -96.79573,
    "state_id": "TX"
  },
  {
    "zip": 76665,
    "lat": 31.91349,
    "lng": -97.71634,
    "state_id": "TX"
  },
  {
    "zip": 76666,
    "lat": 32.04403,
    "lng": -96.91737,
    "state_id": "TX"
  },
  {
    "zip": 76667,
    "lat": 31.65529,
    "lng": -96.46854,
    "state_id": "TX"
  },
  {
    "zip": 76670,
    "lat": 32.13146,
    "lng": -96.98936,
    "state_id": "TX"
  },
  {
    "zip": 76671,
    "lat": 31.99489,
    "lng": -97.53199,
    "state_id": "TX"
  },
  {
    "zip": 76673,
    "lat": 31.74944,
    "lng": -96.89139,
    "state_id": "TX"
  },
  {
    "zip": 76676,
    "lat": 31.86551,
    "lng": -96.95103,
    "state_id": "TX"
  },
  {
    "zip": 76678,
    "lat": 31.65548,
    "lng": -96.75883,
    "state_id": "TX"
  },
  {
    "zip": 76679,
    "lat": 31.92428,
    "lng": -96.58566,
    "state_id": "TX"
  },
  {
    "zip": 76680,
    "lat": 31.19335,
    "lng": -96.81791,
    "state_id": "TX"
  },
  {
    "zip": 76681,
    "lat": 31.8933,
    "lng": -96.43508,
    "state_id": "TX"
  },
  {
    "zip": 76682,
    "lat": 31.45363,
    "lng": -96.9059,
    "state_id": "TX"
  },
  {
    "zip": 76685,
    "lat": 31.35326,
    "lng": -97.01115,
    "state_id": "TX"
  },
  {
    "zip": 76686,
    "lat": 31.76592,
    "lng": -96.558,
    "state_id": "TX"
  },
  {
    "zip": 76687,
    "lat": 31.37171,
    "lng": -96.49934,
    "state_id": "TX"
  },
  {
    "zip": 76689,
    "lat": 31.66663,
    "lng": -97.50415,
    "state_id": "TX"
  },
  {
    "zip": 76690,
    "lat": 32.08226,
    "lng": -97.73325,
    "state_id": "TX"
  },
  {
    "zip": 76691,
    "lat": 31.77818,
    "lng": -97.09625,
    "state_id": "TX"
  },
  {
    "zip": 76692,
    "lat": 31.95806,
    "lng": -97.32888,
    "state_id": "TX"
  },
  {
    "zip": 76693,
    "lat": 31.78599,
    "lng": -96.40641,
    "state_id": "TX"
  },
  {
    "zip": 76701,
    "lat": 31.55202,
    "lng": -97.13858,
    "state_id": "TX"
  },
  {
    "zip": 76704,
    "lat": 31.57676,
    "lng": -97.127,
    "state_id": "TX"
  },
  {
    "zip": 76705,
    "lat": 31.62655,
    "lng": -97.09605,
    "state_id": "TX"
  },
  {
    "zip": 76706,
    "lat": 31.46653,
    "lng": -97.09121,
    "state_id": "TX"
  },
  {
    "zip": 76707,
    "lat": 31.55371,
    "lng": -97.15818,
    "state_id": "TX"
  },
  {
    "zip": 76708,
    "lat": 31.62687,
    "lng": -97.21135,
    "state_id": "TX"
  },
  {
    "zip": 76710,
    "lat": 31.53876,
    "lng": -97.19525,
    "state_id": "TX"
  },
  {
    "zip": 76711,
    "lat": 31.51442,
    "lng": -97.15332,
    "state_id": "TX"
  },
  {
    "zip": 76712,
    "lat": 31.52403,
    "lng": -97.25566,
    "state_id": "TX"
  },
  {
    "zip": 76798,
    "lat": 31.5484,
    "lng": -97.11901,
    "state_id": "TX"
  },
  {
    "zip": 76801,
    "lat": 31.74249,
    "lng": -99.03991,
    "state_id": "TX"
  },
  {
    "zip": 76802,
    "lat": 31.75328,
    "lng": -98.90283,
    "state_id": "TX"
  },
  {
    "zip": 76820,
    "lat": 30.76719,
    "lng": -99.04869,
    "state_id": "TX"
  },
  {
    "zip": 76821,
    "lat": 31.73869,
    "lng": -99.93625,
    "state_id": "TX"
  },
  {
    "zip": 76823,
    "lat": 31.70119,
    "lng": -99.15991,
    "state_id": "TX"
  },
  {
    "zip": 76824,
    "lat": 31.0036,
    "lng": -98.47069,
    "state_id": "TX"
  },
  {
    "zip": 76825,
    "lat": 31.10471,
    "lng": -99.39545,
    "state_id": "TX"
  },
  {
    "zip": 76827,
    "lat": 31.52636,
    "lng": -99.12819,
    "state_id": "TX"
  },
  {
    "zip": 76828,
    "lat": 32.00902,
    "lng": -99.29396,
    "state_id": "TX"
  },
  {
    "zip": 76831,
    "lat": 30.63979,
    "lng": -98.91958,
    "state_id": "TX"
  },
  {
    "zip": 76832,
    "lat": 30.98538,
    "lng": -98.73031,
    "state_id": "TX"
  },
  {
    "zip": 76834,
    "lat": 31.87436,
    "lng": -99.47039,
    "state_id": "TX"
  },
  {
    "zip": 76836,
    "lat": 31.42607,
    "lng": -99.55601,
    "state_id": "TX"
  },
  {
    "zip": 76837,
    "lat": 31.2208,
    "lng": -99.89593,
    "state_id": "TX"
  },
  {
    "zip": 76841,
    "lat": 30.8936,
    "lng": -100.09665,
    "state_id": "TX"
  },
  {
    "zip": 76842,
    "lat": 30.95434,
    "lng": -99.07295,
    "state_id": "TX"
  },
  {
    "zip": 76844,
    "lat": 31.43788,
    "lng": -98.50904,
    "state_id": "TX"
  },
  {
    "zip": 76845,
    "lat": 31.54733,
    "lng": -99.48707,
    "state_id": "TX"
  },
  {
    "zip": 76848,
    "lat": 30.84316,
    "lng": -99.56995,
    "state_id": "TX"
  },
  {
    "zip": 76849,
    "lat": 30.48896,
    "lng": -99.79705,
    "state_id": "TX"
  },
  {
    "zip": 76852,
    "lat": 31.35939,
    "lng": -99.4509,
    "state_id": "TX"
  },
  {
    "zip": 76853,
    "lat": 31.24577,
    "lng": -98.3872,
    "state_id": "TX"
  },
  {
    "zip": 76854,
    "lat": 30.68565,
    "lng": -99.57564,
    "state_id": "TX"
  },
  {
    "zip": 76856,
    "lat": 30.70618,
    "lng": -99.26619,
    "state_id": "TX"
  },
  {
    "zip": 76857,
    "lat": 31.93191,
    "lng": -98.93511,
    "state_id": "TX"
  },
  {
    "zip": 76858,
    "lat": 31.19743,
    "lng": -99.62049,
    "state_id": "TX"
  },
  {
    "zip": 76859,
    "lat": 30.90801,
    "lng": -99.8326,
    "state_id": "TX"
  },
  {
    "zip": 76861,
    "lat": 31.59099,
    "lng": -100.19534,
    "state_id": "TX"
  },
  {
    "zip": 76862,
    "lat": 31.41518,
    "lng": -99.68744,
    "state_id": "TX"
  },
  {
    "zip": 76864,
    "lat": 31.5541,
    "lng": -98.73378,
    "state_id": "TX"
  },
  {
    "zip": 76865,
    "lat": 31.85815,
    "lng": -100.1663,
    "state_id": "TX"
  },
  {
    "zip": 76866,
    "lat": 31.46085,
    "lng": -99.92785,
    "state_id": "TX"
  },
  {
    "zip": 76869,
    "lat": 30.91282,
    "lng": -98.99787,
    "state_id": "TX"
  },
  {
    "zip": 76870,
    "lat": 31.63824,
    "lng": -98.52981,
    "state_id": "TX"
  },
  {
    "zip": 76871,
    "lat": 31.29056,
    "lng": -98.98708,
    "state_id": "TX"
  },
  {
    "zip": 76872,
    "lat": 31.32321,
    "lng": -99.16715,
    "state_id": "TX"
  },
  {
    "zip": 76873,
    "lat": 31.51108,
    "lng": -99.37924,
    "state_id": "TX"
  },
  {
    "zip": 76874,
    "lat": 30.45873,
    "lng": -100.06913,
    "state_id": "TX"
  },
  {
    "zip": 76875,
    "lat": 31.60275,
    "lng": -100.00576,
    "state_id": "TX"
  },
  {
    "zip": 76877,
    "lat": 31.17243,
    "lng": -98.75696,
    "state_id": "TX"
  },
  {
    "zip": 76878,
    "lat": 31.65761,
    "lng": -99.29342,
    "state_id": "TX"
  },
  {
    "zip": 76882,
    "lat": 31.81965,
    "lng": -99.70241,
    "state_id": "TX"
  },
  {
    "zip": 76884,
    "lat": 31.73031,
    "lng": -99.55324,
    "state_id": "TX"
  },
  {
    "zip": 76885,
    "lat": 30.87708,
    "lng": -98.86324,
    "state_id": "TX"
  },
  {
    "zip": 76887,
    "lat": 31.01265,
    "lng": -99.16057,
    "state_id": "TX"
  },
  {
    "zip": 76888,
    "lat": 31.60827,
    "lng": -99.63989,
    "state_id": "TX"
  },
  {
    "zip": 76890,
    "lat": 31.69146,
    "lng": -98.7756,
    "state_id": "TX"
  },
  {
    "zip": 76901,
    "lat": 31.54094,
    "lng": -100.62877,
    "state_id": "TX"
  },
  {
    "zip": 76903,
    "lat": 31.48482,
    "lng": -100.43722,
    "state_id": "TX"
  },
  {
    "zip": 76904,
    "lat": 31.2836,
    "lng": -100.39452,
    "state_id": "TX"
  },
  {
    "zip": 76905,
    "lat": 31.50981,
    "lng": -100.32217,
    "state_id": "TX"
  },
  {
    "zip": 76908,
    "lat": 31.43208,
    "lng": -100.40222,
    "state_id": "TX"
  },
  {
    "zip": 76930,
    "lat": 31.27278,
    "lng": -101.17275,
    "state_id": "TX"
  },
  {
    "zip": 76932,
    "lat": 31.26949,
    "lng": -101.54094,
    "state_id": "TX"
  },
  {
    "zip": 76933,
    "lat": 31.86341,
    "lng": -100.28783,
    "state_id": "TX"
  },
  {
    "zip": 76934,
    "lat": 31.60305,
    "lng": -100.66898,
    "state_id": "TX"
  },
  {
    "zip": 76935,
    "lat": 31.08046,
    "lng": -100.41658,
    "state_id": "TX"
  },
  {
    "zip": 76936,
    "lat": 30.88566,
    "lng": -100.58752,
    "state_id": "TX"
  },
  {
    "zip": 76937,
    "lat": 31.38854,
    "lng": -100.15138,
    "state_id": "TX"
  },
  {
    "zip": 76939,
    "lat": 31.27778,
    "lng": -100.5574,
    "state_id": "TX"
  },
  {
    "zip": 76940,
    "lat": 31.43903,
    "lng": -100.14294,
    "state_id": "TX"
  },
  {
    "zip": 76941,
    "lat": 31.26808,
    "lng": -100.91169,
    "state_id": "TX"
  },
  {
    "zip": 76943,
    "lat": 30.53101,
    "lng": -101.30067,
    "state_id": "TX"
  },
  {
    "zip": 76945,
    "lat": 31.88096,
    "lng": -100.61152,
    "state_id": "TX"
  },
  {
    "zip": 76949,
    "lat": 32.04184,
    "lng": -100.69272,
    "state_id": "TX"
  },
  {
    "zip": 76950,
    "lat": 30.43734,
    "lng": -100.56036,
    "state_id": "TX"
  },
  {
    "zip": 76951,
    "lat": 31.81488,
    "lng": -101.04641,
    "state_id": "TX"
  },
  {
    "zip": 76953,
    "lat": 31.72301,
    "lng": -100.32766,
    "state_id": "TX"
  },
  {
    "zip": 76955,
    "lat": 31.28953,
    "lng": -100.15274,
    "state_id": "TX"
  },
  {
    "zip": 76957,
    "lat": 31.3722,
    "lng": -100.30507,
    "state_id": "TX"
  },
  {
    "zip": 76958,
    "lat": 31.59512,
    "lng": -100.79924,
    "state_id": "TX"
  },
  {
    "zip": 77002,
    "lat": 29.75641,
    "lng": -95.3653,
    "state_id": "TX"
  },
  {
    "zip": 77003,
    "lat": 29.74932,
    "lng": -95.34582,
    "state_id": "TX"
  },
  {
    "zip": 77004,
    "lat": 29.72463,
    "lng": -95.36309,
    "state_id": "TX"
  },
  {
    "zip": 77005,
    "lat": 29.71816,
    "lng": -95.42419,
    "state_id": "TX"
  },
  {
    "zip": 77006,
    "lat": 29.74088,
    "lng": -95.39132,
    "state_id": "TX"
  },
  {
    "zip": 77007,
    "lat": 29.77128,
    "lng": -95.41144,
    "state_id": "TX"
  },
  {
    "zip": 77008,
    "lat": 29.7986,
    "lng": -95.41754,
    "state_id": "TX"
  },
  {
    "zip": 77009,
    "lat": 29.79504,
    "lng": -95.36747,
    "state_id": "TX"
  },
  {
    "zip": 77010,
    "lat": 29.75365,
    "lng": -95.35982,
    "state_id": "TX"
  },
  {
    "zip": 77011,
    "lat": 29.7433,
    "lng": -95.30849,
    "state_id": "TX"
  },
  {
    "zip": 77012,
    "lat": 29.71896,
    "lng": -95.27431,
    "state_id": "TX"
  },
  {
    "zip": 77013,
    "lat": 29.79548,
    "lng": -95.2382,
    "state_id": "TX"
  },
  {
    "zip": 77014,
    "lat": 29.9805,
    "lng": -95.4636,
    "state_id": "TX"
  },
  {
    "zip": 77015,
    "lat": 29.76437,
    "lng": -95.17347,
    "state_id": "TX"
  },
  {
    "zip": 77016,
    "lat": 29.86163,
    "lng": -95.29997,
    "state_id": "TX"
  },
  {
    "zip": 77017,
    "lat": 29.68995,
    "lng": -95.25224,
    "state_id": "TX"
  },
  {
    "zip": 77018,
    "lat": 29.82694,
    "lng": -95.42589,
    "state_id": "TX"
  },
  {
    "zip": 77019,
    "lat": 29.7532,
    "lng": -95.41008,
    "state_id": "TX"
  },
  {
    "zip": 77020,
    "lat": 29.77305,
    "lng": -95.31379,
    "state_id": "TX"
  },
  {
    "zip": 77021,
    "lat": 29.69713,
    "lng": -95.35743,
    "state_id": "TX"
  },
  {
    "zip": 77022,
    "lat": 29.83057,
    "lng": -95.37697,
    "state_id": "TX"
  },
  {
    "zip": 77023,
    "lat": 29.72163,
    "lng": -95.31846,
    "state_id": "TX"
  },
  {
    "zip": 77024,
    "lat": 29.77084,
    "lng": -95.51161,
    "state_id": "TX"
  },
  {
    "zip": 77025,
    "lat": 29.68661,
    "lng": -95.43499,
    "state_id": "TX"
  },
  {
    "zip": 77026,
    "lat": 29.8001,
    "lng": -95.32947,
    "state_id": "TX"
  },
  {
    "zip": 77027,
    "lat": 29.74023,
    "lng": -95.44589,
    "state_id": "TX"
  },
  {
    "zip": 77028,
    "lat": 29.82466,
    "lng": -95.28678,
    "state_id": "TX"
  },
  {
    "zip": 77029,
    "lat": 29.76278,
    "lng": -95.26243,
    "state_id": "TX"
  },
  {
    "zip": 77030,
    "lat": 29.70628,
    "lng": -95.40258,
    "state_id": "TX"
  },
  {
    "zip": 77031,
    "lat": 29.65448,
    "lng": -95.54639,
    "state_id": "TX"
  },
  {
    "zip": 77032,
    "lat": 29.96497,
    "lng": -95.34163,
    "state_id": "TX"
  },
  {
    "zip": 77033,
    "lat": 29.66724,
    "lng": -95.33703,
    "state_id": "TX"
  },
  {
    "zip": 77034,
    "lat": 29.61755,
    "lng": -95.19245,
    "state_id": "TX"
  },
  {
    "zip": 77035,
    "lat": 29.65246,
    "lng": -95.47699,
    "state_id": "TX"
  },
  {
    "zip": 77036,
    "lat": 29.70165,
    "lng": -95.53595,
    "state_id": "TX"
  },
  {
    "zip": 77037,
    "lat": 29.89114,
    "lng": -95.39441,
    "state_id": "TX"
  },
  {
    "zip": 77038,
    "lat": 29.9186,
    "lng": -95.44169,
    "state_id": "TX"
  },
  {
    "zip": 77039,
    "lat": 29.90926,
    "lng": -95.34118,
    "state_id": "TX"
  },
  {
    "zip": 77040,
    "lat": 29.8744,
    "lng": -95.52787,
    "state_id": "TX"
  },
  {
    "zip": 77041,
    "lat": 29.86048,
    "lng": -95.58085,
    "state_id": "TX"
  },
  {
    "zip": 77042,
    "lat": 29.74115,
    "lng": -95.5606,
    "state_id": "TX"
  },
  {
    "zip": 77043,
    "lat": 29.81187,
    "lng": -95.58126,
    "state_id": "TX"
  },
  {
    "zip": 77044,
    "lat": 29.8979,
    "lng": -95.17696,
    "state_id": "TX"
  },
  {
    "zip": 77045,
    "lat": 29.63572,
    "lng": -95.43304,
    "state_id": "TX"
  },
  {
    "zip": 77046,
    "lat": 29.73376,
    "lng": -95.4334,
    "state_id": "TX"
  },
  {
    "zip": 77047,
    "lat": 29.60844,
    "lng": -95.38665,
    "state_id": "TX"
  },
  {
    "zip": 77048,
    "lat": 29.62042,
    "lng": -95.3303,
    "state_id": "TX"
  },
  {
    "zip": 77049,
    "lat": 29.83413,
    "lng": -95.14734,
    "state_id": "TX"
  },
  {
    "zip": 77050,
    "lat": 29.90307,
    "lng": -95.27004,
    "state_id": "TX"
  },
  {
    "zip": 77051,
    "lat": 29.6562,
    "lng": -95.38015,
    "state_id": "TX"
  },
  {
    "zip": 77053,
    "lat": 29.58305,
    "lng": -95.46032,
    "state_id": "TX"
  },
  {
    "zip": 77054,
    "lat": 29.68088,
    "lng": -95.40494,
    "state_id": "TX"
  },
  {
    "zip": 77055,
    "lat": 29.79765,
    "lng": -95.49173,
    "state_id": "TX"
  },
  {
    "zip": 77056,
    "lat": 29.74832,
    "lng": -95.46803,
    "state_id": "TX"
  },
  {
    "zip": 77057,
    "lat": 29.74546,
    "lng": -95.48868,
    "state_id": "TX"
  },
  {
    "zip": 77058,
    "lat": 29.56284,
    "lng": -95.09287,
    "state_id": "TX"
  },
  {
    "zip": 77059,
    "lat": 29.60764,
    "lng": -95.12483,
    "state_id": "TX"
  },
  {
    "zip": 77060,
    "lat": 29.93513,
    "lng": -95.3969,
    "state_id": "TX"
  },
  {
    "zip": 77061,
    "lat": 29.65377,
    "lng": -95.28423,
    "state_id": "TX"
  },
  {
    "zip": 77062,
    "lat": 29.57485,
    "lng": -95.13148,
    "state_id": "TX"
  },
  {
    "zip": 77063,
    "lat": 29.73554,
    "lng": -95.5216,
    "state_id": "TX"
  },
  {
    "zip": 77064,
    "lat": 29.92218,
    "lng": -95.54706,
    "state_id": "TX"
  },
  {
    "zip": 77065,
    "lat": 29.92696,
    "lng": -95.60376,
    "state_id": "TX"
  },
  {
    "zip": 77066,
    "lat": 29.95659,
    "lng": -95.50204,
    "state_id": "TX"
  },
  {
    "zip": 77067,
    "lat": 29.95272,
    "lng": -95.44687,
    "state_id": "TX"
  },
  {
    "zip": 77068,
    "lat": 30.00596,
    "lng": -95.48692,
    "state_id": "TX"
  },
  {
    "zip": 77069,
    "lat": 29.98568,
    "lng": -95.52439,
    "state_id": "TX"
  },
  {
    "zip": 77070,
    "lat": 29.97775,
    "lng": -95.57315,
    "state_id": "TX"
  },
  {
    "zip": 77071,
    "lat": 29.65295,
    "lng": -95.52025,
    "state_id": "TX"
  },
  {
    "zip": 77072,
    "lat": 29.69965,
    "lng": -95.585,
    "state_id": "TX"
  },
  {
    "zip": 77073,
    "lat": 29.99986,
    "lng": -95.39958,
    "state_id": "TX"
  },
  {
    "zip": 77074,
    "lat": 29.68803,
    "lng": -95.51626,
    "state_id": "TX"
  },
  {
    "zip": 77075,
    "lat": 29.6209,
    "lng": -95.26714,
    "state_id": "TX"
  },
  {
    "zip": 77076,
    "lat": 29.85962,
    "lng": -95.38351,
    "state_id": "TX"
  },
  {
    "zip": 77077,
    "lat": 29.75009,
    "lng": -95.61546,
    "state_id": "TX"
  },
  {
    "zip": 77078,
    "lat": 29.853,
    "lng": -95.2516,
    "state_id": "TX"
  },
  {
    "zip": 77079,
    "lat": 29.77627,
    "lng": -95.60462,
    "state_id": "TX"
  },
  {
    "zip": 77080,
    "lat": 29.8159,
    "lng": -95.52401,
    "state_id": "TX"
  },
  {
    "zip": 77081,
    "lat": 29.71203,
    "lng": -95.48118,
    "state_id": "TX"
  },
  {
    "zip": 77082,
    "lat": 29.72358,
    "lng": -95.64179,
    "state_id": "TX"
  },
  {
    "zip": 77083,
    "lat": 29.69327,
    "lng": -95.6489,
    "state_id": "TX"
  },
  {
    "zip": 77084,
    "lat": 29.82642,
    "lng": -95.66213,
    "state_id": "TX"
  },
  {
    "zip": 77085,
    "lat": 29.62431,
    "lng": -95.48427,
    "state_id": "TX"
  },
  {
    "zip": 77086,
    "lat": 29.91819,
    "lng": -95.49109,
    "state_id": "TX"
  },
  {
    "zip": 77087,
    "lat": 29.68613,
    "lng": -95.30343,
    "state_id": "TX"
  },
  {
    "zip": 77088,
    "lat": 29.88181,
    "lng": -95.45352,
    "state_id": "TX"
  },
  {
    "zip": 77089,
    "lat": 29.58673,
    "lng": -95.22523,
    "state_id": "TX"
  },
  {
    "zip": 77090,
    "lat": 30.00866,
    "lng": -95.44393,
    "state_id": "TX"
  },
  {
    "zip": 77091,
    "lat": 29.85378,
    "lng": -95.44065,
    "state_id": "TX"
  },
  {
    "zip": 77092,
    "lat": 29.82968,
    "lng": -95.47383,
    "state_id": "TX"
  },
  {
    "zip": 77093,
    "lat": 29.86227,
    "lng": -95.341,
    "state_id": "TX"
  },
  {
    "zip": 77094,
    "lat": 29.7621,
    "lng": -95.67824,
    "state_id": "TX"
  },
  {
    "zip": 77095,
    "lat": 29.9082,
    "lng": -95.65322,
    "state_id": "TX"
  },
  {
    "zip": 77096,
    "lat": 29.67475,
    "lng": -95.47951,
    "state_id": "TX"
  },
  {
    "zip": 77098,
    "lat": 29.73487,
    "lng": -95.41529,
    "state_id": "TX"
  },
  {
    "zip": 77099,
    "lat": 29.67079,
    "lng": -95.58519,
    "state_id": "TX"
  },
  {
    "zip": 77201,
    "lat": 29.76601,
    "lng": -95.36456,
    "state_id": "TX"
  },
  {
    "zip": 77301,
    "lat": 30.30793,
    "lng": -95.43427,
    "state_id": "TX"
  },
  {
    "zip": 77302,
    "lat": 30.22236,
    "lng": -95.359,
    "state_id": "TX"
  },
  {
    "zip": 77303,
    "lat": 30.37665,
    "lng": -95.38136,
    "state_id": "TX"
  },
  {
    "zip": 77304,
    "lat": 30.32956,
    "lng": -95.5141,
    "state_id": "TX"
  },
  {
    "zip": 77306,
    "lat": 30.27974,
    "lng": -95.31255,
    "state_id": "TX"
  },
  {
    "zip": 77316,
    "lat": 30.31022,
    "lng": -95.67997,
    "state_id": "TX"
  },
  {
    "zip": 77318,
    "lat": 30.44571,
    "lng": -95.54319,
    "state_id": "TX"
  },
  {
    "zip": 77320,
    "lat": 30.80399,
    "lng": -95.55092,
    "state_id": "TX"
  },
  {
    "zip": 77326,
    "lat": 30.50488,
    "lng": -94.81705,
    "state_id": "TX"
  },
  {
    "zip": 77327,
    "lat": 30.32155,
    "lng": -94.9263,
    "state_id": "TX"
  },
  {
    "zip": 77328,
    "lat": 30.39628,
    "lng": -95.19391,
    "state_id": "TX"
  },
  {
    "zip": 77331,
    "lat": 30.61649,
    "lng": -95.16596,
    "state_id": "TX"
  },
  {
    "zip": 77334,
    "lat": 30.77998,
    "lng": -95.36464,
    "state_id": "TX"
  },
  {
    "zip": 77335,
    "lat": 30.58762,
    "lng": -94.92472,
    "state_id": "TX"
  },
  {
    "zip": 77336,
    "lat": 30.06429,
    "lng": -95.09884,
    "state_id": "TX"
  },
  {
    "zip": 77338,
    "lat": 30.00678,
    "lng": -95.29019,
    "state_id": "TX"
  },
  {
    "zip": 77339,
    "lat": 30.04931,
    "lng": -95.22132,
    "state_id": "TX"
  },
  {
    "zip": 77340,
    "lat": 30.64369,
    "lng": -95.53981,
    "state_id": "TX"
  },
  {
    "zip": 77342,
    "lat": 30.74017,
    "lng": -95.55393,
    "state_id": "TX"
  },
  {
    "zip": 77345,
    "lat": 30.05416,
    "lng": -95.15899,
    "state_id": "TX"
  },
  {
    "zip": 77346,
    "lat": 29.99497,
    "lng": -95.17718,
    "state_id": "TX"
  },
  {
    "zip": 77350,
    "lat": 30.82867,
    "lng": -94.84797,
    "state_id": "TX"
  },
  {
    "zip": 77351,
    "lat": 30.7127,
    "lng": -94.82192,
    "state_id": "TX"
  },
  {
    "zip": 77354,
    "lat": 30.21107,
    "lng": -95.64787,
    "state_id": "TX"
  },
  {
    "zip": 77355,
    "lat": 30.15769,
    "lng": -95.74805,
    "state_id": "TX"
  },
  {
    "zip": 77356,
    "lat": 30.45055,
    "lng": -95.70183,
    "state_id": "TX"
  },
  {
    "zip": 77357,
    "lat": 30.15846,
    "lng": -95.19112,
    "state_id": "TX"
  },
  {
    "zip": 77358,
    "lat": 30.55776,
    "lng": -95.43502,
    "state_id": "TX"
  },
  {
    "zip": 77359,
    "lat": 30.75385,
    "lng": -95.29988,
    "state_id": "TX"
  },
  {
    "zip": 77360,
    "lat": 30.84704,
    "lng": -95.11402,
    "state_id": "TX"
  },
  {
    "zip": 77362,
    "lat": 30.15825,
    "lng": -95.66832,
    "state_id": "TX"
  },
  {
    "zip": 77363,
    "lat": 30.32935,
    "lng": -95.85388,
    "state_id": "TX"
  },
  {
    "zip": 77364,
    "lat": 30.76591,
    "lng": -95.22091,
    "state_id": "TX"
  },
  {
    "zip": 77365,
    "lat": 30.1111,
    "lng": -95.2675,
    "state_id": "TX"
  },
  {
    "zip": 77367,
    "lat": 30.85587,
    "lng": -95.39831,
    "state_id": "TX"
  },
  {
    "zip": 77368,
    "lat": 30.45857,
    "lng": -94.82625,
    "state_id": "TX"
  },
  {
    "zip": 77369,
    "lat": 30.42364,
    "lng": -94.7452,
    "state_id": "TX"
  },
  {
    "zip": 77371,
    "lat": 30.49363,
    "lng": -94.99091,
    "state_id": "TX"
  },
  {
    "zip": 77372,
    "lat": 30.2408,
    "lng": -95.16108,
    "state_id": "TX"
  },
  {
    "zip": 77373,
    "lat": 30.06145,
    "lng": -95.38415,
    "state_id": "TX"
  },
  {
    "zip": 77374,
    "lat": 30.39595,
    "lng": -94.62253,
    "state_id": "TX"
  },
  {
    "zip": 77375,
    "lat": 30.09544,
    "lng": -95.5894,
    "state_id": "TX"
  },
  {
    "zip": 77376,
    "lat": 30.44803,
    "lng": -94.67384,
    "state_id": "TX"
  },
  {
    "zip": 77377,
    "lat": 30.06135,
    "lng": -95.68201,
    "state_id": "TX"
  },
  {
    "zip": 77378,
    "lat": 30.4574,
    "lng": -95.36867,
    "state_id": "TX"
  },
  {
    "zip": 77379,
    "lat": 30.03912,
    "lng": -95.53382,
    "state_id": "TX"
  },
  {
    "zip": 77380,
    "lat": 30.13645,
    "lng": -95.46866,
    "state_id": "TX"
  },
  {
    "zip": 77381,
    "lat": 30.17818,
    "lng": -95.50144,
    "state_id": "TX"
  },
  {
    "zip": 77382,
    "lat": 30.19804,
    "lng": -95.54606,
    "state_id": "TX"
  },
  {
    "zip": 77384,
    "lat": 30.23494,
    "lng": -95.49526,
    "state_id": "TX"
  },
  {
    "zip": 77385,
    "lat": 30.18888,
    "lng": -95.42223,
    "state_id": "TX"
  },
  {
    "zip": 77386,
    "lat": 30.10125,
    "lng": -95.35584,
    "state_id": "TX"
  },
  {
    "zip": 77388,
    "lat": 30.05984,
    "lng": -95.46851,
    "state_id": "TX"
  },
  {
    "zip": 77389,
    "lat": 30.11516,
    "lng": -95.5075,
    "state_id": "TX"
  },
  {
    "zip": 77396,
    "lat": 29.94822,
    "lng": -95.25991,
    "state_id": "TX"
  },
  {
    "zip": 77401,
    "lat": 29.70443,
    "lng": -95.46175,
    "state_id": "TX"
  },
  {
    "zip": 77406,
    "lat": 29.64359,
    "lng": -95.79799,
    "state_id": "TX"
  },
  {
    "zip": 77407,
    "lat": 29.67651,
    "lng": -95.71253,
    "state_id": "TX"
  },
  {
    "zip": 77412,
    "lat": 29.555,
    "lng": -96.42816,
    "state_id": "TX"
  },
  {
    "zip": 77414,
    "lat": 28.89958,
    "lng": -95.88091,
    "state_id": "TX"
  },
  {
    "zip": 77415,
    "lat": 28.93254,
    "lng": -95.73575,
    "state_id": "TX"
  },
  {
    "zip": 77417,
    "lat": 29.46457,
    "lng": -95.96703,
    "state_id": "TX"
  },
  {
    "zip": 77418,
    "lat": 29.97879,
    "lng": -96.25055,
    "state_id": "TX"
  },
  {
    "zip": 77419,
    "lat": 28.85073,
    "lng": -96.2561,
    "state_id": "TX"
  },
  {
    "zip": 77420,
    "lat": 29.2581,
    "lng": -95.93008,
    "state_id": "TX"
  },
  {
    "zip": 77422,
    "lat": 28.96092,
    "lng": -95.56105,
    "state_id": "TX"
  },
  {
    "zip": 77423,
    "lat": 29.83893,
    "lng": -95.98251,
    "state_id": "TX"
  },
  {
    "zip": 77426,
    "lat": 30.11817,
    "lng": -96.24904,
    "state_id": "TX"
  },
  {
    "zip": 77428,
    "lat": 28.62311,
    "lng": -96.18975,
    "state_id": "TX"
  },
  {
    "zip": 77429,
    "lat": 29.99104,
    "lng": -95.6588,
    "state_id": "TX"
  },
  {
    "zip": 77430,
    "lat": 29.26718,
    "lng": -95.65366,
    "state_id": "TX"
  },
  {
    "zip": 77432,
    "lat": 29.06993,
    "lng": -96.19387,
    "state_id": "TX"
  },
  {
    "zip": 77433,
    "lat": 29.94911,
    "lng": -95.73923,
    "state_id": "TX"
  },
  {
    "zip": 77434,
    "lat": 29.52568,
    "lng": -96.30214,
    "state_id": "TX"
  },
  {
    "zip": 77435,
    "lat": 29.51733,
    "lng": -96.11114,
    "state_id": "TX"
  },
  {
    "zip": 77436,
    "lat": 29.42393,
    "lng": -96.23226,
    "state_id": "TX"
  },
  {
    "zip": 77437,
    "lat": 29.21304,
    "lng": -96.28036,
    "state_id": "TX"
  },
  {
    "zip": 77440,
    "lat": 28.89492,
    "lng": -96.15082,
    "state_id": "TX"
  },
  {
    "zip": 77441,
    "lat": 29.66824,
    "lng": -95.92279,
    "state_id": "TX"
  },
  {
    "zip": 77442,
    "lat": 29.42428,
    "lng": -96.50681,
    "state_id": "TX"
  },
  {
    "zip": 77443,
    "lat": 29.34828,
    "lng": -96.19267,
    "state_id": "TX"
  },
  {
    "zip": 77444,
    "lat": 29.28815,
    "lng": -95.77719,
    "state_id": "TX"
  },
  {
    "zip": 77445,
    "lat": 30.09968,
    "lng": -96.06494,
    "state_id": "TX"
  },
  {
    "zip": 77446,
    "lat": 30.08681,
    "lng": -95.99051,
    "state_id": "TX"
  },
  {
    "zip": 77447,
    "lat": 30.05124,
    "lng": -95.83044,
    "state_id": "TX"
  },
  {
    "zip": 77448,
    "lat": 29.41534,
    "lng": -96.08656,
    "state_id": "TX"
  },
  {
    "zip": 77449,
    "lat": 29.83556,
    "lng": -95.73813,
    "state_id": "TX"
  },
  {
    "zip": 77450,
    "lat": 29.74629,
    "lng": -95.73832,
    "state_id": "TX"
  },
  {
    "zip": 77451,
    "lat": 29.4379,
    "lng": -96.00165,
    "state_id": "TX"
  },
  {
    "zip": 77453,
    "lat": 29.21196,
    "lng": -95.99945,
    "state_id": "TX"
  },
  {
    "zip": 77454,
    "lat": 29.52037,
    "lng": -96.21862,
    "state_id": "TX"
  },
  {
    "zip": 77455,
    "lat": 29.13955,
    "lng": -96.43031,
    "state_id": "TX"
  },
  {
    "zip": 77456,
    "lat": 29.00035,
    "lng": -96.09033,
    "state_id": "TX"
  },
  {
    "zip": 77457,
    "lat": 28.70908,
    "lng": -95.92228,
    "state_id": "TX"
  },
  {
    "zip": 77458,
    "lat": 28.95289,
    "lng": -96.24036,
    "state_id": "TX"
  },
  {
    "zip": 77459,
    "lat": 29.52565,
    "lng": -95.53298,
    "state_id": "TX"
  },
  {
    "zip": 77460,
    "lat": 29.39744,
    "lng": -96.38451,
    "state_id": "TX"
  },
  {
    "zip": 77461,
    "lat": 29.38013,
    "lng": -95.80672,
    "state_id": "TX"
  },
  {
    "zip": 77464,
    "lat": 29.59337,
    "lng": -95.96175,
    "state_id": "TX"
  },
  {
    "zip": 77465,
    "lat": 28.76248,
    "lng": -96.22968,
    "state_id": "TX"
  },
  {
    "zip": 77466,
    "lat": 29.80562,
    "lng": -96.01287,
    "state_id": "TX"
  },
  {
    "zip": 77467,
    "lat": 29.21068,
    "lng": -96.12324,
    "state_id": "TX"
  },
  {
    "zip": 77468,
    "lat": 29.14259,
    "lng": -95.93111,
    "state_id": "TX"
  },
  {
    "zip": 77469,
    "lat": 29.48,
    "lng": -95.68313,
    "state_id": "TX"
  },
  {
    "zip": 77470,
    "lat": 29.53214,
    "lng": -96.55145,
    "state_id": "TX"
  },
  {
    "zip": 77471,
    "lat": 29.54596,
    "lng": -95.86815,
    "state_id": "TX"
  },
  {
    "zip": 77473,
    "lat": 29.79516,
    "lng": -96.1077,
    "state_id": "TX"
  },
  {
    "zip": 77474,
    "lat": 29.77377,
    "lng": -96.17926,
    "state_id": "TX"
  },
  {
    "zip": 77475,
    "lat": 29.49304,
    "lng": -96.65871,
    "state_id": "TX"
  },
  {
    "zip": 77476,
    "lat": 29.67246,
    "lng": -95.98329,
    "state_id": "TX"
  },
  {
    "zip": 77477,
    "lat": 29.62571,
    "lng": -95.56673,
    "state_id": "TX"
  },
  {
    "zip": 77478,
    "lat": 29.61964,
    "lng": -95.607,
    "state_id": "TX"
  },
  {
    "zip": 77479,
    "lat": 29.56646,
    "lng": -95.63557,
    "state_id": "TX"
  },
  {
    "zip": 77480,
    "lat": 29.09233,
    "lng": -95.766,
    "state_id": "TX"
  },
  {
    "zip": 77481,
    "lat": 29.47038,
    "lng": -95.56,
    "state_id": "TX"
  },
  {
    "zip": 77482,
    "lat": 29.1021,
    "lng": -95.8802,
    "state_id": "TX"
  },
  {
    "zip": 77483,
    "lat": 28.78761,
    "lng": -95.83652,
    "state_id": "TX"
  },
  {
    "zip": 77484,
    "lat": 30.07526,
    "lng": -95.93087,
    "state_id": "TX"
  },
  {
    "zip": 77485,
    "lat": 29.63418,
    "lng": -96.05612,
    "state_id": "TX"
  },
  {
    "zip": 77486,
    "lat": 29.15812,
    "lng": -95.68094,
    "state_id": "TX"
  },
  {
    "zip": 77488,
    "lat": 29.30684,
    "lng": -96.09126,
    "state_id": "TX"
  },
  {
    "zip": 77489,
    "lat": 29.6006,
    "lng": -95.5163,
    "state_id": "TX"
  },
  {
    "zip": 77493,
    "lat": 29.85208,
    "lng": -95.83094,
    "state_id": "TX"
  },
  {
    "zip": 77494,
    "lat": 29.7433,
    "lng": -95.82862,
    "state_id": "TX"
  },
  {
    "zip": 77498,
    "lat": 29.64338,
    "lng": -95.65236,
    "state_id": "TX"
  },
  {
    "zip": 77502,
    "lat": 29.67951,
    "lng": -95.19976,
    "state_id": "TX"
  },
  {
    "zip": 77503,
    "lat": 29.70218,
    "lng": -95.15943,
    "state_id": "TX"
  },
  {
    "zip": 77504,
    "lat": 29.64802,
    "lng": -95.18997,
    "state_id": "TX"
  },
  {
    "zip": 77505,
    "lat": 29.64607,
    "lng": -95.13825,
    "state_id": "TX"
  },
  {
    "zip": 77506,
    "lat": 29.7149,
    "lng": -95.20013,
    "state_id": "TX"
  },
  {
    "zip": 77507,
    "lat": 29.62601,
    "lng": -95.06443,
    "state_id": "TX"
  },
  {
    "zip": 77510,
    "lat": 29.36107,
    "lng": -95.08555,
    "state_id": "TX"
  },
  {
    "zip": 77511,
    "lat": 29.38147,
    "lng": -95.24204,
    "state_id": "TX"
  },
  {
    "zip": 77514,
    "lat": 29.68073,
    "lng": -94.59949,
    "state_id": "TX"
  },
  {
    "zip": 77515,
    "lat": 29.17436,
    "lng": -95.44993,
    "state_id": "TX"
  },
  {
    "zip": 77517,
    "lat": 29.36962,
    "lng": -95.13221,
    "state_id": "TX"
  },
  {
    "zip": 77518,
    "lat": 29.50703,
    "lng": -94.98696,
    "state_id": "TX"
  },
  {
    "zip": 77519,
    "lat": 30.23425,
    "lng": -94.60224,
    "state_id": "TX"
  },
  {
    "zip": 77520,
    "lat": 29.73895,
    "lng": -94.99829,
    "state_id": "TX"
  },
  {
    "zip": 77521,
    "lat": 29.79848,
    "lng": -94.96591,
    "state_id": "TX"
  },
  {
    "zip": 77523,
    "lat": 29.78789,
    "lng": -94.86717,
    "state_id": "TX"
  },
  {
    "zip": 77530,
    "lat": 29.78836,
    "lng": -95.11149,
    "state_id": "TX"
  },
  {
    "zip": 77531,
    "lat": 29.04935,
    "lng": -95.39242,
    "state_id": "TX"
  },
  {
    "zip": 77532,
    "lat": 29.93018,
    "lng": -95.05654,
    "state_id": "TX"
  },
  {
    "zip": 77533,
    "lat": 30.09162,
    "lng": -94.59604,
    "state_id": "TX"
  },
  {
    "zip": 77534,
    "lat": 29.22632,
    "lng": -95.30893,
    "state_id": "TX"
  },
  {
    "zip": 77535,
    "lat": 30.05703,
    "lng": -94.91786,
    "state_id": "TX"
  },
  {
    "zip": 77536,
    "lat": 29.69839,
    "lng": -95.12128,
    "state_id": "TX"
  },
  {
    "zip": 77538,
    "lat": 29.99154,
    "lng": -94.53111,
    "state_id": "TX"
  },
  {
    "zip": 77539,
    "lat": 29.456,
    "lng": -95.03172,
    "state_id": "TX"
  },
  {
    "zip": 77541,
    "lat": 29.02696,
    "lng": -95.3112,
    "state_id": "TX"
  },
  {
    "zip": 77545,
    "lat": 29.53635,
    "lng": -95.47456,
    "state_id": "TX"
  },
  {
    "zip": 77546,
    "lat": 29.51493,
    "lng": -95.19218,
    "state_id": "TX"
  },
  {
    "zip": 77547,
    "lat": 29.73604,
    "lng": -95.23785,
    "state_id": "TX"
  },
  {
    "zip": 77550,
    "lat": 29.3098,
    "lng": -94.77746,
    "state_id": "TX"
  },
  {
    "zip": 77551,
    "lat": 29.27872,
    "lng": -94.83323,
    "state_id": "TX"
  },
  {
    "zip": 77554,
    "lat": 29.2456,
    "lng": -94.92348,
    "state_id": "TX"
  },
  {
    "zip": 77560,
    "lat": 29.87662,
    "lng": -94.59323,
    "state_id": "TX"
  },
  {
    "zip": 77561,
    "lat": 30.16651,
    "lng": -94.73547,
    "state_id": "TX"
  },
  {
    "zip": 77562,
    "lat": 29.83106,
    "lng": -95.05353,
    "state_id": "TX"
  },
  {
    "zip": 77563,
    "lat": 29.29417,
    "lng": -95.03031,
    "state_id": "TX"
  },
  {
    "zip": 77564,
    "lat": 30.20361,
    "lng": -94.66743,
    "state_id": "TX"
  },
  {
    "zip": 77565,
    "lat": 29.53619,
    "lng": -95.03021,
    "state_id": "TX"
  },
  {
    "zip": 77566,
    "lat": 29.05026,
    "lng": -95.47542,
    "state_id": "TX"
  },
  {
    "zip": 77568,
    "lat": 29.36383,
    "lng": -94.97975,
    "state_id": "TX"
  },
  {
    "zip": 77571,
    "lat": 29.69052,
    "lng": -95.05359,
    "state_id": "TX"
  },
  {
    "zip": 77573,
    "lat": 29.50205,
    "lng": -95.08652,
    "state_id": "TX"
  },
  {
    "zip": 77575,
    "lat": 30.07534,
    "lng": -94.73395,
    "state_id": "TX"
  },
  {
    "zip": 77577,
    "lat": 29.27976,
    "lng": -95.27872,
    "state_id": "TX"
  },
  {
    "zip": 77578,
    "lat": 29.48132,
    "lng": -95.36559,
    "state_id": "TX"
  },
  {
    "zip": 77580,
    "lat": 29.8676,
    "lng": -94.86923,
    "state_id": "TX"
  },
  {
    "zip": 77581,
    "lat": 29.56048,
    "lng": -95.27753,
    "state_id": "TX"
  },
  {
    "zip": 77583,
    "lat": 29.38137,
    "lng": -95.46219,
    "state_id": "TX"
  },
  {
    "zip": 77584,
    "lat": 29.546,
    "lng": -95.35081,
    "state_id": "TX"
  },
  {
    "zip": 77585,
    "lat": 30.29552,
    "lng": -94.52242,
    "state_id": "TX"
  },
  {
    "zip": 77586,
    "lat": 29.58428,
    "lng": -95.03611,
    "state_id": "TX"
  },
  {
    "zip": 77587,
    "lat": 29.66108,
    "lng": -95.22955,
    "state_id": "TX"
  },
  {
    "zip": 77590,
    "lat": 29.39095,
    "lng": -94.91973,
    "state_id": "TX"
  },
  {
    "zip": 77591,
    "lat": 29.39941,
    "lng": -94.99751,
    "state_id": "TX"
  },
  {
    "zip": 77597,
    "lat": 29.83708,
    "lng": -94.70221,
    "state_id": "TX"
  },
  {
    "zip": 77598,
    "lat": 29.53784,
    "lng": -95.13685,
    "state_id": "TX"
  },
  {
    "zip": 77611,
    "lat": 30.0132,
    "lng": -93.82257,
    "state_id": "TX"
  },
  {
    "zip": 77612,
    "lat": 30.40615,
    "lng": -93.92573,
    "state_id": "TX"
  },
  {
    "zip": 77613,
    "lat": 30.04723,
    "lng": -94.35561,
    "state_id": "TX"
  },
  {
    "zip": 77614,
    "lat": 30.30478,
    "lng": -93.76105,
    "state_id": "TX"
  },
  {
    "zip": 77615,
    "lat": 30.32407,
    "lng": -94.05969,
    "state_id": "TX"
  },
  {
    "zip": 77616,
    "lat": 30.56777,
    "lng": -94.17998,
    "state_id": "TX"
  },
  {
    "zip": 77617,
    "lat": 29.50649,
    "lng": -94.52151,
    "state_id": "TX"
  },
  {
    "zip": 77619,
    "lat": 29.94724,
    "lng": -93.91851,
    "state_id": "TX"
  },
  {
    "zip": 77622,
    "lat": 29.85997,
    "lng": -94.29897,
    "state_id": "TX"
  },
  {
    "zip": 77623,
    "lat": 29.55988,
    "lng": -94.41714,
    "state_id": "TX"
  },
  {
    "zip": 77624,
    "lat": 30.68372,
    "lng": -94.29168,
    "state_id": "TX"
  },
  {
    "zip": 77625,
    "lat": 30.37575,
    "lng": -94.37899,
    "state_id": "TX"
  },
  {
    "zip": 77627,
    "lat": 29.98742,
    "lng": -94.00907,
    "state_id": "TX"
  },
  {
    "zip": 77629,
    "lat": 29.97774,
    "lng": -94.40228,
    "state_id": "TX"
  },
  {
    "zip": 77630,
    "lat": 30.0711,
    "lng": -93.86461,
    "state_id": "TX"
  },
  {
    "zip": 77632,
    "lat": 30.20328,
    "lng": -93.80512,
    "state_id": "TX"
  },
  {
    "zip": 77640,
    "lat": 29.88111,
    "lng": -93.94105,
    "state_id": "TX"
  },
  {
    "zip": 77642,
    "lat": 29.93921,
    "lng": -93.91601,
    "state_id": "TX"
  },
  {
    "zip": 77650,
    "lat": 29.4276,
    "lng": -94.68586,
    "state_id": "TX"
  },
  {
    "zip": 77651,
    "lat": 29.98507,
    "lng": -93.95605,
    "state_id": "TX"
  },
  {
    "zip": 77655,
    "lat": 29.71322,
    "lng": -93.95371,
    "state_id": "TX"
  },
  {
    "zip": 77656,
    "lat": 30.41587,
    "lng": -94.17206,
    "state_id": "TX"
  },
  {
    "zip": 77657,
    "lat": 30.2256,
    "lng": -94.19614,
    "state_id": "TX"
  },
  {
    "zip": 77659,
    "lat": 30.15814,
    "lng": -94.43521,
    "state_id": "TX"
  },
  {
    "zip": 77660,
    "lat": 30.64628,
    "lng": -94.14546,
    "state_id": "TX"
  },
  {
    "zip": 77661,
    "lat": 29.73465,
    "lng": -94.41377,
    "state_id": "TX"
  },
  {
    "zip": 77662,
    "lat": 30.17843,
    "lng": -94.01841,
    "state_id": "TX"
  },
  {
    "zip": 77663,
    "lat": 30.51239,
    "lng": -94.39544,
    "state_id": "TX"
  },
  {
    "zip": 77664,
    "lat": 30.59999,
    "lng": -94.41637,
    "state_id": "TX"
  },
  {
    "zip": 77665,
    "lat": 29.81685,
    "lng": -94.41658,
    "state_id": "TX"
  },
  {
    "zip": 77701,
    "lat": 30.07314,
    "lng": -94.106,
    "state_id": "TX"
  },
  {
    "zip": 77702,
    "lat": 30.08626,
    "lng": -94.12829,
    "state_id": "TX"
  },
  {
    "zip": 77703,
    "lat": 30.12828,
    "lng": -94.1174,
    "state_id": "TX"
  },
  {
    "zip": 77705,
    "lat": 29.9046,
    "lng": -94.16146,
    "state_id": "TX"
  },
  {
    "zip": 77706,
    "lat": 30.09973,
    "lng": -94.17074,
    "state_id": "TX"
  },
  {
    "zip": 77707,
    "lat": 30.05295,
    "lng": -94.17654,
    "state_id": "TX"
  },
  {
    "zip": 77708,
    "lat": 30.14475,
    "lng": -94.16484,
    "state_id": "TX"
  },
  {
    "zip": 77713,
    "lat": 30.06086,
    "lng": -94.25397,
    "state_id": "TX"
  },
  {
    "zip": 77801,
    "lat": 30.63888,
    "lng": -96.36343,
    "state_id": "TX"
  },
  {
    "zip": 77802,
    "lat": 30.66126,
    "lng": -96.32265,
    "state_id": "TX"
  },
  {
    "zip": 77803,
    "lat": 30.68077,
    "lng": -96.38545,
    "state_id": "TX"
  },
  {
    "zip": 77807,
    "lat": 30.67879,
    "lng": -96.48006,
    "state_id": "TX"
  },
  {
    "zip": 77808,
    "lat": 30.78934,
    "lng": -96.31434,
    "state_id": "TX"
  },
  {
    "zip": 77830,
    "lat": 30.52414,
    "lng": -96.01185,
    "state_id": "TX"
  },
  {
    "zip": 77831,
    "lat": 30.72848,
    "lng": -95.90476,
    "state_id": "TX"
  },
  {
    "zip": 77833,
    "lat": 30.21068,
    "lng": -96.40705,
    "state_id": "TX"
  },
  {
    "zip": 77835,
    "lat": 30.1917,
    "lng": -96.595,
    "state_id": "TX"
  },
  {
    "zip": 77836,
    "lat": 30.5375,
    "lng": -96.68003,
    "state_id": "TX"
  },
  {
    "zip": 77837,
    "lat": 31.00731,
    "lng": -96.68717,
    "state_id": "TX"
  },
  {
    "zip": 77840,
    "lat": 30.60908,
    "lng": -96.32601,
    "state_id": "TX"
  },
  {
    "zip": 77845,
    "lat": 30.56253,
    "lng": -96.27564,
    "state_id": "TX"
  },
  {
    "zip": 77853,
    "lat": 30.36444,
    "lng": -96.84281,
    "state_id": "TX"
  },
  {
    "zip": 77855,
    "lat": 31.1455,
    "lng": -96.14068,
    "state_id": "TX"
  },
  {
    "zip": 77856,
    "lat": 31.07333,
    "lng": -96.42385,
    "state_id": "TX"
  },
  {
    "zip": 77857,
    "lat": 30.78439,
    "lng": -96.70859,
    "state_id": "TX"
  },
  {
    "zip": 77859,
    "lat": 30.86932,
    "lng": -96.51734,
    "state_id": "TX"
  },
  {
    "zip": 77861,
    "lat": 30.72741,
    "lng": -96.09598,
    "state_id": "TX"
  },
  {
    "zip": 77864,
    "lat": 30.95416,
    "lng": -95.90558,
    "state_id": "TX"
  },
  {
    "zip": 77865,
    "lat": 31.23034,
    "lng": -96.2281,
    "state_id": "TX"
  },
  {
    "zip": 77867,
    "lat": 30.74124,
    "lng": -96.5444,
    "state_id": "TX"
  },
  {
    "zip": 77868,
    "lat": 30.36859,
    "lng": -96.0616,
    "state_id": "TX"
  },
  {
    "zip": 77871,
    "lat": 31.05102,
    "lng": -96.11752,
    "state_id": "TX"
  },
  {
    "zip": 77872,
    "lat": 30.88687,
    "lng": -96.11025,
    "state_id": "TX"
  },
  {
    "zip": 77873,
    "lat": 30.57425,
    "lng": -95.81288,
    "state_id": "TX"
  },
  {
    "zip": 77876,
    "lat": 30.59729,
    "lng": -95.87444,
    "state_id": "TX"
  },
  {
    "zip": 77878,
    "lat": 30.47152,
    "lng": -96.43601,
    "state_id": "TX"
  },
  {
    "zip": 77879,
    "lat": 30.39927,
    "lng": -96.49695,
    "state_id": "TX"
  },
  {
    "zip": 77880,
    "lat": 30.28141,
    "lng": -96.18401,
    "state_id": "TX"
  },
  {
    "zip": 77901,
    "lat": 28.80495,
    "lng": -96.98474,
    "state_id": "TX"
  },
  {
    "zip": 77904,
    "lat": 28.92334,
    "lng": -97.01818,
    "state_id": "TX"
  },
  {
    "zip": 77905,
    "lat": 28.73132,
    "lng": -97.04241,
    "state_id": "TX"
  },
  {
    "zip": 77950,
    "lat": 28.30141,
    "lng": -96.87481,
    "state_id": "TX"
  },
  {
    "zip": 77951,
    "lat": 28.63416,
    "lng": -96.91088,
    "state_id": "TX"
  },
  {
    "zip": 77954,
    "lat": 29.09675,
    "lng": -97.28269,
    "state_id": "TX"
  },
  {
    "zip": 77957,
    "lat": 29.03767,
    "lng": -96.70041,
    "state_id": "TX"
  },
  {
    "zip": 77960,
    "lat": 28.6691,
    "lng": -97.20818,
    "state_id": "TX"
  },
  {
    "zip": 77961,
    "lat": 28.85739,
    "lng": -96.36151,
    "state_id": "TX"
  },
  {
    "zip": 77962,
    "lat": 29.02264,
    "lng": -96.48946,
    "state_id": "TX"
  },
  {
    "zip": 77963,
    "lat": 28.65701,
    "lng": -97.41547,
    "state_id": "TX"
  },
  {
    "zip": 77964,
    "lat": 29.37701,
    "lng": -96.84377,
    "state_id": "TX"
  },
  {
    "zip": 77968,
    "lat": 28.90923,
    "lng": -96.84712,
    "state_id": "TX"
  },
  {
    "zip": 77969,
    "lat": 28.77244,
    "lng": -96.65117,
    "state_id": "TX"
  },
  {
    "zip": 77970,
    "lat": 28.8447,
    "lng": -96.43443,
    "state_id": "TX"
  },
  {
    "zip": 77971,
    "lat": 28.83222,
    "lng": -96.52192,
    "state_id": "TX"
  },
  {
    "zip": 77973,
    "lat": 28.54326,
    "lng": -96.96374,
    "state_id": "TX"
  },
  {
    "zip": 77974,
    "lat": 28.89163,
    "lng": -97.29484,
    "state_id": "TX"
  },
  {
    "zip": 77975,
    "lat": 29.56787,
    "lng": -97.08485,
    "state_id": "TX"
  },
  {
    "zip": 77976,
    "lat": 28.95449,
    "lng": -97.12272,
    "state_id": "TX"
  },
  {
    "zip": 77977,
    "lat": 28.69558,
    "lng": -96.79413,
    "state_id": "TX"
  },
  {
    "zip": 77978,
    "lat": 28.66665,
    "lng": -96.57237,
    "state_id": "TX"
  },
  {
    "zip": 77979,
    "lat": 28.60229,
    "lng": -96.60787,
    "state_id": "TX"
  },
  {
    "zip": 77982,
    "lat": 28.44603,
    "lng": -96.46007,
    "state_id": "TX"
  },
  {
    "zip": 77983,
    "lat": 28.42886,
    "lng": -96.57441,
    "state_id": "TX"
  },
  {
    "zip": 77984,
    "lat": 29.45351,
    "lng": -97.18787,
    "state_id": "TX"
  },
  {
    "zip": 77987,
    "lat": 29.34254,
    "lng": -97.07564,
    "state_id": "TX"
  },
  {
    "zip": 77988,
    "lat": 28.83903,
    "lng": -96.88962,
    "state_id": "TX"
  },
  {
    "zip": 77990,
    "lat": 28.46992,
    "lng": -96.91719,
    "state_id": "TX"
  },
  {
    "zip": 77991,
    "lat": 28.79624,
    "lng": -96.61312,
    "state_id": "TX"
  },
  {
    "zip": 77993,
    "lat": 28.85774,
    "lng": -97.42858,
    "state_id": "TX"
  },
  {
    "zip": 77994,
    "lat": 29.17819,
    "lng": -97.46017,
    "state_id": "TX"
  },
  {
    "zip": 77995,
    "lat": 29.21179,
    "lng": -97.08341,
    "state_id": "TX"
  },
  {
    "zip": 78001,
    "lat": 28.29493,
    "lng": -99.21954,
    "state_id": "TX"
  },
  {
    "zip": 78002,
    "lat": 29.28295,
    "lng": -98.73826,
    "state_id": "TX"
  },
  {
    "zip": 78003,
    "lat": 29.70959,
    "lng": -99.09413,
    "state_id": "TX"
  },
  {
    "zip": 78004,
    "lat": 29.90066,
    "lng": -98.55579,
    "state_id": "TX"
  },
  {
    "zip": 78005,
    "lat": 28.94844,
    "lng": -98.85243,
    "state_id": "TX"
  },
  {
    "zip": 78006,
    "lat": 29.85533,
    "lng": -98.72266,
    "state_id": "TX"
  },
  {
    "zip": 78007,
    "lat": 28.49506,
    "lng": -98.37836,
    "state_id": "TX"
  },
  {
    "zip": 78008,
    "lat": 28.72411,
    "lng": -98.27925,
    "state_id": "TX"
  },
  {
    "zip": 78009,
    "lat": 29.35732,
    "lng": -98.88667,
    "state_id": "TX"
  },
  {
    "zip": 78010,
    "lat": 29.9123,
    "lng": -99.04482,
    "state_id": "TX"
  },
  {
    "zip": 78011,
    "lat": 28.79237,
    "lng": -98.72129,
    "state_id": "TX"
  },
  {
    "zip": 78012,
    "lat": 28.75991,
    "lng": -98.47914,
    "state_id": "TX"
  },
  {
    "zip": 78013,
    "lat": 29.9706,
    "lng": -98.90518,
    "state_id": "TX"
  },
  {
    "zip": 78014,
    "lat": 28.36134,
    "lng": -99.14498,
    "state_id": "TX"
  },
  {
    "zip": 78015,
    "lat": 29.747,
    "lng": -98.6555,
    "state_id": "TX"
  },
  {
    "zip": 78016,
    "lat": 29.18359,
    "lng": -98.96602,
    "state_id": "TX"
  },
  {
    "zip": 78017,
    "lat": 28.70688,
    "lng": -99.1938,
    "state_id": "TX"
  },
  {
    "zip": 78019,
    "lat": 28.04207,
    "lng": -99.39643,
    "state_id": "TX"
  },
  {
    "zip": 78021,
    "lat": 28.50102,
    "lng": -98.82637,
    "state_id": "TX"
  },
  {
    "zip": 78022,
    "lat": 28.25927,
    "lng": -98.14445,
    "state_id": "TX"
  },
  {
    "zip": 78023,
    "lat": 29.61087,
    "lng": -98.74532,
    "state_id": "TX"
  },
  {
    "zip": 78024,
    "lat": 30.00114,
    "lng": -99.48089,
    "state_id": "TX"
  },
  {
    "zip": 78025,
    "lat": 30.08367,
    "lng": -99.28317,
    "state_id": "TX"
  },
  {
    "zip": 78026,
    "lat": 28.83751,
    "lng": -98.59733,
    "state_id": "TX"
  },
  {
    "zip": 78027,
    "lat": 29.97556,
    "lng": -98.54274,
    "state_id": "TX"
  },
  {
    "zip": 78028,
    "lat": 30.03421,
    "lng": -99.1582,
    "state_id": "TX"
  },
  {
    "zip": 78029,
    "lat": 30.1591,
    "lng": -99.34498,
    "state_id": "TX"
  },
  {
    "zip": 78039,
    "lat": 29.31371,
    "lng": -98.82963,
    "state_id": "TX"
  },
  {
    "zip": 78040,
    "lat": 27.51345,
    "lng": -99.50228,
    "state_id": "TX"
  },
  {
    "zip": 78041,
    "lat": 27.55417,
    "lng": -99.46337,
    "state_id": "TX"
  },
  {
    "zip": 78043,
    "lat": 27.54915,
    "lng": -99.26173,
    "state_id": "TX"
  },
  {
    "zip": 78044,
    "lat": 27.75007,
    "lng": -99.13106,
    "state_id": "TX"
  },
  {
    "zip": 78045,
    "lat": 27.85007,
    "lng": -99.66888,
    "state_id": "TX"
  },
  {
    "zip": 78046,
    "lat": 27.37485,
    "lng": -99.34941,
    "state_id": "TX"
  },
  {
    "zip": 78050,
    "lat": 29.07059,
    "lng": -98.48192,
    "state_id": "TX"
  },
  {
    "zip": 78052,
    "lat": 29.20449,
    "lng": -98.77534,
    "state_id": "TX"
  },
  {
    "zip": 78055,
    "lat": 29.82094,
    "lng": -99.31944,
    "state_id": "TX"
  },
  {
    "zip": 78056,
    "lat": 29.53765,
    "lng": -98.93486,
    "state_id": "TX"
  },
  {
    "zip": 78057,
    "lat": 29.03929,
    "lng": -98.99952,
    "state_id": "TX"
  },
  {
    "zip": 78058,
    "lat": 30.0957,
    "lng": -99.63952,
    "state_id": "TX"
  },
  {
    "zip": 78059,
    "lat": 29.19189,
    "lng": -98.84177,
    "state_id": "TX"
  },
  {
    "zip": 78060,
    "lat": 28.44686,
    "lng": -98.10699,
    "state_id": "TX"
  },
  {
    "zip": 78061,
    "lat": 28.90171,
    "lng": -99.14689,
    "state_id": "TX"
  },
  {
    "zip": 78063,
    "lat": 29.68785,
    "lng": -98.91422,
    "state_id": "TX"
  },
  {
    "zip": 78064,
    "lat": 28.93613,
    "lng": -98.42571,
    "state_id": "TX"
  },
  {
    "zip": 78065,
    "lat": 29.07762,
    "lng": -98.63983,
    "state_id": "TX"
  },
  {
    "zip": 78066,
    "lat": 29.4794,
    "lng": -98.8956,
    "state_id": "TX"
  },
  {
    "zip": 78067,
    "lat": 27.13267,
    "lng": -99.33646,
    "state_id": "TX"
  },
  {
    "zip": 78069,
    "lat": 29.18606,
    "lng": -98.68109,
    "state_id": "TX"
  },
  {
    "zip": 78070,
    "lat": 29.89826,
    "lng": -98.4063,
    "state_id": "TX"
  },
  {
    "zip": 78071,
    "lat": 28.46948,
    "lng": -98.22431,
    "state_id": "TX"
  },
  {
    "zip": 78072,
    "lat": 28.30174,
    "lng": -98.58516,
    "state_id": "TX"
  },
  {
    "zip": 78073,
    "lat": 29.24487,
    "lng": -98.61955,
    "state_id": "TX"
  },
  {
    "zip": 78075,
    "lat": 28.60116,
    "lng": -98.35311,
    "state_id": "TX"
  },
  {
    "zip": 78076,
    "lat": 26.93099,
    "lng": -99.15506,
    "state_id": "TX"
  },
  {
    "zip": 78101,
    "lat": 29.34402,
    "lng": -98.23884,
    "state_id": "TX"
  },
  {
    "zip": 78102,
    "lat": 28.40248,
    "lng": -97.71084,
    "state_id": "TX"
  },
  {
    "zip": 78104,
    "lat": 28.51876,
    "lng": -97.76461,
    "state_id": "TX"
  },
  {
    "zip": 78107,
    "lat": 28.5493,
    "lng": -97.57399,
    "state_id": "TX"
  },
  {
    "zip": 78108,
    "lat": 29.57435,
    "lng": -98.21878,
    "state_id": "TX"
  },
  {
    "zip": 78109,
    "lat": 29.48788,
    "lng": -98.29148,
    "state_id": "TX"
  },
  {
    "zip": 78112,
    "lat": 29.21277,
    "lng": -98.37165,
    "state_id": "TX"
  },
  {
    "zip": 78113,
    "lat": 28.95336,
    "lng": -98.12689,
    "state_id": "TX"
  },
  {
    "zip": 78114,
    "lat": 29.11822,
    "lng": -98.20179,
    "state_id": "TX"
  },
  {
    "zip": 78116,
    "lat": 29.11262,
    "lng": -97.75699,
    "state_id": "TX"
  },
  {
    "zip": 78117,
    "lat": 28.94398,
    "lng": -97.95448,
    "state_id": "TX"
  },
  {
    "zip": 78118,
    "lat": 28.88645,
    "lng": -97.95035,
    "state_id": "TX"
  },
  {
    "zip": 78119,
    "lat": 28.73025,
    "lng": -97.89597,
    "state_id": "TX"
  },
  {
    "zip": 78121,
    "lat": 29.35147,
    "lng": -98.10722,
    "state_id": "TX"
  },
  {
    "zip": 78122,
    "lat": 29.4366,
    "lng": -97.73588,
    "state_id": "TX"
  },
  {
    "zip": 78123,
    "lat": 29.6024,
    "lng": -98.04405,
    "state_id": "TX"
  },
  {
    "zip": 78124,
    "lat": 29.55515,
    "lng": -98.14732,
    "state_id": "TX"
  },
  {
    "zip": 78125,
    "lat": 28.53824,
    "lng": -97.96328,
    "state_id": "TX"
  },
  {
    "zip": 78130,
    "lat": 29.69772,
    "lng": -98.07021,
    "state_id": "TX"
  },
  {
    "zip": 78132,
    "lat": 29.76312,
    "lng": -98.1911,
    "state_id": "TX"
  },
  {
    "zip": 78133,
    "lat": 29.88674,
    "lng": -98.24214,
    "state_id": "TX"
  },
  {
    "zip": 78140,
    "lat": 29.30696,
    "lng": -97.76913,
    "state_id": "TX"
  },
  {
    "zip": 78141,
    "lat": 28.91743,
    "lng": -97.627,
    "state_id": "TX"
  },
  {
    "zip": 78142,
    "lat": 28.53664,
    "lng": -97.81055,
    "state_id": "TX"
  },
  {
    "zip": 78143,
    "lat": 29.26244,
    "lng": -97.86053,
    "state_id": "TX"
  },
  {
    "zip": 78144,
    "lat": 28.95506,
    "lng": -97.88795,
    "state_id": "TX"
  },
  {
    "zip": 78145,
    "lat": 28.64519,
    "lng": -97.9941,
    "state_id": "TX"
  },
  {
    "zip": 78146,
    "lat": 28.60318,
    "lng": -97.83849,
    "state_id": "TX"
  },
  {
    "zip": 78147,
    "lat": 29.07384,
    "lng": -98.0802,
    "state_id": "TX"
  },
  {
    "zip": 78148,
    "lat": 29.54349,
    "lng": -98.29565,
    "state_id": "TX"
  },
  {
    "zip": 78150,
    "lat": 29.53078,
    "lng": -98.279,
    "state_id": "TX"
  },
  {
    "zip": 78151,
    "lat": 28.86506,
    "lng": -97.70391,
    "state_id": "TX"
  },
  {
    "zip": 78152,
    "lat": 29.43411,
    "lng": -98.20648,
    "state_id": "TX"
  },
  {
    "zip": 78154,
    "lat": 29.54622,
    "lng": -98.26627,
    "state_id": "TX"
  },
  {
    "zip": 78155,
    "lat": 29.53341,
    "lng": -97.93886,
    "state_id": "TX"
  },
  {
    "zip": 78159,
    "lat": 29.23632,
    "lng": -97.58721,
    "state_id": "TX"
  },
  {
    "zip": 78160,
    "lat": 29.24306,
    "lng": -97.92958,
    "state_id": "TX"
  },
  {
    "zip": 78161,
    "lat": 29.28697,
    "lng": -98.05743,
    "state_id": "TX"
  },
  {
    "zip": 78162,
    "lat": 28.58997,
    "lng": -97.77347,
    "state_id": "TX"
  },
  {
    "zip": 78163,
    "lat": 29.76677,
    "lng": -98.45476,
    "state_id": "TX"
  },
  {
    "zip": 78164,
    "lat": 28.98216,
    "lng": -97.52254,
    "state_id": "TX"
  },
  {
    "zip": 78201,
    "lat": 29.46879,
    "lng": -98.52828,
    "state_id": "TX"
  },
  {
    "zip": 78202,
    "lat": 29.42814,
    "lng": -98.46082,
    "state_id": "TX"
  },
  {
    "zip": 78203,
    "lat": 29.41507,
    "lng": -98.45918,
    "state_id": "TX"
  },
  {
    "zip": 78204,
    "lat": 29.4032,
    "lng": -98.50372,
    "state_id": "TX"
  },
  {
    "zip": 78205,
    "lat": 29.42347,
    "lng": -98.48593,
    "state_id": "TX"
  },
  {
    "zip": 78207,
    "lat": 29.42214,
    "lng": -98.52545,
    "state_id": "TX"
  },
  {
    "zip": 78208,
    "lat": 29.43982,
    "lng": -98.45855,
    "state_id": "TX"
  },
  {
    "zip": 78209,
    "lat": 29.48907,
    "lng": -98.4567,
    "state_id": "TX"
  },
  {
    "zip": 78210,
    "lat": 29.39601,
    "lng": -98.46437,
    "state_id": "TX"
  },
  {
    "zip": 78211,
    "lat": 29.34641,
    "lng": -98.56315,
    "state_id": "TX"
  },
  {
    "zip": 78212,
    "lat": 29.46463,
    "lng": -98.49266,
    "state_id": "TX"
  },
  {
    "zip": 78213,
    "lat": 29.51693,
    "lng": -98.52359,
    "state_id": "TX"
  },
  {
    "zip": 78214,
    "lat": 29.32654,
    "lng": -98.46939,
    "state_id": "TX"
  },
  {
    "zip": 78215,
    "lat": 29.44117,
    "lng": -98.48056,
    "state_id": "TX"
  },
  {
    "zip": 78216,
    "lat": 29.53648,
    "lng": -98.48922,
    "state_id": "TX"
  },
  {
    "zip": 78217,
    "lat": 29.54045,
    "lng": -98.42007,
    "state_id": "TX"
  },
  {
    "zip": 78218,
    "lat": 29.49152,
    "lng": -98.40114,
    "state_id": "TX"
  },
  {
    "zip": 78219,
    "lat": 29.44208,
    "lng": -98.38754,
    "state_id": "TX"
  },
  {
    "zip": 78220,
    "lat": 29.41674,
    "lng": -98.39321,
    "state_id": "TX"
  },
  {
    "zip": 78221,
    "lat": 29.28016,
    "lng": -98.47826,
    "state_id": "TX"
  },
  {
    "zip": 78222,
    "lat": 29.36614,
    "lng": -98.37783,
    "state_id": "TX"
  },
  {
    "zip": 78223,
    "lat": 29.3122,
    "lng": -98.39091,
    "state_id": "TX"
  },
  {
    "zip": 78224,
    "lat": 29.29429,
    "lng": -98.53944,
    "state_id": "TX"
  },
  {
    "zip": 78225,
    "lat": 29.38799,
    "lng": -98.52602,
    "state_id": "TX"
  },
  {
    "zip": 78226,
    "lat": 29.38373,
    "lng": -98.56909,
    "state_id": "TX"
  },
  {
    "zip": 78227,
    "lat": 29.40625,
    "lng": -98.62942,
    "state_id": "TX"
  },
  {
    "zip": 78228,
    "lat": 29.46095,
    "lng": -98.57202,
    "state_id": "TX"
  },
  {
    "zip": 78229,
    "lat": 29.50555,
    "lng": -98.57221,
    "state_id": "TX"
  },
  {
    "zip": 78230,
    "lat": 29.54613,
    "lng": -98.55552,
    "state_id": "TX"
  },
  {
    "zip": 78231,
    "lat": 29.57806,
    "lng": -98.5427,
    "state_id": "TX"
  },
  {
    "zip": 78232,
    "lat": 29.58813,
    "lng": -98.47482,
    "state_id": "TX"
  },
  {
    "zip": 78233,
    "lat": 29.55554,
    "lng": -98.36173,
    "state_id": "TX"
  },
  {
    "zip": 78234,
    "lat": 29.46038,
    "lng": -98.43926,
    "state_id": "TX"
  },
  {
    "zip": 78235,
    "lat": 29.34611,
    "lng": -98.44374,
    "state_id": "TX"
  },
  {
    "zip": 78236,
    "lat": 29.3781,
    "lng": -98.64431,
    "state_id": "TX"
  },
  {
    "zip": 78237,
    "lat": 29.42111,
    "lng": -98.56936,
    "state_id": "TX"
  },
  {
    "zip": 78238,
    "lat": 29.47143,
    "lng": -98.61824,
    "state_id": "TX"
  },
  {
    "zip": 78239,
    "lat": 29.51681,
    "lng": -98.36304,
    "state_id": "TX"
  },
  {
    "zip": 78240,
    "lat": 29.52464,
    "lng": -98.60748,
    "state_id": "TX"
  },
  {
    "zip": 78242,
    "lat": 29.35066,
    "lng": -98.60748,
    "state_id": "TX"
  },
  {
    "zip": 78243,
    "lat": 29.37177,
    "lng": -98.59514,
    "state_id": "TX"
  },
  {
    "zip": 78244,
    "lat": 29.47379,
    "lng": -98.35006,
    "state_id": "TX"
  },
  {
    "zip": 78245,
    "lat": 29.40397,
    "lng": -98.73081,
    "state_id": "TX"
  },
  {
    "zip": 78247,
    "lat": 29.58549,
    "lng": -98.40705,
    "state_id": "TX"
  },
  {
    "zip": 78248,
    "lat": 29.59047,
    "lng": -98.52525,
    "state_id": "TX"
  },
  {
    "zip": 78249,
    "lat": 29.56794,
    "lng": -98.61346,
    "state_id": "TX"
  },
  {
    "zip": 78250,
    "lat": 29.50316,
    "lng": -98.66626,
    "state_id": "TX"
  },
  {
    "zip": 78251,
    "lat": 29.46216,
    "lng": -98.67599,
    "state_id": "TX"
  },
  {
    "zip": 78252,
    "lat": 29.34097,
    "lng": -98.70532,
    "state_id": "TX"
  },
  {
    "zip": 78253,
    "lat": 29.46744,
    "lng": -98.7906,
    "state_id": "TX"
  },
  {
    "zip": 78254,
    "lat": 29.5369,
    "lng": -98.73775,
    "state_id": "TX"
  },
  {
    "zip": 78255,
    "lat": 29.65429,
    "lng": -98.66554,
    "state_id": "TX"
  },
  {
    "zip": 78256,
    "lat": 29.62304,
    "lng": -98.62635,
    "state_id": "TX"
  },
  {
    "zip": 78257,
    "lat": 29.66124,
    "lng": -98.58356,
    "state_id": "TX"
  },
  {
    "zip": 78258,
    "lat": 29.63372,
    "lng": -98.49603,
    "state_id": "TX"
  },
  {
    "zip": 78259,
    "lat": 29.6263,
    "lng": -98.42781,
    "state_id": "TX"
  },
  {
    "zip": 78260,
    "lat": 29.69664,
    "lng": -98.48743,
    "state_id": "TX"
  },
  {
    "zip": 78261,
    "lat": 29.69179,
    "lng": -98.40192,
    "state_id": "TX"
  },
  {
    "zip": 78263,
    "lat": 29.35946,
    "lng": -98.30931,
    "state_id": "TX"
  },
  {
    "zip": 78264,
    "lat": 29.18831,
    "lng": -98.50117,
    "state_id": "TX"
  },
  {
    "zip": 78266,
    "lat": 29.65385,
    "lng": -98.33055,
    "state_id": "TX"
  },
  {
    "zip": 78330,
    "lat": 27.74268,
    "lng": -97.91564,
    "state_id": "TX"
  },
  {
    "zip": 78332,
    "lat": 27.71843,
    "lng": -98.14285,
    "state_id": "TX"
  },
  {
    "zip": 78335,
    "lat": 27.87319,
    "lng": -97.09231,
    "state_id": "TX"
  },
  {
    "zip": 78336,
    "lat": 27.94281,
    "lng": -97.18347,
    "state_id": "TX"
  },
  {
    "zip": 78338,
    "lat": 26.89255,
    "lng": -97.76255,
    "state_id": "TX"
  },
  {
    "zip": 78339,
    "lat": 27.80511,
    "lng": -97.79226,
    "state_id": "TX"
  },
  {
    "zip": 78340,
    "lat": 28.12487,
    "lng": -97.21093,
    "state_id": "TX"
  },
  {
    "zip": 78341,
    "lat": 27.62992,
    "lng": -98.48907,
    "state_id": "TX"
  },
  {
    "zip": 78342,
    "lat": 27.65989,
    "lng": -98.07573,
    "state_id": "TX"
  },
  {
    "zip": 78343,
    "lat": 27.6194,
    "lng": -97.74459,
    "state_id": "TX"
  },
  {
    "zip": 78344,
    "lat": 27.48001,
    "lng": -98.86472,
    "state_id": "TX"
  },
  {
    "zip": 78349,
    "lat": 27.37535,
    "lng": -98.29991,
    "state_id": "TX"
  },
  {
    "zip": 78351,
    "lat": 27.67313,
    "lng": -97.75971,
    "state_id": "TX"
  },
  {
    "zip": 78352,
    "lat": 27.96291,
    "lng": -97.67901,
    "state_id": "TX"
  },
  {
    "zip": 78353,
    "lat": 26.88252,
    "lng": -98.22368,
    "state_id": "TX"
  },
  {
    "zip": 78355,
    "lat": 27.19012,
    "lng": -98.20251,
    "state_id": "TX"
  },
  {
    "zip": 78357,
    "lat": 27.84382,
    "lng": -98.6217,
    "state_id": "TX"
  },
  {
    "zip": 78358,
    "lat": 28.06824,
    "lng": -97.04427,
    "state_id": "TX"
  },
  {
    "zip": 78359,
    "lat": 27.92357,
    "lng": -97.28712,
    "state_id": "TX"
  },
  {
    "zip": 78361,
    "lat": 27.10434,
    "lng": -98.78675,
    "state_id": "TX"
  },
  {
    "zip": 78362,
    "lat": 27.86644,
    "lng": -97.20199,
    "state_id": "TX"
  },
  {
    "zip": 78363,
    "lat": 27.44953,
    "lng": -97.86267,
    "state_id": "TX"
  },
  {
    "zip": 78368,
    "lat": 28.10546,
    "lng": -97.8103,
    "state_id": "TX"
  },
  {
    "zip": 78369,
    "lat": 27.36791,
    "lng": -98.99745,
    "state_id": "TX"
  },
  {
    "zip": 78370,
    "lat": 27.94872,
    "lng": -97.60099,
    "state_id": "TX"
  },
  {
    "zip": 78371,
    "lat": 27.62829,
    "lng": -98.95939,
    "state_id": "TX"
  },
  {
    "zip": 78372,
    "lat": 27.98728,
    "lng": -98.0559,
    "state_id": "TX"
  },
  {
    "zip": 78373,
    "lat": 27.77081,
    "lng": -97.12146,
    "state_id": "TX"
  },
  {
    "zip": 78374,
    "lat": 27.89799,
    "lng": -97.37808,
    "state_id": "TX"
  },
  {
    "zip": 78375,
    "lat": 27.39448,
    "lng": -98.14463,
    "state_id": "TX"
  },
  {
    "zip": 78376,
    "lat": 27.41844,
    "lng": -98.51401,
    "state_id": "TX"
  },
  {
    "zip": 78377,
    "lat": 28.35557,
    "lng": -97.15776,
    "state_id": "TX"
  },
  {
    "zip": 78379,
    "lat": 27.30223,
    "lng": -97.79593,
    "state_id": "TX"
  },
  {
    "zip": 78380,
    "lat": 27.78905,
    "lng": -97.74623,
    "state_id": "TX"
  },
  {
    "zip": 78382,
    "lat": 28.14388,
    "lng": -97.05082,
    "state_id": "TX"
  },
  {
    "zip": 78383,
    "lat": 28.09164,
    "lng": -97.96378,
    "state_id": "TX"
  },
  {
    "zip": 78384,
    "lat": 27.85315,
    "lng": -98.37031,
    "state_id": "TX"
  },
  {
    "zip": 78385,
    "lat": 27.17648,
    "lng": -97.82556,
    "state_id": "TX"
  },
  {
    "zip": 78387,
    "lat": 28.0917,
    "lng": -97.54469,
    "state_id": "TX"
  },
  {
    "zip": 78389,
    "lat": 28.24898,
    "lng": -97.66068,
    "state_id": "TX"
  },
  {
    "zip": 78390,
    "lat": 27.9989,
    "lng": -97.32981,
    "state_id": "TX"
  },
  {
    "zip": 78391,
    "lat": 28.18201,
    "lng": -97.73277,
    "state_id": "TX"
  },
  {
    "zip": 78393,
    "lat": 28.19994,
    "lng": -97.36805,
    "state_id": "TX"
  },
  {
    "zip": 78401,
    "lat": 27.79816,
    "lng": -97.40097,
    "state_id": "TX"
  },
  {
    "zip": 78402,
    "lat": 27.82253,
    "lng": -97.3967,
    "state_id": "TX"
  },
  {
    "zip": 78404,
    "lat": 27.76805,
    "lng": -97.39901,
    "state_id": "TX"
  },
  {
    "zip": 78405,
    "lat": 27.77425,
    "lng": -97.43876,
    "state_id": "TX"
  },
  {
    "zip": 78406,
    "lat": 27.77125,
    "lng": -97.51879,
    "state_id": "TX"
  },
  {
    "zip": 78407,
    "lat": 27.81081,
    "lng": -97.43899,
    "state_id": "TX"
  },
  {
    "zip": 78408,
    "lat": 27.795,
    "lng": -97.44693,
    "state_id": "TX"
  },
  {
    "zip": 78409,
    "lat": 27.80942,
    "lng": -97.52284,
    "state_id": "TX"
  },
  {
    "zip": 78410,
    "lat": 27.83848,
    "lng": -97.59655,
    "state_id": "TX"
  },
  {
    "zip": 78411,
    "lat": 27.72946,
    "lng": -97.38567,
    "state_id": "TX"
  },
  {
    "zip": 78412,
    "lat": 27.70386,
    "lng": -97.34302,
    "state_id": "TX"
  },
  {
    "zip": 78413,
    "lat": 27.68352,
    "lng": -97.40538,
    "state_id": "TX"
  },
  {
    "zip": 78414,
    "lat": 27.6603,
    "lng": -97.36893,
    "state_id": "TX"
  },
  {
    "zip": 78415,
    "lat": 27.6566,
    "lng": -97.48182,
    "state_id": "TX"
  },
  {
    "zip": 78416,
    "lat": 27.75202,
    "lng": -97.43651,
    "state_id": "TX"
  },
  {
    "zip": 78417,
    "lat": 27.73965,
    "lng": -97.46681,
    "state_id": "TX"
  },
  {
    "zip": 78418,
    "lat": 27.6155,
    "lng": -97.26563,
    "state_id": "TX"
  },
  {
    "zip": 78419,
    "lat": 27.69491,
    "lng": -97.26889,
    "state_id": "TX"
  },
  {
    "zip": 78501,
    "lat": 26.21583,
    "lng": -98.23917,
    "state_id": "TX"
  },
  {
    "zip": 78503,
    "lat": 26.16531,
    "lng": -98.24764,
    "state_id": "TX"
  },
  {
    "zip": 78504,
    "lat": 26.27199,
    "lng": -98.23657,
    "state_id": "TX"
  },
  {
    "zip": 78516,
    "lat": 26.14583,
    "lng": -98.11938,
    "state_id": "TX"
  },
  {
    "zip": 78520,
    "lat": 25.96396,
    "lng": -97.5501,
    "state_id": "TX"
  },
  {
    "zip": 78521,
    "lat": 25.95038,
    "lng": -97.32168,
    "state_id": "TX"
  },
  {
    "zip": 78526,
    "lat": 25.99101,
    "lng": -97.43946,
    "state_id": "TX"
  },
  {
    "zip": 78535,
    "lat": 26.25421,
    "lng": -97.74282,
    "state_id": "TX"
  },
  {
    "zip": 78536,
    "lat": 26.64926,
    "lng": -98.4511,
    "state_id": "TX"
  },
  {
    "zip": 78537,
    "lat": 26.16192,
    "lng": -98.05669,
    "state_id": "TX"
  },
  {
    "zip": 78538,
    "lat": 26.37275,
    "lng": -97.97821,
    "state_id": "TX"
  },
  {
    "zip": 78539,
    "lat": 26.27915,
    "lng": -98.18321,
    "state_id": "TX"
  },
  {
    "zip": 78541,
    "lat": 26.44809,
    "lng": -98.2842,
    "state_id": "TX"
  },
  {
    "zip": 78542,
    "lat": 26.452,
    "lng": -98.08433,
    "state_id": "TX"
  },
  {
    "zip": 78543,
    "lat": 26.29737,
    "lng": -97.99997,
    "state_id": "TX"
  },
  {
    "zip": 78545,
    "lat": 26.59041,
    "lng": -99.11813,
    "state_id": "TX"
  },
  {
    "zip": 78548,
    "lat": 26.2708,
    "lng": -98.65456,
    "state_id": "TX"
  },
  {
    "zip": 78549,
    "lat": 26.46089,
    "lng": -98.03372,
    "state_id": "TX"
  },
  {
    "zip": 78550,
    "lat": 26.25668,
    "lng": -97.66671,
    "state_id": "TX"
  },
  {
    "zip": 78552,
    "lat": 26.19515,
    "lng": -97.75025,
    "state_id": "TX"
  },
  {
    "zip": 78557,
    "lat": 26.10688,
    "lng": -98.24342,
    "state_id": "TX"
  },
  {
    "zip": 78558,
    "lat": 26.29962,
    "lng": -98.03525,
    "state_id": "TX"
  },
  {
    "zip": 78559,
    "lat": 26.14827,
    "lng": -97.83136,
    "state_id": "TX"
  },
  {
    "zip": 78560,
    "lat": 26.24615,
    "lng": -98.49434,
    "state_id": "TX"
  },
  {
    "zip": 78561,
    "lat": 26.47803,
    "lng": -97.91813,
    "state_id": "TX"
  },
  {
    "zip": 78562,
    "lat": 26.29361,
    "lng": -97.89885,
    "state_id": "TX"
  },
  {
    "zip": 78563,
    "lat": 26.65735,
    "lng": -98.25679,
    "state_id": "TX"
  },
  {
    "zip": 78564,
    "lat": 26.67347,
    "lng": -99.10303,
    "state_id": "TX"
  },
  {
    "zip": 78565,
    "lat": 26.24351,
    "lng": -98.55199,
    "state_id": "TX"
  },
  {
    "zip": 78566,
    "lat": 26.14669,
    "lng": -97.40332,
    "state_id": "TX"
  },
  {
    "zip": 78567,
    "lat": 26.05192,
    "lng": -97.73932,
    "state_id": "TX"
  },
  {
    "zip": 78569,
    "lat": 26.37181,
    "lng": -97.74315,
    "state_id": "TX"
  },
  {
    "zip": 78570,
    "lat": 26.17478,
    "lng": -97.91093,
    "state_id": "TX"
  },
  {
    "zip": 78572,
    "lat": 26.2281,
    "lng": -98.37666,
    "state_id": "TX"
  },
  {
    "zip": 78573,
    "lat": 26.29368,
    "lng": -98.30077,
    "state_id": "TX"
  },
  {
    "zip": 78574,
    "lat": 26.31893,
    "lng": -98.37005,
    "state_id": "TX"
  },
  {
    "zip": 78575,
    "lat": 26.02476,
    "lng": -97.52767,
    "state_id": "TX"
  },
  {
    "zip": 78576,
    "lat": 26.30716,
    "lng": -98.47604,
    "state_id": "TX"
  },
  {
    "zip": 78577,
    "lat": 26.15047,
    "lng": -98.19104,
    "state_id": "TX"
  },
  {
    "zip": 78578,
    "lat": 26.05308,
    "lng": -97.31838,
    "state_id": "TX"
  },
  {
    "zip": 78579,
    "lat": 26.08664,
    "lng": -97.95966,
    "state_id": "TX"
  },
  {
    "zip": 78580,
    "lat": 26.48667,
    "lng": -97.73791,
    "state_id": "TX"
  },
  {
    "zip": 78582,
    "lat": 26.55556,
    "lng": -98.73564,
    "state_id": "TX"
  },
  {
    "zip": 78583,
    "lat": 26.26419,
    "lng": -97.48614,
    "state_id": "TX"
  },
  {
    "zip": 78584,
    "lat": 26.57855,
    "lng": -98.98754,
    "state_id": "TX"
  },
  {
    "zip": 78585,
    "lat": 26.52603,
    "lng": -99.12133,
    "state_id": "TX"
  },
  {
    "zip": 78586,
    "lat": 26.10508,
    "lng": -97.63108,
    "state_id": "TX"
  },
  {
    "zip": 78588,
    "lat": 26.73785,
    "lng": -98.39723,
    "state_id": "TX"
  },
  {
    "zip": 78589,
    "lat": 26.16789,
    "lng": -98.15483,
    "state_id": "TX"
  },
  {
    "zip": 78590,
    "lat": 26.50603,
    "lng": -97.6523,
    "state_id": "TX"
  },
  {
    "zip": 78591,
    "lat": 26.58074,
    "lng": -98.51372,
    "state_id": "TX"
  },
  {
    "zip": 78592,
    "lat": 26.06523,
    "lng": -97.84603,
    "state_id": "TX"
  },
  {
    "zip": 78593,
    "lat": 26.27385,
    "lng": -97.82225,
    "state_id": "TX"
  },
  {
    "zip": 78594,
    "lat": 26.34519,
    "lng": -97.79489,
    "state_id": "TX"
  },
  {
    "zip": 78595,
    "lat": 26.29144,
    "lng": -98.54548,
    "state_id": "TX"
  },
  {
    "zip": 78596,
    "lat": 26.15806,
    "lng": -97.98925,
    "state_id": "TX"
  },
  {
    "zip": 78597,
    "lat": 26.2491,
    "lng": -97.19744,
    "state_id": "TX"
  },
  {
    "zip": 78598,
    "lat": 26.55778,
    "lng": -97.4368,
    "state_id": "TX"
  },
  {
    "zip": 78602,
    "lat": 30.12383,
    "lng": -97.32841,
    "state_id": "TX"
  },
  {
    "zip": 78605,
    "lat": 30.7822,
    "lng": -98.04563,
    "state_id": "TX"
  },
  {
    "zip": 78606,
    "lat": 30.0926,
    "lng": -98.46682,
    "state_id": "TX"
  },
  {
    "zip": 78607,
    "lat": 30.82439,
    "lng": -98.47564,
    "state_id": "TX"
  },
  {
    "zip": 78608,
    "lat": 30.93527,
    "lng": -97.93381,
    "state_id": "TX"
  },
  {
    "zip": 78609,
    "lat": 30.76314,
    "lng": -98.44875,
    "state_id": "TX"
  },
  {
    "zip": 78610,
    "lat": 30.07722,
    "lng": -97.83741,
    "state_id": "TX"
  },
  {
    "zip": 78611,
    "lat": 30.79177,
    "lng": -98.26018,
    "state_id": "TX"
  },
  {
    "zip": 78612,
    "lat": 30.0942,
    "lng": -97.48686,
    "state_id": "TX"
  },
  {
    "zip": 78613,
    "lat": 30.50395,
    "lng": -97.82462,
    "state_id": "TX"
  },
  {
    "zip": 78614,
    "lat": 29.41742,
    "lng": -97.59347,
    "state_id": "TX"
  },
  {
    "zip": 78615,
    "lat": 30.46862,
    "lng": -97.38362,
    "state_id": "TX"
  },
  {
    "zip": 78616,
    "lat": 29.94016,
    "lng": -97.56199,
    "state_id": "TX"
  },
  {
    "zip": 78617,
    "lat": 30.14719,
    "lng": -97.59614,
    "state_id": "TX"
  },
  {
    "zip": 78618,
    "lat": 30.47773,
    "lng": -99.16514,
    "state_id": "TX"
  },
  {
    "zip": 78619,
    "lat": 30.11222,
    "lng": -98.03327,
    "state_id": "TX"
  },
  {
    "zip": 78620,
    "lat": 30.21895,
    "lng": -98.12894,
    "state_id": "TX"
  },
  {
    "zip": 78621,
    "lat": 30.3385,
    "lng": -97.36145,
    "state_id": "TX"
  },
  {
    "zip": 78622,
    "lat": 29.75402,
    "lng": -97.77606,
    "state_id": "TX"
  },
  {
    "zip": 78623,
    "lat": 29.97297,
    "lng": -98.23303,
    "state_id": "TX"
  },
  {
    "zip": 78624,
    "lat": 30.28151,
    "lng": -98.87712,
    "state_id": "TX"
  },
  {
    "zip": 78626,
    "lat": 30.66539,
    "lng": -97.59735,
    "state_id": "TX"
  },
  {
    "zip": 78628,
    "lat": 30.64104,
    "lng": -97.75111,
    "state_id": "TX"
  },
  {
    "zip": 78629,
    "lat": 29.47723,
    "lng": -97.4517,
    "state_id": "TX"
  },
  {
    "zip": 78631,
    "lat": 30.33775,
    "lng": -99.3053,
    "state_id": "TX"
  },
  {
    "zip": 78632,
    "lat": 29.69632,
    "lng": -97.47048,
    "state_id": "TX"
  },
  {
    "zip": 78633,
    "lat": 30.74198,
    "lng": -97.75432,
    "state_id": "TX"
  },
  {
    "zip": 78634,
    "lat": 30.55906,
    "lng": -97.54471,
    "state_id": "TX"
  },
  {
    "zip": 78635,
    "lat": 30.21089,
    "lng": -98.55961,
    "state_id": "TX"
  },
  {
    "zip": 78636,
    "lat": 30.30503,
    "lng": -98.41888,
    "state_id": "TX"
  },
  {
    "zip": 78638,
    "lat": 29.65691,
    "lng": -97.79496,
    "state_id": "TX"
  },
  {
    "zip": 78639,
    "lat": 30.66283,
    "lng": -98.44051,
    "state_id": "TX"
  },
  {
    "zip": 78640,
    "lat": 29.99495,
    "lng": -97.82813,
    "state_id": "TX"
  },
  {
    "zip": 78641,
    "lat": 30.56175,
    "lng": -97.91516,
    "state_id": "TX"
  },
  {
    "zip": 78642,
    "lat": 30.69726,
    "lng": -97.94692,
    "state_id": "TX"
  },
  {
    "zip": 78643,
    "lat": 30.69024,
    "lng": -98.69483,
    "state_id": "TX"
  },
  {
    "zip": 78644,
    "lat": 29.87049,
    "lng": -97.67527,
    "state_id": "TX"
  },
  {
    "zip": 78645,
    "lat": 30.44902,
    "lng": -97.96999,
    "state_id": "TX"
  },
  {
    "zip": 78648,
    "lat": 29.69106,
    "lng": -97.6345,
    "state_id": "TX"
  },
  {
    "zip": 78650,
    "lat": 30.29937,
    "lng": -97.2184,
    "state_id": "TX"
  },
  {
    "zip": 78652,
    "lat": 30.13281,
    "lng": -97.87467,
    "state_id": "TX"
  },
  {
    "zip": 78653,
    "lat": 30.33941,
    "lng": -97.52363,
    "state_id": "TX"
  },
  {
    "zip": 78654,
    "lat": 30.57233,
    "lng": -98.20735,
    "state_id": "TX"
  },
  {
    "zip": 78655,
    "lat": 29.81446,
    "lng": -97.84018,
    "state_id": "TX"
  },
  {
    "zip": 78656,
    "lat": 29.90047,
    "lng": -97.80868,
    "state_id": "TX"
  },
  {
    "zip": 78657,
    "lat": 30.53098,
    "lng": -98.37775,
    "state_id": "TX"
  },
  {
    "zip": 78659,
    "lat": 30.20888,
    "lng": -97.12359,
    "state_id": "TX"
  },
  {
    "zip": 78660,
    "lat": 30.44304,
    "lng": -97.59548,
    "state_id": "TX"
  },
  {
    "zip": 78661,
    "lat": 29.72559,
    "lng": -97.75364,
    "state_id": "TX"
  },
  {
    "zip": 78662,
    "lat": 29.93523,
    "lng": -97.43421,
    "state_id": "TX"
  },
  {
    "zip": 78663,
    "lat": 30.42067,
    "lng": -98.33532,
    "state_id": "TX"
  },
  {
    "zip": 78664,
    "lat": 30.50479,
    "lng": -97.64577,
    "state_id": "TX"
  },
  {
    "zip": 78665,
    "lat": 30.54488,
    "lng": -97.64378,
    "state_id": "TX"
  },
  {
    "zip": 78666,
    "lat": 29.87958,
    "lng": -97.96642,
    "state_id": "TX"
  },
  {
    "zip": 78669,
    "lat": 30.42264,
    "lng": -98.12244,
    "state_id": "TX"
  },
  {
    "zip": 78670,
    "lat": 29.77133,
    "lng": -97.82003,
    "state_id": "TX"
  },
  {
    "zip": 78671,
    "lat": 30.21635,
    "lng": -98.62376,
    "state_id": "TX"
  },
  {
    "zip": 78672,
    "lat": 30.85966,
    "lng": -98.4711,
    "state_id": "TX"
  },
  {
    "zip": 78675,
    "lat": 30.44589,
    "lng": -98.68497,
    "state_id": "TX"
  },
  {
    "zip": 78676,
    "lat": 30.03286,
    "lng": -98.14588,
    "state_id": "TX"
  },
  {
    "zip": 78677,
    "lat": 29.34997,
    "lng": -97.58021,
    "state_id": "TX"
  },
  {
    "zip": 78681,
    "lat": 30.53328,
    "lng": -97.7247,
    "state_id": "TX"
  },
  {
    "zip": 78701,
    "lat": 30.27049,
    "lng": -97.74235,
    "state_id": "TX"
  },
  {
    "zip": 78702,
    "lat": 30.26327,
    "lng": -97.71432,
    "state_id": "TX"
  },
  {
    "zip": 78703,
    "lat": 30.29409,
    "lng": -97.76571,
    "state_id": "TX"
  },
  {
    "zip": 78704,
    "lat": 30.24316,
    "lng": -97.76536,
    "state_id": "TX"
  },
  {
    "zip": 78705,
    "lat": 30.29437,
    "lng": -97.73855,
    "state_id": "TX"
  },
  {
    "zip": 78712,
    "lat": 30.28502,
    "lng": -97.73477,
    "state_id": "TX"
  },
  {
    "zip": 78717,
    "lat": 30.4899,
    "lng": -97.75398,
    "state_id": "TX"
  },
  {
    "zip": 78719,
    "lat": 30.14483,
    "lng": -97.67083,
    "state_id": "TX"
  },
  {
    "zip": 78721,
    "lat": 30.27005,
    "lng": -97.68365,
    "state_id": "TX"
  },
  {
    "zip": 78722,
    "lat": 30.28997,
    "lng": -97.71465,
    "state_id": "TX"
  },
  {
    "zip": 78723,
    "lat": 30.30427,
    "lng": -97.6857,
    "state_id": "TX"
  },
  {
    "zip": 78724,
    "lat": 30.2944,
    "lng": -97.61415,
    "state_id": "TX"
  },
  {
    "zip": 78725,
    "lat": 30.23581,
    "lng": -97.60837,
    "state_id": "TX"
  },
  {
    "zip": 78726,
    "lat": 30.42949,
    "lng": -97.84206,
    "state_id": "TX"
  },
  {
    "zip": 78727,
    "lat": 30.4295,
    "lng": -97.71743,
    "state_id": "TX"
  },
  {
    "zip": 78728,
    "lat": 30.45654,
    "lng": -97.68986,
    "state_id": "TX"
  },
  {
    "zip": 78729,
    "lat": 30.45842,
    "lng": -97.75595,
    "state_id": "TX"
  },
  {
    "zip": 78730,
    "lat": 30.36489,
    "lng": -97.83731,
    "state_id": "TX"
  },
  {
    "zip": 78731,
    "lat": 30.34736,
    "lng": -97.76847,
    "state_id": "TX"
  },
  {
    "zip": 78732,
    "lat": 30.37913,
    "lng": -97.89309,
    "state_id": "TX"
  },
  {
    "zip": 78733,
    "lat": 30.32323,
    "lng": -97.87609,
    "state_id": "TX"
  },
  {
    "zip": 78734,
    "lat": 30.37853,
    "lng": -97.94961,
    "state_id": "TX"
  },
  {
    "zip": 78735,
    "lat": 30.26591,
    "lng": -97.86659,
    "state_id": "TX"
  },
  {
    "zip": 78736,
    "lat": 30.26109,
    "lng": -97.95944,
    "state_id": "TX"
  },
  {
    "zip": 78737,
    "lat": 30.1878,
    "lng": -97.95965,
    "state_id": "TX"
  },
  {
    "zip": 78738,
    "lat": 30.31942,
    "lng": -97.95838,
    "state_id": "TX"
  },
  {
    "zip": 78739,
    "lat": 30.17844,
    "lng": -97.88868,
    "state_id": "TX"
  },
  {
    "zip": 78741,
    "lat": 30.23049,
    "lng": -97.71401,
    "state_id": "TX"
  },
  {
    "zip": 78742,
    "lat": 30.24413,
    "lng": -97.6583,
    "state_id": "TX"
  },
  {
    "zip": 78744,
    "lat": 30.18276,
    "lng": -97.7292,
    "state_id": "TX"
  },
  {
    "zip": 78745,
    "lat": 30.20686,
    "lng": -97.79738,
    "state_id": "TX"
  },
  {
    "zip": 78746,
    "lat": 30.29729,
    "lng": -97.81054,
    "state_id": "TX"
  },
  {
    "zip": 78747,
    "lat": 30.12652,
    "lng": -97.74017,
    "state_id": "TX"
  },
  {
    "zip": 78748,
    "lat": 30.16538,
    "lng": -97.82343,
    "state_id": "TX"
  },
  {
    "zip": 78749,
    "lat": 30.21378,
    "lng": -97.85819,
    "state_id": "TX"
  },
  {
    "zip": 78750,
    "lat": 30.41827,
    "lng": -97.80246,
    "state_id": "TX"
  },
  {
    "zip": 78751,
    "lat": 30.31082,
    "lng": -97.72275,
    "state_id": "TX"
  },
  {
    "zip": 78752,
    "lat": 30.33181,
    "lng": -97.70425,
    "state_id": "TX"
  },
  {
    "zip": 78753,
    "lat": 30.38205,
    "lng": -97.67361,
    "state_id": "TX"
  },
  {
    "zip": 78754,
    "lat": 30.35574,
    "lng": -97.64483,
    "state_id": "TX"
  },
  {
    "zip": 78756,
    "lat": 30.32227,
    "lng": -97.74017,
    "state_id": "TX"
  },
  {
    "zip": 78757,
    "lat": 30.35158,
    "lng": -97.73252,
    "state_id": "TX"
  },
  {
    "zip": 78758,
    "lat": 30.388,
    "lng": -97.70682,
    "state_id": "TX"
  },
  {
    "zip": 78759,
    "lat": 30.40266,
    "lng": -97.76104,
    "state_id": "TX"
  },
  {
    "zip": 78801,
    "lat": 29.35664,
    "lng": -99.87148,
    "state_id": "TX"
  },
  {
    "zip": 78802,
    "lat": 29.15506,
    "lng": -99.88181,
    "state_id": "TX"
  },
  {
    "zip": 78827,
    "lat": 28.42466,
    "lng": -99.73537,
    "state_id": "TX"
  },
  {
    "zip": 78828,
    "lat": 29.86442,
    "lng": -100.01737,
    "state_id": "TX"
  },
  {
    "zip": 78829,
    "lat": 28.88992,
    "lng": -99.5591,
    "state_id": "TX"
  },
  {
    "zip": 78830,
    "lat": 28.52537,
    "lng": -99.50854,
    "state_id": "TX"
  },
  {
    "zip": 78832,
    "lat": 29.39072,
    "lng": -100.41624,
    "state_id": "TX"
  },
  {
    "zip": 78833,
    "lat": 29.69362,
    "lng": -100.05887,
    "state_id": "TX"
  },
  {
    "zip": 78834,
    "lat": 28.4643,
    "lng": -99.92141,
    "state_id": "TX"
  },
  {
    "zip": 78836,
    "lat": 28.29633,
    "lng": -99.70453,
    "state_id": "TX"
  },
  {
    "zip": 78837,
    "lat": 29.83396,
    "lng": -101.21496,
    "state_id": "TX"
  },
  {
    "zip": 78838,
    "lat": 29.5291,
    "lng": -99.74382,
    "state_id": "TX"
  },
  {
    "zip": 78839,
    "lat": 28.71396,
    "lng": -99.8165,
    "state_id": "TX"
  },
  {
    "zip": 78840,
    "lat": 29.74209,
    "lng": -100.88273,
    "state_id": "TX"
  },
  {
    "zip": 78843,
    "lat": 29.35687,
    "lng": -100.77863,
    "state_id": "TX"
  },
  {
    "zip": 78850,
    "lat": 29.28023,
    "lng": -99.32761,
    "state_id": "TX"
  },
  {
    "zip": 78851,
    "lat": 30.00451,
    "lng": -101.93072,
    "state_id": "TX"
  },
  {
    "zip": 78852,
    "lat": 28.75206,
    "lng": -100.31076,
    "state_id": "TX"
  },
  {
    "zip": 78860,
    "lat": 28.48238,
    "lng": -100.29501,
    "state_id": "TX"
  },
  {
    "zip": 78861,
    "lat": 29.42672,
    "lng": -99.15448,
    "state_id": "TX"
  },
  {
    "zip": 78870,
    "lat": 29.29912,
    "lng": -99.62906,
    "state_id": "TX"
  },
  {
    "zip": 78871,
    "lat": 29.99927,
    "lng": -101.64943,
    "state_id": "TX"
  },
  {
    "zip": 78872,
    "lat": 28.92588,
    "lng": -99.88007,
    "state_id": "TX"
  },
  {
    "zip": 78873,
    "lat": 29.76655,
    "lng": -99.73616,
    "state_id": "TX"
  },
  {
    "zip": 78877,
    "lat": 28.94734,
    "lng": -100.59231,
    "state_id": "TX"
  },
  {
    "zip": 78879,
    "lat": 29.64778,
    "lng": -99.67588,
    "state_id": "TX"
  },
  {
    "zip": 78880,
    "lat": 29.97978,
    "lng": -100.28849,
    "state_id": "TX"
  },
  {
    "zip": 78881,
    "lat": 29.38695,
    "lng": -99.52964,
    "state_id": "TX"
  },
  {
    "zip": 78883,
    "lat": 29.68094,
    "lng": -99.31614,
    "state_id": "TX"
  },
  {
    "zip": 78884,
    "lat": 29.61298,
    "lng": -99.49587,
    "state_id": "TX"
  },
  {
    "zip": 78885,
    "lat": 29.82233,
    "lng": -99.54779,
    "state_id": "TX"
  },
  {
    "zip": 78886,
    "lat": 29.13466,
    "lng": -99.16073,
    "state_id": "TX"
  },
  {
    "zip": 78931,
    "lat": 30.02924,
    "lng": -96.44481,
    "state_id": "TX"
  },
  {
    "zip": 78932,
    "lat": 30.16569,
    "lng": -96.70634,
    "state_id": "TX"
  },
  {
    "zip": 78933,
    "lat": 29.7954,
    "lng": -96.35761,
    "state_id": "TX"
  },
  {
    "zip": 78934,
    "lat": 29.69373,
    "lng": -96.56014,
    "state_id": "TX"
  },
  {
    "zip": 78935,
    "lat": 29.69036,
    "lng": -96.43035,
    "state_id": "TX"
  },
  {
    "zip": 78938,
    "lat": 29.80211,
    "lng": -96.69061,
    "state_id": "TX"
  },
  {
    "zip": 78940,
    "lat": 29.93147,
    "lng": -96.66018,
    "state_id": "TX"
  },
  {
    "zip": 78941,
    "lat": 29.74274,
    "lng": -97.15231,
    "state_id": "TX"
  },
  {
    "zip": 78942,
    "lat": 30.16901,
    "lng": -96.92221,
    "state_id": "TX"
  },
  {
    "zip": 78943,
    "lat": 29.71058,
    "lng": -96.61182,
    "state_id": "TX"
  },
  {
    "zip": 78944,
    "lat": 30.00044,
    "lng": -96.498,
    "state_id": "TX"
  },
  {
    "zip": 78945,
    "lat": 29.91566,
    "lng": -96.88949,
    "state_id": "TX"
  },
  {
    "zip": 78946,
    "lat": 30.19124,
    "lng": -96.7709,
    "state_id": "TX"
  },
  {
    "zip": 78947,
    "lat": 30.42088,
    "lng": -97.03765,
    "state_id": "TX"
  },
  {
    "zip": 78948,
    "lat": 30.303,
    "lng": -96.962,
    "state_id": "TX"
  },
  {
    "zip": 78949,
    "lat": 29.84134,
    "lng": -97.08594,
    "state_id": "TX"
  },
  {
    "zip": 78950,
    "lat": 29.90255,
    "lng": -96.48194,
    "state_id": "TX"
  },
  {
    "zip": 78951,
    "lat": 29.58727,
    "lng": -96.82436,
    "state_id": "TX"
  },
  {
    "zip": 78953,
    "lat": 29.8522,
    "lng": -97.3483,
    "state_id": "TX"
  },
  {
    "zip": 78954,
    "lat": 30.04587,
    "lng": -96.69959,
    "state_id": "TX"
  },
  {
    "zip": 78956,
    "lat": 29.68793,
    "lng": -96.93416,
    "state_id": "TX"
  },
  {
    "zip": 78957,
    "lat": 30.01287,
    "lng": -97.17578,
    "state_id": "TX"
  },
  {
    "zip": 78959,
    "lat": 29.69777,
    "lng": -97.30285,
    "state_id": "TX"
  },
  {
    "zip": 78962,
    "lat": 29.65419,
    "lng": -96.73556,
    "state_id": "TX"
  },
  {
    "zip": 78963,
    "lat": 29.92619,
    "lng": -97.02796,
    "state_id": "TX"
  },
  {
    "zip": 79001,
    "lat": 35.32995,
    "lng": -102.78511,
    "state_id": "TX"
  },
  {
    "zip": 79003,
    "lat": 35.58007,
    "lng": -100.09466,
    "state_id": "TX"
  },
  {
    "zip": 79005,
    "lat": 36.38545,
    "lng": -100.50397,
    "state_id": "TX"
  },
  {
    "zip": 79007,
    "lat": 35.69978,
    "lng": -101.39791,
    "state_id": "TX"
  },
  {
    "zip": 79009,
    "lat": 34.53322,
    "lng": -102.88568,
    "state_id": "TX"
  },
  {
    "zip": 79010,
    "lat": 35.44484,
    "lng": -102.12361,
    "state_id": "TX"
  },
  {
    "zip": 79011,
    "lat": 35.63469,
    "lng": -100.22942,
    "state_id": "TX"
  },
  {
    "zip": 79012,
    "lat": 35.26652,
    "lng": -102.10915,
    "state_id": "TX"
  },
  {
    "zip": 79013,
    "lat": 36.03841,
    "lng": -102.00344,
    "state_id": "TX"
  },
  {
    "zip": 79014,
    "lat": 35.91222,
    "lng": -100.29813,
    "state_id": "TX"
  },
  {
    "zip": 79015,
    "lat": 34.94032,
    "lng": -101.91647,
    "state_id": "TX"
  },
  {
    "zip": 79016,
    "lat": 34.98342,
    "lng": -101.91867,
    "state_id": "TX"
  },
  {
    "zip": 79018,
    "lat": 35.69549,
    "lng": -102.38629,
    "state_id": "TX"
  },
  {
    "zip": 79019,
    "lat": 35.00736,
    "lng": -101.39696,
    "state_id": "TX"
  },
  {
    "zip": 79021,
    "lat": 33.98484,
    "lng": -102.02773,
    "state_id": "TX"
  },
  {
    "zip": 79022,
    "lat": 36.10661,
    "lng": -102.61107,
    "state_id": "TX"
  },
  {
    "zip": 79024,
    "lat": 36.43867,
    "lng": -100.34722,
    "state_id": "TX"
  },
  {
    "zip": 79025,
    "lat": 34.94098,
    "lng": -102.2059,
    "state_id": "TX"
  },
  {
    "zip": 79027,
    "lat": 34.48691,
    "lng": -102.33088,
    "state_id": "TX"
  },
  {
    "zip": 79029,
    "lat": 35.89512,
    "lng": -102.02431,
    "state_id": "TX"
  },
  {
    "zip": 79031,
    "lat": 34.26917,
    "lng": -102.45283,
    "state_id": "TX"
  },
  {
    "zip": 79032,
    "lat": 34.28289,
    "lng": -101.94141,
    "state_id": "TX"
  },
  {
    "zip": 79033,
    "lat": 36.33008,
    "lng": -100.98394,
    "state_id": "TX"
  },
  {
    "zip": 79034,
    "lat": 36.37348,
    "lng": -100.16502,
    "state_id": "TX"
  },
  {
    "zip": 79035,
    "lat": 34.68446,
    "lng": -102.78723,
    "state_id": "TX"
  },
  {
    "zip": 79036,
    "lat": 35.61317,
    "lng": -101.54855,
    "state_id": "TX"
  },
  {
    "zip": 79039,
    "lat": 35.26038,
    "lng": -101.09519,
    "state_id": "TX"
  },
  {
    "zip": 79040,
    "lat": 36.29869,
    "lng": -101.5571,
    "state_id": "TX"
  },
  {
    "zip": 79041,
    "lat": 34.0477,
    "lng": -101.9313,
    "state_id": "TX"
  },
  {
    "zip": 79042,
    "lat": 34.74685,
    "lng": -101.90313,
    "state_id": "TX"
  },
  {
    "zip": 79043,
    "lat": 34.37049,
    "lng": -102.13485,
    "state_id": "TX"
  },
  {
    "zip": 79044,
    "lat": 35.87299,
    "lng": -102.36703,
    "state_id": "TX"
  },
  {
    "zip": 79045,
    "lat": 34.91238,
    "lng": -102.55902,
    "state_id": "TX"
  },
  {
    "zip": 79046,
    "lat": 36.16345,
    "lng": -100.1216,
    "state_id": "TX"
  },
  {
    "zip": 79051,
    "lat": 36.47743,
    "lng": -102.26,
    "state_id": "TX"
  },
  {
    "zip": 79052,
    "lat": 34.36101,
    "lng": -101.74252,
    "state_id": "TX"
  },
  {
    "zip": 79053,
    "lat": 34.40739,
    "lng": -102.58601,
    "state_id": "TX"
  },
  {
    "zip": 79054,
    "lat": 35.42585,
    "lng": -100.7875,
    "state_id": "TX"
  },
  {
    "zip": 79056,
    "lat": 36.21717,
    "lng": -100.22121,
    "state_id": "TX"
  },
  {
    "zip": 79057,
    "lat": 35.24303,
    "lng": -100.6426,
    "state_id": "TX"
  },
  {
    "zip": 79058,
    "lat": 35.6164,
    "lng": -101.9076,
    "state_id": "TX"
  },
  {
    "zip": 79059,
    "lat": 35.83018,
    "lng": -100.76789,
    "state_id": "TX"
  },
  {
    "zip": 79061,
    "lat": 35.52039,
    "lng": -100.44182,
    "state_id": "TX"
  },
  {
    "zip": 79062,
    "lat": 36.03137,
    "lng": -101.52535,
    "state_id": "TX"
  },
  {
    "zip": 79063,
    "lat": 34.52584,
    "lng": -102.10042,
    "state_id": "TX"
  },
  {
    "zip": 79064,
    "lat": 34.19816,
    "lng": -102.12113,
    "state_id": "TX"
  },
  {
    "zip": 79065,
    "lat": 35.54207,
    "lng": -100.8998,
    "state_id": "TX"
  },
  {
    "zip": 79068,
    "lat": 35.34375,
    "lng": -101.43649,
    "state_id": "TX"
  },
  {
    "zip": 79070,
    "lat": 36.27683,
    "lng": -100.79875,
    "state_id": "TX"
  },
  {
    "zip": 79072,
    "lat": 34.17158,
    "lng": -101.73863,
    "state_id": "TX"
  },
  {
    "zip": 79078,
    "lat": 35.70051,
    "lng": -101.55828,
    "state_id": "TX"
  },
  {
    "zip": 79079,
    "lat": 35.20904,
    "lng": -100.24313,
    "state_id": "TX"
  },
  {
    "zip": 79080,
    "lat": 35.7348,
    "lng": -101.21075,
    "state_id": "TX"
  },
  {
    "zip": 79081,
    "lat": 36.2301,
    "lng": -101.21809,
    "state_id": "TX"
  },
  {
    "zip": 79082,
    "lat": 34.22431,
    "lng": -102.28774,
    "state_id": "TX"
  },
  {
    "zip": 79083,
    "lat": 35.88431,
    "lng": -101.52804,
    "state_id": "TX"
  },
  {
    "zip": 79084,
    "lat": 36.2801,
    "lng": -102.00052,
    "state_id": "TX"
  },
  {
    "zip": 79085,
    "lat": 34.74073,
    "lng": -102.51441,
    "state_id": "TX"
  },
  {
    "zip": 79086,
    "lat": 36.03844,
    "lng": -101.7741,
    "state_id": "TX"
  },
  {
    "zip": 79087,
    "lat": 36.30895,
    "lng": -102.93592,
    "state_id": "TX"
  },
  {
    "zip": 79088,
    "lat": 34.57853,
    "lng": -101.69537,
    "state_id": "TX"
  },
  {
    "zip": 79091,
    "lat": 34.90633,
    "lng": -102.11251,
    "state_id": "TX"
  },
  {
    "zip": 79092,
    "lat": 35.2815,
    "lng": -102.46868,
    "state_id": "TX"
  },
  {
    "zip": 79093,
    "lat": 36.28992,
    "lng": -101.03396,
    "state_id": "TX"
  },
  {
    "zip": 79094,
    "lat": 34.85075,
    "lng": -101.52674,
    "state_id": "TX"
  },
  {
    "zip": 79095,
    "lat": 34.89616,
    "lng": -100.2061,
    "state_id": "TX"
  },
  {
    "zip": 79096,
    "lat": 35.4381,
    "lng": -100.19611,
    "state_id": "TX"
  },
  {
    "zip": 79097,
    "lat": 35.43582,
    "lng": -101.17852,
    "state_id": "TX"
  },
  {
    "zip": 79098,
    "lat": 35.15532,
    "lng": -102.22841,
    "state_id": "TX"
  },
  {
    "zip": 79101,
    "lat": 35.20599,
    "lng": -101.8395,
    "state_id": "TX"
  },
  {
    "zip": 79102,
    "lat": 35.19953,
    "lng": -101.84676,
    "state_id": "TX"
  },
  {
    "zip": 79103,
    "lat": 35.17735,
    "lng": -101.79438,
    "state_id": "TX"
  },
  {
    "zip": 79104,
    "lat": 35.20276,
    "lng": -101.78859,
    "state_id": "TX"
  },
  {
    "zip": 79105,
    "lat": 35.6883,
    "lng": -101.81163,
    "state_id": "TX"
  },
  {
    "zip": 79106,
    "lat": 35.20317,
    "lng": -101.89517,
    "state_id": "TX"
  },
  {
    "zip": 79107,
    "lat": 35.22939,
    "lng": -101.80227,
    "state_id": "TX"
  },
  {
    "zip": 79108,
    "lat": 35.35066,
    "lng": -101.80584,
    "state_id": "TX"
  },
  {
    "zip": 79109,
    "lat": 35.16631,
    "lng": -101.88646,
    "state_id": "TX"
  },
  {
    "zip": 79110,
    "lat": 35.14901,
    "lng": -101.87111,
    "state_id": "TX"
  },
  {
    "zip": 79111,
    "lat": 35.21967,
    "lng": -101.6893,
    "state_id": "TX"
  },
  {
    "zip": 79118,
    "lat": 35.09861,
    "lng": -101.73879,
    "state_id": "TX"
  },
  {
    "zip": 79119,
    "lat": 35.11029,
    "lng": -102.01663,
    "state_id": "TX"
  },
  {
    "zip": 79121,
    "lat": 35.17471,
    "lng": -101.92969,
    "state_id": "TX"
  },
  {
    "zip": 79124,
    "lat": 35.26275,
    "lng": -101.9691,
    "state_id": "TX"
  },
  {
    "zip": 79201,
    "lat": 34.43715,
    "lng": -100.28085,
    "state_id": "TX"
  },
  {
    "zip": 79220,
    "lat": 33.75776,
    "lng": -100.78642,
    "state_id": "TX"
  },
  {
    "zip": 79225,
    "lat": 34.2563,
    "lng": -99.51496,
    "state_id": "TX"
  },
  {
    "zip": 79226,
    "lat": 34.90323,
    "lng": -100.99525,
    "state_id": "TX"
  },
  {
    "zip": 79227,
    "lat": 33.89957,
    "lng": -99.77068,
    "state_id": "TX"
  },
  {
    "zip": 79229,
    "lat": 33.66676,
    "lng": -100.67825,
    "state_id": "TX"
  },
  {
    "zip": 79230,
    "lat": 34.72708,
    "lng": -100.05157,
    "state_id": "TX"
  },
  {
    "zip": 79231,
    "lat": 33.90634,
    "lng": -101.07003,
    "state_id": "TX"
  },
  {
    "zip": 79233,
    "lat": 34.52345,
    "lng": -100.52865,
    "state_id": "TX"
  },
  {
    "zip": 79234,
    "lat": 34.22681,
    "lng": -100.92952,
    "state_id": "TX"
  },
  {
    "zip": 79235,
    "lat": 33.96355,
    "lng": -101.25214,
    "state_id": "TX"
  },
  {
    "zip": 79236,
    "lat": 33.64183,
    "lng": -100.22815,
    "state_id": "TX"
  },
  {
    "zip": 79237,
    "lat": 34.91339,
    "lng": -100.63784,
    "state_id": "TX"
  },
  {
    "zip": 79239,
    "lat": 34.65106,
    "lng": -100.75781,
    "state_id": "TX"
  },
  {
    "zip": 79240,
    "lat": 34.88123,
    "lng": -100.77111,
    "state_id": "TX"
  },
  {
    "zip": 79241,
    "lat": 34.1848,
    "lng": -101.38676,
    "state_id": "TX"
  },
  {
    "zip": 79243,
    "lat": 33.7595,
    "lng": -100.99107,
    "state_id": "TX"
  },
  {
    "zip": 79244,
    "lat": 34.03174,
    "lng": -100.84615,
    "state_id": "TX"
  },
  {
    "zip": 79245,
    "lat": 34.84545,
    "lng": -100.50845,
    "state_id": "TX"
  },
  {
    "zip": 79247,
    "lat": 34.32983,
    "lng": -99.40904,
    "state_id": "TX"
  },
  {
    "zip": 79248,
    "lat": 34.00045,
    "lng": -100.29274,
    "state_id": "TX"
  },
  {
    "zip": 79250,
    "lat": 33.87872,
    "lng": -101.59391,
    "state_id": "TX"
  },
  {
    "zip": 79251,
    "lat": 34.92884,
    "lng": -100.4389,
    "state_id": "TX"
  },
  {
    "zip": 79252,
    "lat": 34.30503,
    "lng": -99.81699,
    "state_id": "TX"
  },
  {
    "zip": 79255,
    "lat": 34.34612,
    "lng": -101.05839,
    "state_id": "TX"
  },
  {
    "zip": 79256,
    "lat": 33.89496,
    "lng": -100.7464,
    "state_id": "TX"
  },
  {
    "zip": 79257,
    "lat": 34.46105,
    "lng": -101.28703,
    "state_id": "TX"
  },
  {
    "zip": 79258,
    "lat": 34.22723,
    "lng": -101.28847,
    "state_id": "TX"
  },
  {
    "zip": 79259,
    "lat": 34.40293,
    "lng": -100.46298,
    "state_id": "TX"
  },
  {
    "zip": 79261,
    "lat": 34.45015,
    "lng": -100.81109,
    "state_id": "TX"
  },
  {
    "zip": 79311,
    "lat": 33.86075,
    "lng": -101.883,
    "state_id": "TX"
  },
  {
    "zip": 79312,
    "lat": 34.05163,
    "lng": -102.36315,
    "state_id": "TX"
  },
  {
    "zip": 79313,
    "lat": 33.8049,
    "lng": -102.16223,
    "state_id": "TX"
  },
  {
    "zip": 79314,
    "lat": 33.58498,
    "lng": -103.01455,
    "state_id": "TX"
  },
  {
    "zip": 79316,
    "lat": 33.14437,
    "lng": -102.31614,
    "state_id": "TX"
  },
  {
    "zip": 79322,
    "lat": 33.65766,
    "lng": -101.18732,
    "state_id": "TX"
  },
  {
    "zip": 79323,
    "lat": 32.94698,
    "lng": -102.87231,
    "state_id": "TX"
  },
  {
    "zip": 79324,
    "lat": 33.89794,
    "lng": -102.68244,
    "state_id": "TX"
  },
  {
    "zip": 79325,
    "lat": 34.41493,
    "lng": -102.93999,
    "state_id": "TX"
  },
  {
    "zip": 79326,
    "lat": 34.07796,
    "lng": -102.21612,
    "state_id": "TX"
  },
  {
    "zip": 79329,
    "lat": 33.7248,
    "lng": -101.68202,
    "state_id": "TX"
  },
  {
    "zip": 79330,
    "lat": 33.03669,
    "lng": -101.20991,
    "state_id": "TX"
  },
  {
    "zip": 79331,
    "lat": 32.69327,
    "lng": -102.01054,
    "state_id": "TX"
  },
  {
    "zip": 79336,
    "lat": 33.60537,
    "lng": -102.40277,
    "state_id": "TX"
  },
  {
    "zip": 79339,
    "lat": 33.88987,
    "lng": -102.33543,
    "state_id": "TX"
  },
  {
    "zip": 79342,
    "lat": 32.9082,
    "lng": -102.33661,
    "state_id": "TX"
  },
  {
    "zip": 79343,
    "lat": 33.65565,
    "lng": -101.53011,
    "state_id": "TX"
  },
  {
    "zip": 79344,
    "lat": 33.84496,
    "lng": -102.93043,
    "state_id": "TX"
  },
  {
    "zip": 79345,
    "lat": 33.3342,
    "lng": -102.32962,
    "state_id": "TX"
  },
  {
    "zip": 79346,
    "lat": 33.64815,
    "lng": -102.82498,
    "state_id": "TX"
  },
  {
    "zip": 79347,
    "lat": 34.17747,
    "lng": -102.78205,
    "state_id": "TX"
  },
  {
    "zip": 79350,
    "lat": 33.73175,
    "lng": -101.83121,
    "state_id": "TX"
  },
  {
    "zip": 79351,
    "lat": 32.92343,
    "lng": -101.76915,
    "state_id": "TX"
  },
  {
    "zip": 79353,
    "lat": 33.78403,
    "lng": -102.58383,
    "state_id": "TX"
  },
  {
    "zip": 79355,
    "lat": 33.18172,
    "lng": -102.87664,
    "state_id": "TX"
  },
  {
    "zip": 79356,
    "lat": 33.25907,
    "lng": -101.33041,
    "state_id": "TX"
  },
  {
    "zip": 79357,
    "lat": 33.61648,
    "lng": -101.40248,
    "state_id": "TX"
  },
  {
    "zip": 79358,
    "lat": 33.43884,
    "lng": -102.19135,
    "state_id": "TX"
  },
  {
    "zip": 79359,
    "lat": 32.96704,
    "lng": -102.58926,
    "state_id": "TX"
  },
  {
    "zip": 79360,
    "lat": 32.68905,
    "lng": -102.74145,
    "state_id": "TX"
  },
  {
    "zip": 79363,
    "lat": 33.70901,
    "lng": -102.04105,
    "state_id": "TX"
  },
  {
    "zip": 79364,
    "lat": 33.45204,
    "lng": -101.64304,
    "state_id": "TX"
  },
  {
    "zip": 79366,
    "lat": 33.53015,
    "lng": -101.68366,
    "state_id": "TX"
  },
  {
    "zip": 79367,
    "lat": 33.62146,
    "lng": -102.19081,
    "state_id": "TX"
  },
  {
    "zip": 79369,
    "lat": 33.95575,
    "lng": -102.14515,
    "state_id": "TX"
  },
  {
    "zip": 79370,
    "lat": 33.50304,
    "lng": -100.90344,
    "state_id": "TX"
  },
  {
    "zip": 79371,
    "lat": 33.97457,
    "lng": -102.75404,
    "state_id": "TX"
  },
  {
    "zip": 79372,
    "lat": 33.43423,
    "lng": -102.4884,
    "state_id": "TX"
  },
  {
    "zip": 79373,
    "lat": 33.1708,
    "lng": -101.8355,
    "state_id": "TX"
  },
  {
    "zip": 79376,
    "lat": 33.2339,
    "lng": -102.67314,
    "state_id": "TX"
  },
  {
    "zip": 79377,
    "lat": 32.9321,
    "lng": -102.13166,
    "state_id": "TX"
  },
  {
    "zip": 79378,
    "lat": 33.05323,
    "lng": -102.48596,
    "state_id": "TX"
  },
  {
    "zip": 79379,
    "lat": 33.51066,
    "lng": -102.64637,
    "state_id": "TX"
  },
  {
    "zip": 79380,
    "lat": 33.74713,
    "lng": -102.35928,
    "state_id": "TX"
  },
  {
    "zip": 79381,
    "lat": 33.32286,
    "lng": -101.7652,
    "state_id": "TX"
  },
  {
    "zip": 79382,
    "lat": 33.45859,
    "lng": -102.02106,
    "state_id": "TX"
  },
  {
    "zip": 79401,
    "lat": 33.58785,
    "lng": -101.85142,
    "state_id": "TX"
  },
  {
    "zip": 79403,
    "lat": 33.6446,
    "lng": -101.76828,
    "state_id": "TX"
  },
  {
    "zip": 79404,
    "lat": 33.52535,
    "lng": -101.7963,
    "state_id": "TX"
  },
  {
    "zip": 79406,
    "lat": 33.58349,
    "lng": -101.88125,
    "state_id": "TX"
  },
  {
    "zip": 79407,
    "lat": 33.56317,
    "lng": -102.07929,
    "state_id": "TX"
  },
  {
    "zip": 79410,
    "lat": 33.56969,
    "lng": -101.89127,
    "state_id": "TX"
  },
  {
    "zip": 79411,
    "lat": 33.57002,
    "lng": -101.85779,
    "state_id": "TX"
  },
  {
    "zip": 79412,
    "lat": 33.54539,
    "lng": -101.85744,
    "state_id": "TX"
  },
  {
    "zip": 79413,
    "lat": 33.54623,
    "lng": -101.88729,
    "state_id": "TX"
  },
  {
    "zip": 79414,
    "lat": 33.54875,
    "lng": -101.91944,
    "state_id": "TX"
  },
  {
    "zip": 79415,
    "lat": 33.69755,
    "lng": -101.90483,
    "state_id": "TX"
  },
  {
    "zip": 79416,
    "lat": 33.60429,
    "lng": -101.97648,
    "state_id": "TX"
  },
  {
    "zip": 79423,
    "lat": 33.44038,
    "lng": -101.85079,
    "state_id": "TX"
  },
  {
    "zip": 79424,
    "lat": 33.46993,
    "lng": -101.94095,
    "state_id": "TX"
  },
  {
    "zip": 79501,
    "lat": 32.74483,
    "lng": -99.90507,
    "state_id": "TX"
  },
  {
    "zip": 79502,
    "lat": 33.17852,
    "lng": -100.24898,
    "state_id": "TX"
  },
  {
    "zip": 79503,
    "lat": 32.87114,
    "lng": -99.69599,
    "state_id": "TX"
  },
  {
    "zip": 79504,
    "lat": 32.32058,
    "lng": -99.35233,
    "state_id": "TX"
  },
  {
    "zip": 79505,
    "lat": 33.57582,
    "lng": -99.81601,
    "state_id": "TX"
  },
  {
    "zip": 79506,
    "lat": 32.11798,
    "lng": -100.32345,
    "state_id": "TX"
  },
  {
    "zip": 79508,
    "lat": 32.27409,
    "lng": -99.82606,
    "state_id": "TX"
  },
  {
    "zip": 79510,
    "lat": 32.27507,
    "lng": -99.52899,
    "state_id": "TX"
  },
  {
    "zip": 79511,
    "lat": 32.41453,
    "lng": -101.25956,
    "state_id": "TX"
  },
  {
    "zip": 79512,
    "lat": 32.31554,
    "lng": -100.91776,
    "state_id": "TX"
  },
  {
    "zip": 79517,
    "lat": 32.87671,
    "lng": -101.24268,
    "state_id": "TX"
  },
  {
    "zip": 79518,
    "lat": 33.33064,
    "lng": -100.72207,
    "state_id": "TX"
  },
  {
    "zip": 79519,
    "lat": 32.04877,
    "lng": -99.69704,
    "state_id": "TX"
  },
  {
    "zip": 79520,
    "lat": 32.86489,
    "lng": -100.13233,
    "state_id": "TX"
  },
  {
    "zip": 79521,
    "lat": 33.12235,
    "lng": -99.65414,
    "state_id": "TX"
  },
  {
    "zip": 79525,
    "lat": 32.63114,
    "lng": -99.83178,
    "state_id": "TX"
  },
  {
    "zip": 79526,
    "lat": 32.63739,
    "lng": -100.72015,
    "state_id": "TX"
  },
  {
    "zip": 79527,
    "lat": 32.64376,
    "lng": -101.15094,
    "state_id": "TX"
  },
  {
    "zip": 79528,
    "lat": 33.26207,
    "lng": -100.79076,
    "state_id": "TX"
  },
  {
    "zip": 79529,
    "lat": 33.43853,
    "lng": -99.84731,
    "state_id": "TX"
  },
  {
    "zip": 79530,
    "lat": 32.11907,
    "lng": -99.75931,
    "state_id": "TX"
  },
  {
    "zip": 79532,
    "lat": 32.40496,
    "lng": -100.71227,
    "state_id": "TX"
  },
  {
    "zip": 79533,
    "lat": 32.85549,
    "lng": -99.55488,
    "state_id": "TX"
  },
  {
    "zip": 79534,
    "lat": 32.75673,
    "lng": -100.21828,
    "state_id": "TX"
  },
  {
    "zip": 79535,
    "lat": 32.2257,
    "lng": -100.47631,
    "state_id": "TX"
  },
  {
    "zip": 79536,
    "lat": 32.47378,
    "lng": -100.03345,
    "state_id": "TX"
  },
  {
    "zip": 79537,
    "lat": 32.29187,
    "lng": -100.21074,
    "state_id": "TX"
  },
  {
    "zip": 79538,
    "lat": 31.97814,
    "lng": -99.68094,
    "state_id": "TX"
  },
  {
    "zip": 79539,
    "lat": 33.36843,
    "lng": -99.88223,
    "state_id": "TX"
  },
  {
    "zip": 79540,
    "lat": 33.16876,
    "lng": -100.03773,
    "state_id": "TX"
  },
  {
    "zip": 79541,
    "lat": 32.15305,
    "lng": -99.81497,
    "state_id": "TX"
  },
  {
    "zip": 79543,
    "lat": 32.7218,
    "lng": -100.40076,
    "state_id": "TX"
  },
  {
    "zip": 79544,
    "lat": 33.30151,
    "lng": -99.85288,
    "state_id": "TX"
  },
  {
    "zip": 79545,
    "lat": 32.41144,
    "lng": -100.58009,
    "state_id": "TX"
  },
  {
    "zip": 79546,
    "lat": 32.89242,
    "lng": -100.49804,
    "state_id": "TX"
  },
  {
    "zip": 79547,
    "lat": 33.19923,
    "lng": -99.91193,
    "state_id": "TX"
  },
  {
    "zip": 79548,
    "lat": 33.05573,
    "lng": -99.92295,
    "state_id": "TX"
  },
  {
    "zip": 79549,
    "lat": 32.91367,
    "lng": -100.84506,
    "state_id": "TX"
  },
  {
    "zip": 79553,
    "lat": 32.93882,
    "lng": -99.84265,
    "state_id": "TX"
  },
  {
    "zip": 79556,
    "lat": 32.45431,
    "lng": -100.36401,
    "state_id": "TX"
  },
  {
    "zip": 79560,
    "lat": 32.67887,
    "lng": -100.20891,
    "state_id": "TX"
  },
  {
    "zip": 79561,
    "lat": 32.52133,
    "lng": -100.2004,
    "state_id": "TX"
  },
  {
    "zip": 79562,
    "lat": 32.24437,
    "lng": -99.86596,
    "state_id": "TX"
  },
  {
    "zip": 79563,
    "lat": 32.42317,
    "lng": -99.90769,
    "state_id": "TX"
  },
  {
    "zip": 79565,
    "lat": 32.39463,
    "lng": -101.09316,
    "state_id": "TX"
  },
  {
    "zip": 79566,
    "lat": 32.11347,
    "lng": -100.12203,
    "state_id": "TX"
  },
  {
    "zip": 79567,
    "lat": 31.97769,
    "lng": -99.95308,
    "state_id": "TX"
  },
  {
    "zip": 79601,
    "lat": 32.57964,
    "lng": -99.66351,
    "state_id": "TX"
  },
  {
    "zip": 79602,
    "lat": 32.33948,
    "lng": -99.66982,
    "state_id": "TX"
  },
  {
    "zip": 79603,
    "lat": 32.40157,
    "lng": -99.88468,
    "state_id": "TX"
  },
  {
    "zip": 79605,
    "lat": 32.43398,
    "lng": -99.78205,
    "state_id": "TX"
  },
  {
    "zip": 79606,
    "lat": 32.36093,
    "lng": -99.82698,
    "state_id": "TX"
  },
  {
    "zip": 79607,
    "lat": 32.42853,
    "lng": -99.82546,
    "state_id": "TX"
  },
  {
    "zip": 79699,
    "lat": 32.46227,
    "lng": -99.71586,
    "state_id": "TX"
  },
  {
    "zip": 79701,
    "lat": 31.99237,
    "lng": -102.08147,
    "state_id": "TX"
  },
  {
    "zip": 79703,
    "lat": 31.97945,
    "lng": -102.13231,
    "state_id": "TX"
  },
  {
    "zip": 79705,
    "lat": 32.05114,
    "lng": -102.05954,
    "state_id": "TX"
  },
  {
    "zip": 79706,
    "lat": 31.84553,
    "lng": -102.00236,
    "state_id": "TX"
  },
  {
    "zip": 79707,
    "lat": 32.03347,
    "lng": -102.21158,
    "state_id": "TX"
  },
  {
    "zip": 79713,
    "lat": 32.49638,
    "lng": -101.7556,
    "state_id": "TX"
  },
  {
    "zip": 79714,
    "lat": 32.32943,
    "lng": -102.69918,
    "state_id": "TX"
  },
  {
    "zip": 79718,
    "lat": 31.00535,
    "lng": -103.76186,
    "state_id": "TX"
  },
  {
    "zip": 79719,
    "lat": 31.46011,
    "lng": -103.40559,
    "state_id": "TX"
  },
  {
    "zip": 79720,
    "lat": 32.25363,
    "lng": -101.47291,
    "state_id": "TX"
  },
  {
    "zip": 79730,
    "lat": 31.16955,
    "lng": -103.0851,
    "state_id": "TX"
  },
  {
    "zip": 79731,
    "lat": 31.41808,
    "lng": -102.48336,
    "state_id": "TX"
  },
  {
    "zip": 79733,
    "lat": 32.10287,
    "lng": -101.36544,
    "state_id": "TX"
  },
  {
    "zip": 79734,
    "lat": 30.68395,
    "lng": -103.95565,
    "state_id": "TX"
  },
  {
    "zip": 79735,
    "lat": 30.74291,
    "lng": -102.78314,
    "state_id": "TX"
  },
  {
    "zip": 79738,
    "lat": 32.75712,
    "lng": -101.47555,
    "state_id": "TX"
  },
  {
    "zip": 79739,
    "lat": 31.76367,
    "lng": -101.51601,
    "state_id": "TX"
  },
  {
    "zip": 79741,
    "lat": 31.97,
    "lng": -102.62753,
    "state_id": "TX"
  },
  {
    "zip": 79742,
    "lat": 31.34203,
    "lng": -102.86846,
    "state_id": "TX"
  },
  {
    "zip": 79743,
    "lat": 31.17762,
    "lng": -102.59435,
    "state_id": "TX"
  },
  {
    "zip": 79744,
    "lat": 30.92196,
    "lng": -101.97036,
    "state_id": "TX"
  },
  {
    "zip": 79745,
    "lat": 31.82638,
    "lng": -103.05179,
    "state_id": "TX"
  },
  {
    "zip": 79748,
    "lat": 32.41116,
    "lng": -101.65046,
    "state_id": "TX"
  },
  {
    "zip": 79749,
    "lat": 32.28903,
    "lng": -101.87178,
    "state_id": "TX"
  },
  {
    "zip": 79752,
    "lat": 31.21544,
    "lng": -102.15319,
    "state_id": "TX"
  },
  {
    "zip": 79754,
    "lat": 31.80166,
    "lng": -103.59994,
    "state_id": "TX"
  },
  {
    "zip": 79755,
    "lat": 31.50464,
    "lng": -101.93778,
    "state_id": "TX"
  },
  {
    "zip": 79756,
    "lat": 31.5458,
    "lng": -102.85809,
    "state_id": "TX"
  },
  {
    "zip": 79758,
    "lat": 32.00814,
    "lng": -102.33409,
    "state_id": "TX"
  },
  {
    "zip": 79759,
    "lat": 31.79647,
    "lng": -102.70154,
    "state_id": "TX"
  },
  {
    "zip": 79761,
    "lat": 31.85574,
    "lng": -102.34958,
    "state_id": "TX"
  },
  {
    "zip": 79762,
    "lat": 31.92647,
    "lng": -102.35444,
    "state_id": "TX"
  },
  {
    "zip": 79763,
    "lat": 31.79918,
    "lng": -102.46788,
    "state_id": "TX"
  },
  {
    "zip": 79764,
    "lat": 31.89053,
    "lng": -102.47105,
    "state_id": "TX"
  },
  {
    "zip": 79765,
    "lat": 31.92346,
    "lng": -102.29242,
    "state_id": "TX"
  },
  {
    "zip": 79766,
    "lat": 31.7033,
    "lng": -102.41204,
    "state_id": "TX"
  },
  {
    "zip": 79770,
    "lat": 31.86396,
    "lng": -103.92971,
    "state_id": "TX"
  },
  {
    "zip": 79772,
    "lat": 31.42541,
    "lng": -103.62296,
    "state_id": "TX"
  },
  {
    "zip": 79777,
    "lat": 31.49787,
    "lng": -103.12979,
    "state_id": "TX"
  },
  {
    "zip": 79778,
    "lat": 31.17611,
    "lng": -101.92385,
    "state_id": "TX"
  },
  {
    "zip": 79780,
    "lat": 31.06197,
    "lng": -103.59216,
    "state_id": "TX"
  },
  {
    "zip": 79781,
    "lat": 30.59596,
    "lng": -101.77778,
    "state_id": "TX"
  },
  {
    "zip": 79782,
    "lat": 32.15842,
    "lng": -101.84321,
    "state_id": "TX"
  },
  {
    "zip": 79783,
    "lat": 32.3217,
    "lng": -102.04229,
    "state_id": "TX"
  },
  {
    "zip": 79785,
    "lat": 31.32749,
    "lng": -103.9069,
    "state_id": "TX"
  },
  {
    "zip": 79788,
    "lat": 31.5729,
    "lng": -102.98358,
    "state_id": "TX"
  },
  {
    "zip": 79789,
    "lat": 31.76026,
    "lng": -103.18576,
    "state_id": "TX"
  },
  {
    "zip": 79821,
    "lat": 31.97696,
    "lng": -106.59781,
    "state_id": "TX"
  },
  {
    "zip": 79830,
    "lat": 29.80008,
    "lng": -103.22468,
    "state_id": "TX"
  },
  {
    "zip": 79831,
    "lat": 30.4295,
    "lng": -103.32553,
    "state_id": "TX"
  },
  {
    "zip": 79834,
    "lat": 29.29122,
    "lng": -103.26531,
    "state_id": "TX"
  },
  {
    "zip": 79835,
    "lat": 31.9352,
    "lng": -106.58693,
    "state_id": "TX"
  },
  {
    "zip": 79836,
    "lat": 31.57127,
    "lng": -106.2019,
    "state_id": "TX"
  },
  {
    "zip": 79837,
    "lat": 32.19026,
    "lng": -105.26698,
    "state_id": "TX"
  },
  {
    "zip": 79838,
    "lat": 31.49182,
    "lng": -106.17357,
    "state_id": "TX"
  },
  {
    "zip": 79839,
    "lat": 31.21317,
    "lng": -105.60772,
    "state_id": "TX"
  },
  {
    "zip": 79842,
    "lat": 30.09108,
    "lng": -103.11657,
    "state_id": "TX"
  },
  {
    "zip": 79843,
    "lat": 30.12696,
    "lng": -104.22342,
    "state_id": "TX"
  },
  {
    "zip": 79845,
    "lat": 29.88855,
    "lng": -104.51961,
    "state_id": "TX"
  },
  {
    "zip": 79846,
    "lat": 29.45217,
    "lng": -103.99027,
    "state_id": "TX"
  },
  {
    "zip": 79847,
    "lat": 31.80678,
    "lng": -104.97247,
    "state_id": "TX"
  },
  {
    "zip": 79848,
    "lat": 30.24901,
    "lng": -102.30544,
    "state_id": "TX"
  },
  {
    "zip": 79849,
    "lat": 31.56126,
    "lng": -106.25047,
    "state_id": "TX"
  },
  {
    "zip": 79851,
    "lat": 31.28841,
    "lng": -105.31975,
    "state_id": "TX"
  },
  {
    "zip": 79852,
    "lat": 29.40324,
    "lng": -103.62417,
    "state_id": "TX"
  },
  {
    "zip": 79853,
    "lat": 31.42987,
    "lng": -106.06371,
    "state_id": "TX"
  },
  {
    "zip": 79854,
    "lat": 30.57932,
    "lng": -104.65911,
    "state_id": "TX"
  },
  {
    "zip": 79855,
    "lat": 31.09237,
    "lng": -104.63688,
    "state_id": "TX"
  },
  {
    "zip": 79901,
    "lat": 31.76003,
    "lng": -106.4798,
    "state_id": "TX"
  },
  {
    "zip": 79902,
    "lat": 31.78414,
    "lng": -106.49791,
    "state_id": "TX"
  },
  {
    "zip": 79903,
    "lat": 31.78623,
    "lng": -106.44188,
    "state_id": "TX"
  },
  {
    "zip": 79904,
    "lat": 31.85287,
    "lng": -106.44647,
    "state_id": "TX"
  },
  {
    "zip": 79905,
    "lat": 31.7666,
    "lng": -106.42488,
    "state_id": "TX"
  },
  {
    "zip": 79906,
    "lat": 31.80924,
    "lng": -106.41651,
    "state_id": "TX"
  },
  {
    "zip": 79907,
    "lat": 31.7075,
    "lng": -106.32683,
    "state_id": "TX"
  },
  {
    "zip": 79908,
    "lat": 31.85719,
    "lng": -106.38038,
    "state_id": "TX"
  },
  {
    "zip": 79911,
    "lat": 31.89246,
    "lng": -106.54251,
    "state_id": "TX"
  },
  {
    "zip": 79912,
    "lat": 31.84821,
    "lng": -106.53264,
    "state_id": "TX"
  },
  {
    "zip": 79915,
    "lat": 31.74586,
    "lng": -106.37237,
    "state_id": "TX"
  },
  {
    "zip": 79916,
    "lat": 31.81397,
    "lng": -106.41816,
    "state_id": "TX"
  },
  {
    "zip": 79920,
    "lat": 31.82482,
    "lng": -106.4517,
    "state_id": "TX"
  },
  {
    "zip": 79922,
    "lat": 31.81507,
    "lng": -106.56101,
    "state_id": "TX"
  },
  {
    "zip": 79924,
    "lat": 31.90245,
    "lng": -106.41325,
    "state_id": "TX"
  },
  {
    "zip": 79925,
    "lat": 31.79772,
    "lng": -106.36331,
    "state_id": "TX"
  },
  {
    "zip": 79927,
    "lat": 31.64458,
    "lng": -106.27373,
    "state_id": "TX"
  },
  {
    "zip": 79928,
    "lat": 31.62862,
    "lng": -106.15597,
    "state_id": "TX"
  },
  {
    "zip": 79930,
    "lat": 31.82028,
    "lng": -106.46994,
    "state_id": "TX"
  },
  {
    "zip": 79932,
    "lat": 31.87816,
    "lng": -106.60643,
    "state_id": "TX"
  },
  {
    "zip": 79934,
    "lat": 31.95191,
    "lng": -106.434,
    "state_id": "TX"
  },
  {
    "zip": 79935,
    "lat": 31.76795,
    "lng": -106.33019,
    "state_id": "TX"
  },
  {
    "zip": 79936,
    "lat": 31.77618,
    "lng": -106.29786,
    "state_id": "TX"
  },
  {
    "zip": 79938,
    "lat": 31.83091,
    "lng": -105.97012,
    "state_id": "TX"
  },
  {
    "zip": 79942,
    "lat": 30.57792,
    "lng": -101.34274,
    "state_id": "TX"
  },
  {
    "zip": 84001,
    "lat": 40.3514,
    "lng": -110.27544,
    "state_id": "UT"
  },
  {
    "zip": 84002,
    "lat": 40.45199,
    "lng": -110.30781,
    "state_id": "UT"
  },
  {
    "zip": 84003,
    "lat": 40.46017,
    "lng": -111.71916,
    "state_id": "UT"
  },
  {
    "zip": 84004,
    "lat": 40.48814,
    "lng": -111.74906,
    "state_id": "UT"
  },
  {
    "zip": 84005,
    "lat": 40.32513,
    "lng": -111.99656,
    "state_id": "UT"
  },
  {
    "zip": 84006,
    "lat": 40.60131,
    "lng": -112.11715,
    "state_id": "UT"
  },
  {
    "zip": 84007,
    "lat": 40.32134,
    "lng": -110.20174,
    "state_id": "UT"
  },
  {
    "zip": 84010,
    "lat": 40.86775,
    "lng": -111.86726,
    "state_id": "UT"
  },
  {
    "zip": 84013,
    "lat": 40.29589,
    "lng": -112.09228,
    "state_id": "UT"
  },
  {
    "zip": 84014,
    "lat": 40.93212,
    "lng": -111.88505,
    "state_id": "UT"
  },
  {
    "zip": 84015,
    "lat": 41.12088,
    "lng": -112.06121,
    "state_id": "UT"
  },
  {
    "zip": 84017,
    "lat": 40.93355,
    "lng": -111.20387,
    "state_id": "UT"
  },
  {
    "zip": 84018,
    "lat": 41.21539,
    "lng": -111.44543,
    "state_id": "UT"
  },
  {
    "zip": 84020,
    "lat": 40.50025,
    "lng": -111.86742,
    "state_id": "UT"
  },
  {
    "zip": 84021,
    "lat": 40.13019,
    "lng": -110.52755,
    "state_id": "UT"
  },
  {
    "zip": 84022,
    "lat": 40.27878,
    "lng": -112.76586,
    "state_id": "UT"
  },
  {
    "zip": 84023,
    "lat": 40.845,
    "lng": -109.20404,
    "state_id": "UT"
  },
  {
    "zip": 84024,
    "lat": 41.00765,
    "lng": -111.44734,
    "state_id": "UT"
  },
  {
    "zip": 84025,
    "lat": 40.98035,
    "lng": -111.90359,
    "state_id": "UT"
  },
  {
    "zip": 84026,
    "lat": 40.28101,
    "lng": -109.83949,
    "state_id": "UT"
  },
  {
    "zip": 84027,
    "lat": 40.15755,
    "lng": -110.82253,
    "state_id": "UT"
  },
  {
    "zip": 84028,
    "lat": 41.93362,
    "lng": -111.4055,
    "state_id": "UT"
  },
  {
    "zip": 84029,
    "lat": 40.62653,
    "lng": -112.72171,
    "state_id": "UT"
  },
  {
    "zip": 84031,
    "lat": 40.53283,
    "lng": -110.76874,
    "state_id": "UT"
  },
  {
    "zip": 84032,
    "lat": 40.36183,
    "lng": -111.2011,
    "state_id": "UT"
  },
  {
    "zip": 84033,
    "lat": 40.98567,
    "lng": -111.50109,
    "state_id": "UT"
  },
  {
    "zip": 84034,
    "lat": 40.06029,
    "lng": -113.89529,
    "state_id": "UT"
  },
  {
    "zip": 84035,
    "lat": 40.20701,
    "lng": -109.26359,
    "state_id": "UT"
  },
  {
    "zip": 84036,
    "lat": 40.64134,
    "lng": -111.14475,
    "state_id": "UT"
  },
  {
    "zip": 84037,
    "lat": 41.02504,
    "lng": -111.95054,
    "state_id": "UT"
  },
  {
    "zip": 84038,
    "lat": 41.87582,
    "lng": -111.31773,
    "state_id": "UT"
  },
  {
    "zip": 84039,
    "lat": 40.48557,
    "lng": -109.80848,
    "state_id": "UT"
  },
  {
    "zip": 84040,
    "lat": 41.09148,
    "lng": -111.92696,
    "state_id": "UT"
  },
  {
    "zip": 84041,
    "lat": 41.07146,
    "lng": -111.98377,
    "state_id": "UT"
  },
  {
    "zip": 84042,
    "lat": 40.34079,
    "lng": -111.72449,
    "state_id": "UT"
  },
  {
    "zip": 84043,
    "lat": 40.41087,
    "lng": -111.8733,
    "state_id": "UT"
  },
  {
    "zip": 84044,
    "lat": 40.71968,
    "lng": -112.1559,
    "state_id": "UT"
  },
  {
    "zip": 84045,
    "lat": 40.33847,
    "lng": -111.91176,
    "state_id": "UT"
  },
  {
    "zip": 84046,
    "lat": 40.94375,
    "lng": -109.8228,
    "state_id": "UT"
  },
  {
    "zip": 84047,
    "lat": 40.61519,
    "lng": -111.89079,
    "state_id": "UT"
  },
  {
    "zip": 84049,
    "lat": 40.52481,
    "lng": -111.50985,
    "state_id": "UT"
  },
  {
    "zip": 84050,
    "lat": 41.01959,
    "lng": -111.67577,
    "state_id": "UT"
  },
  {
    "zip": 84051,
    "lat": 40.39216,
    "lng": -110.42923,
    "state_id": "UT"
  },
  {
    "zip": 84052,
    "lat": 40.17198,
    "lng": -109.99552,
    "state_id": "UT"
  },
  {
    "zip": 84053,
    "lat": 40.44996,
    "lng": -110.0181,
    "state_id": "UT"
  },
  {
    "zip": 84054,
    "lat": 40.84113,
    "lng": -111.91693,
    "state_id": "UT"
  },
  {
    "zip": 84055,
    "lat": 40.77872,
    "lng": -111.08246,
    "state_id": "UT"
  },
  {
    "zip": 84056,
    "lat": 41.12649,
    "lng": -111.99017,
    "state_id": "UT"
  },
  {
    "zip": 84057,
    "lat": 40.31425,
    "lng": -111.7104,
    "state_id": "UT"
  },
  {
    "zip": 84058,
    "lat": 40.28728,
    "lng": -111.7278,
    "state_id": "UT"
  },
  {
    "zip": 84060,
    "lat": 40.65236,
    "lng": -111.50183,
    "state_id": "UT"
  },
  {
    "zip": 84061,
    "lat": 40.75259,
    "lng": -111.34282,
    "state_id": "UT"
  },
  {
    "zip": 84062,
    "lat": 40.43484,
    "lng": -111.67779,
    "state_id": "UT"
  },
  {
    "zip": 84063,
    "lat": 39.98758,
    "lng": -109.70237,
    "state_id": "UT"
  },
  {
    "zip": 84064,
    "lat": 41.68381,
    "lng": -111.12536,
    "state_id": "UT"
  },
  {
    "zip": 84065,
    "lat": 40.4954,
    "lng": -111.94443,
    "state_id": "UT"
  },
  {
    "zip": 84066,
    "lat": 40.32926,
    "lng": -110.02869,
    "state_id": "UT"
  },
  {
    "zip": 84067,
    "lat": 41.17183,
    "lng": -112.04814,
    "state_id": "UT"
  },
  {
    "zip": 84069,
    "lat": 40.3814,
    "lng": -112.49747,
    "state_id": "UT"
  },
  {
    "zip": 84070,
    "lat": 40.577,
    "lng": -111.88907,
    "state_id": "UT"
  },
  {
    "zip": 84071,
    "lat": 40.41818,
    "lng": -112.31834,
    "state_id": "UT"
  },
  {
    "zip": 84072,
    "lat": 40.35538,
    "lng": -110.66026,
    "state_id": "UT"
  },
  {
    "zip": 84073,
    "lat": 40.35652,
    "lng": -110.435,
    "state_id": "UT"
  },
  {
    "zip": 84074,
    "lat": 40.55929,
    "lng": -112.27335,
    "state_id": "UT"
  },
  {
    "zip": 84075,
    "lat": 41.08139,
    "lng": -112.07974,
    "state_id": "UT"
  },
  {
    "zip": 84076,
    "lat": 40.46648,
    "lng": -109.84551,
    "state_id": "UT"
  },
  {
    "zip": 84078,
    "lat": 40.62968,
    "lng": -109.48291,
    "state_id": "UT"
  },
  {
    "zip": 84080,
    "lat": 40.09995,
    "lng": -112.4301,
    "state_id": "UT"
  },
  {
    "zip": 84081,
    "lat": 40.60284,
    "lng": -112.03764,
    "state_id": "UT"
  },
  {
    "zip": 84082,
    "lat": 40.36581,
    "lng": -111.39533,
    "state_id": "UT"
  },
  {
    "zip": 84083,
    "lat": 40.86034,
    "lng": -113.67265,
    "state_id": "UT"
  },
  {
    "zip": 84084,
    "lat": 40.62288,
    "lng": -111.96464,
    "state_id": "UT"
  },
  {
    "zip": 84085,
    "lat": 40.58696,
    "lng": -109.92909,
    "state_id": "UT"
  },
  {
    "zip": 84086,
    "lat": 41.47983,
    "lng": -111.22871,
    "state_id": "UT"
  },
  {
    "zip": 84087,
    "lat": 40.88573,
    "lng": -111.93128,
    "state_id": "UT"
  },
  {
    "zip": 84088,
    "lat": 40.59515,
    "lng": -111.96092,
    "state_id": "UT"
  },
  {
    "zip": 84092,
    "lat": 40.55778,
    "lng": -111.74311,
    "state_id": "UT"
  },
  {
    "zip": 84093,
    "lat": 40.59475,
    "lng": -111.82855,
    "state_id": "UT"
  },
  {
    "zip": 84094,
    "lat": 40.57188,
    "lng": -111.86214,
    "state_id": "UT"
  },
  {
    "zip": 84095,
    "lat": 40.55724,
    "lng": -111.9789,
    "state_id": "UT"
  },
  {
    "zip": 84096,
    "lat": 40.51368,
    "lng": -112.09853,
    "state_id": "UT"
  },
  {
    "zip": 84097,
    "lat": 40.30481,
    "lng": -111.67313,
    "state_id": "UT"
  },
  {
    "zip": 84098,
    "lat": 40.73472,
    "lng": -111.53432,
    "state_id": "UT"
  },
  {
    "zip": 84101,
    "lat": 40.75643,
    "lng": -111.90012,
    "state_id": "UT"
  },
  {
    "zip": 84102,
    "lat": 40.76021,
    "lng": -111.86439,
    "state_id": "UT"
  },
  {
    "zip": 84103,
    "lat": 40.79519,
    "lng": -111.84247,
    "state_id": "UT"
  },
  {
    "zip": 84104,
    "lat": 40.74865,
    "lng": -111.98221,
    "state_id": "UT"
  },
  {
    "zip": 84105,
    "lat": 40.73826,
    "lng": -111.85954,
    "state_id": "UT"
  },
  {
    "zip": 84106,
    "lat": 40.70674,
    "lng": -111.85571,
    "state_id": "UT"
  },
  {
    "zip": 84107,
    "lat": 40.65805,
    "lng": -111.88444,
    "state_id": "UT"
  },
  {
    "zip": 84108,
    "lat": 40.7899,
    "lng": -111.73783,
    "state_id": "UT"
  },
  {
    "zip": 84109,
    "lat": 40.70266,
    "lng": -111.70607,
    "state_id": "UT"
  },
  {
    "zip": 84111,
    "lat": 40.75591,
    "lng": -111.88397,
    "state_id": "UT"
  },
  {
    "zip": 84112,
    "lat": 40.76504,
    "lng": -111.84139,
    "state_id": "UT"
  },
  {
    "zip": 84113,
    "lat": 40.7603,
    "lng": -111.83585,
    "state_id": "UT"
  },
  {
    "zip": 84115,
    "lat": 40.71451,
    "lng": -111.89271,
    "state_id": "UT"
  },
  {
    "zip": 84116,
    "lat": 40.79988,
    "lng": -111.9573,
    "state_id": "UT"
  },
  {
    "zip": 84117,
    "lat": 40.66104,
    "lng": -111.83464,
    "state_id": "UT"
  },
  {
    "zip": 84118,
    "lat": 40.6535,
    "lng": -112.01342,
    "state_id": "UT"
  },
  {
    "zip": 84119,
    "lat": 40.70158,
    "lng": -111.94607,
    "state_id": "UT"
  },
  {
    "zip": 84120,
    "lat": 40.69747,
    "lng": -112.00121,
    "state_id": "UT"
  },
  {
    "zip": 84121,
    "lat": 40.62561,
    "lng": -111.69818,
    "state_id": "UT"
  },
  {
    "zip": 84123,
    "lat": 40.65882,
    "lng": -111.92161,
    "state_id": "UT"
  },
  {
    "zip": 84124,
    "lat": 40.67722,
    "lng": -111.81329,
    "state_id": "UT"
  },
  {
    "zip": 84128,
    "lat": 40.70043,
    "lng": -112.04676,
    "state_id": "UT"
  },
  {
    "zip": 84180,
    "lat": 40.77044,
    "lng": -111.9011,
    "state_id": "UT"
  },
  {
    "zip": 84301,
    "lat": 41.6082,
    "lng": -112.13016,
    "state_id": "UT"
  },
  {
    "zip": 84302,
    "lat": 41.54808,
    "lng": -112.04562,
    "state_id": "UT"
  },
  {
    "zip": 84304,
    "lat": 41.81658,
    "lng": -111.99687,
    "state_id": "UT"
  },
  {
    "zip": 84305,
    "lat": 41.94419,
    "lng": -112.07563,
    "state_id": "UT"
  },
  {
    "zip": 84306,
    "lat": 41.79265,
    "lng": -112.06399,
    "state_id": "UT"
  },
  {
    "zip": 84307,
    "lat": 41.51548,
    "lng": -112.49332,
    "state_id": "UT"
  },
  {
    "zip": 84308,
    "lat": 41.96115,
    "lng": -111.98642,
    "state_id": "UT"
  },
  {
    "zip": 84309,
    "lat": 41.71408,
    "lng": -112.09835,
    "state_id": "UT"
  },
  {
    "zip": 84310,
    "lat": 41.33537,
    "lng": -111.84949,
    "state_id": "UT"
  },
  {
    "zip": 84311,
    "lat": 41.8237,
    "lng": -112.11604,
    "state_id": "UT"
  },
  {
    "zip": 84312,
    "lat": 41.77904,
    "lng": -112.15365,
    "state_id": "UT"
  },
  {
    "zip": 84313,
    "lat": 41.65759,
    "lng": -113.96194,
    "state_id": "UT"
  },
  {
    "zip": 84314,
    "lat": 41.64845,
    "lng": -112.09289,
    "state_id": "UT"
  },
  {
    "zip": 84315,
    "lat": 41.16686,
    "lng": -112.13653,
    "state_id": "UT"
  },
  {
    "zip": 84316,
    "lat": 41.76767,
    "lng": -112.4778,
    "state_id": "UT"
  },
  {
    "zip": 84317,
    "lat": 41.30437,
    "lng": -111.61868,
    "state_id": "UT"
  },
  {
    "zip": 84318,
    "lat": 41.80258,
    "lng": -111.81368,
    "state_id": "UT"
  },
  {
    "zip": 84319,
    "lat": 41.61013,
    "lng": -111.6665,
    "state_id": "UT"
  },
  {
    "zip": 84320,
    "lat": 41.96481,
    "lng": -111.84864,
    "state_id": "UT"
  },
  {
    "zip": 84321,
    "lat": 41.80001,
    "lng": -111.68546,
    "state_id": "UT"
  },
  {
    "zip": 84324,
    "lat": 41.48788,
    "lng": -111.9526,
    "state_id": "UT"
  },
  {
    "zip": 84325,
    "lat": 41.72579,
    "lng": -111.99278,
    "state_id": "UT"
  },
  {
    "zip": 84326,
    "lat": 41.67062,
    "lng": -111.81782,
    "state_id": "UT"
  },
  {
    "zip": 84327,
    "lat": 41.87166,
    "lng": -111.99469,
    "state_id": "UT"
  },
  {
    "zip": 84328,
    "lat": 41.54584,
    "lng": -111.83476,
    "state_id": "UT"
  },
  {
    "zip": 84329,
    "lat": 41.76545,
    "lng": -113.41927,
    "state_id": "UT"
  },
  {
    "zip": 84330,
    "lat": 41.92693,
    "lng": -112.16341,
    "state_id": "UT"
  },
  {
    "zip": 84331,
    "lat": 41.94851,
    "lng": -112.27356,
    "state_id": "UT"
  },
  {
    "zip": 84332,
    "lat": 41.69996,
    "lng": -111.81217,
    "state_id": "UT"
  },
  {
    "zip": 84333,
    "lat": 41.90907,
    "lng": -111.81106,
    "state_id": "UT"
  },
  {
    "zip": 84334,
    "lat": 41.78256,
    "lng": -112.14763,
    "state_id": "UT"
  },
  {
    "zip": 84335,
    "lat": 41.83119,
    "lng": -111.8788,
    "state_id": "UT"
  },
  {
    "zip": 84336,
    "lat": 41.90957,
    "lng": -112.77871,
    "state_id": "UT"
  },
  {
    "zip": 84337,
    "lat": 41.76588,
    "lng": -112.31807,
    "state_id": "UT"
  },
  {
    "zip": 84338,
    "lat": 41.91124,
    "lng": -111.93872,
    "state_id": "UT"
  },
  {
    "zip": 84339,
    "lat": 41.62534,
    "lng": -111.94442,
    "state_id": "UT"
  },
  {
    "zip": 84340,
    "lat": 41.37974,
    "lng": -112.04637,
    "state_id": "UT"
  },
  {
    "zip": 84341,
    "lat": 41.77584,
    "lng": -111.80695,
    "state_id": "UT"
  },
  {
    "zip": 84401,
    "lat": 41.21865,
    "lng": -112.03814,
    "state_id": "UT"
  },
  {
    "zip": 84403,
    "lat": 41.19446,
    "lng": -111.90253,
    "state_id": "UT"
  },
  {
    "zip": 84404,
    "lat": 41.27066,
    "lng": -112.11644,
    "state_id": "UT"
  },
  {
    "zip": 84405,
    "lat": 41.15797,
    "lng": -111.96657,
    "state_id": "UT"
  },
  {
    "zip": 84414,
    "lat": 41.32192,
    "lng": -111.9762,
    "state_id": "UT"
  },
  {
    "zip": 84501,
    "lat": 39.547,
    "lng": -110.76031,
    "state_id": "UT"
  },
  {
    "zip": 84511,
    "lat": 37.56298,
    "lng": -109.41441,
    "state_id": "UT"
  },
  {
    "zip": 84512,
    "lat": 37.13715,
    "lng": -109.57122,
    "state_id": "UT"
  },
  {
    "zip": 84513,
    "lat": 39.24423,
    "lng": -111.00988,
    "state_id": "UT"
  },
  {
    "zip": 84515,
    "lat": 38.9426,
    "lng": -109.17929,
    "state_id": "UT"
  },
  {
    "zip": 84516,
    "lat": 39.143,
    "lng": -111.06883,
    "state_id": "UT"
  },
  {
    "zip": 84518,
    "lat": 39.39247,
    "lng": -110.89036,
    "state_id": "UT"
  },
  {
    "zip": 84520,
    "lat": 39.52915,
    "lng": -110.42051,
    "state_id": "UT"
  },
  {
    "zip": 84521,
    "lat": 39.42156,
    "lng": -110.78186,
    "state_id": "UT"
  },
  {
    "zip": 84522,
    "lat": 38.92041,
    "lng": -111.19993,
    "state_id": "UT"
  },
  {
    "zip": 84523,
    "lat": 39.09447,
    "lng": -111.19997,
    "state_id": "UT"
  },
  {
    "zip": 84525,
    "lat": 38.94652,
    "lng": -110.25453,
    "state_id": "UT"
  },
  {
    "zip": 84526,
    "lat": 39.74541,
    "lng": -110.94615,
    "state_id": "UT"
  },
  {
    "zip": 84528,
    "lat": 39.40956,
    "lng": -111.07856,
    "state_id": "UT"
  },
  {
    "zip": 84529,
    "lat": 39.67943,
    "lng": -110.82074,
    "state_id": "UT"
  },
  {
    "zip": 84530,
    "lat": 38.2889,
    "lng": -109.16129,
    "state_id": "UT"
  },
  {
    "zip": 84531,
    "lat": 37.15828,
    "lng": -109.88439,
    "state_id": "UT"
  },
  {
    "zip": 84532,
    "lat": 38.50073,
    "lng": -109.53381,
    "state_id": "UT"
  },
  {
    "zip": 84533,
    "lat": 37.56105,
    "lng": -110.43123,
    "state_id": "UT"
  },
  {
    "zip": 84534,
    "lat": 37.25309,
    "lng": -109.23317,
    "state_id": "UT"
  },
  {
    "zip": 84535,
    "lat": 37.99929,
    "lng": -109.56779,
    "state_id": "UT"
  },
  {
    "zip": 84536,
    "lat": 37.12298,
    "lng": -110.3505,
    "state_id": "UT"
  },
  {
    "zip": 84537,
    "lat": 39.32972,
    "lng": -111.19802,
    "state_id": "UT"
  },
  {
    "zip": 84539,
    "lat": 39.69371,
    "lng": -110.27228,
    "state_id": "UT"
  },
  {
    "zip": 84540,
    "lat": 39.24348,
    "lng": -109.43536,
    "state_id": "UT"
  },
  {
    "zip": 84542,
    "lat": 39.54221,
    "lng": -110.73512,
    "state_id": "UT"
  },
  {
    "zip": 84601,
    "lat": 40.22724,
    "lng": -111.69697,
    "state_id": "UT"
  },
  {
    "zip": 84604,
    "lat": 40.34321,
    "lng": -111.5913,
    "state_id": "UT"
  },
  {
    "zip": 84606,
    "lat": 40.21444,
    "lng": -111.62555,
    "state_id": "UT"
  },
  {
    "zip": 84620,
    "lat": 38.91484,
    "lng": -111.92771,
    "state_id": "UT"
  },
  {
    "zip": 84621,
    "lat": 39.05132,
    "lng": -111.82568,
    "state_id": "UT"
  },
  {
    "zip": 84622,
    "lat": 39.10383,
    "lng": -111.82172,
    "state_id": "UT"
  },
  {
    "zip": 84623,
    "lat": 39.45609,
    "lng": -111.58605,
    "state_id": "UT"
  },
  {
    "zip": 84624,
    "lat": 39.40755,
    "lng": -112.83985,
    "state_id": "UT"
  },
  {
    "zip": 84626,
    "lat": 39.90261,
    "lng": -112.00195,
    "state_id": "UT"
  },
  {
    "zip": 84627,
    "lat": 39.35416,
    "lng": -111.56517,
    "state_id": "UT"
  },
  {
    "zip": 84628,
    "lat": 39.97343,
    "lng": -112.20579,
    "state_id": "UT"
  },
  {
    "zip": 84629,
    "lat": 39.80007,
    "lng": -111.47028,
    "state_id": "UT"
  },
  {
    "zip": 84630,
    "lat": 39.29518,
    "lng": -111.81731,
    "state_id": "UT"
  },
  {
    "zip": 84631,
    "lat": 38.95992,
    "lng": -112.38345,
    "state_id": "UT"
  },
  {
    "zip": 84632,
    "lat": 39.62017,
    "lng": -111.65825,
    "state_id": "UT"
  },
  {
    "zip": 84633,
    "lat": 39.97,
    "lng": -111.90429,
    "state_id": "UT"
  },
  {
    "zip": 84634,
    "lat": 39.1584,
    "lng": -111.81108,
    "state_id": "UT"
  },
  {
    "zip": 84635,
    "lat": 39.40218,
    "lng": -112.72509,
    "state_id": "UT"
  },
  {
    "zip": 84636,
    "lat": 39.14063,
    "lng": -112.33894,
    "state_id": "UT"
  },
  {
    "zip": 84637,
    "lat": 38.72278,
    "lng": -112.49285,
    "state_id": "UT"
  },
  {
    "zip": 84638,
    "lat": 39.49963,
    "lng": -112.26359,
    "state_id": "UT"
  },
  {
    "zip": 84639,
    "lat": 39.50438,
    "lng": -111.88128,
    "state_id": "UT"
  },
  {
    "zip": 84640,
    "lat": 39.49824,
    "lng": -112.38168,
    "state_id": "UT"
  },
  {
    "zip": 84642,
    "lat": 39.28853,
    "lng": -111.61537,
    "state_id": "UT"
  },
  {
    "zip": 84643,
    "lat": 39.11518,
    "lng": -111.71294,
    "state_id": "UT"
  },
  {
    "zip": 84645,
    "lat": 39.84447,
    "lng": -111.8442,
    "state_id": "UT"
  },
  {
    "zip": 84646,
    "lat": 39.54217,
    "lng": -111.61378,
    "state_id": "UT"
  },
  {
    "zip": 84647,
    "lat": 39.54111,
    "lng": -111.41587,
    "state_id": "UT"
  },
  {
    "zip": 84648,
    "lat": 39.68585,
    "lng": -111.86448,
    "state_id": "UT"
  },
  {
    "zip": 84649,
    "lat": 39.33902,
    "lng": -112.33759,
    "state_id": "UT"
  },
  {
    "zip": 84651,
    "lat": 39.95422,
    "lng": -111.71464,
    "state_id": "UT"
  },
  {
    "zip": 84652,
    "lat": 39.01694,
    "lng": -111.86822,
    "state_id": "UT"
  },
  {
    "zip": 84653,
    "lat": 40.02761,
    "lng": -111.64407,
    "state_id": "UT"
  },
  {
    "zip": 84654,
    "lat": 38.87875,
    "lng": -111.62476,
    "state_id": "UT"
  },
  {
    "zip": 84655,
    "lat": 39.97373,
    "lng": -111.80373,
    "state_id": "UT"
  },
  {
    "zip": 84656,
    "lat": 39.13949,
    "lng": -112.07459,
    "state_id": "UT"
  },
  {
    "zip": 84657,
    "lat": 38.81494,
    "lng": -111.9393,
    "state_id": "UT"
  },
  {
    "zip": 84660,
    "lat": 40.06445,
    "lng": -111.56862,
    "state_id": "UT"
  },
  {
    "zip": 84662,
    "lat": 39.43194,
    "lng": -111.47365,
    "state_id": "UT"
  },
  {
    "zip": 84663,
    "lat": 40.16568,
    "lng": -111.49505,
    "state_id": "UT"
  },
  {
    "zip": 84664,
    "lat": 40.11509,
    "lng": -111.56511,
    "state_id": "UT"
  },
  {
    "zip": 84665,
    "lat": 39.19799,
    "lng": -111.66517,
    "state_id": "UT"
  },
  {
    "zip": 84667,
    "lat": 39.49209,
    "lng": -111.65333,
    "state_id": "UT"
  },
  {
    "zip": 84701,
    "lat": 38.67463,
    "lng": -111.83313,
    "state_id": "UT"
  },
  {
    "zip": 84710,
    "lat": 37.4756,
    "lng": -112.49915,
    "state_id": "UT"
  },
  {
    "zip": 84711,
    "lat": 38.67624,
    "lng": -112.00587,
    "state_id": "UT"
  },
  {
    "zip": 84712,
    "lat": 37.99958,
    "lng": -111.94426,
    "state_id": "UT"
  },
  {
    "zip": 84713,
    "lat": 38.32633,
    "lng": -112.54937,
    "state_id": "UT"
  },
  {
    "zip": 84714,
    "lat": 37.8477,
    "lng": -113.72807,
    "state_id": "UT"
  },
  {
    "zip": 84715,
    "lat": 38.28997,
    "lng": -111.54894,
    "state_id": "UT"
  },
  {
    "zip": 84716,
    "lat": 37.96476,
    "lng": -111.44665,
    "state_id": "UT"
  },
  {
    "zip": 84718,
    "lat": 37.53954,
    "lng": -112.04512,
    "state_id": "UT"
  },
  {
    "zip": 84719,
    "lat": 37.67542,
    "lng": -112.80149,
    "state_id": "UT"
  },
  {
    "zip": 84720,
    "lat": 37.58866,
    "lng": -113.16826,
    "state_id": "UT"
  },
  {
    "zip": 84721,
    "lat": 37.87876,
    "lng": -113.20454,
    "state_id": "UT"
  },
  {
    "zip": 84722,
    "lat": 37.44017,
    "lng": -113.61312,
    "state_id": "UT"
  },
  {
    "zip": 84723,
    "lat": 38.17953,
    "lng": -112.25043,
    "state_id": "UT"
  },
  {
    "zip": 84724,
    "lat": 38.67525,
    "lng": -112.14893,
    "state_id": "UT"
  },
  {
    "zip": 84725,
    "lat": 37.53615,
    "lng": -113.76936,
    "state_id": "UT"
  },
  {
    "zip": 84726,
    "lat": 37.73904,
    "lng": -111.59129,
    "state_id": "UT"
  },
  {
    "zip": 84728,
    "lat": 39.25789,
    "lng": -113.92729,
    "state_id": "UT"
  },
  {
    "zip": 84729,
    "lat": 37.36657,
    "lng": -112.60246,
    "state_id": "UT"
  },
  {
    "zip": 84730,
    "lat": 38.73619,
    "lng": -112.00509,
    "state_id": "UT"
  },
  {
    "zip": 84731,
    "lat": 38.27526,
    "lng": -112.85381,
    "state_id": "UT"
  },
  {
    "zip": 84732,
    "lat": 38.4454,
    "lng": -111.9023,
    "state_id": "UT"
  },
  {
    "zip": 84733,
    "lat": 37.33081,
    "lng": -113.8966,
    "state_id": "UT"
  },
  {
    "zip": 84734,
    "lat": 38.30074,
    "lng": -110.64158,
    "state_id": "UT"
  },
  {
    "zip": 84735,
    "lat": 37.64061,
    "lng": -112.43069,
    "state_id": "UT"
  },
  {
    "zip": 84736,
    "lat": 37.55015,
    "lng": -111.99608,
    "state_id": "UT"
  },
  {
    "zip": 84737,
    "lat": 37.09795,
    "lng": -113.23307,
    "state_id": "UT"
  },
  {
    "zip": 84738,
    "lat": 37.17879,
    "lng": -113.70683,
    "state_id": "UT"
  },
  {
    "zip": 84739,
    "lat": 38.63428,
    "lng": -112.31568,
    "state_id": "UT"
  },
  {
    "zip": 84740,
    "lat": 38.27519,
    "lng": -112.25306,
    "state_id": "UT"
  },
  {
    "zip": 84741,
    "lat": 37.18055,
    "lng": -112.30607,
    "state_id": "UT"
  },
  {
    "zip": 84742,
    "lat": 37.54024,
    "lng": -113.2051,
    "state_id": "UT"
  },
  {
    "zip": 84743,
    "lat": 38.17418,
    "lng": -112.13845,
    "state_id": "UT"
  },
  {
    "zip": 84744,
    "lat": 38.58047,
    "lng": -111.89864,
    "state_id": "UT"
  },
  {
    "zip": 84745,
    "lat": 37.23152,
    "lng": -113.24466,
    "state_id": "UT"
  },
  {
    "zip": 84746,
    "lat": 37.24275,
    "lng": -113.35624,
    "state_id": "UT"
  },
  {
    "zip": 84747,
    "lat": 38.40658,
    "lng": -111.64383,
    "state_id": "UT"
  },
  {
    "zip": 84749,
    "lat": 38.41847,
    "lng": -111.53617,
    "state_id": "UT"
  },
  {
    "zip": 84750,
    "lat": 38.44529,
    "lng": -112.19569,
    "state_id": "UT"
  },
  {
    "zip": 84751,
    "lat": 38.48633,
    "lng": -113.0578,
    "state_id": "UT"
  },
  {
    "zip": 84752,
    "lat": 38.20899,
    "lng": -112.89068,
    "state_id": "UT"
  },
  {
    "zip": 84753,
    "lat": 37.98971,
    "lng": -113.88556,
    "state_id": "UT"
  },
  {
    "zip": 84754,
    "lat": 38.62331,
    "lng": -112.10996,
    "state_id": "UT"
  },
  {
    "zip": 84755,
    "lat": 37.23671,
    "lng": -112.78611,
    "state_id": "UT"
  },
  {
    "zip": 84756,
    "lat": 37.59004,
    "lng": -113.54194,
    "state_id": "UT"
  },
  {
    "zip": 84757,
    "lat": 37.45599,
    "lng": -113.25131,
    "state_id": "UT"
  },
  {
    "zip": 84758,
    "lat": 37.30207,
    "lng": -112.76235,
    "state_id": "UT"
  },
  {
    "zip": 84759,
    "lat": 37.90784,
    "lng": -112.39486,
    "state_id": "UT"
  },
  {
    "zip": 84760,
    "lat": 37.93734,
    "lng": -112.67608,
    "state_id": "UT"
  },
  {
    "zip": 84761,
    "lat": 37.87441,
    "lng": -112.84236,
    "state_id": "UT"
  },
  {
    "zip": 84762,
    "lat": 37.47132,
    "lng": -112.74162,
    "state_id": "UT"
  },
  {
    "zip": 84763,
    "lat": 37.14232,
    "lng": -113.07054,
    "state_id": "UT"
  },
  {
    "zip": 84764,
    "lat": 37.61843,
    "lng": -112.17352,
    "state_id": "UT"
  },
  {
    "zip": 84765,
    "lat": 37.12813,
    "lng": -113.67604,
    "state_id": "UT"
  },
  {
    "zip": 84766,
    "lat": 38.57636,
    "lng": -112.31712,
    "state_id": "UT"
  },
  {
    "zip": 84767,
    "lat": 37.237,
    "lng": -112.98642,
    "state_id": "UT"
  },
  {
    "zip": 84770,
    "lat": 37.17567,
    "lng": -113.60824,
    "state_id": "UT"
  },
  {
    "zip": 84772,
    "lat": 37.78078,
    "lng": -112.93863,
    "state_id": "UT"
  },
  {
    "zip": 84773,
    "lat": 38.2265,
    "lng": -111.34215,
    "state_id": "UT"
  },
  {
    "zip": 84774,
    "lat": 37.29052,
    "lng": -113.2812,
    "state_id": "UT"
  },
  {
    "zip": 84775,
    "lat": 38.07,
    "lng": -111.00525,
    "state_id": "UT"
  },
  {
    "zip": 84776,
    "lat": 37.63061,
    "lng": -112.08028,
    "state_id": "UT"
  },
  {
    "zip": 84779,
    "lat": 37.33956,
    "lng": -113.12071,
    "state_id": "UT"
  },
  {
    "zip": 84780,
    "lat": 37.12645,
    "lng": -113.49026,
    "state_id": "UT"
  },
  {
    "zip": 84781,
    "lat": 37.41281,
    "lng": -113.46609,
    "state_id": "UT"
  },
  {
    "zip": 84782,
    "lat": 37.34955,
    "lng": -113.66483,
    "state_id": "UT"
  },
  {
    "zip": 84783,
    "lat": 37.24641,
    "lng": -113.69304,
    "state_id": "UT"
  },
  {
    "zip": 84784,
    "lat": 37.03294,
    "lng": -112.99447,
    "state_id": "UT"
  },
  {
    "zip": 84790,
    "lat": 37.04637,
    "lng": -113.55812,
    "state_id": "UT"
  },
  {
    "zip": 20105,
    "lat": 38.96273,
    "lng": -77.61002,
    "state_id": "VA"
  },
  {
    "zip": 20106,
    "lat": 38.68699,
    "lng": -78.01175,
    "state_id": "VA"
  },
  {
    "zip": 20109,
    "lat": 38.79282,
    "lng": -77.52658,
    "state_id": "VA"
  },
  {
    "zip": 20110,
    "lat": 38.74711,
    "lng": -77.48591,
    "state_id": "VA"
  },
  {
    "zip": 20111,
    "lat": 38.74928,
    "lng": -77.43012,
    "state_id": "VA"
  },
  {
    "zip": 20112,
    "lat": 38.66651,
    "lng": -77.42484,
    "state_id": "VA"
  },
  {
    "zip": 20115,
    "lat": 38.82155,
    "lng": -77.9001,
    "state_id": "VA"
  },
  {
    "zip": 20117,
    "lat": 38.99204,
    "lng": -77.74336,
    "state_id": "VA"
  },
  {
    "zip": 20118,
    "lat": 38.9678,
    "lng": -77.73746,
    "state_id": "VA"
  },
  {
    "zip": 20119,
    "lat": 38.61839,
    "lng": -77.62492,
    "state_id": "VA"
  },
  {
    "zip": 20120,
    "lat": 38.85433,
    "lng": -77.47715,
    "state_id": "VA"
  },
  {
    "zip": 20121,
    "lat": 38.81767,
    "lng": -77.46029,
    "state_id": "VA"
  },
  {
    "zip": 20124,
    "lat": 38.7815,
    "lng": -77.39162,
    "state_id": "VA"
  },
  {
    "zip": 20129,
    "lat": 39.16223,
    "lng": -77.6018,
    "state_id": "VA"
  },
  {
    "zip": 20130,
    "lat": 39.02747,
    "lng": -77.9491,
    "state_id": "VA"
  },
  {
    "zip": 20132,
    "lat": 39.16773,
    "lng": -77.72706,
    "state_id": "VA"
  },
  {
    "zip": 20135,
    "lat": 39.09525,
    "lng": -77.87125,
    "state_id": "VA"
  },
  {
    "zip": 20136,
    "lat": 38.73801,
    "lng": -77.55371,
    "state_id": "VA"
  },
  {
    "zip": 20137,
    "lat": 38.81012,
    "lng": -77.72103,
    "state_id": "VA"
  },
  {
    "zip": 20139,
    "lat": 38.65629,
    "lng": -77.69719,
    "state_id": "VA"
  },
  {
    "zip": 20141,
    "lat": 39.11358,
    "lng": -77.78901,
    "state_id": "VA"
  },
  {
    "zip": 20143,
    "lat": 38.86001,
    "lng": -77.56762,
    "state_id": "VA"
  },
  {
    "zip": 20144,
    "lat": 38.91354,
    "lng": -77.94242,
    "state_id": "VA"
  },
  {
    "zip": 20147,
    "lat": 39.04152,
    "lng": -77.47954,
    "state_id": "VA"
  },
  {
    "zip": 20148,
    "lat": 38.99608,
    "lng": -77.52734,
    "state_id": "VA"
  },
  {
    "zip": 20151,
    "lat": 38.89574,
    "lng": -77.44484,
    "state_id": "VA"
  },
  {
    "zip": 20152,
    "lat": 38.91757,
    "lng": -77.50414,
    "state_id": "VA"
  },
  {
    "zip": 20155,
    "lat": 38.81015,
    "lng": -77.61837,
    "state_id": "VA"
  },
  {
    "zip": 20158,
    "lat": 39.13859,
    "lng": -77.65443,
    "state_id": "VA"
  },
  {
    "zip": 20164,
    "lat": 39.0127,
    "lng": -77.39565,
    "state_id": "VA"
  },
  {
    "zip": 20165,
    "lat": 39.05056,
    "lng": -77.3887,
    "state_id": "VA"
  },
  {
    "zip": 20166,
    "lat": 38.9858,
    "lng": -77.45517,
    "state_id": "VA"
  },
  {
    "zip": 20169,
    "lat": 38.87344,
    "lng": -77.6454,
    "state_id": "VA"
  },
  {
    "zip": 20170,
    "lat": 38.98079,
    "lng": -77.38038,
    "state_id": "VA"
  },
  {
    "zip": 20171,
    "lat": 38.92363,
    "lng": -77.39821,
    "state_id": "VA"
  },
  {
    "zip": 20175,
    "lat": 39.05928,
    "lng": -77.60071,
    "state_id": "VA"
  },
  {
    "zip": 20176,
    "lat": 39.1821,
    "lng": -77.53593,
    "state_id": "VA"
  },
  {
    "zip": 20180,
    "lat": 39.26836,
    "lng": -77.63875,
    "state_id": "VA"
  },
  {
    "zip": 20181,
    "lat": 38.6869,
    "lng": -77.57076,
    "state_id": "VA"
  },
  {
    "zip": 20184,
    "lat": 39.00275,
    "lng": -77.88265,
    "state_id": "VA"
  },
  {
    "zip": 20186,
    "lat": 38.70158,
    "lng": -77.84441,
    "state_id": "VA"
  },
  {
    "zip": 20187,
    "lat": 38.72258,
    "lng": -77.74269,
    "state_id": "VA"
  },
  {
    "zip": 20190,
    "lat": 38.95978,
    "lng": -77.33688,
    "state_id": "VA"
  },
  {
    "zip": 20191,
    "lat": 38.93382,
    "lng": -77.35084,
    "state_id": "VA"
  },
  {
    "zip": 20194,
    "lat": 38.98034,
    "lng": -77.34299,
    "state_id": "VA"
  },
  {
    "zip": 20197,
    "lat": 39.19499,
    "lng": -77.62629,
    "state_id": "VA"
  },
  {
    "zip": 20198,
    "lat": 38.88034,
    "lng": -77.75758,
    "state_id": "VA"
  },
  {
    "zip": 22003,
    "lat": 38.83076,
    "lng": -77.21443,
    "state_id": "VA"
  },
  {
    "zip": 22015,
    "lat": 38.78812,
    "lng": -77.28116,
    "state_id": "VA"
  },
  {
    "zip": 22025,
    "lat": 38.59932,
    "lng": -77.34028,
    "state_id": "VA"
  },
  {
    "zip": 22026,
    "lat": 38.5666,
    "lng": -77.29482,
    "state_id": "VA"
  },
  {
    "zip": 22027,
    "lat": 38.89452,
    "lng": -77.22126,
    "state_id": "VA"
  },
  {
    "zip": 22030,
    "lat": 38.83937,
    "lng": -77.34167,
    "state_id": "VA"
  },
  {
    "zip": 22031,
    "lat": 38.85999,
    "lng": -77.26006,
    "state_id": "VA"
  },
  {
    "zip": 22032,
    "lat": 38.82046,
    "lng": -77.28999,
    "state_id": "VA"
  },
  {
    "zip": 22033,
    "lat": 38.87521,
    "lng": -77.38454,
    "state_id": "VA"
  },
  {
    "zip": 22035,
    "lat": 38.85526,
    "lng": -77.35826,
    "state_id": "VA"
  },
  {
    "zip": 22039,
    "lat": 38.75269,
    "lng": -77.31754,
    "state_id": "VA"
  },
  {
    "zip": 22041,
    "lat": 38.84806,
    "lng": -77.14197,
    "state_id": "VA"
  },
  {
    "zip": 22042,
    "lat": 38.8644,
    "lng": -77.19563,
    "state_id": "VA"
  },
  {
    "zip": 22043,
    "lat": 38.90029,
    "lng": -77.19732,
    "state_id": "VA"
  },
  {
    "zip": 22044,
    "lat": 38.85971,
    "lng": -77.15559,
    "state_id": "VA"
  },
  {
    "zip": 22046,
    "lat": 38.88711,
    "lng": -77.18076,
    "state_id": "VA"
  },
  {
    "zip": 22060,
    "lat": 38.70543,
    "lng": -77.15639,
    "state_id": "VA"
  },
  {
    "zip": 22066,
    "lat": 39.00895,
    "lng": -77.30026,
    "state_id": "VA"
  },
  {
    "zip": 22079,
    "lat": 38.68071,
    "lng": -77.20888,
    "state_id": "VA"
  },
  {
    "zip": 22101,
    "lat": 38.93942,
    "lng": -77.16663,
    "state_id": "VA"
  },
  {
    "zip": 22102,
    "lat": 38.94813,
    "lng": -77.22788,
    "state_id": "VA"
  },
  {
    "zip": 22124,
    "lat": 38.89056,
    "lng": -77.33028,
    "state_id": "VA"
  },
  {
    "zip": 22125,
    "lat": 38.68228,
    "lng": -77.26075,
    "state_id": "VA"
  },
  {
    "zip": 22134,
    "lat": 38.51861,
    "lng": -77.38479,
    "state_id": "VA"
  },
  {
    "zip": 22150,
    "lat": 38.77242,
    "lng": -77.18599,
    "state_id": "VA"
  },
  {
    "zip": 22151,
    "lat": 38.80286,
    "lng": -77.2095,
    "state_id": "VA"
  },
  {
    "zip": 22152,
    "lat": 38.77578,
    "lng": -77.23156,
    "state_id": "VA"
  },
  {
    "zip": 22153,
    "lat": 38.74484,
    "lng": -77.23465,
    "state_id": "VA"
  },
  {
    "zip": 22172,
    "lat": 38.56708,
    "lng": -77.36486,
    "state_id": "VA"
  },
  {
    "zip": 22180,
    "lat": 38.89655,
    "lng": -77.25486,
    "state_id": "VA"
  },
  {
    "zip": 22181,
    "lat": 38.90635,
    "lng": -77.29438,
    "state_id": "VA"
  },
  {
    "zip": 22182,
    "lat": 38.93568,
    "lng": -77.27194,
    "state_id": "VA"
  },
  {
    "zip": 22185,
    "lat": 38.87463,
    "lng": -77.30401,
    "state_id": "VA"
  },
  {
    "zip": 22191,
    "lat": 38.62411,
    "lng": -77.26905,
    "state_id": "VA"
  },
  {
    "zip": 22192,
    "lat": 38.68334,
    "lng": -77.3161,
    "state_id": "VA"
  },
  {
    "zip": 22193,
    "lat": 38.64308,
    "lng": -77.34807,
    "state_id": "VA"
  },
  {
    "zip": 22201,
    "lat": 38.88665,
    "lng": -77.09518,
    "state_id": "VA"
  },
  {
    "zip": 22202,
    "lat": 38.85639,
    "lng": -77.0517,
    "state_id": "VA"
  },
  {
    "zip": 22203,
    "lat": 38.87376,
    "lng": -77.11726,
    "state_id": "VA"
  },
  {
    "zip": 22204,
    "lat": 38.86083,
    "lng": -77.09894,
    "state_id": "VA"
  },
  {
    "zip": 22205,
    "lat": 38.88348,
    "lng": -77.13957,
    "state_id": "VA"
  },
  {
    "zip": 22206,
    "lat": 38.84412,
    "lng": -77.08891,
    "state_id": "VA"
  },
  {
    "zip": 22207,
    "lat": 38.90662,
    "lng": -77.12384,
    "state_id": "VA"
  },
  {
    "zip": 22209,
    "lat": 38.89469,
    "lng": -77.07532,
    "state_id": "VA"
  },
  {
    "zip": 22211,
    "lat": 38.8802,
    "lng": -77.07067,
    "state_id": "VA"
  },
  {
    "zip": 22213,
    "lat": 38.89529,
    "lng": -77.16244,
    "state_id": "VA"
  },
  {
    "zip": 22214,
    "lat": 38.86883,
    "lng": -77.07397,
    "state_id": "VA"
  },
  {
    "zip": 22301,
    "lat": 38.82002,
    "lng": -77.05961,
    "state_id": "VA"
  },
  {
    "zip": 22302,
    "lat": 38.8281,
    "lng": -77.08485,
    "state_id": "VA"
  },
  {
    "zip": 22303,
    "lat": 38.79331,
    "lng": -77.07853,
    "state_id": "VA"
  },
  {
    "zip": 22304,
    "lat": 38.81377,
    "lng": -77.11394,
    "state_id": "VA"
  },
  {
    "zip": 22305,
    "lat": 38.83646,
    "lng": -77.06247,
    "state_id": "VA"
  },
  {
    "zip": 22306,
    "lat": 38.7567,
    "lng": -77.0929,
    "state_id": "VA"
  },
  {
    "zip": 22307,
    "lat": 38.77203,
    "lng": -77.05739,
    "state_id": "VA"
  },
  {
    "zip": 22308,
    "lat": 38.73131,
    "lng": -77.05774,
    "state_id": "VA"
  },
  {
    "zip": 22309,
    "lat": 38.71932,
    "lng": -77.10725,
    "state_id": "VA"
  },
  {
    "zip": 22310,
    "lat": 38.78468,
    "lng": -77.12148,
    "state_id": "VA"
  },
  {
    "zip": 22311,
    "lat": 38.83438,
    "lng": -77.12238,
    "state_id": "VA"
  },
  {
    "zip": 22312,
    "lat": 38.81648,
    "lng": -77.15368,
    "state_id": "VA"
  },
  {
    "zip": 22314,
    "lat": 38.80723,
    "lng": -77.05683,
    "state_id": "VA"
  },
  {
    "zip": 22315,
    "lat": 38.756,
    "lng": -77.15058,
    "state_id": "VA"
  },
  {
    "zip": 22401,
    "lat": 38.29918,
    "lng": -77.48713,
    "state_id": "VA"
  },
  {
    "zip": 22405,
    "lat": 38.31521,
    "lng": -77.40371,
    "state_id": "VA"
  },
  {
    "zip": 22406,
    "lat": 38.40022,
    "lng": -77.55114,
    "state_id": "VA"
  },
  {
    "zip": 22407,
    "lat": 38.2834,
    "lng": -77.57529,
    "state_id": "VA"
  },
  {
    "zip": 22408,
    "lat": 38.22075,
    "lng": -77.44514,
    "state_id": "VA"
  },
  {
    "zip": 22427,
    "lat": 38.09231,
    "lng": -77.27064,
    "state_id": "VA"
  },
  {
    "zip": 22432,
    "lat": 37.86906,
    "lng": -76.34909,
    "state_id": "VA"
  },
  {
    "zip": 22433,
    "lat": 38.3579,
    "lng": -77.86348,
    "state_id": "VA"
  },
  {
    "zip": 22435,
    "lat": 37.9717,
    "lng": -76.57188,
    "state_id": "VA"
  },
  {
    "zip": 22436,
    "lat": 38.04212,
    "lng": -77.07106,
    "state_id": "VA"
  },
  {
    "zip": 22437,
    "lat": 37.77657,
    "lng": -76.80916,
    "state_id": "VA"
  },
  {
    "zip": 22438,
    "lat": 38.03834,
    "lng": -76.99114,
    "state_id": "VA"
  },
  {
    "zip": 22443,
    "lat": 38.17862,
    "lng": -76.9962,
    "state_id": "VA"
  },
  {
    "zip": 22448,
    "lat": 38.3405,
    "lng": -77.03217,
    "state_id": "VA"
  },
  {
    "zip": 22454,
    "lat": 37.84077,
    "lng": -76.84591,
    "state_id": "VA"
  },
  {
    "zip": 22460,
    "lat": 37.87589,
    "lng": -76.60731,
    "state_id": "VA"
  },
  {
    "zip": 22469,
    "lat": 38.07,
    "lng": -76.6536,
    "state_id": "VA"
  },
  {
    "zip": 22473,
    "lat": 37.88529,
    "lng": -76.41935,
    "state_id": "VA"
  },
  {
    "zip": 22476,
    "lat": 38.04281,
    "lng": -77.07113,
    "state_id": "VA"
  },
  {
    "zip": 22480,
    "lat": 37.66653,
    "lng": -76.41511,
    "state_id": "VA"
  },
  {
    "zip": 22482,
    "lat": 37.73409,
    "lng": -76.35442,
    "state_id": "VA"
  },
  {
    "zip": 22485,
    "lat": 38.27166,
    "lng": -77.16006,
    "state_id": "VA"
  },
  {
    "zip": 22488,
    "lat": 38.04632,
    "lng": -76.59111,
    "state_id": "VA"
  },
  {
    "zip": 22503,
    "lat": 37.76227,
    "lng": -76.50215,
    "state_id": "VA"
  },
  {
    "zip": 22504,
    "lat": 37.76328,
    "lng": -76.71059,
    "state_id": "VA"
  },
  {
    "zip": 22508,
    "lat": 38.31812,
    "lng": -77.78725,
    "state_id": "VA"
  },
  {
    "zip": 22509,
    "lat": 38.12101,
    "lng": -77.07479,
    "state_id": "VA"
  },
  {
    "zip": 22511,
    "lat": 37.98868,
    "lng": -76.50515,
    "state_id": "VA"
  },
  {
    "zip": 22514,
    "lat": 37.97427,
    "lng": -77.24636,
    "state_id": "VA"
  },
  {
    "zip": 22520,
    "lat": 38.11794,
    "lng": -76.80415,
    "state_id": "VA"
  },
  {
    "zip": 22529,
    "lat": 38.00963,
    "lng": -76.68586,
    "state_id": "VA"
  },
  {
    "zip": 22530,
    "lat": 37.90103,
    "lng": -76.28057,
    "state_id": "VA"
  },
  {
    "zip": 22534,
    "lat": 38.0794,
    "lng": -77.67173,
    "state_id": "VA"
  },
  {
    "zip": 22535,
    "lat": 38.1521,
    "lng": -77.16466,
    "state_id": "VA"
  },
  {
    "zip": 22538,
    "lat": 38.2062,
    "lng": -77.25121,
    "state_id": "VA"
  },
  {
    "zip": 22539,
    "lat": 37.85905,
    "lng": -76.28774,
    "state_id": "VA"
  },
  {
    "zip": 22542,
    "lat": 38.29321,
    "lng": -77.88264,
    "state_id": "VA"
  },
  {
    "zip": 22546,
    "lat": 37.94192,
    "lng": -77.44866,
    "state_id": "VA"
  },
  {
    "zip": 22548,
    "lat": 37.8293,
    "lng": -76.69244,
    "state_id": "VA"
  },
  {
    "zip": 22551,
    "lat": 38.16843,
    "lng": -77.70157,
    "state_id": "VA"
  },
  {
    "zip": 22553,
    "lat": 38.271,
    "lng": -77.64475,
    "state_id": "VA"
  },
  {
    "zip": 22554,
    "lat": 38.42691,
    "lng": -77.37803,
    "state_id": "VA"
  },
  {
    "zip": 22556,
    "lat": 38.47178,
    "lng": -77.51021,
    "state_id": "VA"
  },
  {
    "zip": 22560,
    "lat": 37.91429,
    "lng": -76.96102,
    "state_id": "VA"
  },
  {
    "zip": 22567,
    "lat": 38.23434,
    "lng": -77.92369,
    "state_id": "VA"
  },
  {
    "zip": 22572,
    "lat": 37.97584,
    "lng": -76.77804,
    "state_id": "VA"
  },
  {
    "zip": 22576,
    "lat": 37.69171,
    "lng": -76.43585,
    "state_id": "VA"
  },
  {
    "zip": 22578,
    "lat": 37.65077,
    "lng": -76.36241,
    "state_id": "VA"
  },
  {
    "zip": 22579,
    "lat": 37.80346,
    "lng": -76.32027,
    "state_id": "VA"
  },
  {
    "zip": 22580,
    "lat": 38.10885,
    "lng": -77.44633,
    "state_id": "VA"
  },
  {
    "zip": 22601,
    "lat": 39.17472,
    "lng": -78.17353,
    "state_id": "VA"
  },
  {
    "zip": 22602,
    "lat": 39.14989,
    "lng": -78.26622,
    "state_id": "VA"
  },
  {
    "zip": 22603,
    "lat": 39.27922,
    "lng": -78.20376,
    "state_id": "VA"
  },
  {
    "zip": 22610,
    "lat": 38.81812,
    "lng": -78.27942,
    "state_id": "VA"
  },
  {
    "zip": 22611,
    "lat": 39.16474,
    "lng": -77.98393,
    "state_id": "VA"
  },
  {
    "zip": 22620,
    "lat": 39.0659,
    "lng": -78.03319,
    "state_id": "VA"
  },
  {
    "zip": 22623,
    "lat": 38.85156,
    "lng": -78.13737,
    "state_id": "VA"
  },
  {
    "zip": 22624,
    "lat": 39.26514,
    "lng": -78.09184,
    "state_id": "VA"
  },
  {
    "zip": 22625,
    "lat": 39.37443,
    "lng": -78.30802,
    "state_id": "VA"
  },
  {
    "zip": 22627,
    "lat": 38.76416,
    "lng": -78.11755,
    "state_id": "VA"
  },
  {
    "zip": 22630,
    "lat": 38.92857,
    "lng": -78.17669,
    "state_id": "VA"
  },
  {
    "zip": 22637,
    "lat": 39.25095,
    "lng": -78.35907,
    "state_id": "VA"
  },
  {
    "zip": 22639,
    "lat": 38.81492,
    "lng": -78.02296,
    "state_id": "VA"
  },
  {
    "zip": 22640,
    "lat": 38.8088,
    "lng": -78.12882,
    "state_id": "VA"
  },
  {
    "zip": 22641,
    "lat": 39.08061,
    "lng": -78.39151,
    "state_id": "VA"
  },
  {
    "zip": 22642,
    "lat": 38.9103,
    "lng": -78.04202,
    "state_id": "VA"
  },
  {
    "zip": 22643,
    "lat": 38.88404,
    "lng": -77.98651,
    "state_id": "VA"
  },
  {
    "zip": 22644,
    "lat": 38.95277,
    "lng": -78.48929,
    "state_id": "VA"
  },
  {
    "zip": 22645,
    "lat": 39.02148,
    "lng": -78.27515,
    "state_id": "VA"
  },
  {
    "zip": 22646,
    "lat": 39.07133,
    "lng": -78.04206,
    "state_id": "VA"
  },
  {
    "zip": 22650,
    "lat": 38.75957,
    "lng": -78.35993,
    "state_id": "VA"
  },
  {
    "zip": 22652,
    "lat": 38.83988,
    "lng": -78.42848,
    "state_id": "VA"
  },
  {
    "zip": 22654,
    "lat": 39.03066,
    "lng": -78.51986,
    "state_id": "VA"
  },
  {
    "zip": 22655,
    "lat": 39.06535,
    "lng": -78.2144,
    "state_id": "VA"
  },
  {
    "zip": 22656,
    "lat": 39.21887,
    "lng": -78.08885,
    "state_id": "VA"
  },
  {
    "zip": 22657,
    "lat": 39.00546,
    "lng": -78.37231,
    "state_id": "VA"
  },
  {
    "zip": 22660,
    "lat": 38.94958,
    "lng": -78.43203,
    "state_id": "VA"
  },
  {
    "zip": 22663,
    "lat": 39.05633,
    "lng": -78.11478,
    "state_id": "VA"
  },
  {
    "zip": 22664,
    "lat": 38.8882,
    "lng": -78.53037,
    "state_id": "VA"
  },
  {
    "zip": 22701,
    "lat": 38.43912,
    "lng": -77.99759,
    "state_id": "VA"
  },
  {
    "zip": 22709,
    "lat": 38.33179,
    "lng": -78.21874,
    "state_id": "VA"
  },
  {
    "zip": 22711,
    "lat": 38.46468,
    "lng": -78.27033,
    "state_id": "VA"
  },
  {
    "zip": 22712,
    "lat": 38.56057,
    "lng": -77.75891,
    "state_id": "VA"
  },
  {
    "zip": 22713,
    "lat": 38.54958,
    "lng": -78.13123,
    "state_id": "VA"
  },
  {
    "zip": 22714,
    "lat": 38.52132,
    "lng": -77.89738,
    "state_id": "VA"
  },
  {
    "zip": 22715,
    "lat": 38.41144,
    "lng": -78.18341,
    "state_id": "VA"
  },
  {
    "zip": 22716,
    "lat": 38.62574,
    "lng": -78.11034,
    "state_id": "VA"
  },
  {
    "zip": 22718,
    "lat": 38.47364,
    "lng": -77.82243,
    "state_id": "VA"
  },
  {
    "zip": 22719,
    "lat": 38.53063,
    "lng": -78.2857,
    "state_id": "VA"
  },
  {
    "zip": 22720,
    "lat": 38.47674,
    "lng": -77.64538,
    "state_id": "VA"
  },
  {
    "zip": 22722,
    "lat": 38.46159,
    "lng": -78.22953,
    "state_id": "VA"
  },
  {
    "zip": 22723,
    "lat": 38.34037,
    "lng": -78.38698,
    "state_id": "VA"
  },
  {
    "zip": 22724,
    "lat": 38.61641,
    "lng": -77.90101,
    "state_id": "VA"
  },
  {
    "zip": 22726,
    "lat": 38.40137,
    "lng": -77.82228,
    "state_id": "VA"
  },
  {
    "zip": 22727,
    "lat": 38.3984,
    "lng": -78.29888,
    "state_id": "VA"
  },
  {
    "zip": 22728,
    "lat": 38.58746,
    "lng": -77.694,
    "state_id": "VA"
  },
  {
    "zip": 22729,
    "lat": 38.37307,
    "lng": -78.01428,
    "state_id": "VA"
  },
  {
    "zip": 22730,
    "lat": 38.36732,
    "lng": -78.16271,
    "state_id": "VA"
  },
  {
    "zip": 22731,
    "lat": 38.3366,
    "lng": -78.25749,
    "state_id": "VA"
  },
  {
    "zip": 22732,
    "lat": 38.31466,
    "lng": -78.18995,
    "state_id": "VA"
  },
  {
    "zip": 22733,
    "lat": 38.33041,
    "lng": -78.04156,
    "state_id": "VA"
  },
  {
    "zip": 22734,
    "lat": 38.5263,
    "lng": -77.80406,
    "state_id": "VA"
  },
  {
    "zip": 22735,
    "lat": 38.47751,
    "lng": -78.17377,
    "state_id": "VA"
  },
  {
    "zip": 22736,
    "lat": 38.39738,
    "lng": -77.70494,
    "state_id": "VA"
  },
  {
    "zip": 22737,
    "lat": 38.59064,
    "lng": -78.00326,
    "state_id": "VA"
  },
  {
    "zip": 22738,
    "lat": 38.28824,
    "lng": -78.2774,
    "state_id": "VA"
  },
  {
    "zip": 22740,
    "lat": 38.64178,
    "lng": -78.25706,
    "state_id": "VA"
  },
  {
    "zip": 22741,
    "lat": 38.43522,
    "lng": -77.87336,
    "state_id": "VA"
  },
  {
    "zip": 22742,
    "lat": 38.46197,
    "lng": -77.71673,
    "state_id": "VA"
  },
  {
    "zip": 22743,
    "lat": 38.52137,
    "lng": -78.36909,
    "state_id": "VA"
  },
  {
    "zip": 22747,
    "lat": 38.71213,
    "lng": -78.16735,
    "state_id": "VA"
  },
  {
    "zip": 22749,
    "lat": 38.62021,
    "lng": -78.18178,
    "state_id": "VA"
  },
  {
    "zip": 22801,
    "lat": 38.40459,
    "lng": -78.87716,
    "state_id": "VA"
  },
  {
    "zip": 22802,
    "lat": 38.49949,
    "lng": -78.86195,
    "state_id": "VA"
  },
  {
    "zip": 22807,
    "lat": 38.43417,
    "lng": -78.86733,
    "state_id": "VA"
  },
  {
    "zip": 22810,
    "lat": 38.82936,
    "lng": -78.79788,
    "state_id": "VA"
  },
  {
    "zip": 22811,
    "lat": 38.78999,
    "lng": -78.97873,
    "state_id": "VA"
  },
  {
    "zip": 22812,
    "lat": 38.37724,
    "lng": -79.02623,
    "state_id": "VA"
  },
  {
    "zip": 22815,
    "lat": 38.63019,
    "lng": -78.80938,
    "state_id": "VA"
  },
  {
    "zip": 22820,
    "lat": 38.72812,
    "lng": -79.01629,
    "state_id": "VA"
  },
  {
    "zip": 22821,
    "lat": 38.47071,
    "lng": -79.08519,
    "state_id": "VA"
  },
  {
    "zip": 22824,
    "lat": 38.83901,
    "lng": -78.63065,
    "state_id": "VA"
  },
  {
    "zip": 22827,
    "lat": 38.41139,
    "lng": -78.59939,
    "state_id": "VA"
  },
  {
    "zip": 22830,
    "lat": 38.65653,
    "lng": -78.97464,
    "state_id": "VA"
  },
  {
    "zip": 22831,
    "lat": 38.56888,
    "lng": -79.0885,
    "state_id": "VA"
  },
  {
    "zip": 22832,
    "lat": 38.45465,
    "lng": -78.75839,
    "state_id": "VA"
  },
  {
    "zip": 22834,
    "lat": 38.55867,
    "lng": -78.86025,
    "state_id": "VA"
  },
  {
    "zip": 22835,
    "lat": 38.67241,
    "lng": -78.45251,
    "state_id": "VA"
  },
  {
    "zip": 22840,
    "lat": 38.37711,
    "lng": -78.74042,
    "state_id": "VA"
  },
  {
    "zip": 22841,
    "lat": 38.3392,
    "lng": -78.89684,
    "state_id": "VA"
  },
  {
    "zip": 22842,
    "lat": 38.77578,
    "lng": -78.70521,
    "state_id": "VA"
  },
  {
    "zip": 22843,
    "lat": 38.36852,
    "lng": -79.16038,
    "state_id": "VA"
  },
  {
    "zip": 22844,
    "lat": 38.65573,
    "lng": -78.66018,
    "state_id": "VA"
  },
  {
    "zip": 22845,
    "lat": 38.78759,
    "lng": -78.81508,
    "state_id": "VA"
  },
  {
    "zip": 22846,
    "lat": 38.37957,
    "lng": -78.78885,
    "state_id": "VA"
  },
  {
    "zip": 22847,
    "lat": 38.72007,
    "lng": -78.72132,
    "state_id": "VA"
  },
  {
    "zip": 22849,
    "lat": 38.52589,
    "lng": -78.62304,
    "state_id": "VA"
  },
  {
    "zip": 22850,
    "lat": 38.56071,
    "lng": -78.92444,
    "state_id": "VA"
  },
  {
    "zip": 22851,
    "lat": 38.562,
    "lng": -78.5116,
    "state_id": "VA"
  },
  {
    "zip": 22853,
    "lat": 38.64841,
    "lng": -78.76397,
    "state_id": "VA"
  },
  {
    "zip": 22901,
    "lat": 38.08844,
    "lng": -78.55617,
    "state_id": "VA"
  },
  {
    "zip": 22902,
    "lat": 37.95389,
    "lng": -78.46997,
    "state_id": "VA"
  },
  {
    "zip": 22903,
    "lat": 38.00683,
    "lng": -78.59998,
    "state_id": "VA"
  },
  {
    "zip": 22904,
    "lat": 38.03438,
    "lng": -78.51986,
    "state_id": "VA"
  },
  {
    "zip": 22911,
    "lat": 38.09276,
    "lng": -78.40756,
    "state_id": "VA"
  },
  {
    "zip": 22920,
    "lat": 37.97724,
    "lng": -78.81071,
    "state_id": "VA"
  },
  {
    "zip": 22922,
    "lat": 37.69407,
    "lng": -78.9216,
    "state_id": "VA"
  },
  {
    "zip": 22923,
    "lat": 38.188,
    "lng": -78.32302,
    "state_id": "VA"
  },
  {
    "zip": 22931,
    "lat": 37.89782,
    "lng": -78.70609,
    "state_id": "VA"
  },
  {
    "zip": 22932,
    "lat": 38.15164,
    "lng": -78.69428,
    "state_id": "VA"
  },
  {
    "zip": 22935,
    "lat": 38.26218,
    "lng": -78.55554,
    "state_id": "VA"
  },
  {
    "zip": 22936,
    "lat": 38.16728,
    "lng": -78.4988,
    "state_id": "VA"
  },
  {
    "zip": 22937,
    "lat": 37.80525,
    "lng": -78.61617,
    "state_id": "VA"
  },
  {
    "zip": 22938,
    "lat": 37.86066,
    "lng": -78.79452,
    "state_id": "VA"
  },
  {
    "zip": 22939,
    "lat": 38.106,
    "lng": -78.97414,
    "state_id": "VA"
  },
  {
    "zip": 22940,
    "lat": 38.20259,
    "lng": -78.59264,
    "state_id": "VA"
  },
  {
    "zip": 22942,
    "lat": 38.10968,
    "lng": -78.19864,
    "state_id": "VA"
  },
  {
    "zip": 22943,
    "lat": 38.03675,
    "lng": -78.77112,
    "state_id": "VA"
  },
  {
    "zip": 22946,
    "lat": 37.85604,
    "lng": -78.56991,
    "state_id": "VA"
  },
  {
    "zip": 22947,
    "lat": 38.04553,
    "lng": -78.32738,
    "state_id": "VA"
  },
  {
    "zip": 22948,
    "lat": 38.35712,
    "lng": -78.12292,
    "state_id": "VA"
  },
  {
    "zip": 22949,
    "lat": 37.7872,
    "lng": -78.87341,
    "state_id": "VA"
  },
  {
    "zip": 22952,
    "lat": 37.96317,
    "lng": -78.9651,
    "state_id": "VA"
  },
  {
    "zip": 22958,
    "lat": 37.90201,
    "lng": -78.90045,
    "state_id": "VA"
  },
  {
    "zip": 22959,
    "lat": 37.94084,
    "lng": -78.65369,
    "state_id": "VA"
  },
  {
    "zip": 22960,
    "lat": 38.22425,
    "lng": -78.07207,
    "state_id": "VA"
  },
  {
    "zip": 22963,
    "lat": 37.84921,
    "lng": -78.28843,
    "state_id": "VA"
  },
  {
    "zip": 22964,
    "lat": 37.72093,
    "lng": -79.00043,
    "state_id": "VA"
  },
  {
    "zip": 22967,
    "lat": 37.79497,
    "lng": -79.01438,
    "state_id": "VA"
  },
  {
    "zip": 22968,
    "lat": 38.24106,
    "lng": -78.39722,
    "state_id": "VA"
  },
  {
    "zip": 22969,
    "lat": 37.79725,
    "lng": -78.69451,
    "state_id": "VA"
  },
  {
    "zip": 22971,
    "lat": 37.74389,
    "lng": -78.80016,
    "state_id": "VA"
  },
  {
    "zip": 22972,
    "lat": 38.21615,
    "lng": -78.23029,
    "state_id": "VA"
  },
  {
    "zip": 22973,
    "lat": 38.33622,
    "lng": -78.46989,
    "state_id": "VA"
  },
  {
    "zip": 22974,
    "lat": 37.96121,
    "lng": -78.2794,
    "state_id": "VA"
  },
  {
    "zip": 22976,
    "lat": 37.83379,
    "lng": -79.05924,
    "state_id": "VA"
  },
  {
    "zip": 22980,
    "lat": 38.09565,
    "lng": -78.88513,
    "state_id": "VA"
  },
  {
    "zip": 22989,
    "lat": 38.29122,
    "lng": -78.12248,
    "state_id": "VA"
  },
  {
    "zip": 23002,
    "lat": 37.35065,
    "lng": -77.96877,
    "state_id": "VA"
  },
  {
    "zip": 23004,
    "lat": 37.68225,
    "lng": -78.40866,
    "state_id": "VA"
  },
  {
    "zip": 23005,
    "lat": 37.75956,
    "lng": -77.48221,
    "state_id": "VA"
  },
  {
    "zip": 23009,
    "lat": 37.81386,
    "lng": -77.18737,
    "state_id": "VA"
  },
  {
    "zip": 23011,
    "lat": 37.47681,
    "lng": -76.80954,
    "state_id": "VA"
  },
  {
    "zip": 23015,
    "lat": 37.92909,
    "lng": -77.62554,
    "state_id": "VA"
  },
  {
    "zip": 23021,
    "lat": 37.39782,
    "lng": -76.37016,
    "state_id": "VA"
  },
  {
    "zip": 23022,
    "lat": 37.73417,
    "lng": -78.26418,
    "state_id": "VA"
  },
  {
    "zip": 23023,
    "lat": 37.77866,
    "lng": -76.95957,
    "state_id": "VA"
  },
  {
    "zip": 23024,
    "lat": 37.9254,
    "lng": -77.77924,
    "state_id": "VA"
  },
  {
    "zip": 23025,
    "lat": 37.41288,
    "lng": -76.36154,
    "state_id": "VA"
  },
  {
    "zip": 23027,
    "lat": 37.64541,
    "lng": -78.13391,
    "state_id": "VA"
  },
  {
    "zip": 23030,
    "lat": 37.34627,
    "lng": -77.05928,
    "state_id": "VA"
  },
  {
    "zip": 23032,
    "lat": 37.65463,
    "lng": -76.67968,
    "state_id": "VA"
  },
  {
    "zip": 23035,
    "lat": 37.49456,
    "lng": -76.38857,
    "state_id": "VA"
  },
  {
    "zip": 23038,
    "lat": 37.75544,
    "lng": -78.14779,
    "state_id": "VA"
  },
  {
    "zip": 23039,
    "lat": 37.65579,
    "lng": -77.8063,
    "state_id": "VA"
  },
  {
    "zip": 23040,
    "lat": 37.50349,
    "lng": -78.24753,
    "state_id": "VA"
  },
  {
    "zip": 23043,
    "lat": 37.55444,
    "lng": -76.34754,
    "state_id": "VA"
  },
  {
    "zip": 23045,
    "lat": 37.42929,
    "lng": -76.26506,
    "state_id": "VA"
  },
  {
    "zip": 23047,
    "lat": 37.84226,
    "lng": -77.47358,
    "state_id": "VA"
  },
  {
    "zip": 23050,
    "lat": 37.50053,
    "lng": -76.43588,
    "state_id": "VA"
  },
  {
    "zip": 23055,
    "lat": 37.77493,
    "lng": -78.22126,
    "state_id": "VA"
  },
  {
    "zip": 23056,
    "lat": 37.4261,
    "lng": -76.37424,
    "state_id": "VA"
  },
  {
    "zip": 23059,
    "lat": 37.70488,
    "lng": -77.56251,
    "state_id": "VA"
  },
  {
    "zip": 23060,
    "lat": 37.65986,
    "lng": -77.53341,
    "state_id": "VA"
  },
  {
    "zip": 23061,
    "lat": 37.4337,
    "lng": -76.55228,
    "state_id": "VA"
  },
  {
    "zip": 23062,
    "lat": 37.25759,
    "lng": -76.50404,
    "state_id": "VA"
  },
  {
    "zip": 23063,
    "lat": 37.71652,
    "lng": -78.00165,
    "state_id": "VA"
  },
  {
    "zip": 23064,
    "lat": 37.49674,
    "lng": -76.29979,
    "state_id": "VA"
  },
  {
    "zip": 23065,
    "lat": 37.80389,
    "lng": -77.93212,
    "state_id": "VA"
  },
  {
    "zip": 23066,
    "lat": 37.49869,
    "lng": -76.28602,
    "state_id": "VA"
  },
  {
    "zip": 23068,
    "lat": 37.49434,
    "lng": -76.33729,
    "state_id": "VA"
  },
  {
    "zip": 23069,
    "lat": 37.77171,
    "lng": -77.32083,
    "state_id": "VA"
  },
  {
    "zip": 23070,
    "lat": 37.55244,
    "lng": -76.38997,
    "state_id": "VA"
  },
  {
    "zip": 23071,
    "lat": 37.54293,
    "lng": -76.45146,
    "state_id": "VA"
  },
  {
    "zip": 23072,
    "lat": 37.29641,
    "lng": -76.48182,
    "state_id": "VA"
  },
  {
    "zip": 23075,
    "lat": 37.54867,
    "lng": -77.31773,
    "state_id": "VA"
  },
  {
    "zip": 23076,
    "lat": 37.47556,
    "lng": -76.31358,
    "state_id": "VA"
  },
  {
    "zip": 23079,
    "lat": 37.72321,
    "lng": -76.69114,
    "state_id": "VA"
  },
  {
    "zip": 23083,
    "lat": 37.31387,
    "lng": -78.121,
    "state_id": "VA"
  },
  {
    "zip": 23084,
    "lat": 37.8846,
    "lng": -78.11014,
    "state_id": "VA"
  },
  {
    "zip": 23085,
    "lat": 37.70648,
    "lng": -76.84127,
    "state_id": "VA"
  },
  {
    "zip": 23086,
    "lat": 37.66281,
    "lng": -77.05337,
    "state_id": "VA"
  },
  {
    "zip": 23089,
    "lat": 37.46306,
    "lng": -76.90375,
    "state_id": "VA"
  },
  {
    "zip": 23091,
    "lat": 37.64796,
    "lng": -76.80509,
    "state_id": "VA"
  },
  {
    "zip": 23092,
    "lat": 37.59392,
    "lng": -76.50956,
    "state_id": "VA"
  },
  {
    "zip": 23093,
    "lat": 38.00243,
    "lng": -78.04076,
    "state_id": "VA"
  },
  {
    "zip": 23102,
    "lat": 37.71259,
    "lng": -77.83149,
    "state_id": "VA"
  },
  {
    "zip": 23103,
    "lat": 37.65064,
    "lng": -77.72554,
    "state_id": "VA"
  },
  {
    "zip": 23106,
    "lat": 37.71618,
    "lng": -77.20181,
    "state_id": "VA"
  },
  {
    "zip": 23108,
    "lat": 37.64844,
    "lng": -76.72771,
    "state_id": "VA"
  },
  {
    "zip": 23109,
    "lat": 37.43527,
    "lng": -76.32874,
    "state_id": "VA"
  },
  {
    "zip": 23110,
    "lat": 37.58216,
    "lng": -76.77014,
    "state_id": "VA"
  },
  {
    "zip": 23111,
    "lat": 37.61146,
    "lng": -77.24696,
    "state_id": "VA"
  },
  {
    "zip": 23112,
    "lat": 37.43498,
    "lng": -77.66207,
    "state_id": "VA"
  },
  {
    "zip": 23113,
    "lat": 37.53845,
    "lng": -77.67756,
    "state_id": "VA"
  },
  {
    "zip": 23114,
    "lat": 37.48221,
    "lng": -77.65937,
    "state_id": "VA"
  },
  {
    "zip": 23115,
    "lat": 37.81101,
    "lng": -76.9145,
    "state_id": "VA"
  },
  {
    "zip": 23116,
    "lat": 37.67949,
    "lng": -77.33458,
    "state_id": "VA"
  },
  {
    "zip": 23117,
    "lat": 37.97998,
    "lng": -77.87501,
    "state_id": "VA"
  },
  {
    "zip": 23119,
    "lat": 37.44651,
    "lng": -76.28156,
    "state_id": "VA"
  },
  {
    "zip": 23120,
    "lat": 37.40973,
    "lng": -77.78499,
    "state_id": "VA"
  },
  {
    "zip": 23123,
    "lat": 37.65471,
    "lng": -78.29894,
    "state_id": "VA"
  },
  {
    "zip": 23124,
    "lat": 37.5535,
    "lng": -77.05236,
    "state_id": "VA"
  },
  {
    "zip": 23125,
    "lat": 37.3385,
    "lng": -76.28285,
    "state_id": "VA"
  },
  {
    "zip": 23126,
    "lat": 37.9226,
    "lng": -77.14492,
    "state_id": "VA"
  },
  {
    "zip": 23128,
    "lat": 37.44555,
    "lng": -76.42687,
    "state_id": "VA"
  },
  {
    "zip": 23129,
    "lat": 37.68646,
    "lng": -77.7753,
    "state_id": "VA"
  },
  {
    "zip": 23130,
    "lat": 37.39552,
    "lng": -76.2719,
    "state_id": "VA"
  },
  {
    "zip": 23138,
    "lat": 37.36922,
    "lng": -76.30324,
    "state_id": "VA"
  },
  {
    "zip": 23139,
    "lat": 37.54972,
    "lng": -77.93374,
    "state_id": "VA"
  },
  {
    "zip": 23140,
    "lat": 37.43858,
    "lng": -77.04431,
    "state_id": "VA"
  },
  {
    "zip": 23141,
    "lat": 37.52994,
    "lng": -77.15541,
    "state_id": "VA"
  },
  {
    "zip": 23146,
    "lat": 37.72648,
    "lng": -77.7074,
    "state_id": "VA"
  },
  {
    "zip": 23148,
    "lat": 37.84792,
    "lng": -77.05503,
    "state_id": "VA"
  },
  {
    "zip": 23149,
    "lat": 37.56856,
    "lng": -76.61577,
    "state_id": "VA"
  },
  {
    "zip": 23150,
    "lat": 37.50692,
    "lng": -77.2535,
    "state_id": "VA"
  },
  {
    "zip": 23153,
    "lat": 37.76975,
    "lng": -77.94478,
    "state_id": "VA"
  },
  {
    "zip": 23156,
    "lat": 37.52127,
    "lng": -76.7099,
    "state_id": "VA"
  },
  {
    "zip": 23160,
    "lat": 37.62943,
    "lng": -77.85103,
    "state_id": "VA"
  },
  {
    "zip": 23161,
    "lat": 37.71657,
    "lng": -76.9307,
    "state_id": "VA"
  },
  {
    "zip": 23163,
    "lat": 37.35571,
    "lng": -76.30768,
    "state_id": "VA"
  },
  {
    "zip": 23168,
    "lat": 37.39997,
    "lng": -76.82728,
    "state_id": "VA"
  },
  {
    "zip": 23169,
    "lat": 37.5901,
    "lng": -76.46489,
    "state_id": "VA"
  },
  {
    "zip": 23173,
    "lat": 37.57707,
    "lng": -77.53884,
    "state_id": "VA"
  },
  {
    "zip": 23175,
    "lat": 37.65026,
    "lng": -76.6225,
    "state_id": "VA"
  },
  {
    "zip": 23176,
    "lat": 37.57039,
    "lng": -76.42144,
    "state_id": "VA"
  },
  {
    "zip": 23177,
    "lat": 37.73799,
    "lng": -77.01393,
    "state_id": "VA"
  },
  {
    "zip": 23180,
    "lat": 37.70008,
    "lng": -76.62202,
    "state_id": "VA"
  },
  {
    "zip": 23181,
    "lat": 37.59889,
    "lng": -76.88952,
    "state_id": "VA"
  },
  {
    "zip": 23185,
    "lat": 37.24594,
    "lng": -76.69829,
    "state_id": "VA"
  },
  {
    "zip": 23187,
    "lat": 37.26743,
    "lng": -76.72078,
    "state_id": "VA"
  },
  {
    "zip": 23188,
    "lat": 37.33941,
    "lng": -76.75551,
    "state_id": "VA"
  },
  {
    "zip": 23192,
    "lat": 37.82069,
    "lng": -77.68152,
    "state_id": "VA"
  },
  {
    "zip": 23219,
    "lat": 37.54011,
    "lng": -77.43531,
    "state_id": "VA"
  },
  {
    "zip": 23220,
    "lat": 37.54897,
    "lng": -77.46043,
    "state_id": "VA"
  },
  {
    "zip": 23221,
    "lat": 37.55338,
    "lng": -77.49392,
    "state_id": "VA"
  },
  {
    "zip": 23222,
    "lat": 37.58256,
    "lng": -77.41905,
    "state_id": "VA"
  },
  {
    "zip": 23223,
    "lat": 37.55584,
    "lng": -77.37889,
    "state_id": "VA"
  },
  {
    "zip": 23224,
    "lat": 37.49779,
    "lng": -77.46623,
    "state_id": "VA"
  },
  {
    "zip": 23225,
    "lat": 37.51842,
    "lng": -77.51103,
    "state_id": "VA"
  },
  {
    "zip": 23226,
    "lat": 37.5779,
    "lng": -77.52383,
    "state_id": "VA"
  },
  {
    "zip": 23227,
    "lat": 37.61464,
    "lng": -77.4425,
    "state_id": "VA"
  },
  {
    "zip": 23228,
    "lat": 37.62536,
    "lng": -77.49198,
    "state_id": "VA"
  },
  {
    "zip": 23229,
    "lat": 37.58729,
    "lng": -77.57281,
    "state_id": "VA"
  },
  {
    "zip": 23230,
    "lat": 37.5862,
    "lng": -77.49093,
    "state_id": "VA"
  },
  {
    "zip": 23231,
    "lat": 37.44103,
    "lng": -77.31869,
    "state_id": "VA"
  },
  {
    "zip": 23233,
    "lat": 37.64547,
    "lng": -77.62662,
    "state_id": "VA"
  },
  {
    "zip": 23234,
    "lat": 37.45208,
    "lng": -77.46968,
    "state_id": "VA"
  },
  {
    "zip": 23235,
    "lat": 37.51332,
    "lng": -77.56451,
    "state_id": "VA"
  },
  {
    "zip": 23236,
    "lat": 37.4744,
    "lng": -77.58632,
    "state_id": "VA"
  },
  {
    "zip": 23237,
    "lat": 37.40215,
    "lng": -77.45236,
    "state_id": "VA"
  },
  {
    "zip": 23238,
    "lat": 37.59735,
    "lng": -77.64626,
    "state_id": "VA"
  },
  {
    "zip": 23250,
    "lat": 37.50516,
    "lng": -77.32092,
    "state_id": "VA"
  },
  {
    "zip": 23294,
    "lat": 37.63044,
    "lng": -77.54232,
    "state_id": "VA"
  },
  {
    "zip": 23301,
    "lat": 37.68333,
    "lng": -75.63618,
    "state_id": "VA"
  },
  {
    "zip": 23302,
    "lat": 37.86357,
    "lng": -75.5171,
    "state_id": "VA"
  },
  {
    "zip": 23303,
    "lat": 37.90728,
    "lng": -75.50885,
    "state_id": "VA"
  },
  {
    "zip": 23304,
    "lat": 36.9961,
    "lng": -76.57063,
    "state_id": "VA"
  },
  {
    "zip": 23306,
    "lat": 37.57016,
    "lng": -75.87351,
    "state_id": "VA"
  },
  {
    "zip": 23307,
    "lat": 37.43369,
    "lng": -75.87307,
    "state_id": "VA"
  },
  {
    "zip": 23308,
    "lat": 37.82556,
    "lng": -75.62061,
    "state_id": "VA"
  },
  {
    "zip": 23310,
    "lat": 37.26182,
    "lng": -75.95954,
    "state_id": "VA"
  },
  {
    "zip": 23313,
    "lat": 37.20267,
    "lng": -75.9453,
    "state_id": "VA"
  },
  {
    "zip": 23314,
    "lat": 36.95069,
    "lng": -76.53571,
    "state_id": "VA"
  },
  {
    "zip": 23315,
    "lat": 36.73871,
    "lng": -76.84278,
    "state_id": "VA"
  },
  {
    "zip": 23316,
    "lat": 37.29877,
    "lng": -75.98219,
    "state_id": "VA"
  },
  {
    "zip": 23320,
    "lat": 36.75193,
    "lng": -76.2183,
    "state_id": "VA"
  },
  {
    "zip": 23321,
    "lat": 36.80125,
    "lng": -76.42294,
    "state_id": "VA"
  },
  {
    "zip": 23322,
    "lat": 36.63219,
    "lng": -76.22873,
    "state_id": "VA"
  },
  {
    "zip": 23323,
    "lat": 36.67487,
    "lng": -76.39162,
    "state_id": "VA"
  },
  {
    "zip": 23324,
    "lat": 36.7999,
    "lng": -76.27517,
    "state_id": "VA"
  },
  {
    "zip": 23325,
    "lat": 36.81499,
    "lng": -76.23891,
    "state_id": "VA"
  },
  {
    "zip": 23336,
    "lat": 37.95452,
    "lng": -75.33766,
    "state_id": "VA"
  },
  {
    "zip": 23337,
    "lat": 37.92334,
    "lng": -75.48324,
    "state_id": "VA"
  },
  {
    "zip": 23347,
    "lat": 37.35478,
    "lng": -75.9707,
    "state_id": "VA"
  },
  {
    "zip": 23350,
    "lat": 37.51869,
    "lng": -75.87275,
    "state_id": "VA"
  },
  {
    "zip": 23354,
    "lat": 37.47638,
    "lng": -75.90487,
    "state_id": "VA"
  },
  {
    "zip": 23356,
    "lat": 37.99049,
    "lng": -75.39232,
    "state_id": "VA"
  },
  {
    "zip": 23357,
    "lat": 37.75549,
    "lng": -75.67555,
    "state_id": "VA"
  },
  {
    "zip": 23358,
    "lat": 37.64451,
    "lng": -75.86561,
    "state_id": "VA"
  },
  {
    "zip": 23359,
    "lat": 37.88316,
    "lng": -75.58988,
    "state_id": "VA"
  },
  {
    "zip": 23389,
    "lat": 37.662,
    "lng": -75.83469,
    "state_id": "VA"
  },
  {
    "zip": 23395,
    "lat": 37.97582,
    "lng": -75.46743,
    "state_id": "VA"
  },
  {
    "zip": 23398,
    "lat": 37.5121,
    "lng": -75.94103,
    "state_id": "VA"
  },
  {
    "zip": 23401,
    "lat": 37.62749,
    "lng": -75.77945,
    "state_id": "VA"
  },
  {
    "zip": 23405,
    "lat": 37.4117,
    "lng": -75.91316,
    "state_id": "VA"
  },
  {
    "zip": 23407,
    "lat": 37.83755,
    "lng": -75.55412,
    "state_id": "VA"
  },
  {
    "zip": 23408,
    "lat": 37.45565,
    "lng": -75.84731,
    "state_id": "VA"
  },
  {
    "zip": 23409,
    "lat": 37.87938,
    "lng": -75.6436,
    "state_id": "VA"
  },
  {
    "zip": 23410,
    "lat": 37.63348,
    "lng": -75.73962,
    "state_id": "VA"
  },
  {
    "zip": 23413,
    "lat": 37.47284,
    "lng": -75.85798,
    "state_id": "VA"
  },
  {
    "zip": 23414,
    "lat": 37.8062,
    "lng": -75.57747,
    "state_id": "VA"
  },
  {
    "zip": 23415,
    "lat": 37.96889,
    "lng": -75.53604,
    "state_id": "VA"
  },
  {
    "zip": 23416,
    "lat": 37.93378,
    "lng": -75.56333,
    "state_id": "VA"
  },
  {
    "zip": 23417,
    "lat": 37.72934,
    "lng": -75.76577,
    "state_id": "VA"
  },
  {
    "zip": 23418,
    "lat": 37.66648,
    "lng": -75.68136,
    "state_id": "VA"
  },
  {
    "zip": 23420,
    "lat": 37.58323,
    "lng": -75.80986,
    "state_id": "VA"
  },
  {
    "zip": 23421,
    "lat": 37.76698,
    "lng": -75.61443,
    "state_id": "VA"
  },
  {
    "zip": 23422,
    "lat": 37.63672,
    "lng": -75.82551,
    "state_id": "VA"
  },
  {
    "zip": 23423,
    "lat": 37.52286,
    "lng": -75.70557,
    "state_id": "VA"
  },
  {
    "zip": 23426,
    "lat": 37.92158,
    "lng": -75.67047,
    "state_id": "VA"
  },
  {
    "zip": 23427,
    "lat": 37.91297,
    "lng": -75.71341,
    "state_id": "VA"
  },
  {
    "zip": 23430,
    "lat": 36.9836,
    "lng": -76.66052,
    "state_id": "VA"
  },
  {
    "zip": 23432,
    "lat": 36.87174,
    "lng": -76.55076,
    "state_id": "VA"
  },
  {
    "zip": 23433,
    "lat": 36.90773,
    "lng": -76.49162,
    "state_id": "VA"
  },
  {
    "zip": 23434,
    "lat": 36.70121,
    "lng": -76.59159,
    "state_id": "VA"
  },
  {
    "zip": 23435,
    "lat": 36.83257,
    "lng": -76.4829,
    "state_id": "VA"
  },
  {
    "zip": 23436,
    "lat": 36.89525,
    "lng": -76.50858,
    "state_id": "VA"
  },
  {
    "zip": 23437,
    "lat": 36.63562,
    "lng": -76.80294,
    "state_id": "VA"
  },
  {
    "zip": 23438,
    "lat": 36.58144,
    "lng": -76.69689,
    "state_id": "VA"
  },
  {
    "zip": 23440,
    "lat": 37.8307,
    "lng": -75.99018,
    "state_id": "VA"
  },
  {
    "zip": 23441,
    "lat": 37.71026,
    "lng": -75.69956,
    "state_id": "VA"
  },
  {
    "zip": 23442,
    "lat": 37.90632,
    "lng": -75.57224,
    "state_id": "VA"
  },
  {
    "zip": 23451,
    "lat": 36.86739,
    "lng": -76.00587,
    "state_id": "VA"
  },
  {
    "zip": 23452,
    "lat": 36.84674,
    "lng": -76.09255,
    "state_id": "VA"
  },
  {
    "zip": 23453,
    "lat": 36.78557,
    "lng": -76.07572,
    "state_id": "VA"
  },
  {
    "zip": 23454,
    "lat": 36.82642,
    "lng": -76.02462,
    "state_id": "VA"
  },
  {
    "zip": 23455,
    "lat": 36.89334,
    "lng": -76.14639,
    "state_id": "VA"
  },
  {
    "zip": 23456,
    "lat": 36.73404,
    "lng": -76.03592,
    "state_id": "VA"
  },
  {
    "zip": 23457,
    "lat": 36.61597,
    "lng": -76.01907,
    "state_id": "VA"
  },
  {
    "zip": 23459,
    "lat": 36.92469,
    "lng": -76.0201,
    "state_id": "VA"
  },
  {
    "zip": 23460,
    "lat": 36.80798,
    "lng": -76.02844,
    "state_id": "VA"
  },
  {
    "zip": 23461,
    "lat": 36.77533,
    "lng": -75.96316,
    "state_id": "VA"
  },
  {
    "zip": 23462,
    "lat": 36.83721,
    "lng": -76.1517,
    "state_id": "VA"
  },
  {
    "zip": 23464,
    "lat": 36.79724,
    "lng": -76.17922,
    "state_id": "VA"
  },
  {
    "zip": 23480,
    "lat": 37.61394,
    "lng": -75.6905,
    "state_id": "VA"
  },
  {
    "zip": 23486,
    "lat": 37.5135,
    "lng": -75.81115,
    "state_id": "VA"
  },
  {
    "zip": 23487,
    "lat": 36.852,
    "lng": -76.72259,
    "state_id": "VA"
  },
  {
    "zip": 23488,
    "lat": 37.95242,
    "lng": -75.60824,
    "state_id": "VA"
  },
  {
    "zip": 23502,
    "lat": 36.86285,
    "lng": -76.20988,
    "state_id": "VA"
  },
  {
    "zip": 23503,
    "lat": 36.94728,
    "lng": -76.26289,
    "state_id": "VA"
  },
  {
    "zip": 23504,
    "lat": 36.8568,
    "lng": -76.26574,
    "state_id": "VA"
  },
  {
    "zip": 23505,
    "lat": 36.91587,
    "lng": -76.28827,
    "state_id": "VA"
  },
  {
    "zip": 23507,
    "lat": 36.86451,
    "lng": -76.30312,
    "state_id": "VA"
  },
  {
    "zip": 23508,
    "lat": 36.88444,
    "lng": -76.30898,
    "state_id": "VA"
  },
  {
    "zip": 23509,
    "lat": 36.88214,
    "lng": -76.26203,
    "state_id": "VA"
  },
  {
    "zip": 23510,
    "lat": 36.85188,
    "lng": -76.29173,
    "state_id": "VA"
  },
  {
    "zip": 23511,
    "lat": 36.936,
    "lng": -76.30379,
    "state_id": "VA"
  },
  {
    "zip": 23513,
    "lat": 36.88986,
    "lng": -76.23859,
    "state_id": "VA"
  },
  {
    "zip": 23517,
    "lat": 36.8696,
    "lng": -76.29241,
    "state_id": "VA"
  },
  {
    "zip": 23518,
    "lat": 36.91648,
    "lng": -76.21584,
    "state_id": "VA"
  },
  {
    "zip": 23523,
    "lat": 36.83255,
    "lng": -76.272,
    "state_id": "VA"
  },
  {
    "zip": 23551,
    "lat": 36.92404,
    "lng": -76.28863,
    "state_id": "VA"
  },
  {
    "zip": 23601,
    "lat": 37.05863,
    "lng": -76.46392,
    "state_id": "VA"
  },
  {
    "zip": 23602,
    "lat": 37.1148,
    "lng": -76.51582,
    "state_id": "VA"
  },
  {
    "zip": 23603,
    "lat": 37.19285,
    "lng": -76.5663,
    "state_id": "VA"
  },
  {
    "zip": 23604,
    "lat": 37.13162,
    "lng": -76.58874,
    "state_id": "VA"
  },
  {
    "zip": 23605,
    "lat": 37.01814,
    "lng": -76.43569,
    "state_id": "VA"
  },
  {
    "zip": 23606,
    "lat": 37.07775,
    "lng": -76.49622,
    "state_id": "VA"
  },
  {
    "zip": 23607,
    "lat": 36.98737,
    "lng": -76.4223,
    "state_id": "VA"
  },
  {
    "zip": 23608,
    "lat": 37.15114,
    "lng": -76.54327,
    "state_id": "VA"
  },
  {
    "zip": 23651,
    "lat": 37.00917,
    "lng": -76.30503,
    "state_id": "VA"
  },
  {
    "zip": 23661,
    "lat": 37.00799,
    "lng": -76.38682,
    "state_id": "VA"
  },
  {
    "zip": 23662,
    "lat": 37.13181,
    "lng": -76.35673,
    "state_id": "VA"
  },
  {
    "zip": 23663,
    "lat": 37.03303,
    "lng": -76.31431,
    "state_id": "VA"
  },
  {
    "zip": 23664,
    "lat": 37.07528,
    "lng": -76.29229,
    "state_id": "VA"
  },
  {
    "zip": 23665,
    "lat": 37.09045,
    "lng": -76.36454,
    "state_id": "VA"
  },
  {
    "zip": 23666,
    "lat": 37.05912,
    "lng": -76.40727,
    "state_id": "VA"
  },
  {
    "zip": 23669,
    "lat": 37.04973,
    "lng": -76.33944,
    "state_id": "VA"
  },
  {
    "zip": 23690,
    "lat": 37.22098,
    "lng": -76.52399,
    "state_id": "VA"
  },
  {
    "zip": 23691,
    "lat": 37.25171,
    "lng": -76.55206,
    "state_id": "VA"
  },
  {
    "zip": 23692,
    "lat": 37.18303,
    "lng": -76.46665,
    "state_id": "VA"
  },
  {
    "zip": 23693,
    "lat": 37.12555,
    "lng": -76.44667,
    "state_id": "VA"
  },
  {
    "zip": 23696,
    "lat": 37.19248,
    "lng": -76.4259,
    "state_id": "VA"
  },
  {
    "zip": 23701,
    "lat": 36.81181,
    "lng": -76.37078,
    "state_id": "VA"
  },
  {
    "zip": 23702,
    "lat": 36.80318,
    "lng": -76.32892,
    "state_id": "VA"
  },
  {
    "zip": 23703,
    "lat": 36.88036,
    "lng": -76.37108,
    "state_id": "VA"
  },
  {
    "zip": 23704,
    "lat": 36.82404,
    "lng": -76.31011,
    "state_id": "VA"
  },
  {
    "zip": 23707,
    "lat": 36.84263,
    "lng": -76.33988,
    "state_id": "VA"
  },
  {
    "zip": 23708,
    "lat": 36.84817,
    "lng": -76.30635,
    "state_id": "VA"
  },
  {
    "zip": 23709,
    "lat": 36.81267,
    "lng": -76.30525,
    "state_id": "VA"
  },
  {
    "zip": 23801,
    "lat": 37.23535,
    "lng": -77.3353,
    "state_id": "VA"
  },
  {
    "zip": 23803,
    "lat": 37.21232,
    "lng": -77.48143,
    "state_id": "VA"
  },
  {
    "zip": 23805,
    "lat": 37.12902,
    "lng": -77.39782,
    "state_id": "VA"
  },
  {
    "zip": 23806,
    "lat": 37.23857,
    "lng": -77.41827,
    "state_id": "VA"
  },
  {
    "zip": 23821,
    "lat": 36.88067,
    "lng": -77.92043,
    "state_id": "VA"
  },
  {
    "zip": 23824,
    "lat": 37.08962,
    "lng": -77.96734,
    "state_id": "VA"
  },
  {
    "zip": 23827,
    "lat": 36.61059,
    "lng": -77.2012,
    "state_id": "VA"
  },
  {
    "zip": 23828,
    "lat": 36.58214,
    "lng": -77.28601,
    "state_id": "VA"
  },
  {
    "zip": 23829,
    "lat": 36.73033,
    "lng": -77.22214,
    "state_id": "VA"
  },
  {
    "zip": 23830,
    "lat": 37.02013,
    "lng": -77.39588,
    "state_id": "VA"
  },
  {
    "zip": 23831,
    "lat": 37.3454,
    "lng": -77.44974,
    "state_id": "VA"
  },
  {
    "zip": 23832,
    "lat": 37.39467,
    "lng": -77.5926,
    "state_id": "VA"
  },
  {
    "zip": 23833,
    "lat": 37.2077,
    "lng": -77.6727,
    "state_id": "VA"
  },
  {
    "zip": 23834,
    "lat": 37.28974,
    "lng": -77.40366,
    "state_id": "VA"
  },
  {
    "zip": 23836,
    "lat": 37.34422,
    "lng": -77.34477,
    "state_id": "VA"
  },
  {
    "zip": 23837,
    "lat": 36.75245,
    "lng": -77.08504,
    "state_id": "VA"
  },
  {
    "zip": 23838,
    "lat": 37.31847,
    "lng": -77.63389,
    "state_id": "VA"
  },
  {
    "zip": 23839,
    "lat": 37.07982,
    "lng": -76.91887,
    "state_id": "VA"
  },
  {
    "zip": 23840,
    "lat": 37.05936,
    "lng": -77.66519,
    "state_id": "VA"
  },
  {
    "zip": 23841,
    "lat": 37.05234,
    "lng": -77.55182,
    "state_id": "VA"
  },
  {
    "zip": 23842,
    "lat": 37.13341,
    "lng": -77.22381,
    "state_id": "VA"
  },
  {
    "zip": 23843,
    "lat": 36.84663,
    "lng": -77.81515,
    "state_id": "VA"
  },
  {
    "zip": 23844,
    "lat": 36.66982,
    "lng": -77.33704,
    "state_id": "VA"
  },
  {
    "zip": 23845,
    "lat": 36.5722,
    "lng": -77.98887,
    "state_id": "VA"
  },
  {
    "zip": 23846,
    "lat": 37.057,
    "lng": -76.83777,
    "state_id": "VA"
  },
  {
    "zip": 23847,
    "lat": 36.67043,
    "lng": -77.55067,
    "state_id": "VA"
  },
  {
    "zip": 23850,
    "lat": 37.17355,
    "lng": -77.7417,
    "state_id": "VA"
  },
  {
    "zip": 23851,
    "lat": 36.65546,
    "lng": -76.95391,
    "state_id": "VA"
  },
  {
    "zip": 23856,
    "lat": 36.78383,
    "lng": -77.70183,
    "state_id": "VA"
  },
  {
    "zip": 23857,
    "lat": 36.5856,
    "lng": -77.88357,
    "state_id": "VA"
  },
  {
    "zip": 23860,
    "lat": 37.27984,
    "lng": -77.21731,
    "state_id": "VA"
  },
  {
    "zip": 23866,
    "lat": 36.91864,
    "lng": -76.86692,
    "state_id": "VA"
  },
  {
    "zip": 23867,
    "lat": 36.81698,
    "lng": -77.48272,
    "state_id": "VA"
  },
  {
    "zip": 23868,
    "lat": 36.71344,
    "lng": -77.80481,
    "state_id": "VA"
  },
  {
    "zip": 23872,
    "lat": 36.98779,
    "lng": -77.71819,
    "state_id": "VA"
  },
  {
    "zip": 23874,
    "lat": 36.60221,
    "lng": -77.08604,
    "state_id": "VA"
  },
  {
    "zip": 23875,
    "lat": 37.2283,
    "lng": -77.2631,
    "state_id": "VA"
  },
  {
    "zip": 23876,
    "lat": 36.95656,
    "lng": -77.83771,
    "state_id": "VA"
  },
  {
    "zip": 23878,
    "lat": 36.83196,
    "lng": -77.024,
    "state_id": "VA"
  },
  {
    "zip": 23879,
    "lat": 36.59299,
    "lng": -77.59676,
    "state_id": "VA"
  },
  {
    "zip": 23881,
    "lat": 37.18796,
    "lng": -76.98665,
    "state_id": "VA"
  },
  {
    "zip": 23882,
    "lat": 36.92569,
    "lng": -77.4151,
    "state_id": "VA"
  },
  {
    "zip": 23883,
    "lat": 37.12075,
    "lng": -76.74915,
    "state_id": "VA"
  },
  {
    "zip": 23884,
    "lat": 36.91725,
    "lng": -77.27943,
    "state_id": "VA"
  },
  {
    "zip": 23885,
    "lat": 37.18641,
    "lng": -77.57841,
    "state_id": "VA"
  },
  {
    "zip": 23887,
    "lat": 36.57107,
    "lng": -77.80839,
    "state_id": "VA"
  },
  {
    "zip": 23888,
    "lat": 36.9569,
    "lng": -76.98245,
    "state_id": "VA"
  },
  {
    "zip": 23889,
    "lat": 36.89609,
    "lng": -77.73768,
    "state_id": "VA"
  },
  {
    "zip": 23890,
    "lat": 37.0034,
    "lng": -77.11941,
    "state_id": "VA"
  },
  {
    "zip": 23891,
    "lat": 37.05082,
    "lng": -77.21243,
    "state_id": "VA"
  },
  {
    "zip": 23893,
    "lat": 36.63627,
    "lng": -77.92792,
    "state_id": "VA"
  },
  {
    "zip": 23894,
    "lat": 37.12619,
    "lng": -77.81795,
    "state_id": "VA"
  },
  {
    "zip": 23897,
    "lat": 36.83172,
    "lng": -77.28377,
    "state_id": "VA"
  },
  {
    "zip": 23898,
    "lat": 36.8273,
    "lng": -76.85599,
    "state_id": "VA"
  },
  {
    "zip": 23899,
    "lat": 37.23078,
    "lng": -76.96558,
    "state_id": "VA"
  },
  {
    "zip": 23901,
    "lat": 37.31622,
    "lng": -78.41609,
    "state_id": "VA"
  },
  {
    "zip": 23909,
    "lat": 37.30057,
    "lng": -78.39668,
    "state_id": "VA"
  },
  {
    "zip": 23915,
    "lat": 36.70149,
    "lng": -78.28677,
    "state_id": "VA"
  },
  {
    "zip": 23917,
    "lat": 36.62447,
    "lng": -78.34255,
    "state_id": "VA"
  },
  {
    "zip": 23919,
    "lat": 36.59513,
    "lng": -78.13501,
    "state_id": "VA"
  },
  {
    "zip": 23920,
    "lat": 36.72431,
    "lng": -77.97677,
    "state_id": "VA"
  },
  {
    "zip": 23921,
    "lat": 37.59078,
    "lng": -78.61976,
    "state_id": "VA"
  },
  {
    "zip": 23922,
    "lat": 37.1871,
    "lng": -78.2148,
    "state_id": "VA"
  },
  {
    "zip": 23923,
    "lat": 37.09234,
    "lng": -78.64394,
    "state_id": "VA"
  },
  {
    "zip": 23924,
    "lat": 36.80743,
    "lng": -78.43198,
    "state_id": "VA"
  },
  {
    "zip": 23927,
    "lat": 36.61067,
    "lng": -78.51213,
    "state_id": "VA"
  },
  {
    "zip": 23930,
    "lat": 37.17241,
    "lng": -78.09939,
    "state_id": "VA"
  },
  {
    "zip": 23934,
    "lat": 37.17387,
    "lng": -78.62197,
    "state_id": "VA"
  },
  {
    "zip": 23936,
    "lat": 37.52776,
    "lng": -78.47872,
    "state_id": "VA"
  },
  {
    "zip": 23937,
    "lat": 36.94483,
    "lng": -78.52616,
    "state_id": "VA"
  },
  {
    "zip": 23938,
    "lat": 36.90654,
    "lng": -77.99612,
    "state_id": "VA"
  },
  {
    "zip": 23942,
    "lat": 37.13542,
    "lng": -78.30324,
    "state_id": "VA"
  },
  {
    "zip": 23943,
    "lat": 37.24362,
    "lng": -78.45574,
    "state_id": "VA"
  },
  {
    "zip": 23944,
    "lat": 36.90668,
    "lng": -78.13427,
    "state_id": "VA"
  },
  {
    "zip": 23947,
    "lat": 37.0283,
    "lng": -78.4502,
    "state_id": "VA"
  },
  {
    "zip": 23950,
    "lat": 36.66122,
    "lng": -78.07537,
    "state_id": "VA"
  },
  {
    "zip": 23952,
    "lat": 36.92908,
    "lng": -78.29143,
    "state_id": "VA"
  },
  {
    "zip": 23954,
    "lat": 37.10638,
    "lng": -78.38363,
    "state_id": "VA"
  },
  {
    "zip": 23958,
    "lat": 37.26651,
    "lng": -78.65975,
    "state_id": "VA"
  },
  {
    "zip": 23959,
    "lat": 37.10568,
    "lng": -78.7932,
    "state_id": "VA"
  },
  {
    "zip": 23960,
    "lat": 37.31378,
    "lng": -78.56053,
    "state_id": "VA"
  },
  {
    "zip": 23962,
    "lat": 36.93449,
    "lng": -78.70254,
    "state_id": "VA"
  },
  {
    "zip": 23963,
    "lat": 37.19454,
    "lng": -78.80214,
    "state_id": "VA"
  },
  {
    "zip": 23964,
    "lat": 36.77452,
    "lng": -78.62881,
    "state_id": "VA"
  },
  {
    "zip": 23966,
    "lat": 37.29262,
    "lng": -78.27278,
    "state_id": "VA"
  },
  {
    "zip": 23967,
    "lat": 36.91799,
    "lng": -78.63345,
    "state_id": "VA"
  },
  {
    "zip": 23968,
    "lat": 36.72523,
    "lng": -78.5221,
    "state_id": "VA"
  },
  {
    "zip": 23970,
    "lat": 36.7508,
    "lng": -78.188,
    "state_id": "VA"
  },
  {
    "zip": 23974,
    "lat": 36.97099,
    "lng": -78.25669,
    "state_id": "VA"
  },
  {
    "zip": 23976,
    "lat": 36.84705,
    "lng": -78.58415,
    "state_id": "VA"
  },
  {
    "zip": 24011,
    "lat": 37.27044,
    "lng": -79.94127,
    "state_id": "VA"
  },
  {
    "zip": 24012,
    "lat": 37.3153,
    "lng": -79.90276,
    "state_id": "VA"
  },
  {
    "zip": 24013,
    "lat": 37.26648,
    "lng": -79.92248,
    "state_id": "VA"
  },
  {
    "zip": 24014,
    "lat": 37.22288,
    "lng": -79.91289,
    "state_id": "VA"
  },
  {
    "zip": 24015,
    "lat": 37.25644,
    "lng": -79.97971,
    "state_id": "VA"
  },
  {
    "zip": 24016,
    "lat": 37.27295,
    "lng": -79.95414,
    "state_id": "VA"
  },
  {
    "zip": 24017,
    "lat": 37.29476,
    "lng": -79.99184,
    "state_id": "VA"
  },
  {
    "zip": 24018,
    "lat": 37.21061,
    "lng": -80.04236,
    "state_id": "VA"
  },
  {
    "zip": 24019,
    "lat": 37.34625,
    "lng": -79.95225,
    "state_id": "VA"
  },
  {
    "zip": 24020,
    "lat": 37.35837,
    "lng": -79.94416,
    "state_id": "VA"
  },
  {
    "zip": 24053,
    "lat": 36.60858,
    "lng": -80.53375,
    "state_id": "VA"
  },
  {
    "zip": 24054,
    "lat": 36.67394,
    "lng": -79.70511,
    "state_id": "VA"
  },
  {
    "zip": 24055,
    "lat": 36.75708,
    "lng": -79.99954,
    "state_id": "VA"
  },
  {
    "zip": 24058,
    "lat": 37.18414,
    "lng": -80.6157,
    "state_id": "VA"
  },
  {
    "zip": 24059,
    "lat": 37.15457,
    "lng": -80.13587,
    "state_id": "VA"
  },
  {
    "zip": 24060,
    "lat": 37.25676,
    "lng": -80.42107,
    "state_id": "VA"
  },
  {
    "zip": 24064,
    "lat": 37.37194,
    "lng": -79.77645,
    "state_id": "VA"
  },
  {
    "zip": 24065,
    "lat": 37.11658,
    "lng": -79.97088,
    "state_id": "VA"
  },
  {
    "zip": 24066,
    "lat": 37.53847,
    "lng": -79.67921,
    "state_id": "VA"
  },
  {
    "zip": 24067,
    "lat": 37.03371,
    "lng": -80.06895,
    "state_id": "VA"
  },
  {
    "zip": 24069,
    "lat": 36.58325,
    "lng": -79.63481,
    "state_id": "VA"
  },
  {
    "zip": 24070,
    "lat": 37.36705,
    "lng": -80.20873,
    "state_id": "VA"
  },
  {
    "zip": 24072,
    "lat": 37.03633,
    "lng": -80.2329,
    "state_id": "VA"
  },
  {
    "zip": 24073,
    "lat": 37.12852,
    "lng": -80.42216,
    "state_id": "VA"
  },
  {
    "zip": 24076,
    "lat": 36.59731,
    "lng": -80.42028,
    "state_id": "VA"
  },
  {
    "zip": 24077,
    "lat": 37.36641,
    "lng": -79.90388,
    "state_id": "VA"
  },
  {
    "zip": 24078,
    "lat": 36.72502,
    "lng": -79.91092,
    "state_id": "VA"
  },
  {
    "zip": 24079,
    "lat": 37.05424,
    "lng": -80.15716,
    "state_id": "VA"
  },
  {
    "zip": 24082,
    "lat": 36.61973,
    "lng": -80.12316,
    "state_id": "VA"
  },
  {
    "zip": 24083,
    "lat": 37.40604,
    "lng": -79.92314,
    "state_id": "VA"
  },
  {
    "zip": 24084,
    "lat": 37.11584,
    "lng": -80.79319,
    "state_id": "VA"
  },
  {
    "zip": 24085,
    "lat": 37.6775,
    "lng": -79.82379,
    "state_id": "VA"
  },
  {
    "zip": 24086,
    "lat": 37.27426,
    "lng": -80.6343,
    "state_id": "VA"
  },
  {
    "zip": 24087,
    "lat": 37.22569,
    "lng": -80.24845,
    "state_id": "VA"
  },
  {
    "zip": 24088,
    "lat": 36.8969,
    "lng": -80.07868,
    "state_id": "VA"
  },
  {
    "zip": 24089,
    "lat": 36.70917,
    "lng": -79.97282,
    "state_id": "VA"
  },
  {
    "zip": 24090,
    "lat": 37.52293,
    "lng": -79.88824,
    "state_id": "VA"
  },
  {
    "zip": 24091,
    "lat": 36.91752,
    "lng": -80.32859,
    "state_id": "VA"
  },
  {
    "zip": 24092,
    "lat": 37.00339,
    "lng": -79.7671,
    "state_id": "VA"
  },
  {
    "zip": 24093,
    "lat": 37.38779,
    "lng": -80.8565,
    "state_id": "VA"
  },
  {
    "zip": 24095,
    "lat": 37.22324,
    "lng": -79.73811,
    "state_id": "VA"
  },
  {
    "zip": 24101,
    "lat": 37.17622,
    "lng": -79.79959,
    "state_id": "VA"
  },
  {
    "zip": 24102,
    "lat": 36.83954,
    "lng": -79.99678,
    "state_id": "VA"
  },
  {
    "zip": 24104,
    "lat": 37.13604,
    "lng": -79.48842,
    "state_id": "VA"
  },
  {
    "zip": 24105,
    "lat": 36.89908,
    "lng": -80.60013,
    "state_id": "VA"
  },
  {
    "zip": 24112,
    "lat": 36.72729,
    "lng": -79.84428,
    "state_id": "VA"
  },
  {
    "zip": 24120,
    "lat": 36.71684,
    "lng": -80.41394,
    "state_id": "VA"
  },
  {
    "zip": 24121,
    "lat": 37.17233,
    "lng": -79.63977,
    "state_id": "VA"
  },
  {
    "zip": 24122,
    "lat": 37.42514,
    "lng": -79.69357,
    "state_id": "VA"
  },
  {
    "zip": 24124,
    "lat": 37.31275,
    "lng": -80.85807,
    "state_id": "VA"
  },
  {
    "zip": 24127,
    "lat": 37.48631,
    "lng": -80.19046,
    "state_id": "VA"
  },
  {
    "zip": 24128,
    "lat": 37.34333,
    "lng": -80.46942,
    "state_id": "VA"
  },
  {
    "zip": 24130,
    "lat": 37.61252,
    "lng": -79.99643,
    "state_id": "VA"
  },
  {
    "zip": 24131,
    "lat": 37.57072,
    "lng": -80.22656,
    "state_id": "VA"
  },
  {
    "zip": 24132,
    "lat": 37.21021,
    "lng": -80.6498,
    "state_id": "VA"
  },
  {
    "zip": 24133,
    "lat": 36.66968,
    "lng": -80.1344,
    "state_id": "VA"
  },
  {
    "zip": 24134,
    "lat": 37.24522,
    "lng": -80.77995,
    "state_id": "VA"
  },
  {
    "zip": 24136,
    "lat": 37.33123,
    "lng": -80.59756,
    "state_id": "VA"
  },
  {
    "zip": 24137,
    "lat": 36.92888,
    "lng": -79.65251,
    "state_id": "VA"
  },
  {
    "zip": 24138,
    "lat": 37.04844,
    "lng": -80.31825,
    "state_id": "VA"
  },
  {
    "zip": 24139,
    "lat": 37.01141,
    "lng": -79.47875,
    "state_id": "VA"
  },
  {
    "zip": 24141,
    "lat": 37.09487,
    "lng": -80.56832,
    "state_id": "VA"
  },
  {
    "zip": 24142,
    "lat": 37.13794,
    "lng": -80.55083,
    "state_id": "VA"
  },
  {
    "zip": 24147,
    "lat": 37.40044,
    "lng": -80.82588,
    "state_id": "VA"
  },
  {
    "zip": 24148,
    "lat": 36.58168,
    "lng": -79.87269,
    "state_id": "VA"
  },
  {
    "zip": 24149,
    "lat": 37.02437,
    "lng": -80.43582,
    "state_id": "VA"
  },
  {
    "zip": 24150,
    "lat": 37.40581,
    "lng": -80.60582,
    "state_id": "VA"
  },
  {
    "zip": 24151,
    "lat": 36.95739,
    "lng": -79.86453,
    "state_id": "VA"
  },
  {
    "zip": 24153,
    "lat": 37.30011,
    "lng": -80.11063,
    "state_id": "VA"
  },
  {
    "zip": 24161,
    "lat": 36.97848,
    "lng": -79.54437,
    "state_id": "VA"
  },
  {
    "zip": 24162,
    "lat": 37.13787,
    "lng": -80.25777,
    "state_id": "VA"
  },
  {
    "zip": 24165,
    "lat": 36.58491,
    "lng": -80.05194,
    "state_id": "VA"
  },
  {
    "zip": 24167,
    "lat": 37.24904,
    "lng": -80.73439,
    "state_id": "VA"
  },
  {
    "zip": 24168,
    "lat": 36.73475,
    "lng": -79.94645,
    "state_id": "VA"
  },
  {
    "zip": 24171,
    "lat": 36.67195,
    "lng": -80.23868,
    "state_id": "VA"
  },
  {
    "zip": 24174,
    "lat": 37.35161,
    "lng": -79.66867,
    "state_id": "VA"
  },
  {
    "zip": 24175,
    "lat": 37.42845,
    "lng": -79.9396,
    "state_id": "VA"
  },
  {
    "zip": 24176,
    "lat": 37.00932,
    "lng": -79.69178,
    "state_id": "VA"
  },
  {
    "zip": 24179,
    "lat": 37.28726,
    "lng": -79.80117,
    "state_id": "VA"
  },
  {
    "zip": 24184,
    "lat": 37.08814,
    "lng": -79.78134,
    "state_id": "VA"
  },
  {
    "zip": 24185,
    "lat": 36.8116,
    "lng": -80.26923,
    "state_id": "VA"
  },
  {
    "zip": 24201,
    "lat": 36.61087,
    "lng": -82.16978,
    "state_id": "VA"
  },
  {
    "zip": 24202,
    "lat": 36.66094,
    "lng": -82.2126,
    "state_id": "VA"
  },
  {
    "zip": 24210,
    "lat": 36.77248,
    "lng": -82.02235,
    "state_id": "VA"
  },
  {
    "zip": 24211,
    "lat": 36.65389,
    "lng": -81.95744,
    "state_id": "VA"
  },
  {
    "zip": 24216,
    "lat": 36.9437,
    "lng": -82.79763,
    "state_id": "VA"
  },
  {
    "zip": 24217,
    "lat": 37.08415,
    "lng": -82.17205,
    "state_id": "VA"
  },
  {
    "zip": 24219,
    "lat": 36.84878,
    "lng": -82.75814,
    "state_id": "VA"
  },
  {
    "zip": 24220,
    "lat": 37.13948,
    "lng": -82.24335,
    "state_id": "VA"
  },
  {
    "zip": 24221,
    "lat": 36.63361,
    "lng": -82.99548,
    "state_id": "VA"
  },
  {
    "zip": 24224,
    "lat": 36.86232,
    "lng": -82.28197,
    "state_id": "VA"
  },
  {
    "zip": 24225,
    "lat": 36.98142,
    "lng": -82.13603,
    "state_id": "VA"
  },
  {
    "zip": 24226,
    "lat": 37.13931,
    "lng": -82.33458,
    "state_id": "VA"
  },
  {
    "zip": 24228,
    "lat": 37.16971,
    "lng": -82.45001,
    "state_id": "VA"
  },
  {
    "zip": 24230,
    "lat": 36.96108,
    "lng": -82.46443,
    "state_id": "VA"
  },
  {
    "zip": 24236,
    "lat": 36.6424,
    "lng": -81.74467,
    "state_id": "VA"
  },
  {
    "zip": 24237,
    "lat": 37.0358,
    "lng": -82.25593,
    "state_id": "VA"
  },
  {
    "zip": 24239,
    "lat": 37.11348,
    "lng": -82.15014,
    "state_id": "VA"
  },
  {
    "zip": 24243,
    "lat": 36.77883,
    "lng": -82.92718,
    "state_id": "VA"
  },
  {
    "zip": 24244,
    "lat": 36.71568,
    "lng": -82.78923,
    "state_id": "VA"
  },
  {
    "zip": 24245,
    "lat": 36.83615,
    "lng": -82.49912,
    "state_id": "VA"
  },
  {
    "zip": 24246,
    "lat": 36.86696,
    "lng": -82.74519,
    "state_id": "VA"
  },
  {
    "zip": 24248,
    "lat": 36.62711,
    "lng": -83.51378,
    "state_id": "VA"
  },
  {
    "zip": 24250,
    "lat": 36.75664,
    "lng": -82.59741,
    "state_id": "VA"
  },
  {
    "zip": 24251,
    "lat": 36.65886,
    "lng": -82.60645,
    "state_id": "VA"
  },
  {
    "zip": 24256,
    "lat": 37.22066,
    "lng": -82.28182,
    "state_id": "VA"
  },
  {
    "zip": 24258,
    "lat": 36.64803,
    "lng": -82.42034,
    "state_id": "VA"
  },
  {
    "zip": 24260,
    "lat": 37.03954,
    "lng": -82.02463,
    "state_id": "VA"
  },
  {
    "zip": 24263,
    "lat": 36.67174,
    "lng": -83.14547,
    "state_id": "VA"
  },
  {
    "zip": 24265,
    "lat": 36.83806,
    "lng": -82.93891,
    "state_id": "VA"
  },
  {
    "zip": 24266,
    "lat": 36.86436,
    "lng": -82.11867,
    "state_id": "VA"
  },
  {
    "zip": 24269,
    "lat": 37.08122,
    "lng": -82.37603,
    "state_id": "VA"
  },
  {
    "zip": 24270,
    "lat": 36.72716,
    "lng": -82.25077,
    "state_id": "VA"
  },
  {
    "zip": 24271,
    "lat": 36.7467,
    "lng": -82.41696,
    "state_id": "VA"
  },
  {
    "zip": 24272,
    "lat": 37.0218,
    "lng": -82.33306,
    "state_id": "VA"
  },
  {
    "zip": 24273,
    "lat": 36.96167,
    "lng": -82.65647,
    "state_id": "VA"
  },
  {
    "zip": 24277,
    "lat": 36.74835,
    "lng": -83.03076,
    "state_id": "VA"
  },
  {
    "zip": 24279,
    "lat": 37.10263,
    "lng": -82.61839,
    "state_id": "VA"
  },
  {
    "zip": 24280,
    "lat": 36.95609,
    "lng": -81.93742,
    "state_id": "VA"
  },
  {
    "zip": 24281,
    "lat": 36.64929,
    "lng": -83.33253,
    "state_id": "VA"
  },
  {
    "zip": 24282,
    "lat": 36.82137,
    "lng": -83.05428,
    "state_id": "VA"
  },
  {
    "zip": 24283,
    "lat": 36.94302,
    "lng": -82.34732,
    "state_id": "VA"
  },
  {
    "zip": 24290,
    "lat": 36.61196,
    "lng": -82.56806,
    "state_id": "VA"
  },
  {
    "zip": 24292,
    "lat": 36.61457,
    "lng": -81.57778,
    "state_id": "VA"
  },
  {
    "zip": 24293,
    "lat": 37.00496,
    "lng": -82.56082,
    "state_id": "VA"
  },
  {
    "zip": 24301,
    "lat": 37.06048,
    "lng": -80.79978,
    "state_id": "VA"
  },
  {
    "zip": 24311,
    "lat": 36.88173,
    "lng": -81.40915,
    "state_id": "VA"
  },
  {
    "zip": 24312,
    "lat": 36.8371,
    "lng": -80.87483,
    "state_id": "VA"
  },
  {
    "zip": 24313,
    "lat": 36.90827,
    "lng": -80.80658,
    "state_id": "VA"
  },
  {
    "zip": 24314,
    "lat": 37.16731,
    "lng": -81.21383,
    "state_id": "VA"
  },
  {
    "zip": 24315,
    "lat": 37.13892,
    "lng": -81.0643,
    "state_id": "VA"
  },
  {
    "zip": 24316,
    "lat": 36.95465,
    "lng": -81.67291,
    "state_id": "VA"
  },
  {
    "zip": 24317,
    "lat": 36.60351,
    "lng": -80.67192,
    "state_id": "VA"
  },
  {
    "zip": 24318,
    "lat": 36.99739,
    "lng": -81.38637,
    "state_id": "VA"
  },
  {
    "zip": 24319,
    "lat": 36.74804,
    "lng": -81.64611,
    "state_id": "VA"
  },
  {
    "zip": 24322,
    "lat": 36.80642,
    "lng": -81.11414,
    "state_id": "VA"
  },
  {
    "zip": 24323,
    "lat": 36.86759,
    "lng": -81.19686,
    "state_id": "VA"
  },
  {
    "zip": 24324,
    "lat": 36.97559,
    "lng": -80.77948,
    "state_id": "VA"
  },
  {
    "zip": 24325,
    "lat": 36.80532,
    "lng": -80.59684,
    "state_id": "VA"
  },
  {
    "zip": 24326,
    "lat": 36.73007,
    "lng": -81.20247,
    "state_id": "VA"
  },
  {
    "zip": 24328,
    "lat": 36.66206,
    "lng": -80.69463,
    "state_id": "VA"
  },
  {
    "zip": 24330,
    "lat": 36.72655,
    "lng": -81.01998,
    "state_id": "VA"
  },
  {
    "zip": 24333,
    "lat": 36.63834,
    "lng": -80.92883,
    "state_id": "VA"
  },
  {
    "zip": 24340,
    "lat": 36.76018,
    "lng": -81.76542,
    "state_id": "VA"
  },
  {
    "zip": 24343,
    "lat": 36.76955,
    "lng": -80.70144,
    "state_id": "VA"
  },
  {
    "zip": 24347,
    "lat": 36.96183,
    "lng": -80.65565,
    "state_id": "VA"
  },
  {
    "zip": 24348,
    "lat": 36.6411,
    "lng": -81.17212,
    "state_id": "VA"
  },
  {
    "zip": 24350,
    "lat": 36.81117,
    "lng": -81.01963,
    "state_id": "VA"
  },
  {
    "zip": 24351,
    "lat": 36.58079,
    "lng": -80.7659,
    "state_id": "VA"
  },
  {
    "zip": 24352,
    "lat": 36.7148,
    "lng": -80.53313,
    "state_id": "VA"
  },
  {
    "zip": 24354,
    "lat": 36.82712,
    "lng": -81.54325,
    "state_id": "VA"
  },
  {
    "zip": 24360,
    "lat": 36.93372,
    "lng": -80.89676,
    "state_id": "VA"
  },
  {
    "zip": 24361,
    "lat": 36.76674,
    "lng": -81.84611,
    "state_id": "VA"
  },
  {
    "zip": 24363,
    "lat": 36.61363,
    "lng": -81.39861,
    "state_id": "VA"
  },
  {
    "zip": 24366,
    "lat": 37.25786,
    "lng": -81.11575,
    "state_id": "VA"
  },
  {
    "zip": 24368,
    "lat": 36.88987,
    "lng": -81.29595,
    "state_id": "VA"
  },
  {
    "zip": 24370,
    "lat": 36.91371,
    "lng": -81.69237,
    "state_id": "VA"
  },
  {
    "zip": 24374,
    "lat": 36.79077,
    "lng": -81.19621,
    "state_id": "VA"
  },
  {
    "zip": 24375,
    "lat": 36.77177,
    "lng": -81.38824,
    "state_id": "VA"
  },
  {
    "zip": 24377,
    "lat": 37.0008,
    "lng": -81.57247,
    "state_id": "VA"
  },
  {
    "zip": 24378,
    "lat": 36.68736,
    "lng": -81.44408,
    "state_id": "VA"
  },
  {
    "zip": 24380,
    "lat": 36.86007,
    "lng": -80.50025,
    "state_id": "VA"
  },
  {
    "zip": 24381,
    "lat": 36.72755,
    "lng": -80.84298,
    "state_id": "VA"
  },
  {
    "zip": 24382,
    "lat": 36.96023,
    "lng": -81.09972,
    "state_id": "VA"
  },
  {
    "zip": 24401,
    "lat": 38.13353,
    "lng": -79.08591,
    "state_id": "VA"
  },
  {
    "zip": 24411,
    "lat": 38.10519,
    "lng": -79.3111,
    "state_id": "VA"
  },
  {
    "zip": 24412,
    "lat": 38.05505,
    "lng": -79.82804,
    "state_id": "VA"
  },
  {
    "zip": 24413,
    "lat": 38.52851,
    "lng": -79.59324,
    "state_id": "VA"
  },
  {
    "zip": 24415,
    "lat": 37.92506,
    "lng": -79.31711,
    "state_id": "VA"
  },
  {
    "zip": 24416,
    "lat": 37.7223,
    "lng": -79.3607,
    "state_id": "VA"
  },
  {
    "zip": 24421,
    "lat": 38.26198,
    "lng": -79.20084,
    "state_id": "VA"
  },
  {
    "zip": 24422,
    "lat": 37.82994,
    "lng": -79.75643,
    "state_id": "VA"
  },
  {
    "zip": 24426,
    "lat": 37.76844,
    "lng": -80.07654,
    "state_id": "VA"
  },
  {
    "zip": 24430,
    "lat": 38.08803,
    "lng": -79.35441,
    "state_id": "VA"
  },
  {
    "zip": 24431,
    "lat": 38.16873,
    "lng": -78.84034,
    "state_id": "VA"
  },
  {
    "zip": 24432,
    "lat": 38.16222,
    "lng": -79.43398,
    "state_id": "VA"
  },
  {
    "zip": 24433,
    "lat": 38.42073,
    "lng": -79.46284,
    "state_id": "VA"
  },
  {
    "zip": 24435,
    "lat": 37.87203,
    "lng": -79.30305,
    "state_id": "VA"
  },
  {
    "zip": 24437,
    "lat": 38.22158,
    "lng": -78.93861,
    "state_id": "VA"
  },
  {
    "zip": 24439,
    "lat": 37.99289,
    "lng": -79.48967,
    "state_id": "VA"
  },
  {
    "zip": 24440,
    "lat": 37.99463,
    "lng": -79.16613,
    "state_id": "VA"
  },
  {
    "zip": 24441,
    "lat": 38.22631,
    "lng": -78.82184,
    "state_id": "VA"
  },
  {
    "zip": 24442,
    "lat": 38.33915,
    "lng": -79.40838,
    "state_id": "VA"
  },
  {
    "zip": 24445,
    "lat": 37.95507,
    "lng": -79.88501,
    "state_id": "VA"
  },
  {
    "zip": 24448,
    "lat": 37.79831,
    "lng": -79.78995,
    "state_id": "VA"
  },
  {
    "zip": 24450,
    "lat": 37.78579,
    "lng": -79.52385,
    "state_id": "VA"
  },
  {
    "zip": 24457,
    "lat": 37.77061,
    "lng": -79.92019,
    "state_id": "VA"
  },
  {
    "zip": 24458,
    "lat": 38.31187,
    "lng": -79.525,
    "state_id": "VA"
  },
  {
    "zip": 24459,
    "lat": 38.02689,
    "lng": -79.28828,
    "state_id": "VA"
  },
  {
    "zip": 24460,
    "lat": 38.01893,
    "lng": -79.65204,
    "state_id": "VA"
  },
  {
    "zip": 24464,
    "lat": 37.87323,
    "lng": -79.10378,
    "state_id": "VA"
  },
  {
    "zip": 24465,
    "lat": 38.38024,
    "lng": -79.64631,
    "state_id": "VA"
  },
  {
    "zip": 24467,
    "lat": 38.25999,
    "lng": -78.97223,
    "state_id": "VA"
  },
  {
    "zip": 24471,
    "lat": 38.30905,
    "lng": -78.79629,
    "state_id": "VA"
  },
  {
    "zip": 24472,
    "lat": 37.93755,
    "lng": -79.21452,
    "state_id": "VA"
  },
  {
    "zip": 24473,
    "lat": 37.91697,
    "lng": -79.41446,
    "state_id": "VA"
  },
  {
    "zip": 24474,
    "lat": 37.8057,
    "lng": -79.84541,
    "state_id": "VA"
  },
  {
    "zip": 24476,
    "lat": 37.97044,
    "lng": -79.2233,
    "state_id": "VA"
  },
  {
    "zip": 24477,
    "lat": 38.00446,
    "lng": -79.04232,
    "state_id": "VA"
  },
  {
    "zip": 24479,
    "lat": 38.15207,
    "lng": -79.24144,
    "state_id": "VA"
  },
  {
    "zip": 24482,
    "lat": 38.20521,
    "lng": -78.99551,
    "state_id": "VA"
  },
  {
    "zip": 24483,
    "lat": 37.81192,
    "lng": -79.22925,
    "state_id": "VA"
  },
  {
    "zip": 24484,
    "lat": 38.14374,
    "lng": -79.8207,
    "state_id": "VA"
  },
  {
    "zip": 24485,
    "lat": 38.27271,
    "lng": -79.33542,
    "state_id": "VA"
  },
  {
    "zip": 24486,
    "lat": 38.29299,
    "lng": -78.93013,
    "state_id": "VA"
  },
  {
    "zip": 24487,
    "lat": 38.18974,
    "lng": -79.58785,
    "state_id": "VA"
  },
  {
    "zip": 24501,
    "lat": 37.353,
    "lng": -79.15576,
    "state_id": "VA"
  },
  {
    "zip": 24502,
    "lat": 37.35805,
    "lng": -79.22058,
    "state_id": "VA"
  },
  {
    "zip": 24503,
    "lat": 37.45331,
    "lng": -79.24965,
    "state_id": "VA"
  },
  {
    "zip": 24504,
    "lat": 37.36104,
    "lng": -79.05445,
    "state_id": "VA"
  },
  {
    "zip": 24517,
    "lat": 37.14937,
    "lng": -79.22977,
    "state_id": "VA"
  },
  {
    "zip": 24520,
    "lat": 36.58395,
    "lng": -79.03673,
    "state_id": "VA"
  },
  {
    "zip": 24521,
    "lat": 37.63442,
    "lng": -79.09689,
    "state_id": "VA"
  },
  {
    "zip": 24522,
    "lat": 37.37369,
    "lng": -78.78523,
    "state_id": "VA"
  },
  {
    "zip": 24523,
    "lat": 37.34003,
    "lng": -79.5221,
    "state_id": "VA"
  },
  {
    "zip": 24526,
    "lat": 37.54207,
    "lng": -79.40242,
    "state_id": "VA"
  },
  {
    "zip": 24527,
    "lat": 36.7369,
    "lng": -79.34396,
    "state_id": "VA"
  },
  {
    "zip": 24528,
    "lat": 37.07678,
    "lng": -78.87836,
    "state_id": "VA"
  },
  {
    "zip": 24529,
    "lat": 36.62441,
    "lng": -78.64117,
    "state_id": "VA"
  },
  {
    "zip": 24530,
    "lat": 36.80401,
    "lng": -79.61411,
    "state_id": "VA"
  },
  {
    "zip": 24531,
    "lat": 36.84029,
    "lng": -79.44817,
    "state_id": "VA"
  },
  {
    "zip": 24534,
    "lat": 36.87472,
    "lng": -78.76692,
    "state_id": "VA"
  },
  {
    "zip": 24536,
    "lat": 37.49299,
    "lng": -79.32598,
    "state_id": "VA"
  },
  {
    "zip": 24538,
    "lat": 37.33355,
    "lng": -78.95837,
    "state_id": "VA"
  },
  {
    "zip": 24539,
    "lat": 36.85133,
    "lng": -78.9179,
    "state_id": "VA"
  },
  {
    "zip": 24540,
    "lat": 36.64309,
    "lng": -79.4234,
    "state_id": "VA"
  },
  {
    "zip": 24541,
    "lat": 36.59208,
    "lng": -79.51244,
    "state_id": "VA"
  },
  {
    "zip": 24549,
    "lat": 36.71496,
    "lng": -79.50706,
    "state_id": "VA"
  },
  {
    "zip": 24550,
    "lat": 37.23318,
    "lng": -79.25998,
    "state_id": "VA"
  },
  {
    "zip": 24551,
    "lat": 37.35604,
    "lng": -79.32368,
    "state_id": "VA"
  },
  {
    "zip": 24553,
    "lat": 37.54759,
    "lng": -78.81782,
    "state_id": "VA"
  },
  {
    "zip": 24554,
    "lat": 37.13308,
    "lng": -79.0626,
    "state_id": "VA"
  },
  {
    "zip": 24555,
    "lat": 37.66874,
    "lng": -79.47241,
    "state_id": "VA"
  },
  {
    "zip": 24556,
    "lat": 37.38279,
    "lng": -79.39626,
    "state_id": "VA"
  },
  {
    "zip": 24557,
    "lat": 36.96662,
    "lng": -79.30432,
    "state_id": "VA"
  },
  {
    "zip": 24558,
    "lat": 36.77421,
    "lng": -78.95416,
    "state_id": "VA"
  },
  {
    "zip": 24562,
    "lat": 37.69247,
    "lng": -78.62293,
    "state_id": "VA"
  },
  {
    "zip": 24563,
    "lat": 37.06392,
    "lng": -79.27634,
    "state_id": "VA"
  },
  {
    "zip": 24565,
    "lat": 36.8499,
    "lng": -79.18943,
    "state_id": "VA"
  },
  {
    "zip": 24566,
    "lat": 36.72748,
    "lng": -79.25076,
    "state_id": "VA"
  },
  {
    "zip": 24569,
    "lat": 37.04485,
    "lng": -79.12173,
    "state_id": "VA"
  },
  {
    "zip": 24570,
    "lat": 37.34837,
    "lng": -79.42701,
    "state_id": "VA"
  },
  {
    "zip": 24571,
    "lat": 37.13691,
    "lng": -79.37932,
    "state_id": "VA"
  },
  {
    "zip": 24572,
    "lat": 37.46363,
    "lng": -79.0965,
    "state_id": "VA"
  },
  {
    "zip": 24574,
    "lat": 37.57697,
    "lng": -79.2598,
    "state_id": "VA"
  },
  {
    "zip": 24577,
    "lat": 36.93564,
    "lng": -78.97195,
    "state_id": "VA"
  },
  {
    "zip": 24578,
    "lat": 37.66174,
    "lng": -79.55753,
    "state_id": "VA"
  },
  {
    "zip": 24579,
    "lat": 37.58705,
    "lng": -79.50457,
    "state_id": "VA"
  },
  {
    "zip": 24580,
    "lat": 36.58095,
    "lng": -78.68318,
    "state_id": "VA"
  },
  {
    "zip": 24581,
    "lat": 37.6575,
    "lng": -78.80464,
    "state_id": "VA"
  },
  {
    "zip": 24586,
    "lat": 36.60699,
    "lng": -79.283,
    "state_id": "VA"
  },
  {
    "zip": 24588,
    "lat": 37.26276,
    "lng": -79.09685,
    "state_id": "VA"
  },
  {
    "zip": 24589,
    "lat": 36.75875,
    "lng": -78.75788,
    "state_id": "VA"
  },
  {
    "zip": 24590,
    "lat": 37.79308,
    "lng": -78.48481,
    "state_id": "VA"
  },
  {
    "zip": 24592,
    "lat": 36.67561,
    "lng": -78.9636,
    "state_id": "VA"
  },
  {
    "zip": 24593,
    "lat": 37.32795,
    "lng": -78.91137,
    "state_id": "VA"
  },
  {
    "zip": 24594,
    "lat": 36.64366,
    "lng": -79.18779,
    "state_id": "VA"
  },
  {
    "zip": 24595,
    "lat": 37.55779,
    "lng": -79.08251,
    "state_id": "VA"
  },
  {
    "zip": 24597,
    "lat": 36.78531,
    "lng": -79.10954,
    "state_id": "VA"
  },
  {
    "zip": 24598,
    "lat": 36.60742,
    "lng": -78.78345,
    "state_id": "VA"
  },
  {
    "zip": 24599,
    "lat": 37.63047,
    "lng": -78.72569,
    "state_id": "VA"
  },
  {
    "zip": 24601,
    "lat": 37.19378,
    "lng": -81.64921,
    "state_id": "VA"
  },
  {
    "zip": 24602,
    "lat": 37.18279,
    "lng": -81.65999,
    "state_id": "VA"
  },
  {
    "zip": 24603,
    "lat": 37.35328,
    "lng": -82.19682,
    "state_id": "VA"
  },
  {
    "zip": 24604,
    "lat": 37.20996,
    "lng": -81.54212,
    "state_id": "VA"
  },
  {
    "zip": 24605,
    "lat": 37.2448,
    "lng": -81.36635,
    "state_id": "VA"
  },
  {
    "zip": 24606,
    "lat": 37.28771,
    "lng": -81.39385,
    "state_id": "VA"
  },
  {
    "zip": 24607,
    "lat": 37.29078,
    "lng": -82.2653,
    "state_id": "VA"
  },
  {
    "zip": 24609,
    "lat": 37.0107,
    "lng": -81.81373,
    "state_id": "VA"
  },
  {
    "zip": 24612,
    "lat": 37.09338,
    "lng": -81.83828,
    "state_id": "VA"
  },
  {
    "zip": 24613,
    "lat": 37.26168,
    "lng": -81.33695,
    "state_id": "VA"
  },
  {
    "zip": 24614,
    "lat": 37.31062,
    "lng": -82.06499,
    "state_id": "VA"
  },
  {
    "zip": 24620,
    "lat": 37.42993,
    "lng": -82.01884,
    "state_id": "VA"
  },
  {
    "zip": 24622,
    "lat": 37.20942,
    "lng": -81.79562,
    "state_id": "VA"
  },
  {
    "zip": 24628,
    "lat": 37.28718,
    "lng": -82.2177,
    "state_id": "VA"
  },
  {
    "zip": 24630,
    "lat": 37.17592,
    "lng": -81.46741,
    "state_id": "VA"
  },
  {
    "zip": 24631,
    "lat": 37.21374,
    "lng": -81.99169,
    "state_id": "VA"
  },
  {
    "zip": 24634,
    "lat": 37.27465,
    "lng": -81.90232,
    "state_id": "VA"
  },
  {
    "zip": 24635,
    "lat": 37.309,
    "lng": -81.35133,
    "state_id": "VA"
  },
  {
    "zip": 24637,
    "lat": 37.05873,
    "lng": -81.70332,
    "state_id": "VA"
  },
  {
    "zip": 24639,
    "lat": 37.16695,
    "lng": -81.89861,
    "state_id": "VA"
  },
  {
    "zip": 24641,
    "lat": 37.11584,
    "lng": -81.80373,
    "state_id": "VA"
  },
  {
    "zip": 24646,
    "lat": 37.13642,
    "lng": -82.02889,
    "state_id": "VA"
  },
  {
    "zip": 24649,
    "lat": 37.07276,
    "lng": -81.90936,
    "state_id": "VA"
  },
  {
    "zip": 24651,
    "lat": 37.07667,
    "lng": -81.50416,
    "state_id": "VA"
  },
  {
    "zip": 24656,
    "lat": 37.17393,
    "lng": -82.1233,
    "state_id": "VA"
  },
  {
    "zip": 24657,
    "lat": 37.22509,
    "lng": -81.8605,
    "state_id": "VA"
  },
  {
    "zip": 5001,
    "lat": 43.67222,
    "lng": -72.38133,
    "state_id": "VT"
  },
  {
    "zip": 5031,
    "lat": 43.71205,
    "lng": -72.59101,
    "state_id": "VT"
  },
  {
    "zip": 5032,
    "lat": 43.80641,
    "lng": -72.65941,
    "state_id": "VT"
  },
  {
    "zip": 5033,
    "lat": 44.00888,
    "lng": -72.15799,
    "state_id": "VT"
  },
  {
    "zip": 5034,
    "lat": 43.5846,
    "lng": -72.63205,
    "state_id": "VT"
  },
  {
    "zip": 5035,
    "lat": 43.60153,
    "lng": -72.68757,
    "state_id": "VT"
  },
  {
    "zip": 5036,
    "lat": 44.02644,
    "lng": -72.57977,
    "state_id": "VT"
  },
  {
    "zip": 5037,
    "lat": 43.45943,
    "lng": -72.4915,
    "state_id": "VT"
  },
  {
    "zip": 5038,
    "lat": 43.99436,
    "lng": -72.45721,
    "state_id": "VT"
  },
  {
    "zip": 5039,
    "lat": 44.03669,
    "lng": -72.29713,
    "state_id": "VT"
  },
  {
    "zip": 5040,
    "lat": 44.06711,
    "lng": -72.20241,
    "state_id": "VT"
  },
  {
    "zip": 5041,
    "lat": 43.95065,
    "lng": -72.54065,
    "state_id": "VT"
  },
  {
    "zip": 5042,
    "lat": 44.22756,
    "lng": -72.09962,
    "state_id": "VT"
  },
  {
    "zip": 5043,
    "lat": 43.8081,
    "lng": -72.20826,
    "state_id": "VT"
  },
  {
    "zip": 5045,
    "lat": 43.91719,
    "lng": -72.19868,
    "state_id": "VT"
  },
  {
    "zip": 5046,
    "lat": 44.23643,
    "lng": -72.25272,
    "state_id": "VT"
  },
  {
    "zip": 5048,
    "lat": 43.58199,
    "lng": -72.4237,
    "state_id": "VT"
  },
  {
    "zip": 5050,
    "lat": 44.26743,
    "lng": -72.06423,
    "state_id": "VT"
  },
  {
    "zip": 5051,
    "lat": 44.10302,
    "lng": -72.12118,
    "state_id": "VT"
  },
  {
    "zip": 5052,
    "lat": 43.59931,
    "lng": -72.35792,
    "state_id": "VT"
  },
  {
    "zip": 5053,
    "lat": 43.71934,
    "lng": -72.48795,
    "state_id": "VT"
  },
  {
    "zip": 5055,
    "lat": 43.75411,
    "lng": -72.31311,
    "state_id": "VT"
  },
  {
    "zip": 5056,
    "lat": 43.51922,
    "lng": -72.72269,
    "state_id": "VT"
  },
  {
    "zip": 5058,
    "lat": 43.88578,
    "lng": -72.26998,
    "state_id": "VT"
  },
  {
    "zip": 5059,
    "lat": 43.64003,
    "lng": -72.42622,
    "state_id": "VT"
  },
  {
    "zip": 5060,
    "lat": 43.95922,
    "lng": -72.69001,
    "state_id": "VT"
  },
  {
    "zip": 5061,
    "lat": 43.9321,
    "lng": -72.58631,
    "state_id": "VT"
  },
  {
    "zip": 5062,
    "lat": 43.49791,
    "lng": -72.59247,
    "state_id": "VT"
  },
  {
    "zip": 5065,
    "lat": 43.7845,
    "lng": -72.42335,
    "state_id": "VT"
  },
  {
    "zip": 5067,
    "lat": 43.68816,
    "lng": -72.51317,
    "state_id": "VT"
  },
  {
    "zip": 5068,
    "lat": 43.79648,
    "lng": -72.53527,
    "state_id": "VT"
  },
  {
    "zip": 5069,
    "lat": 44.19055,
    "lng": -72.13678,
    "state_id": "VT"
  },
  {
    "zip": 5070,
    "lat": 43.83988,
    "lng": -72.36404,
    "state_id": "VT"
  },
  {
    "zip": 5071,
    "lat": 43.56065,
    "lng": -72.55341,
    "state_id": "VT"
  },
  {
    "zip": 5072,
    "lat": 43.88308,
    "lng": -72.37878,
    "state_id": "VT"
  },
  {
    "zip": 5075,
    "lat": 43.83699,
    "lng": -72.27976,
    "state_id": "VT"
  },
  {
    "zip": 5076,
    "lat": 44.1506,
    "lng": -72.23238,
    "state_id": "VT"
  },
  {
    "zip": 5077,
    "lat": 43.90325,
    "lng": -72.48074,
    "state_id": "VT"
  },
  {
    "zip": 5079,
    "lat": 43.95624,
    "lng": -72.3246,
    "state_id": "VT"
  },
  {
    "zip": 5081,
    "lat": 44.13695,
    "lng": -72.0723,
    "state_id": "VT"
  },
  {
    "zip": 5083,
    "lat": 43.91882,
    "lng": -72.26809,
    "state_id": "VT"
  },
  {
    "zip": 5084,
    "lat": 43.72711,
    "lng": -72.44982,
    "state_id": "VT"
  },
  {
    "zip": 5086,
    "lat": 44.11433,
    "lng": -72.30077,
    "state_id": "VT"
  },
  {
    "zip": 5089,
    "lat": 43.49224,
    "lng": -72.44642,
    "state_id": "VT"
  },
  {
    "zip": 5091,
    "lat": 43.64021,
    "lng": -72.57302,
    "state_id": "VT"
  },
  {
    "zip": 5101,
    "lat": 43.17899,
    "lng": -72.49454,
    "state_id": "VT"
  },
  {
    "zip": 5141,
    "lat": 43.15352,
    "lng": -72.56688,
    "state_id": "VT"
  },
  {
    "zip": 5142,
    "lat": 43.403,
    "lng": -72.58629,
    "state_id": "VT"
  },
  {
    "zip": 5143,
    "lat": 43.2701,
    "lng": -72.63211,
    "state_id": "VT"
  },
  {
    "zip": 5146,
    "lat": 43.17621,
    "lng": -72.61729,
    "state_id": "VT"
  },
  {
    "zip": 5148,
    "lat": 43.22877,
    "lng": -72.79999,
    "state_id": "VT"
  },
  {
    "zip": 5149,
    "lat": 43.38014,
    "lng": -72.71158,
    "state_id": "VT"
  },
  {
    "zip": 5150,
    "lat": 43.33458,
    "lng": -72.52925,
    "state_id": "VT"
  },
  {
    "zip": 5151,
    "lat": 43.39699,
    "lng": -72.4906,
    "state_id": "VT"
  },
  {
    "zip": 5152,
    "lat": 43.25358,
    "lng": -72.9153,
    "state_id": "VT"
  },
  {
    "zip": 5153,
    "lat": 43.41,
    "lng": -72.64158,
    "state_id": "VT"
  },
  {
    "zip": 5154,
    "lat": 43.13561,
    "lng": -72.5355,
    "state_id": "VT"
  },
  {
    "zip": 5155,
    "lat": 43.12417,
    "lng": -72.89441,
    "state_id": "VT"
  },
  {
    "zip": 5156,
    "lat": 43.30577,
    "lng": -72.47029,
    "state_id": "VT"
  },
  {
    "zip": 5158,
    "lat": 43.09816,
    "lng": -72.47035,
    "state_id": "VT"
  },
  {
    "zip": 5161,
    "lat": 43.31566,
    "lng": -72.80597,
    "state_id": "VT"
  },
  {
    "zip": 5201,
    "lat": 42.88108,
    "lng": -73.14144,
    "state_id": "VT"
  },
  {
    "zip": 5250,
    "lat": 43.12029,
    "lng": -73.19321,
    "state_id": "VT"
  },
  {
    "zip": 5251,
    "lat": 43.26218,
    "lng": -73.07048,
    "state_id": "VT"
  },
  {
    "zip": 5252,
    "lat": 43.0668,
    "lng": -73.06111,
    "state_id": "VT"
  },
  {
    "zip": 5253,
    "lat": 43.25148,
    "lng": -72.9993,
    "state_id": "VT"
  },
  {
    "zip": 5254,
    "lat": 43.15692,
    "lng": -73.06683,
    "state_id": "VT"
  },
  {
    "zip": 5255,
    "lat": 43.16674,
    "lng": -73.06638,
    "state_id": "VT"
  },
  {
    "zip": 5257,
    "lat": 42.95913,
    "lng": -73.25389,
    "state_id": "VT"
  },
  {
    "zip": 5260,
    "lat": 42.80718,
    "lng": -73.26493,
    "state_id": "VT"
  },
  {
    "zip": 5261,
    "lat": 42.78226,
    "lng": -73.19745,
    "state_id": "VT"
  },
  {
    "zip": 5262,
    "lat": 42.98044,
    "lng": -73.12684,
    "state_id": "VT"
  },
  {
    "zip": 5301,
    "lat": 42.84622,
    "lng": -72.66073,
    "state_id": "VT"
  },
  {
    "zip": 5340,
    "lat": 43.16232,
    "lng": -72.93418,
    "state_id": "VT"
  },
  {
    "zip": 5341,
    "lat": 42.96365,
    "lng": -72.79678,
    "state_id": "VT"
  },
  {
    "zip": 5342,
    "lat": 42.76828,
    "lng": -72.79471,
    "state_id": "VT"
  },
  {
    "zip": 5343,
    "lat": 43.09865,
    "lng": -72.80043,
    "state_id": "VT"
  },
  {
    "zip": 5345,
    "lat": 42.98434,
    "lng": -72.67994,
    "state_id": "VT"
  },
  {
    "zip": 5346,
    "lat": 43.02506,
    "lng": -72.53167,
    "state_id": "VT"
  },
  {
    "zip": 5350,
    "lat": 42.79875,
    "lng": -72.97178,
    "state_id": "VT"
  },
  {
    "zip": 5352,
    "lat": 42.788,
    "lng": -73.07852,
    "state_id": "VT"
  },
  {
    "zip": 5353,
    "lat": 43.06537,
    "lng": -72.66648,
    "state_id": "VT"
  },
  {
    "zip": 5354,
    "lat": 42.76226,
    "lng": -72.52597,
    "state_id": "VT"
  },
  {
    "zip": 5355,
    "lat": 43.02052,
    "lng": -72.80645,
    "state_id": "VT"
  },
  {
    "zip": 5356,
    "lat": 42.95346,
    "lng": -72.86865,
    "state_id": "VT"
  },
  {
    "zip": 5358,
    "lat": 42.7629,
    "lng": -72.72087,
    "state_id": "VT"
  },
  {
    "zip": 5359,
    "lat": 43.15259,
    "lng": -72.71587,
    "state_id": "VT"
  },
  {
    "zip": 5360,
    "lat": 43.00253,
    "lng": -72.94412,
    "state_id": "VT"
  },
  {
    "zip": 5361,
    "lat": 42.78275,
    "lng": -72.8765,
    "state_id": "VT"
  },
  {
    "zip": 5362,
    "lat": 42.93638,
    "lng": -72.66111,
    "state_id": "VT"
  },
  {
    "zip": 5363,
    "lat": 42.88181,
    "lng": -72.89291,
    "state_id": "VT"
  },
  {
    "zip": 5401,
    "lat": 44.47629,
    "lng": -73.20997,
    "state_id": "VT"
  },
  {
    "zip": 5403,
    "lat": 44.44697,
    "lng": -73.17237,
    "state_id": "VT"
  },
  {
    "zip": 5404,
    "lat": 44.49509,
    "lng": -73.18431,
    "state_id": "VT"
  },
  {
    "zip": 5405,
    "lat": 44.47375,
    "lng": -73.19564,
    "state_id": "VT"
  },
  {
    "zip": 5408,
    "lat": 44.512,
    "lng": -73.24919,
    "state_id": "VT"
  },
  {
    "zip": 5439,
    "lat": 44.49506,
    "lng": -73.16399,
    "state_id": "VT"
  },
  {
    "zip": 5440,
    "lat": 44.95953,
    "lng": -73.27952,
    "state_id": "VT"
  },
  {
    "zip": 5441,
    "lat": 44.79139,
    "lng": -72.76967,
    "state_id": "VT"
  },
  {
    "zip": 5442,
    "lat": 44.75855,
    "lng": -72.67678,
    "state_id": "VT"
  },
  {
    "zip": 5443,
    "lat": 44.13347,
    "lng": -73.03668,
    "state_id": "VT"
  },
  {
    "zip": 5444,
    "lat": 44.64772,
    "lng": -72.90086,
    "state_id": "VT"
  },
  {
    "zip": 5445,
    "lat": 44.30945,
    "lng": -73.22162,
    "state_id": "VT"
  },
  {
    "zip": 5446,
    "lat": 44.54857,
    "lng": -73.18529,
    "state_id": "VT"
  },
  {
    "zip": 5447,
    "lat": 44.93105,
    "lng": -72.70282,
    "state_id": "VT"
  },
  {
    "zip": 5448,
    "lat": 44.7555,
    "lng": -72.89736,
    "state_id": "VT"
  },
  {
    "zip": 5450,
    "lat": 44.90025,
    "lng": -72.79113,
    "state_id": "VT"
  },
  {
    "zip": 5452,
    "lat": 44.52414,
    "lng": -73.06239,
    "state_id": "VT"
  },
  {
    "zip": 5454,
    "lat": 44.70245,
    "lng": -73.027,
    "state_id": "VT"
  },
  {
    "zip": 5455,
    "lat": 44.80293,
    "lng": -72.96681,
    "state_id": "VT"
  },
  {
    "zip": 5456,
    "lat": 44.20764,
    "lng": -73.25509,
    "state_id": "VT"
  },
  {
    "zip": 5457,
    "lat": 44.97097,
    "lng": -72.91336,
    "state_id": "VT"
  },
  {
    "zip": 5458,
    "lat": 44.71825,
    "lng": -73.30543,
    "state_id": "VT"
  },
  {
    "zip": 5459,
    "lat": 44.95348,
    "lng": -73.00452,
    "state_id": "VT"
  },
  {
    "zip": 5461,
    "lat": 44.32219,
    "lng": -73.09131,
    "state_id": "VT"
  },
  {
    "zip": 5462,
    "lat": 44.30058,
    "lng": -72.9538,
    "state_id": "VT"
  },
  {
    "zip": 5463,
    "lat": 44.86946,
    "lng": -73.34108,
    "state_id": "VT"
  },
  {
    "zip": 5464,
    "lat": 44.64518,
    "lng": -72.81763,
    "state_id": "VT"
  },
  {
    "zip": 5465,
    "lat": 44.46831,
    "lng": -72.94993,
    "state_id": "VT"
  },
  {
    "zip": 5468,
    "lat": 44.65535,
    "lng": -73.1456,
    "state_id": "VT"
  },
  {
    "zip": 5471,
    "lat": 44.85599,
    "lng": -72.59409,
    "state_id": "VT"
  },
  {
    "zip": 5472,
    "lat": 44.10887,
    "lng": -73.15993,
    "state_id": "VT"
  },
  {
    "zip": 5473,
    "lat": 44.24344,
    "lng": -73.19339,
    "state_id": "VT"
  },
  {
    "zip": 5474,
    "lat": 44.84032,
    "lng": -73.27363,
    "state_id": "VT"
  },
  {
    "zip": 5476,
    "lat": 44.96308,
    "lng": -72.64117,
    "state_id": "VT"
  },
  {
    "zip": 5477,
    "lat": 44.40316,
    "lng": -72.95475,
    "state_id": "VT"
  },
  {
    "zip": 5478,
    "lat": 44.80968,
    "lng": -73.08121,
    "state_id": "VT"
  },
  {
    "zip": 5481,
    "lat": 44.77291,
    "lng": -73.20506,
    "state_id": "VT"
  },
  {
    "zip": 5482,
    "lat": 44.38509,
    "lng": -73.20957,
    "state_id": "VT"
  },
  {
    "zip": 5483,
    "lat": 44.88195,
    "lng": -72.97202,
    "state_id": "VT"
  },
  {
    "zip": 5485,
    "lat": 44.90718,
    "lng": -72.97536,
    "state_id": "VT"
  },
  {
    "zip": 5486,
    "lat": 44.64061,
    "lng": -73.3108,
    "state_id": "VT"
  },
  {
    "zip": 5487,
    "lat": 44.2318,
    "lng": -73.01056,
    "state_id": "VT"
  },
  {
    "zip": 5488,
    "lat": 44.92141,
    "lng": -73.12487,
    "state_id": "VT"
  },
  {
    "zip": 5489,
    "lat": 44.54087,
    "lng": -72.88743,
    "state_id": "VT"
  },
  {
    "zip": 5491,
    "lat": 44.11254,
    "lng": -73.30877,
    "state_id": "VT"
  },
  {
    "zip": 5492,
    "lat": 44.7166,
    "lng": -72.75421,
    "state_id": "VT"
  },
  {
    "zip": 5494,
    "lat": 44.6024,
    "lng": -73.00523,
    "state_id": "VT"
  },
  {
    "zip": 5495,
    "lat": 44.42935,
    "lng": -73.09246,
    "state_id": "VT"
  },
  {
    "zip": 5602,
    "lat": 44.28362,
    "lng": -72.60497,
    "state_id": "VT"
  },
  {
    "zip": 5640,
    "lat": 44.34527,
    "lng": -72.4932,
    "state_id": "VT"
  },
  {
    "zip": 5641,
    "lat": 44.18881,
    "lng": -72.47294,
    "state_id": "VT"
  },
  {
    "zip": 5647,
    "lat": 44.40805,
    "lng": -72.28833,
    "state_id": "VT"
  },
  {
    "zip": 5648,
    "lat": 44.38501,
    "lng": -72.49349,
    "state_id": "VT"
  },
  {
    "zip": 5649,
    "lat": 44.15608,
    "lng": -72.35782,
    "state_id": "VT"
  },
  {
    "zip": 5650,
    "lat": 44.38001,
    "lng": -72.44187,
    "state_id": "VT"
  },
  {
    "zip": 5651,
    "lat": 44.27945,
    "lng": -72.48829,
    "state_id": "VT"
  },
  {
    "zip": 5652,
    "lat": 44.72831,
    "lng": -72.58748,
    "state_id": "VT"
  },
  {
    "zip": 5653,
    "lat": 44.7012,
    "lng": -72.49317,
    "state_id": "VT"
  },
  {
    "zip": 5654,
    "lat": 44.13977,
    "lng": -72.47465,
    "state_id": "VT"
  },
  {
    "zip": 5655,
    "lat": 44.62381,
    "lng": -72.57053,
    "state_id": "VT"
  },
  {
    "zip": 5656,
    "lat": 44.64938,
    "lng": -72.68772,
    "state_id": "VT"
  },
  {
    "zip": 5657,
    "lat": 44.53474,
    "lng": -72.5258,
    "state_id": "VT"
  },
  {
    "zip": 5658,
    "lat": 44.35812,
    "lng": -72.35581,
    "state_id": "VT"
  },
  {
    "zip": 5660,
    "lat": 44.24718,
    "lng": -72.74139,
    "state_id": "VT"
  },
  {
    "zip": 5661,
    "lat": 44.53797,
    "lng": -72.6184,
    "state_id": "VT"
  },
  {
    "zip": 5663,
    "lat": 44.15144,
    "lng": -72.67702,
    "state_id": "VT"
  },
  {
    "zip": 5664,
    "lat": 44.16712,
    "lng": -72.65313,
    "state_id": "VT"
  },
  {
    "zip": 5667,
    "lat": 44.27112,
    "lng": -72.39185,
    "state_id": "VT"
  },
  {
    "zip": 5669,
    "lat": 44.06666,
    "lng": -72.73084,
    "state_id": "VT"
  },
  {
    "zip": 5672,
    "lat": 44.48134,
    "lng": -72.7219,
    "state_id": "VT"
  },
  {
    "zip": 5673,
    "lat": 44.19366,
    "lng": -72.84186,
    "state_id": "VT"
  },
  {
    "zip": 5674,
    "lat": 44.11103,
    "lng": -72.86083,
    "state_id": "VT"
  },
  {
    "zip": 5675,
    "lat": 44.07382,
    "lng": -72.41872,
    "state_id": "VT"
  },
  {
    "zip": 5676,
    "lat": 44.34289,
    "lng": -72.80906,
    "state_id": "VT"
  },
  {
    "zip": 5677,
    "lat": 44.39285,
    "lng": -72.70967,
    "state_id": "VT"
  },
  {
    "zip": 5678,
    "lat": 44.15625,
    "lng": -72.47566,
    "state_id": "VT"
  },
  {
    "zip": 5679,
    "lat": 44.10737,
    "lng": -72.53974,
    "state_id": "VT"
  },
  {
    "zip": 5680,
    "lat": 44.54403,
    "lng": -72.47068,
    "state_id": "VT"
  },
  {
    "zip": 5681,
    "lat": 44.45476,
    "lng": -72.40368,
    "state_id": "VT"
  },
  {
    "zip": 5682,
    "lat": 44.403,
    "lng": -72.57147,
    "state_id": "VT"
  },
  {
    "zip": 5701,
    "lat": 43.62458,
    "lng": -72.90981,
    "state_id": "VT"
  },
  {
    "zip": 5730,
    "lat": 43.41371,
    "lng": -72.82335,
    "state_id": "VT"
  },
  {
    "zip": 5732,
    "lat": 43.68531,
    "lng": -73.18255,
    "state_id": "VT"
  },
  {
    "zip": 5733,
    "lat": 43.82007,
    "lng": -73.09189,
    "state_id": "VT"
  },
  {
    "zip": 5734,
    "lat": 43.9821,
    "lng": -73.33027,
    "state_id": "VT"
  },
  {
    "zip": 5735,
    "lat": 43.64413,
    "lng": -73.15642,
    "state_id": "VT"
  },
  {
    "zip": 5736,
    "lat": 43.61461,
    "lng": -73.00954,
    "state_id": "VT"
  },
  {
    "zip": 5737,
    "lat": 43.75901,
    "lng": -72.91196,
    "state_id": "VT"
  },
  {
    "zip": 5738,
    "lat": 43.52929,
    "lng": -72.85566,
    "state_id": "VT"
  },
  {
    "zip": 5739,
    "lat": 43.33996,
    "lng": -73.00605,
    "state_id": "VT"
  },
  {
    "zip": 5740,
    "lat": 43.97165,
    "lng": -73.10536,
    "state_id": "VT"
  },
  {
    "zip": 5742,
    "lat": 43.43637,
    "lng": -72.90138,
    "state_id": "VT"
  },
  {
    "zip": 5743,
    "lat": 43.66962,
    "lng": -73.30619,
    "state_id": "VT"
  },
  {
    "zip": 5744,
    "lat": 43.69842,
    "lng": -73.08496,
    "state_id": "VT"
  },
  {
    "zip": 5747,
    "lat": 44.00517,
    "lng": -72.84161,
    "state_id": "VT"
  },
  {
    "zip": 5748,
    "lat": 43.91973,
    "lng": -72.91087,
    "state_id": "VT"
  },
  {
    "zip": 5751,
    "lat": 43.65428,
    "lng": -72.78924,
    "state_id": "VT"
  },
  {
    "zip": 5753,
    "lat": 44.00005,
    "lng": -73.17543,
    "state_id": "VT"
  },
  {
    "zip": 5757,
    "lat": 43.4789,
    "lng": -73.12485,
    "state_id": "VT"
  },
  {
    "zip": 5758,
    "lat": 43.43386,
    "lng": -72.78729,
    "state_id": "VT"
  },
  {
    "zip": 5759,
    "lat": 43.53707,
    "lng": -72.96268,
    "state_id": "VT"
  },
  {
    "zip": 5760,
    "lat": 43.80274,
    "lng": -73.29282,
    "state_id": "VT"
  },
  {
    "zip": 5761,
    "lat": 43.36223,
    "lng": -73.14953,
    "state_id": "VT"
  },
  {
    "zip": 5762,
    "lat": 43.79273,
    "lng": -72.82914,
    "state_id": "VT"
  },
  {
    "zip": 5763,
    "lat": 43.72667,
    "lng": -72.99965,
    "state_id": "VT"
  },
  {
    "zip": 5764,
    "lat": 43.53191,
    "lng": -73.19178,
    "state_id": "VT"
  },
  {
    "zip": 5765,
    "lat": 43.65249,
    "lng": -73.0351,
    "state_id": "VT"
  },
  {
    "zip": 5766,
    "lat": 43.99232,
    "lng": -72.98758,
    "state_id": "VT"
  },
  {
    "zip": 5767,
    "lat": 43.87508,
    "lng": -72.82878,
    "state_id": "VT"
  },
  {
    "zip": 5769,
    "lat": 43.91923,
    "lng": -73.11551,
    "state_id": "VT"
  },
  {
    "zip": 5770,
    "lat": 43.8869,
    "lng": -73.3204,
    "state_id": "VT"
  },
  {
    "zip": 5772,
    "lat": 43.75604,
    "lng": -72.74472,
    "state_id": "VT"
  },
  {
    "zip": 5773,
    "lat": 43.42157,
    "lng": -72.98612,
    "state_id": "VT"
  },
  {
    "zip": 5774,
    "lat": 43.43135,
    "lng": -73.1901,
    "state_id": "VT"
  },
  {
    "zip": 5775,
    "lat": 43.36247,
    "lng": -73.23133,
    "state_id": "VT"
  },
  {
    "zip": 5776,
    "lat": 43.26348,
    "lng": -73.19066,
    "state_id": "VT"
  },
  {
    "zip": 5777,
    "lat": 43.55818,
    "lng": -73.05647,
    "state_id": "VT"
  },
  {
    "zip": 5778,
    "lat": 43.87369,
    "lng": -73.21114,
    "state_id": "VT"
  },
  {
    "zip": 5819,
    "lat": 44.40509,
    "lng": -71.98762,
    "state_id": "VT"
  },
  {
    "zip": 5820,
    "lat": 44.73871,
    "lng": -72.34482,
    "state_id": "VT"
  },
  {
    "zip": 5821,
    "lat": 44.31356,
    "lng": -72.10953,
    "state_id": "VT"
  },
  {
    "zip": 5822,
    "lat": 44.75042,
    "lng": -72.14273,
    "state_id": "VT"
  },
  {
    "zip": 5824,
    "lat": 44.4368,
    "lng": -71.86146,
    "state_id": "VT"
  },
  {
    "zip": 5825,
    "lat": 44.86231,
    "lng": -72.24567,
    "state_id": "VT"
  },
  {
    "zip": 5826,
    "lat": 44.64661,
    "lng": -72.40245,
    "state_id": "VT"
  },
  {
    "zip": 5827,
    "lat": 44.68112,
    "lng": -72.35814,
    "state_id": "VT"
  },
  {
    "zip": 5828,
    "lat": 44.43709,
    "lng": -72.11974,
    "state_id": "VT"
  },
  {
    "zip": 5829,
    "lat": 44.95204,
    "lng": -72.07039,
    "state_id": "VT"
  },
  {
    "zip": 5830,
    "lat": 44.98021,
    "lng": -71.98493,
    "state_id": "VT"
  },
  {
    "zip": 5832,
    "lat": 44.5882,
    "lng": -71.91273,
    "state_id": "VT"
  },
  {
    "zip": 5833,
    "lat": 44.8257,
    "lng": -71.97743,
    "state_id": "VT"
  },
  {
    "zip": 5836,
    "lat": 44.51521,
    "lng": -72.2663,
    "state_id": "VT"
  },
  {
    "zip": 5837,
    "lat": 44.65856,
    "lng": -71.81538,
    "state_id": "VT"
  },
  {
    "zip": 5839,
    "lat": 44.67474,
    "lng": -72.21313,
    "state_id": "VT"
  },
  {
    "zip": 5841,
    "lat": 44.60692,
    "lng": -72.29446,
    "state_id": "VT"
  },
  {
    "zip": 5842,
    "lat": 44.55316,
    "lng": -72.2208,
    "state_id": "VT"
  },
  {
    "zip": 5843,
    "lat": 44.50925,
    "lng": -72.34706,
    "state_id": "VT"
  },
  {
    "zip": 5845,
    "lat": 44.79642,
    "lng": -72.30023,
    "state_id": "VT"
  },
  {
    "zip": 5846,
    "lat": 44.80129,
    "lng": -71.86309,
    "state_id": "VT"
  },
  {
    "zip": 5847,
    "lat": 44.79153,
    "lng": -72.45682,
    "state_id": "VT"
  },
  {
    "zip": 5850,
    "lat": 44.54163,
    "lng": -72.01749,
    "state_id": "VT"
  },
  {
    "zip": 5851,
    "lat": 44.55542,
    "lng": -72.07157,
    "state_id": "VT"
  },
  {
    "zip": 5853,
    "lat": 44.89474,
    "lng": -71.96653,
    "state_id": "VT"
  },
  {
    "zip": 5855,
    "lat": 44.92782,
    "lng": -72.19223,
    "state_id": "VT"
  },
  {
    "zip": 5857,
    "lat": 44.93599,
    "lng": -72.30465,
    "state_id": "VT"
  },
  {
    "zip": 5858,
    "lat": 44.54945,
    "lng": -71.79566,
    "state_id": "VT"
  },
  {
    "zip": 5859,
    "lat": 44.96727,
    "lng": -72.4453,
    "state_id": "VT"
  },
  {
    "zip": 5860,
    "lat": 44.80176,
    "lng": -72.10307,
    "state_id": "VT"
  },
  {
    "zip": 5862,
    "lat": 44.31892,
    "lng": -72.22587,
    "state_id": "VT"
  },
  {
    "zip": 5866,
    "lat": 44.64221,
    "lng": -72.12761,
    "state_id": "VT"
  },
  {
    "zip": 5867,
    "lat": 44.66286,
    "lng": -72.0422,
    "state_id": "VT"
  },
  {
    "zip": 5868,
    "lat": 44.89457,
    "lng": -72.37739,
    "state_id": "VT"
  },
  {
    "zip": 5871,
    "lat": 44.68772,
    "lng": -71.94178,
    "state_id": "VT"
  },
  {
    "zip": 5872,
    "lat": 44.8668,
    "lng": -72.04665,
    "state_id": "VT"
  },
  {
    "zip": 5873,
    "lat": 44.42209,
    "lng": -72.20549,
    "state_id": "VT"
  },
  {
    "zip": 5874,
    "lat": 44.87698,
    "lng": -72.46757,
    "state_id": "VT"
  },
  {
    "zip": 5875,
    "lat": 44.71084,
    "lng": -72.25655,
    "state_id": "VT"
  },
  {
    "zip": 5901,
    "lat": 44.94293,
    "lng": -71.68228,
    "state_id": "VT"
  },
  {
    "zip": 5902,
    "lat": 45.00852,
    "lng": -71.49034,
    "state_id": "VT"
  },
  {
    "zip": 5903,
    "lat": 44.93231,
    "lng": -71.58281,
    "state_id": "VT"
  },
  {
    "zip": 5904,
    "lat": 44.4166,
    "lng": -71.70654,
    "state_id": "VT"
  },
  {
    "zip": 5905,
    "lat": 44.69368,
    "lng": -71.66952,
    "state_id": "VT"
  },
  {
    "zip": 5906,
    "lat": 44.47605,
    "lng": -71.71485,
    "state_id": "VT"
  },
  {
    "zip": 5907,
    "lat": 44.92015,
    "lng": -71.8011,
    "state_id": "VT"
  },
  {
    "zip": 98001,
    "lat": 47.30998,
    "lng": -122.2652,
    "state_id": "WA"
  },
  {
    "zip": 98002,
    "lat": 47.30836,
    "lng": -122.21638,
    "state_id": "WA"
  },
  {
    "zip": 98003,
    "lat": 47.30512,
    "lng": -122.31507,
    "state_id": "WA"
  },
  {
    "zip": 98004,
    "lat": 47.61883,
    "lng": -122.20595,
    "state_id": "WA"
  },
  {
    "zip": 98005,
    "lat": 47.61478,
    "lng": -122.16862,
    "state_id": "WA"
  },
  {
    "zip": 98006,
    "lat": 47.55748,
    "lng": -122.15081,
    "state_id": "WA"
  },
  {
    "zip": 98007,
    "lat": 47.61445,
    "lng": -122.14381,
    "state_id": "WA"
  },
  {
    "zip": 98008,
    "lat": 47.60527,
    "lng": -122.11098,
    "state_id": "WA"
  },
  {
    "zip": 98010,
    "lat": 47.31309,
    "lng": -122.00055,
    "state_id": "WA"
  },
  {
    "zip": 98011,
    "lat": 47.75338,
    "lng": -122.20189,
    "state_id": "WA"
  },
  {
    "zip": 98012,
    "lat": 47.84156,
    "lng": -122.19911,
    "state_id": "WA"
  },
  {
    "zip": 98014,
    "lat": 47.66122,
    "lng": -121.89445,
    "state_id": "WA"
  },
  {
    "zip": 98019,
    "lat": 47.73961,
    "lng": -121.84941,
    "state_id": "WA"
  },
  {
    "zip": 98020,
    "lat": 47.80022,
    "lng": -122.37266,
    "state_id": "WA"
  },
  {
    "zip": 98021,
    "lat": 47.79257,
    "lng": -122.20819,
    "state_id": "WA"
  },
  {
    "zip": 98022,
    "lat": 47.15032,
    "lng": -121.65482,
    "state_id": "WA"
  },
  {
    "zip": 98023,
    "lat": 47.3088,
    "lng": -122.36269,
    "state_id": "WA"
  },
  {
    "zip": 98024,
    "lat": 47.57493,
    "lng": -121.90112,
    "state_id": "WA"
  },
  {
    "zip": 98026,
    "lat": 47.83559,
    "lng": -122.33164,
    "state_id": "WA"
  },
  {
    "zip": 98027,
    "lat": 47.50138,
    "lng": -121.99933,
    "state_id": "WA"
  },
  {
    "zip": 98028,
    "lat": 47.75423,
    "lng": -122.24753,
    "state_id": "WA"
  },
  {
    "zip": 98029,
    "lat": 47.55847,
    "lng": -122.00546,
    "state_id": "WA"
  },
  {
    "zip": 98030,
    "lat": 47.36821,
    "lng": -122.19736,
    "state_id": "WA"
  },
  {
    "zip": 98031,
    "lat": 47.40491,
    "lng": -122.19562,
    "state_id": "WA"
  },
  {
    "zip": 98032,
    "lat": 47.39234,
    "lng": -122.25872,
    "state_id": "WA"
  },
  {
    "zip": 98033,
    "lat": 47.67612,
    "lng": -122.19233,
    "state_id": "WA"
  },
  {
    "zip": 98034,
    "lat": 47.71577,
    "lng": -122.2158,
    "state_id": "WA"
  },
  {
    "zip": 98036,
    "lat": 47.81111,
    "lng": -122.2816,
    "state_id": "WA"
  },
  {
    "zip": 98037,
    "lat": 47.8392,
    "lng": -122.28549,
    "state_id": "WA"
  },
  {
    "zip": 98038,
    "lat": 47.41741,
    "lng": -121.95374,
    "state_id": "WA"
  },
  {
    "zip": 98039,
    "lat": 47.63305,
    "lng": -122.23961,
    "state_id": "WA"
  },
  {
    "zip": 98040,
    "lat": 47.56612,
    "lng": -122.23198,
    "state_id": "WA"
  },
  {
    "zip": 98042,
    "lat": 47.36623,
    "lng": -122.11717,
    "state_id": "WA"
  },
  {
    "zip": 98043,
    "lat": 47.792,
    "lng": -122.30743,
    "state_id": "WA"
  },
  {
    "zip": 98045,
    "lat": 47.39158,
    "lng": -121.63471,
    "state_id": "WA"
  },
  {
    "zip": 98047,
    "lat": 47.26208,
    "lng": -122.24756,
    "state_id": "WA"
  },
  {
    "zip": 98050,
    "lat": 47.54399,
    "lng": -121.94041,
    "state_id": "WA"
  },
  {
    "zip": 98051,
    "lat": 47.34072,
    "lng": -121.88739,
    "state_id": "WA"
  },
  {
    "zip": 98052,
    "lat": 47.68125,
    "lng": -122.12025,
    "state_id": "WA"
  },
  {
    "zip": 98053,
    "lat": 47.66565,
    "lng": -122.01946,
    "state_id": "WA"
  },
  {
    "zip": 98055,
    "lat": 47.44695,
    "lng": -122.20142,
    "state_id": "WA"
  },
  {
    "zip": 98056,
    "lat": 47.51287,
    "lng": -122.18959,
    "state_id": "WA"
  },
  {
    "zip": 98057,
    "lat": 47.47142,
    "lng": -122.22029,
    "state_id": "WA"
  },
  {
    "zip": 98058,
    "lat": 47.44181,
    "lng": -122.12439,
    "state_id": "WA"
  },
  {
    "zip": 98059,
    "lat": 47.50223,
    "lng": -122.12101,
    "state_id": "WA"
  },
  {
    "zip": 98065,
    "lat": 47.58459,
    "lng": -121.79164,
    "state_id": "WA"
  },
  {
    "zip": 98068,
    "lat": 47.44072,
    "lng": -121.34967,
    "state_id": "WA"
  },
  {
    "zip": 98070,
    "lat": 47.41219,
    "lng": -122.4726,
    "state_id": "WA"
  },
  {
    "zip": 98072,
    "lat": 47.76117,
    "lng": -122.13215,
    "state_id": "WA"
  },
  {
    "zip": 98074,
    "lat": 47.62263,
    "lng": -122.04314,
    "state_id": "WA"
  },
  {
    "zip": 98075,
    "lat": 47.58654,
    "lng": -122.03847,
    "state_id": "WA"
  },
  {
    "zip": 98077,
    "lat": 47.75293,
    "lng": -122.05826,
    "state_id": "WA"
  },
  {
    "zip": 98087,
    "lat": 47.863,
    "lng": -122.26643,
    "state_id": "WA"
  },
  {
    "zip": 98092,
    "lat": 47.28763,
    "lng": -122.12871,
    "state_id": "WA"
  },
  {
    "zip": 98101,
    "lat": 47.6113,
    "lng": -122.33465,
    "state_id": "WA"
  },
  {
    "zip": 98102,
    "lat": 47.63642,
    "lng": -122.32218,
    "state_id": "WA"
  },
  {
    "zip": 98103,
    "lat": 47.67332,
    "lng": -122.34254,
    "state_id": "WA"
  },
  {
    "zip": 98104,
    "lat": 47.60169,
    "lng": -122.32849,
    "state_id": "WA"
  },
  {
    "zip": 98105,
    "lat": 47.66068,
    "lng": -122.28403,
    "state_id": "WA"
  },
  {
    "zip": 98106,
    "lat": 47.54349,
    "lng": -122.35435,
    "state_id": "WA"
  },
  {
    "zip": 98107,
    "lat": 47.66762,
    "lng": -122.3781,
    "state_id": "WA"
  },
  {
    "zip": 98108,
    "lat": 47.54131,
    "lng": -122.31292,
    "state_id": "WA"
  },
  {
    "zip": 98109,
    "lat": 47.63159,
    "lng": -122.34417,
    "state_id": "WA"
  },
  {
    "zip": 98110,
    "lat": 47.64469,
    "lng": -122.54353,
    "state_id": "WA"
  },
  {
    "zip": 98112,
    "lat": 47.63392,
    "lng": -122.28893,
    "state_id": "WA"
  },
  {
    "zip": 98115,
    "lat": 47.685,
    "lng": -122.28216,
    "state_id": "WA"
  },
  {
    "zip": 98116,
    "lat": 47.57397,
    "lng": -122.39507,
    "state_id": "WA"
  },
  {
    "zip": 98117,
    "lat": 47.6882,
    "lng": -122.38148,
    "state_id": "WA"
  },
  {
    "zip": 98118,
    "lat": 47.54245,
    "lng": -122.2688,
    "state_id": "WA"
  },
  {
    "zip": 98119,
    "lat": 47.63995,
    "lng": -122.37005,
    "state_id": "WA"
  },
  {
    "zip": 98121,
    "lat": 47.61541,
    "lng": -122.34669,
    "state_id": "WA"
  },
  {
    "zip": 98122,
    "lat": 47.61151,
    "lng": -122.29184,
    "state_id": "WA"
  },
  {
    "zip": 98125,
    "lat": 47.71641,
    "lng": -122.29812,
    "state_id": "WA"
  },
  {
    "zip": 98126,
    "lat": 47.54768,
    "lng": -122.37442,
    "state_id": "WA"
  },
  {
    "zip": 98133,
    "lat": 47.73988,
    "lng": -122.34425,
    "state_id": "WA"
  },
  {
    "zip": 98134,
    "lat": 47.57783,
    "lng": -122.33743,
    "state_id": "WA"
  },
  {
    "zip": 98136,
    "lat": 47.53676,
    "lng": -122.38986,
    "state_id": "WA"
  },
  {
    "zip": 98144,
    "lat": 47.58596,
    "lng": -122.29237,
    "state_id": "WA"
  },
  {
    "zip": 98146,
    "lat": 47.50009,
    "lng": -122.35751,
    "state_id": "WA"
  },
  {
    "zip": 98148,
    "lat": 47.44375,
    "lng": -122.32508,
    "state_id": "WA"
  },
  {
    "zip": 98154,
    "lat": 47.60641,
    "lng": -122.33372,
    "state_id": "WA"
  },
  {
    "zip": 98155,
    "lat": 47.75601,
    "lng": -122.30034,
    "state_id": "WA"
  },
  {
    "zip": 98158,
    "lat": 47.45015,
    "lng": -122.30798,
    "state_id": "WA"
  },
  {
    "zip": 98164,
    "lat": 47.60596,
    "lng": -122.33203,
    "state_id": "WA"
  },
  {
    "zip": 98166,
    "lat": 47.45281,
    "lng": -122.3501,
    "state_id": "WA"
  },
  {
    "zip": 98168,
    "lat": 47.48887,
    "lng": -122.30122,
    "state_id": "WA"
  },
  {
    "zip": 98174,
    "lat": 47.60457,
    "lng": -122.33536,
    "state_id": "WA"
  },
  {
    "zip": 98177,
    "lat": 47.74226,
    "lng": -122.37079,
    "state_id": "WA"
  },
  {
    "zip": 98178,
    "lat": 47.49929,
    "lng": -122.24706,
    "state_id": "WA"
  },
  {
    "zip": 98188,
    "lat": 47.44822,
    "lng": -122.27317,
    "state_id": "WA"
  },
  {
    "zip": 98195,
    "lat": 47.64925,
    "lng": -122.30919,
    "state_id": "WA"
  },
  {
    "zip": 98198,
    "lat": 47.3946,
    "lng": -122.31122,
    "state_id": "WA"
  },
  {
    "zip": 98199,
    "lat": 47.65137,
    "lng": -122.40272,
    "state_id": "WA"
  },
  {
    "zip": 98201,
    "lat": 47.99339,
    "lng": -122.21327,
    "state_id": "WA"
  },
  {
    "zip": 98203,
    "lat": 47.94354,
    "lng": -122.23329,
    "state_id": "WA"
  },
  {
    "zip": 98204,
    "lat": 47.90132,
    "lng": -122.26088,
    "state_id": "WA"
  },
  {
    "zip": 98207,
    "lat": 47.98799,
    "lng": -122.22302,
    "state_id": "WA"
  },
  {
    "zip": 98208,
    "lat": 47.90187,
    "lng": -122.1865,
    "state_id": "WA"
  },
  {
    "zip": 98220,
    "lat": 48.68497,
    "lng": -122.19692,
    "state_id": "WA"
  },
  {
    "zip": 98221,
    "lat": 48.50321,
    "lng": -122.65492,
    "state_id": "WA"
  },
  {
    "zip": 98222,
    "lat": 48.56085,
    "lng": -122.80263,
    "state_id": "WA"
  },
  {
    "zip": 98223,
    "lat": 48.21048,
    "lng": -121.95172,
    "state_id": "WA"
  },
  {
    "zip": 98224,
    "lat": 47.66382,
    "lng": -121.51302,
    "state_id": "WA"
  },
  {
    "zip": 98225,
    "lat": 48.75302,
    "lng": -122.50144,
    "state_id": "WA"
  },
  {
    "zip": 98226,
    "lat": 48.80061,
    "lng": -122.42744,
    "state_id": "WA"
  },
  {
    "zip": 98229,
    "lat": 48.6955,
    "lng": -122.41046,
    "state_id": "WA"
  },
  {
    "zip": 98230,
    "lat": 48.95408,
    "lng": -122.71066,
    "state_id": "WA"
  },
  {
    "zip": 98232,
    "lat": 48.56535,
    "lng": -122.41566,
    "state_id": "WA"
  },
  {
    "zip": 98233,
    "lat": 48.50393,
    "lng": -122.35082,
    "state_id": "WA"
  },
  {
    "zip": 98235,
    "lat": 48.46475,
    "lng": -122.23332,
    "state_id": "WA"
  },
  {
    "zip": 98236,
    "lat": 47.95814,
    "lng": -122.40474,
    "state_id": "WA"
  },
  {
    "zip": 98237,
    "lat": 48.59328,
    "lng": -121.68519,
    "state_id": "WA"
  },
  {
    "zip": 98238,
    "lat": 48.33523,
    "lng": -122.34459,
    "state_id": "WA"
  },
  {
    "zip": 98239,
    "lat": 48.1916,
    "lng": -122.66083,
    "state_id": "WA"
  },
  {
    "zip": 98240,
    "lat": 48.9473,
    "lng": -122.62133,
    "state_id": "WA"
  },
  {
    "zip": 98241,
    "lat": 48.2237,
    "lng": -121.32766,
    "state_id": "WA"
  },
  {
    "zip": 98243,
    "lat": 48.62096,
    "lng": -123.00505,
    "state_id": "WA"
  },
  {
    "zip": 98244,
    "lat": 48.80005,
    "lng": -121.9544,
    "state_id": "WA"
  },
  {
    "zip": 98245,
    "lat": 48.66637,
    "lng": -122.91025,
    "state_id": "WA"
  },
  {
    "zip": 98247,
    "lat": 48.908,
    "lng": -122.32248,
    "state_id": "WA"
  },
  {
    "zip": 98248,
    "lat": 48.8659,
    "lng": -122.62,
    "state_id": "WA"
  },
  {
    "zip": 98249,
    "lat": 48.01818,
    "lng": -122.5391,
    "state_id": "WA"
  },
  {
    "zip": 98250,
    "lat": 48.55815,
    "lng": -123.0915,
    "state_id": "WA"
  },
  {
    "zip": 98251,
    "lat": 47.90263,
    "lng": -121.54536,
    "state_id": "WA"
  },
  {
    "zip": 98252,
    "lat": 48.07309,
    "lng": -121.68949,
    "state_id": "WA"
  },
  {
    "zip": 98253,
    "lat": 48.08535,
    "lng": -122.57687,
    "state_id": "WA"
  },
  {
    "zip": 98255,
    "lat": 48.52207,
    "lng": -121.98931,
    "state_id": "WA"
  },
  {
    "zip": 98256,
    "lat": 47.82999,
    "lng": -121.43037,
    "state_id": "WA"
  },
  {
    "zip": 98257,
    "lat": 48.41605,
    "lng": -122.5095,
    "state_id": "WA"
  },
  {
    "zip": 98258,
    "lat": 48.0424,
    "lng": -122.06897,
    "state_id": "WA"
  },
  {
    "zip": 98260,
    "lat": 48.03069,
    "lng": -122.45086,
    "state_id": "WA"
  },
  {
    "zip": 98261,
    "lat": 48.48381,
    "lng": -122.88303,
    "state_id": "WA"
  },
  {
    "zip": 98262,
    "lat": 48.69276,
    "lng": -122.6638,
    "state_id": "WA"
  },
  {
    "zip": 98263,
    "lat": 48.52196,
    "lng": -122.06694,
    "state_id": "WA"
  },
  {
    "zip": 98264,
    "lat": 48.95016,
    "lng": -122.46036,
    "state_id": "WA"
  },
  {
    "zip": 98266,
    "lat": 48.9533,
    "lng": -122.12832,
    "state_id": "WA"
  },
  {
    "zip": 98267,
    "lat": 48.44342,
    "lng": -121.31629,
    "state_id": "WA"
  },
  {
    "zip": 98270,
    "lat": 48.0596,
    "lng": -122.14454,
    "state_id": "WA"
  },
  {
    "zip": 98271,
    "lat": 48.09415,
    "lng": -122.2345,
    "state_id": "WA"
  },
  {
    "zip": 98272,
    "lat": 47.84931,
    "lng": -121.88521,
    "state_id": "WA"
  },
  {
    "zip": 98273,
    "lat": 48.40393,
    "lng": -122.36933,
    "state_id": "WA"
  },
  {
    "zip": 98274,
    "lat": 48.35934,
    "lng": -122.14555,
    "state_id": "WA"
  },
  {
    "zip": 98275,
    "lat": 47.9133,
    "lng": -122.29926,
    "state_id": "WA"
  },
  {
    "zip": 98276,
    "lat": 48.92698,
    "lng": -122.32597,
    "state_id": "WA"
  },
  {
    "zip": 98277,
    "lat": 48.31624,
    "lng": -122.63163,
    "state_id": "WA"
  },
  {
    "zip": 98278,
    "lat": 48.34128,
    "lng": -122.66293,
    "state_id": "WA"
  },
  {
    "zip": 98279,
    "lat": 48.64471,
    "lng": -122.8083,
    "state_id": "WA"
  },
  {
    "zip": 98280,
    "lat": 48.60694,
    "lng": -122.90963,
    "state_id": "WA"
  },
  {
    "zip": 98281,
    "lat": 48.98817,
    "lng": -123.05767,
    "state_id": "WA"
  },
  {
    "zip": 98282,
    "lat": 48.18657,
    "lng": -122.47075,
    "state_id": "WA"
  },
  {
    "zip": 98283,
    "lat": 48.80273,
    "lng": -121.25818,
    "state_id": "WA"
  },
  {
    "zip": 98284,
    "lat": 48.55307,
    "lng": -122.12046,
    "state_id": "WA"
  },
  {
    "zip": 98286,
    "lat": 48.57262,
    "lng": -122.95712,
    "state_id": "WA"
  },
  {
    "zip": 98288,
    "lat": 47.666,
    "lng": -121.27535,
    "state_id": "WA"
  },
  {
    "zip": 98290,
    "lat": 47.95104,
    "lng": -121.98023,
    "state_id": "WA"
  },
  {
    "zip": 98292,
    "lat": 48.2234,
    "lng": -122.3073,
    "state_id": "WA"
  },
  {
    "zip": 98294,
    "lat": 47.87617,
    "lng": -121.74479,
    "state_id": "WA"
  },
  {
    "zip": 98295,
    "lat": 48.97181,
    "lng": -122.22851,
    "state_id": "WA"
  },
  {
    "zip": 98296,
    "lat": 47.84067,
    "lng": -122.10169,
    "state_id": "WA"
  },
  {
    "zip": 98297,
    "lat": 48.7005,
    "lng": -123.02834,
    "state_id": "WA"
  },
  {
    "zip": 98303,
    "lat": 47.15783,
    "lng": -122.70387,
    "state_id": "WA"
  },
  {
    "zip": 98304,
    "lat": 46.86803,
    "lng": -121.75183,
    "state_id": "WA"
  },
  {
    "zip": 98305,
    "lat": 48.06029,
    "lng": -124.43012,
    "state_id": "WA"
  },
  {
    "zip": 98310,
    "lat": 47.59269,
    "lng": -122.623,
    "state_id": "WA"
  },
  {
    "zip": 98311,
    "lat": 47.63019,
    "lng": -122.63698,
    "state_id": "WA"
  },
  {
    "zip": 98312,
    "lat": 47.57326,
    "lng": -122.76008,
    "state_id": "WA"
  },
  {
    "zip": 98314,
    "lat": 47.55595,
    "lng": -122.6387,
    "state_id": "WA"
  },
  {
    "zip": 98315,
    "lat": 47.72288,
    "lng": -122.71457,
    "state_id": "WA"
  },
  {
    "zip": 98320,
    "lat": 47.72075,
    "lng": -122.99145,
    "state_id": "WA"
  },
  {
    "zip": 98321,
    "lat": 47.15287,
    "lng": -122.06432,
    "state_id": "WA"
  },
  {
    "zip": 98323,
    "lat": 47.04063,
    "lng": -121.88815,
    "state_id": "WA"
  },
  {
    "zip": 98325,
    "lat": 47.96585,
    "lng": -122.77839,
    "state_id": "WA"
  },
  {
    "zip": 98326,
    "lat": 48.16779,
    "lng": -124.38897,
    "state_id": "WA"
  },
  {
    "zip": 98327,
    "lat": 47.10126,
    "lng": -122.66074,
    "state_id": "WA"
  },
  {
    "zip": 98328,
    "lat": 46.86578,
    "lng": -122.17373,
    "state_id": "WA"
  },
  {
    "zip": 98329,
    "lat": 47.37783,
    "lng": -122.72546,
    "state_id": "WA"
  },
  {
    "zip": 98330,
    "lat": 46.77244,
    "lng": -122.16879,
    "state_id": "WA"
  },
  {
    "zip": 98331,
    "lat": 47.78437,
    "lng": -124.1851,
    "state_id": "WA"
  },
  {
    "zip": 98332,
    "lat": 47.365,
    "lng": -122.59887,
    "state_id": "WA"
  },
  {
    "zip": 98333,
    "lat": 47.24771,
    "lng": -122.62561,
    "state_id": "WA"
  },
  {
    "zip": 98335,
    "lat": 47.29895,
    "lng": -122.61892,
    "state_id": "WA"
  },
  {
    "zip": 98336,
    "lat": 46.56449,
    "lng": -122.11248,
    "state_id": "WA"
  },
  {
    "zip": 98337,
    "lat": 47.56861,
    "lng": -122.63088,
    "state_id": "WA"
  },
  {
    "zip": 98338,
    "lat": 47.02047,
    "lng": -122.28328,
    "state_id": "WA"
  },
  {
    "zip": 98339,
    "lat": 48.01877,
    "lng": -122.74952,
    "state_id": "WA"
  },
  {
    "zip": 98340,
    "lat": 47.9023,
    "lng": -122.56348,
    "state_id": "WA"
  },
  {
    "zip": 98342,
    "lat": 47.75387,
    "lng": -122.51789,
    "state_id": "WA"
  },
  {
    "zip": 98345,
    "lat": 47.69921,
    "lng": -122.62354,
    "state_id": "WA"
  },
  {
    "zip": 98346,
    "lat": 47.8185,
    "lng": -122.53234,
    "state_id": "WA"
  },
  {
    "zip": 98349,
    "lat": 47.26622,
    "lng": -122.77272,
    "state_id": "WA"
  },
  {
    "zip": 98350,
    "lat": 47.90532,
    "lng": -124.61399,
    "state_id": "WA"
  },
  {
    "zip": 98351,
    "lat": 47.20301,
    "lng": -122.7687,
    "state_id": "WA"
  },
  {
    "zip": 98353,
    "lat": 47.53885,
    "lng": -122.49294,
    "state_id": "WA"
  },
  {
    "zip": 98354,
    "lat": 47.25027,
    "lng": -122.31469,
    "state_id": "WA"
  },
  {
    "zip": 98355,
    "lat": 46.68689,
    "lng": -122.05559,
    "state_id": "WA"
  },
  {
    "zip": 98356,
    "lat": 46.56342,
    "lng": -122.29219,
    "state_id": "WA"
  },
  {
    "zip": 98357,
    "lat": 48.33199,
    "lng": -124.62847,
    "state_id": "WA"
  },
  {
    "zip": 98358,
    "lat": 48.0557,
    "lng": -122.70761,
    "state_id": "WA"
  },
  {
    "zip": 98359,
    "lat": 47.43045,
    "lng": -122.57661,
    "state_id": "WA"
  },
  {
    "zip": 98360,
    "lat": 47.03026,
    "lng": -122.15928,
    "state_id": "WA"
  },
  {
    "zip": 98361,
    "lat": 46.5888,
    "lng": -121.59131,
    "state_id": "WA"
  },
  {
    "zip": 98362,
    "lat": 47.98419,
    "lng": -123.3616,
    "state_id": "WA"
  },
  {
    "zip": 98363,
    "lat": 48.01057,
    "lng": -123.87548,
    "state_id": "WA"
  },
  {
    "zip": 98364,
    "lat": 47.84697,
    "lng": -122.58839,
    "state_id": "WA"
  },
  {
    "zip": 98365,
    "lat": 47.89513,
    "lng": -122.70283,
    "state_id": "WA"
  },
  {
    "zip": 98366,
    "lat": 47.53716,
    "lng": -122.58649,
    "state_id": "WA"
  },
  {
    "zip": 98367,
    "lat": 47.46508,
    "lng": -122.68313,
    "state_id": "WA"
  },
  {
    "zip": 98368,
    "lat": 48.04272,
    "lng": -122.84534,
    "state_id": "WA"
  },
  {
    "zip": 98370,
    "lat": 47.75101,
    "lng": -122.6229,
    "state_id": "WA"
  },
  {
    "zip": 98371,
    "lat": 47.19956,
    "lng": -122.32219,
    "state_id": "WA"
  },
  {
    "zip": 98372,
    "lat": 47.20602,
    "lng": -122.26691,
    "state_id": "WA"
  },
  {
    "zip": 98373,
    "lat": 47.1476,
    "lng": -122.32519,
    "state_id": "WA"
  },
  {
    "zip": 98374,
    "lat": 47.12994,
    "lng": -122.26196,
    "state_id": "WA"
  },
  {
    "zip": 98375,
    "lat": 47.10367,
    "lng": -122.32346,
    "state_id": "WA"
  },
  {
    "zip": 98376,
    "lat": 47.8512,
    "lng": -122.86207,
    "state_id": "WA"
  },
  {
    "zip": 98377,
    "lat": 46.52518,
    "lng": -121.89481,
    "state_id": "WA"
  },
  {
    "zip": 98380,
    "lat": 47.57555,
    "lng": -122.90221,
    "state_id": "WA"
  },
  {
    "zip": 98381,
    "lat": 48.27373,
    "lng": -124.47476,
    "state_id": "WA"
  },
  {
    "zip": 98382,
    "lat": 48.02747,
    "lng": -123.07863,
    "state_id": "WA"
  },
  {
    "zip": 98383,
    "lat": 47.66207,
    "lng": -122.71793,
    "state_id": "WA"
  },
  {
    "zip": 98385,
    "lat": 47.13755,
    "lng": -122.09186,
    "state_id": "WA"
  },
  {
    "zip": 98387,
    "lat": 47.05699,
    "lng": -122.39543,
    "state_id": "WA"
  },
  {
    "zip": 98388,
    "lat": 47.19891,
    "lng": -122.66275,
    "state_id": "WA"
  },
  {
    "zip": 98390,
    "lat": 47.20999,
    "lng": -122.22801,
    "state_id": "WA"
  },
  {
    "zip": 98391,
    "lat": 47.17717,
    "lng": -122.17228,
    "state_id": "WA"
  },
  {
    "zip": 98392,
    "lat": 47.73296,
    "lng": -122.5629,
    "state_id": "WA"
  },
  {
    "zip": 98394,
    "lat": 47.3191,
    "lng": -122.77665,
    "state_id": "WA"
  },
  {
    "zip": 98396,
    "lat": 47.09851,
    "lng": -122.00986,
    "state_id": "WA"
  },
  {
    "zip": 98402,
    "lat": 47.24859,
    "lng": -122.4387,
    "state_id": "WA"
  },
  {
    "zip": 98403,
    "lat": 47.26595,
    "lng": -122.4585,
    "state_id": "WA"
  },
  {
    "zip": 98404,
    "lat": 47.20975,
    "lng": -122.41008,
    "state_id": "WA"
  },
  {
    "zip": 98405,
    "lat": 47.2458,
    "lng": -122.47201,
    "state_id": "WA"
  },
  {
    "zip": 98406,
    "lat": 47.26211,
    "lng": -122.50898,
    "state_id": "WA"
  },
  {
    "zip": 98407,
    "lat": 47.28861,
    "lng": -122.51131,
    "state_id": "WA"
  },
  {
    "zip": 98408,
    "lat": 47.19802,
    "lng": -122.44647,
    "state_id": "WA"
  },
  {
    "zip": 98409,
    "lat": 47.21115,
    "lng": -122.48203,
    "state_id": "WA"
  },
  {
    "zip": 98416,
    "lat": 47.26258,
    "lng": -122.48128,
    "state_id": "WA"
  },
  {
    "zip": 98418,
    "lat": 47.22323,
    "lng": -122.44647,
    "state_id": "WA"
  },
  {
    "zip": 98421,
    "lat": 47.25917,
    "lng": -122.39953,
    "state_id": "WA"
  },
  {
    "zip": 98422,
    "lat": 47.28962,
    "lng": -122.39171,
    "state_id": "WA"
  },
  {
    "zip": 98424,
    "lat": 47.23435,
    "lng": -122.35193,
    "state_id": "WA"
  },
  {
    "zip": 98430,
    "lat": 47.11732,
    "lng": -122.56915,
    "state_id": "WA"
  },
  {
    "zip": 98433,
    "lat": 47.1113,
    "lng": -122.58723,
    "state_id": "WA"
  },
  {
    "zip": 98438,
    "lat": 47.13163,
    "lng": -122.49706,
    "state_id": "WA"
  },
  {
    "zip": 98439,
    "lat": 47.12388,
    "lng": -122.50169,
    "state_id": "WA"
  },
  {
    "zip": 98443,
    "lat": 47.20462,
    "lng": -122.37412,
    "state_id": "WA"
  },
  {
    "zip": 98444,
    "lat": 47.15285,
    "lng": -122.44899,
    "state_id": "WA"
  },
  {
    "zip": 98445,
    "lat": 47.13981,
    "lng": -122.40989,
    "state_id": "WA"
  },
  {
    "zip": 98446,
    "lat": 47.1297,
    "lng": -122.37423,
    "state_id": "WA"
  },
  {
    "zip": 98447,
    "lat": 47.14429,
    "lng": -122.44348,
    "state_id": "WA"
  },
  {
    "zip": 98465,
    "lat": 47.24823,
    "lng": -122.52906,
    "state_id": "WA"
  },
  {
    "zip": 98466,
    "lat": 47.22778,
    "lng": -122.53573,
    "state_id": "WA"
  },
  {
    "zip": 98467,
    "lat": 47.20405,
    "lng": -122.54641,
    "state_id": "WA"
  },
  {
    "zip": 98498,
    "lat": 47.16231,
    "lng": -122.55452,
    "state_id": "WA"
  },
  {
    "zip": 98499,
    "lat": 47.16671,
    "lng": -122.507,
    "state_id": "WA"
  },
  {
    "zip": 98501,
    "lat": 46.97906,
    "lng": -122.87092,
    "state_id": "WA"
  },
  {
    "zip": 98502,
    "lat": 47.09052,
    "lng": -123.01239,
    "state_id": "WA"
  },
  {
    "zip": 98503,
    "lat": 47.02578,
    "lng": -122.79819,
    "state_id": "WA"
  },
  {
    "zip": 98506,
    "lat": 47.10283,
    "lng": -122.87324,
    "state_id": "WA"
  },
  {
    "zip": 98512,
    "lat": 46.95651,
    "lng": -123.04842,
    "state_id": "WA"
  },
  {
    "zip": 98513,
    "lat": 46.98328,
    "lng": -122.73472,
    "state_id": "WA"
  },
  {
    "zip": 98516,
    "lat": 47.09657,
    "lng": -122.7836,
    "state_id": "WA"
  },
  {
    "zip": 98520,
    "lat": 47.04349,
    "lng": -123.80637,
    "state_id": "WA"
  },
  {
    "zip": 98524,
    "lat": 47.35771,
    "lng": -122.86367,
    "state_id": "WA"
  },
  {
    "zip": 98526,
    "lat": 47.46144,
    "lng": -123.97576,
    "state_id": "WA"
  },
  {
    "zip": 98527,
    "lat": 46.58626,
    "lng": -123.89747,
    "state_id": "WA"
  },
  {
    "zip": 98528,
    "lat": 47.44215,
    "lng": -122.89179,
    "state_id": "WA"
  },
  {
    "zip": 98530,
    "lat": 46.78803,
    "lng": -122.82345,
    "state_id": "WA"
  },
  {
    "zip": 98531,
    "lat": 46.73496,
    "lng": -122.92102,
    "state_id": "WA"
  },
  {
    "zip": 98532,
    "lat": 46.63122,
    "lng": -123.04085,
    "state_id": "WA"
  },
  {
    "zip": 98533,
    "lat": 46.67121,
    "lng": -122.43414,
    "state_id": "WA"
  },
  {
    "zip": 98535,
    "lat": 47.1066,
    "lng": -124.1339,
    "state_id": "WA"
  },
  {
    "zip": 98536,
    "lat": 47.20063,
    "lng": -124.09948,
    "state_id": "WA"
  },
  {
    "zip": 98537,
    "lat": 46.83011,
    "lng": -123.60261,
    "state_id": "WA"
  },
  {
    "zip": 98538,
    "lat": 46.47758,
    "lng": -123.15272,
    "state_id": "WA"
  },
  {
    "zip": 98541,
    "lat": 47.06045,
    "lng": -123.39138,
    "state_id": "WA"
  },
  {
    "zip": 98542,
    "lat": 46.52462,
    "lng": -122.71866,
    "state_id": "WA"
  },
  {
    "zip": 98544,
    "lat": 46.7389,
    "lng": -123.02662,
    "state_id": "WA"
  },
  {
    "zip": 98546,
    "lat": 47.31681,
    "lng": -122.93542,
    "state_id": "WA"
  },
  {
    "zip": 98547,
    "lat": 46.78634,
    "lng": -124.01991,
    "state_id": "WA"
  },
  {
    "zip": 98548,
    "lat": 47.43003,
    "lng": -123.33218,
    "state_id": "WA"
  },
  {
    "zip": 98550,
    "lat": 47.10384,
    "lng": -123.9367,
    "state_id": "WA"
  },
  {
    "zip": 98552,
    "lat": 47.30465,
    "lng": -123.91924,
    "state_id": "WA"
  },
  {
    "zip": 98555,
    "lat": 47.53579,
    "lng": -123.06229,
    "state_id": "WA"
  },
  {
    "zip": 98557,
    "lat": 47.04402,
    "lng": -123.26797,
    "state_id": "WA"
  },
  {
    "zip": 98558,
    "lat": 46.93397,
    "lng": -122.55659,
    "state_id": "WA"
  },
  {
    "zip": 98559,
    "lat": 46.95859,
    "lng": -123.32725,
    "state_id": "WA"
  },
  {
    "zip": 98560,
    "lat": 47.30752,
    "lng": -123.41887,
    "state_id": "WA"
  },
  {
    "zip": 98562,
    "lat": 47.23855,
    "lng": -124.20362,
    "state_id": "WA"
  },
  {
    "zip": 98563,
    "lat": 47.20859,
    "lng": -123.60306,
    "state_id": "WA"
  },
  {
    "zip": 98564,
    "lat": 46.45637,
    "lng": -122.43293,
    "state_id": "WA"
  },
  {
    "zip": 98565,
    "lat": 46.57832,
    "lng": -122.90197,
    "state_id": "WA"
  },
  {
    "zip": 98568,
    "lat": 46.85486,
    "lng": -123.31387,
    "state_id": "WA"
  },
  {
    "zip": 98569,
    "lat": 47.00632,
    "lng": -124.15122,
    "state_id": "WA"
  },
  {
    "zip": 98570,
    "lat": 46.63827,
    "lng": -122.63651,
    "state_id": "WA"
  },
  {
    "zip": 98571,
    "lat": 47.19446,
    "lng": -124.17242,
    "state_id": "WA"
  },
  {
    "zip": 98572,
    "lat": 46.5512,
    "lng": -123.32437,
    "state_id": "WA"
  },
  {
    "zip": 98575,
    "lat": 47.41778,
    "lng": -123.79553,
    "state_id": "WA"
  },
  {
    "zip": 98576,
    "lat": 46.83741,
    "lng": -122.63546,
    "state_id": "WA"
  },
  {
    "zip": 98577,
    "lat": 46.64218,
    "lng": -123.60769,
    "state_id": "WA"
  },
  {
    "zip": 98579,
    "lat": 46.79802,
    "lng": -123.11937,
    "state_id": "WA"
  },
  {
    "zip": 98580,
    "lat": 46.99631,
    "lng": -122.51612,
    "state_id": "WA"
  },
  {
    "zip": 98581,
    "lat": 46.35777,
    "lng": -123.06723,
    "state_id": "WA"
  },
  {
    "zip": 98582,
    "lat": 46.5215,
    "lng": -122.62382,
    "state_id": "WA"
  },
  {
    "zip": 98583,
    "lat": 47.00116,
    "lng": -123.48746,
    "state_id": "WA"
  },
  {
    "zip": 98584,
    "lat": 47.24236,
    "lng": -123.15513,
    "state_id": "WA"
  },
  {
    "zip": 98585,
    "lat": 46.55493,
    "lng": -122.49572,
    "state_id": "WA"
  },
  {
    "zip": 98586,
    "lat": 46.54489,
    "lng": -123.81369,
    "state_id": "WA"
  },
  {
    "zip": 98587,
    "lat": 47.30875,
    "lng": -124.21419,
    "state_id": "WA"
  },
  {
    "zip": 98588,
    "lat": 47.44458,
    "lng": -123.0233,
    "state_id": "WA"
  },
  {
    "zip": 98589,
    "lat": 46.83933,
    "lng": -122.81146,
    "state_id": "WA"
  },
  {
    "zip": 98590,
    "lat": 46.71866,
    "lng": -124.00252,
    "state_id": "WA"
  },
  {
    "zip": 98591,
    "lat": 46.44269,
    "lng": -122.74286,
    "state_id": "WA"
  },
  {
    "zip": 98592,
    "lat": 47.32587,
    "lng": -123.08021,
    "state_id": "WA"
  },
  {
    "zip": 98593,
    "lat": 46.40794,
    "lng": -123.0055,
    "state_id": "WA"
  },
  {
    "zip": 98595,
    "lat": 46.88232,
    "lng": -124.10849,
    "state_id": "WA"
  },
  {
    "zip": 98596,
    "lat": 46.4957,
    "lng": -122.95323,
    "state_id": "WA"
  },
  {
    "zip": 98597,
    "lat": 46.85138,
    "lng": -122.49229,
    "state_id": "WA"
  },
  {
    "zip": 98601,
    "lat": 45.95632,
    "lng": -122.36119,
    "state_id": "WA"
  },
  {
    "zip": 98602,
    "lat": 45.86716,
    "lng": -121.26554,
    "state_id": "WA"
  },
  {
    "zip": 98603,
    "lat": 46.0408,
    "lng": -122.42018,
    "state_id": "WA"
  },
  {
    "zip": 98604,
    "lat": 45.80019,
    "lng": -122.50522,
    "state_id": "WA"
  },
  {
    "zip": 98605,
    "lat": 45.77509,
    "lng": -121.6328,
    "state_id": "WA"
  },
  {
    "zip": 98606,
    "lat": 45.72973,
    "lng": -122.45637,
    "state_id": "WA"
  },
  {
    "zip": 98607,
    "lat": 45.64338,
    "lng": -122.38206,
    "state_id": "WA"
  },
  {
    "zip": 98610,
    "lat": 45.86735,
    "lng": -122.0707,
    "state_id": "WA"
  },
  {
    "zip": 98611,
    "lat": 46.30812,
    "lng": -122.89524,
    "state_id": "WA"
  },
  {
    "zip": 98612,
    "lat": 46.21778,
    "lng": -123.32552,
    "state_id": "WA"
  },
  {
    "zip": 98613,
    "lat": 45.73673,
    "lng": -120.96569,
    "state_id": "WA"
  },
  {
    "zip": 98614,
    "lat": 46.29717,
    "lng": -123.90149,
    "state_id": "WA"
  },
  {
    "zip": 98616,
    "lat": 46.197,
    "lng": -122.14157,
    "state_id": "WA"
  },
  {
    "zip": 98617,
    "lat": 45.64391,
    "lng": -121.15643,
    "state_id": "WA"
  },
  {
    "zip": 98619,
    "lat": 45.97502,
    "lng": -121.27212,
    "state_id": "WA"
  },
  {
    "zip": 98620,
    "lat": 45.87202,
    "lng": -120.77468,
    "state_id": "WA"
  },
  {
    "zip": 98621,
    "lat": 46.37784,
    "lng": -123.54961,
    "state_id": "WA"
  },
  {
    "zip": 98624,
    "lat": 46.36954,
    "lng": -123.9714,
    "state_id": "WA"
  },
  {
    "zip": 98625,
    "lat": 46.04237,
    "lng": -122.72783,
    "state_id": "WA"
  },
  {
    "zip": 98626,
    "lat": 46.15464,
    "lng": -122.78638,
    "state_id": "WA"
  },
  {
    "zip": 98628,
    "lat": 45.85926,
    "lng": -121.09064,
    "state_id": "WA"
  },
  {
    "zip": 98629,
    "lat": 45.88169,
    "lng": -122.61413,
    "state_id": "WA"
  },
  {
    "zip": 98631,
    "lat": 46.38858,
    "lng": -124.03692,
    "state_id": "WA"
  },
  {
    "zip": 98632,
    "lat": 46.21684,
    "lng": -123.06852,
    "state_id": "WA"
  },
  {
    "zip": 98635,
    "lat": 45.74258,
    "lng": -121.20388,
    "state_id": "WA"
  },
  {
    "zip": 98638,
    "lat": 46.38956,
    "lng": -123.74334,
    "state_id": "WA"
  },
  {
    "zip": 98639,
    "lat": 45.66284,
    "lng": -121.98949,
    "state_id": "WA"
  },
  {
    "zip": 98640,
    "lat": 46.54758,
    "lng": -124.04613,
    "state_id": "WA"
  },
  {
    "zip": 98641,
    "lat": 46.54638,
    "lng": -124.02992,
    "state_id": "WA"
  },
  {
    "zip": 98642,
    "lat": 45.80248,
    "lng": -122.70178,
    "state_id": "WA"
  },
  {
    "zip": 98643,
    "lat": 46.30135,
    "lng": -123.61472,
    "state_id": "WA"
  },
  {
    "zip": 98644,
    "lat": 46.32964,
    "lng": -124.05628,
    "state_id": "WA"
  },
  {
    "zip": 98645,
    "lat": 46.32644,
    "lng": -122.78197,
    "state_id": "WA"
  },
  {
    "zip": 98647,
    "lat": 46.31842,
    "lng": -123.43538,
    "state_id": "WA"
  },
  {
    "zip": 98648,
    "lat": 45.70807,
    "lng": -121.95486,
    "state_id": "WA"
  },
  {
    "zip": 98649,
    "lat": 46.31417,
    "lng": -122.53538,
    "state_id": "WA"
  },
  {
    "zip": 98650,
    "lat": 45.97293,
    "lng": -121.52874,
    "state_id": "WA"
  },
  {
    "zip": 98651,
    "lat": 45.74276,
    "lng": -121.584,
    "state_id": "WA"
  },
  {
    "zip": 98660,
    "lat": 45.68772,
    "lng": -122.73187,
    "state_id": "WA"
  },
  {
    "zip": 98661,
    "lat": 45.63918,
    "lng": -122.62575,
    "state_id": "WA"
  },
  {
    "zip": 98662,
    "lat": 45.68851,
    "lng": -122.57784,
    "state_id": "WA"
  },
  {
    "zip": 98663,
    "lat": 45.65399,
    "lng": -122.66253,
    "state_id": "WA"
  },
  {
    "zip": 98664,
    "lat": 45.61981,
    "lng": -122.57752,
    "state_id": "WA"
  },
  {
    "zip": 98665,
    "lat": 45.67974,
    "lng": -122.65942,
    "state_id": "WA"
  },
  {
    "zip": 98670,
    "lat": 45.85885,
    "lng": -121.14091,
    "state_id": "WA"
  },
  {
    "zip": 98671,
    "lat": 45.61329,
    "lng": -122.2422,
    "state_id": "WA"
  },
  {
    "zip": 98672,
    "lat": 45.81843,
    "lng": -121.46297,
    "state_id": "WA"
  },
  {
    "zip": 98673,
    "lat": 45.65212,
    "lng": -121.03609,
    "state_id": "WA"
  },
  {
    "zip": 98674,
    "lat": 45.94333,
    "lng": -122.66499,
    "state_id": "WA"
  },
  {
    "zip": 98675,
    "lat": 45.827,
    "lng": -122.34442,
    "state_id": "WA"
  },
  {
    "zip": 98682,
    "lat": 45.67317,
    "lng": -122.48174,
    "state_id": "WA"
  },
  {
    "zip": 98683,
    "lat": 45.60301,
    "lng": -122.51024,
    "state_id": "WA"
  },
  {
    "zip": 98684,
    "lat": 45.63073,
    "lng": -122.51644,
    "state_id": "WA"
  },
  {
    "zip": 98685,
    "lat": 45.71621,
    "lng": -122.68991,
    "state_id": "WA"
  },
  {
    "zip": 98686,
    "lat": 45.72415,
    "lng": -122.62335,
    "state_id": "WA"
  },
  {
    "zip": 98801,
    "lat": 47.42334,
    "lng": -120.33781,
    "state_id": "WA"
  },
  {
    "zip": 98802,
    "lat": 47.49059,
    "lng": -120.16774,
    "state_id": "WA"
  },
  {
    "zip": 98811,
    "lat": 47.71386,
    "lng": -120.37618,
    "state_id": "WA"
  },
  {
    "zip": 98812,
    "lat": 48.1445,
    "lng": -119.75284,
    "state_id": "WA"
  },
  {
    "zip": 98813,
    "lat": 48.01416,
    "lng": -119.60655,
    "state_id": "WA"
  },
  {
    "zip": 98814,
    "lat": 48.20686,
    "lng": -120.15826,
    "state_id": "WA"
  },
  {
    "zip": 98815,
    "lat": 47.48374,
    "lng": -120.47431,
    "state_id": "WA"
  },
  {
    "zip": 98816,
    "lat": 47.99847,
    "lng": -120.32949,
    "state_id": "WA"
  },
  {
    "zip": 98817,
    "lat": 47.79153,
    "lng": -119.99527,
    "state_id": "WA"
  },
  {
    "zip": 98819,
    "lat": 48.61684,
    "lng": -119.81462,
    "state_id": "WA"
  },
  {
    "zip": 98821,
    "lat": 47.55177,
    "lng": -120.55942,
    "state_id": "WA"
  },
  {
    "zip": 98822,
    "lat": 47.86643,
    "lng": -120.47563,
    "state_id": "WA"
  },
  {
    "zip": 98823,
    "lat": 47.32197,
    "lng": -119.63358,
    "state_id": "WA"
  },
  {
    "zip": 98824,
    "lat": 47.07784,
    "lng": -119.85868,
    "state_id": "WA"
  },
  {
    "zip": 98826,
    "lat": 47.7875,
    "lng": -120.85453,
    "state_id": "WA"
  },
  {
    "zip": 98827,
    "lat": 48.86486,
    "lng": -119.76696,
    "state_id": "WA"
  },
  {
    "zip": 98828,
    "lat": 47.30561,
    "lng": -120.17619,
    "state_id": "WA"
  },
  {
    "zip": 98829,
    "lat": 48.31242,
    "lng": -119.80894,
    "state_id": "WA"
  },
  {
    "zip": 98830,
    "lat": 47.89518,
    "lng": -119.51453,
    "state_id": "WA"
  },
  {
    "zip": 98831,
    "lat": 47.9346,
    "lng": -120.1301,
    "state_id": "WA"
  },
  {
    "zip": 98832,
    "lat": 47.37195,
    "lng": -119.01776,
    "state_id": "WA"
  },
  {
    "zip": 98833,
    "lat": 48.68821,
    "lng": -120.61132,
    "state_id": "WA"
  },
  {
    "zip": 98834,
    "lat": 48.11708,
    "lng": -120.08911,
    "state_id": "WA"
  },
  {
    "zip": 98836,
    "lat": 47.47526,
    "lng": -120.42756,
    "state_id": "WA"
  },
  {
    "zip": 98837,
    "lat": 47.14978,
    "lng": -119.30455,
    "state_id": "WA"
  },
  {
    "zip": 98840,
    "lat": 48.31467,
    "lng": -119.56659,
    "state_id": "WA"
  },
  {
    "zip": 98841,
    "lat": 48.35883,
    "lng": -119.26948,
    "state_id": "WA"
  },
  {
    "zip": 98843,
    "lat": 47.75201,
    "lng": -120.05985,
    "state_id": "WA"
  },
  {
    "zip": 98844,
    "lat": 48.91594,
    "lng": -119.2444,
    "state_id": "WA"
  },
  {
    "zip": 98845,
    "lat": 47.41839,
    "lng": -119.92731,
    "state_id": "WA"
  },
  {
    "zip": 98846,
    "lat": 48.03405,
    "lng": -119.97863,
    "state_id": "WA"
  },
  {
    "zip": 98847,
    "lat": 47.44055,
    "lng": -120.64667,
    "state_id": "WA"
  },
  {
    "zip": 98848,
    "lat": 47.17238,
    "lng": -119.8433,
    "state_id": "WA"
  },
  {
    "zip": 98849,
    "lat": 48.53819,
    "lng": -119.40391,
    "state_id": "WA"
  },
  {
    "zip": 98850,
    "lat": 47.30764,
    "lng": -120.07758,
    "state_id": "WA"
  },
  {
    "zip": 98851,
    "lat": 47.42069,
    "lng": -119.42514,
    "state_id": "WA"
  },
  {
    "zip": 98852,
    "lat": 48.25281,
    "lng": -120.55118,
    "state_id": "WA"
  },
  {
    "zip": 98853,
    "lat": 47.46201,
    "lng": -119.26036,
    "state_id": "WA"
  },
  {
    "zip": 98855,
    "lat": 48.67535,
    "lng": -119.27048,
    "state_id": "WA"
  },
  {
    "zip": 98856,
    "lat": 48.37294,
    "lng": -120.25319,
    "state_id": "WA"
  },
  {
    "zip": 98857,
    "lat": 46.98565,
    "lng": -119.05068,
    "state_id": "WA"
  },
  {
    "zip": 98858,
    "lat": 47.64655,
    "lng": -119.90883,
    "state_id": "WA"
  },
  {
    "zip": 98859,
    "lat": 48.80241,
    "lng": -118.91408,
    "state_id": "WA"
  },
  {
    "zip": 98860,
    "lat": 47.46881,
    "lng": -119.16396,
    "state_id": "WA"
  },
  {
    "zip": 98862,
    "lat": 48.58164,
    "lng": -120.28825,
    "state_id": "WA"
  },
  {
    "zip": 98901,
    "lat": 46.69319,
    "lng": -120.41528,
    "state_id": "WA"
  },
  {
    "zip": 98902,
    "lat": 46.59668,
    "lng": -120.53375,
    "state_id": "WA"
  },
  {
    "zip": 98903,
    "lat": 46.53886,
    "lng": -121.02662,
    "state_id": "WA"
  },
  {
    "zip": 98908,
    "lat": 46.61658,
    "lng": -120.70952,
    "state_id": "WA"
  },
  {
    "zip": 98921,
    "lat": 46.43107,
    "lng": -120.31878,
    "state_id": "WA"
  },
  {
    "zip": 98922,
    "lat": 47.26931,
    "lng": -120.88143,
    "state_id": "WA"
  },
  {
    "zip": 98923,
    "lat": 46.66635,
    "lng": -120.71772,
    "state_id": "WA"
  },
  {
    "zip": 98925,
    "lat": 47.25806,
    "lng": -121.28081,
    "state_id": "WA"
  },
  {
    "zip": 98926,
    "lat": 47.01293,
    "lng": -120.46296,
    "state_id": "WA"
  },
  {
    "zip": 98930,
    "lat": 46.27048,
    "lng": -119.89355,
    "state_id": "WA"
  },
  {
    "zip": 98932,
    "lat": 46.33242,
    "lng": -120.16117,
    "state_id": "WA"
  },
  {
    "zip": 98933,
    "lat": 46.40223,
    "lng": -120.63124,
    "state_id": "WA"
  },
  {
    "zip": 98934,
    "lat": 46.98179,
    "lng": -120.41493,
    "state_id": "WA"
  },
  {
    "zip": 98935,
    "lat": 46.1314,
    "lng": -120.08637,
    "state_id": "WA"
  },
  {
    "zip": 98936,
    "lat": 46.53598,
    "lng": -120.14407,
    "state_id": "WA"
  },
  {
    "zip": 98937,
    "lat": 46.808,
    "lng": -121.16104,
    "state_id": "WA"
  },
  {
    "zip": 98938,
    "lat": 46.38829,
    "lng": -120.09413,
    "state_id": "WA"
  },
  {
    "zip": 98939,
    "lat": 46.49687,
    "lng": -120.46581,
    "state_id": "WA"
  },
  {
    "zip": 98940,
    "lat": 47.40476,
    "lng": -121.07325,
    "state_id": "WA"
  },
  {
    "zip": 98941,
    "lat": 47.22328,
    "lng": -120.98209,
    "state_id": "WA"
  },
  {
    "zip": 98942,
    "lat": 46.80184,
    "lng": -120.67505,
    "state_id": "WA"
  },
  {
    "zip": 98943,
    "lat": 47.18562,
    "lng": -120.95846,
    "state_id": "WA"
  },
  {
    "zip": 98944,
    "lat": 46.49322,
    "lng": -119.64732,
    "state_id": "WA"
  },
  {
    "zip": 98946,
    "lat": 47.07912,
    "lng": -120.72166,
    "state_id": "WA"
  },
  {
    "zip": 98947,
    "lat": 46.68755,
    "lng": -120.80146,
    "state_id": "WA"
  },
  {
    "zip": 98948,
    "lat": 46.28525,
    "lng": -120.32839,
    "state_id": "WA"
  },
  {
    "zip": 98950,
    "lat": 46.89747,
    "lng": -119.99058,
    "state_id": "WA"
  },
  {
    "zip": 98951,
    "lat": 46.45977,
    "lng": -120.51617,
    "state_id": "WA"
  },
  {
    "zip": 98952,
    "lat": 46.35435,
    "lng": -120.76141,
    "state_id": "WA"
  },
  {
    "zip": 98953,
    "lat": 46.44227,
    "lng": -120.22679,
    "state_id": "WA"
  },
  {
    "zip": 99001,
    "lat": 47.63652,
    "lng": -117.58632,
    "state_id": "WA"
  },
  {
    "zip": 99003,
    "lat": 47.91839,
    "lng": -117.28467,
    "state_id": "WA"
  },
  {
    "zip": 99004,
    "lat": 47.41112,
    "lng": -117.63773,
    "state_id": "WA"
  },
  {
    "zip": 99005,
    "lat": 47.84425,
    "lng": -117.36623,
    "state_id": "WA"
  },
  {
    "zip": 99006,
    "lat": 47.98298,
    "lng": -117.50225,
    "state_id": "WA"
  },
  {
    "zip": 99008,
    "lat": 47.50805,
    "lng": -117.92447,
    "state_id": "WA"
  },
  {
    "zip": 99009,
    "lat": 48.01865,
    "lng": -117.2544,
    "state_id": "WA"
  },
  {
    "zip": 99011,
    "lat": 47.61883,
    "lng": -117.64812,
    "state_id": "WA"
  },
  {
    "zip": 99012,
    "lat": 47.38209,
    "lng": -117.19286,
    "state_id": "WA"
  },
  {
    "zip": 99013,
    "lat": 47.89239,
    "lng": -117.83445,
    "state_id": "WA"
  },
  {
    "zip": 99016,
    "lat": 47.62116,
    "lng": -117.14626,
    "state_id": "WA"
  },
  {
    "zip": 99017,
    "lat": 47.18881,
    "lng": -117.8756,
    "state_id": "WA"
  },
  {
    "zip": 99018,
    "lat": 47.28979,
    "lng": -117.15487,
    "state_id": "WA"
  },
  {
    "zip": 99019,
    "lat": 47.64281,
    "lng": -117.07648,
    "state_id": "WA"
  },
  {
    "zip": 99020,
    "lat": 47.56242,
    "lng": -117.49663,
    "state_id": "WA"
  },
  {
    "zip": 99021,
    "lat": 47.84824,
    "lng": -117.18953,
    "state_id": "WA"
  },
  {
    "zip": 99022,
    "lat": 47.60157,
    "lng": -117.71132,
    "state_id": "WA"
  },
  {
    "zip": 99023,
    "lat": 47.56109,
    "lng": -117.17176,
    "state_id": "WA"
  },
  {
    "zip": 99025,
    "lat": 47.78991,
    "lng": -117.08596,
    "state_id": "WA"
  },
  {
    "zip": 99026,
    "lat": 47.81146,
    "lng": -117.62775,
    "state_id": "WA"
  },
  {
    "zip": 99027,
    "lat": 47.71042,
    "lng": -117.12622,
    "state_id": "WA"
  },
  {
    "zip": 99029,
    "lat": 47.69765,
    "lng": -117.82934,
    "state_id": "WA"
  },
  {
    "zip": 99030,
    "lat": 47.4841,
    "lng": -117.11023,
    "state_id": "WA"
  },
  {
    "zip": 99031,
    "lat": 47.42351,
    "lng": -117.36247,
    "state_id": "WA"
  },
  {
    "zip": 99032,
    "lat": 47.31739,
    "lng": -118.02805,
    "state_id": "WA"
  },
  {
    "zip": 99033,
    "lat": 47.23237,
    "lng": -117.09927,
    "state_id": "WA"
  },
  {
    "zip": 99034,
    "lat": 47.88562,
    "lng": -117.74864,
    "state_id": "WA"
  },
  {
    "zip": 99036,
    "lat": 47.52532,
    "lng": -117.27588,
    "state_id": "WA"
  },
  {
    "zip": 99037,
    "lat": 47.6364,
    "lng": -117.19718,
    "state_id": "WA"
  },
  {
    "zip": 99039,
    "lat": 47.34466,
    "lng": -117.2656,
    "state_id": "WA"
  },
  {
    "zip": 99040,
    "lat": 47.90086,
    "lng": -118.00526,
    "state_id": "WA"
  },
  {
    "zip": 99101,
    "lat": 48.32729,
    "lng": -117.92514,
    "state_id": "WA"
  },
  {
    "zip": 99102,
    "lat": 46.79212,
    "lng": -117.2501,
    "state_id": "WA"
  },
  {
    "zip": 99103,
    "lat": 47.73715,
    "lng": -118.93697,
    "state_id": "WA"
  },
  {
    "zip": 99105,
    "lat": 46.9034,
    "lng": -118.10477,
    "state_id": "WA"
  },
  {
    "zip": 99109,
    "lat": 48.28532,
    "lng": -117.70135,
    "state_id": "WA"
  },
  {
    "zip": 99110,
    "lat": 48.01215,
    "lng": -117.57636,
    "state_id": "WA"
  },
  {
    "zip": 99111,
    "lat": 46.85372,
    "lng": -117.42452,
    "state_id": "WA"
  },
  {
    "zip": 99113,
    "lat": 46.59412,
    "lng": -117.19146,
    "state_id": "WA"
  },
  {
    "zip": 99114,
    "lat": 48.65406,
    "lng": -117.7396,
    "state_id": "WA"
  },
  {
    "zip": 99115,
    "lat": 47.64718,
    "lng": -119.42387,
    "state_id": "WA"
  },
  {
    "zip": 99116,
    "lat": 48.01601,
    "lng": -118.92858,
    "state_id": "WA"
  },
  {
    "zip": 99117,
    "lat": 47.72402,
    "lng": -118.5208,
    "state_id": "WA"
  },
  {
    "zip": 99118,
    "lat": 48.89659,
    "lng": -118.639,
    "state_id": "WA"
  },
  {
    "zip": 99119,
    "lat": 48.5039,
    "lng": -117.28854,
    "state_id": "WA"
  },
  {
    "zip": 99121,
    "lat": 48.94794,
    "lng": -118.46514,
    "state_id": "WA"
  },
  {
    "zip": 99122,
    "lat": 47.67761,
    "lng": -118.20799,
    "state_id": "WA"
  },
  {
    "zip": 99123,
    "lat": 47.84593,
    "lng": -119.14289,
    "state_id": "WA"
  },
  {
    "zip": 99124,
    "lat": 47.9999,
    "lng": -118.95282,
    "state_id": "WA"
  },
  {
    "zip": 99125,
    "lat": 46.94299,
    "lng": -117.77858,
    "state_id": "WA"
  },
  {
    "zip": 99126,
    "lat": 48.73963,
    "lng": -117.97196,
    "state_id": "WA"
  },
  {
    "zip": 99128,
    "lat": 47.09934,
    "lng": -117.07292,
    "state_id": "WA"
  },
  {
    "zip": 99129,
    "lat": 48.00522,
    "lng": -118.1927,
    "state_id": "WA"
  },
  {
    "zip": 99130,
    "lat": 46.99731,
    "lng": -117.18411,
    "state_id": "WA"
  },
  {
    "zip": 99131,
    "lat": 48.27909,
    "lng": -118.12317,
    "state_id": "WA"
  },
  {
    "zip": 99133,
    "lat": 48.01489,
    "lng": -119.07685,
    "state_id": "WA"
  },
  {
    "zip": 99134,
    "lat": 47.4301,
    "lng": -118.31124,
    "state_id": "WA"
  },
  {
    "zip": 99135,
    "lat": 47.66121,
    "lng": -119.09739,
    "state_id": "WA"
  },
  {
    "zip": 99136,
    "lat": 46.67473,
    "lng": -117.95542,
    "state_id": "WA"
  },
  {
    "zip": 99137,
    "lat": 48.16826,
    "lng": -118.09022,
    "state_id": "WA"
  },
  {
    "zip": 99138,
    "lat": 48.22236,
    "lng": -118.37874,
    "state_id": "WA"
  },
  {
    "zip": 99139,
    "lat": 48.7346,
    "lng": -117.40668,
    "state_id": "WA"
  },
  {
    "zip": 99140,
    "lat": 48.11156,
    "lng": -118.67673,
    "state_id": "WA"
  },
  {
    "zip": 99141,
    "lat": 48.73768,
    "lng": -118.14451,
    "state_id": "WA"
  },
  {
    "zip": 99143,
    "lat": 46.73947,
    "lng": -117.85625,
    "state_id": "WA"
  },
  {
    "zip": 99144,
    "lat": 47.3777,
    "lng": -118.50423,
    "state_id": "WA"
  },
  {
    "zip": 99146,
    "lat": 48.9576,
    "lng": -118.29754,
    "state_id": "WA"
  },
  {
    "zip": 99147,
    "lat": 47.8395,
    "lng": -118.44258,
    "state_id": "WA"
  },
  {
    "zip": 99148,
    "lat": 48.10251,
    "lng": -117.59825,
    "state_id": "WA"
  },
  {
    "zip": 99149,
    "lat": 47.21719,
    "lng": -117.46406,
    "state_id": "WA"
  },
  {
    "zip": 99150,
    "lat": 48.79435,
    "lng": -118.58291,
    "state_id": "WA"
  },
  {
    "zip": 99151,
    "lat": 48.66449,
    "lng": -118.06362,
    "state_id": "WA"
  },
  {
    "zip": 99152,
    "lat": 48.84959,
    "lng": -117.39626,
    "state_id": "WA"
  },
  {
    "zip": 99153,
    "lat": 48.8515,
    "lng": -117.2127,
    "state_id": "WA"
  },
  {
    "zip": 99154,
    "lat": 47.38973,
    "lng": -118.36596,
    "state_id": "WA"
  },
  {
    "zip": 99155,
    "lat": 48.25567,
    "lng": -118.95348,
    "state_id": "WA"
  },
  {
    "zip": 99156,
    "lat": 48.1848,
    "lng": -117.19075,
    "state_id": "WA"
  },
  {
    "zip": 99157,
    "lat": 48.92469,
    "lng": -117.81576,
    "state_id": "WA"
  },
  {
    "zip": 99158,
    "lat": 47.13032,
    "lng": -117.24738,
    "state_id": "WA"
  },
  {
    "zip": 99159,
    "lat": 47.32943,
    "lng": -118.74247,
    "state_id": "WA"
  },
  {
    "zip": 99160,
    "lat": 48.83875,
    "lng": -118.3063,
    "state_id": "WA"
  },
  {
    "zip": 99161,
    "lat": 46.8982,
    "lng": -117.13047,
    "state_id": "WA"
  },
  {
    "zip": 99163,
    "lat": 46.72893,
    "lng": -117.21485,
    "state_id": "WA"
  },
  {
    "zip": 99164,
    "lat": 46.73104,
    "lng": -117.1522,
    "state_id": "WA"
  },
  {
    "zip": 99166,
    "lat": 48.5717,
    "lng": -118.64454,
    "state_id": "WA"
  },
  {
    "zip": 99167,
    "lat": 48.41723,
    "lng": -118.11899,
    "state_id": "WA"
  },
  {
    "zip": 99169,
    "lat": 47.112,
    "lng": -118.35868,
    "state_id": "WA"
  },
  {
    "zip": 99170,
    "lat": 47.24762,
    "lng": -117.4022,
    "state_id": "WA"
  },
  {
    "zip": 99171,
    "lat": 47.10444,
    "lng": -117.65624,
    "state_id": "WA"
  },
  {
    "zip": 99173,
    "lat": 48.03696,
    "lng": -117.86312,
    "state_id": "WA"
  },
  {
    "zip": 99174,
    "lat": 47.01064,
    "lng": -117.3588,
    "state_id": "WA"
  },
  {
    "zip": 99176,
    "lat": 47.08093,
    "lng": -117.42387,
    "state_id": "WA"
  },
  {
    "zip": 99179,
    "lat": 46.49006,
    "lng": -117.11898,
    "state_id": "WA"
  },
  {
    "zip": 99180,
    "lat": 48.29579,
    "lng": -117.3285,
    "state_id": "WA"
  },
  {
    "zip": 99181,
    "lat": 48.14329,
    "lng": -117.76926,
    "state_id": "WA"
  },
  {
    "zip": 99185,
    "lat": 47.74592,
    "lng": -118.71951,
    "state_id": "WA"
  },
  {
    "zip": 99201,
    "lat": 47.66272,
    "lng": -117.43614,
    "state_id": "WA"
  },
  {
    "zip": 99202,
    "lat": 47.65743,
    "lng": -117.37908,
    "state_id": "WA"
  },
  {
    "zip": 99203,
    "lat": 47.6291,
    "lng": -117.40327,
    "state_id": "WA"
  },
  {
    "zip": 99204,
    "lat": 47.64564,
    "lng": -117.42712,
    "state_id": "WA"
  },
  {
    "zip": 99205,
    "lat": 47.69665,
    "lng": -117.44622,
    "state_id": "WA"
  },
  {
    "zip": 99206,
    "lat": 47.62899,
    "lng": -117.25487,
    "state_id": "WA"
  },
  {
    "zip": 99207,
    "lat": 47.68889,
    "lng": -117.38782,
    "state_id": "WA"
  },
  {
    "zip": 99208,
    "lat": 47.77989,
    "lng": -117.46275,
    "state_id": "WA"
  },
  {
    "zip": 99212,
    "lat": 47.66523,
    "lng": -117.30916,
    "state_id": "WA"
  },
  {
    "zip": 99216,
    "lat": 47.67999,
    "lng": -117.20944,
    "state_id": "WA"
  },
  {
    "zip": 99217,
    "lat": 47.73991,
    "lng": -117.2568,
    "state_id": "WA"
  },
  {
    "zip": 99218,
    "lat": 47.75954,
    "lng": -117.40845,
    "state_id": "WA"
  },
  {
    "zip": 99223,
    "lat": 47.59132,
    "lng": -117.34474,
    "state_id": "WA"
  },
  {
    "zip": 99224,
    "lat": 47.64881,
    "lng": -117.53682,
    "state_id": "WA"
  },
  {
    "zip": 99301,
    "lat": 46.38449,
    "lng": -118.94392,
    "state_id": "WA"
  },
  {
    "zip": 99320,
    "lat": 46.26522,
    "lng": -119.48767,
    "state_id": "WA"
  },
  {
    "zip": 99321,
    "lat": 46.87464,
    "lng": -119.89279,
    "state_id": "WA"
  },
  {
    "zip": 99322,
    "lat": 45.94405,
    "lng": -120.18078,
    "state_id": "WA"
  },
  {
    "zip": 99323,
    "lat": 46.19237,
    "lng": -118.84285,
    "state_id": "WA"
  },
  {
    "zip": 99324,
    "lat": 46.04264,
    "lng": -118.38662,
    "state_id": "WA"
  },
  {
    "zip": 99326,
    "lat": 46.64977,
    "lng": -118.8522,
    "state_id": "WA"
  },
  {
    "zip": 99328,
    "lat": 46.25661,
    "lng": -117.86548,
    "state_id": "WA"
  },
  {
    "zip": 99329,
    "lat": 46.15636,
    "lng": -118.14531,
    "state_id": "WA"
  },
  {
    "zip": 99330,
    "lat": 46.48982,
    "lng": -119.04317,
    "state_id": "WA"
  },
  {
    "zip": 99333,
    "lat": 46.69266,
    "lng": -118.15021,
    "state_id": "WA"
  },
  {
    "zip": 99335,
    "lat": 46.65368,
    "lng": -118.47453,
    "state_id": "WA"
  },
  {
    "zip": 99336,
    "lat": 46.21378,
    "lng": -119.17886,
    "state_id": "WA"
  },
  {
    "zip": 99337,
    "lat": 46.07461,
    "lng": -119.08614,
    "state_id": "WA"
  },
  {
    "zip": 99338,
    "lat": 46.14795,
    "lng": -119.27355,
    "state_id": "WA"
  },
  {
    "zip": 99341,
    "lat": 46.91289,
    "lng": -118.65311,
    "state_id": "WA"
  },
  {
    "zip": 99343,
    "lat": 46.59183,
    "lng": -119.17937,
    "state_id": "WA"
  },
  {
    "zip": 99344,
    "lat": 46.81646,
    "lng": -119.17787,
    "state_id": "WA"
  },
  {
    "zip": 99345,
    "lat": 45.95266,
    "lng": -119.68965,
    "state_id": "WA"
  },
  {
    "zip": 99346,
    "lat": 45.99829,
    "lng": -119.30493,
    "state_id": "WA"
  },
  {
    "zip": 99347,
    "lat": 46.4278,
    "lng": -117.56644,
    "state_id": "WA"
  },
  {
    "zip": 99348,
    "lat": 46.38968,
    "lng": -118.45178,
    "state_id": "WA"
  },
  {
    "zip": 99349,
    "lat": 46.73155,
    "lng": -119.70123,
    "state_id": "WA"
  },
  {
    "zip": 99350,
    "lat": 46.18027,
    "lng": -119.70516,
    "state_id": "WA"
  },
  {
    "zip": 99352,
    "lat": 46.25225,
    "lng": -119.28785,
    "state_id": "WA"
  },
  {
    "zip": 99353,
    "lat": 46.3167,
    "lng": -119.37926,
    "state_id": "WA"
  },
  {
    "zip": 99354,
    "lat": 46.32566,
    "lng": -119.3067,
    "state_id": "WA"
  },
  {
    "zip": 99356,
    "lat": 45.82125,
    "lng": -120.31507,
    "state_id": "WA"
  },
  {
    "zip": 99357,
    "lat": 46.89138,
    "lng": -119.66058,
    "state_id": "WA"
  },
  {
    "zip": 99359,
    "lat": 46.52731,
    "lng": -118.11115,
    "state_id": "WA"
  },
  {
    "zip": 99360,
    "lat": 46.09016,
    "lng": -118.66296,
    "state_id": "WA"
  },
  {
    "zip": 99361,
    "lat": 46.23673,
    "lng": -118.1439,
    "state_id": "WA"
  },
  {
    "zip": 99362,
    "lat": 46.08931,
    "lng": -118.30738,
    "state_id": "WA"
  },
  {
    "zip": 99363,
    "lat": 46.06652,
    "lng": -118.88846,
    "state_id": "WA"
  },
  {
    "zip": 99371,
    "lat": 46.80678,
    "lng": -118.31679,
    "state_id": "WA"
  },
  {
    "zip": 99401,
    "lat": 46.08744,
    "lng": -117.25143,
    "state_id": "WA"
  },
  {
    "zip": 99402,
    "lat": 46.19394,
    "lng": -117.14736,
    "state_id": "WA"
  },
  {
    "zip": 99403,
    "lat": 46.37243,
    "lng": -117.25273,
    "state_id": "WA"
  },
  {
    "zip": 53001,
    "lat": 43.60613,
    "lng": -88.05516,
    "state_id": "WI"
  },
  {
    "zip": 53002,
    "lat": 43.47014,
    "lng": -88.35696,
    "state_id": "WI"
  },
  {
    "zip": 53003,
    "lat": 43.20657,
    "lng": -88.50973,
    "state_id": "WI"
  },
  {
    "zip": 53004,
    "lat": 43.49997,
    "lng": -87.88005,
    "state_id": "WI"
  },
  {
    "zip": 53005,
    "lat": 43.06261,
    "lng": -88.09925,
    "state_id": "WI"
  },
  {
    "zip": 53006,
    "lat": 43.62009,
    "lng": -88.53642,
    "state_id": "WI"
  },
  {
    "zip": 53007,
    "lat": 43.10842,
    "lng": -88.07118,
    "state_id": "WI"
  },
  {
    "zip": 53010,
    "lat": 43.60987,
    "lng": -88.27706,
    "state_id": "WI"
  },
  {
    "zip": 53011,
    "lat": 43.65816,
    "lng": -88.07891,
    "state_id": "WI"
  },
  {
    "zip": 53012,
    "lat": 43.3147,
    "lng": -88.03769,
    "state_id": "WI"
  },
  {
    "zip": 53013,
    "lat": 43.56705,
    "lng": -87.85218,
    "state_id": "WI"
  },
  {
    "zip": 53014,
    "lat": 44.02436,
    "lng": -88.175,
    "state_id": "WI"
  },
  {
    "zip": 53015,
    "lat": 43.90617,
    "lng": -87.7918,
    "state_id": "WI"
  },
  {
    "zip": 53016,
    "lat": 43.31069,
    "lng": -88.71438,
    "state_id": "WI"
  },
  {
    "zip": 53017,
    "lat": 43.20382,
    "lng": -88.25538,
    "state_id": "WI"
  },
  {
    "zip": 53018,
    "lat": 43.0471,
    "lng": -88.39209,
    "state_id": "WI"
  },
  {
    "zip": 53019,
    "lat": 43.69408,
    "lng": -88.31255,
    "state_id": "WI"
  },
  {
    "zip": 53020,
    "lat": 43.86343,
    "lng": -88.01051,
    "state_id": "WI"
  },
  {
    "zip": 53021,
    "lat": 43.49465,
    "lng": -88.00158,
    "state_id": "WI"
  },
  {
    "zip": 53022,
    "lat": 43.23067,
    "lng": -88.11333,
    "state_id": "WI"
  },
  {
    "zip": 53023,
    "lat": 43.78226,
    "lng": -88.10641,
    "state_id": "WI"
  },
  {
    "zip": 53024,
    "lat": 43.33196,
    "lng": -87.93311,
    "state_id": "WI"
  },
  {
    "zip": 53027,
    "lat": 43.31795,
    "lng": -88.37298,
    "state_id": "WI"
  },
  {
    "zip": 53029,
    "lat": 43.14521,
    "lng": -88.34689,
    "state_id": "WI"
  },
  {
    "zip": 53031,
    "lat": 43.63914,
    "lng": -87.91563,
    "state_id": "WI"
  },
  {
    "zip": 53032,
    "lat": 43.45044,
    "lng": -88.62616,
    "state_id": "WI"
  },
  {
    "zip": 53033,
    "lat": 43.23611,
    "lng": -88.25567,
    "state_id": "WI"
  },
  {
    "zip": 53034,
    "lat": 43.34065,
    "lng": -88.60911,
    "state_id": "WI"
  },
  {
    "zip": 53035,
    "lat": 43.39214,
    "lng": -88.54509,
    "state_id": "WI"
  },
  {
    "zip": 53036,
    "lat": 43.17006,
    "lng": -88.57682,
    "state_id": "WI"
  },
  {
    "zip": 53037,
    "lat": 43.31225,
    "lng": -88.16245,
    "state_id": "WI"
  },
  {
    "zip": 53038,
    "lat": 43.08335,
    "lng": -88.78702,
    "state_id": "WI"
  },
  {
    "zip": 53039,
    "lat": 43.37002,
    "lng": -88.7129,
    "state_id": "WI"
  },
  {
    "zip": 53040,
    "lat": 43.5208,
    "lng": -88.19076,
    "state_id": "WI"
  },
  {
    "zip": 53042,
    "lat": 43.95138,
    "lng": -87.98168,
    "state_id": "WI"
  },
  {
    "zip": 53044,
    "lat": 43.74238,
    "lng": -87.78231,
    "state_id": "WI"
  },
  {
    "zip": 53045,
    "lat": 43.06091,
    "lng": -88.15149,
    "state_id": "WI"
  },
  {
    "zip": 53046,
    "lat": 43.15219,
    "lng": -88.16174,
    "state_id": "WI"
  },
  {
    "zip": 53047,
    "lat": 43.25715,
    "lng": -88.63018,
    "state_id": "WI"
  },
  {
    "zip": 53048,
    "lat": 43.57945,
    "lng": -88.45134,
    "state_id": "WI"
  },
  {
    "zip": 53049,
    "lat": 43.88533,
    "lng": -88.28656,
    "state_id": "WI"
  },
  {
    "zip": 53050,
    "lat": 43.51167,
    "lng": -88.54775,
    "state_id": "WI"
  },
  {
    "zip": 53051,
    "lat": 43.14878,
    "lng": -88.12274,
    "state_id": "WI"
  },
  {
    "zip": 53057,
    "lat": 43.78927,
    "lng": -88.24517,
    "state_id": "WI"
  },
  {
    "zip": 53058,
    "lat": 43.10999,
    "lng": -88.4035,
    "state_id": "WI"
  },
  {
    "zip": 53059,
    "lat": 43.28863,
    "lng": -88.52972,
    "state_id": "WI"
  },
  {
    "zip": 53061,
    "lat": 43.94409,
    "lng": -88.12167,
    "state_id": "WI"
  },
  {
    "zip": 53063,
    "lat": 43.96272,
    "lng": -87.80144,
    "state_id": "WI"
  },
  {
    "zip": 53065,
    "lat": 43.68397,
    "lng": -88.56886,
    "state_id": "WI"
  },
  {
    "zip": 53066,
    "lat": 43.11314,
    "lng": -88.48992,
    "state_id": "WI"
  },
  {
    "zip": 53069,
    "lat": 43.11378,
    "lng": -88.43298,
    "state_id": "WI"
  },
  {
    "zip": 53070,
    "lat": 43.62349,
    "lng": -87.80988,
    "state_id": "WI"
  },
  {
    "zip": 53072,
    "lat": 43.08113,
    "lng": -88.26664,
    "state_id": "WI"
  },
  {
    "zip": 53073,
    "lat": 43.75211,
    "lng": -87.98876,
    "state_id": "WI"
  },
  {
    "zip": 53074,
    "lat": 43.42621,
    "lng": -87.88359,
    "state_id": "WI"
  },
  {
    "zip": 53075,
    "lat": 43.56635,
    "lng": -87.99129,
    "state_id": "WI"
  },
  {
    "zip": 53076,
    "lat": 43.2684,
    "lng": -88.20707,
    "state_id": "WI"
  },
  {
    "zip": 53078,
    "lat": 43.31933,
    "lng": -88.4601,
    "state_id": "WI"
  },
  {
    "zip": 53079,
    "lat": 43.80915,
    "lng": -88.18454,
    "state_id": "WI"
  },
  {
    "zip": 53080,
    "lat": 43.40951,
    "lng": -87.98748,
    "state_id": "WI"
  },
  {
    "zip": 53081,
    "lat": 43.71276,
    "lng": -87.74032,
    "state_id": "WI"
  },
  {
    "zip": 53083,
    "lat": 43.8164,
    "lng": -87.77057,
    "state_id": "WI"
  },
  {
    "zip": 53085,
    "lat": 43.73592,
    "lng": -87.85119,
    "state_id": "WI"
  },
  {
    "zip": 53086,
    "lat": 43.32336,
    "lng": -88.27199,
    "state_id": "WI"
  },
  {
    "zip": 53088,
    "lat": 44.07003,
    "lng": -88.30063,
    "state_id": "WI"
  },
  {
    "zip": 53089,
    "lat": 43.1472,
    "lng": -88.23476,
    "state_id": "WI"
  },
  {
    "zip": 53090,
    "lat": 43.45568,
    "lng": -88.18518,
    "state_id": "WI"
  },
  {
    "zip": 53091,
    "lat": 43.49609,
    "lng": -88.43664,
    "state_id": "WI"
  },
  {
    "zip": 53092,
    "lat": 43.22139,
    "lng": -87.95366,
    "state_id": "WI"
  },
  {
    "zip": 53093,
    "lat": 43.65997,
    "lng": -87.94223,
    "state_id": "WI"
  },
  {
    "zip": 53094,
    "lat": 43.14566,
    "lng": -88.73039,
    "state_id": "WI"
  },
  {
    "zip": 53095,
    "lat": 43.38903,
    "lng": -88.15788,
    "state_id": "WI"
  },
  {
    "zip": 53097,
    "lat": 43.24581,
    "lng": -88.0067,
    "state_id": "WI"
  },
  {
    "zip": 53098,
    "lat": 43.24963,
    "lng": -88.71305,
    "state_id": "WI"
  },
  {
    "zip": 53103,
    "lat": 42.88164,
    "lng": -88.21375,
    "state_id": "WI"
  },
  {
    "zip": 53104,
    "lat": 42.55728,
    "lng": -88.03498,
    "state_id": "WI"
  },
  {
    "zip": 53105,
    "lat": 42.66416,
    "lng": -88.27988,
    "state_id": "WI"
  },
  {
    "zip": 53108,
    "lat": 42.81978,
    "lng": -87.94647,
    "state_id": "WI"
  },
  {
    "zip": 53110,
    "lat": 42.94672,
    "lng": -87.86398,
    "state_id": "WI"
  },
  {
    "zip": 53114,
    "lat": 42.59736,
    "lng": -88.75083,
    "state_id": "WI"
  },
  {
    "zip": 53115,
    "lat": 42.65545,
    "lng": -88.67317,
    "state_id": "WI"
  },
  {
    "zip": 53118,
    "lat": 42.97132,
    "lng": -88.49083,
    "state_id": "WI"
  },
  {
    "zip": 53119,
    "lat": 42.88488,
    "lng": -88.48554,
    "state_id": "WI"
  },
  {
    "zip": 53120,
    "lat": 42.80064,
    "lng": -88.41664,
    "state_id": "WI"
  },
  {
    "zip": 53121,
    "lat": 42.70882,
    "lng": -88.52927,
    "state_id": "WI"
  },
  {
    "zip": 53122,
    "lat": 43.04813,
    "lng": -88.08681,
    "state_id": "WI"
  },
  {
    "zip": 53125,
    "lat": 42.54793,
    "lng": -88.56179,
    "state_id": "WI"
  },
  {
    "zip": 53126,
    "lat": 42.78838,
    "lng": -87.99671,
    "state_id": "WI"
  },
  {
    "zip": 53128,
    "lat": 42.53087,
    "lng": -88.33951,
    "state_id": "WI"
  },
  {
    "zip": 53129,
    "lat": 42.93801,
    "lng": -88.00003,
    "state_id": "WI"
  },
  {
    "zip": 53130,
    "lat": 42.94103,
    "lng": -88.0489,
    "state_id": "WI"
  },
  {
    "zip": 53132,
    "lat": 42.8856,
    "lng": -88.01084,
    "state_id": "WI"
  },
  {
    "zip": 53137,
    "lat": 43.00563,
    "lng": -88.6687,
    "state_id": "WI"
  },
  {
    "zip": 53139,
    "lat": 42.68594,
    "lng": -88.11952,
    "state_id": "WI"
  },
  {
    "zip": 53140,
    "lat": 42.62256,
    "lng": -87.83,
    "state_id": "WI"
  },
  {
    "zip": 53142,
    "lat": 42.54277,
    "lng": -87.94223,
    "state_id": "WI"
  },
  {
    "zip": 53143,
    "lat": 42.56095,
    "lng": -87.8304,
    "state_id": "WI"
  },
  {
    "zip": 53144,
    "lat": 42.61949,
    "lng": -87.92314,
    "state_id": "WI"
  },
  {
    "zip": 53146,
    "lat": 42.97188,
    "lng": -88.1589,
    "state_id": "WI"
  },
  {
    "zip": 53147,
    "lat": 42.57095,
    "lng": -88.45149,
    "state_id": "WI"
  },
  {
    "zip": 53149,
    "lat": 42.87777,
    "lng": -88.3411,
    "state_id": "WI"
  },
  {
    "zip": 53150,
    "lat": 42.8843,
    "lng": -88.12768,
    "state_id": "WI"
  },
  {
    "zip": 53151,
    "lat": 42.97409,
    "lng": -88.09873,
    "state_id": "WI"
  },
  {
    "zip": 53153,
    "lat": 42.93381,
    "lng": -88.40785,
    "state_id": "WI"
  },
  {
    "zip": 53154,
    "lat": 42.88039,
    "lng": -87.90087,
    "state_id": "WI"
  },
  {
    "zip": 53156,
    "lat": 42.88772,
    "lng": -88.59074,
    "state_id": "WI"
  },
  {
    "zip": 53158,
    "lat": 42.52429,
    "lng": -87.88838,
    "state_id": "WI"
  },
  {
    "zip": 53167,
    "lat": 42.74132,
    "lng": -88.22526,
    "state_id": "WI"
  },
  {
    "zip": 53168,
    "lat": 42.582,
    "lng": -88.13148,
    "state_id": "WI"
  },
  {
    "zip": 53170,
    "lat": 42.55195,
    "lng": -88.16144,
    "state_id": "WI"
  },
  {
    "zip": 53172,
    "lat": 42.91197,
    "lng": -87.86266,
    "state_id": "WI"
  },
  {
    "zip": 53177,
    "lat": 42.70241,
    "lng": -87.93542,
    "state_id": "WI"
  },
  {
    "zip": 53178,
    "lat": 43.02247,
    "lng": -88.59833,
    "state_id": "WI"
  },
  {
    "zip": 53179,
    "lat": 42.5135,
    "lng": -88.13113,
    "state_id": "WI"
  },
  {
    "zip": 53181,
    "lat": 42.5166,
    "lng": -88.24475,
    "state_id": "WI"
  },
  {
    "zip": 53182,
    "lat": 42.6989,
    "lng": -88.04164,
    "state_id": "WI"
  },
  {
    "zip": 53183,
    "lat": 43.0043,
    "lng": -88.37322,
    "state_id": "WI"
  },
  {
    "zip": 53184,
    "lat": 42.53101,
    "lng": -88.6068,
    "state_id": "WI"
  },
  {
    "zip": 53185,
    "lat": 42.79492,
    "lng": -88.19529,
    "state_id": "WI"
  },
  {
    "zip": 53186,
    "lat": 43.02337,
    "lng": -88.20311,
    "state_id": "WI"
  },
  {
    "zip": 53188,
    "lat": 43.02214,
    "lng": -88.29138,
    "state_id": "WI"
  },
  {
    "zip": 53189,
    "lat": 42.95324,
    "lng": -88.28223,
    "state_id": "WI"
  },
  {
    "zip": 53190,
    "lat": 42.80597,
    "lng": -88.73329,
    "state_id": "WI"
  },
  {
    "zip": 53191,
    "lat": 42.57946,
    "lng": -88.54526,
    "state_id": "WI"
  },
  {
    "zip": 53192,
    "lat": 42.50568,
    "lng": -88.18673,
    "state_id": "WI"
  },
  {
    "zip": 53195,
    "lat": 42.51399,
    "lng": -88.49014,
    "state_id": "WI"
  },
  {
    "zip": 53202,
    "lat": 43.04513,
    "lng": -87.89819,
    "state_id": "WI"
  },
  {
    "zip": 53203,
    "lat": 43.03857,
    "lng": -87.91617,
    "state_id": "WI"
  },
  {
    "zip": 53204,
    "lat": 43.01827,
    "lng": -87.9255,
    "state_id": "WI"
  },
  {
    "zip": 53205,
    "lat": 43.05339,
    "lng": -87.93393,
    "state_id": "WI"
  },
  {
    "zip": 53206,
    "lat": 43.07508,
    "lng": -87.93368,
    "state_id": "WI"
  },
  {
    "zip": 53207,
    "lat": 42.97199,
    "lng": -87.90148,
    "state_id": "WI"
  },
  {
    "zip": 53208,
    "lat": 43.04662,
    "lng": -87.96559,
    "state_id": "WI"
  },
  {
    "zip": 53209,
    "lat": 43.12841,
    "lng": -87.94713,
    "state_id": "WI"
  },
  {
    "zip": 53210,
    "lat": 43.06881,
    "lng": -87.9738,
    "state_id": "WI"
  },
  {
    "zip": 53211,
    "lat": 43.08262,
    "lng": -87.8842,
    "state_id": "WI"
  },
  {
    "zip": 53212,
    "lat": 43.07455,
    "lng": -87.90853,
    "state_id": "WI"
  },
  {
    "zip": 53213,
    "lat": 43.04889,
    "lng": -88.00141,
    "state_id": "WI"
  },
  {
    "zip": 53214,
    "lat": 43.02079,
    "lng": -88.01465,
    "state_id": "WI"
  },
  {
    "zip": 53215,
    "lat": 42.99922,
    "lng": -87.9468,
    "state_id": "WI"
  },
  {
    "zip": 53216,
    "lat": 43.08636,
    "lng": -87.97635,
    "state_id": "WI"
  },
  {
    "zip": 53217,
    "lat": 43.15744,
    "lng": -87.91631,
    "state_id": "WI"
  },
  {
    "zip": 53218,
    "lat": 43.11472,
    "lng": -87.99312,
    "state_id": "WI"
  },
  {
    "zip": 53219,
    "lat": 42.99546,
    "lng": -87.99218,
    "state_id": "WI"
  },
  {
    "zip": 53220,
    "lat": 42.96513,
    "lng": -87.99135,
    "state_id": "WI"
  },
  {
    "zip": 53221,
    "lat": 42.95406,
    "lng": -87.94392,
    "state_id": "WI"
  },
  {
    "zip": 53222,
    "lat": 43.08244,
    "lng": -88.03549,
    "state_id": "WI"
  },
  {
    "zip": 53223,
    "lat": 43.1634,
    "lng": -87.99049,
    "state_id": "WI"
  },
  {
    "zip": 53224,
    "lat": 43.16331,
    "lng": -88.03964,
    "state_id": "WI"
  },
  {
    "zip": 53225,
    "lat": 43.11501,
    "lng": -88.04186,
    "state_id": "WI"
  },
  {
    "zip": 53226,
    "lat": 43.04948,
    "lng": -88.04215,
    "state_id": "WI"
  },
  {
    "zip": 53227,
    "lat": 42.99555,
    "lng": -88.04252,
    "state_id": "WI"
  },
  {
    "zip": 53228,
    "lat": 42.96682,
    "lng": -88.0434,
    "state_id": "WI"
  },
  {
    "zip": 53233,
    "lat": 43.03691,
    "lng": -87.93383,
    "state_id": "WI"
  },
  {
    "zip": 53235,
    "lat": 42.97168,
    "lng": -87.87294,
    "state_id": "WI"
  },
  {
    "zip": 53295,
    "lat": 43.02243,
    "lng": -87.97609,
    "state_id": "WI"
  },
  {
    "zip": 53402,
    "lat": 42.79871,
    "lng": -87.82385,
    "state_id": "WI"
  },
  {
    "zip": 53403,
    "lat": 42.68864,
    "lng": -87.82515,
    "state_id": "WI"
  },
  {
    "zip": 53404,
    "lat": 42.75379,
    "lng": -87.81121,
    "state_id": "WI"
  },
  {
    "zip": 53405,
    "lat": 42.72615,
    "lng": -87.82545,
    "state_id": "WI"
  },
  {
    "zip": 53406,
    "lat": 42.72989,
    "lng": -87.8649,
    "state_id": "WI"
  },
  {
    "zip": 53501,
    "lat": 42.60606,
    "lng": -89.06536,
    "state_id": "WI"
  },
  {
    "zip": 53502,
    "lat": 42.73287,
    "lng": -89.44184,
    "state_id": "WI"
  },
  {
    "zip": 53503,
    "lat": 43.1393,
    "lng": -89.95615,
    "state_id": "WI"
  },
  {
    "zip": 53504,
    "lat": 42.70422,
    "lng": -89.86003,
    "state_id": "WI"
  },
  {
    "zip": 53505,
    "lat": 42.65691,
    "lng": -88.81901,
    "state_id": "WI"
  },
  {
    "zip": 53506,
    "lat": 43.15083,
    "lng": -90.28774,
    "state_id": "WI"
  },
  {
    "zip": 53507,
    "lat": 43.00235,
    "lng": -89.9026,
    "state_id": "WI"
  },
  {
    "zip": 53508,
    "lat": 42.86884,
    "lng": -89.56429,
    "state_id": "WI"
  },
  {
    "zip": 53510,
    "lat": 42.73729,
    "lng": -90.31818,
    "state_id": "WI"
  },
  {
    "zip": 53511,
    "lat": 42.54557,
    "lng": -89.09499,
    "state_id": "WI"
  },
  {
    "zip": 53515,
    "lat": 43.11685,
    "lng": -89.75134,
    "state_id": "WI"
  },
  {
    "zip": 53516,
    "lat": 42.80168,
    "lng": -89.86334,
    "state_id": "WI"
  },
  {
    "zip": 53517,
    "lat": 43.03593,
    "lng": -89.8317,
    "state_id": "WI"
  },
  {
    "zip": 53518,
    "lat": 43.24518,
    "lng": -90.59399,
    "state_id": "WI"
  },
  {
    "zip": 53520,
    "lat": 42.59967,
    "lng": -89.35857,
    "state_id": "WI"
  },
  {
    "zip": 53521,
    "lat": 42.84109,
    "lng": -89.40278,
    "state_id": "WI"
  },
  {
    "zip": 53522,
    "lat": 42.55635,
    "lng": -89.79023,
    "state_id": "WI"
  },
  {
    "zip": 53523,
    "lat": 42.98402,
    "lng": -89.03208,
    "state_id": "WI"
  },
  {
    "zip": 53525,
    "lat": 42.54801,
    "lng": -88.85533,
    "state_id": "WI"
  },
  {
    "zip": 53526,
    "lat": 42.9741,
    "lng": -90.34362,
    "state_id": "WI"
  },
  {
    "zip": 53527,
    "lat": 43.06173,
    "lng": -89.1927,
    "state_id": "WI"
  },
  {
    "zip": 53528,
    "lat": 43.11866,
    "lng": -89.63877,
    "state_id": "WI"
  },
  {
    "zip": 53529,
    "lat": 43.24143,
    "lng": -89.51559,
    "state_id": "WI"
  },
  {
    "zip": 53530,
    "lat": 42.69384,
    "lng": -90.10584,
    "state_id": "WI"
  },
  {
    "zip": 53531,
    "lat": 43.06122,
    "lng": -89.10168,
    "state_id": "WI"
  },
  {
    "zip": 53532,
    "lat": 43.24904,
    "lng": -89.32467,
    "state_id": "WI"
  },
  {
    "zip": 53533,
    "lat": 42.99399,
    "lng": -90.14677,
    "state_id": "WI"
  },
  {
    "zip": 53534,
    "lat": 42.84999,
    "lng": -89.0875,
    "state_id": "WI"
  },
  {
    "zip": 53536,
    "lat": 42.76193,
    "lng": -89.26543,
    "state_id": "WI"
  },
  {
    "zip": 53537,
    "lat": 42.67244,
    "lng": -89.21045,
    "state_id": "WI"
  },
  {
    "zip": 53538,
    "lat": 42.9209,
    "lng": -88.86448,
    "state_id": "WI"
  },
  {
    "zip": 53540,
    "lat": 43.2293,
    "lng": -90.28481,
    "state_id": "WI"
  },
  {
    "zip": 53541,
    "lat": 42.56614,
    "lng": -90.02929,
    "state_id": "WI"
  },
  {
    "zip": 53543,
    "lat": 43.05436,
    "lng": -90.36073,
    "state_id": "WI"
  },
  {
    "zip": 53544,
    "lat": 42.88253,
    "lng": -89.91449,
    "state_id": "WI"
  },
  {
    "zip": 53545,
    "lat": 42.73551,
    "lng": -89.04054,
    "state_id": "WI"
  },
  {
    "zip": 53546,
    "lat": 42.65267,
    "lng": -88.94916,
    "state_id": "WI"
  },
  {
    "zip": 53548,
    "lat": 42.68536,
    "lng": -89.12871,
    "state_id": "WI"
  },
  {
    "zip": 53549,
    "lat": 42.99112,
    "lng": -88.77675,
    "state_id": "WI"
  },
  {
    "zip": 53550,
    "lat": 42.5596,
    "lng": -89.49335,
    "state_id": "WI"
  },
  {
    "zip": 53551,
    "lat": 43.08158,
    "lng": -88.91614,
    "state_id": "WI"
  },
  {
    "zip": 53553,
    "lat": 42.90783,
    "lng": -90.30218,
    "state_id": "WI"
  },
  {
    "zip": 53554,
    "lat": 42.90148,
    "lng": -90.45066,
    "state_id": "WI"
  },
  {
    "zip": 53555,
    "lat": 43.32154,
    "lng": -89.55904,
    "state_id": "WI"
  },
  {
    "zip": 53556,
    "lat": 43.23808,
    "lng": -90.24298,
    "state_id": "WI"
  },
  {
    "zip": 53557,
    "lat": 43.34225,
    "lng": -88.79883,
    "state_id": "WI"
  },
  {
    "zip": 53558,
    "lat": 43.0037,
    "lng": -89.28276,
    "state_id": "WI"
  },
  {
    "zip": 53559,
    "lat": 43.16526,
    "lng": -89.07999,
    "state_id": "WI"
  },
  {
    "zip": 53560,
    "lat": 43.19328,
    "lng": -89.75666,
    "state_id": "WI"
  },
  {
    "zip": 53561,
    "lat": 43.39963,
    "lng": -89.64159,
    "state_id": "WI"
  },
  {
    "zip": 53562,
    "lat": 43.11646,
    "lng": -89.54157,
    "state_id": "WI"
  },
  {
    "zip": 53563,
    "lat": 42.7917,
    "lng": -88.93357,
    "state_id": "WI"
  },
  {
    "zip": 53565,
    "lat": 42.83912,
    "lng": -90.16508,
    "state_id": "WI"
  },
  {
    "zip": 53566,
    "lat": 42.60867,
    "lng": -89.64885,
    "state_id": "WI"
  },
  {
    "zip": 53569,
    "lat": 42.98163,
    "lng": -90.43311,
    "state_id": "WI"
  },
  {
    "zip": 53570,
    "lat": 42.73922,
    "lng": -89.61868,
    "state_id": "WI"
  },
  {
    "zip": 53571,
    "lat": 43.27802,
    "lng": -89.35327,
    "state_id": "WI"
  },
  {
    "zip": 53572,
    "lat": 42.96143,
    "lng": -89.73562,
    "state_id": "WI"
  },
  {
    "zip": 53573,
    "lat": 43.18541,
    "lng": -90.46751,
    "state_id": "WI"
  },
  {
    "zip": 53574,
    "lat": 42.81652,
    "lng": -89.65015,
    "state_id": "WI"
  },
  {
    "zip": 53575,
    "lat": 42.9337,
    "lng": -89.38998,
    "state_id": "WI"
  },
  {
    "zip": 53576,
    "lat": 42.62423,
    "lng": -89.23481,
    "state_id": "WI"
  },
  {
    "zip": 53577,
    "lat": 43.31433,
    "lng": -90.07105,
    "state_id": "WI"
  },
  {
    "zip": 53578,
    "lat": 43.32208,
    "lng": -89.77613,
    "state_id": "WI"
  },
  {
    "zip": 53579,
    "lat": 43.29012,
    "lng": -88.87891,
    "state_id": "WI"
  },
  {
    "zip": 53580,
    "lat": 42.85981,
    "lng": -90.37807,
    "state_id": "WI"
  },
  {
    "zip": 53581,
    "lat": 43.37272,
    "lng": -90.41141,
    "state_id": "WI"
  },
  {
    "zip": 53582,
    "lat": 43.01834,
    "lng": -89.99178,
    "state_id": "WI"
  },
  {
    "zip": 53583,
    "lat": 43.25426,
    "lng": -89.80015,
    "state_id": "WI"
  },
  {
    "zip": 53585,
    "lat": 42.52965,
    "lng": -88.71557,
    "state_id": "WI"
  },
  {
    "zip": 53586,
    "lat": 42.57309,
    "lng": -90.22662,
    "state_id": "WI"
  },
  {
    "zip": 53587,
    "lat": 42.57809,
    "lng": -89.9032,
    "state_id": "WI"
  },
  {
    "zip": 53588,
    "lat": 43.19261,
    "lng": -90.07965,
    "state_id": "WI"
  },
  {
    "zip": 53589,
    "lat": 42.9269,
    "lng": -89.21277,
    "state_id": "WI"
  },
  {
    "zip": 53590,
    "lat": 43.20077,
    "lng": -89.20597,
    "state_id": "WI"
  },
  {
    "zip": 53593,
    "lat": 42.98451,
    "lng": -89.57813,
    "state_id": "WI"
  },
  {
    "zip": 53594,
    "lat": 43.187,
    "lng": -88.97222,
    "state_id": "WI"
  },
  {
    "zip": 53597,
    "lat": 43.18256,
    "lng": -89.45691,
    "state_id": "WI"
  },
  {
    "zip": 53598,
    "lat": 43.20628,
    "lng": -89.33763,
    "state_id": "WI"
  },
  {
    "zip": 53599,
    "lat": 42.64765,
    "lng": -89.85989,
    "state_id": "WI"
  },
  {
    "zip": 53702,
    "lat": 43.06093,
    "lng": -88.23073,
    "state_id": "WI"
  },
  {
    "zip": 53703,
    "lat": 43.07984,
    "lng": -89.37708,
    "state_id": "WI"
  },
  {
    "zip": 53704,
    "lat": 43.13688,
    "lng": -89.34735,
    "state_id": "WI"
  },
  {
    "zip": 53705,
    "lat": 43.07364,
    "lng": -89.46339,
    "state_id": "WI"
  },
  {
    "zip": 53706,
    "lat": 43.07484,
    "lng": -89.41054,
    "state_id": "WI"
  },
  {
    "zip": 53711,
    "lat": 43.01504,
    "lng": -89.40923,
    "state_id": "WI"
  },
  {
    "zip": 53713,
    "lat": 43.03713,
    "lng": -89.38561,
    "state_id": "WI"
  },
  {
    "zip": 53714,
    "lat": 43.09867,
    "lng": -89.31218,
    "state_id": "WI"
  },
  {
    "zip": 53715,
    "lat": 43.06037,
    "lng": -89.39734,
    "state_id": "WI"
  },
  {
    "zip": 53716,
    "lat": 43.06444,
    "lng": -89.314,
    "state_id": "WI"
  },
  {
    "zip": 53717,
    "lat": 43.07384,
    "lng": -89.51958,
    "state_id": "WI"
  },
  {
    "zip": 53718,
    "lat": 43.09842,
    "lng": -89.27339,
    "state_id": "WI"
  },
  {
    "zip": 53719,
    "lat": 43.02756,
    "lng": -89.50153,
    "state_id": "WI"
  },
  {
    "zip": 53726,
    "lat": 43.06974,
    "lng": -89.42264,
    "state_id": "WI"
  },
  {
    "zip": 53792,
    "lat": 43.07754,
    "lng": -89.43065,
    "state_id": "WI"
  },
  {
    "zip": 53801,
    "lat": 42.92505,
    "lng": -91.06963,
    "state_id": "WI"
  },
  {
    "zip": 53802,
    "lat": 42.80589,
    "lng": -90.88858,
    "state_id": "WI"
  },
  {
    "zip": 53803,
    "lat": 42.55947,
    "lng": -90.35364,
    "state_id": "WI"
  },
  {
    "zip": 53804,
    "lat": 42.87499,
    "lng": -90.90786,
    "state_id": "WI"
  },
  {
    "zip": 53805,
    "lat": 43.1447,
    "lng": -90.68732,
    "state_id": "WI"
  },
  {
    "zip": 53806,
    "lat": 42.73845,
    "lng": -90.93029,
    "state_id": "WI"
  },
  {
    "zip": 53807,
    "lat": 42.61173,
    "lng": -90.47021,
    "state_id": "WI"
  },
  {
    "zip": 53808,
    "lat": 42.62614,
    "lng": -90.5931,
    "state_id": "WI"
  },
  {
    "zip": 53809,
    "lat": 42.99378,
    "lng": -90.63365,
    "state_id": "WI"
  },
  {
    "zip": 53810,
    "lat": 42.82151,
    "lng": -90.99945,
    "state_id": "WI"
  },
  {
    "zip": 53811,
    "lat": 42.53599,
    "lng": -90.51387,
    "state_id": "WI"
  },
  {
    "zip": 53813,
    "lat": 42.84391,
    "lng": -90.71955,
    "state_id": "WI"
  },
  {
    "zip": 53816,
    "lat": 42.97885,
    "lng": -90.85437,
    "state_id": "WI"
  },
  {
    "zip": 53817,
    "lat": 42.94508,
    "lng": -90.96765,
    "state_id": "WI"
  },
  {
    "zip": 53818,
    "lat": 42.74737,
    "lng": -90.48892,
    "state_id": "WI"
  },
  {
    "zip": 53820,
    "lat": 42.69557,
    "lng": -90.71111,
    "state_id": "WI"
  },
  {
    "zip": 53821,
    "lat": 43.052,
    "lng": -91.05837,
    "state_id": "WI"
  },
  {
    "zip": 53825,
    "lat": 42.91821,
    "lng": -90.59059,
    "state_id": "WI"
  },
  {
    "zip": 53826,
    "lat": 43.12235,
    "lng": -90.91348,
    "state_id": "WI"
  },
  {
    "zip": 53827,
    "lat": 43.05207,
    "lng": -90.83367,
    "state_id": "WI"
  },
  {
    "zip": 53901,
    "lat": 43.55706,
    "lng": -89.48644,
    "state_id": "WI"
  },
  {
    "zip": 53910,
    "lat": 43.89017,
    "lng": -89.8045,
    "state_id": "WI"
  },
  {
    "zip": 53911,
    "lat": 43.32544,
    "lng": -89.36305,
    "state_id": "WI"
  },
  {
    "zip": 53913,
    "lat": 43.49603,
    "lng": -89.7288,
    "state_id": "WI"
  },
  {
    "zip": 53916,
    "lat": 43.45547,
    "lng": -88.85942,
    "state_id": "WI"
  },
  {
    "zip": 53919,
    "lat": 43.73482,
    "lng": -88.77605,
    "state_id": "WI"
  },
  {
    "zip": 53920,
    "lat": 43.66696,
    "lng": -89.59605,
    "state_id": "WI"
  },
  {
    "zip": 53922,
    "lat": 43.5246,
    "lng": -88.70977,
    "state_id": "WI"
  },
  {
    "zip": 53923,
    "lat": 43.57359,
    "lng": -89.13717,
    "state_id": "WI"
  },
  {
    "zip": 53924,
    "lat": 43.48544,
    "lng": -90.25622,
    "state_id": "WI"
  },
  {
    "zip": 53925,
    "lat": 43.33474,
    "lng": -89.05209,
    "state_id": "WI"
  },
  {
    "zip": 53926,
    "lat": 43.67075,
    "lng": -89.21169,
    "state_id": "WI"
  },
  {
    "zip": 53928,
    "lat": 43.42635,
    "lng": -89.15357,
    "state_id": "WI"
  },
  {
    "zip": 53929,
    "lat": 43.7509,
    "lng": -90.28434,
    "state_id": "WI"
  },
  {
    "zip": 53930,
    "lat": 43.68344,
    "lng": -89.49072,
    "state_id": "WI"
  },
  {
    "zip": 53931,
    "lat": 43.74164,
    "lng": -88.85983,
    "state_id": "WI"
  },
  {
    "zip": 53932,
    "lat": 43.42085,
    "lng": -89.06709,
    "state_id": "WI"
  },
  {
    "zip": 53933,
    "lat": 43.5731,
    "lng": -88.88294,
    "state_id": "WI"
  },
  {
    "zip": 53934,
    "lat": 43.98193,
    "lng": -89.81915,
    "state_id": "WI"
  },
  {
    "zip": 53935,
    "lat": 43.58857,
    "lng": -89.067,
    "state_id": "WI"
  },
  {
    "zip": 53936,
    "lat": 43.87433,
    "lng": -89.70865,
    "state_id": "WI"
  },
  {
    "zip": 53937,
    "lat": 43.38661,
    "lng": -90.15233,
    "state_id": "WI"
  },
  {
    "zip": 53939,
    "lat": 43.69169,
    "lng": -89.13067,
    "state_id": "WI"
  },
  {
    "zip": 53941,
    "lat": 43.57392,
    "lng": -90.13772,
    "state_id": "WI"
  },
  {
    "zip": 53943,
    "lat": 43.39384,
    "lng": -90.03058,
    "state_id": "WI"
  },
  {
    "zip": 53944,
    "lat": 43.68666,
    "lng": -89.92579,
    "state_id": "WI"
  },
  {
    "zip": 53946,
    "lat": 43.71576,
    "lng": -89.02484,
    "state_id": "WI"
  },
  {
    "zip": 53947,
    "lat": 43.74678,
    "lng": -89.14009,
    "state_id": "WI"
  },
  {
    "zip": 53948,
    "lat": 43.77532,
    "lng": -90.04816,
    "state_id": "WI"
  },
  {
    "zip": 53949,
    "lat": 43.78296,
    "lng": -89.33587,
    "state_id": "WI"
  },
  {
    "zip": 53950,
    "lat": 43.90181,
    "lng": -90.13635,
    "state_id": "WI"
  },
  {
    "zip": 53951,
    "lat": 43.39197,
    "lng": -89.87218,
    "state_id": "WI"
  },
  {
    "zip": 53952,
    "lat": 43.78323,
    "lng": -89.59856,
    "state_id": "WI"
  },
  {
    "zip": 53953,
    "lat": 43.77254,
    "lng": -89.46829,
    "state_id": "WI"
  },
  {
    "zip": 53954,
    "lat": 43.55253,
    "lng": -89.31312,
    "state_id": "WI"
  },
  {
    "zip": 53955,
    "lat": 43.40405,
    "lng": -89.40619,
    "state_id": "WI"
  },
  {
    "zip": 53956,
    "lat": 43.54181,
    "lng": -89.01821,
    "state_id": "WI"
  },
  {
    "zip": 53959,
    "lat": 43.53422,
    "lng": -89.99898,
    "state_id": "WI"
  },
  {
    "zip": 53960,
    "lat": 43.42134,
    "lng": -89.23355,
    "state_id": "WI"
  },
  {
    "zip": 53961,
    "lat": 43.46255,
    "lng": -89.93557,
    "state_id": "WI"
  },
  {
    "zip": 53963,
    "lat": 43.63945,
    "lng": -88.74388,
    "state_id": "WI"
  },
  {
    "zip": 53964,
    "lat": 43.90959,
    "lng": -89.50927,
    "state_id": "WI"
  },
  {
    "zip": 53965,
    "lat": 43.67536,
    "lng": -89.75564,
    "state_id": "WI"
  },
  {
    "zip": 53968,
    "lat": 43.64267,
    "lng": -90.22906,
    "state_id": "WI"
  },
  {
    "zip": 53969,
    "lat": 43.49387,
    "lng": -89.30435,
    "state_id": "WI"
  },
  {
    "zip": 54001,
    "lat": 45.33476,
    "lng": -92.38718,
    "state_id": "WI"
  },
  {
    "zip": 54002,
    "lat": 44.9625,
    "lng": -92.37135,
    "state_id": "WI"
  },
  {
    "zip": 54003,
    "lat": 44.7902,
    "lng": -92.43919,
    "state_id": "WI"
  },
  {
    "zip": 54004,
    "lat": 45.30862,
    "lng": -92.12583,
    "state_id": "WI"
  },
  {
    "zip": 54005,
    "lat": 45.23565,
    "lng": -92.23198,
    "state_id": "WI"
  },
  {
    "zip": 54006,
    "lat": 45.59165,
    "lng": -92.65028,
    "state_id": "WI"
  },
  {
    "zip": 54007,
    "lat": 45.18829,
    "lng": -92.35803,
    "state_id": "WI"
  },
  {
    "zip": 54009,
    "lat": 45.3528,
    "lng": -92.57797,
    "state_id": "WI"
  },
  {
    "zip": 54011,
    "lat": 44.71608,
    "lng": -92.45864,
    "state_id": "WI"
  },
  {
    "zip": 54013,
    "lat": 45.08091,
    "lng": -92.23065,
    "state_id": "WI"
  },
  {
    "zip": 54014,
    "lat": 44.64671,
    "lng": -92.56764,
    "state_id": "WI"
  },
  {
    "zip": 54015,
    "lat": 44.96473,
    "lng": -92.45626,
    "state_id": "WI"
  },
  {
    "zip": 54016,
    "lat": 44.97895,
    "lng": -92.69329,
    "state_id": "WI"
  },
  {
    "zip": 54017,
    "lat": 45.11889,
    "lng": -92.52065,
    "state_id": "WI"
  },
  {
    "zip": 54020,
    "lat": 45.2789,
    "lng": -92.65071,
    "state_id": "WI"
  },
  {
    "zip": 54021,
    "lat": 44.73898,
    "lng": -92.70967,
    "state_id": "WI"
  },
  {
    "zip": 54022,
    "lat": 44.84792,
    "lng": -92.60802,
    "state_id": "WI"
  },
  {
    "zip": 54023,
    "lat": 44.98115,
    "lng": -92.55497,
    "state_id": "WI"
  },
  {
    "zip": 54024,
    "lat": 45.49745,
    "lng": -92.6465,
    "state_id": "WI"
  },
  {
    "zip": 54025,
    "lat": 45.14311,
    "lng": -92.6894,
    "state_id": "WI"
  },
  {
    "zip": 54026,
    "lat": 45.22603,
    "lng": -92.53037,
    "state_id": "WI"
  },
  {
    "zip": 54027,
    "lat": 44.92414,
    "lng": -92.19588,
    "state_id": "WI"
  },
  {
    "zip": 54028,
    "lat": 44.95437,
    "lng": -92.27948,
    "state_id": "WI"
  },
  {
    "zip": 54082,
    "lat": 45.07196,
    "lng": -92.75135,
    "state_id": "WI"
  },
  {
    "zip": 54101,
    "lat": 44.78959,
    "lng": -88.06277,
    "state_id": "WI"
  },
  {
    "zip": 54102,
    "lat": 45.50014,
    "lng": -87.98358,
    "state_id": "WI"
  },
  {
    "zip": 54103,
    "lat": 45.65038,
    "lng": -88.50159,
    "state_id": "WI"
  },
  {
    "zip": 54104,
    "lat": 45.44818,
    "lng": -88.26253,
    "state_id": "WI"
  },
  {
    "zip": 54106,
    "lat": 44.48476,
    "lng": -88.45863,
    "state_id": "WI"
  },
  {
    "zip": 54107,
    "lat": 44.69819,
    "lng": -88.44737,
    "state_id": "WI"
  },
  {
    "zip": 54110,
    "lat": 44.18832,
    "lng": -88.07538,
    "state_id": "WI"
  },
  {
    "zip": 54111,
    "lat": 44.81453,
    "lng": -88.38449,
    "state_id": "WI"
  },
  {
    "zip": 54112,
    "lat": 45.04584,
    "lng": -88.04584,
    "state_id": "WI"
  },
  {
    "zip": 54113,
    "lat": 44.26422,
    "lng": -88.30647,
    "state_id": "WI"
  },
  {
    "zip": 54114,
    "lat": 45.25282,
    "lng": -88.12948,
    "state_id": "WI"
  },
  {
    "zip": 54115,
    "lat": 44.40464,
    "lng": -88.09197,
    "state_id": "WI"
  },
  {
    "zip": 54119,
    "lat": 45.6115,
    "lng": -88.16741,
    "state_id": "WI"
  },
  {
    "zip": 54120,
    "lat": 45.76084,
    "lng": -88.49609,
    "state_id": "WI"
  },
  {
    "zip": 54121,
    "lat": 45.86474,
    "lng": -88.31981,
    "state_id": "WI"
  },
  {
    "zip": 54123,
    "lat": 44.21126,
    "lng": -88.14301,
    "state_id": "WI"
  },
  {
    "zip": 54124,
    "lat": 44.90706,
    "lng": -88.36666,
    "state_id": "WI"
  },
  {
    "zip": 54125,
    "lat": 45.60064,
    "lng": -88.37509,
    "state_id": "WI"
  },
  {
    "zip": 54126,
    "lat": 44.29013,
    "lng": -88.03134,
    "state_id": "WI"
  },
  {
    "zip": 54127,
    "lat": 44.79639,
    "lng": -88.2696,
    "state_id": "WI"
  },
  {
    "zip": 54128,
    "lat": 44.86848,
    "lng": -88.80487,
    "state_id": "WI"
  },
  {
    "zip": 54129,
    "lat": 44.13195,
    "lng": -88.20044,
    "state_id": "WI"
  },
  {
    "zip": 54130,
    "lat": 44.31239,
    "lng": -88.24169,
    "state_id": "WI"
  },
  {
    "zip": 54135,
    "lat": 44.92472,
    "lng": -88.6266,
    "state_id": "WI"
  },
  {
    "zip": 54136,
    "lat": 44.26826,
    "lng": -88.33892,
    "state_id": "WI"
  },
  {
    "zip": 54137,
    "lat": 44.75683,
    "lng": -88.25495,
    "state_id": "WI"
  },
  {
    "zip": 54138,
    "lat": 45.32936,
    "lng": -88.42985,
    "state_id": "WI"
  },
  {
    "zip": 54139,
    "lat": 44.95601,
    "lng": -88.07501,
    "state_id": "WI"
  },
  {
    "zip": 54140,
    "lat": 44.28664,
    "lng": -88.31133,
    "state_id": "WI"
  },
  {
    "zip": 54141,
    "lat": 44.72742,
    "lng": -88.00603,
    "state_id": "WI"
  },
  {
    "zip": 54143,
    "lat": 45.08657,
    "lng": -87.70086,
    "state_id": "WI"
  },
  {
    "zip": 54149,
    "lat": 45.1997,
    "lng": -88.48299,
    "state_id": "WI"
  },
  {
    "zip": 54150,
    "lat": 44.98708,
    "lng": -88.84341,
    "state_id": "WI"
  },
  {
    "zip": 54151,
    "lat": 45.72489,
    "lng": -87.98815,
    "state_id": "WI"
  },
  {
    "zip": 54153,
    "lat": 44.90181,
    "lng": -87.92149,
    "state_id": "WI"
  },
  {
    "zip": 54154,
    "lat": 44.86807,
    "lng": -88.18845,
    "state_id": "WI"
  },
  {
    "zip": 54155,
    "lat": 44.52252,
    "lng": -88.19821,
    "state_id": "WI"
  },
  {
    "zip": 54156,
    "lat": 45.62477,
    "lng": -88.0129,
    "state_id": "WI"
  },
  {
    "zip": 54157,
    "lat": 45.04905,
    "lng": -87.81165,
    "state_id": "WI"
  },
  {
    "zip": 54159,
    "lat": 45.194,
    "lng": -87.82772,
    "state_id": "WI"
  },
  {
    "zip": 54160,
    "lat": 44.12057,
    "lng": -88.10046,
    "state_id": "WI"
  },
  {
    "zip": 54161,
    "lat": 45.12332,
    "lng": -88.15937,
    "state_id": "WI"
  },
  {
    "zip": 54162,
    "lat": 44.66275,
    "lng": -88.27925,
    "state_id": "WI"
  },
  {
    "zip": 54165,
    "lat": 44.51889,
    "lng": -88.32055,
    "state_id": "WI"
  },
  {
    "zip": 54166,
    "lat": 44.77703,
    "lng": -88.63245,
    "state_id": "WI"
  },
  {
    "zip": 54169,
    "lat": 44.17644,
    "lng": -88.27799,
    "state_id": "WI"
  },
  {
    "zip": 54170,
    "lat": 44.5136,
    "lng": -88.57595,
    "state_id": "WI"
  },
  {
    "zip": 54171,
    "lat": 44.71734,
    "lng": -88.11667,
    "state_id": "WI"
  },
  {
    "zip": 54173,
    "lat": 44.64354,
    "lng": -88.03191,
    "state_id": "WI"
  },
  {
    "zip": 54174,
    "lat": 45.03767,
    "lng": -88.42948,
    "state_id": "WI"
  },
  {
    "zip": 54175,
    "lat": 45.30646,
    "lng": -88.62295,
    "state_id": "WI"
  },
  {
    "zip": 54177,
    "lat": 45.35544,
    "lng": -87.888,
    "state_id": "WI"
  },
  {
    "zip": 54180,
    "lat": 44.32639,
    "lng": -88.17479,
    "state_id": "WI"
  },
  {
    "zip": 54201,
    "lat": 44.62082,
    "lng": -87.49347,
    "state_id": "WI"
  },
  {
    "zip": 54202,
    "lat": 45.07718,
    "lng": -87.13809,
    "state_id": "WI"
  },
  {
    "zip": 54204,
    "lat": 44.75871,
    "lng": -87.62032,
    "state_id": "WI"
  },
  {
    "zip": 54205,
    "lat": 44.60088,
    "lng": -87.62521,
    "state_id": "WI"
  },
  {
    "zip": 54207,
    "lat": 44.08782,
    "lng": -87.98502,
    "state_id": "WI"
  },
  {
    "zip": 54208,
    "lat": 44.36408,
    "lng": -87.79668,
    "state_id": "WI"
  },
  {
    "zip": 54209,
    "lat": 45.01107,
    "lng": -87.26975,
    "state_id": "WI"
  },
  {
    "zip": 54210,
    "lat": 45.25343,
    "lng": -87.0356,
    "state_id": "WI"
  },
  {
    "zip": 54211,
    "lat": 45.15937,
    "lng": -87.16786,
    "state_id": "WI"
  },
  {
    "zip": 54212,
    "lat": 45.12134,
    "lng": -87.22908,
    "state_id": "WI"
  },
  {
    "zip": 54213,
    "lat": 44.69964,
    "lng": -87.52147,
    "state_id": "WI"
  },
  {
    "zip": 54214,
    "lat": 44.19737,
    "lng": -87.71273,
    "state_id": "WI"
  },
  {
    "zip": 54216,
    "lat": 44.43696,
    "lng": -87.58065,
    "state_id": "WI"
  },
  {
    "zip": 54217,
    "lat": 44.55075,
    "lng": -87.71497,
    "state_id": "WI"
  },
  {
    "zip": 54220,
    "lat": 44.09757,
    "lng": -87.72976,
    "state_id": "WI"
  },
  {
    "zip": 54227,
    "lat": 44.28012,
    "lng": -87.80783,
    "state_id": "WI"
  },
  {
    "zip": 54228,
    "lat": 44.27675,
    "lng": -87.64873,
    "state_id": "WI"
  },
  {
    "zip": 54229,
    "lat": 44.56155,
    "lng": -87.82307,
    "state_id": "WI"
  },
  {
    "zip": 54230,
    "lat": 44.15781,
    "lng": -87.91291,
    "state_id": "WI"
  },
  {
    "zip": 54232,
    "lat": 44.00743,
    "lng": -87.9243,
    "state_id": "WI"
  },
  {
    "zip": 54234,
    "lat": 45.18253,
    "lng": -87.09228,
    "state_id": "WI"
  },
  {
    "zip": 54235,
    "lat": 44.84077,
    "lng": -87.37946,
    "state_id": "WI"
  },
  {
    "zip": 54241,
    "lat": 44.21813,
    "lng": -87.59652,
    "state_id": "WI"
  },
  {
    "zip": 54245,
    "lat": 44.0271,
    "lng": -87.89606,
    "state_id": "WI"
  },
  {
    "zip": 54246,
    "lat": 45.37524,
    "lng": -86.8974,
    "state_id": "WI"
  },
  {
    "zip": 54247,
    "lat": 44.19705,
    "lng": -87.78509,
    "state_id": "WI"
  },
  {
    "zip": 54301,
    "lat": 44.48205,
    "lng": -88.02034,
    "state_id": "WI"
  },
  {
    "zip": 54302,
    "lat": 44.50772,
    "lng": -87.97613,
    "state_id": "WI"
  },
  {
    "zip": 54303,
    "lat": 44.53868,
    "lng": -88.05,
    "state_id": "WI"
  },
  {
    "zip": 54304,
    "lat": 44.49407,
    "lng": -88.0679,
    "state_id": "WI"
  },
  {
    "zip": 54307,
    "lat": 44.47045,
    "lng": -88.03664,
    "state_id": "WI"
  },
  {
    "zip": 54311,
    "lat": 44.48328,
    "lng": -87.89609,
    "state_id": "WI"
  },
  {
    "zip": 54313,
    "lat": 44.58619,
    "lng": -88.11147,
    "state_id": "WI"
  },
  {
    "zip": 54401,
    "lat": 44.96559,
    "lng": -89.70676,
    "state_id": "WI"
  },
  {
    "zip": 54403,
    "lat": 45.03139,
    "lng": -89.51374,
    "state_id": "WI"
  },
  {
    "zip": 54405,
    "lat": 44.96139,
    "lng": -90.28741,
    "state_id": "WI"
  },
  {
    "zip": 54406,
    "lat": 44.40962,
    "lng": -89.31085,
    "state_id": "WI"
  },
  {
    "zip": 54407,
    "lat": 44.52623,
    "lng": -89.29339,
    "state_id": "WI"
  },
  {
    "zip": 54408,
    "lat": 45.02914,
    "lng": -89.31057,
    "state_id": "WI"
  },
  {
    "zip": 54409,
    "lat": 45.11913,
    "lng": -89.16997,
    "state_id": "WI"
  },
  {
    "zip": 54410,
    "lat": 44.542,
    "lng": -90.03027,
    "state_id": "WI"
  },
  {
    "zip": 54411,
    "lat": 45.04989,
    "lng": -90.02757,
    "state_id": "WI"
  },
  {
    "zip": 54412,
    "lat": 44.66906,
    "lng": -89.98049,
    "state_id": "WI"
  },
  {
    "zip": 54413,
    "lat": 44.28432,
    "lng": -90.13183,
    "state_id": "WI"
  },
  {
    "zip": 54414,
    "lat": 44.95708,
    "lng": -89.17873,
    "state_id": "WI"
  },
  {
    "zip": 54416,
    "lat": 44.87666,
    "lng": -88.94167,
    "state_id": "WI"
  },
  {
    "zip": 54417,
    "lat": 45.0255,
    "lng": -89.65176,
    "state_id": "WI"
  },
  {
    "zip": 54418,
    "lat": 45.22116,
    "lng": -88.94497,
    "state_id": "WI"
  },
  {
    "zip": 54420,
    "lat": 44.61231,
    "lng": -90.35344,
    "state_id": "WI"
  },
  {
    "zip": 54421,
    "lat": 44.89899,
    "lng": -90.29505,
    "state_id": "WI"
  },
  {
    "zip": 54422,
    "lat": 44.98436,
    "lng": -90.45648,
    "state_id": "WI"
  },
  {
    "zip": 54423,
    "lat": 44.58228,
    "lng": -89.42148,
    "state_id": "WI"
  },
  {
    "zip": 54424,
    "lat": 45.28771,
    "lng": -89.18119,
    "state_id": "WI"
  },
  {
    "zip": 54425,
    "lat": 45.00925,
    "lng": -90.33973,
    "state_id": "WI"
  },
  {
    "zip": 54426,
    "lat": 44.90587,
    "lng": -89.98573,
    "state_id": "WI"
  },
  {
    "zip": 54427,
    "lat": 44.82476,
    "lng": -89.26009,
    "state_id": "WI"
  },
  {
    "zip": 54428,
    "lat": 45.43252,
    "lng": -89.14769,
    "state_id": "WI"
  },
  {
    "zip": 54430,
    "lat": 45.1507,
    "lng": -88.88287,
    "state_id": "WI"
  },
  {
    "zip": 54433,
    "lat": 45.20014,
    "lng": -90.81672,
    "state_id": "WI"
  },
  {
    "zip": 54435,
    "lat": 45.35639,
    "lng": -89.43915,
    "state_id": "WI"
  },
  {
    "zip": 54436,
    "lat": 44.53929,
    "lng": -90.42885,
    "state_id": "WI"
  },
  {
    "zip": 54437,
    "lat": 44.77497,
    "lng": -90.6383,
    "state_id": "WI"
  },
  {
    "zip": 54440,
    "lat": 44.80885,
    "lng": -89.38629,
    "state_id": "WI"
  },
  {
    "zip": 54441,
    "lat": 44.64249,
    "lng": -90.10484,
    "state_id": "WI"
  },
  {
    "zip": 54442,
    "lat": 45.36581,
    "lng": -89.65445,
    "state_id": "WI"
  },
  {
    "zip": 54443,
    "lat": 44.61209,
    "lng": -89.75353,
    "state_id": "WI"
  },
  {
    "zip": 54446,
    "lat": 44.7532,
    "lng": -90.47184,
    "state_id": "WI"
  },
  {
    "zip": 54447,
    "lat": 45.08446,
    "lng": -90.73992,
    "state_id": "WI"
  },
  {
    "zip": 54448,
    "lat": 44.93371,
    "lng": -89.83362,
    "state_id": "WI"
  },
  {
    "zip": 54449,
    "lat": 44.63687,
    "lng": -90.19925,
    "state_id": "WI"
  },
  {
    "zip": 54450,
    "lat": 45.00431,
    "lng": -89.04064,
    "state_id": "WI"
  },
  {
    "zip": 54451,
    "lat": 45.19521,
    "lng": -90.40546,
    "state_id": "WI"
  },
  {
    "zip": 54452,
    "lat": 45.20989,
    "lng": -89.76838,
    "state_id": "WI"
  },
  {
    "zip": 54454,
    "lat": 44.6071,
    "lng": -89.8849,
    "state_id": "WI"
  },
  {
    "zip": 54455,
    "lat": 44.76579,
    "lng": -89.68458,
    "state_id": "WI"
  },
  {
    "zip": 54456,
    "lat": 44.54707,
    "lng": -90.63589,
    "state_id": "WI"
  },
  {
    "zip": 54457,
    "lat": 44.22743,
    "lng": -89.89145,
    "state_id": "WI"
  },
  {
    "zip": 54458,
    "lat": 44.49572,
    "lng": -89.30808,
    "state_id": "WI"
  },
  {
    "zip": 54459,
    "lat": 45.42581,
    "lng": -90.27358,
    "state_id": "WI"
  },
  {
    "zip": 54460,
    "lat": 44.94244,
    "lng": -90.53901,
    "state_id": "WI"
  },
  {
    "zip": 54462,
    "lat": 45.39568,
    "lng": -88.99395,
    "state_id": "WI"
  },
  {
    "zip": 54463,
    "lat": 45.5104,
    "lng": -89.21785,
    "state_id": "WI"
  },
  {
    "zip": 54465,
    "lat": 45.38306,
    "lng": -88.8912,
    "state_id": "WI"
  },
  {
    "zip": 54466,
    "lat": 44.38423,
    "lng": -90.30733,
    "state_id": "WI"
  },
  {
    "zip": 54467,
    "lat": 44.41621,
    "lng": -89.54175,
    "state_id": "WI"
  },
  {
    "zip": 54469,
    "lat": 44.34595,
    "lng": -89.8703,
    "state_id": "WI"
  },
  {
    "zip": 54470,
    "lat": 45.29264,
    "lng": -90.14032,
    "state_id": "WI"
  },
  {
    "zip": 54471,
    "lat": 44.93864,
    "lng": -89.41638,
    "state_id": "WI"
  },
  {
    "zip": 54473,
    "lat": 44.65015,
    "lng": -89.3558,
    "state_id": "WI"
  },
  {
    "zip": 54474,
    "lat": 44.87483,
    "lng": -89.62092,
    "state_id": "WI"
  },
  {
    "zip": 54475,
    "lat": 44.48241,
    "lng": -89.78891,
    "state_id": "WI"
  },
  {
    "zip": 54476,
    "lat": 44.88805,
    "lng": -89.5241,
    "state_id": "WI"
  },
  {
    "zip": 54479,
    "lat": 44.75636,
    "lng": -90.32153,
    "state_id": "WI"
  },
  {
    "zip": 54480,
    "lat": 45.05988,
    "lng": -90.2767,
    "state_id": "WI"
  },
  {
    "zip": 54481,
    "lat": 44.51577,
    "lng": -89.63778,
    "state_id": "WI"
  },
  {
    "zip": 54482,
    "lat": 44.55634,
    "lng": -89.51828,
    "state_id": "WI"
  },
  {
    "zip": 54484,
    "lat": 44.79947,
    "lng": -90.07835,
    "state_id": "WI"
  },
  {
    "zip": 54485,
    "lat": 45.39504,
    "lng": -89.22156,
    "state_id": "WI"
  },
  {
    "zip": 54486,
    "lat": 44.72524,
    "lng": -89.05162,
    "state_id": "WI"
  },
  {
    "zip": 54487,
    "lat": 45.50665,
    "lng": -89.76299,
    "state_id": "WI"
  },
  {
    "zip": 54488,
    "lat": 44.84315,
    "lng": -90.3324,
    "state_id": "WI"
  },
  {
    "zip": 54489,
    "lat": 44.45927,
    "lng": -89.99994,
    "state_id": "WI"
  },
  {
    "zip": 54490,
    "lat": 45.33809,
    "lng": -90.40265,
    "state_id": "WI"
  },
  {
    "zip": 54491,
    "lat": 45.24004,
    "lng": -88.75196,
    "state_id": "WI"
  },
  {
    "zip": 54493,
    "lat": 44.70187,
    "lng": -90.80356,
    "state_id": "WI"
  },
  {
    "zip": 54494,
    "lat": 44.33908,
    "lng": -89.73768,
    "state_id": "WI"
  },
  {
    "zip": 54495,
    "lat": 44.38628,
    "lng": -89.95749,
    "state_id": "WI"
  },
  {
    "zip": 54498,
    "lat": 45.03348,
    "lng": -90.62445,
    "state_id": "WI"
  },
  {
    "zip": 54499,
    "lat": 44.79125,
    "lng": -89.17806,
    "state_id": "WI"
  },
  {
    "zip": 54501,
    "lat": 45.65303,
    "lng": -89.34502,
    "state_id": "WI"
  },
  {
    "zip": 54511,
    "lat": 45.72765,
    "lng": -88.83421,
    "state_id": "WI"
  },
  {
    "zip": 54512,
    "lat": 46.09528,
    "lng": -89.67093,
    "state_id": "WI"
  },
  {
    "zip": 54513,
    "lat": 45.56604,
    "lng": -90.11667,
    "state_id": "WI"
  },
  {
    "zip": 54514,
    "lat": 46.04328,
    "lng": -90.47638,
    "state_id": "WI"
  },
  {
    "zip": 54515,
    "lat": 45.51813,
    "lng": -90.49694,
    "state_id": "WI"
  },
  {
    "zip": 54517,
    "lat": 46.14749,
    "lng": -90.93303,
    "state_id": "WI"
  },
  {
    "zip": 54519,
    "lat": 46.0499,
    "lng": -89.26222,
    "state_id": "WI"
  },
  {
    "zip": 54520,
    "lat": 45.53287,
    "lng": -88.91079,
    "state_id": "WI"
  },
  {
    "zip": 54521,
    "lat": 45.9341,
    "lng": -89.28504,
    "state_id": "WI"
  },
  {
    "zip": 54524,
    "lat": 45.84562,
    "lng": -90.33167,
    "state_id": "WI"
  },
  {
    "zip": 54525,
    "lat": 46.42897,
    "lng": -90.22015,
    "state_id": "WI"
  },
  {
    "zip": 54526,
    "lat": 45.50554,
    "lng": -90.85908,
    "state_id": "WI"
  },
  {
    "zip": 54527,
    "lat": 46.12333,
    "lng": -90.66421,
    "state_id": "WI"
  },
  {
    "zip": 54529,
    "lat": 45.67827,
    "lng": -89.66341,
    "state_id": "WI"
  },
  {
    "zip": 54530,
    "lat": 45.5307,
    "lng": -90.72529,
    "state_id": "WI"
  },
  {
    "zip": 54531,
    "lat": 45.74851,
    "lng": -89.78583,
    "state_id": "WI"
  },
  {
    "zip": 54534,
    "lat": 46.34892,
    "lng": -90.22852,
    "state_id": "WI"
  },
  {
    "zip": 54536,
    "lat": 46.3286,
    "lng": -90.35431,
    "state_id": "WI"
  },
  {
    "zip": 54537,
    "lat": 45.5269,
    "lng": -90.6061,
    "state_id": "WI"
  },
  {
    "zip": 54538,
    "lat": 45.97046,
    "lng": -89.90891,
    "state_id": "WI"
  },
  {
    "zip": 54539,
    "lat": 45.80539,
    "lng": -89.56781,
    "state_id": "WI"
  },
  {
    "zip": 54540,
    "lat": 46.15615,
    "lng": -89.38222,
    "state_id": "WI"
  },
  {
    "zip": 54541,
    "lat": 45.55474,
    "lng": -88.64354,
    "state_id": "WI"
  },
  {
    "zip": 54542,
    "lat": 45.92228,
    "lng": -88.71962,
    "state_id": "WI"
  },
  {
    "zip": 54545,
    "lat": 46.12872,
    "lng": -89.859,
    "state_id": "WI"
  },
  {
    "zip": 54546,
    "lat": 46.30229,
    "lng": -90.70272,
    "state_id": "WI"
  },
  {
    "zip": 54547,
    "lat": 46.1923,
    "lng": -90.10199,
    "state_id": "WI"
  },
  {
    "zip": 54548,
    "lat": 45.85982,
    "lng": -89.82106,
    "state_id": "WI"
  },
  {
    "zip": 54550,
    "lat": 46.36212,
    "lng": -90.26062,
    "state_id": "WI"
  },
  {
    "zip": 54552,
    "lat": 45.94084,
    "lng": -90.31193,
    "state_id": "WI"
  },
  {
    "zip": 54554,
    "lat": 46.05232,
    "lng": -89.05673,
    "state_id": "WI"
  },
  {
    "zip": 54555,
    "lat": 45.70662,
    "lng": -90.3978,
    "state_id": "WI"
  },
  {
    "zip": 54556,
    "lat": 45.53563,
    "lng": -90.29965,
    "state_id": "WI"
  },
  {
    "zip": 54557,
    "lat": 46.21921,
    "lng": -89.75778,
    "state_id": "WI"
  },
  {
    "zip": 54558,
    "lat": 45.92342,
    "lng": -89.50139,
    "state_id": "WI"
  },
  {
    "zip": 54559,
    "lat": 46.49173,
    "lng": -90.47819,
    "state_id": "WI"
  },
  {
    "zip": 54560,
    "lat": 46.0151,
    "lng": -89.54514,
    "state_id": "WI"
  },
  {
    "zip": 54561,
    "lat": 46.08694,
    "lng": -89.51407,
    "state_id": "WI"
  },
  {
    "zip": 54562,
    "lat": 45.80976,
    "lng": -89.08346,
    "state_id": "WI"
  },
  {
    "zip": 54563,
    "lat": 45.46546,
    "lng": -90.97366,
    "state_id": "WI"
  },
  {
    "zip": 54564,
    "lat": 45.61407,
    "lng": -89.97157,
    "state_id": "WI"
  },
  {
    "zip": 54565,
    "lat": 46.30136,
    "lng": -90.44842,
    "state_id": "WI"
  },
  {
    "zip": 54566,
    "lat": 45.43213,
    "lng": -88.62055,
    "state_id": "WI"
  },
  {
    "zip": 54568,
    "lat": 45.94645,
    "lng": -89.67527,
    "state_id": "WI"
  },
  {
    "zip": 54601,
    "lat": 43.80439,
    "lng": -91.1425,
    "state_id": "WI"
  },
  {
    "zip": 54603,
    "lat": 43.85705,
    "lng": -91.24708,
    "state_id": "WI"
  },
  {
    "zip": 54610,
    "lat": 44.3717,
    "lng": -91.83453,
    "state_id": "WI"
  },
  {
    "zip": 54611,
    "lat": 44.44771,
    "lng": -90.95226,
    "state_id": "WI"
  },
  {
    "zip": 54612,
    "lat": 44.24805,
    "lng": -91.50053,
    "state_id": "WI"
  },
  {
    "zip": 54613,
    "lat": 44.07503,
    "lng": -89.92131,
    "state_id": "WI"
  },
  {
    "zip": 54614,
    "lat": 43.90109,
    "lng": -90.97023,
    "state_id": "WI"
  },
  {
    "zip": 54615,
    "lat": 44.26092,
    "lng": -90.78617,
    "state_id": "WI"
  },
  {
    "zip": 54616,
    "lat": 44.29961,
    "lng": -91.23187,
    "state_id": "WI"
  },
  {
    "zip": 54618,
    "lat": 43.96744,
    "lng": -90.28807,
    "state_id": "WI"
  },
  {
    "zip": 54619,
    "lat": 43.75716,
    "lng": -90.77702,
    "state_id": "WI"
  },
  {
    "zip": 54621,
    "lat": 43.65801,
    "lng": -91.08282,
    "state_id": "WI"
  },
  {
    "zip": 54622,
    "lat": 44.26719,
    "lng": -91.77157,
    "state_id": "WI"
  },
  {
    "zip": 54623,
    "lat": 43.73042,
    "lng": -91.01906,
    "state_id": "WI"
  },
  {
    "zip": 54624,
    "lat": 43.46795,
    "lng": -91.13957,
    "state_id": "WI"
  },
  {
    "zip": 54625,
    "lat": 44.13342,
    "lng": -91.525,
    "state_id": "WI"
  },
  {
    "zip": 54626,
    "lat": 43.21702,
    "lng": -91.03844,
    "state_id": "WI"
  },
  {
    "zip": 54627,
    "lat": 44.16764,
    "lng": -91.24052,
    "state_id": "WI"
  },
  {
    "zip": 54628,
    "lat": 43.37294,
    "lng": -91.02466,
    "state_id": "WI"
  },
  {
    "zip": 54629,
    "lat": 44.1587,
    "lng": -91.66432,
    "state_id": "WI"
  },
  {
    "zip": 54630,
    "lat": 44.1009,
    "lng": -91.35798,
    "state_id": "WI"
  },
  {
    "zip": 54631,
    "lat": 43.28559,
    "lng": -90.83419,
    "state_id": "WI"
  },
  {
    "zip": 54632,
    "lat": 43.56413,
    "lng": -91.14609,
    "state_id": "WI"
  },
  {
    "zip": 54634,
    "lat": 43.59665,
    "lng": -90.42659,
    "state_id": "WI"
  },
  {
    "zip": 54635,
    "lat": 44.40441,
    "lng": -91.04457,
    "state_id": "WI"
  },
  {
    "zip": 54636,
    "lat": 44.00759,
    "lng": -91.22657,
    "state_id": "WI"
  },
  {
    "zip": 54637,
    "lat": 43.87875,
    "lng": -90.27016,
    "state_id": "WI"
  },
  {
    "zip": 54638,
    "lat": 43.79595,
    "lng": -90.38606,
    "state_id": "WI"
  },
  {
    "zip": 54639,
    "lat": 43.60653,
    "lng": -90.63976,
    "state_id": "WI"
  },
  {
    "zip": 54641,
    "lat": 44.27697,
    "lng": -90.33979,
    "state_id": "WI"
  },
  {
    "zip": 54642,
    "lat": 44.15146,
    "lng": -91.05324,
    "state_id": "WI"
  },
  {
    "zip": 54643,
    "lat": 44.1866,
    "lng": -90.63897,
    "state_id": "WI"
  },
  {
    "zip": 54644,
    "lat": 44.03086,
    "lng": -91.04235,
    "state_id": "WI"
  },
  {
    "zip": 54645,
    "lat": 43.32116,
    "lng": -90.93268,
    "state_id": "WI"
  },
  {
    "zip": 54646,
    "lat": 44.09002,
    "lng": -90.08963,
    "state_id": "WI"
  },
  {
    "zip": 54648,
    "lat": 43.834,
    "lng": -90.63729,
    "state_id": "WI"
  },
  {
    "zip": 54650,
    "lat": 43.91066,
    "lng": -91.22523,
    "state_id": "WI"
  },
  {
    "zip": 54651,
    "lat": 43.72533,
    "lng": -90.56471,
    "state_id": "WI"
  },
  {
    "zip": 54652,
    "lat": 43.46063,
    "lng": -90.76227,
    "state_id": "WI"
  },
  {
    "zip": 54653,
    "lat": 43.81746,
    "lng": -90.90828,
    "state_id": "WI"
  },
  {
    "zip": 54654,
    "lat": 43.26813,
    "lng": -90.96341,
    "state_id": "WI"
  },
  {
    "zip": 54655,
    "lat": 43.37944,
    "lng": -90.76606,
    "state_id": "WI"
  },
  {
    "zip": 54656,
    "lat": 44.00277,
    "lng": -90.81094,
    "state_id": "WI"
  },
  {
    "zip": 54657,
    "lat": 43.19307,
    "lng": -90.89826,
    "state_id": "WI"
  },
  {
    "zip": 54658,
    "lat": 43.67908,
    "lng": -91.17662,
    "state_id": "WI"
  },
  {
    "zip": 54659,
    "lat": 44.29442,
    "lng": -91.10773,
    "state_id": "WI"
  },
  {
    "zip": 54660,
    "lat": 43.98256,
    "lng": -90.49021,
    "state_id": "WI"
  },
  {
    "zip": 54661,
    "lat": 44.0597,
    "lng": -91.47069,
    "state_id": "WI"
  },
  {
    "zip": 54664,
    "lat": 43.49887,
    "lng": -90.63688,
    "state_id": "WI"
  },
  {
    "zip": 54665,
    "lat": 43.52853,
    "lng": -90.92655,
    "state_id": "WI"
  },
  {
    "zip": 54666,
    "lat": 44.17466,
    "lng": -90.44513,
    "state_id": "WI"
  },
  {
    "zip": 54667,
    "lat": 43.66723,
    "lng": -90.85913,
    "state_id": "WI"
  },
  {
    "zip": 54669,
    "lat": 43.91175,
    "lng": -91.09104,
    "state_id": "WI"
  },
  {
    "zip": 54670,
    "lat": 43.83738,
    "lng": -90.48586,
    "state_id": "WI"
  },
  {
    "zip": 54701,
    "lat": 44.74438,
    "lng": -91.51058,
    "state_id": "WI"
  },
  {
    "zip": 54703,
    "lat": 44.83443,
    "lng": -91.51609,
    "state_id": "WI"
  },
  {
    "zip": 54720,
    "lat": 44.80403,
    "lng": -91.43549,
    "state_id": "WI"
  },
  {
    "zip": 54721,
    "lat": 44.62932,
    "lng": -92.06966,
    "state_id": "WI"
  },
  {
    "zip": 54722,
    "lat": 44.71502,
    "lng": -91.10299,
    "state_id": "WI"
  },
  {
    "zip": 54723,
    "lat": 44.60881,
    "lng": -92.43235,
    "state_id": "WI"
  },
  {
    "zip": 54724,
    "lat": 45.10646,
    "lng": -91.48778,
    "state_id": "WI"
  },
  {
    "zip": 54725,
    "lat": 45.07012,
    "lng": -92.01216,
    "state_id": "WI"
  },
  {
    "zip": 54726,
    "lat": 44.93125,
    "lng": -91.01739,
    "state_id": "WI"
  },
  {
    "zip": 54727,
    "lat": 44.95514,
    "lng": -91.15774,
    "state_id": "WI"
  },
  {
    "zip": 54728,
    "lat": 45.30833,
    "lng": -91.63943,
    "state_id": "WI"
  },
  {
    "zip": 54729,
    "lat": 44.94402,
    "lng": -91.39495,
    "state_id": "WI"
  },
  {
    "zip": 54730,
    "lat": 45.02006,
    "lng": -91.72338,
    "state_id": "WI"
  },
  {
    "zip": 54731,
    "lat": 45.35093,
    "lng": -91.08182,
    "state_id": "WI"
  },
  {
    "zip": 54732,
    "lat": 45.14305,
    "lng": -91.17255,
    "state_id": "WI"
  },
  {
    "zip": 54733,
    "lat": 45.27787,
    "lng": -91.85681,
    "state_id": "WI"
  },
  {
    "zip": 54734,
    "lat": 45.09863,
    "lng": -92.11774,
    "state_id": "WI"
  },
  {
    "zip": 54736,
    "lat": 44.59978,
    "lng": -91.90719,
    "state_id": "WI"
  },
  {
    "zip": 54737,
    "lat": 44.71526,
    "lng": -91.9822,
    "state_id": "WI"
  },
  {
    "zip": 54738,
    "lat": 44.59184,
    "lng": -91.48015,
    "state_id": "WI"
  },
  {
    "zip": 54739,
    "lat": 44.86288,
    "lng": -91.7014,
    "state_id": "WI"
  },
  {
    "zip": 54740,
    "lat": 44.74436,
    "lng": -92.16172,
    "state_id": "WI"
  },
  {
    "zip": 54741,
    "lat": 44.61528,
    "lng": -90.97799,
    "state_id": "WI"
  },
  {
    "zip": 54742,
    "lat": 44.765,
    "lng": -91.27075,
    "state_id": "WI"
  },
  {
    "zip": 54745,
    "lat": 45.25013,
    "lng": -91.12559,
    "state_id": "WI"
  },
  {
    "zip": 54746,
    "lat": 44.54446,
    "lng": -90.87579,
    "state_id": "WI"
  },
  {
    "zip": 54747,
    "lat": 44.39028,
    "lng": -91.50512,
    "state_id": "WI"
  },
  {
    "zip": 54748,
    "lat": 45.07813,
    "lng": -91.26289,
    "state_id": "WI"
  },
  {
    "zip": 54749,
    "lat": 44.94767,
    "lng": -92.09664,
    "state_id": "WI"
  },
  {
    "zip": 54750,
    "lat": 44.6143,
    "lng": -92.29379,
    "state_id": "WI"
  },
  {
    "zip": 54751,
    "lat": 44.85062,
    "lng": -91.93037,
    "state_id": "WI"
  },
  {
    "zip": 54754,
    "lat": 44.43413,
    "lng": -90.76372,
    "state_id": "WI"
  },
  {
    "zip": 54755,
    "lat": 44.57631,
    "lng": -91.68452,
    "state_id": "WI"
  },
  {
    "zip": 54756,
    "lat": 44.46805,
    "lng": -91.94816,
    "state_id": "WI"
  },
  {
    "zip": 54757,
    "lat": 45.23174,
    "lng": -91.52428,
    "state_id": "WI"
  },
  {
    "zip": 54758,
    "lat": 44.54861,
    "lng": -91.21876,
    "state_id": "WI"
  },
  {
    "zip": 54759,
    "lat": 44.48662,
    "lng": -92.12777,
    "state_id": "WI"
  },
  {
    "zip": 54760,
    "lat": 44.42402,
    "lng": -91.20653,
    "state_id": "WI"
  },
  {
    "zip": 54761,
    "lat": 44.6305,
    "lng": -92.18001,
    "state_id": "WI"
  },
  {
    "zip": 54762,
    "lat": 45.24796,
    "lng": -91.99095,
    "state_id": "WI"
  },
  {
    "zip": 54763,
    "lat": 45.17955,
    "lng": -91.88157,
    "state_id": "WI"
  },
  {
    "zip": 54765,
    "lat": 45.16912,
    "lng": -91.68549,
    "state_id": "WI"
  },
  {
    "zip": 54766,
    "lat": 45.33877,
    "lng": -90.85758,
    "state_id": "WI"
  },
  {
    "zip": 54767,
    "lat": 44.82855,
    "lng": -92.26147,
    "state_id": "WI"
  },
  {
    "zip": 54768,
    "lat": 44.95702,
    "lng": -90.92924,
    "state_id": "WI"
  },
  {
    "zip": 54769,
    "lat": 44.51417,
    "lng": -92.23733,
    "state_id": "WI"
  },
  {
    "zip": 54770,
    "lat": 44.5483,
    "lng": -91.37612,
    "state_id": "WI"
  },
  {
    "zip": 54771,
    "lat": 44.93238,
    "lng": -90.80678,
    "state_id": "WI"
  },
  {
    "zip": 54772,
    "lat": 45.09971,
    "lng": -91.87388,
    "state_id": "WI"
  },
  {
    "zip": 54773,
    "lat": 44.39256,
    "lng": -91.29405,
    "state_id": "WI"
  },
  {
    "zip": 54801,
    "lat": 45.87254,
    "lng": -91.93445,
    "state_id": "WI"
  },
  {
    "zip": 54805,
    "lat": 45.41281,
    "lng": -92.02158,
    "state_id": "WI"
  },
  {
    "zip": 54806,
    "lat": 46.5508,
    "lng": -90.86913,
    "state_id": "WI"
  },
  {
    "zip": 54810,
    "lat": 45.46026,
    "lng": -92.38174,
    "state_id": "WI"
  },
  {
    "zip": 54812,
    "lat": 45.39678,
    "lng": -91.88238,
    "state_id": "WI"
  },
  {
    "zip": 54813,
    "lat": 45.65593,
    "lng": -92.0319,
    "state_id": "WI"
  },
  {
    "zip": 54814,
    "lat": 46.86052,
    "lng": -90.91781,
    "state_id": "WI"
  },
  {
    "zip": 54817,
    "lat": 45.67316,
    "lng": -91.54273,
    "state_id": "WI"
  },
  {
    "zip": 54819,
    "lat": 45.48398,
    "lng": -91.3059,
    "state_id": "WI"
  },
  {
    "zip": 54820,
    "lat": 46.5718,
    "lng": -91.56072,
    "state_id": "WI"
  },
  {
    "zip": 54821,
    "lat": 46.22159,
    "lng": -91.16299,
    "state_id": "WI"
  },
  {
    "zip": 54822,
    "lat": 45.39374,
    "lng": -91.69652,
    "state_id": "WI"
  },
  {
    "zip": 54824,
    "lat": 45.47415,
    "lng": -92.54195,
    "state_id": "WI"
  },
  {
    "zip": 54826,
    "lat": 45.50282,
    "lng": -92.16712,
    "state_id": "WI"
  },
  {
    "zip": 54827,
    "lat": 46.79683,
    "lng": -91.10857,
    "state_id": "WI"
  },
  {
    "zip": 54828,
    "lat": 45.84072,
    "lng": -91.28358,
    "state_id": "WI"
  },
  {
    "zip": 54829,
    "lat": 45.55904,
    "lng": -92.05212,
    "state_id": "WI"
  },
  {
    "zip": 54830,
    "lat": 46.10807,
    "lng": -92.21857,
    "state_id": "WI"
  },
  {
    "zip": 54832,
    "lat": 46.32679,
    "lng": -91.32795,
    "state_id": "WI"
  },
  {
    "zip": 54835,
    "lat": 45.67993,
    "lng": -91.24127,
    "state_id": "WI"
  },
  {
    "zip": 54836,
    "lat": 46.43044,
    "lng": -92.1946,
    "state_id": "WI"
  },
  {
    "zip": 54837,
    "lat": 45.67293,
    "lng": -92.35442,
    "state_id": "WI"
  },
  {
    "zip": 54838,
    "lat": 46.25158,
    "lng": -91.81064,
    "state_id": "WI"
  },
  {
    "zip": 54839,
    "lat": 46.36035,
    "lng": -91.14147,
    "state_id": "WI"
  },
  {
    "zip": 54840,
    "lat": 45.75628,
    "lng": -92.68902,
    "state_id": "WI"
  },
  {
    "zip": 54841,
    "lat": 45.60657,
    "lng": -91.77864,
    "state_id": "WI"
  },
  {
    "zip": 54842,
    "lat": 46.4951,
    "lng": -91.85627,
    "state_id": "WI"
  },
  {
    "zip": 54843,
    "lat": 46.02612,
    "lng": -91.29713,
    "state_id": "WI"
  },
  {
    "zip": 54844,
    "lat": 46.79019,
    "lng": -91.22254,
    "state_id": "WI"
  },
  {
    "zip": 54845,
    "lat": 45.80039,
    "lng": -92.17539,
    "state_id": "WI"
  },
  {
    "zip": 54846,
    "lat": 46.37917,
    "lng": -90.7211,
    "state_id": "WI"
  },
  {
    "zip": 54847,
    "lat": 46.58715,
    "lng": -91.40233,
    "state_id": "WI"
  },
  {
    "zip": 54848,
    "lat": 45.51783,
    "lng": -91.09811,
    "state_id": "WI"
  },
  {
    "zip": 54849,
    "lat": 46.47653,
    "lng": -91.72473,
    "state_id": "WI"
  },
  {
    "zip": 54850,
    "lat": 46.92356,
    "lng": -90.62246,
    "state_id": "WI"
  },
  {
    "zip": 54853,
    "lat": 45.57001,
    "lng": -92.44633,
    "state_id": "WI"
  },
  {
    "zip": 54854,
    "lat": 46.64465,
    "lng": -91.66853,
    "state_id": "WI"
  },
  {
    "zip": 54855,
    "lat": 46.37428,
    "lng": -90.84374,
    "state_id": "WI"
  },
  {
    "zip": 54856,
    "lat": 46.43996,
    "lng": -91.13186,
    "state_id": "WI"
  },
  {
    "zip": 54857,
    "lat": 45.59493,
    "lng": -91.60317,
    "state_id": "WI"
  },
  {
    "zip": 54858,
    "lat": 45.51428,
    "lng": -92.46429,
    "state_id": "WI"
  },
  {
    "zip": 54859,
    "lat": 46.1264,
    "lng": -91.80167,
    "state_id": "WI"
  },
  {
    "zip": 54861,
    "lat": 46.61506,
    "lng": -90.65678,
    "state_id": "WI"
  },
  {
    "zip": 54862,
    "lat": 45.77725,
    "lng": -91.11838,
    "state_id": "WI"
  },
  {
    "zip": 54864,
    "lat": 46.60494,
    "lng": -91.79234,
    "state_id": "WI"
  },
  {
    "zip": 54865,
    "lat": 46.74666,
    "lng": -91.3792,
    "state_id": "WI"
  },
  {
    "zip": 54867,
    "lat": 45.78494,
    "lng": -91.22066,
    "state_id": "WI"
  },
  {
    "zip": 54868,
    "lat": 45.52596,
    "lng": -91.71346,
    "state_id": "WI"
  },
  {
    "zip": 54870,
    "lat": 45.71621,
    "lng": -91.77102,
    "state_id": "WI"
  },
  {
    "zip": 54871,
    "lat": 45.74345,
    "lng": -92.01353,
    "state_id": "WI"
  },
  {
    "zip": 54872,
    "lat": 45.77019,
    "lng": -92.40008,
    "state_id": "WI"
  },
  {
    "zip": 54873,
    "lat": 46.34635,
    "lng": -91.67535,
    "state_id": "WI"
  },
  {
    "zip": 54874,
    "lat": 46.5663,
    "lng": -91.94369,
    "state_id": "WI"
  },
  {
    "zip": 54875,
    "lat": 45.97,
    "lng": -91.69431,
    "state_id": "WI"
  },
  {
    "zip": 54876,
    "lat": 45.83049,
    "lng": -91.50264,
    "state_id": "WI"
  },
  {
    "zip": 54880,
    "lat": 46.59058,
    "lng": -92.12741,
    "state_id": "WI"
  },
  {
    "zip": 54888,
    "lat": 45.98085,
    "lng": -91.88299,
    "state_id": "WI"
  },
  {
    "zip": 54889,
    "lat": 45.41583,
    "lng": -92.17149,
    "state_id": "WI"
  },
  {
    "zip": 54891,
    "lat": 46.70092,
    "lng": -90.96518,
    "state_id": "WI"
  },
  {
    "zip": 54893,
    "lat": 45.87112,
    "lng": -92.30633,
    "state_id": "WI"
  },
  {
    "zip": 54895,
    "lat": 45.45093,
    "lng": -91.44721,
    "state_id": "WI"
  },
  {
    "zip": 54896,
    "lat": 45.81343,
    "lng": -90.8856,
    "state_id": "WI"
  },
  {
    "zip": 54901,
    "lat": 44.06283,
    "lng": -88.53313,
    "state_id": "WI"
  },
  {
    "zip": 54902,
    "lat": 43.9493,
    "lng": -88.53969,
    "state_id": "WI"
  },
  {
    "zip": 54904,
    "lat": 44.02148,
    "lng": -88.62508,
    "state_id": "WI"
  },
  {
    "zip": 54909,
    "lat": 44.28416,
    "lng": -89.35463,
    "state_id": "WI"
  },
  {
    "zip": 54911,
    "lat": 44.2817,
    "lng": -88.38164,
    "state_id": "WI"
  },
  {
    "zip": 54913,
    "lat": 44.34148,
    "lng": -88.39996,
    "state_id": "WI"
  },
  {
    "zip": 54914,
    "lat": 44.26384,
    "lng": -88.47529,
    "state_id": "WI"
  },
  {
    "zip": 54915,
    "lat": 44.24247,
    "lng": -88.35644,
    "state_id": "WI"
  },
  {
    "zip": 54921,
    "lat": 44.30242,
    "lng": -89.56444,
    "state_id": "WI"
  },
  {
    "zip": 54922,
    "lat": 44.54243,
    "lng": -88.74312,
    "state_id": "WI"
  },
  {
    "zip": 54923,
    "lat": 43.9978,
    "lng": -88.96727,
    "state_id": "WI"
  },
  {
    "zip": 54927,
    "lat": 44.10166,
    "lng": -88.65399,
    "state_id": "WI"
  },
  {
    "zip": 54928,
    "lat": 44.73803,
    "lng": -88.88733,
    "state_id": "WI"
  },
  {
    "zip": 54929,
    "lat": 44.63661,
    "lng": -88.74009,
    "state_id": "WI"
  },
  {
    "zip": 54930,
    "lat": 44.02804,
    "lng": -89.5561,
    "state_id": "WI"
  },
  {
    "zip": 54931,
    "lat": 44.2745,
    "lng": -88.67911,
    "state_id": "WI"
  },
  {
    "zip": 54932,
    "lat": 43.83082,
    "lng": -88.61354,
    "state_id": "WI"
  },
  {
    "zip": 54933,
    "lat": 44.66819,
    "lng": -88.70533,
    "state_id": "WI"
  },
  {
    "zip": 54935,
    "lat": 43.77424,
    "lng": -88.43516,
    "state_id": "WI"
  },
  {
    "zip": 54937,
    "lat": 43.76132,
    "lng": -88.43035,
    "state_id": "WI"
  },
  {
    "zip": 54940,
    "lat": 44.22487,
    "lng": -88.84428,
    "state_id": "WI"
  },
  {
    "zip": 54941,
    "lat": 43.84447,
    "lng": -88.99315,
    "state_id": "WI"
  },
  {
    "zip": 54942,
    "lat": 44.29119,
    "lng": -88.54784,
    "state_id": "WI"
  },
  {
    "zip": 54943,
    "lat": 44.12528,
    "lng": -89.5836,
    "state_id": "WI"
  },
  {
    "zip": 54944,
    "lat": 44.32503,
    "lng": -88.62775,
    "state_id": "WI"
  },
  {
    "zip": 54945,
    "lat": 44.57649,
    "lng": -89.13694,
    "state_id": "WI"
  },
  {
    "zip": 54946,
    "lat": 44.33661,
    "lng": -89.14439,
    "state_id": "WI"
  },
  {
    "zip": 54947,
    "lat": 44.19998,
    "lng": -88.68862,
    "state_id": "WI"
  },
  {
    "zip": 54948,
    "lat": 44.7771,
    "lng": -88.86982,
    "state_id": "WI"
  },
  {
    "zip": 54949,
    "lat": 44.48361,
    "lng": -88.91262,
    "state_id": "WI"
  },
  {
    "zip": 54950,
    "lat": 44.65489,
    "lng": -88.90539,
    "state_id": "WI"
  },
  {
    "zip": 54952,
    "lat": 44.20034,
    "lng": -88.32884,
    "state_id": "WI"
  },
  {
    "zip": 54956,
    "lat": 44.1825,
    "lng": -88.52769,
    "state_id": "WI"
  },
  {
    "zip": 54960,
    "lat": 43.94703,
    "lng": -89.22337,
    "state_id": "WI"
  },
  {
    "zip": 54961,
    "lat": 44.40924,
    "lng": -88.76117,
    "state_id": "WI"
  },
  {
    "zip": 54962,
    "lat": 44.48563,
    "lng": -89.02258,
    "state_id": "WI"
  },
  {
    "zip": 54963,
    "lat": 44.04307,
    "lng": -88.77445,
    "state_id": "WI"
  },
  {
    "zip": 54964,
    "lat": 43.92077,
    "lng": -88.71955,
    "state_id": "WI"
  },
  {
    "zip": 54965,
    "lat": 44.16619,
    "lng": -89.03272,
    "state_id": "WI"
  },
  {
    "zip": 54966,
    "lat": 44.2122,
    "lng": -89.50419,
    "state_id": "WI"
  },
  {
    "zip": 54967,
    "lat": 44.131,
    "lng": -88.98352,
    "state_id": "WI"
  },
  {
    "zip": 54968,
    "lat": 43.84918,
    "lng": -89.13053,
    "state_id": "WI"
  },
  {
    "zip": 54970,
    "lat": 44.07211,
    "lng": -89.09307,
    "state_id": "WI"
  },
  {
    "zip": 54971,
    "lat": 43.85612,
    "lng": -88.8298,
    "state_id": "WI"
  },
  {
    "zip": 54974,
    "lat": 43.78367,
    "lng": -88.6613,
    "state_id": "WI"
  },
  {
    "zip": 54977,
    "lat": 44.45795,
    "lng": -89.16597,
    "state_id": "WI"
  },
  {
    "zip": 54979,
    "lat": 43.86871,
    "lng": -88.53749,
    "state_id": "WI"
  },
  {
    "zip": 54980,
    "lat": 43.98377,
    "lng": -88.76983,
    "state_id": "WI"
  },
  {
    "zip": 54981,
    "lat": 44.32667,
    "lng": -89.12443,
    "state_id": "WI"
  },
  {
    "zip": 54982,
    "lat": 44.07214,
    "lng": -89.29132,
    "state_id": "WI"
  },
  {
    "zip": 54983,
    "lat": 44.31923,
    "lng": -88.93356,
    "state_id": "WI"
  },
  {
    "zip": 54984,
    "lat": 44.18831,
    "lng": -89.21758,
    "state_id": "WI"
  },
  {
    "zip": 54985,
    "lat": 44.07507,
    "lng": -88.51838,
    "state_id": "WI"
  },
  {
    "zip": 54986,
    "lat": 44.12078,
    "lng": -88.7475,
    "state_id": "WI"
  },
  {
    "zip": 24701,
    "lat": 37.30095,
    "lng": -81.20654,
    "state_id": "WV"
  },
  {
    "zip": 24712,
    "lat": 37.46476,
    "lng": -81.01395,
    "state_id": "WV"
  },
  {
    "zip": 24714,
    "lat": 37.47671,
    "lng": -81.18917,
    "state_id": "WV"
  },
  {
    "zip": 24715,
    "lat": 37.34319,
    "lng": -81.32865,
    "state_id": "WV"
  },
  {
    "zip": 24716,
    "lat": 37.48718,
    "lng": -81.3771,
    "state_id": "WV"
  },
  {
    "zip": 24719,
    "lat": 37.48719,
    "lng": -81.32668,
    "state_id": "WV"
  },
  {
    "zip": 24724,
    "lat": 37.33081,
    "lng": -81.29975,
    "state_id": "WV"
  },
  {
    "zip": 24726,
    "lat": 37.51971,
    "lng": -81.35652,
    "state_id": "WV"
  },
  {
    "zip": 24729,
    "lat": 37.44965,
    "lng": -81.25639,
    "state_id": "WV"
  },
  {
    "zip": 24731,
    "lat": 37.39797,
    "lng": -81.15108,
    "state_id": "WV"
  },
  {
    "zip": 24733,
    "lat": 37.4481,
    "lng": -81.20698,
    "state_id": "WV"
  },
  {
    "zip": 24736,
    "lat": 37.44465,
    "lng": -81.28034,
    "state_id": "WV"
  },
  {
    "zip": 24737,
    "lat": 37.35309,
    "lng": -81.24968,
    "state_id": "WV"
  },
  {
    "zip": 24738,
    "lat": 37.30217,
    "lng": -81.31247,
    "state_id": "WV"
  },
  {
    "zip": 24740,
    "lat": 37.37644,
    "lng": -81.01879,
    "state_id": "WV"
  },
  {
    "zip": 24747,
    "lat": 37.39621,
    "lng": -81.23169,
    "state_id": "WV"
  },
  {
    "zip": 24801,
    "lat": 37.44261,
    "lng": -81.5776,
    "state_id": "WV"
  },
  {
    "zip": 24808,
    "lat": 37.33627,
    "lng": -81.41506,
    "state_id": "WV"
  },
  {
    "zip": 24811,
    "lat": 37.40164,
    "lng": -81.77855,
    "state_id": "WV"
  },
  {
    "zip": 24813,
    "lat": 37.35742,
    "lng": -81.71665,
    "state_id": "WV"
  },
  {
    "zip": 24815,
    "lat": 37.2375,
    "lng": -81.67646,
    "state_id": "WV"
  },
  {
    "zip": 24816,
    "lat": 37.4612,
    "lng": -81.70504,
    "state_id": "WV"
  },
  {
    "zip": 24817,
    "lat": 37.35108,
    "lng": -81.80659,
    "state_id": "WV"
  },
  {
    "zip": 24818,
    "lat": 37.59883,
    "lng": -81.62628,
    "state_id": "WV"
  },
  {
    "zip": 24822,
    "lat": 37.6272,
    "lng": -81.68134,
    "state_id": "WV"
  },
  {
    "zip": 24823,
    "lat": 37.66123,
    "lng": -81.73721,
    "state_id": "WV"
  },
  {
    "zip": 24826,
    "lat": 37.29067,
    "lng": -81.63265,
    "state_id": "WV"
  },
  {
    "zip": 24827,
    "lat": 37.74225,
    "lng": -81.6667,
    "state_id": "WV"
  },
  {
    "zip": 24828,
    "lat": 37.48833,
    "lng": -81.6439,
    "state_id": "WV"
  },
  {
    "zip": 24830,
    "lat": 37.32375,
    "lng": -81.5096,
    "state_id": "WV"
  },
  {
    "zip": 24831,
    "lat": 37.39342,
    "lng": -81.40886,
    "state_id": "WV"
  },
  {
    "zip": 24834,
    "lat": 37.55325,
    "lng": -81.63047,
    "state_id": "WV"
  },
  {
    "zip": 24836,
    "lat": 37.3326,
    "lng": -81.55346,
    "state_id": "WV"
  },
  {
    "zip": 24839,
    "lat": 37.55264,
    "lng": -81.75146,
    "state_id": "WV"
  },
  {
    "zip": 24843,
    "lat": 37.48363,
    "lng": -81.70915,
    "state_id": "WV"
  },
  {
    "zip": 24844,
    "lat": 37.46241,
    "lng": -81.80464,
    "state_id": "WV"
  },
  {
    "zip": 24845,
    "lat": 37.52353,
    "lng": -81.80339,
    "state_id": "WV"
  },
  {
    "zip": 24846,
    "lat": 37.53033,
    "lng": -81.90864,
    "state_id": "WV"
  },
  {
    "zip": 24847,
    "lat": 37.57795,
    "lng": -81.41541,
    "state_id": "WV"
  },
  {
    "zip": 24848,
    "lat": 37.29081,
    "lng": -81.45116,
    "state_id": "WV"
  },
  {
    "zip": 24849,
    "lat": 37.67065,
    "lng": -81.55857,
    "state_id": "WV"
  },
  {
    "zip": 24850,
    "lat": 37.31161,
    "lng": -81.82836,
    "state_id": "WV"
  },
  {
    "zip": 24851,
    "lat": 37.59689,
    "lng": -81.83548,
    "state_id": "WV"
  },
  {
    "zip": 24853,
    "lat": 37.43434,
    "lng": -81.51184,
    "state_id": "WV"
  },
  {
    "zip": 24854,
    "lat": 37.74786,
    "lng": -81.56742,
    "state_id": "WV"
  },
  {
    "zip": 24857,
    "lat": 37.67446,
    "lng": -81.66072,
    "state_id": "WV"
  },
  {
    "zip": 24860,
    "lat": 37.66233,
    "lng": -81.60698,
    "state_id": "WV"
  },
  {
    "zip": 24861,
    "lat": 37.36111,
    "lng": -81.36569,
    "state_id": "WV"
  },
  {
    "zip": 24862,
    "lat": 37.47932,
    "lng": -81.95361,
    "state_id": "WV"
  },
  {
    "zip": 24866,
    "lat": 37.24808,
    "lng": -81.58027,
    "state_id": "WV"
  },
  {
    "zip": 24867,
    "lat": 37.603,
    "lng": -81.44719,
    "state_id": "WV"
  },
  {
    "zip": 24868,
    "lat": 37.41934,
    "lng": -81.40577,
    "state_id": "WV"
  },
  {
    "zip": 24869,
    "lat": 37.55936,
    "lng": -81.83264,
    "state_id": "WV"
  },
  {
    "zip": 24870,
    "lat": 37.73531,
    "lng": -81.54176,
    "state_id": "WV"
  },
  {
    "zip": 24871,
    "lat": 37.35701,
    "lng": -81.46798,
    "state_id": "WV"
  },
  {
    "zip": 24872,
    "lat": 37.44919,
    "lng": -81.8995,
    "state_id": "WV"
  },
  {
    "zip": 24873,
    "lat": 37.36594,
    "lng": -81.87873,
    "state_id": "WV"
  },
  {
    "zip": 24874,
    "lat": 37.56242,
    "lng": -81.52745,
    "state_id": "WV"
  },
  {
    "zip": 24878,
    "lat": 37.43445,
    "lng": -81.62941,
    "state_id": "WV"
  },
  {
    "zip": 24879,
    "lat": 37.32446,
    "lng": -81.76138,
    "state_id": "WV"
  },
  {
    "zip": 24880,
    "lat": 37.6439,
    "lng": -81.5376,
    "state_id": "WV"
  },
  {
    "zip": 24881,
    "lat": 37.4397,
    "lng": -81.69151,
    "state_id": "WV"
  },
  {
    "zip": 24882,
    "lat": 37.62737,
    "lng": -81.76625,
    "state_id": "WV"
  },
  {
    "zip": 24884,
    "lat": 37.25557,
    "lng": -81.56462,
    "state_id": "WV"
  },
  {
    "zip": 24887,
    "lat": 37.37458,
    "lng": -81.3998,
    "state_id": "WV"
  },
  {
    "zip": 24888,
    "lat": 37.38453,
    "lng": -81.49107,
    "state_id": "WV"
  },
  {
    "zip": 24892,
    "lat": 37.32664,
    "lng": -81.68777,
    "state_id": "WV"
  },
  {
    "zip": 24894,
    "lat": 37.28284,
    "lng": -81.70357,
    "state_id": "WV"
  },
  {
    "zip": 24898,
    "lat": 37.59418,
    "lng": -81.60561,
    "state_id": "WV"
  },
  {
    "zip": 24901,
    "lat": 37.84062,
    "lng": -80.46534,
    "state_id": "WV"
  },
  {
    "zip": 24910,
    "lat": 37.73709,
    "lng": -80.66095,
    "state_id": "WV"
  },
  {
    "zip": 24915,
    "lat": 38.46521,
    "lng": -79.77636,
    "state_id": "WV"
  },
  {
    "zip": 24916,
    "lat": 37.83127,
    "lng": -80.57465,
    "state_id": "WV"
  },
  {
    "zip": 24918,
    "lat": 37.52016,
    "lng": -80.75007,
    "state_id": "WV"
  },
  {
    "zip": 24920,
    "lat": 38.58404,
    "lng": -79.70137,
    "state_id": "WV"
  },
  {
    "zip": 24924,
    "lat": 38.22762,
    "lng": -80.18476,
    "state_id": "WV"
  },
  {
    "zip": 24925,
    "lat": 37.72352,
    "lng": -80.37915,
    "state_id": "WV"
  },
  {
    "zip": 24927,
    "lat": 38.37437,
    "lng": -79.95142,
    "state_id": "WV"
  },
  {
    "zip": 24931,
    "lat": 37.91927,
    "lng": -80.59151,
    "state_id": "WV"
  },
  {
    "zip": 24934,
    "lat": 38.33439,
    "lng": -79.89158,
    "state_id": "WV"
  },
  {
    "zip": 24935,
    "lat": 37.56075,
    "lng": -80.81113,
    "state_id": "WV"
  },
  {
    "zip": 24938,
    "lat": 37.91587,
    "lng": -80.3782,
    "state_id": "WV"
  },
  {
    "zip": 24941,
    "lat": 37.59451,
    "lng": -80.35445,
    "state_id": "WV"
  },
  {
    "zip": 24944,
    "lat": 38.38973,
    "lng": -79.78368,
    "state_id": "WV"
  },
  {
    "zip": 24945,
    "lat": 37.5427,
    "lng": -80.68473,
    "state_id": "WV"
  },
  {
    "zip": 24946,
    "lat": 38.18499,
    "lng": -80.26634,
    "state_id": "WV"
  },
  {
    "zip": 24951,
    "lat": 37.48122,
    "lng": -80.63645,
    "state_id": "WV"
  },
  {
    "zip": 24954,
    "lat": 38.22328,
    "lng": -80.0431,
    "state_id": "WV"
  },
  {
    "zip": 24957,
    "lat": 37.87649,
    "lng": -80.41916,
    "state_id": "WV"
  },
  {
    "zip": 24962,
    "lat": 37.66198,
    "lng": -80.72022,
    "state_id": "WV"
  },
  {
    "zip": 24963,
    "lat": 37.43603,
    "lng": -80.76931,
    "state_id": "WV"
  },
  {
    "zip": 24966,
    "lat": 38.0697,
    "lng": -80.37499,
    "state_id": "WV"
  },
  {
    "zip": 24970,
    "lat": 37.73129,
    "lng": -80.4789,
    "state_id": "WV"
  },
  {
    "zip": 24974,
    "lat": 37.65906,
    "lng": -80.4442,
    "state_id": "WV"
  },
  {
    "zip": 24976,
    "lat": 37.66092,
    "lng": -80.51347,
    "state_id": "WV"
  },
  {
    "zip": 24977,
    "lat": 37.90547,
    "lng": -80.6739,
    "state_id": "WV"
  },
  {
    "zip": 24981,
    "lat": 37.63105,
    "lng": -80.73586,
    "state_id": "WV"
  },
  {
    "zip": 24983,
    "lat": 37.5809,
    "lng": -80.52614,
    "state_id": "WV"
  },
  {
    "zip": 24984,
    "lat": 37.49259,
    "lng": -80.40375,
    "state_id": "WV"
  },
  {
    "zip": 24986,
    "lat": 37.90714,
    "lng": -80.19336,
    "state_id": "WV"
  },
  {
    "zip": 24991,
    "lat": 37.99916,
    "lng": -80.49923,
    "state_id": "WV"
  },
  {
    "zip": 25002,
    "lat": 38.13577,
    "lng": -81.24177,
    "state_id": "WV"
  },
  {
    "zip": 25003,
    "lat": 38.25115,
    "lng": -81.78615,
    "state_id": "WV"
  },
  {
    "zip": 25005,
    "lat": 38.58088,
    "lng": -81.26921,
    "state_id": "WV"
  },
  {
    "zip": 25007,
    "lat": 37.82536,
    "lng": -81.42866,
    "state_id": "WV"
  },
  {
    "zip": 25008,
    "lat": 37.94699,
    "lng": -81.34422,
    "state_id": "WV"
  },
  {
    "zip": 25009,
    "lat": 38.19199,
    "lng": -81.71202,
    "state_id": "WV"
  },
  {
    "zip": 25011,
    "lat": 38.50864,
    "lng": -81.83936,
    "state_id": "WV"
  },
  {
    "zip": 25015,
    "lat": 38.24257,
    "lng": -81.50234,
    "state_id": "WV"
  },
  {
    "zip": 25019,
    "lat": 38.37293,
    "lng": -81.08679,
    "state_id": "WV"
  },
  {
    "zip": 25021,
    "lat": 37.9224,
    "lng": -81.68056,
    "state_id": "WV"
  },
  {
    "zip": 25022,
    "lat": 37.85869,
    "lng": -81.8117,
    "state_id": "WV"
  },
  {
    "zip": 25024,
    "lat": 38.15593,
    "lng": -81.61944,
    "state_id": "WV"
  },
  {
    "zip": 25025,
    "lat": 38.30873,
    "lng": -81.39832,
    "state_id": "WV"
  },
  {
    "zip": 25028,
    "lat": 37.94783,
    "lng": -81.72337,
    "state_id": "WV"
  },
  {
    "zip": 25030,
    "lat": 38.45047,
    "lng": -81.22557,
    "state_id": "WV"
  },
  {
    "zip": 25031,
    "lat": 38.15192,
    "lng": -81.26561,
    "state_id": "WV"
  },
  {
    "zip": 25033,
    "lat": 38.61032,
    "lng": -81.92474,
    "state_id": "WV"
  },
  {
    "zip": 25035,
    "lat": 38.16171,
    "lng": -81.52151,
    "state_id": "WV"
  },
  {
    "zip": 25036,
    "lat": 38.22049,
    "lng": -81.25406,
    "state_id": "WV"
  },
  {
    "zip": 25039,
    "lat": 38.24482,
    "lng": -81.38211,
    "state_id": "WV"
  },
  {
    "zip": 25040,
    "lat": 38.12706,
    "lng": -81.23862,
    "state_id": "WV"
  },
  {
    "zip": 25043,
    "lat": 38.44173,
    "lng": -81.00563,
    "state_id": "WV"
  },
  {
    "zip": 25044,
    "lat": 37.89995,
    "lng": -81.34962,
    "state_id": "WV"
  },
  {
    "zip": 25045,
    "lat": 38.44508,
    "lng": -81.31869,
    "state_id": "WV"
  },
  {
    "zip": 25047,
    "lat": 37.92177,
    "lng": -81.76046,
    "state_id": "WV"
  },
  {
    "zip": 25048,
    "lat": 37.94294,
    "lng": -81.43672,
    "state_id": "WV"
  },
  {
    "zip": 25049,
    "lat": 38.11633,
    "lng": -81.56971,
    "state_id": "WV"
  },
  {
    "zip": 25051,
    "lat": 38.15894,
    "lng": -81.7092,
    "state_id": "WV"
  },
  {
    "zip": 25053,
    "lat": 38.04426,
    "lng": -81.87307,
    "state_id": "WV"
  },
  {
    "zip": 25054,
    "lat": 38.10831,
    "lng": -81.4916,
    "state_id": "WV"
  },
  {
    "zip": 25057,
    "lat": 38.12187,
    "lng": -81.2532,
    "state_id": "WV"
  },
  {
    "zip": 25059,
    "lat": 38.23312,
    "lng": -81.20771,
    "state_id": "WV"
  },
  {
    "zip": 25060,
    "lat": 37.9422,
    "lng": -81.42986,
    "state_id": "WV"
  },
  {
    "zip": 25061,
    "lat": 38.16753,
    "lng": -81.43745,
    "state_id": "WV"
  },
  {
    "zip": 25062,
    "lat": 37.87486,
    "lng": -81.44118,
    "state_id": "WV"
  },
  {
    "zip": 25063,
    "lat": 38.58235,
    "lng": -80.93417,
    "state_id": "WV"
  },
  {
    "zip": 25064,
    "lat": 38.37629,
    "lng": -81.74852,
    "state_id": "WV"
  },
  {
    "zip": 25067,
    "lat": 38.19767,
    "lng": -81.42894,
    "state_id": "WV"
  },
  {
    "zip": 25070,
    "lat": 38.54657,
    "lng": -81.93183,
    "state_id": "WV"
  },
  {
    "zip": 25071,
    "lat": 38.47317,
    "lng": -81.46821,
    "state_id": "WV"
  },
  {
    "zip": 25075,
    "lat": 38.05046,
    "lng": -81.42272,
    "state_id": "WV"
  },
  {
    "zip": 25076,
    "lat": 37.86915,
    "lng": -81.93628,
    "state_id": "WV"
  },
  {
    "zip": 25081,
    "lat": 38.08728,
    "lng": -81.77283,
    "state_id": "WV"
  },
  {
    "zip": 25082,
    "lat": 38.61211,
    "lng": -82.02606,
    "state_id": "WV"
  },
  {
    "zip": 25083,
    "lat": 38.0704,
    "lng": -81.36247,
    "state_id": "WV"
  },
  {
    "zip": 25085,
    "lat": 38.17118,
    "lng": -81.19568,
    "state_id": "WV"
  },
  {
    "zip": 25086,
    "lat": 38.20968,
    "lng": -81.41218,
    "state_id": "WV"
  },
  {
    "zip": 25088,
    "lat": 38.37978,
    "lng": -81.2145,
    "state_id": "WV"
  },
  {
    "zip": 25090,
    "lat": 38.15175,
    "lng": -81.21877,
    "state_id": "WV"
  },
  {
    "zip": 25093,
    "lat": 37.98081,
    "lng": -81.6567,
    "state_id": "WV"
  },
  {
    "zip": 25102,
    "lat": 38.18657,
    "lng": -81.36699,
    "state_id": "WV"
  },
  {
    "zip": 25103,
    "lat": 38.18321,
    "lng": -81.3831,
    "state_id": "WV"
  },
  {
    "zip": 25106,
    "lat": 38.78321,
    "lng": -82.08843,
    "state_id": "WV"
  },
  {
    "zip": 25107,
    "lat": 38.2275,
    "lng": -81.63441,
    "state_id": "WV"
  },
  {
    "zip": 25108,
    "lat": 37.96733,
    "lng": -81.87435,
    "state_id": "WV"
  },
  {
    "zip": 25109,
    "lat": 38.52787,
    "lng": -81.85533,
    "state_id": "WV"
  },
  {
    "zip": 25110,
    "lat": 38.22569,
    "lng": -81.30847,
    "state_id": "WV"
  },
  {
    "zip": 25111,
    "lat": 38.34987,
    "lng": -81.12695,
    "state_id": "WV"
  },
  {
    "zip": 25112,
    "lat": 38.38026,
    "lng": -81.76715,
    "state_id": "WV"
  },
  {
    "zip": 25113,
    "lat": 38.56094,
    "lng": -81.04967,
    "state_id": "WV"
  },
  {
    "zip": 25114,
    "lat": 37.97915,
    "lng": -81.79921,
    "state_id": "WV"
  },
  {
    "zip": 25115,
    "lat": 38.13064,
    "lng": -81.18988,
    "state_id": "WV"
  },
  {
    "zip": 25118,
    "lat": 38.1056,
    "lng": -81.30255,
    "state_id": "WV"
  },
  {
    "zip": 25119,
    "lat": 38.04395,
    "lng": -81.27234,
    "state_id": "WV"
  },
  {
    "zip": 25121,
    "lat": 37.91627,
    "lng": -81.89728,
    "state_id": "WV"
  },
  {
    "zip": 25123,
    "lat": 38.73473,
    "lng": -81.90386,
    "state_id": "WV"
  },
  {
    "zip": 25124,
    "lat": 38.62164,
    "lng": -81.76516,
    "state_id": "WV"
  },
  {
    "zip": 25125,
    "lat": 38.31361,
    "lng": -81.19409,
    "state_id": "WV"
  },
  {
    "zip": 25126,
    "lat": 38.20216,
    "lng": -81.37206,
    "state_id": "WV"
  },
  {
    "zip": 25130,
    "lat": 38.04153,
    "lng": -81.76326,
    "state_id": "WV"
  },
  {
    "zip": 25132,
    "lat": 38.30039,
    "lng": -81.34034,
    "state_id": "WV"
  },
  {
    "zip": 25133,
    "lat": 38.47715,
    "lng": -81.12929,
    "state_id": "WV"
  },
  {
    "zip": 25134,
    "lat": 38.16171,
    "lng": -81.48418,
    "state_id": "WV"
  },
  {
    "zip": 25136,
    "lat": 38.16042,
    "lng": -81.33168,
    "state_id": "WV"
  },
  {
    "zip": 25139,
    "lat": 38.13831,
    "lng": -81.28627,
    "state_id": "WV"
  },
  {
    "zip": 25140,
    "lat": 37.86721,
    "lng": -81.49651,
    "state_id": "WV"
  },
  {
    "zip": 25141,
    "lat": 38.63013,
    "lng": -81.03292,
    "state_id": "WV"
  },
  {
    "zip": 25142,
    "lat": 38.1531,
    "lng": -81.73204,
    "state_id": "WV"
  },
  {
    "zip": 25143,
    "lat": 38.41872,
    "lng": -81.82151,
    "state_id": "WV"
  },
  {
    "zip": 25148,
    "lat": 38.04912,
    "lng": -81.56878,
    "state_id": "WV"
  },
  {
    "zip": 25149,
    "lat": 37.95523,
    "lng": -81.76712,
    "state_id": "WV"
  },
  {
    "zip": 25152,
    "lat": 38.05738,
    "lng": -81.25363,
    "state_id": "WV"
  },
  {
    "zip": 25154,
    "lat": 38.11702,
    "lng": -81.70495,
    "state_id": "WV"
  },
  {
    "zip": 25156,
    "lat": 38.18734,
    "lng": -81.3484,
    "state_id": "WV"
  },
  {
    "zip": 25159,
    "lat": 38.50347,
    "lng": -81.78239,
    "state_id": "WV"
  },
  {
    "zip": 25160,
    "lat": 38.28953,
    "lng": -81.28653,
    "state_id": "WV"
  },
  {
    "zip": 25161,
    "lat": 38.05625,
    "lng": -81.32386,
    "state_id": "WV"
  },
  {
    "zip": 25162,
    "lat": 38.20867,
    "lng": -81.38624,
    "state_id": "WV"
  },
  {
    "zip": 25164,
    "lat": 38.52499,
    "lng": -81.20027,
    "state_id": "WV"
  },
  {
    "zip": 25165,
    "lat": 38.14734,
    "lng": -81.65112,
    "state_id": "WV"
  },
  {
    "zip": 25168,
    "lat": 38.56468,
    "lng": -81.8768,
    "state_id": "WV"
  },
  {
    "zip": 25169,
    "lat": 38.15255,
    "lng": -81.78931,
    "state_id": "WV"
  },
  {
    "zip": 25173,
    "lat": 38.08474,
    "lng": -81.22005,
    "state_id": "WV"
  },
  {
    "zip": 25174,
    "lat": 37.8603,
    "lng": -81.41206,
    "state_id": "WV"
  },
  {
    "zip": 25177,
    "lat": 38.3675,
    "lng": -81.84696,
    "state_id": "WV"
  },
  {
    "zip": 25180,
    "lat": 37.79008,
    "lng": -81.43459,
    "state_id": "WV"
  },
  {
    "zip": 25181,
    "lat": 38.04699,
    "lng": -81.62905,
    "state_id": "WV"
  },
  {
    "zip": 25183,
    "lat": 37.91741,
    "lng": -81.81367,
    "state_id": "WV"
  },
  {
    "zip": 25185,
    "lat": 38.23518,
    "lng": -81.23804,
    "state_id": "WV"
  },
  {
    "zip": 25186,
    "lat": 38.18187,
    "lng": -81.27268,
    "state_id": "WV"
  },
  {
    "zip": 25187,
    "lat": 38.72744,
    "lng": -82.02481,
    "state_id": "WV"
  },
  {
    "zip": 25193,
    "lat": 38.03466,
    "lng": -81.51738,
    "state_id": "WV"
  },
  {
    "zip": 25201,
    "lat": 38.33567,
    "lng": -81.49043,
    "state_id": "WV"
  },
  {
    "zip": 25202,
    "lat": 38.31778,
    "lng": -81.85577,
    "state_id": "WV"
  },
  {
    "zip": 25203,
    "lat": 38.01599,
    "lng": -81.88232,
    "state_id": "WV"
  },
  {
    "zip": 25204,
    "lat": 37.92602,
    "lng": -81.61411,
    "state_id": "WV"
  },
  {
    "zip": 25205,
    "lat": 38.0187,
    "lng": -81.78285,
    "state_id": "WV"
  },
  {
    "zip": 25206,
    "lat": 37.97748,
    "lng": -81.70128,
    "state_id": "WV"
  },
  {
    "zip": 25208,
    "lat": 37.84899,
    "lng": -81.64557,
    "state_id": "WV"
  },
  {
    "zip": 25209,
    "lat": 37.96296,
    "lng": -81.52965,
    "state_id": "WV"
  },
  {
    "zip": 25211,
    "lat": 38.46255,
    "lng": -80.88294,
    "state_id": "WV"
  },
  {
    "zip": 25213,
    "lat": 38.50759,
    "lng": -81.91664,
    "state_id": "WV"
  },
  {
    "zip": 25214,
    "lat": 38.17959,
    "lng": -81.55641,
    "state_id": "WV"
  },
  {
    "zip": 25234,
    "lat": 38.8106,
    "lng": -81.12968,
    "state_id": "WV"
  },
  {
    "zip": 25235,
    "lat": 38.66862,
    "lng": -81.09437,
    "state_id": "WV"
  },
  {
    "zip": 25239,
    "lat": 38.84049,
    "lng": -81.8431,
    "state_id": "WV"
  },
  {
    "zip": 25241,
    "lat": 38.78392,
    "lng": -81.807,
    "state_id": "WV"
  },
  {
    "zip": 25243,
    "lat": 38.68673,
    "lng": -81.47946,
    "state_id": "WV"
  },
  {
    "zip": 25244,
    "lat": 38.77216,
    "lng": -81.54808,
    "state_id": "WV"
  },
  {
    "zip": 25245,
    "lat": 38.69739,
    "lng": -81.74459,
    "state_id": "WV"
  },
  {
    "zip": 25247,
    "lat": 39.00471,
    "lng": -81.99687,
    "state_id": "WV"
  },
  {
    "zip": 25248,
    "lat": 38.64092,
    "lng": -81.61335,
    "state_id": "WV"
  },
  {
    "zip": 25251,
    "lat": 38.62056,
    "lng": -81.23634,
    "state_id": "WV"
  },
  {
    "zip": 25252,
    "lat": 38.88882,
    "lng": -81.53252,
    "state_id": "WV"
  },
  {
    "zip": 25253,
    "lat": 38.92317,
    "lng": -81.96954,
    "state_id": "WV"
  },
  {
    "zip": 25259,
    "lat": 38.67354,
    "lng": -81.25173,
    "state_id": "WV"
  },
  {
    "zip": 25260,
    "lat": 39.01271,
    "lng": -82.02672,
    "state_id": "WV"
  },
  {
    "zip": 25261,
    "lat": 38.82625,
    "lng": -81.08959,
    "state_id": "WV"
  },
  {
    "zip": 25262,
    "lat": 38.90658,
    "lng": -81.82867,
    "state_id": "WV"
  },
  {
    "zip": 25264,
    "lat": 38.85705,
    "lng": -81.89657,
    "state_id": "WV"
  },
  {
    "zip": 25265,
    "lat": 38.98673,
    "lng": -81.956,
    "state_id": "WV"
  },
  {
    "zip": 25266,
    "lat": 38.60601,
    "lng": -81.16232,
    "state_id": "WV"
  },
  {
    "zip": 25267,
    "lat": 38.84773,
    "lng": -80.94138,
    "state_id": "WV"
  },
  {
    "zip": 25268,
    "lat": 38.73254,
    "lng": -81.09847,
    "state_id": "WV"
  },
  {
    "zip": 25270,
    "lat": 38.8683,
    "lng": -81.44068,
    "state_id": "WV"
  },
  {
    "zip": 25271,
    "lat": 38.78552,
    "lng": -81.68577,
    "state_id": "WV"
  },
  {
    "zip": 25275,
    "lat": 38.92153,
    "lng": -81.61078,
    "state_id": "WV"
  },
  {
    "zip": 25276,
    "lat": 38.78676,
    "lng": -81.33124,
    "state_id": "WV"
  },
  {
    "zip": 25285,
    "lat": 38.54319,
    "lng": -81.11143,
    "state_id": "WV"
  },
  {
    "zip": 25286,
    "lat": 38.61521,
    "lng": -81.40795,
    "state_id": "WV"
  },
  {
    "zip": 25287,
    "lat": 38.95743,
    "lng": -82.05165,
    "state_id": "WV"
  },
  {
    "zip": 25301,
    "lat": 38.35104,
    "lng": -81.63004,
    "state_id": "WV"
  },
  {
    "zip": 25302,
    "lat": 38.39111,
    "lng": -81.59669,
    "state_id": "WV"
  },
  {
    "zip": 25303,
    "lat": 38.35988,
    "lng": -81.6859,
    "state_id": "WV"
  },
  {
    "zip": 25304,
    "lat": 38.30559,
    "lng": -81.59315,
    "state_id": "WV"
  },
  {
    "zip": 25305,
    "lat": 38.33767,
    "lng": -81.61273,
    "state_id": "WV"
  },
  {
    "zip": 25306,
    "lat": 38.3111,
    "lng": -81.49031,
    "state_id": "WV"
  },
  {
    "zip": 25309,
    "lat": 38.30848,
    "lng": -81.75055,
    "state_id": "WV"
  },
  {
    "zip": 25311,
    "lat": 38.36313,
    "lng": -81.54925,
    "state_id": "WV"
  },
  {
    "zip": 25312,
    "lat": 38.45242,
    "lng": -81.65735,
    "state_id": "WV"
  },
  {
    "zip": 25313,
    "lat": 38.41679,
    "lng": -81.75645,
    "state_id": "WV"
  },
  {
    "zip": 25314,
    "lat": 38.30584,
    "lng": -81.65057,
    "state_id": "WV"
  },
  {
    "zip": 25315,
    "lat": 38.23049,
    "lng": -81.56809,
    "state_id": "WV"
  },
  {
    "zip": 25320,
    "lat": 38.53925,
    "lng": -81.63074,
    "state_id": "WV"
  },
  {
    "zip": 25401,
    "lat": 39.45683,
    "lng": -77.97265,
    "state_id": "WV"
  },
  {
    "zip": 25403,
    "lat": 39.48285,
    "lng": -78.00499,
    "state_id": "WV"
  },
  {
    "zip": 25404,
    "lat": 39.48517,
    "lng": -77.89536,
    "state_id": "WV"
  },
  {
    "zip": 25405,
    "lat": 39.40916,
    "lng": -77.96154,
    "state_id": "WV"
  },
  {
    "zip": 25411,
    "lat": 39.55551,
    "lng": -78.21892,
    "state_id": "WV"
  },
  {
    "zip": 25413,
    "lat": 39.31619,
    "lng": -78.05298,
    "state_id": "WV"
  },
  {
    "zip": 25414,
    "lat": 39.25107,
    "lng": -77.86895,
    "state_id": "WV"
  },
  {
    "zip": 25419,
    "lat": 39.58288,
    "lng": -77.88497,
    "state_id": "WV"
  },
  {
    "zip": 25420,
    "lat": 39.38157,
    "lng": -78.11503,
    "state_id": "WV"
  },
  {
    "zip": 25422,
    "lat": 39.55731,
    "lng": -78.3613,
    "state_id": "WV"
  },
  {
    "zip": 25425,
    "lat": 39.29498,
    "lng": -77.78686,
    "state_id": "WV"
  },
  {
    "zip": 25427,
    "lat": 39.5213,
    "lng": -78.09121,
    "state_id": "WV"
  },
  {
    "zip": 25428,
    "lat": 39.37304,
    "lng": -78.02708,
    "state_id": "WV"
  },
  {
    "zip": 25430,
    "lat": 39.33789,
    "lng": -77.93831,
    "state_id": "WV"
  },
  {
    "zip": 25431,
    "lat": 39.48344,
    "lng": -78.56789,
    "state_id": "WV"
  },
  {
    "zip": 25432,
    "lat": 39.30579,
    "lng": -77.78432,
    "state_id": "WV"
  },
  {
    "zip": 25434,
    "lat": 39.45166,
    "lng": -78.44811,
    "state_id": "WV"
  },
  {
    "zip": 25437,
    "lat": 39.42445,
    "lng": -78.57344,
    "state_id": "WV"
  },
  {
    "zip": 25438,
    "lat": 39.31963,
    "lng": -77.86411,
    "state_id": "WV"
  },
  {
    "zip": 25442,
    "lat": 39.37021,
    "lng": -77.8326,
    "state_id": "WV"
  },
  {
    "zip": 25443,
    "lat": 39.43726,
    "lng": -77.81459,
    "state_id": "WV"
  },
  {
    "zip": 25444,
    "lat": 39.42006,
    "lng": -78.51955,
    "state_id": "WV"
  },
  {
    "zip": 25446,
    "lat": 39.24156,
    "lng": -77.9534,
    "state_id": "WV"
  },
  {
    "zip": 25501,
    "lat": 38.15716,
    "lng": -81.95885,
    "state_id": "WV"
  },
  {
    "zip": 25502,
    "lat": 38.66381,
    "lng": -82.10594,
    "state_id": "WV"
  },
  {
    "zip": 25503,
    "lat": 38.61255,
    "lng": -82.12161,
    "state_id": "WV"
  },
  {
    "zip": 25504,
    "lat": 38.37329,
    "lng": -82.26409,
    "state_id": "WV"
  },
  {
    "zip": 25505,
    "lat": 38.01192,
    "lng": -82.03505,
    "state_id": "WV"
  },
  {
    "zip": 25506,
    "lat": 38.20695,
    "lng": -82.18599,
    "state_id": "WV"
  },
  {
    "zip": 25507,
    "lat": 38.39671,
    "lng": -82.55395,
    "state_id": "WV"
  },
  {
    "zip": 25508,
    "lat": 37.96347,
    "lng": -82.02167,
    "state_id": "WV"
  },
  {
    "zip": 25510,
    "lat": 38.39335,
    "lng": -82.06642,
    "state_id": "WV"
  },
  {
    "zip": 25511,
    "lat": 38.02952,
    "lng": -82.35244,
    "state_id": "WV"
  },
  {
    "zip": 25512,
    "lat": 38.18651,
    "lng": -82.32232,
    "state_id": "WV"
  },
  {
    "zip": 25514,
    "lat": 38.10071,
    "lng": -82.5302,
    "state_id": "WV"
  },
  {
    "zip": 25515,
    "lat": 38.75267,
    "lng": -82.15357,
    "state_id": "WV"
  },
  {
    "zip": 25517,
    "lat": 38.10222,
    "lng": -82.45706,
    "state_id": "WV"
  },
  {
    "zip": 25520,
    "lat": 38.56095,
    "lng": -82.17741,
    "state_id": "WV"
  },
  {
    "zip": 25521,
    "lat": 38.21975,
    "lng": -81.99944,
    "state_id": "WV"
  },
  {
    "zip": 25523,
    "lat": 38.28058,
    "lng": -82.07362,
    "state_id": "WV"
  },
  {
    "zip": 25524,
    "lat": 38.01441,
    "lng": -82.11243,
    "state_id": "WV"
  },
  {
    "zip": 25526,
    "lat": 38.39829,
    "lng": -81.98474,
    "state_id": "WV"
  },
  {
    "zip": 25529,
    "lat": 38.15176,
    "lng": -81.84675,
    "state_id": "WV"
  },
  {
    "zip": 25530,
    "lat": 38.3548,
    "lng": -82.56376,
    "state_id": "WV"
  },
  {
    "zip": 25534,
    "lat": 38.08514,
    "lng": -82.27507,
    "state_id": "WV"
  },
  {
    "zip": 25535,
    "lat": 38.31153,
    "lng": -82.43091,
    "state_id": "WV"
  },
  {
    "zip": 25537,
    "lat": 38.52982,
    "lng": -82.27183,
    "state_id": "WV"
  },
  {
    "zip": 25540,
    "lat": 38.15911,
    "lng": -82.13972,
    "state_id": "WV"
  },
  {
    "zip": 25541,
    "lat": 38.45719,
    "lng": -82.12832,
    "state_id": "WV"
  },
  {
    "zip": 25545,
    "lat": 38.46331,
    "lng": -82.225,
    "state_id": "WV"
  },
  {
    "zip": 25547,
    "lat": 37.92816,
    "lng": -81.96052,
    "state_id": "WV"
  },
  {
    "zip": 25550,
    "lat": 38.8682,
    "lng": -82.07576,
    "state_id": "WV"
  },
  {
    "zip": 25555,
    "lat": 38.23891,
    "lng": -82.55691,
    "state_id": "WV"
  },
  {
    "zip": 25557,
    "lat": 38.09699,
    "lng": -82.16327,
    "state_id": "WV"
  },
  {
    "zip": 25559,
    "lat": 38.32687,
    "lng": -82.2317,
    "state_id": "WV"
  },
  {
    "zip": 25560,
    "lat": 38.44401,
    "lng": -81.89792,
    "state_id": "WV"
  },
  {
    "zip": 25564,
    "lat": 38.27159,
    "lng": -81.89917,
    "state_id": "WV"
  },
  {
    "zip": 25565,
    "lat": 38.10894,
    "lng": -81.96351,
    "state_id": "WV"
  },
  {
    "zip": 25567,
    "lat": 38.21875,
    "lng": -81.87585,
    "state_id": "WV"
  },
  {
    "zip": 25570,
    "lat": 38.23109,
    "lng": -82.42179,
    "state_id": "WV"
  },
  {
    "zip": 25571,
    "lat": 38.2931,
    "lng": -82.15835,
    "state_id": "WV"
  },
  {
    "zip": 25573,
    "lat": 38.22326,
    "lng": -81.94424,
    "state_id": "WV"
  },
  {
    "zip": 25601,
    "lat": 37.85903,
    "lng": -82.00464,
    "state_id": "WV"
  },
  {
    "zip": 25606,
    "lat": 37.76257,
    "lng": -81.82353,
    "state_id": "WV"
  },
  {
    "zip": 25607,
    "lat": 37.7867,
    "lng": -81.78644,
    "state_id": "WV"
  },
  {
    "zip": 25608,
    "lat": 37.57413,
    "lng": -81.88935,
    "state_id": "WV"
  },
  {
    "zip": 25611,
    "lat": 37.68965,
    "lng": -81.85317,
    "state_id": "WV"
  },
  {
    "zip": 25617,
    "lat": 37.72799,
    "lng": -81.77904,
    "state_id": "WV"
  },
  {
    "zip": 25621,
    "lat": 37.62734,
    "lng": -81.90517,
    "state_id": "WV"
  },
  {
    "zip": 25624,
    "lat": 37.90298,
    "lng": -81.97914,
    "state_id": "WV"
  },
  {
    "zip": 25625,
    "lat": 37.82764,
    "lng": -82.09475,
    "state_id": "WV"
  },
  {
    "zip": 25628,
    "lat": 37.76602,
    "lng": -81.86216,
    "state_id": "WV"
  },
  {
    "zip": 25630,
    "lat": 37.79415,
    "lng": -81.69019,
    "state_id": "WV"
  },
  {
    "zip": 25632,
    "lat": 37.75662,
    "lng": -81.92843,
    "state_id": "WV"
  },
  {
    "zip": 25634,
    "lat": 37.73097,
    "lng": -81.84809,
    "state_id": "WV"
  },
  {
    "zip": 25635,
    "lat": 37.71765,
    "lng": -81.89407,
    "state_id": "WV"
  },
  {
    "zip": 25637,
    "lat": 37.85619,
    "lng": -82.02986,
    "state_id": "WV"
  },
  {
    "zip": 25638,
    "lat": 37.73174,
    "lng": -82.01386,
    "state_id": "WV"
  },
  {
    "zip": 25639,
    "lat": 37.88917,
    "lng": -81.95942,
    "state_id": "WV"
  },
  {
    "zip": 25644,
    "lat": 37.7049,
    "lng": -82.01849,
    "state_id": "WV"
  },
  {
    "zip": 25646,
    "lat": 37.83272,
    "lng": -81.89498,
    "state_id": "WV"
  },
  {
    "zip": 25647,
    "lat": 37.78206,
    "lng": -81.98473,
    "state_id": "WV"
  },
  {
    "zip": 25649,
    "lat": 37.86631,
    "lng": -82.08183,
    "state_id": "WV"
  },
  {
    "zip": 25650,
    "lat": 37.67672,
    "lng": -81.81506,
    "state_id": "WV"
  },
  {
    "zip": 25651,
    "lat": 37.5713,
    "lng": -81.97213,
    "state_id": "WV"
  },
  {
    "zip": 25652,
    "lat": 37.78936,
    "lng": -82.05341,
    "state_id": "WV"
  },
  {
    "zip": 25653,
    "lat": 37.82969,
    "lng": -81.99838,
    "state_id": "WV"
  },
  {
    "zip": 25654,
    "lat": 37.80519,
    "lng": -81.8818,
    "state_id": "WV"
  },
  {
    "zip": 25661,
    "lat": 37.73619,
    "lng": -82.27393,
    "state_id": "WV"
  },
  {
    "zip": 25666,
    "lat": 37.92672,
    "lng": -82.24888,
    "state_id": "WV"
  },
  {
    "zip": 25669,
    "lat": 37.92928,
    "lng": -82.43239,
    "state_id": "WV"
  },
  {
    "zip": 25670,
    "lat": 37.71866,
    "lng": -82.13833,
    "state_id": "WV"
  },
  {
    "zip": 25671,
    "lat": 37.88386,
    "lng": -82.19187,
    "state_id": "WV"
  },
  {
    "zip": 25672,
    "lat": 37.58156,
    "lng": -82.11335,
    "state_id": "WV"
  },
  {
    "zip": 25674,
    "lat": 37.8689,
    "lng": -82.35158,
    "state_id": "WV"
  },
  {
    "zip": 25676,
    "lat": 37.82605,
    "lng": -82.22617,
    "state_id": "WV"
  },
  {
    "zip": 25678,
    "lat": 37.59031,
    "lng": -82.06505,
    "state_id": "WV"
  },
  {
    "zip": 25688,
    "lat": 37.62827,
    "lng": -82.13805,
    "state_id": "WV"
  },
  {
    "zip": 25690,
    "lat": 37.68964,
    "lng": -82.12434,
    "state_id": "WV"
  },
  {
    "zip": 25692,
    "lat": 37.63629,
    "lng": -82.10986,
    "state_id": "WV"
  },
  {
    "zip": 25696,
    "lat": 37.67903,
    "lng": -82.11588,
    "state_id": "WV"
  },
  {
    "zip": 25699,
    "lat": 37.96167,
    "lng": -82.33258,
    "state_id": "WV"
  },
  {
    "zip": 25701,
    "lat": 38.36871,
    "lng": -82.40768,
    "state_id": "WV"
  },
  {
    "zip": 25702,
    "lat": 38.43827,
    "lng": -82.32918,
    "state_id": "WV"
  },
  {
    "zip": 25703,
    "lat": 38.42395,
    "lng": -82.41878,
    "state_id": "WV"
  },
  {
    "zip": 25704,
    "lat": 38.35348,
    "lng": -82.50879,
    "state_id": "WV"
  },
  {
    "zip": 25705,
    "lat": 38.40561,
    "lng": -82.36193,
    "state_id": "WV"
  },
  {
    "zip": 25801,
    "lat": 37.81451,
    "lng": -81.25467,
    "state_id": "WV"
  },
  {
    "zip": 25810,
    "lat": 37.6024,
    "lng": -81.34452,
    "state_id": "WV"
  },
  {
    "zip": 25811,
    "lat": 37.56318,
    "lng": -81.28917,
    "state_id": "WV"
  },
  {
    "zip": 25812,
    "lat": 38.15571,
    "lng": -81.11248,
    "state_id": "WV"
  },
  {
    "zip": 25813,
    "lat": 37.75947,
    "lng": -81.11546,
    "state_id": "WV"
  },
  {
    "zip": 25817,
    "lat": 37.7583,
    "lng": -81.40345,
    "state_id": "WV"
  },
  {
    "zip": 25818,
    "lat": 37.86063,
    "lng": -81.1926,
    "state_id": "WV"
  },
  {
    "zip": 25820,
    "lat": 37.50764,
    "lng": -81.13945,
    "state_id": "WV"
  },
  {
    "zip": 25823,
    "lat": 37.65924,
    "lng": -81.1996,
    "state_id": "WV"
  },
  {
    "zip": 25825,
    "lat": 37.65252,
    "lng": -81.07808,
    "state_id": "WV"
  },
  {
    "zip": 25826,
    "lat": 37.57678,
    "lng": -81.35807,
    "state_id": "WV"
  },
  {
    "zip": 25827,
    "lat": 37.7385,
    "lng": -81.25356,
    "state_id": "WV"
  },
  {
    "zip": 25831,
    "lat": 37.95668,
    "lng": -80.93788,
    "state_id": "WV"
  },
  {
    "zip": 25832,
    "lat": 37.72554,
    "lng": -81.10882,
    "state_id": "WV"
  },
  {
    "zip": 25836,
    "lat": 37.77744,
    "lng": -81.27297,
    "state_id": "WV"
  },
  {
    "zip": 25837,
    "lat": 38.05363,
    "lng": -81.01767,
    "state_id": "WV"
  },
  {
    "zip": 25839,
    "lat": 37.78397,
    "lng": -81.38021,
    "state_id": "WV"
  },
  {
    "zip": 25840,
    "lat": 38.03966,
    "lng": -81.12323,
    "state_id": "WV"
  },
  {
    "zip": 25841,
    "lat": 37.55494,
    "lng": -81.08499,
    "state_id": "WV"
  },
  {
    "zip": 25843,
    "lat": 37.61241,
    "lng": -81.11996,
    "state_id": "WV"
  },
  {
    "zip": 25844,
    "lat": 37.81028,
    "lng": -81.37737,
    "state_id": "WV"
  },
  {
    "zip": 25845,
    "lat": 37.69584,
    "lng": -81.52433,
    "state_id": "WV"
  },
  {
    "zip": 25846,
    "lat": 37.91389,
    "lng": -81.14785,
    "state_id": "WV"
  },
  {
    "zip": 25848,
    "lat": 37.71655,
    "lng": -81.43516,
    "state_id": "WV"
  },
  {
    "zip": 25849,
    "lat": 37.72996,
    "lng": -81.27973,
    "state_id": "WV"
  },
  {
    "zip": 25853,
    "lat": 37.63755,
    "lng": -81.31375,
    "state_id": "WV"
  },
  {
    "zip": 25854,
    "lat": 38.13671,
    "lng": -80.98006,
    "state_id": "WV"
  },
  {
    "zip": 25855,
    "lat": 37.9363,
    "lng": -81.15607,
    "state_id": "WV"
  },
  {
    "zip": 25857,
    "lat": 37.62346,
    "lng": -81.2595,
    "state_id": "WV"
  },
  {
    "zip": 25862,
    "lat": 38.08688,
    "lng": -81.0588,
    "state_id": "WV"
  },
  {
    "zip": 25864,
    "lat": 37.87762,
    "lng": -81.02815,
    "state_id": "WV"
  },
  {
    "zip": 25865,
    "lat": 37.72516,
    "lng": -81.3489,
    "state_id": "WV"
  },
  {
    "zip": 25868,
    "lat": 38.07198,
    "lng": -80.9596,
    "state_id": "WV"
  },
  {
    "zip": 25870,
    "lat": 37.62509,
    "lng": -81.38752,
    "state_id": "WV"
  },
  {
    "zip": 25871,
    "lat": 37.76975,
    "lng": -81.21015,
    "state_id": "WV"
  },
  {
    "zip": 25873,
    "lat": 37.75151,
    "lng": -81.21522,
    "state_id": "WV"
  },
  {
    "zip": 25875,
    "lat": 37.67817,
    "lng": -81.45649,
    "state_id": "WV"
  },
  {
    "zip": 25876,
    "lat": 37.63669,
    "lng": -81.44925,
    "state_id": "WV"
  },
  {
    "zip": 25878,
    "lat": 37.71836,
    "lng": -81.2314,
    "state_id": "WV"
  },
  {
    "zip": 25879,
    "lat": 37.97965,
    "lng": -81.10541,
    "state_id": "WV"
  },
  {
    "zip": 25880,
    "lat": 37.87822,
    "lng": -81.20561,
    "state_id": "WV"
  },
  {
    "zip": 25882,
    "lat": 37.62376,
    "lng": -81.3891,
    "state_id": "WV"
  },
  {
    "zip": 25901,
    "lat": 37.94835,
    "lng": -81.11277,
    "state_id": "WV"
  },
  {
    "zip": 25902,
    "lat": 37.5727,
    "lng": -81.20826,
    "state_id": "WV"
  },
  {
    "zip": 25904,
    "lat": 37.91692,
    "lng": -81.28185,
    "state_id": "WV"
  },
  {
    "zip": 25906,
    "lat": 37.83875,
    "lng": -81.11562,
    "state_id": "WV"
  },
  {
    "zip": 25907,
    "lat": 37.85921,
    "lng": -81.07165,
    "state_id": "WV"
  },
  {
    "zip": 25908,
    "lat": 37.67243,
    "lng": -81.2561,
    "state_id": "WV"
  },
  {
    "zip": 25911,
    "lat": 37.75739,
    "lng": -81.16812,
    "state_id": "WV"
  },
  {
    "zip": 25913,
    "lat": 37.71407,
    "lng": -81.48945,
    "state_id": "WV"
  },
  {
    "zip": 25915,
    "lat": 37.59903,
    "lng": -81.2859,
    "state_id": "WV"
  },
  {
    "zip": 25916,
    "lat": 37.67559,
    "lng": -81.49803,
    "state_id": "WV"
  },
  {
    "zip": 25917,
    "lat": 37.97809,
    "lng": -81.25099,
    "state_id": "WV"
  },
  {
    "zip": 25918,
    "lat": 37.74924,
    "lng": -80.99895,
    "state_id": "WV"
  },
  {
    "zip": 25920,
    "lat": 37.68716,
    "lng": -81.33385,
    "state_id": "WV"
  },
  {
    "zip": 25921,
    "lat": 37.67008,
    "lng": -81.30864,
    "state_id": "WV"
  },
  {
    "zip": 25922,
    "lat": 37.46931,
    "lng": -81.10467,
    "state_id": "WV"
  },
  {
    "zip": 25928,
    "lat": 37.55958,
    "lng": -81.33259,
    "state_id": "WV"
  },
  {
    "zip": 25932,
    "lat": 37.75652,
    "lng": -81.30928,
    "state_id": "WV"
  },
  {
    "zip": 25936,
    "lat": 37.92488,
    "lng": -81.02809,
    "state_id": "WV"
  },
  {
    "zip": 25938,
    "lat": 38.15742,
    "lng": -81.04372,
    "state_id": "WV"
  },
  {
    "zip": 25942,
    "lat": 38.02628,
    "lng": -80.98617,
    "state_id": "WV"
  },
  {
    "zip": 25951,
    "lat": 37.6595,
    "lng": -80.86708,
    "state_id": "WV"
  },
  {
    "zip": 25958,
    "lat": 38.02152,
    "lng": -80.75079,
    "state_id": "WV"
  },
  {
    "zip": 25962,
    "lat": 37.97432,
    "lng": -80.81168,
    "state_id": "WV"
  },
  {
    "zip": 25969,
    "lat": 37.61887,
    "lng": -81.00187,
    "state_id": "WV"
  },
  {
    "zip": 25971,
    "lat": 37.47901,
    "lng": -80.9765,
    "state_id": "WV"
  },
  {
    "zip": 25972,
    "lat": 38.0413,
    "lng": -80.74158,
    "state_id": "WV"
  },
  {
    "zip": 25976,
    "lat": 37.85054,
    "lng": -80.84718,
    "state_id": "WV"
  },
  {
    "zip": 25977,
    "lat": 37.80711,
    "lng": -80.90296,
    "state_id": "WV"
  },
  {
    "zip": 25978,
    "lat": 37.62197,
    "lng": -80.94575,
    "state_id": "WV"
  },
  {
    "zip": 25979,
    "lat": 37.5174,
    "lng": -80.91948,
    "state_id": "WV"
  },
  {
    "zip": 25981,
    "lat": 38.08469,
    "lng": -80.70849,
    "state_id": "WV"
  },
  {
    "zip": 25984,
    "lat": 38.02595,
    "lng": -80.60318,
    "state_id": "WV"
  },
  {
    "zip": 25985,
    "lat": 37.77258,
    "lng": -80.80963,
    "state_id": "WV"
  },
  {
    "zip": 25989,
    "lat": 37.68278,
    "lng": -81.06999,
    "state_id": "WV"
  },
  {
    "zip": 26003,
    "lat": 40.07399,
    "lng": -80.64996,
    "state_id": "WV"
  },
  {
    "zip": 26030,
    "lat": 40.22631,
    "lng": -80.65581,
    "state_id": "WV"
  },
  {
    "zip": 26031,
    "lat": 40.00838,
    "lng": -80.72245,
    "state_id": "WV"
  },
  {
    "zip": 26032,
    "lat": 40.19612,
    "lng": -80.54318,
    "state_id": "WV"
  },
  {
    "zip": 26033,
    "lat": 39.83105,
    "lng": -80.5774,
    "state_id": "WV"
  },
  {
    "zip": 26034,
    "lat": 40.59398,
    "lng": -80.55256,
    "state_id": "WV"
  },
  {
    "zip": 26035,
    "lat": 40.34829,
    "lng": -80.55516,
    "state_id": "WV"
  },
  {
    "zip": 26036,
    "lat": 39.98484,
    "lng": -80.54475,
    "state_id": "WV"
  },
  {
    "zip": 26037,
    "lat": 40.33434,
    "lng": -80.58475,
    "state_id": "WV"
  },
  {
    "zip": 26038,
    "lat": 39.96473,
    "lng": -80.72373,
    "state_id": "WV"
  },
  {
    "zip": 26039,
    "lat": 39.81372,
    "lng": -80.67119,
    "state_id": "WV"
  },
  {
    "zip": 26040,
    "lat": 39.98752,
    "lng": -80.7216,
    "state_id": "WV"
  },
  {
    "zip": 26041,
    "lat": 39.89944,
    "lng": -80.70824,
    "state_id": "WV"
  },
  {
    "zip": 26047,
    "lat": 40.53129,
    "lng": -80.58299,
    "state_id": "WV"
  },
  {
    "zip": 26050,
    "lat": 40.60047,
    "lng": -80.62592,
    "state_id": "WV"
  },
  {
    "zip": 26055,
    "lat": 39.75481,
    "lng": -80.77544,
    "state_id": "WV"
  },
  {
    "zip": 26056,
    "lat": 40.53123,
    "lng": -80.57744,
    "state_id": "WV"
  },
  {
    "zip": 26059,
    "lat": 40.07129,
    "lng": -80.59115,
    "state_id": "WV"
  },
  {
    "zip": 26060,
    "lat": 40.10477,
    "lng": -80.54758,
    "state_id": "WV"
  },
  {
    "zip": 26062,
    "lat": 40.42316,
    "lng": -80.56115,
    "state_id": "WV"
  },
  {
    "zip": 26070,
    "lat": 40.2518,
    "lng": -80.58403,
    "state_id": "WV"
  },
  {
    "zip": 26074,
    "lat": 40.16163,
    "lng": -80.59258,
    "state_id": "WV"
  },
  {
    "zip": 26075,
    "lat": 40.19497,
    "lng": -80.65901,
    "state_id": "WV"
  },
  {
    "zip": 26101,
    "lat": 39.23903,
    "lng": -81.5762,
    "state_id": "WV"
  },
  {
    "zip": 26104,
    "lat": 39.27568,
    "lng": -81.47855,
    "state_id": "WV"
  },
  {
    "zip": 26105,
    "lat": 39.32992,
    "lng": -81.52135,
    "state_id": "WV"
  },
  {
    "zip": 26133,
    "lat": 39.12255,
    "lng": -81.67231,
    "state_id": "WV"
  },
  {
    "zip": 26134,
    "lat": 39.36798,
    "lng": -81.2876,
    "state_id": "WV"
  },
  {
    "zip": 26136,
    "lat": 38.9663,
    "lng": -81.13614,
    "state_id": "WV"
  },
  {
    "zip": 26137,
    "lat": 39.00517,
    "lng": -81.04462,
    "state_id": "WV"
  },
  {
    "zip": 26138,
    "lat": 39.02869,
    "lng": -81.20432,
    "state_id": "WV"
  },
  {
    "zip": 26141,
    "lat": 38.92113,
    "lng": -81.25213,
    "state_id": "WV"
  },
  {
    "zip": 26142,
    "lat": 39.21053,
    "lng": -81.44742,
    "state_id": "WV"
  },
  {
    "zip": 26143,
    "lat": 39.05665,
    "lng": -81.37044,
    "state_id": "WV"
  },
  {
    "zip": 26146,
    "lat": 39.45283,
    "lng": -81.04397,
    "state_id": "WV"
  },
  {
    "zip": 26147,
    "lat": 38.90661,
    "lng": -81.07294,
    "state_id": "WV"
  },
  {
    "zip": 26148,
    "lat": 39.06903,
    "lng": -81.18634,
    "state_id": "WV"
  },
  {
    "zip": 26149,
    "lat": 39.46525,
    "lng": -80.89183,
    "state_id": "WV"
  },
  {
    "zip": 26150,
    "lat": 39.15676,
    "lng": -81.53654,
    "state_id": "WV"
  },
  {
    "zip": 26151,
    "lat": 38.88326,
    "lng": -81.17296,
    "state_id": "WV"
  },
  {
    "zip": 26152,
    "lat": 39.01006,
    "lng": -81.19587,
    "state_id": "WV"
  },
  {
    "zip": 26155,
    "lat": 39.63218,
    "lng": -80.76541,
    "state_id": "WV"
  },
  {
    "zip": 26159,
    "lat": 39.6021,
    "lng": -80.92757,
    "state_id": "WV"
  },
  {
    "zip": 26160,
    "lat": 38.97842,
    "lng": -81.41283,
    "state_id": "WV"
  },
  {
    "zip": 26161,
    "lat": 39.1726,
    "lng": -81.25368,
    "state_id": "WV"
  },
  {
    "zip": 26164,
    "lat": 38.98877,
    "lng": -81.71709,
    "state_id": "WV"
  },
  {
    "zip": 26167,
    "lat": 39.55724,
    "lng": -80.73499,
    "state_id": "WV"
  },
  {
    "zip": 26169,
    "lat": 39.07254,
    "lng": -81.57589,
    "state_id": "WV"
  },
  {
    "zip": 26170,
    "lat": 39.35963,
    "lng": -81.17228,
    "state_id": "WV"
  },
  {
    "zip": 26175,
    "lat": 39.53905,
    "lng": -80.9646,
    "state_id": "WV"
  },
  {
    "zip": 26178,
    "lat": 39.05891,
    "lng": -81.03815,
    "state_id": "WV"
  },
  {
    "zip": 26180,
    "lat": 39.20191,
    "lng": -81.3658,
    "state_id": "WV"
  },
  {
    "zip": 26181,
    "lat": 39.20644,
    "lng": -81.67222,
    "state_id": "WV"
  },
  {
    "zip": 26184,
    "lat": 39.29803,
    "lng": -81.35135,
    "state_id": "WV"
  },
  {
    "zip": 26187,
    "lat": 39.36857,
    "lng": -81.44836,
    "state_id": "WV"
  },
  {
    "zip": 26201,
    "lat": 39.00049,
    "lng": -80.20388,
    "state_id": "WV"
  },
  {
    "zip": 26202,
    "lat": 38.21812,
    "lng": -80.61906,
    "state_id": "WV"
  },
  {
    "zip": 26203,
    "lat": 38.54088,
    "lng": -80.58269,
    "state_id": "WV"
  },
  {
    "zip": 26205,
    "lat": 38.32663,
    "lng": -80.65023,
    "state_id": "WV"
  },
  {
    "zip": 26206,
    "lat": 38.43952,
    "lng": -80.53941,
    "state_id": "WV"
  },
  {
    "zip": 26208,
    "lat": 38.33758,
    "lng": -80.49038,
    "state_id": "WV"
  },
  {
    "zip": 26209,
    "lat": 38.43389,
    "lng": -79.99538,
    "state_id": "WV"
  },
  {
    "zip": 26215,
    "lat": 38.7133,
    "lng": -80.38794,
    "state_id": "WV"
  },
  {
    "zip": 26217,
    "lat": 38.60756,
    "lng": -80.4562,
    "state_id": "WV"
  },
  {
    "zip": 26218,
    "lat": 38.84498,
    "lng": -80.26628,
    "state_id": "WV"
  },
  {
    "zip": 26222,
    "lat": 38.65492,
    "lng": -80.36492,
    "state_id": "WV"
  },
  {
    "zip": 26224,
    "lat": 38.74032,
    "lng": -80.18293,
    "state_id": "WV"
  },
  {
    "zip": 26228,
    "lat": 38.75442,
    "lng": -80.37091,
    "state_id": "WV"
  },
  {
    "zip": 26230,
    "lat": 38.65382,
    "lng": -80.19757,
    "state_id": "WV"
  },
  {
    "zip": 26234,
    "lat": 38.7738,
    "lng": -80.3151,
    "state_id": "WV"
  },
  {
    "zip": 26236,
    "lat": 38.74811,
    "lng": -80.22295,
    "state_id": "WV"
  },
  {
    "zip": 26237,
    "lat": 38.84545,
    "lng": -80.15069,
    "state_id": "WV"
  },
  {
    "zip": 26238,
    "lat": 39.10308,
    "lng": -80.15939,
    "state_id": "WV"
  },
  {
    "zip": 26241,
    "lat": 38.92947,
    "lng": -79.78707,
    "state_id": "WV"
  },
  {
    "zip": 26250,
    "lat": 39.03087,
    "lng": -79.96234,
    "state_id": "WV"
  },
  {
    "zip": 26253,
    "lat": 38.79388,
    "lng": -79.85933,
    "state_id": "WV"
  },
  {
    "zip": 26254,
    "lat": 38.89437,
    "lng": -79.64762,
    "state_id": "WV"
  },
  {
    "zip": 26257,
    "lat": 38.91919,
    "lng": -80.00361,
    "state_id": "WV"
  },
  {
    "zip": 26259,
    "lat": 38.80274,
    "lng": -79.89611,
    "state_id": "WV"
  },
  {
    "zip": 26260,
    "lat": 39.07893,
    "lng": -79.41767,
    "state_id": "WV"
  },
  {
    "zip": 26261,
    "lat": 38.20361,
    "lng": -80.5436,
    "state_id": "WV"
  },
  {
    "zip": 26263,
    "lat": 38.94899,
    "lng": -79.43727,
    "state_id": "WV"
  },
  {
    "zip": 26264,
    "lat": 38.57581,
    "lng": -79.82698,
    "state_id": "WV"
  },
  {
    "zip": 26266,
    "lat": 38.4193,
    "lng": -80.48969,
    "state_id": "WV"
  },
  {
    "zip": 26267,
    "lat": 38.91469,
    "lng": -80.06517,
    "state_id": "WV"
  },
  {
    "zip": 26268,
    "lat": 38.74898,
    "lng": -79.7389,
    "state_id": "WV"
  },
  {
    "zip": 26269,
    "lat": 39.11128,
    "lng": -79.61795,
    "state_id": "WV"
  },
  {
    "zip": 26270,
    "lat": 38.86342,
    "lng": -79.57775,
    "state_id": "WV"
  },
  {
    "zip": 26271,
    "lat": 39.03292,
    "lng": -79.58107,
    "state_id": "WV"
  },
  {
    "zip": 26273,
    "lat": 38.63515,
    "lng": -79.95558,
    "state_id": "WV"
  },
  {
    "zip": 26275,
    "lat": 38.9768,
    "lng": -79.95058,
    "state_id": "WV"
  },
  {
    "zip": 26276,
    "lat": 39.01765,
    "lng": -79.7637,
    "state_id": "WV"
  },
  {
    "zip": 26278,
    "lat": 38.82539,
    "lng": -80.03046,
    "state_id": "WV"
  },
  {
    "zip": 26280,
    "lat": 38.72958,
    "lng": -80.02958,
    "state_id": "WV"
  },
  {
    "zip": 26282,
    "lat": 38.52177,
    "lng": -80.16247,
    "state_id": "WV"
  },
  {
    "zip": 26283,
    "lat": 39.06386,
    "lng": -79.81142,
    "state_id": "WV"
  },
  {
    "zip": 26285,
    "lat": 38.91296,
    "lng": -79.93749,
    "state_id": "WV"
  },
  {
    "zip": 26287,
    "lat": 39.16583,
    "lng": -79.69037,
    "state_id": "WV"
  },
  {
    "zip": 26288,
    "lat": 38.4968,
    "lng": -80.3631,
    "state_id": "WV"
  },
  {
    "zip": 26291,
    "lat": 38.39467,
    "lng": -80.15438,
    "state_id": "WV"
  },
  {
    "zip": 26292,
    "lat": 39.15018,
    "lng": -79.52118,
    "state_id": "WV"
  },
  {
    "zip": 26293,
    "lat": 38.78246,
    "lng": -79.93577,
    "state_id": "WV"
  },
  {
    "zip": 26294,
    "lat": 38.53145,
    "lng": -80.03429,
    "state_id": "WV"
  },
  {
    "zip": 26296,
    "lat": 38.75153,
    "lng": -79.58189,
    "state_id": "WV"
  },
  {
    "zip": 26298,
    "lat": 38.47487,
    "lng": -80.25416,
    "state_id": "WV"
  },
  {
    "zip": 26301,
    "lat": 39.28976,
    "lng": -80.37938,
    "state_id": "WV"
  },
  {
    "zip": 26320,
    "lat": 39.42285,
    "lng": -80.8226,
    "state_id": "WV"
  },
  {
    "zip": 26321,
    "lat": 39.05152,
    "lng": -80.6827,
    "state_id": "WV"
  },
  {
    "zip": 26323,
    "lat": 39.26278,
    "lng": -80.29056,
    "state_id": "WV"
  },
  {
    "zip": 26325,
    "lat": 39.09888,
    "lng": -80.88036,
    "state_id": "WV"
  },
  {
    "zip": 26327,
    "lat": 39.11964,
    "lng": -80.93878,
    "state_id": "WV"
  },
  {
    "zip": 26330,
    "lat": 39.28657,
    "lng": -80.22099,
    "state_id": "WV"
  },
  {
    "zip": 26335,
    "lat": 38.84969,
    "lng": -80.67304,
    "state_id": "WV"
  },
  {
    "zip": 26337,
    "lat": 39.23792,
    "lng": -81.16266,
    "state_id": "WV"
  },
  {
    "zip": 26338,
    "lat": 39.08197,
    "lng": -80.61266,
    "state_id": "WV"
  },
  {
    "zip": 26339,
    "lat": 39.41965,
    "lng": -80.61062,
    "state_id": "WV"
  },
  {
    "zip": 26342,
    "lat": 39.03006,
    "lng": -80.84975,
    "state_id": "WV"
  },
  {
    "zip": 26343,
    "lat": 38.83113,
    "lng": -80.40475,
    "state_id": "WV"
  },
  {
    "zip": 26346,
    "lat": 39.29691,
    "lng": -81.06213,
    "state_id": "WV"
  },
  {
    "zip": 26347,
    "lat": 39.25656,
    "lng": -80.12613,
    "state_id": "WV"
  },
  {
    "zip": 26348,
    "lat": 39.45826,
    "lng": -80.56711,
    "state_id": "WV"
  },
  {
    "zip": 26349,
    "lat": 39.2357,
    "lng": -80.13016,
    "state_id": "WV"
  },
  {
    "zip": 26351,
    "lat": 38.93372,
    "lng": -80.8536,
    "state_id": "WV"
  },
  {
    "zip": 26354,
    "lat": 39.34663,
    "lng": -80.03047,
    "state_id": "WV"
  },
  {
    "zip": 26361,
    "lat": 39.36662,
    "lng": -80.3175,
    "state_id": "WV"
  },
  {
    "zip": 26362,
    "lat": 39.14968,
    "lng": -81.05944,
    "state_id": "WV"
  },
  {
    "zip": 26366,
    "lat": 39.3812,
    "lng": -80.33459,
    "state_id": "WV"
  },
  {
    "zip": 26369,
    "lat": 39.33403,
    "lng": -80.33176,
    "state_id": "WV"
  },
  {
    "zip": 26372,
    "lat": 38.9586,
    "lng": -80.37348,
    "state_id": "WV"
  },
  {
    "zip": 26374,
    "lat": 39.45058,
    "lng": -79.87894,
    "state_id": "WV"
  },
  {
    "zip": 26376,
    "lat": 38.77625,
    "lng": -80.45667,
    "state_id": "WV"
  },
  {
    "zip": 26377,
    "lat": 39.4924,
    "lng": -80.63879,
    "state_id": "WV"
  },
  {
    "zip": 26378,
    "lat": 39.12155,
    "lng": -80.43717,
    "state_id": "WV"
  },
  {
    "zip": 26384,
    "lat": 38.97585,
    "lng": -80.71386,
    "state_id": "WV"
  },
  {
    "zip": 26385,
    "lat": 39.15932,
    "lng": -80.35533,
    "state_id": "WV"
  },
  {
    "zip": 26386,
    "lat": 39.39209,
    "lng": -80.40102,
    "state_id": "WV"
  },
  {
    "zip": 26404,
    "lat": 39.33475,
    "lng": -80.30928,
    "state_id": "WV"
  },
  {
    "zip": 26405,
    "lat": 39.22336,
    "lng": -79.9017,
    "state_id": "WV"
  },
  {
    "zip": 26408,
    "lat": 39.20336,
    "lng": -80.29787,
    "state_id": "WV"
  },
  {
    "zip": 26410,
    "lat": 39.40412,
    "lng": -79.8254,
    "state_id": "WV"
  },
  {
    "zip": 26411,
    "lat": 39.1741,
    "lng": -80.70412,
    "state_id": "WV"
  },
  {
    "zip": 26412,
    "lat": 38.88624,
    "lng": -80.58488,
    "state_id": "WV"
  },
  {
    "zip": 26415,
    "lat": 39.29706,
    "lng": -80.93771,
    "state_id": "WV"
  },
  {
    "zip": 26416,
    "lat": 39.1617,
    "lng": -80.02163,
    "state_id": "WV"
  },
  {
    "zip": 26419,
    "lat": 39.55351,
    "lng": -80.66513,
    "state_id": "WV"
  },
  {
    "zip": 26421,
    "lat": 39.18432,
    "lng": -80.91595,
    "state_id": "WV"
  },
  {
    "zip": 26422,
    "lat": 39.29458,
    "lng": -80.44379,
    "state_id": "WV"
  },
  {
    "zip": 26424,
    "lat": 39.26688,
    "lng": -80.17361,
    "state_id": "WV"
  },
  {
    "zip": 26425,
    "lat": 39.32046,
    "lng": -79.69275,
    "state_id": "WV"
  },
  {
    "zip": 26426,
    "lat": 39.28724,
    "lng": -80.56387,
    "state_id": "WV"
  },
  {
    "zip": 26430,
    "lat": 38.87615,
    "lng": -80.75532,
    "state_id": "WV"
  },
  {
    "zip": 26431,
    "lat": 39.39873,
    "lng": -80.29536,
    "state_id": "WV"
  },
  {
    "zip": 26435,
    "lat": 39.26665,
    "lng": -80.08944,
    "state_id": "WV"
  },
  {
    "zip": 26436,
    "lat": 39.29643,
    "lng": -80.72352,
    "state_id": "WV"
  },
  {
    "zip": 26437,
    "lat": 39.52648,
    "lng": -80.51813,
    "state_id": "WV"
  },
  {
    "zip": 26438,
    "lat": 39.34647,
    "lng": -80.31908,
    "state_id": "WV"
  },
  {
    "zip": 26440,
    "lat": 39.32595,
    "lng": -79.9039,
    "state_id": "WV"
  },
  {
    "zip": 26443,
    "lat": 39.07016,
    "lng": -80.75828,
    "state_id": "WV"
  },
  {
    "zip": 26444,
    "lat": 39.36218,
    "lng": -79.76808,
    "state_id": "WV"
  },
  {
    "zip": 26447,
    "lat": 38.90951,
    "lng": -80.48569,
    "state_id": "WV"
  },
  {
    "zip": 26448,
    "lat": 39.4068,
    "lng": -80.49337,
    "state_id": "WV"
  },
  {
    "zip": 26451,
    "lat": 39.20772,
    "lng": -80.40226,
    "state_id": "WV"
  },
  {
    "zip": 26452,
    "lat": 39.0372,
    "lng": -80.514,
    "state_id": "WV"
  },
  {
    "zip": 26456,
    "lat": 39.27989,
    "lng": -80.77338,
    "state_id": "WV"
  },
  {
    "zip": 26501,
    "lat": 39.63014,
    "lng": -80.04138,
    "state_id": "WV"
  },
  {
    "zip": 26505,
    "lat": 39.65045,
    "lng": -79.94408,
    "state_id": "WV"
  },
  {
    "zip": 26508,
    "lat": 39.60051,
    "lng": -79.89596,
    "state_id": "WV"
  },
  {
    "zip": 26519,
    "lat": 39.55198,
    "lng": -79.63508,
    "state_id": "WV"
  },
  {
    "zip": 26520,
    "lat": 39.49702,
    "lng": -79.82364,
    "state_id": "WV"
  },
  {
    "zip": 26521,
    "lat": 39.71428,
    "lng": -80.22637,
    "state_id": "WV"
  },
  {
    "zip": 26525,
    "lat": 39.65574,
    "lng": -79.61743,
    "state_id": "WV"
  },
  {
    "zip": 26534,
    "lat": 39.64778,
    "lng": -79.99727,
    "state_id": "WV"
  },
  {
    "zip": 26537,
    "lat": 39.48736,
    "lng": -79.71237,
    "state_id": "WV"
  },
  {
    "zip": 26541,
    "lat": 39.68449,
    "lng": -80.07453,
    "state_id": "WV"
  },
  {
    "zip": 26542,
    "lat": 39.57312,
    "lng": -79.79061,
    "state_id": "WV"
  },
  {
    "zip": 26543,
    "lat": 39.66535,
    "lng": -80.00229,
    "state_id": "WV"
  },
  {
    "zip": 26547,
    "lat": 39.51269,
    "lng": -79.812,
    "state_id": "WV"
  },
  {
    "zip": 26554,
    "lat": 39.4668,
    "lng": -80.11485,
    "state_id": "WV"
  },
  {
    "zip": 26559,
    "lat": 39.50132,
    "lng": -80.16821,
    "state_id": "WV"
  },
  {
    "zip": 26560,
    "lat": 39.54143,
    "lng": -80.14517,
    "state_id": "WV"
  },
  {
    "zip": 26562,
    "lat": 39.63972,
    "lng": -80.45546,
    "state_id": "WV"
  },
  {
    "zip": 26563,
    "lat": 39.48226,
    "lng": -80.27428,
    "state_id": "WV"
  },
  {
    "zip": 26568,
    "lat": 39.42485,
    "lng": -80.27767,
    "state_id": "WV"
  },
  {
    "zip": 26570,
    "lat": 39.64157,
    "lng": -80.22826,
    "state_id": "WV"
  },
  {
    "zip": 26571,
    "lat": 39.51903,
    "lng": -80.25541,
    "state_id": "WV"
  },
  {
    "zip": 26572,
    "lat": 39.48519,
    "lng": -80.30769,
    "state_id": "WV"
  },
  {
    "zip": 26574,
    "lat": 39.58108,
    "lng": -80.18659,
    "state_id": "WV"
  },
  {
    "zip": 26575,
    "lat": 39.68203,
    "lng": -80.45009,
    "state_id": "WV"
  },
  {
    "zip": 26576,
    "lat": 39.49303,
    "lng": -80.25716,
    "state_id": "WV"
  },
  {
    "zip": 26581,
    "lat": 39.6651,
    "lng": -80.56753,
    "state_id": "WV"
  },
  {
    "zip": 26582,
    "lat": 39.52976,
    "lng": -80.38166,
    "state_id": "WV"
  },
  {
    "zip": 26585,
    "lat": 39.6149,
    "lng": -80.42928,
    "state_id": "WV"
  },
  {
    "zip": 26586,
    "lat": 39.5231,
    "lng": -80.10299,
    "state_id": "WV"
  },
  {
    "zip": 26587,
    "lat": 39.51769,
    "lng": -80.29504,
    "state_id": "WV"
  },
  {
    "zip": 26588,
    "lat": 39.57268,
    "lng": -80.14078,
    "state_id": "WV"
  },
  {
    "zip": 26590,
    "lat": 39.67353,
    "lng": -80.31816,
    "state_id": "WV"
  },
  {
    "zip": 26591,
    "lat": 39.45852,
    "lng": -80.28941,
    "state_id": "WV"
  },
  {
    "zip": 26601,
    "lat": 38.62919,
    "lng": -80.6564,
    "state_id": "WV"
  },
  {
    "zip": 26610,
    "lat": 38.48769,
    "lng": -80.7481,
    "state_id": "WV"
  },
  {
    "zip": 26611,
    "lat": 38.84514,
    "lng": -80.83229,
    "state_id": "WV"
  },
  {
    "zip": 26615,
    "lat": 38.82238,
    "lng": -80.72414,
    "state_id": "WV"
  },
  {
    "zip": 26617,
    "lat": 38.49617,
    "lng": -80.83622,
    "state_id": "WV"
  },
  {
    "zip": 26619,
    "lat": 38.77348,
    "lng": -80.73754,
    "state_id": "WV"
  },
  {
    "zip": 26621,
    "lat": 38.71617,
    "lng": -80.54675,
    "state_id": "WV"
  },
  {
    "zip": 26623,
    "lat": 38.65217,
    "lng": -80.87742,
    "state_id": "WV"
  },
  {
    "zip": 26624,
    "lat": 38.71811,
    "lng": -80.80468,
    "state_id": "WV"
  },
  {
    "zip": 26627,
    "lat": 38.75354,
    "lng": -80.60122,
    "state_id": "WV"
  },
  {
    "zip": 26629,
    "lat": 38.56537,
    "lng": -80.70658,
    "state_id": "WV"
  },
  {
    "zip": 26631,
    "lat": 38.79593,
    "lng": -80.54734,
    "state_id": "WV"
  },
  {
    "zip": 26636,
    "lat": 38.74848,
    "lng": -80.9468,
    "state_id": "WV"
  },
  {
    "zip": 26638,
    "lat": 38.76169,
    "lng": -81.00445,
    "state_id": "WV"
  },
  {
    "zip": 26651,
    "lat": 38.3243,
    "lng": -80.87245,
    "state_id": "WV"
  },
  {
    "zip": 26656,
    "lat": 38.26456,
    "lng": -81.16352,
    "state_id": "WV"
  },
  {
    "zip": 26660,
    "lat": 38.35751,
    "lng": -80.69975,
    "state_id": "WV"
  },
  {
    "zip": 26662,
    "lat": 38.26325,
    "lng": -80.75478,
    "state_id": "WV"
  },
  {
    "zip": 26676,
    "lat": 38.16191,
    "lng": -80.65974,
    "state_id": "WV"
  },
  {
    "zip": 26678,
    "lat": 38.1685,
    "lng": -80.91073,
    "state_id": "WV"
  },
  {
    "zip": 26679,
    "lat": 38.16527,
    "lng": -80.79764,
    "state_id": "WV"
  },
  {
    "zip": 26680,
    "lat": 38.09522,
    "lng": -80.86939,
    "state_id": "WV"
  },
  {
    "zip": 26681,
    "lat": 38.22732,
    "lng": -80.71023,
    "state_id": "WV"
  },
  {
    "zip": 26684,
    "lat": 38.16213,
    "lng": -80.84585,
    "state_id": "WV"
  },
  {
    "zip": 26690,
    "lat": 38.22553,
    "lng": -81.12725,
    "state_id": "WV"
  },
  {
    "zip": 26691,
    "lat": 38.4045,
    "lng": -80.66673,
    "state_id": "WV"
  },
  {
    "zip": 26704,
    "lat": 39.29248,
    "lng": -78.5905,
    "state_id": "WV"
  },
  {
    "zip": 26705,
    "lat": 39.31813,
    "lng": -79.57635,
    "state_id": "WV"
  },
  {
    "zip": 26707,
    "lat": 39.22442,
    "lng": -79.39584,
    "state_id": "WV"
  },
  {
    "zip": 26710,
    "lat": 39.30719,
    "lng": -78.94228,
    "state_id": "WV"
  },
  {
    "zip": 26711,
    "lat": 39.2785,
    "lng": -78.47677,
    "state_id": "WV"
  },
  {
    "zip": 26714,
    "lat": 39.18418,
    "lng": -78.63638,
    "state_id": "WV"
  },
  {
    "zip": 26716,
    "lat": 39.26122,
    "lng": -79.52098,
    "state_id": "WV"
  },
  {
    "zip": 26717,
    "lat": 39.34297,
    "lng": -79.17426,
    "state_id": "WV"
  },
  {
    "zip": 26719,
    "lat": 39.48347,
    "lng": -78.77373,
    "state_id": "WV"
  },
  {
    "zip": 26720,
    "lat": 39.28032,
    "lng": -79.32957,
    "state_id": "WV"
  },
  {
    "zip": 26722,
    "lat": 39.50267,
    "lng": -78.6425,
    "state_id": "WV"
  },
  {
    "zip": 26726,
    "lat": 39.40136,
    "lng": -78.99087,
    "state_id": "WV"
  },
  {
    "zip": 26731,
    "lat": 39.16139,
    "lng": -79.07472,
    "state_id": "WV"
  },
  {
    "zip": 26739,
    "lat": 39.2428,
    "lng": -79.2557,
    "state_id": "WV"
  },
  {
    "zip": 26743,
    "lat": 39.28867,
    "lng": -79.0768,
    "state_id": "WV"
  },
  {
    "zip": 26750,
    "lat": 39.47614,
    "lng": -79.04388,
    "state_id": "WV"
  },
  {
    "zip": 26753,
    "lat": 39.56357,
    "lng": -78.78879,
    "state_id": "WV"
  },
  {
    "zip": 26755,
    "lat": 39.15209,
    "lng": -78.72752,
    "state_id": "WV"
  },
  {
    "zip": 26757,
    "lat": 39.31566,
    "lng": -78.74649,
    "state_id": "WV"
  },
  {
    "zip": 26761,
    "lat": 39.29323,
    "lng": -78.68703,
    "state_id": "WV"
  },
  {
    "zip": 26763,
    "lat": 39.47011,
    "lng": -78.69746,
    "state_id": "WV"
  },
  {
    "zip": 26764,
    "lat": 39.45267,
    "lng": -79.55504,
    "state_id": "WV"
  },
  {
    "zip": 26767,
    "lat": 39.61582,
    "lng": -78.7574,
    "state_id": "WV"
  },
  {
    "zip": 26801,
    "lat": 39.05028,
    "lng": -78.80364,
    "state_id": "WV"
  },
  {
    "zip": 26802,
    "lat": 38.62752,
    "lng": -79.19655,
    "state_id": "WV"
  },
  {
    "zip": 26804,
    "lat": 38.63645,
    "lng": -79.53943,
    "state_id": "WV"
  },
  {
    "zip": 26807,
    "lat": 38.65569,
    "lng": -79.35391,
    "state_id": "WV"
  },
  {
    "zip": 26808,
    "lat": 39.20373,
    "lng": -78.45699,
    "state_id": "WV"
  },
  {
    "zip": 26810,
    "lat": 38.98119,
    "lng": -78.74476,
    "state_id": "WV"
  },
  {
    "zip": 26812,
    "lat": 38.88169,
    "lng": -78.87695,
    "state_id": "WV"
  },
  {
    "zip": 26814,
    "lat": 38.76757,
    "lng": -79.47144,
    "state_id": "WV"
  },
  {
    "zip": 26815,
    "lat": 38.48905,
    "lng": -79.35935,
    "state_id": "WV"
  },
  {
    "zip": 26817,
    "lat": 39.36039,
    "lng": -78.39166,
    "state_id": "WV"
  },
  {
    "zip": 26818,
    "lat": 39.07495,
    "lng": -79.03425,
    "state_id": "WV"
  },
  {
    "zip": 26823,
    "lat": 39.13662,
    "lng": -78.4821,
    "state_id": "WV"
  },
  {
    "zip": 26833,
    "lat": 39.09295,
    "lng": -79.22064,
    "state_id": "WV"
  },
  {
    "zip": 26836,
    "lat": 38.99078,
    "lng": -78.97451,
    "state_id": "WV"
  },
  {
    "zip": 26845,
    "lat": 39.16195,
    "lng": -78.96532,
    "state_id": "WV"
  },
  {
    "zip": 26847,
    "lat": 38.953,
    "lng": -79.13655,
    "state_id": "WV"
  },
  {
    "zip": 26851,
    "lat": 39.05166,
    "lng": -78.62801,
    "state_id": "WV"
  },
  {
    "zip": 26852,
    "lat": 39.24798,
    "lng": -78.90077,
    "state_id": "WV"
  },
  {
    "zip": 26855,
    "lat": 38.96122,
    "lng": -79.26274,
    "state_id": "WV"
  },
  {
    "zip": 26865,
    "lat": 39.19587,
    "lng": -78.50864,
    "state_id": "WV"
  },
  {
    "zip": 26866,
    "lat": 38.79398,
    "lng": -79.23698,
    "state_id": "WV"
  },
  {
    "zip": 26884,
    "lat": 38.85834,
    "lng": -79.39256,
    "state_id": "WV"
  },
  {
    "zip": 82001,
    "lat": 41.09143,
    "lng": -104.93442,
    "state_id": "WY"
  },
  {
    "zip": 82005,
    "lat": 41.14781,
    "lng": -104.8645,
    "state_id": "WY"
  },
  {
    "zip": 82007,
    "lat": 41.06472,
    "lng": -104.74951,
    "state_id": "WY"
  },
  {
    "zip": 82009,
    "lat": 41.3782,
    "lng": -104.84869,
    "state_id": "WY"
  },
  {
    "zip": 82050,
    "lat": 41.45315,
    "lng": -104.1898,
    "state_id": "WY"
  },
  {
    "zip": 82051,
    "lat": 41.57352,
    "lng": -105.6112,
    "state_id": "WY"
  },
  {
    "zip": 82052,
    "lat": 41.11856,
    "lng": -105.30861,
    "state_id": "WY"
  },
  {
    "zip": 82053,
    "lat": 41.25087,
    "lng": -104.35077,
    "state_id": "WY"
  },
  {
    "zip": 82054,
    "lat": 41.06376,
    "lng": -104.36061,
    "state_id": "WY"
  },
  {
    "zip": 82055,
    "lat": 41.33767,
    "lng": -106.16839,
    "state_id": "WY"
  },
  {
    "zip": 82058,
    "lat": 42.09055,
    "lng": -105.59802,
    "state_id": "WY"
  },
  {
    "zip": 82059,
    "lat": 41.05729,
    "lng": -105.17729,
    "state_id": "WY"
  },
  {
    "zip": 82060,
    "lat": 41.23547,
    "lng": -104.50147,
    "state_id": "WY"
  },
  {
    "zip": 82061,
    "lat": 41.44497,
    "lng": -105.14912,
    "state_id": "WY"
  },
  {
    "zip": 82063,
    "lat": 40.88767,
    "lng": -105.9845,
    "state_id": "WY"
  },
  {
    "zip": 82070,
    "lat": 41.20539,
    "lng": -105.83205,
    "state_id": "WY"
  },
  {
    "zip": 82072,
    "lat": 41.42474,
    "lng": -105.47812,
    "state_id": "WY"
  },
  {
    "zip": 82073,
    "lat": 41.35677,
    "lng": -105.58757,
    "state_id": "WY"
  },
  {
    "zip": 82081,
    "lat": 41.52211,
    "lng": -104.41725,
    "state_id": "WY"
  },
  {
    "zip": 82082,
    "lat": 41.23335,
    "lng": -104.12182,
    "state_id": "WY"
  },
  {
    "zip": 82083,
    "lat": 41.65941,
    "lng": -106.08568,
    "state_id": "WY"
  },
  {
    "zip": 82084,
    "lat": 41.0361,
    "lng": -105.54661,
    "state_id": "WY"
  },
  {
    "zip": 82190,
    "lat": 44.5744,
    "lng": -110.51808,
    "state_id": "WY"
  },
  {
    "zip": 82201,
    "lat": 42.01851,
    "lng": -105.14688,
    "state_id": "WY"
  },
  {
    "zip": 82210,
    "lat": 41.74661,
    "lng": -104.83081,
    "state_id": "WY"
  },
  {
    "zip": 82212,
    "lat": 42.28847,
    "lng": -104.52488,
    "state_id": "WY"
  },
  {
    "zip": 82213,
    "lat": 42.49127,
    "lng": -104.99113,
    "state_id": "WY"
  },
  {
    "zip": 82214,
    "lat": 42.26072,
    "lng": -104.75951,
    "state_id": "WY"
  },
  {
    "zip": 82215,
    "lat": 42.38176,
    "lng": -104.76064,
    "state_id": "WY"
  },
  {
    "zip": 82217,
    "lat": 41.76566,
    "lng": -104.1701,
    "state_id": "WY"
  },
  {
    "zip": 82219,
    "lat": 42.49875,
    "lng": -104.51339,
    "state_id": "WY"
  },
  {
    "zip": 82221,
    "lat": 41.63837,
    "lng": -104.34292,
    "state_id": "WY"
  },
  {
    "zip": 82222,
    "lat": 43.20147,
    "lng": -104.67087,
    "state_id": "WY"
  },
  {
    "zip": 82223,
    "lat": 42.12846,
    "lng": -104.39494,
    "state_id": "WY"
  },
  {
    "zip": 82224,
    "lat": 43.04212,
    "lng": -104.79743,
    "state_id": "WY"
  },
  {
    "zip": 82225,
    "lat": 42.98523,
    "lng": -104.31652,
    "state_id": "WY"
  },
  {
    "zip": 82227,
    "lat": 42.76168,
    "lng": -104.72261,
    "state_id": "WY"
  },
  {
    "zip": 82229,
    "lat": 42.87669,
    "lng": -104.96792,
    "state_id": "WY"
  },
  {
    "zip": 82240,
    "lat": 42.24072,
    "lng": -104.17919,
    "state_id": "WY"
  },
  {
    "zip": 82242,
    "lat": 42.69459,
    "lng": -104.1093,
    "state_id": "WY"
  },
  {
    "zip": 82243,
    "lat": 41.9945,
    "lng": -104.4275,
    "state_id": "WY"
  },
  {
    "zip": 82244,
    "lat": 41.88119,
    "lng": -104.43974,
    "state_id": "WY"
  },
  {
    "zip": 82301,
    "lat": 41.88846,
    "lng": -107.49508,
    "state_id": "WY"
  },
  {
    "zip": 82321,
    "lat": 41.17809,
    "lng": -107.76322,
    "state_id": "WY"
  },
  {
    "zip": 82322,
    "lat": 42.23771,
    "lng": -107.56103,
    "state_id": "WY"
  },
  {
    "zip": 82323,
    "lat": 41.04457,
    "lng": -107.49892,
    "state_id": "WY"
  },
  {
    "zip": 82324,
    "lat": 41.66703,
    "lng": -106.41037,
    "state_id": "WY"
  },
  {
    "zip": 82325,
    "lat": 41.16527,
    "lng": -107.07331,
    "state_id": "WY"
  },
  {
    "zip": 82327,
    "lat": 42.16538,
    "lng": -106.68553,
    "state_id": "WY"
  },
  {
    "zip": 82329,
    "lat": 42.08873,
    "lng": -106.07531,
    "state_id": "WY"
  },
  {
    "zip": 82331,
    "lat": 41.3839,
    "lng": -106.80053,
    "state_id": "WY"
  },
  {
    "zip": 82332,
    "lat": 41.05674,
    "lng": -107.29439,
    "state_id": "WY"
  },
  {
    "zip": 82334,
    "lat": 41.8812,
    "lng": -106.99553,
    "state_id": "WY"
  },
  {
    "zip": 82335,
    "lat": 41.81001,
    "lng": -106.85236,
    "state_id": "WY"
  },
  {
    "zip": 82336,
    "lat": 41.73137,
    "lng": -108.19015,
    "state_id": "WY"
  },
  {
    "zip": 82401,
    "lat": 43.98603,
    "lng": -108.14599,
    "state_id": "WY"
  },
  {
    "zip": 82410,
    "lat": 44.36572,
    "lng": -108.12363,
    "state_id": "WY"
  },
  {
    "zip": 82411,
    "lat": 44.41452,
    "lng": -108.45816,
    "state_id": "WY"
  },
  {
    "zip": 82412,
    "lat": 44.79598,
    "lng": -108.55221,
    "state_id": "WY"
  },
  {
    "zip": 82414,
    "lat": 44.47308,
    "lng": -109.58734,
    "state_id": "WY"
  },
  {
    "zip": 82420,
    "lat": 44.92284,
    "lng": -108.45493,
    "state_id": "WY"
  },
  {
    "zip": 82421,
    "lat": 44.90754,
    "lng": -108.59834,
    "state_id": "WY"
  },
  {
    "zip": 82422,
    "lat": 44.49766,
    "lng": -108.38215,
    "state_id": "WY"
  },
  {
    "zip": 82423,
    "lat": 44.97006,
    "lng": -108.58398,
    "state_id": "WY"
  },
  {
    "zip": 82426,
    "lat": 44.52252,
    "lng": -107.76196,
    "state_id": "WY"
  },
  {
    "zip": 82428,
    "lat": 44.3084,
    "lng": -107.52607,
    "state_id": "WY"
  },
  {
    "zip": 82430,
    "lat": 43.82019,
    "lng": -108.18855,
    "state_id": "WY"
  },
  {
    "zip": 82431,
    "lat": 44.84238,
    "lng": -108.16573,
    "state_id": "WY"
  },
  {
    "zip": 82432,
    "lat": 44.22676,
    "lng": -107.89134,
    "state_id": "WY"
  },
  {
    "zip": 82433,
    "lat": 44.06316,
    "lng": -109.10138,
    "state_id": "WY"
  },
  {
    "zip": 82434,
    "lat": 44.38717,
    "lng": -108.30866,
    "state_id": "WY"
  },
  {
    "zip": 82435,
    "lat": 44.82008,
    "lng": -108.93824,
    "state_id": "WY"
  },
  {
    "zip": 82440,
    "lat": 44.71995,
    "lng": -108.86413,
    "state_id": "WY"
  },
  {
    "zip": 82441,
    "lat": 44.6435,
    "lng": -107.77168,
    "state_id": "WY"
  },
  {
    "zip": 82442,
    "lat": 43.87936,
    "lng": -107.31477,
    "state_id": "WY"
  },
  {
    "zip": 82443,
    "lat": 43.72509,
    "lng": -108.47146,
    "state_id": "WY"
  },
  {
    "zip": 82450,
    "lat": 44.50527,
    "lng": -109.43548,
    "state_id": "WY"
  },
  {
    "zip": 82501,
    "lat": 43.03512,
    "lng": -108.20235,
    "state_id": "WY"
  },
  {
    "zip": 82510,
    "lat": 42.99405,
    "lng": -108.59798,
    "state_id": "WY"
  },
  {
    "zip": 82512,
    "lat": 43.30055,
    "lng": -109.25649,
    "state_id": "WY"
  },
  {
    "zip": 82513,
    "lat": 43.59307,
    "lng": -109.64271,
    "state_id": "WY"
  },
  {
    "zip": 82514,
    "lat": 43.04967,
    "lng": -108.95359,
    "state_id": "WY"
  },
  {
    "zip": 82515,
    "lat": 42.90373,
    "lng": -108.54265,
    "state_id": "WY"
  },
  {
    "zip": 82516,
    "lat": 43.20144,
    "lng": -108.86383,
    "state_id": "WY"
  },
  {
    "zip": 82520,
    "lat": 42.6905,
    "lng": -108.62851,
    "state_id": "WY"
  },
  {
    "zip": 82523,
    "lat": 43.44312,
    "lng": -108.81411,
    "state_id": "WY"
  },
  {
    "zip": 82601,
    "lat": 43.09718,
    "lng": -106.38744,
    "state_id": "WY"
  },
  {
    "zip": 82604,
    "lat": 42.85792,
    "lng": -106.76934,
    "state_id": "WY"
  },
  {
    "zip": 82609,
    "lat": 42.80683,
    "lng": -106.18906,
    "state_id": "WY"
  },
  {
    "zip": 82620,
    "lat": 42.46957,
    "lng": -107.10001,
    "state_id": "WY"
  },
  {
    "zip": 82630,
    "lat": 43.15275,
    "lng": -107.30617,
    "state_id": "WY"
  },
  {
    "zip": 82633,
    "lat": 43.02562,
    "lng": -105.42225,
    "state_id": "WY"
  },
  {
    "zip": 82635,
    "lat": 43.39814,
    "lng": -106.22238,
    "state_id": "WY"
  },
  {
    "zip": 82636,
    "lat": 42.90923,
    "lng": -106.16892,
    "state_id": "WY"
  },
  {
    "zip": 82637,
    "lat": 42.81448,
    "lng": -105.88636,
    "state_id": "WY"
  },
  {
    "zip": 82638,
    "lat": 43.11535,
    "lng": -107.34356,
    "state_id": "WY"
  },
  {
    "zip": 82639,
    "lat": 43.71545,
    "lng": -106.60918,
    "state_id": "WY"
  },
  {
    "zip": 82640,
    "lat": 43.56598,
    "lng": -106.1957,
    "state_id": "WY"
  },
  {
    "zip": 82642,
    "lat": 43.26261,
    "lng": -107.61967,
    "state_id": "WY"
  },
  {
    "zip": 82643,
    "lat": 43.44577,
    "lng": -106.26892,
    "state_id": "WY"
  },
  {
    "zip": 82644,
    "lat": 42.84306,
    "lng": -106.37618,
    "state_id": "WY"
  },
  {
    "zip": 82646,
    "lat": 42.99606,
    "lng": -106.826,
    "state_id": "WY"
  },
  {
    "zip": 82648,
    "lat": 43.29487,
    "lng": -107.13995,
    "state_id": "WY"
  },
  {
    "zip": 82649,
    "lat": 43.29935,
    "lng": -108.05634,
    "state_id": "WY"
  },
  {
    "zip": 82701,
    "lat": 43.7037,
    "lng": -104.43779,
    "state_id": "WY"
  },
  {
    "zip": 82710,
    "lat": 44.70768,
    "lng": -104.27047,
    "state_id": "WY"
  },
  {
    "zip": 82711,
    "lat": 44.70937,
    "lng": -104.45863,
    "state_id": "WY"
  },
  {
    "zip": 82712,
    "lat": 44.45337,
    "lng": -104.1216,
    "state_id": "WY"
  },
  {
    "zip": 82714,
    "lat": 44.57159,
    "lng": -104.69965,
    "state_id": "WY"
  },
  {
    "zip": 82715,
    "lat": 44.11912,
    "lng": -104.13329,
    "state_id": "WY"
  },
  {
    "zip": 82716,
    "lat": 44.48073,
    "lng": -105.68543,
    "state_id": "WY"
  },
  {
    "zip": 82718,
    "lat": 43.88124,
    "lng": -105.61309,
    "state_id": "WY"
  },
  {
    "zip": 82720,
    "lat": 44.79764,
    "lng": -104.67587,
    "state_id": "WY"
  },
  {
    "zip": 82721,
    "lat": 44.49175,
    "lng": -104.9126,
    "state_id": "WY"
  },
  {
    "zip": 82723,
    "lat": 43.99627,
    "lng": -104.41398,
    "state_id": "WY"
  },
  {
    "zip": 82725,
    "lat": 44.87763,
    "lng": -105.7088,
    "state_id": "WY"
  },
  {
    "zip": 82727,
    "lat": 44.32797,
    "lng": -105.19841,
    "state_id": "WY"
  },
  {
    "zip": 82729,
    "lat": 44.35171,
    "lng": -104.36139,
    "state_id": "WY"
  },
  {
    "zip": 82730,
    "lat": 44.04329,
    "lng": -104.69889,
    "state_id": "WY"
  },
  {
    "zip": 82731,
    "lat": 44.82086,
    "lng": -105.28786,
    "state_id": "WY"
  },
  {
    "zip": 82732,
    "lat": 43.70242,
    "lng": -105.62088,
    "state_id": "WY"
  },
  {
    "zip": 82801,
    "lat": 44.8445,
    "lng": -106.83968,
    "state_id": "WY"
  },
  {
    "zip": 82831,
    "lat": 44.62376,
    "lng": -106.06917,
    "state_id": "WY"
  },
  {
    "zip": 82832,
    "lat": 44.60032,
    "lng": -106.79543,
    "state_id": "WY"
  },
  {
    "zip": 82833,
    "lat": 44.61987,
    "lng": -107.12312,
    "state_id": "WY"
  },
  {
    "zip": 82834,
    "lat": 44.23696,
    "lng": -106.65641,
    "state_id": "WY"
  },
  {
    "zip": 82835,
    "lat": 44.77749,
    "lng": -106.35873,
    "state_id": "WY"
  },
  {
    "zip": 82836,
    "lat": 44.78181,
    "lng": -107.50268,
    "state_id": "WY"
  },
  {
    "zip": 82837,
    "lat": 44.78402,
    "lng": -106.26489,
    "state_id": "WY"
  },
  {
    "zip": 82838,
    "lat": 44.94529,
    "lng": -107.55832,
    "state_id": "WY"
  },
  {
    "zip": 82839,
    "lat": 44.92366,
    "lng": -107.15493,
    "state_id": "WY"
  },
  {
    "zip": 82842,
    "lat": 44.57674,
    "lng": -106.9288,
    "state_id": "WY"
  },
  {
    "zip": 82844,
    "lat": 44.78645,
    "lng": -107.22022,
    "state_id": "WY"
  },
  {
    "zip": 82845,
    "lat": 44.75848,
    "lng": -106.6739,
    "state_id": "WY"
  },
  {
    "zip": 82901,
    "lat": 41.36487,
    "lng": -109.00246,
    "state_id": "WY"
  },
  {
    "zip": 82922,
    "lat": 43.13997,
    "lng": -110.38958,
    "state_id": "WY"
  },
  {
    "zip": 82923,
    "lat": 42.65397,
    "lng": -109.46208,
    "state_id": "WY"
  },
  {
    "zip": 82925,
    "lat": 43.23145,
    "lng": -109.89264,
    "state_id": "WY"
  },
  {
    "zip": 82929,
    "lat": 41.55995,
    "lng": -109.84999,
    "state_id": "WY"
  },
  {
    "zip": 82930,
    "lat": 41.00812,
    "lng": -110.63715,
    "state_id": "WY"
  },
  {
    "zip": 82932,
    "lat": 42.25268,
    "lng": -109.35569,
    "state_id": "WY"
  },
  {
    "zip": 82933,
    "lat": 41.2798,
    "lng": -110.45747,
    "state_id": "WY"
  },
  {
    "zip": 82934,
    "lat": 41.60405,
    "lng": -110.01286,
    "state_id": "WY"
  },
  {
    "zip": 82935,
    "lat": 41.69097,
    "lng": -109.67217,
    "state_id": "WY"
  },
  {
    "zip": 82936,
    "lat": 41.06521,
    "lng": -110.18397,
    "state_id": "WY"
  },
  {
    "zip": 82937,
    "lat": 41.35587,
    "lng": -110.26616,
    "state_id": "WY"
  },
  {
    "zip": 82938,
    "lat": 41.14441,
    "lng": -109.7202,
    "state_id": "WY"
  },
  {
    "zip": 82939,
    "lat": 41.20709,
    "lng": -110.33112,
    "state_id": "WY"
  },
  {
    "zip": 82941,
    "lat": 43.04005,
    "lng": -109.95411,
    "state_id": "WY"
  },
  {
    "zip": 82942,
    "lat": 41.71746,
    "lng": -108.85283,
    "state_id": "WY"
  },
  {
    "zip": 82943,
    "lat": 41.75462,
    "lng": -109.20805,
    "state_id": "WY"
  },
  {
    "zip": 82944,
    "lat": 41.10152,
    "lng": -110.50675,
    "state_id": "WY"
  },
  {
    "zip": 82945,
    "lat": 41.7372,
    "lng": -109.05174,
    "state_id": "WY"
  },
  {
    "zip": 83001,
    "lat": 43.51048,
    "lng": -110.46116,
    "state_id": "WY"
  },
  {
    "zip": 83011,
    "lat": 43.56136,
    "lng": -110.43683,
    "state_id": "WY"
  },
  {
    "zip": 83012,
    "lat": 43.71497,
    "lng": -110.74201,
    "state_id": "WY"
  },
  {
    "zip": 83013,
    "lat": 43.95445,
    "lng": -110.43579,
    "state_id": "WY"
  },
  {
    "zip": 83014,
    "lat": 43.45859,
    "lng": -110.93681,
    "state_id": "WY"
  },
  {
    "zip": 83025,
    "lat": 43.59237,
    "lng": -110.83137,
    "state_id": "WY"
  },
  {
    "zip": 83101,
    "lat": 41.96375,
    "lng": -110.54814,
    "state_id": "WY"
  },
  {
    "zip": 83110,
    "lat": 42.72276,
    "lng": -110.9014,
    "state_id": "WY"
  },
  {
    "zip": 83111,
    "lat": 42.76815,
    "lng": -111.12375,
    "state_id": "WY"
  },
  {
    "zip": 83112,
    "lat": 42.91946,
    "lng": -110.8309,
    "state_id": "WY"
  },
  {
    "zip": 83113,
    "lat": 42.53213,
    "lng": -110.2569,
    "state_id": "WY"
  },
  {
    "zip": 83114,
    "lat": 42.17611,
    "lng": -110.94517,
    "state_id": "WY"
  },
  {
    "zip": 83115,
    "lat": 42.89738,
    "lng": -110.2995,
    "state_id": "WY"
  },
  {
    "zip": 83116,
    "lat": 41.77257,
    "lng": -110.53577,
    "state_id": "WY"
  },
  {
    "zip": 83118,
    "lat": 43.04943,
    "lng": -111.00229,
    "state_id": "WY"
  },
  {
    "zip": 83119,
    "lat": 42.64397,
    "lng": -110.98825,
    "state_id": "WY"
  },
  {
    "zip": 83120,
    "lat": 42.93376,
    "lng": -111.13785,
    "state_id": "WY"
  },
  {
    "zip": 83121,
    "lat": 41.82019,
    "lng": -110.53387,
    "state_id": "WY"
  },
  {
    "zip": 83122,
    "lat": 42.82027,
    "lng": -110.94651,
    "state_id": "WY"
  },
  {
    "zip": 83123,
    "lat": 42.22391,
    "lng": -110.26321,
    "state_id": "WY"
  },
  {
    "zip": 83124,
    "lat": 41.77054,
    "lng": -110.23093,
    "state_id": "WY"
  },
  {
    "zip": 83126,
    "lat": 42.57962,
    "lng": -110.84472,
    "state_id": "WY"
  },
  {
    "zip": 83127,
    "lat": 42.95547,
    "lng": -110.96513,
    "state_id": "WY"
  },
  {
    "zip": 83128,
    "lat": 43.12143,
    "lng": -110.7886,
    "state_id": "WY"
  },
  {
    "zip": 83414,
    "lat": 43.88851,
    "lng": -110.94924,
    "state_id": "WY"
  }
]