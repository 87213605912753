import { Component } from '@angular/core'
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router'
import { AuthService } from './shared/services/auth/auth.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'
import { ColorSchemeService } from './shared/services/color-scheme.service'
import { ScreenSizeService } from './shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'

document.body.onkeydown = function (event) {
    // TODO: if needed, can look into multiple keys pressed at once (ex: control-shift-c, or whatever). for now this seems fine
    if (event.key == '`') {
        console.clear()
    }
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    //test for commit

    previousUrl
    currentUrl

    isAuthenticated = new BehaviorSubject(false)

    constructor(
        private authService: AuthService,
        private router: Router,
        private dialog: MatDialog,
        private colorSchemeService: ColorSchemeService,
        private screenSizeService: ScreenSizeService,
    ) {
        this.colorSchemeService.load()

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                // console.log('start', event)
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
                // console.log('end', event)
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator
                // Present error to user
                // console.log(event.error)
            }
        })

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.previousUrl = this.currentUrl
                this.currentUrl = event.url

                // console.log('prev ~~~', this.previousUrl)
                // console.log('current ~~~', this.currentUrl)
            })
    }

    isMobileScreen = false
    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.authService.authStatusChanged.pipe(untilDestroyed(this)).subscribe((authenticated) => {
            this.isAuthenticated.next(authenticated)
            if (authenticated) {
                this.dialog.closeAll()
            }
        })

        this.authService.checkAuth() //! this line needs to be AFTER the above subscription
    }

    onLogout() {
        this.authService.logout()
        this.router.navigate(['public/home'])
    }
}
