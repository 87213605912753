import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-info-message',
  templateUrl: './simple-info-message.component.html',
  styleUrls: ['./simple-info-message.component.scss']
})
export class SimpleInfoMessageComponent implements OnInit {

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
  }

  constructor() { }

  ngOnInit(): void {
  }

}
