<div class="parent-container" *ngIf="eventDetails$ | async">
    <div class="mx-0 mb-4 row" *ngIf="userIsAdmin">
        <div class="text-right col-12">
            <button
                mat-raised-button
                (click)="deleteEvent()"
                class="ml-3 gh-button gh-button-warning"
            >
                Delete Event
            </button>
        </div>
    </div>

    <div class="mx-0 mb-3 row">
        <div class="col-sm-12 col-12 col-md-5">
            <div class="image-gallery-container" *ngIf="thumbs$ | async; else noImages">
                <app-image-gallery
                    (showAdditionalImagesEmitter)="showAdditionalImages()"
                    [_thumbs]="thumbs.value"
                    [_itemId]="thumbs.value"
                    [_showBottomDivider]="false"
                    [_sourceOfInput]="'app-profile-view-event-details'"
                >
                </app-image-gallery>
            </div>

            <ng-template #noImages>
                <mat-card-subtitle>No images available</mat-card-subtitle>
            </ng-template>
        </div>
        <div class="col-sm-12 col-12 col-md-7">
            <div class="pl-5 data-group">
                <div class="row">
                    <div class="col-sm-12 col-12 col-lg-6 col-md-6 col-xl-6">
                        <div class="row">
                            <div class="col-12">
                                <mat-card-title class="gh-xsm-title"> Title: </mat-card-title>
                            </div>
                            <div class="col-12">
                                <mat-card-subtitle>
                                    {{ eventDetails.value.eventTitle }}
                                </mat-card-subtitle>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <mat-card-title class="gh-xsm-title"> Organizer: </mat-card-title>
                            </div>
                            <div class="col-12">
                                <mat-card-subtitle>
                                    {{ eventDetails.value.eventOrganizer }}
                                </mat-card-subtitle>
                                <!-- <mat-card-subtitle>
                                    {{eventDetails.value.eventOrganizerId}}
                                </mat-card-subtitle> -->
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <mat-card-title class="gh-xsm-title"> Start: </mat-card-title>
                            </div>
                            <div class="col-12">
                                <mat-card-subtitle>
                                    {{ eventDetails.value.date | date : 'short' }}
                                </mat-card-subtitle>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <mat-card-title class="gh-xsm-title"> End: </mat-card-title>
                            </div>
                            <div class="col-12">
                                <mat-card-subtitle>
                                    {{
                                        (eventDetails.value.end
                                            ? eventDetails.value.end
                                            : eventDetails.value.date
                                        ) | date : 'short'
                                    }}
                                </mat-card-subtitle>
                            </div>
                        </div>

                        <div *ngIf="eventDetails.value.groupTitleText" class="row">
                            <div class="col-12">
                                <mat-card-title class="gh-xsm-title"> Group: </mat-card-title>
                            </div>
                            <div class="col-12">
                                <mat-card-subtitle>
                                    {{ eventDetails.value.groupTitleText }}
                                </mat-card-subtitle>
                            </div>
                        </div>

                        <div *ngIf="eventDetails.value.havenTitle" class="row">
                            <div class="col-12">
                                <mat-card-title class="gh-xsm-title"> Haven: </mat-card-title>
                            </div>
                            <div class="col-12">
                                <mat-card-subtitle>
                                    {{ eventDetails.value.havenTitle }}
                                </mat-card-subtitle>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-12 col-lg-6 col-md-6 col-xl-6">
                        <div class="row">
                            <div class="col-12">
                                <mat-card-title class="gh-xsm-title"> Description: </mat-card-title>
                            </div>
                            <div class="col-12">
                                <mat-card-subtitle>
                                    {{ eventDetails.value.description }}
                                </mat-card-subtitle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="mx-0 mb-3 row"
        *ngIf="eventDetails.value.selectedGames && eventDetails.value.selectedGames.length > 0"
    >
        <div class="col-12">
            <div class="pl-5 data-group">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title"> Games: </mat-card-title>
                    </div>
                </div>

                <div class="row">
                    <div
                        class="col-sm-12 clickableImg col-12 col-lg-3 col-md-3 col-xl-3"
                        *ngFor="let game of eventDetails.value.selectedGames"
                        (click)="openGameDetails(game)"
                    >
                        <div class="row">
                            <div class="text-center col-12">
                                <mat-card-subtitle class="gh-md-subtitle">
                                    {{ game.title }}
                                </mat-card-subtitle>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 image-container">
                                <img class="game-image" [src]="game.mainImageFiles[0]" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mx-0 mb-3 px-3 data-group">
        <div class="row">
            <div class="col-12 col-md-8">
                <app-gh-autocomplete-multiselect
                    #friendInput
                    [_attributeToDisplay]="'title'"
                    [_currentSelections]="null"
                    [_itemsList]="coreUsers.value"
                    (changeEmitter)="selectUsersToInvite($event)"
                >
                </app-gh-autocomplete-multiselect>
            </div>
            <div class="col-12 col-md-2">
                <button
                    mat-raised-button
                    class="m-3 gh-button gh-button-primary"
                    (click)="inviteMoreUsersViaViewEvent()"
                >
                    Invite Friends
                </button>
            </div>
        </div>
        <!-- * ATTENDEES -->

        <div class="row">
            <div class="col-sm-12 col-12 col-lg-6 col-md-6 col-xl-6">
                <mat-card-title class="gh-sm-title"> Attendees: </mat-card-title>
            </div>
        </div>
        <div class="attendees-grid">
            <div class="attendees-grid-item" *ngFor="let attendee of attendees; let i = index">
                <img
                    class="profile-image"
                    src="{{ getAttendeeImage(i) }}"
                    alt=""
                    (click)="openAttendeeDetails(attendee)"
                />
                <mat-card-subtitle class="gh-md-subtitle">{{ attendee.title }}</mat-card-subtitle>
            </div>
        </div>

        <!-- Add a horizontal rule to separate the sections -->
        <hr class="mt-4 mb-4 separator" />

        <div class="row">
            <div class="col-sm-12 col-12 col-lg-6 col-md-6 col-xl-6">
                <mat-card-title class="gh-sm-title"> Invited: </mat-card-title>
            </div>
        </div>
        <!-- * INVITED -->
        <div *ngIf="uniqueInvitedUsers && uniqueInvitedUsers.length > 0">
            <div class="invited-grid">
                <div
                    class="invited-grid-item"
                    *ngFor="let user of uniqueInvitedUsers; let i = index"
                >
                    <img
                        class="profile-image"
                        src="{{ getInvitedImage(i) }}"
                        alt=""
                        (click)="openAttendeeDetails(user)"
                    />
                    <mat-card-subtitle class="gh-md-subtitle">{{
                        user.sentToTitle
                    }}</mat-card-subtitle>
                </div>
            </div>
        </div>

        <hr class="mt-4 mb-4 separator" />

        <div class="mt-3 data-group">
            <div class="mx-0 row">
                <div class="col-12">
                    <mat-card-title class="gh-sm-title"> Logged Plays: </mat-card-title>
                </div>

                <div class="mt-2 col-12">
                    <app-profile-recent-logged-plays [_directInputData]="loggedPlays">
                    </app-profile-recent-logged-plays>
                </div>
            </div>
        </div>

        <hr class="mt-4 mb-4 separator" />

        <div class="mt-3 data-group" *ngIf="videos$ | async">
            <div class="mx-0 row">
                <div class="col-12">
                    <mat-card-title class="gh-sm-title"> Videos: </mat-card-title>
                </div>
                <div class="mt-2 col-12">
                    <app-image-or-video-gallery-grid
                        #imageOrVideoGalleryGrid
                        *ngIf="videos.value && videos.value.length > 0"
                        [_origin]="'profile'"
                        [_items]="videos.value"
                        [_type]="'video'"
                    >
                    </app-image-or-video-gallery-grid>
                </div>
            </div>
        </div>
    </div>
</div>
