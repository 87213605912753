import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms'
import {
    competitionTags,
    mechanicTags,
    settingTags,
    uncertaintyTags,
    interactionTags,
    atmosphereTags,
    skillTags,
    accessibilityTags,
    playTimes,
    minAgeOptions,
    playerCountOptions,
    gameFuseOptions,
    defaultRatingConst,
    printStatusOptions,
    crowdfundingSiteOptions,
} from '../../../constants/games.constants'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { DAVE_ID } from 'src/app/dashboard/constants/admin-id-constants'
import { urlRegex } from '../../../constants/regex-constants'
import { BannerLocationEnums } from '../../../enums/banner-location-enums'
import { BannerSizeEnums } from '../../../enums/banner-size-enums'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { iGame, iIdealPlayerVotes } from '../../../interfaces/games/IGame'
import { StringHelperService } from '../../../services/helpers/string-helper.service'
import { StringHelper } from '../../../../../../../../common/helpers/string-helper'
import { CommunityVotesComponent } from '../community-votes/community-votes.component'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GAMES_BY_TITLE_PK } from '../../../../../../../../common/constants/gamesTableConstants'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { ImageMultiSizeService } from '../../../services/helpers/image-multi-size.service'
import { ulid } from 'ulid'
import { uploadImageFile } from 'src/app/backend-api-services/s3-file-uploader'
import { S3ContentHelperService } from '../../../services/helpers/s3-content-helper.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { SnackbarService } from '../../../services/user-action-feedback/snackbar.service'
import { MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-add-or-edit-game[_sourceOfInput]',
    templateUrl: './add-or-edit-game.component.html',
    styleUrls: ['./add-or-edit-game.component.scss'],
})
export class AddOrEditGameComponent implements OnInit {
    // todo fuze stuff
    list = [
        {
            name: 'Mustermann',
            vorname: 'Maxi',
            email: 'Maxi.Mustermann@gmail.com',
        },
        {
            name: 'Mustermann',
            vorname: 'Maximilian',
            email: 'Maxi.Mustermann@gmail.com',
        },
        {
            name: 'Hans',
            vorname: 'Wurst',
            email: 'xahrens@gstar.de',
        }, 
    ]

    // ! shay help dave with this JS to TS conversion
    // var searchEle = document.querySelector(".search"),
    // result = document.querySelector(".result");

    // function doSearch() {
    //   var resultJSON = fuse.search(searchEle.value);
    //   result.value = JSON.stringify(resultJSON, null, 3);
    // }

    // var options = {
    //   shouldSort: true,
    //   matchAllTokens: true,
    //   findAllMatches: true,
    //   threshold: 0.4,
    //   location: 0,
    //   distance: 100,
    //   maxPatternLength: 32,
    //   minMatchCharLength: 1,
    //   keys: ["vorname", "nachname","email"]
    // };
    // var fuse = new Fuse(list, options);

    // searchEle.addEventListener("input", doSearch);

    // doSearch();

    // todo fuze stuff

    baseGameRequiredError = false

    gameToBeEditedHasInitialRatings = false
    gameToBeEditedHasInitialTags = false

    @Input() _sourceOfInput: string

    initialRatingsAreEnabled = true
    initialRatingsCheckboxMessage = 'Check the initial ratings checkbox to modify.'

    submitIsClicked = false

    defaultRadarAttributeValue = 5

    @ViewChild('fileUploader', { static: false }) fileUploader
    @ViewChild('fileUploader2', { static: false }) fileUploader2
    @ViewChild('fileUploader3', { static: false }) fileUploader3
    @ViewChild('fileUploader4', { static: false }) fileUploader4
    @ViewChild('fileUploader5', { static: false }) fileUploader5

    pageTitle = 'Add a Game to the Database'

    publishers = new BehaviorSubject(null) // todo Replicating Here
    publishers$ = this.publishers.asObservable()

    designers = new BehaviorSubject(null)
    designers$ = this.designers.asObservable()

    artists = new BehaviorSubject(null)
    artists$ = this.artists.asObservable()

    factions = new BehaviorSubject(null)
    factions$ = this.factions.asObservable()

    minPlayerCountOptions = playerCountOptions
    maxPlayerCountOptions = playerCountOptions
    minAgeOptions = minAgeOptions
    minPlayTimeOptions = playTimes
    maxPlayTimeOptions = playTimes
    printStatusOptions = printStatusOptions
    crowdfundingSiteOptions = crowdfundingSiteOptions

    complexityRating: number = defaultRatingConst
    depthRating: number = defaultRatingConst
    durationRating: number = defaultRatingConst
    interactionRating: number = defaultRatingConst
    competitionRating: number = defaultRatingConst
    playersRating: number = defaultRatingConst
    chanceRating: number = defaultRatingConst
    thematicRating: number = defaultRatingConst

    competitionTags = competitionTags
    mechanicTags = mechanicTags
    settingTags = settingTags
    uncertaintyTags = uncertaintyTags
    interactionTags = interactionTags
    atmosphereTags = atmosphereTags
    skillTags = skillTags
    accessibilityTags = accessibilityTags

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.submit()
        }
    }

    @Input() set _inputData(inputData) {
        if (inputData) {
            // todo not sure this is needed anymore
            // this.gamesDbAccessorService.getBig8Votes(inputData.gameToEdit.pk).then(res => {
            //   this.gameToBeEditedHasInitialRatings = JSON.parse(res.data).voteCount > 0
            // })

            // this.gamesDbAccessorService.getTags(inputData.gameToEdit.pk).then(res => {
            //   let tags = Object.entries(JSON.parse(res.data))
            //   for (var i = 0 i < tags.length i++) {
            //     if (tags[i][1] > 0) {
            //       this.gameToBeEditedHasInitialTags = true
            //       break
            //     }
            //   }
            // })

            this.crowdfundingOption = inputData.gameToEdit.isCrowdfunded
                ? 'isCrowdfunded'
                : 'notCrowdfunded'

            this.inputData = inputData
            this.isEditing = true

            this.isGHAdmin = this.inputData.isGHAdmin.isGHAdmin
            this.setGameToEditAmazonAd()

            try {
                this.mainImageToEdit = this.inputData.gameToEditMainImage[0]
            } catch (err) {
                this.mainImageToEdit = ''
            }

            try {
                this.additionalImagesToEdit = this.inputData.gameToEditAdditionalImages
            } catch (err) {
                this.additionalImagesToEdit = null
            }
        }
    }

    async setGameToEditAmazonAd() {
        // await this.gamesDbAccessorService.getAmazonAd(this.inputData.gameToEdit.pk).then(result => {
        //   if (this.form && result && result.data && result.data != null) {
        //     this.form.get('aAd').patchValue(result.data)
        //     this.adInputChange()
        //   }
        // })
    }

    @Output() closeEmitter = new EventEmitter()

    bannerAdInputDataSpecific = {
        bannerSizeEnum: BannerSizeEnums.banner120x240,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        // specificAds: [{ link: '//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=davidrazidlo-20&marketplace=amazon&region=US&placement=B084Z8L6KZ&asins=B084Z8L6KZ&linkId=73f096a89d123bfe420da6b63d2bada2&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066C0&bg_color=FFFFFF' }],
        specificAds: [{ link: '' }],
        isIFrame: true,
    }

    isEditing = false
    inputData
    expansionOption = 'notAnExpansion'
    crowdfundingOption = 'notCrowdfunded'
    crowdfundingHasLaunchedOption = 'crowdfundingNotLaunched'
    form: UntypedFormGroup

    fuse: any
    fuseResults: any[] = []
    fuseResultString = ''
    gameFuseOptions = gameFuseOptions

    publishersToEdit
    publisherDescriptionToEdit = ''
    designersToEdit
    artistsToEdit
    factionsToEdit
    mainImageToEdit
    isGHAdmin
    additionalImagesToEdit = [undefined, undefined, undefined, undefined]

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private backendApiGamesService: BackendAPIGamesService,
        private stringHelperService: StringHelperService,
        private backendApiUsersService: BackendAPIUsersService,
        private backendApiContentService: BackendAPIContentService,
        private screenSizeService: ScreenSizeService,
        private imageMultiSizeService: ImageMultiSizeService,
        private s3ContentHelperService: S3ContentHelperService,
        private snackbarService: SnackbarService,

    ) { }

    compareFunction(o1: any, o2: any) {
        return o1 == o2
    }

    compareFunctionForGames(o1: any, o2: any) {
        return o1.pk == o2.pk
    }

    iframeExample =
        '<iframe style="width:120pxheight:240px" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=davidrazidl0c-20&language=en_US&marketplace=amazon&region=US&placement=B005DBRDPG&asins=B005DBRDPG&linkId=cbd54d26f04329b0c22ddacf8764f82e&show_border=true&link_opens_in_new_window=true"></iframe>'

    potentialParentGames = []
    async searchParentGames() {
        let parentGameSearchText = this.form.get('parentItem').value
        if (parentGameSearchText.length >= 1) {
            const result = await this.backendApiGamesService.listGamesByTitle(
                parentGameSearchText,
                15,
            )
            this.potentialParentGames = result.games
        } else {
            this.potentialParentGames = []
        }
    }

    showDuplicateTitleError = false

    potentialExistingGames = []
    async searchExistingGames() {
        let existingGameSearchText = this.form.get('title').value

        if (existingGameSearchText.length >= 1) {
            const result = await this.backendApiGamesService.listGamesByTitle(
                existingGameSearchText,
                5,
            )
            this.potentialExistingGames = result.games
            this.fuseResults = this.potentialExistingGames
            this.fuseResultString = 'the thing might already exist ...'
            this.fuseResultString = this.stringHelperService.createCommaSeparatedString(
                this.potentialExistingGames,
                true,
            )
            // TODO: make string helper service generic enough to handle difference sin npomenclature (title vs whatever vs whatever2)

            this.setDuplicateErrorMessage()
        } else {
            this.potentialExistingGames = []
        }
    }

    setDuplicateErrorMessage() {
        this.showDuplicateTitleError = this.potentialExistingGames.some(
            (g) => g.title.toLowerCase() === this.form.get('title').value.toLowerCase(),
        )
    }

    currentUser
    formIsLoaded = false
    carl

    whateverIsFound = false

    isMobileScreen = false

    async ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        // ! If this value is greater than 248, then it won't work in the autocomplete / filter

        await this.backendApiUsersService.listPublishersByTitle('#', 3000).then((result) => {
            this.publishers.next(result.users)
        })

        await this.backendApiUsersService.listDesignersByTitle('#', 3000).then((result) => {
            this.designers.next(result.users)
        })

        await this.backendApiUsersService.listArtistsByTitle('#', 3000).then((result) => {
            this.artists.next(result.users)
        })

        this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.currentUser = user
        })

        // TODO: still need to do like above for factions

        var printStausToEdit
        if (this.isEditing) {
            this.publishersToEdit =
                this.inputData.gameToEdit.publishers &&
                    this.inputData.gameToEdit.publishers.length > 0
                    ? this.inputData.gameToEdit.publishers
                    : []
            this.designersToEdit =
                this.inputData.gameToEdit.designers &&
                    this.inputData.gameToEdit.designers.length > 0
                    ? this.inputData.gameToEdit.designers
                    : []
            this.artistsToEdit =
                this.inputData.gameToEdit.artists && this.inputData.gameToEdit.artists.length > 0
                    ? this.inputData.gameToEdit.artists
                    : []
            this.factionsToEdit =
                this.inputData.gameToEdit.factions && this.inputData.gameToEdit.factions.length > 0
                    ? this.inputData.gameToEdit.factions
                    : []

            if (this.inputData.gameToEdit.isExpansion) {
                this.expansionOption = 'isExpansion'
            }

            // TODO: eventually figure out how to check against the string values of the enums

            // todo Something is going wrong here afsdlkjd aldfsjk

            if (this.inputData.gameToEdit.printStatus == 'inDevelopement') {
                //GamePrintStatusEnums.inDevelopement
                printStausToEdit = 0
            } else if (this.inputData.gameToEdit.printStatus == 'available') {
                printStausToEdit = 1
            } else {
                printStausToEdit = 2
            }

            if (
                this.inputData.gameToEdit.publisherDescription &&
                this.inputData.gameToEdit.publisherDescription.length > 0
            ) {
                for (var i = 0; i < this.inputData.gameToEdit.publisherDescription.length; i++) {
                    this.publisherDescriptionToEdit +=
                        this.inputData.gameToEdit.publisherDescription[i]
                    if (i != this.inputData.gameToEdit.publisherDescription.length - 1) {
                        this.publisherDescriptionToEdit += '\n'
                    }
                }
            }
        }

        // ! FIX ME
        var crowdfundingLaunchDate = undefined
        if (this.isEditing && this.inputData.gameToEdit.crowdfundingLaunchDate != 0) {
            crowdfundingLaunchDate = new Date(+this.inputData.gameToEdit.crowdfundingLaunchDate)
        }

        var crowdfundingExpiryDate = undefined
        if (this.isEditing && this.inputData.gameToEdit.crowdfundingExpiryDate != 0) {
            crowdfundingExpiryDate = new Date(+this.inputData.gameToEdit.crowdfundingExpiryDate)
        }

        var crowdfundingSiteOption = 0
        if (this.isEditing && this.crowdfundingOption == 'isCrowdfunded') {
            crowdfundingSiteOption = this.inputData.gameToEdit.crowdfundingSite
        }

        this.form = this.formBuilder.group({
            // title: [{value: this.isEditing ? this.inputData.gameToEdit.title : ''}, [Validators.required, Validators.maxLength(70)]],
            title: new UntypedFormControl(
                {
                    value: this.isEditing ? this.inputData.gameToEdit.title : '',
                    disabled: this.isEditing ? true : false,
                },
                [Validators.required, Validators.maxLength(70)],
            ),

            yearPublished: [this.isEditing ? this.inputData.gameToEdit.yearPublished : undefined],

            minPlaytime: [this.isEditing ? this.inputData.gameToEdit.minPlaytime : ''],
            maxPlaytime: [this.isEditing ? this.inputData.gameToEdit.maxPlaytime : ''],
            expectedPlaytime: [this.isEditing ? this.inputData.gameToEdit.expectedPlaytime : ''],
            minSuggestedAge: [
                this.isEditing ? this.inputData.gameToEdit.minSuggestedAge : undefined,
            ],
            publishers: [this.isEditing ? this.publishersToEdit : undefined],
            designers: [this.isEditing ? this.designersToEdit : undefined],
            artists: [this.isEditing ? this.artistsToEdit : undefined],
            minPlayers: [this.isEditing ? this.inputData.gameToEdit.minPlayers : ''],
            maxPlayers: [this.isEditing ? this.inputData.gameToEdit.maxPlayers : ''],
            officialLink: [
                this.isEditing ? this.inputData.gameToEdit.officialLink : '',
                [Validators.pattern(urlRegex)],
            ],
            factions: [this.isEditing ? this.factionsToEdit : ''],
            publisherDescription: [
                this.isEditing ? this.publisherDescriptionToEdit : '',
                [Validators.minLength(20), Validators.maxLength(2000)],
            ],
            parentItem: [
                this.isEditing &&
                    this.inputData.gameToEdit.parentItem != '' &&
                    this.inputData.gameToEdit.parentItem != undefined
                    ? this.inputData.gameToEdit.parentItem
                    : undefined,
            ], // AKA, Base Game
            printStatus: [this.isEditing ? printStausToEdit : 1], // 1 defaults it to available
            crowdfundingLaunchDate: crowdfundingLaunchDate, // default has to either be valid or undefined, otherwise will be invalid due to matDatepickerParse error
            crowdfundingExpiryDate: crowdfundingExpiryDate, // default has to either be valid or undefined, otherwise will be invalid due to matDatepickerParse error
            crowdfundingSite: crowdfundingSiteOption,
            crowdfundingLink: [
                this.isEditing && this.crowdfundingOption == 'isCrowdfunded'
                    ? this.inputData.gameToEdit.crowdfundingLink
                    : '',
                [Validators.pattern(urlRegex)],
            ],
            metaDataForGH: [
                this.isEditing && this.inputData.gameToEdit.metaDataForGH
                    ? this.inputData.gameToEdit.metaDataForGH
                    : '',
                Validators.maxLength(700),
            ],
            internalGhNotes: [
                this.isEditing && this.inputData.gameToEdit.internalGhNotes
                    ? this.inputData.gameToEdit.internalGhNotes
                    : '',
                Validators.maxLength(700),
            ],
            aAd: [this.isEditing ? this.inputData.gameToEdit.aad : ''],

            //! something going wrong hre. Since you are editing, these values don't exist on the form, so they get set to 5
            //TODO If there are previous ratings, modify the service so that they are included when the data is fetched?
            //TODO If there are not previous ratings, set it to the initial rating (5)
            //!Shay help finish this off using best practices asfdkjosdaijfsdaoifsdafojias

            starRating: [this.isEditing ? this.inputData.gameToEdit.starRating : -1],
            idealPlayerVotes: [
                this.isEditing ? this.inputData.gameToEdit.idealPlayerVotes : undefined,
            ],

            complexityRating: [
                { value: this.complexityRating, disabled: !this.initialRatingsAreEnabled },
            ],
            depthRating: [{ value: this.depthRating, disabled: !this.initialRatingsAreEnabled }],
            durationRating: [
                { value: this.durationRating, disabled: !this.initialRatingsAreEnabled },
            ],
            interactionRating: [
                { value: this.interactionRating, disabled: !this.initialRatingsAreEnabled },
            ],
            competitionRating: [
                { value: this.competitionRating, disabled: !this.initialRatingsAreEnabled },
            ],
            playersRating: [
                { value: this.playersRating, disabled: !this.initialRatingsAreEnabled },
            ],
            chanceRating: [{ value: this.chanceRating, disabled: !this.initialRatingsAreEnabled }],
            thematicRating: [
                { value: this.thematicRating, disabled: !this.initialRatingsAreEnabled },
            ],

            competitionTags: '',
            mechanicTags: '',
            settingTags: '',
            uncertaintyTags: '',
            interactionTags: '',
            atmosphereTags: '',
            skillTags: '',
            accessibilityTags: '',
        })
        this.formIsLoaded = true
    }

    createPublisherText
    async manuallyCreatePublisherItem() {
        if (this.createPublisherText.length >= 1) {
            let publisherInput = {
                username: ulid().toLowerCase(),
                title: this.createPublisherText,
            }

            const result = await this.backendApiUsersService.createUserPublisherItem(publisherInput)
            this.createPublisherText = ''
            await this.backendApiUsersService.listPublishersByTitle('#', 2000).then((result) => {
                this.publishers.next(result.users)
            })
        }
    }
    // End of OnInit()

    createDeveloperText
    async manuallyCreateDeveloperItem() {
        if (this.createDeveloperText.length >= 1) {
            let developerInput = {
                username: ulid().toLowerCase(),
                title: this.createDeveloperText,
            }
            const result = await this.backendApiUsersService.createUserDesignerItem(developerInput)

            this.createDeveloperText = ''
            await this.backendApiUsersService.listDesignersByTitle('#', 2000).then((result) => {
                this.designers.next(result.users)
            })
        }
    }

    createArtistText
    async manuallyCreateArtistItem() {
        if (this.createArtistText.length >= 1) {
            let artistInput = {
                username: ulid().toLowerCase(),
                title: this.createArtistText,
            }

            const result = await this.backendApiUsersService.createUserArtistItem(artistInput)
            this.createArtistText = ''
            await this.backendApiUsersService.listArtistsByTitle('#', 2000).then((result) => {
                this.artists.next(result.users)
            })
        }
    }

    setPublishers(publishers) {
        this.form.get('publishers').patchValue(publishers)
    }

    setMinPlaytime(playtimes) {
        this.form.get('maxPlaytime').patchValue(JSON.stringify(playtimes))
    }

    setMaxPlaytime(playtimes) {
        this.form.get('minPlaytime').patchValue(JSON.stringify(playtimes))
    }

    setDesigners(designers) {
        this.form.get('designers').patchValue(designers)
    }

    setArtists(artists) {
        this.form.get('artists').patchValue(artists)
    }

    setFactions(factions) {
        this.form.get('factions').patchValue(factions)
    }

    setStarRating(starRating) {
        this.form.get('starRating').patchValue(starRating)
    }

    openSubmitPlayerCount() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Ideal Number of Players',
                component: CommunityVotesComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '80vw',
        })
        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data && data.submitted) {
                    this.form.get('idealPlayerVotes').patchValue({
                        idealPlayerCount: data.idealPlayerVotes,
                        userVotes: data.userVotes,
                    })
                }
            })
    }

    onYearSelected(year) {
        this.form.get('yearPublished').patchValue(year)
    }

    truncate(input: string, maxCharacters: number) {
        return StringHelper.truncateString(input, maxCharacters)
    }

    setExpansionOption(event) {
        this.expansionOption = event.value
    }

    setBaseGame(event) {
        this.form.get('parentItem').patchValue(event.value)
        this.baseGameRequiredError = false
    }

    mostRecentPrintStatusSelection
    mostRecentYearPublishedSelection
    setCrowdfundingOption(event) {
        this.crowdfundingOption = event.value

        if (this.crowdfundingOption == 'isCrowdfunded') {
            this.mostRecentPrintStatusSelection = this.form.get('printStatus').value
            this.mostRecentYearPublishedSelection = this.form.get('yearPublished').value

            this.form.get('printStatus').patchValue(0) // 0 sets it to inDevelopement
            this.form.get('printStatus').disable()
            this.form.get('yearPublished').patchValue(undefined)
            this.form.get('yearPublished').disable()
        } else {
            this.form.get('printStatus').patchValue(this.mostRecentPrintStatusSelection)
            this.form.get('printStatus').enable()
            this.form.get('yearPublished').patchValue(this.mostRecentYearPublishedSelection)
            this.form.get('yearPublished').enable()
        }
    }

    setCrowdfundingHasLaunchedOption(event) {
        this.crowdfundingHasLaunchedOption = event.value
    }

    setPrintStatus(event) {
        if (event.value == 0) {
            this.mostRecentYearPublishedSelection = this.form.get('yearPublished').value
            this.form.get('yearPublished').patchValue(undefined)
            this.form.get('yearPublished').disable()
        } else {
            this.form.get('yearPublished').patchValue(this.mostRecentYearPublishedSelection)
            this.form.get('yearPublished').enable()
        }
    }
    Confirm
    setCrowdfundingSite(event) {
        this.form.get('crowdfundingSite').patchValue(event.value)
    }

    updateMinMaxPlaytimeOptions(typeThatChanged) {
        if (typeThatChanged == 'min') {
            let shallowCopy = JSON.stringify(playTimes)
            this.maxPlayTimeOptions = JSON.parse(shallowCopy)
            var i = this.maxPlayTimeOptions.length
            while (i--) {
                if (this.maxPlayTimeOptions[i].value < this.form.get('minPlaytime').value) {
                    this.maxPlayTimeOptions.splice(i, 1)
                }
            }
        } else if (typeThatChanged == 'max') {
            let shallowCopy = JSON.stringify(playTimes)
            this.minPlayTimeOptions = JSON.parse(shallowCopy)
            var i = this.minPlayTimeOptions.length
            while (i--) {
                if (this.minPlayTimeOptions[i].value > this.form.get('maxPlaytime').value) {
                    this.minPlayTimeOptions.splice(i, 1)
                }
            }
        }
    }

    updateMinMaxPlayerCountOptions(typeThatChanged) {
        if (typeThatChanged == 'min') {
            let shallowCopy = JSON.stringify(playerCountOptions)
            this.maxPlayerCountOptions = JSON.parse(shallowCopy)
            var i = this.maxPlayerCountOptions.length
            while (i--) {
                if (this.maxPlayerCountOptions[i].value < this.form.get('minPlayers').value) {
                    this.maxPlayerCountOptions.splice(i, 1)
                }
            }
        } else if (typeThatChanged == 'max') {
            let shallowCopy = JSON.stringify(playerCountOptions)
            this.minPlayerCountOptions = JSON.parse(shallowCopy)
            var i = this.minPlayerCountOptions.length
            while (i--) {
                if (this.minPlayerCountOptions[i].value > this.form.get('maxPlayers').value) {
                    this.minPlayerCountOptions.splice(i, 1)
                }
            }
        }
    }

    getGameTitleDisplay(game) {
        return game.title
    }

    disableSubmitButton = false
    async submit() {
        if (!this.disableSubmitButton) {
            this.submitIsClicked = true

            this.baseGameRequiredError =
                this.expansionOption == 'isExpansion' &&
                (this.form.get('parentItem').value == '' ||
                    this.form.get('parentItem').value == undefined)
            if (this.baseGameRequiredError) {
                return
            }

            if (this.form.valid && !this.showDuplicateTitleError) {
                // if (this.initialRatingsAreEnabled && this.allRatingsAreDefault()) {
                //   let inputData = {
                //     message: 'Did you forget to adjust initial ratings?',
                //     submessage: 'We dafault all initital ratings to 5, so just checking that you really intend to rate each value at 5.',
                //     isDeletion: false
                //   }
                //   let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
                //     data: {
                //       title: 'Are you sure?',
                //       component: ConfirmActionComponent,
                //       inputData: inputData,
                //       hasSubmitButton: true,
                //       hasCancelButton: true

                //     },
                // panelClass: this.isMobileScreen ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS] : DESKTOP_MODAL_PANEL_CLASS,
                //     backdropClass: 'gh-dialog-backdrop',
                //     disableClose: true
                //   })

                //   dialogRef.afterClosed().pipe(untilDestroyed(this)).subscribe(data => {
                //     if (data && data == 'confirm') {
                //       this.completeSubmission()
                //     }
                //   })
                // }
                // else {
                //   this.completeSubmission()
                // }

                //! I think that this code is redundant since it is already checked by showDuplicateTitleError, but I am leaving it here because I dont want to take the time to check if removing it breaks anything (plus, the trim() thing might be good to have too)
                let proposedTitle = this.form.get('title').value.trim()
                if (proposedTitle) {
                    const result = await this.backendApiGamesService.listGamesByTitle(
                        proposedTitle,
                        5,
                    )

                    if (result.games.length > 0 && !this.isEditing) {
                        for (let game of result.games) {
                            if (game.title == proposedTitle) {
                                throw new Error('Game Already Exists')
                            }
                        }
                    } else {
                        console.log('game title does not exist')
                    }
                }
                this.completeSubmission()
            } else {
                this.snackbarService.openErrorSnackBar(
                    'ERROR: Please fill in required fields and/or resolve errors.',
                )
            }
        }
    }

    async completeSubmission() {
        this.snackbarService.openSuccessSnackBar('Processing... This will take a few moments')

        this.disableSubmitButton = true

        // NOTE: for some strange reason doing this directly when building the game object below causes it be undefined, so keep it here
        let currentUserLevel = this.currentUser.userLevel

        // todo  CoProgram If statement here that asks if all 5s then disreguards input without notification sdkjdskfjsh
        let radarAttributes = [
            +this.form.get('complexityRating').value / 10,
            +this.form.get('depthRating').value / 10,
            +this.form.get('durationRating').value / 10,
            +this.form.get('interactionRating').value / 10,
            +this.form.get('competitionRating').value / 10,
            +this.form.get('playersRating').value / 10,
            +this.form.get('chanceRating').value / 10,
            +this.form.get('thematicRating').value / 10,
        ]

        let allAreFives = true
        for (let attribute of radarAttributes) {
            if (attribute != 0.5) {
                allAreFives = false
            }
        }

        if (allAreFives) {
            radarAttributes = undefined
        }

        var printStatus
        if (this.form.get('printStatus').value == 0) {
            printStatus = 'inDevelopement'
        } else if (this.form.get('printStatus').value == 1) {
            printStatus = 'available'
        } else if (this.form.get('printStatus').value == 2) {
            printStatus = 'outOfPrint'
        }

        let starRating =
            this.form.get('starRating').value && this.form.get('starRating').value != undefined
                ? this.form.get('starRating').value
                : 0

        let idealPlayerVotes: iIdealPlayerVotes = {
            numberVotes:
                this.form.get('idealPlayerVotes').value &&
                    this.form.get('idealPlayerVotes').value != undefined
                    ? 1
                    : 0,
            averagedArray:
                this.form.get('idealPlayerVotes').value &&
                    this.form.get('idealPlayerVotes').value.idealPlayerCount &&
                    this.form.get('idealPlayerVotes').value.idealPlayerCount != undefined
                    ? this.form.get('idealPlayerVotes').value.idealPlayerCount
                    : undefined,
            userIdealPlayersVotes:
                this.form.get('idealPlayerVotes').value &&
                    this.form.get('idealPlayerVotes').value.userVotes &&
                    this.form.get('idealPlayerVotes').value.userVotes != undefined
                    ? [
                        {
                            userId: '',
                            userVotesArray: this.form.get('idealPlayerVotes').value.userVotes,
                        },
                    ]
                    : [],
        }

        var aAd = this.form.get('aAd').value == '' ? undefined : this.form.get('aAd').value
        aAd = this.form.get('aAd').value.includes('<iframe')
            ? StringHelper.extractSrcFromIframe(this.form.get('aAd').value)
            : this.form.get('aAd').value

        let publisherDescription = this.form.get('publisherDescription').value
        let publisherDescriptionSplit = publisherDescription.split('\n')

        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // Need logic here for the case in which we are editing and a game that was previously an expansion is being converted to no longer being so.
        //  Need logic to strip the parent items and : out of the title in order to revert to the original title
        //  Need to figure out what to set parent item to (null? undefined?). Should check dynamo of a non-expansion game, or check the backend createGame to see what the value should be

        // Once this is done, and mothod level variables are set here, need to update the attribiutes of the game to submit below
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
console.log('this is epansion?', this.expansionOption)
console.log('wee', this.expansionOption == 'isExpansion')
        let game: iGame = {
            aad: aAd,
            addedByUID: this.currentUser.username,
            pk: this.isEditing ? this.inputData.gameToEdit.id : undefined,
            title:
                this.form.get('parentItem').value && this.form.get('parentItem').value != undefined
                    ? this.form
                        .get('parentItem')
                        .value.title.concat(': ')
                        .concat(this.form.get('title').value)
                    : this.form.get('title').value,

            yearPublished:
                this.form.get('yearPublished').value != undefined
                    ? +this.form.get('yearPublished').value
                    : undefined,

            minPlaytime:
                this.form.get('minPlaytime').value != ''
                    ? +this.form.get('minPlaytime').value
                    : undefined,
            maxPlaytime:
                this.form.get('maxPlaytime').value != ''
                    ? +this.form.get('maxPlaytime').value
                    : undefined,
            minSuggestedAge:
                +this.form.get('minSuggestedAge').value != undefined
                    ? +this.form.get('minSuggestedAge').value
                    : undefined,
            minPlayers:
                this.form.get('minPlayers').value != ''
                    ? +this.form.get('minPlayers').value
                    : undefined,
            maxPlayers:
                this.form.get('maxPlayers').value != ''
                    ? +this.form.get('maxPlayers').value
                    : undefined,
            designers:
                this.form.get('designers').value != undefined
                    ? this.form.get('designers').value
                    : undefined,
            artists:
                this.form.get('artists').value != undefined
                    ? this.form.get('artists').value
                    : undefined,

            big8: radarAttributes,
            big8Votes: undefined,
            expectedPlaytime:
                this.form.get('expectedPlaytime').value != ''
                    ? +this.form.get('expectedPlaytime').value
                    : undefined, // could be used in the future for average
            crowdfundingLaunchDate:
                this.crowdfundingHasLaunchedOption == 'crowdfundingNotLaunched'
                    ? undefined
                    : +new Date(this.form.get('crowdfundingLaunchDate').value),
            crowdfundingExpiryDate:
                this.crowdfundingHasLaunchedOption == 'crowdfundingNotLaunched'
                    ? undefined
                    : +new Date(this.form.get('crowdfundingExpiryDate').value),

            publishers:
                this.form.get('publishers').value != undefined
                    ? this.form.get('publishers').value
                    : undefined,
            officialLink: this.form.get('officialLink').value,
            factions:
                this.form.get('factions').value != '' ? this.form.get('factions').value : undefined,
            publisherDescription: publisherDescriptionSplit,
            tags: this.generateAllTags(),
            isExpansion: this.expansionOption == 'isExpansion',
            parentItem:
                this.form.get('parentItem').value && this.form.get('parentItem').value != undefined
                    ? {
                        id: this.form.get('parentItem').value.id,
                        title: this.form.get('parentItem').value.title,
                        expansions: [], // ??? added in backend ????
                    }
                    : undefined,
            // adminFlag: '', // is this still needed? if so need to add it to interfaces as well as CreateGameInput in GQL schema in backend
            printStatus: printStatus,
            gameAdminId: DAVE_ID,
            isCrowdfunded: this.crowdfundingOption == 'isCrowdfunded',
            crowdfundingHasLaunched:
                this.crowdfundingHasLaunchedOption == 'crowdfundingHasLaunched',
            crowdfundingSite: this.form.get('crowdfundingSite').value,
            crowdfundingLink: this.form.get('crowdfundingLink').value,
            idealPlayerVotes: idealPlayerVotes,
            metaDataForGH: this.form.get('metaDataForGH').value,
            internalGhNotes: this.form.get('internalGhNotes').value,
            needsAttentionPK: this.isEditing ? 'edit' : 'new',
            needsAttentionSK: this.isEditing ? this.inputData.gameToEdit.pk : '',
            flaggedForModerationPK: undefined,
            flaggedForModerationSK: undefined,
            userLevelWhoAdded: currentUserLevel,
            trendingFactor: undefined,
            trendingFactorPK: undefined


            // set below and passed in directly to method header // also note that lower case is important based on CreateGameInput from GQL schema
            // radarAttributes : [
            //   3, 4, 3, 4, 3, 3, 4, 3
            // ]
            // radarAttributes: [undefined,undefined,undefined,undefined,undefined,undefined,undefined,undefined]
        }

        game.id = this.isEditing ? this.inputData.gameToEdit.id : undefined

        //* if game is already approved then need to maintain coreGSI when editing
        //* there may be other conditions than 'new' that matter, but this is good enough for now
        if (this.isEditing && this.inputData.gameToEdit.needsAttentionPK != 'new') {
            ; (game.corePK = 'g'), (game.coreSK = '#'.concat(game.title.toLowerCase()))
        }

        if (this.isEditing) {
            // need to eventually check if is approved here?  right now only approved games are in the list and thus available to edit
            // NOTE: could use input.gameToEdit.gsi2pk, but then would need to make sure these attributes are returned from the GQL fetch
            game.gsi2pk = GAMES_BY_TITLE_PK //! as long as there is something in this attribute it will be included in the index. when the game is created this gsi is ~NOT~ created with the intention to make the index sparse
            game.gsi2sk = game.title
        }

        let files = null
        if (!this.isEditing || (this.isEditing && this.fileUploader.imageChanged)) {
            files = [this.fileUploader.rawImage]
        }

        if (this.isEditing && files == null) {
            // if main image hasn't been changed then use the mainImage from input (since if files is not null, input.mainImageFiles is set in backendApiGamesService rather than here)
            files = this.inputData.gameToEditMainImage
        }

        if (this.isEditing) {
            await this.backendApiGamesService.UpdateGame(game, files).then((result) => {
                // If you do NOT change the image but you do edit the game and click submit then the Image is lost asdfadfda

                if (result) {
                    this.snackbarService.openSuccessSnackBar('Game Edited successfully. Please REFRESH')
                    this.closeEmitter.emit('success')
                }

                let input = {
                    replyToEmail: this.currentUser.email,
                    // toEmail: this.currentUser.email,
                    toEmail: 'support@mygamehaven.com',
                    subject: this.currentUser.title + ' edited ' + game.gsi2sk,
                    messageText: 'could have other data here',
                }
                this.backendApiContentService.createSESRequest(input)
            })
        } else {
            await this.backendApiGamesService
                .CreateGame(game, files, starRating)
                .then(async (result) => {
                    if (result) {
                        this.snackbarService.openSuccessSnackBar('TY!  Admin will Approve soon.')
                        this.closeEmitter.emit('success')

                        if (!this.isEditing) {
                            // if (this.fileUploader2.imageChanged) {
                            //     let s3result =
                            //         await this.s3ContentHelperService.prepareMainImageFiles([
                            //             this.fileUploader2.rawImage,
                            //         ])

                            //     let createItemsInput = {
                            //         userLevelWhoAdded: this.currentUser.userLevel,
                            //         paths: s3result,
                            //         addedByUID: this.currentUser.username,
                            //         gameId: result[1].id,
                            //         gameTitle: '',
                            //         havenId: undefined,
                            //         havenTitle: undefined,
                            //         userId: undefined,
                            //         userTitle: undefined,
                            //         title: undefined,
                            //         subtitle: undefined,
                            //         createdByUID: this.currentUser.username,
                            //         createdByTitle: this.currentUser.title,
                            //         groupId: undefined
                            //     }
                            //     this.backendApiContentService.createImageItems(createItemsInput)
                            // }

                            // if (this.fileUploader3.imageChanged) {
                            //     let s3result =
                            //         await this.s3ContentHelperService.prepareMainImageFiles([
                            //             this.fileUploader3.rawImage,
                            //         ])

                            //     let createItemsInput = {
                            //         userLevelWhoAdded: this.currentUser.userLevel,
                            //         paths: s3result,
                            //         addedByUID: this.currentUser.username,
                            //         gameId: result[1].id,
                            //         gameTitle: '',
                            //         havenId: undefined,
                            //         havenTitle: undefined,
                            //         userId: undefined,
                            //         userTitle: undefined,
                            //         title: undefined,
                            //         subtitle: undefined,
                            //         createdByUID: this.currentUser.username,
                            //         createdByTitle: this.currentUser.title,
                            //         groupId: undefined
                            //     }
                            //     this.backendApiContentService.createImageItems(createItemsInput)
                            // }

                            // if (this.fileUploader4.imageChanged) {
                            //     let s3result =
                            //         await this.s3ContentHelperService.prepareMainImageFiles([
                            //             this.fileUploader4.rawImage,
                            //         ])

                            //     let createItemsInput = {
                            //         userLevelWhoAdded: this.currentUser.userLevel,
                            //         paths: s3result,
                            //         addedByUID: this.currentUser.username,
                            //         gameId: result[1].id,
                            //         gameTitle: '',
                            //         havenId: undefined,
                            //         havenTitle: undefined,
                            //         userId: undefined,
                            //         userTitle: undefined,
                            //         title: undefined,
                            //         subtitle: undefined,
                            //         createdByUID: this.currentUser.username,
                            //         createdByTitle: this.currentUser.title,
                            //         groupId: undefined
                            //     }
                            //     this.backendApiContentService.createImageItems(createItemsInput)
                            // }

                            // if (this.fileUploader5.imageChanged) {
                            //     let s3result =
                            //         await this.s3ContentHelperService.prepareMainImageFiles([
                            //             this.fileUploader5.rawImage,
                            //         ])

                            //     let createItemsInput = {
                            //         userLevelWhoAdded: this.currentUser.userLevel,
                            //         paths: s3result,
                            //         addedByUID: this.currentUser.username,
                            //         gameId: result[1].id,
                            //         gameTitle: '',
                            //         havenId: undefined,
                            //         havenTitle: undefined,
                            //         userId: undefined,
                            //         userTitle: undefined,
                            //         title: undefined,
                            //         subtitle: undefined,
                            //         createdByUID: this.currentUser.username,
                            //         createdByTitle: this.currentUser.title,
                            //         groupId: undefined,

                            //     }
                            //     this.backendApiContentService.createImageItems(createItemsInput)
                            // }
                        }
                    }

                    let input = {
                        replyToEmail: this.currentUser.email,
                        toEmail: 'support@mygamehaven.com',
                        // toEmail: this.currentUser.email,
                        subject: this.currentUser.title + ' created ' + game.title,
                        messageText: 'could have other data here',
                    }
                    this.backendApiContentService.createSESRequest(input)
                })
        }
    }

    adInputChange() {
        let beforeCleaned = this.form.get('aAd').value

        var afterCleaned = beforeCleaned
        if (this.form.get('aAd').value.includes('<iframe')) {
            afterCleaned = StringHelper.extractSrcFromIframe(beforeCleaned)
        }

        this.bannerAdInputDataSpecific = {
            bannerSizeEnum: BannerSizeEnums.banner120x240,
            multiplier: 0.75,
            bannerLocation: BannerLocationEnums.sidebar,
            hasButtons: true,
            specificAds: [{ link: afterCleaned }],
            isIFrame: true,
        }
    }

    generateAllTags() {
        var allTags = []
        if (this.form.get('competitionTags').value) {
            for (let tag of JSON.parse(this.form.get('competitionTags').value)) {
                allTags.push(tag)
            }
        }
        if (this.form.get('mechanicTags').value) {
            for (let tag of JSON.parse(this.form.get('mechanicTags').value)) {
                allTags.push(tag)
            }
        }
        if (this.form.get('settingTags').value) {
            for (let tag of JSON.parse(this.form.get('settingTags').value)) {
                allTags.push(tag)
            }
        }
        if (this.form.get('uncertaintyTags').value) {
            for (let tag of JSON.parse(this.form.get('uncertaintyTags').value)) {
                allTags.push(tag)
            }
        }
        if (this.form.get('interactionTags').value) {
            for (let tag of JSON.parse(this.form.get('interactionTags').value)) {
                allTags.push(tag)
            }
        }
        if (this.form.get('atmosphereTags').value) {
            for (let tag of JSON.parse(this.form.get('atmosphereTags').value)) {
                allTags.push(tag)
            }
        }
        if (this.form.get('skillTags').value) {
            for (let tag of JSON.parse(this.form.get('skillTags').value)) {
                allTags.push(tag)
            }
        }
        if (this.form.get('accessibilityTags').value) {
            for (let tag of JSON.parse(this.form.get('accessibilityTags').value)) {
                allTags.push(tag)
            }
        }

        return allTags
    }

    allRatingsAreDefault(): boolean {
        // TODO: there is probably a cleaner way to validate this, but this works for now...
        return (
            +this.form.get('complexityRating').value == this.defaultRadarAttributeValue &&
            +this.form.get('depthRating').value == this.defaultRadarAttributeValue &&
            +this.form.get('durationRating').value == this.defaultRadarAttributeValue &&
            +this.form.get('interactionRating').value == this.defaultRadarAttributeValue &&
            +this.form.get('competitionRating').value == this.defaultRadarAttributeValue &&
            +this.form.get('playersRating').value == this.defaultRadarAttributeValue &&
            +this.form.get('chanceRating').value == this.defaultRadarAttributeValue &&
            +this.form.get('thematicRating').value == this.defaultRadarAttributeValue
        )
    }

    toggleInitialRatingsAreEnabled(event) {
        this.initialRatingsAreEnabled = event.checked

        if (this.initialRatingsAreEnabled) {
            this.form.get('complexityRating').enable()
            this.form.get('depthRating').enable()
            this.form.get('durationRating').enable()
            this.form.get('interactionRating').enable()
            this.form.get('competitionRating').enable()
            this.form.get('playersRating').enable()
            this.form.get('chanceRating').enable()
            this.form.get('thematicRating').enable()
        } else {
            this.form.get('complexityRating').disable()
            this.form.get('depthRating').disable()
            this.form.get('durationRating').disable()
            this.form.get('interactionRating').disable()
            this.form.get('competitionRating').disable()
            this.form.get('playersRating').disable()
            this.form.get('chanceRating').disable()
            this.form.get('thematicRating').disable()

            // TODO:  if disabled and changes have been made, disregard them upon submit!
        }
    }

    setCompetitionTags(competitionTags) {
        this.form.get('competitionTags').patchValue(JSON.stringify(competitionTags))
    }

    setMechanicTags(mechanicTags) {
        this.form.get('mechanicTags').patchValue(JSON.stringify(mechanicTags))
    }

    setSettingTags(settingTags) {
        this.form.get('settingTags').patchValue(JSON.stringify(settingTags))
    }

    setUncertaintyTags(uncertaintyTags) {
        this.form.get('uncertaintyTags').patchValue(JSON.stringify(uncertaintyTags))
    }

    setInteractionTags(interactionTags) {
        this.form.get('interactionTags').patchValue(JSON.stringify(interactionTags))
    }

    setAtmosphereTags(atmosphereTags) {
        this.form.get('atmosphereTags').patchValue(JSON.stringify(atmosphereTags))
    }

    setSkillTags(skillTags) {
        this.form.get('skillTags').patchValue(JSON.stringify(skillTags))
    }

    setAccessibilityTags(accessibilityTags) {
        this.form.get('accessibilityTags').patchValue(JSON.stringify(accessibilityTags))
    }

    setaAd(aAd) {
        this.form.get('aAd').patchValue(JSON.stringify(aAd))
    }
}
