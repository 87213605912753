import { Component, OnInit, Inject, Input } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ulid } from 'ulid'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-create-scheduled-play',
    templateUrl: './create-scheduled-play.component.html',
    styleUrls: ['./create-scheduled-play.component.scss'],
})
export class CreateScheduledPlayComponent implements OnInit {
    form: FormGroup
    isEditing = false
    selectedGame: any = null
    availableTimes: string[] = []
    gameSelected: boolean = false
    coreGamesToSearch: any
    coreGames = new BehaviorSubject(null)
    coreGames$ = this.coreGames.asObservable()
    possibleExpansions = []
    currentUser: any
    conventionDays: any[] = []

    // If passed in from the parent through data, extract the hasAbilityToManage flag.
    // Adjust the path as needed if it's in a different place.
    get hasAbilityToManage(): boolean {
        return this.data?.inputData?.hasAbilityToManage === true
    }

    constructor(
        public dialogRef: MatDialogRef<CreateScheduledPlayComponent>,
        private backendAPIUsersService: BackendAPIUsersService,
        private gamesService: GamesService,
        private backendAPIContentService: BackendAPIContentService,
        private fb: FormBuilder,
        private backendApiContentService: BackendAPIContentService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.isEditing = this.data.inputData?.isEditing || false
    }

    async ngOnInit() {
        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((currentUser) => {
                if (currentUser) {
                    this.currentUser = currentUser
                }
            })

        await this.gamesService.coreGames$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreGames) => {
                if (coreGames) {
                    coreGames = coreGames.filter((obj) => !obj.isExpansion)
                    this.coreGames.next(coreGames)
                    this.coreGamesToSearch = coreGames
                }
            })

        // Initialize the form including the new 'location' control.
        // If hasAbilityToManage is true, we can require location; if not, just set it to "Not set".
        this.form = this.fb.group({
            title: [null, Validators.required],
            gameTitle: [null, Validators.required],
            selectedDay: [null, Validators.required],
            selectedDuration: [null, Validators.required],
            selectedTime: [null, Validators.required],
            maxPlayers: [4, [Validators.required, Validators.min(1)]],
            waitlistCapacity: [0, [Validators.min(0)]],
            cost: [0, Validators.required],
            isPasswordProtected: [false],
            password: [''],
            notes: [''],
            location: [
                this.hasAbilityToManage ? '' : 'Not set',
                this.hasAbilityToManage ? Validators.required : [],
            ],
        })

        this.form.get('isPasswordProtected').valueChanges.subscribe((isProtected) => {
            if (isProtected) {
                this.form.get('password').setValidators([Validators.required])
            } else {
                this.form.get('password').clearValidators()
            }
            this.form.get('password').updateValueAndValidity()
        })

        if (
            this.data.inputData &&
            this.data.inputData.parentInputData &&
            this.data.inputData.parentInputData.convention &&
            this.data.inputData.parentInputData.convention.extendedProps &&
            this.data.inputData.parentInputData.convention.extendedProps.days
        ) {
            this.conventionDays = this.data.inputData.parentInputData.convention.extendedProps.days
        } else {
            this.conventionDays = []
        }

        if (this.conventionDays.length > 0) {
            this.form.get('selectedDay').setValue(this.conventionDays[0])
            this.generateTimeOptions(
                this.form.get('selectedDay').value,
                this.form.get('selectedDuration').value,
            )
        }

        this.form.get('selectedDay').valueChanges.subscribe((day) => {
            this.onDayChange(day)
        })

        this.form.get('selectedDuration').valueChanges.subscribe((duration) => {
            this.onDurationChange(duration)
        })

        if (this.isEditing) {
            const scheduledPlay = this.data.inputData.scheduledPlay

            if (this.coreGames.value) {
                this.selectedGame = this.coreGames.value.find(
                    (game) => game.title === scheduledPlay.name,
                )
                this.gameSelected = !!this.selectedGame
                this.form.get('gameTitle').setValue(this.selectedGame)
            }

            const selectedDay = this.conventionDays.find(
                (day) =>
                    new Date(day.date).toDateString() ===
                    new Date(scheduledPlay.date).toDateString(),
            )
            this.form.get('selectedDay').setValue(selectedDay)
            this.onDayChange(selectedDay)

            this.form.get('selectedDuration').setValue(parseInt(scheduledPlay.duration, 10))
            this.onDurationChange(parseInt(scheduledPlay.duration, 10))

            const startTime = new Date(scheduledPlay.startTime)
            const timeString = startTime.toLocaleTimeString([], {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            })
            this.form.get('selectedTime').setValue(timeString)

            this.form.get('maxPlayers').setValue(parseInt(scheduledPlay.maxPlayers, 10))
            this.form.get('waitlistCapacity').setValue(parseInt(scheduledPlay.waitlistCapacity, 10))
            this.form.get('notes').setValue(scheduledPlay.notes)
            const isPasswordProtected = scheduledPlay.isPasswordProtected === 'true'
            this.form.get('isPasswordProtected').setValue(isPasswordProtected)
            if (isPasswordProtected) {
                this.form.get('password').setValue(scheduledPlay.password || '')
            }

            this.form.get('title').setValue(scheduledPlay.eventTitle)
            this.form.get('cost').setValue(scheduledPlay.cost)

            // If we have a location and we can manage it, set it:
            if (this.hasAbilityToManage && scheduledPlay.location) {
                this.form.get('location').setValue(scheduledPlay.location)
            }

            this.generateTimeOptions(selectedDay, parseInt(scheduledPlay.duration, 10))
        }
    }

    generateTimeOptions(day: any, duration: number): void {
        if (!day || !duration) {
            this.availableTimes = []
            return
        }

        const parseTime = (timeStr: string): Date => {
            const [time, modifier] = timeStr.split(' ')
            let [hours, minutes] = time.split(':').map(Number)
            if (modifier === 'PM' && hours < 12) {
                hours += 12
            }
            if (modifier === 'AM' && hours === 12) {
                hours = 0
            }
            const date = new Date()
            date.setHours(hours)
            date.setMinutes(minutes)
            date.setSeconds(0)
            date.setMilliseconds(0)
            return date
        }

        const startTime = parseTime(day.startTime)
        const endTime = parseTime(day.endTime)

        this.availableTimes = []

        let currentTime = new Date(startTime.getTime())

        while (currentTime.getTime() + duration * 60000 <= endTime.getTime()) {
            const timeString = currentTime.toLocaleTimeString([], {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
            })
            this.availableTimes.push(timeString)
            currentTime = new Date(currentTime.getTime() + 15 * 60000)
        }
    }

    onDayChange(day: any): void {
        this.form.get('selectedDuration').setValue(null)
        this.form.get('selectedTime').setValue(null)
        this.availableTimes = []
    }

    onDurationChange(duration: number): void {
        const day = this.form.get('selectedDay').value
        this.generateTimeOptions(day, duration)
    }

    selectGame(game: any): void {
        this.selectedGame = game[0]
        this.gameSelected = !!this.selectedGame
        this.form.get('gameTitle').setValue(this.selectedGame)

        if (this.selectedGame && this.selectedGame.expansions != '') {
            this.possibleExpansions = JSON.parse(this.selectedGame.expansions)
        } else {
            this.possibleExpansions = []
        }
    }

    // working here
    // working here
    // working here
    // working here
    // working here
    // working here
    // working here
    async submitForm() {
        if (this.form.valid && this.gameSelected) {
            const selectedDay = this.form.get('selectedDay').value
            const date = new Date(selectedDay.date)
            const selectedTime = this.form.get('selectedTime').value
            const [timePart, modifier] = selectedTime.split(' ')
            let [hours, minutes] = timePart.split(':').map(Number)

            if (modifier === 'PM' && hours < 12) {
                hours += 12
            }
            if (modifier === 'AM' && hours === 12) {
                hours = 0
            }

            date.setHours(hours)
            date.setMinutes(minutes)
            date.setSeconds(0)
            date.setMilliseconds(0)

            const startTime = date.toISOString()

            let pk: string
            let sk: string

            if (this.isEditing) {
                pk = this.data.inputData.scheduledPlay.pk
                sk = this.data.inputData.scheduledPlay.sk
            } else {
                pk = this.data.inputData.parentInputData.convention.pk
                sk = 'sp_' + ulid()
            }

            let conventionId = ''
            if (this.data.conventionId) {
                conventionId = this.data.inputData.conventionId
            } else if (
                this.data.inputData &&
                this.data.inputData.parentInputData &&
                this.data.inputData.parentInputData.convention &&
                this.data.inputData.parentInputData.convention.pk
            ) {
                conventionId = this.data.inputData.parentInputData.convention.pk.toString()
            } else {
                console.error('Convention ID not found')
                return
            }

            const createInput: any = {
                conventionId: conventionId,
                currentPlayers: '',
                date: selectedDay.date.toString(),
                duration: this.form.get('selectedDuration').value.toString(),
                maxPlayers: this.form.get('maxPlayers').value.toString(),
                name: this.selectedGame.title,
                eventTitle: this.form.get('title').value,
                cost: this.form.get('cost').value,
                notes: this.form.get('notes').value,
                pk: pk,
                sk: sk,
                startTime: startTime,
                waitlist: '',
                waitlistCapacity: this.form.get('waitlistCapacity').value.toString(),
                isPasswordProtected: this.form.get('isPasswordProtected').value.toString(),
                isApproved: true,
                location: this.hasAbilityToManage ? this.form.get('location').value : 'Not set',
            }

            if (this.form.get('isPasswordProtected').value) {
                createInput.password = this.form.get('password').value
            } else {
                createInput.password = ''
            }

            if (this.isEditing) {
                createInput.createdByTitle = this.data.inputData.scheduledPlay.createdByTitle
                createInput.createdByUID = this.data.inputData.scheduledPlay.createdByUID
            } else {
                createInput.createdByTitle = this.currentUser.title
                createInput.createdByUID = this.currentUser.username
            }

            await this.backendAPIContentService.createScheduledPlay(createInput)
            this.dialogRef.close(createInput)

            let NotificationInput = {
                notificationType: 'scheduledPlay',
                addedByTitle: createInput.createdByTitle,
                addedByUID: createInput.createdByUID,
                date: createInput.startTime,
                eventId: undefined,
                eventTitle: undefined,
                conventionTitle:
                    this.data.inputData.parentInputData.convention.extendedProps.eventTitle,
                conventionId: this.data.inputData.parentInputData.convention.pk,
                gameId: this.selectedGame.pk,
                gameMainImageFiles: undefined,
                gameTitle: this.selectedGame.title,
                groupId: undefined,
                groupTitle: undefined,
                havenId: undefined,
                havenTitle: undefined,
                userId: this.data.inputData.parentInputData.convention.extendedProps
                    .eventOrganizerId,
                userImage: undefined,
                eventStart: undefined,
                eventEnd: undefined,
            }

            await this.backendApiContentService.createNotificationItems(NotificationInput)
        } else {
            this.form.markAllAsTouched()
        }
    }
    // working here
    // working here
    // working here
    // working here
}
