import { Component, Input, OnInit } from '@angular/core';
import { StringHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/string-helper.service';

@Component({
  selector: 'app-simple-lp-detail-modal',
  templateUrl: './simple-lp-detail-modal.component.html',
  styleUrls: ['./simple-lp-detail-modal.component.scss']
})
export class SimpleLpDetailModalComponent implements OnInit {

  taggedUsersString

  inputData
  @Input() set _inputData(inputData) {

    this.inputData = inputData

    this.taggedUsersString = this.stringHelperService.createCommaSeparatedString(inputData.taggedUsers, true)
  }

  constructor(private stringHelperService: StringHelperService) { }

  ngOnInit(): void {
  }

}
