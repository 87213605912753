import { Injectable } from '@angular/core'
import { RadarChartAttributeEnums, RadarChartHavenAttributeEnums, RadarChartGroupAttributeEnums, RadarChartProfileFriendGameAttributeEnums } from 'src/app/dashboard/enums/radar-chart-enums'

@Injectable({
  providedIn: 'root'
})
export class RadarChartService {
  constructor() { }

  updateChartOption(options, optionToChange: string, value: any) {
    let radarChartOptionsStringified = JSON.stringify(options)
    let shallowCopy = JSON.parse(radarChartOptionsStringified)
    shallowCopy[optionToChange] = value
    return shallowCopy
  }

  // NOTE: not possible in TypeScript to accept enum as parameter so must use any
  // https://stackoverflow.com/questions/30774874/enum-as-parameter-in-typescript

  getUpdatedLabel(enumeration: any, propertyName: string, value: number, chartType: RadarChartAttributeEnums): string {
    return this.matchValueByPropertyName(enumeration, propertyName, value, chartType)
  }

  matchValueByPropertyName(enumeration: any, propertyName: string, chartValue: number, chartType: RadarChartAttributeEnums): string {
    var result
    Object.values(enumeration).forEach((value) => {
      if (value == propertyName) {
        result = this.getDisplayValue(propertyName, chartValue, chartType)
      }
    })
    return result
  }

  getDisplayValue(enumerationSelection: any, chartValue: number, chartType: RadarChartAttributeEnums): string {
    switch (chartType) {
      case RadarChartAttributeEnums.friend:
        return this.getProfileFriendGameDisplayValue(enumerationSelection, chartValue)

      case RadarChartAttributeEnums.game:
        return this.getProfileFriendGameDisplayValue(enumerationSelection, chartValue)

      case RadarChartAttributeEnums.group:
        return this.getGroupDisplayValue(enumerationSelection, chartValue)

      case RadarChartAttributeEnums.haven:
        return this.getHavenDisplayValue(enumerationSelection, chartValue)

      case RadarChartAttributeEnums.profile:
        return this.getProfileFriendGameDisplayValue(enumerationSelection, chartValue)
    }
  }

  getHavenDisplayValue(enumerationSelection: any, chartValue: number) {
    switch (enumerationSelection) {
      case RadarChartHavenAttributeEnums.gamingAtmosphere:
        return this.getValueForGamingAtmosphere(chartValue - 1)

      case RadarChartHavenAttributeEnums.competitionLevel:
        return this.getValueForCompetitionLevel(chartValue - 1)

      case RadarChartHavenAttributeEnums.tableCount:
        return this.getValueForTableCount(chartValue - 1)

      case RadarChartHavenAttributeEnums.librarySize:
        return this.getValueForLibrarySize(chartValue - 1)

      case RadarChartHavenAttributeEnums.retail:
        return this.getValueForRetail(chartValue - 1)

      case RadarChartHavenAttributeEnums.food:
        return this.getValueForFood(chartValue - 1)
    }
  }

  getGroupDisplayValue(enumerationSelection: any, chartValue: number) {
    switch (enumerationSelection) {
      case RadarChartGroupAttributeEnums.gamingAtmosphere:
        return this.getValueForGamingAtmosphere(chartValue - 1)

      case RadarChartGroupAttributeEnums.membership:
        return this.getValueForMembership(chartValue - 1)

      case RadarChartGroupAttributeEnums.competitionLevel:
        return this.getValueForCompetitionLevel(chartValue - 1)

      case RadarChartGroupAttributeEnums.focus:
        return this.getValueForFocus(chartValue - 1)

      case RadarChartGroupAttributeEnums.familyFriendly:
        return this.getValueForFamilyFriendly(chartValue - 1)

      case RadarChartGroupAttributeEnums.organization:
        return this.getValueForOrganization(chartValue - 1)
    }
  }

  getProfileFriendGameDisplayValue(enumerationSelection: any, chartValue: number) {
    switch (enumerationSelection) {
      case RadarChartProfileFriendGameAttributeEnums.complexity:
        return this.getValueForComplexity(chartValue - 1)

      case RadarChartProfileFriendGameAttributeEnums.depth:
        return this.getValueForDepth(chartValue - 1)

      case RadarChartProfileFriendGameAttributeEnums.duration:
        return this.getValueForDuration(chartValue - 1)

      case RadarChartProfileFriendGameAttributeEnums.interaction:
        return this.getValueForInteraction(chartValue - 1)

      case RadarChartProfileFriendGameAttributeEnums.competition:
        return this.getValueForCompetition(chartValue - 1)

      case RadarChartProfileFriendGameAttributeEnums.players:
        return this.getValueForPlayers(chartValue - 1)

      case RadarChartProfileFriendGameAttributeEnums.chance:
        return this.getValueForChance(chartValue - 1)

      case RadarChartProfileFriendGameAttributeEnums.theme:
        return this.getValueForTheme(chartValue - 1)
    }
  }

  getValueForGamingAtmosphere(chartValue): string {
    switch (chartValue) {
      case 0: return 'No Focus on Gaming'
      case 1: return 'Very Little Focus on Gaming'
      case 2: return 'Small Focus on Gaming'
      case 3: return 'Some Gaming Focus'
      case 4: return 'Less than average Focus on Gaming'
      case 5: return 'Average Focus on Gaming'
      case 6: return 'Above Average Focus on Tabletop Gaming'
      case 7: return 'Gaming Important to Haven'
      case 8: return 'Gaming Very Important'
      case 9: return 'Major Focus on Tabletop Gaming'
      case 10: return 'All About Tabletop Gaming'
    }
  }

  getValueForCompetitionLevel(chartValue): string {
    switch (chartValue) {
      case 0: return 'No Competition'
      case 1: return 'Small amounts of Competition'
      case 2: return 'Casual'
      case 3: return 'Relaxed'
      case 4: return 'Slighly Relaxed'
      case 5: return 'Average Competition'
      case 6: return 'Somewhat Competitive'
      case 7: return 'Competitive'
      case 8: return 'Very Competitive'
      case 9: return 'Extremely Competitive'
      case 10: return 'Brutal Competition'
    }
  }

  getValueForTableCount(chartValue): string {
    switch (chartValue) {
      case 0: return '0: No Tables'
      case 1: return '1: 1-2 Tables'
      case 2: return '2: 3-4 Tables'
      case 3: return '3: 5-6 Tables'
      case 4: return '4: 7-10 Tables'
      case 5: return '5: 11-15 Tables'
      case 6: return '6: 16-20 Tables'
      case 7: return '7: 21-30 Tables'
      case 8: return '8: 30-50 Tables'
      case 9: return '9: 51-100 Tables'
      case 10: return '10: 100+ Tables'
    }
  }

  getValueForLibrarySize(chartValue): string {
    switch (chartValue) {
      case 0: return 'No Games in Library'
      case 1: return '1-2 Games in Library'
      case 2: return '3-4 Games in Library'
      case 3: return '5-6 Games in Library'
      case 4: return '7-10 Games in Library'
      case 5: return '11-15 Games in Library'
      case 6: return '15-19 Games in Library'
      case 7: return '20-49 Games in Library'
      case 8: return '50-100 Games in Library'
      case 9: return '100s of Games in Library'
      case 10: return '1000s of Games in Library'
    }
  }

  getValueForRetail(chartValue): string {
    switch (chartValue) {
      case 0: return 'No Games for Sale'
      case 1: return 'Very Few Games for Sale'
      case 2: return 'A Few Games for Sale'
      case 3: return 'Some Games for Sale'
      case 4: return 'Several Games for Sale'
      case 5: return 'Average Retail Offerings'
      case 6: return 'Above Average Retail Offerings'
      case 7: return 'Lots of Games for Sale'
      case 8: return 'Large Game Inventory for Sale'
      case 9: return 'Very Large Game Inventory for Sale'
      case 10: return 'Huge Game Inventory for Sale'
    }
  }

  getValueForFood(chartValue): string {
    switch (chartValue) {
      case 0: return 'No Food Available'
      case 1: return 'Very Little Food Available'
      case 2: return 'A Little Food Available'
      case 3: return 'Some Food Available'
      case 4: return 'Less than Average Amounts of Food Available'
      case 5: return 'Average Amounts of Food Available'
      case 6: return 'More than Average Amounts of Food'
      case 7: return 'Even More Food'
      case 8: return 'Large Food Selection Available'
      case 9: return 'Restaurant'
      case 10: return 'Full Service Restaurant'
    }
  }

  getValueForMembership(chartValue): string {
    switch (chartValue) {
      case 0: return '1-2 Members'
      case 1: return '3-5 Members'
      case 2: return '6-10 Members'
      case 3: return '11-20 Members'
      case 4: return '21-40 Members'
      case 5: return '41-100 Members'
      case 6: return '101-300 Members'
      case 7: return '301-600 Members'
      case 8: return '1000 Members'
      case 9: return '1,000s of Members'
      case 10: return '10,000s of Members'
    }
  }

  getValueForFocus(chartValue): string {
    switch (chartValue) {
      case 0: return 'Plays Only 1 Game'
      case 1: return '2-4 Games Played'
      case 2: return 'A few Games Played'
      case 3: return 'Handfull Games Played'
      case 4: return '25-35 Games Played'
      case 5: return '35-45 Games Played'
      case 6: return '45-60 Games Played'
      case 7: return 'Lots of Games Played'
      case 8: return 'Many Games Played'
      case 9: return 'Huge number of Games Played'
      case 10: return 'ALL Games Played'
    }
  }

  getValueForFamilyFriendly(chartValue): string {
    switch (chartValue) {
      case 0: return 'Adults Only'
      case 1: return 'Kids Highly Discouraged'
      case 2: return 'Kids Discouraged'
      case 3: return 'Mostly Adults'
      case 4: return 'Trends toward adults'
      case 5: return 'Mix of Adults and Kids'
      case 6: return 'Family Friendly'
      case 7: return 'Very Family Friendly'
      case 8: return 'Large Family Focus'
      case 9: return 'Extremly Family Focused'
      case 10: return 'Entirely Family Focused'
    }
  }

  getValueForOrganization(chartValue): string {
    switch (chartValue) {
      case 0: return 'Completly Informal'
      case 1: return 'Very little structure'
      case 2: return 'Very informal'
      case 3: return 'Informal'
      case 4: return 'Somewhat unstructured'
      case 5: return 'Average'
      case 6: return 'Above average structure'
      case 7: return 'Lots of Structure'
      case 8: return 'Formal'
      case 9: return 'Very Formal'
      case 10: return 'Exceptionally Formal'
    }
  }

  getValueForComplexity(chartValue): string {
    switch (chartValue) {
      case 0: return '0 Exceptionally Easy'
      case 1: return '1 Very Easy'
      case 2: return '2 Simple'
      case 3: return '3 Fairly Simple'
      case 4: return '4 Average Complexity'
      case 5: return '5 Somewhat Complex'
      case 6: return '6 Moderatly Complex'
      case 7: return '7 Very Complex'
      case 8: return '8 Super Complex'
      case 9: return '9 Exceptionally Complex'
      case 10: return '10 WTF Complex'

    }
  }

  getValueForDepth(chartValue): string {
    switch (chartValue) {
      case 0: return 'Exceptionally Shallow'
      case 1: return 'Very easy to master'
      case 2: return 'Easy to master'
      case 3: return 'Somewhat hard to master'
      case 4: return 'A little hard to master'
      case 5: return 'Average Depth'
      case 6: return 'A little hard to master'
      case 7: return 'Somewhat hard to master'
      case 8: return 'Hard to master'
      case 9: return 'Very hard to master'
      case 10: return 'Exceptionally Deep'
    }
  }

  getValueForDuration(chartValue): string {
    switch (chartValue) {
      case 0: return 'A minute or less'
      case 1: return 'About 5 Minutes>'
      case 2: return 'About 10 Minutes>'
      case 3: return 'About 20 Minutes'
      case 4: return 'About 30 Minutes'
      case 5: return 'About 45 Minutes'
      case 6: return '1-2 Hours'
      case 7: return '2-4 Hours'
      case 8: return '4-8 hours'
      case 9: return 'An entire Day'
      case 10: return 'Multiple Days'
    }
  }

  getValueForInteraction(chartValue): string {
    switch (chartValue) {
      case 0: return 'Completely Independent'
      case 1: return 'Almost entirely independent'
      case 2: return 'Mostly Independent'
      case 3: return 'More Independence'
      case 4: return 'Somewhat independent'
      case 5: return 'Average interaction'
      case 6: return 'Somewhat interactive'
      case 7: return 'More Interaction'
      case 8: return 'Very interactive'
      case 9: return 'Nearly Constant Interaction'
      case 10: return 'Constant Interaction'
    }
  }

  getValueForCompetition(chartValue): string {
    switch (chartValue) {
      case 0: return 'Cooperative'
      case 1: return '2-5 members'
      case 2: return '6- members'
      case 3: return '20-30 members'
      case 4: return '40-50 members'
      case 5: return 'Average'
      case 6: return '2-5 members'
      case 7: return '2-5 members'
      case 8: return '2-5 members'
      case 9: return '2-5 members'
      case 10: return 'Duel'
    }
  }

  getValueForPlayers(chartValue): string {
    switch (chartValue) {
      case 0: return 'Solo is best'
      case 1: return '2 Players is best'
      case 2: return '2-3 Players is best'
      case 3: return '3 Players is best'
      case 4: return '3-4 players is best'
      case 5: return '4 players is best'
      case 6: return '4-5 players is best'
      case 7: return '4-6 players is best'
      case 8: return '6-8 players is best'
      case 9: return '8-12 players is best'
      case 10: return '12+ players is best'
    }
  }

  getValueForChance(chartValue): string {
    switch (chartValue) {
      case 0: return 'No Chance'
      case 1: return 'Almost no luck'
      case 2: return 'Very little luck'
      case 3: return 'Some luck'
      case 4: return 'Below average luck'
      case 5: return 'Average amount of Luck'
      case 6: return 'Above average luck'
      case 7: return 'Lots of luck'
      case 8: return 'Loads of luck'
      case 9: return 'Almost all luck'
      case 10: return 'Only Luck'
    }
  }

  getValueForTheme(chartValue): string {
    switch (chartValue) {
      case 0: return 'Completely Abstract'
      case 1: return 'Nearly Abstract'
      case 2: return 'Very little theme'
      case 3: return 'Some Theme'
      case 4: return 'Below Average Theme'
      case 5: return 'Avearage Theme'
      case 6: return 'Above Average Theme'
      case 7: return 'Very Immersive'
      case 8: return 'Super Duper Immersive'
      case 9: return 'Fully Immersive'
      case 10: return 'Virtual Reality'
    }
  }

}