import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants';
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component';
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service';
import { MessageDetailsComponent } from '../message-details/message-details.component';
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service';
import { BackendApiUserTrackingService } from 'src/app/backend-api-services/backend-api-user-tracking.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-friend-requests-table',
  templateUrl: './friend-requests-table.component.html',
  styleUrls: ['./friend-requests-table.component.scss']
})
export class FriendRequestsTableComponent implements OnInit {

  defaultUserImage = window.location.origin + '/assets/images/defaults/profile-placeholder.png'

  @ViewChild(MatTable) table: MatTable<any>

  dataSource = new MatTableDataSource<any>()
  @Input() set _dataSource(dataSource) {
    if (dataSource) {
      this.dataSource = new MatTableDataSource<any>(dataSource)
      this.getUserImages()
    }
  }

  displayedColumns
  @Input() set _displayedColumns(displayedColumns: string[]) {
    this.displayedColumns = displayedColumns
  }

  isIncoming
  @Input() set _isIncoming(isIncoming: boolean) {
    this.isIncoming = isIncoming
  }

  user
  @Input() set _user(user: boolean) {
    this.user = user
  }


  hasDeleteButtons
  @Input() set _hasDeleteButtons(hasDeleteButtons: boolean) {
    this.hasDeleteButtons = hasDeleteButtons
  }

  hasConfirmationButtons
  @Input() set _hasConfirmationButtons(hasConfirmationButtons: boolean) {
    this.hasConfirmationButtons = hasConfirmationButtons
  }

  @Output() composeMessageEmitter = new EventEmitter()

  constructor(private screenSizeService: ScreenSizeService,
    private dialog: MatDialog,
    private backendApiMessageService: BackendAPIMessageService,
    private backendAPIUserTrackingService: BackendApiUserTrackingService,
    private backendAPIUsersService: BackendAPIUsersService,
    private snackbar: MatSnackBar,
    private arrayHelperService: ArrayHelperService,
    private backendApiUsersService: BackendAPIUsersService) { }

  isMobileScreen = false
  ngOnInit(): void {
    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })
  }

  async getUserImages() {
    if (this.dataSource.filteredData && this.dataSource.filteredData.length > 0) {
      for (let item of this.dataSource.filteredData) {
        if (this.isIncoming) {
          let res = await this.backendApiUsersService.GetUserMainImageFiles(item.createdBy)
          item.mainImageFiles = res.mainImageFiles
        }
        else {
          let res = await this.backendApiUsersService.GetUserMainImageFiles(item.sentToUID)
          item.mainImageFiles = res.mainImageFiles
        }
      }
    }
  }

  seeMessageDetails(item) {
    let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      data: {
        title: 'Message Details',
        component: MessageDetailsComponent,


        inputData: item,


        hasSubmitButton: true,
        hasCancelButton: true,
        submitButtonText: 'Reply',
        allowParentClose: true,
        hasLeftActionButton: true,
        leftActionButtonText: 'Delete Message',
      },
    })

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.composeMessageEmitter.emit({ currentUser: data.currentUser, messageToReplyTo: data.messageToReplyTo })
        }
      })
  }

  async declineInviteItem(item, type) {
    let messageInput = { pk: this.user.username, sk: item.sk_type_ULID }
    await this.backendApiMessageService.deleteMessage(messageInput)
  }


  async confirmRequest(user, element, type) {

    let input1 = {
      userId: user,
      targetId: element.createdBy,
      type: 'friendOf'
    }

    this.backendAPIUserTrackingService.createUserTrackingItem(input1)

    let input42 = {
      userId: element.createdBy,
      targetId: user,
      type: 'friendOf'
    }

    this.backendAPIUserTrackingService.createUserTrackingItem(input42)

    this.backendAPIUserTrackingService.listUserTrackingItemsByType(user, 'friendOf', 500)


    let otherUserData = await this.backendAPIUsersService.getUserByTitle(element.sentFromTitle)


    // ********************
    //  todo Deletion of messages
    // ********************
    this.snackbar.open('Adding: ' + otherUserData.title, 'Processing.', {
      duration: 2500,
    })

    let removeInvitationInput = { pk: this.user.username, sk: element.sk_type_ULID }
    await this.backendApiMessageService.deleteMessage(removeInvitationInput)

    let carl = '#FriendRequestOutgoing#' + this.user.username

    let removeOtherInput = { pk: otherUserData.username, sk: carl }
    await this.backendApiMessageService.deleteMessage(removeOtherInput)

    let carl2 = '#FriendRequestIncoming#' + otherUserData.username

    let removeOtherInput2 = { pk: this.user.username, sk: carl2 }
    await this.backendApiMessageService.deleteMessage(removeOtherInput2)

    this.dataSource = this.arrayHelperService.removeFromArray(
      this.dataSource,
      element,
      true,
      'sk_type_ULID',
    )
    this.table.renderRows()

    await this.backendAPIUsersService.initCurrentUser()
  }

  async cancelOutgoingInviteItem(item, type) {
    let deleteOutgoingInviteInput = { pk: this.user.username, sk: item.sk_type_ULID }
    await this.backendApiMessageService.deleteMessage(deleteOutgoingInviteInput)

    let deleteIncomingInviteInput = {
      pk: item.sentToUID,
      sk: item.sk_type_ULID.replace('Outgoing', 'Incoming'),
    }
    await this.backendApiMessageService.deleteMessage(deleteIncomingInviteInput)

    let tempDataSource = this.arrayHelperService.removeFromArray(
      this.dataSource,
      item,
      true,
      'sk_type_ULID',
    )
    this.dataSource = new MatTableDataSource<any>(tempDataSource)

    this.table.renderRows()
  }


}
