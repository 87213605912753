<div
    class="parent-container"
    [hidden]="!dataSource || !dataSource.filteredData || dataSource.filteredData.length == 0"
>
    <div class="px-2 parent-container">
        <div [hidden]="isMobileScreen">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                    <td mat-cell *matCellDef="let element">{{ element.date | date }}</td>
                </ng-container>

                <ng-container matColumnDef="gamePlayed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Game</th>
                    <td mat-cell *matCellDef="let element">{{ element.gamePlayed }}</td>
                </ng-container>

                <ng-container matColumnDef="havenTitle">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Haven</th>
                    <td mat-cell *matCellDef="let element">{{ element.havenTitle }}</td>
                </ng-container>

                <ng-container matColumnDef="userTitle">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
                    <td mat-cell *matCellDef="let element">{{ element.taggedUsers[0].title }}</td>
                </ng-container>

                <ng-container matColumnDef="publicNotes">
                    <th mat-header-cell *matHeaderCellDef>Public Notes</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.publicNotes }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="image" class="ml-2">
                    <th mat-header-cell *matHeaderCellDef>Pic</th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            *ngIf="element.mainImageFiles"
                            mat-button
                            (click)="seeImageDetails(element)"
                        >
                            <mat-icon>image</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <!-- <tr (click)="logRow(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <ng-container *ngTemplateOutlet="paginatorTemplate"> </ng-container>
        </div>

        <div [hidden]="!isMobileScreen" id="mobile-table-container">
            <mat-table [dataSource]="dataSource" multiTemplateDataRows>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef>User</th>

                    <!-- element.taggedUsers[0].title -->
                    <td mat-cell *matCellDef="let row">
                        {{ truncate(row.taggedUsers[0].title, 40) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let row">{{ row.date | date }}</td>
                </ng-container>

                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon class="down-arrow">keyboard_arrow_down</mat-icon>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="mobileColumns.length">
                        <div
                            class="element-detail"
                            [@detailExpand]="
                                element == mobilExpandedElement ? 'expanded' : 'collapsed'
                            "
                        >
                            <div class="my-3 row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-card-title class="gh-xxsm-title"
                                                >Public Notes:</mat-card-title
                                            >
                                        </div>
                                        <div class="col-12">
                                            <mat-card-subtitle>{{
                                                element.publicNotes | nullPlaceholder
                                            }}</mat-card-subtitle>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <mat-card-title class="gh-xxsm-title"
                                                >Players:</mat-card-title
                                            >
                                        </div>
                                        <div class="col-12">
                                            <mat-card-subtitle>{{
                                                truncate(
                                                    element.commaSeparatedPlayersList,
                                                    tooltipMaxLength
                                                ) | nullPlaceholder
                                            }}</mat-card-subtitle>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-1 text-center col-12">
                                    <mat-icon
                                        class="gh-icon gh-icon-clickable gh-icon-primary"
                                        matTooltip="View Detail"
                                        matTooltipPosition="above"
                                        (click)="viewLPDetail(element)"
                                    >
                                        zoom_in
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="mobileColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let element; columns: mobileColumns"
                    [class.expanded-row]="mobilExpandedElement === element"
                    (click)="
                        mobilExpandedElement = mobilExpandedElement === element ? null : element
                    "
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: ['expandedDetail']"
                    class="detail-row"
                ></tr>
            </mat-table>

            <ng-container *ngTemplateOutlet="paginatorTemplate"> </ng-container>
        </div>

        <ng-template #paginatorTemplate>
            <mat-paginator
                id="paginator"
                #paginator
                [pageSize]="5"
                [pageSizeOptions]="[5, 10, 25, 100]"
                showFirstLastButtons
            ></mat-paginator>
        </ng-template>
    </div>
</div>

<app-no-data
    [hidden]="dataSource && dataSource.filteredData && dataSource.filteredData.length > 0"
    [_message]="'No data...'"
></app-no-data>
