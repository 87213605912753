import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'

@Component({
  selector: 'app-image-zoom-gallery[_sourceOfInput]',
  templateUrl: './image-zoom-gallery.component.html',
  styleUrls: ['./image-zoom-gallery.component.scss']
})
export class ImageZoomGalleryComponent implements OnInit {

  @Input() _sourceOfInput: string

  carouselContent
  images = []

  inputData
  @Input() set _inputData(inputData) {
      this.inputData = inputData 
  }

  @Output() closeEmitter = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
    for (let img of this.inputData) {
      this.images.push(img.imgPath)
    }
    this.carouselContent = this.images
  }

  close() {
    this.closeEmitter.emit()
  }

}
