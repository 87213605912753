import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'

@Component({
    selector: 'app-game-detail-action-buttons',
    templateUrl: './game-detail-action-buttons.component.html',
    styleUrls: ['./game-detail-action-buttons.component.scss'],
})

@UntilDestroy({ checkProperties: true })

export class GameDetailActionButtonsComponent implements OnInit {
    isInMyGames
    @Input() set _isInMyGames(isInMyGames: boolean) {
        this.isInMyGames = isInMyGames
    }

    isInWishlist
    @Input() set _isInWishlist(isInWishlist: boolean) {
        this.isInWishlist = isInWishlist
    }

    isInDemoLibrary
    @Input() set _isInDemoLibrary(isInDemoLibrary: boolean) {
        this.isInDemoLibrary = isInDemoLibrary
    }

    isInLendingLibrary
    @Input() set _isInLendingLibrary(isInLendingLibrary: boolean) {
        this.isInLendingLibrary = isInLendingLibrary
    }

    isInUsuallyStocked
    @Input() set _isInUsuallyStocked(isInUsuallyStocked: boolean) {
        this.isInUsuallyStocked = isInUsuallyStocked
    }

    userIsHaven
    @Input() set _userIsHaven(userIsHaven: boolean) {
        this.userIsHaven = userIsHaven
        // alert('userIsHaven ' + userIsHaven)
    }
    isGHAdmin = false
    @Input() set _isGHAdmin(isGHAdmin: boolean) {
        this.isGHAdmin = isGHAdmin
    }

    @Output() addToMyGamesEmitter = new EventEmitter()
    @Output() removeFromMyGamesEmitter = new EventEmitter()
    @Output() addToMyWishlistEmitter = new EventEmitter()
    @Output() removeFromMyWishlistEmitter = new EventEmitter()

    @Output() addToDemoLibraryEmitter = new EventEmitter()
    @Output() removeFromDemoLibraryEmitter = new EventEmitter()
    @Output() addToLendingLibraryEmitter = new EventEmitter()
    @Output() removeFromLendingLibraryEmitter = new EventEmitter()
    @Output() addToNormallyStockedEmitter = new EventEmitter()
    @Output() removeFromNormallyStockedEmitter = new EventEmitter()

    @Output() editGameDetailsEmitter = new EventEmitter()
    @Output() deleteGameEmitter = new EventEmitter()

    constructor(private screenSizeService: ScreenSizeService) { }

    isMobileScreen = false
    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    addToDemoLibrary() {
        this.addToDemoLibraryEmitter.emit()
    }
    removeFromDemoLibrary() {
        this.removeFromDemoLibraryEmitter.emit()
    }
    addToLendingLibrary() {
        this.addToLendingLibraryEmitter.emit()
    }
    removeFromLendingLibrary() {
        this.removeFromLendingLibraryEmitter.emit()
    }
    addToNormallyStocked() {
        this.addToNormallyStockedEmitter.emit()
    }
    removeFromNormallyStocked() {
        this.removeFromNormallyStockedEmitter.emit()
    }

    addToMyGames() {
        this.addToMyGamesEmitter.emit()
    }

    removeFromMyGames() {
        this.removeFromMyGamesEmitter.emit()
    }

    addToWishlist() {
        this.addToMyWishlistEmitter.emit()
    }

    removeFromWishlist() {
        this.removeFromMyWishlistEmitter.emit()
    }

    editGameDetails() {
        this.editGameDetailsEmitter.emit()
    }

    deleteGame() {
        this.deleteGameEmitter.emit()
    }
}
