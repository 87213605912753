<mat-form-field class="gh-form-field ">
    <mat-label>{{_label}}</mat-label>
    <mat-chip-list id="chip-list" #chipList aria-label="Chip list">
        <mat-chip *ngFor="let item of selectedItems; let i = index" [selectable]="selectable"
            [removable]="getCanRemoveChip(item)" (removed)="remove(item)"
            [ngClass]="{ flaggedAsProblematic: getItemIsFlaggedAsProblematic(i) }"
            matTooltip="{{getItemIsFlaggedAsProblematic(i) ? flaggedAsProblematicTooltip : ''}}"
            matTooltipPosition="above">
            {{_itemsAreObjects ? item.title : item}}
            <mat-icon matChipRemove *ngIf="getCanRemoveChip(item)">cancel</mat-icon>
        </mat-chip>

        <!-- IMPORTANT: 
                setting autocomplete="off" wasnt working, and setting it to new-password is apparently the new solution?
                reference: https://stackoverflow.com/questions/25823448/ng-form-and-autocomplete-off
        -->

        <!--! IMPORTANT 2: 
                I reverted back to "off" and it is working now, at least in the current UI that I am working with (publishers in create game)
        -->


        <input #input [formControl]="itemControl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)" (focus)="clearSearch($event)" (input)="onKeyDown()" autocomplete="off">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
            {{_itemsAreObjects ? item.title : item}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>