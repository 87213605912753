<div class="p-5 parent-container">
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <!-- <p style='margin-top:24.0pt;margin-right:0in;margin-bottom:6.0pt;margin-left:0in;font-size:48px;font-family:"Times New Roman",serif;color:black;font-weight:bold;margin:0in;line-height:115%;'><span style='font-family:"Arial",sans-serif;'>WEBSITE TERMS OF USE TEMPLATE</span></p> -->
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 115%;
        "
    >
        <span style="font-family: 'Arial', sans-serif; color: #434343">Last updated 10-6-22</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 115%;
        "
    >
        <span style="font-size: 8px; line-height: 115%; font-family: 'Arial', sans-serif"
            >&nbsp;</span
        >
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">AGREEMENT TO TERMS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >These Terms of Use constitute a legally binding agreement made between you, whether
            personally or on behalf of an entity (&ldquo;you&rdquo;) and GAME HAVEN
            LLC<strong>&nbsp;</strong>(&ldquo;we,&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;),
            concerning your access to and use of the mygamehaven.com website as well as any other
            media form, media channel, mobile website or mobile application related, linked, or
            otherwise connected thereto (collectively, the &ldquo;Site&rdquo;).&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You agree that by accessing the Site, you have read, understood, and agree to be bound
            by all of these Terms of Use. If you do not agree with all of these Terms of Use, then
            you are expressly prohibited from using the Site and you must discontinue use
            immediately.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Supplemental Terms of Use or documents that may be posted on the Site from time to time
            are hereby expressly incorporated herein by reference. We reserve the right, in our sole
            discretion, to make changes or modifications to these Terms of Use at any time and for
            any reason.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We will alert you about any changes by updating the &ldquo;Last updated&rdquo; date of
            these Terms of Use, and you waive any right to receive specific notice of each such
            change.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >It is your responsibility to periodically review these Terms of Use to stay informed of
            updates. You will be subject to, and will be deemed to have been made aware of and to
            have accepted, the changes in any revised Terms of Use by your continued use of the Site
            after the date such revised Terms of Use are posted.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The information provided on the Site is not intended for distribution to or use by any
            person or entity in any jurisdiction or country where such distribution or use would be
            contrary to law or regulation or which would subject us to any registration requirement
            within such jurisdiction or country.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Accordingly, those persons who choose to access the Site from other locations do so on
            their own initiative and are solely responsible for compliance with local laws, if and
            to the extent local laws are applicable.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">These&nbsp;</span
        ><a href="https://termly.io/resources/templates/terms-of-use-template/"
            ><span style="font-family: 'Arial', sans-serif">terms of use</span></a
        ><span style="font-family: 'Arial', sans-serif"
            >&nbsp;were<span style="color: windowtext">&nbsp;created using Termly.</span></span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The Site is intended for users who are at least 13 years of age. All users who are
            minors in the jurisdiction in which they reside (generally under the age of 18) must
            have the permission of, and be directly supervised by, their parent or guardian to use
            the Site. If you are a minor, you must have your parent or guardian read and agree to
            these Terms of Use prior to you using the Site.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">INTELLECTUAL PROPERTY RIGHTS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Unless otherwise indicated, the Site is our proprietary property and all source code,
            databases, functionality, software, website designs, audio, video, text, photographs,
            and graphics on the Site (collectively, the &ldquo;Content&rdquo;) and the trademarks,
            service marks, and logos contained therein (the &ldquo;Marks&rdquo;) are owned or
            controlled by us or licensed to us, and are protected by copyright and trademark laws
            and various other intellectual property rights and unfair competition laws of the United
            States, foreign jurisdictions, and international conventions.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The Content and the Marks are provided on the Site &ldquo;AS IS&rdquo; for your
            information and personal use only. Except as expressly provided in these Terms of Use,
            no part of the Site and no Content or Marks may be copied, reproduced, aggregated,
            republished, uploaded, posted, publicly displayed, encoded, translated, transmitted,
            distributed, sold, licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Provided that you are eligible to use the Site, you are granted a limited license to
            access and use the Site and to download or print a copy of any portion of the Content to
            which you have properly gained access solely for your personal, non-commercial use. We
            reserve all rights not expressly granted to you in and to the Site, the Content and the
            Marks.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">USER REPRESENTATIONS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >By using the Site, you represent and warrant that:&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(1) all registration information you submit will be true, accurate, current, and
            complete;<strong>&nbsp;</strong>(2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <strong><span style="font-family: 'Arial', sans-serif">&nbsp;</span></strong>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(3) you have the legal capacity and you agree to comply with these Terms of
            Use;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">(4) you are not under the age of 13;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(5) not a minor in the jurisdiction in which you reside, or if a minor, you have
            received parental permission to use the Site;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(6) you will not access the Site through automated or non-human means, whether through
            a bot, script, or otherwise;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(7) you will not use the Site for any illegal or unauthorized purpose;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(8) your use of the Site will not violate any applicable law or regulation.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If you provide any information that is untrue, inaccurate, not current, or incomplete,
            we have the right to suspend or terminate your account and refuse any and all current or
            future use of the Site (or any portion thereof).&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">USER REGISTRATION</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You may be required to register with the Site. You agree to keep your password
            confidential and will be responsible for all use of your account and password. We
            reserve the right to remove, reclaim, or change a username you select if we determine,
            in our sole discretion, that such username is inappropriate, obscene, or otherwise
            objectionable.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">PROHIBITED ACTIVITIES</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You may not access or use the Site for any purpose other than that for which we make
            the Site available. The Site may not be used in connection with any commercial endeavors
            except those that are specifically endorsed or approved by us.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >As a user of the Site, you agree not to:</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <ol style="list-style-type: decimal; margin-left: 18.35px">
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >systematically retrieve data or other content from the Site to create or compile,
                directly or indirectly, a collection, compilation, database, or directory without
                written permission from us.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >make any unauthorized use of the Site, including collecting usernames and/or email
                addresses of users by electronic or other means for the purpose of sending
                unsolicited email, or creating user accounts by automated means or under false
                pretenses.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >use a buying agent or purchasing agent to make purchases on the Site.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >use the Site to advertise or offer to sell goods and services.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >circumvent, disable, or otherwise interfere with security-related features of the
                Site, including features that prevent or restrict the use or copying of any Content
                or enforce limitations on the use of the Site and/or the Content contained
                therein.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >engage in unauthorized framing of or linking to the Site.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >trick, defraud, or mislead us and other users, especially in any attempt to learn
                sensitive account information such as user passwords;</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >make improper use of our support services or submit false reports of abuse or
                misconduct.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >engage in any automated use of the system, such as using scripts to send comments
                or messages, or using any data mining, robots, or similar data gathering and
                extraction tools.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >interfere with, disrupt, or create an undue burden on the Site or the networks or
                services connected to the Site.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >attempt to impersonate another user or person or use the username of another
                user.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >sell or otherwise transfer your profile.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >use any information obtained from the Site in order to harass, abuse, or harm
                another person.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >use the Site as part of any effort to compete with us or otherwise use the Site
                and/or the Content for any revenue-generating endeavor or commercial
                enterprise.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >decipher, decompile, disassemble, or reverse engineer any of the software
                comprising or in any way making up a part of the Site.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >attempt to bypass any measures of the Site designed to prevent or restrict access
                to the Site, or any portion of the Site.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >harass, annoy, intimidate, or threaten any of our employees or agents engaged in
                providing any portion of the Site to you.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >delete the copyright or other proprietary rights notice from any Content.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >copy or adapt the Site&rsquo;s software, including but not limited to Flash, PHP,
                HTML, JavaScript, or other code.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or
                other material, including excessive use of capital letters and spamming (continuous
                posting of repetitive text), that interferes with any party&rsquo;s uninterrupted
                use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes
                with the use, features, functions, operation, or maintenance of the Site.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >upload or transmit (or attempt to upload or to transmit) any material that acts as
                a passive or active information collection or transmission mechanism, including
                without limitation, clear graphics interchange formats (&ldquo;gifs&rdquo;),
                1&times;1 pixels, web bugs, cookies, or other similar devices (sometimes referred to
                as &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo; or
                &ldquo;pcms&rdquo;).</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >except as may be the result of standard search engine or Internet browser usage,
                use, launch, develop, or distribute any automated system, including without
                limitation, any spider, robot, cheat utility, scraper, or offline reader that
                accesses the Site, or using or launching any unauthorized script or other
                software.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >use the Site in a manner inconsistent with any applicable laws or
                regulations.</span
            >
        </li>
    </ol>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <strong
            ><span style="font-size: 21px; line-height: 150%; font-family: 'Arial', sans-serif"
                >USER GENERATED CONTRIBUTIONS</span
            ></strong
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-size: 21px; line-height: 150%; font-family: 'Arial', sans-serif"
            >&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The Site may invite you to chat, contribute to, or participate in blogs, message
            boards, online forums, and other functionality, and may provide you with the opportunity
            to create, submit, post, display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Site, including but not limited to text, writings,
            video, audio, photographs, graphics, comments, suggestions, or personal information or
            other material (collectively, &quot;Contributions&quot;).&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Contributions may be viewable by other users of the Site and through third-party
            websites. As such, any Contributions you transmit may be treated as non-confidential and
            non-proprietary. When you create or make available any Contributions, you thereby
            represent and warrant that:</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <ol style="list-style-type: decimal; margin-left: 17px">
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >the creation, distribution, transmission, public display, or performance, and the
                accessing, downloading, or copying of your Contributions do not and will not
                infringe the proprietary rights, including but not limited to the copyright, patent,
                trademark, trade secret, or moral rights of any third party.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >you are the creator and owner of or have the necessary licenses, rights, consents,
                releases, and permissions to use and to authorize us, the Site, and other users of
                the Site to use your Contributions in any manner contemplated by the Site and these
                Terms of Use.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >you have the written consent, release, and/or permission of each and every
                identifiable individual person in your Contributions to use the name or likeness of
                each and every such identifiable individual person to enable inclusion and use of
                your Contributions in any manner contemplated by the Site and these Terms of
                Use.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions are not false, inaccurate, or misleading.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions are not unsolicited or unauthorized advertising, promotional
                materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of
                solicitation.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing,
                libelous, slanderous, or otherwise objectionable (as determined by us).</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions do not ridicule, mock, disparage, intimidate, or abuse
                anyone.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions do not advocate the violent overthrow of any government or
                incite, encourage, or threaten physical harm against another.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions do not violate any applicable law, regulation, or rule.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions do not violate the privacy or publicity rights of any third
                party.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions do not contain any material that solicits personal information
                from anyone under the age of 18 or exploits people under the age of 18 in a sexual
                or violent manner.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions do not violate any federal or state law concerning child
                pornography, or otherwise intended to protect the health or well-being of
                minors;</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions do not include any offensive comments that are connected to
                race, national origin, gender, sexual preference, or physical handicap.</span
            >
        </li>
        <li>
            <span style="font-family: 'Arial', sans-serif"
                >your Contributions do not otherwise violate, or link to material that violates, any
                provision of these Terms of Use, or any applicable law or regulation.</span
            >
        </li>
    </ol>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            margin-left: 0.3in;
            text-indent: -0.3in;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Any use of the Site in violation of the foregoing violates these Terms of Use and may
            result in, among other things, termination or suspension of your rights to use the
            Site.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">CONTRIBUTION LICENSE</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >By posting your Contributions to any part of the Site or making Contributions
            accessible to the Site by linking your account from the Site to any of your social
            networking accounts, you automatically grant, and you represent and warrant that you
            have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual,
            non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to
            host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle,
            archive, store, cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such Contributions (including,
            without limitation, your image and voice) for any purpose, commercial, advertising, or
            otherwise, and to prepare derivative works of, or incorporate into other works, such
            Contributions, and grant and authorize sublicenses of the foregoing. The use and
            distribution may occur in any media formats and through any media channels.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >This license will apply to any form, media, or technology now known or hereafter
            developed, and includes our use of your name, company name, and franchise name, as
            applicable, and any of the trademarks, service marks, trade names, logos, and personal
            and commercial images you provide. You waive all moral rights in your Contributions, and
            you warrant that moral rights have not otherwise been asserted in your
            Contributions.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We do not assert any ownership over your Contributions. You retain full ownership of
            all of your Contributions and any intellectual property rights or other proprietary
            rights associated with your Contributions. We are not liable for any statements or
            representations in your Contributions provided by you in any area on the
            Site.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You are solely responsible for your Contributions to the Site and you expressly agree
            to exonerate us from any and all responsibility and to refrain from any legal action
            against us regarding your Contributions. &nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We have the right, in our sole and absolute discretion, (1) to edit, redact, or
            otherwise change any Contributions; (2) to re-categorize any Contributions to place them
            in more appropriate locations on the Site; and (3) to pre-screen or delete any
            Contributions at any time and for any reason, without notice. We have no obligation to
            monitor your Contributions.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">GUIDELINES FOR REVIEWS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We may provide you areas on the Site to leave reviews or ratings. When posting a
            review, you must comply with the following criteria:&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(1) you should have firsthand experience with the person/entity being
            reviewed;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(2) your reviews should not contain offensive profanity, or abusive, racist, offensive,
            or hate language;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(3) your reviews should not contain discriminatory references based on religion, race,
            gender, national origin, age, marital status, sexual orientation, or
            disability;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(4) your reviews should not contain references to illegal activity;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(5) you should not be affiliated with competitors if posting negative
            reviews;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(6) you should not make any conclusions as to the legality of conduct;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(7) you may not post any false or misleading statements;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(8) you may not organize a campaign encouraging others to post reviews, whether
            positive or negative.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We may accept, reject, or remove reviews in our sole discretion. We have absolutely no
            obligation to screen reviews or to delete reviews, even if anyone considers reviews
            objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily
            represent our opinions or the views of any of our affiliates or partners.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We do not assume liability for any review or for any claims, liabilities, or losses
            resulting from any review. By posting a review, you hereby grant to us a perpetual,
            non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right
            and license to reproduce, modify, translate, transmit by any means, display, perform,
            and/or distribute all content relating to reviews.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;<strong>&nbsp;</strong></span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">MOBILE APPLICATION LICENSE</span>
    </h1>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 16px; line-height: 150%">&nbsp;</span>
    </h2>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 16px; line-height: 150%">Use License</span>
    </h2>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If you access the Site via a mobile application, then we grant you a revocable,
            non-exclusive, non-transferable, limited right to install and use the mobile application
            on wireless electronic devices owned or controlled by you, and to access and use the
            mobile application on such devices strictly in accordance with the Terms of Use of this
            mobile application license contained in these Terms of Use.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">You shall not:&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or
            decrypt the application;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(2) make any modification, adaptation, improvement, enhancement, translation, or
            derivative work from the application;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(3) violate any applicable laws, rules, or regulations in connection with your access
            or use of the application;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(4) remove, alter, or obscure any proprietary notice (including any notice of copyright
            or trademark) posted by us or the licensors of the application;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(5) use the application for any revenue generating endeavor, commercial enterprise, or
            other purpose for which it is not designed or intended;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(6) make the application available over a network or other environment permitting
            access or use by multiple devices or users at the same time;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(7) use the application for creating a product, service, or software that is, directly
            or indirectly, competitive with or in any way a substitute for the
            application;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(8) use the application to send automated queries to any website or to send any
            unsolicited commercial e-mail;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(9) use any proprietary information or any of our interfaces or our other intellectual
            property in the design, development, manufacture, licensing, or distribution of any
            applications, accessories, or devices for use with the application.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 16px; line-height: 150%">Apple and Android Devices</span>
    </h2>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The following terms apply when you use a mobile application obtained from either the
            Apple Store or Google Play (each an &ldquo;App Distributor&rdquo;) to access the
            Site:&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(1) the license granted to you for our mobile application is limited to a
            non-transferable license to use the application on a device that utilizes the Apple iOS
            or Android operating systems, as applicable, and in accordance with the usage rules set
            forth in the applicable App Distributor&rsquo;s Terms of Use;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(2) we are responsible for providing any maintenance and support services with respect
            to the mobile application as specified in the Terms of Use of this mobile application
            license contained in these Terms of Use or as otherwise required under applicable law,
            and you acknowledge that each App Distributor has no obligation whatsoever to furnish
            any maintenance and support services with respect to the mobile application;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(3) in the event of any failure of the mobile application to conform to any applicable
            warranty, you may notify the applicable App Distributor, and the App Distributor, in
            accordance with its terms and policies, may refund the purchase price, if any, paid for
            the mobile application, and to the maximum extent permitted by applicable law, the App
            Distributor will have no other warranty obligation whatsoever with respect to the mobile
            application;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(4) you represent and warrant that (i) you are not located in a country that is subject
            to a U.S. government embargo, or that has been designated by the U.S. government as a
            &ldquo;terrorist supporting&rdquo; country and (ii) you are not listed on any U.S.
            government list of prohibited or restricted parties;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(5) you must comply with applicable third-party terms of agreement when using the
            mobile application, e.g., if you have a VoIP application, then you must not be in
            violation of their wireless data service agreement when using the mobile
            application;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >6) you acknowledge and agree that the App Distributors are third-party beneficiaries of
            the Terms of Use in this mobile application license contained in these Terms of Use, and
            that each App Distributor will have the right (and will be deemed to have accepted the
            right) to enforce the Terms of Use in this mobile application license contained in these
            Terms of Use against you as a third-party beneficiary thereof. &nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">SOCIAL MEDIA</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >As part of the functionality of the Site, you may link your account with online
            accounts you have with third-party service providers (each such account, a
            &ldquo;Third-Party Account&rdquo;) by either: (1) providing your Third-Party Account
            login information through the Site; or (2) allowing us to access your Third-Party
            Account, as is permitted under the applicable Terms of Use that govern your use of each
            Third-Party Account.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You represent and warrant that you are entitled to disclose your Third-Party Account
            login information to us and/or grant us access to your Third-Party Account, without
            breach by you of any of the Terms of Use that govern your use of the applicable
            Third-Party Account, and without obligating us to pay any fees or making us subject to
            any usage limitations imposed by the third-party service provider of the Third-Party
            Account.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >By granting us access to any Third-Party Accounts, you understand that (1) we may
            access, make available, and store (if applicable) any content that you have provided to
            and stored in your Third-Party Account (the &ldquo;Social Network Content&rdquo;) so
            that it is available on and through the Site via your account, including without
            limitation any friend lists and (2) we may submit to and receive from your Third-Party
            Account additional information to the extent you are notified when you link your account
            with the Third-Party Account.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Depending on the Third-Party Accounts you choose and subject to the privacy settings
            that you have set in such Third-Party Accounts, personally identifiable information that
            you post to your Third-Party Accounts may be available on and through your account on
            the Site.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Please note that if a Third-Party Account or associated service becomes unavailable or
            our access to such Third-Party Account is terminated by the third-party service
            provider, then Social Network Content may no longer be available on and through the
            Site. You will have the ability to disable the connection between your account on the
            Site and your Third-Party Accounts at any time.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
            WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
            THIRD-PARTY SERVICE PROVIDERS.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We make no effort to review any Social Network Content for any purpose, including but
            not limited to, for accuracy, legality, or non-infringement, and we are not responsible
            for any Social Network Content.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You acknowledge and agree that we may access your email address book associated with a
            Third-Party Account and your contacts list stored on your mobile device or tablet
            computer solely for purposes of identifying and informing you of those contacts who have
            also registered to use the Site.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You can deactivate the connection between the Site and your Third-Party Account by
            contacting us using the contact information below or through your account settings (if
            applicable). We will attempt to delete any information stored on our servers that was
            obtained through such Third-Party Account, except the username and profile picture that
            become associated with your account.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">SUBMISSIONS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You acknowledge and agree that any questions, comments, suggestions, ideas, feedback,
            or other information regarding the Site (&quot;Submissions&quot;) provided by you to us
            are non-confidential and shall become our sole property. We shall own exclusive rights,
            including all intellectual property rights, and shall be entitled to the unrestricted
            use and dissemination of these Submissions for any lawful purpose, commercial or
            otherwise, without acknowledgment or compensation to you.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You hereby waive all moral rights to any such Submissions, and you hereby warrant that
            any such Submissions are original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any alleged or actual
            infringement or misappropriation of any proprietary right in your
            Submissions.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">&nbsp;</span>
    </h1>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">THIRD-PARTY WEBSITES AND CONTENT</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The Site may contain (or you may be sent via the Site) links to other websites
            (&quot;Third-Party Websites&quot;) as well as articles, photographs, text, graphics,
            pictures, designs, music, sound, video, information, applications, software, and other
            content or items belonging to or originating from third parties (&quot;Third-Party
            Content&quot;).&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Such Third-Party Websites and Third-Party Content are not investigated, monitored, or
            checked for accuracy, appropriateness, or completeness by us, and we are not responsible
            for any Third-Party Websites accessed through the Site or any Third-Party Content posted
            on, available through, or installed from the Site, including the content, accuracy,
            offensiveness, opinions, reliability, privacy practices, or other policies of or
            contained in the Third-Party Websites or the Third-Party Content.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Inclusion of, linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or endorsement thereof by
            us. If you decide to leave the Site and access the Third-Party Websites or to use or
            install any Third-Party Content, you do so at your own risk, and you should be aware
            these Terms of Use no longer govern.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You should review the applicable terms and policies, including privacy and data
            gathering practices, of any website to which you navigate from the Site or relating to
            any applications you use or install from the Site. Any purchases you make through
            Third-Party Websites will be through other websites and from other companies, and we
            take no responsibility whatsoever in relation to such purchases which are exclusively
            between you and the applicable third party.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You agree and acknowledge that we do not endorse the products or services offered on
            Third-Party Websites and you shall hold us harmless from any harm caused by your
            purchase of such products or services. Additionally, you shall hold us harmless from any
            losses sustained by you or harm caused to you relating to or resulting in any way from
            any Third-Party Content or any contact with Third-Party Websites.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">ADVERTISERS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We allow advertisers to display their advertisements and other information in certain
            areas of the Site, such as sidebar advertisements or banner advertisements. If you are
            an advertiser, you shall take full responsibility for any advertisements you place on
            the Site and any services provided on the Site or products sold through those
            advertisements.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Further, as an advertiser, you warrant and represent that you possess all rights and
            authority to place advertisements on the Site, including, but not limited to,
            intellectual property rights, publicity rights, and contractual rights.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >As an advertiser, you agree that such advertisements are subject to our Digital
            Millennium Copyright Act (&ldquo;DMCA&rdquo;) Notice and Policy provisions as described
            below, and you understand and agree there will be no refund or other compensation for
            DMCA takedown-related issues. We simply provide the space to place such advertisements,
            and we have no other relationship with advertisers. &nbsp;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <strong><span style="font-family: 'Arial', sans-serif">&nbsp;</span></strong>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">SITE MANAGEMENT</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We reserve the right, but not the obligation, to:&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(1) monitor the Site for violations of these Terms of Use;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(2) take appropriate legal action against anyone who, in our sole discretion, violates
            the law or these Terms of Use, including without limitation, reporting such user to law
            enforcement authorities;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(3) in our sole discretion and without limitation, refuse, restrict access to, limit
            the availability of, or disable (to the extent technologically feasible) any of your
            Contributions or any portion thereof;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(4) in our sole discretion and without limitation, notice, or liability, to remove from
            the Site or otherwise disable all files and content that are excessive in size or are in
            any way burdensome to our systems;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(5) otherwise manage the Site in a manner designed to protect our rights and property
            and to facilitate the proper functioning of the Site.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">PRIVACY POLICY</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We care about data privacy and security. Please review our Privacy Policy. By using the
            Site, you agree to be bound by our Privacy Policy, which is incorporated into these
            Terms of Use. Please be advised the Site is hosted in the United States.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If you access the Site from the European Union, Asia, or any other region of the world
            with laws or other requirements governing personal data collection, use, or disclosure
            that differ from applicable laws in the United States, then through your continued use
            of the Site, you are transferring your data to the United States, and you expressly
            consent to have your data transferred to and processed in the United States.</span
        ><span style="font-family: 'Verdana', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Verdana', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Further, we do not knowingly accept, request, or solicit information from children or
            knowingly market to children. Therefore, in accordance with the U.S. Children&rsquo;s
            Online Privacy Protection Act, if we receive actual knowledge that anyone under the age
            of 13 has provided personal information to us without the requisite and verifiable
            parental consent, we will delete that information from the Site as quickly as is
            reasonably practical.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%"
            >DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</span
        >
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 16px; line-height: 150%">Notifications</span>
    </h2>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We respect the intellectual property rights of others. If you believe that any material
            available on or through the Site infringes upon any copyright you own or control, please
            immediately notify our Designated Copyright Agent using the contact information provided
            below (a &ldquo;Notification&rdquo;).&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >A copy of your Notification will be sent to the person who posted or stored the
            material addressed in the Notification. Please be advised that pursuant to federal law
            you may be held liable for damages if you make material misrepresentations in a
            Notification. Thus, if you are not sure that material located on or linked to by the
            Site infringes your copyright, you should consider first contacting an attorney.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >All Notifications should meet the requirements of DMCA 17 U.S.C. &sect; 512(c)(3) and
            include the following information:&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(1) A physical or electronic signature of a person authorized to act on behalf of the
            owner of an exclusive right that is allegedly infringed;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(2) identification of the copyrighted work claimed to have been infringed, or, if
            multiple copyrighted works on the Site are covered by the Notification, a representative
            list of such works on the Site;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(3) identification of the material that is claimed to be infringing or to be the
            subject of infringing activity and that is to be removed or access to which is to be
            disabled, and information reasonably sufficient to permit us to locate the
            material;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(4) information reasonably sufficient to permit us to contact the complaining party,
            such as an address, telephone number, and, if available, an email address at which the
            complaining party may be contacted;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(5) a statement that the complaining party has a good faith belief that use of the
            material in the manner complained of is not authorized by the copyright owner, its
            agent, or the law;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(6) a statement that the information in the notification is accurate, and under penalty
            of perjury, that the complaining party is authorized to act on behalf of the owner of an
            exclusive right that is allegedly infringed upon.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
            text-indent: -0.3in;
        "
    >
        <em><span style="font-size: 16px; line-height: 150%">&nbsp; &nbsp; &nbsp; &nbsp;</span></em
        ><span style="font-size: 16px; line-height: 150%">Counter Notification</span>
    </h2>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If you believe your own copyrighted material has been removed from the Site as a result
            of a mistake or misidentification, you may submit a written counter notification to our
            Designated Copyright Agent using the contact information provided below (a
            &ldquo;Counter Notification&rdquo;).&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >To be an effective Counter Notification under the DMCA, your Counter Notification must
            include substantially the following:&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(1) identification of the material that has been removed or disabled and the location
            at which the material appeared before it was removed or disabled;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(2) a statement that you consent to the jurisdiction of the Federal District Court in
            which your address is located, or if your address is outside the United States, for any
            judicial district in which we are located;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(3) a statement that you will accept service of process from the party that filed the
            Notification or the party&apos;s agent;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(4) your name, address, and telephone number;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(5) a statement under penalty of perjury that you have a good faith belief that the
            material in question was removed or disabled as a result of a mistake or
            misidentification of the material to be removed or disabled;&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >(6) your physical or electronic signature.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            text-indent: -0.3in;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If you send us a valid, written Counter Notification meeting the requirements described
            above, we will restore your removed or disabled material, unless we first receive notice
            from the party filing the Notification informing us that such party has filed a court
            action to restrain you from engaging in infringing activity related to the material in
            question.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Please note that if you materially misrepresent that the disabled or removed content
            was removed by mistake or misidentification, you may be liable for damages, including
            costs and attorney&apos;s fees. Filing a false Counter Notification constitutes
            perjury.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">Designated Copyright Agent</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">David Razidlo</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">Attn: Copyright Agent</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">support@mygamehaven.com</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">COPYRIGHT INFRINGEMENTS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We respect the intellectual property rights of others. If you believe that any material
            available on or through the Site infringes upon any copyright you own or control, please
            immediately notify us using the contact information provided below (a
            &ldquo;Notification&rdquo;). A copy of your Notification will be sent to the person who
            posted or stored the material addressed in the Notification.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Please be advised that pursuant to federal law you may be held liable for damages if
            you make material misrepresentations in a Notification. Thus, if you are not sure that
            material located on or linked to by the Site infringes your copyright, you should
            consider first contacting an attorney.]</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">TERM AND TERMINATION</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >These Terms of Use shall remain in full force and effect while you use the Site.
            WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN
            OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE
            (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
            REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
            COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
            TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND]ANY CONTENT
            OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
            DISCRETION.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If we terminate or suspend your account for any reason, you are prohibited from
            registering and creating a new account under your name, a fake or borrowed name, or the
            name of any third party, even if you may be acting on behalf of the third
            party.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >In addition to terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing civil, criminal, and
            injunctive redress.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%"
            >MODIFICATIONS AND INTERRUPTIONS&nbsp;</span
        >
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We reserve the right to change, modify, or remove the contents of the Site at any time
            or for any reason at our sole discretion without notice. However, we have no obligation
            to update any information on our Site. We also reserve the right to modify or
            discontinue all or part of the Site without notice at any time.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We will not be liable to you or any third party for any modification, price change,
            suspension, or discontinuance of the Site.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We cannot guarantee the Site will be available at all times. We may experience
            hardware, software, or other problems or need to perform maintenance related to the
            Site, resulting in interruptions, delays, or errors.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We reserve the right to change, revise, update, suspend, discontinue, or otherwise
            modify the Site at any time or for any reason without notice to you. You agree that we
            have no liability whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Site during any downtime or discontinuance of the
            Site.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Nothing in these Terms of Use will be construed to obligate us to maintain and support
            the Site or to supply any corrections, updates, or releases in connection
            therewith.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
            margin-bottom: 2pt;
        "
    >
        <span style="font-size: 21px; line-height: 150%">GOVERNING LAW&nbsp;</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >These Terms of Use and your use of the Site are governed by and construed in accordance
            with the laws of the State of Minnesota<strong>&nbsp;</strong>applicable to agreements
            made and to be entirely performed within the State/Commonwealth of Minnesota, without
            regard to its conflict of law principles.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
            margin-bottom: 2pt;
        "
    >
        <span style="font-size: 21px; line-height: 150%">DISPUTE RESOLUTION</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <strong><span style="font-family: 'Arial', sans-serif">Option 1</span></strong
        ><span style="font-family: 'Arial', sans-serif"
            >: Any legal action of whatever nature brought by either you or us (collectively, the
            &ldquo;Parties&rdquo; and individually, a &ldquo;Party&rdquo;) shall be commenced or
            prosecuted in the state and federal courts located in Olmstead County, Minnesota, and
            the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction
            and forum non conveniens with respect to venue and jurisdiction in such state and
            federal courts.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Application of the United Nations Convention on Contracts for the International Sale of
            Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from
            these Terms of Use. In no event shall any claim, action, or proceeding brought by either
            Party related in any way to the Site be commenced more than ______ years after the cause
            of action arose.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
            margin-bottom: 5pt;
        "
    >
        <span style="font-size: 16px; line-height: 150%">Option 2: Informal Negotiations</span>
    </h2>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >To expedite resolution and control the cost of any dispute, controversy, or claim
            related to these Terms of Use (each a &quot;Dispute&quot; and collectively, the
            &ldquo;Disputes&rdquo;) brought by either you or us (individually, a &ldquo;Party&rdquo;
            and collectively, the &ldquo;Parties&rdquo;), the Parties agree to first attempt to
            negotiate any Dispute (except those Disputes expressly provided below) informally for at
            least <strong>______</strong> days before initiating arbitration. Such informal
            negotiations commence upon written notice from one Party to the other Party.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
            margin-bottom: 5pt;
        "
    >
        <span style="font-size: 16px; line-height: 150%">Binding Arbitration</span>
    </h2>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If the Parties are unable to resolve a Dispute through informal negotiations, the
            Dispute (except those Disputes expressly excluded below) will be finally and exclusively
            resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The arbitration shall be commenced and conducted under the Commercial Arbitration Rules
            of the American Arbitration Association (&quot;AAA&quot;) and, where appropriate, the
            AAA&rsquo;s Supplementary Procedures for Consumer Related Disputes (&quot;AAA Consumer
            Rules&quot;), both of which are available at the AAA website&nbsp;</span
        ><a href="http://www.adr.org"
            ><span style="font-family: 'Arial', sans-serif">www.adr.org</span></a
        ><span style="font-family: 'Arial', sans-serif">.&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Your arbitration fees and your share of arbitrator compensation shall be governed by
            the AAA Consumer Rules and, where appropriate, limited by the AAA Consumer
            Rules.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The arbitration may be conducted in person, through the submission of documents, by
            phone, or online. The arbitrator will make a decision in writing, but need not provide a
            statement of reasons unless requested by either Party.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The arbitrator must follow applicable law, and any award may be challenged if the
            arbitrator fails to do so. Except where otherwise required by the applicable AAA rules
            or applicable law, the arbitration will take place in Olmstead County,
            Minnesota.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Except as otherwise provided herein, the Parties may litigate in court to compel
            arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or
            enter judgment on the award entered by the arbitrator.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute
            shall be commenced or prosecuted in the state and federal courts located in Olmstead
            County, Minnesota, and the Parties hereby consent to, and waive all defenses of lack of
            personal jurisdiction, and forum non conveniens with respect to venue and jurisdiction
            in such state and federal courts.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Application of the United Nations Convention on Contracts for the International Sale of
            Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from
            these Terms of Use.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >In no event shall any Dispute brought by either Party related in any way to the Site be
            commenced more than _____ years after the cause of action arose. If this provision is
            found to be illegal or unenforceable, then neither Party will elect to arbitrate any
            Dispute falling within that portion of this provision found to be illegal or
            unenforceable and such Dispute shall be decided by a court of competent jurisdiction
            within the courts listed for jurisdiction above, and the Parties agree to submit to the
            personal jurisdiction of that court.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
            margin-bottom: 5pt;
        "
    >
        <span style="font-size: 16px; line-height: 150%">&nbsp;</span>
    </h2>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
            margin-bottom: 5pt;
        "
    >
        <span style="font-size: 16px; line-height: 150%">Option 3: Binding Arbitration</span>
    </h2>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >To expedite resolution and control the cost of any dispute, controversy or claim
            related to these Terms of Use (each a &quot;Dispute&quot; and collectively,
            &ldquo;Disputes&rdquo;), any Dispute brought by either you or us (individually, a
            &ldquo;Party&rdquo; and collectively, the &ldquo;Parties&rdquo;) shall be finally and
            exclusively resolved by binding arbitration.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT
            AND HAVE A JURY TRIAL. The arbitration shall be commenced and conducted under the
            Commercial Arbitration Rules of the American Arbitration Association (&quot;AAA&quot;)
            and, where appropriate, the AAA&rsquo;s Supplementary Procedures for Consumer Related
            Disputes (&quot;AAA Consumer Rules&quot;), both of which are available at the AAA
            website&nbsp;</span
        ><a href="http://www.adr.org"
            ><span style="font-family: 'Arial', sans-serif">www.adr.org</span></a
        ><span style="font-family: 'Arial', sans-serif">.&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Your arbitration fees and your share of arbitrator compensation shall be governed by
            the AAA Consumer Rules and, where appropriate, limited by the AAA Consumer Rules.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The arbitration may be conducted in person, through the submission of documents, by
            phone, or online. The arbitrator will make a decision in writing, but need not provide a
            statement of reasons unless requested by either Party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator fails to do
            so.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Except where otherwise required by the applicable AAA rules or applicable law, the
            arbitration will take place in Olmstead County, MN. Except as otherwise provided herein,
            the Parties may litigate in court to compel arbitration, stay proceedings pending
            arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by
            the arbitrator.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute
            shall be commenced or prosecuted in the state and federal courts located in Olmsted
            County, MN, and the Parties hereby consent to, and waive all defenses of lack of,
            personal jurisdiction, and forum non conveniens with respect to venue and jurisdiction
            in such state and federal courts.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Application of the United Nations Convention on Contracts for the International Sale of
            Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from
            these Terms of Use. In no event shall any Dispute brought by either Party related in any
            way to the Site or Services be commenced more than ______ years after the cause of
            action arose.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If this provision is found to be illegal or unenforceable, then neither Party will
            elect to arbitrate any Dispute falling within that portion of this provision found to be
            illegal or unenforceable and such Dispute shall be decided by a court of competent
            jurisdiction within the courts listed for jurisdiction above, and the Parties agree to
            submit to the personal jurisdiction of that court.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
            margin-bottom: 5pt;
        "
    >
        <span style="font-size: 16px; line-height: 150%">Option 2/Option 3: Restrictions</span>
    </h2>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The Parties agree that any arbitration shall be limited to the Dispute between the
            Parties individually. To the full extent permitted by law, (a) no arbitration shall be
            joined with any other proceeding; (b) there is no right or authority for any Dispute to
            be arbitrated on a class-action basis or to utilize class action procedures; and (c)
            there is no right or authority for any Dispute to be brought in a purported
            representative capacity on behalf of the general public or any other persons.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h2
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 15px;
            font-family: 'Arial', sans-serif;
            color: black;
            margin-bottom: 5pt;
        "
    >
        <span style="font-size: 16px; line-height: 150%"
            >Option 2/Option 3: Exceptions and Arbitration</span
        >
    </h2>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >The Parties agree that the following Disputes are not subject to the above provisions
            concerning informal negotiations and binding arbitration: (a) any Disputes seeking to
            enforce or protect, or concerning the validity of, any of the intellectual property
            rights of a Party; (b) any Dispute related to, or arising from, allegations of theft,
            piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive
            relief.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If this provision is found to be illegal or unenforceable, then neither Party will
            elect to arbitrate any Dispute falling within that portion of this provision found to be
            illegal or unenforceable and such Dispute shall be decided by a court of competent
            jurisdiction within the courts listed for jurisdiction above, and the Parties agree to
            submit to the personal jurisdiction of that court.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <strong><span style="font-family: 'Arial', sans-serif">&nbsp;</span></strong>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">CORRECTIONS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >There may be information on the Site that contains typographical errors, inaccuracies,
            or omissions that may relate to the Site, including descriptions, pricing, availability,
            and various other information. We reserve the right to correct any errors, inaccuracies,
            or omissions and to change or update the information on the Site at any time, without
            prior notice.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">DISCLAIMER</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE
            SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW,
            WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE
            THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
            REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE&rsquo;S CONTENT OR THE
            CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
            RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
            (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
            ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
            SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
            THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY
            BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
            SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS
            OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
            POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT,
            ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
            OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
            MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
            PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">LIMITATIONS OF LIABILITY</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY
            THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
            PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
            ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">INDEMNIFICATION</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
            affiliates, and all of our respective officers, agents, partners, and employees, from
            and against any loss, damage, liability, claim, or demand, including reasonable
            attorneys&rsquo; fees and expenses, made by any third party due to or arising out of:
            (1) your Contributions; (2) use of the Site; (3) breach of these Terms of Use; (4) any
            breach of your representations and warranties set forth in these Terms of Use; (5) your
            violation of the rights of a third party, including but not limited to intellectual
            property rights; or (6) any overt harmful act toward any other user of the Site with
            whom you connected via the Site.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Notwithstanding the foregoing, we reserve the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are required to indemnify us,
            and you agree to cooperate, at your expense, with our defense of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or proceeding which is
            subject to this indemnification upon becoming aware of it.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">USER DATA</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >We will maintain certain data that you transmit to the Site for the purpose of managing
            the Site, as well as data relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data that you transmit or
            that relates to any activity you have undertaken using the Site.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You agree that we shall have no liability to you for any loss or corruption of any such
            data, and you hereby waive any right of action against us arising from any such loss or
            corruption of such data.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <strong><span style="font-family: 'Arial', sans-serif">&nbsp;</span></strong>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%"
            >ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span
        >
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >Visiting the Site, sending us emails, and completing online forms constitute electronic
            communications. You consent to receive electronic communications, and you agree that all
            agreements, notices, disclosures, and other communications we provide to you
            electronically, via email and on the Site, satisfy any legal requirement that such
            communication be in writing.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
            INITIATED OR COMPLETED BY US OR VIA THE SITE.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You hereby waive any rights or requirements under any statutes, regulations, rules,
            ordinances, or other laws in any jurisdiction which require an original signature or
            delivery or retention of non-electronic records, or to payments or the granting of
            credits by any means other than electronic means.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">CALIFORNIA USERS AND RESIDENTS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If any complaint with us is not satisfactorily resolved, you can contact the Complaint
            Assistance Unit of the Division of Consumer Services of the California Department of
            Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento,
            California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">&nbsp;</span>
    </h1>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">MISCELLANEOUS</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >These Terms of Use and any policies or operating rules posted by us on the Site
            constitute the entire agreement and understanding between you and us. Our failure to
            exercise or enforce any right or provision of these Terms of use shall not operate as a
            waiver of such right or provision.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >These Terms of Use operate to the fullest extent permissible by law. We may assign any
            or all of our rights and obligations to others at any time. We shall not be responsible
            or liable for any loss, damage, delay, or failure to act caused by any cause beyond our
            reasonable control.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >If any provision or part of a provision of these Terms of Use is determined to be
            unlawful, void, or unenforceable, that provision or part of the provision is deemed
            severable from these Terms of Use and does not affect the validity and enforceability of
            any remaining provisions.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >There is no joint venture, partnership, employment or agency relationship created
            between you and us as a result of these Terms of Use or use of the Site. You agree that
            these Terms of Use will not be construed against us by virtue of having drafted
            them.&nbsp;</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >You hereby waive any and all defenses you may have based on the electronic form of
            these Terms of Use and the lack of signing by the parties hereto to execute these Terms
            of Use.</span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">&nbsp;</span>
    </h1>
    <h1
        style="
            margin: 0in;
            text-align: left;
            line-height: 150%;
            font-size: 19px;
            font-family: 'Arial', sans-serif;
            color: black;
        "
    >
        <span style="font-size: 21px; line-height: 150%">CONTACT US&nbsp;</span>
    </h1>
    <p style="margin: 0in; font-size: 16px; font-family: 'Times New Roman', serif; color: black">
        &nbsp;
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif"
            >In order to resolve a complaint regarding the Site or to receive further information
            regarding use of the Site, please contact us at:<strong>&nbsp;</strong></span
        >
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">Game Haven LLC</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">support@mygamehaven.com</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
    <table style="width: 4.7e2pt; border: none; border-collapse: collapse">
        <tbody>
            <tr>
                <td style="width: 221.5pt; padding: 0.75pt; vertical-align: top">
                    <p
                        style="
                            margin: 0in;
                            font-size: 16px;
                            font-family: 'Times New Roman', serif;
                            color: black;
                            line-height: 150%;
                        "
                    >
                        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
                    </p>
                </td>
                <td style="width: 29.5pt; padding: 0.75pt; vertical-align: top">
                    <p
                        style="
                            margin: 0in;
                            font-size: 16px;
                            font-family: 'Times New Roman', serif;
                            color: black;
                            line-height: 150%;
                        "
                    >
                        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
                    </p>
                </td>
                <td style="width: 221.5pt; padding: 0.75pt; vertical-align: top">
                    <p
                        style="
                            margin: 0in;
                            font-size: 16px;
                            font-family: 'Times New Roman', serif;
                            color: black;
                            line-height: 150%;
                        "
                    >
                        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
    <p
        style="
            margin: 0in;
            font-size: 16px;
            font-family: 'Times New Roman', serif;
            color: black;
            line-height: 150%;
        "
    >
        <span style="font-family: 'Arial', sans-serif">&nbsp;</span>
    </p>
</div>
