import { Component, OnInit, Input } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { CreateReviewComponent } from 'src/app/dashboard/dashboard-shared/components/reviews/create-review/create-review.component'
import {
    ReviewCategoryEnums,
    ReviewTypeEnums,
    ReviewTypeSortingEnums,
} from 'src/app/dashboard/dashboard-shared/enums/review-types-enums'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { ContentService } from 'src/app/dashboard/dashboard-shared/services/content/content.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-ta-reviews',
    templateUrl: './ta-reviews.component.html',
    styleUrls: ['./ta-reviews.component.scss'],
})
export class TaReviewsComponent implements OnInit {
    myReviews
    currentUserHasAlreadyReviewedThisGame = false

    game
    myStarRating = null // should this be initiated to null? or undefined? or 0?

    data
    @Input() set _data(data) {
        this.data = data
        //! still need to deal with this logic??? -> not sure what, if anything, is different when it is a modal???

        if (data.origin == 'modal') {
            this.initCurrentGameReviews()
            this.game = data.game
            this.myStarRating = data.myStarRating ? data.myStarRating.rating : null
        } else {
            this.game = data.game
            this.myStarRating = data.myStarRating ? data.myStarRating.rating : null
            this.initCurrentUserReviews()
            this.initCurrentGameReviews()
        }
    }

    reviewCateogry: ReviewCategoryEnums = ReviewCategoryEnums.games

    includeOptions: ReviewTypeEnums[] = [ReviewTypeEnums.game]
    includedSortingOptions: ReviewTypeSortingEnums[] = [
        ReviewTypeSortingEnums.date,
        ReviewTypeSortingEnums.likes,
        ReviewTypeSortingEnums.rating,
    ]

    constructor(
        private dialog: MatDialog,
        private contentService: ContentService,
        private backendAPIUsersService: BackendAPIUsersService,
        private screenSizeService: ScreenSizeService,

        private backendAPIContentService: BackendAPIContentService,
    ) { }

    user
    isMobileScreen = false

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            if (user) {
                this.user = user
            }
        })
    }

    async initCurrentUserReviews() {
        await this.contentService.myReviews$
            .pipe(untilDestroyed(this))
            .subscribe(async (myReviews) => {
                if (myReviews) {
                    this.myReviews = myReviews
                    if (this.myReviews.some((r) => r.title === this.game.title)) {
                        this.currentUserHasAlreadyReviewedThisGame = true
                    }
                }
            })
    }

    currentGameReviews
    async initCurrentGameReviews() {
        let res = await this.backendAPIContentService.listReviewsByGameTitle(
            this.data.game.title,
            49,
        )
        this.currentGameReviews = res.reviews
    }

    openReviewComponent() {
        let inputData = {
            item: this.game, // TODO: this might eventually need more info to tell if the review is about a game or a haven or whatever
            myStarRating: this.myStarRating,
            reviewType: ReviewTypeEnums.game,
            hasTypeOptions: false,
        }
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Create Review',
                component: CreateReviewComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
        })
        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.myStarRating = data
            })
    }
}
