import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-gh-expandable-image',
  templateUrl: './gh-expandable-image.component.html',
  styleUrls: ['./gh-expandable-image.component.scss']
})
export class GhExpandableImageComponent implements OnInit {

  placeholderImg = window.location.origin + '/assets/images/defaults/group.webp'
  @Input() set _placeholderImg(_placeholderImg: string[]) {
    this._placeholderImg = _placeholderImg
  }

  img
  @Input() set _img(img: string[]) {
    this.img = img
  }

  isAvatar = false
  @Input() set _isAvatar(isAvatar: boolean) {
    this.isAvatar = isAvatar
  }

  title = ''
  @Input() set _title(title: string) {
    this.title = title
  }

  caption = ''
  @Input() set _caption(caption: string) {
    this.caption = caption
  }

  @ViewChild('modal', { static: false }) modal
  @ViewChild('modalImg', { static: false }) modalImg

  constructor() { }

  ngOnInit(): void {
  }

  isExpanded = false
  expand() {
    this.isExpanded = true

    // TODO
    // var captionText = document.getElementById("caption");
  }

  close() {
    this.isExpanded = false
  }

}
