<div class="parent-container" *ngIf="formIsLoaded">
    <!-- <div>
        <label for=""><span>Enter fuzzy search query:</span><input type="text" class="search" value="Ahr"></label>
        <textarea name="" id="" cols="30" rows="10" class="result"></textarea>
        <small>Testing <a href="http://fusejs.io/" target="_blank">http://fusejs.io/</a></small>
    </div>
 -->

    <mat-accordion class="gh-accordion" *ngIf="currentUser.userLevel > 6">
        <mat-expansion-panel class="mt-4 mat-elevation-z6">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-card-title class="gh-xsm-title"
                        >Create Publishers, Developers, or Artists:
                    </mat-card-title>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="mx-0 mt-2 pt-2 row">
                <div class="col-12 col-md-4">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Create Publisher</mat-label>
                        <input
                            matInput
                            type="text"
                            autocomplete="off"
                            [(ngModel)]="createPublisherText"
                        />
                        <button
                            class="gh-button gh-button-primary"
                            mat-button
                            matSuffix
                            (click)="manuallyCreatePublisherItem()"
                            (keydown.enter)="$event.preventDefault()"
                        >
                            <mat-icon class="gh-add-icon gh-icon">check</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Create Developer</mat-label>
                        <input
                            matInput
                            type="text"
                            autocomplete="off"
                            [(ngModel)]="createDeveloperText"
                        />
                        <button
                            class="gh-button gh-button-primary"
                            mat-button
                            matSuffix
                            (click)="manuallyCreateDeveloperItem()"
                            (keydown.enter)="$event.preventDefault()"
                        >
                            <mat-icon class="gh-add-icon gh-icon">check</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Create Artist</mat-label>
                        <input
                            matInput
                            type="text"
                            autocomplete="off"
                            [(ngModel)]="createArtistText"
                        />
                        <button
                            class="gh-button gh-button-primary"
                            mat-button
                            matSuffix
                            (click)="manuallyCreateArtistItem()"
                            (keydown.enter)="$event.preventDefault()"
                        >
                            <mat-icon class="gh-add-icon gh-icon">check</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <form [formGroup]="form" class="mt-4">
        <!-- GENERAL INFO -->
        <section>
            <div class="mx-0 row">
                <div class="col-4">
                    <!-- <mat-card-subtitle class="gh-lg-subtitle">General Info:</mat-card-subtitle> -->
                </div>
                <div class="col-6">
                    <div
                        id="already-existing-games-container"
                        class="row"
                        *ngIf="fuseResults.length > 0"
                    >
                        <div class="col-12">
                            <mat-card-subtitle id="fuse-results-title">
                                The following games that match your search already exist in our
                                database:
                            </mat-card-subtitle>
                        </div>
                        <div class="col-12">
                            <mat-card-subtitle
                                id="fuse-results-string"
                                matTooltip="{{
                                    fuseResultString.length > 200 ? fuseResultString : ''
                                }}"
                                matTooltipPosition="above"
                            >
                                {{ truncate(fuseResultString, 200) }}</mat-card-subtitle
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="mx-0 row">
                <div class="mb-2 col-12">
                    <div class="info-source-banner">
                        <div class="row">
                            <div class="pt-2 text-center col-12">
                                <mat-card-title class="gh-sm-title">Important!</mat-card-title>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-center col-12">
                                <mat-card-subtitle class="gh-md-subtitle"
                                    >Please make sure that all input information comes either
                                    directly from the game publisher's website or from the game box.
                                </mat-card-subtitle>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-center col-12">
                                <mat-card-subtitle class="gh-md-subtitle"
                                    >If the Publisher, Developer, or Artist are not in our database,
                                    please let us know in the Notes.
                                </mat-card-subtitle>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-center col-12">
                                <mat-card-subtitle id="thank-you" class="gh-md-subtitle"
                                    >Thank you!</mat-card-subtitle
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- title, year, minAge -->
            <div class="mx-0 mt-2 row">
                <div class="col-sm-4 col-xs-12">
                    <mat-form-field class="gh-form-field">
                        <mat-label #whatever>Game Title (REQUIRED):</mat-label>

                        <!-- ! still need search exiting games so that the UI at the top and process!!! -->
                        <input matInput formControlName="title" (input)="searchExistingGames()" />

                        <mat-error *ngIf="submitIsClicked && form.get('title').errors?.required"
                            >Required</mat-error
                        >
                        <mat-error *ngIf="submitIsClicked && form.get('title').errors?.maxlength"
                            >Max Lenght: 70 characters
                        </mat-error>
                    </mat-form-field>

                    <mat-error class="gh-mat-error-simulator" *ngIf="showDuplicateTitleError"
                        >Title already exists
                    </mat-error>
                </div>

                <!--! PUBLISHERS -->
                <div class="col-sm-4 col-xs-12">
                    <app-autocomplete-chiplist
                        *ngIf="publishers$"
                        [_items]="publishers.value"
                        [_label]="'Publishers...'"
                        [_allowDuplicates]="false"
                        [_selectedItems]="publishersToEdit"
                        [_allowCustomInput]="false"
                        [_itemsAreObjects]="true"
                        [_sourceOfInput]="'app-add-or-edit-game[_sourceOfInput]1'"
                        (selectionChanged)="setPublishers($event)"
                        [_useFuzzySearch]="true"
                        [_fuzzySearchThreshold]="0.25"
                    >
                    </app-autocomplete-chiplist>
                </div>

                <div class="col-sm-4 col-xs-12">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Min Age</mat-label>
                        <mat-select
                            [compareWith]="compareFunction"
                            formControlName="minSuggestedAge"
                            (selectionChange)="updateMinMaxPlaytimeOptions('min')"
                        >
                            <mat-option *ngFor="let option of minAgeOptions" [value]="option.value">
                                {{ option.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <!-- minPlaytime, maxPlaytime, minPlayers, maxPlayers -->
            <div class="mx-0 mt-2 row">
                <div class="col-sm-3 col-xs-12">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Min Playtime</mat-label>
                        <mat-select
                            [compareWith]="compareFunction"
                            formControlName="minPlaytime"
                            (selectionChange)="updateMinMaxPlaytimeOptions('min')"
                        >
                            <mat-option
                                *ngFor="let option of minPlayTimeOptions"
                                [value]="option.value"
                            >
                                {{ option.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-3 col-xs-12">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Max Playtime</mat-label>
                        <mat-select
                            [compareWith]="compareFunction"
                            formControlName="maxPlaytime"
                            (selectionChange)="updateMinMaxPlaytimeOptions('max')"
                        >
                            <mat-option
                                *ngFor="let option of maxPlayTimeOptions"
                                [value]="option.value"
                            >
                                {{ option.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-3 col-xs-12">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Min Players</mat-label>
                        <mat-select
                            [compareWith]="compareFunction"
                            formControlName="minPlayers"
                            (selectionChange)="updateMinMaxPlayerCountOptions('min')"
                        >
                            <mat-option
                                *ngFor="let option of minPlayerCountOptions"
                                [value]="option.value"
                            >
                                {{ option.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-3 col-xs-12">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Max Players</mat-label>
                        <mat-select
                            [compareWith]="compareFunction"
                            formControlName="maxPlayers"
                            (selectionChange)="updateMinMaxPlayerCountOptions('max')"
                        >
                            <mat-option
                                *ngFor="let option of maxPlayerCountOptions"
                                [value]="option.value"
                            >
                                {{ option.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="mx-0 mt-2 row">
                <!-- ! 1. This should span half of the space (col-6) -->
                <!-- ! Test to make sure that this value exists unchanged when submitting a game -->
                <!-- ! 2. Test this when editing/updating a game!!!! -->

                <div class="col-6">
                    <app-gh-year-picker
                        [_label]="'Year Published'"
                        [_selectedYear]="this.form.get('yearPublished').value"
                        [_startYear]="1900"
                        [_endYear]="2024"
                        (yearSelectedEmitter)="onYearSelected($event)"
                    ></app-gh-year-picker>
                </div>

                <div class="col-6">
                    <!-- ! DESIGNERS -->
                    <app-autocomplete-chiplist
                        *ngIf="designers$"
                        [_items]="designers.value"
                        [_label]="'Developers...'"
                        [_selectedItems]="designersToEdit"
                        [_allowDuplicates]="false"
                        [_allowCustomInput]="false"
                        [_itemsAreObjects]="true"
                        (selectionChanged)="setDesigners($event)"
                        [_sourceOfInput]="'app-add-or-edit-game[_sourceOfInput]2'"
                        [_useFuzzySearch]="true"
                        [_fuzzySearchThreshold]="0.25"
                    >
                    </app-autocomplete-chiplist>
                </div>
            </div>

            <div class="mx-0 row">
                <!-- ! ARTISTS -->
                <div class="col-6">
                    <app-autocomplete-chiplist
                        *ngIf="artists$"
                        [_items]="artists.value"
                        [_label]="'Artists...'"
                        [_allowDuplicates]="false"
                        [_selectedItems]="artistsToEdit"
                        [_allowCustomInput]="false"
                        [_itemsAreObjects]="true"
                        [_sourceOfInput]="'app-add-or-edit-game[_sourceOfInput]3'"
                        (selectionChanged)="setArtists($event)"
                        [_useFuzzySearch]="true"
                        [_fuzzySearchThreshold]="0.25"
                    ></app-autocomplete-chiplist>
                </div>

                <!-- <div class="col-6">
                    <app-autocomplete-chiplist *ngIf="factions$" [_items]="factions.value"
                        [_sourceOfInput]="'app-add-or-edit-game[_sourceOfInput]4'"
                        [_label]="'Player Options / Teams / Factions'" [_allowDuplicates]="false"
                        [_selectedItems]="factionsToEdit" [_allowCustomInput]="false"
                        (selectionChanged)="setFactions($event)">
                    </app-autocomplete-chiplist>
                </div> -->

                <div class="col-6">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Print Status:</mat-label>
                        <mat-select
                            [compareWith]="compareFunction"
                            formControlName="printStatus"
                            (selectionChange)="setPrintStatus($event)"
                        >
                            <mat-option
                                *ngFor="let option of printStatusOptions"
                                [value]="option.value"
                            >
                                {{ option.name | gamePrintStatus }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="mx-0 row">
                <div class="col-6">
                    <mat-form-field class="gh-form-field">
                        <input
                            matInput
                            placeholder="Official Link"
                            formControlName="officialLink"
                            type="text"
                        />
                    </mat-form-field>
                    <mat-error
                        class="gh-mat-error-simulator"
                        *ngIf="submitIsClicked && form.get('officialLink').errors?.pattern"
                        >Invalid Url
                    </mat-error>
                </div>
            </div>

            <div class="mx-0 mt-3 row">
                <div class="col-12">
                    <mat-radio-group class="gh-radio-group" (change)="setExpansionOption($event)">
                        <mat-card-subtitle>Is this an expansion?</mat-card-subtitle>
                        <mat-radio-button
                            value="notAnExpansion"
                            [checked]="expansionOption == 'notAnExpansion'"
                            class="ml-2 gh-radio-button"
                            >No</mat-radio-button
                        >
                        <mat-radio-button
                            value="isExpansion"
                            [checked]="expansionOption == 'isExpansion'"
                            class="ml-2 gh-radio-button"
                            >Yes</mat-radio-button
                        >
                    </mat-radio-group>
                </div>
            </div>

            <div class="mx-0 expansions-input row" *ngIf="expansionOption == 'isExpansion'">
                <div class="pt-2 text-center col-12">
                    <mat-card-subtitle id="missing-base-game-warning"
                        >NOTE: if the base game doesn't yet exist, you'll need to add it before
                        adding the expansion!</mat-card-subtitle
                    >
                </div>
                <div class="col-12">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Game Title:</mat-label>
                        <input
                            matInput
                            [matAutocomplete]="auto"
                            (input)="searchParentGames()"
                            formControlName="parentItem"
                        />

                        <mat-autocomplete
                            #auto="matAutocomplete"
                            [displayWith]="getGameTitleDisplay"
                        >
                            <mat-option *ngFor="let game of potentialParentGames" [value]="game">
                                {{ game.title }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <!-- ! this might be a problem since using ngModel for parentGameSearchText -->
                    <!-- ! actually this might not even be relevant anymore, but we still should have some kind of error here... -->
                    <!-- <mat-error class="gh-mat-error-simulator" *ngIf="submitIsClicked && baseGameRequiredError">Required
                        for Expansions
                    </mat-error> -->
                </div>
            </div>
            <!-- 
            <div class="mx-0 mt-3 row">
                <div class="col-12">
                    <mat-radio-group class="gh-radio-group" (change)="setCrowdfundingOption($event)">
                        <mat-card-subtitle>Is this crowdfunded?</mat-card-subtitle>
                        <mat-radio-button value="notCrowdfunded" [checked]="crowdfundingOption == 'notCrowdfunded'"
                            class="ml-2 gh-radio-button">No</mat-radio-button>
                        <mat-radio-button value="isCrowdfunded" [checked]="crowdfundingOption == 'isCrowdfunded'"
                            class="ml-2 gh-radio-button">Yes</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div> -->

            <div class="mx-0 crowdfunding-input row" *ngIf="crowdfundingOption == 'isCrowdfunded'">
                <div class="col-12">
                    <mat-radio-group
                        class="gh-radio-group"
                        (change)="setCrowdfundingHasLaunchedOption($event)"
                    >
                        <mat-card-subtitle>Has the crowdfunding launched yet?</mat-card-subtitle>
                        <mat-radio-button
                            value="crowdfundingNotLaunched"
                            [checked]="crowdfundingHasLaunchedOption == 'crowdfundingNotLaunched'"
                            class="ml-2 gh-radio-button"
                            >No</mat-radio-button
                        >
                        <mat-radio-button
                            value="crowdfundingHasLaunched"
                            [checked]="crowdfundingHasLaunchedOption == 'crowdfundingHasLaunched'"
                            class="ml-2 gh-radio-button"
                            >Yes</mat-radio-button
                        >
                    </mat-radio-group>
                </div>

                <div class="w-100 row">
                    <div class="col-4">
                        <mat-form-field
                            class="gh-form-field"
                            *ngIf="crowdfundingHasLaunchedOption == 'crowdfundingNotLaunched'"
                        >
                            <input
                                #date
                                matInput
                                [matDatepicker]="picker"
                                placeholder="Estimated Launch Date"
                                formControlName="crowdfundingLaunchDate"
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field
                            class="gh-form-field"
                            *ngIf="crowdfundingHasLaunchedOption == 'crowdfundingHasLaunched'"
                        >
                            <input
                                #date
                                matInput
                                [matDatepicker]="picker"
                                placeholder="Expiry Date"
                                formControlName="crowdfundingExpiryDate"
                            />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Crowdfunding Site:</mat-label>
                            <mat-select
                                (selectionChange)="setCrowdfundingSite($event)"
                                [compareWith]="compareFunction"
                                formControlName="crowdfundingSite"
                            >
                                <!-- <mat-option *ngFor="let site of crowdfundingSiteOptions" [value]="site">
                                    {{site | crowdfundingType}}
                                </mat-option> -->
                                <mat-option
                                    *ngFor="let site of crowdfundingSiteOptions"
                                    [value]="site"
                                >
                                    {{ site }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="gh-form-field">
                            <input
                                matInput
                                placeholder="Specific Link"
                                formControlName="crowdfundingLink"
                                type="text"
                            />
                        </mat-form-field>
                        <mat-error
                            class="gh-mat-error-simulator"
                            *ngIf="submitIsClicked && form.get('crowdfundingLink').errors?.pattern"
                            >Invalid Url
                        </mat-error>
                    </div>
                </div>
            </div>

            <div class="mx-0 mt-2 pt-2 row" *ngIf="currentUser.userLevel > 6">
                <div class="col-12">
                    <div>
                        <mat-form-field class="gh-form-field">
                            <input
                                matInput
                                placeholder="Amazon Ad Link"
                                formControlName="aAd"
                                type="text"
                                (change)="adInputChange()"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="mx-0 mt-2 pt-2 row" *ngIf="currentUser.userLevel > 6">
                <div class="col-12">
                    <div id="ad-container">
                        <app-banner-ad
                            [_inputData]="bannerAdInputDataSpecific"
                            [_sourceOfInput]="'app-add-or-edit-game'"
                            [_displayCloseIcon]="false"
                        >
                        </app-banner-ad>
                    </div>
                </div>
            </div>

            <div class="mx-0 mt-2 pt-2 row">
                <div class="col-sm-6 col-xs-12">
                    <div class="mt-2 gh-bordered-textarea-container">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Publisher Description</mat-label>

                            <textarea
                                style="white-space: pre-wrap"
                                matInput
                                class="gh-bordered-textarea"
                                autocomplete="off"
                                name=""
                                id=""
                                cols="30"
                                rows="5"
                                formControlName="publisherDescription"
                            ></textarea>
                            <mat-error
                                *ngIf="
                                    submitIsClicked &&
                                    form.get('publisherDescription').errors?.minlength
                                "
                                >Min Lenght: 40 characters</mat-error
                            >
                            <mat-error
                                *ngIf="
                                    submitIsClicked &&
                                    form.get('publisherDescription').errors?.maxlength
                                "
                                >Max Length: 2000 characters</mat-error
                            >
                        </mat-form-field>
                    </div>
                </div>
                <div
                    style="border: 1px solid #868788"
                    class="col-sm-6 img-input-bordered-container col-xs-12 image-upload-container"
                >
                    <div class="row">
                        <div class="col-12">
                            <mat-card-subtitle class="mb-2">Main Image:</mat-card-subtitle>
                        </div>
                        <div class="col-12">
                            <app-gh-file-uploader
                                #fileUploader
                                [_defaultImage]="mainImageToEdit"
                                [_isEditing]="isEditing"
                                [_isProfileImg]="false"
                                [_sourceOfInput]="'app-add-or-edit-game'"
                                [_inputId]="'file-input-1'"
                            >
                            </app-gh-file-uploader>
                        </div>
                    </div>
                </div>
                <!-- 
                <div *ngIf="!isEditing" class="mt-5 col-12">
                    <mat-card-subtitle class="mb-2">Other Images:</mat-card-subtitle>
                </div>


                <div *ngIf="!isEditing" class="col-12">
                    <div class="mt-4 row">
                        <div class="col-6 image-upload-container">
                            <app-gh-file-uploader #fileUploader2
                                [_defaultImage]="additionalImagesToEdit[0] != undefined && additionalImagesToEdit[0].paths[0] ? additionalImagesToEdit[0].paths[0] : '' "
                                [_sourceOfInput]="'app-add-or-edit-game0'" [_isProfileImg]="false"
                                [_inputId]="'file-input-2'">
                            </app-gh-file-uploader>
                        </div>
                        <div class="col-6 image-upload-container">
                            <app-gh-file-uploader #fileUploader3
                                [_defaultImage]="additionalImagesToEdit[1] != undefined && additionalImagesToEdit[1].paths[0] ? additionalImagesToEdit[1].paths[0] : '' "
                                [_sourceOfInput]="'app-add-or-edit-game1'" [_isProfileImg]="false"
                                [_inputId]="'file-input-3'">
                            </app-gh-file-uploader>
                        </div>
                    </div>
                    <div class="mt-4 row">
                        <div class="col-6 image-upload-container">
                            <app-gh-file-uploader #fileUploader4
                                [_defaultImage]="additionalImagesToEdit[2] != undefined && additionalImagesToEdit[2].paths[0] ? additionalImagesToEdit[2].paths[0] : '' "
                                [_sourceOfInput]="'app-add-or-edit-game2'" [_isProfileImg]="false"
                                [_inputId]="'file-input-4'">
                            </app-gh-file-uploader>
                        </div>
                        <div class="col-6 image-upload-container">
                            <app-gh-file-uploader #fileUploader5
                                [_defaultImage]="additionalImagesToEdit[3] != undefined && additionalImagesToEdit[3].paths[0] ? additionalImagesToEdit[3].paths[0] : '' "
                                [_sourceOfInput]="'app-add-or-edit-game3'" [_isProfileImg]="false"
                                [_inputId]="'file-input-5'">
                            </app-gh-file-uploader>
                        </div>
                    </div>
                </div>

            </div> -->
            </div>
        </section>

        <section>
            <div class="mx-0 mt-3 pt-2 img-input-bordered-container additional-images-row row">
                <div *ngIf="currentUser.userLevel > 4" class="col-12">
                    <mat-form-field class="gh-form-field">
                        <input
                            matInput
                            placeholder="Share any additional info for GH Staff internal use"
                            formControlName="metaDataForGH"
                            type="text"
                        />
                    </mat-form-field>
                </div>
                <div *ngIf="currentUser.userLevel > 8" class="col-12">
                    <mat-form-field class="gh-form-field">
                        <input
                            matInput
                            placeholder="GH Staff Internal Notes"
                            formControlName="internalGhNotes"
                            type="text"
                        />
                    </mat-form-field>
                </div>
            </div>
        </section>

        <!-- !INITIAL RATINGS-->
        <!-- !NOTE THE CODE FOR THIS IS IN A FILE next to this one. REMOVEDTEMP.html -->
    </form>
</div>

<!-- <div class="row">
    <div class="col-12" style="text-align: right">
        <button mat-raised-button class="gh-button gh-button-primary" style="display: inline" class="m-1 mx-3 p-1"
            routerLink="../games-dashboard" [mat-dialog-close]>Cancel</button>
        <button mat-raised-button class="gh-button gh-button-success" style="display: inline" class="m-1 mx-3 px-4 p-1"
            [mat-dialog-close]>Submit New Game to Database</button>
    </div>
</div> -->
