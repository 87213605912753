import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core'
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    UntypedFormControl,
} from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { HopperCompetitionLevelEnums } from 'src/app/dashboard/dashboard-shared/enums/hopper-enums'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { StringHelper } from '../../../../../../../../../../common/helpers/string-helper'
import { ConfirmActionComponent } from '../../../../modals/confirm-action/confirm-action.component'
import { AddToHopperAnimations } from './animations/add-to-hopper-animations'
import { AddToHopperConfirmMatchesComponent } from './components/add-to-hopper-confirm-matches/add-to-hopper-confirm-matches.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-add-to-hopper',
    templateUrl: './add-to-hopper.component.html',
    styleUrls: ['./add-to-hopper.component.scss'],
    animations: [AddToHopperAnimations.addGameItemTrigger],
})
export class AddToHopperComponent implements OnInit {
    addGameItemAnimState = ''
    isAnimating = false

    initialGame // used for edit only
    initialHaven // used for edit only
    isEditing = false

    acceptableCustomPlayersNeededText = ''
    unacceptableCustomPlayersNeededText = ''

    @Input() set _initialData(initialData) {
        if (initialData) {
            this.isEditing = true

            this.form.get('itemId').patchValue(initialData.itemId)
            this.form.get('admin').patchValue(initialData.group.admin)
            this.form.get('group').patchValue(initialData.group.members)

            this.selectedPlayers = initialData.group.members
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // NOTE:
            // initialGame must be in array since that is what autocomplete-chiplist expects, but games should use autocomplete (not chiplist),
            //   so, once this is fixed this can just be initialData.game rather than [inititalData.game]
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            if (initialData.game) {
                this.initialGame = [initialData.game]
            } else {
                this.initialHaven = [initialData.haven]
            }

            this.form.get('hasCustomBounds').patchValue(initialData.game.hasCustomBounds)
            this.form.get('customLowerBound').patchValue(initialData.game.customLowerBound)
            this.form.get('customUpperBound').patchValue(initialData.game.customUpperBound)

            this.selectedcompetitionLevel = initialData.competitionLevel
            this.form.get('comments').patchValue(initialData.comments)
        }
    }

    isGameSpecific = false
    specificGame = null
    specificGameBulkData = null
    @Input() set _inputData(inputData) {
        this.isGameSpecific = inputData.isGameSpecific
        this.specificGame = inputData.specificGame
        this.selectedGame = this.specificGame // cloning data so I dont have to have conditions in the HTML
    }

    matchesFound
    @Input() set _matchesFound(matchesFound) {
        this.matchesFound = matchesFound
    }

    @Output() possibleGameMatchEmitter = new EventEmitter()
    @Output() possibleHavenMatchEmitter = new EventEmitter()
    @Output() clearPossibleMatchesEmitter = new EventEmitter()
    @Output() joinGroupEmitter = new EventEmitter()
    @Output() submitEmitter = new EventEmitter()

    submitIsClicked = false

    possiblePlayers = []
    selectedPlayers

    possibleHavens = []

    competitionLevels = [
        HopperCompetitionLevelEnums.casual,
        HopperCompetitionLevelEnums.serious,
        HopperCompetitionLevelEnums.competitive,
        HopperCompetitionLevelEnums.tournament,
    ]
    selectedcompetitionLevel

    form: UntypedFormGroup
    user

    possibleGames = []

    selectedGame
    selectedGameBulkData

    constructor(
        private formBuilder: UntypedFormBuilder,
        private backendAPIUsersService: BackendAPIUsersService,
        private screenSizeService: ScreenSizeService,
        private gamesService: GamesService,
        private dialog: MatDialog,
        private backendApiGamesService: BackendAPIGamesService
    ) { }

    minMaxValidation(customLowerBound: string, customUpperBound: string) {
        return (group: UntypedFormGroup): { [key: string]: any } => {
            let min = group.controls[customLowerBound]
            let max = group.controls[customUpperBound]
            if (this.form && this.form.get('hasCustomBounds').value && min.value > max.value) {
                return {
                    minMax: 'Min must be <= max',
                }
            }
            return {}
        }
    }

    isMobileScreen = false

    async ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.form = this.formBuilder.group(
            {
                itemId: [StringHelper.getRandomString(), [Validators.required]],
                admin: [null, [Validators.required]],
                group: [null, [Validators.required]],
                game: [null, [Validators.required]], // still need game even if isGameSpecific
                hasCustomBounds: [false, [Validators.required]],
                customLowerBound: [null],
                customUpperBound: [null],
                desiredIndices: [null],
                comments: '',
                competitionLevel: [this.competitionLevels[0], [Validators.required]],
            },
            { validators: [this.minMaxValidation('customLowerBound', 'customUpperBound')] },
        )

        await this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                this.user = user
            })

        this.form.get('admin').patchValue(this.user)
        this.selectedPlayers = [this.user]

        // should this be a list of people at the event rather than friends?  probably...
        // REPLACE THIS WITH USERS DB SERVICE BASED ON CURRENT USERS FRIENDS
        // this.friendsDbService.getAllFriends().then(friends => {
        //   this.possiblePlayers = friends
        // })

        if (!this.isGameSpecific) {
            this.possibleGames = this.gamesService.getCoreGames()

        } else {
            this.form.get('game').patchValue(this.specificGame)
            this.form.addControl('haven', new UntypedFormControl(null, [Validators.required]))

            // this.gamesDbAccessorService.getBulkData(this.specificGame.pk).then(result => {
            //   if (result) {
            //     this.specificGameBulkData = JSON.parse(result.data)
            //     this.selectedGameBulkData = this.specificGameBulkData // cloning data so I dont have to have conditions in the HTML
            //     this.form.get('customLowerBound').patchValue(this.specificGameBulkData.minPlayers)
            //     this.form.get('customUpperBound').patchValue(this.specificGameBulkData.maxPlayers)
            //     this.form.get('desiredIndices').patchValue(this.generateDefaultDesiredIndices())

            //   }
            // })

            // this.havensDbService.havens$.pipe(untilDestroyed(this)).subscribe(havens => {
            //   this.possibleHavens = havens
            // })
        }

        this.onFormChanges()
    }

    onFormChanges(): void {
        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            if (!this.isGameSpecific) {
                if (this.form.get('game').value && this.form.get('game').value != null) {
                    this.possibleGameMatchEmitter.emit({
                        game: this.form.get('game').value,
                        currentPlayerCount: this.form.get('group').value ? this.form.get('group').value.length : 1,
                    })
                } else {
                    this.clearPossibleMatchesEmitter.emit()
                }
            } else {
                if (this.form.get('haven').value && this.form.get('haven').value != null) {
                    this.possibleHavenMatchEmitter.emit({
                        haven: this.form.get('haven').value,
                        currentPlayerCount: this.form.get('group').value.length,
                    })
                } else {
                    this.clearPossibleMatchesEmitter.emit()
                }
            }
        })
    }

    compareFunction(o1: any, o2: any) {
        return o1.value == o2.value && o1.key == o2.key
    }

    addPlayersToGroup(players) {
        this.selectedPlayers = players
        this.form.get('group').patchValue(players)
    }

    setCompetitionLevel(levels) {
        this.selectedcompetitionLevel = levels
        this.form.get('competitionLevel').patchValue(levels)
    }

    submit() {
        if (this.matchesFound && this.matchesFound.length > 0) {
            let inputData = {
                message: 'There are already one or more items in the list that match your critera!',
                submessage: 'Do you want to join one of these groups or start a new group?',
                isDeletion: false,
                contentComponent: AddToHopperConfirmMatchesComponent,
                contentComponentInputData: {
                    title: this.isGameSpecific
                        ? 'Existing options for ' + this.form.get('haven').value.title + ':'
                        : 'Existing options for ' + this.form.get('game').value.title + ':',
                    hopperMatches: this.matchesFound,
                },
            }
            let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
                data: {
                    title: 'Are you sure?',
                    component: ConfirmActionComponent,
                    inputData: inputData,
                    hasSubmitButton: true,
                    hasCancelButton: true,
                    allowParentClose: true,
                    submitButtonText: 'Start my own!',
                },
                panelClass: this.isMobileScreen
                    ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                    : DESKTOP_MODAL_PANEL_CLASS,
                backdropClass: 'gh-dialog-backdrop',
                disableClose: true,
            })

            dialogRef
                .afterClosed()
                .pipe(untilDestroyed(this))
                .subscribe((data) => {
                    if (data && data == 'confirm') {
                        this.processSubmission()
                    } else if (data && data != 'confirm') {
                        this.joinGroupEmitter.emit(data)
                    }
                })
        } else {
            this.processSubmission()
        }
    }

    processSubmission() {
        this.submitIsClicked = true

        if (this.form.get('hasCustomBounds').value) {
            this.form.controls['customLowerBound'].setValidators([
                Validators.required,
                Validators.pattern('^[0-9]*$'),
            ])
            this.form.controls['customUpperBound'].setValidators([
                Validators.required,
                Validators.pattern('^[0-9]*$'),
                Validators.min(2),
            ])
            this.form.controls['customLowerBound'].updateValueAndValidity()
            this.form.controls['customUpperBound'].updateValueAndValidity()
        }

        if (!this.isEditing) {
            this.form.get('group').patchValue([{ title: this.user.title }])

        }

        if (this.form.valid) {
            if (this.form.get('desiredIndices').value.length == 0) {
                // otherwise they are already set
                this.form.get('desiredIndices').patchValue(this.generateDefaultDesiredIndices())
            }

            let itemToSubmit = {
                admin: this.form.get('admin').value,
                comments: this.form.get('comments').value,
                competitionLevel: this.form.get('competitionLevel').value,
                customLowerBound: this.form.get('customLowerBound').value,
                customUpperBound: this.form.get('customUpperBound').value,
                desiredIndices: this.form.get('desiredIndices').value,
                game: this.selectedGame,
                group: this.form.get('group').value,
                hasCustomBounds: this.form.get('hasCustomBounds').value,
                itemId: this.form.get('itemId').value,
            }

            this.submitEmitter.emit({
                isEdit: this.isEditing,
                data: itemToSubmit,
            })
            // this.clearData() // this was causing submission issues, come back to it?
        }
    }

    generateDefaultDesiredIndices(): number[] {
        var desiredIndices = []
        for (var i = 1; i <= this.form.get('game').value.maxPlayers; i++) {
            let votedMin = this.form.get('game').value.votedBestMinPlayers
            let votedMax = this.form.get('game').value.votedBestMaxPlayers
            if (i >= votedMin && i <= votedMax) {
                desiredIndices.push(i)
            }
        }
        return desiredIndices
    }

    clearData() {
        this.submitIsClicked = false
        this.isEditing = false
        this.form.get('game').patchValue(null)
        this.form.get('competitionLevel').patchValue(this.competitionLevels[0])
        this.form.get('comments').patchValue('')
        this.form.get('hasCustomBounds').patchValue(false)
        this.form.get('customLowerBound').patchValue(null)
        this.form.get('customUpperBound').patchValue(null)
        this.selectedPlayers = [this.user]
        this.initialGame = null
        this.selectedcompetitionLevel = HopperCompetitionLevelEnums.casual
    }

    async setGame(event) {
        //////////////////////////////////////////////////////////////////////////////////////////////////////////
        // NOTE: setting value to event[0] since autocomplete-chiplist returns an array and is set to single input
        //////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (event.length != 0) {

            let gameRes = await this.backendApiGamesService.GetGame(event[0].pk)

            this.selectedGame = gameRes
            this.selectedGameBulkData = gameRes
            this.form.get('game').patchValue(gameRes) // still need this for generateDefaultIndicies() even though using selected game
            this.form.get('hasCustomBounds').patchValue(this.initialGame && this.initialGame[0] && this.initialGame[0] != null ? this.initialGame[0].hasCustomBounds : false)
            // editing
            if (this.initialGame && this.initialGame != null) {
                this.form.get('customLowerBound').patchValue(this.initialGame[0].customLowerBound)
                this.form.get('customUpperBound').patchValue(this.initialGame[0].customUpperBound)
            }
            // not editing
            else {
                this.form.get('customLowerBound').patchValue(event.length == 0 ? null : this.selectedGameBulkData.minPlayers)
                this.form.get('customUpperBound').patchValue(event.length == 0 ? null : this.selectedGameBulkData.maxPlayers)
            }
            this.form.get('desiredIndices').patchValue(this.generateDefaultDesiredIndices())
            this.generatePlayersNeededText(this.generateDefaultDesiredIndices())

        } else {
            this.form.get('game').patchValue(null)
            this.form.get('hasCustomBounds').patchValue(false)
            this.form.get('customLowerBound').patchValue(null)
            this.form.get('customUpperBound').patchValue(null)
            this.form.get('desiredIndices').patchValue(null)
        }
    }

    setHaven(event) {
        this.form.get('haven').patchValue(event.length == 0 ? null : event[0])
    }

    onDesiredIndicesChanged(event) {
        this.form.get('desiredIndices').patchValue(event)
        this.generatePlayersNeededText(event)
    }

    generatePlayersNeededText(desiredIndices) {
        desiredIndices = desiredIndices.sort()

        var acceptableValues = []
        var unacceptableValues = []

        // dont think I need this, but leaving here for now...
        // let lowestValue: number = this.form.get('hasCustomBounds').value ? this.form.get('customLowerBound').value : 1

        var highestValue
        if (this.form.get('hasCustomBounds').value) {
            highestValue =
                this.form.get('customUpperBound').value <= 8
                    ? this.form.get('customUpperBound').value
                    : 8
        } else {
            highestValue =
                this.form.get('game').value.maxPlayers <= 8
                    ? this.form.get('game').value.maxPlayers
                    : 8
        }

        if (this.form.get('hasCustomBounds').value) {
            for (var i = 1; i <= highestValue; i++) {
                if (
                    desiredIndices.includes(i) &&
                    i >= this.form.get('customLowerBound').value &&
                    i <= this.form.get('customUpperBound').value
                ) {
                    acceptableValues.push(i)
                } else {
                    unacceptableValues.push(i)
                }
            }
        } else {
            for (var i = 1; i <= highestValue; i++) {
                if (desiredIndices.includes(i)) {
                    acceptableValues.push(i)
                } else {
                    unacceptableValues.push(i)
                }
            }
        }

        this.acceptableCustomPlayersNeededText = ''
        for (var i = 0; i < acceptableValues.length; i++) {
            if (i < acceptableValues.length - 1) {
                this.acceptableCustomPlayersNeededText += acceptableValues[i]
                    .toString()
                    .concat(', ')
            } else {
                this.acceptableCustomPlayersNeededText +=
                    acceptableValues[i] != 8 ? acceptableValues[i].toString() : '8+'
            }
        }

        this.unacceptableCustomPlayersNeededText = ''
        for (var i = 0; i < unacceptableValues.length; i++) {
            if (i < unacceptableValues.length - 1) {
                this.unacceptableCustomPlayersNeededText += unacceptableValues[i]
                    .toString()
                    .concat(', ')
            } else {
                this.unacceptableCustomPlayersNeededText +=
                    unacceptableValues[i] != 8 ? unacceptableValues[i].toString() : '8+'
            }
        }
    }
}
