<div *ngIf="selectedGame">

    <div class="row mobile-parent-container" [hidden]="!isMobileScreen">

        <div class="col-12 mb-4">
            <div *ngIf="selectedGame">
                <ng-container *ngTemplateOutlet="imagesAndButtonsCard"></ng-container>
            </div>
        </div>

        <div class="col-12 mb-4" *ngIf="selectedGame">
            <ng-container *ngTemplateOutlet="objectiveInfoCard"></ng-container>
        </div>

        <div class="col-12 mb-4" *ngIf="selectedGame">
            <ng-container *ngTemplateOutlet="radarChartCard"></ng-container>
        </div>

        <div class="col-12 mb-4" *ngIf="selectedGame">
            <ng-container *ngTemplateOutlet="subjectiveInfoCard"></ng-container>
        </div>

        <div class="col-12 mb-4" *ngIf="selectedGame">
            <ng-container *ngTemplateOutlet="interestCard"></ng-container>
        </div>

        <div class="col-12 w-100" *ngIf="selectedGame">
            <ng-container *ngTemplateOutlet="gameContent"></ng-container>
        </div>

        <!-- <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <div *ngIf="bannerAdInputDataSpecific1$ | async">
                        <app-banner-ad [_inputData]="bannerAdInputDataSpecific1.value"
                            [_sourceOfInput]="'app-md-game-detail-1'">
                        </app-banner-ad>
                    </div>
                </div>
                <div class="col-6">
                    <div *ngIf="bannerAdInputDataSpecific2$ | async">
                        <app-banner-ad [_inputData]="bannerAdInputDataSpecific2.value"
                            [_sourceOfInput]="'app-md-game-detail-2'">
                        </app-banner-ad>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <div [hidden]="isMobileScreen">
        <div class="parent-container mb-3 mt-3">
            <div class="row mb-3">

                <div class="col-12 col-xl-6 d-flex justify-content-center">
                    <ng-container *ngTemplateOutlet="imagesAndButtonsCard"></ng-container>
                </div>

                <div class="col-12 col-xl-6" *ngIf="selectedGame">
                    <div class="row mb-4">
                        <ng-container *ngTemplateOutlet="objectiveInfoCard"></ng-container>
                    </div>
                    <div class="row">
                        <ng-container *ngTemplateOutlet="subjectiveInfoCard"></ng-container>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                    <ng-container *ngTemplateOutlet="radarChartCard"></ng-container>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                    <ng-container *ngTemplateOutlet="interestCard"></ng-container>
                </div>

            </div>

            <div>
                <ng-container *ngTemplateOutlet="gameContent"></ng-container>
            </div>

        </div>
    </div>

</div>





<div *ngIf="!selectedGame || selectedGameIsUndefined" style="margin-top: 16rem">
    <app-no-data [_message]="'The list is empty...'"></app-no-data>
</div>



<!-- <div class="gh-loading-container" *ngIf="!(selectedGameIsSet$ | async)">
    <app-gh-loading-spinner></app-gh-loading-spinner>
</div> -->


<!-- * REUSABLE TEMPLATES -->
<ng-template #objectiveInfoCard>
    <div>
        <app-game-detail-objective-info [_user]="user" [_selectedGame]="selectedGame">
        </app-game-detail-objective-info>
    </div>
</ng-template>


<ng-template #subjectiveInfoCard>
    <div>
        <app-game-detail-subjective-info [_user]="user" [_selectedGame]="selectedGame" [_gameRating]="selectedGameRatingItem"></app-game-detail-subjective-info>
    </div>
</ng-template>


<ng-template #images>
    <div class="image-gallery-container" *ngIf="(thumbs$ | async)">
        <app-image-gallery (showAdditionalImagesEmitter)="showAdditionalImages()" [_thumbs]="thumbs.value" [_showBottomDivider]="true" [_sourceOfInput]="'app-md-game-detail'">
        </app-image-gallery>
    </div>
</ng-template>


<ng-template #imagesAndButtonsCard>
    <mat-card class="mat-elevation-z6">
        <div class="row mx-0 images-and-buttons-card">
            <div class="col-12">
                <ng-container *ngTemplateOutlet="images"></ng-container>
            </div>

            <!-- !!!!! removing ( (isInMyCollection$ && isInMyWishlist$) | async) solves the problem of action buttons not showing up for certain games -->
            <!-- !!!!! if this doesn't work, might need to create another boolean flag to check if BOTH are done being fetched -->
            <div class="col-12 mt-4 action-buttons-container">
                <app-game-detail-action-buttons [_isInMyGames]="isInMyCollection.value" [_isInWishlist]="isInMyWishlist.value" [_isInDemoLibrary]="isInDemoLibrary.value" [_isInLendingLibrary]="isInLendingLibrary.value" [_isInUsuallyStocked]="isInUsuallyStocked.value"
                    [_isGHAdmin]="user && isGHAdmin" [_userIsHaven]="userIsHaven" (addToMyGamesEmitter)="addToMyGames()" (removeFromMyGamesEmitter)="removeFromMyGames()" (addToDemoLibraryEmitter)="addToDemoLibrary()" (removeFromDemoLibraryEmitter)="removeFromDemoLibrary()"
                    (addToLendingLibraryEmitter)="addToLendingLibrary()" (removeFromLendingLibraryEmitter)="removeFromLendingLibrary()" (addToNormallyStockedEmitter)="addToNormallyStocked()" (removeFromNormallyStockedEmitter)="removeFromNormallyStocked()"
                    (addToMyWishlistEmitter)="addToWishlist()" (removeFromMyWishlistEmitter)="removeFromWishlist()" (editGameDetailsEmitter)="editGame()" (deleteGameEmitter)="deleteGame()">
                </app-game-detail-action-buttons>



            </div>

            <!-- <h6>isInDemoLibrary{{isInDemoLibrary.value}}</h6>
            <h6>isInLendingLibrary{{isInLendingLibrary.value}}</h6>
            <h6>isInUsuallyStocked{{isInUsuallyStocked.value}}</h6> -->

        </div>
    </mat-card>
</ng-template>

<ng-template #radarChartCard>
    <mat-card class="subjective-info-card mat-elevation-z6">
        <app-gh-game-radar-chart-mini [_miniRadarData]="radarData" [_type]="radarChartType" [_selectedItem]="selectedGame" (radarChartResultEmitter)="logEmittedResults($event)" [_showMatchPercentage]="true" [_overlayType]="radarOverlayType" [_editButtonShows]="false"
            [_uniqueAttribute]="'username'">

        </app-gh-game-radar-chart-mini>

    </mat-card>
</ng-template>

<ng-template #interestCard>
    <mat-card id="findGamersNearYouCtaCard" (click)="openFindInterestNearYou()" style="cursor: pointer; min-height: 90%" class="mat-elevation-z12">
        <div class="row mx-0">
            <div class="col-12 text-center pt-2">
                <mat-card-title class="gh-sm-title">Find Interest Near You!</mat-card-title>
            </div>
        </div>
    </mat-card>
</ng-template>


<ng-template #gameContent>
    <div class="row mx-0">
        <div class="col-12 w-100">

            <div class="tabbed-accordion-container" *ngIf="!isMobileScreen">
                <!-- <app-tabbed-accordion [_tabs]="taTitles" [_isExpanded]="true" [_contentComponent0]="taComponent0"
                    [_contentComponent1]="taComponent1" [_contentComponent2]="taComponent2"
                    [_contentComponent3]="taComponent3" [_contentComponent4]="taComponent4"
                    (tabClickedEmitter)="onTabbedAccordionClicked($event)" [_sourceOfInput]="'app-md-game-detail'">
                </app-tabbed-accordion> -->

                <mat-card class="mat-elevation-z6">
                    <!-- <app-tab-group-with-component-params [_tabs]="taTitles" [_isExpanded]="true"
                        [_contentComponent0]="taComponent0" [_contentComponent1]="taComponent1"
                        [_contentComponent2]="taComponent2" [_contentComponent3]="taComponent3"
                        [_contentComponent4]="taComponent4"
                        (tabClickedEmitter)="onTabbedAccordionClicked($event)"></app-tab-group-with-component-params> -->
                    <app-tab-group-with-component-params [_tabs]="taTitles" [_isExpanded]="true" [_contentComponent0]="taComponent0" [_contentComponent1]="taComponent1" [_contentComponent2]="taComponent2" [_contentComponent3]="taComponent3" (tabClickedEmitter)="onTabbedAccordionClicked($event)"></app-tab-group-with-component-params>
                </mat-card>
            </div>

            <div *ngIf="taComponentsAreSet && isMobileScreen" class="mat-elevation-z6 mb-2">
                <mat-accordion class="gh-accordion">
                    <mat-expansion-panel *ngFor="let item of mobileAccordionItems; let i = index" [expanded]="false" (opened)="onPanelOpened(i)" (closed)="onPanelClosed(i)" [ngClass]="{ selected: selectedMobileAccordionIndex == i, stripe: i % 2 == 0 }">

                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{item}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div ngSwitch="{{item}}">
                            <div *ngSwitchCase="'Publisher Description'">
                                <app-ta-publisher-description [_data]="taComponent0.data">
                                </app-ta-publisher-description>
                            </div>

                            <div *ngSwitchCase="'Reviews'">
                                <app-ta-reviews [_data]="taComponent1.data"></app-ta-reviews>
                            </div>

                            <div *ngSwitchCase="'Content'">
                                <app-ta-content [_data]="taComponent2.data" [_sourceOfInput]="'app-md-game-detail'">
                                </app-ta-content>
                            </div>

                            <div *ngSwitchCase="'Recent Plays'">
                                <app-ta-recent-game-plays [_data]="taComponent3.data"></app-ta-recent-game-plays>

                            </div>

                            <!-- <div *ngSwitchCase="'Recent Plays'">
                                <app-ta-rules-forum [_data]="taComponent3.data"></app-ta-rules-forum>
                            </div> -->

                            <!-- 
                            <div *ngSwitchCase="'Similar Games'">
                                <app-ta-similar-games [_data]="taComponent2"></app-ta-similar-games>
                            </div> -->

                            <div *ngSwitchDefault>Nothing Found.</div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>

    <!-- BRING BACK Game Detail FEATURES afsoifaoisdjaofidsjdoiafjdoi -->
    <!-- <div class="row live-data-row" *ngIf="selectedGame">
        <div class="col-12 w-100 mt-3">
            <div class="row">

                <div class="col-12 col-sm-12 col-md-12 col-lg-6"
                    *ngIf="!limitDataFecthing; else playersInterestedOnlyOnClick">
                    <mat-card class="mat-elevation-z6">
                        <div class="row gh-card-header-row">
                            <div class="col-12">
                                <mat-card-title style="color: red; text-decoration: line-through" class="gh-sm-title">
                                    Gaming Near You:
                                </mat-card-title>
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                        <app-players-interested [_selectedGame]="selectedGame"></app-players-interested>
                    </mat-card>

                </div>

                <ng-template #playersInterestedOnlyOnClick>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                        <mat-accordion>
                            <mat-expansion-panel class="mat-elevation-z6">
                                <mat-expansion-panel-header>
                                    <mat-panel-title style="color: red; text-decoration: line-through"
                                        class="pt-3 pb-3">
                                        Gaming Near You:
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <app-players-interested [_selectedGame]="selectedGame"></app-players-interested>

                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </ng-template>

                <div class="col-12 col-sm-12 col-md-12 col-lg-6"
                    *ngIf="!limitDataFecthing && (hopperInputData$ | async)">
                    <mat-card class="objective-info-card mat-elevation-z6">
                        <div class="row gh-card-header-row has-button">
                            <div class="col-12 ">
                                <span class="inline-span">
                                    <mat-card-title style="color: red; text-decoration: line-through"
                                        class="gh-sm-title">Live Online Gaming Opportunities:
                                    </mat-card-title>
                                </span>

                                <span class="inline-span inline-span-align-right" *ngIf="!isMobileScreen">
                                    todo RETURN THIS BUTTON
                                    <button mat-raised-button class="gh-button gh-button-secondary"
                                        (click)="openHopper()">Detail View</button>
                                </span>

                            </div>
                            <div class="col-12">
                                <mat-divider></mat-divider>
                            </div>
                        </div>

                        <div class="row mt-1 section">
                            <div class="overview-content mt-2">
                                <span class="inline-span">
                                    <p class="gh-lg-p">{{gamesSeekingPlayersMockData}}</p>
                                </span>
                                <span class="inline-span ml-1">
                                    <p> havens are looking for</p>
                                </span>
                                <span class="inline-span">
                                    <p class="gh-lg-p ml-1">{{totalPlayersNeededMockData}}</p>
                                </span>
                                <span class="inline-span ml-1">
                                    <p> players.</p>
                                </span>
                            </div>

                            <div class="overview-content">
                                <span class="inline-span">
                                    <p class="gh-lg-p">{{recentGamesMockData}}</p>
                                </span>
                                <span class="inline-span ml-1">
                                    <p> successful matchups in the last {{timeSpanMockData}} hours.</p>
                                </span>
                            </div>

                        </div>
                        <div class="row content-row">
                            <app-hopper [_inputData]="hopperInputData.value" [_isMobileScreen]="true"
                                [_userCanAddItems]="true" [_sourceOfInput]="'app-md-game-detail'"></app-hopper>
                        </div>
                    </mat-card>
                </div>

                ! need to also prevent loading in TS until clicked!!!
                <div class="col-12 col-sm-12 col-md-12 col-lg-6"
                    *ngIf="limitDataFecthing && (hopperInputData$ | async)">
                    <mat-accordion>
                        <mat-expansion-panel class="mat-elevation-z6">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="pt-3 pb-3">
                                    Live Online Gaming Opportunities:
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <app-hopper [_inputData]="hopperInputData.value" [_isMobileScreen]="true"
                                [_userCanAddItems]="true" [_sourceOfInput]="'app-md-game-detail'"></app-hopper>

                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

            </div>
        </div>
    </div> -->


    <!-- <div class="row mt-3" *ngIf="!selectedGameIsUndefined && !limitDataFecthing">
        <div class="col-12 w-100">
            <mat-card class="mat-elevation-z6">
                <div class="row gh-card-header-row has-button">
                    <div class="col-12 ">
                        <span class="inline-span">
                            <mat-card-title class="gh-sm-title">Find {{selectedGame.title}} in Your Area:
                            </mat-card-title>
                        </span>

                        <span class="inline-span inline-span-align-right" *ngIf="!isMobileScreen">
                            <button mat-raised-button class="gh-button gh-button-secondary" (click)="openHopper()">Full
                                Screen</button>
                        </span>

                    </div>
                    <div class="col-12">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row">
                    <div class="col-10 w-100 map-container">
                        <app-map></app-map>
                    </div>
                    <div class="col-2 w-100 map-container">

                        <div id="ad-container" *ngIf="!isMobileScreen;">
                            <div *ngIf="bannerAdInputDataSpecific1$ | async">
                                <app-banner-ad [_inputData]="bannerAdInputDataSpecific1.value"
                                    [_sourceOfInput]="'app-md-game-detail-1'">
                                </app-banner-ad>
                            </div>
                        </div>

                        <div id="ad-container" *ngIf="!isMobileScreen;">
                            <div *ngIf="bannerAdInputDataSpecific2$ | async">
                                <app-banner-ad [_inputData]="bannerAdInputDataSpecific2.value"
                                    [_sourceOfInput]="'app-md-game-detail-2'">
                                </app-banner-ad>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div> -->

    <!-- ! need to also prevent loading in TS until clicked!!! -->
    <!-- <div class="row mt-3" *ngIf="!selectedGameIsUndefined && limitDataFecthing">
        <div class="col-12 w-100">
            <mat-accordion>
                <mat-expansion-panel class="mat-elevation-z6">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="pt-3 pb-3">
                            Find {{selectedGame.title}} in Your Area:
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div> -->


</ng-template>