import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { BackendApiGroupTrackingService } from 'src/app/backend-api-services/backend-api-group-tracking.service';
import { BackendAPIGroupsService } from 'src/app/backend-api-services/backend-api-groups.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';

@UntilDestroy({ checkProperties: true })


@Injectable({
  providedIn: 'root'
})

export class GroupsService {

  user

  coreGroups = new BehaviorSubject(null)
  coreGroups$ = this.coreGroups.asObservable()

  myGroups = new BehaviorSubject(null)
  myGroups$ = this.myGroups.asObservable()

  constructor(
    private backendAPIGroupsService: BackendAPIGroupsService,
    private backendAPIUsersService: BackendAPIUsersService,
    private backendApiGroupTrackingService: BackendApiGroupTrackingService,
    private backendApiGroupsService: BackendAPIGroupsService,

  ) {

    this.init()
  }

  async init() {
    this.user = await this.initUser()
    await this.initCoreGroupData()
  }

  async initUser() {
    let currentUserRes
    await this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(async user => {
      // alert('initing user updated')

      if (user) {
        currentUserRes = user
        this.initMyGroups(currentUserRes.username)
      }
    })


    return currentUserRes
  }

  async initMyGroups(username) {
    let myGroupsRes = await this.fetchMyGroups(username)
    this.updateMyGroups(myGroupsRes)
  }


  async fetchMyGroups(username?) {
    let myGroupsResult = await this.backendApiGroupTrackingService.listMyGroups(username ? username : this.user.username, 49)

    // return myGroupsResult.groups
    // TODO: This might be needed!
    return this.reshapeMyGroupsToWorkWithMD(myGroupsResult.groups)
  }

  updateMyGroups(myGroups) {
    this.myGroups.next(myGroups)
  }

  async initCoreGroupData() {

    let coreGroupDataRes = await this.backendAPIGroupsService.listAllGroupCoreData('#', 19999, null)
    this.coreGroups.next(coreGroupDataRes.coreGroups)
  }


  getIsInMyGroupsByPK(pk: string) {
    let result = false
    if (this.myGroups.value != null) {
      if (this.myGroups.value.some(h => h.PK_GROUPID
        === pk)) {
        result = true
      }
    }
    return result
  }

  async reshapeMyGroupsToWorkWithMD(myGroups) {
    for (let group of myGroups) {
      let mainImageFilesResult = await this.backendApiGroupsService.GetGroupMainImageFiles(group.PK_GROUPID)
      if (mainImageFilesResult && mainImageFilesResult != null) {
        group.mainImageFiles = mainImageFilesResult.mainImageFiles
      }
      group.pk = group.PK_GROUPID // needed to fetch details in md-group-detail
      group.id = group.PK_GROUPID // needed to fetch details in md-group-detail
    }
    return myGroups
  }
}
