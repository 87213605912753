import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { untilDestroyed } from '@ngneat/until-destroy';
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants';
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service';
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { ProfileViewEventDetailsComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile-calendar/components/profile-view-event-details/profile-view-event-details.component';
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component';
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service';
import { ProfileService } from 'src/app/dashboard/dashboard-shared/services/profile/profile.service';
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service';

@Component({
  selector: 'app-invites-table',
  templateUrl: './invites-table.component.html',
  styleUrls: ['./invites-table.component.scss']
})
export class InvitesTableComponent implements OnInit {

  defaultUserImage = window.location.origin + '/assets/images/defaults/profile-placeholder.png'


  selectedIndex = null

  dataSource = new MatTableDataSource<any>()
  @Input() set _dataSource(dataSource) {
    if (dataSource) {
      this.dataSource = new MatTableDataSource<any>(dataSource)
      this.getUserImages()
    }
  }

  displayedColumns
  @Input() set _displayedColumns(displayedColumns: string[]) {
    this.displayedColumns = displayedColumns
  }

  isIncoming
  @Input() set _isIncoming(isIncoming: boolean) {
    this.isIncoming = isIncoming
  }

  user
  @Input() set _user(user: boolean) {
    this.user = user
  }


  hasDeleteButtons
  @Input() set _hasDeleteButtons(hasDeleteButtons: boolean) {
    this.hasDeleteButtons = hasDeleteButtons
  }

  hasConfirmationButtons
  @Input() set _hasConfirmationButtons(hasConfirmationButtons: boolean) {
    this.hasConfirmationButtons = hasConfirmationButtons
  }

  @Output() composeMessageEmitter = new EventEmitter()

  constructor(private dialog: MatDialog,
    private backendAPIContentService: BackendAPIContentService,
    private screenSizeService: ScreenSizeService,
    private backendApiMessageService: BackendAPIMessageService,
    private arrayHelperService: ArrayHelperService,
    private profileService: ProfileService,
    private backendApiUsersService: BackendAPIUsersService) { }

  isMobileScreen = false
  ngOnInit(): void {
    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })
  }

  async getUserImages() {
    if (this.dataSource.filteredData && this.dataSource.filteredData.length > 0) {
      for (let item of this.dataSource.filteredData) {
        if (this.isIncoming) {
          let res = await this.backendApiUsersService.GetUserMainImageFiles(item.sentFromUID)
          item.mainImageFiles = res.mainImageFiles
        }
        else {
          let res = await this.backendApiUsersService.GetUserMainImageFiles(item.sentToUID)
          item.mainImageFiles = res.mainImageFiles
        }
      }
    }
  }


  async seeInviteDetails(item) {
    let attendees = await this.backendAPIContentService.listContentItemsByPkSk(
      item.extendedProps.ulid,
      'e-new',
      100,
    )

    let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      data: {
        title: 'Invite Details',

        component: ProfileViewEventDetailsComponent,

        inputData: {
          attendees: attendees,
          eventDetails: item.extendedProps,
          haven: item.extendedProps.havenId,
          shouldHaveInviteButton: false,
        },

        hasCloseButton: true,
        hasSubmitButton: this.isIncoming,
        hasCancelButton: this.isIncoming,
        submitButtonText: 'Accept Invite',
        allowParentClose: true,
        hasLeftActionButton: this.isIncoming,
        leftActionButtonText: 'Decline Invite',
      },
    })

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (data) {
          this.composeMessageEmitter.emit({ currentUser: data.currentUser, messageToReplyTo: data.messageToReplyTo })
        }
      })
  }

  async confirmInvite(user, item, type) {
    let deleteIncomingMessageInput = { pk: this.user.username, sk: item.sk_type_ULID }
    await this.backendApiMessageService.deleteMessage(deleteIncomingMessageInput)

    let cancelIncomingInputSK = item.sk_type_ULID
      .replace('Incoming', 'Outgoing')
      .concat(`#${item.sentToUID}`)

    let cancelOutgoingInput = { pk: item.sentFromUID, sk: cancelIncomingInputSK }
    await this.backendApiMessageService.deleteMessage(cancelOutgoingInput)

    let tempDataSource = this.arrayHelperService.removeFromArray(
      this.dataSource.filteredData,
      item,
      true,
      'sk_type_ULID',
    )
    this.dataSource = new MatTableDataSource<any>(tempDataSource)


    await this.backendAPIContentService
      .getCalendarEventById(item.eventID)
      .then(async (result) => {

        let eventToAdd = result

        eventToAdd.prefix = 'e-new-' + this.user.username
        eventToAdd.havenId = this.user.username

        let carl = await this.backendAPIContentService.createCalendarItems(
          eventToAdd,
          eventToAdd.extendedProps.mainImageFiles,
        )
      })

    this.profileService.setShouldRefreshEvents(true)
  }

  async declineIncomingInviteItem(item, type) {
    let deleteIncomingMessageInput = { pk: this.user.username, sk: item.sk_type_ULID }
    await this.backendApiMessageService.deleteMessage(deleteIncomingMessageInput)

    let cancelOutgoingInput = {
      pk: item.sentFromUID,
      sk: item.sk_type_ULID.replace('Incoming', 'Outgoing'),
    }
    await this.backendApiMessageService.deleteMessage(cancelOutgoingInput)

    let tempDataSource = this.arrayHelperService.removeFromArray(
      this.dataSource.filteredData,
      item,
      true,
      'sk_type_ULID',
    )
    this.dataSource = new MatTableDataSource<any>(tempDataSource)
  }

  onPanelOpened(index) {
    this.selectedIndex = index
  }

  onPanelClosed(index) {
    if (index == this.selectedIndex) {
      this.selectedIndex = null
    }
  }


}
