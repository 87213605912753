<div *ngIf="!(isLoading$ | async)">
    <form [formGroup]="updateUserForm">
        <div class="row section m-0">

            <div class="col-12 col-md-6 text-center">
                <mat-card-subtitle class="ml-5">Profile Image:</mat-card-subtitle>

                <app-gh-file-uploader (selectedImageChanged)="setSelectedImage($event)" #fileUploader
                    [_isProfileImg]="true" [_defaultImage]="currentProfilePic" [_sourceOfInput]="'app-edit-my-profile'"
                    [_isEditing]="true">

                </app-gh-file-uploader>
            </div>

            <div class="col-12 col-md-6 mt-5">
                <div class="gh-bordered-textarea-container mt-2">
                    <mat-form-field class="gh-form-field">
                        <mat-label>My Bio:</mat-label>
                        <textarea matInput class="gh-bordered-textarea" autocomplete="off" cols="30" rows="10"
                            formControlName="bio"></textarea>
                    </mat-form-field>
                </div>
            </div>

        </div>


    </form>
</div>

<div class="gh-loading-container mt-5" *ngIf="(isLoading$ | async)">
    <app-gh-loading-spinner></app-gh-loading-spinner>
</div>