import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TemporaryCredentials } from 'aws-sdk';
import { BehaviorSubject } from 'rxjs';
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service';
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { ONBOARDING_GAME_SURVEY_DATA } from 'src/app/dashboard/constants/mock-data';
import { ContentService } from '../../../services/content/content.service';
import { SnackbarService } from '../../../services/user-action-feedback/snackbar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-bulk-rate-games',
  templateUrl: './bulk-rate-games.component.html',
  styleUrls: ['./bulk-rate-games.component.scss']
})
export class BulkRateGamesComponent implements OnInit {

  isSubmitting = false

  loadAllGamesAtOnce = true
  submitIsClicked = false

  items = []

  completeData
  numberToLoadEachClick = 12
  currentNumberLoaded = 12
  dataMax = 72

  data = new BehaviorSubject(null)
  data$ = this.data.asObservable()

  gameSurveyData = ONBOARDING_GAME_SURVEY_DATA

  breakpoint: number
  imageElements: any = []

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  parentLeftActionButtonClicked = false
  @Input() set _parentLeftActionButtonClicked(parentLeftActionButtonClicked: boolean) {
    if (parentLeftActionButtonClicked) {
      alert('this should save but not increase userlevel')
      this.submitButDontUpdateUserLevel()
    }
  }

  @Output() ratingsChangedEmitter = new EventEmitter()

  constructor(private formBuilder: UntypedFormBuilder,
    private backendApiUsersService: BackendAPIUsersService,
    private backendAPIGamesService: BackendAPIGamesService,
    private backendApiGameTrackingService: BackendApiGameTrackingService,
    private contentService: ContentService,
    private snackbarService: SnackbarService,

    private componentDialogRef: MatDialogRef<BulkRateGamesComponent>,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {

    if (window.innerWidth >= 1200) {
      this.breakpoint = 6
    }
    if ((window.innerWidth >= 1000) && (window.innerWidth < 1200)) {
      this.breakpoint = 4
    }
    if ((window.innerWidth >= 800) && (window.innerWidth < 1000)) {
      this.breakpoint = 3
    }
    if ((window.innerWidth >= 600) && (window.innerWidth < 800)) {
      this.breakpoint = 2
    }
    if (window.innerWidth < 600) {
      this.breakpoint = 1
    }

    this.initSurveyData()

    if (this.loadAllGamesAtOnce) {
      this.loadMoreGames()
      this.loadMoreGames()
      this.loadMoreGames()
      this.loadMoreGames()
      this.loadMoreGames()
    }

    this.contentService.myRatings$.pipe(untilDestroyed(this)).subscribe(ratings => {
      if (ratings) {
        this.stampPreviouslyRatedGames(ratings)
      }
    })
  }

  stampPreviouslyRatedGames(ratings) {
    for (var i = 0; i < this.items.length; i++) {
      for (var j = 0; j < ratings.length; j++) { //! this could eventually get slow if the user has rated a lot of games. look for better solution or put this off?
        if (this.items[i].id == ratings[j].id) {
          this.items[i].touched = true
          this.items[i].value = ratings[j].rating
        }
      }
    }
  }

  initSurveyData() {
    this.completeData = this.gameSurveyData
    for (var i = 0; i < this.currentNumberLoaded; i++) {
      this.items.push(
        {
          touched: false,
          value: 5,
          title: this.gameSurveyData[i].title,
          imagePath: this.gameSurveyData[i].imagePath,
          id: this.gameSurveyData[i].id
        }
      )
    }
    this.data.next(this.items)
  }

  ngAfterViewChecked() {
    this.imageElements = document.getElementsByClassName('thumbnail-image')
  }

  onResize(event) {
    if (event.target.innerWidth >= 1200) {
      this.breakpoint = 4
    }
    if ((event.target.innerWidth >= 1000) && (event.target.innerWidth < 1200)) {
      this.breakpoint = 3
    }
    if ((event.target.innerWidth >= 800) && (event.target.innerWidth < 1000)) {
      this.breakpoint = 2
    }
    if ((event.target.innerWidth >= 600) && (event.target.innerWidth < 800)) {
      this.breakpoint = 1
    }
    if (event.target.innerWidth < 600) {
      this.breakpoint = 1
    }
  }

  onSliderTouched(index, clickEvent) {
    this.items[index].touched = true
    this.items[index].value = clickEvent.value
  }

  cancelRating(index) {
    this.items[index].touched = false
    this.items[index].value = 5
  }

  loadMoreGames() {
    for (var i = this.currentNumberLoaded; i < this.currentNumberLoaded + this.numberToLoadEachClick; i++) {
      if (this.completeData[i]) {
        this.items.push(
          {
            touched: false,
            value: 5,
            title: this.completeData[i].title,
            imagePath: this.completeData[i].imagePath,
            id: this.gameSurveyData[i].id
          }
        )
      }
    }
    this.data.next(this.items)
    this.currentNumberLoaded += this.numberToLoadEachClick
  }

  async submit() {
    await this.submitButDontUpdateUserLevel()

    // ! Change user level here
    // TODO: UPDATE USER LEVEL HERE - ONBOARDING IS DONE!

    let currentUser = await this.backendApiUsersService.getMyProfile()

    let updateSingleAttributeForUserInput = {
      pkName: 'pk',
      pkValue: currentUser.username,
      skName: 'sk',
      skValue: `BD#${currentUser.username}`,

      // ! THIS SHOULD CHANGE THE HASCOMPLETEDbULKrATE
      attributeName: 'userLevel',
      attributeValue: '6'
    }

    await this.backendApiUsersService.updateSingleAttributeForUser(updateSingleAttributeForUserInput)
    this.backendApiUsersService.initCurrentUser()
  }

  async submitButDontUpdateUserLevel() {

    this.isSubmitting = true
    this.submitIsClicked = true
    let currentUser = await this.backendApiUsersService.getMyProfile()
    for (let item of this.items) {
      if (item.touched) {

        try {
          let ratingRes = this.backendApiGameTrackingService.createGameTrackingAddRating({
            gameId: item.id,
            UID: currentUser.username,
            type: 'rating',
            userState: currentUser.state,
            userZipCode: currentUser.zipCode,
            title: item.title,
            userTitle: currentUser.title,
            mainImageFiles: null, //! fix this!!!
            rating: item.value
          })
        } catch (error) {
          console.log('an error occured during backendApiGameTrackingService.createGameTrackingAddRating', error)
        }
      }
    }
    this.isSubmitting = false
    this.snackbarService.openSuccessSnackBar('Initial game ratings updated successfully!')
    this.componentDialogRef.close()
  }

}