<div class="bring-game-session">
    <mat-card>
        <mat-card-title>Are you intersted?</mat-card-title>
        <mat-card-content>
            <form (ngSubmit)="submitForm()" #form="ngForm">
                <!-- Game Title -->
                <div class="form-field full-width">
                    <label class="custom-label">Game Title</label>
                    <app-autocomplete-chiplist
                        *ngIf="coreGames$ | async"
                        [_items]="coreGames.value"
                        [_selectedItems]="gameToEdit ? [gameToEdit] : null"
                        [_allowDuplicates]="false"
                        [_allowCustomInput]="false"
                        [_itemsAreObjects]="true"
                        [_singleInput]="true"
                        [_sourceOfInput]="'app-bring-game'"
                        [_useFuzzySearch]="true"
                        (selectionChanged)="selectGame($event)"
                        name="gameTitle"
                        required
                    ></app-autocomplete-chiplist>
                    <div class="mat-error" *ngIf="!gameSelected">Game Title is required</div>
                </div>

                <!-- Notes -->
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Notes</mat-label>
                    <textarea matInput [(ngModel)]="notes" name="notes" rows="3"></textarea>
                </mat-form-field>

                <!-- Submit and Cancel Buttons -->
                <div class="button-group">
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        [disabled]="!isFormValid()"
                    >
                        Create Interest
                    </button>
                    <button mat-button mat-dialog-close>Cancel</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
