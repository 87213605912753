import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-gh-loading-spinner',
  templateUrl: './gh-loading-spinner.component.html',
  styleUrls: ['./gh-loading-spinner.component.scss']
})
export class GhLoadingSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
