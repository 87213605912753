<!-- TODO: the disabled button doesnt allow the tooltip, so tooltip must be applied to the parent element.  However, this makes the tooltip display in the center of the div rather than right above the button.  Can deal with this some other time...-->


<div class="flex-container">
    <!-- <div style="align-self: end;" *ngIf="!user.userIsHaven"
    matTooltip=" {{currentUserHasAlreadyReviewedThisGame ? 'You have already reviewed this game. However, you can edit your review!' : 'Submit a comprehensive or video review.' }} "
    matTooltipPosition="above">
    <button mat-raised-button class="gh-button gh-button-primary p-2 m-2"
      [disabled]="currentUserHasAlreadyReviewedThisGame" (click)="openReviewComponent()">Write Review</button>
  </div> -->



    <!-- working here adflskjadfslkjadfslkjs
  this is passing up the game.title, which the review list is using
  we are alsao passing up the game, with ta-reveiws is using.  -->

    <div *ngIf="currentGameReviews && currentGameReviews.length != 0">
        <app-review-list-container [_inputData]="currentGameReviews" [_reviewCateogry]="reviewCateogry" [_includeOptions]="includeOptions" [_includedSortingOptions]="includedSortingOptions">
        </app-review-list-container>
    </div>
</div>