<!-- ! NOTE: tried using ngTemplateOutlet to reduce code duplication in this file but it caused strange errors that were not worth it -->

<form [formGroup]="locationForm">

    <div class="row" *ngIf="maxRows == 1; else showAddRowButton">

        <!-- <div class="col-12 pl-1">
            <mat-card-title class="gh-xsm-title">Primary:</mat-card-title>
        </div> -->

        <div class="col-6">
            <mat-form-field class="gh-form-field">
                <mat-label>State:</mat-label>

                <input type="text" placeholder="State" aria-label="State" matInput formControlName="state"
                    [matAutocomplete]="autoState" autocompleteOff>
                <mat-autocomplete #autoState="matAutocomplete" (optionSelected)="selectState($event)">
                    <mat-option *ngFor="let state of stateFilteredOptions1 | async" [value]="state">
                        {{state}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div class="col-6">
            <mat-form-field class="gh-form-field">
                <mat-label>Zip Code:</mat-label>
                <input type="text" placeholder="Zip Code" aria-label="zipCode" matInput formControlName="zipCode"
                    [matAutocomplete]="autoCityZip" autocompleteOff>
                <mat-autocomplete #autoCityZip="matAutocomplete">

                    <!-- ! before we removed attributes from the csv -->
                    <!-- <mat-option (click)="selectSpecificLocation(0, i)" *ngFor="let option of filteredLocationOptions1 | async; let i = index" [value]="option.city + ', ' + option.zip + ', ' + option.county_name + ' County'">
                        {{option.city}}, {{option.zip}} ({{option.county_name}} County)
                    </mat-option> -->

                    <!-- ! after we removed attributes from the csv -->
                    <mat-option (click)="selectSpecificLocation(0, i)"
                        *ngFor="let option of filteredLocationOptions1 | async; let i = index" [value]="option.zip">
                        {{option.zip}}
                    </mat-option>


                </mat-autocomplete>
            </mat-form-field>
        </div>

    </div>

    <ng-template #showAddRowButton>

        <div formArrayName="areaOfInterestItems">
            <div *ngFor="let item of areaOfInterestItems.controls; let i = index" class="item"
                [ngClass]="{ stripeGray: i % 2 == 0 }">
                <div [formGroupName]="i">
                    <div class="row">
                        <div class="col-12 pl-1">
                            <mat-card-title class="gh-xsm-title">{{i == 0 ? 'Primary:' : 'Additional
                                '.concat(i).concat(':')}}</mat-card-title>
                        </div>

                        <div class="col-5">
                            <mat-form-field class="gh-form-field">
                                <mat-label>State:</mat-label>

                                <input type="text" placeholder="State" aria-label="State" matInput
                                    formControlName="state" [matAutocomplete]="autoState" autocompleteOff>
                                <mat-autocomplete #autoState="matAutocomplete"
                                    (optionSelected)="selectState($event, i)">
                                    <mat-option *ngFor="let state of stateFilteredOptions1 | async" [value]="state">
                                        {{state}}
                                    </mat-option>
                                </mat-autocomplete>

                            </mat-form-field>
                        </div>

                        <div class="col-5">
                            <mat-form-field class="gh-form-field">
                                <mat-label>City / Zip Code:</mat-label>
                                <input type="text" placeholder="Zipcode" aria-label="Zipcode" matInput
                                    formControlName="zipcode" [matAutocomplete]="autoCityZip" autocompleteOff>
                                <mat-autocomplete #autoCityZip="matAutocomplete">
                                    <mat-option (click)="selectSpecificLocation(0, i)"
                                        *ngFor="let option of filteredLocationOptions1 | async; let i = index"
                                        [value]="option">
                                        {{option.city}}, {{option.zip}} ({{option.county_name}} county)
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="col-2 pt-3">
                            <div id="add-or-delete-area-of-interest-button-container">
                                <!-- TODO: fix the alignment when there are both buttons -->
                                <span class="inline-span" *ngIf="i != 0 && areaOfInterestItems.controls.length != 1">
                                    <mat-icon class="gh-icon gh-icon-larger gh-icon-warning gh-icon-clickable"
                                        (click)="removeAreaOfInterestItem(i)">
                                        delete_sweep</mat-icon>
                                </span>
                                <span class="inline-span ml-4"
                                    *ngIf="i == 0 && areaOfInterestItems.controls.length == 1 || i == areaOfInterestItems.controls.length-1 && areaOfInterestItems.controls.length != maxRows">
                                    <mat-icon class="gh-icon gh-icon-larger gh-icon-clickable gh-icon-primary"
                                        (click)="addAreaOfInterestItem()">add_circle</mat-icon>
                                </span>
                            </div>
                        </div>



                    </div>
                </div>

            </div>

        </div>

    </ng-template>

</form>