import { Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-tab-group-with-component-params',
  templateUrl: './tab-group-with-component-params.component.html',
  styleUrls: ['./tab-group-with-component-params.component.scss']
})
export class TabGroupWithComponentParamsComponent implements OnInit {

  @Input() _sourceOfInput: string

  @ViewChildren('viewContainer', { read: ViewContainerRef }) viewContainers!: QueryList<any>

  @ViewChild('viewContainer0', { read: ViewContainerRef }) viewContainer0
  @ViewChild('viewContainer1', { read: ViewContainerRef }) viewContainer1
  @ViewChild('viewContainer2', { read: ViewContainerRef }) viewContainer2 
  @ViewChild('viewContainer3', { read: ViewContainerRef }) viewContainer3
  @ViewChild('viewContainer4', { read: ViewContainerRef }) viewContainer4

  tabs
  @Input() set _tabs(tabs) {
    this.tabs = tabs
  }

  isExpanded = false
  @Input() set _isExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded
  }

  contentComponent0Component
  contentComponent0Data
  @Input() set _contentComponent0(contentComponent0) {
    if (contentComponent0) {
      this.contentComponent0Component = contentComponent0.component
      this.contentComponent0Data = contentComponent0.data
      if (this.viewContainer0) {
        this.viewContainer0.clear()
      }
      this.viewContainer0Set = false
    }
  }

  contentComponent1Component
  contentComponent1Data
  @Input() set _contentComponent1(contentComponent1) {
    if (contentComponent1) {
      this.contentComponent1Component = contentComponent1.component
      this.contentComponent1Data = contentComponent1.data
      if (this.viewContainer1) {
        this.viewContainer1.clear()
      }
      this.viewContainer1Set = false
    }
  }

  contentComponent2Component
  contentComponent2Data
  @Input() set _contentComponent2(contentComponent2) {
    if (contentComponent2) {
      this.contentComponent2Component = contentComponent2.component
      this.contentComponent2Data = contentComponent2.data
      if (this.viewContainer2) {
        this.viewContainer2.clear()
      }
      this.viewContainer2Set = false
    }
  }

  contentComponent3Component
  contentComponent3Data
  @Input() set _contentComponent3(contentComponent3) {
    if (contentComponent3) {
      this.contentComponent3Component = contentComponent3.component
      this.contentComponent3Data = contentComponent3.data
      if (this.viewContainer3) {
        this.viewContainer3.clear()
      }
      this.viewContainer3Set = false
    }
  }

  contentComponent4Component
  contentComponent4Data
  @Input() set _contentComponent4(contentComponent4) { 
    if (contentComponent4) {
      this.contentComponent4Component = contentComponent4.component
      this.contentComponent4Data = contentComponent4.data
      if (this.viewContainer4) {
        this.viewContainer4.clear()
      }
      this.viewContainer4Set = false
    }
  }

  @Output() tabClickedEmitter = new EventEmitter()

  viewContainer0Set = false
  viewContainer1Set = false
  viewContainer2Set = false
  viewContainer3Set = false
  viewContainer4Set = false

  ngAfterContentChecked() {
    if (!this.viewContainer0Set) {
      if (this.viewContainer0 && this.contentComponent0Data) {
        this.viewContainer0Set = true
        let component0 = this.viewContainer0.createComponent(this.resolver.resolveComponentFactory(this.contentComponent0Component))
        component0.instance._data = this.contentComponent0Data
      }
    }

    if (!this.viewContainer1Set) {
      if (this.viewContainer1 && this.contentComponent1Data) {
        this.viewContainer1Set = true
        let component1 = this.viewContainer1.createComponent(this.resolver.resolveComponentFactory(this.contentComponent1Component))
        component1.instance._data = this.contentComponent1Data
      }
    }

    if (!this.viewContainer2Set) {
      if (this.viewContainer2 && this.contentComponent2Data) {
        this.viewContainer2Set = true
        let component2 = this.viewContainer2.createComponent(this.resolver.resolveComponentFactory(this.contentComponent2Component))
        component2.instance._data = this.contentComponent2Data
      }
    }

    if (!this.viewContainer3Set) {
      if (this.viewContainer3 && this.contentComponent3Data) {
        this.viewContainer3Set = true
        let component3 = this.viewContainer3.createComponent(this.resolver.resolveComponentFactory(this.contentComponent3Component))
        component3.instance._data = this.contentComponent3Data
      }
    }

    if (!this.viewContainer4Set) {
      if (this.viewContainer4 && this.contentComponent4Data) {
        this.viewContainer4Set = true
        let component4 = this.viewContainer4.createComponent(this.resolver.resolveComponentFactory(this.contentComponent4Component))
        component4.instance._data = this.contentComponent4Data
      }
    }
  }

  @ViewChild("panel") panel: MatExpansionPanel

  tabChangeOccured = false

  tabContentIndex = 0
  

  constructor(private resolver: ComponentFactoryResolver) { }

  ngOnInit() {

  }

  viewContainersAreSet: boolean = false

  ngAfterViewInit() {
    if (!this.viewContainersAreSet) {
      if (this.viewContainers) {
        this.viewContainersAreSet = true
      }
    }
  }

  onTabChange(event) {
    this.tabContentIndex = event.index
    this.tabChangeOccured = true
    // this.tabClickedEmitter.emit(event.index)
  }

  onPanelHeaderClicked() {
    this.tabChangeOccured = false
    setTimeout(() => {
      if (this.tabChangeOccured) {
        this.panel.expanded = true
      }
      this.tabClickedEmitter.emit(this.tabContentIndex) //! IMPORANT: this must be inside the timeout function!
    }, 0)
  }

}
