import { Injectable } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { baseYoutubeImagePath, srcdoc } from '../../constants/base-path-constants'

@Injectable({
  providedIn: 'root'
})
export class VideoEmbeddingHelperService {

  baseYoutubeImagePath = baseYoutubeImagePath
  srcdoc = srcdoc

  constructor(private sanitizer: DomSanitizer) { }


  getVideoImage(unsafeURL) {
    let youtubeItemId = unsafeURL.replace('https://www.youtube.com/embed/', '')
    return this.baseYoutubeImagePath.replace('{videoId}', youtubeItemId)
  }

  getSrcdoc(unsafeURL, customImgClass?, maxHeight?) {
    let youtubeItemId = unsafeURL.replace('https://www.youtube.com/embed/', '')
    let videoPath = unsafeURL.replace('{youtubeItemId}', youtubeItemId).concat('?autoplay=1')
    let imagePath = this.baseYoutubeImagePath.replace('{videoId}', youtubeItemId)
    let result = this.srcdoc.replace('{embedPath}', videoPath).replace('{imgPath}', imagePath)
    if (customImgClass) {
      result = result.replace('{customImgClass}', customImgClass)
    }
    if (maxHeight) {
      result = result.replace('{maxHeight}', maxHeight)
    }
    return result
  }

  getVideoPath(unsafeURL){
    return this.sanitizer.bypassSecurityTrustResourceUrl(unsafeURL)
  }
}
 