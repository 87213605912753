<div class="dot-revolution"></div> <!-- this has been customized -->

<!-- <div class="dot-elastic"></div>
<div class="dot-pulse"></div>
<div class="dot-flashing"></div>
<div class="dot-collision"></div> -->
<!-- <div class="dot-carousel"></div>
<div class="dot-typing"></div>
<div class="dot-windmill"></div>
<div class="dot-bricks"></div>
<div class="dot-floating"></div>
<div class="dot-fire"></div>
<div class="dot-spin"></div>
<div class="dot-falling"></div>
<div class="dot-stretching"></div> -->