import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { CreateScheduledPlayComponent } from '../create-scheduled-play/create-scheduled-play.component'
import { MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { ProfileComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile/profile.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { animate, state, style, transition, trigger } from '@angular/animations'

@Component({
    selector: 'app-manage-scheduled-plays',
    templateUrl: './manage-scheduled-plays.component.html',
    styleUrls: ['./manage-scheduled-plays.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms ease-in-out')),
        ]),
    ],
})
export class ManageScheduledPlaysComponent implements OnInit {
    @Input() inputData: any
    @Input() user: any
    @Input() isGHAdmin: boolean

    events: any[] = []
    allEventsDataSource = new MatTableDataSource<any>()
    displayedColumns: string[] = [
        'game',
        'title',
        'cost',
        'location', // Added
        'createdByTitle',
        'startTime',
        'duration',
        'players',
        'waitlist',
        'notes',
        'playerList',
        // 'attend',
        'actions',
        'isApproved',
    ]

    @ViewChild('allEventsSort') allEventsSort: MatSort
    @ViewChild('notesDialog') notesDialog: TemplateRef<any>
    @ViewChild('passwordDialog') passwordDialog: TemplateRef<any>
    @ViewChild('deleteDialog', { static: true }) deleteDialog: TemplateRef<any>

    passwordInput: string = ''
    passwordError: boolean = false
    gameForPasswordDialog: any
    passwordDialogRef: MatDialogRef<any>

    gameForDeleteDialog: any
    deleteDialogRef: MatDialogRef<any>

    coreGames = new BehaviorSubject<any[]>(null)

    constructor(
        private dialog: MatDialog,
        private backendApiUsersService: BackendAPIUsersService,
        private backendApiContentService: BackendAPIContentService,
        private backendApiGamesService: BackendAPIGamesService,
        private gamesService: GamesService,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
    ) {}

    async ngOnInit() {
        // Load core games for details
        this.gamesService.coreGames$.subscribe((coreGames) => {
            if (coreGames) {
                this.coreGames.next(coreGames)
            }
        })

        this.allEventsDataSource.filterPredicate = (data, filter) =>
            data.name.toLowerCase().includes(filter)

        await this.fetchScheduledPlaysForThisConvention()
    }

    ngAfterViewInit() {
        if (this.allEventsSort) {
            this.allEventsDataSource.sort = this.allEventsSort
        }
    }

    async toggleApproval(game: any): Promise<void> {
        const newApprovalState: boolean = !game.isApproved // Boolean in the component

        const updatedGameData = {
            pkName: 'pk',
            pkValue: this.inputData.convention.pk,
            skName: 'sk',
            skValue: game.sk,
            attributeName: 'isApproved',
            // Convert the boolean to string if backend expects it as a string
            attributeValue: newApprovalState ? 'true' : 'false',
        }

        try {
            // Update the boolean attribute on the backend
            await this.backendApiContentService.updateSingleAttributeForContentItem(updatedGameData)

            // Update local object to immediately reflect the change
            game.isApproved = newApprovalState
            this.cdr.detectChanges()

            this.snackBar.open(
                `Event "${game.name}" has been ${newApprovalState ? 'approved' : 'declined'}.`,
                'Close',
                { duration: 3000 },
            )
        } catch (error) {
            console.error('Error updating approval status:', error)
            this.snackBar.open('Could not update approval status. Please try again.', 'Close', {
                duration: 3000,
            })
        }
    }

    onEditButtonClick(game: any): void {
        const inputData = {
            parentInputData: this.inputData,
            scheduledPlay: game,
            isEditing: true,
            hasAbilityToManage: true,
        }

        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Edit Event',
                component: CreateScheduledPlayComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                inputData: inputData,
                hasCloseButton: true,
            },
            height: '70%',
            width: '40%',
            disableClose: true,
            panelClass: [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS],
            backdropClass: 'gh-dialog-backdrop',
        })

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                // Refresh the events after editing
                await this.fetchScheduledPlaysForThisConvention()
            } else {
                console.log('Dialog closed without any result')
            }
        })
    }

    async fetchScheduledPlaysForThisConvention() {
        try {
            const response = await this.backendApiContentService.listScheduledPlaysByConventionId(
                this.inputData.convention.pk,
                'sp',
                500,
            )
            this.events = response.items.map((game: any) => {
                let playerList = []
                let waitlist = []

                if (game.playerList) {
                    if (typeof game.playerList === 'string' && game.playerList.trim() !== '') {
                        try {
                            playerList = JSON.parse(game.playerList)
                        } catch (e) {
                            console.error('Error parsing playerList:', e)
                        }
                    } else if (Array.isArray(game.playerList)) {
                        playerList = game.playerList
                    }
                }

                if (game.waitlist) {
                    if (typeof game.waitlist === 'string' && game.waitlist.trim() !== '') {
                        try {
                            waitlist = JSON.parse(game.waitlist)
                        } catch (e) {
                            console.error('Error parsing waitlist:', e)
                        }
                    } else if (Array.isArray(game.waitlist)) {
                        waitlist = game.waitlist
                    }
                }

                const startTime = new Date(game.startTime)
                const endTime = new Date(startTime.getTime() + game.duration * 60000)
                let isApprovedBoolean = game.isApproved === true || game.isApproved === 'true'

                return {
                    ...game,
                    startTime: startTime,
                    endTime: endTime,
                    isApproved: isApprovedBoolean,

                    playerList: playerList,
                    waitlist: waitlist,
                    currentPlayers: playerList.length,
                    isUserOrganizer:
                        game.createdByTitle?.toLowerCase() === this.user.title?.toLowerCase(),
                }
            })

            // Assign events to the dataSource
            this.allEventsDataSource.data = this.events

            // If you have a sorter
            if (this.allEventsSort) {
                this.allEventsDataSource.sort = this.allEventsSort
            }

            // If the view isn't updating, try forcing change detection
            this.cdr.detectChanges()
        } catch (error) {
            console.error('Error fetching scheduled plays:', error)
        }
    }

    truncate(input: string, maxCharacters: number): string {
        return input && input.length > maxCharacters
            ? input.substring(0, maxCharacters) + '...'
            : input
    }

    isUserAttending(game: any): boolean {
        return game.playerList.includes(this.user.title)
    }

    isUserOnWaitlist(game: any): boolean {
        return game.waitlist && game.waitlist.includes(this.user.title)
    }

    getButtonLabel(game: any): string {
        if (this.isUserAttending(game)) {
            return 'Leave'
        } else if (game.isPasswordProtected === 'true') {
            return 'Enter Password'
        } else if (game.playerList.length >= game.maxPlayers) {
            return this.isUserOnWaitlist(game) ? 'Leave Waitlist' : 'Join Waitlist'
        } else {
            return 'Join'
        }
    }

    isAttendDisabled(game: any): boolean {
        return (
            game.isOverlapping ||
            (game.playerList.length >= game.maxPlayers &&
                game.waitlist.length >= game.waitlistCapacity &&
                !this.isUserOnWaitlist(game) &&
                !this.isUserAttending(game))
        )
    }

    async handleAttendClick(game: any): Promise<void> {
        if (this.isUserAttending(game) || this.isUserOnWaitlist(game)) {
            await this.toggleAttendance(game)
        } else if (game.isPasswordProtected === 'true') {
            this.openPasswordDialog(game)
        } else {
            await this.toggleAttendance(game)
        }
    }

    async toggleAttendance(game: any): Promise<void> {
        if (this.isUserAttending(game)) {
            game.playerList = game.playerList.filter((player: string) => player !== this.user.title)
        } else if (game.playerList.length < game.maxPlayers) {
            game.playerList.push(this.user.title)
        } else {
            if (this.isUserOnWaitlist(game)) {
                game.waitlist = game.waitlist.filter((user: string) => user !== this.user.title)
            } else if (game.waitlist.length < game.waitlistCapacity) {
                game.waitlist.push(this.user.title)
            }
        }

        game.currentPlayers = game.playerList.length
        game.playerList = [...game.playerList]
        game.waitlist = [...game.waitlist]

        const updatedGameData = {
            pkName: 'pk',
            pkValue: this.inputData.convention.pk,
            skName: 'sk',
            skValue: game.sk,
            attributeName: 'playerList',
            attributeValue: JSON.stringify(game.playerList),
        }

        const updatedGameData2 = {
            pkName: 'pk',
            pkValue: this.inputData.convention.pk,
            skName: 'sk',
            skValue: game.sk,
            attributeName: 'waitlist',
            attributeValue: JSON.stringify(game.waitlist),
        }

        await Promise.all([
            this.backendApiContentService.updateSingleAttributeForContentItem(updatedGameData),
            this.backendApiContentService.updateSingleAttributeForContentItem(updatedGameData2),
        ])

        await this.fetchScheduledPlaysForThisConvention()
    }

    applyFilter(filterValue: string): void {
        this.allEventsDataSource.filter = filterValue.trim().toLowerCase()
    }

    async openGameDetails(game: any): Promise<void> {
        const matchedGame = this.coreGames.value?.find(
            (coreGame: any) => coreGame.title === game.name,
        )

        if (!matchedGame) {
            console.error('Game not found in coreGames')
            return
        }

        game.pk = matchedGame.pk

        try {
            let gameDetails = await this.backendApiGamesService.GetGame(game.pk)
            let inputData = {
                limitDataFecthing: true,
                game: gameDetails,
            }

            this.dialog.open(GhDialogWrapperComponent, {
                data: {
                    title: game.name,
                    component: MdGameDetailComponent,
                    hasSubmitButton: false,
                    hasCloseButton: true,
                    hasCancelButton: false,
                    inputData: inputData,
                    allowParentClose: true,
                },
                maxWidth: '90%',
                panelClass: [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS],
                backdropClass: 'gh-dialog-backdrop',
                disableClose: true,
            })
        } catch (error) {
            console.error('Error fetching game details:', error)
        }
    }

    openPasswordDialog(game: any): void {
        this.passwordInput = ''
        this.passwordError = false
        this.gameForPasswordDialog = game

        this.passwordDialogRef = this.dialog.open(this.passwordDialog, {
            width: '300px',
        })
    }

    closePasswordDialog(): void {
        this.passwordDialogRef.close()
    }

    async submitPassword(): Promise<void> {
        if (this.passwordInput === this.gameForPasswordDialog.password) {
            this.passwordDialogRef.close()
            await this.toggleAttendance(this.gameForPasswordDialog)
        } else {
            this.passwordError = true
        }
    }

    async openUserDetails(userID: string) {
        let userDetails = await this.backendApiUsersService.getProfileById(userID)

        let inputData = {
            user: userDetails,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS],
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'User Details',
                component: ProfileComponent,
                inputData: inputData,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                submitButtonText: 'Reply',
                allowParentClose: true,
            },
        })
    }

    onButtonClick(event?: Event): void {
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }

        const inputData = {
            parentInputData: this.inputData,
        }

        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Create Event',
                component: CreateScheduledPlayComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                inputData: inputData,
                hasCloseButton: true,
            },
            height: '70%',
            width: '40%',
            disableClose: true,
            panelClass: [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS],
            backdropClass: 'gh-dialog-backdrop',
        })

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                await this.fetchScheduledPlaysForThisConvention()
            }
        })
    }

    openDeleteDialog(game: any): void {
        this.gameForDeleteDialog = game
        this.deleteDialogRef = this.dialog.open(this.deleteDialog, {
            width: '400px',
            autoFocus: false,
        })
    }

    closeDeleteDialog(): void {
        this.deleteDialogRef.close()
    }

    async confirmDelete(): Promise<void> {
        await this.performDeleteEvent(this.gameForDeleteDialog)
        this.deleteDialogRef.close()
    }

    async performDeleteEvent(game: any) {
        if (this.isGHAdmin || game.isUserOrganizer) {
            this.events = this.events.filter((event) => event.id !== game.id)

            let deleteInput = {
                pk: game.pk,
                sk: game.sk,
            }
            await this.backendApiContentService.deleteContentItemByPkSk(deleteInput)
            await this.fetchScheduledPlaysForThisConvention()
            this.snackBar.open(`Event "${game.name}" has been deleted.`, 'Close', {
                duration: 3000,
            })
        } else {
            console.error('User is not authorized to delete this event.')
            this.snackBar.open('You are not authorized to delete this event.', 'Close', {
                duration: 3000,
            })
        }
    }

    openNotesDialog(notes: string): void {
        this.dialog.open(this.notesDialog, {
            data: notes,
            width: '400px',
        })
    }
}
