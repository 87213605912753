export enum TableNameEnums {
    'gamesTable' = 'gamesTable',
    'usersTable' = 'usersTable',
    'havensTable' = 'havensTable',
    'groupsTable' = 'groupsTable',
    'eventsTable' = 'eventsTable',
    'communicationsTable' = 'communicationsTable'
}

export enum ImageFileSuffixEnums {
    '_S.jpg' = '_S.jpg',
    '_M.jpg' = '_M.jpg',
    '_L.jpg' = '_L.jpg',
    '_XL.jpg' = '_XL.jpg',
}

export enum UserGameCollectionTypeEnums {
    'gamesILove' = 'Games I Love',
    'lookingToPlay' = 'Looking to Play'
}

export enum GroupGameOrHavenTypeEnums {
    'gamesWeLove' = 'Games We Love',
    'havensWeLove' = 'Havens We Love'
}