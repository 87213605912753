export enum HavenTypeEnums {
    "RetailStore" = "Retail Store",
    "Restaurant" = "Restaurant",
    "EventCenter" = "Event Center",
    "GamingCafe" = "Gaming Cafe",
    "PublicSpace" = "Public Space",
    "Bar" = "Bar",
    "PersonalHome" = "Personal Home",
    "Club" = "Club",
    "Other" = "Other"
}

export enum currentStatusTypeEnums {
    "Active" = "Active",
    "Inactive" = "Inactive",
    "Pending" = "Pending",
    "Archived" = "Archived"
}
