import { Injectable } from '@angular/core'
@Injectable({
  providedIn: 'root'
})
export class BensMathService {

  constructor() { }

  calculateMatchPrecent(list1, list2) { 
    if (list1.length != list2.length) {
      return new Error('list1 and list2 must be the same length!')
    }
    
    var sumOfDifferencesSquared = 0
    for (var i = 0; i < list1.length; i++) { 
      sumOfDifferencesSquared += ((list1[i] - list2[i]) * (list1[i] - list2[i]))
    }

    return (1 - ( Math.sqrt(sumOfDifferencesSquared) / Math.sqrt(list1.length) )) * 100  
  }

}
