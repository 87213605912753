<div *ngIf="!(isLoading$ | async)">

    <!-- * GAME CONTRIBUTION -->
    <div *ngIf="contributionType == 0">
        <app-autocomplete-chiplist id="game-input" [_items]="userCollection.value" [_itemsAreObjects]="true"
            [_label]="'Games I\'m Bringing'" [_allowDuplicates]="false" [_allowCustomInput]="true" [_sourceOfInput]="'app-add-gamenight-contribution[_sourceOfInput]1'"
            (selectionChanged)="setGames($event)" required>
        </app-autocomplete-chiplist>

        <div class="row mt-5">
            <div class="col-12">
                <div class="carousel-container">
                    <!-- <ngu-carousel *ngIf="gamesToContribute" class="carousel" #gamesCarousel [inputs]="carouselConfig"
                        [dataSource]="gamesToContribute">
                        <div *nguCarouselDef="let item;" class="item">
                            <div class="tile">
                                <div class="col-12 img-container">
                                    <img src="{{item.thumbImgPath != '' ? item.thumbImgPath : defaultMainImage}}"
                                        alt="">
                                </div>
                                <div class="col-12 text-center mt-2">
                                    <mat-card-title class="gh-xsm-title">{{item.title}}</mat-card-title>
                                </div>
                            </div>
                        </div>

                        <button NguCarouselNext class="rightRs">
                            <mat-icon>
                                keyboard_arrow_right
                            </mat-icon>
                        </button>
                        <button NguCarouselPrev class="leftRs">
                            <mat-icon>
                                keyboard_arrow_left
                            </mat-icon>
                        </button>
                        <ul class="myPoint" NguCarouselPoint>
                            <li *ngFor="let j of gamesCarousel.pointNumbers; let jnd = index"
                                [class.active]="j==gamesCarousel.activePoint" (click)="gamesCarousel.moveTo(jnd)">
                            </li>
                        </ul>
                    </ngu-carousel> -->
                </div>
            </div>
        </div>
    </div>

    <!-- * FOOD CONTRIBUTION -->
    <div *ngIf="contributionType == 1">
        <app-autocomplete-chiplist id="food-input" [_items]="commonFoodContributions" [_itemsAreObjects]="false"
            [_label]="'Food I\'m Bringing'" [_allowDuplicates]="false" [_allowCustomInput]="true" [_sourceOfInput]="'app-add-gamenight-contribution[_sourceOfInput]2'"
            (selectionChanged)="setFood($event)" required>
        </app-autocomplete-chiplist>

        <div class="row mt-5">
            <div class="col-12">
                <div class="carousel-container">
                    <!-- <ngu-carousel *ngIf="foodToContribute" class="carousel" #foodCarousel [inputs]="carouselConfig"
                        [dataSource]="foodToContribute">
                        <div *nguCarouselDef="let item; let i = index" class="item">
                            <div class="tile">
                                <div class="col-12 img-container">
                                    <app-gh-file-uploader #fileUploader [_defaultImage]="defaultMainFoodImage" [_sourceOfInput]="'app-add-gamenight-contribution'"
                                        [_isProfileImg]="false" [_inputId]="item.itemId" (selectedImageChanged)="selectedFoodImageChanged($event, i)">
                                    </app-gh-file-uploader>
                                </div>
                                <div class="col-12 text-center mt-2">
                                    <mat-card-title class="gh-xsm-title">{{item.title}}</mat-card-title>
                                </div>
                            </div>
                        </div>

                        <button NguCarouselNext class="rightRs">
                            <mat-icon>
                                keyboard_arrow_right
                            </mat-icon>
                        </button>
                        <button NguCarouselPrev class="leftRs">
                            <mat-icon>
                                keyboard_arrow_left
                            </mat-icon>
                        </button>
                        <ul class="myPoint" NguCarouselPoint>
                            <li *ngFor="let j of foodCarousel.pointNumbers; let jnd = index"
                                [class.active]="j==foodCarousel.activePoint" (click)="foodCarousel.moveTo(jnd)">
                            </li>
                        </ul>
                    </ngu-carousel> -->
                </div>
            </div>
        </div>
    </div>

    <!-- * BEVERAGES CONTRIBUTION -->
    <div *ngIf="contributionType == 2">
        <app-autocomplete-chiplist id="beverage-input" [_items]="commonBeverageContributions" [_itemsAreObjects]="false"
            [_label]="'Beverages I\'m Bringing'" [_allowDuplicates]="false" [_allowCustomInput]="true" [_sourceOfInput]="'app-add-gamenight-contribution[_sourceOfInput]3'"
            (selectionChanged)="setBeverages($event)" required>
        </app-autocomplete-chiplist>

        <div class="row mt-5">
            <div class="col-12">
                <div class="carousel-container">
                    <!-- <ngu-carousel *ngIf="beveragesToContribute" class="carousel" #beverageCarousel [inputs]="carouselConfig"
                        [dataSource]="beveragesToContribute">
                        <div *nguCarouselDef="let item; let i = index" class="item">
                            <div class="tile">
                                <div class="col-12 img-container">
                                    <app-gh-file-uploader #fileUploader [_defaultImage]="defaultMainBeverageImage" [_sourceOfInput]="'app-add-gamenight-contribution'"
                                        [_isProfileImg]="false" [_inputId]="item.itemId" (selectedImageChanged)="selectedBeverageImageChanged($event, i)">
                                    </app-gh-file-uploader>
                                </div>
                                <div class="col-12 text-center mt-2">
                                    <mat-card-title class="gh-xsm-title">{{item.title}}</mat-card-title>
                                </div>
                            </div>
                        </div>

                        <button NguCarouselNext class="rightRs">
                            <mat-icon>
                                keyboard_arrow_right
                            </mat-icon>
                        </button>
                        <button NguCarouselPrev class="leftRs">
                            <mat-icon>
                                keyboard_arrow_left
                            </mat-icon>
                        </button>
                        <ul class="myPoint" NguCarouselPoint>
                            <li *ngFor="let j of beverageCarousel.pointNumbers; let jnd = index"
                                [class.active]="j==beverageCarousel.activePoint" (click)="beverageCarousel.moveTo(jnd)">
                            </li>
                        </ul>
                    </ngu-carousel> -->
                </div>
            </div>
        </div>
    </div>


</div>

<div class="gh-loading-container mt-5" *ngIf="(isLoading$ | async)">
    <app-gh-loading-spinner></app-gh-loading-spinner>
</div>