<div class="parent-container">


    <!-- PERSONAL ACCOUNT -->
    <div class="row">
        <div class="col-12">
            <mat-card class="objective-info-card mat-elevation-z6">
                <div class="row gh-card-header-row">
                    <div class="col-12">
                        <span class="inline-span">
                            <mat-card-title class="gh-sm-title">Personal Account</mat-card-title>
                        </span>
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row content-row">
                    Explanation of account type goes here (use info-message-modal with animated bullets?)
                </div>
            </mat-card>
        </div>
    </div>


    <!-- PROFESSIONAL ACCOUNT -->
    <div class="row mt-5">
        <div class="col-12">
            <mat-card class="objective-info-card mat-elevation-z6">
                <div class="row gh-card-header-row">
                    <div class="col-12">
                        <span class="inline-span">
                            <mat-card-title class="gh-sm-title">Professional Account</mat-card-title>
                        </span>
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="row content-row">
                    Explanation of account type goes here (use info-message-modal with animated bullets?)
                </div>
            </mat-card>
        </div>
    </div>

</div>