<div *ngIf="publishers">
    <h4>Publishers:</h4>
    <ul>
        <span style='cursor: pointer;' *ngFor="let pub of publishers; index as i"
            (click)="fetchPublisherGames($event, i)">
            {{pub.title}}
            <mat-icon class="tab-icon">zoom_in</mat-icon>
        </span>

    </ul>
</div>


<div *ngIf="designers">
    <h4>Developers:</h4>
    <ul>
        <span style='cursor: pointer;' *ngFor="let dev of designers; index as i"
            (click)="fetchDeveloperGames($event, i)">
            {{dev.title}}
            <mat-icon class="tab-icon">zoom_in</mat-icon>
        </span>

    </ul>
</div>


<div *ngIf="artists">
    <h4>Artists:</h4>
    <ul>
        <span style='cursor: pointer;' *ngFor="let art of artists; index as i" (click)="fetchArtistGames($event, i)">
            {{art.title}}
            <mat-icon class="tab-icon">zoom_in</mat-icon>
        </span>

    </ul>
</div>