import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SnackbarService } from '../../../services/user-action-feedback/snackbar.service';
import { GhButtonColor } from '../../../enums/gh-button-enums';

@Component({
  selector: 'app-gh-button',
  templateUrl: './gh-button.component.html',
  styleUrls: ['./gh-button.component.scss']
})
export class GhButtonComponent implements OnInit {

  isLoading = false
  isDisabled = false

  @Input() _hasSnackbar = true
  @Input() _snackbarSuccessMessage = 'Success!'
  @Input() _snackbarErrorMessage = 'Something went wrong...'
  @Input() _buttonText = 'Submit'

  @Input() _icon = null

  @Input() _isStrokedButton = false  

  @Input() _buttonColor = GhButtonColor.primary
  get GhButtonColor() {
    return GhButtonColor
  }

  @Input() _clickTrackInfo = ''

  @Output() _onClick = new EventEmitter()

  constructor(
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
  }

  onClick() {
    if (!this.isLoading) {
      this.isLoading = true
      this._onClick.emit()
    }
  }

  onResolve(wasSuccessful: boolean) {
    this.isLoading = false
    if (this._hasSnackbar) {
      if (wasSuccessful) {
        this.snackbarService.openSuccessSnackBar(this._snackbarSuccessMessage)
      }
      else {
        this.snackbarService.openErrorSnackBar(this._snackbarErrorMessage)
      }
    }
  }

}
