<mat-card-subtitle class="gh-lg-subtitle gh-bold">{{inputData.title}}</mat-card-subtitle>
<mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let option of options" matTooltip="this should show the details of the hopper item" matTooltipPosition="above">

        <span class="inline-span">
            <mat-icon class="gh-icon">casino</mat-icon>
        </span>

        <span class="inline-span">

            <span class="inline-span ml-3">
                {{truncate(getGroupName(option.item.group.members), 20)}}
            </span>

        <span class="inline-span ml-3">
                <button mat-raised-button class="gh-button gh-button-primary ml-3" (click)="joinGroup(option)">JOIN</button>
            </span>

        </span>

    </mat-list-item>
</mat-list>