import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-play-review-modal',
  templateUrl: './play-review-modal.component.html',
  styleUrls: ['./play-review-modal.component.scss']
})
export class PlayReviewModalComponent implements OnInit {

  constructor() { }

  currentHaven = ""
  currentGame = ""
  players = []


  // ! IT IS UNCLEAR IF WE WILL HAVE THIS FEATURE SO THIS IS LEFT MOCKED
  ngOnInit() {

    // Fetch the currrent Haven and players
    this.currentHaven = "This Haven!"
    this.players = ['Carl Jr.', 'Bob Sr.', 'Junie B.', ]
    this.currentGame = "Patchwork"

  }

}
