import { Injectable } from '@angular/core'
import { Auth } from 'aws-amplify'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { environment } from 'src/environments/environment'
import {
    iAddRatingInput,
    iAddReviewInput,
    iAddToGamesIAmLookingToPlayInput,
    iCreateHavenGameTrackingItem,
    iDeleteFromMyCollectionInput,
    iDeleteFromMyWishlistInput,
    iDeleteGamesILoveInput,
    iDeleteLookingToPlayInput,
} from '../../../../common/interfaces/GameTrackingInterfaces'
import gql from 'graphql-tag'
import { iUpdateSingleAttributeForGameInput } from '../../../../common/interfaces/GameInterfaces'
import { ContentService } from '../dashboard/dashboard-shared/services/content/content.service'
import { iDeleteHavenTrackingItemByPkSkInput } from '../../../../common/interfaces/HavenInterfaces'

@Injectable({
    providedIn: 'root',
})
export class BackendApiHavenTrackingService {
    async listHavenTrackingItemsByHavenByType(id: any, type: string, limit: number, nextToken?) {
        type = '#' + type

        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listHavenTrackingItemsByHavenByType(
                    $id: String!
                    $type: String
                    $limit: Int
                    $nextToken: String
                ) {
                    listHavenTrackingItemsByHavenByType(
                        id: $id
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        havens {
                            PK_HAVENID
                            title
                            pk_GAMEID
                            sk_TYPE_UID_ULID
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byGameSK_LPID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            updatedAt
                        }
                    }
                }
            `,
            variables: { id, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })

        return response.data.listHavenTrackingItemsByHavenByType
    }

    constructor(private contentService: ContentService) { }

    private appSyncClient = new AWSAppSyncClient({
        url: environment.apiUrl,
        region: 'us-east-1',
        auth: {
            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            jwtToken: async () => {
                const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                return token
            },
        },
        disableOffline: true,
    })

    async listMyHavens(id: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listMyHavens($id: ID!, $limit: Int, $nextToken: String) {
                    listMyHavens(id: $id, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        havens {
                            title
                            PK_HAVENID
                            id
                        }
                    }
                }
            `,
            variables: { id, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listMyHavens
    }

    async listHavenTrackingItemsByUserAndType(id: string, type: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listHavenTrackingItemsByUserAndType(
                    $id: ID!
                    $type: String
                    $limit: Int
                    $nextToken: String
                ) {
                    listHavenTrackingItemsByUserAndType(
                        id: $id
                        type: $type
                        limit: $limit
                        nextToken: $nextToken
                    ) {
                        nextToken
                        havens {
                            id
                            title
                            pk_GAMEID
                            sk_TYPE_UID_ULID
                            byGame_byType_byLocation_byUser_PK_GAMEID
                            byGameSK_LPID
                            byUser_byType_byGame_PK_UID
                            byUser_byType_byGame_SK_TYPE_GAMEID_ULID
                            createdAt
                            updatedAt
                        }
                    }
                }
            `,
            variables: { id, type, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listHavenTrackingItemsByUserAndType
    }

    async deleteHavenTrackingItemByPkSk(
        deleteHavenTrackingItemByPkSkInput: iDeleteHavenTrackingItemByPkSkInput,
    ) {
        let input = deleteHavenTrackingItemByPkSkInput

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteHavenTrackingItemByPkSk(
                    $input: IGQL_DeleteHavenTrackingItemByPkSk!
                ) {
                    deleteHavenTrackingItemByPkSk(input: $input) {
                        createdAt
                    }
                }
            `,
            variables: { input },
        })
        return response.data.deleteHavenTrackingItemByPkSk
    }

    async listFollowersByHavenId(id: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listFollowersByHavenId($id: ID!, $limit: Int, $nextToken: String) {
                    listFollowersByHavenId(id: $id, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        followers {
                            havenTitle
                            SK_byType_byZip
                            updatedAt
                            createdAt
                            mainImageFiles
                            userTitle
                            username
                            sk_TYPE_UID_ULID
                        }
                    }
                }
            `,
            variables: { id, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listFollowersByHavenId
    }

    async createHavenTrackingAddRating(addRatingInput: iAddRatingInput) {
        let response = []

        let input = addRatingInput

        const createHavenTrackingAddRating = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createHavenTrackingAddRating($input: IGQL_CreateHavenTrackingAddRating!) {
                    createHavenTrackingAddRating(input: $input) {
                        createdAt
                    }
                }
            `,
            variables: { input },
        })

        // !WORKING to HERE ***********************************************************************
        // ! theRating (including Game: theRating) is a future feature.****************************

        response.push(createHavenTrackingAddRating)

        if (createHavenTrackingAddRating.data.createHavenTrackingAddRating) {
            const fetchHavenPreviousRatingData = await this.fetchHavenPreviousRatingData(
                addRatingInput.havenId,
            )

            if (fetchHavenPreviousRatingData.numberRatings == null) {
                // set the new number ratings = 1
                let setNumberRatingsInput = {
                    pkName: 'pk',
                    pkValue: addRatingInput.havenId,
                    skName: 'sk',
                    skValue: `BD#${addRatingInput.havenId}`,
                    attributeName: 'numberRatings',
                    attributeValue: '1',
                }

                this.updateSingleAttributeForHaven(setNumberRatingsInput)

                //  set the theRating to the rating that the first user just submitted
                let setTheRatingInput = {
                    pkName: 'pk',
                    pkValue: addRatingInput.havenId,
                    skName: 'sk',
                    skValue: `BD#${addRatingInput.havenId}`,
                    attributeName: 'theRating',
                    attributeValue: addRatingInput.rating,
                }
                this.updateSingleAttributeForHaven(setTheRatingInput)
            } else {
                let newNumberRatings = fetchHavenPreviousRatingData.numberRatings + 1

                let setNumberRatingsInput = {
                    pkName: 'pk',
                    pkValue: addRatingInput.havenId,
                    skName: 'sk',
                    skValue: `BD#${addRatingInput.havenId}`,
                    attributeName: 'numberRatings',
                    attributeValue: newNumberRatings,
                }
                this.updateSingleAttributeForHaven(setNumberRatingsInput)
                // todo theRating = (theRating  * numberRatings + newRating) / (numberRatings  + 1)

                let newTheRating =
                    (+fetchHavenPreviousRatingData.theRating *
                        +fetchHavenPreviousRatingData.numberRatings +
                        +addRatingInput.rating) /
                    (+fetchHavenPreviousRatingData.numberRatings + 1)

                // REMOVAL -----------------------------------------------------------------------------
                // todo IF THEY HAVE ALREADY RATED it
                // todo theRating = (theRating  * numberRatings - old rating + newRating) / (numberRatings)

                //! this is not done
                let newTheRatingRemoval =
                    (+fetchHavenPreviousRatingData.theRating *
                        +fetchHavenPreviousRatingData.numberRatings +
                        +addRatingInput.rating) /
                    (+fetchHavenPreviousRatingData.numberRatings + 1)
                //! this is not done

                let setTheRatingInput = {
                    pkName: 'pk',
                    pkValue: addRatingInput.havenId,
                    skName: 'sk',
                    skValue: `BD#${addRatingInput.havenId}`,
                    attributeName: 'theRating',
                    attributeValue: JSON.stringify(newTheRating),
                }
                this.updateSingleAttributeForHaven(setTheRatingInput)
            }
        }
        return response
    }

    async updateSingleAttributeForHaven(input: iUpdateSingleAttributeForGameInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateSingleAttributeForGame(
                    $input: IGQL_UpdateSingleAttributeForGameInput!
                ) {
                    updateSingleAttributeForGame(input: $input) {
                        pkName
                        pkValue
                        skName
                        skValue
                        attributeName
                        attributeValue
                    }
                }
            `,
            variables: { input },
        })
        return response.data.updateSingleAttributeForGame
    }




    async listHavenTrackingItemsByType(type: string, limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listHavenTrackingItemsByType( $type: String, $limit: Int,  $nextToken: String) {
                    listHavenTrackingItemsByType( type: $type, limit: $limit, nextToken: $nextToken ) {
                        nextToken
                        havens {

                            pk_HAVENID
                            sk_TYPE_UID_ULID
                            byHaven_byType_byLocation_byUser_PK_HAVENID
                            byUser_byType_byHaven_PK_UID
                            byUser_byType_byHaven_SK_TYPE_HAVENID_ULID
                            createdAt
                            id
                            PK_HAVENID
                            SK_byType_byZip
                            typePK
                            typeSK
                            updatedAt
                            username
                            userTitle

                        }
                    }
                }
            `,
            variables: { type, limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listHavenTrackingItemsByType
    }











    // todo SOMEDAY, fix this issue where this hsould be in the games service Shay's feelings are hurt
    async fetchHavenPreviousRatingData(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getHaven($id: ID!) {
                    getHaven(id: $id) {
                        numberRatings
                    }
                }
            `,
            variables: { id },
            fetchPolicy: 'network-only',
        })
        return response.data.getHaven
    }
}
