import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core'
import { TimingHelperService } from '../../services/helpers/timing-helper.service'

@Component({
  selector: 'app-players-needed-bar[_sourceOfInput]',
  templateUrl: './players-needed-bar.component.html',
  styleUrls: ['./players-needed-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayersNeededBarComponent implements OnInit {

  @Input() _sourceOfInput: string

  blockElements = null

  numbers = []

  isLarge = false
  @Input() set _isLarge(isLarge: boolean) {
    this.isLarge = isLarge
  }

  isInteractive: boolean = false
  @Input() set _isInteractive(isInteractive: boolean) {
    if (this.isInteractive && !isInteractive) { // reset to original value
      let desiredIndicesOriginalStringified = JSON.stringify(this.desiredIndicesOriginal)
      this.desiredIndices = JSON.parse(desiredIndicesOriginalStringified) // shallow copy
    }
    this.isInteractive = isInteractive
  }

  shouldAnimate = true
  @Input() set _shouldAnimate(shouldAnimate: boolean) {
    this.shouldAnimate = shouldAnimate
  }

  currentPlayerCount
  @Input() set _currentPlayerCount(currentPlayerCount: number) {
    this.currentPlayerCount = currentPlayerCount
  }

  gameMin
  @Input() set _gameMin(gameMin: number) {
    this.gameMin = gameMin
  }

  gameMax
  @Input() set _gameMax(gameMax: number) {
    this.gameMax = gameMax > 8 ? 8 : gameMax
  }

  communityMin
  @Input() set _communityMin(communityMin) {
    this.communityMin = communityMin
  }

  communityMax
  @Input() set _communityMax(communityMax) {
    this.communityMax = communityMax
  }

  accept1 = false
  @Input() set _accept1(accept1: boolean) {
    this.accept1 = accept1
  }

  desiredIndices
  desiredIndicesOriginal // dont modify this!!! it is used to reset when isInteractive changes from true to false
  @Input() set _desiredIndices(desiredIndices: number[]) {
    if (!this.desiredIndices) { // needed so not to switch back to default when making changes in interactive mode
      this.desiredIndices = desiredIndices

      let desiredIndicesStringified = JSON.stringify(desiredIndices)
      this.desiredIndicesOriginal = JSON.parse(desiredIndicesStringified) // shallow copy
    }
  }

  customLowerBound
  @Input() set _customLowerBound(lowerBound: number) {
    this.customLowerBound = lowerBound
  }

  customUpperBound
  @Input() set _customUpperBound(upperBound: number) {
    this.customUpperBound = upperBound
  }

  @Output() desiredIndicesChangedEmitter = new EventEmitter()

  constructor(private timingHelperService: TimingHelperService) { }

  blockSizeBase = '20px'
  blockSizeHovered = '40px'
  blockSizeHoveredSibling1 = '30px'
  blockSizeHoveredSibling2 = '25px'

  ngOnInit(): void {
    for (var i = 1; i <= this.gameMax; i++) {
      this.numbers.push(i)
    }

    if (this.isLarge) {
      this.blockSizeBase = '40px'
      this.blockSizeHovered = '80px'
      this.blockSizeHoveredSibling1 = '65px'
      this.blockSizeHoveredSibling2 = '52px'
    }
  }

  blockContentIsLoaded = false
  ngAfterViewChecked() {
    if (!this.blockContentIsLoaded) {
      this.blockElements = document.getElementsByClassName('block')
      if (this.blockElements != null) {
        this.blockContentIsLoaded = true
      }
    }
  }

  getColor(index) {
    if (this.customLowerBound != null && this.customUpperBound != null) {
      if (this.desiredIndices.includes(index + 1)) {
        return this.getIsOutOfSpecifiedBounds(index) ? '#BDBDBD' : '#116466' // gray or gh-color-primary
      }
      else {
        return this.getIsOutOfSpecifiedBounds(index) ? '#BDBDBD' : '#ffcb9a' // gray or gh-color-4
      }
    }
    else {
      if (this.desiredIndices.includes(index + 1)) {
        return '#116466' // gh-color-primary
      }
      else {
        return '#ffcb9a' // gh-color-4
      }
    }
  }

  getIsOutOfSpecifiedBounds(index) {
    return this.customLowerBound && index + 1 < this.customLowerBound && this.customLowerBound > this.gameMin || this.customUpperBound && index + 1 > this.customUpperBound && this.customUpperBound < this.gameMax
  }

  getIsInteractive(index) {
    return this.isInteractive && !this.getIsOutOfSpecifiedBounds(index)
  }

  onBlockClicked(index) {
    if (this.isInteractive && !this.getIsOutOfSpecifiedBounds(index)) {
      if (this.desiredIndices.includes(index + 1) && (this.desiredIndices.length != 1 || this.accept1)) {
        this.desiredIndices.splice(this.desiredIndices.indexOf(index + 1), 1)
      }
      else {
        this.desiredIndices.push(index + 1)
      }
      this.desiredIndicesChangedEmitter.emit(this.desiredIndices)
    }
  }


  isHovering = false

  onMouseEnter(index) {
    if (this.isInteractive) {
      this.isHovering = true

      if (this.shouldAnimate) {
        for (var i = 0; i < this.blockElements.length; i++) {
          if (i != this.blockElements.length - 1) {
            this.blockElements[i].style.marginRight = '4px'
          }
          this.blockElements[i].style.borderRadius = '8px'
          if (i < index - 2 || i > index + 2) {
            this.blockElements[i].style.height = this.blockSizeBase
            this.blockElements[i].style.width = this.blockSizeBase
            this.blockElements[i].firstElementChild.style.height = this.blockSizeBase
            this.blockElements[i].firstElementChild.style.width = this.blockSizeBase
          }
        }

        this.setHoveredBlockStyling(this.blockElements[index], this.blockSizeHovered)
        this.setSiblingBlockStyling(this.blockElements[index - 1], this.blockSizeHoveredSibling1, 1)
        this.setSiblingBlockStyling(this.blockElements[index + 1], this.blockSizeHoveredSibling1, 1)
        this.setSiblingBlockStyling(this.blockElements[index - 2], this.blockSizeHoveredSibling2, 2)
        this.setSiblingBlockStyling(this.blockElements[index + 2], this.blockSizeHoveredSibling2, 2)
      }
      else {
        this.blockElements[index].style.opacity = '0.5'
        this.blockElements[index].style.transition = '0.25s'
      }

    }

  }

  setHoveredBlockStyling(element, scalePx: string) {
    element.style.height = scalePx
    element.style.width = scalePx
    element.firstElementChild.style.height = scalePx
    element.firstElementChild.style.width = scalePx
    element.firstElementChild.style.lineHeight = scalePx
    element.style.boxShadow = '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'
  }

  setSiblingBlockStyling(element, scalePx: string, distanceFromHoveredIndex: number) {
    if (element) {
      element.style.height = scalePx
      element.style.width = scalePx
      element.firstElementChild.style.height = scalePx
      element.firstElementChild.style.width = scalePx
      element.firstElementChild.style.lineHeight = scalePx
      distanceFromHoveredIndex == 1 ? element.style.boxShadow = '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)' : element.style.boxShadow = '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
    }
  }

  onMouseLeave(index) {
    this.isHovering = false
    this.timingHelperService.delay(100).then(() => {
      if (!this.isHovering) {

        for (var i = 0; i < this.blockElements.length; i++) {
          this.blockElements[i].style.height = this.blockSizeBase
          this.blockElements[i].style.width = this.blockSizeBase
          this.blockElements[i].firstElementChild.style.height = this.blockSizeBase
          this.blockElements[i].firstElementChild.style.width = this.blockSizeBase
          this.blockElements[i].firstElementChild.style.lineHeight = this.blockSizeBase
          this.blockElements[i].style.marginRight = '0'
          this.blockElements[i].style.boxShadow = 'none'
          if (i == 0) {
            this.blockElements[i].style.borderRadius = '8px 0 0 8px'
          }
          else if (i == this.blockElements.length - 1) {
            this.blockElements[i].style.borderRadius = '0 8px 8px 0'
          }
          else {
            this.blockElements[i].style.borderRadius = '0'
          }
        }

      }
    })

    if (!this.shouldAnimate) {
      this.blockElements[index].style.opacity = '1'
      this.blockElements[index].style.transition = '0.25s'
    }
  }

}

