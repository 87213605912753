
import { environment } from './environments/environment'
const region = 'us-east-1'

const amplifySdkConfig = {
  Auth: {
    region,
    userPoolId: environment.cognitoUserPoolId,
    userPoolWebClientId: environment.cognitoUserPoolWebClientId,
  },
  aws_appsync_graphqlEndpoint: environment.apiUrl,
  aws_appsync_region: region,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
}


export default amplifySdkConfig
