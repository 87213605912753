import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class SoundEffectsService {

  shouldPlaySound = true

  private soundIsEnabled = new BehaviorSubject(this.shouldPlaySound)
  soundIsEnabled$ = this.soundIsEnabled.asObservable()

  constructor() { }

  updateSoundIsEnabled(value) {
    this.soundIsEnabled.next(value)
    this.shouldPlaySound = value
  }

  //TODO: why multiple audio.play() needed???
  //TODO: need a timeout or something????

  playCoinNoise() {
    if (this.shouldPlaySound) {
      let audio = new Audio()
      audio.src = "../../../../assets/audio/coins.wav"
      audio.load()
      audio.play()
      audio.play()
      audio.play()
      audio.play()
      audio.play()
    }
  }

  playSecretFoundNoise() {
    if (this.shouldPlaySound) {
      let audio = new Audio()
      audio.src = "../../../../assets/audio/zelda_secret_sound.mp3"
      audio.load()
      audio.play()
    }
  }

  playLockNoise() {
    if (this.shouldPlaySound) {
      let audio = new Audio()
      audio.src = "../../../../assets/audio/door_unlock.wav"
      audio.load()
      audio.play()
    }
  }

}
