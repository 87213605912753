import { Injectable } from '@angular/core'
@Injectable({
  providedIn: 'root'
})
export class TimingHelperService {

  constructor() { }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}
