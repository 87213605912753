import { RadarChartGroupAttributeEnums, RadarChartHavenAttributeEnums, RadarChartProfileFriendGameAttributeEnums } from '../enums/radar-chart-enums'

export const RADAR_CHART_GROUP_LABELS: string[] = [
    RadarChartGroupAttributeEnums.gamingAtmosphere,
    RadarChartGroupAttributeEnums.membership,
    RadarChartGroupAttributeEnums.competitionLevel,
    RadarChartGroupAttributeEnums.focus,
    RadarChartGroupAttributeEnums.familyFriendly,
    RadarChartGroupAttributeEnums.organization
]

export const RADAR_CHART_HAVEN_LABELS: string[] = [
    RadarChartHavenAttributeEnums.gamingAtmosphere,
    RadarChartHavenAttributeEnums.competitionLevel,
    RadarChartHavenAttributeEnums.tableCount,
    RadarChartHavenAttributeEnums.librarySize,
    RadarChartHavenAttributeEnums.retail,
    RadarChartHavenAttributeEnums.food
]

export const RADAR_CHART_PROFILE_FRIEND_GAME_LABELS: string [] = [
    RadarChartProfileFriendGameAttributeEnums.complexity,
    RadarChartProfileFriendGameAttributeEnums.depth,
    RadarChartProfileFriendGameAttributeEnums.duration,
    RadarChartProfileFriendGameAttributeEnums.interaction,
    RadarChartProfileFriendGameAttributeEnums.competition,
    RadarChartProfileFriendGameAttributeEnums.players,
    RadarChartProfileFriendGameAttributeEnums.chance,
    RadarChartProfileFriendGameAttributeEnums.theme
]
