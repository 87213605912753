import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'nullPlaceholder'
})
export class NullPlaceholderPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return !value || value == null || value == '' || value == -1 ? '?' : value
  }

}
