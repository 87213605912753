import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'noHavenSoDefault'
})
export class NoHavenSoDefaultPipe implements PipeTransform {

    transform(value: unknown, ...args: unknown[]): unknown {
        return !value || value == null || value == '' ? 'Home' : value
    }

}
