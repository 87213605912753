import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private shouldRefreshEvents = new BehaviorSubject(false)
  shouldRefreshEvents$ = this.shouldRefreshEvents.asObservable()

  private isCurrentUsersProfile = new BehaviorSubject(true)
  isCurrentUsersProfile$ = this.isCurrentUsersProfile.asObservable()

  constructor() { }

  getIsCurrentUsersProfile() {
    return this.isCurrentUsersProfile.value
  }

  setIsCurrentUsersProfile(isCurrentUsersProfile: boolean) {
    this.isCurrentUsersProfile.next(isCurrentUsersProfile)
  }

  setShouldRefreshEvents(shouldRefreshEvents: boolean) {
    this.shouldRefreshEvents.next(shouldRefreshEvents)
  }

}
