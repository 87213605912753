<!-- Taken/modified from: https://dzone.com/articles/angular-image-gallery-with-bootstrap -->

<div class="parent-container">

    <!-- *IMAGE -->
    <div class="row mx-0" *ngIf="type == 'image'">
        <div class="col-12 col-md-4 mb-2 card-container" *ngFor="let item of items">
            <mat-card class="card mat-elevation-z6">

                <div class="img-or-video-container">
                    <img style="cursor: pointer;" (click)="showFullSize(item)" class="card-img-top" [src]="item.imgPath"
                        [alt]="item.title" />
                </div>

                <ng-container [ngTemplateOutlet]="cardBody" [ngTemplateOutletContext]="{item:item}"></ng-container>

            </mat-card>
        </div>
    </div>

    <!-- * VIDEO -->
    <div class="row mx-9" *ngIf="type == 'video'">
        <div class="col-12 col-md-6 mb-2 card-container" *ngFor="let item of items">
            <mat-card class="card mat-elevation-z6">

                <div class="img-or-video-container">

                    <!--------------------------------------------------------------------------------  
                        IMPORTANT: iframe width and height must be set (and must be reasonable values). 
                                    values are changed in css, but weird UI issues if not set here
                                    (might event need to adjust the ratio here to get full aspect ratio of video...?)
                    ---------------------------------------------------------------------------------->

                    <!-- Fix this for multiple video view asdfkjfadsoifdjafadsoidfsajio -->

                    <iframe [src]="getTrustedUrl(item.videoPath)" frameborder="0" width="426" height="240"
                        allowfullscreen="true"></iframe>
                </div>






                <ng-container [ngTemplateOutlet]="cardBody" [ngTemplateOutletContext]="{item:item}"></ng-container>







            </mat-card>
        </div>
    </div>
</div>

<ng-template #cardBody let-item="item">
    <div class="card-body">
        <mat-card-title class="gh-sm-title">{{ item.title }}</mat-card-title>
        <mat-card-subtitle>{{ item.subtitle }}</mat-card-subtitle>
        <div *ngIf="item.gameTitle" class="bottom-left-container">
            <p> Game: {{ item.gameTitle }} </p>
        </div>

        <hr *ngIf="getHasTitle(item) || getHasSubtitle(item)">

        <mat-accordion *ngIf="getHasDetails(item)">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Details
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let detail of item.details">
                    <span class="inline-span">
                        <mat-card-subtitle>{{detail.key}}:</mat-card-subtitle>
                    </span>
                    <span class="inline-span ml-1">
                        <p>{{detail.value}}</p>
                    </span>
                </div>

            </mat-expansion-panel>
        </mat-accordion>

        <hr *ngIf="getHasDetails(item)">

        <div class="bottom-left-container">
            <small> {{ item.published | date: "shortDate" }} </small>
        </div>



        <!-- ! Full Delete Via PROFILE -->
        <div *ngIf="origin == 'profile'">

            <div class="bottom-right-container" *ngIf="item.addedByUID == user.username || isGHAdmin">
                <!-- <span *ngIf="item.addedByUID == user.username" class="inline-span ml-3" -->

                <span class="inline-span ml-3" (click)="deleteImageOrVideo(item)">
                    <mat-icon class="gh-icon gh-icon-clickable" matTooltip="Delete" matTooltipPosition="above">delete
                    </mat-icon>
                </span>
            </div>
        </div>



        <div *ngIf="origin == 'havens'">

            <div class="bottom-right-container" *ngIf="item.addedByUID == user.username || isGHAdmin">
                <!-- <span *ngIf="item.addedByUID == user.username" class="inline-span ml-3" -->

                <span class="inline-span ml-3" (click)="removeImageOrVideoTag(item)">
                    <mat-icon class="gh-icon gh-icon-clickable" matTooltip="Remove Tag"
                        matTooltipPosition="above">broken_image
                    </mat-icon>
                </span>
            </div>
        </div>

        <div *ngIf="origin == 'groups'">

            <div class="bottom-right-container" *ngIf="item.addedByUID == user.username || isGHAdmin">
                <!-- <span *ngIf="item.addedByUID == user.username" class="inline-span ml-3" -->

                <span class="inline-span ml-3" (click)="removeImageOrVideoTag(item)">
                    <mat-icon class="gh-icon gh-icon-clickable" matTooltip="Remove Tag"
                        matTooltipPosition="above">broken_image
                    </mat-icon>
                </span>
            </div>
        </div>

        <div *ngIf="origin == 'games'">

            <div class="bottom-right-container" *ngIf="item.addedByUID == user.username || this.isGHAdmin">
                <!-- <span *ngIf="item.addedByUID == user.username" class="inline-span ml-3" -->

                <span class="inline-span ml-3" (click)="removeImageOrVideoTag(item)">
                    <mat-icon class="gh-icon gh-icon-clickable" matTooltip="Remove Tag"
                        matTooltipPosition="above">broken_image
                    </mat-icon>
                </span>
            </div>
        </div>

    </div>
</ng-template>




<!-- FUTURE FEATURE: FLAGGING ITEMS, FOR NOW HANDLES BY CONTACT US -->
<!-- <span class="inline-span ml-3">
                <mat-icon class="gh-icon gh-icon-clickable" matTooltip="Flag as inappropriate"
                    matTooltipPosition="above">flag</mat-icon>
            </span> -->


<!-- FUTURE FEATURE: LIKES -->
<!-- <div class="bottom-right-container">

                <span class="inline-span ml-3">
                    <span class="inline-span">
                        <mat-icon class="gh-icon">thumb_up_alt</mat-icon>
                    </span>
                <span class="inline-span ml-1">
                        {{ item.likes }}
                    </span>
                </span>

            </div> -->