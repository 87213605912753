import { Component, Input, OnInit } from '@angular/core';
import { BackendApiS3Service } from 'src/app/backend-api-services/backend-api-s3.service';

@Component({
  selector: 'app-article-full-detail',
  templateUrl: './article-full-detail.component.html',
  styleUrls: ['./article-full-detail.component.scss']
})
export class ArticleFullDetailComponent implements OnInit {

  constructor(
    private backendApiS3Service: BackendApiS3Service
  ) { }

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
  }


  parsedData
  async ngOnInit() {

    let fetchItem = {
      bucketName: 'gamehaven-api-dev-appdatabucket-d1uqdueie9h5',
      objectKey: this.inputData.item.replace('https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/', '')
    }
    this.parsedData = await this.backendApiS3Service.fetchS3Object(fetchItem) // pass in enum here instead of hard-coded
  }

}
