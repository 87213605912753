import { Injectable } from '@angular/core'
import { Auth } from 'aws-amplify'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { environment } from 'src/environments/environment'
import gql from 'graphql-tag'
import { iUpdateSingleAttributeForGameInput } from '../../../../common/interfaces/GameInterfaces'
import { ContentService } from '../dashboard/dashboard-shared/services/content/content.service'
import { iAddToGamesWeLoveInput, iAddToHavensWeLoveInput, iDeleteGamesWeLoveInput, iDeleteHavensWeLoveInput } from '../../../../common/interfaces/GroupTrackingInterfaces'
import { iDeleteHavenTrackingItemInput } from '../../../../common/interfaces/HavenTrackingInterfaces'


@Injectable({
  providedIn: 'root'
})

export class BackendApiGroupTrackingService {

  constructor(
    private contentService: ContentService
  ) { }


  async createMultipleHavenTrackingItems(havens, currentUserID, state, zip, userTitle, mainImageFiles) {
    let result = []
    for (let haven of havens) {

      result.push(await this.createHavenTrackingItem({
        havenId: haven.pk,
        UID: currentUserID,
        type: 'HWL',
        userState: state,
        userZipCode: zip,
        title: haven.title,
        userTitle: userTitle,
        mainImageFiles: mainImageFiles
        // TODO: using coreSK for now since its the same thing but still need to know why title is null...
      }))
    }
    return result
  }

  async createHavenTrackingItem(addHavensWeLoveInput: iAddToHavensWeLoveInput) {
    let input = addHavensWeLoveInput

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation createHavenTrackingItem($input: IGQL_CreateHavenTrackingItem!) {
            createHavenTrackingItem (input: $input) {
              createdAt
            }
          }
        `,
      variables: { input }
    })
    return response.data.createHavenTrackingItem
  }

  async deleteMultipleHavenTrackingItems(havens, currentUserId) {

    let res = []

    for (let haven of havens) {
      let input = {
        havenId: haven.id,
        UID: currentUserId,
        type: 'HWL',
        // userLocation: undefined,
        title: haven.title
      }

      let deletedHaven = await this.deleteHavenTrackingItem(input)
      res.push(deletedHaven)
    }
    return res
  }

  async deleteHavenTrackingItem(deleteHavensTrackingItemInput: iDeleteHavenTrackingItemInput) {

    let input = deleteHavensTrackingItemInput

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
        mutation deleteHavenTrackingItem($input: IGQL_DeleteHavenTrackingItem!) {
          deleteHavenTrackingItem (input: $input) {
            createdAt
          }
        }
      `,
      variables: { input }
    })
    return response.data.deleteHavenTrackingItem
  }

  // async deleteMultipleGameTrackingGamesWeLove(games, currentUserId) {
  //   let res = []

  //   for (let game of games) {
  //     let input = {
  //       gameId: game.id,
  //       UID: currentUserId,
  //       type: 'GWL',
  //       title: game.title // ! not sure this is needed, we probably want an ID that is set in Backend?
  //     }

  //     let deletedGame = await this.deleteGameTrackingGamesWeLove(input)
  //     res.push(deletedGame)
  //   }
  //   return res
  // }

  // async deleteGameTrackingGamesWeLove(deleteGamesILoveInput: iDeleteGamesWeLoveInput) {

  //   let input = deleteGamesILoveInput

  //   const response = await this.appSyncClient.mutate<any>({
  //     mutation: gql`
  //       mutation deleteGameTrackingGamesWeLove($input: IGQL_DeleteGameTrackingItem!) {
  //         deleteGameTrackingGamesWeLove (input: $input) {
  //           createdAt
  //         }
  //       }
  //     `,
  //     variables: { input }
  //   })

  //   return response.data.deleteGameTrackingGamesWeLove
  // }

  private appSyncClient = new AWSAppSyncClient({
    url: environment.apiUrl,
    region: 'us-east-1',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        return token
      }
    },
    disableOffline: true,
  })

  async listMyGroups(id: string, limit: number, nextToken?) {

    const response = await this.appSyncClient.query<any>({
      query: gql`
        query listMyGroups($id: ID!, $limit: Int, $nextToken: String) {
          listMyGroups(id: $id, limit: $limit, nextToken: $nextToken) {
            nextToken
            groups{
              title
              PK_GROUPID
              mainImageFiles
              sk_TYPE_UID_ULID
              }      
            }
          }
        `,
      variables: { id, limit, nextToken },
      fetchPolicy: 'network-only'

    })

    return response.data.listMyGroups
  }

  async listFollowersByGroupId(id: string, limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
        query listFollowersByGroupId($id: ID!, $limit: Int, $nextToken: String) {
          listFollowersByGroupId(id: $id, limit: $limit, nextToken: $nextToken) {
            nextToken
            followers{
              groupTitle
              SK_byType_byZip
              updatedAt
              createdAt
              mainImageFiles
              userTitle
              }      
            }
          }
        `,
      variables: { id, limit, nextToken },
      fetchPolicy: 'network-only'

    })
    return response.data.listFollowersByGroupId
  }

  async updateSingleAttributeForGroup(input: iUpdateSingleAttributeForGameInput) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
        mutation updateSingleAttributeForGame($input: IGQL_UpdateSingleAttributeForGameInput!) {
          updateSingleAttributeForGame (input: $input) {
            pkName
            pkValue
            skName
            skValue
            attributeName
            attributeValue
          }
        }
      `,
      variables: { input }
    })
    return response.data.updateSingleAttributeForGame
  }

}
