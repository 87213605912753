import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import Cropper from 'cropperjs'
// import 'cropperjs/dist/cropper.css'

@Component({
  selector: 'app-image-cropper[_sourceOfInput]',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  @Input() _sourceOfInput: string

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
  }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  @Output() closeEmitter = new EventEmitter()

  @ViewChild("image", { static: false })
  public imageElement: ElementRef

  public imageUpload: File

  public imageDestination: string

  private cropper: Cropper

  url

  constructor(private dialogRef: MatDialogRef<ImageCropperComponent>) {
    this.imageDestination = ""
  }

  ngOnInit(): void {
  }

  imageElementLoaded = false
  ngAfterViewInit() {
    if (!this.imageElementLoaded) {
      if (this.imageElement) {
        this.imageElementLoaded = true
        this.cropper = new Cropper(this.imageElement.nativeElement, {
          zoomable: false,
          scalable: false,
          aspectRatio: NaN,
          crop: () => {
            const canvas = this.cropper.getCroppedCanvas()

            // ! Switched to .jpg , might cause an issue
            this.imageDestination = canvas.toDataURL("image/png")
          }
        })
      }
    }
  }

  submit() {
    this.close(this.imageDestination)
  }

  close(data?) {
    this.dialogRef.close(data)
  }

}
