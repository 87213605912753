<mat-card-content>

    <div class="data-group data-group-no-min-height">

        <div class="row">
            <mat-card-subtitle>Select a layout for this row:</mat-card-subtitle>
        </div>

        <div class="row">
            <mat-radio-group aria-label="Select an option" (change)="selectLayout($event)">

                <div class="col-12">
                    <div class="row mt-3">

                        <div class="col-4">
                            <mat-radio-button value="1" [checked]="true">
                                <ng-container *ngTemplateOutlet="layout1Img"></ng-container>
                            </mat-radio-button>
                        </div>

                        <div class="col-4">
                            <mat-radio-button value="2" class="ml-5">
                                <ng-container *ngTemplateOutlet="layout2Img"></ng-container>
                            </mat-radio-button>
                        </div>

                        <div class="col-4">
                            <mat-radio-button value="3" class="ml-5">
                                <ng-container *ngTemplateOutlet="layout3Img"></ng-container>
                            </mat-radio-button>
                        </div>

                    </div>

                </div>

            </mat-radio-group>
        </div>

    </div>

    <section *ngFor="let content of getControls(); let i = index">

        <div class="data-group">
            <div class="row col-header-row">
                <div class="col-12 text-center">
                    <span class="inline-span mr-3">
                        <mat-card-subtitle>Column {{i+1}}</mat-card-subtitle>
                    </span>

                    <!-- LAYOUT 1 -->
                    <span class="inline-span" *ngIf="layoutId == 1">
                        <ng-container *ngTemplateOutlet="layout1Img"></ng-container>
                    </span>

                    <!-- LAYOUT 2 -->
                    <span class="inline-span" *ngIf="layoutId == 2 && i == 0">
                        <ng-container *ngTemplateOutlet="layout2ImgLeftSelected"></ng-container>
                    </span>
                    <span class="inline-span" *ngIf="layoutId == 2 && i == 1">
                        <ng-container *ngTemplateOutlet="layout2ImgRightSelected"></ng-container>
                    </span>

                    <!-- LAYOUT 3 -->
                    <span class="inline-span" *ngIf="layoutId == 3 && i == 0">
                        <ng-container *ngTemplateOutlet="layout3ImgLeftSelected"></ng-container>
                    </span>
                    <span class="inline-span" *ngIf="layoutId == 3 && i == 1">
                        <ng-container *ngTemplateOutlet="layout3ImgMiddleSelected"></ng-container>
                    </span>
                    <span class="inline-span" *ngIf="layoutId == 3 && i == 2">
                        <ng-container *ngTemplateOutlet="layout3ImgRightSelected"></ng-container>
                    </span>
                </div>
            </div>


            <mat-tab-group mat-stretch-tabs class="gh-tab-group">

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">text_format</mat-icon>
                        Text
                    </ng-template>

                    <div class="content">
                        <div class="row">
                            <mat-form-field>
                                <mat-label>Text to Display</mat-label>
                                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                                    (input)="setText($event, i)"></textarea>
                            </mat-form-field>
                        </div>

                    </div>

                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">image</mat-icon>
                        Image
                    </ng-template>

                    <div class="content">
                        <div class="row">
                            <app-gh-file-uploader (selectedImageChanged)="setImage($event, i)" #fileUploader
                                [_isProfileImg]="false" [_defaultImage]="selectedImage"></app-gh-file-uploader>
                        </div>
                    </div>

                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">ondemand_video</mat-icon>
                        Video
                    </ng-template>

                    <div class="content">
                        <div class="row">
                            add video selector here
                            <button (click)="setVideo(i)">Temp button to add an awesome video</button>
                        </div>
                    </div>

                </mat-tab>

            </mat-tab-group>
        </div>



    </section>

</mat-card-content>



<ng-template #layout1Img>
    <img class="layout-img" src="../../../../../../assets/images/story-builder/story-builder-1-col.png" alt="">
</ng-template>

<ng-template #layout2Img>
    <img class="layout-img" src="../../../../../../assets/images/story-builder/story-builder-2-col.png" alt="">
</ng-template>

<ng-template #layout3Img>
    <img class="layout-img" src="../../../../../../assets/images/story-builder/story-builder-3-col.png" alt="">
</ng-template>

<ng-template #layout2ImgLeftSelected>
    <img class="layout-img" src="../../../../../../assets/images/story-builder/story-builder-2-col-left-selected.png"
        alt="">
</ng-template>

<ng-template #layout2ImgRightSelected>
    <img class="layout-img" src="../../../../../../assets/images/story-builder/story-builder-2-col-right-selected.png"
        alt="">
</ng-template>

<ng-template #layout3ImgLeftSelected>
    <img class="layout-img" src="../../../../../../assets/images/story-builder/story-builder-3-col-left-selected.png"
        alt="">
</ng-template>

<ng-template #layout3ImgMiddleSelected>
    <img class="layout-img" src="../../../../../../assets/images/story-builder/story-builder-3-col-middle-selected.png"
        alt="">
</ng-template>

<ng-template #layout3ImgRightSelected>
    <img class="layout-img" src="../../../../../../assets/images/story-builder/story-builder-3-col-right-selected.png"
        alt="">
</ng-template>