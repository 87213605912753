import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ChartType } from 'chart.js'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { RADAR_CHART_PROFILE_FRIEND_GAME_LABELS, RADAR_CHART_GROUP_LABELS, RADAR_CHART_HAVEN_LABELS } from 'src/app/dashboard/constants/radar-chart-constants'
import { RadarChartOverlayTypeEnums, RadarChartTypeEnums, RadarChartProfileFriendGameAttributeEnums, RadarChartAttributeEnums, RadarChartGroupAttributeEnums, RadarChartHavenAttributeEnums } from 'src/app/dashboard/enums/radar-chart-enums'
import { RadarChartService } from 'src/app/dashboard/dashboard-shared/services/charts/radar/radar-chart.service'
import { BehaviorSubject } from 'rxjs'
import { BaseChartDirective } from 'ng2-charts'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { defaultRatingConst } from 'src/app/dashboard/dashboard-shared/constants/games.constants'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatSnackBar } from '@angular/material/snack-bar'
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-profile-gh-radar-chart[_sourceOfInput]',
  templateUrl: './gh-profile-radar-chart.component.html',
  styleUrls: ['./gh-profile-radar-chart.component.scss']
})
export class GhProfileRadarChartComponent implements OnInit, AfterViewChecked {

  complexityRating: number = defaultRatingConst
  depthRating: number = defaultRatingConst
  durationRating: number = defaultRatingConst
  interactionRating: number = defaultRatingConst
  competitionRating: number = defaultRatingConst
  playersRating: number = defaultRatingConst
  chanceRating: number = defaultRatingConst
  thematicRating: number = defaultRatingConst

  isMobileScreen = true
  initialRatingsAreEnabled = true
  initialRatingsCheckboxMessage = 'Check the initial ratings checkbox to modify.'
  isLoading = new BehaviorSubject(true)
  isLoading$ = this.isLoading.asObservable()
  form: UntypedFormGroup
  uniqueAttribute = 'id'
  attributes // check order of magnitude
  overlayAttributes// check order of magnitude
  overlayType: RadarChartOverlayTypeEnums
  hasOverlayOption = false
  displayOverlay = false
  isEditing = true
  hasRated = false
  currentRatingDataSet
  chartType: ChartType = 'radar'
  radarChartLabelsStringified
  radarChartLabelsShallowCopy
  chartLegend = false
  chartData
  chartLabels
  mostRecentDataValues = []

  radarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements:
    {
      point:
      {
        radius: 1,
        hitRadius: 5,
        hoverRadius: 10,
        hoverBorderWidth: 2
      }
    },
    scales: {
      r: {
        min: 0,
        max: 10,
        beginAtZero: true,
        angleLines: {
          color: "rgba(245, 121, 58,0.6)",
        },
        pointLabels: {
          font: {
            size: 16,
          },
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
    }
  }



  @ViewChild(BaseChartDirective) chart: BaseChartDirective

  @Input() _sourceOfInput: string

  inputData
  selectedItem
  @Input() set _inputData(inputData) {
    this.inputData = inputData
    if (inputData.uniqueAttribute) {
      this.uniqueAttribute = inputData.uniqueAttribute
    }

    this.selectedItem = inputData.selectedItem

  }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submitRating()
    }
  }

  @Output() closeEmitter = new EventEmitter()

  async initChartData(inputData) {



    this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })

    this.inputData = inputData

    this.attributes = inputData.attributes
    this.overlayAttributes = inputData.overlayAttributes //! this no longer makes sense, comes from the fetch for tracking item instead
    this.overlayType = inputData.overlayType

    this.initProfileBig8()

    //todo do we need a conditional here based on 
    this.initCurrentUsersBig8VotesWhenViewingFriend() //* PREVIOUS RATINGS

    let chartDataAsAny: any = this.chartData[0]
    for (var i = 0; i < chartDataAsAny.data.length; i++) {
      this.mostRecentDataValues.push(chartDataAsAny.data[i]) //! mostRecentDataValues HERE -----
    }
  }

  initProfileBig8() {
    this.chartData = [
      {
        data: this.attributes,
        borderColor: '#116466', // aka GH color primary
        fill: true,
        backgroundColor: "#11646675", // sets fill for area inside lines 
        pointRadius: 10,
        pointHoverRadius: 20,
        pointBackgroundColor: 'white',
        pointBorderColor: '#116466', // aka GH Dark
        pointBorderWidth: 2
      }
    ]
  }


  async initCurrentUsersBig8VotesWhenViewingFriend() {
    this.hasRated = true

    this.hasOverlayOption = !this.inputData.isCurrentUsersProfile
    this.displayOverlay = true

    this.chartData.push(
      {
        data: this.inputData.overlayAttributes,
        borderColor: '#ffcb9a', // aka GH color 4
        fill: true,
        // backgroundColor: "#ffcb9a99", // sets fill for area inside lines 
        backgroundColor: "black", // sets fill for area inside lines 
        pointRadius: 10,
        pointHoverRadius: 20,
        pointBackgroundColor: 'white',
        pointBorderColor: '#ffcb9a99', // aka GH Dark
        pointBorderWidth: 4,
        dragData: true
      }
    )

    this.complexityRating = this.chartData[0].data[0]
    this.depthRating = this.chartData[0].data[1]
    this.durationRating = this.chartData[0].data[2]
    this.interactionRating = this.chartData[0].data[3]
    this.competitionRating = this.chartData[0].data[4]
    this.playersRating = this.chartData[0].data[5]
    this.chanceRating = this.chartData[0].data[6]
    this.thematicRating = this.chartData[0].data[7]

    this.form.get('complexityRating').patchValue(this.complexityRating)
    this.form.get('depthRating').patchValue(this.depthRating)
    this.form.get('durationRating').patchValue(this.durationRating)
    this.form.get('interactionRating').patchValue(this.interactionRating)
    this.form.get('competitionRating').patchValue(this.competitionRating)
    this.form.get('playersRating').patchValue(this.playersRating)
    this.form.get('chanceRating').patchValue(this.chanceRating)
    this.form.get('thematicRating').patchValue(this.thematicRating)
  }


  constructor(
    private snackbar: MatSnackBar,
    private chartService: RadarChartService,
    private screenSizeService: ScreenSizeService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackbarService,

    private backendApiUsersService: BackendAPIUsersService,
    private backendApiGameTrackingService: BackendApiGameTrackingService
  ) { }

  formIsLoaded = false

  radarChartAttributeEnum
  radarChartTypeEnum

  user
  previousUserBig8Votes
  async ngOnInit() {

    this.form = this.formBuilder.group({
      complexityRating: [{ value: this.complexityRating, disabled: !this.initialRatingsAreEnabled }],
      depthRating: [{ value: this.depthRating, disabled: !this.initialRatingsAreEnabled }],
      durationRating: [{ value: this.durationRating, disabled: !this.initialRatingsAreEnabled }],
      interactionRating: [{ value: this.interactionRating, disabled: !this.initialRatingsAreEnabled }],
      competitionRating: [{ value: this.competitionRating, disabled: !this.initialRatingsAreEnabled }],
      playersRating: [{ value: this.playersRating, disabled: !this.initialRatingsAreEnabled }],
      chanceRating: [{ value: this.chanceRating, disabled: !this.initialRatingsAreEnabled }],
      thematicRating: [{ value: this.thematicRating, disabled: !this.initialRatingsAreEnabled }],
    })

    this.initChartData(this.inputData).then(() => {
      this.isLoading.next(false)
    })

    this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobileScreen: boolean) => {
      this.isMobileScreen = isMobileScreen

      if (this.isMobileScreen) {
        this.radarChartOptions.scales.r.pointLabels.font.size = 12
        this.chartData[0].pointRadius = 4
        this.chart.update()
      }
    })

    switch (this.inputData.radarChartType) {

      case RadarChartTypeEnums.profile:
        this.radarChartAttributeEnum = RadarChartProfileFriendGameAttributeEnums
        this.radarChartTypeEnum = RadarChartAttributeEnums.friend
        this.radarChartLabelsStringified = JSON.stringify(RADAR_CHART_PROFILE_FRIEND_GAME_LABELS)
        break


    }

    this.radarChartLabelsShallowCopy = JSON.parse(this.radarChartLabelsStringified)
    this.chartLabels = JSON.parse(this.radarChartLabelsStringified)


    this.formIsLoaded = true




  }

  ngDoCheck() { // ! Something is going wrong here? The previous value might be influencing the next?
    if (this.chartData) {
      for (var i = 0; i < this.mostRecentDataValues.length; i++) { // ! THE ISSUE IS HERE? SOMETHING WITH THE MOST RECENT DATA VALUE?
        if (this.mostRecentDataValues[i] != this.chartData[0].data[i]) {
          this.chartLabels[i] = this.chartService.getUpdatedLabel(this.radarChartAttributeEnum, this.radarChartLabelsShallowCopy[i], this.mostRecentDataValues[i], this.radarChartTypeEnum)
        }
      }
      for (var i = 0; i < this.mostRecentDataValues.length; i++) {
        this.mostRecentDataValues[i] = this.chartData[0].data[i]
      }
    }
  }

  chartIsLoaded = false
  ngAfterViewChecked() {
    if (!this.chartIsLoaded) {
      if (this.chart) {
        this.chartIsLoaded = true
        this.radarChartOptions = this.chartService.updateChartOption(this.radarChartOptions, 'dragData', true)
        this.chart.update()
      }
    }
  }

  toggleOverlay() {
    this.displayOverlay = !this.displayOverlay
    if (this.displayOverlay) {
      this.chartData[1].data = this.overlayAttributes
    }
    else {
      this.chartData[1].data = null
    }
    this.chart.update()
  }

  cancelRating() {
    this.isEditing = false
    this.attributes = this.inputData.attributes
  }

  editRating() {
    this.isEditing = true
  }

  removeRating() {
    this.hasRated = false
  }

  saveChanges() {
    this.isEditing = false
  }

  async submitRating() {
    // todo: need to pass these updated values back up to parent (mini-radar via viewChild?)

    this.snackbarService.openSuccessSnackBar('Information Recorded in the Database')
    this.hasRated = true
    this.isEditing = false

    this.backendApiUsersService.updateUGQDataWithPKandSK(this.user.username, this.chartData[0].data)

    this.closeEmitter.emit('resetData')
  }

  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  getHideOverlayButtonText() {
    switch (this.overlayType) {
      case RadarChartOverlayTypeEnums.myAttributes:
        return 'Hide ' + this.overlayType + ' Overlay'
    }
  }

  getShowOverlayButtonText() {
    switch (this.overlayType) {
      case RadarChartOverlayTypeEnums.myAttributes:
        return 'Show ' + this.overlayType + ' Overlay'
    }
  }

  getCanvasPx() {
    return this.isMobileScreen ? 200 : 400
  }

  onComplexitySliderChanged() {
    this.complexityRating = this.form.get('complexityRating').value
    this.chartData[0].data[0] = this.form.get('complexityRating').value
    this.chart.update();
  }

  onDepthSliderChanged() {
    this.depthRating = this.form.get('depthRating').value
    this.chartData[0].data[1] = this.form.get('depthRating').value
    this.chart.update();
  }

  onDurationSliderChanged() {
    this.durationRating = this.form.get('durationRating').value
    this.chartData[0].data[2] = this.form.get('durationRating').value
    this.chart.update();
  }

  onInteractionSliderChanged() {
    this.interactionRating = this.form.get('interactionRating').value
    this.chartData[0].data[3] = this.form.get('interactionRating').value
    this.chart.update();
  }

  onCompetitionSliderChanged() {
    this.competitionRating = this.form.get('competitionRating').value
    this.chartData[0].data[4] = this.form.get('competitionRating').value
    this.chart.update();
  }

  onPlayersSliderChanged() {
    this.playersRating = this.form.get('playersRating').value
    this.chartData[0].data[5] = this.form.get('playersRating').value
    this.chart.update();
  }

  onChanceSliderChanged() {
    this.chanceRating = this.form.get('chanceRating').value
    this.chartData[0].data[6] = this.form.get('chanceRating').value
    this.chart.update();
  }

  onThematicSliderChanged() {
    this.thematicRating = this.form.get('thematicRating').value
    this.chartData[0].data[7] = this.form.get('thematicRating').value
    this.chart.update();
  }
}
