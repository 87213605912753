import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { iGhSwiperItem } from 'src/app/dashboard/dashboard-shared/interfaces/media/iGhSwiperItem'

import SwiperCore, { Pagination, Navigation } from 'swiper'
SwiperCore.use([Pagination])
SwiperCore.use([Navigation])

@Component({
    selector: 'app-gh-swiper',
    templateUrl: './gh-swiper.component.html',
    styleUrls: ['./gh-swiper.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class GhSwiperComponent implements OnInit {
    items
    @Input() set _items(items) {
        this.processItems(items)
    }

    processItems(items) {
        for (let item of items) {
            if (
                !item.mainImageFiles ||
                item.mainImageFiles[0] == '' ||
                item.mainImageFiles[0] == '[]'
            ) {
                item.mainImageFiles = [
                    window.location.origin + '/assets/images/defaults/placeholder-event.png',
                    window.location.origin + '/assets/images/defaults/placeholder-event.png',
                    window.location.origin + '/assets/images/defaults/placeholder-event.png',
                    window.location.origin + '/assets/images/defaults/placeholder-event.png',
                ]
            }
        }
        this.items = items
    }

    imgMaxSizePx = 80 // TODO: change this to % or rem or something eventually
    @Input() set _imgMaxSizePx(imgMaxSizePx: number) {
        this.imgMaxSizePx = imgMaxSizePx
    }

    numberOfTiles
    @Input() set _numberOfTiles(numberOfTiles: number) {
        this.numberOfTiles = numberOfTiles
    }

    @Output() _itemClickedEmitter = new EventEmitter(null)

    constructor() {}

    ngOnInit(): void {
        // this.processItems(this.items)
    }

    getImgMaxSize() {
        return this.imgMaxSizePx.toString().concat('px')
    }

    //! could also use index as a param if item is not sifficient...
    onImageClicked(item) {
        this._itemClickedEmitter.emit(item)
    }
}
