import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'

import { IconSnackbarComponent } from '../../components/custom-snackbars/icon-snackbar/icon-snackbar.component'
import { SoundEffectsService } from '../../services/audio/sound-effects.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialog } from '@angular/material/dialog'


@Component({
  selector: 'app-gh-ratings',
  templateUrl: './gh-ratings.component.html',
  styleUrls: ['./gh-ratings.component.scss']
})
export class GhRatingsComponent implements OnInit {

  isReadOnly = true
  @Input() set _isReadOnly(isReadOnly: boolean) {
    this.isReadOnly = isReadOnly
  }

  @Input('_rating') rating: number = 0;
  @Input('_starCount') starCount: number = 10;
  @Input('_color') color: string = 'accent';
  @Output() submitEmitter = new EventEmitter()
  @Output() clearEmitter = new EventEmitter()

  constructor(
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private soundEffectsService: SoundEffectsService) { }


  private snackBarDuration: number = 2000;
  possibleRatings = [];

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.possibleRatings.push(index);
    }
  }
  onClick(rating: number) {
    // this.snackBar.open('You rated ' + rating + ' / ' + this.starCount, '', {
    //   duration: this.snackBarDuration
    // });
    this.rating = rating
    this.submitEmitter.emit(this.rating.toString())
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }

  clear() {
    this.rating = 0
    this.clearEmitter.emit()
  }

}

export enum StarRatingColor {
  primary = "primary",
  accent = "accent",
  warn = "warn"
}
