
export const SPECIAL_CHARACTERS = ['@', '%', '+', '\\', '/', '\'', '!', '#', '$', '^', '?', ':', ',', '(', ')', '{', '}', '[', ']', '~', '-', '_', '.']

export const MONTHS_LIST = [
    { key: 'January', value: '01' },
    { key: 'February', value: '02' },
    { key: 'March', value: '03' },
    { key: 'April', value: '04' },
    { key: 'May', value: '05' },
    { key: 'June', value: '06' },
    { key: 'July', value: '07' }, 
    { key: 'August', value: '08' },
    { key: 'September', value: '09' },
    { key: 'October', value: '10' },
    { key: 'November', value: '11' },
    { key: 'December', value: '12' }
]
 