import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject, Subscription } from 'rxjs'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import {
    uploadFileToPresignedUrl,
    uploadImageFile,
} from 'src/app/backend-api-services/s3-file-uploader'
import { PlayReviewModalComponent } from 'src/app/dashboard/dashboard-shared/components/stats/play-review-modal/play-review-modal.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { GroupsService } from 'src/app/dashboard/dashboard-shared/services/groups/groups.service'
import { HavensService } from 'src/app/dashboard/dashboard-shared/services/havens/havens.service'
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service'
import { ImageMultiSizeService } from 'src/app/dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import { TimingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/timing-helper.service'
import { LogPlayService } from 'src/app/dashboard/dashboard-shared/services/loggingPlays/log-play.service'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'

import { ulid } from 'ulid'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-share-modal-advanced-log-play',
    templateUrl: './share-modal-advanced-log-play.component.html',
    styleUrls: ['./share-modal-advanced-log-play.component.scss'],
})
export class ShareModalAdvancedLogPlayComponent implements OnInit {
    @ViewChild('fileUploader', { static: false }) fileUploader
    selectedImage

    myFriends = new BehaviorSubject(null)
    myFriends$ = this.myFriends.asObservable()

    isSubmitting = false

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked && !this.isSubmitting) {
            this.submit()
        }
    }

    isEditing = false
    gameToEdit
    havenToEdit
    groupToEdit
    eventToEdit
    inputData

    @Input() set _inputData(inputData) {
        this.inputData = inputData
        this.isEditing = true

        this.gameToEdit = {
            title: inputData.gamePlayed,
            pk: inputData.gameId,
        }

        if (inputData.havenTitle != '' && inputData.havenTitle != null) {
            this.havenToEdit = {
                title: inputData.havenTitle,
                id: inputData.havenId,
            }
        }

        if (inputData.groupTitle != '' && inputData.groupTitle != null) {
            this.groupToEdit = {
                title: inputData.groupTitle,
            }
        }

        if (inputData.eventTitle != '' && inputData.eventTitle != null) {
            this.eventToEdit = {
                title: inputData.eventTitle,
            }
        }

        try {
            this.selectedImage = this.inputData.mainImageFiles[0]
        } catch (err) {
            this.selectedImage = ''
        }
    }

    @Output() blurContentEmitter = new EventEmitter()
    @Output() formChangeEmitter = new EventEmitter()
    @Output() closeEmitter = new EventEmitter()

    iWon = false
    games
    selectedTime = 'undefined'
    timePickerSubscription$: Subscription = null
    havens
    activity
    cols: any[]
    selectedColumns

    players = new BehaviorSubject([])
    players$ = this.players.asObservable()

    newPlayers = []
    playerRows = ['Current User']

    addTeam() {
        this.playerRows.push('random player')
    }

    removeTeam() {
        this.playerRows.pop()
    }

    durationOptions = [
        { name: '5 Minutes', value: 5 },
        { name: '10 Minutes', value: 10 },
        { name: '15 Minutes', value: 15 },
        { name: '30 Minutes', value: 30 },
        { name: '45 Minutes', value: 45 },
        { name: '60 Minutes', value: 60 },
        { name: '90 Minutes', value: 90 },
        { name: '120 Minutes', value: 120 },
        { name: '180 Minutes', value: 180 },
        { name: '240+ Minutes', value: 240 },
        { name: 'Unknown / Clear', value: undefined },
    ]

    allGames
    possibleExpansions = []
    user
    form: UntypedFormGroup

    constructor(
        private dialog: MatDialog,
        private backendAPIUsersService: BackendAPIUsersService,
        private formBuilder: UntypedFormBuilder,
        private backendApiContentService: BackendAPIContentService,
        private backendApiGamesService: BackendAPIGamesService,
        private logPlayService: LogPlayService,
        private screenSizeService: ScreenSizeService,
        private gamesService: GamesService,
        private havensService: HavensService,
        private arrayHelperService: ArrayHelperService,
        private timingHelperService: TimingHelperService,
        private groupsService: GroupsService,
        private backendApiUsersService: BackendAPIUsersService,
        private usersService: UsersService,
        private snackbar: MatSnackBar,
        private snackbarService: SnackbarService,
        private imageMultiSizeService: ImageMultiSizeService,
        private backendAPIContentService: BackendAPIContentService,
    ) {}

    coreGamesToSearch
    coreGames = new BehaviorSubject(null)
    coreGames$ = this.coreGames.asObservable()

    coreHavens = new BehaviorSubject(null)
    coreHavens$ = this.coreHavens.asObservable()

    coreGroups

    coreUsers = new BehaviorSubject(null)
    coreUsers$ = this.coreUsers.asObservable()

    myEvents = new BehaviorSubject(null)
    myEvents$ = this.myEvents.asObservable()

    myGroups = new BehaviorSubject(null)
    myGroups$ = this.myGroups.asObservable()

    coreUserForFilter

    isMobileScreen = false

    async ngOnInit() {
        await this.groupsService.myGroups$
            .pipe(untilDestroyed(this))
            .subscribe(async (myGroups) => {
                if (myGroups) {
                    this.myGroups = myGroups
                    this.coreGroups = myGroups
                }
            })

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.usersService.myFriends$.pipe(untilDestroyed(this)).subscribe((myFriends) => {
            this.myFriends.next(myFriends)
        })

        let today = new Date()
        let tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1)

        await this.groupsService.coreGroups$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreGroups) => {
                if (coreGroups) {
                    this.coreGroups.next(coreGroups)
                }
            })

        this.form = this.formBuilder.group({
            gamePlayed: [this.isEditing ? this.gameToEdit : null], // Raw Validators wont work since using autocomplete chiplist, so need to check manually
            havenPlayed: [this.isEditing ? this.havenToEdit : null],
            groupPlayed: [this.isEditing ? this.groupToEdit : null],
            eventPlayed: [this.isEditing ? this.eventToEdit : null],
            date: [this.isEditing ? this.inputData.date : new Date()],
            duration: [this.isEditing ? this.inputData.duration : undefined],
            publicNotes: [this.isEditing ? this.inputData.publicNotes : ''],
            notesToSelf: [this.isEditing ? this.inputData.notesToSelf : ''],
            nonGhUser1: [this.isEditing ? this.inputData.selectedNonGHUsers : undefined],
            iWon: [this.isEditing ? this.inputData.iWon : undefined],
            // not working -- worry about later
            // expansions: [undefined],
            taggableNonGhUsers: '',
            removeNonGHUser: [undefined],
        })

        if (this.isEditing) {
            this.form.get('gamePlayed').patchValue(this.inputData.gamePlayed)
            this.form.get('havenPlayed').patchValue(this.inputData.havenTitle)
            this.form.get('groupPlayed').patchValue(this.inputData.groupTitle)
        }

        this.backendApiUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe(async (user) => {
                this.user = user

                this.usersService.coreUsers$
                    .pipe(untilDestroyed(this))
                    .subscribe(async (coreUsersResult) => {
                        let coreUsersWithoutCurrentUser
                        if (coreUsersResult) {
                            coreUsersWithoutCurrentUser =
                                await this.arrayHelperService.removeFromArray(
                                    coreUsersResult,
                                    this.user,
                                    true,
                                    'username',
                                )
                        }
                        this.coreUsers.next(coreUsersWithoutCurrentUser)
                        this.coreUserForFilter = this.coreUsers.value

                        let myFriendsClean = this.myFriends.value.map((ob) => ob.sk)

                        let myFriendsExtraRemoved = myFriendsClean.map((str) =>
                            str.replace('#friendOf#', ''),
                        )

                        let friendsOnly = this.arrayHelperService.extractSubsetFromArray(
                            this.coreUserForFilter,
                            myFriendsExtraRemoved,
                            true,
                            false,
                            'username',
                        )

                        this.coreUsers.next(friendsOnly)

                        if (this.user.personalListOfNonGhUsers) {
                            this.players.next(JSON.parse(this.user.personalListOfNonGhUsers))
                        }
                    })
            })

        this.cols = [
            { field: 'player', header: 'Player' },
            { field: 'score', header: 'Score' },
            { field: 'winner', header: 'Winner' },
        ]

        let fetchedEvents = await this.backendApiContentService.listCalendarEventsById(
            this.user.username,
            249,
        )

        this.myEvents.next(fetchedEvents.calendarItems)

        await this.gamesService.coreGames$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreGames) => {
                if (coreGames) {
                    for (let g of coreGames) {
                    }
                    coreGames = coreGames.filter(function (obj) {
                        return obj.isExpansion !== true
                    })
                    this.coreGames.next(coreGames)
                    this.coreGamesToSearch = coreGames
                }
            })

        await this.havensService.coreHavens$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreHavens) => {
                if (coreHavens) {
                    this.coreHavens.next(coreHavens)
                }
            })

        this.onChanges()
    }

    onChanges(): void {
        this.form.valueChanges.subscribe((value) => {
            this.formChangeEmitter.emit(value)
        })
    }

    potentialGames = []

    selectGame(game) {
        this.form.get('gamePlayed').patchValue(game[0])

        if (game[0].expansions != '') {
            this.possibleExpansions = JSON.parse(game[0].expansions)
        } else {
            this.possibleExpansions = []
        }
    }

    selectHaven(haven) {
        this.form.get('havenPlayed').patchValue(haven[0])
    }

    selectedGroupId
    selectGroup(group) {
        if (this.selectedGroupId != group[0].pk) {
            this.selectedGroupId = group[0].pk
            this.form.get('groupPlayed').patchValue(group[0])
        }
    }
    selectedEventId
    selectEvent(event) {
        if (event && event[0] && event[0].extendedProps) {
            this.selectedEventId = event[0].extendedProps.ulid
            this.form.get('eventPlayed').patchValue(event[0].extendedProps.eventTitle)
        }
    }

    selectedUser2 = null
    selectUser2(user) {
        this.selectedUser2 = user
    }

    selectedUser3
    selectUser3(user) {
        this.selectedUser3 = user
    }
    selectedUser4
    selectUser4(user) {
        this.selectedUser4 = user
    }
    selectedUser5
    selectUser5(user) {
        this.selectedUser5 = user
    }
    selectedUser6
    selectUser6(user) {
        this.selectedUser6 = user
    }
    selectedUser7
    selectUser7(user) {
        this.selectedUser7 = user
    }
    selectedUser8
    selectUser8(user) {
        this.selectedUser8 = user
    }

    setExpansions(expansions) {}

    goodTimeSelected() {
        this.blurContentEmitter.emit(true)
        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            width: '550px',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: "How'd it go?",
                component: PlayReviewModalComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.blurContentEmitter.emit(false)
            })
    }

    addPlayers(event) {
        for (let player of event) {
            if (!this.players.value.includes(player) && !this.newPlayers.includes(player)) {
                this.newPlayers.push(player)
            }
        }
    }

    addWinners(event) {
        for (let player of event) {
            if (!this.players.value.includes(player) && !this.newPlayers.includes(player)) {
                this.newPlayers.push(player)
            }
        }
    }

    addOtherPlayers(event) {
        for (let player of event) {
            if (!this.players.value.includes(player) && !this.newPlayers.includes(player)) {
                this.newPlayers.push(player)
            }
        }
    }

    selectedNonGHUsers
    setNonGhUser1(event) {
        this.selectedNonGHUsers = event.value
    }

    async addNameToPersonalListOfNonGhUsers() {
        this.backendAPIUsersService.initCurrentUser()

        let listOfCustomUsers = []
        if (this.user.personalListOfNonGhUsers) {
            listOfCustomUsers = JSON.parse(this.user.personalListOfNonGhUsers)
        }

        let newUser = this.form.get('taggableNonGhUsers').value

        if (!listOfCustomUsers.includes(newUser)) {
            listOfCustomUsers.push(newUser)
        }

        let input = {
            pkName: 'pk',
            pkValue: this.user.username,
            skName: 'sk',
            skValue: `BD#${this.user.username}`,
            attributeName: 'personalListOfNonGhUsers',
            attributeValue: JSON.stringify(listOfCustomUsers),
        }

        await this.backendAPIUsersService.updateSingleAttributeForUser(input)

        let updatedPlayers = this.players.value

        updatedPlayers.push(newUser)

        this.players.next(updatedPlayers)

        this.form.get('taggableNonGhUsers').patchValue(undefined)
    }

    async removeNameFromPersonalListOfNonGhUsers() {
        this.backendAPIUsersService.initCurrentUser()

        let userToRemove = this.form.get('removeNonGHUser').value

        let listOfCustomUsers = []
        if (this.user.personalListOfNonGhUsers) {
            listOfCustomUsers = JSON.parse(this.user.personalListOfNonGhUsers)
        }

        if (listOfCustomUsers.length == 0) {
            listOfCustomUsers = null
        }

        let newListOfCustomUsers = this.arrayHelperService.removeFromArray(
            listOfCustomUsers,
            userToRemove,
            false,
        )

        let input = {
            pkName: 'pk',
            pkValue: this.user.username,
            skName: 'sk',
            skValue: `BD#${this.user.username}`,
            attributeName: 'personalListOfNonGhUsers',
            attributeValue: [JSON.stringify(newListOfCustomUsers)],
        }

        await this.backendAPIUsersService.updateSingleAttributeForUser(input)
        this.players.next(JSON.parse(this.user.personalListOfNonGhUsers))
    }

    selectionChanged(event) {
        // todo
    }

    updatedCollectionToEmit
    onSelectionsChanged(event) {
        this.updatedCollectionToEmit = event.selections
    }

    setSelectedImage(event) {
        if (event.rawImage == undefined) {
            this.selectedImage =
                window.location.origin + '/assets/images/defaults/profile-placeholder.png'
        } else {
            this.selectedImage = event
        }
    }

    messageStringForEmail

    async submit() {
        if (!this.form.get('gamePlayed').value) {
            this.snackbarService.openErrorSnackBar('Game is required!')
            return
        }

        // Prevent multiple submissions
        this.isSubmitting = true

        try {
            this.closeEmitter.emit()

            let files
            if (!this.isEditing) {
                files = this.fileUploader?.imageChanged
                    ? [this.fileUploader?.rawImage?.rawImage]
                    : null

                const imageName = this.selectedImage?.rawImage?.name
                if (imageName) {
                    const extension = imageName.split('.').pop()

                    if (this.selectedImage?.selectedImage && extension) {
                        const s3ObjectUrl = await uploadImageFile(
                            this.selectedImage.selectedImage,
                            extension,
                        )

                        const correctedUrl = s3ObjectUrl.replace(
                            'https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/',
                            'https://gh-img.s3.amazonaws.com/',
                        )
                    } else {
                        files = this.fileUploader.imageChanged
                            ? [this.fileUploader.rawImage.rawImage]
                            : this.inputData.mainImageFiles
                    }
                }
            }

            if (this.isEditing) {
                let pkToDelete = this.inputData.pk_UID
                let skToDelete = this.inputData.sk_LPID

                let deleteInput = {
                    title: skToDelete,
                    userID: pkToDelete,
                }

                await this.backendApiContentService.deleteLP(deleteInput)

                let LPInput = {
                    uniqueLpGroupingId: ulid().toLowerCase(),
                    zipCode: this.user.zipCode,
                    addedbyUID: this.user.username,
                    havenID: this.form.get('havenPlayed').value
                        ? this.form.get('havenPlayed').value.id
                        : this.user.defaultHaven,
                    groupId: this.selectedGroupId ? this.selectedGroupId : 'noGroupSelected',
                    eventId: this.selectedEventId ? this.selectedEventId : 'noEventSelected',
                    havenTitle:
                        this.form.get('havenPlayed').value &&
                        this.form.get('havenPlayed').value.title != null
                            ? this.form.get('havenPlayed').value.title
                            : undefined,
                    groupTitle:
                        this.form.get('groupPlayed').value &&
                        this.form.get('groupPlayed').value.title != null
                            ? this.form.get('groupPlayed').value.title
                            : undefined,
                    eventTitle:
                        this.form.get('eventPlayed').value &&
                        this.form.get('eventPlayed').value != null
                            ? this.form.get('eventPlayed').value
                            : undefined,

                    userTitle: this.user.title,
                    userId: this.user.username,
                    selectedNonGHUsers: this.selectedNonGHUsers,
                    gameId: this.form.get('gamePlayed').value.pk,
                    date: this.form.get('date').value,
                    duration: this.form.get('duration').value,
                    expansions: null,
                    gamePlayed: this.form.get('gamePlayed').value.title,
                    publicNotes: this.form.get('publicNotes').value,
                    notesToSelf: this.form.get('notesToSelf').value,
                    iWon: this.form.get('iWon').value,
                    mainImageFiles: [],
                    taggedUsers: undefined,

                    user2ZipCode: undefined,
                    user3ZipCode: undefined,
                    user4ZipCode: undefined,
                    user5ZipCode: undefined,
                    user6ZipCode: undefined,
                    user7ZipCode: undefined,
                    user8ZipCode: undefined,

                    userId2: undefined,
                    userId3: undefined,
                    userId4: undefined,
                    userId5: undefined,
                    userId6: undefined,
                    userId7: undefined,
                    userId8: undefined,

                    userTitle2: undefined,
                    userTitle3: undefined,
                    userTitle4: undefined,
                    userTitle5: undefined,
                    userTitle6: undefined,
                    userTitle7: undefined,
                    userTitle8: undefined,
                }

                let result2 = await this.backendApiContentService.createLPItem(LPInput, files)

                this.snackbarService.openSuccessSnackBar('Play Edited')

                this.logPlayService.updateShouldRefreshLoggedPlays()
                this.closeEmitter.emit()
            } else {
                let LPInput = {
                    uniqueLpGroupingId: ulid().toLowerCase(),

                    zipCode: this.user.zipCode,
                    addedbyUID: this.user.username,
                    havenID: this.form.get('havenPlayed').value
                        ? this.form.get('havenPlayed').value.id
                        : this.user.defaultHaven,
                    groupId: this.selectedGroupId ? this.selectedGroupId : undefined,
                    eventId: this.selectedEventId ? this.selectedEventId : undefined,

                    havenTitle:
                        this.form.get('havenPlayed').value &&
                        this.form.get('havenPlayed').value.title != null
                            ? this.form.get('havenPlayed').value.title
                            : undefined,
                    groupTitle:
                        this.form.get('groupPlayed').value &&
                        this.form.get('groupPlayed').value.title != null
                            ? this.form.get('groupPlayed').value.title
                            : undefined,
                    eventTitle:
                        this.form.get('eventPlayed').value &&
                        this.form.get('eventPlayed').value != null
                            ? this.form.get('eventPlayed').value
                            : undefined,

                    userTitle: this.user.title,
                    userId: this.user.username,
                    selectedNonGHUsers: this.selectedNonGHUsers,

                    user2ZipCode: this.selectedUser2 ? this.selectedUser2[0].zipCode : undefined,
                    user3ZipCode: this.selectedUser3 ? this.selectedUser3[0].zipCode : undefined,
                    user4ZipCode: this.selectedUser4 ? this.selectedUser4[0].zipCode : undefined,
                    user5ZipCode: this.selectedUser5 ? this.selectedUser5[0].zipCode : undefined,
                    user6ZipCode: this.selectedUser6 ? this.selectedUser6[0].zipCode : undefined,
                    user7ZipCode: this.selectedUser7 ? this.selectedUser7[0].zipCode : undefined,
                    user8ZipCode: this.selectedUser8 ? this.selectedUser8[0].zipCode : undefined,

                    userId2: this.selectedUser2 ? this.selectedUser2[0].username : undefined,
                    userId3: this.selectedUser3 ? this.selectedUser3[0].username : undefined,
                    userId4: this.selectedUser4 ? this.selectedUser4[0].username : undefined,
                    userId5: this.selectedUser5 ? this.selectedUser5[0].username : undefined,
                    userId6: this.selectedUser6 ? this.selectedUser6[0].username : undefined,
                    userId7: this.selectedUser7 ? this.selectedUser7[0].username : undefined,
                    userId8: this.selectedUser8 ? this.selectedUser8[0].username : undefined,

                    userTitle2: this.selectedUser2 ? this.selectedUser2[0].title : undefined,
                    userTitle3: this.selectedUser3 ? this.selectedUser3[0].title : undefined,
                    userTitle4: this.selectedUser4 ? this.selectedUser4[0].title : undefined,
                    userTitle5: this.selectedUser5 ? this.selectedUser5[0].title : undefined,
                    userTitle6: this.selectedUser6 ? this.selectedUser6[0].title : undefined,
                    userTitle7: this.selectedUser7 ? this.selectedUser7[0].title : undefined,
                    userTitle8: this.selectedUser8 ? this.selectedUser8[0].title : undefined,

                    gameId: this.form.get('gamePlayed').value.pk,

                    date: this.form.get('date').value,
                    duration: this.form.get('duration').value,
                    expansions: null,
                    gamePlayed: this.form.get('gamePlayed').value.title,
                    publicNotes: this.form.get('publicNotes').value,
                    notesToSelf: this.form.get('notesToSelf').value,
                    iWon: this.form.get('iWon').value,
                    mainImageFiles: [],
                    taggedUsers: undefined,
                }

                await this.backendApiContentService.createLPItem(LPInput, files)

                const coreGame = this.coreGamesToSearch.find(
                    (coreGame) => coreGame.title === LPInput.gamePlayed,
                )

                if (!coreGame.mainImageFiles) {
                    coreGame.mainImageFiles = []
                }

                const processSkValue = (sk) => {
                    return sk.replace(/#.*?#/g, '')
                }

                const allNotifications = [
                    ...this.myFriends.value.map((friend) => ({
                        ...friend,
                        userId: processSkValue(friend.sk),
                    })),
                    { pk: LPInput.addedbyUID, userId: LPInput.addedbyUID },
                ]

                allNotifications.forEach(async (item) => {
                    let NotificationInput = {
                        notificationType: 'loggedPlay',
                        addedByTitle: LPInput.userTitle,
                        addedByUID: LPInput.addedbyUID,
                        date: LPInput.date,
                        eventId: LPInput.eventId,
                        eventTitle: LPInput.eventTitle,
                        gameId: LPInput.gameId,
                        gameMainImageFiles: coreGame.mainImageFiles,
                        gameTitle: LPInput.gamePlayed,
                        groupId: LPInput.groupId,
                        groupTitle: LPInput.groupTitle,
                        havenId: LPInput.havenID,
                        havenTitle: LPInput.havenTitle,
                        userId: item.userId,
                        userImage: this.user.mainImageFiles,
                        eventStart: null,
                        eventEnd: null,
                    }

                    await this.backendApiContentService.createNotificationItems(NotificationInput)
                })

                let fetchedGame = await this.backendApiGamesService.GetGame(
                    this.form.get('gamePlayed').value.pk,
                )

                let currentTrendingFactor = parseInt(fetchedGame.trendingFactor, 10) || 0

                let newTrendingFactor = currentTrendingFactor + 10

                let paddedNewTrendingFactor = newTrendingFactor.toString().padStart(10, '0')

                if (LPInput.havenTitle) {
                    this.messageStringForEmail =
                        LPInput.userTitle +
                        ' played ' +
                        LPInput.gamePlayed +
                        ' at ' +
                        LPInput.havenTitle
                } else {
                    this.messageStringForEmail = LPInput.userTitle + ' played ' + LPInput.gamePlayed
                }

                let input = {
                    replyToEmail: 'loggedPlay@mygamehaven.com',
                    toEmail: 'support@mygamehaven.com',
                    subject: this.messageStringForEmail,
                    messageText: 'could have other things here',
                }
                this.backendApiContentService.createSESRequest(input)

                let updateInput = {
                    pkName: 'pk',
                    pkValue: this.form.get('gamePlayed').value.pk,
                    skName: 'sk',
                    skValue: 'BD#' + this.form.get('gamePlayed').value.pk,
                    attributeName: 'trendingFactor',
                    attributeValue: paddedNewTrendingFactor,
                }

                await this.backendApiGamesService.updateSingleAttributeForGame(updateInput)

                let updateInput2 = {
                    pkName: 'pk',
                    pkValue: this.form.get('gamePlayed').value.pk,
                    skName: 'sk',
                    skValue: 'BD#' + this.form.get('gamePlayed').value.pk,
                    attributeName: 'trendingFactorPK',
                    attributeValue: 'g',
                }

                await this.backendApiGamesService.updateSingleAttributeForGame(updateInput2)

                this.snackbarService.openSuccessSnackBar('Play Logged. Hope you had FUN!')
                this.logPlayService.updateShouldRefreshLoggedPlays()
            }
        } catch (error) {
            this.snackbarService.openErrorSnackBar('An error occurred during submission.')
        } finally {
            // Reset isSubmitting regardless of success or error
            this.isSubmitting = false
        }
    }

    compareFn(o1: any, o2: any): boolean {
        return o1 == o2
    }
}
