<div class="row m-0 parent-container">

    <!--* INFO CARD -->
    <div class="col-12 mt-3">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z6" [expanded]="infoPanelIsOpen" (opened)="infoPanelIsOpen = true"
                (closed)="infoPanelIsOpen = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-card-title class="gh-sm-title">INFO:</mat-card-title>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider *ngIf="infoPanelIsOpen" class="mb-2"></mat-divider>

                <div class="row pb-3">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div class="row">
                            <div class="col-12">
                                <mat-card-subtitle class="gh-md-subtitle">Description:</mat-card-subtitle>
                            </div>
                            <div class="col-12 description-container" *ngIf="event && event.description">
                                {{event.description}}</div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div class="row">
                            <div class="col-12">
                                <mat-card-subtitle class="gh-md-subtitle">Attending:</mat-card-subtitle>
                                <p *ngIf="attendingList"
                                    matTooltip="{{attendingList.str.length > 40 ? attendingList.str : ''}}"
                                    matTooltipPosition="above">
                                    {{truncate(attendingList.str, 40)}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                        <div class="row">
                            <div class="col-12">
                                <mat-card-subtitle class="gh-md-subtitle">Invited:</mat-card-subtitle>
                                <p *ngIf="invitedList"
                                    matTooltip="{{invitedList.str.length > 40 ? invitedList.str : ''}}"
                                    matTooltipPosition="above">
                                    {{truncate(invitedList.str, 40)}}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <!--* TIME PLANNING -->
    <div class="col-12 mt-3">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z6" [expanded]="timePlanningPanelIsOpen"
                (opened)="timePlanningPanelIsOpen = true" (closed)="timePlanningPanelIsOpen = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-card-title class="gh-sm-title">TIME PLANNING:</mat-card-title>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider *ngIf="timePlanningPanelIsOpen" class="mb-2"></mat-divider>
                <app-event-timing-manager [_sourceOfInput]="'app-gamenight'"></app-event-timing-manager>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <!--* HOST'S COLLECTION -->
    <!--  TODO: this should either not display or be different if current user is the host!!! -->
    <div class="col-12 mt-3">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z6" [expanded]="hostCollectionPanelIsOpen"
                (opened)="hostCollectionPanelIsOpen = true" (closed)="hostCollectionPanelIsOpen = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-card-title class="gh-sm-title">HOST'S COLLECTION:</mat-card-title>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider *ngIf="hostCollectionPanelIsOpen" class="mb-2"></mat-divider>
                <app-gamenight-host-collection *ngIf="hostCollectionInputData; else noHostCollection"
                    [_inputData]="hostCollectionInputData"
                    [_sourceOfInput]="'app-gamenight'"></app-gamenight-host-collection>
                <ng-template #noHostCollection>
                    <app-no-data [_message]="'No host collection available...'"></app-no-data>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <!--* GAMES BROUGHT BY GUESTS -->
    <div class="col-12 mt-3">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z6" [expanded]="guestGamesBroughtPanelIsOpen"
                (opened)="guestGamesBroughtPanelIsOpen = true" (closed)="guestGamesBroughtPanelIsOpen = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-card-title class="gh-sm-title">GAMES BROUGHT BY GUESTS:</mat-card-title>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider *ngIf="guestGamesBroughtPanelIsOpen" class="mb-2"></mat-divider>

                <div class="row">
                    <div class="col-6">
                        <!-- TODO: emitter to add changes -->
                        <app-gh-dropdown-select [_label]="'Add My Games'" [_tier0Items]="currentUserCollection"
                            [_isTiered]="false" [_isMultiSelect]="true" [_displayThumbImg]="true"
                            [_itemsAreObjects]="true" (selectionChangedEmitter)="addMyGames($event)"
                            [_sourceOfInput]="'app-gamenight'">
                        </app-gh-dropdown-select>
                    </div>

                    <div class="col-6">
                        <!-- TODO: emitter to add changes -->
                        <app-gh-dropdown-select [_label]="'Request Games From Guests'" [_tier0Items]="guestCollections"
                            [_isTiered]="true" [_isMultiSelect]="true" [_displayThumbImg]="true"
                            [_itemsAreObjects]="true" (selectionChangedEmitter)="requestGames($event)"
                            [_sourceOfInput]="'app-gamenight'">
                        </app-gh-dropdown-select>
                    </div>
                </div>

                <!-- ! move this to its own component or generisize the carousel in a template -->
                <div class="row">
                    <div class="col-12">
                        <div class="carousel-container">
                            <!-- <ngu-carousel *ngIf="(games$ | async)" class="carousel" #gamesCarousel [inputs]="carouselConfig" [dataSource]="games.value">
                                <div *nguCarouselDef="let item;" class="item">
                                    <div class="tile tile-with-image" [ngClass]="{ hasTwoInfoRows: item.requestors && item.contributor }">
                                        <div class="col-12 img-container" [ngClass]="{ requestedItem: !item.isCommitted }">
                                            <img src="{{item.game.thumbImgPath != '' ? item.game.thumbImgPath : defaultMainImage}}" alt="">
                                        </div>
                                        <div class="col-12 text-center mt-2">
                                            <mat-card-title class="gh-xsm-title">{{item.game.title}}</mat-card-title>
                                        </div>

                                        <div class="col-12 text-center" *ngIf="item.requestors && item.requestors.length != 0">
                                            <mat-card-subtitle class="gh-italic">Requested By:
                                            </mat-card-subtitle>

                                            <mat-card-subtitle class="gh-bold" style="margin-top: -1rem">
                                                {{getUserNamesString(item.requestors)}}
                                            </mat-card-subtitle>
                                        </div>

                                        <div class="col-12 text-center" *ngIf="item.isCommitted">
                                            <mat-card-subtitle class="gh-italic">Contributed By:
                                            </mat-card-subtitle>

                                            <mat-card-subtitle class="gh-bold" style="margin-top: -1rem">
                                                {{item.contributor.title}}
                                            </mat-card-subtitle>
                                        </div>

                                        <div class="item-action-button">
                                            <mat-icon class="gh-icon gh-delete-icon gh-icon-clickable" matTooltip="{{ item.isCommitted && item.contributor && item.contributor.pk == user.pk && item.requestors.length != 0 ? 'Remove Game Contribution' : 'Remove Game Request' }}" matTooltipPosition="above"
                                                (click)="removeGame(item)" *ngIf="item.isCommitted && item.contributor && item.contributor.pk == user.pk && item.requestors && item.requestors.length == 0 || !item.isCommitted && (item.requestors.length - 1 == 0) && item.requestors.includes(user)">
                                                close</mat-icon>
                                            <mat-icon class="gh-icon gh-add-icon gh-icon-clickable" matTooltip="I'll Bring It!" matTooltipPosition="above" (click)="fulfillRequest(item)" *ngIf="!item.isCommitted && item.requestors && !item.requestors.includes(user)">
                                                check
                                            </mat-icon>
                                            <mat-icon class="gh-icon gh-edit-icon gh-icon-clickable" matTooltip="Remove Contribution" matTooltipPosition="above" (click)="cancelRequestFulfillment(item)" *ngIf="item.isCommitted && item.contributor && item.contributor.pk == user.pk && item.requestors && item.requestors.length != 0">
                                                undo
                                            </mat-icon>
                                        </div>
                                    </div>
                                </div>

                                <button NguCarouselNext class="rightRs">
                                    <mat-icon>
                                        keyboard_arrow_right
                                    </mat-icon>
                                </button>
                                <button NguCarouselPrev class="leftRs">
                                    <mat-icon>
                                        keyboard_arrow_left
                                    </mat-icon>
                                </button>
                                <ul class="myPoint" NguCarouselPoint>
                                    <li *ngFor="let j of gamesCarousel.pointNumbers; let jnd = index" [class.active]="j==gamesCarousel.activePoint" (click)="gamesCarousel.moveTo(jnd)">
                                    </li>
                                </ul>
                            </ngu-carousel> -->
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <!-- * FOOD -->
    <div class="col-12 mt-3">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z6" [expanded]="foodPanelIsOpen" (opened)="foodPanelIsOpen = true"
                (closed)="foodPanelIsOpen = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-card-title class="gh-sm-title">FOOD:</mat-card-title>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider *ngIf="foodPanelIsOpen" class="mb-2"></mat-divider>

                <div class="row">
                    <div class="col-6">
                        <!-- TODO: emitter to add changes -->
                        <app-gh-dropdown-select [_label]="'Add My Food'" [_tier0Items]="commonFoodContributions"
                            [_isTiered]="false" [_isMultiSelect]="true" [_displayThumbImg]="false"
                            [_itemsAreObjects]="false" [_sourceOfInput]="'app-gamenight'">
                        </app-gh-dropdown-select>
                    </div>

                    <div class="col-6">
                        <!-- TODO: emitter to add changes -->
                        <app-gh-dropdown-select [_label]="'Request Food From Guests'"
                            [_tier0Items]="commonFoodContributions" [_isTiered]="false" [_isMultiSelect]="true"
                            [_displayThumbImg]="false" [_itemsAreObjects]="false" [_sourceOfInput]="'app-gamenight'">
                        </app-gh-dropdown-select>
                    </div>
                </div>

                <!-- ! move this to its own component or generisize the carousel in a template -->
                <div class="row">
                    <div class="carousel-container mt-5">
                        <!-- <ngu-carousel *ngIf="(food$ | async)" class="carousel" #foodCarousel [inputs]="carouselConfig" [dataSource]="food.value">
                            <div *nguCarouselDef="let item;" class="item">
                                <div class="tile tile-with-image" [ngClass]="{ hasTwoInfoRows: item.requestor && item.contributor }">
                                    <div class="col-12 img-container mb-1" [ngClass]="{ requestedItem: !item.isCommitted }">
                                        <img src="{{item.food.selectedImage ? item.food.selectedImage : '../../../../../../assets/images/coolStuff/pizza-meeple.png' }}" alt="">
                                    </div>
                                    <div class="col-12 text-center">
                                        <mat-card-title class="gh-xsm-title">{{item.food.title}}</mat-card-title>
                                    </div>
                                    <div class="col-12 text-center" *ngIf="item.requestor">
                                        <mat-card-subtitle class="gh-italic">Requested By:
                                        </mat-card-subtitle>

                                        <mat-card-subtitle class="gh-bold" style="margin-top: -1rem">
                                            {{item.requestor.title}}
                                        </mat-card-subtitle>
                                    </div>

                                    <div class="col-12 text-center" *ngIf="item.isCommitted">
                                        <mat-card-subtitle class="gh-italic">Contributed By:
                                        </mat-card-subtitle>

                                        <mat-card-subtitle class="gh-bold" style="margin-top: -1rem">
                                            {{item.contributor.title}}
                                        </mat-card-subtitle>
                                    </div>

                                    <div class="item-action-button">
                                        <mat-icon class="gh-icon gh-delete-icon gh-icon-clickable" matTooltip="{{ item.isCommitted && item.contributor && item.contributor.pk == user.pk && !item.requestor ? 'Remove Food Contribution' : 'Remove Food Request' }}" matTooltipPosition="above" (click)="removeFood(item)"
                                            *ngIf="item.isCommitted && item.contributor && item.contributor.pk == user.pk && !item.requestor || !item.isCommitted && item.requestor && item.requestor.id == user.pk">
                                            close</mat-icon>
                                        <mat-icon class="gh-icon gh-add-icon gh-icon-clickable" matTooltip="I'll Bring It!" matTooltipPosition="above" (click)="fulfillRequest(item)" *ngIf="!item.isCommitted && item.requestor && item.requestor.id != user.pk">
                                            check
                                        </mat-icon>
                                        <mat-icon class="gh-icon gh-edit-icon gh-icon-clickable" matTooltip="Remove Contribution" matTooltipPosition="above" (click)="cancelRequestFulfillment(item)" *ngIf="item.isCommitted && item.contributor && item.contributor.pk == user.pk && item.requestor">
                                            undo
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>

                            <button NguCarouselNext class="rightRs">
                                <mat-icon>
                                    keyboard_arrow_right
                                </mat-icon>
                            </button>
                            <button NguCarouselPrev class="leftRs">
                                <mat-icon>
                                    keyboard_arrow_left
                                </mat-icon>
                            </button>
                            <ul class="myPoint" NguCarouselPoint>
                                <li *ngFor="let j of foodCarousel.pointNumbers; let jnd = index" [class.active]="j==foodCarousel.activePoint" (click)="foodCarousel.moveTo(jnd)">
                                </li>
                            </ul>
                        </ngu-carousel> -->
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>



    <!--* BEVERAGES -->
    <div class="col-12 mt-3">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z6" [expanded]="beveragesPanelIsOpen"
                (opened)="beveragesPanelIsOpen = true" (closed)="beveragesPanelIsOpen = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-card-title class="gh-sm-title">BEVERAGES:</mat-card-title>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider *ngIf="beveragesPanelIsOpen" class="mb-2"></mat-divider>

                <div class="row">
                    <div class="col-6">
                        <!-- TODO: emitter to add changes -->
                        <app-gh-dropdown-select [_label]="'Add My Beverages'"
                            [_tier0Items]="commonBeverageContributions" [_isTiered]="false" [_isMultiSelect]="true"
                            [_displayThumbImg]="false" [_itemsAreObjects]="false" [_sourceOfInput]="'app-gamenight'">
                        </app-gh-dropdown-select>
                    </div>

                    <div class="col-6">
                        <!-- TODO: emitter to add changes -->
                        <app-gh-dropdown-select [_label]="'Request Beverages From Guests'"
                            [_tier0Items]="commonBeverageContributions" [_isTiered]="false" [_isMultiSelect]="true"
                            [_displayThumbImg]="false" [_itemsAreObjects]="false" [_sourceOfInput]="'app-gamenight'">
                        </app-gh-dropdown-select>
                    </div>
                </div>


                <!-- ! move this to its own component or generisize the carousel in a template -->
                <div class="row">
                    <div class="carousel-container mt-5">
                        <!-- <ngu-carousel *ngIf="(beverages$ | async)" class="carousel" #beveragesCarousel [inputs]="carouselConfig" [dataSource]="beverages.value">
                            <div *nguCarouselDef="let item;" class="item">
                                <div class="tile tile-with-image" [ngClass]="{ hasTwoInfoRows: item.requestor && item.contributor }">
                                    <div class="col-12 img-container mb-1" [ngClass]="{ requestedItem: !item.isCommitted }">
                                        <img src="{{item.beverage.selectedImage ? item.beverage.selectedImage : '../../../../../../assets/images/coolStuff/beer-meeple.png' }}" alt="">
                                    </div>
                                    <div class="col-12 text-center">
                                        <mat-card-title class="gh-xsm-title">{{item.beverage.title}}</mat-card-title>
                                    </div>
                                    <div class="col-12 text-center" *ngIf="item.requestor">
                                        <mat-card-subtitle class="gh-italic">Requested By:
                                        </mat-card-subtitle>

                                        <mat-card-subtitle class="gh-bold" style="margin-top: -1rem">
                                            {{item.requestor.title}}
                                        </mat-card-subtitle>
                                    </div>

                                    <div class="col-12 text-center" *ngIf="item.isCommitted">
                                        <mat-card-subtitle class="gh-italic">Contributed By:
                                        </mat-card-subtitle>

                                        <mat-card-subtitle class="gh-bold" style="margin-top: -1rem">
                                            {{item.contributor.title}}
                                        </mat-card-subtitle>
                                    </div>

                                    <div class="item-action-button">
                                        <mat-icon class="gh-icon gh-delete-icon gh-icon-clickable" matTooltip="{{ item.isCommitted && item.contributor && item.contributor.pk == user.pk && !item.requestor ? 'Remove Beverage Contribution' : 'Remove Beverage Request' }}" matTooltipPosition="above"
                                            (click)="removeBeverage(item)" *ngIf="item.isCommitted && item.contributor && item.contributor.pk == user.pk && !item.requestor || !item.isCommitted && item.requestor && item.requestor.id == user.pk">
                                            close</mat-icon>
                                        <mat-icon class="gh-icon gh-add-icon gh-icon-clickable" matTooltip="I'll Bring It!" matTooltipPosition="above" (click)="fulfillRequest(item)" *ngIf="!item.isCommitted && item.requestor && item.requestor.id != user.pk">
                                            check
                                        </mat-icon>
                                        <mat-icon class="gh-icon gh-edit-icon gh-icon-clickable" matTooltip="Remove Contribution" matTooltipPosition="above" (click)="cancelRequestFulfillment(item)" *ngIf="item.isCommitted && item.contributor && item.contributor.pk == user.pk && item.requestor">
                                            undo
                                        </mat-icon>
                                    </div>
                                </div>
                            </div>

                            <button NguCarouselNext class="rightRs">
                                <mat-icon>
                                    keyboard_arrow_right
                                </mat-icon>
                            </button>
                            <button NguCarouselPrev class="leftRs">
                                <mat-icon>
                                    keyboard_arrow_left
                                </mat-icon>
                            </button>
                            <ul class="myPoint" NguCarouselPoint>
                                <li *ngFor="let j of beveragesCarousel.pointNumbers; let jnd = index" [class.active]="j==beveragesCarousel.activePoint" (click)="beveragesCarousel.moveTo(jnd)">
                                </li>
                            </ul>
                        </ngu-carousel> -->
                    </div>
                </div>
            </mat-expansion-panel>

        </mat-accordion>
    </div>




    <!--* FORUM PLACEHOLDER -->
    <div class="col-12 mt-3">
        <mat-accordion>
            <mat-expansion-panel class="mat-elevation-z6" [expanded]="forumPanelIsOpen"
                (opened)="forumPanelIsOpen = true" (closed)="forumPanelIsOpen = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-card-title class="gh-sm-title">FORUM:</mat-card-title>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider *ngIf="forumPanelIsOpen" class="mb-2"></mat-divider>

                <div class="row">
                    <div class="col-12">
                        <img class="user-img" style="width: 100%;"
                            src="../../../../../../assets/images/general/forumPlaceholder.jpg" alt="">
                    </div>
                </div>

            </mat-expansion-panel>

        </mat-accordion>
    </div>

</div>