import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { iImageOrVideoGalleryInputItem } from '../../../../../../../../common/interfaces/ContentInterfaces'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { VideoEmbeddingHelperService } from '../../../services/helpers/video-embedding-helper.service'
import { FullSizedImageViewComponent } from '../full-sized-image-view/full-sized-image-view.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { ArrayHelperService } from '../../../services/helpers/array-helper.service'
import { TimingHelperService } from '../../../services/helpers/timing-helper.service'
import { DomSanitizer } from '@angular/platform-browser';
import { SortByOptions } from '../../../enums/sort-by-enums'
import { SnackbarService } from '../../../services/user-action-feedback/snackbar.service'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-image-or-video-gallery-grid',
    templateUrl: './image-or-video-gallery-grid.component.html',
    styleUrls: ['./image-or-video-gallery-grid.component.scss'],
})
export class ImageOrVideoGalleryGridComponent implements OnInit {
    user
    isGHAdmin = false

    @Input() _sourceOfInput: string

    items
    @Input() set _items(items: [iImageOrVideoGalleryInputItem]) {
        this.items = items
    }

    type
    @Input() set _type(type) {
        this.type = type
    }


    origin
    @Input() set _origin(origin) {
        this.origin = origin
    }

    sortBy
    sortByString
    @Input() set _sortBy(sortBy: SortByOptions) {
        this.sortBy = sortBy
        this.sortByString = SortByOptions[sortBy]
    }


    @Output() onDeleteEmitter = new EventEmitter(null)

    shouldCheckChanges = true

    setItems(items: [iImageOrVideoGalleryInputItem]) {
        this.items = items
    }

    constructor(
        private videoEmbeddingHelperService: VideoEmbeddingHelperService,
        private backendAPIUsersService: BackendAPIUsersService,
        private dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private snackbar: MatSnackBar,
        private screenSizeService: ScreenSizeService,
        private changeDetectorRef: ChangeDetectorRef,
        private backendAPIContentService: BackendAPIContentService,
        private arrayHelperService: ArrayHelperService,
        private snackbarService: SnackbarService,

        private timingHelperService: TimingHelperService
    ) {
        changeDetectorRef.detach()

        setInterval(() => {
            if (this.shouldCheckChanges) {
                this.shouldCheckChanges = false
                this.changeDetectorRef.detectChanges()
            }
        }, 500)
    }

    isMobileScreen = false

    async ngOnInit(): Promise<void> {

        this.sortItems()

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        await this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                if (user) {
                    this.user = user
                    this.isGHAdmin = this.user.userLevel >= 6
                }
            })

        if (this.type == 'video') {
            for (let item of this.items) {
                if (!item.videoPath && item.url) {
                    item.videoPath = item.url
                }
            }
        }

    }

    sortItems() {
        switch (this.sortByString) {
            case 'date':
                this.items.sort((a, b) => a.published.localeCompare(b.published)).reverse()
                break;

            default:
                break;
        }
    }


    getTrustedUrl(myUrl: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(myUrl);
    }


    getSrcdoc(unsafeURL) {
        return this.videoEmbeddingHelperService.getSrcdoc(unsafeURL)
    }

    getVideoPath(unsafeURL) {
        return this.videoEmbeddingHelperService.getVideoPath(unsafeURL)
    }

    getHasTitle(item) {
        return item.title && item.title != null && item.title != ''
    }

    getHasSubtitle(item) {
        return item.subtitle && item.subtitle != null && item.subtitle != ''
    }

    getHasDetails(item) {
        return item.details && item.details.length > 0
    }

    async deleteImageOrVideo(event) {
        this.onDeleteEmitter.emit(event)
    }







    async removeImageOrVideoTag(event) { //! wont work for video


        if (this.origin == 'profile' && this.type == 'image') {
            alert('This should never happen, please let us know you saw this')
        }

        if (this.origin == 'havens' && this.type == 'image') {
            let input = {
                pkName: 'pk',
                pkValue: 'image',
                skName: 'sk',
                skValue: event.sk,
                attributeName: 'contentByHavenByLikesPK',
                attributeValue: 'removedByTaggedHaven'
            }
            this.backendAPIContentService.updateSingleAttributeForContentItem(input)

            let input2 = {
                pkName: 'pk',
                pkValue: 'image',
                skName: 'sk',
                skValue: event.sk,
                attributeName: 'havenTitle',
                attributeValue: null
            }
            this.backendAPIContentService.updateSingleAttributeForContentItem(input2)
        }

        if (this.origin == 'havens' && this.type == 'video') {
            let input = {
                pkName: 'pk',
                pkValue: 'video',
                skName: 'sk',
                skValue: event.sk,
                attributeName: 'contentByHavenByLikesPK',
                attributeValue: 'removedByTaggedHaven'
            }
            this.backendAPIContentService.updateSingleAttributeForContentItem(input)
        }


        if (this.origin == 'groups' && this.type == 'video') {
            let input = {
                pkName: 'pk',
                pkValue: 'video',
                skName: 'sk',
                skValue: event.sk,
                attributeName: 'contentByGroupByLikesPK',
                attributeValue: 'removedByTaggedGroup'
            }
            this.backendAPIContentService.updateSingleAttributeForContentItem(input)
        }

        if (this.origin == 'groups' && this.type == 'image') {
            let input = {
                pkName: 'pk',
                pkValue: 'image',
                skName: 'sk',
                skValue: event.sk,
                attributeName: 'contentByGroupByLikesPK',
                attributeValue: 'removedByTaggedGroup'
            }
            this.backendAPIContentService.updateSingleAttributeForContentItem(input)

            let input2 = {
                pkName: 'pk',
                pkValue: 'image',
                skName: 'sk',
                skValue: event.sk,
                attributeName: 'groupTitle',
                attributeValue: null
            }
            this.backendAPIContentService.updateSingleAttributeForContentItem(input2)
        }




        if (this.origin == 'games' && this.type == 'video') {

            let input = {
                pkName: 'pk',
                pkValue: 'video',
                skName: 'sk',
                skValue: '#' + event.videoType + '#' + event.id,
                attributeName: 'contentByGameByLikesPK',
                attributeValue: 'removedByTaggedGame'
            }

            this.backendAPIContentService.updateSingleAttributeForContentItem(input)

            let input2 = {
                pkName: 'pk',
                pkValue: 'video',
                skName: 'sk',
                skValue: '#' + event.videoType + '#' + event.id,
                attributeName: 'gameTitle',
                attributeValue: null
            }
            this.backendAPIContentService.updateSingleAttributeForContentItem(input2)
        }

        if (this.origin == 'games' && this.type == 'image') {
            let input = {
                pkName: 'pk',
                pkValue: 'image',
                skName: 'sk',
                skValue: event.sk,
                attributeName: 'contentByGameByLikesPK',
                attributeValue: 'removedByTaggedGame'
            }
            this.backendAPIContentService.updateSingleAttributeForContentItem(input)

            let input2 = {
                pkName: 'pk',
                pkValue: 'image',
                skName: 'sk',
                skValue: event.sk,
                attributeName: 'gameTitle',
                attributeValue: null
            }
            this.backendAPIContentService.updateSingleAttributeForContentItem(input2)
        }

        this.items = this.arrayHelperService.removeFromArray(this.items, event, true, 'id')
        this.timingHelperService.delay(1000).then(() => {
            this.shouldCheckChanges = true
        })
        this.snackbarService.openErrorSnackBar('Tag Removed')
    }

    showFullSize(item) {
        // todo: deal with video? (NO: Dave says this is way down the line)
        // todo: deal with number of likes both passed in and received!!!
        //! Need to figure out how to force this generic input to adhere to iFullSizeImageDialogInput !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        let bigItem = item.imgPath.replace('_S.jpg', '_XL.jpg')
        let bigArray = [bigItem, bigItem, bigItem, bigItem]
        let bigArrayClean = {
            imgPath: bigArray,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: item.title,
                component: FullSizedImageViewComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
                hasToolbar: { value: item.title && item.title != null && item.title != '' },
                inputData: {
                    itemId: item.id,
                    image: bigArrayClean.imgPath[2],
                    numLikes: 22,
                },
            },
            height: '70%',
            width: '90%',
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                // TODO: if like is clicked need to do stuff here
            })
    }
}
