import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-players-needed-bar-old[_sourceOfInput]',
  templateUrl: './players-needed-bar-old.component.html',
  styleUrls: ['./players-needed-bar-old.component.scss']
})
export class PlayersNeededBarOldComponent implements OnInit {

  @Input() _sourceOfInput: string

  numbers = []

  @Input() temp

  isInteractive: boolean = false
  @Input() set _isInteractive(isInteractive: boolean) {
    if (this.isInteractive && !isInteractive) { // reset to original value
      let desiredIndicesOriginalStringified = JSON.stringify(this.desiredIndicesOriginal)
      this.desiredIndices = JSON.parse(desiredIndicesOriginalStringified) // shallow copy
    }
    this.isInteractive = isInteractive
  }

  currentPlayerCount
  @Input() set _currentPlayerCount(currentPlayerCount: number) {
    this.currentPlayerCount = currentPlayerCount
  }

  gameMin
  @Input() set _gameMin(gameMin: number) {
    this.gameMin = gameMin
  }

  gameMax
  @Input() set _gameMax(gameMax: number) {
    this.gameMax = gameMax > 8 ? 8 : gameMax
  }

  communityMin
  @Input() set _communityMin(communityMin) {
    this.communityMin = communityMin
  }

  communityMax
  @Input() set _communityMax(communityMax) {
    this.communityMax = communityMax
  }

  accept1 = false
  @Input() set _accept1(accept1: boolean) {
    this.accept1 = accept1
  }

  desiredIndices
  desiredIndicesOriginal // dont modify this!!! it is used to reset when isInteractive changes from true to false
  @Input() set _desiredIndices(desiredIndices: number[]) {
    if (!this.desiredIndices) { // needed so not to switch back to default when making changes in interactive mode
      this.desiredIndices = desiredIndices

      let desiredIndicesStringified = JSON.stringify(desiredIndices)
      this.desiredIndicesOriginal = JSON.parse(desiredIndicesStringified) // shallow copy
    }
  }

  customLowerBound
  @Input() set _customLowerBound(lowerBound: number) {
    this.customLowerBound = lowerBound
    if (this.customLowerBound != null && this.customLowerBound != '' && this.customLowerBound > this.gameMin) {
      this.adjustDesiredIndicesForCustomBounds()
    }
  }

  customUpperBound
  @Input() set _customUpperBound(upperBound: number) {
    this.customUpperBound = upperBound
    this.setNumbers()
  }

  @Output() desiredIndicesChangedEmitter = new EventEmitter()


  adjustDesiredIndicesForCustomBounds() {
    // might not need this...

    // var i = this.desiredIndices.length
    // while (i--) {
    // // ...
    //   if (this.desiredIndices[i] > this.customLowerBound || this.desiredIndices[i] > this.customUpperBound) {
    //     this.desiredIndices.splice(i, 1)
    //   }
    // }
  }

  constructor() { }

  ngOnInit(): void {
      this.setNumbers()
  }

  setNumbers() {
    this.numbers = []
    let max = this.customUpperBound ? this.customUpperBound : this.gameMax
    for (var i = 1; i <= max; i++) {
      this.numbers.push(i)
      if (i == 8) {
        break
      }
    }
  }

  getColor(index) {
    if (this.customLowerBound != null && this.customUpperBound != null) {
      if (this.desiredIndices.includes(index + 1)) {
        return this.getIsOutOfSpecifiedBounds(index) ? '#BDBDBD' : '#116466' // gray or gh-color-primary
      }
      else {
        return this.getIsOutOfSpecifiedBounds(index) ? '#BDBDBD' : '#ffcb9a' // gray or gh-color-4
      }
    }
    else {
      if (this.desiredIndices.includes(index + 1)) {
        return '#116466' // gh-color-primary
      }
      else {
        return '#ffcb9a' // gh-color-4
      }
    }
  }

  getIsOutOfSpecifiedBounds(index) {
    return this.customLowerBound && index + 1 < this.customLowerBound && this.customLowerBound > this.gameMin || this.customUpperBound && index + 1 > this.customUpperBound && this.customUpperBound < this.gameMax
  }

  getIsInteractive(index) {
    return this.isInteractive && !this.getIsOutOfSpecifiedBounds(index)
  }

  onBlockClicked(index) {
    if (this.isInteractive && !this.getIsOutOfSpecifiedBounds(index)) {
      if (this.desiredIndices.includes(index + 1) && (this.desiredIndices.length != 1 || this.accept1)) {
        this.desiredIndices.splice(this.desiredIndices.indexOf(index + 1), 1)
      }
      else {
        this.desiredIndices.push(index + 1)
      }
      this.desiredIndicesChangedEmitter.emit(this.desiredIndices)
    }
  }


}
