import { ComponentFactoryResolver, Output, ViewChild, ViewContainerRef } from '@angular/core'
import { Component, OnInit, Inject, Input, EventEmitter } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-confirm-action[_sourceOfInput]',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit {

  @Input() _sourceOfInput: string

  @ViewChild('viewContainer', { read: ViewContainerRef }) viewContainer

  password
  message: string
  submessage: string
  isDeletion
  hasPassword = false
  contentComponent

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
    this.hasPassword = inputData.hasPassword

    if (inputData.contentComponent) {
      if (this.viewContainer) {
        this.viewContainer.clear()
      }
      this.viewContainerSet = false
    }
  }

  @Output() closeEmitter = new EventEmitter()

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      if (this.hasPassword) {
        this.submit(this.password)
      }
      else {
        this.submit()
      }
    }
  }

  constructor(
    private snackbar: MatSnackBar,
    private resolver: ComponentFactoryResolver
  ) { }

  ngOnInit() {
    this.message = this.inputData.message
    this.submessage = this.inputData.submessage
    this.isDeletion = this.inputData.isDeletion
  }

  viewContainerSet = false
  ngAfterContentChecked() {
    if (!this.viewContainerSet) {
      if (this.viewContainer) {
        this.viewContainerSet = true
        this.contentComponent = this.viewContainer.createComponent(this.resolver.resolveComponentFactory(this.inputData.contentComponent))

        if (this.inputData.contentComponentInputData) {
          this.contentComponent.instance._inputData = this.inputData.contentComponentInputData
        }

        if (this.contentComponent.instance.closeEmitter) {
          this.closeEmitter = this.contentComponent.instance.closeEmitter.subscribe(data => {
            this.submit(data)
          })
        }
      }
    }
  }

  async submit(data?) {
    this.closeEmitter.emit(data ? data : 'confirm')
  }

}
