<div class="parent-container row">

    <div class="col-12">

        <div class="row">

            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Game Played
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.gamePlayed}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            User
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.userTitle}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>




            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Date
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.date | date: 'MM/dd/yyyy'}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Duration
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.duration}} Minutes
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="col-12 mt-3">

        <div class="row">

            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Haven
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.havenTitle}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Group
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.groupTitle}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Event
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.eventTitle}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            I Won?
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.iWon | falseToNope}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div class="col-12 mt-3">
        <div class="row">
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Tagged Users
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{taggedUsersString}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Public Notes
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.publicNotes}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Notes to self
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            {{inputData.notesToSelf}}
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>

        </div>
    </div>


    <div *ngIf="inputData.mainImageFiles && inputData.mainImageFiles[0]" class="col-12 mt-3">
        <div class="row">
            <div class="col-12">
                <mat-card-title class="gh-sm-title">
                    Images
                </mat-card-title>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- <img [src]="inputData.mainImageFiles[0]" id="mainImage" alt="thumbnail"> -->
                <!-- <img [src]="inputData.mainImageFiles && inputData.mainImageFiles[0] ? inputData.mainImageFiles[0]: null"
                    id="mainImage" alt="thumbnail"> -->

                <app-gh-expandable-image [_img]="inputData.mainImageFiles" [_title]=" 'Test Title' " [_caption]=" 'Test caption' "></app-gh-expandable-image>
            </div>
        </div>
    </div>




</div>