import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { ONBOARDING_GAME_SURVEY_DATA } from '../../constants/mock-data'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { iCreateOrUpdateHavenInput } from '../../../../../../common/interfaces/HavenInterfaces'
import { BackendAPIHavensService } from 'src/app/backend-api-services/backend-api-havens.service'
import { adoptionOriginEnums } from 'src/app/shared/enums/adoption-enums'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { CoreUser } from './newUserInterfaces'
import { UsersService } from '../../dashboard-shared/services/users/users.service'
import { SnackbarService } from '../../dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatDialogRef } from '@angular/material/dialog'
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})

export class NewUserComponent implements OnInit {

  useTabGroup = false

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  mapShouldInit = false

  selectedTabIndex = 0 // NOTE: important to default this value!!!
  user
  maxAreasOfInterest = 1
  areasOfInterest = null
  gameSurveyData = ONBOARDING_GAME_SURVEY_DATA

  @ViewChild('stepper', { static: false }) stepper

  pageTitle = 'Welcome to Game Haven'

  hasCloseButton = false
  usernameAndAOIForm: UntypedFormGroup
  // havenForm: UntypedFormGroup 

  constructor(
    private dialogRef: MatDialogRef<NewUserComponent>,
    private formBuilder: UntypedFormBuilder,
    private backendApiContentService: BackendAPIContentService,
    private backendApiUsersService: BackendAPIUsersService,
    private backendAPIHavensService: BackendAPIHavensService,
    private usersService: UsersService,
    private snackbarService: SnackbarService,
    private screenSizeService: ScreenSizeService
  ) { }


  coreUsersToSearch: CoreUser[] = [];



  makeTitlesLowercase(coreUsers: CoreUser[]): CoreUser[] {
    return coreUsers.map(coreUser => {
      if (coreUser.title && typeof coreUser.title === 'string') {
        return { ...coreUser, title: coreUser.title.toLowerCase() };
      }
      return coreUser;
    });
  }

  isMobileScreen = false
  ngOnInit() {

    this.usersService.coreUsers$
      .pipe(untilDestroyed(this))
      .subscribe((coreUsersResult: CoreUser[]) => {
        // Make titles lowercase
        this.coreUsersToSearch = this.makeTitlesLowercase(coreUsersResult);
      });


    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })

    this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })

    this.usernameAndAOIForm = this.formBuilder.group({
      zipCode: [this.user.zipCode, [Validators.minLength(5), Validators.maxLength(5)]],
      uniqueUserName: new FormControl('', [
        Validators.required,
        Validators.maxLength(25)
      ]),
      howTheyFoundOutAboutUs: ''


    })
  }

  zipCode
  selectZipCode(zipCode) {
    this.zipCode = zipCode
  }


  currentStatusTypes = [
    adoptionOriginEnums.Friend,
    adoptionOriginEnums.Family,
    adoptionOriginEnums.Ad,
    adoptionOriginEnums.Store,
    adoptionOriginEnums.Event,
    adoptionOriginEnums.Other,
  ]

  viewIsLoaded = false
  ngAfterViewChecked() {
    if (!this.viewIsLoaded) {
      if (this.stepper) {
        this.viewIsLoaded = true
      }
    }
  }






  close() {
    this.dialogRef.close()
  }

  profileImage
  setProfilePicture(event) {
    this.profileImage = event
  }

  bio
  setBio(event) {
    this.bio = event.bio
  }

  setLocationSelected(event) {
    this.usernameAndAOIForm.get('zipCode').patchValue(event.primaryAOI.zipCode)
    this.usernameAndAOIForm.get('state').patchValue(event.primaryAOI.state)
  }


  newHavenInputData
  havenTitleIsAvailible = false
  catchHavenFormChange(event) {
    this.newHavenInputData = event
  }

  catchIsValideHavenNameEmitter(event) {
    this.havenTitleIsAvailible = event
  }


  async submit() {

    // USER SUBMISSION ***************************************************************
    if (this.selectedTabIndex == 0) {

      if (this.usernameAndAOIForm.get('uniqueUserName').value && this.usernameAvailable && this.usernameAndAOIForm.get('uniqueUserName')) {

        let currentUser = await this.backendApiUsersService.getMyProfile()

        currentUser.title = this.usernameAndAOIForm.get('uniqueUserName').value
        currentUser.gsi2sk = this.usernameAndAOIForm.get('uniqueUserName').value

        currentUser.corePK = 'u'
        currentUser.coreSK = '#' + this.usernameAndAOIForm.get('uniqueUserName').value

        currentUser.userIsHaven = false
        currentUser.isNewUserFlag = false

        currentUser.zipCode = this.zipCode

        let updateResult = await this.backendApiUsersService.updateUser(currentUser, this.profileImage ? this.profileImage.rawImage : null)

        let acquisitionInput = {
          replyToEmail: this.user.email,
          toEmail: 'support@mygamehaven.com',
          messageText: 'Source: ' + this.usernameAndAOIForm.get('howTheyFoundOutAboutUs').value,
          subject: 'User Title: ' + this.usernameAndAOIForm.get('uniqueUserName').value
        }

        this.backendApiContentService.createSESRequest(acquisitionInput)
        this.dialogRef.close()
      }
      else {
        this.snackbarService.openErrorSnackBar('Unique username is required and cannot be more than 25 characters.')
      }
    }

    // FUTURE: Make a admmin panel to deal with new Users
    // let customerAcquisitionData = {
    //   title: this.usernameAndAOIForm.get('uniqueUserName').value,
    //   origin: this.usernameAndAOIForm.get('howTheyFoundOutAboutUs').value,
    //   otherData: 'customer acquisition Data WIP'
    // }



    // HAVEN SUBMISSION ***************************************************

    if (this.selectedTabIndex == 1) {

      if (this.havenTitleIsAvailible) {

        let currentUser = await this.backendApiUsersService.getMyProfile()

        currentUser.title = this.newHavenInputData.havenTitle
        currentUser.gsi2sk = this.newHavenInputData.havenTitle

        currentUser.corePK = 'h'
        currentUser.coreSK = '#' + this.newHavenInputData.havenTitle

        // todo can we set the default hvaven == the newly created haven?

        currentUser.userIsHaven = true
        currentUser.isNewUserFlag = false

        currentUser.defaultHaven = currentUser.username

        currentUser.address = this.newHavenInputData.address
        currentUser.state = this.newHavenInputData.state
        currentUser.zipCode = 55906 //!this.newHavenInputData.zipCode
        currentUser.coordinates = this.newHavenInputData.coordinates

        let updateResult = await this.backendApiUsersService.updateUser(currentUser, this.profileImage ? this.profileImage.rawImage : null)

        if (updateResult) { // TODO: should check for success since we need the username to create the haven!
          this.createHaven(currentUser.username)
        }
        this.snackbarService.openSuccessSnackBar("Info saved. Thank you!")
        this.dialogRef.close()
      }
      else {
        this.snackbarService.openErrorSnackBar('Haven Title is NOT availible')
      }
    }
  }


  async createHaven(havenId) {

    //! it is taking in a parameter

    let haven: iCreateOrUpdateHavenInput = {

      addedByUID: this.user.username,
      title: this.newHavenInputData.havenTitle,
      description: this.newHavenInputData.description,

      address: this.newHavenInputData.address, //! should interface be changed to locationDat rather than address?
      state: this.newHavenInputData.state,
      zipCode: this.newHavenInputData.zipCode,
      coordinates: this.newHavenInputData.coordinates,

      // TODO WE NEED TO REEMMEBER THAT THIS HAVEN IS MANAGED AND NEEDDS A FLAG HERE: true

      isOfficial: true,
      isPublic: true,
      timeZone: this.newHavenInputData.timeZone,
      havenType: this.newHavenInputData.havenType,
      activityStatus: this.newHavenInputData.activityStatus,
      dateEstablished: this.newHavenInputData.dateEstablished,
      userWhoUpdated: this.user.username,
      havenWebsite: this.newHavenInputData.havenWebsite,
      havenAdmin: this.user.title,
      id: havenId,
      hasDemoLibrary: this.newHavenInputData.hasDemoLibrary,
      hasLendingLibrary: this.newHavenInputData.hasLendingLibrary,
      hasNormallyForSale: this.newHavenInputData.hasNormallyForSale

    }

    let files = this.newHavenInputData.mainImageFiles && this.newHavenInputData.mainImageFiles != null ? [this.newHavenInputData.mainImageFiles] : null

    let createHavenResult = await this.backendAPIHavensService.CreateHaven(haven, files) //*** stupid! files need to be in an array to work!!! */
  }


  tabChanged(event) {
    this.selectedTabIndex = event.index
    this.mapShouldInit = event.index == 1
  }

  cancelTesting() {
    this.dialogRef.close()
  }


  compareFunction(o1: any, o2: any) {
    return (o1 == o2)
  }



  usernameAvailable = false
  usernameUnavailable = false
  checkedForAvailibilityOfUsername = false
  whatYouWant: any




  searchObject(obj, searchString) {
    // Base case: if obj is not an object, return false
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }

    // Iterate over the properties of the object
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        // If the current property is an object, search inside it
        if (typeof value === 'object' && value !== null) {
          if (this.searchObject(value, searchString)) {
            return true;
          }
          // If the current property has a "title" attribute, check if it matches the search string
        } else if (key === 'title' && value === searchString) {
          return true;
        }
      }
    }
    // If no match was found, return false
    return false;
  }







  async getUserByTitle() {
    // Reset flags
    this.usernameAvailable = false;
    this.usernameUnavailable = false;

    // Get username value
    const usernameSearchText = this.usernameAndAOIForm.get('uniqueUserName').value;

    // Check if the form control is valid (implicitly checks for max length)
    if (this.usernameAndAOIForm.get('uniqueUserName').valid) {
      this.checkedForAvailibilityOfUsername = true;

      // Convert username to lowercase for consistent search
      const isUsernameTaken = this.searchObject(this.coreUsersToSearch, usernameSearchText.toLowerCase());

      // Update flags based on availability
      this.usernameAvailable = !isUsernameTaken;
      this.usernameUnavailable = isUsernameTaken;
    } else {
      // Handle invalid input, such as displaying an error message
      // This block can be used to set flags or messages indicating the input is too long
    }
  }









  howTheyFoundOutAboutUsChanged(event) {
    this.usernameAndAOIForm.get('howTheyFoundOutAboutUs').patchValue(event.value)
  }





}




// timeZoneChanged(event) {
//   this.havenForm.get('timeZoneInput').patchValue(event.value)
// }

// activityStatusChanged(event) {
//   this.havenForm.get('activityStatus').patchValue(event.value)
// }

// onYearSelected(year) {
//   this.havenForm.get('dateEstablished').patchValue(year)
// }

// havenTypeChanged(event) {
//   this.havenForm.get('havenType').patchValue(event.value)
// }
