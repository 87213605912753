import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { EventType } from 'src/app/dashboard/dashboard-shared/enums/event-types-enums'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
export interface iEvent {
  type: number,
  item?: string,
  customText?: string
}
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-add-event-modal[_sourceOfInput]',
  templateUrl: './add-event-modal.component.html',
  styleUrls: ['./add-event-modal.component.scss']
})
export class AddEventModalComponent implements OnInit {

  @Input() _sourceOfInput: string

  user

  // change these, adding for amplify publish
  emailFormControl
  matcher

  item
  hours

  allGames
  restrictAttendance = false
  hasWaitlist = false
  userList = ['Open Slot', 'Carl', 'Jim', 'Carl Jr.', 'Carla']

  type = EventType.game

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
    //this.hours = this.inputData.tableOrRoom.text.concat(', ').concat(this.inputData.startHour.text).concat('-WIP')
  }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  @Output() closeEmitter = new EventEmitter()

  constructor(
    private snackbar: MatSnackBar,
    private snackbarService: SnackbarService,

    private backendAPIUsersService: BackendAPIUsersService,
  ) { }

  async ngOnInit() {
    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(async user => {
      if (user) {
        this.user = user
        let gameRecommendations = JSON.parse(this.user.gameRecommendations)
        this.allGames = []
        for (let recommendedGame of gameRecommendations) {
          // await this.gamesDbAccessorService.getGameByGSI1(recommendedGame.i).then(result => {
          //   this.allGames.push(result[0]) // TODO :  THIS IS A GREAT CANDIDATE FOR BATCH GET RESOLVER!!!???
          // })
        }
      }
    })
  }

  selectType(event) {
    switch (event.value) {
      case 'game':
        this.type = EventType.game
        break

      case 'open':
        this.type = EventType.openPlay
        this.item = { title: 'Open' }
        break

      case 'break':
        this.type = EventType.break
        this.item = { title: 'Break/Closed' }
        break

      case 'custom':
        this.type = EventType.custom
        this.item = { title: 'Custom' }
        break
    }
  }

  toggleAttendance() {
    this.restrictAttendance = !this.restrictAttendance
  }



  toggleWaitlist() {
    this.hasWaitlist = !this.hasWaitlist
  }

  selectItem(event) {
    switch (this.type) {
      case EventType.game:
        this.item = event[0]
        break

      // ! these values are set when radio button changes...
      // case EventType.open:
      //   this.item = { title: 'Open' }
      //   break

      // case EventType.break:
      //   this.item = { title: 'Break/Closed' }
      //   break
    }
  }


  submit() {
    if (this.item) { // change this to form.valid once form is done
      this.closeDialog()
    }
    else {
      this.snackbarService.openErrorSnackBar('Please fix any errors and try again.')
    }
  }

  cancel() {
    this.closeEmitter.emit()
  }

  closeDialog() {
    let event: iEvent = {
      type: this.type,
      item: this.item
    }
    this.closeEmitter.emit(event)
  }

}
