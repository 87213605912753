import { trigger, state, style, transition, animate, keyframes } from '@angular/animations'

export const EditMyProfileAnimations = {

  openOptionsTrigger: trigger('openOptionsAnimTrigger', [
    state('optionsClosed', style({
      width: '0'
    })),
    state('optionsOpened', style({
      width: '300px'
    })),

    transition('optionsClosed => optionsOpened', [
      animate(250, keyframes([
        style({ width: '0'}),
        style({ width: '300px'}),
      ]))
    ]),

    transition('optionsOpened => optionsClosed', [
      animate(250, keyframes([
        style({ width: '300px' }),
        style({ width: '0'}),
      ]))
    ]),
  ])
}
