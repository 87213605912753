import { trigger, style, transition, animate, keyframes } from '@angular/animations'

export const HopperTableAnimations = {

    addItemTrigger: trigger('addItemAnimTrigger', [
        transition('void => *', [
            animate(500, keyframes([
                style({ transform: 'translateX(2000px)', opacity: '0', transitionTimingFunction: 'ease-in-out', offset: 0 }),
                style({ transform: 'translateX(-40px)', opacity: '1', transitionTimingFunction: 'ease-in-out', offset: 0.7 }),
                style({ transform: 'translateX(0)', opacity: '1', transitionTimingFunction: 'ease-in-out', offset: 1 }),
            ]))
        ]),

        transition('* => void', [
            animate(700, keyframes([
                style({ transform: 'translateX(0)', opacity: '1', transitionTimingFunction: 'ease-in-out', offset: 0 }),
                style({ transform: 'translateX(2000px)', opacity: '0', transitionTimingFunction: 'ease-in-out', offset: 1 }),
            ]))
        ])
    ])

}
