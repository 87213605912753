import { Component, HostListener, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject, Subscription, interval } from 'rxjs'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { MessagingComponent } from 'src/app/dashboard/components/sidebar/components/messaging/messaging.component'
import { TournamentBuilderSetupComponent } from 'src/app/dashboard/dashboard-shared/components/tournaments/tournament-builder-setup/tournament-builder-setup.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { SoundEffectsService } from 'src/app/dashboard/dashboard-shared/services/audio/sound-effects.service'
import { TimingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/timing-helper.service'
import { NotificationPanelService } from 'src/app/dashboard/dashboard-shared/services/panels/notification-panel.service'
import { SidebarService } from 'src/app/dashboard/dashboard-shared/services/sidebar/sidebar.service'
import { AuthService } from 'src/app/shared/services/auth/auth.service'
import { GlobalService } from 'src/app/shared/services/global/global.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { AuthenticatedHeaderAnimations } from './animations/authenticated-header-animations'
import { BulkRateGamesComponent } from 'src/app/dashboard/dashboard-shared/components/ratings/bulk-rate-games/bulk-rate-games.component'
import { GhLocationInputComponent } from 'src/app/dashboard/dashboard-shared/components/custom-inputs/gh-location-input/gh-location-input.component'
import { TempHavenComponentsComponent } from 'src/app/dashboard/pages/havens/pages/havens-dashboard/temp-haven-components/temp-haven-components.component'
import { GhLocationSelectorMapboxGLComponent } from 'src/app/dashboard/dashboard-shared/components/mapping/gh-location-selector-mapbox-gl/gh-location-selector-mapbox-gl.component'
import { GhMapLocationSelectorTextOnlyComponent } from 'src/app/dashboard/dashboard-shared/components/mapping/gh-map-location-selector-text-only/gh-map-location-selector-text-only.component'
import { ColorSchemeService } from 'src/app/shared/services/color-scheme.service'
import { ManageAccountComponent } from 'src/app/dashboard/components/account-panel/components/manage-account/manage-account.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { EditMyProfileComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile/components/edit-my-profile/edit-my-profile.component'
import { BannerSizeEnums } from 'src/app/dashboard/dashboard-shared/enums/banner-size-enums'
import { BannerLocationEnums } from 'src/app/dashboard/dashboard-shared/enums/banner-location-enums'
import { GamenightComponent } from 'src/app/dashboard/dashboard-shared/components/events/gamenight/gamenight.component'
import { HopperComponent } from 'src/app/dashboard/dashboard-shared/components/hopper/hopper/hopper.component'
import { HopperCompetitionLevelEnums } from 'src/app/dashboard/dashboard-shared/enums/hopper-enums'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { PlayNowModalComponent } from 'src/app/dashboard/components/sidebar/components/play-now/components/play-now-modal/play-now-modal.component'
import { EventBuilderComponent } from 'src/app/dashboard/dashboard-shared/generics/event-builder/event-builder.component'
import { GamesBuySellSwapComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/components/games-buy-sell-swap/games-buy-sell-swap.component'
import { CalendarManagementComponent } from 'src/app/dashboard/components/sidebar/components/calendar-management/calendar-management.component'
import { FriendsAndFollowersComponent } from 'src/app/dashboard/components/sidebar/components/friends-and-followers/friends-and-followers.component'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TrendingDataComponent } from 'src/app/dashboard/components/sidebar/components/trending-data/trending-data.component'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { TheFeedComponent } from 'src/app/dashboard/components/sidebar/components/the-feed/the-feed.component'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-authenticated-header',
    templateUrl: './authenticated-header.component.html',
    styleUrls: ['./authenticated-header.component.scss'],
    animations: [AuthenticatedHeaderAnimations.growIn],
})
export class AuthenticatedHeaderComponent implements OnInit {
    @ViewChild('header', { static: false }) header: any
    @ViewChild('logo', { static: false }) logo: any

    user = new BehaviorSubject(null)
    user$ = this.user.asObservable()

    userAvatarImg = window.location.origin + '/assets/images/defaults/profile-placeholder.png'

    showAlphaBadge = true

    pageTitleIcon
    pageTitle

    animState

    userIsActive = true // Set this to false if you want to test the inactive case

    notificationCount: number = null // or any other initial value
    messagesCount: number = null // or any other initial value
    newFriendCount: number
    newInviteCount: number

    hideSecretButtonsForAnimation = false

    @ViewChild('secretButton', { static: false }) secretButton
    @ViewChild('key', { static: false }) key

    showTestingToggles = false
    keepLockVisible = false

    shouldAnimateOpen = false
    shouldAnimateClose = false

    private intervalId: any

    username

    notificationPanelIsActive = false

    sidebarIsActive = false

    isMobileScreen = true

    showMobileMenuIcon = false
    private subscription: Subscription

    constructor(
        private soundEffectsService: SoundEffectsService,
        private sidebarService: SidebarService,
        private notificationPanelService: NotificationPanelService,
        private globalService: GlobalService,
        private screenSizeService: ScreenSizeService,
        private timingHelperService: TimingHelperService,
        private router: Router,
        private userService: UsersService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private authService: AuthService,
        private backendApiMessageService: BackendAPIMessageService,
        private backendAPIContentService: BackendAPIContentService,
        private backendAPIUsersService: BackendAPIUsersService,
        private colorSchemeService: ColorSchemeService,
        private backendAPIMessageService: BackendAPIMessageService,
        private gamesService: GamesService,
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.showMobileMenuIcon = !this.router.url.includes('/public/')
            }
        })
    }

    isGHAdmin = false
    isGHStaff = false
    isHaven = false

    userData
    currentUser
    async ngOnInit() {
        this.startInterval()
        this.userService.getUserIsActive().subscribe((isActive) => {
            this.userIsActive = isActive
        })

        await this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                if (user) {
                    this.userAvatarImg = user.mainImageFiles
                        ? user.mainImageFiles[0]
                        : window.location.origin + '/assets/images/defaults/profile-placeholder.png'
                    this.user.next(user)
                    this.currentUser = user
                    this.fetchNotifications(this.currentUser.username)
                    this.fetchMyMessages()
                }
            })

        this.notificationPanelService.panelIsActive$
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.notificationPanelIsActive = value
            })

        this.globalService.sidebarIsActive$
            .pipe(untilDestroyed(this))
            .subscribe((sidebarIsActive: boolean) => {
                this.sidebarIsActive = sidebarIsActive
            })

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.globalService.currentPageInfo$
            .pipe(untilDestroyed(this))
            .subscribe((currentPageInfo) => {
                if (currentPageInfo) {
                    this.pageTitleIcon = currentPageInfo.icon
                    this.pageTitle = currentPageInfo.title
                }
            })
    }

    // working here
    // working here
    // working here

    async fetchNotifications(username) {
        this.fetchMyNotifications()

        await this.backendAPIMessageService.listMyFriendRequests(username, 50)
        await this.backendAPIMessageService.listMyInvites(username, 50)
        await this.backendAPIMessageService.listMyReceivedMessages(username, 50)
    }

    private startInterval() {
        this.intervalId = setInterval(() => {
            if (this.userIsActive) {
                this.fetchMyNotifications()
                this.fetchMyMessages()
                this.fetchMyFriendInvites()
                this.fetchMyEventInvites()
            } else {
                console.log('User is inactive')
            }
        }, 30000) // Execute every 29 seconds
    }

    async fetchMyEventInvites() {
        let invitesRes = await this.backendApiMessageService.listMyInvites(
            this.user.value.username,
            20,
        )
        this.newInviteCount = invitesRes.invites.length
    }

    async fetchMyFriendInvites() {
        let requestsRes = await this.backendApiMessageService.listMyFriendRequests(
            this.user.value.username,
            20,
        )

        // Filter requests where sentToTitle matches user title and count them
        this.newFriendCount = requestsRes.requests.filter(
            (request) => request.sentToTitle === this.user.value.title,
        ).length
    }

    // working here
    // working here
    // working here

    ngOnDestroy() {
        this.subscription.unsubscribe()
        this.stopInterval()
    }
    private stopInterval() {
        clearInterval(this.intervalId)
    }

    shouldCheck = true
    secretButtonIsLoaded = false
    ngAfterViewChecked() {
        if (!this.secretButtonIsLoaded && this.secretButton) {
            this.secretButtonIsLoaded = true
            this.secretButton.nativeElement.style.cursor =
                "url('../../../../assets/images/coolStuff/key-only.png'), auto"
            this.toggleShowExpirimentalItems({ checked: true })
        }

        if (this.user.value && this.shouldCheck) {
            this.shouldCheck = false

            if (this.user.value.userLevel > 6 && this.user.value.userLevel < 7) {
                this.isGHStaff = true
            }
            if (this.user.value.userLevel > 8) {
                this.isGHAdmin = true
            }
            if (this.user.value.userIsHaven) {
                this.isHaven = true
            }
        }
    }

    toggleDarkMode(event) {
        this.colorSchemeService.update(event.checked ? 'dark' : 'light')
    }

    toggleUserIsHaven(event) {
        this.globalService.setUserIsHaven(event.checked)
    }

    toggleShowExpirimentalItems(event) {
        this.globalService.setShowExpirimentalItems(event.checked)
    }

    toggleNotificationPanel() {
        this.notificationPanelIsActive = !this.notificationPanelIsActive
        this.notificationPanelService.setPanelIsActive(this.notificationPanelIsActive)
    }

    manageAccountSettings() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Manage Account',
                component: ManageAccountComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }

    toggleMessagingPanel() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Messaging',
                component: MessagingComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }

    toggleCalendarManagementPanel() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Calendar Management',

                component: CalendarManagementComponent,

                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }

    toggleFriendsAndFollowers() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Friend Invites',

                component: FriendsAndFollowersComponent,

                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                // hasLeftActionButton: true,
                // leftActionButtonText: 'Start Over',
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }

    currentListOfMessagess
    async fetchMyMessages() {
        let myMessages = await this.backendAPIMessageService.listMyReceivedMessages(
            this.user.value.username,
            500,
        )
        let messagesCount: Array<{ date: string }> = myMessages.messages

        this.messagesCount = messagesCount.length

        this.currentListOfMessagess = messagesCount
    }

    currentListOfNotifications
    async fetchMyNotifications() {
        let myNotifications = await this.backendAPIContentService.listContentByUserID(
            this.user.value.username,
            'notification',
            500,
        )

        let notificationCount: Array<{ date: string }> = myNotifications.content

        // Sort notifications by date in descending order
        notificationCount.sort((a, b) => {
            let dateA: number = new Date(a.date).getTime()
            let dateB: number = new Date(b.date).getTime()
            return dateB - dateA
        })

        this.notificationCount = notificationCount.length
        this.currentListOfNotifications = notificationCount
    }

    toggleIsAdmin(event) {
        //this.userLevelService.updateIsAdmin(event.checked)
    }

    toggleIsAuthenticated(event) {
        //this.authService.setIsAuthenticated(event.checked)
    }

    toggleSidebar() {
        this.globalService.toggleSidebarIsActive()
    }

    editProfile() {
        let editProfileRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Edit Profile',
                component: EditMyProfileComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                hasCloseButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        editProfileRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                this.timingHelperService.delay(1000).then(async () => {
                    let updatedUserResult = await this.backendAPIUsersService.updateUser(
                        this.currentUser,
                        null,
                    )
                    this.backendAPIUsersService.initCurrentUser()
                })
            })
    }

    bannerAdInputDataNonSpecific = {
        bannerSizeEnum: BannerSizeEnums.banner300x250,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        specificAds: null,
        isIFrame: false,
    }

    async onSecretButtonMouseDown() {
        let getProfileResult = await this.backendAPIUsersService.getMyProfile()

        // this.gamesDbService.sendEmailViaLambda('davidrazidlo@gmail.com', getProfileResult.title + ': FOUND THE CODE', getProfileResult.email)
        // this.gamesDbService.sendEmailViaLambda(getProfileResult.email, 'You found the key that opened the lock...', 'You will be contacted in the future with your next challenge. For now, enjoy the beta features you have access to.')

        this.hideSecretButtonsForAnimation = true
        this.showTestingToggles = !this.showTestingToggles

        if (this.showTestingToggles) {
            this.keepLockVisible = true
            this.shouldAnimateOpen = true
            this.shouldAnimateClose = false
        } else {
            this.shouldAnimateOpen = false
            this.shouldAnimateClose = true
        }

        this.secretButton.nativeElement.style.cursor = 'none'

        // NOTE: even though animation duration in scss is 0.5 sec, 400ms seems to work best here...
        this.timingHelperService.delay(400).then(() => {
            if (this.showTestingToggles) {
                this.soundEffectsService.playLockNoise()
                this.soundEffectsService.playSecretFoundNoise()
                this.key.nativeElement.style.display = 'block'
                this.secretButton.nativeElement.style.cursor = 'pointer'
            } else {
                this.soundEffectsService.playLockNoise()
                this.keepLockVisible = false
                this.key.nativeElement.style.display = 'none'
                this.secretButton.nativeElement.style.cursor =
                    "url('../../../../assets/images/coolStuff/key-only.png'), auto"
            }
        })
    }

    onAnimStart(event) {
        //this.animIsDone = false
        //this.hideSecretButtonsForAnimation = true
        //this.hideSecretButtonsForAnimation = event.fromState == 'void' ? true : false
    }

    onAnimDone(event) {
        //this.animIsDone = true
        this.hideSecretButtonsForAnimation = event.fromState == 'void' ? false : true
    }

    goHome() {
        //! Important: router.navigate must come after sidebarService.updateSelectedItem, otherwise the route param doesn't work
        this.sidebarService.updateSelectedItem('PROFILE')
        this.router.navigate(['./../../dashboard/my-profile'], { relativeTo: this.route })
    }

    openTournamentBuilderSetup() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Create Tournement',
                component: TournamentBuilderSetupComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }

    openMapTesting() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Pick Haven Location',
                component: GhLocationSelectorMapboxGLComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }

    openBulkRateGames() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Bulk Rate Games',
                component: BulkRateGamesComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    openMapLocationSelectorTextOnly() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Location Selector',
                component: GhMapLocationSelectorTextOnlyComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    openEventBuilder() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Event Builder',
                component: EventBuilderComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,

                inputData: {
                    events: null,
                    rooms: JSON.stringify([
                        'Main Room Table 1',
                        'Main Room Table 2',
                        'Back Room Table 1',
                    ]),
                    timeInterval: 1,
                    startHour: 15,
                    endHour: 20,
                    haven: 'testing',
                },
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    openCastleConDetails() {
        this.sidebarService.updateSelectedItem('PROFILE')
        this.router.navigate(['./../../dashboard/my-profile'], { relativeTo: this.route })
        this.timingHelperService.delay(1000).then(() => {
            this.sidebarService.updateSelectedItem('FIND EVENTS')
            this.router.navigate(['../../../dashboard/events'], { relativeTo: this.route })
            //ghfjdkslghjfkdlsghjfkdlsghfjdklsg
            // This path will need to be releative to whereever you put the button within the UI
        })
    }

    openLocationSelector() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Location Selector',
                component: GhLocationInputComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    openHavenTestingButtons() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Haven Testing Buttons',
                component: TempHavenComponentsComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            width: '90%',
            height: '90%',
        })
    }

    logout() {
        this.authService.logout().then(() => {
            this.router.navigate(['../../../public'])
        })
    }

    goToConventionsTempPage() {
        this.router.navigate(['../../../temp-conventions'])
    }

    // * TEMP TESTING STUFF BELOW
    testBuySellSwap() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Preview Buy/Sell/Swap',
                component: GamesBuySellSwapComponent,
                inputData: null,
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Submit',
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }

    testPlayNow() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Play Now Preview',
                component: PlayNowModalComponent,
                inputData: null,
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Full Detail',
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }

    openTheFeed() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'The Feed',
                component: TheFeedComponent,
                inputData: this.currentListOfNotifications,
                hasSubmitButton: false,
                hasCancelButton: false,
                submitButtonText: 'Full Detail',
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })

        dialogRef.afterClosed().subscribe(() => {
            this.fetchNotifications(this.currentUser.username)
        })
    }

    trendingDataModal() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Trending Games',
                component: TrendingDataComponent,
                inputData: null,
                hasSubmitButton: false,
                hasCancelButton: false,
                submitButtonText: 'Full Detail',
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }

    testGamenight() {
        // TODO: creeate interface or die!!!
        let gameNightInputData = {
            attendingList: [],
            invitedList: [],
            games: [],
            food: [
                {
                    // food: 'Pizza',
                    eventFood: 'Pizza',
                    contributor: '01gejf8bxqk0hkcmz99tk4da4c', // should this be an object?
                    requestor: '01gejf8bxqk0hkcmz99tk4da4c', // should this be an object?
                    isCommitted: true, // what does isCommittedMean? Can't remember...
                },
            ],
            beverages: [],
            description: 'here is event description!!!',
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Game Night Preview',
                component: GamenightComponent,
                inputData: gameNightInputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Full Detail',
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }

    testHopper() {
        let coreGames = this.gamesService.getCoreGames()

        let gamesSample = []

        for (var i = 0; i < 4; i++) {
            gamesSample.push(coreGames[i])
        }

        let inputData = {
            hopperData: [
                {
                    itemId: 'abc',
                    group: {
                        admin: 'Shay',
                        members: [{ title: 'Shay' }],
                    },
                    game: {
                        title: gamesSample[0].title,
                        currentPlayerCount: 1,
                        minPlayers: 1,
                        maxPlayers: 2,
                        votedBestMinPlayers: 1,
                        votedBestMaxPlayers: 2,
                        hasCustomBounds: false,
                        customLowerBound: 0,
                        customUpperBound: 0,
                        minPlayersCanAccept: 1,
                        maxPlayersCanAccept: 1,
                        idealPlayersToAccept: 1,
                        isFlaggedForMatch: false,
                        mainImage: gamesSample[0].mainImageFiles[0],
                    },
                    // haven?: any,
                    desiredIndices: [],
                    comments: 'Hello everyone',
                    competitionLevel: HopperCompetitionLevelEnums.tournament,
                    postedTimestamp: '',
                    // isFlaggedForMatch?: boolean,
                    // start?: string,
                    // end?: string
                },
                {
                    itemId: 'def',
                    group: {
                        admin: 'Dave',
                        members: [{ title: 'Dave' }, { title: 'Shay' }],
                    },
                    game: {
                        title: gamesSample[1].title,
                        currentPlayerCount: 2,
                        minPlayers: 2,
                        maxPlayers: 4,
                        votedBestMinPlayers: 4,
                        votedBestMaxPlayers: 4,
                        hasCustomBounds: false,
                        customLowerBound: 0,
                        customUpperBound: 0,
                        minPlayersCanAccept: 2,
                        maxPlayersCanAccept: 2,
                        idealPlayersToAccept: 2,
                        isFlaggedForMatch: false,
                        mainImage: gamesSample[1].mainImageFiles[0],
                    },
                    // haven?: any,
                    desiredIndices: [],
                    comments: '',
                    competitionLevel: HopperCompetitionLevelEnums.serious,
                    postedTimestamp: '',
                    // isFlaggedForMatch?: boolean,
                    // start?: string,
                    // end?: string
                },
                {
                    itemId: 'ghi',
                    group: {
                        admin: 'Shay',
                        members: [{ title: 'Shay' }],
                    },
                    game: {
                        title: gamesSample[2].title,
                        currentPlayerCount: 1,
                        minPlayers: 4,
                        maxPlayers: 6,
                        votedBestMinPlayers: 4,
                        votedBestMaxPlayers: 6,
                        hasCustomBounds: true,
                        customLowerBound: 2,
                        customUpperBound: 8,
                        minPlayersCanAccept: 1,
                        maxPlayersCanAccept: 99,
                        idealPlayersToAccept: 7,
                        isFlaggedForMatch: true,
                        mainImage: gamesSample[2].mainImageFiles[0],
                    },
                    // haven?: any,
                    desiredIndices: [],
                    comments: 'Im the guy in the blue shirt',
                    competitionLevel: HopperCompetitionLevelEnums.competitive,
                    postedTimestamp: '',
                    // isFlaggedForMatch?: boolean,
                    // start?: string,
                    // end?: string
                },
                {
                    itemId: 'jkl',
                    group: {
                        admin: 'Gina',
                        members: [{ title: 'Gina' }],
                    },
                    game: {
                        title: gamesSample[3].title,
                        currentPlayerCount: 1,
                        minPlayers: 2,
                        maxPlayers: 2,
                        votedBestMinPlayers: 2,
                        votedBestMaxPlayers: 2,
                        hasCustomBounds: false,
                        customLowerBound: 0,
                        customUpperBound: 0,
                        minPlayersCanAccept: 1,
                        maxPlayersCanAccept: 1,
                        idealPlayersToAccept: 2,
                        isFlaggedForMatch: false,
                        mainImage: gamesSample[3].mainImageFiles[0],
                    },
                    // haven?: any,
                    desiredIndices: [],
                    comments: 'Free beer to join!',
                    competitionLevel: HopperCompetitionLevelEnums.casual,
                    postedTimestamp: '',
                    // isFlaggedForMatch?: boolean,
                    // start?: string,
                    // end?: string
                },
            ],
            displayedColumns: [
                'group',
                'game',
                'playersNeeded',
                'competition',
                'comments',
                'minutesSincePosted',
                'join',
                'actions',
            ],
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Hopper Preview: Let us know what you think!',
                component: HopperComponent,
                inputData: inputData,
                hasSubmitButton: false,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
                width: '99%',
                height: '99%',
            },
        })
    }
}
