<div class="row m-0 parent-container" *ngIf="!(isLoading | async)">

    <button mat-raised-button *ngIf="hasOverlayOption" class="gh-button gh-button-secondary"
        (click)="toggleOverlay()">{{ displayOverlay ? getHideOverlayButtonText() : getShowOverlayButtonText()
        }}</button>

    <div class="col-12 chart-container">
        <div>
            <!-- ! Important: canvas height and width must be set in px (at least as far as I can tell so far...) -->
            <!-- !            Will eventually need to figure out how to adjust this to screen sizes -->

            <canvas style="width: 100%" baseChart [datasets]="chartData" [labels]="radarChartLabelsShallowCopy"
                [type]="chartType" [options]="radarChartOptions" (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)" [height]="getCanvasPx()" [width]="getCanvasPx()">
            </canvas>
        </div>
    </div>





    <div class="row mx-0 mt-5" *ngIf="inputData.isCurrentUsersProfile">
        <form [formGroup]="form">

            <section>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                            <!-- ! make this below ngClass so that it doesnt apply to mobile!!!! -->
                            <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="row header-row">
                                    <div class="col-12 text-center">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Complexity</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row mt-5" [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                    matTooltipPosition="above">
                                    <mat-slider formControlName="complexityRating"
                                        (change)="onComplexitySliderChanged()" thumbLabel tickInterval="1" min="0"
                                        max="10" value="complexityRating" class="slider">
                                    </mat-slider>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Very Few Rules</mat-card-subtitle>
                                    </div>
                                    <div class="col-4 ">
                                    </div>
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Lots of Rules</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="row header-row">
                                    <div class="col-12 text-center">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Depth</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row mt-5" [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                    matTooltipPosition="above">
                                    <mat-slider formControlName="depthRating" (change)="onDepthSliderChanged()"
                                        thumbLabel tickInterval="1" min="0" max="10" value="depthRating" class="slider">
                                    </mat-slider>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Easy to Master</mat-card-subtitle>
                                    </div>
                                    <div class="col-4 ">
                                    </div>
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Hard to Master</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="row header-row">
                                    <div class="col-12 text-center">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Duration</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row mt-5" [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                    matTooltipPosition="above">
                                    <mat-slider formControlName="durationRating" (change)="onDurationSliderChanged()"
                                        thumbLabel tickInterval="1" min="0" max="10" value="durationRating"
                                        class="slider">
                                    </mat-slider>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Short</mat-card-subtitle>
                                    </div>
                                    <div class="col-4 ">
                                    </div>
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Long</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="row header-row">
                                    <div class="col-12 text-center">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Interaction</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row mt-5" [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                    matTooltipPosition="above">
                                    <mat-slider formControlName="interactionRating"
                                        (change)="onInteractionSliderChanged()" thumbLabel tickInterval="1" min="0"
                                        max="10" value="interactionRating" class="slider">
                                    </mat-slider>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Player Actions are Independent</mat-card-subtitle>
                                    </div>
                                    <div class="col-4 ">
                                    </div>
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Players Influence Each Other</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="row header-row">
                                    <div class="col-12 text-center">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Competition</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row mt-5" [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                    matTooltipPosition="above">
                                    <mat-slider formControlName="competitionRating"
                                        (change)="onCompetitionSliderChanged()" thumbLabel tickInterval="1" min="0"
                                        max="10" value="competitionRating" class="slider">
                                    </mat-slider>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Cooperative</mat-card-subtitle>
                                    </div>
                                    <div class="col-4 ">
                                    </div>
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Competitive</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="row header-row">
                                    <div class="col-12 text-center">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Player Count</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row mt-5" [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                    matTooltipPosition="above">
                                    <mat-slider formControlName="playersRating" (change)="onPlayersSliderChanged()"
                                        thumbLabel tickInterval="1" min="0" max="10" value="playersRating"
                                        class="slider">
                                    </mat-slider>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Solo Play</mat-card-subtitle>
                                    </div>
                                    <div class="col-4 ">
                                    </div>
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Large Group</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="row header-row">
                                    <div class="col-12 text-center">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Chance</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row mt-5" [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                    matTooltipPosition="above">
                                    <mat-slider formControlName="chanceRating" (change)="onChanceSliderChanged()"
                                        thumbLabel tickInterval="1" min="0" max="10" value="chanceRating"
                                        class="slider">
                                    </mat-slider>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Skill-Based</mat-card-subtitle>
                                    </div>
                                    <div class="col-4 ">
                                    </div>
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Luck-Based</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="row header-row">
                                    <div class="col-12 text-center">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Thematic</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row mt-5" [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                    matTooltipPosition="above">
                                    <mat-slider formControlName="thematicRating" (change)="onThematicSliderChanged()"
                                        thumbLabel tickInterval="1" min="0" max="10" value="thematicRating"
                                        class="slider">
                                    </mat-slider>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Abstract / Symbolic</mat-card-subtitle>
                                    </div>
                                    <div class="col-4 ">
                                    </div>
                                    <div class="col-4 text-center">
                                        <mat-card-subtitle>Simulates Reality</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </form>
    </div>


    <div class="col-12" *ngIf="inputData.isRatable">
        <div class="button-container">
            <button *ngIf="!hasRated && !isEditing" class="mr-5" mat-button mat-raised-button style="float: right"
                class="gh-button gh-button-primary" (click)="editRating()">Rate It</button>

            <button *ngIf="hasRated" mat-button mat-raised-button style="float: right;"
                class="gh-button gh-button-secondary" (click)="editRating()">Edit Rating</button>

            <button *ngIf="hasRated" mat-button mat-raised-button style="float: right;"
                class="gh-button gh-button-warning" (click)="removeRating()">Remove Rating</button>



            <!-- <button *ngIf="isEditing" mat-button mat-raised-button style="float: right;"
                class="gh-button gh-button-primary" (click)="cancelRating()">Cancel</button> -->

            <button *ngIf="isEditing && hasRated" mat-button mat-raised-button style="float: right;"
                class="gh-button gh-button-primary" (click)="submitRating()">Save Changes</button>
        </div>
    </div>
    <button *ngIf="isEditing && !hasRated" mat-button mat-raised-button style="float: right;"
        class="gh-button gh-button-primary" (click)="submitRating()">Submit</button>



</div>


<div class="gh-loading-container" *ngIf="(isLoading$ | async)">
    <app-gh-loading-spinner></app-gh-loading-spinner>
</div>