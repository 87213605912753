<mat-card-content #viewContent>
    <form [formGroup]="form">
        <div class="mx-0 mt-2 row section">
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-12">
                        <app-autocomplete-chiplist style="width: 100%" *ngIf="coreGames$ | async" [_items]="coreGames.value" [_selectedItems]="gameToEdit ? [gameToEdit] : null" [_label]="'Game Title'" [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true"
                            [_singleInput]="true" [_sourceOfInput]="'app-share-modal-advanced-log-play1'" [_useFuzzySearch]="true" (selectionChanged)="selectGame($event)">
                            <!-- ! Is the app-autocomplete-chiplist working properly here to not allow custom inputs -->
                        </app-autocomplete-chiplist>
                    </div>
                    <div class="col-12">
                        <app-autocomplete-chiplist *ngIf="coreHavens$ | async" [_items]="coreHavens.value" [_selectedItems]="havenToEdit ? [havenToEdit] : null" [_label]="'Haven Title'" [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="true"
                            [_sourceOfInput]="'app-share-modal-advanced-log-play2'" (selectionChanged)="selectHaven($event)">
                        </app-autocomplete-chiplist>
                    </div>
                    <div class="col-12">
                        <app-autocomplete-chiplist *ngIf="coreGroups" [_items]="coreGroups" [_selectedItems]="groupToEdit ? [groupToEdit] : null" [_label]="'Your Group Title'" [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="true"
                            [_sourceOfInput]="'app-share-modal-advanced-log-play3'" (selectionChanged)="selectGroup($event)">
                        </app-autocomplete-chiplist>
                    </div>

                    <div class="col-12">
                        <app-autocomplete-chiplist *ngIf="myEvents$ | async" [_items]="myEvents.value" [_selectedItems]="eventToEdit ? [eventToEdit] : null" [_label]="'Your Event Title'" [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="true"
                            [_sourceOfInput]="'app-share-modal-advanced-log-play3'" (selectionChanged)="selectEvent($event)">
                        </app-autocomplete-chiplist>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <app-gh-file-uploader (selectedImageChanged)="setSelectedImage($event)" #fileUploader [_isProfileImg]="false" [_defaultImage]="selectedImage" [_sourceOfInput]="'app-share-modal-image'" [_isEditing]="isEditing">
                </app-gh-file-uploader>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <mat-form-field class="gh-form-field">
                            <!-- <input type="text" placeholder="Select Duration" matInput [matAutocomplete]="autoDuration"
                                formControlName="duration"> -->
                            <!-- <mat-autocomplete #autoDuration="matAutocomplete">
                                <mat-option *ngFor="let duration of durationOptions" [value]="duration">
                                    {{duration}}
                                </mat-option>
                            </mat-autocomplete> -->

                            <mat-label>Duration</mat-label>
                            <mat-select [compareWith]="compareFn" formControlName="duration">
                                <mat-option *ngFor="let option of durationOptions" [value]="option.value">
                                    {{ option.name }}
                                </mat-option>
                            </mat-select>

                            <!-- 
                            <mat-select (selectionChange)="setExpansions($event)" multiple formControlName="duration">
                                <mat-option *ngFor="let duration of durationOptions" [value]="duration.value">
                                    {{duration.name}}
                                </mat-option>
                            </mat-select> -->
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="date" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker touchUi #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="text-center col-12 col-md-4" style="padding-top: 1rem">
                        <mat-label>I Won?</mat-label>

                        <mat-checkbox value="0" [checked]="form.get('iWon').value" (change)="iWon = !iWon" formControlName="iWon">
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isEditing" class="mx-0 row section">
            <!-- Label -->
            <div class="mt-2 text-center col-12">
                <mat-card-title class="gh-xsm-title">Log Play for a Friend?</mat-card-title>
            </div>

            <div class="col-12">
                <div class="row" *ngIf="coreUsers$ | async">
                    <!-- USER 2 -->
                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist [_items]="coreUsers.value" [_label]="'2nd Player'" [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="true" [_sourceOfInput]="'app-messaging'" (selectionChanged)="selectUser2($event)">
                        </app-autocomplete-chiplist>

                        <!-- PICK BACK UP HERE??! -->
                        <!-- <mat-form-field class="gh-form-field">
                            <mat-label>2nd Player</mat-label>
                            <mat-select formControlName="player2" (selectionChanged)="selectUser2($event)">
                                <mat-option *ngFor="let option of coreUsers.value" [value]="option">
                                    {{option.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                    </div>

                    <!-- USER 3 -->
                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist [_items]="coreUsers.value" [_label]="'3rd Player'" [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="true" [_sourceOfInput]="'app-messaging'" (selectionChanged)="selectUser3($event)">
                        </app-autocomplete-chiplist>
                    </div>

                    <!-- USER 4 -->
                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist [_items]="coreUsers.value" [_label]="'4th Player'" [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="true" [_sourceOfInput]="'app-messaging'" (selectionChanged)="selectUser4($event)">
                        </app-autocomplete-chiplist>
                    </div>

                    <!-- USER 5 -->
                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist [_items]="coreUsers.value" [_label]="'5th Player'" [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="true" [_sourceOfInput]="'app-messaging'" (selectionChanged)="selectUser5($event)">
                        </app-autocomplete-chiplist>
                    </div>
                </div>

                <!-- <div class="row">
                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist [_items]="coreUsers.value" [_label]="'6th Player'"
                            [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true"
                            [_singleInput]="true" [_sourceOfInput]="'app-messaging'"
                            (selectionChanged)="selectUser6($event)">
                        </app-autocomplete-chiplist>
                    </div>

                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist [_items]="coreUsers.value" [_label]="'7th Player'"
                            [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true"
                            [_singleInput]="true" [_sourceOfInput]="'app-messaging'"
                            (selectionChanged)="selectUser7($event)">
                        </app-autocomplete-chiplist>
                    </div>

                    <div class="col-12 col-md-3">
                        <app-autocomplete-chiplist [_items]="coreUsers.value" [_label]="'8th Player'"
                            [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true"
                            [_singleInput]="true" [_sourceOfInput]="'app-messaging'"
                            (selectionChanged)="selectUser8($event)">
                        </app-autocomplete-chiplist>
                    </div>
                </div> -->
            </div>
        </div>

        <!-- ! FUTURE  --  expansions -->
        <div class="m-0 row section" *ngIf="possibleExpansions && possibleExpansions.length > 0">
            <div class="col-12">
                <h1>hi</h1>
                <!-- <div class="col-12 col-md-4">
                <mat-form-field class="gh-form-field">
                    <mat-label>Expansions</mat-label>
                    <mat-select (selectionChange)="setExpansions($event)" multiple formControlName="expansions">
                        <mat-option *ngFor="let expansion of possibleExpansions" [value]="expansion">
                            {{expansion.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
            </div>
        </div>

        <div class="mx-0 row section">
            <div class="mt-2 text-center col-12">
                <mat-card-title class="gh-xsm-title">Tag Other Non-Game Haven Users?</mat-card-title>
            </div>

            <div class="w-100 text-center col-12 col-md-4">
                <mat-form-field class="gh-form-field">
                    <mat-label>Non-GH User</mat-label>
                    <mat-select formControlName="nonGhUser1" multiple (selectionChange)="setNonGhUser1($event)">
                        <mat-option *ngFor="let player of players.value" [value]="player">{{ player }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="w-100 text-center col-12 col-md-4">
                <mat-form-field class="gh-form-field">
                    <mat-label>Add Non-Game Haven User:</mat-label>
                    <input type="text" placeholder="Name" matInput formControlName="taggableNonGhUsers" autocompleteOff />
                </mat-form-field>
                <button mat-raised-button (click)="addNameToPersonalListOfNonGhUsers()">Add</button>
            </div>

            <div class="w-100 text-center col-12 col-md-3">
                <mat-form-field class="gh-form-field">
                    <mat-label>Remove Non-Game Haven User:</mat-label>
                    <mat-select formControlName="removeNonGHUser" placeholder="Name" (selectionChange)="selectionChanged($event)">
                        <mat-option *ngFor="let user of players.value" [value]="user">
                            {{ user }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button (click)="removeNameFromPersonalListOfNonGhUsers()">
                    Remove
                </button>
            </div>
        </div>

        <div class="mx-0 row section">
            <div class="col-12">
                <div class="mt-2 gh-bordered-textarea-container">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Public Notes:</mat-label>
                        <textarea style="white-space: pre-wrap" matInput class="gh-bordered-textarea" autocomplete="off" name="" id="" cols="30" rows="5" formControlName="publicNotes">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="mx-0 row section">
            <div class="col-12">
                <div class="mt-2 gh-bordered-textarea-container">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Notes to Self:</mat-label>
                        <textarea style="white-space: pre-wrap" matInput class="gh-bordered-textarea" autocomplete="off" name="" id="" cols="30" rows="5" formControlName="notesToSelf">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- ! BELOW HERE IS FUTURE. ONCE WE GET TO IT REMEMBER TO ADD THE STYLING (SECTION, ETC.) -->
        <div class="mx-0 row">
            <!-- <div class="ml-3 experience-review-container gh-section col-8" style="margin-bottom: 0; margin-top: 1rem;">
                    <div class="min-height-input row" *ngFor="let player of playerRows; let i = index">

                        <div class="mt-1 col-1">
                            <span class="ml-2 green material-icons" *ngIf="i == 0" mat-button (click)="addTeam()" style="cursor: pointer;"> add_circle_outline
                            </span>
                            <span class="ml-2 material-icons red" *ngIf="i > 0" mat-button (click)="removeTeam()" style="cursor: pointer;"> remove_circle
                            </span>
                        </div>

                        <div class="col-2">
                            <mat-card-title>Team {{i+1}}</mat-card-title>
                        </div>

                        <div class="col-3">
                            <app-autocomplete-chiplist [_items]="players" [_label]="'Players'" [_allowDuplicates]="false" [_allowCustomInput]="true" [_sourceOfInput]="'app-share-modal-advanced-log-play2'" (selectionChanged)="addPlayers($event)">
                            </app-autocomplete-chiplist>
                        </div>

                        <div class="col-2">
                            <mat-form-field class="example-full-width">
                                <mat-label>Victory Points</mat-label>
                                <input matInput placeholder="Points" value="">
                            </mat-form-field>
                        </div>

                        <div class="mt-4 col-2">
                            <mat-label class="mt-5">Winner?</mat-label>
                            <mat-checkbox class="ml-2"></mat-checkbox>
                        </div>

                    </div>
                </div> -->
            <!-- 
                        REMOVED FOR NOW, POSSIBLE FUTURE FEATURE
                    <mat-divider class="mb-3"></mat-divider>
                    <div class="row">
                        <mat-card-subtitle class="mt-3">Did you have a good time?</mat-card-subtitle>
                    </div>

                    <div class="row">
                        <mat-radio-group aria-label="Select an option" class="ml-3">
                            <div class="col-12">

                                <div class="pl-4 row">
                                    <mat-radio-button value="1">Yes!</mat-radio-button>
                                </div>

                                <div class="pl-3 row">
                                    <mat-radio-button value="2" (change)="goodTimeSelected()" class="ml-2">Not so
                                        much...
                                    </mat-radio-button>
                                </div>

                            </div>
                        </mat-radio-group>
                    </div> -->
        </div>

        <div class="mx-0 gh-section invite-players-container row" *ngIf="newPlayers.length > 0">
            <div class="col-12">
                <div class="row">
                    <mat-card-title>Invite players to GH?</mat-card-title>
                </div>

                <div class="row">
                    <mat-card-subtitle>Email(s) will send when this form is submitted.</mat-card-subtitle>
                </div>

                <div class="row-height-override checkboxes row" *ngFor="let p of newPlayers">
                    <mat-checkbox>{{ p }}</mat-checkbox>
                </div>

                <div class="row">
                    <mat-form-field>
                        <mat-label>Message (optional)</mat-label>
                        <<<<<<< HEAD <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5">
                            </textarea>
                            =======
                            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea> >>>>>>> 657d37e1be968a9d7a2a64fb5af21c90a581bc21
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- TODO FUTURE FEATURE -->
        <div class="mx-0 my-3 row">
            <mat-accordion class="gh-accordion">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="header-span" class="title">Future Feature for Preview</span
                            >
                        </mat-panel-title>

                        <mat-panel-description>
                            <span class="pt-3 header-description-span header-span"
                                >Add images, videos, and text. No Data Saved.</span
                            >
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <app-story-builder></app-story-builder>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </form>
</mat-card-content>

<!-- <div class="min-height-input row"> -->

<!-- <p-table #dt [columns]="selectedColumns" [value]="activity">
    
                    <ng-template pTemplate="caption">
                        <div style="text-align:left; z-index: 1555;">
                            <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                                selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}">
                            </p-multiSelect>
    
                        </div>
                    </ng-template>
            
            
                    <ng-template pTemplate="header" let-columns>
            
                        <tr>
                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
            
                        </tr>
            
                        <tr>
                            <th *ngFor="let col of columns" [ngSwitch]="col.field">
            
                                <input *ngSwitchCase="'player'" pInputText type="player?"
                                    (input)="dt.filter($event.target.value, col.field, 'contains')">
            
                                <input *ngSwitchCase="'score'" pInputText type="score?"
                                    (input)="dt.filter($event.target.value, col.field, 'contains')">
                    
                                    <input *ngSwitchCase="'winner'" pInputText type="winner?"
                                    (input)="dt.filter($event.target.value, col.field, 'contains')">
                            </th>
                        </tr>
            
                    </ng-template>
            
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <td *ngFor="let col of columns">
                                {{rowData[col.field]}}
                            </td>
                        </tr>
                    </ng-template>
            
                </p-table> -->

<!-- </div> -->