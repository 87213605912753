import { Output } from '@angular/core'
import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MatStepper } from '@angular/material/stepper'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { uploadImageFile } from 'src/app/backend-api-services/s3-file-uploader'
import { ImageMultiSizeService } from 'src/app/dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import { iCreateLinkItemsInput, iImageOrVideoGalleryInputItem } from '../../../../../../../../../../common/interfaces/ContentInterfaces'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { CreateAndShareType } from 'src/app/dashboard/dashboard-shared/enums/create-and-share-type-enums'
import { MatDialogRef } from '@angular/material/dialog'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { TimingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/timing-helper.service'

@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-share-modal[_sourceOfInput]',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {

  @ViewChild('shareReviewModalVC', { static: false }) shareReviewModalVC
  @ViewChild('shareArticleModalVC', { static: false }) shareArticleModalVC
  @ViewChild('shareLPModalVC', { static: false }) shareLPModalVC

  now = new Date().toString()

  imageForm: UntypedFormGroup
  imageForPreviewReview = ''
  imageTitle = ''
  selectedImage

  imageInfo: [iImageOrVideoGalleryInputItem] = [{
    imgPath: this.imageForPreviewReview,
    title: this.imageTitle,
    gameTitle: null,
    subtitle: 'This is my favorite version of Risk to date123!',
    published: this.now,
    likes: null,
    tags: undefined,
    contentByGameByLikesPK: undefined,
    contentByGroupByLikesPK: undefined,
    contentByEventByLikesPK: undefined,
    contentByHavenByLikesPK: undefined,
    contentByUserByLikesPK: undefined,
    byLikesSK: undefined, pk: undefined,
    sk: undefined

  }]

  videoForm: UntypedFormGroup
  videoForPreviewReview = ''
  videoTitle = ''
  videoSubtitle = ''
  selectedVideoType = "OTHER"

  videoInfo: [iImageOrVideoGalleryInputItem] = [{
    videoPath: '', //? Note, if it says showInfo-8 on the end it fails
    title: this.videoTitle,
    gameTitle: null,
    subtitle: this.videoSubtitle,
    published: this.now,
    likes: null,
    tags: undefined,
    contentByGameByLikesPK: undefined,
    contentByGroupByLikesPK: undefined,
    contentByEventByLikesPK: undefined,
    contentByHavenByLikesPK: undefined,
    contentByUserByLikesPK: undefined,
    byLikesSK: undefined,
    pk: undefined,
    sk: undefined

  }]

  linkImageForReview
  linkTitlePreview
  linkDescriptionPreview
  linkLinkPreview

  linkInfo: [iCreateLinkItemsInput] = [{
    userLevelWhoAdded: undefined,
    addedByUID: '',
    title: '',
    subtitle: '',
    link: '',
    paths: null
  }]

  reviewToSubmit

  problematicPlayerTags = []
  problematicGameTags = []
  problematicHavenTags = []
  problematicGroupTags = []
  problematicEventTags = []

  user
  type
  conditionalSteps

  typeIsSelected = false
  submitClicked = false

  @ViewChild('stepper', { static: false }) stepper: MatStepper

  @Input() _sourceOfInput: string

  @Input() set _inputData(inputData) {
    let initialSelectionString = CreateAndShareType[inputData.initialSelection]
    this.setType(initialSelectionString)
  }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submitClicked = true
      this.submit()
    }
  }

  parentLeftActionButtonClicked = false
  @Input() set _parentLeftActionButtonClicked(parentLeftActionButtonClicked: boolean) {
    if (parentLeftActionButtonClicked) {
      this.resetStepper()
    }
  }

  @Output() closeEmitter = new EventEmitter()
  @Output() hideParentWrapperButtonsEmitter = new EventEmitter()

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ShareModalComponent>,
    private imageMultiSizeService: ImageMultiSizeService,
    private backendAPIUsersService: BackendAPIUsersService,
    private backendAPIContentService: BackendAPIContentService,
    private screenSizeService: ScreenSizeService,
    private timingHelperService: TimingHelperService
  ) { }

  isMobileScreen = false

  ngOnInit(): void {

    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })

    this.imageForm = this.formBuilder.group({
      imageCaption: [''],
      imageTitle: [''],
    })

    this.videoForm = this.formBuilder.group({
      videoCaption: [''],
      videoTitle: [''],
    })

    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })
  }

  stepperIsLoaded = false
  ngAfterViewChecked() {
    if (!this.stepperIsLoaded) {
      if (this.stepper) {
        this.stepperIsLoaded = true
      }
    }
  }

  nextStep() {
    this.stepper.next()
  }

  setType(type) {
    this.type = type
    this.hideParentWrapperButtonsEmitter.emit(false)
    this.typeIsSelected = true
    this.setConditionalSteps(type)
  }

  resetStepper() {
    this.typeIsSelected = false
    this.conditionalSteps = null
  }

  setHasProblematicTags() {
    var indexToAddFixTags = -1
    for (var i = 0; i < this.conditionalSteps.length; i++) {
      if (this.conditionalSteps[i].label == 'Tags') {
        indexToAddFixTags = i + 1
      }
    }
    if (!this.conditionalSteps.some(e => e.label === 'Fix Tags') && indexToAddFixTags != -1) {
      this.conditionalSteps.splice(indexToAddFixTags, 0, { type: this.type, label: 'Fix Tags' })
    }
  }

  setConditionalSteps(type) {
    // TODO: create enum for type
    switch (type) {

      case 'advancedLogPlay':
        this.conditionalSteps = [
          { type: 'advancedLogPlay', label: 'Advanced Log Play' },
          // { type: 'advancedLogPlay', label: 'Tags' },
          { type: 'advancedLogPlay', label: 'Review & Submit' }
        ]
        break

      case 'image':
        this.conditionalSteps = [
          { type: 'image', label: 'Upload Image' },
          // { type: 'image', label: 'Tags' }
          // { type: 'image', label: 'Review & Submit' }
        ]
        break

      case 'video':
        this.conditionalSteps = [
          { type: 'video', label: 'Share Video' },
          // { type: 'video', label: 'Tags' },
          // { type: 'video', label: 'Review & Submit' }
        ]
        break

      case 'article':
        this.conditionalSteps = [
          { type: 'article', label: 'Create Article' },
          { type: 'article', label: 'Review & Submit' }
        ]
        break

      case 'review':
        this.conditionalSteps = [
          { type: 'review', label: 'Create Review' },
          { type: 'review', label: 'Review & Submit' }
        ]
        break

      case 'link':
        this.conditionalSteps = [
          { type: 'link', label: 'Create Link' },
          { type: 'link', label: 'Review & Submit' }
        ]
        break

      // case 'survey':
      //   this.conditionalSteps = [
      //     { type: 'survey', label: 'Create Survey' },
      //     { type: 'survey', label: 'Tags' },
      //     { type: 'survey', label: 'Invite Friends' },
      //     { type: 'survey', label: 'Review & Submit' }
      //   ]
      //   break

      // case 'statusUpdate':
      //   this.conditionalSteps = [
      //     { type: 'statusUpdate', label: 'Status' },
      //     { type: 'statusUpdate', label: 'Tags' },
      //     { type: 'statusUpdate', label: 'Review & Submit' }
      //   ]
      //   break
    }
  }

  selectSubject(event) {
    this.selectedVideoType = event.value
  }

  submit() {

    switch (this.type) {
      case 'image':

        if (this.imageForPreviewReview != '') {
          this.completeImageSubmission()
          this.closeEmitter.emit()
        } else {
          alert('no image data')
        }
        break



      case 'video':
        if (this.videoInfo[0].videoPath != '') {
          this.completeVideoSubmission()
          this.closeEmitter.emit()
        } else {
          alert('no video data')
        }
        break



      case 'link':
        this.onLinkSubmission()
        this.closeEmitter.emit()
        break

      case 'review':
        //! TEMPALTE HERE, IS GIVEN ACCESS TO METHODS AND VARIABLES
        this.shareReviewModalVC.submit()
        break

      case 'advancedLogPlay':
        this.shareLPModalVC.submit()
        break

      case 'article':
        this.shareArticleModalVC.submitArticle()
        this.closeEmitter.emit()
        break
    }

    this.timingHelperService.delay(2000).then(() => {
      //! this causes the route to reset back to profile... would need to update everything individually I think
      this.backendAPIUsersService.initCurrentUser()
    })


  }


  async completeVideoSubmission() {
    // Create a single item on the Games Table

    //! wrong, use new modeling to tag the game

    let createVideoItemInput = {
      videoType: this.selectedVideoType,
      userLevel: this.user.userLevel,
      addedByUID: this.user.username,
      gameId: this.videoInfo[0].tags,
      gameTitle: this.videoInfo[0].gameTitle,
      title: this.videoForm.get('videoTitle').value,
      subtitle: this.videoForm.get('videoCaption').value,
      url: this.videoInfo[0].videoPath,
      havenId: this.havenId ? this.havenId : undefined,
      groupId: this.groupId ? this.groupId : undefined,
      eventId: this.eventId ? this.eventId : undefined
    }
    this.backendAPIContentService.createVideoItem(createVideoItemInput)



  }
  //! wrong, use new modeling to tag the game
  //! wrong, use new modeling to tag the game
  //! wrong, use new modeling to tag the game






  async completeImageSubmission() {

    const extension = this.selectedImage.name.split('.').pop()

    const s3ObjectUrl = await uploadImageFile(this.selectedImage, extension)

    const correctedUrl = s3ObjectUrl.replace('https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/', 'https://gh-img.s3.amazonaws.com/')

    let paths = this.imageMultiSizeService.createArrayForAllSizes(correctedUrl.replace('.jpg', '').replace('.jpeg', ''))

    let createItemsInput = {
      addedByUID: this.user.username,
      createdByTitle: this.user.title,
      createdByUID: this.user.username,
      gameId: this.imageInfo[0].tags,
      gameTitle: this.imageInfo[0].gameTitle,
      paths: [paths],
      subtitle: this.imageForm.get('imageCaption').value,
      title: this.imageForm.get('imageTitle').value,
      userLevelWhoAdded: this.user.userLevel,
      havenId: this.havenId ? this.havenId : undefined,
      groupId: this.groupId ? this.groupId : undefined,
      eventId: this.eventId ? this.eventId : undefined
    }

    this.backendAPIContentService.createImageItems(createItemsInput)
  }







  close() {
    this.dialogRef.close()
  }

  async onLinkSubmission() {

    if (this.linkImageForReview && this.linkImageForReview.rawImage) {

      const extension = this.linkImageForReview.rawImage.name.split('.').pop()
      const s3ObjectUrl = await uploadImageFile(this.linkImageForReview.rawImage, extension)
      const correctedUrl = s3ObjectUrl.replace('https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/', 'https://gh-img.s3.amazonaws.com/')

      let s3result = this.imageMultiSizeService.createArrayForAllSizes(correctedUrl.replace('.jpg', ''))

      if (this.linkTitlePreview != '' && this.linkLinkPreview != '') {

        this.linkInfo = [{
          userLevelWhoAdded: this.user.userLevel,
          paths: [s3result],
          addedByUID: this.user.username,
          title: this.linkTitlePreview,
          subtitle: this.linkDescriptionPreview,
          link: this.linkLinkPreview
        }]
        this.backendAPIContentService.createLinkItems(this.linkInfo[0])
      }


      // ! NO IMAGE ******************************************************
    } else {
      let pathObject =
        [
          [
            "https://gh-img.s3.amazonaws.com/01H6W5QBT4A0HEWXP0Q8J8X4CS_S.jpg",
            "https://gh-img.s3.amazonaws.com/01H6W5QBT4A0HEWXP0Q8J8X4CS_M.jpg",
            "https://gh-img.s3.amazonaws.com/01H6W5QBT4A0HEWXP0Q8J8X4CS_L.jpg",
            "https://gh-img.s3.amazonaws.com/01H6W5QBT4A0HEWXP0Q8J8X4CS_XL.jpg"
          ]
        ]

      if (this.linkTitlePreview != '' && this.linkLinkPreview != '') {

        this.linkInfo = [{
          userLevelWhoAdded: this.user.userLevel,
          paths: pathObject,
          addedByUID: this.user.username,
          title: this.linkTitlePreview,
          subtitle: this.linkDescriptionPreview,
          link: this.linkLinkPreview
        }]
        this.backendAPIContentService.createLinkItems(this.linkInfo[0])
      }
    }
  }


  onImageSelection(event) {
    this.linkImageForReview = event
  }

  logEventInParent(event) {

    this.selectedImage = event.rawImage
    this.imageForPreviewReview = event.selectedImage
    this.imageInfo[0].imgPath = this.imageForPreviewReview

    this.videoInfo[0].videoPath = this.videoForPreviewReview
  }


  captureVideoInfoEvent(event) {

    // const leftSide = '<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="'
    // const rightSide = '"></iframe><p><br></p>'
    // const showInfo = '?showinfo=0'
    // let showInfoRemoved = rightSideRemoved.replace(showInfo, '')


    // TODO DAVE CAN FIX THIS HIMSELF, IT WILL BE SOME DICKING AROUND
    // const leftSide = '<p><iframe src="'
    // const rightSide = '" width="560" height="314" allowfullscreen="allowfullscreen"></iframe></p>'

    // let htmlResult = event

    // let leftSideRemoved = htmlResult.replace(leftSide, '')
    // let rightSideRemoved = leftSideRemoved.replace(rightSide, '')

    this.videoInfo[0].videoPath = event




  }

  taggedUserId1
  taggedUserTitle1
  setUserTag(event) {
    switch (this.type) {
      case 'image':
        this.taggedUserId1 = event.userId
        this.taggedUserTitle1 = event.userTitle
        break
      case 'video':
        this.taggedUserId1 = event.userId
        this.taggedUserTitle1 = event.userTitle
        break
    }
  }

  setGameTag(event) {
    switch (this.type) {
      case 'image':
        this.imageInfo[0].tags = event.gameId
        this.imageInfo[0].gameTitle = event.gameTitle
        break

      case 'video':
        this.videoInfo[0].tags = event.gameId
        this.videoInfo[0].gameTitle = event.gameTitle
        break
    }
  }

  havenId = undefined
  havenTitle
  setHavenTag(event) {
    switch (this.type) {
      case 'image':
        this.havenId = event.havenId
        this.havenTitle = event.havenTitle
        break
      case 'video':
        this.havenId = event.havenId
        this.havenTitle = event.havenTitle
        break
    }
  }

  groupId = undefined
  groupTitle
  setGroupTag(event) {

    switch (this.type) {
      case 'image':
        this.groupId = event.groupId
        this.groupTitle = event.groupTitle
        break
      case 'video':
        this.groupId = event.groupId
        this.groupTitle = event.groupTitle
        break
    }
  }



  eventId = undefined
  eventTitle
  setEventTag(event) {

    switch (this.type) {
      case 'image':
        this.eventId = event.eventId
        this.eventTitle = event.eventTitle
        break
      case 'video':
        this.eventId = event.eventId
        this.eventTitle = event.eventTitle
        break
    }
  }


  onSecondLinkInfoChange(event) {
    this.linkTitlePreview = event.title
    this.linkDescriptionPreview = event.description
    this.linkLinkPreview = event.link
  }

  followLink() {
    let linkClean = 'https://' + this.linkLinkPreview + '/'
    window.open(linkClean, '_blank')
  }

  advancedLogPlayData
  advancedLPvalueChanged(event) {
    this.advancedLogPlayData = event
  }

  articleData = {
    title: null,
    editor: null,
    mainImageFiles: null,
    intro: null,
    addedByUID: null
  }
  articleValueChanged(event) {

    if (event.title) {
      this.articleData.title = event.title
      this.articleData.addedByUID = this.user.username
    }
    if (event.editor) {
      this.articleData.editor = event.editor
      this.articleData.addedByUID = this.user.username
    }
    if (event.intro) {
      this.articleData.intro = event.intro
      this.articleData.addedByUID = this.user.username
    }
  }

  setArticleImage(event) {
    this.articleData.mainImageFiles = [event.selectedImage]
  }

  currentStep
  onStepChange(event) {
    this.currentStep = event.selectedStep.label
  }

  onArticleFormChange(event) {
    this.articleData.addedByUID = this.user.username
  }


  reviewData
  reviewDataShapedForPreview
  onReviewFormChange(event) {

    let reviewDataReshaped = {
      addedByUID: this.user.username,
      big8: [
        event.chanceRating,
        event.competitionRating,
        event.complexityRating,
        event.depthRating,
        event.durationRating,
        event.interactionRating,
        event.playersRating,
        event.thematicRating,
      ],
      createdAt: '',
      editor: event.editor,

      gameToReview: event.gameToReview,

      havenToReview: null,
      id: '',
      title: event.title,
      intro: event.intro,
      updatedAt: ''
    }

    this.reviewData = reviewDataReshaped

    this.reviewDataShapedForPreview = {
      gameToReview: this.shareReviewModalVC && this.shareReviewModalVC.shareReviewVC.selectedGame ? this.shareReviewModalVC.shareReviewVC.selectedGame.title : null,
      havenToReview: this.shareReviewModalVC && this.shareReviewModalVC.shareReviewVC.selectedHaven ? this.shareReviewModalVC.shareReviewVC.selectedHaven.title : null, //! ???? working ?
      title: event.form.title,
      rating: +event.rating,
      updatedAt: '', // ! pass in current datetime?
      reviewAuthor: this.user.title,
      reviewImage: this.reviewPreviewImage,
      textOnlyVersionOfReview: event.textOnlyVersionOfReview
    }

  }

  reviewPreviewImage
  onReviewImageChange(event) {
    this.reviewData.reviewImage = event
    this.reviewPreviewImage = event.selectedImage
  }

  updateAllData() {
    switch (this.type) {
      case 'image':
        this.imageInfo[0].title = this.imageForm.get('imageTitle').value
        this.imageInfo[0].subtitle = this.imageForm.get('imageCaption').value
        break

      case 'video':
        this.videoInfo[0].title = this.videoForm.get('videoTitle').value
        this.videoInfo[0].subtitle = this.videoForm.get('videoCaption').value
        break

      case 'link':
        break

      case 'review':
        break
    }
  }
}

// todo This can be reworked for multiple users, future feature.
// todo this is also where PERMISSIONS would be, future feature.

// if (this.taggedUserId1 && this.taggedUserTitle1) {
//   let createItemsInput2 = {
//     userLevelWhoAdded: undefined,
//     paths: [carl],
//     addedByUID: this.taggedUserId1,
//     gameId: this.imageInfo[0].tags,
//     gameTitle: this.imageInfo[0].gameTitle,
//     title: this.imageForm.get('imageTitle').value,
//     subtitle: this.imageForm.get('imageCaption').value,

//     //! added this
//     createdByUID: this.user.username,
//     createdByTitle: this.user.title
//   }
//   this.backendAPIContentService.createImageItems(createItemsInput2)
// }
