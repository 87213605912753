import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { ChartDataset, ChartOptions, ChartType } from 'chart.js'
// import { BaseChartDirective, Label } from 'ng2-charts'
import * as pluginDataLabels from 'chartjs-plugin-datalabels'

@Component({
  selector: 'app-actual-duration',
  templateUrl: './actual-duration.component.html',
  styleUrls: ['./actual-duration.component.scss']
})
export class actualDurationComponent implements OnInit {


  // @ViewChild(BaseChartDirective) chart: BaseChartDirective

  @Output() closeEmitter = new EventEmitter()

  usersOpinion = []

  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.

    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },

    hover: {
      mode: null
    }
  }

  barChartLabels =  ['1 Player', '2 Players', '3 Players', '4 Players', '5 Players', '6 Players', '7 Players', '8+ Players']
  barChartType: ChartType = 'bar'
  barChartPlugins = [pluginDataLabels]

  barChartData: ChartDataset[] = [
    
    { 
      data: [30, 35, 40, 49, 52, 90, 0, 0],
      label: 'Yes', 
      backgroundColor: '#116466' ,
    }

  ]

  constructor() { }

  ngOnInit(): void {
  }

}
