import { Injectable } from '@angular/core';
import { ArrayHelperService } from './array-helper.service';
import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { uploadImageFile } from 'src/app/backend-api-services/s3-file-uploader';
import { environment } from 'src/environments/environment';
import { ImageMultiSizeService } from './image-multi-size.service';

@Injectable({
  providedIn: 'root'
})
export class S3ContentHelperService {

  constructor(private arrayHelperService: ArrayHelperService,
    private imageMultiSizeService: ImageMultiSizeService) { }

  private appSyncClient = new AWSAppSyncClient({
    url: environment.apiUrl,
    region: 'us-east-1',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        return token
      }
    },
    disableOffline: true,
  })

  async prepareMainImageFiles(files) {
    let result = null
    let isStringArray = this.arrayHelperService.checkIfIsArrayOfTypeT(files, 'string')
    if (isStringArray) { // not foolproof, but close...
      result = files
    }
    else {
      if (files && files != null && files[0] != undefined && !isStringArray) {
        const extension = files[0].name.split('.').pop()
        const s3ObjectUrl = await uploadImageFile(files[0], extension)
        const correctedUrl = s3ObjectUrl.replace('https://gamehaven-api-dev-appdatabucket-d1uqdueie9h5.s3-accelerate.amazonaws.com/', 'https://gh-img.s3.amazonaws.com/')
        result = this.imageMultiSizeService.createArrayForAllSizes(correctedUrl.replace('.jpg', '').replace('.jpeg', ''))
      }
    }
    return result
  }

}
