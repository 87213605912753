import { Injectable } from '@angular/core'
import { EVENTS_BANNER_ADS, GROUPS_BANNER_ADS, POPULAR_AMAZON_AD_ARRAY, SIDEBAR_BANNER_ADS } from 'src/app/dashboard/constants/mock-data'
import { BannerLocationEnums } from '../../enums/banner-location-enums'

@Injectable({
  providedIn: 'root'
})
export class BannerAdService {

  sidebarBannerAds = SIDEBAR_BANNER_ADS
  eventsBannerAds = EVENTS_BANNER_ADS
  groupsBannerAds = GROUPS_BANNER_ADS
  amazonBannerAds = POPULAR_AMAZON_AD_ARRAY

  constructor() { }

  getBannerAds(bannerLocation: BannerLocationEnums) {
    switch (bannerLocation) {

      case BannerLocationEnums.sidebar:
        return this.sidebarBannerAds

      case BannerLocationEnums.events:
        return this.eventsBannerAds

      case BannerLocationEnums.groups:
        return this.groupsBannerAds
    }
  }
}
