export class StringHelper {

  //* if items are objects, this algorithm will refer to the "title" attribute
  static createCommaSeparatedString(list: any[], itemsAreObjects: boolean): string {
    var result: string = ''
    for (var i = 0; i < list.length; i++) {
      if (i == list.length - 1) {
        result = itemsAreObjects ? result.concat(list[i].title) : result.concat(list[i])
      }
      else {
        result = itemsAreObjects ? result.concat(list[i].title).concat(', ') : result.concat(list[i]).concat(', ')
      }
    }
    return result
  }

  static getInitials(input: string) {
    var initials: any = input.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  static getRandomString(length?: number) {
    let strLength = length ? length : 15
    // substring starting at 2 is neccesary to avoid decimals
    return Math.random().toString(36).substring(2, strLength) + Math.random().toString(36).substring(2, strLength)
  }

  static truncateString(input: string, maxCharacters: number) {
    return input.length > maxCharacters ? input.substring(0, maxCharacters) + '...' : input
  }

  // Paul suggestion: Don't include DOM-specific functions in the `common` folder as DOM-specific code should be in the frontend folder.
  static extractSrcFromIframe(input: string) {
    var tmp = document.createElement('div')
    tmp.innerHTML = input
    var src = tmp.querySelector('iframe')?.getAttribute('src')
    return src
  }
}
