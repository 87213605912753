import { Component, OnInit, Input } from '@angular/core'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'

@Component({
  selector: 'app-ta-publisher-description',
  templateUrl: './ta-publisher-description.component.html',
  styleUrls: ['./ta-publisher-description.component.scss']
})
export class TaPublisherDescriptionComponent implements OnInit {

  gameId

  publisherDescription
  publishers = []

  @Input() set _data(data) {
    if (data) {
      this.gameId = data.gameId
      this.fetchData()
    }
  }

  async fetchData() {
    let descriptoinResult = await this.backendApiGamesService.GetPublisherDescription(this.gameId)
    this.publisherDescription = descriptoinResult.publisherDescription

    let publishersResult = this.backendApiGamesService.GetPublishers(this.gameId)
    // this.publishers = publishersResult
  }

  constructor(
    private backendApiGamesService: BackendAPIGamesService
  ) { }

  ngOnInit() {

  }

}
