import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core'
import { ChartOptions, ChartType, ChartDataset } from 'chart.js'
import * as pluginDataLabels from 'chartjs-plugin-datalabels'
import { IconSnackbarComponent } from 'src/app/dashboard/dashboard-shared/components/custom-snackbars/icon-snackbar/icon-snackbar.component'
import { SoundEffectsService } from 'src/app/dashboard/dashboard-shared/services/audio/sound-effects.service'
import { BaseChartDirective } from 'ng2-charts'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatDialogRef } from '@angular/material/dialog'


@Component({
  selector: 'app-community-votes',
  templateUrl: './community-votes.component.html',
  styleUrls: ['./community-votes.component.scss']
})
export class CommunityVotesComponent implements OnInit {

  initialData = [0, 0, 0, 0, 0, 0, 0, 0] // todo this will come from the database

  readonly initialDataConst = this.initialData

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  @Input() set _inputData(inputData) {
    this.barChartData[0].data = inputData
    this.chart.update()
  }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective

  usersOpinion = []

  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    // scales: { x: [{}], y [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    },
    // tooltips: {
    //   enabled: false
    // },
    hover: {
      mode: null
    }
  }

  barChartLabels = ['1 Player', '2 Players', '3 Players', '4 Players', '5 Players', '6 Players', '7 Players', '8+ Players']
  barChartType: ChartType = 'bar'
  barChartPlugins = [pluginDataLabels]

  barChartData: ChartDataset[] = [
    { data: this.initialData, label: 'Yes', backgroundColor: '#116466' }, // gh-color-primary
  ]

  fullOpinions: boolean[] = []

  constructor(private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<CommunityVotesComponent>,
    private soundEffectsService: SoundEffectsService) { }

  ngOnInit(): void { }

  onIdealPlayersChanged(event) {
    this.usersOpinion = event.sort()

    this.fullOpinions = []

    for (var i = 1; i <= 8; i++) {
      if (this.usersOpinion.includes(i)) {
        this.fullOpinions.push(true)
      }
      else {
        this.fullOpinions.push(false)
      }
    }

    let initialDataStringified = JSON.stringify(this.initialDataConst)

    let barChartDataAsAny: any = JSON.parse(initialDataStringified)
    for (var i = 0; i < barChartDataAsAny.length; i++) {
      if (this.fullOpinions[i]) {
        barChartDataAsAny[i]++
      }
    }

    this.barChartData[0].data = barChartDataAsAny
    this.chart.update()
  }

  submit() {
    this.dialogRef.close({
      submitted: true,
      idealPlayerVotes: this.barChartData[0].data,
      userVotes: this.fullOpinions
    })
  }

}
