<mat-tab-group mat-stretch-tabs (selectedTabChange)="onTabChange($event)" *ngIf="tabs">
    <mat-tab>
        <ng-template mat-tab-label>
            {{tabs[0]}}
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            {{tabs[1]}}
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            {{tabs[2]}}
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            {{tabs[3]}}
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            {{tabs[4]}}
        </ng-template>
    </mat-tab>
</mat-tab-group>


<div class="tab-content" [ngClass]=" { hidden: tabContentIndex != 0 }">
    <ng-template #viewContainer0></ng-template>
</div>

<div class="tab-content" [ngClass]=" { hidden: tabContentIndex != 1 }">
    <ng-template #viewContainer1></ng-template>
</div>

<div class="tab-content" [ngClass]=" { hidden: tabContentIndex != 2 }">
    <ng-template #viewContainer2></ng-template>
</div>

<div class="tab-content" [ngClass]=" { hidden: tabContentIndex != 3 }">
    <ng-template #viewContainer3></ng-template>
</div>

<div class="tab-content" [ngClass]=" { hidden: tabContentIndex != 4 }">
    <ng-template #viewContainer4></ng-template>
</div>