import { GamePrintStatusEnums } from "../../../../../../common/interfaces/GameInterfaces"
import { CrowdfundingSiteOptionEnums } from "../enums/crowdfunding-enums"

export const MAX_USERS_FOR_FIND_BEST_GAMES_THING = 4

export const competitionTags = [
    { key: 'solo', title: 'Solo' },
    { key: 'cooperative', title: 'Cooperative' },
    { key: 'teams', title: 'Teams' },
    { key: 'semiCooperative', title: 'Semi-Cooperative' },
    { key: 'competitive', title: 'Competitive' },
]

export const mechanicTags = [
    { key: 'deckBuilding', title: 'Deck Building' },
    { key: 'setCollection', title: 'Set Collection' },
    { key: 'areaControl', title: 'Area Control' },
    { key: 'workerPlacement', title: 'Worker Placement' },
    { key: 'tilePlacement', title: 'Tile Placement' },
    { key: 'trickTaking', title: 'Trick Taking' },
    { key: 'drafting', title: 'Drafting' },
    { key: 'legacy', title: 'Legacy' },
    { key: 'realTime', title: 'Real-Time' },
    { key: 'hiddenObjectives', title: 'Hidden Objectives' },
    { key: 'hiddenMovement_info', title: 'Hidden Movement / Info' },
    { key: 'roll_spinAndMove', title: 'Roll / Spin and Move' },
    { key: 'engineBuilding', title: 'Engine Building' },
    { key: 'handManagement', title: 'Hand Management' },
    { key: 'diceRolling', title: 'Dice Rolling' },
    { key: 'rolePlaying', title: 'Role Playing' }
]

export const settingTags = [
    { key: 'fantasy', title: 'Fantasy' },
    { key: 'sciFi', title: 'Sci-fi' },
    { key: 'historical', title: 'Historical' },
    { key: 'dystopia', title: 'Dystopia' },
    { key: 'contemporary', title: 'Contemporary' },
    { key: 'horror', title: 'Horror' },
    { key: 'space', title: 'Space' },
    { key: 'medieval', title: 'Medieval' },
    { key: 'western', title: 'Western' },
    { key: 'steam_victorian', title: 'Steam / Victorian' }
]


export const uncertaintyTags = [
    { key: 'dice', title: 'Dice' },
    { key: 'card', title: 'Card' },
    { key: 'hiddenRole', title: 'Hidden Role' },
    { key: 'pressYourLuck', title: 'Press Your Luck' },
    { key: 'electronic', title: 'Electronic' },
    { key: 'tile', title: 'Tile' },
]


export const interactionTags = [
    { key: 'bluffing', title: 'Bluffing' },
    { key: 'trading', title: 'Trading' },
    { key: 'rolePLaying', title: 'Role PLaying' },
    { key: 'storytelling', title: 'Storytelling' },
    { key: 'combat', title: 'Combat' },
    { key: 'auction_bidding', title: 'Auction / Bidding' },
    { key: 'takeThat', title: 'Take That' },
    { key: 'betting', title: 'Betting' },
    { key: 'racing', title: 'Racing' },
    { key: 'simultaneousActions', title: 'Simultaneous Actions' },
    { key: 'voting', title: 'Voting' },
    { key: 'rockPaperScissors', title: 'Rock Paper Scissors' },
    { key: 'deception', title: 'Deception' },
]


export const atmosphereTags = [
    { key: 'thematic', title: 'Thematic' },
    { key: 'abstract', title: 'Abstract' },
    { key: 'adventure', title: 'Adventure' },
    { key: 'economic', title: 'Economic' },
    { key: 'educational', title: 'Educational' },
    { key: 'humor', title: 'Humor' },
    { key: 'party', title: 'Party' },
    { key: 'social', title: 'Social' },
    { key: 'family', title: 'Family' },
    { key: 'war', title: 'War' },
    { key: 'puzzle', title: 'Puzzle' },
    { key: 'adult', title: 'Adult' },
]


export const skillTags = [
    { key: 'trivia', title: 'Trivia' },
    { key: 'acting', title: 'Acting' },
    { key: 'memory', title: 'Memory' },
    { key: 'deduction', title: 'Deduction' },
    { key: 'dexterity', title: 'Dexterity' },
    { key: 'patternRecognition', title: 'Pattern Recognition' },
    { key: 'programming', title: 'Programming' },
    { key: 'planning', title: 'Planning' },
    { key: 'luck', title: 'Luck' },
    { key: 'influence', title: 'Influence' },
    { key: 'persuasion', title: 'Persuasion' },
]

export const accessibilityTags = [
    { key: 'colorBlindFriendly', title: 'Color Blind Friendly' },
    { key: 'lowVisionFriendly', title: 'Low Vision Friendly' },
    { key: 'noVisionNeeded', title: 'No Vision Needed' },
    { key: 'lowOrNoReadingNecessary', title: 'Low / No Reading Necessary' },
    { key: 'nonVerbal', title: 'Non-verbal' },
    { key: 'noHearingNeeded', title: 'No Hearing Needed' },
    { key: 'lowOrNoDexterityNecessary', title: 'Low / No Dexterity Necessary' },
]

export const playTimes = [
    { name: '5 Minutes', value: 5 },
    { name: '10 Minutes', value: 10 },
    { name: '15 Minutes', value: 15 },
    { name: '20 Minutes', value: 20 },
    { name: '30 Minutes', value: 30 },
    { name: '45 Minutes', value: 45 },
    { name: '60 Minutes', value: 60 },
    { name: '75 Minutes', value: 75 },
    { name: '90 Minutes', value: 90 },
    { name: '120 Minutes', value: 120 },
    { name: '180 Minutes', value: 180 },
    { name: '240+ Minutes', value: 240 },
    { name: 'Unknown / Clear', value: undefined }

]

export const minAgeOptions = [
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
    { name: '6', value: 6 },
    { name: '7', value: 7 },
    { name: '8', value: 8 },
    { name: '9', value: 9 },
    { name: '10', value: 10 },
    { name: '11', value: 11 },
    { name: '12', value: 12 },
    { name: '13', value: 13 },
    { name: '14', value: 14 },
    { name: '15', value: 15 },
    { name: '16', value: 16 },
    { name: '17', value: 17 },
    { name: '18+', value: 18 },
    { name: 'Unknown / Clear', value: undefined }
]

export const playerCountOptions = [
    { name: "1", value: 1 },
    { name: "2", value: 2 },
    { name: "3", value: 3 },
    { name: "4", value: 4 },
    { name: "5", value: 5 },
    { name: "6", value: 6 },
    { name: "7", value: 7 },
    { name: "8+", value: 8 },
    { name: 'Unknown / Clear', value: undefined }

]

export const printStatusOptions = [
    { name: GamePrintStatusEnums.inDevelopement, value: GamePrintStatusEnums.inDevelopement },
    { name: GamePrintStatusEnums.available, value: GamePrintStatusEnums.available },
    { name: GamePrintStatusEnums.outOfPrint, value: GamePrintStatusEnums.outOfPrint },
    { name: GamePrintStatusEnums.na, value: undefined }
]

export const crowdfundingSiteOptions = [
    CrowdfundingSiteOptionEnums.kickstarter,
    CrowdfundingSiteOptionEnums.gamefound,
    CrowdfundingSiteOptionEnums.other

]

export const gameFuseOptions = {
    threshold: 0.35, // the lower the number the better match needed, 0-1
    keys: ["t"] // "t" rather than "title" since it is shortened in the core game data list
}

export const defaultRatingConst = 5
//! Being set here