
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MessageTableType } from 'src/app/dashboard/dashboard-shared/enums/message-type-enums'
import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-calendar-management',
  templateUrl: './calendar-management.component.html',
  styleUrls: ['./calendar-management.component.scss']
}) 
export class CalendarManagementComponent implements OnInit {
  coreUsers = new BehaviorSubject(null)
  coreUsers$ = this.coreUsers.asObservable()

  user
  type = 'invites'

  incomingInvitesDataSource = new MatTableDataSource<any>()
  outgoingInvitesDataSource = new MatTableDataSource<any>()

  // INVITES
  displayedColumnsIncomingEventInvites = ['avatar', 'from', 'date', 'title', 'haven', 'organizer', 'delete', 'confirm']
  displayedColumnsOutgoingEventInvites = ['avatar', 'to', 'date', 'title', 'haven', 'organizer', 'delete']

  hasConfirmationButtons = true
  hasDeleteButtons = true // todo need to bring it the conditional based on wether this is the current user

  sendMessageMessage
  sendToUserId

  constructor(
    private backendApiMessageService: BackendAPIMessageService,
    private backendAPIUsersService: BackendAPIUsersService,
    private dialog: MatDialog,
    private screenSizeService: ScreenSizeService,
    private usersService: UsersService,
  ) { }

  isMobileScreen = false

  doneLoading = false

  async ngOnInit(): Promise<void> {
    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })

    this.backendAPIUsersService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe(async (user) => {
        this.user = user

        this.usersService.coreUsers$
          .pipe(untilDestroyed(this))
          .subscribe(async (coreUsersResult) => {
            this.coreUsers.next(coreUsersResult)
          })
      })

    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user
    })
    await this.fetchInvites()
    this.doneLoading = true
  }


  async fetchInvites() {
    let invitesRes = await this.backendApiMessageService.listMyInvites(this.user.username, 20)
    this.incomingInvitesDataSource = invitesRes.invites.filter(
      (item) => item.sentFromTitle != this.user.title,
    )
    this.outgoingInvitesDataSource = invitesRes.invites.filter(
      (item) => item.sentFromTitle == this.user.title,
    )
  }

}
