import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ChartConfiguration, ChartType } from 'chart.js'
import { BaseChartDirective } from 'ng2-charts'
import {
    RadarChartTypeEnums,
    RadarChartOverlayTypeEnums,
} from 'src/app/dashboard/enums/radar-chart-enums'
import { GhDialogWrapperComponent } from '../../../gh-dialog-wrapper/gh-dialog-wrapper.component'
import { GhGameRadarChartComponent } from '../gh-game-radar-chart/gh-game-radar-chart.component'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-gh-game-radar-chart-mini',
    templateUrl: './gh-game-radar-chart-mini.component.html',
    styleUrls: ['./gh-game-radar-chart-mini.component.scss'],
})
export class GhGameRadarChartMiniComponent implements OnInit {
    matchPercentage
    chartType: ChartType = 'radar' // determines the chart type
    radarChartData: any
    attributes = []
    attributesX10 = []
    overlayAttributes = []
    overlayAttributesX10 = []
    attributesAreKnown = false
    numberOfVotes

    miniRadarData = {
        attributes: [5, 5, 5, 5, 5, 5, 5, 5],
        labels: [
            'Complexity',
            'Depth',
            'Duration',
            'Interaction',
            'Competition',
            'Players',
            'Chance',
            'Theme',
        ],
        overlayAttributes: [],
        numberOfVotes: 0
    }

    @ViewChild(BaseChartDirective) chart: BaseChartDirective

    @Input() set _miniRadarData(miniRadarData) {

        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
        })

        this.fetchUsersBig8Votes() // todo this ishappening before the user is initiatied.

        this.numberOfVotes = miniRadarData.numberOfVotes


        if (miniRadarData && miniRadarData.attributes != null && miniRadarData.attributes != '') {
            this.attributesAreKnown = true
            this.clearArrays()

            this.miniRadarData = miniRadarData

            if (miniRadarData.attributes[0] == -1) {
                // trigger this.atributesAreUnknown here?
                // this will depend on if we decide on default of -1 or 5
            }

            if (miniRadarData.attributes && miniRadarData.attributes.length > 0) {
                this.attributes = miniRadarData.attributes
            }

            if (miniRadarData.overlayAttributes) {
                for (let overlayAttribute of miniRadarData.overlayAttributes) {
                    this.overlayAttributes.push(overlayAttribute)
                    this.overlayAttributesX10.push(overlayAttribute * 10)
                }
            }

            try {
                this.fetchUsersBig8Votes() // todo this ishappening before the user is initiatied.
            } catch (error) {
                console.log('ERROR: ', error)
            }

            // if not data (attributes are -1), then dont do this, show not enough data instead... right???
            // this.matchPercentage = this.bensMathService.calculateMatchPrecent(this.overlayAttributes, this.attributes)

            this.radarChartLabels = miniRadarData.labels
            this.radarChartData = [
                {
                    data: this.attributes,
                    backgroundColor: 'rgba(17, 100, 102, 0.6)', // gh-color-primary
                    borderColor: 'rgba(rgba(17, 100, 102, 1)', // gh-color-primary
                    pointBorderColor: 'rgba(rgba(17, 100, 102, 1)', // gh-color-primary
                    pointBackgroundColor: 'rgba(255, 255, 255, 1)', // white
                    borderWidth: 1, // modifying this changes the chart
                },
                {
                    data: this.overlayAttributes,
                    backgroundColor: 'rgba(rgba(0, 0, 0, 1)', // gh-color-primary
                    borderColor: 'rgba(rgba(0, 0, 0, 1)', // gh-color-primary
                    pointBorderColor: 'rgba(rgba(0, 0, 0, 1)', // gh-color-primary
                    pointBackgroundColor: 'rgba(rgba(0, 0, 0, 1)', // white
                    borderWidth: 1, // modifying this changes the chart
                },
            ]
        } else {
            this.attributesAreKnown = false
            this.clearArrays()
        }
    }

    type
    @Input() set _type(type: RadarChartTypeEnums) {
        this.type = type
    }

    selectedItem
    @Input() set _selectedItem(selectedItem) {
        this.selectedItem = selectedItem
    }

    showMatchPercentage
    @Input() set _showMatchPercentage(showMatchPercentage: boolean) {
        this.showMatchPercentage = showMatchPercentage
    }

    isCurrentUsersProfile = true
    @Input() set _isCurrentUsersProfile(isCurrentUsersProfile: boolean) {
        this.isCurrentUsersProfile = isCurrentUsersProfile
    }

    overlayType: RadarChartOverlayTypeEnums
    @Input() set _overlayType(overlayType: RadarChartOverlayTypeEnums) {
        this.overlayType = overlayType
    }

    editButtonShows = true
    @Input() set _editButtonShows(editButtonShows: boolean) {
        this.editButtonShows = editButtonShows
    }

    uniqueAttribute = 'id'
    @Input() set _uniqueAttribute(uniqueAttribute) {
        this.uniqueAttribute = uniqueAttribute
    }

    @Output() radarChartResultEmitter = new EventEmitter()

    radarChartLabels: string[]

    radarChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
            r: {
                min: 0,
                max: 10,
                beginAtZero: true,
                angleLines: {
                    color: 'rgba(245, 121, 58,0.6)',
                },
                pointLabels: {
                    font: {
                        size: 12,
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    }

    constructor(
        private dialog: MatDialog,
        private backendAPIUsersService: BackendAPIUsersService,
        private screenSizeService: ScreenSizeService,

        private backendApiGameTrackingService: BackendApiGameTrackingService,
    ) { }

    user
    isMobileScreen = false

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
        })
    }

    openDialog() {
        let inputData = {
            radarChartType: this.type,
            attributes: this.attributes,
            overlayAttributes: this.overlayAttributes,
            labels: this.miniRadarData.labels,

            numberOfVotes: this.miniRadarData.numberOfVotes,
            isCurrentUsersProfile: this.isCurrentUsersProfile,

            overlayType: this.overlayType,
            selectedItem: this.selectedItem,
            uniqueAttribute: this.uniqueAttribute,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            // starting to work on mobile dialog size (or maybe should rather route to page of its own?)
            width: '95%',
            height: '95%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: this.selectedItem.title + '\'s Attributes',
                component: GhGameRadarChartComponent,
                inputData: inputData,
                hasSubmitButton: this.isCurrentUsersProfile,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
            },
        })

        dialogRef.afterClosed().subscribe((data) => {
            this.radarChartResultEmitter.emit(data)
        })
    }

    userHasRated
    previousUserBig8Votes
    filteredArray
    async fetchUsersBig8Votes() {
        try {
            this.previousUserBig8Votes =
                await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(
                    this.user.username,
                    '#big8Vote#',
                    490,
                )

            let target = this.selectedItem.id
            let array = this.previousUserBig8Votes.games

            let filteredArrayfetched = array.filter((object) => object.id === target)

            this.filteredArray = filteredArrayfetched

            if (filteredArrayfetched.length == 1) {
                this.userHasRated = true
            } else {
                this.userHasRated = false
            }
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    castVoteForBig8() {
        let inputData = {
            isEditing: false,
            isCurrentUsersProfile: this.isCurrentUsersProfile,
            radarChartType: this.type,
            attributes: [5, 5, 5, 5, 5, 5, 5, 5],
            selectedItem: this.selectedItem,
            overlayAttributes: undefined,
            labels: this.miniRadarData.labels,
            overlayType: this.overlayType,
            uniqueAttribute: this.uniqueAttribute,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            // starting to work on mobile dialog size (or maybe should rather route to page of its own?)
            width: '95%',
            height: '95%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: this.selectedItem.title + ' Attributes',
                component: GhGameRadarChartComponent,
                inputData: inputData,
                hasSubmitButton: this.isCurrentUsersProfile,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
            },
        })

        dialogRef.afterClosed().subscribe((data) => {
            this.radarChartResultEmitter.emit(data)
        })
    }

    editVoteForBig8() {
        let inputData = {
            isEditing: true,
            radarChartType: this.type,
            attributes: this.filteredArray[0].big8Vote,
            isCurrentUsersProfile: this.isCurrentUsersProfile,
            selectedItem: this.selectedItem,
            //...
            overlayAttributes: undefined,
            labels: this.miniRadarData.labels,
            overlayType: this.overlayType,
            uniqueAttribute: this.uniqueAttribute,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            width: '95%',
            height: '95%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: this.selectedItem.title + ' Attributes',
                component: GhGameRadarChartComponent,
                inputData: inputData,
                hasSubmitButton: this.isCurrentUsersProfile,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
            },
        })

        dialogRef.afterClosed().subscribe((data) => {
            this.radarChartResultEmitter.emit(data)
        })
    }

    openUnknownAttributesDialog() {
        alert("We don't have enough data for this user yet")
    }

    clearArrays() {
        this.attributes = []
        this.attributesX10 = []
        this.overlayAttributes = []
        this.overlayAttributesX10 = []
    }
}
