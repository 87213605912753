<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <div>
                    <img src="../../assets/images/coolStuff/D20-clipart-transparent-png-5.png" height="200px">
                </div>
                <h1>
                    Ope!</h1>
                <h2 class="mt-3">
                    404 Page Not Found</h2>
                <div class="error-details mt-3">
                    Somebody made a mistake, most likely US.
                </div>
                <div class="error-details mt-3">
                    Cripes, we sure are sorry...
                </div>
                <div class="error-actions">
                    <!-- <a href="#" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span> Take Me Home </a> -->
                    <!-- <a (click)="openAd()" class="btn btn-default btn-lg"><span class="glyphicon glyphicon-envelope"  mat-button></span> Contact Support </a> -->
                    <button mat-button class="gh-button gh-button-primary" (click)="openAd()">Contact Support</button>

                </div>
            </div>
        </div>
    </div>
</div>