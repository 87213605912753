import { Component, OnInit, ViewChild } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-friends-and-followers',
    templateUrl: './friends-and-followers.component.html',
    styleUrls: ['./friends-and-followers.component.scss'],
})
export class FriendsAndFollowersComponent implements OnInit {
    coreUsers = new BehaviorSubject(null)
    coreUsers$ = this.coreUsers.asObservable()

    user
    type = 'requests'

    incomingRequests = new MatTableDataSource<any>()
    outgoingRequests = new MatTableDataSource<any>()

    // FRIEND REQUESTS
    displayedColumnsIncomingFriendRequests = [
        'avatar',
        'sentFromTitle',
        'createdAt',
        'delete',
        'confirm',
    ]
    displayedColumnsOutgoingFriendRequests = [
        'avatar',
        'sentToTitle',
        'sentFromTitle',
        'createdAt',
        'delete',
    ]

    hasDeleteButtons = true // todo need to bring it the conditional based on wether this is the current user
    hasArchiveButtons = true // todo need to bring it the conditional based on wether this is the current user
    hasConfirmationButtons = false

    constructor(
        private backendApiMessageService: BackendAPIMessageService,
        private backendAPIUsersService: BackendAPIUsersService,
        private dialog: MatDialog,
        private screenSizeService: ScreenSizeService,
        private usersService: UsersService,
    ) {}

    isMobileScreen = false

    async ngOnInit(): Promise<void> {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe(async (user) => {
                this.user = user

                this.usersService.coreUsers$
                    .pipe(untilDestroyed(this))
                    .subscribe(async (coreUsersResult) => {
                        this.coreUsers.next(coreUsersResult)
                    })
            })

        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
        })
        this.fetchRequests()

        this.executeWithDelay()
    }

    executeWithDelay() {
        setTimeout(() => {
            this.fetchRequests()
        }, 4000) // 4000 milliseconds = 4 seconds
    }

    async fetchRequests() {
        let requestsRes = await this.backendApiMessageService.listMyFriendRequests(
            this.user.username,
            20,
        )

        this.incomingRequests = requestsRes.requests.filter(
            (item) => item.createdBy !== this.user.username,
        )

        this.outgoingRequests = requestsRes.requests.filter(
            (item) => item.createdBy === this.user.username,
        )
    }
}
