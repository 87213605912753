import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class FileTypeConversionService {

  constructor() { }

  async convertCSVtoJSON(csvFileBody) {
    let csvAsAny: any = csvFileBody

    //! line below was used before when returned result was in a different format, but not needed anymore since it is returned as a string now.
    //! leaving here for future reference just in case
    // var decodedAsString = new TextDecoder("utf-8").decode(csvAsAny)

    var lines = csvAsAny.split("\n")

    var result = []

    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step 
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers = lines[0].split(",")

    for (var i = 1; i < lines.length; i++) {
      var obj = {}
      var currentline = lines[i].split(",")
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j]
      }
      result.push(obj)
    }

    return result
  }
}
