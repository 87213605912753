<form [formGroup]="form">
    <mat-form-field class="gh-form-field">
        <mat-label>Enter valid Zip Code</mat-label>
        <input matInput formControlName="firstCharacters" (input)="onInputChange($event)">
    </mat-form-field>


    <div *ngIf="narrowedDownList">
        <mat-form-field class="gh-form-field">
            <mat-label>Confirm Zip Code</mat-label>
            <mat-select [compareWith]="compareFunction" (selectionChange)="selectZipcode($event)"
                formControlName="zipCodeSelection">
                <mat-option *ngFor="let zip of narrowedDownList" [value]="zip">
                    {{zip}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</form>