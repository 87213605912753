<div class="container-fluid">
	<div class="row">
		<mat-toolbar> 
			<span class="span">
				I want to play: 
			</span>
		</mat-toolbar>
	</div>
	<div class="row">
		<div class="col-md-6 wrapper">
            <img src="https://gh-img.s3.amazonaws.com/G/agricola.jpg">
		</div>
		<div class="col-md-6 wrapper">
            <img src="https://gh-img.s3.amazonaws.com/G/blokus.jpg">
		</div>
	</div>
	<div class="row">
		<div class="col-md-6 wrapper">
            <img src="https://gh-img.s3.amazonaws.com/G/axis_and_allies.jpg">
		</div>
		<div class="col-md-6 wrapper">
            <img src="https://gh-img.s3.amazonaws.com/G/betrayal.jpg">
		</div>
    </div>

</div>