<div class="parent-container" *ngIf="selectedHopperItem">

    <div class="row">
        <div class="col-12">
            <button mat-raised-button class="gh-button gh-button-primary" (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <!-- NOTE: no mat-eleavation since styling manually to provide matching color to selected table row -->
    <mat-card class="mt-3">

        <form [formGroup]="form">
            <div class="row section">
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 interested-in-col">
                            <mat-card-subtitle>{{!isGameSpecific ? 'Game:' : 'Haven:'}}</mat-card-subtitle>
                        </div>
                        <div class="col-12">
                            {{!isGameSpecific ? form.get('game').value.title : selectedHopperItem.haven.title}}
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 interested-in-col">
                            <mat-card-subtitle>Current Group:</mat-card-subtitle>
                        </div>
                        <div class="col-12">
                            {{getGroupName(selectedHopperItem.group.members)}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row section">
                <div class="col-12 game-row">
                    <app-autocomplete-chiplist [_items]="possiblePlayers" [_itemsAreObjects]="true" [_defaultItemsAreRemovable]="false" [_selectedItems]="selectedPlayers" [_sourceOfInput]="'app-join-hopper-group'" [_removeItemFromListOnceSelected]="true" [_allowDuplicates]="false"
                        [_allowCustomInput]="true" [_label]="'* Your Group:'" [_max]="form.get('game').value.maxPlayersCanAccept" (selectionChanged)="addPlayersToGroup($event)" (maxIsReachedError)="onMaxReachedError()">
                    </app-autocomplete-chiplist>
                </div>
            </div>

            <div class="row section gh-bordered-textarea-container-row">
                <div class="gh-bordered-textarea-container mt-2">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Message:</mat-label>
                        <textarea matInput class="gh-bordered-textarea" autocomplete="off" name="" id="" cols="60" rows="5" formControlName="message"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row button-row">
                <button mat-raised-button class="gh-button gh-button-success" (click)="submit()">SUBMIT</button>
            </div>
        </form>

    </mat-card>
</div>