import { Component, OnInit } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-games-buy-sell-swap',
  templateUrl: './games-buy-sell-swap.component.html',
  styleUrls: ['./games-buy-sell-swap.component.scss']
})
export class GamesBuySellSwapComponent implements OnInit {

  user
  allGames

  zipcodes = [55901, 55987, 55906]
  conditions = ['Like New', 'Good', 'Fair', 'Poor', 'Bad', 'Etc...']

  lookingToBuyForm: UntypedFormGroup
  lookingToSellForm: UntypedFormGroup
  lookingToTradeForm: UntypedFormGroup

  constructor(private formBuilder: UntypedFormBuilder,
    private backendAPIUsersService: BackendAPIUsersService,
    private gamesService: GamesService
  ) { }

  ngOnInit() {
    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      if (user) {
        this.user = user
        this.getGames()
      }
    })


    this.lookingToBuyForm = this.formBuilder.group({
      lookingToBuyItems: this.formBuilder.array([]),
    })

    this.lookingToSellForm = this.formBuilder.group({
      lookingToSellItems: this.formBuilder.array([]),
    })

    this.lookingToTradeForm = this.formBuilder.group({
      lookingToTradeItems: this.formBuilder.array([]),
    })

    this.addLookingToBuyItem()
    this.addLookingToSellItem()
    this.addLookingToTradeItem()
  }

  // Once figured out how to make this faster in games-dashboard component, fix here too
  async getGames() {
    var gameIds = []

    // await this.usersDbService.getGameRecommendations(this.user.pk).then(result => {
    //   gameIds = result
    // })

    await this.gamesService.coreGames$.pipe(untilDestroyed(this)).subscribe(async coreGames => {
      if (coreGames) {
        this.allGames = coreGames
      }
    })
  }

  lookingToBuyItemList = []
  lookingToSellItemList = []
  lookingToTradeItemList = []

  get lookingToBuyItems(): UntypedFormArray {
    return this.lookingToBuyForm.get("lookingToBuyItems") as UntypedFormArray
  }

  get lookingToSellItems(): UntypedFormArray {
    return this.lookingToSellForm.get("lookingToSellItems") as UntypedFormArray
  }

  get lookingToTradeItems(): UntypedFormArray {
    return this.lookingToTradeForm.get("lookingToTradeItems") as UntypedFormArray
  }

  newLookingToBuyItem(value?): UntypedFormGroup {
    return this.formBuilder.group({
      gameToBuy: [{ value: value ? value : '' }, Validators.required],
      willingToPayAmount: '',
      zipcode: '',
      condition: '',
      willingToAcceptShipping: false
    })
  }

  newLookingToSellItem(value?): UntypedFormGroup {
    return this.formBuilder.group({
      gameToSell: [{ value: value ? value : '' }, Validators.required],
      price: '',
      zipcode: '',
      condition: '',
      willingToShip: false
    })
  }

  newLookingToTradeItem(value?): UntypedFormGroup {
    return this.formBuilder.group({
      offeredGame: [{ value: value ? value : '' }, Validators.required],
      desiredGame: [{ value: value ? value : '' }, Validators.required],
      zipcode: '',
      offeredGameCondition: '',
      desiredGameCondition: '',
      willingToTradeByShipping: false
    })
  }

  addLookingToBuyItem(value?) {
    this.lookingToBuyItems.push(this.newLookingToBuyItem(value))
    this.lookingToBuyItemList.push({ value: value ? value : null })
  }

  addLookingToSellItem(value?) {
    this.lookingToSellItems.push(this.newLookingToSellItem(value))
    this.lookingToSellItemList.push({ value: value ? value : null })
  }

  addLookingToTradeItem(value?) {
    this.lookingToTradeItems.push(this.newLookingToTradeItem(value))
    this.lookingToTradeItemList.push({ value: value ? value : null })
  }

  removeLookingToBuyItem(i: number) {
    this.lookingToBuyItems.removeAt(i)
    this.lookingToBuyItemList.splice(i, 1)
    if (this.lookingToBuyItemList.length == 0) {
      this.addLookingToBuyItem()
    }
  }

  removeLookingToSellItem(i: number) {
    this.lookingToSellItems.removeAt(i)
    this.lookingToSellItemList.splice(i, 1)
    if (this.lookingToSellItemList.length == 0) {
      this.addLookingToSellItem()
    }
  }

  removeLookingToTradeItem(i: number) {
    this.lookingToTradeItems.removeAt(i)
    this.lookingToTradeItemList.splice(i, 1)
    if (this.lookingToTradeItemList.length == 0) {
      this.addLookingToTradeItem()
    }
  }

  save() {
    // TODO: ...
  }

}
