import { Component, OnInit, Optional, Inject, EventEmitter, Input, Output } from '@angular/core'
import { ValidatorFn, AbstractControl, ValidationErrors, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { FormErrorStateMatcher } from 'src/app/dashboard/dashboard-shared/utilities/form-error-state-matcher'

export function integer(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const error: ValidationErrors = { integer: true }

    if (control.value && control.value !== `${parseInt(control.value, 10)}`) {
      control.setErrors({notInteger: true})
      return error
    }

    control.setErrors(null)
    return null
  }
}

@Component({
  selector: 'app-score-game-modal[_sourceOfInput]',
  templateUrl: './score-game-modal.component.html',
  styleUrls: ['./score-game-modal.component.scss']
})
export class ScoreGameModalComponent implements OnInit {

  @Input() _sourceOfInput: string

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  @Input() set _inputData(inputData) {
    this.game = inputData
  }
  
  @Output() closeEmitter = new EventEmitter()

  matcher = new FormErrorStateMatcher()

  game

  submitClicked = false

  scoresForm: UntypedFormGroup

  constructor(
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {

    this.scoresForm = this.formBuilder.group({
      team1Score: [null, Validators.required, integer()],
      team2Score: [null, Validators.required, integer()] 
    }, { 
      //validator: this.checkForSameScores
     })
  }

  submit() {
    this.submitClicked = true
    // if (this.scoresForm.valid) {

    //   let data = {
    //     team1Score: +this.scoresForm.get('team1Score').value,
    //     team2Score: +this.scoresForm.get('team2Score').value
    //   }
    //   this.closeEmitter.emit(data)
    // }

    // shortcut for now
    let data = {
      team1Score: 5,
      team2Score: 2
    }
    this.closeEmitter.emit(data)

  }

  //   let score1 = group.get('team1Score').value
  //   let score2 = group.get('team2Score').value

  //   return score1 !== score2 ? null : { same: true }
  // }


}
