import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NotificationPanelService {

  private panelIsActive = new BehaviorSubject(false)
  panelIsActive$ = this.panelIsActive.asObservable()

  constructor() { }

  setPanelIsActive(value: boolean) { 
    this.panelIsActive.next(value)
  }

}
