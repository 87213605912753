<div class="parent-container">

    <!-- * BUY -->
    <section>
        <div class="row gh-card-header-row">
            <div class="col-12">

                <span class="inline-span">
                    <mat-card-title class="gh-xsm-title ml-1">Looking to Purchase:</mat-card-title>
                </span>

                <span class="inline-span inline-span-align-right">
                    <button mat-raised-button class="add-item-button gh-button gh-button-round gh-button-secondary"
                        (click)="addLookingToBuyItem()">
                        <mat-icon class="gh-icon gh-icon-clickable">add</mat-icon>
                    </button>
                </span>

                <mat-divider></mat-divider>
            </div>
        </div>

        <form [formGroup]="lookingToBuyForm" class="mt-1">

            <div formArrayName="lookingToBuyItems">
                <div *ngFor="let item of lookingToBuyItems.controls; let i=index" class="item"
                    [ngClass]="{ stripeGray: i % 2 == 0 }">
                    <div [formGroupName]="i">

                        <mat-form-field class="width18">
                            <mat-select formControlName="gameToBuy" placeholder="Game">
                                <mat-option *ngFor="let game of allGames" [value]="game">
                                    {{game.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="ml-4 width18">
                            <input matInput formControlName="willingToPayAmount" placeholder="Willing to pay amount"
                                autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="ml-4 width18">
                            <mat-select formControlName="zipcode" placeholder="Zipcode">
                                <mat-option *ngFor="let zipcode of zipcodes" [value]="zipcode">
                                    {{zipcode}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="ml-4 width18">
                            <mat-select formControlName="condition" placeholder="Condition">
                                <mat-option *ngFor="let condition of conditions" [value]="condition">
                                    {{condition}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-checkbox formControlName="willingToAcceptShipping" class="ml-4 width18">Willing to receive
                            by shipping
                        </mat-checkbox>

                        <button mat-button class="ml-2 delete-button" (click)="removeLookingToBuyItem(i)">
                            <mat-icon class="gh-icon gh-icon-warning gh-icon-clickable">delete_sweep</mat-icon>
                        </button>

                    </div>

                </div>

            </div>
        </form>
    </section>


    <!-- * SELL -->
    <section>
        <div class="row gh-card-header-row">
            <div class="col-12">

                <span class="inline-span">
                    <mat-card-title class="gh-xsm-title ml-1">Looking to Sell:</mat-card-title>
                </span>

                <span class="inline-span inline-span-align-right">
                    <button mat-raised-button class="add-item-button gh-button gh-button-round gh-button-secondary"
                        (click)="addLookingToSellItem()">
                        <mat-icon class="gh-icon gh-icon-clickable">add</mat-icon>
                    </button>
                </span>

                <mat-divider></mat-divider>
            </div>
        </div>

        <form [formGroup]="lookingToSellForm">

            <div formArrayName="lookingToSellItems">
                <div *ngFor="let item of lookingToSellItems.controls; let i=index" class="item"
                    [ngClass]="{ stripeGray: i % 2 == 0 }">
                    <div [formGroupName]="i">

                        <mat-form-field class="width18">
                            <mat-select formControlName="gameToSell" placeholder="Game">
                                <mat-option *ngFor="let game of allGames" [value]="game">
                                    {{game.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="ml-4 width18">
                            <input matInput formControlName="price" placeholder="Price" autocomplete="off">
                        </mat-form-field>

                        <mat-form-field class="ml-4 width18">
                            <mat-select formControlName="zipcode" placeholder="Zipcode">
                                <mat-option *ngFor="let zipcode of zipcodes" [value]="zipcode">
                                    {{zipcode}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="ml-4 width18">
                            <mat-select formControlName="condition" placeholder="Condition">
                                <mat-option *ngFor="let condition of conditions" [value]="condition">
                                    {{condition}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-checkbox formControlName="willingToShip" class="ml-4 width18">Willing to ship
                        </mat-checkbox>

                        <button mat-button class="ml-2 delete-button" (click)="removeLookingToSellItem(i)">
                            <mat-icon class="gh-icon gh-icon-warning gh-icon-clickable">delete_sweep</mat-icon>
                        </button>
                    </div>

                </div>

            </div>
        </form>
    </section>


    <!-- * SWAP -->
    <section>
        <div class="row gh-card-header-row">
            <div class="col-12">

                <span class="inline-span">
                    <mat-card-title class="gh-xsm-title ml-1">Looking to Swap:</mat-card-title>
                </span>

                <span class="inline-span inline-span-align-right">
                    <button mat-raised-button class="add-item-button gh-button gh-button-round gh-button-secondary"
                        (click)="addLookingToTradeItem()">
                        <mat-icon class="gh-icon gh-icon-clickable">add</mat-icon>
                    </button>
                </span>

                <mat-divider></mat-divider>
            </div>
        </div>

        <form [formGroup]="lookingToTradeForm">

            <div formArrayName="lookingToTradeItems">
                <div *ngFor="let item of lookingToTradeItems.controls; let i=index" class="item"
                    [ngClass]="{ stripeGray: i % 2 == 0 }">
                    <div [formGroupName]="i">

                        <mat-form-field class="width14">
                            <mat-select formControlName="offeredGame" placeholder="Offered Game">
                                <mat-option *ngFor="let game of allGames" [value]="game">
                                    {{game.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="ml-4 width14">
                            <mat-select formControlName="desiredGame" placeholder="Desired Game">
                                <mat-option *ngFor="let game of allGames" [value]="game">
                                    {{game.t}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="ml-4 width14">
                            <mat-select formControlName="zipcode" placeholder="Zipcode">
                                <mat-option *ngFor="let zipcode of zipcodes" [value]="zipcode">
                                    {{zipcode}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="ml-4 width14">
                            <mat-select formControlName="offeredGameCondition" placeholder="Offered Game Condition">
                                <mat-option *ngFor="let condition of conditions" [value]="condition">
                                    {{condition}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="ml-4 width14">
                            <mat-select formControlName="desiredGameCondition" placeholder="Desired Game Condition">
                                <mat-option *ngFor="let condition of conditions" [value]="condition">
                                    {{condition}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-checkbox formControlName="willingToTradeByShipping" class="ml-4 width14">Willing to trade
                            by shipping
                        </mat-checkbox>

                        <button mat-button class="ml-2 delete-button" (click)="removeLookingToTradeItem(i)">
                            <mat-icon class="gh-icon gh-icon-warning gh-icon-clickable">delete_sweep
                            </mat-icon>
                        </button>
                    </div>

                </div>

            </div>
        </form>
    </section>

    <!-- <p class="ml-5 gh-color-red">TODO:
        <br>
        1): warning prompt against navigating away when changes are pending
        <br>
        2): animate or change color or something of save button when unsaved changes exist!
    </p>

    <div class="row save-button-row">
        <button mat-raised-button id="save-button" class="gh-button gh-button-success" (click)="save()">Save</button>
    </div> -->

</div>