import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
// import { NguCarousel, NguCarouselConfig } from '@ngu/carousel'
import { actualDurationComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/components/actual-duration/actual-duration.component'
import { CommunityVotesComponent } from 'src/app/dashboard/dashboard-shared/components/games/community-votes/community-votes.component'
import { BannerLocationEnums } from '../../../enums/banner-location-enums'
import { BannerSizeEnums } from '../../../enums/banner-size-enums'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { BannerAdService } from '../../../services/ads/banner-ad.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-game-detail-preview-modal',
    templateUrl: './game-detail-preview-modal.component.html',
    styleUrls: ['./game-detail-preview-modal.component.scss'],
})
export class GameDetailPreviewModalComponent implements OnInit {
    bannerAdInputDataSpecific = {
        bannerSizeEnum: BannerSizeEnums.banner120x240,
        multiplier: 0.75,
        bannerLocation: BannerLocationEnums.sidebar,
        hasButtons: true,
        specificAds: [
            {
                link: '//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=davidrazidlo-20&marketplace=amazon&region=US&placement=B084Z8L6KZ&asins=B084Z8L6KZ&linkId=73f096a89d123bfe420da6b63d2bada2&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066C0&bg_color=FFFFFF',
            },
        ],
        isIFrame: true,
    }

    userHasSubmittedIdealPlayers = false

    isLoading: boolean

    inputData
    @Input() set _inputData(inputData) {
        this.inputData = inputData
    }

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.goToFullDetail()
        }
    }

    @Output() closeEmitter = new EventEmitter()

    @ViewChild('myCarousel') myCarousel
    // @ViewChild('myCarousel') myCarousel: NguCarousel<any>
    carouselConfig = {
        // carouselConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 6, all: 0 },
        load: 3,
        //interval: {timing: 4000, initialDelay: 1000},
        loop: true,
        touch: true,
        velocity: 0.2,
    }

    carouselItems = [
        { img: window.location.origin + '/assets/images/defaults/placeholder-event.png' },
        { img: window.location.origin + '/assets/images/defaults/placeholder-event.png' },
    ]

    constructor(
        private bannerAdService: BannerAdService,
        private screenSizeService: ScreenSizeService,

        private dialog: MatDialog,
    ) { }

    isMobileScreen = false

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        function getRandomInt(max) {
            return Math.floor(Math.random() * max)
        }
        this.bannerAdInputDataSpecific.specificAds = [
            { link: this.bannerAdService.amazonBannerAds[getRandomInt(4)].l },
        ]
        this.isLoading = true
    }

    goToFullDetail() {
        this.closeEmitter.emit('routeToDetail')
    }

    openViewRealDuration() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Duration Based on Real User Plays',
                component: actualDurationComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: false,
        })
        // dialogRef.afterClosed().pipe(untilDestroyed(this)).pipe(untilDestroyed(this)).subscribe(data => {
        //   if (data && data == 'submitted') {
        //     this.userHasSubmittedIdealPlayers = true
        //   }
        // })
    }

    getMinMaxPlayers() {
        if (this.inputData.minPlayers && this.inputData.maxPlayers) {
            return this.inputData.minPlayers == this.inputData.maxPlayers
                ? this.inputData.minPlayers
                : this.inputData.minPlayers + '-' + this.inputData.maxPlayers
        } else {
            return null
        }
    }

    getVotedMinMaxPlayers() {
        if (this.inputData.votedBestMinPlayers && this.inputData.votedBestMaxPlayers) {
            return this.inputData.votedBestMinPlayers == this.inputData.votedBestMaxPlayers
                ? this.inputData.votedBestMinPlayers
                : this.inputData.votedBestMinPlayers + '-' + this.inputData.votedBestMaxPlayers
        } else {
            return null
        }
    }

    openSubmitPlayerCount() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Works Well: Community Votes (WORK IN PROGRESS)',
                component: CommunityVotesComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data && data == 'submitted') {
                    this.userHasSubmittedIdealPlayers = true
                }
            })
    }
}
