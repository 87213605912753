<div class="row sign-up-container">
    <form [formGroup]="passwordForm">

        <div class="inner-flex-container">

            <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Password:</mat-label>
                            <input matInput type="password" formControlName="password" (focus)="onPasswordFocus()"
                                (blur)="onPasswordBlur()">
                        </mat-form-field>
                        <mat-error class="gh-mat-error-simulator"
                            *ngIf="submitIsClicked && passwordForm.get('password').errors?.required">
                            Required</mat-error>
                    </div>

                    <div class="col-6">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Confirm Password:</mat-label>
                            <input matInput type="password" formControlName="confirmPassword"
                                (focus)="onPasswordFocus()" (blur)="onPasswordBlur()">
                        </mat-form-field>
                        <mat-error class="gh-mat-error-simulator"
                            *ngIf="submitIsClicked && passwordForm.get('confirmPassword').errors?.required">
                            Required</mat-error>
                        <mat-error class="gh-mat-error-simulator"
                            *ngIf="submitIsClicked && passwordForm.get('confirmPassword').errors.mustMatch">
                            Passwords Don't Match</mat-error>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>

<div class="password-hint-container">
    <div class="password-hint-inner-container">

        <div class="row">
            <div class="col-12">
                <mat-card-subtitle class="gh-md-subtitle">
                    Make sure that the password is at least 8 characters long:
                </mat-card-subtitle>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 text-center">

                <span class="inline-span">
                    <mat-icon class="gh-icon password-satisfaction-icon"
                        [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('length'), unsatisfiedCriterion: !getRequirementIsSatisfied('length') }">
                        {{ getRequirementIsSatisfied('lowercaseLetter') ? 'check' : 'close' }}</mat-icon>
                </span>

                <span class="inline-span ml-3">
                    <mat-card-subtitle class="password-length-subtitle"
                        [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('length'), unsatisfiedCriterion: !getRequirementIsSatisfied('length') }">
                        Password Length: {{passwordForm.get('password').value.length}}
                    </mat-card-subtitle>
                </span>

            </div>
        </div>
    </div>

</div>

<div class="password-hint-container">
    <div class="password-hint-inner-container">
        <mat-card-subtitle class="gh-md-subtitle">
            Make sure that the password contains at least one of each of the following:
        </mat-card-subtitle>

        <div class="row">
            <div class="col-6">

                <mat-list role="list">
                    <mat-list-item role="listitem">
                        <span class="inline-span">
                            <mat-icon class="gh-icon password-satisfaction-icon"
                                [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('lowercaseLetter'), unsatisfiedCriterion: !getRequirementIsSatisfied('lowercaseLetter') }">
                                {{ getRequirementIsSatisfied('lowercaseLetter') ? 'check' : 'close' }}</mat-icon>
                        </span>

                        <span class="inline-span ml-3">
                            <mat-card-subtitle
                                [ngStyle]="{ color: getRequirementIsSatisfiedColor('lowercaseLetter') }">Lowercase</mat-card-subtitle>
                        </span>
                    </mat-list-item>
                    <mat-list-item role="listitem" class="bottom-item">
                        <span class="inline-span">
                            <mat-icon class="gh-icon password-satisfaction-icon"
                                [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('uppercaseLetter'), unsatisfiedCriterion: !getRequirementIsSatisfied('uppercaseLetter') }">
                                {{ getRequirementIsSatisfied('uppercaseLetter') ? 'check' : 'close' }}</mat-icon>
                        </span>

                        <span class="inline-span ml-3">
                            <mat-card-subtitle
                                [ngStyle]="{ color: getRequirementIsSatisfiedColor('uppercaseLetter') }">Uppercase</mat-card-subtitle>
                        </span>
                    </mat-list-item>
                </mat-list>

            </div>
            <div class="col-6">

                <mat-list role="list">
                    <mat-list-item role="listitem">
                        <span class="inline-span">
                            <mat-icon class="gh-icon password-satisfaction-icon"
                                [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('number'), unsatisfiedCriterion: !getRequirementIsSatisfied('number') }">
                                {{ getRequirementIsSatisfied('number') ? 'check' : 'close' }}</mat-icon>
                        </span>

                        <span class="inline-span ml-3">
                            <mat-card-subtitle
                                [ngStyle]="{ color: getRequirementIsSatisfiedColor('number') }">Number</mat-card-subtitle>
                        </span>
                    </mat-list-item>
                    <mat-list-item role="listitem" class="bottom-item">
                        <span class="inline-span">
                            <mat-icon class="gh-icon password-satisfaction-icon"
                                [ngClass]="{ satisfiedCriterion: getRequirementIsSatisfied('specialCharacter'), unsatisfiedCriterion: !getRequirementIsSatisfied('specialCharacter') }">
                                {{ getRequirementIsSatisfied('specialCharacter') ? 'check' : 'close' }}</mat-icon>
                        </span>

                        <span class="inline-span ml-3">
                            <mat-card-subtitle
                                [ngStyle]="{ color: getRequirementIsSatisfiedColor('specialCharacter') }">Special</mat-card-subtitle>
                        </span>
                    </mat-list-item>
                </mat-list>

            </div>
        </div>
    </div>
</div>

<div class="peeking-meeple-container">
    <img src="../../../../assets/images/coolStuff/peeking-meeple.png" alt="" *ngIf="!passwordFieldIsFocused">
    <img src="../../../../assets/images/coolStuff/non-peeking-meeple.png" alt="" *ngIf="passwordFieldIsFocused">
</div>