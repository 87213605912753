import {
    Component,
    OnInit,
    Input,
    ChangeDetectorRef,
    OnDestroy,
    ViewChild,
    TemplateRef,
    AfterViewInit,
} from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSelectChange } from '@angular/material/select'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { untilDestroyed } from '@ngneat/until-destroy'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { CreateScheduledPlayComponent } from '../create-scheduled-play/create-scheduled-play.component'
import { MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { ProfileComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile/profile.component'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { StringHelper } from '../../../../../../../../../../common/helpers/string-helper'
import { StringHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/string-helper.service'

@Component({
    selector: 'app-scheduled-plays',
    templateUrl: './scheduled-plays.component.html',
    styleUrls: ['./scheduled-plays.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms ease-in-out')),
        ]),
    ],
})
export class ScheduledPlaysComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() inputData: any
    @Input() user: any
    @Input() isGHAdmin: boolean

    conventionDetails = new BehaviorSubject<any>(null)
    conventionDetails$ = this.conventionDetails.asObservable()

    events: any[] = []

    myEventsDataSource = new MatTableDataSource<any>()

    dayViewDisplayedColumns: string[] = [
        'game',
        'title',
        'cost',
        'location',
        'createdByTitle',
        'startTime',
        'duration',
        'players',
        'waitlist',
        'notes',
        'playerList',
        'attend',
        'actions',
    ]

    myEventsDisplayedColumns: string[] = [
        'game',
        'title',
        'cost',
        'location',
        'createdByTitle',
        'date',
        'startTime',
        'duration',
        'players',
        'waitlist',
        'notes',
        'playerList',
        'attend',
        'actions',
    ]

    mobileColumns: string[] = ['game', 'startTime']
    mobileColumnsWithExpand: string[] = [...this.mobileColumns, 'expand']

    mobileMyEventsColumns: string[] = ['game', 'date']
    mobileMyEventsColumnsWithExpand: string[] = [...this.mobileMyEventsColumns, 'expand']
    allEventsDataSource = new MatTableDataSource<any>()

    expandedElement: any | null

    isMobileScreen: boolean
    private screenSizeSubscription: Subscription

    coreGames = new BehaviorSubject<any[]>(null)
    coreGames$ = this.coreGames.asObservable()

    // @ViewChild('daySort', { static: false }) daySort: MatSort
    // @ViewChild('myEventsSort', { static: false }) myEventsSort: MatSort
    // @ViewChild('allEventsSort', { static: false }) allEventsSort: MatSort
    // Use setters with ViewChild to assign matSorts
    @ViewChild('daySort') set matSortDay(ms: MatSort) {
        if (ms) {
            this.daySort = ms
            const selectedOption = this.dropdownOptions[this.selectedTabIndex]
            if (selectedOption?.type === 'day') {
                const day = selectedOption?.data
                if (day && day.games) {
                    day.games.sort = this.daySort
                }
            }
        }
    }

    @ViewChild('myEventsSort') set matSortMyEvents(ms: MatSort) {
        if (ms) {
            this.myEventsSort = ms
            if (this.myEventsDataSource) {
                this.myEventsDataSource.sort = this.myEventsSort
            }
        }
    }

    @ViewChild('allEventsSort') set matSortAllEvents(ms: MatSort) {
        if (ms) {
            this.allEventsSort = ms
            if (this.allEventsDataSource) {
                this.allEventsDataSource.sort = this.allEventsSort
            }
        }
    }

    // Define variables to hold matSort instances
    daySort: MatSort
    myEventsSort: MatSort
    allEventsSort: MatSort

    selectedTabIndex: number = 0
    dropdownOptions: Array<{ label: string; type: string; data?: any }> = []

    @ViewChild('notesDialog') notesDialog: TemplateRef<any>
    @ViewChild('passwordDialog') passwordDialog: TemplateRef<any>
    @ViewChild('deleteDialog', { static: true }) deleteDialog: TemplateRef<any>

    passwordInput: string = ''
    passwordError: boolean = false
    gameForPasswordDialog: any
    passwordDialogRef: MatDialogRef<any>

    gameForDeleteDialog: any
    deleteDialogRef: MatDialogRef<any>

    constructor(
        private screenSizeService: ScreenSizeService,
        private dialog: MatDialog,
        private backendApiUsersService: BackendAPIUsersService,
        private backendApiContentService: BackendAPIContentService,
        private backendApiGamesService: BackendAPIGamesService,
        private gamesService: GamesService,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private stringHelperService: StringHelperService,
    ) {}

    async ngOnInit() {
        this.conventionDetails.next(this.inputData.convention)

        this.screenSizeSubscription = this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
                this.cdr.detectChanges()
            })

        await this.gamesService.coreGames$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreGames) => {
                if (coreGames) {
                    this.coreGames.next(coreGames)
                }
            })

        this.myEventsDataSource.filterPredicate = (data, filter) => {
            return data.name.toLowerCase().includes(filter)
        }

        this.conventionDetails$.subscribe((details) => {
            if (details) {
                this.buildDropdownOptions()
            }
        })

        await this.fetchScheduledPlaysForThisConvention()
    }

    ngAfterViewInit() {
        this.assignSort()
    }

    ngOnDestroy(): void {
        if (this.screenSizeSubscription) {
            this.screenSizeSubscription.unsubscribe()
        }
    }

    // Custom sortingDataAccessor
    customSortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
        switch (sortHeaderId) {
            case 'day':
                return new Date(data.date).getDay() // Sort by day of the week (0 = Sunday)
            case 'game':
                return data.name || ''
            case 'title':
                return data.title || ''
            case 'cost':
                return data.cost || ''
            case 'players':
                return data.currentPlayers || 0
            case 'startTime':
                return data.startTime ? data.startTime.getTime() : 0
            case 'duration':
                return data.duration || 0
            case 'createdByTitle':
                return data.createdByTitle || ''
            case 'date':
                return data.date ? new Date(data.date).getTime() : 0
            default:
                return data[sortHeaderId]
        }
    }

    buildDropdownOptions(): void {
        this.dropdownOptions = []
        // Add 'All Events' as the first option
        this.dropdownOptions.push({
            label: 'All Events',
            type: 'allEvents',
        })

        const days = this.conventionDetails.value.extendedProps.days
        days.forEach((day: any) => {
            this.dropdownOptions.push({
                label: new Date(day.date).toLocaleDateString(undefined, {
                    weekday: 'long',
                    month: 'short',
                    day: 'numeric',
                }),
                type: 'day',
                data: day,
            })
        })
        // Add 'My Events'
        this.dropdownOptions.push({
            label: 'My Events',
            type: 'myEvents',
        })
    }

    truncate(input: string, maxCharacters: number) {
        return input.length > maxCharacters ? input.substring(0, maxCharacters) + '...' : input
    }

    onDropdownChange(event: MatSelectChange): void {
        this.selectedTabIndex = event.value
        const selectedOption = this.dropdownOptions[event.value]
        if (selectedOption.type === 'createEvent') {
            // this.onButtonClick()
        } else {
            setTimeout(() => this.assignSort())
        }
    }

    assignSort() {
        const selectedOption = this.dropdownOptions[this.selectedTabIndex]
        if (selectedOption?.type === 'day') {
            const day = selectedOption?.data
            if (day && day.games && this.daySort) {
                day.games.sort = this.daySort
            }
        } else if (selectedOption?.type === 'myEvents') {
            if (this.myEventsDataSource && this.myEventsSort) {
                this.myEventsDataSource.sort = this.myEventsSort
            }
        } else if (selectedOption?.type === 'allEvents') {
            if (this.allEventsDataSource && this.allEventsSort) {
                this.allEventsDataSource.sort = this.allEventsSort
            }
        }
    }
    async fetchScheduledPlaysForThisConvention() {
        try {
            const response = await this.backendApiContentService.listScheduledPlaysByConventionId(
                this.conventionDetails.value.pk,
                'sp',
                500,
            )

            // Filter out events that are not approved.
            const approvedItems = response.items.filter((game: any) => game.isApproved === 'true')

            this.events = approvedItems.map((game: any) => {
                let playerList = []
                let waitlist = []

                // Handle playerList
                if (game.playerList) {
                    if (typeof game.playerList === 'string' && game.playerList.trim() !== '') {
                        try {
                            playerList = JSON.parse(game.playerList)
                        } catch (e) {
                            console.error('Error parsing playerList:', e)
                            playerList = []
                        }
                    } else if (Array.isArray(game.playerList)) {
                        playerList = game.playerList
                    } else {
                        playerList = []
                    }
                }

                // Handle waitlist
                if (game.waitlist) {
                    if (typeof game.waitlist === 'string' && game.waitlist.trim() !== '') {
                        try {
                            waitlist = JSON.parse(game.waitlist)
                        } catch (e) {
                            console.error('Error parsing waitlist:', e)
                            waitlist = []
                        }
                    } else if (Array.isArray(game.waitlist)) {
                        waitlist = game.waitlist
                    } else {
                        waitlist = []
                    }
                }

                let startTime = new Date(game.startTime)
                let endTime = new Date(startTime.getTime() + game.duration * 60000)

                return {
                    ...game,
                    startTime: startTime,
                    endTime: endTime,
                    playerList: playerList,
                    waitlist: waitlist,
                    currentPlayers: playerList.length,
                    isUserOrganizer:
                        game.createdByTitle?.toLowerCase() === this.user.title?.toLowerCase(),
                }
            })

            this.allEventsDataSource = new MatTableDataSource(this.events)
            this.allEventsDataSource.filterPredicate = (data, filter) => {
                return data.name.toLowerCase().includes(filter)
            }
            this.allEventsDataSource.sortingDataAccessor = this.customSortingDataAccessor
            if (this.allEventsSort) {
                this.allEventsDataSource.sort = this.allEventsSort
            }

            // Update views
            this.updateDaysWithEvents()
            this.updateMyEvents()
        } catch (error) {
            console.error('Error fetching scheduled plays:', error)
        }
    }

    private updateDaysWithEvents(): void {
        if (this.conventionDetails.value && this.conventionDetails.value.extendedProps) {
            const newDays = this.conventionDetails.value.extendedProps.days.map((day: any) => {
                const eventsForDay = this.events.filter(
                    (event) =>
                        new Date(event.date).toDateString() === new Date(day.date).toDateString(),
                )

                const eventsWithFlags = eventsForDay.map((game: any) => {
                    return {
                        ...game,
                        isUserOrganizer:
                            game.createdByTitle?.toLowerCase() === this.user.title?.toLowerCase(),
                        isOverlapping: game.isOverlapping || false,
                    }
                })

                const gamesDataSource = new MatTableDataSource(eventsWithFlags)
                gamesDataSource.filterPredicate = (data, filter) => {
                    return data.name.toLowerCase().includes(filter)
                }

                // Set custom sortingDataAccessor
                gamesDataSource.sortingDataAccessor = this.customSortingDataAccessor

                // Assign sort to day games
                if (this.daySort) {
                    gamesDataSource.sort = this.daySort
                }

                return {
                    ...day,
                    games: gamesDataSource,
                }
            })

            const newExtendedProps = {
                ...this.conventionDetails.value.extendedProps,
                days: newDays,
            }

            const conventionDetailsClone = {
                ...this.conventionDetails.value,
                extendedProps: newExtendedProps,
            }

            this.conventionDetails.next(conventionDetailsClone)
        }
    }

    private updateMyEvents(): void {
        if (this.conventionDetails.value && this.conventionDetails.value.extendedProps) {
            const events: any[] = []

            this.conventionDetails.value.extendedProps.days.forEach((day: any) => {
                if (day.games && day.games.data) {
                    day.games.data.forEach((game: any) => {
                        if (this.isUserAttending(game) || this.isUserOnWaitlist(game)) {
                            game.date = day.date

                            game.isUserOrganizer =
                                game.createdByTitle?.toLowerCase() ===
                                this.user.title?.toLowerCase()

                            game.startTime = new Date(game.startTime)
                            game.endTime = new Date(
                                game.startTime.getTime() + game.duration * 60000,
                            )

                            events.push(game)
                        }
                    })
                }
            })

            this.myEventsDataSource.data = events.sort((a, b) => a.startTime - b.startTime)

            // Set custom sortingDataAccessor
            this.myEventsDataSource.sortingDataAccessor = this.customSortingDataAccessor

            if (this.myEventsSort) {
                this.myEventsDataSource.sort = this.myEventsSort
            }

            // After updating my events, mark overlapping events
            this.markOverlappingEvents()
        }
    }

    private markOverlappingEvents(): void {
        const userEvents = this.myEventsDataSource.data

        this.events.forEach((event) => {
            event.isOverlapping = false

            if (this.isUserAttending(event) || this.isUserOnWaitlist(event)) {
                event.isOverlapping = false
                return
            }

            const eventStartTime = new Date(event.startTime)
            const eventEndTime = new Date(event.endTime)

            for (let userEvent of userEvents) {
                const userEventStartTime = new Date(userEvent.startTime)
                const userEventEndTime = new Date(userEvent.endTime)

                if (
                    new Date(event.date).toDateString() === new Date(userEvent.date).toDateString()
                ) {
                    if (
                        this.eventsOverlap(
                            eventStartTime,
                            eventEndTime,
                            userEventStartTime,
                            userEventEndTime,
                        )
                    ) {
                        event.isOverlapping = true
                        break
                    }
                }
            }
        })

        this.updateDaysWithEvents()
    }

    private eventsOverlap(start1: Date, end1: Date, start2: Date, end2: Date): boolean {
        return start1 < end2 && start2 < end1
    }

    isUserAttending(game: any): boolean {
        return game.playerList.includes(this.user.title)
    }

    isUserOnWaitlist(game: any): boolean {
        return game.waitlist && game.waitlist.includes(this.user.title)
    }

    async handleAttendClick(game: any): Promise<void> {
        if (this.isUserAttending(game) || this.isUserOnWaitlist(game)) {
            await this.toggleAttendance(game)
        } else if (game.isPasswordProtected === 'true') {
            this.openPasswordDialog(game)
        } else {
            await this.toggleAttendance(game)
        }
    }

    openPasswordDialog(game: any): void {
        this.passwordInput = ''
        this.passwordError = false
        this.gameForPasswordDialog = game

        this.passwordDialogRef = this.dialog.open(this.passwordDialog, {
            width: '300px',
        })
    }

    closePasswordDialog(): void {
        this.passwordDialogRef.close()
    }

    async submitPassword(): Promise<void> {
        if (this.passwordInput === this.gameForPasswordDialog.password) {
            this.passwordDialogRef.close()
            await this.toggleAttendance(this.gameForPasswordDialog)
        } else {
            this.passwordError = true
        }
    }

    formatDate(date: string | Date): string {
        const dateObj = new Date(date)
        const options: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
        }
        const dateString = dateObj.toLocaleDateString('en-US', options)
        const day = dateObj.getDate()
        const ordinalSuffix = (n: number) => {
            const s = ['th', 'st', 'nd', 'rd']
            const v = n % 100
            return n + (s[(v - 20) % 10] || s[v] || s[0])
        }
        return `${dateString.replace(day.toString(), ordinalSuffix(day))}`
    }

    async toggleAttendance(game: any): Promise<void> {
        if (this.isUserAttending(game)) {
            game.playerList = game.playerList.filter((player: string) => player !== this.user.title)
        } else if (game.playerList.length < game.maxPlayers) {
            game.playerList.push(this.user.title)
        } else {
            if (this.isUserOnWaitlist(game)) {
                game.waitlist = game.waitlist.filter((user: string) => user !== this.user.title)
            } else if (game.waitlist.length < game.waitlistCapacity) {
                game.waitlist.push(this.user.title)
            }
        }

        game.currentPlayers = game.playerList.length

        game.playerList = [...game.playerList]
        game.waitlist = [...game.waitlist]

        let updatedGameData = {
            pkName: 'pk',
            pkValue: this.inputData.convention.pk,
            skName: 'sk',
            skValue: game.sk,
            attributeName: 'playerList',
            attributeValue: JSON.stringify(game.playerList),
        }

        let updatedGameData2 = {
            pkName: 'pk',
            pkValue: this.inputData.convention.pk,
            skName: 'sk',
            skValue: game.sk,
            attributeName: 'waitlist',
            attributeValue: JSON.stringify(game.waitlist),
        }

        await Promise.all([
            this.backendApiContentService.updateSingleAttributeForContentItem(updatedGameData),
            this.backendApiContentService.updateSingleAttributeForContentItem(updatedGameData2),
        ])

        await this.fetchScheduledPlaysForThisConvention()

        this.updateMyEvents()
    }

    getButtonLabel(game: any): string {
        if (this.isUserAttending(game)) {
            return 'Leave'
        } else if (game.isPasswordProtected === 'true') {
            return 'Enter Password'
        } else if (game.playerList.length >= game.maxPlayers) {
            return this.isUserOnWaitlist(game) ? 'Leave Waitlist' : 'Join Waitlist'
        } else {
            return 'Join'
        }
    }

    isAttendDisabled(game: any): boolean {
        return (
            game.isOverlapping ||
            (game.playerList.length >= game.maxPlayers &&
                game.waitlist.length >= game.waitlistCapacity &&
                !this.isUserOnWaitlist(game) &&
                !this.isUserAttending(game))
        )
    }

    applyGameFilter(filterValue: string, dataSource: MatTableDataSource<any>): void {
        dataSource.filter = filterValue.trim().toLowerCase()
    }

    applyMyEventsFilter(filterValue: string): void {
        this.myEventsDataSource.filter = filterValue.trim().toLowerCase()
    }

    async openGameDetails(game: any): Promise<void> {
        const matchedGame = this.coreGames.value.find(
            (coreGame: any) => coreGame.title === game.name,
        )

        if (matchedGame) {
            game.pk = matchedGame.pk
        } else {
            console.error('Game not found in coreGames')
            return
        }

        try {
            let gameDetails = await this.backendApiGamesService.GetGame(game.pk)
            let inputData = {
                limitDataFecthing: true,
                game: gameDetails,
            }

            this.dialog.open(GhDialogWrapperComponent, {
                data: {
                    title: game.name,
                    component: MdGameDetailComponent,
                    hasSubmitButton: false,
                    hasCloseButton: true,
                    hasCancelButton: false,
                    inputData: inputData,
                    allowParentClose: true,
                },
                maxWidth: '90%',
                panelClass: this.isMobileScreen
                    ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                    : DESKTOP_MODAL_PANEL_CLASS,
                backdropClass: 'gh-dialog-backdrop',
                disableClose: true,
            })
        } catch (error) {
            console.error('Error fetching game details:', error)
        }
    }

    getFilterLabel(): string {
        const selectedOption = this.dropdownOptions
            ? this.dropdownOptions[this.selectedTabIndex]
            : null
        if (
            selectedOption &&
            (selectedOption.type === 'day' ||
                selectedOption.type === 'myEvents' ||
                selectedOption.type === 'allEvents')
        ) {
            return 'Filter by Game'
        } else {
            return ''
        }
    }

    isFilterEnabled(): boolean {
        const selectedOption = this.dropdownOptions
            ? this.dropdownOptions[this.selectedTabIndex]
            : null
        return (
            selectedOption &&
            (selectedOption.type === 'day' ||
                selectedOption.type === 'myEvents' ||
                selectedOption.type === 'allEvents')
        )
    }

    applyFilter(filterValue: string): void {
        const selectedOption = this.dropdownOptions[this.selectedTabIndex]
        if (selectedOption.type === 'day') {
            const day = selectedOption.data
            if (day && day.games) {
                day.games.filter = filterValue.trim().toLowerCase()
            }
        } else if (selectedOption.type === 'myEvents') {
            this.myEventsDataSource.filter = filterValue.trim().toLowerCase()
        } else if (selectedOption.type === 'allEvents') {
            this.allEventsDataSource.filter = filterValue.trim().toLowerCase()
        }
    }

    async openUserDetails(userID: string) {
        let userDetails = await this.backendApiUsersService.getProfileById(userID)

        let inputData = {
            user: userDetails,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,

            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'User Details',
                component: ProfileComponent,
                inputData: inputData,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                submitButtonText: 'Reply',
                allowParentClose: true,
            },
        })
    }

    onButtonClick(event?: Event): void {
        if (event) {
            event.preventDefault()
            event.stopPropagation()
        }

        const inputData = {
            parentInputData: this.inputData,
        }

        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Create Event',
                component: CreateScheduledPlayComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                inputData: inputData,
                hasCloseButton: true,
            },
            height: '70%',
            width: '40%',
            disableClose: true,
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
        })

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                // Refresh the events data from the backend
                await this.fetchScheduledPlaysForThisConvention()
            } else {
                console.log('Dialog closed without any result')
            }
        })
    }

    navigateToCreateEvent(event: Event): void {
        this.onButtonClick(event)
    }

    openDeleteDialog(game: any): void {
        this.gameForDeleteDialog = game
        this.deleteDialogRef = this.dialog.open(this.deleteDialog, {
            width: '400px',
            autoFocus: false,
        })
    }

    closeDeleteDialog(): void {
        this.deleteDialogRef.close()
    }

    async confirmDelete(): Promise<void> {
        await this.performDeleteEvent(this.gameForDeleteDialog)
        this.deleteDialogRef.close()
    }

    async performDeleteEvent(game: any) {
        if (
            this.isGHAdmin ||
            game.createdByTitle?.toLowerCase() === this.user.title?.toLowerCase()
        ) {
            this.events = this.events.filter((event) => event.id !== game.id)

            this.updateDaysWithEvents()
            this.updateMyEvents()

            let deleteInput = {
                pk: game.pk,
                sk: game.sk,
            }
            await this.backendApiContentService.deleteContentItemByPkSk(deleteInput)
            await this.fetchScheduledPlaysForThisConvention()
            this.updateDaysWithEvents()
            this.updateMyEvents()
            this.snackBar.open(`Event "${game.name}" has been deleted.`, 'Close', {
                duration: 3000,
            })
        } else {
            console.error('User is not authorized to delete this event.')
            this.snackBar.open('You are not authorized to delete this event.', 'Close', {
                duration: 3000,
            })
        }
    }

    onEditButtonClick(game: any): void {
        const inputData = {
            parentInputData: this.inputData,
            scheduledPlay: game,
            isEditing: true, // Ensure this is set
        }

        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Edit Event',
                component: CreateScheduledPlayComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                inputData: inputData,
                hasCloseButton: true,
            },
            height: '70%',
            width: '40%',
            disableClose: true,
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
        })

        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                // Refresh the events data
                await this.fetchScheduledPlaysForThisConvention()
            } else {
                console.log('Dialog closed without any result')
            }
        })
    }

    openNotesDialog(notes: string): void {
        this.dialog.open(this.notesDialog, {
            data: notes,
            width: '400px',
        })
    }

    toggleRow(game: any) {
        this.expandedElement = this.expandedElement === game ? null : game
    }

    formatTime(timeString: string): string {
        const date = new Date(`1970-01-01T${this.convertTo24Hour(timeString)}:00`)
        return date ? date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }) : ''
    }

    private convertTo24Hour(time: string): string {
        const [timePart, modifier] = time.split(' ')
        let [hours, minutes] = timePart.split(':').map(Number)

        if (modifier.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12
        }
        if (modifier.toLowerCase() === 'am' && hours === 12) {
            hours = 0
        }

        const formattedHours = hours.toString().padStart(2, '0')
        const formattedMinutes = minutes.toString().padStart(2, '0')

        return `${formattedHours}:${formattedMinutes}`
    }
}
