<div class="notification-list" #notificationList>
    <div *ngFor="let notification of notifications">
        <div
            class="notification"
            *ngIf="notification.notificationType"
            [ngClass]="notification.notificationType"
        >
            <!-- Section for 'loggedPlay' notificationType -->

            <!-- **************************************************************************************** -->
            <!-- ! TODO There is an issue with populating the avatar card, everything else works -->
            <!-- **************************************************************************************** -->

            <!-- CHATGPT LOOK HERE, CSS TYPE 0 -->

            <div *ngIf="notification.notificationType == 'loggedPlay'" class="loggedPlay-content">
                <div
                    class="notification-image user-image"
                    *ngIf="notification.userImage && notification.userImage.length > 0"
                >
                    <img
                        [src]="notification.userImage[0]"
                        alt="User Image"
                        (click)="seeUserDetails(notification)"
                    />
                </div>
                <div class="notification-user-title" (click)="seeUserDetails(notification)">
                    {{ notification.addedByTitle }}
                </div>
                <div class="notification-additional-info">Played</div>

                <div
                    class="game-image notification-image"
                    *ngIf="
                        notification.gameMainImageFiles &&
                        notification.gameMainImageFiles.length > 0
                    "
                >
                    <img
                        [src]="notification.gameMainImageFiles[0]"
                        alt="Game Image"
                        (click)="seeGameDetails(notification)"
                    />
                </div>

                <div class="notification-text">
                    <div class="game-title" (click)="seeGameDetails(notification)">
                        {{ notification.gameTitle }}
                    </div>
                    <div class="game-details">{{ notification.date | date : 'MM/dd/yyyy' }}</div>
                </div>
            </div>

            <!-- CHATGPT LOOK HERE, CSS TYPE 1 -->

            <div
                *ngIf="notification.notificationType == 'Announcement'"
                class="loggedPlay-content"
                (click)="seeNotificationDetails(notification)"
            >
                <!-- this image shrinks, make sure it retains its full size of 80px -->
                <img
                    id="logo"
                    src="../../../../../../assets/images/coolStuff/logo-redo-no-flag.png"
                    alt=""
                    class="ng-tns-c394-0"
                />

                <div class="mr-3 ml-3 p-3">
                    {{ notification.messageIntro }}
                </div>
                <div class="p-3" style="font-weight: normal">
                    {{ notification.message }}
                </div>
            </div>

            <!-- Section for 'ad' notificationType -->
            <!-- CHATGPT LOOK HERE, CSS TYPE 2 -->

            <div *ngIf="notification.notificationType == 'ad'">
                <div class="notification-user-title">
                    {{ notification.addedByTitle }}
                </div>

                <div>
                    {{ notification.adIntro }}
                </div>
                <div>
                    {{ notification.adMessage }}
                </div>
            </div>

            <!-- Section for 'event' notificationType -->
            <div
                *ngIf="notification.notificationType == 'event'"
                class="event-content"
                (click)="seeEventDetails(notification)"
            >
                <div class="event-image notification-image" *ngIf="notification.gameMainImageFiles">
                    <img [src]="notification.gameMainImageFiles[0]" alt="User Image" />
                </div>
                <div class="notification-additional-info">
                    {{ notification.havenTitle }}
                </div>
                <div class="notification-user-title">
                    {{ notification.eventTitle }}
                </div>
                <div class="notification-text">
                    <div class="event-start-date">
                        {{ notification.eventStart | date : 'EEE, MMM d, h:mma' }} -
                        {{ notification.eventEnd | date : 'h:mma' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-4">
    <button mat-button class="m-2 gh-button gh-button-primary" (click)="clearAllNotifications(3)">
        Clear 3 Old Notifications
    </button>
    <button mat-button class="m-2 gh-button gh-button-primary" (click)="clearAllNotifications(20)">
        Clear 20 Old Notifications
    </button>
</div>
