<div class="parent-container">

    <button mat-raised-button (click)="shortcut(4)">shortcut 4 teams</button>
    <button mat-raised-button (click)="shortcut(8)" class="ml-2">shortcut 8 teams</button>
    <button mat-raised-button (click)="shortcut(16)" class="ml-2">shortcut 16 teams</button>
    <button mat-raised-button (click)="shortcut(32)" class="ml-2">shortcut 32 teams</button>
    <button mat-raised-button (click)="shortcut(64)" class="ml-2">shortcut 64 teams</button>
    <button mat-raised-button (click)="shortcut(128)" class="ml-2">shortcut 128 teams</button>
    

    <div class="row mt-4">
        <div class="col-2">
            <mat-form-field>
                <mat-label>Number of teams</mat-label>
                <mat-select (selectionChange)="setNumberOfTeams($event)">
                    <mat-option *ngFor="let option of numberOfTeamOptions" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-radio-group aria-label="Select an option" (change)="setIsRandomSetup($event)">
                <span class="radio-button-span">
                    <mat-radio-button value="true" [checked]="true">Randomize start games</mat-radio-button>
                </span>
                <span class="radio-button-span">
                    <mat-radio-button value="false">I'll do it manually</mat-radio-button>
                </span>
            </mat-radio-group>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-12">
            <!-- TODO: Fix this -->
            <h4>NOTE: only shortcuts work right now, need to be able to add members to the teams or the data passed will be incorrect</h4>
            <app-autocomplete-chiplist [_items]="teamSelections" [_label]="'Add Team Names'" [_allowDuplicates]="false" [_max]="numberOfTeamsSelection" [_sourceOfInput]="'app-tournament-builder-setup'"
                [_allowCustomInput]="true" (selectionChanged)="updateTeams($event)" (maxIsReachedError)="setMaxIsReached()"></app-autocomplete-chiplist>
        </div>
    </div>

</div>