import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Subscription } from 'rxjs'
import { ADD_GAME_PUBLISHER_LIST, ADD_GAME_DESIGNER_LIST, ADD_GAME_ARTIST_LIST } from 'src/app/dashboard/constants/mock-data'
import { competitionTags, mechanicTags, settingTags, uncertaintyTags, interactionTags, atmosphereTags, skillTags, accessibilityTags } from 'src/app/dashboard/dashboard-shared/constants/games.constants'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss']
})
export class AddTagsComponent implements OnInit {

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
  }

  @Output() closeEmitter = new EventEmitter()

  undo = false

  competitionTags = competitionTags
  mechanicTags = mechanicTags
  settingTags = settingTags
  uncertaintyTags = uncertaintyTags
  interactionTags = interactionTags
  atmosphereTags = atmosphereTags
  skillTags = skillTags
  accessibilityTags = accessibilityTags

  // replace with database call to publishers table
  publishers = ADD_GAME_PUBLISHER_LIST
  designers = ADD_GAME_DESIGNER_LIST
  artists = ADD_GAME_ARTIST_LIST
  playerCount = ['1', '2', '3', '4', '5', '6', '7', '8+']


  complexityStartingValue: number = 3
  depthStartingValue: number = 3
  speedStartingValue: number = 3
  thematicStartingValue: number = 3
  playerStartingValue: number = 3
  interactionStartingValue: number = 3
  massStartingValue: number = 3
  obscurityStartingValue: number = 3

  startingValue: number = 3

  displaySliders = false
  state: string

  submitButtonText

  snackBar$: Subscription = null
  subscriptions: Subscription[] = [this.snackBar$]

  constructor(private formBuilder: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private snackbarService: SnackbarService,

    //private soundEffectsService: SoundEffectsService

  ) { }

  ngOnInit() {
  }

  close() {
    this.closeEmitter.emit()
  }

  submitTags() {
    this.closeEmitter.emit()
    this.snackbarService.openSuccessSnackBar('THANK YOU, Suggestions sent to server!')
  }

  onSubmit() {
    document.getElementById('submit-button').style.fontSize = '2rem'
    this.state = 'afterSubmit'
  }

  animEnd(event) {
    //this.closeDialog()
  }


  openSimpleSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 2000, horizontalPosition: "center", verticalPosition: "bottom", panelClass: 'ghSuccessSnackbar'
    })
  }

}
