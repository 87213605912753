import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core'
// import { CarouselComponent } from 'angular-responsive-carousel'

@Component({
  selector: 'app-content-carousel[_sourceOfInput]',
  templateUrl: './content-carousel.component.html',
  styleUrls: ['./content-carousel.component.scss']
})
export class ContentCarouselComponent implements OnInit {

  @Input() _sourceOfInput: string

  // @ViewChild('carousel', {static: false}) carousel: CarouselComponent

  isLoading = true

  carouselItems = []

  @Output() closeDialog = new EventEmitter()

  @Input() set _data(data) {
    this.carouselItems = []
    for (let d of data) {
      this.carouselItems.push({path: d})
    }
  }

  @Input() _cellsToShow = 1
  @Input() _cellsToScroll = 1
  @Input() _height = 1000
  @Input() _width = 1000
  @Input() _cellWidth = 1000
  @Input() _arrowsOutside = true
  @Input() _overflowCellsLimit = 3
  @Input() _arrowsTheme = 'light' // other option is 'dark'
 

  constructor() { 
  }


  ngOnInit() {
    this.isLoading = false
  }

  carouselIsLoaded = false
  ngAfterContentChecked() {
    if (!this.carouselIsLoaded) {
      // if (this.carousel) {
      //   this.carouselIsLoaded = true
      // }
    }
  }

  close() {
    this.closeDialog.emit(null)
  }

}
