<nav #header class="navbar navbar-dark navbar-expand-lg" id="headerId">
    <span (click)="goHome()" class="logo-container" id="logoContainerId">
        <a class="navbar-brand" #header>
            <img
                id="logo"
                src="../../../../../../assets/images/coolStuff/logo-redo-no-flag.png"
                alt=""
            />
        </a>
        <!-- <a class="navbar-brand" #logo>
            <img id="logo-alpha-badge" src="../../../../../../assets/images/coolStuff/alpha-badge.png" alt="">
        </a> -->
    </span>

    <span class="right-side-span">
        <!-- <span>
            <button mat-stroked-button class="with-colored-border gh-stroked-button gh-stroked-button-4"
                (click)="currentVersion()">
                Current Version
            </button>
        </span>
    <button mat-stroked-button class="ml-2 with-colored-border gh-stroked-button gh-stroked-button-4" (click)="version2()">
                Version 2
            </button>
    </span> -->
        <!-- <button mat-stroked-button class="ml-2 with-colored-border gh-stroked-button gh-stroked-button-4" (click)="version3()">
                Version 3
            </button> -->
        <span>
            <button
                mat-stroked-button
                class="with-colored-border gh-stroked-button gh-stroked-button-4"
                (click)="signUp()"
            >
                SIGN UP
            </button>
        </span>
        <span class="ml-3">
            <button
                mat-stroked-button
                class="with-colored-border gh-stroked-button gh-stroked-button-4"
                (click)="logIn()"
            >
                LOG IN
            </button>
        </span>
    </span>
</nav>
