export const DESKTOP_MODAL_PANEL_CLASS = 'gh-dialog'
export const MOBILE_MODAL_PANEL_CLASS = 'gh-dialog-mobile'

export const ONBOARDING_USER_LEVEL_THRESHOLD = 5;
export const ADMIN_USER_LEVEL_THRESHOLD = 10;


export const TIME_PICKER_MAP = new Map([
    [0, '12:00'],
    [1, '01:00'],
    [2, '02:00'],
    [3, '03:00'],
    [4, '04:00'],
    [5, '05:00'],
    [6, '06:00'],
    [7, '07:00'],
    [8, '08:00'],
    [9, '09:00'],
    [10, '10:00'],
    [11, '11:00'],
    [12, '12:00'],
    [13, '13:00'],
    [14, '14:00'],
    [15, '15:00'],
    [16, '16:00'],
    [17, '17:00'],
    [18, '18:00'],
    [19, '19:00'],
    [20, '20:00'],
    [21, '21:00'],
    [22, '22:00'],
    [23, '23:00']
])

export const timeIntervals1Hour = [
    { key: '12:00 am', value: 0 },
    { key: '1:00 am', value: 1 },
    { key: '2:00 am', value: 2 },
    { key: '3:00 am', value: 3 },
    { key: '4:00 am', value: 4 },
    { key: '5:00 am', value: 5 },
    { key: '6:00 am', value: 6 },
    { key: '7:00 am', value: 7 },
    { key: '8:00 am', value: 8 },
    { key: '9:00 am', value: 9 },
    { key: '10:00 am', value: 10 },
    { key: '11:00 am', value: 11 },
    { key: '12:00 pm', value: 12 },
    { key: '1:00 pm', value: 13 },
    { key: '2:00 pm', value: 14 },
    { key: '3:00 pm', value: 15 },
    { key: '4:00 pm', value: 16 },
    { key: '5:00 pm', value: 17 },
    { key: '6:00 pm', value: 18 },
    { key: '7:00 pm', value: 19 },
    { key: '8:00 pm', value: 20 },
    { key: '9:00 pm', value: 21 },
    { key: '10:00 pm', value: 22 },
    { key: '11:00 pm', value: 23 },
]

export const timeIntervals30Minutes = [
    { key: '12:00 am', value: 0 },
    { key: '12:30 am', value: 0.5 },
    { key: '1:00 am', value: 1 },
    { key: '1:30 am', value: 1.5 },
    { key: '2:00 am', value: 2 },
    { key: '2:30 am', value: 2.5 },
    { key: '3:00 am', value: 3 },
    { key: '3:30 am', value: 3.5 },
    { key: '4:00 am', value: 4 },
    { key: '4:30 am', value: 4.5 },
    { key: '5:00 am', value: 5 },
    { key: '5:30 am', value: 5.5 },
    { key: '6:00 am', value: 6 },
    { key: '6:30 am', value: 6.5 },
    { key: '7:00 am', value: 7 },
    { key: '7:30 am', value: 7.5 },
    { key: '8:00 am', value: 8 },
    { key: '8:30 am', value: 8.5 },
    { key: '9:00 am', value: 9 },
    { key: '9:30 am', value: 9.5 },
    { key: '10:00 am', value: 10 },
    { key: '10:30 am', value: 10.5 },
    { key: '11:00 am', value: 11 },
    { key: '11:30 am', value: 11.5 },
    { key: '12:00 pm', value: 12 },
    { key: '12:30 pm', value: 12.5 },
    { key: '1:00 pm', value: 13 },
    { key: '1:30 pm', value: 13.5 },
    { key: '2:00 pm', value: 14 },
    { key: '2:30 pm', value: 14.5 },
    { key: '3:00 pm', value: 15 },
    { key: '3:30 pm', value: 15.5 },
    { key: '4:00 pm', value: 16 },
    { key: '4:30 pm', value: 16.5 },
    { key: '5:00 pm', value: 17 },
    { key: '5:30 pm', value: 17.5 },
    { key: '6:00 pm', value: 18 },
    { key: '6:30 pm', value: 18.5 },
    { key: '7:00 pm', value: 19 },
    { key: '7:30 pm', value: 19.5 },
    { key: '8:00 pm', value: 20 },
    { key: '8:30 pm', value: 20.5 },
    { key: '9:00 pm', value: 21 },
    { key: '9:30 pm', value: 21.5 },
    { key: '10:00 pm', value: 22 },
    { key: '10:30 pm', value: 22.5 },
    { key: '11:00 pm', value: 23 },
    { key: '11:30 pm', value: 23.5 },
]

export const timeIntervals15Minutes = [
    { key: '12:00 am', value: 0 },
    { key: '12:15 am', value: 0.25 },
    { key: '12:30 am', value: 0.5 },
    { key: '12:45 am', value: 0.75 },

    { key: '1:00 am', value: 1 },
    { key: '1:15 am', value: 1.25 },
    { key: '1:30 am', value: 1.5 },
    { key: '1:45 am', value: 1.75 },

    { key: '2:00 am', value: 2 },
    { key: '2:15 am', value: 2.25 },
    { key: '2:30 am', value: 2.5 },
    { key: '2:45 am', value: 2.75 },

    { key: '3:00 am', value: 3 },
    { key: '3:15 am', value: 3.25 },
    { key: '3:30 am', value: 3.5 },
    { key: '3:45 am', value: 3.75 },

    { key: '4:00 am', value: 4 },
    { key: '4:15 am', value: 4.25 },
    { key: '4:30 am', value: 4.5 },
    { key: '4:45 am', value: 4.75 },

    { key: '5:00 am', value: 5 },
    { key: '5:15 am', value: 5.25 },
    { key: '5:30 am', value: 5.5 },
    { key: '5:45 am', value: 5.75 },

    { key: '6:00 am', value: 6 },
    { key: '6:15 am', value: 6.25 },
    { key: '6:30 am', value: 6.5 },
    { key: '6:45 am', value: 6.75 },

    { key: '7:00 am', value: 7 },
    { key: '7:15 am', value: 7.25 },
    { key: '7:30 am', value: 7.5 },
    { key: '7:45 am', value: 7.75 },

    { key: '8:00 am', value: 8 },
    { key: '8:15 am', value: 8.25 },
    { key: '8:30 am', value: 8.5 },
    { key: '8:45 am', value: 8.75 },

    { key: '9:00 am', value: 9 },
    { key: '9:15 am', value: 9.25 },
    { key: '9:30 am', value: 9.5 },
    { key: '9:45 am', value: 9.75 },

    { key: '10:00 am', value: 10 },
    { key: '10:15 am', value: 10.25 },
    { key: '10:30 am', value: 10.5 },
    { key: '10:45 am', value: 10.75 },

    { key: '11:00 am', value: 11 },
    { key: '11:15 am', value: 11.25 },
    { key: '11:30 am', value: 11.5 },
    { key: '11:45 am', value: 11.75 },

    { key: '12:00 pm', value: 12 },
    { key: '12:15 pm', value: 12.25 },
    { key: '12:30 pm', value: 12.5 },
    { key: '12:45 pm', value: 12.75 },

    { key: '1:00 pm', value: 13 },
    { key: '1:15 pm', value: 13.25 },
    { key: '1:30 pm', value: 13.5 },
    { key: '1:45 pm', value: 13.75 },

    { key: '2:00 pm', value: 14 },
    { key: '2:15 pm', value: 14.25 },
    { key: '2:30 pm', value: 14.5 },
    { key: '2:45 pm', value: 14.75 },

    { key: '3:00 pm', value: 15 },
    { key: '3:15 pm', value: 15.25 },
    { key: '3:30 pm', value: 15.5 },
    { key: '3:45 pm', value: 15.75 },

    { key: '4:00 pm', value: 16 },
    { key: '4:15 pm', value: 16.25 },
    { key: '4:30 pm', value: 16.5 },
    { key: '4:45 pm', value: 16.75 },

    { key: '5:00 pm', value: 17 },
    { key: '5:15 pm', value: 17.25 },
    { key: '5:30 pm', value: 17.5 },
    { key: '5:45 pm', value: 17.75 },

    { key: '6:00 pm', value: 18 },
    { key: '6:15 pm', value: 18.25 },
    { key: '6:30 pm', value: 18.5 },
    { key: '6:45 pm', value: 18.75 },

    { key: '7:00 pm', value: 19 },
    { key: '7:15 pm', value: 19.25 },
    { key: '7:30 pm', value: 19.5 },
    { key: '7:45 pm', value: 19.75 },

    { key: '8:00 pm', value: 20 },
    { key: '8:15 pm', value: 20.25 },
    { key: '8:30 pm', value: 20.5 },
    { key: '8:45 pm', value: 20.75 },

    { key: '9:00 pm', value: 21 },
    { key: '9:15 pm', value: 21.25 },
    { key: '9:30 pm', value: 21.5 },
    { key: '9:45 pm', value: 21.75 },

    { key: '10:00 pm', value: 22 },
    { key: '10:15 pm', value: 22.25 },
    { key: '10:30 pm', value: 22.5 },
    { key: '10:45 pm', value: 22.75 },

    { key: '11:00 pm', value: 23 },
    { key: '11:15 pm', value: 23.25 },
    { key: '11:30 pm', value: 23.5 },
    { key: '11:45 pm', value: 23.75 },
]