<div class="parent-component row" *ngIf="!isSubmitting; else spinner">
    <div class="col-12">
        <h1 class="centered-text">
            Rate the games you've played using the slider to get personalized suggestions. Leave
            untouched the ones you haven't played or don't have a strong opinion about.
        </h1>

        <!-- ! Make sure that card height is tall enough to accomodate games that have 2+ rows for thier titles  -- grid list sets height in HTML rather than CSS!! -->
        <mat-grid-list
            [cols]="breakpoint"
            rowHeight="280px"
            gutterSize="30px"
            (window:resize)="onResize($event)"
            *ngIf="data$ | async"
        >
            <mat-grid-tile
                *ngFor="let item of data.value; let i = index"
                [ngClass]="{ ratedTile: item.touched }"
            >
                <div class="row tile-content">
                    <div class="text-center col-12">
                        <img class="thumbnail-image" src="{{ item.imagePath }}" />
                    </div>

                    <div class="mt-4 text-center col-12">
                        <p class="title">{{ item.title }}</p>
                    </div>

                    <div class="mt-2 w-100 text-center col-12">
                        <div
                            [ngClass]="{
                                disabledSliderContainer: !items[i].touched,
                                activeSlider: items[i].touched
                            }"
                        >
                            <mat-slider
                                thumbLabel
                                tickInterval="1"
                                min="0"
                                max="10"
                                [value]="item.value"
                                class="slider"
                                (change)="onSliderTouched(i, $event)"
                            >
                            </mat-slider>
                        </div>
                    </div>

                    <div class="w-100 text-center col-12" *ngIf="items[i].touched">
                        <mat-icon
                            class="gh-icon gh-icon-clickable gh-icon-warning"
                            matTooltip="Cancel Rating"
                            [matTooltipPosition]="'right'"
                            matTooltipPosition="above"
                            (click)="cancelRating(i)"
                            >close</mat-icon
                        >
                    </div>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <div class="mt-5 col-12" *ngIf="data.value.length < dataMax">
        <div class="row">
            <div class="text-center col-12 message-col">
                <mat-card-subtitle class="gh-md-subtitle">
                    The more games you rate, the better we can serve you and personalize your
                    experience!
                </mat-card-subtitle>
            </div>
        </div>

        <!-- <div class="row" *ngIf="!loadAllGamesAtOnce">
            <div class="text-center col-12">
                <button mat-raised-button class="gh-button gh-button-secondary" (click)="loadMoreGames()">Load More
                    Games!</button>
            </div>
        </div> -->
    </div>
</div>

<ng-template #spinner>
    <div class="spinner-container">
        <app-gh-loading-spinner></app-gh-loading-spinner>
    </div>
</ng-template>
