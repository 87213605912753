<div class="tabs-container mt-2">
    <mat-tab-group #tabGroup mat-stretch-tabs class="gh-tab-group" (selectedTabChange)="onTabChange($event)">

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">group</mat-icon>
                Find Gamers
            </ng-template>

            <div class="content-component">
                <app-games-find-interest-near-you-gamers [_inputData]="inputData">
                </app-games-find-interest-near-you-gamers>
            </div>

        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="tab-icon">house</mat-icon>
                Find Havens
            </ng-template>

            <div class="content-component">
                <app-games-find-interest-near-you-havens [_inputData]="inputData">
                </app-games-find-interest-near-you-havens>
            </div>
        </mat-tab>

    </mat-tab-group>
</div>