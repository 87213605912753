import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core'
import { EventTile } from './models/event-tile.model'
import { TileState } from './enums/state-enum'
import {
    timeIntervals1Hour,
    timeIntervals30Minutes,
    timeIntervals15Minutes,
    DESKTOP_MODAL_PANEL_CLASS,
    MOBILE_MODAL_PANEL_CLASS,
} from 'src/app/app.constants'
import { AddEventModalComponent } from './components/add-event-modal/add-event-modal.component'
import { iHourTile } from './interfaces/iHourTile'
import { iRoomTile } from './interfaces/iRoomTile'
import { EventType } from './enums/event-type-enum'
import { GhDialogWrapperComponent } from '../gh-dialog-wrapper/gh-dialog-wrapper.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { iPlannerStartData } from '../../interfaces/events/IPlannerStartData'
import { EventInfoModalComponent } from './components/event-info-modal/event-info-modal.component'
import { StringHelper } from '../../../../../../../common/helpers/string-helper'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-event-builder[_sourceOfInput]',
    templateUrl: './event-builder.component.html',
    styleUrls: ['./event-builder.component.scss'],
})
export class EventBuilderComponent implements OnInit {
    @Input() _sourceOfInput: string

    inputData
    @Input() set _inputData(inputData: iPlannerStartData) {
        if (inputData) {
            this.inputData = inputData
        }
    }

    currentUserIsAdmin = false
    @Input() set _currentUserIsAdmin(currentUserIsAdmin: boolean) {
        this.currentUserIsAdmin = currentUserIsAdmin
    }

    @Output() showEventBuilder = new EventEmitter()

    @Output() eventAddedEmitter = new EventEmitter()

    title: string
    date: Date
    haven // make this type Haven eventually

    hourTiles: iHourTile[] = []
    roomTiles: iRoomTile[] = []
    eventTiles: EventTile[] = []

    rowEnds = []

    selectionIsPending = false
    selectedRootIndex = null
    selectedRootColumns

    buttonTriggeredClick = false // prevent click on button from also triggering a tile click event

    constructor(private dialog: MatDialog, private screenSizeService: ScreenSizeService) { }

    setRows(event) {
        var room: iRoomTile
        for (let e of event) {
            room = { text: e, color: 'transparent' }
            this.roomTiles.push(room)
        }
    }

    calculateRowEnds() {
        this.rowEnds = []
        var columnCount = 0
        for (var i = 0; i < this.eventTiles.length; i++) {
            if (columnCount == this.hourTiles.length - 1) {
                this.rowEnds.push(i)
                columnCount = 0
                continue
            } else if (columnCount > this.hourTiles.length - 1) {
                this.rowEnds.push(i - 1)
                i = i - 1
                columnCount = 0
                continue
            }
            columnCount = columnCount + this.eventTiles[i].getColumns()
        }
    }

    isMobileScreen = false

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.title = this.inputData.title
        this.date = this.inputData.date
        this.haven = this.inputData.haven

        let hours = this.filterHours(
            this.inputData.timeInterval,
            this.inputData.startHour,
            this.inputData.endHour,
        )

        for (let hour of hours) {
            let hourTile: iHourTile = {
                text: hour.key,
                hourValue: hour.value,
                color: 'lightgray',
            }
            this.hourTiles.push(hourTile)
        }

        for (let room of JSON.parse(this.inputData.rooms)) {
            let roomTile: iRoomTile = {
                text: room,
                color: 'transparent',
            }
            this.roomTiles.push(roomTile)
        }

        let totalCells = this.hourTiles.length * this.roomTiles.length
        for (var i = 0; i < totalCells; i++) {
            this.eventTiles.push(new EventTile(TileState.open))
        }

        this.calculateRowEnds()

        if (this.inputData.events) {
            this.initEvents(this.inputData.events)
        }
    }

    initEvents(events) {
        for (var i = 0; i < events.length; i++) {
            this.selectRoot(this.eventTiles[events[i].startIndex], events[i].startIndex)
            this.selectChildNode(events[i].endIndex)
            this.assignSelectedTilesToEvent(events[i].title, events[i].type)
            this.clearLocalSelectedTiles()
        }
    }

    filterHours(timeInterval: number, startHour: number, endHour: number) {
        var hoursList
        switch (timeInterval) {
            case 0:
                hoursList = timeIntervals1Hour
                break

            case 1:
                hoursList = timeIntervals30Minutes
                break

            case 2:
                hoursList = timeIntervals15Minutes
                break

            default:
                hoursList = timeIntervals1Hour
                break
        }

        var hoursSpan = []
        for (var i = 0; i < hoursList.length; i++) {
            if (hoursList[i].value >= startHour && hoursList[i].value <= endHour) {
                hoursSpan.push(hoursList[i])
            }
        }
        return hoursSpan
    }

    selectTile(tile: EventTile, index: number) {
        // TODO: uncomment this out to deal with admnin / non-admin
        // if (this.currentUserIsAdmin) {
        //     if (this.buttonTriggeredClick) {
        //         this.buttonTriggeredClick = false
        //         return
        //     }

        //     let state = this.eventTiles[index].getState()

        //     switch (state) {
        //         case TileState.open:
        //             if (!this.selectionIsPending) {
        //                 this.selectRoot(tile, index)
        //             }
        //             break

        //         case TileState.assigned:
        //             // do nothing
        //             break

        //         case TileState.pendingSelection:
        //             this.selectChildNode(index)
        //             break
        //     }
        // } else {
        //     alert('need to deal with non event admin clicks...')
        // }

        if (this.buttonTriggeredClick) {
            this.buttonTriggeredClick = false
            return
        }

        let state = this.eventTiles[index].getState()

        switch (state) {
            case TileState.open:
                if (!this.selectionIsPending) {
                    this.selectRoot(tile, index)
                }
                break

            case TileState.assigned:
                // do nothing
                break

            case TileState.pendingSelection:
                this.selectChildNode(index)
                break
        }
    }

    selectRoot(tile: EventTile, index: number) {
        this.selectionIsPending = true
        this.selectedRootIndex = index
        this.selectedRootColumns = tile.getColumns()
        tile.setState(TileState.selected)
        tile.setIsEventRoot(true)
        this.calculateTilesPendingAssignment(index)
    }

    calculateTilesPendingAssignment(index: number) {
        if (!this.rowEnds.includes(index)) {
            // does this.hourTiles.length need to be an even number???
            let distanceFromRowEnd = this.hourTiles.length - ((index + 1) % this.hourTiles.length)

            var endOfRowHit = false
            for (var i = distanceFromRowEnd, j = index + 1; i > 0; i--, j++) {
                if (endOfRowHit || this.eventTiles[j].getState() != TileState.open) {
                    return
                }
                endOfRowHit = this.rowEnds.includes(j)

                this.eventTiles[j].setState(TileState.pendingSelection)
            }
        }
    }

    selectChildNode(index: number) {
        let distanceFromRoot = index - this.selectedRootIndex
        this.eventTiles[this.selectedRootIndex].addColumns(distanceFromRoot)
        this.eventTiles.splice(this.selectedRootIndex + 1, distanceFromRoot)
        this.calculateRowEnds()
    }

    cancelSelection() {
        this.buttonTriggeredClick = true
        var tileState

        let numberOfTilesToRestore =
            this.eventTiles[this.selectedRootIndex].getColumns() - this.selectedRootColumns
        this.eventTiles[this.selectedRootIndex].removeColumns(numberOfTilesToRestore)

        for (let tile of this.eventTiles) {
            tileState = tile.getState()
            if (tileState == TileState.selected || tileState == TileState.pendingSelection) {
                tile.setState(TileState.open)
            }
        }

        for (
            var i = this.selectedRootIndex;
            i < this.selectedRootIndex + numberOfTilesToRestore;
            i++
        ) {
            this.eventTiles.splice(i, 0, new EventTile(TileState.open))
        }

        this.clearLocalSelectedTiles()
        this.calculateRowEnds()
    }

    clearLocalSelectedTiles() {
        this.selectionIsPending = false
        this.selectedRootIndex = null
    }

    removeColumnFromSelected() {
        this.eventTiles[this.selectedRootIndex].removeColumns(1)
        this.eventTiles.splice(this.selectedRootIndex + 1, 0, new EventTile(TileState.open))
        this.calculateRowEnds()
        this.calculateTilesPendingAssignment(this.selectedRootIndex)
    }

    assignSelectedTilesToEvent(title: string, type: EventType) {
        var tileState
        for (let tile of this.eventTiles) {
            tileState = tile.getState()
            if (tileState == TileState.selected) {
                tile.setState(TileState.assigned, title, type)
            }
            if (tileState == TileState.pendingSelection) {
                tile.setState(TileState.open)
            }
        }
    }

    getTileColumn(index: number): number {
        if ((index + 1) % this.hourTiles.length == 0) {
            return this.hourTiles.length
        } else {
            return (index + 1) % this.hourTiles.length
        }
    }

    getHourByColumn(tileColumn: number): iHourTile {
        return this.hourTiles[tileColumn - 1]
    }

    getTileRow(index: number): number {
        // can probably simplify this function to use only one for loop... too tired right now
        var rows = []
        var currentRow = []
        for (var i = 0; i < this.eventTiles.length; i++) {
            currentRow.push(i)
            if ((i + 1) % this.hourTiles.length == 0) {
                rows.push(currentRow)
                currentRow = []
            }
        }

        for (var i = 0; i < rows.length; i++) {
            if (rows[i].includes(index)) {
                return i
            }
        }
    }

    getRoomOrTableByRow(tileRow: number): iRoomTile {
        return this.roomTiles[tileRow]
    }

    openDialog() {
        let inputData = {
            tableOrRoom: this.getRoomOrTableByRow(this.getTileRow(this.selectedRootIndex) + 1),
            startHour: this.getHourByColumn(this.getTileColumn(this.selectedRootIndex)),
            hourLength: this.eventTiles[this.selectedRootIndex].getColumns(),
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: inputData.tableOrRoom.text
                    .concat(', ')
                    .concat(inputData.startHour.text)
                    .concat('  -WIP'),
                component: AddEventModalComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                inputData: inputData,
            },
        })
        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data) {
                    this.assignSelectedTilesToEvent(data.item.title, data.type)
                    this.eventAddedEmitter.emit(this.eventTiles)
                    this.clearLocalSelectedTiles()
                } else {
                    // clear pending and selected colors
                    this.cancelSelection()
                }
            })
    }

    editEvent(tile: EventTile, index: number) {
        this.selectedRootIndex = index
        tile.setState(TileState.selected)
        this.calculateTilesPendingAssignment(index)
    }

    join() {
        // TODO: if still desired, finish this functionality...
    }

    mouseEnter(index) {
        if (this.eventTiles[index].getState() != 0) {
            this.eventTiles[index].setIsHovered(true)
        }
    }

    mouseLeave(index) {
        this.eventTiles[index].setIsHovered(false)
    }

    truncate(input: string, maxCharacters: number) {
        return StringHelper.truncateString(input, maxCharacters)
    }

    showInfoButton(tile) {
        return tile.getState() == TileState.assigned
    }

    showInfo(tile) {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: tile.text,
                component: EventInfoModalComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                inputData: tile,
            },
        })
    }
}
