import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service';
import { BackendAPIGroupsService } from 'src/app/backend-api-services/backend-api-groups.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { currentStatusTypeEnums } from 'src/app/shared/enums/havens-enums';
import { GroupsService } from '../../../services/groups/groups.service';
import { ArrayHelperService } from '../../../services/helpers/array-helper.service';
import { AddOrEditGamingGroupComponent } from '../add-or-edit-gaming-group/add-or-edit-gaming-group.component';
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service';
import { SnackbarService } from '../../../services/user-action-feedback/snackbar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../../../services/users/users.service';
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-add-or-edit-gaming-group-temp',
  templateUrl: './add-or-edit-gaming-group-temp.component.html',
  styleUrls: ['./add-or-edit-gaming-group-temp.component.scss']
})
export class AddOrEditGamingGroupTempComponent implements OnInit {

  @ViewChild('fileUploader', { static: false }) fileUploader

  myFriends = new BehaviorSubject(null)
  myFriends$ = this.myFriends.asObservable()

  players = new BehaviorSubject([])
  players$ = this.players.asObservable()

  coreUsers = new BehaviorSubject(null)
  coreUsers$ = this.coreUsers.asObservable()
  coreUserForFilter

  zipToEdit

  otherGroupAdminsToEdit
  inputData
  @Input() set _inputData(inputData) {

    if (inputData) {
      this.inputData = inputData;
      if (inputData.groupToEdit.zipCode && inputData.groupToEdit.zipCode != null && inputData.groupToEdit.zipCode != '') {
        this.zipToEdit = inputData.groupToEdit.zipCode;
      }
      this.isEditing = this.inputData.isEditing;

      // Check if mainImageFiles exists and has at least one element, otherwise set to null or another default value
      this.mainImageToEdit = this.inputData.groupToEdit.mainImageFiles && this.inputData.groupToEdit.mainImageFiles.length > 0 ? this.inputData.groupToEdit.mainImageFiles[0] : null;

      if (this.inputData.isEditing) {
        this.groupTitle = this.inputData.groupToEdit.title;
      }

      // Ensure otherGroupAdmin is a string that can be parsed to JSON
      if (this.inputData.groupToEdit.otherGroupAdmin && typeof this.inputData.groupToEdit.otherGroupAdmin === 'string') {
        this.otherGroupAdminsToEdit = JSON.parse(this.inputData.groupToEdit.otherGroupAdmin);
      } else {
        // Handle the case where otherGroupAdmin is not a valid JSON string
        this.otherGroupAdminsToEdit = []; // or another suitable default value
      }
    }

  }

  @Output() isValideGroupNameEmitter = new EventEmitter()

  @Input() set _shouldSubmit(shouldSubmit: boolean) {
    if (shouldSubmit) {
      this.submit() // change this back from completeSubmission once ready to deal with the form validation and stuff
      // this.completeSubmission()
    }
  }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  constructor(
    private backendAPIUsersService: BackendAPIUsersService,
    private dialogRef: MatDialogRef<AddOrEditGamingGroupComponent>,
    private formBuilder: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private usersService: UsersService,
    private backendApiGamesService: BackendAPIGamesService,
    private arrayHelperService: ArrayHelperService,
    private groupsService: GroupsService,
    private backendAPIGroupsService: BackendAPIGroupsService,
    private backendApiUsersService: BackendAPIUsersService,
    private backendApiGroupsService: BackendAPIGroupsService,
    private screenSizeService: ScreenSizeService,
    private snackbarService: SnackbarService,

  ) { }

  selectedImage

  isEditing: false
  submitIsClicked: boolean
  mainImageToEdit
  user: any
  id: string
  dateCreated: Date

  gameString: string

  title: string
  playingSince: string
  description: string
  zipCode: number

  currentStatusTypes = [
    currentStatusTypeEnums.Active,
    currentStatusTypeEnums.Inactive,
    currentStatusTypeEnums.Pending,
    currentStatusTypeEnums.Archived,
  ]

  memberList = []
  collection = []

  identityForm: UntypedFormGroup

  coreGameData = new BehaviorSubject(null)
  coreGameData$ = this.coreGameData.asObservable()

  coreGroups

  isMobileScreen = false

  async ngOnInit() {


    this.usersService.myFriends$
      .pipe(untilDestroyed(this))
      .subscribe((myFriends) => {
        this.myFriends.next(myFriends)
      })

    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      if (user) {
        this.user = user
      }
    })

    this.usersService.coreUsers$
      .pipe(untilDestroyed(this))
      .subscribe(async (coreUsersResult) => {
        let coreUsersWithoutCurrentUser
        if (coreUsersResult) {
          coreUsersWithoutCurrentUser =
            await this.arrayHelperService.removeFromArray(
              coreUsersResult,
              this.user,
              true,
              'username',
            )
        }
        this.coreUsers.next(coreUsersWithoutCurrentUser)
        this.coreUserForFilter = this.coreUsers.value

        let myFriendsClean = this.myFriends.value.map(ob => ob.sk)

        let myFriendsExtraRemoved = myFriendsClean.map(str => str.replace('#friendOf#', ''))

        let friendsOnly = this.arrayHelperService.extractSubsetFromArray(
          this.coreUserForFilter,
          myFriendsExtraRemoved,
          true,
          false,
          'username',
        )

        this.coreUsers.next(friendsOnly)

        if (this.user.personalListOfNonGhUsers) {
          this.players.next(JSON.parse(this.user.personalListOfNonGhUsers))
        }
      })



    this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobileScreen: boolean) => {
      this.isMobileScreen = isMobileScreen
    })

    this.groupsService.coreGroups$.pipe(untilDestroyed(this)).subscribe(async coreGroups => {
      if (coreGroups) {
        this.coreGroups = coreGroups
      }
    })




    this.fetchCoreGameData()
    this.backendAPIUsersService.currentUser$.subscribe(user => {
      this.user = user
    })

    this.memberList = []

    this.dateCreated = new Date()

    // ! FORMS------------------------------------------------------------------------------------------------
    this.identityForm = this.formBuilder.group({
      groupAdmin: [this.isEditing ? this.inputData.groupToEdit.groupAdmin : undefined],
      title: [this.isEditing ? this.inputData.groupToEdit.title : undefined, Validators.required],
      playingSince: [this.isEditing ? this.inputData.groupToEdit.dateEstablished : undefined],
      state: [this.isEditing ? this.inputData.groupToEdit.state : undefined],
      zipCode: [this.isEditing ? this.inputData.groupToEdit.zipCode : undefined],
      activityStatus: [this.isEditing ? this.inputData.groupToEdit.activityStatus : undefined],
      description: [this.isEditing ? this.inputData.groupToEdit.description : undefined],
      playTimes: [this.isEditing ? this.inputData.groupToEdit.playTimes : undefined],
      otherGroupAdmin: [this.isEditing ? this.inputData.groupToEdit.otherGroupAdmin : undefined],
    })
    this.onStateChanged(this.inputData.groupToEdit.state)
  }

  nextCoreGameDataToken //todo need to finish next token logic

  async fetchCoreGameData() {
    let coreGameDataResult = await this.backendApiGamesService.listAllGameCoreData(1000, this.nextCoreGameDataToken)
    this.coreGameData.next(coreGameDataResult.coreGames)
    this.coreGameData.next(await this.arrayHelperService.removePrependingCharacters(this.coreGameData.value, '#'))
  }

  compareFunction(o1: any, o2: any) {
    return (o1 == o2)
  }

  close() {
    this.dialogRef.close()
  }

  setIdentity() {
    this.title = this.identityForm.get('title').value
    this.description = this.identityForm.get('description').value
    this.zipCode = this.identityForm.get('zipCode').value
  }


  selectZipCode(zipCode) {
    this.identityForm.get('zipCode').patchValue(zipCode)
  }

  submit() {
    if (this.identityForm.valid) {
      this.submitIsClicked = true
      this.completeSubmission()
    } else {
      this.snackbarService.openErrorSnackBar('ERROR: Please fill in required fields and/or resolve errors.')
    }
  }

  selectedState = ''
  onStateChanged(event) {
    this.selectedState = event
  }

  groupTitle
  groupTitleIsAvailible = true
  handleInputChange() {

    if (this.coreGroups) {
      for (let coreGroup of this.coreGroups) {

        if (coreGroup.title) {
          if (this.identityForm.get('title').value.toLowerCase().trim() == coreGroup.title.toLowerCase().trim()) {
            this.groupTitleIsAvailible = false
            break
          } else {
            this.groupTitleIsAvailible = true
          }
        }
      }
      this.isValideGroupNameEmitter.emit(this.groupTitleIsAvailible)
    }
  }

  async completeSubmission() {

    let otherGroupAdmin = JSON.stringify(this.otherGroupAdmin)


    if (this.isEditing) {

      let groupToBeEdited = {

        id: this.inputData.groupToEdit.pk,
        groupAdmin: this.inputData.groupToEdit.groupAdmin,
        pk: this.inputData.groupToEdit.pk,
        corePK: this.inputData.groupToEdit.corePK,
        coreSK: this.inputData.groupToEdit.coreSK,
        createdAt: this.inputData.groupToEdit.createdAt,
        mainImageFiles: this.inputData.groupToEdit.mainImageFiles, //! null here ?!?!?
        addedByUID: this.user.username,
        addedByTitle: this.user.title,
        userWhoUpdated: this.user.username,
        title: this.identityForm.get('title').value,
        description: this.identityForm.get('description').value,
        activityStatus: this.identityForm.get('activityStatus').value,
        groupIsPrivate: this.groupIsPrivate,
        dateEstablished: this.identityForm.get('playingSince').value,
        state: this.selectedState,
        zipCode: this.identityForm.get('zipCode').value,
        otherGroupAdmin: otherGroupAdmin,

      }

      let files = null //! Problem that if the fileUploader not trig, then files = null
      if (this.fileUploader.imageChanged) {
        files = [this.fileUploader.rawImage]
      }

      if (this.isEditing && files == null) {
        // if main image hasn't been changed then use the mainImage from input (since if files is not null, input.mainImageFiles is set in backend service rather than here)
        files = this.inputData.groupToEdit.mainImageFiles
      }

      let createGroupResult = await this.backendAPIGroupsService.UpdateGroup(groupToBeEdited, files)

      // } 
      // else {
      //   let createGroupResult = await this.backendAPIGroupsService.UpdateGroup(groupToBeEdited)
      // }

      this.close()

      // CREATING NEW GROUP
    } else {

      if (this.groupTitleIsAvailible) {


        this.close()

        var files = [this.fileUploader.rawImage]

        let groupToBeCreated
        if (this.identityForm.get('zipCode').value == null) {
          groupToBeCreated = {
            addedByUID: this.user.username,
            groupAdmin: this.user.title,
            addedByTitle: this.user.title,
            userWhoUpdated: this.user.username,
            title: this.identityForm.get('title').value,
            description: this.identityForm.get('description').value,
            activityStatus: this.identityForm.get('activityStatus').value,
            groupIsPrivate: this.groupIsPrivate,
            dateEstablished: this.identityForm.get('playingSince').value,
            state: this.selectedState,
            zipCode: '00000',
            otherGroupAdmin: otherGroupAdmin,

          }
        } else {

          groupToBeCreated = {
            addedByUID: this.user.username,
            addedByTitle: this.user.title,
            userWhoUpdated: this.user.username,
            title: this.identityForm.get('title').value,
            description: this.identityForm.get('description').value,
            activityStatus: this.identityForm.get('activityStatus').value,
            groupIsPrivate: this.groupIsPrivate,
            dateEstablished: this.identityForm.get('playingSince').value,
            state: this.selectedState,
            zipCode: this.identityForm.get('zipCode').value,
            otherGroupAdmin: otherGroupAdmin,

          }

        }

        let createGroupResult = await this.backendAPIGroupsService.CreateGroup(groupToBeCreated, files)

        let updateResult3

        // todo should add the user who created group to the group

        let input = {
          groupId: createGroupResult.id,
          UID: this.user.username,
          type: 'myFavorite',
          title: createGroupResult.title,
          userTitle: this.user.title,
          userState: this.user.state,
          userZipCode: this.user.zipCode,

          // mainImageFiles: this.user.mainImageFiles && this.user.mainImageFiles.length > 0 ? this.user.mainImageFiles : undefined
        }

        await this.backendApiGroupsService.addToMyGroups(input) //!~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~??????~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

        if (this.user.groupsIManage && this.user.groupsIManage.length > 0) { // conditional isn't working WTF?

          this.user.groupsIManage.push(createGroupResult.id)

          updateResult3 = await this.backendApiUsersService.updateUser(this.user, null)
        }
        else {
          this.user.groupsIManage = [createGroupResult.id]
          updateResult3 = await this.backendApiUsersService.updateUser(this.user, null)
        }



      } else {
        alert('form not valid')
        this.submitIsClicked = false
      }
    }
  }

  groupIsPrivate = true
  groupNameNotice = 'PUBLIC group name (REQUIRED)'
  groupPrivacyNotice = 'About our PUBLIC group'

  attemptIsPrivateChange() {
    if (this.groupPrivacyNotice == 'About our PRIVATE group') {
      this.groupPrivacyNotice = 'About our PUBLIC group'
      this.groupNameNotice = 'PUBLIC group name (REQUIRED)'
      this.groupIsPrivate = false

    } else {
      this.groupPrivacyNotice = 'About our PRIVATE group'
      this.groupNameNotice = 'PRIVATE group name (REQUIRED)'
      this.groupIsPrivate = false
    }
  }

  onYearSelected(year) {
    this.identityForm.get('playingSince').patchValue(year)
  }

  setSelectedImage(event) {
    this.selectedImage = event
  }

  activityStatusChanged(event) {
    this.identityForm.get('activityStatus').patchValue(event.value)
  }

  updatedGamesWeLoveToEmit
  onSelectionsChangedGames(event) {
    this.updatedGamesWeLoveToEmit = event.selections
  }

  updatedHavensWeLoveToEmit
  onSelectionsChangedHavens(event) {
    this.updatedHavensWeLoveToEmit = event.selections
  }


  otherGroupAdmin
  selectUsers(otherGroupAdmin) {
    this.otherGroupAdmin = otherGroupAdmin
    this.identityForm.get('otherGroupAdmin').patchValue(JSON.stringify(otherGroupAdmin))

  }






}
