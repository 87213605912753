export enum SidebarEnums {
    'MY_GH',
    'GAMES',
    'EVENTS',
    'GROUPS',
    'HAVENS'
}

export enum SidebarCategoryEnums {
    "main",
    "pro",
    "admin"
}