<!-- <app-bracket-tree></app-bracket-tree> -->



<!-- ORIGINAL TEMPLATE -->
<!-- https://codepen.io/aronduby/pen/qliuj -->

<div class="parent-container">

    <div class="winner">
        <span class="inline-span">Winner:</span>
        <span class="inline-span" style="color: black">&nbsp;{{winner}}</span>
    </div>

    <div matTooltip="{{submitStarterTeamsTooltip}}" matTooltipPosition="above">
        <button *ngIf="!setupComplete && !isRandomSetup" mat-raised-button class="gh-button gh-button-success" [disabled]="checkStarterTeams()"
            (click)="submitStarterTeams()">SUBMIT STARTER TEAMS</button>
    </div>

    <main id="tournament">

        <ul class="round" *ngFor="let r of rounds; let roundIndex = index" [ngClass]="{ indentRound: roundIndex !== 0 }"
            [ngStyle]="{ width: (roundWidthConstant/rounds.length).toString().concat('px')}">

            <li class="spacer">&nbsp;</li>

            <a class="clickable" *ngFor="let game of r; let gameIndex = index" (click)="onClick(game, roundIndex)"
                [ngClass]="{ disabled: getIsDisabled(game, roundIndex)}">

                <div class="pair" [ngStyle]="{ marginTop: getMarginTop(roundIndex, gameIndex) }">

                    <!-- Random Setup or roundIndex > 0 -->
                    <div *ngIf="isRandomSetup || roundIndex > 0 || setupComplete">

                        <li class="game game-top winner"
                            [ngClass]="{ winner: game.winner === 1, loser: game.loser === 1 }">
                           <span>
                                <p> 
                                        <b class="team-name mr-2">{{game.team1.teamName}} </b>
                                        {{game.team1.members}}
                                    </p> 
                           </span>
                            <span class="span-right">{{game.team1Score}}</span>
                        </li>

                        <li class="game game-spacer">&nbsp;</li>
                        <li class="game game-bottom "
                            [ngClass]="{ winner: game.winner === 2, loser: game.loser === 2 }">
                            <span>
                                    <p>
                                            <b class="team-name mr-2">{{game.team2.teamName}}</b>
                                            {{game.team2.members}}
                                        </p>
                            </span>
                            <span class="span-right">{{game.team2Score}}</span></li>
                    </div>

                    <!-- Manual Setup -->
                    <div *ngIf="!(isRandomSetup || roundIndex > 0 || setupComplete)">

                        <!-- team1 value not yet set -->
                        <li *ngIf="game.team1 == '?' " class="game game-top winner"
                            [ngClass]="{ winner: game.winner === 1, loser: game.loser === 1 }">
                            <input type="text" class="input-top" [formControl]="filterFormControl"
                                [matAutocomplete]="autocomplete" (focus)="clearSearch($event)" [readonly]="isLoading"
                                placeholder="Team 1">

                            <mat-autocomplete #autocomplete="matAutocomplete"
                                (optionSelected)='selectTeam($event, roundIndex, gameIndex, 1)'>
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                            <span>
                                {{game.team1Score}}
                            </span>
                        </li>

                        <!-- team1 value is set -->
                        <li *ngIf="game.team1 != '?'" class="game game-top winner"
                            [ngClass]="{ winner: game.winner === 1, loser: game.loser === 1 }">
                            <button mat-button (click)="restoreTeamToOptions(roundIndex, gameIndex, game.team1)">
                                <mat-icon class="remove-starter-team-icon">close</mat-icon>
                            </button>
                            {{game.team1}}
                            <span>{{game.team1Score}}</span>
                        </li>


                        <li class="game game-spacer">&nbsp;</li>

                        <!-- team2 value not yet set -->
                        <li *ngIf="game.team2 == '?' " class="game game-bottom "
                            [ngClass]="{ winner: game.winner === 2, loser: game.loser === 2 }">
                            <input type="text" class="input-bottom" [formControl]="filterFormControl"
                                [matAutocomplete]="autocomplete" (focus)="clearSearch($event)" [readonly]="isLoading"
                                placeholder="Team 2">

                            <mat-autocomplete #autocomplete="matAutocomplete"
                                (optionSelected)='selectTeam($event, roundIndex, gameIndex, 2)'>
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                            <span>
                                {{game.team2Score}}
                            </span>
                        </li>

                        <!-- team2 value is set -->
                        <li *ngIf="game.team2 != '?'" class="game game-bottom "
                            [ngClass]="{ winner: game.winner === 2, loser: game.loser === 2 }">
                            <button mat-button (click)="restoreTeamToOptions(roundIndex, gameIndex, game.team2)">
                                <mat-icon class="remove-starter-team-icon">close</mat-icon>
                            </button>
                            {{game.team2}}
                            <span>{{game.team2Score}}</span>
                        </li>

                    </div>


                    <div class="horizontal-line-left" *ngIf="roundIndex > 0"></div>
                    <div class="horizontal-line-right" *ngIf="roundIndex != rounds.length-1"></div>
                    <div class="vertical-line"
                        [ngStyle]="{ top: getVerticalLineTop(roundIndex), height: getVerticalLineHeight(roundIndex, false) }"
                        *ngIf="roundIndex > 0"></div>

                </div>
            </a>



            <li class="spacer">&nbsp;</li>

        </ul>

    </main>
</div>