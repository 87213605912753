import { Injectable } from '@angular/core'
import { ImageFileSuffixEnums } from '../../../../../../../common/enums/CommonEnums'
@Injectable({
  providedIn: 'root'
})
export class ImageMultiSizeService {

  constructor() { }

  createArrayForAllSizes(fileName: string): string[] {
    var result = []
    // TODO: make these constants (_S.jpg), etc.
    result = [fileName.concat(ImageFileSuffixEnums['_S.jpg']), fileName.concat(ImageFileSuffixEnums['_M.jpg']), fileName.concat(ImageFileSuffixEnums['_L.jpg']), fileName.concat(ImageFileSuffixEnums['_XL.jpg'])]
    return result
  }

}
  