import 'hammerjs'
import { enableProdMode } from '@angular/core'
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { platformBrowser } from '@angular/platform-browser' //DAVE CHAGED THIS
import { Amplify } from "aws-amplify"
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

// import aws_exports from "./aws-exports"

import awsConfig from './aws-config'

// Amplify.configure(aws_exports)
Amplify.configure(awsConfig)
if (environment.production) {
  enableProdMode()
}

platformBrowser().bootstrapModule(AppModule) //DAVE CHAGED TH IS
  .catch(err => console.error(err))
// Shay is cool