import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { BackendApiHavenTrackingService } from 'src/app/backend-api-services/backend-api-haven-tracking.service';
import { BackendAPIHavensService } from 'src/app/backend-api-services/backend-api-havens.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';

@UntilDestroy({ checkProperties: true })


@Injectable({
  providedIn: 'root'
})

export class HavensService {

  user

  coreHavens = new BehaviorSubject(null)
  coreHavens$ = this.coreHavens.asObservable()

  myHavens = new BehaviorSubject(null)
  myHavens$ = this.myHavens.asObservable()

  shouldRefreshCoreHavens = new BehaviorSubject(false)
  shouldRefreshCoreHavens$ = this.shouldRefreshCoreHavens.asObservable()

  constructor(
    private backendAPIHavensService: BackendAPIHavensService,
    private backendAPIUsersService: BackendAPIUsersService,
    private backendApiHavenTrackingService: BackendApiHavenTrackingService,
    private backendApiHavensService: BackendAPIHavensService,

  ) {

    this.init()
  }

  async init() {
    this.user = await this.initUser()
    await this.initCoreHavenData()
  }

  updateShouldRefreshCoreHavens() {
    this.shouldRefreshCoreHavens.next(true)
  }

  async initUser() {
    let currentUserRes
    await this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(async user => {
      // alert('initing user updated')

      if (user) {
        currentUserRes = user
        this.user = user
        this.initMyHavens(currentUserRes.username)
      }
    })
    return currentUserRes
  }

  async initMyHavens(username) {
    let myHavensRes = await this.fetchMyHavens(username)
    this.updateMyHavens(myHavensRes)
  }


  async fetchMyHavens(username?) {
    try {
      let myHavensResult = await this.backendApiHavenTrackingService.listMyHavens(username ? username : this.user.username, 49)

      return this.reshapeMyHavensToWorkWithMD(myHavensResult.havens)
    }
    catch (error) {

    }

  }

  updateMyHavens(myHavens) {
    this.myHavens.next(myHavens)
  }

  async initCoreHavenData() {
    let coreHavenDataRes = await this.backendAPIHavensService.listAllHavenCoreData('#', 19999, null)
    this.coreHavens.next(coreHavenDataRes.coreHavens)
  }


  getIsInMyHavensByPK(pk: string) {
    let result = false
    if (this.myHavens.value != null) {
      if (this.myHavens.value.some(h => h.PK_HAVENID
        === pk)) {
        result = true
      }
    }
    return result
  }

  async reshapeMyHavensToWorkWithMD(myHavens) {

    for (let haven of myHavens) {
      let mainImageFilesResult = await this.backendApiHavensService.GetHavenMainImageFiles(haven.PK_HAVENID)


      if (mainImageFilesResult && mainImageFilesResult != null) {
        haven.mainImageFiles = mainImageFilesResult.mainImageFiles
      }
      haven.pk = haven.PK_HAVENID // needed to fetch details in md-haven-detail
      haven.id = haven.PK_HAVENID // needed to fetch details in md-haven-detail
    }

    return myHavens
  }

}
