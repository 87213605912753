<div class="parent-container">
    <!-- <div id="delete-ad-icon-container" class="gh-elevation-5" *ngIf="displayCloseIcon">
        <mat-icon id="delete-ad-icon" class="gh-icon gh-icon-clickable" (click)="onCloseIconClicked()">close</mat-icon>
    </div> -->
    <div class="ad-container pt-2">
        <div class="img-wrapper" *ngIf="!isIFrame">
            <!-- TODO appClickTrack not workin here!?!?! -->
            <img appClickTrack data-clicktrack="sidebar-banner-ad" id="img" (click)="openAd()" class="ad gh-elevation-4"
                src="{{currentImgPath}}" [ngStyle]="{ height: getBannerHeight(), width: getBannerWidth() }"> 
        </div>
        <div *ngIf="isIFrame && (currentIFrameLink$ | async)">
            <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0"
                [src]="currentIFrameLink.value | safeResource">
            </iframe>
        </div>
    </div>

    <div class="ad-container pt-2 mt-2" *ngIf="displaySecondAd"> 
        <div class="img-wrapper" *ngIf="!isIFrame">
            <!-- TODO appClickTrack not workin here!?!?! -->
            <img appClickTrack data-clicktrack="sidebar-banner-ad" id="img2" (click)="openAd()"
                class="ad gh-elevation-4" src="{{currentImgPath}}"
                [ngStyle]="{ height: getBannerHeight(), width: getBannerWidth() }">
        </div>
        <div *ngIf="isIFrame && (currentIFrameLink$ | async)">
            <iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0"
                [src]="currentIFrameLink.value | safeResource">
            </iframe>
        </div>
    </div>

</div>