import { Component, HostListener, Input, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { FullSizedImageViewComponent } from '../full-sized-image-view/full-sized-image-view.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ImageGalleryGridService } from '../../../services/media/image-gallery-grid.service'
import { OnImageActionType } from '../../../enums/media-enums'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-image-gallery-grid[_sourceOfInput]',
    templateUrl: './image-gallery-grid.component.html',
    styleUrls: ['./image-gallery-grid.component.scss'],
})
export class ImageGalleryGridComponent implements OnInit {
    @Input() _sourceOfInput: string

    inputData
    @Input() set _inputData(inputData) {
        this.inputData = inputData
        this.imageArray = inputData.images
    }

    helperText = ''
    breakpoint: number
    imageArray = []

    currentUser

    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event): void {
        if (this.isMobileScreen && event.srcElement.nodeName != 'IMG') {
            for (let image of this.imageArray) {
                image.displayActionButtons = false
            }
        }
    }

    constructor(
        private backendApiContentService: BackendAPIContentService,
        private screenSizeService: ScreenSizeService,
        private imageGalleryGridService: ImageGalleryGridService,
        private dialog: MatDialog,
        private backendApiUsersService: BackendAPIUsersService
    ) { }

    isMobileScreen = false


    isGHAdmin
    userLevel
    async ngOnInit() {

        await this.backendApiUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((currentUser) => {
                if (currentUser) {
                    this.currentUser = currentUser
                    this.userLevel = currentUser.userLevel // comment this out to force button to be there for testing
                    this.isGHAdmin = this.userLevel > 6
                }
            })

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
                this.helperText = this.isMobileScreen ? 'Press images for actions' : 'Hover over images for actions'

                if (this.isMobileScreen) {
                    for (let image of this.imageArray) {
                        image.displayActionButtons = false
                    }
                }
            })

        if (window.innerWidth >= 1200) {
            this.breakpoint = 6
        }
        if (window.innerWidth >= 1000 && window.innerWidth < 1200) {
            this.breakpoint = 4
        }
        if (window.innerWidth >= 800 && window.innerWidth < 1000) {
            this.breakpoint = 3
        }
        if (window.innerWidth >= 600 && window.innerWidth < 800) {
            this.breakpoint = 2
        }
        if (window.innerWidth < 600) {
            this.breakpoint = 1
        }
    }


    onResize(event) {
        if (event.target.innerWidth >= 1200) {
            this.breakpoint = 6
        }
        if (event.target.innerWidth >= 1000 && event.target.innerWidth < 1200) {
            this.breakpoint = 4
        }
        if (event.target.innerWidth >= 800 && event.target.innerWidth < 1000) {
            this.breakpoint = 3
        }
        if (event.target.innerWidth >= 600 && event.target.innerWidth < 800) {
            this.breakpoint = 2
        }
        if (event.target.innerWidth < 600) {
            this.breakpoint = 1
        }
    }

    selectImage(index, item) {

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: item.title && item.title != '' ? item.title : 'Zoomed View',
                component: FullSizedImageViewComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
                inputData: {
                    itemId: 'wip',
                    image: item.paths[3],
                    numLikes: 22,
                },
            },
            height: '70%',
            width: '90%',
        })
    }

    toggleMobileButtons(item) {
        item.displayActionButtons = !item.displayActionButtons
    }


    onDeleteButtonClicked(item) {

        this.imageGalleryGridService.updateOnImageAction({
            type: OnImageActionType.delete,
            imageItem: item
        })
    }

    onUntagButtonClicked(item) {
        this.imageGalleryGridService.updateOnImageAction({
            type: OnImageActionType.untag,
            imageItem: item
        })
    }

    onLikeButtonClicked(item) {
        this.imageGalleryGridService.updateOnImageAction({
            type: OnImageActionType.like,
            imageItem: item
        })
    }
}
