<div class="parent-container" [ngClass]="{ isDesktop: !isMobileScreen }">

    <form [formGroup]="identityForm">
        <div class="row identity-container">
            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle>
                            Group Admin:
                        </mat-card-subtitle>
                    </div>
                    <div *ngIf="isEditing" class="col-12 pull-up">
                        <p>{{inputData.groupToEdit.groupAdmin}}</p>
                    </div>
                    <div *ngIf="!isEditing" class="col-12 pull-up">
                        <p>You!</p>
                    </div>


                </div>
            </div>



            <div class="col-4 pt-4">
                <span class="inline-span">
                    <mat-card-subtitle>Other Admin: </mat-card-subtitle>
                </span>
                <span class="inline-span ml-3">

                    <app-autocomplete-chiplist [_items]="coreUsers.value" [_selectedItems]="otherGroupAdminsToEdit"
                        [_label]="'2nd Player'" [_allowDuplicates]="false" [_allowCustomInput]="false"
                        [_itemsAreObjects]="true" [_singleInput]="false" [_sourceOfInput]="'app-messaging'"
                        (selectionChanged)="selectUsers($event)">
                    </app-autocomplete-chiplist>


                </span>
            </div>


            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle>
                            Created:
                        </mat-card-subtitle>
                    </div>
                    <div class="col-12 pull-up">
                        <p id="date-value">{{dateCreated | date}}</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="row input-container">

            <div class="col-12">
                <div class="row mb-3">
                    <div class="col-12 col-md-4">
                        <mat-form-field *ngIf="!isEditing" class="gh-form-field">
                            <mat-label>GroupName:</mat-label>

                            <input [(ngModel)]="groupTitle" (ngModelChange)="handleInputChange()" matInput
                                autocomplete="off" formControlName="title">

                            <span *ngIf="groupTitle && groupTitleIsAvailible && groupTitle.length > 0"
                                style="color: green;">{{groupTitle}} -
                                is AVAILABLE</span>
                            <span *ngIf="groupTitle && !groupTitleIsAvailible && groupTitle.length > 0"
                                style="color: red;">{{groupTitle}}
                                - is NOT AVAILABLE</span>
                        </mat-form-field>

                        <h5 *ngIf="isEditing">Group Name:{{groupTitle}}</h5>

                    </div>

                    <div class="col-12 col-md-4">
                        <app-gh-year-picker [_label]="'Playing Since'"
                            [_selectedYear]="this.identityForm.get('playingSince').value"
                            (yearSelectedEmitter)="onYearSelected($event)"></app-gh-year-picker>
                    </div>

                    <div class="col-12 col-md-4">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Current Status:</mat-label>
                            <mat-select [compareWith]="compareFunction"
                                (selectionChange)="activityStatusChanged($event)" formControlName="activityStatus">
                                <mat-option *ngFor="let statusType of currentStatusTypes" [value]="statusType">
                                    {{statusType}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>

                <div class="row">
                     <div class="col-12 col-md-4">
                        <app-gh-state-input [_defaultState]="this.identityForm.get('state').value"
                            (stateChangedEmitter)="onStateChanged($event)" formControlName="state"></app-gh-state-input>
                    </div>

                    <!--<div class="col-12 col-md-4">
                        <mat-form-field class="gh-form-field">
                            <input matInput type="text" placeholder="Zip Code" formControlName="zipCode">
                        </mat-form-field>
                    </div> -->

                    <div class="col-12 col-md-8">
                        <app-gh-simple-zipcode-selector [_zipToEdit]="zipToEdit ? zipToEdit : null"
                            (onZipSelectedEmitter)="selectZipCode($event)"></app-gh-simple-zipcode-selector>
                    </div>

                    <div class="col-12 col-md-4">
                        <app-gh-file-uploader (selectedImageChanged)="setSelectedImage($event)" #fileUploader
                            [_defaultImage]="mainImageToEdit" [_isEditing]="isEditing" [_isProfileImg]="false"
                            [_inputId]="'file-input-1'">
                        </app-gh-file-uploader>
                    </div>

                </div>

                <div class="row">
                    <div class="col-12 mt-3">
                        <mat-form-field class="gh-form-field">
                            <textarea matInput [placeholder]="groupPrivacyNotice"
                                formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <!-- <div class="col-12 col-md-6">
                            <app-gh-autocomplete-multiselect [_label]="'Game'" [_attributeToDisplay]="'title'"
                                [_attributeToConvertToPK]="'id'" [_currentSelections]="collection"
                                [_itemsList]="coreGameData.value" (changeEmitter)="onSelectionsChangedGames($event)">
                            </app-gh-autocomplete-multiselect>
                        </div>
                        <div class="col-12 col-md-6">
                            <app-gh-autocomplete-multiselect [_label]="'Haven'" [_attributeToDisplay]="'title'"
                                [_attributeToConvertToPK]="'id'" [_currentSelections]="collection" [_itemsList]="coreHavens"
                                (changeEmitter)="onSelectionsChangedHavens($event)">
                            </app-gh-autocomplete-multiselect>
                        </div> -->
                </div>
            </div>

        </div>
    </form>
</div>