<div class="parent-container">
    <div class="row">
        <div class="col-12 w-100">

            <form [formGroup]="form">
                <mat-form-field id="input-form-field">
                    <mat-label>{{label}}</mat-label>
                    <mat-select #selectInput [formControl]="itemsControl" multiple
                        [(ngModel)]="reshapedCurrentSelections" [compareWith]="compareFn" formControlName="selections"
                        [disabled]="isDisabled">
                        <mat-option *ngFor="let item of itemsList"
                            [value]="item">{{item[attributeToDisplay]}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

        </div>
    </div>
</div>