import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormArray,
    FormControl,
    UntypedFormArray,
    UntypedFormGroup,
} from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { StringHelperService } from '../../../services/helpers/string-helper.service'
import { ArrayHelperService } from '../../../services/helpers/array-helper.service'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'

export interface InputItem {
    title: string
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-gh-variable-length-autocomplete-selector',
    templateUrl: './gh-variable-length-autocomplete-selector.component.html',
    styleUrls: ['./gh-variable-length-autocomplete-selector.component.scss'],
})
export class GhVariableLengthAutocompleteSelectorComponent implements OnInit {
    limit = 8
    @Input() set _limit(limit: number) {
        this.limit = limit
    }

    label = 'Search'
    @Input() set _label(label: string) {
        this.label = label
    }

    hasAutocomplete = true
    @Input() set _hasAutocomplete(hasAutocomplete: boolean) {
        this.hasAutocomplete = hasAutocomplete
    }

    itemsAreObjects = false
    @Input() set _itemsAreObjects(itemsAreObjects: boolean) {
        this.itemsAreObjects = itemsAreObjects
    }

    originalOptions
    options
    @Input() set _options(options) {
        if (options) {
            this.options = options
            this.originalOptions = JSON.parse(JSON.stringify(options))
        }

        this.createForm()
    }

    currentSelections
    @Input() set _currentSelections(currentSelections) {
        this.currentSelections = currentSelections
    }

    allowCustomInput = false
    @Input() set _allowCustomInput(allowCustomInput: boolean) {
        this.allowCustomInput = allowCustomInput
    }

    submitIsClicked = false // set by parent class
    @Output() onChangeEmitter = new EventEmitter()
    isReady: boolean = false

    filteredOptions: Observable<any>[] = []
    myForm: FormGroup

    isMobileScreen = false

    constructor(
        private fb: FormBuilder,
        private stringHelperService: StringHelperService,
        private arrayHelperService: ArrayHelperService,
        private screenSizeService: ScreenSizeService,
    ) {}

    clearAll() {
        this.createForm()
    }

    createForm() {
        this.myForm = this.fb.group({
            items: this.fb.array([]),
        })
        // Always start with one empty row
        this.addSelectionItem()
        this.isReady = true
    }

    get selectionItems(): UntypedFormArray {
        return this.myForm.get('items') as UntypedFormArray
    }

    /**
     * Add a new row only if:
     * - We haven't reached the limit
     * - There isn't an existing blank row
     */
    addSelectionItem(value?: any) {
        if (this.selectionItems.length < this.limit && !this.hasBlankRow()) {
            this.selectionItems.push(this.newSelectionItem(value))
            this.ManageTitleControl(this.selectionItems.length - 1)
        }
    }

    newSelectionItem(value?): UntypedFormGroup {
        return this.fb.group({
            selection: [value ? value : undefined],
        })
    }

    ManageTitleControl(index: number) {
        var arrayControl = this.myForm.get('items') as FormArray
        this.filteredOptions[index] = arrayControl
            .at(index)
            .get('selection')
            .valueChanges.pipe(
                startWith<string | InputItem>(''),
                map((value) => (typeof value === 'string' ? value : value?.title)),
                map((title) => (title ? this._filter(title) : this.options?.slice() || [])),
            )
    }

    removeItem(i: number) {
        const controls = <FormArray>this.myForm.controls['items']
        controls.removeAt(i)
        this.filteredOptions.splice(i, 1)
        this.emitChanges()
    }

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        if (this.currentSelections && this.currentSelections.length > 0) {
            // If currentSelections are provided, replace the initial row with them
            this.removeItem(0)
            for (let item of this.currentSelections) {
                item.pk = item.id ? item.id : item.pk
                item.sk = 'BD#'.concat(item.pk)
                this.addSelectionItem(item)
            }
        }

        // used when there is no options because the only use case is custom input
        if (!this.options && (!this.currentSelections || this.currentSelections.length === 0)) {
            this.clearAll()
        }

        this.myForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.emitChanges()
        })
    }

    formIsInvalid() {
        // if form is touched but not used, dont throw an error (ex. selecting optional games)
        if (
            this.selectionItems.controls.length == 1 &&
            this.selectionItems.controls[0].value.selection == null
        ) {
            return false
        } else {
            let result = false
            this.selectionItems.controls.forEach((item) => {
                if (!this.allowCustomInput && item.value.selection && !item.value.selection.pk) {
                    result = true
                }
                if (!item.value.selection || !item.value.selection.title) {
                    result = true
                }
            })
            return result
        }
    }

    emitChanges() {
        let output = []

        if (this.options) {
            this.options = JSON.parse(JSON.stringify(this.originalOptions))
        }

        let dataIsComplete = true

        this.selectionItems.controls.forEach((item, index) => {
            if (!item.value.selection || (!this.allowCustomInput && !item.value.selection.pk)) {
                dataIsComplete = false
            } else {
                if (this.options) {
                    item.value.selection.priority = index
                    this.options = this.arrayHelperService.removeFromArray(
                        this.options,
                        item.value.selection.pk,
                        true,
                        'pk',
                    )
                }
            }

            output.push(item.value.selection)
        })

        if (dataIsComplete) {
            this.onChangeEmitter.emit(output)
        }
    }

    displayFn(inputItem?: InputItem): string | undefined {
        return inputItem ? inputItem.title : undefined
    }

    private _filter(title: string) {
        const filterValue = title.toLowerCase()
        this.submitIsClicked = false

        if (this.itemsAreObjects) {
            let optionsAsAny: any = this.options
            return optionsAsAny.filter(
                (option) => option.title.toLowerCase().indexOf(filterValue) === 0,
            )
        } else {
            return this.options.filter((option) => option.toLowerCase().indexOf(filterValue) === 0)
        }
    }

    drop(event: CdkDragDrop<{ title: string }[]>) {
        moveItemInArray(this.selectionItems.controls, event.previousIndex, event.currentIndex)
        this.emitChanges()
    }

    /**
     * Check if there's a blank row present in the form.
     * A blank row is defined as a row that doesn't have a valid selection.
     */
    hasBlankRow(): boolean {
        return this.selectionItems.controls.some((item) => {
            const val = item.value.selection
            if (!val) return true
            if (!this.allowCustomInput && !val.pk) return true
            if (!val.title) return true
            return false
        })
    }
}
