export interface iCreateOrUpdateGameInput {
  id?: string;
  pk?: string;
  title: string;
  modelPreferences?: string;
  big8?: string[];
  duration?: number;
  age?: number;
  tags?: string[];
  big8Votes?: number;
  similarGames?: string[];
  similarGamesByUserPreference?: string[];
  allPlayers?: number;
  ownershipCounts?: number;
  hopperData?: string;
  addedByUID?: string;
  minPlayers?: number;
  maxPlayers?: number;
  idealPlayers?: number;
  officialLink?: string;
  factions?: string[];
  yearPublished?: number;
  minPlaytime?: number;
  maxPlaytime?: number;
  expectedPlaytime?: number;
  minSuggestedAge?: number;
  publisherDescription?: string[];
  publishers?: iPublisher[];
  designers?: iDesigner[];
  artists?: iArtist[];
  printStatus?: GamePrintStatusEnums;
  gameAdminId?: string;
  isCrowdfunded?: boolean;
  crowdfundingHasLaunched?: boolean;
  crowdfundingLaunchDate?: number;
  crowdfundingExpiryDate?: number;
  crowdfundingSite?: string;
  crowdfundingLink?: string;
  aad?: string;
  isExpansion?: boolean;
  hasExpansions?: boolean;
  parentItem?: iParentItemInput;
  createdAt?: string;
  updatedAt?: string;
  idealPlayerVotes?: iIdealPlayersVotes;
  mainImageFiles?: string[];
  metaDataForGH?: string;
  internalGhNotes?: string;
  userLevelWhoAdded: number;
  mainImage?: string[];

  trendingFactor?: string
  trendingFactorPK?: string

  initialStarRating?: any;

  needsAttentionPK?: string;
  needsAttentionSK?: string;

  flaggedForModerationPK?: string;
  flaggedForModerationSK?: string;

  // these two lines below are only added after game approval
  corePK?: string;
  coreSK?: string;
}

export interface iApproveNewGameInput {
  id?: string;
  pk?: string;
  title: string;
  modelPreferences?: string;
  big8?: string[];
  duration?: number;
  age?: number;
  tags?: string[];
  big8Votes?: number;
  similarGames?: string[];
  similarGamesByUserPreference?: string[];
  allPlayers?: number;
  ownershipCounts?: number;
  hopperData?: string;
  addedByUID?: string;
  minPlayers?: number;
  maxPlayers?: number;
  idealPlayers?: number;
  officialLink?: string;
  factions?: string[];
  yearPublished?: number;
  minPlaytime?: number;
  maxPlaytime?: number;
  expectedPlaytime?: number;
  minSuggestedAge?: number;
  publisherDescription?: string[];
  publishers?: iPublisher[];
  designers?: iDesigner[];
  artists?: iArtist[];
  printStatus?: GamePrintStatusEnums;
  gameAdminId?: string;
  isCrowdfunded?: boolean;
  crowdfundingHasLaunched?: boolean;
  crowdfundingLaunchDate?: number;
  crowdfundingExpiryDate?: number;
  crowdfundingSite?: string;
  crowdfundingLink?: string;
  aad?: string;
  isExpansion?: boolean;
  hasExpansions?: boolean;
  parentItem?: iParentItemInput;
  createdAt?: string;
  updatedAt?: string;
  idealPlayerVotes?: iIdealPlayersVotes;
  mainImage: string;
  mainImageFiles?: string[];
  metaDataForGH?: string;
  internalGhNotes?: string;
  userLevelWhoAdded: number;

  initialStarRating?: any;

  trendingFactor: string
  trendingFactorPK: string

  needsAttentionPK?: string;
  needsAttentionSK?: string;

  flaggedForModerationPK?: string;
  flaggedForModerationSK?: string;
}

export interface iPublisher {
  username: string;
  title: string;
  createdAt: string;
  __typename: string;
}

export interface iArtist {
  username: string;
  title: string;
  createdAt: string;
  __typename: string;
}

export interface iDesigner {
  username: string;
  title: string;
  createdAt: string;
  __typename: string;
}

export interface iParentItemInput {
  id: string;
  title: string;
  expansions?: string[];
}

export interface iUpdateRatingsInput {
  id: string;
  theRating: number;
  numberRatings: number;
  userRating: iUserRating[];
}

export interface iUserRating {
  userId: string;
  rating: number;
}

export interface iUpdateIdealPlayersInput {
  id: string;

  idealPlayers: {
    numberVotes: number;
    averagedArray: number[];
    userIdealPlayersVotes: iUserIdealPlayersVotes;
  };
}

export interface iIdealPlayersVotes {
  numberVotes: number;
  averagedArray: number;
  userIdealPlayersVotes: iUserIdealPlayersVotes[];
}

export interface iUserIdealPlayersVotes {
  userVotesArray: boolean[];
  userId: string;
}

export interface iDeleteGameInput {
  id: string;
}

export interface iUpdateSingleAttributeForGameInput {
  pkName: string;
  pkValue: string;
  skName: string;
  skValue: string;
  attributeName: string;
  attributeValue: any;
}

export interface iUpdateSingleAttributeForGameTrackingInput {
  pkName: string;
  pkValue: string;
  skName: string;
  skValue: string;
  attributeName: string;
  attributeValue: any;
}

export interface iDeleteGameTrackingItemInput {
  gameId: string;
  UID: string;
  type: string;
  userLocation: number;
  title: string;
}

export interface iDeleteGameTrackingItemByPkSkInput {
  pk: string;
  sk: string;
}

export interface iIdealPlayersVotes {
  numberVotes: number;
  averagedArray: number;
  userIdealPlayersVotes: iUserIdealPlayersVotes[];
}
export interface iUserIdealPlayersVotes {
  userId: string;
  userVotesArray: boolean[];
}

//! remove this from dashboard enums and from here, and move to common/enums, and update references to common/enums
export enum GamePrintStatusEnums {
  inDevelopement,
  available,
  outOfPrint,
  na,
}

// export interface iCreateGameImageItemInput {
//   id: string
//   paths: string[][]
//   gameId: string
//   addedByUID: string
//   createdAt: string
//   title?: string
//   subtitle?: string
//   updatedAt: string
//   userLevelWhoAdded: number
// }

export interface iChangeOrUpdateGameAttributeInput {
  pkInput: string;
  skInput: string;
  attributeNameInput: string;
  attributeValueInput: string;
}

export interface iCoreGameDataItem {
  i: string;
  t: string;
  p: number;
}
export interface iCoreHavenDataItem {
  i: string;
  t: string;
  p: number;
}
