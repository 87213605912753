import { trigger, state, style, transition, animate } from '@angular/animations'
import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { FullSizedImageViewComponent } from 'src/app/dashboard/dashboard-shared/components/media/full-sized-image-view/full-sized-image-view.component'
import { SimpleLpDetailModalComponent } from 'src/app/dashboard/dashboard-shared/components/modals/logged-plays/simple-lp-detail-modal/simple-lp-detail-modal.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { StringHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/string-helper.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'


export interface iUserData {
    game: string
    haven: string
    date: string
    duration: string
    players: string
    winner: string
}
@Component({
    selector: 'app-ta-recent-game-plays',
    templateUrl: './ta-recent-game-plays.component.html',
    styleUrls: ['./ta-recent-game-plays.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state(
                'expanded',
                style({
                    height: '*',
                    minHeight: '',
                }),
            ),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
@UntilDestroy({ checkProperties: true })
export class TaRecentGamePlaysComponent implements OnInit {

    maxNotesToSelfLengthBeforeTooltip = 75

    displayedColumns: string[] = ['date', 'havenTitle', 'userTitle', 'image']
    mobileColumns = ['user', 'date', 'expand']
    mobilExpandedElement
    tooltipMaxLength = 10

    shouldLoad = false

    dataSource: MatTableDataSource<iUserData>

    gameId
    gameTitle
    data

    @Input() set _data(data) {
        this.data = data
    }

    constructor(
        private backendAPIContentService: BackendAPIContentService,
        private screenSizeService: ScreenSizeService,
        private stringHelperService: StringHelperService,
        private dialog: MatDialog
    ) { }

    isMobileScreen = false

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
        this.fetchRecentLoggedPlays()
    }

    truncate(input: string, maxCharacters: number) {
        return input ? this.stringHelperService.truncateString(input, maxCharacters) : null
    }

    async fetchRecentLoggedPlays() {
        let res = await this.backendAPIContentService.listLPByGameId(this.data.gameId, 50)
        this.dataSource = new MatTableDataSource(res.loggedPlays)
    }

    seeImageDetails(item) {
        // item.mainImageFiles = JSON.parse(item.mainImageFiles)

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: '',
                component: FullSizedImageViewComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
                inputData: {
                    caption: '',
                    image: item.mainImageFiles[2],
                    numLikes: 22,
                },
            },
            height: '70%',
            width: '90%',
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                // TODO: if like is clicked need to do stuff here
            })
    }

    viewLPDetail(element) {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Logged Play Details',
                inputData: element,
                component: SimpleLpDetailModalComponent,
                hasCloseButton: true,
                hasSubmitButton: false,
                hasCancelButton: false,
                // hasLeftActionButton: true,
                // leftActionButtonText: 'Start Over',
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }


}
