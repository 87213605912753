<div class="parent-container" [ngClass]="{ isMobileScreen: isMobileScreen }">

    <div class="row mx-0 mb-3">
        <div class="col-6">
            <mat-label class="m-4">Sound</mat-label>
            <mat-slide-toggle (change)="toggleSound($event)" [checked]="true" class="ml-2"></mat-slide-toggle>
        </div>
        <!-- <div class="col-6">
            <mat-label class="m-4">Dark Mode (experimental)</mat-label>
            <mat-slide-toggle (change)="toggleDarkMode($event)" style="color: white">Dark Mode</mat-slide-toggle>
        </div> -->

        <div class="col-6">
            <button mat-raised-button class="gh-button gh-button-secondary" (click)="this.openResetPassword()">Change
                Password</button>

        </div>
    </div>

    <form [formGroup]="accountForm">

        <!-- PREMIUM PROMT MESSAGE -->
        <!-- <div class="row" *ngIf="user && !user.isProUser; else notProUser">
            <div class="col-12">
                <mat-accordion class="gh-flat-accordion" *ngIf="user && !user.isProUser">
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="gh-expansion-panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Want Premium Features?
                            </mat-panel-title>
                            <mat-panel-description>
                                Click to learn more!
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <app-info-message [_inputData]="upgradeAccountInput" [_sourceOfInput]="'app-manage-account'">
                        </app-info-message>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div> -->
        <!-- PREMIUM THANKS MESSAGE -->
        <!-- <ng-template #notProUser>
            <div class="row">
                <div class="col-12 pl-5 mt-2 account-type-message" *ngIf="user && user.isProUser">
                    <span>
                        <p>Thanks for being a premium member!</p>
                        <p>Enjoy being Ad-free and all of the advanced features!</p>
                        <p>Enjoy being Ad-free and all of the advanced features!</p> 
                        <p>Enjoy being Ad-free and all of the advanced features!</p>
                    </span>
                </div>
            </div>
        </ng-template> -->


        <div class="areas-of-interest-container">
            <div class="row mx-0">
                <mat-card-title class="gh-xsm-title section-title">Current Area of Interest:</mat-card-title>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <app-gh-simple-zipcode-selector [_zipToEdit]="zipToEdit ? zipToEdit : null"
                        (onZipSelectedEmitter)="selectZipCode($event)"></app-gh-simple-zipcode-selector>
                </div>
            </div>
        </div>


        <div *ngIf="false" class="optional-info-container">
            <div class="row mx-0">
                <mat-card-title class="gh-xsm-title section-title">OPTIONAL Information:</mat-card-title>
            </div>
            <div class="row mx-0">
                <div class="col-12 col-md-4">
                    <mat-form-field class="gh-form-field">
                        <mat-label>Date of Birth:</mat-label>
                        <input #date matInput [matDatepicker]="picker" placeholder="Date" formControlName="dob">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>





                <div class="col-12 col-md-8">
                    <mat-card-subtitle class="gh-md-subtitle">Gender:</mat-card-subtitle>
                    <div class="mb-3">
                        <mat-radio-group name="radioGroup" formControlName="gender"
                            (change)="setSelectedGender($event)">
                            <mat-radio-button *ngFor="let gender of genderOptions" name="radioGroup"
                                [checked]="checkedGenderOptionKey == gender" [value]="gender" class="ml-4">
                                {{gender}}
                            </mat-radio-button>
                            <!-- <mat-radio-button value="Custom" name="radioGroup" class="ml-4"
                                        [checked]="checkedGenderOptionKey == 'Custom'">
                                        Custom
                                    </mat-radio-button> -->
                        </mat-radio-group>
                        <!-- <mat-form-field *ngIf="checkedGenderOptionKey == 'Custom'">
                                    <input matInput class="ml-2" placeholder="Enter custom gender"
                                        formControlName="customGenderName">
                                </mat-form-field> -->
                    </div>
                </div>





            </div>
        </div>




        <!-- PRIVACY AND COMMUNICATION PREFERENCES -->
        <!-- <div class="row preferences-parent-container">
            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">Privacy:</mat-card-title>
                    </div>
                    <div class="preferences-container">
                        <div class="col-12 toggle-button-parent-container" *ngFor="let privacySetting of privacySettings; let i = index" [ngStyle]="{ marginTop: getButtonGroupMarginTop(i) }">
                            <span class="inline-span title-container">

                                <mat-card-subtitle class="gh-md-subtitle">
                                    {{user.isArtist || user.isDeveloper || user.isPublisher || user.isContentCreator ? (privacySetting.label | proUserFriendToFollower) : (privacySetting.label)}}:  -->
        <!-- {{variable.value ? (variable.text | SomePipe) : (variable.text | pipe2)}} -->
        <!-- <mat-card-subtitle class="gh-md-subtitle">{{privacySetting.label}}:
                                </mat-card-subtitle>
                                
                            </span>
                            <span class="inline-span toggle-button-container">
                                <div>
                                    <mat-button-toggle-group #privacyButtonToggleGroup
                                        class="m-3 gh-button-toggle-group" multiple>
                                        <mat-button-toggle
                                            *ngFor="let selection of privacySetting.selections; let j = index"
                                            [checked]="selection.selected" [value]="selection"
                                            (change)="onPrivacyToggleGroupChanged(privacySetting.key, selection.key)">

                                            {{user.isArtist || user.isDeveloper || user.isPublisher || user.isContentCreator ? (selection.label | proUserFriendToFollower) : (selection.label)}}: 

                                        {{selection.label  | proUserFriendToFollower}}

                                        </mat-button-toggle>

                                    </mat-button-toggle-group>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">Communication:</mat-card-title>
                    </div>
                    <div class="preferences-container">
                        <div class="col-12 toggle-button-parent-container" *ngFor="let communicationSetting of communicationSettings; let i = index" [ngStyle]="{ marginTop: getButtonGroupMarginTop(i) }">
                            <span class="inline-span title-container">
                                <mat-card-subtitle class="gh-md-subtitle">

                                    {{user.isArtist || user.isDeveloper || user.isPublisher || user.isContentCreator ? (communicationSetting.label | proUserFriendToFollower) : (communicationSetting.label)}}:

                                    {{communicationSetting.label  | proUserFriendToFollower}}:
        
                                </mat-card-subtitle>
                            </span>
                            <span class="inline-span toggle-button-container">
                                <div>
                                    <mat-button-toggle-group class="m-3 gh-button-toggle-group" multiple>
                                        <mat-button-toggle
                                            *ngFor="let selection of communicationSetting.selections; let j = index"
                                            [checked]="selection.selected" [value]="selection"
                                            (change)="onCommunicationToggleGroupChanged(communicationSetting.key, selection.key)">
                                            {{selection.label}}</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </span>
                        </div>
                    </div> 

                 </div>
            </div>
        </div> -->


        <!-- <section class="gh-section">
            <div class="row mx-0">
                <div class="col-12">
                    <mat-card-title class="gh-xsm-title section-title">Content Settings:</mat-card-title>
                    <div class="ml-5">
                        <mat-radio-group name="radioGroup3" formControlName="contentPreference">
                            <mat-radio-button value="None" name="radioGroup" class="ml-4" [checked]="true">
                                Nobody can tag you in content
                            </mat-radio-button>
                            <mat-radio-button value="Friends" name="radioGroup" class="ml-4" [checked]="false">
                                Allow Friends to tag you in content
                            </mat-radio-button>
                            <mat-radio-button value="All" name="radioGroup" class="ml-4" [checked]="false">
                                Allow ANY user to tag you in content
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </section> -->




        <!-- <section class="gh-section">
            <div class="row mx-0">
                <div class="col-12">
                    <mat-card-title class="gh-xsm-title section-title">Messaging Preferences:</mat-card-title>
                    <div class="ml-5">
                        <mat-radio-group name="radioGroup2" formControlName="messagingPreference">
                            <mat-radio-button value="All" name="radioGroup" class="ml-4" [checked]="true">
                                Accept GH_Mail from all users
                            </mat-radio-button>
                            <mat-radio-button value="Friends" name="radioGroup" class="ml-4" [checked]="false">
                                Accept GH_Mail from Friends
                            </mat-radio-button>
                            <mat-radio-button value="None" name="radioGroup" class="ml-4" [checked]="false">
                                Do not use GH_Mail
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </section>
 -->




    </form>

</div>