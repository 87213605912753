<div class="parent-container">
    <div class="inner-container">

        <div class="col-12 text-center">
            <mat-card-subtitle class="gh-md-subtitle">{{message}}</mat-card-subtitle>
            <img src="../../../../../../assets/images/coolStuff/meeple-looking-down.png" alt="no data">
        </div>

        <div class="col-12 text-center mt-3" *ngIf="hasActionButton">
            <button mat-raised-button class="gh-button gh-stroked-button gh-stroked-button-primary with-colored-border"
                (click)="openActionComponent()">
                {{actionButtonText}}
            </button>
        </div>

    </div>
</div>