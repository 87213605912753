import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { untilDestroyed } from '@ngneat/until-destroy'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { CreateSharedLibraryItemComponent } from '../create-shared-library-item/create-shared-library-item.component'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { ProfileComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile/profile.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { isArray } from 'lodash' // If you have lodash installed
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

@Component({
    selector: 'app-shared-library',
    templateUrl: './shared-library.component.html',
    styleUrls: ['./shared-library.component.scss'],
})
export class SharedLibraryComponent implements OnInit {
    conventionDetails = new BehaviorSubject<any>(null)
    conventionDetails$ = this.conventionDetails.asObservable()
    private screenSizeSubscription: Subscription

    filterValue: string = '' // Input value for filtering
    allEvents: any[] = [] // To store the unfiltered events
    events: MatTableDataSource<any> // Changed to MatTableDataSource

    @Input() inputData: any
    @Input() user: any

    @Input() isGHAdmin: boolean

    @ViewChild('notesDialog') notesDialog: TemplateRef<any>
    gameForDeleteDialog: any
    deleteDialogRef: MatDialogRef<any>
    @ViewChild('deleteDialog', { static: true }) deleteDialog: TemplateRef<any>

    coreGames = new BehaviorSubject<any[]>(null)
    coreGames$ = this.coreGames.asObservable()

    isMobileScreen: boolean

    @ViewChild('daySort') set matSortDay(ms: MatSort) {
        if (ms) {
            this.daySort = ms
            if (this.events) {
                this.events.sort = this.daySort
            }
        }
    }
    daySort: MatSort

    constructor(
        private screenSizeService: ScreenSizeService,
        private dialog: MatDialog,
        private backendApiContentService: BackendAPIContentService,
        private backendApiGamesService: BackendAPIGamesService,
        private gamesService: GamesService,
        private backendApiUsersService: BackendAPIUsersService,
        private snackBar: MatSnackBar,

        private cdr: ChangeDetectorRef,
    ) {}
    dayViewDisplayedColumns: string[] = ['game', 'broughtBy', 'notes', 'attend', 'actions']

    async ngOnInit() {
        this.conventionDetails.next(this.inputData.convention)

        this.screenSizeSubscription = this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
                this.cdr.detectChanges()
            })
        await this.gamesService.coreGames$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreGames) => {
                if (coreGames) {
                    this.coreGames.next(coreGames)
                }
            })

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
                this.cdr.detectChanges()
            })
        this.conventionDetails.next(this.inputData.convention)
        // Fetch and store the full list of events
        await this.fetchSharedLibraryForThisConvention()
    }
    ngOnDestroy(): void {
        if (this.screenSizeSubscription) {
            this.screenSizeSubscription.unsubscribe()
        }
    }
    applyFilter() {
        const filterValueLower = this.filterValue.toLowerCase()
        this.events.filter = filterValueLower
    }
    isUserAttending(game: any): boolean {
        return game.createdByTitle.includes(this.user.title)
    }

    async fetchSharedLibraryForThisConvention() {
        try {
            const response = await this.backendApiContentService.listScheduledPlaysByConventionId(
                this.conventionDetails.value.pk,
                'sl',
                500,
            )

            this.allEvents = response.items.map((game: any) => {
                let currentPlayers = game.currentPlayers

                // Parse currentPlayers if it's a string
                if (typeof currentPlayers === 'string') {
                    try {
                        currentPlayers = JSON.parse(currentPlayers)
                    } catch (e) {
                        console.error('Error parsing currentPlayers:', e)
                        currentPlayers = []
                    }
                }

                return {
                    ...game,
                    currentPlayers: Array.isArray(currentPlayers) ? currentPlayers : [],
                    isUserOrganizer:
                        game.createdByTitle?.toLowerCase() === this.user.title?.toLowerCase(),
                }
            })

            // Initialize the MatTableDataSource with the events
            this.events = new MatTableDataSource(this.allEvents)

            // Set up filtering
            this.events.filterPredicate = (data, filter) => {
                return data.name.toLowerCase().includes(filter)
            }

            // Set up custom sorting accessor if needed
            this.events.sortingDataAccessor = (
                data: any,
                sortHeaderId: string,
            ): string | number => {
                switch (sortHeaderId) {
                    case 'game':
                        return data.name || ''
                    case 'broughtBy':
                        return data.createdByTitle || ''
                    case 'notes':
                        return data.notes || ''
                    default:
                        return data[sortHeaderId]
                }
            }

            // Assign the sort if it's already available
            if (this.daySort) {
                this.events.sort = this.daySort
            }
        } catch (error) {
            console.error('Error fetching scheduled plays:', error)
        }
    }

    openNotesDialog(notes: string): void {
        this.dialog.open(this.notesDialog, {
            data: notes,
            width: '400px',
        })
    }

    async openGameDetails(game: any): Promise<void> {
        const matchedGame = this.coreGames.value.find(
            (coreGame: any) => coreGame.title === game.name,
        )

        if (matchedGame) {
            game.pk = matchedGame.pk
        } else {
            console.error('Game not found in coreGames')
            return
        }

        try {
            let gameDetails = await this.backendApiGamesService.GetGame(game.pk)
            let inputData = {
                limitDataFecthing: true,
                game: gameDetails,
            }

            this.dialog.open(GhDialogWrapperComponent, {
                data: {
                    title: game.name,
                    component: MdGameDetailComponent,
                    hasSubmitButton: false,
                    hasCloseButton: true,
                    hasCancelButton: false,
                    inputData: inputData,
                    allowParentClose: true,
                },
                maxWidth: '90%',
                panelClass: this.isMobileScreen
                    ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                    : DESKTOP_MODAL_PANEL_CLASS,
                backdropClass: 'gh-dialog-backdrop',
                disableClose: true,
            })
        } catch (error) {
            console.error('Error fetching game details:', error)
        }
    }

    openDeleteDialog(game: any): void {
        this.gameForDeleteDialog = game
        this.deleteDialogRef = this.dialog.open(this.deleteDialog, {
            width: '400px',
            autoFocus: false,
        })
    }

    closeDeleteDialog(): void {
        this.deleteDialogRef.close()
    }
    async confirmDelete(): Promise<void> {
        await this.performDeleteEvent(this.gameForDeleteDialog)
        this.deleteDialogRef.close()
    }

    async performDeleteEvent(game: any) {
        if (
            this.isGHAdmin ||
            game.createdByTitle?.toLowerCase() === this.user.title?.toLowerCase()
        ) {
            this.events.data = this.events.data.filter((event) => event.id !== game.id)
            let deleteInput = {
                pk: game.pk,
                sk: game.sk,
            }
            await this.backendApiContentService.deleteContentItemByPkSk(deleteInput)
            await this.fetchSharedLibraryForThisConvention()

            this.snackBar.open(`Event "${game.name}" has been deleted.`, 'Close', {
                duration: 3000,
            })
        } else {
            console.error('User is not authorized to delete this event.')
            this.snackBar.open('You are not authorized to delete this event.', 'Close', {
                duration: 3000,
            })
        }
    }
    addNameToListOfUsers(game: any): void {
        if (!Array.isArray(game.currentPlayers)) {
            game.currentPlayers = []
        }

        const userName = this.user.title

        if (!game.currentPlayers.includes(userName)) {
            game.currentPlayers.push(userName)
        } else {
            game.currentPlayers = game.currentPlayers.filter((name) => name !== userName)
        }

        // Update the game in the backend
        this.updateGamePlayers(game)
    }

    private async updateGamePlayers(game: any): Promise<void> {
        const updateInput = {
            pkName: 'pk',
            pkValue: game.pk,
            skName: 'sk',
            skValue: game.sk,
            attributeName: 'currentPlayers',
            attributeValue: JSON.stringify(game.currentPlayers),
        }

        try {
            await this.backendApiContentService.updateSingleAttributeForContentItem(updateInput)
            this.snackBar.open('Game updated successfully.', 'Close', { duration: 3000 })
        } catch (error) {
            console.error('Error updating game players:', error)
            this.snackBar.open('Failed to update the game. Please try again.', 'Close', {
                duration: 3000,
            })
        }
    }

    createSharedLibraryItem(event?: Event) {
        const inputData = {
            parentInputData: this.inputData,
        }
        const dialogRef = this.dialog
            .open(GhDialogWrapperComponent, {
                data: {
                    title: 'Add your game to the Shared Library',
                    component: CreateSharedLibraryItemComponent,
                    hasSubmitButton: false,
                    hasCancelButton: false,
                    allowParentClose: true,
                    inputData: inputData,
                    hasCloseButton: true,
                },
                height: '70%',
                width: '40%',
                disableClose: true,
                panelClass: this.isMobileScreen
                    ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                    : DESKTOP_MODAL_PANEL_CLASS,
                backdropClass: 'gh-dialog-backdrop',
            })
            .afterClosed()
            .subscribe(async (result: any) => {
                if (result) {
                    // Update the data source
                    await this.fetchSharedLibraryForThisConvention()
                } else {
                    console.error('Day not found for date:', result.date)
                }
            })
    }

    async openUserDetails(userID: string) {
        let userDetails = await this.backendApiUsersService.getProfileById(userID)

        let inputData = {
            user: userDetails,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,

            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'User Details',
                component: ProfileComponent,
                inputData: inputData,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                submitButtonText: 'Reply',
                allowParentClose: true,
            },
        })
    }
}
