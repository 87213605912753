<div>
    <span class="inline-span">
        <button mat-button (click)="flag()">
            <mat-icon [ngStyle]="{ color: flagColor }">flag</mat-icon>
        </button>
    </span>
</div>

<div *ngIf="isFlagged">
    <span class="inline-span">
        <mat-card-subtitle>You flagged this for review on:</mat-card-subtitle>
    </span>

    <span class="inline-span ml-2">
        <p>{{date | date}}</p>
    </span>
</div>