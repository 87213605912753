<div class="parent-container row" [ngClass]="{ isMobileScreen: isMobileScreen }">
    <div class="col-12 text-center">
        <div class="row mb-2" *ngIf="!userIsHaven">

            <div class="col-6">
                <button mat-raised-button class="gh-button gh-stroked-button gh-stroked-button-primary with-colored-border" (click)="addToMyGames()" *ngIf="!isInMyGames">
                    <div class="button-icon-container">
                        <mat-icon class="button-icon mr-2">add</mat-icon>
                        <div>{{isMobileScreen ? 'Collection' : 'My Collection'}}</div>
                    </div>
                </button>
                <button mat-raised-button class="gh-button gh-stroked-button gh-stroked-button-warning with-colored-border" (click)="removeFromMyGames()" *ngIf="isInMyGames">
                    <div class="button-icon-container">
                        <mat-icon class="button-icon mr-2">remove</mat-icon> 
                        <div>{{isMobileScreen ? 'Collection' : 'My Collection'}}</div>
                    </div>
                </button>

            </div>

            <div class="col-6">
                <button mat-raised-button class="gh-button gh-stroked-button gh-stroked-button-primary with-colored-border" (click)="addToWishlist()" *ngIf="!isInWishlist">
                    <div class="button-icon-container">
                        <mat-icon class="button-icon mr-2">add</mat-icon>
                        <div>{{isMobileScreen ? 'Wishlist' : 'My Wishlist'}}</div>
                    </div>
                </button>
                <button mat-raised-button class="gh-button gh-stroked-button gh-stroked-button-warning with-colored-border" (click)="removeFromWishlist()" *ngIf="isInWishlist">
                    <div class="button-icon-container">
                        <mat-icon class="button-icon mr-2">remove</mat-icon>
                        <div>{{isMobileScreen ? 'Wishlist' : 'My Wishlist'}}</div>
                    </div>
                </button>

            </div>

        </div>

        <!-- <div *ngIf="userIsHaven" class="mb-2">
            <div class="row">

                <div class="col-6">
                    <button *ngIf="!isInDemoLibrary" mat-raised-button class="gh-button gh-button-primary" (click)="addToDemoLibrary()">
                        <div class="button-icon-container">
                            <mat-icon class="button-icon mr-2">add</mat-icon>
                            <div>{{isMobileScreen ? 'Wishlist' : 'My Wishlist'}}</div>
                        </div>
                    </button>
                    <button *ngIf="isInDemoLibrary" mat-raised-button class="gh-button gh-button-primary" (click)="removeFromDemoLibrary()">
                        <div class="button-icon-container">
                            <mat-icon class="button-icon mr-2">remove</mat-icon>
                            <div>Demo Library</div>
                        </div>
                    </button>
                </div>

                <div class="col-6">
                    <button *ngIf="!isInLendingLibrary" mat-raised-button class="gh-button gh-button-primary" (click)="addToLendingLibrary()">
                        <div class="button-icon-container">
                            <mat-icon class="button-icon mr-2">add</mat-icon>
                            <div>Lending LIbrary</div>
                        </div>
                    </button>
                    <button *ngIf="isInLendingLibrary" mat-raised-button class="gh-button gh-button-primary" (click)="removeFromLendingLibrary()">
                        <div class="button-icon-container">
                            <mat-icon class="button-icon mr-2">remove</mat-icon>
                            <div>Lending Library</div>
                        </div>
                    </button>
                </div>

            </div>

            <div class="row">
                <div class="col-12">
                    <button *ngIf="!isInUsuallyStocked" mat-raised-button class="gh-button gh-button-primary" (click)="addToNormallyStocked()">
                        <div class="button-icon-container">
                            <mat-icon class="button-icon mr-2">add</mat-icon>
                            <div>Normally Stocked</div>
                        </div>
                    </button>
                    <button *ngIf="isInUsuallyStocked" mat-raised-button class="gh-button gh-button-primary" (click)="removeFromNormallyStocked()">
                        <div class="button-icon-container">
                            <mat-icon class="button-icon mr-2">remove</mat-icon>
                            <div>Normally Stocked</div>
                        </div>
                    </button>
                </div>
            </div>

        </div> -->



        <!-- * ADMIN ONLY -->
        <div class="row" *ngIf="isGHAdmin">

            <div class="col-6">
                <button mat-raised-button class="gh-button gh-button-secondary" (click)="editGameDetails()">
                    {{isMobileScreen ? 'Edit Details' : 'Edit Game Details'}}
                </button>
            </div>

            <div class="col-6">
                <button mat-raised-button class="gh-button gh-button-warning" (click)="deleteGame()">
                    {{isMobileScreen ? 'Delete' : 'Delete Game'}}
                </button>
            </div>

        </div>
    </div>
</div>