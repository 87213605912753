import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { HeaderComponent } from './components/header/header.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ClickTrackDirective } from './directives/click-track.directive'
import { AuthGuardService } from './guards/auth-guard.service'
import { PublicHeaderComponent } from './components/header/components/public-header/public-header.component'
import { SignUpComponent } from './components/sign-up/sign-up.component'
import { LogInComponent } from './components/log-in/log-in.component'
import { PersonalProfessionalAccountDistinctionExpainationComponent } from './components/sign-up/components/personal-professional-account-distinction-expaination/personal-professional-account-distinction-expaination.component'
import { AutocompleteOffDirective } from './directives/autocomplete-off.directive'
import { GhDialogWrapperComponent } from '../dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { GhArticleListComponent } from './components/refactor-move-these/gh-article-list/gh-article-list.component'
import { MatInputModule } from '@angular/material/input'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatFormFieldModule } from '@angular/material/form-field'
import { AuthenticatedHeaderComponent } from './components/header/components/authenticated-header/authenticated-header.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatMenuModule } from '@angular/material/menu'
import { GhButtonComponent } from '../dashboard/dashboard-shared/components/custom-inputs/gh-button/gh-button.component'
import { StateNamePipe } from '../dashboard/dashboard-shared/pipes/stateName'

@NgModule({
    declarations: [
        HeaderComponent,
        AuthenticatedHeaderComponent,
        GhDialogWrapperComponent,
        ClickTrackDirective,
        PublicHeaderComponent,
        SignUpComponent,
        LogInComponent,
        PersonalProfessionalAccountDistinctionExpainationComponent,
        AutocompleteOffDirective,
        GhArticleListComponent,
        GhButtonComponent,
        StateNamePipe,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatDividerModule,
        MatTabsModule,
        MatListModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatDividerModule,
        MatTooltipModule,
        MatSelectModule,
        MatMenuModule,
        CommonModule,
    ],
    exports: [
        HeaderComponent,
        AuthenticatedHeaderComponent,
        GhArticleListComponent,
        GhButtonComponent,
        StateNamePipe,
        // DIRECTIVES
        ClickTrackDirective,
        AutocompleteOffDirective,
    ],
    providers: [AuthGuardService],
})
export class SharedModule {}
