export enum HopperCompetitionLevelEnums {
    'casual' = 'Casual',
    'serious' = 'Serious',
    'competitive' = 'Competitive',
    'tournament' = 'Tournament'
}

export enum HopperTypeEnums {
    'events' = 'Events',
    'games' = 'Games',
    'havens' = 'Havens'
}