<div class="gh-map-container">
    <div #map id="map"></div>
    <mat-card-subtitle class="mt-4">Selected Address: {{selectedLocation.address}}</mat-card-subtitle>
    <!-- <h6>Neighborhood: {{selectedNeighborhood}}</h6>
    <h6>Zip Code: {{selectedZipCode}}</h6>
    <h6>City: {{selectedCity}}</h6>
    <h6>County: {{selectedCounty}}</h6>
    <h6>State: {{selectedState}}</h6>
    <h6>Country: {{selectedCountry}}</h6> -->
</div>

<link rel="stylesheet"
    href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.css" type="text/css">