import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { iReviewData } from '../../../interfaces/reviews-articles/IReviewAndArticleData'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { defaultRatingConst } from '../../../constants/games.constants'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { REMOVE_IMG_TAG_FROM_HTML_REGEX } from '../../../../../../../../common/constants/regex'
import { GamesService } from '../../../services/games/games.service'
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service'
import { ContentService } from '../../../services/content/content.service'
import { HavensService } from '../../../services/havens/havens.service'
import { BehaviorSubject } from 'rxjs-compat'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS, ONBOARDING_USER_LEVEL_THRESHOLD } from 'src/app/app.constants'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { BulkRateGamesComponent } from '../../ratings/bulk-rate-games/bulk-rate-games.component'
import { MatDialogRef, MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-create-review[_sourceOfInput]',
  templateUrl: './create-review.component.html',
  styleUrls: ['./create-review.component.scss']
})
export class CreateReviewComponent implements OnInit {

  onboardingUserLevelThreshold = ONBOARDING_USER_LEVEL_THRESHOLD

  tinyText

  selectedGame
  selectedHaven
  selectedReviewType

  coreHavens = ['sdflkj']
  coreGames

  myReviews

  myRatings = new BehaviorSubject(null)
  myRatings$ = this.myRatings.asObservable()

  isReviewingGame = true
  isReviewingHaven = false

  currentUserHasAlreadyReviewedThisGame = false
  currentUserHasAlreadyReviewedThisHaven = false

  reviewImage
  initialRatingsAreEnabled = true
  initialRatingsCheckboxMessage = 'Check the initial ratings checkbox to modify.'

  @Input() _sourceOfInput: string

  @Input() set _shouldTrigger(shouldTrigger) {
    if (shouldTrigger) {
      this.submitReview()
    }
  }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submitReview()
    }
  }


  inputData
  @Input() set _inputData(inputData) {

    this.inputData = inputData
    this.selectedGame = this.inputData.item
    this.myStarRating = inputData.myStarRating

    if (inputData.reviewType == "HAVEN") {
      this.selectedReviewType = "HAVEN"
    } else {
      this.selectedReviewType = "GAME"
    }

  }

  @Output() onReviewFormChangeEmitter = new EventEmitter()
  @Output() imageChangedEmitter = new EventEmitter()

  editorForm: UntypedFormGroup
  editorContent: string
  newArticle: string = ''

  myStarRating
  user
  config
  item

  complexityRating: number = defaultRatingConst
  depthRating: number = defaultRatingConst
  durationRating: number = defaultRatingConst
  interactionRating: number = defaultRatingConst
  competitionRating: number = defaultRatingConst
  playersRating: number = defaultRatingConst
  chanceRating: number = defaultRatingConst
  thematicRating: number = defaultRatingConst

  // TODO WE NEED TO MODIFY THIS VIA THE INPUT SO THAT IT WORKS FOR BOTH HAVEN AND GAME

  videoExistsInPost: boolean
  imageExistsInPost: boolean
  hasPottyWords: boolean

  constructor(
    private dialogRef: MatDialogRef<CreateReviewComponent>,
    private formBuilder: UntypedFormBuilder,
    private backendAPIUsersService: BackendAPIUsersService,
    private backendApiContentService: BackendAPIContentService,
    private gamesService: GamesService,
    private havensService: HavensService,
    private backendApiGameTrackingService: BackendApiGameTrackingService,
    private contentService: ContentService,
    private screenSizeService: ScreenSizeService,
    private dialog: MatDialog
  ) { }


  isMobileScreen = false
  async ngOnInit() {

    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })

    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })

    await this.contentService.myRatings$.pipe(untilDestroyed(this)).subscribe(async myRatings => {
      if (myRatings) {
        this.myRatings.next(myRatings)
      }
    })

    await this.contentService.myReviews$.pipe(untilDestroyed(this)).subscribe(async myReviews => {
      if (myReviews) {
        this.myReviews = myReviews
      }
    })

    await this.gamesService.coreGames$.pipe(untilDestroyed(this)).subscribe(async coreGames => {
      if (coreGames) {
        this.coreGames = coreGames
      }
    })

    await this.havensService.coreHavens$.pipe(untilDestroyed(this)).subscribe(async coreHavens => {
      if (coreHavens) {
        this.coreHavens = coreHavens
      }
    })

    this.editorForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(40)]],
      editor: undefined,
      rating: undefined,
      // gameToReview: undefined,
      // havenToReview: undefined,
      complexityRating: [{ value: this.complexityRating, disabled: !this.initialRatingsAreEnabled }],
      depthRating: [{ value: this.depthRating, disabled: !this.initialRatingsAreEnabled }],
      durationRating: [{ value: this.durationRating, disabled: !this.initialRatingsAreEnabled }],
      interactionRating: [{ value: this.interactionRating, disabled: !this.initialRatingsAreEnabled }],
      competitionRating: [{ value: this.competitionRating, disabled: !this.initialRatingsAreEnabled }],
      playersRating: [{ value: this.playersRating, disabled: !this.initialRatingsAreEnabled }],
      chanceRating: [{ value: this.chanceRating, disabled: !this.initialRatingsAreEnabled }],
      thematicRating: [{ value: this.thematicRating, disabled: !this.initialRatingsAreEnabled }]
    })

    // todo Need help getting Haven to be selected properly in the Ui


    this.onChanges()
    // this.editorForm.get('gameToReview').setValue(this.inputData.item.title)
  }

  onChanges(): void { // todo we will need to unsubscribe or use the NgNeat thingy
    this.editorForm.valueChanges.subscribe(value => {

      let textOnlyVersionOfReview
      try {
        textOnlyVersionOfReview = this.tinyText.replace(REMOVE_IMG_TAG_FROM_HTML_REGEX, "")
      }
      catch (error) { }

      this.onReviewFormChangeEmitter.emit(
        {
          form: value,
          rating: this.myStarRating,
          gameToReview: this.selectedGame,
          havenToReview: this.selectedHaven,
          textOnlyVersionOfReview: textOnlyVersionOfReview
        }
      )
    })
  }


  handleEvent($event) { // nothing needed from event
  }

  maxLength(e) {
    if (e.editor.getLength() > 2000) {
      e.editor.deleteText(1999, e.editor.getLength())
      alert('Max Length Reached')
    }
  }

  async setStarRating(event) {
    this.myStarRating = event

    if (this.selectedGame) {
      await this.backendApiGameTrackingService.createGameTrackingAddRating({
        gameId: this.selectedGame.id,
        UID: this.user.username,
        type: 'rating',
        userState: this.user.state,
        userZipCode: this.user.zipCode,
        title: this.selectedGame.title,
        userTitle: this.user.title,
        mainImageFiles: null, //! fix this!!!
        rating: this.myStarRating
      })

      this.contentService.fetchCurrentUserRatings()
    }

  }

  onSubmitText() {
    this.newArticle = this.editorForm.get('editor').value
  }


  selectSubject(event) {
    this.selectedReviewType = event.value
  }








  selectedImage
  selectedImageChanged(event) {
    this.selectedImage = event
    this.imageChangedEmitter.emit(event)
  }











  selectGame(game) {
    this.selectedGame = game[0]

    if (this.myReviews != undefined) {


      if (this.myReviews.some(r => r.gameToReview == this.selectedGame.title)) {
        this.currentUserHasAlreadyReviewedThisGame = true
      }
      for (let rating of this.myRatings.value) {
        if (game[0].pk == rating.pk_GAMEID) {
          this.myStarRating = rating.rating
        }
      }
    }
    this.isReviewingGame = true
    this.isReviewingHaven = false
  }

  selectHaven(haven) {
    this.selectedHaven = haven[0]
    if (this.myReviews != undefined) {

      if (this.myReviews.some(r => r.title === this.selectedHaven.title)) {
        this.currentUserHasAlreadyReviewedThisHaven = true
      }
    }
    this.isReviewingGame = false
    this.isReviewingHaven = true

    // ! something is differnt here

    // let selectedHavenToEmit = {
    //   havenId: haven[0].pk,
    //   havenTitle: haven[0].title
    // }
    // this.selectedHavenToTagEmitter.emit(selectedHavenToEmit)

  }


  clearSelectedGame() {
    this.selectedGame = undefined
    this.currentUserHasAlreadyReviewedThisGame = false
  }

  async submitReview() {

    let reviewHTML = this.editorForm.get('editor').value

    if (reviewHTML != null && reviewHTML != '') {

      let textOnlyVersionOfReview = this.tinyText.replace(REMOVE_IMG_TAG_FROM_HTML_REGEX, "")

      if (this.isReviewingGame) {

        if (this.selectedGame.title) {

          let review: iReviewData = {
            addedByUID: this.user.username,
            userLevelWhoAdded: this.user.userLevel,
            title: this.editorForm.get('title').value,
            editor: this.tinyText,
            textOnlyVersionOfReview: textOnlyVersionOfReview,
            rating: this.myStarRating,
            gameToReview: this.selectedGame.title,
            createdByUID: this.user.username,
            createdByTitle: this.user.title,
            big8: [
              this.editorForm.get('complexityRating').value,
              this.editorForm.get('depthRating').value,
              this.editorForm.get('durationRating').value,
              this.editorForm.get('interactionRating').value,
              this.editorForm.get('competitionRating').value,
              this.editorForm.get('playersRating').value,
              this.editorForm.get('chanceRating').value,
              this.editorForm.get('thematicRating').value
            ],
            reviewImage: '',
          }

          if (review.gameToReview == null) {
            review.gameToReview = undefined
          }

          let reviewResult = await this.backendApiContentService.createReviewItem(review, this.selectedImage)
          this.contentService.fetchCurrentUserAllContent()

          if (reviewResult) {
            let input = {
              gameId: this.selectedGame.pk ? this.selectedGame.pk : this.selectedGame.id,
              UID: this.user.username,
              type: 'rating',
              userState: this.user.state,
              userZipCode: this.user.zipCode,
              title: this.selectedGame.title,
              userTitle: this.user.title,
              mainImageFiles: null, //! fix this!!!
              rating: this.myStarRating
            }

            // ! WORKS FOR GAMES
            let ratingRes = await this.backendApiGameTrackingService.createGameTrackingAddRating(input)

          }


          this.dialogRef.close()

        } else {
          console.log('No Review Data')
        }
      }

      // todo HAVEN Review **********************************
      if (this.isReviewingHaven) {

        if (this.selectedHaven.title) {

          let havenToReview = this.selectedHaven.id
          let review: iReviewData = {
            addedByUID: havenToReview.toLowerCase(),
            userLevelWhoAdded: this.user.userLevel,
            title: this.editorForm.get('title').value,
            editor: this.tinyText,
            textOnlyVersionOfReview: textOnlyVersionOfReview,
            rating: this.myStarRating,
            gameToReview: undefined,
            createdByUID: this.user.username,
            createdByTitle: this.user.title,
            big8: [
              this.editorForm.get('complexityRating').value,
              this.editorForm.get('depthRating').value,
              this.editorForm.get('durationRating').value,
              this.editorForm.get('interactionRating').value,
              this.editorForm.get('competitionRating').value,
              this.editorForm.get('playersRating').value,
              this.editorForm.get('chanceRating').value,
              this.editorForm.get('thematicRating').value
            ],
            reviewImage: '',
            havenToReview: havenToReview
          }

          if (havenToReview == null) {
            havenToReview = undefined
          }
          if (review.gameToReview == null) {
            review.gameToReview = undefined
          }

          let reviewResult = await this.backendApiContentService.createReviewItem(review, this.selectedImage)

          // ! THIS IS THE SOURCE OF HTE PROBLEM

          if (reviewResult) {
            let input = {
              gameId: undefined,
              UID: this.user.username,
              type: 'rating',
              userState: this.user.state,
              userZipCode: this.user.zipCode,
              title: undefined,
              userTitle: this.user.title,
              mainImageFiles: null, //todo fix this!!!
              rating: this.myStarRating,
              createdByUID: this.user.username,
              createdByTitle: this.user.title
            }

            // todo something going wrong here. does not have all the info it needs?
            // todo ERROR IN THIS LINE
            // todo ERROR IN THIS LINE
            // !  this feature is a wip. will complete rate haven and reactivate this code soon.
            // todo ERROR IN THIS LINE
            // todo ERROR IN THIS LINE
            // let ratingRes = await this.backendApiGameTrackingService.createGameTrackingAddRating(input)

          }

          this.dialogRef.close()

        } else {
          console.log('No Review Data')
        }
      }
    }
    else { alert('HTML cant be empty') }
  }


  close() {
    this.dialogRef.close()
  }

  openBulkRateGames() {
    let onboardingDialogRef = this.dialog.open(GhDialogWrapperComponent, {
      data: {
        title: 'Bulk Rate Games',
        component: BulkRateGamesComponent,
        hasSubmitButton: true,
        hasCancelButton: true,
        hasLeftActionButton: true,
        allowParentClose: true,
      },
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      disableClose: true,
      width: '90%',
      height: '90%',
    })

    onboardingDialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => { })
  }
}
