


<mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let game of games.publisherGames">
        <span class="inline-span">
            <mat-icon class="gh-icon">casino</mat-icon>
        </span>
        <span class="inline-span ml-3">
            {{game.sk | replaceGameHashes}}
        </span>
    </mat-list-item>
    <mat-list-item role="listitem" *ngFor="let game of games.developerGames">
        <span class="inline-span">
            <mat-icon class="gh-icon">casino</mat-icon>
        </span>
        <span class="inline-span ml-3">
            {{game.sk | replaceGameHashes}}
        </span>
    </mat-list-item>
    <mat-list-item role="listitem" *ngFor="let game of games.artistGames">
        <span class="inline-span">
            <mat-icon class="gh-icon">casino</mat-icon>
        </span>
        <span class="inline-span ml-3">
            {{game.sk | replaceGameHashes}}
        </span>
    </mat-list-item>
</mat-list>