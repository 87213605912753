<nav class="navbar navbar-dark navbar-expand-lg">
    <div id="mobile-header" *ngIf="isMobileScreen; else branding">
        <a
            id="menu-button help"
            *ngIf="showMobileMenuIcon; else showHomeButton"
            (click)="toggleSidebar()"
        >
            <mat-icon>menu</mat-icon>
        </a>

        <button
            mat-button
            class="gh-button gh-button-tertiary centered-castle-con-button-mobile help"
            (click)="openCastleConDetails()"
        >
            2025 Con
        </button>

        <ng-template #showHomeButton>
            <a id="menu-button help" (click)="goHome()" #header>
                <img
                    id="logo"
                    src="../../../../../../assets/images/coolStuff/logo-redo-no-flag.png"
                    alt=""
                />
            </a>
        </ng-template>

        <!-- Icons in mobile view -->
        <span id="mobile-experimental-button help">
            <ng-container *ngTemplateOutlet="notifications"></ng-container>
        </span>
        <span id="mobile-experimental-button help">
            <ng-container *ngTemplateOutlet="trendingData"></ng-container>
        </span>
        <span id="mobile-messaging-button help">
            <ng-container *ngTemplateOutlet="messaging"></ng-container>
        </span>
        <span id="mobile-invites-button help">
            <ng-container *ngTemplateOutlet="invites"></ng-container>
        </span>
        <span id="mobile-account-button help">
            <ng-container *ngTemplateOutlet="userStuff"></ng-container>
        </span>
    </div>

    <!-- This is all fine below here -->
    <ng-template #branding>
        <a class="navbar-brand" (click)="goHome()" #header>
            <img
                id="logo"
                src="../../../../../../assets/images/coolStuff/logo-redo-no-flag.png"
                alt=""
            />
        </a>

        <!-- Centered Castle-Con Button in Desktop View -->
        <button
            mat-button
            class="centered-castle-con-button-desktop gh-button gh-button-tertiary"
            (click)="openCastleConDetails()"
        >
            2025 Castle-Con
        </button>
    </ng-template>

    <!-- DESKTOP -->
    <!-- DESKTOP -->
    <!-- DESKTOP -->
    <div *ngIf="!isMobileScreen" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="mr-auto navbar-nav"></ul>

        <form class="form-inline my-2 my-lg-0">
            <span class="mr-4">
                <ng-container *ngTemplateOutlet="notifications"></ng-container>
            </span>

            <span class="mr-4">
                <ng-container *ngTemplateOutlet="trendingData"></ng-container>
            </span>

            <span class="mr-4">
                <ng-container *ngTemplateOutlet="friends"></ng-container>
            </span>

            <span class="mr-4">
                <ng-container *ngTemplateOutlet="messaging"></ng-container>
            </span>

            <span class="mr-4">
                <ng-container *ngTemplateOutlet="invites"></ng-container>
            </span>

            <span class="mr-4">
                <ng-container *ngTemplateOutlet="userStuff"></ng-container>
            </span>

            <span class="testing-toggles" *ngIf="showTestingToggles">
                <button
                    mat-raised-button
                    (click)="openLocationSelector()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Location Selector
                </button>
                <button
                    mat-raised-button
                    (click)="openHavenTestingButtons()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Haven Testing
                </button>
                <button
                    mat-raised-button
                    (click)="openBulkRateGames()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Bulk Rate
                </button>
                <button
                    mat-raised-button
                    (click)="openTournamentBuilderSetup()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Tourn
                </button>
                <button
                    mat-raised-button
                    (click)="openMapTesting()"
                    class="mr-3 gh-button gh-button-4"
                >
                    MAP
                </button>
                <button
                    mat-raised-button
                    (click)="openMapLocationSelectorTextOnly()"
                    class="mr-3 gh-button gh-button-4"
                >
                    LOC
                </button>

                <button
                    mat-raised-button
                    (click)="openEventBuilder()"
                    class="mr-3 gh-button gh-button-4"
                >
                    Event Builder
                </button>

                <mat-slide-toggle
                    (change)="toggleUserIsHaven($event)"
                    class="mr-2 ml-2"
                ></mat-slide-toggle>

                <mat-slide-toggle
                    (change)="toggleShowExpirimentalItems($event)"
                    class="mr-2 ml-2"
                ></mat-slide-toggle>
            </span>

            <span
                *ngIf="currentUser && currentUser.userLevel >= 6"
                #secretButton
                class="ml-5 secret-button-container"
                (mousedown)="onSecretButtonMouseDown()"
            >
                <span class="secret-button-inner-container">
                    <img
                        id="lock"
                        [ngClass]="{ keepLockVisible: keepLockVisible }"
                        src="../../../../assets/images/coolStuff/lock-only.png"
                        alt=""
                    />
                    <img
                        #key
                        id="key"
                        [ngClass]="{
                            shouldAnimateOpen: shouldAnimateOpen,
                            shouldAnimateClose: shouldAnimateClose
                        }"
                        src="../../../../assets/images/coolStuff/key-only.png"
                        alt=""
                    />
                </span>
            </span>
        </form>
    </div>
</nav>

<ng-template #friends>
    <div class="experimental-stuff">
        <button id="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon
                [matBadge]="newFriendCount > 0 ? newFriendCount : null"
                class="custom-badge notification-icon"
                style="color: #fff; font-size: 24px; vertical-align: middle"
                >person_add</mat-icon
            >
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="toggleFriendsAndFollowers()">
                <span>Friend Invites</span>
            </button>
        </mat-menu>
    </div>
</ng-template>

<ng-template #notifications>
    <div class="experimental-stuff">
        <button id="menu-button" mat-icon-button (click)="openTheFeed()">
            <a class="gb_d" aria-label="Search Labs" target="_top" role="button" tabindex="0">
                <mat-icon
                    [matBadge]="notificationCount > 0 ? notificationCount : null"
                    class="custom-badge notification-icon"
                    style="color: #fff; font-size: 24px; vertical-align: middle"
                >
                    notifications
                </mat-icon>
            </a>
        </button>
    </div>
</ng-template>

<ng-template #trendingData>
    <div class="experimental-stuff">
        <button id="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <a class="gb_d" aria-label="Search Labs" target="_top" role="button" tabindex="0">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path d="M3 3v18h18" />
                    <path fill="#ffffff" d="M18.7 8l-5.1 5.2-2.8-2.7L7 14.3" />
                </svg>
            </a>
        </button>

        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="trendingDataModal()">
                <span>Trending Games</span>
            </button>
        </mat-menu>
    </div>
</ng-template>

<ng-template #invites>
    <div class="experimental-stuff">
        <button id="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon
                [matBadge]="newInviteCount > 0 ? newInviteCount : null"
                class="custom-badge notification-icon"
                style="color: #fff; font-size: 24px; vertical-align: middle"
                >calendar_today</mat-icon
            >
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="toggleCalendarManagementPanel()">
                <span>Event Invites</span>
            </button>
        </mat-menu>
    </div>
</ng-template>

<ng-template #messaging>
    <a (click)="toggleMessagingPanel()" style="cursor: pointer">
        <mat-icon
            id="mail-icon"
            [matBadge]="messagesCount > 0 ? messagesCount : null"
            [ngClass]="{ 'custom-red-badge': messagesCount > 0 }"
            class="gh-icon gh-icon-clickable"
            >mail
        </mat-icon>
    </a>
</ng-template>

<ng-template #userStuff>
    <div class="user-stuff">
        <button id="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
            <img
                id="avatar-img"
                src="{{ userAvatarImg }}"
                alt=""
                [ngClass]="{ isDesktop: !isMobileScreen }"
            />
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editProfile()">
                <mat-icon>account_circle</mat-icon>
                <span>{{ user.value ? user.value.title : null }}</span>
                <span style="display: inline" *ngIf="isGHStaff" class="white-text">
                    (GH STAFF)
                </span>
                <span style="display: inline" *ngIf="isGHAdmin" class="white-text">
                    (GH ADMIN)
                </span>
                <span style="display: inline" *ngIf="isHaven" class="white-text"> (HAVEN) </span>
            </button>
            <button mat-menu-item [disabled]="true">
                <mat-icon>assignment_ind</mat-icon>
                <span>Level: </span>
                <span>{{ user.value ? user.value.userLevel : null }}</span>
            </button>
            <button mat-menu-item [disabled]="true">
                <mat-icon>tag_faces</mat-icon>
                <span>Points: </span>
                <span>{{ user.value ? user.value.GH_Points : null }}</span>
            </button>
            <button mat-menu-item (click)="manageAccountSettings()">
                <mat-icon>settings</mat-icon>
                <span>Account Settings</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </div>
</ng-template>
