<div class="parent-container">

    <div class="type-selection-container" *ngIf="!typeIsSelected; else showStepper">

        <!-- todo broken because of old version of currentUser fetching?
        <div class="row mt-5">
            <div class="col-12 d-flex justify-content-center align-items-center">
                <h4 class="gh-lg-subtitle">Log a play will go here</h4>
            </div> 
        </div>

         <div class="log-play-container mb-5">
            <app-share-modal-log-play></app-share-modal-log-play>
        </div> -->

        <div class="col-12 text-center">
            <!-- ! if we ever add the simple logged play thing back in below, then remove vertical from desktop version and remove margin-top in scss -->
            <mat-button-toggle-group id="desktop-button-group" #group="matButtonToggleGroup" vertical *ngIf="!isMobileScreen">
                <ng-container *ngTemplateOutlet="buttonToggleGroupInnerContainer"></ng-container>
            </mat-button-toggle-group>

            <mat-button-toggle-group id="mobile-button-group" #group="matButtonToggleGroup" vertical *ngIf="isMobileScreen">
                <ng-container *ngTemplateOutlet="buttonToggleGroupInnerContainer"></ng-container>
            </mat-button-toggle-group>
        </div>

    </div>

    <ng-template #showStepper>
        <div class="stepper-container" (click)="updateAllData()">
            <mat-horizontal-stepper #stepper [linear]="false" (selectionChange)="onStepChange($event)">

                <mat-step *ngFor="let step of conditionalSteps; let i = index" label="{{step.label}}" state="pending">

                    <div class="content-container">
                        <div [ngSwitch]="step.type">

                            <!-- ADVANCED LOG PLAY -->
                            <div *ngSwitchCase="'advancedLogPlay'">

                                <div *ngIf="step.label == 'Advanced Log Play'">
                                    <app-share-modal-advanced-log-play shareLPModalVC (formChangeEmitter)="advancedLPvalueChanged($event)" (closeEmitter)="close()">
                                    </app-share-modal-advanced-log-play>
                                </div>

                                <div *ngIf="step.label == 'Review & Submit'">
                                    <div *ngIf="advancedLogPlayData">
                                        <h3>You played: {{advancedLogPlayData.gamePlayed}}</h3>
                                        <h3 *ngIf="advancedLogPlayData.expansions">Using this expansion: {{advancedLogPlayData.expansions}}
                                        </h3>
                                        <h3>It took this long: {{advancedLogPlayData.duration}} minutes</h3>
                                        <h3>On: {{advancedLogPlayData.date | date: 'MMM d, y'}}</h3>
                                        <h3>Notes to Self:{{advancedLogPlayData.notesToSelf}}</h3>
                                    </div>
                                </div>
                            </div>

                            <!-- IMAGE -->
                            <div *ngSwitchCase="'image'">
                                <form [formGroup]="imageForm">

                                    <div *ngIf="step.label == 'Upload Image'">
                                        <app-share-modal-image (selectedImageEvent)="logEventInParent($event)" [_sourceOfInput]="'app-share-modal'">
                                        </app-share-modal-image>
                                        <mat-form-field class="gh-form-field">
                                            <input formControlName="imageTitle" matInput type="text" placeholder="Title">
                                        </mat-form-field>
                                        <mat-form-field class="gh-form-field">
                                            <input formControlName="imageCaption" matInput type="text" placeholder="Caption">
                                        </mat-form-field>

                                        <div class="pl-4">
                                            <ng-container *ngTemplateOutlet="tags"></ng-container>
                                        </div>

                                    </div>

                                    <!-- !moved tags into step 1 -->
                                    <!-- <div *ngIf="step.label == 'Tags'">
                                        <ng-container *ngTemplateOutlet="tags"></ng-container>
                                    </div> -->

                                    <!-- ! pick this back up at some point... -->
                                    <!-- <div *ngIf="step.label == 'Fix Tags'">
                                        <ng-container *ngTemplateOutlet="fixTags"></ng-container>
                                    </div>

                                    <div *ngIf="step.label == 'Review & Submit'">
                                        <h3 *ngIf="imageInfo[0].tags">Also Posting to Game: {{this.imageInfo[0].tags}}
                                        </h3>
                                        <h4>this.imageForPreviewReview{{this.imageForPreviewReview}}</h4>
                                        <app-image-or-video-gallery-grid [_items]="imageInfo" [_type]="type">
                                        </app-image-or-video-gallery-grid>
                                    </div> -->

                                </form>
                            </div>



                            <!-- VIDEO -->
                            <div *ngSwitchCase="'video'">
                                <form [formGroup]="videoForm">

                                    <div *ngIf="step.label == 'Share Video'">

                                        <app-share-modal-video (selectedVideoEvent)="captureVideoInfoEvent($event)">
                                        </app-share-modal-video>

                                        <div class="row">
                                            <div class="col-6">
                                                <mat-form-field class="gh-form-field">
                                                    <input formControlName="videoTitle" matInput type="text" placeholder="Title">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-6">
                                                <mat-form-field class="gh-form-field">
                                                    <input formControlName="videoCaption" matInput type="text" placeholder="Caption">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row radio-button-container data-group data-group-no-min-height mr-4 mt-4 mb-4 ml-4">
                                            <mat-radio-group aria-label="Select an option" (change)="selectSubject($event)">
                                                <mat-radio-button value="HTP" class="ml-5">How to Play
                                                </mat-radio-button>
                                                <mat-radio-button value="PT" class="ml-5">Play Through
                                                </mat-radio-button>
                                                <mat-radio-button value="OTHER" [checked]="true" class="ml-5">Other
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                        <p><br></p>

                                        <div class="pl-4">
                                            <ng-container *ngTemplateOutlet="tags"></ng-container>
                                        </div>
                                    </div>

                                    <!-- !moved tags into step 1 -->
                                    <!-- <div *ngIf="step.label == 'Tags'">
                                        <ng-container *ngTemplateOutlet="tags"></ng-container>
                                    </div> -->

                                    <!-- <div *ngIf="step.label == 'Fix Tags'">
                                        <ng-container *ngTemplateOutlet="fixTags"></ng-container>
                                    </div> -->
                                    <!-- <div *ngIf="step.label == 'Review & Submit'">
                                        <h3 *ngIf="videoInfo[0].tags">Also Posting to Game: {{this.videoInfo[0].gameTitle}}
                                        </h3>

                                        <app-image-or-video-gallery-grid [_origin]="'share'" [_items]="videoInfo" [_type]="type">
                                        </app-image-or-video-gallery-grid>

                                    </div> -->
                                </form>
                            </div>







                            <!-- ARTICLE -->
                            <div *ngSwitchCase="'article'">

                                <div *ngIf="step.label == 'Create Article'">
                                    <app-share-modal-article #shareArticleModalVC (imageEmitter)="setArticleImage($event)" (onArticleFormChangeEmitter)="articleValueChanged($event)">
                                    </app-share-modal-article>
                                </div>


                                <div *ngIf="step.label == 'Tags'">
                                    <ng-container *ngTemplateOutlet="tags"></ng-container>
                                </div>
                                <div *ngIf="step.label == 'Fix Tags'">
                                    <ng-container *ngTemplateOutlet="fixTags"></ng-container>
                                </div>

                                <div *ngIf="step.label == 'Review & Submit'">
                                    <!-- I know nested array is stupid but article list expects it so leaving as is for now -->
                                    <app-gh-article-table *ngIf="currentStep == 'Review & Submit'" [_articles]="[[articleData]]" [_isReadOnly]="true" [_showActionButtons]="false" [_autoExpandFirst]="true"></app-gh-article-table>
                                </div>
                            </div>

                            <!-- REVIEW -->
                            <div *ngSwitchCase="'review'">
                                <div *ngIf="step.label == 'Create Review'">
                                    <app-share-modal-review #shareReviewModalVC (onImageChangeEmitter)="onReviewImageChange($event)" (onReviewFormChangeEmitter)="onReviewFormChange($event)">
                                    </app-share-modal-review>
                                </div>
                                <div *ngIf="step.label == 'Tags'">
                                    <ng-container *ngTemplateOutlet="tags"></ng-container>
                                </div>
                                <div *ngIf="step.label == 'Fix Tags'">
                                    <ng-container *ngTemplateOutlet="fixTags"></ng-container>
                                </div>
                                <!-- <div *ngIf="step.label == 'Social'">
                                    <ng-container *ngTemplateOutlet="social"></ng-container>
                                </div> -->
                                <div *ngIf="step.label == 'Review & Submit'">

                                    <div *ngIf="currentStep == 'Review & Submit'">
                                        <div [hidden]="isMobileScreen">
                                            <app-review-list [_reviews]="[reviewDataShapedForPreview]" [_selectedIndex]="0" [_useSingleImageForPreview]="true"></app-review-list>
                                        </div>

                                        <div [hidden]="!isMobileScreen">
                                            <app-review-list-mobile [_inputData]="[reviewDataShapedForPreview]" [_expandFirstItem]="true"></app-review-list-mobile>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <!-- Link -->
                            <div *ngSwitchCase="'link'">
                                <div *ngIf="step.label == 'Create Link'">
                                    <app-share-modal-link (linkInfoChangeSecondEmitter)="onSecondLinkInfoChange($event)" (imageEmitter)="onImageSelection($event)" [_parentSubmitClicked]="submitClicked" [_sourceOfInput]="'app-share-modal[_sourceOfInput]'"></app-share-modal-link>
                                </div>

                                <div *ngIf="step.label == 'Review & Submit'">

                                    <mat-card class="mat-elevation-z12">

                                        <a>
                                            <div class="row">

                                                <div class="col-5">
                                                    <div class="img-wrapper" style="height: 100px; width: 100px;">
                                                        <img style="height: 100%; width: 100%;" *ngIf="linkImageForReview" class="image" [src]="linkImageForReview.selectedImage">
                                                    </div>
                                                </div>

                                                <div class="col-7">

                                                    <div class="row">
                                                        <mat-card-title>Title: {{linkTitlePreview}}</mat-card-title>
                                                    </div>

                                                    <div class="row">
                                                        <mat-card-subtitle>Desc: {{linkDescriptionPreview}}
                                                        </mat-card-subtitle>
                                                    </div>
                                                    <div class="row">
                                                        <mat-card-subtitle>Link: {{ linkLinkPreview | truncate:50:'...' }}
                                                        </mat-card-subtitle>

                                                    </div>
                                                </div>

                                            </div>
                                        </a>

                                        <button><span (click)="followLink()" class="material-icons" id="lauchIcon">
                                                launch </span></button>
                                    </mat-card>

                                </div>
                            </div>


                            <!-- STATUS UPDATE -->
                            <!-- <div *ngSwitchCase="'statusUpdate'">

                                <div *ngIf="step.label == 'Status'">
                                    <app-share-modal-status-update></app-share-modal-status-update>
                                </div>

                                <div *ngIf="step.label == 'Tags'">
                                    <ng-container *ngTemplateOutlet="tags"></ng-container>
                                </div>
                                <div *ngIf="step.label == 'Fix Tags'">
                                    <ng-container *ngTemplateOutlet="fixTags"></ng-container>
                                </div>
                                <div *ngIf="step.label == 'Review & Submit'">
                                </div>
                            </div> -->


                            <!-- SURVEY -->
                            <!-- <div *ngSwitchCase="'survey'">
                                <div *ngIf="step.label == 'Create Survey'">
                                    <app-share-modal-survey></app-share-modal-survey>
                                </div>
                                <div *ngIf="step.label == 'Tags'">
                                    <ng-container *ngTemplateOutlet="tags"></ng-container>
                                </div>
                                <div *ngIf="step.label == 'Fix Tags'">
                                    <ng-container *ngTemplateOutlet="fixTags"></ng-container>
                                </div>
                                <div *ngIf="step.label == 'Invite Friends'">
                                    <ng-container *ngTemplateOutlet="inviteFriends"></ng-container>
                                </div>
                                <div *ngIf="step.label == 'Review & Submit'">
                                    survey review goes here
                                </div>
                            </div> -->

                        </div>

                    </div>

                    <div class="action-button-container">
                        <button (click)="updateAllData()" mat-stroked-button matStepperPrevious *ngIf="i != 0" class="previous-button gh-stroked-button gh-stroked-button-primary">Back</button>
                        <button (click)="updateAllData()" mat-stroked-button matStepperNext *ngIf="i != conditionalSteps.length-1" class="next-button gh-stroked-button gh-stroked-button-primary">Next</button>
                    </div>
                </mat-step>

                <!-- ICON OVERRIDES -->
                <ng-template matStepperIcon="pending">
                    <mat-icon>pending</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="pending">
                    <mat-icon>pending</mat-icon>
                </ng-template>

            </mat-horizontal-stepper>
        </div>

    </ng-template>

</div>


<!-- (problematicEventTagsEmitter)="problematicEventTags = $event" -->
<ng-template #tags>
    <app-share-modal-tags (hasProblematicTags)="setHasProblematicTags()" (selectedUserToTagEmitter)="setUserTag($event)" (selectedGameToTagEmitter)="setGameTag($event)" (selectedHavenToTagEmitter)="setHavenTag($event)" (selectedGroupToTagEmitter)="setGroupTag($event)"
        (selectedEventToTagEmitter)="setEventTag($event)" (goToFixTagsEmitter)="nextStep()" (problematicPlayerTagsEmitter)="problematicPlayerTags = $event" (problematicHavenTagsEmitter)="problematicHavenTags = $event" (problematicGameTagsEmitter)="problematicGameTags = $event"
        (problematicGroupTagsEmitter)="problematicGroupTags = $event">
    </app-share-modal-tags>
</ng-template>

<!-- [_newFoundEvents]="problematicEventTags" -->
<ng-template #fixTags>
    <app-share-modal-fix-tags [_newFoundPlayers]="problematicPlayerTags" [_newFoundHavens]="problematicHavenTags" [_newFoundGames]="problematicGameTags" [_newFoundGroups]="problematicGroupTags" [_sourceOfInput]="'app-share-modal[_sourceOfInput'"></app-share-modal-fix-tags>
</ng-template>

<!-- FUTURE -->
<ng-template #inviteFriends>
    <app-share-modal-invite-friends></app-share-modal-invite-friends>
</ng-template>


<!-- !TEMPLATES BELOW ------------------------------------------------------------------------------ -->

<!-- <div *ngIf="step.label == 'Fix Tags'">
    <ng-container *ngTemplateOutlet="fixTags"></ng-container>
</div> -->

<!-- <ng-template #social>
    <app-share-modal-social></app-share-modal-social>
</ng-template> -->


<!-- <div *ngIf="step.label == 'Social'">
    <ng-container *ngTemplateOutlet="social"></ng-container>
</div> -->

<!-- <iframe class="ql-video" frameborder="0" allowfullscreen="true" src="https://www.youtube.com/embed/2cUD8dOZyu8?showinfo=0\"></iframe> -->




<ng-template #buttonToggleGroupInnerContainer>
    <!-- <mat-button-toggle (click)="setType('advancedLogPlay')">
                            Log a Play
                        </mat-button-toggle> -->

    <mat-button-toggle (click)="setType('image')">
        Image
    </mat-button-toggle>

    <mat-button-toggle (click)="setType('video')">
        Video
    </mat-button-toggle>

    <mat-button-toggle (click)="setType('article')">
        Article
    </mat-button-toggle>

    <mat-button-toggle *ngIf="!user.userIsHaven" (click)="setType('review')">
        Review
    </mat-button-toggle>

    <mat-button-toggle (click)="setType('link')">
        Link
    </mat-button-toggle>

    <!-- <mat-button-toggle (click)="setType('statusUpdate')">
                            Status Update
                        </mat-button-toggle> -->

    <!--  <mat-button-toggle (click)="setType('survey')">
                            Survey
                        </mat-button-toggle> -->
</ng-template>