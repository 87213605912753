<mat-card-content>
    <div class="mb-2 tabs-container">
        <mat-tab-group
            #tabGroup
            mat-stretch-tabs
            (selectedTabChange)="tabChanged($event)"
            *ngIf="useTabGroup; else withoutTabGroup"
        >
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">person</mat-icon>
                    For Individual Users
                </ng-template>

                <ng-container *ngTemplateOutlet="normalUser"></ng-container>
            </mat-tab>

            <!-- ! ********************************* HAVENS *************************************** -->

            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">store</mat-icon>
                    Businesses (Havens) COMING SOON
                </ng-template>

                <!-- <app-add-or-edit-haven (isValideHavenNameEmitter)="catchIsValideHavenNameEmitter($event)"
                    (havenFormChangeEmitter)="catchHavenFormChange($event)" [_shouldInit]="mapShouldInit"
                    [_sourceOfInput]="'app-new-user'">
                </app-add-or-edit-haven> -->
            </mat-tab>
        </mat-tab-group>

        <ng-template #withoutTabGroup>
            <ng-container *ngTemplateOutlet="normalUser"></ng-container>
        </ng-template>

        <!-- <app-bulk-rate-games></app-bulk-rate-games> -->
    </div>
</mat-card-content>

<ng-template #normalUser
    >`
    <form [formGroup]="usernameAndAOIForm">
        <div class="mx-0 mt-2 data-group data-group-no-min-height row">
            <div class="col-12 col-md-6" [ngStyle]="{ marginBottom: isMobileScreen ? '2rem' : 0 }">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-xsm-title">
                            Please select a unique username
                        </mat-card-title>
                    </div>
                    <div class="col-12">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Username</mat-label>
                            <input
                                matInput
                                placeholder="Username"
                                formControlName="uniqueUserName"
                                (input)="getUserByTitle()"
                                maxlength="25"
                            />
                            <mat-hint
                                *ngIf="usernameAndAOIForm.get('uniqueUserName').value.length >= 25"
                                class="text-danger"
                            >
                                Maximum characters reached.
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <div *ngIf="checkedForAvailibilityOfUsername">
                            <mat-card-title
                                class="gh-xsm-title"
                                id="warning-message"
                                *ngIf="usernameUnavailable"
                            >
                                <mat-icon class="mr-1">thumb_down</mat-icon>
                                Username
                                <!-- <i>{{whatYouWant}}</i>  -->
                                is Unavailable
                            </mat-card-title>
                            <mat-card-title
                                class="gh-xsm-title"
                                id="success-message"
                                *ngIf="usernameAvailable"
                            >
                                <mat-icon class="mr-1">thumb_up</mat-icon>
                                Username
                                <!-- (<i>{{whatYouWant}}</i> ) -->
                                is Available
                            </mat-card-title>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-xsm-title"
                            >How did you find out about us?</mat-card-title
                        >
                    </div>

                    <div class="col-12">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Please Select:</mat-label>
                            <mat-select
                                [compareWith]="compareFunction"
                                (selectionChange)="howTheyFoundOutAboutUsChanged($event)"
                                formControlName="activityStatus"
                            >
                                <mat-option
                                    *ngFor="let statusType of currentStatusTypes"
                                    [value]="statusType"
                                >
                                    {{ statusType }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="mx-0 mt-2 data-group data-group-no-min-height row">
            <div class="col-12 col-md-6">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-xsm-title"
                            >We can serve you better if we know roughly where you
                            are!</mat-card-title
                        >
                    </div>
                    <div class="col-12">
                        <app-gh-simple-zipcode-selector
                            [_zipToEdit]="null"
                            (onZipSelectedEmitter)="selectZipCode($event)"
                        ></app-gh-simple-zipcode-selector>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
