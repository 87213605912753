const GAMES_DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 500] // can export this if needed, keeping private for now

export const GAMES_PAGINATION_DEFAULT_PAGE_SIZE = 10

export const GAMES_DEFAULT_PAGINATION_OPTIONS = {
    pageSizeOptions: GAMES_DEFAULT_PAGE_SIZE_OPTIONS,
    length: null,
    pageIndex: 0,
    pageSize: GAMES_DEFAULT_PAGE_SIZE_OPTIONS[0],
    previousPageIndex: 0
}