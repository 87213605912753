import { Component, OnInit } from '@angular/core'
import { GhDialogWrapperComponent } from '../gh-dialog-wrapper/gh-dialog-wrapper.component'
import { StoryBuilderAddContentModalComponent } from './components/story-builder-add-content-modal/story-builder-add-content-modal.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-story-builder',
    templateUrl: './story-builder.component.html',
    styleUrls: ['./story-builder.component.scss'],
})
export class StoryBuilderComponent implements OnInit {
    title = 'Story Builder'

    rows = []

    constructor(
        private dialog: MatDialog,
        private screenSizeService: ScreenSizeService
    ) { }

    isMobileScreen = false

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        // temp, delete me
        //this.addContent()
    }

    addContent(indexToEdit?) {

        let inputData

        if (indexToEdit) {
            inputData = this.rows[indexToEdit]
        }

        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            minHeight: '60vh',
            width: '40vw',
            disableClose: true,
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Add Content',
                component: StoryBuilderAddContentModalComponent,
                allowParentClose: true,
                hasSubmitButton: true,
                hasCancelButton: true,
                hasCloseButton: true,
                inputData: inputData
            }
        })

        dialogRef.afterClosed().subscribe((data) => {

            let dataRow = {
                layoutId: data.layoutId,
                content: data.content,
            }
            this.rows.push(dataRow)
        })
    }

}
