import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
// import { NguCarouselConfig } from '@ngu/carousel'
import { BehaviorSubject } from 'rxjs'
import { ulid } from 'ulid'
import { COMMON_BEVERAGE_CONTRIBUTIONS, COMMON_FOOD_CONTRIBUTIONS } from '../../constants/gamenight-constants'
import { GameNightContributionTypeEnums } from '../../enums/gamenight-enums'

@Component({
  selector: 'app-add-gamenight-contribution[_sourceOfInput]',
  templateUrl: './add-gamenight-contribution.component.html',
  styleUrls: ['./add-gamenight-contribution.component.scss']
})
export class AddGamenightContributionComponent implements OnInit {

  @Input() _sourceOfInput: string

  carouselConfig = {
  // carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 4, all: 0 },
    load: 3,
    //interval: {timing: 4000, initialDelay: 1000},
    loop: true,
    touch: true,
    velocity: 0.2,
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  }

  defaultMainImage = window.location.origin + '/assets/images/defaults/placeholder-event.png'
  defaultMainFoodImage = window.location.origin + '/assets/images/coolStuff/pizza-meeple.png'
  defaultMainBeverageImage = window.location.origin + '/assets/images/coolStuff/beer-meeple.png'

  gamesToContribute
  foodToContribute
  beveragesToContribute

  commonFoodContributions = COMMON_FOOD_CONTRIBUTIONS
  commonBeverageContributions = COMMON_BEVERAGE_CONTRIBUTIONS

  isLoading = new BehaviorSubject(true)
  isLoading$ = this.isLoading.asObservable()

  userCollection = new BehaviorSubject(null)
  userCollection$ = this.userCollection.asObservable()

  contributionType: GameNightContributionTypeEnums

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
    this.contributionType = inputData.contributionType

    switch (this.contributionType) {
      case GameNightContributionTypeEnums.games:
        this.initUserCollection()
        break

      case GameNightContributionTypeEnums.food:
      case GameNightContributionTypeEnums.beverages:
        this.isLoading.next(false)
        break
    }

  }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  @Output() closeEmitter = new EventEmitter()

  async initUserCollection() {
    var userCollection = []
    for (let game of this.inputData.user.gameRecommendations) {
      // await this.gamesDbAccessorService.getBulkData(game.i).then(result => {
      //   userCollection.push(result)
      // })
    }
    this.userCollection.next(userCollection)
    this.isLoading.next(false)
  }

  constructor() { }

  ngOnInit(): void {
  }

  setGames(event) {
    this.gamesToContribute = event
  }

  setFood(event) {
    var items = []
    for (let item of event) {
      items.push({ itemId: ulid().toLowerCase(), title: item })
    }
    this.foodToContribute = items
  }

  setBeverages(event) {
    var items = []
    for (let item of event) {
      items.push({ itemId: ulid().toLowerCase(), title: item })
    }
    this.beveragesToContribute = items
  }

  submit() {
    switch (this.contributionType) {
      case GameNightContributionTypeEnums.games:
        this.closeEmitter.emit(this.gamesToContribute)
        break

      case GameNightContributionTypeEnums.food:
        this.closeEmitter.emit(this.foodToContribute)
        break

      case GameNightContributionTypeEnums.beverages:
        this.closeEmitter.emit(this.beveragesToContribute)
        break
    }
  }

  selectedFoodImageChanged(event, index) {
    this.foodToContribute[index].rawImage = event.rawImage
    this.foodToContribute[index].selectedImage = event.selectedImage
  }

  selectedBeverageImageChanged(event, index) {
    this.beveragesToContribute[index].rawImage = event.rawImage
    this.beveragesToContribute[index].selectedImage = event.selectedImage
  }

}
