
// Reference: https://www.match2one.com/blog/standard-banner-sizes/

export enum BannerSizeEnums {
    banner300x250, // medium banner
    banner728x90,  // leaderboard
    banner160x600, // wide skyscraper 
    banner300x600, // halfpage,
    banner970x90,  // large leaderboard,
    banner970x250, // billboard
    banner336x280, // large rectangle
    banner120x600, // skyscraper
    banner200x200, // small square 
    banner250x250, // square
    banner120x240, // vertical banner 
    banner468x60,  // full banner
    banner234x60,  // half-banner
    // portait doesn't give dimensions... 
}