<!-- matTooltip="Click to view detail and/or submit your opinion"  -->

<!-- <button (click)="fetchUsersBig8Votes()" class="m-2">Fetch User's Big8 Votes</button> -->

<div
    class="parent-container"
    matTooltipPosition="above"
    (click)="openDialog()"
    *ngIf="attributesAreKnown; else unknownAttributes"
>
    <div style="display: block; margin-left: 1rem; margin-right: 1rem">
        <!-- todo This should be full sized but NO scrollbar asdflkjdfsalkj -->
        <canvas
            style="width: 100%"
            baseChart
            style="pointer-events: none !important"
            [datasets]="radarChartData"
            [labels]="radarChartLabels"
            [type]="chartType"
            [options]="radarChartOptions"
        >
        </canvas>
    </div>

    <!-- <mat-icon *ngIf="editButtonShows" id="edit-icon" class="gh-icon gh-icon-clickable gh-icon-edit">edit</mat-icon> -->
</div>

<div class="vote-button-container">
    <span *ngIf="numberOfVotes">Votes: {{ numberOfVotes }}</span>

    <button
        mat-raised-button
        class="m-2 gh-button gh-button-secondary"
        *ngIf="!userHasRated"
        (click)="castVoteForBig8()"
    >
        Cast Vote
    </button>
    <button
        mat-raised-button
        class="m-2 gh-button gh-button-secondary"
        *ngIf="userHasRated"
        (click)="editVoteForBig8()"
    >
        Edit Vote
    </button>
</div>

<ng-template #unknownAttributes>
    <mat-icon
        (click)="openDialog()"
        *ngIf="editButtonShows"
        id="edit-icon"
        class="gh-icon gh-icon-clickable gh-icon-edit"
        >edit</mat-icon
    >
    <div class="parent-container unknown-attributes" (click)="openUnknownAttributesDialog()">
        <!-- <img src="../../../../../../../assets/images/coolStuff/playerRadarMock.jpg" style="width: auto; height: 100%;"> -->
        <app-no-data [_message]="'Not enough data... YET!'"></app-no-data>
    </div>
</ng-template>

<!-- <mat-card-subtitle class="gh-lg-subtitle" style="text-align: center;">Personality</mat-card-subtitle> -->
<!-- <div class="percentage" *ngIf="showMatchPercentage">{{matchPercentage | roundToPercent}}</div> -->
