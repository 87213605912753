<div class="p-3">

    <h2 class="mb-3">Sorry to hear that. What did go right? </h2>

    <div class="row">
        <mat-checkbox>I liked the game: {{currentGame}}</mat-checkbox>
    </div>

    <div class="row">
        <mat-checkbox>I liked the Haven: {{currentHaven}}</mat-checkbox>
    </div>

    <div class="row" *ngFor="let player of players; let i = index">
        <mat-checkbox>I liked: {{player}}</mat-checkbox>
    </div>

    <div class="row">
        <div class="col-12">
            <mat-form-field class="" style="width: 100%;">
                <mat-label>Leave a comment for the Haven Owner?</mat-label>
                <textarea matInput placeholder=""></textarea>
              </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-12" style="text-align: right;">
            <button mat-raised-button class="gh-button gh-button-success" [mat-dialog-close]>SUBMIT</button>
        </div>
    </div>

</div>