<div [hidden]="!dataSource || dataSource.filteredData.length == 0">
    <table
        #table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        [hidden]="isMobileScreen"
    >
        <ng-container matColumnDef="avatar">
            <th mat-header-cell *matHeaderCellDef></th>
            <td class="p-3" mat-cell *matCellDef="let element">
                <img
                    class="avatar-img"
                    src="{{
                        element.mainImageFiles && element.mainImageFiles[0]
                            ? element.mainImageFiles[0]
                            : defaultUserImage
                    }}"
                    alt=""
                    *ngIf="messageTableTypeString != 'archived'"
                />
            </td>
        </ng-container>

        <ng-container
            matColumnDef="sentFromTitle"
            *ngIf="messageTableTypeString == 'inbox' || messageTableTypeString == 'archived'"
        >
            <th mat-header-cell *matHeaderCellDef>From</th>
            <td class="p-3" mat-cell *matCellDef="let element">
                {{ element.sentFromTitle }}
            </td>
        </ng-container>

        <ng-container
            matColumnDef="sentToTitle"
            *ngIf="messageTableTypeString == 'sent' || messageTableTypeString == 'archived'"
        >
            <th mat-header-cell *matHeaderCellDef>To</th>
            <td class="p-3" mat-cell *matCellDef="let element">
                {{ element.sentToTitle }}
            </td>
        </ng-container>

        <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>Message</th>
            <td mat-cell *matCellDef="let element">
                {{ element.message }}
            </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Created</th>
            <td mat-cell *matCellDef="let element">
                {{ element.createdAt | date : 'MM/dd/yyyy' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="delete" class="ml-2">
            <th mat-header-cell *matHeaderCellDef [hidden]="!hasDeleteButtons"></th>
            <td mat-cell *matCellDef="let element" [hidden]="!hasDeleteButtons">
                <button
                    style="z-index: 500"
                    mat-button
                    (click)="deleteMessageItem(user.username, element); $event.stopPropagation()"
                >
                    <mat-icon class="gh-delete-icon">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container
            matColumnDef="archive"
            class="ml-2"
            *ngIf="messageTableTypeString != 'archived'"
        >
            <th mat-header-cell *matHeaderCellDef [hidden]="!hasArchiveButtons"></th>
            <td mat-cell *matCellDef="let element" [hidden]="!hasArchiveButtons">
                <button
                    mat-button
                    (click)="archiveMessageItem(user.username, element); $event.stopPropagation()"
                    matTooltip="Archive"
                    matTooltipPosition="above"
                >
                    <mat-icon class="gh-icon gh-icon-secondary">archive</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
            style="z-index: -5"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="seeMessageDetails(row)"
        ></tr>
    </table>

    <mat-accordion class="gh-accordion" [hidden]="!isMobileScreen">
        <mat-expansion-panel
            *ngFor="let element of dataSource.filteredData; let i = index"
            [expanded]="false"
            (opened)="onPanelOpened(i)"
            (closed)="onPanelClosed(i)"
            [ngClass]="{ selected: selectedIndex == i, stripe: i % 2 == 0 }"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <img
                        class="avatar-img"
                        src="{{
                            element.mainImageFiles && element.mainImageFiles[0]
                                ? element.mainImageFiles[0]
                                : defaultUserImage
                        }}"
                        alt=""
                        *ngIf="messageTableTypeString != 'archived'"
                    />
                </mat-panel-title>

                <mat-panel-description
                    *ngIf="
                        messageTableTypeString == 'inbox' || messageTableTypeString == 'archived'
                    "
                >
                    {{ element.sentFromTitle }}
                </mat-panel-description>

                <mat-panel-description
                    *ngIf="messageTableTypeString == 'sent' || messageTableTypeString == 'archived'"
                >
                    {{ element.sentToTitle }}
                </mat-panel-description>

                <mat-panel-description>
                    {{ element.createdAt | date : 'MM/dd/yyyy' }}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="mx-0 panel-content row">
                <div class="text-center col-12">
                    <mat-card-subtitle>
                        {{ element.message }}
                    </mat-card-subtitle>
                </div>
            </div>

            <mat-action-row class="action-row">
                <div class="col-6">
                    <button
                        style="z-index: 1001"
                        mat-button
                        (click)="
                            deleteMessageItem(user.username, element); $event.stopPropagation()
                        "
                        *ngIf="hasDeleteButtons"
                    >
                        <mat-icon class="gh-delete-icon">delete</mat-icon>
                    </button>
                </div>
                <div class="col-6">
                    <button
                        style="z-index: 1001"
                        mat-button
                        (click)="
                            archiveMessageItem(user.username, element); $event.stopPropagation()
                        "
                        matTooltip="Archive"
                        matTooltipPosition="above"
                        *ngIf="hasArchiveButtons"
                    >
                        <mat-icon class="gh-icon gh-icon-secondary">archive</mat-icon>
                    </button>
                </div>
            </mat-action-row>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div [hidden]="dataSource && dataSource.filteredData.length > 0">
    <app-no-data [_message]="'No data...'"></app-no-data>
</div>
