import { CdkDragDrop, DragRef, DropListRef, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop'
import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'

@Component({
  selector: 'app-event-timing-manager[_sourceOfInput]',
  templateUrl: './event-timing-manager.component.html',
  styleUrls: ['./event-timing-manager.component.scss']
})
export class EventTimingManagerComponent implements OnInit {
  
  @Input() _sourceOfInput: string

  maxImgHeight: 90 // px

  availableGamesControl = new UntypedFormControl()
  availableGames = [
    {
      title: 'Rum and Bones',
      minutes: 60,
      thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F3P6S45P931Q56PF0WZ05DPQ'
    },
    {
      title: 'Scythe',
      minutes: 120,
      thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F3PGNFWJN78PVFRHM9KCYFBH'
    },
    {
      title: 'Blood Rage',
      minutes: 30,
      thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F2T7MZS1RMWQ09TZVXC8PCMJ'
    },
    {
      title: 'Spirit Island',
      minutes: 15,
      thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F2T7TQ4A0X1JFS9D5FATPJQ4'
    },
    {
      title: 'Magic: The Gathering',
      minutes: 240,
      thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F3PGHMAS70S44CA49045CEMT'
    },
    {
      title: 'Axis & Allies',
      minutes: 120,
      thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F2T6JPJ6DCT6PVJ900XVHM34'
    },
    {
      title: 'Chess',
      minutes: 60,
      thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F3PGCF9WSX4SS9N26P77K4WR'
    },
    {
      title: 'Drop It',
      minutes: 30,
      thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F3PG1BHMRD2DYB4A8WWVQARS'
    }
  ]

  hours = ['12:00 pm', '1:00 pm', '2:00 pm', '3:00 pm', '4:00 pm']
  totalMinutes = 300 // hours.length * 60
  currentScheduledMinutes = 60

  selectedGames = [
    {
      title: 'Patchwork',
      minutes: 60,
      thumbImgPath: 'https://gh-img.s3.amazonaws.com/G/01F2T7T19G8ZDREJAGH5J0RNH7'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  getMaxWidth(game) {
    return (game.minutes / this.totalMinutes * 100).toString().concat('%')
  }

  //! below method not yet working!!!
  getImgMaxHeight(game) {
    // return this.maxImgHeight.toString().concat('px')
    return '90px'
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex)


      if (event.container.id == 'selectedGames') { 
        this.currentScheduledMinutes += event.item.data.minutes
      }
      else {
        this.currentScheduledMinutes -= event.item.data.minutes
      }
    }
  }


  acceptedGamePredicate = (drag: DragRef, drop: DropListRef) => {
    return this.currentScheduledMinutes + drag.data.minutes <= this.totalMinutes
  }

  //! below method not used in this scenario but leaving here for reference, for now....
  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate() {
    return false
  }

}
