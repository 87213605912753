export interface iUpdateUserInput {
  address: string
  username: string
  bio: string
  title: string
  userModelVector: string
  pda: string
  createdAt: any
  updatedAt: any
  hotList: any
  predictedHotList: any
  predictedRatings: any
  data: string
  userLevel: number
  videoId: string
  imageId: string
  userId: string
  context: string
  contextIdentity: string
  contextIdentityUsername: string
  contextArguments: string
  gsi1sk: string
  gsi2pk: string
  gsi2sk: string
  corePK: string
  coreSK: string
  gamesILove: string[]
  defaultHaven: string

  city: string
  country: string
  dob: string
  gender: string
  lat: string
  lng: string
  state: string
  coordinates: string
  iUpdateUserInput: string
  isNewUserFlag: boolean
  userIsHaven: boolean
  havensIManage: string[]
  groupsIManage: string[]

  lookingToPlay: string[]
  email: string
  zipCode: number
  mainImageFiles: string[]
  messagingPreference: string
  contentPreference: string
  personalListOfNonGhUsers: string
  listOfFollowers: string
  GH_Points: number
}

export interface iChangeOrUpdateUserAttributeInput {
  pkInput: string
  skInput: string
  attributeNameInput: string
  attributeValueInput: string
}

export interface iCreateOrUpdateUserPublisherItem {
  username?: string
  title: string
}

export interface iCreateOrUpdateUserDeveloperItem {
  username?: string
  title: string
}


export interface iCreateOrUpdatePublisherGameItem {
  publisherTitle: string
  publisherId: string
  gameTitle: string
}
export interface iCreateOrUpdateDeveloperGameItem {
  developerTitle: string
  developerId: string
  gameTitle: string
}
export interface iCreateOrUpdateArtistGameItem {
  artistTitle: string
  artistId: string
  gameTitle: string
}


export interface iCreateOrUpdateUserArtistItem {
  username: string
  title: string
}

export interface iDeletePDAInput {
  title: string,
  prefix: string
}

export interface iChangePointsInput {
  tableName: string,
  pk: string,
  attributeName: string,
  value: number
}

export interface iUpdateDataWithPKandSKInput {
  tableName: string,
  pk: string,
  sk: string,
  attribute: string,
  data: string
}
export interface iDeleteS3Object {
  bucketName: string
  objectKey: string
}

export interface iCreateOrUpdateUserContentCreatorItem {
  username: string
  title: string
}

export interface iCreateUser {
  username: string
  name: string
  title?: string
  email: string
  userLevel: number
  hotList: any
  predictedHotList: any
  predictedRatings: any
  zipCode: number
  state: string
  isNewUserFlag: boolean
  lookingToPlay: string[]
  gamesILove: string[]
  userGameQualities: any
  GH_Points: number
  contentPreference: string[]
  defaultHaven?: string
}

export interface iCreateUserImageItemsInput {
  addedByUID: string
  gameId: string
  paths: string[][] // make a sub interface
  title?: string
  subtitle?: string
  userLevelWhoAdded: number
}

export interface iCreateOrUpdateUserInput {
  mainImageFiles?: string[]
  username: string
  bio?: string
  title?: string
  userModelVector?: string
  pda?: string
  createdAt?: string
  updatedAt?: string
  hotList?: any
  predictedHotList?: any
  predictedRatings?: any
  data?: string
  userLevel?: number
  videoId?: string
  imageId?: string
  userId?: string
  context?: string
  contextIdentity?: string
  contextIdentityUsername?: string
  contextArguments?: string
  gsi1sk?: string
  lookingToPlay?: any
  email?: string
  zipCode?: number
}


export interface iUpdateSingleAttributeForUserInput {
  pkName: string
  pkValue: string
  skName: string
  skValue: string
  attributeName: string
  attributeValue: any
}

export interface iUpdateSingleArrayAttributeForUserInput {
  pkName: string
  pkValue: string
  skName: string
  skValue: string
  attributeName: string
  attributeValue: any
}
