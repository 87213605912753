import { Injectable } from '@angular/core'
import gql from 'graphql-tag'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { Auth } from 'aws-amplify'
import {
    iCreateOrUpdateGameInput,
    iDeleteGameInput,
    iUpdateSingleAttributeForGameInput,
} from '../../../../common/interfaces/GameInterfaces'
import { environment } from 'src/environments/environment'
import { ImageMultiSizeService } from '../dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import { BackendAPIUsersService } from './backend-api-users.service'
import {
    iCreateOrUpdateUserArtistItem,
    iCreateOrUpdateUserDeveloperItem,
    iCreateOrUpdateUserPublisherItem,
    iCreateUserImageItemsInput,
} from '../../../../common/interfaces/UserInterfaces'
import { uploadImageFile } from './s3-file-uploader'
import { BackendApiGameTrackingService } from './backend-api-game-tracking.service'
import { SoundEffectsService } from '../dashboard/dashboard-shared/services/audio/sound-effects.service'
import { S3ContentHelperService } from '../dashboard/dashboard-shared/services/helpers/s3-content-helper.service'
import { SnackbarService } from '../dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'

@Injectable({
    providedIn: 'root',
})
export class BackendAPIGamesService {
    constructor(
        private backendApiUsersService: BackendAPIUsersService,
        private imageMultiSizeService: ImageMultiSizeService,
        private backendApiGameTrackingService: BackendApiGameTrackingService,
        private soundEffectsService: SoundEffectsService,
        private snackbarService: SnackbarService,
        private s3ContentHelperService: S3ContentHelperService,
    ) {}

    private appSyncClient = new AWSAppSyncClient({
        url: environment.apiUrl,
        region: 'us-east-1',
        auth: {
            type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
            jwtToken: async () => {
                const token = (await Auth.currentSession()).getIdToken().getJwtToken()
                return token
            },
        },
        disableOffline: true,
    })

    async listAllGameCoreData(limit: number, nextToken?: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllGameCoreData($limit: Int!, $nextToken: String) {
                    listAllGameCoreData(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        coreGames {
                            corePK
                            coreSK
                            popularity
                            big8
                            umv
                            pk
                            sk
                            title
                            mainImageFiles
                            expansions
                            isExpansion
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: { limit, nextToken },
        })

        const { coreGames, nextToken: newNextToken } = response.data.listAllGameCoreData

        if (newNextToken) {
            const additionalData = await this.listAllGameCoreData(limit, newNextToken)
            return {
                coreGames: [...coreGames, ...additionalData.coreGames], // Concatenate the arrays
                nextToken: additionalData.nextToken,
            }
        }

        return { coreGames, nextToken: newNextToken }
    }

    async listTrendingFactorData(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listTrendingFactorData($limit: Int!, $nextToken: String) {
                    listTrendingFactorData(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        games {
                            aad
                            id
                            title
                            big8
                            duration
                            age
                            tags
                            big8Votes
                            minPlayers
                            maxPlayers
                            printStatus
                            duration
                            yearPublished
                            minPlaytime
                            maxPlaytime
                            tags
                            minSuggestedAge
                            publishers {
                                username
                                title
                            }
                            designers {
                                username
                                title
                            }
                            artists {
                                username
                                title
                            }
                            isCrowdfunded
                            crowdfundingLink
                            crowdfundingLaunchDate
                            crowdfundingHasLaunched
                            crowdfundingExpiryDate
                            crowdfundingSite
                            createdAt
                            updatedAt
                            mainImageFiles
                            publisherDescription
                            officialLink
                            metaDataForGH
                            internalGhNotes
                            corePK
                            coreSK
                            isExpansion
                            trendingFactor
                        }
                    }
                }
            `,
            fetchPolicy: 'network-only',
            variables: { limit, nextToken },
        })
        return response.data.listTrendingFactorData
    }

    async removeFromSubmissions() {
        // todo need to remove needsAttentionFlag and add isOfficialGHGameFlag
        // alert('trying to send email')
    }

    async UpdateGame(input: iCreateOrUpdateGameInput, files?) {
        //! ERROR HERE
        //! Cannot set properties of undefined (setting '0')
        // ! SHAY THIS IS NOT included in the input

        //* NOTE: since additional images are not included when editing, files will only be the main image
        //* NOTE: since certain updates (such as just approving a game) do not touch main image, files is optional

        input.mainImageFiles = await this.s3ContentHelperService.prepareMainImageFiles(files)

        let publishers: iCreateOrUpdateUserPublisherItem[] = input.publishers
        let publishersResult = input.publishers

        if (publishers && publishers != null) {
            for (let publisher of publishers) {
                try {
                    const publisherGameItemRes =
                        await this.backendApiUsersService.createPublisherGameItem(publisher, input)
                } catch (error) {
                    console.log('try catch publisherGameItemRes duplicate error~~~', error)
                }
            }
        }

        // input.mainImageFiles[0] = input.mainImageFiles[0] // ! SHAY THIS IS NOT included in the input

        // ! even if the image selector is used it doesn't work.

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateGame($input: IGQL_CreateOrUpdateGameInput!) {
                    updateGame(input: $input) {
                        id
                        title
                        modelPreferences
                        big8
                        duration
                        age
                        tags
                        big8Votes
                        similarGames
                        similarGamesByUserPreference
                        allPlayers
                        ownershipCounts
                        hopperData
                        addedByUID
                        minPlayers
                        maxPlayers
                        idealPlayerVotes {
                            numberVotes
                        }
                        officialLink
                        factions
                        yearPublished
                        minPlaytime
                        expectedPlaytime
                        minSuggestedAge
                        publisherDescription
                        publishers {
                            username
                        }
                        designers {
                            username
                        }
                        artists {
                            username
                        }
                        printStatus
                        gameAdminId
                        isCrowdfunded
                        crowdfundingHasLaunched
                        crowdfundingLaunchDate
                        crowdfundingExpiryDate
                        crowdfundingSite
                        crowdfundingLink
                        aad
                        metaDataForGH
                        internalGhNotes
                        isExpansion
                        hasExpansions
                        parentItem {
                            id
                        }
                        expansions
                        createdAt
                        updatedAt
                        flaggedForModerationPK
                        flaggedForModerationSK
                        needsAttentionPK
                        needsAttentionSK
                        trendingFactor
                    }
                }
            `,
            variables: { input },
        })
        return response.data.updateGame
    }

    async approveNewGame(input: iCreateOrUpdateGameInput) {
        //* NOTE: since additional images are not included when editing, files will only be the main image
        //* NOTE: since certain updates (such as just approving a game) do not touch main image, files is optional

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation approveNewGame($input: IGQL_CreateOrUpdateGameInput!) {
                    approveNewGame(input: $input) {
                        id
                        needsAttentionPK
                        needsAttentionSK
                    }
                }
            `,
            variables: { input },
        })
        return response.data.approveNewGame
    }

    async CreateGame(input: iCreateOrUpdateGameInput, files, initialStarRating) {
        let result = []
        let mainImageResult

        input.mainImageFiles = await this.s3ContentHelperService.prepareMainImageFiles(files)

        //? is this needed below?
        if (input.mainImage) {
            input.mainImage[0] = input.mainImageFiles[0]
        }

        result.push(mainImageResult)

        //! check to make sure publisher doedsnt already exist (DO THIS IN USERS REPO!!!  createUserPublisherItem)

        let publishers: iCreateOrUpdateUserPublisherItem[] = input.publishers
        let publishersResult = input.publishers
        if (publishers && publishers != null) {
            for (let publisher of publishers) {
                try {
                    const publisherGameItemRes =
                        await this.backendApiUsersService.createPublisherGameItem(publisher, input)
                } catch (error) {
                    console.log('try catch publisherGameItemRes duplicate error thingy~~~', error)
                }
            }
            result.push({
                publishers: publishersResult,
            })
        }

        let developers: iCreateOrUpdateUserDeveloperItem[] = input.designers
        let developersResult = input.designers

        if (developers && developers != null) {
            for (let developer of developers) {
                try {
                    const developerGameItemRes =
                        await this.backendApiUsersService.createDeveloperGameItem(developer, input)
                } catch (error) {
                    console.log('try catch developerGameItemRes duplicate error thingy~~~', error)
                }
            }
            result.push({
                developers: developersResult,
            })
        }

        let artists: iCreateOrUpdateUserArtistItem[] = input.artists
        let artistsResult = input.designers

        if (artists && artists != null) {
            for (let artist of artists) {
                try {
                    const artistGameItemRes =
                        await this.backendApiUsersService.createArtistGameItem(artist, input)
                } catch (error) {
                    console.log('try catch artistGameItemRes duplicate error thingy~~~', error)
                }
            }
            result.push({
                artists: artistsResult,
            })
        }

        // If we return publishers to be able to be entered in add game, then return this code to above
        // note, only publishers that exist should make it this far
        // For now, publishers can only be created via Adimin
        // const publisherRes = await this.backendApiUsersService.createUserPublisherItem(publisher)
        // publishersResult.push(publisherRes)
        // try {
        //   const publisherRes = await this.backendApiUsersService.createUserPublisherItem(publisher)
        //   publishersResult.push(publisherRes)
        // }
        // catch (error) {
        //   console.log('try catch publisher duplicate error thingy~~~', error)
        // }
        // let designers: iCreateOrUpdateUserDeveloperItem[] = input.designers
        // let designersResult = []
        // if (designers && designers != null && designers != []) {
        //   for (let designer of designers) {
        //     const designersRes = await this.backendApiUsersService.createUserDesignerItem(designer)
        //     designersResult.push(designersRes)
        //   }
        //   result.push({
        //     'designers': designersResult
        //   })
        // }
        // let artists: iCreateOrUpdateUserArtistItem[] = input.artists
        // let artistsResult = []
        // if (artists && artists != null && artists != []) {
        //   for (let artist of artists) {
        //     const artistsRes = await this.backendApiUsersService.createUserArtistItem(artist)
        //     artistsResult.push(artistsRes)
        //   }
        //   result.push({
        //     'artists': artistsResult
        //   })
        // }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation createGame($input: IGQL_CreateOrUpdateGameInput!) {
                    createGame(input: $input) {
                        id
                        title
                        modelPreferences
                        big8
                        duration
                        age
                        isExpansion
                        hasExpansions
                        tags
                        big8Votes
                        similarGames
                        similarGamesByUserPreference
                        allPlayers
                        ownershipCounts
                        hopperData
                        addedByUID
                        minPlayers
                        maxPlayers
                        officialLink
                        factions
                        yearPublished
                        minPlaytime
                        maxPlaytime
                        expectedPlaytime
                        minSuggestedAge
                        publisherDescription
                        publishers {
                            title
                            __typename
                            username
                        }
                        designers {
                            title
                            __typename
                            username
                        }
                        artists {
                            title
                            __typename
                            username
                        }
                        printStatus
                        gameAdminId
                        isCrowdfunded
                        crowdfundingHasLaunched
                        crowdfundingLaunchDate
                        crowdfundingExpiryDate
                        crowdfundingSite
                        crowdfundingLink
                        aad
                        metaDataForGH
                        internalGhNotes
                        isExpansion
                        hasExpansions
                        parentItem {
                            id
                            title
                        }
                        expansions
                        createdAt
                        updatedAt
                        needsAttentionPK
                        needsAttentionSK
                        flaggedForModerationPK
                        flaggedForModerationSK
                        userLevelWhoAdded
                        numberRatings
                        idealPlayerVotes {
                            numberVotes
                            averagedArray
                            userIdealPlayersVotes {
                                userId
                                userVotesArray
                            }
                        }
                        mainImageFiles
                        trendingFactor
                    }
                }
            `,
            variables: { input },
        })
        // # theRating

        result.push(response.data.createGame)

        // if (initialStarRating) {
        //   let user = this.backendApiUsersService.getCurrentUser()
        //   let ratingRes = await this.backendApiGameTrackingService.createGameTrackingAddRating({
        //     gameId: response.data.createGame.id,
        //     UID: user.id,
        //     type: 'rating',
        //     userState: user.state,
        //     userZipCode: user.zipCode,
        //     title: response.data.createGame.title,
        //     userTitle: user.title,
        //     mainImageFiles: null, //! fix this!!!
        //     rating: initialStarRating
        //   })
        //   result.push(ratingRes)
        // }

        this.snackbarService.openSuccessSnackBar('Game Added, we will approve soon, ', '', true)

        // this.soundEffectsService.playCoinNoise()

        return result
    }

    async GetGame(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGame($id: ID!) {
                    getGame(id: $id) {
                        aad
                        id
                        title
                        big8
                        duration
                        age
                        tags
                        big8Votes
                        minPlayers
                        maxPlayers
                        printStatus
                        duration
                        yearPublished
                        minPlaytime
                        maxPlaytime
                        tags
                        minSuggestedAge
                        publishers {
                            username
                            title
                        }
                        designers {
                            username
                            title
                        }
                        artists {
                            username
                            title
                        }
                        isCrowdfunded
                        crowdfundingLink
                        crowdfundingLaunchDate
                        crowdfundingHasLaunched
                        crowdfundingExpiryDate
                        crowdfundingSite
                        createdAt
                        updatedAt
                        mainImageFiles
                        publisherDescription
                        officialLink
                        metaDataForGH
                        internalGhNotes
                        corePK
                        coreSK
                        isExpansion
                        trendingFactor
                        expansions
                        parentItem {
                            id
                            title
                        }
                    }
                }
            `,
            variables: { id },
            fetchPolicy: 'network-only',
        })
        return response.data.getGame
    }

    async listAllGameBD(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllGameBD($limit: Int, $nextToken: String) {
                    listAllGameBD(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        games {
                            aad
                            id
                            title
                            big8
                            duration
                            age
                            tags
                            big8Votes
                            minPlayers
                            maxPlayers
                            printStatus
                            duration
                            yearPublished
                            minPlaytime
                            maxPlaytime
                            tags
                            minSuggestedAge
                            publishers {
                                username
                                title
                            }
                            designers {
                                username
                                title
                            }
                            artists {
                                username
                                title
                            }
                            isCrowdfunded
                            crowdfundingLink
                            crowdfundingLaunchDate
                            crowdfundingHasLaunched
                            crowdfundingExpiryDate
                            crowdfundingSite
                            createdAt
                            updatedAt
                            mainImageFiles
                            publisherDescription
                            officialLink
                            metaDataForGH
                            internalGhNotes
                            corePK
                            coreSK
                            isExpansion
                            trendingFactor
                        }
                    }
                }
            `,
            variables: { limit, nextToken },

            fetchPolicy: 'network-only',
        })
        return response.data.listAllGameBD
    }

    async GetMainImage(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGame($id: ID!) {
                    getGame(id: $id) {
                        mainImageFiles
                    }
                }
            `,
            variables: { id },
        })
        return response.data.getGame
    }

    async GetPublisherDescription(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGame($id: ID!) {
                    getGame(id: $id) {
                        publisherDescription
                    }
                }
            `,
            variables: { id },
        })
        return response.data.getGame
    }

    async GetPublishers(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGame($id: ID!) {
                    getGame(id: $id) {
                        publishers {
                            username
                            title
                            __typename
                        }
                    }
                }
            `,
            variables: { id },
        })
        return response.data.getGame
    }

    async GetGameMainImageFiles(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGame($id: ID!) {
                    getGame(id: $id) {
                        mainImageFiles
                    }
                }
            `,
            variables: { id },
        })
        return response.data.getGame
    }

    //! TBC - commenting out until I get back to this
    // async listGameReviewsByGameID(numberOfLikes: string, limit: number, nextToken?) {

    //   const response = await this.appSyncClient.query<any>({
    //     query: gql`
    //         query listGameReviewsByGameID($numberOfLikes: String!, $limit: Int!, $nextToken: String ) {
    //           listGameReviewsByGameID(numberOfLikes: $numberOfLikes, limit: $limit, nextToken: $nextToken ) {
    //             nextToken
    //              reviews {
    //                id
    //           }
    //         }
    //       `,
    //     variables: { numberOfLikes, limit, nextToken }
    //   })
    //   return response.data.listGameReviewsByGameID
    // }

    async listGamesByTitle(titleStartsWith: string, limit: number) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listGamesByTitle($titleStartsWith: String!, $limit: Int!) {
                    listGamesByTitle(titleStartsWith: $titleStartsWith, limit: $limit) {
                        nextToken
                        games {
                            id
                            title
                        }
                    }
                }
            `,
            variables: { titleStartsWith, limit },
        })
        return response.data.listGamesByTitle
    }

    async listNewGamesThatNeedAttention(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listNewGamesThatNeedAttention($limit: Int, $nextToken: String) {
                    listNewGamesThatNeedAttention(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        games {
                            id
                            title
                            big8
                            duration
                            age
                            tags
                            big8Votes
                            minPlayers
                            maxPlayers
                            printStatus
                            duration
                            yearPublished
                            minPlaytime
                            maxPlaytime
                            tags
                            age
                            publishers {
                                username
                            }
                            designers {
                                username
                            }
                            artists {
                                username
                            }
                            publisherDescription
                            isCrowdfunded
                            crowdfundingLink
                            crowdfundingLaunchDate
                            crowdfundingHasLaunched
                            crowdfundingExpiryDate
                            crowdfundingSite
                            createdAt
                            updatedAt
                            gsi2pk
                            gsi2sk
                            corePK
                            coreSK
                            isExpansion
                            trendingFactor
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listNewGamesThatNeedAttention
    }

    //!    NOT WORKING, this is where you need to implement the scan

    async listGamesThatNeedAttention(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listGamesThatNeedAttention($limit: Int, $nextToken: String) {
                    listGamesThatNeedAttention(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        games {
                            id
                            title
                            big8
                            duration
                            age
                            tags
                            big8Votes
                            minPlayers
                            maxPlayers
                            printStatus
                            duration
                            yearPublished
                            minPlaytime
                            maxPlaytime
                            minSuggestedAge

                            internalGhNotes
                            metaDataForGH
                            tags
                            age
                            aad
                            factions
                            officialLink
                            publishers {
                                title
                                username
                            }
                            designers {
                                title
                                username
                            }
                            artists {
                                title
                                username
                            }
                            publisherDescription
                            isCrowdfunded
                            crowdfundingLink
                            crowdfundingLaunchDate
                            crowdfundingHasLaunched
                            crowdfundingExpiryDate
                            crowdfundingSite
                            needsAttentionPK
                            needsAttentionSK
                            flaggedForModerationPK
                            flaggedForModerationSK
                            createdAt
                            updatedAt
                            gameAdminId
                            addedByUID
                            mainImageFiles

                            gsi2pk
                            gsi2sk

                            similarGames
                            isExpansion
                            trendingFactor
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
            fetchPolicy: 'network-only',
        })
        return response.data.listGamesThatNeedAttention
    }

    async listGamesThatAreApproved(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listGamesThatAreApproved($limit: Int, $nextToken: String) {
                    listGamesThatAreApproved(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        games {
                            id
                            title
                            big8
                            duration
                            age
                            tags
                            big8Votes
                            minPlayers
                            maxPlayers
                            printStatus
                            duration
                            yearPublished
                            minPlaytime
                            maxPlaytime
                            minSuggestedAge
                            metaDataForGH
                            tags
                            age
                            aad
                            factions
                            officialLink
                            publishers {
                                title
                                username
                            }
                            designers {
                                title
                                username
                            }
                            artists {
                                title
                                username
                            }
                            isCrowdfunded
                            crowdfundingLink
                            crowdfundingLaunchDate
                            crowdfundingHasLaunched
                            crowdfundingExpiryDate
                            crowdfundingSite
                            createdAt
                            updatedAt
                            gameAdminId
                            addedByUID
                            mainImageFiles
                            publisherDescription
                            isExpansion
                            trendingFactor
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listGamesThatAreApproved
    }

    async listAllGames(limit: number, nextToken?) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query listAllGames($limit: Int, $nextToken: String) {
                    listAllGames(limit: $limit, nextToken: $nextToken) {
                        nextToken
                        games {
                            id
                            title
                            big8
                            duration
                            age
                            tags
                            big8Votes
                            minPlayers
                            maxPlayers
                            printStatus
                            duration
                            yearPublished
                            minPlaytime
                            maxPlaytime
                            tags
                            age
                            publishers {
                                username
                            }
                            designers {
                                username
                            }
                            artists {
                                username
                            }
                            isCrowdfunded
                            crowdfundingLink
                            crowdfundingLaunchDate
                            crowdfundingHasLaunched
                            crowdfundingExpiryDate
                            crowdfundingSite
                            createdAt
                            updatedAt
                            gameAdminId
                            addedByUID
                            mainImageFiles
                            isExpansion
                            trendingFactor
                        }
                    }
                }
            `,
            variables: { limit, nextToken },
        })
        return response.data.listAllGames
    }

    async GetGameMasterListMinimalItems(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGame($id: ID!) {
                    getGame(id: $id) {
                        id
                        title
                        createdAt
                        updatedAt
                    }
                }
            `,
            variables: { id },
        })
        return response.data.getGame
    }

    async GetSimilarGames(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGame($id: ID!) {
                    getGame(id: $id) {
                        id
                        similarGames
                    }
                }
            `,
            variables: { id },
        })
        return response.data.getGame
    }

    async GetGameIdealPlayers(id: string) {
        const response = await this.appSyncClient.query<any>({
            query: gql`
                query getGameIdealPlayers($id: ID!) {
                    getGameIdealPlayers(id: $id) {
                        id
                        data
                        idealPlayerVotes {
                            numberVotes
                            userIdealPlayersVotes {
                                userVotesArray
                                userId
                            }
                            averagedArray
                        }
                    }
                }
            `,
            variables: { id },
        })
        return response.data.getGameIdealPlayers
    }

    async ChangeGameIdealPlayers(gameId) {
        const currentIdealPlayers = await this.GetGameIdealPlayers(gameId)

        let idealPlayersAsNumber = Number(currentIdealPlayers.data)
        let newIdealRating: number = idealPlayersAsNumber + 1

        let input = {
            id: gameId,
            title: 'your 2',
            idealPlayerVotes: {
                numberVotes: 3232,
                userIdealPlayersVotes: {
                    userVotesArray: [true, false, false, true, false, false, true, false],
                    userId: 'carlbob Junie',
                },
                averagedArray: [343, 2, 45, 35, 475, 63, 56, 45],
            },
        }

        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateGameIdealPlayers($input: IGQL_CreateOrUpdateGameInput!) {
                    updateGameIdealPlayers(input: $input) {
                        id
                        title
                        internalGhNotes
                        idealPlayerVotes {
                            numberVotes
                            userIdealPlayersVotes {
                                userVotesArray
                                userId
                            }
                            averagedArray
                        }
                    }
                }
            `,
            variables: { input },
        })
        return response.data.updateGameIdealPlayers
    }

    async deleteGame(input: iDeleteGameInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation deleteGame($input: IGQL_DeleteGameInput!) {
                    deleteGame(input: $input) {
                        id
                    }
                }
            `,
            variables: { input },
        })

        return response.data.deleteGame
    }

    // ! DUPLICATE CODE: BOTH IN GAMESsERVICE AND gAMEtRACKINGsERVICE
    async updateSingleAttributeForGame(input: iUpdateSingleAttributeForGameInput) {
        const response = await this.appSyncClient.mutate<any>({
            mutation: gql`
                mutation updateSingleAttributeForGame(
                    $input: IGQL_UpdateSingleAttributeForGameInput!
                ) {
                    updateSingleAttributeForGame(input: $input) {
                        pkName
                        pkValue
                        skName
                        skValue
                        attributeName
                        attributeValue
                    }
                }
            `,
            variables: { input },
        })

        return response.data.updateSingleAttributeForGame
    }

    patchBig8Script(coreGames) {
        for (let game of coreGames) {
            this.updateSingleAttributeForGame({
                pkName: 'pk',
                pkValue: game.pk,
                skName: 'sk',
                skValue: 'BD#'.concat(game.pk),
                attributeName: 'big8',
                attributeValue: null,
            })
        }
        alert('done')

        // for (var i = 0; i < 5; i++) {
        //   this.updateSingleAttributeForGame({
        //     pkName: 'pk',
        //     pkValue: coreGames[i].pk,
        //     skName: 'sk',
        //     skValue: 'BD#'.concat(coreGames[i].pk),
        //     attributeName: 'big8',
        //     attributeValue: [] // this forces it to a string so instead I am setting it to null. I tested and editing the game after changes it to a list
        //   })
        // }
    }
}
