import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { ArticleFullDetailComponent } from '../components/article-full-detail/article-full-detail.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-gh-article-list',
  templateUrl: './gh-article-list.component.html',
  styleUrls: ['./gh-article-list.component.scss']
})

export class GhArticleListComponent implements OnInit {

  user

  articles
  @Input() set _articles(articles) { // todo articles should an iterface

    if (articles && articles[0] && articles[0].title != null) {
      this.articles = articles
      // this.processArticles(articles)
    }
    this.articles = articles[0]

    // this.processArticles(this.articles)
  }

  @Output() onDeleteEmitter = new EventEmitter(null)


  async processArticles(articles) {

    for (let article of articles) {
      let articleAuthorBD = await this.backendAPIUsersService.getProfileById(article.addedByUID)
      article.articleAuthor = articleAuthorBD.title

      article.articleAuthorImage = articleAuthorBD.mainImageFiles[0]

    }
  }

  // selectedImage
  // @Input() set _selectedImage(selectedImage){ // todo articles should an iterface
  //   if(selectedImage != null){
  //     this.articles = selectedImage
  //   }
  // } 

  constructor(
    private backendAPIUsersService: BackendAPIUsersService,
    private screenSizeService: ScreenSizeService,

    private dialog: MatDialog
  ) { }

  todaysDate
  isMobileScreen
  ngOnInit(): void {
    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })
    this.todaysDate = new Date()

    this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobileScreen: boolean) => {
      this.isMobileScreen = isMobileScreen
    })


  }

  async deleteArticle(event) {
    this.onDeleteEmitter.emit(event)
  }

  openFullDetails(editor) {
    let inputData = {
      item: editor,
    }

    this.dialog.open(GhDialogWrapperComponent, {
      panelClass: this.isMobileScreen ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS] : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      disableClose: true,
      data: {
        title: 'Article Details',
        component: ArticleFullDetailComponent,
        hasSubmitButton: false,
        hasCancelButton: false,
        inputData: inputData,
        hasCloseButton: true,
        allowParentClose: true
      },
      autoFocus: false
    })

  }


}
