import { Component, Input, OnInit } from '@angular/core'
import { untilDestroyed } from '@ngneat/until-destroy'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ShareModalComponent } from 'src/app/dashboard/components/sidebar/components/user-update/components/share-modal/share-modal.component'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent implements OnInit {

  message
  @Input() set _message(message) {
    this.message = message
  }

  hasActionButton = false
  @Input() set _hasActionButton(hasActionButton: boolean) {
    this.hasActionButton = hasActionButton
  }

  actionButtonText = false
  @Input() set _actionButtonText(actionButtonText: boolean) {
    this.actionButtonText = actionButtonText
  }

  actionComponent = false
  @Input() set _actionComponent(actionComponent: boolean) {
    this.actionComponent = actionComponent
  }

  actionComponentTitle
  @Input() set _actionComponentTitle(actionComponentTitle: string) {
    this.actionComponentTitle = actionComponentTitle
  }

  actionComponentInputData
  @Input() set _actionComponentInputData(actionComponentInputData) {
    this.actionComponentInputData = actionComponentInputData
  }

  actionComponentSubmitText = 'Submit'
  @Input() set _actionComponentSubmitText(actionComponentSubmitText) {
    this.actionComponentSubmitText = actionComponentSubmitText
  }

  constructor(private screenSizeService: ScreenSizeService,
    private dialog: MatDialog) { }

  isMobileScreen = false
  ngOnInit(): void {
    this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobileScreen: boolean) => {
      this.isMobileScreen = isMobileScreen
    })
  }

  openActionComponent() {
    this.dialog.open(GhDialogWrapperComponent, {
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      disableClose: true,
      data: {
        title: this.actionComponentTitle,
        component: this.actionComponent,
        hasSubmitButton: true,
        submitButtonText: this.actionComponentSubmitText,
        hasCancelButton: true,
        hasCloseButton: true,
        // hasLeftActionButton: true,
        // leftActionButtonText: 'Start Over',
        allowParentClose: true,
        inputData: this.actionComponentInputData
      },
      autoFocus: false
    })
  }

}
