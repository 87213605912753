


// TODO: see task: Would be nice, but beyond best practices...
// export const kickstarterString = 'KickStarter;'

// export enum CrowdfundingSiteOptionEnums {
//     'kickstarter' = kickstarterString,
//     'gamefound' = 'GameFound',
//     'other' = 'Other'
// }


export enum CrowdfundingSiteOptionEnums {
    'kickstarter' = 'KickStarter',
    'gamefound' = 'GameFound',
    'other' = 'Other'
}