import { Component, Input, OnInit, ViewChild } from '@angular/core'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { ShareModalAdvancedLogPlayComponent } from 'src/app/dashboard/components/sidebar/components/user-update/components/share-modal/components/share-modal-advanced-log-play/share-modal-advanced-log-play.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { StringHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/string-helper.service'
import { LogPlayService } from 'src/app/dashboard/dashboard-shared/services/loggingPlays/log-play.service'
import { DELETE_CONFIRMATION_PASSWORD, MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { SimpleLpDetailModalComponent } from '../../../../modals/logged-plays/simple-lp-detail-modal/simple-lp-detail-modal.component'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatSort } from '@angular/material/sort'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ConfirmActionComponent } from '../../../../modals/confirm-action/confirm-action.component'
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource, MatTable } from '@angular/material/table'
import { de } from 'date-fns/locale'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-profile-recent-logged-plays',
    templateUrl: './profile-recent-logged-plays.component.html',
    styleUrls: ['./profile-recent-logged-plays.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state(
                'expanded',
                style({
                    height: '*',
                    minHeight: '',
                }),
            ),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ProfileRecentLoggedPlaysComponent implements OnInit {
    currentUser
    tooltipMaxLength = 10
    maxNotesToSelfLengthBeforeTooltip = 14
    maxPublicNotesLengthBeforeTooltip = 14

    dataSource = new MatTableDataSource<any>() 

    @ViewChild(MatTable) table: MatTable<any>
    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort



    //! even though reset in init columns, this needs to be initialized here for it to work in modal
    displayedColumns = [
        'gamePlayed',
        'date',
        'otherPlayers',
        'view',
        'edit',
    ]
    mobileColumns = ['gamePlayed', 'date', 'expand']
    mobilExpandedElement

    constructor(
        private backendApiContentService: BackendAPIContentService,
        private loggedPlayService: LogPlayService,
        private backendApiGamesService: BackendAPIGamesService,
        private dialog: MatDialog,
        private stringHelperService: StringHelperService,
        private backendApiUsersService: BackendAPIUsersService,
        private screenSizeService: ScreenSizeService,
        private arrayHelperService: ArrayHelperService
    ) { }

    selectedEntity
    @Input() set _selectedEntity(selectedEntity) {
        if (selectedEntity) {
            this.selectedEntity = selectedEntity
            this.initColumns()
            this.fetchLoggedPlays()
        }
    }

    isGroup = false
    @Input() set _isGroup(isGroup) {
        if (isGroup) {
            this.isGroup = isGroup
        } else {
        }
    }

    @Input() set _directInputData(directInputData) {
        this.displayedColumns = [
            'gamePlayed',
            'date',
            'otherPlayers',
            'view',
        ]

        this.dataSource = new MatTableDataSource<any>(
            this.createCommaSeparatedListsForPlayersInvolved(directInputData),
        )
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort;

        if (this.table) {
            this.table.renderRows()
        }

    }

    userIsCurrentUser = false

    isMobileScreen = false

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.loggedPlayService.shouldRefreshLoggedPlays$
            .pipe(untilDestroyed(this))
            .subscribe((shouldRefreshLoggedPlays: boolean) => {
                if (shouldRefreshLoggedPlays && this.selectedEntity) {
                    this.fetchLoggedPlays()
                }
            })

        this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            if (user && this.selectedEntity) {
                this.currentUser = user
                this.userIsCurrentUser = this.currentUser.username == this.selectedEntity.username
                this.initColumns()
            }
        })
    }

    viewIsChecked = false
    ngAfterViewChecked() {
        if (!this.viewIsChecked) {
            if (this.dataSource) {
                this.viewIsChecked = true
                this.dataSource.sort = this.sort
            }
        }
    }

    initColumns() {
        if (this.userIsCurrentUser) {
            if (this.currentUser.username == this.selectedEntity.username) {
                this.displayedColumns = [
                    'gamePlayed',
                    'date',
                    'otherPlayers',
                    'view',
                    'edit',
                ]
            } else {
                this.displayedColumns = [
                    'gamePlayed',
                    'date',
                    'otherPlayers',
                    'view',
                ]
            }
        }
    }

    async openGameDetails(element) {

        // todo this is the LP ide not game
        let gameDetails = await this.backendApiGamesService.GetGame(element.gameId)

        let inputData = {
            limitDataFecthing: true,
            game: gameDetails,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: gameDetails.title,
                component: MdGameDetailComponent,
                hasSubmitButton: false,
                hasCloseButton: true,
                hasCancelButton: false,
                inputData: inputData, //! what does limit data fetching do???
                allowParentClose: true,
            },
            maxWidth: '90%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }

    truncate(input: string, maxCharacters: number) {
        return input ? this.stringHelperService.truncateString(input, maxCharacters) : null
    }









    eliminateDuplicates = (arr) => {

        const seenIds: { [key: string]: boolean } = {}
        const result = []

        for (const obj of arr) {
            if (!seenIds[obj.uniqueLpGroupingId]) {
                seenIds[obj.uniqueLpGroupingId] = true;
                result.push(obj)
            }
        }

        return result
    };








    async fetchLoggedPlays() {

        let result

        if (this.isGroup) {
            result = await this.backendApiContentService.listLPByGroupId(this.selectedEntity.pk, 50)

            // ! This is impropoerly elminating all duplicates 
            // result.loggedPlays = this.eliminateDuplicates(result.loggedPlays)

            this.dataSource = new MatTableDataSource<any>(
                this.createCommaSeparatedListsForPlayersInvolved(result.loggedPlays),
            )
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort

            if (this.table) {
                this.table.renderRows()
            }
        }
        else {
            result = await this.backendApiContentService.listLPByUserId(
                this.selectedEntity.username,
                50,
            )

            this.dataSource = new MatTableDataSource<any>(
                this.createCommaSeparatedListsForPlayersInvolved(result.loggedPlays),
            )
            this.dataSource.paginator = this.paginator
            this.dataSource.sort = this.sort

            if (this.table) {
                this.table.renderRows()
            }
        }
    }

    createCommaSeparatedListsForPlayersInvolved(loggedPlays) {
        //! keep in mind that if we add a filter then this will need to be datasource.filteredData or whatever...
        //* also keep in mind that accessing datasource directly is probably better, but in a hurry so using this param for now
        //* this is probably not the most efficient way to do this, but going with it for now

        // todo something going wrong here?
        // ! NOTE: some only daves last two LP will ahve the right structure. Will have to manually update others.
        // todo something going wrong here?

        if (loggedPlays && loggedPlays.length > 0) {
            for (let lp of loggedPlays) {
                let commaSeparatedPlayersList = '' // todo, set this to null and use a null data pipe?

                if (lp.taggedUsers && lp.taggedUsers != null && lp.taggedUsers) {
                    commaSeparatedPlayersList = this.stringHelperService.createCommaSeparatedString(
                        lp.taggedUsers,
                        true,
                    )
                }
                lp.commaSeparatedPlayersList = commaSeparatedPlayersList

                // ! others is a future problem?
            }
        }
        return loggedPlays
    }

    viewLPDetail(element) {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                title: 'Logged Play Details',
                inputData: element,
                component: SimpleLpDetailModalComponent,
                hasCloseButton: true,
                hasSubmitButton: false,
                hasCancelButton: false,
                // hasLeftActionButton: true,
                // leftActionButtonText: 'Start Over',
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }

    editLP(event) {
        this.currentUser = this.backendApiUsersService.getCurrentUser()

        if (event.taggedUsers[0].username == this.currentUser.username) {
            let carl = this.dialog.open(GhDialogWrapperComponent, {
                panelClass: this.isMobileScreen
                    ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                    : DESKTOP_MODAL_PANEL_CLASS,
                backdropClass: 'gh-dialog-backdrop',
                disableClose: true,
                data: {
                    title: 'Edit Logged Play',
                    inputData: event,
                    component: ShareModalAdvancedLogPlayComponent,
                    hasSubmitButton: true,
                    hasCancelButton: true,
                    hasCloseButton: true,
                    // hasLeftActionButton: true,
                    // leftActionButtonText: 'Start Over',
                    allowParentClose: true,
                },
                autoFocus: false,
            })
            carl.afterClosed()
                .pipe(untilDestroyed(this))
                .subscribe((output) => {
                    this.fetchLoggedPlays()
                })
        }
    }

    removeLPItem(item, userID) {
        // passing in item as a param, which should be good enough for this purpose I think. if we need to we can also pass the index

        let inputData = {
            message: 'Permanently delete: ' + item.title + '?',
            submessage: 'All game data and references will be deleted!',
            isDeletion: true,
            hasPassword: true,
        }
        let dialogRefFoo = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Are you sure?',
                component: ConfirmActionComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRefFoo
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                if (data && data.toLowerCase().trim() == DELETE_CONFIRMATION_PASSWORD) {
                    let prependedItem = '#' + item.id

                    let pdaInput = { title: prependedItem, userID: userID }
                    this.backendApiContentService.deleteLP(pdaInput)





                    let fetchedGame = await this.backendApiGamesService.GetGame(item.gameId);

                    // Convert currentTrendingFactor to an integer for arithmetic operation.
                    let currentTrendingFactor = parseInt(fetchedGame.trendingFactor, 10) || 0;

                    // Subtract 10 from currentTrendingFactor.
                    let newTrendingFactor = currentTrendingFactor - 10;

                    // If you need to convert newTrendingFactor back to string and pad it, uncomment the following line:
                    // let paddedNewTrendingFactor = newTrendingFactor.toString().padStart(10, '0');

                    let updateInput = {
                        pkName: 'pk',
                        pkValue: item.gameId,
                        skName: 'sk',
                        skValue: 'BD#' + item.gameId,
                        attributeName: 'trendingFactor',
                        // Use paddedNewTrendingFactor instead if padding is necessary
                        attributeValue: newTrendingFactor.toString()
                    };

                    await this.backendApiGamesService.updateSingleAttributeForGame(updateInput);

                    this.dataSource = this.arrayHelperService.removeFromArray(
                        this.dataSource,
                        item,
                        true,
                        'id',
                    );

                    this.table.renderRows()
                } else {
                    alert('Wrong Confirmation Code')
                }
            })
    }
}
