import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-games-find-interest-near-you',
  templateUrl: './games-find-interest-near-you.component.html',
  styleUrls: ['./games-find-interest-near-you.component.scss']
})
export class GamesFindInterestNearYouComponent implements OnInit {

  currentTabTitlePrefix = 'Gamers'

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
  }

  constructor() { }

  ngOnInit(): void {
  }

  onTabChange(event) {
    switch (event.index) {
      case 0:
        this.currentTabTitlePrefix = 'Gamers'
        break

      case 1:
        this.currentTabTitlePrefix = 'Havens'
        break
    }
  }
}
