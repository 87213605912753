import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { MAX_USERS_FOR_FIND_BEST_GAMES_THING } from 'src/app/dashboard/dashboard-shared/constants/games.constants'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service'
import { NumberHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/number-helper.service'
import { TimingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/timing-helper.service'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-play-now-modal',
  templateUrl: './play-now-modal.component.html',
  styleUrls: ['./play-now-modal.component.scss']
})
export class PlayNowModalComponent implements OnInit {

  gamesAreLoaded = new BehaviorSubject(false)
  gamesAreLoaded$ = this.gamesAreLoaded.asObservable()

  testAnim = false

  shouldAnimateAvatar0 = false
  shouldAnimateAvatar1 = false
  shouldAnimateAvatar2 = false
  shouldAnimateAvatar3 = false

  shouldShakeCup = false
  shouldTipCup = false

  shouldAnimateGame0Dump = false
  shouldAnimateGame0Bounce = false


  @ViewChild('avatar0', { static: false }) avatar0
  @ViewChild('avatar1', { static: false }) avatar1
  @ViewChild('avatar2', { static: false }) avatar2
  @ViewChild('avatar3', { static: false }) avatar3

  defaultUserImage = window.location.origin + '/assets/images/defaults/profile-placeholder.png'
  defaultGameImage = window.location.origin + '/assets/images/defaults/placeholder-event.png'

  currentUser
  friends = new BehaviorSubject(null)
  friends$ = this.friends.asObservable()

  tempGames
  topGames = []

  constructor(
    private arrayHelperService: ArrayHelperService,
    private backendAPIUsersService: BackendAPIUsersService,
    private timingHelperService: TimingHelperService,
    private usersService: UsersService,
    private gamesService: GamesService,
    private numberHelperService: NumberHelperService,
    private backendApiGamesService: BackendAPIGamesService,
    private screenSizeService: ScreenSizeService,
    private dialog: MatDialog) { }


  isMobileScreen = false
  ngOnInit(): void {
    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })

    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      if (user) {
        this.currentUser = user
        this.initFriends()
        this.tempGetGames()
      }
    })
  }

  async tempGetGames() {
    let coreGames = this.gamesService.getCoreGames()
    let gamesSample = []

    let randomGameStartIndex = this.numberHelperService.getRandomIntFromInterval(1, 200)

    // fetch 10 games starting at a random index, for now
    for (var i = randomGameStartIndex; i < randomGameStartIndex + 10; i++) {
      let game = coreGames[i]

      let mainImageFilesRes = await this.backendApiGamesService.GetMainImage(game.pk)

      game.i = game.pk
      game.v = this.numberHelperService.getRandomIntFromInterval(1, 10) //TODO: I think Ben is expecting decimals, need new helper method for that
      game.e = 0 // ! what is e???
      game.t = game.title
      game.mainImageFiles = mainImageFilesRes.mainImageFiles
      gamesSample.push(game)
    }
    this.tempGames = gamesSample
    this.gamesAreLoaded.next(true)
  }

  avatarsAreLoaded = false

  ngAfterViewChecked() {
  }

  async initFriends() {
    let friendIdsParsed = this.currentUser.friends
    var friendsList = []
    // for (let id of friendIdsParsed) {
    //   await this.usersDbService.getNonCurrentUserBulkData(id).then(result => {
    //     friendsList.push(result)
    //   })
    // }
    // this.usersDbService.getFriends(this.currentUser.pk).then(result => {
    //   // only fetching the first one for now, in a hurry for emergency demo (Dave only has one friend right now and its Shay)
    //   this.usersDbService.getNonCurrentUserBulkData(JSON.parse(result.data)[0]).then(shay => {
    //     friendsList.push(shay)
    //   })
    // })


    this.usersService.myFriends$
      .pipe(untilDestroyed(this))
      .subscribe(async (myFriends) => {
        this.friends.next(myFriends)
        let friendsList = []

        for (let friend of this.friends.value) {

          await this.backendAPIUsersService.getProfileById(friend.pk).then(result => {
            if (result != null) {
              friendsList.push(result)
            }
          })
        }
        let reshapedFriends = this.reshapeFriendsToWorkWithMD(friendsList)
        this.friends.next(reshapedFriends)

        this.friends.next(friendsList)
      })



  }

  // below method might be unnecessary....
  reshapeFriendsToWorkWithMD(friends) {
    for (let friend of friends) {
      if (friend.pk) {
        friend.pk = friend.username
      }
    }
    return friends
  }

  addFriendsToBig8Match(event) {
    this.testCombineUsersLists()
  }

  testCombineUsersLists() {
    //! actually want to use predictedRatings list from users, but to get a better estimate of size and compute, using mock data for now...
    // let currentUserValues = JSON.parse(this.currentUser.gameRecommendations)
    // let friend0values = JSON.parse(this.friends[0].gameRecommendations)



    // ! woudl do try catch here (below), but why would we set predecited ratings to null anyways?????

    let predictedRatings = null //! NEED TO REDO THIS WITH NEW SERVERLESS SYSTEM //this.gamesDbAccessorService.getPredictedRatingsArray()

    // predictedRatings = tempPredictedsRatiings
    // this.tempGetGames()
    predictedRatings = this.tempGames

    let predictedRatingsShuffled0 = this.arrayHelperService.generateShallowCopy(this.arrayHelperService.shuffleArray(predictedRatings))
    let predictedRatingsShuffled1 = this.arrayHelperService.generateShallowCopy(this.arrayHelperService.shuffleArray(predictedRatings))
    let predictedRatingsShuffled2 = this.arrayHelperService.generateShallowCopy(this.arrayHelperService.shuffleArray(predictedRatings))

    // var t0 = performance.now()
    // let resultOf4Users = this.getBestGamesFor4Users(predictedRatings, predictedRatingsShuffled0,predictedRatingsShuffled1, predictedRatingsShuffled2)
    // var t1 = performance.now()

    // let resultOfNUsers = this.getBestGamesForNUsers([predictedRatings, predictedRatingsShuffled0, predictedRatingsShuffled1, predictedRatingsShuffled2])
    let resultOfNUsers = this.getBestGamesFor4Users(predictedRatings, predictedRatingsShuffled0, predictedRatingsShuffled1, predictedRatingsShuffled2)
    this.topGames = []
    for (var i = 0; i < 10; i++) {
      this.topGames.push(resultOfNUsers[i])
    }
  }

  getBestGamesFor4Users(l1, l2, l3, l4) {
    l1 = l1.sort((a, b) => (a.i > b.i) ? 1 : -1)
    l2 = l2.sort((a, b) => (a.i > b.i) ? 1 : -1)
    l3 = l3.sort((a, b) => (a.i > b.i) ? 1 : -1)
    l4 = l4.sort((a, b) => (a.i > b.i) ? 1 : -1)

    var result = []
    // length must be the same for each list
    // values for i, e, and t are the same for each, so just using l1 for loop and in result.push object
    for (var i = 0; i < l1.length; i++) {
      let numericalL1 = +l1[i].v
      let numericalL2 = +l2[i].v
      let numericalL3 = +l3[i].v
      let numericalL4 = +l4[i].v
      result.push(
        {
          i: l1[i].i,
          v: numericalL1 + numericalL2 + numericalL3 + numericalL4,
          e: l1[i].e,
          t: l1[i].t,
          mainImageFiles: l1[i].mainImageFiles
        }
      )
    }

    result = result.sort((a, b) => (+a.v > +b.v) ? 1 : -1)
    result = result.reverse() // sorting gives ascending order, not sure if there is a way to change that without using reverse after...
    return result
  }

  // below is a length-agnostic version of the method above, but keeping the method above for now for reference to how I came up with the below method
  getBestGamesForNUsers(predictedRatings: any[]) {
    //! Mock data note: rather than having differing mock data for each user, I just shuffled the single instance of mock data to simulate multiple lists,
    //!     thus, the value "v" for each item with the same value for "i" is the same (ex. value "v" for item at index 0 (where "i" is "aaad") is 214 for each list)
    //!     this seems inconsequential though, for the sake of getting an estimate for stress testing
    var predictedRatingsLength = predictedRatings.length
    var listsAreAllTheSameLength = true
    var listLength = predictedRatings[0].length
    var listsSortedById = []
    for (let list of predictedRatings) {
      listsAreAllTheSameLength = list.length == listLength
      listsSortedById.push(list.sort((a, b) => (a.i > b.i) ? 1 : -1))
    }

    if (!listsAreAllTheSameLength || predictedRatingsLength > MAX_USERS_FOR_FIND_BEST_GAMES_THING) {
      return new Error('lists must all be the same length and the max number of lists allowed is ' + MAX_USERS_FOR_FIND_BEST_GAMES_THING + '!')
    }

    //! Don't delete these comments, this is a weird algorithm
    //* NOTE: this is an unusual loop through a multi-dimensional array...
    //*      I changed index "i" to "gameIndex" and index "j" to "listIndex", for a little better readability
    //*      The strange thing is that rather than the usual [i][j], the appropriate reference is [j][i]

    var result = []
    for (var gameIndex = 0; gameIndex < listLength; gameIndex++) { //* loops through the large list of games (which is the same length for each list)

      var sum = 0 //* this is the sum of ratings for the game at index "gameIndex" from each list
      for (var listIndex = 0; listIndex < listsSortedById.length; listIndex++) { //* loops through the list of lists, which is at most a length of 4
        sum += +listsSortedById[listIndex][gameIndex].v
      }

      //* using index 0 for "listsSortedByIndex[0]" is arbitrary. Each list at index "gameIndex" has the same values for everything aside from "v"
      result.push(
        {
          i: listsSortedById[0][gameIndex].i,
          v: sum,
          e: listsSortedById[0][gameIndex].e,
          t: listsSortedById[0][gameIndex].t
        }
      );
    }

    result = result.sort((a, b) => (+a.v > +b.v) ? 1 : -1)
    result = result.reverse() // sorting gives ascending order, not sure if there is a way to change that without using reverse after...
    return result

  }

  async startAnim() {
    // this.avatar0.nativeElement.classList.add('animate')

    this.animateAvatars()
    this.animateCup()
    this.animateGamesResult()
  }


  async animateAvatars() {
    this.shouldAnimateAvatar0 = true

    await this.timingHelperService.delay(100).then()
    this.shouldAnimateAvatar1 = true

    await this.timingHelperService.delay(100).then()
    this.shouldAnimateAvatar2 = true

    await this.timingHelperService.delay(100).then()
    this.shouldAnimateAvatar3 = true

    await this.timingHelperService.delay(500).then()
    this.avatar0.nativeElement.classList.add('hide')
    this.avatar1.nativeElement.classList.add('hide')
    this.avatar2.nativeElement.classList.add('hide')
    this.avatar3.nativeElement.classList.add('hide')
  }


  async animateCup() {
    this.shouldShakeCup = true

    await this.timingHelperService.delay(500).then()
    this.shouldTipCup = true

    await this.timingHelperService.delay(500).then()
    this.shouldTipCup = true
  }

  async animateGamesResult() {
    await this.timingHelperService.delay(750).then()
    this.shouldAnimateGame0Dump = true

    await this.timingHelperService.delay(750).then()
    this.shouldAnimateGame0Bounce = true
  }

  async openGameDetails(game) {
    let gameDetails = await this.backendApiGamesService.GetGame(game.i)
    let inputData = {
      limitDataFecthing: true,
      game: gameDetails,
    }

    this.dialog.open(GhDialogWrapperComponent, {
      data: {
        title: game.t,
        component: MdGameDetailComponent,
        hasSubmitButton: false,
        hasCloseButton: true,
        hasCancelButton: false,
        inputData: inputData,
        allowParentClose: true,
      },
      maxWidth: '90%',
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      disableClose: true,
    })
  }


}



const tempPredictedsRatiings = [
  {
    e: 0,
    i: 'abc',
    t: 'star realms',
    v: 5
  },
  {
    e: 0,
    i: '123',
    t: 'monopoly',
    v: 3
  },
  {
    e: 0,
    i: 'efg',
    t: 'guess who',
    v: 1
  },
  {
    e: 0,
    i: '456',
    t: 'candy land',
    v: 7
  },
  {
    e: 0,
    i: 'xyz',
    t: 'drop it',
    v: 8
  },
  {
    e: 0,
    i: '789',
    t: 'shoots and laddars',
    v: 9
  },
  {
    e: 0,
    i: 'jkl',
    t: 'blabla',
    v: 3
  },
  {
    e: 0,
    i: 'lmn',
    t: 'foobar',
    v: 9
  },
  {
    e: 0,
    i: 'fuy',
    t: 'another title',
    v: 5
  },
  {
    e: 0,
    i: 'rhf',
    t: 'breain dead',
    v: 8
  }
]