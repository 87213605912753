import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { untilDestroyed } from '@ngneat/until-destroy';
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS, ONBOARDING_USER_LEVEL_THRESHOLD } from 'src/app/app.constants';
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service';
import { BulkRateGamesComponent } from 'src/app/dashboard/dashboard-shared/components/ratings/bulk-rate-games/bulk-rate-games.component';
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component';
import { ContentService } from 'src/app/dashboard/dashboard-shared/services/content/content.service';
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service';

@Component({
  selector: 'app-game-detail-subjective-info',
  templateUrl: './game-detail-subjective-info.component.html',
  styleUrls: ['./game-detail-subjective-info.component.scss']
})
export class GameDetailSubjectiveInfoComponent implements OnInit {

  onboardingUserLevelThreshold = ONBOARDING_USER_LEVEL_THRESHOLD


  gameRating = { rating: 0 }
  @Input() set _gameRating(gameRating) {
    // alert(gameRating.rating)
    if (gameRating && gameRating != null) {
      this.gameRating = { rating: gameRating.rating }
    }
  }

  user
  @Input() set _user(user) {
    if (user) {
      this.user = user
    }
  }

  selectedGame
  @Input() set _selectedGame(selectedGame) {
    this.selectedGame = selectedGame
  }


  constructor(
    private backendApiGameTrackingService: BackendApiGameTrackingService,
    private contentService: ContentService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private screenSizeService: ScreenSizeService
  ) {
    changeDetectorRef.detach();
    setInterval(() => {
      this.changeDetectorRef.detectChanges();
    }, 250);
  }

  isMobileScreen = false
  ngOnInit(): void {
    this.screenSizeService.isMobileScreen$
      .pipe(untilDestroyed(this))
      .subscribe((isMobileScreen: boolean) => {
        this.isMobileScreen = isMobileScreen
      })

  }

  async setStarRating(event) {
    //* if event is null then the rating will be reset to essentially be unrated
    await this.backendApiGameTrackingService.createGameTrackingAddRating({
      gameId: this.selectedGame.id,
      UID: this.user.username,
      type: 'rating',
      userState: this.user.state,
      userZipCode: this.user.zipCode,
      userTitle: this.user.title,
      title: this.selectedGame.title,
      mainImageFiles: this.selectedGame.mainImageFiles,
      rating: event,
      big8Vote: undefined
    })

    this.contentService.fetchCurrentUserRatings()
  }

  openBulkRateGames() {
    let onboardingDialogRef = this.dialog.open(GhDialogWrapperComponent, {
      data: {
        title: 'Bulk Rate Games',
        component: BulkRateGamesComponent,
        hasSubmitButton: true,
        hasCancelButton: true,
        hasLeftActionButton: true,
        allowParentClose: true,
        hasCloseButton: true,
      },
      panelClass: this.isMobileScreen
        ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
        : DESKTOP_MODAL_PANEL_CLASS,
      backdropClass: 'gh-dialog-backdrop',
      disableClose: true,
      width: '90%',
      height: '90%',
    })

    onboardingDialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((data) => { })
  }

}
