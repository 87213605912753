<div class="gh-container">
    <div class="row">
        <div class="col-md-3 col-sm-12">
            <section class="gh-section">
                <mat-card-subtitle>Competition Tags:</mat-card-subtitle>
                <app-autocomplete-chiplist [_items]="competitionTags" [_label]="'Add tag...'" [_sourceOfInput]="'app-add-tags1'"
                    [_allowDuplicates]="false" [_allowCustomInput]="false"></app-autocomplete-chiplist >
            </section>
        </div>

        <div class="col-md-3 col-sm-12">
            <section class="gh-section">
                <mat-card-subtitle>Mechanic Tags:</mat-card-subtitle>
                <app-autocomplete-chiplist [_items]="mechanicTags" [_label]="'Add tag...'" [_sourceOfInput]="'app-add-tags2'"
                    [_allowDuplicates]="false" [_allowCustomInput]="false">
                </app-autocomplete-chiplist >
            </section>
        </div>

        <div class="col-md-3 col-sm-12">
            <section class="gh-section">
                <mat-card-subtitle>Setting Tags:</mat-card-subtitle>
                <app-autocomplete-chiplist [_items]="settingTags" [_label]="'Add tag...'" [_sourceOfInput]="'app-add-tags3'"
                    [_allowDuplicates]="false" [_allowCustomInput]="false">
                </app-autocomplete-chiplist >
            </section>
        </div>

        <div class="col-md-3 col-sm-12">
            <section class="gh-section">
                <mat-card-subtitle>Uncertainty Tags:</mat-card-subtitle>
                <app-autocomplete-chiplist [_items]="uncertaintyTags" [_label]="'Add tag...'" [_sourceOfInput]="'app-add-tags4'"
                    [_allowDuplicates]="false" [_allowCustomInput]="false"></app-autocomplete-chiplist >
            </section>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-sm-12">
            <section class="gh-section">
                <mat-card-subtitle>Interaction Tags:</mat-card-subtitle>
                <app-autocomplete-chiplist [_items]="interactionTags" [_label]="'Add tag...'" [_sourceOfInput]="'app-add-tags5'"
                    [_allowDuplicates]="false" [_allowCustomInput]="false"></app-autocomplete-chiplist >
            </section>
        </div>

        <div class="col-md-3 col-sm-12">
            <section class="gh-section">
                <mat-card-subtitle>Atmosphere Tags:</mat-card-subtitle>
                <app-autocomplete-chiplist [_items]="atmosphereTags" [_label]="'Add tag...'" [_sourceOfInput]="'app-add-tags6'"
                    [_allowDuplicates]="false" [_allowCustomInput]="false"></app-autocomplete-chiplist >
            </section>
        </div>

        <div class="col-md-3 col-sm-12">
            <section class="gh-section">
                <mat-card-subtitle>Skill Tags:</mat-card-subtitle>
                <app-autocomplete-chiplist [_items]="skillTags" [_label]="'Add tag...'" [_sourceOfInput]="'app-add-tags7'"
                    [_allowDuplicates]="false" [_allowCustomInput]="false"></app-autocomplete-chiplist >
            </section>
        </div>

        <div class="col-md-3 col-sm-12">
            <section class="gh-section">
                <mat-card-subtitle>Accessibility Tags:</mat-card-subtitle>
                <app-autocomplete-chiplist [_items]="accessibilityTags" [_label]="'Add tag...'" [_sourceOfInput]="'app-add-tags8'"
                    [_allowDuplicates]="false" [_allowCustomInput]="false"></app-autocomplete-chiplist >
            </section>
        </div>
    </div>
</div>


<div class="row">
    <button id="submit-button" mat-raised-button color="success" (click)="submitTags()">SUBMIT</button>
</div>