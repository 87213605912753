<div class="parent-container" *ngIf="(videos$ | async)">
    <!-- <div class="inner-container" *ngIf="videos.value.length > 0; else noData">
        <iframe *ngFor="let video of videos.value" width="300" height="250" [src]="getVideoPath(video.data)" frameborder="0"
            allowfullscreen></iframe>
    </div> -->

    <app-image-or-video-gallery-grid *ngIf="videos.value.length > 0; else noData" [_origin]="'games'"
        [_items]="videos.value" [_type]="'video'"></app-image-or-video-gallery-grid>

    <ng-template #noData>
        <app-no-data style="margin-left: 42rem" [_message]="'No data...'"></app-no-data>
    </ng-template>

</div>