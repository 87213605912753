import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { Auth } from 'aws-amplify'
import gql from 'graphql-tag'
import { iCreateInviteInput, iCreateMessageInput, iCreateRequestInput, iDeleteMessageInput } from '../../../../common/interfaces/MessageInterfaces'

@Injectable({
  providedIn: "root"
})
export class BackendAPIMessageService {

  myInvites = []
  myRequests = []
  myReceivedMessages = []

  constructor(

  ) { }

  private appSyncClient = new AWSAppSyncClient({
    url: environment.apiUrl,
    region: 'us-east-1',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        return token
      }
    },
    disableOffline: true,
  })



  async createMessageItem(createMessageInput: iCreateMessageInput) {

    let input = {
      message: createMessageInput.message,

      createdByUID: createMessageInput.createdByUID,
      sentToUID: createMessageInput.sentToUID,

      sentToTitle: createMessageInput.sentFromTitle,
      sentFromTitle: createMessageInput.sentToTitle
    }

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
            mutation createMessageItem($input: IGQL_CreateMessageItemInput!) {
              createMessageItem (input: $input) {
                message
              }
            }
          `,
      variables: { input },

    })
    return response.data.createMessageItem
  }


  async createRequestItem(createRequestInput: iCreateRequestInput) {

    let input = {
      type: createRequestInput.type,
      createdByUID: createRequestInput.createdByUID,
      sentToUID: createRequestInput.sentToUID,
      sentToTitle: createRequestInput.sentFromTitle,
      sentFromTitle: createRequestInput.sentToTitle
    }
    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
            mutation createRequestItem($input: IGQL_CreateRequestItemInput!) {
              createRequestItem (input: $input) {
                type
              }
            }
          `,
      variables: { input },

    })
    return response.data.createRequestItem
  }


  async createInviteItem(createInviteInput: iCreateInviteInput) {

    let input = { // Reshape Here ????
      sentToUID: createInviteInput.sentToUID,
      sentToTitle: createInviteInput.sentToTitle,
      sentFromUID: createInviteInput.sentFromUID,
      sentFromTitle: createInviteInput.sentFromTitle,
      eventID: createInviteInput.eventID,
      type: createInviteInput.type,
      extendedProps: createInviteInput.extendedProps
    }

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
            mutation createInviteItem($input: IGQL_CreateInviteItemInput!) {
              createInviteItem (input: $input) {
                type
              }
            }
          `,
      variables: { input },

    })
    return response.data.createInviteItem
  }


  async createArchivedMessageItem(createMessageInput: iCreateMessageInput) {

    let input = {
      message: createMessageInput.message,
      createdByUID: createMessageInput.createdByUID,
      sentToUID: createMessageInput.sentToUID,
      sentToTitle: createMessageInput.sentFromTitle,
      sentFromTitle: createMessageInput.sentToTitle
    }

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
            mutation createArchivedMessageItem($input: IGQL_CreateMessageItemInput!) {
              createArchivedMessageItem (input: $input) {
                message
              }
            }
          `,
      variables: { input }
    })
    return response.data.createArchivedMessageItem
  }

  async listMyFriendRequests(userId: string, limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
            query listMyFriendRequests($userId: String!, $limit: Int!, $nextToken: String ) {
              listMyFriendRequests(userId: $userId, limit: $limit, nextToken: $nextToken ) {
                requests{
                  pk_UID
                  sk_type_ULID
                  createdAt
                  createdBy
                  type
                  sentFromTitle
                  sentToTitle
                  sentToUID
                }
              }
            }
          `,
      variables: { userId, limit, nextToken },
      fetchPolicy: 'network-only'
    })
    this.myInvites = response.data.listMyFriendRequests.requests
    return response.data.listMyFriendRequests
  }

  async listMyInvites(userId: string, limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
            query listMyInvites($userId: String!, $limit: Int!, $nextToken: String ) {
              listMyInvites(userId: $userId, limit: $limit, nextToken: $nextToken ) {
                invites{
                  sentFromUID
                  pk_UID
                  sk_type_ULID
                  createdAt
                  type
                  sentFromTitle
                  sentToTitle
                  sentToUID
                  eventID
                  extendedProps{
                    date
                    description
                    end
                    endTime
                    eventOrganizer
                    eventOrganizerId
                    eventTitle
                    havenTitle
                    havenZipCode
                    mainImageFiles
                    startTime
                    ulid
                    selectedGames {
                      big8
                      corePK
                      coreSK
                      mainImageFiles
                      pk
                      popularity
                      sk
                      title
                      umv
                    }
                  }
                }
              }
            }
          `,
      variables: { userId, limit, nextToken },
      fetchPolicy: 'network-only'
    })
    this.myInvites = response.data.listMyInvites.invites //!!!! is .invites correct? didnt have any to test it at the moment
    return response.data.listMyInvites
  }



  async listMyReceivedMessages(userId: string, limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listMyReceivedMessages($userId: String!, $limit: Int!, $nextToken: String ) {
            listMyReceivedMessages(userId: $userId, limit: $limit, nextToken: $nextToken ) {
              messages{
                pk_UID
                sk_type_ULID
                createdAt
                createdBy
                message
                sentFromTitle
                sentToTitle
                sentToUID
              }
            }
          }
        `,
      variables: { userId, limit, nextToken },
      fetchPolicy: 'network-only'
    })
    this.myReceivedMessages = response.data.listMyReceivedMessages.messages
    return response.data.listMyReceivedMessages
  }

  async listMySentMessages(userId: string, limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
            query listMySentMessages($userId: String!, $limit: Int!, $nextToken: String ) {
              listMySentMessages(userId: $userId, limit: $limit, nextToken: $nextToken ) {
                messages{
                  message
                  createdAt
                  pk_UID
                  sk_type_ULID
                  sentToUID
                  createdBy
                  sentToTitle
                  sentFromTitle
                }
              }
            }
          `,
      variables: { userId, limit, nextToken },
      fetchPolicy: 'network-only'
    })
    return response.data.listMySentMessages
  }




  async listMyArchivedMessages(userId: string, limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
            query listMyArchivedMessages($userId: String!, $limit: Int!, $nextToken: String ) {
              listMyArchivedMessages(userId: $userId, limit: $limit, nextToken: $nextToken ) {
                messages{
                  message
                  createdAt
                  pk_UID
                  sk_type_ULID
                  sentToUID
                  createdBy
                  sentToTitle
                  sentFromTitle
                }
              }
            }
          `,
      variables: { userId, limit, nextToken },
      fetchPolicy: 'network-only'
    })
    return response.data.listMyArchivedMessages
  }

  async listInvitesByEventID(eventID: string, limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
            query listInvitesByEventID($eventID: String!, $limit: Int!, $nextToken: String ) {
              listInvitesByEventID(eventID: $eventID, limit: $limit, nextToken: $nextToken ) {
                messages{
                  message
                  createdAt
                  pk_UID
                  sk_type_ULID
                  sentToUID
                  createdBy
                  sentToTitle
                  sentFromTitle
                }
              }
            }
          `,
      variables: { eventID, limit, nextToken },
      fetchPolicy: 'network-only'
    })
    return response.data.listInvitesByEventID
  }








  async deleteMessage(input: iDeleteMessageInput) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation deleteMessage($input: IGQL_DeleteMessageInput!) {
            deleteMessage (input: $input) {
              pk_UID
              sk_type_ULID
              createdAt
              sentToUID
              sentToTitle
            }
          }
        `,
      variables: { input }
    })

    return response.data.deleteMessage
  }









}
