<div class="align-items-center row">
    <!-- Left Column: Button -->
    <div class="text-end col-md-4">
        <button
            mat-raised-button
            class="m-3 p-2 w-75 create-event-btn"
            color="primary"
            aria-label="Create Shared Library Item"
            (click)="createGameInterestItem()"
        >
            Create Looking to Play Item
        </button>
    </div>

    <!-- Right Column: Input -->
    <div class="col-md-4">
        <mat-form-field appearance="fill" style="margin-top: 21px" class="w-100">
            <mat-label>Filter by Game</mat-label>
            <input
                matInput
                placeholder="Search games"
                [(ngModel)]="filterValue"
                (input)="applyFilter()"
                aria-label="Filter games by name"
            />
        </mat-form-field>
    </div>
</div>

<!-- Content Section -->
<div class="mx-0 mb-3 px-3 data-group row">
    <div class="w-100 col-12">
        <!-- Desktop Version -->
        <div [hidden]="isMobileScreen" class="day-content table-container">
            <table
                mat-table
                [dataSource]="events"
                class="mat-elevation-z8 mat-table"
                matSort
                [matSortActive]="'startTime'"
                matSortDirection="asc"
                #daySort="matSort"
            >
                <!-- Game Column -->
                <ng-container matColumnDef="game">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Game</th>
                    <td mat-cell *matCellDef="let game">
                        <a
                            href="#"
                            (click)="openGameDetails(game); $event.preventDefault()"
                            class="gh-link"
                        >
                            {{ game.name }}
                        </a>
                    </td>
                </ng-container>

                <!-- Brough By -->
                <!-- <ng-container matColumnDef="broughtBy">
                    <th mat-header-cell *matHeaderCellDef>Brought By</th>
                    <td mat-cell *matCellDef="let game">
                        <a
                            href="#"
                            (click)="openUserDetails(game.createdByUID); $event.preventDefault()"
                            class="clickable-link"
                        >
                            {{ game.createdByTitle }}
                        </a>
                    </td>
                </ng-container> -->

                <ng-container matColumnDef="notes">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
                    <td mat-cell *matCellDef="let game">
                        <ng-container *ngIf="game.notes.length > 20; else fullNotes">
                            <a
                                href="#"
                                (click)="openNotesDialog(game.notes); $event.preventDefault()"
                                class="gh-link"
                                aria-label="View full notes"
                            >
                                {{ game.notes | slice : 0 : 20 }}...
                            </a>
                        </ng-container>
                        <ng-template #fullNotes>
                            <span class="truncate-disabled">{{ game.notes }}</span>
                        </ng-template>
                    </td>
                </ng-container>

                <!-- Attend/Leave Button Column -->
                <ng-container matColumnDef="attend">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let game">
                        <!-- <button
                            mat-raised-button
                            [ngClass]="{
                                'interested-button': !game.currentPlayers.includes(user.title),
                                'not-interested-button': game.currentPlayers.includes(user.title)
                            }"
                            (click)="addNameToListOfUsers(game)"
                            aria-label="Toggle interest in the game"
                        >
                            {{
                                game.currentPlayers.includes(user.title)
                                    ? 'Not Interested'
                                    : 'Also Interested'
                            }}
                        </button> -->
                        <div *ngIf="game.currentPlayers && game.currentPlayers.length">
                            <span class="interested-users">
                                Interested: {{ game.currentPlayers.join(', ') }}
                            </span>
                        </div>
                    </td>
                </ng-container>

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let game">
                        <!-- Delete Button: Visible if User is an Admin or Organizer -->
                        <button
                            mat-icon-button
                            aria-label="Delete event"
                            *ngIf="isGHAdmin || game.isUserOrganizer"
                            (click)="openDeleteDialog(game)"
                        >
                            <mat-icon class="red-icon">delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Header Row -->
                <tr mat-header-row *matHeaderRowDef="dayViewDisplayedColumns"></tr>
                <!-- Data Rows -->
                <tr
                    [ngClass]="{
                        'bold-row': isUserAttending(row),
                        'organizer-user': row.isUserOrganizer,
                        'overlapping-event': row.isOverlapping
                    }"
                    mat-row
                    *matRowDef="let row; columns: dayViewDisplayedColumns"
                ></tr>
            </table>
        </div>
    </div>
</div>

<!-- No Events Template -->
<ng-template #noEvents>
    <div class="no-events-message">
        <p>No events are currently available.</p>
        <p>Join an event from the schedule or create a new event</p>
    </div>
</ng-template>

<!-- Notes Dialog Template -->
<ng-template #notesDialog let-data>
    <h1 mat-dialog-title>Notes</h1>
    <div mat-dialog-content>
        {{ data }}
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
    </div>
</ng-template>

<!-- Delete Dialog Template -->
<ng-template #deleteDialog>
    <h1 mat-dialog-title>Confirm Deletion</h1>
    <div mat-dialog-content>Are you sure you want to delete this scheduled play?</div>
    <div mat-dialog-actions>
        <button mat-button (click)="closeDeleteDialog()" tabindex="0">Cancel</button>
        <button mat-button color="warn" (click)="confirmDelete()" tabindex="0">Delete</button>
    </div>
</ng-template>
