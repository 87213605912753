import { TmplAstTextAttribute } from '@angular/compiler'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ArrayHelperService {

  constructor() { }

  isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false
    }
    return typeof obj[Symbol.iterator] === 'function'
  }

  removeFromArray(array, valueToRemove, arrayItemsAreObjects = false, attributeToCompareWhenItemsAreObjectsKey?) {
    var i = array.length
    while (i--) {
      if (arrayItemsAreObjects) {

        if (typeof valueToRemove === 'string' || valueToRemove instanceof String) {
          if (array[i][attributeToCompareWhenItemsAreObjectsKey] == valueToRemove) {
            array.splice(i, 1)
          }
        }
        else {
          if (array[i][attributeToCompareWhenItemsAreObjectsKey] == valueToRemove[attributeToCompareWhenItemsAreObjectsKey]) {
            array.splice(i, 1)
          }
        }

      }
      else if (array[i] == valueToRemove) {
        array.splice(i, 1);
      }
    }
    return array
  }

  checkIfIsArrayOfTypeT(arr, type: string) {
    if (Array.isArray(arr)) {
      const isCorrectArrayType =
        arr.length > 0 &&
        arr.every((value) => {
          return typeof value === type;
        });
      return isCorrectArrayType
    }
  }

  //*  Fisher-Yates (aka Knuth) Shuffle
  shuffleArray(array) {
    var currentIndex = array.length, temporaryValue, randomIndex

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }
    return array
  }

  generateShallowCopy(array) {
    return JSON.parse(JSON.stringify(array))
  }

  removePrependingCharacters(array, characters: string) {
    for (let item of array) {
      item['coreSK'] = item['coreSK'].replace(characters, '')
    }
    return array
  }

  extractSubsetFromArray(wholeSet, subset, wholeSetIsObjects, subsetIsObjects, attributeToCompareWhenItemsAreObjectsKey) {
    let result = []

    for (let item of wholeSet) {
      for (let subsetItem of subset) {

        //! this shit can likely be drastically simplified, but going with this for now...
        if (wholeSetIsObjects && subsetIsObjects) {
          if (item[attributeToCompareWhenItemsAreObjectsKey] == subsetItem[attributeToCompareWhenItemsAreObjectsKey]) {
            result.push(item)
          }
        }
        else if (wholeSetIsObjects && !subsetIsObjects) {
          if (item[attributeToCompareWhenItemsAreObjectsKey] == subsetItem) {
            result.push(item)
          }
        }
        else if (!wholeSetIsObjects && subsetIsObjects) {
          if (attributeToCompareWhenItemsAreObjectsKey == subsetItem[attributeToCompareWhenItemsAreObjectsKey]) {
            result.push(item)
          }
        }
      }
    }
    return result
  }

  removeDuplicatesFromArray(wholeSet, subset, wholeSetIsObjects, subsetIsObjects, attributeToCompareWhenItemsAreObjectsKey) {
    let result = []

    for (let item of wholeSet) {
      for (let subsetItem of subset) {

        //! this shit can likely be drastically simplified, but going with this for now...
        if (wholeSetIsObjects && subsetIsObjects) {
          if (item[attributeToCompareWhenItemsAreObjectsKey] == subsetItem[attributeToCompareWhenItemsAreObjectsKey]) {
            result.push(item)
          }
        }
        else if (wholeSetIsObjects && !subsetIsObjects) {
          if (item[attributeToCompareWhenItemsAreObjectsKey] == subsetItem) {
            result.push(item)
          }
        }
        else if (!wholeSetIsObjects && subsetIsObjects) {
          if (attributeToCompareWhenItemsAreObjectsKey == subsetItem[attributeToCompareWhenItemsAreObjectsKey]) {
            result.push(item)
          }
        }
      }
    }
    return result
  }

}
