export enum ReviewTypeEnums {
    "game" = "Game",
    "haven" = "Haven",
    // "group" = "Group"
}

// categories related to which location in the site the component is being used
export enum ReviewCategoryEnums {
    "myProfile" = "Profile",
    "friendsProfile" = "Friends Profile",
    "games" = "Games",
    "havens" = "Havens",
    "groups" = "Groups"
}

export enum ReviewTypeSortingEnums {
    "hot" = "Trending",
    // "smoldering" = "Smoldering",
    "date" = "Date",
    "likes" = "Likes",
    "followers" = "Followers",
    "rating" = "Rating"
}