import { Component, Input, OnInit } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-profile-tabs',
  templateUrl: './profile-tabs.component.html',
  styleUrls: ['./profile-tabs.component.scss']
})
export class ProfileTabsComponent implements OnInit {


  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
  }

  isMobileScreen = false

  constructor(private screenSizeService: ScreenSizeService) { }

  ngOnInit(): void {
    this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobileScreen: boolean) => {
      this.isMobileScreen = isMobileScreen
    })
  }

}
