<div class="parent-container">

    <div class="row">
        <div class="col-md-6 m-5">
            <img [src]="imageDestination" class="img-preview" [ngClass]="{ isProfileImg: inputData.isProfileImg }">
        </div>
        <div class="col-md-6 m-5">
            <div class="input-img-container">
                <img #image id="input-img" [src]="inputData.img" crossorigin>
            </div>
        </div>
    </div>

</div>