<div class="schedule-play-session">
    <mat-card>
        <mat-card-title>
            {{ isEditing ? 'Edit Play Session' : 'Schedule a Play Session' }}
        </mat-card-title>
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="submitForm()">
                <!-- Title -->
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="title" />
                    <mat-error
                        *ngIf="form.get('title').hasError('required') && form.get('title').touched"
                    >
                        Title is required
                    </mat-error>
                </mat-form-field>

                <!-- Game Title -->
                <div class="form-field full-width">
                    <label class="custom-label">Game Title</label>
                    <app-autocomplete-chiplist
                        *ngIf="coreGames$ | async"
                        [_items]="coreGames.value"
                        [_selectedItems]="selectedGame ? [selectedGame] : null"
                        [_allowDuplicates]="false"
                        [_allowCustomInput]="false"
                        [_itemsAreObjects]="true"
                        [_singleInput]="true"
                        [_sourceOfInput]="'app-share-modal-advanced-log-play1'"
                        [_useFuzzySearch]="true"
                        (selectionChanged)="selectGame($event)"
                    ></app-autocomplete-chiplist>
                    <div class="mat-error" *ngIf="!gameSelected && form.get('gameTitle').touched">
                        Game Title is required
                    </div>
                </div>

                <!-- Select Day -->
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Select Day</mat-label>
                    <mat-select formControlName="selectedDay">
                        <mat-option *ngFor="let day of conventionDays" [value]="day">
                            {{ day.date | date : 'fullDate' }}
                            ({{ day.startTime }} - {{ day.endTime }})
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            form.get('selectedDay').hasError('required') &&
                            form.get('selectedDay').touched
                        "
                    >
                        Day is required
                    </mat-error>
                </mat-form-field>
                <!-- Location (only if the user can manage) -->
                <mat-form-field appearance="outline" class="full-width" *ngIf="hasAbilityToManage">
                    <mat-label>Location</mat-label>
                    <input matInput formControlName="location" />
                    <mat-error
                        *ngIf="
                            form.get('location').hasError('required') &&
                            form.get('location').touched
                        "
                    >
                        Location is required
                    </mat-error>
                </mat-form-field>

                <!-- Select Duration -->
                <mat-form-field
                    appearance="outline"
                    class="full-width"
                    *ngIf="form.get('selectedDay').value"
                >
                    <mat-label>Select Duration</mat-label>
                    <mat-select formControlName="selectedDuration">
                        <mat-option [value]="30">30 Minutes</mat-option>
                        <mat-option [value]="60">1 Hour</mat-option>
                        <mat-option [value]="90">1.5 Hours</mat-option>
                        <mat-option [value]="120">2 Hours</mat-option>
                        <mat-option [value]="180">3 Hours</mat-option>
                        <mat-option [value]="240">4 Hours</mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            form.get('selectedDuration').hasError('required') &&
                            form.get('selectedDuration').touched
                        "
                    >
                        Duration is required
                    </mat-error>
                </mat-form-field>

                <!-- Select Time -->
                <mat-form-field
                    appearance="outline"
                    class="full-width"
                    *ngIf="form.get('selectedDuration').value"
                >
                    <mat-label>Select Time</mat-label>
                    <mat-select formControlName="selectedTime">
                        <mat-option *ngFor="let time of availableTimes" [value]="time">
                            {{ time }}
                        </mat-option>
                    </mat-select>
                    <mat-error
                        *ngIf="
                            form.get('selectedTime').hasError('required') &&
                            form.get('selectedTime').touched
                        "
                    >
                        Time is required
                    </mat-error>
                </mat-form-field>

                <!-- Max Players -->
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Max Players</mat-label>
                    <input matInput type="number" formControlName="maxPlayers" />
                    <mat-error
                        *ngIf="
                            form.get('maxPlayers').hasError('required') &&
                            form.get('maxPlayers').touched
                        "
                    >
                        Max Players is required
                    </mat-error>
                    <mat-error
                        *ngIf="
                            form.get('maxPlayers').hasError('min') && form.get('maxPlayers').touched
                        "
                    >
                        Minimum value is 1
                    </mat-error>
                </mat-form-field>

                <!-- Waitlist Capacity -->
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Waitlist Capacity</mat-label>
                    <input matInput type="number" formControlName="waitlistCapacity" />
                    <mat-error
                        *ngIf="
                            form.get('waitlistCapacity').hasError('min') &&
                            form.get('waitlistCapacity').touched
                        "
                    >
                        Minimum value is 0
                    </mat-error>
                </mat-form-field>

                <!-- Cost -->
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Cost</mat-label>
                    <input matInput formControlName="cost" />
                    <mat-error
                        *ngIf="form.get('cost').hasError('required') && form.get('cost').touched"
                    >
                        Cost is required
                    </mat-error>
                </mat-form-field>

                <!-- Password Protection -->
                <mat-checkbox formControlName="isPasswordProtected">
                    Password Protect Event
                </mat-checkbox>

                <!-- Password Input -->
                <mat-form-field
                    appearance="outline"
                    class="full-width"
                    *ngIf="form.get('isPasswordProtected').value"
                >
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" />
                    <mat-error
                        *ngIf="
                            form.get('password').hasError('required') &&
                            form.get('password').touched
                        "
                    >
                        Password is required
                    </mat-error>
                </mat-form-field>

                <!-- Notes -->
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Notes</mat-label>
                    <textarea matInput formControlName="notes" rows="3"></textarea>
                </mat-form-field>

                <mat-label>NOTE: Location will be set later by convention organizer</mat-label>

                <!-- Submit and Cancel Buttons -->
                <div class="button-group">
                    <button mat-raised-button color="primary" type="submit">
                        {{ isEditing ? 'Update' : 'Create' }}
                    </button>
                    <button mat-button mat-dialog-close>Cancel</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
