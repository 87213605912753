<div class="parent-container" *ngIf="(coreGameData$ | async); else isLoading">
    <div class="row m-0">
        <div class="col-12 w-100">
            <app-gh-variable-length-autocomplete-selector #variableLengthSelector [_limit]="12"
                [_itemsAreObjects]="true" [_options]="coreGameData.value" [_currentSelections]="collection"
                (onChangeEmitter)="onSelectionsChanged($event)"></app-gh-variable-length-autocomplete-selector>

        </div>
    </div>



    <!-- MOBILE -->
    <div class="row mx-0 mt-5" *ngIf="isMobileScreen">
        <div class="col-6 p-2" *ngFor="let item of collection">
            <!-- ?ghfjldsghfjdlksghjfdkslghfjub -->
            <!-- <img *ngIf="item.mainImageFiles" [src]="item.mainImageFiles[0]" alt="" matTooltip="{{item.title}}"
                matTooltipPosition="above"> -->
            <app-gh-expandable-image [_img]="item.mainImageFiles" [_title]="item.title" [_caption]=""
                matTooltip="{{item.title}}" matTooltipPosition="above"></app-gh-expandable-image>

        </div>
    </div>


    <!-- DESKTOP -->
    <div class="row mx-0 mt-5" *ngIf="!isMobileScreen">
        <div class="col-3 p-2" *ngFor="let item of collection">
            <!-- ?ghfjldsghfjdlksghjfdkslghfjub -->
            <!-- <img *ngIf="item.mainImageFiles" [src]="item.mainImageFiles[0]" alt="" matTooltip="{{item.title}}"
                matTooltipPosition="above"> -->
            <app-gh-expandable-image [_img]="item.mainImageFiles" [_title]="item.title" [_caption]=""
                matTooltip="{{item.title}}" matTooltipPosition="above"></app-gh-expandable-image>
        </div>
    </div>

</div>

<ng-template #isLoading>
    <div class="gh-loading-container mt-5">
        <app-gh-loading-spinner></app-gh-loading-spinner>
    </div>
</ng-template>