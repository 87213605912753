<div class="parent-container">
    <div class="row">
        <div class="col-12">
            <div class="players-needed-text-container">
                <mat-card-subtitle class="gh-lg-subtitle">Future Feature: Choose all that apply</mat-card-subtitle>
            </div>
        </div>
        <div class="col-12 text-center">
            <div class="players-needed-container">
                <app-players-needed-bar [_isLarge]="true" [_currentPlayerCount]="null" [_gameMin]="1" [_gameMax]="8"
                    [_communityMin]="1" [_accept1]="true" [_sourceOfInput]="'app-community-votes'" [_communityMax]="8"
                    [_customLowerBound]="null" [_customUpperBound]="null" [_desiredIndices]="[]" [_isInteractive]="true"
                    [_shouldAnimate]="false" (desiredIndicesChangedEmitter)="onIdealPlayersChanged($event)">
                </app-players-needed-bar>
            </div>
        </div>
    </div>
    <div style="display: block; width: 90%; margin: auto;">
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
            [plugins]="barChartPlugins" [type]="barChartType" [legend]="false">
        </canvas>
    </div>
</div>