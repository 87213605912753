import { Component, OnInit } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { BackendAPIConflictResolution } from 'aws-sdk/clients/amplifybackend';
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service';
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service';
import { BackendApiHavenTrackingService } from 'src/app/backend-api-services/backend-api-haven-tracking.service';
import { BackendAPIHavensService } from 'src/app/backend-api-services/backend-api-havens.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';

@Component({
  selector: 'app-temp-haven-components',
  templateUrl: './temp-haven-components.component.html',
  styleUrls: ['./temp-haven-components.component.scss']
})
export class TempHavenComponentsComponent implements OnInit {

  constructor(
    private backendApiContentService: BackendAPIContentService,
    private backendApiHavensService: BackendAPIHavensService,
    private backendApiGameTrackingService: BackendApiGameTrackingService,
    private backendAPIUsersService: BackendAPIUsersService
  ) { }

  havenTitleOptions = ['carl', 'bob']

  havenOptions = ['1234', 'xwkf']

  user
  async ngOnInit() {

    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })
  }

  state = 'Ohio' // just hard coding to this for now. if needed create n init thing or whatever

  gameOptions = ['s59q', 'r733', '209s', 'xijs', 'yyep', 'zfgn', 'czpy', 'g62o', 'r5d1', 'lccs', 'xwat', 'jlui', 'mjvs', 'lcwh', 'e5wx', 'tjqm', 's1gs', 'czsp', '9knn']
  gameToAdd

  typeOptions = ['havenDemo', 'havenLending', 'havenUsuallyInStock']

  onStateChanged(event) {
    this.state = event.value
  }

  async fetchLPbyCurrentHaven() {

    let carl = await this.backendApiContentService.listLPByHavenTitle(this.selectedHavenTitle.value, 49)
  }

  async fetchHavensByState() {
    const result = await this.backendApiHavensService.listHavensByState(this.state, 10)
  }

  selectedHavenId
  setHavenId(havenId) {
    this.selectedHavenId = havenId.value
  }

  selectedHavenTitle
  setHavenTitle(havenTitle) {
    this.selectedHavenTitle = havenTitle
  }

  type
  setType(type) {
    this.type = type.value
  }

  selectedGameId
  setGameId(gameId) {
    this.selectedGameId = gameId.value
  }




  //! TEMPLATE HERE
  //! TEMPLATE HERE
  //! TEMPLATE HERE
  // typeOptions = ['havenDemo', 'havenLending', 'havenUsuallyInStock']

  createHavenDemoItem() {

    let input = {
      gameId: this.selectedGameId,
      UID: this.selectedHavenId,
      type: this.type,
      userState: this.user.state,
      userZipCode: this.user.zipCode,
      title: 'WIP Game title',
      userTitle: this.user.title,
      mainImageFiles: this.user.mainImageFiles,
      specificLocation: 'downstairsWIPsdflkjs'
    }

    this.backendApiGameTrackingService.createHavenGameTrackingItem(input)
  }

}
