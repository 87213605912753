<mat-card *ngIf="!isMobileScreen" class="mat-elevation-z6">
    <ng-container *ngTemplateOutlet="tabs"></ng-container>
</mat-card>

<div *ngIf="isMobileScreen">
    <ng-container *ngTemplateOutlet="tabs"></ng-container>
</div>

<ng-template #tabs>
    <div class="row tab-container mb-3">

        <div class="col-12">

            <mat-tab-group mat-stretch-tabs class="gh-tab-group">

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon *ngIf="!isMobileScreen" class="tab-icon">accessibility_new</mat-icon>
                        <span>About Me</span>
                    </ng-template>

                    <app-profile-about-me [_inputData]="inputData"></app-profile-about-me>

                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon *ngIf="!isMobileScreen" class="tab-icon">image</mat-icon>
                        My Content
                    </ng-template>

                    <app-profile-content [_inputData]="inputData"></app-profile-content>

                </mat-tab>
                <!-- 
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon *ngIf="!isMobileScreen" class="tab-icon">bar_chart</mat-icon>
                        Suggestions
                    </ng-template>
                    <app-profile-data></app-profile-data>
                </mat-tab> -->

            </mat-tab-group>

        </div>

    </div>
</ng-template>