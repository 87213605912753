<div class="parent-container mt-2" *ngIf="doneLoading">

    <div class="row mx-0 mb-3">

        <div [ngSwitch]="type">

            <!-- * Incoming EVENT INVITES -->
            <div *ngSwitchCase="'invites'">
                <mat-card-title class="gh-sm-title">Incoming invites</mat-card-title>
                <div class="px-2 mt-2">
                    <app-invites-table [_dataSource]="incomingInvitesDataSource" [_displayedColumns]="displayedColumnsIncomingEventInvites" [_isIncoming]="true" [_user]="user" [_hasDeleteButtons]="hasDeleteButtons" [_hasConfirmationButtons]="hasConfirmationButtons"></app-invites-table>
                </div>
            </div>

            <!-- * Outgoing EVENT INVITES -->
            <div *ngSwitchCase="'invites'" class="mt-4">
                <mat-card-title class="gh-sm-title">Outgoing Invites</mat-card-title>
                <div class="px-2 mt-2">
                    <app-invites-table [_dataSource]="outgoingInvitesDataSource" [_displayedColumns]="displayedColumnsOutgoingEventInvites" [_isIncoming]="false" [_user]="user" [_hasDeleteButtons]="hasDeleteButtons" [_hasConfirmationButtons]="hasConfirmationButtons"></app-invites-table>
                </div>
            </div>
        </div>
    </div>