<section>

    <mat-card-content class="content mb-5 mr-5">

        <div class="row mb-4">
            <div class="col-1"></div>
            <div class="col-11">
                <mat-grid-list cols="{{hourTiles.length}}" rowHeight="40px">
                    <mat-grid-tile class="header-tile" *ngFor="let tile of hourTiles"
                        [style.background]="tile.backgroundColor">
                        {{tile.text}}
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>

        <div class="row">
            <div class="col-1">
                <mat-grid-list cols="1" rowHeight="50px">
                    <mat-grid-tile class="room-tile" *ngFor="let tile of roomTiles" [style.background]="tile.color">
                        {{tile.text}}
                    </mat-grid-tile>
                </mat-grid-list>
            </div>

            <div class="col-11 events">
                <mat-grid-list cols="{{hourTiles.length}}" rowHeight="50px">
                    <mat-grid-tile class="event-tile" *ngFor="let tile of eventTiles; let i = index"
                        [colspan]="tile.getColumns()" [rowspan]="tile.getRows()"
                        [style.background]="tile.getBackgroundColor()" [style.color]="tile.getFontColor()"
                        (click)="selectTile(tile, i)" (mouseenter)="mouseEnter(i) " (mouseleave)="mouseLeave(i)"
                        [ngClass]="{ isHovered: tile.getIsHovered() }">
                        <!-- <mat-grid-tile-header>details</mat-grid-tile-header> -->
                        <!-- <span class="mr-3">{{i}}</span>
                                    <span class="mr-3" style="color: red" *ngIf="rowEnds.includes(i)">RE</span> -->

                        <!-- <span>{{tile.getText()}}</span> -->

                        <span>
                            <p class="pt-3" matTooltip="{{tile.getText().length > 5 ? tile.getText() : ''}}"
                                matTooltipPosition="above">{{truncate(tile.getText(),
                                5)}}
                            </p>
                        </span>

                        <div *ngIf="currentUserIsAdmin">
                            <span class="button-container">
                                <button mat-button class="add-event-details-button" *ngIf="tile.getHasSetEventButton()"
                                    (click)="openDialog()">
                                    <mat-icon>check</mat-icon>
                                </button>
                                <button mat-button class="cancel-button" *ngIf="tile.getHasCancelButton()"
                                    (click)="cancelSelection()">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                                <button mat-button class="edit-button" *ngIf="tile.getHasEditButton()"
                                    (click)="editEvent(tile, i)">
                                    <mat-icon>edit</mat-icon>
                                </button>

                            </span>

                            <span>
                                <button mat-button class="minus-button" *ngIf="tile.getHasMinusButton()"
                                    (click)="removeColumnFromSelected()">
                                    <mat-icon>remove</mat-icon>
                                </button>
                            </span>
                        </div>

                        <div *ngIf="!currentUserIsAdmin">
                            <span class="button-container ml-4">
                                <!-- change below to hasOpenSeats rather than hasEditButton -->
                                <button class="gh-button gh-button-primary" *ngIf="tile.getHasEditButton()"
                                    (click)="join()" matTooltip="3 open seats" matTooltipPosition="above">
                                    JOIN (3)
                                </button>
                            </span>
                        </div>

                        <!-- show regardless of if user is admin or not -->
                        <div *ngIf="showInfoButton(tile)">
                            <span class="button-container ml-2">
                                <!-- should show only if has info (ex. game, reason for custom, etc.) -->
                                <mat-icon matTooltip="View Details" matTooltipPosition="above" (click)="showInfo(tile)"
                                    class="gh-icon gh-icon-clickable info-button-icon">info</mat-icon>
                            </span>
                        </div>


                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
    </mat-card-content>
</section>