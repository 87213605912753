import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core'
import { TimingHelperService } from '../../../services/helpers/timing-helper.service'

@Component({
  selector: 'app-info-message[_sourceOfInput]',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss']
})
export class InfoMessageComponent implements OnInit {

  @Input() _sourceOfInput: string

  message
  subMessage

  bulletsHeader
  bullets = []

  image

  data
  @Input() set _data(data) {
    this.data = data
  }


  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
  }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  @Output() closeEmitter = new EventEmitter()

  constructor(
    private timingHelperService: TimingHelperService
  ) { }

  ngOnInit() {
    let dataPassed = this.inputData
    this.message = dataPassed.message
    this.subMessage = dataPassed.subMessage
    this.bulletsHeader = dataPassed.bulletsHeader
    let bullets = dataPassed.bullets
    this.animateBulletsEntrance(bullets)

    if (dataPassed.image) {
      this.image = window.location.origin + dataPassed.image
    }
  }

  async animateBulletsEntrance(bullets) {
    for (let bullet of bullets) {
      await this.timingHelperService.delay(500).then(() => {
        this.bullets.push(bullet)
      })
    }
  }

  submit() {
    this.closeEmitter.emit('confirm')
  }

}
