import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Observable } from 'rxjs'
import { UntypedFormControl } from '@angular/forms'
import { startWith, map } from 'rxjs/operators'
import { ScoreGameModalComponent } from './components/score-game-modal/score-game-modal.component'
import { GhDialogWrapperComponent } from '../gh-dialog-wrapper/gh-dialog-wrapper.component'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { StringHelper } from '../../../../../../../common/helpers/string-helper'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-tournament-builder',
    templateUrl: './tournament-builder.component.html',
    styleUrls: ['./tournament-builder.component.scss'],
})
export class TournamentBuilderComponent implements OnInit {
    @Output() blurContentEmitter = new EventEmitter()

    winner = 'Winners name here..'

    isLoading

    availableTeams = []

    rounds = []

    filteredOptions: Observable<Array<any>>
    filterFormControl: UntypedFormControl

    roundWidthConstant = 1600
    pairHeight = 120 // must match .pair => .height in SCSS file

    submitStarterTeamsTooltip

    isRandomSetup = false
    setupComplete = false

    baseMargin = this.pairHeight / 10 // base margin is the margin value for column 0 divided by some given multiplier
    totalNonEmptySpace
    totalEmptySpace
    totalSpace

    @Input() set _inputData(setupData: any) {
        if (setupData) {
            this.availableTeams = setupData.availableTeams
            this.isRandomSetup = setupData.isRandomSetup

            this.totalNonEmptySpace = (this.availableTeams.length / 2) * this.pairHeight
            this.totalEmptySpace = (this.availableTeams.length / 2) * this.baseMargin
            this.totalSpace = this.totalNonEmptySpace + this.totalEmptySpace

            this.initTree()
        }
    }

    checkStarterTeams() {
        var teamNotSet = false
        for (var i = 0; i < this.rounds.length; i++) {
            if (this.rounds[0][i].team1 == '?' || this.rounds[0][i].team2 == '?') {
                teamNotSet = true
            }
        }
        teamNotSet
            ? (this.submitStarterTeamsTooltip = 'Fill in all the starter teams please')
            : 'submit!'
        return teamNotSet
    }

    submitStarterTeams() {
        this.setupComplete = true

        for (let game of this.rounds[0]) {
            game.isEnabledForScoring = true
        }
    }

    clearSearch(event) {
        event.srcElement.value = ''
        this.filterFormControl.patchValue('')
    }

    private filter(value: string): any[] {
        const filterValue = typeof value == 'string' ? value.toLowerCase() : ''
        return this.availableTeams.sort().filter((team) => team.toLowerCase().includes(filterValue)) // sort() sorts alphabetically
    }

    constructor(private dialog: MatDialog, private screenSizeService: ScreenSizeService) { }

    getRandomIndex() {
        let min = Math.ceil(0)
        let max = Math.floor(this.availableTeams.length - 1)
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    getIsDisabled(game, roundIndex): boolean {
        return !game.isEnabledForScoring && roundIndex != 0
    }

    initTree() {
        if (!this.isRandomSetup) {
            this.filterFormControl = new UntypedFormControl()
            this.filteredOptions = new Observable()
            this.filteredOptions = this.filterFormControl.valueChanges.pipe(
                startWith(''),
                map((value) => this.filter(value)),
            )
        } else {
            this.setupComplete = true
        }

        let availableTeamsCount = this.availableTeams.length

        ////////////////////////////////////////////////////////////////////
        // let number of rounds = n, then:
        //   availableTeamsCount = 2^(n-1)
        //
        //   rearranging gives:
        //       n = log(availableTeamsCount) / log(2) + 1
        ////////////////////////////////////////////////////////////////////

        let numberOfRounds = Math.log(availableTeamsCount / 2) / Math.log(2) + 1

        let gamesPerRound = availableTeamsCount / 2
        for (var i = 0; i < numberOfRounds; i++) {
            var round = []
            for (var j = 0; j < gamesPerRound; j++) {
                var game
                if (i == 0 && this.isRandomSetup) {
                    let t1Index = this.getRandomIndex()
                    let t1 = this.availableTeams[t1Index]
                    this.availableTeams.splice(t1Index, 1)
                    let t1Members = StringHelper.createCommaSeparatedString(t1.members, false)

                    let t2Index = this.getRandomIndex()
                    let t2 = this.availableTeams[t2Index]
                    this.availableTeams.splice(t2Index, 1)
                    let t2Members = StringHelper.createCommaSeparatedString(t2.members, false)

                    game = {
                        team1: t1,
                        team1Members: t1Members,
                        team2: t2,
                        team2Members: t2Members,
                        team1Score: '?',
                        team2Score: '?',
                        isEnabledForScoring: true,
                    }
                } else if (i == 0 && !this.isRandomSetup) {
                    game = {
                        team1: '?',
                        team2: '?',
                        team1Score: '?',
                        team2Score: '?',
                        isEnabledForScoring: false,
                    }
                } else {
                    game = {
                        team1: '?',
                        team2: '?',
                        team1Score: '?',
                        team2Score: '?',
                        isEnabledForScoring: false,
                    }
                }
                round.push(game)
            }
            this.rounds.push(round)
            gamesPerRound = gamesPerRound / 2
        }

        this.setParentNodes()
    }

    isMobileScreen = false

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    setParentNodes() {
        for (var roundIndex = 0; roundIndex < this.rounds.length; roundIndex++) {
            if (roundIndex == this.rounds.length - 1) {
                return
            }

            var parentIndex = -1
            for (var gameIndex = 0; gameIndex < this.rounds[roundIndex].length; gameIndex++) {
                var placementInParent = 'bottom'
                if (gameIndex % 2 == 0) {
                    parentIndex++
                    placementInParent = 'top'
                }
                this.rounds[roundIndex][gameIndex].parentGameIndex = parentIndex
                this.rounds[roundIndex][gameIndex].placementInParent = placementInParent
            }
        }
    }

    // MOVE COMMENTS TO README.TXT
    //   ////////////////////////////////////////////////////////////////////
    //   //  Counts not needed for implementation, but for the purpose of documentation:
    //   //        innerSpaceCount = roundLength-1
    //   //        outSpaceCount = 2 (constant, since its always the top and bottom spacers (and with this approach, since using marginTop, bottom spacer wouldnt even matter) )
    //   ////////////////////////////////////////////////////////////////////

    getMarginTop(roundIndex, gameIndex) {
        let roundLength = this.rounds[roundIndex].length
        let roundEmptySpace = this.totalSpace - roundLength * this.pairHeight
        let innerSpaceMargin = roundEmptySpace / roundLength
        let outerSpaceMargin = innerSpaceMargin / 2

        var result = 0

        if (gameIndex == 0) {
            result = outerSpaceMargin
        } else {
            result = innerSpaceMargin
        }
        return result.toString().concat('px')
    }

    getVerticalLineTop(roundIndex) {
        let height: any = this.getVerticalLineHeight(roundIndex, true)
        let gameEmptySpace = height - this.pairHeight
        let top = (-1 * gameEmptySpace) / 2
        return top.toString().concat('px')
    }

    getVerticalLineHeight(roundIndex, returnAsNumber: boolean) {
        let multiplier = Math.pow(2, roundIndex - 1)

        if (returnAsNumber) {
            return multiplier * this.pairHeight + multiplier * this.baseMargin
        } else {
            return (multiplier * this.pairHeight + multiplier * this.baseMargin)
                .toString()
                .concat('px')
        }
    }

    onClick(game, roundIndex) {
        if (this.setupComplete) {
            this.scoreGame(game, roundIndex)
        }
    }

    selectTeam(event, roundIndex, gameIndex, team1Orteam2) {
        if (team1Orteam2 == 1) {
            this.rounds[roundIndex][gameIndex].team1 = event.option.value
        } else {
            this.rounds[roundIndex][gameIndex].team2 = event.option.value
        }

        var indexToRemove
        for (var i = 0; i < this.availableTeams.length; i++) {
            if (this.availableTeams[i] == event.option.value) {
                indexToRemove = i
            }
        }
        this.availableTeams.splice(indexToRemove, 1)
    }

    restoreTeamToOptions(roundIndex, gameIndex, teamName) {
        this.availableTeams.push(teamName)
        this.rounds[roundIndex][gameIndex].team1 = '?'
    }

    scoreGame(game, roundIndex) {
        if (!game.isEnabledForScoring) {
            return
        }

        this.blurContentEmitter.emit(true)
        const dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Who Won?',
                component: ScoreGameModalComponent,
                inputData: game,
                hasSubmitButton: true,
                hasCancelButton: true,
            },
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.blurContentEmitter.emit(false)
                if (!data) {
                    return
                }

                let winner = data.team1Score > data.team2Score ? game.team1 : game.team2
                game.winner = data.team1Score > data.team2Score ? 1 : 2
                game.loser = data.team1Score > data.team2Score ? 2 : 1
                game.team1Score = data.team1Score
                game.team2Score = data.team2Score

                if (game.placementInParent == 'top') {
                    this.rounds[roundIndex + 1][game.parentGameIndex].team1 = winner
                } else {
                    this.rounds[roundIndex + 1][game.parentGameIndex].team2 = winner
                }

                if (
                    this.rounds[roundIndex + 1][game.parentGameIndex].team1 != '?' &&
                    this.rounds[roundIndex + 1][game.parentGameIndex].team2 != '?'
                ) {
                    this.rounds[roundIndex + 1][game.parentGameIndex].isEnabledForScoring = true
                }
            })
    }
}
