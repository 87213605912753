<div class="row mx-0">
    <div class="col-12">
        <app-autocomplete-chiplist *ngIf="(coreUsers$ | async)" [_items]="coreUsers.value" [_label]="'To:'"
            [_selectedItems]="selectedUser" [_allowDuplicates]="false" [_allowCustomInput]="false"
            [_itemsAreObjects]="true" [_singleInput]="true" [_sourceOfInput]="'app-messaging'"
            (selectionChanged)="selectUser($event)" [_useFuzzySearch]="true">

            <!--! WHY DOES THE ABOVE TRIGGER ONLY SOMETIEMS -->
            <!-- //!  //! THIS IS WRONG? -->
        </app-autocomplete-chiplist>
    </div>
</div>


<div class="row mx-0 mt-4">
    <div class="col-12 w-100">
        <div class="gh-textarea-container">
            <textarea [(ngModel)]="sendMessageMessage" matInput class="gh-bordered-textarea m-2" autocomplete="off"
                name="" id="" cols="30" rows="5"></textarea>
        </div>
    </div>
</div>

<div *ngIf="previousMessageExists" class="row mx-0 mt-4">
    <div class="col-12 w-100">
        <div class="gh-textarea-container">
            <h5>Previous Message: {{previousMessage}}</h5>
        </div>
    </div>
</div>