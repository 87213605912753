import { Injectable } from '@angular/core'
import { Auth } from 'aws-amplify'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { environment } from 'src/environments/environment'
import gql from 'graphql-tag'
import { iUpdateSingleAttributeForGameInput } from '../../../../common/interfaces/GameInterfaces'
import { ContentService } from '../dashboard/dashboard-shared/services/content/content.service'
import { iAddToGamesWeLoveInput, iAddToHavensWeLoveInput, iDeleteGamesWeLoveInput, iDeleteHavensWeLoveInput } from '../../../../common/interfaces/GroupTrackingInterfaces'
import { iDeleteHavenTrackingItemInput } from '../../../../common/interfaces/HavenTrackingInterfaces'


@Injectable({
  providedIn: 'root'
})

export class BackendApiUserTrackingService {

  constructor() { }

  private appSyncClient = new AWSAppSyncClient({
    url: environment.apiUrl,
    region: 'us-east-1',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        return token
      }
    },
    disableOffline: true,
  })



  async listUserTrackingItemsByType(id: string, type: string, limit: number, nextToken?) {

    type = '#' + type
    const response = await this.appSyncClient.query<any>({
      query: gql`
        query listUserTrackingItemsByType($id: ID!, $type: String, $limit: Int, $nextToken: String) {
          listUserTrackingItemsByType(id: $id, type: $type, limit: $limit, nextToken: $nextToken) {
            nextToken
            userTrackingItems{
              pk
              sk
              data
              }      
            }
          }
        `,
      variables: { id, type, limit, nextToken },
      fetchPolicy: 'network-only'
    })

    return response.data.listUserTrackingItemsByType
  }



  async deleteUserTrackingItem(pk: string, sk: string) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation deleteUserTrackingItem($pk: String!, $sk: String) {
            deleteUserTrackingItem (pk: $pk, sk: $sk) {
              username
            }
          }
        `,
      variables: { pk, sk }
    })
    return response.data.deleteUserTrackingItem
  }







  async createUserTrackingItem(input) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation createUserTrackingItem($input: IGQL_CreateUserTrackingItem!) {
            createUserTrackingItem (input: $input) {
              mainImageFiles
            }
          }
        `,
      variables: { input }
    })
    return response.data.createUserTrackingItem
  }





}
