<div class="notification-list" #notificationList>
    <div *ngFor="let notification of notifications">
        <div
            class="notification"
            *ngIf="notification.notificationType"
            [ngClass]="notification.notificationType"
        >
            <!-- Section for 'loggedPlay' notificationType -->
            <div *ngIf="notification.notificationType == 'loggedPlay'" class="loggedPlay-content">
                <div
                    class="notification-image user-image"
                    *ngIf="notification.userImage && notification.userImage.length > 0"
                >
                    <img
                        [src]="notification.userImage[0]"
                        alt="User Image"
                        (click)="seeUserDetails(notification)"
                        class="link-icon"
                    />
                </div>
                <div
                    class="link-icon notification-user-title"
                    (click)="seeUserDetails(notification)"
                >
                    {{ notification.addedByTitle }}
                </div>
                <div class="notification-additional-info">Played</div>

                <div
                    class="game-image notification-image"
                    *ngIf="
                        notification.gameMainImageFiles &&
                        notification.gameMainImageFiles.length > 0
                    "
                >
                    <img
                        [src]="notification.gameMainImageFiles[0]"
                        alt="Game Image"
                        (click)="seeGameDetails(notification)"
                        class="link-icon"
                    />
                </div>

                <div class="notification-text">
                    <div class="game-title link-icon" (click)="seeGameDetails(notification)">
                        {{ notification.gameTitle }}
                    </div>
                    <div class="game-details">{{ notification.date | date : 'MM/dd/yyyy' }}</div>
                </div>
            </div>

            <!-- Section for 'Announcement' notificationType -->
            <div
                *ngIf="notification.notificationType == 'Announcement'"
                class="loggedPlay-content"
                (click)="seeNotificationDetails(notification)"
            >
                <img
                    id="logo"
                    src="../../../../../../assets/images/coolStuff/logo-redo-no-flag.png"
                    alt=""
                />

                <div class="mr-3 ml-3 p-3">
                    {{ notification.messageIntro }}
                </div>
                <div class="p-3" style="font-weight: normal">
                    {{ notification.message }}
                </div>
            </div>

            <!-- Section for 'ad' notificationType -->
            <div *ngIf="notification.notificationType == 'ad'">
                <div>
                    {{ notification.adIntro }}
                </div>
                <div>
                    {{ notification.adMessage }}
                </div>
            </div>

            <!-- Section for 'event' notificationType -->
            <div
                *ngIf="notification.notificationType == 'event'"
                class="event-content link-icon"
                (click)="seeEventDetails(notification)"
            >
                <div class="event-image notification-image" *ngIf="notification.gameMainImageFiles">
                    <img [src]="notification.gameMainImageFiles[0]" alt="Event Image" />
                </div>
                <div class="notification-additional-info">
                    {{ notification.havenTitle }}
                </div>
                <div class="notification-user-title">
                    {{ notification.eventTitle }}
                </div>
                <div class="notification-text">
                    <div class="event-start-date">
                        {{ notification.eventStart | date : 'EEE, MMM d, h:mma' }} -
                        {{ notification.eventEnd | date : 'h:mma' }}
                    </div>
                </div>
            </div>
            <div
                *ngIf="notification.notificationType == 'scheduledPlay'"
                class="scheduledPlay-content"
                style="
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    flex-wrap: nowrap;
                    white-space: nowrap;
                "
            >
                <span class="notification-additional-info">
                    <span (click)="seeUserDetails(notification)" class="link-icon">
                        {{ notification.addedByTitle }}
                    </span>
                </span>
                <span>scheduled</span>
                <span class="notification-user-title">
                    <span (click)="seeGameDetails(notification)" class="link-icon">
                        {{ notification.gameTitle }}
                    </span>
                </span>
                <span>on</span>
                <span class="notification-text">
                    <span class="event-start-date">
                        {{ notification.date | date : 'EEE, MMM d, h:mma' }}
                    </span>
                </span>
                <span>at</span>
                <span class="notification-user-title">
                    <span (click)="openConventionDetails(notification)" class="link-icon">
                        {{ notification.conventionTitle }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="mt-4">
    <button
        mat-button
        class="m-2 gh-button gh-button-primary link-icon"
        (click)="clearAllNotifications(3)"
    >
        Clear 3 Old Notifications
    </button>
    <button
        mat-button
        class="m-2 gh-button gh-button-primary link-icon"
        (click)="clearAllNotifications(20)"
    >
        Clear 20 Old Notifications
    </button>
</div>
