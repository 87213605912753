import { Directive, HostListener } from '@angular/core'
import { ClickTrackService } from '../services/click-track.service'

@Directive({
  selector: '[appClickTrack]'
})
export class ClickTrackDirective {

  clickedItem

  @HostListener('click', ['event'])
  onClick() {
    let eventAsAny: any = event
    eventAsAny.stopImmediatePropagation()
    this.clickTrackService.updateClicks(eventAsAny.srcElement.getAttribute('data-clicktrack'))
  }

  constructor(private clickTrackService: ClickTrackService) { }

}

