import { Injectable } from '@angular/core'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { Auth } from 'aws-amplify'
import { environment } from 'src/environments/environment'
import { ArrayHelperService } from '../dashboard/dashboard-shared/services/helpers/array-helper.service'
import gql from 'graphql-tag'
import { iDeleteS3Object, iFetchS3Object } from '../../../../common/interfaces/ContentInterfaces'

@Injectable({ providedIn: 'root' })

export class BackendApiS3Service {

  constructor(
    private arrayHelperService: ArrayHelperService
  ) { }

  private appSyncClient = new AWSAppSyncClient({
    url: environment.apiUrl,
    region: 'us-east-1',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        return token
      }
    },
    disableOffline: true,
  })









  async deleteS3Object(input: iDeleteS3Object) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
        mutation deleteS3Object($input: IGQL_DeleteS3Object!) {
          deleteS3Object (input: $input) {
            bucketName
          }
        }
      `,
      variables: { input }
    })

    return response.data.deleteS3Object
  }









  async fetchS3Object(input: iFetchS3Object) {

    const response = await this.appSyncClient.query<any>({
      query: gql`
        query fetchS3Object($input: IGQL_FetchS3Object!) {
          fetchS3Object (input: $input) {
            bucketName
            body
          }
        }
      `,
      variables: { input }
    })

    return response.data.fetchS3Object
  }

}
