import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class NumberHelperService {

  constructor() { }

  // getRandomInt(min, max) {
  //   min = Math.ceil(min)
  //   max = Math.floor(max)
  //   return Math.floor(Math.random() * (max - min + 1)) + min
  // }

  getRandomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}
