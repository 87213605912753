import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import heic2any from 'heic2any';

@Directive({
  selector: '[appHeicToJpeg]'
})
export class HeicToJpegDirective {
  @Input('appHeicToJpeg') set heicFile(file: File | null) {
    if (file) {
      this.convertHeicToJpeg(file);
    }
  }

  convertedImageUrl

  getThing() {
    return this.convertedImageUrl
  }

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  private async convertHeicToJpeg(heicFile: File) {
    try {
      const result = await heic2any({ blob: heicFile, toType: 'image/jpeg', quality: 0.8 });
      const jpegBlob = Array.isArray(result) ? result[0] : result;
      this.convertedImageUrl = URL.createObjectURL(jpegBlob);
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.convertedImageUrl);
    }
    catch (error) {
      console.error('Error converting HEIC to JPEG:', error);
    }
  }
} 