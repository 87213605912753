import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth/auth.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { TimingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/timing-helper.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { ScreenSizeService } from '../../services/screen-size.service'
import { GhButtonColor } from 'src/app/dashboard/dashboard-shared/enums/gh-button-enums'

@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-log-in[_sourceOfInput]',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit {

  emailFromForgotPassword

  hidePassword = false

  @ViewChild('tabGroup', { static: false }) tabGroup

  @Input() _sourceOfInput: string

  passwordFieldIsFocused = false
  resetCodeIsSent = false

  currentTab = 0
  submitIsClicked = false

  loginForm: UntypedFormGroup
  forgotPasswordForm: UntypedFormGroup

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      if (this.currentTab == 0) {
        this.onSubmit()
      }
      else {
        this.forgotPassword()
      }
    }
  }

  isLoading = false
  constructor(private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private timingHelperService: TimingHelperService,
    private backendAPIUsersService: BackendAPIUsersService,
    private screenSizeService: ScreenSizeService
  ) {
  }

  // this needs to be defaulted to false so HTML doesn't get confused, but below then need to make sure to use authDidFail subscription rather than this.showFailedAuthMessage or else it will navigate right away!
  // also need to check if submit is clicked in HTML so not to show error message right away, which will be set in onSubmit
  showFailedAuthMessage = false

  isMobileScreen = false

  async ngOnInit() {

    this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobileScreen: boolean) => {
      this.isMobileScreen = isMobileScreen
    })

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })

    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required]
    })

    this.authService.authIsLoading.subscribe(
      (isLoading: boolean) => this.isLoading = isLoading
    )

    await this.authService.shouldResetLoginForm$.pipe(untilDestroyed(this)).subscribe(async shouldResetLoginForm => {
      if (shouldResetLoginForm) {
        this.showFailedAuthMessage = false
        this.submitIsClicked = false
        this.loginForm.get('username').patchValue(this.emailFromForgotPassword)
        this.loginForm.get('password').reset()
        this.tabGroup.selectedIndex = 0
      }
    })

    await this.authService.authDidFail$.pipe(untilDestroyed(this)).subscribe(async authDidFail => {
      this.showFailedAuthMessage = authDidFail
    })


  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword
  }

  async onSubmit() {

    if (this.loginForm.valid) {
      try {
        await this.authService.signIn(this.loginForm.get('username').value, this.loginForm.get('password').value)
      } catch (error) {
        console.log('There be an err')
      }
    }

    // delay prevent UI from showing error message as the route is navigating (avoids false negative)
    this.timingHelperService.delay(1500).then(
      () => {
        this.submitIsClicked = true
      }
    )

  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.onSubmit()
    }
  }

  forgotPassword() {
    this.submitIsClicked = true
    if (this.forgotPasswordForm.valid) {
      this.emailFromForgotPassword = this.forgotPasswordForm.get('username').value
      this.resetCodeIsSent = true
      this.authService.forgotPassword(this.forgotPasswordForm.get('username').value)
    }

  }

  tabChanged(event) {
    this.currentTab = event.index
  }

  onPasswordFocus() {
    this.passwordFieldIsFocused = true
  }

  onPasswordBlur() {
    this.passwordFieldIsFocused = false
  }

}
