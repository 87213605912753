<div class="row parent-container">

    <div class="col-12">
        Event title is: {{inputData.text}}
    </div>

    <div class="col-12">
        might need to pass event id with tile info to fetch more data???
    </div>

    <div class="col-12">
        info about event goes here, might be players if event type is game, otherwise, other stuff
    </div>

</div>