import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class TaVideosContentService {

  gameId
  gameTitle

  getGameId() {
    return this.gameId
  }

  getGameTitle() {
    return this.gameTitle
  }

  private videos = new BehaviorSubject(null)
  videos$ = this.videos.asObservable()

  constructor() { }

  updateVideos(videos) {

    // alert('updating videos ')
    // <!-- wokring here afdlskjadslkfjfdsa -->
    // this.videos.next(null)

    this.videos.next(videos)
  }


  updateImages(images) {
    this.videos.next(images)
  }

}
