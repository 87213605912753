import { Component, OnInit, Inject, NgZone, ViewChild, Input, ViewChildren, QueryList } from '@angular/core'
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { CdkTextareaAutosize } from '@angular/cdk/text-field'
import { take } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'


@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-story-builder-add-content-modal[_sourceOfInput]',
  templateUrl: './story-builder-add-content-modal.component.html',
  styleUrls: ['./story-builder-add-content-modal.component.scss']
})
export class StoryBuilderAddContentModalComponent implements OnInit {

  selectedImage

  @ViewChildren("fileUploader") fileUploaders: QueryList<any>

  @Input() _sourceOfInput: string

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
    alert('!!!')
  }

  layoutId = 1

  contentForm: UntypedFormGroup
  items: UntypedFormArray

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<StoryBuilderAddContentModalComponent>,
    private _ngZone: NgZone,
    private formBuilder: UntypedFormBuilder) { }

  @ViewChild('autosize') autosize: CdkTextareaAutosize

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.

    this._ngZone.onStable.pipe(take(1)).pipe(untilDestroyed(this))
      .subscribe(() => this.autosize.resizeToFitContent(true))
  }


  ngOnInit() {
    this.contentForm = this.formBuilder.group({
      items: this.formBuilder.array([this.createItem()])
    })
  }

  ngAfterViewInit() {

  }

  createItem(): UntypedFormGroup {
    return this.formBuilder.group({
      contentType: ['', Validators.required],
      content: ['', Validators.required]
    })
  }


  addItem(): void {
    this.items = this.contentForm.get('items') as UntypedFormArray
    this.items.push(this.createItem())
  }


  selectLayout(event) {
    this.layoutId = +event.value

    this.contentForm = this.formBuilder.group({
      items: this.formBuilder.array([this.createItem()])
    })

    for (var i = 0; i < this.layoutId - 1; i++) {
      this.addItem()
    }

  }

  getControls() {
    return (this.contentForm.get('items') as UntypedFormArray).controls
  }

  setText(event, index) {
    let controls = this.getControls()
    controls[index].get('contentType').setValue('text')
    controls[index].get('content').setValue(event.target.value)
  }

  setImage(event, index) {
    let controls = this.getControls()
    controls[index].get('contentType').setValue('image')
    controls[index].get('content').setValue(event)
    controls[index].get('content').updateValueAndValidity()
  }

  setVideo(index) {
    let controls = this.getControls()
    controls[index].get('contentType').setValue('video')
    controls[index].get('content').setValue('some video...')
  }


  submit() {
    if (this.contentForm.valid) {
      let data = {
        layoutId: this.layoutId,
        content: this.contentForm.value.items
      }
      this.dialogRef.close(data)
    }
  }

  cancel() {
    this.dialog.closeAll()
  }

}
