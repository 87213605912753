<div class="tabs-container" [ngClass]="{ isMobileScreen: isMobileScreen }">
    <mat-tab-group #tabGroup mat-stretch-tabs class="gh-tab-group" (selectedTabChange)="tabChanged($event)">

        <mat-tab>
            <ng-template mat-tab-label>
                Sign In
            </ng-template>

            <div class="login-error-container text-center" *ngIf="showFailedAuthMessage && submitIsClicked">
                <mat-card-subtitle class="gh-md-subtitle">
                    Incorrect Username or Password. Please Try Again.
                </mat-card-subtitle>
            </div>

            <form [formGroup]="loginForm" class="login-form" (keydown)="keyDownFunction($event)" style="overflow: hidden;">
                <div class="inner-flex-container" style="overflow: hidden;">
                    <div class="row" style="overflow: hidden;">

                        <div class="col-9">


                            <mat-form-field class="gh-form-field">
                                <mat-label>Email:</mat-label>
                                <input autocomplete="username" matInput type="email" formControlName="username">
                            </mat-form-field>

                            <mat-error class="gh-mat-error-simulator" *ngIf="submitIsClicked && loginForm.get('username').errors?.required">
                                Required</mat-error>
                        </div>

                        <div class="col-9">

                            <!-- <h1>{{hidePassword}}</h1> -->
                            <mat-form-field class="gh-form-field">
                                <mat-label>Password:</mat-label>

                                <!-- HIDE PASSWORD -->
                                <input autocomplete="current-password" matInput type="password" formControlName="password" (focus)="onPasswordFocus()" (blur)="onPasswordBlur()" *ngIf="hidePassword; else showPassword">

                                <!-- SHOW PASSWORD -->
                                <ng-template #showPassword>
                                    <input autocomplete="text" matInput type="password" formControlName="password" (focus)="onPasswordFocus()" (blur)="onPasswordBlur()">
                                </ng-template>

                            </mat-form-field>
                            <mat-error class="gh-mat-error-simulator" *ngIf="submitIsClicked && loginForm.get('password').errors?.required">
                                Required</mat-error>
                        </div>

                        <!-- <div class="col-1" matTooltip="Toggle Show Password" matTooltipPosition="above">
              <mat-icon *ngIf="hidePassword" (click)="togglePasswordVisibility()"
                class="gh-icon gh-icon-clickable gh-icon-warning">
                visibility_off</mat-icon>
              <mat-icon *ngIf="!hidePassword" (click)="togglePasswordVisibility()"
                class="gh-icon gh-icon-clickable gh-icon-success">
                visibility</mat-icon>
            </div> -->

                    </div>
                </div>
            </form>

        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                Forgot Password
            </ng-template>

            <div class="forgot-password-reset-message-container text-center" *ngIf="resetCodeIsSent">
                <mat-card-subtitle class="gh-md-subtitle">
                    <mat-card-subtitle class="gh-md-subtitle">
                        Please check your email for a password reset verification code.
                    </mat-card-subtitle>
                    <br>
                    <mat-card-subtitle class="gh-md-subtitle">
                        If you don't see the email, please check your spam folder.
                    </mat-card-subtitle>
                </mat-card-subtitle>
            </div>

            <form [formGroup]="forgotPasswordForm" class="forgot-password-form">
                <div class="inner-flex-container">
                    <div class="row">

                        <div class="col-12">
                            <mat-form-field class="gh-form-field">
                                <mat-label>Email:</mat-label>



                                <input autocomplete="username" matInput type="email" formControlName="username">

                            </mat-form-field>
                            <mat-error class="gh-mat-error-simulator" *ngIf="submitIsClicked && forgotPasswordForm.get('username').errors?.required">
                                Required</mat-error>
                        </div>

                    </div>
                </div>

            </form>

        </mat-tab>

    </mat-tab-group>
</div>


<div class="peeking-meeple-container">
    <img src="../../../../assets/images/coolStuff/peeking-meeple.png" alt="" *ngIf="!passwordFieldIsFocused">
    <img src="../../../../assets/images/coolStuff/non-peeking-meeple.png" alt="" *ngIf="passwordFieldIsFocused">
</div>