<!-- ! multi: how to display image in checked results??? -->

<div *ngIf="!isTiered; else tieredSelect">
    <ng-container *ngTemplateOutlet="basicSelect"></ng-container>
</div>

<ng-template #tieredSelect>
    <ng-container *ngTemplateOutlet="tieredSelectTemplate"></ng-container>
</ng-template>

<ng-template #basicSelect>
    <mat-form-field class="gh-form-field">
        <mat-label>{{label}}</mat-label>
        <mat-select (selectionChange)="selectItems($event)" multiple="isMultiSelect">
            <mat-option *ngFor="let tier0Item of tier0Items" [value]="tier0Item">
                <!--! height issue here with label not aligned with trigger arrow -->
                <span class="inline-span ml-2" *ngIf="displayThumbImg">
                    <div class="row">
                        <div class="col-12 text-center">
                            <img class="thumb-img" src="{{tier0Item.thumbImgPath}}" alt="">
                        </div>
                    </div>
                </span>
                <span class="inline-span ml-2">
                    <div class="row">
                        <div class="col-12 w-100 text-center">
                            <div *ngIf="itemsAreObjects">{{tier0Item.title}}</div>
                            <div *ngIf="!itemsAreObjects">{{tier0Item}}</div>
                            <div class=""></div>
                        </div>
                    </div>
                </span>
            </mat-option>
        </mat-select>
    </mat-form-field>

</ng-template>


<ng-template #tieredSelectTemplate>
    <mat-form-field class="gh-form-field">
        <mat-label>{{label}}</mat-label>
        <mat-select (selectionChange)="selectItems($event)" multiple class="gh-mat-select-full-height">
            <mat-optgroup *ngFor="let tier0Item of tier0Items" [label]="tier0Item.title">
                <!--! height issue here with label not aligned with trigger arrow -->
                <mat-option class="mat-option-indented" *ngFor="let tier1Item of tier0Item.tier1Items" [value]="tier1Item">
                    <span class="inline-span ml-2" *ngIf="displayThumbImg">
                        <div class="row">
                            <div class="col-12 text-center">
                                <img class="thumb-img" src="{{tier1Item.thumbImgPath}}" alt="">
                            </div>
                        </div>
                    </span>
                    <span class="inline-span ml-2">
                        <div class="row">
                            <div class="col-12 w-100 text-center">
                                <div *ngIf="itemsAreObjects">{{tier1Item.title}}</div>
                                <div *ngIf="!itemsAreObjects">{{tier1Item}}</div>
                            </div>
                        </div>
                    </span>
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
</ng-template>