import { Injectable, NgZone } from '@angular/core'
import { SoundEffectsService } from '../audio/sound-effects.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  //! Referencing this service causes a cyclical reference error!!!

  constructor(
    private snackbar: MatSnackBar,
    private soundEffectsService: SoundEffectsService
  ) { }


  openSuccessSnackBar(message: string, action?: string, playSound?: boolean, successSoundEnum?: string) {
    this.snackbar.open(message, action, {
      duration: 1500,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: 'ghSuccessSnackbar',
    })

    if (playSound) {
      this.soundEffectsService.playCoinNoise() // todo future can use enums here
    }

  }

  openErrorSnackBar(message: string) {
    this.snackbar.open(message, '', {
      duration: 3000, horizontalPosition: "center", verticalPosition: "bottom", panelClass: 'ghErrorSnackbar'
    })
  }











}
