<div class="parent-container" *ngIf="thumbs">
    <div class="inner-container">
        <div class="row mx-0">
            <div class="col-2 thumbnail-images">

                <div>
                    <img id="thumb0" class="thumb" *ngIf="thumbs && thumbs[0] && thumbs[0].imgPath"
                        [ngClass]="{ 'selectedImg gh-elevation-2': showcaseImg == thumbs[0] }"
                        (mouseenter)="onHoverImage($event)" src="{{thumbs[0].imgPath[0]}}" alt="">
                </div>

                <div>
                    <img id="thumb1" class="thumb" *ngIf="thumbs && thumbs[1] && thumbs[1].imgPath"
                        [ngClass]="{ 'selectedImg gh-elevation-2': showcaseImg == thumbs[1] }"
                        (mouseenter)="onHoverImage($event)" src="{{thumbs[1].imgPath[0]}}" alt="">
                </div>

                <div>
                    <img id="thumb2" class="thumb" *ngIf="thumbs && thumbs[2] && thumbs[2].imgPath"
                        [ngClass]="{ 'selectedImg gh-elevation-2': showcaseImg == thumbs[2] }"
                        (mouseenter)="onHoverImage($event)" src="{{thumbs[2].imgPath[0]}}" alt="">
                </div>

                <!-- ! limited to 2 additional images for UI sake. if we want more than 2, need to tweak the UI so additional images are not so vertically long -->

                <!-- <div>
                    <img id="thumb3" class="thumb" *ngIf="!isMobileScreen && thumbs && thumbs[3] && thumbs[3].imgPath"
                        [ngClass]="{ 'selectedImg gh-elevation-2': showcaseImg == thumbs[3] }"
                        (mouseenter)="onHoverImage($event)" src="{{thumbs[3].imgPath[0]}}" alt="">
                </div>

                <div>
                    <img id="thumb4" class="thumb" *ngIf="!isMobileScreen && thumbs && thumbs[4] && thumbs[4].imgPath"
                        [ngClass]="{ 'selectedImg gh-elevation-2': showcaseImg == thumbs[4] }"
                        (mouseenter)="onHoverImage($event)" src="{{thumbs[4].imgPath[0]}}" alt="">
                </div> -->

                <div>
                    <a id="zoom-icon-container" (click)="showAdditionalImages()">
                        <mat-icon>collections</mat-icon>
                    </a>
                </div>

            </div>

            <div class="col-10">
                <div *ngIf="!showcaseIsVideo; else isVideo">
                    <img matTooltip="View Full Size Image" matTooltipPosition="above" class="box-image"
                        *ngIf="showcaseImg != null" (click)="showFullSize()" src="{{showcaseImg.imgPath[1]}}" alt="">
                </div>

                <ng-template #isVideo>
                    <iframe width="300" height="300" [src]="videoPath" frameborder="0" allowfullscreen="true"
                        [srcdoc]="srcdoc"></iframe>
                </ng-template>

                <!-- flag will be incorporated into the image focus -->
                <!-- <div class="content-flag-container">
                                <app-content-flag></app-content-flag>
                            </div> -->

            </div>
        </div>
    </div>

</div>

<mat-divider id="bottom-divider" *ngIf="_showBottomDivider"></mat-divider>