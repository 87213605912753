export const SIDEBAR_TAB_TITLES = [
    'PROFILE',      // 0
    'FIND EVENTS',  // 1
    'GAMES',        // 2
    'HAVENS',       // 3
    'FRIENDS',      // 4
    'GROUPS',       // 5
    'FEEDBACK',     // 6
    'DISCOVER',     // 7
    'GH PRO',       // 8 
    'ADMIN',        // 9

    //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    //! possibly temp, moving forum to top level for now
    //! note that the fact that this is 10 is meaningful it is the index of the array
    'FORUM',       //10
    //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

]

export const SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES = [
    'GAMES',        // 0
    'IMAGES',       // 1
    'VIDEOS',       // 2
    'ARTICLES',     // 3
    'FORUMS'        // 4
    // 'SURVEYS',   // 5
]
