<!-- <div class="parent-container row" *ngIf="!(isLoading | async)"> -->
<div class="parent-container row" *ngIf="!(isLoading | async)">
    <button *ngIf="isEditing" (click)="removeVoteAndCloseModal()">Remove My Vote</button>
    <div class="mt-5 chart-container col-12">
        <div>
            <!-- ! Important: canvas height and width must be set in px (at least as far as I can tell so far...) -->
            <!-- !            Will eventually need to figure out how to adjust this to screen sizes -->

            <canvas
                style="width: 100%"
                baseChart
                [datasets]="chartData"
                [labels]="radarChartLabelsShallowCopy"
                [type]="chartType"
                [options]="radarChartOptions"
                (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"
                [height]="getCanvasPx()"
                [width]="getCanvasPx()"
            >
            </canvas>
        </div>
    </div>

    <div class="mt-5 row">
        <form [formGroup]="form">
            <section>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="header-row row">
                                    <div class="text-center col-12">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Complexity</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="mt-5 row" matTooltipPosition="above">
                                    <mat-slider
                                        formControlName="complexityRating"
                                        (change)="onComplexitySliderChanged()"
                                        thumbLabel
                                        tickInterval="1"
                                        min="0"
                                        max="10"
                                        value="complexityRating"
                                        class="slider"
                                    >
                                    </mat-slider>
                                </div>
                                <div class="mt-1 row">
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Very Few Rules</mat-card-subtitle>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Lots of Rules</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="header-row row">
                                    <div class="text-center col-12">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Depth</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="mt-5 row" matTooltipPosition="above">
                                    <mat-slider
                                        formControlName="depthRating"
                                        (change)="onDepthSliderChanged()"
                                        thumbLabel
                                        tickInterval="1"
                                        min="0"
                                        max="10"
                                        value="depthRating"
                                        class="slider"
                                    >
                                    </mat-slider>
                                </div>
                                <div class="mt-1 row">
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Easy to Master</mat-card-subtitle>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Hard to Master</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="header-row row">
                                    <div class="text-center col-12">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Duration</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="mt-5 row" matTooltipPosition="above">
                                    <mat-slider
                                        formControlName="durationRating"
                                        (change)="onDurationSliderChanged()"
                                        thumbLabel
                                        tickInterval="1"
                                        min="0"
                                        max="10"
                                        value="durationRating"
                                        class="slider"
                                    >
                                    </mat-slider>
                                </div>
                                <div class="mt-1 row">
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Short</mat-card-subtitle>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Long</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="header-row row">
                                    <div class="text-center col-12">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Interaction</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="mt-5 row" matTooltipPosition="above">
                                    <mat-slider
                                        formControlName="interactionRating"
                                        (change)="onInteractionSliderChanged()"
                                        thumbLabel
                                        tickInterval="1"
                                        min="0"
                                        max="10"
                                        value="interactionRating"
                                        class="slider"
                                    >
                                    </mat-slider>
                                </div>
                                <div class="mt-1 row">
                                    <div class="text-center col-4">
                                        <mat-card-subtitle
                                            >Player Actions are Independent</mat-card-subtitle
                                        >
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="text-center col-4">
                                        <mat-card-subtitle
                                            >Players Influence Each Other</mat-card-subtitle
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="header-row row">
                                    <div class="text-center col-12">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Competition</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="mt-5 row" matTooltipPosition="above">
                                    <mat-slider
                                        formControlName="competitionRating"
                                        (change)="onCompetitionSliderChanged()"
                                        thumbLabel
                                        tickInterval="1"
                                        min="0"
                                        max="10"
                                        value="competitionRating"
                                        class="slider"
                                    >
                                    </mat-slider>
                                </div>
                                <div class="mt-1 row">
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Cooperative</mat-card-subtitle>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Competitive</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="header-row row">
                                    <div class="text-center col-12">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Player Count</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="mt-5 row" matTooltipPosition="above">
                                    <mat-slider
                                        formControlName="playersRating"
                                        (change)="onPlayersSliderChanged()"
                                        thumbLabel
                                        tickInterval="1"
                                        min="0"
                                        max="10"
                                        value="playersRating"
                                        class="slider"
                                    >
                                    </mat-slider>
                                </div>
                                <div class="mt-1 row">
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Solo Play</mat-card-subtitle>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Large Group</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="header-row row">
                                    <div class="text-center col-12">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Chance</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="mt-5 row" matTooltipPosition="above">
                                    <mat-slider
                                        formControlName="chanceRating"
                                        (change)="onChanceSliderChanged()"
                                        thumbLabel
                                        tickInterval="1"
                                        min="0"
                                        max="10"
                                        value="chanceRating"
                                        class="slider"
                                    >
                                    </mat-slider>
                                </div>
                                <div class="mt-1 row">
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Skill-Based</mat-card-subtitle>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Luck-Based</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-3">
                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                <div class="header-row row">
                                    <div class="text-center col-12">
                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                            <strong>Thematic</strong>
                                        </mat-card-subtitle>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="mt-5 row" matTooltipPosition="above">
                                    <mat-slider
                                        formControlName="thematicRating"
                                        (change)="onThematicSliderChanged()"
                                        thumbLabel
                                        tickInterval="1"
                                        min="0"
                                        max="10"
                                        value="thematicRating"
                                        class="slider"
                                    >
                                    </mat-slider>
                                </div>
                                <div class="mt-1 row">
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Abstract / Symbolic</mat-card-subtitle>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="text-center col-4">
                                        <mat-card-subtitle>Simulates Reality</mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>

        <!-- <button (click)="currentValueOfSelectedGame()">currentValueOfSelectedGame</button> -->
    </div>
</div>

<div class="gh-loading-container" *ngIf="isLoading$ | async">
    <app-gh-loading-spinner></app-gh-loading-spinner>
</div>
