import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { SPECIAL_CHARACTERS } from '../../constants/lists-constants'
import { AuthService } from '../../services/auth/auth.service'
import { ulid } from 'ulid'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { PersonalProfessionalAccountDistinctionExpainationComponent } from './components/personal-professional-account-distinction-expaination/personal-professional-account-distinction-expaination.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { Router, ActivatedRoute } from '@angular/router'
import { SidebarService } from 'src/app/dashboard/dashboard-shared/services/sidebar/sidebar.service'
import { TermsAndConditionsComponent } from 'src/app/public/pages/help-center/components/terms-and-conditions/terms-and-conditions.component'
import { TermsOfUseComponent } from 'src/app/public/pages/terms-of-use/terms-of-use.component'
import { PrivacyComponent } from 'src/app/public/pages/privacy/privacy.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from '../../services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-sign-up[_sourceOfInput]',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
    generatedUsername
    passwordFieldIsFocused = false
    passwordLength = 0
    showPassword = true
    submitIsClicked = false
    waitingOnConfirmation = false
    isMobileScreen = false

    signupForm: UntypedFormGroup
    confirmationForm: UntypedFormGroup

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            if (!this.waitingOnConfirmation) {
                this.onSubmit()
            } else {
                this.onConfirm()
            }
        }
    }

    parentLeftActionButtonClicked = false
    @Input() set _parentLeftActionButtonClicked(parentLeftActionButtonClicked: boolean) {
        if (parentLeftActionButtonClicked) {
            this.signupForm.reset()
            this.confirmationForm.reset()
            this.waitingOnConfirmation = false
            this.submitIsClicked = false
        }
    }

    @Output() closeEmitter = new EventEmitter()
    @Output() blurContentEmitter = new EventEmitter()

    didFail = false
    isLoading = false

    constructor(
        private authService: AuthService,
        private formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private sidebarService: SidebarService,
        private router: Router,
        private screenSizeService: ScreenSizeService,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.authService.authIsLoading.subscribe(
            (isLoading: boolean) => (this.isLoading = isLoading),
        )
        this.authService.authDidFail.subscribe((didFail: boolean) => (this.didFail = didFail))

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.didFail = false // needed to prevent false positive on init

        this.signupForm = this.formBuilder.group(
            {
                email: ['', [Validators.required, Validators.email]],
                password: ['', Validators.required],
                confirmPassword: ['', Validators.required],
            },
            { validator: this.mustMatch('password', 'confirmPassword') },
        )

        this.confirmationForm = this.formBuilder.group({
            confirmationCode: ['', Validators.required],
        })

        this.passwordLength = this.signupForm.get('password').value.length
    }

    mustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName]
            const matchingControl = formGroup.controls[matchingControlName]

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return
            }
            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true })
            } else {
                matchingControl.setErrors(null)
            }
        }
    }

    onSubmit() {
        this.submitIsClicked = true

        if (this.signupForm.valid) {
            const username = ulid().toLocaleLowerCase()
            this.generatedUsername = username
            const email = this.signupForm.get('email').value
            const password = this.signupForm.get('password').value
            this.authService.signUp(username, email, password)
            // this.authService.signUp(username, email, password, zipCode)
            this.waitingOnConfirmation = true
            this.submitIsClicked = false
            // this.confirmationForm.get('username').patchValue(this.signupForm.get('username').value)
        }
    }

    onConfirm() {
        this.authService
            .confirmUser(
                this.generatedUsername,
                this.confirmationForm.get('confirmationCode').value,
            )
            .then((userConfirmedRes) => {
                // TODO: need some kind of error handling here...
                this.router.navigate(['/public'])
                alert('Welcome to Game Haven, please LOG IN')
                this.closeEmitter.emit()
            })
    }

    getRequirementIsSatisfiedColor(type) {
        // TODO: create enum for type
        // switch (type) {
        //   case 'lowercaseLetter':
        //     return this.checkIfPasswordContainsLowercaseCharacter() ? 'green' : 'red'
        //   case 'uppercaseLetter':
        //     return this.checkIfPasswordContainsUppercaseCharacter() ? 'green' : 'red'
        //   case 'number':
        //     return this.checkIfPasswordContainsNumber() ? 'green' : 'red'
        //   case 'specialCharacter':
        //     return this.checkIfPasswordContainsSpecialCharacter() ? 'green' : 'red'
        //   case 'specialCharacter':
        //     return this.signupForm.get('password').value.length >= 8 ? 'green' : 'red'
        // }
    }

    getRequirementIsSatisfied(type) {
        // TODO: create enum for type
        switch (type) {
            // case 'lowercaseLetter':
            //   return this.checkIfPasswordContainsLowercaseCharacter()
            // case 'uppercaseLetter':
            //   return this.checkIfPasswordContainsUppercaseCharacter()
            // case 'number':
            //   return this.checkIfPasswordContainsNumber()
            // case 'specialCharacter':
            //   return this.checkIfPasswordContainsSpecialCharacter()
            case 'length':
                return this.signupForm.get('password').value.length
                    ? this.signupForm.get('password').value.length >= 8
                    : 0
        }
    }

    checkIfPasswordContainsLowercaseCharacter() {
        let currentlyEnteredPassword = this.signupForm.get('password').value
        var containsLowercaseCharacter: boolean = false
        for (var i = 0; i < currentlyEnteredPassword.length; i++) {
            if (
                currentlyEnteredPassword.charAt(i) ==
                    currentlyEnteredPassword.charAt(i).toLowerCase() &&
                !this.checkIfCharacterIsNumber(currentlyEnteredPassword.charAt(i)) &&
                !SPECIAL_CHARACTERS.includes(currentlyEnteredPassword.charAt(i))
            ) {
                containsLowercaseCharacter = true
            }
        }
        return true
        return containsLowercaseCharacter
    }

    checkIfPasswordContainsUppercaseCharacter() {
        let currentlyEnteredPassword = this.signupForm.get('password').value
        var containsUppercaseCharacter: boolean = false
        for (var i = 0; i < currentlyEnteredPassword.length; i++) {
            if (
                currentlyEnteredPassword.charAt(i) ==
                    currentlyEnteredPassword.charAt(i).toUpperCase() &&
                !this.checkIfCharacterIsNumber(currentlyEnteredPassword.charAt(i)) &&
                !SPECIAL_CHARACTERS.includes(currentlyEnteredPassword.charAt(i))
            ) {
                containsUppercaseCharacter = true
            }
        }
        return true
        return containsUppercaseCharacter
    }

    checkIfPasswordContainsNumber() {
        let currentlyEnteredPassword = this.signupForm.get('password').value
        var containsNumber: boolean = false
        for (var i = 0; i < currentlyEnteredPassword.length; i++) {
            if (this.checkIfCharacterIsNumber(currentlyEnteredPassword.charAt(i))) {
                containsNumber = true
            }
        }
        return true
        return containsNumber
    }

    checkIfPasswordContainsSpecialCharacter() {
        let currentlyEnteredPassword = this.signupForm.get('password').value
        var containsSpecialCharacter: boolean = false
        for (var i = 0; i < currentlyEnteredPassword.length; i++) {
            if (SPECIAL_CHARACTERS.includes(currentlyEnteredPassword.charAt(i))) {
                containsSpecialCharacter = true
            }
        }
        return true
        return containsSpecialCharacter
    }

    checkIfCharacterIsNumber(value) {
        return value >= '0' && value <= '9'
    }

    resendCode() {
        this.authService.resendActivationCode(this.signupForm.get('email').value)
    }

    onPasswordFocus() {
        this.passwordFieldIsFocused = true
    }

    onPasswordBlur() {
        this.passwordFieldIsFocused = false
    }

    toggleProfessionalAccount(event) {}

    showProfessionalAccountInfo() {
        this.blurContentEmitter.emit(true)
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Account Type Info',
                component: PersonalProfessionalAccountDistinctionExpainationComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
            },
            maxWidth: '30%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.blurContentEmitter.emit(false)
            })
    }

    isPasswordLongEnough(): boolean {
        const password = this.signupForm.get('password').value
        return password && password.length >= 6
    }

    zipCode
    setZipCode(zipCode) {
        let cleanZipCode = zipCode.value.substring(0, 5)
        this.zipCode = cleanZipCode
    }

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword
    }

    contactUs() {
        //! Important: router.navigate must come after sidebarService.updateSelectedItem, otherwise the route param doesn't work
        this.sidebarService.updateSelectedItem('FEEDBACK')
        this.router.navigate(['/dashboard/feedback/feedback-dashboard/2'])
    }

    openTerms() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: false,
            data: {
                title: 'Terms of Service',
                component: TermsOfUseComponent,
                hasSubmitButton: true,
                hasCancelButton: false,
                hasLeftActionButton: false,
                // leftActionButtonText: 'Start Over',
                allowParentClose: true,
                hasCloseButton: true,
            },
            // width: '80%',
            // height: '60%',
        })
        dialogRef.afterClosed().subscribe((data) => {})
    }

    openPrivacyPolicy() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: false,
            data: {
                title: 'Privacy Policy',
                component: PrivacyComponent,
                hasSubmitButton: true,
                hasCancelButton: false,
                hasLeftActionButton: false,
                // leftActionButtonText: 'Start Over',
                allowParentClose: true,
                hasCloseButton: true,
            },
            // width: '80%',
            // height: '60%',
        })
        dialogRef.afterClosed().subscribe((data) => {})
    }
}
