import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-detail-pda-portfolio',
  templateUrl: './game-detail-pda-portfolio.component.html',
  styleUrls: ['./game-detail-pda-portfolio.component.scss']
})
export class GameDetailPDAPortfolioComponent implements OnInit {

  games
  @Input() set _inputData(inputData) {
    this.games = inputData
  }

  constructor() { }

  ngOnInit(): void {
  }

}
