<div class="parent-container">
    <span class="inline-span block" [ngClass]="{ isCurrentPlayerCount: i+1 == currentPlayerCount }"
        *ngFor="let number of numbers; let i = index">
        <div class="block-content"
            [ngClass]="{ isCurrentPlayerCount: i+1 == currentPlayerCount, isFirstBlock: i == 0, isLastBlock: i == (numbers.length-1), isInteractive: getIsInteractive(i) }"
            [ngStyle]="{ backgroundColor: getColor(i) }" (click)="onBlockClicked(i)">
            <span class="number-span"
                [ngClass]="{ isCurrentPlayerCount: i+1 == currentPlayerCount }">{{ i == 7? '8+' : i+1}}</span>
        </div>
    </span>

</div>