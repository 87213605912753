import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { BreakpointObserver } from '@angular/cdk/layout'

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private currentPageInfo = new BehaviorSubject(null)
  currentPageInfo$ = this.currentPageInfo.asObservable()

  userIsHaven = false // toggle this for testing

  mylat = 1
  mylng = 2

  private showExpirimentalItems = new BehaviorSubject(false)
  showExpirimentalItems$ = this.showExpirimentalItems.asObservable()

  public setCurrentPageInfo(currentPageInfo) { // TODO: create interface for this
    this.currentPageInfo.next(currentPageInfo)
  }

  public setShowExpirimentalItems(value: boolean) {
    this.showExpirimentalItems.next(value)
  }

  public setUserIsHaven(value) {
    this.userIsHaven = value
    //this.setUser()
  }

  private sidebarIsActive = new BehaviorSubject(false)
  sidebarIsActive$ = this.sidebarIsActive.asObservable()

  public toggleSidebarIsActive() {
    this.sidebarIsActive.next(!this.sidebarIsActive.value)
  }

  constructor(private breakpointObserver: BreakpointObserver) { }

  getUserIsHaven(): boolean {
    return this.userIsHaven
  }

}

// USER (ghdocs)
const USER = {
  "id": "0",
  "isHavenEntity": false,
  "isProUser": false,
  "title": "Space Ghost",
  "email": "CoastToCoast@gmail.com",
  "radarAttributes": [9, 5, 3, 7, 7, 2, 7, 8],
  "myGroups": ["0"],
  "myGames": [

    // TODO: I don't think we have this implemented? Where are these main images being rendered?

    { "title": "Patchwork", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" },
    { "title": "Sagrada", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" },
    { "title": "Betrayal at the House on the Hill", "numberOfCopies": 2, "mainImage": "https://gh-img.s3.amazonaws.com/G/betrayal.jpg" },
    { "title": "Flash Point Fire Rescue 2nd Edition", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/flash_point.jpg" }
  ],
  "wishlist": [
    { "title": "Blokus", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/blokus.jpg" },
    { "title": "Carcassonne", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/carcassonne.jpg" },
    { "title": "Champions of Midgard", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/champions_of_midgard.jpg" }
  ],
  "mainImage": "https://gh-img.s3.amazonaws.com/U/space_ghost.jpg",
  "hotlist": [
    {
      "id": "0",
      "title": "Patchwork",
      "components": [
        {
          "title": "Mini Test 1",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 2",
          "image": "mini2.jpg",
          "type": "component"
        },
        {
          "title": "Mini Test 3",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 4",
          "image": "mini2.jpg",
          "type": "component"
        }
      ],
      "havenHotlist": ["Tabletop Simulator", "Dave\'s House", "Fett\'s Hutt"],

      "yearPublished": 2016,
      "publishers": [
        "Mayfair Games"
      ],
      "publisherDescription": "Patchwork is a form of needlework that involves sewing together pieces of fabric into a larger design. In the past, it was a way to make use of leftover pieces of cloth to create clothing and quilts. Today, patchwork is a form of art, in which the designers use precious fabrics to create beautiful textiles. The use of uneven pieces of fabric in particular can result in real masterpieces and is therefore being practiced by a large number of textile artists. \\n To create a beautiful quilt, however, requires effort and time, but the avilable patches just do not want to fit together. So choose your patches carefully and keep a healthy supply of buttons to not only finish your quilt, but to make it better and more beautiful than your opponent‘s.",
      "designers": [
        "Uwe Rosenberg"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": 0.3,
        "complexityANS": 0,
        "complexityBIN": 2,
        "depth": 0.5,
        "depthANS": 0,
        "depthBIN": 3,
        "speed": 0.3,
        "speedANS": 0,
        "speedBIN": 2,
        "thematic": 0.1,
        "thematicANS": 0,
        "thematicBIN": 1,
        "interaction": 0.3,
        "interactionANS": 0,
        "interactionBIN": 2,
        "players": 0.3,
        "playersANS": 0,
        "playersBIN": 2,
        "mass": 0.3,
        "massANS": 0,
        "massBIN": 2,
        "obscurity": 0.3,
        "obscurityANS": 0,
        "obscurityBIN": 2
      },
      "competitionTags": [
        "Competitive"
      ],
      "mechanicTags": [
        "Tile Placement"
      ],
      "settingTags": [
        "",
        ""
      ],
      "uncertaintyTags": [
        ""
      ],
      "interactionTags": [
        "",
        ""
      ],
      "atmosphereTags": [
        ""
      ],
      "skillTags": [
        "Pattern Recognition",
        "Planning"
      ],
      "mainImage": "patchwork.png",
      "additionalImages": [
        "img1",
        "img2"
      ]
    },
    {
      "id": "3",
      "title": "Chess",
      "yearPublished": 200,
      "publishers": [
        "Unknown"
      ],
      "publisherDescription": "",
      "designers": [
        "Unknown"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": "0.3",
        "complexityANS": "0",
        "complexityBIN": "2",
        "depth": "1.0",
        "depthANS": "0",
        "depthBIN": "5",
        "speed": "0.5",
        "speedANS": "0",
        "speedBIN": "3",
        "thematic": "0.2",
        "thematicANS": "0",
        "thematicBIN": "2",
        "interaction": "0.5",
        "interactionANS": "0",
        "interactionBIN": "3",
        "players": "0.2",
        "playersANS": "0",
        "playersBIN": "2",
        "mass": "0.3",
        "massANS": "0",
        "massBIN": "2",
        "obscurity": "0.05",
        "obscurityANS": "0",
        "obscurityBIN": "1"
      },
      "competitionTags": [
        "tag1",
        "tag2"
      ],
      "mechanicTags": [
        "tag1",
        "tag2"
      ],
      "settingTags": [
        "tag1",
        "tag2"
      ],
      "uncertaintyTags": [
        "tag1",
        "tag2"
      ],
      "interactionTags": [
        "tag1",
        "tag2"
      ],
      "atmosphereTags": [
        "tag1",
        "tag2"
      ],
      "skillTags": [
        "tag1",
        "tag2"
      ],
      "mainImage": "Chess.jpg",
      "additionalImages": [
        "img1",
        "img2"
      ]
    }
  ],
  "tradedGames": [
    { "game": { "title": "Patchwork", "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" }, "lenderNumberOfCopies": 1, "lender": "Space Ghost", "borrower": "Shay", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Sagrada", "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" }, "lenderNumberOfCopies": 1, "lender": "Shay", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Agricola", "mainImage": "https://gh-img.s3.amazonaws.com/G/agricola.jpg" }, "lenderNumberOfCopies": 3, "lender": "D6", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" }
  ],
  "friends": [
    { "id": "1", "name": "Shay" },
    { "id": "2", "name": "Ben" },
    { "id": "3", "name": "Dave" }
  ],
  "friendsGames": [
    { "title": "Clue", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/clue.jpg" },
    { "title": "Dark Souls", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/dark_souls.jpg" },

  ],
  "recentGames": ["9", "10"],
  "suggestedGames": ["11", "12"],
  "havensAttended": ["0", "1", "3", "4"],
  "havensOwned": ["3"],
  "bio": "User is a good user. Tip, meatloaf corned beef t-bone jowl shoulder. Cupim ball tip meatball, bacon shoulder landjaeger picanha. Sausage capicola meatball fatback pastrami shoulder. Buffalo jerky biltong, boudin shank meatloaf pancetta prosciutto pork loin jowl frankfurter doner beef."
}

const NICK = {
  "id": "0",
  "isHavenEntity": false,
  "isProUser": false,
  "title": "Nick",
  "email": "CoastToCoast@gmail.com",
  "radarAttributes": [9, 5, 3, 7, 7, 2, 7, 8],
  "myGroups": ["0"],
  "myGames": [

    // TODO: I don't think we have this implemented? Where are these main images being rendered?

    { "title": "Patchwork", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" },
    { "title": "Sagrada", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" },
    { "title": "Betrayal at the House on the Hill", "numberOfCopies": 2, "mainImage": "https://gh-img.s3.amazonaws.com/G/betrayal.jpg" },
    { "title": "Flash Point Fire Rescue 2nd Edition", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/flash_point.jpg" }
  ],
  "wishlist": [
    { "title": "Blokus", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/blokus.jpg" },
    { "title": "Carcassonne", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/carcassonne.jpg" },
    { "title": "Champions of Midgard", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/champions_of_midgard.jpg" }
  ],
  "mainImage": "https://gh-img.s3.amazonaws.com/U/space_ghost.jpg",
  "hotlist": [
    {
      "id": "0",
      "title": "Patchwork",
      "components": [
        {
          "title": "Mini Test 1",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 2",
          "image": "mini2.jpg",
          "type": "component"
        },
        {
          "title": "Mini Test 3",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 4",
          "image": "mini2.jpg",
          "type": "component"
        }
      ],
      "havenHotlist": ["Tabletop Simulator", "Dave\'s House", "Fett\'s Hutt"],

      "yearPublished": 2016,
      "publishers": [
        "Mayfair Games"
      ],
      "publisherDescription": "Patchwork is a form of needlework that involves sewing together pieces of fabric into a larger design. In the past, it was a way to make use of leftover pieces of cloth to create clothing and quilts. Today, patchwork is a form of art, in which the designers use precious fabrics to create beautiful textiles. The use of uneven pieces of fabric in particular can result in real masterpieces and is therefore being practiced by a large number of textile artists. \\n To create a beautiful quilt, however, requires effort and time, but the avilable patches just do not want to fit together. So choose your patches carefully and keep a healthy supply of buttons to not only finish your quilt, but to make it better and more beautiful than your opponent‘s.",
      "designers": [
        "Uwe Rosenberg"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": 0.3,
        "complexityANS": 0,
        "complexityBIN": 2,
        "depth": 0.5,
        "depthANS": 0,
        "depthBIN": 3,
        "speed": 0.3,
        "speedANS": 0,
        "speedBIN": 2,
        "thematic": 0.1,
        "thematicANS": 0,
        "thematicBIN": 1,
        "interaction": 0.3,
        "interactionANS": 0,
        "interactionBIN": 2,
        "players": 0.3,
        "playersANS": 0,
        "playersBIN": 2,
        "mass": 0.3,
        "massANS": 0,
        "massBIN": 2,
        "obscurity": 0.3,
        "obscurityANS": 0,
        "obscurityBIN": 2
      },
      "competitionTags": [
        "Competitive"
      ],
      "mechanicTags": [
        "Tile Placement"
      ],
      "settingTags": [
        "",
        ""
      ],
      "uncertaintyTags": [
        ""
      ],
      "interactionTags": [
        "",
        ""
      ],
      "atmosphereTags": [
        ""
      ],
      "skillTags": [
        "Pattern Recognition",
        "Planning"
      ],
      "mainImage": "patchwork.png",
      "additionalImages": [
        "img1",
        "img2"
      ]
    },
    {
      "id": "3",
      "title": "Chess",
      "yearPublished": 200,
      "publishers": [
        "Unknown"
      ],
      "publisherDescription": "",
      "designers": [
        "Unknown"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": "0.3",
        "complexityANS": "0",
        "complexityBIN": "2",
        "depth": "1.0",
        "depthANS": "0",
        "depthBIN": "5",
        "speed": "0.5",
        "speedANS": "0",
        "speedBIN": "3",
        "thematic": "0.2",
        "thematicANS": "0",
        "thematicBIN": "2",
        "interaction": "0.5",
        "interactionANS": "0",
        "interactionBIN": "3",
        "players": "0.2",
        "playersANS": "0",
        "playersBIN": "2",
        "mass": "0.3",
        "massANS": "0",
        "massBIN": "2",
        "obscurity": "0.05",
        "obscurityANS": "0",
        "obscurityBIN": "1"
      },
      "competitionTags": [
        "tag1",
        "tag2"
      ],
      "mechanicTags": [
        "tag1",
        "tag2"
      ],
      "settingTags": [
        "tag1",
        "tag2"
      ],
      "uncertaintyTags": [
        "tag1",
        "tag2"
      ],
      "interactionTags": [
        "tag1",
        "tag2"
      ],
      "atmosphereTags": [
        "tag1",
        "tag2"
      ],
      "skillTags": [
        "tag1",
        "tag2"
      ],
      "mainImage": "Chess.jpg",
      "additionalImages": [
        "img1",
        "img2"
      ]
    }
  ],
  "tradedGames": [
    { "game": { "title": "Patchwork", "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" }, "lenderNumberOfCopies": 1, "lender": "Space Ghost", "borrower": "Shay", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Sagrada", "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" }, "lenderNumberOfCopies": 1, "lender": "Shay", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Agricola", "mainImage": "https://gh-img.s3.amazonaws.com/G/agricola.jpg" }, "lenderNumberOfCopies": 3, "lender": "D6", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" }
  ],
  "friends": [
    { "id": "1", "name": "Shay" },
    { "id": "2", "name": "Ben" },
    { "id": "3", "name": "Dave" }
  ],
  "friendsGames": [
    { "title": "Clue", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/clue.jpg" },
    { "title": "Dark Souls", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/dark_souls.jpg" },

  ],
  "recentGames": ["9", "10"],
  "suggestedGames": ["11", "12"],
  "havensAttended": ["0", "1", "3", "4"],
  "havensOwned": ["3"],
  "bio": "I like to play really long games that take forever and the rule book is a book"
}


const ZACH = {
  "id": "0",
  "username": "Zach",
  "isHavenEntity": false,
  "isProUser": false,
  "title": "Zach",
  "email": "zachrazidlo@gmail.com",
  "radarAttributes": [9, 5, 3, 7, 7, 2, 7, 8],
  "myGroups": ["0"],
  "myGames": [

    // TODO: I don't think we have this implemented? Where are these main images being rendered?

    { "title": "Patchwork", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" },
    { "title": "Sagrada", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" },
    { "title": "Betrayal at the House on the Hill", "numberOfCopies": 2, "mainImage": "https://gh-img.s3.amazonaws.com/G/betrayal.jpg" },
    { "title": "Flash Point Fire Rescue 2nd Edition", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/flash_point.jpg" }
  ],
  "wishlist": [
    { "title": "Blokus", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/blokus.jpg" },
    { "title": "Carcassonne", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/carcassonne.jpg" },
    { "title": "Champions of Midgard", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/champions_of_midgard.jpg" }
  ],
  "mainImage": "https://gh-img.s3.amazonaws.com/U/space_ghost.jpg",
  "hotlist": [
    {
      "id": "0",
      "title": "Patchwork",
      "components": [
        {
          "title": "Mini Test 1",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 2",
          "image": "mini2.jpg",
          "type": "component"
        },
        {
          "title": "Mini Test 3",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 4",
          "image": "mini2.jpg",
          "type": "component"
        }
      ],
      "havenHotlist": ["Tabletop Simulator", "Dave\'s House", "Fett\'s Hutt"],

      "yearPublished": 2016,
      "publishers": [
        "Mayfair Games"
      ],
      "publisherDescription": "Patchwork is a form of needlework that involves sewing together pieces of fabric into a larger design. In the past, it was a way to make use of leftover pieces of cloth to create clothing and quilts. Today, patchwork is a form of art, in which the designers use precious fabrics to create beautiful textiles. The use of uneven pieces of fabric in particular can result in real masterpieces and is therefore being practiced by a large number of textile artists. \\n To create a beautiful quilt, however, requires effort and time, but the avilable patches just do not want to fit together. So choose your patches carefully and keep a healthy supply of buttons to not only finish your quilt, but to make it better and more beautiful than your opponent‘s.",
      "designers": [
        "Uwe Rosenberg"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": 0.3,
        "complexityANS": 0,
        "complexityBIN": 2,
        "depth": 0.5,
        "depthANS": 0,
        "depthBIN": 3,
        "speed": 0.3,
        "speedANS": 0,
        "speedBIN": 2,
        "thematic": 0.1,
        "thematicANS": 0,
        "thematicBIN": 1,
        "interaction": 0.3,
        "interactionANS": 0,
        "interactionBIN": 2,
        "players": 0.3,
        "playersANS": 0,
        "playersBIN": 2,
        "mass": 0.3,
        "massANS": 0,
        "massBIN": 2,
        "obscurity": 0.3,
        "obscurityANS": 0,
        "obscurityBIN": 2
      },
      "competitionTags": [
        "Competitive"
      ],
      "mechanicTags": [
        "Tile Placement"
      ],
      "settingTags": [
        "",
        ""
      ],
      "uncertaintyTags": [
        ""
      ],
      "interactionTags": [
        "",
        ""
      ],
      "atmosphereTags": [
        ""
      ],
      "skillTags": [
        "Pattern Recognition",
        "Planning"
      ],
      "mainImage": "patchwork.png",
      "additionalImages": [
        "img1",
        "img2"
      ]
    },
    {
      "id": "3",
      "title": "Chess",
      "yearPublished": 200,
      "publishers": [
        "Unknown"
      ],
      "publisherDescription": "",
      "designers": [
        "Unknown"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": "0.3",
        "complexityANS": "0",
        "complexityBIN": "2",
        "depth": "1.0",
        "depthANS": "0",
        "depthBIN": "5",
        "speed": "0.5",
        "speedANS": "0",
        "speedBIN": "3",
        "thematic": "0.2",
        "thematicANS": "0",
        "thematicBIN": "2",
        "interaction": "0.5",
        "interactionANS": "0",
        "interactionBIN": "3",
        "players": "0.2",
        "playersANS": "0",
        "playersBIN": "2",
        "mass": "0.3",
        "massANS": "0",
        "massBIN": "2",
        "obscurity": "0.05",
        "obscurityANS": "0",
        "obscurityBIN": "1"
      },
      "competitionTags": [
        "tag1",
        "tag2"
      ],
      "mechanicTags": [
        "tag1",
        "tag2"
      ],
      "settingTags": [
        "tag1",
        "tag2"
      ],
      "uncertaintyTags": [
        "tag1",
        "tag2"
      ],
      "interactionTags": [
        "tag1",
        "tag2"
      ],
      "atmosphereTags": [
        "tag1",
        "tag2"
      ],
      "skillTags": [
        "tag1",
        "tag2"
      ],
      "mainImage": "Chess.jpg",
      "additionalImages": [
        "img1",
        "img2"
      ]
    }
  ],
  "tradedGames": [
    { "game": { "title": "Patchwork", "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" }, "lenderNumberOfCopies": 1, "lender": "Space Ghost", "borrower": "Shay", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Sagrada", "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" }, "lenderNumberOfCopies": 1, "lender": "Shay", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Agricola", "mainImage": "https://gh-img.s3.amazonaws.com/G/agricola.jpg" }, "lenderNumberOfCopies": 3, "lender": "D6", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" }
  ],
  "friends": [
    { "id": "1", "name": "Shay" },
    { "id": "2", "name": "Ben" },
    { "id": "3", "name": "Dave" }
  ],
  "friendsGames": [
    { "title": "Clue", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/clue.jpg" },
    { "title": "Dark Souls", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/dark_souls.jpg" },

  ],
  "recentGames": ["9", "10"],
  "suggestedGames": ["11", "12"],
  "havensAttended": ["0", "1", "3", "4"],
  "havensOwned": ["3"],
  "bio": "I like to play DnD and war games."
}

const BEN = {
  "id": "0",
  "username": "Ben",
  "isHavenEntity": false,
  "isProUser": false,
  "title": "Mr. Ben",
  "email": "ben.razidlo@gmail.com",
  "radarAttributes": [9, 5, 3, 7, 7, 2, 7, 8],
  "myGroups": ["0"],
  "myGames": [

    // TODO: I don't think we have this implemented? Where are these main images being rendered?

    { "title": "Patchwork", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" },
    { "title": "Sagrada", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" },
    { "title": "Betrayal at the House on the Hill", "numberOfCopies": 2, "mainImage": "https://gh-img.s3.amazonaws.com/G/betrayal.jpg" },
    { "title": "Flash Point Fire Rescue 2nd Edition", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/flash_point.jpg" }
  ],
  "wishlist": [
    { "title": "Blokus", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/blokus.jpg" },
    { "title": "Carcassonne", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/carcassonne.jpg" },
    { "title": "Champions of Midgard", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/champions_of_midgard.jpg" }
  ],
  "mainImage": "https://gh-img.s3.amazonaws.com/U/space_ghost.jpg",
  "hotlist": [
    {
      "id": "0",
      "title": "Patchwork",
      "components": [
        {
          "title": "Mini Test 1",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 2",
          "image": "mini2.jpg",
          "type": "component"
        },
        {
          "title": "Mini Test 3",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 4",
          "image": "mini2.jpg",
          "type": "component"
        }
      ],
      "havenHotlist": ["Tabletop Simulator", "Dave\'s House", "Fett\'s Hutt"],

      "yearPublished": 2016,
      "publishers": [
        "Mayfair Games"
      ],
      "publisherDescription": "Patchwork is a form of needlework that involves sewing together pieces of fabric into a larger design. In the past, it was a way to make use of leftover pieces of cloth to create clothing and quilts. Today, patchwork is a form of art, in which the designers use precious fabrics to create beautiful textiles. The use of uneven pieces of fabric in particular can result in real masterpieces and is therefore being practiced by a large number of textile artists. \\n To create a beautiful quilt, however, requires effort and time, but the avilable patches just do not want to fit together. So choose your patches carefully and keep a healthy supply of buttons to not only finish your quilt, but to make it better and more beautiful than your opponent‘s.",
      "designers": [
        "Uwe Rosenberg"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": 0.3,
        "complexityANS": 0,
        "complexityBIN": 2,
        "depth": 0.5,
        "depthANS": 0,
        "depthBIN": 3,
        "speed": 0.3,
        "speedANS": 0,
        "speedBIN": 2,
        "thematic": 0.1,
        "thematicANS": 0,
        "thematicBIN": 1,
        "interaction": 0.3,
        "interactionANS": 0,
        "interactionBIN": 2,
        "players": 0.3,
        "playersANS": 0,
        "playersBIN": 2,
        "mass": 0.3,
        "massANS": 0,
        "massBIN": 2,
        "obscurity": 0.3,
        "obscurityANS": 0,
        "obscurityBIN": 2
      },
      "competitionTags": [
        "Competitive"
      ],
      "mechanicTags": [
        "Tile Placement"
      ],
      "settingTags": [
        "",
        ""
      ],
      "uncertaintyTags": [
        ""
      ],
      "interactionTags": [
        "",
        ""
      ],
      "atmosphereTags": [
        ""
      ],
      "skillTags": [
        "Pattern Recognition",
        "Planning"
      ],
      "mainImage": "patchwork.png",
      "additionalImages": [
        "img1",
        "img2"
      ]
    },
    {
      "id": "3",
      "title": "Chess",
      "yearPublished": 200,
      "publishers": [
        "Unknown"
      ],
      "publisherDescription": "",
      "designers": [
        "Unknown"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": "0.3",
        "complexityANS": "0",
        "complexityBIN": "2",
        "depth": "1.0",
        "depthANS": "0",
        "depthBIN": "5",
        "speed": "0.5",
        "speedANS": "0",
        "speedBIN": "3",
        "thematic": "0.2",
        "thematicANS": "0",
        "thematicBIN": "2",
        "interaction": "0.5",
        "interactionANS": "0",
        "interactionBIN": "3",
        "players": "0.2",
        "playersANS": "0",
        "playersBIN": "2",
        "mass": "0.3",
        "massANS": "0",
        "massBIN": "2",
        "obscurity": "0.05",
        "obscurityANS": "0",
        "obscurityBIN": "1"
      },
      "competitionTags": [
        "tag1",
        "tag2"
      ],
      "mechanicTags": [
        "tag1",
        "tag2"
      ],
      "settingTags": [
        "tag1",
        "tag2"
      ],
      "uncertaintyTags": [
        "tag1",
        "tag2"
      ],
      "interactionTags": [
        "tag1",
        "tag2"
      ],
      "atmosphereTags": [
        "tag1",
        "tag2"
      ],
      "skillTags": [
        "tag1",
        "tag2"
      ],
      "mainImage": "Chess.jpg",
      "additionalImages": [
        "img1",
        "img2"
      ]
    }
  ],
  "tradedGames": [
    { "game": { "title": "Patchwork", "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" }, "lenderNumberOfCopies": 1, "lender": "Space Ghost", "borrower": "Shay", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Sagrada", "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" }, "lenderNumberOfCopies": 1, "lender": "Shay", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Agricola", "mainImage": "https://gh-img.s3.amazonaws.com/G/agricola.jpg" }, "lenderNumberOfCopies": 3, "lender": "D6", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" }
  ],
  "friends": [
    { "id": "1", "name": "Shay" },
    { "id": "2", "name": "Ben" },
    { "id": "3", "name": "Dave" }
  ],
  "friendsGames": [
    { "title": "Clue", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/clue.jpg" },
    { "title": "Dark Souls", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/dark_souls.jpg" },

  ],
  "recentGames": ["9", "10"],
  "suggestedGames": ["11", "12"],
  "havensAttended": ["0", "1", "3", "4"],
  "havensOwned": ["3"],
  "bio": "I like to play Really long games, but more often they sit on a shelf while I want youtube looking for more."
}


// probably need to change lots of other things aside from myGames -> lendingLibrary (changed a few already...)


const HAVEN_USER = {
  "id": "99",
  "username": "Haven Owner",
  "isHavenEntity": true,
  "isProUser": false,
  "title": "D6",
  "email": "d6@gmail.com",
  "radarAttributes": [9, 5, 3, 7, 7, 2, 7, 8],
  "myGroups": ["0"],
  "lendingLibrary": [
    { "title": "Dominion", "numberOfCopies": 1, "mainImage": "dominion.jpg" },
    { "title": "Everdell", "numberOfCopies": 2, "mainImage": "Sagrada.jpg" },
    { "title": "Fallout", "numberOfCopies": 1, "mainImage": "wavelength.jpg" }
  ],
  "demoLibrary": [
    { "title": "Forbidden Island", "numberOfCopies": 1, "mainImage": "forbiddenIsland.jpg" },
    { "title": "Fox in the Forest", "numberOfCopies": 1, "mainImage": "foxInTheForest.jpg" },
    { "title": "Wavelength", "numberOfCopies": 1, "mainImage": "Flashpoint.jpg" }
  ],
  "usuallyStocked": [
    { "title": "Scythe", "numberOfCopies": 1, "mainImage": "scythe.jpg" },
    { "title": "Trekking the World", "numberOfCopies": 1, "mainImage": "trekkingTheWorld.jpg" },
    { "title": "Hungry Hungry Hippos", "numberOfCopies": 1, "mainImage": "hungryHungryHippos.jpg" }
  ],
  "mainImage": "space-ghost.png",
  "hotlist": [
    {
      "id": "0",
      "title": "Patchwork",
      "components": [
        {
          "title": "Mini Test 1",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 2",
          "image": "mini2.jpg",
          "type": "component"
        },
        {
          "title": "Mini Test 3",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 4",
          "image": "mini2.jpg",
          "type": "component"
        }
      ],
      "havenHotlist": ["Tabletop Simulator", "Dave\'s House", "Fett\'s Hutt"],

      "yearPublished": 2016,
      "publishers": [
        "Mayfair Games"
      ],
      "publisherDescription": "Patchwork is a form of needlework that involves sewing together pieces of fabric into a larger design. In the past, it was a way to make use of leftover pieces of cloth to create clothing and quilts. Today, patchwork is a form of art, in which the designers use precious fabrics to create beautiful textiles. The use of uneven pieces of fabric in particular can result in real masterpieces and is therefore being practiced by a large number of textile artists. \\n To create a beautiful quilt, however, requires effort and time, but the avilable patches just do not want to fit together. So choose your patches carefully and keep a healthy supply of buttons to not only finish your quilt, but to make it better and more beautiful than your opponent‘s.",
      "designers": [
        "Uwe Rosenberg"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": 0.3,
        "complexityANS": 0,
        "complexityBIN": 2,
        "depth": 0.5,
        "depthANS": 0,
        "depthBIN": 3,
        "speed": 0.3,
        "speedANS": 0,
        "speedBIN": 2,
        "thematic": 0.1,
        "thematicANS": 0,
        "thematicBIN": 1,
        "interaction": 0.3,
        "interactionANS": 0,
        "interactionBIN": 2,
        "players": 0.3,
        "playersANS": 0,
        "playersBIN": 2,
        "mass": 0.3,
        "massANS": 0,
        "massBIN": 2,
        "obscurity": 0.3,
        "obscurityANS": 0,
        "obscurityBIN": 2
      },
      "competitionTags": [
        "Competitive"
      ],
      "mechanicTags": [
        "Tile Placement"
      ],
      "settingTags": [
        "",
        ""
      ],
      "uncertaintyTags": [
        ""
      ],
      "interactionTags": [
        "",
        ""
      ],
      "atmosphereTags": [
        ""
      ],
      "skillTags": [
        "Pattern Recognition",
        "Planning"
      ],
      "mainImage": "patchwork.png",
      "additionalImages": [
        "img1",
        "img2"
      ]
    },
    {
      "id": "3",
      "title": "Chess",
      "yearPublished": 200,
      "publishers": [
        "Unknown"
      ],
      "publisherDescription": "",
      "designers": [
        "Unknown"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": "0.3",
        "complexityANS": "0",
        "complexityBIN": "2",
        "depth": "1.0",
        "depthANS": "0",
        "depthBIN": "5",
        "speed": "0.5",
        "speedANS": "0",
        "speedBIN": "3",
        "thematic": "0.2",
        "thematicANS": "0",
        "thematicBIN": "2",
        "interaction": "0.5",
        "interactionANS": "0",
        "interactionBIN": "3",
        "players": "0.2",
        "playersANS": "0",
        "playersBIN": "2",
        "mass": "0.3",
        "massANS": "0",
        "massBIN": "2",
        "obscurity": "0.05",
        "obscurityANS": "0",
        "obscurityBIN": "1"
      },
      "competitionTags": [
        "tag1",
        "tag2"
      ],
      "mechanicTags": [
        "tag1",
        "tag2"
      ],
      "settingTags": [
        "tag1",
        "tag2"
      ],
      "uncertaintyTags": [
        "tag1",
        "tag2"
      ],
      "interactionTags": [
        "tag1",
        "tag2"
      ],
      "atmosphereTags": [
        "tag1",
        "tag2"
      ],
      "skillTags": [
        "tag1",
        "tag2"
      ],
      "mainImage": "Chess.jpg",
      "additionalImages": [
        "img1",
        "img2"
      ]
    }
  ],
  "tradedGames": [
    { "game": "Patchwork", "lenderNumberOfCopies": 1, "lender": "Space Ghost", "borrower": "Shay", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019", "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" },
    { "game": "Sagrada", "lenderNumberOfCopies": 1, "lender": "Shay", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019", "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" },
    { "game": "Agricola", "lenderNumberOfCopies": 3, "lender": "D6", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019", "mainImage": "https://gh-img.s3.amazonaws.com/G/agricola.jpg" }
  ],
  "friends": [
    { "id": "1", "name": "Shay" },
    { "id": "2", "name": "Ben" },
    { "id": "3", "name": "Dave" }
  ],
  "friendsGames": ["6", "7", "8"],
  "recentGames": ["9", "10"],
  "suggestedGames": ["11", "12"],
  "havensAttended": ["0", "1", "3", "4"],
  "havensOwned": ["3"],
  "bio": "I am the owner of the Game store and cofffe shop based in Austin MN."
}


const DAVE = {
  "id": "0",
  "username": "Dave",
  "isHavenEntity": false,
  "isProUser": false,
  "title": "David Razidlo",
  "email": "davidrazidlo@gmail.com",
  "radarAttributes": [9, 5, 3, 7, 7, 2, 7, 8],
  "myGroups": ["0"],
  "myGames": [
    { "title": "Patchwork", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" },
    { "title": "Sagrada", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" },
    { "title": "Betrayal at the House on the Hill", "numberOfCopies": 2, "mainImage": "https://gh-img.s3.amazonaws.com/G/betrayal.jpg" },
    { "title": "Flash Point Fire Rescue 2nd Edition", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/flash_point.jpg" }
  ],
  "wishlist": [
    { "title": "Blokus", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/blokus.jpg" },
    { "title": "Carcassonne", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/carcassonne.jpg" },
    { "title": "Champions of Midgard", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/champions_of_midgard.jpg" }
  ],
  "mainImage": "https://gh-img.s3.amazonaws.com/U/pikachu.png",
  "hotlist": [
    {
      "id": "0",
      "title": "Patchwork",
      "components": [
        {
          "title": "Mini Test 1",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 2",
          "image": "mini2.jpg",
          "type": "component"
        },
        {
          "title": "Mini Test 3",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 4",
          "image": "mini2.jpg",
          "type": "component"
        }
      ],
      "havenHotlist": ["Tabletop Simulator", "Dave\'s House", "Fett\'s Hutt"],

      "yearPublished": 2016,
      "publishers": [
        "Mayfair Games"
      ],
      "publisherDescription": "Patchwork is a form of needlework that involves sewing together pieces of fabric into a larger design. In the past, it was a way to make use of leftover pieces of cloth to create clothing and quilts. Today, patchwork is a form of art, in which the designers use precious fabrics to create beautiful textiles. The use of uneven pieces of fabric in particular can result in real masterpieces and is therefore being practiced by a large number of textile artists. \\n To create a beautiful quilt, however, requires effort and time, but the avilable patches just do not want to fit together. So choose your patches carefully and keep a healthy supply of buttons to not only finish your quilt, but to make it better and more beautiful than your opponent‘s.",
      "designers": [
        "Uwe Rosenberg"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": 0.3,
        "complexityANS": 0,
        "complexityBIN": 2,
        "depth": 0.5,
        "depthANS": 0,
        "depthBIN": 3,
        "speed": 0.3,
        "speedANS": 0,
        "speedBIN": 2,
        "thematic": 0.1,
        "thematicANS": 0,
        "thematicBIN": 1,
        "interaction": 0.3,
        "interactionANS": 0,
        "interactionBIN": 2,
        "players": 0.3,
        "playersANS": 0,
        "playersBIN": 2,
        "mass": 0.3,
        "massANS": 0,
        "massBIN": 2,
        "obscurity": 0.3,
        "obscurityANS": 0,
        "obscurityBIN": 2
      },
      "competitionTags": [
        "Competitive"
      ],
      "mechanicTags": [
        "Tile Placement"
      ],
      "settingTags": [
        "",
        ""
      ],
      "uncertaintyTags": [
        ""
      ],
      "interactionTags": [
        "",
        ""
      ],
      "atmosphereTags": [
        ""
      ],
      "skillTags": [
        "Pattern Recognition",
        "Planning"
      ],
      "mainImage": "patchwork.png",
      "additionalImages": [
        "img1",
        "img2"
      ]
    },
    {
      "id": "3",
      "title": "Chess",
      "yearPublished": 200,
      "publishers": [
        "Unknown"
      ],
      "publisherDescription": "",
      "designers": [
        "Unknown"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": "0.3",
        "complexityANS": "0",
        "complexityBIN": "2",
        "depth": "1.0",
        "depthANS": "0",
        "depthBIN": "5",
        "speed": "0.5",
        "speedANS": "0",
        "speedBIN": "3",
        "thematic": "0.2",
        "thematicANS": "0",
        "thematicBIN": "2",
        "interaction": "0.5",
        "interactionANS": "0",
        "interactionBIN": "3",
        "players": "0.2",
        "playersANS": "0",
        "playersBIN": "2",
        "mass": "0.3",
        "massANS": "0",
        "massBIN": "2",
        "obscurity": "0.05",
        "obscurityANS": "0",
        "obscurityBIN": "1"
      },
      "competitionTags": [
        "tag1",
        "tag2"
      ],
      "mechanicTags": [
        "tag1",
        "tag2"
      ],
      "settingTags": [
        "tag1",
        "tag2"
      ],
      "uncertaintyTags": [
        "tag1",
        "tag2"
      ],
      "interactionTags": [
        "tag1",
        "tag2"
      ],
      "atmosphereTags": [
        "tag1",
        "tag2"
      ],
      "skillTags": [
        "tag1",
        "tag2"
      ],
      "mainImage": "Chess.jpg",
      "additionalImages": [
        "img1",
        "img2"
      ]
    }
  ],
  "tradedGames": [
    { "game": { "title": "Patchwork", "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" }, "lenderNumberOfCopies": 1, "lender": "Space Ghost", "borrower": "Shay", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Sagrada", "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" }, "lenderNumberOfCopies": 1, "lender": "Shay", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Agricola", "mainImage": "https://gh-img.s3.amazonaws.com/G/agricola.jpg" }, "lenderNumberOfCopies": 3, "lender": "D6", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" }
  ],
  "friends": [
    { "id": "1", "name": "Shay" },
    { "id": "2", "name": "Ben" },
    { "id": "3", "name": "Dave" }
  ],
  "friendsGames": [
    { "title": "Clue", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/clue.jpg" },
    { "title": "Dark Souls", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/dark_souls.jpg" },

  ],
  "recentGames": ["9", "10"],
  "suggestedGames": ["11", "12"],
  "havensAttended": ["0", "1", "3", "4"],
  "havensOwned": ["3"],
  "bio": "I like short games and lots of meatloaf pancetta prosciutto pork loin jowl frankfurter doner beef."
}

const GH_DOCS = {
  "id": "0",
  "username": "GH_Docs",
  "isHavenEntity": false,
  "isProUser": false,
  "title": "Gh Docs",
  "email": "gamehaven.documentation@gmail.com",
  "radarAttributes": [9, 5, 3, 7, 7, 2, 7, 8],
  "myGroups": ["0"],
  "myGames": [
    { "title": "Patchwork", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" },
    { "title": "Sagrada", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" },
    { "title": "Betrayal at the House on the Hill", "numberOfCopies": 2, "mainImage": "https://gh-img.s3.amazonaws.com/G/betrayal.jpg" },
    { "title": "Flash Point Fire Rescue 2nd Edition", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/flash_point.jpg" }
  ],
  "wishlist": [
    { "title": "Blokus", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/blokus.jpg" },
    { "title": "Carcassonne", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/carcassonne.jpg" },
    { "title": "Champions of Midgard", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/champions_of_midgard.jpg" }
  ],
  "mainImage": "https://gh-img.s3.amazonaws.com/U/peter_griffin.jpg",
  "hotlist": [
    {
      "id": "0",
      "title": "Patchwork",
      "components": [
        {
          "title": "Mini Test 1",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 2",
          "image": "mini2.jpg",
          "type": "component"
        },
        {
          "title": "Mini Test 3",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 4",
          "image": "mini2.jpg",
          "type": "component"
        }
      ],
      "havenHotlist": ["Tabletop Simulator", "Dave\'s House", "Fett\'s Hutt"],

      "yearPublished": 2016,
      "publishers": [
        "Mayfair Games"
      ],
      "publisherDescription": "Patchwork is a form of needlework that involves sewing together pieces of fabric into a larger design. In the past, it was a way to make use of leftover pieces of cloth to create clothing and quilts. Today, patchwork is a form of art, in which the designers use precious fabrics to create beautiful textiles. The use of uneven pieces of fabric in particular can result in real masterpieces and is therefore being practiced by a large number of textile artists. \\n To create a beautiful quilt, however, requires effort and time, but the avilable patches just do not want to fit together. So choose your patches carefully and keep a healthy supply of buttons to not only finish your quilt, but to make it better and more beautiful than your opponent‘s.",
      "designers": [
        "Uwe Rosenberg"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": 0.3,
        "complexityANS": 0,
        "complexityBIN": 2,
        "depth": 0.5,
        "depthANS": 0,
        "depthBIN": 3,
        "speed": 0.3,
        "speedANS": 0,
        "speedBIN": 2,
        "thematic": 0.1,
        "thematicANS": 0,
        "thematicBIN": 1,
        "interaction": 0.3,
        "interactionANS": 0,
        "interactionBIN": 2,
        "players": 0.3,
        "playersANS": 0,
        "playersBIN": 2,
        "mass": 0.3,
        "massANS": 0,
        "massBIN": 2,
        "obscurity": 0.3,
        "obscurityANS": 0,
        "obscurityBIN": 2
      },
      "competitionTags": [
        "Competitive"
      ],
      "mechanicTags": [
        "Tile Placement"
      ],
      "settingTags": [
        "",
        ""
      ],
      "uncertaintyTags": [
        ""
      ],
      "interactionTags": [
        "",
        ""
      ],
      "atmosphereTags": [
        ""
      ],
      "skillTags": [
        "Pattern Recognition",
        "Planning"
      ],
      "mainImage": "patchwork.png",
      "additionalImages": [
        "img1",
        "img2"
      ]
    },
    {
      "id": "3",
      "title": "Chess",
      "yearPublished": 200,
      "publishers": [
        "Unknown"
      ],
      "publisherDescription": "",
      "designers": [
        "Unknown"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": "0.3",
        "complexityANS": "0",
        "complexityBIN": "2",
        "depth": "1.0",
        "depthANS": "0",
        "depthBIN": "5",
        "speed": "0.5",
        "speedANS": "0",
        "speedBIN": "3",
        "thematic": "0.2",
        "thematicANS": "0",
        "thematicBIN": "2",
        "interaction": "0.5",
        "interactionANS": "0",
        "interactionBIN": "3",
        "players": "0.2",
        "playersANS": "0",
        "playersBIN": "2",
        "mass": "0.3",
        "massANS": "0",
        "massBIN": "2",
        "obscurity": "0.05",
        "obscurityANS": "0",
        "obscurityBIN": "1"
      },
      "competitionTags": [
        "tag1",
        "tag2"
      ],
      "mechanicTags": [
        "tag1",
        "tag2"
      ],
      "settingTags": [
        "tag1",
        "tag2"
      ],
      "uncertaintyTags": [
        "tag1",
        "tag2"
      ],
      "interactionTags": [
        "tag1",
        "tag2"
      ],
      "atmosphereTags": [
        "tag1",
        "tag2"
      ],
      "skillTags": [
        "tag1",
        "tag2"
      ],
      "mainImage": "Chess.jpg",
      "additionalImages": [
        "img1",
        "img2"
      ]
    }
  ],
  "tradedGames": [
    { "game": { "title": "Patchwork", "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" }, "lenderNumberOfCopies": 1, "lender": "Space Ghost", "borrower": "Shay", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Sagrada", "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" }, "lenderNumberOfCopies": 1, "lender": "Shay", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Agricola", "mainImage": "https://gh-img.s3.amazonaws.com/G/agricola.jpg" }, "lenderNumberOfCopies": 3, "lender": "D6", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" }
  ],
  "friends": [
    { "id": "1", "name": "Shay" },
    { "id": "2", "name": "Ben" },
    { "id": "3", "name": "Dave" }
  ],
  "friendsGames": [
    { "title": "Clue", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/clue.jpg" },
    { "title": "Dark Souls", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/dark_souls.jpg" },

  ],
  "recentGames": ["9", "10"],
  "suggestedGames": ["11", "12"],
  "havensAttended": ["0", "1", "3", "4"],
  "havensOwned": ["3"],
  "bio": "GHDOCS Bio: und round frankfurter ball tip, meatloaf corned beef t-bone jowl shoulder. Cupim ball tip meatball, bacon shoulder landjaeger picanha. Sausage capicola meatball fatback pastrami shoulder. Buffalo jerky biltong, boudin shank meatloaf pancetta prosciutto pork loin jowl frankfurter doner beef."
}

const SHAY = {
  "id": "0",
  "username": "Shay",
  "isHavenEntity": false,
  "isProUser": false,
  "title": "Shay Ebertowski",
  "email": "ebert.shay@gmail.com",
  "radarAttributes": [9, 5, 3, 7, 7, 2, 7, 8],
  "myGroups": ["0"],
  "myGames": [
    { "title": "Patchwork", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" },
    { "title": "Sagrada", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" },
    { "title": "Betrayal at the House on the Hill", "numberOfCopies": 2, "mainImage": "https://gh-img.s3.amazonaws.com/G/betrayal.jpg" },
    { "title": "Flash Point Fire Rescue 2nd Edition", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/flash_point.jpg" }
  ],
  "wishlist": [
    { "title": "Blokus", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/blokus.jpg" },
    { "title": "Carcassonne", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/carcassonne.jpg" },
    { "title": "Champions of Midgard", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/champions_of_midgard.jpg" }
  ],
  "mainImage": "https://gh-img.s3.amazonaws.com/U/jonny_bravo.jpg",
  "hotlist": [
    {
      "id": "0",
      "title": "Patchwork",
      "components": [
        {
          "title": "Mini Test 1",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 2",
          "image": "mini2.jpg",
          "type": "component"
        },
        {
          "title": "Mini Test 3",
          "image": "mini1.png",
          "type": "accessory"
        },
        {
          "title": "Mini Test 4",
          "image": "mini2.jpg",
          "type": "component"
        }
      ],
      "havenHotlist": ["Tabletop Simulator", "Dave\'s House", "Fett\'s Hutt"],

      "yearPublished": 2016,
      "publishers": [
        "Mayfair Games"
      ],
      "publisherDescription": "Patchwork is a form of needlework that involves sewing together pieces of fabric into a larger design. In the past, it was a way to make use of leftover pieces of cloth to create clothing and quilts. Today, patchwork is a form of art, in which the designers use precious fabrics to create beautiful textiles. The use of uneven pieces of fabric in particular can result in real masterpieces and is therefore being practiced by a large number of textile artists. \\n To create a beautiful quilt, however, requires effort and time, but the avilable patches just do not want to fit together. So choose your patches carefully and keep a healthy supply of buttons to not only finish your quilt, but to make it better and more beautiful than your opponent‘s.",
      "designers": [
        "Uwe Rosenberg"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": 0.3,
        "complexityANS": 0,
        "complexityBIN": 2,
        "depth": 0.5,
        "depthANS": 0,
        "depthBIN": 3,
        "speed": 0.3,
        "speedANS": 0,
        "speedBIN": 2,
        "thematic": 0.1,
        "thematicANS": 0,
        "thematicBIN": 1,
        "interaction": 0.3,
        "interactionANS": 0,
        "interactionBIN": 2,
        "players": 0.3,
        "playersANS": 0,
        "playersBIN": 2,
        "mass": 0.3,
        "massANS": 0,
        "massBIN": 2,
        "obscurity": 0.3,
        "obscurityANS": 0,
        "obscurityBIN": 2
      },
      "competitionTags": [
        "Competitive"
      ],
      "mechanicTags": [
        "Tile Placement"
      ],
      "settingTags": [
        "",
        ""
      ],
      "uncertaintyTags": [
        ""
      ],
      "interactionTags": [
        "",
        ""
      ],
      "atmosphereTags": [
        ""
      ],
      "skillTags": [
        "Pattern Recognition",
        "Planning"
      ],
      "mainImage": "patchwork.png",
      "additionalImages": [
        "img1",
        "img2"
      ]
    },
    {
      "id": "3",
      "title": "Chess",
      "yearPublished": 200,
      "publishers": [
        "Unknown"
      ],
      "publisherDescription": "",
      "designers": [
        "Unknown"
      ],
      "artists": [
        "unknown artist"
      ],
      "isExpansionOf": "",
      "expansions": [""],
      "minutes": 30,
      "minPlayers": 2,
      "maxPlayers": 2,
      "idealPlayers": 2,
      "primaryCategories": {
        "complexity": "0.3",
        "complexityANS": "0",
        "complexityBIN": "2",
        "depth": "1.0",
        "depthANS": "0",
        "depthBIN": "5",
        "speed": "0.5",
        "speedANS": "0",
        "speedBIN": "3",
        "thematic": "0.2",
        "thematicANS": "0",
        "thematicBIN": "2",
        "interaction": "0.5",
        "interactionANS": "0",
        "interactionBIN": "3",
        "players": "0.2",
        "playersANS": "0",
        "playersBIN": "2",
        "mass": "0.3",
        "massANS": "0",
        "massBIN": "2",
        "obscurity": "0.05",
        "obscurityANS": "0",
        "obscurityBIN": "1"
      },
      "competitionTags": [
        "tag1",
        "tag2"
      ],
      "mechanicTags": [
        "tag1",
        "tag2"
      ],
      "settingTags": [
        "tag1",
        "tag2"
      ],
      "uncertaintyTags": [
        "tag1",
        "tag2"
      ],
      "interactionTags": [
        "tag1",
        "tag2"
      ],
      "atmosphereTags": [
        "tag1",
        "tag2"
      ],
      "skillTags": [
        "tag1",
        "tag2"
      ],
      "mainImage": "Chess.jpg",
      "additionalImages": [
        "img1",
        "img2"
      ]
    }
  ],
  "tradedGames": [
    { "game": { "title": "Patchwork", "mainImage": "https://gh-img.s3.amazonaws.com/G/patchwork.jpg" }, "lenderNumberOfCopies": 1, "lender": "Space Ghost", "borrower": "Shay", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Sagrada", "mainImage": "https://gh-img.s3.amazonaws.com/G/sagrada.jpg" }, "lenderNumberOfCopies": 1, "lender": "Shay", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" },
    { "game": { "title": "Agricola", "mainImage": "https://gh-img.s3.amazonaws.com/G/agricola.jpg" }, "lenderNumberOfCopies": 3, "lender": "D6", "borrower": "Space Ghost", "dateOfExchange": "12/25/2019", "returnDueDate": "1/25/2019" }
  ],
  "friends": [
    { "id": "1", "name": "Shay" },
    { "id": "2", "name": "Ben" },
    { "id": "3", "name": "Dave" }
  ],
  "friendsGames": [
    { "title": "Clue", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/clue.jpg" },
    { "title": "Dark Souls", "numberOfCopies": 1, "mainImage": "https://gh-img.s3.amazonaws.com/G/dark_souls.jpg" },

  ],
  "recentGames": ["9", "10"],
  "suggestedGames": ["11", "12"],
  "havensAttended": ["0", "1", "3", "4"],
  "havensOwned": ["3"],
  "bio": "I like Chess and am just getting into tabletop games. I also love burritos and tacos and ChinaStar."
}