import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class ClickTrackService {

  clicks = []

  constructor(
    @Inject('LOCALSTORAGE') private localStorage,
    private router: Router
    ) { 
      // console.log('CTService :', localStorage.getItem('clicks')) //! returns all sorts of stuff
    }

  updateClicks(newClick) {

    try {
      this.clicks = JSON.parse(localStorage.getItem('clicks'))
    } catch(e) {
      this.clicks = []
    }
    
    if (this.clicks == null) {
      this.clicks = []
    }

    let itemToSave = {
      routeWhenClickOccured: this.router.url,
      timestamp: new Date(),
      item: newClick
    }

    this.clicks.push(itemToSave)
    this.localStorage.setItem('clicks', JSON.stringify(this.clicks))
    // this.localStorage.setItem('noauthCTA', JSON.stringify(this.clicks))
  }

}

