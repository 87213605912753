import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, FormGroup } from '@angular/forms'
import { Observable } from 'rxjs'
import { startWith, map } from 'rxjs/operators'
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes'
import { TimingHelperService } from '../../services/helpers/timing-helper.service'
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'
import Fuse from 'fuse.js'
import { SnackbarService } from '../../services/user-action-feedback/snackbar.service'


@Component({
  selector: 'app-autocomplete-chiplist[_sourceOfInput]',
  templateUrl: './autocomplete-chiplist.component.html',
  styleUrls: ['./autocomplete-chiplist.component.scss']
})
export class AutocompleteChiplistComponent implements OnInit {

  @Input() _sourceOfInput: string

  items: any[] = [] // must initialize to empty array to avoid slice error when filtering

  @Input() set _items(items: any[]) {
    if (items) {
      this.items = items
    }
  }


  @Input() _itemsAreObjects = false // if true, refer to item.title rather than just item
  @Input() _label: string
  @Input() _allowDuplicates: boolean
  @Input() _allowCustomInput: boolean
  @Input() _singleInput: boolean
  @Input() _max: number = 10000 // just an arbitrary big number that is unlikely to be reached
  @Input() _defaultItemsAreRemovable = true
  @Input() _removeItemFromListOnceSelected = false
  @Input() _useFuzzySearch = false
  @Input() _fuzzySearchThreshold = 0.45

  itemsRemovedFromList = []

  visible = true
  selectable = true
  removable = true
  addOnBlur = true
  separatorKeysCodes: number[] = [ENTER, COMMA, TAB]

  itemControl = new UntypedFormControl()
  filteredItems: Observable<string[]>
  selectedItems: any[] = []

  @Input() set _selectedItems(selectedItems) {
    if (selectedItems && selectedItems != null && selectedItems[0] != null) {
      this.selectedItems = selectedItems
      this.selectionChanged.emit(this.selectedItems)

      // Note about flaggedForPreventRemoval:
      // using double negation on purpose because non-default items wont have this property, so would return incorrectly
      if (!this._defaultItemsAreRemovable) {
        for (let item of this.selectedItems) {
          item.flaggedForPreventRemoval = true
        }
      }
    }
  }

  itemsFlaggedAsProblematic
  @Input() set _itemsFlaggedAsProblematic(itemsFlaggedAsProblematic: number[]) {
    this.itemsFlaggedAsProblematic = itemsFlaggedAsProblematic
  }

  flaggedAsProblematicTooltip
  @Input() set _flaggedAsProblematicTooltip(flaggedAsProblematicTooltip: string) {
    this.flaggedAsProblematicTooltip = flaggedAsProblematicTooltip
  }

  @ViewChild('input') input: ElementRef<HTMLInputElement>
  @ViewChild('auto') matAutocomplete: MatAutocomplete

  @Output() selectionChanged = new EventEmitter()
  @Output() maxIsReachedError = new EventEmitter()
  @Output() searchText = new EventEmitter()

  fuse: any
  fuseResults: any[]

  fuseOptions = {
    threshold: this._fuzzySearchThreshold, // the lower the number the better match needed, 0-1
    keys: ['title'],
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private timingHelperService: TimingHelperService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit() {
    // TODO: should valueChanges below be unsubscribed to????
    this.filteredItems = this.itemControl.valueChanges.pipe(
      startWith(null),
      map((item: any | null) => item ? this._filter(item) : this.items.slice()))
  }

  add(event: MatChipInputEvent): void {

    // Add only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input
      const value = event.value

      // Add tag
      if (this._allowCustomInput && (value || '').trim()) {
        if (this.selectedItems.length < this._max) {
          let valueToAdd = this._itemsAreObjects ? { title: value.trim() } : value.trim()
          this.selectedItems.push(valueToAdd)
          this.selectionChanged.emit(this.selectedItems)
        }
        else {
          this.maxIsReachedError.emit()
        }
      }
      else if (!this._allowCustomInput && value != '') {
        this.snackbarService.openErrorSnackBar('Custom input is not allowed')
      }


      // Reset the input value
      if (input) {
        input.value = ''
      }

      this.itemControl.setValue(null)
    }
  }

  getCanRemoveChip(item) {
    return this.removable && !item.flaggedForPreventRemoval
  }

  remove(item: string): void {
    const index = this.selectedItems.indexOf(item)

    if (index >= 0) {
      this.selectedItems.splice(index, 1)

      if (this._removeItemFromListOnceSelected) {
        this.restoreItemToList(item)
      }
    }
    this.selectionChanged.emit(this.selectedItems)
  }

  restoreItemToList(item) {
    var indexToRemove = -1
    for (var i = 0; i < this.itemsRemovedFromList.length; i++) {
      if (item == this.itemsRemovedFromList[i]) {
        indexToRemove = i
      }
    }
    if (indexToRemove != -1) {
      this.itemsRemovedFromList.splice(indexToRemove, 1)
    }

    // This approach allows to splice without affecting the indexing of the array while still in the loop
    var i = this.items.length
    while (i--) {
      if (this.itemsRemovedFromList.includes(this.items[i])) {
        this.items.splice(i, 1)
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    let isIncluded = !this._itemsAreObjects ? this.selectedItems.includes(event.option.value) : this.selectedItems.some(e => e.title === event.option.value.title) 

    if (!this._allowDuplicates && !isIncluded) {
      if (this._singleInput && this.selectedItems.length == 1) {
        this.selectedItems = []
      }
      if (this.selectedItems.length < this._max) {
        if (this._itemsAreObjects) {
          this.selectedItems.push(event.option.value)
        }
        else {
          this.selectedItems.push(event.option.viewValue)
        }

        this.selectionChanged.emit(this.selectedItems)

        if (this._removeItemFromListOnceSelected) {
          var indexToRemove = -1
          var itemToMoveToRemovedList
          for (var i = 0; i < this.items.length; i++) {
            if (this.items[i] == event.option.value) {
              itemToMoveToRemovedList = this.items[i]
              indexToRemove = i
            }
          }
          if (indexToRemove != -1) {
            this.itemsRemovedFromList.push(itemToMoveToRemovedList)
            this.items.splice(indexToRemove, 1)
          }
        }

      }
      else {
        this.maxIsReachedError.emit()
      }
    }
    this.input.nativeElement.blur()
    this.input.nativeElement.value = ''
    this.itemControl.setValue(null)
  }

  private _filter(value: any): any[] {
    const filterValue = value.title ? value.title.toLowerCase() : value.toLowerCase() 
    var result

    if (!this._useFuzzySearch) {
      if (this._itemsAreObjects) {
        result = this.items.filter(item => item.title.toLowerCase().indexOf(filterValue) === 0)
      }
      else {
        result = this.items.filter(item => item.toLowerCase().indexOf(filterValue) === 0)
      }  
    }
    else {
      this.fuse = new Fuse(this.items, this.fuseOptions)
      result = this.fuse.search(filterValue)
    }
   

    return result
  }

  manageTypedInputForObjects(result) {
    var resultCopy = result
    result = []

    for (let itemString of resultCopy) {
      var foundItem = false
      for (let item of this.items) {
        if (resultCopy.some(e => e === item.title)) {
          foundItem = true
          result.push(item) // found match so push result
        }
      }
      if (!foundItem) {
        result.push({ title: itemString }) // did not find match, so create
      }
      return result
    }
  }

  clearSearch(event) {
    event.srcElement.value = ''
    this.itemControl.patchValue('')
  }

  getItemIsFlaggedAsProblematic(index) {
    return this.itemsFlaggedAsProblematic && this.itemsFlaggedAsProblematic.includes(index)
  }


  onKeyDown() {
    this.searchText.emit(this.itemControl.value)
    this.timingHelperService.delay(6000).then(() => {
    })
  }

}
