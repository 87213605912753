<div class="parent-container">

    <!-- <div class="row">
        <div class="col-12 text-center">
            <p>should this be a checkbox? or something else? leaving as is for now...</p>
            <button mat-raised-button class="gh-button gh-button-primary" (click)="toggleSorting()" >{{buttonText}}</button>
        </div>
    </div> -->

    <div class="row">

        <div class="col-12">
            <app-vertical-tabs [_data]="data" [_tabs]="tabs" [_contentComponent]="contentComponent"
                (tabChanged)="onTabChange($event)" [_sourceOfInput]="'app-ta-content'">
            </app-vertical-tabs>
        </div>
    </div>

</div>