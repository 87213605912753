<form [formGroup]="myForm" *ngIf="isReady">

    <div class="row">

        <div class="col-12">

            <div cdkDropList class="dragable-list" [ngClass]="{ isMobileScreen: isMobileScreen }" (cdkDropListDropped)="drop($event)">

                <div formArrayName="items" class="dragable-box" cdkDrag *ngFor="let item of selectionItems.controls; let i = index;">

                    <div class="dragable-handle" cdkDragHandle>
                        <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                            </path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </div>

                    <ng-container [formGroupName]="i">
                        <mat-form-field class="gh-form-field gh-form-field-w-90">

                            <mat-label [ngClass]="{ ghErrorLabel: submitIsClicked && item.value.selection && !item.value.selection.id }">
                                {{submitIsClicked && item.value.selection && !item.value.selection.pk ? label + ' - Custom input is not accepted' : label}}
                            </mat-label>

                            <div *ngIf="hasAutocomplete">
                                <input type="text" aria-label="Search" matInput formControlName="selection" [matAutocomplete]="auto" (keydown.enter)="$event.preventDefault()">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                    <mat-option *ngFor="let option of filteredOptions[i] | async" [value]="option">
                                        {{itemsAreObjects ? option.title : option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                            <div *ngIf="!hasAutocomplete">
                                <input type="text" aria-label="Search" matInput formControlName="selection" (keydown.enter)="$event.preventDefault()">
                            </div>

                            <mat-icon matSuffix class="gh-icon gh-icon-warning gh-icon-clickable ml-4" (click)="removeItem(i)">delete</mat-icon>

                        </mat-form-field>



                    </ng-container>
                    <!-- <img *cdkDragPreview [src]="item.value.selection.mainImageFiles[0]"> -->

                </div>
            </div>

        </div>


        <div class="col-12 text-right mr-5 mt-2">
            <button mat-fab id="add-button" [disabled]="selectionItems.length >= limit" color="ifNothingIsHereItsPinkForSomeReason" class="gh-button gh-button-primary mat-elevation-z18" matTooltip="Add Row" matTooltipPosition="above" (click)="addSelectionItem()">
                <mat-icon class="add-icon">add</mat-icon>
            </button>
        </div>

    </div>

</form>