<div *ngIf="(items$ | async)">
    <app-image-or-video-gallery-grid *ngIf="items.value.length > 0; else noData" #imageOrVideoGalleryGrid
        [_origin]="'games'" [_items]="items.value" [_type]="type"></app-image-or-video-gallery-grid>

    <ng-template #noData>
        <app-no-data style="margin-left: 42rem" [_message]="noDataMessage" [_hasActionButton]="true"
            [_actionButtonText]=" 'Add images!' " [_actionComponent]="noDataActionComponent"
            [_actionComponentTitle]="noDataActionComponentTitle"
            [_actionComponentInputData]="actionComponentInputData"></app-no-data>
    </ng-template>
</div>