import { trigger, style, transition, animate, keyframes } from '@angular/animations'

export const AddToHopperAnimations = {

    addGameItemTrigger: trigger('addGameItemAnimTrigger', [
        transition('void => *', [
            animate(500, keyframes([
                style({ width: '0', opacity: '0', transitionTimingFunction: 'ease-in-out' }),
                style({ width: '100%', opacity: '1', transitionTimingFunction: 'ease-in-out' }),
            ]))
        ])

        // IMPORTANT NOTE!!!
        // Not adding an exit animation (* => void) because each time the game list updates from autocomplete-chiplist the list is nullified and then repopulated
    ]),

}