<div *ngIf="isMobileScreenIsKnown">
    <div
        id="mobile"
        *ngIf="isMobileScreen; else desktopView"
        [ngClass]="{ isViewingSelf: isCurrentUsersProfile }"
    >
        <div class="row">
            <div *ngIf="isCurrentUsersProfile" class="col-3" (click)="editProfile()">
                <img id="profile-img" src="{{ selectedEntityImg }}" />
            </div>

            <div
                *ngIf="!isCurrentUsersProfile"
                class="col-3"
                (click)="viewProfileImage(selectedEntityImgFullSized)"
            >
                <img id="profile-img" src="{{ selectedEntityImg }}" />
            </div>

            <div class="col-9">
                <mat-card-title id="title" class="gh-sm-title"
                    >{{ isAuthenticated ? selectedEntity.title : 'You?' }}
                </mat-card-title>
                <mat-card-subtitle id="subtitle" class="gh-md-subtitle" *ngIf="!isAuthenticated"
                    >Join Today For Free!
                </mat-card-subtitle>

                <div *ngIf="selectedEntityBio != null && selectedEntityBio != ''">
                    <mat-accordion *ngIf="isAuthenticated">
                        <mat-expansion-panel
                            #expansion
                            (opened)="togglePanel()"
                            (closed)="togglePanel()"
                        >
                            <mat-expansion-panel-header style="height: 10vh">
                                <mat-panel-description *ngIf="!panelOpen">
                                    <p>{{ truncatedBio }}</p>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div style="white-space: pre-wrap !important" class="panel-content">
                                <p style="white-space: pre-wrap !important">
                                    {{ selectedEntityBio }}
                                </p>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>

        <div class="mb-2 row">
            <div class="col-12">
                <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
            </div>
        </div>
    </div>

    <ng-template #desktopView>
        <!-- <button (click)="openActionableSnackbar()">Testing Undo Snackbar</button> -->

        <mat-card id="avatar-card" class="mat-elevation-z6">
            <div class="avatar-container row">
                <div
                    *ngIf="isCurrentUsersProfile"
                    style="z-index: 1000"
                    class="col-3 edit-profile-button"
                    (click)="editProfile()"
                >
                    <img id="profile-img" [src]="selectedEntityImg" />
                </div>

                <div
                    *ngIf="!isCurrentUsersProfile"
                    style="z-index: 1000"
                    class="col-3 edit-profile-button"
                    (click)="viewProfileImage(selectedEntityImgFullSized)"
                >
                    <img id="profile-img" [src]="selectedEntityImg" />
                </div>

                <div class="col-3 title-container">
                    <div class="title-container">
                        <mat-card-title id="title" class="gh-lg-title"
                            >{{ isAuthenticated ? selectedEntity.title : 'You?' }}
                        </mat-card-title>

                        <mat-card-subtitle
                            id="subtitle"
                            class="gh-md-subtitle"
                            *ngIf="isAuthenticated && joinDate"
                            >Joined: {{ joinDate | date : 'shortDate' }}
                        </mat-card-subtitle>
                    </div>
                </div>

                <!-- <mat-card-subtitle id="subtitle" class="gh-md-subtitle" *ngIf="!isAuthenticated">Join Today For
                            Free!
                        </mat-card-subtitle> -->

                <div class="col-6">
                    <div class="bio-container" *ngIf="isAuthenticated">
                        <!-- NOTE: bio is styled globally!!! -->
                        <mat-accordion
                            id="bio"
                            [ngClass]="{
                                bioPanelClosedMyProfile: bioPanelOpened === false,
                                bioPanelOpen: bioPanelOpened === true
                            }"
                        >
                            <mat-expansion-panel
                                (opened)="bioPanelOpened = true"
                                (closed)="bioPanelOpened = false"
                            >
                                <mat-expansion-panel-header style="height: 100%">
                                    <mat-panel-description>
                                        <!-- <img id="bio-left-quote-mark"
                                                src="../../../../../assets/images/general/quotes-left.png" alt=""> -->
                                        <p>{{ truncatedBio }}</p>
                                        <!-- <img id="bio-right-quote-mark"
                                                src="../../../../../assets/images/general/quotes-right.png" alt=""> -->
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div style="white-space: pre-wrap !important" class="panel-content">
                                    <p style="white-space: pre-wrap !important">
                                        {{ selectedEntityBio }}
                                    </p>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="edit-or-contact-button-container" *ngIf="isAuthenticated; else cta">
                        <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
                    </div>

                    <ng-template #cta>
                        <div class="sign-up-button-container">
                            <button
                                mat-raised-button
                                class="gh-button gh-button-primary"
                                (click)="signUp()"
                            >
                                Sign Up!
                            </button>
                        </div>
                    </ng-template>
                </div>
            </div>
        </mat-card>
    </ng-template>

    <ng-template #actionButtons>
        <!-- <span *ngIf="!currentUser.userIsHaven">
            <span *ngIf="userLevel <= onboardingUserLevelThreshold" class="inline-span"
                matTooltip="You haven't yet completed the onboarding survey!" matTooltipPosition="above">

                <button *ngIf="!isMobileScreen" mat-raised-button (click)="openBulkRateGames()"
                    id="continue-onboarding-button" class="mr-3 gh-button gh-button-secondary">Continue
                    Onboarding Survey</button>

                <mat-icon *ngIf="isMobileScreen" class="gh-icon gh-icon-clickable gh-icon-warning wiggle-icon"
                    (click)="openBulkRateGames()">
                    error
                </mat-icon>
A
            </span>
        </span> -->

        <span class="inline-span">
            <div *ngIf="!isCurrentUsersProfile && !isGroup">
                <button
                    *ngIf="
                        !isFriend &&
                        !friendRequestData.value.friendReqeustOutToSelectedUser &&
                        !friendRequestData.value.selectedUserIsReqeustingIFriendThem
                    "
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-primary"
                    (click)="createFriendRequest()"
                >
                    --nd
                </button>

                <button
                    *ngIf="isFriend"
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-warning"
                    (click)="unFriend()"
                >
                    Un-Friend
                </button>

                <button
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-warning"
                    (click)="cancelFriendRequest()"
                    *ngIf="friendRequestData.value.friendReqeustOutToSelectedUser"
                >
                    Undo Friend Reqeust
                </button>

                <button
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-primary"
                    (click)="acceptFriendRequest()"
                    *ngIf="friendRequestData.value.selectedUserIsReqeustingIFriendThem"
                >
                    Accept Friend Request
                </button>

                <button
                    mat-raised-button
                    class="with-colored-border gh-button gh-stroked-button gh-stroked-button-warning"
                    (click)="declineFriendRequest()"
                    *ngIf="friendRequestData.value.selectedUserIsReqeustingIFriendThem"
                >
                    Decline Friend Request
                </button>

                <!-- USED FOR FUTURE FEATURE PDAC FAKSDLJFDSLKDJSFALK
                <button mat-raised-button class="m-1 p-1 gh-button gh-button-secondary" (click)="followUser()">
                    Follow
                </button>
                <button mat-raised-button class="m-1 p-1 gh-button gh-button-warning" (click)="unfollowFriend()">
                    Un-Follow
                </button> -->
            </div>
        </span>

        <!-- Want to be able to toggle the visibility of the above buttons (shouldn't see them when looking at own profile) -->
        <span
            *ngIf="!isGroup"
            class="inline-span ml-3"
            [ngStyle]="{ marginTop: isMobileScreen ? '1rem' : 0 }"
        >
            <!-- *ngIf="!isCurrentUsersProfile"  asdfdasfadsfdsfafdsf -->
            <span
                *ngIf="!hideContactButton && !isGroup"
                class="inline-span ml-3"
                [ngStyle]="{ marginTop: isMobileScreen ? '1rem' : 0 }"
            >
                <button
                    mat-raised-button
                    class="gh-button gh-button-primary"
                    (click)="contactEntity()"
                >
                    {{ contactButtonText }}
                    <mat-icon class="ml-2 gh-icon">send</mat-icon>
                </button>
            </span>
        </span>
        <span *ngIf="!currentUser.userIsHaven">
            <span
                *ngIf="isGroup"
                class="inline-span ml-3"
                [ngStyle]="{ marginTop: isMobileScreen ? '1rem' : 0 }"
            >
                <button
                    *ngIf="!isInMyGroups"
                    mat-raised-button
                    class="m-1 with-colored-border gh-button gh-stroked-button gh-stroked-button-primary"
                    (click)="joinGroup()"
                >
                    Join Group
                </button>
                <button
                    *ngIf="isInMyGroups"
                    mat-raised-button
                    class="m-1 with-colored-border gh-button gh-stroked-button gh-stroked-button-warning"
                    (click)="leaveGroup()"
                >
                    Leave Group
                </button>

                <button
                    *ngIf="userIsGroupAdmin || isGHAdmin"
                    mat-raised-button
                    class="m-1 gh-button gh-button-warning"
                    (click)="deleteGroup()"
                >
                    Delete Group
                </button>

                <button
                    *ngIf="userIsGroupAdmin || isGHAdmin"
                    mat-raised-button
                    class="m-1 gh-button gh-button-edit"
                    (click)="editGroup()"
                >
                    Edit Group
                </button>
            </span>
        </span>
    </ng-template>

    <!-- <button *ngIf="userIsGroupAdmin" mat-raised-button class="m-1 gh-button gh-button-warning"
        (click)="removeAllUsersFromGroup()">REMOVE FOLLOWERS</button> -->
</div>
