import { Component, Input, OnInit } from '@angular/core'
@Component({
  selector: 'app-gamenight-host-collection[_sourceOfInput]',
  templateUrl: './gamenight-host-collection.component.html',
  styleUrls: ['./gamenight-host-collection.component.scss']
})
export class GamenightHostCollectionComponent implements OnInit {

  @Input() _sourceOfInput: string

  // carouselConfig: NguCarouselConfig = {
  carouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 4, all: 0 },
    load: 3,
    //interval: {timing: 4000, initialDelay: 1000},
    loop: true,
    touch: true,
    velocity: 0.2,
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  }

  defaultMainImage = window.location.origin + '/assets/images/defaults/placeholder-event.png'

  requestedGames // figure out how to name this, this is games previously requested by other guests so far

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData

    try {
      this.requestedGames = JSON.parse(inputData.event.games)
    } catch (error) {
    }
  }

  gamesOfInterest

  constructor() { }

  ngOnInit(): void {
  }

  getRequestorsString(requestors) {
    // return StringHelper.createCommaSeparatedString(requestors, true)
  }

  async setGames(event) {
    this.gamesOfInterest = []

    for (let gameSelected of event.value) {

      var requestors = [
        {
          pk: this.inputData.user.pk,
          title: this.inputData.user.title
        }
      ]
      for (let gameContributedOrRequested of this.requestedGames) {
        if (gameSelected.pk == gameContributedOrRequested.pk) {

          if (gameContributedOrRequested.requestorId && gameContributedOrRequested.requestorId != this.inputData.user.pk) {

            // await this.usersDbService.getUserByGSI1(gameContributedOrRequested.requestorId).then(user => {
            //   requestors.push(
            //     {
            //       pk: gameContributedOrRequested.requestorId,
            //       title: user[0].title
            //     }
            //   )
            // })

          }
        }
      }

      this.gamesOfInterest.push({
        game: gameSelected,
        requestors: requestors
      })

    }
  }

}
