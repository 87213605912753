import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-about-me-friends-friend-detail-modal',
  templateUrl: './profile-about-me-friends-friend-detail-modal.component.html',
  styleUrls: ['./profile-about-me-friends-friend-detail-modal.component.scss']
})
export class ProfileAboutMeFriendsFriendDetailModalComponent implements OnInit {


  inputData
  @Input() set _inputData(inputData) {

    this.inputData = inputData
  }

  constructor() { }

  ngOnInit(): void {
  }

}
