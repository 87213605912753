import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
// import { APIService } from 'src/app/API.service'
import { GAMES_PAGINATION_DEFAULT_PAGE_SIZE } from '../../constants/pagination.constants'
import { iPaginationOptions } from '../../interfaces/pagination/IPaginationOptions'

@Injectable({
  providedIn: 'root'
})
export class MdService {

  private shouldUpdate = new BehaviorSubject(false)
  shouldUpdate$ = this.shouldUpdate.asObservable()

  private selectedItem = new BehaviorSubject(null)
  selectedItem$ = this.selectedItem.asObservable()

  private openMobileSidenav = new BehaviorSubject(true)
  openMobileSidenav$ = this.openMobileSidenav.asObservable()

  private mostRecentDataSet

  private paginationOptions = new BehaviorSubject({
    length: null,
    pageIndex: 0,
    pageSize: GAMES_PAGINATION_DEFAULT_PAGE_SIZE,
    previousPageIndex: 0
  })

  paginationOptions$ = this.paginationOptions.asObservable()

  private paginatorLoadMoreButtonClicked = new BehaviorSubject(false)
  paginatorLoadMoreButtonClicked$ = this.paginatorLoadMoreButtonClicked.asObservable()

  private paginatorGoToNextPage = new BehaviorSubject(false)
  paginatorGoToNextPage$ = this.paginatorGoToNextPage.asObservable()

  selectedMobileSubheaderOption = new BehaviorSubject(null)
  selectedMobileSubheaderOption$ = this.selectedMobileSubheaderOption.asObservable()

  // constructor(private api: APIService) {
  constructor() {
    // this.api.OnUpdateHavenListener.subscribe((updatedHaven: any) => {
    //   if (updatedHaven.value.data.onUpdateHaven.id == this.selectedItem.value.id) {
    //     this.selectedItem.next(updatedHaven.value.data.onUpdateHaven)
    //   }
    // })
  }

  triggerShouldUpdate() {
    this.shouldUpdate.next(true)
  }

  toggleMobileSidenav() {
    this.openMobileSidenav.next(!this.openMobileSidenav.value)
  }

  setSelectedMobileSubheaderOption(option) {
    this.selectedMobileSubheaderOption.next(option)
  }

  async updateSelectedItem(item) {
    this.selectedItem.next(item == undefined ? 'emptylist' : item)
  }

  async getGameItem(item) {
    var bulkData
    var mainImage
    var secondaryImages

    // await this.gamesDbAccessorService.getBulkData(item.pk).then(result => {
    //   bulkData = result
    // })

    // await this.gamesDbAccessorService.getMainImage(item.pk).then(result => {
    //   mainImage = result
    // })

    // await this.gamesDbAccessorService.getSecondaryImages(item.pk).then(result => {
    //   secondaryImages = result
    // })

    let gameItem = {
      bulkData: bulkData,
      mainImage: mainImage,
      secondaryImages: secondaryImages
    }
    return gameItem
  }

  updatePaginationOptions(paginationOptions: iPaginationOptions) {
    this.paginationOptions.next(paginationOptions)
  }

  updatePaginatorLoadMoreButtonClicked() {
    this.paginatorLoadMoreButtonClicked.next(true)
  }

  updatePaginatorGoToNextPage() {
    this.paginatorGoToNextPage.next(true)
  }

  setMostRecentDataSet(dataSet) {
    this.mostRecentDataSet = dataSet
  }

  getMostRecentDataSet() {
    return this.mostRecentDataSet
  }

}
