import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    SimpleChanges,
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
    DESKTOP_MODAL_PANEL_CLASS,
    DIALOG_FULL_SCREEN,
    MOBILE_MODAL_PANEL_CLASS,
} from 'src/app/app.constants'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { FindEventsViewEventDetailsComponent } from 'src/app/dashboard/dashboard-shared/components/events/find-events-view-event-details/find-events-view-event-details.component'
import { ProfileAboutMeFriendsFriendDetailModalComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile-about-me/components/profile-about-me-friends/components/profile-about-me-friends-friend-detail-modal/profile-about-me-friends-friend-detail-modal.component'
import { ProfileComponent } from 'src/app/dashboard/dashboard-shared/components/profile/profile/profile.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { MdGameDetailComponent } from 'src/app/dashboard/pages/games/pages/games-dashboard/md-components/md-game-detail/md-game-detail.component'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { ViewConventionDetailsComponent } from '../view-convention-details/view-convention-details.component'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-the-feed',
    templateUrl: './the-feed.component.html',
    styleUrls: ['./the-feed.component.scss'],
})
export class TheFeedComponent implements OnInit {
    @ViewChild('notificationList') notificationListElement: ElementRef

    inputData: any
    notifications: any[]
    isMobileScreen = false

    @Input() set _inputData(inputData: any) {
        this.notifications = inputData
        this.notifyCountChange()
    }

    @Output() notificationsCountChanged = new EventEmitter<number>()

    constructor(
        private backendApiContentService: BackendAPIContentService,
        private backendApiGamesService: BackendAPIGamesService,
        private screenSizeService: ScreenSizeService,
        private backendAPIContentService: BackendAPIContentService,
        private backendAPIUsersService: BackendAPIUsersService,

        private dialog: MatDialog,
    ) {}

    ngAfterViewInit() {
        this.scrollToTop()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['notifications'] && !changes['notifications'].firstChange) {
            // Use setTimeout to ensure this runs after view update
            setTimeout(() => this.scrollToTop(), 0)
        }
    }

    scrollToTop() {
        if (this.notificationListElement) {
            this.notificationListElement.nativeElement.scrollTop = 0
        }
    }
    user
    ngOnInit(): void {
        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            if (user) {
                this.user = user
            }
        })

        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    seeNotificationDetails(notification: Notification) {
        // Add code here to display the notification details
    }

    buttons
    submitActionDataText

    async seeEventDetails(element) {
        if (this.user.userIsHaven) {
            this.buttons = false
        } else {
            this.buttons = true
        }

        element.ulid = element.eventId

        let submitButtonText = 'Add To My Schedule'

        if (element.isInMyEvent) {
            submitButtonText = 'Remove From My Schedule'
        } else {
            element.isInMyEvent = false
        }

        let attendees = await this.backendAPIContentService.listContentItemsByPkSk(
            element.eventId,
            'e',
            100,
        )

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: element.eventTitle,

                component: FindEventsViewEventDetailsComponent,

                hasCancelButton: false,
                hasCloseButton: true,
                hasSubmitButton: false,
                submitButtonText: submitButtonText,
                // isDeletion: element.isInMyEvent, //! this caused the cancel button to disappear, I need to deal with that

                inputData: {
                    attendees: attendees, //! not in the element data package
                    // haven: { id: clickEvent.event._def.extendedProps.havenId },  //! not in the element data package
                    eventDetails: element,

                    isInMyEvent: element.isInMyEvent,
                    shouldHaveInviteButton: true,
                },

                isEditing: true,
                allowParentClose: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe(async (data) => {
                if (data) {
                    // ! *****************************************************************
                    // todo When 'remove from my schedule': 'remove' is returned
                    // todo When 'add to my schedule': 'remove' is INCORR#ECLTY returned
                    // ! *****************************************************************

                    if (data == 'new event' || data == 'add') {
                        //! GETTING THE SINGAL 'remove"
                        element.havenId = this.user.username
                        element.prefix = 'e-new-' + this.user.username

                        await this.backendAPIContentService.createCalendarItems(
                            element,
                            element.extendedProps.mainImageFiles,
                        )
                    } else {
                        // todo beware here, this accepts all other options
                        // ! need a conditional here!!!!

                        // todo beware, we are removing the attendance item here (e-new) but not deleting the actual event.
                        // !    beware, we are removing the attendance item here (e-new) but not deleting the actual event.
                        // todo beware, we are removing the attendance item here (e-new) but not deleting the actual event.

                        let input = {
                            pk: element.pk,
                            sk: 'e-new-' + this.user.username,
                        }
                        await this.backendAPIContentService.deleteContentItemByPkSk(input)
                    }
                }

                let fetchedEvents = await this.backendAPIContentService.listCalendarEventsById(
                    this.user.username,
                    249,
                )

                // this.myFetchedEvents = fetchedEvents.calendarItems

                // this.processMyEvents(this.myFetchedEvents)

                // this.dataSource = new MatTableDataSource(this.myFetchedEvents)
            })
    }

    async seeUserDetails(item) {
        let userForDetails = await this.backendAPIUsersService.getProfileById(item.addedByUID)

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'User Details',
                component: ProfileAboutMeFriendsFriendDetailModalComponent,
                inputData: { user: userForDetails },
                // inputData: { user: item }, / old version

                hasSubmitButton: true,
                hasCancelButton: true,
                submitButtonText: 'Reply',
                allowParentClose: true,
                // hasLeftActionButton: true,
                // leftActionButtonText: 'Delete Message'
            },
        })
    }

    async seeGameDetails(loggedPlayDetails) {
        console.log('seGameDetails input', loggedPlayDetails)
        let gameDetails = await this.backendApiGamesService.GetGame(loggedPlayDetails.gameId)

        let inputData = {
            limitDataFecthing: true,
            game: gameDetails,
            origin: 'modal',
        }

        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: gameDetails.title,
                component: MdGameDetailComponent,
                hasSubmitButton: false,
                hasCloseButton: true,
                hasCancelButton: false,
                inputData: inputData, //! what does limit data fetching do???
                allowParentClose: true,
            },
            maxWidth: '90%',

            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,

            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
    }

    clearAllNotifications(numberToClear) {
        let count = 0
        let notificationsToRemove: number[] = []

        for (let i = this.notifications.length - 1; i >= 0; i--) {
            if (count >= numberToClear) {
                break
            }
            let notification = this.notifications[i]
            let input = {
                pk: notification.pk,
                sk: notification.sk,
            }
            this.backendApiContentService.deleteContentItemByPkSk(input)
            notificationsToRemove.push(i)
            count++
        }

        // Remove the notifications from the array
        for (let i of notificationsToRemove) {
            this.notifications.splice(i, 1)
        }

        // Notify the header about the change in the number of notifications
        this.notifyCountChange()
    }

    private notifyCountChange() {
        this.notificationsCountChanged.emit(this.notifications.length)
    }

    async openConventionDetails(notificaiton) {
        console.log('111 notification', notificaiton)
        console.log('111 this.user', this.user)

        let bob = await this.backendAPIContentService.getContentItemByPkSk(
            notificaiton.conventionId,
            'e',
        )

        console.log('bob', bob)
        let seeDetailsInput = {
            convention: bob,
            currentUser: this.user,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            backdropClass: 'gh-dialog-backdrop',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : [DESKTOP_MODAL_PANEL_CLASS, DIALOG_FULL_SCREEN],
            disableClose: true,
            data: {
                title: notificaiton.conventionTitle + ' Details',
                convention: notificaiton,
                component: ViewConventionDetailsComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                hasCloseButton: true,
                inputData: seeDetailsInput,
                allowParentClose: true,
                isFullScreen: true,
            },
            height: '70vh',
            width: '99%',
            autoFocus: false,
        })
    }
}
