<p>temp-haven-components works!</p>

<div class="row">
    <div class="col-6">
        <app-gh-state-input [_defaultState]="state" (stateChangedEmitter)="onStateChanged($event)">
        </app-gh-state-input>
    </div>
    <!-- <div class="col-6">
        <button (click)="fetchHavensByState()">FETCH HAVENS FOR SPECIFIC ZIP CODE</button>
    </div> -->
    <div class="col-6">
        <button (click)="fetchLPbyCurrentHaven()">FETCH LP for Selected Haven</button>
    </div>
    <div class="col-3">
        <mat-form-field class="gh-form-field">
            <mat-label>Select HavenId:</mat-label>
            <mat-select formControlName="game" (selectionChange)="setHavenId($event)">
                <mat-option *ngFor="let option of havenOptions" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-form-field class="gh-form-field">
            <mat-label>Select Haven Title:</mat-label>
            <mat-select formControlName="haven" (selectionChange)="setHavenTitle($event)">
                <mat-option *ngFor="let option of havenTitleOptions" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-form-field class="gh-form-field">
            <mat-label>Select GameId:</mat-label>
            <mat-select formControlName="game" (selectionChange)="setGameId($event)">
                <mat-option *ngFor="let option of gameOptions" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-2">
        <mat-form-field class="gh-form-field">
            <mat-label>Select Type:</mat-label>
            <mat-select formControlName="game" (selectionChange)="setType($event)">
                <mat-option *ngFor="let option of typeOptions" [value]="option">
                    {{option}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-2">
        <button (click)="createHavenDemoItem()">Create Haven Demo Item</button>
    </div>
</div>