import {
    Component,
    ComponentFactoryResolver,
    Inject,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { StringHelperService } from '../../services/helpers/string-helper.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { GhButtonColor } from '../../enums/gh-button-enums'
import { TimingHelperService } from '../../services/helpers/timing-helper.service'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-gh-dialog-wrapper',
    templateUrl: './gh-dialog-wrapper.component.html',
    styleUrls: ['./gh-dialog-wrapper.component.scss'],
})
export class GhDialogWrapperComponent implements OnInit {
    truncationValue = 50

    blurContent = false

    @ViewChild('viewContainer', { read: ViewContainerRef }) viewContainer

    allowParentClose = true
    cancelButtonText = 'Cancel'
    dialogContentComponent
    hasCloseButton
    inputData
    hasSubmitButton
    hasCancelButton
    hasLeftActionButton = false
    hasInfoSnippet = false
    infoSnippetText
    hasDeleteButton = false
    submitButtonText = 'Submit'
    isDeletion = false
    leftActionButtonText
    deleteButtonText
    title
    topLeftIcon
    topLeftIconColor
    isAdminModal = false
    hideActionButtons = false
    hasToolbar = true
    isFullScreen = false

    component

    constructor(
        private dialogRef: MatDialogRef<GhDialogWrapperComponent>,
        private screenSizeService: ScreenSizeService,
        private resolver: ComponentFactoryResolver,
        private stringHelperService: StringHelperService,
        private timingHelperService: TimingHelperService,

        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.title = data.title
        this.dialogContentComponent = data.component
        this.inputData = data.inputData
        this.hasSubmitButton = data.hasSubmitButton
        this.hasCancelButton = data.hasCancelButton

        if (data.isAdminModal) {
            this.isAdminModal = data.isAdminModal
        }

        if (data.isAdminModal) {
            this.isAdminModal = data.isAdminModal
        }

        if (data.hideActionButtons) {
            this.hideActionButtons = true
        }

        if (data.hasLeftActionButton) {
            this.hasLeftActionButton = true
            this.leftActionButtonText = data.leftActionButtonText
        }

        if (data.hasInfoSnippet) {
            this.hasInfoSnippet = true
            this.infoSnippetText = data.infoSnippetText
        }

        if (data.hasDeleteButton) {
            this.hasDeleteButton = true
            this.deleteButtonText = data.deleteButtonText
        }

        if (data.submitButtonText) {
            this.submitButtonText = data.submitButtonText
        }

        if (data.cancelButtonText) {
            this.cancelButtonText = data.cancelButtonText
        }

        if (data.hasCloseButton) {
            this.hasCloseButton = data.hasCloseButton
        }

        if (data.topLeftIcon) {
            this.topLeftIcon = data.topLeftIcon
        }

        if (data.topLeftIconColor) {
            this.topLeftIconColor = data.topLeftIconColor
        }

        if (data.isDeletion) {
            this.isDeletion = data.isDeletion
        }

        if (data.hasToolbar) {
            this.hasToolbar = data.hasToolbar.value
        }

        //! IMPORTANT: since we are checking a boolean value, and the conditional will return false if the value is either FALSE or UNDEFINED, need to check this differently.
        //!       is there a better way to do this? I think there probably is a better way but not spending time on it now since this works good enough for now
        if (!data.allowParentClose || data.allowParentClose == false) {
            this.allowParentClose = false
        }

        if (data.isFullScreen) {
            this.updateSize()
        }
    }

    isMobileScreen = false
    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
                this.truncationValue = this.isMobileScreen ? 40 : 50
            })
    }

    updateSize() {
        this.dialogRef.updateSize('300%', '99%')
    }

    viewContainerSet = false
    ngAfterViewChecked() {
        if (!this.viewContainerSet) {
            if (this.viewContainer) {
                this.viewContainerSet = true
                //this.viewContainer.clear()
                this.component = this.viewContainer.createComponent(
                    this.resolver.resolveComponentFactory(this.dialogContentComponent),
                )

                if (this.inputData) {
                    this.component.instance._inputData = this.inputData
                }

                if (this.component.instance.closeEmitter) {
                    this.component.instance.closeEmitter
                        .pipe(untilDestroyed(this))
                        .subscribe((data) => {
                            this.close(data)
                        })
                }

                if (this.component.instance.blurContentEmitter) {
                    this.component.instance.blurContentEmitter
                        .pipe(untilDestroyed(this))
                        .subscribe((data) => {
                            this.blurContent = data
                        })
                }
            }

            if (this.component.instance.hideParentWrapperButtonsEmitter) {
                this.component.instance.hideParentWrapperButtonsEmitter
                    .pipe(untilDestroyed(this))
                    .subscribe((data) => {
                        this.hideActionButtons = data
                    })
            }
        }
    }

    truncate(input: string, maxCharacters: number) {
        return this.stringHelperService.truncateString(input, maxCharacters)
    }

    refreshInputData() {
        this.component.instance._inputData = this.inputData
    }

    @ViewChild('submitButton', { static: true }) submitButton
    get GhButtonColor() {
        return GhButtonColor
    }

    submitIsClicked = false // temp fix to prevent double clicking until we can implement gh-button with callback info
    submit() {
        //! This is getting activated, need to catch this information in the child and close / reopen new

        if (!this.submitIsClicked) {
            this.submitIsClicked = false
            this.component.instance._parentSubmitClicked = true

            this.timingHelperService.delay(3000).then(() => {
                this.submitIsClicked = false
            })
        }
    }

    close(data?) {
        if (this.allowParentClose) {
            this.dialogRef.close(data)
        } else {
            this.component.instance._parentCloseClicked = true
        }
    }

    onLeftActionButtonClicked() {
        this.component.instance._parentLeftActionButtonClicked = true
    }

    onDeleteButtonClicked() {
        this.component.instance._parentDeleteButtonClicked = true
    }
}
