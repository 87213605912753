import { Component, Inject, OnInit } from '@angular/core'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { ulid } from 'ulid'

@UntilDestroy()
@Component({
    selector: 'app-create-shared-library-item',
    templateUrl: './create-shared-library-item.component.html',
    styleUrls: ['./create-shared-library-item.component.scss'],
})
export class CreateSharedLibraryItemComponent implements OnInit {
    gameSelected: boolean = false // Tracks if at least one game is selected
    selectedGames: any[] = [] // Stores all selected games, each with its own notes
    coreGames = new BehaviorSubject<any[]>([])
    coreGames$ = this.coreGames.asObservable()
    gameToEdit: any

    currentUser: any
    inputData: any

    constructor(
        private backendAPIUsersService: BackendAPIUsersService,
        private gamesService: GamesService,
        private backendAPIContentService: BackendAPIContentService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CreateSharedLibraryItemComponent>,
    ) {}

    async ngOnInit() {
        // Subscribe to the current user
        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((currentUser) => {
                if (currentUser) {
                    this.currentUser = currentUser
                }
            })

        this.inputData = this.data.inputData.parentInputData

        // Subscribe to the list of core games
        await this.gamesService.coreGames$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreGames) => {
                if (coreGames) {
                    // Filter out expansions
                    const filtered = coreGames.filter((obj) => !obj.isExpansion)
                    this.coreGames.next(filtered)
                }
            })
    }

    // Function to handle game selection
    selectGame(games: any[]): void {
        // Ensure each selected game has a notes field
        this.selectedGames = games.map((game) => {
            return { ...game, notes: game.notes || '' }
        })
        this.gameSelected = this.selectedGames && this.selectedGames.length > 0
    }

    // Check if the form is valid
    isFormValid(): boolean {
        return this.gameSelected
    }

    // Form submission logic
    async submitForm() {
        if (this.isFormValid()) {
            for (const game of this.selectedGames) {
                const newUlid = ulid()
                const createInput: any = {
                    conventionId: this.inputData.convention.pk.toString(),
                    createdByTitle: this.currentUser.title,
                    createdByUID: this.currentUser.username,
                    currentPlayers: '',
                    name: game.title,
                    notes: game.notes, // use the per-game notes
                    pk: this.inputData.convention.pk.toString(),
                    sk: 'sl_' + newUlid,
                    gameTitle: game.title,
                    gameId: game.pk,
                }
                await this.backendAPIContentService.createSharedLibraryItem(createInput)
            }

            this.dialogRef.close(this.selectedGames)
        }
    }
}
