import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'gamePrintStatus'
})
export class GamePrintStatusPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 0:
      case 'inDevelopement':
        return 'In Developement'

      case 1:
      case 'available':
        return 'Available'

      case 2:
      case 'outOfPrint':
        return 'Out of Print'

      case 3:
        case 'na':
          return 'Unknown / Clear'
    
    }

  }

}
