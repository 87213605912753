import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { HopperService } from '../../../services/hopper.service'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-join-hopper-group',
  templateUrl: './join-hopper-group.component.html',
  styleUrls: ['./join-hopper-group.component.scss']
})
export class JoinHopperGroupComponent implements OnInit {

  user

  possiblePlayers = []
  selectedPlayers = []

  form: UntypedFormGroup

  isGameSpecific = false
  specificGame = null
  @Input() set _inputData(inputData) {
    this.isGameSpecific = inputData.isGameSpecific
    this.specificGame = inputData.specificGame
  }

  selectedHopperItem: any
  @Input() set _selectedHopperItem(selectedHopperItem) { // create interface!
    if (selectedHopperItem) {
      this.selectedHopperItem = selectedHopperItem

      this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
        this.user = user
      })

      this.form = this.formBuilder.group({
        admin: [this.user, [Validators.required]],
        message: [''],
        game: [this.selectedHopperItem.game],
        playersToJoin: [null, [Validators.required]]
      })

      this.selectedPlayers = [this.user]

      // should this be a list of people at the event rather than friends?  probably...
      // REPLACE THIS WITH USERS DB SERVICE BASED ON CURRENT USERS FRIENDS
      // this.friendsDbService.getAllFriends().then(friends => {
      //   this.possiblePlayers = friends
      // })

    }
  }

  @Output() onClose = new EventEmitter()
  @Output() submitEmitter = new EventEmitter()

  constructor(private backendAPIUsersService: BackendAPIUsersService,
    private snackbar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    private hopperService: HopperService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {

  }

  close() {
    this.onClose.emit()
  }

  addPlayersToGroup(event) {
    this.selectedPlayers = event
    this.form.controls.playersToJoin.patchValue(event)
  }

  onMaxReachedError() {
    this.snackbarService.openErrorSnackBar('You\'ve reached the maximum players that ' + this.hopperService.getGroupName(this.form.controls.playersToJoin.value) + ' can accept for ' + this.form.get('game').value.title)
  }

  submit() {
    if (!this.form.valid) {
      // NOTE: these errors should be specific to the reason that the form is invalid
      this.snackbarService.openErrorSnackBar('Please selected at least one game!')
    }
    else {
      this.submitEmitter.emit({
        form: this.form,
        itemToJoin: this.selectedHopperItem
      })
    }
  }

  getGroupName(members) {
    return this.hopperService.getGroupName(members)
  }

}
