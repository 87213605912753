<span class="inline-span">
    <button mat-icon-button id="clear-button" [matTooltip]="'Clear'" matTooltipPosition="above" (click)="clear()">
        <mat-icon>
            close
        </mat-icon>
    </button>
</span>

<span class="inline-span star-icons-container">
    <button class="star-icon-button" mat-icon-button [color]="color" *ngFor="let ratingId of possibleRatings;index as i" [id]="'star_'+i"
        (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
        <mat-icon>
            {{showIcon(i)}}
        </mat-icon>
    </button>

</span>




 


<!-- TODO: trying to create this component with svg rather than material icons. see URL below: -->
<!-- TODO:    https://dev.to/ngconf/how-to-use-angular-material-s-mat-icon-with-local-svg-images-22p8   -->

<!-- <button mat-icon-button [color]="color" *ngFor="let ratingId of possibleRatings;index as i" [id]="'star_'+i"
    (click)="onClick(i+1)" [matTooltip]="ratingId+1" matTooltipPosition="above">
    <mat-icon>
        {{showIcon(i)}}
    </mat-icon>
</button>  -->