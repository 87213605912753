<!-- * MOBILE VIEW -->
<div *ngIf="isMobileScreen; else desktopView">

    <div class="row m-0 restore-items-button-mobile-container mt-3">

        <!-- ! BUG: add to hopper button not showing for havens mobile component -->
        <span class="inline-span" *ngIf="userCanAddItems">
            <button id="toggle-button-closed" type="button" mat-button class="gh-button gh-button-secondary"
                (click)="toggleLeftDrawer()">
                <mat-icon>add</mat-icon>
            </button>
            <button id="toggle-button-open" type="button" mat-button class="gh-button gh-button-primary"
                (click)="toggleLeftDrawer()">
                <mat-icon>close</mat-icon>
            </button>
        </span>

        <span class="inline-span restore-items-button-span">
            <button type="button" mat-button id="restore-items-button" class="gh-button gh-button-primary"
                *ngIf="hiddenItems.length  != 0" (click)="restoreHiddenItems()">Restore Hidden Items</button>
        </span>

    </div>

    <div class="row m-0 accordion-container" [ngStyle]="{ maxHeight: getMaxMobileAccordionHeight() }"
        [ngClass]="{ paddingX: mobileScreenOverride }">
        <mat-accordion class="gh-accordion">
            <!-- <mat-expansion-panel *ngFor="let e of events; let i = index" [expanded]="false" (opened)="onPanelOpened(i)" -->

            <div *ngFor="let element of dataSource.filteredData; let i = index">
                <mat-expansion-panel [expanded]="false" *ngIf="!getItemIsHidden(dataSource.filteredData[i])"
                    (opened)="onPanelOpened(i)" (closed)="onPanelClosed(i)"
                    [ngClass]="{ selected: selectedIndex == i, stripe: i % 2 == 0, noElevation: mobileScreenOverride }">

                    <mat-expansion-panel-header>

                        <mat-panel-title>
                            <div class="row">
                                <div class="col-6">
                                    <img class="game-img" src="{{element.game.mainImage}}" alt=""
                                        *ngIf="!isGameSpecific">
                                    <img class="game-img" src="{{element.haven.mainImage}}" alt=""
                                        *ngIf="isGameSpecific && element.haven">
                                    <p *ngIf="element.haven && !element.haven.mainImage">{{element.haven.title}}</p>
                                </div>

                                <div class="col-6">
                                    <p class="pt-4"
                                        matTooltip="{{getGroupName(element.group.members).length > 20 ? getGroupName(element.group.members) : ''}}"
                                        matTooltipPosition="above">{{truncate(getGroupName(element.group.members), 20)}}
                                    </p>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-12">
                                    <div class="players-needed-bar-container" *ngIf="!isGameSpecific">
                                        <app-players-needed-bar-old
                                            [_currentPlayerCount]="element.game.currentPlayerCount"
                                            [_gameMin]="element.game.minPlayers" [_gameMax]="element.game.maxPlayers"
                                            [_sourceOfInput]="'app-hopper[_sourceOfInput]'"
                                            [_communityMin]="element.game.votedBestMaxPlayers"
                                            [_communityMax]="element.game.minPlayers"
                                            [_customLowerBound]="element.game.hasCustomBounds ? element.game.customLowerBound : null"
                                            [_customUpperBound]="element.game.hasCustomBounds ? element.game.customUpperBound : null"
                                            [_desiredIndices]="element.desiredIndices">
                                        </app-players-needed-bar-old>
                                    </div>
                                    <div class="players-needed-bar-container" *ngIf="isGameSpecific">
                                        <app-players-needed-bar-old
                                            [_currentPlayerCount]="specificGame.currentPlayerCount"
                                            [_gameMin]="specificGame.minPlayers" [_gameMax]="specificGame.maxPlayers"
                                            [_sourceOfInput]="'app-hopper[_sourceOfInput2]'"
                                            [_communityMin]="specificGame.votedBestMaxPlayers"
                                            [_communityMax]="specificGame.minPlayers"
                                            [_customLowerBound]="specificGame.hasCustomBounds ? specificGame.customLowerBound : null"
                                            [_customUpperBound]="specificGame.hasCustomBounds ? specificGame.customUpperBound : null"
                                            [_desiredIndices]="element.desiredIndices">
                                        </app-players-needed-bar-old>
                                    </div>
                                </div>
                            </div>

                        </mat-panel-title>

                    </mat-expansion-panel-header>

                    <div class="panel-content">

                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-card-subtitle>
                                            Competition Level:
                                        </mat-card-subtitle>
                                    </div>
                                    <div class="col-12 negative-margin-top">
                                        <div>
                                            {{element.competitionLevel.toString().replace(',', ', ')}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-card-subtitle>
                                            Time Posted:
                                        </mat-card-subtitle>
                                    </div>
                                    <div class="col-12 negative-margin-top">
                                        <div class="time-posted-container">
                                            <p
                                                [ngClass]="{ newItem: getIsNewItem(element.postedTimestamp), oldItem: getIsOldItem(element.postedTimestamp) }">
                                                <span
                                                    *ngIf="element.postedTimestamp != 0">{{getMinutesSincePosted(element.postedTimestamp)}}
                                                    min
                                                    ago
                                                </span>
                                                <span *ngIf="element.postedTimestamp == 0">Just now </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <mat-card-subtitle>
                                    Comments:
                                </mat-card-subtitle>
                            </div>
                            <div class="col-12 negative-margin-top">
                                <p>{{element.comments}}</p>
                            </div>
                        </div>

                    </div>

                    <mat-action-row class="action-row ml-3">
                        <span class="inline-span ml-3">
                            <mat-icon *ngIf="element.group && user && element.group.admin.title == user.title"
                                class="gh-icon gh-icon-edit gh-icon-clickable" (click)="openEditItem(element)">
                                edit
                            </mat-icon>
                        </span>
                        <span class="inline-span ml-3">
                            <mat-icon *ngIf="element.group && user && element.group.admin.title == user.title"
                                class="gh-icon gh-icon-warning gh-icon-clickable"
                                (click)="deleteItem(element)">person_remove
                            </mat-icon>
                        </span>
                        <span class="inline-span ml-3">
                            <mat-icon *ngIf="element.group && user && element.group.admin.title != user.title"
                                class="gh-icon gh-icon-warning gh-icon-clickable" (click)="hideItem(element)">
                                mood_bad
                            </mat-icon>
                        </span>
                    </mat-action-row>

                </mat-expansion-panel>
            </div>

        </mat-accordion>
    </div>

</div>


<!-- * DESKTOP VIEW -->
<ng-template #desktopView>

    <mat-drawer-container [ngClass]="{ grayBackground: isGrayBackground }">
        <mat-drawer #leftDrawer mode="side">
            <app-add-to-hopper [_initialData]="initialAddToHopperData" [_inputData]="inputData"
                [_matchesFound]="matchesFound" (possibleGameMatchEmitter)="onPossibleGameMatches($event)"
                (submitEmitter)="addOrEditItem($event)" (clearPossibleMatchesEmitter)="clearPossibleMatches()"
                (joinGroupEmitter)="onJoinGroupFromAddToHopper($event)">
            </app-add-to-hopper>
        </mat-drawer>

        <mat-drawer #rightDrawer mode="side" position="end">
            <app-join-hopper-group [_selectedHopperItem]="selectedHopperItem" [_inputData]="inputData"
                (onClose)="closeRightDrawer()" (submitEmitter)="joinUsersToItem($event)">
            </app-join-hopper-group>
        </mat-drawer>

        <div class="sidenav-content" [ngClass]="{ leftDrawerIsOpen: leftDrawerIsOpen }">

            <span class="inline-span" *ngIf="userCanAddItems">
                <button id="toggle-button-closed" type="button" mat-button class="gh-button gh-button-secondary"
                    (click)="toggleLeftDrawer()">
                    <mat-icon>add</mat-icon>
                </button>
                <button id="toggle-button-open" type="button" mat-button class="gh-button gh-button-primary"
                    (click)="toggleLeftDrawer()">
                    <mat-icon>close</mat-icon>
                </button>
            </span>

            <span class="inline-span restore-items-button-span">
                <button type="button" mat-button id="restore-items-button" class="gh-button gh-button-primary"
                    *ngIf="hiddenItems.length  != 0" (click)="restoreHiddenItems()">Restore Hidden Items</button>
            </span>

            <div class="table-container" *ngIf="dataSource && dataSource.filteredData.length > 0 && displayedColumns">

                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                    <!-- GROUP -->
                    <ng-container matColumnDef="group">
                        <th mat-header-cell *matHeaderCellDef> Players </th>
                        <td mat-cell *matCellDef="let element">
                            <p class="pt-3"
                                matTooltip="{{getGroupName(element.group.members).length > 20 ? getGroupName(element.group.members) : ''}}"
                                matTooltipPosition="above">{{truncate(getGroupName(element.group.members), 20)}} </p>
                        </td>
                    </ng-container>

                    <!-- GAMES (optional column) -->
                    <ng-container *ngIf="showGameColumn()" matColumnDef="game">
                        <th mat-header-cell *matHeaderCellDef> Game </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="games-item">
                                <span [ngClass]="{ flaggedForMatch: element.isFlaggedForMatch }">
                                    <span class="pt-3"
                                        matTooltip="{{element.game.title.length > 20 ? element.game.title : ''}}"
                                        matTooltipPosition="above">
                                        <span *ngIf="getIsInUserHotlist(element.game); else notInHotlist"
                                            class="hotlist-item"><img class="item-img" src="{{element.game.mainImage}}"
                                                alt="">
                                            <div class="fire">
                                                <div class="fireParticle" *ngFor="let particle of fireParticles"></div>
                                            </div>
                                        </span>

                                        <ng-template #notInHotlist>
                                            <img class="item-img" src="{{element.game.mainImage}}" alt="">
                                        </ng-template>

                                    </span>
                                </span>

                                <!-- <span *ngIf="getIsInUserHotlist(element.game)" class="inline-span hotlist-item">(in hotlist)
                      <div class="fire">
                        <div class="fireParticle" *ngFor="let particle of fireParticles"></div>
                      </div>
                    </span> -->
                            </div>
                        </td>
                    </ng-container>

                    <!-- HAVENS (optional column) -->
                    <ng-container *ngIf="showHavenColumn()" matColumnDef="haven">
                        <th mat-header-cell *matHeaderCellDef> Haven </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="games-item">
                                <span [ngClass]="{ flaggedForMatch: element.isFlaggedForMatch }">
                                    <span class="pt-3"
                                        *ngIf="element.haven && !element.haven.thumbImgPath; else havenThumb"
                                        matTooltip="{{element.haven && element.haven.title.length > 20 ? element.haven : ''}}"
                                        matTooltipPosition="above">
                                        {{truncate(element.haven.title, 20)}}


                                        <!-- <span *ngIf="getIsInUserHavenHotlist(element.haven)"
                        class="inline-span hotlist-item">{{truncate(element.haven, 20)}}
                        <div class="fire">
                          <div class="fireParticle" *ngFor="let particle of fireParticles"></div>
                        </div>
                      </span> -->

                                    </span>
                                    <ng-template #havenThumb>
                                        <img class="item-img" src="{{element.haven.thumbImgPath}}" alt="">
                                    </ng-template>

                                </span>

                                <!-- <span *ngIf="getIsInUserHotlist(element.game)" class="inline-span hotlist-item">(in hotlist)
                      <div class="fire">
                        <div class="fireParticle" *ngFor="let particle of fireParticles"></div>
                      </div>
                    </span> -->
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="playersNeeded">
                        <th mat-header-cell *matHeaderCellDef> Player Count </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="players-needed-bar-container" *ngIf="!isGameSpecific">
                                <app-players-needed-bar-old [_currentPlayerCount]="element.game.currentPlayerCount"
                                    [_gameMin]="element.game.minPlayers" [_gameMax]="element.game.maxPlayers"
                                    [_sourceOfInput]="'app-hopper[_sourceOfInput3]'"
                                    [_communityMin]="element.game.votedBestMaxPlayers"
                                    [_communityMax]="element.game.minPlayers"
                                    [_customLowerBound]="element.game.hasCustomBounds ? element.game.customLowerBound : null"
                                    [_customUpperBound]="element.game.hasCustomBounds ? element.game.customUpperBound : null"
                                    [_desiredIndices]="element.desiredIndices">
                                </app-players-needed-bar-old>
                            </div>
                            <div class="players-needed-bar-container" *ngIf="isGameSpecific">
                                <h5>players neede dbar!</h5>
                                <app-players-needed-bar-old [_currentPlayerCount]="element.game.currentPlayerCount"
                                    [_gameMin]="element.game.minPlayers" [_gameMax]="element.game.maxPlayers"
                                    [_sourceOfInput]="'app-hopper[_sourceOfInput4]'"
                                    [_communityMin]="element.game.votedBestMaxPlayers"
                                    [_communityMax]="element.game.minPlayers"
                                    [_customLowerBound]="element.game.hasCustomBounds ? element.game.customLowerBound : null"
                                    [_customUpperBound]="element.game.hasCustomBounds ? element.game.customUpperBound : null"
                                    [_desiredIndices]="element.desiredIndices">
                                </app-players-needed-bar-old>
                            </div>
                        </td>
                    </ng-container>

                    <!-- COMPETITION LEVELS -->
                    <ng-container matColumnDef="competition">
                        <th mat-header-cell *matHeaderCellDef> Competition Level </th>
                        <td mat-cell *matCellDef="let element"> {{element.competitionLevel.toString().replace(',', ',
                            ')}} </td>
                    </ng-container>


                    <!-- COMMENTS -->
                    <ng-container matColumnDef="comments">
                        <th mat-header-cell *matHeaderCellDef> Comments </th>
                        <td mat-cell *matCellDef="let element">
                            <p class="pt-3" matTooltip="{{element.comments.length > 50 ? element.comments : ''}}"
                                matTooltipPosition="above">
                                {{truncate(element.comments, 50)}} </p>
                        </td>
                    </ng-container>

                    <!-- MINUTES SINCE POSTED -->
                    <ng-container matColumnDef="minutesSincePosted" *ngIf="showMinutesSinceColumn()">
                        <th mat-header-cell *matHeaderCellDef> Time Posted</th>
                        <td mat-cell *matCellDef="let element"
                            [ngClass]="{ newItem: getIsNewItem(element.postedTimestamp), oldItem: getIsOldItem(element.postedTimestamp) }">
                            <span
                                *ngIf="element.postedTimestamp != 0">{{getMinutesSincePosted(element.postedTimestamp)}}
                                min ago
                            </span>
                            <span *ngIf="element.postedTimestamp == 0">Just now </span>
                        </td>
                    </ng-container>

                    <!-- JOIN -->
                    <ng-container matColumnDef="join">
                        <th mat-header-cell *matHeaderCellDef> Join </th>
                        <td mat-cell *matCellDef="let element; index as i" class="icon-column">
                            <span class="inline-span">
                                <mat-icon class="gh-icon gh-icon-primary gh-icon-clickable"
                                    *ngIf="!getCurrentUserIsInMembers(element)"
                                    [ngClass]="{ flaggedForMatchButton: element.isFlaggedForMatch }"
                                    (click)="openRightDrawer(i)" [ngClass]="{  disabled: leftDrawerIsOpen }">person_add
                                </mat-icon>
                            </span>
                            <span class="inline-span">
                                <mat-icon
                                    *ngIf="element.group && user && element.group.admin.title != user.title && getCurrentUserIsInMembers(element)"
                                    class="gh-icon gh-icon-primary gh-icon-clickable"
                                    [ngClass]="{  ghIconClickableDisabled: leftDrawerIsOpen || rightDrawerIsOpen }"
                                    [ngClass]="{ oldItem: true}" (click)="removeUserFromRow(element)"
                                    matTooltip="Remove me" matTooltipPosition="above">person_remove
                                </mat-icon>
                            </span>
                        </td>
                    </ng-container>

                    <!-- ACTION BUTTONS -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="icon-column">
                            <span class="inline-span">
                                <mat-icon *ngIf="element.group && user && element.group.admin.title == user.title"
                                    class="gh-icon gh-icon-edit gh-icon-clickable"
                                    [ngClass]="{  ghIconClickableDisabled: leftDrawerIsOpen || rightDrawerIsOpen }"
                                    (click)="openEditItem(element)" matTooltip="Edit" matTooltipPosition="above">
                                    edit
                                </mat-icon>
                            </span>
                            <span class="inline-span">
                                <mat-icon *ngIf="element.group && user && element.group.admin.title == user.title"
                                    class="gh-icon gh-icon-warning gh-icon-clickable"
                                    [ngClass]="{  ghIconClickableDisabled: leftDrawerIsOpen || rightDrawerIsOpen }"
                                    (click)="deleteItem(element)" matTooltip="Delete"
                                    matTooltipPosition="above">person_remove
                                </mat-icon>
                            </span>
                            <span class="inline-span">
                                <mat-icon *ngIf="element.group && user && element.group.admin.title != user.title"
                                    class="gh-icon gh-icon-warning gh-icon-clickable"
                                    [ngClass]="{  ghIconClickableDisabled: leftDrawerIsOpen || rightDrawerIsOpen }"
                                    (click)="hideItem(element)" matTooltip="Hide Item: I'm not interested"
                                    matTooltipPosition="above">
                                    mood_bad
                                </mat-icon>
                            </span>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <!-- !disabling the animation for now, need to make it work on single items and not the entire list when it refreshes -->
                    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
              [@addItemAnimTrigger]="addItemAnimState" [ngClass]="{ selectedRow: selectedHopperItemIndex == i }"></tr> -->

                    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
                        [ngClass]="{ selectedRow: selectedHopperItemIndex == i, hiddenRow: getItemIsHidden(dataSource.filteredData[i]) }">
                    </tr>
                </table>
            </div>

        </div>

    </mat-drawer-container>
</ng-template>