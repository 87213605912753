import { Injectable } from '@angular/core'
import gql from 'graphql-tag'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { Auth } from 'aws-amplify'
import { iCreateOrUpdateHavenInput, iDeleteHavenInput, iSuggestHavenInfo } from '../../../../common/interfaces/HavenInterfaces'
import { environment } from 'src/environments/environment'
import { BackendApiS3Service } from './backend-api-s3.service'
import { ImageMultiSizeService } from '../dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import { BackendAPIGamesService } from './backend-api-games.service'
import { uploadImageFile } from './s3-file-uploader'
import { iAddToMyHavensInput, iRemoveFromMyHavensInput } from '../../../../common/interfaces/GameTrackingInterfaces'
import { S3ContentHelperService } from '../dashboard/dashboard-shared/services/helpers/s3-content-helper.service'

@Injectable({
  providedIn: "root"
})
export class BackendAPIHavensService {

  constructor(
    private backendApiS3Service: BackendApiS3Service,
    private imageMultiSizeService: ImageMultiSizeService,
    private backendApiGamesService: BackendAPIGamesService,
    private s3ContentHelperService: S3ContentHelperService
  ) { }

  private appSyncClient = new AWSAppSyncClient({
    url: environment.apiUrl,
    region: 'us-east-1',
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => {
        const token = (await Auth.currentSession()).getIdToken().getJwtToken()
        return token
      }
    },
    disableOffline: true,
  })


  async CreateHaven(input: iCreateOrUpdateHavenInput, files) {

    let result = []

    input.mainImageFiles = await this.s3ContentHelperService.prepareMainImageFiles(files)

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation createHaven($input: IGQL_CreateOrUpdateHavenInput!) {
            createHaven (input: $input) {
              addedByUID
              id
              title
              description
              address
              zipCode
              state
              isPublic
              mainImage
              additionalImages
              hours
              havenType
              dateEstablished
              isOfficial
              radarAttributes
              isOnline
              admins
              tables
              hopperData
              eventsTimeInterval
              activityStatus
              createdAt
              updatedAt
              userWhoUpdated
              havenWebsite
              havenAdmin
              isOfficial
              newId
              otherHavenAdmin
            }
          }
        `,
      variables: { input }
    })
    result.push(response.data.createHaven)
    return result
  }

  async listHavensByTitle(titleStartsWith: string, limit: number) {

    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listHavensByTitle($titleStartsWith: String!, $limit: Int!) {
            listHavensByTitle(titleStartsWith: $titleStartsWith, limit: $limit ) {
              nextToken
              havens {
                title
              } 
            }
          }
        `,
      variables: { titleStartsWith, limit }
    })
    return response.data.listHavensByTitle
  }

  async listHavensByZip(zipStartsWith: string, limit: number) {

    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listHavensByZip($zipStartsWith: String!, $limit: Int!) {
            listHavensByZip(zipStartsWith: $zipStartsWith, limit: $limit ) {
              nextToken
              havens {
                title
              } 
            }
          }
        `,
      variables: { zipStartsWith, limit },
      fetchPolicy: 'network-only'

    })
    return response.data.listHavensByZip
  }

  async listHavensByState(stateStartsWith: string, limit: number) {

    stateStartsWith = '#usa#' + stateStartsWith

    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listHavensByState($stateStartsWith: String!, $limit: Int!) {
            listHavensByState(stateStartsWith: $stateStartsWith, limit: $limit ) {
              nextToken
              havens {
                title
              } 
            }
          }
        `,
      variables: { stateStartsWith, limit },
      fetchPolicy: 'network-only'

    })
    return response.data.listHavensByState
  }


  async GetHavenMainImageFiles(id: string) {
    try {
      const response = await this.appSyncClient.query<any>({
        query: gql`
            query getHaven($id: ID!) {
              getHaven(id: $id) {
                mainImageFiles
              }
            }
          `,
        variables: { id }
      })
      return response.data.getHaven
    } catch (error) {
      console.log('Error fetching haven main image files', error)
    }

  }



  async CreateSuggestedHavenInfo(input: iSuggestHavenInfo) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation createSuggestedHaven($input: IGQL_SuggestHavenInfoInput!) {
            createSuggestedHaven (input: $input) {
              id

            }
          }
        `,
      variables: { input }
    })

    // this.backendApiGamesService.sendEmailViaLambda('davidrazidlo@gmail.com', input.userWhoUpdated.title + ': tried to create haven in: ' + input.zipCode, input.messageToGH)

    return response.data.createSuggestedHaven
  }


  async UpdateHaven(input: iCreateOrUpdateHavenInput, files?) {

    input.mainImageFiles = await this.s3ContentHelperService.prepareMainImageFiles(files)

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation updateHaven($input: IGQL_CreateOrUpdateHavenInput!) {
            updateHaven (input: $input) {
              addedByUID
              title
              description
              address
              zipCode
              isPublic
              mainImage
              additionalImages
              hours
              havenType
              dateEstablished
              isOfficial
              radarAttributes
              isOnline
              admins
              tables
              hopperData
              eventsTimeInterval
              activityStatus
              createdAt
              updatedAt
              userWhoUpdated
              hasDemoLibrary
              hasLendingLibrary
              hasNormallyForSale
            }
          }
        `,
      variables: { input }
    })
    return response.data.updateHaven
  }

  async GetHaven(id: string) {

    const response = await this.appSyncClient.query<any>({
      query: gql`
        query getHaven($id: ID!) {
          getHaven(id: $id) {
            title
            createdAt
            updatedAt
            zipCode
            state
            mainImageFiles
            description
            address
            isPublic
            havenType
            havenWebsite
            id
            addedByUID
            activityStatus
            isOfficial
            havenAdmin
            hasDemoLibrary
            hasLendingLibrary
            hasNormallyForSale
            otherHavenAdmin
          }
        }
      `,
      variables: { id }
    })
    return response.data.getHaven
  }

  async listHavens(limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`query listHavens( $limit: Int, $nextToken: String ) {
        listHavens (limit: $limit, nextToken: $nextToken) {
          nextToken
          havens { 
            title
          }
          context
          contextIdentity
          contextIdentityUsername
          contextArguments
        }
      }`,
      variables: { limit, nextToken }
    })


    //! something similar to below would be if we wanted to include the filtering input. leaving here in case we decide to do so
    // let filter = {

    // }
    // const response = await this.appSyncClient.query<any>({
    //   query: gql`query listHavens($filter: ModelHavenFilterInput, $limit: Int!, $nextToken: String) {
    //     listHavens (filter: $filter, limit: $limit, nextToken: $nextToken) {
    //       nextToken
    //       havens 
    //     }
    //   }`,
    //   // variables: { id }
    // })

    return response.data.listHavens
  }


  async deleteHaven(input: iDeleteHavenInput) {
    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
            mutation deleteHaven($input: IGQL_DeleteHavenInput!) {
              deleteHaven (input: $input) {
                id
              }
            }
          `,
      variables: { input }
    })
    return response.data.deleteHaven
  }



  async listAllHavenCoreData(type: string, limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listAllHavenCoreData($type: String, $limit: Int!, $nextToken: String) {
            listAllHavenCoreData(type: $type, limit: $limit, nextToken: $nextToken ) {
              nextToken
              coreHavens {
                id
                corePK
                coreSK
                title
                mainImageFiles
                pk
                state
                zipCode
                description
                address
                havenType
              } 
            }
          }
        `,
      fetchPolicy: 'network-only',
      variables: { type, limit, nextToken }
    })
    return response.data.listAllHavenCoreData
  }



  async listHavensThatNeedAttention(limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listHavensThatNeedAttention( $limit: Int, $nextToken: String ) {
            listHavensThatNeedAttention( limit: $limit, nextToken: $nextToken ) {
              nextToken
              havens {
                title
                addedByUID
                createdAt
                __typename
                zipCode
                addedByUID
                title
                description
                address
                timeZone
                mainImage
                hours
                havenType
                dateEstablished
                tables
                activityStatus
                updatedAt
                userWhoUpdated
                needsAttentionPK
                needsAttentionSK
                mainImageFiles
                havenWebsite
                havenAdmin
              } 
              
            }
          }
        `,
      variables: { limit, nextToken }
    })
    return response.data.listHavensThatNeedAttention
  }

  async listAllHavens(limit: number, nextToken?) {
    const response = await this.appSyncClient.query<any>({
      query: gql`
          query listAllHavens( $limit: Int, $nextToken: String ) {
            listAllHavens( limit: $limit, nextToken: $nextToken ) {
              nextToken
              havens {
                title
                addedByUID
                createdAt
                __typename
                zipCode
                addedByUID
                title
                description
                address
                timeZone
                hours
                havenType
                dateEstablished
                tables
                activityStatus
                updatedAt
                userWhoUpdated
                needsAttentionPK
                needsAttentionSK
                mainImageFiles
                havenWebsite
                havenAdmin
              } 
              
            }
          }
        `,
      variables: { limit, nextToken }
    })
    return response.data.listAllHavens
  }

  async removeFromSubmissions() {
    // todo need to remove needsAttentionFlag and add isOfficialGHHavenFlag
    // alert('trying to send email')
  }

  async approveNewHaven(input: iCreateOrUpdateHavenInput) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation approveNewHaven($input: IGQL_CreateOrUpdateHavenInput!) {
            approveNewHaven (input: $input) {
              addedByUID
              needsAttentionPK
              needsAttentionSK
            }
          }
        `,
      variables: { input }
    })
    return response.data.approveNewHaven
  }












  async addToMyHavens(input: iAddToMyHavensInput) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation addToMyHavens($input: IGQL_AddToMyHavens!) {
            addToMyHavens (input: $input) {
              mainImageFiles
            }
          }
        `,
      variables: { input }
    })
    return response.data.addToMyHavens
  }













  async removeFromMyHavens(input: iRemoveFromMyHavensInput) {

    const response = await this.appSyncClient.mutate<any>({
      mutation: gql`
          mutation removeFromMyHavens($input: IGQL_RemoveFromMyHavens!) {
            removeFromMyHavens (input: $input) {
              mainImageFiles
            }
          }
        `,
      variables: { input }
    })
    return response.data.removeFromMyHavens
  }






  // let IGQL_CreateGameTrackingAddToLookingToPlay = {
  //   gameId: String
  //   UID: String
  //   type: String
  //   userState: String
  //   userZipCode: Int  
  //   __typename: String
  //   title: String
  //   userTitle: String
  //   mainImageFiles: String
  // }











}
