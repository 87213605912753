import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { environment } from '../../environments/environment'
import { Auth } from 'aws-amplify'
import gql from 'graphql-tag'

const appSyncClient = new AWSAppSyncClient({
  url: environment.apiUrl,
  region: 'us-east-1',
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken()
      return token
    },
  },
  disableOffline: true,
})

//! ========================================================================================================
export async function getS3PresignedUrlForImagePut(extension: string, contentType: string = null) {
  const response = await appSyncClient.query<any>({
    query: gql`
      query getImageUploadUrl($extension: String, $contentType: String) {
        getImageUploadUrl(extension: $extension, contentType: $contentType) {
          presignedUrl
          contentType
        }
      }
    `,
    variables: { extension, contentType },
    fetchPolicy: 'network-only',
  })

  // ! if same image  type is used
  // ! if PNG then JPG or JPG then PNG
  // ! it works.

  return {
    presignedUrl: response.data.getImageUploadUrl.presignedUrl,
    contentType: response.data.getImageUploadUrl.contentType,
  }
}
//! ========================================================================================================

export async function getS3PresignedUrlForTextPut(extension: string, contentType: string = null) {
  const response = await appSyncClient.query<any>({
    query: gql`
      query getTextUploadUrl($extension: String, $contentType: String) {
        getTextUploadUrl(extension: $extension, contentType: $contentType) {
          presignedUrl
          contentType
        }
      }
    `,
    variables: { extension, contentType },
    fetchPolicy: 'network-only',
  })
  return {
    presignedUrl: response.data.getTextUploadUrl.presignedUrl,
    contentType: response.data.getTextUploadUrl.contentType,
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#uploading_a_file
export async function uploadFileToPresignedUrl(
  presignedUrl: string,
  fileBody: BodyInit,
  contentType?: string,
) {
  const req: RequestInit = {
    method: 'PUT',
    body: fileBody,
    ...(contentType && {
      headers: { 'Content-Type': contentType },
    }),
    cache: 'no-cache', // ! add this line
  }
  const response = await fetch(presignedUrl, req)
  if (!response.ok) {
    throw new Error(
      `Error uploading file to presigned URL ${presignedUrl}. Error details: ${response.status} ${response.statusText}`,
    )
  }
}

//todo ========================================================================================================

export async function uploadImageFile(
  fileBody: BodyInit,
  extension: string, // things that follow the .
  providedContentType?: string, // optional, used for backend validation
) {
  const { presignedUrl, contentType } = await getS3PresignedUrlForImagePut(
    // todo this is happeing 1x
    // todo lambda only triggers once
    // todo reusing old path
    extension,
    providedContentType,
  )

  await uploadFileToPresignedUrl(presignedUrl, fileBody, contentType)

  const s3Url = presignedUrl.split('?')[0]
  return s3Url
}

//todo ========================================================================================================

export async function uploadTextFile(
  fileBody: BodyInit,
  extension: string, // things that follow the .
  providedContentType?: string, // optional, used for backend validation
) {
  const { presignedUrl, contentType } = await getS3PresignedUrlForTextPut(
    extension,
    providedContentType,
  )

  await uploadFileToPresignedUrl(presignedUrl, fileBody, contentType)
  const s3Url = presignedUrl.split('?')[0]
  return s3Url
}

// MULTIPLE IMAGES - Not currently being used
// export async function uploadImageFiles(files: BodyInit[], extension: string) {
//   const keys: string[] = []
//   for (let file of files) {
//     keys.push(await uploadImageFile(file, extension))
//   }
//   return keys
// }
