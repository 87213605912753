<div class="parent-container">
    <mat-card class="mat-elevation-z12">

        <form [formGroup]="form">
            <div class="row">

                <!-- GROUP MEMBERS -->
                <div class="col-12 section">
                    <div class="chiplist-container" *ngIf="possiblePlayers && possiblePlayers.length > 0">
                        <app-autocomplete-chiplist [_items]="possiblePlayers" [_itemsAreObjects]="true" [_removeItemFromListOnceSelected]="true" [_defaultItemsAreRemovable]="false" [_sourceOfInput]="'app-add-to-hopper1'" [_selectedItems]="selectedPlayers" [_allowDuplicates]="false"
                            [_allowCustomInput]="true" [_label]="'* Your Group:'" (selectionChanged)="addPlayersToGroup($event)">
                        </app-autocomplete-chiplist>
                        <mat-error class="gh-mat-error-simulator" *ngIf="submitIsClicked && form.get('group').errors?.required">Required</mat-error>
                    </div>
                </div>

                <!-- GAME OR HAVEN-->
                <div class="col-12 section">
                    <div class="row mb-4">
                        <div class="col-12">
                            <div class="chiplist-container" *ngIf="!isGameSpecific &&  (possibleGames && possibleGames.length > 0)">
                                <app-autocomplete-chiplist [_items]="possibleGames" [_itemsAreObjects]="true" [_sourceOfInput]="'app-add-to-hopper2'" [_selectedItems]="initialGame" [_allowDuplicates]="false" [_label]="'Game:'" [_allowCustomInput]="true" [_singleInput]="true" (selectionChanged)="setGame($event)"
                                    required>
                                </app-autocomplete-chiplist>
                                <!-- <app-gh-autocomplete [_items]="possibleGames" [itemsAreObjects]="true" [label]="'Game:'"></app-gh-autocomplete> -->
                                <mat-error class="gh-mat-error-simulator" *ngIf="submitIsClicked && form.get('game').errors?.required">
                                    Game is required.</mat-error>
                            </div>
                            <div class="chiplist-container" *ngIf="isGameSpecific && possibleHavens && possibleHavens.length > 0">
                                <app-autocomplete-chiplist [_items]="possibleHavens" [_itemsAreObjects]="true" [_sourceOfInput]="'app-add-to-hopper3'" [_selectedItems]="initialHaven" [_allowDuplicates]="false" [_label]="'Haven:'" [_allowCustomInput]="true" [_singleInput]="true" (selectionChanged)="setHaven($event)"
                                    required>
                                </app-autocomplete-chiplist>
                                <!-- <app-gh-autocomplete [_items]="possibleGames" [itemsAreObjects]="true" [label]="'Game:'"></app-gh-autocomplete> -->
                                <mat-error class="gh-mat-error-simulator" *ngIf="submitIsClicked && form.get('game').errors?.required">
                                    Game is required.</mat-error>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="(!isGameSpecific && form.get('game').value != null) || (isGameSpecific && form.get('haven') && form.get('haven').value != null)">

                            <div class="game-min-max-outer-container" [@addGameItemAnimTrigger]="addGameItemAnimState" (@addGameItemAnimTrigger.start)="isAnimating = true" (@addGameItemAnimTrigger.done)="isAnimating = false">
                                <div class="row game-min-max-item-container">

                                    <div class="col-12" *ngIf="!isAnimating">
                                        <div class="row">

                                            <div class="col-12 mt-3">
                                                <span class="inline-span">
                                                    <mat-card-subtitle>Publisher's Rules:</mat-card-subtitle>
                                                </span>
                                                <span class="inline-span ml-1">
                                                    <p
                                                        *ngIf="selectedGameBulkData.minPlayers != selectedGameBulkData.maxPlayers; else noRangePlayerCount">
                                                        {{selectedGameBulkData.minPlayers}}-{{selectedGameBulkData.maxPlayers | nullPlaceholder}}
                                                    </p>
                                                    <ng-template #noRangePlayerCount>
                                                        {{selectedGameBulkData.minPlayers | nullPlaceholder}}
                                                    </ng-template>
                                                </span>
                                                <span class="inline-span ml-1">
                                                    <p>players</p>
                                                </span>
                                            </div>

                                            <div class="col-12 community-votes-container">
                                                <span class="inline-span">
                                                    <mat-card-subtitle>Community Voted Best:</mat-card-subtitle>
                                                </span>
                                                <span class="inline-span ml-1">
                                                    <p
                                                        *ngIf="selectedGame.votedBestMinPlayers != selectedGame.votedBestMaxPlayers; else noRangePlayerCommunityCount">
                                                        {{selectedGame.votedBestMinPlayers}}-{{selectedGame.votedBestMaxPlayers | nullPlaceholder}}
                                                    </p>
                                                    <ng-template #noRangePlayerCommunityCount>
                                                        {{selectedGame.votedBestMinPlayers | nullPlaceholder}}
                                                    </ng-template>
                                                </span>
                                                <span class="inline-span ml-1">
                                                    <p>players</p>
                                                </span>
                                            </div>
                                            <div class="col-12 gh-checkbox-container customize-bounds-checkbox-container">
                                                <mat-checkbox class="customize-bounds-checkbox" [checked]="form.get('hasCustomBounds').value" (change)="onDesiredIndicesChanged(form.get('desiredIndices').value)" formControlName="hasCustomBounds">Customize player bounds</mat-checkbox>
                                            </div>
                                            <div class="col-12 custom-min-max-input-container" *ngIf="form.get('hasCustomBounds').value">
                                                <div class="row mt-1 game-min-max-form-field-container">
                                                    <div class="col-6">
                                                        <mat-form-field class="game-min-max-form-field">
                                                            <mat-label>* Min Players:</mat-label>
                                                            <input matInput autocomplete="off" formControlName="customLowerBound" (input)="onDesiredIndicesChanged(form.get('desiredIndices').value)">
                                                            <mat-error *ngIf="form.get('customLowerBound').errors?.required">
                                                                Required</mat-error>
                                                            <mat-error *ngIf="form.get('customLowerBound').errors?.pattern">
                                                                Must be a number</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-6">
                                                        <mat-form-field class="game-min-max-form-field">
                                                            <mat-label>* Max Players:</mat-label>
                                                            <input matInput autocomplete="off" formControlName="customUpperBound" (input)="onDesiredIndicesChanged(form.get('desiredIndices').value)">
                                                            <mat-error *ngIf="form.get('customUpperBound').errors?.required">
                                                                Required</mat-error>
                                                            <mat-error *ngIf="form.get('customUpperBound').errors?.pattern">
                                                                Must be a number</mat-error>
                                                            <mat-error *ngIf="form.get('customUpperBound').errors?.min">
                                                                Must be greater than 1</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="form.get('hasCustomBounds').value">

                                                <div class="col-12 players-needed-bar-container">
                                                    <app-players-needed-bar-old *ngIf="form.get('game').value " [_isInteractive]="form.get('hasCustomBounds').value" [_gameMin]="form.get('game').value.minPlayers" [_sourceOfInput]="'app-add-to-hopper'" [_gameMax]="form.get('game').value.maxPlayers" [_communityMin]="form.get('game').value.votedBestMinPlayers"
                                                        [_communityMax]="form.get('game').value.votedBestMaxPlayers" [_customLowerBound]="form.get('hasCustomBounds').value ? form.get('customLowerBound').value : null" [_customUpperBound]="form.get('hasCustomBounds').value ? form.get('customUpperBound').value : null"
                                                        [_desiredIndices]="generateDefaultDesiredIndices()" (desiredIndicesChangedEmitter)="onDesiredIndicesChanged($event)">
                                                    </app-players-needed-bar-old>
                                                </div>

                                                <div class="col-12 players-needed-text-container" *ngIf="form.get('hasCustomBounds').value">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="row text-center">
                                                                <div class="col-12">
                                                                    <mat-card-subtitle>Acceptable #:
                                                                    </mat-card-subtitle>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p>{{acceptableCustomPlayersNeededText}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="row text-center">
                                                                <div class="col-12">
                                                                    <mat-card-subtitle>Unacceptable #:
                                                                    </mat-card-subtitle>
                                                                </div>
                                                                <div class="col-12">
                                                                    <p>{{unacceptableCustomPlayersNeededText}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                    <!-- Need submitIsClicked for this error to avoid error popping up when editing the upper bound -->
                                    <mat-error class="min-max-error gh-mat-error-simulator" *ngIf="submitIsClicked && form.errors?.minMax">
                                        Minimum can't be greater than maximum!
                                    </mat-error>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- COMPETITION LEVEL -->
                <div class="col-12 section">
                    <mat-form-field class="gh-select-wrapper input-form-field w-100">
                        <mat-label>Competition Level:</mat-label>
                        <mat-select [compareWith]="compareFunction" formControlName="competitionLevel">
                            <mat-option *ngFor="let level of competitionLevels" [value]="level">
                                {{level}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- COMMENTS -->
                <div class="col-12 section">
                    <mat-form-field class="input-form-field w-100">
                        <mat-label>Comments:</mat-label>
                        <input matInput autocomplete="off" formControlName="comments">
                    </mat-form-field>
                </div>

            </div>

            <div class="row button-row">
                <button mat-raised-button class="gh-button gh-button-success" (click)="submit()">{{isEditing ? 'UPDATE' : 'SUBMIT'}}</button>
            </div>
        </form>

    </mat-card>
</div>