<div class="container-fluid big-wrapper">

    <div class="row">
        <mat-toolbar>
            <span class="span">
				Games I Love 
			</span>
        </mat-toolbar>
    </div>

    <!-- !THIS IS HARD CODED -->
    <!-- <div class="row">
		<div class="col-md-6 img-wrapper">
            <img src="https://robohash.org/set_set3/bgset_bg1/3.14159?size=250x250">
		</div>
		<div class="col-md-6 img-wrapper">
            <img src="https://robohash.org/set_set3/bgset_bg1/3.14159?size=250x250">
		</div>
	</div>
	<div class="row">
		<div class="col-md-6 img-wrapper">
            <img src="https://robohash.org/set_set3/bgset_bg1/3.14159?size=250x250">
		</div>
		<div class="col-md-6 img-wrapper">
            <img src="https://robohash.org/set_set3/bgset_bg1/3.14159?size=250x250">
		</div>
    </div> -->

</div>