export const COMMON_FOOD_CONTRIBUTIONS = [
    'Pizza',
    'Chips',
    'Cake',
    'etc...'
]

export const COMMON_BEVERAGE_CONTRIBUTIONS = [
    'Beer',
    'Wine',
    'Mountain Dew',
    'etc...'
]
