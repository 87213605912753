<div class="parent-container mt-2">

    <mat-card-title class="gh-sm-title">Incoming Friend Requests</mat-card-title>
    <div class="px-2 mt-2 mb-4">
        <app-friend-requests-table [_dataSource]="incomingRequests" [_displayedColumns]="displayedColumnsIncomingFriendRequests" [_isIncoming]="true" [_user]="user" [_hasDeleteButtons]="hasDeleteButtons" [_hasConfirmationButtons]="true"></app-friend-requests-table>
    </div>

    <mat-card-title class="gh-sm-title">Outgoing Friend Requests</mat-card-title>
    <div class="px-2 mt-2">
        <app-friend-requests-table [_dataSource]="outgoingRequests" [_displayedColumns]="displayedColumnsOutgoingFriendRequests" [_isIncoming]="false" [_user]="user" [_hasDeleteButtons]="hasDeleteButtons" [_hasConfirmationButtons]="false"></app-friend-requests-table>

    </div>
</div>