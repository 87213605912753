import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { AuthService } from '../services/auth/auth.service'
import { Observable } from 'rxjs/Rx'

@Injectable()
export class AuthGuardService  {
  constructor(private authService: AuthService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    | Promise<boolean>
    | boolean {
    const isAuthenticated = this.authService.isAuthenticated()
    return isAuthenticated
  }
}
