import { TileState } from '../enums/state-enum'
import { EventType } from '../enums/event-type-enum'

export class EventTile {

    private openColor = '#EFEFEF'
    private pendingColor = '#ffcb9a70'
    private selectedColor = '#ffcb9a'

    private gameColor = '#116466'
    private breakColor = '#F8BBD0'
    private tourneyColorolor = '#DCEDC8'
    private openPlayColor = '#FFCCBC'
    private walkUpPlayColor = '#D7CCC8'
    private customTypeColor = '#B2EBF2'

    private text: string = 'Open'
    private fontColor: string = '#000'
    private backgroundColor: string = this.openColor
    private rows: number = 1
    private columns: number = 1

    private state: TileState
    private isEventRoot = false

    private eventType: EventType

    private isHovered: boolean

    constructor(state?: TileState) {
        state ? this.state = state : this.state = TileState.open
    }


    //#region GETTERS 

    getFontColor() {
        return this.fontColor
    }

    getBackgroundColor(): string {
        return this.backgroundColor
    }

    getText(): string {
        return this.text
    }

    getColumns(): number {
        return this.columns
    }

    getRows(): number {
        return this.rows
    }

    getHasSetEventButton(): boolean {
        return this.isEventRoot && this.state == TileState.selected
    }

    getHasCancelButton(): boolean {
        return this.isEventRoot && this.state == TileState.selected
    }

    getHasEditButton(): boolean {
        return this.isEventRoot && this.state == TileState.assigned
    }

    getHasMinusButton(): boolean {
        return this.isEventRoot && this.columns > 1 && this.state == TileState.selected
    }

    getState(): TileState {
        return this.state
    }

    getEventType(): EventType {
        return this.eventType
    }

    getIsHovered(): boolean {
        return this.isHovered
    }

    //#endregion





    //#region SETTERS 


    addColumns(amountToAdd: number) {
        this.columns = this.columns + amountToAdd
    }

    removeColumns(amountToRemove: number) {
        this.columns = this.columns - amountToRemove
    }

    setIsEventRoot(isEventRoot: boolean) {
        this.isEventRoot = isEventRoot
    }


    setState(state: TileState, text?: string, type?: EventType) {
        this.state = state

        switch (state) { 
            case TileState.open:
                this.backgroundColor = this.openColor
                this.text = 'Open'
                break

            case TileState.selected:
                this.backgroundColor = this.selectedColor
                this.text = ''
                break

            case TileState.pendingSelection:
                this.backgroundColor = this.pendingColor
                this.text = 'Click to add'
                break

            case TileState.assigned:
                this.setEventType(type, text)
                break


        }
    }

    setEventType(eventType: EventType, text?: string) {

        switch (eventType) {

            case EventType.game:
                this.text = text
                this.fontColor = "#fff"
                this.backgroundColor = this.gameColor
                break

            case EventType.break:
                this.text = 'Break'
                this.backgroundColor = this.breakColor
                break

            case EventType.tourney:
                this.text = 'Tourney'
                this.backgroundColor = this.tourneyColorolor
                break

            case EventType.openPlay:
                this.text = 'Open Play'
                this.backgroundColor = this.openPlayColor
                break

            case EventType.walkUpPlay:
                this.text = 'Walk Up Play'
                this.backgroundColor = this.walkUpPlayColor
                break

            case EventType.custom:
                this.text = 'Custom (not done yet)'
                this.backgroundColor = this.customTypeColor
                break
        }
    }

    setIsHovered(isHovered: boolean) {
        this.isHovered = isHovered
    }

    //#endregion

}