<div *ngIf="(gamesAreLoaded$ | async); else loading">

    <div class="col-12 mt-3" *ngIf="(friends | async)">
        <app-autocomplete-chiplist class="gh-form-field" [_items]="friends.value" [_itemsAreObjects]="true"
            [_label]="'Choose up to 3 friends'" [_allowDuplicates]="false" [_allowCustomInput]="false"
            [_singleInput]="false" [_max]="3" (selectionChanged)="addFriendsToBig8Match($event)"
            [_sourceOfInput]="'app-play-now-modal'">
        </app-autocomplete-chiplist>
    </div>


    <div class="mt-4" *ngIf="topGames && topGames.length > 0">
        <mat-card-subtitle class="gh-md-subtitle">Top 10 Games for you and soAndSo and soAndSo and
            ...:</mat-card-subtitle>

        <div class="row mx-0">

            <div class="col-12 col-md-3 mb-3" *ngFor="let game of topGames">
                <div class="img-container">
                    <img [src]="game.mainImageFiles[0]" alt="" matTooltip="{{game.t}}" matTooltipPosition="above"
                        (click)="openGameDetails(game)">
                </div>
            </div>

        </div>

    </div>


    <div *ngIf="testAnim">
        <div class="row">
            <div class="col-12">
                <button (click)="startAnim()">Test animation</button>
            </div>
        </div>

        <div class="anim-container">

            <div class="row" id="player-avatars-container">

                <div class="col-12 w-100">

                    <span class="inline-span">
                        <div #avatar0 id="avatar0" class="avatar-img-container"
                            [ngClass]="{ animateAvatar0: shouldAnimateAvatar0 }">
                            <div class="img-wrapper">
                                <img class="profile-image" src="{{defaultUserImage}}">
                            </div>
                        </div>
                    </span>

                    <span class="inline-span ml-5">
                        <div #avatar1 id="avatar1" class="avatar-img-container"
                            [ngClass]="{ animateAvatar1: shouldAnimateAvatar1 }">
                            <div class="img-wrapper">
                                <img class="profile-image" src="{{defaultUserImage}}">
                            </div>
                        </div>
                    </span>

                    <span class="inline-span ml-5">
                        <div #avatar2 id="avatar2" class="avatar-img-container"
                            [ngClass]="{ animateAvatar2: shouldAnimateAvatar2 }">
                            <div class="img-wrapper">
                                <img class="profile-image" src="{{defaultUserImage}}">
                            </div>
                        </div>
                    </span>

                    <span class="inline-span ml-5">
                        <div #avatar3 id="avatar3" class="avatar-img-container"
                            [ngClass]="{ animateAvatar3: shouldAnimateAvatar3 }">
                            <div class="img-wrapper">
                                <img class="profile-image" src="{{defaultUserImage}}">
                            </div>
                        </div>
                    </span>

                </div>

            </div>


            <div id="cup-container" [ngClass]="{ shakeCup: shouldShakeCup }">
                <div id="cup-back" [ngClass]="{ tipCup: shouldTipCup }"></div>
                <div id="cup-front" [ngClass]="{ tipCup: shouldTipCup }"></div>
            </div>

            <div class="row" id="games-result-container">
                <div #game0 id="game0" class="game-img-container"
                    [ngClass]="{ dumpGame0: shouldAnimateGame0Dump, bounceGame0: shouldAnimateGame0Bounce }">
                    <div class="img-wrapper">
                        <img class="game-image" src="{{defaultGameImage}}">
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div #loading [hidden]="(gamesAreLoaded$ | async)" class="gh-loading-container">
    <app-gh-loading-spinner></app-gh-loading-spinner>
</div>