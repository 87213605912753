import { Component, Inject, OnInit } from '@angular/core'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GamesService } from 'src/app/dashboard/dashboard-shared/services/games/games.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { ulid } from 'ulid'
@Component({
    selector: 'app-create-game-interest-item',
    templateUrl: './create-game-interest-item.component.html',
    styleUrls: ['./create-game-interest-item.component.scss'],
})
export class CreateGameInterestItemComponent implements OnInit {
    gameSelected: boolean = false // Tracks if a game has been selected
    selectedGame: any = null // Stores the selected game
    notes: string = '' // Stores notes

    coreGamesToSearch: any
    coreGames = new BehaviorSubject(null)
    coreGames$ = this.coreGames.asObservable()
    gameToEdit: any

    currentUser: any

    constructor(
        private backendAPIUsersService: BackendAPIUsersService,
        private gamesService: GamesService,
        private backendAPIContentService: BackendAPIContentService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CreateGameInterestItemComponent>,
    ) {}

    inputData
    async ngOnInit() {
        // Subscribe to the current user
        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe((currentUser) => {
                if (currentUser) {
                    this.currentUser = currentUser
                }
            })

        this.inputData = this.data.inputData.parentInputData

        // Subscribe to the list of core games
        await this.gamesService.coreGames$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreGames) => {
                if (coreGames) {
                    // Filter out expansions
                    coreGames = coreGames.filter((obj) => !obj.isExpansion)
                    this.coreGames.next(coreGames)
                    this.coreGamesToSearch = coreGames
                }
            })
    }

    // Function to handle game selection
    selectGame(game: any): void {
        this.selectedGame = game[0]
        this.gameSelected = !!this.selectedGame
    }

    // Check if the form is valid
    isFormValid(): boolean {
        return this.gameSelected
    }

    // Form submission logic
    async submitForm() {
        if (this.isFormValid()) {
            let newUlid = ulid()
            const createInput: any = {
                conventionId: this.data.inputData.parentInputData.convention.pk.toString(),
                createdByTitle: this.currentUser.title,
                createdByUID: this.currentUser.username,
                currentPlayers: '',
                name: this.selectedGame.title,
                notes: this.notes,
                pk: this.data.inputData.parentInputData.convention.pk.toString(),
                sk: 'gi_' + newUlid,
                gameTitle: this.selectedGame.title,
                gameId: this.selectedGame.pk,
            }

            await this.backendAPIContentService.createSharedLibraryItem(createInput)
            this.dialogRef.close(createInput)
        }
    }
}
