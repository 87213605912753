import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { iOnImageAction } from '../../interfaces/media/IMedia';



@Injectable({
  providedIn: 'root'
})
export class ImageGalleryGridService {

  onImageAction = new BehaviorSubject<iOnImageAction>({
    type: null,
    imageItem: null
  });

  onImageAction$ = this.onImageAction.asObservable()

  constructor() { }

  updateOnImageAction(action: iOnImageAction) {
    this.onImageAction.next(action)
  }
}
