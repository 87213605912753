<img [src]="img && img[0] ? img[0]: placeholderImg" (click)="expand()">

<div #modal class="modal" [ngClass]="{ isExpanded: isExpanded }">
    <span class="close" (click)="close()">&times;</span>
    <div id="title" *ngIf="title && title != ''">
        <mat-card-title>{{title}}</mat-card-title>
    </div>
    <img #modalImg class="modal-content" id="img01" [src]="img && img[0] ? img[0]: placeholderImg">
    <div id="caption">
        <mat-card-subtitle>
            {{caption}}
        </mat-card-subtitle>
    </div>
</div>