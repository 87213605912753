<div class="row parent-container">
    <div class="col-12 text-center">

        <!-- AVAILABLE GAMES CARD -->
        <mat-card class="mat-elevation-z12 mt-3">
            <div class="row gh-card-header-row">
                <div class="col-12">
                    <span class="inline-span">
                        <mat-card-title class="gh-sm-title">Games of Interest</mat-card-title>
                    </span>
                    <mat-divider></mat-divider>
                </div>
            </div>

            <div class="row content-row pt-3">
                <!-- AVAILABLE GAMES -->
                <div class="col-12 w-100">
                    <div class="game-list-container">
                        <div id="availableGames" cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="availableGames" cdkDropListConnectedTo="selectedGames" class="game-list" (cdkDropListDropped)="drop($event)">
                            <div class="game-box" *ngFor="let game of availableGames" [cdkDragData]="game" cdkDrag [ngStyle]="{ maxWidth: getMaxWidth(game) }">
                                <div>
                                    <div class="row mb-2">
                                        <div class="col-12 text-center">
                                            <img class="game-thumb-img" [src]="game.thumbImgPath" alt="" [ngStyle]="{ maxHeight: '90px' }">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <mat-card-title class="gh-xsm-title">
                                                {{game.title}}
                                            </mat-card-title>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <mat-card-subtitle class="gh-md-subtitle">
                                                {{game.minutes + 'min'}}
                                            </mat-card-subtitle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>

        <!-- SELECTED GAMES CARD -->
        <mat-card class="mat-elevation-z12 mt-4">
            <div class="row gh-card-header-row">
                <div class="col-12">
                    <span class="inline-span">
                        <mat-card-title class="gh-sm-title">Scheduled Games</mat-card-title>
                    </span>
                    <mat-divider></mat-divider>
                </div>
            </div>

            <div class="row content-row pt-3">
                <!-- HOURS HEADER -->
                <div class="col-12 w-100">
                    <mat-grid-list id="header-hours" cols="{{hours.length}}" rowHeight="40px">
                        <mat-grid-tile class="hour-tile" *ngFor="let hour of hours">
                            {{hour}}
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>

                <!-- SELECTED GAMES -->
                <div class="col-12 w-100">
                    <div class="game-list-container">
                        <div id="selectedGames" cdkDropList [cdkDropListData]="selectedGames" cdkDropListConnectedTo="availableGames" class="game-list" (cdkDropListDropped)="drop($event)" [cdkDropListEnterPredicate]="acceptedGamePredicate">
                            <div class="game-box" *ngFor="let game of selectedGames" cdkDrag [cdkDragData]="game" [ngStyle]="{ maxWidth: getMaxWidth(game) }">
                                <div>
                                    <div class="row mb-2">
                                        <div class="col-12 text-center">
                                            <img class="game-thumb-img" [src]="game.thumbImgPath" alt="" [ngStyle]="{ maxHeight: '90px' }">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <mat-card-title class="gh-xsm-title">
                                                {{game.title}}
                                            </mat-card-title>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <mat-card-subtitle class="gh-md-subtitle">
                                                {{game.minutes + 'min'}}
                                            </mat-card-subtitle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-card>

    </div>
</div>