import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-personal-professional-account-distinction-expaination',
  templateUrl: './personal-professional-account-distinction-expaination.component.html',
  styleUrls: ['./personal-professional-account-distinction-expaination.component.scss']
})
export class PersonalProfessionalAccountDistinctionExpainationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
