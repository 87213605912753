import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BackendAPIAppSyncAuthSettings } from 'aws-sdk/clients/amplifybackend';
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component';
import { ComposeMessageComponent } from '../compose-message/compose-message.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.scss']
})
export class MessageDetailsComponent implements OnInit {

  inputData
  currentUser
  @Input() set _inputData(inputData) {
    this.inputData = inputData
  }

  constructor(
    private dialog: MatDialog,
    private backendAPIUsersService: BackendAPIUsersService,
    private dialogRef: MatDialogRef<MessageDetailsComponent>,
    private backendApiMessageService: BackendAPIMessageService

  ) { }

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {

      let inputData = { currentUser: this.currentUser, messageToReplyTo: this.inputData }

      this.close(inputData)
    }
  }


  @Input() set _parentLeftActionButtonClicked(deleteButtonClicked: boolean) {
    if (deleteButtonClicked) {
      this.deleteMessage(this.inputData)
      // asdlfkjfsadlijfdsaoifjdsaoafsdij Need to pass information up so that it can be popped out array
      this.close()

    }
  }

  async deleteMessage(inputData) {

    let deleteMessageInput = {
      pk: inputData.pk_UID,
      sk: inputData.sk_type_ULID
    }
    let deleteResult = await this.backendApiMessageService.deleteMessage(deleteMessageInput)

  }

  ngOnInit(): void {
    this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.currentUser = user
    })
  }

  close(data?) {
    this.dialogRef.close(data)
  }

}
