import { Component, Input, OnInit } from '@angular/core'
import { GlobalService } from '../../services/global/global.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() isAuthenticated = false

  constructor(
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {

  }

}
