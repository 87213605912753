<div class="row gray mt-5">
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-card-title class="gh-sm-title">
            To:
        </mat-card-title>
    </div>

    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <mat-card-subtitle class="gh-md-subtitle gh-subtitle-bold">
            {{inputData.sentToTitle}}
        </mat-card-subtitle>
    </div>
</div>

<div class="row">
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-card-title class="gh-sm-title">
            From:
        </mat-card-title>
    </div>

    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <mat-card-subtitle class="gh-md-subtitle gh-subtitle-bold">
            {{inputData.sentFromTitle}}
        </mat-card-subtitle>
    </div>
</div>

<div class="row gray">
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-card-title class="gh-sm-title">
            Date:
        </mat-card-title>
    </div>

    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <mat-card-subtitle class="gh-md-subtitle gh-subtitle-bold">
            {{inputData.createdAt | date}}
        </mat-card-subtitle>
    </div>
</div>

<div class="row">
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-card-title class="gh-sm-title">
            Message:
        </mat-card-title>
    </div>

    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <mat-card-subtitle class="gh-md-subtitle gh-subtitle-bold">
            {{inputData.message}}
        </mat-card-subtitle>
    </div>
</div>
<!-- 
<div class="row gray">
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-card-title class="gh-sm-title">
            Created By:
        </mat-card-title>
    </div>

    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <mat-card-subtitle class="gh-md-subtitle gh-subtitle-bold">
            {{inputData.createdBy}}
        </mat-card-subtitle>
    </div>
</div>

<div class="row">
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-card-title class="gh-sm-title">
            pk_UID:
        </mat-card-title>
    </div>

    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <mat-card-subtitle class="gh-md-subtitle gh-subtitle-bold">
            {{inputData.pk_UID}}
        </mat-card-subtitle>
    </div>
</div>

<div class="row gray">
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-card-title class="gh-sm-title">
            sentToUID:
        </mat-card-title>
    </div>

    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <mat-card-subtitle class="gh-md-subtitle gh-subtitle-bold">
            {{inputData.sentToUID}}
        </mat-card-subtitle>
    </div>
</div>

<div class="row">
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-card-title class="gh-sm-title">
            sk_type_ULID:
        </mat-card-title>
    </div>

    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <mat-card-subtitle class="gh-md-subtitle gh-subtitle-bold">
            {{inputData.sk_type_ULID}}
        </mat-card-subtitle>
    </div>
</div>

<div class="row gray">
    <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <mat-card-title class="gh-sm-title">
            __typename:
        </mat-card-title>
    </div>

    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <mat-card-subtitle class="gh-md-subtitle gh-subtitle-bold">
            {{inputData.__typename}}
        </mat-card-subtitle>
    </div>
</div> -->