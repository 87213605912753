import { ThisReceiver } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject } from 'rxjs';
import { BackendApiGameTrackingService } from 'src/app/backend-api-services/backend-api-game-tracking.service';
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { RADAR_CHART_PROFILE_FRIEND_GAME_LABELS } from 'src/app/dashboard/constants/radar-chart-constants';
import { defaultRatingConst } from 'src/app/dashboard/dashboard-shared/constants/games.constants';
import { RadarChartService } from 'src/app/dashboard/dashboard-shared/services/charts/radar/radar-chart.service';
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service';
import { RadarChartAttributeEnums, RadarChartOverlayTypeEnums, RadarChartProfileFriendGameAttributeEnums } from 'src/app/dashboard/enums/radar-chart-enums';
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service';
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-gh-game-radar-chart',
  templateUrl: './gh-game-radar-chart.component.html',
  styleUrls: ['./gh-game-radar-chart.component.scss']
})

export class GhGameRadarChartComponent implements OnInit {

  complexityRating: number = defaultRatingConst
  depthRating: number = defaultRatingConst
  durationRating: number = defaultRatingConst
  interactionRating: number = defaultRatingConst
  competitionRating: number = defaultRatingConst
  playersRating: number = defaultRatingConst
  chanceRating: number = defaultRatingConst
  thematicRating: number = defaultRatingConst

  isMobileScreen = true
  isLoading = new BehaviorSubject(true)
  isLoading$ = this.isLoading.asObservable()
  form: UntypedFormGroup
  attributes
  isEditing = false
  chartType: ChartType = 'radar'
  radarChartLabelsStringified
  radarChartLabelsShallowCopy
  chartData
  chartLabels
  mostRecentDataValues = []

  radarChartOptions = { // CHART OPTIONS
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        min: 0,
        max: 10,
        beginAtZero: true,
        angleLines: {
          color: "rgba(245, 121, 58, 0.6)",
        },
        pointLabels: {
          font: {
            size: 18,
          },
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
    }
  }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective

  @Output() closeEmitter = new EventEmitter()

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submitRating()
    }
  }

  inputData
  selectedGame
  @Input() set _inputData(inputData) {

    this.inputData = inputData
    this.selectedGame = inputData.selectedItem

    this.initChartData(this.inputData).then(() => {
      this.isLoading.next(false)
    })
  }

  user
  async initChartData(inputData) {

    if (inputData.isEditing) {
      this.attributes = JSON.parse(inputData.attributes)
      this.isEditing = true
    } else {
      this.attributes = [5, 5, 5, 5, 5, 5, 5, 5]
      this.isEditing = false
    }

    this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })

    this.chartData = [
      {
        data: this.attributes,
        borderColor: '#116466',
        fill: true,
        backgroundColor: "#11646675",
        pointRadius: 10,
        pointHoverRadius: 20,
        pointBackgroundColor: 'white',
        pointBorderColor: '#116466',
        pointBorderWidth: 2
      }
    ]
    let chartDataAsAny: any = this.chartData[0]
    for (var i = 0; i < chartDataAsAny.data.length; i++) {
      this.mostRecentDataValues.push(chartDataAsAny.data[i])
    }
  }

  constructor(
    private snackbar: MatSnackBar,
    private chartService: RadarChartService,
    private screenSizeService: ScreenSizeService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private backendApiUsersService: BackendAPIUsersService,
    private backendApiGameTrackingService: BackendApiGameTrackingService,
    private backendApiGamesService: BackendAPIGamesService,
    private dialogRef: MatDialogRef<GhGameRadarChartComponent>
  ) { }

  formIsLoaded = false
  radarChartAttributeEnum
  radarChartTypeEnum
  async ngOnInit() {

    this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobileScreen: boolean) => {
      this.isMobileScreen = isMobileScreen
    })

    this.radarChartAttributeEnum = RadarChartProfileFriendGameAttributeEnums
    this.radarChartTypeEnum = RadarChartAttributeEnums.game
    this.radarChartLabelsStringified = JSON.stringify(RADAR_CHART_PROFILE_FRIEND_GAME_LABELS)

    this.radarChartLabelsShallowCopy = JSON.parse(this.radarChartLabelsStringified)
    this.chartLabels = JSON.parse(this.radarChartLabelsStringified)

    this.form = this.formBuilder.group({
      complexityRating: [],
      depthRating: [],
      durationRating: [],
      interactionRating: [],
      competitionRating: [],
      playersRating: [],
      chanceRating: [],
      thematicRating: [],
    })
    this.formIsLoaded = true

    this.form.get('complexityRating').patchValue(this.attributes[0])
    this.form.get('depthRating').patchValue(this.attributes[1])
    this.form.get('durationRating').patchValue(this.attributes[2])
    this.form.get('interactionRating').patchValue(this.attributes[3])
    this.form.get('competitionRating').patchValue(this.attributes[4])
    this.form.get('playersRating').patchValue(this.attributes[5])
    this.form.get('chanceRating').patchValue(this.attributes[6])
    this.form.get('thematicRating').patchValue(this.attributes[7])
  }

  chartIsLoaded = false
  ngAfterViewChecked() {
    if (!this.chartIsLoaded) {
      if (this.chart) {
        this.chartIsLoaded = true
        this.radarChartOptions = this.chartService.updateChartOption(this.radarChartOptions, 'dragData', true)
        this.chart.update()
      }
    }
  }


  currentValueOfSelectedGame() { // tester button
  }







  // REMOVES THE OLD VOTE - something like this will be needed if we chose to allow the user to remove a rpeviously rated game
  async removeVoteAndCloseModal() {

    if (this.isEditing) {

      let previousUserBig8Votes = await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(this.user.username, '#big8Vote#', 49,)

      if (this.selectedGame.big8Votes == 1 || this.selectedGame.big8Votes == 0) { //! adsfklfadjslkja







        let gameBDbig8Votes = {
          pkName: 'pk',
          pkValue: this.selectedGame.id,
          skName: 'sk',
          skValue: 'BD#' + this.selectedGame.id,
          attributeName: 'big8Votes',
          attributeValue: '0'
        }

        await this.backendApiGamesService.updateSingleAttributeForGame(gameBDbig8Votes)






        let gameBDbig8 = {
          pkName: 'pk',
          pkValue: this.selectedGame.id,
          skName: 'sk',
          skValue: 'BD#' + this.selectedGame.id,
          attributeName: 'big8',
          attributeValue: null
        }
        await this.backendApiGamesService.updateSingleAttributeForGame(gameBDbig8)
        this.snackbarService.openSuccessSnackBar('Information Recorded in the Database')
        this.dialogRef.close('resetData')

        previousUserBig8Votes =
          await this.backendApiGameTrackingService.listGameTrackingItemsByUserAndType(
            this.user.username,
            '#big8Vote#',
            490,
          )

        let arrayOfObjectsToSearch = previousUserBig8Votes.games
        let searchString = this.selectedGame.id


        let itemToDelete = null;

        for (const item of arrayOfObjectsToSearch) {
          if (item.id === searchString) {
            itemToDelete = item;
            break; // stops the loop once a match is found
          }
        }

        if (itemToDelete) {
          console.log('Item to delete:', itemToDelete);
        } else {
          console.log('No item found with the given ID.');
        }

        let pk = searchString
        let sk = '#big8Vote#' + this.user.username

        let input = {
          pk: pk,
          sk: sk,
        }

        this.backendApiGameTrackingService.deleteGameTrackingItemByPkSk(input)
      } else {
        alert('let us know that we have to finish delete Big 8 Vote Result, thanks!')
      }






    }
  }
















  async submitRating() {

    // SETUP necessary to deal with undefined or null values after the db call
    let big8VotesLocal: number
    if (this.selectedGame && (this.selectedGame.big8Votes == null || this.selectedGame.big8Votes == undefined)) {
      this.selectedGame.big8Votes = 0
      big8VotesLocal = 0
      this.selectedGame.big8 = '[5, 5, 5, 5, 5, 5, 5, 5]'
    } else {
      big8VotesLocal = this.selectedGame.big8Votes
    }

    // Creates Game Tracking item for the user
    let gameTrackingItemInput = {
      gameId: this.selectedGame.id,
      UID: this.user.username,
      type: 'big8Vote',
      userState: this.user.state,
      userZipCode: this.user.zipCode,
      userTitle: this.user.title,
      mainImageFiles: undefined,
      title: undefined,
      big8Vote: [
        this.chartData[0].data[0],
        this.chartData[0].data[1],
        this.chartData[0].data[2],
        this.chartData[0].data[3],
        this.chartData[0].data[4],
        this.chartData[0].data[5],
        this.chartData[0].data[6],
        this.chartData[0].data[7],
      ]
    }

    this.backendApiGameTrackingService.createGameTrackingItemAR(gameTrackingItemInput)


    //  THIS ADDS THE NEW VOTE ************************************************************************

    if (this.isEditing) {
      // todo wip, this is where the EDITING code goes

      big8VotesLocal = this.selectedGame.big8Votes

      if (this.selectedGame.big8) {
        this.selectedGame.big8 = JSON.parse(this.selectedGame.big8)
      } else {
        this.selectedGame.big8 = [5, 5, 5, 5, 5, 5, 5, 5]
      }

      let prevVote = JSON.parse(this.inputData.attributes) // no idea why you cant use attributes, but you just cant.


      let editingMath0 = `${this.chartData[0].data[0]} = (((${this.selectedGame.big8[0]} * ${big8VotesLocal}) + ${this.chartData[0].data[0]} - ${prevVote[0]}) / (${big8VotesLocal}))`
      console.log('editingMath0', editingMath0)
      let editingMath1 = `${this.chartData[0].data[1]} = (((${this.selectedGame.big8[1]} * ${big8VotesLocal}) + ${this.chartData[0].data[1]} -  ${prevVote[1]}) / (${big8VotesLocal}))`
      console.log('editingMath1', editingMath1)

      this.chartData[0].data[0] = (((this.selectedGame.big8[0] * big8VotesLocal) + this.chartData[0].data[0] - prevVote[0]) / (big8VotesLocal))
      this.chartData[0].data[1] = (((this.selectedGame.big8[1] * big8VotesLocal) + this.chartData[0].data[1] - prevVote[1]) / (big8VotesLocal))
      this.chartData[0].data[2] = (((this.selectedGame.big8[2] * big8VotesLocal) + this.chartData[0].data[2] - prevVote[2]) / (big8VotesLocal))
      this.chartData[0].data[3] = (((this.selectedGame.big8[3] * big8VotesLocal) + this.chartData[0].data[3] - prevVote[3]) / (big8VotesLocal))
      this.chartData[0].data[4] = (((this.selectedGame.big8[4] * big8VotesLocal) + this.chartData[0].data[4] - prevVote[4]) / (big8VotesLocal))
      this.chartData[0].data[5] = (((this.selectedGame.big8[5] * big8VotesLocal) + this.chartData[0].data[5] - prevVote[5]) / (big8VotesLocal))
      this.chartData[0].data[6] = (((this.selectedGame.big8[6] * big8VotesLocal) + this.chartData[0].data[6] - prevVote[6]) / (big8VotesLocal))
      this.chartData[0].data[7] = (((this.selectedGame.big8[7] * big8VotesLocal) + this.chartData[0].data[7] - prevVote[7]) / (big8VotesLocal))

    } else {

      if (this.selectedGame.big8) {
        // this.selectedGame.big8 = JSON.parse(this.selectedGame.big8)
        this.selectedGame.big8 = JSON.parse(this.selectedGame.big8)
      } else {
        this.selectedGame.big8 = [5, 5, 5, 5, 5, 5, 5, 5]
      }

      let additionMath = `${this.chartData[0].data[0]} = (((${this.selectedGame.big8[0]} * ${big8VotesLocal}) + ${this.chartData[0].data[0]}) / (${big8VotesLocal} + 1))`
      console.log('additionMath', additionMath)
      let additionMath1 = `${this.chartData[0].data[1]} = (((${this.selectedGame.big8[1]} * ${big8VotesLocal}) + ${this.chartData[0].data[1]}) / (${big8VotesLocal} + 1))`
      console.log('additionMath1', additionMath1)

      this.chartData[0].data[0] = (((this.selectedGame.big8[0] * big8VotesLocal) + this.chartData[0].data[0]) / (big8VotesLocal + 1))
      this.chartData[0].data[1] = (((this.selectedGame.big8[1] * big8VotesLocal) + this.chartData[0].data[1]) / (big8VotesLocal + 1))
      this.chartData[0].data[2] = (((this.selectedGame.big8[2] * big8VotesLocal) + this.chartData[0].data[2]) / (big8VotesLocal + 1))
      this.chartData[0].data[3] = (((this.selectedGame.big8[3] * big8VotesLocal) + this.chartData[0].data[3]) / (big8VotesLocal + 1))
      this.chartData[0].data[4] = (((this.selectedGame.big8[4] * big8VotesLocal) + this.chartData[0].data[4]) / (big8VotesLocal + 1))
      this.chartData[0].data[5] = (((this.selectedGame.big8[5] * big8VotesLocal) + this.chartData[0].data[5]) / (big8VotesLocal + 1))
      this.chartData[0].data[6] = (((this.selectedGame.big8[6] * big8VotesLocal) + this.chartData[0].data[6]) / (big8VotesLocal + 1))
      this.chartData[0].data[7] = (((this.selectedGame.big8[7] * big8VotesLocal) + this.chartData[0].data[7]) / (big8VotesLocal + 1))

      big8VotesLocal++

    }

    //  BELOW HERE IS SUBMISSION *********************************************************************

    let gameBDbig8Votes = {
      pkName: 'pk',
      pkValue: this.selectedGame.id,
      skName: 'sk',
      skValue: 'BD#' + this.selectedGame.id,
      attributeName: 'big8Votes',
      attributeValue: JSON.stringify(big8VotesLocal)
    }

    await this.backendApiGamesService.updateSingleAttributeForGame(gameBDbig8Votes)

    let big8reshaped =
      [
        this.chartData[0].data[0],
        this.chartData[0].data[1],
        this.chartData[0].data[2],
        this.chartData[0].data[3],
        this.chartData[0].data[4],
        this.chartData[0].data[5],
        this.chartData[0].data[6],
        this.chartData[0].data[7],
      ]

    let gameBDbig8 = {
      pkName: 'pk',
      pkValue: this.selectedGame.id,
      skName: 'sk',
      skValue: 'BD#' + this.selectedGame.id,
      attributeName: 'big8',
      attributeValue: JSON.stringify(big8reshaped)
    }
    await this.backendApiGamesService.updateSingleAttributeForGame(gameBDbig8)
    this.snackbarService.openSuccessSnackBar('Information Recorded in the Database')

    this.dialogRef.close('resetData')
  }





  // BELOW is random stuff **********************************************************************************
  // BELOW is random stuff **********************************************************************************
  // BELOW is random stuff **********************************************************************************
  // BELOW is random stuff **********************************************************************************
  // BELOW is random stuff **********************************************************************************
  // BELOW is random stuff **********************************************************************************






  async fetchBig8forCurrentGameAndUser() {

    let prefix = 'big8Vote#'
    let gameTrackingItems = await this.backendApiGameTrackingService.listGameTrackingItemsByGameByUser(this.selectedGame.id, prefix, 49)
    return gameTrackingItems
  }

  onComplexitySliderChanged() {
    this.complexityRating = this.form.get('complexityRating').value
    this.chartData[0].data[0] = this.form.get('complexityRating').value
    this.chart.update();
  }

  onDepthSliderChanged() {
    this.depthRating = this.form.get('depthRating').value
    this.chartData[0].data[1] = this.form.get('depthRating').value
    this.chart.update();
  }

  onDurationSliderChanged() {
    this.durationRating = this.form.get('durationRating').value
    this.chartData[0].data[2] = this.form.get('durationRating').value
    this.chart.update();
  }

  onInteractionSliderChanged() {
    this.interactionRating = this.form.get('interactionRating').value
    this.chartData[0].data[3] = this.form.get('interactionRating').value
    this.chart.update();
  }

  onCompetitionSliderChanged() {
    this.competitionRating = this.form.get('competitionRating').value
    this.chartData[0].data[4] = this.form.get('competitionRating').value
    this.chart.update();
  }

  onPlayersSliderChanged() {
    this.playersRating = this.form.get('playersRating').value
    this.chartData[0].data[5] = this.form.get('playersRating').value
    this.chart.update();
  }

  onChanceSliderChanged() {
    this.chanceRating = this.form.get('chanceRating').value
    this.chartData[0].data[6] = this.form.get('chanceRating').value
    this.chart.update();
  }

  onThematicSliderChanged() {
    this.thematicRating = this.form.get('thematicRating').value
    this.chartData[0].data[7] = this.form.get('thematicRating').value
    this.chart.update();
  }

  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }
  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
  }

  getCanvasPx() {
    return this.isMobileScreen ? 200 : 400
  }

}
