import { Component, Input, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { ImageGalleryGridComponent } from 'src/app/dashboard/dashboard-shared/components/media/image-gallery-grid/image-gallery-grid.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { MatDialog } from '@angular/material/dialog'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'

@Component({
    selector: 'app-view-convention-details',
    templateUrl: './view-convention-details.component.html',
    styleUrls: ['./view-convention-details.component.scss'],
})
export class ViewConventionDetailsComponent implements OnInit, OnDestroy {
    conventionDetails = new BehaviorSubject<any>(null)
    conventionDetails$ = this.conventionDetails.asObservable()

    thumbs = new BehaviorSubject<any>(null)
    thumbs$ = this.thumbs.asObservable()

    inputData: any
    user: any

    isMobileScreen: boolean
    private screenSizeSubscription: Subscription

    isGHAdmin: boolean

    constructor(
        private screenSizeService: ScreenSizeService,
        private backendApiUsersService: BackendAPIUsersService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private backendApiContentService: BackendAPIContentService,
    ) {}

    @Input() set _inputData(inputData: any) {
        console.log('inputData', inputData)
        this.inputData = inputData
        this.conventionDetails.next(this.inputData.convention)
        this.initImages()
    }

    currentUserId
    currentUserIsAdmin = false
    ngOnInit() {
        this.screenSizeSubscription = this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
                this.cdr.detectChanges()
            })

        this.backendApiUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user
            this.isGHAdmin = this.user.userLevel > 6
        })

        if (this.user.username === this.inputData.convention.extendedProps.eventOrganizerId) {
            this.currentUserIsAdmin = true
        } else {
            this.currentUserIsAdmin = false
        }
    }

    pathToMainImage
    initImages() {
        this.pathToMainImage = this.inputData.convention.extendedProps.mainImageFiles[1]

        this.thumbs.next([
            {
                imgPath: this.inputData.convention.extendedProps.mainImageFiles,
                isMainImage: true,
            },
        ])
    }

    async showAdditionalImages(): Promise<void> {
        let images = await this.backendApiContentService.listContentByEventID(
            this.inputData.eventDetails.ulid,
            'images',
            49,
        )

        let inputData = {
            images: images.content,
        }

        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Additional Images',
                component: ImageGalleryGridComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                inputData: inputData,
                hasCloseButton: true,
            },
            height: '70%',
            width: '40%',
            disableClose: true,
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
        })
    }

    ngOnDestroy(): void {
        if (this.screenSizeSubscription) {
            this.screenSizeSubscription.unsubscribe()
        }
    }

    private convertTo24Hour(time: string): string {
        const [timePart, modifier] = time.split(' ')
        let [hours, minutes] = timePart.split(':').map(Number)

        if (modifier.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12
        }
        if (modifier.toLowerCase() === 'am' && hours === 12) {
            hours = 0
        }

        const formattedHours = hours.toString().padStart(2, '0')
        const formattedMinutes = minutes.toString().padStart(2, '0')

        return `${formattedHours}:${formattedMinutes}`
    }

    formatTime(timeString: string): string {
        const date = new Date(`1970-01-01T${this.convertTo24Hour(timeString)}:00`)
        return isNaN(date.getTime())
            ? timeString
            : date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
    }
}
