<!-- <div class="row mb-5">
    <input type="file" (change)="onFileSelected($event)" accept=".heic" />
    <img [appHeicToJpeg]="selectedFile" alt="HEIC Image" />
    <button *ngIf="selectedFile" (click)="downloadConvertedImage()">Download</button>

</div> -->


<div>

    <div class="row gh-image-upload-container gh-image-upload-container-mobile" [hidden]="!isMobileScreen">

        <div class="col-12">
            <div class="row">

                <div class="col-12 text-center">
                    <div *ngIf="imageIsSelected">
                        <button mat-stroked-button id="crop-button" class="gh-stroked-button gh-stroked-button-primary"
                            (click)="openCropper()">
                            <mat-icon class="gh-icon">crop</mat-icon>
                            Crop Image
                        </button>
                    </div>
                </div>

                <div class="col-12">

                    <div class="gh-file-upload-button-container">
                        <label for="{{inputId}}" class="gh-file-upload-button"
                            [ngClass]="{ imageIsSelected: imageIsSelected }">
                            <mat-icon class="gh-icon" [ngClass]="{ imageIsSelected: imageIsSelected }">
                                {{imageIsSelected ? 'edit' : 'backup'}}</mat-icon>
                            {{imageIsSelected ? 'Change Image' : 'Upload Image'}}
                        </label>
                        <input id="{{inputId}}" type="file" (change)="onFileChanged($event)" />
                    </div>
                </div>

            </div>

        </div>

        <div class="col-12">
            <div class="img-container img-container-mobile">

                <ng-container *ngTemplateOutlet="images"></ng-container>

                <div id="delete-icon-container" *ngIf="imageIsSelected">
                    <mat-icon id="delete-icon" class="gh-icon gh-icon-clickable" (click)="deleteSelectedImage()">close
                    </mat-icon>
                </div>
            </div>
        </div>

    </div>

    <div [hidden]="isMobileScreen">

        <div class="gh-image-upload-container">
            <span class="inline-span">

                <div *ngIf="imageIsSelected">
                    <button mat-stroked-button id="crop-button" class="gh-stroked-button gh-stroked-button-primary"
                        (click)="openCropper()">
                        <mat-icon class="gh-icon">crop</mat-icon>
                        Crop Image
                    </button>
                </div>
                <div class="gh-file-upload-button-container">
                    <label for="{{inputId}}" class="gh-file-upload-button"
                        [ngClass]="{ imageIsSelected: imageIsSelected }">
                        <mat-icon class="gh-icon" [ngClass]="{ imageIsSelected: imageIsSelected }">
                            {{imageIsSelected ? 'edit' : 'backup'}}</mat-icon>
                        {{imageIsSelected ? 'Change Image' : 'Upload Image'}}
                    </label>
                    <input id="{{inputId}}" type="file" (change)="onFileChanged($event)" />
                </div>

            </span>

            <span class="inline-span ml-3">

                <div class="img-container">

                    <ng-container *ngTemplateOutlet="images"></ng-container>

                    <div id="delete-icon-container" *ngIf="imageIsSelected">
                        <mat-icon id="delete-icon" class="gh-icon gh-icon-clickable"
                            (click)="deleteSelectedImage()">close
                        </mat-icon>
                    </div>
                </div>

            </span>
        </div>

    </div>

</div>


<ng-template #images>
    <img [hidden]="fileIsHeic" [src]="selectedImage" alt="" [ngClass]="{ isProfileImg: isProfileImg }">

    <img [hidden]="!fileIsHeic" [appHeicToJpeg]="heicPreview" alt="" [ngClass]="{ isProfileImg: isProfileImg }">
</ng-template>