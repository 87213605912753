import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

import { Router } from '@angular/router'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { TournamentBuilderComponent } from '../../../generics/tournament-builder/tournament-builder.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SnackbarService } from '../../../services/user-action-feedback/snackbar.service'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-tournament-builder-setup',
    templateUrl: './tournament-builder-setup.component.html',
    styleUrls: ['./tournament-builder-setup.component.scss'],
})
export class TournamentBuilderSetupComponent implements OnInit {
    numberOfTeamOptions = [4, 8, 16, 32, 64, 128]
    numberOfTeamsSelection = 4

    teamSelections = []

    isRandomSetup = true

    constructor(
        //private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<TournamentBuilderSetupComponent>,
        private screenSizeService: ScreenSizeService,
        private snackbarService: SnackbarService,
        private router: Router,
    ) { }

    isMobileScreen = false

    ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.teamSelections = []
    }

    setNumberOfTeams(event) {
        this.numberOfTeamsSelection = event.value
    }

    setMaxIsReached() {
        this.snackbarService.openErrorSnackBar('Max teams is reached!')
    }

    updateTeams(event) {
        this.teamSelections = event
    }

    setIsRandomSetup(event) {
        this.isRandomSetup = event.value
    }

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.submit()
            this.closeEmitter.emit()
        }
    }

    @Output() closeEmitter = new EventEmitter()

    submit() {
        // create formgroup and check validity here before navigating

        if (this.teamSelections.length == this.numberOfTeamsSelection) {
            let setupData = {
                availableTeams: this.teamSelections,
                isRandomSetup: this.isRandomSetup,
            }
            this.openTournamentBuilder(setupData)
        } else {
            alert('You need to select more teams')
        }
    }

    shortcut(num) {
        var setupData

        switch (num) {
            case 4:
                setupData = {
                    availableTeams: [
                        { teamName: 'Tenacious D', members: ['Jack Black', 'Kyle Gass'] },
                        {
                            teamName: 'The Michaels',
                            members: ['Michael Jackson', 'Michael Jordan'],
                        },
                        { teamName: 'The Cartoons', members: ['Bugs Bunny', 'Elmer Fudd'] },
                        { teamName: 'The Nerds', members: ['Dave', 'Ben'] },
                    ],
                    isRandomSetup: this.isRandomSetup,
                }
                break

            case 8:
                setupData = {
                    availableTeams: [
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                    ],
                    isRandomSetup: this.isRandomSetup,
                }
                break

            case 16:
                setupData = {
                    availableTeams: [
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                    ],
                    isRandomSetup: this.isRandomSetup,
                }
                break

            case 32:
                setupData = {
                    availableTeams: [
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                    ],
                    isRandomSetup: this.isRandomSetup,
                }
                break

            case 64:
                setupData = {
                    availableTeams: [
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                    ],
                    isRandomSetup: this.isRandomSetup,
                }
                break

            case 128:
                setupData = {
                    availableTeams: [
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'michael jackson' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                        { teamName: null, members: 'Jack Black' },
                        { teamName: null, members: 'Shay Sucks' },
                        { teamName: null, members: 'michael jordan' },
                        { teamName: null, members: 'bugs bunny' },
                        { teamName: null, members: 'Kumar' },
                        { teamName: null, members: 'tom green' },
                        { teamName: null, members: 'Tom Vasel' },
                        { teamName: null, members: 'Carl' },
                        { teamName: null, members: 'old greg' },
                        { teamName: null, members: 'shrek' },
                        { teamName: null, members: 'mojo jojo' },
                        { teamName: null, members: 'derek zoolander' },
                        { teamName: null, members: 'Eddie Van Halen' },
                        { teamName: null, members: 'weird al' },
                        { teamName: null, members: 'Charlie Brown' },
                        { teamName: null, members: 'Harold' },
                    ],
                    isRandomSetup: this.isRandomSetup,
                }
                break
        }

        this.openTournamentBuilder(setupData)
    }

    openTournamentBuilder(setupData) {
        this.dialogRef.close()
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: 'Tournament',
                component: TournamentBuilderComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                inputData: setupData,
            },
        })
    }

}
