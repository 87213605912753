import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { START_YEAR_FOR_YEAR_PICKER } from '../../../../../../../../common/constants/gamesECTConstants'
@Component({
  selector: 'app-gh-year-picker',
  templateUrl: './gh-year-picker.component.html',
  styleUrls: ['./gh-year-picker.component.scss']
})
export class GhYearPickerComponent implements OnInit {

  years = []

  selectedYear
  @Input() set _selectedYear (selectedYear: number) {
    this.selectedYear = selectedYear 
  }

  label
  @Input() set _label (label: string) {
    this.label = label
  }

  startYear = START_YEAR_FOR_YEAR_PICKER
  @Input() set _startYear(startYear: number) {
    if (startYear) {
      this.startYear = startYear
    }
  }

  endYear = new Date().getFullYear() //! defaulted to current year
  @Input() set _endYear(endYear: number) {
    if (endYear) {
      this.endYear = endYear
    }
  }

  @Output() yearSelectedEmitter = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
    
    for (var i = this.endYear; i >= this.startYear; i--) {
      this.years.push({name: i, value: i})
    }
    this.years.push ({name: 'Unknown', value: undefined})

  }

  yearChanged(event) {
    this.yearSelectedEmitter.emit(event.value)
  }

  compareFunction(o1: any, o2: any) {
    return (o1 == o2)
  }

}
