import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DatetimeHelperService {

  isLeapYear(year: number): boolean {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)
  }

  datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()

  datesAreInSameMonth = (first, second) => //! BROKEN!!!????
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth()



  //! IMPORTANT: both inputs must be in MS
  //!     it is hardly worth even having this helper aside from having this reminder
  // ??????????????? any way to enforce this?
  // ?? custom param types?
  // ?? logical check and return error if inputs are not what is expected?
  getMinutesDifferenceAbsValue(nowTimeStamp: number, otherTimestamp: number) : number {
    return Math.abs(nowTimeStamp - otherTimestamp) / 60000
  }

}
