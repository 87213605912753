<div class="parent-container">
    <div class="inner-container">
        <div class="row">

            <!-- IMAGE -->
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <div class="img-container">
                            <img [src]="inputData.mainImageFiles[0]" [alt]="inputData.title" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="title-container">
                            <mat-card-title class="gh-sm-title">{{ inputData.title }}
                            </mat-card-title>
                        </div>
                    </div>
                </div>
            </div>

            <!-- OBJECTIVE INFO (BOTH) -->
            <div class="col-2 game-info-overview-container">
                <div class="row">


                    <!-- OBJECTIVE INFO - PLAYERS -->
                    <div class="col-6 col-sm-6 col-md-6 col-lg-12">
                        <span class="hoverable" matTooltip="Number of players according to publisher"
                            style="cursor: default;" matTooltipPosition="above">
                            <span class="inline-span">
                                <mat-icon class="gh-icon tab-icon">people</mat-icon>
                            </span>
                            <span class="inline-span">
                                <mat-card-subtitle>
                                    {{getMinMaxPlayers() | nullPlaceholder}}
                                </mat-card-subtitle>
                            </span>
                        </span>
                        <span class="hoverable pl-1" matTooltip="Best number of players according to the community"
                            matTooltipPosition="above" (click)="openSubmitPlayerCount()">
                            <span class="inline-span">
                                <mat-icon class="gh-icon tab-icon gh-icon-success">people</mat-icon>
                            </span>
                            <span class="inline-span">
                                <mat-card-subtitle>
                                    {{getVotedMinMaxPlayers() | nullPlaceholder}}
                                </mat-card-subtitle>
                            </span>
                        </span>
                    </div>

                    <!-- OBJECTIVE INFO - DURATION -->
                    <div class="col-6 col-sm-6 col-md-6 col-lg-12">
                        <!-- <span class="hoverable" matTooltip="Game time in minutes" style="cursor: default;" matTooltipPosition="above"> -->
                        <span class="hoverable">
                            <span class="inline-span">
                                <mat-icon class="gh-icon tab-icon">hourglass_full
                                </mat-icon>
                            </span>
                            <span class="inline-span">
                                <mat-card-subtitle>
                                    <p *ngIf="inputData.minPlaytime != inputData.maxPlaytime; else noRangePlaytime">
                                        {{inputData.minPlaytime}}-{{inputData.maxPlaytime | nullPlaceholder}}
                                    </p>
                                    <ng-template #noRangePlaytime>
                                        {{inputData.minPlaytime | nullPlaceholder}}
                                    </ng-template>

                                </mat-card-subtitle>
                            </span>
                            <span class="hoverable pl-1" matTooltip="Duration Based on Real User Plays"
                                matTooltipPosition="above" (click)="openViewRealDuration()">
                                <span class="inline-span">
                                    <mat-icon class="gh-icon tab-icon gh-icon-success">zoom_in</mat-icon>
                                </span>
                            </span>
                        </span>
                    </div>


                    <!-- todo FUTURE FEATURE PLAYERS LIKE YOU -->
                    <!-- BRING BACK Game Detail FEATURES afsoifaoisdjaofidsjdoiafjdoi -->

                    <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div class="row">
                            <div class="col-12">
                                <mat-card-subtitle class="gh-md-subtitle">Players like you rate this:
                                </mat-card-subtitle>
                            </div>
                            <div class="col-12">
                                <mat-card-title class="gh-xsm-title margin-top-negative">
                                    Overwhelmingly Positive
                                </mat-card-title>
                            </div>
                            <div class="col-12">
                                <mat-card-subtitle>
                                    * Based on 212 ratings
                                </mat-card-subtitle>
                            </div>
                        </div>
                    </div> -->

                </div>
            </div>


            <!-- PUBLISHER DESCRIPTION -->
            <div class="col-4 publisher-description-container">
                <div class="row">
                    <mat-card-title class="gh-md-subtitle">Publisher Description:</mat-card-title>
                </div>
                <div class="row">
                    <mat-card-subtitle id="publisher-description">
                        {{inputData.publisherDescription}}
                    </mat-card-subtitle>
                </div>
            </div>


            <!-- BANNER AD -->
            <div class="col-2 publisher-description-container">
                <div class="row">
                    <app-banner-ad [_inputData]="bannerAdInputDataSpecific" [_sourceOfInput]="'app-profile'">
                    </app-banner-ad>
                </div>
            </div>


        </div>
    </div>
</div>

<!-- 
<div class="row mt-4">
    <div class="col-12">
        <mat-card-subtitle class="gh-lg-subtitle additional-images-subtitle">
            Additional Images:
        </mat-card-subtitle>
    </div>
    <div class="col-12">
        <div class="carousel-container">
            <ngu-carousel class="carousel" #myCarousel [inputs]="carouselConfig" [dataSource]="carouselItems">
                <div *nguCarouselDef="let item;" class="item">
                    <div class="img-container">
                        <img [src]="item.img" />
                    </div>
                </div>

                <button NguCarouselNext class="rightRs">
                    <mat-icon>
                        keyboard_arrow_right
                    </mat-icon>
                </button>
                <button NguCarouselPrev class="leftRs">
                    <mat-icon>
                        keyboard_arrow_left
                    </mat-icon>
                </button>
                <ul class="myPoint" NguCarouselPoint>
                    <li *ngFor="let j of myCarousel.pointNumbers; let jnd = index" [class.active]="j==myCarousel.activePoint" (click)="myCarousel.moveTo(jnd)"></li>
                </ul>
            </ngu-carousel>
        </div>
    </div>
</div> -->