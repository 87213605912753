<div class="parent-container">

    <mat-card-content [ngClass]="{ isMobileScreen: isMobileScreen }">

        <!--  TODO: need to deal with the fact that item might be a haven, not a game, so maybe template should be called something like userHasAlreadyRatedThisItem, and the associated logic will be needed... -->
        <div *ngIf="!currentUserHasAlreadyReviewedThisGame; else userHasAlreadyRatedThisGame">
            <form [formGroup]="editorForm">


                <div class="data-group data-group-no-min-height p-5 mb-4">
                    <div class="row" *ngIf="inputData.hasTypeOptions">
                        <div class="col-12 col-md-6">
                            <mat-card-subtitle class="gh-md-subtitle">
                                I'm reviewing a:
                            </mat-card-subtitle>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-radio-group aria-label="Select an option" (change)="selectSubject($event)">
                                <mat-radio-button [checked]="true" value="GAME" class="ml-5">Game</mat-radio-button>
                                <mat-radio-button value="HAVEN" class="ml-5">Haven</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="selectedReviewType == 'GAME'">

                                <!-- TODO: there's gotta be a better way to do this. Only difference is _selectedItems. For now this is fine... -->
                                <div *ngIf="selectedGame; else noSelectedGame">
                                    <app-autocomplete-chiplist *ngIf="coreGames" [_items]="coreGames"
                                        [_itemsAreObjects]="true" [_label]="'Game Title'"
                                        [_selectedItems]="[selectedGame]" [_allowDuplicates]="false"
                                        [_allowCustomInput]="false" [_singleInput]="true"
                                        [_sourceOfInput]="'app-share-modal-advanced-log-play1'"
                                        (selectionChanged)="selectGame($event)">
                                    </app-autocomplete-chiplist>
                                </div>

                                <ng-template #noSelectedGame>
                                    <app-autocomplete-chiplist *ngIf="coreGames" [_items]="coreGames"
                                        [_itemsAreObjects]="true" [_label]="'Game Title'" [_allowDuplicates]="false"
                                        [_allowCustomInput]="false" [_singleInput]="true"
                                        [_sourceOfInput]="'app-share-modal-advanced-log-play1'"
                                        (selectionChanged)="selectGame($event)">
                                    </app-autocomplete-chiplist>
                                </ng-template>

                                <!-- ! deal with expansions??? -->
                            </div>

                            <div class="div" *ngIf="selectedReviewType == 'HAVEN'">
                                <app-autocomplete-chiplist *ngIf="coreHavens" [_items]="coreHavens"
                                    [_label]="'Haven Title'" [_allowDuplicates]="false" [_allowCustomInput]="false"
                                    [_itemsAreObjects]="true" [_singleInput]="true"
                                    [_sourceOfInput]="'app-share-modal-advanced-log-play1'"
                                    (selectionChanged)="selectHaven($event)">
                                </app-autocomplete-chiplist>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-12">
                        <mat-form-field class="gh-form-field">
                            <mat-label>Title of Review:</mat-label>
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-12 text-center mb-4">
                        <span class="inline-span">
                            <mat-card-subtitle>Your Rating:</mat-card-subtitle>
                        </span>
                        <span class="inline-span ml-3">

                            <div *ngIf="user.userLevel > onboardingUserLevelThreshold; else onboardingButton">
                                <div style="margin-top: -2rem; margin-left: 0.5rem;">
                                    <app-gh-ratings [_isReadOnly]="false" [_rating]="myStarRating"
                                        (submitEmitter)="setStarRating($event)" (clearEmitter)="setStarRating(null)">
                                    </app-gh-ratings>
                                </div>
                            </div>

                            <ng-template #onboardingButton>
                                <button *ngIf="!isMobileScreen" mat-raised-button (click)="openBulkRateGames()"
                                    id="continue-onboarding-button" class="gh-button gh-button-secondary mr-3">Continue
                                    Onboarding Survey</button>

                                <mat-icon *ngIf="isMobileScreen"
                                    class="gh-icon gh-icon-warning gh-icon-clickable wiggle-icon"
                                    (click)="openBulkRateGames()">
                                    error
                                </mat-icon>
                            </ng-template>

                        </span>
                    </div>









                    <div class="col-12 text-center">
                        <!-- !NEED HELP HERE -->
                        <app-gh-file-uploader (selectedImageChanged)="selectedImageChanged($event)"
                            [_defaultImage]="reviewImage" [_isProfileImg]="false" #fileUploader [_isProfileImg]="false">
                        </app-gh-file-uploader>
                    </div>










                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="rte-container">
                            <editor apiKey="3gji6f46ozf9p0mnr5tjx6ci1vt5457ku6so2ux0chbvsw38"
                                [init]="{ plugins: 'lists link image table code help wordcount'}" outputFormat="html"
                                (onSelectionChange)="handleEvent($event)" [(ngModel)]="tinyText"
                                formControlName="editor">
                                ></editor>
                        </div>
                    </div>
                </div>


                <!-- todo NOTE FALSE -->
                <div class="row mt-5" *ngIf="false">

                    <!-- FUTURE FEATURE -->
                    <!-- FUTURE FEATURE -->
                    <!-- FUTURE FEATURE -->
                    <!-- FUTURE FEATURE -->
                    <!-- FUTURE FEATURE -->
                    <!-- FUTURE FEATURE -->

                    <div class="col-12">

                        <mat-accordion class="gh-accordion">
                            <mat-expansion-panel>

                                <mat-expansion-panel-header>

                                    <mat-panel-title>
                                        <span class="header-span" class="title">Rate Game Qualities?</span>
                                    </mat-panel-title>

                                </mat-expansion-panel-header>

                                <section class="mt-4">

                                    <mat-card-subtitle class="gh-lg-subtitle">Game Qualities:</mat-card-subtitle>

                                    <!-- <div class="row gh-checkbox-container">
                                        <div class="col-12">
                                            <mat-checkbox [checked]="false" (change)="toggleInitialRatingsAreEnabled($event)">
                                                Provide initial values?
                                            </mat-checkbox>
                                        </div>
                                    </div> -->

                                    <div class="row mt-3">

                                        <div class="col-lg-3 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                                <div class="row header-row">
                                                    <!-- todo Annoying CSS Heck here asodijfdosijfsodfjiasdoij -->
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                                            <strong>Complexity</strong>
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <!-- <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Number of Rules</mat-card-subtitle>
                                                    </div>
                                                </div> -->
                                                <div class="row mt-5"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider formControlName="complexityRating" thumbLabel
                                                        tickInterval="1" min="0" max="10" value="5" class="slider">
                                                    </mat-slider>
                                                </div>

                                                <div class="row mt-1">
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Very Few Rules</mat-card-subtitle>
                                                    </div>
                                                    <div class="col-4 ">
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Lots of Rules</mat-card-subtitle>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                                            <strong>Depth</strong>
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <!-- <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Time to Mastery</mat-card-subtitle>
                                                    </div>
                                                </div> -->
                                                <div class="row mt-5"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="depthRating"
                                                        tickInterval="1" min="0" max="10" value="5" class="slider">
                                                    </mat-slider>
                                                </div>

                                                <div class="row mt-1">
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Easy to Master</mat-card-subtitle>
                                                    </div>
                                                    <div class="col-4 ">
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Difficult to Master</mat-card-subtitle>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                                            <strong>Duration</strong>
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <!-- <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Time to Play</mat-card-subtitle>
                                                    </div>
                                                </div> -->
                                                <div class="row mt-5"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="durationRating"
                                                        tickInterval="1" min="0" max="10" value="5" class="slider">
                                                    </mat-slider>
                                                </div>

                                                <div class="row mt-1">
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Short</mat-card-subtitle>
                                                    </div>
                                                    <div class="col-4 ">
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Long</mat-card-subtitle>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                                            <strong>Player
                                                                Interaction</strong>
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <!-- <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Influence on other players</mat-card-subtitle>
                                                    </div>
                                                </div> -->
                                                <div class="row mt-5"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="interactionRating"
                                                        tickInterval="1" min="0" max="10" value="5" class="slider">
                                                    </mat-slider>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Player Actions are Independent
                                                        </mat-card-subtitle>
                                                    </div>
                                                    <div class="col-4 ">
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Players Influence Each Other
                                                        </mat-card-subtitle>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row initial-data-row mt-3">

                                        <div class="col-lg-3 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                                            <strong>Competition</strong>
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <!-- <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Level of Competition</mat-card-subtitle>
                                                    </div>
                                                </div> -->
                                                <div class="row mt-5"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="competitionRating"
                                                        tickInterval="1" min="0" max="10" value="5"
                                                        style="width: 100%;">
                                                    </mat-slider>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Cooperative</mat-card-subtitle>
                                                    </div>
                                                    <div class="col-4 ">
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Competitive</mat-card-subtitle>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                                            <strong>Player
                                                                Count</strong>
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <!-- <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Number of Players</mat-card-subtitle>
                                                    </div>
                                                </div> -->
                                                <div class="row mt-5"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="playersRating"
                                                        tickInterval="1" min="0" max="10" value="5"
                                                        style="width: 100%;">
                                                    </mat-slider>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Solo Play</mat-card-subtitle>
                                                    </div>
                                                    <div class="col-4 ">
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Large Group</mat-card-subtitle>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                                            <strong>Chance</strong>
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <!-- <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Amount of Luck</mat-card-subtitle>
                                                    </div>
                                                </div> -->
                                                <div class="row mt-5"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="chanceRating"
                                                        tickInterval="1" min="0" max="10" value="5"
                                                        style="width: 100%;">
                                                    </mat-slider>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Skill-Based</mat-card-subtitle>
                                                    </div>
                                                    <div class="col-4 ">
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Luck-Based</mat-card-subtitle>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border game-qualities-min-height">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                                            <strong>Thematic</strong>
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <!-- <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Simulation of Reality</mat-card-subtitle>
                                                    </div>
                                                </div> -->
                                                <div class="row mt-5"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="thematicRating"
                                                        tickInterval="1" min="0" max="10" value="5"
                                                        style="width: 100%;">
                                                    </mat-slider>
                                                </div>
                                                <div class="row mt-1">
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Abstract / Symbolic</mat-card-subtitle>
                                                    </div>
                                                    <div class="col-4 ">
                                                    </div>
                                                    <div class="col-4 text-center">
                                                        <mat-card-subtitle>Simulates Reality</mat-card-subtitle>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </section>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </div>
                </div>

                <!-- <div class="row mt-5" *ngIf="selectedReviewType == 'HAVEN'">
                    <div class="col-12">

                        <mat-accordion class="gh-accordion">
                            <mat-expansion-panel>

                                <mat-expansion-panel-header>

                                    <mat-panel-title>
                                        <span class="header-span" class="title">Rate Haven Qualities?</span>
                                    </mat-panel-title>

                                    <mat-panel-description>
                                        <span class="header-span header-description-span pt-3">6 Qualities that define a
                                            Haven?</span>
                                    </mat-panel-description>

                                </mat-expansion-panel-header>


                                <section class="mt-4">
                                    <mat-card-subtitle class="gh-lg-subtitle">Initital Ratings (optional):
                                    </mat-card-subtitle>

                                    <div class="row mt-3">

                                        <div class="col-lg-4 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">Gaming
                                                            Atmosphere
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Focus on Gaming?</mat-card-subtitle>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider formControlName="gamingAtmosphere" thumbLabel
                                                        tickInterval="1" min="0" max="10" value="5" class="slider">
                                                    </mat-slider>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">
                                                            Competition
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Casual to Competitive</mat-card-subtitle>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="competitionLevel"
                                                        tickInterval="1" min="0" max="10" value="5" class="slider">
                                                    </mat-slider>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">Tables
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>How many tables</mat-card-subtitle>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="tableCount" tickInterval="1"
                                                        min="0" max="10" value="5" class="slider">
                                                    </mat-slider>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row initial-data-row mt-3">

                                        <div class="col-lg-4 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">Library
                                                            Size
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Number of games availible to play
                                                        </mat-card-subtitle>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="librarySize"
                                                        tickInterval="1" min="0" max="10" value="5" class="slider">
                                                    </mat-slider>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">Retail
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Stuff for sale</mat-card-subtitle>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="retail" tickInterval="1"
                                                        min="0" max="10" value="5" style="width: 100%;">
                                                    </mat-slider>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6 col-xs-12">
                                            <div class="gh-content-group rounded-border">
                                                <div class="row header-row">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle class="gh-md-subtitle rating-header">Food
                                                        </mat-card-subtitle>
                                                        <mat-divider></mat-divider>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-12 text-center">
                                                        <mat-card-subtitle>Food options availible</mat-card-subtitle>
                                                    </div>
                                                </div>
                                                <div class="row mt-2"
                                                    [ngClass]="{ disabledSliderContainer: !initialRatingsAreEnabled }"
                                                    matTooltip="{{initialRatingsAreEnabled ? '' : initialRatingsCheckboxMessage}}"
                                                    matTooltipPosition="above">
                                                    <mat-slider thumbLabel formControlName="food" tickInterval="1"
                                                        min="0" max="10" value="5" style="width: 100%;">
                                                    </mat-slider>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </section>

                            </mat-expansion-panel>
                        </mat-accordion>

                    </div>
                </div> -->

            </form>



        </div>

        <ng-template #userHasAlreadyRatedThisGame>

            <div class="row">
                <div class="col-12 mb-4 text-center">
                    You have already reviewed this game. However, you can edit your review!
                </div>

                <div class="col-12 text-center">
                    <button mat-raised-button class="gh-button gh-button-primary" (click)="clearSelectedGame()">Remove
                        Game Selection</button>
                </div>
            </div>



        </ng-template>

    </mat-card-content>
</div>