import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { BehaviorSubject } from 'rxjs-compat'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { iProfileAddOrEditSimpleEventInput } from 'src/app/dashboard/dashboard-shared/interfaces/events/iProfileAddOrEditSimpleEventInput'
import { ArrayHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/array-helper.service'
import { ImageMultiSizeService } from 'src/app/dashboard/dashboard-shared/services/helpers/image-multi-size.service'
import { ulid } from 'ulid'
import dayjs from 'dayjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { BackendAPIMessageService } from 'src/app/backend-api-services/backend-api-message.service'
import { UsersService } from 'src/app/dashboard/dashboard-shared/services/users/users.service'
import { S3ContentHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/s3-content-helper.service'
import { GroupsService } from 'src/app/dashboard/dashboard-shared/services/groups/groups.service'
import { HavensService } from 'src/app/dashboard/dashboard-shared/services/havens/havens.service'
import { SnackbarService } from 'src/app/dashboard/dashboard-shared/services/user-action-feedback/snackbar.service'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ThemePalette } from '@angular/material/core'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { InfoMessageComponent } from '../../../../modals/info-message/info-message.component'
import { SimpleInfoMessageComponent } from '../../../../modals/simple-info-message/simple-info-message.component'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-profile-add-or-edit-simple-event',
    templateUrl: './profile-add-or-edit-simple-event.component.html',
    styleUrls: ['./profile-add-or-edit-simple-event.component.scss'],
})
export class ProfileAddOrEditSimpleEventComponent implements OnInit {
    privacyOptions = [
        {
            text: 'Private',
            icon: 'people',
            value: 'private',
        },
        {
            text: 'Public',
            icon: 'public',
            value: 'public',
        },
    ]

    officialStatusOptions = [
        {
            text: 'Unofficial',
            icon: 'cake',
            value: 'unofficial',
        },
        {
            text: 'Official',
            icon: 'business',
            value: 'official',
        },
    ]

    @ViewChild('variableLengthSelector', { static: false }) variableLengthSelector
    @ViewChild('fileUploader', { static: false }) fileUploader

    privacy
    officalStatus

    repeatOptions = ['Every day', 'Every week', 'Every month', 'Every year', 'Custom...']

    compareFunction(o1: any, o2: any) {
        return o1 == o2
    }

    coreGameData = new BehaviorSubject(null)
    coreGameData$ = this.coreGameData.asObservable()

    coreUsers = new BehaviorSubject(null)
    coreUsers$ = this.coreUsers.asObservable()

    myFriends = new BehaviorSubject(null)
    myFriends$ = this.myFriends.asObservable()

    groupToEdit
    havenToEdit

    coreHavens = new BehaviorSubject(null)
    coreHavens$ = this.coreHavens.asObservable()

    coreGroups = new BehaviorSubject(null)
    coreGroups$ = this.coreGroups.asObservable()

    user

    mainImageToEdit
    inputData
    isEditing = false

    @Input() set _inputData(inputData: iProfileAddOrEditSimpleEventInput) {
        if (inputData.groupTitle != '') {
            this.groupToEdit = {
                title: inputData.groupTitle,
            }
        }

        this.inputData = inputData
        this.isEditing = inputData.isEditing // if input data exists, then this must be true... right???
        // todo Should there be something like: isFromMyProfile here?

        this.collection = []
        if (this.inputData && this.inputData.eventDetails) {
            this.collection = this.inputData.eventDetails.selectedGames
            this.hasInitialSelections = true
        }

        try {
            this.mainImageToEdit = this.inputData.eventDetails.mainImageFiles[0]
        } catch (err) {
            this.mainImageToEdit = ''
        }
    }

    @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
        if (parentSubmitClicked) {
            this.submit()
        }
    }

    @Output() closeEmitter = new EventEmitter()

    eventForm: UntypedFormGroup
    selectedImage
    updatedCollectionToEmit
    collection = []

    datetimeValue: string
    datetimeValueEnd: string
    constructor(
        private formBuilder: UntypedFormBuilder,
        private backendApiGamesService: BackendAPIGamesService,
        private arrayHelperService: ArrayHelperService,
        private dialogRef: MatDialogRef<ProfileAddOrEditSimpleEventComponent>,
        private backendAPIUsersService: BackendAPIUsersService,
        private backendAPIMessageService: BackendAPIMessageService,
        private usersService: UsersService,
        private s3ContentHelperService: S3ContentHelperService,
        private havensService: HavensService,
        private snackbar: MatSnackBar,
        private groupsService: GroupsService,
        private snackbarService: SnackbarService,
        private screenSizeService: ScreenSizeService,
        private dialog: MatDialog,
    ) {}

    isMobileScreen = false
    async ngOnInit() {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.backendAPIUsersService.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe(async (user) => {
                this.user = user

                this.usersService.myFriends$.pipe(untilDestroyed(this)).subscribe((myFriends) => {
                    this.myFriends.next(myFriends)
                })

                this.usersService.coreUsers$
                    .pipe(untilDestroyed(this))
                    .subscribe(async (coreUsersResult) => {
                        let coreUsersWithoutCurrentUser = this.arrayHelperService.removeFromArray(
                            coreUsersResult,
                            this.user,
                            true,
                            'username',
                        )
                        this.coreUsers.next(coreUsersWithoutCurrentUser)

                        let myFriendsClean = this.myFriends.value.map((ob) => ob.sk)

                        let myFriendsExtraRemoved = myFriendsClean.map((str) =>
                            str.replace('#friendOf#', ''),
                        )

                        let friendsOnly = this.arrayHelperService.extractSubsetFromArray(
                            coreUsersWithoutCurrentUser,
                            myFriendsExtraRemoved,
                            true,
                            false,
                            'username',
                        )

                        this.coreUsers.next(friendsOnly)
                    })
            })

        await this.groupsService.coreGroups$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreGroups) => {
                if (coreGroups) {
                    this.coreGroups.next(coreGroups)
                }
            })

        await this.havensService.coreHavens$
            .pipe(untilDestroyed(this))
            .subscribe(async (coreHavens) => {
                if (coreHavens) {
                    this.coreHavens.next(coreHavens)
                }
            })

        this.fetchCoreGameData()

        this.eventForm = this.formBuilder.group({
            date: [''],
            end: [''],
            organizerTitle: [''],
            title: [''],
            description: [''],
            player2: '',
            havenTitle: [''],
            groupTitle: [''],
            repeatOption: [''],
            privacy: [
                this.isEditing ? this.inputData.eventDetails.privacy : this.privacyOptions[0],
            ],
            officalStatus: 'unofficial',
        })
        this.initForm()

        if (!this.isEditing) {
            this.setDateForNewEvent()
        } else {
            this.setDateForEditEvent()
        }
    }

    nextCoreGameDataToken //todo need to finish next token logic
    async fetchCoreGameData() {
        let coreGameDataResult = await this.backendApiGamesService.listAllGameCoreData(
            1000,
            this.nextCoreGameDataToken,
        )
        this.coreGameData.next(coreGameDataResult.coreGames)

        //! once done, move this logic tp helper class (array helper service)
        this.coreGameData.next(
            await this.arrayHelperService.removePrependingCharacters(this.coreGameData.value, '#'),
        )
    }

    selectedGamesArray
    hasInitialSelections = false
    onSelectionsChanged(event) {
        this.selectedGamesArray = event

        if (this.isEditing || !this.hasInitialSelections) {
            this.updatedCollectionToEmit = event
            this.hasInitialSelections = true
        }
    }

    inputValue
    dateValue: string

    setDateForNewEvent() {
        let origionalDateFromClick = dayjs(this.inputData.date)
        origionalDateFromClick = origionalDateFromClick.set('hour', 19)
        let twoHoursLater = origionalDateFromClick.add(2, 'h')
        this.datetimeValue = origionalDateFromClick.format('YYYY-MM-DDTHH:mm')
        this.datetimeValueEnd = twoHoursLater.format('YYYY-MM-DDTHH:mm')
    }

    setDateForEditEvent() {
        let start = dayjs(this.inputData.date)
        let end = dayjs(this.inputData.end)
        this.datetimeValue = start.format('YYYY-MM-DDTHH:mm')
        this.datetimeValueEnd = end.format('YYYY-MM-DDTHH:mm')
    }

    setRepeatOption(event) {
        alert('What now?')
    }

    dateViaDirectInsertion
    initForm() {
        let officialStaus = this.officialStatusOptions[0]
        let privacyStatus = this.privacyOptions[0]

        for (let option of this.officialStatusOptions) {
            if (option.value == this.inputData.officalStatus) {
                officialStaus = option
            }
        }

        for (let option of this.privacyOptions) {
            if (option.value == this.inputData.privacy) {
                privacyStatus = option
            }
        }

        // EDITING
        if (this.isEditing) {
            let privacyToEdit =
                this.inputData.eventDetails.privacy == 'private'
                    ? this.privacyOptions[0]
                    : this.privacyOptions[1]

            this.eventForm.get('title').patchValue(this.inputData.eventDetails.eventTitle)
            this.eventForm.get('description').patchValue(this.inputData.eventDetails.description)
            this.eventForm
                .get('organizerTitle')
                .patchValue(this.inputData.eventDetails.eventOrganizer)
            this.eventForm.get('date').patchValue(this.inputData.eventDetails.date)
            this.eventForm.get('end').patchValue(this.inputData.eventDetails.end)
            this.eventForm.get('privacy').patchValue(privacyToEdit)
            this.eventForm.get('officalStatus').patchValue(this.inputData.officalStatus)

            // CREATING NEW
        } else {
            this.setDateForNewEvent()
            this.eventForm.get('organizerTitle').patchValue(this.inputData.currentUser.title)
            this.eventForm.get('date').patchValue(this.inputData.date)
            this.eventForm.get('end').patchValue(this.inputData.end)
            this.eventForm.get('privacy').patchValue(privacyStatus)
            this.eventForm.get('officalStatus').patchValue(officialStaus)
        }
    }

    async submit() {
        if (
            this.variableLengthSelector.myForm.touched &&
            this.variableLengthSelector.formIsInvalid()
        ) {
            this.variableLengthSelector.submitIsClicked = true
            this.snackbarService.openErrorSnackBar('Games form is invalid.')
        } else {
            if (this.eventForm.get('title').value == '') {
                alert('Title Required')
            } else {
                let files

                if (!this.isEditing) {
                    files = this.fileUploader.imageChanged ? [this.fileUploader.rawImage] : null
                } else {
                    files = this.fileUploader.imageChanged
                        ? [this.fileUploader.rawImage]
                        : this.inputData.eventDetails.mainImageFiles
                }

                let hereAreMainImageFiles = await this.s3ContentHelperService.prepareMainImageFiles(
                    files,
                )

                let ulidForNewCalendarEvent = ulid().toLowerCase()

                let newEvent = {
                    date: this.eventForm.get('date').value,
                    end: this.eventForm.get('end').value,
                    havenId: this.inputData.haven.id.toLowerCase(),
                    havenZipCode: this.inputData.haven.havenZipCode,
                    pk: this.isEditing ? this.inputData.eventDetails.pk : ulidForNewCalendarEvent,
                    prefix: 'e',
                    title: this.eventForm.get('title').value,
                    extendedProps: {
                        date: this.eventForm.get('date').value,
                        description: this.eventForm.get('description').value,
                        end: this.eventForm.get('end').value,
                        eventOrganizer: this.eventForm.get('organizerTitle').value,
                        eventOrganizerId: this.inputData.currentUser.username,
                        eventTitle: this.eventForm.get('title').value,
                        havenTitle: this.inputData.haven.title,
                        havenZipCode: this.inputData.haven.havenZipCode,
                        groupTitleText: this.eventForm.get('groupTitle').value,
                        havenTitleText: this.eventForm.get('havenTitle').value,
                        privacy: this.eventForm.get('privacy').value.value,
                        officalStatus: undefined,
                        mainImageFiles: hereAreMainImageFiles,
                        selectedGames: this.isEditing
                            ? this.updatedCollectionToEmit
                            : this.selectedGamesArray,
                        ulid: this.isEditing
                            ? this.inputData.eventDetails.pk
                            : ulidForNewCalendarEvent,
                        wasEdited: this.isEditing,
                    },
                }

                if (
                    !this.isEditing &&
                    this.selectedUsersToInvite &&
                    this.selectedUsersToInvite[0]
                ) {
                    for (let user of this.selectedUsersToInvite) {
                        let input = {
                            sentToUID: user.username,
                            sentToTitle: user.title,
                            sentFromUID: this.user.username,
                            sentFromTitle: this.user.title,
                            eventID: ulidForNewCalendarEvent,
                            type: 'EventInvite',
                            extendedProps: {
                                date: this.eventForm.get('date').value,
                                description: this.eventForm.get('description').value,
                                end: this.eventForm.get('end').value,
                                eventOrganizer: this.eventForm.get('organizerTitle').value,
                                eventOrganizerId: this.inputData.currentUser.username,
                                eventTitle: this.eventForm.get('title').value,
                                havenTitle: this.inputData.haven.title,
                                havenZipCode: this.inputData.haven.havenZipCode,
                                groupTitleText: this.eventForm.get('groupTitle').value,
                                havenTitleText: this.eventForm.get('havenTitle').value,

                                mainImageFiles: hereAreMainImageFiles,
                                privacy: this.eventForm.get('privacy').value.value,
                                officalStatus: this.eventForm.get('officalStatus').value.value,

                                selectedGames: this.isEditing
                                    ? this.updatedCollectionToEmit
                                    : this.selectedGamesArray,
                                ulid: this.isEditing
                                    ? this.inputData.eventDetails.pk
                                    : ulidForNewCalendarEvent,
                                wasEdited: this.isEditing,
                            },
                        }

                        // ! here WE NEED THE FILES IN THE PROPER SHAPE BUT THIS IS DONE AFTER EMIT

                        this.backendAPIMessageService.createInviteItem(input)
                    }
                }

                this.closeEmitter.emit({
                    newEvent: newEvent,
                    files: files,
                    attendees: this.inputData.attendees,
                })
                this.dialogRef.close({
                    newEvent: newEvent,
                    files: files,
                    attendees: this.inputData.attendees,
                })
            }
        }
    }

    selectGroup(group) {
        this.eventForm.get('groupTitle').patchValue(group[0].title)
    }

    selectHaven(haven) {
        this.eventForm.get('havenTitle').patchValue(haven[0].title)
    }

    setSelectedImage(event) {
        this.selectedImage = event
    }

    selectedUserToInvite
    selectedUsersToInvite = null
    selectUsersToInvite(users) {
        // todo need to implement a soltion to check if user has contentPreference to ALL
        this.selectedUsersToInvite = users
    }

    onRepeatEventChange(event) {
        console.log('repeat event option changed!!!!', event)
    }

    showInfo(isPrivacy: boolean) {
        let privacyInfoMessage = 'Public Events: Visible to everyone'
        let privacyInfoMessage2 =
            'Private Events: Visible only to you and people who have accepted your invitation'
        // let officialStatusInfoMessage = 'Dave and Gina can fill this in and I can style it better. Pellentesque lorem mi, aliquam eu erat quis, fermentum gravida dolor. Pellentesque turpis metus, dictum ut condimentum ut, vehicula ac lorem. '
        // let officialStatusInfoMessage2 = 'Dave and Gina can fill this in and I can style it better. Pellentesque lorem mi, aliquam eu erat quis, fermentum gravida dolor. Pellentesque turpis metus, dictum ut condimentum ut, vehicula ac lorem. '

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            data: {
                title: isPrivacy ? 'Event Privacy' : 'Official Status',
                component: SimpleInfoMessageComponent,
                inputData: {
                    // message: isPrivacy ? privacyInfoMessage : officialStatusInfoMessage,
                    // message2: isPrivacy ? privacyInfoMessage2 : officialStatusInfoMessage2,
                    message: privacyInfoMessage,
                    message2: privacyInfoMessage2,
                },
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
            },
        })
    }
}
