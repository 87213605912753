import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-cs-profile-games-looking-to-play',
  templateUrl: './cs-profile-games-looking-to-play.component.html',
  styleUrls: ['./cs-profile-games-looking-to-play.component.scss']
})
export class CsProfileGamesLookingToPlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
