<div class="row">
    <div class="col-12">
        <mat-form-field clas="gh-form-field" style="width: 100%">
            <mat-label>{{label}}</mat-label>
            <mat-select 
                [compareWith]="compareFunction" 
                [(value)]="selectedYear" 
                (selectionChange)="yearChanged($event)">
                <mat-option *ngFor="let year of years" [value]="year.value">
                    {{year.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>