import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service';
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service';
import { iImageOrVideoGalleryInputItem } from '../../../../../../../../../../common/interfaces/ContentInterfaces';
import { TaVideosListComponent } from '../ta-videos/components/ta-videos-list/ta-videos-list.component';
import { TaVideosContentService } from '../ta-videos/services/ta-videos-content.service';
import { TaImagesComponent } from '../ta-images/ta-images.component';
import { IMAGES_SK_TO_REMOVE } from '../../../../../../../../../../common/constants/contentConstants';

@Component({
  selector: 'app-ta-content',
  templateUrl: './ta-content.component.html',
  styleUrls: ['./ta-content.component.scss']
})
export class TaContentComponent implements OnInit {

  @Input() _sourceOfInput: string

  tabs = [
    { title: "Images", isSelected: true, icon: " " },
    { title: "How To Play", isSelected: false, icon: " " },
    { title: "Play Through", isSelected: false, icon: " " },
    { title: "Other Videos", isSelected: true, icon: " " }
  ]

  buttonText = 'Order By Likes'
  sortedByLikes = false
  user
  selectedTabIndex = 0
  videoList
  gameId
  gameTitle
  shouldLoad = false

  initialTabChangeDone = false

  data

  @Input() set _data(data) {
    if (data) {

      this.data = data

      this.gameId = data.gameId
      this.gameTitle = data.title

      if (data.shouldLoad) {
        this.shouldLoad = data.shouldLoad
        this.loadOTHERSortedByLikes()
      }
    }
  }

  contentComponent

  constructor(
    private taVideosContentService: TaVideosContentService,
    private backendApiContentService: BackendAPIContentService,
    private backendAPIUsersService: BackendAPIUsersService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.contentComponent = TaImagesComponent
  }

  async ngOnInit() {
    await this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })

    this.taVideosContentService.gameId = this.data.gameId
    this.taVideosContentService.gameTitle = this.data.title

    this.loadImagesSortedByLikes()
    this.loadAllSortedByLikes()
  }


  imageList
  async loadImagesSortedByLikes() {
    this.taVideosContentService.updateImages([])

    let imagesByGameID = await this.backendApiContentService.listImagesByGameID(this.data.gameId, 30)

    this.imageList = imagesByGameID.images

    let reshapedItems = []
    for (let item of this.imageList) {
      let reshapedItem: iImageOrVideoGalleryInputItem = {

        imgPath: item.paths[0],
        title: item.title,
        gameTitle: item.gameTitle,
        subtitle: item.subtitle,
        published: item.createdAt, //! There may be a problem with AWS Datetime
        likes: +item.byLikesSK.replace(IMAGES_SK_TO_REMOVE, ''),
        addedByUID: item.addedByUID,
        id: item.id,
        gameTagged: item.byLikesPK,
        byLikesSK: item.byLikesSK,
        pk: item.pk,
        sk: item.sk,
        contentByGameByLikesPK: item.contentByGameByLikesPK,
        contentByGroupByLikesPK: item.contentByGroupByLikesPK,
        contentByEventByLikesPK: item.contentByEventByLikesPK,
        contentByHavenByLikesPK: item.contentByHavenByLikesPK,
        contentByUserByLikesPK: item.contentByUserByLikesPK
      }
      reshapedItems.push(reshapedItem)
    }

    this.taVideosContentService.updateImages(reshapedItems)

  }



  async loadAllSortedByLikes() {

    this.taVideosContentService.updateVideos([])

    let videosByGameID = await this.backendApiContentService.listAllVideosByTypeByGameID('#', this.gameId, 20)

    this.videoList = videosByGameID.videos

    let reshapedItems = []
    for (let item of this.videoList) {
      let reshapedItem: iImageOrVideoGalleryInputItem = {

        videoPath: item.url,
        title: item.title,
        subtitle: item.subtitle,
        published: item.createdAt, //! There may be a problem with AWS Datetime
        likes: 12,
        addedByUID: item.addedByUID,
        id: item.id,
        videoType: item.videoType,
        gameTagged: item.byLikesPK,
        byLikesSK: item.byLikesSK,
        pk: item.pk,
        sk: item.sk,
        contentByGameByLikesPK: item.contentByGameByLikesPK,
        contentByGroupByLikesPK: item.contentByGroupByLikesPK,
        contentByEventByLikesPK: item.contentByEventByLikesPK,
        contentByHavenByLikesPK: item.contentByHavenByLikesPK,
        contentByUserByLikesPK: item.contentByUserByLikesPK
      }
      reshapedItems.push(reshapedItem)
    }

    // <!-- wokring here afdlskjadslkfjfdsa -->

    this.taVideosContentService.updateVideos(reshapedItems)
  }

  async loadOTHERSortedByLikes() {

    this.taVideosContentService.updateVideos([])

    let videosByGameID = await this.backendApiContentService.listAllVideosByTypeByGameID('#OTHER', this.gameId, 20)

    this.videoList = videosByGameID.videos

    let reshapedItems = []
    for (let item of this.videoList) {
      let reshapedItem: iImageOrVideoGalleryInputItem = {

        videoPath: item.url,
        title: item.title,
        subtitle: item.subtitle,
        published: item.createdAt, //! There may be a problem with AWS Datetime
        likes: 12,
        addedByUID: item.addedByUID,
        id: item.id,
        videoType: item.videoType,
        gameTagged: item.byLikesPK,
        byLikesSK: item.byLikesSK,
        pk: item.pk,
        sk: item.sk,
        contentByGameByLikesPK: item.contentByGameByLikesPK,
        contentByGroupByLikesPK: item.contentByGroupByLikesPK,
        contentByEventByLikesPK: item.contentByEventByLikesPK,
        contentByHavenByLikesPK: item.contentByHavenByLikesPK,
        contentByUserByLikesPK: item.contentByUserByLikesPK
      }
      reshapedItems.push(reshapedItem)
    }

    this.taVideosContentService.updateVideos(reshapedItems)
  }

  async loadHTPSortedByLikes() {

    this.taVideosContentService.updateVideos([])

    let videosByGameID = await this.backendApiContentService.listAllVideosByTypeByGameID('#HTP', this.gameId, 20)

    this.videoList = videosByGameID.videos

    let reshapedItems = []
    for (let item of this.videoList) {
      let reshapedItem: iImageOrVideoGalleryInputItem = {

        videoPath: item.url,
        title: item.title,
        subtitle: item.subtitle,
        published: item.createdAt, //! There may be a problem with AWS Datetime
        likes: 12,
        addedByUID: item.addedByUID,
        id: item.id,
        videoType: item.videoType,
        gameTagged: item.byLikesPK,
        byLikesSK: item.byLikesSK,
        pk: item.pk,
        sk: item.sk,
        contentByGameByLikesPK: item.contentByGameByLikesPK,
        contentByGroupByLikesPK: item.contentByGroupByLikesPK,
        contentByEventByLikesPK: item.contentByEventByLikesPK,
        contentByHavenByLikesPK: item.contentByHavenByLikesPK,
        contentByUserByLikesPK: item.contentByUserByLikesPK
      }
      reshapedItems.push(reshapedItem)
    }

    this.taVideosContentService.updateVideos(reshapedItems)
  }

  async loadPTSortedByLikes() {

    this.taVideosContentService.updateVideos([])

    let videosByGameID = await this.backendApiContentService.listAllVideosByTypeByGameID('#PT', this.gameId, 20)

    this.videoList = videosByGameID.videos

    let reshapedItems = []
    for (let item of this.videoList) {
      let reshapedItem: iImageOrVideoGalleryInputItem = {

        videoPath: item.url,
        title: item.title,
        subtitle: item.subtitle,
        published: item.createdAt, //! There may be a problem with AWS Datetime
        likes: 12,
        addedByUID: item.addedByUID,
        id: item.id,
        videoType: item.videoType,
        gameTagged: item.byLikesPK,
        byLikesSK: item.byLikesSK,
        pk: item.pk,
        sk: item.sk,
        contentByGameByLikesPK: item.contentByGameByLikesPK,
        contentByGroupByLikesPK: item.contentByGroupByLikesPK,
        contentByEventByLikesPK: item.contentByEventByLikesPK,
        contentByHavenByLikesPK: item.contentByHavenByLikesPK,
        contentByUserByLikesPK: item.contentByUserByLikesPK
      }
      reshapedItems.push(reshapedItem)
    }

    this.taVideosContentService.updateVideos(reshapedItems)

  }


  onTabChange(index) {

    this.selectedTabIndex = index
    this.sortedByLikes = false

    if (this.initialTabChangeDone) {
      switch (index) {
        case 0:
          this.contentComponent = TaImagesComponent
          break
        case 1:
          this.contentComponent = TaVideosListComponent
          this.loadHTPSortedByLikes()
          break
        case 2:
          this.contentComponent = TaVideosListComponent
          this.loadPTSortedByLikes()
          break
        case 3:
          this.contentComponent = TaVideosListComponent
          if (this.shouldLoad) {
            this.loadOTHERSortedByLikes()
          }
          break
      }
    }

    this.initialTabChangeDone = true


  }

  toggleSorting() {
    this.sortedByLikes = !this.sortedByLikes

    // if (this.sortedByLikes) {
    //   this.orderByLikes()
    // }
    // else {
    //   this.orderByDefault()
    // }
  }

  // orderByLikes() {
  //   this.buttonText = 'Default Ordering'
  //   switch (this.selectedTabIndex) {
  //     case 0:
  //       if (this.shouldLoad) {
  //         this.loadAllSortedByLikes()
  //       }
  //       break
  //     case 1:
  //       this.loadPTSortedByLikes()
  //       break
  //     case 2:
  //       this.loadAllSortedByLikes()
  //       break
  //   }
  // }

  orderByDefault() {
    this.buttonText = 'Order By Likes'
    switch (this.selectedTabIndex) {
      case 0:
        if (this.shouldLoad) {
          this.loadOTHERSortedByLikes()
        }
        break
      case 1:
        this.loadHTPSortedByLikes()
        break
      case 2:
        this.loadPTSortedByLikes()
        break
    }
  }
}
