<div class="row mx-0 mt-2" *ngIf="publisherDescription; else noData">
    <div class="col-12 text-center">
        <blockquote>
            <p *ngFor="let string of publisherDescription">
                {{string}}
            </p>
            <span class="author" *ngFor="let publisher of publishers"><i>{{publisher.title}}</i></span>
        </blockquote>
    </div>
</div>

<ng-template #noData>
    <app-no-data [_message]="'No data...'"></app-no-data>
</ng-template>