<div class="align-items-center mt-1 mb-1 p-0 row">
    <div class="mt-0 mb-0 col-md-4">
        <!-- Optional filter -->
        <mat-form-field appearance="standard" class="w-100">
            <mat-label>Filter by Game</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Ex. Game 1" />
        </mat-form-field>
    </div>
    <!-- <div class="text-end col-md-4">
        <button
            mat-raised-button
            color="primary"
            class="create-event-btn"
            (click)="onButtonClick()"
        >
            Schedule a Game
        </button>
    </div> -->
</div>

<div class="mx-0 mb-3 px-0 data-group row">
    <div class="w-100 col-12">
        <div class="all-events-content table-container wrapper">
            <table
                mat-table
                [dataSource]="allEventsDataSource"
                class="mat-elevation-z8 mat-table"
                matSort
                [matSortActive]="'startTime'"
                matSortDirection="asc"
                #allEventsSort="matSort"
            >
                <!-- Game Column -->
                <ng-container matColumnDef="game">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Game</th>
                    <td mat-cell *matCellDef="let game">
                        <a (click)="openGameDetails(game); $event.preventDefault()" class="gh-link">
                            {{ truncate(game.name, 30) }}
                        </a>
                    </td>
                </ng-container>

                <!-- Title Column -->
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                    <td mat-cell *matCellDef="let game">{{ game.eventTitle }}</td>
                </ng-container>
                <!-- Location Column -->
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
                    <td mat-cell *matCellDef="let game">
                        {{ game.location }}
                    </td>
                </ng-container>

                <!-- Cost Column -->
                <ng-container matColumnDef="cost">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost</th>
                    <td mat-cell *matCellDef="let game">{{ game.cost }}</td>
                </ng-container>

                <!-- Organizer Column -->
                <ng-container matColumnDef="createdByTitle">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Organizer</th>
                    <td mat-cell *matCellDef="let game">
                        <a
                            href="#"
                            (click)="openUserDetails(game.createdByUID); $event.preventDefault()"
                            class="gh-link"
                        >
                            {{ game.createdByTitle }}
                        </a>
                    </td>
                </ng-container>

                <!-- Start Time Column -->
                <ng-container matColumnDef="startTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
                    <td mat-cell *matCellDef="let game">
                        {{ game.startTime | date : 'EEE h:mma' }}
                    </td>
                </ng-container>

                <!-- Duration Column -->
                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
                    <td mat-cell *matCellDef="let game">{{ game.duration }} mins</td>
                </ng-container>

                <!-- Players Column -->
                <ng-container matColumnDef="players">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Players</th>
                    <td mat-cell *matCellDef="let game">
                        {{ game.currentPlayers }} / {{ game.maxPlayers }}
                    </td>
                </ng-container>

                <!-- Waitlist Column -->
                <ng-container matColumnDef="waitlist">
                    <th mat-header-cell *matHeaderCellDef>Waitlist</th>
                    <td mat-cell *matCellDef="let game">
                        <ng-container *ngIf="game.waitlist && game.waitlist.length > 0">
                            <ul>
                                <li *ngFor="let user of game.waitlist">
                                    {{ user }}
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="!(game.waitlist && game.waitlist.length > 0)">
                            <div>{{ game.waitlistCapacity }} slots</div>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Notes Column -->
                <ng-container matColumnDef="notes">
                    <th mat-header-cell *matHeaderCellDef>Notes</th>
                    <td mat-cell *matCellDef="let game">
                        <a
                            href="#"
                            (click)="openNotesDialog(game.notes); $event.preventDefault()"
                            class="gh-link"
                        >
                            {{ game.notes || '' | slice : 0 : 20
                            }}{{ game.notes && game.notes.length > 20 ? '...' : '' }}
                        </a>
                    </td>
                </ng-container>

                <!-- Player List Column -->
                <ng-container matColumnDef="playerList">
                    <th mat-header-cell *matHeaderCellDef>Player List</th>
                    <td mat-cell *matCellDef="let game">
                        <ul>
                            <li *ngFor="let player of game.playerList">
                                {{ player }}
                            </li>
                        </ul>
                    </td>
                </ng-container>

                <!-- Attend/Leave Button Column -->
                <!-- <ng-container matColumnDef="attend">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let game">
                        <button
                            mat-raised-button
                            class="gh-button gh-button-secondary"
                            (click)="handleAttendClick(game)"
                            [disabled]="isAttendDisabled(game)"
                            [ngClass]="{
                                'attend-button': !isUserAttending(game) && !isUserOnWaitlist(game),
                                'leave-button': isUserAttending(game) || isUserOnWaitlist(game)
                            }"
                        >
                            {{ getButtonLabel(game) }}
                        </button>
                    </td>
                </ng-container> -->

                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                    <td mat-cell *matCellDef="let game">
                        <!-- Edit Button -->
                        <button
                            mat-icon-button
                            aria-label="Edit event"
                            *ngIf="isGHAdmin || game.isUserOrganizer"
                            (click)="onEditButtonClick(game)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <!-- Delete Button -->
                        <button
                            mat-icon-button
                            aria-label="Delete event"
                            *ngIf="isGHAdmin || game.isUserOrganizer"
                            (click)="openDeleteDialog(game)"
                        >
                            <mat-icon class="red-icon">delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- isApproved Column -->
                <!-- isApproved Column -->
                <ng-container matColumnDef="isApproved">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Approved</th>
                    <td mat-cell *matCellDef="let game">
                        <button mat-raised-button color="primary" (click)="toggleApproval(game)">
                            {{ game.isApproved ? 'Decline' : 'Approve' }}
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                    [ngClass]="{
                        'bold-row': isUserAttending(row) || isUserOnWaitlist(row),
                        'organizer-user': row.isUserOrganizer,
                        'overlapping-event': row.isOverlapping
                    }"
                ></tr>
            </table>
        </div>
    </div>
</div>

<!-- Notes Dialog Template -->
<ng-template #notesDialog let-data>
    <h1 mat-dialog-title>Notes</h1>
    <div mat-dialog-content>
        {{ data }}
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
    </div>
</ng-template>

<!-- Password Dialog Template -->
<ng-template #passwordDialog>
    <h1 mat-dialog-title>Enter Password</h1>
    <div mat-dialog-content>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="passwordInput" type="password" />
        </mat-form-field>
        <div *ngIf="passwordError" class="error-message">Incorrect password. Please try again.</div>
    </div>
    <div mat-dialog-actions>
        <button mat-button (click)="closePasswordDialog()">Cancel</button>
        <button mat-button (click)="submitPassword()">Submit</button>
    </div>
</ng-template>

<!-- Delete Dialog Template -->
<ng-template #deleteDialog>
    <h1 mat-dialog-title>Confirm Deletion</h1>
    <div mat-dialog-content>Are you sure you want to delete this scheduled play?</div>
    <div mat-dialog-actions>
        <button mat-button (click)="closeDeleteDialog()" tabindex="0">Cancel</button>
        <button mat-button color="warn" (click)="confirmDelete()" tabindex="0">Delete</button>
    </div>
</ng-template>
