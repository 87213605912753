import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { TaVideosContentService } from '../../services/ta-videos-content.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { VideoEmbeddingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/video-embedding-helper.service'


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-ta-videos-list',
  templateUrl: './ta-videos-list.component.html',
  styleUrls: ['./ta-videos-list.component.scss']
})
export class TaVideosListComponent implements OnInit {

  videos = new BehaviorSubject(null)
  videos$ = this.videos.asObservable()

  constructor(
    private taVideosContentService: TaVideosContentService,
    private videoEmbeddingHelperService: VideoEmbeddingHelperService,
  ) { }

  async ngOnInit() {

    await this.taVideosContentService.videos$.pipe(untilDestroyed(this)).subscribe(videos => {
      if (videos) {
        let shallowCopy = JSON.stringify(videos)
        this.videos.next(JSON.parse(shallowCopy))
      }
    })


  }

  getVideoPath(unsafeURL) {
    return this.videoEmbeddingHelperService.getVideoPath(unsafeURL)
  }

}
