import { Component, Input, OnInit } from '@angular/core'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { GameDetailPDAPortfolioComponent } from '../game-detail-pda-portfolio/game-detail-pda-portfolio.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { untilDestroyed } from '@ngneat/until-destroy'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatDialog } from '@angular/material/dialog'

@Component({
    selector: 'app-game-detail-full-credits',
    templateUrl: './game-detail-full-credits.component.html',
    styleUrls: ['./game-detail-full-credits.component.scss'],
})
export class GameDetailFullCreditsComponent implements OnInit {
    publishers
    designers
    artists
    inputData
    @Input() set _inputData(inputData) {

        this.publishers = inputData.publishers
        this.designers = inputData.designers
        this.artists = inputData.artists
        this.inputData = inputData

        if (Array.isArray(inputData.artists) && inputData.artists.length === 0) {
            this.artists = false
        }

        if (Array.isArray(inputData.designers) && inputData.designers.length === 0) {
            this.designers = false
        }

        if (Array.isArray(inputData.publishers) && inputData.publishers.length === 0) {
            this.publishers = false
        }
    }

    constructor(
        private backendApiUsersService: BackendAPIUsersService,
        private screenSizeService: ScreenSizeService,

        private dialog: MatDialog,
    ) { }

    isMobileScreen = false

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    async fetchPublisherGames(event, i) {
        let publishersGames = await this.backendApiUsersService.listAllGamesByPublisherTitle(
            this.inputData.publishers[i].title,
            50,
        )

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                inputData: publishersGames,
                title: `${this.inputData.publishers[i].title}'s Portfolio`,
                component: GameDetailPDAPortfolioComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }

    async fetchDeveloperGames(event, i) {
        let developerGames = await this.backendApiUsersService.listAllGamesByDeveloperTitle(
            this.inputData.designers[i].title,
            50,
        )

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                inputData: developerGames,
                title: `${this.inputData.designers[i].title}'s Portfolio`,
                component: GameDetailPDAPortfolioComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }

    async fetchArtistGames(event, i) {
        let artistGames = await this.backendApiUsersService.listAllGamesByArtistTitle(
            this.inputData.artists[i].title,
            50,
        )

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                inputData: artistGames,
                title: `${this.inputData.designers[i].title}'s Portfolio`,
                component: GameDetailPDAPortfolioComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }
}
