import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { StringHelperService } from '../../../services/helpers/string-helper.service'
import { iHopperItem } from '../../../interfaces/hopper/IHopperItem'
import { HopperCompetitionLevelEnums } from '../../../enums/hopper-enums'
import { StringHelper } from '../../../../../../../../common/helpers/string-helper'

@Injectable({
  providedIn: 'root'
})
export class HopperService {

  private hopperDataList
  private hopperData
  hopperData$

  private gameSpecificHopperDataList
  private gameSpecificHopperData
  gameSpecificHopperData$

  constructor() {
    this.initDataLists()

    for (let item of this.hopperDataList) {
      item.itemId = StringHelper.getRandomString()
    }
    this.hopperData = new BehaviorSubject(this.hopperDataList)
    this.hopperData$ = this.hopperData.asObservable()

    for (let item of this.gameSpecificHopperDataList) {
      item.itemId = StringHelper.getRandomString()
    }
    this.gameSpecificHopperData = new BehaviorSubject(this.gameSpecificHopperDataList)
    this.gameSpecificHopperData$ = this.gameSpecificHopperData.asObservable()
  }

  initDataLists() {
    let hopperDataListShallowCopy = JSON.stringify(HOPPER_DATA)
    this.hopperDataList = JSON.parse(hopperDataListShallowCopy)
    let gameSpecificHopperDataListShallowCopy = JSON.stringify(GAME_SPECIFIC_HOPPER_DATA)
    this.gameSpecificHopperDataList = JSON.parse(gameSpecificHopperDataListShallowCopy)
  }

  async refresh() {
    this.initDataLists()
    this.hopperData.next(this.hopperDataList)
    this.gameSpecificHopperData.next(this.gameSpecificHopperDataList)
  }

  public getGroupName(members) {
    var result = ''
    for (var i = 0; i < members.length; i++) {
      if (i != members.length - 1) {
        result += members[i].title.concat(', ')
      }
      else {
        result += members[i].title
      }
    }
    return result
  }

  async addToHopperData(itemToAdd: iHopperItem, isGameSpecific: boolean) {
    if (!isGameSpecific) {
      this.hopperDataList.unshift(itemToAdd) // inserts at beginning of array
      this.hopperData.next(this.hopperDataList)
    }
    else {
      this.gameSpecificHopperDataList.unshift(itemToAdd) // inserts at beginning of array
      this.gameSpecificHopperData.next(this.gameSpecificHopperDataList)
    }
  }

  async deleteFromHopperData(itemToDelete: iHopperItem, isGameSpecific: boolean) {
    if (!isGameSpecific) {
      var indexToRemove = -1
      for (var i = 0; i < this.hopperDataList.length; i++) {
        if (this.hopperDataList[i] == itemToDelete) {
          indexToRemove = i
        }
      }
      if (i != -1) {
        this.hopperDataList.splice(indexToRemove, 1)
        this.hopperData.next(this.hopperDataList)
      }
    }
    else {
      var indexToRemove = -1
      for (var i = 0; i < this.gameSpecificHopperDataList.length; i++) {
        if (this.gameSpecificHopperDataList[i] == itemToDelete) {
          indexToRemove = i
        }
      }
      if (i != -1) {
        this.gameSpecificHopperDataList.splice(indexToRemove, 1)
        this.gameSpecificHopperData.next(this.gameSpecificHopperDataList)
      }
    }
  }

  async editItem(item: iHopperItem, isGameSpecific: boolean) {
    if (!isGameSpecific) {
      for (var i = 0; i < this.hopperDataList.length; i++) {
        if (this.hopperDataList[i].itemId == item.itemId) {
          this.hopperDataList[i] = item
          this.hopperData.next(this.hopperDataList)
          break
        }
      }
    }
    else {
      for (var i = 0; i < this.gameSpecificHopperDataList.length; i++) {
        if (this.gameSpecificHopperDataList[i].itemId == item.itemId) {
          this.gameSpecificHopperDataList[i] = item
          this.gameSpecificHopperData.next(this.gameSpecificHopperDataList)
          break
        }
      }
    }
  }

}


const HOPPER_DATA: iHopperItem[] = [
  {
    itemId: '',
    group: {
      admin: { title: 'Dave' },
      members: [{ title: 'Dave' }]
    },
    game:
    {
      title: 'Patchwork',
      currentPlayerCount: 1,
      minPlayers: 2,
      maxPlayers: 2,
      votedBestMinPlayers: 2,
      votedBestMaxPlayers: 2,
      hasCustomBounds: false,
      customLowerBound: null,
      customUpperBound: null,
      minPlayersCanAccept: 1,
      maxPlayersCanAccept: 1,
      idealPlayersToAccept: 1,
      mainImage: "https://gh-img.s3.amazonaws.com/G/patchwork.jpg"
    },
    desiredIndices: [2],
    comments: 'Anything quick!',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612325621718',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Jack' },
      members: [{ title: 'Jack' }, { title: 'Jill' }]
    },
    game:
    {
      title: 'Drop It',
      currentPlayerCount: 2,
      minPlayers: 2,
      maxPlayers: 4,
      votedBestMinPlayers: 2,
      votedBestMaxPlayers: 4,
      hasCustomBounds: false,
      customLowerBound: null,
      customUpperBound: null,
      minPlayersCanAccept: 1,
      maxPlayersCanAccept: 2,
      idealPlayersToAccept: 2,
      mainImage: "https://gh-img.s3.amazonaws.com/G/drop_it.jpg"
    },
    desiredIndices: [2, 3, 4],
    comments: 'Or let us know a great dexterity game!',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612324930123',
  },
  {
    itemId: '',
    group: {
      admin: 'Santa',
      members: [{ title: 'Santa' }, { title: 'Mrs Clause' }]
    },
    game:
    {
      title: 'You Gotta Be Kitten Me',
      currentPlayerCount: 2,
      minPlayers: 2,
      maxPlayers: 10,
      votedBestMinPlayers: 2,
      votedBestMaxPlayers: 4,
      hasCustomBounds: false,
      customLowerBound: null,
      customUpperBound: null,
      minPlayersCanAccept: 1,
      maxPlayersCanAccept: 8,
      idealPlayersToAccept: 2,
      mainImage: "https://gh-img.s3.amazonaws.com/G/you_gotta_be_kitten_me.jpg"
    },
    desiredIndices: [2, 3, 4],
    comments: 'Will be here for a long time!',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612324930123',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Shay' },
      members: [{ title: 'Shay' }]
    },
    game:
    {
      title: 'Chess',
      currentPlayerCount: 1,
      minPlayers: 2,
      maxPlayers: 2,
      votedBestMinPlayers: 2,
      votedBestMaxPlayers: 2,
      hasCustomBounds: false,
      customLowerBound: null,
      customUpperBound: null,
      minPlayersCanAccept: 1,
      maxPlayersCanAccept: 1,
      idealPlayersToAccept: 1,
      mainImage: "https://gh-img.s3.amazonaws.com/G/chess.jpg"
    },
    desiredIndices: [2],
    comments: 'I only play chess. I only play chess. I only play chess. I only play chess I only play chess. I only play chess. I only play chess. I only play chess. I only play chess. I only play chess. I only play chess. I only play chess',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612324930123',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Ben' },
      members: [{ title: 'Ben' }]
    },
    game:
    {
      title: 'MtG',
      currentPlayerCount: 1,
      minPlayers: 2,
      maxPlayers: 4,
      votedBestMinPlayers: 2,
      votedBestMaxPlayers: 2,
      hasCustomBounds: false,
      customLowerBound: null,
      customUpperBound: null,
      minPlayersCanAccept: 1,
      maxPlayersCanAccept: 1,
      idealPlayersToAccept: 1,
      mainImage: "https://gh-img.s3.amazonaws.com/G/magic-the-gathering.jpg"
    },
    desiredIndices: [2],
    comments: 'New to games and love them!',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612324930123',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Some Guy' },
      members: [{ title: 'Some Guy' }]
    },
    game:
    {
      title: 'Scythe',
      currentPlayerCount: 1,
      minPlayers: 1,
      maxPlayers: 5,
      votedBestMinPlayers: 3,
      votedBestMaxPlayers: 3,
      hasCustomBounds: false,
      customLowerBound: null,
      customUpperBound: null,
      minPlayersCanAccept: 1,
      maxPlayersCanAccept: 4,
      idealPlayersToAccept: 3,
      mainImage: "https://gh-img.s3.amazonaws.com/G/scythe.jpg"
    },
    desiredIndices: [3],
    comments: 'New to games and love them!',
    competitionLevel: HopperCompetitionLevelEnums.competitive,
    postedTimestamp: '1612324930123',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Some dude' },
      members: [{ title: 'Some dude' }]
    },
    game:
    {
      title: 'Scythe',
      currentPlayerCount: 1,
      minPlayers: 1,
      maxPlayers: 5,
      votedBestMinPlayers: 3,
      votedBestMaxPlayers: 3,
      hasCustomBounds: true,
      customLowerBound: 2,
      customUpperBound: 4,
      minPlayersCanAccept: 2,
      maxPlayersCanAccept: 3,
      idealPlayersToAccept: 3,
      mainImage: "https://gh-img.s3.amazonaws.com/G/scythe.jpg"
    },
    desiredIndices: [3],
    comments: 'I have custom bounds on my game!',
    competitionLevel: HopperCompetitionLevelEnums.competitive,
    postedTimestamp: '1612323249472',
  }
]


// TODO: modify game data after talking to Dave about it
const GAME_SPECIFIC_HOPPER_DATA: iHopperItem[] = [
  {
    itemId: '',
    group: {
      admin: { title: 'Dave' },
      members: [{ title: 'Dave' }]
    },
    haven: { title: 'Tabletopia' },
    desiredIndices: [2],
    comments: 'Anything quick!',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612323249472',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Jack' },
      members: [{ title: 'Jack' }, { title: 'Jill' }]
    },
    haven: { title: 'Tabletopia' },
    desiredIndices: [2, 3, 4],
    comments: 'Or let us know a great dexterity game!',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612323249472',
  },
  {
    itemId: '',
    group: {
      admin: 'Santa',
      members: [{ title: 'Santa' }, { title: 'Mrs Clause' }]
    },
    haven: { title: 'Tabletop Simulator' },
    desiredIndices: [2, 3, 4],
    comments: 'Will be here for a long time!',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612323249472',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Shay' },
      members: [{ title: 'Shay' }]
    },
    haven: { title: 'Tabletopia' },
    desiredIndices: [2],
    comments: 'I only play chess. I only play chess. I only play chess. I only play chess I only play chess. I only play chess. I only play chess. I only play chess. I only play chess. I only play chess. I only play chess. I only play chess',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612323249472',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Ben' },
      members: [{ title: 'Ben' }]
    },
    haven: { title: 'Tabletop Simulator' },
    desiredIndices: [2],
    comments: 'New to games and love them!',
    competitionLevel: HopperCompetitionLevelEnums.casual,
    postedTimestamp: '1612323249472',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Some Guy' },
      members: [{ title: 'Some Guy' }]
    },
    haven: { title: 'Tabletop Simulator' },
    desiredIndices: [3],
    comments: 'New to games and love them!',
    competitionLevel: HopperCompetitionLevelEnums.competitive,
    postedTimestamp: '1612323249472',
  },
  {
    itemId: '',
    group: {
      admin: { title: 'Another Guy' },
      members: [{ title: 'Another Guy' }]
    },
    haven: { title: 'Tabletop Simulator' },
    desiredIndices: [3],
    comments: 'I have custom bounds on my game!',
    competitionLevel: HopperCompetitionLevelEnums.competitive,
    postedTimestamp: '1612323249472',
  }
]