<div class="swiper-wrapper">
    <swiper
        [slidesPerGroup]="numberOfTiles"
        [slidesPerView]="numberOfTiles"
        [spaceBetween]="20"
        [pagination]="{ clickable: true }"
        class="mySwiper"
    >
        <!-- ! NEED TO FIGURE OUT A WAY TO DEFAULT TO AN IMAGE SO THAT IT IS CLICKABLE -->

        <ng-template class="swiper-slide" swiperSlide *ngFor="let item of items">
            <!-- <img *ngIf="item.mainImageFiles" [ngStyle]="{ maxWidth: getImgMaxSize(), height: 'auto' }"
                [src]="item.mainImageFiles[0]" alt="" (click)="onImageClicked(item)"> -->

            <img
                *ngIf="item.mainImageFiles"
                [src]="item.mainImageFiles[0]"
                alt=""
                (click)="onImageClicked(item)"
            />
        </ng-template>
    </swiper>
</div>

<!-- navigation="  
    {
        nextEl: 'swiper-button-next', 
        prevEl: 'swiper-button-prev'
    }
    " -->
