import { Component, EventEmitter, Host, Inject, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core'
import { EditMyProfileAnimations } from './animations/edit-my-profile-animations'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BehaviorSubject } from 'rxjs'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-edit-my-profile[_sourceOfInput]',
  templateUrl: './edit-my-profile.component.html',
  styleUrls: ['./edit-my-profile.component.scss'],
  animations: [EditMyProfileAnimations.openOptionsTrigger],
})

export class EditMyProfileComponent implements OnInit {
  @ViewChild('fileUploader', { static: false }) fileUploader

  @Input() _sourceOfInput: string

  @Input() set _parentSubmitClicked(parentSubmitClicked: boolean) {
    if (parentSubmitClicked) {
      this.submit()
    }
  }

  @Output() closeEmitter = new EventEmitter()

  isLoading = new BehaviorSubject(true)
  isLoading$ = this.isLoading.asObservable()

  user
  currentProfilePic

  allGames
  gamesILove

  selectedImage

  updateUserForm: UntypedFormGroup

  constructor(
    @Inject(forwardRef(() => GhDialogWrapperComponent)) private _parent: GhDialogWrapperComponent,
    private backendAPIUsersService: BackendAPIUsersService,
    private formBuilder: UntypedFormBuilder
  ) { }

  async ngOnInit() {
    await this.backendAPIUsersService.currentUser$
      .pipe(untilDestroyed(this))
      .subscribe(async (user) => {
        if (user) {
          this.user = user
          if (user.mainImageFiles) {
            //! do NOT set this.selected image to currentProfilePic, that causes an error on submit if image hasnt changed
            this.currentProfilePic = this.user.mainImageFiles[1]
          } else {
            this.currentProfilePic =
              window.location.origin + '/assets/images/defaults/profile-placeholder.png'
          }
          this.updateUserForm = this.formBuilder.group({
            // title: [this.usersDbService.getCurrentUserData().title, [Validators.required]],
            bio: [this.user.bio],
            gamesILove: [this.gamesILove == '' ? [] : this.gamesILove],
          })
          this.isLoading.next(false)
        }
      })
  }

  setSelectedImage(event) {
    if (event.rawImage == undefined) {
      this.selectedImage = window.location.origin + '/assets/images/defaults/profile-placeholder.png'
    } else {
      this.selectedImage = event
    }
  }

  async submit() {

    let updatedUser = this.user
    updatedUser.bio = this.updateUserForm.get('bio').value

    // ! pass in null if maintaining the previous profile image and upateUser will handle the logic
    this.backendAPIUsersService
      .updateUser(updatedUser, this.selectedImage ? this.selectedImage.rawImage : null)
      .then((result) => {
        this.closeEmitter.emit()
      })

  }

}
