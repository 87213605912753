import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { USA_ZIP_CODES } from 'src/assets/jsons/uszips';

@Component({
  selector: 'app-gh-simple-zipcode-selector',
  templateUrl: './gh-simple-zipcode-selector.component.html',
  styleUrls: ['./gh-simple-zipcode-selector.component.scss']
})
export class GhSimpleZipcodeSelectorComponent implements OnInit {

  accumulatingInput = ''
  minCharacters = 2
  usaZipCodes = USA_ZIP_CODES
  narrowedDownList


  isEditing = false
  zipToEdit
  @Input() set _zipToEdit(zipToEdit) {
    if (zipToEdit && zipToEdit != '' && zipToEdit != null) {
      this.zipToEdit = zipToEdit
      this.isEditing = true
    }
  }

  @Output() onZipSelectedEmitter = new EventEmitter()

  constructor(private formBuilder: UntypedFormBuilder) { }


  form
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      firstCharacters: this.isEditing ? this.zipToEdit : '',
      zipCodeSelection: this.isEditing ? this.zipToEdit : ''
    })

    if (this.isEditing) {
      this.onInputChange({ data: this.zipToEdit })
    }
  }

  onInputChange(event) {
    // backspace causes event.data to be null - dont want to append it
    if (event.data != null) {
      // append most recent character to end of string
      this.accumulatingInput += event.data
    }
    else {
      // remove character at end of string
      this.accumulatingInput = this.accumulatingInput.slice(0, this.accumulatingInput.length - 1);
    }

    if (this.accumulatingInput.length >= this.minCharacters) {
      // display narrowed down list in UI
      this.narrowedDownList = this.usaZipCodes.filter(option => option.toString().startsWith(this.accumulatingInput));
    }
    else {
      // remove narrowed down list from UI
      this.narrowedDownList = null
    }
  }

  compareFunction(o1: any, o2: any) {
    return (o1 == o2)
  }

  selectZipcode(event) {
    this.onZipSelectedEmitter.emit(event.value)
  }


}
