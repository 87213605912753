import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { StringHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/string-helper.service'
import { TimingHelperService } from 'src/app/dashboard/dashboard-shared/services/helpers/timing-helper.service'
import { StringHelper } from '../../../../../../../../../../../../common/helpers/string-helper'
import { HopperService } from '../../../../../services/hopper.service'


@Component({
  selector: 'app-add-to-hopper-confirm-matches',
  templateUrl: './add-to-hopper-confirm-matches.component.html',
  styleUrls: ['./add-to-hopper-confirm-matches.component.scss']
})
export class AddToHopperConfirmMatchesComponent implements OnInit {

  options = []

  inputData
  @Input() set _inputData(inputData) {
    this.inputData = inputData
  }

  parentSubmitClicked = false

  @Output() closeEmitter = new EventEmitter()

  constructor(private timingHelperService: TimingHelperService,
    private hopperService: HopperService) { }

  ngOnInit(): void {
    this.animateItemsEntrance(this.inputData.hopperMatches)
  }

  async animateItemsEntrance(hopperMatches) {
    for (let match of hopperMatches) {
      await this.timingHelperService.delay(500).then(() => {
        this.options.push(match)
      })
    }
  }

  truncate(input: string, maxCharacters: number) {
    return StringHelper.truncateString(input, maxCharacters)
  }

  getGroupName(members) {
    return this.hopperService.getGroupName(members)
  }

  joinGroup(option) {
    this.closeEmitter.emit(option)
  }

  close() {
    this.closeEmitter.emit()
  }

}
