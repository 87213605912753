import { Component, OnInit, ViewChild } from '@angular/core'
import * as mapboxgl from 'mapbox-gl'
import { identityPoolCredentials } from 'src/app/shared/services/auth/auth.service'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { MapBoxAPI } from '../../../../../../../../common/constants/mapAPI'

@Component({
  selector: 'app-gh-map-location-selector-text-only',
  templateUrl: './gh-map-location-selector-text-only.component.html',
  styleUrls: ['./gh-map-location-selector-text-only.component.scss']
})
export class GhMapLocationSelectorTextOnlyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.initStuff()
  }


  initStuff(){
        var geocoder = new MapboxGeocoder({
        accessToken: MapBoxAPI,
        types: 'country,region,place,postcode,locality,neighborhood'
    });

    geocoder.addTo('#geocoder');

    // Get the geocoder results container.
    const results = document.getElementById('result');

    // Add geocoder result to container.
    geocoder.on('result', (e) => {
        results.innerText = JSON.stringify(e.result, null, 2);
    });

    // Clear results container when search is cleared.
    geocoder.on('clear', () => {
        results.innerText = '';
    });
  }







}
