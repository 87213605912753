<!-- todo Stepping isn't really supported. If we want something fancier, we will have to make a select or use package -->
<form [formGroup]="eventForm">

    <div class="row mx-0 mb-3 max-w-100 salient-header">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

            <div class="row">
                <div class="col-12">

                    <span class="inline-span">
                        <mat-card-title class="gh-sm-title">Event Privacy:</mat-card-title>
                    </span>

                    <span class="inline-span ml-4">
                        <mat-icon class="info-icon" (click)="showInfo(true)">help</mat-icon>
                    </span>
                </div>
                <div class="col-12">

                    <mat-form-field class="gh-form-field">





                        <!-- ! AND REPLICATE THIS TO GROUPS AND HAVENS-->

                        <mat-select #select formControlName="privacy" [compareWith]="compareFunction">
                            <mat-select-trigger *ngIf="select.value">
                                <mat-icon>{{select.value.icon}}</mat-icon>&nbsp;{{select.value.text}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let option of privacyOptions" [value]="option">
                                <mat-icon>{{option.icon}}</mat-icon>{{option.text}}
                            </mat-option>
                        </mat-select>





                    </mat-form-field>
                </div>
            </div>

        </div>
        <!-- 
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

            <div class="row">
                <div class="col-12">

                    <span class="inline-span">
                        <mat-card-title class="gh-sm-title">Official Status:</mat-card-title>
                    </span>

                    <span class="inline-span ml-4">
                        <mat-icon class="info-icon" (click)="showInfo(false)">help</mat-icon>
                    </span>
                </div>
                <div class="col-12">

                    <mat-form-field class="gh-form-field">

                        <mat-select #select2 formControlName="officalStatus">
                            <mat-select-trigger *ngIf="select2.value">
                                <mat-icon>{{select2.value.icon}}</mat-icon>&nbsp;{{select2.value.text}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let option of officialStatusOptions" [value]="option">
                                <mat-icon>{{option.icon}}</mat-icon>{{option.text}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

        </div>-->
    </div>

    <div class="row mx-0 mb-3 max-w-100">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <mat-form-field class="gh-form-field">
                <mat-label>Event Name (Required)
                    <Title></Title>
                </mat-label>
                <input matInput formControlName="title">
            </mat-form-field>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <mat-form-field class="gh-form-field">
                <mat-label>Event Description</mat-label>
                <input matInput formControlName="description">
            </mat-form-field>
        </div>
    </div>

    <div class="row mx-0 mb-3">
        <div class="col-12 col-sm-12 col-md-6">
            <mat-form-field class="gh-form-field">
                <mat-label>Organizer - YOU!</mat-label>
                <input readonly matInput formControlName="organizerTitle">
            </mat-form-field>
        </div>

        <div class="col-12 col-sm-12 col-md-6">
            <app-gh-file-uploader #fileUploader [_defaultImage]="mainImageToEdit" [_isProfileImg]="false" [_isEditing]="isEditing" [_sourceOfInput]="'app-share-modal-image'" (selectedImageChanged)="setSelectedImage($event)">
            </app-gh-file-uploader>
        </div>
    </div>

    <div class="row mx-0 mb-3 local-data-group">

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="data-group data-group-no-min-height" style="min-height: 14rem">
                <div class="row">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            Start:
                        </mat-card-title>
                        <!-- <h3>{{datetimeValue}}</h3> -->
                        <div class="gh-datetime-input-container">
                            <input type="datetime-local" [(ngModel)]="datetimeValue" formControlName="date">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="data-group data-group-no-min-height">
                <div class="row ">
                    <div class="col-12">
                        <mat-card-title class="gh-sm-title">
                            End:
                        </mat-card-title>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 w-100">
                        <mat-card-subtitle class="gh-sm-title">
                            <!-- <h3>{{datetimeValueEnd}}</h3> -->
                            <div class="gh-datetime-input-container">

                                <input type="datetime-local" [(ngModel)]="datetimeValueEnd" formControlName="end">
                            </div>
                        </mat-card-subtitle>
                    </div>
                </div>
            </div>
        </div>

        <!-- TODO DON ASKED FOR THIS. IT IS A GOOD IDEA BUT WILL HAVE TO BE A FUTURE FEATURE -->
        <!-- TODO MODEL OFF OF COZI OR OTHER CALENDAR SOFTWARE -->
        <!-- <div class="col-12">
            <mat-form-field class="gh-form-field">
                <mat-label>Repeat:</mat-label>
                <mat-select [compareWith]="compareFunction" (selectionChange)="setRepeatOption($event)" formControlName="repeatOption">
                    <mat-option *ngFor="let option of repeatOptions" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->

    </div>










    <div class="row mx-0 my-3 max-w-100">
        <div class="col-12">
            <mat-card-title class="gh-sm-title">
                Games of Interest
            </mat-card-title>

            <div *ngIf="(coreGameData$ | async)">
                <app-gh-variable-length-autocomplete-selector #variableLengthSelector [_limit]="8" [_itemsAreObjects]="true" [_options]="coreGameData.value" [_currentSelections]="collection" (onChangeEmitter)="onSelectionsChanged($event)"></app-gh-variable-length-autocomplete-selector>
            </div>

        </div>
    </div>











    <div class="row mx-0 my-3 local-data-group">

        <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

            GERLIUJP
            <app-autocomplete-chiplist *ngIf="(coreGroups$ | async)" [_items]="coreGroups.value"
                [_selectedItems]="groupToEdit && groupToEdit.title ? [groupToEdit] : null" [_label]="'Group Title'"
                [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="true"
                [_sourceOfInput]="'app-share-modal-advanced-log-play3'" (selectionChanged)="selectGroup($event)">
            </app-autocomplete-chiplist>
        </div>


        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

            HAVENKSLD KFJ
            <app-autocomplete-chiplist *ngIf="(coreHavens$ | async)" [_items]="coreHavens.value"
                [_selectedItems]="havenToEdit && havenToEdit.title ? [havenToEdit] : null" [_label]="'Haven Title'"
                [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="true"
                [_sourceOfInput]="'app-share-modal-advanced-log-play2'" (selectionChanged)="selectHaven($event)">
            </app-autocomplete-chiplist>
        </div> -->


        <div class="col-12 mt-2">
            <div class="data-group data-group-no-min-height">
                <div class="row">
                    <div class="col-12">
                        <mat-card-subtitle class="gh-md-subtitle">
                            Invite Users
                        </mat-card-subtitle>
                        <div *ngIf="(coreUsers$ | async)">
                            <app-autocomplete-chiplist style="z-index: 1000;" [_items]="coreUsers.value" [_label]="'User Name'" [_allowDuplicates]="false" [_allowCustomInput]="false" [_itemsAreObjects]="true" [_singleInput]="false" [_sourceOfInput]="'app-messaging'" (selectionChanged)="selectUsersToInvite($event)">
                            </app-autocomplete-chiplist>

                            <!-- <app-gh-autocomplete-multiselect [_attributeToDisplay]="'title'"
                                                        [_currentSelections]="null" [_itemsList]="coreUsers.value"
                                                        (changeEmitter)="selectUsersToInvite($event)">
                                                 </app-gh-autocomplete-multiselect> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>