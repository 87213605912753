<div class="parent-container row">

    <div class="col-12 text-center">
        <!-- <img [src]="image.imgPath[3]" alt=""> -->
        <!-- OLD VERSION< WORKS  afsd  afdsfdsa f asdfadssa-->
        <img [src]="image" alt="">
    </div>

    <div class="col-12">
        <mat-card-subtitle>{{caption}}</mat-card-subtitle>
    </div>

    <!-- todo title -->
    <!-- todo creator -->
    <!-- todo date -->
    <!-- todo likes -->

</div>