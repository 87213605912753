import { Component, Input, OnInit } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { GhDialogWrapperComponent } from 'src/app/dashboard/dashboard-shared/generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { StringHelper } from '../../../../../../../../../../../../common/helpers/string-helper'
import { actualDurationComponent } from '../actual-duration/actual-duration.component'
import { AddTagsComponent } from '../add-tags/add-tags.component'
import { CommunityVotesComponent } from '../../../../../../../../dashboard-shared/components/games/community-votes/community-votes.component'
import { BackendAPIContentService } from 'src/app/backend-api-services/backend-api-content.service'
import { GameDetailFullCreditsComponent } from '../game-detail-full-credits/game-detail-full-credits.component'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { GameDetailPDAPortfolioComponent } from '../game-detail-pda-portfolio/game-detail-pda-portfolio.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { MatDialog } from '@angular/material/dialog'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-game-detail-objective-info',
    templateUrl: './game-detail-objective-info.component.html',
    styleUrls: ['./game-detail-objective-info.component.scss'], 
})
export class GameDetailObjectiveInfoComponent implements OnInit {
    timeUntil = 'Crowdfunding Lauches in:' // this will be changed to "Crowdfunding Expiry" or something like that
    releaseDate //! refactor!!! actually need two dates, one for crowdfunding start and one for crowdfunding end (once it starts)

    user
    @Input() set _user(user) {
        if (user) {
            this.user = user
        }
    }

    isLoading = true
    selectedGame
    @Input() set _selectedGame(selectedGame) {
        this.selectedGame = selectedGame

        this.getIdealPlayers()

        if (this.selectedGame.crowdfundingHasLaunched) {
            this.timeUntil = 'Crowdfunding Expires in:'
        }
        this.releaseDate = new Date(+this.selectedGame.crowdfundingLaunchDate)
        this.initPublishersDesignersAndArtists()
    }

    idealPlayers
    async getIdealPlayers() {
        let result = await this.backendApiGamesService.GetGameIdealPlayers(this.selectedGame.id)
        if (result && result.idealPlayers) {
            this.idealPlayers = result.idealPlayerVotes
        }
        this.isLoading = false
    }

    publisherString: string = ''
    developerString: string = ''
    artistString: string = ''

    userHasSubmittedIdealPlayers = false

    tags = []

    parentItem
    hasExpansions: boolean = false
    expansions = []
    expansionsString = ''
    isExpansion: boolean = false

    constructor(
        private dialog: MatDialog,
        private backendApiContentService: BackendAPIContentService,
        private screenSizeService: ScreenSizeService,
        private backendApiGamesService: BackendAPIGamesService,
        private backendApiUsersService: BackendAPIUsersService,
    ) {}

    isMobileScreen = false

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })

        this.tags = ['SciFi', 'Cooperative', 'Solo']
    }

    truncate(input: string, maxCharacters: number) {
        return StringHelper.truncateString(input, maxCharacters)
    }

    ensureHttpOrHttps(url) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return 'https://' + url
        }
        return url
    }

    navigateToOfficialPage() {
        if (this.selectedGame.officialLink) {
            let bob = this.ensureHttpOrHttps(this.selectedGame.officialLink)

            window.open(bob, '_blank')
        }
    }

    navigateToCrowdfundingPage() {
        if (this.selectedGame.crowdfundingLink) {
            let bob = this.ensureHttpOrHttps(this.selectedGame.officialLink)

            window.open(bob, '_blank')
        }
    }

    getMinMaxPlayers() {
        if (this.selectedGame.minPlayers && this.selectedGame.maxPlayers) {
            return this.selectedGame.minPlayers == this.selectedGame.maxPlayers
                ? this.selectedGame.minPlayers
                : this.selectedGame.minPlayers + '-' + this.selectedGame.maxPlayers
        } else {
            return null
        }
    }

    getVotedMinMaxPlayers() {
        // this is wrong, math will be done by ben eventually
        if (this.selectedGame.votedBestMinPlayers && this.selectedGame.votedBestMaxPlayers) {
            return this.selectedGame.votedBestMinPlayers == this.selectedGame.votedBestMaxPlayers
                ? this.selectedGame.votedBestMinPlayers
                : this.selectedGame.votedBestMinPlayers +
                      '-' +
                      this.selectedGame.votedBestMaxPlayers
        } else {
            return null
        }
    }

    getMinSuggestedAge() {
        return this.selectedGame.minSuggestedAge &&
            this.selectedGame.minSuggestedAge != null &&
            this.selectedGame.minSuggestedAge != ''
            ? this.selectedGame.minSuggestedAge.toString().concat('+')
            : null
    }

    showFullDetailsButton = false
    initPublishersDesignersAndArtists() {
        if (this.selectedGame.publishers != null) {
            this.publisherString = StringHelper.createCommaSeparatedString(
                this.selectedGame.publishers,
                true,
            )
        }
        if (this.selectedGame.designers != null) {
            this.developerString = StringHelper.createCommaSeparatedString(
                this.selectedGame.designers,
                true,
            )
        }
        if (this.selectedGame.artists != null) {
            this.artistString = StringHelper.createCommaSeparatedString(
                this.selectedGame.artists,
                true,
            )
        }

        this.showFullDetailsButton =
            (this.selectedGame.publisherString && this.selectedGame.publisherString.length > 1) ||
            (this.selectedGame.designers && this.selectedGame.designers.length > 1) ||
            (this.selectedGame.artists && this.selectedGame.artists.length > 1)
    }

    openSubmitPlayerCount() {
        let inputData = this.idealPlayers.averagedArray

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Works Well: Community Votes (WORK IN PROGRESS)',
                component: CommunityVotesComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                hasCloseButton: true,
                inputData: inputData,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })
        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                if (data && data.submitted) {
                    this.userHasSubmittedIdealPlayers = true
                    //ghfjkdshgjfkdhgjkfdhjkgfdh
                    // console.log('Ben\'s nudging or whatever goes here... Data: ', data)
                }
            })
    }

    openViewRealDuration() {
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Duration based on Logged Plays: WORK IN PROGRESS',
                component: actualDurationComponent,
                hasSubmitButton: false,
                hasCancelButton: false,
                allowParentClose: true,
                hasCloseButton: true,
            },
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: false,
        })
        // dialogRef.afterClosed().pipe(untilDestroyed(this)).pipe(untilDestroyed(this)).subscribe(data => {
        //   if (data && data == 'submitted') {
        //     this.userHasSubmittedIdealPlayers = true
        //   }
        // })
    }

    editTags() {
        this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Suggest Tags',
                component: AddTagsComponent,
                inputData: this.selectedGame,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            width: '90%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
        })
    }

    initExpansionsData() {
        this.hasExpansions =
            this.selectedGame.expansions &&
            this.selectedGame.expansions != '' &&
            this.selectedGame.expansions != null
        this.isExpansion =
            this.selectedGame.parentItem &&
            this.selectedGame.parentItem != '' &&
            this.selectedGame.parentItem != null

        if (this.hasExpansions) {
            this.expansions = JSON.parse(this.selectedGame.expansions)
            this.expansionsString = ''

            for (var i = 0; i < this.expansions.length; i++) {
                this.expansionsString +=
                    i == this.expansions.length - 1
                        ? this.expansions[i].title
                        : this.expansions[i].title.concat(', ')
            }
        }

        if (this.isExpansion) {
            this.parentItem = JSON.parse(this.selectedGame.parentItem)
        }
    }

    gameImageNextToken = null
    async fetchSelectedGameImagesByLikes() {
        let selectedGameImagesByLikes = await this.backendApiContentService.listImagesByGameID(
            this.selectedGame.id,
            1,
            this.gameImageNextToken,
        )
        this.gameImageNextToken = selectedGameImagesByLikes.nextToken
    }

    async fetchPublisherGames() {
        let publishersGames = await this.backendApiUsersService.listAllGamesByPublisherTitle(
            this.selectedGame.publishers[0].title,
            50,
        )

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,

            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                inputData: publishersGames,
                title: `${this.selectedGame.publishers[0].title}'s Portfolio`,
                component: GameDetailPDAPortfolioComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }

    async fetchDeveloperGames() {
        let developerGames = await this.backendApiUsersService.listAllGamesByDeveloperTitle(
            this.selectedGame.designers[0].title,
            50,
        )

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                inputData: developerGames,
                title: `${this.selectedGame.designers[0].title}'s Portfolio`,
                component: GameDetailPDAPortfolioComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }

    async fetchArtistGames() {
        let artistGames = await this.backendApiUsersService.listAllGamesByArtistTitle(
            this.selectedGame.artists[0].title,
            50,
        )

        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            data: {
                inputData: artistGames,
                title: `${this.selectedGame.designers[0].title}'s Portfolio`,
                component: GameDetailPDAPortfolioComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }

    openFullCredits() {
        this.dialog.open(GhDialogWrapperComponent, {
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
            maxWidth: '70%',
            maxHeight: '80%',

            data: {
                inputData: this.selectedGame,
                title: `${this.selectedGame.title}: Full Credits`,
                component: GameDetailFullCreditsComponent,
                hasSubmitButton: true,
                hasCancelButton: true,
                // hasLeftActionButton: true,
                // leftActionButtonText: 'Start Over',
                allowParentClose: true,
            },
            autoFocus: false,
        })
    }
}
