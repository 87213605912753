import { Component, OnInit, ViewChild, ViewContainerRef, Input, Output, EventEmitter, ComponentFactoryResolver } from '@angular/core'
import { VerticalTabsService } from '../../services/vertical-tabs/vertical-tabs.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { MatSidenav } from '@angular/material/sidenav'
@UntilDestroy({ checkProperties: true })

@Component({
  selector: 'app-vertical-tabs[_sourceOfInput]',
  templateUrl: './vertical-tabs.component.html',
  styleUrls: ['./vertical-tabs.component.scss']
})
export class VerticalTabsComponent implements OnInit {



  inputData
  @Input() set _inputData(inputData) {

    this.inputData = inputData
    this.viewContainerSet = false

    if (this.viewContainer) {
      this.viewContainer.clear()
    }
  }




  @Input() _sourceOfInput: string

  isMobileScreen = true

  @ViewChild('sidenav') sidenav: MatSidenav

  component

  animateOutUp = false
  animatInUp = false

  @ViewChild('viewContainer', { read: ViewContainerRef }) viewContainer

  type
  // @Input() set _type(type: FeedbackTypeEnums) { 
  @Input() set _type(type: any) {
    this.type = type
  }

  selectedMobileContent

  tabs
  @Input() set _tabs(tabs) {
    if (tabs) {
      this.tabs = tabs
      //* reshape data: by default, set first item to selected and set all others to not selected
      for (var i = 0; i < this.tabs.length; i++) {
        i == 0
          ? this.tabs[i].isSelected = true : this.tabs[i].isSelected = false
      }
      this.selectedMobileContent = this.tabs[0]
    }
  }

  contentComponent
  @Input() set _contentComponent(contentComponent) {
    if (contentComponent) {
      this.contentComponent = contentComponent
      if (this.viewContainer) {
        this.viewContainer.clear()
      }
      this.viewContainerSet = false
    }
  }

  data
  @Input() set _data(data) {
    if (data) {
      this.data = data
    }
  }


  @Output() tabChanged = new EventEmitter()

  @Output() shouldRefreshContentComponentEmitter = new EventEmitter()

  selectedTabIndex = 0

  reason = ''

  close(reason: string) {
    this.reason = reason
    this.sidenav.close()
  }


  constructor(
    private resolver: ComponentFactoryResolver,
    private verticalTabsService: VerticalTabsService,
    private screenSizeService: ScreenSizeService
  ) { }

  ngOnInit() {

    this.screenSizeService.isMobileScreen$.pipe(untilDestroyed(this)).subscribe((isMobileScreen: boolean) => {
      this.isMobileScreen = isMobileScreen
    })


    // If default selected tab not specified, default to tabs[0]
    var initalTabSelected = false
    for (let t of this.tabs) {
      initalTabSelected = t.isSelected
    }
    if (!initalTabSelected) {
      this.selectTab(0)
    }

    this.verticalTabsService.shouldRefreshContentComponent$.pipe(untilDestroyed(this)).subscribe((shouldRefreshContentComponent: boolean) => {
      if (shouldRefreshContentComponent) {
        this.shouldRefreshContentComponentEmitter.emit()
      }
    })

    // testing, delete below:
    //this.selectTab(1)
  }

  viewContainerSet = false
  ngAfterContentChecked() {
    if (!this.viewContainerSet) {
      if (this.viewContainer) {
        this.viewContainerSet = true

        if (this.contentComponent) {
          this.component = this.viewContainer.createComponent(this.resolver.resolveComponentFactory(this.contentComponent))
        }

        if (this.component) {
          this.component.instance._type = this.type
          this.component.instance._maxHeight = '10rem' // notworking
        }

        if (this.component && this.inputData) {

          this.component.instance._inputData = this.inputData
        }
      }
    }
  }

  selectTab(index) {
    this.selectedTabIndex = index
    this.tabs[index].isSelected = true

    for (var i = 0; i < this.tabs.length; i++) {
      if (i != index) {
        this.tabs[i].isSelected = false
      }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //TODO:  ~~~~~~~~~~ TODO ~~~~~~~~~~
    // Working on vertical transition animations:
    //    Was attempting the approach: change class (animate out up, animate in up, etc), but this isn't ideal.
    //       Need to use native angular animations to base these animations on void => * and * => void
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // commenting the below code out until animation is done...
    this.animateOutUp = true

    this.sleep(50).then(() => {
      this.tabChanged.emit(index)
    })
  }

  async sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  getInnerHTML(index) {
    return this.tabs[index].content
  }

  onMobileContentSelectionChange(selectedContent) {
    for (var i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].title == selectedContent.value.title) {
        this.tabChanged.emit(i)
      }
    }
  }


}
