import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogPlayService {

  shouldRefreshLoggedPlays = new BehaviorSubject(false)
  shouldRefreshLoggedPlays$ = this.shouldRefreshLoggedPlays.asObservable()

  constructor() { }


  updateShouldRefreshLoggedPlays(){
    this.shouldRefreshLoggedPlays.next(true)
  }

}
