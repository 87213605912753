import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import {
    SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES,
    SIDEBAR_TAB_TITLES,
} from 'src/app/dashboard/constants/sidebar/sidebar-constants'
import { SidebarCategoryEnums } from '../../enums/sidebar-enums'
import { iSidebarItem } from '../../interfaces/sidebar/ISidebarItem'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
@UntilDestroy({ checkProperties: true })
@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    // NOTE: stringifying and then parsing creates a shallow copy this prevents mutating the ITEMS constant
    itemsAsString
    itemsShallowCopy

    private sidebarItems
    sidebarItems$

    constructor(private backendAPIUsersService: BackendAPIUsersService) {
        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            let sidebarItemsForUser = ITEMS

            if (user && user != null) {
                //! should eventually use switch statement and make these conditionals more ronust. for now only worrying about isHaven
                sidebarItemsForUser = user.userIsHaven ? ITEMS_HAVEN_USER : ITEMS
            }
            this.itemsAsString = JSON.stringify(sidebarItemsForUser)
            this.itemsShallowCopy = JSON.parse(this.itemsAsString)

            this.sidebarItems = new BehaviorSubject(this.itemsShallowCopy)
            this.sidebarItems$ = this.sidebarItems.asObservable()
        })
    }

    getPossibleItems() {
        return POSSIBLE_ITEMS
    }

    public updateSidebarItems(items: iSidebarItem[]) {
        items[0].isSelected = true
        for (var i = 1; i < items.length; i++) {
            items[i].isSelected = false
        }
        this.sidebarItems.next(items)
    }

    public updateSelectedItem(itemTitle: string) {
        for (let item of this.itemsShallowCopy) {
            item.isSelected = item.title == itemTitle
        }
        let updatedItems = JSON.stringify(this.itemsShallowCopy)
        let updatedItemsParsed = JSON.parse(updatedItems)
        this.sidebarItems.next(updatedItemsParsed)
    }

    resetToDefaults() {
        let itemsAsString = JSON.stringify(ITEMS)
        let itemsShallowCopy = JSON.parse(itemsAsString)
        this.sidebarItems.next(itemsShallowCopy)
    }
}

const ITEMS = [
    //{ title: 'MY GH', id: "1", isSelected: false, category: SidebarCategoryEnums.main, icon: 'widgets', isExpiremental: true },
    // { title: 'MY GH', id: "1", isSelected: false, category: SidebarCategoryEnums.main, icon: 'widgets' },
    {
        title: SIDEBAR_TAB_TITLES[0],
        id: '2',
        isSelected: true,
        category: SidebarCategoryEnums.main,
        icon: 'account_circle',
    },
    {
        title: SIDEBAR_TAB_TITLES[1],
        id: '3',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'event_seat',
    },
    {
        title: SIDEBAR_TAB_TITLES[2],
        id: '4',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'casino',
    },
    {
        title: SIDEBAR_TAB_TITLES[3],
        id: '5',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'store',
    }, // replace this icon with custom haven castle icon
    {
        title: SIDEBAR_TAB_TITLES[4],
        id: '6',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'group',
    },
    {
        title: SIDEBAR_TAB_TITLES[5],
        id: '7',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'groups',
    },

    //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    //! possibly temp, moving forum to top level for now
    //! note that the fact that this is 10 is meaningful it is the index of the array
    // 'FORUM'         //10
    {
        title: SIDEBAR_TAB_TITLES[10],
        id: '10',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'forum',
    },
    //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    {
        title: SIDEBAR_TAB_TITLES[6],
        id: '8',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'feedback',
    },
    {
        title: SIDEBAR_TAB_TITLES[7],
        id: '9',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'local_library',
        subItems: [
            {
                title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[0],
                id: '9a',
                isSelected: false,
                category: SidebarCategoryEnums.main,
                icon: 'casino',
            },
            {
                title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[1],
                id: '9b',
                isSelected: false,
                category: SidebarCategoryEnums.main,
                icon: 'insert_photo',
            },
            {
                title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[2],
                id: '9c',
                isSelected: false,
                category: SidebarCategoryEnums.main,
                icon: 'play_circle_filled',
            },
            {
                title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[3],
                id: '9d',
                isSelected: false,
                category: SidebarCategoryEnums.main,
                icon: 'article',
            },
            // { title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[4], id: "9e", isSelected: false, category: SidebarCategoryEnums.main, icon: 'poll' },

            //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            //! poosibly temp, might want to add this back in if we eventually want forum to be in discover rather than top level
            // { title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[4], id: "9e", isSelected: false, category: SidebarCategoryEnums.main, icon: 'forum' }
            //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        ],
    },
    {
        title: SIDEBAR_TAB_TITLES[8],
        id: '10',
        isSelected: false,
        category: SidebarCategoryEnums.pro,
        icon: 'emoji_events',
    },
    {
        title: SIDEBAR_TAB_TITLES[9],
        id: '11',
        isSelected: false,
        category: SidebarCategoryEnums.admin,
        icon: 'admin_panel_settings',
    },
]

const POSSIBLE_ITEMS = [
    //! add id to these too
    //{ title: 'MY GH', isSelected: false, category: SidebarCategoryEnums.main, icon: 'category', isExpiremental: true },
    //{ title: 'MY GH', isSelected: true, category: SidebarCategoryEnums.main, icon: 'category' },

    // { title: 'MY PROFILE', isSelected: false, category: SidebarCategoryEnums.main, icon: 'account_circle' },
    {
        title: 'PROFILE',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'account_circle',
    },

    {
        title: 'EVENTS',
        id: '3',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'event_seat',
    },
    //{ title: 'MY ACTIVITY', isSelected: false, category: SidebarCategoryEnums.main, icon: 'event_seat' },
    { title: 'GAMES', isSelected: false, category: SidebarCategoryEnums.main, icon: 'casino' },
    { title: 'HAVENS', isSelected: false, category: SidebarCategoryEnums.main, icon: 'house' }, // replace this icon with custom haven castle icon

    // { title: 'FIND FRIENDS', isSelected: false, category: SidebarCategoryEnums.main, icon: 'group' },
    { title: 'FRIENDS', isSelected: false, category: SidebarCategoryEnums.main, icon: 'group' },

    { title: 'GROUPS', isSelected: false, category: SidebarCategoryEnums.main, icon: 'groups' },
    { title: 'FEEDBACK', isSelected: false, category: SidebarCategoryEnums.main, icon: 'feedback' },
    { title: 'DISCOVER', isSelected: false, category: SidebarCategoryEnums.main, icon: 'forum' },
    //TODO this is work yet to be done
    //! add subitmes here too once done!!!
    {
        title: 'GH PRO',
        isSelected: false,
        category: SidebarCategoryEnums.pro,
        icon: 'emoji_events',
    },
    {
        title: 'ADMIN',
        isSelected: false,
        category: SidebarCategoryEnums.admin,
        icon: 'admin_panel_settings',
    },

    // testing...
    {
        title: 'UNUSED 1',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'child_care',
    },
    {
        title: 'UNUSED 2',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'sentiment_satisfied_alt',
    },
    {
        title: 'UNUSED 3',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'tag_faces',
    },
    {
        title: 'UNUSED 4',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'emoji_emotions',
    },
    { title: 'UNUSED 5', isSelected: false, category: SidebarCategoryEnums.main, icon: 'mood_bad' },
]

const ITEMS_HAVEN_USER = [
    //{ title: 'MY GH', id: "1", isSelected: false, category: SidebarCategoryEnums.main, icon: 'widgets', isExpiremental: true },
    // { title: 'MY GH', id: "1", isSelected: false, category: SidebarCategoryEnums.main, icon: 'widgets' },

    // was commented out.
    {
        title: SIDEBAR_TAB_TITLES[0],
        id: '2',
        isSelected: true,
        category: SidebarCategoryEnums.main,
        icon: 'account_circle',
    },

    {
        title: SIDEBAR_TAB_TITLES[1],
        id: '3',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'event_seat',
    },
    {
        title: SIDEBAR_TAB_TITLES[2],
        id: '4',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'casino',
    },
    {
        title: SIDEBAR_TAB_TITLES[3],
        id: '5',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'store',
    }, // replace this icon with custom haven castle icon
    {
        title: SIDEBAR_TAB_TITLES[4],
        id: '6',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'group',
    },
    {
        title: SIDEBAR_TAB_TITLES[5],
        id: '7',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'groups',
    },

    //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    //! possibly temp, moving forum to top level for now
    //! note that the fact that this is 10 is meaningful it is the index of the array
    // 'FORUM'         //10
    {
        title: SIDEBAR_TAB_TITLES[10],
        id: '10',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'forum',
    },
    //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    {
        title: SIDEBAR_TAB_TITLES[6],
        id: '8',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'feedback',
    },
    {
        title: SIDEBAR_TAB_TITLES[7],
        id: '9',
        isSelected: false,
        category: SidebarCategoryEnums.main,
        icon: 'local_library',
        subItems: [
            {
                title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[0],
                id: '9a',
                isSelected: false,
                category: SidebarCategoryEnums.main,
                icon: 'casino',
            },
            {
                title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[1],
                id: '9b',
                isSelected: false,
                category: SidebarCategoryEnums.main,
                icon: 'insert_photo',
            },
            {
                title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[2],
                id: '9c',
                isSelected: false,
                category: SidebarCategoryEnums.main,
                icon: 'play_circle_filled',
            },
            {
                title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[3],
                id: '9d',
                isSelected: false,
                category: SidebarCategoryEnums.main,
                icon: 'article',
            },
            // { title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[4], id: "9e", isSelected: false, category: SidebarCategoryEnums.main, icon: 'poll' },

            //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            //! poosibly temp, might want to add this back in if we eventually want forum to be in discover rather than top level
            // { title: SIDEBAR_TAB_DISCOVER_SUBTAB_TITLES[4], id: "9e", isSelected: false, category: SidebarCategoryEnums.main, icon: 'forum' }
            //! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        ],
    },
    {
        title: SIDEBAR_TAB_TITLES[8],
        id: '10',
        isSelected: false,
        category: SidebarCategoryEnums.pro,
        icon: 'emoji_events',
    },
    {
        title: SIDEBAR_TAB_TITLES[9],
        id: '11',
        isSelected: false,
        category: SidebarCategoryEnums.admin,
        icon: 'admin_panel_settings',
    },
]
