import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { BackendAPIUsersService } from 'src/app/backend-api-services/backend-api-users.service'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { StringHelper } from '../../../../../../../../common/helpers/string-helper'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { iHopperItem } from '../../../interfaces/hopper/IHopperItem'
import { DatetimeHelperService } from '../../../services/helpers/datetime-helper.service'
import { MdService } from '../../../services/master-detail/master-detail.service'
import { InfoMessageComponent } from '../../modals/info-message/info-message.component'
import { HopperService } from '../services/hopper.service'
import { HopperTableAnimations } from './animations/hopper-table-animations'
import { MatDrawer } from '@angular/material/sidenav'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { BackendAPIGamesService } from 'src/app/backend-api-services/backend-api-games.service'
import { ulid } from 'ulid'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-hopper[_sourceOfInput]',
    templateUrl: './hopper.component.html',
    styleUrls: ['./hopper.component.scss'],
    animations: [HopperTableAnimations.addItemTrigger],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HopperComponent implements OnInit {
    @Input() _sourceOfInput: string

    selectedIndex = null

    onPanelOpened(index) {
        this.selectedIndex = index
    }

    onPanelClosed(index) {
        if (index == this.selectedIndex) {
            this.selectedIndex = null
        }
    }

    mobileScreenOverride = false
    isMobileScreen = false
    @Input() set _isMobileScreen(isMobileScreen: boolean) {
        if (isMobileScreen) {
            this.isMobileScreen = isMobileScreen // used to force mobile view when hopper used as sub component in desktop view
            this.mobileScreenOverride = true
        }
    }

    isGrayBackground = false // if false, defaults to white
    @Input() set _isGrayBackground(isGrayBackground: boolean) {
        if (isGrayBackground) {
            this.isGrayBackground = isGrayBackground
        }
    }

    userCanAddItems = true
    @Input() set _userCanAddItems(userCanAddItems: boolean) {
        this.userCanAddItems = userCanAddItems
    }

    selectedHaven
    currentHopperData

    hiddenItems = []

    matchesFound = []

    inputData //* needed to pass into add-to-hopper
    isGameSpecific = false
    specificGame = null
    havenId = null
    @Input() set _inputData(inputData) {
        this.inputData = inputData
        this.isGameSpecific = inputData.isGameSpecific

        this.displayedColumns = inputData.displayedColumns

        this.currentHopperData = inputData.hopperData
        this.dataSource = new MatTableDataSource<iHopperItem>(inputData.hopperData)

        this.selectedHaven = inputData.haven
        this.refresh()

        if (this.isGameSpecific) {
            // this.gamesDbAccessorService.getBulkData(this.inputData.specificGame.pk).then(result => {
            //   this.specificGame = JSON.parse(result.data)
            //   this.gamesDbAccessorService.getHopperData(result.pk).then(hopperData => {
            //     this.currentHopperData = JSON.parse(hopperData.data)
            //     this.dataSource = new MatTableDataSource<iHopperItem>(this.currentHopperData)
            //     this.refresh()
            //   })
            // })
        }
    }

    @Output() blurContentEmitter = new EventEmitter()

    fireParticles = []
    numberOfFireParticles = 20
    onFireThreshold = 0.75

    initialAddToHopperData

    user
    hotList

    addItemAnimState = ''

    selectedHopperItem

    leftDrawerIsOpen = false
    @ViewChild('leftDrawer', { static: false }) leftDrawer: MatDrawer

    rightDrawerIsOpen = false
    @ViewChild('rightDrawer', { static: false }) rightDrawer: MatDrawer

    displayedColumns
    dataSource

    constructor(
        private dialog: MatDialog,
        private hopperService: HopperService,
        private backendAPIUsersService: BackendAPIUsersService,
        private changeDetectorRefs: ChangeDetectorRef,
        private datetimeHelperService: DatetimeHelperService,
        private screenSizeService: ScreenSizeService,
        private backendApiGamesService: BackendAPIGamesService
    ) { }

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                if (!this.mobileScreenOverride) {
                    this.isMobileScreen = isMobileScreen
                }
            })

        for (var i = 0; i < this.numberOfFireParticles; i++) {
            this.fireParticles.push(i)
        }

        this.backendAPIUsersService.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
            this.user = user

            // this.usersDbService.getHotlist(this.user.pk).then(result => {
            //   var hotlistIds = []
            //   for (let item of result.data) {
            //     hotlistIds.push(item.i)
            //   }
            //   if (hotlistIds.length != 0) {
            //     this.gamesDbAccessorService.getMultipleGamesByGSI1(hotlistIds).then(hotList => {
            //       this.hotList = hotList.games
            //     })
            //   }
            // })
        })

        if (!this.isGameSpecific) {
            // this.mdService.selectedItem$.pipe(untilDestroyed(this)).subscribe(haven => {
            //   if (haven) {
            //     this.selectedHaven = haven
            //     this.currentHopperData = JSON.parse(haven.hopperData)
            //     this.dataSource = new MatTableDataSource<IHopperItem>(this.currentHopperData)
            //     this.refresh()
            //     // testing, delete below
            //     //this.openRightDrawer(0)
            //     //this.toggleLeftDrawer()
            //   }
            // })
        }
    }

    refresh() {
        this.changeDetectorRefs.detectChanges()
    }

    showGameColumn() {
        return this.displayedColumns.includes('game')
    }

    showHavenColumn() {
        return this.displayedColumns.includes('haven')
    }

    showMinutesSinceColumn() {
        return this.displayedColumns.includes('minutesSincePosted')
    }

    checkIfUserIsAdminOfAnyItems() {
        return this.dataSource.filteredData.some((e) => e.group.admin.pk === this.user.pk)
    }

    drawerIsLoaded = false
    ngAfterContentChecked() {
        if (!this.drawerIsLoaded) {
            if (this.leftDrawer) {
                this.drawerIsLoaded = true
            }
        }
    }

    getMinutesSincePosted(postedTimestamp) {
        return this.datetimeHelperService.getMinutesDifferenceAbsValue(Date.now(), postedTimestamp)
    }

    getIsNewItem(postedTimestamp) {
        return (
            this.datetimeHelperService.getMinutesDifferenceAbsValue(Date.now(), postedTimestamp) <=
            10
        )
    }

    getIsOldItem(postedTimestamp) {
        return (
            this.datetimeHelperService.getMinutesDifferenceAbsValue(Date.now(), postedTimestamp) >=
            50
        )
    }

    toggleLeftDrawer() {
        this.leftDrawerIsOpen = !this.leftDrawerIsOpen
        this.leftDrawer.toggle()
        if (!this.leftDrawerIsOpen) {
            this.clearMatchFlags()
            this.refresh()
        }

        if (this.leftDrawerIsOpen && this.rightDrawerIsOpen) {
            this.closeRightDrawer()
        }
    }

    selectedHopperItemIndex
    openRightDrawer(index) {
        this.selectedHopperItem = this.dataSource.filteredData[index]
        if (this.isGameSpecific) {
            this.selectedHopperItem.game = this.specificGame
        }
        this.selectedHopperItemIndex = index
        this.rightDrawerIsOpen = true
        this.rightDrawer.open()
        this.rightDrawer.open()

        if (this.leftDrawerIsOpen) {
            this.toggleLeftDrawer()
        }
    }

    closeRightDrawer() {
        this.rightDrawer.close()
        this.rightDrawerIsOpen = false
        this.selectedHopperItemIndex = null
    }

    onJoinGroupFromAddToHopper(item) {
        this.toggleLeftDrawer()
        this.openRightDrawer(item.index)
    }

    onPossibleGameMatches(event) {
        let matchesFound = []
        for (var i = 0; i < this.dataSource.filteredData.length; i++) {
            if (this.dataSource.filteredData[i].game.title == event.game.title) {
                this.dataSource.filteredData[i].isFlaggedForMatch = true
                matchesFound.push({
                    index: i,
                    item: this.dataSource.filteredData[i],
                })
            } else {
                if (!this.dataSource.filteredData[i].game.isFlaggedForMatch) {
                    this.dataSource.filteredData[i].isFlaggedForMatch = false // add these properties in since they don't exist before first time through this method
                }
            }
        }
        this.matchesFound = matchesFound
    }

    clearPossibleMatches() {
        for (let item of this.dataSource.filteredData) {
            item.isFlaggedForMatch = false
        }
    }

    clearMatchFlags() {
        for (let item of this.dataSource.filteredData) {
            item.isFlaggedForMatch = false
        }
    }

    addOrEditItem(event) {
        this.checkForLimits()
        if (!this.isGameSpecific) {
            this.addOrEditGame(event)
        } else {
            this.addOrEditHaven(event)
        }
    }

    checkForLimits() {
        var currentUserRowCount = 0
        for (let item of this.dataSource.filteredData) {
            if (item.group.admin.pk == this.user.pk) {
                // user id should change to user pk
                currentUserRowCount++
            }
        }

        if (!this.user.isProUser && currentUserRowCount == 2) {
            this.openShowInfoModal(
                'Game limit reached!',
                '2 games limit for non-pro users.',
                'Upgrade to GH-PRO to access these premium features:',
                [
                    "Game limit for hopper or whatever we're calling this is increased to 5.",
                    'Some other stuff.',
                    'Some more other stuff.',
                    'And even more other stuff!',
                ],
                null,
                'Get Pro!',
            )
            return
        } else if (currentUserRowCount == 5) {
            this.openShowInfoModal(
                'Even pro users have a limit of 5 games.',
                'Thanks for being a pro user!',
                null,
                null,
                null,
                null,
            )
            return
        }
    }

    addOrEditGame(event) {
        let newItem: iHopperItem = {
            itemId: event.isEdit ? event.data.itemId : ulid(),
            group: {  // inferred type:: IHopperGroup
                admin: event.data.admin,
                members: event.data.group
            },
            game: {  // inferred type:: IHopperGame
                title: event.data.game.title,
                currentPlayerCount: event.data.group.length,
                minPlayers: event.data.game.minPlayers,
                maxPlayers: event.data.game.maxPlayers,
                votedBestMinPlayers: 2, // values dont exist in bulk data anymore
                votedBestMaxPlayers: 4, // values dont exist in bulk data anymore
                hasCustomBounds: event.data.hasCustomBounds,
                customLowerBound: event.hasCustomBounds ? event.data.customLowerBound : null,
                customUpperBound: event.data.hasCustomBounds ? event.data.customUpperBound : null,
                minPlayersCanAccept: event.data.game.minPlayers - event.data.group.length,
                maxPlayersCanAccept: event.data.game.maxPlayers - event.data.group.length,
                idealPlayersToAccept: 111111,
                mainImage: event.data.game.thumbImgPath
            },
            desiredIndices: event.data.desiredIndices,
            comments: event.data.comments,
            competitionLevel: event.data.competitionLevel,
            postedTimestamp: event.isEdit ? '1612323465941' : Date.now().toString(), // TODO: change hard-coded timestamp to value from edited item
        }
        //* EDIT HOPPER ITEM
        if (event.isEdit) {
            for (let hopperItem of this.currentHopperData) {
                if (hopperItem.id == newItem.itemId) {
                    hopperItem = newItem
                }
            }
        }
        //* ADD HOPPER ITEM
        else {
            this.currentHopperData.unshift(newItem) // unshift inserts at beginning of array
            // this.selectedHaven.hopperData = JSON.stringify(this.currentHopperData)
            // this.havensDbService.updateHaven(this.selectedHaven).then(result => {
            //   this.toggleLeftDrawer()
            // })
            this.toggleLeftDrawer()
        }
    }

    //! combine these!!!

    addOrEditHaven(event) {

        let newItem: iHopperItem = {
            itemId: event.isEdit ? event.data.itemId : StringHelper.getRandomString(),
            group: {  // inferred type:: IHopperGroup
                admin: event.data.admin,
                members: event.data.group
            },
            game: {  // inferred type:: IHopperGame
                title: event.data.game.title,
                currentPlayerCount: event.data.group.length,
                minPlayers: event.data.game.minPlayers,
                maxPlayers: event.data.game.maxPlayers,
                votedBestMinPlayers: 2, // values dont exist in bulk data anymore
                votedBestMaxPlayers: 4, // values dont exist in bulk data anymore
                hasCustomBounds: event.data.hasCustomBounds,
                customLowerBound: event.data.hasCustomBounds ? event.data.customLowerBound : null,
                customUpperBound: event.data.hasCustomBounds ? event.data.customUpperBound : null,
                minPlayersCanAccept: event.data.game.minPlayers - event.data.group.length,
                maxPlayersCanAccept: event.data.game.maxPlayers - event.data.group.length,
                idealPlayersToAccept: 111111,
                mainImage: event.data.game.mainImage
            },
            desiredIndices: event.data.desiredIndices,
            comments: event.data.comments,
            competitionLevel: event.data.competitionLevel,
            postedTimestamp: event.isEdit ? '1612323465941' : Date.now().toString(), // TODO: change hard-coded timestamp to value from edited item
            haven: event.data.haven
        }
        //* EDIT HOPPER ITEM
        if (event.isEdit) {
            for (let hopperItem of this.currentHopperData) {
                if (hopperItem.id == newItem.itemId) {
                    hopperItem = newItem
                }
            }
        }
        //* ADD HOPPER ITEM
        else {
            this.currentHopperData.unshift(newItem) // unshift inserts at beginning of array
            this.specificGame.hopperData = JSON.stringify(this.currentHopperData)
            // this.gamesDbUpdateService.updateGameHopperData(this.specificGame).then(result => {

            //   this.toggleLeftDrawer()
            // })
        }

    }

    joinUsersToItem(event) {
        let updatedMembers = event.itemToJoin.group.members
        for (let member of event.form.controls.playersToJoin.value) {
            updatedMembers.push(member)
        }

        let newItem: iHopperItem = {
            itemId: event.itemToJoin.itemId,
            group: {
                admin: event.itemToJoin.group.admin,
                members: updatedMembers,
            },
            game: event.itemToJoin.game,
            desiredIndices: event.itemToJoin.desiredIndices,
            comments: event.itemToJoin.comments,
            competitionLevel: event.itemToJoin.competitionLevel,
            postedTimestamp: '1612323465941', // TODO: change hard-coded timestamp to value from edited item
        }

        newItem.game.currentPlayerCount = updatedMembers.length

        if (this.isGameSpecific) {
            newItem.haven = event.itemToJoin.haven
        }

        // TODO: regarding below.. if non-game-specific hopper data is stored in haven DB item, then should game-specific hopper data be stored in the game DB item?  (probably, seems to make sense...)
        //! need to account for game specific, hopper service handled this...
        // this.hopperService.editItem(newItem, this.isGameSpecific).then(() => {
        //   this.closeRightDrawer()
        // })

        for (let hopperItem of this.currentHopperData) {
            if (hopperItem.id == newItem.itemId) {
                hopperItem = newItem
            }
        }
        // TODO: once figured out edit, add the call here
    }

    truncate(input: string, maxCharacters: number) {
        return StringHelper.truncateString(input, maxCharacters)
    }

    //!!!!!!!!! this wont work for hiding items!!! will actually delete them!!!
    deleteItem(element) {
        var indexToRemove = -1
        for (var i = 0; i < this.currentHopperData.length; i++) {
            if (this.currentHopperData[i].itemId == element.itemId) {
                indexToRemove = i
            }
        }
        if (i != -1) {
            this.currentHopperData.splice(indexToRemove, 1)
        }

        if (!this.isGameSpecific) {
            this.selectedHaven.hopperData = JSON.stringify(this.currentHopperData)
            // this.havensDbService.updateHaven(this.selectedHaven).then(result => {
            //   // call snackbar here
            // })
        } else {
            // this.gamesDbUpdateService.updateGameHopperData(this.specificGame).then(result => {
            //   this.toggleLeftDrawer()
            // })
        }
    }

    hideItem(element) {
        this.hiddenItems.push(element)
    }

    getItemIsHidden(element) {
        return this.hiddenItems.some((item) => item.itemId === element.itemId)
    }

    restoreHiddenItems() {
        this.hiddenItems = []
    }

    openEditItem(element) {
        this.initialAddToHopperData = element
        this.toggleLeftDrawer()
    }

    getIsInUserHotlist(item) {
        if (this.user && this.hotList && this.hotList.length != 0) {
            if (this.hotList.some((e) => e.title === item.title)) {
                return true
            }
        }
        return false
    }

    // getIsInUserHavenHotlist(item) {
    //   if (this.user.havenHotlist.some(e => e === item)) {
    //     return true
    //   }
    //   return false
    // }

    getGroupName(members) {
        var result = ''
        for (var i = 0; i < members.length; i++) {
            if (i != members.length - 1) {
                result += members[i].title.concat(', ')
            } else {
                result += members[i].title
            }
        }
        return result
    }

    openShowInfoModal(
        message: string,
        submessage: string,
        bulletsHeader: string,
        bullets: string[],
        imagePath,
        actionButtonText,
    ) {
        let inputData = {
            message: message,
            subMessage: submessage,
            bulletsHeader: bulletsHeader,
            bullets: bullets,
            image: null,
            actionButtonText: actionButtonText,
        }

        this.blurContentEmitter.emit(true)
        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Get GH Pro!',
                component: InfoMessageComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                submitButtonText: 'Get Pro!',
            },
            maxWidth: '30%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRef
            .afterClosed()
            .pipe(untilDestroyed(this))
            .subscribe((data) => {
                this.blurContentEmitter.emit(false)
                if (data && data == 'confirm') {
                    // pretending that the user got pro
                    this.user.isProUser = true
                    // this.maxGames = 5
                }
            })
    }

    getCurrentUserIsInMembers(element) {
        if (element && this.user) {
            return element.group.members.some((e) => e.title === this.user.title)
        }
    }

    removeUserFromRow(element) {
        var updatedMembers = []
        for (let member of element.group.members) {
            if (member != this.user) {
                updatedMembers.push(member)
            }
        }

        let newItem: iHopperItem = {
            itemId: element.itemId,
            group: {
                admin: element.group.admin,
                members: updatedMembers,
            },
            game: element.game,
            desiredIndices: element.desiredIndices,
            comments: element.comments ? element.comments.value : '',
            competitionLevel: element.competitionLevel,
            postedTimestamp: '1612323465941', // TODO: change hard-coded timestamp to value from edited item
        }

        newItem.game.currentPlayerCount = updatedMembers.length

        this.hopperService.editItem(newItem, this.isGameSpecific).then(() => {
            // do whatever here
        })
    }

    getMaxMobileAccordionHeight() {
        return '16rem'
    }
}
