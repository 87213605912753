import { ChangeDetectionStrategy, Component, Input, OnInit, SecurityContext } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { BehaviorSubject, interval } from 'rxjs'
import {
    GH_PRO_INFO_MESSAGE_BULLETS,
    GH_PRO_INFO_MESSAGE_BULLETS_HEADER,
} from '../../../constants/info-message-constants'
import { BannerLocationEnums } from '../../../enums/banner-location-enums'
import { BannerSizeEnums } from '../../../enums/banner-size-enums'
import { GhDialogWrapperComponent } from '../../../generics/gh-dialog-wrapper/gh-dialog-wrapper.component'
import { iBannerAdInputData } from '../../../interfaces/ads/IBannerAdInputData'
import { BannerAdService } from '../../../services/ads/banner-ad.service'
import { SidebarService } from '../../../services/sidebar/sidebar.service'
import { InfoMessageComponent } from '../../modals/info-message/info-message.component'
import { DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS } from 'src/app/app.constants'
import { ScreenSizeService } from 'src/app/shared/services/screen-size.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatDialog } from '@angular/material/dialog'
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-banner-ad[_sourceOfInput]',
    templateUrl: './banner-ad.component.html',
    styleUrls: ['./banner-ad.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerAdComponent implements OnInit {
    @Input() _sourceOfInput: string

    bannerSizeEnum: BannerSizeEnums
    bannerWidth: number
    bannerHeight: number
    multiplier: number = 1
    bannerLocation: BannerLocationEnums
    hasButtons = true
    specificAds = []
    isIFrame = false


    @Input() set _inputData(inputData: iBannerAdInputData) {
        this.bannerSizeEnum = inputData.bannerSizeEnum
        this.multiplier = inputData.multiplier
        this.setDimensions()
        this.bannerLocation = inputData.bannerLocation
        this.hasButtons = inputData.hasButtons
        //! still need to deal with other indexes of specific ad if there is more than one in the array
        this.specificAds = inputData.specificAds
        this.isIFrame = inputData.isIFrame

        if (!this.specificAds) {
            this.initAds()

            interval(250000).subscribe(() => {
                this.iterateAd()
            })
        } else {
            // TODO: change this to input.blabla...
            if (this.isIFrame) {
                this.currentIFrameLink.next(this.specificAds[0].link)

                if (this.specificAds[1]) {
                    alert('burrito!')
                }
            } else {
                this.ads = [
                    {
                        imgPath: 'https://gh-img.s3.amazonaws.com/ADs/ad_two.jpg',
                        link: 'https://www.kickstarter.com/projects/piratesofpenryn/dice-splice?ref=discovery&term=dice%20splice',
                        newTab: true,
                    },
                ]
                this.currentImgPath = this.ads[0].imgPath
            }
        }
    }

    displayCloseIcon = true
    @Input() set _displayCloseIcon(displayCloseIcon: boolean) {
        this.displayCloseIcon = displayCloseIcon
    }

    displaySecondAd = false
    @Input() set _displaySecondAd(displaySecondAd: boolean) {
        this.displaySecondAd = displaySecondAd
    }

    imageElement
    ads
    currentIndex = 0
    currentImgPath
    currentImgPath2
    currentIFrameLink = new BehaviorSubject('')
    currentIFrameLink$ = this.currentIFrameLink.asObservable()

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private screenSizeService: ScreenSizeService,

        private sidebarService: SidebarService,
        private bannerAdService: BannerAdService,
        private dialog: MatDialog,
    ) { }

    isMobileScreen = false

    ngOnInit(): void {
        this.screenSizeService.isMobileScreen$
            .pipe(untilDestroyed(this))
            .subscribe((isMobileScreen: boolean) => {
                this.isMobileScreen = isMobileScreen
            })
    }

    initAds() {
        this.ads = this.bannerAdService.getBannerAds(this.bannerLocation)
        this.currentImgPath = this.ads[this.currentIndex].imgPath

        this.currentImgPath2 = window.location.origin + '/assets/images/coolStuff/Chart-example.png'
    }

    setDimensions() {
        // see enum file for banner size descriptions and link to reference website
        switch (this.bannerSizeEnum) {
            case BannerSizeEnums.banner300x250:
                this.bannerWidth = 300
                this.bannerHeight = 250
                break

            case BannerSizeEnums.banner728x90:
                this.bannerWidth = 728
                this.bannerHeight = 90
                break

            case BannerSizeEnums.banner160x600:
                this.bannerWidth = 160
                this.bannerHeight = 600
                break

            case BannerSizeEnums.banner300x600:
                this.bannerWidth = 300
                this.bannerHeight = 600
                break

            case BannerSizeEnums.banner970x90:
                this.bannerWidth = 970
                this.bannerHeight = 90
                break

            case BannerSizeEnums.banner336x280:
                this.bannerWidth = 336
                this.bannerHeight = 280
                break

            case BannerSizeEnums.banner120x600:
                this.bannerWidth = 120
                this.bannerHeight = 600
                break

            case BannerSizeEnums.banner200x200:
                this.bannerWidth = 200
                this.bannerHeight = 200
                break

            case BannerSizeEnums.banner250x250:
                this.bannerWidth = 250
                this.bannerHeight = 250
                break

            case BannerSizeEnums.banner120x240:
                this.bannerWidth = 120
                this.bannerHeight = 240
                break

            case BannerSizeEnums.banner468x60:
                this.bannerWidth = 468
                this.bannerHeight = 60
                break

            case BannerSizeEnums.banner234x60:
                this.bannerWidth = 234
                this.bannerHeight = 60
                break
        }
    }

    getBannerWidth() {
        return (this.bannerWidth * this.multiplier).toString().concat('px')
    }

    getBannerHeight() {
        return (this.bannerHeight * this.multiplier).toString().concat('px')
    }

    imageIsLoaded = false
    ngAfterViewChecked() {
        if (!this.imageIsLoaded) {
            this.imageElement = document.getElementById('img')
            if (this.imageElement) {
                this.imageIsLoaded = true
            }
        }
    }

    openAd() {
        if (this.ads[this.currentIndex.valueOf()].newTab == false) {
            //! Important: router.navigate must come after sidebarService.updateSelectedItem, otherwise the route param doesn't work
            this.sidebarService.updateSelectedItem('FEEDBACK')
            // this.router.navigate(['feedback/feedback-dashboard/2'], { relativeTo: this.route })
            this.router.navigate(['dashboard/feedback/feedback-dashboard/2'])
        } else {
            window.open(this.ads[this.currentIndex.valueOf()].link, '_newtab' + Date.now())
        }
    }

    iterateAd() {
        if (this.currentIndex < this.ads.length - 1) {
            this.currentIndex += 1
        } else {
            this.currentIndex = 0
        }
        let imageAsAny: any = this.imageElement
        imageAsAny.src = this.ads[this.currentIndex.valueOf()].imgPath
    }

    onCloseIconClicked() {
        this.openShowInfoModal(
            'Want to remove ads...',
            'Unlock this premium feature?',
            GH_PRO_INFO_MESSAGE_BULLETS_HEADER,
            GH_PRO_INFO_MESSAGE_BULLETS,
            null,
        )
    }

    openShowInfoModal(
        message: string,
        submessage: string,
        bulletsHeader: string,
        bullets: string[],
        imagePath,
    ) {
        let inputData = {
            message: message,
            subMessage: submessage,
            bulletsHeader: bulletsHeader,
            bullets: bullets,
            image: null,
        }

        let dialogRef = this.dialog.open(GhDialogWrapperComponent, {
            data: {
                title: 'Get GH Pro!',
                component: InfoMessageComponent,
                inputData: inputData,
                hasSubmitButton: true,
                hasCancelButton: true,
                allowParentClose: true,
                submitButtonText: 'Get Pro!',
            },
            maxWidth: '30%',
            panelClass: this.isMobileScreen
                ? [DESKTOP_MODAL_PANEL_CLASS, MOBILE_MODAL_PANEL_CLASS]
                : DESKTOP_MODAL_PANEL_CLASS,
            backdropClass: 'gh-dialog-backdrop',
            disableClose: true,
        })

        dialogRef.afterClosed().subscribe((data) => {
            if (data && data == 'confirm') {
                // remove ads for testing purposes?
            }
        })
    }
}
