import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { ScreenSizeEnums } from '../enums/screen-size-enums';

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {

  private isMobileScreen = new BehaviorSubject(true);
  isMobileScreen$ = this.isMobileScreen.asObservable();

  private currentViewportWidth = new BehaviorSubject(null);
  currentViewportWidth$ = this.currentViewportWidth.asObservable();


  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.XSmall]) {
           this.isMobileScreen.next(true); 
           this.currentViewportWidth.next(ScreenSizeEnums.XSmall)
      }
      if (state.breakpoints[Breakpoints.Small]) {
           this.isMobileScreen.next(true); 
           this.currentViewportWidth.next(ScreenSizeEnums.Small)
      }
      if (state.breakpoints[Breakpoints.Medium]) {
           this.isMobileScreen.next(true); 
           this.currentViewportWidth.next(ScreenSizeEnums.Medium)
      }
      if (state.breakpoints[Breakpoints.Large]) {
          this.isMobileScreen.next(false);
          this.currentViewportWidth.next(ScreenSizeEnums.Large)
      }
      if (state.breakpoints[Breakpoints.XLarge]) {
         this.isMobileScreen.next(false);
         this.currentViewportWidth.next(ScreenSizeEnums.XLarge)
      }
    });
   }
}
