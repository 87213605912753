export enum RadarChartTypeEnums {
    "friend" = "Friend",
    "game" = "Game",
    "group" = "Group",
    "haven" = "Haven",
    "profile" = "Profile"
}

export enum RadarChartOverlayTypeEnums {
    "myAttributes" = "My Attributes"
}

export enum RadarChartAttributeEnums {
    "friend" = "RadarChartProfileFriendGameAttributeEnums",
    "game" = "RadarChartProfileFriendGameAttributeEnums",
    "group" = "RadarChartGroupAttributeEnums",
    "haven" = "RadarChartHavenAttributeEnums",
    "profile" = "RadarChartProfileFriendGameAttributeEnums"
}

export enum RadarChartGroupAttributeEnums {
    "gamingAtmosphere" = "Gaming Atmosphere",
    "membership" = "Membership Size",
    "competitionLevel" = "Competition Level",
    "focus" = "Game Variety",
    "familyFriendly" = "Family Friendly",
    "organization" = "Organization",
}
export enum RadarChartHavenAttributeEnums {
    "gamingAtmosphere" = "Gaming Atmosphere",
    "competitionLevel" = "Competition Level",
    "tableCount" = "Table Count",
    "librarySize" = "Library Size",
    "retail" = "Retail",
    "food" = "Food",
}

export enum RadarChartProfileFriendGameAttributeEnums {
    "complexity" = "Complexity",
    "depth" = "Depth",
    "duration" = "Duration",
    "interaction" = "Interaction",
    "competition" = "Competition",
    "players" = "Players",
    "chance" = "Chance",
    "theme" = "Theme",
}
