<div class="parent-container">

    <div class="inner-container">
        <mat-dialog-content>
            <div class="row">

                <div class="col-3">
                    <mat-card-title class="gh-sm-title mt-3">{{message}}</mat-card-title>
                    <mat-card-subtitle class="gh-md-subtitle mt-2">{{subMessage}}</mat-card-subtitle>
                </div>

                <div class="col-1">
                    <mat-divider id="vertical-divider" [vertical]="true"></mat-divider>
                </div>

                <div class="col-8">
                    <mat-card-subtitle class="gh-lg-subtitle gh-bold">{{bulletsHeader}}</mat-card-subtitle>
                    <mat-list role="list">
                        <mat-list-item role="listitem" *ngFor="let bullet of bullets">

                            <span class="inline-span">
                                <mat-icon class="gh-icon">casino</mat-icon>
                            </span>

                            <span class="inline-span ml-3">
                                {{bullet}}
                            </span>

                        </mat-list-item>
                    </mat-list>

                    <div class="img-container" *ngIf="image">
                        <img src="{{image}}" alt="">
                    </div>
                </div>

            </div>
        </mat-dialog-content>
    </div>

</div>