<div class="parent-container">

    <div class="inner-container">
        <mat-dialog-content *ngIf="!inputData || !inputData.contentComponent">

            <div class="row">
                <div class="col-12 text-center">
                    <mat-card-title id="message" class="gh-sm-title">{{message}}</mat-card-title>
                </div>
                <div class="col-12 text-center">
                    <mat-card-subtitle id="submessage" class="gh-md-subtitle">{{submessage}}</mat-card-subtitle>
                </div>
            </div>

            <div class="row" *ngIf="hasPassword">
                <div class="col-12 text-center">
                    <mat-form-field>
                        <mat-label>Type "Confirm" to proceed</mat-label>
                        <input matInput type="text" [(ngModel)]="password">
                    </mat-form-field>
                </div>
            </div>

        </mat-dialog-content>

        <mat-dialog-content *ngIf="inputData && inputData.contentComponent">

            <div class="row">
                <div class="col-5 mt-2">
                    <mat-card-title class="gh-sm-title">{{message}}</mat-card-title>
                    <mat-card-subtitle class="gh-md-subtitle mt-3 ">{{submessage}}</mat-card-subtitle>
                </div>

                <div class="col-1">
                    <mat-divider id="vertical-divider" [vertical]="true"></mat-divider>
                </div>

                <div class="col-6">
                    <ng-template #viewContainer></ng-template>
                </div>
            </div>

            <div class="row" *ngIf="hasPassword">
                <h1>password!</h1>
            </div>

        </mat-dialog-content>

    </div>

</div>