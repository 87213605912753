import { trigger, state, style, transition, animate, keyframes } from '@angular/animations'

export const GameDetailAnimations = {

  addToMyGamesTrigger: trigger('addToMyGamesAnimTrigger', [
    state('addToMyGamesAnimIdle', style({
      background: '#8a2d20'
    })),
    state('addToMyGamesAnimActive', style({
      background: 'lightgray'
    })),

    transition('addToMyGamesAnimIdle => addToMyGamesAnimActive', [
      animate(500, keyframes([
        style({ transform: 'rotate(0deg)', transition: '' }),
        style({ transform: 'rotate(359deg) scale(1.2)' }),
      ]))
    ]),

    transition('addToMyGamesAnimActive => addToMyGamesAnimIdle', [
      animate(500, keyframes([
        style({ transform: 'rotate(0deg)', transition: '' }),
        style({ transform: 'rotate(-359deg) scale(1.2)' }),
      ]))
    ]),
    
  ]),


  addToWishlistTrigger: trigger('addToWishlistAnimTrigger', [
    state('addToWishlistAnimIdle', style({
      background: '#2c3531'
    })),
    state('addToWishlistAnimActive', style({
      background: 'lightgray'
    })),

    transition('addToWishlistAnimIdle => addToWishlistAnimActive', [
      animate(500, keyframes([
        style({ transform: 'rotate(0deg)', transition: '' }),
        style({ transform: 'rotate(359deg) scale(1.2)' }),
      ]))
    ]),

    transition('addToWishlistAnimActive => addToWishlistAnimIdle', [
      animate(500, keyframes([
        style({ transform: 'rotate(0deg)', transition: '' }),
        style({ transform: 'rotate(-359deg) scale(1.2)' }),
      ]))
    ])
  ]),


  fadeInImage: trigger('fadeInTrigger', [
    transition('* => *', [
      animate(500, keyframes([
        style({ opacity: 0, transform: 'scale(0)' }),
        style({ opacity: 1, transform: 'scale(1.05)' }),
        style({ opacity: 1, transform: 'scale(1)' })
      ]))
    ])
  ]),

  fadeInSlowlyImage: trigger('fadeInSlowlyTrigger', [
    transition('* => *', [
      animate(1500, keyframes([
        style({ opacity: 0}),
        style({ opacity: 1})
      ]))
    ])
  ]),

  slideUp: trigger('slideUpTrigger', [
    transition('* => *', [
      animate(750, keyframes([
        style({ opacity: 0, transform: 'translateY(60px)', offset: 0 }),
        style({ opacity: 1, transform: 'translateY(-15px)', offset: .3 }),
        style({ opacity: 1, transform: 'translateY(0)', offset: 1 })
      ]))
    ])
  ]),

  myGamesDieRollTrigger: trigger('myGamesDieRollAnimTrigger',  [
    state('dieAnimIdle', style({
      background: '#8a2d20',
      position: 'fixed',
      bottom: '5%',
      left: '80%',
      height: '100px',
      width: '100px'
    })),
    state('dieAnimActive', style({
      display: 'none'
    })),

    transition('dieAnimIdle => dieAnimActive', [
        animate(1000, keyframes([
            style({ left: '0', transform: 'rotate(0deg)', display: 'block' }),
            style({ left: '80%', background: 'white', transform: 'rotate(1077deg)'})
        ]))
    ])
]),

wishListDieRollTrigger: trigger('wishlistDieRollAnimTrigger',  [
  state('dieAnimIdle', style({
    background: '#2c3531',
    position: 'fixed',
    bottom: '5%',
    left: '80%',
    height: '100px',
    width: '100px'
  })),
  state('dieAnimActive', style({
    display: 'none'
  })),

  transition('dieAnimIdle => dieAnimActive', [
      animate(1000, keyframes([
          style({ left: '0', transform: 'rotate(0deg)', display: 'block' }),
          style({ left: '80%', background: 'white', transform: 'rotate(718deg)' })
      ]))
  ])
])




}