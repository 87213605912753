import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class VerticalTabsService {

  private shouldRefreshContentComponent = new BehaviorSubject(false)
  shouldRefreshContentComponent$ = this.shouldRefreshContentComponent.asObservable()

  constructor() { }

  setShouldRefreshContentComponent() {
    this.shouldRefreshContentComponent.next(true)
  }

}
