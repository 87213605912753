export interface iCreateHavenGameTrackingItemInput {
  gameId: string;
  UID: string;
  type: string;
  userState: string;
  userZipCode: number;
  title: string;
  specificLocation: string;
  userTitle: string;
  mainImageFiles: string[];
}

export interface iCreateHavenGameTrackingItem {
  gameId: string;
  UID: string;
  type: string;
  userState: string;
  userZipCode: number;
  title: string;
  userTitle: string;
  mainImageFiles: string[];
  specificLocation?: string;
}










export interface iCreateGameTrackingItemInput {
  gameId: string;
  priority?: number;
  UID: string;
  type: string;
  userState: string;
  userZipCode: number;
  title: string;
  userTitle: string;
  mainImageFiles: string[];
  specificLocation?: string;

  notes?: string;

}










export interface iAddToMyHavensInput {
  havenId: string;
  title: string;
  UID: string;
  type: string;
  userTitle: string;
  userState: string;
  userZipCode: number;

  // mainImageFiles: string[]
}

export interface iRemoveFromMyHavensInput {
  havenId: string;
  userId: string;
  type: string;
}

export interface iAddRatingInput {
  gameId?: string;
  havenId?: string;
  UID: string;
  type: string;
  userState: string;
  userZipCode: number;
  title: string;
  userTitle: string;
  mainImageFiles: string[];
  rating?: string;
  numberRatings?: string;
  big8Vote?: any;
}

export interface iAddReviewInput {
  gameId: string;
  UID: string;
  type: string;
  userState: string;
  userZipCode: number;
  title: string;
}

export interface iDeleteGamesTrackingItemInput {
  gameId: string;
  UID: string;
  type: string;
  title: string;
}

export interface iDeleteGamesTrackingItemByPkSkInput {
  pk: string;
  sk: string;
}

export interface iDeleteGamesILoveInput {
  gameId: string;
  UID: string;
  type: string;
  title: string;
}

export interface iDeleteLookingToPlayInput {
  gameId: string;
  UID: string;
  type: string;
  title: string;
}

export interface iDeleteMyCollectionInput {
  gameId: string;
  UID: string;
  type: string;
  title: string;
}

export interface iDeleteMyWishlistInput {
  gameId: string;
  UID: string;
  type: string;
  title: string;
}

export interface iDeleteRatingInput {
  pk: string;
  sk: string;
}

export interface IGQL_DeleteGameTrackingRating {
  pk: string;
  sk: string;
}

export interface iAddToGamesIAmLookingToPlayInput {
  gameId: string;
  UID: string;
  type: string;
  userState: string;
  userZipCode: number;
  title: string;
  userTitle: string;
  mainImageFiles: string[];
}

export interface iDeleteFromMyCollectionInput {
  gameId: string;
  UID: string;
  type: string;
  userState: string;
  userZipCode: number;
  title: string;
}

export interface iDeleteFromMyWishlistInput {
  gameId: string;
  UID: string;
  type: string;
  userState: string;
  userZipCode: number;
  title: string;
}
