import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-sized-image-view',
  templateUrl: './full-sized-image-view.component.html',
  styleUrls: ['./full-sized-image-view.component.scss']
})
export class FullSizedImageViewComponent implements OnInit {

  image
  caption
  numLikes

  inputData

  @Input() set _inputData(inputData) {
    this.inputData = inputData
    this.image = inputData.image
    this.caption = inputData.caption
    this.numLikes = inputData.numLikes
  }


  constructor() { }

  ngOnInit(): void {
  }

}
