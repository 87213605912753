<div class="col-12 text-center" [ngStyle]="{ marginTop: isMobileScreen ? '1rem' : 0 }">

    <span class="inline-span mr-2">
        <mat-icon *ngIf="isMobileScreen" class="gh-icon">touch_app</mat-icon>
        <mat-icon *ngIf="!isMobileScreen" class="gh-icon">mouse</mat-icon>
    </span>

    <span class="inline-span">
        <mat-card-subtitle class="gh-md-subtitle">{{helperText}}</mat-card-subtitle>
    </span>

</div>

<mat-grid-list [cols]="breakpoint" rowHeight="10rem" gutterSize="4px" (window:resize)="onResize($event)">

    <mat-grid-tile *ngFor="let item of imageArray; let i = index">

        <div class="row tile-content">

            <div class="col-12">
                <div class="item-container">
                    <img (click)="toggleMobileButtons(item)" src="{{item.paths[1]}}">

                    <div *ngIf="!isMobileScreen || isMobileScreen && item.displayActionButtons" class="hover-actions">

                        <!-- <button *ngIf="isGHAdmin" mat-raised-button class="gh-button gh-button-secondary mb-1"
                            (click)="onUntagButtonClicked(item)">
                            <mat-icon>broken_image</mat-icon>
                        </button> -->

                        <button *ngIf="isGHAdmin" mat-raised-button class="gh-button gh-button-warning mb-1"
                            (click)="onDeleteButtonClicked(item)">
                            <mat-icon>delete</mat-icon>
                        </button>

                        <!-- <button mat-raised-button class="gh-button gh-button-like mb-1" (click)="onLikeButtonClicked(item)">
                            <mat-icon>thumb_up_alt</mat-icon>
                        </button> -->

                        <button mat-raised-button class="gh-button gh-button-4" (click)="selectImage(i, item)">
                            <mat-icon>zoom_in</mat-icon>
                        </button>

                    </div>

                </div>

            </div>

        </div>

    </mat-grid-tile>

</mat-grid-list>