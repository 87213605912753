


<!-- ! THis is not being used -->
<!-- ! THis is not being used -->
<!-- ! THis is not being used -->
<!-- ! THis is not being used -->
<!-- ! THis is not being used -->
<!-- ! THis is not being used -->
<!-- ! THis is not being used -->
<!-- ! THis is not being used -->
<!-- ! THis is not being used -->
<!-- ! THis is not being used -->
<!-- ! THis is not being used -->


<div *ngIf="articles && articles != [null]" class="card-columns"> 
    <mat-card *ngFor="let article of articles" class="card mat-elevation-z6">

        <div class="card-body">

            <div class="row">
                <div class="col-7" *ngIf="article.mainImageFiles">
                    <img *ngIf="article.mainImageFiles[0]" [src]="article.mainImageFiles[0]"> 
                </div>
                <div class="col-5"> 
                    <mat-card-title class="gh-sm-title">{{ article.title }}</mat-card-title>
                    <mat-card-subtitle>{{ article.intro }}</mat-card-subtitle>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <mat-card-subtitle [innerHTML]="article.textonly"></mat-card-subtitle>
                </div>
            </div>

            <div class="row">
                <div class="col-2">
                    <span class="inline-span">
                        <mat-icon class="gh-icon">thumb_up_alt</mat-icon>
                        {{ article.likes }}
                    </span>
                </div>
                <div class="col-5">
                    <h6>Author goes here</h6>
                </div>
                <div class="col-5">
                    <h4 *ngIf="article.createdAt">Published: {{article.createdAt | date: 'MMM d, y'}}</h4>
                </div>
            </div>

            <button (click)="openFullDetails(article.editor)"> See Full Article</button>

            <span *ngIf="article.addedByUID == user.username" class="inline-span ml-3" (click)="deleteArticle(article)">
                <mat-icon class="gh-icon gh-icon-clickable" matTooltip="Delete" matTooltipPosition="above">delete
                </mat-icon>
            </span>

            <!-- <div [innerHTML]="article.editor"></div> -->
            <!-- <img style="width: 50px; width: 50px; border-radius: 50%;" *ngIf="article.articleAuthorImage"
                [src]="article.articleAuthorImage">-->
            <!-- todo we only have the ID of this user <h3>Author: {{article.articleAuthor}}</h3>  -->

        </div>

    </mat-card>
</div>