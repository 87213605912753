import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'falseToNope'
})
export class FalseToNopePipe implements PipeTransform {

  transform(value: boolean, ...args: any[]): string {

    switch (value) {

      case true:
        return 'Yep!'

      case false:
        return 'Nope.'

    }
  }

}
