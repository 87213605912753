<mat-card-content>
    <div class="row teams-row">

        <div class="col-5 teams-col">
            {{game.team1.teamName ? game.team1.teamName : game.team1.members}}
        </div>

        <div class="col-2 teams-col"><b id="vs">VS</b></div>

        <div class="col-5 teams-col">
            {{game.team2.teamName ? game.team2.teamName : game.team2.members}}
        </div>

    </div>

    <mat-divider id="horizontal-divider"></mat-divider>

    <form [formGroup]="scoresForm">
        <div class="row scores-row">
            <div class="col-5 scores-col">
                <mat-form-field>
                    <input matInput placeholder="Score" formControlName="team1Score" type="text" autocomplete="off"
                        [errorStateMatcher]="matcher">

                    <mat-error *ngIf="submitClicked">
                        <span *ngIf="scoresForm.get('team1Score').errors?.required">
                            Score is required.
                        </span>
                    </mat-error>

                    <mat-error>
                        <span *ngIf="scoresForm.get('team1Score').hasError('notInteger')">
                            Must be an integer.
                        </span>
                    </mat-error>

                    <mat-error>
                        <span *ngIf="scoresForm.hasError('same')">
                            Scores cannot be the same.
                        </span>
                    </mat-error>

                </mat-form-field>
            </div>

            <div class="col-2 scores-col">
                <mat-divider id="vertical-divider" [vertical]="true"></mat-divider>
            </div>

            <div class="col-5 scores-col">
                <mat-form-field>
                    <input matInput placeholder="Score" formControlName="team2Score" type="text" autocomplete="off"
                        [errorStateMatcher]="matcher">

                    <mat-error *ngIf="submitClicked">
                        <span *ngIf="scoresForm.get('team2Score').errors?.required">
                            Score is required.
                        </span>
                    </mat-error>

                    <mat-error>
                        <span *ngIf="scoresForm.get('team2Score').hasError('notInteger')">
                            Must be an integer.
                        </span>
                    </mat-error>

                    <mat-error>
                        <span *ngIf="scoresForm.hasError('same')">
                            Scores cannot be the same.
                        </span>
                    </mat-error>

                </mat-form-field>
            </div>
        </div>

    </form>



</mat-card-content>