import { Component, Input, OnInit } from '@angular/core'
import { ManageScheduledPlaysComponent } from '../manage-scheduled-plays/manage-scheduled-plays.component'
import { ManageGameLibraryComponent } from '../manaage-game-library/manage-game-library.component'
import { ManageGameInterestComponent } from '../manage-game-interest/manage-game-interest.component'
import { ManageAttendanceComponent } from '../manage-attendance/manage-attendance.component'

@Component({
    selector: 'app-manage-convention',
    templateUrl: './manage-convention.component.html',
    styleUrls: ['./manage-convention.component.scss'],
})
export class ManageConventionComponent implements OnInit {
    @Input() inputData: any //I WANT TO PASS THIS DATAA INTO THE CHILDREN
    @Input() user: any //I WANT TO PASS THIS DATAA INTO THE CHILDREN
    @Input() isGHAdmin: boolean //I WANT TO PASS THIS DATAA INTO THE CHILDREN

    currentTabIndex: number = 0

    constructor() {}

    scheduledPlaysManager = ManageScheduledPlaysComponent //child
    magageGameLibrary = ManageGameLibraryComponent //child
    manageGameInterest = ManageGameInterestComponent //child
    manageAttendance = ManageAttendanceComponent //child

    tabs
    tabsOriginal = [
        // dont modify this!!! its used to reset so that unshift doesnt create multiple instances of events (and for removing items for mobile view???)
        { title: 'Scheduled Plays', isSelected: true, icon: ' ' },
        { title: 'Game Library', isSelected: false, icon: ' ' },
        { title: 'Game Interest', isSelected: false, icon: ' ' },
        { title: 'Attendance', isSelected: false, icon: ' ' },
    ]
    contentComponent

    ngOnInit(): void {
        let shallowCopy = JSON.stringify(this.tabsOriginal)

        this.tabs = JSON.parse(shallowCopy)
    }
    onTabChange(index: number) {
        this.currentTabIndex = index
    }
    refreshContentComponent() {
        // ? is this ever called?
        let currentComponent = this.contentComponent
        this.contentComponent = null
        this.contentComponent = currentComponent
    }
}
