import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
@Component({
  selector: 'app-gh-dropdown-select[_sourceOfInput]',
  templateUrl: './gh-dropdown-select.component.html',
  styleUrls: ['./gh-dropdown-select.component.scss']
})
export class GhDropdownSelectComponent implements OnInit {

  @Input() _sourceOfInput: string

  label = 'Input'
  @Input() set _label(label: string) {
    if (label) {
      this.label = label
    }
  }

  tier0Items = []
  @Input() set _tier0Items(tier0Items) {
    if (tier0Items) {
      this.tier0Items = tier0Items
    }
  }

  isTiered = false
  @Input() set _isTiered(isTiered: boolean) {
    this.isTiered = isTiered
  }

  isMultiSelect = false
  @Input() set _isMultiSelect(isMultiSelect: boolean) {
    this.isMultiSelect = isMultiSelect
  }

  displayThumbImg = false
  @Input() set _displayThumbImg(displayThumbImg: boolean) {
    this.displayThumbImg = displayThumbImg
  }

  itemsAreObjects = false
  @Input() set _itemsAreObjects(itemsAreObjects: boolean) {
    this.itemsAreObjects = itemsAreObjects
  }
 
  @Output() selectionChangedEmitter = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  selectItems(event) {
    this.selectionChangedEmitter.emit({
      tier0Items: this.tier0Items,
      tier1Items: event.value
    })
  }

}
