<div class="bring-game-session">
    <mat-card>
        <mat-card-title>Commit to Bringing a Game</mat-card-title>
        <mat-card-content>
            <form (ngSubmit)="submitForm()" #form="ngForm">
                <!-- Game Title -->
                <div class="form-field full-width">
                    <label class="custom-label">Game Title</label>
                    <app-autocomplete-chiplist
                        *ngIf="coreGames$ | async"
                        [_items]="coreGames.value"
                        [_selectedItems]="gameToEdit ? [gameToEdit] : []"
                        [_allowDuplicates]="false"
                        [_allowCustomInput]="false"
                        [_itemsAreObjects]="true"
                        [_singleInput]="false"
                        [_sourceOfInput]="'app-bring-game'"
                        [_useFuzzySearch]="true"
                        (selectionChanged)="selectGame($event)"
                        name="gameTitle"
                        required
                    ></app-autocomplete-chiplist>
                    <div class="mat-error" *ngIf="!gameSelected">At least one game is required</div>
                </div>

                <!-- Selected Games and Their Notes -->
                <div class="selected-games" *ngIf="selectedGames.length > 0">
                    <h3>Selected Games & Notes</h3>
                    <div
                        *ngFor="let game of selectedGames; let i = index"
                        class="selected-game-entry"
                        style="
                            display: flex;
                            align-items: center;
                            gap: 16px;
                            height: 40px;
                            margin-bottom: 8px;
                        "
                    >
                        <div
                            class="game-info"
                            style="
                                min-width: 150px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            "
                        >
                            <strong>{{ game.title }}</strong>
                        </div>
                        <mat-form-field style="flex: 1; margin: 0">
                            <input
                                matInput
                                [(ngModel)]="game.notes"
                                [name]="'notes_' + game.pk"
                                placeholder="Notes"
                            />
                        </mat-form-field>
                    </div>
                </div>

                <!-- Submit and Cancel Buttons -->
                <div class="button-group">
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        [disabled]="!isFormValid()"
                    >
                        Commit to Bringing Games
                    </button>
                    <button mat-button mat-dialog-close>Cancel</button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
